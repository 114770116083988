import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {ISearchResult} from "@colmeia/core/src/comm-interfaces/ds-interfaces";



/**
 * This service emits/listens a search result when the user interacts with it somehow(ex: clicks),
 * is used by the group-search component
 */
@Injectable()
export class SearchResultService {
    private searchResult: Subject<ISearchResult> = new Subject<ISearchResult>();

    constructor() { }

    /**
     * sends a search result to everyone listening to the subject(like chat-messages)
     * @param {ISearchResultMsgDisplay} result [description]
     */
    public send(result: ISearchResult) {
        this.searchResult.next(result);
    }

    public listen(): Observable<ISearchResult> {
        return this.searchResult.asObservable();
    }

    public clear(): void {
        this.searchResult.next(null);
    }
}
