import { FriendlyMessage, isValidString } from "../../../../tools/barrel-tools";
import { IColmeiaAPITokenClient } from "../../../colmeia-apis/colmeia-api-model";
import { commonNSerErrorFields } from "../../../const-text/errors/general-errors";
import { screenOptionToError } from "../error-messages";
import { TNserValidator } from "../validate-nser";

const commonErrors = screenOptionToError(commonNSerErrorFields);

export const ColmeiaApiMinNameLength = 4;

const validator: TNserValidator = (nser: IColmeiaAPITokenClient, friendly: FriendlyMessage) => {

    if (!isValidString(nser.nName, 0)) {
        friendly.addReturnResponse(commonErrors.insertName);
    }

    if (!isValidString(nser.nName, ColmeiaApiMinNameLength)) {
        friendly.addReturnResponse(commonErrors.smallName);
    }

    if (!isValidString(nser.apiType)) {
        friendly.addReturnResponse(commonErrors.insertDescription);
    }

    return friendly;
}

export const colmeiaApiModelValidator = validator;