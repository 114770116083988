import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import {isInvalid, isInvalidOrEqual, isValidRef} from '@colmeia/core/src/tools/utility';

interface IColmeiaDialogComponentParams {
    title?: string;
    componentOrTemplateRefToOpen: ComponentType<any> | TemplateRef<any>;
    contentText?: string;
    lineUnderHeader?: boolean;
    hideHeader?: boolean;
    hideClose?: boolean;
}

export interface IColmeiaDialogComponentData<T> {
    getParamsToChildComponent(): T;
}

export type TColmeiaDialogComponentDescriptor<T> = IColmeiaDialogComponentParams &
    IColmeiaDialogComponentData<T>;

/**
 * This component is not suposed to be used directly,
 * you should use ColmeiaDialogService to open it
 *
 * @param  {'cm-dialog'} selector
 * @param  {'./dialog.component.html'} templateUrl
 * @param  {['./dialog.component.scss']} styleUrls
 */
@Component({
    selector: 'cm-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class ColmeiaDialogComponent<T> implements OnInit {
    disableAnimation = true;
    ngAfterViewInit(): void {
        setImmediate(() => this.disableAnimation = false);
    }

    lineUnderHeader: boolean = true;
    includeHeader: boolean = true;

    constructor(
        private dialogRef: MatDialogRef<ColmeiaDialogComponent<T>>,
        @Inject(MAT_DIALOG_DATA)
        public dataFromColmeiaDialogSvc: TColmeiaDialogComponentDescriptor<T>
    ) {
        if (this.dataFromColmeiaDialogSvc.lineUnderHeader !== undefined) {
            this.lineUnderHeader = this.dataFromColmeiaDialogSvc.lineUnderHeader;
        }

        if (this.dataFromColmeiaDialogSvc.hideHeader !== undefined) {
            this.includeHeader = !this.dataFromColmeiaDialogSvc.hideHeader;
        }
    }

    ngOnInit() {
    }

    hasTitle(): boolean {
        return this.dataFromColmeiaDialogSvc && isValidRef(this.dataFromColmeiaDialogSvc.title);
    }

    close(): void {
        this.dialogRef.close();
    }

    get hasCloseButton(): boolean {
        return isInvalidOrEqual(this.dataFromColmeiaDialogSvc.hideClose, false);
    }
}
