import {Component, OnInit, Input, ChangeDetectionStrategy} from '@angular/core';
import {TonMouseClickCallback} from "../../../../model/card-types";

@Component({
  selector: 'cm-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardButtonComponent implements OnInit {

  @Input() icon: string;
  @Input() callback: TonMouseClickCallback;
  @Input() visible: boolean;

  constructor() {}
  ngOnInit() {}

  public safeIcon(): string {
      return this.icon || ``;
  }

  public safeVisible(): boolean {
      return (this.visible !== undefined)
          ? this.visible
          : true;
  }

  public triggerCallback(event: MouseEvent) {
      if ('function' === typeof this.callback) {
          this.callback(event);
      }
  }
}
