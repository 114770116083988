import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { MatButton } from "@angular/material/button";
import { IConnectionVariable, TIConnectionVariableArray } from "@colmeia/core/src/shared-business-rules/connections/endpoint-model";
import { isEqual, isValidNumber, isValidRef } from "@colmeia/core/src/tools/utility";
import { MatQuill } from "app/components/var-editor/mat-quill/mat-quill";
import {
    ColmeiaVariableInserterService,
    IVarInserterHandler
} from "app/services/dashboard/colmeia-variable-inserter.service";
import { uniqBy } from "lodash";
import { QuillModules } from "ngx-quill";
import Quill from "quill";
import {
    cleanHTML,
    mentionsToVariables,
    removeCurlyBraces,
    TParamVariable,
    turnVarToHtml
} from "../params-editor/mat-quill-utility";
import { VarInserterDialogComponent } from "../var-inserter-dialog/var-inserter-dialog.component";
import { QuillVarInserterBlot } from "./quill-var-inserter.blot";

@Component({
    selector: "mat-quill-var-inserter",
    templateUrl: "./mat-quill-var-inserter.component.html",
    styleUrls: ["./mat-quill-var-inserter.component.scss"],
})
export class MatQuillVarInserterComponent implements OnInit, OnChanges {
    @Input() readonly: boolean = false;
    @Input() inputLabel: string;
    @Input() inputKey: string;
    @Input()
    set inputValue(value: string) {
        if (value !== this.clearedValue) this._inputValue = value;
    }
    clearedValue: string;
    _inputValue: string;
    @Output() inputValueChange: EventEmitter<string> = new EventEmitter();

    @Input() persistedVariables: TIConnectionVariableArray = [];
    @Output() persistedVariablesChange: EventEmitter<IConnectionVariable[]> =
        new EventEmitter();

    paramsVariables: TParamVariable[] = [];

    @ViewChild("insertVarInput", { static: false })
    insertVarInput: ElementRef<HTMLInputElement>;

    @ViewChild("paramValueInput") paramValueInput: MatQuill;


    regex = /\${([\d\D][^}]+)}/g;

    public readonly modules: QuillModules = {
        toolbar: {
            container: [],
        },
        keyboard: {
            bindings: {
                enter: {
                    key: 13,
                    handler: () => { },
                },
                shift_enter: {
                    key: 13,
                    shiftKey: true,
                    handler: () => { },
                },
            },
        },
        mention: {
            blotName: QuillVarInserterBlot.blotName,
            allowedChars: /^[A-Za-z0-9ÅÄÖåä_ö-]*$/,
            positioningStrategy: "fixed",
            showDenotationChar: false,
            spaceAfterInsert: false,
            onSelect: (item: DOMStringMap, insertItem: Function) => {
                insertItem(item);
            },
            source: (searchTerm: string, renderList: Function) => {
                const values = [
                    {
                        id: this.addCurlyBraces(searchTerm),
                        value: searchTerm,
                    },
                    {
                        id: '${auth}',
                        value: 'auth',
                    },
                ];

                renderList(values, searchTerm);
            },
        },
    };

    constructor(
        private viewContainerRef: ViewContainerRef,
        private varInserter: ColmeiaVariableInserterService
    ) { }

    ngOnInit(): void {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isEqual(changes.persistedVariables?.currentValue, this.persistedVariables)) {
            if (!this.persistedVariables) return;
            this.init();
        }
    }

    init() {
        this.cleaInputFromVariables();
        this.populatePopUpVariablesList();
        this.placeVariablesInInput();
    }

    cleaInputFromVariables() {
        this._inputValue = cleanHTML(
            mentionsToVariables(this._inputValue, this.paramsVariables)
        );
    }

    private populatePopUpVariablesList() {
        this.paramsVariables = this.persistedVariables.map((variable) => ({
                id: variable.varName,
                value: variable.varId || removeCurlyBraces(variable.varName),
            })
        );
    }
    arrayString: string[] = []
    private placeVariablesInInput() {
        if (!this.persistedVariables || !this.persistedVariables.length) return;

        if (this._inputValue) {
            const inputValue = this._inputValue.split("");
            let nextVarStartIdx = 0;
            const uniqueArr = this.persistedVariables.sort(
                (a, b) => a.startIdx - b.startIdx
            );
            uniqueArr.forEach(({ startIdx, endIdx, varName }, i, arr) => {
                const offSetOrStart =
                    nextVarStartIdx > 0 ? nextVarStartIdx : startIdx;

                inputValue.splice(
                    offSetOrStart,
                    endIdx - startIdx,
                    turnVarToHtml(varName)
                );

                nextVarStartIdx += arr[i + 1]?.startIdx - (endIdx - startIdx) + 1;
            });

            this._inputValue = inputValue.join("");
        }

    }

    public handleInputValueChange(inputValue: string) {
        this._inputValue = inputValue;
        this.clearedValue = cleanHTML(
            mentionsToVariables(this._inputValue, this.paramsVariables)
        );
        const mappedVariables: TIConnectionVariableArray = [];

        let startIdx: number | undefined;
        let endIdx: number | undefined;
        let possibleVar: string | undefined;

        this.clearedValue.split("").forEach((char, i, arr) => {
            if (char === "$" && arr[i + 1] === "{") {
                startIdx = i;
                endIdx = undefined;
            }
            if (isValidRef(startIdx) && char === "}") {
                endIdx = i + 1;
                possibleVar = arr.slice(startIdx, endIdx).join("");

                const isVar = this.paramsVariables.find(
                    (variable) => variable.id === possibleVar
                );
                if (isVar) {
                    mappedVariables.push({
                        varName: isVar.id,
                        varId: isVar.value,
                        startIdx,
                        endIdx,
                        fieldName: this.inputKey
                    });
                }
                startIdx = undefined;
                endIdx = undefined;
                possibleVar = undefined;
            }
        });
        
        this.persistedVariablesChange.emit(mappedVariables);
        this.inputValueChange.emit(this.clearedValue);
    }

    private addCurlyBraces(value: string): string {
        return "${" + value + "}";
    }

    public openTemplate(buttonTrigger: MatButton, event: MouseEvent) {
        const varInserterHandler: IVarInserterHandler = {
            subscriptionCallback: (value) => {
                const editor: Quill = this.paramValueInput.quillEditor;
                const quillMention = editor.getModule("mention");
                this.paramsVariables.push(value);
                quillMention.insertItem(value, true);
            },
            popUpEventEmitterProperty: "varToInsert",
            popUpComponent: VarInserterDialogComponent,
            containerRef: this.viewContainerRef,
            data: [{ id: "${auth}", value: "auth" }],
            componentDataProp: "paramsVariables",
        };

        this.varInserter.openPopUp(buttonTrigger, event, varInserterHandler);
    }
}
