export namespace EscapeRegExp {
    const safeRegExp = safeFactoryOf(RegExp)
    const chars = [...range(0, String.fromCharCode(-1).charCodeAt(0))].map(code => String.fromCharCode(code))
    const textChars = chars.join('');
    
    function* range(from: number, to: number) {
        for (let index = from; index <= to; index++) yield index;
    }
    
    function searchPatternOf(char: string): RegExp | undefined {
        const pattern = safeRegExp(char, 'g');
    
        if (!pattern) return;
    
        const it = textChars.matchAll(pattern);
        
        for (const item of it) {
            if (item[0] !== char) return;
        }
        
        return pattern;
    }
    
    function findEscapeOf(char: string): RegExp | undefined {
        if (char === '[') return safeRegExp(`\\${char}`)
        return searchPatternOf(char) ?? safeRegExp(`[${char}]`) ?? safeRegExp(`\\${char}`)
    }
    
    const escaped = chars
        .map((char) => ({ char }))
        .map(item => {
            return { ...item, pattern: findEscapeOf(item.char)! };
        })
    ;
    
    const mapCharToEscaped = new Map<string, string>();
    escaped.forEach(item => {
        mapCharToEscaped.set(item.char, item.pattern.source);
    });
    
    function safeFactoryOf<A extends any[], B>(fn: new (...params: A) => B) {
        return (...a: A): B | undefined => { try { return new fn(...a); } catch { return; } }
    }
    
    export function escapeRegExp(text: string) {
        let output = '';
        for (const char of text) {
            output += mapCharToEscaped.get(char)!;
        }
        return output;
    }

}

