import { TGlobalUID } from '../../business/constant';
import { Participant } from '../../business/participant';
import { IInteractionJSON, ITrackerSignature, ITrackerInteractionJSON } from '../../comm-interfaces/barrel-comm-interfaces';
import { Tracker } from './tracker';


export abstract class TrackerSignature extends Tracker {

    protected constructor (primaryID: TGlobalUID, participant: Participant, trackerType: number) {
        super(primaryID, participant, trackerType);
    }

    public toJSON(): ITrackerSignature {
        let tracker: ITrackerInteractionJSON = super.toJSON();
        return {...tracker, 
            receiveSignatureCycle: null,
            receivedSignature: {
                idParentEntity: this.getParentEntityID(),
                idParticipant: this.getParticipant().getParticipantID(),
                spokeParticipant: this.getParticipant().getAvatar().isSpokeAvatar(),
                idGroup: tracker.idGroup,
                idAvatar: tracker.participant.avatar.primaryID,
                avatarName: this.getParticipant().getAvatar().getName(),
            }
        }; 
    };

};
