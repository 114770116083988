import { $RegExp, MapGroups } from '../../utility-types'
import { resetPattern } from './resetPattern';

/**
 * Get typed groups from regular expression
 * @param pattern $RegExp
 * 
 * @example 
 * * @input pattern $RegExp<'(?<name>colmeia(?:(?!(?:\\.[^.]*$|\\.d\\.ts$)).)*)'>
 * * @input text '/source/source/colmeia/core/src/file.ts'
 * * @output { name: 'colmeia/core/src/file' }
 */
 export function getGroupsFromPattern<Source extends string>(pattern: $RegExp<Source>): (text: string) => MapGroups<Source> | undefined;
 export function getGroupsFromPattern<Source extends string>(pattern: $RegExp<Source>): (text: string) => {} | undefined;
 export function getGroupsFromPattern<Source extends string>(pattern: $RegExp<Source>) {
     return <T extends string>(text: T) => {
         resetPattern(pattern);
         return pattern.exec(text)?.groups;
     };
 }
 
 