<div class="action-bar" [class.is-loading]="isLoading()">
  <chat-action-bar-window-component #chatBarWindow [ngClass]="{'action-bar-window--hidden': hideActionWindow()}"
  [handler]="getActionBarWindowHandler()"></chat-action-bar-window-component>
<form (ngSubmit)="sendInteractionWrapper()" [style.min-height]="'calc(100% - '+windowBarHeight()+'px)'">
    <div class="action-bar__scribbler">
      <section *ngIf="!hasFieldEngagement" class="more-features"
        [ngClass]="{'btn-over-input--icons-container': !canShowBtnOverInput}">
        <button class="more-features-button btn-over-input--button" (click)="toggleMoreFeatures($event)"
          [disabled]="isLoading()" [ngClass]="{'btn-over-input--button': canShowBtnOverInput,
                        'btn-over-input--button--rotate': canShowMoreFeatures}">
          <mat-icon>expand_more</mat-icon>
        </button>
        <div class="multimedia-options multimedia-options--hide"
          [ngClass]="{'multimedia-options--show': canShowMoreFeatures}">
          
          <div class="icon-container microphone-icon" *ngIf="canSendAudioMessage">
            <button (click)="toggleMoreFeatures($event); toggleMediaRecorder()" class="icon-container__button">
              <mat-icon class="mat-20">mic</mat-icon>
            </button>
          </div>

          <app-chat-bar-extra-features class="icon-container" *ngIf="canShowMoreFeatures && ! isInEmbeddedMode"
            [personalGroupParticipant]="getPersonalGroupParticipant()" [interaction]="getClientInteraction()">
          </app-chat-bar-extra-features>
        </div>
      </section>
      <div class="action-bar__scribbler__input-container">
        <div class="action-bar__scribbler__block" [class.has-field-engagement]="hasFieldEngagement"
          [class.show-as-calendar]="isFieldShowAsCalendar()"
          [class.btn-over-input--input-container]="canShowBtnOverInput">

          <ng-container *ngIf="hasFieldEngagement">
            <input *ngIf="!isFieldShowAsCalendar() else fieldAsCalendar" matInput name="field-response-message" class="field-response-input"
              placeholder="Digite aqui" [type]="getFormFieldType()" [leadZeroDateTime]="true" [hiddenInput]="true"
              [(ngModel)]="formFieldAnswer" [mask]="getFormFieldMask().formatMask"
              [dropSpecialCharacters]="!!getFormFieldMask().removePontuation">
          </ng-container>

          <button *ngIf="!hasFieldEngagement" (click)="toggleEmojiBox()" type="button" [disabled]="isLoading()"
            class="toggle-emoji-box__button">
            <i class="material-icons">tag_faces</i>
          </button>
          <div 
            #contenteditableInput
            [attr.contenteditable]="!isLoading() ? 'true' : 'false'" 
            [style.opacity]="isLoading() ? '0.6' : '1'"
            [style.display]="hasFieldEngagement ? 'none' : null" 
            [attr.data-placeholder]="getInputPlaceholder()" 
            class="chat-input__message nice-scroll notranslate"
            (keyup)="onKeyUp($event)" 
            (keydown)="onKeyDown($event)" 
            (paste)="onPaste($event)" 
            (input)="onInput($event)"
            (focus)="updateEditingPosition()"
            (click)="updateEditingPosition()"
          >
          </div>
        </div>
        <div class="right-buttons" [class.has-field-engagement]="hasFieldEngagement">
          <button *ngIf="!hasFieldEngagement" matRipple [disabled]="isLoading()"
            (click)="canShowMoreFeatures && toggleMoreFeatures($event); openAttachments(true, $event)"
            class="attachments-button">
            <mat-icon>attach_file</mat-icon>
          </button>

          <button matRipple class="send-button" [disabled]="!canSendMessage() || isLoading()"
            [ngClass]="{'send-disabled': !canSendMessage()}" type="submit">
            <mat-icon *ngIf="!isLoading() else sendLoading">near_me</mat-icon>
          </button>
        </div>
      </div>
      <div #emojibox [class]="emojiBoxClasses"></div>
    </div>
  </form>
</div>

<ng-template #sendLoading>
  <mat-spinner diameter="18" color="primary"></mat-spinner>
</ng-template>

<ng-template #fieldAsCalendar>
  <mat-calendar [(selected)]="calendarValue"></mat-calendar>
</ng-template>