import { replaceCompiledMessageByTagContent, TComputedInfo,  } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { IServerColmeiaTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { ICompiledTemplateWithVariables } from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";

export interface IMacroChooseListener {
    onMacroClicked: (output: string) => void;
}

export interface IMacro {
    name: string;
    template: ICompiledTemplateWithVariables;
}

export type TMacroList = Array<IMacro>;

export interface IMacrosHandlerParameter {
    callback: IMacroChooseListener;
    variables: IServerColmeiaTag[];
    computed: TComputedInfo;
    variableIds: string[];
    list: TMacroList;
}


export class MacrosHandler {

    private parameter: IMacrosHandlerParameter;

    constructor(parameter: IMacrosHandlerParameter) {
        this.parameter = parameter;
    }

    public getMacroList(): TMacroList {
        return this.parameter.list;
    }

    public async renderMacro(macro: IMacro): Promise<string> {
        const { messageIfNoBind, compiledTemplate } = macro.template;
        const { variables, computed } = this.parameter;

        const result: string = replaceCompiledMessageByTagContent(
            messageIfNoBind || compiledTemplate, 
            macro.template, 
            variables, 
            computed, 
            {}
            );

        return result;
    }


    public async getCanonicalsComputedInfo(): Promise<TComputedInfo> {
        return;
    }

    public async onMacroClick(macro: IMacro): Promise<void> {
        const render = await this.renderMacro(macro);

        this.parameter.callback.onMacroClicked(render);
    }
}
