import { Component, OnInit, Inject } from '@angular/core';
import { IColmeiaDialogComponentData } from '../dialog/dialog.component';
import { IInteractivePromptHandler, EInteractiveButton, allInteractiveButtonTranslations, IInteractiveButton, IInteractiveButtonMiddlewareClick } from 'app/services/global-warning.service';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { TColmeiaExtraPallete } from 'app/utils/theme.types';
import { isValidFunction, isValidRef, random } from '@colmeia/core/src/tools/utility';
import { ENotificationPromptReadConfirmationType, INotificationPromptReadConfirmation } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import { AcceptPromise } from '@colmeia/core/src/tools/utility-types';

interface ILocalINotificationPromptReadConfirmation extends INotificationPromptReadConfirmation {
    typedValue: string | number,
    expectedValue: string | number,
    prompt: string;
    width: number;
}

interface IButtonsForHtml{
    text: string,
    color: ThemePalette | TColmeiaExtraPallete,
    type: EInteractiveButton,
    style: string,
    matIcon?: string,
    tooltip?: string,
    enableQuestion?: ILocalINotificationPromptReadConfirmation;
    middlewareClick?: () => AcceptPromise<IInteractiveButtonMiddlewareClick>
}

@Component({
    selector: 'app-interactive-prompt',
    templateUrl: './interactive-prompt.component.html',
    styleUrls: ['./interactive-prompt.component.scss']
})
export class InteractivePromptComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<InteractivePromptComponent>,
        @Inject(MAT_DIALOG_DATA)
        dataFromParentComponent: IColmeiaDialogComponentData<IInteractivePromptHandler>

    ) { 
        this.handler = dataFromParentComponent.getParamsToChildComponent()
    }
    handler: IInteractivePromptHandler;
    buttonsToShow: IButtonsForHtml[];

    private buttonHtmlToInteractive: Map<IButtonsForHtml, IInteractiveButton> = new Map();

    ngOnInit() {
        this.buttonsToShow = this.handler.options.map((val:IInteractiveButton)=>{
            const translatedButton = val.text || Serializable.getTranslation(allInteractiveButtonTranslations[val.option])

            const buttonHtmlConfig = <IButtonsForHtml>{
                text: translatedButton,
                color: val.color,
                type: val.option,
                style: val.style,
                matIcon: val.matIcon,
                tooltip: val.tooltip,
                middlewareClick: val.middlewareClick,
            };

            if(isValidRef(val.enableQuestion)) {
                const isSum: boolean = val.enableQuestion.type === ENotificationPromptReadConfirmationType.Sum;
                let expectedValue: string;
                let prompt: string;
                if(isSum) {
                    const left: number = random(5, 1);
                    const right: number = random(5, 1);
                    expectedValue = String(left + right);
                    prompt = `Quanto é ${left} + ${right}?`
                } else {
                    expectedValue = val.enableQuestion.text;
                    prompt = `Digite "${val.enableQuestion.text}"`
                }

                const avaragePixelPerChar: number = 8;
                buttonHtmlConfig.enableQuestion = {
                    ...val.enableQuestion,
                    typedValue: undefined,
                    expectedValue,
                    prompt,
                    width: prompt.length * avaragePixelPerChar
                }
            }

            this.buttonHtmlToInteractive.set(buttonHtmlConfig, val);

            return buttonHtmlConfig;
        })

        this.dialogRef.disableClose = this.handler.disableClose;
        
    }

    async buttonPressed(btn: IButtonsForHtml) {
        if (isValidFunction(btn.middlewareClick)) {
            const result = await btn.middlewareClick();

            if (result?.stop) return;
        }

        this.dialogRef.close(btn.type);
    }
    
    public isDisabled(button: IButtonsForHtml) {
        const hasNotQuestionToEnable = !isValidRef(button.enableQuestion);

        if(hasNotQuestionToEnable) {
            return false;
        }

        return button.enableQuestion.typedValue !== button.enableQuestion.expectedValue;
    }
}
