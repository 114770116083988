import { EDelivery360Action } from "../../comm-interfaces/barrel-comm-interfaces";
import { DeliveryProvider } from "../../core-constants/types";
import { mbToByte } from "../../tools/utility";

interface IDelivery360Config {
    hasVoiceSupport: boolean;
    maxFileBytesLength: number;
    providerSpecifcSettings: {
        [key in DeliveryProvider]?: IProviderSpecificSettings
    }
}

interface IProviderSpecificSettings {
    maxTextLengthByStandardMessage: number;
}

type TDelivery360ConfigDB = {
    [key in EDelivery360Action]: IDelivery360Config
}

export const delivery360ConfigDB: TDelivery360ConfigDB = {
    [EDelivery360Action.Delivery360Email]: {
        hasVoiceSupport: false,
        maxFileBytesLength: -1,
        providerSpecifcSettings: {
            [DeliveryProvider.sendGrid]: {
                maxTextLengthByStandardMessage: Number.MAX_SAFE_INTEGER,
            },
        },
    },
    [EDelivery360Action.Delivery360FBMessenger]: {
        hasVoiceSupport: false,
        maxFileBytesLength: -1,
        providerSpecifcSettings: {
            [DeliveryProvider.facebook]: {
                maxTextLengthByStandardMessage: 4096,
            },
        }
    },
    [EDelivery360Action.Delivery360SMS]: {
        hasVoiceSupport: false,
        maxFileBytesLength: mbToByte(5),
        providerSpecifcSettings: {
            [DeliveryProvider.sendGrid]: {
                maxTextLengthByStandardMessage: 160,
            },
            [DeliveryProvider.twilio]: {
                maxTextLengthByStandardMessage: 360,
            },
        },
    },
    [EDelivery360Action.Delivery360Voice]: {
        hasVoiceSupport: true,
        maxFileBytesLength: 0,
        providerSpecifcSettings: {
        },
    },
    [EDelivery360Action.Delivery360WhatsApp]: {
        hasVoiceSupport: true,
        maxFileBytesLength: mbToByte(16),
        providerSpecifcSettings: {
            [DeliveryProvider.whatsApp]: {
                maxTextLengthByStandardMessage: 4096,
            },
            [DeliveryProvider.twilio]: {
                maxTextLengthByStandardMessage: 1600,
            },
            [DeliveryProvider.infobip]: {
                maxTextLengthByStandardMessage: 4096,
            },
            [DeliveryProvider.wavy]: {
                maxTextLengthByStandardMessage: 4096,
            },
            [DeliveryProvider.smarters]: {
                maxTextLengthByStandardMessage: 4096,
            },
            [DeliveryProvider.sinch]: {
                maxTextLengthByStandardMessage: 4096,
            },
        },
    },
    [EDelivery360Action.DeliveryColmeia]: {
        hasVoiceSupport: true,
        maxFileBytesLength: mbToByte(100),
        providerSpecifcSettings: {
            [DeliveryProvider.colmeia]: {
                maxTextLengthByStandardMessage: Number.MAX_SAFE_INTEGER,
            },
        },
    },
    [EDelivery360Action.DeliveryInstagram]: {
        hasVoiceSupport: false,
        maxFileBytesLength: -1,
        providerSpecifcSettings: {
            [DeliveryProvider.instagram]: {
                maxTextLengthByStandardMessage: 1024,
            },
        },
    },
    [EDelivery360Action.DeliveryTelegram]: {
        hasVoiceSupport: true,
        maxFileBytesLength: mbToByte(100),
        providerSpecifcSettings: {
            [DeliveryProvider.telegram]: {
                maxTextLengthByStandardMessage: 4096,
            },
        },
    },
}

export function getMaxLengthForStandardMessage(providerType: EDelivery360Action, provider: DeliveryProvider): number {
    return delivery360ConfigDB[providerType].providerSpecifcSettings[provider]?.maxTextLengthByStandardMessage || -1;
}
