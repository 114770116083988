import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { EMenuMode, IBotElementWithRenderOptions, IMenuContainerDisplayOptions } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { defaultFields, ifTrueExecute, ifValidExecute } from "@colmeia/core/src/tools/utility";
import { CmRenderOptionsComponent } from "app/components/dashboard/ai-pages/bots/bot-edit/bot-configuration/cm-render-options/cm-render-options.component";
import { EnumPickerHandler } from "app/components/foundation/enum-picker/enum-picker.handler";
import { MainHandler } from "app/handlers/main-handler";
import { TVarEditorVariables } from "app/handlers/var-editor.handler";
import { IComponentParameter } from "app/model/component-comm/basic";
import { BotMenuContainerWhatsappConfigurationHandler } from "../../../bot-menu-container-whatsapp-configuration/bot-menu-container-whatsapp-configuration.handler";

/**
 * Esse tipo é uma união de tipos.
 * Acrescenta o tipo que deseja adicionar a direita usando pipe.
 * @example
 * type TMenuContainerConfiguration =
 *      | BotMenuContainerWhatsappConfigurationHandler
 *      | TipoExemplo
 * ;
 */
export type TMenuContainerConfiguration = BotMenuContainerWhatsappConfigurationHandler; // Use union type to add more types

export interface IMenuRenderTabOptionsSettingsItem {
    label: string;
    icon: string;
    value: EDelivery360Action;
    modeEnumPicker: EnumPickerHandler<EMenuMode>;
};

export type TMenuRenderTabOptionsSettings = IMenuRenderTabOptionsSettingsItem[];

export interface ICmRenderOptionsClientCallback {

}
export interface ICmRenderOptionsParameters extends IComponentParameter {
    element: IBotElementWithRenderOptions;
    shouldShowMultipleChoiceAdvancedTitle?: boolean;
    clientCallback: {
        onConsistency?: (element: IBotElementWithRenderOptions) => void;
    },
    menuContainerHandler?: BotMenuContainerWhatsappConfigurationHandler;
    ignoredRenderOptions?: EMenuMode[];
}

function initDefaultParameters() {
    const defaultParameters: Partial<ICmRenderOptionsParameters> = {
        shouldShowMultipleChoiceAdvancedTitle: true,
    };
    return defaultParameters;
}

export class CmRenderOptionsHandler extends MainHandler<ICmRenderOptionsParameters> {
    slave?: CmRenderOptionsComponent;
    public constructor(parameter: ICmRenderOptionsParameters) { defaultFields(parameter, initDefaultParameters()); super(parameter) }

    getSelectedAction(): EDelivery360Action | undefined {
        return this.slave?.item.value;
    }

    get parameters() { return this.getComponentParameter(); }

    getItem(): IMenuContainerDisplayOptions | undefined {
        const item = ifValidExecute(this.getSelectedAction(), value => this.parameters.element.renderOptions[value]);
        return item;
    }
}
