import { ENextGenBotElementType } from '../../../bot/bot-model';
import { ESubscribedTo } from '../../../BPM/common/common-bpm';
import { IBPMMarketingListenerServer, TBpmMarketingNsTypes } from '../../../BPM/marketing/bpm-marketing.model';
import { GraphElement } from '../../../graph/essential/graph-element';
import { GraphRulesProcessor } from '../../../graph/essential/graph-rules-processor';
import { TGraphPredicateArray } from '../../../graph/essential/predicate';
import { ENonSerializableObjectType, INonSerializable } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { EAdditionalPredicateType, TReferencedObject } from '../../toolbar/config-toolbar.types';
import { INodeDeletedConnection } from '../ns/ns.host.types';
import { MktHostNode } from './mkt-host-node';

export class MktListenerHost extends MktHostNode {
    private dragableIntoMe: TReferencedObject[] = [
        ENonSerializableObjectType.bpmMarketingPause,
        ENonSerializableObjectType.bpmMarketingEnd,
        ENonSerializableObjectType.bpmMarketingAction,
    ];

    private drawableConnections: TReferencedObject[] = [
        ENextGenBotElementType.formTransaction,
        ENextGenBotElementType.root,
        ENonSerializableObjectType.bot,
        ENonSerializableObjectType.contentGenerator,
    ];

    constructor(mktListener: IBPMMarketingListenerServer) {
        super(mktListener)
    }

    static create(mktListener: IBPMMarketingListenerServer): MktListenerHost {
        return new MktListenerHost(mktListener)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return this.dragableIntoMe.includes(nodeTypeToDrag);
    }

    deleteConnectionTo(targetToBeDeleted: MktHostNode, connectionType: TBpmMarketingNsTypes): INodeDeletedConnection {
        const isContentGenerator: boolean = targetToBeDeleted.getHostedType() == ENonSerializableObjectType.contentGenerator;
        const isBotItem: boolean = targetToBeDeleted.getHostedType() == ENextGenBotElementType.botMenuItem;

        if (isContentGenerator || isBotItem) {
            const nser: IBPMMarketingListenerServer = (<IBPMMarketingListenerServer>this.getNonSerializable());

            nser.listenTo = {
                idSubscribedTo: undefined,
                type: undefined
            }
            return { deletedConnection: true }
        }

        return { deletedConnection: false }
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return false
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        return true//connectionType !== ESubscribedTo.campaignAction
    }

    thereIsMoreChildrenNodesToCreate(): boolean {
        const con = this.getConnectionTargetData()[0];
        return con.connectionType === ESubscribedTo.contentGenerator
            || con.connectionType === ESubscribedTo.botElement;
    }

    getConnectionsToRemove(selfNode: GraphElement, ruleProcessor: GraphRulesProcessor): TGraphPredicateArray {
        const allDrawableConnections: TGraphPredicateArray = super.getConnectionsToRemove(selfNode, ruleProcessor);

        const connectionsToRemove = allDrawableConnections.filter(conn => {
            const isParenthood: boolean = conn.getBusinessPredicateType() === EAdditionalPredicateType.Parent;
            const targetNS: INonSerializable = (ruleProcessor.getElementById(conn.getToElementId()) as GraphElement).getHostObject().getNonSerializable();
            const isChild: boolean = targetNS.idParent === this.getHostedID();

            return !(isParenthood && isChild)
        });

        return connectionsToRemove;
    }
}
