import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { INewAgentCallContactItem } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { NamedString, TArrayID } from "@colmeia/core/src/core-constants/types";

export interface IDashboardActiveCallInfoClientCallback {
    onUpdateActiveCallInfo(): void;
    closeDialog?(): void;
    refreshList?(): void;
}

export type TCampaignActionId = NamedString<'campaignActionId'>

export interface IDashboardActiveCallInfoParameters extends IComponentParameter {
    idParentContactList?: string;
    initialSearchInputValue?: string,
    contactNS?: INewAgentCallContactItem;
    clientCallback: IDashboardActiveCallInfoClientCallback;
    contactList?: TArrayID;
    parentContactListIdCampaignAction?: string;
    sendToAllWithCampaignActionId?: TCampaignActionId;
    directToActiveCall?: boolean;
}

export class DashboardActiveCallInfoHandler extends MainHandler {
    private constructor(parameter: IDashboardActiveCallInfoParameters) { super(parameter) }
    public static factory(parameter: IDashboardActiveCallInfoParameters) { return new DashboardActiveCallInfoHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IDashboardActiveCallInfoParameters }
}
