import { Injectable, Injector } from '@angular/core';
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { UID } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IFocusInfo } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { IClientVersion } from "@colmeia/core/src/security/version";
import { isValidAndEqual } from "@colmeia/core/src/tools/utility";
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IInterfaceInfoSignal, InterfaceInfoSignal } from "../../model/signal/interface-signal";
import { SignalPublisherService } from "../signal/signal-publisher";
import { SocketService } from '../socket.service';
import { BrowserHardwareLayer } from "./browser/browser-hardware-layer";
import { CordovaHardwareLayer } from "./cordova/cordova-hardware-layer";
import {
    GenericPlatform,
    ICameraHardwareAbstraction,
    IFileSystemLayer,
    IGeolocationHardwareAbstraction,
    IHardwareAbstractionLayer,
    ILocalNotificationsHardwareAbstraction,
    IMediaViewer,
    IMicrophoneHardwareAbstraction,
    INetworkHardwareAbstraction,
    INotificationHardwareAbstraction,
    IRedirectHardwareAbstraction,
    IStorageLayer,
    IUpdaterHardwareLayer,
    IVibrationHardwareAbstraction,
    hwConfig
} from './hardware-interfaces';
import { ReactHardwareLayer } from './react/react-hardware-layer';

@Injectable({
    providedIn: 'root'
})
export class HardwareLayerService {
    private abstraction: IHardwareAbstractionLayer;
    private UID: UID;
    private browserId: string;
    private isOfflineEvent = null;
    private _UID$: ReplaySubject<string> = new ReplaySubject(1);
    private expoPushNotificationToken: string;
    private expoPushNotificationToken$: ReplaySubject<string> = new ReplaySubject(1);

    constructor(
        private _injector: Injector,
        private publisher: SignalPublisherService
    ) {
        if ((hwConfig.cordova in window)) {
            this.abstraction = _injector.get<CordovaHardwareLayer>(CordovaHardwareLayer);
        }
        else if (!!(window as any).ReactNativeWebView) {
            this.abstraction = _injector.get<ReactHardwareLayer>(ReactHardwareLayer);
        } else {
            this.abstraction = _injector.get<BrowserHardwareLayer>(BrowserHardwareLayer);
        }
        this.generateUID();
        this.abstraction.bootListeners();
        this.keepEmitNetworkStateToApp();
        this.playEmptySound();
    }

    public playEmptySound(): void {
        if (this.isBrowser()) this.getAudio().playSound('/assets/audio/notifications/focused/silence.wav');
    }

    private async initIds(): Promise<void> {
        this.UID = await this.abstraction.getDeviceUID();
        this._UID$.next(this.UID);
        this.browserId = this.abstraction.getBrowserId();
    }

    UID$(): Promise<string> {
        return new Promise(r => this._UID$.pipe(take(1)).subscribe(r));
    }

    private async generateUID(): Promise<void> {
        if (hwConfig.cordova in window) {
            document.addEventListener("deviceready", async () => {
                await this.initIds();
            });
        } else {
            await this.initIds();
        }
    }

    async loadPlatformSpecificAssets(): Promise<void> {
        return await this.abstraction.loadPlatformSpecificAssets();
    }

    setCachedPlayer(player: PlayerCachedInfo) {
        this.abstraction.setCachedPlayer(player);
    }

    isMobile(): boolean {
        return this.abstraction.isMobile();
    }

    isBrowser(): boolean {
        return this.abstraction.isNative();
    }

    isFocused(): boolean {
        return this.abstraction.isFocused();
    }

    getCamera(): ICameraHardwareAbstraction {
        return this.abstraction.getCameraLayer();
    }

    getMicrophone(): IMicrophoneHardwareAbstraction {
        return this.abstraction.getMicrophoneLayer();
    }

    getGeolocation(): IGeolocationHardwareAbstraction {
        return this.abstraction.getGeolocationLayer();
    }

    getRedirector(): IRedirectHardwareAbstraction {
        return this.abstraction.getRedirectLayer();
    }

    getNetwork(): INetworkHardwareAbstraction {
        return this.abstraction.getNetworkLayer();
    }

    getStorage(): IStorageLayer {
        return this.abstraction.getStorage();
    }

    getVibration(): IVibrationHardwareAbstraction {
        return this.abstraction.getVibrationLayer();
    }

    getFilesystem(): IFileSystemLayer {
        return this.abstraction.getFile();
    }

    getVersion(): IClientVersion {
        return this.abstraction.getVersion();
    }

    async waitUntilUnlock(): Promise<void> {
        return this.abstraction.waitUntilUnlock();
    }

    getDeviceUID(): UID {
        return this.UID;
    }

    getBrowserId() {
        return this.browserId;
    }

    getNotifications(): INotificationHardwareAbstraction {
        return this.abstraction.getNotifications();
    }

    async getPNClientID(): Promise<string> {
        if (!!(window as any).ReactNativeWebView) {
            return this.expoPushNotificationToken;
        }
        return this.abstraction.getPNClientID();
    }

    getDeviceName(): string {
        return this.abstraction.getDeviceName();
    }

    getGenericPlataform(): GenericPlatform {
        return this.abstraction.getGenericPlatform();
    }

    getLocalNotifications(): ILocalNotificationsHardwareAbstraction {
        return this.abstraction.getLocalNotifications();
    }

    onMainHeaderMount(): void {
        this.abstraction.onMainHeaderMounted();
    }

    onMainHeaderUnmount(): void {
        this.abstraction.onMainHeaderUnmounted();
    }

    async beforeNotificationsActivate(): Promise<boolean> {
        return this.abstraction.beforeNotificationsActivate();
    }

    getAudio() {
        return this.abstraction.getAudio();
    }

    getUpdater(): IUpdaterHardwareLayer {
        return this.abstraction.getUpdater();
    }

    getFileOpener() {
        return this.abstraction.getFileOpener();
    }

    private keepEmitNetworkStateToApp(): void {
        this.abstraction.getNetworkLayer().subscribeToOffline(isOffline => {
            if (!isValidAndEqual(this.isOfflineEvent, isOffline)) {
                this.isOfflineEvent = isOffline;
                const int: IInterfaceInfoSignal = {
                    isOffLine: isOffline
                };
                const signal: InterfaceInfoSignal = new InterfaceInfoSignal(int);
                this.publisher.specificSignalEmissorOnGenericStream(signal);
            }
        });
    }

    runOptimizations() {
        this.abstraction.runOptimizations();
    }

    setSocketConnectionOnline(isOnline: boolean): void {
        this.abstraction.setSocketConnectionOnline(isOnline);
    }

    isIOS(): boolean {
        return this.isMobile() && (<CordovaHardwareLayer>this.abstraction).isIOS();
    }

    isAndroid(): boolean {
        return this.isMobile() && (<CordovaHardwareLayer>this.abstraction).isAndroid();
    }

    platformHasPushNotifications(): boolean {
        return this.abstraction.platformHasPushNotifications();
    }

    isAllowedToChangeNotificationsState(): boolean {
        return this.abstraction.isAllowedToChangeNotificationsState();
    }

    getMediaViewer(): IMediaViewer {
        return this.abstraction.getMediaViewer();
    };


    public setSocketServiceDependency(socketService: SocketService): void {
        this.abstraction.getNetworkLayer().setSocketService(socketService);
    };

    public setExpoPushNotificationToken(token: string) {
        this.expoPushNotificationToken = token;
        this.expoPushNotificationToken$.next(token);
    }

    onVisibilityChange(): Observable<IFocusInfo> {
        return this.abstraction.onVisibilityChange();
    }

}
