
<app-ui-carousel
  [autoPlay]="false"
  [arrows]="false"
  [dots]="false"
  height="200px"
  width="300px"
  *ngIf="canShowCarousel()"
  #uiCarousel
>

  <app-ui-carousel-item [canIswipe]="canShowDots()" *ngFor="let media of multimediaList">
    <img
      class="carousel--img"
      *ngIf="canShowPhoto(media) && (getPhoto(media) | imgSecureDownload | async) as multimedia else spinner"
      [src]="getMedia(multimedia)"
      alt="Photo of group"
    />
  </app-ui-carousel-item>
  <div class="dots" *ngIf="canShowDots()">
      <button *ngFor="let media of multimediaList; index as i"
        class="dot" [ngClass]="{'dot--active': uiCarousel.currentItemIndex === i}"
        (click)="uiCarousel.goTo(i); uiCarousel.currentItemIndex = i">
      </button>
  </div>
</app-ui-carousel>

<ng-template #spinner>
  <div class="carousel-spinner">
    <div *cmSpinner="true"></div>
  </div>
</ng-template>

