import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { isInEnum, isValidString } from "../../../../tools/utility";
import { EGlobalFunction, EUserFunctionType, IUserFunctionModelServer } from "../../../user-function/user-function-model";
import { userFunctionErrors } from "../error-fields";
import { screenOptionToError } from "../error-messages";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";

const errors = screenOptionToError(userFunctionErrors);

export const userFunctionValidator: TNserValidator = (nser: IUserFunctionModelServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);
  
  if(!isInEnum(EUserFunctionType, nser.type)) {
    friendly.addReturnResponse(errors.invalidType);
  }

  switch(nser.type) {
    case EUserFunctionType.custom:
      if(!isValidString(nser.customFunctionTS)) {
        friendly.addReturnResponse(errors.invalidCustomCode);
      }
      break;

    case EUserFunctionType.global:
      if(!isInEnum(EGlobalFunction, nser.globalFunction)) {
        friendly.addReturnResponse(errors.invalidGlobalType);
      }
      break;
  }

  return friendly;
}