import {IRedirect, IRedirectHardwareAbstraction} from "../hardware-interfaces";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserRedirect implements IRedirectHardwareAbstraction{
    URI(base: string): string {
        const origin: string = window.location.origin;
        const mustAppendBar: boolean = ! base.startsWith('/');
        return `${origin}${(mustAppendBar) ? '/' : ''}${base}`;
    }

    redirect(options: IRedirect): void {
        window.open(options.url, '_blank').focus();
    }
}
