import { IBotEvent, EBotEventType, IAgentDoesNotAnwer, ITransactionToTransactionConfirmation } from "../../../bot/bot-event-model";
import { IFriendlyExtraMessage } from "../../../../comm-interfaces/business-interfaces";
import { isInvalid, isValidRef, isValidArray } from "../../../../tools/utility";
import { minLength, nserNameMinLength } from "../helpers";
import { validateBotAction } from "./bot-action";
import { eBotEventDB } from "../../../bot/bot-event-config-db";
import { IBasicAsset, KAssetType, KBAssetType } from "../../../bot/bot-asset-model";
import { IContentBasicAsset, IBotActionAPIAsset } from "../../../bot/bot-content-model";
import { isContentAsset } from "../../../bot/bot-function-model-helper";
import { eventInsertTextError, eventInsertMinutesError, eventInsertAttemptsError, eventYesQuestionError, eventYesQuestionSmallError, eventNoQuestionError, eventNoQuestionSmallError, eventYesActionError, eventNoActionError, assetInsertMessageError, assetMessageSmallError, assetMediaIsMissingError, assetRouteIsMissingError } from "../error-messages";

export const validateBotAsset = (asset: IBasicAsset) : Array<IFriendlyExtraMessage> => {
//export const validateBotAsset = (asset: IBasicAsset): Array<String> => {
    let ret : Array<IFriendlyExtraMessage> = [];

    //if is event
    const assetEvents: KAssetType[] = Object.values(EBotEventType) as KAssetType[];
    if (assetEvents.includes(asset.type)) {
        const eventDb = eBotEventDB[asset.type];
        if (isValidRef(eventDb.showOptionText) && isInvalid((<IBotEvent>asset).eventText)) {
            ret.push(eventInsertTextError);
        }
        if (isValidRef(eventDb.hasExpireTime)) {
            if (isInvalid((<IAgentDoesNotAnwer>asset).expireInMinutes)) {
                ret.push(eventInsertMinutesError);
            }
            if (isInvalid((<IAgentDoesNotAnwer>asset).attempts)) {
                ret.push(eventInsertAttemptsError);
            }
        }
        if (isValidArray((<IBotEvent>asset).contentArray)) {
            (<IBotEvent>asset).contentArray.forEach(asset => {
                const validateAsset = validateBotAsset(asset);
                if (isValidArray(validateAsset)) {
                    ret = [
                        ...ret,
                        ...validateAsset
                    ]
                }
            });
        }

        if(isValidRef((<IBotEvent>asset).eventAction) && isValidRef((<IBotEvent>asset).eventAction.type)){
            const errors = validateBotAction((<IBotEvent>asset).eventAction);
            if(isValidArray(errors)){
                ret = [
                    ...ret,
                    ...errors
                ];
            }
        }

        //if is confirmation event
    //     if (asset.type === EBotEventType.onConfirmation) {
    //         const evtConfirmation = <IConfirmationEvent>asset;
    //         if (isInvalid(evtConfirmation.confirm.yesQuestion)) {
    //             ret.push(eventYesQuestionError);
    //         } else if (!minLength(evtConfirmation.confirm.yesQuestion, nserNameMinLength)) {
    //             ret.push(eventYesQuestionSmallError);
    //         }
    //         if (isInvalid(evtConfirmation.confirm.noQuestion)) {
    //             ret.push(eventNoQuestionError);
    //         } else if (!minLength(evtConfirmation.confirm.noQuestion, nserNameMinLength)) {
    //             ret.push(eventNoQuestionSmallError);
    //         }

    //         if (isInvalid(evtConfirmation.confirm.yesAction)) {
    //             ret.push(eventYesActionError);
    //         } else if (isValidArray(validateBotAction(evtConfirmation.confirm.yesAction))) {
    //             ret = [
    //                 ...ret,
    //                 ...validateBotAction(evtConfirmation.confirm.yesAction)
    //             ];
    //         }
    //         if (isInvalid(evtConfirmation.confirm.noAction)) {
    //             ret.push(eventNoActionError);
    //         } else if (isValidArray(validateBotAction(evtConfirmation.confirm.noAction))) {
    //             ret = [
    //                 ...ret,
    //                 ...validateBotAction(evtConfirmation.confirm.noAction)
    //             ];
    //         }
    //     }
    }

    if (isContentAsset(asset.type)) {
        if (asset.type === KBAssetType.content) {
            if (!(<IContentBasicAsset>asset).content) {
                ret.push(assetInsertMessageError)
            } else if (!minLength((<IContentBasicAsset>asset).content, nserNameMinLength)) {
                ret.push(assetMessageSmallError);
            }
        }
        if (asset.type === KBAssetType.media) {
            if(isInvalid((<IContentBasicAsset>asset).media.idMedia)){
                ret.push(assetMediaIsMissingError)
            }
        }
        if (asset.type === KBAssetType.contentAPICall) {
            if(isInvalid((<IBotActionAPIAsset>asset).idElement)){
                ret.push(assetRouteIsMissingError);
            }
        }
    }
    return ret;
}
