import { EDelivery360Action } from "../comm-interfaces/interaction-interfaces";
import { IBotActionAsset } from "../shared-business-rules/bot/bot-action-model";
import { TBotEventArray } from "../shared-business-rules/bot/bot-event-model";
import { EMenuMode, ENextGenBotElementType, IBotMenuContainerServer, IBotMenuItemServer, IBotRootServer, IMenuContainerDisplayOptions, INextGenBotServer } from "../shared-business-rules/bot/bot-model";
import { initBotRootElement } from "../shared-business-rules/bot/bot-function-model-helper";
import { EActionOnComplete } from "../shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { ENonSerializableObjectType, INonSerializable } from "../shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { getReadableUniqueID, typedClone } from "../tools/utility";
import { getBlanckNoIdentifiedNS } from "./blank-ns";
import { asConst } from "@colmeia/core/src/tools/type-utils";
import { emptyContentBasicAsset } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";


export class VitualNonSerializableGenerator {

    public static getNonVirtualNS(nsType: ENonSerializableObjectType, nName: string = ''): INonSerializable {
        return getBlanckNoIdentifiedNS(nsType, nName, false);
    }

    public static getNS<T extends INonSerializable>(nsType: ENonSerializableObjectType, nName: string, isVirtual: boolean): T {
        return getBlanckNoIdentifiedNS(nsType, nName, isVirtual) as T;
    }

    public static getElementBotWithParent(botLevel: ENextGenBotElementType, name: string, events: TBotEventArray, idParent: string, isVirtual: boolean): INextGenBotServer {
        const bot = VitualNonSerializableGenerator.getElementBot(botLevel, name, events, isVirtual)
        bot.idParent = idParent
        return bot
    }

    public static getElementBot<Level extends ENextGenBotElementType>(botLevel: Level, name: string, events: TBotEventArray, isVirtual: boolean) {
        const bot = asConst<INextGenBotServer>()({
            ...VitualNonSerializableGenerator.getNS(ENonSerializableObjectType.bot, name, isVirtual),
            nsType: ENonSerializableObjectType.bot,
            botLevel,
            events,
            content: name,
        })
        return bot;
    }


    public static getMenuContainerNS(name: string, events: TBotEventArray, isVirtual: boolean): IBotMenuContainerServer {

        const defaultDisplay: IMenuContainerDisplayOptions = {
            menuRenderConfigType: EMenuMode.Quick //EFBMenuMode.Adaptative
        };


        return {
            ...VitualNonSerializableGenerator.getElementBot(ENextGenBotElementType.menuContainer, name, events, isVirtual),
            botLevel: ENextGenBotElementType.menuContainer,
            actionOnComplete: EActionOnComplete.keepUntilCommand,
            renderOptions: {
                [EDelivery360Action.Delivery360WhatsApp]: typedClone(defaultDisplay),
                [EDelivery360Action.Delivery360SMS]: typedClone(defaultDisplay),
                [EDelivery360Action.Delivery360FBMessenger]: typedClone(defaultDisplay),
                [EDelivery360Action.Delivery360Email]: typedClone(defaultDisplay),
                [EDelivery360Action.DeliveryInstagram]: typedClone(defaultDisplay),
                [EDelivery360Action.Delivery360Voice]: typedClone(defaultDisplay),
                [EDelivery360Action.DeliveryColmeia]: typedClone(defaultDisplay),
                [EDelivery360Action.DeliveryTelegram]: typedClone(defaultDisplay),
            }
        };
    };

    public static getMenuItemContainer(name: string, action: IBotActionAsset, isVirtual: boolean, description: string | undefined): IBotMenuItemServer {
        return {
            ...VitualNonSerializableGenerator.getElementBot(ENextGenBotElementType.botMenuItem, name, [], isVirtual),
            nsType: ENonSerializableObjectType.bot,
            botLevel: ENextGenBotElementType.botMenuItem,
            description,
            action
        };
    }


    public static getRoot(): IBotRootServer {
        return {
            ...initBotRootElement(),
            idNS: getReadableUniqueID(),
            nsType: ENonSerializableObjectType.bot,
        } as IBotRootServer;
    }


}
