import { MainHandler } from '../main-handler';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { MultimediaTag } from '@colmeia/core/src/multi-media/multi-media-tag';
import { Participant } from '@colmeia/core/src/business/participant';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/multi-media-instance';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { constant } from '@colmeia/core/src/business/constant';
import {TExecutableItemArray} from "../../services/controllers-services/security-controller/executable-item";
import {IComponentParameter} from "../../model/component-comm/basic";
import {ChatBackboneModel} from "../../model/chat-backbone.model";
import {ClientTransactService, IRollbackTransaction, rollbackType} from "../../services/client.transact.service";
import { RecoverySystemService } from 'app/services/recovery-system.service';
import { SessionService } from 'app/services/session.service';

export interface IChatBarParameter extends IComponentParameter {
    multimediaTag: MultimediaTag;
    interactionType: InteractionType;
    editingInteraction: Interaction;
    parentInteraction: Interaction;
    numberOfFilesAllowed: number;
    chatBackboneModel: ChatBackboneModel;
    idGroup: TGlobalUID;
    isEditing: boolean;
    servicePack: {
        rollbackServices: ClientTransactService;
        recoveryService: RecoverySystemService;
        sessionService: SessionService;
    };
    windowComponent: any;
}

export interface IChatBarHandler {
    resetChatBarHandler(): void;
    getPersonalGroupParticipant(): Participant;
    getTimedMessages(): TExecutableItemArray;
    getInteractionType(): InteractionType;
    getInteraction(): Interaction;
    getInteractionParent(): Interaction;
    rollback(rollbackTransaction: IRollbackTransaction): void;
    isEditing(): boolean;
    beforeInteractionSave(interaction: Interaction): Promise<void>;
    saveInteraction(interaction: Interaction,  multimediaInstanceArray: TMultimediaInstanceArray, idGeneralAnswer: TGlobalUID): Promise<FriendlyMessage>;
    afterInteractionSave(interaction: Interaction): Promise<void> ;
    getMultiMediaTagID(): TGlobalUID;
    getNumberOfFilesAllowed(): number;
    getNewInteraction(particpant: Participant): Interaction;

    getComponentWindow(): any;

    getGroupID(): TGlobalUID;

    getParticipant(): Participant;
    getInputPlaceholder(): string;
    onSuccess(): void;

    getRollbackTransaction(): IRollbackTransaction;
    getComponentParameter(): IChatBarParameter;
}

export class ChatbarHandler extends MainHandler implements IChatBarHandler {

    protected rollbackId;

    constructor(parameter: IChatBarParameter) {
        super(parameter);
    };

    public isEditing(): boolean {
        return this.getComponentParameter().isEditing;
    }

    public getComponentParameter(): IChatBarParameter {
        return <IChatBarParameter>super.getComponentParameter();
    }

    public getNewInteraction(particpant: Participant): Interaction {
        return Interaction.getNewInteraction(particpant, this.getInteractionType(), '');
    }

    public getInteraction(): Interaction {
        return this.getComponentParameter().isEditing
            ? this.getEditingInteraction()
            : this.getNewInteraction(this.getParticipant());
    }

    public getGroupID(): TGlobalUID {
        return this.getComponentParameter().idGroup;
    }

    getPersonalGroupParticipant(): Participant {
        return this.getComponentParameter().chatBackboneModel.getPersonalGroupParticipant();
    }

    getTimedMessages(): TExecutableItemArray {
        return this.getComponentParameter().chatBackboneModel.getTimedMessages();
    }

    public getParticipant(): Participant {return this.getComponentParameter().chatBackboneModel.getParticipant()};

    async saveInteraction(interaction: Interaction,  multimediaInstanceArray: TMultimediaInstanceArray): Promise<FriendlyMessage> {
        return this.getComponentParameter().chatBackboneModel.saveInteraction(interaction, multimediaInstanceArray, null);
    }

    resetChatBarHandler(): void {
        // this.getComponentParameter().clientCallback.resetChatBarHandler();
    }

    getMultiMediaTagID(): TGlobalUID {
        return this.getComponentParameter().multimediaTag.getMultiMediaTagID();
    }

    getNumberOfFilesAllowed(): number {
        return this.getComponentParameter().numberOfFilesAllowed;
    }

    getInteractionType(): InteractionType {
        return this.getComponentParameter().interactionType;
    }

    getEditingInteraction(): Interaction {
        return this.getComponentParameter().editingInteraction
    }

    getInteractionParent(): Interaction {
        return this.getComponentParameter().parentInteraction
    }

    getInputPlaceholder(): string {
        return null;
    }

    getComponentWindow(): any {
        return this.getComponentParameter().windowComponent;
    }

    public rollback(rollbackTransaction: IRollbackTransaction): void {
        this.getComponentParameter().servicePack.rollbackServices.rollback(rollbackTransaction);
    }

    async beforeInteractionSave(): Promise<void> {

    }

    async afterInteractionSave(interaction: Interaction): Promise<void> {
        const interactionTypeID: TGlobalUID = interaction
            .getInteractionType()
            .getPrimaryID()
        switch (interactionTypeID) {
            // case constant.interactionType.faceCompany.faceCompany:
            //     this.onFaceCompanyAfterSendToServer();
            //     break;
            default:
                break;
        }
    }

    public onSuccess(): void {}

    public getRollbackTransaction(): IRollbackTransaction {
        return this.getComponentParameter().servicePack.rollbackServices.getRollbackTransaction(this.getInteraction(), rollbackType.interaction);
    }

}
