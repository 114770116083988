import { TInteractionArray } from "@colmeia/core/src/interaction/interaction";

export interface ISharedMsgsViewerClient {
    sharedMsgsViewerModalClosedCallback(): void;
}

export interface ISharedMsgsViewerParameter {
    shares: TInteractionArray;
    clientInstance: ISharedMsgsViewerClient
}

export class SharedMsgsViewerHandler { 
    constructor(private parameters: ISharedMsgsViewerParameter) { }

    getShares(): TInteractionArray {
        return this.parameters.shares
    }

    closeClicked(): void {
        if (this.parameters.clientInstance)
            this.parameters.clientInstance.sharedMsgsViewerModalClosedCallback();
    }
}
