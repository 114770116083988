
import { TGlobalUID, TNserUID, TPostgresPrimaryKey } from '../../core-constants/types';
import { IRequest, IRequestMultipleCursor } from '../../request-interfaces/request-interfaces';
import { IResponse } from '../../request-interfaces/response-interfaces';
import { IServerColmeiaTag } from "../colmeia-tags/tags";
import { IJobPatchServer } from '../jobs/jobs-model';
import { ENonSerializableObjectType, INserMigrationOperation, TENonSerializableObjectArray, TNonSerializableArray } from '../non-serializable-id/non-serializable-id-interfaces';
import { ITagableSearch } from '../non-serializable-id/non-serializable-req-resp';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { IPatchDeployModel, IProdPatchItem } from './prod-deploy-model';



export interface ISavePatchRequest extends IRequest {
    deploy: IPatchDeployModel
};

export interface ISavePatchResponse extends IResponse { };


export interface ISavePatchItemData {
    deployItem: IProdPatchItem;
}
export interface ISavePatchItemRequest extends IRequest, ISavePatchItemData {
}

export interface ISavePatchItemResponse extends IResponse { };

export interface IChangeRequestFilter {
    toBeSelected: TENonSerializableObjectArray;
    tagable?: ITagableSearch;
    fromClock: number;
    toClock: number;
    idColmeiaProvider: TNserUID;
    idRootElement: TNserUID;
    idSocialContextTarget?: TGlobalUID;
}

export interface IGetChangedNSRequest extends IRequestMultipleCursor {
    onlyDiffType: ENSDifference
    filter: IChangeRequestFilter;
    tagable?: ITagableSearch;
};

export enum ENSDifference {
    all = 'all', notExist = 'notExist', different = 'different', equal = 'equall', presentOpenPatch = 'preOpen'
}

export type TEBatchSaveTypeArray = Array<EBatchSaveType>;
export enum EBatchSaveType {
    normal = 'normal',
    recursive = 'recursive',
    hierarchical = 'hierarchical',
}

export interface INSChanges {
    difType: ENSDifference;
    diffString?: string;
}

export interface IDiffMap {
    [idNonSerializable: string]: INSChanges
}

export interface ITagHashByID {
    [idTag: string]: IServerColmeiaTag;
}
export interface IDiffNonSerializableList {
    diffMap: IDiffMap,
    nserList: TNonSerializableArray;
    nserListGroupedByStatus: Record<ENSDifference, TNonSerializableArray>;
}
export interface IGetChangedNSResponse extends IResponse {

    // nserListDiffFromRemoteToLocal: Record<ENSDifference, TNonSerializableArray>
    // candidates: TNonSerializableArray;
    // diffMap?: IDiffMap;
    localEnvInfo: IDiffNonSerializableList,
    remoteEnvInfo: IDiffNonSerializableList,
    // tags: ITagHashByID;
};

export interface IBatchSaveToDeployNserBasicData {
    idNS?: TNserUID,
    nsType: ENonSerializableObjectType,
    nName: string,
    versionName?: string
    versionID?: TPostgresPrimaryKey
}
export interface IBatchSaveToDeployNser extends IBatchSaveToDeployNserBasicData, INserMigrationOperation {
}

export interface IBatchSaveToDeployModelData {
    idPatch: string;
    saveToBatchType: EBatchSaveType;
    nonSerializablesToBeDeployed: IBatchSaveToDeployNser[];
    idSocialContextSource?: TGlobalUID
}

export interface IBatchSaveToDeployModelRequest extends IRequest, IBatchSaveToDeployModelData {

};

export interface IBatchSaveToDeployResponse extends IResponse {
    idJob: string;
}

export interface INewDeployProdDesription {
    name: string;
    description: string;
}

export interface IPromoteToServerData {
    idPatch: string;
    idProvider: IdDep<ENonSerializableObjectType.connection>;
    newDeployDescription: INewDeployProdDesription;
}
export interface IPromoteToServerRequest extends IRequest {
    deployData: IPromoteToServerData
}

export interface IPromoteToServerResponse extends IResponse {
    jobPatchServer: IJobPatchServer
}
