import { Injectable, ElementRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';


interface IEvent {
  event: MouseEvent;
  el: ElementRef;
  links: any[];
  distance: Number;
  type: String;
  status: boolean;
  background: string;
  rightMargin: string;
}

@Injectable({
    providedIn: 'root'
})
export class DropdownMenuService {
  public show: Subject<IEvent> = new Subject();
  private subject = new Subject<any>();

  constructor() { }

  status(message: boolean) {
    this.subject.next({ 'cm_open': message });
  }

  clearMessage() {
    this.subject.next();
  }

  getStatus(): Observable<any> {
    return this.subject.asObservable();
  }

  onClickListener(): Observable<any> {
    return this.show.asObservable();
  }

}
