import { asConst } from "../tools/type-utils";


export const ESCode = asConst<{}>()({
    server1: {
        fromServer: 'fromServer',
        f: {
            universal: {
                avatarDoesNotExist: 8,
                groupDoesNotExist: 9,
                cookieInvalid: 10,
                emailDoesNotExist: 11,
                entityDoesNotExist: 12,
                interactionDoeNotExist: 14,
            },
            genericError: 5,
            cache: {
                noPlayerCached: 1,
            },
            bulk: {
                noSolicitationFound: 4,
                noTemplateExist: 6,
                yourInviteDoesNotExist: 7,

            },
            typeConsistency: {
                interactionWithNoParent: 2,
                interactionTypeMismatch: 15,
            },
            dw: {
                badRoute: 3
            },
            spoke: {
                noSpokeForThisGroup: 13,
            },
            pushNot: {
                pushDoesNotExist: 16,
            },
            security: {
                noPrivileges: 17,
            },
        }
    },
    server2: {
        idSerializable: 'fromServer2',
        infra: {
            cantOpenCookie: 4,
            fileOnStorageDoesNotExist: 6,
            databaseDMLError: 11,
        },
        subscription: {
            notFollowingToCancel: 1,
        },
        signUp: {
            confirmationIDExpired: 2,
            confirmationIDoesNotExist: 3
        },
        share: {
            alreadySharedAsFeature: 5,
            invalidMessageToShareAsFeature: 7,
        },
        command: {
            serviceGroupAlreadyDefined: 8
        },
        mmedia: {
            noFileMultimedia: 9,
        },
        wallet: {
            noFunds: 10,
        }
    },
    server3: {
        idSerializable: 'fromServer3',
        noConnectionOnRequestCache: 4,
        groups: {
            dontHaveAccessOnDestinyGroup: 1,
            noServiceDefinedForThisGroup: 3,
        },
        face: {
            faceAlreadyShared: 2,
        },
        attendent: {
            noIslandAssociatedToAttendent: 5,
        },
        communicationError: 6,
        customerStillInteraction: 7,

    },
    delMessage: {
        idSerializable: 'delMessage',
        cantDeleteThisKindOfInteraction: 1,
        chidrenExist: 2,
        activeAgentsWithCharacteristic: 3,
    }


});

export const errorCodes = asConst<{}>()({
    errorIdentifier: '!#$',
    clientErrorDomain: {
        internalError: '!#$clientInternalError', //!NEW
        unknownError: '!#$unknownError',
    },
    gcloud: {
        cantGetInfoDS: '!#$cantGetInfoDS',
        catSaveInfoDS: '!#$catSaveInfoDS',
        cantFindInteractionDS: '!#$cantFindInteractionDS',
        queryWithErrorsDS: '!#$queryWithErrorsDS', // !NEW
        queryByPredicateError: '!#$queryByPredicateError', // NEW
    },
    system: {
        posProcessmentError: 'posProcessmentError', // !NEW
        highLevelAPIError: '!#$highLevelAPIError', //!NEW 
        cantFindInteractionChild: '!#$cantFindInteractionChild', //!NEW
        responseDoesNotHaveFriendlyError: '!#$responseDoesNotHaveFriendlyError', //!NEW,
        cantBindQueue: '!#$cantBindQueue',
        cantHandleSocketData: '!#$cantHandleSocketData',
        couldNotBindGroupsToSocket: '!#$couldNotBindGroupsToSocket',
        couldNotCreateRabbitQueue: '!#$couldNotBindGroupsToSocket',
        errorDoesNotExist: '!#$errorDoesNotExist', // não foi possível encontrar código em ErrorDomain
        nullSerializableCantBeAddedUber: '!#$nullSerializableCantBeAddedUber',  // não se pode adicionar objetos nulos no cache
        cantProcessPersistencyRequest: '!#$cantProcessPersistencyRequest',
        invalidPersistencyOperation: '!#$invalidPersistencyOperation',  // Objeto passado na rota de persistency tem tipo invalido
        cantValidateSecurityJSONArrayOperation: '!#$cantValidateSecurityJSONArrayOperation', //checkIfOwnData nao trata do tipo de objeto passado,
        unsupportedInteractionTypeClient: '!#$unsupportedInteractionTypeClient',   // A interacao em InteractionPersistoer.insert nao esta programada
        objectTypeIsNotWhatYouExpect: '!#$objectTypeIsNotWhatYouExpect', // object is not what you are expecting
        noGroupSecurityDomain: '!#$noGroupSecurityDomain', // nao existem group security domains 
        noHandleForRequestInEntryPoint: 'noHandleForRequestInEntryPoint',
    },
    player: {
        loginAlreadyExists: '!#$loginAlreadyExists',  //
        avatarDoesNotExist: '!#$avatarDoesNotExist',    // Não existe avatar procurado nem avatar
    },
    connection: {
        statementInvalid: '!#$statementInvalid',    // sql mal criado em executeSQL
        numberOfRowsInvalid: '!#$numberOfRowsInvalid',    // foi validado o nunero de linhas em connection.lineEonforcer
        connectionError: '!#$connectionError',    // Não foi possível initPool ou getConnection
        commitError: '!#$commitError',    // Erro ao comitar
        rowNull: '!#$rowNull',    // a linha está nula.. sem campos.. possível bug
        fieldWithNullValue: '!#$fieldWithNullValue',     // função getValueColumn está avisando que um campo null indevido está sendo processado
        fieldDoesNotExist: '!#$fieldDoesNotExist',     // getValueColum está tentando ler um campo que não existe
        sqlBoundWithNoBinders: '!#$sqlBoundWithNoBinders',     // não foi passado objeto binders {}
        bindVariableNotInBinders: '!#$bindVariableNotInBinders',  // existe um :bind na expressão sql que não foi passado como propriedade do binders
        DMLNotChangedAnything: '!#$DMLNotChangedAnything',     // DML não alterou nada.. e o call para ela força a alteração
        autoIncrementZero: '!#$autoIncrementZero',     // se esperava um autoincremento que não houve.
    },
    session: {
        noPersistedSession: '!#$noPersistedSession',   //
        notLoggedSession: '!#$notLoggedSessio',     // operação inválida sem estar logado
        avatarNotSynchedToPlayer: '!#$avatarNotSynchedToPlayer',     // Na session, player e avatar não se referenciam ao mesmo objeto
        participantNotSynchedToAvatar: '!#$participantNotSynchedToAvatar',  // Na session, avatar e player não se referenciam ao mesmo objeto
    },
    security: {
        roleDoesNotExist: '!#$roleDoesNotExist',     // staticFactory da Role chamado com código errado
        cantFactoryMessageGroupSecurtySettings: '!#$cantFactoryMessageGroupSecurtySettings', // Não faz sentido fazer operação em GroupSecuritySettings
        credentialCheck: '!#$credentialCheck', // erro ao checar credencial
        onlyADMAccessADMGroup: '!#$onlyADMAccessADMGroup', // Tentando entrar em grupo de suporte para administradores sem ser administrador
    },
    interaction: {
        interactionTypeDoesNotExist: '!#$interactionTypeDoesNotExist',
        interactionPairNotAllowed: '!#$interactionPairNotAllowed', // as interações não estão marcadas como consistentes em interaction_allowed
        paramsCannotBeNull: '!#$paramsCannotBeNull', // É necessario passar um id do grupo e um do interaction type para fazer o factory
        treeSearchOperationInvalid: '!#$treeSearchOperationInvalid', // foi passado um tipo de objeto de busca que nao esta programado
    },
    participant: {
        participantDoesNotExistOnGetGroup: '!#$participantDoesNotExistOnGetGroup', // getGroupGroupFromParticipant não conseguiu achar participant,
        cantCreateParticipant: '!#$cantCreateParticipant'
    },
    validationError: {
        emailInvalid: '!#$emailInvalid2',
    },
    others: {
        socialContextDoesNotExist: '!#$socialContextDoesNotExist', // social context não foi encontrado em social_context
    },
    pane: {
        paneDoesNotExists: '!#$paneDoesNotExists'           // StaticFactory do Pane não encontrou ID
    },
    rosetta: {
        textSizeInvalid: '!#$textSizeInvalid',  // tamanho do texto invalido no staticFactory de TextInstance
    },
    search: {
        invalidObjectType: '!#$invalidObjectType',
        invalidRequest: '!#$invalidRequest',
    },
    // TODO colocar só um
    clientErrors: {
        routeDoesNotExist: '!#$routeDoesNotExist'
    },
    client: {
        contract: {
            featureNotCoveredByContract: 'featureNotCoveredByContract',
        },
        handlers: {
            wrongHandlerParameter: '!#$wrongHandlerParameter',
        },
        internalError: 'internalError',
        businessError: 'businessError',
        features: {
            needChoseGroupsOnDistributionList: 'needChoseGroupsOnDistributionList', //!NEW
        },
        rules: {
            spokePersonAreNotAllowed: 'spokePersonAreNotAllowed',
            needAParticipantOnMulti: 'needAParticipantOnMulti',
            ciclicalReferenceOnMultiGroup: 'ciclicalReferenceOnMultiGroup',
            cantSetSpokePersonOnMultiGroup: 'cantSetSpokePersonOnMultiGroup',
            canSetSpokeToAOneToManyGroup: 'canSetSpokeToAOneToManyGroup',
            cantAcceptDistributionList: 'cantAcceptDistributionList', //!NEW
        },
        auth: {
            doesNotAddressGroupAuthenticationDemands: 'doesNotAddressGroupAuthenticationDem', // grupo possui mais demandas de segurança que player possui
            avatarAlreadyExistsOnGroup: 'avatarAlreadyExistsOnGroup', // Player já foi adicionado no grupo.
            nickAlreadyExists: 'nickAlreadyExists', // já existe um avatar com esse nickname
            signUpError: 'signUpError',  // email ou name já utilizados
            socketLoginError: 'loginError',  // login ou password inválidos
            authenticationFailed: 'authenticationFailed',
            emailInvalid: 'emailInvalid',  // email não correto
            weakPassword: 'weakPassword',   // 6 caracteres, um especial, um maiusculo
            participationWithAnotherAvatar: 'participationWithAnotherAvatar', // Há outro avatar do mesmo player no grupo.. proibido em SecurityValidator,
        },
        allow: {
            insufficientRolePrivileges: 'insufficientRolePrivileges', // Role não permite que seja executada a interacao
            interactionOwnerCanUpdateInteraction: '!#$interactionOwnerCanUpdateInteraction', // apenas o owner da interação pode alterá-la.

            cantModifyAnotherPlayer: 'cantModifyAnotherPlayer',  // saveOwnData player está tentando alterar dados de outro player
            cantModifyAnotherAvatar: 'cantModifyAnotherAvatar',  // saveOwnData player está tentando alterar dados de outro player

            adminOnlyCanChangeGroup: 'adminOnlyCanChangeGroup',  // em checkIfOwnData tentando alterar preoduto sem ser adm
            mustBeAParticipantToExecute: 'mustBeAParticipantToExecute', //canGetGroupInteractions: Não é participante ,
            onlyParentsCanBeShared: 'onlyParentsCanBeShared',

            shareInteractionsNotFound: 'shareInteractionsNotFound',
            playerDoesNotHaveParticipantInGroup: 'playerDoesNotHaveParticipantInGroup',
            cantCreateSubGroupHere: 'cantCreateSubGroupHere', // Não é permitido criar subgrupos

            roleDoesNotAllowThisOperation: 'roleDoesNotAllowThisOperation', // A role não permite fazer o tipo de operação canExecuteOperationOnGroup


        },
        subscribe: {
            mustHaveAnInvite: 'mustHaveAnInvite', // Para entrar nesse grupo é necessário ser convidado
            needApprovalRequest: 'needApprovalRequest', // Precisa primeiro solicitar uma aprovação
            waitingForApproval: 'waitingForApproval', // aprovacao ainda nao feita
            deniedSubscription: 'deniedSubscription',   // Administradores negaram a subscrição

        },
        businessQueue: {
            resourceTypeInvalidInResourceSet: '!#$resourceTypeInvalidInResourceSet',
            dateBeyondLimit: '!#$dateBeyondLimit', // A data da requisição passou da data limite em subscribe
            maximumPerRequestExceded: '!#$maximumPerRequestExceded', // Não se pode alocar tantos elementos em uma chamada
            maximumPerAvatarExceded: '!#$maximumPerAvatarExceded', // Não se pode alocar tantos elementos em uma chamada
            requestNotInTimeInterval: '!#$requestNotInTimeInterval', // O intervalo solicitado não está dentro do possibilitado pelo ResourceSet
            noResourceForResourceSet: '!#$noResourceForResourceSet', // não existe recurso para o resourceSet
            cantRehydrateQueue: '!#$cantRehydrateQueue', // não foi possível rehydratar a fila
            couldNotProcessQueueSolicitation: '!#$couldNotProcessQueueSolicitation',
            moreThanOneOpenedQueueEvent: '!#$moreThanOneOpenedQueueEvent',
        },
        timeManagement: {
            noDefaultSlotAvailable: '!#noDefaultSlotAvailable',
            noSlotAvailable: '!#noSlotAvailable',
        },
        connection: {
            clientConnectionError: '!#$clientConnectionError', // generic connection error
            serverResponseIncomplete: '!#$serverResponseIncomplete', // resposta do servidor para cliente incompleta
            timeoutExpired: '!#$timeoutExpired' // some timeout expired
        },
        feedback: {
            feedbackMustBeVote: '!#$feedbackMustBeVote',
            poolFinished: '!#$poolFinished',
            limitToChangeDateExpired: '!#$limitToChangeDateExpired',
            maximumNumberOFVotesReached: '!#$maximumNumberOFVotesReached',
            voteSceneryCouldNotBeFound: '!#$voteSceneryCouldNotBeFound',
            maximumVotePrioritiesExceded: '!#$maximumVotePrioritiesExceded',
            voteInvalid: '!#$voteInvalid',
        },

        serverToClient: {
            daysYouAreLookingOutsideSetup: '!#daysYouAreLookingOutsideSetup',
        },
        multimedia: {
            noAccessToPerformOperation: '!#$noAccessToPerformOperation',
        },
        avatar: {
            telephoneInvalid: '!#$telephoneInvalid',
        },
        safeProgram: {
            weMustHaveAFileOnCacheToUpdate: '!#$weMustHaveAFileOnCacheToUpdate',
        },
    },
    server: {
        bot: {
            processor: {
                formulary: {
                    undefinedCurrentQuestion: 'undefinedCurrentQuestion'
                },
                knowledgeBase: {
                    unexpectedBehavior: 'unexpectedBehavior'
                }
            },
            events: {
                conditionInconsistency: 'conditionInconsistency'
            },
            multiplesMainManifests: 'multiplesMainManifests'
        },
        eventsQueue: {
            dispatcher: {
                inconsistency: 'inconsistency'
            }
        },
        luis: {
            wrongPatternUpsert: 'wrongPatternUpsert'
        }
    },
    coreServer: {
        luis: {
            connectionDontInitialized: 'Initialized'
        }
    },
    waf: { //whatsapp Flow
        validationError: 'validationError',
        wafPersistor: {
            getWAF: 'getWAF', // NS nao encontrado
            getChannel: 'WAF-getChannel', // channel nao adicionado
            insert: 'insert'
        },
        wafController: {
            getHash: 'getHash',
        },
        wafService: {
            createFlow: 'createFlow', // erro ao criar flow
            updateSchema: 'updateSchema', // erro ao atualizar schema
            publish: 'publish', // erro no publish do Flow
            deprecated: 'deprecated', // erro no deprecated do Flow
            deleteFlow: 'deleteFlow', // erro no delete do flow
            throttled: 'throttled', //  flow esta registrado como throttled
            blocked: 'blocked', // flow esta registrado como blocked na meta deve-se ter um update do flow (e/ou) endponit(nao existe) para republicar
            unknown: 'unknown', // erro desconhecido
        },
        mapping: {
            invalidField: 'invalidField', // campo invalido no mapeamento
            unknown: 'unknown', // erro desconhecido no mapeamento
        }
    }
} as const);

