import { Injectable } from '@angular/core';
import { EColmeiaHelpRequestTypes } from '@colmeia/core/src/request-interfaces/message-types';
import { IColmeiaHelpGetAllRequest, IColmeiaHelpGetAllResponse, IColmeiaHelpUpsertOneRequest, IColmeiaHelpUpsertOneResponse } from '@colmeia/core/src/shared-business-rules/colmeia-help/colmeia-help-req-res.dto';
import { TTooltipInfoModelMap, ITooltipInfoModel } from '@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model';
import { ServerCommunicationService } from './server-communication.service';

@Injectable({
    providedIn: 'root'
})
export class HelpTipsService {
    private tipsMap: TTooltipInfoModelMap;

    constructor(
        private api: ServerCommunicationService
    ) { }

    setHelpItems(tipsMap: TTooltipInfoModelMap) {
        this.tipsMap = tipsMap
    }

    public async getHelpItemsFromServer(): Promise<TTooltipInfoModelMap> {
        const response = await this.api.sendRequest<
            IColmeiaHelpGetAllRequest,
            IColmeiaHelpGetAllResponse
        >(EColmeiaHelpRequestTypes.getAll)
            ({});

        this.tipsMap = response?.allHelpItems;
        return this.tipsMap;
    }

    public getTipById(id: string): ITooltipInfoModel {
        return this.tipsMap[id];
    }

    public async upsertTip(tip: ITooltipInfoModel): Promise<TTooltipInfoModelMap> {
        const response = await this.api.sendRequest<
            IColmeiaHelpUpsertOneRequest,
            IColmeiaHelpUpsertOneResponse
        >(EColmeiaHelpRequestTypes.upsertOne)
            ({
                helpItem: tip
            });

        this.tipsMap = response?.allHelpItems
        return this.tipsMap
    }

    public getAllTips(): ITooltipInfoModel[] {
        return Object.values(this.tipsMap);
    }
}
