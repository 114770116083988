<div class="bot-action">
    <cm-bot-action-editor
    [handler]="handler"
    (targetElementLoaded)=targetElementLoaded($event)
    (onActionChange)="onActionChange()"
    (onTargetRemoved)="onTargetRemoved()"
    ></cm-bot-action-editor>

  <app-conditional-expansion-panel *ngIf="canHasCondition" [(active)]="useConditions" label="Usar condições">
    <app-new-condition-editor *ngIf="showConditions" [handler]="conditionHandler"></app-new-condition-editor>
  </app-conditional-expansion-panel>

  <app-conditional-expansion-panel
    *ngIf="canConfigurePosAction"
    [active]="hasPosAction"
    (activeChange)="handlePosActionActive($event)"
    label="Pós Ação">
    <app-bot-action *ngIf="handler.botRoot.posAction && posActionBotHandler" [handler]="posActionBotHandler"></app-bot-action>
  </app-conditional-expansion-panel>
</div>
