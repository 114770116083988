import { Injectable } from '@angular/core';
import { SafeMenuBuilderServices } from '../security-controller/safe-menu-builder';
import { TGlobalUID, constant } from '@colmeia/core/src/business/constant';
import { TInteractionArray, Interaction } from '@colmeia/core/src/interaction/interaction';
import { getPubForGroup, hasVisualizationRestrictions } from '@colmeia/core/src/rules/interaction-filter';
import { isValidArray, isValidRef, isInvalidArray } from '@colmeia/core/src/tools/utility';
import { IInteractionJSON, IPublishGroup } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { SessionService } from "../../session.service";
import { IBasicRenderInfo } from '@colmeia/core/src/business/small-serializable';
import { TFeatureGroupServerArray, IFeatureGroupServer, IFeatureDeployed, TDeployedFeatureArray } from '@colmeia/core/src/shared-business-rules/group-features/group-featues-model';
import { LookupService } from 'app/services/lookup.service';
import { environment } from 'environments/environment-client';
import { GroupType } from '@colmeia/core/src/business/group-type';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { NonSerializableClassForm } from '@colmeia/core/src/business/non-seriallizable-form';
import { INonserializableSchemaResponseServer } from '@colmeia/core/src/general-form/general-form-interface';
import { getRolesUseFromPub, hasDemandedRole } from '@colmeia/core/src/rules/sec-functions';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import {EChatBarExtraFeatures} from "@colmeia/core/src/shared-business-rules/const-text/chat-bar-extra-features";

export interface IExecutableFeature {
    featureItem: IBasicRenderInfo | NonSerializableClassForm
}

export type TFeatureArray = Array<IExecutableFeature>;

export interface IDeployedFeature {
    featureSet: IExecutableFeature;
    actionFeatures: TFeatureArray;
};

export type TDeployFeatures = Array<IDeployedFeature>;

interface ICustomFeature {
    feature: Interaction;
    interactionFeatures: TInteractionArray
};

type TCustomFeatureArray = Array<ICustomFeature>

interface ICustomFeatureDatabase {
    [idGroup: string]: TCustomFeatureArray;
}

@Injectable({
    providedIn: 'root'
})
export class ToolBarServices {

    private featureDB: ICustomFeatureDatabase = {};
    private featureList: TDeployedFeatureArray = [];
    private toolbarFeatures = {};

    constructor(
        private session: SessionService,
        private lookupSvc: LookupService
    ) { }

    public isTollbarFeature(groupId: TGlobalUID, chatInteraction: Interaction): boolean {
        return this.toolbarFeatures[groupId]
            && this.toolbarFeatures[groupId]
                .some((blackInteraction: Interaction) => blackInteraction.iss(chatInteraction))
    }

    public getDeployFeatureFromSmall(): IDeployedFeature {
        return;
    };

    public getDeployFeatureFromSerializable(): IDeployedFeature {
        return;
    };

    public async getFeatureSet(): Promise<TDeployFeatures> {
        const gType: GroupType = this.session.getSelectedGroup().getGroupType();
        const menuBuilder: SafeMenuBuilderServices = this.getMenuBuilder();

        const timedSet: IDeployedFeature = {
            featureSet: {
                featureItem: menuBuilder.getSerializableFeatureFromPrimaryID(EChatBarExtraFeatures.TimedMessage)
            },
            actionFeatures: menuBuilder.getSerializableFeaturesFromScreenGroup(constant.objectType.dueDateType)
                .map(feature => {return {featureItem: feature}})
        };

        const featureSet: TDeployFeatures = [timedSet];
        if (environment.allDevFeatures) {
            const delivery360: IDeployedFeature = this.getDelivery360Feature(menuBuilder);
            featureSet.push(delivery360);
        }

        if (gType.is(constant.groupType.functional.distributionList)) {
            const engagement: IDeployedFeature = {
                featureSet: {
                    featureItem: menuBuilder.getSerializableFeatureFromPrimaryID(EChatBarExtraFeatures.Engagement)
                },

                actionFeatures: menuBuilder.getSerializableFeaturesFromScreenGroup(EChatBarExtraFeatures.Engagement)
                    .map(feature => {return {featureItem: feature}})
            };
            featureSet.push(engagement);

        }

        /// Check whether there are custom features
        const idGroup: TGlobalUID = this.session.getSelectedGroupID();
        const customFeatureList: TCustomFeatureArray = this.featureDB[idGroup];
        const customToRemoveFromDatabase: TCustomFeatureArray = []

        if (isValidArray(customFeatureList)) {
            for (const custom of customFeatureList) {
                const isInCache = UberCache.testCache(custom.feature.getInteractionID())
                if(!isInCache) {
                    customToRemoveFromDatabase.push(custom)
                    continue
                }

                const deploy: IDeployedFeature = {
                    featureSet: {
                        featureItem: menuBuilder.getSerializableFeatureFromPrimaryID(custom.feature.getInteractionID())
                    },
                    actionFeatures: []
                }
                featureSet.push(deploy);
            };
        };

        if(customToRemoveFromDatabase.length) this.removeFromLocalDb(customToRemoveFromDatabase)

        const customFeatureListNons: TDeployedFeatureArray = this.featureList.filter(feature => feature.typeDeployed != ENonSerializableObjectType.faceCompany)
        let customFeatureListNonSerializables : INonserializableSchemaResponseServer[] = []

        if (isValidArray(customFeatureListNons)) {
            const ids: string[] = customFeatureListNons.map((feature: IFeatureDeployed) => feature.idFeatureDeployed);
            customFeatureListNonSerializables = await this.lookupSvc.getBatchNonSerializables<INonserializableSchemaResponseServer[]>(ids);
            customFeatureListNonSerializables.forEach(feature => {

                let nonSer = NonSerializableClassForm.getNonSerializableForm(feature);
                featureSet.push(<IDeployedFeature>{
                    featureSet: <IExecutableFeature>{
                        featureItem: nonSer
                    },
                    actionFeatures: []
                })
            })
        };

        return featureSet;
    }

    private getMenuBuilder(): SafeMenuBuilderServices {
        return new SafeMenuBuilderServices(this.session.getMenuSafeBuilderData());
    }

    public getDelivery360Feature(menuBuilder: SafeMenuBuilderServices = this.getMenuBuilder()): IDeployedFeature {
        return {
            featureSet: {
                featureItem: menuBuilder.getSerializableFeatureFromPrimaryID(EChatBarExtraFeatures.Delivery360)
            },

            actionFeatures: menuBuilder.getSerializableFeaturesFromScreenGroup(EChatBarExtraFeatures.Delivery360)
                .map(feature => {return {featureItem: feature}})
        };
    }

    private removeFromLocalDb(customToRemoveFromDatabase: TCustomFeatureArray): void {
        const idGroup: TGlobalUID = this.session.getSelectedGroupID();
        const customFeatureList: TCustomFeatureArray = this.featureDB[idGroup];

        customToRemoveFromDatabase.forEach(customToRemove => {
            const customToRemoveIdx: number = customFeatureList
                .findIndex(custom => custom.feature.getPrimaryID() == customToRemove.feature.getPrimaryID())
            const foundCustomToRemove: boolean = customToRemoveIdx != -1
            if(foundCustomToRemove) {
                customFeatureList.splice(customToRemoveIdx, 1)
            }
        })
    };

    public addCustomFeatureToGroup(idGroup: TGlobalUID, interactionFeatures: TInteractionArray, groupFeatures: TFeatureGroupServerArray): void {
        if (isInvalidArray(groupFeatures)) {
            return;
        };

        let features : TDeployedFeatureArray = [];

        groupFeatures.forEach((el:IFeatureGroupServer) =>{
            features = features.concat(el.deployedFeatures);
        })

        this.featureList = features;
    };

    public removeCustomFeatureToGroup(interactionFeature: Interaction): void {
        if (!isValidRef(interactionFeature)) {
            return;
        };
        const customFeature: ICustomFeature = {
            feature: interactionFeature,
            interactionFeatures: [],
        }
        this.removeFromLocalDb([customFeature]);
    }

    private checkFeatureAccess(rootInteraction: Interaction, idGroup: TGlobalUID): boolean {
        const rootJSON: IInteractionJSON = rootInteraction.toJSON();
        let canSeeFeature: boolean = true;
        if (hasVisualizationRestrictions(idGroup, rootJSON.idInteractionType, rootJSON) ) {
            const pub: IPublishGroup = getPubForGroup(idGroup, rootJSON.publishingGroups);
            const cached: PlayerCachedInfo = this.session.getPlayerInfoServices().getPlayerInfo();
            canSeeFeature = false;
            const arrayAvatar: TArrayID = cached.getAvatarArrayID();
            let k = 0;
            while (! canSeeFeature && k < arrayAvatar.length) {
                const idAvatar = arrayAvatar[k];
                const roles: TArrayID = cached.getGroupAvatarRoleIDS(idGroup, idAvatar, true);
                canSeeFeature = hasDemandedRole(getRolesUseFromPub(pub), roles);
                ++k;
            };
        };
        return canSeeFeature;
    }

}
