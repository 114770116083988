<ng-content></ng-content>

<div
  class="helper-info-indicator"
  [class]="classes"
  [style]="_indicatorStyle"
  [ngStyle]="{
    'top': topPosition,
    'right': rightPosition,
    'bottom': bottomPosition,
    'left': leftPosition,
  }"
>
  <ng-container *ngIf="isPopover && helperInfo; else materialTooltip">
    <div class="icon-container">
      <mat-icon
        [popover]="helpTip"
        strategy="hover"
        class="mat-{{ hiIconSize }}"
      >
        {{ hiIcon }}
      </mat-icon>
    </div>

    <ng-template #helpTip>
      <help-tip *ngIf="tipHandler" [handler]="tipHandler"></help-tip>
    </ng-template>
  </ng-container>

  <ng-template #materialTooltip>
    <div *ngIf="helperInfo || isIconOnly" class="icon-container">
      <mat-icon
        [matTooltip]="helperInfo"
        matTooltipClass="tooltip-wrapline"
        class="mat-{{ hiIconSize }}"
        [matTooltipPosition]="hiTooltipPosition"
        [matPositionAtOrigin]="hiPositionAtOrigin"
        [matTooltipDisabled]="hiTooltipDisabled"
        [matHideDelay]="hiHideDelay"
        [matShowDelay]="hiShowDelay"
      >
        {{ hiIcon }}
      </mat-icon>
    </div>
  </ng-template>
</div>
