import { Injectable } from '@angular/core';
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { IClientVersion } from "@colmeia/core/src/security/version";
import { getClock, isValidRef } from "@colmeia/core/src/tools/utility";
import {
    GenericPlatform,
    ICameraHardwareAbstraction,
    IFileSystemLayer,
    IGeolocationHardwareAbstraction,
    IHardwareAbstractionLayer,
    ILocalNotificationsHardwareAbstraction,
    IMicrophoneHardwareAbstraction,
    INetworkHardwareAbstraction,
    INotificationHardwareAbstraction,
    IRedirectHardwareAbstraction,
    IStorageLayer,
    IUpdaterHardwareLayer,
    IVibrationHardwareAbstraction
} from '../hardware-interfaces';
import { SharedStorage } from "../shared/SharedStorage";
import { SharedVibration } from "../shared/SharedVibration";
import { CordovaCamera } from "./cordova-camera";
import { cordovaHardwareEvents } from './cordova-events';
import { CordovaFilesystem } from './cordova-filesystem';
import { CordovaGeolocation } from "./cordova-geolocation";
import { CordovaMicrophone } from "./cordova-microphone";
import { CordovaNetwork } from "./cordova-network";
import { CordovaPhoneEvents } from "./cordova-phone-events";
import { CordovaRedirect } from "./cordova-redirect";
import { CordovaNotifications } from "./cordova-notifications";
import { CordovaGeneric } from "./cordova-generic";
import { CordovaLocalNotification } from "./cordova-local-notification";
import { Window } from './cordova-plugins-definitions';
import { EHardwareResourceStatus } from "./vendor/hardware-status";
import { CordovaAudio } from './cordova-audio';
import { EPlatformUpdateType, versionContol } from "@colmeia/core/src/core-constants/version-control";
import { CordovaUpdater } from "./cordova-updater";
import { CordovaFileOpener } from './cordova-file-opener';
import { CordovaMediaViewer } from './cordova-media-viewer';
import { SignalPublisherService } from "../../signal/signal-publisher";
import { UID } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { Observable } from 'rxjs';
import { IFocusInfo } from '@colmeia/core/src/request-interfaces/response-interfaces';
const loadcss = require('fg-loadcss');

declare var window: Window;

/**
 * @deprecated
 */
@Injectable({
    providedIn: 'root'
})
export class CordovaHardwareLayer implements IHardwareAbstractionLayer {

    private static FirstRunKey: string = 'COLMEIA_FIRST_RUN';

    private _isFocused: boolean;

    constructor(
        private signalEmiter: SignalPublisherService,
        private geo: CordovaGeolocation,
        private vibrate: SharedVibration,
        private mic: CordovaMicrophone,
        private storage: SharedStorage,
        private camera: CordovaCamera,
        private file: CordovaFilesystem,
        private net: CordovaNetwork,
        private redirect: CordovaRedirect,
        private notifications: CordovaNotifications,
        private generic: CordovaGeneric,
        private localNotification: CordovaLocalNotification,
        private updater: CordovaUpdater,
        private audio: CordovaAudio,
        private fileOpener: CordovaFileOpener,
        private mediaViewer: CordovaMediaViewer,
    ) {
        this._isFocused = true;
    }

    onVisibilityChange(): Observable<IFocusInfo> {
        throw new Error('Method not implemented.');
    }

    platformHasPushNotifications(): boolean {
        return true;
    }

    isAllowedToChangeNotificationsState(): boolean {
        return true;
    }

    setSocketConnectionOnline(isOnline: boolean): void {
        this.getNetworkLayer().setSocketIsOnline(isOnline);
    }

    getUpdater(): IUpdaterHardwareLayer {
        return this.updater;
    }

    waitUntilUnlock(): Promise<void> {
        return new Promise<void>((resolve) => {
            document.addEventListener('deviceready', () => {
                console.log("COM AOT FOI RAPIDIN");
                window.navigator.splashscreen.hide();
                resolve();
            }, false);
        });
    }

    setCachedPlayer(player: PlayerCachedInfo): void {
        document.addEventListener('deviceready', () => {
            this.geo.setCachedPlayer(player);
        });
    }

    bootListeners(): void {
        document.addEventListener('deviceready', () => {
            document.addEventListener('resume', () => {
                this._isFocused = true;
            }, false);

            document.addEventListener('pause', () => {
                this._isFocused = false;
            }, false);

            // this.enableBackgroundMode();
            this.promptPermissionsIfFirstRun();
            // this.update.onDeviceReady();

            CordovaPhoneEvents.boot(cordovaHardwareEvents, this.signalEmiter);

            window.FirebasePlugin.onMessageReceived(function (notification) {
                console.log("Notification~>", notification);
                window.handleOpenURL(notification.schemaURL);
            });
        });

    }

    isFocused(): boolean {
        return this._isFocused;
    }

    getFile(): IFileSystemLayer {
        return this.file;
    }

    getStorage(): IStorageLayer {
        return this.storage;
    }

    getVibrationLayer(): IVibrationHardwareAbstraction {
        return this.vibrate;
    }

    getCameraLayer(): ICameraHardwareAbstraction {
        return this.camera;
    }

    getGeolocationLayer(): IGeolocationHardwareAbstraction {
        return this.geo;
    }

    getMicrophoneLayer(): IMicrophoneHardwareAbstraction {
        return this.mic;
    }

    getRedirectLayer(): IRedirectHardwareAbstraction {
        return this.redirect;
    }

    isMobile(): boolean {
        return true;
    }

    isNative(): boolean {
        return false;
    }

    getNetworkLayer(): INetworkHardwareAbstraction {
        return this.net;
    }

    private promptPermissionsIfFirstRun(): void {
        if (this.checkIfIsFirstRun()) {
            // ask permissions
            window.localStorage.setItem(CordovaHardwareLayer.FirstRunKey, `${getClock()}`);
        }
    }

    private checkIfIsFirstRun(): boolean {
        return !isValidRef(window.localStorage.getItem(CordovaHardwareLayer.FirstRunKey));
    }

    getVersion(): IClientVersion {
        return {
            buildType: 'mobile',
            build: window['_COLMEIA_CORDOVA_BUILD_'],
            assets: 'latest',
            version: versionContol.version,
            platform: (this.isAndroid() ? EPlatformUpdateType.Android : EPlatformUpdateType.Ios)
        }
    }

    async getDeviceUID(): Promise<UID> {
        const device = (window.device);
        return Promise.resolve(`${device.uuid}-${this.getBrowserId()}` as const);
    }

    getBrowserId(): string {
        const device = (window.device);
        return device?.serial!;
    }

    getPNClientID(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            window.FirebasePlugin.getToken(function (token) {
                resolve(token);
            }, function (error) {
                reject(error);
            });
        });
    }
    getNotifications(): INotificationHardwareAbstraction {
        return this.notifications;
    }

    getDeviceName(): string {
        const manufacturer = window.device.manufacturer;
        const model = window.device.model;
        return `${manufacturer.charAt(0).toUpperCase()}${manufacturer.slice(1)} ${model}`;
    }

    getGenericPlatform(): GenericPlatform {
        return this.generic;
    }

    getLocalNotifications(): ILocalNotificationsHardwareAbstraction {
        return this.localNotification;
    }

    onMainHeaderMounted(): void {
        window.StatusBar.overlaysWebView(false);
        window.StatusBar.backgroundColorByHexString('#262d3d');
    }

    onMainHeaderUnmounted(): void {
        window.StatusBar.overlaysWebView(false);
        window.StatusBar.backgroundColorByHexString('#000000');
    }

    async beforeNotificationsActivate(): Promise<boolean> {
        let result = true;
        if (this.isIOS()) {
            const isAuthorized = await this.notifications.isAllowed();
            if (!isAuthorized) {
                result = (await this.notifications.requestAuthorization() === EHardwareResourceStatus.GRANTED);
            }
        }
        return result;
    }

    getAudio() {
        return this.audio;
    }

    isIOS(): boolean {
        return window.device.platform.toLowerCase() === 'ios';
    }

    isAndroid(): boolean {
        return window.device.platform.toLowerCase() === 'android';
    }

    loadPlatformSpecificAssets() {
        document.addEventListener('deviceready', () => {
            if (this.isAndroid()) {
                loadcss.loadCSS('./assets/emojis/google.css');
            }
        });
    }

    getFileOpener() {
        return this.fileOpener;
    }

    runOptimizations() {
        this.fileOpener.clearCache();
    }
    getMediaViewer() {
        return this.mediaViewer;
    }
}
