

import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { BackupCore } from "@colmeia/core/src/shared-business-rules/ns-backup/backup-req-resp";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { notImplementedSharedFunction } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services.basic-functions";
import { AddStaticClassNameOnStack, assign } from "@colmeia/core/src/tools/barrel-tools";
import { StaticImplement } from "@colmeia/core/src/tools/utility/functions/StaticImplement";
import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";

class ServerService {
    getConfig = (): Promise<BackupCore.ExportedConfig> => {
        notImplementedSharedFunction([]);
    }
    clone(id: IdDep): Promise<INonSerializable[]> {
        notImplementedSharedFunction(id);
    }
}


export namespace BackupSharedService {
    export interface BothService {
        config: BackupCore.ExportedConfig;
    }
}

@StaticImplement<BackupSharedService.BothService>()
@ToStatic
@AddStaticClassNameOnStack
export class BackupSharedService extends toStatic(ServerService) {
    static config: BackupCore.ExportedConfig;
    static async init() {
        assign<BackupSharedService.BothService>(BackupSharedService, {
            config: await BackupSharedService.getConfig(),
        });
    }
}
