<app-loading></app-loading>
<ng-container *ngIf="showMainHeader; else noMainHeader">
  <div class="home-content" [ngClass]="extraClasses">
    <section class="main-header">
      <app-new-main-header></app-new-main-header>
    </section>
    <app-dropdown-menu></app-dropdown-menu>

    <mat-sidenav-container class="content-modules" [@.disabled]="sideNavTemporaryDisabled">
      <mat-sidenav position="start" [mode]="menuMode" [disableClose]="true" id="mat-sidenav-dash-menu">
        <div class="module-nav">
          <div class="left-bar">
            <app-module-nav class="nice-scroll-square-divider"></app-module-nav>
          </div>
          <app-module-nav-menus></app-module-nav-menus>
        </div>
      </mat-sidenav>

      <mat-sidenav-content class="content">
        <section>
          <router-outlet></router-outlet>
        </section>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-container>
<ng-template #noMainHeader>
  <section class="content full-height" #mainContent>
    <router-outlet></router-outlet>
  </section>
</ng-template>
<app-dropdown-global></app-dropdown-global>
<app-image-slider></app-image-slider>
<app-new-notifications-page></app-new-notifications-page>
