import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isValidNumber, isValidObject, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { TAppColorThemes, TButtonAlertConfig } from '@colmeia/core/src/ui/ui.model';


@Component({
    selector: 'button-alert',
    templateUrl: './button-alert.component.html',
    styleUrls: ['./button-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonAlertComponent implements OnInit, OnChanges, AfterViewInit {
    _visible: boolean = false;
    _overrideContent: string;
    _toRight: boolean = false;
    _wrap: boolean = false;
    _theme: TAppColorThemes;

    @Input()
    config: TButtonAlertConfig;

    public classes: string[] = [];

    get visible(): boolean {
        return this._visible;
    }

    @Input('toRight')
    set toRight(value: any) {
        this._toRight = coerceBooleanProperty(value);
    }

    @Input()
    set wrap(value: any) {
        this._wrap = coerceBooleanProperty(value);
    }

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if(isValidObject(this.config)) this.show(this.config);
    }

    ngOnChanges(_changes: SimpleChanges): void {
        this.bind()
    }

    ngAfterViewInit(): void {
        this.bind();
    }

    private bind() {
        const classes: string[] = [];

        if (this._visible) {
            classes.push("visible");
        }

        if (this._toRight) {
            classes.push("toRight");
        }

        if (this._wrap) {
            classes.push("wrap");
        }

        classes.push(this._theme);

        this.classes = classes.filter(Boolean);

        this.cdr.markForCheck();
    }

    private messageDurationTimeout: NodeJS.Timeout;

    show(config: TButtonAlertConfig = {}) {
        if(!isValidObject(config)) return;

        const { message, theme, duration, toRight, wrap } = config;

        this._visible = false;
        this.bind();

        isValidRef(this.messageDurationTimeout) && clearTimeout(this.messageDurationTimeout);

        if (isValidString(message)) {
            this._overrideContent = message;
        }

        if (isValidString(theme)) {
            this._theme = theme;
        }

        if (isValidRef(toRight)) {
            this._toRight = toRight;
        }

        if (isValidRef(wrap)) {
            this._wrap = wrap;
        }

        this.bind();
        this._visible = true;
        this.bind();

        if (isValidNumber(duration)) {
            this.messageDurationTimeout = setTimeout(() => {
                this.hide();
            }, duration);
        }
    }

    public hide() {
        this._visible = false;
        this.bind();
    }
}
