import { Component, OnInit, Inject } from '@angular/core';
import { constant } from "@colmeia/core/src/business/constant";
import { Participant } from "@colmeia/core/src/business/participant";
import { Message } from "@colmeia/core/src/interaction/interactions/standard-interaction";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EBillType } from '@colmeia/core/src/comm-interfaces/finance-interfaces';
import { GroupChatServices } from "../../../services/group-chat.services";
import { SessionService } from "../../../services/session.service";
import { IColmeiaDialogComponentData } from "../dialog/dialog.component";
import { RootComponent } from 'app/components/foundation/root/root.component';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

export interface IChargeDialogHandler {
    onChargeCallback(): void;
}

export interface IChargeDialogData {
    handler: IChargeDialogHandler;
}


@Component({
    selector: 'app-charge-dialog',
    templateUrl: './charge-dialog.component.html',
    styleUrls: ['./charge-dialog.component.scss']
})
export class ChargeDialogComponent extends RootComponent<'addWallet'> implements OnInit {

    private price: string = '0';
    private handler: IChargeDialogHandler;

    constructor(
        private groupSVC: GroupChatServices,
        private session: SessionService,
        @Inject(MAT_DIALOG_DATA) dataFromParentComponent: IColmeiaDialogComponentData<IChargeDialogData>
    ) {
        super({
            ...pickTranslations(gTranslations.fragments, [
                'addWallet',
            ])
        });

        this.handler = dataFromParentComponent.getParamsToChildComponent().handler;
    }

    ngOnInit() {
    }

    setNewValue($event): void {
        this.price = $event;
    }

    async createInteraction(): Promise<void> {

        const participant: Participant = this.session.getCurrentPersonalParticipant();
        const interaction: Message = Message.getNewMessage(participant, 'WALLET CHARGE');

        interaction.setBillableOptions({
            billType: EBillType.wallet,
            price: this.price,
            endOffering: 1,
            maxInstallment: 1,
            quantityOffered: 1
        });

        await this.groupSVC.saveInteraction(interaction, [], null);
        await this.groupSVC.saveReaction(
            interaction.getPrimaryID(),
            interaction.getInteractionType().getPrimaryID(),
            constant.interactionType.products.prePurchase.feedbacks.wantPurchase,
            constant.interactionType.products.prePurchase.billableDriverFeedback,
            participant.getPrimaryID(),
            participant.getGroupID(),
        );

        this.handler.onChargeCallback();
    }
}
