import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EJobProcess } from '@colmeia/core/src/request-interfaces/message-types';
import { EJobStatus, EJOBType, TIJobServerArray } from '@colmeia/core/src/shared-business-rules/jobs/jobs-model';
import { IDeleteJobRequest, IGetJobsRequest, IGetJobsRequestData, IGetJobsResponse, IRescheduleJobRequest, IRescheduleJobResponse } from '@colmeia/core/src/shared-business-rules/jobs/jobs-req-res.dto';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { minToMS } from '@colmeia/core/src/time/time-utl';
import { getClock } from '@colmeia/core/src/tools/utility';
import { DSOperators } from '@colmeia/core/src/tools/utility/functions/DSOperators';
import { NewNotificationsService } from 'app/services/new-notifications.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { JobsListComponent } from '../jobs-list/jobs-list.component';
const DEFINED_30_MIN = minToMS(30);

@Injectable({
    providedIn: 'root'
})
export class JobService {
    constructor(
        private newNotificationsSvc: NewNotificationsService,
        private api: ServerCommunicationService,
        private dialog: MatDialog
    ) { }

    get printJobMessageByTargetIdNS() {
        return this.newNotificationsSvc.printJobMessageByTargetIdNS;
    }

    public async searchRunningJobsFromTarget(idNS: IdDep | undefined) {
        if (!idNS) return [];
        const { nss: jobs } = await NSSharedService.search({
            nsType: ENonSerializableObjectType.job,
            isJobStillInProgress: true,
            idNSTarget: idNS,
            clockTick: DSOperators.gt(getClock() - DEFINED_30_MIN),
        });
        jobs.forEach(job => this.newNotificationsSvc.setJob({ idJob: job.idNS!, inputJob: job }));
        return jobs;
    }

    async getAllJobs(jobData: IGetJobsRequestData): Promise<TIJobServerArray> {
        const result: IGetJobsResponse = <IGetJobsResponse>await this.api.doRequest<IGetJobsRequest>(
            EJobProcess.getAll, { multipleCursor: {}, ...jobData });
        return result.nonSerializableArray
    }

    async getAllRunningJobs(cursor: string, amountItemsPerPage: number): Promise<TIJobServerArray> {
        const jobs: TIJobServerArray = await this.getAllJobs({
            onlyInProgress: true,
            amountItemsPerPage,
            cursor,
        })
        return jobs
    }

    async reschedule(idJob: string, toMS: number): Promise<void> {
        const result: IRescheduleJobResponse = <IRescheduleJobResponse>await this.api.doRequest<IRescheduleJobRequest>(EJobProcess.rescheduleJob, {
            idJob,
            toMS,
        });
    }

    async customGetRequest(request: Partial<IGetJobsRequest> & Required<Pick<IGetJobsRequest, 'amountItemsPerPage'>>) {
        const result: IGetJobsResponse = <IGetJobsResponse>await this.api.doRequest<IGetJobsRequest>(
            EJobProcess.getAll, request as IGetJobsRequest);
        return result.nonSerializableArray
    }

    async showJobsList(jobType: EJOBType, onlyInProgress?: boolean) {
        // const jobs = await this.customGetRequest({
        //     jobType, onlyInProgress
        // });

        const jobs: TIJobServerArray = [{
            idNS: "DMWVMZbEtiCg1l2RlAlWAxtVzxLDvI",
            isVirtual: false,
            ident: {
                genealogy: ["SiVGIlSZH1fSCI4z9rwzLZCNnWPqbG"],
                idAvatar: "ZWaZyJfmOmOxigoAV3q7itaxRKea2n",
                idGroup: "SiVGIlSZH1fSCI4z9rwzLZCNnWPqbG",
            },
            nsType: ENonSerializableObjectType.job,
            nName: "deploy1",
            clockTick: 1623418773344,
            lastTouch: 1623418773375,
            scheduleAt: 0,
            ended: 0,
            jobType: EJOBType.patchApply,
            lastJobTouch: 1623418773344,
            started: 0,
            phaseName: "to be started",
            isRoot: true,
            error: undefined,
            isError: false,
            status: EJobStatus.tobestarted,
            idPlayerStartedList: [],
            isJobStillInProgress: false,
        },
        {
            idNS: "DMWVMZbEtiCg1l2RlAlWAxtVzxLDvL",
            isVirtual: false,
            ident: {
                genealogy: ["SiVGIlSZH1fSCI4z9rwzLZCNnWPqbG"],
                idAvatar: "ZWaZyJfmOmOxigoAV3q7itaxRKea2n",
                idGroup: "SiVGIlSZH1fSCI4z9rwzLZCNnWPqbG",
            },
            nsType: ENonSerializableObjectType.job,
            nName: "deploy1",
            clockTick: 1623418773344,
            lastTouch: 1623418773375,
            scheduleAt: 0,
            ended: 0,
            jobType: EJOBType.patchApply,
            lastJobTouch: 1623418773344,
            started: 0,
            phaseName: "to be started",
            isRoot: true,
            error: undefined,
            isError: false,
            status: EJobStatus.tobestarted,
            idPlayerStartedList: [],
            isJobStillInProgress: false,
        }]

        this.dialog.open<JobsListComponent, TIJobServerArray>(JobsListComponent, {
            panelClass: "average-size",
            maxWidth: "860px",
            data: jobs
        });
    }

    public delete = async (idJob: string): Promise<void> => {
        await this.api.doRequest<IDeleteJobRequest>(EJobProcess.delete, {
            idJob,
        });
    }
}
