import { CrmHostNode } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/crm/crm-host-node';
import { EBPMType } from '../../../BPM/bpm-model';
import { ICRMAgentEventServer } from '../../../crm/crm-services/crm-bpm-model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { BPMConfigToolbarSelector } from '../../toolbar/bpm-config-toolbar-selector';
import { IBasicToolbarElement, TReferencedObject } from '../../toolbar/config-toolbar.types';


export class CrmUserEventHost extends CrmHostNode {

    constructor(private crmUserEvent: ICRMAgentEventServer) {
        super(crmUserEvent)
    }

    static create(crmUserEvent: ICRMAgentEventServer): CrmUserEventHost {
        return new CrmUserEventHost(crmUserEvent)
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return target === ENonSerializableObjectType.crmServiceConfigRoot
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        const toolbarConfig: IBasicToolbarElement = BPMConfigToolbarSelector.getConfigToolbarElements(EBPMType.crm)
            .find(e => e.referencedObject === ENonSerializableObjectType.crmServiceConfigAgentEvent)

        return !!toolbarConfig.canConnectTo[nodeTypeToDrag]?.length;

    }
}
