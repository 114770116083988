import { ValidateEntries } from '@colmeia/core/src/tools/barrel-tools';
import { $ValueOf, AfterNarrow, AllSimpleLiteralTypeNames, AnyOrUnknownTo, Assert, AsyncReturnType, AsyncType, CheckIfIsInEnum, Compute, DeepFindValuesOfType, DeepInfer, DeepMap, DeepPartial, Entries, EntriesHash, FindKeysWithValueOf, FromEntries, GetByKey, HasKey, HashObjectLiteralMetadata, ICreateWrapSlot, IDeferedPromise, IGetTimeInfo, IHashSet, IHashSetNumberIndexed, IHashTableAlias, IIgnoreProperty, IObjectLiteralMetadata, IPluralAndSingularText, IPrintTimeInfoTranslatedText, IsEmpty, IsEqual, IsNever, IsUnknown, ItPropertyType, KeyOf, Narrow, Narrowable, Nullable, Nullish, ObjectLiteralMetadata, Primitive, SpecificOverload, TDeepMap, TFunction, TGenericTypeGuardVerifier, TOcurrencesHash, TPrintTimeInfoShouldHideOptions, ToSimpleLiteralTypeName, ToTuple, ToTypeOf, TurnURLParametersIntoJSON, TypeDeepMap, UnionLast, UnionToArray, UnionToIntersection, ValueOf, _TImplementPrivate, isPrimitive } from '../../utility-types';

export function createMapFromEntries<Hash extends object, IsDeepLiteral extends boolean = true>() {
    type TEntriesHash = EntriesHash<Hash>;

    return <T, V extends Primitive>(source: DeepInfer<T, V> & ValidateEntries<T, Hash, TEntriesHash, IsDeepLiteral>) => {
        type Result = T extends TEntriesHash ? Map<keyof Hash, Hash[keyof Hash]> : never;
        const map: Map<keyof Hash, Hash[keyof Hash]> = new Map(source as TEntriesHash);
        return map as Result;
    }
}