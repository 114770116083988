import { IVibrationHardwareAbstraction, IVibrationParameter } from "../hardware-interfaces";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedVibration implements IVibrationHardwareAbstraction {
    vibrate(param: IVibrationParameter) {
        window.navigator.vibrate(param);
    }
}
