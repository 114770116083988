import { EUnitTypeID } from "../../business/constant.enums";
import { EAllSerializable, getTranslationsFields } from "./all-serializables";
import { EBotEventType } from "../bot/bot-event-model";
import { EBotContentEvent, EMessageRecency } from "../bot/bot-content-model";
import { EServiceChatType } from "../../comm-interfaces/service-group-interfaces";
import { EFaceCompanyServiceType } from "../face-company/ns-facecompany";
import { IdField, typedEntryFields, typedFields, typedScreenOptions } from "../../tools/utility";
import { EMenuMode } from "../bot/bot-model";

export const unityEnumTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.MetadataUnities,
    fields: typedFields<EUnitTypeID>()({
        [EUnitTypeID.avatarInput]: 1,
        [EUnitTypeID.dateType]: 2,
        [EUnitTypeID.groupInput]: 3,
        [EUnitTypeID.logicalType]: 4,
        [EUnitTypeID.multipleChoiceType]: 5,
        [EUnitTypeID.numberType]: 6,
        [EUnitTypeID.objectType]: 7,
        [EUnitTypeID.stringType]: 8,
        [EUnitTypeID.imageType]: 9,
        [EUnitTypeID.geoReferencingOrZipType]: 10, // Geo Referência
    })
}));

export const botEventEnum = typedScreenOptions({
    idSerializable: EAllSerializable.BotEventType,
    fields: {
        [EBotEventType.commandText]: 1,
        [EBotEventType.desambiguation]: 2,
        [EBotEventType.onInvalidOption]: 3,
        [EBotEventType.customerExpire]: 4,
        [EBotEventType.providerExpire]: 5,
        [EBotEventType.preCondition]: 7,
        [EBotEventType.defaultReturnOption]: 8,
        [EBotEventType.onHumanAssignation]: 9,
        [EBotEventType.onIdentificationClient]: 10,
        [EBotEventType.onError]: 11,
        [EBotEventType.onFinish]: 12,
        [EBotEventType.onConfirmation]: 13,
        [EBotEventType.missingInformation]: 14,
        [EMessageRecency.everyTime]: 15,
        [EMessageRecency.once]: 16,
        [EMessageRecency.onceInTheSession]: 17,
        [EMessageRecency.replayAfterDays]: 18,
        [EBotEventType.accuracyEvent]: 19,
        [EBotEventType.onQueueAdd]: 20, // Adicionado a fila
        [EBotEventType.reuseFormInformation]: 21,
        [EBotEventType.isUsefullInsight]: 22,
        [EBotEventType.onQueueNext]: 24, // Próximo na fila
        [EBotEventType.onQueueOnBegin]: 25, // Ao iniciar o atendimento
        [EBotEventType.onQueueOnFinish]: 26, // Ao finalizar o atendimento
        [EBotEventType.onQueueReturn]: 27, // Retorno para fila
        [EBotEventType.onQueueTimeRestriction]: 28, // Restrição de tempo
        [EMessageRecency.conditional]: 29, // Condicional
        [EBotEventType.onCustomerGaveUp]: 30, // Desistência de usuário
        [EBotEventType.onQueueTransfer]: 31, // Transferência de ilha
        [EBotEventType.addressSearch]: 32, // Busca de endereço
        [EBotEventType.onCreatingCredentials]: 33, // Quando estiver criando as credenciais
        [EBotEventType.onAttendentAbsent]: 34, // Quando o atendente está ausente
        [EBotEventType.onRtfInjectionParameter]: 35, // Injeção RTF
        [EBotEventType.onChannelClosedOnAgentChat]: 36, // Reabertura de canal
        [EBotEventType.onMaximumCustomerQueueReach]: 37, // Número máximo de clientes na fila atingido
        [EBotEventType.onAgentNotAvailable]: 38, // Nenhum agente disponível
        [EBotEventType.useFormDataToFieldReuse]: 39, // Reuso de campos
        [EBotContentEvent.optionTitle]: 40, // Título ação menu dinâmico
        [EBotContentEvent.optionDescription]: 41, // Título ação menu dinâmico
    }
} as const);

export const allServiceChatTypeOptions: { [key in EServiceChatType]: number } = {
    [EServiceChatType.inactives]: 1,
    [EServiceChatType.internalSupport]: 2,
    [EServiceChatType.panic]: 3,
    [EServiceChatType.service]: 4,
    [EServiceChatType.socialReceptiveCallcenter]: 5,
    [EServiceChatType.embeddedColmeiaChat]: 6 // @TODO DANIEL CRIAR ISTO.
};

export const serviceChatTypeEnum = typedScreenOptions({
    idSerializable: EAllSerializable.ServiceChatTypeEnum,
    fields: allServiceChatTypeOptions
} as const);

export const allFaceCompanyServiceTypeOptions: { [key in EFaceCompanyServiceType]: number } = {
    [EFaceCompanyServiceType.goToGroup]: 1,
};

export const faceCompanyServiceTypeEnum = typedScreenOptions({
    idSerializable: EAllSerializable.FaceCompanyServiceTypeEnum,
    fields: allFaceCompanyServiceTypeOptions
} as const);


export const menuRenderEnum = typedScreenOptions({
    idSerializable: EAllSerializable.SharedEnumOne,
    fields: typedEntryFields<{ [key in EMenuMode]: IdField }>()([
        [EMenuMode.Adaptative, 1], // Adaptativo
        [EMenuMode.Full, 2], // Completo
        [EMenuMode.Quick, 3], // Instântaneo
        [EMenuMode.Text, 5], // Texto
    ])
});
