import { IAudioHardwareAbstraction } from "../hardware-interfaces";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class BrowserAudio implements IAudioHardwareAbstraction {

    private audioPathMap: Map<string, HTMLAudioElement> = new Map();

    async playSound(path: string): Promise<void> {
        try {
            let audio: HTMLAudioElement = this.audioPathMap.get(path);

            if(!audio) {
                audio = new Audio(path);
                this.audioPathMap.set(path, audio);
            }

            await audio.play();
        } catch (err) {
            // nothing to do just don't throw error
            // return Promise.resolve(undefined);
        }
    }
}
