import { Serializable } from '@colmeia/core/src/business/serializable';
import { templateActionTypeConfig } from '@colmeia/core/src/shared-business-rules/bot/bot-action-config-db';
import { isValidWhatsappNumber } from '@colmeia/core/src/shared-business-rules/social-cc/config-cell';
import * as Joi from 'joi';
import { EDelivery360Action } from "../../../../comm-interfaces/interaction-interfaces";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { EPartialMimeTypes } from '../../../../multi-media/file-interfaces';
import { isValidMedia } from '../../../../rules/mm-functions';
import { suggestions } from '../../../../tools/type-utils';
import { createHash, ETypeOf, groupBy, isInvalidArray, isValidArray, isValidRef, isValidString, isValidURL, pickKeys, values } from "../../../../tools/utility";
import { SimpleDifferences } from "../../../../tools/utility-types";
import { EBotActionType } from "../../../bot/new-bot-action";
import { whatsAppAdvancedTemplateActionErrors, whatsAppSchemaToValidateWhatsAppTemplate } from "../../../const-text/views/social-media";
import { getAmountWhatsAppVariablesOnMessage } from '../../../metadata/metadata-utils';
import { getMimeTypePrefix, isEmailTemplateVariables, isTemplateSent } from "../../../social-media/social-media.functions";
import {
    EEmailTemplateType,
    IAdvancedTemplateAction,
    IEmailTemplate, IEmailTemplateVariables, IGenericTemplateDetails, MAX_WHATSAPP_BUTTONS_PHONE_BUTTON, MAX_WHATSAPP_BUTTONS_QUICK_REPLY_CALL_TO_ACTION,
    MAX_WHATSAPP_BUTTONS_URL_BUTTON,
    MAX_WHATSAPP_TEMPLATE_BODY_SIZE,
    MAX_WHATSAPP_TEMPLATE_NAME,
    TIQuickReplyOptionArray
} from "../../../social-media/social-media.model";
import { ColmeiaTemplateComponents, ICTAConfigOnAsset, IWhatsAppTemplateParameter, IWhatsAppTemplateTemplateComponents, TGenericNSTemplateServer } from "../../../social-media/template-model";
import { getButtonFromTemplate, getURLButtonFromTemplate, isAuthTemplate, isLtoTemplate } from '../../../social-media/template.functions';
import { WhatsApp } from "../../../social-media/whatsapp-interface";
import { ITranslationConfig } from "../../../translation/translation-engine";
import { assertEntity, EAdditionalTypeOf, entityNameMinLength, TMapAllAssertEntityField } from '../helpers';
import { $Joi } from "../joi-validator";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";


export const templateNserValidator: TNserValidator = (nser: TGenericNSTemplateServer, friendly: FriendlyMessage) => {
    defaultNserValidator(nser, friendly);

    validateTemplate(friendly)(nser.parameter)

    switch (nser.parameter.channelType) {
        case EDelivery360Action.Delivery360WhatsApp: {
            validateWhatsAppTemplate(friendly, nser.parameter);
        }
            break;
        case EDelivery360Action.Delivery360Email: {
            validateEmailTemplate(friendly, nser.parameter);
        }
            break;
    }

    return friendly;
}



export type ToValidateWhatsAppTemplate =
    & Pick<
        SimpleDifferences<IGenericTemplateDetails, IWhatsAppTemplateParameter>,
        | 'template'
        | 'language'
        | 'category'
    >
    & Pick<IGenericTemplateDetails, 'name'>
    ;


export const templatePatterns = createHash<{ [key in keyof ToValidateWhatsAppTemplate]?: RegExp }>()({
    name: /^((?![A-Z])(?=\w)[^])+$/, // must be composed only of lowercase letters, numbers and underscores
})


const $pickWhatsAppSchemaToValidateWhatsAppTemplate = pickKeys<typeof whatsAppSchemaToValidateWhatsAppTemplate>()

export const schemaToValidateWhatsAppTemplate = ({
    name:
        $Joi
            .string()
            .pattern(templatePatterns.name)
            .max(MAX_WHATSAPP_TEMPLATE_NAME)
            .required()
            .empty('')
            .messages({
                "string.pattern.base": $pickWhatsAppSchemaToValidateWhatsAppTemplate.namePattern,
                'any.required': 'Nome do template não pode estar vazio.',
                'any.empty': 'Nome do template não pode estar vazio.',
            })
    ,
    language:
        $Joi
            .string()
            .valid(...values(WhatsApp.Template.Language))
            .required()
            .messages({
                'string.valid': $pickWhatsAppSchemaToValidateWhatsAppTemplate.languageValid,
                'any.required': 'Idioma do template não pode estar vazio.',
            })
    ,
    category:
        $Joi
            .string()
            .valid(...values(WhatsApp.Template.Categories))
            .required()
            .messages({
                'string.valid': $pickWhatsAppSchemaToValidateWhatsAppTemplate.categoryValid,
                'any.required': 'Categoria do template não pode estar vazia.',
            })
    ,
    template: // Coloquei esse campo por ultimo para que a validação dele seja a ultima a ser feita pelo pacote Joi.
        $Joi.string()
            .required()
            .empty('')
            .messages({
                'any.required': 'Corpo do template não pode estar vazio.',
                'any.empty': 'Corpo do template não pode estar vazio.',
            })
    ,
})

// 

const formatToMimePrefix: { [key in WhatsApp.Message.Template.Structure.Format]?: EPartialMimeTypes } = {
    [WhatsApp.Message.Template.Structure.Format.Image]: EPartialMimeTypes.Image,
    [WhatsApp.Message.Template.Structure.Format.Video]: EPartialMimeTypes.Video,
}

const allowedFormats: Set<string | undefined> = new Set(values(formatToMimePrefix))


export function validateWhatsAppTemplate(friendly: FriendlyMessage, template: IWhatsAppTemplateParameter) {

    if (isTemplateSent(template)) return;

    const validation = Joi.object(schemaToValidateWhatsAppTemplate).unknown(true);

    const messages = validation.validate(template);
    const message: string = messages?.error?.message;

    const errorMessages: string[] = [];
    const errorTranslations: ITranslationConfig[] = [];

    if (isValidString(message)) {
        let translationConfig: ITranslationConfig = whatsAppSchemaToValidateWhatsAppTemplate[message];
        if (isValidRef(translationConfig)) {
            friendly.addReturnTranslationResponse(
                translationConfig
            )
        } else {
            errorMessages.push(message)
        }
    }

    if (template.template) {
        if (isAuthTemplate(template)) {
            if (!stringHasOnlyCanonical(template.template))
                errorMessages.push('O Corpo do Template precisa conter apenas o Significado que representará o CÓDIGO a ser preenchido.');
        } else if (template.template.endsWith('}}'))
            errorMessages.push('O template não pode terminar com uma variável.');
    }

    validateComponents();

    if (isValidArray(template?.advancedOption?.quickReplyOptions)) {
        validateAdvancedTemplateOption(template.advancedOption);
    }

    if (isValidArray(errorMessages)) {
        friendly.addReturnTranslationResponse(
            whatsAppAdvancedTemplateActionErrors.invalidTemplate,
            makeErrorMessage(errorMessages),
        )
    }

    if (isValidArray(errorTranslations)) {
        errorTranslations.forEach(translation => friendly.addReturnTranslationResponse(
            translation
        ))
    }

    function validateComponents() {
        const header = template.header
        if (header) {
            const isText = header?.format === WhatsApp.Message.Template.Structure.Format.Text;
            const content = header.content;

            if (isText) {
                if (!content.variablesTemplate?.compiledTemplate) {
                    errorMessages.push('Cabeçalho sem conteúdo');
                    return;
                }

                if ((content.variablesTemplate?.variables) && (content.variablesTemplate?.variables?.length > 1)) {
                    errorMessages.push('O cabeçalho só pode ter até 1 variável.');
                    return;
                }
            } else {
                if (!isValidMedia(content.media)) {
                    errorMessages.push('Cabeçalho sem mídia');
                    return;
                }

                const prefix = formatToMimePrefix[header.format];
                const mimePrefix = getMimeTypePrefix(content.media?.mymeType);

                if ((isValidRef(prefix) && mimePrefix !== prefix) || (!prefix && allowedFormats.has(mimePrefix))) {
                    errorMessages.push('Formato de mídia inválido');
                }
            }
        }

        if (isAuthTemplate(template) && isInvalidArray(template.allVariables)) {
            errorMessages.push('Adicione um Significado para representar o CÓDIGO a ser preenchido.');
        }

        if (isLtoTemplate(template)) {
            const urlBtn = getURLButtonFromTemplate(template);
            const urlBtnIndex = template.advancedOption.quickReplyOptions.indexOf(urlBtn);

            const copyCodeBtn = getButtonFromTemplate(template.advancedOption, EBotActionType.copyCode);
            const copyCodeBtnIndex = template.advancedOption.quickReplyOptions.indexOf(copyCodeBtn);

            if (!urlBtn) {
                errorMessages.push('Template com oferta por tempo limitado deve conter pelo menos 1 botão VISITAR SITE.');
                return;
            }

            if (copyCodeBtn) {
                if (copyCodeBtnIndex !== 0 || urlBtnIndex !== 1) {
                    errorMessages.push('O botão VISITAR SITE da template com oferta por tempo limitado deve vir logo após o botão COPIAR CÓDIGO.');
                    return;
                }
            } else {
                if (urlBtnIndex !== 0) {
                    errorMessages.push('O primeiro botão da template com oferta por tempo limitado deve ser VISITAR SITE.');
                    return;
                }
            }

            if (template.footer) {
                errorMessages.push('Template com oferta por tempo limitado não pode conter RODAPÉ.');
            }

            if (!isValidString(template.limitedTimeOffer.text)) {
                errorMessages.push('Texto da oferta por tempo limitado inválido.');
            }
            
            if (template.limitedTimeOffer.hasExpiration && !isValidString(template.limitedTimeOffer.idCanonicalExpirationDate)) {
                errorMessages.push('Selecione um significado para o TEMPO DE EXPIRAÇÃO da oferta por tempo limitado.');
            }
        }

        if (template.allVariables?.some(item => !template.variablesExamples[item.idProperty])) {
            errorMessages.push('Existem exemplos de variáveis não preenchidos.');
        }

        if (template.footer && !template.footer.content.variablesTemplate?.compiledTemplate) {
            errorMessages.push('Footer sem conteúdo');
        }
    }

    function validateAdvancedTemplateOption(advancedOption: IAdvancedTemplateAction) {
        if (isValidArray(advancedOption.quickReplyOptions)) {
            if (advancedOption.quickReplyOptions.length > MAX_WHATSAPP_BUTTONS_QUICK_REPLY_CALL_TO_ACTION) {
                errorTranslations.push(whatsAppAdvancedTemplateActionErrors.allowMaxTenButtonsOnTemplate)
            }

            const groupedItems = groupBy(advancedOption.quickReplyOptions, (item) => item.action.type);

            if (groupedItems.quickCallPhone?.length > MAX_WHATSAPP_BUTTONS_PHONE_BUTTON) {
                errorTranslations.push(whatsAppAdvancedTemplateActionErrors.allowMaxOnePhoneButton);
            }

            if (groupedItems.quickVisitSite?.length > MAX_WHATSAPP_BUTTONS_URL_BUTTON) {
                errorTranslations.push(whatsAppAdvancedTemplateActionErrors.allowMaxTwoURLButtons);
            }

            if (!validateButtonsGrouping(advancedOption.quickReplyOptions)) {
                errorTranslations.push(whatsAppAdvancedTemplateActionErrors.invalidButtonGrouping);
            }

            advancedOption.quickReplyOptions.forEach(option => {
                switch (option.action.type) {
                    case EBotActionType.quickCallPhone: {
                        const isValueValidNumber = isValidWhatsappNumber(option.buttonData);

                        if (!isValueValidNumber) {
                            errorTranslations.push(whatsAppAdvancedTemplateActionErrors.invalidButtonPhoneNumber)
                        }
                    }
                        break;
                    case EBotActionType.quickVisitWebsite: {
                        const isValueValidURL: boolean = isValidURL(option.buttonData);
                        if (!isValueValidURL) {
                            errorTranslations.push(whatsAppAdvancedTemplateActionErrors.invalidButtonURL)
                        }

                        if (option.buttonData) {
                            const errorMessage = validateURLButtonData(option.buttonData);
                            if (errorMessage) errorMessages.push(errorMessage);
                        }

                    }
                        break;
                    case EBotActionType.otpCopyCode:
                        if (!stringHasOnlyCanonical(option.buttonData))
                            errorMessages.push('O botão precisa conter apenas o Significado que representará o CÓDIGO a ser preenchido.');
                        break;
                }
            });
        }
    }

}

// export function validateWhatsAppTemplate(friendly: FriendlyMessage, template: IWhatsAppTemplateParameter) {

//     if (isTemplateSent(template)) return;

//     const validation = Joi.object(schemaToValidateWhatsAppTemplate).unknown(true);

//     const messages = validation.validate(template);
//     const message: string = messages?.error?.message;

//     const errorMessages: string[] = [];
//     const errorTranslations: ITranslationConfig[] = [];

//     if (isValidString(message)) {
//         let translationConfig: ITranslationConfig = whatsAppSchemaToValidateWhatsAppTemplate[message];
//         if (isValidRef(translationConfig)) {
//             friendly.addReturnTranslationResponse(
//                 translationConfig
//             )
//         } else {
//             errorMessages.push(message)
//         }
//     }

//     if (template.template) {
//         if (isAuthTemplate(template)) {
//             if (!stringHasOnlyCanonical(template.template))
//                 errorMessages.push('O Corpo do Template precisa conter apenas o Significado que representará o CÓDIGO a ser preenchido.');
//         } else if (template.template.endsWith('}}'))
//             errorMessages.push('O template não pode terminar com uma variável.');
//     }

//     validateWhatsAppTemplateComponents(template, errorMessages);
//     validateTemplateVariables();


//     if (isValidArray(template?.advancedOption?.quickReplyOptions)) {
//         validateAdvancedTemplateOption(template.advancedOption?.quickReplyOptions!, errorMessages);
//     }

//     if (isValidArray(errorMessages)) {
//         friendly.addReturnTranslationResponse(
//             whatsAppAdvancedTemplateActionErrors.invalidTemplate,
//             makeErrorMessage(errorMessages),
//         )
//     }

//     if (isValidArray(errorTranslations)) {
//         errorTranslations.forEach(translation => friendly.addReturnTranslationResponse(
//             translation
//         ))
//     }


//     function validateTemplateVariables() {
//         if (isAuthTemplate(template) && isInvalidArray(template.allVariables)) {
//             errorMessages.push('Adicione um Significado para representar o CÓDIGO a ser preenchido.');
//         }

//         if (template.allVariables?.some(item => !template.variablesExamples[item.idProperty])) {
//             errorMessages.push('Existem exemplos de variáveis não preenchidos.');
//         }
//     }
// }

// function validateAdvancedTemplateOption(
//     quickReplyOptions: TIQuickReplyOptionArray,
//     errorMessages: string[] = [],
// ): string[] {
//     if (isInvalidArray(quickReplyOptions)) return errorMessages;

//     if (quickReplyOptions.length > MAX_WHATSAPP_BUTTONS_QUICK_REPLY_CALL_TO_ACTION) {
//         addErrorTranslation(whatsAppAdvancedTemplateActionErrors.allowMaxTenButtonsOnTemplate)
//     }

//     const groupedItems = groupBy(quickReplyOptions, (item) => item.action.type);

//     if (groupedItems.quickCallPhone?.length > MAX_WHATSAPP_BUTTONS_PHONE_BUTTON) {
//         addErrorTranslation(whatsAppAdvancedTemplateActionErrors.allowMaxOnePhoneButton);
//     }

//     if (groupedItems.quickVisitSite?.length > MAX_WHATSAPP_BUTTONS_URL_BUTTON) {
//         addErrorTranslation(whatsAppAdvancedTemplateActionErrors.allowMaxTwoURLButtons);
//     }

//     if (!validateButtonsGrouping(quickReplyOptions)) {
//         addErrorTranslation(whatsAppAdvancedTemplateActionErrors.invalidButtonGrouping);
//     }

//     quickReplyOptions.forEach(option => {
//         switch (option.action.type) {
//             case EBotActionType.quickCallPhone: {
//                 const isValueValidNumber = isValidWhatsappNumber(option.buttonData);

//                 if (!isValueValidNumber) {
//                     addErrorTranslation(whatsAppAdvancedTemplateActionErrors.invalidButtonPhoneNumber)
//                 }
//             }
//                 break;
//             case EBotActionType.quickVisitWebsite: {
//                 const isValueValidURL: boolean = isValidURL(option.buttonData);
//                 if (!isValueValidURL) {
//                     addErrorTranslation(whatsAppAdvancedTemplateActionErrors.invalidButtonURL)
//                 }

//                 if (option.buttonData) {
//                     const errorMessage = validateURLButtonData(option.buttonData);
//                     if (errorMessage) errorMessages.push(errorMessage);
//                 }

//             }
//                 break;
//             case EBotActionType.otpCopyCode:
//                 if (!stringHasOnlyCanonical(option.buttonData))
//                     errorMessages.push('O botão precisa conter apenas o Significado que representará o CÓDIGO a ser preenchido.');
//                 break;
//         }
//     });
    
//     return errorMessages;

//     function addErrorTranslation(error: ITranslationConfig) {
//         errorMessages.push(Serializable.getTranslation(error));
//     }
// }


export function validateWhatsAppTemplateComponents(
    template: IWhatsAppTemplateTemplateComponents,
    errorMessages: string[] = [],
): string[] {
    const header = template.header;
    
    validateHeader();

    if (validateComponent('Footer', template.footer)) {
        return errorMessages;
    }

    return errorMessages;


    function validateHeader() {
        if (!header) return;
        if (isDisabledComponent(header)) return;

        const isText = header?.format === WhatsApp.Message.Template.Structure.Format.Text;
        const content = header.content;

        if (!header.format) {
            errorMessages.push('Cabeçalho sem formato');
            return;
        }

        if (isText) {
            if (validateComponent('Cabeçalho', header)) {
                return errorMessages;
            }

            if ((content.variablesTemplate?.variables) && (content.variablesTemplate?.variables?.length > 1)) {
                errorMessages.push('O cabeçalho só pode ter até 1 variável.');
                return errorMessages;
            }

            return errorMessages;
        }


        if (!isValidMedia(content?.media)) {
            errorMessages.push('Cabeçalho sem mídia');
            return errorMessages;
        }

        const prefix = formatToMimePrefix[header.format];
        const mimePrefix = getMimeTypePrefix(content.media?.mymeType);

        if ((isValidRef(prefix) && mimePrefix !== prefix) || (!prefix && allowedFormats.has(mimePrefix))) {
            errorMessages.push('Formato de mídia inválido');
        }
    }

    function validateComponent(name: string, component: ColmeiaTemplateComponents.Base | undefined) {
        if (isDisabledComponent(component)) {
            return;
        }

        if (!component?.content?.variablesTemplate?.compiledTemplate) {
            const message = `${name} sem conteúdo`;
            errorMessages.push(message);
            return message;
        }
    }
}


export function validateWhatsAppCTA(cta: ICTAConfigOnAsset) {
    const errorMessages: string[] = [
        ...validateWhatsAppTemplateComponents(cta),
    ];

    if (!validateButtonsGrouping(cta.buttons)) {
        return addError(Serializable.getTranslation(whatsAppAdvancedTemplateActionErrors.invalidButtonGrouping)!);
    }

    if (cta.buttons.length !== 1) {
        return addError('A quantidade de botões deve ser 1');
    }

    if (cta.buttons.some(item => item.action.type !== EBotActionType.quickVisitWebsite)) {
        return addError('Somente botões do tipo URL são permitidos');
    }

    
    return errorMessages;

    function addError(message: string) {
        errorMessages.push(message);
        return errorMessages;
    }
}


export function isDisabledComponent(component: ColmeiaTemplateComponents.Base | undefined) {
    return component ? component.shouldShow === false : true;
}

export function stringHasOnlyCanonical(value: string): boolean {
    const pattern = /^{{[^{}]+}}$/;
    return pattern.test(value);
}


export function validateURLButtonData(buttonData: string) {
    const amountVariables = getAmountWhatsAppVariablesOnMessage(buttonData);
    if (amountVariables > 1) {
        return 'Máximo de 1 variável no botão';
    }
}

function validateEmailTemplate(friendly: FriendlyMessage, template: IEmailTemplate): void {
    assertEntity<IEmailTemplate, IGenericTemplateDetails>({
        idEmailTemplate: defaultStringValidation,
        type: {
            type: EAdditionalTypeOf.Enum,
            isRequired: true,
            literalJoi: {
                entity: EEmailTemplateType,
            },
        },
    }, friendly)(template)

    if (isEmailTemplateVariables(template)) {
        assertEntity<IEmailTemplateVariables, IEmailTemplate>({
            localCanonicals: {
                type: ETypeOf.Array,
                isRequired: true,
            },
            title: {
                type: ETypeOf.Object,
                isRequired: true,
            },
        }, friendly)(template)
    }
}

function makeErrorMessage(messages: string[]): string {
    return messages.map(message => `\n${message}`).join('')
}


const defaultStringValidation = suggestions<TMapAllAssertEntityField[ETypeOf.String]>()({
    type: ETypeOf.String,
    isRequired: true,
    literalJoi: {
        min: entityNameMinLength,
    },
} as const)

function validateTemplate(friendly: FriendlyMessage) {
    return (template: IGenericTemplateDetails): void => assertEntity<Pick<IGenericTemplateDetails, 'name'>>({
        name: defaultStringValidation,
    }, friendly)(template)
}

/**
 * verifica se os botões tipo quick reply e os não quick reply estão agrupados corretamente (um grupo após o outro)
 */
function validateButtonsGrouping(buttons: TIQuickReplyOptionArray): boolean {
    for (let i = 0, isReadingSecondGroup = false; i < buttons.length; i++) {
        if (i >= buttons.length - 1) {
            break;
        }

        const action = buttons[i].action;
        const nextAction = buttons[i + 1].action;

        const templateOptionType1 = templateActionTypeConfig[action.type].templateOptionType;
        const templateOptionType2 = templateActionTypeConfig[nextAction.type].templateOptionType;

        if (templateOptionType1 !== templateOptionType2) {
            if (isReadingSecondGroup) {
                return false;
            } else {
                isReadingSecondGroup = true;
            }
        }
    }

    return true;
}