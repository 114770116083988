export type TWeekdaysArray = Array<WeekDay>;

export type TWeekdayArray = Array<WeekDay>;

export class WeekDay {

    private static dayArray: TWeekdayArray = [new WeekDay(0,true), new WeekDay(1,false), new WeekDay(2,false), 
        new WeekDay(3,false), new WeekDay(4,false), new WeekDay(5,false), new WeekDay(6,true)] 

    private day: number;
    private weekend: boolean;

    private constructor(day: number, weekEnd: boolean) {
        this.day = day;
        this.weekend = this.weekend;
    };

    public getDay(): number {return this.day;};
    public isWeekend(): boolean {return this.weekend;};

    public static getWeekDay(day: number): WeekDay {
        return WeekDay.dayArray[day];
    };

    public static getAllWeekDays(): TWeekdayArray {
        return WeekDay.dayArray;
    };   

    public static getDaysArrayFromDaysID(...dayArray: Array<number>): TWeekdayArray {
        let array: TWeekdayArray = [];
        for (let day of dayArray) {
            array.push(WeekDay.getWeekDay(day));
        };
        return array;
    };


    public static getWeekDayOf(weekend: boolean): TWeekdayArray {
        return WeekDay.getAllWeekDays().filter((d) => {return d.weekend === weekend})
    }



};