import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { MultimediaObject } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { getSocialCCThreadId } from '@colmeia/core/src/rules/thread-conversation-functions';
import { EIslandType } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { isInvalid, isValidString } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ServiceRequestLocationRetrieverComponent } from 'app/components/maps/service-request-location-retriever/service-request-location-retriever.component';
import { EHexagonSizes, HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { IMapsHandler } from 'app/handlers/maps-handler';
import { IBotChatMessage, IGroupServiceParameter } from 'app/model/group-service.model';
import { routeID } from 'app/model/routes/route-constants';
import { DateService } from 'app/services/date.service';
import { RoutingService } from 'app/services/routing.service';
import { SessionService } from 'app/services/session.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupServiceBotHistoryComponent, GroupServiceBotHistoryParameters } from 'app/components/chat/message-container/message-instance/group-service/group-service-bot-history/group-service-bot-history.component';
import { GroupServiceFormHistoryComponent } from 'app/components/chat/message-container/message-instance/group-service/group-service-form-history/group-service-form-history.component';
import { MessageInstance } from 'app/components/chat/message-container/message-instance/message-instance.component';
import { HardwareLayerService } from 'app/services/hardware';
import { AttendanceService } from 'app/services/attendance.service';
import { IServiceIslandServer } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { LookupService } from 'app/services/lookup.service';
import { ChatAtAGlanceComponent } from 'app/components/chat-at-a-glance/chat-at-a-glance.component';
import { IAttendentServicePackClient } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';

@Component({
    selector: 'app-group-service',
    templateUrl: './group-service.component.html',
    styleUrls: ['./group-service.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupServiceComponent extends RootComponent<
    'botHistory' | 'startingService' | 'formHistory' | 'see'
> implements OnInit {
    public mapsHandler: IMapsHandler;
    public hexagonHandler: HandlerHexagonon;

    @Input()
    groupServiceParameter: IGroupServiceParameter;

    public title: string;
    public time: string;
    public nickName: string;
    public iconUrl: string;
    public message: string;
    public agentName: string | undefined;
    public island: IServiceIslandServer;
    public wasTransfered: boolean; // atendimento veio de uma transferência

    public canShowMoreOptions: boolean = false;
    public isFinished: boolean;
    public isMobile: boolean;

    public botThreadId: string = null;
    private botHistoryCache: Array<IBotChatMessage> | undefined;

    private attendentServicePack: IAttendentServicePackClient;

    constructor(
        private dateSvc: DateService,
        private sessionSvc: SessionService,
        private routingSvc: RoutingService,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        private hw: HardwareLayerService,
        private attendanceSvc: AttendanceService,
        private lookupSvc: LookupService
    ) {
        super({
            botHistory: gTranslations.fragments.botHistory,
            startingService: gTranslations.fragments.startingService,
            formHistory: gTranslations.fragments.formHistory,
            'see': gTranslations.common.see,
        });

        this.isMobile = this.hw.isMobile();
    }

    async ngOnInit() {
        this.buildHexagonHandler();
        this.isFinished = this.groupServiceParameter.interaction.isFinished(this.sessionSvc.getSelectedGroupID());
        this.time = this.dateSvc.getBestDate(new Date(this.groupServiceParameter.interaction.getClockTick()));
        this.nickName = this.groupServiceParameter.interaction.getParticipant().getName();

        this.iconUrl = this.getIconUrl();

        this.botThreadId = getSocialCCThreadId(this.groupServiceParameter.interaction);

        this.title = this.isInternalSupportMessage ? 'Suporte Interno' : 'Início de Atendimento';
        this.message = this.interaction.getMessage();
        this.agentName = this.interaction.getAgentName();
        this.island = this.attendanceSvc.getIsland(this.interaction.getIdIsland());
        this.wasTransfered = isValidString(this.interaction.getIdSourceIsland());

        if (isInvalid(this.island)) {
            this.getIslandAsync();
        }

        this.attendentServicePack = this.attendanceSvc.getAttendentServicePackForCurrentGroup();

        this.cdr.markForCheck();
    }

    private async getIslandAsync() {
        this.island = await this.lookupSvc.getSingleLookupElement<IServiceIslandServer>(this.interaction.getIdIsland());
        this.cdr.markForCheck();
    }

    get interaction(): StartServiceChat {
        return this.groupServiceParameter.interaction;
    }

    get isInternalSupportMessage(): boolean {
        return this.interaction.getServiceType() === EIslandType.internalSupport;
    }

    public buildHexagonHandler(): void {
        this.hexagonHandler = HandlerHexagonon.newHandler({
            serializable: this.groupServiceParameter.interaction.getParticipant().getAvatar(),
            size: EHexagonSizes.xss,
            isSelectionable: false
        });
    }

    private buildHexagonHandlerForForm(idMedia: string): HandlerHexagonon {
        if (isValidString(idMedia)) {
            const mmObject = MultimediaObject.getNewMultimediaObjectFromIdMedia(idMedia);
            const mm = mmObject.getAllMultimediaInstance()[0];
            return HandlerHexagonon.newHandler({
                size: EHexagonSizes.xs,
                multimediaInstance: mm
            });
        }

        return HandlerHexagonon.newHandler({
            size: EHexagonSizes.xs,
            forceURL: './assets/icons/feature-icons/form.svg',
        });
    }

    canShowLocationBtn(): boolean {
        return !this.isInternalSupportMessage && this.interaction.getServiceType() !== EIslandType.internalSupport
    }

    showLocation(): void {
        ServiceRequestLocationRetrieverComponent.interaction = this.groupServiceParameter.interaction;
        this.routingSvc.navigateToId(
            routeID.location.locationViewer.service,
            this.groupServiceParameter.interaction.getPrimaryID(),
        );
    }

    canShowMinimizeButton(): boolean {
        return !this.isInternalSupportMessage;
    }

    openBotHistory() {
        const parameters: GroupServiceBotHistoryParameters = {
            botThreadId: this.botThreadId,
            groupServiceParameter: this.groupServiceParameter,
            onLoadBotHistory: (botHistory) => {
                this.botHistoryCache = botHistory
            },
            botHistory: this.botHistoryCache
        };

        this.dialog.open(GroupServiceBotHistoryComponent, {
            data: parameters,
            width: "95vw",
            height: "80vh",
            maxWidth: "720px"
        });
    }
    
    openFormHistory() {
        this.dialog.open(GroupServiceFormHistoryComponent, {
            data: { botThreadId: this.botThreadId },
            width: "95vw",
            height: "80vh",
            maxWidth: "720px"
        });
    }

    public openAtAGlance() {
        this.dialog.open(ChatAtAGlanceComponent, {
            width: "95vw",
            height: "80vh",
            maxWidth: "720px"
        });
    }

    private getIconUrl() {
        const deliveryIcon = MessageInstance.getDeliveryIcon(this.interaction.get360ProviderType());

        if (deliveryIcon) {
            return deliveryIcon;
        }

        return this.isInternalSupportMessage
            ? 'assets/icons/group-service/icon_intSupportMessage.svg'
            : 'assets/icons/group-service/icon_solicitacaoservico.svg';
        ;
    }

    public isAttendingOnThisGroup(): boolean {
        const ready: boolean = this.attendanceSvc.isReadyToAttend();
        const attending: boolean = this.sessionSvc.iAmAttendingOnCurrentGroup();

        return ready && attending && !this.isFinished;
    }

    public hasAtAGlanceConfigured(): boolean {
        return !!this.attendentServicePack.widgetEventConfigList?.length;
    }

    public hasLoadedAllComputedInfoForAttendance(): boolean {
        return this.attendanceSvc.hasLoadedAllComputedInfoForAttendance(this.sessionSvc.getSelectedGroupID());
    }

    public get hasQueueMessages(): boolean {
        return this.groupServiceParameter.interaction.toJSON().hasQueueMsg;
    }
}
