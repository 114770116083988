import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {IDropdownMenuItem, TIDropdownMenuItemArray} from "../../../model/misc.model";
import {DropdownMenuService} from "../../../services/dropdown-menu.service";


interface IMouseLocation {
  left: number;
  right: number;
  top: number;
  width: number;
  height: number;
}

interface IEvent {
  event: MouseEvent;
  el: ElementRef;
  render: Renderer2
  links: any[];
  distance: Number;
  type: String;
  status: boolean;
  background: string;
  rightMargin: string;
}

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  host: {
    '(document:mouseenter)': 'clickedOutside()',
    '(document:mouseleave)': 'clickedOutside()',
    '(document:click)': 'clickedOutside()',
    '(mouseleave)': 'showhide($event)',
    '(click)': 'clickInside($event)',
    '(window:scroll)': 'showhide($event)'
  }
})
export class DropdownMenuComponent implements OnInit {

  public links: TIDropdownMenuItemArray = [];

  // aldenir code
  isShown = false;
  animation;
  top;
  status;
  type;
  background;
  submenuElement;
  submenuNextElement;
  dropDownMenuListener: Subscription;
  showSubmenuItems: boolean;
  private mouseLocation: IMouseLocation =
    { right: 0, left: 0, top: 0, width: 0, height: 0 };

  constructor(private dropdownMenuService: DropdownMenuService, public el: ElementRef, public render: Renderer2) {
  }

  ngOnInit() {
    // listen for clicks on main-header
    this.dropdownMenuService.show.subscribe((e: IEvent) => {
      this.showMenu(e.event, e.el, e.links, e.distance, e.type, e.status, e.background, e.rightMargin);
    });

    // listen for changes in dropdownMenu status
    this.dropDownMenuListener = this.dropdownMenuService.getStatus().pipe(
        filter(message => message != null)
      ).subscribe(message => {
        this.isShown = message.cm_open;
        this.status = message.cm_open;
      });
  }

  /**
   * Called when user clicks on some of links inside dropdown list
   * @param  {IDropdownMenuItem} link
   * @returns void
   */
  public onDropdownItemClicked(link: IDropdownMenuItem): void {
    link.itemEventHandler.next(link);
    this.showhide();
  }

  get locationCss() {
    let isRightPosSet = this.mouseLocation.right === undefined ? false : true;
    return {
      'position': 'fixed',
      'display': this.isShown ? 'block' : 'none',
      left: !isRightPosSet ? (this.mouseLocation.left + 'px') : undefined,
      right: isRightPosSet ? (this.mouseLocation.right + 'px') : undefined,
      top: this.mouseLocation.top + 'px',
      // width: this.mouseLocation.width + 'px',
      // minWidth: '100px'
    };
  }

  get closeBottom() {
    return {
      'position': 'absolute',
      'display': this.isShown ? 'block' : 'none',
      top: this.top + 10 + 'px',
      'z-index': 0
    };
  }

  get subItemStyle() {
    return {

    }
  }
  get animationClass() {
    this.animation = this.status ? "show-context-menu" : "hide-context-menu";
    return this.animation
  }


  clickedOutside($event) {
    this.isShown = false;
    this.status = false;
  }

  clickInside(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  showMenu(event, el, links, top, type, status, background, rightMargin) {

    const div = el.nativeElement;
    this.isShown = true;
    this.links = links;
    this.type = type;
    this.status = status;
    this.top = div.getBoundingClientRect().top + 20
    this.background = background || '';
    this.dropdownMenuService.status(this.status);

    /*
     *
     * for mouse position use this:
     * left:event.clientX,
     *  top:event.clientY
     */
    const isRightPosSet = rightMargin === undefined ? false : true;
    this.mouseLocation = {
      left: !isRightPosSet ? div.getBoundingClientRect().left - 0 : undefined,
      right: isRightPosSet ? rightMargin : undefined,
      top: (this.type === 'cm-link' ? ((event.clientY <= 40 * links.length) ? div.getBoundingClientRect().top + 30 : div.getBoundingClientRect().top - top * 6) : ((window.innerHeight - div.getBoundingClientRect().top >= 160) ? (div.getBoundingClientRect().top + (top / 1.7)) : (div.getBoundingClientRect().top - top))),
      width: el.nativeElement.clientWidth,
      height: el.nativeElement.clientHeight
    }
  }

  showhide() {
    this.isShown = false;
    this.status = false;
    this.dropdownMenuService.status(this.status);
    /* Hide submenu on menu close */
    if(this.showSubmenuItems){
      this.showSubmenuItems = false
      this.submenuRenderer(this.showSubmenuItems);
    }

  }


  /**
   * Action click to get submenu element
   * @param event
   */
  showHideSubmenu(event) {
    this.showSubmenuItems = !this.showSubmenuItems //boolean show or hide submenu
    var target = event.currentTarget; // Element that have the submenu
    this.submenuElement = target.parentElement; //Parent node <li>
    this.submenuNextElement = this.submenuElement.nextSibling; //Next node <li>

    this.submenuRenderer(this.showSubmenuItems);
  }
/**
 * Add or remove class by submenu status
 * @param status
 */
  submenuRenderer(status:boolean){
    //Add and remove class by showSubmenuItems  status
    if (status) {
      this.render.addClass(this.submenuElement, "active");
      this.render.removeClass(this.submenuNextElement.querySelector('div'), "item-separate");
      this.render.removeClass(this.submenuElement.querySelector('div'), "item-separate");
    } else {
      this.render.removeClass(this.submenuElement, "active");
      this.render.addClass(this.submenuNextElement.querySelector('div'), "item-separate");
      this.render.addClass(this.submenuElement.querySelector('div'), "item-separate");
    }
  }

}
