import { Injectable } from '@angular/core';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { DeliveryProvider } from '@colmeia/core/src/core-constants/types';
import { EMenuMode, TMenuMode } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { getTranslationsFields } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { menuRenderEnum } from '@colmeia/core/src/shared-business-rules/const-text/enums';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { nonNullable } from '@colmeia/core/src/tools/type-utils';
import { isInvalid, keys, mapBy, mapValues } from '@colmeia/core/src/tools/utility';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import { GenericTableComponent } from 'app/components/dashboard/call-center/generic-table/generic-table.component';
import { GenericTableFieldCreator, GenericTableHandler, GenericTableRowFieldIconType, GetRowsOptions, IGenericTableGenericRowField, IGenericTableHandlerClientCallback, IGenericTableRowFieldText, IGenericTableRowFieldTinyIcon, IOnRowFieldClick } from 'app/handlers/generic-table/generic-table.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { EAppAlertTypes, SnackMessageService } from 'app/services/snack-bar';
import { socialMediaTypeToIcon } from 'app/utils/custom-svg-icons';

@Injectable({
    providedIn: 'root'
})
export class CmRenderOptionsService {

    constructor(
        private dashboardSvc: DashBoardService,
        private snackMessageSvc: SnackMessageService,
    ) { }

    public openMenuValidationTable(text: string, limits: MapLimits) {
        const menuTranslations = getTranslationsFields(menuRenderEnum);

        const rows = generateRowsByLimit(text, limits);
        const orderLimits = keys(limits).map(key => nonNullable(limits[key]));
        const mapRowToLimit: Map<Row, TGenerateRowBySizeLimit> = mapBy(orderLimits, (row, index) => nonNullable(rows[index]))

        this.dashboardSvc.dynamicDialog(
            GenericTableComponent,
            GenericTableHandler.factory({
            title: 'Validação de menus',
            clientCallback: <IGenericTableHandlerClientCallback<Row>>{
                getRows: (options?: GetRowsOptions): Row[] => rows,
                onRowFieldClick: (options: IOnRowFieldClick<Row>) => {
                    const limit: TGenerateRowBySizeLimit | undefined = mapRowToLimit.get(options.row);
                    const item = options.row[options.columnName];
                    if (options.columnName === 'icon' || (('icon' in item) && item.icon !== 'close')) return;
                    const currentLimit: number | undefined = limit?.[options.columnName];
                    if (isInvalid(currentLimit)) return;
                    this.snackMessageSvc.openDefaultMessage(EAppAlertTypes.Error)(`The text size limit is ${currentLimit}`);
                },
            },
            rowNames: {
                icon: '',
                [EMenuMode.Quick]: Serializable.getTranslation(menuTranslations[EMenuMode.Quick]),
                [EMenuMode.Full]: Serializable.getTranslation(menuTranslations[EMenuMode.Full]),
                [EMenuMode.Text]: Serializable.getTranslation(menuTranslations[EMenuMode.Text]),
            },
            enableEqualSize: true,
            hideNavigation: true,
        }))
    }

}



type TGenerateRowBySizeLimit = { [key in TMenuMode]: number | undefined };
type MapLimits = { [key in EDelivery360Action]?: TGenerateRowBySizeLimit };

type Field = IGenericTableRowFieldTinyIcon | IGenericTableRowFieldText;
type Row = Define<{ [key in 'icon' | TMenuMode]: IGenericTableGenericRowField }, {
    icon: Field;
    [EMenuMode.Quick]: Field;
    [EMenuMode.Full]: Field;
    [EMenuMode.Text]: Field;
}>;


function generateRowsByLimit(text: string = '', limits: MapLimits) {
    const row = keys(limits)
        .map((socialMediaType): Row[] => {
            const icon: string = socialMediaTypeToIcon[socialMediaType];
            const currentLimits: TGenerateRowBySizeLimit = nonNullable(limits[socialMediaType]);

            return [
                generateRowByMenuModeLimit(text, icon, currentLimits),
                generateRowByMenuModeLimitText(text, icon, currentLimits),
            ]
        })
        .flat()
    ;

    return row;

    function generateRowByMenuModeLimit(text: string, icon: string, limits: TGenerateRowBySizeLimit): Row {
        const computed = mapValues(limits, (limit: number): IGenericTableRowFieldTinyIcon => {
            const row =
                limit ? GenericSharedService.charCount(text) <= limit ? GenericTableFieldCreator.doneIcon
                : GenericTableFieldCreator.icon('close', '#F44336')
                : GenericTableFieldCreator.icon('block', 'black')
            ;
            return {
                ...row,
                enableOnClick: true
            }
        });

        return {
            icon: {
                ...GenericTableFieldCreator.icon(icon),
                iconType: GenericTableRowFieldIconType.Svg,
            },
            ...computed,
        };
    }

    function generateRowByMenuModeLimitText(text: string, icon: string, limits: TGenerateRowBySizeLimit): Row {
        const computed = mapValues(limits, (limit: number) => limit ? GenericTableFieldCreator.text(`${GenericSharedService.charCount(text)}/${limit}`) : getEmptyTextField());

        return {
            icon: getEmptyTextField(),
            ...computed,
        };
    }

    function getEmptyTextField() {
        return GenericTableFieldCreator.text('')
    }
}
