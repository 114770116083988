import { Component, OnInit, ViewChild, ElementRef, Input, Output , EventEmitter, AfterViewInit} from '@angular/core';
import * as Hammer from 'hammerjs';
import {ImgSecureDownloadService} from "../../services/auth/img-secure-download.service";
import {ImgSecureDownloadPipe} from "../../pipes/img-secure-download.pipe";

declare var window: Window;

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, AfterViewInit {
    private minScale: number = 1;
    private maxScale: number = 4;
    private containerWidth: number;
    private containerHeight: number;
    private displayImageX: number = 0;
    private displayImageY: number = 0;
    private displayImageScale: number = 1;

    private displayDefaultWidth: number;
    private displayDefaultHeight: number;

    private rangeX: number = 0;
    private rangeMaxX: number = 0;
    private rangeMinX: number = 0;

    private rangeY: number = 0;
    private rangeMaxY: number = 0;
    private rangeMinY: number = 0;

    private displayImageCurrentX: number = 0;
    private displayImageCurrentY: number = 0;
    private displayImageCurrentScale: number = 1;

    private imageContainer: any;
    private displayImage: HTMLImageElement;

    @Input() imageUrl: string;
    @Output() onTouch = new EventEmitter();
    @Output() onPan = new EventEmitter();
    @ViewChild('imageContainer', {static: true}) imageContainerRef: ElementRef;

    containerClass: string = '';

    constructor(
        private imageSecureSvc: ImgSecureDownloadService
    ) { }

    ngAfterViewInit() {
        this.imageContainer = this.imageContainerRef.nativeElement;
        this.resizeContainer();
        window.addEventListener('resize', this.resizeContainer.bind(this), true);

        let pipe = new ImgSecureDownloadPipe(this.imageSecureSvc);
        pipe.transform(this.imageUrl).subscribe((safeUrl: {changingThisBreaksApplicationSecurity: string}) => {
            let url: string = safeUrl.changingThisBreaksApplicationSecurity;
            this.initImage(url);
            this.initHammer();
        });
    }

    ngOnInit() { }

    setImageDimensions = () => {
        this.displayImage.addEventListener('mousedown', e => e.preventDefault(), false);
        this.displayDefaultWidth = this.displayImage.offsetWidth;
        this.displayDefaultHeight = this.displayImage.offsetHeight;
        this.rangeX = Math.max(0, this.displayDefaultWidth - this.containerWidth);
        this.rangeY = Math.max(0, this.displayDefaultHeight - this.containerHeight);
    }

    initImage(url: string) {
        let displayImage = new Image();
        displayImage.style.maxWidth = displayImage.style.maxHeight = '100%';
        this.displayImage = displayImage;

        displayImage.src = url;
        this.imageContainer.append(displayImage);
        if (displayImage.complete || !displayImage.src) {
            setTimeout(this.setImageDimensions, 100);
        } else {
            displayImage.onload = this.setImageDimensions;
        }

        this.imageContainer.addEventListener('wheel', (e: any) => {
            this.displayImageScale = this.displayImageCurrentScale = this.clampScale(this.displayImageScale + (e.wheelDelta / 800));
            this.updateRange();
            this.displayImageCurrentX = this.clamp(this.displayImageCurrentX, this.rangeMinX, this.rangeMaxX);
            this.displayImageCurrentY = this.clamp(this.displayImageCurrentY, this.rangeMinY, this.rangeMaxY);
            this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageScale);
            this.onTouch.emit({event: e, scale: this.displayImageCurrentScale});
        }, false);
    }

    initHammer() {
        const hammertime = new Hammer(this.imageContainer);

        hammertime.get('pinch').set({enable: true});
        hammertime.get('pan').set({direction: Hammer.DIRECTION_ALL});

        hammertime.on('pan', (ev: any) => {
            this.displayImageCurrentX = this.clamp(this.displayImageX + ev.deltaX, this.rangeMinX, this.rangeMaxX);
            this.displayImageCurrentY = this.clamp(this.displayImageY + ev.deltaY, this.rangeMinY, this.rangeMaxY);

            if (this.displayImageScale === 1) {
                this.displayImageCurrentX = this.displayImageCurrentY = 0;
            }

            this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageScale);
            if (this.displayImageCurrentX === 0 && this.displayImageCurrentY == 0 && this.displayImageCurrentScale == 1) {
                this.onPan.emit({event: ev, type: ev.additionalEvent});
            }
            this.onTouch.emit({event: ev, scale: this.displayImageCurrentScale});
        });

        hammertime.on('pinch pinchmove', ev => {
            this.displayImageCurrentScale = this.clampScale(ev.scale * this.displayImageScale);

            this.updateRange();
            if (this.displayImageCurrentScale == 1) {
                this.displayImageCurrentX = this.displayImageCurrentY = 0;
            } else {
                this.displayImageCurrentX = this.clamp(this.displayImageX + ev.deltaX, this.rangeMinX, this.rangeMaxX);
                this.displayImageCurrentY = this.clamp(this.displayImageY + ev.deltaY, this.rangeMinY, this.rangeMaxY);
            }
            this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageCurrentScale);
            this.onTouch.emit({event: ev, scale: this.displayImageCurrentScale});
        });

        hammertime.on('tap', (ev: any) => {
            if (ev.tapCount === 1) {
                this.onTouch.emit({event: ev, scale: this.displayImageCurrentScale});
            } else if (ev.tapCount == 2) {
                this.displayImageCurrentScale = 1;
                this.displayImageScale = 1;
                this.updateRange();
                this.displayImageCurrentX = 0;
                this.displayImageCurrentY = 0;
                this.displayImageX = 0;
                this.displayImageY = 0;
                this.updateDisplayImage(this.displayImageCurrentX, this.displayImageCurrentY, this.displayImageCurrentScale, true);
            }
        });

        hammertime.on('panend pancancel pinchend pinchcancel', (ev: any) => {
            this.displayImageScale = this.displayImageCurrentScale;
            this.displayImageX = this.displayImageCurrentX;
            this.displayImageY = this.displayImageCurrentY;
            this.onTouch.emit({event: ev, scale: this.displayImageCurrentScale});
            if (this.displayImageCurrentX === 0 && this.displayImageCurrentY == 0 && this.displayImageCurrentScale == 1) {
                this.onPan.emit({event: ev, type: ev.additionalEvent});
            }
        });
    }

    resizeContainer() {
        this.containerWidth = this.imageContainer.offsetWidth;
        this.containerHeight = this.imageContainer.offsetHeight;

        if (this.displayDefaultWidth !== undefined && this.displayDefaultHeight !== undefined) {
            this.displayDefaultWidth = this.displayImage.offsetWidth;
            this.displayDefaultHeight = this.displayImage.offsetHeight;
            this.updateRange();
            this.displayImageCurrentX = this.clamp(this.displayImageX, this.rangeMinX, this.rangeMaxX);
            this.displayImageCurrentY = this.clamp(this.displayImageY,this.rangeMinY,this.rangeMaxY );
            this.updateDisplayImage(
                this.displayImageCurrentX,
                this.displayImageCurrentY,
                this.displayImageCurrentScale
            );
        }
    }

    clamp(value: number, min: number, max: number) {
       return Math.min(Math.max(min, value), max);
    }

    clampScale(newScale) {
        return this.clamp(newScale, this.minScale, this.maxScale);
    }

    updateDisplayImage(x: number, y: number, scale: number, animate:boolean = false) {
        if (animate) {
            this.displayImage.style.transition = 'transform 400ms cubic-bezier( 0.5, 0, 0.5, 1 )';
            setTimeout(() => this.displayImage.style.transition = '', 400);
        }
        const transform = `translateX(${x}px) translateY(${y}px) translateZ(0px) scale(${scale}, ${scale})`;
        this.displayImage.style.transform = transform;
    }

    updateRange() {
        this.rangeX = Math.max(0, Math.round(this.displayDefaultWidth * this.displayImageCurrentScale) - this.containerWidth);
        this.rangeY = Math.max(0, Math.round(this.displayDefaultHeight * this.displayImageCurrentScale) - this.containerHeight);

        this.rangeMaxX = Math.round(this.rangeX / 2);
        this.rangeMinX = 0 - this.rangeMaxX;

        this.rangeMaxY = Math.round(this.rangeY / 2);
        this.rangeMinY = 0 - this.rangeMaxY;
    }
}
