import { ICameraHardwareAbstraction, IFileSystemLayer } from '../hardware-interfaces';
import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { EHardwareResourceStatus } from "./vendor/hardware-status";

import { Window, CameraOptions, Navigator } from './cordova-plugins-definitions';
import {CordovaPermissionsService} from "./permissions/cordova-permissions.service";
import { Injectable } from '@angular/core';
import {clientConstants} from "../../../model/constants/client.constants";


declare var window: Window;
declare var navigator: Navigator;

@Injectable({
    providedIn: 'root'
})
export class CordovaCamera implements ICameraHardwareAbstraction {

    constructor(
        private permissions: CordovaPermissionsService
    ){}

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .getCameraAuthorizationStatus((status: EHardwareResourceStatus) => {
                    resolve(status);
                }, (error) => {
                    throw new Error(error);
                })
        });
    }

    isAllowed(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .isCameraAuthorized((status: boolean) => {
                    resolve(status);
                }, (error) => {
                    throw new Error(error);
                })
        })
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .requestCameraAuthorization((status: EHardwareResourceStatus) => {
                    resolve(status);
                }, (error) => {
                    throw new Error(error);
                })
        });
    }

    getCameraMedia(fs: IFileSystemLayer): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            const options: CameraOptions = {
                quality: clientConstants.multimedia.mobile.camera.quality,
                cameraDirection: 1,
                mediaType: 2,
                encodingType: 0,
                destinationType:0,
                correctOrientation: true
            };

            const onSuccess = (imgData) => {
                const blob: Blob = this.b64toBlob(imgData, EMimeTypes.Jpeg);
                resolve(
                    fs.newFile([blob], 'photo.jpeg', {
                        type: blob.type
                    })
                )
            };

            const onFail = (message) => {
                // throw throwCustomFieldError()
                alert('Failed because: ' + message);
            };

            navigator.camera.getPicture(onSuccess, onFail, options);
        });
    }

    b64toBlob(b64Data, contentType): Blob {

        contentType = contentType || '';
        const sliceSize = 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }
}
