<app-colmeia-window-top-bar></app-colmeia-window-top-bar>

<div class="modal-w-sidebar-container">
  <div class="sidebar no-border">
    <div class="content">

      <div class="conversations-container__wrapper">
        <h3 class="title-style-1">Conversações:</h3>

        <div class="filter-conversation-container">
          <!-- <mat-slide-toggle class="att-filter dense-2x" [(ngModel)]="filterConfig.isGetOnlyConversationsWithAgent"
            (change)="onConversationListFilterInputChange()">
            Apenas com atendimento
          </mat-slide-toggle> -->

          <!-- <mat-form-field appearance="fill" style="margin-top: 16px;">
            <mat-label>Apenas com atendimento</mat-label>
            <mat-select 
              (selectionChange)="filterConversationsBy()" 
              [(ngModel)]="filterConfig.filterConversationsBy"
            >
              <mat-option [value]="FilterConversationsOptionsObject.all">Todas</mat-option>
              <mat-option [value]="FilterConversationsOptionsObject.withHumanAttendance">Apenas Atendimento Humano</mat-option>
              <mat-option [value]="FilterConversationsOptionsObject.withTemplateMessage">Apenas com mensagem de template</mat-option>
            </mat-select>
          </mat-form-field> -->
          <mat-radio-group
            color="primary"
            [(ngModel)]="filterConfig.filterConversationsBy"
            class="conversations-filter"
            (change)="onConversationListFilterInputChange()"
          >
            <mat-radio-button 
            class="wrap-label" 
            [value]="filterConversationsOptionsObject.all" >
              Todas
            </mat-radio-button>
            <mat-radio-button 
            class="wrap-label" 
            [value]="filterConversationsOptionsObject.withHumanAttendance">
              Apenas com atendimento humano
            </mat-radio-button>
            <mat-radio-button 
            class="wrap-label" 
            [value]="filterConversationsOptionsObject.withTemplateMessage">
              Apenas com mensagem de template
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="!loadingParticipants" class="conversations-container">
        <div #conversationList class="conversations-list nice-scroll">
          <ng-container *ngIf="conversations.length else emptyConversationList">
            <ng-container *ngFor="let conversation of conversations; index as conversationIndex">
              <button 
              #conversationListItems 
              class="conversation-item"
              [class.selected]="selectedConversation?.idConversation === conversation.idConversation"
              [class.has-service]="conversation.hasAgentInConversation" 
              (click)="setSelectedConversation(conversation)"
              >
                <div class="button-wrapper">
                  {{ conversation.readableDate }}
                  <mat-icon *ngIf="conversation.hasAgentInConversation" class="service-icon mat-16">support_agent</mat-icon>
                  <mat-icon *ngIf="selectedConversation?.idConversation === conversation.idConversation"
                    class="mat-16 reload-icon">refresh</mat-icon>
                </div>
              </button>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngIf="loadingParticipants" class="conversations-container loading__wrapper">
        <mat-spinner diameter="18"></mat-spinner>
      </div>

      <div class="conversation-history__pagination">
        <cm-generic-dashboard-pagination [dense]="true" *ngIf="paginationHandler" [handler]="paginationHandler">
        </cm-generic-dashboard-pagination>
      </div>

      <div class="filter-container">
        <h3 class="title-style-1">
          Visualizar no Chat
        </h3>
        <div class="filter-options">
          <div class="filter-item">
            <mat-slide-toggle class="dense-2x" [(ngModel)]="filterConfig.showBotConversation"
              (ngModelChange)="onFiltersUpdate()">
              Conversação com bot
            </mat-slide-toggle>
          </div>

          <div class="filter-item">
            <mat-slide-toggle class="dense-2x" [(ngModel)]="filterConfig.showInteractions"
              (ngModelChange)="onFiltersUpdate()">
              Interações com agentes
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      
    </div>
  </div>
  <div class="main">
    <div class="content">
      <app-attendance-conversation-history-view-item 
      #viewItem
        *ngIf="selectedConversation && showConversation" 
        [conversation]="selectedConversation" 
        [filterConfig]="filterConfig" 
        [idAvatarCustomer]="idAvatarCustomer"
        [customerAvatarName]="customerAvatarName" 
        [target]="target"
        [selectedCustomer]="selectedCustomer"
        >
      </app-attendance-conversation-history-view-item>
    </div>
  </div>
</div>

<ng-template #emptyConversationList>
  <app-alert type="info">Lista vazia.</app-alert>
</ng-template>