import { $unknown, DeepMap, Pop } from "@colmeia/core/src/tools/utility-types";


type EmptyMap = Map<any, any>;

type LastOf<T> = T extends [...infer Items, infer Item] ? Item : never
type TupleItems = [any?, ...any[]];

export namespace Brute {
    type EmptyMap = Map<any, any>;

    export function deepSet<Items extends TupleItems>(map: DeepMap<Items>, items: Partial<Items>): typeof map {
        try {
            let target = map as {} as EmptyMap;
            let index = 0;
            for (index = 0; index < items.length - 2; index++) {
                const item = items[index];
                if (!target.has(item)) target.set(item, new Map());
                target = target.get(item);
            }
            target.set(items[index], items[index + 1]);
        } catch {};
        return map;
    }

    export function deepGet<Items extends TupleItems, T>(map: DeepMap<Items>, items: Partial<Items>): T | undefined;
    export function deepGet<Items extends TupleItems, T>(map: DeepMap<Items>, items: Pop<Items>): T | undefined;
    export function deepGet<Items extends TupleItems>(map: DeepMap<Items>, items: Partial<Items>): EmptyMap | void {
        try {
            let target = map as {} as EmptyMap;
            let index = 0;
            for (index = 0; index < items.length; index++) {
                const item = items[index];
                target = target.get(item);
            }
            return target;
        } catch {}
    }

    export function deepHas<Items extends TupleItems, T extends Partial<Items>>(map: DeepMap<Items>, items: T): boolean
    export function deepHas<Items extends TupleItems, T extends Partial<Items>>(map: DeepMap<Items>, items: Pop<Items>): boolean
    export function deepHas<Items extends TupleItems, T extends Partial<Items>>(map: DeepMap<Items>, items: T): boolean {
        let target = map as {} as EmptyMap;
        let index = 0;
        try {
            for (index = 0; index < items.length - 1; index++) {
                const item = items[index];
                target = target.get(item);
            }
            return target.has(items[index]);
        } catch {
            return false; 
        }
    }
}
export namespace Mapper {
    
    const set = new Set<any>([undefined, null])
    function iterate<T>(items: T[], from: number = 0, to: number = 0) {
        const target = items.length + to;
        let index = from;
        let item: any;
        
        return {
            shouldContinue,
            execute,
            getItem,
            getIndex,
        }
        function shouldContinue() {
            return index < target
        }
        function execute() {
            if (!shouldContinue()) return;
            const item = getItem();
            next();
            return item;
        }
        function next() {
            index++
        }
    
        function getIndex() {
            return index
        }
        function getItem() {
            return items[index];
        }
    }

    export function mapBy<T, V extends [unknown, ...unknown[], Item], Item>(items: T[], it: (item: T, index: number, items: T[]) => V) {
        const map = new Map() as DeepMap<V>;
        items.forEach((...params) => Brute.deepSet(map, it(...params)))
        return map;
    }
    export class Mapper<Item, Items extends unknown[] = unknown[]> {
        #map = new Map() as {} as DeepMap<Items>;

        getMap<T extends unknown[]>(): DeepMap<T>;
        getMap(): DeepMap<Items> {
            return this.#map
        }
        constructor(private items: Item[]) {}
        static resolve<Item>(items: Item[]) {
            return new Mapper<Item>(items);
        }
    
        get map() { return this.by }

        by<Items extends TupleItems>(fn: (item: Item, index: number, items: Item[]) => Items): Mapper<Item, Items>
        by<Items extends TupleItems>(fn: (item: Item, index: number, items: Item[]) => Items) {
            this.forEach((...params) => Brute.deepSet(this.getMap(), fn(...params)))
            return this;
        }
    
        byList<Items extends TupleItems>(fn: (item: Item, index: number, items: Item[]) => Items) {
            return this.byCustom(fn, () => [], (target: LastOf<Items>[], last) => target.push(last))
        }
        bySet<Items extends TupleItems>(fn: (item: Item, index: number, items: Item[]) => Items) {
            return this.byCustom(fn, (last) => new Set<typeof last>(), (target: Set<LastOf<Items>>, last) => target.add(last))
        }

        value() {
            return this.#map;
        }

        isReversed = false;
        reversed() {
            this.isReversed = true;
            return this;
        }
    
        forEach(fn: (item: Item, index: number, items: Item[]) => void) {
            const { items } = this;
            const range = getRange();
            const [from, to] = range;
            let index = this.isReversed ? to : from;

            function getRange() {
                const range = [0, items.length - 1];
                return range;
            }
            const next = this.isReversed ? reversedNext : incrementNext;

            while (shouldContinue()) {
                fn(getItem(), index, items);
                next();
            }

            function reversedNext() {
                index--;
            }
            function incrementNext() {
                index++;
            }
            function shouldContinue() {
                return (index >= from) && (index <= items.length - 1);
            }

            function getItem() {
                return items[index]
            }
        }

        byCustom<Items extends TupleItems, T>(fn: (item: Item, index: number, items: Item[]) => Items, onInit: (last: LastOf<Items>) => T, onAdd: (target: T, last: LastOf<Items>) => void): Mapper<Item, [...Pop<Items>, T]>
        byCustom<Items extends TupleItems, T>(fn: (item: Item, index: number, items: Item[]) => Items, onInit: (last: LastOf<Items>) => T, onAdd: (target: T, last: LastOf<Items>) => void): {} {
            this.forEach((...params) => {
                const items = Reflect.apply(fn, this, params);
                const last = items.pop() as LastOf<Items>;
                let set: T | undefined = Brute.deepGet(this.getMap<Items>(), items);
                if (!set) {
                    items.push(set = onInit(last))
                    Brute.deepSet(this.getMap<Items>(), items);
                }
                onAdd(set, last);
            });
            return this;
        }
        
        get(items: Pop<Items>): LastOf<Items> | undefined {
            return Brute.deepGet(this.#map, items);
        }
    
        has(items: Pop<Items>){
            return Brute.deepHas(this.#map, items);
        }
    
        set(items: Items) {
            Brute.deepSet(this.#map, items);
            return this;
        }
    
    }
}


export class MapDeep<Items extends TupleItems> {
    map = new Map() as {} as DeepMap<Items>;

    

    set(items: Items) {
        return Brute.deepSet(this.map, items);
    }

    has(items: Items): boolean {
        return Brute.deepHas(this.map, items as {} as Partial<Items>);
    }

    get(items: Pop<Items>): LastOf<Items> | undefined;
    get(items: unknown[]): LastOf<Items> | undefined {
        return Brute.deepGet(this.map, items as Partial<Items>);
    }
}


namespace Apply {
    export type Swap<T extends EmptyMap> = T extends Map<infer K, infer V> ? Map<V, K> : T;
    export type Many<T extends EmptyMap> = T extends Map<infer K, infer V> ? Map<K, V[]> : T;
}
