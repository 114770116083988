<div class="search__wrapper">
  <div *ngIf="fullTextSearchResults.size > 0 && isGlobalSearch() && !!searchInput.length" >
    <ul class="search-result--container global-search-container">
      
      <div *ngIf="fullTextSearchResultsTotal > maxSearchResults" [@verticalAppear]="'in'">
        <app-alert style="margin-bottom: 12px;" type="info">Sua busca teve muitos resultados, para otimizá-la deixe-a mais específica.</app-alert>
      </div>

      <li *ngFor="let nsTypeToResults of fullTextSearchResults | keyvalue" class="ns-type-row">
        <div class="ns-type-wrapper">
          <span class="ns-type">
            {{ nsTypeToResults.value[0].nsTypeName }}
          </span>
        </div>

        <ul class="ns-row-wrapper">
          <li *ngFor="let result of nsTypeToResults.value" class="ns-row" (click)="handleGlobalSearchRowClick(nsTypeToResults.key, result)">

            <span class="ns-name">
              {{result.name + ' '}}
              <span *ngIf="isBot(result)" class="bot-level">
                {{botTranslations[result.botLevel]}}
              </span>
            </span>
            <ul class="ns-content-wrapper">
              <li *ngFor="let content of result.content" class="content-row">
                <b>{{localeTranslation[content.locale]+': '}}</b>
                {{content.text}}
              </li>
            </ul>

          </li>
        </ul>
        
      </li>
    </ul>
  </div>

  <div class="search-input__container" *ngIf="!proxySubscription">
    <div class="search-input--icons">
      <!-- close button -->
      <button *ngIf="canShowCloseButton()" (click)="closeSearchBox()" class="search-input--btn-close"
        style="display: flex;" type="button">
        <i class="material-icons clickable">close</i>
      </button>

      <!-- search icon -->
      <i *ngIf="!canShowCloseButton()" class=" search-input--icon material-icons search-icon" tapFeedback="circular">
        search
      </i>
    </div>

    <input #searchInputHtml class="input__search" type="text" name="search" autocomplete="off"
      [placeholder]="placeHolderLabel" [ngClass]="canApplyClasses() ? getClasses() : ''" [(ngModel)]="searchInput"
      (keyup)="searchNext()" (focus)="onInputFocus()">
    <mat-progress-bar *ngIf="searching" color="accent" mode="query"></mat-progress-bar>

  </div>

  <!-- search results -->
  <div *ngIf="(!!searchInput.length && searchFired && !hasAnySNResults() || (isGlobalSearch() && fullTextSearchResults.size === 0 && !!searchInput.length))" [@verticalAppear]="'in'">
    <app-alert style="margin-top: 12px;" type="info">Desculpe. Sem resultados.</app-alert>
  </div>

  <ul class="search-result--container" *ngIf="searchInput.length && visibleResults  && isSnSearch()" [@verticalAppear]="'in'">

    <!-- search options -->
    <section *ngIf="canShowEditBtn(); else searchTypeFeedback" class="search__options">
      <mat-form-field>
        <mat-label>Buscar por</mat-label>
        <mat-select value="all" (selectionChange)="selectSearchFor($event.value)">
          <mat-option value="all" selected>
            {{ groupsLabel }}, {{ peopleLabel }} e {{ interactionsLabel }}
          </mat-option>

          <mat-option *ngFor="let item of getSearchForItems()" [value]="item">
            {{ getSelectSerializableText(item) }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="getSearchWhereItems().length">
        <mat-label>Em</mat-label>
        <mat-select [(ngModel)]="selectedWhere" (selectionChange)="selectSearchWhere($event.value)">
          <mat-option *ngFor="let item of getSearchWhereItems()" [value]="item">
            {{ getSelectSerializableText(item) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <ng-template #searchTypeFeedback>
      <div class="search-for__container">
        <label class="search-for__container--label">
          {{ searchingForLabel }} <span><strong>{{ getSearchForText() }}</strong></span>
          {{ getSelectedWhereText() ? inLabel : '' }} <span><strong>{{ getSelectedWhereText() }} </strong></span>
        </label>
        <!-- <button class="search-for--edit-btn"
              *ngIf="canShowEditBtn()"
              (click)="toggleShowSearchOptions()">({{ editLabel }})</button> -->
      </div>
    </ng-template>

    <!-- results list -->
    <li>

      <!-- selected items section -->
      <div *ngIf="canShowSelectionableBlock()">
        <div class="title">{{ selectedLabel }} ({{userSelectedList.length}})</div>
        <div class="result selected-participants__container">
          <div class="selected-participants__item" *ngFor="let selected of userSelectedList">
            <button>
              <i class="material-icons" (click)="removeSelectedItem(selected)">remove_circle</i>
            </button>
            <hexagon [hexagonHandler]="buildHexagonHandler(selected)">
            </hexagon>
            <span>{{ selected.text }}</span>
          </div>
        </div>
        <div class="btn__container">
          <button class="btn--sm" type="button" (click)="onSelectedItemsCancel()">
            {{ cancelLabel }}
          </button>
          <button class="btn--sm" type="submit" (click)="onSelectedItemsSave()">
            {{ selectLabel }}
          </button>
        </div>
      </div>

      <!-- groups result -->
      <section class="results__title" *ngIf="groups.length">
        <h2>{{ groupsLabel }}</h2>
        <hr>

        <div class="groups overflowable">
          <div class="result group__item" *ngFor="let group of groups">
            <div class="left-group" (click)="onSearchResultClicked(group)">
              <i class="material-icons mright"> {{ getPopularityIcon(group) }}</i>
              <hexagon [hexagonHandler]="buildHexagonHandler(group)"></hexagon>
              <p class="text extended-participant__name" (click)="sendResult(group)">{{ getGroupName(group) }}</p>
            </div>
            <div class="middle-group">
              <!-- participants -->
              <mat-icon [matBadge]="group.groupDetail?.liveInfo?.participants" matBadgeSize="small">group</mat-icon>
              <!-- <i class="material-icons">group</i>
                  <span>{{group.groupDetail?.liveInfo?.participants}}</span> -->
              <!-- likes -->
              <mat-icon [matBadge]="group.groupDetail?.liveInfo?.reacts" matBadgeSize="small">insert_emoticon</mat-icon>
              <!-- <i class="material-icons">insert_emoticon</i>
                  <span>{{group.groupDetail?.liveInfo?.reacts}}</span> -->
              <!-- messages -->
              <mat-icon [matBadge]="group.groupDetail?.liveInfo?.posts" matBadgeSize="small">chat_bubble</mat-icon>
              <!-- <i class="material-icons">chat_bubble</i>
                  <span>{{group.groupDetail?.liveInfo?.posts}}</span> -->
              <!-- share -->
              <mat-icon [matBadge]="group.groupDetail?.liveInfo?.shares" matBadgeSize="small">share</mat-icon>
              <!-- <i class="material-icons">share</i>
                  <span>{{group.groupDetail?.liveInfo?.shares}}</span> -->
            </div>
            <i class="material-icons see-more">exit_to_app</i>
          </div>
        </div>

      </section>

      <!-- avatars result -->
      <section class="results__title" *ngIf="avatars.length">
        <h2>{{ peopleLabel }}</h2>
        <hr>

        <div class="avatars overflowable">
          <div class="result avatar__item-outer" *ngFor="let avatar of avatars">
            <div class="avatar__item flex-container extended-participant__container">
              <i class="material-icons mright online"
                [style]="{ 'color': getAgentColor(avatar) }">fiber_manual_record</i>
              <hexagon [hexagonHandler]="buildHexagonHandler(avatar)" (click)="onSearchResultClicked(avatar)">
              </hexagon>
              <p (click)="onSearchResultClicked(avatar)">{{ avatar.text }}</p>
              <i class="material-icons see-more">exit_to_app</i>
            </div>
          </div>
        </div>

      </section>

      <!-- interactions result -->
      <section class="results__title" *ngIf="interactions.length">
        <h2>{{ interactionsLabel }}</h2>
        <hr>

        <div class="interactions overflowable">
          <div class="result interactions__item" *ngFor="let interaction of interactions">

            <div class="left-group" (click)="onSearchResultClicked(interaction)">
              <hexagon class="mright" [hexagonHandler]="buildHexagonHandlerMentions(interaction)"> </hexagon>
              <div>
                <header>
                  <h3>{{ interaction.interactionAvatarDetail?.nameAvatar }} &#64; {{
                    interaction.interactionAvatarDetail?.groupName }}</h3>
                  <span class="dot-separator">•</span>
                  <p>{{ interaction.interactionAvatarDetail?.liveInfo?.clockTick | date: 'dd/MM/yyyy hh:mm' }}</p>
                </header>
                <p>{{ interaction.text }}</p>
              </div>
            </div>

            <div class="feedback-counter">
              <!-- likes -->
              <i class="material-icons">insert_emoticon</i>
              <span>{{interaction.groupDetail?.liveInfo?.reacts}}</span>
              <!-- messages -->
              <i class="material-icons">chat_bubble</i>
              <span>{{interaction.groupDetail?.liveInfo?.posts}}</span>
            </div>
          </div>
        </div>
      </section>

    </li>
  </ul>
</div>