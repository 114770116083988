import {constant, TGlobalUID} from '../business/constant';
import {hashToArray, isValidArray} from '../tools/utility';
import { CustomError} from '../error-control/barrel-error';
import {UberCache} from '../persistency/uber-cache';
import {Avatar, Participant, Serializable} from '../business/barrel-business';
import {
    IInteractionJSON,
    IInteractionJSONArray,
    IChainedInteractionJSON,
    IGranteeInteractionJSON,
    INewParticipantInteractionJSON,
    IFeedbackInteractionJSON,
    IGroupSecurityItemJSON,
    IDeleteInteractionJSON,
    IStartPublishGroupJSON,
    ISetSpokeAvatarJSON,
    IGrantSpokePersonStatusJSON,
    IConfigurationItemJSON,
    IFeedbackCarrierTrackerJSON,
    IShareInteractionJSON,
    ISubscriptionInteractionJSON,
    IPaymentMediumInteractionJSON,
    IPaymentSourceInteractionJSON,
    IPurchaseConfirmationInteractionJSON,
    IEnableRealtimeMonitoring,
    IDisableRealtimeMonitoring,
    IBIllableInteractionJSON,
    IWrapperInteractionJSON,
    IFeatureCarrierInteractioJSON,
} from '../comm-interfaces/barrel-comm-interfaces';

import { GroupSecurityItem } from '../security/security-interaction';
import { TArrayID } from '../core-constants/types';
import { FeedbackFiguresCarrier } from './tracker/feedback-carrier-interaction';
import { ShareInteraction } from './share-interaction';
import { getAllInteractionThreadInfo } from '../rules/interaction-filter';
import { PaymentMediumRegister } from './billable/payment-medium';
import { PurchaseConfirmation} from "./billable/purchase-confirmation";
import { Billable } from "./billable/billable";

import { EGeolocationIntType, EWalletOperationsIT, EServiceGroupIntType } from '../business/constant.enums';
import { RealTimeGPSTrackerOn } from './interactions/location/realtime-tracker-on';
import { TurnGPSOn } from './interactions/location/turn-gps-on';
import { RealTimeTrackerOff } from './interactions/location/realtime-tracker-off';
import { ECustomErrorTGlobalID } from '../error-control/core-error.constants';
import { Interaction, TInteractionArray } from './interaction';
import { SubscriptionType } from './subscription/subscription-type';
import { ConnectionToSerializable } from './subscription/to-serializable';
import { ToGroup } from './subscription/to-group';
import { ToInteraction } from './subscription/to-interaction';
import { Citation } from './interactions/standard-interaction';
import { DeleteInteraction } from './interactions/deleted-interaction';
import { FeedbackInteraction } from './interactions/feedback-interaction';
import { PublishingInteractionOnGroup } from './interactions/multi-group-publisher';
import { InteractionType } from './interaction-type';
import { ConfigurationItemInteraction } from './social-network-config/configuration-item'
import { GrantSpokePersonStatus } from './grant-spoke-status';
import { Spokeperson } from './spoke-person';
import { GranteeInteraction, NewParticipantInteraction } from './interactions/security-event';
import { ToAvatar } from './subscription/to-avatar';
import { StartServiceChat } from './service-group/start-service-chat';
import { IServiceChatInitiatedInteractionJSON, IFinishChatService } from '../comm-interfaces/service-group-interfaces';
import { WalletTransferInteraction } from './wallet/wallet-transfer';
import { WalletChargeInteraction } from './wallet/wallet-charge';
import { WrapperInteraction } from './wrapper-interaction';
import { IWalletOperationInteractionJSON, EWalletOperation, IWalletChargeInteractionJSON, IWalletTransferInteractionJSON } from '../comm-interfaces/finance-interfaces';

import { FinishServiceCall } from './service-group/finish-service';
import { FeatureCarrier } from './interactions/feature-carrier-interaction';
import { MenuInteraction } from './menu-interaction/menu-interaction';
import { IMenuInteractionJSON, IMenuReplyInteractionJSON } from './menu-interaction/menu-interaction-interfaces';
import { MenuReply } from './menu-interaction/menu-reply';

interface IRehydatratedSerializable {
    [primaryID: string]: Serializable
}

interface IHashTableInteraction {
    [alias: string]: Interaction | IInteractionJSON;
};

export interface IMissingRecord {
    idRehydrating: TGlobalUID;
    missingParent: TGlobalUID;
};

export type TMissingRecords = Array<IMissingRecord>;

interface IMissingIDS {
    [primaryID: string]: IMissingRecord;
};

export interface IHashTableInteractionJSON {
    [alias: string]: IInteractionJSON;
};

export class InteractionRehydrator {
    public static rehydrator(array: IInteractionJSONArray, allreadyRehydrated: TInteractionArray, fewInteractions: boolean = false, missingIDS: TMissingRecords = []): TInteractionArray {
        const avatarsParticipants: IRehydatratedSerializable = {};
       
        // Instancia os Avatares
        if (Serializable.isServerSide()) {
            // Não tem UBER ligado.. tem que guardar no rehydrated
            for (let interaction of array) {
                if (!avatarsParticipants[interaction.participant.avatar.primaryID]) {
                    avatarsParticipants[interaction.participant.avatar.primaryID] = 
                            Avatar.factoryMessage(interaction.participant.avatar);
                };
            };

            for (let interaction of array) {
                if (!avatarsParticipants[interaction.participant.primaryID])
                    avatarsParticipants[interaction.participant.primaryID] = 
                            Participant.factoryNoCache(interaction.participant, 
                                <Avatar>avatarsParticipants[interaction.participant.avatar.primaryID])
            };
        } else {
            for (let interaction of array) {
                if (!UberCache.testCache(interaction.participant.avatar.primaryID)) {
                    Avatar.factoryMessage(interaction.participant.avatar);
                };
                // as the multiGroup will provide a participant whith different groups accordingly to what
                // group we are working with, it is allways necessary to correct the participant
                if (!avatarsParticipants[interaction.participant.primaryID]) {
                    avatarsParticipants[interaction.participant.primaryID] = 
                        Participant.factoryMessage(interaction.participant);
                };
            };
        };

        const interactionRehydrated: IHashTableInteraction = {};
        const isIncremental: boolean = isValidArray(allreadyRehydrated);

        if (fewInteractions) {
            const interactions: TInteractionArray = InteractionRehydrator.inMemoryFastRehydrator(array, avatarsParticipants);
            if (isValidArray(interactions)) {
                return interactions;
            };
        };

        if (isIncremental) {
            for (const interaction of allreadyRehydrated) {
                interactionRehydrated[interaction.getInteractionID()] = interaction;
            };
        };

        missingIDS.push(...InteractionRehydrator.fastHehydrator(array, interactionRehydrated, avatarsParticipants));

        // incrementa as novas interações para message-callback
        if (isIncremental) {
            for (const idInteraction in interactionRehydrated) {
                if (! allreadyRehydrated.some((i) => {return i.is(idInteraction)})) {
                    allreadyRehydrated.push(<Interaction>interactionRehydrated[idInteraction])
                };
            };
        };
        return hashToArray(interactionRehydrated);
    };



    public static inMemoryFastRehydrator(array: IInteractionJSONArray, participantsAvatars: IRehydatratedSerializable): TInteractionArray {
        const interactionRehydrated: IHashTableInteraction = {};

        for (const interaction of array) {
            if (InteractionType.staticFactory(interaction.idInteractionType).isChainedOrTreeWithParent(interaction)) {
                const parent: Interaction = <Interaction>UberCache.unsafeUberFactory((<IChainedInteractionJSON>interaction).idInteractionParent, false);
                if (parent) {
                    interactionRehydrated[parent.getInteractionID()] = parent; 
                } else {
                    return [];
                }
            }
        }
        const missingIDS: TMissingRecords = InteractionRehydrator.fastHehydrator(array, interactionRehydrated, participantsAvatars);
        if (isValidArray(missingIDS)) {
            return [];
        }
        return hashToArray(interactionRehydrated);
    };

    public static fastHehydrator(array: IInteractionJSONArray, interactionRehydrated: IHashTableInteraction, participantsAvatars: IRehydatratedSerializable): TMissingRecords {
        const missingIDs: IMissingIDS = {};
        for (const interaction of array) {
            InteractionRehydrator.rehydateInteraction(interaction, array, interactionRehydrated, participantsAvatars, missingIDs);
        };
        return hashToArray(missingIDs);
    };

    // Reidrata tentando no que já está reidratado e/ou o se esta contindo na carga de array
    private static rehydateInteraction(interaction: IInteractionJSON, array: IInteractionJSONArray, interactionRehydrated: IHashTableInteraction, participantsAvatars: IRehydatratedSerializable, missingIDs: IMissingIDS): boolean {
        const allParentKeys: TArrayID = InteractionRehydrator.getAllKeyNeeded(interaction);
        let allOk: boolean = true;
        for (const parentID of allParentKeys) {
            if (!interactionRehydrated[parentID]) {
                const parent: IInteractionJSON = array.find((i) => {return i.primaryID == parentID});
                if (parent) {
                    const ok = InteractionRehydrator.rehydateInteraction(parent, array, interactionRehydrated, participantsAvatars, missingIDs);
                    if (allOk) { // se ao menos um der pau, então é falso
                        allOk = ok;
                    };
                } else {
                    missingIDs[parentID] = {missingParent: parentID, idRehydrating: interaction.primaryID} ;
                    allOk = false;
                };
            };
        };
        if (allOk) {
            interactionRehydrated[interaction.primaryID] = Serializable.isServerSide()?  interaction :
                    InteractionRehydrator.rehydrate(interaction, <Participant>participantsAvatars[interaction.participant.primaryID]);
        };
        return allOk;
    };

    private static isAllProcessed(parents: IInteractionJSONArray, interactionRehydrated: IHashTableInteraction): boolean {
        return !parents.some((interaction) => {return !interactionRehydrated[interaction.primaryID]});
    }

    private static getAllKeyNeeded(interaction: IInteractionJSON): TArrayID {
        const keys: TArrayID = isValidArray(interaction.idInteractionParentArray) ? interaction.idInteractionParentArray : [];
        const interactionType = interaction.idInteractionType;

        if (isValidArray(interaction.threadInfo)) {
            keys.push(...getAllInteractionThreadInfo(interaction));
        };

        if (interactionType == constant.interactionType.payment.source) {
            keys.push((<IPaymentSourceInteractionJSON>interaction).idPaymentMedium)
        } 
        return keys;
    };

    public static rehydrate(json: IInteractionJSON, participant: Participant): Interaction {
        
        let idInteractionType: TGlobalUID = json.idInteractionType;

        if (!Serializable.isServerSide()) {
            if (!UberCache.testCache(json.participant.avatar.primaryID))
                Avatar.factoryMessage(json.participant.avatar);
            
            
            if (!UberCache.testCache(json.participant.primaryID))
                Participant.factoryMessage(json.participant);
        }
        switch (idInteractionType) {

            // Standard

            case constant.interactionType.menu.menuReply: {
                return MenuReply.factoryMessage(<IMenuReplyInteractionJSON>json, participant)
            };

            case constant.interactionType.menu.menuHeader: {
                return MenuInteraction.factoryMessage(<IMenuInteractionJSON>json, participant)
            };

            case EWalletOperationsIT.operation: {
                const aux: IWalletOperationInteractionJSON = <IWalletOperationInteractionJSON>json;
                if (aux.walletOperation === EWalletOperation.charge) {
                    return WalletChargeInteraction.factoryMessage(<IWalletChargeInteractionJSON>json, participant);
                } else {
                    return WalletTransferInteraction.factoryMessage(<IWalletTransferInteractionJSON>json, participant);
                };
            }

            case constant.interactionType.standard.citation:
                return Citation.factoryMessage(<IChainedInteractionJSON>json, participant);

            case constant.interactionType.standard.message:
                return Interaction.factoryMessage(json, participant);

            case constant.interactionType.standard.featureCarrier:
                return FeatureCarrier.factoryMessage(<IFeatureCarrierInteractioJSON>json, participant);
                

            case constant.interactionType.standard.deleteInteraction: 
                return DeleteInteraction.factoryMessage(<IDeleteInteractionJSON> json, participant);

            // ConfigurationCarrier
            case constant.interactionType.configCarriers.configurationItem:
                return ConfigurationItemInteraction.factoryMessage(<IConfigurationItemJSON>json, participant);

            // Special 

            case constant.interactionType.sharing.shareInteraction:
                return ShareInteraction.factoryMessage(<IShareInteractionJSON>json, participant);

            case constant.interactionType.special.setSpokeAvatar:
                return Spokeperson.factoryMessage(<ISetSpokeAvatarJSON>json, participant);

            case constant.interactionType.special.grantSpokeStatus:
                return GrantSpokePersonStatus.factoryMessage(<IGrantSpokePersonStatusJSON> json, participant);

            case constant.interactionType.changeStateSynch.feedbackFiguresCarrier:
                return FeedbackFiguresCarrier.factoryMessage(<IFeedbackCarrierTrackerJSON>json, participant);

            case constant.interactionType.special.wrapperInteraction: {
                return WrapperInteraction.factoryMessage(<IWrapperInteractionJSON>json, participant); 
            }

            // Payments

            case constant.interactionType.payment.medium: {
                return PaymentMediumRegister.factoryMessage(<IPaymentMediumInteractionJSON>json, participant);
            };

            // Security
           
            case constant.interactionType.security.newGroupCreated:
                return Interaction.factoryMessage(json, participant);
            
            case constant.interactionType.security.grantorRegister:
                return Interaction.factoryMessage(json, participant);
            
            case constant.interactionType.security.newParticipant:
                return NewParticipantInteraction.factoryMessage(<INewParticipantInteractionJSON>json, participant);
            
            case constant.interactionType.security.newRole:
                return GranteeInteraction.factoryMessage(<IGranteeInteractionJSON>json, participant);

            case constant.interactionType.security.groupSecurityRule:
                return GroupSecurityItem.factoryMessage(<IGroupSecurityItemJSON> json, participant);


            // Service Group
            case EServiceGroupIntType.finishService: {
                return FinishServiceCall.factoryMessage(<IFinishChatService>json, participant);
            };

            case EServiceGroupIntType.startServiceChat: {
                return StartServiceChat.factoryMessage(<IServiceChatInitiatedInteractionJSON>json, participant);
            };


            case constant.interactionType.products.prePurchase.billableDriverFeedback: {
                return Billable.factoryMessage(<IBIllableInteractionJSON>json, participant);
            };
            
            case constant.interactionType.products.purchase.confirmation:
                return PurchaseConfirmation.factoryMessage(<IPurchaseConfirmationInteractionJSON>json, participant);

            // Feedback
            case constant.interactionType.products.purchase.feedbackDriver:
            case constant.interactionType.feedback.denounce:
            case constant.interactionType.feedback.react:
            case constant.interactionType.feedback.qualify:
            case constant.interactionType.feedback.chosenPreference:
            case constant.interactionType.feedback.choseResourceSolicitation:
            case constant.interactionType.feedback.choseResourceTree:
            case constant.interactionType.feedback.vote:
            case constant.interactionType.subscription.stdResponse:
                return FeedbackInteraction.factoryMessage(<IFeedbackInteractionJSON>json, participant);

            // Sharing

            case constant.interactionType.sharing.startPublishGroup:
                return PublishingInteractionOnGroup.factoryMessage(<IStartPublishGroupJSON>json, participant);
                
            // GeoLocation
            case EGeolocationIntType.turnGpsOff:
                return DeleteInteraction.factoryMessage(<IDeleteInteractionJSON> json, participant);

            case EGeolocationIntType.turnGpsOn:
                return TurnGPSOn.factoryMessage(json, participant);

            case EGeolocationIntType.beginRealtimeTracking: 
                return RealTimeGPSTrackerOn.factoryMessage(<IEnableRealtimeMonitoring>json, participant)

            case EGeolocationIntType.endRealTimeTracking:
                return RealTimeTrackerOff.factoryMessage(<IDisableRealtimeMonitoring>json, participant);


            // Preference

            case constant.interactionType.subscription.subscription:
                return InteractionRehydrator.rehydrateSubscription(<ISubscriptionInteractionJSON>json, participant);


            default:
                throw new CustomError(
                    ECustomErrorTGlobalID.errorID, 
                    true,
                    'InteractionRehydrator.rehydrate',
                    'Interação não pôde ser Instanciada ' + idInteractionType);
        };
        
    };

    private static rehydrateSubscription(subJSON: ISubscriptionInteractionJSON, participant: Participant): ConnectionToSerializable {

        const subtype: SubscriptionType = SubscriptionType.staticFactory(subJSON.idSubscriptionType);
        let subscription: ConnectionToSerializable;

        if (subtype.isGroupToSubject()) {
            subscription = ToGroup.factoryMessage(subJSON, participant);
        } else if (subtype.toNobody()) {
            subscription = ToInteraction.factoryMessage(subJSON, participant);
        } else if (subtype.isAvatarToSubject()) {
            subscription = ToAvatar.factoryMessage(subJSON, participant);
        }

        return subscription;
    }

    public static hardHehydrator(array: IInteractionJSONArray, interactionRehydrated: IHashTableInteraction, avatarsParticipants: IRehydatratedSerializable): number {
        let count: number = 0;
        let lastCount: number = -1;
        let loops: number = 0;
        const interactionJSONHash: IHashTableInteractionJSON = {};
        let idInteraction: TGlobalUID;

          // Coordinate all the rehydrates considering the relationship among then
          while (lastCount < count){
            ++loops;
            lastCount = count;
            for (let json of array) {
                idInteraction = json.primaryID;
                if (!interactionRehydrated[idInteraction]) {
                    if (InteractionRehydrator.isAllParentProcessed(json, interactionJSONHash)) {
                        interactionRehydrated[json.primaryID] = InteractionRehydrator.rehydrate(json, <Participant>avatarsParticipants[json.participant.primaryID]);
                        interactionJSONHash[json.primaryID] = json;
                        ++count;
                    };
                };
            };
        };
        return count;
    };

    public static isAllParentProcessed(json: IInteractionJSON, hash: IHashTableInteractionJSON): boolean {
        const interactionType = json.idInteractionType;

        // bookmarked serializables not necessarally will be in the cache
        /*if (json.idInteractionType == constant.interactionType.oneOnOne.bookmark)
            return true;
        */
        
        if (InteractionType.staticFactory(json.idInteractionType).isChainedOrTreeWithParent(<IChainedInteractionJSON>json) && 
                    !hash[(<IChainedInteractionJSON>json).idInteractionParent])
            return false;
        
        return true;

    };
};
