<div class="dashboard-menus-container" [class.hidden]="selectedSideScreenId !== ESideMenuScreen.Dashboard" #scrollContainer (scroll)="onScroll()">
  <ng-container *ngIf="(modulesList | async)?.length">
    <app-dashboard-menu *ngFor="let handler of modulesList | async" [handler]="handler"></app-dashboard-menu>
  </ng-container>
</div>

<app-group-chat-left-side-bar [class.hidden]="selectedSideScreenId !== ESideMenuScreen.Chat">
</app-group-chat-left-side-bar>

<div class="dashboard-menus-container" [class.hidden]="selectedSideScreenId">
  <!-- essa mensagem só aparece no mobile -->
  <p>
    <mat-icon class="mat-30">arrow_circle_left</mat-icon>
    Selecione um módulo à esquerda para navegar
  </p>
</div>