import { IGeneralFormAnswerServer, TGeneralFieldArray } from '../../general-form/general-form-answer';


export enum EPropValidationErrorType {
    NotSupportedProperty = 'nsp',
    MissingProperty = 'msp',
    UnexpectedPropertyType = 'exp'
}


export interface FillFormResult {
    success: boolean;
    error?: ValidatePropError;
    fields: TGeneralFieldArray;
}

export interface IFullResultFromMetada extends FillFormResult {
    formAnswer: IGeneralFormAnswerServer
}


export interface ValidatePropError {
    type:EPropValidationErrorType;
    propertyName: string;
    aditional?: string;
}

export interface ValidatePropResult {
    success: boolean;
    error?: ValidatePropError;
}


export type TIVariablesArray = Array<IVariable>;
export type TIVariablesClientArray = Array<IVariableClient>;
export interface IVariable extends IPropertyKey {
	text: string;
    isSafe?: boolean;
}

export interface IVariableClient extends IVariable {
    idLocalCanonical?: string
}
export type TPropertyKeyArray=Array<IPropertyKey>;

export interface IPropertyKey {
    idProperty: string;
    value?: string;
    text?: string;
}

export interface ICompiledTemplateWithVariables {
    variables: IPropertyKey[];
    originalTemplate?: string;
    compiledTemplate: string; // WhatsApp = {{1}} {{2}}
    compiledTemplateWithProperties?: string; // 
    messageIfNoBind?: string;
}
