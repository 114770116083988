import {Serializable} from '../business/serializable';
import {constant, TGlobalUID} from '../business/constant';
import { IGroupSecurityDomainJSON } from '../comm-interfaces/business-interfaces';
import {hashToArray} from '../tools/utility';
import {Pane} from '../business/pane';
import {UberCache} from '../persistency/uber-cache';

export type TGroupSecurityDomainArray = Array<GroupSecurityDomain>;

export class GroupSecurityDomain extends Serializable {

    private pane: Pane; 
    private relatedTo: string;

    private constructor (json: IGroupSecurityDomainJSON) {
        super(constant.objectType.groupSecurityDomain, json.primaryID);
        this.pane = Pane.staticFactory(json.idPane);
        this.relatedTo = json.relatedTo;
    };

    public isPlayerRelated(): boolean {return this.relatedTo == 'P';};
    public isRoleRelated(): boolean {return this.relatedTo == 'R';};
    public isSearchRelated(): boolean {return this.relatedTo == 'S';};
    public isGroupAccessRelated(): boolean {return this.relatedTo == 'G';};
    public isSpokePersonRelated(): boolean {return this.relatedTo == 'O';};
    public isAvatarRelated(): boolean {return this.relatedTo == 'A';};


    public toJSON(): IGroupSecurityDomainJSON {
        let json: IGroupSecurityDomainJSON = <IGroupSecurityDomainJSON>super.toJSON();
        json.idPane = this.pane.getPaneID();
        json.relatedTo = this.relatedTo;
        return json;
    };


    public getPane(): Pane {return this.pane};

    public rehydrate(json: IGroupSecurityDomainJSON): void {
        super.rehydrate(json);
        this.pane = Pane.staticFactory(json.idPane);
    };


    public getGroupSecurityDomainID(): TGlobalUID {return super.getPrimaryID();};

    public static getGroupSecurityDomainArray(): TGroupSecurityDomainArray {
        return <TGroupSecurityDomainArray>UberCache.getSerializableArray(constant.objectType.groupSecurityDomain);
    }

    public static staticFactory(idDomain: TGlobalUID): GroupSecurityDomain {
        return <GroupSecurityDomain>UberCache.uberFactory(idDomain, constant.objectType.groupSecurityDomain, true);
    };

    public static factoryMessage(json: IGroupSecurityDomainJSON): GroupSecurityDomain {
        let groupSecurityDomain: GroupSecurityDomain = <GroupSecurityDomain>UberCache.uberFactory(json.primaryID, constant.objectType.groupSecurityDomain, false);
        if (groupSecurityDomain == null) 
            groupSecurityDomain = new GroupSecurityDomain(json);
        groupSecurityDomain.rehydrate(json);
        return groupSecurityDomain;
    }
}