import { Injectable } from "@angular/core";
import { UserSettingsService } from "./user-settings.service";
import { DatePipe } from "@angular/common";
import { getClock } from "@colmeia/core/src/tools/utility";
import { hourToMS } from "@colmeia/core/src/time/time-utl";

@Injectable({
    providedIn: 'root'
})
export class ReadableClocktickService {

    private pipe: DatePipe;

    constructor(
        private settings: UserSettingsService,
    ) {
        this.pipe = new DatePipe(this.settings.getSelectedLocale());
    }

    getReadable(time: number, shorter?: boolean) {
        const difference = getClock() - time;
        let format: string = shorter ? 'd/MM/yy' : 'd/MM/yy HH:mm';

        if (difference < hourToMS(24)) {
            format = `HH:mm`;
        } else if (difference < hourToMS(24 * 7)) {
            format = `EEE HH:mm`;
        }

        return this.pipe.transform(time, format);
    }

}
