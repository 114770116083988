import { Injectable } from '@angular/core';
import { EObjectType } from '@colmeia/core/src/business/constant.enums';
import { delimiterGetter } from '@colmeia/core/src/business/papa-parse/guess-delimiter';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IFileUploadAdditionalParams, IServerFileInfo } from "@colmeia/core/src/comm-interfaces/aux-interfaces";
import { IUniversalJSON } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { TArrayID, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { clientErrorCodes } from '@colmeia/core/src/error-control/client-error.constants';
import { ClientCachedFile } from '@colmeia/core/src/multi-media/client-cached';
import { TIComponentFileInfoArray, TMimeTypeArray } from '@colmeia/core/src/multi-media/file-interfaces';
import { MultimediaInstance, TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/multi-media-instance";
import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { MultimediaTag } from "@colmeia/core/src/multi-media/multi-media-tag";
import { MultiMediaType } from "@colmeia/core/src/multi-media/multi-media-type";
import { MMconstant } from '@colmeia/core/src/multi-media/multimedia-constant';
import { SelectedFile } from '@colmeia/core/src/multi-media/selected-file';
import { TFileFieldArray } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {
    IGetSetMultimediaInformation,
    ISmartshareQueryRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    IGetSetMMInformationResponse, ISetSerializableMultimediaHeaderResponse,
    ISmartshareQueryResponse
} from "@colmeia/core/src/request-interfaces/response-interfaces";
import { Crypt } from '@colmeia/core/src/security/crypt';
import { getUniqueStringID, isValidArray, isValidRef, throwCustomFieldError } from '@colmeia/core/src/tools/utility';
import * as csvToJson from "csvtojson";
import * as papaParse from 'papaparse';
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { ConstantService } from "./constant.service";
import { RequestBuilderServices } from "./request-builder.services";
import { SpinType } from "./screen-spinner.service";
import { ServerCommunicationService, TIFileUpdateArray } from "./server-communication.service";
import { SessionService } from './session.service';
import { AttendanceService } from 'app/services/attendance.service';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';

export interface ISerializableUpdate {
    serializable: Serializable;
    multimediaObject: MultimediaObject;
};
export type TFileSessionDatabase = Map<Blob, ClientCachedFile>;

export type TISerializableUpdateArray = Array<ISerializableUpdate>;

/**
 * Handle Multimedia getFile, upload it, etc
 */
@Injectable()
export class MultimediaService {

    private fileCache: TFileSessionDatabase;
    private server: ServerCommunicationService;
    private rbs: RequestBuilderServices;

    constructor(
        private constantSvc: ConstantService,
        private requestBuilderSvc: RequestBuilderServices,
        private session: SessionService,
        private attendanceService: AttendanceService,
    ) {
        this.fileCache = new Map();
    }

    public setDependencyRequestBuilderServices(rbs: RequestBuilderServices): void { this.rbs = rbs; };
    public setDependencyServerAPI(server: ServerCommunicationService): void { this.server = server; };

    public getFileInfoFromCache(file: Blob): ClientCachedFile { return this.fileCache.get(file); };

    public getSetFileInfo(file: Blob, name: string, thumbnail: Blob): ClientCachedFile {
        let fileInfo: ClientCachedFile = this.getFileInfoFromCache(file);
        if (!fileInfo) {
            fileInfo = ClientCachedFile.fileToNewCachedFile(file, name, thumbnail);
            this.resetFileCachedInfo(fileInfo);
        };
        return fileInfo;
    };

    public resetFileCachedInfo(fileCached: ClientCachedFile): void {
        this.fileCache.set(fileCached.getFile(), fileCached);
    };

    public async getFileBytes(file: Blob): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = e => resolve(<ArrayBuffer>fileReader.result);
        });
    };

    public async getFileAsString(file: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = _ => resolve(<string>fileReader.result);
        });
    };

    public static async getFileAsDataURL(file: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = _ => resolve(<string>fileReader.result);
        });
    };

    public removeFileFromCache(file: File): void { this.fileCache.delete(file); };

    public getFileDatabase(): TFileSessionDatabase {
        return this.fileCache;
    };

    public async batchUploadSerializable(parameters: IInfraParameters, array: TISerializableUpdateArray, idGroupPermission: TGlobalUID
    ): Promise<boolean> {
        const promise: Array<Promise<boolean>> = [];
        const ignore: IInfraParameters = this.rbs.getNoDelegateNoSpinningParameters(parameters.idPlayer, parameters.idAvatar);
        if (parameters.spinType != SpinType.none) {
            // JOY SPINN
        }
        for (const element of array) {
            promise.push(this.uploadSerializableMedia(ignore, element.serializable, element.multimediaObject,
                idGroupPermission));
        };

        const results: Array<boolean> = await Promise.all(promise);
        const atLeastOneFail: boolean = results.some((wasOk) => { return !wasOk; });

        if (parameters.spinType != SpinType.none) {
            // JOI STOP SPINNING
        }
        return !atLeastOneFail;
    };


    public blobToMultimediaInstance(parentMultimedia: MultimediaInstance, blob: Blob): MultimediaInstance {
        const newInstance = MultimediaInstance.getNewMultimediaInstance(MultimediaTag.staticFactory(MMconstant.tag.thumbnail), null);
        const cachedFile: ClientCachedFile = this.getSetFileInfo(blob, parentMultimedia.getMyThumbnailFileName(), null);
        const fileInfo: SelectedFile = SelectedFile.newClientFileInfo(
            cachedFile,
            {
                idMediaTag: MMconstant.tag.thumbnail,
                currentName: parentMultimedia.getMyThumbnailFileName(),
            });
        newInstance.setClientFileInfo(fileInfo);
        return newInstance;
    };

    public createMultimediaInstanceFromBlob(blob: Blob, filename: string) {
        const newInstance = MultimediaInstance.getNewMultimediaInstance(MultimediaTag.staticFactory(MMconstant.tag.default), null);
        const cachedFile: ClientCachedFile = this.getSetFileInfo(blob, filename, null);
        const fileInfo: SelectedFile = SelectedFile.newClientFileInfo(
            cachedFile,
            {
                idMediaTag: MMconstant.tag.thumbnail,
                currentName: filename,
            });
        newInstance.setClientFileInfo(fileInfo);
        return newInstance;
    }


    /**
     * Uploads serializable to server
     * @param  {Serializable} serializable
     * @returns Promise
     */
    public async uploadSerializableMedia(parameters: IInfraParameters, serializable: Serializable, editingMMObject: MultimediaObject,
        idGroupPermission: TGlobalUID, forceNew: boolean = false): Promise<boolean> {
        let ret: boolean = true;
        if (!editingMMObject)
            return ret;

        const mmInstanceArray: TMultimediaInstanceArray = editingMMObject.getAllMultimediaInstance();
        const blobInstance: TMultimediaInstanceArray = [];

        // Valida e gera multimedia que devem ser salvas
        const allowedThumbnailMMInstances: TMultimediaInstanceArray = [];

        mmInstanceArray.forEach((m) => {
            const mimeType: string = m.getMimeType();

            // throwErrorIfTrueField(isInvalid(m.getIdMedia()), ESCode.server2.idSerializable, ESCode.server2.mmedia.noFileMultimedia, true, 'uploadSerializableMedia');

            if (m.isChanged() && m.getTag().isNot(MMconstant.tag.thumbnail) && MultiMediaType.getMutimediaTypeFromMime(mimeType).isThumbnailable()) {
                allowedThumbnailMMInstances.push(m);
            };
        });

        for (const mmInstance of allowedThumbnailMMInstances) {
            const thumbInstance: MultimediaInstance = this.blobToMultimediaInstance(
                mmInstance,
                mmInstance.getClientFileInfo().getThumbnailBlob());
            thumbInstance.setThumbnailParentKey(mmInstance.getMultmediaKey());
            blobInstance.push(thumbInstance);
        };

        mmInstanceArray.push(...blobInstance);
        // getting file metaData from server
        // setting files that we need to send
        const infraResponse: ClientInfraResponse = await this.setServerFileMetaData(parameters, mmInstanceArray,
            idGroupPermission,
            serializable.getObjectTypeID(),
            forceNew ? null : serializable.getPrimaryID());

        ret = infraResponse.executionOK;
        if (ret) {
            const serverFiles: TIComponentFileInfoArray = (<IGetSetMMInformationResponse>infraResponse.response).files;
            const filesToBeUploaded: TIFileUpdateArray = [];

            for (const fileInfo of serverFiles) {
                let mmInstance: MultimediaInstance;
                if (mmInstance = mmInstanceArray.find((m) => { return m.getMultmediaKey() == fileInfo.mmKey })) {
                    mmInstance.getClientFileInfo().getClientCachedFile().setIDMedia(fileInfo.idMedia);
                    mmInstance.setIdMedia(fileInfo.hash)
                    fileInfo.idMediaTag = mmInstance.getMultimediaTag().getPrimaryID();
                    if (fileInfo.shares == 1) {
                        // if (mmInstance.getMimeType() === EMimeTypes.Webp) { // && isSticker) {
                        //     // detectar que é sticker, se for sticker
                        //     // mmInstance.setMimeType(EMimeTypes.Sticker);
                        // }
                        filesToBeUploaded.push({ fileInfo: fileInfo, file: mmInstance.getClientFileInfo().getClientCachedFile().getFile() });
                    };
                };
            };

            if (filesToBeUploaded.length > 0) {
                const response: ClientInfraResponse = await this.server.uploadFiles(parameters, filesToBeUploaded);
                ret = response.executionOK;
            };
        };
        return ret;
    }

    /**
     * Checks on server if file is already uploaded
     * @param  {Serializable} serializable
     * @returns Promise
     */
    public async getServerMetadata(parameters: IInfraParameters, multimediaInstanceArray: TMultimediaInstanceArray):
        Promise<ClientInfraResponse> {
        const fileInfoList: TIComponentFileInfoArray = [];
        let infraResponse: ClientInfraResponse;
        const mapFileMedia: { [mediaKey: string]: ClientCachedFile } = {};

        for (const mmInstance of multimediaInstanceArray.filter((ci) => { return ci.canUpload() })) {
            await this.processFileInfo(mmInstance.getClientFileInfo().getClientCachedFile());
            const basic: IServerFileInfo = mmInstance.getClientFileInfo().getClientCachedFile().getInfoToServer();
            basic.mmKey = mmInstance.getMultmediaKey();
            mapFileMedia[basic.mmKey] = mmInstance.getClientFileInfo().getClientCachedFile();
            fileInfoList.push(basic);
        };

        const requestData: ISmartshareQueryRequest = {
            ...this.rbs.createRequest(apiRequestType.multimedia.getSmartshareInformation, parameters.idPlayer, parameters.idAvatar),
            files: fileInfoList
        };

        infraResponse = await this.server.managedRequest(parameters, requestData);

        if (infraResponse.executionOK) {
            const serverFiles: TIComponentFileInfoArray = (<ISmartshareQueryResponse>infraResponse.response).files;
            for (const mmInstance of multimediaInstanceArray) {
                let fileInfo: IServerFileInfo;
                if (fileInfo = serverFiles.find((f) => { return f.mmKey == mmInstance.getMultmediaKey() })) {
                    const clientFile: ClientCachedFile = mapFileMedia[mmInstance.getMultmediaKey()];
                    clientFile.setIDMedia(fileInfo.idMedia);
                };
            };
        }
        return infraResponse;

    }

    public async processFileInfo(cachedFile: ClientCachedFile, additionalParams?: IFileUploadAdditionalParams): Promise<void> {
        if (!cachedFile.isProcessed()) {
            cachedFile.setHash(
                Crypt.sha256(
                    new Uint8Array(<ArrayBuffer>await this.getFileBytes(cachedFile.getFile()))
                )
            )
            cachedFile.setFileUrl(cachedFile.getFile());
            cachedFile.setIDMedia(null);
            this.resetFileCachedInfo(cachedFile);
        };
    };

    /**
        Caso haja arquivos que não foram modificiados pelo cliente, não reenvia para o servidor
        até porque não possui informações para isso.
        Nesse caso, retorna uma array de files vazia
     */
    private async setServerFileMetaData(infraParamenters: IInfraParameters, multimediaInstanceArray: TMultimediaInstanceArray,
        idGroup: TGlobalUID, idObjectType: TGlobalUID,
        primaryID: TGlobalUID): Promise<ClientInfraResponse> {
        // getting file bytes and hashing it
        const files: Blob[] = [];
        const fileInfoList: TIComponentFileInfoArray = [];
        const mapFileMedia: { [mediaKey: string]: ClientCachedFile } = {};

        // Sempre checa por segurança, todos os arquivos, independemente se mostra com smartshare
        // retorna todos os arquivos que podem ser uploaded
        for (const mmInstance of multimediaInstanceArray) {
            // Se são arquivos vindos originalmente do serializable, não voltam a ser processados
            if (mmInstance.isChanged()) {
                await this.processFileInfo(mmInstance.getClientFileInfo().getClientCachedFile());
                const basic: IServerFileInfo = mmInstance.getClientFileInfo().getClientCachedFile().getInfoToServer();
                basic.mmKey = mmInstance.getMultmediaKey();
                basic.idMediaTag = mmInstance.getMultimediaTag().getMultiMediaTagID();
                mapFileMedia[basic.mmKey] = mmInstance.getClientFileInfo().getClientCachedFile();
                fileInfoList.push(basic);
                const file: Blob = mmInstance.getClientFileInfo().getClientCachedFile().getFile();
                files.push(file);
            };
        };

        let clientInfraResponse: ClientInfraResponse;
        // deve haver processamento
        if (files.length > 0) {
            // building request
            const requestData: IGetSetMultimediaInformation = {
                ...this.rbs.createRequest(apiRequestType.multimedia.getSETMultimediaMetadata, infraParamenters.idPlayer, infraParamenters.idAvatar),
                idAvatarPermission: infraParamenters.idAvatar,
                idGroup: idGroup,
                idIsland: this.attendanceService.getCurrentIslandId(),
                files: fileInfoList,
                idObjectType: idObjectType,
                primaryID: primaryID,
            };

            // sending request to server
            clientInfraResponse = await this.server.managedRequest(infraParamenters, requestData);

            if (clientInfraResponse.executionOK) {
                const serverFiles: TIComponentFileInfoArray = (<IGetSetMMInformationResponse>clientInfraResponse.response).files;
                for (const instance of multimediaInstanceArray) {
                    const file: IServerFileInfo = serverFiles.find((f) => { return f.mmKey == instance.getMultmediaKey() });
                    if (file) {
                        const clientFile: ClientCachedFile = mapFileMedia[instance.getMultmediaKey()];
                        clientFile.setIDMedia(file.idMedia);
                    };
                };
            };
        } else {
            const response: IGetSetMMInformationResponse = {
                files: [],
                friendlyError: null,
                responseType: null,
            };
            clientInfraResponse = {
                executionOK: true,
                friendlyMessage: null,
                response: response,
                responseButton: null,
            };
        };
        console.log('MultimediaService.getServerFileMetaData response: ', clientInfraResponse);
        return clientInfraResponse;
    }

    public async genericUploadFile(selectedFile: MultimediaInstance, multimediaTag: string, additionalParams?: IFileUploadAdditionalParams): Promise<void> {
        const cachedFile = selectedFile.getSelectedFile().getClientCachedFile();

        if (isValidRef(cachedFile.getIDMedia())) {
            selectedFile.setIdMedia(cachedFile.getIDMedia());
            return
        }

        const infraParameter: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(this.session.getPlayerID(), this.session.getAvatarID());

        const clientInfraResp: ClientInfraResponse = await this.setGenericServerMetadata(
            infraParameter,
            this.session.getSelectedGroupID(),
            cachedFile,
            additionalParams
        );

        const isSuccessOnMetadataGeneration = clientInfraResp.executionOK;

        if (!isSuccessOnMetadataGeneration) {
            this.throwUploadError('Não foi possível gerar os metadados do arquivo');
        }

        const serverFiles: TIComponentFileInfoArray = (<IGetSetMMInformationResponse>clientInfraResp.response).files;
        const serverFileInfo = serverFiles[0];
        cachedFile.setIDMedia(serverFileInfo.idMedia);
        selectedFile.setIdMedia(serverFileInfo.hash);
        const isSuccessOnStorageUpload = await this.uploadFile(selectedFile, multimediaTag);

        if (!isSuccessOnStorageUpload) {
            this.throwUploadError('Não foi possível fazer upload do arquivo');
        }

    }

    throwUploadError(message: string): never {
        return GenericSharedService.throw({
            title: 'Upload error',
            message,
            isWarning: true,
        });
    }

    private async setGenericServerMetadata(infraParamenters: IInfraParameters,
        idGroup: TGlobalUID, ccFile: ClientCachedFile, additionalParams?: IFileUploadAdditionalParams): Promise<ClientInfraResponse> {

        await this.processFileInfo(ccFile, additionalParams);
        ccFile.setBucketInfo(additionalParams?.isMustSaveOnColmeiaBucket)

        const requestData: IGetSetMultimediaInformation = {
            ...this.rbs.createRequest(apiRequestType.multimedia.getSETMultimediaMetadata, infraParamenters.idPlayer, infraParamenters.idAvatar),
            idAvatarPermission: infraParamenters.idAvatar,
            idGroup: idGroup,
            files: [ccFile.getInfoToServer()],
            idObjectType: EObjectType.multiMediaInstance,
            primaryID: getUniqueStringID(15),
        };

        const clientInfraResponse = await this.server.managedRequest(infraParamenters, requestData);
        return clientInfraResponse;
    }

    private async uploadFile(selectedFile: MultimediaInstance, multimediaTag: string): Promise<boolean> {
        const serverFileInfo: IServerFileInfo = {
            hash: selectedFile.getHashFromSelectedFile(),
            idMediaTag: multimediaTag,
            name: selectedFile.getClientFileInfo().getCurrentName(),
            idMedia: selectedFile.getSelectedFile().getClientCachedFile().getInfoToServer().idMedia,
            size: selectedFile.getSelectedFile().getClientCachedFile().getInfoToServer().size,
            mmKey: selectedFile.getSelectedFile().getClientCachedFile().getInfoToServer().mmKey,
            shares: selectedFile.getSelectedFile().getClientCachedFile().getInfoToServer().shares,
            type: selectedFile.getSelectedFile().getClientCachedFile().getInfoToServer().type,
        }
        const result: boolean = await this.server.uploadFile(serverFileInfo, selectedFile.getClientFileInfo().getClientCachedFile().getFile());
        return result;
    }

    private urlRegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    public getMultimediaBestUrl(mmInstance: MultimediaInstance): string {

        // try to get localUrl
        if (mmInstance.getClientFileInfo()) {
            return mmInstance.getClientFileInfo().getClientCachedFile().getFileUrl();
        }

        if (this.urlRegExp.test(mmInstance.getIdMedia())) {
            return mmInstance.getIdMedia();
        }

        // gets idmedia
        return this.getMultimediaUrl(mmInstance.getFileIdMedia());
    }

    public getMultimediaUrl(idMedia: TGlobalUID): string {
        return this.constantSvc.getFileUrl() + idMedia
    }

    async saveMultimediaHeader(responseUploaded: TISerializableUpdateArray): Promise<TISerializableUpdateArray> {
        if (!isValidArray(responseUploaded)) { return [] }
        const parameters: IInfraParameters = this.requestBuilderSvc.getSecureDefaultInfraParameters();
        const sendFilledFormResponseInfra: ClientInfraResponse = await this.server.managedRequest(
            parameters,
            this.requestBuilderSvc.getSendFormFilledMultimediaHeaderRequest(responseUploaded)
        );

        if (!sendFilledFormResponseInfra.executionOK) {
            throwCustomFieldError(
                clientErrorCodes.errorPrimaryID,
                clientErrorCodes.multimedia.saveMultimediaHeader,
                true,
                'MultimediaService.saveMultimediaHeader')
        }

        const sendFilledFormResponse: ISetSerializableMultimediaHeaderResponse = <ISetSerializableMultimediaHeaderResponse>sendFilledFormResponseInfra.response
        sendFilledFormResponse.updatedMedia.forEach((updatedMedia: IUniversalJSON) => {
            responseUploaded.forEach(response => {
                if (response.serializable.getPrimaryID() === updatedMedia.primaryID)
                    response.serializable.updateHeader(updatedMedia)
            })
        })
        return responseUploaded
    }

    public async getCsvData(csvFileMedia: MultimediaInstance, noheader: boolean = false): Promise<[TArrayID, TFileFieldArray]> {
        const csvFile: Blob = csvFileMedia.getClientFileInfo().getClientCachedFile().getFile();
        const csvFileString: string = await this.getFileAsString(csvFile);
        const csvData = await csvToJson({ output: 'json', noheader, delimiter: 'auto' })
            // .fromStream(csvFile)
            .fromString(csvFileString);
        console.log({ csvData });

        const header: TArrayID = Object.keys(isValidRef(csvData && csvData[0]) ? csvData[0] : {});
        const fileFields = csvData; // @daniel

        return [header, fileFields];
    };

    public async getCsvDataPapaParse(csvFileMedia: MultimediaInstance, includeHeader: boolean = true): Promise<[TArrayID, TFileFieldArray]> {
        const csvFile: Blob = csvFileMedia.getClientFileInfo().getClientCachedFile().getFile();
        const csvFileString: string = await this.getFileAsString(csvFile);

        const csvData = papaParse.parse(csvFileString, {
            header: includeHeader,
            skipEmptyLines: true,
            delimiter: delimiterGetter,
        }).data;

        const header: TArrayID = Object.keys(isValidRef(csvData && csvData[0]) ? csvData[0] : {});
        const fileFields = <TFileFieldArray>csvData;

        header.forEach((h, i) => header[i] = h.trim());

        return [header, fileFields];
    };

    public async getCsvLines(csvMMInstance: MultimediaInstance): Promise<string[][]> {
        const csvFile: Blob = csvMMInstance.getClientFileInfo().getClientCachedFile().getFile();
        const csvFileString: string = await this.getFileAsString(csvFile);
        const csvData = await csvToJson({ output: 'csv', noheader: true, delimiter: [',', ';'] }).fromString(csvFileString);
        return csvData;
    }

    async getMultimediaSignedTempURL(idMedia: string): Promise<string> {
        return this.server.getMultimediaSignedTempURL(idMedia).toPromise();
    }

    public static getInputAcceptedFileTypes(mimeTypes: TMimeTypeArray): string {
        if (!isValidArray(mimeTypes)) {
            return '';
        }

        // remove parameters do mime type e concatena
        return mimeTypes.map(type => type.split(';')[0]).join(',');
    }

    //


}
