import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { contentVectorTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/vectors";
import { EVectorLoader, IGenerativeVectorServer } from "@colmeia/core/src/shared-business-rules/generative/generative-model";
import { defaultNserValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/nser";
import { isInvalidString, isValidRef } from '@colmeia/core/src/tools/utility';

export function contentVectorValidator(
    ns: IGenerativeVectorServer,
    friendly: FriendlyMessage
): FriendlyMessage {
    defaultNserValidator(ns, friendly);

    if (!ns.loader) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noLoad);
    }

    if (!ns.splitterConfig?.type) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noSplitter);
    }

    if (isInvalidString(ns.providers?.llm?.idConnection)) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noLLMProvider);
    }

    if (isInvalidString(ns.providers?.embedding?.idConnection)) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noEmbeddingProvider);
    }

    if (ns.loader === EVectorLoader.PDF && (!isValidRef(ns.documentMedia) || !ns.documentMedia?.size)) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noFile);
    }

    return friendly;
}
