import { NgModule } from "@angular/core";
import { routeID, routeList } from "./model/routes/route-constants";
import { AuthGuard } from "./services/auth/auth-guard.service";
import { authGuardCanActivate, authGuardCanMatch } from "./services/auth/anvularV16-auth-guard";
import { NotificationRedirectComponent } from "./components/notification-redirect/notification-redirect.component";
import { browserInteractionRoutePieces } from "@colmeia/core/src/shared-business-rules/cordova/notification-router";
import { CustomPreloadingStrategyService } from "./services/custom-preloading-strategy.service";
import { EClientContext } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { DeleteAccountComponent } from "./components/delete-account/delete-account.component";
import { WidgetComponent } from "app/components/widget/widget.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: '',
        redirectTo: `${routeID.auth.parent}/${routeID.auth.signIn.id}`,
        pathMatch: 'full'
    },
    {
        path: `${browserInteractionRoutePieces.path}/:${browserInteractionRoutePieces.params.idGroup}/:${browserInteractionRoutePieces.params.idInteraction}`,
        canLoad: [authGuardCanMatch],
        component: NotificationRedirectComponent
    },
    {
        path: routeID.auth.parent,
        loadChildren: () => import('app/modules/landing/landing.module').then(m => m.LandingModule),
        data: {
            appContext: EClientContext.Authentication,
        }
    },
    {
        path: routeID.deleteAccount,
        component: DeleteAccountComponent
    },
    {
        path: routeID.privacyPolicy,
        component: PrivacyPolicyComponent
    },
    {
        path: `${routeList.groups.path}/${routeList.groups.children.idGroup.path}`,
        canActivate: [authGuardCanActivate],
        loadChildren: () => import('app/modules/group-page/group-page.module').then(m => m.GroupPageModule),
        data: {
            appContext: EClientContext.GroupHome,
        }
    },
    {
        path: routeList.menu.path,
        loadChildren: () => import('app/modules/dashboard-menu/dashboard-menu.module').then(m => m.DashboardMenuModule),
        canActivate: [authGuardCanActivate],
        data: {
            appContext: EClientContext.Dashboard,
        },
    },
    {
        path: `${routeList.player.path}`,
        loadChildren: () => import(`app/modules/players/players.module`).then(m => m.PlayersModule),
        canActivate: [authGuardCanActivate],
        data: {
            appContext: EClientContext.ChatEmbedded,
        },
    },
    {
        path: `${routeList.groups.path}/${routeList.groups.children.idGroup.children.chat}`,
        loadChildren: () => import(`app/modules/chat/chat.module`).then(m => m.ChatModule),
        canActivate: [authGuardCanActivate],
        data: {
            preload: true,
            appContext: EClientContext.Chat,
        },
    },
    {
        path: `${routeList.groupAdministrative.path}`,
        loadChildren: () => import(`app/modules/group-administrative/group-administrative.module`).then(m => m.GroupAdministrativeModule),
        canLoad: [authGuardCanMatch]
    },
    {
        path: `${routeList.maps.path}`,
        loadChildren: () => import(`app/modules/maps/maps.module`).then(m => m.MapsModule),
        canLoad: [authGuardCanMatch]
    },
    {
        path: 'vendor/fb-messenger',
        loadChildren: () => import(`app/modules/facebook-messenger/facebook-messenger.module`).then(m => m.FacebookMessengerModule)
    },

    {
        path: `${routeList.dashboard.path}`,
        loadChildren: () => import(`app/modules/dashboard/dashboard.module`).then(m => m.DashboardModule),
        data: {
            appContext: EClientContext.Dashboard,
        }
    },
    {
        path: `${routeList.dashboard.path}/${routeList.dashboard.children.serviceStatus.path}/${routeList.dashboard.children.serviceStatus.children.current}`,
        loadChildren: () => import(`app/modules/dashboard/dashboard.module`).then(m => m.DashboardModule),
        data: {
            appContext: EClientContext.Dashboard,
        }
    },
    {
        path: `${routeList.embed.path}`,
        loadChildren: () => import(`app/modules/embedded-chat/embedded-chat.module`).then(m => m.EmbeddedChatModule),
        data: {
            appContext: EClientContext.ChatEmbedded,
        }
    },
    {
        path: `widget`,
        // loadChildren: () => import(`app/modules/widget/widget.module`).then(m => m.WidgetModule),
        component: WidgetComponent,
        data: {
            appContext: EClientContext.Widget,
        }
    },
    {
        path: `**`,
        redirectTo: ``
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            enableTracing: false,
            preloadingStrategy: CustomPreloadingStrategyService,
        }),
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
