import { MessageInstanceHandler, IMessageInstanceParameter } from "../message-instance-handler";
import { TSerializableArray } from "@colmeia/core/src/persistency/uber-cache";
import { EChatBtnsVisualElements, ENotificationBtns } from "@colmeia/core/src/core-constants/constant-visual-elements.enums";
import { Serializable } from "@colmeia/core/src/business/serializable";


export class NotificationMessageHandler extends MessageInstanceHandler {

    constructor(messageParameter: IMessageInstanceParameter){
        super(messageParameter);
    }

    getMoreOptions(): TSerializableArray {
        const chatItems: TSerializableArray = this.getFilteredOptions();

        if (! this.getComponentParameter().sessionSvc.interactionMadeByMe(this.getInteraction().toJSON())) {
            chatItems.push(Serializable.staticFactory(ENotificationBtns.Respond));
        }

        return chatItems;
    }

    private getFilteredOptions(): TSerializableArray {
        return [
            ...super.getMoreOptions()
                .filter(option => option.is(
                    EChatBtnsVisualElements.Reply,
                    EChatBtnsVisualElements.Remove
                ))
        ];
    }
}
