import { IGeneralFormAnswerJson, IRFieldResponse, TGeneralFieldArray } from '../../general-form/general-form-answer';
import { SchemaProperty, TSchemaPropertyArray } from '../../general-form/general-form-interface';
import { getUniqueStringID, isInvalid, isValidArray, isValidRef, isValidString, isValidTrimmedString, setDeepProperty } from '../../tools/utility';
import {
    ECRMType,
    ICRMTranformation,
    ICRMTransformationConfig,
    TICRMTranformationArray
} from './meta-engagement';
import { EMetadataNames } from './metadata-db';
import { EUnitTypeID } from '../../business/constant.enums';
import { IActiveCampaignMessage } from '../active-1x1-call/active-1x1-model';
import { IServerLocalCanonicalArray, TCanonicalDB } from '../canonical-model/local-canonical';
import { IExtraInformation } from '../connections/connections-requests';
import { EConnectionType } from '../connections/endpoint-model';
import { EPropValidationErrorType, FillFormResult } from './metadata-util-interfaces';
import { TComputedInfo, fetchGlobalCanonical, findSchemaProperty, getAnswerForProperty, getResponseByIDProperty, multipleAnswerPropValidation, validateProp } from './metadata-utils';
import { getUnityInfo } from './unity-utils';
import { SetUtils } from '@colmeia/core/src/tools/utility/set/set-utils';


export interface IStandardNormalizedForm {
    [formName: string]: any;
}
export type TISandartdNormalizationErrorArray = Array<ISandartdNormalizationError>;

export interface ISandartdNormalizationError {
    noCaseType: boolean;
    propertyName: string;
    value: string;
    idProperty: string;
    option: string;
}

export interface IPreciseResponseDate {
    property: string;
    clockTick: number;
};

export type TIPreciseResponseDateArray = Array<IPreciseResponseDate>;


export interface ITransformedToCRM {
    obj: any;
    errors: TISandartdNormalizationErrorArray
    canonicalsCRM: TComputedInfo;
    preciseDates: TIPreciseResponseDateArray

}

export function fromConnectionToCRM(connection: EConnectionType): ECRMType {
    switch (connection) {
        case EConnectionType.SalesForce:
            return ECRMType.Salesforce;
    }
}


export function generateJSONFromResponses(responses: TGeneralFieldArray, schemaProperties: TSchemaPropertyArray): IExtraInformation {
    const result: IExtraInformation = {};

    for (const response of responses) {
        const prop = schemaProperties.find(p => p.idProperty === response.idProperty);
        if (prop) {
            result[prop.propertyName] = response.value;
        }
    }

    return result;
}


export function transformCrm(canonical: any, responses: TGeneralFieldArray, config: ICRMTransformationConfig, transFields: TICRMTranformationArray, properties: TSchemaPropertyArray, colmeiaToCRM: TComputedInfo): ITransformedToCRM {
    const errors: TISandartdNormalizationErrorArray = [];

    const processed = auxTransformCRM(canonical, properties, undefined, transFields, errors, 0);
    if (isInvalid(config.recordType)) {
        addTransformLogError(errors, { noCaseType: true })
        return undefined;
    }
    const preciseDates: TIPreciseResponseDateArray = [];

    // Associamos a configuração do recordType feito pelo usuário em uma canonicoGlobak
    // para ser posteriormente transformado em um CRMEspecifico
    colmeiaToCRM[EMetadataNames.crmTypeOfCase] = config.recordType;

    for (const prop of properties.filter((p) => { return getUnityInfo(p.idUnity).isDate })) {
        const resp = getResponseByIDProperty(prop.idProperty, responses);
        const crmFieldName = transFields.find((f) => { return f.idFieldForm === prop.idProperty }).fieldName
        preciseDates.push({ clockTick: resp.clockTick, property: crmFieldName });
    }

    return { obj: processed, errors, canonicalsCRM: colmeiaToCRM, preciseDates };
}


function auxTransformCRM(
    canonical: any,
    properties: TSchemaPropertyArray,
    meta: SchemaProperty,
    transFields: TICRMTranformationArray,
    errors: TISandartdNormalizationErrorArray,
    level: number
): any {
    let obj: any;

    if (isInvalid(canonical)) {
        obj = null;

    } else if (Array.isArray(canonical)) {
        obj = [];
        for (let count = 0; count < canonical.length; ++count) {
            obj.push(auxTransformCRM(canonical[count], properties, meta, transFields, errors, level + 1));
        };

    } else if (!(canonical instanceof Object)) {
        obj = canonical;

    } else {
        obj = {};
        for (let property in canonical) {
            const prop: SchemaProperty = properties.find((p) => { return p.propertyName === property });

            if (isValidRef(prop)) {

                const meta: ICRMTranformation = transFields.find((f) => { return f.idFieldForm === prop.idProperty });

                if (isValidRef(meta)) {

                    let value: any = canonical[property];

                    if (isValidArray(meta.multipleAnswers)) {
                        const toCrmValue = meta.multipleAnswers.find((m) => { return m.value === value });
                        if (isValidRef(toCrmValue)) {
                            value = toCrmValue.toCRMValue;

                        } else {
                            addTransformLogError(errors, { propertyName: property, idProperty: prop.idProperty, option: canonical[property] });
                            value = undefined;

                        }
                    }

                    if (isValidRef(value)) {
                        obj[meta.fieldName] = auxTransformCRM(<IGeneralFormAnswerJson>value, prop.nestedSchema, prop, transFields, errors, level + 1);
                    }


                } else {
                    addTransformLogError(errors, { propertyName: property, idProperty: prop.idProperty });

                }


            } else {
                addTransformLogError(errors, { propertyName: property });
            }

        };

    };
    return obj;
}

function addTransformLogError(errors: TISandartdNormalizationErrorArray, newError: Partial<ISandartdNormalizationError>): void {
    errors.push(<ISandartdNormalizationError>newError);
}

export interface IAnswerToJSON {
    json: any;
    errors: TISandartdNormalizationErrorArray;
}


export function getCodeFirst(code: string, value: string): string {
    return isValidTrimmedString(code) ? code : value
}

export function getCodeFirstForAnswer(p: IRFieldResponse): string {
    return getCodeFirst(p.returningID, p.raw)
}

export function safeResponsesToJSON(responses: TGeneralFieldArray, metadata: TSchemaPropertyArray, useIDPropertyAsField: boolean = false, prefereToCode?: boolean): TComputedInfo {
    try {
        return responsesToJSON(responses, metadata, useIDPropertyAsField, prefereToCode)?.json
    } catch (err) {
        return null;
    }

}

export function responsesToJSON(responses: TGeneralFieldArray, metadata: TSchemaPropertyArray, useIDPropertyAsField: boolean = false, prefereToCode?: boolean): IAnswerToJSON {
    const json = {};
    const errors: TISandartdNormalizationErrorArray = [];

    for (const resp of responses) {
        const deepProperty: string = getDeepPropertyName(resp.idProperty, metadata, useIDPropertyAsField);
        if (isValidString(deepProperty) && deepProperty[deepProperty.length - 1] !== '.') {
            if (prefereToCode) {
                setDeepProperty(json, deepProperty, getCodeFirst(resp.returningID, resp.raw));
            } else {
                setDeepProperty(json, deepProperty, resp.raw);
            }
        } else {
            addTransformLogError(errors, { propertyName: resp.propertyName, idProperty: resp.idProperty });

        }
    }

    return {
        json, errors
    }

}




function getDeepPropertyName(idProperty: string, metadata: TSchemaPropertyArray, useIDPropertyAsField: boolean): string {
    let propertyName: string = '';
    for (const meta of metadata) {
        if (meta.idProperty === idProperty) {
            return useIDPropertyAsField ? meta.idProperty : meta.propertyName;
        } else if (isValidArray(meta.nestedSchema)) {
            propertyName = (useIDPropertyAsField ? meta.idProperty : meta.propertyName) + '.';
            const name = getDeepPropertyName(idProperty, meta.nestedSchema, useIDPropertyAsField);
            if (isValidRef(name)) {
                propertyName += name;
            }
        }
    }
    return propertyName
}



export enum EJSONPropertyNameType {
    localCanonical, propertyName, idProperty
}

export function isFilledAllFieldsInForm(
    filledData: IGeneralFormAnswerJson,
    metadata: TSchemaPropertyArray
): { newFilledData: IGeneralFormAnswerJson, isFilledAllFormFields: boolean } {
    const filledFormKeys = Object.keys(filledData)
    const filledKeys = new SetUtils(filledFormKeys);
    const formFields = metadata.map(m => m.propertyName);
    const filledFields = filledKeys.intersection(formFields);

    const filledDataWithOnlyTargetFormFields = {}
    for (const fieldKey of filledFields) {
        filledDataWithOnlyTargetFormFields[fieldKey] = filledData[fieldKey]
    }

    return {
        newFilledData: filledDataWithOnlyTargetFormFields,
        isFilledAllFormFields: filledFields.length == formFields.length
    }
}

export function getFormAnswerFromJSON(
    obj: IGeneralFormAnswerJson,
    properties: TSchemaPropertyArray,
    canonicals: IServerLocalCanonicalArray,
    typeOfComparison: EJSONPropertyNameType = EJSONPropertyNameType.propertyName
): FillFormResult {

    const result: FillFormResult = {
        fields: [],
        success: true,
    };

    if (Array.isArray(obj)) {
        for (const item of (obj as Array<any>)) {
            getFormAnswerFromJSON(item, properties, canonicals, typeOfComparison);
        }
    } else {
        for (const objKey in obj) {

            const prop = properties.find((property) => {
                return typeOfComparison === EJSONPropertyNameType.propertyName ? property.propertyName === objKey :
                    typeOfComparison === EJSONPropertyNameType.localCanonical ? property.idLocalCanonical === objKey :
                        property.idProperty === objKey
            }
            );

            if (prop) {

                const validation = prop.multipleAnswers
                    ? multipleAnswerPropValidation(prop, obj[objKey] as any)
                    : validateProp(prop, obj[objKey]);

                if (validation.success) {
                    switch (prop.idUnity) {
                        case EUnitTypeID.objectType:
                            if (prop.multipleAnswers) {
                                for (const subField in (obj[objKey] as object)) {
                                    const res = getFormAnswerFromJSON((obj[objKey] as object)[subField], prop.nestedSchema, canonicals, typeOfComparison);
                                    if (res.success) {
                                        result.fields.push(...res.fields);
                                    } else {
                                        result.success = false;
                                        result.error = res.error;
                                    }
                                }
                            } else {
                                const res = getFormAnswerFromJSON(obj[objKey] as any, prop.nestedSchema, canonicals, typeOfComparison);
                                if (res.success) {
                                    result.fields.push(...res.fields);
                                } else {
                                    result.success = false;
                                    result.error = res.error;
                                }
                            }
                            break;
                        default:
                            if (prop.multipleAnswers) {
                                for (const item of (obj as object)[objKey]) {
                                    result.fields.push({
                                        row: 0,
                                        value: item,
                                        idLocalCanonical: prop.idLocalCanonical,
                                        idGlobalCanonical: fetchGlobalCanonical(prop.idLocalCanonical, canonicals),
                                        idProperty: prop.idProperty,
                                        raw: item,
                                    });
                                }
                            } else {
                                result.fields.push({
                                    row: 0,
                                    value: obj[objKey] as any,
                                    idLocalCanonical: prop.idLocalCanonical,
                                    idGlobalCanonical: fetchGlobalCanonical(prop.idLocalCanonical, canonicals),
                                    idProperty: prop.idProperty,
                                    raw: obj[objKey] as any,
                                });
                            }
                            break;
                    }
                } else {
                    return {
                        success: false,
                        fields: [],
                        error: validation.error
                    }
                }
            } else {
                return {
                    success: false,
                    error: {
                        type: EPropValidationErrorType.UnexpectedPropertyType,
                        aditional: `${objKey} not found in schema`,
                        propertyName: objKey
                    },
                    fields: []
                }
            }
        }
    }

    if (!result.success) {
        result.fields = [];
    }

    return result;
}

export function createClientGeneralAnswerForActiveCall(activeMessage: IActiveCampaignMessage, properties: TSchemaPropertyArray, localDB: TCanonicalDB): TGeneralFieldArray {
    const response: TGeneralFieldArray = [];
    const usedCanonicals: { [idCanonical: string]: true } = {};
    // Primeiro preencho na ROW toda a informação das variáveis da campanha
    for (const vari of activeMessage.campaingMessageVariables.filter((v) => { return isValidRef(v.value) })) {
        const prop = findSchemaProperty(properties, p => p.idProperty === vari.idProperty);
        if (prop) {
            const hasLocal: boolean = isValidRef(prop.idLocalCanonical);
            const local = hasLocal ? localDB[prop.idLocalCanonical] : undefined;
            const field: IRFieldResponse = getAnswerForProperty(response, prop, isValidRef(local) ? local.globalCanonical : undefined, true);
            field.value = field.raw = vari.value;
            if (hasLocal) {
                usedCanonicals[prop.idLocalCanonical] = true;
            }
        }
    };

    // Agora processo como field todos os localCanonicals
    for (const pKey of activeMessage.templateLocalCanonicals.filter((local) => { return isValidRef(local.value) && !usedCanonicals[local.idProperty] })) {
        const local = isValidRef(pKey) ? localDB[pKey.idProperty] : undefined;
        const prop = findSchemaProperty(properties, p => p.idLocalCanonical === pKey.idProperty);
        const field: IRFieldResponse = {
            idProperty: isValidRef(prop) ? prop.idProperty : getUniqueStringID(), // Não é realmente necessário o template estar contido no form
            idGlobalCanonical: isValidRef(local) ? local.globalCanonical : undefined,
            idLocalCanonical: pKey.idProperty,
            value: pKey.value,
            raw: pKey.value
        };

        response.push(field)
    }


    return response;


}
