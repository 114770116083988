import { E } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { EConfigurationSetRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { ICorporateSearchBodyServer, ICorporateSearchHeaderServer, TICorporateSearchBodyServerArray } from '@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-model';
import { IInfoSquareGetAllowedCorporateSearchRequest, IInfoSquareGetAllowedCorporateSearchResponse, TConfigSearchDB } from '@colmeia/core/src/shared-business-rules/info-square/info-square-req-resp';
import { arrayToHash, arrayUnique, isValidArray } from '@colmeia/core/src/tools/utility';
import { NavigatorServices } from 'app/services/controllers-services/navigator/navigator.service';
import { LookupService } from 'app/services/lookup.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { Observable, ReplaySubject } from 'rxjs';

export type TInfoSquareAvailableConfig = {
    loading: boolean;
    availableCSBody: ICorporateSearchBodyServer[],
    configSearchDB: TConfigSearchDB
};

@Injectable({
    providedIn: 'root'
})
export class InfoSquareService {

    private lastSNId!: string;

    #availableConfig$: ReplaySubject<TInfoSquareAvailableConfig> = new ReplaySubject(1);
    get allAvailableCS$(): Observable<TInfoSquareAvailableConfig> {

        const currentSNId: string = this.sessionSvc.getCurrentSocialNetworkID();

        if(currentSNId !== this.lastSNId) {
            this.loadAllAvailableCS();
            this.lastSNId = currentSNId;
        }

        return this.#availableConfig$;
    }

    private csHeaderHash: Record<string, ICorporateSearchHeaderServer> = {};

    constructor(
        private api: ServerCommunicationService,
        private lookupSvc: LookupService,
        private sessionSvc: SessionService,
    ) {
        this.loadAllAvailableCS();
        this.lastSNId = this.sessionSvc.getCurrentSocialNetworkID();
    }

    public async loadAllAvailableCS() {
        this.#availableConfig$.next({ loading: true, availableCSBody: [], configSearchDB: {} });

        const result = await this.api.quick<IInfoSquareGetAllowedCorporateSearchRequest, IInfoSquareGetAllowedCorporateSearchResponse>()({
            requestType: EConfigurationSetRequest.getAllowedDB,
        });

        if(!result) {
            this.#availableConfig$.next({ loading: false, availableCSBody: [], configSearchDB: result.configDB || {} });
            return;
        }

        const results: TICorporateSearchBodyServerArray = result.results || [];
        
        if(isValidArray(results)){
            await this.loadHeadersOfBodies(results);
        }

        this.#availableConfig$.next({ loading: false, availableCSBody: results, configSearchDB: result.configDB || {} });
    }


    private async loadHeadersOfBodies(corporateSearchBodies: ICorporateSearchBodyServer[]) {
        const allParentsToLoad: string[] = arrayUnique(corporateSearchBodies.map( csBody => csBody.idParent ));
        const allParents = await this.lookupSvc.getBatchNonSerializables<ICorporateSearchHeaderServer[]>(allParentsToLoad);
        
        isValidArray(allParents) && this.hydrateCSHeaderHash(allParents);
    }

    private hydrateCSHeaderHash(nss: ICorporateSearchHeaderServer[]) {
        this.csHeaderHash = {
            ...this.csHeaderHash,
            ...arrayToHash("idNS", nss)
        }
    }

    getCorporateSearchHeader(idNS: string): ICorporateSearchHeaderServer {
        return this.csHeaderHash[idNS];
    }
}
