<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">
  <mat-dialog-content>
    <mat-tab-group class="boxed-tabs boxed-dense tabs-wrap mat-tab-padding-2x" animationDuration="200ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Configurações gerais
        </ng-template>
        <ng-template matTabContent>
          <app-ns-picker *ngIf="crmConfigPackageNSPickerHandler" [handler]="crmConfigPackageNSPickerHandler">
          </app-ns-picker>
          <ng-container *ngIf="basicCRMHandler">
            <app-crm-graph-basic-data #basicData [handler]="basicCRMHandler"></app-crm-graph-basic-data>

            
            <app-single-content-asset-editor *ngIf="crmRootContainer.crmRoot.useCustomTitleGen && basicCRMHandler && titleAssetHadler && !basicData.isLoadingPackage" [handler]="titleAssetHadler" [schemaVariables]="schemaVariables"></app-single-content-asset-editor>
            <app-ns-picker *ngIf="!crmRootContainer.crmRoot.useCustomTitleGen && visualizerPickerHandler" [handler]="visualizerPickerHandler"></app-ns-picker>
            <mat-slide-toggle class="boxed boxed-14" [(ngModel)]="crmRootContainer.crmRoot.useCustomTitleGen">Usar título dinâmico</mat-slide-toggle>
            
          </ng-container>

        </ng-template>
      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Pacote de configurações
        </ng-template>
        <mat-card>
          <app-ns-picker *ngIf="crmConfigPackageNSPickerHandler" [handler]="crmConfigPackageNSPickerHandler">
          </app-ns-picker>
        </mat-card>
      </mat-tab> -->
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">schedule</mat-icon>&nbsp;
          Expiração
        </ng-template>
        <mat-card>
          <app-timestamp-picker
            [handler]="timestampHandler"
          ></app-timestamp-picker>
        </mat-card>
      </mat-tab> -->
    </mat-tab-group>
  </mat-dialog-content>
</cm-generic-dashboard-edit>