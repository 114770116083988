import { Injectable } from '@angular/core';
import { IClientSemaphoreConfig } from '@colmeia/core/src/core-queue/core-queue-service';
import { CoreSemaphore } from '@colmeia/core/src/core-queue/core-semaphore';
import { minToMS } from '@colmeia/core/src/time/time-utl';


//export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


const standardSemaphore: IClientSemaphoreConfig = {
    expirationLockTime: minToMS(50),
    numberOfTries: 20,
    retryInterval: 500,
    throwErrorIfNotLocked: false,
};


@Injectable()
export class SempaphoreService {
    private semaphore: CoreSemaphore = new CoreSemaphore();


    public async runClientWithLock<T>(operation: () => Promise<T>, resourceID: string, config: IClientSemaphoreConfig = standardSemaphore): Promise<T> {
        return this.semaphore.runClientWithLock<T>(operation, resourceID, config)
    };
}
