import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';


export enum ECopilotContext {
  perAttendance = 'perAttendance',
  perPilot = 'perPilot'
}

export interface ICopilotBasic extends INonSerializable  {
  deployedServiceId: IdDep<ENonSerializableObjectType.deployedServices>
  copilotContext: ECopilotContext
}

export interface ICopilotClient extends ICopilotBasic  {
}

export interface ICopilotServer extends ICopilotBasic  {
}

export type TICopilotServerArray = Array<ICopilotServer>;
