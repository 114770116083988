import { TArrayWalletOperation, EWalletOperation, IWalletTransferInteractionJSON, IBillOptions, EBillType } from '../comm-interfaces/finance-interfaces';
import { IInteractionJSON } from '../comm-interfaces/interaction-interfaces';
import { EWalletOperationsIT } from '../business/constant.enums';
import { TGlobalUID } from '../business/constant';
import { isValidRef } from '../tools/utility';

export function getBalance(idPlayer: TGlobalUID, allOps: TArrayWalletOperation): number {
    let balance = 0;
    for (const ops of allOps) {
        if (ops.walletOperation === EWalletOperation.charge) {
            balance += ops.amount.amount;
        } else if (ops.walletOperation === EWalletOperation.transfer) {
            const transfer: IWalletTransferInteractionJSON = <IWalletTransferInteractionJSON>ops;
            if (transfer.wallet.idSenderPlayer === idPlayer) {
                balance -= ops.amount.amount;
            } else {
                balance += ops.amount.amount;
            }
        };
    }
    return balance;
};

export function isWalletChargeInteraction(interaction: IInteractionJSON): boolean {
    return isValidaBillable(interaction.billOptions) && interaction.billOptions.billType === EBillType.wallet;
};

export function isNewTransferOperation(wallet: IInteractionJSON): boolean {
    return isWalletOperation(wallet) && 
        (<IWalletTransferInteractionJSON>wallet).walletOperation == EWalletOperation.transfer 
};

export function isWalletOperation(wallet: IInteractionJSON): boolean {
    return wallet.idInteractionType === EWalletOperationsIT.operation
};

export function isValidaBillable(billable: IBillOptions): boolean {
    return isValidRef(billable);
};

export function isNewBillable(newInteraction: IInteractionJSON, oldInteraction: IInteractionJSON): boolean {
    return isValidRef(oldInteraction) && ! isValidaBillable(oldInteraction.billOptions) && isValidaBillable(newInteraction.billOptions)
}


