import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { IInteractionJSONArray, IInteractionJSON, IChainedInteractionJSON } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { SempaphoreService } from './semaphore-service';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { isValidArray, isValidRef, getClock, polynary, isInvalidArray } from '@colmeia/core/src/tools/utility';
import { isSecondLevelInteraction, isRootInteractionFeatureShared, isFeedbackCarrier, getTotalFeedbacks } from '@colmeia/core/src/rules/interaction-filter';
import { Interaction, TInteractionArray } from '@colmeia/core/src/interaction/interaction';
import { InteractionRehydrator, TMissingRecords } from '@colmeia/core/src/interaction/interaction-rehydrator';
import { DeleteInteraction } from '@colmeia/core/src/interaction/interactions/deleted-interaction';
import { ChainedInteraction } from '@colmeia/core/src/interaction/chained-interaction';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { QueuService } from './queue.service';
import { ICacheFeedbackInteraction } from '@colmeia/core/src/comm-interfaces/feedback-interfaces';
import { IFeedbackCarrierTrackerJSON } from '@colmeia/core/src/comm-interfaces/tracker-interfaces';
import { socketConfig } from '@colmeia/core/src/core-constants/socket.conf';
import { ELocalQueueName, ILocalQueuEntry } from '@colmeia/core/src/core-queue/core-queue-service';
import {
    IConnectionRecoveryRequest,
    ITopicRecoverRequest,
    TArrayGroupSynch
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    IGetRecoverGroupDataResponse,
    IRecoveryTopicResponse
} from "@colmeia/core/src/request-interfaces/response-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {SubscriptionInteractionInfoBuilder} from "./subscription-info.service";
import {ToolBarServices} from "./controllers-services/tool-bar-controller/tool-bar.service";
import {UniversalRehydrator} from "./universal-rehydrator";
import {RequestBuilderServices} from "./request-builder.services";
import {ServerCommunicationService} from "./server-communication.service";
import {SusbcriptionContainerService} from "./subscriptions.service";
import {ClientInfraResponse, IInfraParameters} from "../model/client-infra-comm";
import {GroupSubscription} from "../model/group-subscription.model";
import {clientConstants} from "../model/constants/client.constants";
import {MessageHandlerCallbak} from "../handlers/message-instance-handler/message-handler-callback";
import { AttendanceService } from './attendance.service';
import { isAttendmentInteraction } from '@colmeia/core/src/rules/thread-conversation-functions';
import { SupervisorAgentService } from 'app/supervisor-agent.service';


interface IDeliveryRecord {
    retries: number;
    firstEntry: number;
    interaction: IInteractionJSON;
}

interface IDeliveryControlStructure {
    [idInteraction: string]: IDeliveryRecord;
}


@Injectable()
export class RecoverySystemService {

    private rbs: RequestBuilderServices;
    private serverCom: ServerCommunicationService;
    private sessionService: SessionService;
    private subsContainer: SusbcriptionContainerService;
    private controls: IDeliveryControlStructure = {};
    private isRunningCheck: boolean;
    private attendanceSVC: AttendanceService


    constructor(
        private queue: QueuService,
        private semaphore: SempaphoreService,
        private subSVC: SubscriptionInteractionInfoBuilder,
        private toolbar: ToolBarServices,
        private rehydrator: UniversalRehydrator,
        private supervisorAgentService: SupervisorAgentService

    ) {
        this.isRunningCheck = false;
    }

    public setDependencyRequestBuilder(rbs: RequestBuilderServices): void { this.rbs = rbs; };
    public setDependencyServerCommunication(serverCom: ServerCommunicationService): void { this.serverCom = serverCom; };
    public setDependencySessionService(session: SessionService): void { this.sessionService = session; };
    public setDependencySubscriptionContainer(subsContainer: SusbcriptionContainerService): void { this.subsContainer = subsContainer; };


    public async syncronizeGap(idBrowserSession: string): Promise<void> {
        const infra: IInfraParameters = this.getInfraParameters();
        const request: IConnectionRecoveryRequest = this.getSyncRequest(infra, idBrowserSession);
        if (isValidArray(request.groupSynchRequests)) {
            const clientResponse: ClientInfraResponse = await this.serverCom.managedRequest(infra, request);

            if (clientResponse.executionOK) {
                const response: IGetRecoverGroupDataResponse = <IGetRecoverGroupDataResponse>clientResponse.response;
                for (const recovery of response.recoveryData) {
                    await this.routeBatchInteractions(recovery.group.primaryID, recovery.interactions);
                };
            };
        };

    };

    private getInfraParameters(): IInfraParameters {
        const idSelectedGroup: TGlobalUID = this.sessionService.getSelectedGroupID();
        const idAvatar: TGlobalUID = this.sessionService.getPlayerInfoServices().getPlayerInfo().getAvatarGroup(idSelectedGroup);
        const infraParameters: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(this.sessionService.getPlayerID(), idAvatar);
        return infraParameters;
    };


    private getSyncRequest(infraParameters: IInfraParameters, idBrowserSession: string): IConnectionRecoveryRequest {
        const possibleDelays: TArrayGroupSynch = this.subsContainer.getAllInMemoryGroups();

        const request: IConnectionRecoveryRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.recover.disconnectionRecover, infraParameters),
            groupSynchRequests: possibleDelays,
            idQueue: idBrowserSession
        };

        return request;
    }

    public setAttServiceDependency(attendanceSVC: AttendanceService): void {
        this.attendanceSVC = attendanceSVC;
    }


    public async getRecoverJSON(groupID: TGlobalUID, interactionID: TGlobalUID): Promise<IInteractionJSONArray> {
        const idSelectedGroup: TGlobalUID = groupID;
        const idAvatar: TGlobalUID = this.sessionService.getAvatarFromGroup(idSelectedGroup);
        const infraParameters: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(this.sessionService.getPlayerID(), idAvatar);

        const request: ITopicRecoverRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.recover.topic, infraParameters),
            idRecoveryGroup: groupID,
            idInteraction: interactionID
        };
        const clientResponse: ClientInfraResponse = await this.serverCom.managedRequest(infraParameters, request);

        if (clientResponse.executionOK) {
            const response: IRecoveryTopicResponse = <IRecoveryTopicResponse>clientResponse.response;
            return response.interactions;
        }
        return [];
    }

    /// 2 - Topic
    public async recoverTopic(groupID: TGlobalUID, interactionID: TGlobalUID): Promise<void> {
        const json = await this.getRecoverJSON(groupID, interactionID);
        await this.routeBatchInteractions(groupID, json);
    };

    private getValidInteractionsConsideringAtt(jInteractions: IInteractionJSONArray): IInteractionJSONArray {
        const valid: IInteractionJSONArray = []
        if (this.attendanceSVC.isCurrentAgentCanReceiveMessage()) {
            for (const int of jInteractions) {
                if (isAttendmentInteraction(int)) {
                    const isInteractionFromAttendentOrIsSupervisingGroup: boolean = this.attendanceSVC.isMyServiceInteractionJSON(int) 
                        || this.supervisorAgentService.isSupervisingAttendance(int.idGroup)
                    if (isInteractionFromAttendentOrIsSupervisingGroup) {
                        valid.push(int)
                    } else {
                        this.queue.removeFromQueue(ELocalQueueName.publishControl, int.primaryID);
                    }

                } else {
                    valid.push(int)
                }
            }
            return valid;

        } else {
            return jInteractions;
        }
    }



    public async routeBatchInteractions(idGroup: TGlobalUID, jInteractions: IInteractionJSONArray): Promise<void> {
        jInteractions = this.getValidInteractionsConsideringAtt(jInteractions);

        if (isInvalidArray(jInteractions)) {
            return;
        }

        const subs: GroupSubscription = this.subsContainer.getGroupSubscriptionModel(idGroup);
        const containerController: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
            MessageHandlerCallbak.composeKey(idGroup, null));
        const missings: TMissingRecords = [];

        jInteractions = jInteractions.sort((x, y) => { return x.clockTick - y.clockTick });

        const allRehydrated: TInteractionArray = [];

        if (subs && containerController) {

            const subscriptionInteractions = subs.getInteractions();
            InteractionRehydrator.rehydrator(jInteractions, subscriptionInteractions, true, missings);

            let missingRehydrations = {};
            if (isValidArray(missings)) {
                for (const roundMiss of missings) {
                    const intArray: IInteractionJSONArray = await this.getRecoverJSON(idGroup, roundMiss.missingParent);
                    const miss: TMissingRecords = [];
                    InteractionRehydrator.rehydrator(intArray, subscriptionInteractions, true, miss);
                    miss.forEach((m) => {missingRehydrations[m.missingParent] = true});
                };
            };

            console.log('missing', missingRehydrations);

            for (const json of jInteractions.filter((i) => {return ! missingRehydrations[i.primaryID]})) {
                this.addToCheckers([json]);
                const interaction: Interaction = <Interaction>UberCache.unsafeUberFactory(json.primaryID, false);


                if (interaction) {
                    const activeCallback: MessageHandlerCallbak = MessageHandlerCallbak.getInteractionCallback(idGroup, interaction);
                    if (activeCallback) {
                        allRehydrated.push(interaction);
                        subs.addInteractionToArray(interaction);
                        if (interaction.getInteractionType().isDeleteInteraction()) {
                            activeCallback.removeInteractionWithQueue(
                                (<DeleteInteraction>interaction).getInteractionParent(), interaction.getInteractionID());
                        } else {
                            activeCallback.addInteraction(interaction);
                        }
                        activeCallback.markForCheckEndPoints();
                    }

                } else {
                    console.log('@@@ Not refactored 2', json.idInteractionType, json.primaryID, 'Missing ', missings.some((m) => { return m.missingParent === json.primaryID }));
                };
            }

            // chamar o subscription

            for (const interaction of allRehydrated.filter((s) => { return s.getInteractionType().isSubscription() })) {
                await this.subSVC.fetchSerializableHeadersIfNeeded(interaction);
            }

            containerController.callMessageContainerChangeDetector(true);
            console.log('SC#!OK BatchRoutingProcessed');


            const isDelete: boolean = jInteractions.some((i) => { return InteractionType.staticFactory(i.idInteractionType).isDeleteInteraction() });
            if (!isDelete) {
                if (isValidArray(missings)) {
                    const processed: { [idInteraction: string]: IInteractionJSON } = {};
                    for (const missed of missings) {
                        for (const int of jInteractions.filter((i) => {
                            return !processed[i.primaryID]
                                && i.idInteractionParentArray.some((p) => { return p == missed.missingParent})
                        })) {
                            processed[int.primaryID] = int;
                            await this.recoverTopic(int.idGroup, int.primaryID);
                        };
                    };
                };
                const root: IInteractionJSON = jInteractions.find((i) => { return isRootInteractionFeatureShared(i) });
                if (root) {
                    this.toolbar.addCustomFeatureToGroup(idGroup, jInteractions.map((i) => { return Interaction.staticFactory(i.primaryID) }), []);
                    containerController.callMessageContainerChangeDetector(true);
                };
            };

        };

    };




    private addToCheckers(interactions: IInteractionJSONArray): void {
        let processed: number = 0;
        for (const interaction of interactions) {
            const iType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType);
            if (iType.isStoredInClientDatabase() || iType.isFeedbackCarrier() || iType.isDeleteInteraction()) {
                let control: IDeliveryRecord = this.controls[interaction.primaryID];
                if (control) {
                    ++control.retries;
                } else {
                    control = { interaction: interaction, firstEntry: getClock(), retries: 0 }
                }
                this.controls[interaction.primaryID] = control;
                if (control.retries <= socketConfig.client.recovery.numberOfInternalDeployToComponentsTries) {
                    ++processed;
                    this.queue.enqueue(ELocalQueueName.publishControl, { data: control, id: interaction.primaryID });
                } else {
                    delete this.controls[interaction.primaryID];
                };

            }


        };
        if (!this.isRunningCheck && processed > 0) {
            setTimeout(() => {
                this.checkDelivery();
            }, clientConstants.delays.daemonDeliveryMessage)
        };
    };

    private async checkDelivery(): Promise<void> {
        try {
            this.isRunningCheck = true;
            let enqued: ILocalQueuEntry;
            while (isValidRef(enqued = this.queue.getNextEntry(ELocalQueueName.publishControl))) {
                const control: IDeliveryRecord = <IDeliveryRecord>enqued.data;
                const interaction: IInteractionJSON = control.interaction;
                if (isValidRef(this.controls[interaction.primaryID])) {
                    await this.routeBatchInteractions(interaction.idGroup, [interaction]);
                }
            };
        } catch (err) {

        } finally {
            this.isRunningCheck = false;
        }
    };

    public async newRouteInteraction(idGroup: TGlobalUID, interactionArray: IInteractionJSONArray): Promise<void> {
        const subs: GroupSubscription = this.subsContainer.getGroupSubscriptionModel(idGroup);
        // Se não tem subscription, o grupo não está em memoria.. ok
        if (!subs) {
            return;
        }

        for (const interaction of interactionArray) {

            const isFeedback: boolean = isFeedbackCarrier(interaction);
            const isDelete: boolean = InteractionType.staticFactory(interaction.idInteractionType).isDeleteInteraction();

            const idAffectedInteraction: TGlobalUID = isFeedback || isDelete
                ? (<IChainedInteractionJSON>interaction).idInteractionParent
                : interaction.primaryID;





            const isOnUber: boolean = UberCache.testCache(idAffectedInteraction);
            const rehydrated: Interaction = isOnUber ? Interaction.searchCachedInteraction(idAffectedInteraction) : null;

            const secondLevel: boolean = isSecondLevelInteraction(rehydrated);

            const idCallback: TGlobalUID = secondLevel
                ? (<ChainedInteraction>rehydrated).getInteractionParent().getInteractionID()
                : null;

            const mhCallback: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(MessageHandlerCallbak.composeKey(idGroup, idCallback));

            /// Checar consistencia do subs
            /// deveria ter sido retirado da memoria
            if (isDelete) {
                if (subs.hasInteractionInArray(idAffectedInteraction) || (isOnUber && !rehydrated.belongsToAnotherGroup(idGroup))) {
                };
            };



            if (isFeedback) {
                const feedbackFigures: ICacheFeedbackInteraction = rehydrated.getInteractionFeedbackFiguresForGroup(idGroup);
                const actual: number = getTotalFeedbacks(feedbackFigures);
                const fromFeed: number = getTotalFeedbacks((<IFeedbackCarrierTrackerJSON>interaction).feedbacks);
            }

        }


    }


}
