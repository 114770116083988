<h3 mat-dialog-title>Selecione:</h3>
<mat-dialog-content>
  <mat-selection-list [(ngModel)]="selectedContacts" [multiple]="isMultiple">
    <mat-list-option *ngFor="let contact of data.contacts" [value]="contact">
      {{ contact.address }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [disabled]="!selectedContacts.length" color="primary" (click)="save()">
    <mat-icon>check</mat-icon>
  </button>
</mat-dialog-actions>
