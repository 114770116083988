

import { IKnowledgeDBServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces";
import { notImplementedSharedFunction } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services.basic-functions";
import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";


class Service {

    public async cloneKB(kb: IKnowledgeDBServer): Promise<void> {
        notImplementedSharedFunction(kb);
    }
    
}

@ToStatic
export class KBSharedService extends toStatic(Service) {}
