import { Injectable } from '@angular/core';

import {
    IUniversalJSON,
    IInteractionJSON,
    IPlayerJSON,
    IGroupJSON,
    IParticipantJSON,
    IFriendlyMessageJSON,
    IUniversalJSONArray,
    IAvatarJSON,
} from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { Player, Participant, Group, Avatar, Serializable, TSerializableArray } from '@colmeia/core/src/business/barrel-business';
import { InteractionRehydrator } from '@colmeia/core/src/interaction/interaction-rehydrator';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { noRootGenealogy } from '@colmeia/core/src/rules/filters';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import {RecoverySystemService} from "./recovery-system.service";

@Injectable()
export class UniversalRehydrator {

    private recoveryServices: RecoverySystemService;

    constructor() {}

    public setDependencyRecoveryService(recoveryService: RecoverySystemService): void {this.recoveryServices = recoveryService;};


    public async rehydrateArrayJSON<T extends TSerializableArray>(universalArray: IUniversalJSONArray): Promise<T> {
        let array: T = <T>[];

        for (let json of universalArray) {
            array.push(await this.rehydrate(json));
        };

        return array;

    };


    public async rehydrate<T extends Serializable>(universal: IUniversalJSON): Promise<T> {


        let mySerializable: Serializable;

        switch (universal.idObjectType) {

            // Player
            case constant.objectType.player: {
                const myPlayer: Player = Player.factoryMessage(<IPlayerJSON> universal);
                mySerializable = myPlayer;
            };
                break

            case constant.objectType.avatar: {
                const avatar: Avatar = Avatar.factoryMessage(<IAvatarJSON> universal);
                mySerializable = avatar;
            };
                break;


            // Participant
            case constant.objectType.participant: {
                const participant: IParticipantJSON = <IParticipantJSON>(universal);
                const idAvatar: TGlobalUID = participant.avatar.primaryID;
                if (!UberCache.testCache(idAvatar)) {
                    await this.rehydrate(participant.avatar);
                };
                const myParticipant: Participant = Participant.factoryMessage(<IParticipantJSON> universal);
                mySerializable = myParticipant;
            };
                break;

            // Group
            case constant.objectType.group: {
                const group: IGroupJSON = <IGroupJSON>universal;
                group.genealogy = noRootGenealogy(group.genealogy);

                const myGroup: Group = Group.factoryMessage(group);
                mySerializable = myGroup;
            };
                break;

            // Interaction
            case constant.objectType.interaction: {
                while (! mySerializable) {
                    try {
                        const myInteraction: Interaction = InteractionRehydrator.rehydrate(<IInteractionJSON>universal, null);
                        mySerializable = myInteraction;

                    } catch (err) {
                        try {
                            const int: IInteractionJSON = <IInteractionJSON>universal;
                            await this.recoveryServices.recoverTopic(int.idGroup, int.primaryID);

                        } catch (err) {

                        } finally {

                        };
                    };
                };

                break;
            };
            default:
                console.log('Não conheço esse tipo ainda', universal);
        };
        return <T>mySerializable;
    };





    public handleErrorMessage(error: IFriendlyMessageJSON): FriendlyMessage {
        return FriendlyMessage.factoryMessage(error);
    }

    public localrehydrateArrayJSON<T extends TSerializableArray>(universalArray: IUniversalJSONArray): T {
        let array: T = <T>[];

        for (let json of universalArray) {
            array.push(this.localRehydrate(json));
        };

        return array;

    };



    public localRehydrate<T extends Serializable>(universal: IUniversalJSON): T {


        let mySerializable: Serializable;

        switch (universal.idObjectType) {

            // Player
            case constant.objectType.player: {
                const myPlayer: Player = Player.factoryMessage(<IPlayerJSON> universal);
                mySerializable = myPlayer;
            };
                break

            case constant.objectType.avatar: {
                const avatar: Avatar = Avatar.factoryMessage(<IAvatarJSON> universal);
                mySerializable = avatar;
            };
                break;


            // Participant
            case constant.objectType.participant: {
                const participant: IParticipantJSON = <IParticipantJSON>(universal);
                const idAvatar: TGlobalUID = participant.avatar.primaryID;
                if (!UberCache.testCache(idAvatar)) {
                    this.localRehydrate(participant.avatar);
                };
                const myParticipant: Participant = Participant.factoryMessage(<IParticipantJSON> universal);
                mySerializable = myParticipant;
            };
                break;

            // Group
            case constant.objectType.group: {
                const group: IGroupJSON = <IGroupJSON>universal;
                group.genealogy = noRootGenealogy(group.genealogy);

                const myGroup: Group = Group.factoryMessage(group);
                mySerializable = myGroup;
            };
                break;

            // Interaction
            case constant.objectType.interaction: {
                const myInteraction: Interaction = InteractionRehydrator.rehydrate(<IInteractionJSON>universal, null);
                mySerializable = myInteraction;

            };
            default:
                console.log('Não conheço esse tipo ainda', universal);
        };
        return <T>mySerializable;
    };



}
