import { Injectable } from '@angular/core';
import { ServerCommunicationService } from "app/services/server-communication.service";
import { IProductModuleListRequest, IProductModuleListResponse } from '@colmeia/core/src/shared-business-rules/billing/billing-ns-req-resp';
import { apiRequestType, EBillingRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { toMultipleCursor } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import { RouteCreator } from 'app/services/routing-builder';


@Injectable({
    providedIn: 'root'
})
export class BillingNSService {

    constructor(
        private api: ServerCommunicationService
    ) { }

    public async list(cursor: string) {
        const out: IProductModuleListResponse | undefined = await this.api.sendRequest<IProductModuleListRequest, IProductModuleListResponse>(EBillingRequest.productModuleList)({
            multipleCursor: toMultipleCursor(cursor),
        });
        return out;
    }

}
