<div class="bpm__graph__page">

  <div class="bpm__graph__header">
    <div class="bpm__graph__title">
      Criar um novo processo
    </div>

    <div class="bpm__graph__button__container">
      <div class="bpm__graph__taggable__editor">
        <app-non-serializable-taggable-editor class='tagEditor' *ngIf="taggableHandler" [taggableHandler]="taggableHandler"></app-non-serializable-taggable-editor>
      </div>
  
      <div class="bpm__graph__flat__icon">
        <app-flat-icon (click)="onCloseModal()" icon="close"></app-flat-icon>
      </div>
    </div>

  </div>

  <mat-form-field appearance="outline" >
    <mat-label>Nome do processo</mat-label>
    <input matInput [(ngModel)]="processName">
  </mat-form-field>

  <div class="bpm__graph__icon__button">
    <icon-button
      *ngIf="showCreateButton"
      [text]="'Criar'"
      [icon]="'save'"
      [style]="iconButtonCreateStyle"
      (click)="onSaveButtonClick()"
    ></icon-button>
  </div>
  
</div>