import { Injectable, InjectionToken } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { ICRMTicketDataWithCustomerName } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { ColmeiaWindowInitService, DashboardWindowInitiator } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-init.service';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ColmeiaWindowRuntime } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-runtime';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { KanbanService } from 'app/services/kanban/kanban.base-service';
import { CRMTicketPersistenceService } from './crm-ticket-persistence.service';
import { CrmServiceTicketsViewComponent } from './crm-service-tickets-view.component';
import { TicketsViewCardDialogComponent } from './tickets-view-card-dialog/tickets-view-card-dialog.component';

export const crmTicketsColmeiaWindowId = "tickets-window";
export const TICKET_DATA = new InjectionToken<ICRMTicketDataWithCustomerName>('ColmeiaDialogData');


@Injectable({
    providedIn: 'root'
})
export class CRMTicketsDialogService {

    windowInitiator: DashboardWindowInitiator<any>

    constructor(
        private windowSvc: ColmeiaWindowService,
        protected persistenceSvc: CRMTicketPersistenceService,
    ) {

    }

    setupWindowInitiator(colmeiaInitService: ColmeiaWindowInitService) {
        this.windowInitiator ||= colmeiaInitService.setup({
            component: CrmServiceTicketsViewComponent,
            initialPath: null,
            createPath: null,
            editPath: null,
            initializeNSObject: () => null,
            dialogConfig: {
                width: "95vw",
                height: "95vh",
                maxWidth: "none"
            },
        });

        return this.windowInitiator;
    }

    async openTicket(idTicket: TGlobalUID) {
        const ticketData: ICRMTicketDataWithCustomerName = await this.persistenceSvc.getTicket(idTicket)

        this.openTicketWindow(ticketData);
    }


    public findOpenedWindow(): ColmeiaWindowRuntime {
        const runtimes = [... this.windowSvc.windowRefRuntimeMap.values()];

        return runtimes.find(
            (r) => r.sourceObject.idNS === crmTicketsColmeiaWindowId
        );
    }

    /**
     * Retorna true se janela está instanciada.
     */
    public restoreWindow(): boolean {
        const alreadyOpenWindow = this.findOpenedWindow();

        alreadyOpenWindow?.dialogRef.restore();

        return !!alreadyOpenWindow
    }

    public openOrRestore(kanbanSvc: KanbanService) {
        if (this.findOpenedWindow()) {
            this.restoreWindow();
        } else {
            this.openWindowed(kanbanSvc);
        }
    }

    private openWindowed(kanbanSvc: KanbanService) {
        const runtime = this.windowInitiator.run(
            { openEditDialog: true },
            { idNS: crmTicketsColmeiaWindowId },
            undefined,
            undefined,
            [
                {
                    provide: KanbanService,
                    useValue: kanbanSvc,
                }
            ]
        );

        runtime.dialogRef.group = "Tickets";
        runtime.dialogRef.title = "Tickets";

        return runtime;
    }

    getWindowRef(): ColmeiaWindowRef {
        return this.findOpenedWindow()?.dialogRef;
    }

    hasOpenWindow(): boolean {
        return !!this.getWindowRef();
    }

    public openTicketWindow(ticketData: ICRMTicketDataWithCustomerName) {
        const { title, group } = this.getWindowInfo(ticketData);

        this.windowSvc.open(TicketsViewCardDialogComponent, {
            windowIdentifier: ticketData.idTicket,
            title,
            group,
            width: '68vw',
            height: '85vh',
            injectProviders: [{
                provide: TICKET_DATA,
                useValue: ticketData,
            }]
        });
    }

    private getWindowInfo = (ticketData: ICRMTicketDataWithCustomerName) => {
        return {
            group: "Tickets",
            title: ticketData.title
        };
    }

}
