import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TIJobServerArray } from '@colmeia/core/src/shared-business-rules/jobs/jobs-model';
import { DateService } from 'app/services/date.service';
import { ReadableClocktickService } from 'app/services/readable-clocktick.service';
import { JobService } from '../service/job.service';

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsListComponent implements OnInit {
  title = 'Lista de Jobs Ativos'
  jobs$: Promise<TIJobServerArray> = Promise.resolve([])
  cursor: string;
  amountItemsPerPage: number;

  constructor(
    private jobSvc: JobService,
    private clockTick: ReadableClocktickService,
  ) { }

  ngOnInit() {
    this.refreshJobs()
  }

  getReadableDate(timestamp: number): string {
    return timestamp == 0 ? '--' : this.clockTick.getReadable(timestamp);
  }

  refreshJobs(): void {
    this.jobs$ = this.jobSvc.getAllRunningJobs(this.cursor, this.amountItemsPerPage)
  }
}
