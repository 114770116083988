import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, } from '@angular/material/dialog';
import { isValidArray, isValidFunction, isValidString } from '@colmeia/core/src/tools/utility';
import { DynamicComponentHandler, IDynamicComponentParameter } from 'app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler';
import type { DynamicDialogComponent } from 'app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component';
import { MainHandler } from 'app/handlers/main-handler';
import { ColmeiaDialogComponent } from "../../components/dialogs/dialog/dialog.component";

export interface IColmeiadialogServiceParams<T, D> {
    title?: string;
    panelClass?: string | string[];
    dataToComponent?: MatDialogConfig<D>;
    componentRef: ComponentType<T> | TemplateRef<T>;
    contentText?: string;
    lineUnderHeader?: boolean;
    hideHeader?: boolean;
    hideClose?: boolean;
    viewContainerRef?: ViewContainerRef
}
export type IDynamicDialogInput<Component, Handler, D> = IDynamicComponentParameter & {
    component: Component;
    handler: Handler;
    setDialogRef?: (ref: MatDialogRef<any>) => void;
    dataToComponent?: MatDialogConfig<D>;
}

export const DIALOG_DATA = MAT_DIALOG_DATA;

@Injectable()
export class ColmeiaDialogService {
    dynamicDialogComponent: typeof DynamicDialogComponent

    constructor(
        private matDialog: MatDialog,
    ) { }

    open<T, D>(
        componentOrLegacyParams: IColmeiadialogServiceParams<T, D> | ComponentType<T> | TemplateRef<T>,
        config?: MatDialogConfig<D>
    ): MatDialogRef<any, any> {
        if (typeof componentOrLegacyParams === 'function' || componentOrLegacyParams instanceof TemplateRef) {
            return this.matDialog.open(componentOrLegacyParams as ComponentType<T>, config);
        }

        const params = componentOrLegacyParams as IColmeiadialogServiceParams<T, D>;
        const panelClass = ['colmeia-modal'];

        if (isValidString(params.panelClass)) {
            panelClass.push(params.panelClass);
        } else if (isValidArray(params.panelClass)) {
            panelClass.push(...params.panelClass);
        }

        return this.matDialog.open(ColmeiaDialogComponent, {
            ...params.dataToComponent,
            panelClass: panelClass,
            autoFocus: false,
            viewContainerRef: params.viewContainerRef,
            data: {
                title: params.title,
                hideHeader: params.hideHeader,
                lineUnderHeader: params.lineUnderHeader,
                getParamsToChildComponent: () => params.dataToComponent && params.dataToComponent.data,
                componentOrTemplateRefToOpen: params.componentRef,
                contentText: params.contentText,
                hideClose: params.hideClose,
            },

        }) as MatDialogRef<T, any>;
    }

    openAndWait<T, D = any, R = any>(
        component: ComponentType<T>,
        config?: MatDialogConfig<D>
    ): { promise: Promise<R>, dialogRef: MatDialogRef<T, R> } {
        const dialogRef = this.open<T, D>(component, config) as MatDialogRef<T, R>;

        const promise = new Promise<R | undefined>(resolve => {
            const sub = dialogRef.afterClosed().subscribe(data => {
                resolve(data);
                sub.unsubscribe();
            });
        });

        return { promise, dialogRef };
    }

    private waitDialog<T>(dialogRef: MatDialogRef<T>): Promise<void> {
        return dialogRef.afterClosed().toPromise();
    }

    public async dynamicDialog<Component extends { prototype: { handler?: Handler } }, Handler, D>({
        component,
        handler,
        setDialogRef,
        dataToComponent,
        ...other
    }: IDynamicDialogInput<Component, Handler, D>): Promise<void> {
        if (!this.dynamicDialogComponent) {
            this.dynamicDialogComponent = (await import('app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component')).DynamicDialogComponent;
        }

        const ref = this.open<
            DynamicDialogComponent,
            DynamicComponentHandler
        >({
            componentRef: this.dynamicDialogComponent,
            hideHeader: true,
            dataToComponent: {
                ...dataToComponent,
                data: DynamicComponentHandler.factory({
                    component,
                    handler: (handler as unknown) as MainHandler,
                    ...other,
                }),
            },
        })
        if (isValidFunction(setDialogRef)) setDialogRef(ref);
        return this.waitDialog(ref);
    }

    closeAll() {
        this.matDialog.closeAll();
    }

}
