import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { getNonSerializableFriendlyNameTranslations } from "./non-serializable-friendly";


const commonFields = {
    yes: 1,
    no: 2,
    cancel: 3,
    date: 4,
    begin: 5,
    end: 6,
    apply: 7,
    send: 8,
    name: 9,
    amount: 10,
    close: 11,
    image: 12,
    acceptTerms: 13, // confirmPurchaseMessage 23
    opened: 14, // sideBarLeft.constants 29
    pendency: 15, // sideBarLeft.constants 20
    demand: 16, // sideBarLeft.constants 21
    people: 17, //ChatFilter 19
    interval: 18, //ChatFilter 21
    add: 19,
    remove: 20,
    fullName: 21,
    save: 22,
    config: 23,
    message: 24,
    create: 25,
    content: 26,
    details: 27,
};

export const commonScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Common,
    fields: commonFields
} as const);

const commonFields2 = {
    title: 1,
    type: 2,// BotTransaction 3
    details: 3,
    actions: 4, // Ações
    interaction: 5, // Interação
    interactions: 6, // Interações
    edit: 7, // Editar
    connection: 8, // conexão
    metadata: 9,// BotTransaction 24
    variables: 10, // Variáveis
    description: 11, // descricao
    fieldRequired: 12, // Este campo é obrigatório'
    route: 13,// Rota
    choose: 14, // Ecolher
    time: 15, // Tempo
    values: 16,
    value: 17,
    servicePack: 18, // Recursos de Atendente
    files: 19, // Bancos de dados
    text: 20,
    selected: 21, // Selecionados
    selectFile: 22, // Selecione um banco de dados
    editMessage: 23, // Editar mensagem
    addAction: 24, // Adicionar Ação
    campaings: 25, // campanhas
} as const;



export const commonScreenOptions2 = typedScreenOptions({
    idSerializable: EAllSerializable.Common2,
    fields: commonFields2
} as const);

export const commonScreenOptions3 = typedScreenOptions({
    idSerializable: EAllSerializable.Common3,
    fields: {
        day: 1, // dia
        days: 2, // dias
        domain: 3, // Domínio
        active: 4, // Ativo
        default: 5, // Padrão
        group: 6, // Grupo
        bot: 7, //Bot
        serviceType: 8, // Tipo de serviço
        expression: 9, // Expressão
        intention: 10, // Intenção
        creationDate: 11, // Data de criação
        follow: 12, // Seguir
        addNew: 13, // Adiciona novo,
        subject: 14,// Assunto
        action: 15, // Ação
        error: 16, // Erro
        metadatas: 17, // Metadados
        settings: 18, // Configurações
        groups: 19, // Grupos
        email: 20,
        templates: 21,
        entity: 22,
        duration: 23, // Duração
        newBotSubjet: 24, // Novo assunto 
        newBotAction: 25, // Nova ação
    }
} as const);

export const commonScreenOptions4 = typedScreenOptions({
    idSerializable: EAllSerializable.Common4,
    fields: {
        nick: 1, // Apelido
        phone: 2, // Telefone
        city: 3, // Cidade
        birthday: 4, // Data de Nascimento
        country: 5, // País
        presentation: 6, // Apresentação
        reactions: 7, // Reações
        of: 8, // de
        refresh: 9, // Atualizar
        history: 10, // Histórico
        success: 11, // Sucesso
        security: 12, // Segurança
        fields: 13, // Campos
        templateName: 14, // Nome do Template
        editTemplate: 15, // Editar Template
        services: 16, // Serviços
        see: 17, // Ver
        user: 18, // Usuário

        and: 19, // e
        minute: 20, // minuto
        hour: 21, // hora
        second: 22, // segundo

        minutes: 23, // minutos
        hours: 24, // horas
        seconds: 25, // segundos
        month: 26, // mês
        months: 27 // meses
    }
} as const);

export const commonScreenOptions5 = typedScreenOptions({
    idSerializable: EAllSerializable.Common5,
    fields: {
        monday: 1, // Segunda
        tuesday: 2, // Terça
        wednesday: 3, // Quarta
        thursday: 4, // Quinta
        friday: 5, // Sexta
        saturday: 6, // Sábado
        sunday: 7, // Domingo
        january: 8, // Janeiro
        february: 9, // Fevereiro
        march: 10, // Março
        april: 11, // Abril
        may: 12, // Maio
        june: 13, // Junho
        july: 14, // Julho
        august: 15, // Agosto
        september: 16, // Setembro
        october: 17, // Outubro
        november: 18, // Novembro
        december: 19, // Dezembro,
        goodMorning: 20, // Bom dia 
        goodAfternoon: 21, // Boa tarde
        goodEvening: 22, // Boa noite
        fail: 23, // Falha
        result: 24, // Resultado
        ok: 25,
        none: 26,
    }
});


export const commonScreenOptionsTranslations = getTranslationsFields(commonScreenOptions);
export const commonScreenOptionsTranslations2 = getTranslationsFields(commonScreenOptions2);
export const commonScreenOptionsTranslations3 = getTranslationsFields(commonScreenOptions3);
export const commonScreenOptionsTranslations4 = getTranslationsFields(commonScreenOptions4);

export const allCommonScreenOptionsTranslations = {
    ...commonScreenOptionsTranslations,
    ...commonScreenOptionsTranslations2,
    ...commonScreenOptionsTranslations3,
    ...commonScreenOptionsTranslations4,
    ...getTranslationsFields(commonScreenOptions5),
    ...getNonSerializableFriendlyNameTranslations(),
};

// export const friendlyNonSerializableNameFieldTranslations = {

// }