
import { EConnectionType } from '@colmeia/core/src/shared-business-rules/connections/endpoint-model';
import { Processor } from '@colmeia/core/src/shared-business-rules/knowledge-base/entity-processor';
import {
    IKnowledgeDBServer
} from '@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces';
import { EOmniSenseMethods, IMLLuisEntity, IMLLuisIntent, IntentId, IOminiSenseIAConfig, IOmniSenseStrategyConfig, luisTrainResultTypeConfig } from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces';
import { getStrategyWeight, initMLLuisApp } from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-core.functions';
import { empty, isEqual, isInvalid, isValidRef, isValidString, MapBy, typedCloneLodash } from '@colmeia/core/src/tools/utility';
import { DeepMap } from '@colmeia/core/src/tools/utility-types';
import { factoryOf } from "@colmeia/core/src/tools/utility/functions/factory-of";
import { isValidTyped } from '@colmeia/core/src/tools/utility/functions/is-valid';
import { TArrayID } from '../../core-constants/types';


const emptyKBApp = initMLLuisApp()
function isEmptyKB(kb: IKnowledgeDBServer) {
    kb = typedCloneLodash(kb);
    kb.app.appName = empty;
    kb.app.appId = empty;
    return isEqual(kb.app, emptyKBApp);
}





export class KBProcessor extends Processor<IKnowledgeDBServer> {
    static factory = factoryOf(this);
    static create = factoryOf(this);

    private mapStrategies!: MapOSStrategy;
    private changeIntentMapStrategies!: MapOSStrategy;

    private mapIntentsById!: DeepMap<[intentId: IntentId, intent: IMLLuisIntent]>;
    private mapEntitiesById!: DeepMap<[id: string, entity: IMLLuisEntity]>;
    public isEmptyKB?: boolean;

    public isUsingDeprecatedConnection(): boolean {
        return this.isUsingStrategy(EOmniSenseMethods.machineLearning) && (this.entity.connectionType === EConnectionType.Luis);
    }

    // get canChangeConnection() {
    //     if (!this.entity) return;
    //     if (!this.backup) return;
    //     return !isValidRef(this.entity?.idConnection);
    // }

    getMapStrategies() {
        this.changeIfNecessary({
            fn: ns => ns.app?.osStrategy,
        });
        return this.mapStrategies;
    }
    getChangeIntentMapStrategies() {
      this.changeIfNecessary({
          fn: ns => ns.app?.intentionChangeConfig,
      });
      return this.changeIntentMapStrategies;
    }

    getMapIntentsById() {
        this.changeIfNecessary({
            fn: ns => ns.app?.intents,
        });
        return this.mapIntentsById;
    }
    getMapEntitiesById() {
        this.changeIfNecessary({
            fn: ns => ns.app?.entities,
        });
        return this.mapEntitiesById;
    }
    isUsingStrategy(strategy: EOmniSenseMethods): boolean {
        const config = this.getMapStrategies().get(strategy) ?? {
            isOn: false,
        };

        if (strategy === EOmniSenseMethods.machineLearning) {
            // isInvalidArray(this.entity.app.osStrategy?.strategy) &&
            return isValidString(this.entity.idConnection) && isValidRef(this.entity.app?.appId) && config.isOn;
        }


        return config?.isOn ?? false;
    }

    isUsingStrategyInChangeIntent(strategy: EOmniSenseMethods): boolean {
      const config = this.getChangeIntentMapStrategies().get(strategy) ?? {
        isOn: false,
      };

      if (strategy === EOmniSenseMethods.machineLearning) {
          // isInvalidArray(this.entity.app.osStrategy?.strategy) &&
          return isValidString(this.entity.idConnection) && isValidRef(this.entity.app?.appId) && config.isOn;
      }


      return config?.isOn ?? false;
    }

    hasStrategy(strategy: EOmniSenseMethods): boolean {
        const strategyConfig = this.getMapStrategies().get(strategy);
        return isValidRef(strategyConfig);
    }
    hasChangeStrategy(strategy: EOmniSenseMethods): boolean {
      const strategyConfig = this.getChangeIntentMapStrategies().get(strategy);
      return isValidRef(strategyConfig);
    }

    public isUsingCLU() {
        return this.isUsingStrategy(EOmniSenseMethods.machineLearning) && (this.entity.connectionType === EConnectionType.CLU);
    }

    isLastTrainError() {
        return (this.entity.lastTrain?.status) && luisTrainResultTypeConfig[this.entity.lastTrain.status].isError;
    }

    get getStrategyWeight() {
        return getStrategyWeight(this.entity.app.osStrategy, this.getMapStrategies())
    }

    isUsingLuisConnection(): boolean {
        return isValidTyped(this.entity.idConnection) && this.isUsingStrategy(EOmniSenseMethods.machineLearning);
    }

    getIntentById(id: IntentId) {
        return this.getMapIntentsById().get(id);
    }
    getEntityById(id: string): IMLLuisEntity | undefined {
        return this.getMapEntitiesById().get(id);
    }



    init(): void {
        this.mapStrategies = mapOSStrategy(this.entity.app?.osStrategy);
        this.changeIntentMapStrategies = mapOSStrategy(this.entity.app?.intentionChangeConfig);

        this.mapIntentsById = MapBy
            .factory(this.entity.app.intents ?? [])
            .by(item => [item.intentId, item])
            .value()
            ;
        this.mapEntitiesById = MapBy
            .factory(this.entity.app.entities ?? [])
            .by(item => [item.entityId, item])
            .value()
            ;
        this.isEmptyKB = isEmptyKB(this.entity);
    }

    addChanges(): void {
        this.addToChange(ns => ns.app?.osStrategy);
        this.addToChange(ns => ns.app?.intents);
        this.addToChange(ns => ns.app?.entities);
    }

    removeIntents(ids: string[]) {
        const set = new Set(ids)
        this.entity.app.intents = this.entity.app.intents.filter(item => !set.has(item.intentId));
    }

    removeEntities(ids: string[]) {
        const set = new Set(ids)
        this.entity.app.entities = this.entity.app.entities.filter(item => !set.has(item.entityId));
    }

    public getDistinctVectors(): TArrayID {
        return []
    }

}

export interface MapOSStrategy extends DeepMap<[strategy: EOmniSenseMethods, strategy: IOmniSenseStrategyConfig]> { }

export function mapOSStrategy(osStrategy: IOminiSenseIAConfig | undefined): MapOSStrategy {
    const map: MapOSStrategy = new Map();
    const strategies = (osStrategy?.strategy ?? (osStrategy as any)?.strategies) ?? [];
    strategies.map(strategy => map.set(strategy.osTatic, strategy));
    return map;
}
