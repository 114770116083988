
import { mapKeys } from 'lodash';

export interface IIndex {
	[key: string]: any
}

export interface IGroup {
	[key: string]: any[]
}

export const indexationHelper = {
	index(data: any[], key: string): IIndex {
		return mapKeys(data, key);
	},
	group(key: string, items: any[]): IGroup {
		const groups = {};
		for (let item of items) {
			if (!groups[item[key]]) groups[item[key]] = [];

			groups[item[key]] = [...groups[item[key]], item];
		}
		return groups
	}
}
