import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'cm-spinner-component',
    templateUrl: './colmeia-spinner.component.html',
    styleUrls: ['./colmeia-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColmeiaSpinnerComponent implements OnInit {
    private static DIAMETER_DEFAULT: number = 30
    @Input() diameter: number

    constructor() { }

    ngOnInit() {
        this.diameter = this.diameter || ColmeiaSpinnerComponent.DIAMETER_DEFAULT
    }
}
