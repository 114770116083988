import { Injectable } from '@angular/core';
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { IBasicElementServer } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces';
import { GraphElement } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element';
import { IGraphElementJSON } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-interfaces';
import { GraphRulesProcessor, IBPMAllElementsByRootIncludingItself } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-rules-processor';
import { IGetAllElementsByRoot } from '@colmeia/core/src/shared-business-rules/knowledge-base/bpm/bpm-req-resp';
import { BpmApiService } from '../../../../../../services/bpm/api/bpm-bot-api.service';

type TAllGraphData = { graph: GraphElement, graphJsonData: IGraphElementJSON }
@Injectable({
    providedIn: 'root'
})
export class BpmGraphViewerService {
    bpmType: EBPMType
    rootElementSaved: IBasicElementServer;

    constructor(
        private bpmApiSvc: BpmApiService
    ) { }

    storeBpmGraphRoot(rootElementSaved: IBasicElementServer) {
        this.rootElementSaved = rootElementSaved
    }

    async initGraph(graphRootId: string, bpmType: EBPMType): Promise<IBPMAllElementsByRootIncludingItself> {
        const response: IGetAllElementsByRoot = await this.bpmApiSvc.getRootAndAllChildren(graphRootId, bpmType);
        return GraphRulesProcessor.initGraphByNodesAndHostedList(bpmType, response);
    }
}
