import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Memoize } from 'typescript-memoize';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TPermissionArray } from '@colmeia/core/src/security/permission';
import { ISecurityRoleChanges, Role, TRoleArray } from '@colmeia/core/src/security/role';
import { hashMapToIterator, hashToArray } from '@colmeia/core/src/tools/utility';
import {ParticipantRoleSelectorHandler} from "../../../../handlers/participant-role-selector.handler";
import {IParticipantSelectorToRolesSelected} from "../../../../handlers/participant-selector.handler";
import {SessionService} from "../../../../services/session.service";
import {GroupPersistorServices} from "../../../../services/group-persistor.services";
import {ParticipantRoleGetterService} from "../../../../services/participant-role-getter.service";
import {RootComponent} from "../../root/root.component";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { TExtendedParticipant } from '@colmeia/core/src/core-constants/types';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
    selector: 'app-participant-role-selector',
    templateUrl: './participant-role-selector.component.html',
    styleUrls: ['./participant-role-selector.component.scss']
})
export class ParticipantRoleSelectorComponent extends RootComponent<'save'> implements OnInit {
    public handler: ParticipantRoleSelectorHandler;
    private participantRoles: IParticipantSelectorToRolesSelected = {};
    private isSavingRoles: boolean = false;
    private possibleRolesArray: TRoleArray

    constructor(
        private sessionSvc: SessionService,
        private groupFunctionService: GroupPersistorServices,
        private participantRoleGetterSvc: ParticipantRoleGetterService,
        private cdr: ChangeDetectorRef,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ParticipantRoleSelectorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { handler: ParticipantRoleSelectorHandler }
    ) {
        super({
            save: gTranslations.common.save
        }, true, cdr);
        if(data) {
            this.handler = data.handler;
        }
    }

    async ngOnInit() {
        const extendedParticipantId = this.handler.getParticipant().getPrimaryID()
        const serverParticipantRoles = await this.participantRoleGetterSvc
            .getRolesAlreadySetInParticipant([extendedParticipantId])
        this.participantRoles = serverParticipantRoles
        this.possibleRolesArray = this.getPossibleRolesArray()
    }

    canShowRolesCheckBoxed(): boolean {
        return Object.keys(this.participantRoles).length > 0
    }

    @Memoize()
    getRoles(): TRoleArray {
        return Role.getActiveRolesArray()
    }

    getPossibleRolesArray(): TRoleArray {
        const participant = this.handler.getParticipant().getPrimaryID()

        return Role.getPossibleRolesArray(this.getGrantor(), this.getGrantee(participant), this.sessionSvc.getAvatarApproverID() )
    }

    getGrantor(): ISecurityRoleChanges {
        return {
            idAvatar: this.sessionSvc.getAvatarID(),
            roles: this.handler.getParticipantRoles().grantorParticipantRoles
        };
    }

    getGrantee(idAvatar: TGlobalUID): ISecurityRoleChanges{

        const participantRole = this.handler.getParticipantRoles().allParticipantRoles
                                    .find(participantRole => participantRole.idAvatar == idAvatar);
        return {
                idAvatar: idAvatar,
                roles:  participantRole
                        ? participantRole.idRoles
                        : []
        };
    }

    isDisableRole(role: Role): boolean {
        return !this.possibleRolesArray.includes(role)
    }

    @Memoize()
    getPermissions(role: Role): TPermissionArray {
        return hashToArray(role.getPermissionArrayList())
    }

    isRoleSelected(role: Role): boolean {
        const roleSelected = this.participantRoles[this.handler.getParticipant().getPrimaryID()]
        if(roleSelected) {
            return roleSelected.roles.includes(role)
        } else {
            this.participantRoles[this.handler.getParticipant().getPrimaryID()] = {roles: []}
        }
        return false
    }

    onRoleModelChange(isSelected: boolean, role: Role) {
        const roles = this.participantRoles[this.handler.getParticipant().getPrimaryID()].roles
        if(isSelected) {
            roles.push(role)
        } else {
            const foundIdx = roles.findIndex(arole => arole.iss(role))
            if(foundIdx > -1) {
                roles.splice(foundIdx, 1)
            }
        }
    }

    onRoleClicked(event: Event): void {
        event.stopPropagation();
    }

    async onSaveClicked() {
        this.isSavingRoles = true;
        for (const [key, value] of hashMapToIterator(this.participantRoles)) {
            const idAvatar: TGlobalUID = key;
            const roles: TRoleArray = value.roles;
            const response = await this.groupFunctionService.setAvatarRoles(
                idAvatar,
                this.handler.getGroupID(),
                roles.map(role => role.getPrimaryID())
            )

            if(!response.executionOK) {
                this._snackBar.open("Erro ao atualizar dados", "", {
                    duration: 2000,
                });
            }

            this.dialogRef.close();
        }

        this.isSavingRoles = false;
    }

    canShowSaveBtnLabel(): boolean {
        return !this.isSavingRoles;
    }

    getRoleLabel(): string {
        return `${
            Serializable.staticFactory(constant.objectType.role).getName()
        } - ${
            this.handler.getParticipant().getName()
        }`
    }
}
