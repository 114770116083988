import { mapBy, values } from "../../tools/utility";
import { TDeepMap } from "../../tools/utility-types";
import { allMetadata } from "../metadata/metadata-db";
import { ECatalogType, TCatalogField } from "./catalog.interfaces";

export const allCatalogFields: TCatalogField[] = values(allMetadata).filter(item => item.catalogConfig).map(item => item.idMetadata as TCatalogField)
export const mapCatalogFieldsByPropertyName: TDeepMap<[propertyName: string, field: TCatalogField]> = mapBy(
    allCatalogFields,
    item => allMetadata[item].catalogConfig![ECatalogType.Meta].key,
)
