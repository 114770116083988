import { Injectable } from "@angular/core";
import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { ETypeOfContact } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { EConfirmationType, TArrayID } from "@colmeia/core/src/core-constants/types";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {
    INewAgentCallBatch,
    INewAgentCallBatchServer,
    INewAgentCallContact,
    INewAgentCallContactServer,
    ISearchActiveCallResult,
    TISearchActiveCallResultArray
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import {
    IActiveCallScheduleSelectedContactsRequest,
    IActiveCallSendActionToSelectedRequest,
    IActiveCallSendActionToSelectedResponse,
    IActiveCallSendSelectedContactsRequest, IAgentSearrchActiveCallCustomerData,
    IBasic1x1CallContact,
    IBasic1x1ContactRequest,
    IGetActiveCallCustomerStatusRequest,
    IGetActiveCustomerStatusResponse,
    IGetChannelOpenTimeRequest,
    IGetChannelOpenTimeResponse,
    IGetCompatibleCampaignActionRequest,
    IGetCompatibleCampaignActionResponse,
    IRunActionCampaignForActiveCallRequest,
    IRunActionCampaignForActiveCallResponse,
    ISaveActiveCallContactRequest,
    ISaveBatchActiveCallRequest,
    ISaveBatchActiveCallResponse,
    ISaveContactActiveCallResponse, ISearchActiveCallCustomerRequest,
    ISearrchActiveCallCustomerResponse,
    IUpdateContactrequest,
    TAllSearchActiveCallCustomerData
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { KBAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { ETypeOfCustomerContact } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import {
    ICMassCommunicationAction,
    TCMassCommActionArray
} from "@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model";
import {
    ECampaignActionType,
    ICAWhatsAppSchedule
} from "@colmeia/core/src/shared-business-rules/campaigns/campaing-comm-strategy";
import { getChannelCommFromAction } from "@colmeia/core/src/shared-business-rules/campaigns/mkt-utils";
import {
    EAttendanceHistorySearchType
} from "@colmeia/core/src/shared-business-rules/search";
import {
    ISocialMediaConnection,
    ISocialMediaConnectionServer,
    IWhatsAppTemplate
} from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { INSWhatsAppTemplateServer } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { LookupService } from "./lookup.service";
import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";
import { ServerCommunicationService } from "./server-communication.service";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { IListNonSerializablesRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IListNonSerializablesResponse } from "@colmeia/core/src/dashboard-control/dashboard-response-interfaces";
import { SnackMessageService } from "./snack-bar";

@Injectable({
    providedIn: "root",
})
export class AttendanceActiveEditCallApiService {
    private mockConfig = {
        enabled: false,
        customers: {
            lucy: { contactName: "lucy-mocked", address: "5521981746873" },
            yuka: { contactName: "yuka-mocked", address: "5521981746874" },
        },
    };

    constructor(
        private api: ServerCommunicationService,
        private lookupSvc: LookupService,
        private snackMsgSvc: SnackMessageService,
    ) { }

    public createNSCacheImplementation =
        this.lookupSvc.createNSCacheImplementation;


    async getWhatsappChannelTemplateNS(
        campaign: ICMassCommunicationAction
    ) {
        const result: ICAWhatsAppSchedule =
            getChannelCommFromAction<ICAWhatsAppSchedule>(
                campaign,
                EDelivery360Action.Delivery360WhatsApp
            );

        if (!result) return;

        const templateNS =
            await this.lookupSvc.getSingleLookupElement<INSWhatsAppTemplateServer>(
                result.idWAFallbackTemplate
            );
        return templateNS;
    }

    async getWhatsappChannelTemplate(
        campaign: ICMassCommunicationAction
    ): Promise<IWhatsAppTemplate> {
        const templateNS = await this.getWhatsappChannelTemplateNS(campaign);
        const whatsTemplate: IWhatsAppTemplate = templateNS?.parameter;
        return whatsTemplate;
    }

    public async getSocialMediaConnection(
        campaign: ICMassCommunicationAction
    ): Promise<ISocialMediaConnectionServer> {
        const result: ICAWhatsAppSchedule =
            getChannelCommFromAction<ICAWhatsAppSchedule>(
                campaign,
                EDelivery360Action.Delivery360WhatsApp
            );
        if (this.mockConfig.enabled) {
            result.idWAFallbackTemplate = "uZwK1JRh8V6bX30QYKnP";
        }

        if (!result) return;

        const allConnections: ISocialMediaConnectionServer[] =
            await this.lookupSvc.getBatchNonSerializables<
                ISocialMediaConnectionServer[]
            >([result.idSocialMediaConnection]);
        const channel: ISocialMediaConnectionServer = allConnections[0];
        return channel;
    }

    public async saveChildContact(
        idParentContentBatch: string,
        targetAvatarId: string,
        data: INewAgentCallContactServer
    ): Promise<ISaveContactActiveCallResponse> {
        const response = (<ISaveContactActiveCallResponse>(
            await this.api.doRequest<ISaveActiveCallContactRequest>(
                apiRequestType.dashboardExtra.attendance.saveContactActiveCall,
                {
                    idParentContentBatch,
                    targetAvatarId,
                    data,
                }
            )
        )) as ISaveContactActiveCallResponse;

        return response;
    }

    public async saveBatch(
        batchNS: INewAgentCallBatch
    ): Promise<INewAgentCallBatchServer> {
        const response = (await this.api.doRequest<ISaveBatchActiveCallRequest>(
            apiRequestType.dashboardExtra.attendance.saveBatchActiveCall,
            {
                batchNS,
            }
        )) as ISaveBatchActiveCallResponse;

        return response.batchNS;
    }

    public async searchCustomersByTarget(
        phoneNumber,
        customersWithOpenCaseOnly,
        customerSearchType
    ): Promise<TISearchActiveCallResultArray> {
        const result = await this.searchCustomers({
            emailNameOrPhoneNumberOrBusinessID: phoneNumber,
            customersWithOpenCaseOnly,
            type: EAttendanceHistorySearchType.byCustomer,
            customerSearchType,
        });
        return result.customers;
    }

    public async searchCustomersByDate(
        beginDate: TISODateString,
        endDate: TISODateString,
        humanAttendanceOnly: boolean
    ): Promise<TISearchActiveCallResultArray> {
        const result = await this.searchCustomers({
            type: EAttendanceHistorySearchType.byDateInterval,
            date: {
                beginDate,
                endDate,
            },
            humanAttendanceOnly
        });
        return result.customersByDate;
    }

    public async searchCustomersByAgentName(
        agentName: string,
        beginDate: TISODateString,
        endDate: TISODateString
    ): Promise<IAgentSearrchActiveCallCustomerData> {
        const result = await this.searchCustomers({
            type: EAttendanceHistorySearchType.byAgent,
            date: {
                beginDate,
                endDate,
            },
            agentName,
        });
        return result.agentsAndCustomersData;
    }

    public async searchCustomers(
        data: TAllSearchActiveCallCustomerData
    ): Promise<ISearrchActiveCallCustomerResponse> {
        // if (this.mockConfig.enabled) {
        //     return this.getMockedSearchedCustomers()
        // }

        const response =
            (await this.api.doRequest<ISearchActiveCallCustomerRequest>(
                apiRequestType.dashboardExtra.attendance
                    .activeCallSearchCustomer,
                {
                    data,
                },
                undefined,
                true
            )) as ISearrchActiveCallCustomerResponse;

        return response;
    }

    public async sendSelectedContacts(selectedIds: string[]): Promise<void> {
        await this.api.doRequest<IActiveCallSendSelectedContactsRequest>(
            apiRequestType.dashboardExtra.attendance
                .activeCallSendSelectedContacts,
            {
                selectedIds,
            }
        );
    }

    public async scheduleSelectedContacts(selectedIds: string[], scheduleAt: number): Promise<void> {
        await this.api.doRequest<IActiveCallScheduleSelectedContactsRequest>(
            apiRequestType.dashboardExtra.attendance
                .activeCallScheduleSelectedContacts,
            {
                selectedIds,
                scheduleClock: scheduleAt
            }
        );
    }


    async saveCampaign(
        contact: INewAgentCallContactServer
    ): Promise<IRunActionCampaignForActiveCallResponse> {
        const response =
            (await this.api.doRequest<IRunActionCampaignForActiveCallRequest>(
                apiRequestType.campaigns.runCampaignForActiveCall,
                {
                    activeCall: contact,
                }
            )) as IRunActionCampaignForActiveCallResponse;

        if (response.cancelReason?.cancelOptOut === true) {
            this.snackMsgSvc.openWarning('Este contato não deseja receber comunicações.', 7000)
        }

        return response;
    }

    async runCampaignForPartial(contactIds: TArrayID, activeCallTemplate: INewAgentCallContact, sendToAllIdParent?: string): Promise<IActiveCallSendActionToSelectedResponse | undefined> {
        const response = await this.api.sendRequest<
            IActiveCallSendActionToSelectedRequest,
            IActiveCallSendActionToSelectedResponse
        >(apiRequestType.dashboardExtra.attendance.sendActionToMultipleContacts)({
            activeCallIds: contactIds,
            campaignData: activeCallTemplate,
            sendToAllIdParent
        });

        return response;
    }

    async searchForTarget(
        target: string,
        channel: EDelivery360Action
    ): Promise<IGetActiveCustomerStatusResponse> {
        if (this.mockConfig.enabled) {
            return this.getMockedSearchResponse(target);
        }

        const request = <IGetActiveCustomerStatusResponse>(
            await this.api.doRequest<IGetActiveCallCustomerStatusRequest>(
                apiRequestType.dashboardExtra.attendance
                    .getActiveCustomerStatus,
                {
                    target: target,
                    channel,
                },
                undefined,
                true
            )
        );

        return request;
    }

    async searchForAvailableCampaignList(
        taggable?: ITagableSearch,
        isPartialSend?: boolean
    ): Promise<TCMassCommActionArray> {
        if (this.mockConfig.enabled) {
            const mock: IGetCompatibleCampaignActionResponse =
                this.getMockedAvailableCampaignList();
            return mock.allowedCampaigns;
        }

        if (isValidRef(taggable)) {
            delete taggable.demandedTag;
        }

        const response = (await this.api.doRequest<IGetCompatibleCampaignActionRequest>(
            apiRequestType.dashboardExtra.attendance
                .getActiveCompactibleCampaignActions,
            {
                cursor: "",
                taggable,
                isPartialSend
            }
        )) as IGetCompatibleCampaignActionResponse;

        return response.allowedCampaigns;
    }

    async getChannelOpenTime(
        target: string,
        campaign: ICMassCommunicationAction,
        channel: EDelivery360Action
    ): Promise<number> {
        const socialMediaConnection: ISocialMediaConnection =
            await this.getSocialMediaConnection(campaign);

        if (!socialMediaConnection) return;

        const response = (await this.api.doRequest<IGetChannelOpenTimeRequest>(
            apiRequestType.dashboardExtra.attendance.getChannelOpenTime,
            {
                target,
                address: socialMediaConnection.address,
                channel,
            }
        )) as IGetChannelOpenTimeResponse;
        return response.openChannelUntil;
    }

    async createContact(
        contact: IBasic1x1CallContact
    ): Promise<IGetActiveCustomerStatusResponse> {
        const response: IGetActiveCustomerStatusResponse =
            (await this.api.doRequest<IBasic1x1ContactRequest>(
                apiRequestType.dashboardExtra.attendance
                    .createActiveCallContact,
                {
                    contact,
                }
            )) as IGetActiveCustomerStatusResponse;

        return response;
    }

    async editContact(
        contact: IBasic1x1CallContact,
        idAvatar: TGlobalUID
    ): Promise<IGetActiveCustomerStatusResponse> {
        const response: IGetActiveCustomerStatusResponse =
            (await this.api.doRequest<IUpdateContactrequest>(
                apiRequestType.dashboardExtra.attendance.editActiveCallContact,
                {
                    contact,
                    idEditingAvatar: idAvatar

                }
            )) as IGetActiveCustomerStatusResponse;

        return response;
    }

    async addContactToList() { }

    private getMockedChannelOpentime(): IGetChannelOpenTimeResponse {
        return {
            responseType: "get-ac-customer-status",
            friendlyError: {
                okState: true,
                functionName: "ActiveCallController::getActiveCustomerStatus",
                friendlyErrorArray: [],
                tips: [],
                sucessMessage: [],
                noFurtherActionInterrupt: false,
                canAutomaticDelete: true,
                deleteDependencies: [],
            },
            openChannelUntil: Date.now() + 86400000,
        };
    }

    private getMockedAvailableCampaignList(): IGetCompatibleCampaignActionResponse {
        return {
            responseType: "get-ac-customer-status",
            cursor: "cursor",
            ignoredBySecurity: 0,
            friendlyError: {
                okState: true,
                functionName: "ActiveCallController::getActiveCustomerStatus",
                friendlyErrorArray: [],
                tips: [],
                sucessMessage: [],
                noFurtherActionInterrupt: false,
                canAutomaticDelete: true,
                deleteDependencies: [],
            },
            allowedCampaigns: [
                {
                    //   "scheduleBeginning": 1606325549000,
                    onOngoingConversation: {
                        reschedule: 1,
                        cancel: false,
                    },
                    idCampaigAction: "EfnihXgJaeHYD9Atblo3kRW5JIyyUS",
                    minimumConfirmation: EConfirmationType.receive,
                    actionName: "Primeira ação",
                    idSchemma: "wGIwhcCXANdjEh9DM6lQegU2Up1Nls",
                    commStrategy: [
                        {
                            //   "offSetOtherChannelsIfPrefered": 60000,
                            clockTick: 1606314509577,
                            idSocialMediaConnection:
                                "otemaL3vsgkuksvOHb7Ko5SdfyXG0Oif",
                            channelType: EDelivery360Action.Delivery360SMS,
                            offset: 60000,
                        },
                    ],
                    idBot: "vT32kLqBubtc0an5BMjb2x3LO1CtwRTV",
                    messages: [
                        {
                            variablesTemplate: {
                                messageIfNoBind: "Caro Fallbacker",
                                compiledTemplate: "Caro {{CkwrlvW9xR}}",
                                variables: [
                                    {
                                        idProperty: "CkwrlvW9xR",
                                    },
                                ],
                            },
                            media: {
                                mymeType: null,
                                idMediaType: null,
                                idMedia: null,
                                size: null,
                            },
                            content: "Caro {{Nome}}",
                            type: KBAssetType.content,
                            idAsset: "HNNnQhajc3",
                        },
                        {
                            idAsset: "JiKHXmsFBc",
                            type: KBAssetType.content,
                            variablesTemplate: {
                                compiledTemplate: "Seu Email é {{CJC0WgCeG3}}",
                                variables: [
                                    {
                                        idProperty: "CJC0WgCeG3",
                                    },
                                ],
                                messageIfNoBind: "Seu Email é Fallbacker",
                            },
                            content: "Seu Email é {{Email}}",
                            media: {
                                size: null,
                                idMediaType: null,
                                idMedia: null,
                                mymeType: null,
                            },
                        },
                        {
                            content:
                                "Seus outros dados são Facebook ID  {{Facebook ID}}, ID Solicitação {{ID Solicitação}}, Tipo de Mensagem {{Tipo de Mensagem}} e Informação {{Informação}}",
                            variablesTemplate: {
                                messageIfNoBind:
                                    "Seus outros dados são Fallbacker",
                                variables: [
                                    {
                                        idProperty: "UFDDWCSLkR",
                                    },
                                    {
                                        idProperty: "NgwTzeYsU3",
                                    },
                                    {
                                        idProperty: "qwW5Y77lEO",
                                    },
                                    {
                                        idProperty: "yEdmZhliUz",
                                    },
                                ],
                                compiledTemplate:
                                    "Seus outros dados são Facebook ID  {{UFDDWCSLkR}}, ID Solicitação {{NgwTzeYsU3}}, Tipo de Mensagem {{qwW5Y77lEO}} e Informação {{yEdmZhliUz}}",
                            },
                            type: KBAssetType.content,
                            media: {
                                idMedia: null,
                                size: null,
                                mymeType: null,
                                idMediaType: null,
                            },
                            idAsset: "gNAhi9p1ta",
                        },
                    ],
                    actionType: ECampaignActionType.massComm,
                },
                {
                    idCampaigAction: "q2j6nbWZBYFTOb1h8bsZ3upCutNzl1iU",
                    actionType: ECampaignActionType.massComm,
                    actionName: "Campanha 1",
                    idSchemma: "",
                    messages: [],
                    commStrategy: [
                        {
                            channelType: EDelivery360Action.Delivery360WhatsApp,
                            offset: 0,
                            idSocialMediaConnection:
                                "JcIfASXGXoJl7xTJrL9vbAHFqAM6VmaQ",
                        },
                    ],
                    onOngoingConversation: {
                        reschedule: 0,
                        cancel: false,
                    },
                } as any,
                {
                    idCampaigAction: "id2",
                    actionType: ECampaignActionType.massComm,
                    actionName: "Campanha 2",
                    idSchemma: "",
                    messages: [],
                    commStrategy: [
                        {
                            channelType: EDelivery360Action.Delivery360WhatsApp,
                            offset: 0,
                            idSocialMediaConnection: "",
                        },
                    ],
                    onOngoingConversation: {
                        reschedule: 0,
                        cancel: false,
                    },
                } as any,
            ],
        };
    }

    private getMockedSearchResponse(
        target: string
    ): IGetActiveCustomerStatusResponse {
        const mock = {
            responseType: "get-ac-customer-status",
            friendlyError: {
                okState: true,
                functionName: "ActiveCallController::getActiveCustomerStatus",
                friendlyErrorArray: [],
                tips: [],
                sucessMessage: [],
                noFurtherActionInterrupt: false,
                canAutomaticDelete: true,
                deleteDependencies: [],
            },
            email: "",
            contactName: "",
            idAvatar: '',
            serviceHistory: [
                {
                    idConversation: "",
                    idSocialContext: "",
                    idAvatar: "",
                    target: "",
                    idCampaigAction: "",
                    clockTick: new Date().getTime() - 1000000,
                    channel: EDelivery360Action.Delivery360WhatsApp,
                    typeOfCustomerContact: ETypeOfCustomerContact.inbound,
                },
                {
                    idConversation: "",
                    idSocialContext: "",
                    idAvatar: "",
                    target: "",
                    idCampaigAction: "",
                    clockTick: new Date().getTime() - 2000000,
                    channel: EDelivery360Action.Delivery360FBMessenger,
                    typeOfCustomerContact: ETypeOfCustomerContact.outbound,
                },
            ],
            contact: [
                {
                    type: ETypeOfContact.mobile,
                    isDefault: true,
                    address: "",
                    isChecked: true,
                    idCheckedReceive360: "idWhatsapp", // endereço checado pelo delivery360
                },
            ],
            openChannelUntil: 1611177296000,
        };

        if (this.mockConfig.customers.lucy.address == target) {
            mock.contactName = this.mockConfig.customers.lucy.contactName;
            mock.contact[0].address = this.mockConfig.customers.lucy.address;
        } else {
            mock.contactName = this.mockConfig.customers.yuka.contactName;
            mock.contact[0].address = this.mockConfig.customers.yuka.address;
        }

        return mock;
    }

    private getMockedSearchedCustomers(): Promise<ISearchActiveCallResult[]> {
        return Promise.resolve([
            {
                target: this.mockConfig.customers.lucy.address,
                name: this.mockConfig.customers.lucy.contactName,
                channel: EDelivery360Action.Delivery360WhatsApp,
                idAvatar: "idMocked",
                email: "foo@bar.com",
                idSocialKey: "",
            },
            {
                target: this.mockConfig.customers.yuka.address,
                name: this.mockConfig.customers.yuka.contactName,
                channel: EDelivery360Action.Delivery360WhatsApp,
                idAvatar: "idMocked2",
                email: "foo@bar.com",
                idSocialKey: "",
            },
        ]);
    }

    public async getActiveCallBatch(): Promise<INewAgentCallBatchServer[]> {
        const res = await this.api.sendRequest<IListNonSerializablesRequest, IListNonSerializablesResponse<INonSerializable>>
            (apiRequestType.dashboardExtra.attendance.activeCallBatch)({
                nsType: ENonSerializableObjectType.activeCallBatch,
                multipleCursor: null
            });

        return <INewAgentCallBatchServer[]>res.nonSerializableArray;
    }
}
