import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultimediaInstance, MultiMediaType, TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { IMMedia, IMultimediaPlayer } from './multimedia-player.model';
import {SignalListenerService} from "../../../services/signal/signal-listener";
import {IColmeiaDialogComponentData} from "../dialog/dialog.component";
import { IMultimediaPlayerComponentHandler } from 'app/handlers/multimedia-player-component.handler';
import { MultimediaService } from 'app/services/multimedia.service';

@Component({
    selector: 'app-multimedia-player',
    templateUrl: './multimedia-player.component.html',
    styleUrls: ['./multimedia-player.component.scss']
})
export class MultimediaPlayerComponent implements OnInit, OnDestroy {
    @Input()
    handler: IMultimediaPlayerComponentHandler;
    private mmPlayerData: IMultimediaPlayer;
    private selectedMultimedia: IMMedia;

    constructor(
        private cdRef: ChangeDetectorRef,
        private listenerSvc: SignalListenerService,
        private mmSvc: MultimediaService,
        @Inject(MAT_DIALOG_DATA) data: IColmeiaDialogComponentData<IMultimediaPlayerComponentHandler>,
    ) {
        if(!this.handler)
            this.handler = data.getParamsToChildComponent();
    }

    ngOnInit() {
        this.mmPlayerData = {
            medias: this.handler.getMultimediaList(),
            selectedMMIdx: this.handler.getSelectedMMIdx(),
        };

        const selectedIdx = this.mmPlayerData.selectedMMIdx
            ? this.mmPlayerData.selectedMMIdx
            : 0;
        this.selectedMultimedia = {
            currentSelectedIdx: selectedIdx,
            mmInstance: this.mmPlayerData.medias[selectedIdx],
        };
    }

    ngOnDestroy() {
        this.listenerSvc.destroySubscriptions(this);
    }

    canShowMultimedia(): boolean {
        return this.mmPlayerData && this.mmPlayerData.medias.length !== 0;
    }

    getSelectedMedia(): MultimediaInstance {
        return this.selectedMultimedia.mmInstance;
    }

    getCurrentSelectedMediaNumber(): number {
        return this.selectedMultimedia.currentSelectedIdx + 1;
    }

    getTotalMediaNumber(): number {
        return this.mmPlayerData && this.mmPlayerData.medias.length;
    }

    canShowArrows(): boolean {
        return this.mmPlayerData.medias.length > 1;
    }

    getMediaMimeType(): string {
        return this.selectedMultimedia.mmInstance.getMimeType();
    }

    // media controls
    prevMedia(): void {
        this.changeMedia(-1);
    }
    nextMedia(): void {
        this.changeMedia(1);
    }
    private changeMedia(moveOffset: number) {
        const idx = this.selectedMultimedia.currentSelectedIdx;
        const isPossibleToChangeCurrentMedia = this.mmPlayerData.medias[idx + moveOffset] !== undefined
        if (isPossibleToChangeCurrentMedia) {
            this.selectedMultimedia = {
                currentSelectedIdx: idx + moveOffset,
                mmInstance: this.mmPlayerData.medias[idx + moveOffset],
            };
        }
    }

    // checks multimedia type
    isVideo() {
        return MultiMediaType.getMutimediaTypeFromMime(
            this.selectedMultimedia.mmInstance.getMimeType()
        ).isVideo();
    }
    isAudio() {
        return MultiMediaType.getMutimediaTypeFromMime(
            this.selectedMultimedia.mmInstance.getMimeType()
        ).isAudio();
    }
    isImage() {
        return MultiMediaType.getMutimediaTypeFromMime(
            this.selectedMultimedia.mmInstance.getMimeType()
        ).isImage();
    }

    getStyle(): Object{
        if(this.isImage()){
            return {
                'height': 'auto',
                'width': 'auto',
                'max-height': '80vh',
                'max-width': '80vw',
            }
        }
    }

    getUrl(mmInstance: MultimediaInstance): string {
        return this.mmSvc.getMultimediaBestUrl(mmInstance);
    }

}
