import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { NSSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service";
import { IArchiveTemplate, IArchiveTemplateRequest, IArchiveTemplateResponse } from "@colmeia/core/src/shared-business-rules/social-media/social-media-req-resp";
import { IGenericNSTemplateServer, INSWhatsAppTemplateServer } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { IBaseGetTemplateProviderApproval, IBaseGetTemplateProviderApprovals, IBaseGetTemplateProviders, IGetTemplateProviderApprovalRequest, IGetTemplateProviderApprovalResponse, IGetTemplateProviderApprovalsRequest, IGetTemplateProviderApprovalsResponse, IGetTemplateProvidersRequest, IGetTemplateProvidersResponse, IGetTemplateRequest, IGetTemplateResponse, ISaveTemplateRequest, ISaveTemplateResponse, TGroupedSocialProvidersByIdProvider } from "@colmeia/core/src/shared-business-rules/social-media/template-req-resp";
import { isValidFunction, isValidRef } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { routeID, routeList } from "app/model/routes/route-constants";
import { ColmeiaDialogService } from "app/services/dialog/dialog.service";
import { RequestBuilderServices } from "app/services/request-builder.services";
import { RouteCreator } from "app/services/routing-builder";
import { RoutingService } from "app/services/routing.service";
import { ServerCommunicationService } from "app/services/server-communication.service";
import { SessionService } from "app/services/session.service";
import { DynamicComponentHandler } from "../../dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler";
import { DynamicDialogComponent } from "../../dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component";
import { WhatsappTemplateApprovalComponent } from "../../dashboard-social-media/whatsapp-templates/whatsapp-template-approval/whatsapp-template-approval.component";
import { IWhatsappTemplateApprovalParameters, WhatsappTemplateApprovalHandler } from "../../dashboard-social-media/whatsapp-templates/whatsapp-template-approval/whatsapp-template-approval.handler";
import { NewNotificationsService } from 'app/services/new-notifications.service';


const baseRoute = routeList.dashboard.children.marketing.children.channelsTemplates

@Injectable({
    providedIn: "root",
})
export class TemplatesService {

    private components: {
        dynamicDialog: typeof DynamicDialogComponent;
        templateApproval: typeof WhatsappTemplateApprovalComponent;
    };
    private ns?: IGenericNSTemplateServer;
    public isFirstSave: boolean;

    public constructor(
		private routeSvc: RoutingService,
		private rbs: RequestBuilderServices,
		private session: SessionService,
		private api: ServerCommunicationService,
        private dialogSvc: ColmeiaDialogService,
        private newNotificationsSvc: NewNotificationsService,
	) { }

    public goToList(): void {
		this.routeSvc.navigateToId(
			routeID.dashboard,
            routeList.dashboard.children.marketing.path,
			baseRoute.path,
			baseRoute.children.list.path,
		)
	}

    public goToCreate(): void {
        this.isFirstSave = false;
		this.routeSvc.navigateToId(
			routeID.dashboard,
            routeList.dashboard.children.marketing.path,
			baseRoute.path,
			baseRoute.children.create.path
		)
	}

    public goToEditAndSetCache(ns: IGenericNSTemplateServer): void {
        this.set(ns);
		this.goToEdit(ns.idNS!);
	}

    public navigator = RouteCreator(
        RouteCreator.routes.dashboard.marketing.channelsTemplates.details,
    );

    public goToEdit(idNS: string) {
		return this.navigator({
            idNS,
        })
	}

    public async getProviders(options: IBaseGetTemplateProviders): Promise<TGroupedSocialProvidersByIdProvider> {
        const response = await this.api.quick<IGetTemplateProvidersRequest, IGetTemplateProvidersResponse>()({
            requestType: apiRequestType.dashboardExtra.marketing.getTemplateProviders,
            ...options,
        });
        return response.grouped;
    }

    public async onArchive(input: IArchiveTemplate) {
        return this.api.sendRequest<IArchiveTemplateRequest, IArchiveTemplateResponse>(apiRequestType.dashboardExtra.marketing.archiveWhatsAppTemplate)(input);
    }

    public async updateApproval(options: IBaseGetTemplateProviderApproval) {
        const response = await this.api.sendRequest<IGetTemplateProviderApprovalRequest, IGetTemplateProviderApprovalResponse>(apiRequestType.dashboardExtra.marketing.updateTemplateProviderApproval)(options);
        return response;
    }

    public async updateApprovals(options: IBaseGetTemplateProviderApprovals): Promise<INSWhatsAppTemplateServer | undefined>  {
        const response = await this.api.sendRequest<IGetTemplateProviderApprovalsRequest, IGetTemplateProviderApprovalsResponse>(apiRequestType.dashboardExtra.marketing.updateTemplateProviderApprovals)({
            ...options,
        });
        const job = await this.newNotificationsSvc.waitJob({
            idJob: response?.idJob!,
        });
        // 
        return NSSharedService.getNS(options.idTemplate);
    }


    public getEditingId(): string {
        return this.navigator.getParams().idNS;
    }

    
    public set<T extends IGenericNSTemplateServer>(template: T): void {
        this.ns = template;
    }

    public async get<T extends IGenericNSTemplateServer>(idNS: string): Promise<T | undefined> {
        if (!idNS) return;

        if (this.ns?.idNS === idNS) {
            const { ns } = this;
            this.ns = undefined;
            return ns as T;
        }

        const response = await this.api.doRequest<IGetTemplateRequest>(apiRequestType.dashboardExtra.marketing.template, {
            idNS,
        }) as IGetTemplateResponse;

        return response.ns as T;
    }

    public async save(ns: IGenericNSTemplateServer) {
        const response = await this.api.quick<ISaveTemplateRequest, ISaveTemplateResponse>()({
            requestType: apiRequestType.dashboardExtra.marketing.saveTemplate,
            ns,
        })
        return response?.ns;
    }

    public openTemplateApproval(parameters: IWhatsappTemplateApprovalParameters, setDialogRef?: (ref: MatDialogRef<any>) => void): Promise<void> {
        return this.dynamicDialog(this.components.templateApproval, WhatsappTemplateApprovalHandler.factory(parameters), setDialogRef)
    }

    public setDynamicDialogComponent(components: typeof TemplatesService.prototype.components): void {
        this.components = components;
    }

    public dynamicDialog<Component, Handler, T>(
        component: Component,
        handler?: Handler,
        setDialogRef?: (ref: MatDialogRef<any>) => void,
    ): Promise<void> {
        const ref = this.dialogSvc.open({
            componentRef: this.components.dynamicDialog,
            hideHeader: true,
            dataToComponent: {
                data: DynamicComponentHandler.factory({
                    component,
                    handler: (handler as unknown) as MainHandler,
                }),
            },
        })
        if (isValidFunction(setDialogRef)) setDialogRef(ref);
        return this.waitDialog(ref);
    }

    private waitDialog<T>(dialogRef: MatDialogRef<T>): Promise<void> {
        return new Promise((resolve) =>
            dialogRef.beforeClosed().subscribe(() => {
                resolve();
            })
        );
    }

}
