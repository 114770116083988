import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { EUnitTypeID } from '@colmeia/core/src/business/constant.enums';
import { GeneralFormField } from '@colmeia/core/src/general-form/general-form-answer';
import { IConditionalData, TValidateFunction } from '@colmeia/core/src/general-form/general-form-condition-processor';
import { TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { isInvalid, isValidRef, values } from '@colmeia/core/src/tools/utility';
import { UserFunctionsService } from 'app/components/dashboard/functions-page/services/user-functions.service';
import { NotificationDialogService } from 'app/components/notifications-dialog/notification-dialog.service';
import { EFormControlStatus } from 'app/model/client-utility-types';
import { GeneralFormFieldRenderer } from 'app/model/general-form.model';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { TwoFactorAuthService } from 'app/services/two-factor-auth.service';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { GeneralFormEvaluator } from '@colmeia/core/src/general-form/general-form-evaluator';
import { IBasicCondition } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
export interface IListenUpdateDisplayFormFieldClient extends GeneralFormFieldRenderer<string> {

}
@Injectable({
    providedIn: 'root'
})
export class GeneralFormFieldService {
    static SelfHandledMultipleAnswer: EUnitTypeID[] = [
        EUnitTypeID.stringType,
        EUnitTypeID.numberType
    ];
    public isSelfHandledMultipleAnswer(idUnitType: EUnitTypeID): boolean {
        return GeneralFormFieldService.SelfHandledMultipleAnswer.includes(idUnitType)
    }

    private twoFactorSvc: TwoFactorAuthService;

    constructor(
        private userFunctionsService: UserFunctionsService,
        private embedded: EmbeddedChatService,
    ) { }

    public setTwoFactorSvc(twoFactorSvc: TwoFactorAuthService): void {
        this.twoFactorSvc = twoFactorSvc;
    }

    isValidInputField = (info: {
        fGroup: UntypedFormGroup,
        generalFormEvaluator: GeneralFormEvaluator,
        schemaForm: TSchemaPropertyArray,
        fnValidator?: TValidateFunction
    }): AsyncValidatorFn => {
        return async (control: AbstractControl): Promise<ValidationErrors | null> => {
            const isFormFieldOk = null
            info.fnValidator = info.fnValidator
                ? info.fnValidator
                : (cond: IBasicCondition, v: IConditionalData) => this.userFunctionsService.validate(cond, v)

            if (!info.fGroup || !info.generalFormEvaluator
                || (Object.keys(info.fGroup.controls).length != info.schemaForm.length)
                || !Object.values(info.fGroup.controls).some(control => !control.pristine)
            ) {
                return isFormFieldOk
            }

            let errorMsg = ''

            const formIdValueHash = this.getFormIdValueMap(info.fGroup, info.schemaForm)
            const [propId, value] = this.getFormFieldData(info.fGroup, control)
            const result = await info.generalFormEvaluator.validateFormField(
                propId,
                value,
                info.fnValidator,
                formIdValueHash
            );

            let isFieldWithValidationError = result.hasAction && !result.isSuccess
            if (result.hasAction && !result.isSuccess) {
                errorMsg = result.errorMsg
            }

            if (this.embedded.isAnEmbedInstance()) {
                if (!isFieldWithValidationError) {
                    if (this.twoFactorSvc.isTokenField(propId)) {
                        return this.twoFactorSvc.validateTokenField(info.fGroup, control);
                    }
                }
            }


            console.log({ propId, value, isFieldWithValidationError: result, errorMsg });

            return isFieldWithValidationError ? { errorMsg } : isFormFieldOk
        };
    }


    clients: IListenUpdateDisplayFormFieldClient[] = [];

    public listenUpdateDisplayFormField = debounce(async (status?: EFormControlStatus): Promise<void> => {
        if (status !== EFormControlStatus.PENDING) {
            await Promise.all(this.clients.map(client => client.subUpdateDisplayFormField()));
            await Promise.all(this.clients.map(client => client.subCheckIfCanEnableFormFieldByOrder()));
        }
    })


    subscription: Subscription;

    public addListenUpdateDisplayFormField(client: IListenUpdateDisplayFormFieldClient): void {
        this.clients.push(client);
        this.listenUpdateDisplayFormField();

        if (!this.subscription) {
            this.subscription = client.fGroup.statusChanges.subscribe(this.listenUpdateDisplayFormField);
        }
    }

    public removeListenUpdateDisplayFormField(client: IListenUpdateDisplayFormFieldClient): void {
        if (!this.clients.includes(client)) return;

        const foundIndex: number = this.clients.indexOf(client)
        this.clients.splice(foundIndex, 1);
        this.subscription = undefined;
    }

    public shouldUpdateFgroupSubscription(client: IListenUpdateDisplayFormFieldClient): boolean {
        return this.clients[0].fGroup !== client.fGroup;
    }

    async canDisplayFormField(
        fGroup: UntypedFormGroup,
        generalFormEvaluator: GeneralFormEvaluator,
        form: TSchemaPropertyArray,
        field: GeneralFormField,
        fields: GeneralFormField[],
    ): Promise<boolean> {
        const fieldControl = fGroup.get(field.idProperty)
        if (!generalFormEvaluator || !fieldControl) {
            return true
        }


        const result: boolean = await generalFormEvaluator.canDisplayFormField(
            field.idProperty,
            field.value,
            (cond: IBasicCondition, v: IConditionalData) => this.userFunctionsService.validate(cond, v),
            this.getFormIdValueMap(fGroup, form),
        )

        console.log({
            canDisplayFormFieldResult: result,
            name: field.propertyName,
            idProperty: field.idProperty,
            fieldValue: field.value,
        });

        return fieldControl && result;
    }

    public checkIfCanEnableFormFieldByOrder(
        fGroup: UntypedFormGroup,
        generalFormEvaluator: GeneralFormEvaluator,
        form: TSchemaPropertyArray,
        field: GeneralFormField,
        fields: GeneralFormField[],
    ): Promise<void> {
        const fieldControl = fGroup.get(field.idProperty)
        if (!generalFormEvaluator || !fieldControl) {
            return;
        }

        if (this.embedded.isAnEmbedInstance()) {
            const canEnableFieldByOrder: boolean = this.canEnableFormFieldByOrder(
                fGroup,
                form,
                field.idProperty,
                fields,
            )

            if (!canEnableFieldByOrder) {
                this.setFieldStatus(fieldControl, EFormControlStatus.DISABLED);
            } else if (fieldControl.status === EFormControlStatus.DISABLED) {
                this.setFieldStatus(fieldControl, EFormControlStatus.VALID);
            }
        }
    }

    public setFieldStatus(fieldControl: AbstractControl, status: EFormControlStatus): void {
        // @ts-ignore
        fieldControl['status'] = status;
    }

    public canEnableFormFieldByOrder(
        fGroup: UntypedFormGroup,
        form: TSchemaPropertyArray,
        idProperty: string,
        fields: GeneralFormField[],
    ): boolean {
        const validFields = fields
            .filter(item => item.idUnity !== EUnitTypeID.objectType || item.idProperty === idProperty)
            .filter(item => item.canDisplayField)
            ;
        const index = validFields.findIndex(item => item.idProperty === idProperty);
        if ([0, -1].includes(index)) return true;

        const previousField: GeneralFormField = validFields[index - 1];
        const previousControl: AbstractControl = fGroup.get(previousField.idProperty);
        return (
            (previousControl.status === EFormControlStatus.VALID) &&
            isValidRef(previousControl.value) &&
            previousField.canDisplayField
        );
    }


    public onFocusField(
        fGroup: UntypedFormGroup,
        idProperty: string,
    ): Promise<void> {
        if (this.embedded.isAnEmbedInstance()) {
            if (this.twoFactorSvc.isTokenField(idProperty)) {
                return this.twoFactorSvc.dispatchTokenField(fGroup, idProperty)
            }
        }
    }

    getFormIdValueMap(fGroup: UntypedFormGroup, form: TSchemaPropertyArray): { [idProperty: string]: string; } {
        const formIdValueMap = {}

        for (const [idProperty, filledValue] of Object.entries(fGroup.controls)) {
            formIdValueMap[idProperty] = filledValue.value
            const foundField = form.find(field => field.idProperty == idProperty)
            if (foundField) {
                formIdValueMap[foundField.idLocalCanonical] = filledValue.value
            }
        }

        return formIdValueMap
    }

    getFormFieldData(fGroup: UntypedFormGroup, control: AbstractControl): [string, string] {
        const formFieldPropId = (Object.keys(fGroup.controls)
            .find(key => fGroup.controls[key] === control));
        const formFieldValue = control.value
        return [formFieldPropId, formFieldValue]
    }
}
