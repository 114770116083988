import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { typedScreenOptions } from "../../../tools/utility";


export const generalFormViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.GeneralForm,
    fields: {
        canonical: 1,  // Canonização
        multiple: 2,  // Múltiplo
        required: 3,  // Obrigatório
        option: 4,  // Opção
        addField: 5,  // Adicionar Campo
        propNameLabel: 6,  // Código da Propriedade
        internalBehavior: 7,  // Comportamento interno
        crmIntegrationField: 8, // Campo da Integração CRM
        crmIntegration: 9, // Integração CRM
    }
} as const);

export const generalFormTranslations = {
    ...getTranslationsFields(generalFormViewScreenOptions),

};