<cm-generic-dashboard-edit *ngIf="genericNserEditHandler" [handler]="genericNserEditHandler">
  <mat-dialog-content>
    <mat-tab-group class="boxed-tabs boxed-dense tabs-wrap mat-tab-padding-2x" animationDuration="200ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Configurações gerais
        </ng-template>

        <ng-template matTabContent>
          <app-crm-graph-basic-data *ngIf="basicCRMHandler" [handler]="basicCRMHandler"></app-crm-graph-basic-data>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">memory</mat-icon>&nbsp;
          Processamento de ação?
        </ng-template>

        <ng-template matTabContent>
          <app-new-condition-editor *ngIf="conditionHandler" [handler]="conditionHandler" [autoOpenWhenEmpty]="false">
          </app-new-condition-editor>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">call_made</mat-icon>&nbsp;
          Ação
        </ng-template>

        <ng-template matTabContent>
          <app-crm-bpm [item]="processingNode.action"></app-crm-bpm>
        </ng-template>
      </mat-tab> -->
    </mat-tab-group>
  </mat-dialog-content>
</cm-generic-dashboard-edit>