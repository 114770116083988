import { Injectable } from '@angular/core';
import { isAnimatedWebP } from 'is-webp-extended';

@Injectable({
    providedIn: 'root'
})
export class StickerService {

    constructor() { }

    /**
     * 
     * @param file arquivo de imagem
     * @returns largura e altura, nessa ordem
     */
    async getImageSize(file: File): Promise<[number, number]> {
        const fr = new FileReader;

        return new Promise(resolve => {
            fr.onload = () => {
                const img = new Image;
                
                img.onload = () => resolve([img.width, img.height]);

                img.src = fr.result as string;
            };

            fr.readAsDataURL(file);
        });
    }
    
    async isWebPAnimated(file: File): Promise<boolean> {
        return isAnimatedWebP(file);
    }
}
