<header class="shared-item-header">
  <div>
      <span class="share__message--badge">{{ translations.item }}{{ humanIndex }}</span>
      <span class="share__message--origin">{{ translations.from }}<b>{{ origin }}</b></span>
  </div>
  <span class="share__message--date">{{ date }}</span>
</header>

<app-interaction-previewer [handler]="getInteractionPreviewerHandler()"></app-interaction-previewer>

<footer *ngIf="wasSharedBefore()" class="shared-item-footer">
  <i class="i--sm material-icons">error</i>
  <span class="already-shared">
    {{ translations.alreadySharedWith }}: <strong>{{ alreadySharedWith() }}</strong>
  </span>
</footer>
