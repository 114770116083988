import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { isValidArray } from '@colmeia/core/src/tools/utility';
import { ToolSection, TToolsData } from "../../../model/tools.model";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-tools-page-visualization',
    templateUrl: './tools-page-visualization.component.html',
    styleUrls: ['./tools-page-visualization.component.scss']
})
export class ToolsPageVisualizationComponent implements OnInit {

    public selectedSection: ToolSection;

    private _sections: TToolsData;

    @Input()
    set sections(value: TToolsData) {
        this._sections = value;
    }

    get sections(): TToolsData {
        return this._sections;
    }

    ngOnInit() {
        this.initSelectedSection();
    }

    private initSelectedSection(): void {
        if (isValidArray(this.sections)) {
            this.selectedSection = this.sections[0];
        }
    }

    onSelectSection(section: ToolSection): void {
        this.selectedSection = section;
    }

    isSelectedTab(section: ToolSection): boolean {
        return this.selectedSection
            ? this.selectedSection.header === section.header
            : false;
    }
}
