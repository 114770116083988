import { deepGet, deepSet, deepHas } from "../../utility";
import { $cast, Assert, EAssert, Pop, UnionToIntersection, Replace, Last, IsPartial, IsUnion, GetByKey, $ValueOf, TDeepMap, AfterNarrow, Compute, TFunction, DeepFromEntries, ItPropertyType } from "../../utility-types";
import { $$ } from "../types/error";
import { Custom } from "../types/generic";
import { $SimpleNarrowExtends, CNarrow } from "../types/validate";
import * as _ from 'lodash';

export namespace $DeepMap {
    export namespace NoCheck {
        export const get: (map: Map<unknown, unknown>) => (...items: unknown[]) => unknown = $cast(deepGet);
        export const set: (map: Map<unknown, unknown>) => (...items: unknown[]) => void = $cast(deepSet);
        export const has: (map: Map<unknown, unknown>) => (...items: unknown[]) => boolean = $cast(deepHas);
    }
}
namespace DefineDeepMap {

    type LastV2<T extends unknown[]> = [0, ...T][T['length']]
    
	type MapEntry<Value = unknown, Key = unknown> = { Key: Key; Value: Value };
	type TMapEntries = MapEntry[];
	type MapEntries<Items> = Assert<{ [key in keyof Items]: MapEntry<Items[key], key> }, TMapEntries>;

    type ErrorMessage = [$$<'Properties are repeated'>];

	type BlockRepetition<T extends unknown[][], Entries extends TMapEntries = MapEntries<T>, UnionEntries extends MapEntry = Entries[number]> = {
		[key in keyof T]:
			[never] extends [EAssert<infer Item, T[key], unknown[]>] ?
			[never] extends [EAssert<infer Popped, Pop<Item>>] ?
			[Extract<UnionEntries, MapEntry<[...Popped, unknown]>>] extends [infer Validation]
			? [...([UnionToIntersection<Validation>] extends [never] ? Assert<Replace<Popped, ErrorMessage[0]>, unknown[]> : Popped), Last<T[key]>]
			: never
			: never
			: never
	};

    type CheckMissingKeysItem<Items, T extends unknown[], E extends {}, ToProcess extends unknown[] = Pop<T>> = {
        [key in keyof ToProcess]:
        [IsPartial<E, Assert<key, keyof E>>] extends [false] ?
        [IsUnion<GetByKey<E, key>>] extends [false] ?
            unknown
        :
        [Exclude<GetByKey<E, key>, GetByKey<GetByKey<Items, number>, key>>] extends [infer Missing] ?
        [Missing] extends [false] ?
        unknown
        : $$<['Missing', Missing]>
        : never
        : unknown
    }
    type CheckMissingKeys<T, E> = {
        // @ts-expect-error
        [key in keyof T]: [...CheckMissingKeysItem<T, T[key], E[key]>, unknown]
    }

    type GetAllowedDiscriminator<E extends unknown[], Entries extends MapEntry = MapEntries<Pop<E>>[number]> = $ValueOf<{ [key in keyof E]: [[UnionToIntersection<Extract<Entries, MapEntry<E[key]>>>]] }>
    type ValidateDiscriminator<T extends E, E extends unknown[], Discriminator> =
        never extends EAssert<infer LastItem, Last<E>> ?
        {
            
        }
        : never
    ;
    declare const $NotExactSymbol: unique symbol;
    type NotExactObject = { [$NotExactSymbol]: never };
    type Sanitize<T> = T extends unknown[] ? T extends [unknown, ...unknown[]] ? T : [T[0]?, ...T] : T
    
    // @ts-expect-error
    type GetByKey<T, Key> = T[Key]
    
    type ItNarrow<T, E, Item> = {
        [key in keyof E]: Narrow<T, E[key], GetByKey<Item, key>>
    };
    
    type Narrow<T, E, Item = T> =
        E extends object ? 
            & ItNarrow<T, E, Item>
            // & (E extends unknown[] ? unknown : [keyof Item] extends [keyof E] ? unknown : NotExactObject)
        :
        (T extends E ? unknown : E)
    ;
    

	export function defineDeepHash<InputE extends unknown[]>() {
        type GetDiscriminator<Items, Index, Item, DefineDiscriminator extends Custom.Generic> = Custom.Execute<DefineDiscriminator, Custom.Generic<Item, Index, Items>>;
        type SafeLast<T extends unknown[]> = LastV2<Required<T>>
        type E = InputE;
        
		return deepHash;

		function deepHash<
            T extends Narrow<T, E[]>,
            DefineDiscriminator extends Custom.Generic, 
            LastTItem = SafeLast<T[number]>,
            EmptyE = Replace<E, unknown>
            
            >(
                source:
                & Compute<T>
                & AfterNarrow<
                    T extends unknown ?
                    Compute<
                        & BlockRepetition<T>
                        & CheckMissingKeys<T, E[]>
                    // & { [key in keyof T]:  never extends EAssert<infer Item, GetDiscriminator<T, key, T[key], DefineDiscriminator>> ? [...Pop<E>, Item]  : never }
                    >
                    : unknown
                >,
                discriminator?: DefineDiscriminator,
        ): DeepFromEntries<T>
		function deepHash<T extends unknown[][]>(source: T, discriminator?: unknown): unknown {
            return deepFromEntries(source);
		}
	}
}

function fromEntry(entry: unknown[]): object {
    const [item, ...items] = entry as [ItPropertyType, ...unknown[]];

    return {
        [item]: items.length > 1 ? fromEntry(items) : items[0],
    }

}
function deepFromEntries(items: unknown[][]): object {
    return _.merge({}, ...items.map(fromEntry));
}

export import defineDeepMap = DefineDeepMap.defineDeepHash;