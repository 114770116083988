import { TArrayID, TExtendedParticipant, TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { TParticipantRolePair } from "@colmeia/core/src/request-interfaces/response-interfaces";
import {IParticipantSelectorToRolesSelected} from "./participant-selector.handler";

export interface IParticipantRoleSelectorClient {
    hasRoles(): boolean;
    hasRemove(): boolean;
    onWindowClosed(): void;
    onRolesSelectedChange(roleSelectedMap: IParticipantSelectorToRolesSelected): void;
}

export interface IParticipantRoles {
    allParticipantRoles: TParticipantRolePair;
    grantorParticipantRoles: TArrayID;

}
export interface  IParticipantRoleSelectorParameter {
    groupId: TGlobalUID;
    participant: TExtendedParticipant;
    clientInstance: IParticipantRoleSelectorClient;
    participantRoles?: IParticipantRoles
}

export class ParticipantRoleSelectorHandler {

    constructor(private parameters: IParticipantRoleSelectorParameter) { }

    onRolesSelectedChange(roleSelectedMap: IParticipantSelectorToRolesSelected): void {
        this.parameters.clientInstance.onRolesSelectedChange(roleSelectedMap)
    }

    getGroupID(): TGlobalUID {
        return this.parameters.groupId
    }

    hasRoles(): boolean {
        return this.parameters.clientInstance.hasRoles()
    }

    hasRemove(): boolean {
        return this.parameters.clientInstance.hasRemove();
    }

    getParticipant(): TExtendedParticipant {
        return this.parameters.participant
    }

    windowClosed(): void {
        return this.parameters.clientInstance.onWindowClosed();
    }

    getParticipantRoles(): IParticipantRoles {
        return this.parameters.participantRoles
    }
}
