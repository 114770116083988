import { Location } from '@angular/common';
import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Participant } from '@colmeia/core/src/business/participant';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { MainHandler } from 'app/handlers/main-handler';
import { tap, takeUntil, take, mapTo } from 'rxjs/operators';
import { DialogRouteConfig } from '../../model/routing-service/routing-service.model';
import { RoutingService } from '../routing.service';
import { SessionService } from '../session.service';
import { ColmeiaWindowService} from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { NamedString} from '@colmeia/core/src/core-constants/types';
import { ColmeiaRoutedWindowWrapperComponent } from 'app/components/dashboard/dashboard-foundation/colmeia-routed-window-wrapper/colmeia-routed-window-wrapper.component';
import { isValidRef } from '@colmeia/core/src/tools/barrel-tools';

export interface IRoutedDlgParameters<T extends INonSerializable> {
  ns?: T,
  dialogRef?: ColmeiaWindowRef<any>,
  disableTopBar?: boolean,
  component?: Type<any>,
}
export type TGenerateHandler = <T extends MainHandler>(nser: INonSerializable, participant: Participant) => T;
type urlName = NamedString<'urlName'>;


@Injectable()
export class WindowNavigationService  {

  private openedFromNav: boolean;
  private lastNavigatedUrl: string;
  private windowsMap = new Map<ColmeiaWindowRef, urlName>();

  constructor(
    public location: Location,
    public routingSvc: RoutingService,
    private sessionSVC: SessionService,
    private colmeiaWindowSvc: ColmeiaWindowService,
  ) {
    this.lastNavigatedUrl = window.location.pathname;
    // console.log('winnav updated lastNavigated URL consutructor', {lastNavigatedUrl: this.lastNavigatedUrl})
    
    window.addEventListener('popstate', (e: PopStateEvent) => this.popStateListenerCallback(e))
    this.colmeiaWindowSvc.afterMinimize$().subscribe((ref: ColmeiaWindowRef) => this.afterWindowMinimize(ref))
    this.colmeiaWindowSvc.afterRestore$().subscribe((ref: ColmeiaWindowRef) => this.afterWindowRestore(ref))

    // console.log('winnav CONSTRUCTOR');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const nextUrl = new URL(window.location.origin + state.url);
    const pathName = nextUrl.pathname

    // console.log('winnav canActivate',{
    //   lastNavigatedUrl: this.lastNavigatedUrl,
    //   stateURL: state.url,
    //   route, 
    //   state, 
    //   canNavigate: this.lastNavigatedUrl === pathName,
    //   pathName,
    //   nextUrl
    // })
    
    /**
     * Can navigate if this is the first page loaded. 
     * So it will load the page behind the window.
     */
    this.openedFromNav = this.lastNavigatedUrl === pathName;
    this.resolve(route, state) 

    return this.openedFromNav;
  }

  public async resolve(nextRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot):  Promise<void> {

    const url = new URL(window.location.toString());
    // console.log('winnav resolve, windowLocation', {windowLocation: window.location})
    url.pathname = routerState.url;
    // console.log('winnav resolve, url', {url})

    const windowRefExists = this.urlHasWindowRef(routerState.url);
    
    if (!this.openedFromNav && !windowRefExists) {
      // console.log('winnav queueMicrotask pushStaTE')
      queueMicrotask(() => {
        window.history.pushState({}, '', url)
      })
    }

    const defaultRedirectPath = this.lastNavigatedUrl === routerState.url ? '/menu' : this.lastNavigatedUrl
    
    const { data } = nextRoute;
    const { replaceUrl, useRedirect, disableTopBar, component, ...dlgExtras }: DialogRouteConfig = data as DialogRouteConfig;

    if (data && useRedirect && !data.redirectPath) data.redirectPath = [defaultRedirectPath];

    let ns: INonSerializable | undefined;
    const idNS = nextRoute.paramMap.get('idNS')
    if (idNS) {
      ns = await NSSharedService.getById(idNS);
    }

    const componentParameters: IRoutedDlgParameters<INonSerializable> = {
      ns, 
      disableTopBar,
      component,
    };
  
    
    const windowRef = this.colmeiaWindowSvc.open(
      // nextRoute.routeConfig?.component!, 
      ColmeiaRoutedWindowWrapperComponent,
      { 
        ...dlgExtras, 
        data: componentParameters,
        windowIdentifier: ns?.idNS,
        title: ns?.nName || dlgExtras.title
      }
    );

    if (windowRefExists) {
      return;
    }
    windowRef
    .afterClosed()
    .pipe(
      tap(async (result: any) => {
        // if (useRedirect) {
        //   await this.routingSvc.router.navigate(cfg.redirectPath!, {replaceUrl});
        // }
        this.windowsMap.delete(windowRef);

        const isAtInitialURL = url.pathname === window.location.pathname;

        if (isValidRef(result?.redirectTo)) {
          this.routingSvc.router.navigate(result?.redirectTo)
        } else if (isAtInitialURL) {
          this.location.back();
        }
        this.resetService();

      }),
      take(1),
    )
    .subscribe();

    windowRef
      .afterOpened()
      .pipe(
        mapTo(windowRef),
        takeUntil(windowRef.afterClosed()),
        tap(async () => {

          (windowRef.data as IRoutedDlgParameters<INonSerializable>).dialogRef = windowRef
          // console.log('winnav afteropened', {data, windowRefData: windowRef.data})
        }),
      )
    
    this.windowsMap.set(windowRef, routerState.url);
  }



  private popStateListenerCallback(_: PopStateEvent) {

    const existingWindowRef: ColmeiaWindowRef | undefined = this.urlHasWindowRef(window.location.pathname);

    // console.log('winnav popstateListener',{
    //   windowLocationPathname: window.location.pathname, 
    //   windowRef: existingWindowRef,
    // })

    if (!existingWindowRef) {
      this.lastNavigatedUrl = window.location.pathname;
      // console.log('winnav updated lastNavigated URL popstateListener !existingWindowRef', {lastNavigatedUrl: this.lastNavigatedUrl})
    } 

  }

  private afterWindowMinimize(windowRef: ColmeiaWindowRef) {
    if (this.getWindowRefUrl(windowRef)) {
      // console.log('winnav afterMinimize',{
      //   windowRef: windowRef, 
      //   lastNavigatedUrl: this.lastNavigatedUrl
      // })
      this.location.back();
    }
  }

  private afterWindowRestore(windowRef: ColmeiaWindowRef) {
    const windowRefUrl = this.getWindowRefUrl(windowRef)

    // console.log('winnav afterRestore',{
    //   windowRefUrl,
    //   windowLocationUrlPathName: window.location.pathname,
    //   windowRef,
    //   lastNavigatedUrl: this.lastNavigatedUrl,
    //   isDifferent: window.location.pathname !== windowRefUrl
    // })
    
    if (windowRefUrl && window.location.pathname !== windowRefUrl) {
      // console.log('winnav afterRestore, window.location.pathname !== windowRefUrl')
      window.history.pushState({}, '', windowRefUrl);
    }
  }

  private revertedWindowMap() {
    const revertedMap: Record<string, ColmeiaWindowRef> = [...this.windowsMap.entries()].reduce((acc, [key, value]) => ({...acc, [value]: key}), {});

      // console.log('winnav revertedWindowMap',{
      //   windowMap: this.windowsMap,
      //   revertedMap
      // })
    return revertedMap;
  }

  private urlHasWindowRef(url: urlName): ColmeiaWindowRef | undefined {
    return this.revertedWindowMap()[url];
  }

  private resetService() {
    this.openedFromNav = false;
  }

  public getParticipant() {
    return this.sessionSVC.getCurrentPersonalParticipant();
  }

  public getWindowRefUrl(ref: ColmeiaWindowRef) {
    return this.windowsMap.get(ref)
  }

  private willNavigate(url: urlName) { 
    return this.lastNavigatedUrl === url ? true : false
  }
}