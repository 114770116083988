import { asConst } from "../tools/type-utils";

export const trackerTypes = asConst<{}>()( {
    readControl: {
        readMultimedia: 2,
        receivedMessage: 3
    },
    dataSynch: {
        sendGroupData: 4,
        sendAvatarData: 5,
        sendPlayerInfo: 6,
        sendClientInformation: 13,
        sendPayload: 14,
    },
    // trackers de envio de informação, não constando no datastore
    typingInfo: 7,
    statusInOutGroup: 8,
    feedbackCarrier: 9,
    gpsTracking: {
        track: 10,
    },
    client: {
        error: 11,
        debug: 12,
    },
} as const);


export interface ITrackerConfDomain {
    sendToClient?: true;
    isNewPrimaryID?: true;
    saveToDS?: true;
    saveOnDW?: true;
    stackdriver?: true;
    fromServerOnly?: true; // apenas do servidor para o cliente
    requeue: boolean;
};

export function getTrackerConfDomain(trackerType: number): ITrackerConfDomain {
    return trackerConfiguration[trackerType];
}

const trackerConfiguration: {[trackerType: number]: ITrackerConfDomain } = {
    // dont save
    [trackerTypes.typingInfo]: {sendToClient: true, requeue: false},
    [trackerTypes.feedbackCarrier]: {requeue: true},

    
    // DS Only
    [trackerTypes.readControl.receivedMessage]: {isNewPrimaryID: true, saveToDS: true, requeue: false, fromServerOnly: true},
    [trackerTypes.readControl.readMultimedia]: {isNewPrimaryID: true, saveToDS: true, requeue: false},
    [trackerTypes.statusInOutGroup]: {isNewPrimaryID: true, saveToDS: true, requeue: false},
    
    // DW and DS
    [trackerTypes.gpsTracking.track]: {saveToDS: true, saveOnDW: true, requeue: false},
                                        
    // stackDriver
    [trackerTypes.client.error]: {stackdriver: true, requeue: false},
    [trackerTypes.client.debug]: {stackdriver: true, requeue: false},


    // Datasync
    [trackerTypes.dataSynch.sendGroupData]: {requeue: true},
    [trackerTypes.dataSynch.sendAvatarData]: {requeue: true},
    [trackerTypes.dataSynch.sendPlayerInfo]: {requeue: true},
    [trackerTypes.dataSynch.sendClientInformation]: {requeue: true},
    [trackerTypes.dataSynch.sendPayload]: {requeue: true},
}