import { MainHandler } from '../handlers/main-handler';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import {IComponentParameter, IRootCallBack} from "../model/component-comm/basic";
import {TDriverFeedbackArray} from "@colmeia/core/src/comm-interfaces/business-interfaces";


export interface IReactBarCallback extends IRootCallBack {
    onReactBarReaction(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedback: Feedback): void;
};


export interface IReactBarParameter extends IComponentParameter {
    interactionID: TGlobalUID;
    interactionTypeID: TGlobalUID;
    isBigIcon: boolean;
    idGroup: TGlobalUID;
    allInteractionTypes: TDriverFeedbackArray;
    clientCallback: IReactBarCallback;
};

export class ReactBarHandler extends MainHandler {

    constructor(reactBarParameter: IReactBarParameter) {
        super(reactBarParameter);
    };

    public getComponentParameter(): IReactBarParameter {
        return <IReactBarParameter>super.getComponentParameter();
    };

    public onMessageReact(feedback: Feedback): void {
        if (super.hasCallback() && this.getComponentParameter().clientCallback.onReactBarReaction) {
            const parameter = this.getComponentParameter();
            this.getComponentParameter().clientCallback.onReactBarReaction(parameter.interactionID, parameter.interactionTypeID, feedback);
        };
    };

    public mustOpenBigIconDialog(): boolean {
        return this.getComponentParameter().isBigIcon;
    };
};
