import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { IBotActionAsset } from "../../bot/bot-action-model";
import { ECampaignExecutionType } from "../../campaigns/capaing-exec-interfaces";
import { TIBPMBasicConditionalEvaluatorArray } from "../../BPM/bpm-model";
import { NamedString } from "@colmeia/core/src/core-constants/named-types";


export enum ECRMTicketActionType {
    fireCampaign = 'fireCamp',
}

export enum ECRMTicketActionTriggerType {
    byAgent = 'byAgent',
    byProject = 'byProject',
}

export interface ICRMTicketActionActionOnReturn {
    enabled: boolean;
    conditions: TIBPMBasicConditionalEvaluatorArray;
    botAction: IBotActionAsset;
}

export type IdConfigTicketAction = NamedString<'TicketActionConfigId'>;

export interface ICRMTicketActionBase {
    actionConfigId: IdConfigTicketAction;
    actionType: ECRMTicketActionType;
    triggerType: ECRMTicketActionTriggerType;
    triggerConditions: TIBPMBasicConditionalEvaluatorArray;
}

export interface ICRMTicketActionFireCampaign extends ICRMTicketActionBase {
    actionType: ECRMTicketActionType.fireCampaign;
    campaigns: TICRMTicketActionFireCampaignConfigArray
    actionOnReturn: ICRMTicketActionActionOnReturn;
}

export interface ICRMTicketActionFireCampaignConfig {
    type: ECampaignExecutionType;
    itemId: TGlobalUID; // pode ser um id de ação de campanha ou régua de mkt
}

export type TICRMTicketActionFireCampaignConfigArray = Array<ICRMTicketActionFireCampaignConfig>;

export type TICRMTicketActionArray = Array<ICRMTicketActionBase>;


export interface ICRMTicketActionRuntimeInfo {
    actionConfigId: IdConfigTicketAction;
}