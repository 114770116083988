import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";
import {ETagType} from "../../colmeia-tags/tags";

export const nserViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.NonSerializable,
    fields: {
        [ETagType.colmeia]: 1, // Colmeia
        [ETagType.socialNetwork]: 2, // Rede Social
        [ETagType.variable]: 3, // Variáveis
        [ETagType.tagGroup]: 4, // Grupo de Tags
        [ETagType.marker]: 5, // Marcador
    }
} as const);

export const nserTranslations = getTranslationsFields(nserViewScreenOptions);