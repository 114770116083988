import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { DeliveryProvider } from "@colmeia/core/src/core-constants/types";
import { EMenuMode, TMenuMode } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { TDeliveryProvidersWithWhatsApp } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { asConst } from "@colmeia/core/src/tools/type-utils";
import { keys } from "@colmeia/core/src/tools/utility";
import { $add, RequiredSet } from "@colmeia/core/src/tools/utility-types";

export const maxWhatsAppPageSize: number = 1600//850;

export type TWhatsAppFeaturesConfig =
    & { [key in TMenuMode]: boolean }
    & IConfigOptions
    ;

interface IConfigOptions {
    shouldAllowHeaderAndFooter?: boolean;
    shouldAllowDelete?: boolean;
    authTemplate?: boolean;
    shouldAllowWhatsappFlows?: boolean;
}

export enum EFeatureContext {
    Template = 'template',
    Interactive = 'interactive',
    WhatsappFlows = 'whatsappFlows',
}

export type TProviderToAllowedFeaturesHash = {
    [delivery in EDelivery360Action.Delivery360WhatsApp]: {
        [provider in TDeliveryProvidersWithWhatsApp]:
        & {
            [featureContext in EFeatureContext.Interactive]: TWhatsAppFeaturesConfig;
        }
        & {
            [featureContext in EFeatureContext.Template]: IConfigOptions;
        }
        & {
            [featureContext in EFeatureContext.WhatsappFlows]?: IConfigOptions;
        }
        ;
    }
};



export const providerToAllowedFeaturesHash: TProviderToAllowedFeaturesHash = asConst<TProviderToAllowedFeaturesHash>()({
    [EDelivery360Action.Delivery360WhatsApp]: {
        [DeliveryProvider.smarters]: {
            [EFeatureContext.Template]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: false,
                [EMenuMode.Text]: true,
                shouldAllowDelete: true,
                shouldAllowHeaderAndFooter: true,
            },
            [EFeatureContext.Interactive]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: true,
                [EMenuMode.Text]: true,
            },
        },
        [DeliveryProvider.wavy]: {
            [EFeatureContext.Template]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: false,
                [EMenuMode.Text]: true,
                shouldAllowDelete: true,
                shouldAllowHeaderAndFooter: true,
            },
            [EFeatureContext.Interactive]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: true,
                [EMenuMode.Text]: true,
            },
        },
        [DeliveryProvider.sinch]: {
            [EFeatureContext.Template]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: false,
                [EMenuMode.Text]: true,
                shouldAllowDelete: false,
                shouldAllowHeaderAndFooter: true,
            },
            [EFeatureContext.Interactive]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: true,
                [EMenuMode.Text]: true,
            },
        },
        [DeliveryProvider.infobip]: {
            [EFeatureContext.Template]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: false,
                [EMenuMode.Text]: true,
                shouldAllowHeaderAndFooter: true,
                shouldAllowDelete: true,
            },
            [EFeatureContext.Interactive]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: true,
                [EMenuMode.Text]: true,
            },
        },
        [DeliveryProvider.twilio]: {
            [EFeatureContext.Template]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: false,
                [EMenuMode.Text]: true,
                shouldAllowHeaderAndFooter: true,
            },
            [EFeatureContext.Interactive]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: true,
                [EMenuMode.Text]: true,
            },
        },
        [DeliveryProvider.whatsApp]: {
            [EFeatureContext.Template]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: false,
                [EMenuMode.Text]: true,
                shouldAllowDelete: true,
                shouldAllowHeaderAndFooter: true,
                authTemplate: true,
            },
            [EFeatureContext.Interactive]: {
                [EMenuMode.Quick]: true,
                [EMenuMode.Full]: true,
                [EMenuMode.Text]: true,
            },
            [EFeatureContext.WhatsappFlows]: {
                shouldAllowWhatsappFlows: true
            },
        }
    }
});

export const providersConfigDB = keys(providerToAllowedFeaturesHash)
    .map($add('provider'))
    .map($add('content', item => providerToAllowedFeaturesHash[item.provider]))
    .map(item => keys(item.content).map(deliveryProvider => ({ deliveryProvider, ...item, content: item.content[deliveryProvider] }) as const))
    .flat()
    .map(item => keys(item.content).map(feature => ({ feature, ...item, content: item.content[feature] })))
    .flat()
    ;

export const whatsAppProvidersSet = RequiredSet.create<TDeliveryProvidersWithWhatsApp>()([
    DeliveryProvider.smarters,
    DeliveryProvider.twilio,
    DeliveryProvider.whatsApp,
    DeliveryProvider.infobip,
    DeliveryProvider.sinch,
    DeliveryProvider.wavy,
]);
export const whatsAppProvidersSetGeneric: Set<DeliveryProvider> = whatsAppProvidersSet;

export function isWhatsAppProvider(provider: DeliveryProvider | undefined): provider is TDeliveryProvidersWithWhatsApp {
    return provider ? whatsAppProvidersSetGeneric.has(provider) : false;
}

export function hasWhatsAppFlows(provider: DeliveryProvider): boolean {
    if (!isWhatsAppProvider(provider)) {
        return false;
    }
    return !!providerToAllowedFeaturesHash[EDelivery360Action.Delivery360WhatsApp][provider].whatsappFlows?.shouldAllowWhatsappFlows
}
