import { Injectable } from '@angular/core';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { IResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { toMultipleCursor } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { IGetSocialMediaConnectionRequest, IGetSocialMediaConnectionResponse, IGetTelegramInfoRequest, IGetTelegramInfoResponse, ISaveSocialMediaRequest, ISaveSocialMediaResponse, ISocialMediasListRequest, ISocialMediasListResponse, ISubmitWhatsAppTemplate, ISubmitWhatsAppTemplateRequest } from '@colmeia/core/src/shared-business-rules/social-media/social-media-req-resp';
import {
	ISocialMediaConnection,
	ISocialMediaConnectionServer, TIISocialMediaConnectionArray
} from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { ITelegramUser } from '@colmeia/core/src/shared-business-rules/social-media/telegram.interfaces';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { IInfraParameters } from 'app/model/client-infra-comm';
import { ClientInfraResponse } from 'app/model/component/client-infra-comm';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { NewNotificationsService } from 'app/services/new-notifications.service';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';

const baseRoute = routeList.dashboard.children.communication.children.socialMedias;

@Injectable({
	providedIn: 'root'
})
export class DashboardSocialMediaService {

	private _selectedSocialMedia: ISocialMediaConnectionServer;

	constructor(
		private routeSvc: RoutingService,
		private rbs: RequestBuilderServices,
		private session: SessionService,
		private api: ServerCommunicationService,
        private newNotificationsSvc: NewNotificationsService,
	) { }

	private buildInfra(): IInfraParameters {
		return this.rbs.getNoCallBackNoSpinnningParameters(
			this.session.getPlayerID(),
			this.session.getSelectedAvatarID()
		);
	}

	getSocialMediasRequest(cursor: string = null): ISocialMediasListRequest {
		const infra: IInfraParameters = this.buildInfra();
		const request: ISocialMediasListRequest = {
			...this.rbs.createRequestFromInfraParameters(apiRequestType.campaigns.listSocialMedias, infra),
			multipleCursor: toMultipleCursor(cursor)
		};
		return request;
	}

	async getSocialMedias(cursor: string = null): Promise<TIISocialMediaConnectionArray> {
		const infra: IInfraParameters = this.buildInfra();
		const request: ISocialMediasListRequest = this.getSocialMediasRequest(cursor);
		const response: ClientInfraResponse = await this.api.managedRequest(infra, request);
		if (response.executionOK) {
			return (<ISocialMediasListResponse>response.response).nonSerializableArray;
		} else {
			return undefined;
		}
	}

	async getTelegramInfo(token: string): Promise<ITelegramUser> {
		const response = await this.api.quick<IGetTelegramInfoRequest, IGetTelegramInfoResponse>()({
			requestType: apiRequestType.campaigns.getTelegramInfo,
			token,
		}, {
			showError: false
		});

		return response?.info;
	}

	async saveSocialMedia(socialMedia: ISocialMediaConnection, shouldRemoveFromTemplatesIfIsNotAllowedForMarketing?: boolean) {
		const response = await this.api.sendRequest<ISaveSocialMediaRequest, ISaveSocialMediaResponse>(apiRequestType.campaigns.saveSocialMedia)(
			{
				socialMedia,
				shouldRemoveFromTemplatesIfIsNotAllowedForMarketing,
			}
		);
		
		if (response?.idJob) {
			await this.newNotificationsSvc.waitJob({
				idJob: response?.idJob!,
				retry: {
					shouldRetry: true,
				},
			});
			const connection: ISocialMediaConnectionServer | undefined = await NSSharedService.getNS(response.ns.idNS!);
			return connection;
		}

		return response?.ns;
	}


	public async submitTemplate(source: ISubmitWhatsAppTemplate): Promise<boolean> {
		const response: IResponse = await this.api.doRequest<ISubmitWhatsAppTemplateRequest>(apiRequestType.dashboardExtra.marketing.submitWhatsAppTemplate, source);
		return isValidRef(response);
	}

	public async getSelectedSocialMedia(idNS: string): Promise<ISocialMediaConnectionServer> {
		const response = await this.api.doRequest<IGetSocialMediaConnectionRequest>(apiRequestType.dashboardExtra.marketing.getSocialMediaConnection, {
			idNS
		}) as IGetSocialMediaConnectionResponse;
		return response.ns
	}

	get selectedSocialMedia(): ISocialMediaConnectionServer {
		const selectedSocialMedia = this._selectedSocialMedia;
		this._selectedSocialMedia = undefined;
		return selectedSocialMedia;
	}

	goToCreateSocialMedia(): void {
		this.routeSvc.navigateToId(
			routeID.dashboard,
			routeList.dashboard.children.communication.path,
			baseRoute.path,
			baseRoute.children.create,
		)
	}

	goToSocialMediaDetails(socialMedia: ISocialMediaConnectionServer): void {
		this._selectedSocialMedia = socialMedia;
		this.routeSvc.navigateToId(
			routeID.dashboard,
			routeList.dashboard.children.communication.path,
			baseRoute.path,
			baseRoute.children.edit.path.replace(baseRoute.children.edit.routeParam, socialMedia.idNS),
		)
	}


	public getEditingId(): string {
		return this.routeSvc.getRouteParam(baseRoute.children.edit.routeParam.slice(1))
	}

}
