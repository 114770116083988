
import { TNserValidator } from "../validate-nser";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { screenOptionToError } from "../error-messages";
import { isValidArray, isValidRef, isValidString } from "../../../../tools/utility";
import { smartFlowRTFParamsErrors } from "../error-fields";
import { defaultNserValidator } from "./nser";
import { IFlowInjectionBodyServer, IFlowInjectionHeaderServer } from "../../../injection-flow/injection-flow";
import { isValidBasicCondition } from '../../../bot/conditional-functions';

const errors = screenOptionToError(smartFlowRTFParamsErrors);

export const smartFlowHeaderValidator: TNserValidator = (nser: IFlowInjectionHeaderServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);

  if(!isValidRef(nser.metadata)) {
    friendly.addReturnResponse(errors.emptyMetadata);
  }

  return friendly;
};

export const smartFlowBodyValidator: TNserValidator = (nser: IFlowInjectionBodyServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);

  if(isValidArray(nser.condition) && !nser.condition.every( c => isValidBasicCondition(c))) {
    friendly.addReturnResponse(errors.invalidConditions);
  }

  return friendly;
};