import { MainSignal } from './main-signal';
import { observableList } from './signal-constants';
import { ISignUpResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";


export class LoginSignal extends MainSignal {

    constructor(response: ISignUpResponse, status: boolean) {
        super(observableList.auth.loginByCookie, status, response);
    };

};
