<div class="wrapper">
  <div class="hex__container">
    <hexagon
      class="hex__item"
      *ngFor="let member of getMembers()"
      [hexagonHandler]="member"
    ></hexagon>
  </div>
  <span *ngIf="!!membersSize" class="members--count">{{ membersSize }} membros</span>
</div>
