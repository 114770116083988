import { ChatbarHandler, IChatBarParameter } from "./chat-bar-handler";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { Participant } from "@colmeia/core/src/business/participant";


export class ChatMessageHandler extends ChatbarHandler {



}
