import { Injectable } from "@angular/core";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { IFriendlyExtraMessage, TExtraFriendlyMessageArray } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { commonScreenOptionsTranslations3 } from "@colmeia/core/src/shared-business-rules/const-text/views/txt-common";
import {
    ENonSerializableObjectType,
    INonSerializableHeader
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { validateNonserializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/validate-nser";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { SnackMessageService } from "./snack-bar";



@Injectable({
    providedIn: 'root'
})
export class NSValidationService {

    constructor(
        private snack: SnackMessageService
    ) { }

    private promptError(translation: ITranslationConfig) {
        const msg: string = (Serializable.staticFactory(translation.serializableId)).getSerializableText(translation.idField);
        this.snack.openError(msg, 3000);
    }


    promptAllErrorsFrom(nser: INonSerializableHeader, type: ENonSerializableObjectType) {
        for (const error of this.getErrorsFrom(nser, type)) {
            this.promptError(error);
        }
    }

    promptAndGetFirstErrorFrom(nser: INonSerializableHeader, type: ENonSerializableObjectType): string {
        const errors: ITranslationConfig[] = this.getErrorsFrom(nser, type);
        const error = errors[0];
        if (error) {
            this.promptError(error);
        }

        return isValidRef(error) ? Serializable.getTranslation(error) : undefined;
    }

    promptFirstErrorFrom(nser: INonSerializableHeader, type: ENonSerializableObjectType) {
        const errors: ITranslationConfig[] = this.getErrorsFrom(nser, type);
        const error = errors[0];
        if (error) {
            this.promptError(error);
        }
    }

    private getErrorsMessages(nser: INonSerializableHeader, type: ENonSerializableObjectType): TExtraFriendlyMessageArray {
        const friendlyMessage: FriendlyMessage = validateNonserializable(nser, type);
        const messages: TExtraFriendlyMessageArray = friendlyMessage.getReturnMessages();
        console.log('getErrorsMessages', { messages, friendlyMessage });
        return messages;
    }

    getErrorsFrom(nser: INonSerializableHeader, type: ENonSerializableObjectType): ITranslationConfig[] {
        return this.getErrorsMessages(nser, type).map(this.toTranslation);
    }

    private toTranslation(message: IFriendlyExtraMessage): ITranslationConfig {
        return {
            idField: message.idField,
            serializableId: message.idSerializable
        };
    }

    getErrorsAsStringFrom(nser: INonSerializableHeader, type: ENonSerializableObjectType): string[] {
        return this.getErrorsMessages(nser, type).map(err => {
            const errors = [Serializable.getTranslation(this.toTranslation(err)), err.additionalText];

            if (err.idSerializable === commonScreenOptionsTranslations3.error.serializableId) {
                errors.shift();
            }

            return errors.filter(item => item).join(' ');
        });
    }

    public getFirstErrorWithAdditionalMessage(nser: INonSerializableHeader, type: ENonSerializableObjectType) {
        const errors = this.getErrorsAsStringFrom(nser, type)
        const error = errors[0]
        return error;
    }

}
