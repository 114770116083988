import { Injectable } from "@angular/core";
import { Avatar } from "@colmeia/core/src/business/avatar";
import { constant } from "@colmeia/core/src/business/constant";
import { Group } from "@colmeia/core/src/business/group";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { TArrayID, TExtendedParticipantArray } from "@colmeia/core/src/core-constants/types";
import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { IGroupCreationRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IGroupCreationResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { SecurityItem } from "@colmeia/core/src/security/security-item";
import { SecuritySettings } from "@colmeia/core/src/security/security-settings";
import { EGroupType } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { CreateGroupDialogComponent } from "../components/new-group/dialogs/create-group-dialog/create-group-dialog.component";
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { ICreateGroupParameters, IGroupSavedCallbackClient } from "../model/group-editor-creator.model";
import { IInterfaceInfoSignal, InterfaceInfoSignal } from "../model/signal/interface-signal";
import { NavigatorServices } from "./controllers-services/navigator/navigator.service";
import { ColmeiaDialogService } from "./dialog/dialog.service";
import { GroupPersistorServices, IGetCreateEditSupportData } from "./group-persistor.services";
import { MultimediaService } from "./multimedia.service";
import { RequestBuilderServices } from "./request-builder.services";
import { RoutingService } from "./routing.service";
import { SessionService } from "./session.service";
import { SignalPublisherService } from "./signal/signal-publisher";

const FeatureHexagonGroupType = {
    [EGroupType.SubGroup]: constant.groupType.standard.standardGroup,
    [EGroupType.Corporate]: constant.groupType.functionalRoot.corporate,
    [EGroupType.Tribe]: constant.groupType.functionalRoot.tribe,
    [EGroupType.Broadcaster]: constant.groupType.functional.broadcast,
    [EGroupType.Channel]: constant.groupType.standard.channel,
    [EGroupType.Distribution]: constant.groupType.functional.distributionList,
    [EGroupType.NewFeature]: constant.groupType.functional.featureCreator
};

@Injectable({
    providedIn: 'root'
})
export class GroupEditorCreatorService {
    constructor(
        private dialog: ColmeiaDialogService,
        private session: SessionService,
        private routing: RoutingService,
        private rbs: RequestBuilderServices,
        private groupFunctionService: GroupPersistorServices,
        private navigator: NavigatorServices,
        private multimedia: MultimediaService,
        private publisher: SignalPublisherService
    ) { }

    create(hexagon: Serializable, currentGroup: Group, groupSavedCallbackClient: IGroupSavedCallbackClient) {
        this.dialog.open<CreateGroupDialogComponent, ICreateGroupParameters>({
            componentRef: CreateGroupDialogComponent,
            title: 'Criar grupo',
            dataToComponent: {
                data: this.buildCreateGroupParameters(hexagon, currentGroup, groupSavedCallbackClient),
                maxWidth: "420px"
            }
        });
        const param: IInterfaceInfoSignal = {
            toolBarOpen: true
        };
        const signal: InterfaceInfoSignal = new InterfaceInfoSignal(param);
        this.publisher.specificSignalEmissorOnGenericStream(signal);
    }

    private buildCreateGroupParameters(hexagon: Serializable, currentGroup: Group, groupSavedCallbackClient: IGroupSavedCallbackClient): ICreateGroupParameters {
        return {
            groupTypeHexagon: hexagon,
            parentGroup: currentGroup,
            createGroupType: FeatureHexagonGroupType[hexagon.getPrimaryID()],
            avatar: this.session.getSelectedAvatar(),
            service: this,
            componentInstance: groupSavedCallbackClient
        };
    }

    private async getSupportCreateData(): Promise<IGetCreateEditSupportData> {
        const createEditSupportData: IGetCreateEditSupportData = await this.groupFunctionService.getGroupSecuritySettings(this.session.getSelectedGroupID(), true);
        return createEditSupportData;
    }

    public async persistGroup(
        group: Group,
        applySecSettings: TArrayID,
        participants: TExtendedParticipantArray,
        additional?: Partial<IGroupCreationRequest>
    ): Promise<void> {
        CreateGroupDialogComponent.CreatingGroup = group;
        const avatar: Avatar = this.session.getSelectedAvatar();
        const securitySettings: SecuritySettings = group.getSecuritySettings();
        const newSecuritySettings: SecuritySettings = new SecuritySettings(securitySettings.getSecuritySettingsID());

        const supportData = await this.getSupportCreateData();
        const defaultSecuritySettings: TArrayID = Object.keys(supportData.securitySettings.getActiveSecurityItemsHash());

        for (const key of [...new Set([...defaultSecuritySettings, ...applySecSettings])]) {
            const secItem: SecurityItem = SecurityItem.getNewSecurityItem(key);
            secItem.setSelected(true);
            newSecuritySettings.addHash(secItem);
        }

        group.replaceSecuritySettings(newSecuritySettings);

        newSecuritySettings.removeUberCache();
        securitySettings.removeUberCache();

        const infraParameters: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(
            avatar.getParentEntityID(),
            avatar.getAvatarID()
        );

        this.navigator.dontRefreshOnNextSecuritySignal();

        const mmObj: MultimediaObject = group.getMultimediaObject();
        await this.multimedia.uploadSerializableMedia(infraParameters, group, mmObj, this.session.getSelectedGroupID());

        const groupInfraResponse: ClientInfraResponse = await this.groupFunctionService.createSubGroup(
            infraParameters,
            group,
            group.getMultimediaObject(),
            avatar,
            participants,
            additional
        );
        this.navigator.dontRefreshOnNextSecuritySignal();

        const res: IGroupCreationResponse = <IGroupCreationResponse>groupInfraResponse.response;
        if (groupInfraResponse.executionOK) {
            const isNewSocialNetworkCreated: boolean = isValidRef(res.socialData)
            if (isNewSocialNetworkCreated) {
                await this.navigator.navigateToGroupIDWithRefresh(res.group.primaryID);
            } else {
                await this.navigator.navigateToGroupIDWithRefresh(res.group.groupParent.primaryID);
            }
        }
    }
}
