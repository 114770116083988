<section class="general-form-field object-field" [hidden]="!canDisplayField">
  <mat-expansion-panel [disabled]="isDisabled" [expanded]="isExpanded" (closed)="expandChange(false)" (opened)="expandChange(true)">
    <mat-expansion-panel-header>
      {{ title }}
    </mat-expansion-panel-header>
    <div  matExpansionPanelContent>

      <ng-container *ngIf="handlers">

        <ng-container *ngFor="let handler of handlers">
          <app-general-form-field-viewer 
            *ngIf="handler"
            [handler]="handler"
            [value]="value[handler.getSchemaProperty().propertyName]"
          ></app-general-form-field-viewer>
        </ng-container>
        
      </ng-container>
      

      <!--  -->
      <!-- [previousAnswer]="getPreviousAnswer(property)" -->
    </div>
  </mat-expansion-panel>
</section>