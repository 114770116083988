
import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
    selector: 'app-carousel-arrow',
    templateUrl: './carousel-arrow.component.html',
    styleUrls: ['./carousel-arrow.component.scss']
  })

export class CarouselArrowComponent {
    @Input() dir: string;

    @Input("disabled")
    disabled: boolean = true;

    @Output('on-click') click: EventEmitter<any> = new EventEmitter<any>();


    constructor() { }

    onClick() {
        if (!this.disabled)
            this.click.emit();
    }
}
