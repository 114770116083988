import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';

export const defaultInteractionTypeID: TGlobalUID = constant.interactionType.standard.message;

export const CREATE_PARTICIPANT_TYPE = {
	needRequestToSubscribe: 0,
	newParticipantAndSubscription: 1,
	NewParticipantWithoutSubscription: 2,
};

export const MAX_TIMEOUT: number = 300000;
