import { constant, TGlobalUID } from './constant';
import { Role } from '../security/role';
import { Avatar, TAvatarArray } from './avatar';
import { GroupType } from './group-type';
import { Serializable } from './serializable';
import { IGroupJSON, TGroupGenealogy, IGroupParentJSON, TDriverFeedbackArray, IGroupParticipanBridgeInfo } from '../comm-interfaces/business-interfaces';
import { UberCache } from '../persistency/uber-cache';
import { SecuritySettings } from '../security/security-settings';
import { domain } from '../core-constants/security-constant';
import { MultimediaObject } from '../multi-media/multi-media-object';
import { IInteractionLiveInfo } from '../comm-interfaces/tracker-interfaces';
import { TGroupArray, TArrayID } from '../core-constants/types';
import { getUniqueStringID, fullCloneObject, isValidRef } from '../tools/utility';
import { EGroupContainer } from '../shared-business-rules/group-container/group-container-config';

export class Group extends Serializable {
    private idAvatarCreator: TGlobalUID;
    private groupType: GroupType;
    private groupParent: Group;
    private securitySettings: SecuritySettings;
    private idSecuritySettings: TGlobalUID;
    private idParentEntityCreator: TGlobalUID;
    private genealogy: TGroupGenealogy;
    private depth: number;
    private firstParticipantRole: Role;  // a role que será concedida como default aos novos players
    private lastGroupNavigatorInteraction: IInteractionLiveInfo;
    private participantsAttachedToGroup: TGlobalUID;
    private securityAttachedToGroup: TGlobalUID;
    private driverFeedbacks: TDriverFeedbackArray;
    private idProjection: TGlobalUID;
    private avatarSample: TAvatarArray;
    private bridgerInfo: IGroupParticipanBridgeInfo;
    private groupContainer: EGroupContainer;
    private idGroupParent: TGlobalUID;

    protected constructor(groupI: IGroupJSON) {
        super(constant.objectType.group, groupI.primaryID)
        this.groupType = GroupType.staticFactory(groupI.groupType);
        this.securitySettings = null;
    };

    public getPlainGroupGenealogy(): TGroupGenealogy {
        return this.genealogy;
    }

    public isMyParentOfLevel(idGroup: TGlobalUID): number {
        const infoParent: IGroupParentJSON = this.genealogy.find((ge) => {
            return ge.idGroupParent == idGroup
        });
        return infoParent ? infoParent.level : -1;
    };

    public getMySocialNetworkID(): TGlobalUID | null {
        const social: IGroupParentJSON | undefined = this.genealogy
            // .filter(item => item.idGroupTypeParent !== constant.groupType.functionalRoot.playerPersonalGroup)
            .find((g) => { return GroupType.staticFactory(g.idGroupTypeParent).isFunctionalRoot() })
            ;
        return social ? social.idGroupParent : this.getGroupType().isFunctionalRoot() ? this.getGroupID() : null;
    };

    public getMySocialNetworkGroupType(): GroupType {
        const social: IGroupParentJSON = this.genealogy.find((g) => { return GroupType.staticFactory(g.idGroupTypeParent).isFunctionalRoot() });
        return social ? GroupType.staticFactory(social.idGroupTypeParent) : null;
    }

    public getProjectionID(): TGlobalUID {
        return this.idProjection;
    }

    // Get all groups from groupArray which has the groupsTypes of arrayGroupType
    // If the user wants to exlude all the groupTypes from arrayGroupType make include = false
    public static getTypedGroups(groupArray: TGroupArray, arrayGroupType: TArrayID, include: boolean = true, method: number = 1): TGroupArray {
        let groups: TGroupArray = [];
        for (let group of groupArray) {
            const hasGroupType: boolean = (arrayGroupType.some((gt) => { return gt == group.getGroupType().getGroupTypeID() }));
            if (include == hasGroupType) {
                groups.push(group);
            };
        };
        return groups;
    };

    public getLastGroupNavigatorInteractionJSON(): IInteractionLiveInfo {
        return this.lastGroupNavigatorInteraction;
    };

    public setLastGroupNavigatorInteractionJSON(lastInteraction: IInteractionLiveInfo): void {
        this.lastGroupNavigatorInteraction = lastInteraction;
    }

    public setDriverFeedbacks(drivers: TDriverFeedbackArray): void {
        this.driverFeedbacks = drivers;
    }

    public getDriverFeedbackArrayStructure(): TDriverFeedbackArray {
        return this.driverFeedbacks;
    };

    public getDriverFeedbacks(): TDriverFeedbackArray {
        return this.driverFeedbacks;
    };

    public toJSON(): IGroupJSON {
        let json: IGroupJSON = {
            ...super.toJSON(),
            groupType: this.getGroupType().getGroupTypeID(),
            idSecuritySettings: this.idSecuritySettings,
            idAvatar: this.idAvatarCreator,
            groupParent: (this.groupParent && this.getGroupType().willLookForParent()) ? this.groupParent.toJSON() : null,
            idObjectType: this.getSerializableObjectTypeID(),
            securitySettings: this.securitySettings ? this.securitySettings.toJSON() : null,
            idParentEntityCreator: this.idParentEntityCreator,
            genealogy: this.genealogy,
            depth: null,
            driverFeedbacks: this.driverFeedbacks,
            idProjection: this.idProjection,
            groupContainer: this.groupContainer,
        };


        return json;
    };

    public rehydrate(json: IGroupJSON): void {
        super.rehydrate(json);
        this.idAvatarCreator = json.idAvatar;
        this.idParentEntityCreator = json.idParentEntityCreator;

        this.genealogy = json.genealogy;
        this.depth = json.depth;
        this.idProjection = json.idProjection;
        this.groupContainer = json.groupContainer;

        if (isValidRef(json.groupParent)) {
            if (UberCache.testCache(json.groupParent.primaryID)) {
                this.groupParent = Group.staticFactory(json.groupParent.primaryID)
            }
            this.idGroupParent = json.groupParent.primaryID;
        };

        if (json.securitySettings) {
            this.securitySettings = SecuritySettings.factoryMessage(json.securitySettings);
        }
        this.idSecuritySettings = json.idSecuritySettings;
        if (json.lastInteractionJSON) {
            this.lastGroupNavigatorInteraction = json.lastInteractionJSON;
        };

        if (json.sampleAvatar) {
            this.avatarSample = json.sampleAvatar.map(avatar => Avatar.factoryMessage(avatar))
        }

        this.participantsAttachedToGroup = json.participantsAttachedToGroup;
        this.securityAttachedToGroup = json.securityAttachedToGroup;
        this.driverFeedbacks = json.driverFeedbacks;
        this.bridgerInfo = json.bridgeInfo;
    };

    public static factoryMessage(json: IGroupJSON): Group {
        let group: Group = <Group>UberCache.uberFactory(json.primaryID, constant.objectType.group, false);
        if (group == null)
            group = new Group(json);
        group.rehydrate(json);
        return group;
    };

    public isGroupAllowedToBridge(): boolean {
        return isValidRef(this.isGroupAllowedToBridge);
    };

    public getAvatarSample(): TAvatarArray {
        return this.avatarSample
    }

    public isParticipantCarrier(): boolean {
        return this.is(this.participantsAttachedToGroup);
    };

    public isSecutityOwner(): boolean {
        return this.is(this.securityAttachedToGroup);
    };

    public static staticFactory(idGroup: TGlobalUID): Group {
        return <Group>UberCache.uberFactory(idGroup, constant.objectType.group, true)
    };


    // add ou sobreescreve o grupo no pool
    public getGroupID(): TGlobalUID { return super.getPrimaryID(); };

    public getParentGroup(): Group { return this.groupParent; };
    public getGroupType(): GroupType { return this.groupType; };
    public setGroupType(groupType: GroupType): void { this.groupType = groupType; };
    public getName(): string {
        return super.getName() != null ? super.getName() : this.getGroupType().getGroupTypeName();
    }
    public getMultimediaObject(): MultimediaObject {
        return super.getMultimediaObject() ? super.getMultimediaObject() : this.getGroupType().getMultimediaObject();
    }
    public setParentCreator(idParentEntity: TGlobalUID): void { this.idParentEntityCreator = idParentEntity };



    public getGroupParentID(): TGlobalUID {
        return this.idGroupParent;
    }

    public getNewGroupFromParent(idGroupType: TGlobalUID, avatar: Avatar): Group {
        const json: IGroupJSON = this.toJSON();
        json.groupParent = fullCloneObject(json);

        json.primaryID = null;
        json.idProjection = null;
        json.serializableTextArray = [];
        json.groupType = idGroupType;
        json.securitySettings = null;
        json.idAvatar = avatar.getAvatarID();
        json.multimediaObject = null;
        json.idMultimedia = null;

        let group: Group = new Group(json);

        json.idSecuritySettings = getUniqueStringID();
        group.rehydrate(json);
        group.replaceSecuritySettings(new SecuritySettings(json.idSecuritySettings));

        // é um grupo novo.. o id será gerado pelo servidor
        // remove do ubercache
        group.removeUberCache(true);
        return group;
    };

    public getDateCreated(): Date { return super.getDate(); };
    public getSecuritySettings(): SecuritySettings { return this.securitySettings };

    public getRecertificationIntervalDays(): number { return parseInt(super.getSerializableText(constant.serializableField.group.recertificationInterval)) };
    public setRecertificationInterval(days: number): void { super.addSerializableText(constant.serializableField.group.recertificationInterval, days.toString()) };

    public replaceSecuritySettings(securitySettings: SecuritySettings): void {
        this.securitySettings = securitySettings;
    }

    public isOpen(): boolean {
        return !this.securitySettings.isSecurityRequested(domain.visibility.onlyParticipantCanSeeContent);
    }
    public getFirstParticipantRole(): Role { return this.firstParticipantRole; };  //@!

    public getGroupContainer(): EGroupContainer {
        return this.groupContainer;
    }

    public setGroupContainer(groupContainer: EGroupContainer): void {
        this.groupContainer = groupContainer;
    }

    public getIdAvatarCreator(): string {
        return this.idAvatarCreator;
    }
};



export class SocialNetwork extends Group {

}