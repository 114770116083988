import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../../all-serializables";
import { genericTypedSuggestions } from "../../../../tools/type-utils";



export enum EDashboardScreenGroup {
    Environments = 'environments',
    YourColmeia = 'yourColmeia',
    Ai = 'ai',
    MenuSN = 'menuSN',
    Marketing = 'marketing',
    Communication = 'communication',
    Service = 'service',
}

const menus = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.Dashboard,
    fields: genericTypedSuggestions<Record<EDashboardScreenGroup, number>>()({
        environments: 1, // Ambientes
        yourColmeia: 2, // Atendimento
        ai: 3, // Omnisense
        menuSN: 4, // Rede social
        marketing: 5, // Marketing
        communication: 6, // Comunicação
        service: 7, // Serviços
    }),
});

export const dashboardMenusScreenOptions = menus.screenOptions;
export const dashboardMenusTranslations = menus.translations;
