<app-colmeia-window-top-bar [title]="windowTitle"></app-colmeia-window-top-bar>

<div class="active-call-info-container">
  <div class="active-call-information">
    <div class="search-container" [style.display]="customerClicked ? 'none' : 'flex'">
      <app-customer-finder
        [target]="initialSearchInputValue"
        [handler]="customerFinderHandler"
        (searchByRadioChange)="handleSearchByRadioChange($event)"
        (targetChange)="handleTargetChange($event)"
      ></app-customer-finder>

      <div  class="add-button-container">
        <button *ngIf="isSearchByPhone() && isPhoneTargetOk()"
          [disabled]="!showAddContact"
          mat-flat-button color="primary"
          (click)="onCreateContactClick()"
          style="margin-bottom: 10px 0;float:right;"
        >
          <mat-icon>add_circle</mat-icon>
          Adicionar contato
        </button>
      </div>

    </div>

    <div *ngIf="customerClicked" class="active-call info-customer-container"
      [class.show-right-panel]="selectedAction">

      <div class="customer-info-left">
        <div class="customer-info-header" *ngIf="!isPartialSend">
          <button (click)="onBackButtonClickedResetFields()" mat-icon-button aria-label="Voltar"
            style="margin-right: 8px;">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <div class="customer-name">
            <h2>{{ customerClicked.name || customerClicked.target }}</h2>
            <div *ngIf="customerClicked.name">
              {{ customerClicked.target }}
            </div>
            <button 
              style="width: auto; margin-top: 4px;" 
              mat-stroked-button 
              *ngIf="!isActiveCallEdit && !!idParentContact" 
              (click)="addPartialActiveCall()"
              class="dense"
            >
              Adicionar parcialmente
            </button>
          </div>
        </div>

        <div class="customer-info-content" [class.showing-history]="isShowingHistory">
          <div id="history" class="customer-info-block block-history" *ngIf="!isPartialSend && serviceHistory">
            <div class="customer-info-block-title">
              Histórico
            
              <div class="customer-info-block-buttons">
                <button mat-icon-button color="primary" (click)="isShowingHistory = false" matTooltip="Ações" class="mat-button-toggle-checked history-button">
                  <mat-icon>history</mat-icon>
                </button>
              </div>
            </div>
            <div class="customer-info-block-list nice-scroll">
              <ng-container *ngIf="!isLoadingHistory; else historyLoading">
                <ng-container *ngIf="serviceHistory.length > 0; else noHistoryFound">
                  <div *ngFor="let history of serviceHistory" class="block-list-item">
                    <div class="item-wrapper">
                      <img class="bh-fixed-icon bh-contact-chanel" [src]="getDelivery360Icon(history.channel)" />
                      <div class="bh-target">{{ history.clockTick | date: 'medium' }}</div>
                      <div class="bh-clocktick">{{ history.messageSent }}</div>
                      <div class="bh-fixed-icon bh-contact-type">
                        <mat-icon style="width: 14px;height: 14px;font-size:14px;">{{
                          getTypeOfCustomerContactIcon(history.typeOfCustomerContact) }}</mat-icon>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #historyLoading>
                <div class="loading-spinner">
                  <mat-spinner diameter="28"></mat-spinner>
                </div>
              </ng-template>
              
              <ng-template #noHistoryFound>
                <div class="empty-message">
                  Não existe histórico para exibir
                </div>
              </ng-template>
            </div>
          </div>

          <div id="campanhas" *ngIf="customerWithTargetFound"
            class="customer-info-block block-actions">
            <div class="customer-info-block-title">
              Ações

              <div class="customer-info-block-buttons">
                <ng-container *ngIf="!isSearchingName">
                  <button mat-icon-button color="primary" (click)="openNameSearch()" matTooltip="Procurar" [class.l-disabled]="filteredActionList.length === 0">
                    <mat-icon>search</mat-icon>
                  </button>
                  <app-tag-pick-filter-for-list [parameter]="campaignActionTagPickerParameter" matTooltip="Filtrar por tags"></app-tag-pick-filter-for-list>
                  <button [class.l-disabled]="filteredActionList.length === 0" mat-icon-button color="primary" (click)="toggleActionsSorting()" matTooltip="Alternar ordem alfabética crescente ou decrescente">
                    <mat-icon>sort_by_alpha</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" (click)="isShowingHistory = true" matTooltip="Histórico" class="history-button">
                    <mat-icon>history</mat-icon>
                  </button>
                </ng-container>

                <ng-container *ngIf="isSearchingName">
                  <cm-input-progress #searchNameInput [handler]="nameSearchHandler"></cm-input-progress>
                  <button mat-icon-button color="primary" (click)="closeNameSearch()" matTooltip="Fechar busca">
                    <mat-icon>close</mat-icon>
                  </button>
                </ng-container>
              </div>
            </div>

            <div class="customer-info-block-list nice-scroll">
              <ng-container *ngIf="!isLoadingActions; else actionsLoading">
                <ng-container *ngIf="filteredActionList.length > 0; else noActionsFound">
                  <div *ngFor="let action of filteredActionList" (click)="onCampaignClicked(action)" class="block-list-item"
                    [class.selected]="selectedAction?.idCampaigAction === action.idCampaigAction"
                    [class.channel-opened]="!!getChannelOpenTime(action)" [matTooltip]="action.actionDescription">
                    <div class="item-wrapper">
                      <div>
                        <div class="ac-item-text acbi-title">{{ action.actionName }}</div>
                      </div>
                      <div *ngIf="!!getChannelOpenTime(action)" class="channel-open-time" matTooltip="Tempo para fechar o canal">
                        {{ getMissingTimeToCloseChannelFormated(getChannelOpenTime(action)) }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>

              <ng-template #actionsLoading>
                <div class="loading-spinner">
                  <mat-spinner diameter="28"></mat-spinner>
                </div>
              </ng-template>

              <ng-template #noActionsFound>
                <div class="empty-message">
                  Nenhuma ação disponível
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="customer-info-right">
        <mat-spinner *ngIf="loading" diameter="28" style="margin: auto;"></mat-spinner>
        <app-alert *ngIf="!loading && hasErrorInTemplateStructureCreation()" type="error" darker
          style="margin: auto 20px;">
          Não foi possível encontrar o template, reveja a configuração da ação
        </app-alert>
        <ng-container *ngIf="!loading && selectedAction && !hasErrorInTemplateStructureCreation()">
          <div class="action-config-section config-section-top">
            <button (click)="selectedAction = null" mat-icon-button aria-label="Voltar" class="back-button">
              <mat-icon>arrow_back</mat-icon>
            </button>

            <mat-form-field class="action-method-select" style="margin-bottom: -.25em;">
              <mat-label>Método de envio ativo</mat-label>
              <mat-select [ngModel]="customerContact.sendMethod" (ngModelChange)="onSendMethodChange($event)">
                <mat-option *ngFor="let method of $ESendActiveMethod | keyvalue" [value]="method.value"
                  [matTooltip]="method.value === $ESendActiveMethod.customMessage && !getChannelOpenTime(selectedAction) ? 'O canal precisa estar aberto' : null"
                  matTooltipClass="mat-tooltip-warning"
                  [disabled]="method.value === $ESendActiveMethod.customMessage && !getChannelOpenTime(selectedAction)">
                  {{ method.value | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="action-config-section config-section-content">
            <cm-non-serializable-errors-display *ngIf="errors.length" [errors]="errors">
            </cm-non-serializable-errors-display>

            <mat-expansion-panel *ngIf="templateSelectedFromCampaign" expanded togglePosition="before">

              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ templateSelectedFromCampaign.name }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- <div class="active__call__selected__campaign__text"
                [highlightTemplate]="templateSelectedFromCampaign.template"></div> -->
              <whatsapp-template-message-preview [title]="'Pré-visualização'" [handler]="templatePreviewHandler" ></whatsapp-template-message-preview>

            </mat-expansion-panel>
            <br>

            <!-- *ngIf="!isPartialSend" -->
            <mat-card>
              <div class="sca-container" *ngIf="showAssetAdder">
                <asset-adder [handler]="actionAssetAdder"></asset-adder>
              </div>
              <ng-container *ngIf="hasVariables()">
                <h4>Variáveis</h4>

                <div class="form-container">
                  <ng-container *ngFor="let variable of allPrompts | keyvalue">
                    <mat-form-field appearance="outline" *ngIf="!isChannelPrompt(variable.value)">
                      <mat-label>{{ variable.value.text }}</mat-label>
                      <input matInput [disabled]="isPartialSend" [(ngModel)]="variable.value.value">
                    </mat-form-field>
                  </ng-container>
                </div>
              </ng-container>

              <h4>Configurações de canal</h4>

              <div class="form-container">
                <ng-container *ngFor="let variable of allPrompts | keyvalue">
                  <mat-form-field appearance="outline" *ngIf="isChannelPrompt(variable.value)">
                    <mat-label>{{ variable.value.text }}</mat-label>
                    <input matInput [disabled]="isPartialSend" [(ngModel)]="variable.value.value">
                  </mat-form-field>
                </ng-container>
              </div>
            </mat-card>
          </div>

          <div class="action-config-section config-section-bottom">
            <div class="bottom-section-container" style="flex-wrap: wrap;">
              <app-ns-picker *ngIf="rtfBodyPickerHandler" [handler]="rtfBodyPickerHandler"></app-ns-picker>

              <mat-form-field appearance="fill">
                <mat-label>Ação</mat-label>
                <mat-select (selectionChange)="selectionChange($event)" [(value)]="action">
                  <mat-option *ngFor="let action of actions | keyvalue" [value]="action.key">
                    {{action.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <cm-date-picker
                class="full-width"
                *ngIf="scheduleBeginningHandler"
                [handler]="scheduleBeginningHandler"
                [style.display]="isScheduledSend() ? '' : 'none'"
                margin
              ></cm-date-picker>
            </div>

            <div class="bottom-section-container no-padding"
              *ngIf="showRedirectToMeCheck()">
              <mat-checkbox [(ngModel)]="responseToMe" class="boxed boxed-14">
                Direcione a resposta do cliente para mim
              </mat-checkbox>
            </div>

            <div class="bottom-section-container no-padding">
              <button 
                [disabled]="shouldDisableSave()" 
                style="width: 120px;" 
                mat-flat-button 
                color="primary"
                (click)="doAction()"
              >
                Salvar
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
