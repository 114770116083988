import { constant, TGlobalUID } from '../business/constant';
import { ESCode } from '../error-control/barrel-error';
import { IInteractionTypeJSON } from '../comm-interfaces/business-interfaces';
import { IChainedInteractionJSON, IInteractionJSON, IDeleteInteractionJSON } from '../comm-interfaces/interaction-interfaces'
import { Serializable } from '../business/serializable';
import { Pane } from '../business/pane';
import { UberCache } from '../persistency/uber-cache';
import { EGeolocationIntType, EWalletOperationsIT, EServiceGroupIntType } from '../business/constant.enums';
import { throwErrorIfTrueField, isThisOneOfThat } from '../tools/utility';
import { TArrayID } from '../core-constants/types';
import { Interaction } from './interaction';

export type TInteractionTypeArray = Array<InteractionType>;

export interface IRelatedToInteractionTypeHash {
    [alias: string]: true;
};


export class InteractionType extends Serializable {

    private visible: boolean;
    private chained: boolean;
    private subscribable: boolean;
    private pane: Pane;
    private filterable: boolean;
    private arrayInteractinType: TInteractionTypeArray;
    private relatedTo: string;
    private filteredByPackage: boolean;
    private tree: boolean;
    private trackerInformation: boolean;
    private mutuallYExclusiveFeedback: boolean;
    private allAvatarInteractionExclusive: boolean;
    private avatarInteractionExclusive: boolean;
    private statusFeedback: boolean;
    private bigIcon: boolean;
    private static interactionTypeArray: TInteractionTypeArray = [];



    private constructor(iType: IInteractionTypeJSON) {
        super(constant.objectType.interactionType, iType.primaryID);
        super.rehydrate(iType);
        this.visible = iType.visible;
        this.chained = iType.chained;
        this.tree = iType.tree;
        this.subscribable = iType.subscribable;
        this.pane = Pane.staticFactory(iType.idPane);
        this.filterable = iType.filterable;
        this.relatedTo = iType.relatedTo;
        this.arrayInteractinType = [];
        this.filteredByPackage = iType.filteredByPackage;
        this.trackerInformation = iType.trackerInformation;
        this.mutuallYExclusiveFeedback = iType.mutuallYExclusiveFeedback;
        this.allAvatarInteractionExclusive = iType.mutuallyExclusiveForInteraction;
        this.avatarInteractionExclusive = iType.mutuallyExclusiveOutsideInteraction;
        this.statusFeedback = iType.statusFeedback;
        this.bigIcon = iType.bigIcon;
        if (!InteractionType.interactionTypeArray.some((i) => { return i.iss(this) })) {
            InteractionType.interactionTypeArray.push(this)
        };
    };


    //// FILTERS DE INTERAÇÕES

    public isStoredInClientDatabase(): boolean {
        return (!this.isFeedbackCarrier() && !this.isDeleteInteraction() && this.isNot(constant.interactionType.sharing.shareInteraction))
    }


    public isChained(): boolean { return this.chained; };
    public isTree(): boolean { return this.tree; };
    public isVisible(): boolean { return this.visible; };
    public isSecurityInteraction(): boolean { return this.isRelatedTo(constant.relatedToInteractionType.security); };
    public isSubscribable(): boolean { return this.subscribable };
    public isFeedback(): boolean { return this.isRelatedTo(constant.relatedToInteractionType.feedback); };


    public isFeedbackAble(): boolean {
        return !this.isFeedback();
    }

    public isFaceCompany(): boolean { return this.isRelatedTo(constant.relatedToInteractionType.faceCompany); };
    public isFeedbackCarrier(): boolean { return this.is(constant.interactionType.changeStateSynch.feedbackFiguresCarrier); };

    public isTimed(): boolean { return this.isRelatedTo(constant.relatedToInteractionType.timed) };
    public isSpecialFeature(): boolean {
        return this.isNot(constant.interactionType.standard.citation, constant.interactionType.standard.message);
    };

    public isCitation(): boolean {
        return this.is(constant.interactionType.standard.citation)
    }
    public isStartServiceChat(): boolean {
        return this.is(EServiceGroupIntType.startServiceChat)
    }
    public isFinishServiceChat(): boolean {
        return this.is(EServiceGroupIntType.finishService)
    }

    public isMappgerGenerator(): boolean {
        return this.is(constant.interactionType.sharing.shareInteraction)
    }

    public canAddAPersonalResponseToThis(): boolean { return !this.isDeleteInteraction(); };
    public isConsideredAnswer(): boolean { return this.isNot(constant.interactionType.sharing.shareInteraction) };

    public isSubscription(): boolean { return this.isRelatedTo(constant.relatedToInteractionType.soliciation); };

    public isStantardResponse(): boolean {
        return this.is(constant.interactionType.subscription.stdResponse);
    }

    public isBillableFeedback(): boolean {
        return this.is(constant.interactionType.products.prePurchase.billableDriverFeedback);
    }

    public isMutuallYExclusiveInteractionFeedbackForAvatar(): boolean { return this.mutuallYExclusiveFeedback; };
    public isAllAvatarInteractionExclusive(): boolean { return this.allAvatarInteractionExclusive; };
    public isAvatarInteractionExclusive(): boolean { return this.avatarInteractionExclusive; };
    public isStatusFeedback(): boolean { return this.statusFeedback; }

    public isVisibleOnChat(): boolean {
        return !this.isSecurityInteraction() && !this.isDeleteInteraction() && !this.isTrackerInformation();
    };

    public isNotifiable(): boolean {
        return !this.isSecurityInteraction() && !this.isDeleteInteraction() && !this.isTrackerInformation() &&
            this.isReadInformationAvailable() && !this.is(EServiceGroupIntType.finishService)
    };


    public is360Notifiable(): boolean {
        return this.isNotifiable() && this.isNot(EServiceGroupIntType.startServiceChat);
    }



    public isChatVisibleOnFirstLevel(): boolean {
        return this.isVisibleOnChat() && !this.isChained();
    };

    public isChangeInGroupSecurity(): boolean {
        return this.isRelatedTo(constant.interactionType.security.revokeGroupSecurity, constant.interactionType.security.groupSecurityRule);
    };

    public isDeleteInteraction(): boolean {
        return this.is(constant.interactionType.security.revokeGroupSecurity,
            constant.interactionType.security.revokeRole,
            constant.interactionType.standard.deleteInteraction,
            constant.interactionType.sharing.stopPublishGroup,
            EGeolocationIntType.turnGpsOff);
    };

    public isOrIsDeleting(deleting: IInteractionJSON, ...idInteractionType: TArrayID): boolean {
        return this.is(...idInteractionType) ||
            (this.isDeleteInteraction() && isThisOneOfThat((<IDeleteInteractionJSON>deleting).idInteractionTypeParent, ...idInteractionType))
    };

    public isSocialCCRelatedOps(interaction: IInteractionJSON): boolean {
        return this.isOrIsDeleting(interaction, EServiceGroupIntType.startServiceChat, EServiceGroupIntType.finishService)
    };


    public isTrackerInformation(): boolean { return this.trackerInformation; };

    public isAnnotation(): boolean { return this.is(constant.interactionType.annotation.interactionType) };

    public isChangeStatusProcessing(): boolean {
        return this.isRelatedTo(constant.interactionType.changeStateSynch.changedCarrierInteraction, constant.interactionType.changeStateSynch.trackers);
    };

    public isReadInformationAvailable(): boolean {
        const extraTypes = [constant.interactionType.special.wrapperInteraction];
        return this.isRelatedTo(constant.relatedToInteractionType.message, ...extraTypes)
    };

    public isWalletOperation(): boolean { return this.is(EWalletOperationsIT.operation); };

    public isGroupRegistered(): boolean {
        return !this.isSubscription() && !this.isTrackerInformation() && !this.isTimed()
            && !this.isRelatedTo(constant.interactionType.changeStateSynch.changedCarrierInteraction)
            && !this.isRelatedTo(constant.relatedToInteractionType.config);
    };
    public isVote(): boolean { return this.isRelatedTo(constant.interactionType.feedback.vote); };
    public isQueue(): boolean { return this.isRelatedTo(constant.relatedToInteractionType.queue); };
    public isFilterable(): boolean { return this.filterable };


    public isChainedOrTreeWithParent(json: IInteractionJSON): boolean {
        if (this.isChained()) {
            throwErrorIfTrueField(!(<IChainedInteractionJSON>json).idInteractionParent, ESCode.server1.fromServer,
                ESCode.server1.f.typeConsistency.interactionWithNoParent, true, 'isChainedOrTree',
                'Interaction', json.primaryID, 'is mall-formed with idParent null');
            return true;
        } else if (this.isTree()) {
            return (<IChainedInteractionJSON>json).idInteractionParent != null;
        } else
            return false;
    };


    ///////////// FIM DE FILTER //////////////////////////////

    public isBigIcon(): boolean { return this.bigIcon; };

    public getPane(): Pane { return this.pane };

    public getRelatedTo(): string { return this.relatedTo; };

    public getInteractionTypeID(): TGlobalUID { return super.getPrimaryID(); };
    public getInteractionTypeAllowed(): TInteractionTypeArray { return this.arrayInteractinType; }

    public static resetInteractionTypeArray(): void {
        InteractionType.interactionTypeArray = [];
    }

    public static getNoParentInteractionTypeArray(): TInteractionTypeArray {
        let array: TInteractionTypeArray = InteractionType.getInteractionTypeArray();
        for (let iType of array) {
            if (!array.find((i) => {
                return i.getInteractionTypeAllowed().find((allowed) => {
                    return allowed.getInteractionTypeID() == iType.getInteractionTypeID()
                }) ? true : false;
            })) {

                array.push(iType);
            }
        };
        return array;
    };


    public toJSON(): IInteractionTypeJSON {
        let json: IInteractionTypeJSON = {
            ...super.toJSON(),
            visible: this.visible,
            chained: this.chained,
            tree: this.tree,
            subscribable: this.subscribable,
            idPane: this.pane.getPaneID(),
            filterable: this.filterable,
            arrayInteractinType: [],
            relatedTo: this.relatedTo,
            filteredByPackage: this.filteredByPackage,
            trackerInformation: this.trackerInformation,

            mutuallYExclusiveFeedback: this.mutuallYExclusiveFeedback,
            mutuallyExclusiveForInteraction: this.allAvatarInteractionExclusive,
            mutuallyExclusiveOutsideInteraction: this.avatarInteractionExclusive,
            statusFeedback: this.statusFeedback,
            bigIcon: this.bigIcon
        };
        for (let r of this.arrayInteractinType) {
            json.arrayInteractinType.push(r.getPrimaryID());
        };
        return json;
    };


    public addInteractionAllowedType(interactonType: InteractionType) {
        this.arrayInteractinType.push(interactonType);
    };


    public isInteractionAlowed(interactionChild: InteractionType): boolean {
        return interactionChild.isDeleteInteraction() ||
            interactionChild.isFeedback() ||
            this.arrayInteractinType.some((i) => { return i.iss(interactionChild) })
    };

    public checkInteracionAllowed(interactionChild: InteractionType): void {
        if (!this.isInteractionAlowed(interactionChild)) {

            /*     throw new CustomError(
                     ECustomErrorTGlobalID.errorID, false,
                     'checkInteractionAllowed',
                     'Tipos de transação (',
                     this.getInteractionTypeID().toLocaleString(), ') e (' ,
                    interactionChild.getInteractionTypeID().toLocaleString() , ') não consistentes entre si');
                 */
        };
    };



    public rehydrate(json: IInteractionTypeJSON) {
        super.rehydrate(json);
        this.arrayInteractinType = [];
        for (let idInteractionType of json.arrayInteractinType)
            this.addInteractionAllowedType(InteractionType.staticFactory(idInteractionType));
    };

    public getMyRelatedInteractionTypeHash(): IRelatedToInteractionTypeHash {
        if (this.filteredByPackage) {
            return InteractionType.getRelatedInteractionTypeHash(this.getInteractionTypeID());
        }
        else
            return InteractionType.getRelatedInteractionTypeHash(this.getRelatedTo());
    };

    public isRelatedTo(...relatedToArray: string[]): boolean {
        for (let relatedTo of relatedToArray)
            if (this.relatedTo == relatedTo || this.getInteractionTypeID() == relatedTo)
                return true;
        return false;
    };

    // Use the property "Is related to" to filter all the interactionTypes which have the same relation
    // Examples: 
    // - Get all the security interactions
    // - Get all the solicitation interactions
    public static getRelatedInteractionTypeHash(...relatedToArray: string[]): IRelatedToInteractionTypeHash {
        let hash: IRelatedToInteractionTypeHash = {};
        for (let iType of InteractionType.getInteractionTypeArray()) {
            for (let relatedTo of relatedToArray)
                if (iType.isRelatedTo(relatedTo) || iType.getInteractionTypeID() == relatedTo)
                    hash[iType.getInteractionTypeID()] = true;
        }
        return hash;
    };


    public static getInteractionTypeArray(): TInteractionTypeArray {
        return InteractionType.interactionTypeArray;
    };

    public static staticFactory(idInteractionType: TGlobalUID): InteractionType {
        return <InteractionType>UberCache.uberFactory(idInteractionType, constant.objectType.interactionType, true);
    };

    public static plainFactoryMessage(json: IInteractionTypeJSON): InteractionType {
        let interactionType: InteractionType = <InteractionType>UberCache.uberFactory(json.primaryID, constant.objectType.interactionType, false);
        if (interactionType == null)
            interactionType = new InteractionType(json);
        //interactionType.addUberCache();
        return interactionType;
    };

    public static factoryMessage(json: IInteractionTypeJSON): InteractionType {
        let interactionType: InteractionType = InteractionType.staticFactory(json.primaryID);
        interactionType.rehydrate(json);
        return interactionType;
    };
};

