<div class="editor-area" [style]="'--editor-font-size: '+varEditorUIConfig.fontSize+'%; --edit-textarea-height: '+varEditorUIConfig.textAreaHeightPX+'px'">
  <ng-container *ngIf="!varEditorUIConfig.viewRawCode else standardEditor">
    <app-var-editor-text-area-tiptap
      #tiptapEditor
      [parameters]="parameters"
      [allVariables]="allVariables"
      [placeholder]="placeholderText"
      [varEditorUIConfig]="varEditorUIConfig"
      [rawTextCharCount]="rawTextCharCount"
      [amoutCharacters]="amoutCharacters"
      [(content)]="rawText"
      [limitCharacters]="maxCharsLength">
    </app-var-editor-text-area-tiptap>
  </ng-container>
  <div #textAreaResizer class="text-area-resizer">
  </div>
  <div #emojibox class="emoji-box-container" [style.height]="varEditorUIConfig.showEmojiBox ? '220px' : '0'"></div>
</div>

<ng-template #standardEditor>
  <div class="row header">
    <div class="tools-container">
      <ng-container *ngIf="!shouldHideBBCode">
        <ng-container *ngFor="let bbCodeStyleTag of bbCodeStyleTags">
          <button *ngIf="showBBCodeStyleTag(bbCodeStyleTag)" class="text-style-tool"
            [matTooltip]="bbCodeStyleTooltip[bbCodeStyleTag]" matTooltipPosition="above" (click)="addBBCodeStyleTag(bbCodeStyleTag)">
            <mat-icon>{{ bbCodeStyleToIcon[bbCodeStyleTag] }}</mat-icon>
          </button>
        </ng-container>
      </ng-container>
      <div class="text-area-tools">
        <div class="l-left-tools">
          <button *ngIf="!shouldHideEmojis" class="toggle-emoji" [class.active]="varEditorUIConfig.showEmojiBox" (click)="toggleEmojiBox()">
            <mat-icon class="mat-22">tag_faces</mat-icon>
          </button>
    
          <button class="toggle-raw" [class.active]="varEditorUIConfig.viewRawCode" (click)="toggleViewRaw()">
            <mat-icon class="mat-24">raw_on</mat-icon>
          </button>
    
          <div class="font-size-buttons" [matTooltip]="varEditorUIConfig.fontSize+'%'" matTooltipPosition="above">
            <button class="font-size-button" (click)="updateTextSize(varEditorUIConfig.fontSize - textUpdateStep)">
              <mat-icon class="mat-14">text_decrease</mat-icon>
            </button>
            <button class="font-size-button" (click)="updateTextSize(varEditorUIConfig.fontSize + textUpdateStep)">
              <mat-icon class="mat-14">text_increase</mat-icon>
            </button>
            <button *ngIf="varEditorUIConfig.fontSize !== varEditorUIConfigDefault.fontSize" (click)="updateTextSize(varEditorUIConfigDefault.fontSize)" class="font-size-button l-reset">
              <mat-icon class="mat-14">restart_alt</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="content-status">
      <div class="text-length-count">
        <span *ngIf="rawTextCharCount !== amoutCharacters" class="chars-count" helperInfo="Alguns emojis ocupam mais de um espaço" hiIndicatorStyle="right: -20px;">caracteres: {{ rawTextCharCount }}</span>
        <span *ngIf="shouldShowCanonicalAlert()" class="chars-count" helperInfo="Certifique-se que o valor retornado pelos canônicos no momento do envio não irá ultrapassar o limite de caracteres" hiIndicatorStyle="right: -20px;" hiIcon="error_outline">canônicos: </span>
        {{ amoutCharacters }} / {{ maxCharsLength }}
      </div>
    </div>
  </div>

  <div class="row main-content">
    <textarea
      [(ngModel)]="rawText"
      (ngModelChange)="callOnVarEditorChangeRawText()"
      (beforeinput)="handlePress($event)"
      (keydown)="setTextareaCursorPosition($event)"
      (blur)="setTextareaCursorPosition($event)"
      id="varEditorTextarea"
      #varEditorTextarea
      placeholder="{{ placeholderText }}"
      cdkFocusInitial
    ></textarea>
  </div>
</ng-template>
