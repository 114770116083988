<ng-container *ngIf="showContent">

  <mat-tab-group class="boxed-tabs boxed-dense tabs-wrap" [selectedIndex]="selectedTabIndex" animationDuration="200ms">
    <mat-tab *ngFor="let item of handlers">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">{{ botEditTabsIcons[item.title] }}</mat-icon>
        {{ item.title }}
      </ng-template>
      <cm-dynamic-component *ngIf="item.handler" [handler]="item.handler"></cm-dynamic-component>
    </mat-tab>
  </mat-tab-group>

</ng-container>
