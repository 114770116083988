import { TGlobalUID, constant} from './constant';
import { GroupType } from './group-type';

export interface IContractSpecs {
    allowExclusiveAvatar: boolean;
    canSellItens: boolean,
    s360Services: {
        allowed: boolean;    
    }

};

export const allOpenContract: IContractSpecs = {
    allowExclusiveAvatar: true,
    canSellItens: true,
    s360Services: {
        allowed: true
    }
};




export class Contract {
    private specs: IContractSpecs;
    

    private constructor(specs: IContractSpecs) {
        this.specs = specs;
    };

    public isExclusiveAvatar(): boolean {return this.specs.allowExclusiveAvatar; };

    public static factoryContract(contract: IContractSpecs): Contract {
        const cont = new Contract(contract);
        return cont;
    };
;
    public isFeatureOnContract(feature: TGlobalUID): boolean {return true; };

    public isCanSellItens(): boolean {
        return this.specs.canSellItens;
    };

    public has360Services(): boolean {
        return this.specs.s360Services.allowed;
    }



    

  
    
};


