import { Component, OnInit } from '@angular/core';
import { IBPMMarketingRootServer, TBpmMarketingNaturalNsTypes, TBpmMarketingNsTypes } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ICampaignActionPicker } from 'app/components/dashboard/campaign-action-picker/campaign-action-picker.component';
import { ETimestmapPickerElements, TimestampPickerHandler } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { MKTRootBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { MarketingBpmBase } from '../marketing-bpm-base.component';

@Component({
    selector: 'app-marketing-bpm-root',
    templateUrl: './marketing-bpm-root.component.html',
    styleUrls: ['./marketing-bpm-root.component.scss']
})
export class MarketingBpmRootComponent extends MarketingBpmBase<MKTRootBpmGraphCreateHandler, IBPMMarketingRootServer> implements OnInit {
    nsType = ENonSerializableObjectType.bpmMarketingRoot as TBpmMarketingNaturalNsTypes;
    timestampHandler: TimestampPickerHandler;
    actionPickerHandler: ICampaignActionPicker;

    ngOnInit() {
        super.ngOnInit();
        this.initTimestampPicker();
        this.initCampaignActionPicker();
    }

    getNS(): IBPMMarketingRootServer {
        return this.handler.getMarketingRoot();
    }

    private initTimestampPicker() {
        this.timestampHandler = new TimestampPickerHandler({
            label: 'Executar próximo comando após',
            elements: {
                [ETimestmapPickerElements.Days]: {
                    min: 0,
                    max: 365
                },
                [ETimestmapPickerElements.Hours]: {
                    min: 0,
                    max: 23
                },
                [ETimestmapPickerElements.Minutes]: {
                    min: 0,
                    max: 59
                },
            },
            time: this.ns.bpmScheduleMS,
            clientCallback: {
                onChangeTime: (value: number) => {
                    this.ns.bpmScheduleMS = value;
                },
            },
        });
    }

    private initCampaignActionPicker() {
        this.actionPickerHandler = {
            value: this.ns.idCampaingAction,
            valueChange: (v) => this.ns.idCampaingAction = v,
        }
    }

}
