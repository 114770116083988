import {Interaction} from '../interaction';
import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { InteractionType } from '../interaction-type';
import { constant } from '../../business/constant';
import { IConfigurationItemJSON} from '../../comm-interfaces/barrel-comm-interfaces';
import { Serializable } from '../../business/serializable';
import { UberCache } from '../../persistency/uber-cache';


export type TConfigurationItemInteraction = Array<ConfigurationItemInteraction>;


export class ConfigurationItemInteraction extends Interaction {

    private configurationType: TGlobalUID;


    protected constructor(primaryID: TGlobalUID, participant: Participant, configurationType: TGlobalUID){ 
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.configCarriers.configurationItem));
        this.configurationType = configurationType;
    };

    public toJSON(): IConfigurationItemJSON {
        return {
            ...super.toJSON(),
            configurationType: this.configurationType,
        };
    };

    public getConfigurationType(): TGlobalUID {return this.configurationType;};

    public rehydrate(json: IConfigurationItemJSON): void {
        super.rehydrate(json);
	};


	public static staticFactory(idConnection: TGlobalUID): ConfigurationItemInteraction {
	    return <ConfigurationItemInteraction>Interaction.staticFactory(idConnection);
    };

    public static factoryMessage(json: IConfigurationItemJSON, participant: Participant = null): ConfigurationItemInteraction {
        let subscription: ConfigurationItemInteraction = <ConfigurationItemInteraction>UberCache.uberFactory(json.primaryID, constant.objectType.interactionType, false);
        if (subscription == null) {
            subscription = new ConfigurationItemInteraction(json.primaryID, 
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                json.configurationType );
        };
        subscription.rehydrate(json);
        return subscription;
    };
    
 
};