<app-modal-body [title]="getRoleLabel()">

  <div class="wrapper">

    <mat-progress-spinner class="spinner"
      *ngIf="!canShowRolesCheckBoxed()"
      mode="indeterminate"
      [diameter]="30" >
    </mat-progress-spinner>
    <div *ngIf="canShowRolesCheckBoxed()">
      <div  class="permission__container" *ngFor="let role of getRoles()">
        <mat-checkbox
          [ngModel]="isRoleSelected(role)"
          (ngModelChange)="onRoleModelChange($event, role)"
          [disabled]="isDisableRole(role)"
          (click)="onRoleClicked($event)">
          {{ role.getName() }}
        </mat-checkbox>
      </div>
    </div>

    <button class="save__btn" mat-raised-button (click)="onSaveClicked()">
      <mat-progress-spinner class="spinner"
        *ngIf="!canShowSaveBtnLabel()"
        mode="indeterminate"
        [diameter]="30">
      </mat-progress-spinner>
      <span *ngIf="canShowSaveBtnLabel()">
        {{ translations.save }}
      </span>
    </button>
  </div>

</app-modal-body>
