import { Injectable } from '@angular/core';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { EFacebookChannelIntegrationReqRes } from '@colmeia/core/src/request-interfaces/message-types';
import { IFacebookChannelIntegrationPage, IFacebookChannelIntegrationRequest, IFacebookChannelIntegrationResponse, IFacebookChannelIntegrationSubscribePageToAppRequest, IFacebookChannelIntegrationSubscribePageToAppResponse, IFacebookGetColmeiaAppIdRequest, IFacebookGetColmeiaAppIdResponse, IFacebookGetColmeiaAppLoginConfigRequest, IFacebookGetColmeiaLoginConfigResponse, IFacebookGetStoredPagesRequest, IFacebookGetStoredPagesResponse, IFacebookLogoutUserRequest, IFacebookLogoutUserResponse, IMetaRegisterWebhookAndPhoneNumber, IMetaRegisterWebhookAndPhoneNumberRequest, IMetaExchangeCodeForTokenAndGetWhatsappInfoResponse, IMetaWhatsappChannelCheckIfPhoneIsRegisteredRequest, IMetaWhatsappChannelCheckIfPhoneIsRegisteredResponse, IMetaRegisterWebhookAndPhoneNumberResponse, IMetaExchangeCodeForTokenAndGetWhatsappInfoRequest } from "@colmeia/core/src/shared-business-rules/channels/facebook-integration-req-resp.dto";
import { FacebookIntegrationModel } from '@colmeia/core/src/shared-business-rules/channels/facebook-integration.model';
import { IMessengerSocialMediaConnectionServer, TMessengerProviderType } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { InitParams } from 'app/model/facebook/init-params';
import { LoginResponse } from 'app/model/facebook/login-response';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { FacebookService } from '../facebook/fb.service';

type TAppId = string


@Injectable({
    providedIn: 'root'
})
export class FacebookIntegrationService {
    private initCache: Record<TAppId, any> = {}

    constructor(
        private fb: FacebookService,
        private api: ServerCommunicationService
    ) {
        window.addEventListener('message', (event) => this.metaSessionInfoListener(event));
    }

    metaSessionInfoListener(event) {
        if (event.origin !== "https://www.facebook.com") {
            return;
        }

        try {
            const data = JSON.parse(event.data);
            console.log({ FacebookEmbeddedSignupServiceMetaSessionInfoListener: data });

            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                // if user finishes the Embedded Signup flow
                if (data.event === 'FINISH') {
                    // const { phoneID, wabaID } = data.data;
                    console.log({ FacebookEmbeddedSignupServiceMetaSessionInfoListenerData: data });
                } else {
                    // if user cancels the Embedded Signup flow
                    const { currentStep } = data.data;
                    console.log({ FacebookEmbeddedSignupServiceMetaSessionInfoListenerCancel: currentStep });
                }
            }
        } catch {
            // Don’t parse info that’s not a JSON
            console.log('Non JSON Response', event.data);
        }
    };

    private async init(appId: TAppId) {
        let initParams: InitParams = {
            appId,
            xfbml: true,
            version: FacebookIntegrationModel.getAPIVersion()
        };

        const fbInitResult = await this.fb.init(initParams);
        return fbInitResult
    }

    async getUserName(): Promise<string> {
        try {
            const userData = await this.fb.api('me')
            return userData?.name
        } catch (error) {
            this.fb.logout()
        }
    }

    async disconnectMetaPage(idChannel: string, pageAccessToken: string): Promise<IMessengerSocialMediaConnectionServer> {
        const result = await this.api.sendRequest<IFacebookLogoutUserRequest, IFacebookLogoutUserResponse>(
            EFacebookChannelIntegrationReqRes.disconnectMetaPage
        )({
            data: { pageAccessToken, idChannel }
        });
        return result.ns
    }

    async getColmeiaConfigLoginId(): Promise<IFacebookGetColmeiaLoginConfigResponse> {
        const result = await this.api.sendRequest<IFacebookGetColmeiaAppLoginConfigRequest, IFacebookGetColmeiaLoginConfigResponse>(
            EFacebookChannelIntegrationReqRes.getColmeiaAppLoginConfiguration
        )({});
        return result
    }

    async getColmeiaAppId(): Promise<{ colmeiaAppId: string; }> {
        const result = await this.api.sendRequest<IFacebookGetColmeiaAppIdRequest, IFacebookGetColmeiaAppIdResponse>(
            EFacebookChannelIntegrationReqRes.getColmeiaAppId
        )({});
        return { ...result }
    }

    async getEmbeddedSignUpLoginConfig(onlyWABASharing: boolean, partnerID: string) {
        const bestFeatureType = onlyWABASharing ? { featureType: "only_waba_sharing" } : {}
        const bestSetup = isValidString(partnerID) ? { setup: { solutionID: partnerID } } : {}
        const configID: string = (await this.getColmeiaConfigLoginId()).colmeiaAppLoginConfigurationId

        const config = {
            config_id: configID, // configuration ID obtained in the previous step goes here
            response_type: 'code', // must be set to 'code' for System User access token
            override_default_response_type: true,
            return_scopes: true,
            auth_type: 'rerequest',
            extras: {
                feature: "whatsapp_embedded_signup",
                // featureType: "only_waba_sharing"
                sessionInfoVersion: 2,
                ...bestSetup,
                ...bestFeatureType
            }
        }

        console.log({ FacebookEmbeddedSignupLaunchWhatsAppSignupaccessToken: config });
        return config
    }

    async exchangeCodeByToken(codeToBeExchangedToToken: string, isGetWABAName?: boolean) {
        const result = await this.api.sendRequest<IMetaExchangeCodeForTokenAndGetWhatsappInfoRequest, IMetaExchangeCodeForTokenAndGetWhatsappInfoResponse>(
            EFacebookChannelIntegrationReqRes.exchangeCodeForTokenAndGetWhatsappInfo
        )({
            codeToBeExchangedToToken,
            isGetWABAName
        });
        return result
    }

    async registerPhoneNumber(metaAPIData: IMetaRegisterWebhookAndPhoneNumber): Promise<IMetaRegisterWebhookAndPhoneNumberResponse> {
        const result = await this.api.sendRequest<IMetaRegisterWebhookAndPhoneNumberRequest, IMetaRegisterWebhookAndPhoneNumberResponse>(
            EFacebookChannelIntegrationReqRes.registerWebhookAndPhoneNumber
        )(metaAPIData);
        return result
    }

    async getUserID(): Promise<string> {
        const facebookUserStatus = await this.fb.getLoginStatus()
        return facebookUserStatus.authResponse?.userID
    }

    async isMetaUserConnected(): Promise<boolean> {
        const facebookUserStatus = await this.fb.getLoginStatus()
        console.log({ isMetaUserConnected: facebookUserStatus });

        return facebookUserStatus.status == "connected"
    }

    async initFacebookSDKAtLeastOnce(appId: TAppId) {
        if (isValidRef(this.initCache[appId])) {
            return
        }

        const fbInitResult = await this.init(appId)
        this.initCache[appId] = fbInitResult
    }

    async loginWithFacebook(channel: IMessengerSocialMediaConnectionServer, appId: TAppId): Promise<LoginResponse> {
        console.log({ channelType: channel.socialMediaType, appId });
        const facebookAndInstagramPermissions = 'pages_show_list,pages_messaging,instagram_manage_messages,instagram_basic,pages_manage_metadata'
        const channelTypeScopeMap: Record<TMessengerProviderType, string> = {
            [EDelivery360Action.Delivery360FBMessenger]: facebookAndInstagramPermissions,
            [EDelivery360Action.DeliveryInstagram]: facebookAndInstagramPermissions,
        }
        await this.initFacebookSDKAtLeastOnce(appId)
        const response: LoginResponse = await this.fb.login({
            scope: channelTypeScopeMap[channel.socialMediaType],
            return_scopes: true,
            auth_type: 'rerequest'
        })
        console.log({ loginWithFacebook: response });

        return response
    }

    async updateFacebookPageListFromUser(channelType: TMessengerProviderType, loginResponse: LoginResponse): Promise<IFacebookChannelIntegrationResponse> {
        const response = await this.api.sendRequest<IFacebookChannelIntegrationRequest, IFacebookChannelIntegrationResponse>(
            EFacebookChannelIntegrationReqRes.updateFacebookPageList
        )({
            data: { ...loginResponse.authResponse, channelType }
        });

        return response
    }

    async getFacebookPageList(): Promise<IFacebookChannelIntegrationPage[]> {
        const response = await this.api.sendRequest<IFacebookGetStoredPagesRequest, IFacebookGetStoredPagesResponse>(
            EFacebookChannelIntegrationReqRes.getFacebookPageList
        )({});

        return response.pageList
    }

    async subscribeAppToPage(
        fbChannelNS: IMessengerSocialMediaConnectionServer,
        fbPage: IFacebookChannelIntegrationPage
    ): Promise<IMessengerSocialMediaConnectionServer> {
        const response = await this.api.sendRequest<IFacebookChannelIntegrationSubscribePageToAppRequest, IFacebookChannelIntegrationSubscribePageToAppResponse>(
            EFacebookChannelIntegrationReqRes.subscribeAppToPage
        )({
            data: {
                fbChannelNS,
                pageAccesToken: fbPage.access_token,
                pageId: fbPage.id,
                pageName: fbPage.name
            }
        });
        console.log({ subscribeAppToPage: response });

        return response.ns
    }

    async checkIfPhoneIsRegistered(phoneID: string, isMustThrowOnError: boolean = true) {
        const response = await this.api.sendRequest<IMetaWhatsappChannelCheckIfPhoneIsRegisteredRequest, IMetaWhatsappChannelCheckIfPhoneIsRegisteredResponse>(
            EFacebookChannelIntegrationReqRes.checkIfPhoneIsRegistered
        )({
            data: { phoneID, isMustThrowOnError }
        });

        return {
            isPhoneRegistered: response?.isPhoneRegistered,
            errorDescription: response?.errorDescription
        };
    }
}
