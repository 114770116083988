import { IInteractionJSON, IInteractionJSONArray, TPublishGroupArray, IPublishGroup, IChainedInteractionJSON,
    IChainedInteractionJSONArray,
    TThreadInfoArray} from '../comm-interfaces/interaction-interfaces';
import { constant, TGlobalUID } from '../business/constant';
import { ICacheFeedbackInteraction, noFeedbackFields, feedbackControlFields } from '../comm-interfaces/feedback-interfaces';
import { TArrayID } from '../core-constants/types';
import {isValidArray, isValidRef, isInvalid, lastArrayItem, getClock} from '../tools/utility';
import { TInteractionArray, Interaction } from '../interaction/interaction';
import { ChainedInteraction} from '../interaction/chained-interaction';
import { Feedback} from '../interaction/feedback';
import { InteractionType} from '../interaction/interaction-type';
import { EThreadType,  EServiceGroupIntType, } from '../business/constant.enums';
import { IInteractionFeedbackDriver, TDriverFeedbackArray, TDriverToFeedbacks } from '../comm-interfaces/business-interfaces';
import { UberCache } from '../persistency/uber-cache';
import {IMultimediaInstanceJSON} from "../comm-interfaces/multi-media-interfaces";
import {MultiMediaType} from "../multi-media/multi-media-type";
import {
    MultimediaNatureEmojiMap
} from "../shared-business-rules/interaction-type/interaction-type-rules";
import {Serializable} from "../business/serializable";



export function getInteractionThreadInfo(idInteraction: TGlobalUID): TThreadInfoArray {
    if (UberCache.testCache(idInteraction)) {
        const parent = <Interaction>UberCache.unsafeUberFactory(idInteraction);
        if (parent.hasThreadInfo()) {
            return parent.getThreadInfo();
        };
    };
    return null;

}

export function sendInteractionOnAttending(interaction: Interaction): boolean {
    return interaction.getInteractionType().isNot(EServiceGroupIntType.startServiceChat)
}

export function feedbackFiguresToIDFeedbackArray(figures: ICacheFeedbackInteraction): TArrayID {
    const feedbackArray: TArrayID = [];
    for (const idFeedback in figures) {
        if (isValidFeedbackOnFigure(idFeedback) && figures[idFeedback] > 0) {
            feedbackArray.push(idFeedback);
        };
    };
    return feedbackArray;
};

export function isValidFeedbackOnFigure(idFeedback: TGlobalUID): boolean {
    return ! noFeedbackFields.some((fld) => idFeedback == fld);
};

export function getTotalFeedbacks(figures: ICacheFeedbackInteraction): number {
    return figures[feedbackControlFields.total];
}


export function isFirstLevelInteractionJSON(interaction: IInteractionJSON): boolean {
    const iType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType);
    return iType.isVisibleOnChat();
}


export function isFirstLevelInteraction(interaction: Interaction, chatViewMode: boolean = false): boolean {
    return interaction.getInteractionType().isVisibleOnChat()
        && (!isSecondLevelInteraction(interaction, chatViewMode));
}


export function isSecondLevelInteractionJSON(interation: IInteractionJSON, chatViewMode: boolean = false): boolean {
    const iType: InteractionType = InteractionType.staticFactory(interation.idInteractionType);
    return (iType.is(constant.interactionType.changeStateSynch.feedbackFiguresCarrier) || iType.isFeedback())
        || (!chatViewMode
            && iType.isChainedOrTreeWithParent(interation)
            && iType.isVisibleOnChat()
        );
};




export function getAllSecondLevelInteraction(interactions: TInteractionArray): TInteractionArray {
    return interactions.filter((x) => {return isDisplayableOnSecondLevel(x)})
};

export function isDisplayableOnSecondLevel(interation: Interaction, chatViewMode: boolean = false): boolean {
    return interation.getInteractionType().isVisibleOnChat() &&
        (
            (interation.getInteractionType().is(constant.interactionType.changeStateSynch.feedbackFiguresCarrier) || interation.getInteractionType().isFeedback())
            || (!chatViewMode
                && interation.isChainedOrTreeWithParent()
            )
        );
};




export function isSecondLevelInteraction(interation: Interaction, chatViewMode: boolean = false): boolean {
    return (interation.getInteractionType().is(constant.interactionType.changeStateSynch.feedbackFiguresCarrier) || interation.getInteractionType().isFeedback())
        || (!chatViewMode
            && interation.isChainedOrTreeWithParent()
            && interation.getInteractionType().isVisibleOnChat()
        );
};




export function includedInChatInteraction(interaction: Interaction): boolean {
    return interaction.getInteractionType().isStoredInClientDatabase() && interaction.isProcessedByServer();
};

export function includedInteractions(interactions: TInteractionArray): TInteractionArray {
    return interactions.filter(interaction => includedInChatInteraction(interaction));
}


export function isFeedbackCarrier(interaction: IInteractionJSON): boolean {
    return interaction.idInteractionType == constant.interactionType.changeStateSynch.feedbackFiguresCarrier;
};

export function getFullDescendency<T extends IInteractionJSONArray>(descendents: IInteractionJSONArray, idInteractionParent: TGlobalUID): T {
    return <T>descendents.filter((i) => {return i.idInteractionParentArray.some((p) => {return p == idInteractionParent})})
};

export function getFirstLevelInteractionChildren<T extends IChainedInteractionJSONArray>(descendents: IChainedInteractionJSONArray, idInteraction: TGlobalUID): T {
    return <T>descendents.filter((i) => {return i.idInteractionParent === idInteraction});
};

export function isRootInteraction(interaction: IInteractionJSON): boolean {
    return !InteractionType.staticFactory(interaction.idInteractionType).isChainedOrTreeWithParent(interaction)
}

export function getRootInteraction(interactions: TInteractionArray): Interaction {
    return interactions.find((x) => {return (<ChainedInteraction>x).getInteractionParent() ? false : true});
};

export function getRootInteractions(interactions: TInteractionArray): TInteractionArray {
    return interactions.filter((x) => {return  !x.isChainedOrTreeWithParent()});
};

export function getRootInteractionFromInteraction(interaction: IInteractionJSON): TGlobalUID {
    return interaction.idInteractionParentArray[0];
};

export function getRootIDEvenIfroot(interaction: IInteractionJSON): TGlobalUID {
    return interaction.idInteractionParentArray[0] || interaction.primaryID;
};

export function getInteractionRootJSON<T extends IInteractionJSON>(interactions: IInteractionJSONArray): T {
    return <T>interactions.find( (i) => {return  isInvalid((<IChainedInteractionJSON>i).idInteractionParent)});
};


export function getInteractionRootsJSON<T extends IInteractionJSON>(interactions: IInteractionJSONArray): Array<T> {
    return <Array<T>>interactions.filter( (i) => {return  isInvalid((<IChainedInteractionJSON>i).idInteractionParent)});
};



export function isChildOff(idRootInteraction: TGlobalUID, interaction: Interaction): boolean {
    return idRootInteraction === interaction.getInteractionParentArray()[0];
};

export function getPubForGroup(idGroup: TGlobalUID, publishingGroups: TPublishGroupArray): IPublishGroup {
    return isValidArray(publishingGroups) ?  publishingGroups.find((p) => {return p.idGroup == idGroup}) : null;
};

export function getLastInteractionWithoutReplyOf(idAvatar: TGlobalUID, interactions: TInteractionArray): Interaction {
    const last: Interaction = interactions.find((x, index) => {
        return x.getParticipant().getAvatar().is(idAvatar) && 
        ! interactions.some((i, index2) => {return index2 > index && !i.getParticipant().getAvatar().is(idAvatar)})
    })
    return last;
}

export function getLastClockTickWithoutReplyOf(idAvatar: TGlobalUID, interactions: TInteractionArray): number {
    const last: Interaction = getLastInteractionWithoutReplyOf(idAvatar, interactions);
    return isValidRef(last)? last.getClockTick() : undefined
}


export function isReplicatedGroups(interaction: IInteractionJSON): boolean {
    return isValidArray(interaction.publishingGroups, 2)
}



export function getPubIDForGroup(idGroup: TGlobalUID, publishingGroups: TPublishGroupArray): string {
    const pub: IPublishGroup = getPubForGroup(idGroup, publishingGroups);
    return pub ? pub.idPublishingGroup : null;
}


export function getOneIDPubGroup(publishingGroups: TPublishGroupArray): TGlobalUID {
    return isValidArray(publishingGroups) ? publishingGroups[0].idPublishingGroup : null;
}

export function addPubGroupIntoInteraction(pubArray: TPublishGroupArray, pub: IPublishGroup) {
    if (pub) {
        const idx: number = pubArray.findIndex((p) => {return p.idGroup == pub.idGroup});
        if (idx == -1) {
            pubArray.push(pub);
        } else {
            pubArray[idx] = pub;
        };
    };
};

export function metricHasGeneralAnswer(generalAnswerID: TGlobalUID, metric: IInteractionJSON): boolean {
    return getGeneralResponseIdFromInteraction(generalAnswerID, metric) != null
}

export function getGeneralResponseIdFromInteraction(idGeneralAnswer: TGlobalUID, interaction: IInteractionJSON): TGlobalUID {
    const foundGeneralResponse = interaction.threadInfo.find(thread => thread.idGeneralResponse === idGeneralAnswer)
    return foundGeneralResponse
        ? foundGeneralResponse.idGeneralResponse
        : null
}

export function getAllInteractionThreadInfo(interaction: IInteractionJSON): TArrayID {
    return isValidArray(interaction.threadInfo) ? interaction.threadInfo.filter((i) => {return i.threadType == EThreadType.featureThread})
        .map((i) => {return i.idThread}) : [];
};

export function putFeedbackData(interaction: IChainedInteractionJSON): void {
    if (interaction.idFeedback) {
        const feedback: Feedback = Feedback.staticFactory(interaction.idFeedback);
        interaction.isFeedback = true;
        interaction.isTypeFeedback = InteractionType.staticFactory(interaction.idInteractionType).isFeedback();
        interaction.isSendableFeedback = feedback.isSendAble() || Interaction.isFullfJText(interaction, constant.serializableField.chat_text);
        interaction.feedback = {
            points: feedback.getPoints(),
            solved: feedback.isPositive(),
        };
    };
};

export function mustCompleteInfoJSON(interaction: IInteractionJSON): boolean {
    return InteractionType.staticFactory(interaction.idInteractionType).isSubscription();
}

export function mustComplementInfo(interaction: Interaction): boolean {
    return mustCompleteInfoJSON(interaction.toJSON());
};


export function addToDriverFeedbackArray(driverFeedbacks: TDriverFeedbackArray, driver: IInteractionFeedbackDriver): void {
    const idx: number = driverFeedbacks.findIndex((d) => {return d.idDriverInteraction == driver.idDriverInteraction})
    if (idx == -1) {
        driverFeedbacks.push(driver);
    } else {
        driverFeedbacks[idx] = driver;
    };
};

export function getDriverToFeedbacksMap(driverFeedbacksFromInteraction: TDriverFeedbackArray): TDriverToFeedbacks {
    const driverToFeedbacks: TDriverToFeedbacks = new Map();
    if (!driverFeedbacksFromInteraction)
        return driverToFeedbacks;

    driverFeedbacksFromInteraction
        .sort((a, b) => a.priority - b.priority)
        .map((driver: IInteractionFeedbackDriver) => InteractionType.staticFactory(driver.idDriverInteraction))
        .forEach((driver: InteractionType) =>
            driverToFeedbacks.set(
                driver,
                Feedback.getInteractionFeedback(driver)
            )
        );
    return driverToFeedbacks;
}






export function interactionReadableMessage(interaction: IInteractionJSON): string {
    let result = '';
    switch (interaction.idInteractionType) {
        case constant.interactionType.serviceGroup.startServiceChat:
            result = 'Início de atendimento';
            break;
        case constant.interactionType.serviceGroup.finishService:
            result = 'Fim de atendimento';
            break;
        default:
            if (isValidRef(interaction.multimediaObject) && isValidArray(interaction.multimediaObject.multiMediaInstanceArray)) {
                const last = lastArrayItem<IMultimediaInstanceJSON>(interaction.multimediaObject.multiMediaInstanceArray);
                const type: MultiMediaType = MultiMediaType.getMutimediaTypeFromMime(last.mimeType);
                const nature: string = type.getMultimediaNature();
                result = MultimediaNatureEmojiMap[nature] || '';
            } else {
                result = Serializable.getJText(interaction, constant.serializableField.chat_text);
            }
            break;
    }
    return result;
}




// Sessão mantida por compatibilidade até pois a interação 
// featureCarrier pode gerar as mesmas demandas de visualizacao

// ATENÇÃO..Essa Funcao sabia basicamente que pai dela era uma feature
// Agora isso está basicamente ligado ao InteractionType-> featureCarrier
// Estou mantendo e vendo o impacto
export function isInteractionFeatureShared(interaction: IInteractionJSON): boolean {
    return false;
};


export function isRootInteractionFeatureShared(rootInteraction: IInteractionJSON): boolean {
    return !isValidRef((<IChainedInteractionJSON>rootInteraction).idInteractionParent)
        && isInteractionFeatureShared(rootInteraction);
};

export function hasFeatureRestrictions(idGroup: TGlobalUID, idInteractionType: TGlobalUID, rootInteraction: IInteractionJSON): boolean {
    if (isFeatureShared(idGroup, idInteractionType, rootInteraction)) {
        const pub: IPublishGroup = getPubForGroup(idGroup, rootInteraction.publishingGroups);
        // Aqui ele via as permissoes que provavelmente agora estarão no sharefeature do NonSerializable
        return false //isValidArray(pub.featureShared.goPermissions);
    };
    return false;
};

export function hasVisualizationRestrictions(idGroup: TGlobalUID, idInteractionType: TGlobalUID, rootInteraction: IInteractionJSON): boolean {
    if (isFeatureShared(idGroup, idInteractionType, rootInteraction)) {
        const pub: IPublishGroup = getPubForGroup(idGroup, rootInteraction.publishingGroups);
        // Aqui ele via as permissoes que provavelmente agora estarão no sharefeature do NonSerializable
        // Aqui é se o usuário pode ver
        return false; // isValidArray(pub.featureShared.usePermissions);
    };
    return false;
};


export function isFeatureResponse(interaction: IInteractionJSON): boolean {
    return interaction.idInteractionType !== constant.interactionType.standard.featureCarrier &&
        isValidArray(interaction.threadInfo) && interaction.threadInfo.some((t) => {return t.threadType == EThreadType.featureThread});
};


export function isRootFeatureOnInteraction(interaction: IInteractionJSON, rootInteraction: IInteractionJSON): boolean {
    return isFeatureShared(interaction.idGroup, interaction.idInteractionType, rootInteraction);
};

export function isFeatureShared(idGroup: TGlobalUID, idInteractionType: TGlobalUID,  rootInteraction: IInteractionJSON): boolean {
    return isFeatureSharedTypes(idInteractionType, rootInteraction.idInteractionType, idGroup, rootInteraction.publishingGroups);
};

export function isFeatureSharedTypes(idInteractionType: TGlobalUID, idRootInteractionType: TGlobalUID, idGroup: TGlobalUID, pubArray: TPublishGroupArray): boolean {
    let pub: IPublishGroup;
    const iMessageType: InteractionType = InteractionType.staticFactory(idInteractionType);
    if (isValidArray(pubArray) && isValidRef(pub = getPubForGroup(idGroup, pubArray))) {
        
        return false;
        /*return isValid(pub.featureShared) && idRootInteractionType === constant.interactionType.standard.featureCarrier
            && ! iMessageType.isDeleteInteraction() */
    };
    return false;
};

export function isGroupOnInteractionPublishingGroups(publishingGroups: TPublishGroupArray, idGroup: TGlobalUID): boolean {
    return publishingGroups.some(group => {
        return group.idGroup === idGroup || group.genealogy.some(g => g.idGroupParent === idGroup)
    });
}