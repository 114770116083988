import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TAvatarArray, Avatar } from '@colmeia/core/src/business/avatar';
import { Memoize } from 'typescript-memoize';
import { shuffleArray } from '@colmeia/core/src/tools/utility';
import {SignalListenerService} from "../../../services/signal/signal-listener";
import {EHexagonSizes, HandlerHexagonon, THandlerHexagononArray} from "../../../handlers/hexagono.handler";


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-group-members',
    templateUrl: './group-members.component.html',
    styleUrls: ['./group-members.component.scss']
})
export class GroupMembersComponent implements OnInit, OnDestroy {
    @Input() members: TAvatarArray = []
    @Input() membersSize: number
    @Input() maxToShow: number

    constructor(
        private listener: SignalListenerService,
    ) { }

    ngOnInit() { };

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    };

    public onMemberClick(): void {
        console.log('onMemberClick!');
    }

    @Memoize()
    getMembers(): THandlerHexagononArray {
        const bestAvatarShuffled: TAvatarArray = shuffleArray(this.members)
        const bestAvatarList: TAvatarArray = this.maxToShow
            ? bestAvatarShuffled.slice(0, this.maxToShow)
            : bestAvatarShuffled;
        return bestAvatarList
            .map((avatar: Avatar) => HandlerHexagonon.newHandler({
                serializable: avatar,
                size: EHexagonSizes.xs,
            }))
    }
}
