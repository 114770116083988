import {Injectable} from "@angular/core";
import {
    INSDependencyDescription,
    TDpendencyObjectType
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import {ColmeiaDialogService} from "./dialog/dialog.service";
import {DependenciesDialogComponent} from "../components/dashboard/dashboard-foundation/dependencies-dialog/dependencies-dialog.component";
import {DashboardModule} from "../modules/dashboard/dashboard.module";

@Injectable({
    providedIn: 'root'
})
export class NsDependenciesService {

    constructor(
        private dialog: ColmeiaDialogService,
    ) {}

    queryDependencies(idNS: string, nName: string, nserType: TDpendencyObjectType): void {
        this.dialog.open<DependenciesDialogComponent, INSDependencyDescription>({
            componentRef: DependenciesDialogComponent,
            dataToComponent: {
                data: {
                    name: nName,
                    id: idNS,
                    type: nserType
                },
                width: '700px'
            }
        });
    }

}
