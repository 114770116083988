import { constant } from "../../../business/constant";
import { suggestions } from "../../../tools/type-utils";
import { typedScreenOptions, IdField } from "../../../tools/utility";
import { getTranslationsFields, EAllSerializable } from "../all-serializables";

const workspaceReportsMenuTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.DashboardsConfig,
    fields: suggestions<{ [key in string]: IdField }>()({
        workspaceReports: constant.serializableField.name,
        workspaceReportsIcon: constant.serializableField.icon,
    })
} as const));

export const workspaceReportsTranslations = {
    ...workspaceReportsMenuTranslations,
}