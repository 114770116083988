import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {TToolsData} from "../../../model/tools.model";
import { SessionService } from "../../../services/session.service";
import { constant } from "@colmeia/core/src/business/constant";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-tools-list-visualization',
	templateUrl: './tools-list-visualization.component.html',
	styleUrls: ['./tools-list-visualization.component.scss']
})
export class ToolsListVisualizationComponent {

    private _sections: TToolsData;

    constructor(
        private session: SessionService
    ) {}

    @Input()
    set sections(value: TToolsData) {
        
        this._sections = value;
    }

    get sections(): TToolsData {
        return this._sections;
    }

    get canShowMenuButton(): boolean {
        return this.session.getSelectedGroup().getPrimaryID() !== constant.socialContext.colmeia;
    }
}
