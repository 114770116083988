import { Serializable } from "../business/serializable";
import {
    EInsideNSObject,
    ENonSerializableObjectType,
    TDpendencyObjectType
} from "./non-serializable-id/non-serializable-id-interfaces";
import {
    friendlyInsideNSNameFieldsMap,
    friendlyInsideNSNameIDSerializable,
} from "./non-serializable-id/non-serializable-enum-mapper";
import {isInsideElement} from "./non-serializable-id/non-serializable-functions";
import { ITranslationConfig, ITranslationHash } from "./translation/translation-engine";
import { Explicit } from "../tools/utility/types";
import { getNonSerializableFriendlyNameTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly";

export type TEnumPickerFieldMaps = {[enumID in string]: number};
export type TCustomEnumPickerFieldMaps<EnumValues> = Explicit<TEnumPickerFieldMaps, {
    [key in Extract<EnumValues, string>]: number;
}>;

export type TEnumPickerOptions=Array<IEnumPickerOption>;

export interface IEnumPickerOption {
    text: string;
    enumValue: string;
}

export function getEnumOptions(enumSt: any, fieldMaps: TEnumPickerFieldMaps, idSerializable: string, ignoreKeys: string[] = []): TEnumPickerOptions {
    const result: TEnumPickerOptions = [];
    const ser: Serializable = Serializable.staticFactory(idSerializable);
    for(const key of Object.keys(enumSt).filter(key => !ignoreKeys.includes(key) && isNaN(Number(key)))) {
        result.push({
            text: ser.getSerializableText(fieldMaps[enumSt[key]]),
            enumValue: enumSt[key]
        });
        
    }
    return result;
}


export function getEnumOptionsTranslationsAndEnumValues(enumSt: Record<string, string>, translations: ITranslationHash | Record<string, string>, ignoreValues: string[] = []): TEnumPickerOptions {
    const result: TEnumPickerOptions = [];
    const values: string[] = Object.values(enumSt).filter(value => !ignoreValues.includes(value) && typeof value !== 'number' )

    for (const value of values) {
        const text: ITranslationConfig | string = translations[value] || value;

        let option: IEnumPickerOption = {
            text: typeof text === 'string' ? text : Serializable.getTranslation(text),
            enumValue: value
        }
        result.push(option);
    }
    
    return result;
}

export type TEnumFields = {
    [enumID: string]: string
}

export function getEnumOption(option: string, fieldMaps:TEnumPickerFieldMaps, idSerializable: string): string {
    const ser: Serializable = Serializable.staticFactory(idSerializable);
    if (ser) {
        const field: number = fieldMaps[option];
        return ser.getSerializableText(field);
    }
    return null;
}


export function getEnumTitleHash(fieldMaps:TEnumPickerFieldMaps, idSerializable: string): TEnumFields {
    return {};
}

export function getReadableNonSerializableType(nsType: ENonSerializableObjectType): string {
    return Serializable.getTranslation(getNonSerializableFriendlyNameTranslations()[nsType]);
}

export function getReadableInsideNonSerializableType(type: EInsideNSObject): string {
    return getEnumOption(type, friendlyInsideNSNameFieldsMap,friendlyInsideNSNameIDSerializable);
}

export function getReadableNonSerializableDependencyType(type: TDpendencyObjectType): string {
    return isInsideElement(type) ? getReadableInsideNonSerializableType(type as EInsideNSObject) : getReadableNonSerializableType(type as ENonSerializableObjectType);
}




