import { Interaction } from '../interaction/interaction';
import { IPanable, Pane} from '../business/pane';
import { InteractionType } from '../interaction/interaction-type';
import { GroupSecurityDomain } from './group-security-domain';
import { Participant } from '../business/participant';
import {constant, TGlobalUID} from '../business/constant';
import {IGroupSecurityItemJSON} 
    from '../comm-interfaces/interaction-interfaces';


export type TGroupSecurityItemArray = Array<GroupSecurityItem>;

export class GroupSecurityItem extends Interaction {
    private groupSecurityDomain: GroupSecurityDomain;
    private idSecuritySettings: TGlobalUID;

    private constructor(primaryID: TGlobalUID, participant: Participant, groupSecurityDomain: GroupSecurityDomain) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.security.groupSecurityRule));
        this.groupSecurityDomain = groupSecurityDomain;
    };

    public static getNewGroupSecurityItem(participant: Participant, groupSecurityDomain: GroupSecurityDomain): GroupSecurityItem {
        return new GroupSecurityItem(null, participant, groupSecurityDomain);
    };

    public getGroupSecurityDomain(): GroupSecurityDomain {
        return this.groupSecurityDomain;
    };

    public toJSON(): IGroupSecurityItemJSON {
        let json: IGroupSecurityItemJSON = {...super.toJSON(),
            idSecurityDomain: this.groupSecurityDomain.getGroupSecurityDomainID(),
            idSecuritySettings: this.idSecuritySettings
        };
        return json;
    };

    public rehydrate(json: IGroupSecurityItemJSON): void {
        super.rehydrate(json); 
    };

    public static factoryMessage(json: IGroupSecurityItemJSON, participant: Participant = null): GroupSecurityItem {
        let groupSecurityItem: GroupSecurityItem = <GroupSecurityItem>Interaction.searchCachedInteraction(json.primaryID);
        if (groupSecurityItem == null)
            groupSecurityItem = new GroupSecurityItem(json.primaryID, 
                participant? participant: Participant.staticFactory(json.participant.primaryID), 
                GroupSecurityDomain.staticFactory(json.idSecurityDomain));
        groupSecurityItem.rehydrate(json);
        return groupSecurityItem;
    };




};