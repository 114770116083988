import { INonSerializableHeader, INonSerializableMedia, INonSerializable } from '../non-serializable-id/non-serializable-id-interfaces';
import { IHTMLConf } from './face-company-interfaces';
import { EServiceChatType } from '../../comm-interfaces/service-group-interfaces';

export type TFaceCompanyServiceArray = Array<IFaceCompanyService>;

export enum EFaceCompanyServiceType {
    goToGroup = 1, // @TODO ver com zé, tirar isso de numerico
}

export interface IFaceCompanyService {
    idService: string;
    type: EFaceCompanyServiceType;
    description: string;
    idEntity: string;
    serviceType: EServiceChatType;
    canAccess: boolean;
}

export interface INSFaceCompany extends INonSerializableHeader {
    conf: IHTMLConf;
    mobileConf: IHTMLConf;
    enabled: boolean;
    assets: INonSerializableMedia;
    services?:TFaceCompanyServiceArray;
}

export interface INSFaceCompanyServer extends INonSerializable, INSFaceCompany {}