<div class="custom-var-inserter">
  <div>
    <div class="search-selector-container">
      <div class="search-selector-wrapper">
        <mat-icon>search</mat-icon>
        <input
          #insertVarInput
          class="search-selector"
          (keyup.enter)="addTokenValueByInput(insertVarInput)"
          (input)="filterVariables(insertVarInput)"
          placeholder="Nome da variável"
        />
      </div>
      <button
        mat-flat-button
        color="primary"
        style="text-align: center"
        (click)="addTokenValueByInput(insertVarInput)"
        class="custom-var-create-button"
      >
        <mat-icon style="height: 20px; width: 20px; font-size: 20px"
          >add</mat-icon
        >
        Criar
      </button>
    </div>
    <div *ngIf="hint" class="hint">{{ hint }}</div>
  </div>

  <div class="line"></div>
  <mat-selection-list
    *ngIf="!filteredParamsVariables"
    #selectVar
    [multiple]="false"
    (selectionChange)="insertVariable(selectVar)"
    class="dense"
  >
    <mat-list-option
      *ngFor="let paramVar of _paramVariables"
      [value]="paramVar"
    >
      {{ paramVar.value }}
    </mat-list-option>
  </mat-selection-list>
  <mat-selection-list
    *ngIf="filteredParamsVariables"
    #selectVarFil
    [multiple]="false"
    (selectionChange)="insertVariable(paramValueInput, selectVarFil)"
    class="dense"
  >
    <mat-list-option
      *ngFor="let paramVar of filteredParamsVariables"
      [value]="paramVar"
    >
      {{ paramVar.value }}
    </mat-list-option>
  </mat-selection-list>
</div>
