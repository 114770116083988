import { Injectable } from '@angular/core';
import { GenericNonSerializableService } from './generic-ns.service';
import { ServerCommunicationService } from './server-communication.service';
import { RequestBuilderServices } from './request-builder.services';
import { SessionService } from './session.service';
import { RoutingService } from './routing.service';
import { TBasicUniveralInfoArray } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { nonSerializableToBasicInfo } from '@colmeia/core/src/rules/aux-function';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader, TNonSerializableArray } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { IListNonSerializablesFilterFields, IListNonSerializablesRequest } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { IListNonSerializablesResponse } from '@colmeia/core/src/dashboard-control/dashboard-response-interfaces';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ISaveGenericNSRequest } from '@colmeia/core/src/request-interfaces/lookup-ns-request';
import { IResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { GenericDashboardRequester } from 'app/services/generic-dashboard-requester.service';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { LookupService } from 'app/services/lookup.service';
import { ISaveGenericNSResponse } from '@colmeia/core/src/request-interfaces/lookup-request';


@Injectable({
	providedIn: 'root'
})
export class NonSerializableService extends GenericNonSerializableService {
	constructor(
		api: ServerCommunicationService,
		rbs: RequestBuilderServices,
		session: SessionService,
		routeSvc: RoutingService,
		private requester: GenericDashboardRequester,
		private lookupService: LookupService
	) {
		super(
			null,
			null,
			{ api, rbs, session, routeSvc },
		);
	}

	async getNonSerializablesBasicInfo(idParent: string, nsType: ENonSerializableObjectType): Promise<TBasicUniveralInfoArray> {
		const nonSerializables = await this.getChildren(idParent, nsType);
		return nonSerializables.map(ns => nonSerializableToBasicInfo(ns));
	}

	async getNonSerializables(idParent: string, nsType: ENonSerializableObjectType, search: ITagableSearch): Promise<TNonSerializableArray> {
		const nonSerializables = await this.getChildren(idParent, nsType, null, search);
		return nonSerializables;
	}

	async getList(req: IListNonSerializablesFilterFields): Promise<TNonSerializableArray<INonSerializable>> {
		const response = await this.api.sendRequest<IListNonSerializablesRequest, IListNonSerializablesResponse>(apiRequestType.nonSerializable.list)
			({
				...req,
				multipleCursor: req.multipleCursor
			});
		return response.nonSerializableArray
	}

	getByID(idNS: IdDep<ENonSerializableObjectType>): Promise<INonSerializable> {
		return this.lookupService.getNS(idNS)
	}

	saveClient(type: ENonSerializableObjectType, nser: INonSerializableHeader): Promise<ISaveGenericNSResponse | undefined> {
		return this.save({
			...nser,
			nsType: type,
			ident: undefined,
		});
	}

	save(nser: INonSerializable): Promise<ISaveGenericNSResponse | undefined> {
		const request: ISaveGenericNSRequest = {
			...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.genericNSSave),
			nserList: [nser]
		};

		return this.requester.request(
			request,
			true
		).then((response: ISaveGenericNSResponse | undefined) => {
			return response;
		});
	}
}
