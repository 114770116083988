import { Injectable } from '@angular/core';
import { EBotEventType } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { TOnConfirmationFields } from '@colmeia/core/src/shared-business-rules/bot/event-confirmation-db';
import { getDeepTypedProperty } from '@colmeia/core/src/tools/utility';
import { TConfirmationTextDb } from './model/confirmation-text-db.types';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationTextService {

  private confirmationTextDB: TConfirmationTextDb;

  constructor() {
    this.confirmationTextDB = {
      [EBotEventType.accuracyEvent]: {
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.onInsuficientAccuracy)]: "Acurácia Insuficiente",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.onInconclusiveAccuracy)]: "Acurácia Inconclusiva",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.onConclusiveAccuracy)]: "Acurácia Conclusiva",
      },

      [EBotEventType.insuficientAccuracy]: {
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: "Informa que não compreendeu e descreve as opções",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: "Informa que não compreendeu (voltará ao controle anterior)",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: "Opção para redigitar a informação",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: "Opção para retornar ao último controle",
      },
      [EBotEventType.inconclusiveAccuracy]: {
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: "Informa que não compreendeu e descreve as opções",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: "Texto do desambiguador",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: "Opção para encaminhar ao próximo controle",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: "Opção para redigitar a informação",
      },
      [EBotEventType.conclusiveAccuracy]: {
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: "Informa que não compreendeu e descreve as opções",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: "Informa que compreendeu (encaminha ao próximo controle)",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: "Opção para encaminhar ao próximo controle",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: "Opção para redigitar a informação",
      },

      [EBotEventType.reuseFormInformation]: {
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: "Informa que já tem um formulário preenchido e descreve as opções",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: "Opção para utilizar o formulário já preenchido",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: "Opção para preencher o formulário novamente",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: "Informa que vai utilizar um formulário já preenchido",
      },

      [EBotEventType.onConfirmation]: {
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: "Informa e descreve as opções",
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: '',//Serializable.getTranslation(translations.fragments.positiveText),
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: '',//Serializable.getTranslation(translations.fragments.negativeText),
        [getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: "Informa",
      },
    }
  }

  getTextByType(botType: EBotEventType) {
    return this.confirmationTextDB[botType]
  }
}
