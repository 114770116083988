import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ColmeiaDialogService } from "../../../../services/dialog/dialog.service";
import { RequestBuilderServices } from "../../../../services/request-builder.services";
import { ServerCommunicationService } from "../../../../services/server-communication.service";
import { UserSettingsService } from "../../../../services/user-settings.service";
import { SessionService } from "../../../../services/session.service";
import { SnackMessageService, ISnackSettingsMessage, SnackDefaultDuration } from 'app/services/snack-bar';
import { ESecurityApiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { IInfraParameters } from 'app/model/client-infra-comm';
import { IChangePasswordRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { encryptPlayerPassword } from '@colmeia/core/src/shared-business-rules/password/password-functions';
import { IResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';

@Component({
    selector: 'app-password-settings',
    templateUrl: './password-settings.component.html',
    styleUrls: ['./password-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordSettingsComponent implements OnInit{

    displayedColumns: string[] = ['id', 'date', 'operation', 'value'];
    dataSource = [];

    public balance: number = 0;

    private datePipe: DatePipe;

    constructor(
        private dialog: ColmeiaDialogService,
        private dialogRef: MatDialog,
        private rbs: RequestBuilderServices,
        private server: ServerCommunicationService,
        private settings: UserSettingsService,
        private session: SessionService,
        private cdr: ChangeDetectorRef,
        private snackBar: SnackMessageService,
    ) {}

    actualPassword: string = "";
    newPassword: string = "";
    newPasswordConfirm: string = "";



    ngOnInit() {

    }

    buildInfra(): IInfraParameters {
        return this.rbs.getNoCallBackSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );
    }

    async changePassword(){
        if(!(this.newPassword == this.newPasswordConfirm)){
            this.snackBar.openWarning("Senhas não conferem")
            return
        } else if(this.newPassword == ""){
            this.snackBar.openWarning("Senha não pode estar vazia")
            return
        }
        const infra = this.buildInfra();
        const request: IChangePasswordRequest = {
            ...this.rbs.createRequestFromInfraParameters(ESecurityApiRequestType.changePass, infra),
            oldPDWHash: encryptPlayerPassword(this.actualPassword),
            newPWDHash: encryptPlayerPassword(this.newPassword)
        }

        const response = await this.server.managedRequest(infra,request);

        if(response.friendlyMessage.isOk()){
            this.snackBar.open(<ISnackSettingsMessage>{
                message: "Senha alterada com sucesso",
                duration: SnackDefaultDuration.Long,
            })
            this.actualPassword = "";
            this.newPassword = "";
            this.newPasswordConfirm = "";
            this.dialogRef.closeAll();
        }

    }


}
