import { defaultFields } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";

export interface ICodeEditorDialogClientCallback {

}

export interface ICodeEditorDialogParameters extends IComponentParameter {
    readonly: boolean;
    content: {};
    language: string;
}

function initDefaultParameters() {
    const defaultParameters: Partial<ICodeEditorDialogParameters> = {

    };
    return defaultParameters;
}

export class CodeEditorDialogHandler extends MainHandler<ICodeEditorDialogParameters> {
    constructor(parameter: ICodeEditorDialogParameters) { defaultFields(parameter, initDefaultParameters()); super(parameter) }
}
