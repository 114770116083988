import { EMetadataNames, allMetadata } from "../../metadata/metadata-db";
import { IGeneralMetadata } from "../../metadata/metadata-interface";
import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../all-serializables";
import * as _ from 'lodash';


export function getCanonicalConfig(name: EMetadataNames) {
    return allMetadata[name];
}
export function getCanonicalExample(name: EMetadataNames) {
    return _.sample(getCanonicalConfig(name).examples ?? []) ?? '';
}


export function canonicalSanitizer(value: string, canonical: EMetadataNames): string {
    const rule = <IGeneralMetadata>allMetadata[canonical];
    if (rule.sanitizer) {
        return rule.sanitizer(value);
    }
    return value;
}

export function sanitizeEmail(email: string) {
    return email.toLowerCase().trim();
}

const canonical = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.Canonical,
    fields: {
        createTitle: 1,// Canonical 22
        editTitle: 2,// Canonical 23
        globalCanonical: 3, // Canonical 20
        titleList: 4, // Canonical 21
        errorLoadingCanonicals: 5, // Canonical 26
        noCanonicals: 6, // Canonical 27
    },
});

export const canonicalScreenOptions = canonical.screenOptions;
export const canonicalTranslations = canonical.translations;

