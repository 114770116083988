<div
  #container
  class="container has-slider hidden"
  [ngClass]="{'hidden': !sliderVisible, 'hideBars': !barsVisible}"
>
  <div class="topBar">
    <span class="sliderTitle">{{ title }}</span>
  </div>

  <div class="slider" #slider>
      <div *ngFor="let imageUrl of imageUrls" class="slider-panel">
        <app-image-viewer [imageUrl]=imageUrl (onTouch)="checkBars($event)" (onPan)="onPan($event)"></app-image-viewer>
      </div>
  </div>

  <div class="bottomBar">
    <span class="counter">{{ activeSlide + 1}} {{translations.of}} {{ slideCount }}</span>
  </div>
</div>
