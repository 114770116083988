import { Interaction } from "../interaction";


import {Participant} from '../../business/participant';
import {constant, TGlobalUID} from '../../business/constant';
import { InteractionType } from "../interaction-type";
import { IMenuInteractionJSON, TMenuInteractionUniqueProps } from "./menu-interaction-interfaces";
import { UberCache } from "../../persistency/uber-cache";
import { EMenuMode } from "../../shared-business-rules/bot/bot-model";
import { isValidString, updateFieldSubset } from "../../tools/utility";
import { TBotMenuOptions } from "../../shared-business-rules/bot/bot-interfaces";

export class MenuInteraction extends Interaction {
    private confirmMessage: string;
    private menuStyle: EMenuMode;
    private menuOptions: TBotMenuOptions;

    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.menu.menuHeader));
    };

    public rehydrate(json: IMenuInteractionJSON): void {
        super.rehydrate(json);
        const insert: TMenuInteractionUniqueProps = {
            confirmMessage: json.confirmMessage,
            menuStyle: json.menuStyle,
            menuOptions: json.menuOptions
        }
        updateFieldSubset(this, insert as Partial<this>);
    };


    public getMenuOptions(): TBotMenuOptions {return this.menuOptions}

    public getMenuStyle(): EMenuMode {return this.menuStyle};
    public hasConfirmMessage(): boolean {return isValidString(this.confirmMessage)}
    public getConfirmMessage(): string {return this.confirmMessage}

    public toJSON(): IMenuInteractionJSON {
        const json: IMenuInteractionJSON = {
            confirmMessage: this.confirmMessage,
            menuStyle: this.menuStyle,
            ...super.toJSON(),
            idInteractionType: constant.interactionType.menu.menuHeader,
            menuOptions: this.menuOptions
        }

        return json;
    }

    public static factoryMessage(json: IMenuInteractionJSON, participant: Participant = null): MenuInteraction {
        let definition: MenuInteraction = <MenuInteraction>Interaction.searchCachedInteraction(json.primaryID);
        if (definition == null)
           definition = <MenuInteraction> new MenuInteraction(json.primaryID, 
                            participant? participant: Participant.staticFactory(json.participant.primaryID));
        definition.rehydrate(json);
        return definition;
    };

    public static staticFactory(idThread: TGlobalUID): MenuInteraction {
        return <MenuInteraction>UberCache.uberFactory(idThread, constant.objectType.interaction, true);
    };

    public static factory(participant: Participant): MenuInteraction {
        const interaction: MenuInteraction = new MenuInteraction(null, participant);
        return interaction;
    }

};


