import { DeepFindValuesOfType, DeepInfer, IsEmpty, Primitive } from "./utility-types";
import { HasAdditionalKeysToUnknown } from "./utility/types";
import { $$ } from "./utility/types/error";
import { $Extends } from "./utility/types/validate";


export function valueIn<T>(source?: T) {
    return <D extends { [key in string]: DeepFindValuesOfType<T, string> }>(define: D) => define;
}
export function genericTuple<E>() {
    return function tuple<T extends E[]>(...args: T) {
        return args;
    }
}


export const _removeClassProperty = genericTuple<string>()('prototype', 'length');
export function getAllClassStaticMethods<T extends object>(source: T): Exclude<keyof T, typeof _removeClassProperty[number]>[] {
    return (Object.getOwnPropertyNames(source).filter(key => !(_removeClassProperty as string[]).includes(key)) as Exclude<keyof T, typeof _removeClassProperty[number]>[]);
}



export function genericTypedSuggestions<E>() {
    return function typedSuggestions<T extends E>(source: T): T {
        return source;
    }
}
    
    // Does not work for deep
export function deprecatedSuggestions<E extends object>() {
    return function typedSuggestions<T extends E>(source: T) {
        return { ok: source } as any as HasAdditionalKeysToUnknown<E, T> extends never ? { ok: typeof source } : unknown;
    }
}
    
    
export function typedValue<I>() {
    return (value: I) => value;
}

    /**
 * Give multiple patterns and it returns a single
 * Flags are not supported
 */
export function mergePatterns(patterns: RegExp[]): RegExp {
    return (new RegExp(patterns.map((pattern: RegExp) => pattern.source).join('|')));
}


export function suggestions<E extends object>() {
    return <T extends E>(source: T & (HasAdditionalKeysToUnknown<E, T> extends never ? unknown : never)) => source;
}

export function asConst<E extends object>() {
    return <T extends E>(source: T) => source
}

export function define<E extends object>() {
    return <T extends $Extends<T, E>>(source: T & E): T => source
}

// export type DeepCompute<T> = { [key in keyof T]: T[key] extends object ? DeepCompute<ConvertArrayToFakeTuple<T[key]>> : T[key]  } & {};

// export type $Save<T> =
//     { [key in keyof T]: never extends EAssert<infer Item, T[key]> ? & { v?: Item } & T[key] & $Save<T[key]> : never }
// ;

export function strictSuggestions<Extends extends object>() {
    return <Fields extends $Extends<Fields, Extends>>(
        fields: (
            & Fields
            // & UnionToIntersection<
            //     & $$.ExtendsObject<Fields, Extends>
            //     & (
            //         Fields extends object ? 
            //             & $$.NonNullableDeep<Fields>
            //             & $$.DeepLiteral<Fields>
            //         : unknown
            //     )
            // >
            // & (UnionToIntersection<Fields> extends Extends ? Extends : unknown)
        )
    ): Fields => fields;
}


export function deepLiteral<Type extends Primitive>() {
    return <T extends { [key in string]: Values }, Values extends Type | { [key in string]: Values }>(source: T & $$.DeepLiteral<T> & $$.NonNullableDeep<T>): T => source;
}

export function deepNonRepeatedLiteral<Type extends Primitive>() {
    return <T extends { [key in string]: Values }, Values extends Type | { [key in string]: Values }>(source: T & $$.DeepLiteral<T> & $$.NonNullableDeep<T> & $$.DeepNonRepeatedValues<T>): T => source;
}

export function noAny<Source>(source: Source & (IsEmpty<Source> extends true ? never : unknown)): Source {
    return source;
}

export function nonNullable<Source>(source: Source & (IsEmpty<Source> extends true ? never : unknown)): Source {
    return source;
}
// type Remount<T extends E, E> = 
//     // @ts-ignore
//     { [key in keyof T]: T[key] extends object ? Remount<T[key], E[key]> : IsEmpty<E[key]> extends false ? T[key] extends E[key] ? unknown : $$<["Value should be", E[key]]> : $$<"Remove this property"> }
// ;

// export function deepSuggestions<E extends object>() {
//     // @ts-ignore
//     return <T extends object, Values extends Primitive>(source: DeepInfer<T, Values> & UnionToIntersection<Remount<T, E> & $$.DeepLiteral<T> & $$.NonNullableDeep<T>> ): T => source;
// }

// /**
//    * Deep infer values
//    * @example deepInfer({ a: 1, b: '2', c: { d: 3 } }) -> { a: 1, b: '2', c: { d: 3 } }
//    * @example deepInfer([{ a: 1, b: '2', c: { d: 3 } }, { f: 1 }]) -> [{ a: 1, b: '2', c: { d: 3 } }, { f: 1 }]
//    */
export function deepInfer<T, V extends Primitive>(source: DeepInfer<T, V>): T {
    return source;
}
export function infer<V extends Primitive>() {
    return <T extends V>(source: T) => source;
}

export function initGetFnName<T extends { name: string }>(typeOfClass: () => T) {
    return (name: string): string => `${typeOfClass.name}::${name}`;
}

export interface IResult {
    isSuccess: boolean;
}

/* class decorator */
export function staticImplements<T>() {
    return <U extends T>(constructor: U) => constructor;
}