import { Component, OnInit } from '@angular/core';
import {Language, TLanguageArray} from "@colmeia/core/src/rosetta/language";
import {constRosetta} from "@colmeia/core/src/rosetta/const-rosetta";
import {UserSettingsService} from "../../../../services/user-settings.service";

@Component({
    selector: 'app-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {

    public flags = {
        [constRosetta.language.portuguese]: './assets/icons/flags/br.svg',
        [constRosetta.language.spanish]: './assets/icons/flags/es.svg',
        [constRosetta.language.greek]: './assets/icons/flags/gr.svg',
        [constRosetta.language.chinese]: './assets/icons/flags/cn.svg',
        [constRosetta.language.english]: './assets/icons/flags/us.svg',
        [constRosetta.language.german]: './assets/icons/flags/de.svg',
    };

    private _lang: string;

    constructor(
        private settings: UserSettingsService
    ) { }

    ngOnInit() {
        this._lang = this.settings.getLanguage();
    }

    get lang() {
        return this._lang;
    }

    set lang(lang: string) {
        this._lang = lang;
    }

    public getAllLanguage(): TLanguageArray {
        return Language.getLanguageArray();
    }

    setCurrentLang(lang: string) {
        this.settings.setLanguage(lang);
        this.lang = lang;
    }
}
