export function copyToClickBoardSync(text: string): void {
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);
    input.focus();
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
}
export function copyToClickBoard(text: string): Promise<void> {
    return window.navigator.clipboard.writeText(text);
}
