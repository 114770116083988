import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, CanActivateFn, CanLoadFn, CanMatchFn, Route, RouterStateSnapshot, UrlSegment } from "@angular/router"
import { AuthGuard } from "./auth-guard.service"


export const authGuardCanActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const AuthGuardSvc = inject(AuthGuard);
    return AuthGuardSvc.canActivate(route, state);
}

export const authGuardCanMatch: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
    const AuthGuardSvc = inject(AuthGuard);
    return AuthGuardSvc.canLoad(route, segments);
}
