import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EInputType, TInputType } from 'app/components/foundation/inputs/cm-input/cm-input.model';
import { EFieldIdentifier, IColmeiaInputClient } from "../../../authentication/landing-page/landing-page.model";

@Component({
    selector: 'app-input-auth',
    templateUrl: './input-auth.component.html',
    styleUrls: ['./input-auth.component.scss'],
})
export class InputAuthComponent implements OnInit {
    @Input() id: EFieldIdentifier;
    @Input() type: TInputType;
    @Input() autocomplete: string;
    @Input() placeholderName: string;
    @Input() validatorClientInstance: IColmeiaInputClient;
    @Input() invalidMessage: string;
    @Input() hintLabel: string;
    @Output() onEnterPressed: EventEmitter<void> = new EventEmitter<void>();
    @Output() onValueChanged: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('inputText', {static: true}) inputTextElement: ElementRef<HTMLInputElement>;

    public _isValid: boolean = false;

    @Input()
    set currentValue(val: string) {
        this.typedText = val;
        this.runValidator();
    }

    get currentValue(): string {
        return this.typedText;
    }

    typedText: string = '';

    constructor() {}

    ngOnInit() {}

    getType(): TInputType {
        return this.type
            ? this.type
            : EInputType.Text;
    }

    isValidatorType(): boolean {
        return this.validatorClientInstance != null
    }

    runValidator() {
        this._isValid = this.validatorClientInstance.isInputFieldValidCallback(this.id, this.typedText)
    }


    async onEnterKeyPressed(event: KeyboardEvent) {
        event.preventDefault();
        this.onEnterPressed.emit()
    }

    saveTypedText(newValue: string): void {
        this.typedText = newValue;
        this.runValidator();
        this.onValueChanged.emit(newValue);
    }

    get icon(): string {
        return this._isValid ? 'check_circle' : 'warning'
    }

    onIconClick(): void {
        if(!this._isValid){
            this.typedText = '';
            this.inputTextElement.nativeElement.focus();
        }
    }

    canShowIcon(): boolean {
        return this.typedText.length && this.isValidatorType();
    }

    isValid() {
        return this._isValid;
    }

    get isInvalid(): boolean {
        return this.typedText.length && !this._isValid;
    }
}
