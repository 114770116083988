

import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { Meta } from "../../catalog/meta-interface";
import { notImplementedSharedFunction as notImplementedSharedMethod } from "../shared-services.basic-functions";

export interface IDeleteCatalogEntity {
    idConnection: string
    id: string
}

export interface IGetEntireProduct {
    idConnection: string,
    productId: string,
}

class Service {
    public deleteEntity(input: IDeleteCatalogEntity): Promise<boolean> {
        notImplementedSharedMethod(input);
    }

    public getEntireProduct(input: IGetEntireProduct): Promise<Meta.Catalog.Product> {
        notImplementedSharedMethod(input);
    }
}

@ToStatic
export class CatalogSharedService extends toStatic(Service) {}