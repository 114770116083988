import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TGlobalUID} from '@colmeia/core/src/business/constant';
import {Group} from '@colmeia/core/src/business/group';
import {
    ICurrentSharedState,
    TExtendedIdentifierArray,
    TExtendedParticipantArray,
    TInteractionSharedTo
} from '@colmeia/core/src/core-constants/types';
import {NShare} from './share.handler';
import {ShareService} from './share.service';
import {IShareListener} from 'app/model/signal/ps-interfaces';
import {DatePickerHandler} from 'app/handlers/date-picker.handler';
import {SignalListenerService} from 'app/services/signal/signal-listener';
import {NShareSignal} from 'app/model/signal/share-signal';
import {clientConstants} from 'app/model/constants/client.constants';
import {ECmBtnColorType} from '../foundation/cm-button/cm-button.component';
import {
    EParticipantClickTypeMode,
    EParticipantSelectorAction,
    IParticipantSelectorClient,
    IParticipantSelectorParameter,
    ParticipantSelectorHandler
} from "../../handlers/participant-selector.handler";
import {RootComponent} from '../foundation/root/root.component';
import {shareViewScreenOptions} from "@colmeia/core/src/shared-business-rules/const-text/views/share";
import {ESearchAllowedType} from "@colmeia/core/src/shared-business-rules/search";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

interface IInteractionIdToGroupList {
    [interactionId: string]: Array<TGlobalUID>
}

// export interface IShareAsFeatureParent {
//     setChildren(shareAsFeature: ShareAsFeatureComponent);
// }

// export interface IShareAsFeatureHandler {
//     parent: IShareAsFeatureParent;
// }

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComponent extends RootComponent<'share' | 'whereIsGoingToBeShared' | 'basicShare' | 'advancedShare' | 'addedItens' | 'clearItens'> implements OnInit, OnDestroy, IParticipantSelectorClient, IShareListener{ //IShareAsFeatureParent

    // shareAsFeatureData: IShareAsFeature;
    // shareAsFeatureChildren: ShareAsFeatureComponent
    isShareAsFeatureEnabled: boolean = false;
    private _shareHandler: NShare.ShareHandler;
    private interactionsSharedOnGroupMap: IInteractionIdToGroupList = { };
    private participantHandler: ParticipantSelectorHandler;
    public datePickerHandler: DatePickerHandler;

    constructor(
        private shareSvc: ShareService,
        private cdRef: ChangeDetectorRef,
        private signalListener: SignalListenerService,
    ) {
        super({
            share: gTranslations.share.share,
            whereIsGoingToBeShared: gTranslations.share.whereIsGoingToBeShared,
            basicShare:gTranslations.share.basicShare,
            advancedShare: gTranslations.share.advancedShare,
            addedItens: gTranslations.share.addedItens,
            clearItens: gTranslations.share.clearItens,
        });
    }

    ngOnInit() {
        this.participantHandler = this.newParticipantHandler();
        this.signalListener.listenToShareSignal(this);
    }

    // setChildren(shareAsFeature: ShareAsFeatureComponent): void {
    //     this.shareAsFeatureChildren = shareAsFeature;
    // }

    // public asFeatureHandler: IShareAsFeatureHandler = {
    //     parent: this
    // };

    ngOnDestroy(): void {
        this.signalListener.destroySubscriptions(this);
    }

    @Input()
    set shareHandler(handler: NShare.ShareHandler) {
        this._shareHandler = handler;
    }

    get shareHandler(): NShare.ShareHandler {
        return this._shareHandler;
    }

    receiveSharedCallback(sign: NShareSignal.ShareSignal): void {
        this._shareHandler.addSharingItem(sign.getObservableInformation());
        this.cdRef.markForCheck();
    }

    async onParticipantsSelected(
        selected: TExtendedParticipantArray,
        excluded: TExtendedParticipantArray
    ): Promise<void> {
        this.shareHandler.setParticipants(selected);
        if (selected.length)
            await this.checkIfAllItemsWhereShared(this.getSharedItems(), selected);
        else
            this.interactionsSharedOnGroupMap = {}
        this.cdRef.markForCheck();
    }

    getSharedItems(): Array<NShareSignal.IShareMessage> {
        return this.shareHandler.getSharedItems()
    }

    closeWindow(): void {
        this._shareHandler.getClientInstance().closeShareWindowCallback();
    }

    fetchAlreadySharedGroupNames(group: ICurrentSharedState): string {
        return Group.staticFactory(group.to).getName()
    }

    isInteractionAlreadyShared(id: TGlobalUID): Array<string> {
        return this.interactionsSharedOnGroupMap[id]
            ? this.interactionsSharedOnGroupMap[id]
            : [];
    }

    async checkIfAllItemsWhereShared(
        sharedItems: Array<NShareSignal.IShareMessage>,
        participants: TExtendedParticipantArray
    ): Promise<void> {
        const selectedParticipants: TExtendedIdentifierArray = participants.map(part => ({
                primaryID: part.getPrimaryID(),
                idObjectType: part.getObjectTypeID(),
            })
        );
        const selectedItems = sharedItems.map(item => item.resource.getPrimaryID());
        const responseIsShared = (await this.shareSvc.isShared(selectedItems, selectedParticipants))
            .match<TInteractionSharedTo>({
                some: value => value,
                none: () => <TInteractionSharedTo>{}
            });

        this.interactionsSharedOnGroupMap = {};
        for (const key in responseIsShared) {
            this.interactionsSharedOnGroupMap[key] = responseIsShared[key].map(this.fetchAlreadySharedGroupNames)
        }
    }

    async shareItems(): Promise<void> {
        const isSharedSuccess = await this.shareSvc.sendShare(
            this._shareHandler.getSharedItems(),
            this.shareHandler.getParticipants(),
        );
        if(isSharedSuccess) {
            this.shareHandler.shareSucceded();
            this.participantHandler = this.newParticipantHandler();

            // if(this.isShareAsFeatureEnabled)
            //     this.onShareAsFeatureSucceded()
        }

    }

    removeItem(item: NShareSignal.IShareMessage): void {
        return this.shareHandler.removeSharingItem(item);
    }

    removeAllItems(): void {
        this.shareHandler.removeAllSharingItems();
    }

    itemsCount(): number {
        return this._shareHandler.getSharedItems().length;
    }

    // onShareAsFeatureChanged(shareAsFeatureOutput: IShareAsFeatureOutput): void {
    //     console.log(shareAsFeatureOutput.shareAsFeature);
    //     this.isShareAsFeatureEnabled = shareAsFeatureOutput.isShareAsFeatureEnabled
    // }

    // onShareAsFeatureSucceded(){
    //     this.isShareAsFeatureEnabled = false;
    //     this.shareAsFeatureChildren.shareAsFeatureClear()
    // }

//#region Handlers
    newParticipantHandler(): ParticipantSelectorHandler {
        const parameter: IParticipantSelectorParameter = {
            alreadySelectedList: this.shareHandler.getParticipants(),
            typesOfActionsOnEachElementSelected: [
                EParticipantSelectorAction.Remove
            ],
            typesAllowed: [
                ESearchAllowedType.groups,
                ESearchAllowedType.people,
            ],
            clientCallback: this,
            isEditableTypesSelected: true,
            operationMode: EParticipantClickTypeMode.SendToParent,
            maxNumberOfParticipants: clientConstants.maxParticipantsPerGroup
        };
        return new ParticipantSelectorHandler(parameter);
    }

    getParticipantSelectorHandler(): ParticipantSelectorHandler {
        return this.participantHandler;
    }
//#endregion
    getClearBtnColor(): ECmBtnColorType{
        return ECmBtnColorType.White
    }

    getShareBtnColor(): ECmBtnColorType{
        return ECmBtnColorType.LightBlue
    }
}


