import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { GenericDashboardPaginationHandler } from "./generic-dashboard-pagination.handler";
import { GenericDashboardPaginationParameter } from "./generic-dashboard-pagination.parameter";
import { MatSelectChange } from "@angular/material/select";
import { RootComponent } from 'app/components/foundation/root/root.component';
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TSortBy } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { EListNonSerializablesSortOrder } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';

@Component({
    selector: 'cm-generic-dashboard-pagination',
    templateUrl: './generic-dashboard-pagination.component.html',
    styleUrls: ['./generic-dashboard-pagination.component.scss']
})
export class GenericDashboardPaginationComponent extends RootComponent<'perPage'> implements OnInit {

    private _handler: GenericDashboardPaginationHandler;
    private isRequestingMoreItems: boolean = false;
    private selectedSorting: EListNonSerializablesSortOrder = EListNonSerializablesSortOrder.Descending;

    @Input()
    set handler(val: GenericDashboardPaginationHandler) {
        this._handler = val;
        this.updateValues();
    }

    get handler(): GenericDashboardPaginationHandler {
        return this._handler;
    }

    @Input()
    dense: boolean = false;

    @Input()
    densex2: boolean = false;

    @Input()
    isSelectedAllActivated: boolean = false;

    options: number[] = [];
    perPage: number;

    isMobile: boolean = false;

    constructor(
        public _intl: MatPaginatorIntl,
        // private cdr: ChangeDetectorRef
    ) {
        super({
            perPage: gTranslations.fragments.perPage,
        });
        // this.isMobile = !!(window as any).ReactNativeWebView;
        this.isMobile = window.innerWidth <= 600;
    }

    ngOnInit() {
    }

    private updateValues(): void {
        const param: GenericDashboardPaginationParameter = this.handler.getComponentParameter();
        this.perPage = param.perPage.current;
        this.options = param.perPage.options;
    }

    get parameter(): GenericDashboardPaginationParameter {
        return this.handler.getComponentParameter();
    }

    onSelectionChange(evt: MatButtonToggleChange): void {
        this.parameter.perPage.current = evt.value;
        this.perPage = evt.value;
        this.parameter.clientCallback.onAmountPerPageChange(this.parameter.perPage.current);
        this.setIndex(0);
        this.emitCurrentIndex();
    }
    onSelectChange(evt: number): void {
        this.parameter.perPage.current = evt;
        this.perPage = evt;
        this.parameter.clientCallback.onAmountPerPageChange(this.parameter.perPage.current);
        this.setIndex(0);
        this.emitCurrentIndex();
    }

    private setIndex(index: number): void {
        this.parameter.index = index;
    }

    goFirstPage(): void {
        this.setIndex(0);
        this.emitCurrentIndex();
    }

    goPreviousPage(): void {
        this.setIndex(this.parameter.index - 1);
        this.emitCurrentIndex();
    }

    async goNextPage(): Promise<void> {
        if (this.handler.isAtLastPage()) {
            const isPageFullyFilled: boolean = (this.parameter.totalItems % this.parameter.perPage.current) === 0;
            this.isRequestingMoreItems = true;
            await this.parameter.clientCallback.loadMoreItems();
            this.isRequestingMoreItems = false;
            if (isPageFullyFilled) {
                this.setIndex(this.parameter.index + 1);
            }
        } else {
            this.setIndex(this.parameter.index + 1);
        }
        this.emitCurrentIndex();

        // this.cdr.markForCheck();
    }

    goLastPage(): void {
        this.setIndex(this.handler.getLastPageIndex());
        this.emitCurrentIndex();
    }

    emitCurrentIndex(): void {
        this.parameter.clientCallback.onPageIndexChange(this.parameter.index);
    }

    noop() {

    }

    get firstPageDisabled(): boolean {
        return this.isRequestingMoreItems || this.parameter.index === 0;
    }

    get previousPageDisabled(): boolean {
        return this.isRequestingMoreItems || this.parameter.index === 0;
    }

    get nextPageDisabled(): boolean {
        return this.isRequestingMoreItems || (this.parameter.hasMoreEntitiesToLoad) ? false : this.handler.isAtLastPage();
    }

    get lastPageDisabled(): boolean {
        return this.isRequestingMoreItems || this.handler.isAtLastPage();
    }

    get showCounter(): boolean {
        return this.parameter.showCounter
    }

    get pageIndex(): number {
        return this.parameter.index;
    }

    get totalItems(): number {
        return this.parameter.totalItems;
    }

    hasSorting() {
        return this._handler.hasSorting()
    }
    getSortingOptions(): string[] {
        return this.parameter.sort!.options;
    }

    asc = EListNonSerializablesSortOrder.Ascending;
    desc = EListNonSerializablesSortOrder.Descending;

    handleSorting(value: EListNonSerializablesSortOrder) {
        if (this.selectedSorting === value) {
            return;
        }
        this.selectedSorting = value;
        this.parameter.sort!.onSortingSelected(value);
    }

    isSelected(val: EListNonSerializablesSortOrder) {
        return val === this.selectedSorting;
    }
}
