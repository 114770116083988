<ng-container *ngIf="!(!text && icon); else onlyIcon">
  <button mat-raised-button
          class="button-with-icon"
          [ngStyle]="style"
          [ngClass]="color"
          [disabled]="disabled"
          (click)="disabled ? noop() : _onClick($event)"
  >
    <mat-icon [ngClass]="{
      'rotating': rotate,
      'blinking': blink
    }"
      *ngIf="icon"
    >
      {{ icon }}
    </mat-icon>
    <span class="text">
      {{ text }}
    </span>
  
    <ng-content></ng-content>
  
  </button>
</ng-container>

<ng-template #onlyIcon>
  <button mat-icon-button
          [ngStyle]="style"
          [ngClass]="color"
          [disabled]="disabled"
          (click)="disabled ? noop() : _onClick($event)"
  >
    <mat-icon [ngClass]="{
      'rotating': rotate,
      'blinking': blink
    }"
      *ngIf="icon"
    >
      {{ icon }}
    </mat-icon>

    <ng-content></ng-content>

  </button>  
</ng-template>