import { MatDialogRef } from "@angular/material/dialog";
import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { ESubscribedTo } from "@colmeia/core/src/shared-business-rules/BPM/common/common-bpm";
import { IBPMMarketingAcknowledgeServer, IBPMMarketingActionServer, IBPMMarketingEndServer, IBPMMarketingListenerServer, IBPMMarketingPauseServer, IBPMMarketingRootServer } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { ICRMAgentEventServer, ICRMNotificationServer, ICRMProcessingNodeEventServer, ICRMRootServer } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model";
import { TRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types";
import { IBPMDialogResult, ICRMProcessingNodeBPMDialogResult } from "app/components/dashboard/ai-pages/bpm-graph/diagram-editor/diagram-editor.types";
import { MainHandler } from "app/handlers/main-handler";
import { INonSerializable } from '../../../../core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IComponentParameter } from "./component-comm/basic";

export interface IBpmGraphCreateParameters extends IComponentParameter {
    bpmType: EBPMType,
    renderData: TRenderData;
    hasSaved?: boolean;
    hasImport?: boolean;
    nser?: INonSerializable
}
export class BpmGraphCreateHandler<P extends IBpmGraphCreateParameters = IBpmGraphCreateParameters> extends MainHandler {
    private dialogRef: MatDialogRef<any, IBPMDialogResult>;
    public constructor(parameters: P) { super(parameters) }
    public static factory(parameters: IBpmGraphCreateParameters): BpmGraphCreateHandler { return new BpmGraphCreateHandler(parameters) }
    public getComponentParameter(): P { return super.getComponentParameter() as P }
    public setDialogRef(value: MatDialogRef<any, IBPMDialogResult>): void { this.dialogRef = value; }
    public getDialogRef(): MatDialogRef<any, IBPMDialogResult> { return this.dialogRef; }
    public closeDialogAndSendResultToParentComponent(result: IBPMDialogResult): void {
        this.getDialogRef().close(result)
    }
}

export interface IBpmGraphCreateWithParentLinkParameters extends IBpmGraphCreateParameters{
    idParent: string
}

export class BPMGraphCreateWithParentLinkHandler extends BpmGraphCreateHandler {
    public constructor(parameters: IBpmGraphCreateWithParentLinkParameters) { super(parameters) }

    getIdParent(): string {
        return (this.getComponentParameter() as IBpmGraphCreateWithParentLinkParameters).idParent;
    }
}

/**
 * CRM itens
 */

export interface ICRMBpmGraphChildCreateParameters {
    idParent: string
}
export interface ICRMRootBpmGraphCreateParameters extends IBpmGraphCreateParameters {
    crmRoot?: ICRMRootServer
}

export class CRMRootBpmGraphCreateHandler extends BpmGraphCreateHandler {
    private constructor(parameter: ICRMRootBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: ICRMRootBpmGraphCreateParameters): CRMRootBpmGraphCreateHandler { return new CRMRootBpmGraphCreateHandler(parameters) }

    private getParams(): ICRMRootBpmGraphCreateParameters {
        return (<ICRMRootBpmGraphCreateParameters>super.getComponentParameter())
    }

    getCrmRoot(): ICRMRootServer {
        return this.getParams().crmRoot
    }
}

export interface ICRMUserEventBpmGraphCreateParameters extends IBpmGraphCreateParameters, ICRMBpmGraphChildCreateParameters {
    crmUserEvent?: ICRMAgentEventServer
}
export class CRMUserEventBpmGraphCreateHandler extends BpmGraphCreateHandler {
    private constructor(parameter: ICRMUserEventBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: ICRMUserEventBpmGraphCreateParameters): CRMUserEventBpmGraphCreateHandler { return new CRMUserEventBpmGraphCreateHandler(parameters) }

    private getParams(): ICRMUserEventBpmGraphCreateParameters {
        return (<ICRMUserEventBpmGraphCreateParameters>super.getComponentParameter())
    }

    getUserEvent(): ICRMAgentEventServer {
        return this.getParams().crmUserEvent
    }

    getRootCrmHostedId(): string {
        return this.getParams().idParent
    }
}

export interface ICRMProcessNodeBpmGraphCreateParameters extends IBpmGraphCreateParameters, ICRMBpmGraphChildCreateParameters {
    crmProcessNode?: ICRMProcessingNodeEventServer
}
export class CRMProcessingNodeBpmGraphCreateHandler extends BpmGraphCreateHandler {
    private constructor(parameter: ICRMProcessNodeBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: ICRMProcessNodeBpmGraphCreateParameters): CRMProcessingNodeBpmGraphCreateHandler {
        return new CRMProcessingNodeBpmGraphCreateHandler(parameters)
    }

    private getParams(): ICRMProcessNodeBpmGraphCreateParameters {
        return (<ICRMProcessNodeBpmGraphCreateParameters>super.getComponentParameter())
    }

    getProcessNode(): ICRMProcessingNodeEventServer {
        return this.getParams().crmProcessNode
    }

    getRootCrmHostedId(): string {
        return this.getParams().idParent
    }

    closeDialogAndSendResultToParentComponent(result: ICRMProcessingNodeBPMDialogResult): void {
        this.getDialogRef().close(result)
    }
}

export interface ICRMNotificationBpmGraphCreateParameters extends IBpmGraphCreateParameters, ICRMBpmGraphChildCreateParameters {
    crmNotification?: ICRMNotificationServer
}

export class CRMNotificationBpmGraphCreateHandler extends BpmGraphCreateHandler {
    private constructor(parameter: ICRMNotificationBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: ICRMNotificationBpmGraphCreateParameters): CRMNotificationBpmGraphCreateHandler {
        return new CRMNotificationBpmGraphCreateHandler(parameters)
    }

    private getParams(): ICRMNotificationBpmGraphCreateParameters {
        return (<ICRMNotificationBpmGraphCreateParameters>super.getComponentParameter())
    }

    getCrmNotification(): ICRMNotificationServer {
        return this.getParams().crmNotification
    }

    getRootCrmHostedId(): string {
        return this.getParams().idParent
    }
}

/**
 * Marketing itens
 */


export interface MKTRootBpmGraphCreateParameters extends IBpmGraphCreateParameters {
    mktRoot?: IBPMMarketingRootServer
}

export class MKTRootBpmGraphCreateHandler extends BpmGraphCreateHandler {
    private constructor(parameter: MKTRootBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: MKTRootBpmGraphCreateParameters): MKTRootBpmGraphCreateHandler {
        return new MKTRootBpmGraphCreateHandler(parameters)
    }

    private getParams(): MKTRootBpmGraphCreateParameters {
        return (<MKTRootBpmGraphCreateParameters>super.getComponentParameter())
    }

    getMarketingRoot(): IBPMMarketingRootServer {
        return this.getParams().mktRoot
    }
}

export interface MKTActionBpmGraphCreateParameters extends IBpmGraphCreateWithParentLinkParameters {
    mktAction?: IBPMMarketingActionServer;
}

export class MKTActionBpmGraphCreateHandler extends BPMGraphCreateWithParentLinkHandler {
    private constructor(parameter: MKTActionBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: MKTActionBpmGraphCreateParameters): MKTActionBpmGraphCreateHandler {
        return new MKTActionBpmGraphCreateHandler(parameters)
    }

    private getParams(): MKTActionBpmGraphCreateParameters {
        return (<MKTActionBpmGraphCreateParameters>super.getComponentParameter())
    }

    getMarketingAction(): IBPMMarketingActionServer {
        return this.getParams().mktAction
    }
}

export interface MKTPauseBpmGraphCreateParameters extends IBpmGraphCreateWithParentLinkParameters {
    mktPause?: IBPMMarketingPauseServer
}

export class MKTPauseBpmGraphCreateHandler extends BPMGraphCreateWithParentLinkHandler {
    private constructor(parameter: MKTPauseBpmGraphCreateParameters) { super(parameter) }

    static create(parameters: MKTPauseBpmGraphCreateParameters): MKTPauseBpmGraphCreateHandler {
        return new MKTPauseBpmGraphCreateHandler(parameters)
    }

    private getParams(): MKTPauseBpmGraphCreateParameters {
        return (<MKTPauseBpmGraphCreateParameters>super.getComponentParameter())
    }

    getMarketingPause(): IBPMMarketingPauseServer {
        return this.getParams().mktPause
    }
}

export interface MKTAckBpmGraphCreateParameters extends IBpmGraphCreateWithParentLinkParameters {
    mktAck?: IBPMMarketingAcknowledgeServer;
    eSubscribeType?: ESubscribedTo;
}

export class MKTAckBpmGraphCreateHandler extends BPMGraphCreateWithParentLinkHandler {
    #isCreation: boolean;
    private constructor(parameter: MKTAckBpmGraphCreateParameters) {
        super(parameter);

        this.#isCreation = !!this.getMarketingAck();
    }

    static create(parameters: MKTAckBpmGraphCreateParameters): MKTAckBpmGraphCreateHandler {
        return new MKTAckBpmGraphCreateHandler(parameters)
    }

    public getParams(): MKTAckBpmGraphCreateParameters {
        return (<MKTAckBpmGraphCreateParameters>super.getComponentParameter())
    }

    getMarketingAck(): IBPMMarketingAcknowledgeServer {
        return this.getParams().mktAck
    }

    getSubscribeType(): ESubscribedTo | undefined {
        return this.getParams().eSubscribeType
    }

    get isCreation(): boolean {
        return this.#isCreation;
    }
}

export interface MKTListenerBpmGraphCreateParameters extends IBpmGraphCreateWithParentLinkParameters {
    mktListener?: IBPMMarketingListenerServer;
}

export class MKTListenerBpmGraphCreateHandler extends BPMGraphCreateWithParentLinkHandler {
    #isCreation: boolean;
    private constructor(parameter: MKTListenerBpmGraphCreateParameters) {
        super(parameter);

        this.#isCreation = !!this.getMarketingListener();
    }

    static create(parameters: MKTListenerBpmGraphCreateParameters): MKTListenerBpmGraphCreateHandler {
        return new MKTListenerBpmGraphCreateHandler(parameters)
    }

    public getParams(): MKTListenerBpmGraphCreateParameters {
        return (<MKTListenerBpmGraphCreateParameters>super.getComponentParameter())
    }

    getMarketingListener(): IBPMMarketingListenerServer {
        return this.getParams().mktListener
    }

    get isCreation(): boolean {
        return this.#isCreation;
    }
}


export interface MKTEndBpmGraphCreateParameters extends IBpmGraphCreateWithParentLinkParameters {
    mktEnd?: IBPMMarketingEndServer;
}

export class MKTEndBpmGraphCreateHandler extends BPMGraphCreateWithParentLinkHandler {
    #isCreation: boolean;
    private constructor(parameter: MKTEndBpmGraphCreateParameters) {
        super(parameter);

        this.#isCreation = !!this.getMarketingEnd();
    }

    static create(parameters: MKTEndBpmGraphCreateParameters): MKTEndBpmGraphCreateHandler {
        return new MKTEndBpmGraphCreateHandler(parameters)
    }

    public getParams(): MKTEndBpmGraphCreateParameters {
        return (<MKTEndBpmGraphCreateParameters>super.getComponentParameter())
    }

    getMarketingEnd(): IBPMMarketingEndServer {
        return this.getParams().mktEnd
    }

    get isCreation(): boolean {
        return this.#isCreation;
    }
}