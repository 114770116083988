import { Component, OnDestroy, OnInit } from '@angular/core';
import { ENotificationPayload, IBasicNotificationPayload, INotificationCustomerExpireClose, INotificationCustomerExpireWarn, TNotificationPayload } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import { narrow } from '@colmeia/core/src/tools/utility';
import { checkEarlyReturnSwitch } from '@colmeia/core/src/tools/utility-types';
import { TAppColorThemes } from '@colmeia/core/src/ui/ui.model';
import { TNotificationDialogConfig } from 'app/components/notifications-dialog/notification-dialog.model';
import { NotificationDialogRef } from 'app/components/notifications-dialog/notification-dialog.ref';
import { NotificationDialogService } from 'app/components/notifications-dialog/notification-dialog.service';
import { CRMTicketNotificationService } from 'app/crm-service-tickets-view/crm-ticket-notification.service';
import { AttendanceService } from 'app/services/attendance.service';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { ENotificationPayloadClientType, INotificationPayloadClient, TNotificationId } from 'app/services/new-notifications-client.model';
import { NewNotificationsService } from 'app/services/new-notifications.service';
import { Subscription } from 'rxjs';

type TNotificationCustomerExpire = INotificationCustomerExpireWarn | INotificationCustomerExpireClose
@Component({
    selector: 'app-new-notifications-page',
    templateUrl: './new-notifications-page.component.html',
    styleUrls: ['./new-notifications-page.component.scss']
})
export class NewNotificationsPageComponent implements OnInit, OnDestroy {
    dialogMap: Record<TNotificationId, NotificationDialogRef<any>> = {}
    notificationSubscription: Subscription;

    constructor(
        private newNotificationSvc: NewNotificationsService,
        private notificationSvc: NotificationDialogService,
        private dialogSVC: ColmeiaDialogService,
        private attendanceSvc: AttendanceService,
        private crmTicketNotificationSvc: CRMTicketNotificationService,
    ) { }

    ngOnInit() {
        this.listenNotifications();
    }

    ngOnDestroy(): void {
        this.notificationSubscription?.unsubscribe();
    }

    listenNotifications() {
        this.notificationSubscription = this.newNotificationSvc.listenNewNotificationsToShowOnScreen()
            .subscribe((notification: INotificationPayloadClient) => {
                this.handleNotificationToShowOnScreen(notification)
            })
    }

    public getNotificationMessage(notification: TNotificationPayload): string {
        console.log({ getNotificationMessage: notification });

        switch (notification.type) {
            case ENotificationPayload.broadcastAllocationStatus:
            case ENotificationPayload.sendOpenCasesBroadcast:
                return undefined;
            case ENotificationPayload.customerExpireClose:
            case ENotificationPayload.customerExpireWarn:
            case ENotificationPayload.job:
            case ENotificationPayload.adminSolicitationTaskApproval:
            case ENotificationPayload.formSolicitationComplete:
            case ENotificationPayload.crmTicket:
                return notification.message;
            case ENotificationPayload.renameTemporaryAvatar:
                return notification.newName;
            case ENotificationPayload.customerTransferedBySupervisor:
                return notification.promptMessage.message;
            case ENotificationPayload.genericPromptMessage:
            case ENotificationPayload.supervisionBroadcastMessage:
            case ENotificationPayload.statusChangedBySupervision:
            case ENotificationPayload.agentExpire:
            case ENotificationPayload.attServiceFinish:
            case ENotificationPayload.warehouseDataExtractor:
                return notification.promptMessage.message;
        }

        checkEarlyReturnSwitch()(notification)
    }

    private readonly jobPayloadThemeToIcon: Record<TAppColorThemes, Partial<TNotificationDialogConfig<unknown>>> = {
        warn: {
            icon: {
                matIcon: 'error'
            },
        },
        success: {
            icon: {
                matIcon: 'task_alt'
            },
        },
        primary: {},
        accent: {},
        alert: {
            icon: {
                matIcon: 'error'
            },
        },
        info: {
            icon: {
                matIcon: 'info'
            },
        },
    }

    private getBaseOptions(notification: TNotificationPayload): Partial<TNotificationDialogConfig<unknown>> {
        switch (notification.type) {
            case ENotificationPayload.job:
                return this.jobPayloadThemeToIcon[notification.theme] || {}
            case ENotificationPayload.crmTicket:
                return {
                    icon: {
                        matIcon: 'confirmation_number'
                    },
                    mainAction: () => {
                        this.crmTicketNotificationSvc.handleNotificationClick(notification.content)
                    }
                }
            default:
                return {}
        }
    }

    public customerExpireNotifications: ENotificationPayload[] = narrow<ENotificationPayload[]>()([
        ENotificationPayload.customerExpireWarn,
        ENotificationPayload.customerExpireClose,
    ])

    public isCustomerExpireNotification(notification: TNotificationPayload): notification is (INotificationCustomerExpireWarn | INotificationCustomerExpireClose) {
        return this.customerExpireNotifications.includes(notification.type)
    }

    public executeCustomExpireActions(notification: TNotificationCustomerExpire) {
        const increment: number = notification.type === ENotificationPayload.customerExpireClose ? 1 : 0;
        this.attendanceSvc.setCustomerDoesNotAnswerCountByGroup(notification.idServiceGroup, increment + notification.attempts);
    }

    handleNotificationToShowOnScreen(notification: INotificationPayloadClient) {
        const notificationPayload = notification as IBasicNotificationPayload as TNotificationPayload;
        if (this.isCustomerExpireNotification(notificationPayload)) {
            return this.executeCustomExpireActions(notificationPayload);
        }

        const msg: string = this.getNotificationMessage(
            notificationPayload
        )

        const baseOptions = this.getBaseOptions(notificationPayload);

        if (notification.viewType == ENotificationPayloadClientType.open) {
            const notificationMsg = {
                ...baseOptions,
                message: msg,
                idNotification: notification.idNotification,
                duration: 5000,
                theme: notification.theme,
            };
            const dialogRef = this.notificationSvc.open(notificationMsg)
            dialogRef.closed.subscribe(({ byUser }) => notification.alreadyClosedByUser = byUser);
            this.dialogMap[notification.idNotification] = dialogRef
        } else {
            const dialogRef = this.dialogMap[notification.idNotification]
            this.notificationSvc.replace(dialogRef, { ...baseOptions, theme: notification.theme, message: msg })
        }
    }
}
