import { Injectable } from '@angular/core';
import { ETrackerPayloadType, ITrackerPayloadCarrierDeliveryGuarantee } from '@colmeia/core/src/comm-interfaces/tracker-interfaces';
import { NewNotificationsService } from './new-notifications.service';

@Injectable({
    providedIn: 'root'
})
export class GuaranteedPayloadHandlerService {

    constructor(
        private newNotificationSvc: NewNotificationsService
    ) { }

    handle(interaction: ITrackerPayloadCarrierDeliveryGuarantee) {
        switch (interaction.payloadType) {
            case ETrackerPayloadType.notification:
                this.newNotificationSvc.handleNotificationPayload((interaction.payload as any).payload);
                break;
            default:
                throw new Error(`${interaction.payloadType} not handled`);
        }
    }

}
