import { TGlobalUID, constant } from "@colmeia/core/src/business/constant";
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { Group } from "@colmeia/core/src/business/group";
import { GroupType } from "@colmeia/core/src/business/group-type";


export enum EColorComponent {
    None,
    White = 'FFFFFF',
    Golden = 'CCB16E',
};

export namespace EColorComponent {

    /**
     * Gets color from objectType
     * @param  {Serializable} serializable
     */
    function getColorFromObjectType(serializable: Serializable) {
        if (serializable.getObjectTypeID() == constant.objectType.group) {
            return getGroupColor((<Group>serializable).getGroupType());
        } else if (serializable.getObjectTypeID() == constant.objectType.groupType) {
            return getGroupColor(<GroupType>serializable);
        }
    }

    function getGroupColor(groupType: GroupType): EColorComponent {
        if (groupType.isSpecialDisplay()) {
            return EColorComponent.Golden;

        } else if (groupType.isRoot() || groupType.isPersonal()) {
            return EColorComponent.White;
        }

        return EColorComponent.White;
    }

    /**
     * Gets colors of any serializable
     * @param  {TGlobalUID} primaryID
     * @returns EColorComponent
     */
    export function getColor(primaryID: TGlobalUID): EColorComponent {
        if (UberCache.testCache(primaryID)) {
            const serializable = Serializable.staticFactory(primaryID);
            return getColorFromObjectType(serializable)
        }
    }


}
