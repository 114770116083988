import { AfterViewInit, Component, Inject } from '@angular/core';
import { DynamicComponentHandler } from '../cm-dynamic-component/cm-dynamic-component.handler';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';

@Component({
    selector: 'app-dynamic-dialog',
    templateUrl: './dynamic-dialog.component.html',
    styleUrls: ['./dynamic-dialog.component.scss']
})
export class DynamicDialogComponent implements AfterViewInit {
    disableAnimation = true;
    ngAfterViewInit(): void {
        setImmediate(() => this.disableAnimation = false);
    }


    public dynamicHandler: DynamicComponentHandler;
    constructor(
        @Inject(MAT_DIALOG_DATA) data: IColmeiaDialogComponentData<DynamicComponentHandler>,
    ) {
        this.dynamicHandler = data.getParamsToChildComponent();
    }

}
