import { Injectable } from '@angular/core';
import { allOpenContract, Contract } from '@colmeia/core/src/business/contract';
import { EFeatures } from "@colmeia/core/src/business/features";
import { environment } from '../../../../environments/environment-client';
import { SessionService } from "../../session.service";
import { HardwareLayerService } from "../../hardware";

@Injectable({
    providedIn: "root"
})
export class ContractService {
    private contract: Contract;
    private session: SessionService;

    private static allDevFeatures: Array<EFeatures> = [
        EFeatures.Billing,
        EFeatures.SocialBind,
        EFeatures.createNewAvatar,
        EFeatures.Wallet,
        EFeatures.Rosetta,
        EFeatures.Informations,
    ];

    private static standart: Array<EFeatures> = [
        EFeatures.Location,
        EFeatures.changePassword
    ];

    constructor(
        private hw: HardwareLayerService
    ) { }

    public getContract(): Contract {
        if (!this.contract) {
            this.contract = Contract.factoryContract(allOpenContract);
        }
        return this.contract;
    };

    public setSessionServiceDependency(session: SessionService) {
        this.session = session;
    }

    public getAllowedFeatures(): Array<EFeatures> {
        const result: Array<EFeatures> = [
            ...ContractService.standart
        ];

        // if (this.hw.platformHasPushNotifications()) {
        result.push(EFeatures.Notification);
        // }

        if (environment.allDevFeatures) {
            result.push(...ContractService.allDevFeatures);
        }

        return result;
    }

    public isAllowedFeature(feature: EFeatures): boolean {
        return this.getAllowedFeatures().some(f => f === feature);
    }
}
