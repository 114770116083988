<div class="app-alert-base" [ngClass]="classes">

  <div style="width: 100%;">
    <div class="alert-header">
      <mat-icon *ngIf="!hideIcon && matIcon">{{ matIcon }}</mat-icon>

      <h4 *ngIf="title" class="alert-title">{{ title }}</h4>

      <ng-container *ngIf="!title">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ng-container>

    </div>
    <div *ngIf="title" class="alert-content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>

  <button *ngIf="action" style="color: inherit;" mat-button (click)="onActionClick()">
    {{ action }}
  </button>

  <button *ngIf="closable" mat-icon-button (click)="onActionClick()">
    <mat-icon class="mat-18-force" >close</mat-icon>
  </button>
</div>

<ng-template #content>
  <app-bbcode-viewer *ngIf="message" [text]="message"></app-bbcode-viewer>

  <ng-container *ngIf="!message">
    <pre *ngIf="preFormatted">
      <ng-container *ngTemplateOutlet="textContent"></ng-container>
    </pre>

    <ng-container *ngIf="!preFormatted">
      <ng-container *ngTemplateOutlet="textContent"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #textContent>
  <ng-content></ng-content>
</ng-template>
