import {Language} from '../rosetta/language';
import {hashToArray} from '../tools/utility';

import {constant, Pane, ObjectType, GroupType, UserDefaultSettings, Serializable} from '../business/barrel-business';
import {MultiMediaType, MultimediaTag} from '../multi-media/barrel-multimedia';
import {IUniversalJSON, IUniversalJSONArray, IPaneJSONArray, IRoleJSONArray, IPermissionJSONArray,
        IObjectTypeJSONArray, ILanguageJSON, IObjectTypeJSON, IGroupTypeJSONArray,
        IInteractionTypeJSONArray, IFeedbackJSON, IGroupSecurityDomainJSON,
        IDueDateTypeJSON,
        TIErrorDomainArray,
        ISubscriptionTypeJSON,
        }
from '../comm-interfaces/business-interfaces';
import {IMultimediaTypeJSONArray, IMultimediaTagJSONArray} from '../comm-interfaces/multi-media-interfaces';
import {GroupSecurityDomain} from '../security/group-security-domain';

import {UberCache, IUberCache, TSerializableArray} from './uber-cache';
import { DueDateType } from '../interaction/due-date/due-date-type';
import { ErrorDomain } from '../error-control/error-domain';
import {MultimediaObject} from '../multi-media/multi-media-object';
import { TGlobalUID } from '../business/constant';
import { InteractionType } from '../interaction/interaction-type';
import { Feedback } from '../interaction/feedback';
import { SubscriptionType } from '../interaction/subscription/subscription-type';
import { Role } from '../security/role';
import { Permission } from '../security/permission';
import { allMetadata } from '../shared-business-rules/metadata/metadata-db';
import { MetadataMessage } from '../shared-business-rules/metadata/metadata-message';


export function getSerializableArray(jsonArray: IUniversalJSONArray, idObjectType: TGlobalUID): IUniversalJSONArray {
    return jsonArray.filter((u) => {return u.idObjectType == idObjectType});
};



export class UberCacheOperation {

    private static lastChangesOnCache: IUberCache;

    public static uberCacheArrayRehydrate(jsonArray: IUniversalJSONArray, socialContext: TGlobalUID = constant.socialContext.colmeia): void {
        // Metadados

        // Independentes 
        for (let object of <IObjectTypeJSONArray>getSerializableArray(jsonArray, constant.objectType.objectType)) 
            ObjectType.plainFactoryMessage(object);

        
        // Rosseta
        for (let language of <Array<ILanguageJSON>>getSerializableArray(jsonArray, constant.objectType.language)) 
            Language.factoryMessage(language);

        // SubscriptionType
        for (let subscription of <Array<ISubscriptionTypeJSON>>getSerializableArray(jsonArray, constant.objectType.subscriptionType)) 
            SubscriptionType.factoryMessage(subscription);
        
        // Multimedia 
        for (let mType of <IMultimediaTypeJSONArray>getSerializableArray(jsonArray, constant.objectType.multiMediaType)) 
            MultiMediaType.factoryMessage(mType);

        for (let mTag of <IMultimediaTagJSONArray>getSerializableArray(jsonArray, constant.objectType.multiMediaTag)) 
            MultimediaTag.factoryMessage(mTag);

        // Errors

        for (let mError of <TIErrorDomainArray>getSerializableArray(jsonArray, constant.objectType.errorDomain))
            ErrorDomain.factoryMessage(mError);

        UberCache._setTemplate(MultimediaObject.getTemplate(), null);
        
        for (let pane of <IPaneJSONArray>getSerializableArray(jsonArray, constant.objectType.pane)) 
            Pane.factoryMessage(pane);
        
        for (let role of <IRoleJSONArray>getSerializableArray(jsonArray, constant.objectType.role)) 
            Role.plainFactoryMessage(role);
        
        for (let permission of <IPermissionJSONArray>getSerializableArray(jsonArray, constant.objectType.permission)) 
            Permission.factoryMessage(permission);

        for (let interactionType of <IInteractionTypeJSONArray>getSerializableArray(jsonArray, constant.objectType.interactionType))
            InteractionType.plainFactoryMessage(interactionType);

        for (let interactionType of <IInteractionTypeJSONArray>getSerializableArray(jsonArray, constant.objectType.interactionType)) {            
            InteractionType.factoryMessage(interactionType);
        }
        
        for (let role of <IRoleJSONArray>getSerializableArray(jsonArray, constant.objectType.role)) 
            Role.factoryMessage(role);


        for (let object of <Array<IObjectTypeJSON>>getSerializableArray(jsonArray, constant.objectType.objectType)) 
            ObjectType.factoryMessage(object);
            
        for (let gSecType of <Array<IGroupSecurityDomainJSON>>getSerializableArray(jsonArray, constant.objectType.groupSecurityDomain))
            GroupSecurityDomain.factoryMessage(gSecType);

        for (let gType of <IGroupTypeJSONArray>getSerializableArray(jsonArray, constant.objectType.groupType)) 
            GroupType.plainFactoryMessage(gType);

        for (let gType of <IGroupTypeJSONArray>getSerializableArray(jsonArray, constant.objectType.groupType)) 
            GroupType.factoryMessage(gType);

        for (let feedback of <Array<IFeedbackJSON>>getSerializableArray(jsonArray, constant.objectType.feedback))
            Feedback.factoryMessage(feedback);

        for (let duedate of <Array<IDueDateTypeJSON>>getSerializableArray(jsonArray, constant.objectType.dueDateType))
            DueDateType.factoryMessage(duedate);

        for (let visualElement of <Array<IUniversalJSON>>getSerializableArray(jsonArray, constant.objectType.visualElement))
            Serializable.factoryMessage(visualElement);

    };


    public static uberCacheRehydrate(uberTxt: string, socialContext: TGlobalUID = constant.socialContext.colmeia): void {
        
        // parsing uberCache from server and rehydrating JSONArray
        let array: any = JSON.parse(uberTxt);
        UberCache.initLoadingSocialContext(socialContext);
        let jsonArray: IUniversalJSONArray = <IUniversalJSONArray>hashToArray(array);
        UberCacheOperation.uberCacheArrayRehydrate(jsonArray);

        // Data Init
        UserDefaultSettings.initProfile();
        
        for (const idMetadata in allMetadata) {
            MetadataMessage.factory(allMetadata[idMetadata]);
        }

        if (socialContext != constant.socialContext.colmeia) {
            UberCache.initLoadingSocialContext(constant.socialContext.colmeia);
            UberCacheOperation.lastChangesOnCache = UberCacheOperation.activeSocialContext(socialContext);
        };
        jsonArray = null;

    };

    private static activeSocialContext(socialContext: TGlobalUID): IUberCache {
        let upcomingChanges: TSerializableArray = UberCache.getContextSerializableArray(socialContext);
        let backChanges: IUberCache = {};

        for (let newSerializable of upcomingChanges) {
            if (UberCache.testCache(newSerializable.getPrimaryID())) {
                backChanges[newSerializable.getPrimaryID()] = UberCache.unsafeUberFactory(newSerializable.getPrimaryID());
            }
            UberCache.addUberHash(newSerializable);
        };
        UberCache.resetSocialContext(socialContext);
        return backChanges;
    };

    public static restoreColmeiaContext(backup: IUberCache):  void {
        let changed: TSerializableArray = UberCache.getContextSerializableArray(constant.socialContext.colmeia)
                    .filter((serializable) => {serializable.getSocialContext() != constant.socialContext.colmeia});
 
        for (let old of changed) {
            if (backup[old.getPrimaryID()]) {
                UberCache.addUberHash(backup[old.getPrimaryID()]);
            } else {
                UberCache.removeFromCache(old.getPrimaryID());
            };
        };
    };


    public static toUberText(socialContext: TGlobalUID = constant.socialContext.colmeia): string {
        return JSON.stringify(UberCache.getUberCache(socialContext));
    }; 


};
