import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { flat } from '../../../tools/utility';
import { NSGenericHost } from '../../graph-transaction/host/ns/ns.host';
import { BasicElement, TBasicElementArray, TBasicElementId } from './basic-element';
import { TIBasicElementServerArray } from './graph-basic-element-interfaces';
import { GraphElement } from './graph-element';
import { EGraphElementType } from './graph-types';
import { GraphPredicate } from './predicate';

export enum EGraphElementAction {
    Save = 'Save',
    Remove = 'Remove',
}

export type TGraphElementActionDescriptorList = IGraphElementActionDescriptorSet[]
export type TGraphOrPredicateElement = GraphElement | GraphPredicate
export type TGraphOrPredicateElementList = TGraphOrPredicateElement[]

interface IGraphElementAction {
    type: EGraphElementType.node,
    element: GraphElement
}
interface IGraphPredicateAction {
    type: EGraphElementType.predicate,
    element: GraphPredicate
}

export interface IGraphElementActionDescriptorUnity {
    action: EGraphElementAction
    element: GraphElement | GraphPredicate
}

export interface IGraphElementActionDescriptorSet {
    action: EGraphElementAction,
    // elementClientList?: TIBasicElementClientArray,
    elementList?: TBasicElementArray,
    nsList?: NSGenericHost[]
}
export interface IGraphElementActionDescriptorResult {
    savedBasicGraphElements: TIBasicElementServerArray,
    savedElements: Record<string, INonSerializable>,
}
type TElementIdToElementMap = Map<TBasicElementId, BasicElement | NSGenericHost>

export class GraphElementActionSaver {
    private constructor(
        private elementActionMap: Map<EGraphElementAction, TElementIdToElementMap> = GraphElementActionSaver.getNewEmptyMap()
    ) { }

    static getNewEmptyMap(): Map<EGraphElementAction, TElementIdToElementMap> {
        return new Map([[EGraphElementAction.Save, new Map()], [EGraphElementAction.Remove, new Map()]])
    }

    static create(): GraphElementActionSaver {
        return new GraphElementActionSaver()
    }

    reset() {
        this.elementActionMap = GraphElementActionSaver.getNewEmptyMap()
    }

    private genericAddToElementList(action: EGraphElementAction, graphNodeOrPredicateList: TBasicElementArray | BasicElement) {
        const elementMap = this.elementActionMap.get(action)
        const graphList: TBasicElementArray = [].concat(graphNodeOrPredicateList)
        graphList.forEach(element => {
            elementMap.set(element.getGraphElementID(), element)
        });
    }

    addSaveActionToElementList(graphNodeOrPredicateList: TBasicElementArray | BasicElement) {
        this.genericAddToElementList(EGraphElementAction.Save, graphNodeOrPredicateList)
        return this
    }

    addRemoveActionToElementList(graphNodeOrPredicateList: TBasicElementArray | BasicElement) {
        this.genericAddToElementList(EGraphElementAction.Remove, graphNodeOrPredicateList)
        return this
    }

    addSaveActionToHostedAndNode(node: BasicElement) {
        this.addSaveActionToNserList(<NSGenericHost>node.getHostObject())
        this.addSaveActionToElementList(node)
    }

    addSaveActionToNserList(nserOrNserList: NSGenericHost | NSGenericHost[]) {
        const elementMap = this.elementActionMap.get(EGraphElementAction.Save)
        const graphList: NSGenericHost | NSGenericHost[] = [].concat(nserOrNserList)
        graphList.forEach(element => {
            elementMap.set(element.getHostedID(), element)
        });
        return this
    }

    concatChangedNodes(connectionDescriptorList: TGraphElementActionDescriptorList) {
        connectionDescriptorList.forEach(element => {
            if (element.elementList) {
                this.genericAddToElementList(element.action, element.elementList)
            }
            if (element.nsList) {
                this.addSaveActionToNserList(element.nsList)
            }
        })
    }

    getChangedNodes(): TGraphElementActionDescriptorList {
        const allToBeSaved = this.getAllUniqueNodesChanged(EGraphElementAction.Save)
        const allToBeRemoved = this.getAllUniqueNodesChanged(EGraphElementAction.Remove)

        return flat([allToBeSaved, allToBeRemoved])
    }

    private getAllUniqueNodesChanged(action: EGraphElementAction): TGraphElementActionDescriptorList {
        const allElements = []
        const allToBeSaved = Array.from(this.elementActionMap.get(action).values())
        const allGenericHost: NSGenericHost[] = allToBeSaved.filter(element => element instanceof NSGenericHost) as NSGenericHost[]
        const allBasicElement: TBasicElementArray = allToBeSaved.filter(element => element instanceof BasicElement) as TBasicElementArray
        const allElementToBeSavedAction: IGraphElementActionDescriptorSet = { action, elementList: allBasicElement }
        const allGenericHostToBeSavedAction: IGraphElementActionDescriptorSet = { action, nsList: allGenericHost }

        if (allElementToBeSavedAction.elementList?.length > 0) {
            allElements.push(allElementToBeSavedAction)
        }
        if (allGenericHostToBeSavedAction.nsList?.length > 0) {
            allElements.push(allGenericHostToBeSavedAction)
        }
        return allElements
    }
}
