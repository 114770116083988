import { INonSerializable, INonSerializableHeader } from "../../non-serializable-id-interfaces";
import { FriendlyMessage } from "../../../../tools/barrel-tools";
import { nserInsertNameError, nserSmallNameError } from "../error-messages";
import { minLength, nserNameMinLength } from "../helpers";

export function defaultNserValidator(nser: INonSerializableHeader, friendly: FriendlyMessage): FriendlyMessage {
    if (! nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }
    return friendly;
}