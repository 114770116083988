import {MultimediaTag, TMultiMediaTagArray} from '../multi-media/multi-media-tag';
import {IObjectTypeJSON} from '../comm-interfaces/business-interfaces';
import {Serializable} from './serializable';
import {constant,TGlobalUID} from './constant';
import {UberCache} from '../persistency/uber-cache';


export type TObjectTypeArray = Array<ObjectType>;

export class ObjectType extends Serializable {
    private tableName: string;
    private container: boolean;
    private contextable: boolean;
    private DScacheable: boolean;

    private constructor (idObject: TGlobalUID, tableName: string, container: boolean, contextable: boolean, cashable: boolean) {
        super(constant.objectType.objectType, idObject);
        this.tableName = tableName;
        this.container = container;
        this.contextable = contextable;
        this.DScacheable = cashable;
    };

    public isDSCacheable(): boolean {return this.DScacheable;};

    public isContextable(): boolean {return this.contextable;};
    public isContainer(): boolean {return this.container;};
    public getObjectID(): TGlobalUID {return super.getPrimaryID();};
    public getTableName(): string {return this.tableName;};
    
    public toJSON(): IObjectTypeJSON {
    
        let json: IObjectTypeJSON =  {
            ...super.toJSON(),
            tableName : this.tableName,
            isContainer : this.container,
            contextable : this.contextable,
            DSCacheable : this.DScacheable,
        }
        return json;
    }

    public rehydrate(json: IObjectTypeJSON): void {
        super.rehydrate(json);
        this.tableName = json.tableName;
        this.container = json.isContainer;
        this.contextable = json.contextable;
        this.DScacheable = json.DSCacheable;
    };

    public static staticFactory(idObject: TGlobalUID): ObjectType {
        return <ObjectType>UberCache.uberFactory(idObject, constant.objectType.objectType, true);
    };

    public static getObjectTypeArray():TObjectTypeArray {
        return <TObjectTypeArray>UberCache.getSerializableArray(constant.objectType.objectType);
    };

    public static factoryMessage(json: IObjectTypeJSON): ObjectType {
        let oType: ObjectType = ObjectType.staticFactory(json.primaryID)
        oType.rehydrate(json);
        return oType;

    }

    public static plainFactoryMessage(json:IObjectTypeJSON): ObjectType {
        let oType: ObjectType = <ObjectType>UberCache.uberFactory(json.primaryID, constant.objectType.objectType, false);
         if (oType == null) 
            oType = new ObjectType(json.primaryID, json.tableName, json.isContainer, json.contextable, json.DSCacheable);
        return oType;
    };
};

