import { IBPMMarketingActionServer, IBPMMarketingPauseServer, TBpmMarketingNsTypes } from '../../../BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { INodeDeletedConnection } from '../ns/ns.host.types';
import { MktHostNode } from './mkt-host-node';

export class MktPauseHost extends MktHostNode {
    constructor(mktPause: IBPMMarketingPauseServer) {
        super(mktPause)
    }

    static create(mktPause: IBPMMarketingPauseServer): MktPauseHost {
        return new MktPauseHost(mktPause)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return nodeTypeToDrag === ENonSerializableObjectType.bpmMarketingAction
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return target === ENonSerializableObjectType.bpmMarketingRoot
    }
}
