import { Injectable } from "@angular/core";
import { EInteractiveButton, GlobalWarningService, IInteractiveButton, INTERACTIVE_REMOVE } from "./global-warning.service";


@Injectable({
    providedIn: 'root'
})
export class AttendantResourcesService {

    constructor(
        private warningSvc: GlobalWarningService
    ) {}

    public async confirmDeletion(title: string): Promise<boolean> {
        const interactiveButtons: IInteractiveButton[] = [
			{
				option: EInteractiveButton.Cancel,
				text: 'Cancelar',
				style: 'stroked'
			},
			{
				...INTERACTIVE_REMOVE,
				text: 'Remover',
			},
		];
		const confirmPromise = await this.warningSvc.showInteractivePrompt({
            title,
            message: 'Essa não é uma ação definitiva. \nSalve suas alterações para confirmar a deleção do item.',
            options: interactiveButtons,
            disableClose: false,
            matIcon:{
                icon: 'warning_amber',
                color: 'error'
            }
        });

        // Retorna false clicando em cancel
        return confirmPromise === EInteractiveButton.Remove;
    }
}
