import { Component, Input } from '@angular/core';
import { HandlerHexagonon, EHexagonSizes } from 'app/handlers/hexagono.handler';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'text-icon-white-box',
	templateUrl: './text-icon-white-box.component.html',
	styleUrls: ['./text-icon-white-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextIconWhiteBoxComponent {

	@Input() title: string;

	@Input() backgroundColor: string = '';

	@Input() set url (icon: string) {
		this.hexagonHandler = HandlerHexagonon.newHandler({
			forceURL: icon,
			size: EHexagonSizes.xsmd,
		});
	};

	@Input() set idMedia(idMedia: string) {
		this.hexagonHandler = HandlerHexagonon.newHandler({
			forceImage: idMedia,
			size: EHexagonSizes.xsmd,
		});
	}

	@Input() text: string;
	@Input() bigText: boolean = false;

	hexagonHandler: HandlerHexagonon;

}
