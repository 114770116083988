import { isInvalidString } from '@colmeia/core/src/tools/utility';
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";
import { attendanceCopilotErrors } from '../error-fields';
import { screenOptionToError } from '../error-messages';
import { ICopilotServer } from '../../../copilot/copilot.model';

const errors = screenOptionToError(attendanceCopilotErrors);

export const attendanceCopilotValidator: TNserValidator<ICopilotServer> = (nser: ICopilotServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);

  if (isInvalidString(nser.deployedServiceId)) {
    friendly.addReturnResponse(errors.invalidDeployedServiceId)
  }

  if (isInvalidString(nser.copilotContext)) {
    friendly.addReturnResponse(errors.invalidCopilotContext)
  }

  return friendly
}

