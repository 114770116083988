import { Interaction } from "../../interaction";
import { TGlobalUID } from "../../../business/constant";
import { Participant } from "../../../business/participant";
import { InteractionType } from "../../interaction-type";
import { EGeolocationIntType } from "../../../business/constant.enums";
import { IEnableRealtimeMonitoring } from "../../../comm-interfaces/interaction-interfaces";
import { getClock } from "../../../tools/utility";


export class RealTimeGPSTrackerOn extends Interaction {
    private enabled: boolean;
    private nameAvatar: string;
    private idMedia: string;
    private idAvatar: TGlobalUID;
    private autoExpireOn: number;

    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(EGeolocationIntType.beginRealtimeTracking));
    };
    
    public static getNewTurnGPSOn(participant: Participant): RealTimeGPSTrackerOn {
        return new RealTimeGPSTrackerOn(null, participant)
    }

    public getTurnGPSOnID(): TGlobalUID {
        return this.getPrimaryID();
    };

    public getTrackedAvatarName(): string {return this.nameAvatar; };
    public getTrackedIDMedia(): string {return this.idMedia; };
    public getTrackedIDAvatar(): TGlobalUID {return this.idAvatar; };

    public rehydrate(json: IEnableRealtimeMonitoring): void {
        super.rehydrate(json);
        this.enabled = json.enabledGPS;
        this.nameAvatar = json.nameAvatar;
        this.idMedia = json.idTrackedMediaAvatar;
        this.idAvatar = json.idTrackedAvatar;
        this.autoExpireOn = json.autoExpireOn;
    };


    public toJSON(): IEnableRealtimeMonitoring {
        return {
            ...super.toJSON(),
            enabledGPS: this.enabled,
            nameAvatar: this.nameAvatar,
            idTrackedMediaAvatar: this.idMedia,
            idTrackedAvatar: this.idAvatar,
            autoExpireOn: this.autoExpireOn
        };
    }; 

    public isExpired(): boolean {return this.autoExpireOn < getClock()};

    public static staticFactory(idRealTimeON: TGlobalUID): RealTimeGPSTrackerOn {
        return <RealTimeGPSTrackerOn>Interaction.staticFactory(idRealTimeON);
    };

    public static factoryMessage(json: IEnableRealtimeMonitoring, participant: Participant = null): RealTimeGPSTrackerOn {
        let interaction: RealTimeGPSTrackerOn = <RealTimeGPSTrackerOn>Interaction.searchCachedInteraction(json.primaryID);
        if (interaction == null)
            interaction = new RealTimeGPSTrackerOn(json.primaryID,
                participant ? participant : Participant.staticFactory(json.participant.primaryID));
        interaction.rehydrate(json);
        return interaction;
    };
};