import { IInteractionJSON, IThreadInfo, TThreadInfoArray } from '../comm-interfaces/interaction-interfaces';
import { isValidRef, isValidArray, isValidString, isValidNumber } from '../tools/utility';
import { Interaction, TInteractionArray } from '../interaction/interaction';
import { EThreadType } from '../business/constant.enums';
import { TGlobalUID } from '../business/constant';
import { setFeatureResponse } from './command-rules';
import { isEmbbededInteraction } from '../shared-business-rules/embedded/embedded-functions';
import { isReplicatedGroups } from './interaction-filter';

export function setThreadInfo(interaction: IInteractionJSON, thread: IThreadInfo): void {
    if (isValidRef(thread)) {
        setFeatureResponse(interaction, thread.idThread, thread.idGeneralResponse, thread.threadType, thread.threadName);
    };
}


export function getSocialCCThread(interaction: Interaction): IThreadInfo {
    const threads = interaction.getThreadInfo();
    if (isValidArray(threads)) {
        const thread = threads.find(thread => thread.threadType == EThreadType.socialCC);
        if (isValidRef(thread)) {
            return thread;
        }
    }
}



export function getSocialCCThreadId(interaction: Interaction): string {
    const threads = interaction.getThreadInfo();
    if (isValidArray(threads)) {
        const thread = threads.find(thread => thread.threadType == EThreadType.socialCC);
        if (isValidRef(thread)) {
            return thread.idThread;
        }
    }
}

export function getIDConversationFromThread(interaction: IInteractionJSON): string {
    if (isValidArray(interaction.threadInfo)) {
        const thread: IThreadInfo = interaction.threadInfo.find((t) => { return t.threadType === EThreadType.socialCC });
        return thread ? thread.idThread : null
    }
    return null;
}

export function getIDConversationFromThreadInfo(threadInfo: TThreadInfoArray): string {
    if (isValidArray(threadInfo)) {
        const thread: IThreadInfo = threadInfo.find((t) => { return t.threadType === EThreadType.socialCC });
        return thread ? thread.idThread : null
    }
    return null;
}

export function isAttendmentInteraction(interaction: IInteractionJSON): boolean {
    const id: string = getGeneralAnswerIDOfType(interaction, EThreadType.socialCC);
    return isValidString(id);
};

export function isEmbeddedAttending(interaction: IInteractionJSON): boolean {
    return isEmbbededInteraction(interaction) && isReplicatedGroups(interaction);
}



export function getGeneralAnswerIDOfType(interaction: IInteractionJSON, threadType: EThreadType): string {
    if (isValidArray(interaction.threadInfo)) {
        const thread = interaction.threadInfo.find((t) => { return t.threadType === threadType });
        return thread ? thread.idGeneralResponse : null;
    }
};


export function getGeneralAnswerID(interaction: IInteractionJSON): TGlobalUID {
    if (isValidArray(interaction.threadInfo)) {
        return interaction.threadInfo[0].idGeneralResponse
    };
    return null;
};

export function getNewCCThread(idConversation: string, id360Identifier: string, name: string): IThreadInfo {
    return {
        idThread: idConversation,
        idGeneralResponse: id360Identifier,
        threadName: name,
        threadType: EThreadType.socialCC
    }
}


export function getIdentifierFromInteraction(interaction: IInteractionJSON): string {
    if (isValidArray(interaction.threadInfo)) {
        const thread: IThreadInfo = interaction.threadInfo.find((t) => { return t.threadType === EThreadType.socialCC });
        return thread ? thread.idGeneralResponse : null
    }
    return null;
};


export function getIDThreadFromJSON(interaction: IInteractionJSON): string {
    if (isValidArray(interaction.threadInfo)) {
        return interaction.threadInfo[0].idThread;
    }
    return null;
}

export function checkIfWillPlayNotRespondAlert(interaction: Interaction, idAvatar: string): boolean {
    const hasAnsweredSomeMessage: boolean = interaction.getChildren(
        interaction.getParticipant().getGroupID()
    ).some((interaction: Interaction) => interaction.getParticipant().getAvatar().getPrimaryID() === idAvatar);
    return !hasAnsweredSomeMessage;
}

const isClientMessage = (interaction: Interaction, attendantAvatarID: string) => interaction.getParticipant().getAvatar().getAvatarID() !== attendantAvatarID;

export function getLastUnansweredClientInteraction(interaction: Interaction, attendantAvatarID: string): Interaction {
    const messages: TInteractionArray = interaction.getChildren(
        interaction.getParticipant().getGroupID()
    ).sort((a, b) => a.getClockTick() - b.getClockTick()).filter(i => !i.getGeneratedByBotEvent());

    if (!isValidArray(messages)) return interaction;

    return messages.reduce<Interaction>((indicated, interaction) => {
        return isClientMessage(interaction, attendantAvatarID) ? (isValidRef(indicated) ? indicated : interaction) : undefined;
    }, undefined);

}