import { Injectable } from "@angular/core";
import { BaseUpdater } from "../shared/base-updater";
import { EHardwareUpdateDeviceType } from "../hardware-interfaces";
import {EPlatformUpdateType, IVersionWarning} from "@colmeia/core/src/core-constants/version-control";

@Injectable({
    providedIn: 'root'
})
export class BrowserUpdater extends BaseUpdater {
    async update(warn: IVersionWarning): Promise<void> {
        this.showUpdatedDialog(EHardwareUpdateDeviceType.Desktop);
    }
}
