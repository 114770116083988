import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { TSerializableArray } from "@colmeia/core/src/persistency/uber-cache";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { IChipAutocompleteParameter } from "app/model/chip-autocomplete.model";
import { ChipAutocompleteComponent } from "app/components/chip-autocomplete/chip-autocomplete.component";

export interface IChipAutocompleteParent {
    setChildren(chipAutoComplete: ChipAutocompleteComponent): void
}

export class ChipAutocompleteHandler {
    constructor(private parameters: IChipAutocompleteParameter) { }

    getClientId(): TGlobalUID {
        return this.parameters.clientId
    }

    getChosenElements(): TSerializableArray {
        return this.parameters.chosenElements;
    }

    addElement(item: Serializable): void {
        if (this.isMutuallyExclusive())
            this.parameters.chosenElements = []
        this.parameters.chosenElements.push(item)
    }

    clearChosenElements(): void {
        this.parameters.chosenElements = []
    }

    getAllElements(): TSerializableArray {
        return this.parameters.allElements;
    }

    getPlaceHolder(): string {
        return this.parameters.placeholder;
    }

    isSelectable(): boolean {
        return this.parameters.selectable;
    }

    isRemovable(): boolean {
        return this.parameters.removable;
    }

    isAddOnBlur(): boolean {
        return this.parameters.addOnBlur;
    }

    isMutuallyExclusive(): boolean {
        return this.parameters.mutuallyExclusive;
    }

    chosenListChangedCallback(): void {
        this.parameters.clientInstance.chosenListChangedCallback(
            this.parameters.clientId,
            this.getChosenElements())
    }

    getParent(): IChipAutocompleteParent{
        return this.parameters.parent
    }
};
