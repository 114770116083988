
<section class="group-order-section" >
  <div class="group-order mode-selection">
    <app-enum-picker *ngIf="enumPickerHandler" [handler]="enumPickerHandler"></app-enum-picker>
  </div>  

  <div class="group-order group-order--icons">
    <ng-container *ngFor="let option of $configOptions">
      <button mat-icon-button
        *ngIf="showConfigOption(option)"
        class="group__flat__icon"
        [class.is__recent__mode]="applyRotateOnIcon(option)"
        (click)="onClickOption(option)"
      >
      <mat-icon>{{ getOptionIcon(option) }}</mat-icon>
      </button>
    </ng-container>
  </div>

</section>
