<div class="container">
  <div class="screen" [ngClass]="{currentScreen: isCurrentScreen('list')}">
    <ul>
      <li *ngFor="let medium of mediums" class="cc__container">
        <app-credit-card-input [lastfour]="true" [publicMedium]="medium" (click)="switchToEditScreen(medium.interactionID)"></app-credit-card-input>
      </li>
      <li>
        <div class="cc__wrapper" (click)="switchToCreateScreen()">
          <div class="left">
            <div class="name">
              {{ translations.addCard }}
            </div>
          </div>
          <div class="right">
            <button>
              <i class="material-icons green-plus-icon">
                add_circle
              </i>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="screen" [ngClass]="{currentScreen: isCurrentScreen('create')}">

    <div class="form-row">
      <input class="input-cc" type="text" name="name" id="cvf-name" [(ngModel)]="name" placeholder="{{ translations.fullName }}">
    </div>

    <div class="form-row">
      <input  class="input-cc" type="text" name="name" id="cvf-document" [(ngModel)]="document" placeholder="CPF" [mask]="cpfMASK">
    </div>

    <div class="form-row">
      <app-credit-card-input [onChange]="onCardChange"></app-credit-card-input>
    </div>

    <div class="form-row">
      <div class="half">
        <input class="input-cc" type="text" name="expiry" id="cvf-expiry" [(ngModel)]="expiry" placeholder="{{ translations.expires }}" [mask]="expiryMASK">
      </div>
      <div class="half">
        <input class="input-cc" type="text" name="cvc" id="cvf-cvc" [attr.maxlength]="codeSize" [(ngModel)]="cvc" placeholder="CVV">
      </div>
    </div>
    <button (click)="create()" class="add-btn">
      {{ translations.add }}
    </button>
  </div>
  <div class="screen" [ngClass]="{currentScreen: isCurrentScreen('edit')}">

    <button (click)="swithToListScreen()">
      voltar <i class="material-icons">
        chevron_left
      </i>
    </button>

    <div class="form-row">
      <app-credit-card-input [lastfour]="true" [publicMedium]="_editingMedium"></app-credit-card-input>
    </div>
    <div class="form-row">
      <input class="input-cc" type="text" name="eexpiry" id="edt-expiry" [(ngModel)]="eexpiry">
    </div>
    <button class="add-btn" (click)="removeMedium()">
      {{ translations.remove }}
    </button>

  </div>

</div>
