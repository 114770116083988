import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { MMconstant } from "@colmeia/core/src/multi-media/multimedia-constant";
import { MultimediaInstance } from "@colmeia/core/src/multi-media/multi-media-instance";
import { isValidRef, isValidArray } from "@colmeia/core/src/tools/utility";
import { deleteMultimediaInstance } from "@colmeia/core/src/rules/mm-functions";
import { IRollbackTransaction } from "../../../services/client.transact.service";
import { EHexagonFormat, EHexagonSizes, HandlerHexagonon, IHexagononParameter } from "../../../handlers/hexagono.handler";

export enum EHexagonUploaderMode {
    Upload,
    View
}

export const HexagonUploaderParameterDefaults = {
    idTag: MMconstant.tag.background,
    size: EHexagonSizes.xs,
    mode: EHexagonUploaderMode.Upload,
    multimediaObject: null,
    onFileSelected: (mm: MultimediaInstance) => { },
    onMediaDeleted: () => { }
}


export interface HexagonUploaderParameter {
    size: EHexagonSizes;
    idTag: TGlobalUID;
    multimediaObject: MultimediaObject;
    idMediaEditing: string;
    mode: EHexagonUploaderMode;
    format?: EHexagonFormat,
    fileMode?: boolean,
    mimeTypeFilter?: Array<string>;
    onFileSelected?: (mm: MultimediaInstance) => void;
    onMediaDeleted?: (mm: MultimediaInstance) => void;
    onMultimediaObjectChange?: () => void;
};


export interface HexagonUploaderClient {
    markForCheck();
    createRollback(multimediaObject: MultimediaObject): IRollbackTransaction;
    rollback(rollback: IRollbackTransaction);
};

export class HexagonUploaderHandler {

    private client: HexagonUploaderClient;
    private instance: MultimediaObject;
    private parameter: HexagonUploaderParameter;
    private hexagon: HandlerHexagonon;
    private hasMultimedia: boolean;
    private transaction: IRollbackTransaction;
    private mode: EHexagonUploaderMode;
    private currentMediaKey: string = '';

    constructor(parameter: HexagonUploaderParameter) {
        this.parameter = parameter;
        this.mode = parameter.mode;
        this.instance = this.parameter.multimediaObject;

        let mmInstance: MultimediaInstance = null;

        if (isValidRef(parameter.multimediaObject) && isValidRef(parameter.idMediaEditing)) {
            mmInstance = this.getMMIntance(parameter);
        }

        this.currentMediaKey = (isValidRef(mmInstance)) ? mmInstance.getMultmediaKey() : '';

        const mmInstanceID = (isValidRef(mmInstance)) ? this.getMMInstanceID(mmInstance) : null;

        this.hasMultimedia = isValidRef(mmInstanceID);

        this.hexagon = HandlerHexagonon.newHandler({
            size: parameter.size,
            format: parameter.format,
            forceImage: mmInstanceID,
            fileMode: parameter.fileMode
        });
    }

    public getMMIntance(parameter: HexagonUploaderParameter): MultimediaInstance {
        const mmObj = parameter.multimediaObject;
        return isValidRef(mmObj.getMultimediaInstanceByIdMedia(parameter.idMediaEditing))
            ? mmObj.getMultimediaInstanceByIdMedia(parameter.idMediaEditing)
            : (isValidRef(mmObj.getMultimediaInstanceByHash(parameter.idMediaEditing))
                ? mmObj.getMultimediaInstanceByHash(parameter.idMediaEditing)
                : null);
    }

    public getMMInstanceID(mmInstance: MultimediaInstance): string {
        return isValidRef(mmInstance.getIdMedia())
            ? mmInstance.getIdMedia()
            : (isValidRef(mmInstance.getHashFromSelectedFile())
                ? mmInstance.getHashFromSelectedFile()
                : null);
    }

    public debug(): any {
        return this.parameter;
    }

    static create(serializable: Serializable, options: HexagonUploaderParameter) {
        let multimediaObject: MultimediaObject = options.multimediaObject || serializable.getMultimediaObject();

        if (!multimediaObject) {
            serializable.setMultimediaObject(MultimediaObject.createMultimediaForSerializable(serializable));
        }

        return new HexagonUploaderHandler({
            ...options,
            multimediaObject: serializable.getMultimediaObject()
        });
    }

    getHexagonHandler(): HandlerHexagonon {
        return this.hexagon;
    }

    private prependMultimediaInstance(mm: MultimediaInstance): void {
        this.instance.replaceMultimediaInstanceArray(
            [mm, ...this.instance.getAllMultimediaInstance()]
        );
    }

    getMultimediaObject(): MultimediaObject {
        return this.instance;
    }

    onMediaUploaded(mm: MultimediaInstance): void {
        this.hexagon = HandlerHexagonon.newHandler({
            size: this.parameter.size,
            format: this.parameter.format,
            fileMode: this.parameter.fileMode,
            forceURL: mm.getClientFileInfo().getClientCachedFile().getFileUrl()
        });
        this.currentMediaKey = mm.getMultmediaKey();
        this.hasMultimedia = true;
        this.prependMultimediaInstance(mm);
        this.parameter.onFileSelected(mm);
        this.multimediaObjectChanged();
        this.client.markForCheck();
    }

    reset(): void {
        if (isValidRef(this.transaction)) {
            this.currentMediaKey = '';
            this.client.rollback(this.transaction);

            const hexagonParameters: Partial<IHexagononParameter> = {
                size: this.parameter.size,
                format: this.parameter.format,
                idMultimediaTag: this.parameter.idTag,
            }

            let mmInstances = this.parameter.multimediaObject.getAllMultimediaInstanceWithTag(this.parameter.idTag);
            if (isValidArray(mmInstances)) {
                hexagonParameters.forceImage = mmInstances[0].getIdMedia();
            }

            this.hexagon = HandlerHexagonon.newHandler(hexagonParameters);
            this.client.markForCheck();
        }
    }

    getMode(): EHexagonUploaderMode {
        return this.mode;
    }

    setViewMode(): void {
        this.mode = EHexagonUploaderMode.View;
    }

    setUploadMode(): void {
        this.mode = EHexagonUploaderMode.Upload;
    }


    private multimediaObjectChanged(): void {
        if (isValidRef(this.parameter.onMultimediaObjectChange)) {
            this.parameter.onMultimediaObjectChange();
        };
    };

    onDeleteClick(): void {
        const instance: MultimediaInstance = this.instance.getAllMultimediaInstance().find((mm) => { return mm.is(this.currentMediaKey) });
        this.hexagon = HandlerHexagonon.newHandler({
            format: this.parameter.format,
            size: this.parameter.size,
            fileMode: this.parameter.fileMode
        });
        this.hasMultimedia = false;
        this.currentMediaKey = '';

        deleteMultimediaInstance(instance, this.instance.getAllMultimediaInstance());
        this.client.markForCheck();

        this.parameter.onMediaDeleted(instance);

        this.multimediaObjectChanged();
    }

    get hasValidMedia(): boolean {
        return this.hasMultimedia;
    }

    setClientInstance(client: HexagonUploaderClient) {
        this.client = client;
        if (isValidRef(this.parameter.multimediaObject)) {
            this.transaction = this.client.createRollback(this.parameter.multimediaObject);
        }
    }

    get idTag(): any {
        return this.parameter.idTag;
    }

    markForCheck() {
        this.client.markForCheck();
    }
}
