import { Component, OnInit } from '@angular/core';
import { IBPMMarketingPauseServer, TBpmMarketingNaturalNsTypes } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ETimestmapPickerElements, TimestampPickerHandler } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { MKTPauseBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { MarketingBpmBase } from '../marketing-bpm-base.component';

@Component({
    selector: 'app-marketing-bpm-pause',
    templateUrl: './marketing-bpm-pause.component.html',
    styleUrls: ['./marketing-bpm-pause.component.scss']
})
export class MarketingBpmPauseComponent extends MarketingBpmBase<MKTPauseBpmGraphCreateHandler, IBPMMarketingPauseServer> implements OnInit {
    nsType = ENonSerializableObjectType.bpmMarketingPause as TBpmMarketingNaturalNsTypes;
    timestampHandler: TimestampPickerHandler;

    getNS(): IBPMMarketingPauseServer {
        return this.handler.getMarketingPause();
    }

    ngOnInit() {
        super.ngOnInit();
        this.initTimestampPicker();
    }

    private initTimestampPicker() {
        this.timestampHandler = new TimestampPickerHandler({
            label: 'Executar próximo comando após',
            elements: {
                [ETimestmapPickerElements.Days]: {
                    min: 0,
                    max: 365
                },
                [ETimestmapPickerElements.Hours]: {
                    min: 0,
                    max: 23
                },
                [ETimestmapPickerElements.Minutes]: {
                    min: 0,
                    max: 59
                },
            },
            time: this.ns.bpmScheduleMS,
            clientCallback: {
                onChangeTime: (value: number) => {
                    this.ns.bpmScheduleMS = value;
                },
            },
        });
    }

}
