
import { isEqual, isValidFunction, objectToSortedEntries, typedCloneLodash } from '@colmeia/core/src/tools/utility';
import { DeepMap, ProxyObserverWatchers, proxyObserver } from '@colmeia/core/src/tools/utility-types';




type Entity = {};


const changesMonitor = createChangesMonitorByHash();

export abstract class Processor<NS extends Entity = Entity> {
    static readonly content: unique symbol = Symbol('Content');

    private observer?: ObserverFn;
    public backup: BackupOf<NS>;
    public previous!: BackupOf<NS>;
    public previousSerialized!: string;
    public amountModifications: number = 0;
    
    public get isProxy() {
        return !!this.observer;
    }

    constructor(
        public entity: NS,
        private isImmutable: boolean = false,
    ) {
        this.backup = changesMonitor(this.entity);
        this.onInit();
        // 
    }

    setAsImmutable() {
        this.isImmutable = true;
        return this;
    }

    asImmutable() {
        return this.setAsImmutable();
    }



    watch(input: Watchers): NS;
    watch(observer: ObserverFn): NS;
    watch(input: ObserverFn | Watchers): NS {
        const { afterChange: observer, beforeChange }: Watchers = isValidFunction(input) ? { afterChange: input } : input
        if (this.isProxy) throw new Error('Only one observer');

        this.entity = proxyObserver({
            target: this.entity,
            afterChange: () => this.onChange(),
            beforeChange,
        });
        this.observer = observer!;

        changesMonitor.updateHash(this.entity);
        this.addChanges();
        
        return this.entity;
    }

    addMainChanges() {
        changesMonitor.updateHash(this.entity);
        changesMonitor.updateHash(this.previous);
    }

    updatePrevious() {
        // 
        this.previous ??= changesMonitor(this.entity);
        this.addMainChanges();
    }

    addToChange(fn: (entity: NS) => {}) {
        changesMonitor.updateHash(fn(this.previous));
        changesMonitor.updateHash(fn(this.entity));
    }

    public onInit() {
        this.updatePrevious();
        this.init();
        this.addChanges();
    }

    protected onChange() {
        this.amountModifications++;
        this.onInit();
        this.observer?.();
        for (const observer of this.observers) observer();
    }

    private observers: Set<ObserverFn> = new Set();
    
    public addObserver(fn: ObserverFn) {
        this.observers.add(fn);
    }
    public removeObserver(fn: ObserverFn) {
        this.observers.delete(fn);
    }

    abstract init(): void;
    abstract addChanges(): void;


    isChangedLastCheck: Set<string> = new Set();

    private isChanged<Target>({ fn, deleter }: Changed.Input<NS, Target> = {}, previous: BackupOf<NS> = this.backup): boolean {
        if (this.isImmutable) return false;

        let entity = this.entity;
        // 
        if (deleter) {
            previous = typedCloneLodash(previous);
            entity = typedCloneLodash(entity);
            deleter(previous);
            deleter(entity);
            return !isEqual(previous, entity);
        }
        
        return changesMonitor.isEqual(previous, entity, fn);
    }

    private isChangedFromPrevious<Target>(input?: Changed.Input<NS, Target>) {
        return this.isChanged(input, this.previous);
    }

    public isNeedingChange<Target>(input?: Changed.Input<NS, Target>) {
        return this.isChangedFromPrevious(input) && !this.isProxy
    }

    public changeIfNecessary<Target>(input?: Changed.Input<NS, Target>) {
        if (this.isNeedingChange(input)) this.onChange();
    }

    public hasModifications<Target>(input?: Changed.Input<NS, Target>) {
        return this.isChangedFromPrevious(input);
    }

    hasModificationsChecker() {
        let current = this.amountModifications;

        return () => {
            if (current !== this.amountModifications) {
                current = this.amountModifications;
                return true;
            }
            return false;
        }
    }
    
    public commit() {
        this.changeIfNecessary();
    }

}


export type ObserverFn = () => void;

export interface Watchers extends ProxyObserverWatchers<ObserverFn> {
    afterChange: ObserverFn;
}

export namespace Changed {
    export type Fn<NS extends Entity, Target = unknown> = (ns: NS) => Target;
    export interface Input<NS extends Entity, Target = unknown> {
        fn?: Fn<NS, Target>;
        deleter?: Fn<NS>;
    }
}


function createChangesMonitorByHash() {
    const map: DeepMap<[entity: Entity, text: string]> = new Map();

    changesMonitor.isEqual = isEqual
    changesMonitor.getBackupOf = getHash
    changesMonitor.updateHash = updateHash;

    return changesMonitor

    function isEqual<T>(previous: T, entity: T, fn: ((entity: T) => unknown) | undefined) {
        const fnPrevious = fn?.(previous) ?? previous;
        const fnEntity = fn?.(entity) ?? entity;
        const out = changesMonitor.getBackupOf(fnPrevious) !== changesMonitor.getBackupOf(fnEntity);
        return out;
    }
    function getHash<T>(entity: T): string | undefined {
        return map.get(entity as BackupOf<T>)
    }
    function changesMonitor<T extends {}>(input: T): BackupOf<T> {
        const entity = typedCloneLodash(input);
        updateHash(entity);
        return entity
    }
    function updateHash<T extends {}>(entity: T) {
        map.set(entity, objectToSortedEntries(entity));
    }
}

type BackupOf<T> = T & { [Processor.content]?: string };

function isEqualByBackup() {

}


// {   } // a1
// { f } // ak

// { f: { r } } // 
// { f: { g } } // 
