import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IFormSchema, TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { isInvalid, isValidArray, isValidPropertyNameForCorporateSearch, isValidRef, nop } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { createEmptyFormSchema, createEmptySchemaProperty, ISchemaInjectionToken, SchemaInjectionToken } from 'app/model/general-form.model';
import { SessionService } from 'app/services/session.service';
import { GeneralFormFieldComponent } from '../general-form-field/general-form-field.component';
// import {NsDeleteService} from "../../../services/ns-delete.service";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ECatalogType } from '@colmeia/core/src/shared-business-rules/catalog/catalog.interfaces';
import { EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { EDependencyMode } from "@colmeia/core/src/shared-business-rules/delete-core/delete-interfaces";
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { EEnumPickerMode, EnumPickerHandler, IEnumPickerClientCallback } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { NsDeleterService } from 'app/services/ns-deleter-service';
import { GlobalWarningService } from "../../../services/global-warning.service";
import { OnChange } from 'app/model/client-utility';
import { isNSServer } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import { allCatalogFields } from '@colmeia/core/src/shared-business-rules/catalog/catalog.constants';
import { getMetaCatalogFieldConfig, getMetaCatalogFieldProperty } from '@colmeia/core/src/shared-business-rules/catalog/catalog.functions';
import { MultimediaHandler } from 'app/handlers/multimedia-handler';
import { MultimediaService } from 'app/services/multimedia.service';
import { EUniqueFileType } from '@colmeia/core/src/multi-media/file-interfaces';
import { TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/multi-media-instance';
import { MMconstant } from '@colmeia/core/src/multi-media/multimedia-constant';
import { delimiterGetter, getDelimiterGetterInfo } from '@colmeia/core/src/business/papa-parse/guess-delimiter';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { IFileField, TFileFieldArray } from '@colmeia/core/src/request-interfaces/files-interfaces';

@Component({
    selector: 'app-general-form-schema-edit',
    templateUrl: './general-form-schema-edit.component.html',
    styleUrls: ['./general-form-schema-edit.component.scss']
})
export class GeneralFormSchemaEditComponent extends RootComponent<'fields' | 'addField' | 'save' | 'fieldRequired' | 'templateName' | 'crmIntegrationField' | 'crmIntegration' | 'connection'> implements OnInit {

    @Input() idNS: string;
    @Input() title: string;
    @Input() schema: IFormSchema;

    @OnChange()
    @Input() ns?: SchemaPropertyServer;

    public onChangeNs() {
        this.initCatalogTypePicker();
    }

    @Output() save = new EventEmitter();

    get isCatalogType() {
        return this.ns?.isCatalogType;
    }

    get catalogType() {
        return this.ns?.catalogType;
    }

    get isSaved() {
        return isNSServer(this.ns);
    }

    get isSmartFlowDashboard() { return this.dashboardSvc.defaultTag === EDefaultTag.smartFlow }

    get isAllowingCatalogType() { return this.dashboardSvc.defaultTag === EDefaultTag.conversationalCommerce; }
    public catalogTypePicker?: EnumPickerHandler<ECatalogType>;

    public initCatalogTypePicker(): void {
        delete this.catalogTypePicker;
        if (!this.ns?.isCatalogType) return;
        
        this.catalogTypePicker = new EnumPickerHandler({
            client: {
                onSingleEnumSelection: value => {
                    this.ns!.catalogType = value;
                    this.initCatalogFields();
                },
            },
            mode: EEnumPickerMode.Single,
            appearance: 'fill',
            inputTitle: 'Tipo de catálogo',
            translations: {
                [ECatalogType.Meta]: 'Meta',
            },
            enum: ECatalogType,
            clientCallback: {},
            current: this.ns.catalogType,
            disabled: this.isSaved,
        })
    }


    public initCatalogFields() {
        for (const name of allCatalogFields) {
            const config = getMetaCatalogFieldConfig(name);
            const field = createEmptySchemaProperty();
            if (!config) continue;
            field.isCatalogType = true;
            field.propertyName = field.prompt = config?.key!;
            if (config?.key === 'retailer_id') {
                field.isPrimaryKey = true;
            }
            field.idUnity ||= config.type ?? field.idUnity;
            field.idGlobalCanonical = name;

            this.schema.form.push(field);
        }
    }


    @ViewChild(NgForm) form: NgForm
    @ViewChildren(GeneralFormFieldComponent) fields: QueryList<GeneralFormFieldComponent>;


    @Input() allowCorporateSearch: boolean;
    @Output() allowCorporateSearchChange = new EventEmitter<boolean>();
    @Input() allowAttendanceView: boolean = true;
    @Output() allowAttendanceViewChange = new EventEmitter<boolean>();

    name: string;
    saving: boolean = false;

    handleCorporateSearchChange(event: MatSlideToggleChange) {
        if(!this.allowCorporateSearch && !this.isAllPropertyNamesFormatedForCS(this.schema.form)) {
            event.source.checked = false;

            this.snackBarSvc.open(
                "Para permitir o uso em Buscas corporativas os campos precisam estar formatados corretamente" + 
                ", podendo conter apenas letras minúsculas e underscore", "Fechar", { duration: 10000 });
            return;
        }

        this.allowCorporateSearchChange.next(event.source.checked);
    }

    handleAttendanceViewChange(event: MatSlideToggleChange) {
        this.ns.allowAttendanceView = event.checked;
    }

    private isAllPropertyNamesFormatedForCS(form: TSchemaPropertyArray): boolean {
        return form.every( item => {
            const isPropertyNameValid = isValidPropertyNameForCorporateSearch(item.propertyName);

            return isValidArray(item.nestedSchema)
                ? isPropertyNameValid && this.isAllPropertyNamesFormatedForCS(item.nestedSchema)
                : isPropertyNameValid;
        });
    }

    constructor(
        @Optional() @Inject(SchemaInjectionToken) private injectedToken: ISchemaInjectionToken,
        private sessionSvc: SessionService,
        private dashboardSvc: DashBoardService,
        private deleter: NsDeleterService,
        private warning: GlobalWarningService,
        private snackBarSvc: MatSnackBar,
        private multiMediaSvc: MultimediaService,
    ) {
        super({
            fields: gTranslations.common.fields,
            addField: gTranslations.generalForm.addField,
            save: gTranslations.common.save,
            fieldRequired: gTranslations.common.fieldRequired,
            templateName: gTranslations.common.templateName,
            crmIntegrationField: gTranslations.generalForm.crmIntegrationField,
            crmIntegration: gTranslations.generalForm.crmIntegration,
            connection: gTranslations.common.connection,
        });
    }

    public onChangeIsCatalogType(event: MatSlideToggleChange): void {
        if (this.ns) {
            this.ns.isCatalogType = event.source.checked;
            this.ns.catalogType = undefined;
        }
        this.initCatalogTypePicker();
    }

    ngOnInit() {
        if (this.injectedToken) {
            this.title = this.title || this.injectedToken.title;
            if (isValidRef(this.injectedToken.onSave)) {
                this.save.subscribe(this.injectedToken.onSave);
            }
            this.schema = this.schema || this.injectedToken.schema;
        }

        if (isInvalid(this.schema)) {
            this.schema = createEmptyFormSchema();
            this.schema.visibility.idGroup = this.sessionSvc.getCurrentSocialNetworkID();
            this.addField();
        }
        
    }

    addField() {
        this.schema.form.push(createEmptySchemaProperty());
    }

    saveSchema() {
        this.save.next(this.schema);
    }

    async deleteField(index: number) {
        // validar se há referências...
        const dependencies = await this.deleter.getDependenciesRows(this.idNS, EDependencyMode.DependsOnMe, false);
        if(dependencies.length) {
            this.warning.showError(`Impossível deletar field deste metadado, uma vez que ele é referenciado por outros objetos, cheque o mapa de dependências.`)
        } else {
            this.schema.form.splice(index, 1);
        }
    }

    isValid() {
        this.form.onSubmit(null);

        if (!this.form.valid) {
            return false;
        }

        const invalid = this.fields.some(field => !field.isValid());

        if (invalid) {
            return false;
        }

        return true;
    }
}
