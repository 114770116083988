import { MatDialogRef } from "@angular/material/dialog";
import { KAssetType } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { EGeneratorTransactionType, ITransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { IBPMDialogResult } from "app/components/dashboard/ai-pages/bpm-graph/diagram-editor/diagram-editor.types";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";

export interface BotTransactionBaseDataComponentDiagramDialogParameters extends IComponentParameter {
    transaction: ITransactionServer;
    selectedTabIndex?: number;
    expandedIntentsIDs?: Array<string>;
    preSelectedValue?: KAssetType;
    nsPickerSelectedProperty?: EGeneratorTransactionType;
}

export class BotTransactionBaseDataComponentDiagramDialogHandler<T extends object = IBPMDialogResult> extends MainHandler {
    private dialogRef: MatDialogRef<any, T>;
    public setDialogRef(value: MatDialogRef<any, T>): void { this.dialogRef = value; }
    public getDialogRef(): MatDialogRef<any, T> { return this.dialogRef; }

    constructor(parameters: BotTransactionBaseDataComponentDiagramDialogParameters) {
        super(parameters);
    }

    public getComponentParameter(): BotTransactionBaseDataComponentDiagramDialogParameters { return super.getComponentParameter() as BotTransactionBaseDataComponentDiagramDialogParameters }
}
