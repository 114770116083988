<section class="white-box">
  <form>
    <div class="field-container">
      <mat-form-field class="full-width-field" appearance="fill" style="font-size: 1.2rem;" *ngIf="!allowPickBotLevel">
        <mat-label style="font-size: 1.2rem;">{{ translations.botLevel }}</mat-label>
        <input matInput readonly [value]="translations[botLevel]">
      </mat-form-field>
      <app-enum-picker *ngIf="allowPickBotLevel && enumPickerHandler" [handler]="enumPickerHandler"></app-enum-picker>
    </div>

    <div class="flex-break"></div>

    <ng-container *ngIf="botLevel === ENextGenBotElementType.nlpTransaction">
      <div class="field-container">
        <mat-form-field class="full-width-field">
          <mat-label>{{ translations.actionOnComplete }}</mat-label>
          <mat-select [(ngModel)]="transaction.actionOnComplete" name="actionOnComplete" required>
            <mat-option *ngFor="let item of actions" [value]="item">{{ translations[item] }}</mat-option>
          </mat-select>
          <mat-error>{{ translations.fieldRequired }}</mat-error>
        </mat-form-field>
      </div>
      <div class="flex-break"></div>
    </ng-container>

    <div class="field-container">
      <mat-checkbox class="submit-checkbox dense-checkbox" [(ngModel)]="submitOnComplete" (change)="onSelectSubmitOnComplete($event)"
        name="submitOnComplete">{{ translations.submitOnComplete }}</mat-checkbox>
    </div>

    <div class="flex-break"></div>

    <app-ns-picker *ngIf="schemaPicker" [handler]="schemaPicker"></app-ns-picker>

    <div class="flex-break"></div>

    <ng-container *ngIf="idDatabaseContentGeneratorPicker">

      <app-ns-picker *ngIf="idDatabaseContentGeneratorPicker"
        [handler]="idDatabaseContentGeneratorPicker"></app-ns-picker>
      <div class="flex-break"></div>

    </ng-container>

    <ng-container *ngIf="showKnowledBasePicker()">

      <app-ns-picker *ngIf="knowledBasePicker" [disabled]="isEdit" [handler]="knowledBasePicker"></app-ns-picker>
      <div class="flex-break"></div>

    </ng-container>

    <app-ns-picker
      *ngIf="llmHandler && isNLP"
      [handler]="llmHandler">
    </app-ns-picker>

    <div class="flex-break"></div>

    <div class="field-container" *ngIf="isNLP">
      <br>
      <mat-label>{{ 'Durante Encadeamento, reexecute:' }}</mat-label>
      <br>
      <br>
      <div class="field-container">
        <mat-checkbox class="submit-checkbox dense-checkbox" name="rerunGreetings" [(ngModel)]="rerunGreetings">{{ 'Boas vindas'
          }}</mat-checkbox>
      </div>
      <div class="field-container">
        <mat-checkbox class="submit-checkbox dense-checkbox" name="rerunConfirmation" [(ngModel)]="rerunConfirmation">{{ 'Confirmações'
          }}</mat-checkbox>
      </div>

      <app-ns-picker *ngIf="userFunctionPicker" [handler]="userFunctionPicker"></app-ns-picker>

    </div>


    <div class="flex-break"></div>
  </form>
</section>
