import { Component, Inject } from '@angular/core';
import { TGeneralFormFieldAnswerValue } from '@colmeia/core/src/general-form/general-form-answer';
import { isInvalid } from '@colmeia/core/src/tools/utility';
import { verticalAppear } from 'app/components/dashboard/dashboard-animations';
import { GeneralFormFieldInjectionToken, GeneralFormFieldRenderer, IGeneralFormFieldData } from 'app/model/general-form.model';
import { GeneralFormFieldService } from '../services/general-form-field.service';

@Component({
    selector: 'app-general-form-multiple-choice-field',
    templateUrl: './general-form-multiple-choice-field.component.html',
    styleUrls: ['./general-form-multiple-choice-field.component.scss']
})
export class GeneralFormMultipleChoiceFieldComponent extends GeneralFormFieldRenderer<null> {
    canDisplayField: boolean = false;

    public get viewModeValue(): string { return this.field.value || ''; }

    constructor(
        generalFormFieldService: GeneralFormFieldService,
        @Inject(GeneralFormFieldInjectionToken) public injectedToken: IGeneralFormFieldData,
    ) {
        super(generalFormFieldService, injectedToken);
        if (isInvalid(this.value)) {
            this.value = '';
            this.setValue(this.value);

        }
        // fix forms saved before fixing this component's HTML
        if ((this.value as any).text) {
            this.value = (this.value as any).text
            this.setValue(this.value);
        }

    }

    async ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public setValue(value: TGeneralFormFieldAnswerValue) {
        if (!this.canDisplayFormGroup) return;

        this.fControl.setValue(value);
    }

}
