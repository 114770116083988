import { ComponentType } from "@angular/cdk/portal";
import { EYouTasksType } from "@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model"
import { SecurityTasksEditComponent } from "./security-tasks-edit/security-tasks-edit.component";
import { YouTasksEditHandler } from "./you-tasks-edit.handler";
import { YouTasksEditComponent } from "./you-tasks-form-solicitation/you-tasks-form-solicitation.component";

export interface IYouTasksClientConfig {
    type: EYouTasksType;
    component: ComponentType<unknown>;
}

export interface IYouTasksComponentBase { }

export const youTasksTypeConfig: Record<EYouTasksType, IYouTasksClientConfig> = {
    [EYouTasksType.formSolicitation]: {
        type: EYouTasksType.formSolicitation,
        component: YouTasksEditComponent,
    },
    [EYouTasksType.groupParticiparionApproval]: {
        type: EYouTasksType.groupParticiparionApproval,
        component: SecurityTasksEditComponent,
    },
    [EYouTasksType.socialNetworkParticipationApproval]: {
        type: EYouTasksType.socialNetworkParticipationApproval,
        component: SecurityTasksEditComponent
    }
};