import {Injectable} from '@angular/core';
import {RoutingService} from './routing.service';
import {routeID, routeList} from 'app/model/routes/route-constants';
import {
    ICloudNaturalLanguageSyntaxWithPartOfSpeech,
    IKnowledgeDB,
    INLPConfig,
    TIKnowledgeDBServerArray,
    IKnowledgeDBServer,
    IIntentCount
} from '@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces';
import {RequestBuilderServices} from './request-builder.services';
import {SessionService} from './session.service';
import {apiRequestType, EKnowledgeBaseRequest} from '@colmeia/core/src/request-interfaces/message-types';
import {ServerCommunicationService} from './server-communication.service';
import {
    IGetCompleteSyntaxAnalysisRequest,
    IGetCompleteSyntaxAnalysisResponse,
    IGetKBFromIdTransactionRequest,
    IGetKbFromIdTransactionResponse,
    IGetSentenceTestAnalysisRequest, IGetSentenceTestAnalysisResponse,
    IGetUtteranceExampleTestAnalysisRequest,
    IGetUtteranceExampleTestAnalysisResponse,
    IKBCreateRequest,
    IKBEditNLPRequest,
    IKBGetInfoRequest,
    IKBGetInformationResponse,
    IKBListRequest,
    IKBLlistResponse,
    IKBPredictDeleteUtterance,
    IKBPredictRequest,
    IKBPredictResponse,
    IKBEditAppConfigRequest,
    IKBEditNameRequest,
    IKBEditNLPResponse,
    IKBCreateResponse,
    IKBProcessUtterancesRequest,
    IKBProcessUtterancesResponse,
    IKBTestPredictionsResponse,
    IKBTestPredictionsRequest
} from '@colmeia/core/src/shared-business-rules/knowledge-base/kn-req-resp';
import {
    ICreateIntentRequest,
    ICreateIntentResponse,
    ICreateUtteranceRequest,
    IGetUtterancesRequest,
    IGetUtterancesResponse,
    IKBDeleteEntityRequest,
    IKBDeletePatternRequest,
    IKBDeletePhraseListRequest,
    IKBListPrebuiltEntitiesRequest,
    IKBListPrebuiltEntitiesResponse,
    IKBRecommendationPhraseListRequest,
    IKBRecommendationPhraseListResponse,
    IKBTraineKnowledgeBase,
    IKBTraineKnowledgeResponse,
    IKBUpdateEntityFeaturesRequest,
    IKBUpsertEntityRequest,
    IKBUpsertPatternRequest,
    IKBUpsertPhraseListRequest
} from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-request';
import {
    IMLCreateIntent,
    IMLLuisEntity,
    IMLLuisPattern,
    IMLLuisPhraseList,
    IMLLuisPredictEntity,
    IMLLuisPredictResult,
    IMLLuisUtterenceExample,
    TUtteranceArray,
    WebLuisRecommendationResult,
    IKBAppConfig,
    TIUtteranceServerArray,
    IUtteranceServer,
    TMLLuisFeature,
    IAvailablePrebuiltEntity,
    EAvailablePrebuiltEntity,
    ITranslatedAvailablePrebuiltEntity,
    IMLLuisIntent
} from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces';
import {errorCodes} from '@colmeia/core/src/error-control/barrel-error';
import {EPatternSaveResult, IGetUtteranceResult, IUtterance} from 'app/model/knowledge-base.model';
import {isInvalid, isValidRef, isValidArray, isValidArrayWithFilter } from '@colmeia/core/src/tools/utility';
import {GenericNonSerializableService} from './generic-ns.service';
import {Subject} from 'rxjs';
import {IGetAllKBInfoRequest} from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import {isNSServer, toMultipleCursor} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions";
import {SnackDefaultDuration, ISnackSettingsMessage, SnackMessageService, EAppAlertTypes} from './snack-bar';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { SpinType } from './screen-spinner.service';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { prebuiltEntitiesDescriptionTranslations, prebuiltEntitiesExampleTranslations, prebuiltEntitiesNameTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/knowledge-base';
import { GlobalWarningService } from './global-warning.service';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdJob, NewNotificationsService } from 'app/services/new-notifications.service';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { KBSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/kb.shared.service';
import { EMutationType } from '@colmeia/core/src/shared-business-rules/journaling/journaling-req-res.dto';

const baseRoute = routeList.dashboard.children.ai.children.knowledgeBase;

@Injectable({
    providedIn: 'root'
})
export class KnowledgeBaseService extends GenericNonSerializableService {

    private _selectedBase: IKBGetInformationResponse;
    private _selectedTabGroup: number;

    private kbTrain = new Subject();

    constructor(
        api: ServerCommunicationService,
        routeSvc: RoutingService,
        rbs: RequestBuilderServices,
        session: SessionService,
        private snackBar: SnackMessageService,
        private globalWarningSvc: GlobalWarningService,
        private newNotificationsSvc: NewNotificationsService,
    ) {
        super(
            routeList.dashboard.children.ai.path,
            routeList.dashboard.children.ai.children.knowledgeBase,
            { api, rbs, session, routeSvc }
        )
    }

    get kbTrainObservable() {
        return this.kbTrain.asObservable();
    }

    get selectedBase(): IKBGetInformationResponse {
        return this._selectedBase;
    }

    get selectedTabGroup(): number {
        const tab = this._selectedTabGroup;
        this._selectedTabGroup = undefined;
        return tab;
    }

    set selectedTabGroup(tab: number) {
        this._selectedTabGroup = tab;
    }

    public async updateEntityFeatures(idKB: string, idEntity: string, features: TMLLuisFeature[]): Promise<boolean> {
        const response = await this.api.doRequest<IKBUpdateEntityFeaturesRequest>(apiRequestType.LUIS.updateEntityFeatures, {
            idEntity,
            idKB,
            features,
        });
        return isValidRef(response);
    }

    public async getBases(): Promise<TIKnowledgeDBServerArray> {
        const request: IKBListRequest = this.baseRequest(apiRequestType.knowledgeBase.listKB);
        const response = await this.send<IKBLlistResponse>(request);

        if (isValidRef(response)) {
            return response.kbs;
        }

        return undefined;
    }

    public getKbListInfoRequest(cursor: string = null, taggable: ITagableSearch): IGetAllKBInfoRequest {
        const request: IGetAllKBInfoRequest = {
            ...this.baseRequest(apiRequestType.knowledgeBase.listKBInfo),
            nsType: null,
            multipleCursor: toMultipleCursor(cursor),
            taggable
        };
        return request;
    }

    public async getKBInfo(idKB: string): Promise<IKBGetInformationResponse> {
        const request: IKBGetInfoRequest = {
            ...this.baseRequest(apiRequestType.knowledgeBase.getInfo),
            idKB: idKB,
        };
        return this.send<IKBGetInformationResponse>(request);
    }

    public async getKBBasesInfos(): Promise<IKBGetInformationResponse[]>{
        const infoList: IKBGetInformationResponse[] = [];
        const bases: TIKnowledgeDBServerArray = await this.getBases();

        const result = bases.map(async (base) => {
            return new Promise<void>(async (resolve) => {
                const kbInfo = await this.getKBInfo(base.idNS);
                infoList.push(kbInfo);
                resolve();
            })
        });

        await Promise.all(result);

        return infoList;
    }

    public async getKBFromIdTranasaction(idTransaction: string): Promise<IGetKbFromIdTransactionResponse> {
        const request: IGetKBFromIdTransactionRequest = {
            ...this.baseRequest(apiRequestType.knowledgeBase.getNLPConfigAndIntents),
            idTransaction
        };
        return await this.send<IGetKbFromIdTransactionResponse>(request);
    }

    public async updateKB(idKB: string): Promise<IKBGetInformationResponse> {
        const request: IKBGetInfoRequest = {
            ...this.baseRequest(apiRequestType.knowledgeBase.getInfo),
            idKB: idKB
        };
        return this.send<IKBGetInformationResponse>(request);
    }

    public async editNLPConfig(idKB: string, config: INLPConfig): Promise<boolean> {
        let shouldProcessUtterancesAfterEdit = false;

        await this.globalWarningSvc.askMessage(
            {
                title: "Confirmação", message: "Deseja processar as utterances após a edição?", yes: () => {
                    shouldProcessUtterancesAfterEdit = true;
                }, no: () => {
                    shouldProcessUtterancesAfterEdit = false;
                }
            }        );

        await this.api.safeSendRequest<IKBEditNLPRequest, IKBEditNLPResponse>(apiRequestType.knowledgeBase.editNLPConfig)({
            nlpConfig: config,
            idKB,
            shouldProcessUtterancesAfterEdit,
        });

        return true;
    }

    async processUtterances(idKB: IdDep<ENonSerializableObjectType.knowledgeBase>) {
        const response = await this.api.safeSendRequest<IKBProcessUtterancesRequest, IKBProcessUtterancesResponse>(apiRequestType.knowledgeBase.processUtterances)({
            idKB,
        });
        await this.setJob(response.idJob);
        return response.idJob;
    }

    setJob(idJob: IdJob) {
        return this.newNotificationsSvc.setJob({
            idJob,
            retry: {
                shouldRetry: false,
            }
        });
    }


    public async getCompleteSyntaxAnalysis(text: string): Promise<ICloudNaturalLanguageSyntaxWithPartOfSpeech[]> {
        const request: IGetCompleteSyntaxAnalysisRequest  = {
            ...this.baseRequest(undefined),
            requestType: EKnowledgeBaseRequest.getCompleteKBTextSyntax,
            text,
        };
        const response: IGetCompleteSyntaxAnalysisResponse = await this.send<IGetCompleteSyntaxAnalysisResponse>(request);
        return response.analysis;
    }

    public async applyLinguisticChangesOnSentence(idKB: string, sentence: string): Promise<string> {
        const request: IGetSentenceTestAnalysisRequest = {
            ...this.baseRequest(undefined),
            requestType: EKnowledgeBaseRequest.getSentenceTestAnalysis,
            sentence,
            idKB
        };
        const response: IGetSentenceTestAnalysisResponse = await this.send<IGetSentenceTestAnalysisResponse>(request);
        if(! response.friendlyError.okState) {

        }
        return response.result;
    }

    public async testSentencesWithConfig(texts: string[], nlpConfig: INLPConfig): Promise<string[]> {
        const request: IGetUtteranceExampleTestAnalysisRequest  = {
            ...this.baseRequest(undefined),
            requestType: EKnowledgeBaseRequest.getUtteranceExampleTestAnalysis,
            texts,
            nlpConfig,
        };

        const response: IGetUtteranceExampleTestAnalysisResponse = await this.send<IGetUtteranceExampleTestAnalysisResponse>(request);
        if(!response.friendlyError.okState){
            this.snackBar.open(<ISnackSettingsMessage>{
                message:"Erro ao analisar",
                duration: SnackDefaultDuration.Long,
                type: EAppAlertTypes.Error
            })
            // throwErrorIfTrue(true,"10", true,"testSentencesWithConfig", ["Erro ao analisar"])
            return []
        }
        return response.texts;
    }

    public async editKBName(idKB: string, name: string): Promise<boolean> {
        const request: IKBEditNameRequest = {
            ...this.baseRequest(apiRequestType.knowledgeBase.editKBName),
            idKB,
            name,
        };
        return this.isSuccessfull(request);
    }

    public async saveKB(kb: IKnowledgeDB) {
        const response = await this.api.sendRequest<IKBCreateRequest, IKBCreateResponse>(apiRequestType.knowledgeBase.saveKB)({
            saveType: EMutationType.create,
            ns: kb         
        });
        return response?.idNS;
    }

    public async editKB(edit: IKnowledgeDBServer): Promise<boolean | undefined> {
        const response = await this.api.sendRequest<IKBCreateRequest, IKBCreateResponse>(apiRequestType.knowledgeBase.saveKB)({
            saveType: EMutationType.edit,
            ns: edit         
        });
        return isValidRef(response);
    }

    async getPredictions(texts: string[], idKB: string): Promise<IMLLuisPredictResult[]> {
        const request: IKBPredictRequest = {
            ...this.baseRequest(apiRequestType.LUIS.predict),
            idKB,
            texts
        }
        const response = await this.send<IKBPredictResponse>(request);

        if (isValidRef(response)) {
            return response.result;
        }

        return null;
    }

    async getTestPredictions(text: string, idKB: string): Promise<IKBTestPredictionsResponse | null> {
        const request: IKBTestPredictionsRequest = {
            ...this.baseRequest(apiRequestType.LUIS.testPredictions),
            idKB,
            text
        }
        const response = await this.send<IKBTestPredictionsResponse>(request);

        if (isValidRef(response)) {
            return response;
        }

        return null;
    }

    async trainKnowledgeBase(idKB: string){
        const response = await this.api.safeSendRequest<IKBTraineKnowledgeBase, IKBTraineKnowledgeResponse>(apiRequestType.LUIS.train)({
            idKB,
        });
        this.kbTrain.next();
        return response;
    }

    async loadRecommendedValues(phraseList: IMLLuisPhraseList, prevRecommendation: any, idKB: string): Promise<WebLuisRecommendationResult> {
        const request: IKBRecommendationPhraseListRequest = {
            ...this.baseRequest(apiRequestType.LUIS.recommendationPhraseList),
            idKB,
            phraseList,
            TopN: 10,
            PreviousResult: prevRecommendation
        }
        const response = await this.send<IKBRecommendationPhraseListResponse>(request);
        if (isValidRef(response)) {
            return response.recommendation;
        }

        return null;
    }

    async savePhraseList(idKB: string, phraseList: IMLLuisPhraseList): Promise<boolean> {
        const request: IKBUpsertPhraseListRequest = {
            ...this.baseRequest(apiRequestType.LUIS.upsertPhraseList),
            idKB,
            phraseList
        }
        return this.isSuccessfull(request);
    }

    async deletePhraseList(idPhraseList: number, idKB: string) {
        const request: IKBDeletePhraseListRequest = {
            ...this.baseRequest(apiRequestType.LUIS.deletePhraseList),
            idKB,
            idPhraseList
        }
        return this.isSuccessfull(request);
    }

    async saveEntity(idKB: string, entity: IMLLuisEntity, idParentEntity?: string): Promise<boolean> {
        const request: IKBUpsertEntityRequest = {
            ...this.baseRequest(apiRequestType.LUIS.upsertEntity),
            idKB,
            entity,
            idParentEntity,
        }
        return this.isSuccessfull(request);
    }

    private mapEntityToTranslated: Map<IAvailablePrebuiltEntity, ITranslatedAvailablePrebuiltEntity> = new Map();
    public getPrebuildTranslations(entity: IAvailablePrebuiltEntity): ITranslatedAvailablePrebuiltEntity {
        if (!this.mapEntityToTranslated.has(entity)) {
            const translatedEntity: ITranslatedAvailablePrebuiltEntity = {
                type: entity.name as EAvailablePrebuiltEntity,
                name: entity.name, //Serializable.getTranslation(prebuiltEntitiesNameTranslations[entity.name]),
                description: entity.description, //Serializable.getTranslation(prebuiltEntitiesDescriptionTranslations[entity.name]),
                examples: entity.examples, //Serializable.getTranslation(prebuiltEntitiesExampleTranslations[entity.name]),
            };
            this.mapEntityToTranslated.set(entity, translatedEntity);
        }
        return this.mapEntityToTranslated.get(entity);
    }

    async listTranslatedPrebuiltEntities(idKB: string): Promise<ITranslatedAvailablePrebuiltEntity[]> {
        const response = await this.api.doRequest<IKBListPrebuiltEntitiesRequest>(apiRequestType.LUIS.listPrebuiltEntities, {
            idKB,
        }) as IKBListPrebuiltEntitiesResponse

        return response.prebuiltEntities.map(entity => this.getPrebuildTranslations(entity));
    }

    async deleteEntity(idKB: string, idEntity: string): Promise<boolean> {
        const request: IKBDeleteEntityRequest = {
            ...this.baseRequest(apiRequestType.LUIS.deleteEntity),
            idKB,
            idEntity
        }
        return this.isSuccessfull(request);
    }

    async savePattern(idKB: string, pattern: IMLLuisPattern): Promise<EPatternSaveResult> {
        const request: IKBUpsertPatternRequest = {
            ...this.baseRequest(apiRequestType.LUIS.upsertPattern),
            idKB,
            pattern
        }
        const clientInfraResponse = await this.api.managedRequest(this.buildInfra(), request, false);
        if (!clientInfraResponse.executionOK) {
            const error = clientInfraResponse.friendlyMessage.getFriendlyArray()[0];
            if (error.errorID === errorCodes.server.luis.wrongPatternUpsert) {
                const missingError = "Error: A pattern must contain at least one entity reference, an optional text field or a group text field."
                if (error.customMessage === missingError) {
                    return EPatternSaveResult.errorMissingEntityOrText;
                }
                return EPatternSaveResult.errorWrongSyntax;
            }
            return EPatternSaveResult.error
        }
        return EPatternSaveResult.success;
    }

    async deletePattern(idKB: string, idPattern: string): Promise<boolean> {
        const request: IKBDeletePatternRequest = {
            ...this.baseRequest(apiRequestType.LUIS.deletePattern),
            idKB,
            idPattern
        }
        return this.isSuccessfull(request);
    }

    async createIntents(intents: IMLCreateIntent[], idKB: string): Promise<string[] | undefined> {
        const response = await this.api.sendRequest<ICreateIntentRequest, ICreateIntentResponse>(apiRequestType.LUIS.saveIntent)({
            idKB,
            intents,
        });
        return response?.intentIDs;
    }

    async saveUtterance(
        utterances: IUtteranceServer[],
        idKB: string,
        appName: string,
        markAsProcessedNlpActivities?: string[],
        spin?: SpinType
    ): Promise<boolean> {
        utterances.map(utterance => utterance.nsType = ENonSerializableObjectType.utterance);

        const request: ICreateUtteranceRequest = {
            ...this.baseRequest(apiRequestType.LUIS.saveUtterance),
            idKB,
            appName,
            utterances,
            markAsProcessedActivities: isValidArrayWithFilter(markAsProcessedNlpActivities) ? markAsProcessedNlpActivities : []
        }
        return this.isSuccessfull(request, spin);
    }

    async deleteUtterance(utterance: IUtterance, idKB: string): Promise<boolean> {
        const request: IKBPredictDeleteUtterance = {
            ...this.baseRequest(apiRequestType.LUIS.deleteUtterance),
            idKB,
            utterance: utterance.utterance
        };
        return this.isSuccessfull(request);
    }

    async getUtterances(
        idKB: string,
        intent: IMLLuisIntent | IIntentCount,
        cursor?: string,
    ):Promise<IGetUtteranceResult> {
        const response = await this.api.safeSendRequest<IGetUtterancesRequest, IGetUtterancesResponse>(apiRequestType.LUIS.getUtterances)({
            idKB,
            intentId: intent.intentId,
            cursor,
        });

        const result: IGetUtteranceResult = {
            cursor: response.cursor,
            utterances: response.utterances
            .map(utterance => {
                return {
                    editing: false,
                    saving: false,
                    oldExample: utterance.utterance.example,
                    utterance,
                    score: utterance.utterance.lastScore!,
                }
            }),
        }

        return result;
    }

    goToKBDetails(knowledgeBase: IKBGetInformationResponse) {
        this._selectedBase = knowledgeBase;
        super.goToDetails(knowledgeBase.kb);
    }

    goToDetailsID(id: string) {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.ai.path,
            baseRoute.path,
            id,
             `details`
        );
    }

    goToListKB(): void {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.ai.path,
            baseRoute.path
        );
    }

    cloneKB(kb: IKnowledgeDBServer) {
        return KBSharedService.cloneKB(kb);
    }
}
