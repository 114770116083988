<div class="field" [class.valid]="_isValid" [class.invalid]="isInvalid" [class.has-hint]="hintLabel">
  <mat-form-field class="landing-input" appearance="fill">
    <mat-label>{{ placeholderName }}</mat-label>
    <input
      class="data-input__item"
      matInput
      #inputText
      [type]="getType()"
      [ngModel]="typedText"
      [autocomplete]="autocomplete"
      (keydown.enter)="onEnterKeyPressed($event)"
      (ngModelChange)="saveTypedText($event)">
    <mat-hint align="start">{{hintLabel}}</mat-hint>
    <button *ngIf="canShowIcon()" (click)="onIconClick()" class="icon" [matTooltip]="isValid() ? '' : invalidMessage">
      <mat-icon>{{ icon }}</mat-icon>
    </button>
  </mat-form-field>
</div>

