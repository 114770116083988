import {Interaction} from "@colmeia/core/src/interaction/interaction";
import {IComponentParameter} from "../model/component-comm/basic";
import {MainHandler} from "./main-handler";
import {IMessagesContainerComponent} from "../components/chat/message-container/message-container.component";
import { InjectionToken } from "@angular/core";

export enum EChatBackboneViewType {
    Chat,
    ChatFeatureView,
    FeaturePreview,
    FeatureCreator,
}

export interface IClickedInteractionOwner {
    getClickedInteraction(): Interaction;
};

export interface IRootInteractionOwner {
    getRootInteraction(): Interaction;
}

export interface IAnyVisualizationOwner {
    isInAnyVisualization(): boolean;
}

export interface IClickableInteractionChildOwner extends IAnyVisualizationOwner, IRootInteractionOwner, IClickedInteractionOwner {}

export interface IChatBackboneInputVisualization {
    isInChat(): boolean;
    isInFeatureCreation(): boolean;
    isInChatFeatureVisualization(): boolean;
}

export interface IChatBackboneInputData extends IClickableInteractionChildOwner, IChatBackboneInputVisualization {
    isInFeatureVisualization(): boolean;
    isInAnyChat(): boolean;
};

export const BackboneInputDataToken = new InjectionToken<IChatBackboneInputData>("chatBackboneInputData");

export interface IChatBackboneInputMsgComponent extends IChatBackboneInputData {
    setMsgContainerInstance(instance: IMessagesContainerComponent): void;
    removeMsgContainerInstance(): void;
}

export interface IMsgContainerGetter {
    getMsgContainerInstance(): IMessagesContainerComponent
}

export interface IChatBackboneInputMsgInstance extends IChatBackboneInputData, IMsgContainerGetter {
}

export interface IChatBackboneParameter extends IComponentParameter {
    rootInteraction: Interaction;
    viewType: EChatBackboneViewType;
    clickedInteraction: Interaction;
}

export class ChatBackboneHandler extends MainHandler implements
    IChatBackboneInputData,
    IChatBackboneInputMsgComponent,
    IMsgContainerGetter,
    IClickableInteractionChildOwner
{
    getClickedInteraction(): Interaction {
        return this.getComponentParameter().clickedInteraction;
    }

    private msgContainerInstance: IMessagesContainerComponent;

    static new(param: IChatBackboneParameter) {
        return new ChatBackboneHandler(param)
    }

    private constructor(parameters: IChatBackboneParameter) {
        super(parameters);
    }

    getComponentParameter(): IChatBackboneParameter {
        return <IChatBackboneParameter>super.getComponentParameter();
    }

    setViewType(viewType: EChatBackboneViewType): void {
        this.getComponentParameter().viewType = viewType;
    }

    getRootInteraction(): Interaction {
        return this.getComponentParameter().rootInteraction;
    }

     isInAnyVisualization(): boolean {
        return this.isInFeatureVisualization()
    }

    isInChat(): boolean {
        return this.getViewType() == EChatBackboneViewType.Chat
    }

    isInFeatureCreation(): boolean {
        return this.getViewType() == EChatBackboneViewType.FeatureCreator
    }

    isInAnyChat(): boolean {
        return this.isInChat() || this.getViewType() == EChatBackboneViewType.FeatureCreator
    }

    isInFeatureVisualization(): boolean {
        return this.getViewType() == EChatBackboneViewType.FeaturePreview
            || this.getViewType() == EChatBackboneViewType.ChatFeatureView;
    }

    isInChatFeatureVisualization(): boolean {
        return this.getViewType() == EChatBackboneViewType.ChatFeatureView
    }

    private getViewType(): EChatBackboneViewType {
        return this.getComponentParameter().viewType;
    }

    setMsgContainerInstance(instance: IMessagesContainerComponent): void {
        this.msgContainerInstance = instance;
    }

    removeMsgContainerInstance(): void {
        this.msgContainerInstance = null;
    }

    getMsgContainerInstance(): IMessagesContainerComponent {
        return this.msgContainerInstance;
    }
}
