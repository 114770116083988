import { IFileSystemLayer } from '../hardware-interfaces';
import { Injectable } from '@angular/core';

declare global {
    interface Window {
        VanillaFile: File;
    }
}

@Injectable({
    providedIn: 'root'
})
export class CordovaFilesystem implements IFileSystemLayer{

    private originalFile: any;

    constructor() {
        this.originalFile = window.VanillaFile;
    }

    newFile(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag): File {
        return new this.originalFile(fileBits, fileName, options);
    }
}
