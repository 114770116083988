import { Component, OnInit } from '@angular/core';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import {IMapsHandler} from "../../../handlers/maps-handler";

@Component({
  selector: 'app-service-request-location-retriever',
  templateUrl: './service-request-location-retriever.component.html',
  styleUrls: ['./service-request-location-retriever.component.scss']
})
export class ServiceRequestLocationRetrieverComponent implements OnInit {

    public static interaction: Interaction = null;
    public mapsHandler: IMapsHandler;

    constructor() { }

    ngOnInit() {
        const coordinates = ServiceRequestLocationRetrieverComponent.interaction.getCoordinates();
        console.log(ServiceRequestLocationRetrieverComponent.interaction);
        this.mapsHandler = {
            locationsReceived: (isValidRef(coordinates)) ? [coordinates] : [],
        };
    }

    getMapsHandler(): IMapsHandler {
        return this.mapsHandler;
    }

}
