import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import { Participant } from '@colmeia/core/src/business/participant';
import { IPublicMedium } from '@colmeia/core/src/comm-interfaces/billing-interfaces';
import { PaymentMediumRegister } from '@colmeia/core/src/interaction/billable/payment-medium';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { isValidCPF } from '@colmeia/core/src/shared-business-rules/user-function/user-function.utils';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { MediumServices } from "../../../../services/billing/medium";
import { GroupChatServices } from "../../../../services/group-chat.services";
import { ScreenSpinnerService, SpinType } from "../../../../services/screen-spinner.service";
import { SessionService } from "../../../../services/session.service";
import {
    SnackDefaultDuration,
    ISnackSettingsMessage,
    SnackMessageService,
    EAppAlertTypes, SnackVerticalPosition
} from "../../../../services/snack-bar";
import { SettingsModalService } from "../../settings-modal/settings-modal.service"; // import just one function

export type PaymentMediumScreens = 'list' | 'edit' | 'create';

@Component({
    selector: 'cm-payment-medium-creator',
    templateUrl: './payment-medium-creator.component.html',
    styleUrls: ['./payment-medium-creator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentMediumCreatorComponent extends RootComponent<'remove' | 'add' | 'addCard' | 'expires' | 'fullName'> implements OnInit {

    private nameRegex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    private expiryRegex = /^((0[1-9])|(1[0-2]))\/((2009)|(20[1-9][0-9]))$/;
    private CVVRegex = /^[0-9]{3,4}$/;
    public cpfMASK = '000.000.000-00';//[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    public expiryMASK = 'M0/0000';//[/[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];
    private _number = '';
    private _name = '';
    private _expiry = '';
    private _cvc = '';
    private _document = '';

    /*
    * Edit
    */
    private _eid = null;
    private _eexpiry = '';
    public _editingMedium: IPublicMedium;
    private creatingInteraction: PaymentMediumRegister;
    public mediums: Array<IPublicMedium> = [];
    private screen: PaymentMediumScreens = 'list';

    private isValidCreditCard: boolean = false;

    public codeSize: number = 4;

    constructor(
        private sessionService: SessionService,
        private mediumService: MediumServices,
        private cdr: ChangeDetectorRef,
        private chatSVC: GroupChatServices,
        private spin: ScreenSpinnerService,
        private settingsModalService: SettingsModalService,
        private snackMessage: SnackMessageService
    ) {
        super({

            'remove': gTranslations.common.remove,
            'add': gTranslations.common.add,
            'addCard': gTranslations.sellable.addCard,
            'expires': gTranslations.sellable.expires,
            'fullName': gTranslations.common.fullName,
        });

        this.generateCreatingInteraction();
    }

    async ngOnInit() {
        this.mediums = await this.settingsModalService.getPaymentMedium();
        this.cdr.markForCheck();
    }

    private async fetchMediums(): Promise<void> {
        this.mediums = [];
        this.mediums = await this.mediumService.getAllPublicMediums();
        this.cdr.markForCheck();
    }

    get eexpiry(): string {
        return this._eexpiry;
    }
    set eexpiry(val: string) {
        this._eexpiry = val;
    }

    get document(): string {
        return this._document;
    }

    set document(doc: string) {
        this._document = doc;
        this.creatingInteraction.setPaymentDocument(doc);
    }

    get number(): string {
        return this._number;
    }

    set number(n: string) {
        this._number = n;
        this.creatingInteraction.setPaymentCard(n);
    }

    get name(): string {
        return this._name;
    }

    set name(n: string) {
        this._name = n;
        this.creatingInteraction.setPaymentCardHolderName(n);
    }

    get expiry(): string {
        return this._expiry;
    }

    set expiry(n: string) {
        this._expiry = n;
        this.creatingInteraction.setPaymentExpiry(n);
    }

    get cvc(): string {
        return this._cvc;
    }

    set cvc(n: string) {
        this._cvc = n;
        this.creatingInteraction.setPaymentCvc(n);
    }

    private validate(): boolean {

        if (!this.nameRegex.test(this.name)) {
            this.promptError('Nome inválido');
            return false;
        }

        if (!isValidCPF(this.document)) {
            this.promptError('CPF inválido');
            return false;
        }

        if (!this.isValidCreditCard) {
            this.promptError('Cartão de crédito inválido');
            return false;
        }

        if (!this.expiryRegex.test(this.expiry)) {
            this.promptError('Validade incorreta');
            return false;
        }

        if (!this.CVVRegex.test(this.cvc) || this.cvc.length != this.codeSize) {
            this.promptError('Código de segurança do cartão incorreto');
            return false;
        }

        return true;
    }

    private promptError(str: string) {
        const msg: ISnackSettingsMessage = {
            message: str,
            duration: SnackDefaultDuration.Long,
            type: EAppAlertTypes.Error,
            vertical: SnackVerticalPosition.Top
        };
        this.snackMessage.open(msg);
    }

    public async create(): Promise<void> {
        if (this.validate()) {
            this.showSpinner();
            await this.mediumService.create(this.creatingInteraction);
            this.clearCreateForm();
            await this.fetchMediums();
            this.hideSpinner();
        }
    }

    public clearCreateForm(): void {
        this._cvc = '';
        this._expiry = '';
        this._name = '';
        this._number = '';
        this.generateCreatingInteraction();
        this.screen = 'list';
    }

    private generateCreatingInteraction() {
        const participant: Participant = this.sessionService.getPlayerPersonalGroupParticipant();
        this.creatingInteraction = PaymentMediumRegister.getNewPaymenetRegister(participant);
    }

    public switchToEditScreen(primaryID: string): void {
        const edit = this.mediums.find((el) => {
            return el.interactionID === primaryID;
        });
        if (edit) {
            this._eexpiry = edit.expiry;
            this._eid = primaryID;
            this._editingMedium = edit;
        }
        this.screen = 'edit';
    }

    public isCurrentScreen(screen: string): boolean {
        return screen === this.screen;
    }

    public switchToCreateScreen(): void {
        this.screen = 'create';
    }

    public swithToListScreen(): void {
        this.screen = 'list';
    }

    onCardChange = (raw: string, val: string, brand: string, codeSize: number, valid: boolean) => {
        this.number = raw;
        this.creatingInteraction.setPaymentCardBrand(brand)
        this.isValidCreditCard = valid;
        this.codeSize = codeSize;
    }

    async removeMedium(): Promise<void> {
        this.showSpinner();
        const interaction: PaymentMediumRegister = await this.mediumService.getRealMedium(this._eid);
        await this.chatSVC.deleteInteraction(interaction, interaction.getParticipant());
        await this.fetchMediums();
        this.screen = 'list';
        this.hideSpinner();
    }

    private showSpinner(): void {
        this.spin.show({
            show: true,
            spinType: SpinType.fullScreen
        });
    }

    private hideSpinner(): void {
        this.spin.show({
            show: false,
            spinType: SpinType.fullScreen
        });
    }
}
