<div class="wrapper">
  <header>
    <h2>{{ title }}</h2>
    <button matDialogClose (click)="onCloseClicked()">
      <i class="material-icons i--md">close</i>
    </button>
  </header>
  <hr />
  <ng-content></ng-content>
</div>
