import { TSResultRecordIdPropertyMap } from "@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-results-interface";
import { TIdPropertyToCanonicalMap } from "@colmeia/core/src/shared-business-rules/info-square/info-square-req-resp";
import { EMetadataNames } from "@colmeia/core/src/shared-business-rules/metadata/metadata-db";
import { ICustomerCreationFullfiledFields } from "./customer-finder.handler.def";
import { isValidRef } from "@colmeia/core/src/tools/barrel-tools";

const metaNameToCreationField: { [key in EMetadataNames]?: keyof ICustomerCreationFullfiledFields } = {
  [EMetadataNames.client]: "name",
  [EMetadataNames.name]: "name",
  [EMetadataNames.email]: "email",
  [EMetadataNames.cellular]: "phoneNumber",
  [EMetadataNames.channelAddress]: "phoneNumber",
  [EMetadataNames.facebookID]: "fbId",
  [EMetadataNames.colmeiaID]: "colmeiaId",
}

export function fullfilCustomerCreationObject(
  customerInfo: ICustomerCreationFullfiledFields,
  idPropertyCanonicalMap: TIdPropertyToCanonicalMap,
  rowMap: TSResultRecordIdPropertyMap
) {
  for (const idProperty in rowMap) {
    if (!(idProperty in idPropertyCanonicalMap)) continue;

    const customerInfoProp: string = metaNameToCreationField[idPropertyCanonicalMap[idProperty]];

    if (!isValidRef(customerInfoProp)) continue;

    customerInfo[customerInfoProp] = rowMap[idProperty].value;
  }
}
