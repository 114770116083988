<div class="container" [class.main-action]="mainAction" [ngStyle]="customContainerStyle" [ngClass]="customClasses" (click)="mainAction ? callMainAction() : null">
  <div class="content">
    <div
      *ngIf="matIcon || iconUrl || hexagon"
      class="icon-container"
      [class.is-mat-icon]="matIcon"
      [class.url-icon]="iconUrl"
      [class.hexagon-icon]="hexagon"
    >
      <mat-icon *ngIf="matIcon" class="mat-24">{{ matIcon }}</mat-icon>
      <img *ngIf="iconUrl" [src]="iconUrl" />
      <hexagon *ngIf="hexagon" [hexagonHandler]="hexagon"></hexagon>
    </div>
    <div class="text-content">
      <h5 *ngIf="label" class="label">
        <app-bbcode-viewer [text]="label"></app-bbcode-viewer>
      </h5>
      <p *ngIf="message" class="message">
        <app-bbcode-viewer [text]="message"></app-bbcode-viewer>
      </p>
    </div>
  </div>
  <button class="close-button" (click)="close($event)">
    <mat-icon>close</mat-icon>
  </button>
  <span *ngIf="hasAutoClose" class="autoclose-indicator" [style.animation-duration]="duration+'ms'"></span>
</div>