import { constant, TGlobalUID } from '../business/constant';
import { Serializable } from '../business/serializable';
import { IRoleJSON } from '../comm-interfaces/business-interfaces';
import { ERoleConstant, permission } from '../core-constants/security-constant';
import { TArrayID } from '../core-constants/types';
import { InteractionType, TInteractionTypeArray } from '../interaction/interaction-type';
import { UberCache } from '../persistency/uber-cache';
import { hasPermissionInRolesIDs } from '../rules/sec-functions';
import { hashToArray, isThisOneOfThat } from '../tools/utility';
import { Permission, TPermissionArray } from './permission';
import { IHashTableAlias } from '../tools/utility-types';

export type TRoleArray = Array<Role>;


const rolesAdminCantHave = {
    [ERoleConstant.Root]: true,
    [ERoleConstant.Nright]: true,
    [ERoleConstant.archiverRole]: true,
}


const notMNodifyableToAdmin = {
    ...rolesAdminCantHave,
    [ERoleConstant.Admin]: true,
}


const notGrantAbleRule = {
    ...rolesAdminCantHave,
}

export interface ISecurityRoleChanges {
    idAvatar: TGlobalUID;
    roles: TArrayID;
};

export class Role extends Serializable {
    private interactionsTypeAllowedHash: IHashTableAlias = {};
    private permissionHash: IHashTableAlias = {};
    private alias : string;
    private static roleArray: TRoleArray = [];

    private constructor (idRole: TGlobalUID, alias: string, name: string,  tooltip: string) {
        super(constant.objectType.role, idRole);
        this.setName(name);
        this.setTooltip(tooltip);
        this.alias = alias;
        Role.roleArray.push(this);
    };


    public sameRole(role: Role): boolean {return super.getPrimaryID() == role.getRoleID(); };
    public addPermission(permission: Permission) {this.permissionHash[permission.getPermissionID()] = permission;}
    public getPermissionArrayList(): IHashTableAlias {return this.permissionHash;};
    public getRoleID(): TGlobalUID {return super.getPrimaryID();};

    public addAllowedInteractionType(interactionType: InteractionType) {
        this.interactionsTypeAllowedHash[interactionType.getInteractionTypeID()] = interactionType;
    };

    public hasPermission(idPermission: TGlobalUID): boolean {
        return this.permissionHash[idPermission]? true: false;
    };

    public isAuthorizedInteraction(idInteractionType: TGlobalUID) {
        return this.interactionsTypeAllowedHash[idInteractionType]? true: false; 
    };

    public toJSON(): IRoleJSON {
        let json: IRoleJSON = <IRoleJSON>super.toJSON();
        json.alias = this.alias;
        json.interactionsTypeAllowedArray =[];
        json.permissionsArray = [];
        for (let interactionType of <TInteractionTypeArray>hashToArray(this.interactionsTypeAllowedHash)) {
            json.interactionsTypeAllowedArray.push(interactionType.getInteractionTypeID());
        };
        for (let permission of <TPermissionArray>hashToArray(this.permissionHash)) {
            json.permissionsArray.push(permission.getPrimaryID());
        };
        return json;
    };

    public rehydrate(json: IRoleJSON): void {
        super.rehydrate(json);
        this.alias = json.alias;
        this.permissionHash = {};
        for (let idPerm of json.permissionsArray) 
            this.permissionHash[idPerm] =  Permission.staticFactory(idPerm);
        this.interactionsTypeAllowedHash = {};
        for (let idInteractionType of json.interactionsTypeAllowedArray)
            this.interactionsTypeAllowedHash[idInteractionType] = InteractionType.staticFactory(idInteractionType);
    };

    public static factoryMessage(json: IRoleJSON): Role {
        let role: Role = Role.staticFactory(json.primaryID);
        role.rehydrate(json);
        return role;
    };

    public static plainFactoryMessage(json: IRoleJSON): Role {
        let role: Role = <Role>UberCache.uberFactory(json.primaryID, constant.objectType.role, false);
        if (role == null) 
            role = new Role(json.primaryID, json.alias, 
                Role.getJText(json, constant.serializableField.name), 
                Role.getJText(json, constant.serializableField.tooltip));
        return role;

    }

    public static staticFactory(idRole: TGlobalUID): Role {
        return <Role>UberCache.uberFactory(idRole, constant.objectType.role, true);
    };

    public static getPossibleRolesArray(grantor: ISecurityRoleChanges, grantee: ISecurityRoleChanges, idAvatarApprover: TGlobalUID): TRoleArray {
        const isGrantorRoot: boolean = hasPermissionInRolesIDs(grantor.roles, permission.canExcludeAdmin);
        const sameUser: boolean = grantor.idAvatar === grantee.idAvatar;
        

        if (isThisOneOfThat(idAvatarApprover, grantee.idAvatar, grantor.idAvatar)) {
            return [Role.staticFactory(ERoleConstant.Root)];
        };

        if (isGrantorRoot) {
            return Role.getActiveRolesArray().filter((x) => {return !notGrantAbleRule[x.getRoleID()]});
        };

        if (hasPermissionInRolesIDs(grantor.roles, permission.hasAdminPrivilege)) {
            if (hasPermissionInRolesIDs(grantee.roles, permission.hasAdminPrivilege)) {
               return Role.getActiveRolesArray().filter((x) => {return !notMNodifyableToAdmin[x.getRoleID()]});
            } else {
               return Role.getActiveRolesArray().filter((x) => {return !notGrantAbleRule[x.getRoleID()]});
            }
        };
        return [];
    }

    public static canRemoveUser(grantor: ISecurityRoleChanges, grantee: ISecurityRoleChanges, idAvatarApprover: TGlobalUID): boolean {
        const isGrantorRoot: boolean = hasPermissionInRolesIDs(grantor.roles, permission.canExcludeAdmin);
        const sameUser: boolean = grantor.idAvatar === grantee.idAvatar;

        if (grantor.idAvatar === idAvatarApprover || sameUser) {
            return false;
        }
        
        if (isGrantorRoot) {
            return true;
        }        
        if (hasPermissionInRolesIDs(grantor.roles, permission.hasAdminPrivilege)) {
            if (hasPermissionInRolesIDs(grantee.roles, permission.hasAdminPrivilege)) {
               return false
            } else {
               return true
            }
        }
        return false
    }

    public static getActiveRolesArray(): TRoleArray {
        return Role.roleArray.filter((r) => {return ! r.hasPermission(permission.excludedFromGroup)});
    }

    public static resetRoleArray() {
        Role.roleArray = [];
    }
};