<app-attendance-broadcasting-catcher *ngIf="hasAnyBroadcastIsland()"></app-attendance-broadcasting-catcher>
<app-group-order-selector-shortcut *ngIf="showGroupOrderSelector()"></app-group-order-selector-shortcut>

<div class="all-talking" [ngClass]="{'restricted-width': restrictedWidth}">
  <div class="group-list-container">
    <cdk-virtual-scroll-viewport
      style="height: 100%;"
      itemSize="76"
    >

    <div class="search-bar-container">
      <input 
        matInput
        type="search"
        class="simple-input"
        placeholder="Procurar"
        [(ngModel)]="searchToken"
        (keyup.enter)="emitSearch(searchToken)"
        (ngModelChange)="emitSearch($event)"> 
      <mat-icon class="searchIcon" size="20">search</mat-icon>         
    </div>

      <ng-container *cdkVirtualFor="let handler of shortcuts; trackBy: groupTrackBy; templateCacheSize: 10">
        <a
          class="group-list clickable"
          (click)="goToGroupChat($event, handler.getGroup())"
          [href]="getURL(handler)"
        >
          <app-group-selector-shortcut
            [handler]="handler"
            [restrictedWidth]="restrictedWidth"
            [isCurrentGroup]="isGroupSelected(handler.getGroup())"
          >
          </app-group-selector-shortcut>
        </a>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
