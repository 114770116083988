import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const searchViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Search,
    fields: {
        searchingFor: 1, // Buscando por
        select: 2, // Selecionar
        in: 3, // em
        placeHolderAll: 4 // Busque aqui uma rede social, grupo ou pessoa
    }
} as const);

export const searchViewTranslations = getTranslationsFields(searchViewScreenOptions);