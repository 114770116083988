import { MainHandler } from "../main-handler";
import { INonSerializableHeader, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";



export interface INSPickerSelectableHandlerSlave {
    onUpdate(): void;
    onSwitchNonSerializable(): void;
}

export interface INSPickerSelectableHandlerClientCallback {
    onSelectCallback(nonSerializables: INonSerializableHeader[]): void;
    switchNonSerializableCallback(nonSerializable: INonSerializableHeader): void;
    mapName(ns: INonSerializable): string;
}

export interface INSPickerSelectableHandlerParameter {
    allNonSerializables: INonSerializableHeader[];
    nonSerializables: INonSerializableHeader[];
    allowMultipleNonSerializableSelections: boolean;
    clientCallback: INSPickerSelectableHandlerClientCallback;
}

export interface INSPickerSelectableHandler
    extends
    INSPickerSelectableHandlerSlave,
    INSPickerSelectableHandlerClientCallback,
    INSPickerSelectableHandlerParameter {
    slave: INSPickerSelectableHandlerSlave;
    setSlave(slave: INSPickerSelectableHandlerSlave): void;
}

export class NSPickerSelectablesHandler extends MainHandler implements INSPickerSelectableHandler {

    slave: INSPickerSelectableHandlerSlave;
    allNonSerializables: INonSerializableHeader[];
    nonSerializables: INonSerializableHeader[];
    allowMultipleNonSerializableSelections: boolean;
    clientCallback: INSPickerSelectableHandlerClientCallback;

    constructor(parameter: INSPickerSelectableHandlerParameter) {
        super(parameter);

        this.allNonSerializables = parameter.allNonSerializables;
        this.nonSerializables = parameter.nonSerializables;
        this.allowMultipleNonSerializableSelections = parameter.allowMultipleNonSerializableSelections;
        this.clientCallback = parameter.clientCallback;
    }

    mapName(ns: INonSerializable): string {
        return this.clientCallback.mapName(ns);
    }

    onSwitchNonSerializable(): void {
        this.slave.onSwitchNonSerializable();
    }
    onUpdate(): void {
        this.slave.onUpdate();
    }
    onSelectCallback(nonSerializables: INonSerializableHeader[]): void {
        this.clientCallback.onSelectCallback(nonSerializables);
    }

    safeSetNonSerializables(nonSerializables: INonSerializableHeader[]) {
        this.nonSerializables = [...nonSerializables];

        this.clientCallback.onSelectCallback(nonSerializables);
        this.slave.onUpdate();
    }

    setSlave(slave: INSPickerSelectableHandlerSlave): void {
        this.slave = slave;
    }

    getComponentParameter(): INSPickerSelectableHandlerParameter {
        return super.getComponentParameter() as INSPickerSelectableHandlerParameter;
    }

    switchNonSerializableCallback(nonSerializable: INonSerializableHeader): void {
        this.clientCallback.switchNonSerializableCallback(nonSerializable);
    }
    
}