import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { isValidNumber } from '@colmeia/core/src/tools/utility';
import { Observable, timer } from 'rxjs';
import { tap, debounce } from 'rxjs/operators';
import { ScreenSpinnerService, SpinType, TLoadingState } from "../../services/screen-spinner.service";

/**
 * Global loading component, the whole page loading
 */
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  public loading$: Observable<boolean>;
  public type$: Observable<SpinType>;
  public SpinType: typeof SpinType = SpinType;

  constructor(private cdr: ChangeDetectorRef, private screenLoadingSvc: ScreenSpinnerService) {
    this.loading$ = this.screenLoadingSvc.loading$.pipe(debounce(() => timer(0)), tap(() => this.cdr.markForCheck()));
    this.type$ = this.screenLoadingSvc.lastType$;
  }

  public getLoadingState(): TLoadingState {
    return this.screenLoadingSvc.getLoadingState()
  }

  public hasFullOrHalf(): boolean {
    const loadingState = this.screenLoadingSvc.getLoadingState();
    return isValidNumber(loadingState.fullScreen || loadingState.halfScreen);
  }

  public hasSocialNavigation(): boolean {
    return isValidNumber(this.screenLoadingSvc.getLoadingState().socialNetworkChange);
  }
}
