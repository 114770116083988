import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Memoize } from 'typescript-memoize';
import { TExtendedParticipantArray } from '@colmeia/core/src/core-constants/types';
import { Participant } from "@colmeia/core/src/business/participant";
import { WalletTransferInteraction } from "@colmeia/core/src/interaction/wallet/wallet-transfer";
import { IAmount } from '@colmeia/core/src/comm-interfaces/finance-interfaces';
import {IColmeiaDialogComponentData} from "../dialog/dialog.component";
import {GroupChatServices} from "../../../services/group-chat.services";
import {SessionService} from "../../../services/session.service";
import {
    EParticipantClickTypeMode,
    EParticipantSelectorAction,
    ParticipantSelectorHandler
} from "../../../handlers/participant-selector.handler";
import {ECmBtnColorType} from "../../foundation/cm-button/cm-button.component";
import {ESearchAllowedType} from "@colmeia/core/src/shared-business-rules/search";

export interface ITransferDialogHandler {
    onSendTransferCallback(): void;
    balance: number;
}

export interface ITransferDialogData {
    handler: ITransferDialogHandler;
}

@Component({
    selector: 'app-transfer-dialog',
    templateUrl: './transfer-dialog.component.html',
    styleUrls: ['./transfer-dialog.component.scss']
})
export class TransferDialogComponent implements OnInit {

    private selected: TExtendedParticipantArray;

    private handler: ITransferDialogHandler;

    public labels = {
        confirm: 'Confirmar',
        amountTitle: 'Digite o valor',
    };

    public value = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) dataFromParentComponent: IColmeiaDialogComponentData<ITransferDialogData>,
        private groupSVC: GroupChatServices,
        private session: SessionService
    ) {
        this.handler = dataFromParentComponent.getParamsToChildComponent().handler;
    }

    ngOnInit() {
    }

    @Memoize()
    getParticipantSelectorHandler(): ParticipantSelectorHandler {
        return ParticipantSelectorHandler.new({
            typesOfActionsOnEachElementSelected: [
                EParticipantSelectorAction.Remove
            ],
            typesAllowed: [ESearchAllowedType.people],
            alreadySelectedList: [],
            clientCallback: this,
            operationMode: EParticipantClickTypeMode.SendToParent,
            maxNumberOfParticipants: 1,
            isEditableTypesSelected: false
        });
    }

    onParticipantsSelected(
        selected: TExtendedParticipantArray,
        excluded: TExtendedParticipantArray
    ): void {
        this.selected = selected;
    }

    async submitTransfer(): Promise<void> {
        if(!this.canShowConfirmButton())
            return Promise.resolve()

        const participant: Participant = this.session.getCurrentPersonalParticipant();

        const interaction: WalletTransferInteraction = WalletTransferInteraction.newWalletTransfer(participant, this.selected[0].getPrimaryID(), )

        const am: IAmount = {
            amount: parseInt(this.value),
            currency: 'BRL'
        };

        interaction.setAmountValue(am);

        const response = await this.groupSVC.saveInteraction(
            interaction,
            [],
            null
            );

        if (response.isOk()) {
            this.handler.onSendTransferCallback();
        }
    }

    canShowConfirmButton(): boolean {
        return  this.selected
                && this.handler.balance >= parseInt(this.value);
    }

    getBtnColor(): ECmBtnColorType {
        return this.canShowConfirmButton()
                ? ECmBtnColorType.Green
                : ECmBtnColorType.Grey;
    }
}
