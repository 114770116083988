import { Injectable } from '@angular/core';
import { EDeviceType } from '@colmeia/core/src/business/push-notifications';
import { EEventName, IAuthToken, IMobileNativeData, IMobileRedirect, IPushToken, IReactNativeAudio, IReactNativeLocation, IReactNativeLog, IReactNativeNotification } from '@colmeia/core/src/client-shared/client-native.model';
import { allSeverityRegistry } from '@colmeia/core/src/core-constants/tracker-qualifiers';
import { isValidRef, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { ILocationCoordinates } from '@colmeia/core/src/tools/geo-util';
import { authenticationKey } from 'app/model/constants/client.keys';
import { routeID } from 'app/model/routes/route-constants';
import { getAvailableStorage } from 'polyfill/storage';
import { NavigatorServices } from '../controllers-services/navigator/navigator.service';
import { HardwareLayerService } from '../hardware';
import { InteractionPersistorServices } from '../interaction-persistor.service';
import { PushNotificationsService } from '../push-notifications.service';
import { SnackMessageService } from '../snack-bar';

const GROUP_ID_REGEXP = /colmeiaapp:\/\/interaction\(([\w\D]+),[\w\D]+\)+/


@Injectable({
  providedIn: 'root'
})
export class NativeEventsHandlerService {

  constructor(
    private notificationSVC: PushNotificationsService,
    private hw: HardwareLayerService,
    private navigatorSvc: NavigatorServices,
    private interactionPersistorSvc: InteractionPersistorServices,
    private snackSvc: SnackMessageService
  ) {
    const constructTime = (new Date()).toISOString();
    const _log: IReactNativeLog = {
      data: '',
      identifier: 'INSTANTIATING native-events-handler.service',
      isoDate: constructTime
    };
    this.handleLogEvent(_log);
  }

  async eventSwitcher(eventData: IMobileNativeData, callback: ()=> void) {
    if (!eventData.payload?.eventName) return;

    switch (eventData.payload?.eventName) {
      
      case EEventName.expoPushToken:
        console.log('expoPushToken ', JSON.stringify(eventData, null ,2))
        const _log: IReactNativeLog = {
          data: eventData.payload.data,
          identifier: 'EEventName.expoPushToken',
          isoDate: new Date().toISOString()
        };
        this.handleLogEvent(_log);
        await this.handleExpoPushToken(eventData.payload.data as IPushToken, callback);
        break;

      case EEventName.authToken: 
        console.log('authToken ', JSON.stringify(eventData, null ,2))

        this.handleMobileLogin((eventData.payload.data as IAuthToken).token);
        break;
    
      case EEventName.navigate: 
        console.log('navigate ', JSON.stringify(eventData, null ,2))
        this.handleNavigateEvent((eventData.payload.data as IMobileRedirect).url)
        break;

      case EEventName.location: 
        console.log('location ', JSON.stringify(eventData, null ,2))
        this.handleLocationEvent((eventData.payload.data as IReactNativeLocation));
        break;

      case EEventName.audio: 
        console.log('audio ', JSON.stringify(eventData, null ,2))
        this.handleAudioEvent((eventData.payload.data as IReactNativeAudio));
        break;

      case EEventName.notification: 
        console.log('notification ', JSON.stringify(eventData, null ,2))
        const log: IReactNativeLog = {
          data: eventData.payload.data,
          identifier: 'EEventName.notification',
          isoDate: new Date().toISOString()
        };
        this.handleLogEvent(log);
        this.handleNotificationEvent((eventData.payload.data as IReactNativeNotification));
        break;

      case EEventName.log: 
        console.log('log ', JSON.stringify(eventData, null ,2))
        this.handleLogEvent((eventData.payload.data as IReactNativeLog))
        break;

      default: console.error('Unhandled case in native-events-handler.service.ts/eventSwitcher using enum EEventName')
    }
  }

  async handleExpoPushToken(data: IPushToken, callback?: ()=> void) {
    if (!isValidRef(data) || !data.pushToken.trim()) return;
    callback?.();

    const expoToken = data.pushToken.trim();
    
    const existingDevices = await this.notificationSVC.getDevices();
    const isDeviceAdded = existingDevices.find(device => device.token === data.pushToken);

    this.hw.setExpoPushNotificationToken(data.pushToken);

    if (isDeviceAdded) {
      return;
    }
    await this.hw.UID$();
    const createDeviceResponse = await this.notificationSVC.createDevice(
      data.deviceName, 
      this.hw.getDeviceUID(),
      data.pushToken,
      EDeviceType.Phone 
    )
  }

  handleMobileLogin(token: string) {
    const storage: Storage = getAvailableStorage();
    storage.setItem(
        authenticationKey,
        JSON.stringify({
            authorization: token
        })
    );
  }

  async handleNavigateEvent(url: string) {
    /**
     * URL format: `${root}interaction(${idGroup},${idInteraction})`
    */
    const groupId = GROUP_ID_REGEXP.exec(url)?.[1];
    if (!isValidString(groupId)) return; 
    const snackRef = this.snackSvc.loadingSnack({label: 'Navegando para o Chat'});
    await this.navigatorSvc.navigateToGroupID(
      groupId,
      routeID.groups.chat
    );
    snackRef.dismiss();
  }


  handleLocationEvent(data: IReactNativeLocation): void {

    if (data.location) {
      const {coords, timestamp} = data.location;
      const {accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed} = coords;

      const normalizedLocation: ILocationCoordinates = {
        gpsTimestamp: timestamp,
        latitude,
        longitude,
        accuracy: accuracy || undefined,
        altitudeAccuracy: altitudeAccuracy || undefined,
        altitude: altitude || undefined,
        speed: speed || undefined,
      }
      this.hw.getGeolocation().updateLocation!(normalizedLocation);
    }

    if (isValidRef(data.isAllowed)) {
      this.hw.getGeolocation().resolveIsAllowed!(data.isAllowed);
    }

    if (isValidRef(data.permissionResponse)) {
      this.hw.getGeolocation().resolveAuthorization!(data.permissionResponse);
    }

    if (isValidRef(data.isLocationOn)) {
      this.hw.getGeolocation().stopLocation!();
    }
  }

  handleAudioEvent(data: IReactNativeAudio) {
    if (isValidRef(data.permissionStatus)) {
      this.hw.getMicrophone().resolveAuthorizationStatus!(data.permissionStatus);
    }
  }

  handleNotificationEvent(data: IReactNativeNotification) {
    this.hw.getNotifications().notificationResolver!(data.permission);
  }

  handleLogEvent(data: IReactNativeLog) {
    this.interactionPersistorSvc.sendTrace(
      allSeverityRegistry.debug,
      "ReactNative debug",
      data
    );
  }
  handleLogErrorEvent(data: IReactNativeLog) {
    this.interactionPersistorSvc.sendTrace(
      allSeverityRegistry.default,
      "ReactNative Error",
      data
    );
  }
}
