import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {ToolSection} from "../../../model/tools.model";
import {HandlerHexagonon} from "../../../handlers/hexagono.handler";
import { environment } from 'environments/environment-client';
import { constant } from '@colmeia/core/src/business/constant';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-tools-content-visualization',
	templateUrl: './tools-content-visualization.component.html',
	styleUrls: ['./tools-content-visualization.component.scss']
})
export class ToolsContentVisualizationComponent {

    @Input() section: ToolSection;

    get hexagons() { // 
        return this.section
            ? this.section.hexagons
            : null;
    }

    public showHexagon(hexagon: HandlerHexagonon): boolean {
        return hexagon.getEditingSerializable().getPrimaryID() !== constant.interactionType.annotation.group.archive || environment.allDevFeatures;
    }

    onItemClicked(event: PointerEvent, section: ToolSection, hexagon: HandlerHexagonon) {
        event.preventDefault();

        section.onItemClick(hexagon);
    }

    getItemURL(section: ToolSection, hexagon: HandlerHexagonon) {
        if (section.getItemURL === undefined) {
            return null;
        }

        return section.getItemURL(hexagon);
    }
}
