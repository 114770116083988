import { IColmeiaRequestAPI } from "@colmeia/core/src/comm-interfaces/colmeia-services-req-res";
import { ERoleConstant } from "../core-constants/security-constant";
import { TArrayID, TGlobalUID } from "../core-constants/types";
import { apiRequestType } from "../request-interfaces/message-types";
import { IRequestCursor, IRequestMultipleCursor, IRequestMultipleCursorData } from "../request-interfaces/request-interfaces";
import { IResponseMultipleCursor } from "../request-interfaces/response-interfaces";
import { EBPMType } from "../shared-business-rules/BPM/bpm-model";
import { EConnectionCategory } from "../shared-business-rules/connections/endpoint-model";
import { ENonSerializableObjectType, IdSMConnection } from "../shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ITagableSearch } from "../shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { TIAvatarMenuAccessArray } from "../shared-business-rules/security/security-control.model";
import { TMenuContractAccessProcessed } from "../shared-business-rules/social-network/social-network-config";
import { ISocialNetworkSettings } from "../shared-business-rules/social-network/social-network-user-settings.model";
import { Primitive } from "../tools/utility-types";
import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";

export interface IBasicDashboardRequest extends IRequestMultipleCursor, ICursorAndTagRequest {
}
export interface ICursorAndTagRequest {
    taggable?: ITagableSearch;
}

export interface IPostgreSQLCursorRequest {
    lastChangedAt: TISODateString,
    pageSize: number
}
export interface IDashBoardRequestData {
    nsType?: ENonSerializableObjectType;
    idParent?: TGlobalUID;
}
export interface IDashBoardRequest extends IDashBoardRequestData, IRequestMultipleCursor, ICursorAndTagRequest {
}

export interface IGenericListNonSerializablesRequest extends IDashBoardRequest {
    idsNS: TArrayID;
}

export interface ICustomNSPicker {
    idConnection?: string;
    lookForParentGenealogy?: boolean;
    isMustIncludeParent?: boolean
}

export interface IListNonSerialibazblesRequestCreateInfo {
    apiRequestType: string,
    cursor: string,
    nsType: ENonSerializableObjectType,
    idParent?: string,
    taggable?: ITagableSearch,
}

export interface IListNonSerialibazblesRequestCreateInfoWithBpmType extends IListNonSerialibazblesRequestCreateInfo {
    bpmType: EBPMType
}



export enum ENonSerializableListMode {
    all = 'all',
    parent = 'parent',
    child = 'child',
}


export type IListNonSerializablesMatch =
    | { [key in string]?: Primitive }
    ;


export enum EListNonSerializablesSortOrder {
    Ascending = 'ascending',
    Descending = 'descending',
}



export type IListNonSerializablesSort<Keys extends string = string> = {
    [key in Keys]?: EListNonSerializablesSortOrder;
};

export type TNsFilterProperty = string;
export type TNsFilterValue = IListNonSerializablesMatch;
export interface IListNonSerializablesFilter {
    property: TNsFilterProperty;
    match: IListNonSerializablesMatch;
}
export interface IListNonSerializablesFilterData {
    bpmType?: EBPMType;
    custom?: ICustomNSPicker;
    archivedOnly?: boolean;
    listMode?: ENonSerializableListMode;
    doNotComputeNSSecure?: boolean;
    match?: IListNonSerializablesMatch[];
    sort?: IListNonSerializablesSort;
    checkSortFields?: string[];
    // filters?: IListNonSerializablesFilter[];
    isMustIgnoreAllSNPredicates?: boolean
    idNSRemoteEnvAuthProvider?: string
    isIdNS?: boolean;
}

export interface IListNonSerializablesFilterFields extends IDashBoardRequestData, IListNonSerializablesFilterData, Partial<IRequestMultipleCursorData> {
}
export interface IListNonSerializablesRequest extends IListNonSerializablesFilterData, IDashBoardRequest {
}

export interface IListNonSerializablesRemoteRequest extends IListNonSerializablesRequest, IColmeiaRequestAPI { }

export interface IListConnectionsRequest extends IDashBoardRequest {
    category?: EConnectionCategory;
}

export interface IAvailableMenuID {
    idMenu: string;
    role: ERoleConstant[];
}

export interface IGetAvailableMenusFromDashboardRequest extends IRequestMultipleCursor {
    screenGroup: string;
}
export interface IGetAvailableMenusFromDashboardResponse extends IResponseMultipleCursor {
    idAvailableMenus: TIAvatarMenuAccessArray;
}

export interface IListConnectionRoutesRequest extends IDashBoardRequest {// @hugo
    idConnection?: string;
}

export interface IListDeployedServicesRequest extends IDashBoardRequest { }

export interface IGetAllKBInfoRequest extends IDashBoardRequest { }


//

export interface IGetApplicationProcessedContractDashboardRequest extends IRequestMultipleCursor {
    requestType: typeof apiRequestType.menuSecurityRequest.getApplicationProcessedContract;
}
export interface IGetApplicationProcessedContractDashboardResponse extends IResponseMultipleCursor {
    responseType: typeof apiRequestType.menuSecurityRequest.getApplicationProcessedContract;
    contract: TMenuContractAccessProcessed;
    snConfig: ISocialNetworkSettings
}

export interface IChannelWhitTemplatesListNonSerializablesRequest extends IRequestCursor {
    channelIdNS: IdSMConnection
}
