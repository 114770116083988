<cm-generic-dashboard-edit [handler]="genericEditHandler" *ngIf="genericEditHandler">
  <ng-container *ngIf="schema">
    <app-general-form-schema-edit
      [idNS]="server.idNS"
      [schema]="schema"
      [(allowCorporateSearch)]="server.allowCorporateSearch"
      [(allowAttendanceView)]="server.allowAttendanceView"
      [(ns)]="server"
      (save)="saveSchema($event)"
      #schemaEditor>
    </app-general-form-schema-edit>
    
    <app-multimedia-instance-uploader *ngIf="multimediaHandler" [multimediaHandler]="multimediaHandler"></app-multimedia-instance-uploader>
  </ng-container>
</cm-generic-dashboard-edit>
