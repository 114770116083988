import { Injectable } from '@angular/core';
import { IBrandTheme } from '@colmeia/core/src/shared-business-rules/brand-theme/brand-theme.model';
import { BrandTheme } from 'app/components/color-theme-builder/brand-theme.functions';
import { SNConfigService } from 'app/components/dashboard/social-network-config/sn-config.service';

@Injectable({
    providedIn: 'root'
})
export class AppThemeService {
    constructor(
        private snConfigSvc: SNConfigService
    ) { }

    init() {
        this.setTheme(this.snConfigSvc.getSettings()?.visualIdentity?.brandTheme);

        this.snConfigSvc.snSettings$.subscribe((settings) => {
            this.setTheme(settings?.visualIdentity?.brandTheme);
        });
    }
    
    getAppElement(): HTMLElement {
        return document.body;
    }

    setTheme(theme: IBrandTheme) {
        if (theme) {
            BrandTheme.applyThemeOnElement(this.getAppElement(), theme);
        }
    }

    removeTheme() {
        this.getAppElement().removeAttribute('style');
    }
}
