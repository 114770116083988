import { IdCampaignAction } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ICTAConfigOnAsset } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { TIBPConditionalEvaluatorArray } from "../BPM/bpm-model";
import { IGenerativeIntentConfig } from "../knowledge-base/luis-core-interfaces";
import { EMessageRecency, TContentAssetArray } from "./bot-content-model";
import { EBotEventType } from "./bot-event-model";
import { EBotActionType } from "./new-bot-action";


export enum KBAssetType {
    media = 'media',
    content = 'content',
    contentAPICall = 'apiCall',
    generativo = 'generativo',
    sendTemplate = 'sendTemplate',
    extractEntity = 'extractEntity',
    sendCTA = 'sendCTA',
}

export enum KBAssetTypeClientOnly {
    sticker = 'sticker'
}

/**
 * Existem opções a mais que são mostradas no client, por enquanto só o sticker.
 * Ao enviar para o server elas são convertidas para KAssetType
 */
export type KAssetTypeClient = KAssetType | KBAssetTypeClientOnly;

export type TKAssetTypeArray = Array<KAssetType>;
export type KAssetType = KBAssetType | EBotActionType | EBotEventType

export type TBasicAssetArray = Array<IBasicAsset>;

export interface IGroupChangeAbleBasicAsset {
    recency?: EMessageRecency;
    daysAfter?: number;
}

// Você é um especialista em X, fale estritamente sobre (A, B, C) e não desvie do assunto.
// Não saia do assunto em hipotese alguma. Você é um especialista em X, fale estritamente sobre (A, B, C) e não desvie do assunto




export interface IGenerativeConfigurationOnAsset {
    idPrompt: string;
    /**
     * Toda a estratégia de fineTuning está sendo questionado, até as motivaçõs. Por agora, deprecado.
     * @deprecated
     */
    fineTunning?: boolean;
    /**
     * Toda a estratégia de fineTuning está sendo questionado, até as motivaçõs. Por agora, deprecado.
     * @deprecated
     */
    idFineTuningModel?: string;
    /*
        a base de conhecimento não necessariamente necessita usar a estratégia de Chunks do vetor para fazer a predição.
        Ainda assim, daremos a opção de gerar conteúdo através daquela intenção, mesmo por exemplo. que ela só tenha ML
    */
    mirrorKBIntent?: boolean; // Espelhe chunks da KnowlegeBase
    chunks?: IGenerativeIntentConfig;


};

export interface ISendTemplateConfigOnAsset {
    idCampaignAction?: IdCampaignAction;
}



export interface IBasicAsset extends IGroupChangeAbleBasicAsset {
    idAsset: string; // PK
    type: KAssetType;


    sendTemplateConfig?: ISendTemplateConfigOnAsset;
    cta?: ICTAConfigOnAsset
    generativeConfiguration?: IGenerativeConfigurationOnAsset;
    advancedConditions?: TIBPConditionalEvaluatorArray;
}

export interface IExecutableAction extends IBasicAsset {
    contentArray: TContentAssetArray;
}

/**
 * converte KBAsset que é apenas no client para o que realmente é salvo no backend
 */
export const mapKBAssetClientToServer: Record<KBAssetTypeClientOnly, KBAssetType> = {
    [KBAssetTypeClientOnly.sticker]: KBAssetType.media
}
