import {ITranslationConfig} from "@colmeia/core/src/shared-business-rules/translation/translation-engine";

export class Translation implements ITranslationConfig {

    constructor(
        public serializableId: string,
        public idField: number,
        public value: string = '',
        public language?: string,
    ) {}

    toString(): string {
        return this.value;
    }
}

