import { Injectable } from "@angular/core";
import { HardwareLayerService, IStorageLayer } from "./hardware";

@Injectable({
    providedIn: 'root'
})
export class LocalStoragePersistence {

    constructor(
        private hardwareLayerSvc: HardwareLayerService
    ) {}

    private getLocalStorage(): IStorageLayer {
        return this.hardwareLayerSvc.getStorage();
    }

    public hasItem(key: string): boolean {
        return this.getLocalStorage().hasItem(key);
    }

    public putItem<T extends object>(key: string, item: T): void {
        this.getLocalStorage().putItem(key, item);
    }

    public getItem<T extends object>(key: string): T {
        return this.getLocalStorage().getItem(key);
    }

    public clearItem(key: string): void {
        this.getLocalStorage().clearItem(key);
    }

    public clearAllItems(): void {
        this.getLocalStorage().clearAllItems();
    }
}
