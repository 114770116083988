import { isValidArray, isValidRef, crCheck32, reverseString } from "./utility";
import { IInteractionJSON } from "../comm-interfaces/interaction-interfaces";
import { TGlobalUID } from "../core-constants/types";

export interface ICoordinates {
    latitude: number;
    longitude: number;
}

export interface ILocationCoordinates extends ICoordinates{
    accuracy?: number;
    gpsTimestamp?: number;
    heading?: number;
    speed?: number;
    altitude?: number;
    altitudeAccuracy?: number;
    activity?: string;
    batery?: number;
    powerSaveMode?: boolean;
    uuid?: string;
    isMoving?: boolean;
};

export type TILocationCoordinates = Array<ILocationCoordinates>;

export interface IGeoPoint {
    latitude: number;
    longitude: number;
}
export enum EMetricUnits {
    Meters = 'M',
    Kilometers = 'K',
    NauticMiles = 'N',
}

export interface IGeoProcessDirectivers {
    fetchSize: number;
    minimunDistanceBetweenPoints: number;
    maxAccuracy: number;
    beginClockTick: number;
    endClockTick?: number;
}

export type TMetricUnits = EMetricUnits.Meters | EMetricUnits.NauticMiles | EMetricUnits.Kilometers;


export function getCleanedCoordinates(coordinates: TILocationCoordinates, directives: IGeoProcessDirectivers): TILocationCoordinates {
    if (! isValidArray(coordinates)) {
        return [];
    };

    const size: number = coordinates.length;
    const processed: TILocationCoordinates = [];

    let firstIndex: number = firstPointOnCriteria(coordinates, directives);
    if (firstIndex == -1) {
        firstIndex = 0;
    };

    let previous: ILocationCoordinates = coordinates[firstIndex];
    processed.push(previous);
    
    for (let pos = firstIndex + 1; pos < size; ++pos) {
        if (coordinates[pos].accuracy <= directives.maxAccuracy) {
            const actual: ILocationCoordinates = coordinates[pos];
            const distance: number = getGeoDistance(actual, previous, EMetricUnits.Meters);
      
            if (distance >= directives.minimunDistanceBetweenPoints) {
                processed.push(coordinates[pos]);
                previous = actual;
            };
        };
    };

    console.log({processed});
    return processed;
}

function firstPointOnCriteria(coordinates: TILocationCoordinates, directives: IGeoProcessDirectivers) : number {
    return coordinates.findIndex((point) => {return point.accuracy <= directives.maxAccuracy});
};



export function getGeoDistance(pos1: IGeoPoint, pos2: IGeoPoint, unit: TMetricUnits = EMetricUnits.Meters): number {
	if ((pos1.latitude == pos2.latitude) && (pos1.longitude == pos2.longitude)) {
		return 0;
	}
	else {
		const radlat1: number = Math.PI * pos1.latitude/180;
		const radlat2: number = Math.PI * pos2.latitude/180;
		const theta: number = pos1.longitude - pos2.longitude;
		const radtheta: number = Math.PI * theta/180;
		let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		if (dist > 1) {
			dist = 1;
		}
		dist = Math.acos(dist);
		dist = dist * 180/Math.PI;
		dist = dist * 60 * 1.1515;

        switch (unit) {
            case EMetricUnits.Kilometers:
                dist = dist * 1.60934 ;
                break;
            case EMetricUnits.Meters:
                dist = dist * 1.60934 * 1000;
                break;
            case EMetricUnits.NauticMiles:
                dist = dist * 0.8684 
                break;
            default:
                break;
        }

		return dist;
	}
};

export function isValidGeoPoint(geo: ILocationCoordinates): boolean {
    return isValidRef(geo) && isValidRef(geo.latitude);
};

export function isValidGeoInteraction(interaction: IInteractionJSON): boolean {
    return isValidGeoPoint(interaction.coordinates);
};

export function encryptAvatarID(idPlayer: TGlobalUID): number{
    return crCheck32(reverseString(idPlayer) + "TROLOLO")
}

export function locationToIlocationCoordinates(loc): ILocationCoordinates {
    return {
        uuid: loc.uuid,
        powerSaveMode: null,
        isMoving: loc.is_moving,
        batery: loc.battery.level,
        activity: loc.activity.type,
        accuracy: loc.coords.accuracy,
        altitude: loc.coords.altitude,
        altitudeAccuracy: null,
        gpsTimestamp: new Date(loc.timestamp).getTime(),
        heading: loc.coords.heading,
        latitude: loc.coords.latitude,
        longitude: loc.coords.longitude,
        speed: loc.coords.speed
    }
}
