import { IdGroup } from "@colmeia/core/src/core-constants/named-types/named-primary-ids";
import { IConnectionRouteBody, IConnectionRouteHeaders, TConnectionDomainArray } from "@colmeia/core/src/shared-business-rules/connections/endpoint-model";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { Swap } from "@colmeia/core/src/tools/barrel-tools";
import { Define } from "@colmeia/core/src/tools/utility/types/entities/define";
import {
    ENonSerializableObjectType,
    IdConnection,
    INonSerializable,
    INonSerializableHeader,
} from "../non-serializable-id/non-serializable-id-interfaces";


export enum ETransformationType {
    group = 'grp',
    // routes = 'route',
    provider = 'provider',
    // domain = 'domain',
    httpConnection = 'httpConnection',
    database = 'database',
    googleProvider = 'googleProvider'
}


export type MapTransformationTypeToId = Define<{ [type in ETransformationType]: string }, {
    [ETransformationType.group]: IdGroup;
    [ETransformationType.database]: IdDep<ENonSerializableObjectType.fileMetadata>;
    
    [ETransformationType.provider]: IdDep<ENonSerializableObjectType.connection>;
    [ETransformationType.httpConnection]: IdDep<ENonSerializableObjectType.connection>;
    [ETransformationType.googleProvider]: IdDep<ENonSerializableObjectType.connection>;
}>;

export type MapTransformationTypeToIdSwapped = Swap<MapTransformationTypeToId>


export type TTransformRule = ITransformRule | ITransformationRuleDomain;

export interface ITransformRule {
    transformationType: ETransformationType;
}

export interface ISimpleTransformationRule extends ITransformRule {
    from: string;
    to: string;
}

export interface IGoogleTransformationRule extends ISimpleTransformationRule {
    newLogName: string;
}

export interface ITransformationRuleDomain extends ISimpleTransformationRule {
    transformationType: ETransformationType.httpConnection;
    headers: IConnectionRouteHeaders;
    body: IConnectionRouteBody;
    domains: TConnectionDomainArray;
    idConnection: IdDep<ENonSerializableObjectType.connection>;
}

export interface IDeploySettingsTransformation {
    rule: ISimpleTransformationRule;
    idNSRemoteEnvAuthProvider: string;
};

export interface IDeployTransformationClient extends IDeploySettingsTransformation, INonSerializableHeader { }
export interface IDeployTransformationServer extends IDeployTransformationClient, INonSerializable { }