import { EDelivery360Action } from "../../../../comm-interfaces/interaction-interfaces";
import { DeliveryProvider } from "../../../../core-constants/types";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { ETypeOf, fakeAssertsGuard } from "../../../../tools/utility";
import { $ValueOf } from "../../../../tools/utility-types";
import { isMessengerDeliveryProvider } from "../../../social-media/social-media.functions";
import {
    IMetaIWhatsAppSocialMediaConnectionServer, IMetaWhatsappDetails,
    IProviderConnectionDetail, ITelegramSocialMediaConnectionDetails,
    IWavyWhatsAppSocialMediaConnectionServer,
    IWavyWhatsappDetails, IWavyWhatsappDetailsAccount,
    InfobipWhatsappConnectionDetails,
    MapTypeToSocialMediaConnection, SendgridEmailConnectionDetails, TwilioBaseConnectionDetails
} from "../../../social-media/social-media.model";
import { ITelegramUser } from "../../../social-media/telegram.interfaces";
import {
    nserInsertNameError,
    nserSmallNameError,
    nserinsertDomainError,
    nserinsertEmailError,
    nserinsertKeyError,
    nserinsertPasswordError,
    nserinsertPhoneNumberError,
    nserinsertScenarioError,
    nserinsertServiceIdError, nserinsertTokenError,
    nserinsertUsernameError
} from "../error-messages";
import { assertEntity, minLength, nserNameMinLength } from "../helpers";
import { TNserValidator } from "../validate-nser";




const socialMediaWithServiceId = new Set([
    EDelivery360Action.Delivery360SMS,
    EDelivery360Action.Delivery360WhatsApp,
]);

export const channelNserValidator: TNserValidator = (nser: $ValueOf<MapTypeToSocialMediaConnection>, friendly: FriendlyMessage) => {

    if (!nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    if (nser.provider === DeliveryProvider.twilio) {
        const aux: TwilioBaseConnectionDetails = nser.detail as TwilioBaseConnectionDetails;
        if (!aux.key) {
            friendly.addReturnResponse(nserinsertKeyError);
        }
        if (!aux.authToken) {
            friendly.addReturnResponse(nserinsertTokenError);
        }
        if (socialMediaWithServiceId.has(nser.socialMediaType) && !aux.service) {
            friendly.addReturnResponse(nserinsertServiceIdError);
        }
    }

    process();

    return friendly;

    function process(): void {
        switch (nser.socialMediaType) {
            case EDelivery360Action.Delivery360Voice:
                if (!nser.address) {
                    friendly.addReturnResponse(nserinsertPhoneNumberError);
                }
                return;
            case EDelivery360Action.Delivery360SMS:
                if (!nser.address) {
                    friendly.addReturnResponse(nserinsertPhoneNumberError);
                }
                return;
            case EDelivery360Action.DeliveryInstagram:
            case EDelivery360Action.Delivery360FBMessenger:
                if (isMessengerDeliveryProvider(nser.provider)) {
                    // const aux: ColmeiaFacebookMessengerDetails = nser.detail as ColmeiaFacebookMessengerDetails;
                    // if (!nser.address) {
                    //     friendly.addReturnResponse(nserinsertPageIdError);
                    // }
                    // if (!aux.tokenPage) {
                    //     friendly.addReturnResponse(nserinsertTokenError);
                    // }
                }
                return;
            case EDelivery360Action.Delivery360Email:
                if (nser.provider === DeliveryProvider.sendGrid) {
                    const aux: SendgridEmailConnectionDetails = nser.detail as SendgridEmailConnectionDetails;
                    if (!aux.key) {
                        friendly.addReturnResponse(nserinsertKeyError);
                    }
                    if (!nser.address) {
                        friendly.addReturnResponse(nserinsertEmailError);
                    }

                }
                return;
            case EDelivery360Action.Delivery360WhatsApp:
                // if (!nser.address) {
                //     friendly.addReturnResponse(nserinsertPhoneNumberError);
                // }

                if (nser.provider === DeliveryProvider.whatsApp) {
                    fakeAssertsGuard<IMetaIWhatsAppSocialMediaConnectionServer>(nser)
                    assertEntity<IMetaWhatsappDetails, IProviderConnectionDetail>({
                        businessId: {
                            type: ETypeOf.String,
                            isRequired: false,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        token: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        phoneId: {
                            type: ETypeOf.String,
                            isRequired: false,
                        },
                        webhookURL: {
                            type: ETypeOf.String,
                            isRequired: false,
                        },
                        whatsAppBusinessAccountId: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        whatsAppBusinessAccountIdNs: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        settings: {
                            type: ETypeOf.Object,
                            isRequired: false,
                        },
                        solutionPartnerID: {
                            type: ETypeOf.String,
                            isRequired: false,
                        },
                        businessEncryptKeyUrl: {
                            type: ETypeOf.String,
                            isRequired: false,

                        },
                        status: {
                            type: ETypeOf.String,
                            isRequired: false,

                        },
                        qualityRating: {
                            type: ETypeOf.String,
                            isRequired: false,
                        },
                        messagingLimitTier: {
                            type: ETypeOf.String,
                            isRequired: false,
                        },
                        messageError: {
                            type: ETypeOf.String,
                            isRequired: false,
                        },
                    }, friendly)(nser.detail)
                }

                if (nser.provider === DeliveryProvider.wavy) {
                    fakeAssertsGuard<IWavyWhatsAppSocialMediaConnectionServer>(nser);

                    assertEntity<IWavyWhatsappDetailsAccount>({
                        email: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            },
                            message: 'Email de envio/criação template inválido ou não preenchido',
                        },
                        password: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            },
                            message: 'Senha de envio/criação template inválido ou não preenchido',
                        },
                    }, friendly)(nser.detail.externalAccount);

                    assertEntity<IWavyWhatsappDetails, IProviderConnectionDetail>({
                        externalAccount: {
                            type: ETypeOf.Object,
                            isRequired: true,
                        },
                        email: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        password: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        username: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        authenticationToken: {
                            type: ETypeOf.String,
                            isRequired: true,
                            literalJoi: {
                                min: 1,
                            }
                        },
                        settings: {
                            type: ETypeOf.Object,
                            isRequired: false,
                        }
                    }, friendly)(nser.detail)
                }

                if (nser.provider === DeliveryProvider.infobip) {
                    const aux: InfobipWhatsappConnectionDetails = nser.detail as InfobipWhatsappConnectionDetails;
                    if (!aux.scenarioKey) {
                        friendly.addReturnResponse(nserinsertScenarioError);
                    }
                    if (!aux.domainApi) {
                        friendly.addReturnResponse(nserinsertDomainError);
                    }

                    if (!aux.username) {
                        friendly.addReturnResponse(nserinsertUsernameError);
                    }
                    if (!aux.password) {
                        friendly.addReturnResponse(nserinsertPasswordError);
                    }
                }
                return;
            case EDelivery360Action.DeliveryTelegram: {

                ; assertEntity<
                    ITelegramSocialMediaConnectionDetails,
                    IProviderConnectionDetail
                >({
                    token: {
                        type: ETypeOf.String,
                        isRequired: true,
                    },
                    webHookDomain: {
                        type: ETypeOf.String,
                        isRequired: true,
                    },
                    info: {
                        type: ETypeOf.Object,
                        isRequired: true,
                        custom({ value }: { value: ITelegramUser }) {
                            assertEntity<ITelegramUser>({
                                id: {
                                    type: ETypeOf.Number,
                                    isRequired: true,
                                },
                                is_bot: {
                                    type: ETypeOf.Boolean,
                                    isRequired: true,
                                },
                                first_name: {
                                    type: ETypeOf.String,
                                    isRequired: true,
                                },
                                last_name: {
                                    type: ETypeOf.String,
                                    isRequired: false,
                                },
                                username: {
                                    type: ETypeOf.String,
                                    isRequired: false,
                                },
                                language_code: {
                                    type: ETypeOf.String,
                                    isRequired: false,
                                },
                            }, friendly)(value)
                        }
                    }
                }, friendly)(nser.detail);



                return;
            }
            case EDelivery360Action.DeliveryColmeia: return;
        }

    }

};



