import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { IGroupConnectionInteractionJSON} from '../../comm-interfaces/barrel-comm-interfaces';
import { ConnectionToSerializable } from './to-serializable';

export class ToGroup extends ConnectionToSerializable {

    
    protected constructor(primaryID: TGlobalUID, participant: Participant, idSubscriptionType: TGlobalUID, idGroup: TGlobalUID){
        super(primaryID, participant, idSubscriptionType, idGroup);
    };

    public toJSON(): IGroupConnectionInteractionJSON {
        return super.toJSON()   
    };

	public static staticFactory(idConnection: TGlobalUID): ToGroup {
	    return <ToGroup> ConnectionToSerializable.staticFactory(idConnection);
    };
    
    
    public static factoryMessage(json: IGroupConnectionInteractionJSON,  participant: Participant): ToGroup {
        let togroup: ToGroup = <ToGroup>ConnectionToSerializable.searchCachedInteraction(json.primaryID);
        if (!togroup) {
            togroup = new ToGroup(json.primaryID, 
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                json.idSubscriptionType,
                json.subs.toSubject);
        };
        togroup.rehydrate(json);
        return togroup;
    };


}
