import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';


export enum EIconButtonStyles {
    Create = 'create',
}
export const iconButtonStyles: { [key in EIconButtonStyles]: Partial<CSSStyleDeclaration> } = {
    [EIconButtonStyles.Create]: {
        backgroundColor: '#262D3D',
        padding: '1px 20px',
        color: 'white'
    },
}

@Component({
    selector: 'icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
    host: {
        '[style.pointer-events]': "'none'"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
    @Input() text: string;
    @Input() icon: string;

    @Input() style?: object = {};

    @Input() color: string = '';

    @Input() blink: boolean = false;
    @Input() rotate: boolean = false;
    @Input() disabled: boolean = false;

    @Output() click: EventEmitter<MouseEvent> = new EventEmitter();

    _onClick(event: MouseEvent) {
        event.stopPropagation();
        this.click.emit(event);
    }

    noop(){}
}
