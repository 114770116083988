import { IBPMMarketingRootServer } from '../../../BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { MktHostNode } from './mkt-host-node';

export class MktRootHost extends MktHostNode {
    private allowedDrageOnMe: TReferencedObject[] = [
        ENonSerializableObjectType.bpmMarketingListener,
        ENonSerializableObjectType.bpmMarketingAction,
    ];

    constructor(mktRoot: IBPMMarketingRootServer) {
        super(mktRoot)
    }

    static create(mktRoot: IBPMMarketingRootServer): MktRootHost {
        return new MktRootHost(mktRoot)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return this.allowedDrageOnMe.includes(nodeTypeToDrag);
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return false
    }
}
