import { isInvalid, isValidRef, isValidString } from "../../../../tools/utility";
import { IBotActionAsset, IBotActionAssetContainer, TIBotActionAssetContainerArray } from "../../../bot/bot-action-model";
import { TKAssetTypeArray } from "../../../bot/bot-asset-model";
import { IBotEvent } from "../../../bot/bot-event-model";
import { ENextGenBotElementType, getInitialActionWithId, IBotMenuContainerServer, IBotMenuItemServer } from "../../../bot/bot-model";
import { EBotActionType } from "../../../bot/new-bot-action";
import { EBPMType } from "../../../BPM/bpm-model";
import { BasicElement } from "../../../graph/essential/basic-element";
import { GraphElement } from "../../../graph/essential/graph-element";
import { GraphRulesProcessor } from "../../../graph/essential/graph-rules-processor";
import { IGraphConnectionData, TIGraphConnectionDataArray } from "../../../graph/essential/graph-types";
import { GraphPredicate } from "../../../graph/essential/predicate";
import { ENonSerializableObjectType, INonSerializable } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { BPMConfigToolbarSelector } from '../../toolbar/bpm-config-toolbar-selector';
import { IBasicToolbarElement, TBPMBOTConnectionTypes, TBPMBOTElementTypes, TReferencedObject } from "../../toolbar/config-toolbar.types";
import { NSGenericHost } from "../ns/ns.host";
import { IAddedConnection } from "../ns/ns.host.types";
import { EHandleHostUpdateAction, IMenuItemFactoryInfo, THandleHostUpdateActions } from "./bot-host.types";
import { BotHostNode } from "./bot.host";


export class MenuItemHostNode extends BotHostNode {
    constructor(ns: IBotMenuItemServer) {
        super(ns);
    }

    public getNonSerializable(): IBotMenuItemServer {
        return super.getNonSerializable() as IBotMenuItemServer;
    }

    public getDisplayableElements(): { type: TReferencedObject; from: string; to: string; }[] {
        return;
    }

    public handleHostUpdate(graphElement: GraphElement, beforeNSData?: INonSerializable): THandleHostUpdateActions {
        const rulesProcessor: GraphRulesProcessor = graphElement.getRuleProcessor();
        const hostObjectNS: IBotMenuItemServer = graphElement.getHostObject().getNonSerializable() as IBotMenuItemServer;
        const beforeAction: IBotActionAsset = (beforeNSData as IBotMenuItemServer)?.action;
        const action: IBotActionAsset = (hostObjectNS as IBotMenuItemServer).action;

        const isGoActionTree: boolean = action.type === EBotActionType.goActionTree;
        const checkConnectionAddition: boolean = isGoActionTree && isValidRef(action.idElement);

        const toSave: BasicElement[] = [];
        const toDelete: BasicElement[] = [];

        if (checkConnectionAddition) {
            const isSame: boolean = (action.idElement === beforeAction?.idElement);

            if (!isSame) {
                const previousPredicate: GraphPredicate = this.findToConnection(graphElement, beforeAction?.idElement);
                const toGraphElement: GraphElement = rulesProcessor.getAllNodes().find(element => element.getHostObject().getNonSerializable().idNS === action.idElement);

                if (isValidRef(previousPredicate)) {
                    rulesProcessor.removePredicate(previousPredicate);
                    toDelete.push(previousPredicate);
                }

                if (isValidRef(toGraphElement)) {
                    const predicate: GraphPredicate = graphElement.createNewPredicateTo(toGraphElement);
                    toSave.push(predicate);
                }
            }
        } else {
            const connectionToMenuContainer: GraphPredicate = this.findToConnection(graphElement, beforeAction?.idElement);

            const isNotConnectedToMenuContainer = isInvalid(connectionToMenuContainer);

            if (!isNotConnectedToMenuContainer) {
                rulesProcessor.removePredicate(connectionToMenuContainer);

                toDelete.push(connectionToMenuContainer);
            }

        }

        if (isValidRef(hostObjectNS.idParent)) {
            const parentConnection: GraphPredicate = this.findFromConnection(graphElement, hostObjectNS.idParent);

            if (isInvalid(parentConnection)) {
                const parentElement: GraphElement = this.findNodeElementByHostedId(rulesProcessor, hostObjectNS.idParent);
                const connection = parentElement.createNewPredicateTo(graphElement);
                toSave.push(connection);
            }

        }

        return {
            [EHandleHostUpdateAction.delete]: toDelete,
            [EHandleHostUpdateAction.save]: toSave,
        }
    }

    public findNodeElementByHostedId(rules: GraphRulesProcessor, hostedId: string): GraphElement {
        return rules.getAllNodes().find(element => element.getHostedID() === hostedId);
    }

    public findToConnection(element: GraphElement, idNSHosted: string): GraphPredicate {
        return element.getConnectionsToOthers().find(predicate => {
            const targetElement: GraphElement = element.getRuleProcessor().getElementById(predicate.getToElementId());
            const targetNS: IBotMenuContainerServer = targetElement.getHostObject().getNonSerializable() as IBotMenuContainerServer;
            return targetNS.idNS === idNSHosted;
        });
    }

    public findFromConnection(element: GraphElement, idNSHosted: string): GraphPredicate {
        return element.getConnectionsToOthers().find(predicate => {
            const targetElement: GraphElement = element.getRuleProcessor().getElementById(predicate.getFromElementId());
            const targetNS: IBotMenuContainerServer = targetElement.getHostObject().getNonSerializable() as IBotMenuContainerServer;
            return targetNS.idNS === idNSHosted;
        });
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        const noOtherConnectionsFromMe: boolean = edgesToOthersCount == 0
        const toolbarElement: IBasicToolbarElement<TReferencedObject> = BPMConfigToolbarSelector.getOneToolbarElement(EBPMType.bot, this.getElementType());
        const canDragOnMe: boolean = BPMConfigToolbarSelector.canDragOn(nodeTypeToDrag, this.getElementType());
        const isConnectable: boolean = isValidRef(toolbarElement.canConnectTo[nodeTypeToDrag]) || canDragOnMe;

        return super.isDraggableIntoMe(nodeTypeToDrag, edgesToOthersCount) || (noOtherConnectionsFromMe && isConnectable);
    }

    public static create(info: IMenuItemFactoryInfo): MenuItemHostNode {
        return new MenuItemHostNode(info.ns as IBotMenuItemServer);
    }

    public getSequencialPosition() {
        return this.getNonSerializable().position;
    }

    public getHostedType(): TReferencedObject {
        return ENextGenBotElementType.botMenuItem;
    }

    public getActionContainer(): TIBotActionAssetContainerArray {
        const asset = (super.getNonSerializable() as IBotMenuItemServer).action
        return [{
            action: asset
        }]
    }

    public setAction(action: IBotActionAsset) {
        const ns = super.getNonSerializable() as IBotMenuItemServer
        ns.action = action
    }

    public getPredicateTypesFromState(): TKAssetTypeArray {
        return isValidRef(this.getNonSerializable().action)
            ? [this.getNonSerializable().action.type]
            : []
    }

    public addElementAction(actionType: TReferencedObject, hostedId?: string) {
        // goHuman e LGPD ja foi editado no dialog, nao precisamos setar o action aqui
        if (actionType == EBotActionType.goHuman || actionType == EBotActionType.LGPD) {
            return
        }

        this.setAction({
            ...getInitialActionWithId(),
            type: <EBotActionType>actionType,
            idElement: hostedId,
        });
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject) {
        return target === ENextGenBotElementType.root || target === ENextGenBotElementType.menuContainer;
    }

    public isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        switch (targetType) {
            case ENextGenBotElementType.menuContainer:
                return action === EBotActionType.goActionTree;
            case ENonSerializableObjectType.contentGenerator:
                return action === EBotActionType.contentGenerator;
            default:
                return false;
        }
    }

    public addConnectionTo(target: NSGenericHost, connectionType: TReferencedObject): IAddedConnection {
        const currentAction = (super.getNonSerializable() as IBotMenuItemServer).action;

        this.setAction({
            ...(isValidRef(currentAction) ? currentAction : getInitialActionWithId()),
            type: <EBotActionType>connectionType,
            idElement: target.getHostedID(),
        })

        return { isConnectionAdded: true }
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        switch (connectionType) {
            case EBotActionType.goActionTree:
            case EBotActionType.goBot:
            case EBotActionType.contentGenerator:
            case EBotActionType.goFirstTree:
            case EBotActionType.goBack:
            case EBotActionType.isEnd:
            case EBotActionType.goHuman:
            case undefined:
                return true;
            default: return false;
        };
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        const ns = super.getNonSerializable() as IBotMenuItemServer
        return [{
            connectionType: ns.action.type,
            targetHostId: ns.action.idElement,
            subElementId: undefined
        }]
    }

    thereIsMoreChildrenNodesToCreate(): boolean {
        const con = this.getConnectionTargetData()[0];
        const availableConTypes: EBotActionType[] = [EBotActionType.contentGenerator, EBotActionType.goBot]
        return availableConTypes.includes(con.connectionType as EBotActionType) && isValidString(con.targetHostId)
    }

    isNeighborDrawable(neighborType: TBPMBOTElementTypes, neighborNSId: string): boolean {
        const events = this.getNonSerializable().events;
        const isFromEvent = events.some((ev: IBotEvent) => ev.preCondition?.idTreeVisited === neighborNSId);

        return neighborType == ENonSerializableObjectType.contentGenerator && !isFromEvent;
    }

    getEdgeTypeToConnect(neighborType: TBPMBOTElementTypes): TBPMBOTConnectionTypes {
        const connectionMap = { [ENonSerializableObjectType.contentGenerator]: EBotActionType.contentGenerator }
        return connectionMap[neighborType]
    }
}
