import { Injectable } from '@angular/core';
import { isValidFunction, isValidRef } from '@colmeia/core/src/tools/barrel-tools';

export interface INotificationConfig {
  title: string;
  options: NotificationOptions;
  onNotificationClickCallbackFn?(): void;
}

@Injectable({
    providedIn: 'root'
})
export class ColmeiaExternalNotificationService {    
    
    constructor(
    ) {}

    private _logoUrlPath: string = './assets/img/icons/colmeia_selecao.png'; //<< Default value
    get logoUrl(): string {
      return this._logoUrlPath;
    }
    set logoUrl(value: string) {
      this._logoUrlPath = value;
    }

    public sendNotification(notificationConfig: INotificationConfig) {
      if(!isValidRef(notificationConfig?.options)) {
        return;
      }

      const notification = new Notification(notificationConfig.title, notificationConfig.options);
  
      notification.onclick = (event) => {
          event.preventDefault(); 
          window.focus();
          
          if(isValidFunction(notificationConfig.onNotificationClickCallbackFn)) {
            notificationConfig.onNotificationClickCallbackFn();
          }
            
          notification.close();
      };
    }
}