import { secToMS } from "@colmeia/core/src/time/time-utl";
import { ExpirableEvent } from "./client-utility";

export interface IAttendantLogin {
    isAttendantLoggedIn: boolean
}

export enum EAttEventType {
    start = 'start',
    end = 'end'
}

export abstract class AttendanceEvent extends ExpirableEvent {
    private static lifetimeMS: number = secToMS(60);
    constructor(
        public readonly idGroup: string,
        public readonly type: 'start' | 'end' | EAttEventType,
        public readonly message: string
    ) {
        super(AttendanceEvent.lifetimeMS);
    }

    public visibleFor(idGroup: string): boolean {
        return !this.expired && this.idGroup === idGroup;
    }
}

export class StartAttendanceEvent extends AttendanceEvent {
    isStart = true;
}
export class FinishAttendanceEvent extends AttendanceEvent { }
export class TransferAttendanceEvent extends AttendanceEvent { }
