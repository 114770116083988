import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../all-serializables";
import { EDelivery360Action } from "../../../comm-interfaces/interaction-interfaces";
import { TCustomEnumPickerFieldMaps } from "../../enum-db";
import { deprecatedSuggestions } from "../../../tools/type-utils";

const activeAgent = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.ActiveAgent,
    fields: deprecatedSuggestions<TCustomEnumPickerFieldMaps<EDelivery360Action>>()({
        [EDelivery360Action.Delivery360WhatsApp]: 1, // 'channelTypePicker' 19
        [EDelivery360Action.Delivery360SMS]: 2, // 'channelTypePicker' 20
        [EDelivery360Action.Delivery360FBMessenger]: 3, // 'channelTypePicker' 21
        [EDelivery360Action.Delivery360Email]: 4, // 'channelTypePicker' 22
        [EDelivery360Action.Delivery360Voice]: 5, // 'channelTypePicker' 23,
        [EDelivery360Action.DeliveryColmeia]: 6, // Colmeia
        [EDelivery360Action.DeliveryInstagram]: 7, // Instagram
        [EDelivery360Action.DeliveryTelegram]: 8, // Telegram
    }).ok,
});
export const activeAgentScreenOptions = activeAgent.screenOptions;
export const activeAgentTranslations = activeAgent.translations;
