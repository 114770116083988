<ng-container *ngIf="!isRange; else rangeDatePickerTpl">
  <mat-form-field [appearance]="appearance" >
    <mat-label><ng-content select="[label]"></ng-content>Data</mat-label>

    <input
      [(ngModel)]="date"
      [matDatepicker]="datePicker"
      type="hidden"
      style="display: none"
      [min]="handler.min()"
      [max]="handler.max()"
    />
    <input
      matInput
      [value]="lbl1Value"
      readonly
      type="text"
      (click)="datePicker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker [touchUi]="isMobile" (opened)="onOpen()" (closed)="onClose()">
      <mat-datepicker-actions>
        <div class="actions-container">
          <div class="timepicker-range-container" *ngIf="hasTimePicker">
            <div class="time-picker-container">
              <app-timestamp-picker [handler]="timestampHandler"></app-timestamp-picker>
            </div>
          </div>
          <div class="mat-datepicker-actions">
            <button mat-button matDatepickerCancel>Cancelar</button>
            <button mat-raised-button color="primary" matDatepickerApply>
              Salvar
            </button>
          </div>
        </div>
      </mat-datepicker-actions>
    </mat-datepicker>
  </mat-form-field>
</ng-container>
<ng-template #rangeDatePickerTpl>
  <mat-form-field
    [appearance]="appearance"
    [class.range-datetime-picker]="hasTimePicker"
    class="no-padding-bottom"
  >
    <mat-label><ng-content select="[label]"></ng-content>Data</mat-label>

    <span matPrefix>
      <ng-content [select]="customPrefix"> </ng-content>
    </span>

    <mat-date-range-input
      [disabled]="disabled"
      [rangePicker]="rangeDatePicker"
      [min]="handler.min()"
      [max]="handler.max()"
    >
      <input matStartDate type="hidden" [(ngModel)]="rangeStartDate" />
      <input matEndDate type="hidden" [(ngModel)]="rangeEndDate" />
    </mat-date-range-input>
    <input
      matInput
      [value]="lbl1Value"
      readonly
      type="text"
      (click)="rangeDatePicker.open()"
      style="font-size: 11.84px; letter-spacing: normal;"
    />
    <mat-datepicker-toggle matSuffix [for]="rangeDatePicker"></mat-datepicker-toggle>
    <mat-date-range-picker #rangeDatePicker [touchUi]="isMobile" (opened)="onOpen()" (closed)="onClose()">
      <mat-datepicker-actions>
        <div class="actions-container">
          <div *ngIf="hasTimePicker" class="timepicker-range-container">
            <div class="time-picker-container">
              <app-timestamp-picker
                label="Início"
                [(time)]="_dateRangeStartTime"
              ></app-timestamp-picker>
            </div>
            <div class="time-picker-container">
              <app-timestamp-picker
                label="Fim"
                [(time)]="_dateRangeEndTime"
              ></app-timestamp-picker>
            </div>
          </div>

          <div class="mat-datepicker-actions">
            <button mat-button matDateRangePickerCancel>Cancelar</button>
            <button
              mat-raised-button
              color="primary"
              matDateRangePickerApply
              (click)="validateAndSet($event)"
            >
              Salvar
            </button>
          </div>
        </div>
      </mat-datepicker-actions>
    </mat-date-range-picker>
  </mat-form-field>
</ng-template>
