import { IMediaViewer } from "../hardware-interfaces";
import { Injectable } from "@angular/core";
import { TMultimediaInstanceArray, MultimediaInstance } from "@colmeia/core/src/multi-media/barrel-multimedia";
import {FullScreenVideoService} from "../../fullscreenvideo.service";
import {ImageSliderService} from "../../image-slider.service";

@Injectable({
    providedIn: 'root'
})
export class CordovaMediaViewer implements IMediaViewer {

    constructor(
        private fullScreenVideoSvc: FullScreenVideoService,
        private imageSliderSvc: ImageSliderService,
    ) { }

    view(mms: TMultimediaInstanceArray, startingIndex: number = 0, title?: string) {
        let videos = mms.some(instance => this.isVideo(instance));
        if (videos) {
            this.fullScreenVideoSvc.showVideo(mms[startingIndex]);
        } else {
            this.imageSliderSvc.openForImages({
                images: mms,
                title,
                startingIndex
            });
        }
    }

    isVideo = (mm: MultimediaInstance) => mm.getMimeType().startsWith('video');

}
