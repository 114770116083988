import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    ViewChildren,
    QueryList,
    ChangeDetectorRef,
    AfterViewInit,
    NgZone
} from '@angular/core';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { MultimediaInstance } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { constant } from "@colmeia/core/src/business/constant";
import { Serializable } from '@colmeia/core/src/business/serializable';
import {ImageSliderService} from "../../../services/image-slider.service";
import {MultimediaService} from "../../../services/multimedia.service";
import {IAndroidBackButtonAction, UserActionService} from "../../../services/user-action.service";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {RootComponent} from "../root/root.component";


export interface IImageSliderParameters{
    images: Array<MultimediaInstance>,
    startingIndex?: number,
    title?: string
}

export interface IImageSlider{
    openForImages(options: IImageSliderParameters): void
}

interface IImageSliderLabels {
    of: string
}

const aux = constant.serializableField.auxiliars;



@Component({
    selector: 'app-image-slider',
    templateUrl: './image-slider.component.html',
    styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent extends RootComponent<'of'> implements OnInit, IImageSlider, AfterViewInit, IAndroidBackButtonAction {
    private sensitivity: number = 25; // horizontal % needed to trigger swipe

    activeSlide: number = 0;
    slideCount: number = 0;

    private sliderEl: HTMLElement;
    private stopAnimationTimer: NodeJS.Timeout;

    private initialized: boolean = false;
    private toggleTimer: NodeJS.Timeout;

    private onPanTimer: NodeJS.Timeout;

    barsVisible = true;
    sliderVisible = false;

    title: string;
    imageUrls: Array<string> = [];

    @ViewChild('container', {static: true}) container: ElementRef;
    @ViewChild('slider', {static: true}) slider: ElementRef;
    @ViewChildren('imageContainer') imageContainers: QueryList<ElementRef>;

    constructor(
        private _zone: NgZone,
        private imageSliderSvc: ImageSliderService,
        private changeDetectorRef: ChangeDetectorRef,
        private multimediaSvc: MultimediaService,
        private userAction: UserActionService,
        private cdr: ChangeDetectorRef
    ) {
        super({
            of: gTranslations.common.of
        }, true, cdr);
        this.imageSliderSvc.setSliderComponent(this);
    }

    onAndroidBackButtonPressed() {
        this._zone.run(() => {
            this.toggle();
        });
    }

    openForImages(options: IImageSliderParameters) {
        this.userAction.addAction(this);
        let newUrls = options.images.map(mmInstance => this.multimediaSvc.getMultimediaBestUrl(mmInstance));

        let differentElements = true;
        if (newUrls.length === this.imageUrls.length) {
            differentElements = newUrls.some((url, index): boolean => this.imageUrls[index] !== url);
        }

        if (differentElements) {
            this.imageUrls = newUrls;
            this.initialized = false;
        }

        this.title = options.title;
        this.changeDetectorRef.detectChanges();
        this.toggle(options.startingIndex);
    }

    ngOnInit() {}

    ngAfterViewInit(): void {
        this.sliderEl = this.slider.nativeElement;
    }

    init() {
        if (isValidRef(this.slider)) {
            this.slideCount = this.imageUrls.length;
            this.sliderEl.style.width = `${this.slideCount * 100}%`;
        }
        this.initialized = true;
    };

    toggle(startingIndex?: number) {
        clearTimeout(this.toggleTimer);
        this.toggleTimer = setTimeout(() => {
            this.sliderVisible = !this.sliderVisible;

            if (!this.initialized) {
                this.init();
                this.barsVisible = true;
            }

            if (isValidRef(startingIndex)) {
                this.goTo(startingIndex);
            }

        }, 0);

    }

    checkBars = (data: {event: any, scale: number}) => {
        let e = data.event;
        switch (e.type) {
            case 'tap':
                this.barsVisible = !this.barsVisible;
                break;
            case 'pan':
                this.barsVisible = false;
                break;
            case 'pinch': case 'wheel':
                this.barsVisible = data.scale === 1;
                break;
            default:
                break;
        }
    }

    onPan = (data: {event: any, type: string}) => {
        // ignore non hammer events
        let e: any = data.event;
        if (!e.additionalEvent) {
            return;
        }
        if (e.additionalEvent === 'panleft' || e.additionalEvent === 'panright') {
            clearTimeout(this.onPanTimer);
            this.onPanTimer = setTimeout( () => {
                let e: any = data.event;
                if (!e.additionalEvent) {
                    return;
                }
                if (e.additionalEvent === 'panleft' || e.additionalEvent === 'panright') {
                    let percentage = 100 / this.slideCount * e.deltaX / window.innerWidth;

                    let percentageCalculated = percentage - 100 / this.slideCount * this.activeSlide;

                    this.sliderEl.style.transform = 'translateX( ' + percentageCalculated + '% )';

                    if (e.isFinal) {
                        if (e.velocityX > 1) {
                            this.goTo(this.activeSlide - 1);
                        } else if (e.velocityX < -1) {
                            this.goTo(this.activeSlide + 1)
                        } else {
                            if (percentage <= -(this.sensitivity / this.slideCount))
                                this.goTo(this.activeSlide + 1);
                            else if (percentage >= (this.sensitivity / this.slideCount))
                                this.goTo(this.activeSlide - 1);
                            else
                                this.goTo(this.activeSlide);
                        }
                    }
                }
            },0);
        } else if (e.isFinal) {
            this.userAction.closeLastAction();
        }
    }

    goTo(number: number) {
        if (number < 0)
            this.activeSlide = 0;
        else if(number > this.slideCount - 1)
            this.activeSlide = this.slideCount - 1;
        else
            this.activeSlide = number;

        this.sliderEl.classList.add('is-animating');
        let percentage = -(100 / this.slideCount) * this.activeSlide;
        this.sliderEl.style.transform = `translateX(${percentage}%)`;

        clearTimeout(this.stopAnimationTimer);
        this.stopAnimationTimer = setTimeout(() => {
            this.sliderEl.classList.remove('is-animating');
        }, 400);
    };

}
