import { IBPMMarketingActionServer } from '../../../BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { MktHostNode } from './mkt-host-node';

export class MktActionHost extends MktHostNode {
    private dreaggableIntoMe: TReferencedObject[] = [
        ENonSerializableObjectType.bpmMarketingEnd,
        ENonSerializableObjectType.bpmMarketingPause,
        ENonSerializableObjectType.bpmMarketingAction,
    ];

    constructor(mktAction: IBPMMarketingActionServer) {
        super(mktAction)
    }

    static create(mktAction: IBPMMarketingActionServer): MktActionHost {
        return new MktActionHost(mktAction)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return this.dreaggableIntoMe.includes(nodeTypeToDrag);
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return true
    }
}
