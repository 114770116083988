import { EBPMAction } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { EMetaEngagementConditionType, ESourceOfInfo, IBasicCondition, TAllConditionInformationSources, TIBasicConditionArray } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { getReadableUniqueID, isValidFunction } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";
import { BPMCondition } from "./condition";
import { IGenerativeIntentConfig } from '@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces';
import { INSPickerHandlerParameter } from "app/handlers/ns-picker/ns-picker.handler";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";

export type TConditionsEditorTargetItem = {
    targetLabel: string;
    targetId: string;
};

export type TConditionsEditorTargetsList = TConditionsEditorTargetItem[];

export type TConditionsEditorUnitChangesCallback = (
    condition: IBasicCondition,
    index: number
) => void;

export interface IConditionsEditorParameters
    extends IComponentParameter {
    allowedSourceInfo?: ESourceOfInfo[];
    viewMode?: boolean;
    bpmAction?: EBPMAction;
    maxConditions?: number;
    currentConditions?: TIBasicConditionArray;
    hiddenTarget?: boolean;
    targetsList?: TConditionsEditorTargetsList;
    isViewMode?: boolean;
    clientCallback: {
        onAdd?: TConditionsEditorUnitChangesCallback;
        onRemove?: TConditionsEditorUnitChangesCallback;
        onUpdate?: TConditionsEditorUnitChangesCallback;
        onChange?: (conditions: TIBasicConditionArray) => void;
        conditionValidation?: (condition: IBasicCondition, hasInvalidFilter: boolean) => void; 
    };
    removeMetaConditionTypes?: EMetaEngagementConditionType[];
    removeConditionInformationSource?: TAllConditionInformationSources[]
    chunksConfig?: IGenerativeIntentConfig;
    getNsPickerValueParams?: (nsType: ENonSerializableObjectType) => Partial<INSPickerHandlerParameter>
}

export class ConditionsEditorHandler extends MainHandler {

    constructor(private parameters: IConditionsEditorParameters) {
        super(parameters);
    }

    public getEmptyCondition(): IBasicCondition {
        return {
            sourceInfo: undefined,
            idConditionalAsset: getReadableUniqueID(16),
            metaConditionType: undefined,
            condition: "",
            reverseCondition: false,
        };
    }

    public getConditionIndex(condition: IBasicCondition): number {
        return this.parameters.currentConditions.indexOf(condition);
    }

    public callClientUpdate(condition: IBasicCondition) {
        if (!isValidFunction(this.parameters.clientCallback?.onUpdate)) return;

        const index = this.getConditionIndex(condition);

        this.parameters.clientCallback?.onUpdate(condition, index);
        this.callClientChange();
    }

    public callClientChange() {
        if (!isValidFunction(this.parameters.clientCallback?.onChange)) return;

        this.parameters.clientCallback?.onChange(this.parameters.currentConditions);
    }

    public callClientAdd(condition: IBasicCondition) {
        if (!isValidFunction(this.parameters.clientCallback?.onAdd)) return;

        const index = this.getConditionIndex(condition);

        this.parameters.clientCallback?.onAdd(
            condition,
            index
        );
        this.callClientChange();
    }

    public callClientRemove(condition: IBasicCondition) {
        if (!isValidFunction(this.parameters.clientCallback?.onRemove)) return;

        const index = this.getConditionIndex(condition);

        this.parameters.clientCallback?.onRemove(condition, index);
        this.callClientChange();
    }
}
