<div class="ordenable-list">

  <app-ns-picker *ngIf="nsSelectHandler" class="canonical-ns-picker" [handler]="nsSelectHandler"></app-ns-picker>

  <div class="ordenable-item ordenable-header">
    <div class="ordenable-item-content">
      <div class="row-title">{{ translations.variables }}</div>
    </div>
    <div class="ordenable-item-actions">
      <button
        *ngIf="allowEdit"
        mat-stroked-button
        color="primary"
        [disabled]="disableAddMoreVariables()"
        (click)="openFieldAdder()"
        [matTooltip]="disableAddMoreVariables() ? 'Não é permitido adicionar mais variavéis.' : ''"
        matTooltipPosition="above"
      >
        <mat-icon style="margin: 0">add</mat-icon>
      </button>
    </div>
  </div>

  <div *ngFor="let variable of possibleTemplateVariables" class="ordenable-item">
    <div class="ordenable-item-content">
      <div class="row-title">{{ variable.text }}</div>
    </div>
    <div class="ordenable-item-actions">
      <app-canonical-picker-edit-canonical-config
        *ngIf="parameters.enableCanonicalsConfig && getCanonicalConfigHandler(variable.idProperty)"
        [handler]="getCanonicalConfigHandler(variable.idProperty)">
      </app-canonical-picker-edit-canonical-config>
      <button
        *ngIf="allowEdit && canRemove(variable.idProperty)"
        mat-icon-button
        color="primary"
        matTooltip="Remover"
        (click)="removeVariable(variable.idProperty)"
        style="height: 40px; width: 40px; display: flex; align-items: center; justify-content: center; padding: 0;"
        >
        <mat-icon>
          delete
        </mat-icon>
      </button>


      <button
        *ngIf="enableCopy"
        mat-icon-button
        color="primary"
        matTooltip="Copiar"
        (click)="copyVariable(variable)">
        <mat-icon>
          copy_all
        </mat-icon>
      </button>
    </div>
  </div>

</div>
