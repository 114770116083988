import { Component, OnDestroy, OnInit } from '@angular/core';
import { isInvalidArray, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { IMenuDashboardOption, MenuDashboardService } from 'app/components/menu-dashboard/menu-dashboard.service';
import { Observable, Subscription, from } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IMapCurrentDashboardClient } from 'app/model/dashboard/db/dashboard-db-types';
import { EScreenGroups } from '@colmeia/core/src/shared-business-rules/visual-constants';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { RoutingService } from 'app/services/routing.service';
import { ScreenSpinnerService, SpinType } from 'app/services/screen-spinner.service';
import { RoutingBuilder } from 'app/services/routing-builder';
import { SessionService } from 'app/services/session.service';
import { ESideMenuScreen, ModuleNavService, TSideMenuScreenId } from 'app/components/navigation/module-nav.service';
import { AttendanceService } from 'app/services/attendance.service';
import { CRMTicketsService } from 'app/crm-service-tickets-view/crm-tickets.service';

@Component({
    selector: 'app-module-nav',
    templateUrl: './module-nav.component.html',
    styleUrls: ['./module-nav.component.scss']
})
export class ModuleNavComponent extends RootComponent<
    'youDontHaveAccessToAnyMenu'
> implements OnInit, OnDestroy {
    public modulesList: Observable<IMenuDashboardOption[]>;

    public groupLoading$: Observable<boolean>;
    public selectedSideScreenId: TSideMenuScreenId;

    ESideMenuScreen = ESideMenuScreen;
    private selectedSideScreenSubs: Subscription;

    public hasTicketsAccess = false;

    constructor(
        private menuDashboardSvc: MenuDashboardService,
        private snackBarSvc: MatSnackBar,
        private dashboardSvc: DashBoardService,
        private warningSvc: GlobalWarningService,
        private routingService: RoutingService,
        private screenLoadingSvc: ScreenSpinnerService,
        private sessionSvc: SessionService,
        private moduleNavService: ModuleNavService,
        private attendanceSvc: AttendanceService,
        private crmTicketsService: CRMTicketsService
    ) {
        super({
            youDontHaveAccessToAnyMenu: gTranslations.errors.youDontHaveAccessToAnyMenu,
        });
    }

    ngOnInit() {
        this.loadModules();

        this.groupLoading$ = this.screenLoadingSvc.lastStatus$.pipe(
            filter(s => s.spinType === SpinType.groupNav),
            map(s => {
                return !this.routingService.isOnChatScreen() && s.show
            })
        );

        this.selectedSideScreenSubs = this.moduleNavService.getSelectedSideScreen$().subscribe(screenId => {
            this.selectedSideScreenId = screenId;
        });

        this.crmTicketsService.checkTicketsAccess().then(access => {
            this.hasTicketsAccess = access;
        });
    }

    ngOnDestroy() {
        this.selectedSideScreenSubs.unsubscribe();
    }

    private async loadModules() {
        this.modulesList = this.menuDashboardSvc.allOptions$.pipe(
            mergeMap(items => from(this.asyncFilter(items, async (item: IMenuDashboardOption) => {
                if (item.belongsToPath) {
                    return false;
                }

                if (!item.modulePath) {
                    return item.hasVisibility;
                }

                const screenGroup = item.config?.screenGroup.name;

                if (!isValidRef(screenGroup)) {
                    return false;
                }

                const allowedMenus: string[] = await this.dashboardSvc.getAllowedMenuIDs(screenGroup);

                if (isInvalidArray(allowedMenus)) {
                    return false;
                }

                return item.hasVisibility;
            })))
        );
    }

    private async asyncFilter(items: IMenuDashboardOption[], call: Function) {
        const result = [];
        for (let item of items) {
            const keep = await call(item);
            if (keep) result.push(item)
        }
        return result
    }

    public isCurrentModule(module: IMenuDashboardOption): boolean {
        if (this.selectedSideScreenId !== ESideMenuScreen.Dashboard) {
            return false;
        }

        let modulePath = module.modulePath || module.path;
        const selectedModule = this.menuDashboardSvc.selectedDashboard;

        if (isValidRef(modulePath)) {
            return selectedModule === modulePath;
        }

        const subModules = this.menuDashboardSvc.getSubOptionsFrom(module);

        return subModules.some(subMod => subMod.modulePath === selectedModule);
    }

    public onModuleClick(module: IMenuDashboardOption): void {
        const modulePath = module.modulePath || module.path;

        if (!isValidRef(modulePath)) {
            return;
        }

        if (
            this.menuDashboardSvc.selectedDashboard === modulePath
            && this.selectedSideScreenId === ESideMenuScreen.Dashboard
            && !this.moduleNavService.isMobileLayout
        ) {
            this.toggleHideMenu();
            return;
        }

        this.selectModule(modulePath);
    }

    private selectModule(modulePath: string): void {
        this.menuDashboardSvc.selectedDashboard = modulePath;

        this.moduleNavService.openModuleMenus();
    }

    async navigateToOption(option: IMenuDashboardOption): Promise<boolean | void> {
        if (option.contractItem && !option.contractItem.hasAccessOnConfig && !option.contractItem.hasSpecialRule) {
            this.snackBarSvc.open(`Este menu nao esta disponivel no seu plano`, "Fechar", { duration: 5000 })
            return
        }

        // seleciona o primeiro menu do módulo caso tenhamos selecionado um módulo com sub módulos
        if (!option.modulePath) {
            option = this.menuDashboardSvc.getSubOptionsFrom(option)[0];
        }

        try {
            if (option.absolutePath) {
                const info: IMapCurrentDashboardClient = option.config;
                const screenGroup: EScreenGroups = info.screenGroup.name;
                const allowedMenus: string[] = await this.dashboardSvc.getAllowedMenuIDs(screenGroup);

                if (isInvalidArray(allowedMenus)) {
                    this.warningSvc.showError(this.translations.youDontHaveAccessToAnyMenu.value);
                    return;
                }

                return this.navigateToId(...option.absolutePath);
            }

            return this.navigateToId(routeID.menu, option.path);
        } catch (err) {
            throw err;
        }
    }

    public async navigateToId(...params): Promise<void> {
        const [firstParam, ...otherParams] = params;

        return this.routingService.navigateToId(firstParam, ...otherParams);
    }

    public onChatClick(): void {
        if (this.selectedSideScreenId === ESideMenuScreen.Chat && !this.moduleNavService.isMobileLayout) {
            this.toggleHideMenu();
        } else {
            this.moduleNavService.openChat();
        }
    }

    private toggleHideMenu(): void {
        this.moduleNavService.hideMenus = !this.moduleNavService.hideMenus;
    }

    public goToHome() {
        this.routingService.goToPath(routeList.menu.path)
    }

    public goToGroup() {
        const groupRoute = "/" + RoutingBuilder.groupHome(this.sessionSvc.getCurrentSocialNetworkID());

        this.routingService.goToPath(groupRoute)
    }

    public onClickTickets(event: PointerEvent) {
        event.stopPropagation();
        
        this.crmTicketsService.navigateOrOpenTicketsWindow();
    }
}
