import { MainSignal } from './main-signal';
import { observableList } from './signal-constants';
import {SelectedFile} from "@colmeia/core/src/multi-media/selected-file";



export class FileUploadSignal extends MainSignal {

    public percentage: number;

    constructor(selectedFile: SelectedFile, percentage: number, status: boolean = true) {
        super(observableList.multiMedia.uploadingImage, status);
        this.percentage = percentage;
        this.setObservableInformation(selectedFile);
    }

    public getPercentage(): number { return this.percentage; };
    public setPercentage(percentage: number): void { this.percentage = percentage; };

    public setObservableInformation(info: SelectedFile): void {
        super.setObservableInformation(info);
    };

    public getObservableInformation(): SelectedFile {
        return super.getObservableInformation();
    };

};
