import type { Narrowable } from "../../utility-types";
import type { $Extends, ConvertArrayToFakeTuple } from "../types/validate";
import { isInEnumFactory } from "./is-in-enum-factory";

export namespace Guard {
    export function is<T extends $Extends<T, (undefined | null | Narrowable)[]>>(items: T) {
        const set = new Set<unknown>(items as unknown[]);
        return (item: unknown): item is T[number] => set.has(item);
    }

    export const isEnum = isInEnumFactory;

    export function not<E>(items: ConvertArrayToFakeTuple<E[]>) {
        const set = new Set<unknown>(items);
        return <T>(item: T): item is Exclude<typeof item, E> => !set.has(item);
    }

    export const $not = compose(is, negateGuard)

    export function compose<A extends unknown[], B, C>(fn: (...a: A) => B, other: (b: B) => C) {
        return (...a: A): C => other(fn(...a));
    }

    export function negateGuard<B>(fn: (a: unknown) => a is B) {
        return <A>(a: A): a is Exclude<A, B> => !fn(a);
    }
}