export enum EColmeiaAppTypes {
    widget = 'widget',
    chatEmbedded = 'chatEmbedded',
    /**
     * @deprecated
     * usar o widget ao inves deste campo
     */
    CRMEmbedded = 'CRMEmbedded',
    web = 'web',
    mobile = 'mobile',
}