import { Injectable } from '@angular/core';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ENextGenBotElementType } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EDefaultTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { IGetBotTransactionRequest, IGetBotTransactionResponse, ISaveBotTransactionRequest, ISaveBotTransactionResponse } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-tran-req-resp';
import { EGeneratorTransactionType, IBotTransaction, ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { routeList } from 'app/model/routes/route-constants';
import { DashBoardService } from './dashboard/dashboard.service';
import { GenericNonSerializableService } from './generic-ns.service';
import { RequestBuilderServices } from './request-builder.services';
import { RoutingService } from './routing.service';
import { ServerCommunicationService } from './server-communication.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class BotTransactionService extends GenericNonSerializableService {


    private dashboardSvc: DashBoardService

    constructor(
        api: ServerCommunicationService,
        rbs: RequestBuilderServices,
        session: SessionService,
        routeSvc: RoutingService,
    ) {
        super(
            routeList.dashboard.children.ai.path,
            routeList.dashboard.children.ai.children.botTransactions,
            { api, rbs, session, routeSvc },
        )
    }

    public setDashboardSvc(dashboardSvc: DashBoardService) {
        this.dashboardSvc = dashboardSvc;
    }


    getTransactionsRequest(search?: ITagableSearch, transactionType: EGeneratorTransactionType = null, cursor: string = null): IGetBotTransactionRequest {
        const request: IGetBotTransactionRequest = {
            ...this.baseRequest(apiRequestType.botTransaction.getTransactions),
            ...search,
            cursor,
            transactionType,
        }

        return request;
    }


    public getBotTransactionType(): EGeneratorTransactionType {
        const type = this.dashboardSvc.defaultTag === EDefaultTag.omniSense ? ENextGenBotElementType.nlpTransaction : ENextGenBotElementType.formTransaction;
        return type;
    }


    async getTransactions(search: ITagableSearch, transactionType: EGeneratorTransactionType = null, cursor: string = null): Promise<IGetBotTransactionResponse> {
        const request: IGetBotTransactionRequest = {
            ...this.baseRequest(apiRequestType.botTransaction.getTransactions),
            ...search,
            cursor,
            transactionType,
        }

        return this.send<IGetBotTransactionResponse>(request);
    }

    async save(transaction: IBotTransaction): Promise<ITransactionServer> {
        const request: ISaveBotTransactionRequest = {
            ...this.baseRequest(apiRequestType.botTransaction.saveTransaction),
            ns: transaction
        }

        return ((await this.send(request) as ISaveBotTransactionResponse))?.savedNS;
    }
}
