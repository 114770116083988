<div
  #wrapper
  class="wrapper"
  [class.is-items-visible]="isItemsVisible"
  [class.is-moving]="isMoving"
  [class.is-above-window-half]="isAboveWindowHalf"
  (mouseleave)="hideItems()"
>
  <div
    #container
    class="container"
    [class.is-open]="hasVisibleItems"
  >
    <button
      #fabButton
      mat-fab
      disableRipple
      color="primary"
      class="faben"
      (mouseenter)="showItems()"
      (click)="toggleItems()"
    >
      <mat-icon>dynamic_feed</mat-icon>
    </button>
    <div #itemsContainer class="items-container nice-scroll" (mouseenter)="showItems()">
      <div class="group-container" *ngFor="let group of groupsHash | keyvalue">
        <div class="group-title-container">
          <h5 class="group-title">{{ group.key }}</h5>
          <button class="close-group-button" (click)="closeGroup(group.key)">
            <mat-icon size="16">close</mat-icon>
          </button>
        </div>
        <ul class="button-list">
          <li *ngFor="let windowRef of group.value">
            <button
              matRipple
              (click)="goTopOrMinimize(windowRef)"
              [class.active]="windowRef.isVisible && windowRef.windowIndex === windowSvc.currentActiveIndex">
              <span>{{ windowRef.title }}</span>
              <button class="close-window-button" (click)="closeWindow($event, windowRef)">
                <mat-icon size="18">close</mat-icon>
              </button>
            </button>
          </li>
        </ul>
      </div>

      <ul class="button-list" *ngIf="orphansNotLikeWayne.length">
        <li *ngFor="let windowRef of orphansNotLikeWayne">
          <button
            matRipple
            (click)="goTopOrMinimize(windowRef)"
            [class.active]="windowRef.isVisible && windowRef.windowIndex === windowSvc.currentActiveIndex">
            {{ windowRef.title }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
