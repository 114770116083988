import { Injectable } from '@angular/core';
import { EEventName, EMobileNativeEvents, IMobileNativeData, IReactNativeLog } from '@colmeia/core/src/client-shared/client-native.model';
import { NativeEventsHandlerService } from './native-events-handler.service';
import { NativeEventEmitterService } from './native-event-emitter.service';
import { stringify } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class NativeAppCommunicationService {
  private _isMobile: boolean = false;

  constructor(
    private nativeEventsHandlerService: NativeEventsHandlerService,
    public nativeEventEmitter: NativeEventEmitterService
  ) {
    const constructTime = (new Date()).toISOString();
    console.log(constructTime + " INSTANTIATED event listener native-app-communication.service");
    
    const _log: IReactNativeLog = {
      data: '',
      identifier: 'INSTANTIATING native-events-handler.service',
      isoDate: constructTime
    };
    this.nativeEventsHandlerService.handleLogEvent(_log);
    window.addEventListener("message", event => this.handleMessage(event));

  }

  public requestPushNotificationToken() {
    this.nativeEventEmitter.sendMsg({ type: EMobileNativeEvents.reqPushToken })
  }

  private handleMessage(event: MessageEvent) {
    try {
      const eventData: IMobileNativeData = event.data;
      if (eventData.type === EMobileNativeEvents.event && eventData.payload) {
        this.nativeEventsHandlerService.eventSwitcher(eventData, () => {
          this._isMobile = true;
        });
      }
    } catch (error) {
      // alert(error)
      console.log('ERROR ', error)
      console.error(error)
    }
  }

  public isMobile(): boolean {
    return this._isMobile;
  }

}
