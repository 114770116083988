import { Injectable } from '@angular/core';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { Role, TRoleArray } from '@colmeia/core/src/security/role';
import { IParticipantJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { IGetGroupParticipants } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IGetGroupParticipantResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import {ServerCommunicationService} from "./server-communication.service";
import {RequestBuilderServices} from "./request-builder.services";
import {IParticipantSelectorToRolesSelected} from "../handlers/participant-selector.handler";
import {ClientInfraResponse, IInfraParameters} from "../model/client-infra-comm";

@Injectable({
    providedIn: 'root'
})
export class ParticipantRoleGetterService {
    constructor(
        private serverSVC: ServerCommunicationService,
        private requestBuilderSvc: RequestBuilderServices
    ) {}

    async getRolesAlreadySetInParticipant(idAvatars: TArrayID): Promise<IParticipantSelectorToRolesSelected> {
        console.log({idAvatars});
        const infra: IInfraParameters = this.requestBuilderSvc.getContextNoCallBackNoSpinnningParameters();
        const request: IGetGroupParticipants = {
            ...this.requestBuilderSvc.secureBasicRequest(apiRequestType.security.getGroupParticipants),
            idAvatars,
        };
        const res: ClientInfraResponse = await this.serverSVC.managedRequest(infra, request);
        const groupParticipantResponse: IGetGroupParticipantResponse =
            <IGetGroupParticipantResponse>(res.response);

        if(!res.executionOK)
            return {}

        return this.handleServerResponse(groupParticipantResponse)
    }

    private handleServerResponse(groupParticipantResponse: IGetGroupParticipantResponse): any {
        const participantRoles: IParticipantSelectorToRolesSelected = {}
        const getParticipantRoles = (participant: IParticipantJSON): TRoleArray =>
            participant.idRoles
            ? participant.idRoles.map(idRole => Role.staticFactory(idRole))
            : []

        groupParticipantResponse
            .participants
            .forEach(participant => {
                if (!participantRoles[participant.idAvatar])
                    participantRoles[participant.idAvatar] = {roles: []}
                participantRoles[participant.idAvatar].roles = getParticipantRoles(participant)
            })

        console.log({participantRoles});
        return participantRoles
    }
}
