import { Component, Inject, OnInit } from '@angular/core';
import { TDriverFeedbackArray } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { InteractionType } from "@colmeia/core/src/interaction/interaction-type";
import { Feedback, TFeedbackArray } from "@colmeia/core/src/interaction/feedback";
import { isValidRef, isValidArray } from "@colmeia/core/src/tools/utility";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IColmeiaDialogComponentData } from "../dialog/dialog.component";
import {SessionService} from "../../../services/session.service";
import {OnFeedbackBigIconSelected, OnSerializableSelected} from "../../../services/big-icon.service";
import { TSerializableArray } from '@colmeia/core/src/business/serializable';
import { ToolSection } from 'app/model/tools.model';
import { EHexagonSizes, HandlerHexagonon } from 'app/handlers/hexagono.handler';

export enum EBigIconChooseMode {
    Feedback = 1,
    Serializable
}

export interface BigIconChooseHandler {
    idGroup: TGlobalUID;
    drivers?: TDriverFeedbackArray;
    serializables?: TSerializableArray;
    callback: OnFeedbackBigIconSelected | OnSerializableSelected;
    disableChangeDriver?: boolean;
}

export interface IDriverPresentable {
    interactionType: InteractionType;
    index: number;
    feedbacks: TFeedbackArray;
}

export interface IIcon {
    name: string;
    image: string;
    emoji: boolean;
    data: any;
}

@Component({
    selector: 'app-big-icon-choose-dialog',
    templateUrl: './big-icon-choose-dialog.component.html',
    styleUrls: ['./big-icon-choose-dialog.component.scss']
})
export class BigIconChooseDialogComponent implements OnInit {

    selectedDriver = 0;
    canChangeDriver: boolean = false;
    isFeedback: boolean = false;
    icons: IIcon[] = [];
    section: ToolSection;

    private handler: BigIconChooseHandler;
    drivers: Array<IDriverPresentable> = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IColmeiaDialogComponentData<BigIconChooseHandler>,
        private session: SessionService
    ) {
        this.handler = data.getParamsToChildComponent();
        this.isFeedback = isValidArray(this.handler.drivers);
    }

    ngOnInit() {


        if (isValidRef(this.handler.disableChangeDriver)) {
            this.canChangeDriver = !this.handler.disableChangeDriver
        } else if (this.handler.drivers) {
            this.canChangeDriver = true;
        }

        if (this.handler.drivers) {
            this.drivers = this.handler.drivers
            .sort((a, b) => a.priority - b.priority)
            .map((driver, index) => {
                const type = InteractionType.staticFactory(driver.idDriverInteraction);
                return {
                    index,
                    interactionType: type,
                    feedbacks: Feedback.getInteractionFeedback(type).filter(fee => fee.isFeedbackDisplayedOnReactionBar() &&
                        fee.doIHaveThePermissionsFor(this.session.getPlayerInfo().getGroupAvatarRole(this.handler.idGroup, this.session.getSelectedAvatarID())))
                };
            });
        }

        if (this.handler.serializables) {
            this.section = {
                serializables: this.handler.serializables,
                onItemClick: hexagon => this.handler.callback(hexagon.getEditingSerializable() as any),
                hexagons: this.handler.serializables.map(s => HandlerHexagonon.newForIcon(s, EHexagonSizes.xsmd))
            }
        }
        this.updateDriverIcons();
    }

    onIconClick(icon: Feedback) {
        this.handler.callback(icon);
    }

    updateDriverIcons() {
        this.icons = isValidRef(this.drivers[this.selectedDriver])
            ? this.drivers[this.selectedDriver].feedbacks.map(fb => {
                let image: string = fb.getEmoji();
                let emoji: boolean = true;

                if (! image) {
                    emoji = false;
                    image = fb.getIcon()
                }


                return {
                    name: fb.getName(),
                    image: image,
                    emoji: emoji,
                    data: fb
                }
            })
            : []
        ;
    }
}
