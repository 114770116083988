import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatTable } from '@angular/material/table';
import { IBasicUniversalInfo, TBasicUniveralInfoArray } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { I360Media } from '@colmeia/core/src/core-constants/bot';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { MMconstant, MultimediaInstance, MultimediaObject } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { checkAssetForBrokenVariables, fixBrokenVariablesOnAssetContent } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';
import { IBotActionAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-action-model';
import { IBasicAsset, KAssetType, KAssetTypeClient, KBAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { getAllMessagesOfGroup, getFirstItemOfGroupIndex, isGroupTaggedMessage, updateGroupedTags } from '@colmeia/core/src/shared-business-rules/bot/bot-client-functions';
import {
    DEFAULT_MESSAGE_RECENCY,
    EBotContentEvent,
    EMessageRecency,
    IBotActionAPIAsset,
    IContentBasicAsset,
    IContentEmailHTMLTemplate,
    TContentAssetArray
} from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { isActionAsset, isContentAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import {
    ConnectionRoutesNameRequest,
    ConnectionRoutesNameResponse
} from '@colmeia/core/src/shared-business-rules/connections/connections-requests';
import { IConnectionRouteServer, RouteNameHash } from '@colmeia/core/src/shared-business-rules/connections/endpoint-model';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { allBotTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/bot';
import { IGenerativePromptServer } from '@colmeia/core/src/shared-business-rules/generative/generative-model';
import {
    IBotTransaction,
    ITransactionServer
} from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { ICompiledTemplateWithVariables, TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { ICompileResponse, IEditorVariable, TIEditorVariableArray, compileText, nsToVariable } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isNSType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { ITranslationConfig } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { genericTypedSuggestions } from '@colmeia/core/src/tools/type-utils';
import {
    defaultFields, entries, getUniqueStringID,
    isInvalid,
    isInvalidArray, isValidArray, isValidArrayWithFilter, isValidFunction, isValidRef, isValidString, resetAndUpdateObject, typedClone
} from '@colmeia/core/src/tools/utility';
import { ValueOf } from '@colmeia/core/src/tools/utility-types';
import {
    EEnumPickerMode,
    EnumPickerHandler,
    IEnumPickerHandlerParameter
} from 'app/components/foundation/enum-picker/enum-picker.handler';
import { HexagonUploaderParameterDefaults } from 'app/components/foundation/hexagon-uploader/hexagon-uploader.model';
import { HexagonVisualizationType } from 'app/components/foundation/hexagon/hexagon.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ConnectionRoutePickerHandler } from 'app/handlers/connection-route-picker.handler';
import { EGenericTableGenericRow, GenericTableFieldCreator, GenericTableHandler, IGenericTableRowFieldHexagon, IGenericTableRowFieldText, IGenericTableRowFieldTinyIcon, IOnRowFieldClick } from 'app/handlers/generic-table/generic-table.handler';
import { HexagonUploaderHandler } from 'app/handlers/hexagon-uploader-handler';
import { EHexagonFormat, EHexagonSizes, HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { EVarEditorEntityType, ICompileVariablesResult, IUsedVariablesID, IVarEditorHandlerParameter, VarEditorHandler } from 'app/handlers/var-editor.handler';
import { AssetTypeTranslations } from 'app/model/bot-transaction.model';
import { IInfraParameters } from 'app/model/component/client-infra-comm';
import { IAssetAdderHandler, getAssetInstanceTypeClient } from 'app/model/dashboard/asset-adder.model';
import { BotTransactionService } from 'app/services/bot-transaction.service';
import { CanonicalService } from 'app/services/canonical.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService } from 'app/services/lookup.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { NonSerializableService } from 'app/services/non-serializable.service';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { assign } from 'lodash';
import { Memoize } from 'typescript-memoize';
import { IInputNumberClientCallback, InputNumberHandler } from '../../../handlers/input-number.handler';
import { ColmeiaDialogService } from "../../../services/dialog/dialog.service";
import {
    AssetAdderAddFormDialogComponent, AssetAdderCloseCallback,
    IAssetAdderInstance
} from "./asset-adder-add-form-dialog/asset-adder-add-form-dialog.component";
import { getEmptyMedia } from '@colmeia/core/src/rules/mm-functions';



async function noop(...args): Promise<void> {

}
type $KAssetType = (typeof KBAssetType & typeof EBotActionType);
const $defineKAssetType: $KAssetType = assign(typedClone(KBAssetType), typedClone(EBotActionType));
interface Row {
    type: IGenericTableRowFieldText;
    action: IGenericTableRowFieldText | IGenericTableRowFieldHexagon;

    close?: IGenericTableRowFieldTinyIcon;
    edit?: IGenericTableRowFieldTinyIcon;
}

type TAssetDescriptorIterationItem = {
    type: "mark" | "asset",
    mark?: "start" | "end",
    label?: string,
    asset?: IContentBasicAsset
    emailAsset?: IContentBasicAsset
}
type TAssetDescriptorIteration = TAssetDescriptorIterationItem[];

type TAssetListDescriptorList = {
    checkpoints: Record<string, { start: number, end: number }>,
    iteration: TAssetDescriptorIteration;
}

type TGroupMatchDescriptorResponse = { group: string, hitInSelfBorderGroup: boolean };

const defaultTitle = 'Mensagens';

export type TSafeVarNameAndType = Array<{ varName: string, varType: ENonSerializableObjectType }>


const hiddenAssets = new Set<string>([
    /**
     * Remove chama de api
     * @Deprecated mas pode voltar, um dia, quem sabe...
     */
    KBAssetType.contentAPICall,
]);

@Component({
    selector: 'asset-adder',
    templateUrl: './asset-adder.component.html',
    styleUrls: ['./asset-adder.component.scss']
})
export class AssetAdderComponent extends RootComponent<
    | keyof typeof AssetTypeTranslations
    | 'image'
    | 'apiCall'
    | 'transaction'
    | 'bot'
    | ValueOf<typeof $defineKAssetType>
> implements OnInit,
    IInputNumberClientCallback,
    IAssetAdderInstance {

    public get title() {
        return this.handler.title ?? defaultTitle
    }
    public showInputTextVariables: boolean = true;
    public enumRecencyPickerHandler: EnumPickerHandler<string>;
    public inputNumberHandler: InputNumberHandler;
    public _schemaVariables: TIVariablesArray = [];
    public safeVarsNamesArray: TSafeVarNameAndType = [];
    public genericTableHandler: GenericTableHandler<Row>;
    public get varEditorMode(): EVarEditorEntityType {
        return this.handler.varEditorMode;
    }
    @Input()
    set schemaVariables(schemaVariables: TIVariablesArray) {
        this._schemaVariables = schemaVariables;
        // this.safeVarsNamesArray = schemaVariables?.filter((v) => v.isSafe).map(({ text }) => text) || [];
    }
    loading = true
    private _routeNames: RouteNameHash = {};
    private _handler: IAssetAdderHandler;
    connRouteHandler: ConnectionRoutePickerHandler;
    private templateVariables: TIEditorVariableArray;

    get parameters() { return this.handler };
    get isEditOnly() { return this.parameters.isEditOnly && !this.parameters.isDisabled }

    @Input()
    set handler(handler: IAssetAdderHandler) {
        defaultFields(handler, {
            enableChangePositions: true
        })

        handler.assetTypesEnabled = handler.assetTypesEnabled
            .filter(item => !hiddenAssets.has(item))
        ;

        this._handler = handler;


        this.init();

        this.handler.slave = this;
    }

    @Input()
    disabled: boolean = false;

    public get isDisabled(): boolean {
        return this.handler.isDisabled ?? this.disabled;
    }

    public get limitCharacters(): number {
        return this.handler.limitCharacters;
    }

    public get disableFallback(): true {
        return this.handler.disableFallback;
    }

    public get hasAddButton(): boolean {
        return this.has(this.handler.removeAdd);
    }

    public async fetchRowsNonSerializables(): Promise<void> {
        const toFetch: string[] = this.assets.map(asset => (asset as IBasicAsset as IBotActionAsset).idElement).filter((idElement: string) => isValidRef(idElement));
        await Promise.all(
            toFetch.map((idElement: string) => this.initNSNameIfNecessary(idElement))
        );
    }

    get hasEdit() {
        return this.isEditOnly || this.has(this.handler.removeEdit);
    }

    get hasDelete() {
        return this.has(this.handler.removeDelete);
    }

    get hasDrag() {
        return !this.handler.removeDrag;
    }

    has(isRemoved: boolean | undefined) {
        return !isRemoved && !this.isDisabled && !this.isEditOnly;
    }

    public async initGenericTableHandler(): Promise<void> {
        this.loading = true

        await this.fetchRowsNonSerializables();
        await this.initRows();

        const rowNames: { [key in keyof Row]?: string } = {
            type: 'Tipo',
            action: 'Ação',
        }

        if (this.hasEdit) {
            rowNames.edit = 'Editar';
        }
        if (this.has(this.handler.removeClose)) {
            rowNames.close = 'Fechar';
        }

        this.genericTableHandler = GenericTableHandler.factory<Row>({
            clientCallback: this,
            rowNames,
            hideNavigation: true,
            enableEqualSize: true,
            movePositions: this.handler.enableChangePositions ? { originalElements: this.assets, } : undefined,
            removeShadows: true,
        });

        this.loading = false
    }

    public async onRowFieldClick({ row, columnName }: IOnRowFieldClick<Row>): Promise<void> {
        const asset: IContentBasicAsset = this.mapRowToAsset.get(row) as IContentBasicAsset;

        switch (columnName) {
            case 'close': {
                this.removeAsset(asset);
            }
                break;
            case 'edit': {
                await this.toggleEditAsset(asset);
            }
                break;
        }
    }

    mapRows: WeakMap<IAssetAdderHandler, Row[]> = new WeakMap();
    mapRowToAsset: WeakMap<Row, IBasicAsset> = new WeakMap();

    get rows() { return this.mapRows.get(this.handler); }
    set rows(value: Row[]) { this.mapRows.set(this.handler, value); }

    public getRows(): Row[] {
        return this.rows;
    }

    public dbActionAsset: { [key in KAssetType]?: (asset: IBasicAsset) => Row['action'] } = {
        [$defineKAssetType.content]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text((asset as IContentBasicAsset).content);
        },
        [$defineKAssetType.contentAPICall]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text(this.getRouteName(asset as IBotActionAPIAsset));
        },
        [$defineKAssetType.media]: (asset: IBasicAsset) => {
            const handler: HandlerHexagonon = this.getHexagonHandler(asset as IContentBasicAsset);
            return <IGenericTableRowFieldHexagon>{
                type: EGenericTableGenericRow.Hexagon,
                handler,
            };
        },
        [$defineKAssetType.goBot]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text(this.getBotName(asset as IBotActionAsset));
        },
        [$defineKAssetType.contentGenerator]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text(this.getNSName((asset as IBotActionAPIAsset).idElement));
        },
        [$defineKAssetType.goHuman]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text(this.getNSName((asset as IBotActionAPIAsset).idElement));
        },
        [$defineKAssetType.goActionTree]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text(this.getNSName((asset as IBotActionAPIAsset).idElement));
        },
        [$defineKAssetType.generativo]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text(this.getNSName((asset as IBotActionAPIAsset).idElement));
        },

        [$defineKAssetType.sendTemplate]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text('Ação enviar template');
        },
        [$defineKAssetType.sendCTA]: (asset: IBasicAsset) => {
            return GenericTableFieldCreator.text('Ação enviar CTA');
        },
    };

    public assetToRow = (asset: IBasicAsset) => {
        const action: Row['action'] = ((this.dbActionAsset)[asset.type]).bind(this)(asset);
        const row: Row = {
            type: GenericTableFieldCreator.text(this.printColumnName(asset)),
            action,
        };

        if (!this.handler.removeClose) {
            row.close = <IGenericTableRowFieldTinyIcon>{
                ...GenericTableFieldCreator.icon('close', 'red'),
                enableOnClick: true
            }
        }
        if (!this.handler.removeEdit) {
            row.edit = <IGenericTableRowFieldTinyIcon>{
                ...GenericTableFieldCreator.icon('edit'),
                enableOnClick: true
            }
        }
        return row;
    }

    public initRows(): void {
        const rows: Row[] = isValidArray(this.assets)
            ? this.assets.map((asset: IBasicAsset) => this.assetToRow(asset))
            : []
            ;
        rows.forEach((row: Row, index: number) => {
            if (!this.mapRowToAsset.has(row)) this.mapRowToAsset.set(row, this.assets[index]);
        });
        this.rows = rows;
    }

    public get enableChangePositions(): boolean {
        return this.handler.enableChangePositions;
    }


    public dropTable(event: CdkDragDrop<IContentBasicAsset[]>) {
        if (this.enableChangePositions && isValidFunction(this.handler.onChangeAssetsPositions)) {
            const prevIndex: number = this.assets.findIndex((asset) => asset === event.item.data);
            moveItemInArray(this.assets, prevIndex, event.currentIndex);

            this.handler.onChangeAssetsPositions(this.assets);
            this.updateTableRows();
        }
    }


    get handler(): IAssetAdderHandler {
        return this._handler;
    }

    get schemaVariables(): TIVariablesArray {
        return this._schemaVariables;
    }

    get waitTimeSeconds(): number {
        return this.newAsset.waitTimeSeconds;
    }

    set waitTimeSeconds(value: number) {
        this.newAsset.waitTimeSeconds = value;
    }

    public initInputNumberHandler() {
        this.inputNumberHandler = new InputNumberHandler({
            initialNumber: this.newAsset.daysAfter || 0,
            min: 0,
            max: 999,
            clientCallback: this,
            placeholderText: "",
            suffix: "dia(s)",
            appearance: "fill"
        });
    }

    waitTimeSecondsNumberHandler: InputNumberHandler;
    initWaitTimeSecondsNumberHandler() {
        this.waitTimeSecondsNumberHandler = new InputNumberHandler({
            initialNumber: this.newAsset.waitTimeSeconds || 0,
            min: 0,
            max: 999,
            appearance: "fill",
            clientCallback: {
                onChangeNumberCallback: (number) => {
                    this.newAsset.waitTimeSeconds = number;
                }
            },
            label: "Tempo de espera",
            suffix: 'segundos',
            helperInfo: 'Este conteúdo será enviado antes de realizar a próxima ação, o tempo de espera será respeitado (máximo de 10 segundos)'
        });
    }

    onChangeNumberCallback(number: number) {
        this.newAsset.daysAfter = number;
    }

    assetTypeEnum: typeof KBAssetType = typedClone(KBAssetType);
    botActionType: typeof EBotActionType = typedClone(EBotActionType);

    contentTextTypes: string[] = [
        KBAssetType.content,
        EBotContentEvent.optionTitle,
        EBotContentEvent.optionDescription,
    ]

    $KAssetType: $KAssetType = $defineKAssetType;

    newAsset: IContentBasicAsset;
    addingAsset: boolean = false;
    newAssetUploader: HexagonUploaderHandler;

    assetColumns = [
        'columnName',
        'action',
        'options',
    ];



    bots: TBasicUniveralInfoArray;
    transactions: IBotTransaction[];
    public mapIdNsToNS: Map<string, INonSerializable> = new Map();


    @ViewChild(MatTable) table: MatTable<IContentBasicAsset>;

    public prompt: IGenerativePromptServer;

    private autoOpenWithPreselectedValue: boolean = true;

    constructor(
        private multimediaSvc: MultimediaService,
        private transactionSvc: BotTransactionService,
        private nsSelectSvc: NonSerializableService,
        private rbs: RequestBuilderServices,
        private api: ServerCommunicationService,
        private dashboardSvc: DashBoardService,
        private dialog: ColmeiaDialogService,
        private lookupSvc: LookupService,
        private viewContainerRef: ViewContainerRef,
        private canonicalSvc: CanonicalService,
        private cdr: ChangeDetectorRef,
    ) {
        super({
            ...AssetTypeTranslations,
            'image': gTranslations.common.image,
            'bot': gTranslations.bot.bots,
            ...allBotTranslations,
            "generativo": gTranslations.contentVectors.generativo,
            'apiCall': gTranslations.bot.apiCall,
            'transaction': gTranslations.bot.transaction,
        });
    }


    public descriptor: TAssetListDescriptorList;


    rateControl: UntypedFormControl;
    assetsContentsCache: {
        [idAsset: string]: {
            highlighTemplate?: string,
            hexagonHandler?: HandlerHexagonon,
            routeName?: string,
            handler?: NSPickerHandler
            handlerAux?: NSPickerHandler,
            generativeHandlers?: [NSPickerHandler, NSPickerHandler]
        }
    } = {};

    public async init(): Promise<void> {

        await this.queryConnectionRoutesName();
        this.resetOrLoadAsset();
        this.rateControl = new UntypedFormControl("", [Validators.max(20), Validators.min(0)])

        for (const asset of this.assets) {
            this.mountBasesIfNeeded(asset.type, asset);
            this.cacheAssetContent(asset);
        }

        this.initInputNumberHandler();
        this.initWaitTimeSecondsNumberHandler();
        this.initGenericTableHandler();

        if (this.handler.autoOpenIfEmpty && isValidArray(this.handler.assets, 0) && this.handler.assets.length === 0) {
            setTimeout(() => {
                this.toggleAddAsset();
            }, 100);
        }
        if (this.autoOpenWithPreselectedValue && isValidRef(this.handler.preSelectedValue)) {
            const actionAsset = this.handler.assets.find(asset => isActionAsset(asset.type));
            if (isValidRef(actionAsset))
                setTimeout(() => {
                    this.toggleEditAsset(actionAsset as IContentBasicAsset);
                }, 250);
            else
                setTimeout(() => {
                    this.toggleAddAsset();
                }, 250);
        }
        this.autoOpenWithPreselectedValue = false;

        this.genIteration();
        this.safeVarsNamesArray = await this.getSafeVariablesNames();
        this.checkForBrokenVariables();
    }

    hasUnsafeVariable(asset: IContentBasicAsset): boolean {
        const canonicalMap = this.canonicalSvc.getCanonicalsConfig();
        const hasUnsafeVariable = !!asset.variablesTemplate?.variables.some(
            (variable) => canonicalMap.get(variable.idProperty)?.isSafe === false
        )
        return hasUnsafeVariable;
    }

    async getSafeVariablesNames(): Promise<TSafeVarNameAndType> {
        const tplVaraibles: string[] = this.assets.map(item => item.variablesTemplate?.variables)
            .flat()
            .filter(isValidRef)
            .map(item => item.idProperty);

        const tagVariables: TSafeVarNameAndType = [];

        if (isValidArray(tplVaraibles)) {
            const nss = await NSSharedService.getNSs(tplVaraibles);
            tagVariables.push(
                ...nss.filter(isNSType(ENonSerializableObjectType.colmeiaTags)).map(vr => ({ varName: vr.nName, varType: vr.nsType }))
            );

            this.templateVariables = nss.map(ns => nsToVariable(ns));
        }
        ;
        const canonicals = this.canonicalSvc.getCanonicalsFromPicker();
        const safeVarsNames: TSafeVarNameAndType = canonicals?.filter(v => this.canonicalSvc.isCanonicalSafeOnConfig(v)).map(v => ({ varName: v.nName, varType: v.nsType })) || [];
        const names = [...tagVariables, ...safeVarsNames];
        return names;
    }

    private genIteration() {
        this.descriptor = this.getAssetListDescriptor();
    }

    private idElementTypes: KAssetType[] = [
        EBotActionType.contentGenerator,
        EBotActionType.goHuman,
        EBotActionType.goIsland,
        EBotActionType.goActionTree
    ];

    public isNsPickerPreviewType(assetType: KAssetType) {
        return this.idElementTypes.includes(assetType);
    }

    private cacheAssetContent(asset: IContentBasicAsset): void {
        this.assetsContentsCache[asset.idAsset] = {};

        const assetType = asset.type as KAssetType;

        const actionTypeToNSType = {
            [EBotActionType.contentGenerator]: ENonSerializableObjectType.contentGenerator,
            [EBotActionType.goHuman]: ENonSerializableObjectType.serviceIsland,
            [EBotActionType.goIsland]: ENonSerializableObjectType.serviceIsland,
            [EBotActionType.goActionTree]: ENonSerializableObjectType.bot
        }

        switch (assetType) {
            case KBAssetType.media:
                this.assetsContentsCache[asset.idAsset].hexagonHandler = this.getHexagonHandler(asset);
                break;
            case KBAssetType.contentAPICall:
                this.assetsContentsCache[asset.idAsset].routeName = this.getRouteName(asset as IBotActionAPIAsset)
                break;
            case EBotActionType.contentGenerator:
            case EBotActionType.goHuman:
            case EBotActionType.goIsland:
                this.assetsContentsCache[asset.idAsset].handler = this.dashboardSvc.easyCreateNSPickerHandler({
                    nsType: actionTypeToNSType[assetType],
                    selectedId: (asset as IBotActionAPIAsset).idElement,
                    useDemandedTag: undefined,
                    clientCallback: {},
                }, {
                    hideLabel: true
                });
                break;
            case EBotActionType.goActionTree:
                this.assetsContentsCache[asset.idAsset].handler = this.dashboardSvc.easyCreateNSPickerHandler({
                    nsType: ENonSerializableObjectType.bot,
                    selectedId: (asset as IBotActionAPIAsset).idElement,
                    useDemandedTag: undefined,
                    clientCallback: {
                        onLoadNonSerializables: ([ns]) => {
                            this.assetsContentsCache[asset.idAsset].handlerAux = this.dashboardSvc.easyCreateNSPickerHandler({
                                nsType: ENonSerializableObjectType.bot,
                                selectedId: ns.idParent,
                                useDemandedTag: undefined,
                                clientCallback: {}
                            }, { disabledTitle: 'Bot' });
                        }
                    }
                }, { disabledTitle: 'Menu' });
                break;
            case KBAssetType.generativo:
                const generativeVectorPicker = this.dashboardSvc.easyCreateNSPickerHandler({
                    nsType: ENonSerializableObjectType.contentVector,
                    clientCallback: {},
                    useDemandedTag: false,
                    selectedId: this.getVectorId(asset),
                }, {
                    hideLabel: true,
                    disabledTitle: 'Vetor',
                });
                const generativeChunksPicker = this.dashboardSvc.easyCreateNSPickerHandler({
                    nsType: ENonSerializableObjectType.contentVectorChunk,
                    idParent: this.handler.intent.intentOSConfig?.generative?.idVector,
                    clientCallback: {},
                    useDemandedTag: false,
                    selectedIds: this.getChunksId(asset),
                    maxSelections: Number.MAX_SAFE_INTEGER,
                }, {
                    hideLabel: true,
                    disabledTitle: 'Chunks',
                })

                this.assetsContentsCache[asset.idAsset].generativeHandlers = [generativeVectorPicker, generativeChunksPicker];
                break;
        }
    }

    private getVectorId(asset: IContentBasicAsset = undefined) {
        return asset?.generativeConfiguration?.mirrorKBIntent ?
            this.handler.intent?.intentOSConfig?.generative?.idVector :
            asset.generativeConfiguration?.chunks?.idVector;
    }

    private getChunksId(asset: IContentBasicAsset = undefined) {
        return asset?.generativeConfiguration?.mirrorKBIntent ?
            this.handler.intent?.intentOSConfig?.generative?.idsNSChunk :
            asset?.generativeConfiguration?.chunks?.idsNSChunk;
    }

    ngOnInit() { }

    public shouldEnableEmailHTMLTemplate(index: number) {
        return this.handler?.shouldEnableEmailHTMLTemplate?.() && !index;
    }

    public editEmail(asset: IContentBasicAsset): void {
        if (isValidRef(asset)) {
            this.toggleEditAsset(asset);
            return;
        }
        this.toggleAddAsset();
        const emailAsset: IContentEmailHTMLTemplate = { ...this.newAsset, isEmailHTMLTemplate: true }
        this.newAsset = emailAsset;
    }

    initEnumRecencyPickerHandler() {
        const recency = this.newAsset.recency || undefined;
        this.enumRecencyPickerHandler = null;
        this.enumRecencyPickerHandler = new EnumPickerHandler(<IEnumPickerHandlerParameter<string>>{
            clientCallback: null,
            client: this,
            mode: EEnumPickerMode.Single,
            appearance: "fill",
            // take caution when use translations that use enum and genericTypedSuggestions
            // translations.bot[EMessageRecency...] // equals to any
            translations: genericTypedSuggestions<{ [key in EMessageRecency]: ITranslationConfig }>()({
                [EMessageRecency.everyTime]: gTranslations.bot[EMessageRecency.everyTime],
                [EMessageRecency.once]: gTranslations.bot[EMessageRecency.once],
                [EMessageRecency.onceInTheSession]: gTranslations.bot[EMessageRecency.onceInTheSession],
                [EMessageRecency.replayAfterDays]: gTranslations.bot[EMessageRecency.replayAfterDays],
                [EMessageRecency.conditional]: gTranslations.bot[EMessageRecency.conditional]
            }),
            inputTitle: 'Mostrar conteúdo:',
            enum: EMessageRecency,
            current: recency || DEFAULT_MESSAGE_RECENCY
        });
    }

    onSingleEnumSelection(mode: EMessageRecency) {
        if (mode !== EMessageRecency.replayAfterDays) {
            this.newAsset.daysAfter = 0;
        }
        this.newAsset.recency = mode;
    }

    onAssetTypeChange(event: MatSelectChange): void {
        const assetType: KAssetType = event.value;
        this.mountBasesIfNeeded(assetType);
    }

    private mountBasesIfNeeded(assetType: KAssetType, asset?: IContentBasicAsset): void {
        switch (assetType) {
            case EBotActionType.contentGenerator:
                if (isInvalidArray(this.transactions)) {
                    this.transactionSvc.getTransactions({ demandedTag: this.dashboardSvc.defaultTag }).then(response => {
                        this.transactions = response ? response.transactions : [];
                    });
                }
                break;
            case EBotActionType.goBot:
                if (isInvalidArray(this.bots)) {
                    this.nsSelectSvc.getNonSerializablesBasicInfo(null, ENonSerializableObjectType.bot).then(bots => {
                        this.bots = bots ? bots : [];
                    });

                }
                break;
            case KBAssetType.generativo:
                if (isValidRef(asset.generativeConfiguration?.idPrompt))
                    this.lookupSvc.getNS<IGenerativePromptServer>(asset.generativeConfiguration.idPrompt).then(response => this.prompt = response ?? undefined);
                else
                    this.prompt = undefined;
                break;
        }
    }

    private buildConnectionRouteHandler(asset?: IBotActionAPIAsset): void {
        this.connRouteHandler = ConnectionRoutePickerHandler.factory({
            onSelect: this.onConnectionRouteSelect,
            currentIdRoute: asset && asset.idElement,
            currentIdDomain: null
        });
    }

    private onConnectionRouteSelect = (selected: IConnectionRouteServer): void => {
        (<IBotActionAPIAsset>this.newAsset).idElement = selected && selected.idNS;
        this.connRouteHandler.changeCurrent(selected && selected.idNS, selected && selected.idDomain);
    };

    getRouteName(el: IBotActionAPIAsset): string {
        return (el.type === KBAssetType.contentAPICall) ? (this._routeNames[el.idElement] || '') : null;
    }

    toggleAddAsset(): void {
        this.addingAsset = !this.addingAsset;
        if (this.addingAsset) {
            this.dialog.open<AssetAdderAddFormDialogComponent, IAssetAdderInstance>({
                componentRef: AssetAdderAddFormDialogComponent,
                hideClose: true,
                hideHeader: true,
                dataToComponent: {
                    data: this
                },
                panelClass: "average-size",
                viewContainerRef: this.viewContainerRef
            });
        } else {
            this.dialogCallback.close();
        }
        this.resetOrLoadAsset();
    }

    get assetTypes(): Array<KAssetTypeClient> {
        return this.handler.assetTypesEnabled;
    }

    get assets(): TContentAssetArray {
        return <TContentAssetArray>this.handler.assets;
    }

    public getAssetType(asset: IBotActionAsset): ValueOf<$KAssetType> {
        return asset.type as ValueOf<$KAssetType>;
    }

    public isContent(asset: IBotActionAsset): boolean {
        return (asset.type as ValueOf<$KAssetType>) === this.$KAssetType.content;
    }


    private resetOrLoadAsset(asset?: IContentBasicAsset): void {
        const hasContentType = this.handler.assetTypesEnabled.includes(KBAssetType.content);
        const type: KBAssetType = (hasContentType ? KBAssetType.content : this.handler.assetTypesEnabled[0]) as KBAssetType

        this.newAsset = isValidRef(asset) ? { media: getEmptyMedia(), ...asset } : {
            idAsset: getUniqueStringID(10),
            type,
            content: '',
            media: getEmptyMedia(),
        };

        this.initEnumRecencyPickerHandler();
        this.newAssetUploader = this.generateHexagonUploaderHandler(this.newAsset);
        this.initInputNumberHandler();
        this.initWaitTimeSecondsNumberHandler();
        this.buildConnectionRouteHandler(<IBotActionAPIAsset>asset);

    }


    generateHexagonUploaderHandler(asset: IContentBasicAsset): HexagonUploaderHandler {
        return HexagonUploaderHandler.createNonSerializable({
            ...HexagonUploaderParameterDefaults,
            size: EHexagonSizes.smd,
            idTag: MMconstant.tag.hexagonon,
            format: EHexagonFormat.RoundedSquare,
            fileMode: true,
            onMultimediaObjectChange: () => { },
            idMediaEditing: asset.media?.idMedia,
            multimediaObject: MultimediaObject.getNewMultimediaObjectFrom360([asset.media]),
            onFileSelected: async (mm: MultimediaInstance) => {
                await this.multimediaSvc.genericUploadFile(mm, MMconstant.tag.photo);
                asset.media = mm.getI360Media();
            },
            onMediaDeleted: (mm: MultimediaInstance) => {
                asset.media = getEmptyMedia();
            }
        });
    }

    public getHexagonVisualizationType(asset: IContentBasicAsset): HexagonVisualizationType {
        const mimeType = asset.media?.mymeType;

        if (mimeType && mimeType.startsWith('image')) return HexagonVisualizationType.Image;

        if (mimeType === EMimeTypes.PDF) return HexagonVisualizationType.PDF;

        return HexagonVisualizationType.Unknown;
    }

    @Memoize()
    getHexagonHandler(asset: IContentBasicAsset): HandlerHexagonon {
        const shouldForceImage = Boolean(asset.media?.idMedia && asset.media?.mymeType?.startsWith('image'))
        const hexagonHandler = HandlerHexagonon.newHandler({
            forceImage: shouldForceImage ? asset.media?.idMedia : undefined,
            size: EHexagonSizes.smd,
            fileMode: true,
            format: EHexagonFormat.RoundedSquare,
            visualizationType: this.getHexagonVisualizationType(asset),
        });
        return hexagonHandler;
    }

    public printColumnName = (asset: IBasicAsset): string => {
        let type: KAssetTypeClient = asset.type;

        if (isContentAsset(type)) {
            type = getAssetInstanceTypeClient(asset as IContentBasicAsset);
        }

        return this.translations[type].value;
    }

    getBotName(asset: IBotActionAsset): string {
        return isValidRef(asset)
            && asset.type === EBotActionType.goBot
            && isValidRef(asset.idElement)
            && isValidArray(this.bots)
            ? this.findBotNameByAssetIdElement(asset.idElement)
            : ''
            ;
    }

    findBotNameByAssetIdElement(assetIdElement: string) {
        const found: IBasicUniversalInfo = this.bots.find(bot => bot.primaryID === assetIdElement);
        return found && found.name;
    }

    getTransactionName(asset: IBotActionAsset): string {
        return isValidRef(asset)
            && asset.type === EBotActionType.contentGenerator
            && isValidRef(asset.idElement)
            && isValidArray(this.transactions)
            ? (this.transactions as ITransactionServer[]).find(transaction => transaction.idNS === asset.idElement).nName // @daniel @freitas @types
            : '';
    }

    public getNSName(idNS: string): string {
        if (isInvalid(idNS)) return '';
        this.initNSNameIfNecessary(idNS);
        return this.mapIdNsToNS.get(idNS) && (this.mapIdNsToNS.get(idNS)).nName;
    }

    public async initNSNameIfNecessary(idNS: string): Promise<void> {
        if (!this.mapIdNsToNS.has(idNS)) await this.initNSName(idNS);
    }

    public async initNSName(idNS: string): Promise<void> {
        this.mapIdNsToNS.set(idNS, undefined);
        this.mapIdNsToNS.set(idNS, await this.lookupSvc.getSingleLookupElement(idNS));
    }

    public onGenericTableMovePosition(): void {
        if (isValidFunction(this.handler.onChangeAssetsPositions))
            this.handler.onChangeAssetsPositions(this.assets)
                ;
        this.updateTableRows();
    }

    private updateTableRows(): void {
        if (isValidRef(this.table)) {
            this.table.renderRows();
        }
        this.initGenericTableHandler()
    }

    toggleAsset(edit: boolean) {
        this.toggleAddAsset();
    }

    compileFunction = (raw: string, variables: IEditorVariable[]): ICompileVariablesResult => {
        const compiledResponse: ICompileResponse = compileText(
            raw,
            variables
        );

        return (
            {
                raw,
                compiled: compiledResponse.variablesTemplate.compiledTemplate,
                usedVariablesID: compiledResponse.variablesTemplate.variables
            }
        );
    }


    varEditorData: IVarEditorHandlerParameter
    public getHandlerVarEditor(source: ICompiledTemplateWithVariables): VarEditorHandler {
        this.varEditorData = {
            variables: {
                [EVarEditorEntityType.SchemaProperty]: []// this.possibleTemplateVariables.map(v => ({ variable: addBracket(v.text, textCompiledDelimeters), idProperty: v.idProperty })),
            },
            clientCallback: {
                onVariableEditFinished: this.onVariableEditFinished(source),
            },
            rawText: source.originalTemplate,
            editorIdentity: null,
            disableFallback: true,
            compileFunction: this.compileFunction
        }
        return new VarEditorHandler(this.varEditorData);
    }

    onVariableEditFinished = (compiled: ICompiledTemplateWithVariables) => (
        rawText: string,
        compiledText: string,
        usedVariablesIds: IUsedVariablesID[],
        identity: string,
        messageIfNoBind: string,
    ) => {
        resetAndUpdateObject(compiled, {
            originalTemplate: rawText,
            compiledTemplate: compiledText,
            variables: usedVariablesIds,
            messageIfNoBind: messageIfNoBind,
        });
    }

    editingAsset: boolean = false;

    toggleEditAsset(asset?: IContentBasicAsset): Promise<void> {
        return new Promise((resolve, reject) => {
            this.showInputTextVariables = false;
            this.addingAsset = isValidRef(asset);
            this.editingAsset = this.addingAsset;

            if (this.addingAsset) {
                this.resetOrLoadAsset(asset);
                this.dialog.open<AssetAdderAddFormDialogComponent, IAssetAdderInstance>({
                    componentRef: AssetAdderAddFormDialogComponent,
                    hideClose: true,
                    hideHeader: true,
                    dataToComponent: {
                        data: this,
                        disableClose: true,
                    },
                    panelClass: "average-size",
                    viewContainerRef: this.viewContainerRef
                });
            } else {
                this.dialogCallback?.close();
            }

            setTimeout(() => {
                this.showInputTextVariables = true
                resolve();
            }, 1);
        })
    }

    async editAsset(): Promise<void> {
        this._editAsset(this.newAsset);
    }

    async _editAsset(asset: IContentBasicAsset, replaceInGroups: boolean = true) {
        const success: boolean = await this.handler.saveAsset(asset, this.assets, true);
        if (success) {
            const assetClone = typedClone(asset);
            const hasGroup = this.hasGroup(assetClone);

            if (hasGroup && replaceInGroups) {
                await Promise.all(updateGroupedTags(asset, this.assets).map(a => this._editAsset(a, false)))
            }

            await this.toggleEditAsset();
            await this.queryConnectionRoutesName().then(noop);
            this.cacheAssetContent(assetClone);
            this.updateTableRows();

            this.genIteration();
        }
    }

    public dispatchSaveAsset(): void {
        if (this.addingAsset) this.saveAsset();
    }

    async saveAsset(): Promise<void> {
        if (this.newAsset.type === KBAssetType.contentAPICall && isInvalid((<IBotActionAPIAsset>this.newAsset).idElement)) {
            this.toggleEditAsset();
            return;
        }

        if (this.newAsset.type === KBAssetType.contentAPICall) {
            this.newAsset.content = undefined;
        }

        this._saveAsset(this.newAsset);
    }

    async _saveAsset(asset: IContentBasicAsset) {
        const success: boolean = await this.handler.saveAsset(asset, this.assets);
        if (success) {
            const assetClone = typedClone(asset);
            this.toggleAddAsset();
            this.queryConnectionRoutesName().then(() => this.cacheAssetContent(assetClone));
            this.updateTableRows();
            this.genIteration();
        }
    }

    async removeAsset(asset: IContentBasicAsset): Promise<void> {
        const index: number = this.assets.indexOf(asset);
        const success: boolean = await this.handler.removeAsset(index, this.assets);
        if (success) {
            this.updateTableRows();
            this.genIteration();
        }
    }

    private async queryConnectionRoutesName(): Promise<void> {
        if (this.assets) {
            const names: TArrayID = this.assets.filter(a => a.type === KBAssetType.contentAPICall && !((a as IBotActionAPIAsset).idElement in this._routeNames)).map(a => (a as IBotActionAPIAsset).idElement);

            const hasNames: boolean = isValidArrayWithFilter(names);
            if (hasNames) {
                const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
                const req: ConnectionRoutesNameRequest = {
                    ...this.rbs.secureBasicRequest(apiRequestType.connections.routes.getNameById),
                    names
                };
                const response = await this.api.managedRequest(infra, req);
                Object.entries((<ConnectionRoutesNameResponse>response.response).names)
                    .forEach(entry => {
                        this._routeNames[entry[0]] = entry[1];
                    });
            }
        }
    }

    get canAddMoreAssets(): boolean {
        return isInvalid(this.handler.maxAssets)
            || this.assets.length < this.handler.maxAssets;
    }

    isOnlyText(): boolean {
        return isValidRef(this.handler.onlyText)
    }

    private cdrOfDialog: ChangeDetectorRef;
    private dialogCallback: AssetAdderCloseCallback;

    setDialogInstanceCDR(callback: AssetAdderCloseCallback, cdr: ChangeDetectorRef): void {
        this.dialogCallback = callback;
    }

    markForCheck() {
    }

    hasGroup(asset: IContentBasicAsset) {
        return isGroupTaggedMessage(asset);
    }

    createAssetGroup(asset: IContentBasicAsset, group: string) {
        asset.randomDisplayControl = {
            idFirstAsset: this.assets.find(a => a.randomDisplayControl?.tag === group)?.idAsset || asset.idAsset,
            tag: group
        };

        this.hideCreationInputFor(asset);
        this.genIteration();
    }

    removeAssetGroup(asset: IContentBasicAsset) {
        asset.randomDisplayControl = undefined;
    }

    isFirstItemOfGroup(asset: IContentBasicAsset, index: number): boolean {
        if (!isGroupTaggedMessage(asset)) return false;

        const firstMessageWithGroupIndex = getFirstItemOfGroupIndex(asset.randomDisplayControl, this.assets);
        return firstMessageWithGroupIndex === index;
    }

    isLastItemOfGroup(asset: IContentBasicAsset, index: number): boolean {
        if (!this.hasGroup(asset)) return false;

        const allMessagesOfGroup = getAllMessagesOfGroup(asset.randomDisplayControl, this.assets);
        const lastMessageOfGroup = allMessagesOfGroup.pop();

        return this.assets.findIndex(a => a.idAsset === lastMessageOfGroup.idAsset) === index;
    }

    public getAssetListDescriptor() {
        return this.assets.reduce<TAssetListDescriptorList>(
            (descriptor: TAssetListDescriptorList, asset: IContentBasicAsset, index: number) => {
                if (this.hasGroup(asset) && !isValidRef(descriptor.checkpoints[asset.randomDisplayControl.tag])) {
                    descriptor.checkpoints[asset.randomDisplayControl.tag] = { start: undefined, end: undefined }
                }

                if (this.isFirstItemOfGroup(asset, index)) {
                    descriptor.checkpoints[asset.randomDisplayControl.tag].start = (
                        descriptor.iteration.push({ type: "mark", mark: "start", label: asset.randomDisplayControl.tag }) - 1
                    );
                }

                const item: TAssetDescriptorIterationItem = { type: "asset", asset }
                descriptor.iteration.push(item);
                if (!index) {
                    item.emailAsset = this.handler.emailHTMLTemplate;
                }

                if (this.isLastItemOfGroup(asset, index)) {
                    descriptor.checkpoints[asset.randomDisplayControl.tag].end = (
                        descriptor.iteration.push({ type: "mark", mark: "end" }) - 1
                    );
                }

                return descriptor;
            }, { checkpoints: {}, iteration: [] });
    }

    public assetDropped(event: CdkDragDrop<IContentBasicAsset>) {
        const descriptor = this.descriptor;
        const assetContent: IContentBasicAsset = event.item.data;
        const { group, hitInSelfBorderGroup } = this.getGroupMatchDescriptor(descriptor, event, assetContent.randomDisplayControl?.tag);
        const hasGroup = this.hasGroup(assetContent);
        const hasDroppedInGroup = isValidString(group);
        const isTopOrBottom = (event.currentIndex === 0) || (event.currentIndex === descriptor.iteration.length - 1);

        if (hitInSelfBorderGroup || isTopOrBottom) {
            this.removeAssetGroup(assetContent);
        } else {
            hasDroppedInGroup
                ? this.addAssetGroupByDrag(event.item.data, group)
                : hasGroup && this.removeAssetGroup(assetContent)
        }

        moveItemInArray(this.descriptor.iteration, event.previousIndex, event.currentIndex);

        this.descriptor.iteration
            .filter(a => a.type === 'asset')
            .forEach((i, index) => {
                this.assets[index] = i.asset
            });

        this.genIteration();

        if (isValidFunction(this.handler.onChangeAssetsPositions)) {
            this.handler.onChangeAssetsPositions(this.assets)
        }

    }

    private addAssetGroupByDrag(asset: IContentBasicAsset, group: string) {
        const itemAlreadyOnGroup = this.assets.find(a => this.hasGroup(a) && a.randomDisplayControl.tag === group);
        if (!isValidRef(itemAlreadyOnGroup)) return;

        asset.randomDisplayControl = typedClone(itemAlreadyOnGroup.randomDisplayControl);

        updateGroupedTags(itemAlreadyOnGroup, this.assets);

        this._editAsset(asset, false);
    }

    private getGroupMatchDescriptor(
        descriptor: TAssetListDescriptorList,
        event: CdkDragDrop<IContentBasicAsset>,
        currentGroup: string
    ): TGroupMatchDescriptorResponse {
        const { currentIndex } = event;
        const response: TGroupMatchDescriptorResponse = {
            group: '',
            hitInSelfBorderGroup: false
        };

        entries(descriptor.checkpoints).forEach(([tag, desc]) => {
            if ((currentIndex === desc.start || currentIndex === desc.end) && currentGroup === tag) {
                response.hitInSelfBorderGroup = true;
            }

            if (
                (currentIndex >= desc.start) && (currentIndex <= desc.end) &&
                !(
                    (currentIndex === desc.start && event.distance.y < 0) ||
                    (currentIndex === desc.end && event.distance.y > 0)
                )
            ) {
                response.group = tag
            }
        });

        return response;
    }

    groupCreationOpened: string[] = [];

    toggleShowGroupCreationInputFor(asset: IContentBasicAsset) {
        this.groupCreationOpened.includes(asset.idAsset)
            ? this.groupCreationOpened.splice(this.groupCreationOpened.indexOf(asset.idAsset), 1)
            : this.groupCreationOpened.push(asset.idAsset)
            ;
    }

    hideCreationInputFor(asset: IContentBasicAsset) {
        this.groupCreationOpened = this.groupCreationOpened.filter(i => i !== asset.idAsset);
    }

    // get useFineTunning() {
    //     return this.handler.intent?.intentOSConfig?.generative?.fineTunning ?? false;
    // }

    public hasConditional(item: TAssetDescriptorIterationItem) {
        return item.asset?.isConditionalContent;
    }

    private checkForBrokenVariables() {
        if (!isValidArray(this.templateVariables)) {
            return;
        }

        for (let asset of this.assets) {
            const hasBrokenVars = !checkAssetForBrokenVariables(asset, this.templateVariables);
    
            if (hasBrokenVars) {
                const fixedAssetContent = fixBrokenVariablesOnAssetContent(asset, this.templateVariables);
    
                asset.content = fixedAssetContent;
            }
        }
    }

    onInputGroupName() {
        // fixes potential change detection issue
        this.cdr.markForCheck();
    }
}
