import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { isEmptyObject, isInvalidArray } from "../../../../tools/utility";
import { INSCatalogServer } from "../../../catalog/catalog.interfaces";
import { gTranslations } from "../../../const-text/translations";
import { TNserValidator } from "../validate-nser";


export const catalogNserValidator: TNserValidator = (nser: INSCatalogServer, friendly: FriendlyMessage) => {
    const message = getError();
    if (message) friendly.addReturnGenericErrorMessages([message]);

    return friendly;

    function getError() {
        if (!nser.title) return ('Sem título');
        if (!nser.description) return ('Sem descrição');
        if (!nser.idDatabase) return ('Banco não selecionado');
        if (!nser.providersInfo) return ('Configurações de provedores inválida');
        if (!nser.providers) return 'Sem provedores';
    }
}