import { MessageInstanceHandler, IMessageInstanceParameter } from "../message-instance-handler";
import { TSerializableArray, Serializable } from "@colmeia/core/src/business/serializable";
import { RealTimeGPSTrackerOn } from "@colmeia/core/src/interaction/interactions/location/realtime-tracker-on";
import {realTimeGPSTracking} from "@colmeia/core/src/shared-business-rules/visual-constants";

export class BeginRealtimeTrackingMessageHandler extends MessageInstanceHandler {

    constructor(messageParameter: IMessageInstanceParameter){
        super(messageParameter);
    }

    getMoreOptions(): TSerializableArray {

        const chatItems: TSerializableArray = [];
        const aux: RealTimeGPSTrackerOn = <RealTimeGPSTrackerOn>this.getInteraction();
        if(!aux.isExpired()){
            chatItems.push(Serializable.staticFactory(realTimeGPSTracking));
        }

        return chatItems;
    }
}
