import { TNserValidator } from '../validate-nser';
import { FriendlyMessage } from '../../../../error-control/friendly-message';
import {
    nserInsertNameError,
    nserSmallNameError,
    nserMacrosError,
    nserServiceGroupsError,
    nserVariableTagsError,
    nserMetadatasError,
    nserClientRegisterMetadatasError,
    nserTransferableIslandsError,
    nserFinalizationMetadataError,
    nserAllowedActiveChannelError,
    nserServiceTypeError,
    nserConfigError,
    nserIsActiveError,
    nserCommError,
    nserIdWhatsAppServiceRoutingError,
    nserWelcomeTextError,
    nserIdBotFileError,
    nserIdBotRoteError,
    getFriendlyExtraMessageFromTranslation,
} from '../error-messages';
import { minLength } from '../helpers';
import {
    IAttendentServicePackClient,
    IMacroEntry,
    IAttendentServiceGroup,
} from '../../../attendent-service-pack/attendente-service-pack';
import {
    isInvalid,
    isInvalidArray,
    isValidNumber,
    isValidRef,
    isInEnum,
    isValidFunction,
    values,
    isValidString,
} from '../../../../tools/utility';
import {
    IActiveAgentComm,
    isValidActiveAgentComm,
} from '../../../../shared-business-rules/attendent-service-pack/active-cc';
import {
    IDeployedServices,
    IDServiceParameters,
    IDServiceConfig,
    IDeployedEmbeddedConfigs,
} from '../../../deployed-services/deployed-services';
import {
    EServiceChatType,
    ECustomerServiceGroup,
} from '../../../../comm-interfaces/service-group-interfaces';
import { IAvailableChannelForService } from '../../../social-cc/social-cc-types';
import { EDelivery360Action } from '../../../../comm-interfaces/interaction-interfaces';
import { getTranslationsFields } from '../../../const-text/all-serializables';
import { deployedServicesErrors } from '../error-fields';


const deployedServicesTranslations = getTranslationsFields(deployedServicesErrors);


export const deployedServicesValidator: TNserValidator = (
    nser: IDeployedServices,
    friendly: FriendlyMessage
) => {
    if (!isValidString(nser.nName)) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, 5)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    const parameters: IDServiceParameters = nser.parameters;

    if (isInvalidServiceType(parameters.serviceType)) {
        friendly.addReturnResponse(nserServiceTypeError);
    }

    if (isValidRef(parameters.config) && isInvalidConfig(parameters.config)) {
        // optional parameter
        friendly.addReturnResponse(nserConfigError);
    }

    if (!isValidString(parameters.keyWordForReset)) {
        friendly.addReturnResponse(nserConfigError);
    }

    if (
        isValidRef(parameters.isActive) &&
        isInvalidIsActive(parameters.isActive)
    ) {
        // optional parameter
        friendly.addReturnResponse(nserIsActiveError);
    }
    if (isValidRef(parameters.comm) && parameters.comm.some(isInvalidComm)) {
        // optional parameter
        friendly.addReturnResponse(nserCommError);
    }

    if (
        isValidRef(parameters.idWhatsAppServiceRouting) &&
        isInvalidIdWhatsAppServiceRouting(parameters.idWhatsAppServiceRouting)
    ) {
        // optional parameter
        friendly.addReturnResponse(nserIdWhatsAppServiceRoutingError);
    }

    if (
        isValidRef(parameters.welcomeText) &&
        isInvalidWelcomeText(parameters.welcomeText)
    ) {
        // optional parameter
        friendly.addReturnResponse(nserWelcomeTextError);
    }

    if (
        isValidRef(parameters.idBotFile) &&
        isInvalidIdBotFile(parameters.idBotFile)
    ) {
        // optional parameter
        friendly.addReturnResponse(nserIdBotFileError);
    }

    type ValidateParameters = { [key in keyof IDServiceParameters]?: () => void; };
    const validateServiceTypes: { [key in EServiceChatType]: ValidateParameters } = {
        
        [EServiceChatType.panic]: undefined,
        [EServiceChatType.service]: undefined,
        [EServiceChatType.socialReceptiveCallcenter]: undefined,
        [EServiceChatType.internalSupport]: undefined,
        [EServiceChatType.inactives]: undefined,
        [EServiceChatType.embeddedColmeiaChat]: {
            embedded: () => {
                const validateEmbedded: {
                    [key in keyof IDeployedEmbeddedConfigs]?: () => void;
                } = {
                    sdkMetadataId: () => {
                        // if (isInvalid(parameters.embedded && parameters.embedded.sdkMetadataId)) friendly.addReturnResponse(getFriendlyExtraMessageFromTranslation(deployedServicesTranslations.sdkMetadataId))
                    },
                    botAvatar: () => {
                        if (isInvalid(parameters.embedded && parameters.embedded.botAvatar)) friendly.addReturnResponse(getFriendlyExtraMessageFromTranslation(deployedServicesTranslations.botAvatar))
                        if (isInvalid(parameters.embedded?.userChatContainerGroup)) friendly.addReturnResponse(getFriendlyExtraMessageFromTranslation(deployedServicesTranslations.userChatContainerGroup))
                    },
                    customerFormulary: () => {
                        // if (
                        //     isValidRef(parameters.embedded)
                        //     && parameters.embedded.allowAnonymousTransitionToBot !== true
                        //     && isInvalid(parameters.embedded.customerFormulary && parameters.embedded.customerFormulary.idMetadata)
                        // ) {
                        //     friendly.addReturnResponse(getFriendlyExtraMessageFromTranslation(deployedServicesTranslations.customerFormulary));
                        // }
                    }
                };
                values(validateEmbedded).forEach(subValidator => subValidator())
            },
        },
    };
    const validate: ValidateParameters = validateServiceTypes[parameters.serviceType];
    if (isValidRef(validate)) values(validate).forEach(validator => validator());
    return friendly;

};


function isInvalidServiceType(serviceType: EServiceChatType): boolean {
    return !isInEnum(EServiceChatType, serviceType);
}

function isInvalidConfig(config: IDServiceConfig): boolean {
    return (
        isInvalid(config.idBotRoot) ||
        !isValidNumber(config.expireBotComm, 1) ||
        (isValidRef(config.createCustomerGroup) &&
            !isInEnum(ECustomerServiceGroup, config.createCustomerGroup))
    );
}

function isInvalidIsActive(isActive: boolean): boolean {
    return typeof isActive !== 'boolean';
}

function isInvalidComm(comm: IAvailableChannelForService): boolean {
    if (isInvalid(comm.address)) return true;
    if (!isInEnum(EDelivery360Action, comm.e360Provider)) return true;
    if (isInvalid(comm.idSocialMedia)) return true;

    return false;
}

function isInvalidIdWhatsAppServiceRouting(
    idWhatsAppServiceRouting: string
): boolean {
    return typeof idWhatsAppServiceRouting !== 'string';
}

function isInvalidWelcomeText(welcomeText: string): boolean {
    return typeof welcomeText !== 'string';
}

function isInvalidIdBotFile(idBotFile: string): boolean {
    return typeof idBotFile !== 'string';
}

function isInvalidIdBotRote(idBotRote: string): boolean {
    return typeof idBotRote !== 'string';
}
