import { Serializable } from '@colmeia/core/src/business/serializable';
import { TGlobalUID, TArrayID } from '@colmeia/core/src/core-constants/types';
import { constant } from '@colmeia/core/src/business/constant';
import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { dateToString } from '@colmeia/core/src/tools/utility';
import {HandlerHexagonon, TvalidExagononSize} from "../../../handlers/hexagono.handler";
import {serializableWithPhotoToHexagonHandler} from "../../../handlers/business-handlers";


export enum ExecutableType {
    groups = 1,
    feature,
    chatFeature,
    interaction,
    timedMessageOptions,
    popularityFilter,
    contextFilter,
    interactionType,
    feedbackDriver,
    surveyRendering,
    surveyOptions,
    searchForDropdown,
    searchWhereDropdown,
    feedbacks,
    chatMessage,
    customFeature,
    followPeople,
    followGroup,
    basicInteractionItems
};

export interface IMenuItemState {
    inMemory: boolean;
    accessWithCurrentAvatar: boolean;
    accessWithAnotherAvatar: boolean;
    onlyInvitees: boolean;
    needToRequestParticipant:  boolean;
    canExecuteOperation: boolean;
};

export type TExecutableItemArray = Array<ExecutableItem>;

export interface ExecutableItemData {
    name: string;
    primaryId: string;
}

export class ExecutableItem {
    public isDefault: boolean;
    private serializable: Serializable;
    private data: ExecutableItemData;

    constructor (
        data: Serializable | ExecutableItemData,
        private itemType: ExecutableType,
        private itemState: IMenuItemState,
        isDefault: boolean = false,
    ) {
        this.itemState = itemState;
        this.isDefault = isDefault;
        if (data instanceof Serializable) {
            this.serializable = data;
        } else {
            this.data = data;
        }
    };

    public getItemState(): IMenuItemState {return this.itemState;}
    public getItemType(): ExecutableType {return this.itemType;}
    public getSerializable(): Serializable {return this.serializable; };
    public isTargetInMemory(): boolean {return this.itemState.inMemory; };
    public hasAccessWithCurrentAvatar(): boolean {return this.itemState.accessWithCurrentAvatar; };
    public hasAccessWithAnotherAvatar(): boolean {return this.itemState.accessWithAnotherAvatar; };
    public isOpenGroup(): boolean {return !this.isClosedGroup(); };
    public isGroup(): boolean { return this.serializable ? this.serializable.getObjectTypeID() == constant.objectType.group : false};
    public getName(): string {return this.serializable ? this.serializable.getName() : this.data.name; };
    public getEmoji(): string {return this.serializable ? this.serializable.getEmoji() : null; };
    public getIcon(): string {return this.serializable ? this.serializable.getIcon() : null; };
    public getRoute(): string {return this.serializable ? this.serializable.getAngularRouteID() : null; };
    public getPrimaryID(): TGlobalUID {return this.serializable ? this.serializable.getPrimaryID() : this.data.primaryId; };
    public showPadLockOpen(): boolean {
        return !this.hasAccessWithCurrentAvatar() && this.isClosedGroup();
    };
    public isClosedGroup(): boolean {return this.itemState.onlyInvitees || this.itemState.needToRequestParticipant; };


    public isOnlyForInvitees(): boolean {return this.itemState.onlyInvitees; };
    public isNeedToAskForParticipation(): boolean {return this.itemState.needToRequestParticipant; };

    public getReadOnlyHexagononHandler(hexagonSize?: TvalidExagononSize): HandlerHexagonon {
        return serializableWithPhotoToHexagonHandler(
            this.serializable,
            null,
            hexagonSize ? hexagonSize : 'xs');
    };

    public is(...idArray: TArrayID): boolean {
        if (this.serializable) {
            return this.serializable.is(...idArray);
        } else {
            return idArray.some(id => this.data.primaryId === id);
        }
    };

    public iss(...serializable: TSerializableArray): boolean {
        return this.serializable && this.serializable.iss(...serializable);
    };

    //Menu
    public isOperationAllowed(): boolean {return this.itemState.canExecuteOperation; };
};
