import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { MultimediaInstance, TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/multi-media-instance";
import { TComponentFileInfoArray, TSelectedFileArray} from "@colmeia/core/src/multi-media/file-interfaces";
import { MainHandler } from "./main-handler";
import {IComponentParameter, IRootCallBack} from "../model/component-comm/basic";
import {MultimediaService} from "../services/multimedia.service";
import {DefaulActionOnError} from "../model/client-infra-comm";
import {SpinType} from "../services/screen-spinner.service";
import { MultimediaInstanceUploaderComponent } from "app/components/foundation/multimedia-instance-uploader/multimedia-instance-uploader.component";


export interface IMultimediaComponentCallback extends IRootCallBack {
    onMultimediaSelected(fileSelection: TMultimediaInstanceArray): void;
    onClearMultimedia(): void;
};

export interface IFileSelectionInformation {
    idMultimediaTag: TGlobalUID;
    fileDetais: TComponentFileInfoArray;
}

export interface IMultimediaClientParameter extends IComponentParameter {
    idPlayer: TGlobalUID;
    idAvatar: TGlobalUID;
    maxNumberOfFiles: number;
    maxFileBytesSize?: number;
    idMultimediaTag: TGlobalUID;
    mimeTypeFilter: Array<string>;
    clientCallback: IMultimediaComponentCallback;
    generateHashNow: boolean;
    multimediaService: MultimediaService;
    onFileSelectionCallback?: (fileSelection: TSelectedFileArray) => TSelectedFileArray;
};

export class MultimediaHandler extends MainHandler {
    private fileSelection: TSelectedFileArray;
    private slave?: MultimediaInstanceUploaderComponent;

    constructor(multimediaParameter: IMultimediaClientParameter) {
        super(multimediaParameter);
    };

    public getComponentParameter(): IMultimediaClientParameter {
        return <IMultimediaClientParameter>super.getComponentParameter();
    };

    getAcceptedFileMimes(): Array<string> {
        return this.getComponentParameter().mimeTypeFilter
    }

    setSlave(component: MultimediaInstanceUploaderComponent) {
        this.slave = component;
    }

    getSlave() {
        return this.slave;
    }

    public async onFileSelection(fileSelection: TSelectedFileArray): Promise<void> {
        this.fileSelection = this.getComponentParameter().onFileSelectionCallback?.(fileSelection) || fileSelection;
        const parameter: IMultimediaClientParameter = this.getComponentParameter();

        if (parameter.clientCallback && parameter.clientCallback.onMultimediaSelected) {
            const instanceArray: TMultimediaInstanceArray = [];
            for (const selected of this.fileSelection) {
                const mmInstance: MultimediaInstance = MultimediaInstance.getNewMultimediaInstanceFromSelectedFile(selected);
                instanceArray.push(mmInstance);
            };

            const response = await parameter.multimediaService.getServerMetadata({
                idPlayer: this.getComponentParameter().idPlayer,
                idAvatar:  this.getComponentParameter().idAvatar,
                clientCallback: null,
                defaultActionOnError: DefaulActionOnError.standardErrorMessage,
                optionButtons: [],
                spinType: SpinType.none},
                instanceArray);

            console.log({onFileSelectionResponse: response});
            parameter.clientCallback.onMultimediaSelected(instanceArray);
        };
    };

    public clearFiles(): void {
        this.fileSelection = null;
        if (this.getComponentParameter().clientCallback && this.getComponentParameter().clientCallback.onClearMultimedia) {
            this.getComponentParameter().clientCallback.onClearMultimedia();
        }
    }

    public getFileFileSelectionInformation(): TSelectedFileArray {
        return this.fileSelection;
    };
};
