import { InjectionToken } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { HandlerHexagonon } from "app/handlers/hexagono.handler";
import { NotificationDialogRef } from "./notification-dialog.ref";
import { TAppColorThemes } from "@colmeia/core/src/ui/ui.model";

export const NOTIFICATION_DIALOG_DATA = new InjectionToken<any>('ColmeiaNotificationDialogData');

export type TNotificationDialogIconConfig = {
  matIcon?: string;
  url?: string;
  hexagonon?: HandlerHexagonon;
};

export type TNotificationDialogActionCallback = (ref: NotificationDialogRef) => void

export type TNotificationDialogCustomAction = {
  label: string;
  handler: TNotificationDialogActionCallback;
  color?: ThemePalette;
};

export type TNotificationDialogConfig<D> = {
  message?: string;
  idNotification?: string;
  duration?: number;
  label?: string;
  icon?: TNotificationDialogIconConfig;
  mainAction?: TNotificationDialogActionCallback;
  imageUrl?: string;
  clockTick?: number;
  customActions?: TNotificationDialogCustomAction[];
  theme?: TAppColorThemes;
  mute?: boolean;
  data?: D;
  customStyle?: {
    container?: Partial<CSSStyleDeclaration>;
  };
  containerTopPosition?: string;
  containerRightPosition?: string
};
