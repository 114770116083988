import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { EAllSerializable, defineTranslations } from "../all-serializables";

const backupSnapshotErrors = defineTranslations({
    idSerializable: EAllSerializable.BackupSnapshotError,
    fields: {
        missing: MetaTranslation.field(1, 'Faltam'),
        missingPackage: MetaTranslation.field(2, 'O pacote não foi selecionado'),
        missingTarget: MetaTranslation.field(3, 'A entidade não foi selecionada'),
    },
});

export const allBackupScreenOptionsTranslations = {
    ...backupSnapshotErrors,
};
