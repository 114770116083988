<div class="dropdown" [id]="type" [ngStyle]="locationCss">
  
  <!-- animation to open/close modal -->
  <div [id]="type" [ngClass]="animationClass">
    <div class="button-group">
      
      <!-- menu items -->
      <ul class="menu-itens center-content">
        <li *ngFor="let link of links; let i = index"
        [class]="link.submenu ? 'submenu-item' : ''"
          (click)="!link.submenu && onDropdownItemClicked(link)" [style.background-image]="'url(' + background + ')'" safeHtml>
          
          <!-- menu item inner -->
          <div class="menu-item item-separate"
            (click)="link.submenu && showHideSubmenu($event)" 
            [style.border]="(showSubmenuItems && i == indexOpenedSubmenu) ? 'none':''"
            [style.display]="link.submenu  ? 'flex':''">{{link?.title}}
            <i class="material-icons" [hidden]="!link.submenu">keyboard_arrow_down</i>
          </div>

          <!-- menu sub-items -->
          <ul [hidden]="!link.submenu" *ngIf="showSubmenuItems">
            <li style="padding:0" *ngFor="let link of link.submenu">
              <!-- submenu item inner -->
                <div class="menu-item item-separate">
                    {{link.name}}
                </div> 
            </li>
          </ul>

        </li>
      </ul>

    </div>
  </div>
</div>
