import { Injectable } from '@angular/core';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { TArrayAvatar } from '@colmeia/core/src/core-constants/types';
import { ESecurityApiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { IGetAvatarsThatParticipateInAGroupRequest, TSortBy } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IAvatarWithParticipationClockTick, IGetAvatarsThatParticipateInAGroupResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { ArrayUtils } from '@colmeia/core/src/tools/utility/arrays/array-utils';
import { ServerCommunicationService } from './server-communication.service';
import { Observable, Subject } from 'rxjs';
import { TGlobalUID } from '@colmeia/core/src/business/constant';

export type TArrayAvatarClocktick = Array<{avatar: Avatar, clockTickBegin: number}>
export type TAvatarResponseClient = Promise<{avatars: TArrayAvatar, cursor: string | undefined, idAvatarToClocktickBegin: Record<string, IAvatarWithParticipationClockTick>} | undefined>;

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  private avatarsChangedSubjects: { [key: TGlobalUID]: Subject<Avatar> } = {};
  
  constructor(
    private api: ServerCommunicationService,

  ) { }

  isAvatarBeingObserved(avatarId: TGlobalUID): boolean {
    if(!this.avatarsChangedSubjects[avatarId]) {
      return false;
    }

    return this.avatarsChangedSubjects[avatarId].observers.length > 0
  }

  broadcastAvatarChanged(avatar: Avatar) {
    const avatarId = avatar.getPrimaryID();

    this.avatarsChangedSubjects[avatarId].next(avatar);
  }

  observeAvatarChanges(avatarId: TGlobalUID): Observable<Avatar> {
    if (!this.avatarsChangedSubjects[avatarId]) {
      this.avatarsChangedSubjects[avatarId] = new Subject<Avatar>;
    }

    return this.avatarsChangedSubjects[avatarId].asObservable();
  }

  async getAvatarsFromGroup(maxResultsPerPage: number, idGroupTarget: string, cursor: string | undefined = undefined, sortBy: TSortBy | undefined = undefined): TAvatarResponseClient {
    
    const response: IGetAvatarsThatParticipateInAGroupResponse | undefined = await this.api.sendRequest<
    IGetAvatarsThatParticipateInAGroupRequest, IGetAvatarsThatParticipateInAGroupResponse>(
      ESecurityApiRequestType.getAvatarsThatParticipateInAGroup
    )({
      cursor,
      idGroupTarget,
      maxResultsPerPage,
      sortBy
    })
    if (!response) return;
    
    const idAvatarToClocktickBegin = ArrayUtils.uniquizeByAttribute(response.avatarsThatParticipate, 'primaryID');
    
    const avatarArray: TArrayAvatar = [];
    
    for (const avatar of response.avatarsThatParticipate) {
      avatarArray.push(Avatar.factoryMessage(avatar));
    };

    return {avatars: avatarArray, cursor: response.cursor, idAvatarToClocktickBegin}
  }
}
