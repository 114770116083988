import { TNserValidator } from "../validate-nser";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { INextGenBotServer, ENextGenBotElementType, IBotRootServer, IBotMenuItemServer, IBotMenuContainerServer } from "../../../bot/bot-model";
import { nserInsertNameError, nserSmallNameError, contentBotError, screenOptionToError } from "../error-messages";
import { minLength, nserNameMinLength } from "../helpers";
import { isInvalid, isInvalidNumber, isValidRef, isValidArray, isValidString } from "../../../../tools/utility";
import { EBotEventType, IBotEvent } from "../../../bot/bot-event-model";
import { IFriendlyExtraMessage } from "../../../../comm-interfaces/business-interfaces";
import { validateBotAsset } from "./bot-asset";
import { hasRequiredBotContentProperty } from "../../../bot/bot-function-model-helper";
import { WhatsApp } from '../../../social-media/whatsapp-interface';
import { botErrors } from '../error-fields';
import { KBAssetType } from '../../../bot/bot-asset-model';
import { TMimeTypeArray, onlyDocumentAllowed, onlyImageAllowed, onlyVideoAllowed } from '@colmeia/core/src/multi-media/file-interfaces';


function defineSource<T extends { [key in string]: any }>(source: T) {
    return source
}

const source = defineSource({
    define: ['string', 'int32']
} as const);


type afdsa = typeof source.define

type TypesMap = {
    string: string;
    i32: number;
    bool: boolean;
}

const errors = screenOptionToError(botErrors);

// type SourceDefine = ListTypes<typeof source.define>;

export const botValidator: TNserValidator = (nser: INextGenBotServer, friendly: FriendlyMessage) => {

    //validações gerais para todos os levels de bot
    if (!nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    if (nser.events.length > 0) {
        nser.events.forEach((event: IBotEvent) => {
            const errors = validateBotAsset(event);
            if (isValidArray(errors)) {
                errors.forEach(err => {
                    console.log(err);
                    //friendly.addReturnResponse(err);
                })
            }
        })
    }

    //validação botRoot
    if (nser.botLevel === ENextGenBotElementType.root) {
        const botRoot = <IBotRootServer>nser;
        if (isInvalidNumber(botRoot.incrementor)) {
            ///add friendly error
        }
        if (isValidRef(botRoot.firstAction) && isInvalid(botRoot.firstAction.idElement)) {
            ///add friendly error
        }
    }

    //validação menuContainer
    if (nser.botLevel === ENextGenBotElementType.menuContainer) {
        const botMenuContainer = <IBotMenuContainerServer>nser;

        if (isValidRef(botMenuContainer.whatsApp?.header)) {
            const headerTypes = WhatsApp.Message.Template.Structure.Format;
            if (botMenuContainer.whatsApp.header.format === headerTypes.Text) {
                !botMenuContainer.whatsApp.header.content.content.trim() && friendly.addReturnResponse(errors.emptyWhatsappTextHeader);
            } else {
                if (botMenuContainer.whatsApp.header.content.media?.size) {
                    switch (botMenuContainer.whatsApp.header.format) {
                        case headerTypes.Document:
                            if (!isAllowedMediaTypes(onlyDocumentAllowed, [botMenuContainer.whatsApp.header.content.media.mymeType]))
                                friendly.addReturnResponse(errors.invalidDocumentType);
                            break;
                        case headerTypes.Video:
                            if (!isAllowedMediaTypes(onlyVideoAllowed, [botMenuContainer.whatsApp.header.content.media.mymeType]))
                                friendly.addReturnResponse(errors.invalidVideoType);
                            break;
                        case headerTypes.Image:
                            if (!isAllowedMediaTypes(onlyImageAllowed, [botMenuContainer.whatsApp.header.content.media.mymeType]))
                                friendly.addReturnResponse(errors.invalidImageType);
                            break;
                    }
                } else {
                    friendly.addReturnResponse(errors.emptyWhatsappMediaHeader);
                }
            }
        }

        if (isValidRef(botMenuContainer.whatsApp?.footer))
            !botMenuContainer.whatsApp.footer.content.content.trim() && friendly.addReturnResponse(errors.emptyWhatsappFooter);
    }

    //validação botMenuItem
    if (nser.botLevel === ENextGenBotElementType.botMenuItem) {
        if (isInvalid((<IBotMenuItemServer>nser).action)) {
            ///add friendly error
        }
    }

    if (hasRequiredBotContentProperty(nser) && !isValidString(nser.content)) {
        friendly.addReturnResponse(errors.content);
    }

    return friendly;
}

export function isAllowedMediaTypes(allowedMediaTypes: TMimeTypeArray, types: TMimeTypeArray) {
    return allowedMediaTypes.some(type => type === types[0]);
}