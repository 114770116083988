import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IBasicRequest } from '@colmeia/core/src/comm-interfaces/colmeia-services-req-res';
import { ConditionsProcessor, IConditionalData } from '@colmeia/core/src/general-form/general-form-condition-processor';
import { apiRequestType, EJobProcess, EUserFunctions } from '@colmeia/core/src/request-interfaces/message-types';
import { IUserFunctionCompileRequest, IUserFunctionRunFromParamsRequest, IUserFunctionRunRawData, IUserFunctionRunRawRequest, IUserFunctionRunRequest, IUserFunctionsEditRequest, IUserFunctionServiceRunRequest, IUserFunctionsGetPayloadInput, IUserFunctionsGetPayloadOutput, IUserFunctionsGetPayloadRequest, IUserFunctionsGetPayloadResponse, IUserFunctionsSaveRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IUserFunctionCompileResponse, IUserFunctionRunRawResponse, IUserFunctionRunResponse, IUserFunctionsEditResponse, IUserFunctionServiceRunResponse, IUserFunctionsSaveResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { TApiDownstreamResourceMethodsDocumentation } from '@colmeia/core/src/shared-business-rules/colmeia-apis/colmeia-external-api-req-res.documentation';
import { IHttpConnectionResult } from '@colmeia/core/src/shared-business-rules/connections/endpoint-model';
import { EMetaEngagementConditionType, IBasicCondition } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep, TAllNserConnectionTypes } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { EUserFunctionRunType, EUserMessageType, ICustomUserFunctionParams, IUserFunctionModel, IUserFunctionModelServer, TAllUserFunctionRuntimeFunctions, TVLReturn, VLReturnTypedMessage } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { NotificationDialogService } from 'app/components/notifications-dialog/notification-dialog.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { EAppAlertTypes } from 'app/services/snack-bar';
import { FunctionsDocsComponent } from '../functions-docs/functions-docs.component';
import { UserFunctionsMessageComponent } from './user-functions/user-functions-message.component';
import { UserFunctionsMessageHandler } from './user-functions/user-functions-message.handler';
import { EMutationType } from '@colmeia/core/src/shared-business-rules/journaling/journaling-req-res.dto';



export interface IUserMessageTypeConfig {
    type: EAppAlertTypes;
    icon: string;
}

type TUserMessageTypeConfig = {
    [key in EUserMessageType]: IUserMessageTypeConfig;
}


export const userMessageTypeConfig: TUserMessageTypeConfig = {
    [EUserMessageType.Standard]: {
        type: EAppAlertTypes.Primary,
        icon: 'priority_high',
    },
    [EUserMessageType.Error]: {
        type: EAppAlertTypes.Error,
        icon: 'error', //
    },
    [EUserMessageType.Warning]: {
        type: EAppAlertTypes.Warning,
        icon: 'warning',
    },
}


@Injectable({
    providedIn: 'root'
})
export class UserFunctionsService {

    constructor(
        private embedded: EmbeddedChatService,
        private api: ServerCommunicationService,
        private dialogSvc: MatDialog,
        private colmeiaDialogSvc: ColmeiaDialogService,
        private notificationDialogSvc: NotificationDialogService,
        private dashboardSvc: DashBoardService,
    ) { }

    async create(nser: IUserFunctionModel): Promise<IUserFunctionModelServer> {
        nser.nsType = ENonSerializableObjectType.userFunction
        const result: IUserFunctionsSaveResponse = <IUserFunctionsSaveResponse>await this.api.doRequest<IUserFunctionsSaveRequest>(
            apiRequestType.userFunctions.createOrEdit, {
            ns: nser,
            saveType: EMutationType.create
        });

        return result?.userFunction
    }

    async edit(nser: IUserFunctionModelServer): Promise<IUserFunctionModelServer> {
        const result: IUserFunctionsEditResponse = <IUserFunctionsEditResponse>await this.api.doRequest<IUserFunctionsSaveRequest>(
            apiRequestType.userFunctions.createOrEdit, {
            ns: nser,
            saveType: EMutationType.edit
        });

        return result?.userFunctionNser
    }

    public getPayloadsFromRequests = this.api.safeSendRequest<IUserFunctionsGetPayloadRequest, IUserFunctionsGetPayloadResponse>(EJobProcess.getPayloads);


    async validate(cond: IBasicCondition, data: IConditionalData): Promise<boolean> {
        if (cond.metaConditionType === EMetaEngagementConditionType.userFunction) {
            const response: IUserFunctionRunResponse = <IUserFunctionRunResponse>await this.api.doRequest<IUserFunctionRunRequest>(
                apiRequestType.userFunctions.run, {
                runType: EUserFunctionRunType.fromDatabase,
                runData: {
                    idNS: cond.idUserFunction,
                    userValue: data.currentValue,
                    info: data.computed
                }
            });
            return response.result.isValid;
        } else {
            return ConditionsProcessor.validateCondition(cond, data);
        }
    }

    // Public methods
    public openTypedMessage(
        typedMessage: VLReturnTypedMessage
    ) {
        const handler = UserFunctionsMessageHandler.factory({
            typedMessage,
            clientCallback: {},
        });

        return this.dashboardSvc.dynamicDialog(UserFunctionsMessageComponent, handler, undefined, { panelClass: ['no-padding', 'transparent-background'] });
    }

    async validateService(cond: IBasicCondition, data: IConditionalData, shouldPrintTypedMessage: boolean = false): Promise<boolean> {
        if (cond.metaConditionType === EMetaEngagementConditionType.userFunction) {
            const req: IUserFunctionServiceRunRequest = {
                runData: {
                    idNS: cond.idUserFunction,
                    userValue: data.currentValue,
                    info: data.computed
                },
                requestType: apiRequestType.userFunctions.runService,
                serviceId: this.embedded.idService,
            };

            const response: IUserFunctionServiceRunResponse = <IUserFunctionServiceRunResponse>await this.api.colmeiaServicesRequest<IUserFunctionServiceRunRequest, IUserFunctionServiceRunResponse>(req);

            if (shouldPrintTypedMessage && response.result.typedMessage) {
                this.openTypedMessage(response.result.typedMessage);
            }

            return response.result.isValid;
        } else {
            return ConditionsProcessor.validateCondition(cond, data);
        }
    }

    async compile(userFunctionData: IUserFunctionModel): Promise<IUserFunctionCompileResponse> {
        const result: IUserFunctionCompileResponse = <IUserFunctionCompileResponse>await this.api.doRequest<IUserFunctionCompileRequest>(
            apiRequestType.userFunctions.compile, {
            runType: EUserFunctionRunType.fromParams,
            ns: userFunctionData
        }, undefined, true, false, true);
        return result
    }

    async testRun(userFunctionData: IUserFunctionModelServer,
        functionCode: string, customParams: ICustomUserFunctionParams
    ): Promise<TVLReturn> {
        const response: IUserFunctionRunResponse = <IUserFunctionRunResponse>await this.api.doRequest<IUserFunctionRunFromParamsRequest>(
            apiRequestType.userFunctions.run, {
            runType: EUserFunctionRunType.fromParams,
            runData: {
                idNS: userFunctionData.idNS,
                userValue: undefined,
                info: undefined,
                fnName: userFunctionData.nName,
                customFunctionJS: functionCode
            },
            customParams,
        }
        );
        return response.result;
    }

    async sendRawRequest(
        idConnection: IdDep<ENonSerializableObjectType.connectionRoute | ENonSerializableObjectType.connection>,
        endpoint: TApiDownstreamResourceMethodsDocumentation,
        type: TAllNserConnectionTypes
    ): Promise<IHttpConnectionResult> {
        const requestData: IBasicRequest = {
            method: endpoint.method,
            url: `${endpoint.hostname}${endpoint.uri}`,
            headers: JSON.stringify(endpoint.headers),
            body: endpoint.postBody ? JSON.stringify(endpoint.postBody.example) : null,
        };
        const userFunctionRunRawRequestData: IUserFunctionRunRawData = {
            data: {
                requestDataToOverwrite: undefined,
                type,
                idRoute: type == ENonSerializableObjectType.connectionRoute
                    ? <IdDep<ENonSerializableObjectType.connectionRoute>>idConnection
                    : undefined,
                idConnection: type == ENonSerializableObjectType.connection
                    ? <IdDep<ENonSerializableObjectType.connection>>idConnection
                    : undefined
            }
        };

        const response: IUserFunctionRunRawResponse = <IUserFunctionRunRawResponse>await this.api.doRequest<IUserFunctionRunRawRequest>(
            EUserFunctions.runRawRequest,
            userFunctionRunRawRequestData
        );

        return response.rawResponse;
    }

    showDocumentation(method?: TAllUserFunctionRuntimeFunctions): void {
        this.dialogSvc.open(FunctionsDocsComponent, {
            data: method,
            panelClass: ["average-size", "no-padding"]
        });
    }
}
