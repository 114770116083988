import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { IBasic1x1CallContact, IGetActiveCustomerStatusResponse } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { ICustomerCreationFullfiledFields } from "app/components/customer-finder/customer-finder.handler.def";
import { CustomerFinderComponent } from "app/components/customer-finder/customer-finder.component";
import { DashboardActiveCallCreateContactComponent } from "app/components/dashboard/dashboard-attendance-active-call/dashboard-active-call-create-contact/dashboard-active-call-create-contact.component";
import { TISearchActiveCallResultArray } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";


export interface IDashboardActiveCallCreateContactClientCallback {
    onClose?(): void;
    onContactCreated(contact: IBasic1x1CallContact, response: IGetActiveCustomerStatusResponse): void;
    onContactEdited(contact: IBasic1x1CallContact, response: IGetActiveCustomerStatusResponse): void;
    getCustomerFinderComponent?(): CustomerFinderComponent;
}

export interface IDashboardActiveCallCreateContactParameters extends IComponentParameter {
    fulfilledFields?: ICustomerCreationFullfiledFields,
    clientCallback: IDashboardActiveCallCreateContactClientCallback;
    forcePhoneSuffix?: string;
    internationContact?: boolean;
    isEditing: boolean;
    contactsList?: TISearchActiveCallResultArray;
}

export class DashboardActiveCallCreateContactHandler extends MainHandler {
    private constructor(parameter: IDashboardActiveCallCreateContactParameters) { super(parameter) }
    public static factory(parameter: IDashboardActiveCallCreateContactParameters) { return new DashboardActiveCallCreateContactHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IDashboardActiveCallCreateContactParameters }

    slave?: DashboardActiveCallCreateContactComponent;
    refresh() {
        this.slave?.init();
    }
    setForcePhoneSuffix(phone: string) {
        this.getComponentParameter().forcePhoneSuffix = phone;
        this.refresh();
    }
}
