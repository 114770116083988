import { ListRange } from "@angular/cdk/collections";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { isValidNumber } from "@colmeia/core/src/tools/utility";
import { debounce } from "lodash";
import { Subject, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

export class FileDetailVirtualScrollStrategy {
    static getKeyForIndex(currentPage: number, index: number): string {
        return `${currentPage}-${index}`;
    };

    private readonly _scrolledIndexChange = new Subject<number>();

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged());
    static defaultItemSize: number = 68;
    static minBufferItensLength: number = 6;
    static maxBufferItensLength: number = 12;

    private customSizeIndexesMap: Map<string, number> = new Map();
    private _viewport: CdkVirtualScrollViewport | null = null;
    private _currentPage: number = 0;
    private fixedItemSize: number;

    constructor(
        readonly defaultItemSize: number = FileDetailVirtualScrollStrategy.defaultItemSize,
        readonly minBufferItensLength: number = FileDetailVirtualScrollStrategy.minBufferItensLength,
        readonly maxBufferItensLength: number = FileDetailVirtualScrollStrategy.maxBufferItensLength
    ) {}

    onDataLengthChanged() {
        this._updateTotalContentSize();
        this._updateRenderedRange();
    }

    attach(viewport: CdkVirtualScrollViewport) {
        this._viewport = viewport;
        this._updateTotalContentSize();
        this._updateRenderedRange();
    }

    /** Detaches this scroll strategy from the currently attached viewport. */
    detach() {
        this._scrolledIndexChange.complete();
        this._viewport = null;
    }

    onContentScrolled() {
        this._updateRenderedRange();
    }

    scrollToIndex(index: number, behavior: ScrollBehavior): void {
        if (this._viewport) {
            this._viewport.scrollToOffset(this.calculateSizeAtIndex(index), behavior);
        }
    }

    setItemSize(index:number, size: number) {
        this.customSizeIndexesMap.set(this.getKeyForIndex(index), size);
        this.onDataChangedDebounced();
    }

    onDataChangedDebounced = debounce(() => {
        this.onDataChanged();
    }, 200);

    private getKeyForIndex(index: number): string {
        return FileDetailVirtualScrollStrategy.getKeyForIndex(this._currentPage, index);
    }

    /** Update the viewport's rendered range. */
    private _updateRenderedRange() {
        if (!this._viewport) {
            return;
        }

        const itemSize = isValidNumber(this.fixedItemSize) ? this.fixedItemSize : this.defaultItemSize;

        const renderedRange = this._viewport.getRenderedRange();
        const newRange = {start: renderedRange.start, end: renderedRange.end};
        const viewportSize = this._viewport.getViewportSize();
        const dataLength = this._viewport.getDataLength();
        let scrollOffset = this._viewport.measureScrollOffset();
        // Prevent NaN as result when dividing by zero.
        let firstVisibleIndex = itemSize > 0 ? scrollOffset / itemSize : 0;

        const minBufferSize = this.calculateCountedItems(newRange.start, this.minBufferItensLength, 'before');
        const maxBufferSize = this.calculateCountedItems(newRange.end, this.maxBufferItensLength, 'after');

        const startBuffer = scrollOffset - newRange.start * itemSize;
        if (startBuffer < minBufferSize && newRange.start != 0) {
            const expandStart = Math.ceil((maxBufferSize - startBuffer) / itemSize);
            newRange.start = Math.max(0, newRange.start - expandStart);
            newRange.end = Math.min(
                dataLength,
                Math.ceil(firstVisibleIndex + (viewportSize + minBufferSize) / itemSize),
            );
        } else {
            const endBuffer = newRange.end * itemSize - (scrollOffset + viewportSize);

            if (endBuffer < maxBufferSize && newRange.end != dataLength) {
                const expandEnd = Math.ceil((maxBufferSize - endBuffer) / itemSize);
                if (expandEnd > 0) {
                        newRange.end = Math.min(dataLength, newRange.end + expandEnd);
                        newRange.start = Math.max(
                            0,
                            Math.floor(firstVisibleIndex - maxBufferSize / itemSize),
                        );
                }
            }
        }

        this._viewport.setRenderedRange(newRange);
        this._viewport.setRenderedContentOffset(this.calculateSizeAtIndex(newRange.start));
        this._scrolledIndexChange.next(Math.floor(firstVisibleIndex));
    }

    public setFixedItemsSize(size?: number) {
        this.fixedItemSize = size;
    }

    private _updateTotalContentSize() {
        if (!this._viewport) {
            return;
        }

        const containerSize = this.calculateSizeAtIndex(this._viewport.getDataLength());

        this._viewport.setTotalContentSize(containerSize);
    }

    private calculateCountedItems(offset: number, length: number, direction: 'before' | 'after' = 'after'): number {
        let result: number = 0;
        const isRight: boolean = direction === 'after';
        const stopIndex = isRight ? offset + length : offset - length;

        for(let i = offset; isRight ? i < stopIndex : i > stopIndex; isRight ? ++i : --i)
            result += this.getItemSizeForIndex(i);
        return result;
    }

    private calculateSizeAtIndex(index: number): number {
        let result: number = 0;
        let i = 0;

        for(;i < index; ++i) result += this.getItemSizeForIndex(i);

        return result
    }

    private getItemSizeForIndex(index: number): number {
        if(isValidNumber(this.fixedItemSize)) return this.fixedItemSize;

        const customSize = this.customSizeIndexesMap.get(this.getKeyForIndex(index));
        return isValidNumber(customSize) ? customSize : this.defaultItemSize;
    }

    public setCurrentPage(value: number) {
        this._currentPage = value;
    }

    onDataChanged() {
        this._updateTotalContentSize();
        this._updateRenderedRange();
    }

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    onContentRendered() {
        /* no-op */
    }

    /** @docs-private Implemented as part of VirtualScrollStrategy. */
    onRenderedOffsetChanged() {
        /* no-op */
    }
}
