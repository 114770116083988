<form name="form">
  <mat-expansion-panel #panel [(expanded)]="expanded" hideToggle aria-disabled="false" style="padding:0">
    <mat-expansion-panel-header (click)="panel.toggle()">
      <div class="wrapper" [class.toggle-visible]="!lockExpansion">
        <div class="actions-container toggle-action">
          <button class="action-button expand-button" (click)="!lockExpansion && expanded = !expanded" [class.expanded]="expanded">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
        <div class="main-container">
          <div class="main-row" style="margin-bottom: 16px;">
            <div class="toggle-options">

              <button class="toggle-item pid" [copyToClipboard]="field.idProperty" matTooltip="Copiar ID" matTooltipPosition="above" matTooltipClass="mat-tooltip-info">
                <mat-icon>fingerprint</mat-icon>
                ID: <span style="text-transform: none;">{{ field.idProperty }}</span>
              </button>

              <mat-divider [vertical]="true"></mat-divider>

              <button class="toggle-item" [class.checked]="field.isPrimaryKey" (click)="field.isPrimaryKey = !field.isPrimaryKey">
                <mat-icon>vpn_key</mat-icon>
                Primary Key
              </button>
              <button class="toggle-item" [class.checked]="field.required" (click)="field.required = !field.required">
                <mat-icon>radio_button_checked</mat-icon>
                {{ translations.required }}
              </button>
              <button class="toggle-item" [class.checked]="field.multipleAnswers" (click)="field.multipleAnswers = !field.multipleAnswers">
                <mat-icon>list</mat-icon>
                {{ translations.multipleLabel }}
              </button>
              <button class="toggle-item" [class.checked]="field.isNotVisibleByAgent" (click)="field.isNotVisibleByAgent = !field.isNotVisibleByAgent">
                <mat-icon>visibility_off</mat-icon>
                Não visível ao agente
              </button>
              <button class="toggle-item" [class.checked]="field.isLGPDSensitive" (click)="field.isLGPDSensitive = !field.isLGPDSensitive">
                <mat-icon>admin_panel_settings</mat-icon>
                Sensível a LGPD
              </button>
              <button class="toggle-item" [class.checked]="field.isVolatile" (click)="field.isVolatile = !field.isVolatile">
                <mat-icon>file_download_off</mat-icon>
                Volátil
              </button>
            </div>
          </div>
          <div class="main-row" [ngStyle]="{'margin-bottom': _propertyNameError ? '24px' : '0'} ">
            <mat-form-field class="gfield" appearance="fill">
              <mat-label>{{ translations.nameLabel }}</mat-label>
              <input matInput [autofocus]="!field.prompt" required
                [(ngModel)]="field.prompt" type="text"
                (keydown)="$event.stopPropagation()"
                name="prompt"
              >
              <mat-error>{{ translations.fieldRequired }}</mat-error>
            </mat-form-field>
            <mat-form-field class="gfield" appearance="fill" [class.mat-form-field-invalid]="_propertyNameError">
              <mat-label>{{ translations.propNameLabel }}</mat-label>
              <input
                matInput
                required
                [(ngModel)]="field.propertyName"
                type="text"
                (keydown)="$event.stopPropagation()"
                (input)="validatePropertyName()"
                name="propName"
                [disabled]="field.isCatalogType"
              >
              <mat-error>{{ translations.fieldRequired }}</mat-error>
              <mat-hint *ngIf="_propertyNameError" class="error" style="margin: 0 -10px; color: red">{{ _propertyNameError }}</mat-hint>
            </mat-form-field>
            <app-enum-picker class="gfield" [required]="true" [handler]="typeEnumPicker"></app-enum-picker>
            <app-ns-picker class="gfield" style="margin-top: -6px;" *ngIf="localCanonicalPicker && !canonicalDisabled" [handler]="localCanonicalPicker"></app-ns-picker>
          </div>
        </div>
        <div class="actions-container">
          <button class="action-button" *ngIf="!field.isCatalogType" (click)="delete.next()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel-header>
    <div class="content-container" matExpansionPanelContent>
      <section class="nested-schema" *ngIf="isObject()">
        <app-general-form-field *ngFor="let item of field.nestedSchema;let i = index;" [isCorporateSearch]="isCorporateSearch" [field]="item" (delete)="removeField(i)"></app-general-form-field>
        <button style="float: right;margin-top: 14px;"  mat-flat-button color="primary" (click)="addInnerField()" type="submit">
          {{ translations.addField }}
        </button>
      </section>
      <ng-container *ngIf="isMultipleChoice()">
        <mat-form-field class="multiple-choice-field" floatLabel="always">
          <mat-label class="mdc-floating-label--float-above">Opções</mat-label>
          <mat-chip-grid
            #multipleChoiceOptions
            aria-label="Opções"
            cdkDropList
            (cdkDropListDropped)="moveChoice($event)"
          >
            <mat-chip-row
              *ngFor="let item of choices; let i = index;"
              class="ordenable-chip"
              cdkDrag
              cdkDragLockAxis="y"
              selectable
              removable
              (removed)="deleteChoice(i)">
              {{ field.choices[i].text }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
              autofocus
              placeholder="Digite aqui"
              [matChipInputFor]="multipleChoiceOptions"
              (matChipInputTokenEnd)="addChoiceByInput($event)"
            >
          </mat-chip-grid>
          <mat-hint>Pressione enter para adicionar</mat-hint>
        </mat-form-field>
      </ng-container>
    </div>
  </mat-expansion-panel>
</form>
