import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EEnumPickerMode, EnumPickerHandler, IEnumPickerHandlerParameter } from "./enum-picker.handler";
import {
    getEnumOptionsTranslationsAndEnumValues,
    IEnumPickerOption
} from "@colmeia/core/src/shared-business-rules/enum-db";
import { isValidRef, isValidFunction, typedArrayToHash, typedCloneLodash, isValidString, isInvalidString } from "@colmeia/core/src/tools/utility";
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

const allValues = 'all';

@Component({
    selector: 'app-enum-picker',
    templateUrl: './enum-picker.component.html',
    styleUrls: ['./enum-picker.component.scss']
})
export class EnumPickerComponent implements OnInit {
    @ViewChild('multipleSelect') matSelect: MatSelect;

    private _handler: EnumPickerHandler<any>;
    options: IEnumPickerOption[] = [];
    optionsHash: Record<string, IEnumPickerOption> = {};
    singleValue: any;
    multipleValues: any[];

    @Input()
    required: boolean = false;

    @Input()
    disabled: boolean = false;

    public get isDisabled(): boolean {
        return this.disabled || this.parameters.disabled;
    }

    @Input()
    error?: string;

    @Input()
    set handler(val: EnumPickerHandler<any>) {
        this._handler = val;
        this.init();
    }

    clone: EnumPickerHandler<any>;
    init() {
        this.buildOptions();
        this.assignCurrentValue();
    }

    reset() {
        this.multipleValues = this.parameters.currents;
        this.matSelect?.close();
    }

    get handler(): EnumPickerHandler<any> {
        return this._handler;
    }

    get parameters(): IEnumPickerHandlerParameter<any> {
        return <IEnumPickerHandlerParameter<any>>this._handler.getComponentParameter();
    }

    get isSingleMode(): boolean {
        return this.parameters.mode === EEnumPickerMode.Single;
    }

    get allowClear(): boolean {
        return this.parameters.allowClear === true;
    }

    get title(): string {
        return this.parameters.inputTitle;
    }

    constructor() { }

    ngOnInit() { }

    private buildOptions(): void {

        // @daniel => should only keep first translations mode
        if (isValidRef(this.parameters.translations)) {
            this.options = getEnumOptionsTranslationsAndEnumValues(
                this.parameters.enum,
                this.parameters.translations,
                this.parameters.ignoreValues
            );
        }

        if (isValidFunction(this.parameters?.client?.mapOption)) {
            this.options = this.options.map(option => this.parameters.client.mapOption(option));
        }
        
        if (isValidString(this.parameters.selectAllMultiple) && !this.isSingleMode) {
            const allSelected = this.parameters.currents?.length === this.options.length;
            if (allSelected) this.parameters.currents!.push(allValues);
            this.options.unshift({text: this.parameters.selectAllMultiple, enumValue: allValues});

        }

        this.optionsHash = typedArrayToHash(this.options, "enumValue")();
    }

    public get appearance(): MatFormFieldAppearance {
        return this.parameters.appearance;
    }

    private assignCurrentValue(): void {
        if (this.isSingleMode) {
            this.singleValue = isValidRef(this.parameters.current) ? this.parameters.current : undefined;
        } else {
            this.multipleValues = (this.parameters.currents || []);
        }
    }

    onSingleChanged() {
        this.parameters.client.onSingleEnumSelection?.(this.singleValue);
    }
    
    onMultiselectNgModelChange(selected: Array<string>) {
        if (isInvalidString(this.parameters.selectAllMultiple)) {
            this.multipleValues = selected;
            return;
        }

        if (this.multipleValues.length > selected.length) {
            // Desselecionou

            if (this.multipleValues.includes(allValues) && !selected.includes(allValues)) {
                // desselecionoy a opção all
                this.multipleValues = [];
                return;
            }
            this.multipleValues = selected.filter(option => option !== allValues);
        } 
        else {
            //selecionou algum valor
            if (!this.multipleValues.includes(allValues) && selected.includes(allValues)) {
                // selecionoy a opção all
                this.multipleValues = this.options.map(option => option.enumValue);
                return;
            }
            this.multipleValues = selected;
        }
    }

    onMultipleChanged() {
        if (this.useActionBtns()) return;
        let value = this.multipleValues;
        if (isValidString(this.parameters.selectAllMultiple) && value.includes(allValues)) value = value.filter(option => option !== allValues);
        this.parameters.client.onMultipleEnumSelection?.(value);
    }

    onCloseSelect() {
        if (!this.useActionBtns()) return;

        this.handler.getComponentParameter().currents = this.multipleValues;
        this.parameters.client.onMultipleEnumSelection?.(this.multipleValues);
    }

    changeSingleOption(value: any) {
        this.singleValue = value;
        this.onSingleChanged();
    }

    useActionBtns(): boolean {
        return !!this.parameters.useActionBtns;
    }
}
