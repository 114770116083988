import { Injectable } from "@angular/core";
import { constant, TGlobalUID } from "@colmeia/core/src/business/constant";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { EChatBtnsVisualElements, ESurveyChatOptions } from "@colmeia/core/src/core-constants/constant-visual-elements.enums";
import { clientErrorCodes } from "@colmeia/core/src/error-control/client-error.constants";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { InteractionType } from "@colmeia/core/src/interaction/interaction-type";
import { throwCustomFieldError } from "@colmeia/core/src/tools/utility";
import { IMessageInstanceSelectChosenOptionStandardMsg } from "../message-instance.service";
import { Feedback } from "@colmeia/core/src/interaction/feedback";
import {SessionService} from "../../../../../services/session.service";
import {GroupChatServices} from "../../../../../services/group-chat.services";
import {ColmeiaDialogService} from "../../../../../services/dialog/dialog.service";
import {BigIconService} from "../../../../../services/big-icon.service";
import { AnnotationsService } from "app/services/annotations.service";
import { IAnnotationsModalParameters, AnnotationsModalHandler } from "app/handlers/annotations-modal-handler";
import {canAddFormFieds} from "@colmeia/core/src/shared-business-rules/visual-constants";
import {IGeneralFormAnswer} from "@colmeia/core/src/general-form/general-form-answer";


@Injectable()
export class FeatureCarrierService {
    constructor(
        private sessionSvc: SessionService,
        private groupChatSvc: GroupChatServices,
        private colmeiaDialogSvc: ColmeiaDialogService,
        private bigIcon: BigIconService,
        private annotationsSvc: AnnotationsService,
    ) {}

    initLabels() {
        return {
            filledBy: Serializable.staticFactory(canAddFormFieds)
                .getSerializableText(constant.serializableField.auxiliars.aux01)
        }
    }

    async sendReactionToServer(interaction: Interaction, driver: InteractionType, bigIcon: Serializable): Promise<void> {
        try {
            const selectedGroupID: TGlobalUID = this.sessionSvc.getSelectedGroupID();
            const participantID: TGlobalUID = this.sessionSvc
                .getParticipant(selectedGroupID)
                .getPrimaryID();

            this.groupChatSvc.saveReaction(
                interaction.getPrimaryID(),
                interaction.getInteractionType().getPrimaryID(),
                bigIcon.getPrimaryID(),
                driver.getPrimaryID(),
                participantID,
                this.sessionSvc.getSelectedGroupID()
            );

        } catch (error) {
            console.log({sendReactionToServer: error});
            //@jurja rever mensagem de erro
            throwCustomFieldError(
                clientErrorCodes.errorPrimaryID,
                clientErrorCodes.chat.generalAnswer, true,
                'GeneralAnswerMessageService.sendReactionToServer'
            );
        }
    }

    onDropdownOptionClicked(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg): void {
        const optionClickedID = chosenOption.optionSelected.getPrimaryID()
        switch (optionClickedID) {
            case EChatBtnsVisualElements.Feedbacks:
                const onFeedbackSelected = (feedback: Feedback) => {
                    this.sendReactionToServer(
                        chosenOption.interactionClicked,
                        feedback.getInteractionType(),
                        feedback
                    );
                };
                this.bigIcon.openFromFeedbackDrivers(
                    chosenOption.interactionClicked.getDriverFeedbacks(),
                    chosenOption.currentGroup,
                    onFeedbackSelected
                );
            break;

            case ESurveyChatOptions.view:
                this.annotationsSvc.openFor( new AnnotationsModalHandler(<IAnnotationsModalParameters>{
                    persist: true,
                    serializableId: chosenOption.interactionClicked.getPrimaryID(),
                    onSaveAnnotationCallback: (anser: IGeneralFormAnswer) => {}
                } ))
                break;

            default:
                break;
        }
    }
}
