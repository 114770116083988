import { Component, EventEmitter, Output, Input, OnInit, Optional, ViewChild, ElementRef } from "@angular/core";
import {
    AbstractControl,
    UntypedFormControl,
    ValidatorFn,
    Validators
} from "@angular/forms";
import { ETypeOfContact } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { emailPattern } from "@colmeia/core/src/core-constants/types";
import {
    IAgentSearrchActiveCallCustomer,
    IBasic1x1CallContact,
    IGetActiveCustomerStatusResponse
} from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp";
import { isValidString } from "@colmeia/core/src/tools/utility";
import { AttendanceActiveEditCallApiService } from "app/services/attendance-active-edit-call.service";
import { DashboardActiveCallCreateContactHandler } from "./dashboard-active-call-create-contact.handler";

import { allCountriesPhonCodes } from "@colmeia/core/src/shared-business-rules/social-cc/all-countries-phone-codes";
import { getNationalNumber, getPossibleCountriesFromPhone, ICountryPhoneCode, isValidGeneralPhoneNumber, isValidPhoneNumber, TCountriesCodes } from "@colmeia/core/src/shared-business-rules/social-cc/config-cell";
import { getUniquized, isUniquizeid, removeUniquizer } from "@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules";
import { SnackMessageService } from "app/services/snack-bar";
import {
    parsePhoneNumber
} from 'libphonenumber-js';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileAvatarStoreService } from "app/services/profile-avatar-store.service";
import { AvatarService } from "app/services/avatar.service";

function sanitizeNumberInput(value: string): string {
    return value.replace(/[\s\D]/g, "");
}

function simplePhoneNumberLengthValidator(
    minLength: number,
    maxLength: number
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const sanitizedValue = sanitizeNumberInput(control.value);
        const hasValidLength: boolean =
            sanitizedValue.length >= minLength &&
            sanitizedValue.length <= maxLength;

        return hasValidLength ? null : { phoneNumberLength: true };
    };
}

const localEmailValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    return !control.value.length || emailPattern.test(control.value) ? null : { localEmail: true };
};


@Component({
    selector: "app-dashboard-active-call-create-contact",
    templateUrl: "./dashboard-active-call-create-contact.component.html",
    styleUrls: ["./dashboard-active-call-create-contact.component.scss"],
})
export class DashboardActiveCallCreateContactComponent implements OnInit {

    public _handler: DashboardActiveCallCreateContactHandler;
    public get handler(): DashboardActiveCallCreateContactHandler {
        return this._handler;
    }
    @Input()
    public set handler(value: DashboardActiveCallCreateContactHandler) {
        this._handler = value;
        this.init();
        this._handler.slave = this;
    }
    public get parameters() {
        return this.handler.getComponentParameter();
    }

    public nameFormControl = new UntypedFormControl("", [Validators.required]);

    public emailFormControl = new UntypedFormControl("", [
        localEmailValidator,
    ]);

    public fbIdInputControl = new UntypedFormControl("", [Validators.required]);

    public colmeiaIdInputControl = new UntypedFormControl("", [Validators.required]);

    public typeOfContact: ETypeOfContact = ETypeOfContact.mobile;
    public contactTypes: { [key in ETypeOfContact]?: string } = {
        [ETypeOfContact.mobile]: "Celular",
        // [ETypeOfContact.fbUserPageID]: "Id no Facebook",
        // [ETypeOfContact.colmeia]: "Id na Colmeia",
    };

    private uniquizedEmail: string;
    brazilPhoneData: ICountryPhoneCode = { name: "Brazil", code: 55, iso: "BR", areaCodeMask: '00', phoneMask: '0 0000-0000||0000-0000' }

    allCountriesPhoneCodes: TCountriesCodes = [...allCountriesPhonCodes].sort((a, b) => a.code - b.code).filter(ctr => ctr.code !== 55);

    public ddiFormControl: UntypedFormControl = new UntypedFormControl('', [
        Validators.required,
    ]);
    public dddFormControl = new UntypedFormControl('', [
        Validators.required,
    ]);
    public dddMask: string = '00';

    private uneditedNumber: string | undefined;

    public numberFormControl: UntypedFormControl = new UntypedFormControl("", [
        Validators.required,
        simplePhoneNumberLengthValidator(5, 13),
    ]);
    public numberMask: string;

    @ViewChild('ddiSelect') ddiSelect: ElementRef;

    hasDdd: boolean;

    isEditing: boolean;
    duplicatedContact: boolean = false;

    get ddiValue(): ICountryPhoneCode {
        return this.numberFormControl.value
    }

    constructor(
        private attendanceActiveCallAPI: AttendanceActiveEditCallApiService,
        private snackMsgSvc: SnackMessageService,
        private dialogRef: MatDialogRef<any>,
        private profileAvatarStoreSvc: ProfileAvatarStoreService,
        private avatarSvc: AvatarService
    ) { }


    ngOnInit(): void {
        this.init();
    }

    public init() {
        this.isEditing = !!this.parameters.isEditing

        this.uneditedNumber = this.parameters.fulfilledFields?.phoneNumber;

        if (!this.isEditing) {
            this.handleCreateContactFormControls();
            return;
        }

        this.handleEditContactFormControls()
    }

    handleEditContactFormControls() {
        const { name, email, phoneNumber, fbId, colmeiaId } = this.parameters.fulfilledFields || {};
        if (isValidString(name)) {
            this.nameFormControl.setValue(name);
        }

        if (isValidString(fbId)) {
            this.fbIdInputControl.setValue(fbId);
        }

        if (isValidString(colmeiaId)) {
            this.colmeiaIdInputControl.setValue(colmeiaId);
        }

        if (isValidString(email)) {
            if (isUniquizeid(email)) {
                this.uniquizedEmail = getUniquized(email);
                this.emailFormControl.setValue(removeUniquizer(email));
            } else {
                this.emailFormControl.setValue(email);
            }
        }

        const phone = this.parameters.forcePhoneSuffix ? this.parameters.forcePhoneSuffix : phoneNumber
        if (isValidString(phone)) {
            this.setPhone(phone);
        }
    }

    getPhoneNumberPlaceholder() {
        return this.isInternationalContact() ? "" : "Ex: 9 8888-8888";
    }

    handleCreateContactFormControls() {
        if (this.isInternationalContact()) {
            this.numberMask = this.getInternationalDefaultMask();
            this.ddiFormControl = new UntypedFormControl(null, [
                Validators.required,
            ]);
            this.numberFormControl = new UntypedFormControl(this.parameters.fulfilledFields?.phoneNumber, [
                Validators.required,
                simplePhoneNumberLengthValidator(5, 13),
            ]);
            this.hasDdd = false;

        } else {
            this.numberMask = '0 0000-0000';
            this.ddiFormControl = new UntypedFormControl(this.brazilPhoneData, [
                Validators.required,
            ])
            this.numberFormControl = new UntypedFormControl(this.parameters.fulfilledFields?.phoneNumber, [
                Validators.required,
                simplePhoneNumberLengthValidator(9, 9)
            ]);
            this.hasDdd = true;
        }
        this.numberFormControl.disable();

    }

    private getInternationalDefaultMask(): string {
        return '0000000000000||000000000000||00000000000||0000000000||000000000||00000000||0000000||000000||00000';
    }

    public closeDialog() {
        this.dialogRef.close();
        this.handler.getComponentParameter().clientCallback.onClose?.();
    }

    public async handleSubmit() {
        const forcePhoneSuffix = this.parameters.forcePhoneSuffix
        if (forcePhoneSuffix && (this.typeOfContact === ETypeOfContact.mobile) && !this.getTarget().endsWith(forcePhoneSuffix)) {
            this.snackMsgSvc.openError(`O número deve terminar com o mesmo da busca ${forcePhoneSuffix}`);
            return;
        }

        if (!this.validateFields()) return;

        const contact: IBasic1x1CallContact = {
            name: this.nameFormControl.value,
            email: this.getEmail(),
            target: this.getTarget(),
            typeOfContect: this.typeOfContact,
        };


        if (this.isEditing && this.parameters.fulfilledFields?.idAvatar) {
            const response: IGetActiveCustomerStatusResponse = await this.attendanceActiveCallAPI.editContact(contact, this.parameters.fulfilledFields?.idAvatar);
            const idAvatar = response.idAvatar;

            this.parameters.clientCallback.onContactEdited(contact, response);

            this.broadcastAvatarNameChange(idAvatar, contact.name);
        } else {
            const response: IGetActiveCustomerStatusResponse = await this.attendanceActiveCallAPI.createContact(contact);

            this.parameters.clientCallback.onContactCreated(contact, response);
        }

        this.closeDialog();
    }

    getEmail(): string {
        if (this.uniquizedEmail) {
            return this.emailFormControl.value + this.uniquizedEmail;
        }
        return this.emailFormControl.value;
    }

    checkIsEditing() {
        return this.isEditing;
    }

    public validateFields(): boolean {
        if (this.ddiFormControl.invalid) return false;
        if (this.hasDdd && this.dddFormControl.invalid) return false;
        if (this.duplicatedContact) return false;
        if (this.invalidNumber) return false;
        if (this.emailFormControl.invalid) return false;
        if (this.nameFormControl.invalid) return false;
        if (
            this.typeOfContact === ETypeOfContact.mobile &&
            this.numberFormControl.invalid
        ) {
            return false;
        }

        if (
            this.typeOfContact === ETypeOfContact.fbUserPageID &&
            this.fbIdInputControl.invalid
        ) {
            return false;
        }

        if (
            this.typeOfContact === ETypeOfContact.colmeia &&
            this.colmeiaIdInputControl.invalid
        ) {
            return false;
        }

        return true;
    }

    private getTarget(): string {
        switch (this.typeOfContact) {
            case ETypeOfContact.mobile:
                let phoneNumber: string;

                if (this.hasDdd) {
                    phoneNumber = String(this.ddiFormControl.value.code) + this.dddFormControl.value + this.numberFormControl.value;
                } else {
                    phoneNumber = String(this.ddiFormControl.value.code) + this.numberFormControl.value;
                }
                return sanitizeNumberInput(phoneNumber);
            case ETypeOfContact.fbUserPageID:
                return this.fbIdInputControl.value;
            case ETypeOfContact.colmeia:
                return this.colmeiaIdInputControl.value;
            default:
                return "";
        }
    }

    get showNumberInput(): boolean {
        return this.typeOfContact === ETypeOfContact.mobile;
    }

    get showFBIdInput(): boolean {
        return this.typeOfContact === ETypeOfContact.fbUserPageID;
    }

    get showColmeiaIdInput(): boolean {
        return this.typeOfContact === ETypeOfContact.colmeia;
    }

    isInternationalContact(): boolean | undefined {
        return this.parameters.internationContact;
    }

    handleDdiChange(country: ICountryPhoneCode) {
        this.hasDdd = country.iso === 'BR';

        if (country.areaCodeMask) {
            this.dddMask = country.areaCodeMask;
        } else {
            this.dddMask = '000';
        }

        if (country.phoneMask) {
            this.numberMask = country.phoneMask;
        } else {
            this.numberMask = this.getInternationalDefaultMask();
        }

        this.checkIfNumberIsDuplicated();
        this.isValidNumber();
    }

    handleDddChange() {
        this.checkIfNumberIsDuplicated();
        if (!!this.ddiFormControl.value?.code) {
            this.isValidNumber();
        }
    }
    invalidNumber: boolean;
    handleNumberChange() {
        this.checkIfNumberIsDuplicated();
        this.isValidNumber();
    }

    isValidNumber() {
        this.invalidNumber = !isValidGeneralPhoneNumber(this.getTarget(), this.ddiFormControl.value.iso);
    }

    checkIfNumberIsDuplicated() {
        const number = this.getTarget();

        const isDuplicated = this.parameters.contactsList?.some((contact) => contact.target === number && contact.target !== this.uneditedNumber)
        this.duplicatedContact = !!isDuplicated;
    }

    public setPhone(number: string) {
        this.typeOfContact = ETypeOfContact.mobile;


        if (number.startsWith('55')) {
            this.hasDdd = true;
            const validNationalNum = getNationalNumber(number);

            if (!validNationalNum) {
                this.snackMsgSvc.openError('Este não é um número válido. Tente adicionar o contato novamente.', 5000);
                this.closeDialog();
                return;
            }
            const { ddd, localNumber } = validNationalNum;

            this.ddiFormControl.setValue(this.brazilPhoneData);
            this.ddiFormControl.disable();

            this.dddFormControl.setValue(ddd);
            this.dddFormControl.disable();

            this.numberFormControl.setValue(localNumber);
            this.numberFormControl.disable();
            this.numberMask = '0 0000-0000';
            return;

        }

        this.hasDdd = false;
        if (number.startsWith('1')) {
            const phoneNumber = parsePhoneNumber(number, 'US');

            if (!phoneNumber.isValid()) {
                this.snackMsgSvc.openError('Este não é um número válido. Tente adicionar o contato novamente.', 5000);
                this.closeDialog();
                return;
            }

            const usData = this.allCountriesPhoneCodes.find(data => data.iso === 'US')
            this.ddiFormControl.setValue(usData);
            this.ddiFormControl.disable();
            this.numberFormControl.setValue(phoneNumber.nationalNumber);
            this.numberFormControl.disable();
            this.numberMask = usData?.phoneMask!
            return;

        }

        const possibleDDIs: TCountriesCodes = getPossibleCountriesFromPhone(number);

        if (possibleDDIs.length === 1) {

            const { iso } = possibleDDIs[0];
            const phoneNumber = parsePhoneNumber(number, iso);

            if (!phoneNumber.isValid()) {
                this.snackMsgSvc.openError('Este não é um número válido. Tente adicionar o contato novamente.', 5000);
                this.closeDialog();
                return;
            }
            const intData = this.allCountriesPhoneCodes.find(data => data.iso === iso);
            this.ddiFormControl.setValue(intData);
            this.ddiFormControl.disable();
            this.numberFormControl.setValue(phoneNumber.nationalNumber);
            this.numberFormControl.disable();
            this.numberMask = intData?.phoneMask || this.getInternationalDefaultMask();
            return;


        } else if (possibleDDIs.length > 1) {
            console.log('possibleDDIs', { possibleDDIs })
            /**
             * Provavelmente nunca teremos esse caso.
             * Por esse motivo não vale a pena implementar no moemnto.
             * Se um dia tivermos a gente implementa
             */

            //TODO - perguntar ao usuário de qual país é o número
            // fazer o parse de acordo com input do usuario

            // await promise do usuario inputar de qual país é o numero

            // const phoneNumber = persePhoneNumber(number, this.selectedCountry.iso)
            //  if (phoneNumber.isValid()) {
            //     this.ddiFormControl.setValue();
            //     this.numberFormControl.setValue(phoneNumber.nationalNumber);
            // }
        } else {
            this.snackMsgSvc.openError('Este não é um número válido. Tente adicionar o contato novamente.', 5000);
            this.closeDialog();
            return;
        }
    }

    private async broadcastAvatarNameChange(idAvatar: string, newName: string) {
        if (!this.avatarSvc.isAvatarBeingObserved(idAvatar)) {
            return
        }

        const avatar = await this.profileAvatarStoreSvc.getAvatarFromServerIfNotOurs(idAvatar);

        if (!avatar) {
            return;
        }

        avatar.setName(newName);

        this.avatarSvc.broadcastAvatarChanged(avatar);
    }
}
