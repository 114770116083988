import { Injectable } from '@angular/core';
import { IColmeiaCrossAppProtocol } from 'app/modules/cross-app-comm/cross-app-comunicator.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { AppService } from 'app/services/app.service';
import { EColmeiaAppTypes } from 'app/model/app.model';
import { ECrossAppCommEventType } from '@colmeia/core/src/shared-business-rules/app-widget/widget.model';

@Injectable({
    providedIn: 'root'
})
export class CrossAppCommunicatorService {
    constructor(
        private snack: MatSnackBar,
        private appSvc: AppService
    ) { }

    get nativeWindow(): Window {
        return window;
    }

    getParentOrigin(): string {
        let referrerURL: URL;
        try {
            referrerURL = new URL(document.referrer);
        } catch (error) {
            console.error({ referrer: document.referrer });
            this.snack.open("Este widget precisa ser carregado dentro de um website!", "Fechar", { duration: 5000 });
        }
        return `${referrerURL.protocol}//${referrerURL.hostname}${referrerURL.port ? ':' + referrerURL.port : ''}`;
    }

    sendMessageToWidgetParent(msg: IColmeiaCrossAppProtocol) {
        if (msg.appType !== EColmeiaAppTypes.widget) {
            return { sentStatus: false };
        }

        console.log({ 'WidgetComponent CrossAppCommunicatorService.sendMessage': msg, whoLoadedME: this.getParentOrigin() });

        parent.postMessage(msg, this.getParentOrigin());
        return { sentStatus: true };
    }

    /**
     * Funcao utilizada para gerar eventos para o iframe pai que esta carregando a colmeia embedded
     * TODO: esta funcao esta sendo chamada apenas no EColmeiaAppTypes.widget por enquanto
     * @param eventType
     * @param clickedFieldId
     * @param clickedValue
     * @param payload
     * @returns
     */
    generateCrossAppMessage(
        type: ECrossAppCommEventType,
        eventType: string,
        clickedFieldId: IdDep<ENonSerializableObjectType.canonical>,
        clickedValue: string,
        payload: Record<IdDep<ENonSerializableObjectType.canonical>, string>,
    ): IColmeiaCrossAppProtocol {
        return {
            type,
            appType: this.appSvc.getAppType(),
            eventType,
            clickedFieldId,
            clickedValue,
            clockTick: new Date().getTime(),
            payload
        };
    }
}
