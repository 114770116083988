import { Injectable } from '@angular/core';
import { IChainedInteractionJSON, IInteractionJSON, IWrapperInteractionJSON } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { constant } from '@colmeia/core/src/business/constant';
import { EInteractionToNotificationCases, NotificationInteractionToText } from "./to-text";
import { isFirstLevelInteractionJSON } from '@colmeia/core/src/rules/interaction-filter';
import { isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { getInteractionActions } from '@colmeia/core/src/rules/notifcation-rules/notifications-normalization';
import { INotificationActions, EAppState } from '@colmeia/core/src/rules/notifcation-rules/notification-config';
import { ETimedInteractionTypeID } from '@colmeia/core/src/business/constant.enums';
import {SessionService} from "./session.service";
import {PlayerInfoService} from "./player-info.service";
import {SubscriptionInteractionInfoBuilder} from "./subscription-info.service";
import {HardwareLayerService} from "./hardware";
import {RoutingService} from "./routing.service";
import {ColmeiaDialogService} from "./dialog/dialog.service";
import {SoundsNotificationService} from "./sounds-notification.service";
import {NotificationsBag} from "../model/notifications/bag";
import { AttendanceService } from './attendance.service';
import { EAttendantStatus } from '@colmeia/core/src/comm-interfaces/service-group-interfaces';
import { EmbeddedChatService } from './embedded-chat.service';
import ColmeiaApp from 'preloader/app';
import { IServiceIslandServer } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    private isPlayingAudioInterval: boolean = false;

    constructor(
        private soundsSVC: SoundsNotificationService,
        private sessionSVC: SessionService,
        private bag: NotificationsBag,
        private playerInfoSVC: PlayerInfoService,
        private subscriptionsSVC: SubscriptionInteractionInfoBuilder,
        private toText: NotificationInteractionToText,
        private hardware: HardwareLayerService,
        private routingSVC: RoutingService,
        private dialogSVC: ColmeiaDialogService,
        private embeddedSvc: EmbeddedChatService,
        private attendanceSvc: AttendanceService
    ) {}

    public async notificate(interaction: IInteractionJSON) {
        if (
            NotificationsService.mustIgnoreThisInteractionType(interaction.idInteractionType)
            || (this.sessionSVC.interactionMadeByMe(interaction) && !this.mustNotifyIfMadeByMe(interaction))
            || this.appIsAtChatScreenAndInteractionGroup(interaction)
        ) {
            return;
        }
        await this.callAttentionIfNeeded(interaction);
        await this.addToNotificationListIfNeeded(interaction);
    }

    private async addToNotificationListIfNeeded(interaction: IInteractionJSON): Promise<boolean> {
        const iType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType),
              isVisibleFirstLevel: boolean = isFirstLevelInteractionJSON(interaction),
              isSub: boolean = iType.isSubscription(),
              wasntMe: boolean = ! this.playerInfoSVC.avatarBelongsToPlayer(interaction.participant.avatar.primaryID);

        const subscription = isSub && wasntMe,
              message = isVisibleFirstLevel && ! this.playerInfoSVC.avatarBelongsToPlayer(interaction.generatedByIDAvatar),
              stdResponse = (<IChainedInteractionJSON> interaction).idInteractionTypeParent === constant.interactionType.subscription.subscription
                  && interaction.idInteractionType === constant.interactionType.subscription.stdResponse
                  && wasntMe;

        if (subscription || message || stdResponse) {
            await this.subscriptionsSVC.fetchSerializableHeadersIfNeededFromJSON(interaction);
            this.bag.add(await this.toText.transformFromJSON(interaction, EInteractionToNotificationCases.NotificationScreen));
            return true;
        }
        return false;
    }

    public get isGreenStatus(): boolean {
        return this.attendanceSvc.currentStatus === EAttendantStatus.online;
    }

    private async callAttentionIfNeeded(interaction: IInteractionJSON): Promise<void> {
        let appState: EAppState;
        let inChatScreen = this.routingSVC.isInsideChat(interaction.idGroup);
        let hasFocus = this.hardware.isFocused();

        if (inChatScreen) {
            appState = EAppState.chat;
        } else if (hasFocus) {
            appState = EAppState.foregroud;
        } else {
            appState = EAppState.background;
        }

        const islandConfig: IServiceIslandServer = this.attendanceSvc.getIslandForGroup(interaction.idGroup);
        const emitSound = !islandConfig?.disableSoundNewMsg;

        const colmeiaApp: ColmeiaApp = window._COLMEIA_;

        let actions: INotificationActions = getInteractionActions(interaction, appState, colmeiaApp.isChatEmbeddedMode());

        // if (actions.vibrate) { CHECK THIS LATER! @TODO
        //     this.hardware.getVibration().vibrate(500);
        // }
        
        
        // if (this.embeddedSvc.isAnEmbedInstance()) return;

        if (isValidString(actions.sound) && emitSound) {
            this.soundsSVC.play(actions.sound);
        }

        if (isValidRef(actions.dialog) && this.hardware.isBrowser()) {
            let dialog = this.dialogSVC.open({
                title: actions.dialog.title,
                componentRef: null,
                dataToComponent: {},
                contentText: actions.dialog.content,
            });

            if (actions.dialog.sound && !this.isPlayingAudioInterval) {
                let interval = setInterval(
                    () => this.soundsSVC.customInteractionPlay(actions.dialog.sound),
                    2000
                );
                this.isPlayingAudioInterval = true;
                let subscription = dialog.afterClosed().subscribe(() => {
                    clearInterval(interval);
                    subscription.unsubscribe();
                    this.isPlayingAudioInterval = false;
                });
            }
        }
    }

    private static mustIgnoreThisInteractionType(type: string): boolean {
        // return isThisOneOfThat(type, constant.interactionType.serviceGroup.startServiceChat);
        return false;
    }

    private isInteractionMadeByMe(interaction: IInteractionJSON): boolean {
        return this.sessionSVC.interactionMadeByMe(interaction);
    }

    private appIsAtChatScreenAndInteractionGroup(interaction: IInteractionJSON): boolean {
        return false;
    }

    private mustNotifyIfMadeByMe(interaction: IInteractionJSON): boolean {
        if (interaction.idInteractionType == constant.interactionType.special.wrapperInteraction) {
            let wrapperInteraction = interaction as IWrapperInteractionJSON;
            if (wrapperInteraction.wrapperType == ETimedInteractionTypeID.rememberMe) {
                return true;
            }
        }

        return false;
    }
}
