<form>
  <!-- <h4 *ngIf="title">{{ title }}</h4>
  <mat-form-field class="title-input">
    <mat-label>{{ translations.templateName }}</mat-label>
    <input matInput [(ngModel)]="schema.name" type="text" name="name" required>
    <mat-error>{{ translations.fieldRequired }}</mat-error>
  </mat-form-field> -->


  <div class="cm__item" *ngIf="isAllowingCatalogType">
    <mat-slide-toggle [(checked)]="isCatalogType" (change)="onChangeIsCatalogType($event)" color="primary" [disabled]="isSaved">
      Usar como catálogo
    </mat-slide-toggle>
  </div>

  <div class="cm__item" *ngIf="isCatalogType">
    <app-enum-picker *ngIf="catalogTypePicker" [handler]="catalogTypePicker"></app-enum-picker>
  </div>
  

  <div class="cm__item">
    <mat-slide-toggle [checked]="allowCorporateSearch" (change)="handleCorporateSearchChange($event)" color="primary">
      Permitir buscas corporativas
    </mat-slide-toggle>
  </div>
  <div *ngIf="isSmartFlowDashboard" class="cm__item">
    <mat-slide-toggle [checked]="allowAttendanceView === undefined || allowAttendanceView" (change)="handleAttendanceViewChange($event)" color="primary">
     Visualizar no atendimento 
    </mat-slide-toggle>
  </div>

  <h4 style="margin-bottom: 1rem;">{{ translations.fields }}:</h4>

  <div *ngFor="let item of schema.form;let i = index;">
    <app-general-form-field [field]="item" [isCorporateSearch]="allowCorporateSearch" (delete)="deleteField(i)"></app-general-form-field>
  </div>

  <button mat-flat-button color="primary" (click)="addField()">{{ translations.addField }}</button>

</form>
