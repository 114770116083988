import { Injectable } from "@angular/core";
import {clientConstants} from "../model/constants/client.constants";
import { constant } from "@colmeia/core/src/business/constant";
import { MMconstant } from "@colmeia/core/src/multi-media/barrel-multimedia";
import {constRosetta} from "@colmeia/core/src/rosetta/const-rosetta";
import {maxSizeOfReplyMessage} from '../model/constants/layout.constants';

@Injectable({
    providedIn: 'root'
})
export class ConstantService {

    public geMMTagPhoto(): string {return MMconstant.tag.photo; };
    public geMMTagHeader(): string {return MMconstant.tag.header; };
    public geMMTagIcon(): string {return MMconstant.tag.icone; };
    public geMMTagThumnail(): string {return MMconstant.tag.thumbnail; };
    public geMMTagBanner(): string {return MMconstant.tag.banner; };
    public geMMTagBackground(): string {return MMconstant.tag.background; };

    public getMMTypeImage(): string { return MMconstant.type.image; }
    public getMMTypeVideo(): string { return MMconstant.type.video; }
    public getMMTypeAudio(): string { return MMconstant.type.audio; }

    public getFileUrl(): string { return clientConstants.FILE_URL+'/'; }

    public showLoading: boolean;
	public errorMessage: string;

	// File URL
	public url: string;

	// Multimedia Constants
	public photoTag: string;

	// InteractionType
	public interactionType  = constant.interactionType;
	public groupType  = constant.groupType;

	// GEneral constants
	public constant = constant;
	public MMconstant = MMconstant;
	public rosettaConst = constRosetta;

	// Client constants
	public maxSizeOfReplyMessage = maxSizeOfReplyMessage;
	public visualElementParam = constant.visualElementParameter;

	constructor() {
		this.showLoading = false;
		this.errorMessage = '';
		this.url = clientConstants.FILE_URL;
		this.photoTag = MMconstant.tag.photo;
	}
}
