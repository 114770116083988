import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy, Injector } from '@angular/core';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import {
    ICacheFeedbackInteraction,
    feedbackControlFields,
    countFeedbacks
} from '@colmeia/core/src/comm-interfaces/feedback-interfaces';
import { TArrayID, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { feedbackFiguresToIDFeedbackArray } from '@colmeia/core/src/rules/interaction-filter';
import { isValidString, isValidRef } from '@colmeia/core/src/tools/utility';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { FeedbackDialogHandler } from '../feedback-dialog/feedback-dialog.handler';
import {IReactBoardDisplayParamenter, ReactDisplayBarHandler} from "../../../../../handlers/react-display-handler";
import {HandlerFactoryService} from "../../../../../handlers/handler-factory.service";
import {CmModalHandler} from "../../../../../handlers/cm-modal-handler";
import {ECmModalState} from "../../../../../model/cm-modal.model";
import { MessageInstanceHandler } from 'app/handlers/message-instance-handler/message-instance-handler';
import { HighLevelMessage } from '../high-level-message';
import { SignalListenerService } from 'app/services/signal/signal-listener';


@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-react-bar-display',
    templateUrl: './react-bar-display.component.html',
    styleUrls: ['./react-bar-display.component.scss']
})
export class ReactBarDisplayComponent extends HighLevelMessage implements OnInit {
    private feedbackFigures: ICacheFeedbackInteraction = {};
    private reactDisplayBarHandler: ReactDisplayBarHandler;
    public _modalHandler: CmModalHandler;
    public currentViewInjectorHandler: Injector;
    feedbackControl: TArrayID;

    constructor(
        private cdr: ChangeDetectorRef,
        private handlerFactory: HandlerFactoryService,
        private messageHandler: MessageInstanceHandler,
        private listener: SignalListenerService,
        private _injector: Injector,
    ) {
        super({currentCDR: cdr, listener: listener, messageHandler: messageHandler})
        this.reactDisplayBarHandler = messageHandler.getDisplayBarHandler();
        this.buildModalHandler(false);
    }

    ngOnInit() {
        this.feedbackFigures = this.getInteraction().getInteractionFeedbackFiguresForGroup(this.getParameter().idGroup);
        this.changeAllSiblingsDetection();
        this.currentViewInjectorHandler = this.getCurrentViewInjectorHandler();
    };

    private getCurrentViewInjectorHandler(): Injector {
        return Injector.create([{
            provide: MessageInstanceHandler,
            useValue: this.getMessageHandler()
        }], this._injector);
    }

    public getEmoji(idFeedback: TGlobalUID): string {
        const feedback: Feedback = Feedback.staticFactory(idFeedback);
        return isValidString(feedback.getEmoji()) ? feedback.getEmoji() : feedback.getIcon();
    };

    public getFeebackCountSum(): number | string {
        const value = countFeedbacks(this.feedbackFigures);
        return (value > 99) ? '99+' : value;
        // return countFeedbacks(this.feedbackFigures) || null;
    }

    public getParameter(): IReactBoardDisplayParamenter {
        if(isValidRef(this.reactDisplayBarHandler)){
            return this.reactDisplayBarHandler.getComponentParameter();
        }
    }

    public updateFeedbackControl() {
        if(isValidRef(this.getParameter())){
            this.feedbackFigures = this.getInteraction().getInteractionFeedbackFiguresForGroup(this.getParameter().idGroup);
            const array: TArrayID = feedbackFiguresToIDFeedbackArray(this.feedbackFigures);
            this.feedbackControl = array;
        }
    };

    public changeAllSiblingsDetection(): void {
        this.updateFeedbackControl();
        super.changeAllSiblingsDetection();
    };
  
    public canShowEmojiList(): boolean {
        return !!this.feedbackControl.length;
    }

    isSmall(): boolean {
        return this.reactDisplayBarHandler.isSmall();
    }

    public isIcon(): boolean {
        return true;
    }

    private buildModalHandler(open: boolean) {
        const handler: CmModalHandler = this.handlerFactory.newCmModal({
                state: (open) ? ECmModalState.Open : ECmModalState.Closed,
                componentProjectionInstance: FeedbackDialogComponent,
                data: new FeedbackDialogHandler({
                    reactBarHandler: this.reactDisplayBarHandler
                }),
                settings: {
                    panelClass: 'min-width-400'
                }
            }
        );
        this._modalHandler = handler;
    }

    openFeedbacksDialog() {
        this.buildModalHandler(true);
    }

}
