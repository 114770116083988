import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Group } from '@colmeia/core/src/business/group';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { IGroupJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { navigationRules } from '@colmeia/core/src/core-constants/security-constant';
import { INavigationRules, TArrayID, TGlobalUID, TGroupArray } from '@colmeia/core/src/core-constants/types';
import { TInteractionArray } from '@colmeia/core/src/interaction/interaction';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {
    IRunGroupNavigationRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import { INavigationResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { INSFaceCompanyServer } from '@colmeia/core/src/shared-business-rules/face-company/ns-facecompany';
import { ENavigatorFilter } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { fullCloneObject, isEmptyObject, isInvalid, isValidArray, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { YouTasksClientService } from 'app/components/dashboard/security/solicitation-tasks.service';
import { AttendanceService } from 'app/services/attendance.service';
import { AuthGuard } from 'app/services/auth/auth-guard.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { HelpTipsService } from 'app/services/help-tips.service';
import { RoutingBuilder } from 'app/services/routing-builder';
import { ScreenSpinnerService, SpinType } from 'app/services/screen-spinner.service';
import { SnackMessageService } from 'app/services/snack-bar/snackbar-message.service';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { delay, filter, first, skip, take } from 'rxjs/operators';
import { IClientGroupData } from "../../../model/child-groups.model";
import { ClientInfraResponse, IInfraParameters } from "../../../model/client-infra-comm";
import { GroupSubscription } from "../../../model/group-subscription.model";
import { routeID, routeList } from "../../../model/routes/route-constants";
import { LoginSignal } from "../../../model/signal/cookie-login-sign";
import { IListenerSubscription, ILoginEventListener } from "../../../model/signal/ps-interfaces";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { GroupPersistorServices } from "../../group-persistor.services";
import { GroupShortcutHandlerService } from "../../group-shortcut-handler.service";
import { RecoverySystemService } from "../../recovery-system.service";
import { RequestBuilderServices } from "../../request-builder.services";
import { RoutingService } from "../../routing.service";
import { ServerCommunicationService } from "../../server-communication.service";
import { SessionService } from "../../session.service";
import { SignalListenerService } from "../../signal/signal-listener";
import { SocialNetworkDatabaseService } from "../../social-network-database.service";
import { SusbcriptionContainerService } from "../../subscriptions.service";
import { UniversalRehydrator } from "../../universal-rehydrator";
import { ExecutableItem } from '../security-controller/executable-item';
import { ToolBarServices } from '../tool-bar-controller/tool-bar.service';
import { GroupNavigationEnd, GroupNavigationEvent, GroupNavigationStart, SocialNetworkNavigationEnd, SocialNetworkNavigationEvent, SocialNetworkNavigationStart } from './navigator';
import { constant } from '@colmeia/core/src/business/constant';

interface INavigationToGroupDescriptor {
    navigatedSuccess: boolean,
    groupModel: GroupSubscription,
    isAnAgentOnSocialNetwork: boolean
}
interface ICachedNavigatorGroup {
    idGroup: TGlobalUID;
    cursor: string;
    searchCriteria: INavigationRules;
    group: IGroupJSON;
    faceCompany?: INSFaceCompanyServer;
};

type TCachedNG = { [idGroup: string]: ICachedNavigatorGroup }

// declare var window: any;

@Injectable()
export class NavigatorServices implements IListenerSubscription, ILoginEventListener {
    private groupProcessed: TCachedNG;
    private currentSelection: INavigationRules;

    private rehy: UniversalRehydrator;
    private rbs: RequestBuilderServices;
    private server: ServerCommunicationService;
    private subscription: SusbcriptionContainerService;
    private groupServices: GroupPersistorServices;
    private session: SessionService;
    private currentPlayer: PlayerCachedInfo;

    private interactionBatchAfterFirstNav: IInteractionJSON;

    private _groupNavigation$: Subject<GroupNavigationEvent> = new Subject();
    public get groupNavigation$(): Observable<GroupNavigationEvent> { return this._groupNavigation$.asObservable() }

    private _socialNetworkNavigation$: Subject<SocialNetworkNavigationEvent> = new ReplaySubject(1);
    public get socialNetworkNavigation$(): Observable<SocialNetworkNavigationEvent> { return this._socialNetworkNavigation$.asObservable().pipe(skip(1)) }
    public get lastSocialNetworkNavigation$(): Observable<SocialNetworkNavigationEvent> { return this._socialNetworkNavigation$.asObservable() }

    private _executedNavigation$: Subject<INavigationToGroupDescriptor> = new Subject()
    public get executedNavigation$(): Observable<INavigationToGroupDescriptor> {
        return this._executedNavigation$.asObservable();
    }

    constructor(
        private solicitationsService: YouTasksClientService,
        private routingService: RoutingService,
        private listener: SignalListenerService,
        private contractServices: ToolBarServices,
        private snDatabase: SocialNetworkDatabaseService,
        private location: Location,
        private recovery: RecoverySystemService,
        private attendanceSvc: AttendanceService,
        private shortcut: GroupShortcutHandlerService,
        private router: Router,
        private authGuard: AuthGuard,
        private spinnerSvc: ScreenSpinnerService,
        private sessionSvc: SessionService,
        private snackMessageService: SnackMessageService,
        private dialogSvc: MatDialog,
        private snackSvc: MatSnackBar,
        private dashboardSvc: DashBoardService,
        private helpTipsService: HelpTipsService,
    ) {
        this.groupProcessed = {};
        this.resetRules();
        this.listener.listenToUserLoginEvent(this);
        this.listenToRouteChange();
    };

    public getSessionSvc(): SessionService {
        return this.sessionSvc;
    }

    private listenToRouteChange(): void {
        this.location.subscribe(c => {
            const url = c.url.substring(1);
            if (url.startsWith(routeList.groups.path)) {
                const idGroup = url.split('/')[1];
                this.changeSN(idGroup);
            }
        });
    }

    public changeSN(idSN: string): void {
        const sub = this.subscription.getGroupSubscriptionModel(idSN);
        if (sub) {
            this.session.setSubscription(sub);
            this.subscription.changeSelectedSubscription(sub);
        }
    }

    public getFaceCompanyInformation(idGroup: TGlobalUID): INSFaceCompanyServer {
        return this.groupProcessed[idGroup].faceCompany;
    }

    public setArchived(archivedGroups: TArrayID, oldCached: PlayerCachedInfo): void {
        for (const idGroup of archivedGroups) {
            const processed = this.groupProcessed[idGroup];
            if (processed && processed.group) {
                const parent = processed.group.genealogy.find(gen => gen.level === 1);
                if (parent) {
                    delete this.groupProcessed[parent.idGroupParent];
                }
            }
            delete this.groupProcessed[idGroup];
            for (const onGenealogy of (oldCached.getAllGroupOnGenealogy(idGroup))) {
                delete this.groupProcessed[onGenealogy];
            }
        };
        this.refreshCached();
    };

    public refreshCached(): void {
        for (const idGroup of this.getAllGroupsToRemoveFromMemory()) {
            this.removeGroupFromMemory(idGroup)
        };
    };

    getAllGroupsToRemoveFromMemory(): TArrayID {
        const groupsToRemove = []
        const newCached: PlayerCachedInfo = this.session.getPlayerInfo();

        for (const idGroup in this.groupProcessed) {
            if (!newCached.belongsToGroup(idGroup)) {
                groupsToRemove.push(idGroup)
            };
        };

        return groupsToRemove
    }

    removeGroupFromMemory(idGroup: string): void {
        delete this.groupProcessed[idGroup];
        this.attendanceSvc.removeAttendanceOfGroup(idGroup);
    }

    public setPlayerCached(playerCached: PlayerCachedInfo): void {
        this.currentPlayer = playerCached;
    };

    public dontRefreshOnNextSecuritySignal(): void {
        this.currentPlayer = null;
    }

    public isRefreshOnSecuritySignalDisabled(): boolean {
        return isInvalid(this.currentPlayer);
    }

    public setDependencyUniversalRehydrator(rehy: UniversalRehydrator): void { this.rehy = rehy; };
    public setDependencyRequestBuilderService(rbs: RequestBuilderServices): void { this.rbs = rbs; };
    public setDependencyCommunucationService(server: ServerCommunicationService): void { this.server = server; };
    public setDependencySubscriptionService(subscription: SusbcriptionContainerService): void { this.subscription = subscription; };
    public setDependencyGroupServices(groupServices: GroupPersistorServices): void { this.groupServices = groupServices; };

    public setDependencySessionService(session: SessionService): void {
        this.session = session;
        this.currentPlayer = this.session.getPlayerInfoServices().getPlayerInfo();
    };

    public async runNavigationSelection(navigation: INavigationRules): Promise<void> {
        navigation.fromGroupID = this.subscription.getSelectedGroupID();
        await this.executeNavigation(navigation, routeID.groups.home, false, [], true);
        this.currentSelection = navigation;
        return;
    };

    public async navigateToGroupID(idGroup: TGlobalUID, page: string = routeID.groups.home,
        refresh: boolean = false, spinning: boolean = true, avoidRedirect: boolean = false, estheticsTimeout = true
    ): Promise<void> {
        // TODO: mount route based on page id with the group id and compare to actual path;
        const isEmbedded = this.session.isEmbeddedChat();

        this._groupNavigation$.next(new GroupNavigationStart(idGroup));
        this.spinnerSvc.show({ spinType: SpinType.groupNav });

        const done = () => {
            this._groupNavigation$.next(new GroupNavigationEnd(idGroup));
            this.spinnerSvc.hide({ spinType: SpinType.groupNav });
        }

        const clearEstheticsTimeout = estheticsTimeout ? setTimeout(() => {
            done();
        }, !isEmbedded && secToMS(5)) : undefined;

        !isEmbedded && this.router.events.pipe(filter(e => e instanceof NavigationEnd), first()).subscribe(() => {
            clearTimeout(clearEstheticsTimeout);
            done();
        });

        const currentNavigation: INavigationRules = this.getCurrentNavigationRules(idGroup);
        await this.executeNavigation(currentNavigation, page, refresh, [], spinning, null, avoidRedirect);
        if (page === routeID.groups.chat) {
            this.shortcut.resetUnreadMessageCounting(idGroup);
            this.attendanceSvc.resetCustomerDoesNotAnswerCountByGroup(idGroup);
        }

        if (isEmbedded || !estheticsTimeout) {
            done();
        }
    }

    public async navigateToChat(idGroup: TGlobalUID, avoidRedirect: boolean = false, refresh: boolean = false, spinning: boolean = true): Promise<void> {
        await this.navigateToGroupID(idGroup, routeID.groups.chat, refresh, spinning, avoidRedirect)
    }

    public async firstNavigation(firstNavigaton: INavigationResponse, idGroupNotificationURL: TGlobalUID, avoidRedirect = false, groupPage: string = routeID.groups.home): Promise<void> {
        const isEmbeddedOrCrm = this.session.isCRMEmbeddedMode() || this.session.isEmbeddedChat()
        this.attendanceSvc.setLastStatusClocktick(firstNavigaton.login.lastClock);

        if (idGroupNotificationURL) {
            const currentNavigation: INavigationRules = this.getCurrentNavigationRules(idGroupNotificationURL, true);
            await this.executeSearch(currentNavigation, true, [], true, firstNavigaton);
            await this.navigateToGroupIDWithRefresh(idGroupNotificationURL, groupPage);
        } else {
            const bestRoute = (isEmbeddedOrCrm) ? routeID.groups.chat : routeID.groups.home
            const currentNavigation: INavigationRules = this.getCurrentNavigationRules(firstNavigaton.groupData.group.primaryID, true);

            await this.executeNavigation(
                currentNavigation,
                bestRoute,
                true,
                [],
                true,
                firstNavigaton,
                avoidRedirect
            );

            if (isValidRef(this.interactionBatchAfterFirstNav)) {
                const interaction = this.interactionBatchAfterFirstNav;
                await this.recovery.routeBatchInteractions(interaction.idGroup, [interaction]);
                this.interactionBatchAfterFirstNav = null;
            }
        }
    }

    public async chargeMoreInteractions(idGroup: TGlobalUID, allreadyRehydrated: TInteractionArray): Promise<void> {
        await this.executeNavigation(this.getCurrentNavigationRules(idGroup), routeID.groups.chat, false, allreadyRehydrated, true);
    };

    public getCurrentNavigationRules(idGroup: TGlobalUID, isFirstNavigation: boolean = false): INavigationRules {
        return {
            ...this.currentSelection,
            cursor: this.getGroupCursor(idGroup),
            fromGroupID: idGroup,
            isFirstNavigation,
        };
    };

    public async navigateToGroupIDWithRefresh(idGroup: TGlobalUID, page: string = routeID.groups.home): Promise<void> {
        const currentNavigation: INavigationRules = this.getCurrentNavigationRules(idGroup);
        currentNavigation.where = navigationRules.where.allGroupsJustBellow;
        await this.executeNavigation(currentNavigation, page, true, [], true);
    };

    // compara o que é relevante para uma nova busca ou não
    private sameContentCriteria(request: INavigationRules, saved: INavigationRules): boolean {
        return (saved
            && request.followUp == saved.followUp
            && request.idInteractionType == saved.idInteractionType
            && request.where == saved.where
            && request.timed.attention == saved.timed.attention)
    };

    private async executeSearch(
        navigation: INavigationRules,
        refresh: boolean = false,
        alreadyRehydrated: TInteractionArray = [],
        spinning: boolean = true,
        firstNavigaton: INavigationResponse,
        pageId?: string
    ): Promise<INavigationToGroupDescriptor> {
        let groupModel: GroupSubscription = this.subscription.getGroupSubscriptionModel(navigation.fromGroupID);
        const incremental: boolean = isValidArray(alreadyRehydrated);
        let cursor: string = null;
        let processNavigation: boolean = true;
        let navigatedSuccess: boolean = false;
        let navigationResponse: INavigationResponse;
        navigation.getGroupData = true;

        if (navigation.where === navigationRules.default) {
            navigation.where = navigationRules.where.allGroupsJustBellow;
        }

        if (navigation.where == navigationRules.where.yourPersonalGroups) {
            navigation.fromGroupID = this.session.getPersonalGroup().getGroupID();
            navigation.where = navigationRules.where.allGroupsJustBellow;
        };

        if (groupModel) {
            const cached: ICachedNavigatorGroup = this.getGroupNavigationCached(navigation.fromGroupID);
            if (cached) {
                if (incremental) {
                    cursor = cached.cursor;
                    if (!cursor) {
                        processNavigation = false;
                    };
                } else if (!refresh && this.sameContentCriteria(navigation, cached.searchCriteria)) {
                    processNavigation = false;
                } else {
                    navigation.getGroupData = false;
                };
            };
        };

        if (processNavigation || firstNavigaton) {
            const [updatedModel, updatedNavigationResponse] = await this.processNavigation({
                navigation,
                cursor,
                spinning,
                firstNavigaton, model: groupModel, alreadyRehydrated, pageId
            })
            groupModel = updatedModel
            navigationResponse = updatedNavigationResponse
        };

        if (groupModel) {
            navigatedSuccess = true;
            if (!incremental) {
                this.session.setSubscription(groupModel);
                this.subscription.changeSelectedSubscription(groupModel);
            }
        };
        let isAnAgentOnSocialNetwork: boolean;
        if (navigationResponse) {
            isAnAgentOnSocialNetwork = this.attendanceSvc.isPlayerAnAgentInSocialNetwork(navigationResponse.idSocialNetwork)

            if (isAnAgentOnSocialNetwork) {
                await this.attendanceSvc.initAttendantState();
            }
        }

        const descriptor = { navigatedSuccess, groupModel, isAnAgentOnSocialNetwork };
        this._executedNavigation$.next(descriptor);

        return descriptor;
    }

    // public async createSubscriptionFor(idGroup: TGlobalUID): Promise<GroupSubscription> {
    //     const navReq: IRunGroupNavigationRequest = {
    //         ...this.rbs.secureBasicRequest(apiRequestType.navigation.runNavigation),
    //         navigation: this.getCurrentNavigationRules(idGroup),
    //         cursor: undefined,
    //         currentCachedSN: []
    //     };

    //     const param: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();

    //     const clientResponse: ClientInfraResponse = await this.server.managedRequest(param, navReq);

    //     let navigationResponse = <INavigationResponse>clientResponse.response;
    //     this.snDatabase.updateGroups(navigationResponse.groupArray);
    //     const groupData: IClientGroupData = this.groupServices.processGetGroupData(navigationResponse.groupData, []);

    //     const model: GroupSubscription = await this.subscription.groupSubscriptionModelFactory(
    //         groupData.group,
    //         groupData.interactions,
    //         groupData.childGroups,
    //         await this.rehy.rehydrateArrayJSON<TGroupArray>(navigationResponse.groupArray)
    //     );

    //     if (navigationResponse.groupData.participant) {
    //         this.snDatabase.updateParticipant(navigationResponse.groupData.participant);
    //     }
    //     this.snDatabase.updateGroups(navigationResponse.groupData.childGroups.concat([navigationResponse.groupData.group]));
    //     this.snDatabase.updateParticipants(navigationResponse.participant || []);

    //     return model;


    // }

    private async processNavigation(
        info: {
            navigation: INavigationRules,
            cursor: string,
            spinning: boolean,
            firstNavigaton: INavigationResponse,
            model: GroupSubscription,
            alreadyRehydrated: TInteractionArray,
            pageId: string
        }
    ): Promise<[GroupSubscription, INavigationResponse]> {
        let navigationResponse: INavigationResponse;
        const isAttendingOnGroup = this.attendanceSvc.isAttendingOnGroup(info.navigation.fromGroupID)
        const navReq: IRunGroupNavigationRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.navigation.runNavigation),
            navigation: info.navigation,
            cursor: info.cursor,
            currentCachedSN: this.snDatabase.getProcessedSN(),
            pageId: info.pageId,
            isAttendingOnGroup
        };
        const param: IInfraParameters = info.spinning ?
            this.rbs.getContextNoCallBackSpinnningParameters()
            : this.rbs.getContextNoCallBackNoSpinnningParameters()

        const clientResponse: ClientInfraResponse = info.firstNavigaton ?
            { executionOK: true, response: info.firstNavigaton, friendlyMessage: null, responseButton: null } :
            await this.server.managedRequest(param, navReq);

        if (clientResponse.executionOK) {
            navigationResponse = <INavigationResponse>clientResponse.response;
            this.snDatabase.updateGroups(navigationResponse.groupArray);

            this.solicitationsService.setSolicitationsForGroups(navigationResponse.avatarOpenGroupParticipateSolicitations);
            if (isValidRef(navigationResponse.allHelpItems)) {
                this.helpTipsService.setHelpItems(navigationResponse.allHelpItems)
            }

            if (info.navigation.getGroupData) {
                const groupData: IClientGroupData = this.groupServices.processGetGroupData(navigationResponse.groupData, info.alreadyRehydrated);
                info.cursor = navigationResponse.groupData.cursor;
                info.model = await this.subscription.groupSubscriptionModelFactory(
                    groupData.group,
                    groupData.interactions,
                    groupData.childGroups,
                    await this.rehy.rehydrateArrayJSON<TGroupArray>(navigationResponse.groupArray));

                this.contractServices.addCustomFeatureToGroup(info.navigation.fromGroupID, groupData.interactionFeatures, navigationResponse.groupData.groupFeatures);

                if (navigationResponse.socialData) {
                    this.snDatabase.insertSN(
                        navigationResponse.idSocialNetwork,
                        navigationResponse.socialData
                    );
                };

                if (!isEmptyObject(navigationResponse.login)) {
                    // update no status do atendente
                }

                if (navigationResponse.groupData.participant) {
                    this.snDatabase.updateParticipant(navigationResponse.groupData.participant);
                }
                this.snDatabase.updateGroups(navigationResponse.groupData.childGroups.concat([navigationResponse.groupData.group]));
                this.snDatabase.updateParticipants(navigationResponse.participant || []);

            } else {
                info.model.setRequestedGroups(navigationResponse.groupArray.map(g => Group.staticFactory(g.primaryID)));
            };
            this.groupProcessed[info.navigation.fromGroupID] = {
                idGroup: info.navigation.fromGroupID,
                cursor: info.cursor,
                searchCriteria: fullCloneObject(info.navigation),
                group: (navigationResponse.groupData) ? navigationResponse.groupData.group : null,
            };
        };

        return [info.model, navigationResponse]
    }

    private async executeNavigation(navigation: INavigationRules, pageIdToGo: string, refresh: boolean = false,
        alreadyRehydrated: TInteractionArray = [], spinning: boolean, firstNavigaton: INavigationResponse = null, avoidRedirect = false
    ): Promise<void> {
        const userWantsToNavigateToHome = pageIdToGo === routeID.groups.home
        const navigatedResponse: INavigationToGroupDescriptor = await this.executeSearch(navigation, refresh,
            alreadyRehydrated, spinning, firstNavigaton, pageIdToGo);

        if (!navigatedResponse.navigatedSuccess) {
            this.snackMessageService.openWarning(`Error ao tentar navegar para o group de id: ${navigation.fromGroupID}`)
            return;
        }

        const groupIdUserWantsToGo = navigation.fromGroupID
        const bestRouteId = userWantsToNavigateToHome
            ? this.mustGoHomeOrFaceCompany(navigation.fromGroupID)
            : pageIdToGo

        const { mustProceedToDashboard, dashboardRoute } = await this.canProceedToDashboardIfHasAgentOnSocialNetwork(
            userWantsToNavigateToHome,
            groupIdUserWantsToGo,
            navigation.isFirstNavigation,
            navigatedResponse.groupModel)

        if (avoidRedirect) {
            return;
        }

        if (navigatedResponse.navigatedSuccess && mustProceedToDashboard) {
            await this.router.navigateByUrl(dashboardRoute);
            return;
        }

        if (isValidRef(firstNavigaton) && navigatedResponse.isAnAgentOnSocialNetwork) {
            await this.routingService.navigateToId(
                routeList.dashboard.path,
                routeList.dashboard.children.serviceStatus.path,
                routeList.dashboard.children.serviceStatus.children.current
            );
            return;
        }

        if (navigatedResponse.navigatedSuccess) {
            const isEmbedded: boolean = this.session.isEmbeddedChat();
            const hasGroup: boolean = firstNavigaton?.socialData.allPlayerGroupOnSN.some(group => group.groupType === constant.groupType.standard.standardGroup);

            if (hasGroup && !isEmbedded) {
                await this.routingService.navigateToId(
                    routeID.menu
                );
            } else {
                await this.routingService.navigateToId(
                    bestRouteId,
                    navigation.fromGroupID
                );
            }
        }
    }

    private async canProceedToDashboardIfHasAgentOnSocialNetwork(
        userWantsToNavigateToHome: boolean,
        groupIdCurrentUserWantsToGo: TGlobalUID,
        isFirstNavigation: boolean, groupModel: GroupSubscription
    ): Promise<{ mustProceedToDashboard: boolean, dashboardRoute: string }> {
        const socialNetworkIdFromGroupWeWantToGo: string = groupModel.getSocialNetworkFromGroup()
        const isAnAgentOnSocialNetwork: boolean = this.attendanceSvc.isPlayerAnAgentInSocialNetwork(socialNetworkIdFromGroupWeWantToGo)

        if (isFirstNavigation && isAnAgentOnSocialNetwork && userWantsToNavigateToHome && !this.session.isCRMEmbeddedMode()) {
            try {
                const dashboardRoutePath: string = routeList.dashboard.children.serviceStatus.path; // ai, communication ...
                const idAngularRoute: string = routeList.dashboard.children.serviceStatus.children.current;
                const dashboardRoute = RoutingBuilder.dashboardPage([
                    dashboardRoutePath,
                    idAngularRoute
                ]);

                const isAllowed = await this.dashboardSvc.isAllowedDashboardRoute(dashboardRoutePath, idAngularRoute);

                return { mustProceedToDashboard: isAllowed, dashboardRoute }
            } catch (err) {
                console.log("erro durante navegação de agente.")
                console.log({ err });
            }
        }

        return { mustProceedToDashboard: false, dashboardRoute: '' }
    }

    private mustGoHomeOrFaceCompany(idGroup: TGlobalUID): string {
        return isValidRef(this.groupProcessed[idGroup]) && isValidRef(this.groupProcessed[idGroup].faceCompany)
            ? routeID.groups.faceCompany
            : routeID.groups.home;
    }

    private selectFilterFunctions(model: GroupSubscription, navigation: INavigationRules): void {
        if (navigation.where == navigationRules.where.specialFeatures) {
            model.setRequestGroupsToSpecialGroups();
        } else if (navigation.where == navigationRules.where.yourPersonalGroups) {
            model.setRequestGroupsToPersonalGroups();
        } else {
            model.setRequestGroupsToDefault();
        };
    }

    private async loadSocialNetworkOfGroup(group: Group): Promise<void> {
        const idSocialNetwork: TGlobalUID = group.getMySocialNetworkID();
        const cached: ICachedNavigatorGroup = this.getGroupNavigationCached(group.getGroupID());
        if (!cached) {

        };
    };

    //// Cache Control
    public allowChatButton(): boolean {
        return this.session.belongsToGroup(this.subscription.getSelectedGroupID());
    };

    public getGroupNavigationCached(idGroup: TGlobalUID): ICachedNavigatorGroup {
        return this.groupProcessed[idGroup];
    };

    public getGroupCursor(idGroup: TGlobalUID): string {
        const data: ICachedNavigatorGroup = this.getGroupNavigationCached(idGroup);
        return data ? data.cursor : null;
    }

    ///// Criterias Control

    public setPopularity(item: ExecutableItem): void {
        this.currentSelection.popularity = item.getPrimaryID();
    };

    public setWhere(item: ExecutableItem): void {
        this.currentSelection.where = item.getPrimaryID();
    };

    public setFollowUp(item: ExecutableItem): void {
        this.currentSelection.followUp = item.getPrimaryID();
    };

    private enforceRuleForAddition(item: ExecutableItem): void {
    };

    public resetRules(): void {
        this.currentSelection = <INavigationRules>{};
        this.resetTimed();
        this.resetInteractionType();
        this.currentSelection.where = navigationRules.where.allGroupsJustBellow;
        this.currentSelection.timed.attention = navigationRules.attention.noAttentionRequired;
        this.currentSelection.popularity = navigationRules.popularity.all;
        this.currentSelection.followUp = navigationRules.followUp.both;
        this.currentSelection.idInteractionType = null;
    };

    // TIMED
    public resetTimed(): void {
        this.currentSelection.timed = {
            afterDate: null,
            whenOccurs: null,
            attention: null
        };
    }

    public setTimedChoice(item: ExecutableItem): void {
        this.currentSelection.timed.attention = item.getPrimaryID();
    };

    public setTimedWhenOcurrs(time: number): void {
        this.currentSelection.timed.whenOccurs = time;
    };
    public setTimedAfter(flag: boolean): void {
        this.currentSelection.timed.afterDate = flag;
    }
    public toogleTimedAfter(): void {
        this.currentSelection.timed.afterDate = !this.currentSelection.timed.afterDate;
    };

    // INTERACTION TYPE
    public setInteractionType(idInteractionType: TGlobalUID): void {
        this.currentSelection.idInteractionType = idInteractionType;
    };
    public setInteractionTime(time: number): void {
        this.currentSelection.interactionTime.clockTick = time;
    };
    public resetInteractionType(): void {
        this.currentSelection.idInteractionType = null;
        this.currentSelection.interactionTime = { afterDate: null, clockTick: null };
    };
    public toogleinteractionAfter(): void {
        this.currentSelection.interactionTime.afterDate = !this.currentSelection.interactionTime.afterDate;
    };
    public isInteractionAfter(): boolean { return this.currentSelection.interactionTime.afterDate; };
    public setInteractionAfter(flag: boolean): void { this.currentSelection.interactionTime.afterDate = flag; };

    public getAllSelected(): TArrayID {
        return [
            this.currentSelection.where,
            this.currentSelection.timed.attention,
            this.currentSelection.followUp,
            this.currentSelection.popularity,
        ];
    }

    public isDefaultSelected(item: ExecutableItem): boolean {
        return this.getAllSelected().some(id => item.is(id))
    }

    public isWhereFilter(filter: ExecutableItem): boolean {
        return filter.is(ENavigatorFilter.where);
    }

    public isAttentionFilter(filter: ExecutableItem): boolean {
        return filter.is(ENavigatorFilter.attention);
    }

    public isPopularityFilter(filter: ExecutableItem): boolean {
        return filter.is(ENavigatorFilter.popularity);
    }

    public isFollowUp(filter: ExecutableItem): boolean {
        return filter.is(ENavigatorFilter.followUp);
    }

    public isInteractionTypeFilter(filter: ExecutableItem): boolean {
        return filter.is(navigationRules.typeOfMessages);
    }

    public isOptionableSelection(filter: ExecutableItem, item: ExecutableItem): boolean {
        return (this.isAttentionFilter(filter) && !item.is(navigationRules.attention.noAttentionRequired)) ||
            (this.isInteractionTypeFilter(filter) && item != null && !item.is(navigationRules.attention.noAttentionRequired));
    }

    public async goToExtendendParticipant(toBeExecutedExP: ExecutableItem): Promise<void> {

    }

    public getCurrentNavRules(): INavigationRules { return this.currentSelection; };

    //// Compliance Callbacks
    public receiveLoginEventCallback(sign: LoginSignal): void {
        this.groupProcessed = {};
        this.listener.listenSubscriptionChanges(this);
        this.resetRules();
    };


    public async changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): Promise<void> {
        await this.loadSocialNetworkOfGroup(subscriptionSignal.getGroup());
    };

    public async goToCompanyFace(groupId: TGlobalUID) {
        this.routingService.navigateToId(routeID.groups.faceCompany, groupId);
    }

    public navigateToSocialNetwork(socialNetworkId: string, backUrl?: string): void {
        const currentIdSocialNetwork: string = this.sessionSvc.getCurrentSocialNetworkID();
        const sourceGroup: Group = UberCache.unsafeUberFactory(currentIdSocialNetwork, false) as Group;
        const targetGroup: Group = UberCache.unsafeUberFactory(socialNetworkId, false) as Group;

        if (!sourceGroup || !targetGroup) {
            return;
        }

        const startEvent = new SocialNetworkNavigationStart(sourceGroup, targetGroup);
        const endEvent = new SocialNetworkNavigationEnd(sourceGroup, targetGroup);
        this._socialNetworkNavigation$.next(startEvent);

        merge(
            this.groupNavigation$.pipe(filter(e => e instanceof GroupNavigationEnd)),
            this.router.events.pipe(filter(ev => ev instanceof NavigationEnd))
        ).pipe(
            take(1),
            delay(1)
        ).subscribe(() => {
            if (!isValidString(backUrl)) {
                this._socialNetworkNavigation$.next(endEvent);
                return;
            }

            const notNeedNavigation = backUrl === this.location.path();

            if (notNeedNavigation) {
                this._socialNetworkNavigation$.next(endEvent);
                return;
            }

            this.router.events.pipe(filter(e => e instanceof NavigationEnd), take(1)).subscribe(() => {
                this._socialNetworkNavigation$.next(endEvent);
            })

            this.router.navigateByUrl(backUrl, { replaceUrl: true });
        });

        this.navigateToGroupID(
            socialNetworkId,
            undefined,
            undefined,
            true,
            undefined,
            false
        );
    }

    public async refreshCurrentRoute(): Promise<void> {
        const url = this.router.url;
        await this.router.navigateByUrl('/', { skipLocationChange: true });
        await this.router.navigateByUrl(url);
    }

    async changeSocialNetwork(idSN: string): Promise<void> {
        this.spinnerSvc.show({ spinType: SpinType.socialNetworkChange });
        await this.executeSearch(this.getCurrentNavigationRules(idSN), false, [], true, undefined);
        await this.refreshCurrentRoute();
        this.spinnerSvc.hide({ spinType: SpinType.socialNetworkChange });
    }

    public async runExecuteSearch(idSN: string = this.sessionSvc.getCurrentSocialNetworkID()): Promise<void> {
        await this.executeSearch(this.getCurrentNavigationRules(idSN), false, [], true, undefined);
    }

    public async checkValidGroupNavigation(route: ActivatedRoute, redirectRouteId: string = routeID.groups.chat) {
        const groupIdFromRoute: string | null | undefined = route.snapshot.paramMap.get("idGroup") || route.parent?.snapshot.paramMap.get('idGroup');
        if (!isValidString(groupIdFromRoute)) return;

        const currentGroupID: string = this.sessionSvc.getSelectedGroupID();
        const allSNIDs: string[] = this.snDatabase.getPlayerSocialNetworksExceptPersonal().map(g => g.getGroupID());
        const isTargetAnSocialNetwork: boolean = allSNIDs.includes(groupIdFromRoute);
        const isTryingAccessSocialNetworkChat: boolean = redirectRouteId === routeID.groups.chat && isTargetAnSocialNetwork;

        const participantInGroup = this.sessionSvc.getParticipant(groupIdFromRoute, true);
        if (isInvalid(participantInGroup)) {
            this.snackMessageService.openError('O atendimento que você está tentando acessar foi finalizado. Você foi redirecionado(a).', 5000)
            const commGroupId = this.attendanceSvc.getIslandCommGroupId(this.sessionSvc.getCurrentSocialNetworkID())
            await this.navigateToGroupID(commGroupId, routeID.groups.chat);
            return;
        }

        if (isTryingAccessSocialNetworkChat) {
            await this.attendanceSvc.navigateToNextAvailableGroup();
            return;
        }

        if (groupIdFromRoute === currentGroupID) return;

        await this.navigateToGroupID(groupIdFromRoute, redirectRouteId);
        const redirectOk: boolean = UberCache.testCache(groupIdFromRoute);

        if (redirectOk) return;

        this.dialogSvc.closeAll();
        this.snackSvc.dismiss();

        if (!this.attendanceSvc.isPlayerAnAgentInCurrentSocialNetwork()) {
            await this.navigateToGroupID(
                currentGroupID,
                routeID.groups.chat
            );
        } else {
            await this.attendanceSvc.navigateToNextAvailableGroup();
        }

    }

    public async safeRouteNavigate(url: string) {
        const isChatNavigation: boolean = url.includes(routeList.groups.children.idGroup.children.chat);
        const allSocialNetworkIDs: string[] = this.snDatabase.getPlayerSocialNetworksExceptPersonal().map(g => g.getGroupID());
        const hasIdSocialNetworkOnUrl: boolean = allSocialNetworkIDs.some(snID => url.includes(snID));
        const isTryingAccessSocialNetworkChat: boolean = isChatNavigation && hasIdSocialNetworkOnUrl;

        if (isTryingAccessSocialNetworkChat) {
            await this.attendanceSvc.navigateToNextAvailableGroup();
            return;
        }

        this.router.navigate([url]);
    }

};
