import { MainSignal } from "./main-signal";
import { observableList } from "./signal-constants";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { TArrayID } from "@colmeia/core/src/core-constants/types";

export const ifCBChangeOnAvatar: IInterfaceInfoSignal = {
    changeGroupHeader: null
};

export interface IInterfaceInfoSignal {
    sideNavOpen?: boolean;
    toolBarOpen?: boolean;
    shareOpen?: boolean;
    shareCount?: number;
    chatOpen?: boolean;
    profileItemSelected?: true;
    changeLanguage?: true;
    isOffLine?: boolean;
    changeGroupHeader?: TGlobalUID;
    newUniversal?: TGlobalUID;
    exclusiveAction?: boolean;
    uniqueID?: string;
    archivedGroups?: TArrayID;
    updated?: boolean;
    updateGroupList?: true;
};

export class InterfaceInfoSignal extends MainSignal {

    constructor(interfaceSignal: IInterfaceInfoSignal
    ) {
        super(observableList.interfaceState.stateChange, true, interfaceSignal);
      }

    public getInterfaceSignal(): IInterfaceInfoSignal {
        return <IInterfaceInfoSignal>super.getObservableInformation();
    }

    public isSideNavOpen(): boolean {
        return this.getInterfaceSignal().sideNavOpen
    }

    public isToolbarOpen(): boolean {
        return this.getInterfaceSignal().toolBarOpen;
    }

    public isShareOpen(): any {
        return this.getInterfaceSignal().shareOpen;
    }

    public getShareCount(): number {
        return this.getInterfaceSignal().shareCount;
    }

    public isChatOpen(): boolean {
        return this.getInterfaceSignal().chatOpen;
    }

    public isProfileItemSelected(): true{
        return this.getInterfaceSignal().profileItemSelected
    }

    public isOnline(): boolean {
        return ! this.getInterfaceSignal().isOffLine;
    }

    public isOffline(): boolean {
        return this.getInterfaceSignal().isOffLine;
    }

    public languageChanged(): boolean {
        return isValidRef(this.getInterfaceSignal().changeLanguage);
    }
}
