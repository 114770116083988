<div class="main__container" [class.file-mode]="handler.isFileMode()">
  <div
    class="hexagon-uploader-container"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (click)="upload()"
    [class.no-border]="shouldHideBorder()"
    >
    <hexagon class="preview-mode" [hexagonHandler]="handler.getHexagonHandler()"></hexagon>
    <div class="content" *ngIf="canUpload">
      <mat-icon class="upload-icon">file_upload</mat-icon>
      <p *ngIf="handler.isFileMode()" class="upload-description">Selecione ou "solte" arquivos aqui para fazer upload.</p>
    </div>
  </div>
  <button *ngIf="canDelete" class="remove-button" (click)="delete()">
    <mat-icon>remove_circle</mat-icon>
  </button>
</div>
<app-multimedia-instance-uploader #uploader [multimediaHandler]="multimediaHandler"></app-multimedia-instance-uploader>
