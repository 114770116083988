import { IClientEnvironmentInterface } from "./client-interface.environment";

export const environment: IClientEnvironmentInterface = {
    production: true,
    allDevFeatures: true,
    // deploymentEnvironment: EDeploymentEnvironment.dev,
    ROOT_URL: 'https://dev-api.colmeia.me',
    googleMapsApiKey: 'AIzaSyCDL_7_Gaw3DKphgV8QsWldUsBJw6tuoRA',
    materialAnimationsEnabled: false,
};
