<mat-select [(value)]="selectedDriver" (selectionChange)="updateDriverIcons()" *ngIf="canChangeDriver">
  <mat-option *ngFor="let driver of drivers; let i = index;" [value]="i">
    {{ driver.interactionType.getName() }}
  </mat-option>
</mat-select>

<ul class="icon-list" *ngIf="isFeedback">
  <li *ngFor="let icon of icons" class="mr10">
    <a class="bigicon__item" (click)="onIconClick(icon.data)" tapFeedback feedbackDelaysClick="500">
      <div class="emoji" *ngIf="icon.emoji">
        <span class="emoji--bigicon">
          {{ icon.image }}
        </span>
      </div>
      <div *ngIf="! icon.emoji">
        <img [src]="icon.image">
      </div>
      <span class="bigicon__name"
            matTooltipClass="mat-tooltip"
            [matTooltip]="icon.name"
      >
        {{ icon.name }}
      </span>
    </a>
  </li>
</ul>

<app-tools-content-visualization [section]="section"></app-tools-content-visualization>