import { SafeResourceUrl } from '@angular/platform-browser';
import { ChangeDetectionStrategy, Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked, ChangeDetectorRef, AfterViewInit, SecurityContext } from '@angular/core';
import { isValidString, isValidRef } from '@colmeia/core/src/tools/utility';
import { MultimediaInstance } from "@colmeia/core/src/multi-media/multi-media-instance";
import { MultimediaService } from "../../services/multimedia.service";
import { HardwareLayerService } from "../../services/hardware";
import { EMultimediaNature, MultiMediaType } from "@colmeia/core/src/multi-media/multi-media-type";
import { ImgSecureDownloadPipe } from 'app/pipes/img-secure-download.pipe';
import { ImgSecureDownloadService } from 'app/services/auth/img-secure-download.service';
import { secToMS } from '@colmeia/core/src/time/time-utl';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-multimedia-instance',
    templateUrl: './multimedia-instance.component.html',
    styleUrls: ['./multimedia-instance.component.scss']
})
export class MultimediaInstanceComponent implements OnInit, AfterViewChecked, AfterViewInit {
    private lastMediaUrl: string;
    @Input() styles: Object;
    @Input() mmInstance: MultimediaInstance;
    @ViewChild('videoPlay', { static: true }) videoPlay: ElementRef;
    @ViewChild('videoSource', { static: true }) videoSource: ElementRef;
    @ViewChild('toggleBar', { static: true }) toggleBar: ElementRef;
    streamableSignedUrl: string

    @Input()
    onStorageRenewClick: (idMedia: string) => void;

    public safeResourceUrl: SafeResourceUrl;

    public isDocument: boolean = false;
    public fileName: string;
    public hasStorageExpiredUrl: boolean = false;

    constructor(
        private cdRef: ChangeDetectorRef,
        private multimediaSvc: MultimediaService,
        private hw: HardwareLayerService,
        private imageSecureSvc: ImgSecureDownloadService
    ) { }

    ngOnInit() {
        this.getStreamableURL();
        this.isDocument = this.mmInstance.hasNature(EMultimediaNature.Document);
        let fileName = this.mmInstance.getFileName();
        if (isValidString(fileName)) {
            this.fileName = fileName;
        } else {
            const selectedFile = this.mmInstance.getSelectedFile();
            if (isValidRef(selectedFile)) {
                this.fileName = selectedFile.getCurrentName();
            }
        }

        this.checkIfHasExpiredStorageToken();
    }

    ngAfterViewChecked(): void {
        if (this.lastMediaUrl !== this.getMultimediaUrl() && this.isVideo())
            this.onLoadVideo();
        this.lastMediaUrl = this.getMultimediaUrl();
    }

    ngAfterViewInit(): void {
        if (this.isVideo()) {
            this.onLoadVideo();
        }

        if (this.isPDF || this.isUnhandled()) {
            let pipe = new ImgSecureDownloadPipe(this.imageSecureSvc);
            pipe.transform(this.getMultimediaUrl(), SecurityContext.RESOURCE_URL).subscribe((safeUrl) => {
                this.safeResourceUrl = safeUrl;
                this.cdRef.markForCheck();
            });
        }
    }

    async getStreamableURL(): Promise<void> {
        if (this.isStreamable()) {
            this.streamableSignedUrl = await this.multimediaSvc.getMultimediaSignedTempURL(this.mmInstance.getIdMedia())
            this.cdRef.markForCheck()
        }
    }

    isStreamable(): boolean {
        const isStreamable = this.isVideo() || this.isAudio()
        return isStreamable
    }

    canShowStreamable(): boolean {
        return this.isStreamable() && isValidRef(this.streamableSignedUrl)
    }

    public onLoadVideo(): void {
        if (this.videoPlay && this.videoSource) {
            const video: HTMLVideoElement = this.videoPlay.nativeElement;
            video.pause();
            video.load();

            // const bar: HTMLElement = this.toggleBar.nativeElement;
            // let triggerFullscreen = () => video.requestFullscreen();
            // bar.addEventListener('click', triggerFullscreen);
            // bar.addEventListener('touchend', triggerFullscreen);
        }
    }

    public getMultimediaUrl(): string {
        return this.multimediaSvc.getMultimediaBestUrl(this.mmInstance);
    }

    public getStyles(): Object {
        return this.styles;
    }

    public getMimeType(): string {
        return this.mmInstance.getMimeType();
    }

    public isVideo() {
        return this.mmInstance.isVideo();
    }

    public isAudio() {
        return this.mmInstance.isAudio();
    }

    public isImage(): boolean {
        return this.mmInstance.isImage() && isValidString(this.getMultimediaUrl());
    }

    public isUnknown(): boolean {
        return this.mmInstance.isUnknown();
    }

    logIt(multimediaUrl) {
        console.log({ multimediaUrl });
    }

    isValidMultimedia(): boolean {
        return this.isImage() || this.isAudio() || this.isVideo()
    }

    get isVCard(): boolean {
        return isValidRef(this.mmInstance) && this.mmInstance.isVCard();
    }

    get isPDF(): boolean {
        return this.getMimeType() === "application/pdf";
    }

    isUnhandled(): boolean {
        return !this.isValidMultimedia() && !this.isVCard && !this.isPDF && !this.isUnknown();
    }

    private async checkIfHasExpiredStorageToken() {
        const mmUrl = this.getMultimediaUrl();

        const isFromStorageFile = this.isFromStorageFile(mmUrl);

        if (!isFromStorageFile) return;

        const url = new URL(mmUrl);

        const expireAtStr = url.searchParams.get('Expires');

        if (!expireAtStr) return;

        const expireAtInt = secToMS(Number(expireAtStr));

        this.hasStorageExpiredUrl = expireAtInt < Date.now();
    }

    private isFromStorageFile(url: string) {
        const storagePrefix = 'https://storage.googleapis.com/';

        return url.startsWith(storagePrefix);
    }

    public onStorageRenewClickWrapper() {
        this.onStorageRenewClick(this.mmInstance.getIdMedia());
    }
}
