import {TNserValidator} from "../validate-nser";
import {FriendlyMessage} from "../../../../error-control/friendly-message";
import {ENextGenBotElementType} from "../../../bot/bot-model";
import {
    botTransactionKnowledgeBaseError,
    botTransactionSelectMetadataError,
    botTransactionSelectRouteError, needToFillAccuracyEvent,
    nserInsertNameError,
    nserSmallNameError
} from "../error-messages";
import {minLength, nserNameMinLength} from "../helpers";
import {isInvalid, isValidRef, isValidArray, ifTruthy} from "../../../../tools/utility";
import {INLPTransactionServer, ITransactionServer} from "../../../knowledge-base/bot-transaction/bot-transaction";
import {EBotEventType, IBotEvent} from "../../../bot/bot-event-model";
import {validateBotAsset} from "./bot-asset";
import {isNLP} from "../../../bot/bot-function-model-helper";
import { IBackupPackage, IBackupSnapshot } from '@colmeia/core/src/shared-business-rules/ns-backup/backup-model';
import { allBackupScreenOptionsTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/backup.translations";

export function backupSnapshotValidator(ns: IBackupSnapshot, friendly: FriendlyMessage): FriendlyMessage {
    if (!ns.idParent) {
        friendly.addReturnTranslationResponse(
            allBackupScreenOptionsTranslations.missingPackage,
        );
    }

    if (!ns.idNSTarget) {
        friendly.addReturnTranslationResponse(
            allBackupScreenOptionsTranslations.missingTarget,
        );
    }

    return friendly;
}