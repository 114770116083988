import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import {IChatBarResetedListener, IChatBarResetProvider} from "../model/chat-action-bar";

@Injectable({
  providedIn: 'root'
})
export class ChatActionBarStateService implements IChatBarResetedListener, IChatBarResetProvider {
    private chatBarState: Subject<TGlobalUID> = new Subject<TGlobalUID>();

    constructor() {}

    chatBarReseted(currentInteractionID: TGlobalUID): void {
        this.chatBarState.next(currentInteractionID);
    }

    chatBarResetedListener(): Observable<TGlobalUID> {
        return this.chatBarState.asObservable();
    }
}
