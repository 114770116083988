import { IBPMActionModel } from '../../BPM/bpm-action-model';

// Colocar esses tipos no EBPMAction
export enum ETypeBPMActionModel {
    Assign = 'crmaAssign',
    CloseTicket = 'crmaClose',
    Notification = 'crmaNotify', // notificação simpples, abrir campanha sem BOT
    GerFollowUpFromCustomer = 'crmaGetCustomerInfo', // pega campanha com um bot associado
    executeFunction = 'crmaExecuteFunction'
}

export interface IBPMCRMActionModel extends IBPMActionModel {
    bpmAction: ETypeBPMActionModel
}

export interface ICRMAssignation extends IBPMCRMActionModel {
    bpmAction: ETypeBPMActionModel.Assign,
    idIsland: string;
}

export interface ICRMActionNotification extends IBPMCRMActionModel {
    bpmAction: ETypeBPMActionModel.Notification,
    idCampaignAction: string;

}

export interface ICRMFollowUpFromCustomer extends IBPMCRMActionModel {
    bpmAction: ETypeBPMActionModel.GerFollowUpFromCustomer;
    idCampaignAction: string;
};

export interface ICRMExecuteFunction extends IBPMCRMActionModel {
    bpmAction: ETypeBPMActionModel.executeFunction;
    idFunction: string;
}