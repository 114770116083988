import { MainHandler } from "../../../../handlers/main-handler";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    EGenericDashboardColumnType,
    GenericDashboardRowFilter,
    IGenericDashboardColumn,
    IGenericDashboardRequestParameters,
    IGenericHomeClientCallback,
    IGenericHomeHandlerParameter
} from "./generic-dashboard-home.model";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { Subject } from "rxjs";
import { Define, DefinePartial, DefinePartialIntersect } from "@colmeia/core/src/tools/utility/types/entities/define";

export class GenericHomeHandler<T extends INonSerializable = INonSerializable, D = any> extends MainHandler<IGenericHomeHandlerParameter<T>> {

    private home: IGenericHomeClientCallback;

    public listLoad: Subject<void> = new Subject();

    constructor(parameter: IGenericHomeHandlerParameter<T, D>) {
        super(parameter);
    }

    static column<T extends IGenericDashboardColumn>(target: T) {
        return target;
    }

    async refresh(): Promise<void> {
        await this.home.refreshPage();
    }

    public setGenericHomeCallback(client: IGenericHomeClientCallback): void {
        this.home = client;
    }

    public getHomeParameter(): IGenericHomeHandlerParameter<T> {
        return <IGenericHomeHandlerParameter<T>>this.getComponentParameter();
    }

    public askForRequest(parameter: IGenericDashboardRequestParameters): IRequest {
        return this.getHomeParameter().clientCallback.getRequest(parameter);
    }

    public clearPickedItems(): void {
        this.home.clearPickedItems();
    }

    public clearCursorsState(): void {
        this.home.clearCursorsState();
    }

    public clearEntities(): void {
        this.home.clearEntities();
    }

    public getRowFilter(): GenericDashboardRowFilter {
        return this.getHomeParameter().clientCallback.filterEntities;
    }

    public getNSList(): T[] {
        return this.home.getNSList() as T[];
    }
}
