import {
    EIOSLocationMode,
    ErrorGeolocationCallback,
    IGeolocationError,
    IGeolocationHardwareAbstraction,
    SuccessGeolocationCallback
} from '../hardware-interfaces';
import { EHardwareResourceStatus } from "./vendor/hardware-status";
import { CordovaPermissionsService } from "./permissions/cordova-permissions.service";
import { getClock, isValidRef } from "@colmeia/core/src/tools/utility";
import { CordovaGeolocationConstants } from "./cordova-hardware.constants";
import { ILocationCoordinates, locationToIlocationCoordinates } from "@colmeia/core/src/tools/geo-util";
import { Location } from './transistorsoft-location';
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { Injectable } from '@angular/core';
import { Window } from './cordova-plugins-definitions';
import {SignalListenerService} from "../../signal/signal-listener";
import {ColmeiaDialogService} from "../../dialog/dialog.service";

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class CordovaGeolocation implements IGeolocationHardwareAbstraction {

    private _successCallbacksMap: Map<number, SuccessGeolocationCallback> = new Map<number, SuccessGeolocationCallback>();
    private _errorCallbacksMap: Map<number, ErrorGeolocationCallback> = new Map<number, ErrorGeolocationCallback>();
    private _baterySaveMode: boolean = false;
    private _lastTimeReceivedPos: number = getClock();
    private _lastLocationReceived: ILocationCoordinates = null;
    private player: PlayerCachedInfo;
    private isStarting: boolean = false;

    constructor(
        private permissions: CordovaPermissionsService,
        private listener: SignalListenerService,
        private dialog: ColmeiaDialogService
    ) {

        document.addEventListener("deviceready", () => {
            window.BackgroundGeolocation.onHttp(response => {
                console.log('[http] - ', response.success, response.status, response.responseText);
            });

            window.BackgroundGeolocation.onHeartbeat((event) => {
                this.onHeartbeatReceived();
            });

            window.BackgroundGeolocation.onLocation(location => {
                this.onLocationReceived(location);
            }, error => {
                this.onLocationErrorReceived(error);
            });

            window.BackgroundGeolocation.onMotionChange(event => {
                this.onLocationReceived(event.location);
            });

            window.BackgroundGeolocation.onPowerSaveChange(enabled => {
                this._baterySaveMode = enabled;
            });

            window.BackgroundGeolocation.ready({
                ...CordovaGeolocationConstants.Defaults,
                ...CordovaGeolocationConstants.IOSOnly,
                ...CordovaGeolocationConstants.AndroidOnly,
            }).then((state) => {
                console.log("Geolocation State", state);
                if (state.enabled && !this.isTrackingOn()) {
                    this.stop();
                }
            });
            this.setEmergencyMode(false);
        });


        this.listener.listenToActivateGeoSignal(async() => {
            await this.determineTrackingState();
        });

        this.listener.listenToDeactivateGeoSignal(async () => {
            await this.determineTrackingState();
        });
    }

    private isTrackingOn(): boolean {
        if(! this.player) { //session isn't available.......
            return false;
        }
        const isLoged: boolean = isValidRef(this.player.getOriginalAvatarID());
        const isTrackingOn: boolean = this.player.isTrackingOn();
        return isLoged && isTrackingOn;
    }

    private async determineTrackingState(): Promise<void> {
        const isOn = this.isTrackingOn();
        const isEnabled = await this.isEnabled();
        console.log("on/enabled",isOn, isEnabled);

        if (isOn) {
            await this.start();
        }

        if (isOn && ! isEnabled) {
            console.log("NOT ENABLED, BUT ON")
            // this.dialog.open<GeolocationDisabledDialogComponent, null>({
            //     dataToComponent: {
            //         data: null
            //     },
            //     componentRef: GeolocationDisabledDialogComponent
            // })
        }
    }

    setCachedPlayer(player: PlayerCachedInfo) {
        this.player = player;
        console.warn("SETANDO NOVO PLAYER LOGADO PARA ENVIAR OS SINAIS DE GPS...")

        const idPlayer: TGlobalUID = player.getPlayerID(),
              participantID: TGlobalUID = player.getParticipantIDPersonalGroup(),
              idGroup: TGlobalUID = player.getPlayerPersonalGroupID();

        if (player) {
            this.appendConfigs({
                params: {
                    "playerID": idPlayer,
                    "personalID": idGroup,
                    "partID": participantID
                }
            });
        }

        this.determineTrackingState();
    }

    private onHeartbeatReceived(): void {
        console.warn('HEARTBEAT')
        window.BackgroundGeolocation.getCurrentPosition({
                persist: true,
                desiredAccuracy: window.BackgroundGeolocation.DESIRED_ACCURACY_HIGH
            }, this.onLocationReceived,
            this.onLocationErrorReceived
        );
    }

    setEmergencyMode(active: boolean): void {
        document.addEventListener('deviceready', () => {
            this.appendConfigs(
                (active) ? CordovaGeolocationConstants.EmergencyModeOn : CordovaGeolocationConstants.EmergencyModeOff
            );
        })
    }

    private appendConfigs(settings: object): void {
        window.BackgroundGeolocation.setConfig(settings);
    }

    private normalizeLocation(loc: Location): ILocationCoordinates {
        return {
            uuid: loc.uuid,
            speed: loc.coords.speed,
            powerSaveMode: this._baterySaveMode,
            longitude: loc.coords.longitude,
            latitude: loc.coords.latitude,
            isMoving: loc.is_moving,
            heading: loc.coords.heading,
            gpsTimestamp: getClock(),
            batery: loc.battery.level,
            altitudeAccuracy: null,
            altitude: loc.coords.altitude,
            activity: loc.activity.type,
            accuracy: loc.coords.accuracy
        }
    }

    private normalizeError(err: GeolocationPositionError): IGeolocationError {
        return {
            gpsTimestamp: getClock(),
            message: err.message
        }
    }

    private onLocationReceived = (location: Location) => {
        this._lastLocationReceived = locationToIlocationCoordinates(location);
        this._lastTimeReceivedPos = getClock();
        this._successCallbacksMap.forEach(cb => {
            cb(this.normalizeLocation(location));
        });
    };

    private onLocationErrorReceived = (err) => {
        this._errorCallbacksMap.forEach(cb => {
            cb(this.normalizeError(err));
        });
    };

    isEnabled(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .isLocationEnabled((status: boolean) => {
                    resolve(status);
                }, (error) => {
                    resolve(false);
                });
        });
    }

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .getLocationAuthorizationStatus((status: EHardwareResourceStatus) => {
                    resolve(status);
                }, (error) => {
                    // Atenção, isso é questionável, não queremos na prática gerar erro desnecessário
                    resolve(EHardwareResourceStatus.NOT_REQUESTED)
                })
        });
    }

    isAllowed(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .isLocationAuthorized((status: boolean) => {
                    resolve(status);
                }, (error) => {
                    resolve(false);
                })
        });
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .requestLocationAuthorization((status: EHardwareResourceStatus) => {
                    resolve(status);
                }, (error) => {
                    throw new Error(error)
                }, EIOSLocationMode.ALWAYS)
        });
    }

    getPosition(successCallback: SuccessGeolocationCallback, errorCallback: ErrorGeolocationCallback, options: object): void {
        window.BackgroundGeolocation.getCurrentPosition({
            persist: false,
            desiredAccuracy: window.BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
            samples: 5
        }, (pos: Location) => {
            successCallback(this.normalizeLocation(pos));
        }, (err: GeolocationPositionError) => {
            errorCallback(this.normalizeError(err));
        });
    }

    watchPosition(successCallback: SuccessGeolocationCallback, errorCallback: ErrorGeolocationCallback, options?: PositionOptions): number {
        const _id = this._successCallbacksMap.size + 1;

        this._successCallbacksMap.set(_id, successCallback);
        this._errorCallbacksMap.set(_id, errorCallback);

        return _id;
    }

    clearWatchPosition(id: number): void {
        this._errorCallbacksMap.delete(id);
        this._successCallbacksMap.delete(id);
    }

    getLastKnownPosition(): ILocationCoordinates {
        return this._lastLocationReceived;
    }

    async start(): Promise<any> {
        const allowed = await this.isAllowed();
        if (allowed && ! this.isStarting) {
            this.isStarting = true;
            console.log('cordova start gps allowed');
            return window.BackgroundGeolocation.start().then((res) => {
                this.isStarting = false;
            }, (err) => {
                this.isStarting = false;
            });
        }
        console.log('cordova start gps not allowed');
        return Promise.reject();
    }

    stop(): Promise<any> {
        console.log('cordova stop gps');
        return window.BackgroundGeolocation.stop();
    }
}
