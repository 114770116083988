import {typedScreenOptions} from "../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "./all-serializables";


export const warningSVCScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Warnings,
    fields: {
        opsTitle: 1, // Ops!!
        successTitle: 2, // Sucesso!,
        failedRequest: 3, // Falha ao processar a requisição
        loadListError: 4, // Erro ao carregar a lista
    }
} as const);

export const warningSVCScreenOptionsTransalation = getTranslationsFields(warningSVCScreenOptions);