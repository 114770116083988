import { constant } from "../../../business/constant";
import { suggestions } from "../../../tools/type-utils";
import { typedScreenOptions, IdField } from "../../../tools/utility";
import { getTranslationsFields, EAllSerializable } from "../all-serializables";

const visualElementsMenuTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.VisualElements,
    fields: suggestions<{ [key in string]: IdField }>()({
        visualElements: constant.serializableField.name,
        visualElementsIcon: constant.serializableField.icon,
    })
} as const));

export const visualElementsTranslations = {
    ...visualElementsMenuTranslations,
}