import { EventEmitter, Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { IParticipantJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { SessionService } from "./session.service";
import { ISubscriber, TIdGroupSet } from 'app/supervisor-agent.model';

export interface IPlayerInfoChangedData {
    removedGroupsFromPlayer: TIdGroupSet 
    addedGroupsToPlayer: TIdGroupSet
}

/**
 * This service will maintain all the infos contained in playerCachedInfo which is all the avatars from this player,
 * all groups that he's in, with theirs participants and respective roles.
 * It will also listen for changes on the personal group of the player for changes
 */
@Injectable({
    providedIn: 'root'
})
export class PlayerInfoService {
    private playerInfo: PlayerCachedInfo;
    private playerChangedData: EventEmitter<IPlayerInfoChangedData> = new EventEmitter<IPlayerInfoChangedData>();

    constructor() { }

    public getPlayerInfo(): PlayerCachedInfo {
        return this.playerInfo;
    }

    public init(playerInfo: PlayerCachedInfo): void { this.playerInfo = playerInfo; }

    public isMyPyParticipant(idParticipant: TGlobalUID): boolean {
        return this.getPlayerInfo().isMyParticipant(idParticipant);
    };

    public avatarBelongsToPlayer(idAvatar: TGlobalUID): boolean {
        return this.getPlayerInfo().avatarBelongsToPlayer(idAvatar);
    };

    public interactionMadeByMe(interaction: IInteractionJSON): boolean {
        return this.getPlayerInfo().interactionMadeByMe(interaction)
    };

    public listenPlayerInfoChanged(): ISubscriber<IPlayerInfoChangedData> {
        return this.playerChangedData
    }

    public broadcastPlayerInfoChanged(playerInfoData: IPlayerInfoChangedData): void {
        this.playerChangedData.emit(playerInfoData)
    }
};
