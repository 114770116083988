import { MainSignal } from "./main-signal";
import { observableList } from "./signal-constants";

export class ActivateGeoSignal extends MainSignal {
    constructor() {
        super(observableList.geo.activate, true, null)
    }
}
export class DeactivateGeoSignal extends MainSignal {
    constructor() {
        super(observableList.geo.deactivate, true, null)
    }
}
