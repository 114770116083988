import { Injectable } from '@angular/core';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { SecurityContextSignal } from 'app/model/signal/state-signals/sec-contex-signal';
import { SessionService } from '../session.service';
import { NavigatorServices } from '../controllers-services/navigator/navigator.service';

@Injectable({
    providedIn: 'root'
})
export class SecurityContextChangeHandlerService {
    private groupSetFromUpToDatePlayerInfoNotOnPlayerCached: Set<string>;

    constructor(
        private sessionSvc: SessionService,
        private navigatorSvc: NavigatorServices
    ) { }

    public handlePlayerChangedInfoThroughWebSocket(signal: SecurityContextSignal) {
        const newUpToDatePlayer = signal.getPlayerCached()
        this.setupLastSocialNetworksAddedToPlayer(newUpToDatePlayer, signal.getLastPlayerCached())
        this.gotoGroupIfSomeAdministratorAddedMeToSomeSocialNetwork()
    }

    private gotoGroupIfSomeAdministratorAddedMeToSomeSocialNetwork() {
        const isCurrentGroupRootSN = this.sessionSvc.getSelectedGroup().getPrimaryID() == constant.socialContext.colmeia
        const {groupId, isAvatarAdded} = this.isMyAvatarAddedToSomeGroup()

        if(isAvatarAdded && isCurrentGroupRootSN) {
            this.navigatorSvc.navigateToGroupID(groupId)
        }
    }

    private isMyAvatarAddedToSomeGroup(): {groupId: TGlobalUID, isAvatarAdded: boolean} {
        if(this.groupSetFromUpToDatePlayerInfoNotOnPlayerCached.size > 0) {
            const groupId = this.groupSetFromUpToDatePlayerInfoNotOnPlayerCached.values().next().value
            return {groupId, isAvatarAdded: true}
        }

        return {groupId: null, isAvatarAdded: false}
    }

    private setupLastSocialNetworksAddedToPlayer(playerCached: PlayerCachedInfo, lastPlayerCached: PlayerCachedInfo) {
        this.groupSetFromUpToDatePlayerInfoNotOnPlayerCached = this.getLastSocialNetworksAddedToPlayer(
            playerCached,
            lastPlayerCached)
    }

    public getLastSocialNetworksAddedToPlayer(playerCached: PlayerCachedInfo, lastPlayerCached: PlayerCachedInfo) {
        return this.difference(
            playerCached.getAllPlayerSN(),
            lastPlayerCached.getAllPlayerSN())
    }
    public getLastSocialNetworksAddedToPlayerBySignal(signal: SecurityContextSignal) {
        return this.getLastSocialNetworksAddedToPlayer(
            signal.getPlayerCached(),
            signal.getLastPlayerCached())
    }

    public getLastGroupsAddedToPlayer(signal: SecurityContextSignal) {
        return this.difference(
            signal.getPlayerCached().getGroupArrayID(),
            signal.getLastPlayerCached().getGroupArrayID())
    }

    public getLastRemovedGroupsToPlayer(signal: SecurityContextSignal) {
        return this.difference(
            signal.getLastPlayerCached().getGroupArrayID(),
            signal.getPlayerCached().getGroupArrayID())
    }

    private difference<T>(setA: Array<T>, setB: Array<T>) {
        let _difference = new Set(setA)
        for (let elem of setB) {
            _difference.delete(elem)
        }
        return _difference
    }
}
