import { Injectable } from '@angular/core';
import { allSeverityRegistry } from '@colmeia/core/src/core-constants/tracker-qualifiers';
import { TArrayID, TNserUID } from '@colmeia/core/src/core-constants/types';
import { IListNonSerializablesRequest } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { IListNonSerializablesResponse } from '@colmeia/core/src/dashboard-control/dashboard-response-interfaces';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { EBPMType, TINewExternalElementArray } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { BasicElement, TBasicElementArray } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element';
import { BasicElementFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element.factory';
import { IBasicElementServer, TIBasicElementClientArray, TIBasicElementServerArray } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces';
import { EGraphElementAction, IGraphElementActionDescriptorResult, TGraphElementActionDescriptorList } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element-action.types';
import { IPredicateElementJSON } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-interfaces';
import { EGraphElementType, TGraphElementArray } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-types';
import { IBPMNestedAIRootAlreadyExistRequest, IBPMNestedAIRootAlreadyExistResponse, IDeleteBPMElementsRequest, IDeleteBPMElementsResponse, IFilterAlreadyUsedBpmRootElementBPMElementRequest, IFilterAlreadyUsedBpmRootElementBPMElementResponse, IGetAllElementsByRootRequest, IGetAllElementsByRootResponse, IGetAllPrintableExternalElementsRequest, IGetAllPrintableExternalElementsResponse, ISaveBPMElementsRequest, ISaveBPMElementsResponse } from '@colmeia/core/src/shared-business-rules/knowledge-base/bpm/bpm-req-resp';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { InteractionPersistorServices } from 'app/services/interaction-persistor.service';
import { NsDeleterService } from 'app/services/ns-deleter-service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { BpmSaveHostedService } from '../bpm-save-hosted.service';
import { Observable, Subject } from 'rxjs';
import { GraphElement } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element';
import { GraphPredicate } from '@colmeia/core/src/shared-business-rules/graph/essential/predicate';
import { GraphRulesProcessor } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-rules-processor';
import { EAdditionalPredicateType } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';

export type TElementsCRUDOp = {
    type: 'start' | 'finish';
    elements?: Array<{
        action: EGraphElementAction,
        element: BasicElement
    }>;
};

@Injectable({
    providedIn: 'root'
})
export class BpmApiService {

    private _elementsCRUDOperation$: Subject<TElementsCRUDOp> = new Subject();

    get elementsCRUDOperation$(): Observable<TElementsCRUDOp> {
        return this._elementsCRUDOperation$.asObservable();
    }

    constructor(
        private apiSvc: ServerCommunicationService,
        private bpmSaveHostedService: BpmSaveHostedService,
        private nserDeleterSvc: NsDeleterService,
        private warningSvc: GlobalWarningService,
        private interactionPersistorSvc: InteractionPersistorServices,
    ) { }

    async getNotAlreadyUsedBotRootList(tagSearch: ITagableSearch) {
        const response = await this.apiSvc.doRequest<IFilterAlreadyUsedBpmRootElementBPMElementRequest>(apiRequestType.dashboardExtra.ai.filterAlreadyUsedBpmRootElement, {
            taggable: tagSearch,
            cursor: undefined,
        }) as IFilterAlreadyUsedBpmRootElementBPMElementResponse;

        return response
    }

    async getAllBPMGraphRootElements(bpmType: EBPMType): Promise<TBasicElementArray> {
        const response: IListNonSerializablesResponse = await this.apiSvc.doRequest<IListNonSerializablesRequest>(
            apiRequestType.dashboardExtra.ai.bpm, {
            bpmType,
            multipleCursor: {}
        }) as IListNonSerializablesResponse

        const basicElementList: TBasicElementArray = response.nonSerializableArray
            .map(nSer => {
                const basicServer = nSer as IBasicElementServer
                const basicElement = BasicElementFactory.createGraphNode(EGraphElementType.root, {
                    name: basicServer.nName,
                    graphJSON: basicServer.element,
                })
                return basicElement
            })

        return basicElementList
    }

    public async getRootAndAllChildren(
        idGraphRoot: string,
        bpmType: EBPMType
    ): Promise<IGetAllElementsByRootResponse> {
        const response = await this.apiSvc.doRequest<IGetAllElementsByRootRequest>(apiRequestType.dashboardExtra.ai.getAllElementsByRoot, {
            idGraphRoot,
            bpmType,
            // enableBPMRuntimeResolver: environment.allDevFeatures
        }) as IGetAllElementsByRootResponse

        this.logInfo(idGraphRoot, bpmType, response)

        return response
    }

    private logInfo(
        idGraphRoot: string,
        bpmType: EBPMType,
        response: IGetAllElementsByRootResponse
    ) {
        const errors = []
        const graphNodesInfo = response.graphElements
            .map(ge => {
                const hosted: INonSerializable | null = response.hostedElementsMap[ge.element.idHostedObject]
                const isEdge = ge.element.elementType == EGraphElementType.predicate

                if (!isValidRef(hosted) && !isEdge) {
                    const errorObj = {
                        errMsg: `for graphNodeId: ${ge.idNS} NO hostedId: ${ge.element.idHostedObject} arrived from server!!!!`,
                        idGraphRoot, bpmType, ge, hosted
                    }
                    errors.push(errorObj)
                }

                const edgeType = isEdge ? (<IPredicateElementJSON>ge.element).businessPredicateType : undefined
                return {
                    "node": { name: ge.nName, id: ge.idNS, edgeType },
                    "hosted": { name: hosted?.nName ?? null, id: hosted?.idNS ?? null }
                }
            })

        console.log(JSON.stringify(graphNodesInfo, null, 2))
        if (isValidArray(errors)) {
            errors.forEach(error => {
                console.error(error)
                this.interactionPersistorSvc.sendTrace(
                    allSeverityRegistry.critical,
                    "BpmApiService.getRootAndAllChildren",
                    error);
            });
        }
    }

    public async persistGraphElements(bpmType: EBPMType, elements: TBasicElementArray, idGraphRoot?: string): Promise<TIBasicElementServerArray> {
        const toBeSent: TIBasicElementClientArray = elements.map((e: BasicElement) => ({
            nsType: ENonSerializableObjectType.graphElement,
            nName: e.getName(),
            element: e.toJSON(),
        }))

        return await this.persistGraphElementsByJSON(bpmType, toBeSent, idGraphRoot)
    }

    public async persistGraphElementsByJSON(bpmType: EBPMType, elements: TIBasicElementClientArray, idGraphRoot?: string): Promise<TIBasicElementServerArray> {

        const elementsWithRoot = elements.map(nSer => {
            if (nSer.element.elementType !== EGraphElementType.root) {
                if (!idGraphRoot) {
                    throw new Error("graphElement sem pai nao pode ser armazenado!!!");
                }
                nSer.idParent = idGraphRoot;
            }
            return nSer
        })

        const response: ISaveBPMElementsResponse = (await this.apiSvc.doRequest<ISaveBPMElementsRequest>(
            apiRequestType.dashboardExtra.ai.saveBpm,
            {
                nsType: ENonSerializableObjectType.graphElement,
                bpmType: bpmType,
                nserList: elementsWithRoot,
            }
        )) as ISaveBPMElementsResponse;
        return response.elements
    }

    public async deleteGraphElementList(bpmType: EBPMType, elementList: TBasicElementArray): Promise<IDeleteBPMElementsResponse> {
        const idGraphElements = elementList.map(el => el.getGraphElementID())
        const resp = this.deleteGraphElementsById(bpmType, idGraphElements)
        return resp
    }

    public async deleteGraphElementsById(bpmType: EBPMType, idGraphElements: string[], isAllowingRootDeletion?: boolean): Promise<IDeleteBPMElementsResponse> {
        const response = await this.apiSvc.doRequest<IDeleteBPMElementsRequest>(apiRequestType.dashboardExtra.ai.deleteBpm, {
            nsType: ENonSerializableObjectType.graphElement,
            nsIdsToDelete: idGraphElements,
            isAllowingRootDeletion,
            bpmType,
            // enableBPMRuntimeResolver: environment.allDevFeatures
        }) as IDeleteBPMElementsResponse

        if (!response.friendlyError.okState || isValidArray(response.friendlyError.deleteDependencies)) {
            const errorMsg = "Nao foi possivel deletar elementos, dependencias: " + JSON.stringify(response.friendlyError.deleteDependencies)
            this.warningSvc.showError(errorMsg)
            throw new Error(errorMsg);
        }
        return response;
    }

    async batchPersistElements(bpmType: EBPMType, graphNodesToPersist: TGraphElementActionDescriptorList, graphProcessor: GraphRulesProcessor): Promise<IGraphElementActionDescriptorResult> {
        try {
            const idGraphRoot: string = graphProcessor.getRootElementId();
            const result: IGraphElementActionDescriptorResult = {
                savedBasicGraphElements: [],
                savedElements: {},
            };
            const beforeExecEvent: TElementsCRUDOp = {
                type: 'start',
                elements: []
            };

            for (const elementData of graphNodesToPersist) {
                if (!isValidArray(elementData.elementList)) continue;

                for (const element of elementData.elementList) {
                    beforeExecEvent.elements.push({
                        action: elementData.action,
                        element
                    })
                }
            }

            console.log({ beforeExecEvent })

            this._elementsCRUDOperation$.next(beforeExecEvent);

            for (const elementData of graphNodesToPersist) {
                if (elementData.action === EGraphElementAction.Save) {
                    if (isValidArray(elementData.nsList)) {

                        const nss = await Promise.all(
                            elementData.nsList.map(ns => {
                                const graphNode = graphProcessor.findNodeElementByHostedId(ns.getHostedID());
                                const neighbor = graphNode.getNeighborsPointingToMe();
                                const parentNode: GraphElement | undefined = neighbor.find(({ pred }) => pred.getBusinessPredicateType() === EAdditionalPredicateType.Parent)?.neighbor;

                                return this.bpmSaveHostedService.save(bpmType, ns.getNonSerializable(), idGraphRoot, parentNode?.getGraphElementID())
                            })
                        );

                        nss.flat().forEach(ns => {
                            const currentSaved: INonSerializable = result.savedElements[ns.idNS];
                            const isNewest: boolean = !currentSaved || (currentSaved.lastTouch < ns.lastTouch);

                            if (isNewest) {
                                result.savedElements[ns.idNS] = ns;
                            }
                        });
                    }

                    if (isValidArray(elementData.elementList)) {
                        result.savedBasicGraphElements = await this.persistGraphElements(bpmType, elementData.elementList, idGraphRoot)
                    }
                }
            }

            for (const elementData of graphNodesToPersist) {
                if (elementData.action === EGraphElementAction.Remove) {
                    if (isValidArray(elementData.nsList)) {
                        const deleteNserRequestList = elementData.nsList.map(ns => this.nserDeleterSvc.delete(ns.getNonSerializable()))
                        await Promise.all(deleteNserRequestList)
                    }

                    if (isValidArray(elementData.elementList)) {
                        await this.deleteGraphElementList(bpmType, elementData.elementList)
                    }
                }
            }


            return result

        } catch (e) {
            console.error(e);
        } finally {
            this._elementsCRUDOperation$.next({ type: 'finish' });
        }
    }

    async getAllPrintableExternalElements(bpmType: EBPMType, idNSList: TArrayID): Promise<TINewExternalElementArray> {
        const response = await this.apiSvc.doRequest<IGetAllPrintableExternalElementsRequest>(apiRequestType.dashboardExtra.ai.getExternalPrintableDependenciesOf, {
            bpmType,
            idNSList
        }) as IGetAllPrintableExternalElementsResponse

        return response?.external || [];
    }

    async isRootOfAnotherBPM(idHostedObject: TNserUID): Promise<boolean> {
        const response = await this.apiSvc.safeSendRequest<IBPMNestedAIRootAlreadyExistRequest
            , IBPMNestedAIRootAlreadyExistResponse>(apiRequestType.dashboardExtra.ai.isBPMNestedAIRootAlreadyExist)({
                idHostedObject: idHostedObject
            });
        return response?.isBPMNestedAIRootAlreadyExist;
    }
}
