import { TArrayID } from '../../../core-constants/types';
import { isInvalidArray, isInvalidArrayWithFilter, isValidArray, isValidRef, requiredUpdateFieldSubset } from '../../../tools/utility';
import { EBPMType } from '../../BPM/bpm-model';
import { TIBasicToolbarElementArray } from '../../graph-transaction/toolbar/config-toolbar.types';
import { IGetAllElementsByRoot } from '../../knowledge-base/bpm/bpm-req-resp';
import { BasicElement, TBasicElementArray } from './basic-element';
import { BasicElementFactory } from './basic-element.factory';
import { IBasicElementServer } from './graph-basic-element-interfaces';
import { GraphElement } from './graph-element';
import { TGraphElementActionDescriptorList } from './graph-element-action.types';
import { initRuleProcessorBasicJSON } from './graph-initializer';
import { IGraphElementJSON, IRuleProcessorBasicJSON, IRuleProcessorJSON } from './graph-interfaces';
import { GraphRoot } from './graph-root-element';
import { EGraphElementType, IExternalSubTreeSliced, IGraphConnectionDB, TGraphElementArray, TRenderData } from './graph-types';
import { GraphPredicate, TGraphPredicateArray } from './predicate';


const ignore = undefined;

export type TAllGraphElements = { [idElement: string]: BasicElement };
export interface IGraphRulesProcessorState extends IRuleProcessorBasicJSON {
    allGraphElements: TAllGraphElements;
}

export interface IBPMAllElementsByRootIncludingItself {
    rootElement: GraphRoot,
    rootElementServer: IBasicElementServer,
    allElements: TBasicElementArray,
    graphRulesProcessor: GraphRulesProcessor
}

export interface IDeleteElementResult {
    fromConnections: GraphPredicate[]
    toConnections: GraphPredicate[]
}

// Componente do fernando
export interface IVisualBPMComponentCallback {
    addConnection(predicate: GraphPredicate): void;
    removeConnection?(predicate: GraphPredicate): void;
    renderNodeGraphElement(graph: GraphElement): void;
    renderBatchElements(elementList: TGraphElementActionDescriptorList): void
    drawGraphsToolbar(toolbarElements: TIBasicToolbarElementArray): void;
    rollbackDiagramState(): void;
    findNodeModelById<R>(id: string): R;
    getElementCoordinatesToRenderData(element: any): TRenderData
    findDropAreaFrom(element: any): Pick<TRenderData, "offsetX" | "offsetY">
}

export class GraphRulesProcessor {

    private componentCallback: IVisualBPMComponentCallback;
    private state: IGraphRulesProcessorState;
    private mapTypeToElements: Map<EGraphElementType, BasicElement[]> = new Map();

    private get toConnections(): IGraphConnectionDB { return this.state.fromConnections }
    private set toConnections(value: IGraphConnectionDB) { this.state.fromConnections = value; }

    private get fromConnections(): IGraphConnectionDB { return this.state.toConnections }
    private set fromConnections(value: IGraphConnectionDB) { this.state.toConnections = value; }

    private get allGraphElements(): TAllGraphElements { return this.state.allGraphElements }
    private set allGraphElements(value: TAllGraphElements) { this.state.allGraphElements = value; }

    private constructor() {
        this.state = {} as IGraphRulesProcessorState;
        this.reset();
    };

    public getAllGraphElements(): TAllGraphElements {
        return this.allGraphElements;
    };

    public getAllNodesAndEdges(): BasicElement[] {
        return Object.values(this.allGraphElements)
    }

    public setAllGraphElements(allElements: TAllGraphElements): void {
        this.allGraphElements = allElements;
    };

    public setComponentCallback(back: IVisualBPMComponentCallback): void {
        this.componentCallback = back;
    }

    public getVisualComponentCallback(): IVisualBPMComponentCallback {
        return this.componentCallback;
    }

    public getElementById<Element extends BasicElement>(elementId: string): Element {
        return this.allGraphElements[elementId] as Element;
    }

    public toJSON(): IRuleProcessorJSON {
        return {
            ...this.state,
            allGraphElements: {},
        };
    }

    public rehydrate(json: IRuleProcessorBasicJSON): void {
        requiredUpdateFieldSubset(this.state, json);
    }

    public reset(): void {
        requiredUpdateFieldSubset(this.state, {
            fromConnections: {},
            toConnections: {},
            allGraphElements: {},
        });
    }

    public getAllToConnectionsFromGraph(): GraphPredicate[] {
        const allConnections: string[] = Object.values(this.fromConnections).reduce((acc, predicates) => { acc.push(...predicates); return acc }, []);
        return allConnections.map(e => <GraphPredicate>this.getElementById(e));
    }

    public getAllFromConnectionsFromGraph(): GraphPredicate[] {
        const allConnections: string[] = Object.values(this.toConnections).reduce((acc, predicates) => { acc.push(...predicates); return acc }, []);
        return allConnections.map(e => <GraphPredicate>this.getElementById(e));
    }

    public getSourceNode(predicate: GraphPredicate) {
        return this.getElementById(predicate.getFromElementId())
    }

    public getTargetNode(predicate: GraphPredicate) {
        return this.getElementById(predicate.getToElementId())
    }

    // A -> P1 -> B
    // A -> P2 -> C
    // getConnectionsToOthers(A) -> [P1, P2]
    public getConnectionsToOthers(idNodeElement: string): GraphPredicate[] {
        if (isValidArray(this.toConnections[idNodeElement])) {
            const connections = this.toConnections[idNodeElement].map(e => <GraphPredicate>this.getElementById(e));
            return connections;
        };
        return [];
    };

    // A -> P1 -> B
    // C -> P2 -> B
    // getConnectionsFromOthers(B) -> [P1, P2]
    public getConnectionsFromOthers(idNodeElement: string): GraphPredicate[] {
        if (isValidArray(this.fromConnections[idNodeElement])) {
            return this.fromConnections[idNodeElement].map(e => <GraphPredicate>this.getElementById(e));
        };
        return [];
    };

    public getElementsOnTop(isIncludingRoot?: boolean): GraphElement[] {
        const items = [...this.getAllGraphNodes()]
        if (isIncludingRoot) items.push(this.getRootElement());

        return items.filter(item => isInvalidArrayWithFilter(this.getConnectionsFromOthers(item.getGraphElementID())));
    }

    public getRootElement(): GraphRoot {
        return this.getElementsByType(EGraphElementType.root)[0] as GraphRoot;
    }

    public getRootElementId(): string {
        return (this.getElementsByType(EGraphElementType.root)[0] as GraphRoot)?.getGraphElementID();
    }

    public getAllGraphNodes(): TGraphElementArray {
        return this.getElementsByType(EGraphElementType.node) as GraphElement[]
    }

    public addGraphElementToDatabase(element: BasicElement): void {
        this.allGraphElements[element.getGraphElementID()] = element;
        this.addElementToTypeMap(element);
    };

    public removeElementList(element: TBasicElementArray): IDeleteElementResult[] {
        return element.map(el => this.removeElement(el))
    }

    /*
        A -> B (predicados com P1)
        P2 apontam para A
    */
    public removeElement(element: BasicElement): IDeleteElementResult {
        if (element.isElementType(EGraphElementType.predicate)) {
            this.removePredicate(<GraphPredicate>element)
            return {
                fromConnections: [<GraphPredicate>element], toConnections: []
            }
        } else {
            const fromConnections: GraphPredicate[] = this.getConnectionsFromOthers(element.getGraphElementID()); // Traz p(x-a), p(y-a), p(b-a)
            const toConnections: GraphPredicate[] = this.getConnectionsToOthers(element.getGraphElementID()); // p(a-b)

            fromConnections.forEach((e) => this.removePredicate(e))
            toConnections.forEach((e) => this.removePredicate(e))

            this.deleteElementFromDB(element);
            return { fromConnections, toConnections }
        }
    }

    public removePredicate(predicate: GraphPredicate): void {
        this.removeFromConnection(predicate.getToElementId(), predicate);
        this.removeToConnection(predicate.getFromElementId(), predicate);
        this.deleteElementFromDB(predicate);
    }

    private removeFromConnection(elementId: string, predicate: GraphPredicate) {
        const predicateIdList = this.fromConnections[elementId]
        const predicateId = predicate.getGraphElementID()
        this.removeFromList(predicateIdList, predicateId);
    }

    private removeToConnection(elementId: string, predicate: GraphPredicate) {
        const predicateIdList = this.toConnections[elementId]
        const predicateId = predicate.getGraphElementID()
        this.removeFromList(predicateIdList, predicateId);
    }

    private deleteElementFromDB(element: BasicElement): void {
        const elementsByType: BasicElement[] = this.mapTypeToElements.get(element.getElementType());
        const index: number = elementsByType.findIndex(currentElement => currentElement.isSameGraphElement(element));
        if (index !== -1) {
            elementsByType.splice(index, 1);
        }
        delete this.allGraphElements[element.getGraphElementID()];
    }

    private addElementToTypeMap(element: BasicElement): void {
        if (this.mapTypeToElements.has(element.getElementType())) {
            const map = this.mapTypeToElements.get(element.getElementType());
            if (map.every(e => !e.isSameGraphElement(element))) {
                map.push(element)
            }
        } else {
            this.mapTypeToElements.set(element.getElementType(), [element])
        }
    }

    public getElementsByType<T extends BasicElement>(type: EGraphElementType): T[] {
        return this.mapTypeToElements.has(type) ? this.mapTypeToElements.get(type) as T[] : [];
    }

    public resetPredicates(predicates: TGraphPredicateArray): void {
        this.toConnections = {};
        this.fromConnections = {};
        predicates.forEach((predicate: GraphPredicate) => this.registerPredicate(predicate));
    }

    // adiciona P <- B no hashmap
    private addConnectionTo(predicate: GraphPredicate) {
        const toElementId = predicate.getToElementId()
        if (isInvalidArray(this.fromConnections[toElementId])) {
            this.fromConnections[toElementId] = [];
        }

        this.addToList(this.fromConnections[toElementId], predicate.getGraphElementID());
        this.addGraphElementToDatabase(predicate);
    }

    // adiciona A -> P no hashmap
    private addConnectionFrom(predicate: GraphPredicate) {
        const fromElementId: string = predicate.getFromElementId();
        if (isInvalidArray(this.toConnections[fromElementId])) {
            this.toConnections[fromElementId] = [];
        }
        this.addToList(this.toConnections[fromElementId], predicate.getGraphElementID());
        this.addGraphElementToDatabase(predicate);
    }

    private addToList(items: TArrayID, item: string): void {
        if (!items.includes(item)) {
            items.push(item);
        }
    }

    public processCycles(rootNode: GraphRoot): void {
        const { discovered, visited, predicateIdListWithCycles } = this.dfs(rootNode)
    }

    /**
     * getSubTree gets all nodes and edges that a node points to, recursively 
     * ex: node0 -> node1 -> node2 -> node0, returns [node1, edge, node2]  
     * @param node 
     * @returns 
     */
    getSubTree(node: GraphElement, includeItself: boolean = false): TBasicElementArray {
        const result = this.dfs(node)
        if (!includeItself) {
            result.visited.delete(node.getGraphElementID())
        }

        const resultElements = [
            Array.from(result.visited.values()).map(visitedId => this.findNodeByGraphID(visitedId)),
            Array.from(result.predicateIdList.values()).map(edgeId => this.getPredicateById(edgeId))
        ].flat().filter(node => isValidRef(node))
        return resultElements
    }

    /**
     * getExternalSubTree gets all nodes and edges that are external
     * @param node 
     * @returns 
     */
    getExternalSubTree(node: GraphElement, includeItself: boolean = false): TBasicElementArray {
        const { externalEdges, externalNodes } = this.getExternalSubTreeSliced(node, includeItself)
        return [externalEdges, externalNodes].flat()
    }

    getExternalSubTreeSliced(node: GraphElement, includeItself: boolean = false): IExternalSubTreeSliced {
        const subtree = this.getSubTree(node, includeItself)
        const externalNodes: GraphElement[] = <GraphElement[]>subtree
            .filter(node => (node.isRoot() || node.isChildNode()) && (<GraphElement>node).isExternalElementOnDiagram())
        const externalEdges: GraphPredicate[] = <GraphPredicate[]>subtree
            .filter(node => node.isElementType(EGraphElementType.predicate)
                && (this.findNodeByGraphID((<GraphPredicate>node).getFromElementId()).isExternalElementOnDiagram()
                    || this.findNodeByGraphID((<GraphPredicate>node).getToElementId()).isExternalElementOnDiagram()))

        return { externalEdges, externalNodes }
    }

    public search(node: GraphElement) {
        this.dfs(node)
    }

    public dfs(node: GraphElement) {
        let discovered: Set<string> = new Set()
        let visited: Set<string> = new Set()
        let predicateIdListWithCycles: Set<string> = new Set()
        let predicateIdList: Set<string> = new Set()

        for (const connectionToOther of node.getConnectionsToOthers()) {
            const notFinishedProcessing = !discovered.has(connectionToOther.getTargetElementId())
                && !visited.has(connectionToOther.getTargetElementId())
            if (notFinishedProcessing) {
                predicateIdList.add(connectionToOther.getGraphElementID());
                ({ discovered, visited, predicateIdListWithCycles } = this
                    .dfsVisitor(connectionToOther, discovered, visited, predicateIdListWithCycles, predicateIdList))
            }
        }

        return { discovered, visited, predicateIdList, predicateIdListWithCycles }
    }

    public dfsVisitor(connection: GraphPredicate, discovered: Set<string>, visited: Set<string>,
        predicateIdListWithCycles: Set<string>, predicateIdList: Set<string>
    ) {
        discovered.add(connection.getTargetElementId())
        const targetElement: GraphElement = this.findNodeByGraphID(connection.getTargetElementId())

        for (const connectionToOther of targetElement.getConnectionsToOthers()) {
            predicateIdList.add(connectionToOther.getGraphElementID())

            if (discovered.has(connectionToOther.getTargetElementId())) {
                predicateIdListWithCycles.add(connectionToOther.getGraphElementID())
                connectionToOther.setPointingToCycle(true)
                break
            }

            if (!visited.has(connectionToOther.getTargetElementId())) {
                this.dfsVisitor(connectionToOther, discovered, visited, predicateIdListWithCycles, predicateIdList)
            }
        }

        discovered.delete(connection.getTargetElementId())
        visited.add(connection.getTargetElementId())
        return { discovered, visited, predicateIdListWithCycles }
    }

    private removeFromList(items: string[], item: string): void {
        const indexes: number[] = items.map((currentItem: string, index: number) => currentItem === item ? index : undefined).filter(isValidRef);
        for (const index of indexes) {
            items.splice(index, 1);
        }
    }

    public findNodeElementByHostedId(hostedId: string): GraphElement {
        return this.getAllNodes().find(element => element.getHostedID() === hostedId);
    }

    public findNodeByGraphID(graphID: string): GraphElement {
        return this.getAllNodes().find(element => element.getGraphElementID() === graphID);
    }

    public getPredicate(fromId: string, toId: string): GraphPredicate {
        return this.getAllPredicates().find(predicate => predicate.getFromElementId() === fromId && predicate.getToElementId() === toId);
    }

    public getPredicateById(idPredicate: string): GraphPredicate {
        return this.getAllPredicates().find(predicate => predicate.getGraphElementID() === idPredicate);
    }

    public getBothNodesAndLinkElements(pred: GraphPredicate): void {
        const from = <GraphElement>this.getElementById(pred.getFromElementId())
        const to = <GraphElement>this.getElementById(pred.getToElementId())
        if (!isValidRef(to)) {
            const errMsg = `problema na tentativa de linkar 2 elementos: node1: ${pred.getFromElementId()} conexao:${pred.getGraphElementID()
                } para node2: ${pred.getToElementId()} <-- inexistente!!!`
            throw new Error(errMsg);
        }
        if (!isValidRef(from)) {
            const errMsg = `problema na tentativa de linkar 2 elementos: node1: ${pred.getFromElementId()
                } <-- inexistente!!! conexao:${pred.getGraphElementID()} para node2: ${pred.getToElementId()} `
            throw new Error(errMsg);
        }
        this.linkElements(from, pred, to);
    }

    public linkElements(fromElement: GraphElement, predicate: GraphPredicate, toElement: GraphElement): void {
        predicate.connectEdges(fromElement, toElement);
        this.registerPredicate(predicate);
    };

    public registerPredicate(predicate: GraphPredicate): void {
        this.addConnectionTo(predicate);
        this.addConnectionFrom(predicate);
    }

    public getAllPredicates(): TGraphPredicateArray {
        return this.getElementsByType(EGraphElementType.predicate);
    }

    public getAllNodes(): GraphElement[] {
        const nodeTypes: EGraphElementType[] = [EGraphElementType.node, EGraphElementType.root];

        return Object.values(this.getAllGraphElements()).filter(el => nodeTypes.includes(el.getElementType())) as GraphElement[];
    }

    public getAllNodesJSON(): IGraphElementJSON[] {
        return this.getAllNodes().map(e => e.toJSON());
    }

    public static create(json: IRuleProcessorBasicJSON = initRuleProcessorBasicJSON()): GraphRulesProcessor {
        const instance: GraphRulesProcessor = new GraphRulesProcessor();
        instance.rehydrate(json);
        return instance;
    }

    public static initGraphByNodesAndHostedList(bpmType: EBPMType, response: IGetAllElementsByRoot, printGraph: boolean = true): IBPMAllElementsByRootIncludingItself {
        let rootElementServer: IBasicElementServer;
        const graphRulesProcessor = GraphRulesProcessor.create()
        const basicElementList: TBasicElementArray = response.graphElements
            .map((basicElementServer: IBasicElementServer) => {
                if (basicElementServer.isGraphRoot) {
                    rootElementServer = basicElementServer
                }
                const nser = response.hostedElementsMap[basicElementServer.element.idHostedObject]
                return BasicElementFactory.create(basicElementServer.element.elementType, {
                    bpmType,
                    name: basicElementServer.nName,
                    hostNser: nser,
                    graphProcessor: graphRulesProcessor,
                    element: basicElementServer.element,
                    isExternalElement: (<IGraphElementJSON>basicElementServer.element).isExternalElement,
                })
            })

        const addConnectionsToGraph = (basicElementList: TBasicElementArray) => basicElementList
            .filter(el => el.isElementType(EGraphElementType.predicate))
            .forEach(pred => graphRulesProcessor.getBothNodesAndLinkElements(<GraphPredicate>pred))
        addConnectionsToGraph(basicElementList)

        graphRulesProcessor.processCycles(graphRulesProcessor.getRootElement())
        printGraph && graphRulesProcessor.printGraph()

        return {
            rootElementServer,
            rootElement: graphRulesProcessor.getRootElement(),
            allElements: basicElementList,
            graphRulesProcessor,
        };
    }

    private printGraph() {
        const graphNodesInfo = this.getAllNodesAndEdges().map(nodeOrConnection => {
            const hosted = nodeOrConnection.getHostObject()
            const edgeType = nodeOrConnection.isConnection() ? (<GraphPredicate>nodeOrConnection).getBusinessPredicate() : undefined
            if (nodeOrConnection.isNode() && !isValidRef(hosted)) {
                console.log({ node: nodeOrConnection });
            }
            return {
                "node": { name: nodeOrConnection.getName(), id: nodeOrConnection.getGraphElementID(), edgeType },
                "hosted": { name: hosted?.getHostedName(), id: hosted?.getHostedID() }
            }
        })

        console.log(JSON.stringify(graphNodesInfo, null, 2))
    }
}
