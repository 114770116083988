import { IBotMenuOption } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { DEFAULT_WHATSAPP_SECTION_NAME } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { WhatsApp } from "./whatsapp-interface";



export namespace WhatsAppFunctions {
    const pattern = /(\*|`|_|~)/g;
    const escapedPattern = new RegExp(getEscapedText(pattern.source), 'g');
    
    export function escape(text: string) {
        return unescape(text).replace(pattern, getEscapedText('$1'));
    }

    export function unescape(text: string) {
        return text.replace(escapedPattern, '$1');
    }

    function getEscapedText(text: string) {
        return `\`\`\`${text}\`\`\``
    }
}

export namespace WhatsAppFunctions.Menu {
    export function parseOptions(menuOptions: IBotMenuOption[]): IBotMenuOption[] {
        let lastSection: string = DEFAULT_WHATSAPP_SECTION_NAME;

        const options: IBotMenuOption[] = (menuOptions);

        for (const option of options) {
            option.sectionName ||= lastSection;
            lastSection = option.sectionName;
        }
        return options;
    }
}

export function isInvalidTemplateStatus(status: WhatsApp.Template.Status): boolean {
    return WhatsApp.Template.ForbiddenToSendWhatsappTemplate.includes(status)
}

export function isTemporaryUnavailableTemplateStatus(status: WhatsApp.Template.Status): boolean {
    return WhatsApp.Template.TemporaryUnavaiableTemplateStatus.includes(status)
}