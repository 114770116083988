import { SchemaProperty } from "@colmeia/core/src/general-form/general-form-interface";
import {
    EMetadataEngagementType, EMetaEngagementConfigContext, IMetadataRegisterDynamicListConfig,
    IMetaEngagement
} from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { TIVariablesArray } from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { IComponentParameter, IRootCallBack } from "app/model/component-comm/basic";
import { Subject } from "rxjs";
import { MainHandler } from "./main-handler";

export interface IMetaEngagamentHandlerCallbacks extends IRootCallBack {

}

export interface IMetaEngagementHandlerData {
    idKnowledgeBase?: string;
}


export interface IMetaEngagementHandlerParameters extends IComponentParameter {
    context: EMetaEngagementConfigContext,
    metaEngagement: IMetaEngagement;
    idSchema: string;
    title: string;
    clientCallback: IMetaEngagamentHandlerCallbacks;
    fieldFilter?: (schemaProperty: SchemaProperty) => boolean;
    includeCanonical?: boolean;
    data?: IMetaEngagementHandlerData;
    allowedEngagementTypes: EMetadataEngagementType[];
    allowedEngagementConfigTypes?: EMetadataEngagementType[];
    nsVariables?: TIVariablesArray,
    allowMandatory?: boolean,
    isMandatory?: boolean,
    allowDynamicList?: boolean,
    dynamicListConfig?: IMetadataRegisterDynamicListConfig,
    onChangeMandatory?: (isMandatory: boolean) => void
}

const defaultCallbacks: IMetaEngagamentHandlerCallbacks = {};

export class MetaEngagementHandler extends MainHandler {

    schemaUpdate: Subject<void>;
    // Subject to allow MetaEngagementComponent to track any changes to data param
    dataUpdate: Subject<void>;

    constructor(parameters: IMetaEngagementHandlerParameters) {
        parameters.clientCallback = {
            ...defaultCallbacks,
            ...parameters.clientCallback
        }

        if (parameters.includeCanonical == undefined) {
            parameters.includeCanonical = true;
        }

        super(parameters);

        this.schemaUpdate = new Subject<void>();
        this.dataUpdate = new Subject<void>();
    }

    get params(): IMetaEngagementHandlerParameters { return this.getComponentParameter() }

    public getComponentParameter(): IMetaEngagementHandlerParameters {
        return super.getComponentParameter() as IMetaEngagementHandlerParameters;
    };

    setIdSchema(idSchema: string) {
        this.params.idSchema = idSchema;
        this.schemaUpdate.next();
    }

    // Engagement Type specific setters
    setIdKnowledgeBase(idKnowledgeBase: string) {
        if (isValidRef(this.params.data)) {
            this.params.data.idKnowledgeBase = idKnowledgeBase;
        } else {
            this.params.data = { idKnowledgeBase };
        }
        this.dataUpdate.next();
    }


}
