import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import {NDropDown} from "../../../../handlers/dropdown.handler";


@Injectable({
    providedIn: 'root'
})
export class DropdownGlobalService {
    private handlerSubject: Subject<NDropDown.DropDownHandler> = new Subject<NDropDown.DropDownHandler>();

    constructor() { }

    public listenHandler(): Observable<NDropDown.DropDownHandler> {
        return this.handlerSubject.asObservable();
    }

    public setHandler(handler: NDropDown.DropDownHandler): void {
        this.handlerSubject.next(handler);
    }
}
