<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">
  <!-- <mat-form-field class="field" [style.margin-left]="0">
    <input matInput [(ngModel)]="canonical.nName" [placeholder]="translations.name" type="text" name="name" [formControl]="nameFormControl" >
    <mat-error>{{ translations.fieldRequired }}</mat-error>
  </mat-form-field> -->
  <mat-form-field class="field standard-form-field full-width-field" [style.margin-right]="0" style="margin-left: 0px;">
    <mat-label>{{translations.globalCanonical}}</mat-label>
    <mat-select [(ngModel)]="canonical.globalCanonical" name="globalCanonical">
      <mat-option *ngFor="let option of canonicalOptions;" [value]="option.value">{{ option.label }}</mat-option>
    </mat-select>
    <mat-error>{{ translations.fieldRequired }}</mat-error>
  </mat-form-field>
  <mat-dialog-actions align="end">
    <div class="toggle-options">
      <mat-checkbox [(ngModel)]="canonical.isNotVisibleByAgent" color="primary">Não visível ao agente</mat-checkbox>
      <mat-checkbox [(ngModel)]="canonical.isLGPDSensitive" color="primary">Sensível a LGPD</mat-checkbox>
    </div>
    <button [loading]="saving" mat-flat-button color="primary" (click)="save()">{{ translations.save }}</button>
  </mat-dialog-actions>
</cm-generic-dashboard-edit>
