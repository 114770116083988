import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IRequestClientCacheConfig, requestClientCacheConfig } from "@colmeia/core/src/rules/dashboard-routes/request-type-config/request-client-cache-config";
import { EClientContext } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { getClock, isSuperValidObject } from "@colmeia/core/src/tools/utility";
import { ClientInfraResponse } from "app/model/client-infra-comm";
import { createServiceLogger } from "app/model/client-utility";

interface IRequestCacheData {
    created: number,
    request: IRequest
    infraResponse: ClientInfraResponse;
}

@Injectable()
export class RequestClientCacheService {
    private isEnabled: boolean = true;
    private requestCacheMap: Map<string, IRequestCacheData> = new Map();

    private log = createServiceLogger('RequestClientCacheService');

    constructor(private router: Router) {
    }

    public getCachedResponse(request: IRequest): ClientInfraResponse | undefined {
        if (!this.isEnabled) return;

        const isCacheable = this.isCacheableRequest(request);

        if (!isCacheable) return;

        const cacheData = this.getCacheData(request);

        if (!cacheData) return;

        if (this.isCacheExpired(cacheData)) {

            this.deleteCache(request);
            return;
        }

        this.log('Served from cache', { request, infraResponse: cacheData.infraResponse.response });

        return cacheData.infraResponse

    }

    public saveCache(request: IRequest, infraResponse: ClientInfraResponse) {
        if (!this.isEnabled) return;

        const cacheKey = this.getRequestCacheKey(request);
        const created = getClock();

        this.log('Cache created', { request, infraResponse, created: new Date(created) });

        this.requestCacheMap.set(cacheKey, {
            created,
            request,
            infraResponse,
        })
    }

    private getAppContext(): EClientContext {
        return this.router.routerState.root?.firstChild?.snapshot?.data?.appContext as EClientContext;
    }

    public isCacheableRequest(request: IRequest): boolean {
        const requestCacheConfig: IRequestClientCacheConfig = this.getRequestConfig(request);

        if (!isSuperValidObject(requestCacheConfig)) {
            return false;
        }

        const appContext = this.getAppContext();
        const { contexts } = requestCacheConfig;
        const shouldCacheInThisContext: boolean = contexts.includes(appContext);

        return shouldCacheInThisContext;
    }

    private getCacheData(request: IRequest): IRequestCacheData | undefined {
        const cacheKey = this.getRequestCacheKey(request);
        return this.requestCacheMap.get(cacheKey);
    }

    private isCacheExpired(cacheData: IRequestCacheData): boolean {
        const cacheConfig = this.getRequestConfig(cacheData.request);
        const now = getClock();
        const isExpired: boolean = now - cacheData.created > cacheConfig.ttl;

        return isExpired;
    }

    private getRequestCacheKey(request: IRequest): string {
        const requestConfig: IRequestClientCacheConfig = this.getRequestConfig(request);
        const keyGenerated: string = requestConfig?.getKey(request);

        return keyGenerated;
    }

    private getRequestConfig(request: IRequest): IRequestClientCacheConfig | undefined {
        return requestClientCacheConfig[request.requestType];
    }

    private deleteCache(request: IRequest): void {
        const cacheKey = this.getRequestCacheKey(request);

        this.log('Cache invalidate', { cacheKey, request });

        this.requestCacheMap.delete(cacheKey);
    }

    public invalidateCache(requestType: string): void {
        for (const [cacheKey, cacheData] of this.requestCacheMap) {
            if (cacheData.request.requestType === requestType) {
                this.requestCacheMap.delete(cacheKey);
            }
        }
    }

}
