import { Group } from '@colmeia/core/src/business/group';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { EYouTaskStatus } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { IFollowButtonHandlerParameter } from 'app/model/follow-button.model';

export class FollowButtonHandler {

    private parameter: IFollowButtonHandlerParameter;

    constructor(parameter: IFollowButtonHandlerParameter) {
        this.parameter = parameter;
    }

    getPrimaryID(): TGlobalUID {
        return this.parameter.primaryID;
    }

    getObjectType(): TGlobalUID {
        return this.parameter.idObjectType;
    }

    getSubscriptionStatus(): EYouTaskStatus {
        return this.parameter.subscriptionStatus;
    }

    canShowChatButton(): boolean {
        return this.parameter.canShowChatButton;
    }

    getGroup(): Group | undefined {
        return this.parameter.group;
    }

}
