<h3>{{ translations.botHistory }}</h3>

<mat-progress-spinner *ngIf="!botHistory" mode="indeterminate" diameter="30">
</mat-progress-spinner>

<app-alert type="info" dense *ngIf="botHistory?.length == 0">
    {{ translations.noBotHistory }}
</app-alert>

<div class="bot-history-messages-container" *ngIf="botHistory?.length > 0">
    <div class="bot-history-message-container" *ngFor="let message of botHistory">
        <!--div *ngIf="message.menuTitle" class="menu-title-container">
            <div class="span-title">
              menu
            </div>
            <div class="title-content">
            {{ message.menuTitle }}
            </div>
          </div-->
        <div class="user-message" [class.is-bot]="message.isBot">
            <div class="content" [class.right-align]="!message.isBot">
                <div class="header">
                    <span class="user-name">{{ message.userName }}</span>
                    <span class="time">{{ message.time }}</span>
                </div>
                <div class="message-content" [class.error]="message.isInvalidOption">
                    <mat-icon *ngIf="message.isDesambiguation" class="desambiguation-icon" aria-hidden="false"
                        aria-label="Desambiguação">psychology</mat-icon>
                    <div class="text">
                        <span *ngIf="message.menuItem" class="menu-item-index">{{ message.menuItem }}</span>
                        <app-bbcode-viewer *ngIf="message.text" [text]="message.text">
                        </app-bbcode-viewer>
                    </div>
                    <div class="media">
                        <app-multimedia-preview [handler]="message.multimediaHandler"></app-multimedia-preview>
                    </div>
                    <div *ngIf="message.sentType" class="status-container">
                        <span *ngIf="message.sentType" class="status-item" matTooltip="Enviada quando na fila">
                            <mat-icon class="mat-14">info</mat-icon>
                        </span>
                    </div>
                </div>
            </div>
            <div class="hexagon-container">
                <hexagon [hexagonHandler]="message.hexagonHandler"> </hexagon>
            </div>
        </div>
    </div>
</div>