import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { IGroupConnectionInteractionJSON} from '../../comm-interfaces/barrel-comm-interfaces';
import { ConnectionToSerializable } from './to-serializable';

export class ToInteraction extends ConnectionToSerializable {

    
    protected constructor(primaryID: TGlobalUID, participant: Participant, idSubscriptionType: TGlobalUID, idInteraction: TGlobalUID){
        super(primaryID, participant, idSubscriptionType, idInteraction);
    
    };

    public toJSON(): IGroupConnectionInteractionJSON {
        return super.toJSON()   
    };

	public static staticFactory(idConnection: TGlobalUID): ToInteraction {
	    return <ToInteraction> ConnectionToSerializable.staticFactory(idConnection);
    };
    
    
    public static factoryMessage(json: IGroupConnectionInteractionJSON,  participant: Participant): ToInteraction {
        let toInteraction: ToInteraction = <ToInteraction>ConnectionToSerializable.searchCachedInteraction(json.primaryID);
        if (!toInteraction) {
            toInteraction = new ToInteraction(json.primaryID, 
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                json.idSubscriptionType,
                json.subs.toSubject
            )
        };
        toInteraction.rehydrate(json);
        return toInteraction;
    };


};
