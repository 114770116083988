import { Injectable, OnInit } from '@angular/core';
import {
    Avatar,
    Group,
    Participant,
    Player,
    PlayerCachedInfo,
    TAvatarArray
} from '@colmeia/core/src/business/barrel-business';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { INotificationDevice } from "@colmeia/core/src/business/push-notifications";
import {
    IAvatarJSON,
    IGroupJSON,
    IParticipantJSON,
    TAvatarJSONArray
} from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { IParticipation } from '@colmeia/core/src/comm-interfaces/ds-interfaces';
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { socketConfig } from '@colmeia/core/src/core-constants/socket.conf';
import { TArrayID, TGroupArray } from '@colmeia/core/src/core-constants/types';
import { clientErrorCodes } from '@colmeia/core/src/error-control/client-error.constants';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { IApproverData } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { needToAskToParticipate, onlyInviteesCanParticipant } from '@colmeia/core/src/rules/filters';
import { isColmeiaStaff } from '@colmeia/core/src/shared-business-rules/social-network/social-network-config.functions';
import { isValidArray, isValidRef, isValidString, throwCustomFieldError } from '@colmeia/core/src/tools/utility';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { GroupSubscription } from "../model/group-subscription.model";
import { AttendanceService } from "./attendance.service";
import { IDataBuilder } from "./controllers-services/security-controller/safe-menu-builder";
import { GeolocationHandlerInteractorService } from "./geolocation-handler-interactor.service";
import { HardwareLayerService } from "./hardware";
import { InteractionPersistorServices } from "./interaction-persistor.service";
import { PlayerInfoService } from './player-info.service';
import { SnackMessageService } from "./snack-bar";
import { SocialNetworkDatabaseService } from "./social-network-database.service";
import { SusbcriptionContainerService } from "./subscriptions.service";
import { UniversalRehydrator } from "./universal-rehydrator";
/**
 * Singleton to store session information from user
 */

export interface IRefreshData {
    idSocialNetwork: string;
    idCurrentGroup: string;
    idPlayer: string;
    idAvatar: string;
}
@Injectable()
export class SessionService implements OnInit {

    private logged: boolean;
    private player: Player;
    private playerAvatars: TAvatarArray = [];
    private avatar: Avatar;
    private tribe: Group;
    private corportate: Group;

    private _currentSubscription: GroupSubscription;

    private playerSubscription = new ReplaySubject<Player>(1);
    private avatarSubject = new ReplaySubject<Avatar>(1);


    private set currentSubscription(value: GroupSubscription) {
        this._currentSubscription = value;
    }

    private get currentSubscription(): GroupSubscription {
        return this._currentSubscription;
    }

    private playerInfo: PlayerInfoService;
    private susbContainer: SusbcriptionContainerService;
    private rehydrate: UniversalRehydrator;
    private geolocationManager: GeolocationHandlerInteractorService;
    private interactionPersistorSvc: InteractionPersistorServices;
    private approverData: IApproverData;
    private CRMEmbeddedMode: boolean = false;
    private embeddedChat: boolean = false;

    constructor(
        private snackSvc: SnackMessageService,
        private hw: HardwareLayerService,
        private snDatabase: SocialNetworkDatabaseService,
        private attendance: AttendanceService
    ) {
        this.logged = false;
    };

    ngOnInit() { }

    public getSelectedAvatarPersonalGroup(): TGlobalUID {
        return this.playerInfo.getPlayerInfo().getAvatarPersonalGroupID(this.getSelectedAvatarID());
    }

    public iAmAttendingOnCurrentGroup(): boolean {
        return this.attendance.isAttendingOnGroup(this.getSelectedGroupID());
    }

    public setIsEmbeddedChat(embedded: boolean): void {
        this.embeddedChat = embedded;
    }

    public isEmbeddedChat(): boolean {
        return this.embeddedChat;
    }

    public setCRMEmbeddedMode(embedded: boolean): void {
        this.CRMEmbeddedMode = embedded;
    }

    public isCRMEmbeddedMode(): boolean {
        return this.CRMEmbeddedMode;
    }

    public setLogged(): void { this.logged = true; };
    public isLogged(): boolean { return this.logged; };

    public getApproverID(): TGlobalUID { return this.approverData.idPlayer; };
    public getAvatarApproverID(): TGlobalUID { return this.approverData.idAvatar };
    public setApproverID(approver: IApproverData): void { this.approverData = approver };
    public isApprover(): boolean { return this.approverData ? this.approverData.idPlayer === this.playerInfo.getPlayerInfo().getPlayerID() : false };

    public isReady(): boolean {
        return isValidRef(this.playerInfo);
    };

    public setDependencyRehydrator(reyhdrator: UniversalRehydrator): void { this.rehydrate = reyhdrator; };
    public setDependencySubscriptionContainer(subsContainer: SusbcriptionContainerService): void { this.susbContainer = subsContainer; };

    public setSubscription(subscription: GroupSubscription): void {
        this.currentSubscription = subscription;

        const refreshData: IRefreshData = {
            idSocialNetwork: subscription.getGroup().getMySocialNetworkID(),
            idCurrentGroup: subscription.getGroupID(),
            idPlayer: this.playerInfo.getPlayerInfo().getPlayerID(),
            idAvatar: this.getAvatarID()
        };

        this.hw.getStorage().putItem(socketConfig.client.currentSubscription, refreshData)
    };

    public getGroupFromStorage(): IRefreshData | undefined {
        const data = this.hw.getStorage().getItem<IRefreshData>(socketConfig.client.currentSubscription);
        return data;
    }

    public setDependencyGeoLocation(geolocationManager: GeolocationHandlerInteractorService): void { this.geolocationManager = geolocationManager; };
    public setDependencyInteractionPersistor(interactionPersistorSvc: InteractionPersistorServices): void { this.interactionPersistorSvc = interactionPersistorSvc; };

    public initSessionService(serviceInfo: PlayerInfoService): void {
        this.playerInfo = serviceInfo;
        this.getPlayerAvatars().some((av) => {
            const isOriginal = av.isOriginal();

            if (isOriginal)
                this.setAvatar(av);

            return isOriginal;
        });
        this.handleTrackingGPS();
    }

    async handleTrackingGPS(): Promise<void> {
        try {
            if (this.isGPSTrackingOn()) {
                await this.geolocationManager.locationTurnedOnByServer()
            }
        } catch (error) {
            this.snackSvc.openError(error)
        }
    }


    public reset() {
        this.avatar = null;
        this.player = null;
        this.logged = false;
    };

    public setPlayer(player: Player): void {
        this.player = player;
        this.avatar = null;
        this.playerSubscription.next(player);
    };

    public getPlayer(): Player {
        return this.player;
    };


    /**
     * Função usada para retornar apenas quando player tenha sido inicializado no 'setPlayer'.
     * Foi criada para impedir que os módulos acessados diretamente pela rota não quebrem a aplicação tentando usar o player antes de ser setado
     * @returns Promise
     */
    public getPlayerAsync(): Promise<Player> {
        return new Promise((resolve) => {
            if (this.player) {
                resolve(this.getPlayer());
            } else {
                this.playerSubscription.pipe(first(),).subscribe(player => {
                    resolve(player);
                });
            }
        });
    }

    /**
     * Função usada para retornar apenas quando avatar tenha sido inicializado no 'setAvatar'.
     * Mesmo motivo acima, só que para o avatar.
     * @returns Promise
     */
    public getAvatarAsync(): Promise<Avatar> {
        return new Promise((resolve) => {
            if (this.avatar) {
                resolve(this.avatar);
            } else {
                this.avatarSubject.pipe(first()).subscribe(avatar => {
                    resolve(avatar);
                });
            }
        });
    }

    public getPersonalGroup(): Group {
        return this.player.getPersonalGroup();
    };

    public getPlayerPersonalGroupParticipant(): Participant {
        return this.player.getPersonalGroupParticipant();
    };

    public getPlayerID(): TGlobalUID { return this.getPlayer().getPlayerID(); };

    public getAvatarID(): TGlobalUID { return this.getSelectedAvatar().getAvatarID(); };
    public getAvatarName(): string { return this.getSelectedAvatar().getName(); };


    public isAdminOnSN(): boolean {
        const sn: TGlobalUID = this.getSelectedGroup()?.getMySocialNetworkID();
        return this.playerInfo?.getPlayerInfo()?.isAdminGroup(sn, this.getSelectedAvatar()?.getAvatarID());
    };

    public isAdminOnGroup(idGroup: TGlobalUID): boolean {
        return this.playerInfo.getPlayerInfo().isAdminGroup(idGroup, this.getSelectedAvatar().getAvatarID());
    }

    public isAdminOnCurrentGroup(): boolean {
        return this.isAdminOnGroup(this.getSelectedGroupID());
    }

    public getAvatarFromGroup(idGroup: TGlobalUID): TGlobalUID {
        return this.playerInfo.getPlayerInfo().getAvatarGroup(idGroup);
    };

    public setAvatar(avatar: Avatar): void {
        if (avatar.isRelatedToPlayer() && avatar.getParentEntityID() != this.getPlayer().getPlayerID()) {
            throwCustomFieldError(
                clientErrorCodes.errorPrimaryID,
                clientErrorCodes.sessionSvc.setAvatar, true,
                'SessionService.setAvatar',
                'Avatar não referencia o Player correto'
            );
        };
        this.avatar = avatar;
        this.avatarSubject.next(avatar);
    };

    public getSelectedAvatar(): Avatar {
        return this.avatar;
    };

    public getSelectedAvatarID(): TGlobalUID {
        return this.avatar?.getAvatarID();
    };

    public setRootGroups(corporate: Group, tribe: Group): void {
        this.corportate = corporate;
        this.tribe = tribe;
    };

    public getTribe(): Group { return this.tribe; };
    public getCorportate(): Group { return this.corportate; };

    public getSelectedGroup(): Group {
        if (this.hasSubscription()) {
            return this.currentSubscription?.getGroup();
        }
    };

    public getSelectedGroupID(): TGlobalUID {
        return this.getSelectedGroup().getGroupID();
    };

    public getCurrentSocialNetworkID(): TGlobalUID | null {
        return this.currentSubscription?.getGroup().getMySocialNetworkID();
    }

    public getSocialNetworkIdFromGroupId(idGroup: TGlobalUID): TGlobalUID {
        const group = Group.staticFactory(idGroup)
        return group.getMySocialNetworkID()
    }

    public getGroupsFromSN(idGroup: TGlobalUID): TGroupArray {
        return this.snDatabase.getGroupsFromSN(idGroup);
    }

    public isActiveSubscription(): boolean {
        return isValidRef(this.currentSubscription);
    }

    public getParticipantJSON(idGroup: TGlobalUID): IParticipantJSON | null {
        const part: Participant = this.getParticipant(idGroup);
        return (part) ? part.toJSON() : null;
    }

    public hasParticipantInGroup(idGroup: TGlobalUID): boolean {
        return !!this.getParticipant(idGroup, true);
    }

    public checkIfParticipantExistsOnPlayerCached(idGroup: TGlobalUID): boolean {
        const groupAccess: IParticipation = this.playerInfo.getPlayerInfo().getGroupAccessMap(idGroup, true)
        return isValidString(groupAccess?.par)
    }

    public checkIfParticipantExistsOnMultipleDbs(idGroup: TGlobalUID) {
        return this.checkIfParticipantExistsOnPlayerCached(idGroup)
            || this.hasParticipantInGroup(idGroup);
    }

    public getParticipant(idGroup: TGlobalUID, dontThrow?: boolean): Participant {
        try {
            const part: Participant = this.snDatabase.getParticipant(idGroup, this.getSelectedAvatarID(), dontThrow);
            return part;
        } catch (e) {
            if (dontThrow) return undefined;

            throw e;
        }
    };

    public getPlayerInfo(): PlayerCachedInfo {
        return this.playerInfo.getPlayerInfo();
    }

    public belongsToGroup(idGroup: TGlobalUID): boolean {
        return this.playerInfo.getPlayerInfo().belongsToGroup(idGroup);
    };

    public avatarBelongsToGroup(idGroup: TGlobalUID, idAvatar: TGlobalUID): boolean {
        return this.playerInfo.getPlayerInfo().avatarBelongsToGroup(idGroup, idAvatar);
    };

    public avatarBelongsToMe(idAvatar: TGlobalUID): boolean {
        return this.playerInfo.getPlayerInfo().avatarBelongsToPlayer(idAvatar);
    };

    public getSelectedParticipant(): Participant {
        const idGroup = this.currentSubscription.getGroup().getGroupID();
        return this.getParticipant(idGroup);
    };


    public getSelectedParticipantJSON(): IParticipantJSON {
        return this.getParticipantJSON(this.currentSubscription.getGroup().getGroupID());
    };

    getAvatarPersonalParticipantID(idAvatar: TGlobalUID): TGlobalUID {
        return this.playerInfo.getPlayerInfo().getAvatarPersonalParticipantID(idAvatar);
    }

    public getPersonalGroupParticipant(idAvatar: TGlobalUID): IParticipantJSON {
        const groupID = this.playerInfo.getPlayerInfo().getAvatarPersonalGroupID(idAvatar);

        if (groupID) {
            return this.getParticipantJSON(groupID);
        }

        return null;
    }

    public getCurrentPersonalParticipant(): Participant {
        if (this.isEmbeddedChat()) return;

        const groupID = this.playerInfo.getPlayerInfo().getAvatarPersonalGroupID(this.getSelectedAvatarID());

        let participant;
        try {
            participant = this.getParticipant(groupID);
        }
        catch (err) {
            if (
                err?.idError == clientErrorCodes.pack2.serilizable &&
                err?.idField == clientErrorCodes.pack2.fields.socialNet.cantFindInDatabase
            ) {
                throwCustomFieldError(
                    clientErrorCodes.pack2.serilizable,
                    clientErrorCodes.pack2.fields.socialNet.cantFindPersonalParticipant,
                    true,
                    err.functionName,
                    `Erro ao buscar participante do grupo pessoal. avatarID: ${this.getAvatarID()}, playerId: ${this.getPlayerID()}`
                );
            } else {
                throw err;
            }
        }

        return participant;
    };

    public isGroupAccessWithAnotherAvatar(idGroup: TGlobalUID): boolean {
        const idAvatarGroup: TGlobalUID = this.playerInfo.getPlayerInfo().getAvatarGroup(idGroup, true);
        return idAvatarGroup && this.getSelectedAvatar().isNot(idAvatarGroup)
    };

    hasParticipantOnGenealogy(idGroup: TGlobalUID): boolean {
        return this.snDatabase.hasParticipantOnGenalogy(idGroup);
    }

    public hasAccessWithCurrentAvatar(idGroup: TGlobalUID): boolean {
        return this.playerInfo.getPlayerInfo().avatarBelongsToGroup(idGroup, this.getSelectedAvatar().getAvatarID());
    };

    public getPlayerInfoServices(): PlayerInfoService { return this.playerInfo };

    public isGroupInMemory(idGroup: TGlobalUID): boolean {
        return this.susbContainer.isInMemory(idGroup);
    }

    public getGroupSubscription(idGroup: TGlobalUID): GroupSubscription {
        return this.susbContainer.getGroupSubscriptionModel(idGroup);
    }

    public isOnlyForInvitees(idGroup: TGlobalUID): boolean {
        if (UberCache.testCache(idGroup)) {
            const group: Group = Group.staticFactory(idGroup);
            return onlyInviteesCanParticipant(group);
        }
        return false;
    }

    public needToAskToParticipate(idGroup: TGlobalUID): boolean {
        if (UberCache.testCache(idGroup)) {
            const group: Group = Group.staticFactory(idGroup);
            return needToAskToParticipate(group);
        }
        return false;
    }

    public getMenuSafeBuilderData(): IDataBuilder {
        if (this.currentSubscription && this.belongsToGroup(this.currentSubscription.getGroupID())) {
            return {
                currentGroup: this.getSelectedGroup(),
                currentParticipant: this.getSelectedParticipant(),
                currentAvatar: this.getSelectedAvatar(),
                playerInfo: this.playerInfo.getPlayerInfo(),
                idGroup: this.getSelectedGroupID(),
                isEmpty: false,
                sessionService: this,
            }
        } else {
            return {
                currentAvatar: (this.getSelectedAvatar()) ? this.getSelectedAvatar() : null,
                currentParticipant: null,
                playerInfo: null,
                currentGroup: null,
                idGroup: constant.entity.rootGroups.root,
                isEmpty: true,
                sessionService: this
            }
        }
    };


    public haveOneOfTheseAvatars(arrayAvatarID: TArrayID): boolean {
        for (const myAvatar of this.playerInfo.getPlayerInfo().getAvatarArrayID()) {
            if (arrayAvatarID.some((avt => { return avt == myAvatar }))) {
                return true;
            };
        };
        return false;
    };

    public willSeeTheMessageInThisGroup(idGroup: TGlobalUID, interaction: Interaction): boolean {
        const personalAvatars: TArrayID = interaction.getGroupPersonalResponse(idGroup);
        return !isValidArray(personalAvatars) || this.haveOneOfTheseAvatars(personalAvatars);
    };


    public isStrucuturesReady(): boolean {
        return this.susbContainer && this.susbContainer.isSubscriptionOperational();
    };

    /**
     * @deprecated
     */
    public trackInGroup(lastGroup: TGlobalUID, currentGroup: TGlobalUID): void {
        // const participant: Participant = this.getCurrentPersonalParticipant();
        // const inOut: InOutGroup = InOutGroup.getInOutGroup(participant, lastGroup, currentGroup);
        // this.interactionPersistorSvc.sendSocketInteraction(inOut);
    };

    public getGroupJSON(idGroup: TGlobalUID): IGroupJSON {
        const group: Group = this.snDatabase.getGroup(idGroup);
        return (group) ? group.toJSON() : null;
    };


    public isGPSTrackingOn(): boolean {
        return this.playerInfo.getPlayerInfo()
            && this.playerInfo.getPlayerInfo().isTrackingOn();
    };


    public interactionMadeByMe(interaction: IInteractionJSON): boolean {
        return this.playerInfo.interactionMadeByMe(interaction);
    }

    public isAtRootGroup(): boolean {
        return this.getSelectedGroup().getGroupID() === constant.entity.rootGroups.root;
    }

    public isDeviceActiveForNotifications(): boolean {
        const player = this.playerInfo.getPlayerInfo();

        const policy = player.getNotificationPolicy();
        const devices = player.getAllActiveDevices();

        const activePolicy: boolean = (policy && policy.sleepUntil === 0);

        const activeDevice: boolean = devices && devices.some((device: INotificationDevice) => {
            return device.deviceUID === this.hw.getDeviceUID() && device.cancelClock === 0;
        });

        return activePolicy && activeDevice;
    }

    upsertPlayerAvatar(avatar: IAvatarJSON): void {
        const avatarIndex = this.playerAvatars.findIndex(ava => ava.getPrimaryID() === avatar.primaryID);
        const avatarEntity = Avatar.factoryMessage(avatar);
        if (avatarIndex > -1) {
            this.playerAvatars[avatarIndex] = avatarEntity;
        } else {
            this.playerAvatars.push(avatarEntity);
        }
    }

    getPlayerAvatars(): TAvatarArray {
        return this.playerAvatars;
    }

    setPlayerAvatars(avatars: TAvatarJSONArray): void {
        this.playerAvatars = avatars.map(aJSON => Avatar.factoryMessage(aJSON));
    }

    public hasSubscription(): boolean {
        return isValidRef(this.currentSubscription);
    };

    public sendSocketDebug(functionName: string, info: Object): void {
        this.interactionPersistorSvc.sendDebug(functionName, info);
    };

    public isColmeiaTeam(): boolean {
        return isColmeiaStaff(this.getPlayer().getEmail());
    }

};
