import { ITransactionServer } from "../../../knowledge-base/bot-transaction/bot-transaction";
import { TReferencedObject } from "../../toolbar/config-toolbar.types";
import { MktHostNode } from "./mkt-host-node";

export class MktContentGeneratorHost extends MktHostNode {
    constructor(contentGenerator: ITransactionServer) {
        super(contentGenerator)
    }

    create(contentGenerator: ITransactionServer): MktContentGeneratorHost {
        return new MktContentGeneratorHost(contentGenerator)
    }

    isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return false
    }
}