import { TNserUID, TPostgresPrimaryKey } from "@colmeia/core/src/core-constants/types";
import { IPostgreSQLCursorRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { EJournalingRequestTypes } from "@colmeia/core/src/request-interfaces/message-types";
import { GenericRequest, GenericResponse, IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IMigrationChangeTrackingWithNames, IMigrationJournaling, IMigrationJournalingWithName, IMigrationObjectDeploy, IMigrationObjectDeployExtended, IMigrationVersionData, TDeployHistoryArray } from "@colmeia/core/src/shared-business-rules/journaling/journaling.model";
import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";

export enum EMutationType {
    create = 'create',
    edit = 'edit'
}
export interface IGetMigrationVersionFilters {
    idSocialContext: string,
    limit: number,
    cursor: {
        lastTimestamp: TISODateString
    },
}

export interface IJournalingGetVersionsRequest extends IRequest {
    data: IGetMigrationVersionFilters
}
export interface IJournalingGetVersionsResponse extends IResponse {
    versionList: IMigrationVersionData[],
    ignoredBySecurity: number
}

export interface IJournalingDeleteNonSerializablesFromVersionRequest extends IRequest {
    data: {
        idsCapturedObjects: string[];
    }
}
export interface IJournalingDeleteNonSerializablesFromVersionResponse extends IResponse {
    removedEntriesCount: number
}

export interface IJournalingTransferNonSerializablesToVersionRequest extends IRequest {
    data: {
        idVersionTarget: TPostgresPrimaryKey;
        idsChangedObjects: TPostgresPrimaryKey[];
    }
}
export interface IJournalingTransferNonSerializablesToVersionResponse extends IResponse {
    changedNonserializablesCount: number
}


export interface IJournalingVersionUpdateRequest extends IRequest {
    data: {
        operationType: EMutationType,
        version: IMigrationVersionData
    }
}
export interface IJournalingVersionUpdateResponse extends IResponse {
}


export interface IListAllNonSerializablesChangedInVersionRequest extends IRequest {
    data: {
        idVersion: TPostgresPrimaryKey
    }
}
export interface IListAllNonSerializablesChangedWithoutVersionRequest extends IRequest {
}
export interface IListAllNonSerializablesChangedInVersionResponse extends IResponse {
    changedNserList: IMigrationJournalingWithName[]
}

export interface IAddChangedJournalingsToVersionRequest extends IRequest {
}
export interface IAddChangedJournalingsToVersionResponse extends IResponse {
}

export interface IListAllNonserializablesWithoutVersionRequest extends IRequest {
    data: IPostgreSQLCursorRequest
}
export interface IListAllNonserializablesWithoutVersionResponse extends IResponse {
    changesWithoutVersion: IMigrationJournalingWithName[];
    hiddenElementsCount?: number;
}


export interface IUpdateAllJournalingToVersionIDRequest extends IRequest {
    requestType: EJournalingRequestTypes.updateAllJournalingToVersionID,
    versionID: TPostgresPrimaryKey,
    journalingIDList: TPostgresPrimaryKey[]
}

export interface IUpdateAllJournalingToVersionIDResponse extends IResponse {
}


export interface IGetAllChangesFromNonSerializableRequest extends IRequest {
    requestType: EJournalingRequestTypes.getAllChangesFromNonSerializable,
    data: {
        nserID: TNserUID;
    }
}
export interface IGetAllChangesFromNonSerializableResponse extends IResponse {
    changes: IMigrationChangeTrackingWithNames[]
}

export interface IGetObjectDeployDataRequest extends IRequest {
    requestType: EJournalingRequestTypes.getObjectDeployDataRequest,
    data: {
        nserID: TNserUID;
    }
}

export interface IGetObjectDeployDataResponse extends IResponse {
    changes: IMigrationObjectDeployExtended[]
}


export interface IGetDeployHistoryFromVersionRequest extends IRequest {
    versionID: TPostgresPrimaryKey,
}


export interface IGetDeployHistoryFromVersionResponse extends IResponse {
    deployHistory: TDeployHistoryArray
}
