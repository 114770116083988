import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ILoadingSnackBarData } from 'app/services/snack-bar';

@Component({
  selector: 'app-loading-snackbar',
  templateUrl: './loading-snackbar.component.html',
  styleUrls: ['./loading-snackbar.component.scss']
})
export class LoadingSnackbarComponent implements OnInit {

  label: string;
  constructor(@Inject(MAT_SNACK_BAR_DATA) snackData: ILoadingSnackBarData) {
    this.label = snackData.label;
  }

  ngOnInit() {
  }

}
