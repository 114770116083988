import { UserSettingsService } from './user-settings.service';
import { DatePipe } from '@angular/common';
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DatetimeConversions {

    private datePipe: DatePipe;

    constructor(private settings: UserSettingsService) {
        this.datePipe = new DatePipe(settings.getSelectedLocale());
    }

    public timeToDateTimeString(time: number): string {
        return time
            ? this.datePipe.transform(time, this.settings.getDateHourFormat())
            : '';
    }

    public timeToDateString(time: number): string {
        return time
            ? this.datePipe.transform(time, this.settings.getDateFormat())
            : '';
    }

    public dateStringToDateObject(date: string): Date {
        return (new Date(
            date.replace(
                this.settings.getDatePattern(),
                '$3-$2-$1 00:00:00'
            ))
        );
    }

}
