import { IdGroup } from "@colmeia/core/src/core-constants/named-types/named-primary-ids";
import { TGlobalUID } from "../../business/constant";
import { TArrayID } from "../../core-constants/types";
import { ECSContentComparison } from "../corporate-search/corporate-search-model";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";

export interface IConfigTagUsedDB {
    idCorporateSearchBody: IdDep<ENonSerializableObjectType.corporateSearchBody>;
    comparison: ECSContentComparison;
}

export type TIConfigTagUsedDBArray = Array<IConfigTagUsedDB>;


export enum EConfigurationSet {
    allowedDB = 'cSAllowDB',
    tagSecurity = 'cTagSec',
}

export interface IConfigurationSetHeader extends INonSerializableHeader {
    idGranteeGroup: IdGroup; // grupo contendo todos os agentes, ex:(fernando, ze)
    configType: EConfigurationSet

    tagSet: TArrayID; // se for report aparece isso
    allowCS: TIConfigTagUsedDBArray; // se for DB
}

export type TIConfigurationSetServerArray = Array<IConfigurationSetServer>;

export interface IConfigurationSetServer extends INonSerializable, IConfigurationSetHeader {
    nsType: ENonSerializableObjectType.configurationSet
}