import { Injectable } from '@angular/core';
import { NotificationSoundService } from '@colmeia/core/src/core-notification-sound/core-notification-sound-service';
import { getClock, isValidRef } from "@colmeia/core/src/tools/utility";
import { secToMS } from "@colmeia/core/src/time/time-utl";
import {HardwareLayerService} from "./hardware";
import {UserSettingsService} from "./user-settings.service";
import { BrowserAudio } from './hardware/browser/browser-audio';
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SoundsNotificationService {

    private notificationSoundSvc: NotificationSoundService;
    private lastPlayClock: number = null;
    private debouncedPlayStream$: Subject<{ path: string, debounce: number }> = new Subject();

    private audioPathMap: Map<string, HTMLAudioElement> = new Map();

    constructor(
        private userSettings: UserSettingsService,
        private hardware: HardwareLayerService
    ) {
        this.notificationSoundSvc = new NotificationSoundService();

        this.debouncedPlayStream$.pipe(debounce(e => timer(e.debounce))).subscribe((e) => {
            this.customInteractionPlay(e.path);
        });
    }

    private canPlay(): boolean {
        return ! isValidRef(this.lastPlayClock)
            || getClock() - this.lastPlayClock > secToMS(5);
    }

    public play(path: string): void {
        if (this.canPlay()) {
            this.hardware.getAudio().playSound(path);
            this.lastPlayClock = getClock();
        }
    }

    public customInteractionPlay(path: string): Promise<void> {
        return (this.hardware.getAudio() as BrowserAudio).playSound(path);
    }

    public playSoundWithDebounce(path: string, debounce: number) {
        this.debouncedPlayStream$.next({ path, debounce });
    }

    public playLoop(path: string): HTMLAudioElement {
        const audio: HTMLAudioElement = this.getAudioRef(path);
        audio.loop = true;

        try {
            audio.play();
        } catch(e) {}

        return audio;
    }

    private getAudioRef(path: string): HTMLAudioElement {
        let audio: HTMLAudioElement = this.audioPathMap.get(path);

        if(!audio) {
            audio = new Audio(path);
            this.audioPathMap.set(path, audio);
        }

        return audio;
    }

}
