import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const groupViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Groups,
    fields: {
        groups: 1, // grupos
        participants: 2, // Participantes
        posts: 3, // Posts
        reacts: 4, // Reações
        toGroup: 5, // Ao grupo
        permission: 6, // Permissão
        content: 7, // Conteúdo
        onlyAuthenticatedBy: 8, // Apenas avatares autenticados por
        secRulesLabel: 9, //Encadeamento de Segurança
        toThisGroup: 10, // Para este Grupo
        thisGroup: 11, // Deste Grupo
        invalidDomain: 12, //Domínio inválido
        intervalOf: 13, // Intervalo de
        allowedDomains: 14, // Domínios habilitados
        reCertification: 15, // Recertificação
        politics: 16 // Políticas
    }
} as const);
export const groupViewScreenOptions2 = typedScreenOptions({
    idSerializable: EAllSerializable.GroupsTwo,
    fields: {
        groupName: 1, // Nome do grupo
        groupDescription: 2, // Descrição do grupo
        about: 3, // Sobre
        members: 4, // Membros
    }
} as const);

export const groupTranslations = {
    ...getTranslationsFields(groupViewScreenOptions),
    ...getTranslationsFields(groupViewScreenOptions2),

};