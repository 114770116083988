import { IBPMValidatorAction } from "../BPM/bpm-action-model";

export interface IReverse2FA {

}

export const MinimumTokenExpireInSeconds = 90;

export enum ETokenType {
    numbersOnly = 'nbrOnly',  // Apenas números
    both = 'both', // Caracteres e Números
    characterOnly = 'charsOnly', // aprenas caracteres
    captionCharacterOnly = 'capCharsOnly' // Apenas caracteres maiúsculos 
}

export interface ITokenGenerateParameter {
    tokenType: ETokenType;
    tokenSize: number; //3 mínimo
}

export interface I2FASettings extends ITokenGenerateParameter{
    idCampaignAction: string;
    sendIfDifferentFromCurrentChannel: boolean; // Envia caso canônico seja diferente do canal atual

    // Caso seja um target que não é o do canal sendo atendido (ex: preenchido email quando se está no whatsApp)
    // é necessário indicar qual o canonico que iremos comparar o da integração.. provavelmente está no formulário sendo prenchido
    idComparingCanonical?: string;  // Canônico para comparação (opcional)

    idCampaignCanonical: string;

    expireInSeconds?: number;  // 

    idUserFunction?: string;
    action: IBPMValidatorAction;
    isReverseTFA?: boolean;
    reverse2FA?: IReverse2FA; // IGNORA POR ENQUANTO VAMOS COBRAR ISSO
}