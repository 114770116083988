import { EIdMenus } from "../colmeia-tags/id-menus";

export enum EAdHocSubMenus {
    extraction = 'extraction',
    elasticSearch = 'elasticSearch',

}

export const allAdHocSubMenusConfig: { [key in EAdHocSubMenus]: EIdMenus } = {
    [EAdHocSubMenus.extraction]: EIdMenus.OmnisenseKnowledgeBase,
    [EAdHocSubMenus.elasticSearch]: EIdMenus.IndexedSearchAi,
}


