export enum ESearchAllowedType {
    all = 'searchBar.all',
    posts = 'searchBar.posts',
    groups = 'searchBar.groups',
    people = 'searchBar.people',
    myStuff = 'searchBar.myStuff',
    fromAvatar = 'searchBar.avatar',
    callcanterAgents = 'searchBar.callcenter',
    corporateGroup = 'searchBar.corporateGroup'
}

export enum ESearchScope {
    allSocialNetwork = 'scopeSearch.allNetwork',
    fromThisSNBellow = 'scopeSearch.fromThisBellow',
    thisLevelOnly = 'scopeSearch.thisLevel',
}

export enum EAttendanceHistorySearchType {
    byAgent = 'byAgent',
    byCustomer = 'byCustomer',
    byDateInterval = 'byDateInterval',
}

export enum ECustomerSearchContactType {
    phone = 'phone',
    email = 'email',
    name = 'name',
    businessID = 'businessID'
}

export const gMaxAttendenceHistoryResultSet = 1000

//     - criar matselect com byDateInterval, byCustomer e byAgent

// byDateInterval -> aparece apenas componente de data com o botao buscar
// byCustomer -> aparece apenas campo de busca
// byAgent -> aparece componente de data e busca
//     resultados devem aparecer tabela com nome de agentes
//     ao selecionar uma das opcoes, deve aparecer tabela que ja existe hoje

//     - caso receba 400 resultados deve avisar ao usuario no frontend "favor selecionar um intervalo de data menor 
// pois existe mais dados para serem renderizados na tela"
