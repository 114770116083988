import { Component } from '@angular/core';
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { ChainedInteraction } from '@colmeia/core/src/interaction/chained-interaction';
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { constant } from "@colmeia/core/src/business/constant";
import { ChatActionBarWindowService } from 'app/services/chat-action-bar-window.service';
import { DatePickerHandler } from 'app/handlers/date-picker.handler';
import { IReplyWindowHandler } from 'app/handlers/chat-bar-interactions/reply-window-handler';
import { RootComponent } from 'app/components/foundation/root/root.component';
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";

@Component({
    selector: 'app-reply-window',
    templateUrl: './reply-window.component.html',
    styleUrls: ['./reply-window.component.scss']
})
export class ReplyWindowComponent extends RootComponent<
    'question'
> {

    public interaction: Interaction;
    public datePickerHandler: DatePickerHandler;

    constructor(
        private windowSvc: ChatActionBarWindowService
    ) {
        super({
            'question': gTranslations.fragments.replyWindow,
        });
        this.windowSvc.subscribeToCarrier((val: IReplyWindowHandler) => {
            this.interaction = val.interaction;
            this.datePickerHandler = val.datePickerHandler;
        }, constant.interactionType.standard.citation);
    }

    ngOnDestroy() {
        this.windowSvc.onWindowDestroy();
    }

    get avatarName(): string {
        return (isValidRef(this.interaction))
            ? `@${(<ChainedInteraction>this.interaction).getInteractionParent().getParticipant().getAvatar().getNickName()}`
            : '';
    }

    get replyMessage(): string {
        return (isValidRef(this.interaction))
            ? (<ChainedInteraction>this.interaction).getInteractionParent().getMessage()
            : '';
    }

    get replyTime(): string {
        if (isValidRef(this.interaction)) {
            const hours = this.interaction.getDate().getHours().toString();
            const minutes = this.interaction.getDate().getMinutes().toString();
            return `${
                hours.length === 1 ? '0' + hours : hours
                }:${
                minutes.length === 1 ? '0' + minutes : minutes
                }`;
        }
        return '';
    }
}
