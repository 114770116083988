
<div class="group-type-container">
  <img class="cover" [src]="groupTypeIcon" />
  <h5 class="g-type-title">{{ groupTypeName }}</h5>
</div>

<div class="hexagon__section">
  <app-hexagon-uploader
    [handler]="hexagonUploaderHandler"
  ></app-hexagon-uploader>
</div>

<mat-form-field>
  <mat-label>{{ translations.name }}</mat-label>
  <input matInput #gname required [maxLength]="inputSettings.name.maxLength" [(ngModel)]="_name">
  <mat-hint align="end">{{gname.value.length}} / {{ inputSettings.name.maxLength }}</mat-hint>
</mat-form-field>
<mat-form-field>
  <mat-label>{{ translations.description }}</mat-label>
  <input matInput #gdescription [maxLength]="inputSettings.description.maxLength" [(ngModel)]="_description">
  <mat-hint align="end">{{gdescription.value.length}} / {{ inputSettings.description.maxLength }}</mat-hint>
</mat-form-field>

<mat-checkbox *devFeature [(ngModel)]="heritageConfig">
  {{ translations.heritageFromFather }}
</mat-checkbox>

<mat-dialog-actions align="end">
  <button mat-button [disabled]="saving" (click)="onCancelClicked()">
    {{ translations.cancel }}
  </button>
  <button mat-flat-button [loading]="saving" [disabled]="saving" color="primary" (click)="onCreateClicked()">
    {{ translations.create }}
  </button>
</mat-dialog-actions>
