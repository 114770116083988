import { allMetadata, EMetadataNames } from "../../shared-business-rules/metadata/metadata-db";
import { IGeneralMetadata } from "../../shared-business-rules/metadata/metadata-interface";
import { IPhoneNumberTimezoneInfo, EBrazilDDDs } from "./timezone.types";
import * as phoneNumberToTimezone from 'phone-number-to-timezone'

import { gTranslations } from "../../shared-business-rules/const-text/translations";
import { throwErrorIfTrueTranslation, updateFieldSubset, values } from "../../tools/utility";
import { OmitByValueType } from "../../tools/utility-types";
import { isValidWhatsappNumber } from "@colmeia/core/src/shared-business-rules/social-cc/config-cell";

const { getLocalInfo } = phoneNumberToTimezone

export class PhoneNumber {

    private static constants: IGeneralMetadata = allMetadata[EMetadataNames.cellular];
    public sanitizedPhoneNumber: string;
    public ddd: string;
    public country: string;
    public numberIdentifier: string;
    public info: IPhoneNumberTimezoneInfo;

    constructor(private phone: string) {
        const sanitizedPhoneNumber: string = PhoneNumber.constants.sanitizer(this.phone);
        throwErrorIfTrueTranslation(!isValidWhatsappNumber(sanitizedPhoneNumber), gTranslations.errors.invalidPhoneNumber, true, 'new PhoneNumber')
        const info: IPhoneNumberTimezoneInfo = getLocalInfo(PhoneNumber.extractSanitizedWithPlusSymbol(sanitizedPhoneNumber));
        const ddd: string = PhoneNumber.extractDDD(sanitizedPhoneNumber, info);

        const state: OmitByValueType<PhoneNumber, Function> = {
            sanitizedPhoneNumber,
            ddd,
            country: PhoneNumber.extractCountry(info),
            numberIdentifier: PhoneNumber.extractNumberIdentifier(sanitizedPhoneNumber, ddd, info),
            info,
        };
        updateFieldSubset(<PhoneNumber>this, state);
    }
    
    public static isValidNumber(phone: string): boolean {
        // TROCAR IMPLEMENTACAO
        return PhoneNumber.constants.regex.test(phone);
    }

    private static extractDDD(phone: string, info: IPhoneNumberTimezoneInfo): string {
        const phoneWithoutCountryCode: string = phone.replace(info.country_info.code, '');
        if (info.country_info.name === 'Brazil') {
            return values(EBrazilDDDs).sort((a, b) => b.length - a.length).find((ddd: EBrazilDDDs) => new RegExp(`^${ddd}`).test(phoneWithoutCountryCode))
        }
        return;
    }

    private static extractCountry(info: IPhoneNumberTimezoneInfo): string {
        return info.country_info.name;
    }

    static getInfo(input: string) {
        const phoneWithoutPlus = PhoneNumber.extractSanitizedWithoutPlusSymbol(input);
        const info: IPhoneNumberTimezoneInfo = getLocalInfo(PhoneNumber.extractSanitizedWithPlusSymbol(input));
        return {
            phoneNumberWithoutCountryCode: phoneWithoutPlus.replace(info.country_info.code, ''),
            info,
        }
    }

    private static extractNumberIdentifier(phone: string, ddd: string, info: IPhoneNumberTimezoneInfo): string {
        return phone
            .replace(info.country_info.code, '')
            .replace(ddd, '')
        ;
    }

    public getInfo(): IPhoneNumberTimezoneInfo {
        return this.info;
    }

    public static extractSanitizedWithPlusSymbol(sanitizedPhoneNumber: string): string {
        return sanitizedPhoneNumber.replace(/\+?(.*)/, '+$1');
    }


    public static extractSanitizedWithoutPlusSymbol(sanitizedPhoneNumber: string): string {
        return sanitizedPhoneNumber.replace(/\+?(.*)/, '$1');
    }

}