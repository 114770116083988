import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { BBCode } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-main';
import { EBBCodeStyles } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-types';
import { IEditorVariable, removeBracket, TIEditorVariableArray, regexDoubleBrackets, getVariablesOfTemplate } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { getNormalizedValue, isEqual, isInvalidArray, isValidArray, isValidNumber, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { Editor, Extensions, JSONContent } from "@tiptap/core";
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Document from '@tiptap/extension-document';
import Italic from '@tiptap/extension-italic';
import Mention from "@tiptap/extension-mention";
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion';
import { IVarEditorTextAreaParameter } from 'app/handlers/var-editor-textarea.handler';
import { createServiceLogger } from 'app/model/client-utility';
import { ColmeiaPopover, ColmeiaPopoverHandler, ColmeiaPopoverService } from 'app/services/dashboard/colmeia-popover.service';
import type { IEditorVariableClient } from '../../var-editor/var-editor.component';
import { IVarEditorUIConfig, VarEditorTextAreaComponent, varEditorUIConfigDefault } from '../var-editor-text-area.component';
import { constant } from '@colmeia/core/src/business/constant';

function getMatchScore(query: string, target: string): number {
    query = query.toLocaleLowerCase();
    target = target.toLocaleLowerCase();

    const charsArr = target.split('');
    let charsCount = charsArr.reduce((score, char) => query.includes(char) ? score + 1 : score, 0);
    charsCount += target.includes(query) ? query.length : 0;

    return charsCount
}

const tiptapTextStyleMarkToBBTag: Record<string, EBBCodeStyles> = {
    'italic': EBBCodeStyles.Italic,
    'bold': EBBCodeStyles.Bold,
    'strike': EBBCodeStyles.Strikethrough,
    'code': EBBCodeStyles.Code,
} as const;

const spaceRegExp = /^\s$/;

@Component({
    selector: 'app-var-editor-text-area-tiptap',
    templateUrl: './var-editor-text-area-tiptap.component.html',
    styleUrls: ['./var-editor-text-area-tiptap.component.scss']
})
export class VarEditorTextAreaTiptapComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    log = createServiceLogger('VarEditor', 'yellow');

    textUpdateStep = 10;

    @Input()
    varEditorUIConfig: IVarEditorUIConfig;
    public varEditorUIConfigDefault: IVarEditorUIConfig = varEditorUIConfigDefault;

    @Input()
    parameters: IVarEditorTextAreaParameter;

    public get shouldHideEmojis() {
        return this.parameters.shouldHideEmojis;
    }

    public get shouldHideBBCode() {
        return this.parameters.shouldHideBBCode;
    }

    @Input()
    allVariables: IEditorVariableClient[] = [];

    @Input()
    rawTextCharCount: number;

    @Input()
    amoutCharacters: number;

    @Input()
    placeholder: TIEditorVariableArray = [];

    @Input()
    content: string;
    @Output()
    contentChange: EventEmitter<string> = new EventEmitter();
    
    @Input()
    limitCharacters: number;

    @ViewChild('tiptapContainer', { static: true })
    tiptapContainer: ElementRef<HTMLDivElement>;

    private tiptapInstance!: Editor;
    public fullTextLength: number = 0;
    public charactersCount: number = 0;

    private internalRawContent: string = '';

    public readonly textStyleModifiers: Record<EBBCodeStyles, {
        matIcon: string;
        tooltip: string;
        isEnabled: boolean;
        isActive: () => boolean;
        toggle: () => unknown;
    }> = {
            [EBBCodeStyles.Italic]: {
                matIcon: 'format_italic',
                tooltip: 'Itálico',
                isEnabled: true,
                isActive: () => this.tiptapInstance?.isActive('italic'),
                toggle: () => this.tiptapInstance?.chain().focus().toggleItalic().run()
            },
            [EBBCodeStyles.Bold]: {
                matIcon: 'format_bold',
                tooltip: 'Negrito',
                isEnabled: true,
                isActive: () => this.tiptapInstance?.isActive('bold'),
                toggle: () => this.tiptapInstance?.chain().focus().toggleBold().run()
            },
            [EBBCodeStyles.Strikethrough]: {
                matIcon: 'strikethrough_s',
                tooltip: 'Tachado',
                isEnabled: true,
                isActive: () => this.tiptapInstance?.isActive('strike'),
                toggle: () => this.tiptapInstance?.chain().focus().toggleStrike().run()
            },
            [EBBCodeStyles.Code]: {
                matIcon: 'code',
                tooltip: 'Código',
                isEnabled: true,
                isActive: () => this.tiptapInstance?.isActive('code'),
                toggle: () => this.tiptapInstance?.chain().focus().toggleCode().run()
            },
            [EBBCodeStyles.Monospace]: { tooltip: '', matIcon: '', isEnabled: false, isActive: () => false, toggle: () => { } },
            [EBBCodeStyles.Menu]: { tooltip: '', matIcon: '', isEnabled: false, isActive: () => false, toggle: () => { } },
        }

    textFormatSort = () => 0;

    public toggleEmojiBox() {
        this.varEditorUIConfig.showEmojiBox = !this.varEditorUIConfig.showEmojiBox;
    }

    updateTextSize(value: number): void {
        this.varEditorUIConfig.fontSize = value;
    }

    private mentionsPopoverHandler: ColmeiaPopoverHandler;

    @ViewChild('mentionsListTpl')
    private mentionsListTpl: TemplateRef<unknown>;

    public popoverVariablesList: IEditorVariableClient[] = [];

    public selectedMentionIdx: number = 0;

    private mentionsPopover: ColmeiaPopover;

    private suggestionProps: SuggestionProps;


    @ViewChild('emojibox') emojibox: ElementRef;

    constructor(
        private popoverSvc: ColmeiaPopoverService,
        private viewContainerRef: ViewContainerRef,
        private varEditorTextAreaComponent: VarEditorTextAreaComponent
    ) { }

    ngOnInit() {
        this.internalRawContent = this.content;
    }
    ngOnDestroy() {
        this.mentionsPopover?.close();
    }
    ngOnChanges(changes: SimpleChanges): void { }
    ngAfterViewInit(): void {
        this.fullTextLength = this.content.length;
        this.charactersCount = GenericSharedService.charCount(this.content);
        this.initTipTapInstance();
    }

    get maxCharsLength(): number {
        return this.limitCharacters ?? this.parameters.limitCharacters ?? constant.maxAssetTextLength;
    }

    public insertEmoji(value: string) {
        this.tiptapInstance.chain().focus().insertContent(value).run();
    }

    private initTipTapInstance() {
        const element = this.tiptapContainer.nativeElement;
        const extensions: Extensions = [
            Document,
            Text,
            Bold,
            Italic,
            Strike,
            Code,
            Paragraph,
            History,
            // CharacterCount.configure({
            //     limit: this.maxCharsLength
            // }),
            Mention.configure({
                HTMLAttributes: {
                    class: 'var-editor-variable',
                },
                renderLabel: ({ node }) => {
                    return `${node.attrs.label ?? node.attrs.id}`
                },
                suggestion: this.suggestion
            })
        ];

        this.tiptapInstance = new Editor({
            element,
            extensions,
            content: this.templateToHTML(),
            autofocus: true,
            editable: true,
            injectCSS: false,
        });

        this.tiptapInstance.on('blur', async (e) => {
            setTimeout(() => {
                this.resetSuggestions();
            }, 100)
        });

        this.tiptapInstance.on('create', e => {
            const rootContent = e.editor.getJSON();

            this.cleanupVariables(rootContent);

            if (!isEqual(e.editor.getJSON(), rootContent)) {
                this.tiptapInstance.commands.setContent(rootContent);
            }
        });

        this.tiptapInstance.on('update', e => {
            const editor = e.editor;
            const rootContent = editor.getJSON();

            this.cleanupVariables(rootContent);
            this.cleanupUnsuportedLines(rootContent);
            
            if (this.varEditorTextAreaComponent.hasTextLengthExceededLimit(this.generateAppOutput(rootContent))) {
                // reverte para o estado anterior
                editor.commands.setContent(this.templateToHTML(this.content));
                return;
            }

            const { from, to } = editor.state.selection;

            if (!isEqual(editor.getJSON(), rootContent)) {
                editor
                    .chain()
                    .focus()
                    .setContent(rootContent, false)
                    .setTextSelection({ from, to })
                    .run();
            }

            this.log('tiptapHTML', e.editor.getHTML());

            const output = this.generateAppOutput(rootContent);

            this.internalRawContent = output;

            this.fullTextLength = output.length;
            this.charactersCount = GenericSharedService.charCount(output);

            this.log({ output, whatsapp: BBCode.parseWhatsApp(output) });

            // editor
            //     .chain()
            //     .focus()
            //     .setContent(this.templateToHTML(output), false)
            //     .setTextSelection({ from, to })
            //     .run();

            this.contentChange.next(output)
        })
    }

    private cleanupUnsuportedLines(rootContent: JSONContent) {
        if (!isValidNumber(this.parameters.limitRows)) return;

        const maxLines = this.parameters.limitRows;
        const { content } = rootContent;

        if (content.length > maxLines) {
            rootContent.content = content.slice(0, maxLines);
        }

    }

    private templateToHTML(content: string = this.content): string {
        this.log({ input: content });
        const html = BBCode.parseHTML(content);
        const withVarialbes = html.replace(regexDoubleBrackets, (a) => {
            const varName = getNormalizedValue(removeBracket(a), false);

            return `<span data-type="mention" class="var-editor-variable" data-id="${a}" data-label="${varName}" contenteditable="false">${varName}</span>`;
        });
        const parseLines = withVarialbes.split('\n').map(l => `<p>${l}</p>`).join('');

        this.log({ parseLines });

        return parseLines;
    }

    private cleanupVariables(rootContent: JSONContent) {
        rootContent.content?.forEach(content => {

            switch (content.type) {
                case 'doc':
                case 'paragraph':
                    this.cleanupVariables(content);
                    break;
                case 'mention':
                    const isAllowed = this.allVariables.some(v => v.variable === content.attrs.id);

                    if (!isAllowed) {
                        content.type = 'text';
                        content.text = content.attrs.label;
                        content.marks = [{
                            type: 'strike'
                        }]
                    }
            }

        });
    }

    private generateAppOutput(rootContent: JSONContent): string {

        const generate = (rootContent: JSONContent): string => {
            let finalString: string = '';

            rootContent.content?.forEach((content, idx) => {
                switch (content.type) {
                    case 'doc':
                        return generate(content);
                    case 'paragraph':
                        finalString += '\n' + generate(content);
                        break;
                    case 'text':
                        finalString += this.applyTextFormat(content.text, content.marks);
                        break;
                    case 'mention':
                        finalString += this.applyTextFormat(content.attrs.id, content.marks);
                        break;
                }
            });

            return finalString;
        }

        const str = generate(rootContent);

        const lines = str.split('\n');

        lines.shift();

        return lines.join('\n');

    }

    private applyTextFormat(initial: string, marks?: JSONContent['marks']): string {
        if (!marks) return initial;

        for (const mark of marks) {
            const bbTag = tiptapTextStyleMarkToBBTag[mark.type];

            if (bbTag) {
                const startWithSpace = spaceRegExp.test(initial[0])
                const endsWithSpace = spaceRegExp.test(initial[initial.length - 1]);

                if (startWithSpace) {
                    initial = initial.slice(1);
                }

                if (endsWithSpace) {
                    initial = initial.slice(0, -1);
                }

                if (initial === '') {
                    return ''
                }

                initial = `[${bbTag}]${initial}[/${bbTag}]`;

                if (startWithSpace) {
                    initial = ' ' + initial;
                }

                if (endsWithSpace) {
                    initial += ' ';
                }
            }
        }

        return initial;
    }

    suggestion: Omit<SuggestionOptions, 'editor'> = {
        allowSpaces: false,
        items: ({ query }) => {
            let list = [...this.allVariables];
            if (isValidString(query)) {
                const filteredItems = list.filter((word) => {
                    const cleanOption = word.variableWithoutBrackets.toLocaleLowerCase().replaceAll(/\s/g, "");
                    if (cleanOption.includes(query.toLocaleLowerCase())) return true;
                    return false;
                })

                list = filteredItems;
            }

            list.forEach(v => (v.variableWithoutBrackets = removeBracket(v.variable)));

            this.popoverVariablesList = list;
            this.selectedMentionIdx = 0;

            return list;
        },
        render: () => {
            return {
                onStart: props => {
                    this.suggestionProps = props;
                    this.mentionsPopoverHandler = {
                        elementTrigger: props.decorationNode as HTMLElement,
                        template: this.mentionsListTpl,
                        viewContainerRef: this.viewContainerRef,
                    };

                    this.mentionsPopover = this.popoverSvc.create(this.mentionsPopoverHandler);

                    this.mentionsPopover.open(false);
                },
                onUpdate: props => {
                    this.mentionsPopover.elementTrigger = props.decorationNode as HTMLElement;
                    this.suggestionProps = props;

                    if (isInvalidArray(props.items) && this.mentionsPopover?.isVisible)
                        this.resetSuggestions();

                    else if (isValidArray(props.items) && !this.mentionsPopover?.isVisible)
                        this.mentionsPopover?.open(false);
                },
                onKeyDown: props => {
                    const response = this.variablesMentionsKeyHandlers[props.event.key]?.();

                    return response ?? false;
                },
                onExit: props => {
                    this.mentionsPopover?.close();
                }
            }
        }
    }

    private variablesMentionsKeyHandlers = {
        'ArrowUp': () => {
            let next: number = this.selectedMentionIdx - 1;

            if (next === - 1) {
                next = this.popoverVariablesList.length - 1
            }

            this.selectedMentionIdx = next;
            return true;

        },
        'ArrowDown': () => {
            let next: number = this.selectedMentionIdx + 1;

            if (next > this.popoverVariablesList.length - 1) {
                next = 0;
            }

            this.selectedMentionIdx = next;

            return true;
        },
        'Enter': () => {
            const item = this.popoverVariablesList[this.selectedMentionIdx];

            this.selectVariable(item);

            return true;
        },
        'Esc': () => {
            this.resetSuggestions();
            return false;
        },
        'Escape': () => {
            this.resetSuggestions();
            return false;
        }
    }

    private async resetSuggestions() {
        await this.mentionsPopover?.close();
        this.selectedMentionIdx = 0;
        this.popoverVariablesList = [];
    }

    public selectVariable(item: IEditorVariableClient) {
        this.suggestionProps.command({ id: item.variable, label: item.variableWithoutBrackets });
    }

    public hoverSuggestion(idx: number) {
        this.selectedMentionIdx = idx;
    }

    insertVariable(variable: IEditorVariable) {
        this.tiptapInstance.chain().focus().insertContent({
            type: 'mention',
            attrs: { id: variable.variable, label: removeBracket(variable.variable) },
        }).run();
    }

    handleTextStyleClick($event: PointerEvent, bbKey: EBBCodeStyles) {
        const config = this.textStyleModifiers[bbKey];
        config.toggle();
    }

    isActiveTextStyleActive(bbKey: EBBCodeStyles): boolean {
        const config = this.textStyleModifiers[bbKey];
        return config.isActive();
    }

    get internalRawGetSet(): string {
        return this.internalRawContent;
    }

    set internalRawGetSet(value: string) {
        this.internalRawContent = value;

        const html = this.templateToHTML(value);

        this.tiptapInstance.commands.setContent(html, false, { from: 0 });
        this.contentChange.next(value);
    }

    toggleViewRaw(): void {
        this.varEditorUIConfig.viewRawCode = !this.varEditorUIConfig.viewRawCode;
    }

    shouldShowCanonicalAlert(): boolean {
        return this.varEditorTextAreaComponent.shouldShowCanonicalAlert();
    }
}
