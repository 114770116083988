import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as noUiSlider from 'nouislider';
import { Options } from "nouislider";
import { isValidFunction, isValidRef } from '@colmeia/core/src/tools/barrel-tools';

export interface ISliderHandler {
    options: Options,
    disable?: () => boolean
}

@Component({
    selector: 'app-colmeia-slider',
    //   standalone: true,
    //   imports: [SharedModule],
    templateUrl: './colmeia-slider.component.html',
    styleUrls: ['./colmeia-slider.component.scss']
})
export class ColmeiaSliderComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() handler: ISliderHandler;
    @ViewChild('nouisliderContainer', { static: false }) slider: ElementRef;
    @Output() change = new EventEmitter();
    private noUiSliderInstance: noUiSlider.API;

    constructor() { }

    ngOnInit(): void {
        // console.log({handler:this.handler})
    }

    ngAfterViewInit(): void {
        this.updateSlider();
        // console.log('After view init',{handler:this.handler})

    }

    ngOnDestroy(): void {
    }

    updateSlider() {
        if (isValidRef(this.noUiSliderInstance)) this.noUiSliderInstance.destroy();

        this.noUiSliderInstance = noUiSlider.create(this.slider.nativeElement, this.handler.options);
        // console.log({
        //     slider: this.slider,
        //     nativeNoui: this.slider?.nativeElement?.noUiSlider,
        //     handler: this.handler
        // })
        this.noUiSliderInstance.on('update', (
            values: (number | string)[],
            handleNumber: number,
            unencoded: number[],
            tap: boolean,
            locations: number[],
            slider: noUiSlider.API
        ) => {
            // console.log({
            //     values,
            //     handleNumber,
            //     unencoded,
            //     tap,
            //     locations,
            //     slider
            // })
            this.change.emit(values);
        })
        if (isValidFunction(this.handler.disable)) {
            if (this.handler.disable()) {
                this.noUiSliderInstance.disable();
            } else {
                this.noUiSliderInstance.enable();
            }

        }
    }

    set([minLimit, maxLimit]: [number | string, number | string]) {
        this.noUiSliderInstance?.set([minLimit, maxLimit]);
    }
}
