import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../../all-serializables";

const views = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.DashboardView,
    fields: {
        selectingSchemaFiles: 1, // hiveScreen.dashboardSendCampaignEdit 19
        sendName: 2, // hiveScreen.dashboardSendCampaignEdit 23
        selectAnAction: 3, // hiveScreen.dashboardSendCampaignEdit 24
        required: 4, // hiveScreen.dashboardSendCampaignEdit 25
        campaigns: 5, // SNManagement/campaigns 1
        addCampaign: 6, // SNManagement/campaigns 19
        Error: 7, // SNManagement/campaigns 23
        noItens: 8, // SNManagement/campaigns 24
    },
});
export const dashboardViewsScreenOptions = views.screenOptions;
export const dashboardViewsTranslations = views.translations;

