
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { IWhatsappBusinessAccountServer } from "../../../waba/waba.model";
import { eventNoWabaIdError, nserInsertNameError, nserSmallNameError } from "../error-messages";
import { minLength, nserNameMinLength } from "../helpers";
import { TNserValidator } from "../validate-nser";


//@ts-ignore
export const wabaNserValidator: TNserValidator = (nser: IWhatsappBusinessAccountServer, friendly: FriendlyMessage): FriendlyMessage => {
  if (!nser.nName) {
    friendly.addReturnResponse(nserInsertNameError);
  } else if (!minLength(nser.nName, nserNameMinLength)) {
    friendly.addReturnResponse(nserSmallNameError);
  }

  if (!nser.wabaId) {
    friendly.addReturnResponse(eventNoWabaIdError)
  }

  return friendly
}