import { IdMediaType } from '@colmeia/core/src/core-constants/named-types/named-primary-ids';
import { Sizes } from '@colmeia/core/src/rules/mm-functions';
import { TMimeType } from '../shared-business-rules/social-media/social-media.functions';
import { IdMedia, IdSN, SHA256Hash, TGlobalUID, URLExternalMedia } from './types';

export interface I360MediaMetadata {
    isThumb?: boolean;
    isVoiceMessage?: boolean;
    isExternalMedia?: boolean;
}

export type TIdMedia = IdMedia | URLExternalMedia;
export interface I360Media extends I360MediaMetadata {
    mymeType: TMimeType;
    idMedia: IdMedia | URLExternalMedia;
    idMediaType: IdMediaType;
    size: Sizes.Bytes;
    displayMediaName?: string;
    ocrContent?: Array<string>
}

export interface I360CustomMedia<Id extends (IdMedia | URLExternalMedia)> extends I360Media {
    idMedia: Id;
}

export type T360MediaArray = Array<I360Media>;

export interface ITextFormulary {
    [property: string]: string
}

export type TFormDisplayArray = Array<IBotFormularyDisplay>;

export type IBotFormularyDisplayArray = { [primaryID: string]: IBotFormularyDisplay };

export interface IBotFormularyDisplay {
    clockTick?: number;
    name: string;
    form: ITextFormulary;
};

export enum EVoiceOption {
    showVoiceOlny = 'voiceOnly',  // Apenas voz processada
    originalContent = 'textOnly',  // Conteúdo original
    both = 'both' // Ambos
}

export interface IConfigVoiceGenerator {
    type: EVoiceOption;
    removeEmojiFromTranscription: boolean;
    idFunction?: string; // Funcao de Text to Voice Customizada
}

export enum EBotServiceStatus {
    upAndRunning = 'upAndRunning',
    stoppedCompany = 'stoppedCompany',
    stoppedService = 'stoppedService'
}

export function isBotServiceStatusPaused(status: EBotServiceStatus): boolean {
    return status === EBotServiceStatus.stoppedCompany || status === EBotServiceStatus.stoppedService
}

export function isBotServiceStatusRunning(status: EBotServiceStatus): boolean {
    return status === EBotServiceStatus.upAndRunning || status == null
}
