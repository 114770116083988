import { Serializable } from "@colmeia/core/src/business/serializable";
import { NamedString } from "@colmeia/core/src/core-constants/types";
import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import type { metaTranslationsFields } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { notImplementedSharedFunction } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services.basic-functions";
import { ITranslationConfig, ITranslationConfigWithMeta } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { getFnName } from "@colmeia/core/src/tools/get-fn-name";
import { AddStaticClassNameOnStack, assign, isValidRef } from "@colmeia/core/src/tools/utility";
import { DeepMap } from "@colmeia/core/src/tools/utility-types";
import { StaticImplement } from "@colmeia/core/src/tools/utility/functions/StaticImplement";
import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { isNullishCoalesce } from "typescript";
import { EColmeiaEnvironment } from "../../production-deploy/prod-deploy-model";
const { default: GraphemeSplitter }: typeof import('graphemer') = require('graphemer');
const splitter = new GraphemeSplitter();

export type MetaFields = NamedString<'MetaField'>;
export type TemplateFields = NamedString<'TemplateField'>;

export namespace GenericSharedService {
    export interface ThrowInputOptions<Message extends BasicThrowInput = BasicThrowInput> {
        isWarning?: boolean;
        isBusiness?: boolean;
        title?: Message;
        message: Message;
        fnName?: string;
    }

    export type BasicThrowInput = string | ITranslationConfig;
    export type ThrowInput = BasicThrowInput | ThrowInputOptions;

    export interface EnvironmentConfig {
        environmentName: EColmeiaEnvironment;
        isLocalServer: boolean;
        version: string;
    }
    export type TService = Service;

    export interface Core {
        isServer: boolean;
        metaTranslationsFields: typeof metaTranslationsFields;
        throw(input: GenericSharedService.ThrowInput): never;
    }

}

class Service {
    
    remoteThrow = (input: GenericSharedService.ThrowInput): never => {
        notImplementedSharedFunction(input);
    }
    fetchEnvironment(): Promise<GenericSharedService.EnvironmentConfig> {
        notImplementedSharedFunction({});
    }
    
    getAPIHost(): Promise<string> {
        notImplementedSharedFunction({});
    }

}

interface FindMetaTemplateTranslationFieldByTextOutput {
    item: ITranslationConfigWithMeta;
    field: MetaTranslation.TemplateField;
    text: string;
}
@StaticImplement<GenericSharedService.Core>()
@ToStatic
@AddStaticClassNameOnStack
export class GenericSharedService extends toStatic(Service) {
    static isServer: boolean = false;
    static metaTranslationsFields: typeof metaTranslationsFields;
    
    public static environmentName: EColmeiaEnvironment;
    public static isLocalServer: boolean;
    public static version: string;

    private static config: GenericSharedService.EnvironmentConfig;
    static mapTemplateFields: DeepMap<[text: string, item: ITranslationConfigWithMeta]>;
    static mapTemplateTexts: DeepMap<[template: string, item: ITranslationConfigWithMeta]>;
    
    static async init() {
        GenericSharedService.config = await GenericSharedService.fetchEnvironment();
        assign(GenericSharedService, GenericSharedService.config);
        GenericSharedService.initMapTemplateFieldsIfNecessary();
    }

    /**
     * Real char counter
     * 👩‍❤️‍💋‍👨 // length: 11, count: 1
     * 😃 // length: 2, count: 1
     * 👩‍❤️‍💋‍👨😃 // length: 13, count: 2
     * 
     * 👩‍🦰 // length: 5, count: 1
     */
    static charCount(input: string | undefined): number {
        if (!input) return 0;
        return splitter.countGraphemes(input);
    }

    static getEnvironment() {
        return GenericSharedService.environmentName;
    }

    static show(input: GenericSharedService.ThrowInput) {
        try {
            GenericSharedService.throw(input);
        } catch {}
    }
    
    static throw(input: GenericSharedService.ThrowInput): never {
        notImplementedSharedFunction(input);
    }

    static findMetaTemplateTranslationByText(input: string): string | undefined {
        
        if (GenericSharedService.mapTemplateTexts.has(input)) {
            return GenericSharedService.mapTemplateTexts.get(input)?.meta.text;
        }
        for (const [text, item] of GenericSharedService.mapTemplateFields) {
            const output = item.meta.translate(input)
            if (output) return output;
        }
    }

    static findMetaTemplateTranslationFieldByText(input: string): FindMetaTemplateTranslationFieldByTextOutput | undefined {
        
        if (GenericSharedService.mapTemplateTexts.has(input)) {
            const item = GenericSharedService.mapTemplateTexts.get(input)!
            if (!item.meta.isTemplate()) return;
            return { item, field: item.meta, text: GenericSharedService.mapTemplateTexts.get(input)?.meta.text! };
        }

        for (const [text, item] of GenericSharedService.mapTemplateFields) {
            const output = item.meta.translate(input)
            if (!item.meta?.isTemplate()) continue;
            if (output) return { item, field: item.meta, text: output }
        }
    }

    static compressTemplateTranslation(input: string) {
        const out = GenericSharedService.findMetaTemplateTranslationFieldByText(input);
        if (!out) return;
        const text = [out.item?.serializableId, out.item?.idField, JSON.stringify(out.field?.groups(input)) ?? ''].join('|');
        return text;
    }

    static decompressTemplateTranslation(input: string) {
        // 
    }

    static throwErrorTextAndFindTranslation(message: string, fnName: string = getFnName(2)): never {
        const foundField = GenericSharedService.findMetaTemplateTranslationFieldByText(message);
        const text = foundField?.text ?? message;
        GenericSharedService.throw({
            message: text,
            isWarning: isValidRef<boolean>(foundField?.field?.config.isWarning) ? foundField?.field?.config.isWarning : true,
            fnName,
        })
        // 
    }

    static initMapTemplateFieldsIfNecessary() {
        if (GenericSharedService.mapTemplateFields) return GenericSharedService.mapTemplateFields;

        // const missing = new Set<string>(metaFieldTexts);

        GenericSharedService.mapTemplateFields = new Map();
        GenericSharedService.mapTemplateTexts = new Map();
        for (const [text, item] of GenericSharedService.metaTranslationsFields) {
            // missing.delete(item.meta.text);
            if (!item.meta?.isTemplate()) continue;
            if (!item.meta.hasGroups) GenericSharedService.mapTemplateTexts.set(item.meta.template, item);
            GenericSharedService.mapTemplateFields.set(text, item);
        }
    }

    static translateMetaField(input: MetaFields): string {
        const config: ITranslationConfig = GenericSharedService.metaTranslationsFields.get(input)!
        const text = Serializable.getTranslation(config, false) ?? input;
        return text;
    }

    static throwByMetaField(input: MetaFields): never {
        const text = GenericSharedService.translateMetaField(input)
        GenericSharedService.throw(text);
    }
    
    
}


