import { Injectable } from "@angular/core";
import { AbstractHttpService } from "./abstract-http-service";
import { GenericNonSerializableService } from "./generic-ns.service";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { EProdDeply, apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IInfraParameters, ClientInfraResponse } from "../model/client-infra-comm";
import { ServerCommunicationService } from "./server-communication.service";
import { RequestBuilderServices } from "./request-builder.services";
import { GlobalWarningService } from "./global-warning.service";
import { ScreenSpinnerService } from "./screen-spinner.service";
import { SessionService } from "./session.service";
import { RoutingService } from "./routing.service";
import {
    IPatchDeployModel,
    IPatchItemServer,
    IProdPatchModelServer
} from "@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model";
import {
    IBatchSaveToDeployModelRequest, IPromoteToServerRequest,
    ISavePatchRequest,
    EBatchSaveType,
    IBatchSaveToDeployNser
} from "@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-req-res";
import { LookupService } from "./lookup.service";
import { IListNonSerializablesRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";

@Injectable({
    providedIn: 'root'
})
export class ProdDeployService extends AbstractHttpService {

    private genericNS: GenericNonSerializableService;

    constructor(
        protected http: ServerCommunicationService,
        protected rbs: RequestBuilderServices,
        protected warning: GlobalWarningService,
        protected spinner: ScreenSpinnerService,
        private routeSVC: RoutingService,
        private session: SessionService,
        private lookup: LookupService
    ) {
        super(http, rbs, warning, spinner);
        this.genericNS = new GenericNonSerializableService(null, null, {
            api: http,
            rbs,
            routeSvc: routeSVC,
            session
        })
    }

    async getDeploy(idDeploy: string): Promise<IProdPatchModelServer> {
        const deploys = await this.lookup.getBatchNonSerializables<IProdPatchModelServer[]>([idDeploy]);
        return deploys[0];
    }

    async getDeploys(): Promise<IProdPatchModelServer[]> {
        return this.genericNS.getChildren<IProdPatchModelServer>(null, ENonSerializableObjectType.patchHeader);
    }

    fetchItemsForRequestId(id: string): IListNonSerializablesRequest {
        return this.genericNS.getChildrenRequest<IPatchItemServer>(
            id,
            ENonSerializableObjectType.patchItem,
        );
    }

    getDeploysRequest(): IListNonSerializablesRequest {
        return this.genericNS.getChildrenRequest<IProdPatchModelServer>(null, ENonSerializableObjectType.patchHeader, null, undefined, apiRequestType.dashboardExtra.env.patches);
    }

    async createDeploy(deploy: IPatchDeployModel): Promise<any> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();
        const request: ISavePatchRequest = {
            ...this.rbs.secureBasicRequest(EProdDeply.savePatchHeader),
            deploy
        };
        await this.http.managedRequest(infra, request);
    }

    async addNSerializablesToDeploy(idPatch: string, nonSerializablesToBeDeployed: IBatchSaveToDeployNser[], saveType: EBatchSaveType): Promise<any> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();
        const request: IBatchSaveToDeployModelRequest = {
            ...this.rbs.secureBasicRequest(EProdDeply.savePatchBatch),
            saveToBatchType: saveType,
            nonSerializablesToBeDeployed,
            idPatch
        };
        const res = await this.http.managedRequest(infra, request);
        return res?.response?.idJob;
    }

    async submitPatchToDeploy(idPatch: string, idProvider: string, description: string, name: string): Promise<ClientInfraResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();
        const request: IPromoteToServerRequest = {
            ...this.rbs.secureBasicRequest(EProdDeply.promote),
            deployData: {
                idPatch,
                idProvider,
                newDeployDescription: {
                    name,
                    description
                }
            }
        };

        return await this.http.managedRequest(infra, request);
    }

}
