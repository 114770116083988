import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IResponse, ISignUpResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { ERateAction } from "@colmeia/core/src/shared-business-rules/rate-limit/rate-limit.interface";
import { getClock, isValidNumber, isValidRef } from "@colmeia/core/src/tools/utility";
import { RateLimitBlockComponent } from "app/components/rate-limit-block-dialog/rate-limit-block.component";
import { createServiceLogger } from "app/model/client-utility";
import { clientConstants } from "app/model/constants/client.constants";
import { Observable, of, Subject, timer } from "rxjs";
import { take } from "rxjs/operators";
import { HardwareLayerService } from "./hardware";

interface IRateLimitState {
    expireAt: number;
    requestType?: string;
}

const storageKey = clientConstants.storage.commonKeys.rateLimitBlockExpiration;

@Injectable()
export class RateLimitClientService {
    log = createServiceLogger('RateLimitClientService', '#ff5900');

    private rateLimitRequestTypeControl: Set<string> = new Set();
    private shownMessage: boolean = false;
    private blockedSubject: Subject<IRateLimitState> = new Subject();
    public get blocked$(): Observable<IRateLimitState> {
        return this.blockedSubject.asObservable();
    }

    private currentDialogRef!: MatDialogRef<RateLimitBlockComponent>;

    constructor(
        private hardwareSvc: HardwareLayerService,
        private dialogSvc: MatDialog,
    ) {
    }

    isBlocked(requestType: string): boolean {
        const isBlocked = this.rateLimitRequestTypeControl.has(requestType);

        isBlocked && this.log('Blocked for ', requestType);

        return isBlocked;
    }

    /**
     * Checagem feita após o login
     */
    afterLoginCheck(loginResponse: ISignUpResponse) {
        const now = getClock();
        const hasValidCurrentBlockedUntil: boolean =
            isValidNumber(loginResponse.rateLimitBlockUntil) &&
            loginResponse.rateLimitBlockUntil > now;

        if (hasValidCurrentBlockedUntil) {
            this.log('Got blocking state from login response, expire at: ', new Date(loginResponse.rateLimitBlockUntil));
            this.handleInitialCheck({ expireAt: loginResponse.rateLimitBlockUntil })
            return;
        }

        const fromStorage = this.getStorageItem();

        if (isValidRef(fromStorage)) {
            this.log('Found blocking state on storage', fromStorage);
            this.handleInitialCheck(fromStorage);
        }
    }

    private handleInitialCheck(data: IRateLimitState) {
        const now = getClock();
        const { expireAt, requestType } = data;
        const isBlockExpired: boolean = expireAt < now;

        if (isBlockExpired) {
            this.removeBlockingStateFromStorage(data);
            return;
        }

        this.rateLimitRequestTypeControl.add(requestType);
        this.blockUI(data);
    }

    private getStorageItem(): IRateLimitState | null {
        return this.hardwareSvc.getStorage().getItem<IRateLimitState>(storageKey);
    }

    private saveBlockingStateOnStorage(requestType: string, expireAt: number) {
        this.hardwareSvc.getStorage().putItem(storageKey, <IRateLimitState>{ expireAt, requestType: requestType });
    }

    private removeBlockingStateFromStorage(data: IRateLimitState) {
        this.log('Blocking state removed from storage');

        if (data.requestType) {
            this.rateLimitRequestTypeControl.delete(data.requestType);
        } else {
            this.rateLimitRequestTypeControl.clear();
        }

        this.hardwareSvc.getStorage().clearItem(storageKey);
    }

    public handleResponse(requestType: string, response: IResponse): boolean {
        if (!isValidRef(response.rateLimitBlockAction)) return false;
        if (response.rateLimitBlockAction === ERateAction.ok) return false;

        this.rateLimitRequestTypeControl.add(requestType);

        const expireAt = response.rateLimitBlockUntil;

        this.log('Got block for ', requestType, ' Expire at ', new Date(expireAt));

        this.saveBlockingStateOnStorage(requestType, expireAt);
        this.blockUI({ requestType, expireAt });

        this.blockedSubject.next({ expireAt, requestType });
        return true;
    }

    private blockUI(data: IRateLimitState) {
        this.showRateLimitBlockingMessage(data.expireAt);
        this.registryUIReleaseTimeout(data);
    }

    private registryUIReleaseTimeout(data: IRateLimitState) {
        timer(new Date(data.expireAt)).subscribe(() => {
            this.log('UI Released ', { requestType: data.requestType || 'Not-available-localstorage-cleared' });

            this.removeBlockingStateFromStorage(data);
            this.currentDialogRef?.close();
        });
    }

    getDialog() {
        return document.querySelector('app-rate-limit-clock-component');
    }

    public showRateLimitBlockingMessage(expireAt?: number) {
        if (this.getDialog()) {
            return;
        }

        this.currentDialogRef = this.dialogSvc.open(RateLimitBlockComponent, {
            panelClass: 'small-size',
            disableClose: true,
            minWidth: '30vw',
            minHeight: '30vh',
            data: {
                expireAt
            }
        });

        this.currentDialogRef.afterClosed().pipe(take(1)).subscribe(() => {
            this.currentDialogRef = undefined;
        });
    }
}
