import { MainSignal } from './main-signal'
import { observableList } from './signal-constants';
import {Serializable} from "@colmeia/core/src/business/serializable";
import {TGlobalUID} from "@colmeia/core/src/business/constant";

export type TShareMessageArray = Array<NShareSignal.IShareMessage>

export namespace NShareSignal {
    export interface IShareMessage {
        resource: Serializable,
        currentGroupID: TGlobalUID
    }
    export class ShareSignal extends MainSignal {
        constructor(msg: IShareMessage) {
            super(observableList.share.message, true, msg)
        }
    }
}
