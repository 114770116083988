import {EHardwareUpdateDeviceType, IUpdaterHardwareLayer} from "../hardware-interfaces";
import {Injectable} from "@angular/core";
import {SignalPublisherService} from "../../signal/signal-publisher";
import {IVersionWarning} from "@colmeia/core/src/core-constants/version-control";
import {IInterfaceInfoSignal, InterfaceInfoSignal} from "../../../model/signal/interface-signal";
import {InteractionPersistorServices} from "../../interaction-persistor.service";
import {UpdatedDialogComponent} from "../../../components/dialogs/updated-dialog/updated-dialog.component";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";

export interface IUpdatedDialogData {
    platform: EHardwareUpdateDeviceType;
}

@Injectable({
    providedIn: "root"
})
export abstract class BaseUpdater implements IUpdaterHardwareLayer{
    private snackRef: MatSnackBarRef<UpdatedDialogComponent>;
    private _restartPending: boolean = false;
    protected persistor: InteractionPersistorServices;

    constructor(
        private snackSvc: MatSnackBar,
        private emissor: SignalPublisherService
    ) {}

    setInteractionPersistor(int: InteractionPersistorServices): void {
        this.persistor = int;
    }

    isRestartPending(): boolean {
        return this._restartPending;
    }

    abstract update(warn: IVersionWarning): Promise<void>;

    protected showUpdatedDialog(platform: EHardwareUpdateDeviceType): void {
        if(this.snackRef) {
            this.blink(platform);
            return;
        }

        this.openSnackBar(platform);

        this._restartPending = true;
        const int: IInterfaceInfoSignal = {
            updated: true
        };
        const signal = new InterfaceInfoSignal(int);
        this.emissor.specificSignalEmissorOnGenericStream(signal)
    }

    private blink(platform: EHardwareUpdateDeviceType) {
        this.snackRef.dismiss();
        this.openSnackBar(platform);
    }

    private openSnackBar(platform: EHardwareUpdateDeviceType) {
        this.snackRef = this.snackSvc.openFromComponent(UpdatedDialogComponent, {
            data: {
                platform
            }
        } as MatSnackBarConfig<IUpdatedDialogData>);
    }
}
