import { Injectable } from "@angular/core";
import { SNConfigService } from "app/components/dashboard/social-network-config/sn-config.service";
import { AppThemeService } from 'app/services/app-theme.service';


@Injectable()
export class ThemeIntializer {

    constructor(
        private snConfigSvc: SNConfigService,
        private appThemeSvc: AppThemeService
    ) { }

    initialize() {
        try {
            this.snConfigSvc.loadAccess().then(() => {
                this.appThemeSvc.init();
            });
        } catch (e) {
            console.error('Theme Initialize Error', e);
        }
    }
}
