import { TFunction } from "./utility-types";
import { resetPattern } from "./utility/functions/resetPattern";

    
//     return {
//         forbiddenNames,
//         defaultContextName,
//         objectContextName
//     };
// }

function getStackGroupItemPattern(): RegExp {
    return / *at (?<name>(?:(?:(?<contextName>[A-Z][a-z]+)\.)?(?:(?=\w)[^ /]+))) /g
}
const stackGroupItemPattern = / *at (?<name>(?:(?:(?<contextName>[A-Z][a-z]+)\.)?(?:(?=\w)[^ /]+))) /g
// // ???
// export function isProxy(source: unknown): boolean {
//     const error = getError.apply(source)
//     const { contextName } = error.stack.matchAll(getStackGroupItemPattern()).next().value.groups
//     const proxyName = 'Proxy'
//     return (contextName === proxyName) && (source.constructor.name !== proxyName);

//     function getError() { return new Error() }
// }


const defaultContextName = getFnName.constructor.name as 'Function';
const objectContextName = ({}).constructor.name as 'Object';
const forbiddenNames: string[] = [
    defaultContextName,
    objectContextName
];

const forbiddenNamesCollection = new Set(forbiddenNames);
// 

export function getFnName(fn: TFunction): string;
export function getFnName<Context extends { name: string }>(this: Context, level?: number, inputContext?: Context | object, ): `${Context['name']}.${string}`
export function getFnName<Context extends { name: string }>(this: unknown, level?: number, inputContext?: Context | object, ): string
export function getFnName<Context extends { name: string }>(this: unknown, input?: TFunction | number, inputContext?: Context | object, ) {
    if (typeof input === 'function') return (input as TFunction & { fnName: string })['fnName'];
    const level: number = input ?? 1;

    const context = (inputContext ?? this) as Context;
    
    const { stack: text } = new Error();
    if (!text) return;

    const sourceContextName = getSourceContextName();

    const { name, contextName } = getGroups(text)

    if (!name) return;

    if (contextName && sourceContextName && !forbiddenNamesCollection.has(sourceContextName)) {
        return name.replace(`${defaultContextName}.`, `${sourceContextName}.`)
    }
    
    return name;

    function getSourceContextName() {
        if (!context) return;
        const sourceContextName = context?.name ?? context.constructor.name;
        return sourceContextName;
    }

    function getGroups(text: string) {
        resetPattern(stackGroupItemPattern);
        const groups = [...text.matchAll(stackGroupItemPattern)].slice(level)
            .find(item => !item.groups?.contextName || (item.groups?.contextName) !== objectContextName)?.groups
        ;
        const { name, contextName } = groups ?? {}
        return { name, contextName }
    }
}
