<div class="privacy-policy_main">
  <div class="privacy-policy_content">

    <button 
      mat-icon-button 
      onclick="history.back()"
      class="privacy-policy-back-button"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>

    <img class="privacy-policy_colmeia--logo" [src]="'assets/img/logo/logo-324x54-white.png'" alt="colmeia logo" />

    <div class="privacy-policy_instructions">
      <h1 class="privacy-policy_instructions__title">
        Política de Privacidade Colmeia.
      </h1>
        
      <p>
        Esta Política de Privacidade tem como objetivo notificar quais informações a Colmeia pode coletar sobre o sobre o Usuário (“Informações”) e como a Colmeia pode utilizá-las.
      </p>
    
      <h2>
        <b>(a) Informações fornecidas pelo Usuário</b>
      </h2>
      
      <p>
        A Colmeia, por intermédio do Aplicativo, ou de seus funcionários, pode obter informações pessoais que o identifique o Usuário diretamente, ou que possam ser usadas em combinação com outras informações para identificá-lo. Exemplos de informações pessoais que o identificam diretamente incluem, seu nome, informações de contato, endereço de e-mail e outras informações que em combinação podem identificar o Usuário (“Informações Pessoais”).
        <br><br>
        Ao criar a conta, o Usuário fornecerá as seguintes informações pessoais para a Colmeia: Nome completo, endereço, número de telefone, CPF, data de nascimento, e-mail e outras informações semelhantes. Estas informações serão coletadas e armazenadas para que a Colmeia possa identificar e se comunicar com cada Usuário quando necessário.
        <br><br>
        Certas funções e áreas de nosso Aplicativo permitem que você compartilhe informações pessoais adicionais, tais como localização, grupos de interesse, preferências ou região. Estas informações são disponibilizadas para as empresas detentoras de cada rede social privada a que o Usuário pertence. Podemos usar as informações fornecidas para enviar suas informações solicitadas, responder a suas dúvidas, melhorar nosso Aplicativo, verificar seu relacionamento conosco e tomar outras ações que possam ser necessárias para responder a sua solicitação.
        <br><br>
        Para garantir a segurança e a reputação dos usuários cadastrados na rede da Contratante, a Colmeia habilita um serviço de autenticação em outras redes sociais como Facebook, Linkedin e Twiter. No momento que essa autenticação ocorre e após autorização do usuário, informações do perfil dos usuários são enviados aos servidores Colmeia. A Colmeia poderá criar algoritmos sobre esses perfis para ajudar a empresa detentora da rede social a ter mais assertividade no controle de autenticidade e reputação de seus usuários.
        <br><br>
        A Colmeia poderá prover ainda serviços que enviam mensagens a determinados perfis baseados em redes sociais e na Colmeia.
      </p>

      <h2>
        <b>(b) Conteúdo das mensagens</b>
      </h2>
    
      <p>
        Para a Colmeia, toda a mensagem é um documento armazenado de forma permanente. Isso é feito para garantir que, em plataformas corporativas, as mensagens tenham o poder de manter o que foi combinado e que nenhuma das partes possa repudiar ou negar o que foi previamente acertado.
        <br><br>
        Para garantir essa capacidade, a Colmeia não apaga nenhuma mensagem de sua plataforma e faz o versionamento de todas as alterações em mensagens, nomes de grupos, nome de pessoas, e qualquer outro dado dentro da plataforma.
        <br><br>
        Quando um usuário apaga uma mensagem, grupo ou participante, esses conteúdos são retirados da visualização padrão dentro do aplicativo, mas continua acessível dentro da plataforma. Quando um usuário modifica o conteúdo da mensagem, a plataforma mostra o novo conteúdo, mas armazena todos os conteúdos antigos, com a data e hora de sua alteração.
        <br><br>
        A Colmeia provê a criptografia de entre o usuário e os servidores da Colmeia em seus Serviços. Assim as suas mensagens estão criptografadas em trânsito para que ninguém possa interceptar de forma inadvertida essa comunicação.
      </p>

      <h2>
        <b>(c) Informações coletadas pela Colmeia</b>
      </h2>
      <p>
        A Colmeia coleta dados de serviços, diagnóstico e desempenho. Isso inclui informações sobre as atividades dos Usuários, sua localização, arquivos de registro, além de registros e relatórios de diagnóstico, falhas, website e desempenho.
        <br><br>
        A Colmeia coleta dados específicos sobre o dispositivo quando nossos Serviços são instalados, acessados ou utilizados pelo Usuário. Isso inclui dados como modelo de hardware, dados do sistema operacional, dados sobre o navegador, endereço de IP, dados sobre a rede móvel e identificadores do dispositivo.
        <br><br>
        A Colmeia coleta dados sobre as alterações de status e de conexão nos Serviços, por exemplo, quando o Usuário está conectado ("status online"), quando os Serviços foram utilizados pela última vez (status "visto pela última vez") e quando a mensagem de status foi atualizada pela última vez.
      </p>
      <h2>
        <b>(d) Cookies</b>
      </h2>
      <p style="color: red">
        A Colmeia não utiliza Cookies para identificar a sessão de seus usuários, contando com tecnologias mais modernas e privadas (Token Assinados Digitalmente), que não possuem significado para outros sites e aplicativos.
        <br><br>
        Quando utilizamos serviços embutidos de terceiros em nosso App (como no caso do Google Maps), este pode utilizar-se de mecanismos próprios de reconhecimento de clientes, aos quais a Colmeia não tem acesso.
      </p>

      <h2>
        <b>(e) Uso e compartilhamento das Informações</b>
      </h2>
      <p>
        A Colmeia usa as Informações para operar e prestar os Serviços, inclusive para suporte ao Usuário, aprimoramento do Aplicativo, correção de falhas e personalização dos Serviços. As Informações também são usadas para promover a segurança no Aplicativo e efetuar login por meio de senha de maneira segura. 
        <br><br>
        A Colmeia não fará qualquer uso materialmente diferente ou cederá as Informações para terceiros para outros fins, comerciais ou não, que não os relacionados com os Serviços ou descritos nesta Política, salvo se previamente cientificar o Usuário e der a oportunidade de recusa.
        <br><br>
        A Colmeia pode compartilhar e transferir suas Informações Pessoais com funcionários, contratados ou parceiros que realizam atividades para a Colmeia, como enviar mensagens de e-mail, gerenciar dados, hospedar bancos de dados, fornecer serviços de processamento de dados, fornecer atendimento ao cliente ou para aprimorar os Serviços. Os funcionários, contratados, ou parceiros estão impedidos de usar as Informações de qualquer outra forma que não seja fornecer serviços para a Colmeia ou para colaborar em projetos da Colmeia.
        <br><br>
        A Colmeia compartilha todas as informações coletadas com as empresas detentoras de cada rede social privada. Da mesma forma, a empresa detentora de uma rede social privada também pode ter acesso ao conteúdo das mensagens trocadas pelos Usuários em sua rede social como também informações sobre a localização dos Usuários. O uso feito destas informações é de responsabilidade da respectiva de cada empresa proprietária de uma rede social privada.
        <br><br>
        A Colmeia também usa as informações para cumprir obrigações legais ou judiciais, como investigação de condutas ilícitas por autoridades governamentais ou para fazer valer seus direitos e garantir o cumprimento de seus termos de uso. Isto inclui o atendimento a intimações ou outras solicitações legais das autoridades governamentais, para fornecer segurança na Internet ou quando exigido por lei.
      </p>

      <h2>
        <b>(f) Proteção das Informações</b>
      </h2>
      <p>
        <span style="color: red">
          A Colmeia fornece proteções físicas e eletrônicas para proteger as Informações. Por exemplo, o acesso a essas informações é limitado a funcionários, prestadores de serviços e contratados autorizados que efetivamente precisam de acesso para executar os serviços prestados pela Colmeia. 
        </span>
        <br><br>
        O aplicativo Colmeia e as Informações são armazenados nos servidores do Google, localizados em Datacenters no Brasil. A Google adota e garante em contrato o fornecimento das mais modernas ferramentas de proteção de dados disponíveis atualmente.
        <br><br>
        A Colmeia esclarece que por mais que se esforce para fornecer segurança razoável para as Informações processadas e mantidas, nenhum sistema de segurança pode impedir todas as possíveis violações de segurança.
      </p>

      <h2>
        <b>(g) Alterações na Política de Privacidade</b>
      </h2>
      <p>
        A Colmeia pode atualizar esta Política de Privacidade de tempos em tempos. Quando atualizada, a Colmeia disponibilizará a Política atualizada. As Informações sempre serão tratadas de acordo com a Política de Privacidade em vigor no momento em que foram coletadas. 
      </p>
    </div>
  </div>
</div>
