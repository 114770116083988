import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { EWidgetRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { IAppWidgetRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { ISignUpResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { EAppWidgetTypes, ECrossAppCommEventType } from '@colmeia/core/src/shared-business-rules/app-widget/widget.model';
import { EColmeiaAppTypes } from 'app/model/app.model';
import { ClientInfraResponse } from 'app/model/client-infra-comm';
import { AppService } from 'app/services/app.service';
import { AuthService } from 'app/services/auth/auth.service';
import { CrossAppCommunicatorService } from 'app/services/cross-app-communicator.service';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss']
})
export class WidgetComponent {
  constructor(
    private serverAPI: ServerCommunicationService,
    private authService: AuthService,
    private rbs: RequestBuilderServices,
    private routingService: RoutingService,
    private crossAppComunicator: CrossAppCommunicatorService,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private crossAppCommunicatorService: CrossAppCommunicatorService
  ) {
    console.log(`WidgetComponent constructor works!!!`)
  }

  ngOnInit() {
    console.log(`WidgetComponent ngOnInit works!!!`);

    // send to backend all parameters from url
    // this.crossAppComunicator.sendMessage({
    //   appType: EColmeiaAppTypes.widget,
    //   eventType: ECrossAppCommEventType.conversationOpened,
    //   payload: { title: 'WidgetComponent testing msg!!!' }
    // })

    this.activatedRoute.queryParams.subscribe(async (params: Params) => {
      if (this.appService.isWidgetApp()) {
        this.executeAutoLogin(params)
      }
    });
  }

  async executeAutoLogin(params: Params) {
    console.log({ 'WidgetComponent executeAutoLogin': params });

    const { email, tokenOnce, mode } = params;
    const apiResponse = await this.sendAutoLoginRequest(email, tokenOnce, mode)
    const signUpResponse: ISignUpResponse = <ISignUpResponse>apiResponse.response

    if (apiResponse.executionOK) {
      const friendly: FriendlyMessage = apiResponse.friendlyMessage;
      if (friendly.isOk()) {
        const resultPlayerResponse = await this.authService.handlePlayerResponse(signUpResponse);
        this.navigateToAttendentStatusPage(resultPlayerResponse)
      }
    };
  }

  async sendAutoLoginRequest(email: string, tokenOnce: string, widgetType: EAppWidgetTypes) {
    const parentOrigin = this.crossAppCommunicatorService.getParentOrigin()
    const loginReq: IAppWidgetRequest = {
      ...this.rbs.createAuthRequestToAuthService(EWidgetRequest.autoLogin),
      email, tokenOnce, widgetType, parentOrigin
    };
    const spinning = this.rbs.getNoCallBackSpinnningParameters(null, null);
    const clientResponse: ClientInfraResponse = await this.serverAPI.managedRequest(
      spinning,
      loginReq);
    return clientResponse;
  }

  navigateToAttendentStatusPage(resultPlayerResponse) {
    console.log({ resultPlayerResponse });
    this.routingService.navigateToAttendentStatusPage()
  }
}
