<div class="sections">
  <button
    *ngFor="let section of sections; let idx = index"
    (click)="onSelectSection(section)"
    class="sections__tab"
    [ngClass]="{ 'sections__tab--selected': isSelectedTab(section) }"
  >
    <input
      type="radio"
      name="selectedSection"
      [value]="section"
      [(ngModel)]="selectedSection"
    />
    <label>{{ section.header }}</label>
  </button>
</div>

<app-tools-content-visualization
  *ngIf="selectedSection"
  [section]="selectedSection"
></app-tools-content-visualization>
