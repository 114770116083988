<div class="delete-account_main">
    <div class="delete-account_content">
        <img class="delete-account_colmeia--logo" [src]="'assets/img/logo/logo-324x54-white.png'" alt="colmeia logo" />
  
        <div class="delete-account_instructions">
            <h1 class="delete-account_instructions__title">
                Você está prestes a deletar sua conta do aplicativo da Colmeia.
            </h1>
            
            
            <h2 class="delete-account_instructions__subtitle">
                Estes são os dados que serão deletados do nosso sistema:
            </h2>
            <ul class="delete-account_instructions__styled-list">
                <li>Email pessoal</li>
                <li>Nome</li>
                <li>Token para envio de push notification</li>
            </ul>

            <h2 class="delete-account_instructions__subtitle">
                Estes são os dados que serão mantidos em nosso sistema se o usuário for Atendente de algum cliente Colmeia:
            </h2>
            <ul class="delete-account_instructions__styled-list">
                <li>Email, se for email corporativo de alguma empresa</li>
                <li>Mesagens trocadas, se o usuário for atendente de algum cliente Colmeia e realizou atendimento com email corporativo</li>
                <li>Nome</li>
                <li>Áudios enviados ou recebidos durante conversação</li>
                <li>Vídeos enviados ou recebidos durante conversação</li>
                <li>Fotos enviados ou recebidas durante conversação</li>
                <li>Arquivos e documentos enviados ou recebidos durante conversação</li>
                <li>Registros de falhas da aplicação</li>
            </ul>

            <h2 class="delete-account_instructions__subtitle">
                Instruções para deletar sua conta.
            </h2>
            <p class="delete-account_instructions__paragraph">
                Envie um email para <a class="delete-account_instructions__email" href="mailto:credential@colmeia.me?subject=Dele%C3%A7%C3%A3o%20de%20conta">credential&#64;colmeia.me</a> informando o email cadastrado para seguirmos com a deleção.
            </p>
            
        </div>
    </div>
  </div>
  