import {MainHandler} from "./main-handler";
import {IComponentParameter} from "../model/component-comm/basic";
import { INextGenBotServer } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { TVarEditorVariables } from "./var-editor.handler";


export interface BotElementEditConfigurationCallback {
    onBotElementConfigurationMetadataChange(idMetadata: string);
}

export interface IBotEditConfigurationHandlerParameter extends IComponentParameter {
    botElement: INextGenBotServer;
    clientCallback: BotElementEditConfigurationCallback;
    getAssetAdderVariables?: () => TVarEditorVariables;
}

export class BotEditConfigurationHandler extends MainHandler {

    constructor(parameter: IBotEditConfigurationHandlerParameter) {
        super(parameter);
    }

    getComponentParameter(): IBotEditConfigurationHandlerParameter {
        return <IBotEditConfigurationHandlerParameter>super.getComponentParameter();
    }
}
