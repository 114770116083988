import { Injectable } from "@angular/core";
import { isValidAssetAction } from "@colmeia/core/src/shared-business-rules/bot/asset-functions";
import { eBotActionDB } from "@colmeia/core/src/shared-business-rules/bot/bot-action-config-db";
import { IBotActionAsset, IBotActionAssetContainer } from "@colmeia/core/src/shared-business-rules/bot/bot-action-model";
import { IBasicAsset, KBAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { EBotContentEvent, IContentBasicAsset } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { EBotEventType } from "@colmeia/core/src/shared-business-rules/bot/bot-event-model";
import { isActionAsset, isContentAsset, isEventAsset, isRegularContentAsset } from "@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper";
import { ENextGenBotElementType, getInitialActionWithId, IBotMenuItemServer, IBotRootServer, INextGenBotServer } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EBotActionType, TNodeAssetIcons } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { BotHostNode } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/bot/bot.host";
import { MenuItemHostNode } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/bot/menu-item-host-node";
import { HostNodeFactory } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory";
import { NSGenericHost } from "@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host";
import { EAdditionalBotObjects, EAdditionalPredicateType, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { TBasicElementArray } from "@colmeia/core/src/shared-business-rules/graph/essential/basic-element";
import { BasicElementInterfaceFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element-interface.factory';
import { BasicElementFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element.factory';
import { IGraphElementRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/diagram.interfaces";
import { IBasicElementClient, TIBasicElementServerArray } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces";
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element";
import { GraphElementActionSaver, TGraphElementActionDescriptorList } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element-action.types";
import { IGraphElementJSON } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-interfaces";
import { EGraphElementType, IGraphConnectionData, IInternalElementRenderData, TGraphElementArray, TIGraphConnectionDataArray, TRenderData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types";
import { findOffsetsFor } from "@colmeia/core/src/shared-business-rules/graph/essential/graph.functions";
import { GraphPredicate } from "@colmeia/core/src/shared-business-rules/graph/essential/predicate";
import { isNSTransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { arrayDiff, isEqual, isInvalid, isValidArray, isValidFunction, isValidRef, isValidString, typedClone, values } from "@colmeia/core/src/tools/utility";
import { BotTransactionBaseDataComponent } from "app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.component";
import { EBpmGraphElementInfoMode } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler";
import { BpmGraphNSPickerComponent } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-ns-picker/bpm-graph-ns-picker.component";
import { BpmGraphNSPickerHandler } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-ns-picker/bpm-graph-ns-picker.handler";
import { DiagramEditorDialogService } from "app/components/dashboard/ai-pages/bpm-graph/diagram-editor/services/diagram-editor-dialog.service";
import { LookupService } from "app/services/lookup.service";
import { RoutingService } from "app/services/routing.service";
import { SnackMessageService } from "app/services/snack-bar/snackbar-message.service";
import { BpmApiService } from "../api/bpm-bot-api.service";
import { BPMRulesBase } from "./bpm-rules-base";
import { BpmRulesBotDataService } from "./bpm-rules-bot-data.service";
import { BpmRulesBotImportGraphService } from "./bpm-rules-bot-import-graph.service";
import { IBPMDragInto, IDraggableReturn, INewGraphElementFromDraggedToolbarIconBasic, IToolbarElementDraggedOnExistingNode, IUpserterDialogReturn, IUpserterNSDialogInfo, TConnectElementsResult } from "./bpm-rules.types";


@Injectable({
    providedIn: "root",
})
export class BPMRulesBotService extends BPMRulesBase {

    public constructor(
        private bpmBotApiService: BpmApiService,
        private bpmRulesBotDataService: BpmRulesBotDataService,
        private bpmRulesBotImportGraphSvc: BpmRulesBotImportGraphService,
        private nserLookupService: LookupService,
        private snackSvc: SnackMessageService,
        private diagramEditorDialogSvc: DiagramEditorDialogService,
        protected routingSvc: RoutingService,
    ) {
        super(
            EBPMType.bot,
            bpmBotApiService,
            bpmRulesBotDataService,
            bpmRulesBotImportGraphSvc,
            snackSvc,
            nserLookupService,
            routingSvc)
    }

    async onToolbarElementDraggedOnDiagram(info: IUpserterNSDialogInfo): Promise<void> {
        const { component, handler } = await this.getUpserterDialogComponent(info);

        const result = await this.diagramEditorDialogSvc.openDialog({
            componentToOpen: component,
            componentHandler: handler,
        });

        if (!result.userHasClickedSave || !info.rootNode) {
            return
        }

        await super.createGraphElementAndPersistToServer({
            draggedElementNS: result.nonSerializable,
            renderData: info.renderData,
        })
    }

    public canRemovePredicate(predicate: GraphPredicate): boolean {
        return predicate.getBusinessPredicate() === EBotActionType.goActionTree;
    }

    public async performGraphImportSteps(idBotRoot: string, idRootGraphElement: string): Promise<TIBasicElementServerArray> {
        return this.bpmRulesBotImportGraphSvc.performGraphImportSteps(EBPMType.bot, idBotRoot, idRootGraphElement)
    }

    private getBotDialogComponent(info: IUpserterNSDialogInfo): IUpserterDialogReturn {
        const componentAndHandlerConstructor = this.bpmRulesBotDataService.getComponentAndHandler(info);
        return isValidFunction(componentAndHandlerConstructor) ? componentAndHandlerConstructor(info) : undefined;
    }

    public async getUpserterDialogComponent(info: IUpserterNSDialogInfo): Promise<IUpserterDialogReturn> {
        const isBotRoot = info.type == ENextGenBotElementType.root
        const isContentGenerator = info.type === EBotActionType.contentGenerator || info.type === ENonSerializableObjectType.contentGenerator;
        const isRootAlreadyOnDiagram = isBotRoot && isValidRef(this.graphProcessor.getRootElement());
        const isEditingBotRoot = isBotRoot && info.mode == EBpmGraphElementInfoMode.Edit

        if (isEditingBotRoot) {
            return this.bpmRulesBotDataService.bpmInfoComponentHandlerFactory(info.entityInfo);
        }

        if (isRootAlreadyOnDiagram) {
            const handler = new BpmGraphNSPickerHandler({
                nsType: ENonSerializableObjectType.bot,
                clientCallback: undefined,
                ignoredNSsIds: [this.getGraphRulesProcessor().getRootElement().getHostedID()]
            });
            return { component: BpmGraphNSPickerComponent, handler: handler }
        }

        if (isContentGenerator) {
            // is dragging content generator into diagram
            if (info.type !== ENonSerializableObjectType.contentGenerator) {
                const handler = new BpmGraphNSPickerHandler({
                    nsType: ENonSerializableObjectType.contentGenerator,
                    clientCallback: undefined
                });

                return { component: BpmGraphNSPickerComponent, handler }
            }
        }

        return this.getBotDialogComponent(info);
    }

    public getAdditionalElementToBeRemoved(srcNode: GraphElement): TBasicElementArray {
        const hosted: BotHostNode = <BotHostNode>srcNode.getHostObject()

        if (hosted.isRoot() || hosted.isMenuItem()) {
            const { externalEdges, externalNodes } = srcNode.getExternalSubTreeSliced()
            const nodesToDeleteWithOnlyOneConnection = externalNodes.filter(node => node.getConnectionsFromOthers().length == 1)
            const toDelete = [externalEdges, nodesToDeleteWithOnlyOneConnection].flat()
            console.log({ toDelete });
            return toDelete
        }

        return [];
    }

    public canConnectElements(from: GraphElement, to: GraphElement): TConnectElementsResult {
        // const hostedFrom = from.getHostObject() as BotHostNode
        const fromType: ENextGenBotElementType = <ENextGenBotElementType>from.getHostedType();
        const toType: ENextGenBotElementType = <ENextGenBotElementType>to.getHostedType();

        if (BotHostNode.isMenuContainerToItem(fromType, toType)) {
            return { result: false, reason: "menu-container para menu-item" };
        }

        if (BotHostNode.isItemToRoot(fromType, toType)) {
            const isTargetBotRoot = to.isSameGraphElement(this.graphProcessor.getRootElement());
            if (isTargetBotRoot) {
                return { result: false, reason: "menu-item para root" };
            }
        }

        if (BotHostNode.isRootToMenuContainer(fromType, toType)) {
            const alreadyConnectedToAnyOne: boolean = from.isAlreadyConnectedToAnyOne();
            if (alreadyConnectedToAnyOne) {
                return { result: false, reason: "root ja esta conectado a outro elemento" };
            }
        };

        const alreadyConnectedTo: boolean = from.isAlreadyConnectedTo(to)
        if (alreadyConnectedTo) {
            return { result: false, reason: "elementos ja estao conectados" };
        }

        const isItemToMenu = fromType === ENextGenBotElementType.botMenuItem && toType === ENextGenBotElementType.menuContainer;
        const isMenuItemToParent = (from.getHostedObject() as INonSerializable).idParent === (to.getHostedObject() as INonSerializable).idNS;
        if (isItemToMenu && isMenuItemToParent) {
            return {
                result: false, reason: "Não é possível conectar um item ao menu container a qual ele faz parte"
            }
        }

        const isValidConnection: boolean = super.isValidConnectionInDB(fromType, toType)
        if (!isValidConnection) {
            return { result: false, reason: "root ja esta conectado a outro elemento" };
        }

        return { result: true };
    }

    public isDraggableOnDiagram(botType: TReferencedObject): boolean {
        const draggableOnDashboard: TReferencedObject[] = [
            ENextGenBotElementType.root,
            ENextGenBotElementType.menuContainer
        ];

        const isMenuContainerDroppedWithoutRoot = botType == ENextGenBotElementType.menuContainer
            && !isValidRef(this.graphProcessor.getRootElement())
        if (isMenuContainerDroppedWithoutRoot) {
            return false
        }

        return draggableOnDashboard.includes(botType);
    }

    public async dragInto(drag: IBPMDragInto): Promise<IDraggableReturn> {
        throw new Error("Method not implemented.");
    }

    public getInternalIconsFromNode(
        graphElement: GraphElement
    ): IInternalElementRenderData[] {
        //TODO: encapsular esse metodo nas classes hosted
        const host: BotHostNode = graphElement.getHostObject() as BotHostNode;
        const ns: INextGenBotServer = host.getNonSerializable();
        const nodeTypeToIcons: Map<TReferencedObject, IBasicAsset[]> = new Map();

        ns.events.forEach((event) => {
            const type = this.eventToReferencedObject(event);
            if (!nodeTypeToIcons.has(type)) nodeTypeToIcons.set(type, []);
            nodeTypeToIcons.get(type).push(event);
        });

        if (host.isMenuItem()) {
            const mi = ns as IBotMenuItemServer
            if (isValidRef(mi.action)) {
                nodeTypeToIcons.set(mi.action.type, [mi.action]);
            }

            if (isValidArray(mi.condDisplay)) {
                nodeTypeToIcons.set(EAdditionalBotObjects.menuItemConditionalDisplay, []);
            }
        }

        if (host.isRoot()) {
            const root = ns as IBotRootServer
            if (isValidRef(root.firstAction)) {
                nodeTypeToIcons.set(root.firstAction.type, [root.firstAction]);
            }

            if (isValidRef(root.posAction)) {
                nodeTypeToIcons.set(root.posAction.type, [root.posAction]);
            }
        }

        const result = [...nodeTypeToIcons.keys()].map(
            (type: TReferencedObject): IInternalElementRenderData => ({
                type: type as TNodeAssetIcons,
                hasValidConfiguration: nodeTypeToIcons
                    .get(type)
                    .every((event) => this.isValidAsset(event)),
            })
        );

        return result
    }

    public onUpdateElement(element: GraphElement, renderData: IGraphElementRenderData): void {
        throw new Error("Method not implemented.");
    }

    private isValidAsset(event: IBasicAsset): boolean {
        return true;
    }

    public eventToReferencedObject(event: IBasicAsset): TReferencedObject {
        if (isRegularContentAsset(event)) return event.type as KBAssetType;
        if (isContentAsset(event.type))
            return (event as IContentBasicAsset).botContentType;
        if (isActionAsset(event.type)) return event.type as EBotActionType;
        if (isEventAsset(event.type)) return event.type as EBotEventType;
    }



    public hasNotPosActionConfig(graphElement: GraphElement): boolean {
        const graphElementType = graphElement.getHostObject().getHostedType();
        const actionContainer = <IBotActionAssetContainer>graphElement.getHostObject().getActionContainer()[0];
        const action = actionContainer.action;
        const hasPosActionConfig = isValidRef(eBotActionDB[action?.type]?.allowedPosAction);
        const hasNotPosActionConfig = hasPosActionConfig
            ? !values(eBotActionDB[action.type].allowedPosAction).some((i) => i.parentTypes.includes(graphElementType as ENextGenBotElementType))
            : true;
        return hasNotPosActionConfig;
    }

    public isDraggableInto(graphElement: GraphElement, draggedType: TReferencedObject): boolean {
        const actionContainer = <IBotActionAssetContainer>graphElement.getHostObject().getActionContainer()[0];
        const action = actionContainer.action;

        /**
         * Sem configuração válida pra posAction
         * segue o fluxo normal
         */
        if (!isValidAssetAction(action) || this.hasNotPosActionConfig(graphElement)) {
            return graphElement.isDraggableIntoMe(draggedType);
        }

        /**
         * Regra específica de uma possível configuração do posAction
         * ex: Root sobre Root só é possível se o level do target da ação
         * esteja permitido nas configurações das ações do bot
         */
        const targetActionBotLevel = (this.getGraphRulesProcessor()
            .findNodeElementByHostedId(action.idElement)
            ?.getHostObject()
            .getNonSerializable() as INextGenBotServer)?.botLevel;

        return eBotActionDB[action.type].allowedPosAction[targetActionBotLevel]?.parentTypes.includes(draggedType as ENextGenBotElementType)
    }

    public updateOrderedPosition(graphElement: GraphElement): TGraphElementActionDescriptorList {
        return super.updateElementsSequenceModel(graphElement);
    }

    updateSequencedGraphNodes(sequencedGraphList: GraphElement[]): NSGenericHost[] {
        const toUpdateElements: NSGenericHost[] = [];

        sequencedGraphList.forEach((el, index) => {
            if (el.getHostedType() === ENextGenBotElementType.botMenuItem) {
                const hostObj = el.getHostObject() as MenuItemHostNode;
                const nonSerializable = hostObj.getNonSerializable();

                const newIndex = index + 1;

                if (nonSerializable.position !== newIndex) {
                    nonSerializable.position = newIndex;
                    toUpdateElements.push(hostObj);
                }
            }
        });

        return toUpdateElements
    }

    async refreshPosActionState(
        isRoot: boolean, nser: INonSerializable, oldNser: INonSerializable,
        updatedElement: GraphElement, batchSaver: GraphElementActionSaver, connectionType: TReferencedObject
    ): Promise<void> {
        const oldPosAction: IBotActionAsset = typedClone((oldNser as IBotRootServer).posAction);

        const botServer: IBotRootServer = nser as IBotRootServer
        const mustUpdateDuePosAction = isRoot && !isEqual(botServer.posAction, oldPosAction);
        const hasPosAction = isValidRef(botServer.posAction);

        /**
         * posAction configurada.
         */
        if (mustUpdateDuePosAction && hasPosAction) {
            const posAction = botServer.posAction;
            const elementToConnect: GraphElement = this.getGraphRulesProcessor()
                .findNodeElementByHostedId(posAction.idElement);
            const connectionData: IGraphConnectionData = {
                connectionType: posAction.type,
                targetHostId: posAction.idElement,
                subElementId: posAction.idAsset
            }

            if (elementToConnect) {
                const changedElements = super.upsertConnections(updatedElement, elementToConnect, connectionData);
                batchSaver.concatChangedNodes(changedElements);
            } else {
                await this.createAndConnectToNewNodeByAction(updatedElement, connectionData);
            }
            /**
             * posAction removida.
             */
        } else if (mustUpdateDuePosAction && !hasPosAction) {
            const graphToRemove: GraphElement = this.getGraphRulesProcessor()
                .findNodeElementByHostedId(oldPosAction.idElement);

            /**
             * Apenas remove se achar o elemento no diagrama e tiver apenas uma conexão
             * apontada pra ele que é referente ao elemento editado.
             */
            if (isValidRef(graphToRemove) && graphToRemove.getConnectionsFromOthers().length === 1) {
                batchSaver.addRemoveActionToElementList(graphToRemove.getDrawableConnectionsFromOthers());
                batchSaver.addRemoveActionToElementList(graphToRemove);
            }
        }
    }

    async createNewGraphNode(info: INewGraphElementFromDraggedToolbarIconBasic): Promise<GraphElement> {
        const botLevel: ENextGenBotElementType = (info.draggedElementNS as INextGenBotServer).botLevel
        const draggedHost: BotHostNode = <BotHostNode>HostNodeFactory.create({
            bpmType: EBPMType.bot,
            ns: info.draggedElementNS
        })
        const isDraggedElementARoot = draggedHost.getHostedType() == ENextGenBotElementType.root
        const isRootAlreadyOnDiagram = isValidRef(this.graphProcessor.getRootElement())
        const isContentGenerator = botLevel == ENextGenBotElementType.formTransaction || botLevel == ENextGenBotElementType.nlpTransaction

        const parentElement = this.graphProcessor.findNodeElementByHostedId(info.draggedElementNS.idParent);

        const isExternalElement = (
            info.isExternalElement ||
            (isDraggedElementARoot && isRootAlreadyOnDiagram) ||
            isContentGenerator ||
            isInvalid(parentElement) ||
            parentElement.isExternalElementOnDiagram()
        );

        const basicElement: IBasicElementClient = BasicElementInterfaceFactory.create(EGraphElementType.node, {
            name: info.draggedElementNS.nName,
            renderData: info.renderData,
            idParent: this.graphProcessor.getRootElementId()
        })
        const bot = info.draggedElementNS as INextGenBotServer
        const newGraphNode: GraphElement = BasicElementFactory.createGraphNode(EGraphElementType.node, {
            name: basicElement.nName,
            hostObject: HostNodeFactory.create({ bpmType: EBPMType.bot, ns: bot }),
            graphJSON: <IGraphElementJSON>{
                ...basicElement.element,
                isExternalElement
            },
            ruleProcessor: this.graphProcessor,
        }) as GraphElement
        return newGraphNode
    }

    updatePosActionRemoval(graphElementList: TBasicElementArray): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()

        /**
         * Checa se algum elemento da lista de remoção está apontando para o root do bot
         * e se é necessário limpar o posAction do root
         */
        graphElementList.forEach((ge: GraphElement) => {
            const neighborsPointingToMe = ge.getNeighborsPointingToMe();
            const itemWithPosActionPointingToMe: GraphElement = neighborsPointingToMe.reduce<GraphElement>((current, item) => {
                return (
                    isValidRef((item.neighbor.getHostObject().getNonSerializable() as IBotRootServer).posAction)
                        ? item.neighbor
                        : current
                );
            }, undefined);

            if (!itemWithPosActionPointingToMe) {
                return;
            }

            const predicate = this.getGraphRulesProcessor().getPredicate(itemWithPosActionPointingToMe.getGraphElementID(), ge.getGraphElementID());
            const predicateBussinessType = predicate.getBusinessPredicateType();
            const botActionConfig = eBotActionDB[predicateBussinessType];
            const itemBotLevel = (ge.getHostObject().getNonSerializable() as INextGenBotServer).botLevel;
            const isAbleToHavePosAction: boolean = isValidRef(botActionConfig?.allowedPosAction?.[itemBotLevel]);
            const itemNS: IBotRootServer = itemWithPosActionPointingToMe.getHostObject().getNonSerializable() as IBotRootServer;
            const isPosActionTarget = ge.getHostedID() === itemNS.posAction?.idElement;
            const needPosActionErease = isAbleToHavePosAction
                ? isValidRef(itemNS.posAction)
                : isPosActionTarget;

            if (needPosActionErease) {
                const posActionGraphElementToRemove = ge.getRuleProcessor().findNodeElementByHostedId(itemNS.posAction.idElement);

                itemNS.posAction = undefined;
                actionSaver.addSaveActionToElementList(itemWithPosActionPointingToMe);

                if (posActionGraphElementToRemove) {
                    actionSaver.addRemoveActionToElementList(posActionGraphElementToRemove.getDrawableConnectionsFromOthers());
                    actionSaver.addRemoveActionToElementList(posActionGraphElementToRemove);
                }

                actionSaver.addSaveActionToNserList(itemWithPosActionPointingToMe.getHostObject());
            }
        })

        return actionSaver.getChangedNodes()
    }

    public async handleConnectionAdditionByUser(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): Promise<void> {

        const actionSaver = GraphElementActionSaver.create()

        const updatedElements: TGraphElementActionDescriptorList = this.upsertConnections(from, to, connectionData);
        actionSaver.concatChangedNodes(updatedElements)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public upsertConnections(from: GraphElement, to: GraphElement, connectionData: IGraphConnectionData): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()
        actionSaver.addSaveActionToElementList(from);

        const { connectionType } = connectionData;

        /**
         * Caso o root tenha uma ação configurada para um formulário(transactionForm)
         * e o elemento dropado for outro Bot, a ação configurada será a posAction
         * do contrário segue o fluxo normal.
         */
        const actionContainer: IBotActionAssetContainer = <IBotActionAssetContainer>from.getHostObject().getActionContainer()[0];
        const action = actionContainer.action;
        const actionConfig = eBotActionDB[action?.type]; // bot specific
        const hasNotPosActionConfig = this.hasNotPosActionConfig(from);
        const targetGraphElement = this.getGraphRulesProcessor().findNodeElementByHostedId(action?.idElement) || to;
        const targetHostObject = targetGraphElement?.getHostObject();
        const targetActionBotLevel = (targetHostObject?.getNonSerializable() as INextGenBotServer)?.botLevel; // bot specific

        /**
         * Sem configuração válida pra posAction
         * segue o fluxo normal
         */
        if (!isValidAssetAction(action) || hasNotPosActionConfig) {
            const addedConnectionData = from.getHostObject().addConnectionTo(to.getHostObject(), connectionType)
            if (addedConnectionData.isConnectionAdded) {
                actionSaver.addSaveActionToNserList(from.getHostObject())
            }
        } else if (actionConfig?.allowedPosAction?.[targetActionBotLevel]?.allowedActions.includes(connectionType as EBotActionType)) { // bot specific
            const ns: IBotRootServer = from.getHostObject().getNonSerializable() as IBotRootServer // bot specific

            ns.posAction = {
                ...(isValidRef(action) ? action : getInitialActionWithId()),
                type: <EBotActionType>connectionType,
                idElement: to.getHostObject().getHostedID(),
            };
            actionSaver.addSaveActionToNserList(from.getHostObject());
        }

        const isPosAction = (
            connectionType === EBotActionType.goBot &&
            from.getHostObject().getHostedType() === to.getHostObject().getHostedType()
        );
        if (!isPosAction) {
            const updatedElements = this.removeOldConnections(from, connectionType)
            actionSaver.concatChangedNodes(updatedElements)
        }

        const [, graphNodesToChange]: [GraphPredicate, TGraphElementActionDescriptorList] = this.updateConnections(
            from, to, connectionData)
        actionSaver.concatChangedNodes(graphNodesToChange)

        return actionSaver.getChangedNodes()
    }

    public removeOldConnections(
        from: GraphElement,
        newPredicateType: TReferencedObject,
    ): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create()
        //begin deve chamar metodos no hosted aqui(getPredicatesToRemove)
        const isBotRootAndHasPosAction = from.getHostedType() === ENextGenBotElementType.root
            && (from.getHostObject().getNonSerializable() as IBotRootServer).posAction;
        const isMenuContainerToMenuItem = from.getHostedType() === ENextGenBotElementType.menuContainer
            && newPredicateType == EAdditionalPredicateType.Parent
        let defaultPredicatesToRemove = this.getPredicatesToRemoveFromModel(from);
        const predicatesToRemove = isMenuContainerToMenuItem
            ? []
            : defaultPredicatesToRemove;
        //end deve chamar metodos no hosted aqui(getPredicatesToRemove)

        this.removeElementsFromRulesProcessorDBByDescriptor(predicatesToRemove);

        if (isBotRootAndHasPosAction) {
            console.log({ defaultPredicatesToRemove });
        }
        actionSaver.concatChangedNodes(predicatesToRemove)
        return actionSaver.getChangedNodes()
    }

    public async handleConnectionRemovalByUser(predicate: GraphPredicate): Promise<void> {
        const actionSaver = GraphElementActionSaver.create()

        const targetNode = this.graphProcessor.getTargetNode(predicate)
        const changedNodes = this.deleteNeighboursPointingToMeAndCleanUpParentNodeState([targetNode])
        actionSaver.concatChangedNodes(changedNodes)

        this.graphProcessor.removePredicate(predicate)
        actionSaver.addRemoveActionToElementList(predicate)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public async handleEditedNserFromDialogByUser(oldElement: GraphElement, updatedElementNser: INonSerializable): Promise<void> {
        const actionSaver = GraphElementActionSaver.create()
        const editedElement: BotHostNode = <BotHostNode>HostNodeFactory.create({
            bpmType: EBPMType.bot,
            ns: updatedElementNser
        })

        const graphNodesToChange = await this.handleEditedNodeNserModelUpdateGraph(oldElement, editedElement)
        actionSaver.concatChangedNodes(graphNodesToChange)

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public async handleToolbarElementDragOnElementByUser(
        targetElement: GraphElement,
        draggedElementNS: INonSerializable,
        renderData: TRenderData,
        predicateType: TReferencedObject,
    ) {
        const actionSaver = GraphElementActionSaver.create()

        const connectionData: IGraphConnectionData = {
            connectionType: predicateType,
            targetHostId: draggedElementNS.idNS,
            subElementId: undefined,
        };
        const elementsToSave = await this.createChildrenNodesAndConnections(
            targetElement,
            draggedElementNS,
            renderData,
            connectionData,
        )
        actionSaver.concatChangedNodes(elementsToSave)

        const draggedGraphElement: GraphElement = this.getGraphRulesProcessor().findNodeElementByHostedId(draggedElementNS.idNS);
        if (isValidRef(draggedGraphElement) && (<BotHostNode>draggedGraphElement.getHostObject()).isMenuItem()) {
            actionSaver.concatChangedNodes(this.updateOrderedPosition(draggedGraphElement));
        }

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public async handleInternalToolbarElementDragOnElementByUser(
        targetElement: GraphElement,
        draggedType: TReferencedObject,
        draggedNS?: INonSerializable
    ) {
        const actionSaver = GraphElementActionSaver.create()
        let connectionsToRemove: TGraphElementActionDescriptorList;

        switch (draggedType) {
            case EBotContentEvent.preContent:
            case EBotContentEvent.posContent:
            case EAdditionalBotObjects.menuItemConditionalDisplay:
                actionSaver.addSaveActionToElementList(targetElement)
                break;
            case EBotActionType.goBack:
            case EBotActionType.isEnd:
            case EBotActionType.goFirstTree:
            case EBotActionType.goBot:
            case EBotActionType.goHuman:
            case EBotActionType.LGPD:
                targetElement.getHostObject().addElementAction(draggedType, draggedNS?.idNS)

                connectionsToRemove = super.getPredicatesToRemoveFromModel(targetElement)
                actionSaver.concatChangedNodes(connectionsToRemove);
                actionSaver.addSaveActionToElementList(targetElement);
                actionSaver.addSaveActionToNserList(targetElement.getHostObject());
                actionSaver.concatChangedNodes(super.removeAllNecessaryChildrenEdgesAndNodes(targetElement))
                break;
            case EBotActionType.contentGenerator:
                const newElementRenderData = findOffsetsFor(targetElement.getRenderData(), this.getGraphRulesProcessor().getAllNodesJSON())
                const connectionData: IGraphConnectionData = {
                    connectionType: draggedType,
                    targetHostId: draggedNS.idNS,
                    subElementId: undefined,
                };
                const elementsToSave = await this.createChildrenNodesAndConnections(
                    targetElement,
                    draggedNS,
                    newElementRenderData,
                    connectionData,
                )
                actionSaver.concatChangedNodes(elementsToSave);
                break;
            default:
                throw new Error(`handleInternalToolbarElementDragOnElement "${draggedType}" nao encontrado`)
        }

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    public onToolbarElementDraggedOnExistingNode(info: IToolbarElementDraggedOnExistingNode) {
        this.handleToolbarElementDragOnElementByUser(
            info.targetNodeAlreadyInDiagram,
            info.dialogInfo.entityInfo.entity,
            info.draggedToolbarElementRenderData,
            info.predicateType,
        );
    }

    public needLeftPushOnAutoImport(type: TReferencedObject): boolean {
        return type !== ENextGenBotElementType.menuContainer
    }

    public async importPostProcessor(): Promise<void> {
        const actionSaver = GraphElementActionSaver.create();
        const allElements = values(this.graphProcessor.getAllGraphElements()) as TGraphElementArray;
        allElements.forEach(element => {
            switch (element.getHostedType()) {
                case ENextGenBotElementType.menuContainer:
                    actionSaver.addSaveActionToElementList(element);
                    actionSaver.concatChangedNodes(this.organizeChildrenOrderedItems(element));
                    break;
                case ENextGenBotElementType.botMenuItem:
                    break;
                default: actionSaver.addSaveActionToElementList(element);
            }
        });

        await this.finishTransaction(actionSaver.getChangedNodes())
    }

    private organizeChildrenOrderedItems(graphElement: GraphElement): TGraphElementActionDescriptorList {
        const actionSaver = GraphElementActionSaver.create();
        const children = graphElement.getNeighborsThatIPointTo();
        const baseRenderData = graphElement.getRenderData();
        const containerWidthSize = 240;
        const containerHeightSize = 60;
        const padding = 20;
        const correctSequence = [...children].sort((elA, elB) => {
            const AElementPosition = elA.getHostObject().getSequencialPosition();
            const BEelementPosition = elB.getHostObject().getSequencialPosition();
            return AElementPosition > BEelementPosition ? 1 : -1;
        });

        const offsetX = baseRenderData.offsetX + (containerWidthSize / 2) + padding;

        correctSequence.forEach((element, index) => {
            ++index;
            const offsetY =
                baseRenderData.offsetY +
                (containerHeightSize + padding) * index;

            element.setRenderData({
                ...element.getRenderData(),
                offsetX,
                offsetY
            });

            actionSaver.addSaveActionToElementList(element);
        });

        return actionSaver.getChangedNodes();
    }

    onDraggedToolbarElementExternalOnDiagramByUser() {
        //create child nodes and connections
    }
    onDraggedToolbarElementInternalOnDiagramByUser() {
        //create child nodes and connections
    }
    onDraggedToolbarElementOnExistingNodeByUser() {
        //update child nodes and connections
    }
    onEditedNodeByUser() {
        //update child nodes and connections
    }
    onDeletedNodeByUser() {
        //remove child nodes and connections
    }
}
