import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { EColmeiaAppTypes } from 'app/model/app.model';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private appType: EColmeiaAppTypes;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    console.log({ AppServiceConstructorTimestamp: (new Date()).toISOString() });
    this.appType = EColmeiaAppTypes.web
  }

  setAppType(appType: EColmeiaAppTypes) {
    this.appType = appType
  }

  getAppType(): EColmeiaAppTypes {
    return this.appType;
  }

  isWidgetApp(): boolean {
    return this.appType == EColmeiaAppTypes.widget
  }

  getSetAppTypeFromUrlAsync() {
    console.log({ AppServiceTimestampGetSetAppTypeFromUrlAsyncTimestamp1: (new Date()).toISOString() });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const activeRoute = this.getLastActivatedRoute(this.activatedRoute);
      activeRoute.queryParams.subscribe(params => {
        const appTypeFromUrlParam: EColmeiaAppTypes = params['appType']
        if (isValidString(appTypeFromUrlParam)) {
          console.log({ AppServiceTimestampGetSetAppTypeFromUrlAsyncTimestamp2: (new Date()).toISOString() });
          this.appType = appTypeFromUrlParam
        }
      });
    });
  }

  private getLastActivatedRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }
}
