import {Tracker} from './tracker';
import { Participant } from '../../business/participant';
import { constant } from '../../business/constant';
import { IClientDebugTracker } from '../../comm-interfaces/barrel-comm-interfaces';
import { ILogCharacteristic } from '../../core-constants/tracker-qualifiers';


export class TrackerDebug extends Tracker {
    private fname: string;
    private details: Object;
    private characteristic: ILogCharacteristic;

    public constructor (participant: Participant, functionName: string, details: Object, characteristic: ILogCharacteristic) {
        super(null, participant, constant.trackerTypes.client.debug);
        this.details = details;
        this.fname = functionName;
        this.characteristic = characteristic;
    };

    public toJSON(): IClientDebugTracker {
        return {...super.toJSON(),
            functionName: this.fname,
            details: this.details,
            characteristic: this.characteristic
        };
    };

};
