import { Group, Avatar, TAvatarArray, GroupType } from '@colmeia/core/src/business/barrel-business';
import { constant } from '@colmeia/core/src/business/constant';
import { Interaction, TInteractionArray } from '@colmeia/core/src/interaction/interaction';

import { TThreadArray} from '@colmeia/core/src/interaction/thread';
import { hashToArray } from '@colmeia/core/src/tools/utility';

import { getChildrenGroupsOfType, getStandardGroup, isAllRoot, getFunctionalGroups, getPersonalGroups } from '@colmeia/core/src/rules/filters';
import { TArrayAvatar, TGroupArray, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { DeleteInteraction } from '@colmeia/core/src/interaction/interactions/deleted-interaction';
import {clientConstants} from "./constants/client.constants";
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';

export interface IClientSubscriptionHash {
	[idGroup: string]: GroupSubscription;
}

export type TRequestedGroupFilter = (groupArray: TGroupArray) => TGroupArray;

/**
 * Wrapper to group, participant and interactions
 */
export class GroupSubscription {


	private group: Group;
	private arrayAvatars: TArrayAvatar;
	private childGroups: TGroupArray;
    private dinamicGroupNavigator: TGroupArray;
	private interactions: Interaction[];
    private currentFilter: TRequestedGroupFilter;
    
    public static getNewGroupSubscriptionModel(group: Group, interactions: TInteractionArray,
                        childGroups: TGroupArray,
                        requestedGroups: TGroupArray, map: IClientSubscriptionHash): GroupSubscription {
        let model: GroupSubscription = map[group.getGroupID()];
        if (model) {
            for (const interaction of interactions) {
                model.addInteractionToArray(interaction);
            };
        } else {
            model = new GroupSubscription(group, interactions, childGroups);
        };
        model.setRequestedGroups(requestedGroups);
        model.setRequestGroupsToDefault();
        return model;
    };

    public setCurrentRequestFilter(currentFilter: TRequestedGroupFilter): void {
        this.currentFilter = currentFilter;
    };

    public setRequestGroupsToSpecialGroups(): void {
        this.setCurrentRequestFilter(getFunctionalGroups);
    };

    public setRequestGroupsToPersonalGroups(): void {
        this.setCurrentRequestFilter(getPersonalGroups);
    };

    public setRequestGroupsToDefault(): void {
        this.setCurrentRequestFilter(getStandardGroup);
    };

    public setRequestedGroups(results: TGroupArray): void {this.dinamicGroupNavigator = results; };

    public getRequestedGroups(): TGroupArray {
        return this.dinamicGroupNavigator;
    };


	private constructor(group: Group, interactions: TInteractionArray, childGroups: TGroupArray) {

        const allAvatars: {[idParticipant: string]: Avatar} = {};
        

        if (!interactions) {
            interactions = [];
        };

		// Insert initial interactions
        this.setInteractionsArray(interactions);

		this.group = group;

        for (const interaction of interactions) {
            allAvatars[interaction.getParticipant().getAvatar().getAvatarID()] = interaction.getParticipant().getAvatar();
        };

        this.arrayAvatars = <TAvatarArray>hashToArray(allAvatars);
        this.childGroups = childGroups;
    }


    private trace(proc: string) {

    }


	public hasInteractionInArray(interactionId: TGlobalUID): number {
		return this.interactions.findIndex((interaction) => interaction.getPrimaryID() == interactionId);
    };

    

    public hasAvatar(avatar: Avatar): boolean {
        return this.arrayAvatars.some((avt) => {return avt.getAvatarID() == avatar.getAvatarID()})
    }

	public setInteractionsArray(interactions: TInteractionArray = []): void {
        this.trace('setInteractions');
		this.interactions = this.sortSubscriptionInteractions(interactions);
	}

	public addInteractionToArray(newInteraction: Interaction): boolean {
        if (newInteraction.getInteractionType().isDeleteInteraction()) {
            for (const del of Interaction.getWholeInteractionChain(this.getGroupID(), (<DeleteInteraction>newInteraction).getInteractionParent())) {
                const index: number = this.hasInteractionInArray(del.getInteractionID());
                if (index > -1) {
                    this.interactions.splice(index, 1);
                };
            };

        } else if (newInteraction.getInteractionType().isStoredInClientDatabase()) {
            const index: number = this.hasInteractionInArray(newInteraction.getInteractionID());
            if (index > -1) {
                this.interactions[index] = newInteraction;
            } else {
                this.interactions.push(newInteraction);
                this.addAvatarToArray(newInteraction.getParticipant().getAvatar());
            };
            return index > -1;
        };
        return false;
	};

    public getGroup(): Group { return this.group; };

    getSocialNetworkFromGroup(): TGlobalUID {
        return this.getGroup().getSocialContext()
    }

    public getGroupType(): GroupType {
        return this.group.getGroupType();
    }

    public getGroupID(): TGlobalUID {
        return this.group.getGroupID();
    };

    public getLastClockTick(): number {
        let maxClockTick: number = 0;
        for (const interaction of this.interactions) {
            if (interaction.getClockTick() > maxClockTick) {
                maxClockTick = interaction.getClockTick();
            };
        };
        return maxClockTick;
    };


    public getAllThreads(): TThreadArray {
        const threads: {[idThread: string]: Interaction} = {};

        for (const interaction of this.interactions) {
            for (const feedback of interaction.getChildren(constant.interactionType.feedback.newThread))
                threads[feedback.getPrimaryID()] = feedback;
        }

        return <TThreadArray>hashToArray(threads);
    }

    public getAllChannels(): TGroupArray {
        return getChildrenGroupsOfType(this.childGroups, constant.groupType.standard.channel);
    }

	public getInteractions(): TInteractionArray {
        this.trace('getInteractions');
		return this.interactions;
	}

   	public getAvatarArray(): TArrayAvatar {
		return this.arrayAvatars;
	}

	public addAvatarToArray(avatar: Avatar): void {
        if (! this.hasAvatar(avatar)) {
            this.arrayAvatars.push(avatar);
        };
	};

	public addGroupChild(group: Group): void {
		this.childGroups.push(group);
	}

	public getGroupChilds(): TGroupArray {
		return this.childGroups;
	}

	public getGroupChannels(): TGroupArray {
		return this.childGroups.filter(group => group.getGroupType().getGroupTypeID() === constant.groupType.standard.channel);
	}

	private sortSubscriptionInteractions(interactions: Interaction[]): Interaction[] {
		// Sort the message
		return interactions
		.sort((a, b) => {
			const x = a.getBusinessID() ? a.getBusinessID() : clientConstants.maxBusinessID;
			const y = b.getBusinessID() ? b.getBusinessID() : clientConstants.maxBusinessID;
			return y <= x ? 1 : -1;
		});
	}
}
