import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SessionService } from 'app/services/session.service';
import { ColmeiaWindowBarComponent } from './colmeia-window-bar.component';

@Injectable({
    providedIn: 'root'
})
export class DashboardWindowBarService {
    private ready: boolean = false;

    constructor(private overlay: Overlay, private sessionSvc: SessionService,) {}
    public setup() {
        if(this.ready || this.sessionSvc.isEmbeddedChat()) return;
        this.ready = true;

        const positionStrategy = this.overlay.position().global();
        positionStrategy.bottom("0px");
        positionStrategy.left("0px");

        const state = new OverlayConfig({
            positionStrategy,
            panelClass: 'dialog-window-bar',
            hasBackdrop: false,
            width: "100%",
            disposeOnNavigation: false
        });

        const overlayRef = this.overlay.create(state);
        const containerPortal = new ComponentPortal(ColmeiaWindowBarComponent);

        overlayRef.attach<ColmeiaWindowBarComponent>(containerPortal);
        overlayRef.hostElement.classList.add("dialog-window-bar");
    }


}
