import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ConditionsProcessor } from '@colmeia/core/src/general-form/general-form-condition-processor';
import { SchemaProperty } from '@colmeia/core/src/general-form/general-form-interface';
import { get2FAEngagementByIdProperty, get2FATarget } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { ETokenStatus, IEmbeddedChatValidateTokenRes } from '@colmeia/core/src/shared-business-rules/embedded/embedded-req-res';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { IFieldSmart2FV, IMetadataRegister } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { I2FASettings } from '@colmeia/core/src/shared-business-rules/two-factor-validation/two-factor-model';
import { minToMS, secToMS } from '@colmeia/core/src/time/time-utl';
import { delay, getClock, isValidRef, typedArrayToHash } from '@colmeia/core/src/tools/utility';
import { twoFactorSettingsToKey } from '@colmeia/core/src/two-factor-auth/two-factor-functions';
import { GeneralFormFieldService } from 'app/components/general-form/services/general-form-field.service';
import { TNotificationDialogConfig } from 'app/components/notifications-dialog/notification-dialog.model';
import { NotificationDialogService } from 'app/components/notifications-dialog/notification-dialog.service';
import { EmbeddedChatService } from './embedded-chat.service';


type TIdPropertyToSchemaProperty = { [idProperty: string]: SchemaProperty };

interface ITwoFactorAuthServiceState {
    idService: string;
    schema: SchemaPropertyServer;
    customerFormulary: IMetadataRegister;
}


enum EDispatchedTokenStatus {
    Pending = 'Pending',
    Sent = 'Sent',
    FirstUse = 'FirstUse',
    SecondUse = 'SecondUse',
}

interface ITokenStatus {
    status: EDispatchedTokenStatus;
    expirationTimeMS?: number;
}

namespace TwoFactorAuthService {
    export interface IGetTokenFieldMetadata {
        canDispatch: boolean,
        target: string,
        settings: I2FASettings,
        mapDispatchedTokenKey: string;
        tokenValue: string
    }
}

@Injectable({
    providedIn: 'root'
})
export class TwoFactorAuthService {


    private mapTokenAmountDispatchs: Map<string, number> = new Map();
    private mapDispatchedToken: Map<string, ITokenStatus> = new Map();
    private state: ITwoFactorAuthServiceState;
    private idPropertyToSchemaProperty: TIdPropertyToSchemaProperty;

    constructor(
        private embedded: EmbeddedChatService,
        private generalFormFieldSvc: GeneralFormFieldService,
        private notificationsSvc: NotificationDialogService,
    ) { }


    public init(state: ITwoFactorAuthServiceState) {
        this.state = state;
        this.idPropertyToSchemaProperty = typedArrayToHash(this.state.schema.schemma.form, 'idProperty')();
    }

    public getIdPropertyToValueHash(fGroup: UntypedFormGroup): TComputedInfo {
        return this.generalFormFieldSvc.getFormIdValueMap(fGroup, this.state.schema.schemma.form)
    }

    public getTokenFieldByIdProperty(idProperty: string): IFieldSmart2FV {
        return get2FAEngagementByIdProperty(
            idProperty,
            this.state.customerFormulary.engagement,
        )
    }

    public isTokenField(idProperty: string): boolean {
        return isValidRef(this.getTokenFieldByIdProperty(idProperty));
    }

    public getTokenFieldMetadata(fGroup: UntypedFormGroup, idProperty: string): TwoFactorAuthService.IGetTokenFieldMetadata {
        const idPropertyOrLocalCanonicalToValueHash: TComputedInfo = this.getIdPropertyToValueHash(fGroup);
        const { settings } = this.getTokenFieldByIdProperty(idProperty);
        const target: string = idPropertyOrLocalCanonicalToValueHash[get2FATarget(settings)];
        const mapDispatchedTokenKey: string = twoFactorSettingsToKey(settings, idProperty, target);
        const hasPreviousDispatchedToken: boolean = this.hasSettingsToken(mapDispatchedTokenKey);

        const tokenValue: string = idPropertyOrLocalCanonicalToValueHash[idProperty];
        const amountDispatchsForTokenValue: number = this.mapTokenAmountDispatchs.get(tokenValue);

        return {
            canDispatch: !hasPreviousDispatchedToken || amountDispatchsForTokenValue === 1,
            target,
            tokenValue,
            settings,
            mapDispatchedTokenKey,
        };
    }

    public hasSettingsToken(mapDispatchedTokenKey: string): boolean {
        return this.mapDispatchedToken.has(mapDispatchedTokenKey);
    }

    public async dispatchTokenField(fGroup: UntypedFormGroup, idProperty: string): Promise<void> {
        const { settings, canDispatch, target, mapDispatchedTokenKey, tokenValue } = this.getTokenFieldMetadata(fGroup, idProperty);

        const style: Partial<CSSStyleDeclaration> = {
            minWidth: '0',
            padding: '0',
            fontSize: '1.1em',
        }

        if (canDispatch) {
            const config: TNotificationDialogConfig<unknown> = {
                duration: secToMS(5),
                customStyle: {
                    container: style,
                },
            };

            const dispatchRef = this.notificationsSvc.open({
                ...config,
                message: 'Disparando token',
            }, true)

            const hasDispatched: boolean = await this.dispatchSettingsToken(
                settings,
                idProperty,
                target,
                mapDispatchedTokenKey,
            );

            if (hasDispatched && tokenValue) {
                this.mapTokenAmountDispatchs.set(tokenValue, this.mapTokenAmountDispatchs.get(tokenValue) + 1)
            }

            dispatchRef.close();

            this.notificationsSvc.open({
                ...config,
                customStyle: {
                    container: {
                        ...style,
                        background: '#3ec955',
                        fontWeight: 'bold',
                    }
                },
                message: hasDispatched ? 'Token disparado com sucesso' : `Não foi possível disparar seu token`,
            })
        } else {
            // disparar novamente?
        }
    }

    public async validateTokenField(fGroup: UntypedFormGroup, control: AbstractControl): Promise<ValidationErrors> {
        const [idProperty, value] = this.generalFormFieldSvc.getFormFieldData(fGroup, control);
        const error = await this.validateTokenAndGetErrorMessage(fGroup, idProperty, value);
        
        return error && { errorMsg: error.errorMsg, status };
    }

    public validateTokenAndGetErrorMessage = async (fGroup: UntypedFormGroup, idProperty: string, value: string): Promise<{ status: ETokenStatus; errorMsg: string } | undefined> => {
        const { canDispatch, target, settings, mapDispatchedTokenKey } = this.getTokenFieldMetadata(fGroup, idProperty);
        const { isSuccess, status }: IEmbeddedChatValidateTokenRes = await this.embedded.validateToken(idProperty, value);

        if ([ETokenStatus.Expired].includes(status)) {
            this.mapDispatchedToken.delete(mapDispatchedTokenKey);
        }

        if (isSuccess || status === ETokenStatus.Used) {
            if (!this.mapTokenAmountDispatchs.get(value)) this.mapTokenAmountDispatchs.set(value, 0);
            this.mapTokenAmountDispatchs.set(value, this.mapTokenAmountDispatchs.get(value) + 1);
        }

        return isSuccess ? undefined : {
            errorMsg: ConditionsProcessor.getErrorMsg(settings.action),
            status,
        };
    }

    public dispatchToken = async (idProperty: string, value: string): Promise<boolean> => {
        return this.embedded.sendToken(idProperty, value);
    }

    public getExpirationTime(fGroup: UntypedFormGroup, idProperty: string): number {
        const { settings, canDispatch, target, mapDispatchedTokenKey } = this.getTokenFieldMetadata(fGroup, idProperty);
        if (!canDispatch) return this.mapDispatchedToken.get(mapDispatchedTokenKey).expirationTimeMS;
    }

    public async dispatchSettingsToken(settings: I2FASettings, idProperty: string, value: string, key: string): Promise<boolean> {
        this.mapDispatchedToken.set(key, {
            status: EDispatchedTokenStatus.Pending,
        });
        const success = await this.dispatchToken(idProperty, value);
        if (success) {
            this.mapDispatchedToken.set(key, {
                status: EDispatchedTokenStatus.Sent,
                expirationTimeMS: getClock() + secToMS(settings.expireInSeconds),
            });
            setTimeout(() => {
                this.mapDispatchedToken.delete(key);
            }, secToMS(settings.expireInSeconds))
        } else {
            this.mapDispatchedToken.delete(key);
        }
        return success;
    }

}
