import { Injectable } from '@angular/core';
import { EBotContentEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EBotActionType, ENodeAssetIcons } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { EAdditionalBotObjects, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { ITransactionServer, initBotTransaction } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { BotTransactionBaseDataComponent } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.component';
import { BotTransactionBaseDataComponentDiagramDialogHandler } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.handler';
import { EGraphElementInfoBotHandlerTabs } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bot-graph-element-info-bot/bot-graph-element-info-bot.handler";
import { BpmGraphElementInfoComponent } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.component';
import { BpmGraphElementInfoHandler, EBpmGraphElementInfoMode, IBpmGraphElementInfoClientCallback, IBpmGraphElementInfoEntity, IBpmGraphElementInfoParameters } from "app/components/dashboard/ai-pages/bpm-graph/bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.handler";
import { IUpserterDialogReturn, IUpserterNSDialogInfo } from './bpm-rules.types';


@Injectable({
    providedIn: 'root'
})
export class BpmRulesBotDataService {

    constructor() { }

    getComponentAndHandler(info: IUpserterNSDialogInfo): (info: IUpserterNSDialogInfo) => IUpserterDialogReturn {
        if (
            info.type === ENonSerializableObjectType.contentGenerator
            && info.iconClicked?.type === ENodeAssetIcons.edit
        ) {
            return (info: IUpserterNSDialogInfo) => (
                {
                    component: BotTransactionBaseDataComponent,
                    handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                        transaction: info.entityInfo.entity as ITransactionServer,
                        clientCallback: undefined,
                    })
                }
            );
        }

        return this.typeToComponentAndHandler[info.type];
    }

    bpmInfoComponentHandlerFactory(workElement: IBpmGraphElementInfoEntity, override?: Partial<IBpmGraphElementInfoParameters["workElement"]>): IUpserterDialogReturn {
        const handler = BpmGraphElementInfoHandler.factory({
            workElement: { ...workElement, ...override },
            clientCallback: <IBpmGraphElementInfoClientCallback>{
                close: () => handler.getDialogRef().close(),
            },
        });

        return {
            component: BpmGraphElementInfoComponent,
            handler
        };
    }

    private typeToComponentAndHandler: { [key in TReferencedObject]?: (info: IUpserterNSDialogInfo) => IUpserterDialogReturn } = {
        [ENextGenBotElementType.root]: (info: IUpserterNSDialogInfo) => this.bpmInfoComponentHandlerFactory(info.entityInfo),
        [ENextGenBotElementType.menuContainer]: (info: IUpserterNSDialogInfo) => this.bpmInfoComponentHandlerFactory(info.entityInfo),
        [ENextGenBotElementType.botMenuItem]: (info: IUpserterNSDialogInfo) => this.bpmInfoComponentHandlerFactory(info.entityInfo),
        [EBotActionType.goHuman]: (info: IUpserterNSDialogInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.actionConfiguration, mode: EBpmGraphElementInfoMode.Edit, preSelectedAction: EBotActionType.goHuman }
            )
        ),
        [EBotActionType.contentGenerator]: (info: IUpserterNSDialogInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.actionConfiguration, mode: EBpmGraphElementInfoMode.Edit, preSelectedAction: EBotActionType.contentGenerator }
            )
        ),
        [EBotContentEvent.preContent]: (info: IUpserterNSDialogInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.preContent, mode: EBpmGraphElementInfoMode.Edit }
            )
        ),
        [EBotContentEvent.posContent]: (info: IUpserterNSDialogInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.posContent, mode: EBpmGraphElementInfoMode.Edit }
            )
        ),
        [EAdditionalBotObjects.menuItemConditionalDisplay]: (info: IUpserterNSDialogInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.conditionalDisplay, mode: EBpmGraphElementInfoMode.Edit }
            )
        ),
        [EBotActionType.LGPD]: (info: IUpserterNSDialogInfo) => (
            this.bpmInfoComponentHandlerFactory(
                info.entityInfo,
                { tabToOpen: EGraphElementInfoBotHandlerTabs.actionConfiguration, mode: EBpmGraphElementInfoMode.Edit, preSelectedAction: EBotActionType.LGPD }
            )
        ),
        [ENonSerializableObjectType.contentGenerator]: (info: IUpserterNSDialogInfo) => (
            {
                component: BotTransactionBaseDataComponent,
                handler: new BotTransactionBaseDataComponentDiagramDialogHandler({
                    transaction: initBotTransaction() as ITransactionServer,
                    clientCallback: undefined,
                })
            }
        ),
    };

}
