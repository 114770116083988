import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ENaviTrackerRules, INaviTracker } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { ETagType, IServerColmeiaTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { EEnumPickerMode, EnumPickerHandler, IEnumPickerClientCallback } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { ETagPickerHandlerMode, ITagPickerHandlerParameter, TagPickerHandler } from 'app/handlers/tag-picker-handler';
import { TNaviTrackerPickerParameters } from 'app/services/dashboard/choice-tracker.service';
import { inArray } from 'jquery';

export enum ENaviTrackTranslations {
  repSameCat = 'Sobreponha',
  noReplace = 'Registre todos',
}

@Component({
  selector: 'choice-tracker',
  templateUrl: './choice-tracker.component.html',
  styleUrls: ['./choice-tracker.component.scss']
})
export class ChoiceTrackerComponent implements OnInit {
  @Input() handler?: TNaviTrackerPickerParameters
  @Input() title?: string;
  naviTracker: INaviTracker;
  @Output() naviTrackerChange = new EventEmitter<INaviTracker>();
  tagName: string;
  error: boolean = false;
  nsPickerHandler: NSPickerHandler<ENonSerializableObjectType.colmeiaTags>;
  tagPickerHandler: TagPickerHandler;
  enumPickerHandler: EnumPickerHandler<ENaviTrackerRules>;
  @Input() parameterInputTitle: string = "Parâmetro";

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: TNaviTrackerPickerParameters,
    private ref: MatDialogRef<any, any>,
  ) { }

  ngOnInit(): void {
    this.naviTracker = this.handler?.naviTracker || this.dialogData?.naviTracker;
    this.initTagPickerHandler();
    this.initEnumPicker();
    if (this.dialogData.title) this.title = this.dialogData.title;
  }

  
  async initTagPickerHandler(): Promise<void> {
    const selected: IServerColmeiaTag[] = [];
    if (this.naviTracker?.idNSTrack) {
      const ns = await NSSharedService.getById(this.naviTracker?.idNSTrack);
      if (ns) selected.push(ns as IServerColmeiaTag);
    }
    const parameter: ITagPickerHandlerParameter = {
      tagType: ETagType.marker,
      mode: ETagPickerHandlerMode.edit,
      selectedTags: selected,
      clientCallback: this,
      compact: false,
      canSelectGroupTag: true
    };

    this.tagPickerHandler = new TagPickerHandler(parameter);
  }

  onFinishSelectionCallback(selectedTags: IServerColmeiaTag[]) {
    if (selectedTags.length > 1 || selectedTags.length === 0) {
      this.tagName = '';
      this.error = true;
      return;
    }
    this.error = false;
    this.tagName = selectedTags[0]?.nName;
    this.naviTracker.idNSTrack = selectedTags[0]?.idNS || '';
    this.emitNaviTracker();
  }

  private initEnumPicker() {
    this.enumPickerHandler = new EnumPickerHandler<ENaviTrackerRules>({
      client: {
        onSingleEnumSelection: val => {
          this.naviTracker.navTrackRule = val
          this.emitNaviTracker();
        }
      } as IEnumPickerClientCallback<ENaviTrackerRules>,
      mode: EEnumPickerMode.Single,
      appearance: 'fill',
      inputTitle: 'Em caso de Track de mesmo nível',
      enum: ENaviTrackerRules,
      clientCallback: {},
      current: this.naviTracker.navTrackRule,
      translations: ENaviTrackTranslations
    })
  }

  cancel() {
    this.ref.close();
  }
  save() {
    if (this.error) return;
    this.ref.close({ naviTracker: this.naviTracker, tagName: this.tagName })
  }

  emitNaviTracker() {
    // const fulfilled = Object.keys(this.naviTracker).every(key => this.naviTracker[key as keyof INaviTracker]);
    this.naviTrackerChange.emit(this.naviTracker);
  }
}
