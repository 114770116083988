import { Component, OnInit, ViewChild, ViewContainerRef, Input, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';
import { DynamicComponentHandler, IDynamicComponentParameter } from './cm-dynamic-component.handler';
import { isValidRef, updateFieldSubset } from '@colmeia/core/src/tools/utility';

@Component({
    selector: 'cm-dynamic-component',
    templateUrl: './cm-dynamic-component.component.html',
    styleUrls: ['./cm-dynamic-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmDynamicComponent implements OnChanges, AfterViewInit, AfterViewChecked {

    @ViewChild("viewContainerRef", { read: ViewContainerRef })
    public viewContainerRef: ViewContainerRef;

    @Input()
    public handler: DynamicComponentHandler;

    private viewInitialized: boolean = false;

    constructor(
        private cdRef: ChangeDetectorRef,
    ) { }

    public get parameter(): IDynamicComponentParameter {
        return this.handler.getComponentParameter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const { handler } = changes;
        if(handler && !handler.isFirstChange()) {
            const previousHandler: DynamicComponentHandler = handler.previousValue;
            const currentHandler = handler.currentValue;

            if(previousHandler.getComponentParameter().id !== currentHandler.getComponentParameter().id)
                this.init()
        }
    }

    private init() {
        if(!this.viewInitialized) return;

        this.viewContainerRef.clear();
        const componentFactory = this.viewContainerRef.createComponent(
            this.parameter.component,
            {
                index: 0,
                projectableNodes: this.parameter.children,
            }
        );
        const instance: object = componentFactory.instance as object;

        if (isValidRef(this.parameter?.handler)) updateFieldSubset(instance, { handler: this.parameter.handler });
        if (isValidRef(this.parameter?.inputs)) updateFieldSubset(instance, this.parameter.inputs);

        this.parameter.onInit?.()
        this.cdRef.markForCheck();
        componentFactory.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit() {
        this.viewInitialized = true;
        this.init();
    }

    ngAfterViewChecked() {
        this.cdRef.markForCheck();
    }

}
