import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { IListNonSerializablesMatch } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EGeneratorTransactionType, IBotTransaction } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isValidArray } from "@colmeia/core/src/tools/utility";
import { useClientPredicates } from "@colmeia/core/src/tools/utility-types";
import { NsPickerModalComponent } from "app/components/dashboard/ns-picker/modal/modal/ns-picker-modal.component";
import {
    INSPickerHandlerClientCallback,
    IUseNsMatchByCustomerChoice,
    IUseNsMatchByCustomerChoiceConfig,
    NSPickerHandler,
} from "app/handlers/ns-picker/ns-picker.handler";
import { BotTransactionTypeTranslations } from "app/model/bot-transaction.model";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { ColmeiaDialogService } from "app/services/dialog/dialog.service";
import { T } from "lodash/fp";
import { BpmGraphNSPickerHandler } from "./bpm-graph-ns-picker.handler";

@Component({
    selector: "app-bpm-graph-ns-picker",
    templateUrl: "./bpm-graph-ns-picker.component.html",
    styleUrls: ["./bpm-graph-ns-picker.component.scss"],
})
export class BpmGraphNSPickerComponent
    implements OnInit, INSPickerHandlerClientCallback {
    @Input()
    public _handler: BpmGraphNSPickerHandler;
    public get handler(): BpmGraphNSPickerHandler {
        return this._handler;
    }
    public set handler(value: BpmGraphNSPickerHandler) {
        this._handler = value;
    }

    public nsPickerHandler: NSPickerHandler;

    constructor(
        private dashboardSvc: DashBoardService,
        private dialogSvc: ColmeiaDialogService,
        private dialogRef: MatDialogRef<BpmGraphNSPickerComponent>
    ) {}

    ngOnInit() {
        this.dialogRef.addPanelClass("no-padding");
        this.init();
    }

    private init() {
        const { ignoredNSsIds } = this.handler.getComponentParameter();

        const nsPickerHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: this.handler.getComponentParameter().nsType,
            clientCallback: this,
            idParent: null,
            useDemandedTag: false,
            maxSelections: 1,
            selectedIds: [],
        }, isValidArray(ignoredNSsIds) ? { filter: (NS) => !ignoredNSsIds.includes(NS.idNS)  } : {});

        console.log({nsPickerHandler, ignoredNSsIDS: isValidArray(ignoredNSsIds)})
        const parameters = nsPickerHandler.getComponentParameter();
        parameters.useNsMatchByCustomerChoice = this.getUseNsPropertyValueSelection((match: IListNonSerializablesMatch[] | undefined) => nsPickerHandler.setHandlerMatch(match))


        const ref: MatDialogRef<
            NsPickerModalComponent,
            NSPickerHandler
        > = this.dialogSvc.open<NsPickerModalComponent, NSPickerHandler>({
            componentRef: NsPickerModalComponent,
            hideHeader: true,
            dataToComponent: {
                data: nsPickerHandler,
                width: "500px",
            },
            panelClass: "modal-clear-white-background",
        });
    }

    onSaveCallback([ns]: INonSerializable[]) {
        this.handler
            .getDialogRef()
            .close({ nonSerializable: ns, userHasClickedSave: true });
    }

    onCancelCallback() {
        this.handler
            .getDialogRef()
            .close({ nonSerializable: undefined, userHasClickedSave: false });
    }

    getUseNsPropertyValueSelection(setHandlerMatch: (match: IListNonSerializablesMatch[] | undefined) => void): IUseNsMatchByCustomerChoice {
        const useNsMatchByCustomerChoice: IUseNsMatchByCustomerChoice = {
            allowPropertyValueSelection: true,
            config: this.getContentGeneratorFilterConfig(setHandlerMatch)
            
        }
        return useNsMatchByCustomerChoice
    }

    getContentGeneratorFilterConfig(setHandlerMatch: (match: IListNonSerializablesMatch[] | undefined) => void): IUseNsMatchByCustomerChoiceConfig {
		return {
			label: 'Tipo: ',
			property: 'botLevel',
			possibleValues: [
				{
					propertyTypeValue: 'Todos',
					translation: 'Todos'
				},
				{
					propertyTypeValue: ENextGenBotElementType.nlpTransaction,
					translation: Serializable.getTranslation(BotTransactionTypeTranslations.nlpTransaction)!
				},
				{
					propertyTypeValue: ENextGenBotElementType.formTransaction,
					translation: Serializable.getTranslation(BotTransactionTypeTranslations.formTransaction)!
				}
			],
			value: 'Todos',
			setMatch: (value: EGeneratorTransactionType | string) => {
				if (value === ENextGenBotElementType.nlpTransaction || value === ENextGenBotElementType.formTransaction) {
					const match = [useClientPredicates<IBotTransaction>()($ => ({ [$.botLevel]: value }))]
                    setHandlerMatch(match)
					return; 
				} 
                setHandlerMatch(undefined) // handle 'Todos' case

			},
		}
	}
}
