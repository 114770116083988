import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatRadioChange } from "@angular/material/radio";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import {
    EDeviceType,
    INotificationDevice,
    IPushNotificationPolicy,
    TNotificationDeviceArray
} from "@colmeia/core/src/business/push-notifications";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { hourToMS } from "@colmeia/core/src/time/time-utl";
import { getClock, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { getAmountOfDaysInAMonth } from 'app/model/client-utility';
import { IChangeInterfaceListener } from "../../../../model/signal/ps-interfaces";
import { HardwareLayerService } from "../../../../services/hardware";
import { PushNotificationsService } from "../../../../services/push-notifications.service";
import { SignalListenerService } from "../../../../services/signal/signal-listener";
import { SnackMessageService } from "../../../../services/snack-bar";
import { UserSettingsService } from "../../../../services/user-settings.service";
import { RootComponent } from "../../../foundation/root/root.component";
import { SettingsModalService } from "../../settings-modal/settings-modal.service";

export enum EDisableNotificationAmount {
    TwoHours = 1,
    OneDay = 2,
    OneMonth = 3,
    Undefined = 4
}

export interface IDeactivateProperties {
    sleepPlus: number;
}

export type TDeactivatePropertiesHash = {
    [key: number]: IDeactivateProperties;
}


@Component({
    selector: 'cm-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsComponent extends RootComponent<
    'activate' |
    'deactivate' |
    'devices' |
    'addDevice' |
    'placeholder' |
    'errSmallName' |
    'deac1' |
    'deac2' |
    'deac3' |
    'deac4' |
    'deacu'
> implements OnInit, OnDestroy, IChangeInterfaceListener {

    public EDisableNotificationAmount: typeof EDisableNotificationAmount = EDisableNotificationAmount;
    private static deactivateProps: TDeactivatePropertiesHash = {
        [EDisableNotificationAmount.TwoHours]: {
            sleepPlus: hourToMS(2),
        },
        [EDisableNotificationAmount.OneDay]: {
            sleepPlus: hourToMS(24),
        },
        [EDisableNotificationAmount.OneMonth]: {
            sleepPlus: hourToMS(24 * getAmountOfDaysInAMonth()),
        },
        [EDisableNotificationAmount.Undefined]: {
            sleepPlus: -1,
        }
    };
    private datePipe: DatePipe;
    private _policy: IPushNotificationPolicy;
    private UID: TGlobalUID;
    public updatingPolicy: boolean = false;
    public loadingModeChange: boolean = false;

    isNotificationsActive: boolean = false;
    disableMode: number = EDisableNotificationAmount.OneMonth;
    devices: TNotificationDeviceArray = [];
    showDisableMode: boolean = false;
    showDeactivateString: boolean = false;
    deactivateString = '';
    deviceName = '';
    isOnlyAllowedToVisualizeNotifications: boolean = false

    constructor(
        private notificationSVC: PushNotificationsService,
        private hw: HardwareLayerService,
        private settings: UserSettingsService,
        private snack: SnackMessageService,
        private modalSettingsSVC: SettingsModalService,
        private cdr: ChangeDetectorRef,
        private listener: SignalListenerService,
    ) {
        super({
            activate: gTranslations.fragments.activateNotifications,
            deactivate: gTranslations.fragments.deactivateNotifications,
            devices: gTranslations.fragments.devices,
            addDevice: gTranslations.fragments.addDevice,
            placeholder: gTranslations.fragments.devicePlaceHolder,
            errSmallName: gTranslations.fragments.deviceSmallName,
            deac1: gTranslations.fragments.deac1,
            deac2: gTranslations.fragments.deac2,
            deac3: gTranslations.fragments.deac3,
            deac4: gTranslations.fragments.deac4,
            deacu: gTranslations.fragments.deacu
        }, true, cdr);
        this.datePipe = new DatePipe(this.settings.getSelectedLocale());
    }

    ngOnInit() {
        this.init();
    }

    ngOnDestroy(): void {
    }

    async init() {
        this.isOnlyAllowedToVisualizeNotifications = !this.hw.isAllowedToChangeNotificationsState();
        this.devices = await this.modalSettingsSVC.getNotificationsDevices();
        console.log({ devices: this.devices });

        this._policy = await this.modalSettingsSVC.getNotificationsPolicy();
        console.log({ policy: this._policy, policyActive: this.policyActive });

        this.showDisableMode = !this.policyActive;
        this.showDeactivateString = !this.policyActive;
        if (this._policy && this.showDeactivateString) {
            this.deactivateString = this._policy.sleepUntil <= 0
                ? 'Indefinidamente'
                : this.datePipe.transform(
                    this._policy.sleepUntil,
                    `${this.settings.getDateFormat()} ${this.settings.getTwentyFourTimeFormat()}`
                );
        }

        this.deviceName = this.hw.getDeviceName();
        this.UID = this.hw.getDeviceUID();
    }

    get policyActive(): boolean {
        const result = isValidRef(this._policy) ? this._policy.sleepUntil === 0 : false;
        return result
    }

    async onDisableModeChange(evt: MatRadioChange): Promise<void> {
        this.disableMode = evt.value;
        this.loadingModeChange = true;

        await this.notificationSVC.updatePolicy(
            ((this.disableMode !== EDisableNotificationAmount.Undefined) ? getClock() : 0) + NotificationSettingsComponent.deactivateProps[this.disableMode].sleepPlus
        );
        this.loadingModeChange = false;
    }

    handlePolicyChange(event: MatSlideToggleChange) {
        event.source.writeValue(!event.source.checked);
        if (this.updatingPolicy) {
            return;
        }
        this.updatePolicy(event.checked);
    }

    private async updatePolicy(active: boolean) {
        this.showDeactivateString = false;
        this.updatingPolicy = true;

        if (active) {
            if (!(await this.canCreateDevice())) {
                this.snack.openError("É necessário habilitar as notificações para prosseguir!");
                this.updatingPolicy = false;
                return
            }

            await this.notificationSVC.updatePolicy(0);
        } else {
            const now: number = (this.disableMode === EDisableNotificationAmount.Undefined) ? 0 : getClock();
            await this.notificationSVC.updatePolicy(
                now + NotificationSettingsComponent.deactivateProps[EDisableNotificationAmount.OneMonth].sleepPlus
            );
        }

        this.updatingPolicy = false;
        this.showDisableMode = !active;
        this._policy = await this.notificationSVC.getPNPolicy();
        console.log({ newPolicy: this._policy });
    }

    async deactivateDevice($event: MatSlideToggleChange, device: INotificationDevice) {
        if ($event.checked) {
            const canReactivateDevice = await this.hw.beforeNotificationsActivate();
            if (canReactivateDevice) {
                await this.notificationSVC.reactivateDevice(device);
            } else {
                this.snack.openError("É necessário ativar as notificações!");
            }
        } else {
            await this.notificationSVC.deactivateDevice(device);
        }
    }

    async createDevice(): Promise<void> {
        console.log({ devices: this.devices, deviceName: this.deviceName, UID: this.UID });

        if (!(await this.canCreateDevice())) {
            this.snack.openError("É necessário habilitar as notificações para prosseguir!");
            return
        }
        const isAlreadyExistsDeviceWithSameName: boolean = this.devices.some(dev => dev.deviceName === this.deviceName)
        if (isAlreadyExistsDeviceWithSameName) {
            this.snack.openError("Ja existe um dispositivo com o mesmo nome, favor escolher outro!");
            return
        }
        const isAlreadyExistsDeviceWithSameId: boolean = this.devices.some(dev => dev.deviceUID === this.UID)
        if (isAlreadyExistsDeviceWithSameId) {
            this.snack.openError("Ja existe um dispositivo cadastrado com o mesmo ID!");
            return
        }

        const firebaseToken: string = await this.hw.getPNClientID()
        // console.log({ canCreateDevice, firebaseToken });
        if (!isValidString(firebaseToken)) {
            this.snack.openError("Nao foi possivel criar o token para as notificacoes!");
            return
        }

        await this.notificationSVC.createDevice(
            this.deviceName,
            this.hw.getDeviceUID(),
            firebaseToken,
            this.hw.isBrowser() ? EDeviceType.Browser : EDeviceType.Phone
        );
        this.devices = await this.notificationSVC.getDevices();
        this.cdr.markForCheck();
    }

    get canInsertCurrentDevice(): boolean {
        const isCurrentDeviceNotIncluded = !this.devices.some((val: INotificationDevice) => {
            return this.UID === val.deviceUID;
        });
        return isCurrentDeviceNotIncluded
    }

    get canSubmitNewDevice(): boolean {
        return this.deviceName.length > 3;
    }

    private canCreateDevice(): Promise<boolean> {
        return this.hw.beforeNotificationsActivate();
    }
}
