<div class="settings__container">
  <header>
    <h2>
      {{ translate(labels.settings) }}
    </h2>
    <button [mat-dialog-close]="true" tapFeedback="circular">
      <i class="material-icons">
        close
      </i>
    </button>
  </header>
  <hr>
  <div class="categories">
    <div class="categories__index">
      <ul>
        <li *ngFor="let category of categories; let i = index;"  (click)="switchToCategory(i)" class="category__title__container">
          <a [ngClass]="{'current--index': isCategoryActive(i)}" class="category__title" tapFeedback>
            {{ translate(category.title) }}
          </a>
        </li>
      </ul>
    </div>
    <div class="categories__wrapper">
      <section *ngFor="let category of categories; let i = index;" class="category__section" [ngClass]="{'hide-if-another-open': mustBeHidden(i), 'category__open': isCategoryOpen(i), 'category--active': isCategoryActive(i)}">
        <div class="category__header mobile-header" (click)="(isCategoryOpen(i)) ? closeCategory(i) : openCategory(i)" tapFeedback>
          <h3> {{ translate(category.title) }} </h3>
          <button>
            <i class="material-icons">{{ isCategoryOpen(i) ? 'chevron_left' : 'chevron_right'}}</i>
          </button>
        </div>
        <div class="category__content">
          <ng-container *ngComponentOutlet="category.component"></ng-container>
        </div>
      </section>
    </div>
  </div>
</div>
