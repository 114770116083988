import {Participant} from '../../business/participant';
import { Interaction } from '../interaction';
import { TGlobalUID, constant } from '../../business/constant';
import { InteractionType } from '../interaction-type';
import {
    IChainedInteractionJSON,
    IPurchaseConfirmationInteractionJSON
} from '../../comm-interfaces/interaction-interfaces';

import { ChainedInteraction } from '../chained-interaction';
import { IPublicMediumPresentable } from "../../comm-interfaces/billing-interfaces";

export class PurchaseConfirmation extends ChainedInteraction {

    private medium: IPublicMediumPresentable;

    private constructor(primaryID: TGlobalUID, participant: Participant, product: Interaction) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.products.purchase.confirmation), product);
    };

    public getProduct(): Interaction {
        return this.getInteractionParent();
    }

    public getPublicMedium(): IPublicMediumPresentable {
        return this.medium;
    }

    //
    public toJSON(): IPurchaseConfirmationInteractionJSON {
        return {
            ...super.toJSON(),
            medium: this.medium
        };
    };

    public rehydrate(json: IPurchaseConfirmationInteractionJSON): void {
        super.rehydrate(json);
        this.medium = json.medium;
    };

    public static staticFactory(idPurchaseConfirmation: TGlobalUID): PurchaseConfirmation {
        return <PurchaseConfirmation>Interaction.staticFactory(idPurchaseConfirmation);
    };

    public static factoryMessage(json: IChainedInteractionJSON, participant: Participant = null): PurchaseConfirmation {
        let confirmation: PurchaseConfirmation = <PurchaseConfirmation> ChainedInteraction.searchCachedInteraction(json.primaryID);
        if (confirmation == null) {
            confirmation = new PurchaseConfirmation(
                json.primaryID,
                participant || Participant.staticFactory(json.idParticipant),
                Interaction.staticFactory(json.idInteractionParent)
            );
        }
        confirmation.rehydrate(<IPurchaseConfirmationInteractionJSON>json);
        return confirmation;
    }
};