import { Injectable } from "@angular/core";
import { IShortMessage } from "@colmeia/core/src/shared-business-rules/small-message/shrink-message";

@Injectable({
    providedIn: 'root'
})
export class NotificationsBag {

    private _notifications: Array<IShortMessage> = [];

    constructor() {}

    public add(notification: IShortMessage) {
        this._notifications.push(notification);
    }

    public all(): Array<IShortMessage> {
        return this._notifications;
    }

    public get length(): number {
        return this._notifications.length;
    }
}
