import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[alphanumeric]'
})
export class AlphanumericDirective {

    regexStr = '^[A-Za-z0-9_]*$';
    @Input() isAlphanumeric: boolean;

    constructor(private el: ElementRef) { }

    @HostListener('keypress', ['$event']) onKeyPress(event) {
        return new RegExp(this.regexStr).test(event.key);
    }
    
    @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
        this.validateFields(event);
    }
    
    validateFields(event: ClipboardEvent) {
        setTimeout(() => {
            this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
            event.preventDefault();
        }, 100);
    }
}
