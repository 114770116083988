import { RemoveIgnoredFromClass } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services";
import { coreImplementation, createNameBuilderFromTarget } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services.basic-functions";
import { getClassNames } from "@colmeia/core/src/tools/utility/functions/getClassNames";


const mapTargetKeys: Map<{}, Set<string>> = new Map();


export function toStaticHelper<T extends {}>(source: new () => T): RemoveIgnoredFromClass<T, never> & (new () => RemoveIgnoredFromClass<T>);
export function toStaticHelper(source: { [key in string]: any } & { new(): { [key in string]: {} } }) {
    if (mapTargetKeys.has(source)) return source;

    const instance = new source()
    const items = getClassNames(instance, { hasInstanceNames: true, hasStaticNames: true })
    const set = new Set([...items, ...Object.keys(instance)])

    const $super = getSuper(source);

    const superSet = mapTargetKeys.get($super);
    if (superSet) {
        superSet.forEach(item => set.add(item));
    }

    set.forEach(key => source[key] ??= instance[key]);

    mapTargetKeys.set(source, set);
    // 
    return source
}

export type AddFnName<
    T extends {},
    Name extends string = string,
    Output = T & { [key in keyof T]: { fnName: `${Name}.${key & string}` } },
> = 
    Output
;

type ToStatic<
    T extends {},
    Computed = RemoveIgnoredFromClass<T, never> & (new () => RemoveIgnoredFromClass<T>),
> = 
    Computed
;
export function addFnNameOnSource<T extends {}>(source: T): AddFnName<T>;
export function addFnNameOnSource<T extends {}>(source: T): T {
    return source;
}
export function toStatic<T extends {}>(source: new () => T): ToStatic<T> {
    return toStaticHelper(source);
}

export function getSharedServiceKeys(service: {}) {
    return mapTargetKeys.get(service);
}

function getSuper<T>(target: T) {
    return Object.getPrototypeOf(target)
}

export function ToStatic<T>(target: new () => T) {
    const buildName = createNameBuilderFromTarget(target);
    const properties = getClassNames(target, { hasStaticNames: true })
    const names = properties.map(buildName);
    names.forEach(coreImplementation);
    toStaticHelper(target)
}