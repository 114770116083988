import { EBotContentEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { EAdditionalBotObjects, EAdditionalInternalType, EAdditionalNestedAIActions } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";

export type TBotActionTypeArray = Array<EBotActionType>;

export enum EBotActionType {
    goHuman = 'goHuman', // Encaminha atendente
    isEnd = 'isBotEnd', // fim do bot
    goFirstTree = 'goFirstAction', // volta para primeira questao
    goActionTree = 'goActionTree', // vai para próxima pergunta
    goBot = 'actType.goBot',  // Vai para root de um bot
    // NÃO MOSTRO NO BOT
    contentGenerator = 'transaction',  // Vai para Transação
    /**
     * @deprecated Use goHuman instead
     */
    goIsland = 'goIsland',
    // Novo Tipo
    exitCommand = 'exitCommand',
    yesOrNoAction = 'actYN',

    //LGPD = 'LGPD',  

    LGPD = 'botActionLGPD',

    yesAnswer = 'yes',
    noAnswer = 'no',

    // goBot é VÁLIDA
    quickContinue = 'quickCont', // só vizível no quick reply do template
    quickCallPhone = 'quickCallPhone',
    quickVisitWebsite = 'quickVisitSite',

    // lto button types
    copyCode = "copyCode",

    continueFlow = 'contFlow',
    redoAnswer = 'redoAnswer', // Faz com o que usuário responda novamente a pergunta.

    goBack = 'goBackBot', // Volta uma questão

    reuseForm = 'reuseform',
    fillForm = 'fillform',
    goIslandOnTemplate = 'goIslOnTmplt',
    gotoProperty = 'goProp', // Voltar para um campo

    upsertTicket = 'upsrtTicket',
    otpCopyCode = 'otpCopyCode', // Auth copy Code Button
};

export const botActionTypeToNserMapping: Partial<Record<EBotActionType, ENonSerializableObjectType>> = {
    [EBotActionType.contentGenerator]: ENonSerializableObjectType.contentGenerator,
    [EBotActionType.goBot]: ENonSerializableObjectType.bot,
    [EBotActionType.goActionTree]: ENonSerializableObjectType.bot,
}


export type TMarketingAction =
    | EBotActionType.quickCallPhone
    | EBotActionType.quickVisitWebsite
    | EBotActionType.quickContinue
    | EBotActionType.goBot
    | EBotActionType.LGPD
    | EBotActionType.goIslandOnTemplate
    | EBotActionType.otpCopyCode
    | EBotActionType.copyCode
;


export enum ENodeAssetIcons {
    edit = 'edit',
}

export type TNodeAssetIcons =
    | ENodeAssetIcons // editar elemento
    | EBotActionType.goHuman // encaminhar a atendente
    | EBotActionType.contentGenerator // gerador de conteúdo
    | EBotActionType.goBot // vai para o root de um bot
    | EBotActionType.goActionTree // ir para um próximo menu
    | EBotContentEvent.preContent // pré conteúdo
    | EBotContentEvent.posContent // pós contéudo
    | EBotActionType.goFirstTree // voltar para primeira questão
    | EBotActionType.goBack // voltar uma questão
    | EBotActionType.isEnd // fim do bot
    | EBotActionType.LGPD
    | EAdditionalBotObjects.menuItemConditionalDisplay // exibição condicional
    | EAdditionalNestedAIActions.openIntentsTab // exibir intenção
    | EAdditionalInternalType.Timer