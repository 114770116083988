import { ILocalNotificationsHardwareAbstraction } from "../hardware-interfaces";
import { IShortMessage } from "@colmeia/core/src/shared-business-rules/small-message/shrink-message";
import { Injectable } from "@angular/core";
import { Window } from './cordova-plugins-definitions';

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class CordovaLocalNotification implements ILocalNotificationsHardwareAbstraction{
    notificate(short: IShortMessage) {
        window.cordova.plugins.notification.local.schedule({
            title: short.fromName,
            text: short.text,
            foreground: true
        });
    }
}
