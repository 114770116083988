<h3 mat-dialog-title>Múltipla ocorrência</h3>
<mat-dialog-content class="multiple-form-tpl">
  <h5>Mínimo</h5>
  <div class="element-size-container">
    <mat-form-field class="item" appearance="fill">
      <mat-label>Modo</mat-label>
      <mat-select [(ngModel)]="formLoopMinUseMode" (ngModelChange)="formLoopMinElementSizeChange($event)">
        <mat-option value="fixed">
          Fixo
        </mat-option>
        <mat-option value="dynamic">
          Dinâmico
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="formLoopMinUseMode === 'fixed'" appearance="fill"  class="item">
      <mat-label>Mínimo</mat-label>
      <input type="number" required min="0" matInput [(ngModel)]="multipleCallsConfig.minimumElements">
    </mat-form-field>

    <app-ns-picker *ngIf="formLoopMinUseMode === 'dynamic'"
      class="item"
      [handler]="multipleCallMinElementsLocalCanonical">
    </app-ns-picker>
  </div>
  <h5>Máximo</h5>
  <div class="element-size-container">
    <mat-form-field class="item" appearance="fill">
      <mat-label>Modo</mat-label>
      <mat-select [(ngModel)]="formLoopMaxUseMode" (ngModelChange)="formLoopMaxElementSizeChange($event)">
        <mat-option value="fixed">
          Fixo
        </mat-option>
        <mat-option value="dynamic">
          Dinâmico
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="formLoopMaxUseMode === 'fixed'" appearance="fill"  class="item">
      <mat-label>Máximo</mat-label>
      <input type="number" required min="0" matInput [(ngModel)]="multipleCallsConfig.maxElements">
    </mat-form-field>

    <app-ns-picker *ngIf="formLoopMaxUseMode === 'dynamic'"
      class="item"
      [handler]="multipleCallMaxElementsLocalCanonical">
    </app-ns-picker>
  </div>

  <h5 class="asset-title">Conteúdo pós término do Subformulário</h5>
  <asset-adder [handler]="posNestedContent"></asset-adder>

  <mat-divider></mat-divider>

  <h5 class="asset-title">Título <span>*</span></h5>
  <asset-adder [handler]="titleAssetHandler"></asset-adder>

  <h5 class="asset-title">Sim <span>*</span></h5>
  <asset-adder [handler]="yesAssetHandler"></asset-adder>

  <h5 class="asset-title">Não <span>*</span></h5>
  <asset-adder [handler]="noAssetHandler"></asset-adder>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">Fechar</button>
  <button mat-flat-button color="primary" (click)="save()">Salvar</button>
</mat-dialog-actions>
