import { Serializable } from "@colmeia/core/src/business/serializable";
import { ETypeOfContact } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { EChatBtnsVisualElements, EStartServiceChatMoreOptions } from "@colmeia/core/src/core-constants/constant-visual-elements.enums";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { StartServiceChat } from "@colmeia/core/src/interaction/service-group/start-service-chat";
import { TSerializableArray } from "@colmeia/core/src/persistency/uber-cache";
import { isPanic } from "@colmeia/core/src/rules/inter-classification";
import { IMessageInstanceParameter, MessageInstanceHandler } from "../message-instance-handler";

export class GroupServiceMessageHandler extends MessageInstanceHandler {
    // static availableOpenActiveCallContactTypes: ETypeOfContact[] = [
    //     ETypeOfContact.email, ETypeOfContact.mobile
    // ]

    constructor(private messageParameter: IMessageInstanceParameter){
        super(messageParameter);
    }

    getMoreOptions(): TSerializableArray{
        // const interaction: StartServiceChat = (<StartServiceChat>this.getInteraction());
        // const chatItems: TSerializableArray = [...this.getFilteredOptions()];
        // const attendanceInteraction: Interaction = this.messageParameter.interaction;
        // const avatarCheckedContactsTypes: ETypeOfContact[] = attendanceInteraction.getParticipant().getAvatar().getContactsTypes();
        // const isAbleToOpenActiveCall: boolean = avatarCheckedContactsTypes.some(
        //     contactType => GroupServiceMessageHandler.availableOpenActiveCallContactTypes.includes(contactType)
        // );
        // const isCurrentStartAttendingCard = !isPanic(interaction.toJSON()) && !interaction.isFinished(this.getGroupID());

        // if (isCurrentStartAttendingCard && isAbleToOpenActiveCall) {
        //     chatItems.push(Serializable.staticFactory(EStartServiceChatMoreOptions.OpenActiveCall));
        // }

        // return chatItems;
        return [];
    }

    private getFilteredOptions(): TSerializableArray {
        return [
            ...super.getMoreOptions()
                .filter(option => option.is(
                    EChatBtnsVisualElements.Reply,
                    EChatBtnsVisualElements.BigIconOptions
                ))
        ];
    }
}
