import { Injectable } from '@angular/core';
import { ICustomNSPicker } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { ENextGenBotElementType, INextGenBotServer } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { BotRootHostNode } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/bot/bot.root.host';
import { HostNodeFactory } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/factory/host-node.factory';
import { EAdditionalPredicateType } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { TBasicElementArray } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element';
import { BasicElementInterfaceFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element-interface.factory';
import { BasicElementFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element.factory';
import { IBasicElementServer } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces';
import { GraphElement } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-element';
import { IGraphElementJSON, IPredicateElementJSON } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-interfaces';
import { GraphRoot } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-root-element';
import { EGraphElementType, EPredicateType, gDefaultRenderData, TGraphElementArray } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-types';
import { GraphPredicate } from '@colmeia/core/src/shared-business-rules/graph/essential/predicate';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { flat } from '@colmeia/core/src/tools/utility';
import { BpmGraphViewerService } from 'app/components/dashboard/ai-pages/bpm-graph/bpm-graph-viewer/services/bpm-graph-viewer.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { BpmApiService } from '../api/bpm-bot-api.service';

type THostedIdToGraphNodeMap = { [id: string]: GraphElement; }

@Injectable({
    providedIn: 'root'
})
export class BpmRulesBotImportGraphService {

    constructor(
        private dashboardSvc: DashBoardService,
        private bpmApiSvc: BpmApiService,
        private bpmViewerSvc: BpmGraphViewerService
    ) { }

    async performGraphImportSteps(bpmType: EBPMType, idBotRoot: string, idRootGraphElement: string) {
        const { botRoot, botElementsWithoutRoot } = await this.getAllBotList(idBotRoot)

        const botRootGraphElement = this.createGraphNode(bpmType, EGraphElementType.root,
            { bot: botRoot, graphElementServer: this.bpmViewerSvc.rootElementSaved })
        const graphNodesToSave: GraphElement[] = botElementsWithoutRoot.map(botElement => {
            return this.createGraphNode(bpmType, EGraphElementType.node, { bot: botElement, idBotRoot })
        })

        const allNodesToCreatePredicates: TGraphElementArray = flat([[botRootGraphElement], graphNodesToSave])
        const predicates: TBasicElementArray = await this.createPredicatesForNodes(bpmType, allNodesToCreatePredicates)
        const allGraphElements: TBasicElementArray = flat([predicates as TBasicElementArray, graphNodesToSave as TBasicElementArray])

        const createdElements = await this.bpmApiSvc.persistGraphElements(bpmType, allGraphElements, idRootGraphElement)
        console.log("🚀 ~ file: bpm-graph-viewer.component.ts ~ line 368 ~ performImportSteps ~ createdElements", createdElements, graphNodesToSave.map(g => g.getHostedObject()))
        return createdElements
    }

    private async getAllBotList(idBotRoot: string) {
        const customSearch: ICustomNSPicker = {
            lookForParentGenealogy: true,
            isMustIncludeParent: true
        };
        const botElements: INextGenBotServer[] = (await this.dashboardSvc.genericNonSerializableService
            .getChildren(idBotRoot, undefined, undefined, null, customSearch))
            .filter(element => element.nsType === ENonSerializableObjectType.bot) as INextGenBotServer[]
        const botRoot = botElements.find(bot => bot.botLevel == ENextGenBotElementType.root)
        const botElementsWithoutRoot = botElements.filter(bot => bot.botLevel != ENextGenBotElementType.root && bot.botLevel != ENextGenBotElementType.metadata)

        return { botRoot, botElementsWithoutRoot }
    }

    private createGraphNode(bpmType, type: EGraphElementType, info: {
        bot: INextGenBotServer, isExternalElement?: boolean,
        graphElementServer?: IBasicElementServer, idBotRoot?: string
    }): GraphElement {
        if (info.graphElementServer) {
            const element = info.graphElementServer
            return BasicElementFactory.createGraphNode(type, {
                graphJSON: element.element,
                name: info.graphElementServer.nName,
                hostObject: HostNodeFactory.create({ bpmType, ns: info.bot }),
            }) as GraphRoot
        } else {
            const basicElement = BasicElementInterfaceFactory.create(type,
                {
                    name: info.bot.nName,
                    idParent: info.idBotRoot,
                    renderData: gDefaultRenderData
                }).element
            return BasicElementFactory.createGraphNode(type, {
                name: basicElement.name,
                hostObject: HostNodeFactory.create({ bpmType, ns: info.bot }),
                graphJSON: <IGraphElementJSON>{
                    ...basicElement,
                    isExternalElement: info.isExternalElement
                },
            }) as GraphElement
        }
    }

    private async createPredicatesForNodes(bpmType, graphNodes: GraphElement[]): Promise<TBasicElementArray> {
        console.log("🚀 ~ file: bpm-rules-bot-import-graph.service.ts ~ line 89 ~ BpmRulesBotImportGraphService ~ createPredicatesForNodes ~ graphNodes", graphNodes)
        let createdPredicates: TBasicElementArray = []
        const hostedIdToGraphNodeMap: THostedIdToGraphNodeMap = graphNodes.reduce((map, obj) => {
            map[obj.getHostedID()] = obj;
            return map;
        }, {});

        for (const srcNode of graphNodes) {
            switch (srcNode.getHostedType()) {
                case ENextGenBotElementType.root: {
                    createdPredicates = createdPredicates.concat(
                        await this.generatePredicateForAction(bpmType, srcNode, hostedIdToGraphNodeMap))
                    break;
                }
                case ENextGenBotElementType.botMenuItem: {
                    createdPredicates.push(this.generatePredicateFromMenuContainerToMenuItem(srcNode, hostedIdToGraphNodeMap))
                    createdPredicates = createdPredicates.concat(
                        await this.generatePredicateForAction(bpmType, srcNode, hostedIdToGraphNodeMap))
                    break;
                }
                case ENextGenBotElementType.menuContainer: {
                    break;
                }
                case ENextGenBotElementType.menuContainer: break;
                default:
                    throw new Error("🚀 ~ file: bpm-graph-viewer.service.ts ~ line 91 ~ BpmGraphViewerService ~ createPredicates ~ error generating predicates!!!");
            }
        }

        return createdPredicates
    }

    private generatePredicateFromMenuContainerToMenuItem(menuItem: GraphElement, hostedIdToGraphNodeMap: THostedIdToGraphNodeMap): GraphPredicate {
        const menuItemParentHostedId = menuItem.getHostObject().getNonSerializable().idParent
        const menuContainer = hostedIdToGraphNodeMap[menuItemParentHostedId]
        const basicElement = BasicElementInterfaceFactory.createEmptyPredicate()

        const predicateJson = <IPredicateElementJSON>{
            ...basicElement.element,
            fromElementId: menuContainer.getGraphElementID(),
            toElementId: menuItem.getGraphElementID(),
            businessPredicateType: EAdditionalPredicateType.Parent,
            inputName: menuContainer.getName(),
            outputName: menuItem.getHostedName(),
            predicateType: EPredicateType.directed,
        }
        const predicate: GraphPredicate = BasicElementFactory.createGraphNode(EGraphElementType.predicate, {
            graphJSON: predicateJson
        })
        return predicate
    }

    private async generatePredicateForAction(bpmType, node: GraphElement, hostedIdToGraphNodeMap: THostedIdToGraphNodeMap): Promise<TBasicElementArray> {
        const rootHosted = node.getHostObject() as BotRootHostNode
        const actionContainer = rootHosted.getActionContainer()[0]
        const action = actionContainer.action;

        if (action?.type == EBotActionType.goBot) {
            return await this.generatePredicateForActionGoBot(bpmType, node)
        }

        if (action?.type == EBotActionType.goActionTree) {
            const nodeThatIPointTo = hostedIdToGraphNodeMap[action.idElement]
            if (!nodeThatIPointTo) {
                return []
            }

            const basicElement = BasicElementInterfaceFactory.createEmptyPredicate()
            const predicateJson = <IPredicateElementJSON>{
                ...basicElement.element,
                businessPredicateType: action.type,
                fromElementId: node.getGraphElementID(),
                toElementId: nodeThatIPointTo.getGraphElementID(),
                inputName: node.getHostedName(),
                outputName: nodeThatIPointTo.getName(),
                predicateType: EPredicateType.directed,
            }

            const predicate: GraphPredicate = BasicElementFactory.createGraphNode(EGraphElementType.predicate, {
                graphJSON: predicateJson
            }) as GraphPredicate
            return [predicate]
        }

        return []
    }

    async generatePredicateForActionGoBot(bpmType, node: GraphElement) {
        const rootHosted = node.getHostObject() as BotRootHostNode
        const actionContainer = rootHosted.getActionContainer()[0]
        const menuItem = node
        const action = actionContainer.action
        const idBotRootThatMenuItemPointsTo = action.idElement
        const { botRoot, } = await this.getAllBotList(idBotRootThatMenuItemPointsTo)

        const rootExternalBasicElement = this.createGraphNode(bpmType, EGraphElementType.node, { bot: botRoot, isExternalElement: true })

        const basicElement = BasicElementInterfaceFactory.createEmptyPredicate()
        const predicateJson = <IPredicateElementJSON>{
            ...basicElement.element,
            businessPredicateType: action.type,
            inputName: menuItem.getHostedName(),
            fromElementId: menuItem.getGraphElementID(),
            outputName: rootExternalBasicElement.getName(),
            toElementId: rootExternalBasicElement.getGraphElementID(),
            predicateType: EPredicateType.directed,
        }

        const predicate: GraphPredicate = BasicElementFactory.createGraphNode(EGraphElementType.predicate, {
            graphJSON: predicateJson
        }) as GraphPredicate
        return [predicate, rootExternalBasicElement]
    }
}
