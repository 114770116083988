import { IMessageInstanceParameter, MessageInstanceHandler } from "./message-instance-handler";
import { constant } from "@colmeia/core/src/business/constant";
import { EGeolocationIntType, EMessageStandard } from "@colmeia/core/src/business/constant.enums";
import { BeginRealtimeTrackingMessageHandler } from "./gps-message/begin-realtime-tracking-message-handler";
import { EndRealtimeTrackingMessageHandler } from "./gps-message/end-realtime-tracking-message-handler";
import { NotificationMessageHandler } from "./notification/notification-message-handler";
import { NotificationFeedbackMessageHandler } from "./notification/notification-feedback-message-handler";
import { TimedMessageHandler } from "./notification/timed-message-handler";
import { GroupServiceMessageHandler } from "./service-message/group-service-message-handler";
import { NoOptionsMessageHandler } from "./no-options-message-instance-handler";
import { FeatureCarrierMessageHandler } from "./form/feature-carrier-message.handler";

export function factoryMessageHandler(messageParameter: IMessageInstanceParameter): MessageInstanceHandler {
    switch (messageParameter.interaction.getInteractionType().getPrimaryID()) {

        // Feature Carrier
        case constant.interactionType.standard.featureCarrier:
            return new FeatureCarrierMessageHandler(messageParameter);

        // GPS
        case EGeolocationIntType.beginRealtimeTracking:
            return new BeginRealtimeTrackingMessageHandler(messageParameter);
        case EGeolocationIntType.endRealTimeTracking:
            return new EndRealtimeTrackingMessageHandler(messageParameter);

        // Service
        case constant.interactionType.serviceGroup.startServiceChat:
            return new GroupServiceMessageHandler(messageParameter);

        // Notification
        case constant.interactionType.subscription.subscription:
            return new NotificationMessageHandler(messageParameter);
        case constant.interactionType.subscription.stdResponse:
            return new NotificationFeedbackMessageHandler(messageParameter);
        case constant.interactionType.special.wrapperInteraction:
            return new TimedMessageHandler(messageParameter);
        case EMessageStandard.message:
        case EMessageStandard.citation:
            return new MessageInstanceHandler(messageParameter);

        default:
            console.log('not treated interactionType: ', messageParameter.interaction.getInteractionType().getPrimaryID());
            return new NoOptionsMessageHandler(messageParameter);
    }
}
