import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";
import { MatFormFieldControl } from "@angular/material/form-field";
import "quill";
import { _MatQuillBase } from "./mat-quill-base";

// Increasing integer for generating unique ids for mat-quill components.
let nextUniqueId = 0;

const SELECTOR = "mat-quill";

@Component({
    selector: SELECTOR,
    exportAs: "matQuill",
    template: ` <div quill-editor-element readOnly="readOnly"></div> `,
    inputs: ["disabled"],
    providers: [{ provide: MatFormFieldControl, useExisting: MatQuill }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatQuill extends _MatQuillBase {
    controlType = SELECTOR;
    @Input() readOnly: boolean = false;
    @HostBinding() id = `${SELECTOR}-${nextUniqueId++}`;

    static ngAcceptInputType_disabled: boolean | string | null | undefined;
    static ngAcceptInputType_required: boolean | string | null | undefined;
}
