import { Injectable, Inject } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import {ChatActionBarStateServiceID, IChatBarResetProvider} from "../../../model/chat-action-bar";

@Injectable({
    providedIn: 'root'
})
export class ChatActionBarService {
    constructor(
        @Inject(ChatActionBarStateServiceID) private chatBarResetProviderSvc: IChatBarResetProvider,
    ) {}

    chatBarReseted(currentInteractionID: TGlobalUID): void {
        this.chatBarResetProviderSvc.chatBarReseted(currentInteractionID)
    }
}
