import { Component, OnDestroy, OnInit } from '@angular/core';
import { EFeatures } from "@colmeia/core/src/business/features";
import { Serializable } from '@colmeia/core/src/business/serializable';
import { settingsViewScreenOptions } from "@colmeia/core/src/shared-business-rules/const-text/views/settings";
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import { IChangeInterfaceListener } from "../../../model/signal/ps-interfaces";
import { ContractService } from "../../../services/controllers-services/contract-services/contract.service";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { AvatarInfoEditComponent } from '../tabs/avatar-info-edit/avatar-info-edit.component';
import { LanguageSelectComponent } from "../tabs/language-select/language-select.component";
import { LocationSettingsComponent } from "../tabs/location-settings/location-settings.component";
import { NotificationSettingsComponent } from "../tabs/notification-settings/notification-settings.component";
import { PasswordSettingsComponent } from '../tabs/password-settings/password-settings.component';
import { PaymentMediumCreatorComponent } from "../tabs/payment-medium-creator/payment-medium-creator.component";
import { SocialBindComponent } from "../tabs/social-bind/social-bind.component";
import { WalletSettingsComponent } from "../tabs/wallet-settings/wallet-settings.component";
import { SettingsModalService } from './settings-modal.service';

export interface SettingTab {
    title: string | number;
    component: any;
    feature: EFeatures;
}

export type SettingsTab = Array<SettingTab>;

@Component({
    selector: 'cm-settings-modal',
    templateUrl: './settings-modal.component.html',
    styleUrls: ['./settings-modal.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsModalComponent implements OnInit, OnDestroy, IChangeInterfaceListener {
    private activeCategory = 0;
    private openCategories: Set<string> = new Set();
    translatedLabels = {};
    labels: { [key: string]: number } = {
        settings: settingsViewScreenOptions.fields.settings,
        bind: settingsViewScreenOptions.fields.bind,
        notifications: settingsViewScreenOptions.fields.notifications,
        language: settingsViewScreenOptions.fields.language,
        payment: settingsViewScreenOptions.fields.payment,
        location: settingsViewScreenOptions.fields.location,
        wallet: settingsViewScreenOptions.fields.wallet,
        informations: settingsViewScreenOptions.fields.informations,
        changePassword: settingsViewScreenOptions.fields.changePassword
    };
    private _categories: SettingsTab = [
        {
            title: this.labels.informations,
            component: AvatarInfoEditComponent,
            feature: EFeatures.Informations
        },
        {
            title: this.labels.bind,
            component: SocialBindComponent,
            feature: EFeatures.SocialBind
        },
        {
            title: this.labels.payment,
            component: PaymentMediumCreatorComponent,
            feature: EFeatures.Billing
        },
        {
            title: this.labels.language,
            component: LanguageSelectComponent,
            feature: EFeatures.Rosetta
        },
        {
            title: this.labels.notifications,
            component: NotificationSettingsComponent,
            feature: EFeatures.Notification
        },
        {
            title: this.labels.location,
            component: LocationSettingsComponent,
            feature: EFeatures.Location
        },
        {
            title: this.labels.wallet,
            component: WalletSettingsComponent,
            feature: EFeatures.Wallet
        },
        {
            title: this.labels.changePassword,
            component: PasswordSettingsComponent,
            feature: EFeatures.changePassword
        }
    ];

    constructor(
        private listener: SignalListenerService,
        private settingsModal: SettingsModalService,
        private contract: ContractService
    ) {
        // this.categories = this.contract.getSettingsTabFeature();
    }

    async ngOnInit() {
        this.translateLabels();
        this.listener.listenToInterfaceChanges(this);
        console.log('settings bef');
        const settings = await this.settingsModal.getUserSettings();
        console.log('settings aftr');
    }

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (sign.languageChanged()) {
            this.translateLabels();
        }
    }

    get categories(): SettingsTab {
        return this._categories.filter(
            f => this.contract.isAllowedFeature(f.feature)
        );
    }

    public translate(key: string | number): string {
        return this.translatedLabels[key] || ``;
    }

    private translateLabels() {
        const serializable = Serializable.staticFactory(settingsViewScreenOptions.idSerializable);
        Object.keys(this.labels)
            .forEach(key => {
                this.translatedLabels[this.labels[key]] = serializable.getSerializableText(this.labels[key]);
            });
    }

    switchToCategory(category: number) {
        this.activeCategory = category;
    }

    isCategoryActive(category: number): boolean {
        return category === this.activeCategory;
    }

    openCategory(category: string) {
        this.openCategories.add(category);
    }

    closeCategory(category: string) {
        this.openCategories.delete(category);
    }

    isCategoryOpen(category: string): boolean {
        return this.openCategories.has(category);
    }

    mustBeHidden(category: string) {
        return (this.openCategories.size)
            ? !this.openCategories.has(category)
            : false;
    }
}
