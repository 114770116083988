import {TGlobalUID} from './constant';
import {TEmployeeSNArray, IClientInfoPlayer, IPlayerAccessCached}
 from '../comm-interfaces/barrel-comm-interfaces';
import { isValidRef } from '../tools/utility';
import { IPushNotificationPolicy, TNotificationDeviceArray } from './push-notifications';
import { PlayerAccessCached } from './player-access';


export class PlayerCachedInfo extends PlayerAccessCached {
    private clientInfo: IClientInfoPlayer;

    constructor(playerInfo: IPlayerAccessCached, throwIfNotFound: boolean = true) {
        super(playerInfo, throwIfNotFound);
        this.clientInfo = <IClientInfoPlayer>{};
    };

    public setClientInfo(clientInfo: IClientInfoPlayer): void {this.clientInfo = clientInfo; };

    public isTrackingOn(): boolean {return isValidRef(this.clientInfo.surrogate); };
    public getSurrogate(): TGlobalUID {return this.clientInfo.surrogate; };
    public setSurrogate(surrogate: string): void {this.clientInfo.surrogate = surrogate; };
    public isOnlineTrackingOn(): boolean {return this.clientInfo.isOnlineLoggingGPS; };

    public hasLastSurrogateStatus(): boolean {return isValidRef(this.clientInfo.lastSurrogateClock); };
    public getLastSurrogateStatys(): number {return this.clientInfo.lastSurrogateClock ;};

    public getPlayerInfoJSON(): IPlayerAccessCached {return <IPlayerAccessCached>super.getPlayerInfoJSON()};

    public getEmployers(): TEmployeeSNArray {
        return this.clientInfo.employerInfo;
    };

    public isEmployedBy(idSocialContext: TGlobalUID): boolean {
        return this.clientInfo.employerInfo.some((e) => {return e.idSocialNetwork === idSocialContext});
    };

    public getNotificationPolicy(): IPushNotificationPolicy {
        return this.clientInfo.notificationPolicy;
    };

    public getAllActiveDevices(): TNotificationDeviceArray {
        return this.clientInfo.devices;
    }

 };


