import { Location } from "@angular/common";
import { Injectable, NgZone } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { constant } from "@colmeia/core/src/business/constant";
import { Group } from "@colmeia/core/src/business/group";
import { Player } from "@colmeia/core/src/business/player";
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { EInitMode } from "@colmeia/core/src/business/security";
import { EEventName, IAuthToken } from "@colmeia/core/src/client-shared/client-native.model";
import { IPlayerJSON } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { IPlayerAccessCached } from "@colmeia/core/src/comm-interfaces/ds-interfaces";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import { TApiRequestType, apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {
    IForgotPassData,
    IForgotPassFirstStep, IForgotPassSecondStep,
    ILoginRequest,
    IRequest, ISignUpConfirmationData, ISignUpFirstStepData,
    ISignUpRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IForgotPassResponse, INavigationResponse, IResponse, ISignUpResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IEmbeddedChatLogin } from "@colmeia/core/src/request-interfaces/thirdparty-interfaces";
import { sanitizeEmail } from "@colmeia/core/src/shared-business-rules/const-text/views/canonical";
import { encryptPlayerPassword } from "@colmeia/core/src/shared-business-rules/password/password-functions";
import { getClock, getIfNotValid, isAllValid, isInvalid, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { DeployedServicesService } from "app/components/dashboard/dashboard-deployed-services/deployed-services.service";
import { SharedService } from "app/components/dashboard/shared-service/shared.service";
import { createServiceLogger } from "app/model/client-utility";
import { BillingNSService } from "app/services/billing-ns.service";
import { HelpTipsService } from "app/services/help-tips.service";
import { RouteCreator } from "app/services/routing-builder";
import { filter, take } from 'rxjs/operators';
import { MessageHandlerCallbak } from "../../handlers/message-instance-handler/message-handler-callback";
import { ChatBackboneModel } from "../../model/chat-backbone.model";
import { IInfraParameters } from "../../model/client-infra-comm";
import { ClientInfraResponse } from "../../model/component/client-infra-comm";
import { clientConstants } from "../../model/constants/client.constants";
import { routeID } from "../../model/routes/route-constants";
import { LoginSignal } from "../../model/signal/cookie-login-sign";
import { LogoutSignal } from "../../model/signal/logout-sign";
import { ISocketReadyListener } from "../../model/signal/ps-interfaces";
import { SocketReadySignal } from "../../model/signal/socket-ready-sign";
import { AttendanceService } from "../attendance.service";
import { BackgroundSender } from "../background-sender";
import { NativeAppCommunicationService } from "../colmeia-mobile-app/native-app-communication.service";
import { ContractService } from "../controllers-services/contract-services/contract.service";
import { NavigatorServices } from "../controllers-services/navigator/navigator.service";
import { DashboardMacroService } from "../dashboard-macro.service";
import { DashBoardService } from "../dashboard/dashboard.service";
import { ColmeiaDialogService } from "../dialog/dialog.service";
import { EmbeddedChatService } from "../embedded-chat.service";
import { FullScreenVideoService } from "../fullscreenvideo.service";
import { GeneralFormService } from '../general-form.service';
import { GeolocationHandlerInteractorService } from "../geolocation-handler-interactor.service";
import { GroupChatServices } from "../group-chat.services";
import { GroupPersistorServices } from "../group-persistor.services";
import { GroupShortcutHandlerService } from "../group-shortcut-handler.service";
import { HardwareLayerService } from "../hardware";
import { InteractionPersistorServices } from "../interaction-persistor.service";
import { InteractionService } from "../interaction.service";
import { LookupService } from "../lookup.service";
import { MultimediaService } from "../multimedia.service";
import { NewNotificationsService } from "../new-notifications.service";
import { PlayerInfoService } from "../player-info.service";
import { PosProcessingInteractionService } from "../pos-processing-interaction.service";
import { QueuService } from "../queue.service";
import { RateLimitClientService } from "../rate-limit-client.service";
import { RecoverySystemService } from "../recovery-system.service";
import { RequestBuilderServices } from "../request-builder.services";
import { RouterManagerService } from "../router-manager.service";
import { RoutingService } from "../routing.service";
import { ScreenSpinnerService } from "../screen-spinner.service";
import { SempaphoreService } from "../semaphore-service";
import { SerializableHeaders } from "../serializable-headers";
import { ServerCommunicationService } from "../server-communication.service";
import { SessionService } from "../session.service";
import { SignalListenerService } from "../signal/signal-listener";
import { SignalPublisherService } from "../signal/signal-publisher";
import { SocialNetworkDatabaseService } from "../social-network-database.service";
import { SocketFunctionsService } from "../socket-functions.service";
import { SocketService } from "../socket.service";
import { SubscriptionInteractionInfoBuilder } from "../subscription-info.service";
import { SusbcriptionContainerService } from "../subscriptions.service";
import { UniversalRehydrator } from "../universal-rehydrator";
import { VisualElementService } from "../visual-element.service";
import { AuthGuard } from "./auth-guard.service";

export type TLogicalIResponse = [boolean, IResponse, ClientInfraResponse]

// declare var window: any;


interface IExecuteLogin {
    email: string;
    password?: string;
    avoidRedirect?: boolean;
    token?: string;
}

interface EmbeddedLoginOptions {
    isCRMEmbedded?: true;
    idGroupToNavigate?: TGlobalUID;
    isEmbeddedChat?: true;
}

/**
 * Singleton to stores the auth state of the user and make auth-related request to server
 */
@Injectable()
export class AuthService implements ISocketReadyListener {
    private log = createServiceLogger('AuthService', 'red');

    private groupToRedirect: TGlobalUID;

    constructor(
        private billingSvc: BillingNSService,
        private sharedSvc: SharedService,
        private infraService: UniversalRehydrator,
        private sessionSvc: SessionService,
        private visualElementSvc: VisualElementService,
        private socketSvc: SocketService,
        private playerInfoService: PlayerInfoService,
        private router: Router,
        private serverAPISvc: ServerCommunicationService,
        private rbs: RequestBuilderServices,
        private navigator: NavigatorServices,
        private emissor: SignalPublisherService,
        private listener: SignalListenerService,
        private rehydrator: UniversalRehydrator,
        private subsContainer: SusbcriptionContainerService,
        private socketFunctions: SocketFunctionsService,
        private chatServices: GroupChatServices,
        private groupServices: GroupPersistorServices,
        public interactPersistor: InteractionPersistorServices,
        public multimediaServices: MultimediaService,
        private interactionService: InteractionService,
        private recoverService: RecoverySystemService,
        private subsInfo: SubscriptionInteractionInfoBuilder,
        private geolocationManager: GeolocationHandlerInteractorService,
        private hw: HardwareLayerService,
        private queue: QueuService,
        private semaphore: SempaphoreService,
        private contract: ContractService,
        private posProcessing: PosProcessingInteractionService,
        private snDatabase: SocialNetworkDatabaseService,
        private fullScreenVideoSvc: FullScreenVideoService,
        private screenLoadingSvc: ScreenSpinnerService,
        private routingSvc: RoutingService,
        private authGuard: AuthGuard,
        private routerManagerSVC: RouterManagerService,
        private dashboardMacroSVC: DashboardMacroService,
        private dashboardSvc: DashBoardService,
        private attendanceSVC: AttendanceService,
        private backSender: BackgroundSender,
        private deployedSvc: DeployedServicesService,
        private lookupSvc: LookupService,
        private embedded: EmbeddedChatService,
        private location: Location,
        private dialog: ColmeiaDialogService,
        private zone: NgZone,
        private generalFormSvc: GeneralFormService,
        private notificationSvc: NewNotificationsService,
        private groupShortchutSvc: GroupShortcutHandlerService,
        private helpTipsSvc: HelpTipsService,
        private rateLimitService: RateLimitClientService,
        private nativeAppSvc: NativeAppCommunicationService,
    ) {
        embedded.setAuthServiceDependency(this);
    };


    /**
     * If the user has a cookie get its info
     */
    public async loginPlayerFromToken(): Promise<void> {
        try {
            const response: ISignUpResponse = await window.loginPromise;
            if (isInvalid(response) || !response.friendlyError.okState) {

                this.hideInitializerSpinner();
                const hasInvalidToken = response?.friendlyError?.okState === false;
                const isAccessAllowedUnauhtorized: boolean = this.routingSvc.isAccessAllowedUnauhtorized(window.location.pathname);
                const shouldRedirectDueInvalidToken: boolean = hasInvalidToken && !isAccessAllowedUnauhtorized;

                if (shouldRedirectDueInvalidToken) {
                    this.router.navigateByUrl(`${routeID.auth.parent}/${routeID.auth.signIn.id}`);
                }

                return;
            }
            this.socketSvc.emmitLoginSignalAgain();
            this.nativeAppSvc.requestPushNotificationToken();
            await this.handlePlayerResponse(response, {}, true);

            this.hideInitializerSpinner();
        } catch (err) {
            console.error('loginPlayerFromToken', {
                err
            })
            this.routingSvc.navigateToId(routeID.landing);
            this.hideInitializerSpinner();
        }
    };

    public hideInitializerSpinner(): void {
        this.screenLoadingSvc.hide();
    }

    public loginByToken(email: string, token: string): Promise<Record<string, boolean>> {
        return this.login(email, 'empty', undefined, token);
    }

    public executeLogin({ email, password, avoidRedirect = false, token }: IExecuteLogin) {
        return this.login(email, password!, avoidRedirect, token);
    }

    public async login(email: string, password: string, avoidRedirect = false, token?: string, redirectUrl?: string): Promise<Record<string, boolean>> {
        const response: Record<string, boolean> = { executionOK: false };
        const target = token ?? password;
        const emailAndPasswordDefined = isAllValid(email, target) && target.length && email.length;
        if (!emailAndPasswordDefined)
            return response;

        this.socketSvc.emmitLoginSignalAgain();

        token ??= encryptPlayerPassword(password);

        const loginReq: ILoginRequest = {
            ...this.rbs.createAuthRequestToAuthService(apiRequestType.security.logUserFromEmail),
            email: sanitizeEmail(getIfNotValid(email, '')),
            password: token,
            mode: EInitMode.Normal,
            isMobile: this.hw.isMobile()
        };

        const clientResponse: ClientInfraResponse = await this.serverAPISvc.managedRequest(
            this.rbs.getNoCallBackSpinnningParameters(null, null),
            loginReq);

        const signUpResponse: ISignUpResponse = <ISignUpResponse>clientResponse.response;

        response.executionOK = clientResponse.executionOK;
        if (clientResponse.executionOK) {

            this.nativeAppSvc.requestPushNotificationToken();
            
            const friendly: FriendlyMessage = clientResponse.friendlyMessage;
            if (friendly.isOk()) {
                await this.handlePlayerResponse(signUpResponse, {}, avoidRedirect);
            }
        };

        if (isValidString(redirectUrl)) {
            this.navigator.safeRouteNavigate(redirectUrl);
            return response;
        }

        return response
    }

    public async register(registerForm: ISignUpRequest): Promise<boolean> {
        this.socketSvc.emmitLoginSignalAgain();
        const clientResponse: ClientInfraResponse = await this.serverAPISvc.managedRequest(
            this.rbs.getNoCallBackSpinnningParameters(null, null),
            registerForm);

        if (!clientResponse.executionOK) {
            return false;
        }

        await this.handlePlayerResponse(<ISignUpResponse>clientResponse.response);
        return clientResponse.executionOK;
    }

    private async sendGenericAuthRequest<T extends object>(requestType: TApiRequestType, requestData: T): Promise<TLogicalIResponse> {
        const infra: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(null, null);
        const request: IRequest = {
            ...this.rbs.createAuthRequestToAuthService(requestType),
            ...Object.assign({}, requestData as object)
        };
        const resp: ClientInfraResponse = await this.serverAPISvc.managedRequest(infra, request)
        const hasFriendlyError = resp.friendlyMessage && resp.friendlyMessage.hasReturnMessageWithError()
        const hasError = !resp.executionOK || hasFriendlyError
        const isSuccess = !hasError
        return [isSuccess, resp.response, resp]
    }

    async sendForgotPassFirstStep(data: IForgotPassFirstStep): Promise<TLogicalIResponse> {
        const [isSuccess, response, resp]: TLogicalIResponse = await this.sendGenericAuthRequest<IForgotPassData>(
            apiRequestType.security.forgotPass,
            { ...data, password: '', confirmationID: '', })
        return [isSuccess, response, resp]
    }

    async sendForgotPassSecondStep(data: IForgotPassSecondStep): Promise<TLogicalIResponse> {
        const password = encryptPlayerPassword(data.password);
        return this.sendGenericAuthRequest<IForgotPassData>(
            apiRequestType.security.forgotPass,
            { ...data, password, email: '' })
    }

    async getForgotPassData(forgotPassID: string): Promise<[boolean, string]> {
        const [isSuccess, response]: TLogicalIResponse = await this.sendGenericAuthRequest<IForgotPassData>(
            apiRequestType.security.forgotPass,
            { email: '', password: '', confirmationID: forgotPassID })
        const playerName = response && (<IForgotPassResponse>response).playerName
        return [isSuccess, playerName]
    }

    async sendSignUpFirstStep(signUpData: ISignUpFirstStepData): Promise<boolean> {
        signUpData.email = sanitizeEmail(signUpData.email);

        const [isSuccess, _]: TLogicalIResponse = await this.sendGenericAuthRequest<ISignUpFirstStepData>(
            apiRequestType.security.firstStepSignup,
            signUpData)
        return isSuccess
    }

    async sendSignUpSecondStep(confirmData: ISignUpConfirmationData): Promise<boolean> {
        const chosenPassword = confirmData.password ? encryptPlayerPassword(confirmData.password) : null;
        const defaultForm: ISignUpRequest = {
            ...this.rbs.createAuthRequestToAuthService(apiRequestType.security.signUp),
            name: null,
            nickName: null,
            password: null,
            confirmationID: null,
        };
        const registerForm: ISignUpRequest = {
            ...defaultForm,
            ...confirmData,
            password: chosenPassword,
        };
        return await this.register(registerForm);
    }

    public async logout(shoudlReload: boolean = true): Promise<boolean> {
        this.socketSvc.emmitLoginSignalAgain();
        this.authGuard.setIsUserLogged(false);
        const clientResponse: ClientInfraResponse = await this.serverAPISvc.managedRequest(
            this.rbs.getNoCallBackSpinnningParameters(this.sessionSvc.getPlayerID(), this.sessionSvc.getAvatarID()),
            this.rbs.createAuthRequestToAuthService(apiRequestType.security.logout));

        if (clientResponse.executionOK) {
            if (this.nativeAppSvc.isMobile()) {
                this.nativeAppSvc.nativeEventEmitter.emit(EEventName.logout, {});
            }
            this.hw.getStorage().clearItem(clientConstants.authentication.storageKey);
            // Clean services
            this.socketSvc.reset();
            this.emissor.specificSignalEmissorOnGenericStreamBehaviour(new LoginSignal(null, false));
            this.resetAll();
            this.emissor.specificSignalEmissorOnGenericStream(new LogoutSignal());
            this.listener.destroyAll();
            this.attendanceSVC.resetAllState();
            // go to landing
            this.routingSvc.navigateToId(routeID.landing);
            UberCache.dataReset();

            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .pipe(take(1))
                .subscribe(() => {
                    shoudlReload && location.reload();
                })
                ;


        } else {
            this.socketSvc.disconnect();
        }


        return clientResponse.executionOK;
    }

    private resetAll(): void {
        this.sessionSvc.reset();
        this.subsContainer.reset();
        this.socketSvc.reset();
        this.queue.clearQueue();
        this.snDatabase.reset();
        this.dashboardSvc.reset();
        this.attendanceSVC.resetAgentInSocialNetworkState()
        MessageHandlerCallbak.reset();
        ChatBackboneModel.reset();
    };

    // --- HELPERS

    public async handlePlayerResponse(response: ISignUpResponse, embedMode: EmbeddedLoginOptions = {}, isRefreshing: boolean = false): Promise<Record<string, boolean>> {
        window.isRefresh ??= true;
        this.authGuard.setIsUserLogged(true);
        this.groupToRedirect = getIfNotValid(response.redirectToGroupId, constant.entity.rootGroups.root);

        this.resetAll();

        const player: IPlayerJSON = response.player,
            playerInfo: IPlayerAccessCached = response.playerInfo,
            offset: string = response.offset;

        this.sessionSvc.setPlayerAvatars(response.avatarInfoArray);
        const myPlayer: Player = await this.infraService.rehydrate<Player>(player);
        this.log('PLAYER', { myPlayer, playerInfo, signInResponse: response });

        // Set singleton player
        const cached: PlayerCachedInfo = new PlayerCachedInfo(playerInfo, true);

        // init playerInfo service
        this.hw.setSocketServiceDependency(this.socketSvc);

        const tribe: Group = Group.factoryMessage(response.tribe);
        const corporate: Group = Group.factoryMessage(response.enterprise);
        Group.factoryMessage(response.allRoot);
        this.playerInfoService.init(cached);
        this.sessionSvc.setDependencySubscriptionContainer(this.subsContainer);
        this.sessionSvc.setDependencyRehydrator(this.rehydrator);
        this.sessionSvc.setDependencyGeoLocation(this.geolocationManager);
        this.sessionSvc.setRootGroups(corporate, tribe);
        this.sessionSvc.setPlayer(myPlayer);
        this.sessionSvc.setAvatar(myPlayer.getAvatarOriginal());
        this.sessionSvc.setLogged();

        this.snDatabase.setVisualElementService(this.visualElementSvc);
        this.snDatabase.setSessionService(this.sessionSvc);
        this.snDatabase.setBillingNSService(this.billingSvc);

        this.sessionSvc.initSessionService(this.playerInfoService);

        // this.snDatabase.insertSN(constant.entity.rootGroups.root, [], [response.allRoot, response.enterprise, response.tribe]);
        this.snDatabase.insertSN(playerInfo.idPersonalGroup, response.playerSN);
        this.snDatabase.updateGroups(response.allPlayerSN);

        window._COLMEIA_.setToken(response.authorization);
        SerializableHeaders.setRequestBuilder(this.rbs);
        SerializableHeaders.setServerApi(this.serverAPISvc);
        RouteCreator.setService(this.routingSvc);

        this.contract.setSessionServiceDependency(this.sessionSvc);

        this.subsContainer.setDependencySessionService(this.sessionSvc);
        this.subsContainer.setDependencyListener(this.listener);
        this.subsContainer.setDependencyNavigator(this.navigator);
        this.subsContainer.setDependencySubscriptionBuilder(this.subsInfo);
        this.subsContainer.setDependencyQueueServices(this.queue);
        this.subsContainer.setDependencyContractService(this.contract);

        this.rbs.setSessionService(this.sessionSvc);
        this.rbs.setDependencySubscribeServices(this.subsContainer);

        this.socketSvc.setDependSessionService(this.sessionSvc);
        this.socketSvc.setDependSocketFunctions(this.socketFunctions);
        this.socketSvc.setDependencyRecoveryService(this.recoverService);
        this.socketSvc.setDependRequestBuilderServices(this.rbs);

        this.backSender.setDependSessionService(this.sessionSvc);
        this.backSender.setDependRequestBuilderServices(this.rbs);
        this.backSender.setHardwareServices(this.hw);
        this.backSender.setSocketServices(this.socketSvc);

        this.backSender.setDependencySemaphore(this.semaphore);
        this.backSender.setDepenendcyQueueService(this.queue);
        this.backSender.setDependencyAPI(this.serverAPISvc);

        this.chatServices.setDependencySessionService(this.sessionSvc);
        this.chatServices.setDependencyRequestBuilderServices(this.rbs);
        this.chatServices.setDependencyServerAPI(this.serverAPISvc);
        this.chatServices.setDependencySocket(this.socketSvc);
        this.chatServices.setDependencySocketPersistor(this.interactPersistor);

        this.navigator.setDependencyUniversalRehydrator(this.rehydrator);
        this.navigator.setDependencyRequestBuilderService(this.rbs);
        this.navigator.setDependencyCommunucationService(this.serverAPISvc);
        this.navigator.setDependencySubscriptionService(this.subsContainer);
        this.navigator.setDependencyGroupServices(this.groupServices);
        this.navigator.setDependencySessionService(this.sessionSvc);
        this.navigator.setPlayerCached(cached);

        this.posProcessing.setNavigatorDependency(this.navigator);
        this.posProcessing.setSubscriptionContainerDependency(this.subsContainer);

        this.multimediaServices.setDependencyRequestBuilderServices(this.rbs);
        this.multimediaServices.setDependencyServerAPI(this.serverAPISvc);

        this.interactPersistor.setDependencyRequestBuilderService(this.rbs);
        this.interactPersistor.setDependSessionService(this.sessionSvc);
        this.interactPersistor.setDependencyCommunucationService(this.serverAPISvc);

        this.groupServices.setDependencyCommunucationService(this.serverAPISvc);
        this.groupServices.setDependencyRequestBuilderService(this.rbs);
        this.groupServices.setDependencySessionService(this.sessionSvc);
        this.groupServices.setDependencySubscriptionContainer(this.subsContainer);
        this.groupServices.setDependencyUniversalRehydrator(this.rehydrator);
        this.groupServices.setDependencyInteractionService(this.interactionService);

        this.recoverService.setDependencyRequestBuilder(this.rbs);
        this.recoverService.setDependencyServerCommunication(this.serverAPISvc);
        this.recoverService.setDependencySessionService(this.sessionSvc);
        this.recoverService.setDependencySubscriptionContainer(this.subsContainer);
        this.socketSvc.setHardwareServices(this.hw);
        this.socketSvc.setDialogServices(this.dialog)
        this.socketSvc.setAuthService(this)

        this.rehydrator.setDependencyRecoveryService(this.recoverService);
        this.queue.setSemaphoreDependency(this.semaphore);

        this.hw.getFileOpener().setMultimediaService(this.multimediaServices);
        this.hw.getUpdater().setInteractionPersistor(this.interactPersistor);

        this.fullScreenVideoSvc.setMultimediaService(this.multimediaServices);

        // Init a new rabbitMq listener
        UberCache.setOffset(offset);
        // this.navigator.navigateToGroupID(constant.entity.rootGroups.root);

        if (cached.isOnlineTrackingOn()) {
            this.hw.getGeolocation().setEmergencyMode(true);
        };

        this.listener.listenToSocketReady(this);
        this.socketSvc.startSocket(player.primaryID, response, cached);

        this.sessionSvc.setIsEmbeddedChat(embedMode.isEmbeddedChat || false);
        this.sessionSvc.setCRMEmbeddedMode(embedMode.isCRMEmbedded || false);

        this.attendanceSVC.setNavigatorServiceDependency(this.navigator);
        this.attendanceSVC.setNotificationService(this.notificationSvc);
        this.attendanceSVC.setDashboardMacroServiceDependency(this.dashboardMacroSVC);
        this.attendanceSVC.setComunnicationServiceDependency(this.serverAPISvc);
        this.attendanceSVC.setRequestBuilderDependency(this.rbs);
        this.attendanceSVC.setSessionService(this.sessionSvc);
        this.attendanceSVC.setDeployedService(this.deployedSvc);
        this.attendanceSVC.setPublisher(this.emissor);
        this.attendanceSVC.setLookupService(this.lookupSvc);
        this.attendanceSVC.setInteractionPersistor(this.interactPersistor)
        this.attendanceSVC.setGeneralFormSvc(this.generalFormSvc);
        this.attendanceSVC.setRoutingService(this.routingSvc);
        this.embedded.setServerCommunicationService(this.serverAPISvc);

        this.recoverService.setAttServiceDependency(this.attendanceSVC);

        this.posProcessing.setAttendaceServiceDependacy(this.attendanceSVC);

        this.chatServices.setGroupShortchutSvc(this.groupShortchutSvc);

        this.subscribeRateLimitBlock();
        this.rateLimitService.afterLoginCheck(response);

        this.sharedSvc.init();

        await Promise.all([
            this.startNavigation(response.firstNavigation, isRefreshing, isValidString(embedMode.idGroupToNavigate) ? routeID.groups.chat : undefined),
        ]);
        
        if (this.nativeAppSvc.isMobile()) {
            this.nativeAppSvc.nativeEventEmitter.emit<IAuthToken>(EEventName.authToken, {token: response.authorization});
        }

        return { handlePlayerResponse: true };
    };

    private subscribeRateLimitBlock() {
        this.rateLimitService.blocked$.subscribe(() => {
            this.logout(false);
        });
    }

    public receiveSocketReadtCallback(sign: SocketReadySignal): void {
        this.emissor.specificSignalEmissorOnGenericStreamBehaviour(new LoginSignal(sign.getSignupResponse(), true));
        this.listener.destroySubscriptions(this);
    };

    private interactionToNavigate: { idGroup: TGlobalUID, idInteraction: TGlobalUID } = null;

    public setInteractionToNavigateOnLogin(idGroup: TGlobalUID, idInteraction: TGlobalUID): void {
        this.interactionToNavigate = {
            idGroup,
            idInteraction
        };
    }

    private async startNavigation(firstNavigation: INavigationResponse, avoidRedirect = false, groupPage?: string): Promise<void> {
        this.queue.rehydrateQueue();

        if (firstNavigation) {
            const hasNotInteraction = !isValidRef(this.interactionToNavigate)
            const idGroupNotification: TGlobalUID = hasNotInteraction ? null : this.interactionToNavigate.idGroup;
            const rootUrls = [`/${routeID.auth.parent}/${routeID.auth.signIn.id}`, '/'];
            const navigationToRoot = rootUrls.includes(window.location.pathname);

            await this.navigator.firstNavigation(
                firstNavigation,
                idGroupNotification,
                navigationToRoot
                    ? false
                    : avoidRedirect,
                groupPage,
            );

            if (!hasNotInteraction && idGroupNotification) {
                const interaction = this.interactionToNavigate;

                await this.routerManagerSVC.goToInteractionID(
                    interaction.idGroup,
                    interaction.idInteraction
                );
            };

        } else {
            this.navigator.navigateToGroupID(this.groupToRedirect);
        }
    }

    public async loginByEmbeddedHandshake(idHandShake: string, firstMessage?: string, idGroupToNavigate?: string): Promise<void> {
        const req: IEmbeddedChatLogin = {
            idPlayer: undefined,
            idAvatar: undefined,
            coordinates: undefined,
            requestType: apiRequestType.security.logUserFromShakingHands,
            idHandShake,
            dateTime: getClock(),
            idCurrentGroup: undefined,
            timezone: 1,
            browserId: undefined,
            uid: undefined,
            firstMessage,
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();
        const response = await this.serverAPISvc.managedRequest(infra, req);
        if (response.executionOK) {
            await this.handlePlayerResponse(response.response as any, {
                isEmbeddedChat: true,
                idGroupToNavigate,
            });
        } else {
            // throw up error...
        }
    }
};
