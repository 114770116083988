<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">
  <mat-dialog-content>
    <mat-tab-group class="boxed-tabs boxed-dense tabs-wrap mat-tab-padding-2x" animationDuration="200ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Configurações gerais
        </ng-template>

        <ng-template matTabContent>
          <app-crm-graph-basic-data *ngIf="basicCRMHandler" [handler]="basicCRMHandler"></app-crm-graph-basic-data>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Configurações de disparo
        </ng-template>
        <div class="campaign-action-container">
          <app-campaign-action-picker [(ngModel)]="crmNotification.idCampaignAction"></app-campaign-action-picker>

          <app-timestamp-picker label="Deadline CK" [(time)]="crmNotification.deadlineCK"></app-timestamp-picker>
          <!-- <mat-form-field appearance="fill">
            <mat-label>Deadline CK</mat-label>
            <input class="time-picker" matInput [value]="initialDeadlineCK" (change)="inputTimeChange($event)" type="time" min="00:00" max="23:59">
          </mat-form-field> -->
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
</cm-generic-dashboard-edit>