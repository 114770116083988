import { ICachedFile } from "@colmeia/core/src/multi-media/file-interfaces";
import { IServerFileInfo } from "@colmeia/core/src/comm-interfaces/aux-interfaces";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";


export class ClientCachedFile {

    private info: ICachedFile;

    constructor(info: ICachedFile) {
        this.info = info;
    };

    public isSmarShare(): boolean {
        return this.info.serverInfo.idMedia ? true : false;
    };

    public getInfoToServer(): IServerFileInfo {
        return this.info.serverInfo;
    };

    public setIDMedia(idMedia: TGlobalUID): void {
        this.info.serverInfo.idMedia = idMedia;
    };

    setBucketInfo(isMustSaveOnColmeiaBucket: boolean) {
        this.info.serverInfo.isMustSaveOnColmeiaBucket = isMustSaveOnColmeiaBucket
    }

    public getIDMedia(): TGlobalUID { return this.info.serverInfo.idMedia; };

    public setServerInfo(serverInfo: IServerFileInfo): void {
        this.info.serverInfo = serverInfo;
    };

    public isProcessed(): boolean {
        return this.info.isProcessed;
    };

    public getFile(): Blob {
        return this.info.file;
    };

    public setHash(hash: string): void {
        this.info.isProcessed = true;
        this.info.serverInfo.hash = hash;
    };

    public getHash(): string {
        return this.info.serverInfo.hash;
    };

    public getFileUrl(): string {
        return this.info.fileLocalUrl;
    };

    public getMimeType() {
        return this.info.serverInfo.type;
    }

    public setMimeType(value: string) {
        this.info.serverInfo.type = value;
    }

    public setFileUrl(file: Blob): void {
        if (file == null) {
            this.info.fileLocalUrl = '';
            return;
        }
        this.info.fileLocalUrl = URL.createObjectURL(file);
    };

    public static fileToNewCachedFile(file: Blob, name: string, thumbnail?: Blob): ClientCachedFile {
        const fcache = new ClientCachedFile({
            serverInfo: ClientCachedFile.newServerFileInfo(file, name),
            file: file,
            thumbnail: thumbnail,
            isProcessed: false,
            fileLocalUrl: null
        });

        return fcache;


    };

    public static newServerFileInfo(file: Blob, name: string): IServerFileInfo {
        return {
            name: name,
            size: file.size,
            type: file.type,
            idMedia: null,
            hash: '',
            mmKey: '',
            shares: 0,
        };
    };

    public getThumbnailBlob(): Blob { return this.info.thumbnail; };



};
