import { Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { IServiceChatInitiatedInteractionJSON } from '@colmeia/core/src/comm-interfaces/service-group-interfaces';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { IOldestRecentInteractionInfo, IOldestRecentInteractionInfoResponse } from '@colmeia/core/src/shared-business-rules/attendance-island/attedance-helpers';
import { isInvalid, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { ServerCommunicationService } from './server-communication.service';
import { IGetOldestRecentInteractionsInfoRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IGetOldestRecentInteractionsInfoResponse } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { getLastUnansweredClientInteraction } from '@colmeia/core/src/rules/thread-conversation-functions';

@Injectable({
    providedIn: 'root'
})
export class OldestRecentInteractionService {

    /**
     * Mapa que armazena informações sobre as últimas interações de um grupo no contexto da conversação atual.
     * A chave é o ID do grupo e o valor é um objeto contendo informações sobre a interação mais antiga da ultima série de interações de um mesmo autor.
     * @type {Map<TGlobalUID, IOldestRecentInteractionInfo>}
     * @private
     */
    private oldestRecentInteractionDB: Map<TGlobalUID, IOldestRecentInteractionInfo> = new Map();

    constructor(
        private api: ServerCommunicationService
    ) { }

    getInteractionFromCacheByID(interactionID: string): Interaction {
        const hasGroupInMemory: boolean = UberCache.testCache(interactionID);
        if (!hasGroupInMemory)
            return;
        return Interaction.staticFactory(interactionID);
    }

    getInteractionFromCacheByJSON(interactionJSON: IInteractionJSON): Interaction {
        return this.getInteractionFromCacheByID(interactionJSON.primaryID);
    }

    getOldestRecentInteractionInfoFromJSON(interactionJSON: IInteractionJSON): IOldestRecentInteractionInfo {
        const groupID = interactionJSON.idGroup;
        const lastInfo = this.oldestRecentInteractionDB.get(groupID);
        return lastInfo;
    }

    async fetchOldestRecentInteractionsInfo(interactions: IServiceChatInitiatedInteractionJSON[]) {
        const newInteractions = interactions.filter(interaction => !this.oldestRecentInteractionDB.has(interaction.idGroup));

        const parentInteractionsIDs = newInteractions.map(interactions => interactions.primaryID);
        const response = await this.api.sendRequest<IGetOldestRecentInteractionsInfoRequest, IGetOldestRecentInteractionsInfoResponse>(apiRequestType.getOldestRecentInteractionsInfo)({ parentInteractionsIDs });

        if (isInvalid(response))
            throw new Error('Failed to get interactions');

        this.setOldestRecentInteractionDBByIdInteractionParent(newInteractions, response.lastInteractionsInfo);

        return;
    }

    private setOldestRecentInteractionDBByIdInteractionParent(
        parentInteractions: IServiceChatInitiatedInteractionJSON[],
        lastInteractionsInfo: IOldestRecentInteractionInfoResponse[]
    ) {
        for (const lastInteractionInfo of lastInteractionsInfo) {
            const idGroup = parentInteractions.find(interaction => interaction.primaryID === lastInteractionInfo.idInteractionParent)?.idGroup;
            const cached = this.getInteractionFromCacheByID(lastInteractionInfo.idInteraction);
            if (isInvalid(cached))
                this.oldestRecentInteractionDB.set(idGroup, {
                    clockTick: lastInteractionInfo.clockTick,
                    idAvatar: lastInteractionInfo.idAvatar,
                    isMessageSentByHumanAttendant: lastInteractionInfo.isMessageSentByHumanAttendant
                })
        }
    }

    removeInteraction(groupID: TGlobalUID) {
        this.oldestRecentInteractionDB.delete(groupID);
    }

    upsertInteraction(groupID: string, newInteractionInfo: IOldestRecentInteractionInfo) {
        const oldestRecentInteraction = this.oldestRecentInteractionDB.get(groupID);

        if (newInteractionInfo.isMessageSentByHumanAttendant) {
            this.oldestRecentInteractionDB.set(groupID, newInteractionInfo);
            return;
        }


        if (isValidRef(oldestRecentInteraction) && oldestRecentInteraction.idAvatar === newInteractionInfo.idAvatar)
            return; // Se ja temos o registro de uma interação do mesmo autor,não precisamos atualizar a lista.

        if (isValidRef(oldestRecentInteraction) && !oldestRecentInteraction.isMessageSentByHumanAttendant)
            return; // Se a msg mais antiga não foi enviada pelo atendente humano então continuamos considerando ela a mais antiga.

        this.oldestRecentInteractionDB.set(groupID, newInteractionInfo);
    }

    shouldUpdate(interactions: IServiceChatInitiatedInteractionJSON[]) {
        const shouldUpdate = !interactions.every(interaction => this.oldestRecentInteractionDB.has(interaction.idGroup));
        return shouldUpdate;
    }
}
