import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {ChangeDetectorRef, Component, Inject, OnInit} from "@angular/core";
import {Group} from "@colmeia/core/src/business/group";
import {ICreateGroupParameters} from "../../../../model/group-editor-creator.model";
import {HexagonUploaderHandler} from "../../../../handlers/hexagon-uploader-handler";
import {IColmeiaDialogComponentData} from "../../../dialogs/dialog/dialog.component";
import {SessionService} from "../../../../services/session.service";
import {SnackMessageService} from "../../../../services/snack-bar";
import {constant, TGlobalUID} from "@colmeia/core/src/business/constant";
import {MMconstant} from "@colmeia/core/src/multi-media/multimedia-constant";
import {EHexagonFormat, EHexagonSizes} from "../../../../handlers/hexagono.handler";
import {EHexagonUploaderMode} from "../../../foundation/hexagon-uploader/hexagon-uploader.model";
import {Avatar} from "@colmeia/core/src/business/avatar";
import {isValidStringAndSize} from "@colmeia/core/src/tools/utility";
import {Serializable} from "@colmeia/core/src/business/serializable";
import {groupConstants} from "@colmeia/core/src/core-constants/groups";
import {domain} from "@colmeia/core/src/core-constants/security-constant";
import {SecurityItem} from "@colmeia/core/src/security/security-item";
import {RootComponent} from "../../../foundation/root/root.component";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { INewSocialMediaConfiguration, initNewSocialMediaConfiguration } from "@colmeia/core/src/request-interfaces/request-interfaces";

@Component({
    selector: 'app-create-group-dialog',
    templateUrl: './create-group-dialog.component.html',
    styleUrls: ['./create-group-dialog.component.scss']
})
export class CreateGroupDialogComponent extends RootComponent<
    'heritageFromFather'
    | 'create'
    | 'cancel'
    | 'name'
    | 'description'
> implements OnInit {

    // public labels: Serializable = Serializable.staticFactory('newGroupCreateDialog');
    //
    // public labelKeys = {
    //     heritage: 19,
    //     create: 20,
    //     cancel: 21,
    //     name: 26,
    //     description: 27
    // };

    public inputSettings = {
        name: {
            minLength: groupConstants.name.minLength,
            maxLength: groupConstants.name.maxLength
        },
        description: {
            minLength: groupConstants.description.minLength,
            maxLength: groupConstants.description.maxLength
        }
    };

    public static CreatingGroup: Group;
    private parameter: ICreateGroupParameters;
    public _name: string = '';
    public _description: string = '';
    public hexagonUploaderHandler: HexagonUploaderHandler;
    private group: Group;
    public heritageConfig: boolean = true;
    private creating: boolean = false;
    public saving: boolean;

    public groupTypeIcon: string;
    public groupTypeName: string;

    private static heritageOptions: Array<string> = [
        domain.securityChain.securityAttachedToParent,
        domain.securityChain.allChainWithSameSecuritySettings,
        domain.securityChain.allChainWithSameParticipants,
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) dataFromParentComponent: IColmeiaDialogComponentData<ICreateGroupParameters>,
        private dialogRef: MatDialogRef<CreateGroupDialogComponent>,
        private sessionSVC: SessionService,
        private snack: SnackMessageService,
        private cdr: ChangeDetectorRef
    ) {
        super({
            cancel: gTranslations.common.cancel,
            name: gTranslations.common.name,
            create: gTranslations.common.create,
            description: gTranslations.common.description,
            heritageFromFather: gTranslations.fragments.heritageFromFather
        }, true, cdr);

        this.parameter = dataFromParentComponent.getParamsToChildComponent();
    }

    ngOnInit() {
        this.createGroupInstance();
        this.buildHexagonUploaderHandler();

        this.groupTypeIcon = this.parameter.groupTypeHexagon.getIcon();
        this.groupTypeName = this.parameter.groupTypeHexagon.getName();
    }

    private createGroupInstance(): void {

        let newGroup: Group;

        switch (this.parameter.createGroupType) {
            case constant.groupType.functionalRoot.tribe:
                newGroup = this.sessionSVC.getTribe().getNewGroupFromParent(this.parameter.createGroupType, this.parameter.avatar);
                break;
            case constant.groupType.functionalRoot.corporate:
                newGroup = this.sessionSVC.getCorportate().getNewGroupFromParent(this.parameter.createGroupType, this.parameter.avatar);
                break;
            default:
                newGroup = this.parameter.parentGroup.getNewGroupFromParent(this.parameter.createGroupType, this.parameter.avatar);
                break;
        }
        this.group = newGroup;
    }

    public get isCorporate(): boolean {
        return this.parameter.createGroupType === constant.groupType.functionalRoot.corporate;
    }

    newSocialMediaConfiguration: INewSocialMediaConfiguration = initNewSocialMediaConfiguration();

    get hasPartition(): boolean {
        return this.newSocialMediaConfiguration.hasPartition;
    }

    set hasPartition(value: boolean) {
        this.newSocialMediaConfiguration.hasPartition = value;
    }


    private buildHexagonUploaderHandler(): void {
        this.hexagonUploaderHandler = HexagonUploaderHandler.create(this.group, {
            onMediaDeleted: (mm => null),
            onFileSelected: mm => null,
            idMediaEditing: null,
            idTag: MMconstant.tag.hexagonon,
            size: EHexagonSizes.lg,
            format: EHexagonFormat.Circle,
            mode: EHexagonUploaderMode.Upload,
            multimediaObject: this.group.getMultimediaObject()
        })
    }

    onCancelClicked(): void {
        this.group.removeUberCache();
        this.dialogRef.close();
    }

    async onCreateClicked(): Promise<void> {
        const avatar: Avatar = this.sessionSVC.getSelectedAvatar();

        if (this.creating) {
            return;
        }

        if (! isValidStringAndSize(this._name, groupConstants.name.minLength, groupConstants.name.maxLength)) {
            this.error(
                'newGroupCreateDialog',
                this._name.length > groupConstants.name.maxLength ? 23 : 22
            );
            return;
        }

        this.saving = true;
        this.group.setName(this._name);
        this.group.setDescription(this._description);

        if (this.heritageConfig) {
            for (const hopt of CreateGroupDialogComponent.heritageOptions) {
                this.group.getSecuritySettings().addHash(SecurityItem.getNewSecurityItem(hopt));
            }
        }



        await this.parameter.service.persistGroup(
            this.group,
            [],
            [],
            this.isCorporate ? { newSocialMedia: this.newSocialMediaConfiguration } : undefined,
        );
        this.saving = false;
        this.parameter.componentInstance.onGroupSaved();

        this.dialogRef.close();
    }


    

    private error(serializableID: TGlobalUID, field: number): void {
        const msgSerializable: Serializable = Serializable.staticFactory(serializableID);
        this.snack.openError(msgSerializable.getSerializableText(field));
    }
}
