import { DeleteInteraction } from "../deleted-interaction";
import { TGlobalUID, constant } from "../../../business/constant";
import { Participant } from "../../../business/participant";
import { EGeolocationIntType } from "../../../business/constant.enums";
import { ITurnGpsOffJSON } from "../../../comm-interfaces/interaction-interfaces";
import { TurnGPSOn } from "./turn-gps-on";

export class TurnGpsOff extends DeleteInteraction {
    private constructor(primaryID: TGlobalUID, participant: Participant, turnOn: TurnGPSOn) {
        super(primaryID, participant, turnOn, EGeolocationIntType.turnGpsOff);
    };
    
    public static getNewTurnGpsOff(participant: Participant, parentInteraction: TurnGPSOn): TurnGpsOff {
        return new TurnGpsOff(null, participant, parentInteraction);
    }

    public getTurnOn(): TurnGPSOn { return <TurnGPSOn>super.getInteractionParent() };

    public rehydrate(json: ITurnGpsOffJSON): void {
        super.rehydrate(json);
    };

    public toJSON(): ITurnGpsOffJSON {
       return {
           ...super.toJSON()
       };
    };
};