import { ISubjectSubscriber, ISubscriptionInteractionJSON } from '../../comm-interfaces/barrel-comm-interfaces';
import { SubscriptionType } from '../../interaction/subscription/subscription-type';
import { constant } from '../../business/constant';


export function getSubjectSubscriberIDs(subscription: ISubscriptionInteractionJSON): ISubjectSubscriber {
    const subSub : ISubjectSubscriber = {toSubject: subscription.subject, fromSubscriber: null, idFromObjectType: null, idToObjectType: null};
    const subType: SubscriptionType = SubscriptionType.staticFactory(subscription.idSubscriptionType);

    if (subType.isAvatarFromSubscriber()) {
        subSub.fromSubscriber = subscription.participant.avatar.primaryID;
        subSub.idFromObjectType = constant.objectType.avatar;
    } else if (subType.isGroupFromSubscriber()) {
        subSub.fromSubscriber = subscription.idGroup;
        subSub.idFromObjectType = constant.objectType.group;
    };

    if (subType.isAvatarToSubject()) {
        subSub.idToObjectType = constant.objectType.avatar;
    } else {
        subSub.idToObjectType = constant.objectType.group;
    };

    return subSub; 
};