import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { constant } from '@colmeia/core/src/business/constant';
import { FeedbackDialogHandler } from './feedback-dialog.handler';
import { DatePipe } from '@angular/common';
import { Memoize } from "typescript-memoize";
import {UserSettingsService} from "../../../../../services/user-settings.service";
import {HandlerHexagonon} from "../../../../../handlers/hexagono.handler";
import {feedbackDialog} from "@colmeia/core/src/shared-business-rules/visual-constants";

@Component({
    selector: 'app-feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {
    private datePipe: DatePipe;
    private reference = null;
    public labels = {
        reactions: constant.serializableField.auxiliars.aux01,
        all: constant.serializableField.auxiliars.aux01
    };
    private translatedLabels = {};

    constructor(
        private settings: UserSettingsService,
        @Inject(MAT_DIALOG_DATA) public data: FeedbackDialogHandler,
    ) {
        this.datePipe = new DatePipe(this.settings.getSelectedLocale());
        const serializable: Serializable = Serializable.staticFactory(feedbackDialog);
        Object.keys(this.labels)
            .forEach(
                label => {
                    this.translatedLabels[this.labels[label]] = serializable.getSerializableText(this.labels[label]);
                }
            );

        this.data.init();
    }

    ngOnInit() {}

    public goToAvatar(avatar): void {
        // const avatarID = avatar.getAvatarID()
        // this.routingSvc.goToAvatarProfile(avatarID)
    }

    _isCurrentFilter(id: string): boolean {
        return id === this.filter;
    }

    get filter() {
        return this.data.getCurrentFilter();
    }

    set dialogReference(reference) {
        if (null === this.reference)
            this.reference = reference;
    }

    setFilter(filter: string) {
        this.data.setFilter(filter);
    }

    get feedbacks() {
        return this.data.getFeedbacks();
    }

    get filteredAvatars() {
        return this.data.getFilteredAvatars();
    }

    _friendlyTime(clock: number): string {
        const date = this.datePipe.transform(clock, this.settings.getDateFormat()),
              time = this.datePipe.transform(clock, this.settings.getTwentyFourTimeFormat());
        return `${date} as ${time}`;
    }
    @Memoize()
    _makeHexagonHandler(avatar): HandlerHexagonon {
        return HandlerHexagonon.newHandler({
            size: 'smd',
            forceImage: avatar.idMediaAvatar
        });
    }

    closeDialog() {
        if (null !== this.reference)
            this.reference.close();
    }

    translate(id: number | string): string {
        return this.translatedLabels[id] || ``;
    }
}
