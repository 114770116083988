<div>
  <div id="contact-list-results" *ngIf="(clientContactListDataArray$ | async) as clientContactListDataArray">
    <mat-list *ngIf="clientContactListDataArray?.length > 0">
      <mat-list-item *ngFor="let clientContactListData of clientContactListDataArray">
        <mat-checkbox (change)="onListNameClicked($event, clientContactListData)">
          {{clientContactListData.name}}
        </mat-checkbox>
      </mat-list-item>
    </mat-list>
  </div>
  <div style="display: flex;">
    <icon-button [text]="'salvar'" (click)="onSaveClicked()"></icon-button>
  </div>
</div>
