import {constRosetta} from '../rosetta/const-rosetta';
import {constant} from './constant';
import { TGlobalUID } from '../core-constants/types';
import { clockTickToDate } from '../time/time-utl';
import { DefaultInteractionActions } from '../rules/notifcation-rules/notifications-normalization';
import { EAppState } from '../rules/notifcation-rules/notification-config';

/**
 * Transformar em service
 */
export class UserDefaultSettings {
    private defaultLanguageID: TGlobalUID = constRosetta.language.portuguese;
    private currentSocialContext: TGlobalUID = constant.socialContext.colmeia;

    private static currentProfile: UserDefaultSettings;

    public static getCurrentProfile(): UserDefaultSettings {return UserDefaultSettings.currentProfile; };
    public static getDefaultLanguageID(): TGlobalUID {return UserDefaultSettings.currentProfile.getDefaultLanguageID() }
    public static getCurrentSocialContext() : TGlobalUID {return UserDefaultSettings.currentProfile.getCurrentSocialContext()};


    public static initProfile(): UserDefaultSettings {
        const profile: UserDefaultSettings = new UserDefaultSettings();
        UserDefaultSettings.currentProfile = profile;
        profile.init();
        return profile;
    };

    private init(): void {
        this.setDefaultLanguage();
        this.resetSocialContext();
    };

    public getCurrentSocialContext(): TGlobalUID {
        return this.currentSocialContext;
    };

    public setCurrentSocialContext(socialContext: TGlobalUID): void {
        this.currentSocialContext = socialContext;
    };

    public resetSocialContext(): void {
        this.setCurrentSocialContext(constant.socialContext.colmeia);
    };


    public setDefaultLanguage(idLanguage: TGlobalUID =  constRosetta.language.portuguese): void {
        this.defaultLanguageID = idLanguage;
    };

    public getDefaultLanguageID(): TGlobalUID {
        return this.defaultLanguageID;
    };

    public getAngularLocale(): string {
        return 'pt'; // Language.staticFactory(UserDefaultSettings.defaultLanguageID).getGoogleLanguageCode();
    };

    public getHourFormat(): string {
        return `HH:mm:ss`;
    ;}
    
    public getDateFormat(): string {
        return `dd/MM/yyyy`;
    }
    
    public getDatePattern(): RegExp {
        return /(\d{2})\/(\d{2})\/(\d{4})/;
    }

    public getNoWindowFocusSound(): Array<string> {
        return [
            `/assets/audio/notifications/unfocused/plucky.mp3`,
            `/assets/audio/notifications/unfocused/plucky.ogg`,
            `/assets/audio/notifications/unfocused/plucky.webm`
        ];
    }

    public getNoGroupFocusSound(): Array<string> {
        return [
            `/assets/audio/notifications/outofgroup/unsure.mp3`,
            `/assets/audio/notifications/outofgroup/unsure.ogg`,
            `/assets/audio/notifications/outofgroup/unsure.webm`
        ];
    }
    
    public static getDefaultNotificationSound(isFocused: boolean): string {
        const defaultSounds = DefaultInteractionActions.sound;
        return isFocused ? defaultSounds[EAppState.foregroud]
            : defaultSounds[EAppState.background];
    }


    public static getDialogNotificationSound(): Array<string> {
        return [
            `/assets/audio/notifications/dialog/dialog.mp3`,
            `/assets/audio/notifications/dialog/dialog.ogg`,
            `/assets/audio/notifications/dialog/dialog.webm`
        ];
    }
};

