import { Component, OnInit, Input, ChangeDetectionStrategy, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog , MatDialogRef} from '@angular/material/dialog';
import {IAndroidBackButtonAction, UserActionService} from "../../services/user-action.service";
import {ECmModalState} from "../../model/cm-modal.model";
import {CmModalHandler} from "../../handlers/cm-modal-handler";
import { isValidRef } from '@colmeia/core/src/tools/utility';

@Component({
    selector: 'cm-modal',
    templateUrl: './cm-modal.component.html',
    styleUrls: ['./cm-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmModalComponent implements OnInit, IAndroidBackButtonAction {
    private _handler: CmModalHandler;
    private _state: ECmModalState = ECmModalState.Closed;
    private _reference: MatDialogRef<unknown>;
    private _hooks: Array<Subscription> = [];

    @Input()
    set handler(value: CmModalHandler) {
        this._handler = value;
        this.proceduresOnSetHandler();
    }

    get handler () {
        return this._handler;
    }

    constructor(
        private dialog: MatDialog,
        private userAction: UserActionService,
        private _zone: NgZone,
    ) {}

    ngOnInit() {}

    onAndroidBackButtonPressed() {
        this._zone.run(() => {
            this.dialog.closeAll();
        });
    }

    private proceduresOnSetHandler() {
        if (this.stateChanged()) {
            Promise.resolve(null)
                .then(p => {
                    this.updateState();
                })
        }
    }

    private clearHooks() {
        this._hooks.forEach(
            hook => hook.unsubscribe()
        );
        this._hooks = [];
    }

    private setHooks() {
        const afterOpen = this.handler.safeAfterOpen(),
            beforeClose = this.handler.safeBeforeClose(),
            afterCloseCB = this.handler.safeAfterClose(),
            afterClose = () => {
                this._state = ECmModalState.Closed;
                afterCloseCB();
            };

        this._hooks.push(
            this._reference.afterOpened()
                .subscribe(sub => afterOpen()),
            this._reference.beforeClosed()
                .subscribe(sub => beforeClose()),
            this._reference.afterClosed()
                .subscribe(sub => afterClose())
        );
    }

    private appendDataToSettings(settings: any, data: any): any {
        settings.data = data;
        return settings;
    }

    private updateState() {
        switch (this._state) {
            case ECmModalState.Closed: {
                this._reference = this.dialog.open(
                    this.handler.getProjection(),
                    this.appendDataToSettings(
                        this.handler.safeSettings(),
                        this.handler.safeData()
                    )
                );
                this.setHooks();
                this.userAction.addAction(this);
                this._state = ECmModalState.Open;
            };
                break;
            case ECmModalState.Open: {
                this._reference.close();
                this._state = ECmModalState.Closed;
                this.clearHooks();
            };
                break;
        }
    }

    private stateChanged(): boolean {
        return this.handler && this.handler.getState() !== this._state;
    }
}
