import { SessionService } from './session.service';
import { RequestBuilderServices } from './request-builder.services';
import { HardwareLayerService } from './hardware';
import { Injectable } from '@angular/core';
import { getClock, isValidArray } from '@colmeia/core/src/tools/utility';
import { socketConfig } from '@colmeia/core/src/core-constants/socket.conf';
import { ELocalQueueName, QueuService } from './queue.service';
import { TIControlledRecordArray, IClientSemaphoreConfig } from '@colmeia/core/src/core-queue/core-queue-service';
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { TInteractionArray, Interaction } from '@colmeia/core/src/interaction/interaction';
import { ClientInfraResponse, IInfraParameters } from 'app/model/client-infra-comm';
import { SempaphoreService } from './semaphore-service';
import { SocketService } from './socket.service';
import { IBatchTrackerInteractionRequest, IRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { ServerCommunicationService } from './server-communication.service';
import {IBatchInteractionInsertResponse} from "@colmeia/core/src/request-interfaces/response-interfaces";

@Injectable()
export class BackgroundSender {
    private hw: HardwareLayerService;
    private sessionService: SessionService;
    private rbs: RequestBuilderServices;
    private socket: SocketService;

    private semaphore: SempaphoreService;
    private queueService: QueuService;
    private api: ServerCommunicationService;

    private batchableCount: number;

    constructor () {
        this.timersControl();
    }



    //// Batch CONTROL
    /// Timer só adiciona se ON-LINE
    private async batchProcessing(): Promise<void> {
        const now: number = getClock()

        if (await ! this.hw.getNetwork().isOnline()) {
            return;
        }

        if (++this.batchableCount >= socketConfig.client.recovery.batchableTimeoutRatio) {
            this.batchableCount = 0; // Batchable são feitos com menos frequencia do que recover
        };

        try {
            this.semaphore.runClientWithLock(async () => {

                for (const key in ELocalQueueName) {


                    const controll = <ELocalQueueName>ELocalQueueName[key];

                    if (this.queueService.isRetryInteraction(<ELocalQueueName>controll)) {

                        if (controll === ELocalQueueName.recoverableInteraction) {
                            const entries: TIControlledRecordArray = this.queueService.getAllEntries(<ELocalQueueName>controll);
                            for (const entry of entries) {
                                const interaction: IInteractionJSON = (<IInteractionJSON>entry.data);
                                if (this.socket.isOnControlDB(interaction.primaryID)) {
                                    await this.socket.sendJSONInteraction(interaction);
                                } else {
                                    this.queueService.removeFromStorage(entry.idQueue, entry.id);
                                }
                            }

                        } else if (controll != ELocalQueueName.batchableTracker || this.batchableCount === 0) {
                            const entries: TIControlledRecordArray = this.queueService.getAllEntries(<ELocalQueueName>controll);
                            const interactions: TInteractionArray = [];
                            for (const entry of entries) {
                                const interaction: Interaction = (<Interaction>entry.data);
                                interactions.push(interaction)
                            };

                            if (isValidArray(interactions)) {
                                const iResp: ClientInfraResponse = await this.sendAPIBatchInteractions(interactions);
                                if (iResp.friendlyMessage.isOk()) {
                                    const apiResponse: IBatchInteractionInsertResponse = (<IBatchInteractionInsertResponse>iResp.response);
                                };
                            };
                        };

                    } else if (this.queueService.isRetryRequest(<ELocalQueueName>controll)) {

                        const entries: TIControlledRecordArray = this.queueService.getAllEntries(<ELocalQueueName>controll);
                        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
                        for (const entry of entries) {
                            const request = (<IRequest>entry.data);
                            const response: ClientInfraResponse = await this.api.managedRequest(infra, request);
                            if (response.executionOK) {
                                this.queueService.removeFromStorage(entry.idQueue, entry.id);
                            } else {
                                this.queueService.republish(entry.idQueue, entry);
                            }
                        };
                    }

                };
            }, socketConfig.client.recovery.lockBatchProcess, semaphoreBatchSend);

        } catch (err) {

        } finally {

        }
    };


    public async sendAPIBatchInteractions(interactions: TInteractionArray): Promise<ClientInfraResponse> {
        const batch: IBatchTrackerInteractionRequest = this.rbs.getBatchInsertTrackerInteractionRequest(this.sessionService.getSelectedAvatar(), interactions);
        const infra: IInfraParameters = this.rbs.getContextNoDelegateNoSpinningParameters();
        const resp: ClientInfraResponse = await this.api.managedRequest(infra, batch);
        return resp;
    };












    public setDependSessionService(sessionService: SessionService): void { this.sessionService = sessionService; };
    public setDependRequestBuilderServices(rbs: RequestBuilderServices): void { this.rbs = rbs; }
    public setHardwareServices(hw: HardwareLayerService): void {this.hw = hw};
    public setSocketServices(socket: SocketService): void {this.socket = socket};
    public setDependencySemaphore(semaphore: SempaphoreService): void {this.semaphore = semaphore; };
    public setDepenendcyQueueService(queueService: QueuService): void {
        this.queueService = queueService;
        this.queueService.confQueue(ELocalQueueName.recoverableInteraction, {persistent: true, isInteraction: true});
        this.queueService.confQueue(ELocalQueueName.recoverableRequest, {persistent: true, isRequest: true});
        this.batchableCount = 0;
    };
    public setDependencyAPI(api: ServerCommunicationService): void {this.api = api; };



    private timersControl(): void {
        setInterval(async () => {
            if (this.hw && this.hw.getNetwork().isOnline()) {
                this.batchProcessing()
            };
        }, socketConfig.client.recovery.timeout);
    };



}


const semaphoreBatchSend: IClientSemaphoreConfig = {
    expirationLockTime: 5000,
    numberOfTries: 0,
    throwErrorIfNotLocked: false,
    retryInterval: 300
};
