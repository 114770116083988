import { ROUTES } from "@colmeia/core/src/core-constants/routes-shared";
import {serverRoute} from "../../../model/routes/route-constants";

declare var BackgroundGeolocation: any;

// Fix, when running in browser, this var will never be available, so crashing..
if(! window['BackgroundGeolocation']) {
    window['BackgroundGeolocation'] = {};
}

export namespace CordovaGeolocationConstants {
    export const Defaults = {
        // reset: true,
        logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
        desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
        desiredOdometerAccuracy: 10,
        stationaryRadius: 25,
        url: serverRoute(ROUTES.v1.tracking),
        batchSync: false,
        autoSync: true,
        heartbeatInterval: 30,
        stopOnTerminate: false,
        startOnBoot: true
    };

    export const AndroidOnly = {
        deferTime: 0
    };

    export const IOSOnly = {
        preventSuspend: true
    };

    export const EmergencyModeOn = {
        disableElasticity: true
    };

    export const EmergencyModeOff = {
        disableElasticity: false
    };
}
