import { trigger, state, style, transition, animate } from "@angular/animations";

export const animationReady = 'ready';

export const fadeInFromTop = trigger('fadeInFromTop', [
    state(animationReady, style({opacity: 1})),
    transition(`void => ${animationReady}`, [
        style({opacity: 0, transform:'translateY(-30px)'}),
        animate('300ms 0s ease-in-out')
    ])
]);

export const appearFromLeft = trigger('appearFromLeft', [
    state(animationReady, style({opacity: 1})),
    transition(`void => ${animationReady}`, [
        style({opacity: 0, transform:'translateX(-30px)'}),
        animate('300ms 0s ease-in')
    ])
]);

export const verticalAppearDurationMS: number = 200 as const;

export const verticalAppear = trigger('verticalAppear', [
    state('in', style({ height: '*' })),
    transition(':enter', [
        style({ height: '0', overflow: 'hidden' }),
        animate(`${verticalAppearDurationMS}ms ease-in-out`, style({ height: '*', })),
    ]),
    transition(':leave', [
        style({ height: '*' }),
        animate(`${verticalAppearDurationMS}ms ease-in-out`, style({ height: '0', overflow: 'hidden' })),
    ]),
]);

export const horizontalAppear = trigger('horizontalAppear', [
    state('in', style({ width: '*' })),
    transition(':enter', [
        style({ width: '0', overflow: 'hidden' }),
        animate('.2s ease-out', style({ width: '*' })),
    ]),
    transition(':leave', [
        style({ width: '*' }),
        animate('.2s ease-out', style({ width: '0', overflow: 'hidden' })),
    ]),
]);

export const widthHeightAppear = trigger('widthHeightAppear', [
    state('in', style({ width: '*', height: '*' })),
    transition(':enter', [
        style({ width: '0', height: '0', overflow: 'hidden', 'white-space': 'nowrap', opacity: 0 }),
        animate('.2s ease-out', style({ width: '*', height: '*', opacity: 1 })),
    ]),
    transition(':leave', [
        style({ width: '*', height: '*', opacity: 1 }),
        animate('.2s ease-out', style({ width: '0', height: '0', overflow: 'hidden', 'white-space': 'nowrap', opacity: 0 })),
    ]),
]);
