import { ComponentType } from "@angular/cdk/portal";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { GenericDashboardEditHandler, IGenericDashboardEditHandlerParameter, IWindowTranslation } from "app/handlers/generic-dashboard-edit.handler";
import { Subject } from "rxjs";
import { ColmeiaWindowConfig } from "./colmeia-window-config";
import { StaticProvider, TemplateRef } from "@angular/core";


export interface IWindowConfig<D = any, C = any> {
    component?: ComponentType<C>,
    templateRef?: TemplateRef<C>,
    title?: string,
    closeOnNavigation?: boolean,
    dialogConfig?: ColmeiaWindowConfig<D>,
}

export interface IWindowRuntimeSetupConfig<T = INonSerializable, D = any> extends IWindowConfig<D> {
    initialPath?: string,
    createPath: string,
    editPath: string,
    keepQueryParams?: boolean,

    hasNesting?: boolean,
    blockSaveButtonWithoutChanges?: boolean,
    getNonSerializableSIdFromUrl?: (allParams: Record<string, string>) => string,
    mountEditPath?: (nser: T) => string,
    mountCreatePath?: () => string,
    initializeNSObject: () => T,
    editHandlerParameters?:
    Omit<IGenericDashboardEditHandlerParameter, 'nsType' | 'nser' | 'clientCallback'> &
    { nsType?: IGenericDashboardEditHandlerParameter['nsType'] } &
    { clientCallback?: Partial<IGenericDashboardEditHandlerParameter['clientCallback']> },
    equalCompare?: (source: T, multable: T) => boolean;
}

export interface IWindowRef<NS extends object = INonSerializable> {
    object: NS;
    editHandler?: GenericDashboardEditHandler;
    close: (reload?: boolean, syncObjects?: boolean) => void;
    onSaveButtonClick: (handler: () => Promise<boolean>) => void;
    syncObjects: () => void;
}

export interface IWindowRuntime<NS = INonSerializable, D = any> {
    isEdit: boolean;
    sourceObject: NS;
    multableObject: NS;
    editHandler: GenericDashboardEditHandler;
    shouldReloadHomeList: boolean;
    subjects: IWindowRuntimeSubjets;
}

export interface IWindowRuntimeSubjets {
    buttonSaveClick: Subject<void>;
    close: Subject<void>;
    afterClose: Subject<void>;
    onSavedNS: Subject<boolean>;
    syncSourceObjects: Subject<void>;
    minimizeWindowClick: Subject<void>;
    startWindowMovement: Subject<void>;
    windowMovement: Subject<IWindowTranslation>;
    endWindowMovement: Subject<void>;
    component: Subject<ComponentType<unknown>>,
}

export interface IWindowRuntimeInitDescriptor {
    openCreateDialog?: boolean;
    openEditExact?: boolean;
    openEditSubset?: boolean;
    openEditByNest?: boolean;
    openEditDialog?: boolean;
    autoOpen?: boolean;
}

export enum EWindowStateChangeType {
    Minimized,
    Restore,
    Closed
}

export type TWindowStateChange = {
    windowIdx: number,
    type: EWindowStateChangeType
}

export type TColmeiWindowRintimeCompareFunction<O extends object = object> = (source: O, multable: O) => boolean;
