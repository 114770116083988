import { EBPMType } from "../../BPM/bpm-model";
import { EToolbarGroupType, EConnectorPointShapes, ISpotConnectorDecorator, IConnectorStyles, EArrowSourcedLineType } from "./config-toolbar.types";

export const defaultToolbarElement = {
    subjectArea: EBPMType.bot,
    groupType: EToolbarGroupType.BotRelated,
};

export function buildStandardConnection(connectorShape: EConnectorPointShapes, firstColor: string, secondColor?: string): IConnectorStyles {
    return {
        style: {
            strokeWidth: '2px',
            stroke: firstColor
        },
        type: EArrowSourcedLineType.Orthogonal,
    }
}