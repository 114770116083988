import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { ESendActiveMethod } from "../../active-1x1-call/active-1x1-model";
import { deprecatedSuggestions } from "../../../tools/type-utils";

export const activeCallScreenOption = typedScreenOptions({
    idSerializable: EAllSerializable.ActiveCall,
    fields: {
        activeCall: 1, // Iniciar conversa
        activeCallIcon: 18, // 
    }
} as const);


export const sendActiveMethodScreenOption = typedScreenOptions({
    idSerializable: EAllSerializable.SendActiveMethod,
    fields: deprecatedSuggestions<{ [key in ESendActiveMethod]: number }>()({
        [ESendActiveMethod.campaignOnly]: 1, // Campanha
        [ESendActiveMethod.customMessage]: 2, // Mensagem customizada
        [ESendActiveMethod.templateOnly]: 3, // Somente template
    }).ok,
} as const);

export const sendActiveMethodTranslations = getTranslationsFields(sendActiveMethodScreenOption);
export const activeCallTranslations = {
    ...getTranslationsFields(activeCallScreenOption),
    ...sendActiveMethodTranslations,
};
