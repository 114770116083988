export enum EFeatures {
    Notification = 'notification',
    Rosetta = 'rosetta',
    SocialBind = 'socialbind',
    Billing = 'billing',
    Location = 'geolocation',
    Wallet = 'wallet',
    createNewAvatar = 'createNewAvatar',
    Annotations = 'Annotations',
    Informations = 'Informations',
    changePassword = 'changePassword'
}

/*
Vincular contas
Pagamento
Idioma
Notificações
Localização
*/