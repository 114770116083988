import { Injectable } from '@angular/core';
import { EBPMMarketingRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { TBpmMarketingNaturalNsTypes, TBpmMarketingNserClientAndServer, TBpmMarketingNserServer } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { IBPMMarketingSaveRequest, IBPMMarketingSaveResponse } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-matketing-req-res.dto';
import { TINonSerializableArray } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ServerCommunicationService } from 'app/services/server-communication.service';

@Injectable({
    providedIn: 'root'
})
export class MktBpmDataService {

    constructor(private api: ServerCommunicationService) { }

    async save(nsType: TBpmMarketingNaturalNsTypes, ns: TBpmMarketingNserClientAndServer): Promise<TINonSerializableArray> {
        
        const response = await this.api.sendRequest<IBPMMarketingSaveRequest, IBPMMarketingSaveResponse>(
            EBPMMarketingRequest.mktBpmSave
        )({
            data: {
                nsType, ns
            }
        });

        return response?.savedElements || [];
    }
}
