import { Injectable } from '@angular/core';
import { Avatar, Group, Participant, TAvatarArray } from '@colmeia/core/src/business/barrel-business';
import { IGroupJSON } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { TArrayAvatar, TArrayID, TExtendedParticipantArray, TGlobalUID, TGroupArray } from '@colmeia/core/src/core-constants/types';
import { errorCodes } from '@colmeia/core/src/error-control/error-definition';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { TInteractionArray } from '@colmeia/core/src/interaction/interaction';
import { MultimediaObject } from '@colmeia/core/src/multi-media/multi-media-object';
import {
    IGetGroupCreateEditSupportData,
    IGroupCreationRequest, IGroupEditRequest, IManageAvatarOnGroupRequest, IManageGroupOnDistributionListRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    ICreateParticipantResponse,
    IGetGroupAvatarsResponse,
    IGetGroupChildResponse, IGetGroupDataResponse,
    IGetGroupSecuritySettingsResponse, IGroupCreationResponse,
    TParticipantRolePair
} from "@colmeia/core/src/request-interfaces/response-interfaces";
import { SecuritySettings } from '@colmeia/core/src/security/security-settings';
import { delay, isValidRef, throwErrorIfTrue } from '@colmeia/core/src/tools/utility';
import { InteractionPersistorServices } from './interaction-persistor.service';
import { ServerCommunicationService } from './server-communication.service';

import {
    ISetRoleRequest
} from "@colmeia/core/src/request-interfaces/security-interfaces";



import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IClientGroupData, IGroupAndItsChilds } from "../model/child-groups.model";
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { GroupSubscription } from "../model/group-subscription.model";
import { InteractionService } from "./interaction.service";
import { PlayerInfoService } from "./player-info.service";
import { RequestBuilderServices } from "./request-builder.services";
import { SessionService } from "./session.service";
import { SocialNetworkDatabaseService } from "./social-network-database.service";
import { SusbcriptionContainerService } from "./subscriptions.service";
import { UniversalRehydrator } from "./universal-rehydrator";

export interface IGetCreateEditSupportData {
    distributionListGroups: TGroupArray;
    securitySettings: SecuritySettings;
    rolesParticipant: TParticipantRolePair;
    hasVinculatedSecuritySettings: boolean;
    currentGroupState: IGroupJSON;
};

@Injectable()
export class GroupPersistorServices {
    private static groupInProssing: { [idGroup: string]: boolean } = {};
    private infra: UniversalRehydrator;
    private sessionService: SessionService;
    private rbs: RequestBuilderServices;
    private server: ServerCommunicationService;
    private subscriptionContainer: SusbcriptionContainerService;
    private interactionService: InteractionService;

    constructor(
        private playerInfoServices: PlayerInfoService,
        private interactionComm: InteractionPersistorServices,
        private snDatabase: SocialNetworkDatabaseService,
    ) { };

    public setDependencySubscriptionContainer(subsContainer: SusbcriptionContainerService): void { this.subscriptionContainer = subsContainer; };
    public setDependencyCommunucationService(server: ServerCommunicationService): void { this.server = server; };
    public setDependencyRequestBuilderService(rbs: RequestBuilderServices): void { this.rbs = rbs; };
    public setDependencySessionService(session: SessionService): void { this.sessionService = session };
    public setDependencyUniversalRehydrator(rehy: UniversalRehydrator): void { this.infra = rehy; };
    public setDependencyInteractionService(interactionService: InteractionService): void { this.interactionService = interactionService; };

    public async enterGroupChat(idGroup: TGlobalUID): Promise<boolean> {
        const current: GroupSubscription = this.subscriptionContainer.getSelectedSubscription();
        let subs: GroupSubscription;

        if (this.subscriptionContainer.getSelectedGroupID() != idGroup) {

            subs = await this.createSubscription(idGroup, this.sessionService.getSelectedAvatar().getParentEntityID(),
                this.sessionService.getSelectedAvatar().getPrimaryID());
            // JC deslogar do server
            // notify listeners about group change
        } else {
            subs = current;
        };

        return subs ? true : false;
    }

    public async createSubscription(idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID, changeGlobal: boolean = true): Promise<GroupSubscription> {
        // "Semaphore"
        try {
            if (GroupPersistorServices.groupInProssing[idGroup]) {
                while (GroupPersistorServices.groupInProssing[idGroup]) {
                    await delay(300);
                };
            } else {
                GroupPersistorServices.groupInProssing[idGroup] = true;
            };

            let model: GroupSubscription = this.subscriptionContainer.getGroupSubscriptionModel(idGroup);
            let groupData: IClientGroupData;

            if (!model) {
                try {
                    console.log('Calling With IDGROUP', idGroup, idPlayer, idAvatar);

                    groupData = await this.getGroupData(idGroup, idPlayer, idAvatar, null);
                    model = await this.subscriptionContainer.groupSubscriptionModelFactory(groupData.group, groupData.interactions,
                        groupData.childGroups, groupData.childGroups);
                } catch (err) {
                    // pls por mensagem de erro
                    console.error('GroupPersistorServices.constructSubscription error: ', err);
                } finally {
                    delete GroupPersistorServices.groupInProssing[idGroup];
                }
            }
            delete GroupPersistorServices.groupInProssing[idGroup];

            if (model && changeGlobal) {
                this.sessionService.setSubscription(model)
                this.subscriptionContainer.changeSelectedSubscription(model);
            }
            return model;

        } catch (err) {
            delete GroupPersistorServices.groupInProssing[idGroup];
            throw err;

        }

    }


    public async forceCreateSubscription(idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID): Promise<GroupSubscription> {
        let model: GroupSubscription;
        let groupData: IClientGroupData;


        try {
            console.log('Calling With IDGROUP', idGroup, idPlayer, idAvatar);

            groupData = await this.getGroupData(idGroup, idPlayer, idAvatar, null);
            model = await this.subscriptionContainer.groupSubscriptionModelFactory(groupData.group, groupData.interactions,
                groupData.childGroups, groupData.childGroups);
        } catch (err) {
            // pls por mensagem de erro
            console.error('GroupPersistorServices.constructSubscription error: ', err);
        } finally {
            delete GroupPersistorServices.groupInProssing[idGroup];
        }

        return model;
    }





    public getSubscriptionByGroupID(idGroup: string, changeGlobal: boolean = true): Promise<GroupSubscription> {
        return new Promise((resolve, reject) => {
            // If I do not have avatar I cannot make a subscription
            const noPlayerOrAvatar = !this.sessionService.getSelectedAvatar() || !this.sessionService.getPlayer();
            if (noPlayerOrAvatar) {
                const error = new FriendlyMessage('getSubscriptionById', false);
                error.add(errorCodes.player.avatarDoesNotExist, 'You do not have an avatar or player');
                reject(error);
                return;
            }

            // Construct subscription
            const idAvatar: TGlobalUID = this.sessionService.getSelectedAvatar().getAvatarID();
            const idPlayer: TGlobalUID = this.sessionService.getPlayer().getPlayerID();
            this.createSubscription(idGroup, idPlayer, idAvatar, changeGlobal)
                .then((subs) => {
                    resolve(subs);
                })
                .catch(err => reject(err));
        });
    }

    public async getGroupChild(type: number, idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID): Promise<IGroupAndItsChilds> {
        const parameter: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(idPlayer, idAvatar);
        const clientInfra: ClientInfraResponse = await this.server.managedRequest(parameter,
            this.rbs.getGroupChildRequest(type, idGroup, idPlayer, idAvatar));
        let resp: IGroupAndItsChilds = null;

        if (clientInfra.executionOK) {
            const result: IGetGroupChildResponse = <IGetGroupChildResponse>clientInfra.response;
            const group: Group = this.infra.localRehydrate<Group>(result.group);
            const childGroups: TGroupArray = result.childGroups.map((grp: IGroupJSON) => this.infra.localRehydrate<Group>(grp));
            resp = { group: group, childGroups: childGroups };
        }
        return resp;
    };

    public async getGroupSecuritySettings(idGroup: TGlobalUID, isNew: boolean): Promise<IGetCreateEditSupportData> {
        const parameter: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();
        const clientInfra: ClientInfraResponse = await this.server.managedRequest(parameter,
            <IGetGroupCreateEditSupportData>{
                ...
                this.rbs.createRequestFromInfraParameters(apiRequestType.security.getGroupCreateEditSupportData, parameter),
                idGroup: idGroup,
                isNew: isNew
            }
        );
        let resp: IGetCreateEditSupportData = null;
        if (clientInfra.executionOK) {
            const result: IGetGroupSecuritySettingsResponse = <IGetGroupSecuritySettingsResponse>clientInfra.response;
            const secSettings: SecuritySettings = result.securitySettings ? SecuritySettings.factoryMessage(result.securitySettings)
                : new SecuritySettings();
            const avatarArray: TArrayAvatar = [];
          
            const groupArray: TGroupArray = [];
            for (const group of result.distributionGroups) {
                groupArray.push(Group.factoryMessage(group));
            };

            resp = {
                securitySettings: secSettings,
                distributionListGroups: groupArray,
                rolesParticipant: result.rolesParticipant,
                hasVinculatedSecuritySettings: result.hasVinculatedSecuritySettings,
                currentGroupState: result.currentGroupState
            };
        }
        return resp;
    };

    public async getGroupAvatars(idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID, amountItemsPerPage?: number, cursor: string | undefined = undefined): Promise<{ avatars: TArrayAvatar, cursor: string | undefined } | null> {
        const parameter: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(idPlayer, idAvatar);
        const clientInfra: ClientInfraResponse = await this.server.managedRequest(parameter,
            this.rbs.getGroupAvatarsRequest(idGroup, idPlayer, idAvatar, amountItemsPerPage, cursor));

        if (!clientInfra.executionOK) {
            return null;
        }
        const result: IGetGroupAvatarsResponse = <IGetGroupAvatarsResponse>clientInfra.response;
        const avatars: TArrayAvatar = await this.infra.rehydrateArrayJSON<TArrayAvatar>(result.avatars);

        return { avatars, cursor: result.cursor };
    };

    public async getGroupData(idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID, cursor: string): Promise<IClientGroupData> {
        const parameter: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(idPlayer, idAvatar);
        const clientInfra: ClientInfraResponse = await this.server.managedRequest(parameter,
            this.rbs.getGroupDataRequest(idGroup, idPlayer, idAvatar, cursor));
        let resp: IClientGroupData;

        if (clientInfra.executionOK) {
            const result: IGetGroupDataResponse = <IGetGroupDataResponse>clientInfra.response;
            resp = this.processGetGroupData(result, []);
        };

        return resp;
    };

    public processGetGroupData(result: IGetGroupDataResponse, alreadyRehydrated: TInteractionArray): IClientGroupData {
        const resp: IClientGroupData = {
            group: this.infra.localRehydrate<Group>(result.group),
            childGroups: this.infra.localrehydrateArrayJSON(result.childGroups),
            participant: null,
            interactions: null,
            interactionFeatures: []
        };

        resp.interactions = this.interactionService.rehydrateInteractions(result.interactions, alreadyRehydrated);
        if (result.participant) {
            resp.participant = this.infra.localRehydrate<Participant>(result.participant);
        };
        return resp;
    };

    public async createParticipantWithSubscription(groupId: string): Promise<void> {

        const parameter: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(this.sessionService.getSelectedAvatar().getParentEntityID(),
            this.sessionService.getSelectedAvatar().getAvatarID());

        const clientInfra: ClientInfraResponse = await this.server.managedRequest(parameter,
            this.rbs.getCreateParticipantRequest(this.sessionService.getSelectedAvatar(), groupId));

        throwErrorIfTrue(!clientInfra.executionOK, errorCodes.participant.cantCreateParticipant, true, 'createParticipantWithSubscription');
    };

    public async createParticipant(mySubscription: GroupSubscription): Promise<GroupSubscription> {

        const parameter: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(this.sessionService.getPlayerID(),
            this.sessionService.getAvatarID());


        const clientInfra: ClientInfraResponse = await this.server.managedRequest(parameter,
            this.rbs.getCreateParticipantRequest(
                this.sessionService.getSelectedAvatar(),
                mySubscription.getGroup().getGroupID()));
        let resp: GroupSubscription;

        if (clientInfra.executionOK) {
            const result: ICreateParticipantResponse = <ICreateParticipantResponse>clientInfra.response;
            this.subscriptionContainer.changeSelectedSubscription(mySubscription);
            resp = mySubscription;
        };
        return mySubscription;
    };

    public async updateSubGroup(
        parameters: IInfraParameters,
        group: Group,
        editingMultimedia: MultimediaObject,
        avatar: Avatar
    ): Promise<ClientInfraResponse> {
        const updateRequest: IGroupEditRequest = this.rbs.getUpdateGroupRequest(group, avatar);
        // O multimedia do cliente só é atualizado quando for tudo OK
        if (editingMultimedia) {
            updateRequest.group.multimediaObject = editingMultimedia.toJSON();
        };
        const infraResponse: ClientInfraResponse = await this.server.managedRequest(parameters, updateRequest);
        await this.upsertClientDatabases(group, infraResponse)
        return infraResponse;
    }

    public async createSubGroup(
        parameters: IInfraParameters,
        group: Group,
        editingMultimedia: MultimediaObject,
        avatar: Avatar,
        selectedExtendedParticipants: TExtendedParticipantArray,
        additional?: Partial<IGroupCreationRequest>
    ): Promise<ClientInfraResponse> {
        const createRequest: IGroupCreationRequest = this.rbs.getCreateGroupRequest(group, avatar, selectedExtendedParticipants, additional);
        // O multimedia do cliente só é atualizado quando for tudo OK
        if (editingMultimedia) {
            createRequest.group.multimediaObject = editingMultimedia.toJSON();
        };
        const infraResponse: ClientInfraResponse = await this.server.managedRequest(parameters, createRequest);
        await this.upsertClientDatabases(group, infraResponse)

        return infraResponse;
    };

    async upsertClientDatabases(group: Group, infraResponse: ClientInfraResponse) {
        const response: IGroupCreationResponse = <IGroupCreationResponse>infraResponse.response;
        if (infraResponse.executionOK) {
            group.rehydrate(response.group);
            group.addUberCache();

            const myParticipant: Participant = this.infra.localRehydrate<Participant>(response.participant);
            const isNewSocialNetworkCreated = isValidRef(response.socialData)
            if (isNewSocialNetworkCreated) {
                const isSocialContext = group.getPrimaryID()
                this.snDatabase.insertSN(isSocialContext, response.socialData)
            } else {
                this.snDatabase.insertGroupsAndParticipants(this.sessionService.getCurrentSocialNetworkID(),
                    [response.group], [response.participant])
            }

            await this.createSubscription(group.getGroupID(), myParticipant.getAvatar().getParentEntityID(),
                myParticipant.getAvatar().getAvatarID());
            // Chech if I have the parent group
            if (response.group.groupParent &&
                this.subscriptionContainer.getGroupSubscriptionModel(response.group.groupParent.primaryID)) {
                this.subscriptionContainer.getGroupSubscriptionModel(response.group.groupParent.primaryID).addGroupChild(group);
            };
        };
    }

    async setAvatarRoles(idAvatarGrantee: TGlobalUID, idGroup: TGlobalUID, idNewRoles: TArrayID): Promise<ClientInfraResponse> {
        const parameters: IInfraParameters = this.rbs
            .getNoCallBackSpinnningParameters(
                this.sessionService.getPlayerID(),
                this.sessionService.getAvatarID());

        const req: ISetRoleRequest = this.rbs.getSetRoleRequest(
            this.sessionService.getPlayerID(),
            this.sessionService.getAvatarID(),
            this.sessionService.getParticipant(idGroup).getPrimaryID(),
            idAvatarGrantee,
            idGroup,
            idNewRoles);

        const setAvatarRolesInfraResponse: ClientInfraResponse = await this.server.managedRequest(parameters, req);
        return setAvatarRolesInfraResponse;
    }

    async addGroupToDL(idGroup: TGlobalUID, idDistributionList: TGlobalUID): Promise<boolean> {
        return this.updateGroupOnDistributionList(
            apiRequestType.groups.addGroupOnDL,
            idGroup,
            idDistributionList
        );
    }

    async removeGroupFromDL(idGroup: TGlobalUID, idDistributionList: TGlobalUID): Promise<boolean> {
        return this.updateGroupOnDistributionList(
            apiRequestType.groups.removeGroupOnDL,
            idGroup,
            idDistributionList
        );
    }

    private async updateGroupOnDistributionList(requestType: TGlobalUID, idGroup: TGlobalUID, idDistributionList: TGlobalUID): Promise<boolean> {
        const infra: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(
            this.sessionService.getPlayer().getPrimaryID(),
            this.sessionService.getSelectedAvatarID()
        );

        const req: IManageGroupOnDistributionListRequest = {
            ...this.rbs.secureBasicRequest(requestType),
            idGranteeGroup: idGroup,
            idDistributionListGroup: idDistributionList
        };
        const response = await this.server.managedRequest(infra, req);

        return response.executionOK;
    }

    async addAvatar(idAvatar: TGlobalUID, idGroup: TGlobalUID): Promise<boolean> {
        return this.updateGroupMember(
            apiRequestType.groups.addAvatar,
            idGroup,
            idAvatar
        );
    }

    async removeAvatar(idAvatar: TGlobalUID, idGroup: TGlobalUID): Promise<boolean> {
        return this.updateGroupMember(
            apiRequestType.groups.removeAvatar,
            idGroup,
            idAvatar
        );
    }

    private async updateGroupMember(requestType: string, idTargetGroup: TGlobalUID, idGranteeAvatar: TGlobalUID): Promise<boolean> {

        const infra: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(
            this.sessionService.getPlayer().getPrimaryID(),
            this.sessionService.getSelectedAvatarID()
        );

        const req: IManageAvatarOnGroupRequest = {
            ...this.rbs.secureBasicRequest(requestType),
            idTargetGroup,
            idGranteeAvatar
        };
        const response = await this.server.managedRequest(infra, req);

        return response.executionOK;
    }
};
