import { MetaTranslation } from "@colmeia/core/src/shared-business-rules/const-text/meta-translation";
import { asConst, deprecatedSuggestions } from "../../../tools/type-utils";
import { typedScreenOptions } from "../../../tools/utility";
import { EJobStatus, EJOBType } from "../../jobs/jobs-model";
import { defineTranslations, EAllSerializable, getTranslationsFields } from "../all-serializables";



const jobStatusScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.JobStatus,
    fields: deprecatedSuggestions<{ [key in EJobStatus]: number }>()({
        [EJobStatus.tobestarted]: 1, // 
        [EJobStatus.ongoing]: 2, // 
        [EJobStatus.finished]: 3, // 
    }).ok,
})


export const jobTypeTranslations = defineTranslations({
    idSerializable: EAllSerializable.JobStatus,
    fields: asConst<{ [key in EJOBType]: number }>()({
        [EJOBType.campaignExecute]: MetaTranslation.field(1, 'Envio de campanhas'),
        [EJOBType.patchApply]: MetaTranslation.field(2, 'patchApply'),
        [EJOBType.patchDependencyResolution]: MetaTranslation.field(3, 'Processamento de itens de migração'),
        [EJOBType.machineLearningTrainingProcessing]: MetaTranslation.field(4, 'Treinamento da base'),
        [EJOBType.deleteAvatarFromSocialNetwork]: MetaTranslation.field(5, 'Deletar usuário da rede social'),
        [EJOBType.deleteFileContents]: MetaTranslation.field(6, 'Deletar conteúdo dos arquivos'),
        [EJOBType.deleteFile]: MetaTranslation.field(7, 'Deletar arquivo'),
        [EJOBType.deleteNS]: MetaTranslation.field(8, 'Deletar entidade'),
        [EJOBType.processElasticSearchDatabase]: MetaTranslation.field(9, 'Processamento de banco de dados no elastic search'),
        [EJOBType.fileUploadProcessor]: MetaTranslation.field(10, 'Processamento de upload de arquivos'),
        [EJOBType.downloadDatabaseUrl]: MetaTranslation.field(11, 'Baixar banco'),
        [EJOBType.processUntrainedUtterances]: MetaTranslation.field(12, 'Processamento de utterances'),
        [EJOBType.sendAllProvidersToReview]: MetaTranslation.field(13, 'Enviar para todos os provedores'),
        [EJOBType.checkTemplateApprovals]: MetaTranslation.field(14, 'Verificar aprovações nos templates.'),
        [EJOBType.backupCreateSnapshot]: MetaTranslation.field(15, 'Criar snapshot'),
        [EJOBType.backupRestoreSnapshot]: MetaTranslation.field(16, 'Restaurar snapshot'),
        [EJOBType.cancelRunCampaign]: MetaTranslation.field(17, 'Cancelamento de envio de campanha'),
        [EJOBType.rescheduleCampaign]: MetaTranslation.field(18, 'Reagendamento de campanhas'),
        [EJOBType.userGroupProfileUpdate]: MetaTranslation.field(19, 'Atualizações de Perfil de Usuário'),
        [EJOBType.generativeTrainingIntentProcessing]: MetaTranslation.field(20, 'Generativo - Processamento de intenção'),
        [EJOBType.approveSocialNetworkParticipationInBatch]: MetaTranslation.field(21, 'Aprovação De Múltiplos Usuários Em Rede Social'),
        [EJOBType.dataExtractionLineToLine]: MetaTranslation.field(22, 'Extração de dados'),
        [EJOBType.updateWAFSchema]: MetaTranslation.field(23, 'Atualização de schema WAF'),
        [EJOBType.migrateWhatsAppSMProvider]: MetaTranslation.field(24, 'Migração de provedor de WhatsApp'),
        [EJOBType.fetchContentFromURLsInVector]: MetaTranslation.field(25, 'Obter conteúdo das URL(s) no vetor.'),
    }),
});

export const jobTranslations = {
    ...getTranslationsFields(jobStatusScreenOptions),
    ...jobTypeTranslations,
}
