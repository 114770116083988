import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TCheckedContactArray } from '@colmeia/core/src/comm-interfaces/business-interfaces';

export type TOpenActiveCallContactSelectComponentInput = {
    contacts: TCheckedContactArray,
    maxSelection: number
}

export type TOpenActiveCallContactSelectComponentOutput = {
    contacts: TCheckedContactArray,
}

@Component({
    selector: 'app-open-active-call-contact-select',
    templateUrl: './open-active-call-contact-select.component.html',
    styleUrls: ['./open-active-call-contact-select.component.scss']
})
export class OpenActiveCallContactSelectComponent implements OnInit {
    public selectedContacts: TCheckedContactArray = [];
    public isMultiple: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: TOpenActiveCallContactSelectComponentInput,
        public dialogRef: MatDialogRef<OpenActiveCallContactSelectComponent, TOpenActiveCallContactSelectComponentOutput>
    ) { }

    ngOnInit(): void {
        this.isMultiple = this.data.maxSelection > 1;
    }

    save() {
        this.dialogRef.close({
            contacts: this.selectedContacts
        });
    }
}
