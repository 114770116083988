import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const gpsTrackingViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.GpsTrackingView,
    fields: {
        activity: 1,
        battery: 2,
        notBeingTracked: 3,
        trackingFinished: 4,
        distanceBetween: 5,
        lastPositions: 6,
        precisionMeters: 7,
        when: 8,
        meters: 9,
        altitude: 10,
        precision: 11,
        speed: 12,
        filterByPeriod: 13,
        isBeingTracked: 14,
        isMovement: 15, // Em Movimento,
        accuracy: 16, // Acurácia
        isTracking: 17, //  Real time tracker iniciado 
        isEndTracked: 18, // não está sendo rastreado
        expiredTracking: 19, //rastreamento expirou
        isNotTracking: 20, //Real-time tracker finalizado
        minDistance: 21, // Distância mínima entre pontos (em metros)
        minAccuracy: 22,// Maior margem de erro Aceita (em metros)
        now: 23, // agora
    }
} as const);

export const gpsTranslations = getTranslationsFields(gpsTrackingViewScreenOptions);