import { FriendlyMessage } from "../../../../tools/barrel-tools";
import { socialNetworkDomainRegex } from "../../../connections/connections-constants";
import { ISocialNetworkSettingsServer } from "../../../social-network/social-network-user-settings.model";
import { invalidDomainError } from "../error-messages";
import { TNserValidator } from "../validate-nser";

export const socialNetworkConfigValidator: TNserValidator = (nser: ISocialNetworkSettingsServer, friendly: FriendlyMessage) => {
    for (const domain of nser.domain)
        if (!socialNetworkDomainRegex.test(domain)) friendly.addReturnResponse(invalidDomainError)
    ;
    
    return friendly;
}
