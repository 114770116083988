import { INextGenBotServer, ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";

export class BotNavigationControl {

    private navigationControl: Array<INextGenBotServer> = [];

    constructor() { }
    

    push(botElement: INextGenBotServer): void {
        this.navigationControl.push(botElement);
    }

    pop(): INextGenBotServer {
        return this.navigationControl.pop();
    }

    getRoot(): INextGenBotServer {
        return this.navigationControl
            ? this.getLastRootItem()
            : undefined;
    }

    getParentElementName(idBotElement: string): string {
        const index = this.navigationControl.findIndex(bot => (bot as INextGenBotServer).idNS === idBotElement);
        if (index > 0) {
            return this.navigationControl[index - 1].nName;
        } else {
            return null;
        }
    }


    getLastRootItem() {
        const rootItems: INextGenBotServer[] = this.navigationControl
            .filter((bot: INextGenBotServer) => bot.botLevel === ENextGenBotElementType.root)
            ;


        return rootItems[rootItems.length - 1];
    }


    getLastItem(): INextGenBotServer {
        const botElement = this.navigationControl[this.navigationControl.length - 1];
        return botElement;
    }

    clear(): void {
        this.navigationControl = [];
    }

}