<mat-radio-group
  color="primary"
  (ngModelChange)="setCurrentLang($event)"
  [ngModel]="lang"
>
  <ul>
    <li *ngFor="let lang of getAllLanguage()">
      <div class="language__item">
        <div class="language--img">
          <img [src]="flags[lang.getLanguageID()]"/>
        </div>
        <div class="language--name">
          {{ lang.getLanguageName() }}
        </div>
        <div class="language--switch">
          <mat-radio-button
            [value]="lang.getLanguageID()"
          >
          </mat-radio-button>
        </div>
      </div>
    </li>
  </ul>
</mat-radio-group>
