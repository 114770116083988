import {TNserValidator} from "../validate-nser";
import {FriendlyMessage} from "../../../../error-control/friendly-message";
import {
    nserInsertNameError,
    nserSmallNameError,
    accessTypeError,
    controledMenusError,
    idGroupError
} from "../error-messages";
import {minLength, nserNameMinLength, isValidEnum} from "../helpers";
import {isInvalid, isInvalidArray} from "../../../../tools/utility";
import { IAccessRole } from "../../../security/security-control.model";
import { EGroupContainer } from "../../../group-container/group-container-config";

export const accessRoleValidator: TNserValidator = (nser: IAccessRole, friendly: FriendlyMessage) => {

    if (! nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    if (isInvalid(nser.idGroup)) 
        friendly.addReturnResponse(idGroupError)
    ;

    if (isInvalid(nser.accessType) || !isValidEnum(EGroupContainer, nser.accessType))
        friendly.addReturnResponse(accessTypeError)
    ;

    // if ( nser.accessType === EGroupContainer.dashboardMenus && isInvalidArray(nser.controledMenus)) 
    //     friendly.addReturnResponse(controledMenusError)
    // ;

    return friendly;
};

