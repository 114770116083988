import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {NCardModel} from "../../../../model/card.model";
import ECardTheme = NCardModel.ECardTheme;
import {TCardButtons, TCardToggleCallback} from "../../../../model/card-types";


@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
	@Input() cardDescriptor: NCardModel.ICardDescriptor;
	@Input() buttons: TCardButtons;
    @ViewChild('sectionContent', {static: true}) sectionContent: ElementRef;
    // TODO: remove this crap
	public displayType: string = 'block';

	@Input() openCallback: TCardToggleCallback;
	@Input() closeCallback: TCardToggleCallback;

	constructor() { }

	ngOnInit() { }

    isSmallFontSize(): boolean {
        return this.cardDescriptor.titleSize === NCardModel.ETitleSize.Small
    }

    isMediumFontSize(): boolean {
        return this.cardDescriptor.titleSize === NCardModel.ETitleSize.Medium
    }

    public getTitle(): string {
        return this.cardDescriptor && this.cardDescriptor.title;
    }

    public canShowContent(): boolean {
        return this.cardDescriptor && this.cardDescriptor.contentsShown;
    }

    public isTitleGrey(): boolean {
        const ret = this.cardDescriptor &&
            this.cardDescriptor.titleColor &&
            this.cardDescriptor.titleColor === NCardModel.ECardTitleColor.Grey;
        return ret;
    }

	public toggleCard(): void {
	    if (!this.cardDescriptor.contentsShown && typeof this.openCallback === 'function') {
	        this.openCallback();
        } else if (this.cardDescriptor.contentsShown && typeof this.closeCallback === 'function') {
	        this.closeCallback();
        }
		this.cardDescriptor.contentsShown = !this.cardDescriptor.contentsShown;
	}

	public arrowDisplayNone(): string {
        if (!this.cardDescriptor)
            return this.displayType;

		if (this.cardDescriptor.displayArrow) {
			this.displayType = 'block';
		} else {
			this.displayType = 'none';
		}

		return this.displayType;
	}

	public safeButtons(): TCardButtons {
	    return this.buttons || [];
    }
    get safeTheme(): NCardModel.ECardTheme {
	    return (this.cardDescriptor)
            ? this.cardDescriptor.theme || NCardModel.ECardTheme.Invisible
            : NCardModel.ECardTheme.Invisible;
    }

    public getThemeClass() {
	    const ret = {};
	    switch (this.safeTheme) {
            case NCardModel.ECardTheme.Invisible:
                ret['invisible--theme'] = true;
                break;
            case NCardModel.ECardTheme.LightGrey:
                ret['light-grey--theme'] = true;
                break;
        }
        return ret;
    }

    canShowTitle(): boolean {
        return this.cardDescriptor ? this.cardDescriptor.displayTitle : true
    }
}
