import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { getCoundown } from "@colmeia/core/src/time/time-utl";
import { getClock, isValidNumber } from "@colmeia/core/src/tools/utility";


export interface IRateLimitBlockComponentProps {
    expireAt?: number;
}

@Component({
    selector: 'app-rate-limit-clock-component',
    templateUrl: './rate-limit-block.component.html',
    styleUrls: ['./rate-limit-block.component.scss']
})
export class RateLimitBlockComponent implements OnInit, OnDestroy {

    public expireAt?: number;
    public unlockTimeout!: string;
    public countDownInterval?: NodeJS.Timeout;
    public hasCoundown: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) private dialogData: IRateLimitBlockComponentProps) {
        this.expireAt = dialogData.expireAt;
        this.hasCoundown = isValidNumber(dialogData.expireAt);
    }

    ngOnInit(): void {
        this.hasCoundown && this.initCoundown();
    }

    ngOnDestroy(): void {
        clearInterval(this.countDownInterval);
    }

    private initCoundown() {
        this.countDownInterval = setInterval(() => {
            this.unlockTimeout = getCoundown(getClock(), this.expireAt);
        }, 1000);
    }
}
