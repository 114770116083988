import { factoryOf } from "@colmeia/core/src/tools/utility/functions/factory-of";
import { IMenuDashboardOption } from "app/components/menu-dashboard/menu-dashboard.service";
import { MainHandler } from "app/handlers/main-handler";
import {IComponentParameter } from "app/model/component-comm/basic";

export interface IDashboardMenuParameters extends IComponentParameter {
    module: IMenuDashboardOption;
}

export class DashboardMenuHandler extends MainHandler<IDashboardMenuParameters> {
    public constructor(parameter: IDashboardMenuParameters) {
        super(parameter);
    }

    public static factory = factoryOf(this);
    
    public get parameters() { return this.getComponentParameter() }
}