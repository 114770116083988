import { customSvgIcons } from 'app/utils/custom-svg-icons';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isThisOneOfThat, isValidRef } from "@colmeia/core/src/tools/utility";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { ignoreAuthMessageTypes } from "@colmeia/core/src/request-interfaces/message-types";
import {HardwareLayerService} from "../hardware";
import {clientConstants} from "../../model/constants/client.constants";
import {IAuthenticationAuthorization} from "../../model/component-comm/auth";

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
    constructor(private hw: HardwareLayerService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let useAuthToken: boolean = !customSvgIcons.some( iconConfig => iconConfig.url === request.url);

        const body: IRequest = request.body || {};

        if (isValidRef(body.requestType) && isThisOneOfThat(body.requestType, ...ignoreAuthMessageTypes)) {
            useAuthToken = false;
        }

        const authToken: string = window._COLMEIA_.getToken();

        if(authToken && useAuthToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: authToken
                }
            });
        }

        return next.handle(request);
    }
}
