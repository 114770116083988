import { TGlobalUID } from "../core-constants/types";
import { constant } from "../business/constant";

export enum PaymentStatus {
    Approved = 'payment.approved',
    Analysis = 'payment.analysis',
    Pending = 'payment.pending',
    Cancelled = 'payment.cancelled',
    Chargeback = 'payment.chargeback',
    Expired = 'payment.expired',
    MediationLoss = 'payment.mediation.loss',
    MediationStart = 'payment.mediation.start',
    MediationWon = 'payment.mediation.won',
    PartialRefunded = 'payment.refunded.partial',
    Refunded = 'payment.refunded.fully',
    Rejected = 'payment.rejected',
}

export type ColmeiaStatusRelatedGatewayStatus = {
    [gatewayStatus: string]: PaymentStatus;
}

export const relatedPaymentStatus: ColmeiaStatusRelatedGatewayStatus = {
    'approved': PaymentStatus.Approved,
    'pending': PaymentStatus.Pending,
    'in_process': PaymentStatus.Pending,
    'rejected': PaymentStatus.Rejected,
    'refunded': PaymentStatus.Refunded,
    'cancelled': PaymentStatus.Cancelled,
    'in_mediation': PaymentStatus.MediationStart,
    'charged_back': PaymentStatus.Chargeback,
};

export enum EPaymentNotificationType {
    NoNotification = 1,
    EmailNotification = 2
}

export interface IPaymentStatusStructure {
    notification: EPaymentNotificationType;
    feedback: PaymentStatus;
    invalidAfter: TPaymentStatusList;
    expectedAfter: TPaymentStatusList;
    isApproved: boolean;
    isNegative: boolean;

};

export type TPaymentStatusList = Array<PaymentStatus>;

export interface IPaymentStatusRecord {
    [idStatus: string]: IPaymentStatusStructure;
}

const _PaymentStatusStructures = {

    [PaymentStatus.Pending]: {
        notification: EPaymentNotificationType.NoNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Pending,
        isApproved: false,
        isNegative: false
    },

    [PaymentStatus.Approved]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Approved,
        isApproved: true,
        isNegative: false
    },

    [PaymentStatus.Analysis]: {
        notification: EPaymentNotificationType.NoNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Analysis,
        isApproved: false,
        isNegative: false
    },

    [PaymentStatus.Cancelled]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Cancelled,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.Rejected]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Rejected,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.Chargeback]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Chargeback,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.MediationStart]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.MediationStart,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.MediationLoss]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.MediationLoss,
        isApproved: false,
        isNegative: true
    },


    [PaymentStatus.MediationWon]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.MediationWon,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.Refunded]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Refunded,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.PartialRefunded]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.PartialRefunded,
        isApproved: false,
        isNegative: true
    },

    [PaymentStatus.Expired]: {
        notification: EPaymentNotificationType.EmailNotification,
        expectedAfter: [],
        invalidAfter: [],
        feedback: PaymentStatus.Expired,
        isApproved: false,
        isNegative: true
    }
}

export const PaymentStatusStructures: IPaymentStatusRecord = _PaymentStatusStructures;

export enum AllowedGateways {
    mercadopago = 'mercadopago'
}

export interface GatewaySettings {
    user: string;
}

export type TAllowedGatewayHash = {
    [key: string]: GatewaySettings;
}

export type TPublicMediumArray = Array<IPublicMedium>;

export type TMediumBrand =
    | "american-express"
    | "diners-club"
    | "discover"
    | "jcb"
    | "maestro"
    | "mastercard"
    | "unionpay"
    | "visa";

export interface IPublicMedium {
    brand: TMediumBrand;
    lastfour: string;
    interactionID: TGlobalUID;
    expiry: string;
}

export interface IPublicMediumPresentable {
    brand: TMediumBrand;
    lastfour: string;
}

export enum Gateway {
    Mercadopago = 'mercadopagobr',
    Paypal = 'paypalbr'
}

export interface ICheckoutMetadata {
    name: string;
    email: string;
}

export interface IMercadopagoProductDescription {
    name: string;
    description: string;
}

export interface IMercadopagoMetadata extends ICheckoutMetadata {
    cpf: string;
    product: IMercadopagoProductDescription;
}

export interface ICheckout {
    metadata: ICheckoutMetadata;
    gateway: Gateway;
    interactionID: TGlobalUID;
}

export enum ECheckoutCurrentStatus {
    Pending = 1,
    Approved
}