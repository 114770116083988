import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from "@angular/router";
import {browserInteractionRoutePieces} from "@colmeia/core/src/shared-business-rules/cordova/notification-router";
import {RouterManagerService} from "../../services/router-manager.service";
import {AuthService} from "../../services/auth/auth.service";
import {SignalListenerService} from "../../services/signal/signal-listener";
import { isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { routeID } from 'app/model/routes/route-constants';
import { SnackMessageService } from 'app/services/snack-bar';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-notification-redirect',
    templateUrl: './notification-redirect.component.html',
    styleUrls: ['./notification-redirect.component.scss']
})
export class NotificationRedirectComponent implements OnInit {
    private groupToNavigate!: string;

    constructor(
        private auth: AuthService,
        private route: ActivatedRoute,
        private routerSvc: RouterManagerService,
        private router: Router,
        private listener: SignalListenerService,
        private snackMsgSvc: SnackMessageService,
    ) {
        this.auth.setInteractionToNavigateOnLogin(
            this.route.snapshot.params[browserInteractionRoutePieces.params.idGroup],
            this.route.snapshot.params[browserInteractionRoutePieces.params.idInteraction]
        );

        this.groupToNavigate = this.route.snapshot.params[browserInteractionRoutePieces.params.idGroup];
    }

    async ngOnInit() {
        if (isValidString(this.groupToNavigate)) {

            const snack = this.snackMsgSvc.loadingSnack({ label: "Carregando..." });
            this.routerSvc.goToGroup(this.groupToNavigate, routeID.groups.chat);

            this.router.events.pipe(filter(ev => ev instanceof NavigationEnd), take(1)).subscribe(() => {
                snack.dismiss();
            });

        }
    }
}
