import { EBotContentEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EBPMAction } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { EMetadataEngagementType } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";

export enum EEntityType {
    ser = 'ser',
    nser = 'nser',
}


export enum EContentLocale {
    name = 'name',
    botTextToShow = 'botTextToShow',
    posContet = 'posContet',
    preContent = 'preContent',
    validation = 'validation',
    smartField = 'smartField',
    botMenuItemAdvancedMessage = "botMenuItemAdvancedMessage",
    botEventMessage = "botEventMessage"
}

export type TFullTextSearchContentLocale = EContentLocale.name
    | EBotContentEvent.preContent
    | EBotContentEvent.posContent
    | EMetadataEngagementType.richFillForm
    | EMetadataEngagementType.NewCondition
    | EBPMAction.action
    | EContentLocale.botTextToShow
    | EContentLocale.botMenuItemAdvancedMessage
    | EContentLocale.botEventMessage

export interface ISearchableContent {
    text: string;
    locale: TFullTextSearchContentLocale;
    contentName?: string;
}

export type TISearchableContentArray = Array<ISearchableContent>;

export interface INonSerializableSearcheableContent {
    id: string;
    entityType: EEntityType,
    nsType: ENonSerializableObjectType;
    idSocialContext: string;
    name: string,
    /**
     * disponivel apenas se o nsType = 'bot'
     */
    botLevel?: ENextGenBotElementType;
    /**
     * disponivel apenas se o nsType = 'bot' no caso do resultado da busca ser um bot o frontend precisa saber 
     * qual o idBPM para conseguir navegar para o item quando o usuario clickar
     */
    idBPMRoot?: IdDep<ENonSerializableObjectType.graphElement>,
    content: TISearchableContentArray;
    lastClockUpdate: number;
}

// Bot, Gerador de conteudo

