import { INetworkHardwareAbstraction, TNetworkStateSubscription } from "../hardware-interfaces";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from "rxjs";
import { SocketService } from "app/services/socket.service";

@Injectable({
    providedIn: 'root'
})
export class BrowserNetwork implements INetworkHardwareAbstraction {

    private onlineSubject: BehaviorSubject<boolean> = new BehaviorSubject(navigator.onLine);
    private offlineSubject: BehaviorSubject<boolean> = new BehaviorSubject(!navigator.onLine);
    private lastRes: boolean = true;
    private socketOn: boolean = true;
    private socket: SocketService;

    constructor() {
        const id = setInterval(async () => {
            const on = await this.isOnline();
            if (!this.lastRes === on) {
                this.onlineSubject.next(on);
                this.offlineSubject.next(!on);
                this.lastRes = on;
            }

        }, 500);
    }


    setSocketService(service: SocketService): void {
        this.socket = service;
    }


    setSocketIsOnline(isOnline: boolean): void {
        this.socketOn = isOnline;
    }

    isOffline(): boolean {
        return !this.isOnline();
    }

    isOnline(): boolean {
        // return this.socket.isSocketOnline();
        if (this.socket) {
            return this.socket.isSocketOnline();
        }
        return false;
    }

    subscribeToOffline(subscription: TNetworkStateSubscription): Subscription {
        return this.offlineSubject.subscribe(subscription);
    }

    subscribeToOnline(subscription: TNetworkStateSubscription): Subscription {
        return this.onlineSubject.subscribe(subscription);
    }
}
