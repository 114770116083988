import { EServiceGroupIntType, EMessageStandard } from "@colmeia/core/src/business/constant.enums";
import { isValidRef } from "@colmeia/core/src/tools/utility";

export type TMessageInstanceDB = {[interactionType: string]:  IMessageInstanceOptionsConfig}

export interface IMessageInstanceOptionsConfig {
    readConfirmation?: boolean;
    edited?: boolean;
    shared?: boolean;
    annotations?: boolean;
}

const allTrue: IMessageInstanceOptionsConfig = {
    readConfirmation: true,
    edited: true,
    shared: true,
    annotations: true,
}

export const messageInstanceConfigDB: TMessageInstanceDB = {

    [EMessageStandard.message]: allTrue,
    [EMessageStandard.citation]: allTrue,
    [EServiceGroupIntType.startServiceChat]: allTrue,
    [EServiceGroupIntType.finishService]: allTrue,

}

export function getMessageInstanceOptionsConfig(interactionType: string): IMessageInstanceOptionsConfig {
    const result: IMessageInstanceOptionsConfig = messageInstanceConfigDB[interactionType];
    return isValidRef(result)
        ? result
        : {};
}
