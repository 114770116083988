import { Component, OnInit } from '@angular/core';
import { IAttendentServicePackClient, IWidgetEventConfig } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { IServerLocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { isValidRef, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { AttendanceService } from 'app/services/attendance.service';
import { CrossAppCommunicatorService } from 'app/services/cross-app-communicator.service';
import { LookupService } from 'app/services/lookup.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TGeneralFormServerAnswerArray } from '@colmeia/core/src/general-form/general-form-answer';
import { ECrossAppCommEventType } from '@colmeia/core/src/shared-business-rules/app-widget/widget.model';

@Component({
    selector: 'chat-at-a-glance',
    templateUrl: './chat-at-a-glance.component.html',
    styleUrls: ['./chat-at-a-glance.component.scss']
})
export class ChatAtAGlanceComponent implements OnInit {
    private attendentServicePack: IAttendentServicePackClient;
    public widgetEventConfigList: IWidgetEventConfig[];
    public computedInfo: TComputedInfo;
    public formAnswers: TGeneralFormServerAnswerArray;
    public nss: IServerLocalCanonical[];
    public isLoading = false;

    constructor(
        private attendanceSvc: AttendanceService,
        private lookupSvc: LookupService,
        private crossAppCommSvc: CrossAppCommunicatorService,
        private snack: MatSnackBar,
    ) {}

    async ngOnInit() {
        this.isLoading = true;
        
        this.attendentServicePack = this.attendanceSvc.getAttendentServicePackForCurrentGroup();
        this.widgetEventConfigList = this.attendentServicePack.widgetEventConfigList || [];

        this.computedInfo = this.attendanceSvc.getAllComputedInfoForCurrentAttendance();
        this.formAnswers = this.attendanceSvc.getFormAnswersForCurrentAttendance();

        const allNSIdsForLookup = this.widgetEventConfigList
            .map(event => event.idCanonical)
            .filter(event => isValidRef(event));

        if (allNSIdsForLookup.length > 0) {
            this.nss = await this.lookupSvc.getBatchNonSerializables(allNSIdsForLookup);
        }
        
        this.isLoading = false;
    }

    public getName(id: string) {
        return this.nss.find(ns => ns.idNS === id)?.nName;
    }

    public dispatchEvent(event: IWidgetEventConfig) {
        const msg = this.crossAppCommSvc.generateCrossAppMessage(
            ECrossAppCommEventType.atAGlanceCanonical,
            event.eventName,
            event.idCanonical,
            this.computedInfo[event.idCanonical],
            this.generatePayload()
        );

        const { sentStatus } = this.crossAppCommSvc.sendMessageToWidgetParent(msg);

        if (sentStatus === false) {
            this.snack.open("Este widget precisa ser carregado dentro de um website!", "Fechar", { duration: 5000 });
        }
    }

    private generatePayload(): Record<IdDep<ENonSerializableObjectType.canonical>, string> {
        const payload: Record<IdDep<ENonSerializableObjectType.canonical>, string> = {};

        for (let event of this.widgetEventConfigList) {
            payload[event.idCanonical] = this.computedInfo[event.idCanonical];
        }

        return payload;
    }

    public isNotVisibleToAgent(idCanonical: IdDep<ENonSerializableObjectType.canonical>) {
        for (let answer of this.formAnswers) {
            const response = answer.responses?.find(r => r.idLocalCanonical === idCanonical);

            if (response) {
                return response.isNotVisibleByAgent;
            }
        }

        return false;
    }

    public getCanonicalValue(idCanonical: IdDep<ENonSerializableObjectType.canonical>): string {
        let value = this.computedInfo[idCanonical];

        if (!isValidString(value)) {
            return "—";
        }
        
        if (this.isNotVisibleToAgent(idCanonical)) {
            value = value.replace(/./g, '•');
        }

        return value;
    }
}
