import { MainHandler } from "./main-handler";
import {MultimediaInstance} from "@colmeia/core/src/multi-media/multi-media-instance";
import {IComponentParameter} from "../model/component-comm/basic";
import {SelectedFile} from "@colmeia/core/src/multi-media/selected-file";
import {ClientCachedFile} from "@colmeia/core/src/multi-media/client-cached";
import {MMconstant} from "@colmeia/core/src/multi-media/multimedia-constant";


export interface IRecorderCallBack {
    onStopRecording(multimediaInstance: MultimediaInstance): void;
    onCancelRecording(): void;
}


export interface IRecorderParameter extends IComponentParameter {
    secondsAllowed: number;
    clientCallback:  IRecorderCallBack;
};


export class RecorderHandler extends MainHandler {
    private secondsAllowed: number;

    constructor(param: IRecorderParameter) {
        super(param);
        this.secondsAllowed = param.secondsAllowed;
    };

    public onFileSelected(file: File): void {
        const selected: SelectedFile = SelectedFile.newClientFileInfo(
                ClientCachedFile.fileToNewCachedFile(file, file.name),
                {currentName: file.name, idMediaTag: MMconstant.tag.voiceMessage});
        const mmInstance: MultimediaInstance =
            MultimediaInstance.getNewMultimediaInstanceFromSelectedFile(selected);
        this.getComponentParameter().clientCallback.onStopRecording(mmInstance);
    };

    public onCancelFile(): void {
        this.getComponentParameter().clientCallback.onCancelRecording();
    }

    public getComponentParameter(): IRecorderParameter {
        return <IRecorderParameter>super.getComponentParameter();
    };
};


