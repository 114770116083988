import { TArrayID } from "../core-constants/types";
import { TGlobalUID } from "./constant";

export interface INserIdentificationData {
    idCurrentGroup: TGlobalUID,
    idAvatar: TGlobalUID
}

export function getSocialNetworkIdFromGenealogy(genealogy: TArrayID): string {
    return genealogy[genealogy.length - 1]
}