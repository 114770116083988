import {MainHandler} from "./main-handler";
import {IComponentParameter} from "../model/component-comm/basic";
import { TIEditorVariableArray } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { IServerLocalCanonical } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { defaultFields, pick } from "@colmeia/core/src/tools/utility";
import { Compute, GetByKey } from "@colmeia/core/src/tools/utility-types";


export interface IVarEditorClientCallback {
    onVariableEditFinished(
        rawText: string,
        compiledText: string,
        usedVariablesIds: IUsedVariablesID[],
        identity: string,
        messageIfNoBind?: string
    ): void;
    onAllVariablesLoaded?: (allVariables: TIEditorVariableArray) => void;
}

export interface ICompileVariablesResult {
    raw: string;
    compiled: string;
    usedVariablesID: IUsedVariablesID[];
}

export interface IUsedVariablesID {
    idProperty: string;
    value?: string;
}

export interface IButtonEditorInstance {
    forceOpenDialog(): void;
}

export type TCompileVariablesTemplateFN = (raw: string, variables: TIEditorVariableArray) => ICompileVariablesResult;


export enum EVarEditorEntityType {
    NonSerializable = 'NonSerializable',
    SchemaProperty = 'SchemaProperty',
}

export type TVarEditorVariables = { [key in EVarEditorEntityType]?: TIEditorVariableArray };
export interface ITextAndVariables {
    variables: TVarEditorVariables;
    rawText: string;
    messageIfNoBind?: string;
}


type RequiredField<T, Keys extends PropertyKey = keyof T> = { [key in Keys]: GetByKey<T, key> };

export function pickFormatVisibility<Source extends IFormatVisibility>(input: Source): RequiredField<IFormatVisibility> {
    return {
        shouldHideBBCode: input.shouldHideBBCode,
        shouldHideEmojis: input.shouldHideEmojis,
        shouldHideBottomOptions: input.shouldHideBottomOptions,
        limitCharacters: input.limitCharacters,
        limitRows: input.limitRows,
        computeAmountCharactersString: input.computeAmountCharactersString,
    }
}
export interface IFormatVisibility {
    shouldHideBBCode?: boolean;
    shouldHideEmojis?: boolean;
    shouldHideBottomOptions?: boolean;
    limitCharacters?: number;
    limitRows?: number;
    computeAmountCharactersString?: (rawText: string, variables: TIEditorVariableArray) => string;
}
export interface IVarEditorHandlerParameter extends IComponentParameter, ITextAndVariables, IFormatVisibility {
    clientCallback: IVarEditorClientCallback;
    editorIdentity?: string;
    compileFunction: TCompileVariablesTemplateFN;
    enableContextCanonicals?: true;
    disableFallback?: true;
    useQuill?: boolean;
}

export interface IHomeHandlerParameter {
    allVariables: TIEditorVariableArray;
    valid: boolean;
    forceSave(): void;
}

const defaultParameter: Partial<IVarEditorHandlerParameter> = {
    useQuill: false
}
export class VarEditorHandler extends MainHandler {

    private instance: IButtonEditorInstance;
    private home: IHomeHandlerParameter;

    constructor(parameter: IVarEditorHandlerParameter) {
        defaultFields(parameter, defaultParameter);
        super(parameter);
    }

    getComponentParameter(): IVarEditorHandlerParameter {
        return <IVarEditorHandlerParameter>super.getComponentParameter();
    }

    setButtonInstance(instance: IButtonEditorInstance): void {
        this.instance = instance;
    }

    setHomeInstance(home: IHomeHandlerParameter): void {
        this.home = home;
    }

    getHomeInstance(): IHomeHandlerParameter {
        return this.home;
    }

    forceButtonOpen() {
        this.instance.forceOpenDialog();
    }
}
