import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { $Extends } from "@colmeia/core/src/tools/utility/types/validate";
import { $$ } from "@colmeia/core/src/tools/utility/types/error";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { TGeneralFormFieldAnswerValue, IRFieldResponse, IGeneralFormAnswerServer, GeneralFormField } from "@colmeia/core/src/general-form/general-form-answer";
import { GeneralFormEvaluator } from "@colmeia/core/src/general-form/general-form-evaluator";
import { SchemaProperty, TSchemaPropertyArray } from "@colmeia/core/src/general-form/general-form-interface";
import { EGeneralFormViewType } from "app/model/general-form.model";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";


export interface IGeneralFormFieldViewerParameters extends IComponentParameter {
    schemaProp: SchemaProperty;
    value: TGeneralFormFieldAnswerValue
    previousAnswer?: IRFieldResponse
    viewType: EGeneralFormViewType
    generalFormEvaluator: GeneralFormEvaluator
    fGroup: UntypedFormGroup
    fControl: UntypedFormControl
    answer: IGeneralFormAnswerServer
    fields: GeneralFormField[]
    field?: GeneralFormField
    schemaForm: TSchemaPropertyArray
    fieldCreation: ((field: GeneralFormField) => void)
    idStartInteraction: TGlobalUID
}

const defaultParameters: Partial<IGeneralFormFieldViewerParameters> = {
    
};

export class GeneralFormFieldViewerHandler extends MainHandler<IGeneralFormFieldViewerParameters> {
    public constructor(parameter: IGeneralFormFieldViewerParameters) { defaultFields(parameter, defaultParameters); super(parameter) }
    public getSchemaProperty(): SchemaProperty { return this.getComponentParameter().schemaProp }
}
