import {EConnectionType, IConnectionRouteServer} from "@colmeia/core/src/shared-business-rules/connections/endpoint-model";
import { isValidFunction } from "@colmeia/core/src/tools/utility";

export type RouteSelectionCallback = (selected: IConnectionRouteServer) => void;

export interface ConnectionRoutePickerHandlerParameter {
    onSelect: RouteSelectionCallback;
    currentIdRoute?: string;
    currentIdDomain?: string;
    customAllowedTypes?: EConnectionType[];
    showRouteDetails?: boolean;
}

export interface ConnectionRoutePickerRefreshOnSelect {
    onSelect: RouteSelectionCallback;
}

export class ConnectionRoutePickerHandler { // @hugo

    private instance: ConnectionRoutePickerRefreshOnSelect;

    constructor(private parameter: ConnectionRoutePickerHandlerParameter) {}

    public setRefreshSelectCallback(instance: ConnectionRoutePickerRefreshOnSelect): void {
        this.instance = instance;
    }

    public getComponentParameter(): ConnectionRoutePickerHandlerParameter {
        return this.parameter;
    }

    public callback(route: IConnectionRouteServer): void {
        this.parameter.onSelect(route);
        if (isValidFunction(this.instance?.onSelect)) {
            this.instance.onSelect(route);
        }
    }

    public changeCurrent(idRoute: string, idDomain){
        this.parameter.currentIdRoute = idRoute;
        this.parameter.currentIdDomain = idDomain;
    }

    public getCurrentIds(){
        return {
            idRoute: this.parameter.currentIdRoute,
            idDomain: this.parameter.currentIdDomain
        };
    }

    public static factory(parameter: ConnectionRoutePickerHandlerParameter): ConnectionRoutePickerHandler {
        return new ConnectionRoutePickerHandler(parameter);
    }

}
