import { Role, TRoleArray} from '../security/role'
import { TArrayID, TGlobalUID } from '../core-constants/types';
import { isValidArray, isValidRef } from '../tools/utility';
import { permission, domain } from '../core-constants/security-constant';
import { ISecuritySettingsJSON, TSecurityItemArrayJSON } from '../comm-interfaces/business-interfaces';
import { UberCache } from '../persistency/uber-cache';
import { constant } from '../business/constant';
import { IPublishGroup, EGoPermissionType } from '../comm-interfaces/interaction-interfaces';


const vinculanteSecurityOptions: TArrayID = [domain.visibility.secretGroup, domain.visibility.secretGroupForNonEmployees];


export function hasRoleToBellongToGroup(roles: TRoleArray): boolean {
    return hasPermissionInRoles(roles, permission.canInteract) && ! hasPermissionInRoles(roles, permission.excludedFromGroup);
}

export function hasRoleIDToBellongToGroup(roles: TArrayID): boolean {
    return hasPermissionInRolesIDs(roles, permission.canInteract) && ! hasPermissionInRolesIDs(roles, permission.excludedFromGroup);
}

export function hasAdminPrivilege(roles: TArrayID): boolean {
    return hasPermissionInRolesIDs(roles, permission.hasAdminPrivilege);
}


export function differentSetOfRoles(roles1: TArrayID, roles2:TArrayID): boolean {
    return ( roles1.some((r) => !roles2.includes(r)) || roles2.some((r) => !roles1.includes(r)) )
}



// Pegando direto do Ubercache devido a referencia Circular
export function hasPermissionInRolesIDs(roles: TArrayID, ...idPermissions: TArrayID): boolean {
    if (! isValidArray(roles)) {
        return false;
    }
    for (const idRole of roles) {
        const role: Role = <Role>UberCache.uberFactory(idRole, constant.objectType.role, true);
        for (const idPermission of idPermissions ) {
            if (role.hasPermission(idPermission)) {
                return true;
            };
        }
    };
    return false;
};


export function hasPermissionInRoles(roles: TRoleArray, idPermission: TGlobalUID): boolean {
    if (! isValidArray(roles)) {
        return false;
    }
    for (const role of roles) {
        if (role.hasPermission(idPermission)) {
            return true;
        };
    };
    return false;
};

export function isAuthorizedInteractionInRoles(roles: TRoleArray, idInteractionType: TGlobalUID): boolean {
    if (! isValidArray(roles)) {
        return false;
    }
    for (const role of roles) {
        if (role.isAuthorizedInteraction(idInteractionType)) {
            return true;
        };
    };
    return false;
};

export function staticFactoryRoles(roles: TArrayID): TRoleArray {
    if (isValidArray(roles)) {
        return roles.map((r) => {
            const role: Role = <Role>UberCache.uberFactory(r, constant.objectType.role, true);
            return role;
        });
    };
    return [];
};

export function hasRole(roles: TArrayID, idRole: TGlobalUID): boolean {
    if (! isValidArray(roles)) {
        return false;
    }
    return roles.some((r) => {return r === idRole});
};

export function hasDemandedRole(demandedRoles: TArrayID, currentParticipantRoles: TArrayID): boolean {
    for (const demanded of demandedRoles) {
        if (currentParticipantRoles.some((c) => {return c === demanded})) {
            return true;
        }
    };
    return false;
}

export function getAllVinculantOptions(securitySettings: ISecuritySettingsJSON): TSecurityItemArrayJSON {
    const vinculants: TSecurityItemArrayJSON = [];
    for (const setting of securitySettings.securityItems) {
        if (vinculanteSecurityOptions.some((v) => {return v === setting.idSecurityDomain})) {
            vinculants.push(setting);
        }
    }
    return vinculants;
}

export function hasVinculatedOptions(securitySettings: ISecuritySettingsJSON): boolean {
    return isValidArray(getAllVinculantOptions(securitySettings));
}

export function isVinculated(idSecurityDomain: TGlobalUID): boolean {
    return vinculanteSecurityOptions.some((v) => {return v === idSecurityDomain});
}

export function getRolesUseFromPub(publish: IPublishGroup): TArrayID {
    return isValidRef(publish.featureShared) && isValidArray(publish.featureShared.usePermissions) ?
        publish.featureShared.usePermissions.filter((r) => {return r.permissionType === EGoPermissionType.role}).map((r) => {return r.idPermissionRole}) :
        [];
}

export function getRolesVisualizationFromPub(publish: IPublishGroup): TArrayID {
    return isValidRef(publish.featureShared) && isValidArray(publish.featureShared.goPermissions) ?
        publish.featureShared.goPermissions.filter((r) => {return r.permissionType === EGoPermissionType.role}).map((r) => {return r.idPermissionRole}) :
        [];
}
