import {
    Component, OnInit,
    ViewChild, ElementRef, ChangeDetectorRef,
    ChangeDetectionStrategy, AfterContentChecked, HostListener, DoCheck
} from '@angular/core';
import { DropdownGlobalService } from './dropdown-global.service';
import {NDropDown} from "../../../../handlers/dropdown.handler";
import {
    ExecutableItem,
    TExecutableItemArray
} from "../../../../services/controllers-services/security-controller/executable-item";


@Component({
    selector: 'app-dropdown-global',
    templateUrl: './dropdown-global.component.html',
    styleUrls: ['./dropdown-global.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownGlobalComponent implements OnInit, AfterContentChecked, DoCheck {
    currentWidth: number = 0;
    dropdownHandler: NDropDown.DropDownHandler;
    @ViewChild('dropdownContent', {static: true})
    dropdownContent: ElementRef;


    constructor (
        private cdRef: ChangeDetectorRef,
        private dropDownSvc: DropdownGlobalService,
    ) { }

    ngOnInit() {
        // we need to set dropdown handler with an instance of a service
        // so it can notify about changes in the view
        NDropDown.DropDownHandler.setDropDownServiceInstance(this.dropDownSvc);

        // create observable to listen handler changes
        this.dropDownSvc
            .listenHandler()
            .subscribe( handler => {
                this.dropdownHandler = handler;
                this.currentWidth = this.getDropDownContentWidth()
                this.cdRef.markForCheck();
            });
    }

    // @HostListener('window:resize')
    ngDoCheck() {
        if (this.dropdownHandler
            && this.dropdownHandler.isVisible()
            && (<HTMLElement>this.dropdownContent.nativeElement).offsetParent !== null
            && this.currentWidth != this.getDropDownContentWidth()) {
            this.currentWidth = this.getDropDownContentWidth();
            this.cdRef.markForCheck();
        }
    }

    // this check needs to be performed because
    // component was not rendered yet in the first change detection
    // so we trigger a second change detection to make colision detection possible
    ngAfterContentChecked() { }

    onDropdownBackgroundClicked(): void {
        this.toggle();
    }

    private getDropDownContentWidth(): number {
        return (<HTMLElement>this.dropdownContent.nativeElement).clientWidth;
    }

    private getDropDownContentHeight(): number {
        return (<HTMLElement>this.dropdownContent.nativeElement).clientHeight;
    }

    public isGrey(): boolean {
        return (
            this.dropdownHandler &&
            this.dropdownHandler.getComponentParameter().color === NDropDown.EColorType.Grey
        );
    }

    isInSideViewport(): boolean {
        const rect = (<HTMLElement>this.dropdownContent.nativeElement).getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight ||
                    document.documentElement.clientHeight) &&
            rect.right <=
                (window.innerWidth ||
                    document.documentElement.clientWidth)
        );
    }

    public canShowIcon(item: ExecutableItem): boolean {
        const hasIcon = item && !!item.getIcon();
        return this.dropdownHandler && !this.canShowSelectedItem() && hasIcon;
    }

    public canShowEmoji(item: ExecutableItem): boolean {
        const hasEmoji = !!item.getEmoji();
        return hasEmoji;
    }

    public getMenuItems(): TExecutableItemArray {
        return this.dropdownHandler && this.dropdownHandler.getAllItems() ?
            this.dropdownHandler.getAllItems() :
            [];
    }

    public isLeft(): boolean {
        return (
            this.isValidParameter() &&
            this.dropdownHandler.getComponentParameter().position ===
                NDropDown.EDropDownPosition.Left
        );
    }
    public isRight(): boolean {
        return (
            this.isValidParameter() &&
            this.dropdownHandler.getComponentParameter().position ===
                NDropDown.EDropDownPosition.Right
        );
    }
    public isBottom(): boolean {
        return (
            this.isValidParameter() &&
            this.dropdownHandler.getComponentParameter().position ===
                NDropDown.EDropDownPosition.Bottom
        );
    }

    public isVisible(): boolean {
        return this.isValidParameter() && this.dropdownHandler.getComponentParameter().visible;
    }

    isValidParameter(): boolean {
        return this.dropdownHandler
            && !!this.dropdownHandler.getComponentParameter()
            && !!this.getMenuItems().length;
    }

    getStyles(): NDropDown.IDropdownPosition {
        if (
            !(
                // this.isVisible() &&
                this.dropdownHandler &&
                this.dropdownHandler.getComponentParameter().currentMouseEvent
            )
        )
            return;

        const mouseEvent = this.dropdownHandler.getComponentParameter().currentMouseEvent;
        let clickedY = mouseEvent.y;
        let clickedX = mouseEvent.x;

        if (this.collidedRight(clickedX)) clickedX -= this.getDeltaCollidedRight(clickedX); //this.getDropDownContentWidth();
        if (this.collidedBottom(clickedY)) clickedY -= this.getDropDownContentHeight();

        return {
            top: clickedY + 'px',
            left: clickedX + 'px'
        };
    }

    private collidedBottom(clickedY: number): boolean {
        const viewPortHeight = window.innerHeight || document.documentElement.clientHeight;

        const currentDropdownHeight = this.getDropDownContentHeight();

        const collidedBottom = (clickedY + currentDropdownHeight) > viewPortHeight;
        return collidedBottom;
    }

    private collidedRight(clickedX: number): boolean {
        const viewPortWidth = window.innerWidth || document.documentElement.clientWidth;
        const currentDropdownWidth = this.getDropDownContentWidth();
        const collidedRight = (clickedX + currentDropdownWidth) > viewPortWidth;

        return collidedRight;
    }

    private getDeltaCollidedRight(clickedX: number): number {
        const viewPortWidth = window.innerWidth || document.documentElement.clientWidth;
        const currentDropdownWidth = this.getDropDownContentWidth();
        return Math.abs(viewPortWidth - (clickedX + currentDropdownWidth + 10));
    }

    public toggle(): void {
        this.dropdownHandler.getComponentParameter().visible = !this.dropdownHandler.getComponentParameter()
            .visible;
    }

    public isSelectedItem(item: ExecutableItem): boolean {
        let selectedItem = this.dropdownHandler ? this.dropdownHandler.getSelectedItem() : null;
        if (!selectedItem) {
            return;
        }

        if (item.getSerializable()) {
            return selectedItem.iss(item.getSerializable());
        } else {
            return selectedItem.getPrimaryID() === item.getPrimaryID();
        }

    }

    public canShowSelectedItem(): boolean {
        return this.dropdownHandler && this.dropdownHandler.isSelectable();
    }

    public onItemClick(event: MouseEvent, item: ExecutableItem): void {
        this.dropdownHandler
            .getComponentParameter()
            .clientCallback.onDropDownItemClickedCallback({
                itemClicked: item,
                event: event,
            });
    }
}
