import { Component, Inject, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { INewAgentCallContactServer } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { DatePickerHandler } from 'app/handlers/date-picker.handler';
import { AttendanceActiveEditHelperService } from 'app/services/attendance-active-edit-call-init.service';
import { AttendanceActiveEditCallApiService } from 'app/services/attendance-active-edit-call.service';
import { SnackMessageService } from 'app/services/snack-bar';

@Component({
    selector: 'app-attendance-send-method-dialog',
    templateUrl: './attendance-send-method-dialog.component.html',
    styleUrls: ['./attendance-send-method-dialog.component.scss']
})
export class AttendanceSendMethodDialogComponent {
    activeCalls: INewAgentCallContactServer[];
    idBatch: string;
    isScheduling: boolean;
    scheduleAt: number;
    scheduleHandler: DatePickerHandler;
    selectedCampaignAction: string;

    get completeContacts() {
        return this.activeCalls.filter(c => !isValidRef(c.isPartial) || c.isPartial === false);
    }

    get partialContacts() {
        return this.activeCalls.filter(c => c.isPartial);
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public componentData: IColmeiaDialogComponentData<{
            idBatch: string,
            data: INonSerializable[],
            selectedCampaignAction?: string,
            afterSend: () => void,
            afterSchedule: () => void
        }>,
        private attendanceActiveEditCallAPISvc: AttendanceActiveEditCallApiService,
        private snackBarMessageSvc: SnackMessageService,

        private attendanceActiveEditCallInitService: AttendanceActiveEditHelperService,
        private viewContainerRef: ViewContainerRef,

        private dialogRef: MatDialogRef<any>
    ) {}

    ngOnInit() {
        this.activeCalls = this.componentData.getParamsToChildComponent().data as INewAgentCallContactServer[];
        this.idBatch = this.componentData.getParamsToChildComponent().idBatch;
        this.selectedCampaignAction = this.componentData.getParamsToChildComponent().selectedCampaignAction;
    }

    initScheduleHandler() {
        this.scheduleHandler = new DatePickerHandler({
            onPick: (number: number) => this.scheduleAt = number,
            current: this.scheduleAt,
        });
    }

    async schedule() {
        if (isValidRef(this.scheduleAt)) {
            await this.attendanceActiveEditCallAPISvc.scheduleSelectedContacts(
                this.completeContacts.map((nser) => nser.idNS),
                this.scheduleAt
            );
            this.componentData.getParamsToChildComponent().afterSchedule();
            this.dialogRef.close();
        }
    }

    async send() {
        await this.attendanceActiveEditCallAPISvc.sendSelectedContacts(
            this.completeContacts.map((nser) => nser.idNS)
        );

        this.componentData.getParamsToChildComponent().afterSend();
        this.dialogRef.close();
    }
    async sendPartial() {
        this.dialogRef.close();
        await this.attendanceActiveEditCallInitService.openActiveCallInfo(
            this.idBatch,
            null,
            this.partialContacts.map(nser => nser.idNS),
            null,
            null,
            null,
            this.selectedCampaignAction,
        );
        this.componentData.getParamsToChildComponent().afterSend();

    }

    hasScheduleValue() {
        return isValidRef(this.scheduleAt);
    }
}
