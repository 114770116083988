import {
    ErrorGeolocationCallback, IGeolocationError,
    IGeolocationHardwareAbstraction,
    SuccessGeolocationCallback
} from '../hardware-interfaces';
import { EHardwareResourceStatus } from "../cordova/vendor/hardware-status";
import { ILocationCoordinates } from "@colmeia/core/src/tools/geo-util";
import { getClock } from "@colmeia/core/src/tools/utility";
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import { stringify } from 'uuid';
import { NativeEventEmitterService } from 'app/services/colmeia-mobile-app/native-event-emitter.service';
import { EEventName, IReactNativeLocation } from '@colmeia/core/src/client-shared/client-native.model';

@Injectable({
    providedIn: 'root'
})
export class ReactGeolocation implements IGeolocationHardwareAbstraction {

    private _lastLocation$: Subject<ILocationCoordinates> = new ReplaySubject(1);
    private _lastLocationObservable: Observable<ILocationCoordinates> = this._lastLocation$.asObservable();
    private _lastLocationSubscription: Subscription;
    
    private _isStarted = false;

    private _lastKnownPosition: ILocationCoordinates | null;
    private _lastKnownPositionSubscription: Subscription;

    private _isTrackingLocationAllowed: boolean;

    constructor(
        private nativeEventEmitterSvc: NativeEventEmitterService
        ) {
        this.start();
    }

    initializePositionSubscription() {
        this._lastKnownPositionSubscription?.unsubscribe();
        this._lastKnownPositionSubscription = this._lastLocationObservable
        .subscribe(position => {
            this._lastKnownPosition = position;
        });
    }

    setEmergencyMode(active: boolean): void {
        //for now, do nothing...
    }

    // private normalizeLocation(coords: GeolocationCoordinates): ILocationCoordinates {
    //     return {
    //         ...coords,
    //         activity: null,
    //         batery: null,
    //         powerSaveMode: false,
    //         uuid: null,
    //         isMoving: false,
    //         gpsTimestamp: getClock(),
    //     }
    // }

    // private normalizeLocationError(err: GeolocationPositionError): IGeolocationError {
    //     return {
    //         message: err.message,
    //         gpsTimestamp: getClock()
    //     }
    // }

    clearWatchPosition(id: number): void {
        // navigator.geolocation.clearWatch(id);
        this._lastLocationSubscription?.unsubscribe();
    }

    getPosition(
        successCallback: SuccessGeolocationCallback, 
        errorCallback: ErrorGeolocationCallback, 
        options: PositionOptions
        ): void {

        if (!this._isStarted) return;

        this._lastLocationObservable.pipe(skip(1))
        .subscribe((pos) => {
            successCallback(pos)})
        .unsubscribe();

    }

    watchPosition(
        successCallback: SuccessGeolocationCallback, 
        errorCallback: ErrorGeolocationCallback, 
        options?: PositionOptions
        ): number {

        if (!this._isStarted) return 0;

        this._lastLocationSubscription = this._lastLocationObservable.subscribe((pos) => {

            successCallback(pos);
        });
        return 0
    }

    isEnabled(): Promise<boolean> {
        // TODO
        return Promise.resolve(true);
    }

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        //TODO
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            this.getPosition((pos) => {
                resolve(EHardwareResourceStatus.GRANTED);
            }, (err) => {
                resolve(EHardwareResourceStatus.DENIED);
            }, {});
        });
    }

    private isAllowedResolve: (isAllowed: boolean) => void;
    private isAllowedTimeout: NodeJS.Timeout | null;
    isAllowed(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.isAllowedResolve = resolve;

            const eventPayload: IReactNativeLocation = {
                isAllowed: true
            }
            this.nativeEventEmitterSvc.emit(EEventName.location, eventPayload);
            this.isAllowedTimeout = setTimeout(() => {
                reject('Não foi possível verificar a permissão da localização')
            }, 30000)
            
        });
    }
    resolveIsAllowed(isAllowed: boolean) {
        this.isAllowedResolve?.(isAllowed);
        if (this.isAllowedTimeout) {
            clearTimeout(this.isAllowedTimeout)
            this.isAllowedTimeout = null;
        }
    }


    private _resolveLocationPermission: (value: EHardwareResourceStatus) => void;
    private _rejectLocationPermission: (reason?: any) => void;
    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            const eventPayload: IReactNativeLocation = {
                requestPermission: true
            }
            this.nativeEventEmitterSvc.emit(EEventName.location, eventPayload);
            this._resolveLocationPermission = resolve;
            this._rejectLocationPermission = reject;
        });
    }

    public resolveAuthorization(authorized: boolean) {
        if (authorized) {
            this._resolveLocationPermission(EHardwareResourceStatus.GRANTED);
        } else {
            this._resolveLocationPermission(EHardwareResourceStatus.DENIED);
        }
    }

    getLastKnownPosition(): ILocationCoordinates {
        return this._lastKnownPosition!;
    }

    start() {
        this.isAllowed()
        .then((isAllowed) => {
            if (isAllowed) {
                this._isStarted = true;
        
                this.initializePositionSubscription();

                this.nativeEventEmitterSvc.emit<IReactNativeLocation>(EEventName.location, {
                    isLocationOn: true
                })
            }
            else {
                console.log('Favor habilitar localização nas configurações da Colmeia.')
            }
        })


        

        return Promise.resolve();
    }

    stop() {
        
        this.nativeEventEmitterSvc.emit<IReactNativeLocation>(EEventName.location, {
            isLocationOn: false
        })
        this.stopLocation();

        return Promise.resolve();
    }

    public updateLocation(location: ILocationCoordinates) {
        if (!location) return;
        this._lastLocation$.next(location);
    }

    public stopLocation() {
        this._lastKnownPosition = null;
        this._lastKnownPositionSubscription?.unsubscribe();
        this._isStarted = false;
    }

}
