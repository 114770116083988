<mat-card>
  <div class="l-header">
    <h4 class="l-title">Notificações</h4>
    <div class="header-actions">
      <mat-slide-toggle labelPosition="before" [(ngModel)]="onlyUnread" class="dense" style="font-size: .75rem;">Mostrar somente não lidas</mat-slide-toggle>
      <button mat-icon-button [disabled]="!hasUnread()" [color]="hasUnread() ? 'accent' : ''" matTooltip="Marcar todas como lidas" (click)="markAllAsRead()">
        <mat-icon>visibility</mat-icon>
      </button>
      <button mat-icon-button [color]="notificationsLength ? 'accent' : ''" matTooltip="Limpar notificações" (click)="clearAll()">
        <mat-icon>clear_all</mat-icon>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>

  <mat-card-content class="no-padding">
    <div class="notifications-container">
      <ng-container *ngIf="getNotifications() as notificationsGroups; else emptyWarn">
        <ng-container *ngFor="let notificationGroup of notificationsGroups | keyvalue">

          <div class="notification-nm-container">
            <button
            *ngIf="canShowRedirectBtn(notificationGroup.value[0].type)"
            (click)="navigateToPage(notificationGroup.key, notificationGroup.value[0])"
            matTooltip="Clique para navegar até a página" matTooltipPosition="left">
              <!-- <span *ngIf="isJobNotification(notificationGroup.value[0].type)">Job:
                {{ getJobTypeGroupName(notificationGroup.key) }}
              </span> -->
              <span *ngIf="isJobNotification(notificationGroup.value[0]?.type)">
                {{ getNotificationSpanMessage(notificationGroup.value[0].type, notificationGroup.key) }}
              </span>
              <!-- <span *ngIf="notificationGroup.key && notificationGroup.key !== 'undefined'">Id: {{notificationGroup.key}}</span> -->

              <!-- <span
                *ngIf="!notificationGroup.key || notificationGroup.key === 'undefined'"
              >
               - Clique aqui ir até lá
              </span> -->
            </button>
            <div class="action-btns-wrapper">
              <button
              mat-icon-button
              *ngIf="canShowDownloadBtn(notificationGroup.value[0].type, notificationGroup.key)"
              (click)="downloadCSV(notificationGroup.key)"
              matTooltip="Clique para fazer o download do CSV"
              matTooltipPosition="below"
              class="no-padding action-btn"
              >
                <mat-icon size="18">download</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="notificationGroup.key && notificationGroup.key !== 'undefined'"
                matTooltip="Clique para copiar o ID do Job"
                matTooltipPosition="below"
                class="no-padding action-btn"
                [copyToClipboard]="notificationGroup.key"
                >
                  <mat-icon size="18">content_copy</mat-icon>

              </button>
            </div>
          </div>

          <div *ngFor="let notification of notificationGroup.value" class="notification-item">
            <div *ngIf="notification.icon || notification.photo" class="cover-container">
              <div *ngIf="notification.icon" class="cover-content icon-cover">
                <mat-icon>{{ notification.icon }}</mat-icon>
              </div>
              <div *ngIf="notification.photo" class="cover-content photo-cover" [style.background-image]="notification.photo"></div>
            </div>
            <div class="content-container">
              <!-- <app-bbcode-viewer [text]="notification.message"></app-bbcode-viewer> -->
              <app-bbcode-viewer [text]="getBbcodeViewerNotificationMessage(notificationGroup?.key, notification)"></app-bbcode-viewer>
            </div>
            <div class="actions-container">
              <button class="read-unread" [matTooltip]="isNotificationUnread(notification) ? 'Marcar como lida' : 'Marcar como não lida'" (click)="toggleReadState(notification)">
                <mat-icon *ngIf="isNotificationUnread(notification)">lens</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #emptyWarn>
  <app-alert type="info" dense>Nenhuma notificação por enquanto.</app-alert>
</ng-template>
