import { Injectable } from '@angular/core';
import { CustomErrorField } from '@colmeia/core/src/error-control/custom-error';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { metaTranslationsFields } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { $SharedServices } from '@colmeia/core/src/shared-business-rules/shared-services/shared-services';
import { defineSharedServices } from '@colmeia/core/src/shared-business-rules/shared-services/shared-services.functions';
import { ISharedServiceRequest, ISharedServiceResponse } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services.interfaces";
import { assign } from '@colmeia/core/src/tools/utility';
import { throwErrorMessage } from '@colmeia/core/src/tools/utility/functions/error.functions';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';


@Injectable({
    providedIn: 'root'
})
export class SharedService {

    public isInitialized: boolean = false;

    constructor(
        private api: ServerCommunicationService,
        private globalWarningService: GlobalWarningService,
    ) { }

    public init() {
        this.injectClientHandlers();
    }

    public injectClientHandlers() { 
        if (this.isInitialized) return; 
        defineSharedServices((info) => { 
            Reflect.set(info.service, info.methodName, async (...input: {}[]) => {
                const response = await this.api.sendRequest<ISharedServiceRequest, ISharedServiceResponse>(info.fnName)({
                    input
                });
                return response?.output;
            });
        }, {
            shouldCallAfterInit: true
        });

        assign<GenericSharedService.Core>(GenericSharedService, {
            isServer: false,
            throw: (input: GenericSharedService.ThrowInput): never => {
                try { throwErrorMessage(input) } catch (error) {
                    if (!(error instanceof CustomErrorField)) throw error;
                    const idError = error.getSerializableID();
                    const friendly = new FriendlyMessage(error.getFunctionName(), true);
                    friendly.isWarning = error.isWarning;
                    friendly.addCustomMessages(idError, error.getAdditionalMessages(), error.title);
                    friendly.setBusinessError(!error.isError());
                    this.globalWarningService.showError(friendly);
                    throw friendly;
                }
            },
            metaTranslationsFields,
        });

        Object.assign(globalThis, this.getSharedServices());

        this.isInitialized = true;
    }

    public getSharedServices() {
        return $SharedServices;
    }
}
