import { MatSnackBar } from '@angular/material/snack-bar';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Directive, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { IColmeiaDialogComponentData } from '../dialog/dialog.component';
import {EMsgDialogType} from "../../../model/misc.model";
import {IMsgDialogDescriptor} from "../../../model/waring-message-interfaces";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {ITranslationConfig} from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import {IChangeInterfaceListener} from "../../../model/signal/ps-interfaces";
import {Subject, Subscription} from "rxjs";
import {SignalListenerService} from "../../../services/signal/signal-listener";
import {arrayUnique, isValidRef} from "@colmeia/core/src/tools/utility";
import {AppComponent} from "../../../app.component";
import {Translation} from "../../foundation/translation/translation-helper";
import {InterfaceInfoSignal} from "../../../model/signal/interface-signal";
import { IFriendlyMessageJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { copyToClickBoard } from 'app/utils/copy';
import { SessionService } from 'app/services/session.service';
import { uniq } from 'lodash';
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { errorCodes } from '@colmeia/core/src/error-control/error-definition';

@Directive()
class RootComponent<T extends string> implements OnInit, OnDestroy, IChangeInterfaceListener {

    protected onTranslation = new Subject<void>();
    private translationSub: Subscription = new Subscription();
    private _listener: SignalListenerService;

    constructor(
        public translations: { [key in T]: ITranslationConfig } = null,
        autoTranslate: boolean = true,
        private cdrForTranslation: ChangeDetectorRef = null
    ) {
        if (isValidRef(translations) && autoTranslate) {
            this.translate();
            this._listener = AppComponent.injector.get(SignalListenerService);
            this._listener.listenToInterfaceChanges(this);
        }
    }

    protected translate() {
        let key, config;
        try {
            for ([key, config] of Object.entries<ITranslationConfig>(this.translations)) {
                const value = Serializable.getTranslation(config);
                this.translations[key] = new Translation(config.serializableId, config.idField, value);
            }
        } catch (e) {
            console.table({
                key,
                config
            });
        }
        if (this.cdrForTranslation) {
            this.cdrForTranslation.markForCheck();
        }
        this.onTranslation.next();
    };

    protected subscribeToTranslations(callback: any) {
        this.translationSub.add(this.onTranslation.subscribe(callback));
    }

    ngOnInit() {}

    ngOnDestroy() {
        if (this._listener) {
            this._listener.destroySubscriptions(this);
        }
        this.translationSub.unsubscribe();
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (sign.languageChanged) {
            this.translate();
        }
    }

    getTranslation(translation: ITranslationConfig): string {
        return Serializable.getTranslation(translation);
    }

}


@Component({
    selector: 'app-global-warning',
    templateUrl: './global-warning.component.html',
    styleUrls: ['./global-warning.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalWarningComponent extends RootComponent<
    'ops' |
    'success' |
    'error' |
    'close'
> implements OnInit {
    public icon: string;
    public title: string;
    public messages: string[];
    public buttons: TSerializableArray;
    public showSeparator = true;
    public type: EMsgDialogType = EMsgDialogType.None;
    private currentMsgDesc: IMsgDialogDescriptor;
    public showDevData: boolean = false;
    public additionalText: string[] = [];
    public errorDump: IResponse | IFriendlyMessageJSON;
    public shouldPrintCustomErrorMessages: boolean = false;
    public customErrorMessages: string[] = [];
    public isInternalError: boolean = false;
    public customErrorIDs: string[] = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        private dialogRef: MatDialogRef<GlobalWarningComponent>,
        private matSnack: MatSnackBar,
        private sessionSv: SessionService,
        @Inject(MAT_DIALOG_DATA)
        dataFromParentComponent: IColmeiaDialogComponentData<IMsgDialogDescriptor>
    ) {
        super({
            ops: gTranslations.warning.opsTitle,
            success: gTranslations.warning.successTitle,
            error: gTranslations.common.error,
            close: gTranslations.common.close
        }, true, cdRef);
        this.currentMsgDesc = dataFromParentComponent.getParamsToChildComponent();
    }

    
    public initCustomErrorMessages(): void {
        const json = this.currentMsgDesc.friendlyMessage?.toJSON();

        if (!json) return ;

        
        this.customErrorMessages = json.friendlyErrorArray
            .map(item => {
                this.shouldPrintCustomErrorMessages ||= !!item.shouldPrintCustomMessageOnly;
                if (item.title) {
                    this.title = item.title;
                }
                return item.customMessage
            })
            .filter(isValidRef)
        ;

        this.customErrorIDs = arrayUnique(json.friendlyErrorArray.map(item => item.errorID));
        this.isInternalError = !json.isBussError && this.customErrorIDs.includes(errorCodes.client.internalError);
    }
    
    ngOnInit() {
        this.messages = [];
        if (!this.currentMsgDesc.icon) this.showSeparator = false;
        this.icon = this.currentMsgDesc.icon;
        this.title = this.currentMsgDesc.title || '';
        this.initCustomErrorMessages();

        this.errorDump = this.currentMsgDesc.serverResponse || this.currentMsgDesc.friendlyMessage?.toJSON();

        if(isValidRef(this.errorDump)) {
            if (isValidRef((this.errorDump as IFriendlyMessageJSON).sucessMessage)) {
                const sucessMessage = (this.errorDump as IFriendlyMessageJSON).sucessMessage;

                this.additionalText = sucessMessage.map( msg => msg.additionalText ) || [];
            } else {
                this.additionalText = [ JSON.stringify(this.errorDump, null, 4) ];
            }
        }

        this.initMessages(this.currentMsgDesc);
        this.cdRef.markForCheck();
    }

    isSuccess(): boolean {return this.type == EMsgDialogType.Success;}
    isError(): boolean { return this.type == EMsgDialogType.Error;}
    isWarning(): boolean { return this.type == EMsgDialogType.Warning;}

    initMessages(msgDesc: IMsgDialogDescriptor): void {
        const json = this.currentMsgDesc.friendlyMessage?.toJSON();

        this.type = json?.isBussError ? EMsgDialogType.Warning : msgDesc.type;
        
        this.messages = uniq(msgDesc.messages.map(msg => {
            if (typeof msg === 'string') {
                return msg;
            }
            return Serializable.getTranslation(msg as ITranslationConfig);
        }));
    }

    getMessages(): string[] {
        return this.shouldPrintCustomErrorMessages ? this.customErrorMessages : this.messages;
    }

    public close(): void {
        this.dialogRef.close();
    }

    public clickHandler(clickedBtn: Serializable): void {
        if (this.currentMsgDesc && this.currentMsgDesc.clientCallback) {
            this.currentMsgDesc.clientCallback.onOptionClickedCallback(clickedBtn);
        }
    }

    get globalWarningTitle(): string {
        if (this.shouldPrintCustomErrorMessages && this.title) {
            return this.title;
        }
        switch(this.currentMsgDesc.type){
            case EMsgDialogType.Warning:
                return this.translations.ops.value;
            case EMsgDialogType.Success:
                return this.translations.success.value;
            case EMsgDialogType.Error:
                return this.translations.error.value;
        }
        return this.title;
    }

    public async copyErrorDump() {
        await copyToClickBoard(JSON.stringify(this.errorDump, null, 4));
        this.matSnack.open("Copiado para a área de transferência!", "Fechar", { duration: 3000 });
    }

    public isLoggedIn(): boolean {
        return this.sessionSv.isLogged()
    }
}
