import { ThemeIntializer } from "app/bootstrap/theme-initializer";
import {UberCacheIntializer} from "./uber-initialiazer";

export function AppInitialize(uber: UberCacheIntializer, theme: ThemeIntializer): () => Promise<void> {
    return async (): Promise<any> => {
        const uberLoaded: boolean = await uber.initialize();
        if (!uberLoaded) {
            document.body.classList.add('error-loading');
            throw 'Erro loading uber';
        }

        theme.initialize();
    };
}
