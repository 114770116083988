import { FindKeysWithValueOf } from "../tools/utility-types";
import { Define } from "../tools/utility/types/entities/define";

export type TInteractionTypeID = EGeolocationIntType 
    | ETimedInteractionTypeID 
    | EMessageStandard
    | EServiceGroupIntType

export enum ETimedInteractionTypeID {
    rememberMe = 'tRememberme',
    suicidal =   'TTLMessage',
    sendAt =     'tSendAt',
    dueDate =    'tDueDate',
    delivered = 'delivered'
}

export enum EMessageStandard {
    deleteInteraction = 'deleteOwnMessage',
    message           = 'message',
    citation          = 'citation',
    checkIn           = 'checkIn',
    sponsoredMsg      = 'advertisingMessage',
    adminMessage      = 'adminGroupMessage',
    personalMessage   = 'personalMessage',
    driverInteraction =  'drivenInteraction',
}

export enum EMessageReplicateType {
    noReplication = 1,
    timedMessage = 2,
    smartShare = 3,
    distribution = 4,
    oneWayShare = 5,
    subscription = 6,
    broadcast = 7,
    wallet = 8,
    embeddedChat = 9
};

export enum EThreadType {
    featureThread = 'feat',
    socialCC = 'socialCC',
    formCreated = 'form',
    userCreated = 'usr',
}


export enum EServiceGroupIntType {
    startServiceChat = 'startServiceChat',
    finishService = 'finishChatService',
    activities = 'yourColmeia/activities',
    serviceBotConfig = 'serviceBotConfig',
}


export enum EGeolocationIntType {
    turnGpsOn = 'turnGpsOn',
    turnGpsOff = 'turnGpsOff',
    beginRealtimeTracking = 'realTimeGpsTrackerOn',
    endRealTimeTracking = 'realTimeGpsTrackerOff',
}

export enum ESubmissionStatus {
    Aproved = 'approved',
    Denied  = 'denied',
    Pending = 'pending'
}

export type MapUnitTypeToPrimitive = Define<{ [key in EUnitTypeID]?: unknown }, {
   [EUnitTypeID.stringType]: string;
   [EUnitTypeID.numberType]: number;
}>

export type FindUnitTypeFromPrimitive<T> = FindKeysWithValueOf<MapUnitTypeToPrimitive, T>

export enum EUnitTypeID {
    dateType    = 'date_type',
    logicalType = 'logical_type',
    multipleChoiceType = 'multiple_choice_type',
    numberType  = 'number_type',
    imageType  = 'image_type',
    stringType  = 'string_type',
    objectType = 'object_type',
    geoReferencingOrZipType = 'georeferencingorzip_type',
    groupInput  = 'group_type',
    avatarInput = 'avatar_type'
};

export enum EWalletOperationsIT {
    operation = 'walletOp',
};

export enum EObjectType {
    interactionType = '1',
    sessionTracker = '2',
    interaction = '3',
    role = '5',
    groupType = '7',
    multiMediaType = '8',
    pane= '9',
    permissionRole = '10',
    feedback = '11',
    group  = '12',
    player = '13',
    avatar = '14',
    participant = '15',
    multiMediaObject = '16',
    errorDomain = '17',
    groupSecurityDomain= '18',
    groupSecuritySettings= '19',
    subscriptionType= '20', 
    objectType= '21',
    language= '22',
    permission= '24',
    multiMediaTag= '26',
    multiMediaInstance= '29',
    groupSecurityTemplate= '31',
    securitySettings= '32',
    visualElement= '33',
    queueStatus= '35',
    dueDateType= '36',
};

export enum EWrapperStatus {
    scheduled = 'scheduled',
    processed = 'processed',
    delivered = 'delivered'
}