<ng-container *ngIf="canDisplayFormGroup; else noFormGroup">
    <section class="general-form-field date-field" [formGroup]="fGroup" [hidden]="!canDisplayField">
        <div *ngIf="isEdit; else viewMode">
            <mat-form-field *ngIf="!showAsCalendar" appearance="outline">
                <mat-label>{{ field.prompt }}</mat-label>
                <input matInput [matDatepicker]="dp3" readonly [(ngModel)]="time" [formControlName]="field.idProperty" [required]="isRequired">
                <div class="mat-form-field-flex" matSuffix>
                    <mat-datepicker-toggle [for]="dp3"></mat-datepicker-toggle>
                    <mat-icon >{{ statusIcon }}</mat-icon>
                </div>
                <mat-datepicker  #dp3 [disabled]="isDisabled"></mat-datepicker>
                <mat-error>{{ getErrorMsg() }}</mat-error>
            </mat-form-field>

            <div *ngIf="showAsCalendar" class="inline-calendar-container" [class.c-disabled]="isDisabled">
              <h5 class="inline-calendar-title">
                {{ field.prompt }} <mat-icon >{{ statusIcon }}</mat-icon>
              </h5>
              <mat-calendar *ngIf="!isDisabled" [@verticalAppear]="'in'" [(selected)]="time"></mat-calendar>
            </div>
        </div>
    </section>
</ng-container>

<ng-template #noFormGroup>
    <section class="general-form-field date-field" [hidden]="!canDisplayField">
        <div *ngIf="isEdit; else viewMode">
            <mat-form-field *ngIf="!showAsCalendar" appearance="outline">
                <mat-label>{{ field.prompt }}</mat-label>
                <input matInput [matDatepicker]="dp3" readonly [(ngModel)]="time" [required]="isRequired">
                <div class="mat-form-field-flex" matSuffix>
                    <mat-datepicker-toggle [for]="dp3"></mat-datepicker-toggle>
                </div>
                <mat-datepicker  #dp3 ></mat-datepicker>
            </mat-form-field>

            <div *ngIf="showAsCalendar" class="inline-calendar-container" [class.c-disabled]="isDisabled">
              <h5 class="inline-calendar-title">
                {{ field.prompt }} <mat-icon >{{ statusIcon }}</mat-icon>
              </h5>
              <mat-calendar *ngIf="!isDisabled" [@verticalAppear]="'in'" [(selected)]="time"></mat-calendar>
            </div>
        </div>
    </section>
</ng-template>

<ng-template #viewMode>
    <mat-form-field appearance="fill">
        <mat-label>{{ field.prompt }}</mat-label>
        <input matInput [value]="field.value" readonly>
    </mat-form-field>
</ng-template>
