<app-webchat-header *ngIf="isWidgetsApi && !isCopilotWebchat" hideDescription></app-webchat-header>
<div id="screen" class="content" [class.is-embedded]="isEmbedded" [class.disable-pointer-events]="isEmbedded && isFinishingEmbeddedConversation()">
  <!-- nav bar -->
  <nav class="chat-header" *ngIf="canShowNav()">
    <span
      *ngIf="getGroupTypeID() != getChannelConstant()"
      class="title__textTitle"
    >
      {{ getGroupName() | slice: 0:16 }}
    </span>

    <span *ngIf="getGroupTypeID() == getChannelConstant()">
      <span class="title__textTitle">
        {{ getGroupName() | slice: 0:16 }}
      </span>
      <span class="separator"></span>
      <span>{{ getGroupName() | slice: 0:16 }}</span>
    </span>

    <button class="button-toggle-rightbar" (click)="openRightSideBar()" tapFeedback="circular">
      <i class="material-icons">chevron_left</i>
    </button>
  </nav>

  <!-- messages -->
  <div
    id="chat-screen"
    [ngClass]="{
      'chat-screen--mright': canShowRightSideBar() && isNotHidingRightBar(),
      'embedded': isEmbedded
    }"
  >
    <ng-container *ngIf="lastAttendanceEvent | async as attendanceEvent">
      <div *ngIf="attendanceEvent.visibleFor(currentGroupID) && !attendanceEvent.isStart" class="attendance-event-overlay">
        <h4 class="ev-message">
          <ng-container *ngIf="attendanceEvent.type !== 'end'">
            <mat-spinner color="primary" diameter="28"></mat-spinner>&nbsp;
          </ng-container>
          <ng-container *ngIf="attendanceEvent.type === 'end'">
            <mat-icon style="color: var(--success-default);" class="mat-28">check</mat-icon>&nbsp;
          </ng-container>

          {{ attendanceEvent.message }}
        </h4>
      </div>
    </ng-container>
    <div class="breadcrumb" *ngIf="canShowBreadCrumb()">
      <div class="breadcrumb__container">
        <i class="material-icons icon-home">home</i>
        <cm-breadcrumb [descriptor]="breadCrumbDescriptor"></cm-breadcrumb>
      </div>
      <div class="chat-right-bar-icon">
        <button (click)="openRightSideBar()" tapFeedback="circular">
          <i class="material-icons">chevron_left</i>
        </button>
      </div>
    </div>
    <div
      class="message-container__list"
      *ngFor="let model of getAllChatBackboneModels()"
      [ngClass]="{
        'height--full': model.isActive(),
        'disable-padding-top': isEmbedded
      }"
    >
      <app-message-container
        class="talking"
        *ngIf="model.isActive()"
        [handler]="model.getChatContainerHandler()"
        [chatBackboneHandler]="handler"
        (click)="closeSidebars()"
      >
      </app-message-container>
    </div>
  </div>

  <div
    *ngIf="canShowLeftSideBarOnMobile() || canShowRightSideBarOnMobile()"
    class="sidebar-overlay"
    (click)="closeSidebars()"
  ></div>


  <!-- Features left / Menu that appears on the left side of the screen -->
  <ng-container *ngIf="canShowLeftSideBar()">

  </ng-container>
  <!--div
    class="left__sidebar chat-features"
    [class.sidebar--visible]="canShowLeftSideBarOnMobile()"
    [class.d-sidebar--visible]="isNotHidingLeftBar()"
  >
    <div class="all-talking">
      <app-group-chat-left-side-bar
        [restrictedWidth]="true"
      ></app-group-chat-left-side-bar>
    </div>
  </!div-->

  <!-- Features / Menu that appears on the right side of the screen-->
  <ng-container *ngIf="canShowRightSideBar()">
    <div
      class="--right-bar right__sidebar chat-features"
      [class.sidebar--visible]="canShowRightSideBarOnMobile()"
      [class.d-sidebar--visible]="isNotHidingRightBar()"
    >
      <ng-container *ngIf="lastAttendanceEvent | async as attendanceEvent">
        <div *ngIf="attendanceEvent.visibleFor(currentGroupID) && !attendanceEvent.isStart" class="attendance-event-overlay">
        </div>
      </ng-container>
      <app-group-chat-right-side-bar
        [chatBackBoneModelInstance]="getActiveBackboneModel()"
      >
      </app-group-chat-right-side-bar>
    </div>
  </ng-container>

  <div id="back-right-chat-features" class="right__backbar" [class.is-visible]="!isNotHidingRightBar()" *ngIf="!isEmbedded">
    <div class="back-button" (click)="toggleRightBarIfIsNotMobile()">
      <mat-icon>chevron_left</mat-icon>
    </div>
  </div>
</div>
