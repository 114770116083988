<div class="var-editor use-tiptap" *ngIf="hasParameters">
  <ng-container *ngIf="!isLoading; else loader">
    <div class="form">
      <div class="c-card mat-elevation-z2">
        <app-var-editor-text-area
          *ngIf="rawTextHandler"
          class="main-editor"
          [class.has-vars]="allVariables?.length"
          (click)="select('rawTextHandler')"
          [handler]="rawTextHandler"
          [allVariables]="allVariables">
        </app-var-editor-text-area>

        <mat-card *ngIf="allVariables?.length" class="variables-container mat-elevation-z0">
          <div class="variables-header">
            <div class="filters-buttons">
              <button
                *ngIf="hasSomeSchemaProperty()"
                matRipple
                class="filter-button"
                (click)="schemaPropertyFilter = !schemaPropertyFilter"
                [class.active]="schemaPropertyFilter"
              >
                <mat-icon size="14" matTooltip="Propriedade" matTooltipPosition="above">list_alt</mat-icon>
              </button>
              <button
                *ngIf="hasSomeTag()"
                matRipple
                class="filter-button"
                (click)="tagFilter = !tagFilter"
                [class.active]="tagFilter"
              >
                <mat-icon size="14" matTooltip="Tag variável" matTooltipPosition="above">local_offer</mat-icon>
              </button>
              <button
                *ngIf="hasSomeCanonical()"
                matRipple
                class="filter-button"
                (click)="canonicalFilter = !canonicalFilter"
                [class.active]="canonicalFilter"
              >
                <mat-icon size="14" matTooltip="Significado" matTooltipPosition="above">spellcheck</mat-icon>
              </button>
            </div>
            <div class="search-container">
              <mat-icon size="16">search</mat-icon>
              <input #searchInput class="simple-input" [class.has-content]="searchToken.length" matInput [(ngModel)]="searchToken" (ngModelChange)="buildSearchVariablesIteration()" />
              <button mat-icon-button class="clear-button" [class.visible]="searchToken" (click)="searchToken = ''; searchInput.focus()">
                <mat-icon size="16" style="line-height: 16px;">cancel</mat-icon>
              </button>
            </div>
          </div>
          <div class="variables-list nice-scroll">
            <ng-container *ngFor="let vr of variablesIteration">
              <button
                *ngIf="vr.variable"
                [appHighlightEl]="getHighlighQuery(vr.variable)"
                highlightClassName="highlighted-accent"
                (click)="insertVariable(vr)"
                class="variable mat-chip mat-standard-chip"
                [class.is-safe]="allVariablesSafeInfoCache[vr.idProperty]"
                [matTooltip]="allVariablesSafeInfoCache[vr.idProperty] ? 'Variável segura (informação garantida)' : ''"
                matTooltipClass="safe-var-tooltip"
              >
                <mat-icon *ngIf="isSchemaProperty(vr)" 
                  class="type"
                  matTooltip="Propriedade"
                  matTooltipPosition="above">list_alt</mat-icon>
                <mat-icon *ngIf="vr.isTagVariable" [matTooltip]="vr.tooltip" matTooltipPosition="above" class="type">local_offer</mat-icon>
                <mat-icon
                  *ngIf="vr.canonical"
                  [class.type]="!isSchemaProperty(vr)"
                  [matTooltip]="vr.tooltip"
                  matTooltipPosition="above">
                  spellcheck
                </mat-icon>
                {{ removeBrackets(vr.variable) }}
              </button>
            </ng-container>
          </div>
        </mat-card>
      </div>
      <div [visible-horizontal]="hasMessageIfNoBind()">
        <h5 class="fallback-title">Mensagem enviada quando alguma variável não é encontrada</h5>
        <div class="c-card mat-elevation-z2" style="margin-bottom: 8px;">
          <app-var-editor-text-area
            *ngIf="fallbackHandler"
            class="fallback-editor"
            (click)="select('fallbackHandler')"
            [handler]="fallbackHandler"
            [allVariables]="allSafeVariables">
          </app-var-editor-text-area>
        </div>
      </div>
      <mat-dialog-actions *ngIf="isDialog" align="end">
        <button mat-button (click)="closeWithoutSave()">Cancelar</button>
        <button mat-flat-button color="primary" (click)="save()">Salvar</button>
      </mat-dialog-actions>
    </div>
  </ng-container>
</div>
<ng-template #loader>
  <div class="spinner-container">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-template>
