import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { EListNonSerializablesSortOrder, IListNonSerializablesMatch } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { IFileField } from "@colmeia/core/src/request-interfaces/files-interfaces";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { EDefaultTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import {
    ENonSerializableObjectType,
    INonSerializable,
    TNonSerializableArray
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ITagableSearch } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { IComponentParameter, IRootCallBack } from "../../../../model/component-comm/basic";
import { IWindowRuntimeSetupConfig } from '../colmeia-window/colmeia-window.model';
import { IGDPerPageOptions } from "../generic-dashboard-pagination/generic-dashboard-pagination.parameter";
import { TMoreOptionsConfigs, TNSMoreOptionsCustomOptionsArray } from "app/handlers/ns-more-options.handler";
import { Define } from "@colmeia/core/src/tools/utility/types/entities/define";
import { ValueOf } from "@colmeia/core/src/tools/utility-types";
import { ICampaignActionPicker } from "../../campaign-action-picker/campaign-action-picker.component";

// Callbacks:
export type GD_MultipleNSCallback = (nsers: INonSerializable[]) => Promise<void>;
export type GD_EntityCallback<T extends INonSerializable = INonSerializable> = (nser: T, $event?: Event, $row?: HTMLElement) => Promise<void | boolean>;
export type GD_EntityShow = (nser: INonSerializable) => boolean;
export type GD_Callback = () => void;

export type TGenericFilters = {
    title: string,
    property: string,
    filterOptions: Array<TGenericFilterOptions>
}
export type TGenericHomeFilters = {
    filters: Array<TGenericFilters>,
    default?: IDefaultFilter,
    /**
     * Se mustUseFilter for true bloqueia exclusão de filtros.
     * Usar quando valor de filtro for obrigatório.
    */
    mustUseFilter?: boolean,
    onFilterChange?: ({ property, value }: { property: string, value: IListNonSerializablesMatch }) => void
    onClearFilter?: () => void
}

export type TGenericFilterOptions = {
    match: IListNonSerializablesMatch,
    label: string
}
export interface IDefaultFilter extends TGenericFilterOptions {
    property: string
}

export type TMapOrdernation<T extends INonSerializable = INonSerializable> = {
    [columnName in string]?: keyof INonSerializable
};

export type TDefaultMapOrdernation<T extends INonSerializable = INonSerializable> = {
    [columnName in keyof INonSerializable]?: EListNonSerializablesSortOrder
};

export interface IGenericHomeHandlerOrdinationConfig<T extends INonSerializable = INonSerializable> {
    allow?: boolean;
    omitClockTick?: boolean;
    map?: TMapOrdernation<T>;
    itemTitleName?: string;
    /**
     * Objeto de chave única, apenas o primeiro valor é usado na requisição.
     */
    default?: TDefaultMapOrdernation<T>
}

export interface IGenericHomeHandlerParameter<T extends INonSerializable = INonSerializable, D = any> extends IComponentParameter {
    skipFirstRequest?: true;
    skipTagFilter?: true;
    nsType: ENonSerializableObjectType;
    loadPlaceholdRowsLength?: number;
    multiplePickAble?: {
        callbacks: GD_MultipleEntityButton[];
    },
    labels: {
        title: string;
        description?: string;
        helpTipKey?: ETooltipInfoKey;
    };
    fixedCallbacks: {
        custom?: GD_InterfaceButton[];
        create?: GD_InterfaceButton;
        back?: GD_InterfaceButton;
    };
    fixedRowCallbacks: {
        custom?: GD_EntityButton<T>[];
        edit?: GD_EntityButton<T>;
        deploy?: boolean;
        copyID?: boolean;
        delete?: boolean;
        checkDependencies?: boolean;
        backup?: boolean;
        clone?: boolean;
        archive?: boolean;
        changesHistory?: boolean;
        deploysHistory?: boolean;
    };
    customRowCallbacks?: {
        delete?: GD_EntityCallback;
        archive?: GD_EntityCallback<T>;
        copyID?: GD_EntityCallback;
        checkDependencies?: GD_EntityCallback;
    }
    pagination?: IGDPerPageOptions;
    disablePagination?: boolean;
    clientCallback: IDashboardClientCallback;
    forcedTitles?: TArrayID;
    spinWhileLoadingEntities?: boolean;
    ordination?: IGenericHomeHandlerOrdinationConfig<T>;
    archive?: boolean;
    disableMoreOptions?: boolean;
    genericWindowConfig?: IWindowRuntimeSetupConfig<T, D>,
    removeStandardEntities?: Array<any>;
    clientFilters?: TGenericHomeFilters;
    defaultTag?: EDefaultTag; // pro caso de quando for criar dialog em cima de uma janela existente no dashboard
    customNSMoreOptions?: TNSMoreOptionsCustomOptionsArray;
    nsMoreOptionsConfigs?: TMoreOptionsConfigs

}

export interface GD_InterfaceButton {
    custom?: GD_ButtonCustomization;
    callback: GD_Callback;
}

export interface GD_CallbackMoreOptionsButton {
    callback: GD_EntityCallback;
}

export interface GD_EntityButton<T extends INonSerializable = INonSerializable> {
    custom?: GD_ButtonCustomization;
    show?: GD_EntityShow;
    callback: GD_EntityCallback<T>;
}

export interface GD_ButtonCustomization {
    icon?: string;
    text?: string;
    disabled?: boolean;
    tooltipMessage?: string;
}

export enum EGenericDashboardColumnType {
    Text = 't',
    Labels = 'l',
    Link = 'lk',
    JSONString = 'jsstr',
    Date = 'dt',
    Timestamp = 'tmstp',
    Error = 'err',
    ID = 'id',
    Tag = 'tag',
    LongText = 'longText',
    CodeEditor = 'codeEditor',
    Status = 'status',
    StatusWithText = 'status-with-text',
}

export interface IGenericDashboardColumn {
    title?: string;
    type: EGenericDashboardColumnType;
    tooltip?: string;
    style?: object | string;
    class?: string;
}

export interface IGenericImage {
    src: string;
    class?: string;
    width?: number;
    height?: number;
    style?: object;
}

export type MapGenericDashboardColumn = Define<{ [type in EGenericDashboardColumnType]: IGenericDashboardColumn & { type: type } }, {
    [EGenericDashboardColumnType.Text]: IGenericDashboardTextColumn;
    [EGenericDashboardColumnType.Labels]: IGenericDashboardLabelsColumn;
    [EGenericDashboardColumnType.Link]: IGenericDashboardLinkColumn;
    [EGenericDashboardColumnType.JSONString]: IGenericDashboardJSONStringColumn;
    [EGenericDashboardColumnType.Date]: IGenericDashboardDateColumn;
    [EGenericDashboardColumnType.Timestamp]: IGenericDashboardTimestampColumn;
    [EGenericDashboardColumnType.Error]: IGenericDashboardErrorColumn;
    [EGenericDashboardColumnType.ID]: IGenericDashboardIDColumn;
    [EGenericDashboardColumnType.Tag]: IGenericDashboardTagColumn;
    [EGenericDashboardColumnType.LongText]: IGenericDashboardLongTextColumn;
    [EGenericDashboardColumnType.CodeEditor]: IGenericDashboardCodeEditorColumn;
    [EGenericDashboardColumnType.Status]: IGenericDashboardStatusColumn;
    [EGenericDashboardColumnType.StatusWithText]: IGenericDashboardStatusWithTextColumn;
}>;


export interface IGenericDashboardTextColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.Text;
    imageBefore?: IGenericImage;
    imageAfter?: IGenericImage;
    iconBefore?: string;
    title: string;
    isExpanded?: boolean;
    isItemName?: boolean;
    value: string;
    valueMask?: string;
    callback?: GD_Callback;
    textTooltip?: string;
}

export interface IGenericDashboardLongTextColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.LongText;
    truncSize: number;
    value: string;
}

export interface IGenericDashboardCodeEditorColumn extends Omit<IGenericDashboardJSONStringColumn, 'type'> {
    type: EGenericDashboardColumnType.CodeEditor;
    label?: string;
    icon?: string;
    language: string;
    isPreformatted?: boolean;
}

export type GenericDashboardColumn = ValueOf<MapGenericDashboardColumn>;

export interface IGenericDashboardLinkColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.Link;
    title: string;
    label: string;
    link: string;
}

export interface IGenericDashboardJSONStringColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.JSONString;
    title: string;
    value: string;
}

export interface IGenericDashboardErrorColumn extends Omit<IGenericDashboardJSONStringColumn, 'type'> {
    type: EGenericDashboardColumnType.Error;
}

export interface IGenericDashboardLabelsColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.Labels;
    showAsMenu?: boolean;
    labels: IGenericDashboardLabel[];
}

export interface IGenericDashboardDateColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.Date;
    title: string;
    value: string;
}

export interface IGenericDashboardTimestampColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.Timestamp;
    title: string;
    value: number;
}

export interface IGenericDashboardIDColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.ID;
    title: string;
    value: string;
}

export interface IGenericDashboardTagColumn extends IGenericDashboardColumn {
    type: EGenericDashboardColumnType.Tag;
    title: string;
    value: string;
}

export interface IGenericDashboardLabel {
    text?: string;
    tooltip?: string;
    style?: object | string;
    svgIcon?: string;
    matIcon?: string;
    copyToClipboard?: string;
}

export type TGenericDashboardColumns = IGenericDashboardColumn[];

export interface IGenericHomeRow {
    columns: TGenericDashboardColumns;
    nser: INonSerializable;
}

export interface IMapResponseGeneric {
    nonSerializableArray: TNonSerializableArray
}

export interface IGenericDashboardRequestParameters {
    taggable?: ITagableSearch;
    perPage?: number
    cursors: IFileField;
}
export type GenericDashboardRowFilter = (entity: IGenericHomeRow) => boolean;
export interface IDashboardClientCallback extends IRootCallBack {
    getRequest(parameters: IGenericDashboardRequestParameters): IRequest;
    mapResponse?(response: IResponse): IMapResponseGeneric;
    mapEntity?(nser: INonSerializable): IGenericHomeRow;
    filterEntities?: GenericDashboardRowFilter;
    listLoaded?(): void;
}

export interface IGenericHomeClientCallback {
    getNSList(): INonSerializable[];
    mustRefresh(): Promise<void>;
    refreshPage(): Promise<void>;
    clearPickedItems();
    clearCursorsState();
    clearEntities();
}

export type GD_MultipleEntityButton = GD_MultipleEntityButtonIcon | GD_MultipleEntityButtonStandard;

interface GD_MultipleEntityButtonBase {
    color?: string;
    callback: GD_MultipleNSCallback;
    icon?: string;
    text: string;
    type?: 'icon' | 'stroked' | 'flat'
}

export interface GD_MultipleEntityButtonIcon extends GD_MultipleEntityButtonBase {
    type: 'icon';
    icon: string;
}

export interface GD_MultipleEntityButtonStandard extends GD_MultipleEntityButtonBase {
    type?: 'stroked' | 'flat';
}

export type TGenericDashboardStatusColumnColor = 'success' | 'info' | 'warning' | 'error'

export interface IGenericDashboardStatusColumn extends Omit<IGenericDashboardJSONStringColumn, 'type'> {
    type: EGenericDashboardColumnType.Status;
    showViewerStatus: Boolean;
    color: TGenericDashboardStatusColumnColor;
    textTooltip: string;
}

export interface IGenericDashboardStatusWithTextColumn extends Omit<IGenericDashboardJSONStringColumn, 'type'> {
    type: EGenericDashboardColumnType.StatusWithText;
    color: 'success' | 'info' | 'warning' | 'error';
}
