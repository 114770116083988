import { ILogCharacteristic } from '../core-constants/tracker-qualifiers';
import { EConfirmationType, ESecurityChanged, TArrayID, TGlobalUID } from "../core-constants/types";
import { IErrorDescription } from "../interaction/tracker/send-client-error";
import { ILocationCoordinates } from "../tools/geo-util";
import { IUniversalJSON } from './business-interfaces';
import { IClientInfoPlayer, IPlayerAccessCached } from "./ds-interfaces";
import { ICacheFeedbackInteraction } from "./feedback-interfaces";
import { IChainedInteractionJSON, IInteractionJSON } from "./interaction-interfaces";

export type TTrackerInteractionArrayJSON =  Array<ITrackerInteractionJSON>;
export type TISenderSignatureArrayJSON = Array<ISenderSignatureJSON>;
export type TTrackerReceivedInteractionArrayJSON = Array<ITrackerReadReceiveInteractionJSON>;
export type TInOutGroupArrayJSON = Array<InOutGroupTrackerInteractionJSON>;
export type TReadPostTrackerArray = Array<IReadPostsTrackerJSON>;
export type TTrackerUniversalJSONArray = Array<ITrackerUniversalJSON>;
export type TReadMMTrackerArray = Array<IReadMMTrackerJSON>;
export type TTrackerGPSJSONArray = Array<ITrackerGPSJSON>;


export interface IInteractionLiveInfo {
    idGroup: TGlobalUID;
    idInteractionType: string;
    primaryID: TGlobalUID;
    nameAvatar: string;
    clockTick: number;
    text: string;
};



export interface ISenderSignatureJSON {
    spokeParticipant: boolean,
    idParentEntity: TGlobalUID,
    idAvatar: TGlobalUID,
    avatarName: string,
    idParticipant: TGlobalUID,
    idGroup: TGlobalUID,
    clockTick?: number;
};


export interface ITrackerInteractionJSON extends IInteractionJSON {
    trackerType: number;
    idSession?: TGlobalUID;
};


export enum ETrackerPayloadType {
    notification = 'notification'
}

export interface ITrackerPayloadInfo {
    payloadType: ETrackerPayloadType;
    payload: Object;
    targetGroup?: string;
}
export interface ITrackerPayloadCarrierDeliveryGuarantee extends ITrackerInteractionJSON, ITrackerPayloadInfo {
}

export interface ITrackerSignature extends ITrackerInteractionJSON {
    receivedSignature: ISenderSignatureJSON;
    receiveSignatureCycle?: TISenderSignatureArrayJSON;
}


export interface ITypingBroadcasterJSON extends ITrackerSignature {};



export interface IAlredySentContact {
    idGroup: TGlobalUID;
    idAvatar: TGlobalUID;
    idPlayer: TGlobalUID;
    idParticipant: TGlobalUID;
};

export type TAlreadySentContactArray = Array<IAlredySentContact>;



export interface ITrackerReadReceiveInteractionJSON extends ITrackerInteractionJSON {
    confirmationType: EConfirmationType;
    contacts: TAlreadySentContactArray;
    idServerInteraction: TGlobalUID;
};


export interface IReadMMInformation extends ITrackerSignature {
    multimediaInteractionID: TGlobalUID;
    multimediaKey: string;
};


export interface InOutGroupTrackerInteractionJSON extends ITrackerInteractionJSON {
    idLastGroup: TGlobalUID;
    idEnteringGroup: TGlobalUID;
};



export interface IFeedbackCarrierTrackerJSON extends IChainedInteractionJSON {
    feedbacks: ICacheFeedbackInteraction;
};


export interface IClientErrorTracker extends ITrackerInteractionJSON {
    error: IErrorDescription;
    qualifier: ILogCharacteristic;
    functionName: string;
    details: Object;
};

export interface IClientDebugTracker extends ITrackerInteractionJSON {
    functionName: string;
    details: Object;
    characteristic: ILogCharacteristic;
};

export interface IChangeDataInteractionJSON extends ITrackerInteractionJSON {
    universal?: IUniversalJSON;
    playerInfo?: IPlayerAccessCached;
    clientInfo?: IClientInfoPlayer;
    changedType: ESecurityChanged;
    archivedGroups?: TArrayID;
    isNewUniversal: boolean
};



export interface IGPSTrackerInteractionJSON extends ITrackerInteractionJSON {
    surrogate?: string;
};


export interface ITrackerUniversalJSON {
    idTracker: TGlobalUID,
    trackerType: number;
    clockTick: number;
    idPlayer: TGlobalUID;
    idAvatar: TGlobalUID;
    idGroup: TGlobalUID,
    idParticipant: TGlobalUID;
    idSession?: TGlobalUID;
}

export interface ITrackerGPSJSON extends ITrackerUniversalJSON {
    coordinates: ILocationCoordinates;
};

export interface IGroupFrequenceTrackerJSON extends ITrackerUniversalJSON {
    idLastGroup: TGlobalUID;
}


export interface IRelatedInteractionTracker extends ITrackerUniversalJSON {
    idInteraction: TGlobalUID;
}

export interface IReadPostsTrackerJSON extends IRelatedInteractionTracker {
    businessID: number;
};

export interface IReadMMTrackerJSON extends IRelatedInteractionTracker {
    multimediaKey: TGlobalUID;
};









