import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { OnChange } from 'app/model/client-utility';

@Directive({
  selector: '[highlightText]'
})
export class HighlightTextDirective implements AfterContentInit  {
  
  @Input() caseSensitive = false;
  @Input() customClasses = "";

  @OnChange()
  @Input('highlightText')
  searchTerm: string;

  originHTML: string;

  constructor(private el: ElementRef) {}

  onChangeSearchTerm() {
    this.highlight(this.originHTML, this.searchTerm);
  }

  ngAfterContentInit(): void {
    this.originHTML = this.el.nativeElement.innerHTML;
    if (this.searchTerm) {
      this.highlight(this.originHTML, this.searchTerm);
    }
  }

  private highlight(text: string, highlight: string): void {
    const regex = new RegExp(
      `(${highlight})`, 
      this.caseSensitive ? "g" : "gi"
      );

    const highlightedText = text.replace(
      regex, 
      `<span class="highlighted-text-directive ${this.customClasses}">$1</span>`);
      
    this.el.nativeElement.innerHTML = highlightedText;
  }
}
