
import { TIBPMBasicConditionalEvaluatorArray } from "../../BPM/bpm-model";
import { IMetadataRegister } from "../../metadata/meta-engagement";
import { IdDep, IdNS } from "../../non-serializable-id/non-serializable-types";
import { TICRMTicketActionArray } from "./crm-config-actions.model";
import { ECRMCanonicalItemsTypes, ECloseState, EServiceManagementPhases, ESeverityLevel, ESupportLevel, ETicketState, EUrgencyLevel } from "./crm-config-canonical-model";
import { TCRMConfigHeaderNsTypes, TCRMConfigItemServer } from "./crm-config-ds-model";

/**
 * Essas interfaces são as representações em memória dos pacotes e itens
 * de atributos salvos no postgre 
 */

export interface ICRMAttributePackageBase {
    headerPackageId: string;
    name: string;
    type: ECRMCanonicalItemsTypes;
    items: Array<TCRMAllPackagesItems>;
}


// TODO: Usar como representação em memoria dos dados persistidos no postgre
export interface ICRMAttributePackageBaseWithItems extends ICRMAttributePackageBase {
    items: Array<TCRMAllPackagesItems>;
}

export interface ICRMAttributeItemBase {
    itemId: string;
    name: string;
    itemType: ECRMCanonicalItemsTypes;
    sortIndex: number;
}

export interface ICRMCloseStateAttributePackage extends ICRMAttributePackageBase {
    items: Array<ICRMCloseStateAttribute>;
}

export interface ICRMCloseStateAttribute extends ICRMAttributeItemBase {
    closeState: ECloseState;
    itemType: ECRMCanonicalItemsTypes.CloseState;
}

export interface ICRMPhaseAttributePackage extends ICRMAttributePackageBase {
    items: Array<ICRMPhaseAttribute>;
}

export interface ICRMPhaseAttribute extends ICRMAttributeItemBase {
    phase: EServiceManagementPhases;
    itemType: ECRMCanonicalItemsTypes.Phase;
}

export interface ICRMSeverityAttributePackage extends ICRMAttributePackageBase {
    items: Array<ICRMSeverityAttribute>;
}

export interface ICRMSeverityAttribute extends ICRMAttributeItemBase {
    severity: ESeverityLevel;
    itemType: ECRMCanonicalItemsTypes.Severity;
}

export interface ICRMStateAttributePackage extends ICRMAttributePackageBase {
    items: Array<ICRMStateAttribute>;
}

export interface ICRMStateAttribute extends ICRMAttributeItemBase {
    ticketState: ETicketState;
    itemType: ECRMCanonicalItemsTypes.TicketState;
}

export interface ICRMSupportLevelAttributePackage extends ICRMAttributePackageBase {
    items: Array<ICRMSupportLevelAttribute>;
}

export interface ICRMSupportLevelAttribute extends ICRMAttributeItemBase {
    supportLevel: ESupportLevel;
    itemType: ECRMCanonicalItemsTypes.SupportLevel;
}

export interface ICRMUrgencyLevelAttributePackage extends ICRMAttributePackageBase {
    items: Array<ICRMUrgencyLevelAttribute>;
}

export interface ICRMUrgencyLevelAttribute extends ICRMAttributeItemBase {
    urgencyLevel: EUrgencyLevel;
    itemType: ECRMCanonicalItemsTypes.UrgencyLevel;
}

export interface ICRMPackagesMap {
    closeState?: ICRMCloseStateAttributePackage;
    phase?: ICRMPhaseAttributePackage;
    severity?: ICRMSeverityAttributePackage;
    ticketState?: ICRMStateAttributePackage;
    supportLevel?: ICRMSupportLevelAttributePackage;
    urgencyLevel?: ICRMUrgencyLevelAttributePackage;
}

export type TCRMAllPackages = ICRMCloseStateAttributePackage | ICRMPhaseAttributePackage | ICRMSeverityAttributePackage | ICRMStateAttributePackage | ICRMSupportLevelAttributePackage | ICRMUrgencyLevelAttributePackage;


export type TCRMAllPackagesItems = ICRMCloseStateAttribute | ICRMPhaseAttribute | ICRMSeverityAttribute | ICRMStateAttribute | ICRMSupportLevelAttribute | ICRMUrgencyLevelAttribute;

export interface ICRMProject {
    packageId: string;
    projectKey: string;
    name: string;
    packages: ICRMPackagesMap;
    config: ICRMProjectConfiguration;
}

export type TICRMProjectArray = Array<ICRMProject>;



export interface ICRMProjectAttributeConfig {
    id: IdDep<TCRMConfigHeaderNsTypes>;
    updateConfig: ICRMAgentAttributeUpdateConfig;
}

export interface ICRMProjectAttributePackagesConfig {
    severity: ICRMProjectAttributeConfig;
    phase: ICRMProjectAttributeConfig;
    ticketState: ICRMProjectAttributeConfig;
    closeState: ICRMProjectAttributeConfig;
    supportLevel: ICRMProjectAttributeConfig;
    urgencyLevel: ICRMProjectAttributeConfig;

    // custom?: unknown;
}

export interface ICRMProjectConfiguration extends ICRMProjectConfigurationBase, ICRMProjectAttributePackagesConfig {
    actions: TICRMTicketActionArray;
}

export interface ICRMProjectConfigurationBase {
    projectKey: string;
}

export interface ICRMAgentAttributeUpdateConfig {
    form: ICRMAgentAttributeUpdateForm;
    items: TCRMAgentAttributeUpdateItems;
}

export type TCRMAgentAttributeUpdateItems = {
    [key in IdNS<TCRMConfigItemServer>]: ICRMAgentAttributeUpdateItem;
};

export interface ICRMAgentAttributeUpdateItem {
    form: ICRMAgentAttributeUpdateForm;
    conditions: TIBPMBasicConditionalEvaluatorArray;
}

export interface ICRMAgentAttributeUpdateForm {
    register: IMetadataRegister;
    control: ECRMAgentAttributeUpdateControl;
}

export enum ECRMAgentAttributeUpdateControl {
    free = 'fr',
    useFormHeader = 'frmhdr',
    useForm = 'frm'
}
