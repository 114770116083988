
import { ICRMTimeWindowConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";
import { ILocalFilterConfig } from "./crm-user-config/crm-user-config.model";

export interface ILocalGroupFilter {
    packageId: string;
    itemPackageId: string;
    label: string;
    filters: ILocalFilterConfig[];
}

export enum ELocalCustomFilters {
    packageFilter = 'package.filter',
    cardsFilter = 'cards.filter',
}

export interface ITimeWindowConfigClient extends ICRMTimeWindowConfig {
    label: string;
}

export interface IErrorState {
    message: string;
}