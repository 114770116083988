import { EMashupSize, IJimpHashInfo, jimpInfos } from "./jimp.constants";

export function getAvailableColorsBySize(size: EMashupSize): IJimpHashInfo {
    const noAvaibleColors: IJimpHashInfo = {
        BLACK: false,
        WHITE: false,
    }
    try {
        const output = jimpInfos['SANS'][size] ?? noAvaibleColors
        return output;
    } catch {
        return noAvaibleColors;
    }
}