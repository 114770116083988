import { Component, Inject } from '@angular/core';
import { GeneralFormFieldInjectionToken, IGeneralFormFieldData, GeneralFormFieldRenderer } from 'app/model/general-form.model';
import { GeneralFormFieldService } from '../services/general-form-field.service';

@Component({
    selector: 'app-general-form-boolean-field',
    templateUrl: './general-form-boolean-field.component.html',
    styleUrls: ['./general-form-boolean-field.component.scss']
})
export class GeneralFormBooleanFieldComponent extends GeneralFormFieldRenderer<null> {

    constructor(
        generalFormFieldService: GeneralFormFieldService,
        @Inject(GeneralFormFieldInjectionToken) injectedtoken: IGeneralFormFieldData
    ) {
        super(generalFormFieldService, injectedtoken);
    }

    async ngOnInit() {
        super.ngOnInit();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    change() {
        this.fControl.setValue(this.field.value);
    }

}
