import { Meta } from '@colmeia/core/src/shared-business-rules/catalog/meta-interface';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { I360Media } from "../../core-constants/bot";
import { TIdProperty } from '../../core-constants/types';
import { CreateRangeOfNumbers } from '../../tools/utility';
import { $number, Differences, SimpleDifferences } from '../../tools/utility-types';
import { Define } from "../../tools/utility/types/entities/define";
import { $$ } from '../../tools/utility/types/error';
import { EMetadataNames } from '../metadata/metadata-db';
import { IGeneralMetadataCatalogConfigField } from '../metadata/metadata-interface';
import { IdDep } from "../non-serializable-id/non-serializable-types";


export enum ECatalogType {
    Meta = 'meta',
}

export type TCatalogField = 
    | EMetadataNames.ProductId
    | EMetadataNames.ProductName
    | EMetadataNames.ProductCategory
    | EMetadataNames.ProductCurrency
    | EMetadataNames.ProductImageURL
    | EMetadataNames.ProductPrice
    | EMetadataNames.ProductDescription
    | EMetadataNames.ProductURL
    | EMetadataNames.ProductGTIN
;

export interface IBasicCatalogFieldItem {
    idProperty: TIdProperty;
}

export type TCalogItemPreview = Meta.Catalog.Product// { [key in IGeneralMetadataCatalogConfigField['key']]: string }

export interface IMetaCatalogFieldItem extends IBasicCatalogFieldItem {
}

export interface IBasicCatalogConfig {
}

type ScheduleHour = $number | 0 | CreateRangeOfNumbers<23>;

export interface ICatalogProvider {
    /**
     * External catalog id
     */
    idCatalog?: string;
    /**
     * External catalog name
     */
    catalogName?: string;

    idProvider: string;

    isConnectedOnWABA?: boolean;
}

export interface ICatalogMetaProviderInfo extends IBasicCatalogConfig {
    /**
     * Schedule
     */
    scheduleHour: ScheduleHour;
    type?: Meta.Catalog.Vertical;
}

export type TCatalogProviders = {
    [idProvider in IdDep<ENonSerializableObjectType.socialMediaConnection>]: ICatalogProvider
};


export type TCatalogProvidersInfo = Define<{ [key in ECatalogType]: IBasicCatalogConfig }, {
    [ECatalogType.Meta]: ICatalogMetaProviderInfo;
}>;


export interface ICatalogDownloadFileInput {
    mode?: ECatalogExternalFileViewMode;
    idCatalog: string;
}

export interface INSCatalogClient extends INonSerializableHeader {
    idNS?: IdDep<ENonSerializableObjectType.catalog>;
    idDatabase: IdDep<ENonSerializableObjectType.fileMetadata>;
    providersInfo: TCatalogProvidersInfo;
    providers: TCatalogProviders;
    title: string;
    description: string;
}

export interface INSCatalogServer extends INonSerializable, INSCatalogClient {
    idNS: IdDep<ENonSerializableObjectType.catalog>;
}

// 


export enum ECatalogExternalFileViewMode {
    View = 'view',
    Download = 'download',
}