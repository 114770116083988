import { Component, Input, OnInit } from '@angular/core';
import { IFormSchema, TFormSchemmaArray } from '@colmeia/core/src/general-form/general-form-interface';
import { NonSerializableFactory } from '@colmeia/core/src/nser/non-serializable.factory';
import { EBPMAction } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { emptyMetaEngagement } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { ICRMAgentEventServer, ICRMRootServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { EMetadataEngagementType, ESourceOfInfo, IMetadataRegister, TIMetadataServiceRegisterArray } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from 'app/handlers/generic-dashboard-edit.handler';
import { IMetaEngagementHandlerParameters, MetaEngagementHandler } from 'app/handlers/meta-engagement.handler';
import { ENsMoreOptions } from 'app/handlers/ns-more-options.handler';
import { CRMUserEventBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { BpmCrmApiService } from 'app/services/bpm/api/bpm-crm-api.service';
import { BpmService } from 'app/services/bpm/bpm.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { NewConditionEditorHandler, NewConditionEditorParameter } from '../../new-condition-editor/new-condition-editor.component';
import { ICRMGraphBasicData } from '../crm-graph-basic-data/crm-graph-basic-data.component';
import { NSPickerHandler } from './../../../../handlers/ns-picker/ns-picker.handler';
import { GeneralFormService } from './../../../../services/general-form.service';

@Component({
    selector: 'app-crm-graph-create-user-event',
    templateUrl: './crm-graph-create-user-event.component.html',
    styleUrls: ['./crm-graph-create-user-event.component.scss']
})
export class CrmGraphCreateUserEventElementComponent implements OnInit {
    @Input() handler: CRMUserEventBpmGraphCreateHandler;

    public userEvent: ICRMAgentEventServer;
    private forms: Map<string, IFormSchema> = new Map();
    public genericEdit: GenericDashboardEditHandler;
    public conditionHandler: NewConditionEditorHandler;
    public addMetadataNSPicker: NSPickerHandler;
    public engagementHandlersHash: {
        [idSchemma: string]: MetaEngagementHandler
    } = {};
    public isCreating: boolean;
    public basicCRMHandler: ICRMGraphBasicData;

    private metadata?: SchemaPropertyServer;

    constructor(
        private dashboardSvc: DashBoardService,
        private formSvc: GeneralFormService,
        private bpmCrmApiService: BpmCrmApiService,
        private bpmSvc: BpmService,
    ) { }

    ngOnInit(): void {
        const userEvent = this.handler.getUserEvent();
        this.isCreating = !isValidRef(userEvent)

        this.userEvent = this.isCreating
            ? <ICRMAgentEventServer>NonSerializableFactory.create(ENonSerializableObjectType.crmServiceConfigAgentEvent,
                { name: '', idParent: this.handler.getRootCrmHostedId() })
            : this.handler.getUserEvent();

        this.initBasicCRMHandler();
        this.initGenericHomeEdit();
        this.loadSchemas();
        this.initAddMetadataNSPicker();
        this.initEngagmentHandlers();
        this.initConditionsHandler();

    }

    initBasicCRMHandler() {
        this.basicCRMHandler = {
            element: <ICRMAgentEventServer>this.userEvent,
            saveNSCallback: (_botItem: INonSerializable) => { },
            configureAction: true,
        }
    }

    private initGenericHomeEdit() {
        this.genericEdit = new GenericDashboardEditHandler({
            nsType: ENonSerializableObjectType.crmServiceConfigAgentEvent,
            autoValidate: true,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: this.onSaveElement,
                onGenericBackButtonPressed: () => {
                    this.handler.getDialogRef().close();
                }
            },
            nser: this.userEvent,
            allowEditTitleInHeader: true,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
            }
        });
    }

    public getForms(): TIMetadataServiceRegisterArray {
        return this.userEvent.agentAvailableForms.map(i => i.form);
    }

    private onSaveElement = async () => {
        const processor = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor();
        const idParentGraphNode = processor.findNodeElementByHostedId(this.handler.getRootCrmHostedId() || this.userEvent.idParent)?.getGraphElementID();

        const [result] = await this.bpmCrmApiService.save(this.userEvent, idParentGraphNode)

        if (!result) return;

        this.handler.closeDialogAndSendResultToParentComponent({ userHasClickedSave: true, nonSerializable: result });
    }

    private initAddMetadataNSPicker() {
        this.addMetadataNSPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            title: "Selecione um metadado para adicionar",
            nsType: ENonSerializableObjectType.formSchemma,
            useDemandedTag: false,
            maxSelections: 1,
            selectedIds: [],
            clientCallback: {
                onSaveCallback: ([ns]) => {
                    if (!ns) return;

                    this.addMetadata(ns);
                    this.initAddMetadataNSPicker();
                }
            }
        }, {
            filter: (ns) => !this.userEvent.agentAvailableForms.some(i => i.form.idMetadata === ns.idNS)
        });
    }

    private addMetadata(metadata: SchemaPropertyServer) {
        const entry: IMetadataRegister = {
            idMetadata: metadata.schemma.idSchemma,
            engagement: emptyMetaEngagement()
        };

        this.generateEngagementHandler(entry);
        this.forms.set(metadata.schemma.idSchemma, metadata.schemma);

        this.userEvent.agentAvailableForms.push({
            required: true,
            form: entry
        });
    }

    private generateEngagementHandler(entry: IMetadataRegister): void {
        const parameter: IMetaEngagementHandlerParameters = {
            context: undefined,
            title: '',
            idSchema: entry.idMetadata,
            includeCanonical: false,
            metaEngagement: entry.engagement,
            clientCallback: this,
            allowedEngagementTypes: [
                EMetadataEngagementType.smartMapper,
                EMetadataEngagementType.NewCondition,
                EMetadataEngagementType.richFillForm
            ],
            allowedEngagementConfigTypes: [],
            data: {},
            nsVariables: [],
        };

        this.engagementHandlersHash[entry.idMetadata] = new MetaEngagementHandler(parameter);
    }


    private async loadSchemas() {
        const forms: TFormSchemmaArray = await this.formSvc.getSchemas(undefined);

        for (const form of forms) {
            this.forms.set(form.idSchemma, form);
        }
    }

    private initEngagmentHandlers() {
        for (const entry of this.userEvent.agentAvailableForms) {
            this.generateEngagementHandler(entry.form);
        }
    }

    public initConditionsHandler() {
        const parameter: NewConditionEditorParameter = {
            field: undefined,
            fields: this.metadata?.schemma.form || [],
            clientCallback: undefined,
            allowedActionTypes: {
                [EBPMAction.action]: {
                    allowedActions: [EBotActionType.upsertTicket]
                },
            },
            currentConditions: this.userEvent.condFormsAction,
            allowedSourceInfo: [ESourceOfInfo.property, ESourceOfInfo.canonical],
            actionHandlerOverride: (action) => {
                const root: ICRMRootServer = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor().getRootElement().getHostedObject();

                action.idElement = root.idConfigurationPackage;
                action.type = EBotActionType.upsertTicket;
                return { showContentArray: false, disableElementNsPicker: true }
            }
        }

        this.conditionHandler = new NewConditionEditorHandler(parameter);
    }

    public nameForMetadata(idMetadata: string): string {
        return this.forms.get(idMetadata)?.name || '...';
    }

    public removeMetadata(idMetadata: string) {
        const idx = this.userEvent.agentAvailableForms.findIndex(entry => entry.form.idMetadata === idMetadata);
        this.userEvent.agentAvailableForms.splice(idx, 1);
    }
}
