import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-attendance-active-method-template',
    templateUrl: './attendance-active-method-template.component.html',
    styleUrls: ['./attendance-active-method-template.component.css']
})
export class AttendanceActiveMethodTemplateComponent implements OnInit {

    @Input() selectedCampaign

    constructor() { }

    ngOnInit() {
    }

}
