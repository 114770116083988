import { isInvalid, isValidRef, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { EBPMType } from '../../../BPM/bpm-model';
import { TIBotActionAssetContainerArray } from '../../../bot/bot-action-model';
import { isActionAsset } from '../../../bot/bot-function-model-helper';
import { ENextGenBotElementType } from '../../../bot/bot-model';
import { EBotActionType } from '../../../bot/new-bot-action';
import { GraphElement } from '../../../graph/essential/graph-element';
import { TIGraphConnectionDataArray } from '../../../graph/essential/graph-types';
import { INLPTransactionServer } from '../../../knowledge-base/bot-transaction/bot-transaction';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { BPMConfigToolbarSelector } from '../../toolbar/bpm-config-toolbar-selector';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { NSGenericHost } from '../ns/ns.host';
import { INodeDeletedConnection } from '../ns/ns.host.types';
import { NestedAItHostNode } from './nested-ai-node.host';

export const ToolbarElementMapToBotAction = {
    [EBotActionType.goActionTree]: EBotActionType.goActionTree,
    [EBotActionType.contentGenerator]: EBotActionType.contentGenerator,
    [EBotActionType.goHuman]: EBotActionType.goHuman,
    [ENextGenBotElementType.nlpTransaction]: EBotActionType.contentGenerator,
};

export const ToolbarElementMapToRenderedElement = {
    [EBotActionType.goActionTree]: ENextGenBotElementType.menuContainer,
    [EBotActionType.contentGenerator]: ENextGenBotElementType.formTransaction,
    [ENextGenBotElementType.nlpTransaction]: ENextGenBotElementType.nlpTransaction,
};

export const RenderedElementMapToBotAction = {
    [ENextGenBotElementType.menuContainer]: EBotActionType.goActionTree,
    [ENextGenBotElementType.formTransaction]: EBotActionType.contentGenerator,
    [ENextGenBotElementType.nlpTransaction]: EBotActionType.contentGenerator,
};

export class NestedAIRootHost extends NestedAItHostNode {
    constructor(nestedAIRoot: INLPTransactionServer) {
        super(nestedAIRoot)
    }

    static create(nestedAIRoot: INLPTransactionServer): NestedAIRootHost {
        return new NestedAIRootHost(nestedAIRoot)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        const isConnectable = BPMConfigToolbarSelector.isValidConnectionInBPM(EBPMType.nestedAI, this.getHostedType(), ToolbarElementMapToRenderedElement[nodeTypeToDrag]);
        const canDragOn = BPMConfigToolbarSelector.canDragOn(nodeTypeToDrag, this.getHostedType(), EBPMType.nestedAI);

        return isConnectable || canDragOn;
    }

    public getHostedType(): TReferencedObject {
        return ENextGenBotElementType.nlpTransaction;
    }

    public isRoot(): boolean {
        return true;
    }

    public allowSetInnerHTML(): boolean {
        return false;
    }

    public getActionContainer(): TIBotActionAssetContainerArray {
        const hostObject = this.getHostedObject() as INLPTransactionServer;
        const actionContainer: TIBotActionAssetContainerArray = []
        Object.entries(hostObject.nlp.assets).forEach(([idIntent, intentAssets]) => {
            const actionAsset = intentAssets?.find(
                (intentAsset) => intentAsset.type === EBotActionType.goActionTree ||
                    intentAsset.type === EBotActionType.contentGenerator
            );
            if (isInvalid(actionAsset)) return;
            actionContainer.push(
                {
                    action: actionAsset,
                    subElementId: idIntent
                }
            );
        });
        return actionContainer;
    }

    public getConnectionTargetData(isExternal: boolean = false): TIGraphConnectionDataArray {
        if (isExternal) return [{
            targetHostId: undefined,
            connectionType: undefined,
            subElementId: undefined
        }]

        const actions = this.getActionContainer();
        let targetsData = actions.map(container => ({
            connectionType: container.action.type,
            targetHostId: container.action.idElement,
            subElementId: container.subElementId
        }));

        return targetsData;
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        switch (connectionType) {
            case EBotActionType.goActionTree:
            case EBotActionType.contentGenerator:
            case undefined:
                return true;
            default: return false;
        };
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject) {
        return target === ENextGenBotElementType.menuContainer ||
            target === ENextGenBotElementType.formTransaction ||
            target === ENextGenBotElementType.nlpTransaction;
    }

    public isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        switch (targetType) {
            case ENextGenBotElementType.menuContainer:
                return action === EBotActionType.goActionTree;
            case ENonSerializableObjectType.contentGenerator:
            case ENextGenBotElementType.formTransaction:
            case ENextGenBotElementType.nlpTransaction:
                return action === EBotActionType.contentGenerator;
            default:
                return false;
        }
    }

    public thereIsMoreChildrenNodesToCreate(isExternal?: boolean): boolean {
        const connections = this.getConnectionTargetData(isExternal);
        return connections.some(con => con.connectionType == EBotActionType.contentGenerator && isValidString(con.targetHostId));
    }

    public isEditable(graphElement: GraphElement) {
        return !graphElement.isExternalElementOnDiagram();
    }

    public deleteConnectionTo(anotherHost: NSGenericHost, connectionType: TReferencedObject, subElementId: string): INodeDeletedConnection {
        const allActionContainers = this.getActionContainer()
        const actionContainer = allActionContainers.find(actionContainer => actionContainer.subElementId === subElementId)

        if (isValidRef(actionContainer)) {
            const nlpTransaction = this.getHostedObject() as INLPTransactionServer;
            const oldAsset = nlpTransaction.nlp.assets[subElementId].find(asset => isActionAsset(asset.type))
            if (isValidRef(oldAsset)) {
                const assets = nlpTransaction.nlp.assets[subElementId].filter(asset => asset.idAsset !== oldAsset.idAsset)
                const events = nlpTransaction.events.filter(asset => asset.idAsset !== oldAsset.idAsset)
                nlpTransaction.nlp.assets[subElementId] = assets;
                nlpTransaction.events = events;
            }
            return { deletedConnection: true }
        }
        return { deletedConnection: false }
    }
}
