<div *ngIf="canShowLoginOptions" class="category__content">
  <ul>
    <li *ngFor="let social of allBinds">
      <div class="account__item">
        <div class="account--img">
          <mat-icon
            size="14"
            [svgIcon]="social.icon.svgIcon"
            [ngStyle]="{color: social.icon.color}"></mat-icon>
        </div>
        <div class="account--name">
          {{ social.name }}
        </div>
        <div class="account--toggle">
          <mat-slide-toggle
            class="example-margin"
            [color]="'primary'"
            (change)="onSocialBindSwitchChanged(social.social)"
            [checked]="isActiveSocialBind(social.social)">
          </mat-slide-toggle>
        </div>
      </div>
    </li>
  </ul>
</div>
