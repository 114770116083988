import { UnsafeOmit } from "../../utility";
import { $ValueOf, CamelCase, Compute, SpecificOverload } from "../../utility-types";
import { ValueOf } from "../types";

import * as _ from 'lodash';
const lodash = require('lodash');

export function cloneDeep<T>(source: T): T {
    return lodash.cloneDeep(source);
}

export function groupBy<T, Property extends (number | string | keyof T)>(items: T[], it: (item: T) => Property | Property): { [key in Property]: T[] } {
    return lodash.groupBy(items, it)
}


export function mapKeys<T extends object, Item extends $ValueOf<T>, Property extends (number | string | keyof T)>(items: T, it: (item: Item, index: keyof T) => Property): { [key in Property]: Item } {
    return lodash.mapKeys(items, it)
}

export function pickBy<T extends {}, Item extends $ValueOf<T>, Property extends (number | string | keyof T)>(items: T, it: (item: Item, index: keyof T) => boolean): Partial<T> {
    return lodash.pickBy(items, it)
}

export function mapValues<Return extends { [key in Keys]: ItReturn }, Source extends { [key in Keys]: Value }, Value extends $ValueOf<Source>, Keys extends keyof Source, ItReturn>(values: Source, iterator: ((item: Value, index: keyof Source, source: Source) => ItReturn)): Return;
export function mapValues<Value extends $ValueOf<Source>, Source extends { [key in Keys]: Value }, Keys extends keyof Source, Iterator extends ((item: Value, index: keyof Source, source: Source) => any)>(values: Source, iterator: Iterator): { [key in Keys]: ReturnType<Iterator> }
export function mapValues<Value extends $ValueOf<Source>, Source extends { [key in Keys]: Value }, Keys extends keyof Source, Iterator extends ((item: Value, index: keyof Source, source: Source) => any)>(values: Source, iterator: Iterator): { [key in Keys]: ReturnType<Iterator> } {
    return lodash.mapValues(values, iterator);
}


export function difference<T>(array1: T[], array2: T[]): T[] {
    return lodash.difference(array1, array2);
}

export function intersection<T>(array1: T[], array2: T[]): T[] {
    return lodash.intersection(array1, array2);
}
// 

export function upperFirst<T extends string>(text: T): Capitalize<T> {
    return lodash.upperFirst(text);
}


export function isEqual<T>(source: T, otherSource: T): boolean {
    return lodash.isEqual(source, otherSource);
}


export function omit<Entity extends object, Key extends (keyof Entity | (keyof Entity)[])>(entity: Entity, key: Key): Compute<UnsafeOmit<Entity, Key extends unknown[] ? Key[number] : Key>> {
    return lodash.omit(entity, key);
}


export function chunk<T>(items: T[], limit: number): T[][] {
    return lodash.chunk(items, limit);
}
export function find<T extends object>(items: T[], predicate: Partial<T>): T {
    return lodash.find(items, predicate);
}


export function sortBy<T>(items: T[], fields: (keyof T)[]): T[] {
    return lodash.sortBy(items, fields);
}


export function lodashDebounce<T extends any[], U>(fn: (...params: T) => U, time?: number): (...params: T) => U {
    return lodash.debounce(fn, time)
}

export function snakeCase(source: string): string {
    return lodash.snakeCase(source);
}

export function toSnakeCase(source: any): any {
    const dest: any = {};
    Object.keys(source).forEach(key => {
        if (source[key] && typeof source[key] === 'object') {
            dest[snakeCase(key)] = toSnakeCase(source[key]);
        } else {
            dest[snakeCase(key)] = source[key];
        }
    });
    return dest;
};

export function camelCase<T extends string & SpecificOverload<T>>(source: T): CamelCase<T>;
export function camelCase<T extends string>(source: T): string;
export function camelCase(source: string): string {
    return lodash.camelCase(source);
}

export function toCamelCase(source: any): any {
    const dest: any = {};
    Object.keys(source).forEach(key => {
        if (source[key] && typeof source[key] === 'object') {
            dest[camelCase(key)] = toCamelCase(source[key]);
        } else {
            dest[camelCase(key)] = source[key];
        }
    });
    return dest;
}

export function uniqBy<T>(array: List<T> | null | undefined, iteratee: ValueIteratee<T>): T[];
export function uniqBy(...params: unknown[]): unknown {
    return lodash.uniqBy(...params);
}


export function uniqWith<T>(items: T[], comparator: (a: T, b: T) => boolean): T[] {
    return lodash.uniqWith(items, comparator);
}
// 


type List<T> = ArrayLike<T>;

type PartialShallow<T> = {
    [P in keyof T]?: T[P] extends object ? object : T[P]
};
type IterateeShorthand<T> = PropertyKey | [PropertyKey, any] | PartialShallow<T>;

type ValueIteratee<T> = ((value: T) => unknown) | IterateeShorthand<T>;

export function pascalCase(text: string) {
    return upperFirst(camelCase(text))
}

export function mergeLodash<T>(...items: T[]): T {
    return lodash.merge(...items);
}

export function kebabCase(text: string) {
    return lodash.kebabCase(text);
}
