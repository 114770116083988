import {Injectable, NgZone} from "@angular/core";
import {NavigatorServices} from "./controllers-services/navigator/navigator.service";
import {SessionService} from "./session.service";
import {PlayerInfoService} from "./player-info.service";
import {GlobalWarningService, IInteractiveButton, EInteractiveButton} from "./global-warning.service";
import {GroupPersistorServices} from "./group-persistor.services";
import {GroupChatServices} from "./group-chat.services";
import {RecoverySystemService} from "./recovery-system.service";
import {hwConfig} from "./hardware";
import {clientConstants} from "../model/constants/client.constants";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {routeID} from "../model/routes/route-constants";
import {Serializable} from "@colmeia/core/src/business/serializable";
import {PlayerCachedInfo} from "@colmeia/core/src/business/player-cached";
import {MessageHandlerCallbak} from "../handlers/message-instance-handler/message-handler-callback";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";

@Injectable({
    providedIn: 'root'
})
export class RouterManagerService  {

    constructor(
        private navSvc: NavigatorServices,
        private sessionSvc: SessionService,
        private playerSvc: PlayerInfoService,
        private warningScreenSvc: GlobalWarningService,
        private groupController: GroupPersistorServices,
        private chatServices: GroupChatServices,
        private recovery: RecoverySystemService,
        private _zone: NgZone,
    ) {
        if(hwConfig.cordova in window) {
            // Cordova Hotfixes: (see index.cordova.html)
            window[clientConstants.global.fixes.router.zone] = this._zone;
            window[clientConstants.global.fixes.router.router] = this;
        }
    }

    /**
     * This function navigates anywhere in the system
     *
     * @param  {ExecutableItem} navigatable can be a group, avatar, interaction
     * @returns Promise<boolean> indicates success or error
     */
    async goToGroup(groupId: TGlobalUID, pageId: string = routeID.groups.home): Promise<boolean> {
        let accessOk: boolean = false;
        const playerCached: PlayerCachedInfo = this.playerSvc.getPlayerInfo();
        const hasCurrentAccess: boolean = playerCached.avatarBelongsToGroup(groupId, this.sessionSvc.getAvatarID());
        // has participant, so navigate to group
        if (hasCurrentAccess || this.sessionSvc.hasParticipantOnGenealogy(groupId)) {
            this.navSvc.navigateToGroupID(groupId, pageId);
            return true;
        };

        // otherwise check all other possibilities
        if (this.sessionSvc.isGroupAccessWithAnotherAvatar(groupId)) {

            const interactiveButtons : IInteractiveButton[] = [
                <IInteractiveButton>{
                    option: EInteractiveButton.No,
                },
                <IInteractiveButton>{
                    option: EInteractiveButton.Yes,
                    color: 'primary'
                }
            ]
            let message: string = Serializable.getTranslation(gTranslations.fragments.participationWithAnotherAvatar)
            const confirmPromisse = this.warningScreenSvc.showInteractivePrompt({ title: gTranslations.fragments.accessDenied, message, options: interactiveButtons })
            await confirmPromisse
            accessOk = false;

        } else if (this.sessionSvc.isOnlyForInvitees(groupId)) {

            const interactiveButtons : IInteractiveButton[] = [
                <IInteractiveButton>{
                    option: EInteractiveButton.Yes,
                    color: 'primary'
                }
            ]
            let message: string = Serializable.getTranslation(gTranslations.fragments.mustBeAParticipantToExecute)
            const confirmPromisse = this.warningScreenSvc.showInteractivePrompt({ title: gTranslations.fragments.accessDenied, message, options: interactiveButtons })
            await confirmPromisse
            accessOk = false;

        }
        return accessOk;
    }

    public async goToInteractionID(groupID: TGlobalUID, interactionID: TGlobalUID): Promise<void> {
        try {
            MessageHandlerCallbak.setScrollToMe(interactionID);
            await this.goToGroup(groupID, routeID.groups.chat);
            const controller: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
                MessageHandlerCallbak.composeKey(groupID, interactionID)
            );
            if (! controller) {
                await this.recovery.recoverTopic(groupID, interactionID);
            }

        } catch (e) {

        }
    }

    private async handleNeedToAskToParticipate(accessOk: boolean, clickedBtn: Serializable, groupId: TGlobalUID): Promise<boolean> {
        /*@TODO warning*/
        // const yesBtn = Serializable.staticFactory(EButtonWarningOptions.Yes);
        // if (yesBtn.iss(clickedBtn)) {
        //     const solicitation: GroupParticipationRequest = GroupParticipationRequest.getNewParticipationRequest
        //         (this.sessionSvc.getCurrentPersonalParticipant(), groupId);
        //
        //     const friendly: FriendlyMessage = await this.chatServices.saveInteraction(solicitation, null, null);
        //     if (friendly.hasSpecificReturnMessage(friendlyAction.subscription.goToGroup)) {
        //        await  this.navSvc.navigateToGroupID(groupId, routeID.groups.chat);
        //         // handle what should be done with the button
        //     };
        // };
        return accessOk
    }

}
