import { OnDestroy } from '@angular/core';
import { Directive, ElementRef, Input, HostListener, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { IPoint, moveEventsOf } from 'app/model/client-utility';
import { Subscription } from 'rxjs';

export interface DraggableDirectiveEvent {
    event: MouseEvent | TouchEvent,
}

export interface DraggableDirectiveMoveEvent {
    event: MouseEvent | TouchEvent,
    pointerTranslation: IPoint
}

@Directive({
    selector: '[draggable]'
})
export class DraggableDirective implements AfterViewInit, OnDestroy {

    @Output() dragStart: EventEmitter<DraggableDirectiveEvent> = new EventEmitter();
    @Output() dragMove: EventEmitter<DraggableDirectiveMoveEvent> = new EventEmitter();
    @Output() dragEnd: EventEmitter<DraggableDirectiveEvent> = new EventEmitter();

    private startSubscription: Subscription;

    constructor(private el: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        this.startSubscription = moveEventsOf(this.el.nativeElement, {
            start: (event) => this.dragStart.emit({ event }),
            move: (event, pointerTranslation) => this.dragMove.emit({ event, pointerTranslation }),
            end: (event) => this.dragEnd.emit({ event })
        });
    }

    ngOnDestroy() {
        this.startSubscription.unsubscribe();
    }
}
