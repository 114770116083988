import { Component, Input } from '@angular/core';
import { IBotRootServer } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { ITaggable } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { BasicElementInterfaceFactory } from '@colmeia/core/src/shared-business-rules/graph/essential/basic-element-interface.factory';
import { IBasicElementClient, IBasicElementServer, TIBasicElementServerArray } from '@colmeia/core/src/shared-business-rules/graph/essential/graph-basic-element-interfaces';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { EIconButtonStyles, iconButtonStyles } from 'app/components/dashboard/icon-button/icon-button.component';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { ITaggableHandlerClientCallback, TaggableHandler } from 'app/handlers/taggable.handler';
import { BpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { BpmService } from 'app/services/bpm/bpm.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { NSValidationService } from 'app/services/nser-validation.service';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { EAppAlertTypes, SnackMessageService } from 'app/services/snack-bar/snackbar-message.service';
import { BpmApiService } from '../../../../../services/bpm/api/bpm-bot-api.service';
import { BpmGraphViewerService } from '../bpm-graph-viewer/services/bpm-graph-viewer.service';



@Component({
    selector: 'app-bpm-graph-create-root',
    templateUrl: './bpm-graph-create-root.component.html',
    styleUrls: ['./bpm-graph-create-root.component.scss']
})
export class BpmGraphCreateRootElementComponent {
    @Input()
    public _handler: BpmGraphCreateHandler;
    private rootElementSaved: IBasicElementServer
    private graphRootJSON: IBasicElementClient
    public get processName(): string { return this.graphRootJSON.nName; }
    public set processName(value: string) { this.graphRootJSON.nName = value }
    public get handler(): BpmGraphCreateHandler { return this._handler }
    public set handler(value: BpmGraphCreateHandler) { this._handler = value; this.init(); }
    public get parameters() { return this.handler.getComponentParameter() }
    public botPickerHandler: NSPickerHandler;
    public taggableHandler: TaggableHandler;
    private hasSaved = false;

    public constructor(
        private dashboardSvc: DashBoardService,
        private api: ServerCommunicationService,
        private snackSvc: SnackMessageService,
        private validator: NSValidationService,
        private bpmApiService: BpmApiService,
        private routingSvc: RoutingService,
        private bpmSvc: BpmService,
        private bpmViewerSvc: BpmGraphViewerService
    ) { }

    public get iconButtonCreateStyle(): Partial<CSSStyleDeclaration> {
        return iconButtonStyles[EIconButtonStyles.Create]
    }

    ngOnInit() { }

    async init(): Promise<void> {
        this.initRootElementGraph();
        this.initBotRootDialog();
        this.generateTagPickerHandler();
    }

    getBpmType(): EBPMType {
        return this.handler.getComponentParameter().bpmType
            ? this.handler.getComponentParameter().bpmType
            : this.bpmSvc.getType()
    }

    public initRootElementGraph(): void {
        this.graphRootJSON = BasicElementInterfaceFactory.createEmptyRoot()
    }

    public initBotRootDialog(): void {

    }


    public getTaggableSearch(): ITagableSearch {
        return {
            searchedTags: [],
            demandedTag: this.dashboardSvc.defaultTag
        }
    }


    public onSelectBot(botRootSelected: IBotRootServer): void {
        this.graphRootJSON.element.idHostedObject = botRootSelected?.idNS;
    }

    public get showCreateButton(): boolean {
        return isValidRef(this.graphRootJSON.element.idHostedObject);
    }

    public validateElement(): boolean {
        const error: string = this.validator.promptAndGetFirstErrorFrom(this.graphRootJSON, ENonSerializableObjectType.graphElement);
        return !error;
    }

    private generateTagPickerHandler(): void {
        this.taggableHandler = new TaggableHandler({
            clientCallback: <ITaggableHandlerClientCallback>this,
            taggable: this.graphRootJSON,
        })
    }

    public onCloseModal(): void {
        this.handler.getDialogRef().close({ userHasClickedSave: this.hasSaved, nonSerializable: this.rootElementSaved });
    }

    public onChangeTaggableCallback = (taggable: ITaggable): void => { }
    public onFinishSelectionCallback = () => { }

    public async onSaveButtonClick(): Promise<void> {
        if (!this.validateElement()) return;

        const elements: TIBasicElementServerArray = await this.bpmApiService.persistGraphElementsByJSON(
            this.getBpmType(),
            [this.graphRootJSON]
        );

        this.rootElementSaved = elements[0]
        this.bpmViewerSvc.storeBpmGraphRoot(this.rootElementSaved)
        console.log("🚀 ~ file: bpm-graph-create-root.component.ts ~ line 160 ~ BpmGraphCreateRootElementComponent ~ onSaveButtonClick ~ this.rootElementSaved", this.rootElementSaved)

        if (isValidArray(elements)) {
            this.hasSaved = true;
            this.snackSvc.openDefaultMessage(EAppAlertTypes.Success)('Salvo com sucesso');
            this.routingSvc.goToRootGraphElement(
                this.rootElementSaved.idNS,
                this.getBpmType(),
                this.parameters.hasImport,
                this.rootElementSaved.element.idHostedObject
            );
            this.onCloseModal();
        } else {
            this.hasSaved = false;
            this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)('Ocorreu um erro ao salvar')
        };
    }

}
