import { GraphElement, IGraphElementCreationInfo } from './graph-element';
import { IGraphElementJSON, IGraphElementRootJSON } from './graph-interfaces';
import { GraphRulesProcessor } from './graph-rules-processor';
import { EGraphElementType } from './graph-types';
import { IHostObjectInterface } from './host-object';


export interface IGraphRootElementCreationInfo extends IGraphElementCreationInfo {
}

export class GraphRoot extends GraphElement {
    public constructor(
        json: IGraphElementJSON,
        ruleProcessor: GraphRulesProcessor,
        hostObject: IHostObjectInterface,
        name: string,
    ) {
        super(json, ruleProcessor, hostObject, name);
    };

    public toJSON(): IGraphElementRootJSON {
        return {
            ...super.toJSON(),
            elementType: EGraphElementType.root,
            rules: this.getRuleProcessor() ? this.getRuleProcessor().toJSON() : null,
        }
    }

    public static create(info: IGraphRootElementCreationInfo): GraphElement {
        return new GraphRoot({
            ...info.graphJSON, 
            elementType: EGraphElementType.root,
        }, 
        info.ruleProcessor, 
        info.hostObject, 
        info.name)
    }
};
