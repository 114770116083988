import { ChangeDetectorRef, EmbeddedViewRef, Pipe, PipeTransform, Type } from '@angular/core';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { Translation } from "app/components/foundation/translation/translation-helper";

@Pipe({
    name: 'translate'
})
export class TranslatePipe implements PipeTransform {
    private component: RootComponent<any>;

    constructor(cdRef: ChangeDetectorRef) {
        this.component = (cdRef as EmbeddedViewRef<Type<any>>).context as unknown as RootComponent<any>;
    }

    transform(translation: Translation | string, data: {}): string {
        const value = translation instanceof Translation
            ? translation.value
            : this.component.translations[translation]?.value

        if (!isValidRef(value))
            return '';

        return value.replace(/\{\{ *(.*?) *\}\}/, (match, p1) => {
            return data[p1];
        });
    }
}
