<div class="dependencies-container">
  <div class="child-dependencies-toggle">
    <mat-slide-toggle [(ngModel)]="childDependencies" (change)="onChildChange()">
      Incluir filhos
    </mat-slide-toggle>
  </div>
  <mat-tab-group mat-align-tabs="center" (selectedIndexChange)="onTabIndexChange($event)" [(selectedIndex)]="selectedIndex">
    <mat-tab label="Dependem deste objeto">
      <ng-container [ngTemplateOutlet]="dependenciesList"></ng-container>
    </mat-tab>
    <mat-tab label="Este objeto depende de">
      <ng-container [ngTemplateOutlet]="dependenciesList"></ng-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #dependenciesList>
  <div class="table-container">

    <app-generic-table *ngIf="genericTableHandler" [handler]="genericTableHandler" ></app-generic-table>
    
  </div>
</ng-template>
