import {Participant} from '../../business/participant';
import {constant, TGlobalUID} from '../../business/constant';
import {Interaction} from '../interaction'
import {IStartPublishGroupJSON, IStopPublishGroupJSON} from '../../comm-interfaces/interaction-interfaces';
import {InteractionType} from '../interaction-type';

export class PublishingInteractionOnGroup extends Interaction {
    private idGroupToMerge: TGlobalUID;

    private constructor(primaryID: TGlobalUID, participant: Participant, idGroupToMerge: TGlobalUID) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.sharing.startPublishGroup));
        this.idGroupToMerge = idGroupToMerge;
    };

    public getPublishingGroupID(): TGlobalUID {return this.idGroupToMerge};
    public setPublishingGroupID(idGroupToMerge: TGlobalUID): void {this.idGroupToMerge = idGroupToMerge;}

    public toJSON(): IStartPublishGroupJSON {
        let json: IStartPublishGroupJSON = <IStartPublishGroupJSON>super.toJSON();
        json.toGroup = this.idGroupToMerge;
        return json;
    };

    public static staticFactory(primaryID: TGlobalUID): PublishingInteractionOnGroup {
        return <PublishingInteractionOnGroup>Interaction.staticFactory(primaryID);
    }

    public rehydrate(json: IStartPublishGroupJSON): void {
        super.rehydrate(json);
        this.idGroupToMerge = json.toGroup;
    };

    public static getNewPublishingInteractionOnGroup(participant: Participant, idGroupToMerge: TGlobalUID): PublishingInteractionOnGroup {
        return new PublishingInteractionOnGroup(null, participant, idGroupToMerge);
    }


    public static factoryMessage(json: IStartPublishGroupJSON, participant: Participant = null): PublishingInteractionOnGroup {
        let start: PublishingInteractionOnGroup = 
               <PublishingInteractionOnGroup>Interaction.searchCachedInteraction(json.primaryID);
        if (start == null)
            start = new PublishingInteractionOnGroup(json.primaryID, 
                        participant? participant: Participant.staticFactory(json.participant.primaryID), 
                        json.participant.idGroup);
        start.rehydrate(json);
        return start;
    };
};


