export function createGetClassNames() {
    class _Class { }
    const _class: _Class = new _Class();

    const constants = {
        names: {
            static: getNames(_Class),
            instance: getNames(_Class.prototype),
        },
    } as const;

    return <T extends object>(source: T, options: { hasInstanceNames?: boolean, hasStaticNames?: boolean }) => {
        const result: string[] = [];
        // @ts-ignore
        if (!source.prototype) source = source.constructor;

        if (options.hasInstanceNames) result.push(...getInstanceNames(source));
        if (options.hasStaticNames) result.push(...getStaticNames(source));
        return result;

    }
    function getNames<T extends { prototype?}>(source: T, remove: string[] = []) { return Object.getOwnPropertyNames(source).filter(item => !remove.includes(item)) }
    function getInstanceNames<T extends { prototype?}>(source: T): string[] { return getNames((source.prototype), constants.names.instance) }
    function getStaticNames<T extends { prototype?}>(source: T): string[] { return getNames(source, constants.names.static) }
}

export const getClassNames = createGetClassNames();