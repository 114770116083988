import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { constant } from '@colmeia/core/src/business/constant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { nop } from '@colmeia/core/src/tools/utility';
import { IHashSet } from '@colmeia/core/src/tools/utility-types';
import { AnnotationsModalHandler, EAnnotationsType } from 'app/handlers/annotations-modal-handler';
import { IMessageInstanceOptionsConfig } from 'app/model/message-instance-config-db';
import { AnnotationsService } from 'app/services/annotations.service';
import { AttendanceService } from 'app/services/attendance.service';
import { PosProcessingInteractionService } from 'app/services/pos-processing-interaction.service';
import { CmModalHandler } from "../../../../../handlers/cm-modal-handler";
import { HandlerFactoryService } from "../../../../../handlers/handler-factory.service";
import { EditedMsgsViewerComponent } from '../edited-msgs-viewer/edited-msgs-viewer.component';
import { TEditedMsgViewerDescriptorArray } from '../edited-msgs-viewer/edited-msgs-viewer.handler';
import { SharedMsgsViewerComponent } from '../shared-msgs-viewer/shared-msgs-viewer.component';
import { StatusIconsHandler } from './status-icons.handler';


@Component({
    selector: 'app-status-icons',
    templateUrl: './status-icons.component.html',
    styleUrls: ['./status-icons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusIconsComponent implements OnInit {
    @Input() handler: StatusIconsHandler;
    @Input() messageInstanceOptionsConfig: IMessageInstanceOptionsConfig;
    localConstants: IHashSet<string> = {
        dissapearWhen: '',
        onlyYouCanSeeThis: '',
    };
    private editedMsgModalHandler: CmModalHandler;
    private sharedMsgModalHandler: CmModalHandler;

    constructor(
        private cdRef: ChangeDetectorRef,
        private handlerFactorySvc: HandlerFactoryService,
        private annotationsSvc: AnnotationsService,
        private attendanceSvc: AttendanceService,
        private posProcessingInteractionService: PosProcessingInteractionService
    ) { }

    ngOnInit() {
        this.localConstants.onlyYouCanSeeThis = Serializable
            .staticFactory(constant.groupType.personalGroupTypes.privateMessage)
            .getSerializableText(constant.serializableField.auxiliars.aux01)
        this.localConstants.dissapearWhen = Serializable
            .staticFactory(constant.groupType.personalGroupTypes.privateMessage)
            .getSerializableText(constant.serializableField.auxiliars.aux02)
        this.posProcessingInteractionService.interactionReceived$()
            .subscribe(interaction => {
                this.onInteractionReceived(<IInteractionJSON>interaction)
            })
    }

    onInteractionReceived(interaction: IInteractionJSON) {
        const isCurrentInteractionReceivedFromServer = interaction.primaryID == this.handler.getInteraction().getPrimaryID()
        if (isCurrentInteractionReceivedFromServer) {
            this.cdRef.markForCheck()
        }
    }

    logInteraction() {
        console.log({
            msgs: this.handler.getInteraction()
                .getSerializableTextArray()
        });
    }

    //#region EditedMsgs
    getNumberOfEditedMsgs(): number {
        return this.getEditedMsgLabel().length
    }

    getEditedMsgLabel(): TEditedMsgViewerDescriptorArray {
        const allMsg = this.handler.getInteraction()
            .getSerializableTextArray()
            .filter(text => text.idField === constant.serializableField.chat_text)
            .map(text => ({
                date: new Date(text.clockTick),
                message: text.text
            }))
        return allMsg;
    }

    private newMsgsModalHandler(open: boolean = false): CmModalHandler {
        return this.handlerFactorySvc.getCmModalHandler(
            open,
            EditedMsgsViewerComponent,
            this.handlerFactorySvc.newEditedMsgs({
                messages: this.getEditedMsgLabel(),
                clientInstance: this,
            }))
    }

    getEditedMsgsModalHandler(): CmModalHandler {
        if (!this.editedMsgModalHandler) {
            this.editedMsgModalHandler = this.newMsgsModalHandler();
        }
        return this.editedMsgModalHandler;
    }

    editedMsgsWindowClosedCallback(): void {
        this.closeEditedMsgs();
        this.cdRef.markForCheck();
    }

    closeEditedMsgs(): void {
        this.editedMsgModalHandler = this.newMsgsModalHandler();
    }

    openEditedMsgs(): void {
        this.editedMsgModalHandler = this.newMsgsModalHandler(true);
    }
    //#endregion

    //#region SharedMsgs
    private newSharedMsgsModalHandler(open: boolean = false): CmModalHandler {
        return this.handlerFactorySvc.getCmModalHandler(
            open,
            SharedMsgsViewerComponent,
            this.handlerFactorySvc.newSharedMsgsViewer({
                shares: this.handler.getShares(),
                clientInstance: this,
            }))
    }

    getSharedMsgsModalHandler(): CmModalHandler {
        if (!this.sharedMsgModalHandler) {
            this.sharedMsgModalHandler = this.newSharedMsgsModalHandler();
        }
        return this.sharedMsgModalHandler;
    }

    getNumberOfShares(): number {
        return this.handler.getShares().length
    }

    openSharedMsgsModal() {
        this.sharedMsgModalHandler = this.newSharedMsgsModalHandler(true);
    }

    sharedMsgsViewerModalClosedCallback(): void {
        this.sharedMsgModalHandler = this.newSharedMsgsModalHandler();
        this.cdRef.markForCheck();
    }

    getDissapearLbl(): string {
        return this.localConstants.dissapearWhen
    }

    openAnnotations(): void {
        const isAttending = this.attendanceSvc.isAttending();
        const handler = new AnnotationsModalHandler({
            type: EAnnotationsType.onlyAnswersFromInteraction,
            persist: true,
            serializableId: this.handler.getInteraction().getPrimaryID(),
            onSaveAnnotationCallback: nop,
            clientCallback: nop,
            hideCreateTab: isAttending
        })

        this.annotationsSvc.openFor(handler);
    }

    canShowShared(): boolean {
        return this.handler.isShared()
            && this.messageInstanceOptionsConfig.shared;
    }

    canShowEditedMessage(): boolean {
        return this.handler.isEditedMessage()
            && this.messageInstanceOptionsConfig.edited;
    }

    canShowAnnotation(): boolean {
        return this.handler.hasAnnotation()
            && this.messageInstanceOptionsConfig.annotations;
    }

    //#endregion
}
