<div class="socket">
    <div class="gel center-gel">
      <div class="color_dgreen hex-brick h1"></div>
      <div class="color_dgreen hex-brick h2"></div>
      <div class="color_dgreen hex-brick h3"></div>
    </div>
    <div class="gel c1 r1">
      <div class="color_purple hex-brick h1"></div>
      <div class="color_purple hex-brick h2"></div>
      <div class="color_purple hex-brick h3"></div>
    </div>
    <div class="gel c2 r1">
      <div class="color_yellow hex-brick h1"></div>
      <div class="color_yellow hex-brick h2"></div>
      <div class="color_yellow hex-brick h3"></div>
    </div>
    <div class="gel c3 r1">
      <div class="color_red hex-brick h1"></div>
      <div class="color_red hex-brick h2"></div>
      <div class="color_red hex-brick h3"></div>
    </div>
    <div class="gel c4 r1">
      <div class="color_orange hex-brick h1"></div>
      <div class="color_orange hex-brick h2"></div>
      <div class="color_orange hex-brick h3"></div>
    </div>
    <div class="gel c5 r1">
      <div class="color_lgreen hex-brick h1"></div>
      <div class="color_lgreen hex-brick h2"></div>
      <div class="color_lgreen hex-brick h3"></div>
    </div>
    <div class="gel c6 r1">
      <div class="color_lyellow hex-brick h1"></div>
      <div class="color_lyellow hex-brick h2"></div>
      <div class="color_lyellow hex-brick h3"></div>
    </div>
  </div>
