import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const goToApplicationScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ChatOptionsGoToDashboard,
    fields: {
        goToApplication: 1, // Ir para a aplicação
        application: 2, // Aplicação
        goToApplicationIcon: 18, // ./
    }
} as const);

export const goToApplicationTranslations = getTranslationsFields(goToApplicationScreenOptions);

