<div class="integration-top-container">
  <app-non-serializable-taggable-editor *ngIf="taggableHandler" [taggableHandler]="taggableHandler">
  </app-non-serializable-taggable-editor>
  <app-colmeia-window-top-bar *ngIf="hasWindow" [title]="translations.createIntegration"></app-colmeia-window-top-bar>
</div>

<section class="dashboard-page">
  <ng-container *ngIf="isUpsertMode">
    <h5 class="upper-title">{{ getBestTitle() }} {{ translations.databaseContent }}</h5>
    <h2 *ngIf="getDBName()" mat-dialog-title style="font-weight: 500;">{{ getDBName() }}</h2>
  </ng-container>
  <section class="integration">

    <ng-container *ngIf="formPickerHandler">
      <app-ns-picker [handler]="formPickerHandler"></app-ns-picker>
    </ng-container>

    <div class="integration__upload" *ngIf="formFields.length">
      <mat-checkbox *ngIf="isCreate()" class="boxed boxed-14" style="margin-bottom: 8px;" [(ngModel)]="startAsAnEmptyDB">
        Iniciar como um banco vazio
      </mat-checkbox>

      <ng-container *ngIf="!startAsAnEmptyDB else inputNonSerializableName">
        <div class="file-data">
          <button class="upload-button" mat-raised-button (click)="onAttachCsvClicked()">
            {{ getUploadButtonText() }}
            <ng-container *ngIf="selectedFile">
              &nbsp;
              <span class="selected-file-name" [title]="getSelectedFileName()">
                {{ getSelectedFileName() }}
              </span>
            </ng-container>
            <mat-progress-bar *ngIf="isUploading" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
          </button>

          <div [visible-horizontal]="fieldsQuantity" style="display: flex;">
            <app-alert type="info" dense style="margin-left: 18px;" light>
              <div>
                {{ translations.linesCount }}: <strong>{{ fieldsQuantity }}</strong>
              </div>
            </app-alert>

            <app-alert
              *ngIf="mayBeInvalidEncoding"
              [matTooltip]="translations.encodingWarningFull | translate"
              dense type="warning"
              light
              matIcon="warning"
            >
              {{ translations.encodingWarning | translate }}
            </app-alert>
          </div>
        </div>

        <mat-card *ngIf="fileHeaders.length" class="mat-elevation-z2" style="margin-bottom: 16px; padding-bottom: 16px;">
          <mat-dialog-content>

            <div class="alerts-container">
              <app-alert *ngIf="isDelete()" closable type="warning" [title]="translations.warning">
                <div>
                  Para remoção de linhas, você precisa subir um banco com apenas as colunas definidas como <strong>Primary
                    Key</strong>.
                </div>
              </app-alert>
              <app-alert *ngIf="!isDelete() && !!fileUsedInDatabase" closable type="info" [title]="translations.warning">
                {{ translations.databaseAlreadyBeingUsed }}: <strong>{{ fileUsedInDatabase }}</strong>
              </app-alert>
              <app-alert *ngIf="!!emptyFieldsQuantity" closable type="warning" [title]="translations.warning">
                <app-bbcode-viewer text="{{ translations.thereAreEmptyRows | translate: { emptyFieldsQuantity } }}">
                </app-bbcode-viewer>
              </app-alert>
            </div>

            <div class="custom-file-name" *ngIf="isCreate()">
              <mat-form-field class="standard-form-field full-width-field">
                <mat-label>{{ translations.fileName }}</mat-label>
                <input type="text" matInput required [(ngModel)]="customFileName">
              </mat-form-field>
            </div>

            <div class="association-container">
              <h6 class="upper-title">{{ translations.autoAssociate }}</h6>

              <mat-button-toggle-group (change)="onChangeAssociationType($event)"
                class="high-contrast dense primary-active">
                <mat-button-toggle value="order">
                  {{ translations.autoAssociateByOrder }}
                </mat-button-toggle>
                <mat-button-toggle value="name">
                  {{ translations.autoAssociateByName }}
                </mat-button-toggle>
                <mat-button-toggle value="none">
                  {{ translations.autoAssociateManually }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>

            <div class="submenu-control-container" cdkDropListGroup>
              <div class="itens-container">
                <div class="ordenable-list header-list" cdkDropList cdkDropListSortingDisabled
                  (cdkDropListDropped)="onHeaderDrop($event)">
                  <div class="access-control-screen ordenable-item acs-header">
                    <div class="text-content">
                      <mat-icon>drag_indicator</mat-icon>
                      {{ translations.availableHeader }}
                    </div>
                  </div>
                  <div cdkDrag class="access-control-screen ordenable-item" *ngFor="let header of availableFileHeaders"
                    [attr.data-header-value]="header">
                    <div class="text-content">
                      {{ header }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="fields-container">
                <ng-container *ngFor="let field of formFields">
                  <div class="itens-container" *ngIf="isFormFieldShowable(field)">
                    <div class="ordenable-list field-list" cdkDropList (cdkDropListDropped)="onHeaderDrop($event)"
                      [attr.data-id-property]="field.idProperty">
                      <div class="access-control-screen ordenable-item acs-header">
                        <div class="text-content">
                          <mat-icon>drag_indicator</mat-icon>
                          {{ field.value }}
                        </div>
                      </div>

                      <div cdkDrag class="access-control-screen ordenable-item" *ngIf="mapper[field.idProperty]"
                        [attr.data-header-value]="mapper[field.idProperty].name">
                        <div class="text-content">
                          {{ mapper[field.idProperty].name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <button *ngIf="!isUpsertMode" [disabled]="!canSave()" mat-raised-button color="primary"
              (click)="processForm()">{{ translations.save }}</button>
          </mat-dialog-content>
        </mat-card>
      </ng-container>

      <ng-template #inputNonSerializableName>
        <mat-form-field appearance="fill" style="width: 400px;">
          <mat-label>Nome</mat-label>
          <input matInput [(ngModel)]="customFileName">
        </mat-form-field>
      </ng-template>

    </div>
  </section>
</section>

<mat-dialog-actions *ngIf="isUpsertMode" align="end" style="display: flex; justify-content: end;">
  <button mat-raised-button [color]="buttonActionLabel.color" [disabled]="shouldDisableActionButton()"
    [loading]="processingForm" (click)="processForm()">
    {{ buttonActionLabel.label }}
  </button>
</mat-dialog-actions>

<app-multimedia-instance-uploader #mmUploader [multimediaHandler]="multimediaHandler">
</app-multimedia-instance-uploader>

<ng-template #spinner>
  <app-dashboard-spinner></app-dashboard-spinner>
</ng-template>

<ng-template #checkError>
  <ng-container *ngIf="error; else spinner">
    <div class="header">
      <h4 class="subtitle">{{ translations.errorLoadingSchemas }}</h4>
    </div>
  </ng-container>
</ng-template>

<ng-template #noForms>
  <div class="header">
    <h4 class="subtitle">{{ translations.noForms }}</h4>
  </div>
</ng-template>
