import { Location } from '@angular/common';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { IGeneralFormAnswer, IRFieldResponse, TGeneralFieldArray } from '@colmeia/core/src/general-form/general-form-answer';
import { IFormSchema, INonSerializableSchemaResponse, SchemaProperty } from '@colmeia/core/src/general-form/general-form-interface';
import { MMconstant, MultimediaInstance, TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { EMimeTypes, EUniqueFileType } from '@colmeia/core/src/multi-media/file-interfaces';
import { ICSVToSchemma, IGeneralFileMetadata, IProcessFileResponse, TFileFieldArray } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { EDefaultTag, ITaggable, ourTags } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { ENonSerializableObjectType, INonSerializableHeader } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { MultimediaInstanceUploaderComponent } from 'app/components/foundation/multimedia-instance-uploader/multimedia-instance-uploader.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { MultimediaHandler } from 'app/handlers/multimedia-handler';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { TaggableHandler } from 'app/handlers/taggable.handler';
import { FileUploadSignal } from 'app/model/signal/file-upload-signal';
import { DashboardFileService } from 'app/services/dashboard/dashboard-file.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService } from 'app/services/lookup.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SnackMessageService, EAppAlertTypes } from 'app/services/snack-bar';
import { DashboardIntegrationHandler, EDatabaseProcessOperation, IDashboardIntegrationParameters } from './dashboard-integration-create.handler';
import { isValidRef, isInvalid, nop, asyncNoop } from '@colmeia/core/src/tools/utility';
import { ValueOf, PickByValueType } from '@colmeia/core/src/tools/utility-types';
import { ColmeiaWindowRef } from '../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { isValidString, noop } from '@colmeia/core/src/tools/utility';
import { ClientCachedFile } from '@colmeia/core/src/multi-media/client-cached';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ThemePalette } from '@angular/material/core';
import * as chardet from 'chardet';
import { generateDatabaseMapperFromFormSchema } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';

@Component({
    selector: 'app-dashboard-integration-create',
    templateUrl: './dashboard-integration-create.component.html',
    styleUrls: ['./dashboard-integration-create.component.scss']
})
export class DashboardIntegrationCreateComponent extends RootComponent<
    'fileName' |
    'fileSize' |
    'columnName' |
    'formField' |
    'nullFields' |
    'cardinality' |
    'savedLines' |
    'linesWithError' |
    'creationDate' |
    'fileDetails1' |
    'noFilesToShow' |
    'selectCSVFile' |
    'errorLoadingFiles' |
    'createIntegration' |
    'selectForm' |
    'selecteCSVFile' |
    'columns' |
    'save' |
    'saveSuccess' |
    'saveError' |
    'noForms' |
    'errorLoadingSchemas' |
    'integrations' |
    'fileSize' |
    'linesWithError' |
    'creationDate' |
    'details' |
    'loadIntegrationsError' |
    'noIntegrationsToShow' |
    'invalidFileType' |
    'encodingWarning' |
    'encodingWarningFull'
> implements OnInit {
    @Input() handler: DashboardIntegrationHandler;
    public get parameters(): IDashboardIntegrationParameters { return this.handler.getComponentParameter(); }
    public get currentFile(): IGeneralFileMetadata { return this.parameters.currentFile; }
    public get currentFileId(): string { return this.currentFile?.idNS; }
    customFileName: string = "";
    error: boolean = false;
    forms: Array<IFormSchema>;
    formFields: TGeneralFieldArray = [];
    fieldsQuantity: number;
    emptyFieldsQuantity: number;
    mayBeInvalidEncoding: boolean;
    multimediaHandler: MultimediaHandler;
    selectedFile: MultimediaInstance;
    fileHeaders: TArrayID = [];
    availableFileHeaders: TArrayID = [];
    formSchema: IFormSchema;
    mapper: ICSVToSchemma = {};
    formResponses: IGeneralFormAnswer[] = [];
    showFields: boolean = true;
    taggable: ITaggable = {
        tags: [],
    }
    formPickerHandler: NSPickerHandler;
    @ViewChild('mmUploader', { static: true }) mmUploader: MultimediaInstanceUploaderComponent;

    public processingForm: boolean = false;
    public fileUsedInDatabase?: string = '';
    public isUploading: boolean = false;
    public uploadProgress: number = 0;
    public isProcessingCSVFile: boolean;
    public hasWindow: boolean = false;
    public startAsAnEmptyDB: boolean = false;

    private buttonActionLabelHash: Record<EDatabaseProcessOperation, { label: string, color: ThemePalette }> = {
        [EDatabaseProcessOperation.create]: {
            label: this.translations.save.value,
            color: 'primary',
        },
        [EDatabaseProcessOperation.delete]: {
            label: 'Deletar',
            color: 'warn',
        },
        [EDatabaseProcessOperation.upsert]: {
            label: this.translations.save.value,
            color: 'primary',
        },
        [EDatabaseProcessOperation.download]: {
            label: this.translations.save.value,
            color: 'primary',
        }
    }

    public buttonActionLabel: { label: string, color: ThemePalette } = this.buttonActionLabelHash.create;

    constructor(
        private multimediaSvc: MultimediaService,
        private server: ServerCommunicationService,
        private snackSvc: SnackMessageService,
        private dashFileSvc: DashboardFileService,
        private location: Location,
        private dashboardSvc: DashBoardService,
        private lookupSvc: LookupService,
        @Optional() private windowRef: ColmeiaWindowRef,
        @Optional() private dialogRef: MatDialogRef<DashboardIntegrationCreateComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private fromModalHandler: DashboardIntegrationHandler
    ) {
        super({
            ...gTranslations.files,
        });

        this.hasWindow = isValidRef(windowRef);

        if (fromModalHandler) {
            this.handler = fromModalHandler;
        } else {
            this.handler = DashboardIntegrationHandler.factory({
                type: EDatabaseProcessOperation.create,
                currentFile: undefined,
                clientCallback: { onRefreshFile: async () => await asyncNoop() }
            });
        }

        this.buttonActionLabel = this.buttonActionLabelHash[this.parameters.type];
    }


    public getNSs = this.lookupSvc.createNSCacheImplementation();
    public getNS = this.lookupSvc.buildSingleNSCacheGetter(this.getNSs);

    taggableHandler: TaggableHandler;
    loadTaggableHandler() {
        this.taggableHandler = new TaggableHandler({
            taggable: this.taggable,
            compact: true,
            clientCallback: this,
        });
    }
    onChangeTaggableCallback() {
    }
    onFinishSelectionCallback() {
    }

    getDBName(): string {
        return this.handler.getComponentParameter().currentFile?.nName;
    }

    public get isUpsertMode(): boolean {
        return isValidRef(this.handler);
    }

    ngOnInit() {
        this.loadForm();
        this.multimediaHandler = this.getMultimediaHandler();
        this.loadTaggableHandler();
        this.initUploadListener();
    }

    public async loadForm(): Promise<void> {
        if (this.getComponentType() == EDatabaseProcessOperation.create) {
            return this.loadFormPickerHandler();
        }
        const schema: SchemaPropertyServer = await this.getNS<SchemaPropertyServer>(this.currentFile?.idSchemma);
        this.onSchemaSelected(schema);
    }

    private initUploadListener() {
        this.server.uploadStatusListener().subscribe((event: FileUploadSignal) => {
            const selectedFile = event.getObservableInformation();
            const commingHash = selectedFile.getHashFile();
            const localHash = this.selectedFile?.getClientFileInfo().getHashFile();

            if (commingHash !== localHash) return;

            this.uploadProgress = event.getPercentage();
        });
    }

    public isUploadButtonDisable(): boolean {
        return this.isUploading || this.isProcessingCSVFile;
    }

    public getUploadButtonText(): string {
        return this.isUploading
            ? `Enviando: ${this.uploadProgress}%`
            : this.isProcessingCSVFile
                ? "Processando arquivo..."
                : this.translations.selecteCSVFile.value + (this.selectedFile ? ": " : "")
    }

    onSaveNSCallback(ns: INonSerializableHeader, nsType: ENonSerializableObjectType) {
        switch (nsType) {
            case ENonSerializableObjectType.formSchemma:
                this.onSchemaSelected(<INonSerializableSchemaResponse>ns);
        }

    }

    shouldUseDemandedTag() {
        const defaultTag: string = this.dashboardSvc.defaultTag;

        const config: { [key in EDefaultTag]?: boolean } = {
            [ourTags.colmeiaTag.markers.services.idNS]: false,
        };

        if (defaultTag in config) return config[defaultTag];

        return true;
    }


    loadFormPickerHandler(): void {
        this.formPickerHandler = this.dashboardSvc.easyCreateNSPickerHandler({
            title: 'Selecione um metadado',
            nsType: ENonSerializableObjectType.formSchemma,
            selectedId: undefined,
            clientCallback: this,
            idParent: undefined,
            useDemandedTag: this.shouldUseDemandedTag()
        });
    }

    goBack() {
        this.location.back();
    }

    resetSchema() {
        this.formFields = [];
        this.fieldsQuantity = null;
        this.emptyFieldsQuantity = null;
        this.mapper = {};
        this.resetFile();
    }

    resetFile() {
        this.selectedFile = null;
    }

    public onSchemaSelected(formSchema: INonSerializableSchemaResponse): void {
        this.resetSchema();
        // this.fileHeaders = [];

        this.formSchema = formSchema.schemma;

        this.formSchema.form.map((item: SchemaProperty) => {
            this.formFields.push({
                idProperty: item.idProperty,
                value: item.prompt,
                raw: item.prompt,
                idLocalCanonical: item.idLocalCanonical,
                idGlobalCanonical: null, //@TODO verificar isso
            });
        });

        // this.resetFields();
    }


    public resetFields(): void {
        this.showFields = false;

        setTimeout(() => {
            this.showFields = true;
        }, 0);
    }

    public isFieldFree(idProperty: string, fileHeader: string): boolean {
        return isInvalid(this.mapper[idProperty])
            || this.mapper[idProperty].name === fileHeader;
    }

    private removeFromMapper(idxOfHeader: number): void {
        if (idxOfHeader !== -1) {
            const idPropertyToRemove = Object.keys(this.mapper)[idxOfHeader];
            delete this.mapper[idPropertyToRemove];
        }
    }

    private getMultimediaHandler(): MultimediaHandler {
        return new MultimediaHandler({
            idPlayer: null,
            idAvatar: null,
            generateHashNow: true,
            idMultimediaTag: MMconstant.tag.photo,
            mimeTypeFilter: [EUniqueFileType.CsvMimes],
            maxNumberOfFiles: 1,
            multimediaService: this.multimediaSvc,
            clientCallback: {
                onClearMultimedia: () => nop,
                onMultimediaSelected: async (fileList: TMultimediaInstanceArray) => {
                    if (!this.validateFileList(fileList)) {
                        return;
                    }

                    this.mapper = {};
                    this.fileHeaders = [];

                    await this.getCsvInfo(fileList);
                },
            },
        });
    }

    private validateFileList(fileList: TMultimediaInstanceArray): boolean {
        if (fileList.some(file => file.getMimeType() !== EMimeTypes.Csv)) {
            this.snackSvc.open({
                message: this.translations.invalidFileType.value,
                duration: secToMS(5),
                type: EAppAlertTypes.Error,
            });
            return false;
        }

        return true;
    }

    private async getCsvInfo(fileList: TMultimediaInstanceArray): Promise<void> {
        const selectedFile = fileList[0];
        const csvFile: Blob = selectedFile.getClientFileInfo().getClientCachedFile().getFile();
        this.selectedFile = selectedFile;

        const cachedFile: ClientCachedFile = selectedFile.getSelectedFile().getClientCachedFile();
        this.isProcessingCSVFile = true;

        this.checkFileEncoding(csvFile);

        // pega cabecalho e linhas de arquivo
        let [headers, fileFields]: [TArrayID, TFileFieldArray] = await this.multimediaSvc.getCsvDataPapaParse(selectedFile);

        // calcula quantidade de linhas vazias
        const emptyFields = fileFields.filter(row => {
            return Object.values(row).every(value => value === '');
        });

        this.fieldsQuantity = fileFields.length;
        this.emptyFieldsQuantity = emptyFields.length;

        this.isProcessingCSVFile = false;

        if (isInvalid(cachedFile.getIDMedia())) {
            await this.uploadFile(selectedFile);
        } else {
            selectedFile.setIdMedia(cachedFile.getIDMedia());
        }

        this.checkIfFileIsUsed(selectedFile.getIdMedia());

        console.log({ headers, fileFields });
        this.fileHeaders = headers;
        this.availableFileHeaders = [...headers];
    }

    private async uploadFile(selectedFile: MultimediaInstance) {
        this.isUploading = true;
        try {
            await this.multimediaSvc.genericUploadFile(selectedFile, MMconstant.tag.csvUpload);
        } catch (err) {
            this.resetFile();
            throw err;
        } finally {
            this.isUploading = false;
        }
    }

    private async checkIfFileIsUsed(idMedia: string) {
        const databaseName = await this.dashFileSvc.isFileUsed(idMedia);
        this.fileUsedInDatabase = databaseName;
    }

    public onAttachCsvClicked(): void {
        if (this.isUploadButtonDisable()) return;

        this.mmUploader.onFileUploadClicked();
    }

    public getSelectedFileName(): string {
        return this.selectedFile.getClientFileInfo().getCurrentName();
    }

    public shouldDisableActionButton(): boolean {
        if(this.startAsAnEmptyDB) return false;

        const hasValidName: boolean = this.isCreate() ? this.customFileName.length > 0 : true;
        const hasFileHeaders: boolean = this.fileHeaders.length > 0;
        const mapperLength: number = Object.values(this.mapper).length;
        const fileHeadersFulfilled: boolean = this.fileHeaders.length === mapperLength;
        const formFieldsFulfilled: boolean = this.formFields.length === mapperLength;

        return !(
            hasValidName &&
            hasFileHeaders &&
            (this.isDelete()
                ? mapperLength > 0
                : (fileHeadersFulfilled || formFieldsFulfilled)
            )
        )
    }

    isCreate() {
        return this.getComponentType() === EDatabaseProcessOperation.create
    }

    isUpsert() {
        return this.getComponentType() === EDatabaseProcessOperation.upsert
    }

    isDelete() {
        return this.getComponentType() === EDatabaseProcessOperation.delete
    }

    isFormFieldShowable(field: IRFieldResponse): boolean {
        if (field == null) {
            return
        }

        if (this.getComponentType() === EDatabaseProcessOperation.delete) {
            const formField: SchemaProperty = this.formSchema.form.find(formField => field.idProperty === formField.idProperty)
            if (formField?.isPrimaryKey) {
                return true
            }
            return false
        }

        return true
    }

    getComponentType() {
        const operationType: EDatabaseProcessOperation = this.handler.getComponentParameter().type
        return operationType
    }

    getBestTitle() {
        return this.getComponentType() === EDatabaseProcessOperation.delete
            ? 'Deletar'
            : this.getComponentType() === EDatabaseProcessOperation.upsert
                ? 'Atualizar'
                : 'Criar'
    }

    public async processForm(): Promise<void> {
        let success: boolean
        this.processingForm = true;

        if (this.getComponentType() == EDatabaseProcessOperation.delete) {
            const result: string = await this.dashFileSvc.deleteFileContents(this.currentFile?.idNS,
                this.selectedFile.getIdMedia(),
                this.mapper)
            success = isValidString(result)
        } else {
            const out: IProcessFileResponse | undefined = await this.dashFileSvc.processFileMetadata({
                customFileName: this.customFileName,
                file: this.selectedFile,
                idSchemma: this.formSchema.idSchemma,
                mapper: this.startAsAnEmptyDB
                    ? generateDatabaseMapperFromFormSchema(this.formSchema)
                    : this.mapper,
                taggable: this.taggable,
                idKB: undefined,
                currentFileId: this.isUpsertMode ? this.currentFileId : undefined,
                startedAsAnEmptyDB: this.startAsAnEmptyDB
            });

            success = isValidRef(out);
        }

        if (success) {


            this.snackSvc.open({
                message: this.translations.saveSuccess.value,
                duration: secToMS(3),
                type: EAppAlertTypes.Success,
            });

            if (this.isUpsertMode) {
                this.parameters.clientCallback.onRefreshFile();
            }

            this.dialogRef?.close();
            this.windowRef?.close();
        }

        this.processingForm = false;
    }

    public onHeaderDrop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) return;

        const previousContainerElement = event.previousContainer.element.nativeElement;
        const containerElement = event.container.element.nativeElement;
        const itemElement = event.item.element.nativeElement;
        const header = itemElement.dataset.headerValue;

        const idxOfHeader = Object.values(this.mapper).findIndex(m => m.name === header);

        if (containerElement.classList.contains('field-list')) {
            const idProperty = containerElement.dataset.idProperty;
            const fieldResponse: IRFieldResponse = this.formFields.find(f => f.idProperty === idProperty);

            if (this.mapper[fieldResponse.idProperty]) return;

            this.mapper[fieldResponse.idProperty] = {
                name: header,
                pos: this.fileHeaders.findIndex((i) => i === header),
            };

            if (previousContainerElement.classList.contains('header-list')) {
                this.availableFileHeaders = this.availableFileHeaders.filter(h => h !== header);
            }
        }
        else {
            this.availableFileHeaders.splice(event.currentIndex, 0, header);
        }

        if (idxOfHeader !== -1)
            this.removeFromMapper(idxOfHeader);
    }

    public onChangeAssociationType(event: MatButtonToggleChange) {
        const associationType: string = event.value;

        this.mapper = {};
        this.availableFileHeaders = [...this.fileHeaders];

        switch (associationType) {
            case 'order': {
                for (let fIndex = 0, hIndex = 0; fIndex < this.formFields.length; fIndex++) {
                    const field: IRFieldResponse = this.formFields[fIndex];
                    const header: string = this.fileHeaders[hIndex];

                    if (header && this.isFormFieldShowable(field)) {
                        hIndex++;
                    } else {
                        continue;
                    }

                    this.mapper[field.idProperty] = {
                        name: header,
                        pos: this.fileHeaders.findIndex((i) => i === header),
                    };

                    this.availableFileHeaders = this.availableFileHeaders.filter(h => h !== header);
                }

                break;
            }
            case 'name': {
                for (let i = 0; i < this.formFields.length; i++) {
                    const field: IRFieldResponse = this.formFields[i];

                    const header: string = this.availableFileHeaders.find(h => h.toLowerCase() == field.value.toString().toLowerCase());

                    if (!header || !this.isFormFieldShowable(field))
                        continue;

                    this.mapper[field.idProperty] = {
                        name: header,
                        pos: this.fileHeaders.findIndex((i) => i === header),
                    };

                    this.availableFileHeaders = this.availableFileHeaders.filter(h => h !== header);
                }

                break;
            }
        }
    }

    private async checkFileEncoding(file: Blob) {
        const [encoding, confidence] = await this.getFileEncoding(file);

        console.log({ encoding, confidence });

        this.mayBeInvalidEncoding = encoding !== 'UTF-8';
    }

    private async getFileEncoding(file: Blob): Promise<[string, number]> {
        const fileReader = new FileReader();

        fileReader.readAsArrayBuffer(file);

        return new Promise(resolve => {
            fileReader.onloadend = () => {
                const arrayBuffer = fileReader.result;
                const encodingAnalysis = chardet.analyse(new Uint8Array(arrayBuffer as ArrayBufferLike));

                if (encodingAnalysis.length === 0) {
                    resolve([null, null]);
                }

                const encoding: string = encodingAnalysis[0].name;
                const confidence: number = encodingAnalysis[0].confidence / 100;

                resolve([encoding, confidence]);
            };
        });
    }
}
