import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MenuDashboardService } from 'app/components/menu-dashboard/menu-dashboard.service';
import { DashBoardService } from './dashboard/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  private readonly titleSuffx: string = ' — Colmeia';

  constructor(
    private ngTitleSvc: Title,
    private dashboardSvc: DashBoardService,
    private dashboardMenuSvc: MenuDashboardService,
  ) { }

  private getCurrentDashboardModuleName(): string {
    const currentDashModuleOption = this.dashboardMenuSvc.allOptions.find( o => o.modulePath === this.dashboardSvc.currentDashboard);
    return currentDashModuleOption?.title;
  }

  setTitle(value?: string, suffix: string = this.titleSuffx): void {
    if(!value) {
      this.resetTitle();
      return;
    }

    const dashboardTitle: string = this.getCurrentDashboardModuleName();
    this.ngTitleSvc.setTitle(`${value} - ${dashboardTitle}${suffix}`);
  }

  getTitle(): string {
    return this.ngTitleSvc.getTitle().replace(this.titleSuffx, '');
  }

  resetTitle() {
    this.ngTitleSvc.setTitle('Colmeia');
  }

  /**
   * TODO
   * Alternância entre títulos
   * @example
   * blinkTitle(['Novo atendimento: Fulano'], 500)
   * blinkTitle(['Atenção!', 'Fulano sem resposta'], 500)
   */
  blinkTitle(values: string[], interval: number): () => void {


    // stop function
    return () => {

    }
  }
}
