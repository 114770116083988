import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const sellAbleScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Sellable,
    fields: {
        configureAsSell: 1, // Configurar como venda
        price: 2, // Preço
        creditCardNumber: 3, // Número do cartão de crédito
        buy: 4,// Comprar
        payWithTheCardBellow: 5, // Pagar com o cartão abaixo
        whenConfirmYouWillReceive: 6, //  ao confirmar a transação, você recebera R$
        ofBalance: 7,// de saldo
        addCard: 8, // Adicionar cartão
        expires: 9, // Expiração
        recharge: 10, // Recarregar
        money: 11,// Dinheiro
        walletRechargeSolicitation: 12, // Solicitação de recarga de carteira
        didTransfer: 13, // Transferiu
        loadBalance: 14, // Carregar Saldo
        transfer: 15, // Transferir
        balance: 16, // Saldo
        operation: 17, // Operação
        availableBalance: 18 // Saldo disponível
      }
} as const);

export const sellableTranslations = getTranslationsFields(sellAbleScreenOptions);