import { Injectable } from '@angular/core';
import {IImageSlider, IImageSliderParameters} from "../components/foundation/image-slider/image-slider.component";

@Injectable({
  providedIn: 'root'
})
export class ImageSliderService {

    private sliderComponent: IImageSlider;

    constructor() {
    }

    setSliderComponent(component: IImageSlider) {
        this.sliderComponent = component;
    }

    openForImages(options: IImageSliderParameters) {
        this.sliderComponent.openForImages(options);
    }
}
