import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from '@angular/router';
import { constant } from "@colmeia/core/src/business/constant";
import { EObjectType } from '@colmeia/core/src/business/constant.enums';
import { Group } from "@colmeia/core/src/business/group";
import { ObjectType } from "@colmeia/core/src/business/object-type";
import { SubscriptionStatusTranslatinoAux } from "@colmeia/core/src/business/subscriptions";
import { TSubscriptionTypeArray } from '@colmeia/core/src/interaction/subscription/subscription-type';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { EYouTaskRequestType, EYouTaskStatus } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { isInvalid, isInvalidArray } from '@colmeia/core/src/tools/utility';
import { YouTasksClientService } from 'app/components/dashboard/security/solicitation-tasks.service';
import { statusMapCustomer } from 'app/model/signal/solicitation-task-client-model';
import { SnackMessageService } from 'app/services/snack-bar';
import { SocialNetworkDatabaseService } from 'app/services/social-network-database.service';
import { environment } from 'environments/environment-client';
import { FollowButtonHandler } from "../../../handlers/follow-button-handler";
import { routeID } from "../../../model/routes/route-constants";
import { IListenerSecuredContext } from "../../../model/signal/ps-interfaces";
import { SecurityContextSignal } from "../../../model/signal/state-signals/sec-contex-signal";
import { NavigatorServices } from "../../../services/controllers-services/navigator/navigator.service";
import {
    ExecutableItem,
    TExecutableItemArray
} from "../../../services/controllers-services/security-controller/executable-item";
import { SafeMenuBuilderServices } from "../../../services/controllers-services/security-controller/safe-menu-builder";
import { RoutingService } from "../../../services/routing.service";
import { SessionService } from "../../../services/session.service";
import { ECmBtnColorType } from "../cm-button/cm-button.component";
import { RootComponent } from '../root/root.component';

export type TStatusTranslation = [string, string, string];

export type TSubtypeLabels = {
    [subTypeID: string]: TStatusTranslation;
}

export type TSubtypeIcons = {
    [subTypeID: string]: string;
}

@Component({
    selector: 'cm-follow-button',
    templateUrl: './follow-button-dropdown.component.html',
    styleUrls: ['./follow-button-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FollowButtonDropdown extends RootComponent<'follow' | 'chat'> implements IListenerSecuredContext, OnInit {

    types: TExecutableItemArray = [];
    public BTNCOLOR: typeof ECmBtnColorType = ECmBtnColorType;
    private _handler: FollowButtonHandler;
    private menubuilderSVC: SafeMenuBuilderServices;
    private statuses: EYouTaskStatus;
    private statusesTranslation: TSubtypeLabels = {};
    private icons: TSubtypeIcons = {};

    public showChatButton: boolean = false;
    public chatURL: string;
    subscriptionItem: ExecutableItem;

    @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger;

    constructor(
        private cdRef: ChangeDetectorRef,
        private sessionSVC: SessionService,
        private navigator: NavigatorServices,
        private routingSvc: RoutingService,
        private router: Router,
        private solicitationSvc: YouTasksClientService,
        private snackSvc: SnackMessageService,
        private snSvc: SocialNetworkDatabaseService,

    ) {
        super({
            follow: gTranslations.common.follow,
            chat: gTranslations.chat.chat
        });
        this.menubuilderSVC = new SafeMenuBuilderServices(this.sessionSVC.getMenuSafeBuilderData());
    }

    ngOnInit() {
        this.chatURL = this.getChatURL();
    }

    get handler(): FollowButtonHandler {
        return this._handler;
    }

    @Input()
    set handler(handler: FollowButtonHandler) {
        this._handler = handler;
        this.refreshContent();
        this.showChatButton = this.canShowChatButton();
    }

    changeOnSecureContextSignCallback(context: SecurityContextSignal): void {
        this.refreshContent();
    };

    private refreshContent(): void {
        this.fetchSubscriptionTypes();
        this.translateSubTypeStatuses();
        this.fetchSubtypeIcons();
        this.getSubscriptionStatus();
        this.subscriptionItem = this.types.find(type => type.getSerializable().getSerializableObjectTypeID() === EObjectType.subscriptionType);
    }

    private fetchSubtypeIcons(): void {
        this.types.forEach(
            _type => {
                const serializable = _type.getSerializable();
                this.icons[_type.getPrimaryID()] = serializable.getIcon();
            }
        )
    }

    private translateSubTypeStatuses(): void {
        this.types.forEach(_type => {
            const serializable = _type.getSerializable();
            this.statusesTranslation[serializable.getPrimaryID()] = <TStatusTranslation>SubscriptionStatusTranslatinoAux.map(
                aux => serializable.getSerializableText(aux)
            );
        });
    }

    private fetchSubscriptionTypes() {
        const options: TSubscriptionTypeArray = [];

        if (isInvalid(this._handler)) {
            return;
        }
        switch (this._handler.getObjectType()) {
            case constant.objectType.group: {
                let filter: (val: ExecutableItem) => boolean = (val: ExecutableItem) => true;
                if (!environment.allDevFeatures) {
                    filter = (val: ExecutableItem) => val.getPrimaryID() === constant.subscription.request.group.groupParticipation;
                }
                this.types = this.menubuilderSVC.getFollowGroupButton(this._handler.getPrimaryID()).filter(filter);
            }
                break;
            case constant.objectType.avatar: {
                this.types = this.menubuilderSVC.getFollowAvatarButton(this._handler.getPrimaryID());
            }
                break;
        }
    }

    public getSubscriptionStatus(): void {
        this.statuses = this._handler.getSubscriptionStatus();

        this.cdRef.markForCheck();
    }

    private isActive(): boolean {
        return this.statuses === EYouTaskStatus.done;
    }

    private isPending(): boolean {
        return this.statuses === EYouTaskStatus.open;
    }

    toggleFollow() {
        if (this.isPending()) {
            return;
        } else if (this.isActive()) {
            this.unfollow();
            return;
        }
        this.follow();
    }

    private async unfollow() {
        // const res: boolean = await this.subscriptionsSVC.unfollow(type, this._handler.getPrimaryID(), this._handler.getObjectType());
        // if (res) {
        //     this.statuses = undefined;
        // }
        // this.cdRef.markForCheck();
    }

    private async follow() {
        const nsClient = this.solicitationSvc.initOnBoardingNSClient(
            this.handler.getPrimaryID(),
            this.handler.getGroup(),
        );

        const res = await this.solicitationSvc.saveTasks(nsClient, EYouTaskRequestType.solicitationCreate);

        if (res) {
            this.statuses = EYouTaskStatus.open;
            this.snackSvc.openSuccess('Solicitação criada com sucesso!', 3000)
            this.cdRef.markForCheck();
        }
    }

    getSubscriptionLabel(): string {
        return statusMapCustomer[this.statuses] || "Solicitar participação"
    }

    currentTitleForType(type: ExecutableItem): string {
        if (ObjectType.staticFactory(type.getSerializable().getObjectTypeID()).is(constant.objectType.subscriptionType)) {
            return this.getSubscriptionLabel();
        } else {
            return type.getSerializable().getSerializableText(constant.serializableField.name);
        }
    }

    iconForType(_type: ExecutableItem): string {
        const id = _type.getSerializable().getPrimaryID();
        return (id in this.icons)
            ? this.icons[id]
            : '';
    }

    get isEnabled(): boolean {
        return this.types.length > 0;
    }

    get btnColor(): ECmBtnColorType {
        return ECmBtnColorType.Main;
    }

    onMenuClosed(): void {
        // this.loaded = false;
    }

    onOptionClicked(): void {
        this.toggleFollow();
    }

    canShowChatButton(): boolean {
        if (isInvalid(this._handler)) {
            return false;
        }
        const group = Group.staticFactory(this._handler.getPrimaryID());
        const isNotSocialNetwork = !group.getGroupType().isFunctionalRoot();

        const urlArray = this.router.url.split('/');
        const participant = this.sessionSVC.getParticipant(this._handler.getPrimaryID(), true)

        // console.log({
        //     group: group.getName(),
        //     isGroupMember: this._handler.canShowChatButton() ,
        //     participant,
        //     isParticipant: !!participant
        // })

        return this._handler.canShowChatButton() || urlArray.includes(this._handler.getPrimaryID()) || !!participant || (constant.objectType.group === this._handler.getObjectType()
            && !this.isEnabled
            && isNotSocialNetwork) || this.types?.length === 0 || this.statuses === EYouTaskStatus.done || isInvalidArray(this.types)
    }

    goToChat(): void {
        this.navigator.navigateToGroupID(
            this._handler.getPrimaryID(),
            routeID.groups.chat
        );
    }

    private getChatURL(): string {
        return this.routingSvc.getURLToNavigateToId(
            routeID.groups.chat,
            this._handler.getPrimaryID()
        );
    }

    async open(): Promise<void> {
        if (this.isEnabled) {
            this.menuTrigger.closeMenu();

            if (!this.statuses) {
                this.menuTrigger.openMenu();
            }
        }
    }

    getStatusIcon() {
        if (this.statuses) {
            const iconMap: {[key in EYouTaskStatus]: string} = {
                yOpen: 'schedule',
                yForw: 'schedule',
                yDone: 'done',
                yRejec: 'block',
                yPrcss: 'schedule'
            }
            return iconMap[this.statuses]
        }
        return 'group_add'
    }
}
