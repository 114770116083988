import { assign, isValidRef } from '@colmeia/core/src/tools/utility';
import { getSharedServiceKeys } from '@colmeia/core/src/tools/utility/functions/toStatic';
import { getFnName } from '../../tools/get-fn-name';
import { $string, $ValueOf, Compute, TFunction } from '../../tools/utility-types';
import { $$ } from '../../tools/utility/types/error';
import type { $SharedServices } from './shared-services';
import { FnOut } from './shared-services.interfaces';


export function makeSharedServiceFn<Input extends {}, Output>(): FnOut<Input, Promise<Output>> {
    return execute;
    function execute(input: Input): Promise<Output> {
        notImplementedSharedFunction(input);
    }
}

export function notImplementedSharedFunction(input?: {}, shouldHideFnName: boolean = true): never {
    throw new Error(`Not implemented${shouldHideFnName ? '' : ` ${getFnName(2)}`}`)
}


export function DefineSharedService<T extends $ValueOf<typeof $SharedServices>>(source: T) {}


const ignoredImplementation = new Set<string | TFunction>()
export function coreImplementation(fnName: string) {
    ignoredImplementation.add(fnName);
}

export function ForceImplementation<T extends { [key in string]: any }>(service: T) {
    const keys = new Set(getSharedServiceKeys(service)!);
    return (implementation: (new () => Partial<T>) & { prototype: Partial<T>; }) => {
        const instance = new implementation();
        for (const key of Object.keys(instance)) keys.add(key);
        
        for (const key of keys) {
            const value = instance[key];
            if (isValidRef(value)) Reflect.set(service, key, value);
        }
    }
    
}

export function defineIgnored<T>(instance: T) {
    return <Key extends (keyof T)[]>( ...ignored: Key) => ignored
}


export function defineAlias<T extends TFunction>(getter: () => T): T;
export function defineAlias<T extends TFunction>(getter: () => T) {
    return function execute(...params: any[]) {
        return getter()(...params);
    }
}

export type TargetWithName = { name?: string; constructor: { name: string } }
export function getTargetName<T extends TargetWithName>(target: T) {
    return target.name ?? target.constructor.name;
}

const noExec = new Set<unknown>()
export function NoExec<T extends {}, Key extends (keyof T & string)>(source: T, key: Key, descriptor: TypedPropertyDescriptor<T[Key]>) {
    const fnName: string = `${getTargetName(source)}.${key}`;
    noExec.add(fnName);
}
export function isIgnoringExec(fnName: string) {
    return noExec.has(fnName);
}

type Builder<T> = <Property extends string>(property: Property) => `${string}.${Property}`;

export function createNameBuilderFromTarget<T extends TargetWithName>(target: T): Builder<T> {
    const name = getTargetName(target);
    
    return function builder<Property extends string>(property: Property): `${string}.${Property}` {
        return `${name}.${property}`;
    }
}

export function isIgnoringImplementation(fnName: string, instance?: { name?: string; ignored?: string[] }) {
    if (instance?.ignored) addToSet(ignoredImplementation, instance.ignored.map(item => `${getTargetName(instance)}.${item}`));
    return ignoredImplementation.has(fnName);
}

function addToSet<T>(set: Set<T>, items: T[]) {
    for (const item of items) set.add(item);
}