import { ComponentType } from "@angular/cdk/portal";
import { Component, OnInit } from "@angular/core";
import { ColmeiaWindowRef } from "../dashboard-foundation/colmeia-window/colmeia-window-ref";
import { IYouTasksClientConfig, youTasksTypeConfig } from "./you-task.client-db";
import { IYouTasksEditParameters, YouTasksEditHandler } from "./you-tasks-edit.handler";

@Component({
    selector: 'cm-you-tasks-dialog',
    template: `
    <app-colmeia-window-top-bar style="margin-bottom: 12px;"></app-colmeia-window-top-bar>
    <ng-container *ngComponentOutlet="component; inputs: { handler: handler };">`,
    styles: [`:host { min-width: 360px; display: block; }`]
})
export class YouTasksDialogComponent implements OnInit {

    handler!: YouTasksEditHandler;
    config!: IYouTasksClientConfig;
    component!: ComponentType<unknown>

    get parameters(): IYouTasksEditParameters { return this.colmeiaWindowRef.data.getComponentParameter() };

    constructor(
        private colmeiaWindowRef: ColmeiaWindowRef<unknown, unknown, YouTasksEditHandler>,
    ) {
        this.handler = colmeiaWindowRef.data;
    }

    ngOnInit(): void {
        this.config = youTasksTypeConfig[this.parameters.nss[0]?.details.taskType];

        this.component = this.config.component;
    }

}