import { ColmeiaWindowRef } from "../../dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref";
import {
    Component,
    OnInit,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject,
    Optional,
} from "@angular/core";
import { ParticipantRoleSelectorComponent } from "./participant-role-selector/participant-role-selector.component";
import { Memoize } from "typescript-memoize";
import { ObjectType } from "@colmeia/core/src/business/object-type";
import { constant } from "@colmeia/core/src/business/constant";
import { Role, ISecurityRoleChanges } from "@colmeia/core/src/security/role";
import { Avatar, TAvatarArray } from "@colmeia/core/src/business/avatar";
import { hasRoleIDToBellongToGroup } from "@colmeia/core/src/rules/sec-functions";
import {
    HandlerSearch,
    IClientSearchCallback,
} from "../../../handlers/search-handler";
import { IParticipantRoleSelectorClient } from "../../../handlers/participant-role-selector.handler";
import {
    NamedNumber,
    NamedString,
    TExtendedParticipant,
    TExtendedParticipantArray,
} from "@colmeia/core/src/core-constants/types";
import { SessionService } from "../../../services/session.service";
import { HandlerFactoryService } from "../../../handlers/handler-factory.service";
import { serializableToHexagononHandler } from "../../../handlers/business-handlers";
import { HandlerHexagonon } from "../../../handlers/hexagono.handler";
import { clientConstants } from "../../../model/constants/client.constants";
import {
    EParticipantClickTypeMode,
    ESelectionType,
    IParticipantSelectorToRolesSelected,
    OnParticipantsSelectedReturn,
    ParticipantSelectorHandler,
} from "../../../handlers/participant-selector.handler";
import { CmModalHandler } from "../../../handlers/cm-modal-handler";
import { RootComponent } from "../root/root.component";
import { ESearchScope } from "@colmeia/core/src/shared-business-rules/search";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { IColmeiaDialogComponentData } from "app/components/dialogs/dialog/dialog.component";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { SearchService } from "app/services/search.service";
import { horizontalAppear } from "app/components/dashboard/dashboard-animations";
import { GenericDashboardPaginationHandler } from "app/components/dashboard/dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.handler";
import { GenericDashboardPaginationParameter } from "app/components/dashboard/dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.parameter";
import { TSortBy } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { DEFAULT_PARTICIPANTS_PER_PAGE, DEFAULT_SORT_BY } from "app/components/new-group/tabs/group-members-tab/group-members-tab.component";
import { EListNonSerializablesSortOrder } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { IAvatarWithParticipationClockTick } from "@colmeia/core/src/request-interfaces/response-interfaces";

type Cursor = NamedString<'Cursor'>;
type CurrentPage = NamedNumber<'CurrentPage'>;

export enum ESortOptions {
    dateTime = 'Data'
}



@Component({
    selector: "app-participant-selector",
    templateUrl: "./participant-selector.component.html",
    styleUrls: ["./participant-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchService],
    animations: [horizontalAppear]
})
export class ParticipantSelectorComponent
    extends RootComponent<"remove" | "addNewParticipantLabel">
    implements OnInit, IClientSearchCallback, IParticipantRoleSelectorClient {
    private searchHandler: HandlerSearch;
    private selectedParticipants: Set<TExtendedParticipant> = new Set();
    private excludedParticipants: Set<TExtendedParticipant> = new Set();
    _participantSelectorHandler: ParticipantSelectorHandler;

    private participantRoleModal: MatDialogRef<ParticipantRoleSelectorComponent>;

    public isInWindow: boolean = false;
    @Input()
    public hideWindowBar: boolean = false;

    loadingParticipants: boolean;
    loadingOnAction: boolean;

    constructor(
        private cdRef: ChangeDetectorRef,
        private sessionSvc: SessionService,
        private handlerFactorySvc: HandlerFactoryService,
        public dialog: MatDialog,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        private dataFromDialog: IColmeiaDialogComponentData<{
            params: ParticipantSelectorHandler;
        }>,
        @Optional()
        private windowRef: ColmeiaWindowRef<
            ParticipantSelectorComponent,
            { params: ParticipantSelectorHandler }
        >
    ) {
        super({
            remove: gTranslations.common.remove,
            addNewParticipantLabel: gTranslations.common.addNew,
        });

        if (isValidRef(dataFromDialog?.getParamsToChildComponent?.().params)) {
            this.participantSelectorHandler =
                dataFromDialog.getParamsToChildComponent().params;
        }

        if (
            isValidRef(windowRef?.data?.params) &&
            windowRef?.data?.params instanceof ParticipantSelectorHandler
        ) {
            this.participantSelectorHandler = windowRef.data.params;
        }

        this.isInWindow = !!windowRef;
    }
    idAvatarToClocktickBegin: Record<string, IAvatarWithParticipationClockTick> | undefined;

    participantsMap = new Map<Cursor, { participants: TExtendedParticipantArray, cursor: Cursor }>();
    @Input()
    set participantSelectorHandler(value: ParticipantSelectorHandler) {
        this._participantSelectorHandler = value;
        this.selectedParticipants.clear();

        value.getParameter().searchRemoteEnvConfig

        const participants: TExtendedParticipantArray = this._participantSelectorHandler.getParticipants();

        this.idAvatarToClocktickBegin = this.handler.getAvatarClocktickBegin();
        participants.forEach((participant) => this.selectedParticipants.add(participant));
        this.paginationSorting = value.getSortBy()?.[0].direction || EListNonSerializablesSortOrder.Descending;
        this.participantsMap.set(value.getCursor()!, { participants, cursor: value.getCursor()! })

        if (value.parameters().usePagination) this.generatePaginationHandler(!!this._participantSelectorHandler.getCursor());

        this.searchHandler = this.newSearchHandler();
        this.getParticipants();
        this.cdRef.markForCheck();
    }

    paginationHandler: GenericDashboardPaginationHandler;
    private currentPage: number = 0;
    private perPage: number = DEFAULT_PARTICIPANTS_PER_PAGE;

    generatePaginationHandler(hasCursor: boolean): void {

        const paginatorParameter: GenericDashboardPaginationParameter = {
            totalItems: this.selectedParticipants.size,
            clientCallback: this,
            perPage: { current: this.perPage, options: [50, 100, 250, 500] },
            index: this.currentPage,
            hasMoreEntitiesToLoad: hasCursor,
            sort: {
                options: Object.values(ESortOptions),
                onSortingSelected: (val: EListNonSerializablesSortOrder) => this.onSortingSelected(val)
            }
        };
        this.paginationHandler = new GenericDashboardPaginationHandler(paginatorParameter);
    }

    get parameters() {
        return this._participantSelectorHandler.getComponentParameter();
    }
    get handler() {
        return this._participantSelectorHandler;
    }

    onPageIndexChange(index: number) {
        const oldIndex = this.currentPage;
        this.currentPage = index;
        this.getParticipants();
        const cursor = this.handler.getCursor();
        if (
            index > oldIndex
            && this.paginationHandler.isAtLastPage()
            && this.perPage > this.cachedParticipants.length
            && cursor
        ) {
            this.loadMoreItems(this.perPage - this.cachedParticipants.length);
        }
    }

    onAmountPerPageChange(amount: number) {
        this.perPage = amount;
        this.currentPage = 0;
        this.loadMoreItems(amount, true);
    }

    async loadMoreItems(perPage?: number, cursorUndefined: boolean = false): Promise<void> {
        this.loadingParticipants = true;

        await this.participantSelectorHandler.getParameter().clientCallback.loadMoreParticipants?.(
            perPage || this.perPage,
            cursorUndefined ? undefined : this.handler.getCursor(),
            [{
                fieldName: 'clockTickBegin',
                direction: this.paginationSorting
            }]
        )
        this.loadingParticipants = false;
    }

    async onSortingSelected(value: EListNonSerializablesSortOrder) {
        this.currentPage = 0;
        this.paginationSorting = value;
        this.loadingParticipants = true;
        await this.participantSelectorHandler.getParameter().clientCallback.loadMoreParticipants?.(
            this.perPage,
            undefined,
            [{
                fieldName: 'clockTickBegin',
                direction: value
            }]
        )
        this.loadingParticipants = false;
    }

    get participantSelectorHandler(): ParticipantSelectorHandler {
        return this._participantSelectorHandler;
    }

    ngOnInit() {
        this.searchHandler = this.newSearchHandler();
        this.getParticipants();
    }

    get remove(): string {
        return this.translations.remove.value;
    }

    canShowRemove(): boolean {
        return this.participantSelectorHandler.hasRemove();
    }

    //#region Handlers
    getHexHandler(participant: TExtendedParticipant): HandlerHexagonon {
        return serializableToHexagononHandler(participant);
    }

    getSearchHandler(): HandlerSearch {
        return this.searchHandler;
    }

    newSearchHandler(): HandlerSearch {
        console.log({ newSearchHandlerMenuID2: this.participantSelectorHandler.getParameter().menuID });

        return new HandlerSearch({
            clientCallback: this,
            defaultOption:
                this.participantSelectorHandler.getDefaultAllowedType(),
            searchTypes: this.participantSelectorHandler.getAllowedTypes(),
            maxSelectionableNumber: clientConstants.maxParticipantsPerGroup,
            searchScope: ESearchScope.allSocialNetwork,
            showCloseBtn: false,
            isEditableTypes:
                this.participantSelectorHandler.isEditableTypesSelected(),
            clickAction: this.participantSelectorHandler.getOperationMode(),
            idAgentIsland: this._participantSelectorHandler.getIdAgentIsland(),
            searchRemoteEnvConfig: this.participantSelectorHandler.getParameter().searchRemoteEnvConfig,
            requestModifierCallback: this.participantSelectorHandler.getParameter().requestModifierCallback,
            menuID: this.participantSelectorHandler.getParameter().menuID
        });
    }
    paginationSorting: EListNonSerializablesSortOrder = EListNonSerializablesSortOrder.Descending;

    cachedParticipants: Array<TExtendedParticipant> = [];
    cachedParticipantMembersGroupDate: Record<string, { current: string, begin: string }> = {};

    public getParticipants(): void {
        let currentParticipants = Array.from(this.selectedParticipants);

        if (this.participantSelectorHandler.getParameter().usePagination) {
            currentParticipants = this.paginationHandler.getPageItens(currentParticipants);
        }

        currentParticipants.forEach(participant => {
            const memberDate = this.getFormatedMembershipDate(participant);
            if (memberDate) {
                this.cachedParticipantMembersGroupDate[participant.getPrimaryID()] = memberDate;
            }
        });
        this.cachedParticipants = currentParticipants;
    }

    getFormatedMembershipDate(avatar: TExtendedParticipant) {
        const clockTickBegin = this.idAvatarToClocktickBegin?.[avatar.getPrimaryID()]?.clockTickBegin;
        const clockTickCurrent = this.idAvatarToClocktickBegin?.[avatar.getPrimaryID()]?.clockTickCurrent;

        if (!clockTickBegin || !clockTickCurrent) return;
        const dateBegin = new Date(clockTickBegin);
        // getMonth => Um valor inteiro que representa o mês, começando com 0 para Janeiro até 11 para Dezembro. Por isso o +1 é para compensar o valor inicial.
        const beginFormated = `${dateBegin.getDate()}/${dateBegin.getMonth() + 1}/${dateBegin.getFullYear().toString().slice(-2)}`;

        const dateCurrent = new Date(clockTickCurrent);
        const curentFormated = `${dateCurrent.getDate()}/${dateCurrent.getMonth() + 1}/${dateCurrent.getFullYear().toString().slice(-2)}`;

        return { begin: beginFormated, current: curentFormated };
    }

    canShowParticipants(): boolean {
        return this.selectedParticipants.size > 0 && !this.loadingParticipants;
    }

    removeParticipant(participant: TExtendedParticipant) {
        this.loadingOnAction = true;
        this.selectedParticipants.delete(participant);
        this.excludedParticipants.add(participant);
        this.sendParticipantsToParentComponent([participant]);
    }

    async sendParticipantsToParentComponent(participants: TExtendedParticipantArray) {
        if (
            this.participantSelectorHandler.getOperationMode() ===
            EParticipantClickTypeMode.SendToParent
        ) {
            const response: OnParticipantsSelectedReturn | void = await this.participantSelectorHandler.onParticipantsSelected(
                [...this.selectedParticipants.values()],
                [...this.excludedParticipants.values()]
            );


            this.getParticipants();
            this.cdRef.markForCheck();
        }
        this.loadingOnAction = false;
    }

    //#region IClientSearchCallback compliance
    onFinishSelection(selectedList: TExtendedParticipantArray) {
        this.loadingOnAction = true;
        const maxParticipants = this._participantSelectorHandler.getMaxNumberOfParticipants();
        selectedList.forEach((participant, index) => {
            if (this.selectedParticipants.size >= maxParticipants) {
                if (
                    !this.participantSelectorHandler.shouldOverwritePartipantsWhenFull() ||
                    this.selectedParticipants.has(participant) ||
                    index >= this.selectedParticipants.size
                ) {
                    return;
                }

                // substitui o participante pelo novo partipante selecionado
                const overwrittenParticipant = [...this.selectedParticipants.values()][index];
                this.selectedParticipants.delete(overwrittenParticipant);
                this.excludedParticipants.add(overwrittenParticipant);
            }

            this.selectedParticipants.add(participant);
        });

        this.sendParticipantsToParentComponent(selectedList);
    }

    public closeSearch = () => {
        this.searchHandler.getSlave().closeSearchBox();
    };

    onSelectedSearchItem(selectionable: TExtendedParticipant): void {
        // this.selected.add(selectionable);
    }

    onRemoveSearchItem(selectionable: TExtendedParticipant): void {
        // this.selected.delete(selectionable);
    }
    //#endregion

    //#region RoleSelector
    private roleSelectorModalHandler: CmModalHandler;

    @Memoize()
    canShowMoreOptions(): boolean {
        return this.canShowRolesBtn() || this.canShowRemoveBtn();
    }

    canShowRemoveBtn(participant?: Avatar): boolean {
        return (
            this.participantSelectorHandler.hasRemove() &&
            this.canRemoveUser(participant)
        );
    }

    canRemoveUser(participant?: TExtendedParticipant): boolean {
        if (
            !this.participantSelectorHandler.getGroupParticipantsControl() ||
            this.participantSelectorHandler.getGroupParticipantsControl()
                .isNewGroup ||
            this.participantSelectorHandler
                .getGroupParticipantsControl()
                .newParticipants.includes(participant)
        ) {
            return true;
        }

        if (
            this.participantSelectorHandler.getGroupParticipantsControl() &&
            this.participantSelectorHandler.getGroupParticipantsControl()
                .selectionType == ESelectionType.groupSelector
        ) {
            if (participant.getObjectTypeID() === constant.objectType.avatar) {
                return Role.canRemoveUser(
                    this.getGrantor(),
                    this.getGrantee(<Avatar>participant),
                    this.sessionSvc.getAvatarApproverID()
                );
            } else {
                return hasRoleIDToBellongToGroup(this.getGrantor().roles);
            }
        }

        return true;
    }

    getGrantor(): ISecurityRoleChanges {
        return {
            idAvatar: this.sessionSvc.getAvatarID(),
            roles: this.participantSelectorHandler.getParticipantRoles()
                .grantorParticipantRoles,
        };
    }

    getGrantee(participant: Avatar): ISecurityRoleChanges {
        const participantRole = this.participantSelectorHandler
            .getParticipantRoles()
            .allParticipantRoles.find(
                (participantRole) =>
                    participantRole.idAvatar == participant.getPrimaryID()
            );

        return {
            idAvatar: participant.getAvatarID(),
            roles: participantRole ? participantRole.idRoles : null,
        };
    }

    canShowRolesBtn(participant?: Avatar): boolean {
        if (
            this.participantSelectorHandler.getGroupParticipantsControl() &&
            this.participantSelectorHandler.getGroupParticipantsControl()
                .selectionType === ESelectionType.groupSelector
        ) {
            const isNewParticipant = this.participantSelectorHandler
                .getGroupParticipantsControl()
                .newParticipants.includes(participant);
            return (
                this.participantSelectorHandler.hasRoles() && !isNewParticipant
            );
        }

        return false;
    }

    getRoleLabel(): string {
        return ObjectType.staticFactory(constant.objectType.role).getName();
    }

    public openDialogForParticipantRolesEdition(
        participant: TExtendedParticipant
    ) {
        console.log({ participant });
    }

    private newRoleSelectorHandler(
        open: boolean = false,
        participant: TExtendedParticipant
    ): CmModalHandler {
        return this.handlerFactorySvc.getCmModalHandler(
            open,
            ParticipantRoleSelectorComponent,
            this.handlerFactorySvc.newParticipantRoleSelectorHandler({
                participant,
                groupId: this.participantSelectorHandler.getGroupId(),
                clientInstance: this,
                participantRoles:
                    this.participantSelectorHandler.getParticipantRoles(),
            })
        );
    }

    onWindowClosed(): void {
        this.closeRoleSelectorModal();
        this.cdRef.markForCheck();
    }

    onRolesSelectedChange(
        participantToRoles: IParticipantSelectorToRolesSelected
    ) {
        return this.participantSelectorHandler.onRolesSelectedChange(
            participantToRoles
        );
    }

    hasRemove(): boolean {
        return this.participantSelectorHandler.hasRoles();
    }

    hasRoles(): boolean {
        return this.participantSelectorHandler.hasRemove();
    }

    openRoleSelectorModal(participant: TExtendedParticipant): void {
        this.participantRoleModal = this.dialog.open(
            ParticipantRoleSelectorComponent,
            {
                minWidth: "300px",
                maxWidth: "50vw",
                data: {
                    handler:
                        this.handlerFactorySvc.newParticipantRoleSelectorHandler(
                            {
                                participant,
                                groupId:
                                    this.participantSelectorHandler.getGroupId(),
                                clientInstance: this,
                                participantRoles:
                                    this.participantSelectorHandler.getParticipantRoles(),
                            }
                        ),
                },
            }
        );
    }

    closeRoleSelectorModal(): void {
        this.participantRoleModal.close();
    }
    //#endregion
}
