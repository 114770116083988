import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {SubscriptionSignal} from "../../model/signal/subscription-signal";
import {MainSignal} from "../../model/signal/main-signal";
import {
    TChainedListener,
    TChangeStateListener,
    TInteractionListener,
    TSubscriptionListener
} from "../../model/signal/ps-interfaces";
import {hwConfig} from "../hardware";
import {IInterfaceInfoSignal, InterfaceInfoSignal} from "../../model/signal/interface-signal";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {ChainedInteractionSignal} from "../../model/signal/state-signals/chained-signal";
import {InteractionSignal} from "../../model/signal/state-signals/interaction-signal";
import {SecurityContextSignal} from "../../model/signal/state-signals/sec-contex-signal";
import {GroupSubscription} from "../../model/group-subscription.model";
import {IDataBuilder} from "../controllers-services/security-controller/safe-menu-builder";
import { Location } from "@angular/common";


@Injectable({
    providedIn: 'root'
})
export class SignalPublisherService {

    private subscriptionBehaviour: BehaviorSubject<SubscriptionSignal> = new BehaviorSubject(null);
    private genericStateBehaviour: BehaviorSubject<MainSignal> = new BehaviorSubject(null);
    private genericStateSubject: Subject<MainSignal> = new Subject();
    private chainedSubject: Subject<ChainedInteractionSignal> = new Subject();
    private interactionSubject: Subject<InteractionSignal> = new Subject();

    // Listeners & Streams
    private subscriptionStream: TSubscriptionListener = this.subscriptionBehaviour.asObservable();
    private genericStream: TChangeStateListener = this.genericStateSubject.asObservable();
    private genericStreamBehaviour: TChangeStateListener = this.genericStateBehaviour.asObservable();
    private chainedStream: TChainedListener = this.chainedSubject.asObservable();
    private interactionStream: TInteractionListener = this.interactionSubject.asObservable();

    constructor (
        private location: Location,
    ) {
        if ((hwConfig.cordova in window)) {
            window['signalPublisher'] = this;
        }
    };

    public _getSubscriptionStream(): TSubscriptionListener { return this.subscriptionStream; };
    public _getGenericStateStream(): TChangeStateListener {return this.genericStream; };
    public _getGenericStateStreamBehaviour(): TChangeStateListener {return this.genericStreamBehaviour; };
    public _getChainedInteractionStream(): TChainedListener {return this.chainedStream; };
    public _getInteractionStream(): TInteractionListener {return this.interactionStream; };

	public specificSignalEmissorOnGenericStream(change: MainSignal): void {
		this.genericStateSubject.next(change);
    };

    public specificSignalEmissorOnGenericStreamBehaviour(change: MainSignal): void {
		this.genericStateBehaviour.next(change);
    };

    // Interactions
    public chainedInteractionEmissor(chained: ChainedInteractionSignal): void {
        this.chainedSubject.next(chained);
    };


    public interactionEmissor(interaction: InteractionSignal): void {
        this.interactionSubject.next(interaction);
    };

    // Security
    public securityContextChanged(security: SecurityContextSignal): void {
        this.genericStateSubject.next(security);
    }

     // Susbscription
    public susbcriptionEmissor(modelSubscription: GroupSubscription, dataMenuBuilder: IDataBuilder): void {
        const signal: SubscriptionSignal = new SubscriptionSignal(dataMenuBuilder.currentAvatar,
                                                                    modelSubscription,
                                                                    dataMenuBuilder);
		this.subscriptionBehaviour.next(signal);
    };

    public emitUserExclusiveAction(uniqueID: TGlobalUID) {
        this.specificSignalEmissorOnGenericStream(new InterfaceInfoSignal({
            uniqueID,
            exclusiveAction: true
        }));
    };


    public emitInterfaceSiginal(signal: IInterfaceInfoSignal): void {
        this.specificSignalEmissorOnGenericStream(new InterfaceInfoSignal(signal));
    };
};
