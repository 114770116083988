<div class="tools-container">
  <div class="items">
    <ng-container *ngFor="let hexagonHandler of hexagons">
      <a
        class="item"
        *ngIf="showHexagon(hexagonHandler)"
        [serializable]="hexagonHandler.getEditingSerializable().getPrimaryID()"
        (click)="onItemClicked($event, section, hexagonHandler)"
        [attr.href]="getItemURL(section, hexagonHandler)"
      >
        <hexagon [hexagonHandler]="hexagonHandler"> </hexagon>
        <h4>
          {{ hexagonHandler.getEditingSerializable().getName() }}
        </h4>
      </a>
    </ng-container>
  </div>
</div>
