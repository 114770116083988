import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { EHexagonUploaderMode, HexagonUploaderClient } from "./hexagon-uploader.model";
import { EMimeTypes, onlyImageAllowed } from "@colmeia/core/src/multi-media/file-interfaces";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/multi-media-instance";
import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { IMultimediaComponentCallback, MultimediaHandler } from "../../../handlers/multimedia-handler";
import { MultimediaService } from "../../../services/multimedia.service";
import { ClientTransactService, IRollbackTransaction, rollbackType } from "../../../services/client.transact.service";
import { MultimediaInstanceUploaderComponent } from "../multimedia-instance-uploader/multimedia-instance-uploader.component";
import { HexagonUploaderHandler } from 'app/handlers/hexagon-uploader-handler';
import { isValidArray, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { getFilesFromDataTransfer } from 'app/model/client-utility';

@Component({
    selector: 'app-hexagon-uploader',
    templateUrl: './hexagon-uploader.component.html',
    styleUrls: ['./hexagon-uploader.component.scss']
})
export class HexagonUploaderComponent implements OnInit, HexagonUploaderClient, IMultimediaComponentCallback {

    private _handler: HexagonUploaderHandler;
    public multimediaHandler: MultimediaHandler;

    @ViewChild('uploader', { static: true }) uploader: MultimediaInstanceUploaderComponent;

    constructor(
        private multimediaServices: MultimediaService,
        private cdr: ChangeDetectorRef,
        private transactService: ClientTransactService
    ) { }

    ngOnInit() { }

    @Input()
    set handler(value: HexagonUploaderHandler) {
        this._handler = value;
        this._handler.setClientInstance(this);
        this.multimediaHandler = new MultimediaHandler({
            idPlayer: null,
            idAvatar: null,
            generateHashNow: true,
            idMultimediaTag: value.idTag,
            mimeTypeFilter: value.mimeType ?? onlyImageAllowed,
            clientCallback: this,
            maxNumberOfFiles: 1,
            multimediaService: this.multimediaServices,
            onFileSelectionCallback: (fileSelection) => {
                if (isValidString(this.forceMimeType)) {
                    fileSelection.forEach(file => file.getClientCachedFile().setMimeType(this.forceMimeType));
                }
                return fileSelection;
            },
        });
    }

    @Input()
    forceMimeType: EMimeTypes | undefined;

    createRollback(multimediaObject: MultimediaObject): IRollbackTransaction {
        return this.transactService.getRollbackTransaction(multimediaObject, rollbackType.multimediaObject);
    }

    rollback(rollback: IRollbackTransaction) {
        this.transactService.rollback(rollback);
    }


    onClearMultimedia(): void {
    }

    onMultimediaSelected(fileSelection: TMultimediaInstanceArray): void {
        if (fileSelection.length === 0) {
            return;
        }

        this.handler.onMediaUploaded(fileSelection[0]);
    }

    get handler(): HexagonUploaderHandler {
        return this._handler;
    }

    upload(): void {
        if (!this.canUpload) return;

        this.uploader.onFileUploadClicked();
    }

    onDrop(ev: DragEvent) {
        if (!this.canUpload) return;

        ev.stopPropagation();
        ev.preventDefault();

        const { dataTransfer } = ev;
        const files = getFilesFromDataTransfer(dataTransfer);

        if (!isValidArray(files)) return;

        this.uploader.handleFileChange(files);
    }

    onDragOver(event: DragEvent) {
        if (!this.canUpload) return;

        event.stopPropagation();
        event.preventDefault();
    }

    delete(): void {
        this.handler.onDeleteClick();
    }

    get canUpload(): boolean {
        return (!this.handler.hasValidMedia) && this.handler.getMode() === EHexagonUploaderMode.Upload;
    }

    get canDelete(): boolean {
        return (this.handler.hasValidMedia) && this.handler.getMode() === EHexagonUploaderMode.Upload;
    }

    markForCheck(): void {
        this.cdr.markForCheck();
    }

    shouldHideBorder(): boolean {
        return (
            this.handler.isFileMode() &&
            isValidRef(this.multimediaHandler.getFileFileSelectionInformation()) &&
            !!this.multimediaHandler.getFileFileSelectionInformation().length
        );
    }

    updateMimeTypeFilters(clearFile = false) {
        const mimeTypes = this.handler.mimeType ?? onlyImageAllowed;

        this.multimediaHandler.getComponentParameter().mimeTypeFilter = mimeTypes;
        this.uploader.initAcceptedTypes();

        if (clearFile && this.canDelete) {
            this.delete();
        }
    }
}
