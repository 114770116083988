import { defineOperator, EDefinedOperatorType, IBasicOperatorValue, IDefinedOperatorInput, TLocalPredicate } from "@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service";

export class DSOperators {
    static eq = defineOperator({
        operator: '=',
    });
    static lt = defineOperator({
        operator: '<',
    });
    static lte = defineOperator({
        operator: '<=',
    });
    static gte = defineOperator({
        operator: '>=',
    });
    static gt = defineOperator({
        operator: '>',
    });

    static searchText = defineOperator({
        type: EDefinedOperatorType.SearchText,
        operator: '=',
    });

    static ne = defineOperator({
        operator: '!=',
    });

    static different = DSOperators.ne;

    static in = defineOperator<true>({
        operator: 'IN',
    });

    static notIn = defineOperator<true>({
        operator: 'NOT_IN',
    });

    static asc = IDefinedOperatorInput.addOrder(1);
    static desc = IDefinedOperatorInput.addOrder(-1);

    static order(number: number) {
        return IDefinedOperatorInput.addOrder(number);
    }
}
