import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { omitBy, isNil } from "lodash";
import { Serializable } from "../../business/serializable";
import { isValidArray, isValidRef, typedClone } from "../../tools/utility";
import { IBasicField, ISerializableTextWithPrimaryId, ISerializableYAML, IVEScreenGroup } from "./visual-element.types";

export function serializableToYAML(primaryID: string): ISerializableYAML {
    const serializable = Serializable.staticFactory(primaryID)
    return serializableInstanceToYAML(serializable);
}

export function safeSerializableToYAML(primaryID: string): ISerializableYAML | undefined {
    try {
        return serializableToYAML(primaryID);
    } catch (err) {
        return;
    }
}

export function serializableInstanceToYAML(serializable: Serializable): ISerializableYAML {
    const result: ISerializableYAML = translationsToSerializableYAML(getSerializableTextWithPrimaryID(serializable))[0]
    if (isValidArray(serializable.screenGroup)) {
        result.screenGroups = serializable.screenGroup
            .map(({ screenGroup, idMenu }) => ({ screenGroup, idMenu }))
            .map(field => omitBy(field, isNil) as IVEScreenGroup)
        ;
    }
    if (isValidRef(serializable.getAngularRouteID())) {
        result.route = serializable.getAngularRouteID();
    }
    
    result.fields = result.fields.sort((field, otherField) => field.id - otherField.id);

    return result;
}


export function getSerializableTextWithPrimaryID(serializable: Serializable): ISerializableTextWithPrimaryId[] {
    const primaryID: string = serializable.getPrimaryID();
    const translations: ISerializableTextWithPrimaryId[] = serializable.getSerializableTextArray().map(item => ({ ...item, primaryID }))
    return translations;
}

export function translationsToSerializableYAML(translations: ISerializableTextWithPrimaryId[]): ISerializableYAML[] {
    const map: Map<string, ISerializableYAML> = new Map()

    for (const translation of typedClone(translations)) {
        if (!map.has(translation.primaryID)) map.set(translation.primaryID, { primaryID: translation.primaryID, fields: [] })
        map.get(translation.primaryID).fields.push(translationToField(translation, false))
    }

    return [...map.values()]
}

export function translationToField(translation: ISerializableTextWithPrimaryId, shouldAddPrimaryID: boolean): IBasicField {
    const field: IBasicField = { id: translation.idField, text: translation.text };
    if (shouldAddPrimaryID) {
        field.primaryID = translation.primaryID;
    }
    return field;
}
export class SerializableYAML {


    static translationsToYAMLConfig(translations: ITranslationConfig[]): ISerializableYAML[] {
        const map: Map<string, ISerializableYAML> = new Map()

        for (const translation of translations) {
            if (!MetaTranslation.isField(translation.idField)) continue;
            if (Serializable.getTranslation(translation, false)) continue;

            if (!map.has(translation.serializableId)) map.set(translation.serializableId, { primaryID: translation.serializableId, fields: [] })
            map.get(translation.serializableId)!.fields.push({ id: +translation.idField, text: translation.idField.text })
        }

        return [...map.values()];
    }


    static translationsToYAML(translations) {
        const map = new Map()

        for (const translation of translations) {
          if (!map.has(translation.primaryID)) map.set(translation.primaryID, { primaryID: translation.primaryID, fields: [] })
          map.get(translation.primaryID).fields.push({ id: translation.idField, text: translation.text })
        }

        return SerializableYAML.normalizeYAML([...map.values()].map(toYAML).join('\n\n'))

        function toYAML(element) {
          const map = new Map();
          for (const field of element.fields) map.set(field.id, field);
          const fields = [...map.values()].sort((item, otherItem) => item.id - otherItem.id);
          return `
        - primaryID: ${element.primaryID}
        fields: ${fields.map(field =>`
          - id: ${field.id}
            text: ${field.text}`).join('')}
`
        }
      }

      static normalizeYAML(yaml: string): string {
          return yaml.replace(/\n        /g, '\n').replace(/\n/g, '\n ').replace('  -', '-')
      }
      static serializableToYAML(primaryID: string) {
          const serializable: Serializable = Serializable.staticFactory(primaryID)
          const translations = serializable.getSerializableTextArray().map(item => ({ ...item, primaryID }))

          let yaml: string = SerializableYAML.translationsToYAML(translations)

          if (serializable.screenGroup?.length) yaml +=
`        screenGroups:${serializable.screenGroup.map(screenGroup => `
          - screenGroup: ${screenGroup.screenGroup}${SerializableYAML.printIdMenu(screenGroup.idMenu)}`).join('')}`

          if (serializable.getAngularRouteID()) yaml += `
         route: ${serializable.getAngularRouteID()}
`

          return (SerializableYAML.normalizeYAML(yaml))
      }
      static printIdMenu(idMenu: string) {
        return idMenu ? `\n            idMenu: ${idMenu}` : '';
      }
}