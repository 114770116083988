
// Graph Element
// Host <- 
// Object

import { Explicit } from "../../../../tools/utility/types";
import { BasicElement } from "../../../graph/essential/basic-element";
import { IBasiGenericHostFactoryInfo } from "../ns/ns.host.types";


export enum EHandleHostUpdateAction {
    delete = 'delete',
    save = 'save',
}

export type THandleHostUpdateActions = Explicit<{ [key in EHandleHostUpdateAction]?: any[] }, {
    [EHandleHostUpdateAction.delete]?: BasicElement[];
    [EHandleHostUpdateAction.save]?: BasicElement[];
}>;

export interface IBotRootFactoryInfo extends IBasiGenericHostFactoryInfo {
}

export interface IMenuContainerFactoryInfo extends IBasiGenericHostFactoryInfo {
}

export interface IMenuItemFactoryInfo extends IBasiGenericHostFactoryInfo {
}

export interface ITransactionFactoryInfo extends IBasiGenericHostFactoryInfo {
}