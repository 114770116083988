
import { EAllSerializable, getTranslationsFieldsAndScreenOptions } from "../all-serializables";

// 
const config = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.Tag,
    fields: {
        noTags: 1, // tagPickerNavigable 19
        toShow: 2, // tagPickerNavigable 20
        byGroup: 3, // NonSerializableTags 36
        parent: 4, // NonSerializableTags 23
        createTitle: 5, // NonSerializableTags 20
        editTitle: 6, // NonSerializableTags 27
        basicData: 7, // NonSerializableTags 32
        children: 8, // NonSerializableTags 33
        addChild: 9, // NonSerializableTags 34
        createTag: 10, // NonSerializableTags 20
        errorLoadingTags: 11, // NonSerializableTags 25
    },
} as const);

export const tagTranslations = config.translations;
export const tagScreenOptions = config.screenOptions;