import { GroupOrderSelectorShortcutComponent } from 'app/components/chat/group-order-selector-shortcut/group-order-selector-shortcut.component';
import { MainHandler } from './main-handler';
import { IComponentParameter } from 'app/model/component-comm/basic';
import { EGroupOrderSelectorMode } from '@colmeia/core/src/shared-business-rules/const-text/views/group-order-selector-shortcut';
import { defaultFields } from '@colmeia/core/src/tools/utility';

export enum EGroupOrderSelectorModeOption {
    recent = 'recent',
    agent = 'agent',
}

export type TGroupOrderSelectorModeConfig = { [key in EGroupOrderSelectorModeOption]?: boolean };

export const defaultGroupOrderSelectorModeConfig: { [key in EGroupOrderSelectorMode]: TGroupOrderSelectorModeConfig } = {
    [EGroupOrderSelectorMode.timeWithoutAnswer]: {
        [EGroupOrderSelectorModeOption.recent]: true,
        [EGroupOrderSelectorModeOption.agent]: true,
    },
    [EGroupOrderSelectorMode.lastMessage]: {
        [EGroupOrderSelectorModeOption.recent]: true,
    },
    [EGroupOrderSelectorMode.startServiceChat]: {
        [EGroupOrderSelectorModeOption.recent]: true,
    },
    [EGroupOrderSelectorMode.alphabeticOrder]: {
        [EGroupOrderSelectorModeOption.recent]: true,
    }
}

export const groupOrderSelectorModeDB: { [key in EGroupOrderSelectorMode]: { [key in EGroupOrderSelectorModeOption]?: true } } = {
    [EGroupOrderSelectorMode.timeWithoutAnswer]: {
        [EGroupOrderSelectorModeOption.recent]: true,
        [EGroupOrderSelectorModeOption.agent]: true,
    },
    [EGroupOrderSelectorMode.lastMessage]: {
        [EGroupOrderSelectorModeOption.recent]: true,
    },
    [EGroupOrderSelectorMode.startServiceChat]: {
        [EGroupOrderSelectorModeOption.recent]: true,
    },
    [EGroupOrderSelectorMode.alphabeticOrder]: {
        [EGroupOrderSelectorModeOption.recent]: true
    },
}

export interface IGroupOrderSelectorShortcutClientCallback {
    onGroupOrderSelectorChange(): void;
}
export interface IGroupOrderSelectorShortcutHandlerParameter extends IComponentParameter {
    selected?: EGroupOrderSelectorMode;
    config?: TGroupOrderSelectorModeConfig;
    clientCallback: IGroupOrderSelectorShortcutClientCallback;
}

export class GroupOrderSelectorShortcutHandler extends MainHandler {

    private slave: GroupOrderSelectorShortcutComponent;

    constructor(parameter: IGroupOrderSelectorShortcutHandlerParameter) {
        super(parameter);
        const selected: EGroupOrderSelectorMode = EGroupOrderSelectorMode.lastMessage;
        defaultFields(parameter, {
            selected,
            config: groupOrderSelectorModeDB[selected],
        });
    }

    public setSlave(slave: GroupOrderSelectorShortcutComponent) {
        this.slave = slave;
    }

    public getSlave(): GroupOrderSelectorShortcutComponent {
        return this.slave
    }
    
    public getComponentParameter(): IGroupOrderSelectorShortcutHandlerParameter {
        return super.getComponentParameter() as IGroupOrderSelectorShortcutHandlerParameter;
    }

}
