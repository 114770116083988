import { Component, Input, OnInit } from '@angular/core';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { RootComponent } from 'app/components/foundation/root/root.component';

export interface IHelpTipHandler {
  message: string,
  link: string
}

@Component({
  selector: 'help-tip',
  templateUrl: './help-tip.component.html',
  styleUrls: ['./help-tip.component.scss']
})
export class HelpTipComponent extends RootComponent<'documentationLink'> implements OnInit {
  @Input()
  handler: IHelpTipHandler;

  constructor() {
    super({
      documentationLink: gTranslations.helpTips.documentationLink,
    })
  }

  ngOnInit(): void { }

}
