import {constant,TGlobalUID} from './constant';
import {Group} from './group';
import {Avatar} from './avatar';
import {permission} from '../core-constants/security-constant';
import {Serializable} from './serializable';
import {IParticipantJSON} from '../comm-interfaces/business-interfaces';
import {UberCache} from '../persistency/uber-cache';
import { isValidArray } from '../tools/utility';
import { TRoleArray } from '../security/role';
import { hasPermissionInRoles, staticFactoryRoles } from '../rules/sec-functions';
import { Permission } from '../security/permission';

export type TParticipantArray = Array<Participant>;

export class Participant extends Serializable {
    private roleParticipant: TRoleArray;
    private avatar: Avatar;
    private group: Group;
    private proxyParticipant: boolean;
    private spokePersonalGroupParticipant: boolean;
    private idGroup: TGlobalUID;

    private constructor (group: Group, avatar: Avatar, idParticipant :TGlobalUID) {
        super(constant.objectType.participant, idParticipant)
        this.avatar = avatar;
        this.group = group;
    };

    public toJSON(): IParticipantJSON {
        const json: IParticipantJSON = {
            ...super.toJSON(),
            avatar: this.avatar.toJSON(),
            idGroup: this.idGroup,
            idRoles: isValidArray(this.roleParticipant) ? this.roleParticipant.map((r) => {return r.getRoleID()}) : null, // proxy pode subir sem role
            spokePersonalGroupParticipant: this.spokePersonalGroupParticipant,
            idAvatar: this.avatar.getAvatarID(),
        };
      
        return json;
    };

    public hasPrivilege(perm: Permission): boolean {
        return hasPermissionInRoles(this.roleParticipant, perm.getPermissionID());
    }

    public isAdmin(): boolean {return hasPermissionInRoles(this.roleParticipant, permission.hasAdminPrivilege);};

    public isProxy(): boolean {return this.proxyParticipant;};
    public isSpokePersonalGroupParticipant(): boolean {return this.spokePersonalGroupParticipant};
    


    public rehydrate(json: IParticipantJSON): void {
        super.rehydrate(json);
        this.proxyParticipant = json.proxy;
        this.spokePersonalGroupParticipant = json.spokePersonalGroupParticipant;
        this.idGroup = json.idGroup;
        if (this.getGroup() && this.getGroup().isNot(this.idGroup)) {
            this.group = <Group>UberCache.unsafeUberFactory(this.idGroup);
        };
        
        if (!this.proxyParticipant)
            this.roleParticipant = staticFactoryRoles(json.idRoles);
    };

    public getParticipantID(): TGlobalUID {return super.getPrimaryID()};
    public getAvatar(): Avatar {return this.avatar;};
    public getGroup(): Group {return this.group;};
    public setGroup(group: Group): void {this.group = group;};
    public getJoinDate(): Date {return super.getDate()};

    public getGroupID(): TGlobalUID {return this.idGroup; };
    public getName(): string {return this.avatar.getName();}

    public static staticFactory(idParticipant: TGlobalUID): Participant {
        return <Participant>UberCache.uberFactory(idParticipant, constant.objectType.participant, true)
    };

    public static factoryMessage(json: IParticipantJSON): Participant {

        let participant: Participant = <Participant>UberCache.uberFactory(json.primaryID, constant.objectType.participant, false);
        if (participant == null) {
            const group = UberCache.testCache(json.idGroup) ? Group.staticFactory(json.idGroup) : null
            participant = new Participant( group,
                                            Avatar.staticFactory(json.avatar.primaryID),
                                            json.primaryID);
        };
        
        participant.rehydrate(json);
        return participant;
    };

    public static factoryNoCache(json: IParticipantJSON, avatar: Avatar): Participant {
        let participant = new Participant(null, avatar, json.primaryID);
        return participant;
    };

     
};
