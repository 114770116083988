import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSelectionList } from "@angular/material/list";
import { safeParseJSON } from "@colmeia/core/src/shared-business-rules/connections/connections-functions";
import { isValidString, safeStringifyJSON } from "@colmeia/core/src/tools/utility";
import { isNewVar, TParamVariable } from "../params-editor/mat-quill-utility";

enum EVarHint {
    emptySpaces = "Coloque um nome válido",
    duplicatedVar = "Já existe uma variável com esse nome",
}

type TVarError = EVarHint | undefined;

@Component({
    selector: "var-inserter-dialog",
    templateUrl: "./var-inserter-dialog.component.html",
    styleUrls: ["./var-inserter-dialog.component.scss"],
})
export class VarInserterDialogComponent implements OnInit {
    @Input() paramsVariables: TParamVariable[];
    get _paramVariables() {
        return this.paramsVariables
    }
    filteredParamsVariables: TParamVariable[] | undefined;

    hint: TVarError;

    @Output() varToInsert: EventEmitter<TParamVariable> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    public addTokenValueByInput($input: HTMLInputElement) {
        const emptySpaces = $input.value
            .split("")
            .every((char) => char === " ");
        if (emptySpaces || !isValidString($input.value)) {
            this.hint = EVarHint.emptySpaces;
            return;
        }

        if (!isNewVar($input.value, this.paramsVariables)) {
            this.hint = EVarHint.duplicatedVar;
            return;
        }
        this.hint = undefined;

        const value = ($input.value || "").trim();
        const newVar = { id: "${" + value + "}", value: value };
        this.varToInsert.emit(newVar);

        $input.value = "";

    }
    

    public filterVariables(input: HTMLInputElement): void {
        const searchTerm = input.value.trim();
        const validValue = searchTerm.replace(/[\s{}]/g, "");
        input.value = validValue;
        this.hint = undefined;

        this.filteredParamsVariables = [];
        this.filteredParamsVariables = this.paramsVariables.filter(
            (variable) => {
                return variable.value.includes(searchTerm);
            }
        );
    }

    public insertVariable(matSelectionList: MatSelectionList): void {
        const value = safeParseJSON(safeStringifyJSON(matSelectionList._value?.[0])) as TParamVariable;
        this.varToInsert.emit(value);
    }
}
