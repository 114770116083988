import { TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/multi-media-instance";
import { MultimediaObject } from "@colmeia/core/src/multi-media/multi-media-object";
import { Observable, ReplaySubject } from "rxjs";
import { IMultimediaClientParameter, IMultimediaComponentCallback, MultimediaHandler } from "./multimedia-handler";

export interface IAttachBarClientParameter extends IMultimediaClientParameter {
    uploadWindowOpen?: boolean;
 };

export interface IAttachBarComponentCallback extends IMultimediaComponentCallback { };

export interface IForceUploader {
    forceUpload(fileList: FileList): void;
    onMultimediaSelected(mmFiles: TMultimediaInstanceArray): void;
    setMMObject(mmObject: MultimediaObject): void;
}

export class AttachBarHandler extends MultimediaHandler {

    private component: IForceUploader;
    private uploadWindowOpen?: boolean;

    private _component$: ReplaySubject<IForceUploader> = new ReplaySubject(1);

    public component$(): Observable<IForceUploader> {
        return this._component$.asObservable();
    }

    constructor(attachParameter: IAttachBarClientParameter, uploadWindowOpen: boolean = false) {
        super(<IMultimediaClientParameter>attachParameter);
        this.setUploadWindowOpen(uploadWindowOpen);
    };

    public onMultimediaSelected(fileSelection: TMultimediaInstanceArray): void {
        const parameter: IAttachBarClientParameter = this.getComponentParameter();
        parameter.clientCallback.onMultimediaSelected(fileSelection);
    }

    public forceUpload(files: FileList): void{
        this.component.forceUpload(files);
    }

    // public onMultimediaSelectedComponent(mmFiles: TMultimediaInstanceArray) {
    //     this.component.onMultimediaSelected(mmFiles);
    // }

    public setComponent(component: IForceUploader): void {
        this.component = component;
        this._component$.next(component);
    }

    public setUploadWindowOpen(uploadWindowOpen: boolean): void {
        this.uploadWindowOpen = uploadWindowOpen;
    }

    public isUploadWindowOpen() {
        return this.uploadWindowOpen;
    }

};
