<ng-container *ngIf="nonSerializables; else spinner">
  <mat-form-field class="ns-select-container">
    <mat-label>{{ handler.title }}</mat-label>
    <mat-select [value]="handler.selectedNS" (selectionChange)="onNSSelect($event)" [compareWith]="compareNSIds">
      <mat-option>---</mat-option>
      <mat-option *ngFor="let ns of nonSerializables" [value]="ns">
        <div class="ns-option">
          <img class="ns-option--image" [src]="getMediaUrl(ns) | imgSecureDownload | async" *ngIf="ns.idMedia">
          <mat-icon class="ns-option--image mat-42" *ngIf="!ns.idMedia">image</mat-icon>
          <span>{{ ns.name }}</span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

<ng-template #spinner>
  <cm-spinner-component></cm-spinner-component>
</ng-template>