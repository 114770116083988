/**
 * Auto cast by inference 
 * @example
 * const input: string = empty;
 * const text: 'novo' = cast(input);
 * @example
 * function getText(): 'novo' {
 *     const input: string = empty;
 *     return cast(input);
 * }
 */
 export function cast<T, R extends T>(source: T): R
 export function cast<T>(source: T): T {
     return source;
 }