<app-modal-body [title]="getTitle()" (modalClosed)="onModalClosed()">
  <ul class="msg__container">
    <li class="msg__item" *ngFor="let share of geSharesList()">
      <app-date-header [date]="share.dateTimestamp"></app-date-header>
      <div class="avatar__container">
        <hexagon [hexagonHandler]="share.hexHandler"></hexagon>
        <p class="avatar__name">{{ share.userName }}</p>
      </div>
    </li>
  </ul>
</app-modal-body>
