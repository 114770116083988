import { Serializable } from "../../business/serializable";
import { TGlobalUID } from "../../core-constants/types";
import { constant } from "../../business/constant";
import { ISubscriptionTypeJSON } from "../../comm-interfaces/business-interfaces";
import { UberCache } from "../../persistency/uber-cache";
import {isThisOneOfThat} from "../../tools/utility";

export type TSubscriptionTypeArray = Array<SubscriptionType>;

export function onlyDevelopedAvatarSubscriptions(sub: SubscriptionType): boolean {
    return isThisOneOfThat(
        sub.getPrimaryID(),
        constant.subscription.request.avatar.realTime,
        constant.subscription.request.avatar.teamMembershipRequest
    );
}

export function onlyDevelopedGroupSubscriptions(sub: SubscriptionType): boolean {
    return isThisOneOfThat(
        sub.getPrimaryID(),
        constant.subscription.request.group.employee,
        constant.subscription.request.group.groupParticipation
    );
}

export class SubscriptionType extends Serializable {

    private follow: boolean;
    private request: boolean; 
    private response: boolean; 
    private symetric: boolean; 
    private fromSubscriberObjectType: string;
    private toSubjectObjectType: string;
    private behaviourWhenRequest: string;
    private static allSubType: TSubscriptionTypeArray = [];

    private constructor(json: ISubscriptionTypeJSON) {
        super(constant.objectType.subscriptionType, json.primaryID);
        this.follow = json.follow;
        this.request = json.request;
        this.response = json.response;
        this.symetric = json.symetric;
        this.behaviourWhenRequest = json.behaviourWhenRequest;
        this.fromSubscriberObjectType = json.fromSubscriberObjectType;
        this.toSubjectObjectType = json.toSubjectObjectType;
        SubscriptionType.allSubType.push(this);
    };

    public getSubsctiptionTypeID(): TGlobalUID {return super.getPrimaryID();};
    public isFollow(): boolean {return this.follow;};
    public isRequest(): boolean {return this.request;};
    public isResponse(): boolean {return this.response;};
    public isSymetric(): boolean {return this.symetric;};


    public isAvatarToSubject(): boolean {return this.toSubjectObjectType == 'A'};
    public isGroupToSubject(): boolean {return this.toSubjectObjectType == 'G'};
    public toNobody(): boolean {return this.toSubjectObjectType == 'N'}; 
    public toSomebody(): boolean {return this.toSubjectObjectType != 'N'};   

    public isAvatarFromSubscriber(): boolean {return this.fromSubscriberObjectType == 'A'};
    public isGroupFromSubscriber(): boolean {return this.fromSubscriberObjectType == 'G'};

    public isAutomaticApproval(): boolean {return this.behaviourWhenRequest == 'A'};
    public isRequestedNeeded(): boolean {return this.behaviourWhenRequest == 'R';};

    public isFollowable(): boolean {
        return this.is(constant.subscription.request.dottedTeamMembership,
            constant.subscription.request.avatar.teamMembershipRequest,
            constant.subscription.request.friendship)
    }


    public static getSubscriptionTypeArray(): TSubscriptionTypeArray {
        return SubscriptionType.allSubType;
    };

    public static getFollowable(): TSubscriptionTypeArray {
        return SubscriptionType.getSubscriptionTypeArray();
    };

    public static getAvatarFollowOptions(): TSubscriptionTypeArray {
        // return SubscriptionType.allSubType.filter((s) => {return s.isAvatarToSubject()});
        return SubscriptionType.allSubType.filter(onlyDevelopedAvatarSubscriptions);
    };

    public static getGroupFollowOptions(): TSubscriptionTypeArray {
        // return SubscriptionType.allSubType.filter((s) => {return s.isGroupToSubject()});
        return SubscriptionType.allSubType.filter(onlyDevelopedGroupSubscriptions);
    };

    public toJSON(): ISubscriptionTypeJSON {
        return  {
            ...super.toJSON(), 
            follow: this.follow,
            request: this.request,
            response: this.response,
            symetric: this.symetric,
            behaviourWhenRequest: this.behaviourWhenRequest,
            fromSubscriberObjectType: this.fromSubscriberObjectType,
            toSubjectObjectType: this.toSubjectObjectType,
        };
    };

    public static factoryMessage(json: ISubscriptionTypeJSON): SubscriptionType {
        let subscription: SubscriptionType = <SubscriptionType>UberCache.uberFactory(json.primaryID, constant.objectType.subscriptionType, false);
        if (subscription == null) {
            subscription = new SubscriptionType(json);
        };
        subscription.rehydrate(json);
        return subscription;
    };

    public static staticFactory(idSubscriptionType: TGlobalUID): SubscriptionType {
        return <SubscriptionType>UberCache.uberFactory(idSubscriptionType, constant.objectType.subscriptionType, true);
    };
}


