import { IKBTraineKnowledgeBase } from "@colmeia/core/src/shared-business-rules/knowledge-base/luis-request";
import { isInvalid, isInvalidArray, isValidArray, isValidObject, isValidString, isValidTrimmedString, keys, mapValues } from "../../tools/utility";
import { IBotActionAsset } from "../bot/bot-action-model";
import { IBotSubject } from "../bot/bot-interfaces";
import { INLPTransactionServer} from '../knowledge-base/bot-transaction/bot-transaction';
import { IKnowledgeDB, IKnowledgeDBServer, LUIS_NONE_INTENT } from "../knowledge-base/kb-inferfaces";
import { EMLLuisTrainResultType, EOmniSenseMethods, IGenerativeIntentConfig, IMLLuisIntent, IOminiSenseIAConfig, IOmniSenseIntentConfig, IOmniSenseStrategyConfig, ISemanticIntentConfig, TIOmniSenseStrategyConfigArray } from "../knowledge-base/luis-core-interfaces";



export function isValidStrategy(strategy: IOmniSenseStrategyConfig): boolean {
    return isValidString(strategy?.osTatic) && (strategy.weight > 0 && strategy.weight < 11)
}

export function isValidOminiSenseStrategyConfig(strategy: IOminiSenseIAConfig): boolean {
    return isValidArray(strategy?.strategy) && strategy.strategy.every(s => isValidStrategy(s));

}

export function isAllIntentForceBruteValid(intents: IMLLuisIntent[]): boolean {
    return intents.every(i => i.intentName.toLowerCase() === 'none' || isValidBruteForceIntentConfig(i.intentOSConfig?.botSubject));
}

export function getEnabledStrategies(strategies: TIOmniSenseStrategyConfigArray): TIOmniSenseStrategyConfigArray {
    return strategies?.filter((strategy) => strategy.isOn) ?? [];
}

export function getEnabledAndValidStrategies(strategies: TIOmniSenseStrategyConfigArray): TIOmniSenseStrategyConfigArray {
    return strategies?.filter((strategy) => strategy.isOn && isValidStrategy(strategy)) ?? [];
}

export function getAIStrategy<T extends IOmniSenseStrategyConfig>(strategy: TIOmniSenseStrategyConfigArray, tatic: EOmniSenseMethods): T {
    return strategy.find(item => item.osTatic === tatic) as T;
}

export function getAISubjectDetectionStrategy<T extends IOmniSenseStrategyConfig>(strategy: TIOmniSenseStrategyConfigArray, tatic: EOmniSenseMethods): T {
    return strategy.find(item => item.osTatic === tatic) as T;
}

/**
 *  Obtem as estratégias utilizadas (para detecção de de intenção e mudança de intenção)
 *  considerando os toggles para cada para de estratégia/intenção
 */
export function getUsedStrategies(kb: IKnowledgeDBServer): EOmniSenseMethods[] {
    // Para cada estratégia
    const enabledStrategies = new Set([
        ...getEnabledStrategies(kb.app.osStrategy.strategy).map((s) => s.osTatic),
        ...getEnabledStrategies(kb.app.intentionChangeConfig?.strategy ?? []).map((s) => s.osTatic)
    ]);

    // Para cada para intenção/estratégia
    const usedStrategies = new Set(kb.app.intents.flatMap((intent) => getIntentEnabledStrategies(intent.intentOSConfig)));

    return [...usedStrategies].filter((strategy) => enabledStrategies.has(strategy));
}

export function isIntentionChangeEnabled(kb: IKnowledgeDBServer): boolean {
    return !!kb.app.intentionChangeConfig?.enabled;
}

export function getIntentsWithoutStrategiesEnabled(kb: IKnowledgeDBServer): IMLLuisIntent[] {
    return kb.app.intents.filter((intent) => getIntentEnabledStrategies(intent.intentOSConfig).length === 0)
}

/**
 * Retorna a lista de estratégia habilitadas para uma intenção
 */
export function getIntentEnabledStrategies(intentConfig: IOmniSenseIntentConfig | undefined): EOmniSenseMethods[] {
    const strategyIsOn = getIntentStrategiesIsOn(intentConfig);
    const enabled = Object.entries(strategyIsOn)
        .filter(([_, isOn]) => isOn)
        .map(([strategy]) => strategy as EOmniSenseMethods);

    return enabled;
}

/**
 * Retorna um objeto com um valor booleano indicando se a estratégia está habilitada para uma intenção
 */
export function getIntentStrategiesIsOn(intentConfig: IOmniSenseIntentConfig) {
    const configs = getIntentStrategiesConfig(intentConfig);

    return mapValues(configs, (config) => config?.isOn);
}

/**
 *  Mapeia as configurações para cada estratégia para uma intenção
 */
export function getIntentStrategiesConfig(intentConfig: IOmniSenseIntentConfig) {
    return {
        [EOmniSenseMethods.bruteForce]: intentConfig?.botSubject,
        [EOmniSenseMethods.generative]: intentConfig?.generative,
        [EOmniSenseMethods.semanticPrediction]: intentConfig?.predictConfig,
        [EOmniSenseMethods.machineLearning]: intentConfig?.machineLearning
    }
}

export function getValidIntentStrategiesConfigs(intentConfig: IOmniSenseIntentConfig): EOmniSenseMethods[] {
    const result: EOmniSenseMethods[] = []

    if (isValidBruteForceIntentConfig(intentConfig?.botSubject)) {
        result.push(EOmniSenseMethods.bruteForce);
    }

    if (isValidGenerativeIntentConfig(intentConfig?.generative)) {
        result.push(EOmniSenseMethods.generative);
    }

    if (isValidSemanticIntentConfig(intentConfig?.predictConfig)) {
        result.push(EOmniSenseMethods.semanticPrediction);
    }

    if (intentConfig?.machineLearning?.isOn) {
        result.push(EOmniSenseMethods.machineLearning);
    }

    return result;
}


export function isValidBruteForceIntentConfig(config: IBotSubject | undefined): boolean {
    return isValidTrimmedString(config?.subject) && isValidTrimmedString(config?.action);
}

export function isValidGenerativeIntentConfig(config: IGenerativeIntentConfig | undefined): boolean {
    return isValidArray(config?.idsNSChunk) && isValidString(config?.idVector);
}

export function isValidSemanticIntentConfig(config: ISemanticIntentConfig | undefined): boolean {
    return isValidArray(config?.keywords);
}

export function isMachineLearningTrained(kb: IKnowledgeDB): boolean {
    return !isInvalid(kb.lastTrain?.at);
}

export function shouldTrainMachineLearning(kb: IKnowledgeDBServer): boolean {
    const usedStrategies = getUsedStrategies(kb);
    const utterancesModified = kb?.mustBeTrained;

    return usedStrategies.includes(EOmniSenseMethods.machineLearning) && (!isMachineLearningTrained(kb)  || utterancesModified);
}

export function hasEntityExtractorEnabled(kb: IKnowledgeDBServer) {
    return !!kb.app.extractorConfig?.llm?.isOn && hasEntityExtractor(kb);
}

export function hasEntityExtractor(kb: IKnowledgeDBServer) {
    return kb.app.intents.some((intent) => intent.extractor);
}

export function hasEmbeddingProvider(kb: IKnowledgeDBServer) {
    return !!kb.app.providers?.embedding?.idConnection;
}

export function isValidContentGeneratorSubjectVector(config: IBotActionAsset): boolean {
    return isValidObject(config?.generativeConfiguration)
        && (
            config.generativeConfiguration?.mirrorKBIntent || (
                isValidGenerativeIntentConfig(config?.generativeConfiguration?.chunks)
        )
        && isValidString(config?.generativeConfiguration?.idPrompt)
    );
}

export function getAllToBeTunnedIntent(server: INLPTransactionServer) {
    return keys(server.nlp.assets)
        .filter(intentId => {
            const items = server.nlp.assets[intentId];
            if (isInvalidArray(items)) {
                return;
            }
            return items.every(item => item.generativeConfiguration?.fineTunning);
        })
    ;
}

export function isToBeTuned(config: IBotActionAsset): boolean {
    return isValidContentGeneratorSubjectVector(config) && config.generativeConfiguration?.fineTunning;

}

export function useGenerativeContent(server: INLPTransactionServer): boolean {
    const keys = Object.keys(server.nlp.assets).filter(name => name !== LUIS_NONE_INTENT);
    const allValid = keys.filter(idI => server.nlp.assets[idI]
        .some(a => isValidContentGeneratorSubjectVector(a)));

    return isValidArray(allValid) && allValid.length === keys.length;

}

//* ----

export function isAllowToTurnStrategyIn(st: EOmniSenseMethods, intents: IMLLuisIntent[]): boolean {
    if (isInvalidArray(intents)) {
        return false;
    }

    switch (st) {
        case EOmniSenseMethods.bruteForce: {
            return isAllowToTurnBFOn(intents);
        }

        // case EOmniSenseMethods.corporateSearch: {
        //     return false;
        // }

        case EOmniSenseMethods.machineLearning: {
            return false;
        }

        case EOmniSenseMethods.generative: {
            return intents.every(intent => isValidGenerativeIntentConfig(intent.intentOSConfig?.generative));
        }

        case EOmniSenseMethods.semanticPrediction: {
            return intents.every(intent => {
                return intent.intentOSConfig?.predictConfig?.keywords
                    .every(item => isValidString(item.keyword))
                ;
            });
        }
    }

    return false;
}


export function isAllowToTurnBFOn(intents: IMLLuisIntent[]): boolean {
    return isValidArray(intents) && intents.every(intent => isValidBruteForceIntentConfig(intent.intentOSConfig?.botSubject));
}
