import { CatalogSharedService } from "./services/catalog.shared.service";
import { MenuSharedService } from "./services/menu.shared.service";
import { MockSharedService } from "./services/mock.shared.service";
import { NSSharedService } from "./services/ns.shared.service";
import { SerializableSharedService } from "./services/serializable.shared.service";
import { TemplateSharedService } from "./services/template.shared.service";
import { SocialMediaSharedService } from "./services/social-media.shared.service";
import { GenericSharedService } from "./services/generic.shared.service";
import { KBSharedService } from "./services/kb.shared.service";
import { BackupSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/backup.shared.service";



export const SharedServices = {
    MenuSharedService,
    NSSharedService,
    CatalogSharedService,
    MockSharedService,
    SerializableSharedService,
    TemplateSharedService,
    SocialMediaSharedService,
    GenericSharedService,
    KBSharedService,
    BackupSharedService,
}
