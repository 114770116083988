import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Input, Output } from '@angular/core';
import { daysToMS, getDaysHoursMinutesSeconds, hourToMS, minToMS, secToMS } from '@colmeia/core/src/time/time-utl';
import { empty, isInvalid, isValidNumber, typedCloneLodash, updateFieldSubset } from '@colmeia/core/src/tools/utility';
import { MainHandler } from 'app/handlers/main-handler';
import { $SimpleChange, HandlerMaker, OnChange } from 'app/model/client-utility';
import { ToSimpleChange } from 'app/model/client-utility-types';

export const TIME_PICKER_SEP = ':' as const;

function getValidValueFor(input: any, min: number, max: number): number {
    const value = parseInt(input);
    let correct: number;

    if(value >= min && value <= max && !isNaN(value)) {
        correct = value;
    } else {
        correct = value < min ? min : (value >= max ? max : 0);
    }

    return correct
}

export enum ETimestmapPickerElements {
    Days,
    Hours,
    Minutes,
    Seconds,
}

export type TTimestampPickerElementConfig = {
    min?: number;
    max?: number;
    suffix?: string;
}

export type TTimestampElementsConfigs = {
    [key in ETimestmapPickerElements]?: TTimestampPickerElementConfig
};

export interface IBasicTimestampPicker {
    label: string;
    time: number;
    dense?: boolean;
    disabled?: boolean;
    elements?: TTimestampElementsConfigs;
}

interface ITimestampPickerClient {
    onChangeTime?(value: number): void;
}

export interface ITimestampPickerParameter extends IBasicTimestampPicker {
    clientCallback?: ITimestampPickerClient;
}




export class TimestampPickerHandler extends MainHandler<ITimestampPickerParameter> {
    constructor(parameter: ITimestampPickerParameter, public isDefaultHandler?: boolean) {
        super(parameter, {
            elements: {
                [ETimestmapPickerElements.Hours]: {
                    min: 0,
                    max: 23
                },
                [ETimestmapPickerElements.Minutes]: {
                    min: 0,
                    max: 59
                },
            },
            disabled: false,
        })
    }
}

const elementsValuesCalcFunctions: { [key in ETimestmapPickerElements]: Function } = {
    [ETimestmapPickerElements.Days]: daysToMS,
    [ETimestmapPickerElements.Hours]: hourToMS,
    [ETimestmapPickerElements.Minutes]: minToMS,
    [ETimestmapPickerElements.Seconds]: secToMS,
}


// handler
// value = ok

// elements
// handler = ok

// time
// elements = default
// handler = ok


function initDefaultHandler() {
    const defaultHandler = new TimestampPickerHandler({
        label: empty,
        time: empty,
    }, true);
    return defaultHandler;
}

@Component({
    selector: 'app-timestamp-picker',
    templateUrl: './timestamp-picker.component.html',
    styleUrls: ['./timestamp-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimestampPickerComponent implements OnInit, OnChanges, IBasicTimestampPicker {

    @OnChange()
    @Input()
    handler: TimestampPickerHandler;

    public onChangeHandler(value: $SimpleChange<this, 'handler'>) {
        if (value.currentValue.isDefaultHandler) {
            return;
        }

        this.updateView();
        this.calculateTimestamp();

        this.cdr.markForCheck();
    }

    readonly defaultElementsSuffix: { [key in ETimestmapPickerElements]: string } = {
        [ETimestmapPickerElements.Days]: 'D',
        [ETimestmapPickerElements.Hours]: 'H',
        [ETimestmapPickerElements.Minutes]: 'M',
        [ETimestmapPickerElements.Seconds]: 'S',
    }

    private elementsValuesHash: { [key in ETimestmapPickerElements]: number } = {
        [ETimestmapPickerElements.Days]: 0,
        [ETimestmapPickerElements.Hours]: 0,
        [ETimestmapPickerElements.Minutes]: 0,
        [ETimestmapPickerElements.Seconds]: 0,
    }

    setElementValueInHash(element: ETimestmapPickerElements, value: number): void {
        if(!this.elements[element]) return;

        this.elementsValuesHash[element] = getValidValueFor(
            value,
            this.elements[element].min || 0,
            this.elements[element].max || Number.MAX_SAFE_INTEGER
        );
    }

    setElementValue(element: ETimestmapPickerElements, value: number): void {
        this.setElementValueInHash(element, value);
        this.calculateTimestamp();
    }

    private calculateTimestamp() {
        let timestamp: number = 0;

        for(const k in this.elementsValuesHash) {
            const rawValue: number = this.elementsValuesHash[k];
            const valueCalculated = elementsValuesCalcFunctions[k](rawValue);

            timestamp += valueCalculated;
        }

        if (this.handler.isDefaultHandler) {
            this.updateView();
            this.timeChange.emit(timestamp);
            return;
        }

        this.time = timestamp;
    }
    get parameters() { return this.handler.getComponentParameter() }

    @Input()
    set label(value) {
        this.initDefaultHandler();
        this.parameters.label = value;
    }
    get label() { return this.parameters.label }

    @Input()
    set elements(value) {
        this.initDefaultHandler();
        this.parameters.elements = value;
    }
    get elements() { return this.parameters.elements }


    @Input()
    set disabled(value) {
        this.initDefaultHandler();
        this.parameters.disabled = value;
    }
    get disabled() { return this.parameters.disabled }


    isFirstTimeChange: boolean = true;

    @Input()
    set time(value) {
        this.initDefaultHandler();

        if(value !== this.parameters.time) {
            this.parameters.clientCallback?.onChangeTime?.(value);
        }

        this.parameters.time = value;

        if (!this.isFirstTimeChange) {
            this.updateView();
        }

        this.isFirstTimeChange = false;
    }
    get time() { return this.parameters.time }

    @Output()
    timeChange: EventEmitter<number> = new EventEmitter();

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {

        this.updateView();
    }

    public initDefaultHandler() {
        this.handler ??= initDefaultHandler();
        this.cdr.markForCheck();
    }

    ngOnChanges(changes: ToSimpleChange<this>) {
        this.cdr.markForCheck();
    }

    updateView() {
        if(!isValidNumber(this.time, 0)) return;

        let { months, days, hours, minutes, seconds } = getDaysHoursMinutesSeconds(this.time);

        days += months * 30;

        if(ETimestmapPickerElements.Days in this.elements)
            this.setElementValueInHash(ETimestmapPickerElements.Days, days);
        else
            hours += days * 24;

        if(ETimestmapPickerElements.Hours in this.elements)
            this.setElementValueInHash(ETimestmapPickerElements.Hours, hours);
        else
            minutes += hours * 60;

        if(ETimestmapPickerElements.Minutes in this.elements)
            this.setElementValueInHash(ETimestmapPickerElements.Minutes, minutes);
        else
            seconds += minutes * 60;

        this.setElementValueInHash(ETimestmapPickerElements.Seconds, seconds);
        this.cdr.markForCheck();
    }

    changeHelpButton(element: ETimestmapPickerElements, op: 'increase' | 'decrease', afterFocus: HTMLInputElement): void {
        const isIncrease = op === 'increase';

        if(isIncrease) {
            this.setElementValue(element, this.elementsValuesHash[element] + 1);
        } else {
            this.setElementValue(element, this.elementsValuesHash[element] - 1);
        }

        afterFocus?.focus();
    }

    charsLength(value: number): number {
        return String(value).length;
    }
}
