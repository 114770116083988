<cm-generic-dashboard-home *ngIf="ghomeHandler" [handler]="ghomeHandler"></cm-generic-dashboard-home>
<ng-template #reschedule>
  <h4>
    Reagendar
  </h4>

  <cm-date-picker *ngIf="rescheduleToPicker" [handler]="rescheduleToPicker"></cm-date-picker>

  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="onReschedule()">Salvar</button>
  </mat-dialog-actions>

</ng-template>
<ng-template #viewer>
  <header>
    <div class="job-title-container">
      <span class="sup-aux">detalhes</span>
      <h1 class="job-title">{{ job.nName }}</h1>
    </div>
    <span class="job-type">
      {{ job.jobType }}
    </span>
  </header>
  <mat-divider></mat-divider>
  <section>
    <mat-form-field appearance="fill">
      <mat-label>Fase:</mat-label>
      <input matInput readonly [value]="job.phaseName" />
    </mat-form-field>

    <div class="row">
      <mat-form-field class="column" appearance="fill">
        <mat-label>Início</mat-label>
        <input matInput readonly [value]="jobStarted" />
      </mat-form-field>
      <mat-form-field class="column" appearance="fill">
        <mat-label>Fim</mat-label>
        <input matInput readonly [value]="jobEnded" />
      </mat-form-field>
    </div>

    <app-alert *ngIf="job.error" stroked hideIcon type="error"><span style="white-space: pre-wrap;">{{ job.error }}</span></app-alert>
  </section>
  <mat-dialog-actions align="end">
    <button mat-flat-button [loading]="loading" color="primary" (click)="refreshSelecedJob()">Atualizar</button>
  </mat-dialog-actions>
</ng-template>
