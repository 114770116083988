import { IFriendlyExtraMessage } from "../../../comm-interfaces/business-interfaces";
import { friendlyAction } from "../../../business/constant";
import { attNSerErrorFields, commNSerErrorFields, botErrors, servicePackErrors, deployedServicesErrors, botTransactionErrors, eventErrors, assetErros, actionsErrors, accessRoleErrors, socialNetworkErrors, attendanceEventsErrors, serviceIslandErrors, wabaErrors } from "./error-fields";
import { IScreenOptions } from "../../const-text/atext-types";
import { ITranslationConfig } from "../../translation/translation-engine";
import { keys } from "../../../tools/utility";
import { commonNSerErrorFields } from "../../const-text/errors/general-errors";


const commonBetweenErrors = {
  isError: true,
  returnNowToClient: true,
  delegateToInfra: true,
  friendlyAction: friendlyAction.none,
};

export function getFriendlyExtraMessageFromTranslation<Translation extends ITranslationConfig>(translation: Translation, extra: Partial<IFriendlyExtraMessage> = {}): IFriendlyExtraMessage {
  return {
      idField: translation.idField,
      idSerializable: translation.serializableId,
      ...commonBetweenErrors,
      ...extra,
  };
}

export function screenOptionToError<Option extends IScreenOptions>(option: Option) {
  const result: {
    [key in keyof Option['fields']]: { [nKey in keyof (typeof commonBetweenErrors & { idField: Option['fields'][key], idSerializable: typeof option.idSerializable })]: (typeof commonBetweenErrors & { idField: Option['fields'][key], idSerializable: Option['idSerializable'] })[nKey] }
  } = {} as any;
  
  keys(option.fields as Option['fields']).forEach((fieldName: keyof Option['fields']) => {
    const idField = (option.fields as Option['fields'])[fieldName];
    result[fieldName] = ({ ...commonBetweenErrors, idField, idSerializable: option.idSerializable })
  });

  return result;
}

export const nserInsertNameError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: commonNSerErrorFields.fields.insertName,
  idSerializable: commonNSerErrorFields.idSerializable
};

export const nserSmallNameError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: commonNSerErrorFields.fields.smallName,
  idSerializable: commonNSerErrorFields.idSerializable
};

export const nserInsertDescriptionError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: commonNSerErrorFields.fields.insertDescription,
  idSerializable: commonNSerErrorFields.idSerializable
};

export const nserSmallDescriptionError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: commonNSerErrorFields.fields.smallDescription,
  idSerializable: commonNSerErrorFields.idSerializable
};

export const nserServiceGroupError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.idServiceGroup
};

export const nserCalendarTimezoneRequired: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.missingCalendarTimezone
}

export const nserCommGroupError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.idISlandCommunicationGroup
};

export const nserIslandTypeError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.islandType
};

export const nserIslandMetadataError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.schemaId
};

export const nserIslandCalendarError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.missingCalendar
};

export const nserIslandEventsError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.missingEvents
};

export const nserIslandNamedAccountError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.invalidNamedAccountConfig
};



export const nserIslandInsertTimeRestritionMessage: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.missingTimeRestrictionMessage
};
export const nserIslandInsertEnqueuedMessage: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: attNSerErrorFields.idSerializable,
  idField: attNSerErrorFields.fields.missingTimeRestrictionMessage
};

export const nserinsertPhoneNumberError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertPhoneNumber
};

export const nserinvalidPhoneNumberError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.invalidPhoneNumber
};

export const nserinsertKeyError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertKey
};

export const nserinsertTokenError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertToken
};

export const nserinsertDomainError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertDomain
};

export const nserinsertScenarioError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertScenario
};

export const nserinsertUsernameError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertUsername
};

export const nserinsertPasswordError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertPassword
};

export const nserinsertEmailError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertEmail
};

export const nserinsertPageIdError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.insertPageID
};

export const nserinsertServiceIdError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.service
};


export const nserinserttemplateTemplateError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.templateTemplate
};


export const nserinserttemplateNameError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: commNSerErrorFields.idSerializable,
  idField: commNSerErrorFields.fields.templateName
};



export const nserMacrosError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.macros
};


export const nserServiceGroupsError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.serviceGroups
};
export const nserVariableTagsError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.variableTags
};
export const nserMetadatasError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.metadatas
};
export const nserClientRegisterMetadatasError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.clientRegisterMetadatas
};
export const nserTransferableIslandsError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.transferableIslands
};
export const nserFinalizationMetadataError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.finalizationMetadata
};
export const nserAllowedActiveChannelError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idSerializable: servicePackErrors.idSerializable,
  idField: servicePackErrors.fields.allowedActiveChannel
};

export const nserServiceTypeError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.serviceType,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserConfigError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.config,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserIsActiveError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.isActive,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserCommError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.comm,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserIdWhatsAppServiceRoutingError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.idWhatsAppServiceRouting,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserWelcomeTextError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.welcomeText,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserIdBotFileError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.idBotFile,
  idSerializable: deployedServicesErrors.idSerializable,
}
export const nserIdBotRoteError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: deployedServicesErrors.fields.idBotRote,
  idSerializable: deployedServicesErrors.idSerializable,
}

export const botTransactionSelectMetadataError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.selectMetadata,
  idSerializable: botTransactionErrors.idSerializable
}
export const botTransactionSelectRouteError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.selectRoute,
  idSerializable: botTransactionErrors.idSerializable
}
export const botTransactionKnowledgeBaseError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.selectKnowledgeBase,
  idSerializable: botTransactionErrors.idSerializable
}
export const botTransactionConfirmMessageError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.insertConfirmMessage,
  idSerializable: botTransactionErrors.idSerializable
}
export const botTransactionConfirmMessageSmallError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.smallConfirmMessage,
  idSerializable: botTransactionErrors.idSerializable
}
export const botTransactionIgnoreMessageError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.insertIgnoreMessage,
  idSerializable: botTransactionErrors.idSerializable
}
export const botTransactionIgnoreMessageSmallError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.smallIgnoreMessage,
  idSerializable: botTransactionErrors.idSerializable
}
export const eventInsertTextError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.insertText,
  idSerializable: eventErrors.idSerializable
}
export const eventInsertMinutesError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.insertMinutesExpire,
  idSerializable: eventErrors.idSerializable
}
export const eventInsertAttemptsError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.insertAttempts,
  idSerializable: eventErrors.idSerializable
}
export const eventYesQuestionError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.insertYesQuestion,
  idSerializable: eventErrors.idSerializable
}
export const eventYesQuestionSmallError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.smallYesQuestion,
  idSerializable: eventErrors.idSerializable
}
export const eventNoQuestionError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.insertNoQuestion,
  idSerializable: eventErrors.idSerializable
}
export const eventNoQuestionSmallError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: eventErrors.fields.smallNoQuestion,
  idSerializable: eventErrors.idSerializable
}
export const assetInsertMessageError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: assetErros.fields.insertMessage,
  idSerializable: assetErros.idSerializable
}
export const assetMessageSmallError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: assetErros.fields.smallMessage,
  idSerializable: assetErros.idSerializable
}
export const assetMediaIsMissingError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: assetErros.fields.insertMedia,
  idSerializable: assetErros.idSerializable
}
export const assetRouteIsMissingError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: assetErros.fields.insertRoute,
  idSerializable: assetErros.idSerializable
}
export const needToFillAccuracyEvent: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.fillAccuracy,
  idSerializable: botTransactionErrors.idSerializable
};

export const missingLLMProviderError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: botTransactionErrors.fields.missingLLMProvider,
  idSerializable: botTransactionErrors.idSerializable
};

/* - - - - - - atualizar aqui - - - - - - -  */

//action miss id element
export const actionMissIdElement: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: actionsErrors.fields.dataIncorrect,
  idSerializable: actionsErrors.idSerializable
}
//event confirmtion yesAction error
export const eventYesActionError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: actionsErrors.fields.dataIncorrect,
  idSerializable: actionsErrors.idSerializable
}
//event confirmtion noAction error
export const eventNoActionError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: actionsErrors.fields.dataIncorrect,
  idSerializable: actionsErrors.idSerializable
}

export const eventNoWabaIdError: IFriendlyExtraMessage = {
  ...commonBetweenErrors,
  idField: actionsErrors.fields.dataIncorrect,
  idSerializable: wabaErrors.idSerializable
}

export const {
  idGroup: idGroupError,
  accessType: accessTypeError,
  controledMenus: controledMenusError,
} = screenOptionToError(accessRoleErrors);

export const { content: contentBotError } = screenOptionToError(botErrors);

// 

export const { invalidDomain: invalidDomainError } = screenOptionToError(socialNetworkErrors);

export const { missingEvent: attendanceEventErrorMissingEvent } = screenOptionToError(attendanceEventsErrors);

export const {
  maximumLoadGreaterThanUnit: maximumLoadGreaterThanUnitError,
  namedAccount: namedAccountError
} = screenOptionToError(serviceIslandErrors);
