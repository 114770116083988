import { IRedirect, IRedirectHardwareAbstraction } from "../hardware-interfaces";
import { Injectable } from '@angular/core';
import { Window } from './cordova-plugins-definitions';
import { isValidRef } from "@colmeia/core/src/tools/utility";
import {environment} from "../../../../environments/environment-client";

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class CordovaRedirect implements IRedirectHardwareAbstraction {

    private static settings: Array<string> = [
        'location=no',
        'hidden=no',
        'clearcache=yes',
        'closebuttoncaption=fechar',
        'closebuttoncolor=#9932CC',
        'footer=yes',
        'hardwareback=no',
        'hidenavigationbuttons=yes',
        'zoom=no',
        'mediaPlaybackRequiresUserAction=yes',
        'shouldPauseOnSuspend=yes'
    ];

    URI(base: string): string {
        const beginsWithBar: boolean = base.startsWith('/');
        return `${environment.ROOT_URL}/${(beginsWithBar) ? base.substr(1) : base}`;
    }

    redirect(options: IRedirect): void {
        const browser = window.cordova.InAppBrowser.open(
            options.url,
            (isValidRef(options.mobileTarget)) ? options.mobileTarget : '_blank',
            CordovaRedirect.settings.join(',')
        );

        // if ('hook' in options) {
        //     options.hook(browser);
        // }
    }
}
