export function getDomainOrigin(target: string): string {
    const { origin } = new URL(target);
    return origin;
}

export function safeGetDomainOrigin(target: string): string {
    try {
        const origin = getDomainOrigin(target)
        return origin;
    } catch {
        return target;
    }
}