import {
    GlobalPositionStrategy,
    Overlay,
    OverlayConfig,
    OverlayRef
} from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType, TemplatePortal } from "@angular/cdk/portal";
import { ComponentRef, EmbeddedViewRef, Injectable, TemplateRef, ViewContainerRef } from "@angular/core";
import { isInvalid, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { verticalAppearDurationMS } from "app/components/dashboard/dashboard-animations";
import { Observable, Subscription } from "rxjs";
import { delay, take } from "rxjs/operators";

export interface ColmeiaPopoverHandler {
    elementTrigger: HTMLElement,
    viewContainerRef: ViewContainerRef,
    template?: TemplateRef<unknown>;
    componentType?: ComponentType<any>;
    componentData?: {
        subscriptionCallback(...value): void;
        eventEmitterProperty?: string;
        dataProperty?: string;
        data?: any;
    }
}

@Injectable({
    providedIn: "root",
})
export class ColmeiaPopoverService {
    constructor(
        private overlay: Overlay
    ) { }

    public create(handler: ColmeiaPopoverHandler) {
        return new ColmeiaPopover(handler, this.overlay);
    }
}

export class ColmeiaPopover {
    public elementTrigger: HTMLElement;
    public containerRef: ViewContainerRef;
    public componentInstance: ComponentRef<any>;
    public viewRef: EmbeddedViewRef<unknown>;

    public onBackdropClick: Observable<MouseEvent>;

    private overlayRef: OverlayRef;
    private nativeElements: HTMLElement[];
    private eventSubscription: Subscription;

    private template: TemplateRef<unknown>;
    private componentType: ComponentType<any>;
    private callback;
    private dataProp: string;
    private data: any;
    private eventEmitterProperty: string;

    private _isVisible: boolean = false;

    constructor(
        handler: ColmeiaPopoverHandler,
        private overlay: Overlay
    ) {
        this.elementTrigger = handler.elementTrigger;
        this.containerRef = handler.viewContainerRef;
        this.template = handler.template;
        this.componentType = handler.componentType;

        this.callback = handler.componentData?.subscriptionCallback;
        this.eventEmitterProperty = handler.componentData?.eventEmitterProperty;
        this.dataProp = handler.componentData?.dataProperty;
        this.data = handler.componentData?.data;
    }

    public get isVisible() {
        return this._isVisible;
    }

    private set isVisible(value: boolean) {
        this._isVisible = value;
    }

    public open(overlayHasBackdrop = true) {
        if (this.template) {
            this.createTemplateInstance(overlayHasBackdrop, this.template);
        } else {
            this.createTemplateInstance(overlayHasBackdrop, null, this.componentType);
            this.initComponentData();
        }

        this.animateOpen();
        this.setupOverlayRefObservables();
        this.isVisible = true;
    }

    public async close() {
        const overlayRef = this.overlayRef;

        await this.animateClose();

        // impede que chamemos o método "dispose" em um overlay que já foi removido
        if (!overlayRef?.hostElement) {
            return;
        }

        overlayRef.dispose();
        this.isVisible = false;
    }

    private createTemplateInstance(
        overlayHasBackdrop: boolean,
        template?: TemplateRef<unknown>,
        component?: ComponentType<any>
    ) {
        this.overlayRef = this.overlay.create(this.getOverlayConfig(overlayHasBackdrop));

        if (template) {
            const templatePortal = new TemplatePortal(
                template,
                this.containerRef
            )

            this.viewRef = this.overlayRef.attach(templatePortal);
            this.nativeElements = this.viewRef.rootNodes.filter(node => node.nodeType === Node.ELEMENT_NODE);
        } else {
            const componentPortal = new ComponentPortal(
                component,
                this.containerRef
            );

            this.componentInstance = this.overlayRef.attach(componentPortal);
            this.nativeElements = [this.componentInstance.location.nativeElement];
        }

        this.collisionDetector();
    }

    private collisionDetector() {
        this.detectOverlayWindowBorderCollision();
    }

    private getOverlayConfig(hasBackdrop): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop,
            disposeOnNavigation: true,
            backdropClass: [
                "no-backdrop-filter",
                "transparent-backdrop",
                "margin-animated",
            ],
        });
    }

    private detectOverlayWindowBorderCollision() {
        if (!this.overlayRef.overlayElement) return;

        const overlayEl = this.overlayRef.overlayElement;
        const { bottom: triggerBottom, left: triggerLeft, width: triggerWidth } = this.elementTrigger.getBoundingClientRect();
        const { height, width } = overlayEl.getBoundingClientRect();
        const overlayLeftPlusWidth = (triggerLeft + (triggerWidth / 2)) + (width / 2);
        const overlayRight = (triggerLeft + (triggerWidth / 2)) - (width / 2);
        // distância mínima do popover até a borda da window
        const minDistanceToBorder = 50;
        let offsetX = 0;

        // verifica se está próximo do canto inferior
        const collisionYPoint = window.innerHeight - 8;
        const getBottomCollision = triggerBottom + height >= collisionYPoint;

        // verifica se está próximo do canto direito
        if (overlayLeftPlusWidth > (window.innerWidth - minDistanceToBorder)) {
            offsetX = window.innerWidth - minDistanceToBorder - overlayLeftPlusWidth;
        }

        // verifica se está próximo do canto esquerdo
        if (overlayRight < (0 + minDistanceToBorder)) {
            offsetX = 0 + minDistanceToBorder - overlayRight;
        }

        const positionStrategy = this.getPositionStrategyForTrigger(
            this.elementTrigger,
            getBottomCollision,
            width,
            offsetX
        );

        this.overlayRef.updatePositionStrategy(positionStrategy);
    }

    private getPositionStrategyForTrigger(
        target: HTMLElement,
        invertY: boolean,
        overlayElementWidth: number,
        offsetX = 0
    ): GlobalPositionStrategy {
        const positionStrategy = this.overlay.position().global();
        const { top, bottom, right, height, width } =
            target.getBoundingClientRect();

        if (invertY) {
            positionStrategy.bottom(
                `${window.innerHeight - bottom + height}px`
            );
        } else {
            positionStrategy.top(`${top + height + 8}px`);
        }

        positionStrategy.right(`${window.innerWidth - right - (overlayElementWidth / 2) + (width / 2) - offsetX}px`);

        return positionStrategy;
    }

    private initComponentData() {
        // adiciona valores ao componente
        if (isValidString(this.dataProp)) {
            this.componentInstance.instance[this.dataProp] = this.data;
        }

        // faz subscribe ao evento do componente
        if (isValidString(this.eventEmitterProperty)) {
            this.eventSubscription = this.componentInstance.instance[
                this.eventEmitterProperty
            ].subscribe((value) => {
                this.callback(value);
                this.overlayRef.dispose();
            });
        }
    }

    private setupOverlayRefObservables() {
        window.addEventListener("resize", this.collisionDetector);

        // esse observable é completado quando fazemos dispose do overlayRef
        this.onBackdropClick = this.overlayRef.backdropClick();

        this.overlayRef
            .attachments()
            .pipe(take(1))
            .pipe(delay(verticalAppearDurationMS))
            .subscribe(() => {
                this.collisionDetector();
            });

        this.overlayRef
            .detachments()
            .pipe(take(1))
            .subscribe(() => {
                window.removeEventListener("resize", this.collisionDetector);

                if (isValidRef(this.eventSubscription)) {
                    this.eventSubscription.unsubscribe();
                }
            });
    }

    private async animateOpen(): Promise<Animation[] | undefined> {
        return this.animatePopover(
            [
                { opacity: "0" },
                { opacity: "1" }
            ]
        );
    }

    private async animateClose(keepHidden: boolean = false): Promise<Animation[] | undefined> {
        // desativamos os eventos do popover para que não dispare "mouseenter" ou "mouseleave"
        this.nativeElements?.map(el => el.style.setProperty('pointer-events', 'none'));

        return this.animatePopover(
            [
                { opacity: "1" },
                { opacity: "0" }
            ]
        );
    }

    private animatePopover(keyframes: Keyframe[]): Promise<Animation[]> | undefined {
        if (!this.nativeElements) return;

        return Promise.all(
            this.nativeElements?.map(element => {
                const animation = element.animate(
                    keyframes,
                    {
                        duration: 200,
                        easing: "cubic-bezier(0, 0, 0.2, 1)"
                    }
                );

                return animation.finished;
            })
        );
    }
}
