
import { IBrandTheme, IColorPallete, IColorPalleteBase, IForegroundColorPalette } from '@colmeia/core/src/shared-business-rules/brand-theme/brand-theme.model';
import { isValidString } from '@colmeia/core/src/tools/barrel-tools';
import tinycolor from 'tinycolor2';

export class BrandTheme {

    private static standardVarsMap: Record<string, number> = {
        default: 500,
        lighter: 100,
        darker: 700,
        text: 900,
        "default-contrast": 500,
        "lighter-contrast": 200,
        "darker-contrast": 700
    }

    public static getContrastColor(color: string): string {
        const black = "#000000";
        const white = "#FFFFFF";
    
        return tinycolor(color).isDark() ? white : black;
    }
    
    public static generateColorPalette(baseColor: string): IColorPallete {
        const paletteParam: IColorPallete = {
            palette: {
                50: tinycolor(baseColor).lighten(52).toHexString(),
                100: tinycolor(baseColor).lighten(37).toHexString(),
                200: tinycolor(baseColor).lighten(26).toHexString(),
                300: tinycolor(baseColor).lighten(12).toHexString(),
                400: tinycolor(baseColor).lighten(6).toHexString(),
                500: tinycolor(baseColor).toHexString(), 
                600: tinycolor(baseColor).darken(6).toHexString(),
                700: tinycolor(baseColor).darken(12).toHexString(),
                800: tinycolor(baseColor).darken(18).toHexString(),
                900: tinycolor(baseColor).darken(24).toHexString(),
                A100: tinycolor(baseColor).lighten(50).saturate(30).toHexString(),
                A200: tinycolor(baseColor).lighten(30).saturate(30).toHexString(),
                A400: tinycolor(baseColor).lighten(10).saturate(15).toHexString(),
                A700: tinycolor(baseColor).darken(10).saturate(15).toHexString(),
                // default: tinycolor(baseColor).toHexString(),
                // lighter: tinycolor(baseColor).lighten(37).toHexString(),
                // darker: tinycolor(baseColor).darken(12).toHexString(),
                // text: tinycolor(baseColor).darken(24).toHexString()                
            },
            contrast: {
                50: this.getContrastColor(tinycolor(baseColor).lighten(52).toHexString()),
                100: this.getContrastColor(tinycolor(baseColor).lighten(37).toHexString()),
                200: this.getContrastColor(tinycolor(baseColor).lighten(26).toHexString()),
                300: this.getContrastColor(tinycolor(baseColor).lighten(12).toHexString()),
                400: this.getContrastColor(tinycolor(baseColor).lighten(6).toHexString()),
                500: this.getContrastColor(tinycolor(baseColor).toHexString()), 
                600: this.getContrastColor(tinycolor(baseColor).darken(6).toHexString()),
                700: this.getContrastColor(tinycolor(baseColor).darken(12).toHexString()),
                800: this.getContrastColor(tinycolor(baseColor).darken(18).toHexString()),
                900: this.getContrastColor(tinycolor(baseColor).darken(24).toHexString()),
                A100: this.getContrastColor(tinycolor(baseColor).lighten(50).saturate(30).toHexString()),
                A200: this.getContrastColor(tinycolor(baseColor).lighten(30).saturate(30).toHexString()),
                A400: this.getContrastColor(tinycolor(baseColor).lighten(10).saturate(15).toHexString()),
                A700: this.getContrastColor(tinycolor(baseColor).darken(10).saturate(15).toHexString()),
                // "default-contrast": this.getContrastColor(tinycolor(baseColor).toHexString()),
                // "lighter-contrast": this.getContrastColor(tinycolor(baseColor).lighten(37).toHexString()),
                // "darker-contrast": this.getContrastColor(tinycolor(baseColor).darken(24).toHexString())
            }
        };
        return paletteParam;
    }

    public static generateForegroundColorPalette(baseColor: string): IForegroundColorPalette {
        const foregroundPaletteParam: IForegroundColorPalette = {
            foregroundPalette: { //<< specificits to be applied
                background: tinycolor(baseColor).toHexString(),
                base: tinycolor(baseColor).toHexString(),
                divider: tinycolor(baseColor).toHexString(),
                surface: tinycolor(baseColor).toHexString(),
                dividers: tinycolor(baseColor).toHexString(),
                "disabled-button": tinycolor(baseColor).toHexString(), 
                "disabled-text": tinycolor(baseColor).toHexString(),
                "hint-text": tinycolor(baseColor).toHexString(),
                "secundary-text": tinycolor(baseColor).toHexString(),
                icon: tinycolor(baseColor).toHexString(),
                icons: tinycolor(baseColor).toHexString(),
                text: tinycolor(baseColor).toHexString(),
                "slider-off": tinycolor(baseColor).toHexString(),
                "slider-off-active": tinycolor(baseColor).toHexString(),
                "slider-min": tinycolor(baseColor).toHexString(),
                "modal-bg": tinycolor(baseColor).toHexString()
            },
            foregroundContrast: {
                background: this.getContrastColor(tinycolor(baseColor).toHexString()),
                base: this.getContrastColor(tinycolor(baseColor).toHexString()),
                divider: this.getContrastColor(tinycolor(baseColor).toHexString()),
                surface: this.getContrastColor(tinycolor(baseColor).toHexString()),
                dividers: this.getContrastColor(tinycolor(baseColor).toHexString()),
                "disabled-button": this.getContrastColor(tinycolor(baseColor).toHexString()), 
                "disabled-text": this.getContrastColor(tinycolor(baseColor).toHexString()),
                "hint-text": this.getContrastColor(tinycolor(baseColor).toHexString()),
                "secundary-text": this.getContrastColor(tinycolor(baseColor).toHexString()),
                icon: this.getContrastColor(tinycolor(baseColor).toHexString()),
                icons: this.getContrastColor(tinycolor(baseColor).toHexString()),
                text: this.getContrastColor(tinycolor(baseColor).toHexString()),
                "slider-off": this.getContrastColor(tinycolor(baseColor).toHexString()),
                "slider-off-active": this.getContrastColor(tinycolor(baseColor).toHexString()),
                "slider-min": this.getContrastColor(tinycolor(baseColor).toHexString()),
                "modal-bg": this.getContrastColor(tinycolor(baseColor).toHexString()),
            }
        };
        return foregroundPaletteParam;
    }
    
    public static getHighlightedColor(color: string): string {
        const isColorDark = tinycolor(color).isDark();
    
        return isColorDark 
            ? tinycolor(color).brighten(18).toHexString()
            : tinycolor(color).darken(18).toHexString();
    }
    
    public static setAlphaColor(color: string, alphaAmount: number): string { 
        return tinycolor(color).setAlpha(alphaAmount).toHexString(); 
    }

    public static getListOfAvailableCSSVariablesPrefix(): string[] {
        return ['primary', 'accent'];
    }    

    public static brandThemeDefaultObjectBuilder(): IBrandTheme { 
        const brandTheme: IBrandTheme = {
            fontFamily: "Roboto",
            primary: this.generateColorPalette('#e639b5'),
            accent: this.generateColorPalette('#4214db'),
            // foreground: this.generateForegroundColorPalette('#29d964')
        };
        return brandTheme;
    }

    public static getAvailableFonts(): string[] {
        const availableFonts = [ //<< all of them are sans-serif typefaces
            "Roboto",
            "Arial",
            "Helvetica",
            "Verdana",
            "Tahoma",
            "Trebuchet MS",
            "Times New Roman",
            "Georgia",
            "Courier New",
            "Lucida Sans Unicode", // Lucida Grande on Mac
            "Palatino Linotype",    // Book Antiqua on some systems
        ];
        return availableFonts;
    }

    public static applyThemeOnElement(targetElement: HTMLElement, theme: IBrandTheme) {
        const { fontFamily, foreground, ...palettes } = theme;

        if (isValidString(fontFamily)) {
            targetElement.style.setProperty('--font-family', BrandTheme.getFontSafeValue(fontFamily));
        }

        for (const paletteName in palettes) {
            const paletteFull: IColorPallete = palettes[paletteName];
            const { palette, contrast } = paletteFull;

            BrandTheme.applyPalleteOnElement(targetElement, palette, paletteName)
            BrandTheme.applyPalleteOnElement(targetElement, contrast, paletteName, true)
        }

    }

    public static applyPalleteOnElement(targetElement: HTMLElement, palette: IColorPalleteBase, paletteName: string, isContrast?: boolean) {
        for (const tone in palette) {
            const value = palette[tone];
            let key: string = `--${paletteName}-${tone}`;

            if (isContrast) {
                key += `-contrast`
            }

            const color: { r: number, g: number, b: number } = tinycolor(value).toRgb()

            targetElement.style.setProperty(key, value);
            targetElement.style.setProperty(`${key}-rgb`, `${color.r}, ${color.g}, ${color.b}`);
        }

        if (!isContrast) {
            for (const name in BrandTheme.standardVarsMap) {
                const tone = BrandTheme.standardVarsMap[name];

                if (name.includes('contrast')) {
                    targetElement.style.setProperty(`--${paletteName}-${name}`, `var(--${paletteName}-${tone}-contrast)`);
                    targetElement.style.setProperty(`--${paletteName}-${name}-rgb`, `var(--${paletteName}-${tone}-contrast-rgb)`);
                } else {
                    targetElement.style.setProperty(`--${paletteName}-${name}`, `var(--${paletteName}-${tone})`);
                    targetElement.style.setProperty(`--${paletteName}-${name}-rgb`, `var(--${paletteName}-${tone}-rgb)`);
                }

            }
        }

    }

    public static getFontSafeValue(fontName: string): string {
        return `${fontName}, sans-serif`;
    }

}
