import { IFormSchema, SchemaProperty } from "@colmeia/core/src/general-form/general-form-interface";
import { EFunctionContext, EGlobalFunction, gUserFunctionDeclarationsStr, IUserFunctionAPIReturn, IUserFunctionDynamicList, IUserFunctionShakeHands, IUserFunctionShakingHandsInjection, IUserFunctionVirtualMenuExecutor, IUserFunctionVirtualMenuParams, TAllUserFunctionRuntimeFunctions } from "@colmeia/core/src/shared-business-rules/user-function/user-function-model";
import { entries, getExactWordRegex, insertContentAt } from "@colmeia/core/src/tools/utility";
import { TPropertyKeyLiteral } from "@colmeia/core/src/tools/utility-types";
import { runtimeFunctionsClientDB } from "../user-functions-client-db";

export const fnParamName = 'runtime'
export const currentFieldValueName = "currentFieldValue";
export const startCursorOffsetAD = "__START_CURSOR_OFFSET__";

export const proxyFunctionsNames = {
    getFieldValueByCanonical: `${fnParamName}.getFieldValueByCanonical`,
    getFieldValueByCanonicalNormalized: `${fnParamName}.getFieldValueByCanonicalNormalized`,
    getFieldValueByProperty: `${fnParamName}.getFieldValueByProperty`,
    getFieldValueByPropertyNormalized: `${fnParamName}.getFieldValueByPropertyNormalized`
}

export type TProxyFunctionsName = TAllUserFunctionRuntimeFunctions[]//ValueOf<typeof proxyFunctionsNames>;
export type RemoveNeverProps<T> = {
    [K in Exclude<
        keyof T, ({
            [P in keyof T]: T[P] extends Function ? P : never
        })[keyof T]>
    ]: T[K]
};



export const oldFunctionHeaderDeclaration = `async (${fnParamName}) {`;


type TAllowedInterface =
    | 'IUserFunctionBotInjection'
    | 'IUserFunctionMarketingInjection'
    | 'IUserFunctionShakingHandsInjection'
    | 'IUserFunctionClientInjection'
    | 'IUserFunctionAIInjection'
;

export function getInitialFunctionBody(fnContext: EFunctionContext) {
    const contextToInterface: {
        [key in EFunctionContext]: TAllowedInterface
    } = {
        [EFunctionContext.BOTContext]: "IUserFunctionBotInjection",
        [EFunctionContext.MKTContext]: "IUserFunctionMarketingInjection",
        [EFunctionContext.ShakingHands]: "IUserFunctionShakingHandsInjection",
        [EFunctionContext.ClientContext]: "IUserFunctionClientInjection",
        [EFunctionContext.AI]: "IUserFunctionAIInjection",
    }
    console.log({ fnContext, contextToInterfacefnContext: contextToInterface[fnContext] });


    return `async function main(${fnParamName}: ${contextToInterface[fnContext]}): Promise<TVLReturn> {
        ${startCursorOffsetAD}

        return ${fnParamName}.getResults()
    }
    `
}

export class FunctionCreateClientModel {
    static getSubmitRequestApiFunctionCode(idNSRoute: string): string {
        const body = `const request: IUserFunctionRequestCreator = ${FunctionCreateClientModel.getFunctionCaller("createRequest")}('${idNSRoute}');`

        return body;
    }

    static createCorporateSearchHeader(idNSCorpSearchHeader: string): string {
        return `const corporateSearch = runtime.createCorporateSearch('${idNSCorpSearchHeader}');`
    }

    static submitRequestMethods: Record<keyof IUserFunctionAPIReturn, [string, string]> = {
        "isError": ["", "boolean"],
        "isSuccess": ["", "boolean"],
        "isOk": ["", "boolean"],
        "isCode": ["code: number", "boolean"],
        "isResponseValue": ["property: string, value: any", "boolean"],
        "getStatus": ["", "EHTTPStatusCode"],
        "getResponseData": ["", "any"],
        "getHeaders": ["", "object"],
        "getFileUrl": ["", "string"],
    };

    static getFunctionDocCode(fnName: TAllUserFunctionRuntimeFunctions, ..._aux: any[]): string {
        switch (fnName) {
            case "createRequest":
                return `interface IBasicRequestWithBodyAndHeaderObject {
    headers?: object;
    body?: object;
}

${FunctionCreateClientModel.getSubmitRequestApiFunctionCode("KdB9UI6kso0A4642ExnABnq9FMiVXxnV")}

interface IUserFunctionAPIReturn {
${entries(FunctionCreateClientModel.submitRequestMethods).reduce((r, [functionName, [params, returnType]]) => {
                    return `${r}    ${functionName}(${params}): ${returnType};\n`
                }, '')}
}`;
            default:
                const { docSuffix } = runtimeFunctionsClientDB[fnName];
                return `${FunctionCreateClientModel.getFunctionCaller(fnName)}${docSuffix}`;
        }
    }

    static getFunctionCaller(method: TAllUserFunctionRuntimeFunctions): string {
        const methodConfig = runtimeFunctionsClientDB[method];
        let prefix: string = `${methodConfig.isAsync ? "await " : ""}${fnParamName}.`;

        return `${prefix}${method}`;
    }

    static mountCorporateSeachMetadataObject(parentName: string, schema: IFormSchema): string {
        const addMethodName: TPropertyKeyLiteral<IUserFunctionVirtualMenuParams, "addToVirtualMenu"> = "addToVirtualMenu";
        const saveVirtualMenu: TPropertyKeyLiteral<IUserFunctionVirtualMenuExecutor, "saveVirtualMenu"> = "saveVirtualMenu";

        return (
            `${parentName}.${addMethodName}({
                ${schema.form.map(field => `\t\t"${field.propertyName}": "",\n`).join("")}\t});
                runtime.${saveVirtualMenu}(${parentName})`
        );
    }

    static mountShakingHandsObject(parentName: string, schema: IFormSchema): string {
        const addMethodName: TPropertyKeyLiteral<IUserFunctionShakeHands, "addToShakeHands"> = "addToShakeHands";
        const saveshakeHandsMenu: TPropertyKeyLiteral<IUserFunctionShakingHandsInjection, "saveShakingHands"> = "saveShakingHands";

        return (
            `${parentName}.${addMethodName}({
                ${schema.form.map(field => `\t\t"${field.idProperty}": "",\n`).join("")}\t});\n
                runtime.${saveshakeHandsMenu}(${parentName})`
        );
    }

    static mountDyanmicDBUpsertRow(parentName: string, schema: IFormSchema): string {
        const addMethodName: TPropertyKeyLiteral<IUserFunctionDynamicList, "upsertRow"> = "upsertRow";

        return (
            `await ${parentName}.${addMethodName}({\n${schema.form.map(field => `\t\t"${field.propertyName}": "",\n`).join("")}\t});\n`
        );
    }

    static mountDynamicDBCall(parentName: string, method: keyof IUserFunctionDynamicList, schema: IFormSchema): string {
        const fields: SchemaProperty[] = schema.form.filter(f => method === 'fetchRow' ? f.isPrimaryKey : true);
        return (
            `await ${parentName}.${method}({\n${fields.map(field => `\t\t"${field.propertyName}": "",\n`).join("")}\t});\n`
        );
    }
    static mountSchemaJSON(schema: IFormSchema): string {
        return (
            `{\n${schema.form.map(field => `\t\t"${field.propertyName}": "",\n`).join("")}\t}`
        );
    }

    static mountDeclarationsForClient(): string {
        const keyswordsA = ["export", "declare"];
        const keyswordsB = ["class", "interface", "enum", "type"];
        let source = gUserFunctionDeclarationsStr.replace("declare const parameters: IUserFunctionInjection;", "");

        keyswordsB.forEach(keywordB => {
            keyswordsA.forEach((keywordA) => {
                source = source.replaceAll(getExactWordRegex(`${keywordA} ${keywordB}`), keywordB);
            })
        });

        return source;
    }
}

const brFlagUri = "./assets/icons/flags/br.svg";

export function getGlobalFunctionDocs() {
    return {
        [EGlobalFunction.docValidation]: {
            value: EGlobalFunction.docValidation,
            title: "Validação de documento",
            matIcon: "assignment_ind",
            desc: "Verifica se o CPF é válido ignorando caracteres não numéricos",
            auxIcon: {
                url: brFlagUri
            },
        },
        [EGlobalFunction.companyDocValidation]: {
            value: EGlobalFunction.companyDocValidation,
            title: "Validação de CNPJ",
            matIcon: "assignment",
            desc: "Verifica se o CNPJ é válido ignorando caracteres não numéricos",
            auxIcon: {
                url: brFlagUri
            },
        },
        [EGlobalFunction.emailValidation]: {
            value: EGlobalFunction.emailValidation,
            title: "Validação de email",
            matIcon: "alternate_email",
            desc: "Verifica se o Email está nos padrões internacionais",
            auxIcon: {
                matIcon: "public"
            }
        },
        [EGlobalFunction.zipCodeValidation]: {
            value: EGlobalFunction.zipCodeValidation,
            title: "Validação de CEP",
            matIcon: "edit_road",
            desc: "Valida se 99999-999 ou 99999999",
            auxIcon: {
                url: brFlagUri
            },
        },
        [EGlobalFunction.phoneValidation]: {
            value: EGlobalFunction.phoneValidation,
            title: "Validação de Telefone",
            matIcon: "phone",
            desc: "Validador de telefone brasileiro pelo tamanho do input, ex: +5511988887777 deve conter 13 digitos",
            auxIcon: {
                url: brFlagUri
            },
        },
        [EGlobalFunction.dateValidation]: {
            value: EGlobalFunction.dateValidation,
            title: "Validação de Data",
            matIcon: "date_range",
            desc: "Validador de data, o input deve conter 8 caracteres numericos contendo dia, mes e ano nessa ordem, ex: 02/02/2022 ou 02-02-2022",
            auxIcon: {
                url: brFlagUri
            },
        },
    };
}
