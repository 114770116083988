import { Sha256, bytes_to_hex, } from 'asmcrypto.js';


export class Crypt {
    private static Sha256Instance = new Sha256();

    constructor() { }

    public static sha256(data: Uint8Array): string {
        const cryptRes = Crypt.Sha256Instance.process(data).finish().result;
        Crypt.Sha256Instance.reset();
        return bytes_to_hex(cryptRes);
    }

    public static sha256Str(data: string): string {
        return Crypt.sha256(Crypt.stringToArrayBuffer(data));
    }

    private static stringToArrayBuffer(str: string): Uint8Array {
        var arr = new Uint8Array(str.length);
        for (var i = str.length; i--;)
            arr[i] = str.charCodeAt(i);
        return arr;
    }
}
