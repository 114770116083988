<h3>Funções: Documentação do tempo de execução</h3>
<div #dialogContent mat-dialog-content>
  <mat-tab-group class="vertical-tab" class="vertical-tab" [selectedIndex]="initialSelectedIndex">
    <mat-tab *ngFor="let item of docsConfigsArray">
      <ng-template mat-tab-label>
        <mat-icon class="label-icon">{{ item.matIcon }}</mat-icon> {{ item.value }}
      </ng-template>
      <div class="content-container">
        <h4>{{ item.title }}</h4>
        <p *ngIf="item.desc">{{ item.desc }}</p>
        <app-code-editor #codeEditor *ngIf="item.sampleCode" readonly [content]="item.sampleCode"></app-code-editor>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
