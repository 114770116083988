import { TMonthDaysHoursMinutesSeconds } from '@colmeia/core/src/time/time-utl';
import { IPrintTimeInfoTranslatedText } from '@colmeia/core/src/tools/utility-types';
import { getDaysHoursMinutesSeconds } from '@colmeia/core/src/time/time-utl';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { lowerCase } from 'lodash';
import { ITranslationConfig } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {UserSettingsService} from "./user-settings.service";

export enum EDateTypes {
    Day = 'd', // 20
    Month = 'MMMM', // fevereiro
    Hour = 'HH\'h\'mm', // 16h50
}

@Injectable({
    providedIn: 'root'
})
export class DateService {
    constructor(
        private settingsSvc: UserSettingsService
    ) { }

    getDateFormated(date: number | Date, dateType: EDateTypes | string): string {
        const locale: string = this.settingsSvc.getSelectedLocale()
        if (typeof date == 'number') {
            date = new Date(date);
        }
        return new DatePipe(locale)
            .transform(date, dateType)
    }

    getDateAndTimeString(date: number): string {
        return this.getDateFormated(date,  this.settingsSvc.getDateHourSecondsFormat());
    }

    getBestDate(date: Date): string {
        if(this.isToday(date)) {
            return this.getDateFormated(date,  this.settingsSvc.getTwentyFourTimeFormat());
        } else {
            return this.getDateFormated(date,  this.settingsSvc.getDateHourFormat());
        };
    }

    isToday(date: Date): boolean{
        const now: Date = new Date();
        now.setHours(0,0,0,0);
        const dateToCompare = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

        if (now.getTime() === dateToCompare.getTime()) {
            return true;
        } else {
            return false;
        }
    }

    msToSimpleSpelledOutText(miliseconds: number): string {
        const dateInfo = getDaysHoursMinutesSeconds(miliseconds);
        const timeUnits = this.getAllTimeUnits().filter(unit => dateInfo[unit] > 0);

        if (!timeUnits.length) {
            return '';
        }

        const biggerTimeUnit = timeUnits[0];

        return this.generateSpelledOutDateText(dateInfo, [biggerTimeUnit]);
    }

    msToSpelledOutText(miliseconds: number, ignoreUnits: (keyof TMonthDaysHoursMinutesSeconds)[] = []): string {
        const dateInfo = getDaysHoursMinutesSeconds(miliseconds);
        let timeUnits = this.getAllTimeUnits();

        timeUnits = timeUnits.filter(unit => !ignoreUnits.includes(unit));
        timeUnits = timeUnits.filter(unit => dateInfo[unit] > 0);

        return this.generateSpelledOutDateText(dateInfo, timeUnits);
    }

    /**
     * retorna as unidades de tempo em ordem decrescente
     * (NÃO mude a ordem)
     */
    private getAllTimeUnits(): (keyof TMonthDaysHoursMinutesSeconds)[] {
        return [
            'months',
            'days',
            'hours',
            'minutes',
            'seconds',
        ];
    }

    private generateSpelledOutDateText(
        dateInfo: TMonthDaysHoursMinutesSeconds,
        timeUnits: (keyof TMonthDaysHoursMinutesSeconds)[]
    ): string {
        const translations = this.getTimeUnitsTranslations();
        const timeUnitTexts: string[] = [];

        for (let timeUnit of timeUnits) {
            const value = Math.trunc(dateInfo[timeUnit]);
            const translation = translations[timeUnit];

            let unitText = `${value} ${value <= 1 ? translation.singular : translation.plural}`;

            timeUnitTexts.push(unitText);
        }

        const beforeLast: string = timeUnitTexts.slice(0, -1).join(', ');
        const lastItem: string = timeUnitTexts[timeUnitTexts.length - 1];

        return [beforeLast, lastItem].filter(Boolean).join(` ${translations.and} `);
    }

    private getTimeUnitsTranslations(): IPrintTimeInfoTranslatedText {
        return {
            minutes: {
                singular: this.getLowerCaseTranslation(gTranslations.common.minute),
                plural: this.getLowerCaseTranslation(gTranslations.common.minutes)
            },
            seconds: {
                singular: this.getLowerCaseTranslation(gTranslations.common.second),
                plural: this.getLowerCaseTranslation(gTranslations.common.seconds)
            },
            hours: {
                singular: this.getLowerCaseTranslation(gTranslations.common.hour),
                plural: this.getLowerCaseTranslation(gTranslations.common.hours)
            },
            days: {
                singular: this.getLowerCaseTranslation(gTranslations.common.day),
                plural: this.getLowerCaseTranslation(gTranslations.common.days)
            },
            months: {
                singular: 'mês',
                plural: 'meses'
            },
            and: this.getLowerCaseTranslation(gTranslations.common.and),
        };
    }

    private getLowerCaseTranslation(field: ITranslationConfig): string {
        return lowerCase(Serializable.getTranslation(field));
    }
}
