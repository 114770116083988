<div class="ordenable-list">

  <div class="ordenable-item ordenable-header">
    <div class="ordenable-item-content">
      <div class="row-name">{{ translations.variables }}</div>
    </div>
    <div class="ordenable-item-actions">
      <app-tag-picker *ngIf="tagPickerHandler" [handler]="tagPickerHandler"></app-tag-picker>
    </div>
  </div>

  <div *ngFor="let variable of selectedVariableTags" class="ordenable-item">
    <div class="ordenable-item-content">
      <div class="row-name">{{ variable.nName }}</div>
      <div class="row-value">{{ variable.value }}</div>
    </div>
    <div class="ordenable-item-actions">
      <button *ngIf="canCopyVariable" mat-icon-button color="primary" matTooltip="Copiar" (click)="copyVariable(variable)">
        <mat-icon>file_copy</mat-icon>
      </button>

      <button mat-icon-button color="primary" matTooltip="Remover" (click)="removeTag(variable)">
        <mat-icon>delete</mat-icon>
      </button>

    </div>
  </div>

</div>
