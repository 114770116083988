export const DIALOG_TYPE = {
	confirmationDialog: 0,
	yesOrNoDialog: 1
};

export const maxSizeOfReplyMessage = 15;



export const DATE_FORMAT = 
{
	date: 'dd/MMM/yyyy',
	dateTime:  "dd/MM/yyyy 'às' hh:mm"
};
