import { Serializable } from '@colmeia/core/src/business/serializable';
import { ISearchInfo, ISearchResult, TSearchResultArray } from '@colmeia/core/src/comm-interfaces/ds-interfaces';
import { TArrayID, TExtendedParticipant, TExtendedParticipantArray, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { hashToArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { Subject } from 'rxjs';
import { MainHandler } from './main-handler';
import { IComponentParameter, IRootBase } from "../model/component-comm/basic";
import { UniversalRehydrator } from "../services/universal-rehydrator";
import { EParticipantClickTypeMode } from "./participant-selector.handler";
import { ESearchAllowedType } from "@colmeia/core/src/shared-business-rules/search";
import { ISearchRemoteEnvConfig } from "../services/search.service";
import { ESearchContentType, ISearchTypeContent } from 'app/components/foundation/search/search.component';
import { ISearchRequest } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { EIdMenus } from '@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus';

export type TSearchValidCssClass = 'group-home-component';
export type TSearchValidCssClassArray = Array<TSearchValidCssClass>;

export interface ISearchInfoLocal extends ISearchInfo {
    checked: boolean;
    displayable: boolean;
};

export interface ISearchType {
    [primaryID: string]: ISearchInfoLocal;
};

export interface ISearchComponentParamater extends IComponentParameter {
    searchTypes: ESearchAllowedType[];
    maxSelectionableNumber: number;
    defaultOption: TGlobalUID;
    showCloseBtn: boolean;
    searchScope: TGlobalUID;
    clientCallback: IClientSearchCallback;
    isEditableTypes: boolean;
    extraCssClasses?: TSearchValidCssClassArray;
    clickAction: EParticipantClickTypeMode;
    observableForTyping?: Subject<string>;
    resetAfterSelect?: true;
    idAgentIsland?: string;
    searchRemoteEnvConfig?: ISearchRemoteEnvConfig;
    selectedSearchType?: ESearchContentType;
    menuID?: EIdMenus;
    requestModifierCallback?: (request: ISearchRequest) => ISearchRequest
};

export interface IClientSearchCallback extends IRootBase {
    onSearchClose?();
    onFinishSelection(selectionableArray: TExtendedParticipantArray);
    onSelectedSearchItem(selectionable: TExtendedParticipant): void;
    onRemoveSearchItem(selectionable: TExtendedParticipant): void;
}


export interface ISearchSlave {
    closeSearchBox(): void;
    hasAnySNResults?(): boolean;
    hasAnyGlobalResults?(): boolean;
    resetGlobalSearchResults?(): void;
}

export class HandlerSearch extends MainHandler {

    private participants: { [primaryId: string]: TExtendedParticipant } = {};

    slave: ISearchSlave;

    constructor(parameters: ISearchComponentParamater) {
        super(parameters);
    }

    setSlave(slave: ISearchSlave) {
        this.slave = slave;
    }

    getSlave() {
        return this.slave;
    }

    public getSearchParameter(): ISearchComponentParamater {
        return <ISearchComponentParamater>this.getComponentParameter();
    }

    getIdEditableTypes(): boolean {
        return this.getSearchParameter().isEditableTypes
    }

    public showCloseBtn(): boolean {
        return this.getSearchParameter().showCloseBtn;
    }

    public isProxySearch(): boolean {
        return isValidRef(this.getSearchParameter().observableForTyping);
    }

    public getExtraCssClasses(): TSearchValidCssClassArray {
        return this.getSearchParameter().extraCssClasses;
    }

    public async onSelectedResult(result: ISearchResult, infraSvc: UniversalRehydrator): Promise<void> {
        const participant = await this.toTExtendedParticipant(result, infraSvc);
        this.participants[participant.getPrimaryID()] = participant;
        if (super.hasCallback() && this.getSearchParameter().clientCallback.onSelectedSearchItem) {
            (<IClientSearchCallback>(this.getComponentParameter().clientCallback))
                .onSelectedSearchItem(participant);
        }
    }

    public async onRemoveResult(result: ISearchResult, infraSvc: UniversalRehydrator): Promise<void> {
        const participant = await this.toTExtendedParticipant(result, infraSvc);
        delete this.participants[participant.getPrimaryID()];
        if (super.hasCallback() && this.getSearchParameter().clientCallback.onRemoveSearchItem) {
            (<IClientSearchCallback>(this.getComponentParameter().clientCallback))
                .onRemoveSearchItem(participant);
        }
    }

    public onSelectedSave(resultArray: TSearchResultArray, infraSvc: UniversalRehydrator): void {
        this.participants = {};
        for (const selected of resultArray) {
            const participant = this.toTExtendedParticipant(selected, infraSvc);
            this.participants[participant.getPrimaryID()] = participant;
        }
    }

    public isResetAfterClick(): boolean {
        return this.getSearchParameter().resetAfterSelect;
    }

    public onSelectedCancel(): void {
        this.participants = {};
        this.onFinishSelection();
    }

    // maybe put infraService into constructor???
    public toTExtendedParticipant(selected: ISearchResult, infraSvc: UniversalRehydrator): TExtendedParticipant {
        return <TExtendedParticipant>(
            UberCache.testCache(selected.primaryID) ?
                Serializable.staticFactory(selected.primaryID) :
                infraSvc.localRehydrate<TExtendedParticipant>(selected.universalJSON));
    }

    // sends array of participants to clientComponent
    public onFinishSelection(): void {
        (<IClientSearchCallback>(this.getComponentParameter().clientCallback))
            .onFinishSelection(
                hashToArray(this.participants)
            );
    }

}
