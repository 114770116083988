<div class="container">
  <div class="info-container">
    <h3 class="title">
      <mat-icon>update</mat-icon>
      {{ title }}
    </h3>
    <p class="message">{{ message }}</p>
  </div>
  <div class="action-container">
    <button mat-button color="accent" (click)="reload()">Reiniciar</button>
  </div>
</div>