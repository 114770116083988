<div class="share__container">
  <div class="share-header">
      <header>
          <h4>{{ translations.share }}</h4>
          <button (click)="closeWindow()"><i class="material-icons">clear</i></button>
      </header>
      <hr>
  </div>

  <mat-tab-group #matTabGroup dynamicHeight mat-stretch-tabs md-no-ink-bar="true">
    <mat-tab label="{{ translations.basicShare }}">
      <section class="search-group-share">
        <app-participant-selector [participantSelectorHandler]="getParticipantSelectorHandler()">
        </app-participant-selector>
      </section>

      <section class="share-added-items">
                <!-- Item(s) adicionado(s) -->
        <h4> {{ itemsCount() }} {{ translations.addedItens }}</h4>
        <ul class="share_items_list__container">
          <li *ngFor="let item of getSharedItems(); let i = index" class="share_items_list__item">
            <div class="share_items_list__content">
              <app-shared-item
                [alreadySharedIn]="isInteractionAlreadyShared(item.resource.getPrimaryID())"
                [item]="item.resource"
                [idGroup]="item.currentGroupID"
                [index]="i"
              ></app-shared-item>
            </div>
            <div class="share_items_list__interactions">
              <button (click)="removeItem(item)">
                <i class="material-icons">remove_circle</i>
              </button>
            </div>
          </li>
        </ul>
      </section>
    </mat-tab>
  </mat-tab-group>

  <section class="buttons-controls">
    <cm-button [color]="getClearBtnColor()" (click)="removeAllItems()">{{ translations.clearItens }}</cm-button>
    <cm-button [color]="getShareBtnColor()" (click)="shareItems()">{{ translations.share }}</cm-button>
  </section>
</div>
