import { TNserValidator } from "../validate-nser";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { screenOptionToError } from "../error-messages";
import { isInvalid } from "../../../../tools/utility";
import { graphElementErrors } from "../error-fields";
import { defaultNserValidator } from "./nser";
import { IBasicElementServer } from "../../../graph/essential/graph-basic-element-interfaces";

const errors = screenOptionToError(graphElementErrors);
export const graphElementValidator: TNserValidator = (nser: IBasicElementServer, friendly: FriendlyMessage) => {
    defaultNserValidator(nser, friendly);

    return friendly;
}

