import { TGlobalUID } from "../../business/constant";

const root: string = 'colmeiaapp://';

export const browserInteractionRoutePieces = {
    path: 'notification',
    params: {
        idGroup: 'idGroup',
        idInteraction: 'idInteraction'
    }
};

export function getInteractionCordovaRoute(idGroup: TGlobalUID, idInteraction: TGlobalUID): string {
    return `${root}interaction(${idGroup},${idInteraction})`;
};

export function getInteractionBrowserRoute(idGroup: TGlobalUID, idInteraction: TGlobalUID, baseURL: string): string {
    return `${baseURL}/${browserInteractionRoutePieces.path}/${idGroup}/${idInteraction}`;
}
