import { TReferencedObject } from '../../graph-transaction/toolbar/config-toolbar.types';
import { BasicElement } from './basic-element';
import { DummyHostObject } from './dummy-host-object';
import { GraphElement } from './graph-element';
import { IPredicateElementJSON } from './graph-interfaces';
import { GraphRulesProcessor } from './graph-rules-processor';
import { EGraphElementType, EPredicateType } from './graph-types';
import { IHostObjectInterface } from './host-object';


export interface IBasicNewPredicate {
    predicateType: EPredicateType;
    fromElementId?: string;
    toElementId?: string;
    inputName?: string;
    outputName?: string;
}
export interface INewPredicate extends IBasicNewPredicate {
    hostObject?: IHostObjectInterface;
};

export interface ICreateNewPredicate {
    fromElementId: string,
    toElementId: string,
    predicateName: string,
    businessPredicateType: TReferencedObject,
    subElementId: string,
}

export type TGraphPredicateArray = Array<GraphPredicate>;

export class GraphPredicate extends BasicElement {

    private predicateType: EPredicateType;
    private businessPredicateType: TReferencedObject;
    private fromElementId: string;
    private toElementId: string;
    private inputName: string;
    private outputName: string;
    private isPointingToCycle: boolean;
    private subElementId: string;

    public gePredicateType(): EPredicateType { return this.predicateType; }
    public getFromElementId(): string { return this.fromElementId; }
    public getToElementId(): string { return this.toElementId; }
    public getBusinessPredicateType(): TReferencedObject { return this.businessPredicateType; }

    constructor(json: IPredicateElementJSON, hostObject = new DummyHostObject()) {
        const name = GraphPredicate.createNameFromJSON(json)
        super(name, json, hostObject);
        this.predicateType = json.predicateType;
        this.businessPredicateType = json.businessPredicateType;
        this.fromElementId = json.fromElementId;
        this.toElementId = json.toElementId;
        this.inputName = json.inputName;
        this.outputName = json.outputName;
        this.subElementId = json.subElementId ?? undefined;
    };

    public toJSON(): IPredicateElementJSON {
        return {
            ...super.toJSON(),
            elementType: EGraphElementType.predicate,
            predicateType: this.predicateType,
            businessPredicateType: this.businessPredicateType,
            fromElementId: this.fromElementId, // botroot
            toElementId: this.toElementId, // menucontainer
            inputName: this.inputName,
            outputName: this.outputName,
            subElementId: this.subElementId
        };
    }

    public rehydrate(json: IPredicateElementJSON): void {
        super.rehydrate(json);
    }

    static createNameFromJSON(elementJSON: IPredicateElementJSON) {
        return `${elementJSON.inputName}(${elementJSON.fromElementId})-to-${elementJSON.outputName}(${elementJSON.toElementId})`
    }

    public getInputName(): string {
        return this.inputName;
    }

    public setInputName(value: string) {
        this.inputName = value;
        this.setName(GraphPredicate.createNameFromJSON(this.toJSON()));
    }

    public getOutputName(): string {
        return this.outputName;
    }

    public setOutputName(value: string) {
        this.outputName = value;
        this.setName(GraphPredicate.createNameFromJSON(this.toJSON()));
    }

    public getPredicateType(): EPredicateType { return this.predicateType; };
    public isSamePredicateType(otherType: EPredicateType) {
        return this.getPredicateType() === otherType
    }
    public isSameBusinessPredicateType(otherPredicateType: TReferencedObject) {
        return this.businessPredicateType === otherPredicateType
    }
    public hasValidBusinessPredicate(): boolean {
        return this.businessPredicateType != null
    }

    public getBusinessPredicate(): TReferencedObject {
        return this.businessPredicateType
    }

    public getSubElementId(): string {
        return this.subElementId;
    }

    public setSubElementId(subElementId: string) {
        return this.subElementId = subElementId;
    }

    public connectEdges(fromElement: GraphElement, toElement: GraphElement): void {
        if (!fromElement) debugger
        if (!(fromElement || toElement)) {
            console.warn("Predicado orfão: ", this);
            return;
        }
        this.fromElementId = fromElement.getGraphElementID();
        this.toElementId = toElement.getGraphElementID();
    };

    getTargetElementId() {
        return this.toElementId
    }

    isDrawable(): boolean {
        return
    }

    isClosingCycle(): boolean {
        return this.isPointingToCycle
    }

    setPointingToCycle(isPointingToCycle: boolean): void {
        this.isPointingToCycle = isPointingToCycle
    }

    getTargetHostId(graphRulesProcessor: GraphRulesProcessor) {
        return graphRulesProcessor.getElementById(this.toElementId).getHostedID();
    }

    isSameSubElementId(subElementId: string) {
        return this.subElementId === subElementId;
    }

    isSameOrigin(originElementId: string) {
        return this.fromElementId === originElementId;
    }
};