import { Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { TPublicMediumArray } from "@colmeia/core/src/comm-interfaces/billing-interfaces";
import { PaymentMediumRegister } from "@colmeia/core/src/interaction/billable/payment-medium";
import { IPaymentMediumInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { GetMediumRequest, IPaymentMediumRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { GetMediumResponse, IPaymentMediumResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { GroupChatServices } from "../group-chat.services";
import { RequestBuilderServices } from "../request-builder.services";
import { ServerCommunicationService } from "../server-communication.service";
import { SessionService } from "../session.service";
import { IInfraParameters } from "app/model/component/client-infra-comm";


@Injectable({
    providedIn: 'root'
})
export class MediumServices {

    constructor(
        private chatService: GroupChatServices,
        private requestBuilder: RequestBuilderServices,
        private serverAPI: ServerCommunicationService,
        private sessionService: SessionService
    ) {

    }

    async getAllPublicMediums(): Promise<TPublicMediumArray> {


        const request: IPaymentMediumRequest = {
            ...this.requestBuilder.secureBasicRequest(apiRequestType.payments.mediums)
        };

        const infra: IInfraParameters = this.requestBuilder.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);

        if(response.executionOK) {
            return (<IPaymentMediumResponse> response.response).results;
        }

        return [];
    }

    async getRealMedium(primaryID: TGlobalUID): Promise<PaymentMediumRegister> {
        const infra: IInfraParameters = this.requestBuilder.getContextNoCallBackNoSpinnningParameters();
        const request: GetMediumRequest = {
            ...this.requestBuilder.secureBasicRequest(apiRequestType.payments.getMedium),
            idRequest: primaryID
        };
        const response = await this.serverAPI.managedRequest(infra, request);
        if (response.executionOK) {
            return PaymentMediumRegister.factoryMessage(<IPaymentMediumInteractionJSON>(<GetMediumResponse> response.response).medium, null);
        } else {
            throw "Não deu pra pegar o método de pagamento"
        }
    }

    async create(interaction: PaymentMediumRegister): Promise<boolean> {
        const result = await this.chatService.saveInteraction(interaction, [], null);
        return result.isOk();
    }
}
