import {
    ExecutableItem,
    TExecutableItemArray
} from "../services/controllers-services/security-controller/executable-item";
import {IComponentParameter, IRootCallBack} from "../model/component-comm/basic";
import {DropdownListCallback} from "../components/foundation/dropdown/dropdown-callback";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {Interaction} from "@colmeia/core/src/interaction/interaction";
import {MainHandler} from "./main-handler";
import {DropdownGlobalService} from "../components/foundation/dropdown/dropdown-global/dropdown-global.service";

export namespace NDropDown {
    export enum EDropDownPosition {
        Left = 1,
        Right,
        Center,
        Bottom
    }


    export enum EColorType {
        Blue,
        Grey
    }
    export interface IDropDownClickedItem {
        event: MouseEvent;
        itemClicked: ExecutableItem;
    }

    export interface IDropDownClickedDescriptor {
        getMouseEvent(): MouseEvent;
        getItemClicked(): ExecutableItem;
    }

    export interface IDropdownOptionClickedClient {
        onDropdownOptionClicked(descriptor: IDropDownClickedDescriptor): void;
    }

    export interface IDropDownClientCallback extends IRootCallBack {
        onDropDownItemClickedCallback(clickDescriptor: IDropDownClickedItem): void;
    }

    export interface IDropDownComponentParamater extends IComponentParameter {
        visible: boolean;
        color: EColorType;
        position: EDropDownPosition;
        selectable: boolean;
        mutuallyExclusive: boolean;
        allItems: TExecutableItemArray;
        defaultSelectedItems: TExecutableItemArray;
        clientCallback: DropdownListCallback;
        clientInstance: IDropdownOptionClickedClient;
        currentMouseEvent?: Partial<MouseEvent>;
    }

    export interface IDropdownPosition {
        top: string;
        left: string;
    }

    export interface IDropdownPositionDescriptor extends IDropdownPosition {
        position: string;
    }

    export interface IDropdownHighLevelDescriptor {
        itemClicked: TGlobalUID,
        interaction: Interaction,
    }

    export interface IDropDownCoordinates {
        event: MouseEvent,
    }

    export class DropDownHandler extends MainHandler {
        private static dropdownSvc: DropdownGlobalService;

        public static getDefaultDropDownParameter(): IDropDownComponentParamater {
            return {
                visible: false,
                color: EColorType.Blue,
                position: EDropDownPosition.Center,
                selectable: false,
                mutuallyExclusive: false,
                allItems: [],
                defaultSelectedItems: [],
                clientCallback: null,
                clientInstance: null,
            };
        }

        public static handlerFactory(clientInstance: IDropdownOptionClickedClient, items: TExecutableItemArray): DropDownHandler {
            const parameter: NDropDown.IDropDownComponentParamater = {
                ...NDropDown.DropDownHandler.getDefaultDropDownParameter(),
                clientInstance,
                allItems: items,
                position: NDropDown.EDropDownPosition.Left,
            };

            const handler: NDropDown.DropDownHandler = new NDropDown.DropDownHandler(parameter);
            return handler;
        }

        public static setDropDownServiceInstance(dropdownSvc: DropdownGlobalService) {
            DropDownHandler.dropdownSvc = dropdownSvc;
        }

        constructor(parameters: IDropDownComponentParamater) {
            super(parameters);
            parameters.clientCallback = new DropdownListCallback(parameters);
        }

        public getComponentParameter(): IDropDownComponentParamater {
            return <IDropDownComponentParamater>super.getComponentParameter();
        }

        public isSelectable(): boolean {
            return this.getComponentParameter().selectable;
        }

        public setPosition(position: EDropDownPosition): void {
            this.getComponentParameter().position = position;
        }

        public setCallback(callback: DropdownListCallback): void {
            super.setCallback(callback);
        }

        public getCallback(): DropdownListCallback {
            return <DropdownListCallback>this.getComponentParameter().clientCallback;
        }

        public setExecutableItems(items: TExecutableItemArray): void {
            if (this.getComponentParameter().clientCallback)
                this.getComponentParameter().clientCallback.setItems(items);
        }

        public getAllItems(): TExecutableItemArray {
            return this.getComponentParameter().clientCallback.getAllItems();
        }

        public containsItem(item: ExecutableItem): boolean {
            return item && this.getAllItems() && this.getAllItems().some(option => item.is(option.getPrimaryID()));
        }

        public setSelectedItem(item: ExecutableItem): void {
            this.getComponentParameter().clientCallback.setSelectedItems([item]);
        }

        public getSelectedItem(): ExecutableItem {
            return this.getComponentParameter().clientCallback.getFinalChoice();
        }

        public isVisible(): boolean {
            return this.getComponentParameter().visible;
        }

        public toggleVisibility(event: MouseEvent): void {
            this.getComponentParameter().visible ? this.hide() : this.show(event);
        }

        public show(event: Partial<MouseEvent>): void {
            this.getComponentParameter().currentMouseEvent = event;
            this.getComponentParameter().visible = true;
            DropDownHandler.dropdownSvc.setHandler(this);
        }

        public hide(): void {
            this.getComponentParameter().visible = false;
            DropDownHandler.dropdownSvc.setHandler(this);
        }

    }

}
