import {IInteractionJSON} from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import {GroupSelectorShortcutComponent} from "../components/chat/group-selector-shortcut/group-selector-shortcut.component";
import {ExecutableItem} from "../services/controllers-services/security-controller/executable-item";
import { Group } from "@colmeia/core/src/business/group";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import {
    GroupShortcutHandlerService,
    IGroupShortcutStats,
    ILastMessage
} from "../services/group-shortcut-handler.service";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import {removeUniquizer} from "@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules";
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';

export interface IShortcutParentInstance {
    mustResortGroups(): void;
}

export interface IGroupSelectorShortcutHandlerParameter {
    service: GroupShortcutHandlerService;
    group: Group;
}

export class GroupSelectorShortcutHandler {
    private parameter: IGroupSelectorShortcutHandlerParameter;
    private slave: GroupSelectorShortcutComponent;
    private _island: unknown;

    get island(): unknown { return this._island };

    constructor(parameter: IGroupSelectorShortcutHandlerParameter){
        this.parameter = parameter;
    }

    public getLastMessage(): ILastMessage {
        return this.getStats().lastMessage;
    }

    public getStats(): IGroupShortcutStats {
        return this.parameter.service.getStatsForGroup(this.getGroupID());
    }

    public setSlaveInstance(instance: GroupSelectorShortcutComponent): void {
        this.slave = instance;
        this.mustResortGroups();
    }

    public getLastInteractionTimeStamp(): number {
        const lastMsg = this.getLastMessage();
        return isValidRef(lastMsg) ? lastMsg.clocktick : 0;
    }

    public mustResortGroups(): void {
        this.parameter.service.mustResortGroups();
    }

    public getLastInteraction(): Interaction {
        // ordem de chegada de service request
        // ordem de quanto tempo não se responde ao cliente
        return undefined;
    }


    public getOpenServiceRequest(): StartServiceChat {
        const root = <StartServiceChat>this.getLastInteraction().getRoot();
        return root;
    }

    public getGroup(): Group {
        return this.parameter.group;
    }

    public getGroupID(): TGlobalUID {
        return this.getGroup().getPrimaryID();
    }

    public getGroupName(): string {
        return removeUniquizer(this.parameter.group.getName());
    }

    public setIsland(island: unknown): void {
        this._island = island;
    }
}
