import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { Serializable, TSubMenuControl } from '@colmeia/core/src/business/serializable';
import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { EIdMenus } from '@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { EScreenGroups } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { isInEnum, isInvalid, isInvalidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { ValueOf } from '@colmeia/core/src/tools/utility-types';
import { DashboardMenuHandler } from 'app/components/dashboard/dashboard-menu/dashboard-menu.handler';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { IMenuDashboardOption, MenuDashboardService } from 'app/components/menu-dashboard/menu-dashboard.service';
import { TRapCurrentDashboardClientRoutes } from 'app/model/dashboard/db/dashboard-db-mapper';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { SecurityContextSignal } from 'app/model/signal/state-signals/sec-contex-signal';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { RoutingService } from 'app/services/routing.service';
import { SignalListenerService } from 'app/services/signal/signal-listener';
import { environment } from 'environments/environment-client';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { mapMenuOrderAndGrouping } from './dashboard-menu-order-grouping-mapper';

type TAllDevFeatureMenu = { [key in EIdMenus]?: true };
export const allDevFeatureMenus: TAllDevFeatureMenu = {
    // [EIdMenus.AttendanceFollowUp]: true
}

export interface IDashboardMenuItem {
    primaryID: string;
    title: string;
    icon: string;
    goTo(event?: Event): void;
    idMenu: string;
    fullRoute: string;
    order?: number;
    grouping?: string;
}

export type TDashboardMenuItemArray = IDashboardMenuItem[];

type DashboardPaths = Extract<ValueOf<typeof routeList.dashboard.children>, { path: string }>['path'];

type FilterNotNumber = {
    [key in DashboardPaths]: string;
};


@Component({
    selector: 'app-dashboard-menu',
    templateUrl: './dashboard-menu.component.html',
    styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent extends RootComponent<'closeMenu'> implements OnInit {

    get dashboardModule(): IMenuDashboardOption { return this.parameters.module }

    @Input()
    handler!: DashboardMenuHandler;

    get parameters() { return this.handler.parameters }

    public routes: typeof routeList.dashboard.children = routeList.dashboard.children;
    public menuItems: TDashboardMenuItemArray = [];
    public menuItemSections: TDashboardMenuItemArray[] = [];
    public loading: boolean = true;
    public screenGroupItems: TSerializableArray;
    private subscription: Subscription;
    public expanded: boolean = false;

    get isMinimized(): boolean {
        return this.menuDashboardSvc.isMinimized;
    }

    constructor(
        private routeSvc: RoutingService,
        private dashboardSvc: DashBoardService,
        private router: Router,
        private listenerSvc: SignalListenerService,
        private menuDashboardSvc: MenuDashboardService,
        private warningSvc: GlobalWarningService,
    ) {
        super({
            closeMenu: gTranslations.fragments.closeMenu
        });
        this.listenerSvc.listenToSecurityChangedContext(this);
    }

    async ngOnInit() {
        this.init();

        const isFirstOf: boolean = this.menuDashboardSvc.allOptions
        .filter( i => this.dashboardSvc.getAllowedMenuIDsSync(i.config?.screenGroup.name)?.length )
        .find( i => i.belongsToPath === this.dashboardModule.belongsToPath)?.primaryId === this.dashboardModule.primaryId;
    }

    setMenuComponent() {
        this.dashboardSvc.menuComponent = this;
    }

    isCurrentDashboard(): boolean {
        return this.dashboardSvc.currentDashboard === this.parameters.module.modulePath;
    }

    public async init(): Promise<void> {
        await this.mountMenu();

        this.loading = false;
    }


    public async goToMenu(): Promise<void> {
        await this.router.navigateByUrl(routeList.menu.path);
    }

    public subscribe(): void {
        this.subscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((): void => {
                if (this.dashboardSvc.isOnDashboard()) this.onDashboardRouteChange();
            })
        ;
    }

    public unsubscribe(): void {
        this.subscription?.unsubscribe();
    }

    public ngOnDestroy() {
        this.unsubscribe();

        if (this.dashboardSvc.menuComponent === this) {
            delete this.dashboardSvc.menuComponent;
        }
    }

    private async mountMenu() {
        const pathRoute = this.dashboardModule.modulePath;
        const dashboardInfo = this.dashboardSvc.slowGetDashboardInfoFromCurrentDashboard(pathRoute);
        const screenGroupName: EScreenGroups = dashboardInfo.screenGroup.name;
        const access: string[] = await this.dashboardSvc.getAllowedMenuIDs(screenGroupName);
        this.initScreenGroupItems(screenGroupName);

        if (isValidRef(screenGroupName)) {
            let menus: TDashboardMenuItemArray = this.getMenuItems(screenGroupName, pathRoute)
            menus = menus.filter(item => access.includes(item.idMenu));

            this.menuItems = menus;

            // has grouping
            if (menus.some(item => isValidRef(item.grouping))) {
                this.menuItemSections = this.getMenusBySections(menus);
            }
        }

        this.updateCurrentDashboardByRoute();
    }

    private getMenusBySections(menus: TDashboardMenuItemArray) {
        const sections = [];
        let menusWithGrouping = 0;

        const groupings = menus.reduce((groupings: Array<string | undefined>, item) => {
            if (isValidRef(item.grouping)) {
                menusWithGrouping++;

                if (!groupings.includes(item.grouping)) {
                    groupings.push(item.grouping);
                }
            }

            return groupings;
        }, []);

        if (menusWithGrouping < menus.length) {
            // usado para agrupar os que não tem grupo definido
            groupings.push(undefined);
        }

        for (let grouping of groupings) {
            const groupItems = menus.filter(item => item.grouping === grouping);
            sections.push(groupItems);
        }

        return sections;
    }

    public onDashboardRouteChange(): void {
        this.updateCurrentDashboardByRoute();
    }

    public getCurrentScreenGroupItemByPath(path: string): Serializable | null {
        const pathWithQueryParams = path.split('/')[3];

        if (isInvalid(pathWithQueryParams)) {
            return null;
        }

        const indexOfQuerySign = pathWithQueryParams.indexOf('?')
        const sgPath: string = indexOfQuerySign !== -1 ? pathWithQueryParams.slice(0, indexOfQuerySign) : pathWithQueryParams;
        const item: Serializable =  this.screenGroupItems.find((item: Serializable) => sgPath === this.dashboardSvc.getFirstIdAngularRoute(item.getAngularRouteID()));

        return item;
    }

    public updateCurrentDashboardByRoute(): void {
        const path: string = this.routeSvc.getCurrentPath();
        const item = this.getCurrentScreenGroupItemByPath(path)
        const isNotInMyModule: boolean = !path.includes(`/${this.dashboardModule.modulePath}/`);

        if (isInvalid(item) || isNotInMyModule) {
            return;
        }

        const hasAccess: boolean = this.menuItems.map((item: IDashboardMenuItem) => item.title).includes(item.getName());

        if (!hasAccess) return this.goToFirstMenuWithAccess();

        this.dashboardSvc.setCurrentDashboarMenuItem(item, this);
    }

    public goToFirstMenuWithAccess(): void {
        if (isInvalidArray(this.menuItems)) { this.goToMenu(); return; }

        this.menuItems[0].goTo();
    }

    public goBack(): void {
        this.routeSvc.navigateToId(routeList.menu.path);
    }


    public changeOnSecureContextSignCallback(sign: SecurityContextSignal): void {
        this.init();
    }


    public isCurrentDashboardItem(item: IDashboardMenuItem): boolean {
        return item.title === this.dashboardSvc.getCurrentDashboarMenuItem()?.getName();
    }

    public get currentDashboardMenuItemName(): string {
        const item: Serializable = this.dashboardSvc.getCurrentDashboarMenuItem();
        return isValidRef(item) ? item.getName() : 'No item';
    }

    private initScreenGroupItems(screenGroupId: string): void {
        this.screenGroupItems = Serializable.getVisualElementFromScreenGroup(screenGroupId);
    }

    private getMenuItems(screenGroupId: string, pathRoute: string): TDashboardMenuItemArray {
        const subMenus: TSubMenuControl = Serializable.getAllSubMenus(screenGroupId);
        
        const mapPrimaryIDToIDMenu: Map<TGlobalUID, string> = new Map();
        for (let subMenu of subMenus) {
            mapPrimaryIDToIDMenu.set(subMenu.primaryID, subMenu.idMenu);
        }

        const menuItems: TDashboardMenuItemArray = this.screenGroupItems
            .map((item: Serializable): IDashboardMenuItem => {
                const idMenu: string = mapPrimaryIDToIDMenu.get(item.getPrimaryID());

                return ({
                    primaryID: item.getPrimaryID(),
                    title: item.getName(),
                    icon: item.getPrimaryID() === 'SNManagement/users' ? 'supervised_user_circle' : item.getIcon(),
                    goTo: (event?: Event) => {
                        event?.preventDefault();
                        this.goToItem(item, pathRoute, idMenu)
                    },
                    fullRoute: this.routeSvc.mountFullRoute(routeID.dashboard, [pathRoute, item.getAngularRouteID()]),
                    idMenu,
                    order: mapMenuOrderAndGrouping[idMenu]?.order,
                    grouping: mapMenuOrderAndGrouping[idMenu]?.grouping,
                })
            })
            .filter(item => allDevFeatureMenus[item.idMenu] ? environment.allDevFeatures : true )
            .sort(this.sortCompareFn)
        ;

        return menuItems;
    }

    private sortCompareFn(a: IDashboardMenuItem, b: IDashboardMenuItem): number {
        const aValue = a.order !== undefined ? a.order : Number.MAX_SAFE_INTEGER;
        const bValue = b.order !== undefined ? b.order : Number.MAX_SAFE_INTEGER;

        return aValue - bValue;
    }

    public get isDev(): boolean {
        return environment.allDevFeatures && !environment.production;
    }

    public goToItem(item: Serializable, pathRoute: string, idMenu: string): void {
        if (this.isDev && !isInEnum(EIdMenus, idMenu as EIdMenus)) this.warningSvc.showError(`IdMenu '${idMenu}' fora do hash EIdMenus`)

        console.log('idMenu: ', idMenu);
        console.log('primaryID: ', item.getPrimaryID());
        console.log('angular routeid: ', item.getAngularRouteID());
        console.log('path: ', pathRoute);
        this.dashboardSvc.currentDashboard = pathRoute as TRapCurrentDashboardClientRoutes;
        this.dashboardSvc.setCurrentDashboarMenuItem(item, this);
        this.routeSvc.navigateToId(routeID.dashboard, pathRoute, item.getAngularRouteID());
    }
}
