import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { Participant } from '@colmeia/core/src/business/participant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { ExecutableItem, ExecutableType, TExecutableItemArray } from './executable-item';
import { Group } from '@colmeia/core/src/business/group';
import { constant } from '@colmeia/core/src/business/constant';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { isRoot } from '@colmeia/core/src/rules/filters';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { TDriverFeedbackArray } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { isValidRef, isValidArray } from '@colmeia/core/src/tools/utility';
import { SubscriptionType } from "@colmeia/core/src/interaction/subscription/subscription-type";
import { GroupType } from '@colmeia/core/src/business/group-type';
import {SessionService} from "../../session.service";
import {routeID} from "../../../model/routes/route-constants";
import { IBasicRenderInfo } from '@colmeia/core/src/business/small-serializable';
import {EGroupNavigation, ENavigatorFilter, AppBarShortcuts} from "@colmeia/core/src/shared-business-rules/visual-constants";

interface IGroupTypeRoutesAllowed {
    [idGroupType: string]: Array<string>
};

const fullPackage: Array<string> = [
    routeID.features.newSubGroup, // *
    //routeID.features.newSpokenGroup,
    routeID.features.newChannel, // *
    routeID.features.newBroadcaster, // *
    routeID.features.newDistributionList, // *
    routeID.features.newMassCommunication, // *
    routeID.features.newFeatureCreator, // *
    AppBarShortcuts.JobsStatus,
    //routeID.features.newAnonymous,
    //routeID.features.newInventory
];

const routesAllowed: IGroupTypeRoutesAllowed = {
    [constant.groupType.functionalRoot.corporate]: [...fullPackage],
    [constant.groupType.functionalRoot.tribe]: [...fullPackage],
    [constant.groupType.root]: [routeID.features.newCorporate, routeID.features.newTribe],
    [constant.groupType.functional.broadcast]: [],
    [constant.groupType.standard.channel]: [],
    [constant.groupType.functional.spokenGroup]:  [],
    [constant.groupType.standard.standardGroup]: [...fullPackage],
    [constant.groupType.functional.distributionList]: [],
    [constant.groupType.functional.featureCreator]: [],
    [constant.groupType.standard.anonymous]: [],
    [constant.groupType.functional.inventory]: []
};


export interface IDataBuilder {
    currentParticipant: Participant;
    currentGroup: Group;
    currentAvatar: Avatar,
    playerInfo: PlayerCachedInfo;
    idGroup: TGlobalUID;
    isEmpty: boolean;
    sessionService: SessionService
};



export class SafeMenuBuilderServices {
    private data: IDataBuilder;

    constructor( menuBulder: IDataBuilder) {
        this.data = menuBulder;
    }

    public getDropDownFeatures(screenGroup: TGlobalUID): TExecutableItemArray {
        const result: TExecutableItemArray = [];

        const isEmpty: boolean = this.data.isEmpty;
        const screenGroupSerializables = Serializable.getVisualElementFromScreenGroup(screenGroup);
        const currentGroup: Group = this.data.currentGroup;
        const idGroupType: TGlobalUID = currentGroup ? currentGroup.getGroupType().getPrimaryID() : constant.groupType.root;
        const isRootGroup: boolean = isRoot(idGroupType);
        const cached: PlayerCachedInfo = this.data.sessionService.getPlayerInfo();
        const isApprover: boolean = isValidRef(cached) && this.data.sessionService.isApprover();

        for (const serializable of screenGroupSerializables) {
            const canExecute: boolean = (
                isApprover ||
                (
                    !serializable.getPrivilegeNeeded() ||
                    serializable.doIHaveThePermissionsFor(
                        (currentGroup)
                        ? cached.getGroupAvatarRole(currentGroup.getPrimaryID(), this.data.sessionService.getSelectedAvatarID())
                        : []
                    )
                )
            );

            const routeAllowed = (
                (!isRootGroup || isApprover) &&
                routesAllowed[idGroupType] && routesAllowed[idGroupType].some(route =>
                    route === serializable.getPrimaryID()
                )
            );

            if (routeAllowed && (canExecute || isEmpty || isRootGroup)) {
                result.push(new ExecutableItem(serializable, ExecutableType.feature, {
                    inMemory: true,
                    canExecuteOperation: canExecute,
                    accessWithAnotherAvatar: false,
                    accessWithCurrentAvatar: false,
                    onlyInvitees: false,
                    needToRequestParticipant: false,
                }));
            }

        }
        return result;
    };

    // All roles handled here getItemsOnlyAllowedToShow
    getMoreOptionsSafeMenuFiltered(items: TSerializableArray): TExecutableItemArray {
        const sessionSVC: SessionService = this.data.sessionService;

        // const roles: TRoleArray = sessionSVC.getPlayerInfo().getGroupAvatarRole(sessionSVC.getSelectedGroupID(), sessionSVC.getSelectedAvataID());
        // const groupType: GroupType = this.data.sessionService.getSelectedGroup().getGroupType();

        const execItems: TExecutableItemArray = items.map(item => new ExecutableItem(
            item,
            ExecutableType.feedbacks,  {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }))
        return execItems
    }

    public getTimedMenu(): TExecutableItemArray {
        const sArray: TSerializableArray = Serializable.getVisualElementFromScreenGroup(constant.objectType.dueDateType);
        const ret: TExecutableItemArray = [];

        for (const element of sArray) {

            ret.push(new ExecutableItem(element, ExecutableType.timedMessageOptions, {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }));
        };
        return ret;
    };

    public getInteractionTypeFilterAvailable(): TExecutableItemArray {
        const sArray: TSerializableArray = InteractionType.getInteractionTypeArray()
            .filter(interactionType => interactionType.isFilterable());
        const ret: TExecutableItemArray = [];

        for (const element of sArray) {
            ret.push(new ExecutableItem(element, ExecutableType.interactionType,  {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }));
        };

        return ret;
    }

    public getFeedbackDriverAvailable(intTypes: TDriverFeedbackArray = null): TExecutableItemArray {
        const sArray: TSerializableArray = isValidArray(intTypes)
            ? intTypes.map((i) => {return InteractionType.staticFactory(i.idDriverInteraction)})
            : []

        const ret: TExecutableItemArray = [];

        for (const element of sArray) {
            ret.push(new ExecutableItem(element, ExecutableType.feedbackDriver,  {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }));
        };

        return ret;
    }


    public getNavigationBarFilters(filterName: string): TExecutableItemArray {
        const sArray: TSerializableArray = Serializable.getVisualElementFromScreenGroup(filterName);

        const ret: TExecutableItemArray = [];
        const typeMenu: ExecutableType = filterName == ENavigatorFilter.popularity ?
                ExecutableType.popularityFilter : ExecutableType.contextFilter;

        for (const element of sArray) {

            ret.push(new ExecutableItem(element, typeMenu,  {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }));
        };
        return ret;
    }

    public getGenericScreenGroupItems(type: ExecutableType, screenGroup: TGlobalUID): TExecutableItemArray {
        const ret: TExecutableItemArray = [];
        const sArray: TSerializableArray = Serializable.getVisualElementFromScreenGroup(screenGroup);

        for (const element of sArray) {
            ret.push(new ExecutableItem(element, type,  {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }));
        };

        return ret;
    }

    public getSerializableFeaturesFromScreenGroup(screenGroup: TGlobalUID): IBasicRenderInfo[] {
        const sArray: TSerializableArray = Serializable.getVisualElementFromScreenGroup(screenGroup);
        return sArray;
    }

    public getDriverFeedbacks(driverFeedbackId: TGlobalUID): TExecutableItemArray {
        const sArray: TSerializableArray = Feedback.getInteractionFeedback(InteractionType.staticFactory(driverFeedbackId), -3);
        const ret: TExecutableItemArray = [];

        for (const element of sArray) {
            ret.push(new ExecutableItem(element, ExecutableType.feedbacks,  {
                accessWithAnotherAvatar: false,
                accessWithCurrentAvatar: true,
                canExecuteOperation: true,
                inMemory: true,
                needToRequestParticipant: false,
                onlyInvitees: false,
            }));
        };

        return ret;
    }

    public getExecutableItemFromPrimaryID(primaryID: TGlobalUID, executableType: ExecutableType): ExecutableItem {
        return new ExecutableItem(Serializable.staticFactory(primaryID),
            executableType,  {
            accessWithAnotherAvatar: false,
            accessWithCurrentAvatar: true,
            canExecuteOperation: true,
            inMemory: true,
            needToRequestParticipant: false,
            onlyInvitees: false,
        });
    }

    public getSerializableFeatureFromPrimaryID(primaryID: TGlobalUID): IBasicRenderInfo {
        const serializable: Serializable = Serializable.staticFactory(primaryID);
        return serializable;
    }

    public getExecutableFromSerializables(serializables: TSerializableArray,  executableType: ExecutableType): TExecutableItemArray{
        return serializables.map(
            serializable => this.getExecutableItemFromPrimaryID(
                serializable.getPrimaryID(),
                executableType
            )
        )
    }

    public getFollowAvatarButton(avatarID: TGlobalUID): TExecutableItemArray {
        let ret = [];

        ret.push(...SubscriptionType.getAvatarFollowOptions());

        return this.getExecutableFromSerializables(ret, ExecutableType.followPeople);
    }

    public getFollowGroupButton(groupID: TGlobalUID): TExecutableItemArray {

        const group: Group = Group.staticFactory(groupID);
        const groupType: GroupType = group.getGroupType();
        const subscriptions: TSerializableArray = [...SubscriptionType.getGroupFollowOptions(), Serializable.staticFactory(EGroupNavigation.createParticipantOnFreeGroup)];
        const filters: Array<(serializable: SubscriptionType) => boolean> = [];

        if (!groupType.isFunctionalRoot() || !this.data.sessionService.hasParticipantOnGenealogy(groupID)) {
            filters.push((serializable: SubscriptionType) => serializable.isNot(constant.subscription.request.group.employee));
        }

        if (! this.data.sessionService.needToAskToParticipate(groupID)
            || this.data.sessionService.belongsToGroup(groupID)
            || groupType.isAlwaysSameParentParticipants()
        ) {
            filters.push((serializable: SubscriptionType) => serializable.isNot(constant.subscription.request.group.groupParticipation));
        }

        if (groupType.isPersonal()) {
            filters.push((serializable: SubscriptionType) => false);
        }

        if (
            this.data.sessionService.needToAskToParticipate(groupID)
            || this.data.sessionService.hasAccessWithCurrentAvatar(groupID)
        ) {
            filters.push((serializable => serializable.isNot(EGroupNavigation.createParticipantOnFreeGroup)));
        }

        const filteredSubscriptions = filters.reduce((acc, val) => {
            return acc.filter(val);
        }, subscriptions);

        return this.getExecutableFromSerializables(
            filteredSubscriptions,
            ExecutableType.followGroup
        );
    }
}
