import { I360Media } from '../core-constants/bot';
import { SHA256Hash, TGlobalUID } from '../core-constants/types';
import { EDelivery360Action } from './interaction-interfaces';

export type TIChosenGroupsArray = Array<IChosenGroups>;
export type TIIChosenAvatars = Array<IChosenAvatars>;

export interface IChosenGroups {
    idChosenGroup: TGlobalUID,
    idGroupType: TGlobalUID,
};

export interface IChosenAvatars {
    idChosenAvatar: TGlobalUID,
};


export interface IFileUploadAdditionalParams {
    isMustSaveOnColmeiaBucket: boolean
}

export interface IServerFileInfo {
    size: number;
    name: string;
    type: string;
    shares: number;
    hash: string;
    idMedia: string;
    mmKey: TGlobalUID;
    idMediaTag?: TGlobalUID,
    isMustSaveOnColmeiaBucket?: boolean;
}

export interface IFileInfoChannelAndClockTick {
    clockTickCreatedAt?: number,
    channel: EDelivery360Action,
}
export interface IFileInfoChannelAndClockTickMedia extends I360Media, IFileInfoChannelAndClockTick {
    sha256Hash: SHA256Hash;
    bucketName?: string;
}

export type TBasicUniveralInfoArray = Array<IBasicUniversalInfo>;

export interface IBasicUniversalInfo {
    idObjectType: TGlobalUID;
    primaryID: TGlobalUID;
    clockTick: number;
    name: string;
    idMedia: TGlobalUID;
    isValid?: boolean;
    idInteractionType?: TGlobalUID;
    email?: string;
};
