<div [class.is-recording]="isRecording" [class.is-uploading]="fileUploadProgress != 0 && fileUploadProgress != 100" [class.1uploaded]="fileUploadProgress === 100">
  <div class="counter">
    <div class="media-recorder__container">
      <span *ngIf="fileUploadProgress != 0 && fileUploadProgress != 100" class="upload-progress-bar" [style.transform]="'scaleX('+(fileUploadProgress / 100)+')'">
      </span>

      <button type="button" class="btn-label" *ngIf="hasRecording && fileUploadProgress == 0" (click)="discardRecording()">
        <mat-icon>
          delete
        </mat-icon>&nbsp;
        descartar
      </button>

      <button type="button" class="btn-label" (click)="onRecordBtnClick()">
        <ng-container *ngIf="fileUploadProgress != 0">
          {{ fileUploadProgress }}%
        </ng-container>
        <ng-container *ngIf="fileUploadProgress == 0">
          <mat-icon>
            {{ isRecording ? 'stop' : 'fiber_manual_record' }}
          </mat-icon>&nbsp;
          {{ recordBtnMessage }}
        </ng-container>
      </button>
      <div class="record-feedback-container">
        <mat-icon>mic</mat-icon>
        <span class="__media-recorder-timer">
          {{ getRecordedTime() }}
        </span>
      </div>
    </div>
<!--
    <div class="counter__progress-bar" *ngIf="fileUploadProgress != 0">
      <progressbar [fileName]="' '" [progressValue]="fileUploadProgress">
      </progressbar>
    </div> -->

  </div>
</div>
