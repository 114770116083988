import { isValidRef, isInvalid } from "@colmeia/core/src/tools/utility";
import { ICitedAvatar, TCitedAvatarArray } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";

// from client to server
export function formatTextToServer(text: string): string {
    if(isValidRef(text)){
        text = brToNewLines(text);
        text = formatText(text);
        return text;
    }
    return '';
}

export function brToNewLines(text: string): string {
    return text.replace(/<br\s*[\/]?>/gi, "\n");
}

export function formatText(text: string): string {
    let parser = new DOMParser;
    let dom = parser.parseFromString(text, 'text/html');
    return dom.body.textContent;
}

// from server to client
export function formatTextToClient(text: string): string {
    if(isValidRef(text)){
        text = newLinesToBr(text);
        return text;
    }
    return '';
}

export function newLinesToBr(text: string): string {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

// TAG cm-citation

export function getCitationTag(citedAvatar: ICitedAvatar): string {
    return `<cm-citation style='font-weight: bold;' idAvatarCited='${citedAvatar.idAvatar}'>@${citedAvatar.name}</cm-citation>`;
}

export function setCitationsOnText(citations: TCitedAvatarArray, message: string): string {
    citations.map((citation) => {
        const regex = new RegExp(`@${citation.name}`, 'g');
        message = message.replace(regex, getCitationTag(citation));
    });
    return message;
}

export function removeCitationsFromList(citations: TCitedAvatarArray, message: string): TCitedAvatarArray {
    citations = citations.filter((citation) => {
                    let regex = new RegExp(`@${citation.name}`);
                    return regex.test(message);
                });
    return citations;
}
