import { formatMessage } from "@colmeia/core/src/tools/barrel-tools";
import { $RegExp, matchGroup } from "@colmeia/core/src/tools/utility-types";


const clonePattern = new $RegExp(`Clone (?:[(](?<number>\\d+)[)] )?- (?<content>.*)`);

interface CloneConfig {
    content: string;
    number?: number;
}

function printCloneName(config: CloneConfig) {
    return formatMessage`Clone ${config.number && `(${config.number}) `}- ${config.content}`;
}


export function getCloneName(name: string) {
    const { groups } = matchGroup(clonePattern)(name) ?? {};
    if (groups) {
        const config: CloneConfig = {
            content: groups.content,
            number: groups.number ? +groups.number + 1 : 1,
        }
        return printCloneName(config);
    }

    return printCloneName({
        content: name,
    });
}