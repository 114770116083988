import { IChatBarWindowParameter, IChatBarWindowClient } from "app/model/chat-bar/chat-bar-window.model";
import { RecorderHandler } from "../recorder-handler";
import { AttachBarHandler } from "../attach-bar-handler";

export class ChatBarWindowHandler {

    private _parameter: IChatBarWindowParameter;

    public static getDefaultParameter(clientInstance: IChatBarWindowClient = null): IChatBarWindowParameter {
        return {
            minimized: false,
            chatBackboneModel: null,
            audioRecorder: false,
            attachment: false,
            onUserWindowClosed: () => clientInstance.onChatBarWindowClosed(),
        };
    }

    constructor(parameter: IChatBarWindowParameter) {
        this._parameter = parameter;
    }

    public getParameter(): IChatBarWindowParameter {
        return this._parameter;
    }

    public setShown(shown: boolean): void {
        // this.getParameter().shown = shown;

    }

    public isShown(): boolean {
        return true;
    }

    public canMinimize(): boolean {
        return true;
    }

    public canClose(): boolean {
        return true;
    }

    public setShowAudioRecorder(enabled: boolean): void {
        this.getParameter().audioRecorder = enabled;
    }

    public getShowAudioRecorder(): boolean {
        return this.getParameter().audioRecorder
    }

    public setShowAttachment(enabled: boolean): void {
        this.getParameter().attachment = enabled;
    }

    public getShowAttachment(): boolean {
        return this.getParameter().attachment;
    }

    public setAudioRecorderHandler(handler: RecorderHandler): void {
        this.getParameter().audioRecorderHandler = handler;
    }

    public setAttachmentHandler(handler: AttachBarHandler): void {
        this.getParameter().attachmentHandler = handler;
    }
}
