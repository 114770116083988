export class ArrayUtils {
    static uniquizeByAttribute<T>(array: T[], attr: keyof T): Record<string, T> {
        return array.reduce((acc, item) => {
            const key: string = <string><unknown>item[attr]
            acc[key] = item;
            return acc;
        }, {} as Record<string, T>);
    }

    static uniquizeByAttributeAndGetValues<T>(array: T[], attr: keyof T) {
        return Object.values(this.uniquizeByAttribute(array, attr))
    }

    static uniquizer<T>(arr: T[]): T[] {
        return Array.from(new Set(arr));
    }

    /**
     * Cria um group by de uma propertyName do objeto dentro da lista
     * const botList = [{nsType: 'bot', nName: 'bot 1'},{nsType: 'bot', nName: 'bot 2'}]
     * ex: groupByProperty(botList, 'nsType') -> {bot: [{nsType: 'bot', nName: 'bot 1'},{nsType: 'bot', nName: 'bot 2'}]}
     * @param arr 
     * @param propertyName 
     * @returns 
     */
    static groupByPropertyName<K, T>(arr: T[], propertyName: string): Map<K, T[]> {
        const grouped = new Map<K, T[]>();
        for (const item of arr) {
            const key = item[propertyName];
            if (grouped.has(key)) {
                grouped.get(key)?.push(item);
            } else {
                grouped.set(key, [item]);
            }
        }
        return grouped;
    }
}