import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { Serializable } from '@colmeia/core/src/business/serializable';
import {NDropDown} from "../../../handlers/dropdown.handler";
import {
    ExecutableItem,
    ExecutableItemData,
    TExecutableItemArray
} from "../../../services/controllers-services/security-controller/executable-item";
import { navigationRules } from '@colmeia/core/src/core-constants/security-constant';


const DefaultItem: ExecutableItemData = {
    name: '--------------',
    primaryId: navigationRules.default,
}

export class DropdownListCallback implements NDropDown.IDropDownClientCallback, NDropDown.IDropDownClickedDescriptor {
    private allItems: TExecutableItemArray;
    private selectedItems:  TExecutableItemArray;
    private dropDownParameter: NDropDown.IDropDownComponentParamater;
    private itemClicked: NDropDown.IDropDownClickedItem;

    constructor(
        parameter: NDropDown.IDropDownComponentParamater,
    ) {
        this.allItems = parameter.allItems;
        this.checkDefaultItem();
        this.selectedItems = parameter.defaultSelectedItems;
        this.dropDownParameter = parameter;
    }

    public setSelectedItems(items: TExecutableItemArray): void{ this.selectedItems = items; };
    public getSelectedItems(): TExecutableItemArray { return this.selectedItems; };
    public getAllItems(): TExecutableItemArray { return this.allItems; };

    public setItems(items: TExecutableItemArray): void {
        this.allItems = items;
        this.checkDefaultItem();
    }

    private checkDefaultItem() {
        if (this.allItems.length == 0) {
            return;
        }
        let hasDefautItem = this.allItems.some(item => item.isDefault);
        if (!hasDefautItem) {
            let defaultItem = new ExecutableItem(DefaultItem, this.allItems[0].getItemType(), this.allItems[0].getItemState(), true);
            this.allItems.unshift(defaultItem);
        }
    }

    public hasChosenAtLeastOneItem(): boolean {
        return this.selectedItems && !!this.selectedItems.length;
    };

    public getFinalChoice(): ExecutableItem {
        return this.hasChosenAtLeastOneItem() ? this.selectedItems[0] : null
    };

    public getFinalChoiceID(): TGlobalUID {
        return this.getFinalChoice() ? this.getFinalChoice().getPrimaryID() : null
    };

    public onDropDownItemClickedCallback(clickDescriptor: NDropDown.IDropDownClickedItem): void {
        if (this.dropDownParameter.mutuallyExclusive) {
            // hiding dropdown
            this.dropDownParameter.visible = false;
            if (this.selectedItems.length) {
                // removes oldValue from the array and sets dropdown
                this.selectedItems.splice(0, 1);
            }
        }
        this.selectedItems.push(clickDescriptor.itemClicked);
        this.itemClicked = clickDescriptor;
        this.dropDownParameter.clientInstance.onDropdownOptionClicked(this);
    };

    public getMouseEvent(): MouseEvent { return this.itemClicked.event }
    public getItemClicked(): ExecutableItem { return this.itemClicked.itemClicked }


}
