import { ROUTES } from "../core-constants/routes-shared";
import {
    ApiRequestType,
    apiRequestType, EThirdParty, EWidgetRequest
} from "../request-interfaces/message-types";
import { asConst } from "../tools/type-utils";
import { isIn } from "../tools/utility";
import { FindKeysWithValueOf, Negate } from "../tools/utility-types";
import { getRequestTypeConfig, TDefinedRequestTypeConfig } from "./dashboard-routes/functions/getRequestTypeConfig";


const messageRoutes = asConst()({
    [apiRequestType.security.init]: ROUTES.v1.controlled,
    [apiRequestType.getInviteeDataToSignUp]: ROUTES.v1.controlled,
    [EThirdParty.thirdPartyLogin]: ROUTES.v1.auth,
    [apiRequestType.security.signUp]: ROUTES.v1.auth,
    [apiRequestType.security.firstStepSignup]: ROUTES.v1.auth,
    [apiRequestType.security.forgotPass]: ROUTES.v1.auth,
    [apiRequestType.security.logUserFromEmail]: ROUTES.v1.auth,
    [EWidgetRequest.autoLogin]: ROUTES.v1.auth,
    [apiRequestType.security.logUserFromShakingHands]: ROUTES.v1.auth,
    [apiRequestType.unAuthenticatedLookup]: ROUTES.v1.controlled,
    // [apiRequestType.dashboardExtra.crm.crmFetchDomainItens]: ROUTES.v1.controlled,
} as const);



export type AllLookupEntries = FindKeysWithValueOf<TDefinedRequestTypeConfig, { isLookup: true }>;

interface IGetRouteByMessageTypeInput {
    messageType: string;
    isAnEmbeddedInstance: boolean;
}

interface IGetRouteByMessageTypeOutput {
    path: string;
    isBotEndpoint: boolean;
}


export function getRouteByMessageType({ messageType, isAnEmbeddedInstance }: IGetRouteByMessageTypeInput): IGetRouteByMessageTypeOutput {
    const selected = messageRoutes[messageType];
    if (selected) {
        return {
            path: selected,
            isBotEndpoint: false,
        };
    }

    if (getRequestTypeConfig(messageType)?.isLookup) {
        return {
            path: `${ROUTES.v1.lookup}/${messageType}`,
            isBotEndpoint: false,
        };
    }

    if (isAnEmbeddedInstance) {
        return {
            path: `${ROUTES.webhook.webChat.business}/${messageType}`,
            isBotEndpoint: true,
        }
    }

    return {
        path: `${ROUTES.v1.business}/${messageType}`,
        isBotEndpoint: false,
    };
}


