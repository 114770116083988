import { Injectable } from '@angular/core';
import { SempaphoreService, } from './semaphore-service';
import { IClientSemaphoreConfig, ELocalQueueName, CoreQueueService, TIControlledRecordArray, ILocalQueuEntry } from '@colmeia/core/src/core-queue/core-queue-service';
import { HardwareLayerService } from './hardware';
import { TArrayID } from '@colmeia/core/src/core-constants/types';

export {ELocalQueueName};

const semaphoreBatchSend: IClientSemaphoreConfig = {
    expirationLockTime: 5000,
    numberOfTries: 0,
    throwErrorIfNotLocked: false,
    retryInterval: 300
};

const waitSemaphore: IClientSemaphoreConfig = {
    expirationLockTime: 6000,
    numberOfTries: 5,
    throwErrorIfNotLocked: false,
    retryInterval: 1000
};

export interface IQueueServiceConf {
    persistent: boolean;
    isInteraction?: boolean;
    isRequest?: boolean;
};

const persistentPrefix = 'OO';


@Injectable({providedIn: 'root'})
export class QueuService {

    constructor(private hw: HardwareLayerService) {

    };

    private queueConf: {[name: string]:IQueueServiceConf} = {};

    private semaphore: SempaphoreService;

    private queue: CoreQueueService = new CoreQueueService();

    public removeFromQueue(idQueue: ELocalQueueName, id: string): boolean {
       return this.queue.removeFromQueue(idQueue, id);
    };

    public confQueue(queueName: ELocalQueueName, conf: IQueueServiceConf): void {
        this.queueConf[queueName] = conf;
    }

    private isPersistency(queueName: ELocalQueueName): boolean {
        return this.queueConf[queueName] && this.queueConf[queueName].persistent;
    }

    public isRetryInteraction(queueName: ELocalQueueName): boolean {
        return this.isPersistency(queueName) && this.queueConf[queueName].isInteraction;
    };

    public isRetryRequest(queueName: ELocalQueueName): boolean {
        return this.isPersistency(queueName) && this.queueConf[queueName].isRequest;
    };

    private saveToStorage(idQueue: ELocalQueueName, entry: ILocalQueuEntry): void {
        if (this.isPersistency(idQueue)) {
            entry.idQueue = idQueue;
            this.hw.getStorage().putItem(persistentPrefix + entry.id, entry);
        };
    };

    public removeFromStorage(idQueue: ELocalQueueName, id: string): void {
        if (this.isPersistency(idQueue)) {
            this.hw.getStorage().clearItem(persistentPrefix + id);
        }
    }


    public enqueue(idQueue: ELocalQueueName, entry: ILocalQueuEntry): void {
        this.queue.enqueue(idQueue, entry);
        this.saveToStorage(idQueue, entry);
    };

    public republish(idQueue: ELocalQueueName, entry: ILocalQueuEntry): void {
        this.queue.republish(idQueue, entry);
        this.saveToStorage(idQueue, entry);
    };

    public getNextEntry(idQueue: ELocalQueueName): ILocalQueuEntry {
        return this.queue.getNextEntry(idQueue);
    };

    public getAllEntries(idQueue: ELocalQueueName): TIControlledRecordArray {
        return this.queue.getAllEntries(idQueue);
    }

    public getFirstXElements(idQueue: ELocalQueueName, elements: number): TIControlledRecordArray {
        return this.queue.getFirstXElements(idQueue, elements);
    }



    public isOnQueue(idQueue: ELocalQueueName, id: string): boolean {
        return this.queue.isOnQueue(idQueue, id);
    }

    public setSemaphoreDependency(semaphore: SempaphoreService): void {
        this.semaphore = semaphore;
    };

    public clearQueue(): void {
        this.queue.clearQueue();
    }

    public rehydrateQueue(): void {
        const array: TArrayID = this.hw.getStorage().getAllStorageKeys(persistentPrefix);
        for (const id of array) {
            const element = this.hw.getStorage().getItem<ILocalQueuEntry>(id)
            this.queue.enqueue(element.idQueue, element);
        }
    }
}
