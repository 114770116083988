import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ESendActiveMethod, IActiveCampaignMessage, INewAgentCallContact, INewAgentCallContactServer } from "./active-1x1-model";

export function initActiveCallContactClient(): INewAgentCallContact {
    return {
        nsType: ENonSerializableObjectType.activeCall1x1,
        nName: undefined,
        target: undefined,
        channel: undefined,
        idCampaigAction: '',
        activeMessage: {
            customMessage: [],
            campaingMessageVariables: [],
            templateLocalCanonicals: [],
        },
        sentAt: undefined,
        responseToMe: true,
        scheduleBeginning: 0,
        sendMethod: ESendActiveMethod.templateOnly,
    };
}

export function initActiveCallContact(): INewAgentCallContactServer {
    return initActiveCallContactClient() as INewAgentCallContactServer;
}

export function initActiveCampaignMessage(): IActiveCampaignMessage {
    return {
        customMessage: [],
        campaingMessageVariables: [],
        templateLocalCanonicals: [],
    };
}
