import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { isValidArray, isValidFunction } from '@colmeia/core/src/tools/utility';
import { TIEditorVariableArray, compileText, ICompileResponse, IEditorVariable } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { VarEditorHandler, ICompileVariablesResult, IVarEditorClientCallback, IUsedVariablesID, IVarEditorHandlerParameter, EVarEditorEntityType, IFormatVisibility, pickFormatVisibility } from '../../../handlers/var-editor.handler';
import { IContentBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { SnackMessageService } from 'app/services/snack-bar';
import { TIVariablesArray, IVariableClient } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { VarEditorComponent } from 'app/components/var-editor/var-editor/var-editor.component';
import { checkAssetForBrokenVariables, fixBrokenVariablesOnAssetContent } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';

export interface InputTextVariablesHome {
	forceSave(): void;
    hasInvalidText(): boolean;
}
export interface InputTextVariablesHandler extends IFormatVisibility {
	home?: InputTextVariablesHome;
}

@Component({
	selector: 'app-input-text-variables',
	templateUrl: './input-text-variables.component.html',
	styleUrls: ['./input-text-variables.component.scss']
})
export class InputTextVariablesComponent extends RootComponent<
'variables' |
'message' |
'editMessage'
> implements OnInit, IVarEditorClientCallback {
	_handler: InputTextVariablesHandler
	get handler(): InputTextVariablesHandler {
		return this._handler;
	}
	@Input()
	set handler(value: InputTextVariablesHandler) {
		this._handler = value;
		value.home = this;
	}
	@Input() enableContextVariables: boolean;
	@Input() varEditorMode: EVarEditorEntityType;
	@Input() disableFallback: true;
	@Input()
	asset: IContentBasicAsset;

    @ViewChild(VarEditorComponent) varEditor: VarEditorComponent;

	private _schemaVariables: TIVariablesArray;
	@Input() set schemaVariables(schemaVariables: TIVariablesArray) {
		this._schemaVariables = schemaVariables;
	};
	get schemaVariables(): TIVariablesArray {
		return this._schemaVariables;
	}

	@Input() private nsVariables: TIVariablesArray;

	@Input() limitCharacters?: number;

	invalidVariables: Array<string> = [];

	variablesForm: UntypedFormGroup;

	varEditorHandler: VarEditorHandler;

	constructor(
		private cdr: ChangeDetectorRef,
		private formBuilder: UntypedFormBuilder,
		private snackSvc: SnackMessageService,
	) {
		super({
			...pickTranslations(gTranslations.common, [
				'variables',
				'message',
				'editMessage',
			]),
		});
	}

	ngOnInit() {
		this.initializeVarEditorHandler();
	}

	public forceSave(): void {
		if (isValidFunction(this.varEditorHandler?.getHomeInstance) && isValidFunction(this.varEditorHandler?.getHomeInstance().forceSave)) {
			this.varEditorHandler.getHomeInstance().forceSave();
		}
	}

	get message(): string {
		return this.asset.content || '';
	}

	set message(text: string) {
		this.asset.content = text;
	}

	get messageIfNoBind(): string {
		return this.asset.variablesTemplate && this.asset.variablesTemplate.messageIfNoBind || '';
	}
	set messageIfNoBind(text: string) {
		this.asset.variablesTemplate.messageIfNoBind = text;
	}

	onVariableEditFinished(
		rawText: string,
		compiledText: string,
		usedVariablesIds: IUsedVariablesID[],
		identity: string,
		messageIfNoBind: string
	): void {
		this.message = rawText;
		// 
		this.asset.variablesTemplate = {
			variables: usedVariablesIds,
			compiledTemplate: compiledText,
			messageIfNoBind,
		};
		this.messageIfNoBind = messageIfNoBind;
		this.varEditorHandler.getComponentParameter().rawText = rawText;
		this.cdr.markForCheck();
	}

	onInputClick() {
		this.varEditorHandler.forceButtonOpen();
	}

	public toVarEditorVariable(variableInput: IVariableClient): IEditorVariable {
		return ({
				variable: variableInput.text,
				idProperty: variableInput.idProperty,
				isSafe: variableInput.isSafe,
				idLocalCanonical: variableInput.idLocalCanonical
			});
	}

	public tryToCompileText(raw: string, variables: TIEditorVariableArray, openError = true): ICompileResponse {
		try {
			const compiledResponse: ICompileResponse = compileText(
				raw,
				variables
			);

			return compiledResponse;

		} catch (err) {
			if (openError) {
				this.snackSvc.openError('Ocorreu um erro ao salvar o texto, cheque se as variáveis estão corretas.')
			}
			throw err;
		}
	}

	initializeVarEditorHandler() {
		

		const data: IVarEditorHandlerParameter  = {
			variables: {
				[EVarEditorEntityType.SchemaProperty]: isValidArray(this.schemaVariables) ? this.schemaVariables.map(vari => this.toVarEditorVariable(vari)) : [],
				[EVarEditorEntityType.NonSerializable]: isValidArray(this.nsVariables) ? this.nsVariables.map(vari => this.toVarEditorVariable(vari)) : [],
			},
			compileFunction: (raw: string, variables: TIEditorVariableArray): ICompileVariablesResult => {
				const compiledResponse = this.tryToCompileText(
					raw,
					variables
				)

				return (
					{
						raw,
						compiled: compiledResponse.variablesTemplate.compiledTemplate,
						usedVariablesID: compiledResponse.variablesTemplate.variables
					}
				);
			},
			rawText: this.message,
			messageIfNoBind: this.messageIfNoBind,
			clientCallback: this,
			enableContextCanonicals: this.enableContextVariables ? true : undefined,
			disableFallback: this.disableFallback,
			...pickFormatVisibility(this.handler),
		};

		this.varEditorHandler = new VarEditorHandler(data);
	}

    hasInvalidText(): boolean {
        return this.varEditor.hasInvalidText();
    }

	onAllVariablesLoaded(variables: TIEditorVariableArray) {
		const isBroken = !checkAssetForBrokenVariables(this.asset, variables);
		
		// essa verificação também é feita no asset adder, portanto é muito raro cair aqui
		if (isBroken) {
			const fixedAssetContent = fixBrokenVariablesOnAssetContent(this.asset, variables);

			this.message = fixedAssetContent;
			this.varEditorHandler.getComponentParameter().rawText = fixedAssetContent;
		}
	}
}
