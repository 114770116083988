import { Participant } from '../../business/participant';
import { Interaction } from '../interaction';
import { TGlobalUID, constant } from '../../business/constant';
import { InteractionType } from '../interaction-type';
import { IPaymentMediumInteractionJSON } from '../../comm-interfaces/interaction-interfaces';

export class PaymentMediumRegister extends Interaction {

    private creditCardNumber: string;

    private creditCardBrand: string;

    private creditCardHolderName: string;

    private creditCardExpiry: string;

    private creditCardCVC: string;

    private creditCardDocument: string;

    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.payment.medium));
    };

    public setPaymentCard(card: string): void {
        this.creditCardNumber = card;
    };

    public getCreditCardNumber(): string {
        return this.creditCardNumber;
    }

    public setPaymentCardBrand(brand: string): void {
        this.creditCardBrand = brand
    }

    public getCreditCardBrand(): string {
        return this.creditCardBrand;
    }

    public setPaymentCardHolderName(name: string): void {
        this.creditCardHolderName = name;
    }

    public getCreditCardHolderName(): string {
        return this.creditCardHolderName;
    }

    public setPaymentExpiry(expiry: string): void {
        this.creditCardExpiry = expiry;
    }

    public getCreditCardExpiry(): string {
        return this.creditCardExpiry;
    }

    public setPaymentCvc(cvc: string): void {
        this.creditCardCVC = cvc;
    }

    public getCreditCardCVC(): string {
        return this.creditCardCVC;
    }

    public setPaymentDocument(doc: string): void {
        this.creditCardDocument = doc;
    }

    public getCreditCardDocument(): string {
        return this.creditCardDocument;
    }

    //
    public toJSON(): IPaymentMediumInteractionJSON {
        return {
            ...super.toJSON(),
            creditCardNumber: this.creditCardNumber,
            creditCardBrand: this.creditCardBrand,
            creditCardHolderName: this.creditCardHolderName,
            creditCardExpiry: this.creditCardExpiry,
            creditCardCVC: this.creditCardCVC,
            creditCardDocument: this.creditCardDocument,
        };
    };

    public rehydrate(json: IPaymentMediumInteractionJSON): void {
        super.rehydrate(json);
        this.creditCardNumber = json.creditCardNumber;
        this.creditCardBrand = json.creditCardBrand;
        this.creditCardHolderName = json.creditCardHolderName;
        this.creditCardExpiry = json.creditCardExpiry;
        this.creditCardCVC = json.creditCardCVC;
        this.creditCardDocument = json.creditCardDocument;
    };

    public static getNewPaymenetRegister(participant): PaymentMediumRegister {
        return new PaymentMediumRegister(null, participant);
    }

    public static staticFactory(idPaymentRegister: TGlobalUID): PaymentMediumRegister {
        return <PaymentMediumRegister>Interaction.staticFactory(idPaymentRegister);
    };

    public static factoryMessage(json: IPaymentMediumInteractionJSON, participant: Participant): PaymentMediumRegister {
        let medium: PaymentMediumRegister = <PaymentMediumRegister>Interaction.searchCachedInteraction(json.primaryID);
        if (medium == null)
            medium = new PaymentMediumRegister(json.primaryID,
                participant? participant: Participant.staticFactory(json.participant.primaryID));
        medium.rehydrate(json);
        return medium;

    }
};
