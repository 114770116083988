import { Component, EventEmitter, Input, Output, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import {
    GeneralFormField,
    IGeneralFormAnswer,
    IGeneralFormAnswerJson,
    IGeneralFormAnswerServer,
    IRFieldResponse,
    ISaveFormAnswer,
    reduceFieldsToJson,
    TGeneralFieldArray
} from '@colmeia/core/src/general-form/general-form-answer';
import { TValidateFunction } from '@colmeia/core/src/general-form/general-form-condition-processor';
import { IFormSchema, SchemaProperty } from '@colmeia/core/src/general-form/general-form-interface';
import { ILocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { IMetaEngagement } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { EJSONPropertyNameType } from '@colmeia/core/src/shared-business-rules/metadata/metadata-transform';
import { getPropertyById, getPropertyWithLocalCanonical, TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ITranslationConfig } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { isEmptyObject, isInvalid, isValidArray, isValidObject, isValidRef, isValidString, typedClone } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { buildPropValueMap, EGeneralFormViewType, getJSONAnswerPropertyTypeByResponses, IPropValueHash } from 'app/model/general-form.model';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { LookupService } from 'app/services/lookup.service';
import { TwoFactorAuthService } from 'app/services/two-factor-auth.service';
import { GeneralFormEvaluator } from '@colmeia/core/src/general-form/general-form-evaluator';
import { GeneralFormFieldViewerComponent } from '../general-form-field-viewer/general-form-field-viewer.component';
import { GeneralFormFieldService } from '../services/general-form-field.service';
import { GeneralFormFieldViewerHandler } from '../general-form-field-viewer/general-form-field-viewer.handler';

@Component({
    selector: 'app-general-form-viewer',
    templateUrl: './general-form-viewer.component.html',
    styleUrls: ['./general-form-viewer.component.scss'],
})
export class GeneralFormViewerComponent extends RootComponent<'save' | 'edit'> {
    private fields: GeneralFormField[] = [];
    @Input() buttonText: string;
    @Input() idMessage: string;
    @Input() schema: IFormSchema;
    @Input() engagement: IMetaEngagement;
    @Input() generalFormEvaluator: GeneralFormEvaluator
    @Input() primaryID: string;
    @Input() blockModification: boolean = false
    @Output() save = new EventEmitter<ISaveFormAnswer>();
    @Input() viewType: EGeneralFormViewType;
    @Input() fnFieldValidator: TValidateFunction;
    @Input() hideActions: boolean = false;
    @Input() computedInfo: TComputedInfo = {};
    fGroup: UntypedFormGroup
    private mapIdPropertyToAnswer: Map<string, IRFieldResponse> = new Map();
    _answer: IGeneralFormAnswerServer;
    viewTypeEnum: typeof EGeneralFormViewType = EGeneralFormViewType;
    propValues: IPropValueHash = {};

    @Input()
    loading: boolean = false;
    internalLoading: boolean = false;

    public dataLoading: boolean = false;

    private localCanonicals: ILocalCanonical[] = [];

    @Input() set answer(answer) {
        this._answer = answer

        this.init()
    }
    get answer() {
        return this._answer
    }    

    @ViewChildren(GeneralFormFieldViewerComponent)
    generalFormFields: QueryList<GeneralFormFieldViewerComponent>;

    constructor(
        private lookupSvc: LookupService,
        private generalFormFieldService: GeneralFormFieldService,
        private twoFactorAuthService: TwoFactorAuthService,
        private embeddedSvc: EmbeddedChatService,
        private cdr: ChangeDetectorRef
    ) {
        super({
            save: gTranslations.common.save,
            edit: gTranslations.common.edit,
        });
        this.generalFormFieldService.setTwoFactorSvc(this.twoFactorAuthService);
    }

    async init() {
        await this.buildAnswer();
        this.mapResponses();
        this.buildFormValidator()
        this.initForm()

        this.initGeneralFormFieldViewerHandlers();

        console.log({ schema: this.schema })
    }

    public setSchema(schema: IFormSchema): void {
        this.schema = schema;
    }

    public async updateForm() { 
        await this.buildAnswer();
        this.mapResponses();
        this.buildFormValidator()
        this.initForm()

        this.initGeneralFormFieldViewerHandlers();

        this.cdr.markForCheck();
    }

    private async loadLocalCanonicals() {
        const idCanonicals: string[] = this.schema?.form.filter( f => f.idLocalCanonical ).map( f => f.idLocalCanonical );

        this.localCanonicals = isValidArray(idCanonicals) ? await this.lookupSvc.getCanonicals(idCanonicals) : [];
    }

    public generalFormFieldViewerHandlers: GeneralFormFieldViewerHandler[];
    public initGeneralFormFieldViewerHandlers(): void {
        this.generalFormFieldViewerHandlers = this.schema.form.map(item => this.getGeneralFormFieldViewerHandler(item))
    }

    public getGeneralFormFieldViewerHandler(property: SchemaProperty) {
        return new GeneralFormFieldViewerHandler({
            schemaProp: property,
            previousAnswer: this.getPreviousAnswer(property),
            viewType: this.viewType,
            generalFormEvaluator: this.generalFormEvaluator,
            fGroup: this.fGroup,
            fControl: this.fGroup.get(property.idProperty) as UntypedFormControl,
            answer: this.answer,
            value: this.propValues[property.idProperty],
            fields: this.fields,
            schemaForm: this.schema.form,
            fieldCreation: item => this.addFieldToCache(item),
            clientCallback: this,
            idStartInteraction: this.primaryID,
        });
    }


    private fulfillValuesByComputedInfo() {
        if(!isValidObject(this.computedInfo)) return

        for(const formProp of this.schema.form) {
            const valueByIdLocalCanonical = this.computedInfo[formProp.idLocalCanonical];

            if(isValidRef(valueByIdLocalCanonical)) {
                this.propValues[formProp.idProperty] = valueByIdLocalCanonical;
                continue;
            }

            const localCanonical = this.localCanonicals.find( localCanonical => localCanonical.idNS === formProp.idLocalCanonical );
            const valueByGlobalCanonical = this.computedInfo[localCanonical?.globalCanonical];

            if(!valueByGlobalCanonical) continue;

            this.propValues[formProp.idProperty] = valueByGlobalCanonical;
        }

    }
    
    asyncValidator: AsyncValidatorFn;

    initForm() {
        this.fGroup = new UntypedFormGroup({}, {
            validators: [Validators.required],
            updateOn: 'blur'
        })
        
        this.asyncValidator = this.generalFormFieldService.isValidInputField({
            fGroup: this.fGroup,
            schemaForm: this.schema.form,
            generalFormEvaluator: this.generalFormEvaluator,
            fnValidator: this.fnFieldValidator
        });
        
        this.initFormControls()
        
    }

    public initFormControls() {
        

        this.schema.form.forEach((item) => this.setFormControl(item));
    }

    public setFormControl(item: SchemaProperty) {
        const isMultiple = item.multipleAnswers && isValidArray(item.nestedSchema);

        const control = new UntypedFormControl(
            {},
            {
                asyncValidators: [this.asyncValidator]
            }
        );

        this.fGroup.addControl(item.idProperty, control)

        if (isMultiple) {
            item.nestedSchema.map(nestedItem => this.setFormControl(nestedItem))
        }
    }


    async buildAnswer() {
        try {
            this.dataLoading = true;
            await this.loadLocalCanonicals();
            this.fulfillValuesByComputedInfo()

            if(isValidRef(this.computedInfo)) {
                Object.entries(this.computedInfo).forEach(([propertyId, value]) => {
                    this.propValues[propertyId] = value;
                });
            }

            if (this.answer && isValidRef(this.schema)) {
                if (isEmptyObject(this.answer.json)) {
                    this.answer.json = this.buildAnswerJson(this.answer.responses);
                }

                const type: EJSONPropertyNameType = getJSONAnswerPropertyTypeByResponses(this.schema.form, this.answer.json);
                this.propValues = buildPropValueMap(this.schema.form, this.answer.json, type);
            }

            this.generalFormFields.forEach( formField => {
                formField.ngOnInit();
            });
        } finally {
            this.dataLoading = false;
        }
    }



    buildFormValidator() {
        this.generalFormEvaluator = this.generalFormEvaluator
            ? this.generalFormEvaluator
            : GeneralFormEvaluator.create({ engagement: this.engagement })
    }

    public mapResponses(): void {
        const responses: TGeneralFieldArray = isValidRef(this.answer && this.answer.responses) ? this.answer.responses : [];
        for (const answer of responses) this.mapIdPropertyToAnswer.set(answer.idProperty, answer);
    }

    public getPreviousAnswer(field: SchemaProperty): IRFieldResponse {
        const idProperty: string = field.idProperty;
        return this.mapIdPropertyToAnswer.get(field.idProperty);
    }

    private viewTypeTranslationsMap: Record<EGeneralFormViewType, ITranslationConfig> = {
        [EGeneralFormViewType.save]: this.translations.save,
        [EGeneralFormViewType.edit]: this.translations.edit,
        [EGeneralFormViewType.upsert]: this.translations.edit,
        [EGeneralFormViewType.view]: undefined, // Modo de visualização não tem ação
        [EGeneralFormViewType.callback]: undefined, // Modo não utilizado
    }

    get msg() {
        return this.viewTypeTranslationsMap[this.viewType] || { value: "Próximo" }; // Garante a renderização correta do botão.
    }

    getButtonText() {
        return isValidString(this.buttonText) ? this.buttonText : (this.msg || "Próximo");
    }

    buildAnswerJson(responses: IRFieldResponse[]): IPropValueHash {
        return responses.reduce((acc: IPropValueHash, resp: IRFieldResponse) => {
            const prop = getPropertyById(this.schema.form, resp.idProperty) || getPropertyWithLocalCanonical(this.schema.form, resp.idLocalCanonical);

            acc[prop.propertyName] = resp.value;

            return acc;
        }, {});
    }

    removeFieldFromCacheByIdProperty(idProperty: string): void {
        const indexes: number[] = this.fields
            .filter((field, index) => field.idProperty === idProperty)
            .filter(item => !item.multipleAnswers)
            .map((field, index) => index)
        ;
        indexes.forEach(index => this.fields.splice(index, 1))
    }

    addFieldToCache(field: GeneralFormField) {
        this.removeFieldFromCacheByIdProperty(field.idProperty);
        this.fields.push(field);

        this.updateFieldsRows();
    }

    updateFieldsRows() {
        this.fields.forEach((field, row) => field.row = row);
    }

    public responsesFieldsJSON(): IGeneralFormAnswerJson {
        return reduceFieldsToJson(this.fields);
    }

    public async saveAnswer(): Promise<ISaveFormAnswer> {
        try {
            this.internalLoading = true;
            const fields: GeneralFormField[] = this.fields.filter(field => field.canDisplayField);


            console.log({saveAnswerFields: fields});
            const formAnswer: IGeneralFormAnswer = {
                idSchemma: this.schema.idSchemma,
                responses: [],
                primaryID: this.primaryID,
                json: reduceFieldsToJson(fields),
            };
            const idCanonicals: TArrayID = [];
            fields.forEach(field => {
                if (field.idLocalCanonical) {
                    idCanonicals.push(field.idLocalCanonical);
                }
                formAnswer.responses.push(...field.toFieldResponseArray())
            });
            if (isValidArray(idCanonicals)) {
                formAnswer.hasCanonical = true;
                const canonicals = await this.lookupSvc.getCanonicals(idCanonicals);
                canonicals.forEach(canonical => {
                    const answer = formAnswer.responses.find(answer => answer.idLocalCanonical === canonical.idNS);
                    if (answer) {
                        answer.idGlobalCanonical = canonical.globalCanonical;
                    }
                });
            }
            formAnswer.responses.forEach((answer, index) => answer.row = index);
            this.fGroup.markAllAsTouched();

            const saveFormAnser: ISaveFormAnswer = {
                idNS: this.answer && this.answer.idNS,
                formAnswer,
            };
            console.log({saveFormAnser})
            this.save.emit(saveFormAnser);

            return saveFormAnser;
        }
        catch(e) { console.error(e); }
        finally { this.internalLoading = false; }
    }

}
