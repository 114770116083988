import { EBPMMarketingActionType } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { suggestions } from "../../../tools/type-utils";
import { typedScreenOptions } from "../../../tools/utility";
import { TCustomEnumPickerFieldMaps } from "../../enum-db";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";


const bpmMktActions = typedScreenOptions({
    idSerializable: EAllSerializable.BPMMktActions,
    fields: suggestions<TCustomEnumPickerFieldMaps<EBPMMarketingActionType>>()({
        [EBPMMarketingActionType.messageWithCampaing]: 1,     // Ação de campanha
        [EBPMMarketingActionType.goCampaignWithBot]: 2,       // Açao atrelada a um bot
    })
});

export const allBPMMktTranslations = ({
    ...getTranslationsFields(bpmMktActions),
});

