import { ToAvatar } from "../to-avatar";
import { TGlobalUID } from "../../../core-constants/types";
import { Participant } from "../../../business/participant";
import { constant } from "../../../business/constant";


export class PrivateMessageRequest extends ToAvatar {

    private constructor(primaryID: TGlobalUID, participant: Participant, idAvatar: TGlobalUID) {
        super(primaryID, participant, constant.subscription.request.privateMessage, idAvatar)
    };

    public static getNewPrivateMessageRequest(personalGroupParticipant: Participant, idAvatar: TGlobalUID): PrivateMessageRequest {
        return new PrivateMessageRequest(null, personalGroupParticipant, idAvatar)
    };

};
