import { Injectable } from '@angular/core';
import { TNserUID, TPostgresPrimaryKey } from '@colmeia/core/src/core-constants/types';
import { IPostgreSQLCursorRequest } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { EJournalingRequestTypes } from '@colmeia/core/src/request-interfaces/message-types';
import { EMutationType, IGetAllChangesFromNonSerializableRequest, IGetAllChangesFromNonSerializableResponse, IGetDeployHistoryFromVersionRequest, IGetDeployHistoryFromVersionResponse, IGetObjectDeployDataRequest, IGetObjectDeployDataResponse, IJournalingDeleteNonSerializablesFromVersionRequest, IJournalingDeleteNonSerializablesFromVersionResponse, IJournalingGetVersionsRequest, IJournalingGetVersionsResponse, IJournalingTransferNonSerializablesToVersionRequest, IJournalingTransferNonSerializablesToVersionResponse, IJournalingVersionUpdateRequest, IJournalingVersionUpdateResponse, IListAllNonSerializablesChangedInVersionRequest, IListAllNonSerializablesChangedInVersionResponse, IListAllNonserializablesWithoutVersionRequest, IListAllNonserializablesWithoutVersionResponse, IUpdateAllJournalingToVersionIDRequest, IUpdateAllJournalingToVersionIDResponse } from "@colmeia/core/src/shared-business-rules/journaling/journaling-req-res.dto";
import { IMigrationChangeTrackingWithNames, IMigrationJournaling, IMigrationJournalingWithName, IMigrationObjectDeploy, IMigrationVersionData, TDeployHistoryArray } from '@colmeia/core/src/shared-business-rules/journaling/journaling.model';
import { IMigrationVersionComm } from 'app/model/journaling-client.model';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { LocalStoragePersistence } from 'app/services/local-storage-persistence.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ENonserializableControllerRequest, ISaveNonserializableOnVersionRequest, ISaveNonserializableOnVersionResponse } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';

export const CURRENT_JOURNALING_VERSION_ON_LOCAL_STORAGE = 'currentJournalingVersion';
@Injectable({
    providedIn: 'root'
})
export class MigrationVersioningJournalingService {

    private selectedVersionSubject = new BehaviorSubject<IMigrationVersionComm>(this.getCurrentVersionPersisted() || { isSelected: false });
    private selectedVersion$ = this.selectedVersionSubject.asObservable();

    constructor(
        private api: ServerCommunicationService,
        private session: SessionService,
        private localStorageSvc: LocalStoragePersistence
    ) {
    }

    setCurrentVersion(row: IMigrationVersionComm) {
        this.selectedVersionSubject.next(row);
        this.localStorageSvc.putItem(CURRENT_JOURNALING_VERSION_ON_LOCAL_STORAGE, row);
    }

    clearCurrentVersion() {
        this.selectedVersionSubject.next({ isSelected: false });
        this.localStorageSvc.putItem<IMigrationVersionComm>(CURRENT_JOURNALING_VERSION_ON_LOCAL_STORAGE, {isSelected: false});
    }

    listenVersionSelected(): Observable<IMigrationVersionComm> {
        return this.selectedVersion$
    }

    getCurrentVersionSelected(): IMigrationVersionComm {
        return this.selectedVersionSubject.getValue();
    }

    getCurrentVersionPersisted(): IMigrationVersionComm | undefined {
        return this.localStorageSvc.getItem<IMigrationVersionComm>(CURRENT_JOURNALING_VERSION_ON_LOCAL_STORAGE);
    }

    public async getAllVersions() {
        const response = await this.api.sendRequest<
            IJournalingGetVersionsRequest,
            IJournalingGetVersionsResponse
        >(EJournalingRequestTypes.getAllMigrationVersions)({
            data: {
                idSocialContext: this.session.getCurrentSocialNetworkID(),
                limit: 50,
                cursor: {
                    lastTimestamp: new Date().toISOString()
                }
            }
        });

        return response.versionList;
    }

    public async getAllChangesFromVersion(idVersion: TPostgresPrimaryKey): Promise<IMigrationJournalingWithName[]> {
        const response = await this.api.sendRequest<
            IListAllNonSerializablesChangedInVersionRequest,
            IListAllNonSerializablesChangedInVersionResponse
        >(EJournalingRequestTypes.listAllNonSerializablesChangedInVersion)({
            data: {
                idVersion
            }
        });

        return response?.changedNserList
    }

    public async getAllChangesWithoutVersion(allChangesWithoutVersionParameterData: IPostgreSQLCursorRequest): Promise<IMigrationJournaling[]> {
        const response = await this.api.sendRequest<
            IListAllNonserializablesWithoutVersionRequest,
            IListAllNonserializablesWithoutVersionResponse>
            (EJournalingRequestTypes.listAllNonSerializablesChangedWithoutVersion)({
                data: <IPostgreSQLCursorRequest>allChangesWithoutVersionParameterData
            });

        return response?.changesWithoutVersion;
    }

    public async getAllChangesFromNonSerializable(nserID: TNserUID): Promise<IMigrationChangeTrackingWithNames[]> {
        const response = await this.api.sendRequest<
            IGetAllChangesFromNonSerializableRequest,
            IGetAllChangesFromNonSerializableResponse>
            (EJournalingRequestTypes.getAllChangesFromNonSerializable)({
                data: {
                    nserID: nserID
                }
            });

        return response?.changes;
    }

    public async deleteNonSerializablesFromList(idsCapturedObjects: string[]) {
        const response = await this.api.sendRequest<
            IJournalingDeleteNonSerializablesFromVersionRequest,
            IJournalingDeleteNonSerializablesFromVersionResponse
        >(EJournalingRequestTypes.deleteNonSerializablesFromVersion)({
            data: {
                idsCapturedObjects: idsCapturedObjects
            }
        });

        return response;
    }

    public async transferNonSerializablesToVersion(idVersionTarget: TPostgresPrimaryKey, idsCapturedObjects: string[]) {
        const response = await this.api.sendRequest<
            IJournalingTransferNonSerializablesToVersionRequest,
            IJournalingTransferNonSerializablesToVersionResponse
        >(EJournalingRequestTypes.transferNonSerializablesToVersion)({
            data: {
                idVersionTarget: idVersionTarget,
                idsChangedObjects: <TPostgresPrimaryKey[]>idsCapturedObjects
            }
        });

        return response;
    }

    public async getDeployDataFromNonSerializable(nserID: TNserUID): Promise<IMigrationObjectDeploy[]> {
        const response = await this.api.sendRequest<
            IGetObjectDeployDataRequest,
            IGetObjectDeployDataResponse>
            (EJournalingRequestTypes.getObjectDeployDataRequest)({
                data: {
                    nserID: nserID
                }
            });

        return response?.changes;
    }

    public async getDeployHistoryFromVersion(versionID: TPostgresPrimaryKey): Promise<TDeployHistoryArray | undefined> {
        const response = await this.api.sendRequest<
            IGetDeployHistoryFromVersionRequest,
            IGetDeployHistoryFromVersionResponse>
            (EJournalingRequestTypes.getDeployHistoryFromVersion)({
                versionID
            });

        return response?.deployHistory;
    }

    public async addChanngedJournalingsToVersion(versionId: TPostgresPrimaryKey, journalingIDList: TPostgresPrimaryKey[]): Promise<void> {
        await this.api.sendRequest<
            IUpdateAllJournalingToVersionIDRequest,
            IUpdateAllJournalingToVersionIDResponse
        >(EJournalingRequestTypes.updateAllJournalingToVersionID)({
            versionID: <TPostgresPrimaryKey>versionId,
            journalingIDList: <TPostgresPrimaryKey[]>journalingIDList
        })
    }

    async saveVersion(operationType: EMutationType, version: IMigrationVersionData) {
        const response = await this.api.sendRequest<
            IJournalingVersionUpdateRequest,
            IJournalingVersionUpdateResponse
        >(EJournalingRequestTypes.upsertMigrationVersion)({
            data: {
                operationType,
                version
            }
        });
        return response
    }

    async saveNsOnVersion(ns: INonSerializable) {
        const response = await this.api.sendRequest<
        ISaveNonserializableOnVersionRequest,
        ISaveNonserializableOnVersionResponse
        >(ENonserializableControllerRequest.save)({
            ns
        });
        return response
    }
}
