import { constant } from "../../../business/constant";
import { suggestions } from "../../../tools/type-utils";
import { typedScreenOptions, IdField } from "../../../tools/utility";
import { getTranslationsFields, EAllSerializable } from "../all-serializables";

export const helpTipScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.HelpTipsView,
    fields: {
        documentationLink: 1, // Confira a documentação
    }
} as const);

const helpTipsMenuTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.ColmeiaHelpTips,
    fields: suggestions<{ [key in string]: IdField }>()({
        helpTips: constant.serializableField.name,
        helpTipsIcon: constant.serializableField.icon,
    })
} as const));

export const helpTipsTranslations = {
    ...getTranslationsFields(helpTipScreenOptions),
    ...helpTipsMenuTranslations,
}