import { EGeneratorTransactionType } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { TGlobalUID } from "../../../business/constant";
import { ENextGenBotElementType } from "../../bot/bot-model";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../../non-serializable-id/non-serializable-id-interfaces";
import { IBPMActionModel } from "../bpm-action-model";
import { IBPConditionalEvaluator } from "../bpm-model";
import { ESubscribedTo, IBPMListenerModelBase, IBPMActionModelBase, IBPMTimerModelBase, IBPMBaseNodeServer, IBPMRootBase } from "../common/common-bpm";

export type TTBpmMarketingNserServerArray = Array<TBpmMarketingNserServer>


export type TBpmMarketingNaturalNsTypes = ENonSerializableObjectType.bpmMarketingRoot
    | ENonSerializableObjectType.bpmMarketingAction
    | ENonSerializableObjectType.bpmMarketingPause
    | ENonSerializableObjectType.bpmMarketingAcknowledge
    | ENonSerializableObjectType.bpmMarketingListener
    | ENonSerializableObjectType.bpmMarketingEnd

export type TBpmMarketingNsTypes = TBpmMarketingNaturalNsTypes
    | ENonSerializableObjectType.contentGenerator
    | ENextGenBotElementType.botMenuItem

export type TBpmMarketingNserServer = IBPMMarketingAcknowledgeServer
    | IBPMMarketingPauseServer
    | IBPMMarketingActionServer
    | IBPMMarketingRootServer
    | IBPMMarketingListenerServer
    | IBPMMarketingEndServer

export type TBpmMarketingNserClient = IBPMMarketingRootClient
    | IBPMMarketingActionClient
    | IBPMMarketingPauseClient
    | IBPMMarketingAcknowledgeClient
    | IBPMMarketingListenerClient
    | IBPMMarketingEndClient

export type TBpmMarketingNserClientAndServer = TBpmMarketingNserClient
    | TBpmMarketingNserServer

export enum EBPMMarketingActionType {
    messageWithCampaing = 'mktRunCamp',
    goCampaignWithBot = 'msgWBot'
}

export interface IAllMarketingListenersSearch {
    idNS: TGlobalUID;
    idElement: TGlobalUID;
    subscriptionType: ESubscribedTo;
}

export type IAllMarketingListenersSearchArray = Array<IAllMarketingListenersSearch>


export interface IBPMMKTMActionModel extends IBPMActionModel {
    bpmAction: EBPMMarketingActionType
}


export interface IBPMMMarketingRunCampaignAction extends IBPMMKTMActionModel {
    bpmAction: EBPMMarketingActionType.messageWithCampaing;
    idCampaignAction: TGlobalUID;
}


export interface IBPMMMarketingBotAction extends IBPMMKTMActionModel {
    bpmAction: EBPMMarketingActionType.goCampaignWithBot;
    idBot: TGlobalUID;
}


export interface IBPMMKTConditionalEvaluator extends IBPConditionalEvaluator {
    action: IBPMMKTMActionModel;
}

export type TIBPMMKTConditionalEvaluatorArray = Array<IBPMMKTConditionalEvaluator>;


export interface IMarketingListenerModel extends IBPMListenerModelBase {

};

export interface IMarketingActionModel extends IBPMActionModelBase<TIBPMMKTConditionalEvaluatorArray>, IBPMListenerModelBase, IBPMTimerModelBase {
};

export interface IBasicBPMMarketingModel extends IMarketingListenerModel {
}


export interface IBasicBPMMarketingModelClient extends IBasicBPMMarketingModel, INonSerializableHeader {
}

export interface IBPMMarketingRootClient extends INonSerializableHeader, IBPMBaseNodeServer {
    idCampaingAction: string;
}

export type TIBPMMarketingRootServerArray = Array<IBPMMarketingRootServer>
export interface IBPMMarketingRootServer extends INonSerializable, IBPMMarketingRootClient, IBPMRootBase {
    nsType: ENonSerializableObjectType.bpmMarketingRoot;
    // allMarketingListeners: IAllMarketingListenersSearchArray;
}

export interface IBPMMarketingActionClient extends IMarketingActionModel, INonSerializableHeader {
}

export interface IBPMMarketingActionServer extends INonSerializable, IBPMMarketingActionClient {
    nsType: ENonSerializableObjectType.bpmMarketingAction
}

export interface IBPMMarketingPauseClient extends IBPMTimerModelBase, INonSerializableHeader {
}

export interface IBPMMarketingPauseServer extends INonSerializable, IBPMMarketingPauseClient {
    nsType: ENonSerializableObjectType.bpmMarketingPause
}

export interface IBPMMarketingAcknowledgeClient extends IBasicBPMMarketingModelClient {

}

export interface IBPMMarketingAcknowledgeServer extends INonSerializable, IBPMMarketingAcknowledgeClient {
    nsType: ENonSerializableObjectType.bpmMarketingAcknowledge
}


export interface IBPMMarketingListenerClient extends IMarketingListenerModel, INonSerializableHeader { }

export interface IBPMMarketingListenerServer extends IBPMMarketingListenerClient, INonSerializable {
    nsType: ENonSerializableObjectType.bpmMarketingListener
}


export interface IBPMMarketingEndClient extends INonSerializableHeader { }

export interface IBPMMarketingEndServer extends IBPMMarketingEndClient, INonSerializable {
    nsType: ENonSerializableObjectType.bpmMarketingEnd
}

export type TIBPMMarketingListenerServerArray = Array<IBPMMarketingListenerServer>;
