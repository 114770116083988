import { IInteractionJSON, IWrapperInteractionJSON } from "../../comm-interfaces/interaction-interfaces";
import { TGlobalUID, constant } from "../../business/constant";

export enum EAppState {
    background = 'background', foregroud = 'foreground', chat = 'inChatScreen'
}

export interface SerializableContent {
    primaryId: TGlobalUID;
    idField: number;
}

export interface INotificationSoundRegisty {
    [EAppState.background]: string;
    [EAppState.foregroud]: string;
    [EAppState.chat]: string;
}

export interface INotificationDialogSetup  {
    title?: SerializableContent;
    content?: SerializableContent;
    soundToPlayUntilClosed?: string;
};

export interface INotificationActions {
    sound: string;
    vibrate: boolean;
    dialog: {
        sound: string;
        title: string;
        content: string;
    }
}

export type TConfigGenerator = (interactionJson: IInteractionJSON, config: IInteractionActionsConfig, state: EAppState) => INotificationActions;

export type TCheckNotifcationEnabled = (interaction: IInteractionJSON) => boolean;

export interface IInteractionActionsConfig {
    enabled?: boolean,
    checkIfEnabled?: TCheckNotifcationEnabled,
    generateActions?: TConfigGenerator;
    sound?: INotificationSoundRegisty;
    vibrate?: boolean;
    dialog?:INotificationDialogSetup;
}

export interface IInteractionActionHash {
    [idInteractionType: string]: IInteractionActionsConfig;
}
