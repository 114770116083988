import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

export const commonNSerErrorFields = typedScreenOptions({
    idSerializable: EAllSerializable.NonSerErrors1,
    fields: {
        insertName: 1,
        smallName: 2,
        insertDescription: 3,
        smallDescription: 4,
        missingField: 5, // Campo não preenchido
        invalidField: 6, // Campo inválido
    }
} as const);

export const submitWhatsAppTemplateError = typedScreenOptions({
    idSerializable: EAllSerializable.SubmitWhatsAppTemplateError,
    fields: {
        submitWhatsAppTemplateError: 1,
    }
} as const);



export const generalErrors = {
    ...getTranslationsFields(commonNSerErrorFields),
}