<div class="group-service-wrapper">
  <section class="group-service__container mat-card">
    <div class="group-service-body">
      <div class="channel-time">
        <img [src]="iconUrl" aria-hidden="true">
        <span>{{ time }}</span>
      </div>

      <div class="info">
        <header>
          <div class="title">
            <mat-icon *ngIf="wasTransfered" class="mat-18">sync_alt</mat-icon>

            <span *ngIf="island" class="island-name">
              {{ island.nName }}
            </span>
          </div>
          <div *ngIf="agentName">
            <span class="agent-name">&#64;{{ agentName }}</span>
          </div>
        </header>

        <hr>

        <div class="group-service-buttons">
          <ng-container *ngIf="!isMobile else historyButtonsMobile">
            <div class="history-buttons" [visible-horizontal]="botThreadId">
              <button class="bot-history-button" mat-icon-button color="primary" (click)="openBotHistory()" [matTooltip]="translations.botHistory">
                <span *ngIf="hasQueueMessages" class="has-queue-message-ind" matTooltip="Contém mensagens enviadas durante o tempo em fila"></span>
                <mat-icon>smart_toy</mat-icon>
              </button>

              <button class="forms-history-button" mat-icon-button color="primary" (click)="openFormHistory()" [matTooltip]="translations.formHistory">
                <mat-icon>assignment</mat-icon>
              </button>

              <button class="at-a-glance-button" *ngIf="isAttendingOnThisGroup() && hasAtAGlanceConfigured()" [disabled]="!hasLoadedAllComputedInfoForAttendance()" mat-icon-button color="primary" (click)="openAtAGlance()" matTooltip="At a Glance">
                <mat-icon>account_box</mat-icon>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="isAttendingOnThisGroup() && !isMobile">
            <mat-divider [vertical]="true" style="margin: 3px 16px;"></mat-divider>

            <div class="att-actions">
              <chat-options-attendant-transferable-islands></chat-options-attendant-transferable-islands>

              <chat-options-finish-attendance [interaction]="interaction"></chat-options-finish-attendance>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isInternalSupportMessage">
      <hr class="hr-absolute">
      <section class="footer" >
        <div class="footer-line">
          <div class="user-container">
            <span class="user-container__hexagon">
              <hexagon [hexagonHandler]="hexagonHandler"> </hexagon>
              <span class="user-name">&#64;{{ nickName }}</span>
            </span>
          </div>

          <!-- <div class="action-container" *ngIf="canShowLocationBtn()">
            <button (click)="showLocation()" class="location">
              <img src="./assets/icons/group-service/map-marker-alt-solid.svg" />
              <span>{{ translations.see }}</span>
            </button>
          </div> -->
        </div>

        <div class="message-container">
          <span>{{ message }}</span>
        </div>
      </section>
    </ng-container>
  </section>
</div>

<div class="message-footer-content">
  <ng-content></ng-content>
</div>

<ng-template #historyButtonsMobile>
  <div class="history-buttons" [visible-horizontal]="botThreadId">
    <button mat-stroked-button color="primary" class="button-w-icon" (click)="openBotHistory()">
      <mat-icon style="margin-right: 4px;">smart_toy</mat-icon>
      {{ translations.botHistory }}
    </button>

    <button mat-stroked-button color="primary" class="button-w-icon" (click)="openFormHistory()">
      <mat-icon style="margin-right: 4px;">assignment</mat-icon>
      {{ translations.formHistory }}
    </button>

    <button *ngIf="isAttendingOnThisGroup()" mat-stroked-button color="primary" class="button-w-icon" (click)="openAtAGlance()">
      <mat-icon style="margin-right: 4px;">account_box</mat-icon>
      At a Glance
    </button>
  </div>
</ng-template>
