<!-- <pre>{{sections | json}}</pre> -->
<div class="main">
  <div [ngSwitch]="visualizationType">
    <app-tools-list-visualization
      *ngSwitchCase="EToolsVisualizationMode.List"
      [sections]="sections"
    >
    </app-tools-list-visualization>

    <app-tools-page-visualization
      *ngSwitchCase="EToolsVisualizationMode.Page"
      [sections]="sections"
    >
    </app-tools-page-visualization>
  </div>
</div>
