import { commonScreenOptionsTranslations3 } from '@colmeia/core/src/shared-business-rules/const-text/views/txt-common';
import { friendlyAction, TGlobalUID } from '../business/constant';
import { Serializable } from '../business/serializable';
import {
    IFriendDeleteExtraMessage, IFriendlyExtraMessage, IFriendlyMessageJSON, IUserErrorJSON, TDeleteErrorDetailArray, TDeleteFriendlyMessageArray, TExtraFriendlyMessageArray
} from '../comm-interfaces/business-interfaces';
import { UberCache } from '../persistency/uber-cache';
import { ITranslationConfig } from '../shared-business-rules/translation/translation-engine';
import { getFnName } from '../tools/get-fn-name';
import { concat, isValidArray, isValidRef } from '../tools/utility';
import { errorCodes } from './error-definition';
import { ErrorDomain } from './error-domain';

export class FriendlyMessage {
    private functionName: string;
    private isBusError: boolean = false;
    private okState: boolean;
    private friendlyErrorArray: Array<IUserErrorJSON>;
    private tips: Array<string>;
    private sucessMessage: TExtraFriendlyMessageArray;
    private noFurtherActionInterrupt: boolean;
    private deleteDependencies: TDeleteFriendlyMessageArray;
    private _isDeleteDependencies: boolean = false;
    private _canAutoDelete: boolean = true;
    private shouldIgnoreIfCannotDelete: boolean;
    public isWarning?: boolean;

    constructor(functionName: string = getFnName(2), initOkState: boolean, sucessMessage: TExtraFriendlyMessageArray = []) {
        // functionName ??= getFnName(2);
        this.functionName = functionName;
        this.okState = initOkState;
        this.friendlyErrorArray = [];
        this.tips = [];
        this.sucessMessage = sucessMessage;
        this.noFurtherActionInterrupt = false;
        this.isBusError = false;
    };

    public setOk(ok: boolean = true): void { this.okState = ok; };

    public updateOkState(): void {
        this.setOk(this.isReallyOk())
    }

    public setBusinessError(isBusinessError: boolean): void {
        this.isBusError = isBusinessError;
    }

    public isBusinessError(): boolean { return this.isBusError; };

    public setShouldIgnoreIfCannotDelete(value: boolean) {
        this.shouldIgnoreIfCannotDelete = value;
    }

    public getShouldIgnoreIfCannotDelete(): boolean {
        return this.shouldIgnoreIfCannotDelete;
    }

    public checkIfMustAbortExecutionFlow(): boolean {
        const shouldIgnoreIfCannotDelete = this.getShouldIgnoreIfCannotDelete()
        if (isValidRef(shouldIgnoreIfCannotDelete)) return !(shouldIgnoreIfCannotDelete);
        return (this.isNotOk() || isValidArray(this.getDeleteDependencies()));
    }

    public isDeleteDependenciesMessage(): boolean {
        return this._isDeleteDependencies;
    }

    public setCanAutoDelete(can: boolean) {
        this._canAutoDelete = can;
    }

    public canAutoDelete(): boolean {
        return this._canAutoDelete;
    }

    public setDeleteDependencies(dependencies: TDeleteFriendlyMessageArray): void {
        this._isDeleteDependencies = true;
        this.deleteDependencies = dependencies;
    }


    public addCustomMessages(errorCode: TGlobalUID, messages: string[], title?: string) {
        const items: IUserErrorJSON[] = messages
            .map(message => ({ title, errorID: errorCode, customMessage: message, shouldPrintCustomMessageOnly: true }))
            ;
        this.setOk(false);
        this.friendlyErrorArray.push(...items);
    };

    public hasCustomMessage() {
        return this.friendlyErrorArray.some(item => item.shouldPrintCustomMessageOnly);
    }

    public add(errorCode: TGlobalUID, ...messageArray: any[]) {
        this.setOk(false);
        this.friendlyErrorArray.push({ errorID: errorCode, customMessage: concat(messageArray) })
    };

    public isNoFurtherActionInterruption(): boolean {
        return this.noFurtherActionInterrupt;
    }

    public setNoFurtherActionInterruptionON(): void {
        this.noFurtherActionInterrupt = true;
    }

    public isOk(): boolean { return this.okState };

    public isOkAndContinue(): boolean { return this.okState && this.continueFlow() }

    public isNotOk(): boolean { return !this.isOk() }

    public addTip(...messageArray: any[]): void {
        this.tips.push(concat(messageArray));
    };

    public getTips() {
        return this.tips;
    }

    private rehydrate(json: IFriendlyMessageJSON): void {
        this.friendlyErrorArray = json.friendlyErrorArray;
        this.sucessMessage = json.sucessMessage;
        this.tips = json.tips;
        this.sucessMessage = json.sucessMessage;
        this.noFurtherActionInterrupt = json.noFurtherActionInterrupt;
        this.deleteDependencies = json.deleteDependencies;
        this._isDeleteDependencies = isValidRef(json.deleteDependencies);
        this._canAutoDelete = (isValidRef(json.canAutomaticDelete)) ? json.canAutomaticDelete : true;
        this.isBusError = json.isBussError;
        this.isWarning = json.isWarning;
    };

    public toJSON(): IFriendlyMessageJSON {
        const json: IFriendlyMessageJSON = {
            okState: this.okState,
            functionName: this.functionName,
            friendlyErrorArray: [],
            tips: [],
            sucessMessage: this.sucessMessage,
            noFurtherActionInterrupt: this.noFurtherActionInterrupt,
            deleteDependencies: this.deleteDependencies,
            canAutomaticDelete: this._canAutoDelete,
            isWarning: this.isWarning,
            isBussError: this.isBusError
        };
        json.friendlyErrorArray = this.friendlyErrorArray;
        json.tips = this.tips;
        return json;
    };

    public getDeleteDependencies(): TDeleteFriendlyMessageArray {
        return this.deleteDependencies;
    }

    public getFirstErrorDomain(): ErrorDomain {
        if (isValidArray(this.friendlyErrorArray) && UberCache.testCache(this.friendlyErrorArray[0].errorID))
            return ErrorDomain.staticFactory(this.friendlyErrorArray[0].errorID);
        return ErrorDomain.staticFactory(errorCodes.client.internalError);
    };

    public getReturnMessages(): TExtraFriendlyMessageArray {
        return this.sucessMessage ? this.sucessMessage : []
    };

    public getDelegatedMessagesToInfra(): TExtraFriendlyMessageArray {
        return this.sucessMessage ? this.sucessMessage.filter((m) => { return m.delegateToInfra; }) : []
    };

    public hasDelegatedMessageToInfra(): boolean {
        return this.hasReturnMessage() && this.sucessMessage.some((m) => { return m.delegateToInfra; })
    };

    public hasReturnMessage(): boolean {
        return isValidArray(this.sucessMessage)
    };

    public isReallyOk(): boolean {
        return this.isOk() && !this.hasReturnMessageWithError() && !this.isDeleteDependenciesMessage();
    }

    public hasReturnMessageWithError(): boolean {
        return isValidArray(this.getReturnMessageWithErrors())
    };

    public getReturnMessageWithErrors(): TExtraFriendlyMessageArray {
        return (this.sucessMessage.filter((e) => { return e.isError }))
    };

    public hasSpecificReturnMessage(idAction: number) {
        return this.hasReturnMessage() && this.sucessMessage.some((m) => { return m.friendlyAction == idAction })
    }

    public addReturnResponseArray(sucess: TExtraFriendlyMessageArray): void {
        for (const suc of sucess) {
            this.addReturnResponse(suc);
        };
    };

    public addReturnResponse(sucess: IFriendlyExtraMessage, additionalText: string = null, deleteDetails: TDeleteErrorDetailArray = null): void {
        if (!this.hasReturnMessage()) {
            this.sucessMessage = [];
        }
        if (!this.sucessMessage.some((s) => { return s.idSerializable == sucess.idSerializable && sucess.idField == s.idField })) {
            this.sucessMessage.push(sucess);
        };

        if (additionalText) {
            sucess.additionalText = additionalText;
        };

        if (isValidArray(deleteDetails)) {
            (<IFriendDeleteExtraMessage>sucess).details = deleteDetails;
        };
    };

    public addReturnTranslationResponse(translation: ITranslationConfig, additionalText?: string, returnNowToClient: boolean = true, isError: boolean = true): void {
        const messageError: IFriendlyExtraMessage = {
            delegateToInfra: true,
            friendlyAction: friendlyAction.none,
            returnNowToClient, isError,
            idField: translation.idField,
            idSerializable: translation.serializableId
        }

        this.addReturnResponse(messageError, additionalText);

    }

    public addReturnGenericErrorMessages(messages: string[]) {
        this.addReturnTranslationResponse(commonScreenOptionsTranslations3.error, makeErrorMessage(messages))
        function makeErrorMessage(messages: string[]): string {
            return messages.map(message => `\n${message}`).join('')
        }
    }

    public addReturnGenericErrorMessage(message: string) {
        return this.addReturnTranslationResponse(commonScreenOptionsTranslations3.error, message)
    }


    public addValidationError(translation: ITranslationConfig, message?: string): void {
        this.addReturnTranslationResponse(translation, message);
    }

    public static getReturnTranslationByMessage(message: IFriendlyExtraMessage): ITranslationConfig {
        const config: ITranslationConfig = {
            serializableId: message.idSerializable,
            idField: message.idField,
        }
        if (message.additionalText) config.additionalValue = message.additionalText;
        return config
    }

    public getReturnErrorTranslations(): ITranslationConfig[] {
        return this.getReturnMessageWithErrors().map(FriendlyMessage.getReturnTranslationByMessage);
    }



    public goNowToClient(): boolean {
        return this.hasReturnMessage() && this.sucessMessage.some((x) => { return x.returnNowToClient });
    };

    public continueFlow(): boolean {
        return !this.noFurtherActionInterrupt && !this.goNowToClient()
    };

    public isCookieLogError(): boolean {
        return isValidArray(this.friendlyErrorArray) && this.friendlyErrorArray[0].errorID == errorCodes.client.auth.socketLoginError;
    };

    public static factoryMessage(json: IFriendlyMessageJSON): FriendlyMessage {
        let friend: FriendlyMessage = new FriendlyMessage(json.functionName, json.okState);
        friend.rehydrate(json);
        return friend;
    };

    public generateSystemError(): string {
        let st: string = '';
        for (let userError of this.friendlyErrorArray)
            st += userError.errorID + ' ' + userError.customMessage + '\n';

        return st;
    };

    public getFriendlyArray(): Array<IUserErrorJSON> {
        return this.friendlyErrorArray;
    }

    public setFunctionName(functionName: string): void {
        this.functionName = functionName;
    };

    public getFunctionName(): string {
        return this.functionName;
    }

    public getErrorMessage(divider: string = '\n'): string {
        return this.friendlyErrorArray.map((error: IUserErrorJSON) => error.customMessage).join(divider);
    };

    public containsErrorId(errorId: string): boolean {
        for (const userError of this.friendlyErrorArray) {
            if (userError.errorID === errorId)
                return true;
        }
        return false;
    };

    public getErrorsAsString(): string[] {
        return this
            .getReturnMessages()
            .map(err =>
                [Serializable.getTranslation(FriendlyMessage.getReturnTranslationByMessage(err)), err.additionalText]
                    .filter(item => item).join(' ')
            )
            ;
    }
}
