<div class="deploy_container">
  <div class="flex-space-btw">
    <span>
      {{ translations.addTheFollowingItemsToAPatch }}
    </span>
    <span *ngIf="hasOperation()">
      {{ translations.operation }}
    </span>
  </div>

  <ul #itensList [class.default-color]="!hasOperation()" class="patch-itens-list nice-scroll">
    <li *ngFor="let desc of descriptions;" class="flex-space-btw">
      <span class="item-name">
        {{ desc.nName }}
      </span>
      <span class="item-versionName">
        {{ desc.versionName }}
      </span>
      <span class="opration-tag" [ngClass]="desc.operationType || ''">
        {{ getOperationTranslation(desc) }}
      </span>
    </li>
  </ul>

  <mat-form-field>
    <mat-label>Tipo de deploy</mat-label>
    <mat-select [(ngModel)]="saveType">
      <mat-option *ngFor="let type of allowedSaveTypes" [value]="type">
        {{saveTypeEnum[type]}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <app-ns-picker *ngIf="deployListHandler" [handler]="deployListHandler"></app-ns-picker>

  <button mat-flat-button color="primary" [disabled]="!canSubmit" (click)="submit()">
    {{ translations.addToTheDeploysList }}
  </button>
</div>
