import { Injectable } from '@angular/core';
import { ESocialMedia } from '@colmeia/core/src/business/social';
import { IRequest, IUnbindSocialMediaRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IActiveSocialBinds } from "@colmeia/core/src/request-interfaces/response-interfaces";
import {RequestBuilderServices} from "./request-builder.services";
import {ServerCommunicationService} from "./server-communication.service";
import {ClientInfraResponse, IInfraParameters} from "../model/client-infra-comm";

@Injectable({
    providedIn: 'root'
})
export class SocialService {

    constructor(
        private requestBuilderSVC: RequestBuilderServices,
        private serverCommSVC: ServerCommunicationService
    ) { }

    async unbindSocialMedia(media: ESocialMedia) {
        const infra: IInfraParameters = this.requestBuilderSVC.getContextNoCallBackSpinnningParameters(),
              request: IUnbindSocialMediaRequest = {
                  ...this.requestBuilderSVC.secureBasicRequest(apiRequestType.socialBind.unbind),
                  media
              },
              response: ClientInfraResponse = await this.serverCommSVC.managedRequest(infra, request);
    }

    async fetchActiveSocialBinds(): Promise<IActiveSocialBinds> {
        const infra: IInfraParameters = this.requestBuilderSVC.getContextNoCallBackNoSpinnningParameters(),
              request: IRequest = {
            ...this.requestBuilderSVC.secureBasicRequest(apiRequestType.socialBind.getActiveBinds)
        },
              response: ClientInfraResponse = await this.serverCommSVC.managedRequest(infra, request);

        if (response.executionOK) {
            return <IActiveSocialBinds> response.response;
        }

        return <IActiveSocialBinds> {activeBinds: []};
    }

}
