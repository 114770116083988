<!-- <pre>{{ getInteractionHandlerArray()?.length }}</pre> -->

<!-- in a survey, display survey title -->
<header class="survey-title" *ngIf="canShowPreviewTitle()">
  <h2>era o texto do survey</h2>
  <hr>
</header>

<!-- Messages Container -->
<div class="message-container-wrapper" [style.height]="getMessageContainerHeight()">
  <div
  #messageContainer
  class="message__container"
  (scroll)="onScroll($event)"
  [class.centered-box]="canShowPreviewTitle()"
  [class.message__survey]="canSetMarginOnBothSides()"
  [class.disable-padding-bottom]="mustAnswerEmbeddedMenu()"
  >
    <div class="loading-prev-interaction-container mat-elevation-z1" [class.visible]="loadingPreviousInteractions">
      <div class="spin-el"></div>
    </div>
    <app-message-instance-iterator #msgIterator
      [chatBackboneInputData]="chatBackboneHandler"
      [arrayMessageHandlers]="getInteractionHandlerArray()"
      [isRootIterator]="true">
    </app-message-instance-iterator>
  </div>
</div>

<div
  #actionBarWrapper
  class="chat-action-bar__wrapper"
  [class.hidden]="!canShowChatInputBar()"
  [class.bar-resizing]="barResizing"
  [style.min-height]="getActionBarMinHeight()+'px'"
  [style.height]="getActionBarHeight()"
  >
  <span
    *ngIf="!this.disableActionBarResize()"
    #resizeTriggerContainer
    class="resize-trigger-container">
    <span draggable (dragStart)="resizeDragStart()" (dragMove)="resizeDragMove($event)" (dragEnd)="resizeDragEnd()" class="material-icons resize-trigger">drag_handle</span>
  </span>
  <!-- chat message input -->
  <div class="chat-action-bar__container">
    <app-chat-action-bar *ngIf="canShowChatInputBar()"></app-chat-action-bar>
  </div>
</div>

<attendance-chat-channel-closed *ngIf="!isEmbedded && channelStatus.isClosed" [channelStatus]="channelStatus"></attendance-chat-channel-closed>

<span class="isTyping"
      [ngClass]="{'isTyping--typing': getWhoIsTyping()}">
  {{ getWhoIsTyping() }}
</span>
