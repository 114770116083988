import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { SerializableHeaders } from "./serializable-headers";
import { TDateTransform } from "@colmeia/core/src/time/time-utl";
import { PlayerAuthorizeOnlyInfo } from "@colmeia/core/src/business/player-minimun-auth";
import { MessageShrinker, IShortMessage } from "@colmeia/core/src/shared-business-rules/small-message/shrink-message"
import { UserDefaultSettings } from "@colmeia/core/src/business/barrel-business";
import {
    IInteractionActors,
    IUniversalJSON
} from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import {SessionService} from "./session.service";
import {UserSettingsService} from "./user-settings.service";

export enum EInteractionToNotificationCases {
    MessageInstance = 1,
    NotificationScreen
}

@Injectable({
    providedIn: 'root'
})
export class NotificationInteractionToText {

    private datePipe: DatePipe;

    constructor(
        private settingsSVC: UserSettingsService,
        private sessionSVC: SessionService,
    ) {
        this.datePipe = new DatePipe(this.settingsSVC.getSelectedLocale());
    }


    private clockTickToDate(clock: number, transform: TDateTransform): string {
        const format = `${this.settingsSVC.getDateFormat()} ${this.settingsSVC.getTwentyFourTimeFormat()}`;
        return ' ';
    }

    public async transform(interaction: Interaction, _case: EInteractionToNotificationCases): Promise<IShortMessage> {
        return this.transformFromJSON(interaction.toJSON(), _case);
    }

    public async transformFromJSON(interactionJSON: IInteractionJSON, _case: EInteractionToNotificationCases): Promise<IShortMessage> {
        const player: PlayerAuthorizeOnlyInfo = this.sessionSVC.getPlayerInfoServices().getPlayerInfo().toAuthorizeOnlyInfo();
        const shrinker: MessageShrinker = new MessageShrinker(player, UserDefaultSettings.getCurrentProfile());
        let from, to: IUniversalJSON;
        const actors: IInteractionActors = shrinker.getInteractionActors(
            interactionJSON,
            interactionJSON.toSocketAvatarID || this.sessionSVC.getSelectedAvatar().getPrimaryID()
        );

        const hashJSON = await SerializableHeaders.fetch([
            {
                objectTypeID: actors.idFromObjectType,
                serializableID: actors.fromSubscriber
            },
            {
                objectTypeID: actors.idToObjectType,
                serializableID: actors.toSubject
            }
        ]);

        from = hashJSON[actors.fromSubscriber];
        to = hashJSON[actors.toSubject];

        const short: IShortMessage = shrinker.getShortMessage(interactionJSON, from, to);

        return short;
    }
}
