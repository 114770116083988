import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { TArrayID } from "../../core-constants/types";
import { INonSerializableSaveRequest, IRequest, IRequestCursor } from "../../request-interfaces/request-interfaces";
import { IResponse } from "../../request-interfaces/response-interfaces";
import { INextGenBotServer } from "../bot/bot-model";
import { TColmeiaTagArray } from "../colmeia-tags/tags";
import { ENonSerializableObjectType, INonSerializableHeader, TINonSerializableArray } from "./non-serializable-id-interfaces";

export interface ISaveBotSerializableRequest extends INonSerializableSaveRequest {
    idGraphRoot: IdDep<ENonSerializableObjectType.graphElement>
    ignoreMenuItemRefresh?: boolean;
}
export interface ISaveBotSerializableResponse extends IResponse {
    savedBot: INextGenBotServer;
    savedElements: TINonSerializableArray;
}

// export interface IListNonSerializablesResponse extends IResponseCursor {
//     nonSerializableArray: TNonSerializableArray;
// }

export interface ITagableSearch {
    demandedTag: string;
    searchedTags?: TArrayID;
}

export interface ITagableRequest extends IRequestCursor, ITagableSearch {

}

export interface IUsedTagsData {
    demandedTag: string;
    nsType: ENonSerializableObjectType;
}


export interface IUsedTagsRequest extends IUsedTagsData, IRequest {

};

export interface IUsedTagsResponse extends IResponse {
    usedTags: TColmeiaTagArray;
}

export enum ENonserializableControllerRequest {
  save = 'save'
}

export interface ISaveNonserializableOnVersionRequest extends INonSerializableSaveRequest {}
export interface ISaveNonserializableOnVersionResponse extends IResponse {}
