<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">
  <mat-tab-group style="margin-top: -6px;" class="boxed-tabs boxed-dense tabs-wrap" animationDuration="200ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">notifications</mat-icon>
        Inscrição
      </ng-template>
      <div class="content">  
        <app-bpm-listener *ngIf="bpmListenerHandler" [handler]="bpmListenerHandler"></app-bpm-listener>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">launch</mat-icon>
        Ações
      </ng-template>
      <div class="content">
        <app-new-condition-editor
          *ngIf="evaluatorsOfActions"
          [handler]="evaluatorsOfActions"
          [autoOpenWhenEmpty]="false"
          [autoOpenFirstItem]="true"
        ></app-new-condition-editor>
      </div>
    </mat-tab>
  </mat-tab-group>
</cm-generic-dashboard-edit>
