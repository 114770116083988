import { TGoPermissionArray, EGoPermissionType } from "../comm-interfaces/interaction-interfaces";

export const domain = {
    validateBy: {
        onlyEmailValidatedPlayer: 'onlyEmailValidatedPlayer', // apenas players que tiveram seus avatares validados com EMAIL
        onlySMSValidatedPlayer: 'onlySMSValidatedPlayer', // apenas players que tiveram seus avatares validados com  SMS
        onlyFacebookValidatedPlayer: 'onlyFacebookValidatedPlayer', // apenas players que tiveram seus avatares validados com  SMS
        onlylinkedinValidated: 'onlylinkedinValidated',
        onlyPurchasedValidatedPlayer: 'onlyPurchasedValidatedPlayer',
        onlyTwiterValidatedPlayer: 'onlyTwiterValidatedPlayer',
        onlyCorporateEmailValidatedPlayer: 'onlyCorporateEmailValidatedPlayer',
    },
    enrollmentRule: {
        subscriptionAfterApproval: 'subscriptionAfterApproval', // Grupo necessita de aprovação para entrar 
        onlyInvitedCanParticipate: 'onlyInvitedCanParticipate', // apenas pessoas que possuem invite
    },
    others: {
        allowSpokePerson: 'allowSpokePerson',
        allowSubgroupCreation: 'allowSubgroupCreation', // Permite a criacao de subgrupos
        allowChannelCreation: 'allowChannelCreation',
        onlyLogedUsersCanSeeContent: 'onlyLogedUsersCanSeeContent', // So usuarios logados podem ver conteudo NÃO EM USO
        dataWillBeCypher: 'dataWillBeCypher', // não tem
        canSearchVoiceMessages: 'canSearchVoiceMessages', // pesquisar texto de mensagem de voz
        canDeleteMessages: 'canDeleteMessages', // pode deletar mensagem
        dontShowParticipantList: 'dontShowParticipantList'
    },
    visibility: {
        contentAvailableOnGoogle: 'contentAvailableOnGoogle',
        secretGroup: 'secretGroup',
        secretGroupForNonEmployees: 'secretForNonEmployees',
        postsCanBeSearchedOnColmeia: 'postsCanBeSearchedOnColmeia',
        onlyParticipantCanSeeContent: 'onlyParticipantCanSeeContent', // conteudo apenas para participants
    },
    sharing: {
        allowGroupFusion: 'canBeAttachedOnAnotherGroup',
        allowGroupFusionAfterApproval: 'attachedOnAnoterGroupAfterApproval',
        allowDistributionList: 'allowReceiveMultiGroupIntereactions',
        allowReceiveDistributionAfterApproval: 'receiveMultiGroupAfterApproval',
        interactions: {
            toOutside: {
                open: 'toOutsideSharingOpen',
                thisSN: 'toOutiseSharingThisSN',
                anySN: 'toOutsideClosed',
            },
            fromOutside: {
                open: 'fromOutsideSharingOpen',
                thisSN: 'fromOutiseSharingThisSN',
                anySN: 'fromOutsideClosed',
            },
        },
    },
    securityChain: {
        allChainWithSameParticipants:       'bringParticipantsFromFatherNetwork',
        allChainWithSameSecuritySettings:   'allGroupChainWillHaveSameSecSet',
        securityAttachedToParent:           'attachSecurityToParentGroup',
        bindParticipantToParent: 'bindParticipantToParent',
    },
    whoWillSeeWhoRead: {
        adminAndSender: 'adminAndOwner',
        senderOnly: 'senderOnly',
        nobody: 'nobodyWillSee',
        everyBodyWillSee: 'everyBodyWillSee',
        adminOnly: 'onlyAdminWillSee',
    },
    avatarPrivacy: {
        friendship: {
            afterApproval: 'avatarFriendshipAfterApproval',
            closed: 'avatarFriendshipClosed',
            open: 'avatarFriendshipOpen',
        },
        addInPrivateMessage: {
            afterApproval: 'avatarPrivateMessageAfterApproval',
            closed: 'avatarPrivateMessageClosed',
            open: 'avatarPrivateMessageOpen',
        },
        teamMembership: {
            afterApproval: 'avatarTeamMembershipAfterApproval',
            closed: 'avatarTeamMembershipClosed',
            open: 'avatarTeamMembershipAllow',

        },
    },
};

export const permission = {
    canA2A:'canA2A',                          
    canAddCompared:'canAddCompared',            
    canAddForm:'canAddForm',                       
    hasAdminPrivilege:'hasAdminPrivilege',              
    hasRootPrivilege: 'hasRootPrivilege',
    canSendAdminMessage:'canSendAdminMessage',                 
    canApproveGroupAttachement:'canApproveGroupAttachement',           
    canApproveParticipant:'canApproveParticipant',            
    canAskToAttachToGroup:'canAskToAttachToGroup',               
    canSendBroadcast:'canSendBroadcast',                    
    canDeleteOthersInteractions:'canDeleteOthersInteractions',            
    canExcludeAdmin:'canExcludeAdmin',                  
    canExcludeParticipant:'canExcludeParticipant',            
    canIncludeAdmin:'canIncludeAdmin',                  
    canInteract:'canInteract',                      
    canGiveRole:'canGiveRole',                      
    canCreateSubgroup:'canCreateSubgroup',                  
    canVote:'canVote',
    canMultiGroup: 'canMultiGroup',
    canApproveMultiGroup: 'canApproveMultiGroup',
    oneToManyPublisher: 'oneToManyPublisher',
    excludedFromGroup: 'exludedgroup',
};

export type ERoleConstantList = Array<ERoleConstant>;
export enum ERoleConstant {
    Root  = '1@5',
    Admin = '2@5',
    Moder = '3@5',
    User = '4@5',
    Nright = '5@5',
    Functional = 'functionalRole',
    archiverRole = 'archiverRole'
    
};

export const navigationRules = {
    default: 'default', // Default para Filtros (sem filtros)
    typeOfMessages: 'typeOfMessages', 
    where: {
        specialFeatures: 'specialGroups',
        hierarchical: 'onThisSocialNetwork',
        yourPersonalGroups: 'yourPersonalGroups',
        createdByAvatar: 'groupsYouCreated',
        enrolled: 'groupsYouParticipate',
        allGroupsJustBellow: 'allGroupsJustBellow', 
    },
    attention: {
        noAttentionRequired:       'noAttentionRequired',
        attentionRequiredByOthers: 'RequireYourAttentionOnNavigation',
        attentionRequiredByYou:    'attentionRequiredByYou', 
    },
    popularity: {
        emerging: 'emergingGroups',
        hot:      'hotGroups',
        recents:  'recentlyUpdatedGroups',
        all:      'allPopularity', 
    },
    followUp: {
        open: 'openMessages', 
        replied: 'replyedMessages',
        both: 'bothReplyedOrNot',
    },
    bookmarked: 'Bookmarked', // Só pode ser PersonalGroup
};

export const RolesWithAdmin: TGoPermissionArray = [
    {permissionType: EGoPermissionType.role, idPermissionRole: ERoleConstant.Admin},
    {permissionType: EGoPermissionType.role, idPermissionRole: ERoleConstant.Root}
]