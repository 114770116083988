import { AnimationTriggerMetadata, trigger, state, style, transition, animate } from "@angular/animations";
import { EXPANSION_PANEL_ANIMATION_TIMING, MatExpansionPanelState } from "@angular/material/expansion";

export type TVisibleContentState = MatExpansionPanelState;

export const visibleComponentAnimations: {
    readonly contentExpansionHorizontal: AnimationTriggerMetadata;
    readonly contentExpansionVertical: AnimationTriggerMetadata;
    readonly contentExpansionBoth: AnimationTriggerMetadata;
  } = {
    /** Animation that expands and collapses the panel content. */
    contentExpansionHorizontal: trigger('contentExpansionHorizontal', [
      state('collapsed, void', style({height: '0px', visibility: 'hidden'})),
      state('expanded', style({height: '*', visibility: 'visible'})),
      transition(
        'expanded <=> collapsed, void => collapsed',
        animate(EXPANSION_PANEL_ANIMATION_TIMING),
      ),
    ]),
    /** Animation that expands and collapses the panel content. */
    contentExpansionVertical: trigger('contentExpansionVertical', [
      state('collapsed, void', style({width: '0px', visibility: 'hidden'})),
      state('expanded', style({width: '*', visibility: 'visible'})),
      transition(
        'expanded <=> collapsed, void => collapsed',
        animate(EXPANSION_PANEL_ANIMATION_TIMING),
      ),
    ]),
    /** Animation that expands and collapses the panel content. */
    contentExpansionBoth: trigger('contentExpansionBoth', [
      state('collapsed, void', style({width: '0px', height: '0px', visibility: 'hidden'})),
      state('expanded', style({width: '*', height: '*', visibility: 'visible'})),
      transition(
        'expanded <=> collapsed, void => collapsed',
        animate(EXPANSION_PANEL_ANIMATION_TIMING),
      ),
    ])
  };
