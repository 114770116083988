import {Participant, constant} from '../../business/barrel-business';
import {Interaction} from '../interaction';
import {InteractionType} from '../interaction-type';
import { TGlobalUID } from '../../business/constant';
import { IFeatureCarrierInteractioJSON, IFirstFormFeature } from '../../comm-interfaces/interaction-interfaces';




export class FeatureCarrier extends Interaction {


    private data: IFirstFormFeature;


    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.standard.featureCarrier));
        this.setMessage('');
    }


    public getFirstIDAnswer(): string {return this.data.idAnswer};
    public getFirstIDSchema(): string {return this.data.idSchema};
    public getData(): IFirstFormFeature {return this.data}

    public static factoryMessage(json: IFeatureCarrierInteractioJSON, participant: Participant = null): FeatureCarrier {
        let carrier = <FeatureCarrier>Interaction.searchCachedInteraction(json.primaryID);
        if (carrier == null)
            carrier = new FeatureCarrier(json.primaryID, 
                        participant? participant: Participant.staticFactory(json.participant.primaryID),
                       );
        carrier.rehydrate(json);
        return carrier;
    };



    public static staticFactory(idFeedback: TGlobalUID): FeatureCarrier {
        return <FeatureCarrier>Interaction.staticFactory(idFeedback);
    };



    public toJSON(): IFeatureCarrierInteractioJSON  {
        const json: IFeatureCarrierInteractioJSON = {
            ...super.toJSON(),
            data: this.data
        };
        return json;
    };

    
    public rehydrate(json: IFeatureCarrierInteractioJSON): void {
        super.rehydrate(json);
        this.data = json.data;
    };
}

