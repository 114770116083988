import { VitualNonSerializableGenerator } from "../../nser/virtual-ns";
import { typedClone } from "../../tools/utility";
import { initialAction } from "./bot-action-model";
import { ENextGenBotElementType, INextGenBotServer } from "./bot-model";

export interface IBotIntefaceFactoryInfo {
    idParentNode: string
}

export class BotIntefaceFactory {
    /**
     * creates bot instance
     */
    public static create(type: ENextGenBotElementType, info: IBotIntefaceFactoryInfo): INextGenBotServer {
        switch(type) {
            case ENextGenBotElementType.botMenuItem: 
                const ns = VitualNonSerializableGenerator.getMenuItemContainer('', typedClone(initialAction), false, undefined);
                ns.idParent = info.idParentNode;
                return ns;
            case ENextGenBotElementType.menuContainer:
                return VitualNonSerializableGenerator.getElementBotWithParent(
                    type,
                    '',
                    [],
                    info.idParentNode,
                    // this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor().getRootElement().getHostedID(),
                    false
                );
            case ENextGenBotElementType.root:
                return VitualNonSerializableGenerator.getRoot();
            default:
                return VitualNonSerializableGenerator.getElementBotWithParent(
                    type, 
                    '', 
                    [], 
                    // this.parameters.workElement.idParentNode,
                    info.idParentNode,
                    false
                );
        }
    }
}
