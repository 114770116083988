import { IKnowledgeDBServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces";
import { IMLLuisPhraseList, IMLLuisEntity, EMLEntityType, IMLLuisNormalizedValueHash, IMLLuisPattern, IMLLuisUtterenceExample, IMLLuisUtterenceLabel, IUtteranceServer, IMLLuisUtterenceEntityPrediction, IOmniSenseStrategyConfig, IOminiSenseIAConfig } from "@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { TArrayID } from "@colmeia/core/src/core-constants/barrel-core-constants";
import { isInvalid, isValidArray, isValidString, tokenSpecialCharacterRegex } from "@colmeia/core/src/tools/utility";
import { ITranslationHash } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { IKBGetInformationResponse } from "@colmeia/core/src/shared-business-rules/knowledge-base/kn-req-resp";

export interface IKnowledgeBaseFields {
    text: string;
    intentName: string;
    intentId: string;
}

export interface IIntent {
    name: string;
    id: string;
}

export type TIntentArray = Array<IIntent>;

export type TKnowledgeBaseFieldsArray = Array<IKnowledgeBaseFields>;

export interface IKnowledgeBaseQuestionResult {
    content: string;
    intents: string[];
    accuracy: number;
}

export type TKnowledgeBaseQuestionResultArray = Array<IKnowledgeBaseQuestionResult>;

export interface IIntentCountHash {
    [intent: string]: number;
}

export interface IKnowlegeBaseIntentContentData {
    contents: string[];
}

export interface IIntentUtteranceCreateHash {
    [intentName: string]: IMLLuisUtterenceExample[];
}

export interface ICreatePhraseListData {
    onSave: () => void;
    idKB: TGlobalUID;
    editingPhraseList?: IMLLuisPhraseList;
}

export interface ICreateStrategyListData {
    onSave: (strategy?: IOmniSenseStrategyConfig) => void;
    idKB: TGlobalUID;
    osIAConfig: IOminiSenseIAConfig;
    editingStrategy?: IOmniSenseStrategyConfig;
    isEditing: boolean;
}

export interface IEntityRole {
    text: string;
    editing: boolean;
}

export interface IKBEntity extends IMLLuisEntity {
    typeText: string;
}

export const EntityTypeTranslations: ITranslationHash = {
    [EMLEntityType.list]: gTranslations.kb[EMLEntityType.list],
    [EMLEntityType.simple]: gTranslations.kb[EMLEntityType.simple],
    [EMLEntityType.composite]: gTranslations.kb[EMLEntityType.composite],
    [EMLEntityType.regex]: gTranslations.kb[EMLEntityType.regex],
    [EMLEntityType.patternAny]: gTranslations.kb[EMLEntityType.patternAny],
    [EMLEntityType.prebuilt]: gTranslations.kb[EMLEntityType.prebuilt],
    [EMLEntityType.machineLearned]: gTranslations.kb[EMLEntityType.machineLearned]
}

export const EntityTypesForCreation: EMLEntityType[] = [
    EMLEntityType.list,
    EMLEntityType.regex,
    EMLEntityType.machineLearned,
];

export interface IKBCreateEntityData {
    kb: IKnowledgeDBServer;
    onSave(entity: IMLLuisEntity): void;
}

export interface INormalizedValue {
    value: string;
    synonyms: string[];
}

export function transformIntoNormalizedValueArray(values: IMLLuisNormalizedValueHash): INormalizedValue[] {
    if (isInvalid(values)) {
        return [];
    }
    return Object.entries(values).map(([value, synonyms]) => ({value, synonyms}));
}

export function transformIntoNormalizedValueHash(values: INormalizedValue[]): IMLLuisNormalizedValueHash {
    if (!isValidArray(values)) {
        return {};
    }

    return values.reduce((acc, normalizedValue) => {
        acc[normalizedValue.value] = normalizedValue.synonyms
        return acc;
    }, {})
}

export function splitAndAdd(source: string, target: string[]): void {
    Array.prototype.push.apply(
        target,
        Array.from(new Set(
            source.split(',')
            .map(text => text.trim())
            .filter(text => isValidString(text) && !target.includes(text))
        ))
    );
}

export interface IEntityChildrenChange {
    children: TArrayID;
}

export interface IMLClientPattern extends IMLLuisPattern {
    editing: boolean;
    oldPattern?: string;
    oldIntentName?: string;
    updating?: boolean;
    patternHtml: string;
}

export function patternTextToHtml(patternText: string): string {
    const entitySplit = patternText.split('{');
    const tokens = [];
    entitySplit.forEach(split => {
        if (split.includes('}')) {
            const parts = split.split('}');
            const entity = `<span class="text-entity">${parts[0]}</span>`;
            tokens.push(entity, parts[1]);
        } else {
            tokens.push(split);
        }
    });
    return tokens.join('');
}

export enum EPatternSaveResult {
    success = 'success',
    error = 'error',
    errorMissingEntityOrText = 'errorMissingEntityOrText',
    errorWrongSyntax = 'errorWrongSyntax'
}

export interface IKbPredictData {
    knowledgeBaseResponse: IKBGetInformationResponse;
    updateKB(): Promise<void>;
    testInput?: string;
}

export interface IKbCreateIntentData {
    knowledgeBase: IKnowledgeDBServer,
    onSave(): void,
}

export interface IUtteranceToken {
    plain: boolean;
    text: string;
    tooltip?: string;
    startingIndex: number;
    compositeEntity?: string;
    extraEntities: string[];
    children: IUtteranceToken[]
}

function getCharIndexesByTokenIndex(startIndex: number, endIndex: number, tokens: string[]): { startCharIndex: number; endCharIndex: number } {
    const state = { startCharIndex: 0, endCharIndex: undefined };
    const spaceLength = 1;
    let currentChatCount = 0;

    for (const tokenIndexString in tokens) {
        const tokenIndex = +tokenIndexString;
        const token = tokens[tokenIndex];

        if (tokenIndex === startIndex) {
            state.startCharIndex = currentChatCount;
        }

        if (tokenIndex <= endIndex) {
            state.endCharIndex = currentChatCount + token.length;

            if (tokenIndex === endIndex) break;
        }


        currentChatCount += token.length + spaceLength;
    }

    return state;
}

function getTokenIndexByCharIndex(charIndex: number, phrase: string): number {
    const left = phrase.slice(0, charIndex);
    return left.match(/\s/g)?.length || 0;
}

export function entityPredictionToLabel(prediction: IMLLuisUtterenceEntityPrediction, tokens: string[]): IMLLuisUtterenceLabel {
    const { startCharIndex, endCharIndex } = getCharIndexesByTokenIndex(prediction.startTokenIndex, prediction.endTokenIndex, tokens);

    const result: IMLLuisUtterenceLabel = {
        entityName: prediction.entityName,
        entityId: prediction.entityId,
        startCharIndex,
        endCharIndex,
        children: []
    }

    if(isValidArray(prediction.children)) {
        result.children = prediction.children.map( pred => entityPredictionToLabel(pred, tokens) );
    }

    return result;
}

export function entityLabelToPrediction(label: IMLLuisUtterenceLabel, phrase: string): IMLLuisUtterenceEntityPrediction {
    const result: IMLLuisUtterenceEntityPrediction = {
        entityId: label.entityId,
        entityName: label.entityName,
        startTokenIndex: getTokenIndexByCharIndex(label.startCharIndex, phrase),
        endTokenIndex: getTokenIndexByCharIndex(label.endCharIndex, phrase),
        phrase: phrase.slice(label.startCharIndex, label.endCharIndex)
    };

    if(isValidArray(label.children)) {
        result.children = label.children.map((label) => entityLabelToPrediction(label, phrase));
    }

    return result;
}

export function tokenizeText(text: string, currentOffset: number): IUtteranceToken[] {
    const tokens: IUtteranceToken[] = [];
    text = text.replace(tokenSpecialCharacterRegex, ' $& ')
    let offset = currentOffset;
    text.split(' ').forEach(part => {
        if (!isValidString(part)) {
            return;
        }

        const token: IUtteranceToken = {
            plain: true,
            text: part,
            startingIndex: offset,
            extraEntities: [],
            children: []
        }
        tokens.push(token);
        offset += part.length +1;
    })
    return tokens;
}

export function splitUtteranceIntoTokens(example: IMLLuisUtterenceExample, entities: IMLLuisEntity[] = []): IUtteranceToken[] {
    let text = example.example;

    let tokens: IUtteranceToken[] = [];
    let indexOffset = 0;

    if (isValidArray(example.entityLabels)) {

        const entityLabels = example.entityLabels.sort((a: IMLLuisUtterenceLabel, b: IMLLuisUtterenceLabel) => a.startCharIndex - b.startCharIndex);

        const hash = entityLabels.reduce((acc, label) => {
            if (acc[label.startCharIndex] == undefined) acc[label.startCharIndex] = [];
            acc[label.startCharIndex].push(label);
            return acc;
        }, {});

        Object.values(hash).forEach((labels: IMLLuisUtterenceLabel[]) => {

            let label: IMLLuisUtterenceLabel;
            let otherLabels: IMLLuisUtterenceLabel[] = [];
            let composite: string;

            if (labels.length === 1) {
                label = labels[0];
            } else {
                label = labels.find(label => {
                    const entity = entities.find(ent => ent.entityName == label.entityName);

                    if (entity.entityType === EMLEntityType.composite) {
                        composite = entity.entityName;
                        return false;
                    }

                    return isValidString(composite) ? true : entity.entityType !== EMLEntityType.list;
                });
                otherLabels = labels.filter(otherLabel => otherLabel.entityName !== label.entityName);
            }

            const entity = entities.find(e => e.entityName === label.entityName);
            const isComposite = entity.entityType === EMLEntityType.composite;

            const startIndex = label.startCharIndex - indexOffset;
            const endIndex = label.endCharIndex - indexOffset;

            const word = text.substring(startIndex, endIndex);

            const firstPart = text.substring(0, startIndex);
            const secondPart = text.substring(endIndex, text.length);

            tokens.push(...tokenizeText(firstPart, indexOffset));
            tokens.push({
                plain: isComposite,
                text: isComposite ? word : label.entityName,
                tooltip: isComposite? undefined : word,
                startingIndex: startIndex,
                extraEntities: otherLabels.map(label => label.entityName),
                compositeEntity: isComposite ? label.entityName : composite,
                children: isValidArray(otherLabels) ? splitUtteranceIntoTokens(
                    {
                        example: example.example,
                        entityLabels: label.children
                    } as IMLLuisUtterenceExample,
                    entity.subEntities
                ) : []
            });

            text = secondPart;
            indexOffset += (firstPart.length + word.length);
        });
    }

    tokens.push(...tokenizeText(text, indexOffset));

    if (tokens[0].text === '') {
        tokens.splice(0, 1);
    }

    let prev: IUtteranceToken = { plain: true, text: '', startingIndex: 0, extraEntities: [], children: [] };
    tokens = tokens.filter(token => isValidString(token.text));
    tokens.forEach((token, index) => {
        if (index === tokens.length -1) {
            return;
        }

        if (token.plain) {
            token.text = token.text + ' ';

            if (!prev.plain) {
                token.text = ' ' + token.text;
            }
        }
        prev = token;
    });
    return tokens;
}

export interface ILabelUtteranceTokenData {
    token: IUtteranceToken;
    utterance: IUtteranceServer;
    kb: IKnowledgeDBServer;
    onSave(): void;
}

export function isValidEntityToLabel(entity: IMLLuisEntity): boolean {
    return [
        EMLEntityType.list,
        EMLEntityType.simple,
    ].includes(entity.entityType);
}

export interface IUtterance {
    utterance: IUtteranceServer;
    editing: boolean;
    saving: boolean;
    oldExample: string;
    score: number;


    isHiding?: boolean;
    isCreating?: boolean;
}

export interface IGetUtteranceResult {
    utterances: IUtterance[],
    cursor: string;
}

export interface IAddUtteranceData {
    kb: IKnowledgeDBServer;
    intentName: string;
    onSave(): void;
}

export function isValidTypeToAssociate(type: EMLEntityType): boolean {
    return [EMLEntityType.simple].includes(type);
}
