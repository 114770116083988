import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {MultimediaInstance} from "@colmeia/core/src/multi-media/multi-media-instance";
import {HttpClient} from "@angular/common/http";
import {ConstantService} from "../../../../../services/constant.service";
import * as vCard from 'vcf';
import {isValidRef} from "@colmeia/core/src/tools/utility";


export function parseVcardFromString(raw: string): vCard[] {
    return vCard.parse(raw);
}


enum ContactPropertyKind {
    phone = 'p',
    email = 'e',
    name = 'n'
}

interface ContactProperty {
    kind: ContactPropertyKind;
    value: string;
}

@Component({
    selector: 'cm-vcard-presenter',
    templateUrl: './vcard-presenter.component.html',
    styleUrls: ['./vcard-presenter.component.scss']
})
export class VcardPresenterComponent implements OnInit {

    @Input() cardMultimedia: MultimediaInstance;

    showCard: boolean = false;

    private static propsDB: {kind: ContactPropertyKind; key: string;}[] = [
        {
            key: 'fn',
            kind: ContactPropertyKind.name,
        },
        {
            key: 'tel',
            kind: ContactPropertyKind.phone,
        },
        {
            key: 'email',
            kind: ContactPropertyKind.email,
        }
    ];


    allProperties: ContactProperty[] = [];

    allKindIcons: {[key in ContactPropertyKind]: string} = {
        [ContactPropertyKind.email]: "email",
        [ContactPropertyKind.phone]: "phone",
        [ContactPropertyKind.name]: "contacts"
    };

    constructor(
        private http: HttpClient,
        private constantSvc: ConstantService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.init();
    }

    private async init(): Promise<void> {
        const card = await this.downloadCardAsVCard();
        if(isValidRef(card)) {
            this.showCard = true;
            for(const propType of VcardPresenterComponent.propsDB) {
                const valueOf = this.getProp(card, propType.key);
                if(valueOf) {
                    this.allProperties.push({
                        kind: propType.kind,
                        value: valueOf
                    });
                }
            }
            this.cdr.markForCheck();
        }
    }

    async downloadCardAsVCard(): Promise<vCard> {
        try {
            const raw: string = await this.http.get(`${this.constantSvc.getFileUrl()}/${this.cardMultimedia.getIdMedia()}`, {
                responseType: "text"
            }).toPromise();
            return parseVcardFromString(raw)[0];
        } catch (err) {
            const card  = new vCard();
            card.set('fn', 'Contato');
            return card;
        }
    }

    private getProp(card: vCard, key: string): string | undefined {
        const prop = card.get(key);
        if(isValidRef(prop)) {
            if(Array.isArray(prop)) {
                const first = prop[0];
                return first.valueOf();
            } else {
                return prop.valueOf();
            }
        }
        return undefined;
    }

    get allPropertiesExceptName(): ContactProperty[] {
        return this.allProperties.filter(prop => prop.kind !== ContactPropertyKind.name);
    }

    get contactName(): string {
        const contact = this.allProperties.find(prop => prop.kind === ContactPropertyKind.name);
        return contact?.value || '';
    }
}
