import { Injectable } from '@angular/core';
import { constant} from '@colmeia/core/src/business/constant';
import { isFeedbackCarrier } from '@colmeia/core/src/rules/interaction-filter';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { socketConfig } from '@colmeia/core/src/core-constants/socket.conf';
import { isThisOneOfThat } from '@colmeia/core/src/tools/utility';
import { ISocketInteractionResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IInteractionJSON, IShareInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import {RecoverySystemService} from "./recovery-system.service";
import {PosProcessingInteractionService} from "./pos-processing-interaction.service";



/**
 * Helper service to received events through socketMessage stream and handle message by its type
 */
@Injectable({providedIn:'root'})
export class SocketFunctionsService {


	constructor(
        private recoveryService: RecoverySystemService,
        private posProcessing: PosProcessingInteractionService,
    ) {

    };

    public async handleSocketMessage(data: ISocketInteractionResponse): Promise<void> {
        performance.mark('handleSocketMessage-start');

        const interaction: IInteractionJSON = <IInteractionJSON>(<ISocketInteractionResponse>data).interaction;
        interaction.toSocketAvatarID = data.idToAvatar;

        // apenas para o pos processamento
         if (isThisOneOfThat(data.responseType, socketConfig.responseTypes.normalInteraction)) {

            let rehydrated: Interaction;

            if (interaction.idInteractionType == constant.interactionType.sharing.shareInteraction) {
                const share: IShareInteractionJSON = <IShareInteractionJSON>interaction;
                await this.recoveryService.routeBatchInteractions(share.idGroupDestiny, share.interactionArray.concat([share]));

            } else if (!InteractionType.staticFactory(interaction.idInteractionType).isTrackerInformation()
                        || isFeedbackCarrier(interaction)
            ) {
                await this.recoveryService.routeBatchInteractions(interaction.idGroup, [interaction]);
            };
            this.posProcessing.posProcessInteraction(interaction, rehydrated);
        }

        performance.mark('handleSocketMessage-end');
        performance.measure('myPerfMarker', 'handleSocketMessage-start', 'handleSocketMessage-end');
    };


}
