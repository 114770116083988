import { IFileSystemLayer } from '../hardware-interfaces';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserFilesystem implements IFileSystemLayer {
    newFile(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag): File {
        return new File(fileBits, fileName, options);
    }
}
