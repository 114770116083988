import { constant, TGlobalUID } from '../business/constant';
import { ObjectType } from '../business/object-type';
import { Serializable } from '../business/serializable';
import { CCustomErrorFieldErrors, ECustomErrorTGlobalID } from '../error-control/core-error.constants';
import { errorCodes } from '../error-control/error-definition';
import { Thread } from '../interaction/thread';
import { MultimediaObject } from '../multi-media/multi-media-object';
import { getUniqueStringID, hashToArray, isInvalid, isValidRef, throwCustomError, throwCustomFieldError, throwErrorIfTrue } from '../tools/utility';

export type TSerializableArray = Array<Serializable>;

const CC: TGlobalUID = constant.socialContext.colmeia;

export interface IOperationalContext {
    [alias: string]: IUberCache;
};

export interface IUberCache {
    [alias: string]: Serializable;
};


export function newGlobalUID(offSet: string = UberCache.getOffSet()): string {
    let id: string = getUniqueStringID(31);
    return id;
};


let serverSide: boolean = false;
let uploadingContext: TGlobalUID;
let loadCache: boolean = true;

export class UberCache {
    private static offSet: string = '1';
    private static serializableHash: IOperationalContext = { [CC]: {}};
    private static multiMediaTemplate: MultimediaObject;
    private static threadTemplate: Thread;
    private static nonSettled: TSerializableArray = [];
    
    public static getUberCache(socialContext: TGlobalUID = CC): IUberCache { 
        const cache =  UberCache.serializableHash[socialContext];
        return cache;
    };
    public static initCacheOperationalContext() { UberCache.serializableHash = {} };
    public static loadUberCache(load: boolean): void { loadCache = load; };

    public static resetSocialContext(socialContext: TGlobalUID): void {
        UberCache.serializableHash[socialContext] = {};
    };

    public static hardReset(): void {
        UberCache.serializableHash = { [CC]: {}};
    }

    public static dataReset(): void {
        for (const property in UberCache.serializableHash[CC]) {
            const idObjectType: TGlobalUID = UberCache.serializableHash[CC][property].getObjectTypeID();
            const serializable: Serializable = UberCache.serializableHash[CC][idObjectType];
            if (!(<ObjectType>serializable).isContextable()) {
                delete UberCache.serializableHash[CC][property];
            };
        };
    };

    public static setServerSide(): void { serverSide = true; };
    public static isServerSide(): boolean { return serverSide; };
    public static isClientSide(): boolean {return ! serverSide;}

    public static initLoadingSocialContext(socialContext: TGlobalUID): IUberCache {
        UberCache.hardReset();
        uploadingContext = socialContext;
        if (!UberCache.serializableHash[uploadingContext])
            UberCache.serializableHash[uploadingContext] = {};
        return UberCache.serializableHash[uploadingContext]
    };

    public static _setTemplate(mmObject: MultimediaObject, thread: Thread) {
        if (!mmObject) throw "O template do multimedia ou rosetta não pode ser null";
        UberCache.multiMediaTemplate = mmObject;
        UberCache.threadTemplate = thread;
    };

    public static bugFactoryMultiMedia(primaryID: TGlobalUID): MultimediaObject {
        return Object.assign(Object.create(UberCache.multiMediaTemplate), UberCache.multiMediaTemplate);
    };


    public static unsafeUberFactory(primaryID: TGlobalUID, throwError: boolean = true): Serializable {
        let serializable: Serializable;
        let msg: string;

        serializable = UberCache.serializableHash[CC][primaryID];

        if (serializable == null) {
            if (throwError)
               throwCustomFieldError(
                   ECustomErrorTGlobalID.errorID,
                   CCustomErrorFieldErrors.uberCache.notInCache, true,
                   'UberCache.unsafeUberFactory',
                   'não conseguiu instanciar o Objeto ', primaryID ? primaryID: 'NULO!');
        };
        return serializable;
    };

    public static uberFactory(primaryID: TGlobalUID, idObjectType: TGlobalUID, throwError: boolean = true): Serializable {
        let msg: string;
        let serializable: Serializable;

        if (isValidRef(primaryID)) {
            serializable = UberCache.serializableHash[CC][primaryID];
        }

        if (isInvalid(serializable)) {
            if (throwError)
                throwCustomFieldError(
                    ECustomErrorTGlobalID.errorID, 
                    CCustomErrorFieldErrors.uberCache.notInCache, true,
                    'UberCache.uberFactory',
                    'StaticFactory não conseguiu instanciar o Objeto -> ',
                    primaryID 
                        ? primaryID 
                        : 'NULO!',
                    ' idObjectType -> ',
                    idObjectType,
                );
        } else {
            throwErrorIfTrue(
                serializable.getObjectTypeID() != idObjectType, 
                errorCodes.system.objectTypeIsNotWhatYouExpect, true,
                'UberCache.uberFactory', 
                'You expected to rehydrate a object of', 
                idObjectType, 
                ' but got one of',
                serializable.getObjectTypeID(), 
                'primaryID ', primaryID);
        }
        return serializable;
    };

    public static uberFactoryContainer(idObjectContainer: TGlobalUID, pkTable: TGlobalUID, throwError: boolean = true): Serializable {
        for (let ser of <TSerializableArray>hashToArray(UberCache.serializableHash[CC]))
            if (ser.getSerializableObjectTypeID() == idObjectContainer && ser.getContainerPKTable() == pkTable)
                return ser;
        if (throwError)
            throwCustomError(ECustomErrorTGlobalID.errorID, true, 'uberFactoryContainer', 
                'StaticContainerFactory não conseguiu instanciar o Objeto ', pkTable);
    };

    public static addUberHash(serializable: Serializable) {
        
        if (loadCache || (<ObjectType> UberCache.uberFactory(serializable.getObjectTypeID(), constant.objectType.objectType, true)).isContextable()) {
            UberCache.serializableHash[uploadingContext][serializable.getPrimaryID()] = serializable;
        };

        /* if (! serializable.isProcessedByServer()) {
            UberCache.nonSettled.push(serializable);
        }*/
    };

    public static getSerializableArray(idObjectType: TGlobalUID): TSerializableArray {
        let arrayS: TSerializableArray = <TSerializableArray>hashToArray(UberCache.serializableHash[CC]);
        let arrayR: TSerializableArray = [];
        for (let serializable of arrayS)
            if (serializable.getSerializableObjectTypeID() == idObjectType)
                arrayR.push(serializable);
        return arrayR;
    };

    public static getAllSerializableArray(): TSerializableArray {
        return <TSerializableArray>hashToArray(UberCache.serializableHash[CC]);
    };

    public static getSerializables(): Serializable[] {
        return Object.values(UberCache.serializableHash[CC]).filter(item => item != null);
    };

    public static getContextSerializableArray(socialContext: TGlobalUID): TSerializableArray {
        return <TSerializableArray>hashToArray(UberCache.serializableHash[socialContext]);
    };

    public static testCache(primaryID: TGlobalUID): boolean {
        return isValidRef(UberCache.serializableHash[CC][primaryID]);
    };

    public static testCacheForObject(primaryID: TGlobalUID, idObjectType: TGlobalUID): boolean {
        return UberCache.testCache(primaryID) && UberCache.serializableHash[CC][primaryID].getObjectTypeID() === idObjectType;
    };


    public static removeFromCache(primaryID: TGlobalUID): void {
        delete UberCache.serializableHash[CC][primaryID];
    };


    public static setOffset(id: string): void {
        UberCache.offSet = id.substr(0,6);
    };

    public static getOffSet(): string {
        return UberCache.offSet;
    };

}