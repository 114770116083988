import {IComponentParameter} from "../model/component-comm/basic";
import {ChatBackboneModel} from "../model/chat-backbone.model";
import {MainHandler} from "./main-handler";
import {EChatViewStyle} from "../components/backbone/chat-backbone.component";
import {Participant} from "@colmeia/core/src/business/participant";
import {Group} from "@colmeia/core/src/business/group";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {GroupType} from "@colmeia/core/src/business/group-type";
import {Interaction, TOnlineFilterFunction} from "@colmeia/core/src/interaction/interaction";
import {TExecutableItemArray} from "../services/controllers-services/security-controller/executable-item";
import {MessagesContainerComponent} from "../components/chat/message-container/message-container.component";
import {MessageHandlerCallbak} from "./message-instance-handler/message-handler-callback";

export interface IMessageContainerNewInteraction {
    onNewInteractionArrivedCallback(isChildInteraction: boolean): void;
}

export interface IMessageContainerParameter extends IComponentParameter {
    chatBackbone: ChatBackboneModel;
}

export type TMessageContainerHandlerArray = Array<MessageContainerHandler>;

export class MessageContainerHandler extends MainHandler {


    constructor (parameter: IMessageContainerParameter) {
        super(parameter);
    };

    public getComponentParameter(): IMessageContainerParameter {
        return <IMessageContainerParameter>super.getComponentParameter();
    };

    public getChatBackbone(): ChatBackboneModel {return this.getComponentParameter().chatBackbone};
    public getActiveViewStyle(): EChatViewStyle { return this.getChatBackbone().getDisplayOptions().viewStyle }
    public isChatViewMode(): boolean { return this.getChatBackbone().isChatViewMode(); }
    public isStyleMode(styleMode: EChatViewStyle): boolean {return this.getChatBackbone().getDisplayOptions().viewStyle == styleMode};
    public getMsgHandlerCallback(): MessageHandlerCallbak { return this.getComponentParameter().chatBackbone.getFirstLevelMsgCallback()}
    public getParticipant(): Participant {return this.getComponentParameter().chatBackbone.getParticipant()};
    public getGroup(): Group {return this.getComponentParameter().chatBackbone.getGroup()}
    public getGroupID(): TGlobalUID {return this.getComponentParameter().chatBackbone.getCurrentGroupID()};
    public getGroupType(): GroupType {return this.getComponentParameter().chatBackbone.getGroupType()};
    // public getChatBarHandler(): ChatbarHandler {return this.getComponentParameter().chatBackbone.getCurrentChatBarHandler()};
    public getNewInteraction(): Interaction {return this.getComponentParameter().chatBackbone.getNewInteraction()};
    public getTimedMessages(): TExecutableItemArray {return this.getComponentParameter().chatBackbone.getTimedMessages()};
    public setMessageContainerEndPoint(component: MessagesContainerComponent): void {
        this.getMsgHandlerCallback().addEndpoint(component);
    };

    removeMessageContainerEndpoint(component: MessagesContainerComponent): void {
        this.getMsgHandlerCallback().removeEndpoint(component);
    }

    isForumViewMode(): boolean {
        return this.getChatBackbone().isForumViewMode();
    }

    public getCurrentSearchFilters(): TOnlineFilterFunction {
        return this.getChatBackbone().getCurrentInteractionFilter();
    }
};
