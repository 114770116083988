import { Injectable } from "@angular/core";
import { constant } from "@colmeia/core/src/business/constant";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { EEventName, IReactNativeLocation } from "@colmeia/core/src/client-shared/client-native.model";
import { ISecurityScreenGroup } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { IGetApplicationProcessedContractDashboardRequest, IGetApplicationProcessedContractDashboardResponse } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { IListNonSerializablesResponse } from "@colmeia/core/src/dashboard-control/dashboard-response-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { EIdMenus } from "@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { ENonSerializableObjectType, TNonSerializableArray } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { EIdMenuContractAccessType, IMenuContractConfig, IProcessedContractItem, ISocialNetworkConfigServer, TIdMenuContractAccess } from "@colmeia/core/src/shared-business-rules/social-network/social-network-config";
import { EAdHocSubMenus } from "@colmeia/core/src/shared-business-rules/social-network/social-network-config-ad-hoc-submenus";
import { ISaveSocialNetworkConfigurationRequest, ISaveSocialNetworkUserSettingsRequest, ISaveSocialNetworkUserSettingsResponse } from "@colmeia/core/src/shared-business-rules/social-network/social-network-config-req-resp";
import { ISocialNetworkSettings, ISocialNetworkSettingsServer } from "@colmeia/core/src/shared-business-rules/social-network/social-network-user-settings.model";
import { EScreenGroups, hasScreenGroup } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { nonNullable } from "@colmeia/core/src/tools/type-utils";
import { createOnePerTimeAsyncFn, deepGet, deepSet, defaults, fakeAssertsGuard, isValidRef, keys, values } from "@colmeia/core/src/tools/utility";
import { TDeepMap } from "@colmeia/core/src/tools/utility-types";
import { ClientInfraResponse } from "app/model/client-infra-comm";
import { mapCurrentDashboardClient } from "app/model/dashboard/db/dashboard-db-mapper";
import { IMapCurrentDashboardClient } from "app/model/dashboard/db/dashboard-db-types";
import { isDevEnvironment } from "app/model/is-dev-env";
import { NativeEventEmitterService } from "app/services/colmeia-mobile-app/native-event-emitter.service";
import { GenericDashboardRequester } from "app/services/generic-dashboard-requester.service";
import { RequestBuilderServices } from "app/services/request-builder.services";
import { RoutingService } from "app/services/routing.service";
import { SpinType } from "app/services/screen-spinner.service";
import { ServerCommunicationService } from "app/services/server-communication.service";
import { SessionService } from "app/services/session.service";
import { SignalPublisherService } from "app/services/signal/signal-publisher";
import { Observable, Subject } from "rxjs";
import { filter, take } from "rxjs/operators";

type TKeysMapIdMenuInfo = [idMenu: TIdMenuContractAccess, info: IProcessedContractItem];
type TKeysMapSNIdMenuInfo = [idSN: string, idAvatar: string];

@Injectable({
    providedIn: "root",
})
export class SNConfigService {
    public snConfig: ISocialNetworkConfigServer;
    public snSettings: ISocialNetworkSettings;
    private _snSettings$: Subject<ISocialNetworkSettings> = new Subject();
    get snSettings$(): Observable<ISocialNetworkSettings> {
        return this._snSettings$.asObservable()
    }
    private mapSNIdMenuInfo: TDeepMap<[...TKeysMapSNIdMenuInfo, ...[map: TDeepMap<TKeysMapIdMenuInfo>]]> =
        new Map([[constant.socialContext.colmeia, undefined]]);
    private mapIdMenuName: TDeepMap<[idMenu: TIdMenuContractAccess, name: string]>;

    public get shouldLoadAccess(): boolean {
        return (
            !this.mapIdMenuInfo
            && (this.idSN ? this.idSN !== constant.entity.rootGroups.root : (isDevEnvironment() && !!window.isRefresh))
        );
    }



    public set shouldLoadAccess(value: boolean) {
        if (value) {
            this.mapIdMenuInfo = undefined;
        }
    }

    public get idSN(): string | null {
        return this.getIdSN();
    }

    public getIdSN() {
        // if (!this.sessionSvc.hasSubscription() && window.isRefresh) {
        //     const refreshData = this.sessionSvc.getGroupFromStorage()
        //     if (refreshData?.idSocialNetwork) return refreshData.idSocialNetwork;
        // }
        return this.sessionSvc.getCurrentSocialNetworkID();
    }

    public get idAvatar(): string {
        return this.sessionSvc.getAvatarID()
    }
    private get mapIdMenuInfoKeys(): TKeysMapSNIdMenuInfo {
        return [this.idSN, this.idAvatar];
    }

    public get mapIdMenuInfo(): TDeepMap<TKeysMapIdMenuInfo> {
        return deepGet(this.mapSNIdMenuInfo)(...this.mapIdMenuInfoKeys)
    }
    public set mapIdMenuInfo(value: TDeepMap<TKeysMapIdMenuInfo>) {
        deepSet(this.mapSNIdMenuInfo)(...this.mapIdMenuInfoKeys, value);
    }

    constructor(
        private requester: GenericDashboardRequester,
        private rbs: RequestBuilderServices,
        private api: ServerCommunicationService,
        private sessionSvc: SessionService,
        private routeSvc: RoutingService,
        private signalPublisher: SignalPublisherService,
        private nativeEventEmitterSvc: NativeEventEmitterService
    ) { }

    public getSettings(): ISocialNetworkSettings {
        return this.snSettings;
    }

    public getAllConfigurableMenus(): TIdMenuContractAccess[] {
        return [...this.mapIdMenuInfo.values()]
            .filter(item => item.type === EIdMenuContractAccessType.EScreenGroups)
            .filter(item => !item.hasSpecialRule)
            .map(item => item.idMenu)
            .filter(isValidRef)
            .filter(hasScreenGroup)
            ;
    }

    public loadAccess = createOnePerTimeAsyncFn(() => this.loadAccessFn());

    public async loadAccessFn(): Promise<void> {
        /**
         * Espera o primeiro GroupSubscription válido, para ter o id do grupo de acesso
         * usado na requisição que busca o contrado.
         */
        await this.signalPublisher._getSubscriptionStream().pipe(filter(sub => !!sub), take(1)).toPromise();

        const response: IGetApplicationProcessedContractDashboardResponse = await this.fetchProcessedContract();

        if (!response) return;

        this.snSettings = response.snConfig;
        //MOCK
        // this.snSettings.geo = {
        //     maxDistance: 12,
        //     timeInterval: 5000
        // }
        // console.log("MOCKED GEO", {SN_SETTINGS: this.snSettings.geo})
        this._snSettings$.next(this.snSettings);

        if ((window as any).ReactNativeWebView && isValidRef(this.snSettings.geo)) {
            this.nativeEventEmitterSvc.emit<IReactNativeLocation>(EEventName.location, {
                locationConfig: this.snSettings.geo
            })
        }

        if (!isValidRef(response.contract)) return;

        for (const idMenu of keys(response.contract)) {
            this.setIdMenuInfo(idMenu, nonNullable(response.contract[idMenu]))
        }
    }

    public async fetchProcessedContract(): Promise<IGetApplicationProcessedContractDashboardResponse> {
        const response: IGetApplicationProcessedContractDashboardResponse = await this.api.quick<IGetApplicationProcessedContractDashboardRequest, IGetApplicationProcessedContractDashboardResponse>()({
            requestType: apiRequestType.menuSecurityRequest.getApplicationProcessedContract,
            multipleCursor: undefined,
        })
        return response;
    }

    public getProcessedInfo(idMenu: TIdMenuContractAccess): IProcessedContractItem | undefined {
        return this.mapIdMenuInfo?.get(idMenu)
    }

    public canAccess(idMenu: TIdMenuContractAccess): boolean | undefined {
        return this.mapIdMenuInfo?.get(idMenu)?.hasAccess;
    }

    public getIdMenuTranslation(idMenu: TIdMenuContractAccess): string | undefined {
        if (!isValidRef(this.mapIdMenuName)) {
            // map is empty, lets initialize it
            this.initIdMenuTranslations();
        }

        if (!this.mapIdMenuName.has(idMenu)) return idMenu;
        return this.mapIdMenuName?.get(idMenu);
    }

    public initIdMenuInfo() {
        this.mapIdMenuInfo ??= new Map();
    }

    public setIdMenuInfo(idMenu: TIdMenuContractAccess, info: Partial<IProcessedContractItem>): void {
        this.initIdMenuInfo();
        this.mapIdMenuInfo.set(idMenu, defaults(info, this.mapIdMenuInfo.get(idMenu)) as IProcessedContractItem)
    }

    public setIdMenuTranslation(idMenu: TIdMenuContractAccess, text: string): void {
        this.mapIdMenuName.set(idMenu, text)
    }

    public initIdMenuTranslations(): void {
        this.mapIdMenuName = new Map();

        for (const item of values(mapCurrentDashboardClient)) {
            try {
                this.setIdMenuTranslation(item.screenGroup.name, this.getDashboardMenuTranslation(item))
                const elements = Serializable.getAllSubMenus(item.screenGroup.name)

                for (const element of elements) {
                    fakeAssertsGuard<EIdMenus>(element.idMenu)
                    this.setIdMenuTranslation(element.idMenu, Serializable.staticFactory(element.primaryID).getName())
                    const subMenus: IMenuContractConfig[] = Serializable.getMenusConfigByParent(element.idMenu)
                    subMenus.forEach(subMenu => {
                        fakeAssertsGuard<EAdHocSubMenus>(subMenu.idMenu)
                        try {
                            const text: string = Serializable.getTranslation(nonNullable(gTranslations.socialNetwork[subMenu.idMenu]))
                            this.setIdMenuTranslation(subMenu.idMenu, text)
                        } catch { }
                    })
                }
            } catch { }
        }
    }

    public async initSNConfig(snConfig?: ISocialNetworkConfigServer) {
        this.snConfig = snConfig ?? await this.fetchConfig();
    }

    // public getIdMenuTranslations(): { [key in TIdMenuContractAccess]: string } {
    //     return Object.fromEntries([...this.mapIdMenuName.entries()].map(([property, name]) => [property, name])) as { [key in TIdMenuContractAccess]: string }
    // }

    public getDashboardMenuTranslation(menu: IMapCurrentDashboardClient) {
        const item = Serializable.staticFactory(menu.primaryID)
        const dashboardScreenGroup: ISecurityScreenGroup = item.screenGroup.find(screenGroup => {
            return screenGroup?.screenGroup.includes(EScreenGroups.MenuDashboard)
        });
        const isSubMenu = dashboardScreenGroup.screenGroup.split('/').length > 1;
        const text = item.getSerializableText(35);
        if (isSubMenu) return `${Serializable.staticFactory(dashboardScreenGroup.screenGroup).getSerializableText(35)} > ${text}`;
        return text;
    }

    public async fetchConfigs(): Promise<ISocialNetworkConfigServer[]> {
        const request = await this.requester.getRequestForNS({
            apiRequestType: apiRequestType.dashboardExtra.socialNetwork.listConfig,
            nsType: ENonSerializableObjectType.socialNetworkConfig,
            cursor: undefined,
        });
        const res: IListNonSerializablesResponse = await this.requester.request(
            request,
            false
        ) as IListNonSerializablesResponse;
        const list: ISocialNetworkConfigServer[] = res.nonSerializableArray as ISocialNetworkConfigServer[];
        return list;
    }

    public async fetchConfig(idSN?: string): Promise<ISocialNetworkConfigServer> {
        const request = await this.requester.getRequestForNS({
            apiRequestType: apiRequestType.dashboardExtra.socialNetwork.config,
            nsType: ENonSerializableObjectType.socialNetworkConfig,
            cursor: undefined,
        });
        const res: IListNonSerializablesResponse = await this.requester.request(
            request,
            false
        ) as IListNonSerializablesResponse;
        const list: TNonSerializableArray = res.nonSerializableArray;
        const config = list?.[0] as ISocialNetworkConfigServer;
        if (isValidRef(config)) this.initConfigAndLoadAccess(config);
        return config;
    }

    public async fetchUserSettings(): Promise<ISocialNetworkSettingsServer> {
        const request = await this.requester.getRequestForNS({
            apiRequestType: apiRequestType.nonSerializable.list,
            nsType: ENonSerializableObjectType.socialNetworkUserSettings,
            cursor: undefined,
        });
        const res: IListNonSerializablesResponse = await this.requester.request(
            request,
            false
        ) as IListNonSerializablesResponse;
        const list: TNonSerializableArray = res.nonSerializableArray;
        const config = list?.[0] as ISocialNetworkSettingsServer;
        return config;
    }

    public async initConfigAndLoadAccess(config?: ISocialNetworkConfigServer): Promise<void> {
        await this.initSNConfig(config);
        await this.loadAccess();
    }

    private editingConfig: ISocialNetworkConfigServer;
    private editRouteHandler = this.routeSvc.createRouteHandlerByPicker($ => $.dashboard.colmeia.contracts.edit.param);
    public goToEdit(idNS: string, config?: ISocialNetworkConfigServer) {
        this.editingConfig = config;
        return this.editRouteHandler.goTo({
            idNS,
        })
    }

    public async getEditingConfig(): Promise<ISocialNetworkConfigServer> {
        const { idNS } = this.editRouteHandler.getParams()
        const config = this.editingConfig ?? (await this.fetchConfigs()).find(ns => ns.idNS === idNS);
        this.editingConfig = undefined;
        return config;
    }

    public async save(contract: ISocialNetworkConfigServer): Promise<boolean> {
        const infra = this.rbs.getDefaultInfraParameters(null, null);
        infra.spinType = SpinType.fullScreen;
        const request: ISaveSocialNetworkConfigurationRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.dashboardExtra.socialNetwork.saveConfig),
            contract,
        };

        //
        const clientInfraResponse: ClientInfraResponse = (await this.api.managedRequest(infra, request));
        //


        const success: boolean = clientInfraResponse && clientInfraResponse.friendlyMessage && clientInfraResponse.friendlyMessage.isOk();

        if (success) this.initConfigAndLoadAccess();
        return success;
    }

    public async saveUserSettings(snUserSettings: ISocialNetworkSettingsServer): Promise<ISaveSocialNetworkUserSettingsResponse> {
        const infra = this.rbs.getDefaultInfraParameters(null, null);
        infra.spinType = SpinType.fullScreen;
        const request: ISaveSocialNetworkUserSettingsRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.dashboardExtra.socialNetwork.saveUserSettings),
            snUserSettings,
        };

        const clientInfraResponse: ClientInfraResponse = (await this.api.managedRequest(infra, request));

        return (clientInfraResponse?.response as ISaveSocialNetworkUserSettingsResponse);
    }
}
