import { GenericPlatform } from "../hardware-interfaces";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CordovaGeneric implements GenericPlatform {
    getMultimediaAllowedMIMETypes(): Array<string> {
        return ['image/*', 'video/*'];
    }
}
