import { IFeatureItemDetailsParameter, IFeatureItemDetailsClient } from "app/model/feature-list.model";
import { SmallSerializable, TSmallSerializableArray, IBasicRenderInfo } from "@colmeia/core/src/business/small-serializable";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IDeployedFeature } from "app/services/controllers-services/tool-bar-controller/tool-bar.service";

export class FeatureItemDetailsHandler {
    constructor(private parameters: IFeatureItemDetailsParameter) { }

    getFeatureName(): string {
        return this.parameters.feature.featureSet.featureItem.getName();
    }

    getFeature(): IDeployedFeature {
        return this.parameters.feature
    }

    getActions(): IBasicRenderInfo[] {
        return this.parameters.feature.actionFeatures.map(action => action.featureItem);
    }

    getClientInstance(): IFeatureItemDetailsClient {
        return this.parameters.clientInstance;
    }

    isOmniChannelDefault(): boolean {
        return this.parameters.omniChannelDefault;
    }
}
