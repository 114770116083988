import { ENextGenBotElementType, IBotMenuContainerServer } from '../../../bot/bot-model';
import { IFormTransactionServer } from '../../../knowledge-base/bot-transaction/bot-transaction';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { IHostNodeDeletionParams, IHostNodeDeletionResult } from '../ns/ns.host.types';
import { NestedAItHostNode } from './nested-ai-node.host';

export class NestedAIColmeiaFormsHost extends NestedAItHostNode {

    public static create(info: IFormTransactionServer): NestedAIColmeiaFormsHost {
        return new NestedAIColmeiaFormsHost(info);
    }

    public getHostedType(): TReferencedObject {
        return ENextGenBotElementType.formTransaction;
    }

    public isRoot(): boolean {
        return false;
    }

    public getNonSerializable(): IFormTransactionServer {
        return super.getNonSerializable() as IFormTransactionServer;
    }

    public getActionContainer() {
        return [{ action: undefined }];
    }
}