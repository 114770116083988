import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const groupChatRightSideBarTaskType = typedScreenOptions({
    idSerializable: EAllSerializable.chatSideBarConstants,
    fields: {
        taskId: 1, //sideBarLeft.constants 19
        taskPendency: 2, //sideBarLeft.constants 20
        taskDemand: 3, //sideBarLeft.constants 21
        taskOpened: 4, //sideBarLeft.constants 29
        specialFiltersId: 5, //sideBarLeft.constants 30
        specialFiltersInvert: 6, //sideBarLeft.constants 31
        specialFiltersService: 7, //sideBarLeft.constants 33
        channelsId: 8, //sideBarLeft.constants 32
    }
} as const);

export const chatViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Chat,
    fields: {
        chat: 1, // Chat
        viewMode: 2, // Modo de Visualização
        topic: 3, // Tópico
        forum: 4, // Forum
        filters: 5, // Filtros
        messages: 6, // Mensagens
        tasks: 7, // Tarefas
    }
} as const);

export const allChatTranslations = {
    ...getTranslationsFields(groupChatRightSideBarTaskType),
    ...getTranslationsFields(chatViewScreenOptions)
};