import * as googleLibPhoneNumber from 'google-libphonenumber';
import * as lib from 'libphonenumber-js';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { isInvalidString, isInvalidValidTrimmedString, isValidRef, isValidString, prefixPhoneWithPlus } from "../../tools/utility";
import { allCountriesPhonCodes } from './all-countries-phone-codes';


const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
export function isBRLandPhone(phone: string) {
    const parsed = phoneUtil.parseAndKeepRawInput(phone, 'BR');
    return phoneUtil.getNumberType(parsed) === googleLibPhoneNumber.PhoneNumberType.FIXED_LINE;
}


export type IPhoneRegion = {
    region: string,
    name: string,
    cellPhoneLength: number
}

type TPhoneRegionDb = { [phoneRegion: string]: IPhoneRegion }
type TCountryDB = { [idCounty: string]: TPhoneRegionDb };

export interface IPhoneNumber {
    ddi: string;
    ddd: string;
    localNumber: string;
}
export interface ICountryPhoneCode {
    name: string
    code: number
    iso: CountryCode
    areaCodeMask?: string
    phoneMask?: string
    isValidPhoneCountry?: (phone: string) => boolean;

}
export type TCountriesCodes = Array<ICountryPhoneCode>;

const dbRegion: TCountryDB = {
    ['55']: {
        ['11']: {
            region: '11',
            name: 'São Paulo',
            cellPhoneLength: 9
        },
        ['21']: {
            region: '21',
            name: 'Rio de Janeiro',
            cellPhoneLength: 9
        }
    }
}

export function isValidPhone(country: string, region: string, phone: string): boolean {
    const countryDB = dbRegion[country];
    if (countryDB) {
        const regDB = countryDB[region];
        if (regDB) {
            return isValidString(phone) && phone.trim().length === regDB.cellPhoneLength
        }
    }
    return false;

}

// @ https://pt.stackoverflow.com/questions/46672/como-fazer-uma-express%C3%A3o-regular-para-telefone-celular
// Modificações por Fernando, para aceitar prefixo internacional.
// 27/01/2021
export const mobileNumberPattern: RegExp = /^(?:(\+?)[0-9]{2,3})? ?\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
export function isMobileNumber(num: string): boolean {
    return mobileNumberPattern.test(num);
}

const phoneRegex = /\d/g;

export function getPhoneNumbersOnly(phone: string): string {
    const match: RegExpMatchArray | null = phone?.match(phoneRegex)
    return isValidRef(match)
        ? match.join('')
        : ''
}


export function getNormalizedHookCellphone(phone: | string | undefined): string | undefined {
    if (isInvalidString(phone)) {
        return;
    }

    return getNormalizedCellphone(phone!);
}

export function getNormalizedCellphone(phone: string): string {
    phone = getPhoneNumbersOnly(phone);

    if (phone.startsWith('55')) {
        if (!isLandPhone(phone) && (phone.length < 13)) {
            phone = phone.substr(0, 4) + '9' + phone.substr(4);
        }
    }

    return phone;
}

export function isLandPhone(phone: string): boolean {
    const phoneAfterDDD = phone.slice(4);

    const [firstDigitText] = phoneAfterDDD;
    const firstDigitNum = +firstDigitText;
    // 
    return ((firstDigitNum >= 2) && (firstDigitNum <= 5));
}

export function isValidWhatsappNumber(phone: string): boolean {
    try {
        if (isValidBRPhone(phone)) {
            return true;
        }
        const parsed = parseLibGooglePhoneNumber(phone);
        return phoneUtil.isValidNumber(parsed);
    } catch {
        return false;
    }
}

export function parseLibGooglePhoneNumber(phone: string, iso?: CountryCode): googleLibPhoneNumber.PhoneNumber {
    phone = prefixPhoneWithPlus(phone);
    const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
    const parsed = phoneUtil.parseAndKeepRawInput(phone, iso);
    return parsed;
}

export function parseLibPhoneNumber(phone: string): lib.PhoneNumber {
    const parsed = parsePhoneNumber(prefixPhoneWithPlus(phone));
    return parsed;
}

export function isValidBRPhone(phone: string): boolean {
    if (isInvalidValidTrimmedString(phone)) {
        return false;
    }

    if (!phone.startsWith('55')) {
        return false;
    }

    if (isLandPhone(phone)) {
        return true;
    }

    const cell = getNormalizedCellphone(phone);
    return cell.length === 13
}


export function getNoDDDPhone(phone: string): string | undefined {
    if (!isValidString(phone)) return '';

    const nationalNumber = getNationalNumber(phone);

    if (isValidBRPhone(phone) && nationalNumber) {
        return nationalNumber.localNumber;
    }

    const [possibleCountry] = getPossibleCountriesFromPhone(phone);

    const parsed = parsePhoneNumber(phone, possibleCountry.iso);

    return parsed?.nationalNumber;
}

export const unsafeBRPhoneRegex = /^((\+?[0-9]{2})?([0-9]{2}))?(?:9[1-9])[0-9]{3}\-?[0-9]{4}$/;
export function unsafeIsValidBRPhone(phone: string): boolean {
    return unsafeBRPhoneRegex.test(phone);
}

export const getPossibleCountriesFromPhone = (number: string): TCountriesCodes => {
    const possibleDDIs: TCountriesCodes = [];
    allCountriesPhonCodes.forEach((country) => {
        if (number.startsWith(country.code.toString())) {
            possibleDDIs.push(country);
        }
    });
    return possibleDDIs;
}

export function getNationalNumber(number: string): IPhoneNumber | undefined {
    const numberArray = number.split('');

    const phoneNumber: lib.PhoneNumber = parsePhoneNumber(number, 'BR');

    if (phoneNumber.isValid()) {
        const ddi = phoneNumber.countryCallingCode;
        const ddd = numberArray[2] + numberArray[3];
        const localNumber = number.slice(4);

        return { ddi, ddd, localNumber };
    }
}
export function isValidGeneralPhoneNumber(
    phone: string,
    iso?: CountryCode,
): boolean {
    try {
        const parsed = parseLibGooglePhoneNumber(phone, iso);
        return phoneUtil.isValidNumber(parsed);
    } catch {
        return false;
    }
}

export function isValidPhoneNumber(number: string, iso: CountryCode) {
    try { // Ao inves de usar o try catch para tratar o erro do parser "not a number", poderiamos usar a função isValidPhoneNumber da libphonenumber-js
        const parsedNumber = parsePhoneNumber(number, iso);
        return parsedNumber.isValid();
    } catch (e) {
        return false;
    }
}



export function getSinchNormalizedPhone(phone: string, otherPhone: string | undefined): string {
    const normalized = {
        phone: getNormalizedCellphone(phone),
        otherPhone: getNormalizedCellphone(otherPhone),
    }

    if (isValidBRPhone(normalized.phone)) {
        return normalized.phone;
    }

    if (isValidBRPhone(normalized.otherPhone)) {
        return normalized.otherPhone;
    }

    // olhar biblioteca que fernando trouxe

    const candidates = {
        phone: getPossibleCountriesFromPhone(normalized.phone),
        otherPhone: getPossibleCountriesFromPhone(normalized.otherPhone),
    };


    if (candidates.phone?.some((p) => { return p.isValidPhoneCountry?.(normalized.phone) })) {
        return normalized.phone;
    }

    if (candidates.otherPhone?.some((p) => { return p.isValidPhoneCountry?.(normalized.otherPhone) })) {
        return normalized.otherPhone;
    }

    if (isValidWhatsappNumber(normalized.phone)) {
        return normalized.phone;
    }

    if (isValidWhatsappNumber(normalized.otherPhone)) {
        return normalized.otherPhone;
    }

    // throw
}


export function isValidUSPhoneNumber(phone: string): boolean {
    const pattern = /^(?:(?:\+1\s*)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})$/;
    return pattern.test(phone);
}

//
