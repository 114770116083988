import { constant } from '../../business/constant';
import { TGlobalUID } from '../../core-constants/types';
import {
    apiRequestType,
    ApiRequestType, EKnowledgeBaseRequest, ESecurityControlRequestType, ETagService
} from '../../request-interfaces/message-types';
import { suggestions, valueIn } from '../../tools/type-utils';
import { getClock, mapBy, values } from '../../tools/utility';
import { CommonKeys, DefineReadableType, GetPropertyFromOr, ReverseEnum, ValueOf } from '../../tools/utility-types';
import { $NarrowExtends } from '../../tools/utility/types/validate';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { EDefineDashboard, EScreenGroups } from '../visual-constants';
import { EIdMenus } from './id-menus';


export enum ETagType {
    colmeia       = 'tagColmeia',
    socialNetwork = 'tagSN',
    variable      = 'tagVariable',
    tagGroup      = "tagGroup",
    marker        = 'tagMarker',
}

export interface IColmeiaTag extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.colmeiaTags,
    tagType: ETagType;
    value?: string;
};

export type TColmeiaTagArray = Array<IServerColmeiaTag>;

export interface IServerColmeiaTag extends IColmeiaTag, INonSerializable {
    nsType: ENonSerializableObjectType.colmeiaTags,
    idNS?: IdDep<ENonSerializableObjectType.colmeiaTags>;
};

export interface ITagAssignment {
    clockTick: number;
    idAvatar?: string;
    idTag: string;
}

export type TTagAssignmentArray = Array<ITagAssignment>;


export interface ITaggable {
    tags?: TTagAssignmentArray;
}

export interface ITaggableType { }

export interface IColmeiaCreatedTag {
    idNS: TGlobalUID;
    children?: {
        [child: string]: IColmeiaCreatedTag;
    }
}

export interface IColmeiaCreatedTagsHash {
    [tagName: string]: IColmeiaCreatedTag
}
export enum EDefaultTag {
    crmServices = 'NGnldYOOezDJX76LoiXvzfICQQKSvGmH',
    omniSense = '8w13woacs1MEUICev52o',
    marketing = 'A0nhDClLJzOVXkcOfIpB',
    jobs = 'BnFyrqwssrpKAo5cC04TuKwjLKhUVWU5',
    serviceAttendent = 'uF4rnU1BDPiHlpWwQQRY',
    services = 'q48TU48yOyv2jbm7QdKI',
    communication = 'v4rhUMHIvj93ESWL8jJC',
    environments = 'L0UfpmHeufd9fxFy54weZ8wFEkAfAJS2',
    conversationalCommerce = 'o2ckim5f5n6qau1hSQ654d3Dsm36lC7Q',
    socialNetwork = 'aOGOEz5vNuTUm0weLP2og42T8PypangX',
    colmeia = 'Mrc0ELm0ozeBLogUwQfxf0lZDT1DjVnt',
    smartFlow = "SKT2PL8TGmbq3agGgsiUllU0g4j2xzrz",
    security = "LTx1GwFwAvcg8kfZafd4ilNoL6yt0R",
    tools = "JKa238mVksT2E7N9vR2MkAUFwCMHq1bj",
}

export enum EBackOfficeTags {
    contratos = 'IOwOyrl0j3RO2whkJrh4ZLCDPnPczKeB'
}

export const ourTags = suggestions<{ colmeiaTag: { idNS: string; markers: { idNS: string } & { [key in keyof typeof EDefaultTag]: { idNS: typeof EDefaultTag[key] } } } }>()({
    colmeiaTag: { // Tag tipo Rede Social
        idNS: 'HUlHn71xygdeN5Y39v3h',
        markers: { // Tag tipo Grupo
            idNS: 'r2iV3YUYVOx1tNIsPXVU', // GRUPO
            colmeia: {
                idNS: EDefaultTag.colmeia,
            },
            omniSense: {
                idNS: EDefaultTag.omniSense,
            },
            conversationalCommerce: {
                idNS: EDefaultTag.conversationalCommerce,
            },
            marketing: {
                idNS: EDefaultTag.marketing
            },
            jobs: {
                idNS: EDefaultTag.jobs
            },
            serviceAttendent: {
                idNS: EDefaultTag.serviceAttendent
            },
            services: {
                idNS: EDefaultTag.services
            },
            communication: {
                idNS: EDefaultTag.communication
            },
            security: {
                idNS: EDefaultTag.security
            },
            environments: {
                idNS: EDefaultTag.environments
            },
            socialNetwork: {
                idNS: EDefaultTag.socialNetwork
            },
            crmServices: {
                idNS: EDefaultTag.crmServices
            },
            smartFlow: {
                idNS: EDefaultTag.smartFlow
            },
            tools: {
                idNS: EDefaultTag.tools
            }
        }
    }
} as const)



export function initDefaultColmeiaTag(name: string): IServerColmeiaTag {
    const now: number = getClock();
    const tag: IServerColmeiaTag = {
        isRoot: false,
        tags: [
            {
                idAvatar: constant.entity.odinAvatar,
                idTag: EDefaultTag.omniSense,
                clockTick: now
            }
        ],
        genealogy: [
            ourTags.colmeiaTag.markers.idNS,
            ourTags.colmeiaTag.idNS,
        ],
        tagType: ETagType.colmeia,
        idParent: ourTags.colmeiaTag.markers.idNS,
        ident: {
            idAvatar: constant.entity.odinAvatar,
            genealogy: [
                "qNylKHl4QhlMUIxHoh03kNy1vp2X9S"
            ],
            idGroup: "qNylKHl4QhlMUIxHoh03kNy1vp2X9S"
        },
        clockTick: now,
        lastTouch: now,
        nName: name,
        nsType: ENonSerializableObjectType.colmeiaTags
    }
    return tag;
}
export interface IMapCurrentDashboardSharedRequests {
    integration?: ApiRequestType;
    form?: ApiRequestType;
    saveForm?: ApiRequestType;
    transformer?: ApiRequestType;
    saveTransformer?: ApiRequestType;
}



export enum EScreenGroupsPrimaryIdItems {
    AttendanceIsland = 'AttendanceIsland',
    AttendanceFile = 'SNManagement/integration',
    AttendanceStatus = 'yourColmeia/status',
    AttendanceForm = 'DashboardGeneralForm',
    AttendanceCalendar = 'attendanceCalendar',
    AttendanceAgentStatus = 'AgentStatus',

    MarketingForm = 'DashboardGeneralForm',
    MarketingSendCampaign = 'SendCampaign',
    MarketingCampaigns = 'SNManagement/campaigns',

    OmnisenseForm = 'DashboardGeneralForm',

    CommunicationForm = 'DashboardGeneralForm',
    CommunicationChannels = 'SNManagement/socialMedias',
    CommunicationConnectionRoute = 'ConnectionRoute',

    OmnisenseChainedBase = 'ChainedBases',
    OmnisenseKnowledgeBase = 'SNManagement/knowledgeBase',
    OmnisenseTags = 'NonSerializableTags',
    OmnisenseCanonicals = 'Canonical',
    OmnisenseTransactions = 'BotTransaction',
    OmnisenseBPMGraph = 'BPMGraph',


    SocialNetworkAccess = 'SNManagement/groups',
    SocialNetworkUsers = 'SNManagement/users',
    SocialNetworkFacecompany = 'ShareFacecompany',
    SocialNetworkShareServices = 'ShareServices',
    SocialNetworkConfigurations = 'SocialNetworkConfig',

    ServicesServiceDeployed = 'SNManagement/deployedServices',

    CRMVisualizer = 'DashboardGeneralVisualizer',
}

export enum ELastMenuLevelControl {
    omniSenseExtraction = 'Omni/Knowle/extraction'
}


type TCommonScreenGroupItemsKeys = CommonKeys<typeof EIdMenus | typeof EScreenGroupsPrimaryIdItems>;

interface IScreenGroupItem {
    primaryID: EScreenGroupsPrimaryIdItems;
    idMenu: EIdMenus;
    requestTypes: { [key in string]?: ApiRequestType };
}
type TScreenGroupItemWithCommonKey<CommonKey extends TCommonScreenGroupItemsKeys> = DefineReadableType<IScreenGroupItem, {
    primaryID: typeof EScreenGroupsPrimaryIdItems[Extract<TCommonScreenGroupItemsKeys, CommonKey>];
    idMenu: typeof EIdMenus[Extract<TCommonScreenGroupItemsKeys, CommonKey>];
    requestTypes: { [key in string]?: ApiRequestType };
}>;

//

export interface IMapCurrentDashboardScreenGroup {
    name: EScreenGroups;
    items: { [key in typeof EScreenGroupsPrimaryIdItems[TCommonScreenGroupItemsKeys]]?: TScreenGroupItemWithCommonKey<ReverseEnum<typeof EScreenGroupsPrimaryIdItems>[key]> };
}

export enum EAllowedDashobardConfig {
    ColmeiaStaff = 'ColmeiaStaff',
}
export interface IMapCurrentDashboardBusiness {
    sharedRequests: IMapCurrentDashboardSharedRequests;
    defaultTag: EDefaultTag;
    screenGroup: IMapCurrentDashboardScreenGroup;
    primaryID: EDefineDashboard;
    allowRules?: EAllowedDashobardConfig[];
}


type _MapCurrentDashobardConfig = { [key in EDefineDashboard]: IMapCurrentDashboardBusiness & { primaryID: key } };



export const mapCurrentDashboard = defineMapCurrentDashboard({
    [EDefineDashboard.colmeia]: {
        sharedRequests: {},
        defaultTag: EDefaultTag.colmeia,
        primaryID: EDefineDashboard.colmeia,
        screenGroup: {
            name: EScreenGroups.Colmeia,
            items: {},
        },
        allowRules: [
            EAllowedDashobardConfig.ColmeiaStaff,
        ],
    },
    [EDefineDashboard.CRMServices]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.crm)({
            transformer: apiRequestType.dashboardExtra.crm.transformer,
            saveTransformer: apiRequestType.dashboardExtra.crm.saveTransformer,
        }),
        defaultTag: EDefaultTag.crmServices,
        primaryID: EDefineDashboard.CRMServices,
        screenGroup: {
            name: EScreenGroups.CRMServices,
            items: {},
        },
        allowRules: [],
    },
    [EDefineDashboard.marketing]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.marketing)({
            integration: apiRequestType.dashboardExtra.marketing.integration,
            form: apiRequestType.dashboardExtra.marketing.form,
            saveForm: apiRequestType.dashboardExtra.marketing.saveForm,
        }),
        defaultTag: EDefaultTag.marketing,
        primaryID: EDefineDashboard.marketing,
        screenGroup: {
            name: EScreenGroups.Marketing,
            items: {},
        },
    },
    [EDefineDashboard.jobs]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.jobs)({

        }),
        defaultTag: EDefaultTag.jobs,
        primaryID: EDefineDashboard.jobs,
        screenGroup: {
            name: EScreenGroups.Jobs,
            items: {},
        },
    },
    [EDefineDashboard.omnisense]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.ai)({
        }),

        defaultTag: EDefaultTag.omniSense,

        primaryID: EDefineDashboard.omnisense,
        screenGroup: {
            name: EScreenGroups.Ai,
            items: {
                [EScreenGroupsPrimaryIdItems.OmnisenseKnowledgeBase]: {
                    primaryID: EScreenGroupsPrimaryIdItems.OmnisenseKnowledgeBase,
                    idMenu: EIdMenus.OmnisenseKnowledgeBase,
                    requestTypes: {
                        editNLPConfig: EKnowledgeBaseRequest.editNLPConfig,
                        editKBAppConfig: EKnowledgeBaseRequest.editKBAppConfig,
                    },
                },

                [EScreenGroupsPrimaryIdItems.OmnisenseTags]: {
                    primaryID: EScreenGroupsPrimaryIdItems.OmnisenseTags,
                    idMenu: EIdMenus.OmnisenseTags,
                    requestTypes: {
                        save: ETagService.save,
                        list: ETagService.list,
                    },
                },
            },

        },
    },
    [EDefineDashboard.attendance]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.attendance)({
            form: apiRequestType.dashboardExtra.attendance.form,
            integration: apiRequestType.dashboardExtra.attendance.integration,
            saveForm: apiRequestType.dashboardExtra.attendance.saveForm,

        }),
        defaultTag: EDefaultTag.serviceAttendent,

        primaryID: EDefineDashboard.attendance,
        screenGroup: {
            name: EScreenGroups.YourColmeia,
            items: {},
        },
    },
    [EDefineDashboard.services]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.service)({
            integration: apiRequestType.dashboardExtra.service.integration,
        }),
        defaultTag: EDefaultTag.services,

        primaryID: EDefineDashboard.services,
        screenGroup: {
            name: EScreenGroups.Service,
            items: {},
        },
    },
    [EDefineDashboard.communication]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.communication)({
            form: apiRequestType.dashboardExtra.communication.form,
            saveForm: apiRequestType.dashboardExtra.communication.saveForm,
        }),
        defaultTag: EDefaultTag.communication,

        primaryID: EDefineDashboard.communication,
        screenGroup: {
            name: EScreenGroups.Communication,
            items: {},
        },
    },
    [EDefineDashboard.environments]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.env)({}),
        defaultTag: EDefaultTag.environments,

        primaryID: EDefineDashboard.environments,
        screenGroup: {
            name: EScreenGroups.Environments,
            items: {},
        },
    },
    [EDefineDashboard.socialNetwork]: {
        sharedRequests: ({
            form: apiRequestType.dashboardExtra.socialNetwork.form,
            saveForm: apiRequestType.dashboardExtra.socialNetwork.saveForm,
        }),
        defaultTag: EDefaultTag.socialNetwork,
        primaryID: EDefineDashboard.socialNetwork,
        screenGroup: {
            name: EScreenGroups.MenuSN,
            items: ({
                [EScreenGroupsPrimaryIdItems.SocialNetworkAccess]: {
                    primaryID: EScreenGroupsPrimaryIdItems.SocialNetworkAccess,
                    idMenu: EIdMenus.SocialNetworkAccess,
                    requestTypes: {
                        listAccess: ESecurityControlRequestType.list,
                        saveAccess: ESecurityControlRequestType.save,
                    },
                },
                [EScreenGroupsPrimaryIdItems.SocialNetworkUsers]: {
                    primaryID: EScreenGroupsPrimaryIdItems.SocialNetworkUsers,
                    idMenu: EIdMenus.SocialNetworkUsers,
                    requestTypes: {},
                },
                [EScreenGroupsPrimaryIdItems.SocialNetworkFacecompany]: {
                    primaryID: EScreenGroupsPrimaryIdItems.SocialNetworkFacecompany,
                    idMenu: EIdMenus.SocialNetworkFacecompany,
                    requestTypes: {},
                },
                [EScreenGroupsPrimaryIdItems.SocialNetworkShareServices]: {
                    primaryID: EScreenGroupsPrimaryIdItems.SocialNetworkShareServices,
                    idMenu: EIdMenus.SocialNetworkShareServices,
                    requestTypes: {},
                },
                [EScreenGroupsPrimaryIdItems.SocialNetworkConfigurations]: {
                    primaryID: EScreenGroupsPrimaryIdItems.SocialNetworkConfigurations,
                    idMenu: EIdMenus.SocialNetworkConfigurations,
                    requestTypes: {},
                },

            } as const)
        },
    },
    [EDefineDashboard.analytics]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.env)({}),
        defaultTag: EDefaultTag.environments,

        primaryID: EDefineDashboard.analytics,
        screenGroup: {
            name: EScreenGroups.Analytics,
            items: {},
        },
    },
    [EDefineDashboard.analyticsHistory]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.env)({}),
        defaultTag: EDefaultTag.environments,

        primaryID: EDefineDashboard.analyticsHistory,
        screenGroup: {
            name: EScreenGroups.AnalyticsHistory,
            items: {},
        },
    },
    [EDefineDashboard.conversationalCommerce]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.conversationalCommerce)({
            integration: apiRequestType.dashboardExtra.conversationalCommerce.integration,
            form: apiRequestType.dashboardExtra.conversationalCommerce.form,
            saveForm: apiRequestType.dashboardExtra.conversationalCommerce.saveForm,
        }),
        defaultTag: EDefaultTag.conversationalCommerce,
        primaryID: EDefineDashboard.conversationalCommerce,
        screenGroup: {
            name: EScreenGroups.ConversationalCommerce,
            items: {},
        },
    },
    [EDefineDashboard.security]: {
        sharedRequests: ({

        }),
        defaultTag: EDefaultTag.security,
        primaryID: EDefineDashboard.security,
        screenGroup: {
            name: EScreenGroups.Security,
            items: {},
        },
    },
    [EDefineDashboard.smartFlow]: {
        sharedRequests: valueIn(apiRequestType.dashboardExtra.smartFlow)({
            integration: apiRequestType.dashboardExtra.smartFlow.integration,
            form: apiRequestType.dashboardExtra.smartFlow.form,
            saveForm: apiRequestType.dashboardExtra.smartFlow.saveForm,
            transformer: apiRequestType.dashboardExtra.smartFlow.transformer,
            saveTransformer: apiRequestType.dashboardExtra.smartFlow.saveTransformer,
        }),
        defaultTag: EDefaultTag.smartFlow,

        primaryID: EDefineDashboard.smartFlow,
        screenGroup: {
            name: EScreenGroups.SmartFlow,
            items: {},
        },
    },
    [EDefineDashboard.tools]: {
        sharedRequests: ({

        }),
        defaultTag: EDefaultTag.tools,
        primaryID: EDefineDashboard.tools,
        screenGroup: {
            name: EScreenGroups.Tools,
            items: {},
        },
    }
});

function defineMapCurrentDashboard<Source extends $NarrowExtends<Source, _MapCurrentDashobardConfig>>(source: Source) {
    return source;
}

// type TScreenGroups = GetPropertyFromOr<ValueOf<typeof mapCurrentDashboard>, 'screenGroup'>;

const mapMapCurrentDashboardByScreenGroup: Map<EScreenGroups, IMapCurrentDashboardBusiness> = mapBy(values(mapCurrentDashboard), item => item.screenGroup.name)
export function findMapCurrentDashboardByScreenGroup(screenGroup: EScreenGroups) {
    return mapMapCurrentDashboardByScreenGroup.get(screenGroup);
}

export type MapCurrentDashboardRequests = ValueOf<typeof mapCurrentDashboard>['sharedRequests'];

export type IntegrationRequestType = GetPropertyFromOr<MapCurrentDashboardRequests, keyof Pick<IMapCurrentDashboardSharedRequests, 'integration'>>;
export type FormRequestType = GetPropertyFromOr<MapCurrentDashboardRequests, keyof Pick<IMapCurrentDashboardSharedRequests, 'form'>>;
export type SaveFormRequestType = GetPropertyFromOr<MapCurrentDashboardRequests, keyof Pick<IMapCurrentDashboardSharedRequests, 'saveForm'>>;
export type TransformerRequestType = GetPropertyFromOr<MapCurrentDashboardRequests, keyof Pick<IMapCurrentDashboardSharedRequests, 'transformer'>>;
export type SaveTransformerRequestType = GetPropertyFromOr<MapCurrentDashboardRequests, keyof Pick<IMapCurrentDashboardSharedRequests, 'saveTransformer'>>;
