import { Directive, Input, OnInit } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { NonSerializableFactory } from "@colmeia/core/src/nser/non-serializable.factory";
import { TBpmMarketingNaturalNsTypes, TBpmMarketingNserServer, TBpmMarketingNsTypes } from "@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model";
import { INonSerializable, TINonSerializableArray } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isValidArray, isValidRef, objectShallowReplace } from "@colmeia/core/src/tools/utility";
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from "app/handlers/generic-dashboard-edit.handler";
import { ENsMoreOptions } from "app/handlers/ns-more-options.handler";
import { BpmGraphCreateHandler, BPMGraphCreateWithParentLinkHandler } from "app/model/bpm-graph-handler.model";
import { IBPMSubjectRulesProcessorMethods } from "app/services/bpm/bpm-subject-rules/bpm-rules.types";
import { BpmService } from "app/services/bpm/bpm.service";
import { MktBpmDataService } from "./mkt-bpm-data.service";

@Directive({
    selector: 'app-marketing-bpm-create-base',
    host: {
        '[class.bpm-mkt-node-dialog]': 'dialogClass'
    }
})
export abstract class MarketingBpmBase<
    Handler extends BpmGraphCreateHandler | BPMGraphCreateWithParentLinkHandler = BpmGraphCreateHandler,
    NS extends TBpmMarketingNserServer = TBpmMarketingNserServer
    > implements OnInit {

    readonly dialogClass: boolean = true;
    abstract nsType: TBpmMarketingNaturalNsTypes;
    ns: NS;

    @Input()
    handler: Handler;

    public genericEdit: GenericDashboardEditHandler;
    protected bpmSvc: IBPMSubjectRulesProcessorMethods;
    public isEdit: boolean;

    constructor(
        bpmSvc: BpmService,
        protected mktBpmDataSvc: MktBpmDataService
    ) {
        this.bpmSvc = bpmSvc.getCurrentStrategy();
    }

    ngOnInit() {
        const ns = this.getNS();

        this.isEdit = isValidRef(ns);

        this.ns = (ns || this.nsFactory()) as NS ;
        this.initGenericEditHandler();
    }

    private initGenericEditHandler() {
        this.genericEdit = new GenericDashboardEditHandler({
            nsType: this.nsType,
            autoValidate: true,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: () => this.save(),
                onGenericBackButtonPressed: () => {
                    this.handler.getDialogRef().close();
                }
            },
            nser: this.ns,
            allowEditTitleInHeader: true,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
            }
        })
    }

    nsFactory(overrides: Partial<NS> = {}): INonSerializable {
        const idParentFromHandler: TGlobalUID = (this.handler as BPMGraphCreateWithParentLinkHandler).getIdParent?.();
        const mktRootId: TGlobalUID = this.bpmSvc.getGraphRulesProcessor().getRootElement()?.getHostedID();

        return {
            ...NonSerializableFactory.createMktBPM(this.nsType, ''),
            ...overrides,
            idParent: idParentFromHandler || mktRootId
        }
    }

    abstract getNS(): NS;
    async save(): Promise<boolean> {
        const savedElements: TINonSerializableArray = await this.mktBpmDataSvc.save(this.nsType, this.ns);
        const saved: boolean = isValidArray(savedElements);
        
        if(saved) {
            const [self] = savedElements;
            objectShallowReplace(this.ns, self as NS);
        }

        this.handler.closeDialogAndSendResultToParentComponent({
            userHasClickedSave: saved,
            nonSerializable: this.ns
        });

        return saved;
    }
}