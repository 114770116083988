import {TArrayID, TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {TSecurityItemArray} from "@colmeia/core/src/security/security-settings";
import {Serializable} from "@colmeia/core/src/business/serializable";
import {domain} from "@colmeia/core/src/core-constants/security-constant";
import {constant} from "@colmeia/core/src/business/constant";
import {Avatar} from "@colmeia/core/src/business/avatar";
import {Group} from "@colmeia/core/src/business/group";
import {TTranslationConfig} from "./translation";
import {GroupEditorCreatorService} from "../services/group-editor-creator.service";
import {createGroupScreenGroups} from "@colmeia/core/src/shared-business-rules/visual-constants";

export type TEditingGroupTabs = Array<IEditingGroupTab>;

export interface IEditingGroupTab {
    title: TTranslationConfig;
    outlet: any;
}

export interface IEditingGroupSettingsTab {
    title: TTranslationConfig;
    outlet: any;
}

export enum ESectionTopicControlMode {
    MultipleOption,
    SingleOptionOptional,
    SingleOptionRequired
}

export interface IEditingGroupSectionTopicHeader {
    title: TTranslationConfig;
    primaryID: TGlobalUID;
    control: ESectionTopicControlMode;
}

export type TEditingGroupSectionTopicHeaders = Array<IEditingGroupSectionTopicHeader>;

export interface IEditingGroupSectionTopic extends IEditingGroupSectionTopicHeader {
    settings: TSecurityItemArray;
    titleStr: string;
}

export type TEditingGroupSectionTopics = Array<IEditingGroupSectionTopic>;


/* ignorar por enquanto!: */

interface ScreenSectionsSecuritySettings {
    groupScreenName: Serializable;
    securitySettings: TSecurityItemArray;
}

interface IHashSections {
    [idScren: string]: ScreenSectionsSecuritySettings;
}

interface HiddenParameters {
    [idSecurity: string]: boolean;
}

interface groupTypeEliminated {
    [idGroupType: string]: HiddenParameters;
}

export enum ESelectionType {
    groupSelector,
}


// extract from the constants file all labels for the screen sections - used for translation purposes
const screenGroups = createGroupScreenGroups;

// invalid security options for ALL groups
// options with TRUE will not be available in the Advanced Settings section
const notImplementedOptions: HiddenParameters = {
    [domain.visibility.contentAvailableOnGoogle]: true,
    [domain.validateBy.onlyPurchasedValidatedPlayer]: true,
    [domain.sharing.allowGroupFusion]: true,
    [domain.sharing.allowGroupFusionAfterApproval]: true
};

// hide every option except for bring participants from Parent
const allSectionsEliminated: HiddenParameters = {
    ...notImplementedOptions,
    [screenGroups.advSettingsValidatedByRulesLabel]: true,
    [screenGroups.advSettingsAccessGroupRulesLabel]: true,
    [screenGroups.advSettingsPermissionRulesLabel]: true,
    [screenGroups.advSettingsAllowDistributionListRulesLabel]: true,
    [screenGroups.advSettingsContentPrivacyLabel]: true,
    [screenGroups.advSettingsChatConfirmReceivedPrivacyLabel]: true,
    [screenGroups.advSettingsAccessRulesLabel]: true,
    [screenGroups.advSettingsPrivacyLabel]: true,
    [screenGroups.advSettingsChatPrivacyLabel]: true,
};

const corporateEliminated: HiddenParameters = {
    ...notImplementedOptions,
    [screenGroups.advSettingsParticipationRulesLabel]: true, //
    [screenGroups.advSettingsAllowDistributionListRulesLabel]: true,

    [screenGroups.advSettingsChatPrivacyLabel]: true,
    [screenGroups.advSettingsChatConfirmReceivedPrivacyLabel]: true,

    // sharing
    [screenGroups.advSettingsSharing]: true,
    [screenGroups.advSettingSharingToOutside]: true,
    [screenGroups.advSettingSharingFromOutside]: true,
};

const inspireEliminated: HiddenParameters = {
    ...corporateEliminated
};

const sponsoredEliminated: HiddenParameters = {
    ...corporateEliminated
};

const personalGroupEliminated: HiddenParameters = {
    ...allSectionsEliminated
};

const privateMessageEliminated: HiddenParameters = {
    ...allSectionsEliminated,
    [screenGroups.advSettingsParticipationRulesLabel]: true,
};

const channelEliminated: HiddenParameters = {
    ...allSectionsEliminated,
    [screenGroups.advSettingsParticipationRulesLabel]: true,
}

const standardGroupEliminated: HiddenParameters = {
    ...notImplementedOptions
};
const administratorEliminated: HiddenParameters = {
    ...allSectionsEliminated
};

// map the eliminated options to the groupType id
const relationEliminated: groupTypeEliminated = {
    [constant.groupType.functional.broadcast]: standardGroupEliminated,
    [constant.groupType.functional.distributionList]: allSectionsEliminated,
    [constant.groupType.functional.spokenGroup]: allSectionsEliminated,
    [constant.groupType.functional.featureCreator]: allSectionsEliminated,
    [constant.groupType.functionalRoot.corporate]: corporateEliminated,
    [constant.groupType.functionalRoot.tribe]: inspireEliminated,
    [constant.groupType.functionalRoot.sponsored]: sponsoredEliminated,
    [constant.groupType.functionalRoot.playerPersonalGroup]: personalGroupEliminated,
    [constant.groupType.personalGroupTypes
        .privateMessage]: privateMessageEliminated,
    [constant.groupType.standard.channel]: channelEliminated,
    [constant.groupType.standard.standardGroup]: standardGroupEliminated,
    [constant.groupType.support.administrator]: administratorEliminated,
};

export function groupEditHiddenSections(idGroupType: TGlobalUID): HiddenParameters {
    return relationEliminated[idGroupType];
}

const onSettingOnSectionsDisabled: { [key: string]: TArrayID} = {
    [domain.visibility.secretGroup]: [
        'createGroup/accessGroupRules'
    ]
};

export interface ISecuritySectionReflection {
    disabled: TArrayID;
    enabled: TArrayID;
}

export function groupReflectSecuritySetting(setting: TGlobalUID, value: boolean): ISecuritySectionReflection {
    const arr = onSettingOnSectionsDisabled[setting] || [];
    return {
        disabled: value ? arr : [],
        enabled: value ? [] : arr
    }
}

export interface IGroupSavedCallbackClient {
    onGroupSaved: () => void;
}

export interface ICreateGroupParameters {
    groupTypeHexagon: Serializable
    avatar: Avatar;
    parentGroup: Group;
    createGroupType: TGlobalUID;
    service: GroupEditorCreatorService;
    componentInstance: IGroupSavedCallbackClient;
}

export const disabledOnVinculatedSecurity: TArrayID = [
    screenGroups.advSettingsContentPrivacyLabel,
    screenGroups.advSettingsAccessGroupRulesLabel
];
