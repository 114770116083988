import { ComponentType } from "@angular/cdk/portal";
import { Location } from "@angular/common";
import { NgZone, StaticProvider } from '@angular/core';
import { Serializable } from "@colmeia/core/src/business/serializable";
import { nonSerializableFriendlyNameTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly";
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { assign, isValidFunction, isValidRef, isValidString, typedClone, values } from '@colmeia/core/src/tools/utility';
import { GenericDashboardEditHandler, IWindowTranslation } from 'app/handlers/generic-dashboard-edit.handler';
import { CopyToClipboardService } from 'app/services/copy-to-clipboard.service';
import { DateService } from 'app/services/date.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { LookupService } from 'app/services/lookup.service';
import { RoutingService } from 'app/services/routing.service';
import hotkeys from 'hotkeys-js';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { filter, first, take, takeUntil } from 'rxjs/operators';
import { IGenericHomeHandlerParameter } from "../generic-dashboard-home/generic-dashboard-home.model";
import { GenericHomeHandler } from "../generic-dashboard-home/generic-home.handler";
import { ColmeiaWindowRef, WindowDialogState } from "./colmeia-window-ref";
import { EWindowStateChangeType, IWindowRuntime, IWindowRuntimeInitDescriptor, IWindowRuntimeSetupConfig, IWindowRuntimeSubjets, TColmeiWindowRintimeCompareFunction } from "./colmeia-window.model";
import { ColmeiaWindowService } from './colmeia-window.service';
import { MainHandler } from 'app/handlers/main-handler';

type TRuntimeSubjects = keyof IWindowRuntime['subjects'];
export interface IColmeiaWindowRuntimeServicesInjection<O = any, D = any> {
    windowService: ColmeiaWindowService;
    routing: RoutingService;
    location: Location;
    warningSvc: GlobalWarningService;
    zone: NgZone;
    copyToClipboardSvc: CopyToClipboardService;
    lookupSvc: LookupService;
    dateSvc: DateService;
}


export class ColmeiaWindowRuntime<NS extends INonSerializable = INonSerializable, D = any> implements IWindowRuntime<NS, D>, IColmeiaWindowRuntimeServicesInjection {
    private handlerParameters?: IGenericHomeHandlerParameter;
    private destroyed$: Subject<boolean> = new Subject()
    private compareEqualFn: TColmeiWindowRintimeCompareFunction<NS>;

    private _isEdit: boolean;
    public get isEdit(): boolean { return !!this._isEdit; };

    public activeEg(): void { hotkeys("ctrl+shift+alt", this.egH); }
    private deactiveEg(): void { hotkeys.unbind("ctrl+shift+alt") }
    private egH = () => console.log("Parabéns, achou um egg, agora vai trabalhar!");

    // public readonly windowConfig!: IWindowConfig;

    private windowIndexId: number;

    sourceObject: NS;
    multableObject: NS;
    editHandler: GenericDashboardEditHandler;
    shouldReloadHomeList: boolean = false;
    subjects: IWindowRuntimeSubjets = {
        buttonSaveClick: new Subject<void>(),
        close: new Subject<void>(),
        afterClose: new Subject<void>(),
        onSavedNS: new Subject<boolean>(),
        syncSourceObjects: new Subject<void>(),
        minimizeWindowClick: new Subject<void>(),
        startWindowMovement: new Subject<void>(),
        windowMovement: new Subject<IWindowTranslation>(),
        endWindowMovement: new Subject<void>(),
        component: new Subject<ComponentType<unknown>>(),
    };

    private _saveButtonClick: () => Promise<boolean>;

    public dialogRef: ColmeiaWindowRef<unknown>;
    public initialPath: string;

    private _triggerUrl: string;
    get triggerUrl(): string { return this._triggerUrl };

    private nsTypeName: string;
    private isFirstSave: boolean;

    windowService: ColmeiaWindowService;
    routing: RoutingService;
    location: Location;
    warningSvc: GlobalWarningService;
    zone: NgZone;
    copyToClipboardSvc: CopyToClipboardService;
    lookupSvc: LookupService;
    dateSvc: DateService;

    constructor(
        services: IColmeiaWindowRuntimeServicesInjection<NS, D>,
        readonly windowConfig: IWindowRuntimeSetupConfig<NS, D>,
        readonly initDescriptor: IWindowRuntimeInitDescriptor,
        readonly handler?: GenericHomeHandler,
        nser?: INonSerializable,
        readonly genericHandler?: MainHandler,
        public additionalProviders: StaticProvider[] = [],
    ) {
        assign(this, services);

        if (initDescriptor.autoOpen) this.activeEg();
        this.handlerParameters = this.handler?.getComponentParameter();

        this.initialPath = this.windowConfig.initialPath || this.location.path();
        this._isEdit = isValidRef(nser);
        this.sourceObject = (isValidRef(nser) ? nser : (this.windowConfig.initializeNSObject?.() || {})) as NS;
        this.multableObject = typedClone(this.sourceObject);

        this.defineIfIsEdit();

        this.isFirstSave = this._isEdit;

        const { equalCompare } = this.windowConfig;
        this.setCompareFunction(equalCompare);

        this.setupDefaultListeners();

        if (isValidRef(this.windowConfig.editHandlerParameters)) {
            this.initGenericDashboardEditHandler();
        }

        this._triggerUrl = this._isEdit ? this.getEditRoute(this.sourceObject) : this.getCreateRoute();
    }

    public setCompareFunction(fn: TColmeiWindowRintimeCompareFunction<NS>) {
        this.compareEqualFn = isValidFunction(fn)
            ? fn
            : isEqual;
    }

    private defineIfIsEdit(): void {
        this._isEdit = isValidString(this.sourceObject.idNS, 30);
    }

    private setupDefaultListeners() {

        this.subscribeToEditHandler('close').subscribe(() => {
            this.disposeEditor();
        });

        this.subscribeToEditHandler('onSavedNS').subscribe((saved) => {
            if (this.isFirstSave && saved) {
                this.subjects.syncSourceObjects.pipe(take(1)).subscribe(() => {
                    this.afterCreated();
                });
            }
        });

        this.windowService.shift$().pipe(takeUntil(this.destroyed$)).subscribe((newIndexId) => {
            if (newIndexId !== this.windowIndexId) return;

            this.isEdit && this.changeUrl(this.getEditRoute(this.sourceObject));
        });
    }

    public afterCreated() {
        this.changeUrl(this.getEditRoute(this.sourceObject));
        this.dialogRef.title = this.sourceObject.nName;
        this.dialogRef.group = this.nsTypeName;
    }

    public reloadList() {
        this.handler?.refresh();
    }

    public destructor() {
        for (const subjet of values(this.subjects)) {
            subjet.complete();
        }

        this.cleanAllSubscriptions();

        this.subjects = undefined;
        this.deactiveEg();
    }

    private cleanAllSubscriptions(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public subscribeToEditHandler<T extends TRuntimeSubjects, R = ReturnType<IWindowRuntime['subjects'][T]["asObservable"]>>(key: T): R {
        return (this.subjects[key] as Subject<any>).pipe(takeUntil(this.destroyed$)) as unknown as R;
    }

    syncSourceObject() {
        const stillEqual = !this.hasChange();
        if (stillEqual) return;

        this.sourceObject = typedClone(this.multableObject);
        this.defineIfIsEdit();
        this.subjects.syncSourceObjects.next();
    }

    hasChange(): boolean {
        const { sourceObject, multableObject } = this;
        const isEqual = this.compareEqualFn(sourceObject, multableObject);
        return !isEqual;
    }

    setDialogRef(dialogRef: ColmeiaWindowRef<unknown>) {
        this.dialogRef = dialogRef;
        this.windowIndexId = dialogRef.windowIndex;

        dialogRef.title = this.sourceObject.nName || `Novo: ${this.nsTypeName}`;
        dialogRef.group = this.nsTypeName;

        this.setupDialogListeners();
    }

    private setupDialogListeners() {
        this.dialogRef.afterOpened().pipe(take(1)).subscribe(() => {
            this.onWindowFocus();

            // Reset autoOpen to restore event handle correctly window focus
            this.initDescriptor.autoOpen = false;
            this.initDescriptor.openEditByNest = false;
        });

        this.windowService.windowStateChange().pipe(
            takeUntil(this.destroyed$),
            filter(command =>
                command.windowIdx === this.dialogRef.windowIndex &&
                this.dialogRef.getState() === WindowDialogState.OPEN
            )
        ).subscribe((command) => {
            if (
                command.type === EWindowStateChangeType.Restore ||
                (
                    command.type === EWindowStateChangeType.Closed &&
                    this.windowService.currentActiveIndex === this.dialogRef.windowIndex
                )
            ) {
                this.onWindowFocus();
            }
        })

        this.subscribeToEditHandler('afterClose').subscribe(() => {
            this.dialogRef.close();
        });

        this.dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.destructor();
        });

        this.subscribeToEditHandler('minimizeWindowClick').subscribe(() => {
            this.dialogRef.minimize();
        });
    }

    private onWindowFocus() {
        if (this.initDescriptor.autoOpen) return;

        if (this.isEdit && !this.initDescriptor.openEditByNest) {
            this.changeUrl(this.getEditRoute(this.sourceObject));
            return;
        }

        if (this.initDescriptor.openCreateDialog) {
            this.changeUrl(this.getCreateRoute());
        }
    }

    private async disposeEditor(): Promise<void> {
        if (this.hasChange() && !(await this.warningSvc.confirmLeaveWithoutSave())) {
            return;
        }

        this.afterClose();
    }

    private afterClose() {
        if (this.shouldReloadHomeList) {
            this.handler?.refresh();
        }

        this.subjects.afterClose.next();
    }

    private changeUrl(url: string) {
        this.windowService.setCurrentUrl(url, false, this.windowConfig.keepQueryParams);
    }

    private getEditRoute(nser: NS) {
        if (isValidFunction(this.windowConfig.mountEditPath)) {
            return this.windowConfig.mountEditPath(nser)
        }

        const [param] = this.routing.getRouteParams(this.windowConfig.editPath);
        return this.windowConfig.editPath.replace(param, nser.idNS);
    }

    private getCreateRoute() {
        return isValidFunction(this.windowConfig.mountCreatePath)
            ? this.windowConfig.mountCreatePath()
            : this.windowConfig.createPath;
    }

    setSaveButtonClick(handler: () => Promise<boolean>) {
        this._saveButtonClick = handler;
    }

    private initGenericDashboardEditHandler() {
        let { nsType } = this.handlerParameters || {};
        const { editHandlerParameters, blockSaveButtonWithoutChanges } = this.windowConfig;
        const { clientCallback = {} } = editHandlerParameters;
        const {
            onGenericBackButtonPressed,
            onGenericDeleteClicked,
            onGenericCopyClicked,
            onGenericSaveButtonPressed,
            onGenericSaveTags
        } = clientCallback;

        nsType = nsType || editHandlerParameters.nsType;

        if (!nsType) {
            throw new Error("NsType not provided");
        }

        const { serializableId, idField } = nonSerializableFriendlyNameTranslations[nsType];
        this.nsTypeName = Serializable.staticFactory(serializableId).getSerializableText(idField);

        this.editHandler = new GenericDashboardEditHandler({
            ...editHandlerParameters,
            hasChange: () => {
                return this.hasChange()
            },
            nser: this.multableObject,
            nsType: nsType,
            backButtonIcon: 'close',
            isWindowMode: true,
            windowButtons: [
                {
                    icon: "minimize",
                    onClick: () => {
                        this.subjects.minimizeWindowClick.next();
                    }
                }
            ],
            clientCallback: {
                onGenericSaveButtonPressed: async () => {
                    const isValidNS = this.editHandler.validateBeforeSave();

                    if (!isValidNS) {
                        return;
                    }

                    let result: boolean;

                    if (isValidFunction(this._saveButtonClick)) {
                        result = await this._saveButtonClick();
                    }

                    if (isValidFunction(onGenericSaveButtonPressed)) {
                        // Pra evitar a perda do contexto da função
                        result = !!(await this.zone.runOutsideAngular(() =>
                            this.handlerParameters.genericWindowConfig.editHandlerParameters.clientCallback.onGenericSaveButtonPressed()
                        ));
                    }

                    this.subjects.onSavedNS.next(result);

                    return result;
                },
                onGenericBackButtonPressed: async () => {
                    if (isValidFunction(onGenericBackButtonPressed)) {
                        this.zone.runOutsideAngular(() =>
                            this.handlerParameters.genericWindowConfig.editHandlerParameters.clientCallback.onGenericBackButtonPressed()
                        );
                    }

                    this.subjects.close.next();
                },
                onGenericDeleteClicked: async () => {
                    if (isValidFunction(onGenericDeleteClicked)) {
                        this.zone.runOutsideAngular(() =>
                            this.handlerParameters.genericWindowConfig.editHandlerParameters.clientCallback.onGenericDeleteClicked()
                        );
                    }
                },
                onGenericCopyClicked: async () => {
                    if (isValidFunction(onGenericCopyClicked)) {
                        this.zone.runOutsideAngular(() => {
                            this.handlerParameters.genericWindowConfig.editHandlerParameters.clientCallback.onGenericCopyClicked(
                                typedClone(this.multableObject)
                            )
                        });
                    } else {
                        this.copyToClipboardSvc.copy(this.multableObject.idNS);
                    }
                },
                onGenericSaveTags: async (tags) => {
                    if (isValidFunction(onGenericSaveTags)) {
                        this.zone.runOutsideAngular(() =>
                            this.handlerParameters.genericWindowConfig.editHandlerParameters.clientCallback.onGenericSaveTags(
                                typedClone(tags)
                            )
                        );
                    }
                }
            }
        });

        this.setupWindowListenerEvents();

        if (this.isEdit) return;

        this.editHandler.taggableHandler$.pipe(first()).subscribe(async (taggableHandler) => {
            taggableHandler.taggableChange$.pipe(first()).subscribe(() => {
                this.sourceObject.tags = typedClone(this.multableObject.tags);
            });
        });
    }

    private setupWindowListenerEvents() {
        this.editHandler.component$().pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.dialogRef.setupTriggerEvents();
        });
    }

    public minimize(): void {
        this.dialogRef.minimize();
    }

}
