import { Injectable } from '@angular/core';
import { IMessageInstanceSelectChosenOptionStandardMsg } from '../message-instance.service';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { EGeolocationIntType } from '@colmeia/core/src/business/constant.enums';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TTrackerType } from './gps-message.component';
import {SessionService} from "../../../../../services/session.service";
import {RoutingService} from "../../../../../services/routing.service";
import {SnackMessageService} from "../../../../../services/snack-bar";
import {routeID} from "../../../../../model/routes/route-constants";
import {realTimeGPSTracking} from "@colmeia/core/src/shared-business-rules/visual-constants";

@Injectable({
    providedIn: 'root'
})
export class GpsMessageService {
    constructor(
        private sessionSvc: SessionService,
        private routingSvc: RoutingService,
        private snackSvc: SnackMessageService) {}

    onDropdownOptionClicked(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg): void {
        const optionClickedID = chosenOption.optionSelected.getPrimaryID()
        switch (optionClickedID) {
            case realTimeGPSTracking:
                this.onGoToLocationViewerClicked(chosenOption.interactionClicked)
                break;
            default:
                break;
        }
    }

    private onGoToLocationViewerClicked(interaction: Interaction): void {
        const isRealTimeTrackerCanceled = interaction
            .getChildren(this.sessionSvc.getSelectedGroupID())
            .some(interaction => interaction.getInteractionType().is(EGeolocationIntType.endRealTimeTracking))
        if(isRealTimeTrackerCanceled) {
            this.snackSvc.open({
                message: Serializable.staticFactory(EGeolocationIntType.endRealTimeTracking).getName(),
                duration: 3000,
            })
        } else {
            this.goToAvatarLocation(<TTrackerType>interaction)
        }
    }

    private goToAvatarLocation(interaction: TTrackerType): void {
        this.routingSvc.navigateToId(
            routeID.location.locationViewer.id,
            interaction.getTrackedIDAvatar())
    }
}
