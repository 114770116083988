import { ETagType, IServerColmeiaTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { MainHandler } from "./main-handler";
import { IComponentParameter, IRootCallBack } from "app/model/component-comm/basic";
import { IUsedTagsData } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";



export interface INavigableTagsHandler {
    tagType: ETagType;
    tagParent?: IServerColmeiaTag;
    tags: IServerColmeiaTag[];
    selectedTags: IServerColmeiaTag[];
    onSelectItemCallback(checked: boolean, tag: IServerColmeiaTag);
    canSelectGroupTag?: boolean;
}
export interface ISelectionableTagHandler {
    selectionableTag: IServerColmeiaTag;
    selectedTags: IServerColmeiaTag[];
    onSelectItemCallback(checked: boolean);
    checked?: boolean;
}
export interface ISelectedTagsListHandler {
    selectedTags: IServerColmeiaTag[];
    isModal: boolean;
    onRemoveCallback(tag: IServerColmeiaTag): void;
    hideRemove?: boolean;
}


export interface ITagPickerHandlerParameter extends IComponentParameter {
    tagType: ETagType;
    tagsData?: IUsedTagsData;
    clientCallback: ITagPickerHandlerClientCallback;
    mode: ETagPickerHandlerMode,
    selectedTags: IServerColmeiaTag[];
    compact?: boolean;
    compactAllowPreview?: boolean;
    canSelectGroupTag?: boolean;
    autoOpen?: boolean;
};
export interface ITagPickerHandlerClientCallback extends IRootCallBack {
    onFinishSelectionCallback?(selectedTags: IServerColmeiaTag[]);
    onRemoveAndSaveCallback?(selectedTags?: IServerColmeiaTag);
    onCancel?();
    onChangeSelectedTagsOutsideModalCallback?(): void;
    onChangeSelectedTagsInModalCallback?(): void;
    onClear?(): void;
}
export interface ITagPickerHandler extends ITagPickerHandlerParameter {
    getTagType?(): ETagType;
    getTagsData?(): IUsedTagsData;
    getSelectedTags?(): IServerColmeiaTag[];

    onRemoveCallback?(selectedTags?: IServerColmeiaTag);
    onRemoveAndSaveCallback?(selectedTags?: IServerColmeiaTag);
};


export enum ETagPickerHandlerMode {
    query = 'query',
    edit = 'edit'
}


export class TagPickerHandler extends MainHandler<ITagPickerHandlerParameter> implements ITagPickerHandler {

    _clients: ITagPickerHandlerClientCallback[];
    set clients(value) {
        this._clients = value;
    }
    get clients(): ITagPickerHandlerClientCallback[] {
        return this._clients;
    }

    tagType: ETagType;
    tagsData: IUsedTagsData;
    selectedTags: IServerColmeiaTag[];
    clientCallback: ITagPickerHandlerClientCallback;
    mode: ETagPickerHandlerMode;

    constructor(parameter: ITagPickerHandlerParameter) {
        super(parameter);

        this.clients = [];

        this.mode = parameter.mode;
        this.tagType = parameter.tagType;
        this.tagsData = parameter.tagsData;
        this.selectedTags = parameter.selectedTags;
        this.clientCallback = parameter.clientCallback;
    }

    public static factory(parameter) {
        return new TagPickerHandler({
            ...parameter
        });
    }

    get alreadySelectedTags(): IServerColmeiaTag[] {
        return this.selectedTags
    }

    addClient(client: ITagPickerHandlerClientCallback) {
        this.clients = [...this.clients, client];
    }

    getTagType(): ETagType {
        return this.tagType;
    }

    getTagsData(): IUsedTagsData {
        return this.tagsData;
    }

    getSelectedTags(): IServerColmeiaTag[] {
        return this.selectedTags;
    }
    setSelectedTags(selectedTags: IServerColmeiaTag[]): void {
        this.selectedTags = selectedTags;
    }


    onRemoveAndSaveCallback(selectedTag?: IServerColmeiaTag, isModal: boolean = true): void {
        const idx: number = this.selectedTags.findIndex(tag => tag.idNS === selectedTag.idNS);

        if (idx !== -1) this.selectedTags.splice(idx, 1);

        if (isModal) this.onChangeSelectedTagsInModalCallback();
        else this.onFinishSelectionCallback(this.selectedTags);
    }

    onCancel(): void {
        this.invokeAllClientsByPropertyName('onCancel');

        if (this.clientCallback.onCancel) this.clientCallback.onCancel();
    }


    onChangeSelectedTagsInModalCallback(): void {
        this.invokeAllClientsByPropertyName('onChangeSelectedTagsInModalCallback');
    }

    onChangeSelectedTagsOutsideModalCallback(): void {
        this.invokeAllClientsByPropertyName('onChangeSelectedTagsOutsideModalCallback');
    }

    onFinishSelectionCallback(selectedTags?: IServerColmeiaTag[]) {
        this.invokeAllClientsByPropertyName('onFinishSelectionCallback', selectedTags);
        this.clientCallback.onFinishSelectionCallback(selectedTags);
    }

    private invokeAllClientsByPropertyName(propertyName: string, value?: any): void {
        for (let client of this.clients) {
            if (client[propertyName]) client[propertyName].bind(client)(value);
        }
    }

    clear(): void {
        this.setSelectedTags([]);
        this.invokeAllClientsByPropertyName('onClear');
    }
}

