<app-colmeia-window-top-bar *ngIf="isInWindow && !hideWindowBar" style="margin-bottom: 24px;"></app-colmeia-window-top-bar>

<div class="participants">

  <!-- search -->
  <section class="participants__search">
    <app-search
      id="searchParticipant"
      type="text"
      name="searchParticipant"
      [searchHandler]="getSearchHandler()">
    </app-search>
  </section>
  <mat-progress-bar *ngIf="loadingOnAction" mode="indeterminate" color="accent"></mat-progress-bar>

  <section class="participants__container" *ngIf="canShowParticipants()">
    <ul>
      <li 
        class="participant__item" 
        *ngFor="let participant of cachedParticipants" [@horizontalAppear]="'in'"
      >
        <div class="hexagon-and-name__container">
          <hexagon
            id="customizationBackgroundLabel"
            name="customizationBackgroundLabel"
            [hexagonHandler]="getHexHandler(participant)">
          </hexagon>
          <div class="name-date__wrapper">
            <span>{{ participant.getName() }}</span>
            <span
            *ngIf="cachedParticipantMembersGroupDate[participant.getPrimaryID()]"
            class="member-since-date"
            [matTooltip]="'Membro desde '+ cachedParticipantMembersGroupDate[participant.getPrimaryID()].begin + '.\n Ativo desde ' + cachedParticipantMembersGroupDate[participant.getPrimaryID()].current "
            >
              {{cachedParticipantMembersGroupDate[participant.getPrimaryID()].current}}
            </span>
          </div>
        </div>

        <div class="more-options__btn" *ngIf="canShowMoreOptions()">
          <button mat-icon-button [matMenuTriggerFor]="participantOptions">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #participantOptions="matMenu" xPosition="before">
            <!-- remove -->
            <button mat-menu-item *ngIf="canShowRemoveBtn(participant)" (click)="removeParticipant(participant)">
              <mat-icon>delete</mat-icon>
              {{ remove }}
            </button>

            <!-- roles -->
            <button mat-menu-item *ngIf="canShowRolesBtn(participant)" (click)="openRoleSelectorModal(participant)">{{ getRoleLabel() }}</button>
          </mat-menu>
        </div>
      </li>
    </ul>
  </section>
  <div *ngIf="loadingParticipants" class="loading__wrapper">
    <mat-spinner diameter="18"></mat-spinner>
  </div>

  <div class="vertical-line"></div>

  <section  class="participants__pagination">
    <cm-generic-dashboard-pagination [dense]="true" *ngIf="paginationHandler" [handler]="paginationHandler">
    </cm-generic-dashboard-pagination>
  </section>

</div>
