<div class="container" [class.tools-open]="toolsOpen" [class.search-input-expanded]="expandSearchInput"
  [class.right-items-expanded]="rightItemsExpanded">
  <nav class="main-header">
    <!--
      Left items
    -->
    <div class="leftItems">
      <button type="button" (click)="toggleMenu()" [class.flip-icon]="!isMenuOpen()" matRipple>
        <mat-icon>menu_open</mat-icon>
      </button>

      <div class="main-header__brand" tapFeedback feedbackStyleMobile="circular-img">
        <a (click)="toggleSocialNetworkTools($event)" [href]="groupRoute" matTooltip="Rede social">
          <div class="brand-with-name">
            <img class="icon-brand"
              [src]="(logoUrlIsAuthenticated) ? (logoUrl | imgSecureDownload | async) : (logoUrl)" />
            <span class="text"> {{ snName }} </span>
          </div>
          <img class="icon-brand-mini"
            [src]="(logoUrlIsAuthenticated) ? (logoUrl | imgSecureDownload | async) : (logoUrl)" />
        </a>
      </div>
    </div>

    <!--
      Search
    -->
    <form class="search-container">
      <div class="search-content">
        <!-- where to search -->
        <button mat-icon-button color="accent" [matMenuTriggerFor]="searchTypeMenu"
          matTooltip="Clique para selecionar onde buscar" matTooltipPosition="below"
          style="margin-right: 4px; margin: -16px 4px -16px -8px;">
          <mat-icon class="mat-24-force">travel_explore</mat-icon>
        </button>

        <mat-menu #searchTypeMenu="matMenu">
          <button mat-menu-item *ngFor="let searchType of searchTypeList" (click)="handleSearcTypeSelec(searchType)"
            [class.mh-search-selected-type]="searchType.type === selectedSearchType">
            <b>{{searchType.name}}</b>
          </button>
        </mat-menu>

        <div class="search-input-container mat-primary" [loading]="isSearchLoading" loadingPos="right">
          <input #searchInput class="search-input" [class.search-focus]="!isMobileSearch"
            (input)="showSearchWithOwnInputWrapper()" [placeholder]="translations.searchPlaceholder"
            (focus)="handleSearchInputFocus()" (blur)="handleSearchInputBlur()" />
          <span *ngIf="showDebounceIndicator && !isSearchLoading" class="debounce-indicator"></span>
        </div>

        <!-- minimum length indicator -->
        <span *ngIf="searchInput.value.length > 0 && searchInput.value.length < minimumCharLengthSearchFire"
          class="minimum-search-indicator">
          Digite pelo menos {{ minimumCharLengthSearchFire }} caracteres
        </span>

        <!-- search icon -->
        <button *ngIf="!canShowSearchCloseButton()" class="search-button search-focus" type="button"
          (click)="!rightItemsExpanded && searchInput.focus()"
          [matTooltip]="expandSearchInput ? 'Digite para pesquisar' : ''">
          <mat-icon>search</mat-icon>
        </button>

        <!-- close button -->
        <button *ngIf="canShowSearchCloseButton()" class="search-button search-clear" type="button"
          (click)="onSearchClose()">
          <mat-icon>close</mat-icon>
        </button>


      </div>
    </form>

    <!--
      Right items
    -->
    <div class="right-items">

      <ul class="menu-container">
        <!--li class="menu-item items-visibility-toggler">
          <button class="app-bar-button icon" tapFeedback (click)="rightItemsExpanded = !rightItemsExpanded"
            matTooltip="Expandir" matTooltipClass="tooltip-contrast">
            <mat-icon>{{ rightItemsExpanded ? 'chevron_right' : 'more_horiz' }}</mat-icon>
          </button>
        </li-->

        <!-- share -->
        <li class="menu-item" *ngIf="showShare" tapFeedback>
          <a (click)="toggleShareVisibility()" matBadgeSize="medium" matBadgeColor="warn" [matBadge]="getShareCount()"
            [matBadgeOverlap]="true">
            <i class="material-icons item-icon">share</i>
            <span class="item-label"> {{ translations.share }} </span>
          </a>
        </li>

        <!-- Version Icon -->
        <li class="menu-item" tapFeedback>
          <button
            mat-icon-button
            matTooltip="{{ currentVersionName }}"
            matTooltipPosition="below"
            [matMenuTriggerFor]="menu"
            matTooltipClass="tooltip-contrast">
            <mat-icon
              matBadgeSize="small"
              [matBadge]="matBadgeText"
              matBadgeColor="warn"
              show="isThereADefinedVersion"
            >history
            </mat-icon>
          </button>
          <mat-menu
            #menu="matMenu"
            class="custom-version-menu">
            <ng-container
              *ngFor="let version of allVersions;">
              <button
                mat-menu-item
                (click)="onMigrationVersionChangeClick(version)"
                (mouseenter)="versionButtonHovered = true"
                (mouseleave)="versionButtonHovered = false"
                style="font-size: .875rem; display: flex; justify-content: space-between; align-items: center; width: 100%;"
              >
                <div style="
                  display: flex;
                  align-items: center;
                  flex-grow: 1;
                  justify-content: space-between;">
                  <span>{{ getMenuItemVersionName(version) }}</span>
                  <div style="display: flex; align-items: center; gap: 8px;">
                    <button
                      (click)="unsetSelectedVersion(); $event.stopPropagation();">
                      <mat-icon
                        *ngIf="isTheDefinedVersion(version)"
                        [size]="20"
                        [ngStyle]="{'margin-top': '5px'}">
                        {{ getUnsetVersionMatIconText(version) }}
                      </mat-icon>
                    </button>
                  </div>
                </div>
              </button>
            </ng-container>
          </mat-menu>
        </li>

        <!-- Notifications -->
        <li class="menu-item ever-visible">
          <button #notificationsTriggerButton class="app-bar-button icon" tapFeedback matTooltip="Notificações"
            matTooltipClass="tooltip-contrast" matBadgeSize="small"
            [matBadgeHidden]="notificationOverlayRef || getUnreadNotificationsCount() === 0"
            [matBadge]="getUnreadNotificationsCount()" matBadgeColor="warn" (click)="onNotificationsClicked()">
            <mat-icon>notifications</mat-icon>
          </button>
        </li>

        <!-- Avatar -->
        <li class="menu-item ever-visible" tapFeedback>
          <button type="button" class="app-bar-button" [matMenuTriggerFor]="playerMenu" (click)="closeAllInteractions()"
            matTooltip="Conta">
            <hexagon *ngIf="hexagonHandler" class="item-icon" [hexagonHandler]="hexagonHandler">
            </hexagon>
            <span class="item-label avatar-name">{{ currentAvatar?.getName() }}</span>
          </button>
          <span class="socket-conn-status" [ngClass]="lastSocketConnStatus$ | async"></span>
        </li>
        <mat-menu #playerMenu="matMenu">
          <button mat-menu-item *ngFor="let item of profileItems" (click)="profileItemClick(item.option)">
            <span style="font-size: .875rem;">
              {{getTranslation(item.translation)}}
            </span>
          </button>
        </mat-menu>
      </ul>
    </div>
  </nav>

  <cm-modal [handler]="settingsCmModalHandler"></cm-modal>

  <div class="social-network-tools" *ngIf="canShowSocialNetworkTools">
    <div class="social-network-tools--overlay" (click)="closeSocialNetworkTools($event)"></div>
    <div class="social-network-tools__container">
      <app-tools [handler]="socialNetworksToolsDataAppToolsHandler"></app-tools>
    </div>
  </div>

  <div class="app-search" [hidden]="!_canShowSearch()">
    <div class="app-search--overlay" (click)="onSearchClose()"></div>
    <div class="app-search__container">
      <app-search *ngIf="searchHandler" [searchHandler]="searchHandler" (searchClosed)="onSearchClose()"></app-search>
    </div>
  </div>

  <div class="share" *ngIf="isShareVisible()">
    <div class="share--overlay" (click)="hideShare()">
    </div>
    <div [hidden]="!isShareVisible()" class="share__container">
      <app-share [shareHandler]="getShareHandler()"></app-share>
    </div>
  </div>
</div>
<!-- <div class="loading-container" [class.visible]="loading$ | async">
  <cm-spinner *ngIf="loading$ | async"></cm-spinner>
</div> -->

<!-- <div *ngIf="isDevMode()" style="border: 1px solid red;">
  <button style="border: 1px solid red;" (click)="execTests()">execTests</button>
</div> -->
