import { Injectable } from "@angular/core";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { RequestBuilderServices } from "./request-builder.services";
import { isInvalid } from "@colmeia/core/src/tools/utility";
import { SessionService } from "./session.service";
import { ServerCommunicationService } from "./server-communication.service";
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IListNonSerializablesRequest, IDashBoardRequest, IGenericListNonSerializablesRequest, IListNonSerialibazblesRequestCreateInfo, IBasicDashboardRequest } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { toMultipleCursor } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions";
import { ITagableSearch } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { TArrayID, TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";


interface IGetRequestForGeneric {
    nsType: ENonSerializableObjectType;
    taggable?:ITagableSearch;

    cursor?: string;
    idParent?: TGlobalUID;
    idsNS?: TArrayID;
}

interface RequestResponseConfig {
    Request?: IRequest,
    Response?: IResponse
}

@Injectable({
    providedIn: 'root'
})
export class GenericDashboardRequester {

    constructor(
        private rbs: RequestBuilderServices,
        private session: SessionService,
        private api: ServerCommunicationService
    ) {
    }

    getBaseRequest<R extends IRequest = IRequest>(request: Partial<R>): R {
        return {
            ...this.rbs.secureBasicRequestForCustomGroup(request.requestType, this.session.getCurrentSocialNetworkID()) as R,
            ...request
        };
    }

    async request<Config extends RequestResponseConfig = RequestResponseConfig>(req: Config["Request"], spinning: boolean): Promise<Config["Response"]> {
        const infra: IInfraParameters = (spinning) ? this.rbs.getContextNoCallBackSpinnningParameters() : this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response: ClientInfraResponse = await this.api.managedRequest(infra, req);
        if (response.executionOK) {
            return response.response as Config["Response"];
        }
    }

    getRequestFor(apiRequestType: string, cursor: string, nsType: ENonSerializableObjectType, taggable?: ITagableSearch): IListNonSerializablesRequest {
        return {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType, this.session.getCurrentSocialNetworkID()),
            multipleCursor: toMultipleCursor(cursor),
            nsType,
            taggable
        }
    }

    getRequestForNS<T extends {}>(info: T & IListNonSerialibazblesRequestCreateInfo): IListNonSerializablesRequest {
        return {
            ...this.rbs.secureBasicRequestForCustomGroup(info.apiRequestType, this.session.getCurrentSocialNetworkID()),
            ...info,
            multipleCursor: toMultipleCursor(info.cursor || null),
            nsType: info.nsType,
            taggable: info.taggable,
            idParent: info.idParent,
        }
    }

    // getBasicDashboardRequest({apiRequestType, nsType, tag, cursor}): IBasicDashboardRequest {
    //     return {
    //         ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType, this.session.getCurrentSocialNetworkID()),
    //         multipleCursor: toMultipleCursor(cursor || null),
    //         nsType,
    //         taggable: tag,
    //     }
    // }

    getRequestForGenericNS(options: IGetRequestForGeneric): IGenericListNonSerializablesRequest {
        const {
            cursor,
            nsType,
            idsNS,
            idParent,
            taggable,
        }: IGetRequestForGeneric = options;

        return {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.nonSerializable.getGeneric, this.session.getCurrentSocialNetworkID()),
            multipleCursor: toMultipleCursor(cursor),
            nsType,
            taggable,

            idsNS,
            idParent,
        }
    }
}
