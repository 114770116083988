import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription, timer } from 'rxjs';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { HardwareLayers } from '@colmeia/core/src/core-constants/constant-visual-elements.enums';
import { constant } from '@colmeia/core/src/business/constant';
import {RecorderHandler} from "../../handlers/recorder-handler";
import {ServerCommunicationService} from "../../services/server-communication.service";
import {HardwareLayerService, IRecordMicrophoneResult} from "../../services/hardware";
import {SnackMessageService} from "../../services/snack-bar";
import {FileUploadSignal} from "../../model/signal/file-upload-signal";
import {EHardwareResourceStatus} from "../../services/hardware/cordova/vendor/hardware-status";


@Component({
    selector: 'chat-media-recorder',
    templateUrl: './chat-media-recorder.component.html',
    styleUrls: ['./chat-media-recorder.scss']
})
export class ChatMediaRecorder implements OnInit, OnDestroy {

    private secondsTick: number = 0;
    private currentUrl: SafeUrl;
    private timerAnimationSubscription: Subscription;
    public fileUploadProgress: number = 0;
    public isRecording: boolean = false;
    public hasRecording: boolean = false;
    public labels = {
        record: '',
        stop: '',
        rerecord: ''
    }

    @Input() recorderHandler: RecorderHandler;

    constructor(
        private cdr: ChangeDetectorRef,
        private serverCommSvc: ServerCommunicationService,
        private hardware: HardwareLayerService,
        private snack: SnackMessageService
    ) { }

    ngOnInit() {
        console.log('chat-media-recorder')
        this.initLabels();

        // listens for upload progress events and emits it to parent components
        this.serverCommSvc.uploadStatusListener()
        .subscribe((progress: FileUploadSignal) => {
            this.fileUploadProgress = progress.getPercentage();
            this.cdr.markForCheck();
        });
        this.startRecording();
    }

    ngOnDestroy(): void {
        this.cancelRecording();
    }

    initLabels() {
        const serializable: Serializable = Serializable.staticFactory(HardwareLayers.MicrophoneHardware);
        this.labels.record = serializable.getSerializableText(constant.serializableField.auxiliars.aux01);
        this.labels.stop = serializable.getSerializableText(constant.serializableField.auxiliars.aux02);
        this.labels.rerecord = serializable.getSerializableText(constant.serializableField.auxiliars.aux04);
    }

    get recordBtnMessage(): string {
        if (this.hasRecording) {
            return this.labels.rerecord;
        }

        return this.isRecording
                ? this.labels.stop
                : this.labels.record;
    }

    public onRecordBtnClick(): void {
        if (this.isRecording){
            this.stopRecording();
        } else {
            this.startRecording();
        }
    }

    private async startRecording(): Promise<void> {
        this.hasRecording = false;
        this.secondsTick = 0;
        const microphoneHardware = this.hardware.getMicrophone();
        const authorizationStatus: EHardwareResourceStatus = await microphoneHardware.getAuthorizationStatus();
        if(authorizationStatus != EHardwareResourceStatus.GRANTED){
            await microphoneHardware.requestAuthorization();
        }

        const isAllowed: boolean = await microphoneHardware.isAllowed();
        const isRecording: boolean = await microphoneHardware.startRecording();

        /**

         [Log] segund – {isAllowed: true, isRecording: false} (cordova.js, line 1509)
         [Log] getAuthorizationStatus -  – "authorized" (cordova.js, line 1509)
         [Log] primeiro – "authorized" (cordova.js, line 1509)
         [Log] getAuthorizationStatus -  – "authorized" (cordova.js, line 1509, x2)
         [Log] segund – {isAllowed: true, isRecording: false} (cordova.js, line 1509)

         */

        if(isRecording && isAllowed){
            this.startAnimation();
        }else{
            const serializable: Serializable = Serializable.staticFactory(HardwareLayers.MicrophoneHardware);
            const errorMessage: string = serializable.getSerializableText(constant.serializableField.auxiliars.aux03);
            this.snack.openError(errorMessage);
        }

    }

    private stopRecording() {
        try {
            this.hardware.getMicrophone()
                .stopRecording(this.hardware.getFilesystem())
                .then((res: IRecordMicrophoneResult) => {
                    this.recorderHandler.onFileSelected(res.file);
                    this.hasRecording = true;
                    this.stopAnimation();
                });

        } catch(e) {

        }
    }

    public cancelRecording(): void {
        this.hardware.getMicrophone().cancelRecording();
        this.stopAnimation();
    }

    public discardRecording(): void {
        this.recorderHandler.onCancelFile();
    }

    getAudioUrl(): SafeUrl {
        return this.currentUrl;
    }

    /**
     * Gets secondsTick in time formatted, ex: 0:0 -> 00:00
     * @returns string
     */
    public getRecordedTime(): string {
        const minutes: number = Math.floor(this.secondsTick / 60);
        const seconds: number =  this.secondsTick - minutes * 60;
        return this.formatTime(minutes) + ':' + this.formatTime(seconds);
    }

    private stopAnimation(): void {
        this.isRecording = false;
        if (this.timerAnimationSubscription){
            this.timerAnimationSubscription.unsubscribe();
            this.cdr.markForCheck();
        }
    }

    private startAnimation(): void {
        this.isRecording = true;
        this.cdr.markForCheck();
        this.timerAnimationSubscription = timer(1000, 1000).subscribe(val => {
            this.secondsTick++;
            this.cdr.markForCheck();
        });
    }

    /**
     * transforms any time number to zero padded left ex: 0 -> 00, 1 -> 01
     * @param  {number} timeSlice
     */
    private formatTime(timeSlice: number) {
        return timeSlice.toString().length === 1 ? `0${timeSlice}` : timeSlice;
    }

}
