import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

const callCenterScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.CallCenter,
    fields: {
        queueName: 1,// 'Nome da fila', 
        customerName: 2,// 'Nome do cliente',
        target: 3,// 'Alvo', //
        totalCustomerVisit: 4,// 'Total de visitas do cliente',
        visitisToday: 5,// 'Visitar de hoje',
        totalIslandVisited: 6,// 'Total de ilhas visitadas',
        waitingTime: 7,// 'Tempo de espera', //
        callCenter: 8, // Call Center
        allAgents: 9, // Todos os agentes
        serviceOverview: 10, // Visão geral de atendimento
        queue: 11,// 'Fila'
        client: 12,// 'Cliente'
        agent: 13, // 'Agente',
        weightSum: 14, // 'Total de pesos',
        longerServiceTime: 15, // 'Maior tempo de atendimento',
        averageOpen: 16, // 'Média de tempo de atendimento',
        totalWeight: 17, // 'Peso total',
        amountService: 18, // 'Quantidade de serviços',
        weight: 19,// 'Peso',
        maxWeight: 20,// 'Peso máximo',
        totalOnlineAgents: 21,// 'Total de agentes online',
        maxWaitTime: 22,// 'Tempo máximo de espera',
        averageWaitTime: 23,// 'Tempo médio de espera',
        waitingList: 24,// 'Lista de espera',
        navigate: 25,// 'Navegar',
        
    }
} as const);


const callCenterScreenOptions2 = typedScreenOptions({
    idSerializable: EAllSerializable.CallCenter2,
    fields: {
        maxServiceTime: 1,// 'Tempo máximo de serviço',
        averageServiceTime: 2,// 'Tempo médio de serviço',
        totalAgents: 3,// 'Total de agentes',
        work: 4, // Trabalho
        beginSr: 5,//'Início de serviço',
        serviceTime: 6,//'Tempo de serviço',
        customerDeviceTime: 7,//'Tempo de dispositivo do cliente',
        requestTime: 8,//'Tempo de solicitação do cliente',
        minServiceTime: 9,//'Mínimo de tempo de serviço',
        case: 10, // 'Caso',
            }
} as const);


export const callcenterTranslations = {
    ...getTranslationsFields(callCenterScreenOptions),
    ...getTranslationsFields(callCenterScreenOptions2),
}