import { EventEmitter, Injectable, Output } from '@angular/core';
import { ISwitchMenuTemplateHandler } from 'app/components/dashboard/dashboard-social-media/menu-templates/switch-menu-template/switch-menu-template.component';

@Injectable({
  providedIn: 'root'
})
export class MenuPreviewService {
  @Output() updateMenuSwitchHandler = new EventEmitter<Partial<ISwitchMenuTemplateHandler>>()

  constructor() { }

  public updateMenuPreview(partial: Partial<ISwitchMenuTemplateHandler>) {
    this.updateMenuSwitchHandler.emit(partial);
  }

}
