import { intervalIntersectsInterval, intervalContainsInterval, midnightMS } from "./time-utl";
import { WeekDay } from "./weekday";
import { IIntervalJSON } from './time-interfaces';

export type TArrayInterval = Array<Interval>;



export class Interval {

    private fromMS: number; // 0..24hs, ms
    private toMS: number;  // 0..24hs, ms
    private idInterval: number;
    private priority: number;
    private weekday: WeekDay;

    protected constructor(fromMS: number, toMS: number, priority: number, weekday: WeekDay, idInterval: number) {
        this.fromMS = fromMS;
        this.toMS = toMS;
        this.priority = priority;
        this.weekday = weekday;
        this.idInterval = idInterval;
    };


    public contains(insideMoment: Interval): boolean {
        return intervalContainsInterval(this, insideMoment);
    };

    public hasIntersectionWith(interval: Interval): boolean {
        return intervalIntersectsInterval(this, interval);
    };

    public getIntervalDuration(): number {return this.toMS - this.fromMS;};

    public getFromMS(): number {return this.fromMS};
    public getToMS(): number {return this.toMS};
    public getDuration(): number {return this.toMS - this.fromMS;};

    public setToMS(ms: number): void {this.toMS = ms;};
    public setFromMS(ms: number): void {this.fromMS = ms;};

    public setWeekDay(weekday: WeekDay): void {this.weekday = weekday;};

    public getPriority(): number {return this.priority? this.priority: 0};
    public setPriority(priority: number): void {this.priority = priority;};

    public getWeekDay(): WeekDay {return this.weekday;};

    public getDayID(): number {return this.weekday? this.weekday.getDay(): null};


    public isDurationOk(duration: number): boolean {return this.getDuration() >= duration}

    public toJSON(): IIntervalJSON {
        return {
            fromMS: this.fromMS,
            toMS: this.toMS,
            idInterval: this.idInterval,
            idDay: this.getDayID(),
            priority: this.getPriority(),
        };
    };

    public static factoryMessage(json: IIntervalJSON): Interval {
        return new Interval(json.fromMS, json.toMS, json.priority,WeekDay.getWeekDay(json.idDay), json.idInterval);
    };

    

    public static getNewInterval(fromMS: number, toMS: number): Interval {
        return new Interval(fromMS, toMS, null, null, null);
    };

    public getNewSolicitationInterval(fromMS: number, toMS: number,  priority: number, weekday: WeekDay): Interval {
        return new Interval(fromMS, toMS, priority, weekday, null);
    }

    


}

