import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SubscriptionGroup } from 'app/model/client-utility';
import { DevService } from 'app/services/dev.service';
import { copyToClickBoard } from 'app/utils/copy';
import { environment } from "environments/environment-client";
import { fromEvent } from 'rxjs';
import { VisualElementService } from './../services/visual-element.service';

@Directive({
    selector: '[serializable]'
})
export class SerializableDirective implements OnInit, OnDestroy {

    @Input()
    serializable: string;

    private events: SubscriptionGroup = new SubscriptionGroup();

    constructor(
        private el: ElementRef<any>,
        private visualElementsSvc: VisualElementService,
        private render: Renderer2,
        private devSvc: DevService
    ) { }

    public ngOnInit() {
        if(environment.production) return;

        this.init();
    }

    public ngOnDestroy() {
        this.events.destroy();
    }

    private init() {
        this.events.from(this.devSvc.enabled$()).subscribe(() => {
            this.el.nativeElement.classList.toggle("serializable-directive-item-enabled");
        });

        if(this.devSvc.enabled) {
            this.el.nativeElement.classList.add("serializable-directive-item-enabled");
        }

        this.el.nativeElement.classList.add("serializable-directive-item");

        const $icon = document.createElement('i');

        $icon.className = "mat-icon material-icons";
        $icon.textContent = "content_copy"

        const $el = document.createElement('button');

        $el.className = "mat-icon-button mat-button-base serializable-directive-help-button";

        $el.appendChild($icon);

        this.events.from(fromEvent($el, "click")).subscribe((e: MouseEvent) => {
            e.preventDefault(); e.stopPropagation();

            copyToClickBoard(JSON.stringify(this.visualElementsSvc.getLocalExample(this.serializable), null, 4))
            // console.log({ serializable: Serializable.staticFactory(this.serializable).toJSON() });
        });

        this.render.appendChild(this.el.nativeElement, $el);
    }
}
