import { Injectable } from '@angular/core';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { IVisualElementGetRequest, IVisualElementGetResponse, IVisualElementRefreshCacheRequest, IVisualElementRefreshCacheResponse, IVisualElementUpsertRequest, IVisualElementUpsertResponse } from '@colmeia/core/src/shared-business-rules/visual-element/visual-element-req-resp';
import { IBasicField, ISerializableTextWithPrimaryId, ISerializableYAML } from '@colmeia/core/src/shared-business-rules/visual-element/visual-element.types';
import { getSerializableTextWithPrimaryID, serializableToYAML, translationToField } from '@colmeia/core/src/shared-business-rules/visual-element/visual-element.utils';
import { isInvalid, isValidRef, isValidString, sortBy, values } from '@colmeia/core/src/tools/utility';
import { IMessageDialogDescriptorClient } from 'app/handlers/descriptor-handler';
import { EMsgDialogType } from 'app/model/misc.model';
import { GlobalWarningService } from './global-warning.service';
import { ServerCommunicationService } from './server-communication.service';

@Injectable({
    providedIn: 'root'
})
export class VisualElementService {
    private mock: ISerializableYAML = {
        primaryID: "ExampleTest",
        fields: [
            {
                id: 1,
                text: "APIs Downstream"
            },
            {
                id: 18,
                text: "api"
            }
        ],
        delete: [
            {
                id: "1"
            },
            {
                id: "18"
            }
        ],
        screenGroups: [
            {
                screenGroup: "somescreengroup",
                idMenu: "someidmenu"
            }
        ],
        route: "someroute"
    };

    constructor(
        private api: ServerCommunicationService,
        private globalWarningSvc: GlobalWarningService,
    ) { }

    public async getExample(idSerializable?: string): Promise<ISerializableYAML[]> {
        const response = await this.api.doRequest<IVisualElementGetRequest>(apiRequestType.visualElement.get, {
            primaryIDList: [idSerializable],
        }) as IVisualElementGetResponse
        return response.elementList;
    }

    public getLocalExample(idSerializable?: string): ISerializableYAML {
        if (isInvalid(idSerializable)) {
            return this.mock;
        }
        return serializableToYAML(idSerializable);
    }

    public async upsertField(primaryID: string, idField: number, text: string): Promise<IVisualElementUpsertResponse> {
        const field: ISerializableYAML = {
            primaryID,
            fields: [{ id: idField, text }],
        };
        return this.upsert([field]);
    }

    public warningMessage(message: string): void {
        this.globalWarningSvc.openDialog({
            type: EMsgDialogType.Warning,
            messages: [message],
            clientCallback: <IMessageDialogDescriptorClient>{
                onOptionClickedCallback: (item) => { },
            },
        })
    }

    public checkDuplicatedFields(elements: ISerializableYAML[]): string {
        const hasDuplicatedFields = elements.some(element => this.hasDuplicatedFields(element))
        if (hasDuplicatedFields) return 'Campos duplicados';
    }

    public hasDuplicatedFields(element: ISerializableYAML): boolean {
        const mapIdFieldToCount: Map<number, number> = new Map()
        element.fields.map(field => {
            if (!mapIdFieldToCount.has(field.id)) mapIdFieldToCount.set(field.id, 0);
            mapIdFieldToCount.set(field.id, mapIdFieldToCount.get(field.id) + 1)
        });

        return [...mapIdFieldToCount.values()].some(count => count > 1);
    }

    public async refreshCache(): Promise<boolean> {
        const isSuccess: IVisualElementRefreshCacheResponse = await this.api.quick<IVisualElementRefreshCacheRequest, IVisualElementRefreshCacheResponse>()({
            requestType: apiRequestType.visualElement.refreshCache,
        })
        return isValidRef(isSuccess)
    }

    public async upsert(elements: ISerializableYAML[]): Promise<IVisualElementUpsertResponse> {
        const errorMessage: string = this.checkDuplicatedFields(elements);
        if (isValidString(errorMessage)) {
            this.warningMessage(errorMessage);
            return;
        }
        const response = await this.api.doRequest<IVisualElementUpsertRequest>(apiRequestType.visualElement.upsert, {
            elements,
        }) as IVisualElementUpsertResponse;
        return response;
    }

    public searchTranslations(text: string): IBasicField[] {
        const pattern: RegExp = new RegExp(text, 'i');
        const fields = values(UberCache.getUberCache())
            .map(getSerializableTextWithPrimaryID)
            .flat(Number.MAX_SAFE_INTEGER)
            .map((item: ISerializableTextWithPrimaryId) => item)
            .filter(item => pattern.test(item.text))
            .map(item => translationToField(item, true))
            ;
        return sortBy(fields, ['primaryID', 'id']);
    }

}
