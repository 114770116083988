<div class="vcard-container" (click)="$event.stopPropagation()">
  <header class="vcard-header">
    <span class="vcard-contact-name">
      {{ contactName }}
    </span>
  </header>
  <section class="vcard">
    <article>
      <ul class="property-list">
        <li *ngFor="let prop of allPropertiesExceptName">
          <div class="vcard-contact-property">
            <div class="vcard-property-icon">
              <mat-icon>{{ allKindIcons[prop.kind] }}</mat-icon>
            </div>
            <span class="vcard-property-value">
              {{ prop.value }}
            </span>
            <div class="vcard-property-controls">
              <button mat-icon-button [copyToClipboard]="prop.value">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </article>
  </section>
</div>
