import { MainSignal } from '../main-signal';
import { observableList } from '../signal-constants';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { ISenderSignatureJSON } from '@colmeia/core/src/comm-interfaces/tracker-interfaces';
import {IMainSignalState} from "../ps-interfaces";


export interface ITypingSignalParameter extends IMainSignalState {
    signature: ISenderSignatureJSON;
}


export class TypingSignal extends MainSignal {
    private idGroup: TGlobalUID;


    constructor(signature: ISenderSignatureJSON) {
        super(observableList.tracker.typing, true, signature);
        this.idGroup = signature.idGroup;

    };

    public getObservableInformation(): ISenderSignatureJSON {
        return super.getObservableInformation()
    };

    public getGroupID(): TGlobalUID {
        return this.idGroup;
    };

    public getWhoIsTalking(): string {
        return this.getObservableInformation().idAvatar;
    };
};
