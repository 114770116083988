import { isValidString } from '../../../../tools/utility';
import { ESubscribedTo } from '../../../BPM/common/common-bpm';
import { IBPMMarketingAcknowledgeServer, TBpmMarketingNsTypes } from '../../../BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { INodeDeletedConnection } from '../ns/ns.host.types';
import { MktHostNode } from './mkt-host-node';

export class MktAckHost extends MktHostNode {
    constructor(mktAck: IBPMMarketingAcknowledgeServer) {
        super(mktAck)
    }

    static create(mktAck: IBPMMarketingAcknowledgeServer): MktAckHost {
        return new MktAckHost(mktAck)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return false;
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return target === ENonSerializableObjectType.bpmMarketingAction ||
            target === ENonSerializableObjectType.bpmMarketingPause
    }

    deleteConnectionTo(targetToBeDeleted: MktHostNode, connectionType: TBpmMarketingNsTypes): INodeDeletedConnection {
        const isContentGenerator: boolean = targetToBeDeleted.getHostedType() == ENonSerializableObjectType.contentGenerator;
        if (isContentGenerator) {
            const nser: IBPMMarketingAcknowledgeServer = (<IBPMMarketingAcknowledgeServer>this.getNonSerializable());

            nser.listenTo = {
                idSubscribedTo: undefined,
                type: undefined
            }
            return { deletedConnection: true }
        }

        return { deletedConnection: false }
    }

    public mustUpdateConnections(connectionType: ESubscribedTo): boolean {
        return true;
    }

    thereIsMoreChildrenNodesToCreate(): boolean {
        const con = this.getConnectionTargetData()[0];
        return con.connectionType == ESubscribedTo.contentGenerator && isValidString(con.targetHostId)
    }
}
