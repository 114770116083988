import { ICRMTicketDataWithCustomerName, TTicketInteraction } from "./crm-entities";


export enum ETicketNotificationContentType {
    interaction = `intrctn`
}


export interface ITicketNotificationContentBase {
    type: ETicketNotificationContentType;

    ticket: ICRMTicketDataWithCustomerName;
}

export interface ITicketNotificationContentInteraction extends ITicketNotificationContentBase {
    type: ETicketNotificationContentType.interaction;
    interaction: TTicketInteraction;
}

export type TCRMTicketNotificationContent = ITicketNotificationContentInteraction