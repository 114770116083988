
<div class="btn__more-options">
  <span
    class="trigger"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="moreOptionsMenu"
    (menuOpened)="moreOptionsMenu.disabled = true"
    (menuClosed)="moreOptionsMenu.disabled = false"></span>
  <button
    mat-icon-button
    (click)="onClick()"
  >
    <mat-icon *ngIf="!waitingReconnect" [ngClass]="{
      'white-more-options': isWhiteMoreOptions,
      'gray-more-options': isGrayMoreOptions
    }">more_vert</mat-icon>
    <mat-spinner *ngIf="waitingReconnect" diameter="18"></mat-spinner>
  </button>

  <mat-menu #moreOptionsMenu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition">
    <button *ngFor="let option of getMenuOptions(); trackBy:trackByFn" (click)="onOptionClicked(option)" mat-menu-item>
      <mat-icon *ngIf="isValid(option.getIcon())">{{ option.getIcon() }}</mat-icon>
      <i class="emoji" *ngIf="canShowEmoji(option)">{{ option.getEmoji() }}</i>
      <span>{{ option.getName() }}</span>
    </button>
  </mat-menu>
</div>
