
<div [hidden]="!canDisplayField">
  <mat-expansion-panel class="stroked-inset" [disabled]="isDisabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>image</mat-icon>&nbsp;
        {{ field.prompt }}
        <span *ngIf="isRequired" style="margin-left: .25em">*</span>
      </mat-panel-title>
      <mat-panel-description *ngIf="canDisplayFormGroup">
        <span *ngIf="getErrorMsg()" class="error-msg">{{ getErrorMsg() }}</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-hexagon-uploader (click)="openImgModal()" [handler]="hexagonUploaderHandler"></app-hexagon-uploader>
  </mat-expansion-panel>
</div>
