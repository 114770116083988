import { Injectable } from '@angular/core';

@Injectable()
export class AttendanceChatSearchCharacters {
    private charactersToHighlight: string    

    public getSearchingLettersToHighLight(){
        return this.charactersToHighlight
    }

    public setSearchingLettersToHighLight(stringToSet: string){
        this.charactersToHighlight = stringToSet
    }
}
