<!-- position fixed 100%(pointer-events: none) -->
<div [ngClass]="{'dropdown-content--hidden': !isVisible()}" class="dropdown-container" >

    <!-- position absolute(100%)/ to close the window(pointer-events: auto) -->
    <div class="dropdown-closer" (click)="onDropdownBackgroundClicked()"></div>

    <!-- position absolute(pointer-events: auto) -->
    <div #dropdownContent class="dropdown-content"
      [ngStyle]="getStyles()"
      [ngClass]="{
        'dropdown-content--hidden': !isVisible(),
        'dropdown-content--left':   isLeft(),
        'dropdown-content--right':  isRight(),
        'dropdown-content--bottom': isBottom(),
        'dropdown--grey':           isGrey()
      }">
      <a *ngFor="let item of getMenuItems()" (click)="onItemClick($event, item)">
        <div class="dropdown-content--centered">
          <!-- <pre>ID: {{ item.getPrimaryID() }} </pre><br> -->
          <!-- icon -->
          <i class="material-icons" *ngIf="canShowIcon(item)">{{ item.getIcon() }}</i>

          <!-- emoji -->
          <i class="emoji" *ngIf="canShowEmoji(item)">{{ item.getEmoji() }}</i>

          <!-- label -->
          <span> {{ item.getName() }} </span>

          <!-- checked -->
          <i *ngIf="canShowSelectedItem()" class="material-icons" [ngClass]="{'dropdown__check--hidden': !isSelectedItem(item)}">
            check
          </i>
        </div>
      </a>
    </div>

  </div>
