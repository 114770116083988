import { TExecutableItemArray, ExecutableItem, ExecutableType } from './executable-item';
import { TGroupArray, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { IDataBuilder } from './safe-menu-builder';
import { Group } from '@colmeia/core/src/business/group';
import { TRoleArray } from '@colmeia/core/src/security/role';
import { hasRoleToBellongToGroup } from '@colmeia/core/src/rules/sec-functions';
import { getChatGroupFilter, onlyInviteesCanParticipant, needToAskToParticipate } from '@colmeia/core/src/rules/filters';


export class SecurityGroupNavigator {
    private data: IDataBuilder;

    constructor(
        menuBuilder: IDataBuilder
    ) {
        this.data = menuBuilder
    }

    public getChatBarGroupList(): TExecutableItemArray {
        const groupArray: TGroupArray = this.data.isEmpty
            ? []
            : getChatGroupFilter(
                this.data.sessionService.getGroupsFromSN(this.data.sessionService.getCurrentSocialNetworkID())
            ).filter(g => this.data.playerInfo.belongsToGroup(g.getGroupID()));

        return this.getSecureGroups(groupArray);
    };
    

    public getSecureGroup(group: Group): ExecutableItem {
        return this.getSecureGroups([group])[0];
    };

    public getSecureGroups(groupArray: TGroupArray): TExecutableItemArray {
        const playercached: PlayerCachedInfo = this.data.playerInfo;
        const isEmpty: boolean = this.data.isEmpty;
        const menu: TExecutableItemArray = [];

        for (const group of groupArray) {
            const hasSubscription: boolean = false;
            const idAvatar: TGlobalUID = isEmpty ? null : playercached.getAvatarGroup(group.getGroupID(), true);
            const roles: TRoleArray = isEmpty ? null : playercached.getGroupAvatarRole(group.getGroupID(), idAvatar, true);

            menu.push(new ExecutableItem(group, ExecutableType.groups, {
                accessWithAnotherAvatar: (idAvatar ? true : false) && ! this.data.currentAvatar.is(idAvatar),
                accessWithCurrentAvatar: isEmpty ? false : this.data.currentAvatar.is(idAvatar),
                canExecuteOperation: isEmpty ? false : hasRoleToBellongToGroup(roles),
                inMemory: hasSubscription,
                onlyInvitees: isEmpty ? true : onlyInviteesCanParticipant(group),
                needToRequestParticipant: isEmpty ? true : needToAskToParticipate(group),
            }));
        };
        return menu;
    };





};
