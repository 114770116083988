<ng-container *ngIf="handler">
  <div class="timestamp__label" *ngIf="label">{{ label }}</div>
  <div class="timestamp__container">
    <ng-container *ngFor="let element of elements | keyvalue; index as i">
      <div class="column input" >
        <button tabindex="1" class="increment-button top" (click)="changeHelpButton(element.key, 'increase', valueInput)" [disabled]="disabled">
          <mat-icon size="18">keyboard_arrow_up</mat-icon>
        </button>
        <input
          matInput
          #valueInput
          type="number"
          [attr.placeholder]="defaultElementsSuffix[element.key] || element.value.suffix"
          [ngModel]="elementsValuesHash[element.key]"
          (ngModelChange)="setElementValue(element.key, $event)"
          [min]="element.value.min"
          [max]="element.value.max"
          [disabled]="disabled || element.value.disabled"
          tabindex="2"
          class="hidden-spin-number">
        <span
          class="date-el-indicator"
          [style.right.px]="charsLength(elementsValuesHash[element.key]) >= 3 ? -8 : 0">
          {{ element.value.suffix || defaultElementsSuffix[element.key] }}
        </span>
        <button tabindex="1" class="increment-button bottom" (click)="changeHelpButton(element.key, 'decrease', valueInput)" [disabled]="disabled || element.value.disabled">
          <mat-icon size="18">keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>

</ng-container>
