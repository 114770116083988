import { ITasksFilter, TServerCheckFeedbackArray } from './dynamic-filter';
import { TArrayID } from '../../core-constants/types';
import { IGetLookupInformationRequest, IGetLookupResponse } from '../../request-interfaces/lookup-request';
import { IResponse } from '../../request-interfaces/response-interfaces';
import { TBasicUniveralInfoArray } from '../../comm-interfaces/aux-interfaces';
import { IRequest } from '../../request-interfaces/request-interfaces';

export enum EDynamicMatch {
    first = 'first', last = 'last', any = 'any'
}

export interface IFilterInterval {
    min: number;
    max: number;
}

export type TFilterletArray = Array<IFilterlet>;

export interface IDynamicFilter2 {
    filterlets: TFilterletArray;
};


export interface IFilterlet {
    positiveMatch: boolean;
    match: EDynamicMatch;
    feedbacks?: TArrayID;
    interactionTypes?: TArrayID;
    avatarList?: TArrayID;
    tasks?: ITasksFilter;
    interval?: IFilterInterval
};

export interface IGetUsedFeedbackAndTypeLookupRequest extends IGetLookupInformationRequest {}
export interface IGetUsedFeedbackAndTypeLookupResponse extends IGetLookupResponse {
    lookupInteractionType: TBasicUniveralInfoArray;
    lookupFeedback: TBasicUniveralInfoArray;
}

export interface IConfirmFeedbackFilterRequest extends IRequest  {
    toBeChecked: TServerCheckFeedbackArray;
};

export interface IConfirmFeedbackFilterResponse extends IResponse {
    confirmedInteractions: TArrayID;
}



