import { Location } from '@angular/common';
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { constant, TGlobalUID } from "@colmeia/core/src/business/constant";
import { Group } from "@colmeia/core/src/business/group";
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { Serializable } from "@colmeia/core/src/business/serializable";
import { SmallSerializable } from "@colmeia/core/src/business/small-serializable";
import { ETypeOfContact, TCheckedContactArray } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { EDelivery360Action, IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import {
    EAttendantStatus,
    EConversationStatusCC,
    EGetServiceOpenStatus,
    IAttendeesServiceRequest,
    IAttendeesServiceResponse,
    IFinishChatService,
    IServiceChatInitiatedInteractionJSON,
    TAttendentStatusChangeLogArray
} from "@colmeia/core/src/comm-interfaces/service-group-interfaces";
import { allSeverityRegistry } from "@colmeia/core/src/core-constants/tracker-qualifiers";
import { ESCode } from "@colmeia/core/src/error-control/error-definition";
import { IGeneralFormAnswer, TGeneralFormServerAnswerArray } from "@colmeia/core/src/general-form/general-form-answer";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { StartServiceChat } from "@colmeia/core/src/interaction/service-group/start-service-chat";
import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import {
    ApiRequestType,
    apiRequestType,
    EAttendanceIslandRequest,
    EAttendentRequest
} from "@colmeia/core/src/request-interfaces/message-types";
import { getRootInteractionFromInteraction, interactionReadableMessage } from "@colmeia/core/src/rules/interaction-filter";
import { checkIfWillPlayNotRespondAlert, getIDConversationFromThread, getSocialCCThreadId } from "@colmeia/core/src/rules/thread-conversation-functions";
import { IOldestRecentInteractionInfo, matchInactivityAlertFor } from '@colmeia/core/src/shared-business-rules/attendance-island/attedance-helpers';
import {
    EIntegrationAllocationType,
    IAttEngagementThresholds,
    IIslandAttEngagement,
    IServiceIslandServer,
    TIAttEngagementThresholdsArray,
    TServiceIslandArray
} from "@colmeia/core/src/shared-business-rules/attendance-island/attendance-island";
import {
    IFinishAllAttServiceRequest,
    IInitIslandTransferRequest,
    IReturnServiceToQueueRequest
} from "@colmeia/core/src/shared-business-rules/attendance-island/attendance-island-requests";
import { TAgentAllowedActiveChannel } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/active-cc";
import {
    IAgentLoginLogout, IAttendanceStatsRequest,
    IAttendanceStatsResponse,
    IGetAttendentStatusRequest,
    IGetAttendentStatusResponse,
    IGetStatusChangeLogReqest,
    IGetStatusChangeLogResponse,
    IStopServiceRequestWatchDog, IUpdateAgentStatusRequest, TBroadcastIslandControl
} from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp";
import {
    IAttendentServicePackClient,
    IMacroPackageServer,
    IPresenceStatusServer
} from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack";
import { IServerColmeiaTag, TColmeiaTagArray } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { addFieldToHash, TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { ENotificationPayload, INotificationCustomerTransferedBySupervisor } from "@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model";
import { getSNFromNS } from '@colmeia/core/src/shared-business-rules/non-serializable-id/ns-client-functions';
import { getMetadataNameByTypeOfContact, getTypeOfContact } from '@colmeia/core/src/shared-business-rules/social-cc/social-cc-rules';
import { delivery360ConfigDB } from '@colmeia/core/src/shared-business-rules/social-cc/social-cc.config';
import { IAPIExecutionInjectionClientBasic } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { getDefaultICustomUserFunctionParams } from '@colmeia/core/src/shared-business-rules/user-function/user-function-utils';
import { intSupportGroupsFeatureID } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { secToMS } from "@colmeia/core/src/time/time-utl";
import {
    arrayUnique,
    isEqual, isInvalid, isInvalidArray, isValidArray, isValidNumber, isValidRef, isValidString, keys, objectShallowReplace, throwPropagateErrorField, values
} from "@colmeia/core/src/tools/utility";
import { TDeepMap } from '@colmeia/core/src/tools/utility-types';
import { TNotificationDialogConfig } from "app/components/notifications-dialog/notification-dialog.model";
import { NotificationDialogRef } from 'app/components/notifications-dialog/notification-dialog.ref';
import { NotificationDialogService } from 'app/components/notifications-dialog/notification-dialog.service';
import { AttendanceEvent, IAttendantLogin, StartAttendanceEvent } from "app/model/attendence.model";
import { getContrastColorFor } from 'app/model/client-utility';
import { clientConstants } from 'app/model/constants/client.constants';
import { IGeneralAnswerListItem } from "app/model/general-form.model";
import { RoutingService } from 'app/services/routing.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { environment } from "environments/environment-client";
import { BehaviorSubject, interval, Observable, ReplaySubject, Subject } from "rxjs";
import { filter, take } from 'rxjs/operators';
import { getIDConversationFromThreadInfo } from '../../../../core/src/rules/thread-conversation-functions';
import { getClock } from '../../../../core/src/tools/utility';
import { DeployedServicesService } from "../components/dashboard/dashboard-deployed-services/deployed-services.service";
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { routeID, routeList } from "../model/routes/route-constants";
import { InterfaceInfoSignal } from "../model/signal/interface-signal";
import { FinishAttendanceEvent, TransferAttendanceEvent } from './../model/attendence.model';
import { NavigatorServices } from "./controllers-services/navigator/navigator.service";
import { SafeMenuBuilderServices } from "./controllers-services/security-controller/safe-menu-builder";
import {
    IDeployedFeature,
    TDeployFeatures,
    TFeatureArray
} from "./controllers-services/tool-bar-controller/tool-bar.service";
import { DashboardMacroService } from "./dashboard-macro.service";
import { GeneralFormService } from './general-form.service';
import { GlobalWarningService } from "./global-warning.service";
import { InteractionPersistorServices } from "./interaction-persistor.service";
import { LookupService, TNSHashCacheImplementation } from "./lookup.service";
import { NewNotificationsService } from "./new-notifications.service";
import { RequestBuilderServices } from "./request-builder.services";
import { ServerCommunicationService } from "./server-communication.service";
import { SessionService } from "./session.service";
import { SignalPublisherService } from "./signal/signal-publisher";
import { SoundsNotificationService } from "./sounds-notification.service";
import { getNoDDDPhone } from '@colmeia/core/src/shared-business-rules/social-cc/config-cell';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { ICustomEventsServer } from '@colmeia/core/src/shared-business-rules/attendance-island/custom-events';
import { OldestRecentInteractionService } from './oldest-recent-interaction.service';
import { IDeliveryTarget } from '@colmeia/core/src/core-constants/types';

export interface IRegistreService {
    interaction: IServiceChatInitiatedInteractionJSON;
    idIsland?: TGlobalUID
    onService: boolean;
    macroPackage?: string;
    allComputedValues?: TComputedInfo;
    attData?: IAPIExecutionInjectionClientBasic;
    formAnswers: TGeneralFormServerAnswerArray;
    fromSocket: boolean;
}

interface ILoginLogoutOptions {
    isLogin: boolean;
    idExternalAvatar?: string;
}

interface IMacroHash {
    [idMacro: string]: IAttendentServicePackClient;
}

interface IIslandHash {
    [idIsland: string]: IServiceIslandServer;
}

type TThresholdDB = {
    [idGroup: string]: TThresholdDBItem
}

type TThresholdNotificationDialog = NotificationDialogRef<{ attThreshold: IAttEngagementThresholds }>;

type TThresholdDBItem = {
    threshold?: IAttEngagementThresholds,
    playSound?: boolean,
    nextVisualFeedbackExecutionClockTick?: number,
    lastAudioFeedbackExecutionClockTick?: number,
    firstNotificationPlayed?: boolean,
    notificationDialogRef?: TThresholdNotificationDialog,
};

export type TThresholdStreamItem = { idGroup: string, threshold: IAttEngagementThresholds, playSound: boolean, runVisualFeedback: boolean };

@Injectable({
    providedIn: 'root'
})
export class AttendanceService {

    public readonly notAllowedMimeTypes: Readonly<string[]> = [EMimeTypes.mpeg];
    private readonly availableOpenActiveCallContactTypes: ETypeOfContact[] = [
        ETypeOfContact.email, ETypeOfContact.mobile
    ];

    private mapCustomerDoesNotAnswerCount: TDeepMap<[idGroup: string, count: number]> = new Map();
    private dashboard: DashboardMacroService;
    private api: ServerCommunicationService;
    private rbs: RequestBuilderServices;
    private session: SessionService;
    private deployedSvc: DeployedServicesService;
    private publisher: SignalPublisherService;
    private lookupSvc: LookupService;
    private internalSupportGroups: Array<string> = [];
    private isExternalCRM: boolean = false;
    private navigator: NavigatorServices;
    private generalFormSvc: GeneralFormService;
    public notificationSvc: NewNotificationsService;
    private routingSvc: RoutingService

    /* STATE */
    private _presences: { [key: string]: IPresenceStatusServer } = {};
    private islandDB: IIslandHash = {};
    private macroHash: IMacroHash = {};
    private attendanceDB: Map<string, IRegistreService> = new Map();
    private _isLoggedIn: boolean = false;
    private _loginId: string = undefined;
    private _status: EAttendantStatus = undefined; //EAttendantStatus.absent;
    private _presenceID: string = null;
    private interactionPersistor: InteractionPersistorServices;
    private agentInSocialNetworkMap = {}
    private isLoggedIn$: BehaviorSubject<IAttendantLogin> = new BehaviorSubject({ isAttendantLoggedIn: false })
    private formAnswersList: IGeneralAnswerListItem[] = [];
    private _lastStatusClocktick: number = 0;
    private inactivityTresholdDB: TThresholdDB = {};
    private inactivitySoundLoop: { audio?: HTMLAudioElement, threshold?: IAttEngagementThresholds } = {};

    public statusUpdate = new Subject();
    private _thresholdStream$: Subject<TThresholdStreamItem> = new Subject();

    public thresholdStream$(): Observable<TThresholdStreamItem> {
        return this._thresholdStream$.asObservable();
    }

    private _events$: Subject<AttendanceEvent> = new ReplaySubject(1);
    public get events$(): Observable<AttendanceEvent> { return this._events$.asObservable() };

    private _newAttendance$: Subject<IServiceChatInitiatedInteractionJSON> = new Subject();
    public newAttendance$(): Observable<IServiceChatInitiatedInteractionJSON> {
        return this._newAttendance$.asObservable();
    }

    private _broadcastIslandControl$ = new BehaviorSubject<TBroadcastIslandControl>({});
    public broadcastIslandControl$ = this._broadcastIslandControl$.asObservable();

    public readonly menuRoute: string = "/" + routeList.menu.path;
    public variablesHashCache: TNSHashCacheImplementation<IServerColmeiaTag>;

    public hasEventBeenEmitted: Set<string> = new Set();

    constructor(
        private lookup: LookupService,
        private warning: GlobalWarningService,
        private soundsSVC: SoundsNotificationService,
        private notificationDialogSvc: NotificationDialogService,
        private location: Location,
        private router: Router,
        private snackMsg: SnackMessageService,
        private oldestRecentInteractionSvc: OldestRecentInteractionService,
    ) {
        this.setupAttendanceInactivityTimer();
    }

    isCurrentUserAnAgentLoggedInAndInteractionDoesNotBelongToMe(interaction: IInteractionJSON) {
        const interactionType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType);
        const idConversation = getIDConversationFromThread(interaction)
        const conversationFromReceivedInteraction = this.getAttendanceRegistryByIdConversation(idConversation)

        const isStartmessageNOTSentToThisAgent = this.isAttending()
            && interactionType.isStartServiceChat()
            && interaction.participant.idAvatar !== this.session.getAvatarID()
        const isMessageFromClientNotDirectedToCurrentAgent =
            this.isAttending()
            && (interactionType.isCitation() || interactionType.isFinishServiceChat())
            && !isValidRef(conversationFromReceivedInteraction)

        return isStartmessageNOTSentToThisAgent || isMessageFromClientNotDirectedToCurrentAgent
    }

    setNotificationService(notificationSvc: NewNotificationsService) {
        this.notificationSvc = notificationSvc;
        this.setupNotificationListeners();
    }

    private setupNotificationListeners() {

        /**
         * Transferência pela supervisão
         */
        this.notificationSvc.notifications$.pipe(filter(ev => ev.type === ENotificationPayload.customerTransferedBySupervisor)).subscribe((ev: INotificationCustomerTransferedBySupervisor) => {
            const attRegistry = this.getAttendanceRegistryByIdConversation(ev.content.idConversation);

            if (!isValidRef(attRegistry)) return;

            this.initAttendanceTransfer(
                attRegistry.interaction.idGroup,
                'Transferindo atendimento...'
            )
        });

        /**
         * Alteração de status pela supervisão
         */
        this.notificationSvc.notifications$.pipe(filter(ev => ev.type === ENotificationPayload.statusChangedBySupervision)).subscribe(() => {
            this.setLastStatusClocktick(Date.now());
        });
    }

    private setupAttendanceInactivityTimer() {
        interval(secToMS(10)).subscribe(() => {
            this.matchAttendancesInactivityThresholds();
        });

        this.notificationDialogSvc.itemClosed
            .pipe<TThresholdNotificationDialog>(filter((ref: TThresholdNotificationDialog) => !!ref.config.data?.attThreshold))
            .subscribe(() => {
                this.inactivitySoundLoop.audio?.pause();
                this.inactivitySoundLoop.audio = undefined;
                this.inactivitySoundLoop.threshold = undefined;
            })
    }

    private matchAttendancesInactivityThresholds() {
        for (const [idGroup, attendanceEntry] of this.attendanceDB) {
            this.defineAlertStateForAttendance(idGroup, attendanceEntry);
        }
    }

    public hasAnyBroadcastIsland(): boolean {
        return values(this.islandDB).some(i => i.allocationType === EIntegrationAllocationType.Broadcast);
    }

    private getIslandEngagementConfig(idGroup: string): IIslandAttEngagement {
        const islandId: string = this.attendanceDB.get(idGroup)?.idIsland;
        const island: IServiceIslandServer = this.islandDB[islandId];
        return island?.attEngagmentConfig;
    }

    private defineAlertStateForAttendance(idGroup: string, registerService: IRegistreService) {
        const attendantIdAvatar: string = this.session.getParticipant(idGroup, true)?.getAvatar()?.getPrimaryID();
        const { interaction: interactionJSON } = registerService;

        if (!isValidString(interactionJSON?.primaryID))
            return;

        const inactivityAlertThresholds: TIAttEngagementThresholdsArray = this.getIslandEngagementConfig(idGroup)?.thresholds;
        const allowAttEngagmentConfig = this.getIslandForGroup(idGroup)?.allowAttEngagmentConfig;

        if (!allowAttEngagmentConfig || isInvalidArray(inactivityAlertThresholds))
            return;

        const interaction: Interaction = this.oldestRecentInteractionSvc.getInteractionFromCacheByJSON(interactionJSON);
        const hasInteractionInCache = isValidRef(interaction);

        const oldestRecentInteractionInfo = this.oldestRecentInteractionSvc.getOldestRecentInteractionInfoFromJSON(interactionJSON);
        const hasOldestRecentInteractionInfo = isValidRef(oldestRecentInteractionInfo);

        const hasInteractionOriginInfo = hasOldestRecentInteractionInfo && isValidRef(oldestRecentInteractionInfo.isMessageSentByHumanAttendant);

        if (!hasInteractionInCache && !hasInteractionOriginInfo && !registerService.fromSocket)
            return; // Não esta em cache, nem no oldestRecentService, e não entrou via Socket

        let interactionData: Interaction | IInteractionJSON | IOldestRecentInteractionInfo = hasInteractionInCache ? interaction : interactionJSON;
        if (hasOldestRecentInteractionInfo)
            interactionData = oldestRecentInteractionInfo;

        const thresholdConfig: IAttEngagementThresholds =
            matchInactivityAlertFor(interactionData, attendantIdAvatar, inactivityAlertThresholds);

        this.runAudioVisualFeedbackForAttendanceInactivity(interactionJSON, thresholdConfig);
    }


    private runAudioVisualFeedbackForAttendanceInactivity(interaction: IServiceChatInitiatedInteractionJSON, threshold?: IAttEngagementThresholds) {
        const idGroup: string = interaction.idGroup;
        const island: IServiceIslandServer = this.islandDB[interaction.idIsland];
        const { useVisualFeedback, visualFeedbackIntervalSeconds } = island.attEngagmentConfig;

        if (!(idGroup in this.inactivityTresholdDB)) {
            this.inactivityTresholdDB[idGroup] = {
                threshold,
                nextVisualFeedbackExecutionClockTick: 0,
                lastAudioFeedbackExecutionClockTick: 0,
            };
        }

        const entry: TThresholdDBItem = this.inactivityTresholdDB[idGroup];
        const isNewMatch = !isEqual(entry.threshold, threshold);
        const pluckSoundDurationMS: number = secToMS(2);
        let playSound: boolean = isValidString(threshold?.sound) && isNewMatch && entry.lastAudioFeedbackExecutionClockTick < Date.now() - pluckSoundDurationMS;

        if (isNewMatch && threshold?.showNotificationDialog) {
            this.showNotificationForThreshold(interaction, threshold);
        }

        if (isNewMatch && threshold?.soundLoop && threshold?.showNotificationDialog && entry.notificationDialogRef) {

            if (this.inactivitySoundLoop.threshold?.untilMinutes < threshold.untilMinutes) {
                this.inactivitySoundLoop.threshold = undefined;
                this.inactivitySoundLoop.audio.pause();
                this.inactivitySoundLoop.audio = undefined;

            }

            if (!this.inactivitySoundLoop.audio) {
                this.inactivitySoundLoop.threshold = threshold;
                this.inactivitySoundLoop.audio = this.soundsSVC.playLoop(threshold.sound);
            }

            playSound = false;
        }

        entry.threshold = threshold;

        if (playSound) {
            this.soundsSVC.playSoundWithDebounce(threshold.sound, 100);
            entry.lastAudioFeedbackExecutionClockTick = Date.now();
        };

        const { nextVisualFeedbackExecutionClockTick } = entry;
        const runVisualFeedback: boolean = threshold && useVisualFeedback && ((nextVisualFeedbackExecutionClockTick < Date.now()) || isNewMatch);

        this._thresholdStream$.next({ idGroup, threshold, playSound, runVisualFeedback });

        if (runVisualFeedback) {
            entry.nextVisualFeedbackExecutionClockTick = Date.now() + secToMS(visualFeedbackIntervalSeconds);
        }

    }

    public async showNotificationForThreshold(
        interaction: IServiceChatInitiatedInteractionJSON,
        threshold: IAttEngagementThresholds,
    ) {
        const entry: TThresholdDBItem = this.inactivityTresholdDB[interaction.idGroup];
        const { color } = threshold;
        const islandName: string = this.islandDB[interaction.idIsland]?.nName;
        const clientName: string = Serializable.getJText(interaction.participant.avatar, constant.serializableField.name);

        const config: TNotificationDialogConfig<{ attThreshold: IAttEngagementThresholds }> = {
            icon: { matIcon: 'priority_high' },
            label: islandName && `[b]${islandName}[/b]`,
            data: { attThreshold: threshold },
            message: `Atendimento sem resposta: [b]${clientName}[/b].`,
            customStyle: {
                container: {
                    background: color,
                    color: getContrastColorFor(color),
                }
            },
            mute: true,
            mainAction: () => {
                entry.notificationDialogRef.close();
                this.navigator.navigateToGroupID(interaction.idGroup, routeID.groups.chat);
            }
        }

        if (entry.notificationDialogRef) {
            this.notificationDialogSvc.replace(entry.notificationDialogRef, config);
        } else {
            entry.notificationDialogRef = this.notificationDialogSvc.open(config);
        }

        entry.notificationDialogRef.closed.pipe(take(1)).subscribe(() => {
            entry.notificationDialogRef = undefined;
        });
    }

    public setCustomerDoesNotAnswerCountByGroup(idGroup: string, amount: number) {
        return this.mapCustomerDoesNotAnswerCount.set(idGroup, amount)
    }
    public getCustomerDoesNotAnswerCountByGroup(idGroup: string) {
        return this.mapCustomerDoesNotAnswerCount.get(idGroup)
    }
    public resetCustomerDoesNotAnswerCountByGroup(idGroup: string) {
        return this.mapCustomerDoesNotAnswerCount.set(idGroup, 0)
    }

    public setRoutingService(routingSvc: RoutingService) {
        this.routingSvc = routingSvc;
    }

    public removeAttendanceOfGroup(idGroup: TGlobalUID): void {
        this.attendanceDB.delete(idGroup);
        this.resetCustomerDoesNotAnswerCountByGroup(idGroup);

    }

    public isOnline(): boolean {
        return this.currentStatus === EAttendantStatus.online;
    }

    public isAttending(): boolean {
        return this.currentStatus === EAttendantStatus.online && this.hasAnyConversation();
    }

    public isCurrentAgentCanReceiveMessage(): boolean {
        return this.currentStatus === EAttendantStatus.resume
            || this.currentStatus === EAttendantStatus.online
            || this.currentStatus === EAttendantStatus.absent
            || this.currentStatus === EAttendantStatus.ocuppied
    }

    public resetAllState() {
        this._isLoggedIn = false;
        this._loginId = undefined;
        this._presences = {};
        this.islandDB = {};
        this.macroHash = {};
        this.attendanceDB.clear();
    }

    public setInteractionPersistor(interactionPersistor: InteractionPersistorServices): void {
        this.interactionPersistor = interactionPersistor;
    }

    get presences(): IPresenceStatusServer[] {
        return Object.values(this._presences);
    }

    get currentStatus(): EAttendantStatus {
        return this._status === EAttendantStatus.resume
            ? EAttendantStatus.online
            : this._status;
    }

    get currentPresence(): IPresenceStatusServer {
        return (this.currentStatus === EAttendantStatus.resume)
            ? null
            : this._presences[this._presenceID] || null;
    }

    get currentPresenceSec(): IPresenceStatusServer {
        return this._presences[this._presenceID];
    }

    public getAllServiceInteractions(): IServiceChatInitiatedInteractionJSON[] {
        return [...this.attendanceDB.values()].map(item => item.interaction);
    }

    public hasAnyConversation(): boolean {
        return [...this.attendanceDB.values()].some(item => item.onService);
    }

    async synchronizeStatus(): Promise<void> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetAttendentStatusRequest = {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.attendent.getAvatarStatus, this.session.getSelectedGroupID()),
            idAvatarAgent: this.session.getAvatarID(),
        };

        let clientResponse: ClientInfraResponse;
        try {
            clientResponse = await this.api.managedRequest(infra, request);

            if (!clientResponse.executionOK) {
                this.snackMsg.openError('Ocorreu um erro ao tentar carregar seu status.');
                return;
            }

            const normalizedResponse: IGetAttendentStatusResponse = <IGetAttendentStatusResponse>clientResponse.response;

            if (isInvalid(normalizedResponse.presences)) {
                return;
            }

            for (const presence of normalizedResponse.presences) {
                this._presences[presence.idNS] = presence;
            }

            this._isLoggedIn = normalizedResponse.login.loggedIn;
            this.loggedDataEmmiter({ isAttendantLoggedIn: this.isLoggedIn })
            this._loginId = normalizedResponse.login.loginId;
            this._status = normalizedResponse.login.status;
            this._presenceID = normalizedResponse.login.presenceId;
            this.upsertIslands(normalizedResponse.islands);
            if (isValidRef(normalizedResponse.login.broadCastIslands)) {
                this._broadcastIslandControl$.next(normalizedResponse.login.broadCastIslands);
            }

            if (!isValidNumber(this._lastStatusClocktick, 0)) {
                this._lastStatusClocktick = Date.now();
            }

            this.matchAttendancesInactivityThresholds();
            this.statusUpdate.next();
        } catch (err) {
            throwPropagateErrorField(
                ESCode.server1.fromServer,
                ESCode.server1.f.genericError,
                err,
                'isInAttendance', [
                err.message,
                err.stack
            ]
            );
        }
    }


    public async getAttendanceStats(initClock: number, endClock: number): Promise<IAttendanceStatsResponse> {
        if (this.isLoggedIn) {
            const request: IAttendanceStatsRequest = {
                ...this.rbs.secureBasicRequest(EAttendentRequest.stats),
                idAgentLogin: this._loginId,
                initClock,
                endClock
            };
            const response = await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);
            const normalizedResponse: IAttendanceStatsResponse = response.response as IAttendanceStatsResponse;

            if (normalizedResponse?.login.broadCastIslands) {
                this._broadcastIslandControl$.next(normalizedResponse?.login.broadCastIslands);
            }

            return normalizedResponse;
        }
    }

    private async promptYesOrNo(title: string, message: string): Promise<boolean> {
        return this.warning.promptYesOrNo(title, message);
    }

    private getInfra(): IInfraParameters {
        return this.rbs.getContextNoCallBackSpinnningParameters();
    }

    private async runIfConfirmed(title: string, message: string, callback: () => Promise<void>) {
        const confirm: boolean = await this.promptYesOrNo(title, message);
        if (confirm) {
            await callback();
        }
        return confirm;
    }

    public isAttendingOnGroup(idGroup: string): boolean {
        return this.attendanceDB.get(idGroup)?.onService;
    }


    public isMyServiceInteractionJSON(receivedInteractionJSON: IInteractionJSON): boolean {
        let result = false
        const isStartAttendenceInteraction = receivedInteractionJSON.idInteractionType === constant.interactionType.serviceGroup.startServiceChat
        if (isStartAttendenceInteraction) {
            result = receivedInteractionJSON.participant.idAvatar === this.session.getAvatarID()
        } else {
            const isSomeServiceMine: boolean = this.getAllReplyServiceInteractionList()
                .some((idReplyInteraction: string) => {
                    const rootInteractionId: TGlobalUID = getRootInteractionFromInteraction(receivedInteractionJSON)
                    const isInteractionSentForThisAttendence: boolean = rootInteractionId == idReplyInteraction
                    const isMyServiceInt: boolean = this.isAttendingOnGroup(receivedInteractionJSON.idGroup) && isInteractionSentForThisAttendence

                    return isMyServiceInt
                });

            result = isSomeServiceMine
        }

        return result
    }

    public promptLogin(isExternal: boolean) {
        return this.warning.promptYesOrNo(
            Serializable.getTranslation(gTranslations.attendance.promptLoginTitle),
            isExternal ? '' : Serializable.getTranslation(gTranslations.attendance.promptLoginMessage)
        );
    }

    public promptLogout(isExternal: boolean) {
        return this.warning.promptYesOrNo(
            Serializable.getTranslation(gTranslations.attendance.promptLogoutTitle),
            isExternal ? '' : Serializable.getTranslation(gTranslations.attendance.promptLogoutMessage),
        );
    }

    //

    public realTimeLoginLogout(
        options: ILoginLogoutOptions
    ) {
        return this.loginLogout(options, apiRequestType.attendent.loginLogoutRealTime);
    }

    public async loginLogout({ isLogin, idExternalAvatar }: ILoginLogoutOptions, type: ApiRequestType = apiRequestType.attendent.login) {
        const isExternalAgent = !!idExternalAvatar;

        const isConfirmed = isLogin
            ? await this.promptLogin(isExternalAgent)
            : await this.promptLogout(isExternalAgent)
            ;

        if (!isConfirmed) return false;

        const response = await this.api.sendRequest<IAgentLoginLogout>(type)({
            idAgentAvatar: idExternalAvatar ?? this.session.getAvatarID(),
            isLogin
        });

        if (!isExternalAgent) {
            if (isLogin) this.setLastStatusClocktick(Date.now());
            await this.synchronizeStatus();
        }
        return isValidRef(response);
    }

    public async login(): Promise<boolean> {
        return this.loginLogout({
            isLogin: true,
        });
    }

    public async logout(): Promise<boolean> {
        return this.loginLogout({
            isLogin: false,
        });
    }

    public async returnAllCasesToQueue(): Promise<void> {
        await this.runIfConfirmed(
            Serializable.getTranslation(gTranslations.attendance.promptReturnQueueTitle),
            '',
            async () => {
                return this.returnAllAgentCasesToQueue(this.session.getSelectedAvatarID());
            }
        );
    }

    public async returnAllAgentCasesToQueue(idAvatarAgent: string) {
        const request: IReturnServiceToQueueRequest = {
            ...this.rbs.secureBasicRequest(EAttendentRequest.returnToQueue),
            idServiceRequest: [],
            allFromAvatar: true,
            idAvatarAgent,
            status: EConversationStatusCC.returnToQueue,
        };
        await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);
    }

    public async finishAllAgentCases(idAvatarAgent: string) {
        const request: IFinishAllAttServiceRequest = {
            ...this.rbs.secureBasicRequest(EAttendentRequest.finishAllAttendance),
            idServiceRequest: [],
            idAvatarAgent,
            status: EConversationStatusCC.finishBySupervisor,
        };
        await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);
    }

    private getStatusName(status: EAttendantStatus, presence?: string): string {
        return isValidRef(presence)
            ? this._presences[presence].nName
            : Serializable.getTranslation(gTranslations.attendance[status]);
    }

    public async changeStatusForAttendant(status: EAttendantStatus, presence?: string): Promise<void> {
        await this.runIfConfirmed(Serializable.getTranslation(gTranslations.attendance.promptChangeStatusTitle), Serializable.getTranslation(gTranslations.attendance.promptChangeStatusMessage) + this.getStatusName(status, presence), async () => {
            await this.changeStatusForAttendantWithoutConfirmation(this.session.getSelectedAvatarID(), status, presence);
        });
    }

    public async changeStatusForAttendantWithoutConfirmation(
        idAvatarAgent: string,
        status: EAttendantStatus,
        presence?: string,
        currentStatus: EAttendantStatus = this._status
    ) {
        const request: IUpdateAgentStatusRequest = {
            ...this.rbs.secureBasicRequest(EAttendentRequest.changeStatus),
            upd: {
                idAvatarAgent,
                status,
                currentStatus,
                idPresenceStatus: presence,
            }
        };
        const response: ClientInfraResponse = await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);

        if (response.executionOK) {
            this._lastStatusClocktick = Date.now();
            await this.synchronizeStatus();
        }
    }

    public setNavigatorServiceDependency(nav: NavigatorServices): void {
        this.navigator = nav;
    }

    public setDashboardMacroServiceDependency(dash: DashboardMacroService): void {
        this.dashboard = dash;
    }

    public setRequestBuilderDependency(rbs: RequestBuilderServices): void {
        this.rbs = rbs;
    }

    public setComunnicationServiceDependency(api: ServerCommunicationService): void {
        this.api = api;
    }

    public setSessionService(session: SessionService): void {
        this.session = session;
    }

    public setDeployedService(deployedService: DeployedServicesService): void {
        this.deployedSvc = deployedService;
    }

    public setPublisher(publisher: SignalPublisherService): void {
        this.publisher = publisher;
    }

    public setLookupService(lookupSvc: LookupService): void {
        this.lookupSvc = lookupSvc;
        this.variablesHashCache = this.lookupSvc.createNSHashCache();
    }

    public setGeneralFormSvc(generalFormSvc: GeneralFormService): void {
        this.generalFormSvc = generalFormSvc;
    }

    public setExternalCRM() {
        this.isExternalCRM = true;
    }

    public getIsExternalCRM(): boolean {
        return this.isExternalCRM;
    }

    public getAllowedActiveChannels(idGroup: string): TAgentAllowedActiveChannel {
        const attendantServicePack: IAttendentServicePackClient = this.getAttendentServicePackForGroup(idGroup);
        return attendantServicePack && attendantServicePack.allowedActiveChannel;
    }
    public getAllowedActiveChannelsForCurrentGroup(): TAgentAllowedActiveChannel {
        return this.getAllowedActiveChannels(this.session.getSelectedGroupID());
    }

    private upsertIslands(islands: TServiceIslandArray): void {
        for (const island of islands) {
            this.islandDB[island.idNS] = island;
        }
    }

    public resetAgentInSocialNetworkState(): void {
        this.agentInSocialNetworkMap = {}
    }

    public isPlayerAnAgentInSocialNetwork(socialNetworkID: TGlobalUID): boolean {
        return this.agentInSocialNetworkMap[socialNetworkID]
    }

    public setIsPlayerAsAgentInSocialNetwork(socialNetworkID: TGlobalUID, isAgent: boolean) {
        this.agentInSocialNetworkMap[socialNetworkID] = isAgent
    }

    public isPlayerAnAgentInCurrentSocialNetwork(): boolean {
        const currentSocialNetworkID = this.session.getCurrentSocialNetworkID()
        return this.isPlayerAnAgentInSocialNetwork(currentSocialNetworkID)
    }

    public setIsPlayerAsAgentInCurrentSocialNetwork(isAgent: boolean) {
        const currentSocialNetworkID = this.session.getCurrentSocialNetworkID()
        this.setIsPlayerAsAgentInSocialNetwork(currentSocialNetworkID, isAgent)
    }

    public isReadyToAttend(): boolean {
        return this.isExternalCRM || this._isLoggedIn;
    }

    public getLastLoginEventData(): IAttendantLogin {
        return this.isLoggedIn$.value
    }

    private loggedDataEmmiter(attendantLogin: IAttendantLogin) {
        this.isLoggedIn$.next(attendantLogin)
    }

    public loggedDataListener(): Observable<IAttendantLogin> {
        return this.isLoggedIn$.asObservable()
    }

    async getStatusChangeLogs(cursor: string = null): Promise<TAttendentStatusChangeLogArray> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetStatusChangeLogReqest = {
            ...this.rbs.secureBasicRequest(apiRequestType.attendent.getStatusChangeLogs),
            idAvatarAgent: this.session.getAvatarID(),
            cursor,
        };
        let clientResponse: ClientInfraResponse;
        try {
            clientResponse = await this.api.managedRequest(infra, request);
            return (<IGetStatusChangeLogResponse>clientResponse.response).logs;
        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getStatusChangeLogs');
        }
    }

    getReplyInteractionForGroup(idGroup: TGlobalUID): TGlobalUID {
        return this.attendanceDB.get(idGroup)?.interaction.primaryID;
    }

    getAllReplyServiceInteractionList(): Array<TGlobalUID> {
        return [...this.attendanceDB.values()].map(item => item.interaction.primaryID)
    }

    getCommunicationGroups(): string[] {
        return Object.values(this.islandDB)
            .map(island => island.idISlandCommunicationGroup)
            .filter(id => isValidRef(id));
    }

    getCommunicationGroupForAttendance(idAttendanceGroup: string): string {
        const att = this.attendanceDB.get(idAttendanceGroup);
        if (!att) return;

        const idIsland = att.idIsland;
        const commID = this.islandDB[idIsland].idISlandCommunicationGroup;
        return commID;
    }

    isAllowedToSendAudioMessages(idGroup: string): boolean {
        const grp = this.attendanceDB.get(idGroup);
        return isValidRef(grp) && isValidRef(this.islandDB[grp.idIsland]) && this.islandDB[grp.idIsland].accessibility.enableAudioMessage;
    }

    isVisibleGroupDuringAttendance(idGroup: TGlobalUID): boolean {
        const internalSupportGroups: string[] = this.getInternalSupportGroups();
        const communicationGroups: string[] = this.getCommunicationGroups();

        return (
            this.attendanceDB.has(idGroup) ||
            (!environment.production && internalSupportGroups.includes(idGroup)) ||
            communicationGroups.includes(idGroup)
        );
    }

    private setAsStart(interaction: IServiceChatInitiatedInteractionJSON, fromSocket: boolean): void {
        this.upsertAttendanceEntry(interaction.idGroup, {
            onService: true,
            interaction: interaction,
            idIsland: interaction.idIsland,
            macroPackage: interaction.idMacroPackage,
            fromSocket,
        });
    }

    private upsertAttendanceEntry(idGroup: string, data: Partial<IRegistreService>) { // seta o nome do atendente
        this.attendanceDB.set(idGroup, {
            ...this.attendanceDB.get(idGroup),
            ...data,
        });
    }

    private async downloadServicePack(interaction: IServiceChatInitiatedInteractionJSON): Promise<IMacroPackageServer> {
        return this.dashboard.getMacros(interaction.idMacroPackage);
    }

    private async fetchIslands(idIslands: string[]): Promise<void> {
        const islandsToLoad: string[] = idIslands.filter(id => !(id in this.islandDB));

        if (isInvalidArray(islandsToLoad)) {
            return;
        }

        const nsers = await this.lookup.getBatchNonSerializables<IServiceIslandServer[]>(islandsToLoad);

        for (const nser of nsers) {
            this.islandDB[nser.idNS] = nser;
        }
    }

    hasIsland(id: string): boolean {
        return !!this.islandDB[id];
    }

    async onServiceChatInitializedBatch(interactions: IServiceChatInitiatedInteractionJSON[]): Promise<void> {
        interactions = interactions.filter(inter => inter.isOpenCase);

        if (this.oldestRecentInteractionSvc.shouldUpdate(interactions))
            this.oldestRecentInteractionSvc.fetchOldestRecentInteractionsInfo(interactions);

        const alreadyLoadedMacros: string[] = keys(this.macroHash);
        const queryAbleMacros: string[] = arrayUnique(
            interactions
                .filter((i) => isValidString(i.idMacroPackage) && !alreadyLoadedMacros.includes(i.idMacroPackage))
                .map(inter => inter.idMacroPackage)
        );

        let macros: IMacroPackageServer[] = [];

        if (isValidArray(queryAbleMacros)) {
            macros = await this.dashboard.getMacrosArray(queryAbleMacros);
            for (const macro of macros) {
                this.macroHash[macro.idNS] = macro;
            }
        }

        for (const interaction of interactions) {
            if (this.isInAttendDB(interaction.idGroup)) {
                continue;
            }

            this.setAsStart(interaction, false);
            if (UberCache.testCache(interaction.idGroup)) {
                const interGroup: Group = Group.staticFactory(interaction.idGroup);
                if (isInvalid(interGroup.getLastGroupNavigatorInteractionJSON())) {
                    interGroup.setLastGroupNavigatorInteractionJSON(
                        {
                            text: interactionReadableMessage(interaction),
                            idGroup: interaction.idGroup,
                            primaryID: interaction.primaryID,
                            nameAvatar: null,
                            clockTick: interaction.clockTick,
                            idInteractionType: interaction.idInteractionType
                        }
                    )
                }
            }
            if (interaction.idMacroPackage) {
                const attendanceSvcPack: IMacroPackageServer = macros.find(macro => macro.idNS === interaction.idMacroPackage);
                if (attendanceSvcPack) {
                    for (const servGroup of attendanceSvcPack.serviceGroups) {
                        if (servGroup.idServiceGroup) {
                            const group: Group = <Group>(await this.deployedSvc.getExtendedParticipantById(servGroup.idServiceGroup));
                            if (group) {
                                this.addInternalSupportGroups(group.getBasicUniversalInfo().primaryID);
                            }
                        }
                    }
                }
            }
        }

        await this.synchronizeStatus();

        for (const interaction of interactions) {
            this.loadAllAttendanceVariablesData(interaction.idGroup);
        }
    }

    public checkIfWillPlayNotRespondAlertByInteraction(interaction: Interaction): boolean {
        if (isInvalid(interaction)) return true;
        const idGroup: string = interaction.getGroupID();

        try {
            const idAvatar: string = this.session.getParticipant(idGroup).getAvatar().getPrimaryID();
            return isValidString(idAvatar) && checkIfWillPlayNotRespondAlert(interaction, idAvatar);
        } catch {
            return false;
        }
    }

    public getNotAnsweredTime(interaction: Interaction): number {
        if (isInvalid(interaction)) return 0;
        const isUnanswered: boolean = this.checkIfWillPlayNotRespondAlertByInteraction(interaction);
        return isUnanswered ? interaction.getClockTick() : -1;
    }

    async onServiceChatInitialized(interaction: IServiceChatInitiatedInteractionJSON): Promise<void> {
        // ATENÇÃO. A comparação do idAgentAvatar é realizada pois podem entrar dois atendimentos para um mesmo
        // grupo (cliente) vindos de canais distintos e portanto aparecerá atendimentos distintos para dois atendentes
        if (!interaction.isOpenCase || interaction.idAgentAvatar !== this.session.getAvatarID()) {
            return;
        }

        const idConversation = getIDConversationFromThreadInfo(interaction.threadInfo);
        try {
            this._events$.next(
                new StartAttendanceEvent(interaction.idGroup, 'start', '')
            );

            if (this.isInAttendDB(interaction.idGroup)) {
                if (!this.hasEventBeenEmitted.has(idConversation)) {
                    this.hasEventBeenEmitted.add(idConversation);
                    this._newAttendance$.next(interaction);
                }

                return;
            }

            this.setAsStart(interaction, true);
            if (isValidRef(interaction.idMacroPackage)) {

                let attendanceSvcPack: IMacroPackageServer = await this.downloadServicePack(interaction);
                if (attendanceSvcPack && attendanceSvcPack.idNS) {
                    this.macroHash[attendanceSvcPack.idNS] = attendanceSvcPack;
                    for (const servGroup of attendanceSvcPack.serviceGroups) {
                        if (servGroup.idServiceGroup) {
                            const group: Group = <Group>(await this.deployedSvc.getExtendedParticipantById(servGroup.idServiceGroup));
                            if (isValidRef(group)) {
                                this.addInternalSupportGroups(group.getBasicUniversalInfo().primaryID);
                            }
                        }
                    }
                }
            }
            if (isValidRef(interaction.idIsland)) {
                await this.fetchIslands([interaction.idIsland]);
            }

            const islandName: string = this.islandDB[interaction.idIsland]?.nName;
            const clientName: string = Serializable.getJText(interaction.participant.avatar, constant.serializableField.name);

            this.notificationDialogSvc.open({
                icon: { matIcon: 'support_agent' },
                label: islandName && `[b]${islandName}[/b]`,
                message: `Novo atendimento: [b]${clientName}[/b]`,
                duration: secToMS(15),
                customStyle: {
                    container: {
                        'maxWidth': '320px'
                    }
                },
                mainAction: (ref) => {
                    ref.close();
                    this.navigator.navigateToGroupID(interaction.idGroup, routeID.groups.chat);
                }
            });

            this.loadAllAttendanceVariablesData(interaction.idGroup);

        } catch (err) {
            this.interactionPersistor.sendErrorEvent(err, allSeverityRegistry.error, 'onServiceChatInitialized');
            throw err;
        }


        try {
            await this.stopServiceWatchDog(interaction.primaryID);
        } catch (err) {
            this.interactionPersistor.sendErrorEvent(err, allSeverityRegistry.error, 'stopServiceWatchDog');
            throw err;
        }

        this._newAttendance$.next(interaction);
        this.hasEventBeenEmitted.add(idConversation);
        this.playSoundForNewAttendance(interaction);
    }

    private playSoundForNewAttendance(interaction: IServiceChatInitiatedInteractionJSON) {
        const island: IServiceIslandServer = this.islandDB[interaction.idIsland];
        const shouldPlaySound: boolean = island.attEngagmentConfig?.beepWhenNewService;

        if (!shouldPlaySound) return;

        this.soundsSVC.customInteractionPlay(clientConstants.notification.newAttendanceSoundPath);
    }

    public getLastAttThresholdForGroup(idGroup: string): IAttEngagementThresholds {
        return this.inactivityTresholdDB[idGroup]?.threshold;
    }

    public async stopServiceWatchDog(idInteraction: string): Promise<void> {
        await this.api.doRequest<IStopServiceRequestWatchDog>(apiRequestType.stopServiceRequestWatchDog, {
            idInteraction,
        });
    }

    finishAttendanceInit(idGroup: string) {
        this._events$.next(
            new FinishAttendanceEvent(idGroup, 'start', 'Finalizando atendimento')
        );
    }

    clearAttendanceOverlay() {
        this._events$.next(undefined);
    }

    onServiceChatFinished(interaction: IFinishChatService): void {
        const idGroup: string = interaction.idGroup;

        this._events$.next(
            new FinishAttendanceEvent(idGroup, 'end', 'Atendimento finalizado')
        );

        if (idGroup === this.session.getSelectedGroupID()) {
            this.navigateToNextAvailableGroup([idGroup])
        }

        this.removeAttendanceOfGroup(idGroup);
    }

    getAttendentServicePackForCurrentGroup(): IAttendentServicePackClient {
        const idGroup: TGlobalUID = this.session.getSelectedGroupID();
        return this.getAttendentServicePackForGroup(idGroup);
    }

    getAttendentServicePackForGroup(idGroup: TGlobalUID): IAttendentServicePackClient {
        return this.macroHash?.[this.attendanceDB.get(idGroup)?.macroPackage];
    }

    public async updateAttendentServicePackForGroup(idGroup: TGlobalUID): Promise<void> {
        const attEntry = this.attendanceDB.get(idGroup);
        if (!attEntry) return;
        const idServicePack: string = attEntry.macroPackage;
        this.macroHash[idServicePack] = await this.dashboard.getMacros(idServicePack);
    }

    public isInAttendDB(idGroup: TGlobalUID): boolean {
        return this.attendanceDB.has(idGroup);
    }

    public async initAttendantState(): Promise<void> {
        const idAvatar: TGlobalUID = this.session.getSelectedAvatarID();
        const infra: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayer().getPrimaryID(),
            idAvatar
        );
        const req: IAttendeesServiceRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.serviceGroup.getService),
            idServiceAvatarID: idAvatar,
            status: EGetServiceOpenStatus.open
        };
        const response: ClientInfraResponse = await this.api.managedRequest(infra, req);
        if (response.executionOK) {
            const AttSvcResponse = response.response as IAttendeesServiceResponse;
            const unique: { [idGroup: string]: { clock: number, interaction: IServiceChatInitiatedInteractionJSON } } = {};
            const sortByClockTickDesc = (a: IServiceChatInitiatedInteractionJSON, b: IServiceChatInitiatedInteractionJSON) => a.clockTick - b.clockTick;
            const idIslands: { [idIsland: string]: true } = {};
            AttSvcResponse
                .services
                .sort(sortByClockTickDesc)
                .forEach((service: IServiceChatInitiatedInteractionJSON) => {
                    idIslands[service.idIsland] = true;
                    if (!unique[service.idGroup] || unique[service.idGroup].clock < service.clockTick) {
                        unique[service.idGroup] = {
                            clock: service.clockTick, interaction: service
                        }
                    }

                    if (isValidRef(service.participant?.avatar)) {
                        const jsonAvatar = service.participant.avatar
                        const avatar = Avatar.factoryMessage(jsonAvatar);

                        if (isValidRef(avatar)) {
                            UberCache.addUberHash(avatar);
                        }
                    }
                });

            await this.onServiceChatInitializedBatch(Object.values(unique).map(entry => entry.interaction));
            await this.fetchIslands(Object.keys(idIslands));

            this.publisher.specificSignalEmissorOnGenericStream(new InterfaceInfoSignal({ updateGroupList: true }));
        }
    }

    public async getFeatureSet(): Promise<TDeployFeatures> {
        const menuBuilder: SafeMenuBuilderServices = new SafeMenuBuilderServices(this.session.getMenuSafeBuilderData());
        const attendanceServicePack = this.getAttendentServicePackForGroup(this.session.getSelectedGroupID());

        if (isValidRef(attendanceServicePack)) {
            const groupIDs = attendanceServicePack.serviceGroups.map(servGroup => servGroup.idServiceGroup);
            const executableItems: TFeatureArray = [];

            groupIDs.map(async groupID => {
                const group = await this.deployedSvc.getExtendedParticipantById(groupID);
                if (isValidRef(group)) {
                    executableItems.push({ featureItem: new SmallSerializable(group.getBasicUniversalInfo()) });
                }
            });

            const groupsFeature: IDeployedFeature = {
                featureSet: {
                    featureItem: menuBuilder.getSerializableFeatureFromPrimaryID(intSupportGroupsFeatureID)
                },
                actionFeatures: executableItems,

            };
            return [groupsFeature];
        } else {
            return [];
        }
    }

    public getInternalSupportGroups(): Array<string> {
        return this.internalSupportGroups;
    }

    public addInternalSupportGroups(groupID: string): void {
        if (!this.getInternalSupportGroups().includes(groupID)) {
            this.getInternalSupportGroups().push(groupID);
        }
    }

    getStartServiceChatInteractionId(): string {
        return this.getReplyInteraction().getPrimaryID()
    }

    getReplyInteraction(idGroup: string = this.session.getSelectedGroupID(), throwError?: false): Interaction {
        const idInteraction: string = this.getReplyInteractionForGroup(idGroup);
        return Interaction.staticFactory(idInteraction, throwError);
    }

    canInteractionReplyToMessage(interaction: Interaction): boolean {
        const selectedGroupID = this.session.getSelectedGroupID();
        const startServiceChat = this.getReplyInteraction(selectedGroupID, false);

        if (!interaction.getInteractionAncestry().length) {
            return true;
        }

        if (!startServiceChat) {
            return true;
        }

        return startServiceChat.getPrimaryID() === interaction.getRoot().getPrimaryID()
    }

    getIdIslandForGroup(idGroup: TGlobalUID): TGlobalUID {
        return this.attendanceDB.get(idGroup)?.idIsland;
    }

    isPlayerAnAgentLoggedInInCurrentSocialNetwork(): boolean {
        return this.isPlayerAnAgentInCurrentSocialNetwork()
            && this.getLastLoginEventData().isAttendantLoggedIn
    }

    public getAttendanceRegistryByIdConversation(idConversation: string): IRegistreService {
        for (const [, registry] of this.attendanceDB) {
            const attIdConversation = getIDConversationFromThreadInfo(registry.interaction.threadInfo);

            if (attIdConversation === idConversation) {
                return registry;
            }
        }

        return undefined
    }

    public getAttendanceRegistryByIdInteraction(idStartInteraction: TGlobalUID): IRegistreService {
        for (const [, registry] of this.attendanceDB) {
            const attIdConversation = getIDConversationFromThreadInfo(registry.interaction.threadInfo);

            if (registry.interaction.primaryID === idStartInteraction) {
                return registry;
            }
        }

        return undefined
    }

    initAttendanceTransfer(idGroup: string, message: string) {
        this._events$.next(
            new TransferAttendanceEvent(
                idGroup,
                'start',
                message
            )
        );
    }

    finishAttendanceTransfer(idGroup: string, message: string) {
        this._events$.next(
            new TransferAttendanceEvent(
                idGroup,
                'end',
                message
            )
        );
    }

    async transferIsland(
        fromIslandId: string,
        toIslandId: string,
        answer: IGeneralFormAnswer,
        idTargetAgentOnIsland?: string,
    ): Promise<boolean> {
        const groupId = this.session.getSelectedGroupID();
        const interaction = this.getReplyInteraction();
        const threadId = getSocialCCThreadId(interaction);
        const idAvatar: TGlobalUID = this.session.getSelectedAvatarID();
        const infra: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayer().getPrimaryID(),
            idAvatar
        );

        this._events$.next(
            new TransferAttendanceEvent(
                groupId,
                'start',
                'Transferindo atendimento'
            )
        );

        const request: IInitIslandTransferRequest = {
            sourceIslandId: fromIslandId,
            targetIslandId: toIslandId,
            idConversation: threadId,
            answer,
            idTargetAgentOnIsland,
            ...this.rbs.secureBasicRequest(EAttendanceIslandRequest.transferIsland),
        };

        const response = await this.api.managedRequest(infra, request);

        this._events$.next(undefined);

        return response.executionOK;
    }

    async onPlayerInfoReceived(removedGroupsFromPlayer: Set<string>): Promise<void> {
        const currentGroupID: TGlobalUID = this.session.getSelectedGroupID();
        const playerInfo: PlayerCachedInfo = this.session.getPlayerInfoServices().getPlayerInfo();
        const belongsToGroup: boolean = playerInfo.belongsToGroup(currentGroupID);
        const stillAtending: boolean = this.isReadyToAttend();
        const snID: string = this.session.getCurrentSocialNetworkID();
        const belongsToSN: boolean = playerInfo.hasSocialNetwork(snID)

        if (!belongsToSN) {
            await this.navigator.navigateToGroupID(constant.entity.rootGroups.root);
        } else if (this.isPlayerAnAgentInCurrentSocialNetwork()) {
            const isVisibleGroupDuringAttendance = this.isVisibleGroupDuringAttendance(currentGroupID);
            if (stillAtending && isVisibleGroupDuringAttendance && !belongsToGroup) {
                // remove groups that does not belong anymore to player
                const idRedirectGroup: string = this.getCommunicationGroupForAttendance(currentGroupID);

                if (this.isOnChatScreen()) {
                    this.navigator.navigateToGroupID(idRedirectGroup, routeID.groups.chat);
                }

                for (const groupId of removedGroupsFromPlayer) {
                    this.removeAttendanceOfGroup(groupId)
                }
            }
            return
        } else {
            if (this.isVisibleGroupDuringAttendance(currentGroupID) && !belongsToGroup) {
                await this.navigator.navigateToGroupID(snID);
            }
        }
    }

    public async navigateToNextAvailableGroup(ignored?: string[]) {
        const currentGroupID: string = this.session.getSelectedGroupID();
        const playerInfo: PlayerCachedInfo = this.session.getPlayerInfoServices().getPlayerInfo();
        let idRedirectGroup: string = this.getCommunicationGroupForAttendance(currentGroupID);

        for (const idGroup in this.attendanceDB) {
            if (isValidArray(ignored) && ignored.includes(idGroup)) continue;

            if (idGroup !== currentGroupID && playerInfo.belongsToGroup(idGroup)) {
                idRedirectGroup = idGroup;
            }
        }

        if (isValidString(idRedirectGroup)) {
            await this.navigator.navigateToGroupID(
                idRedirectGroup,
                routeID.groups.chat
            );
        } else {
            if (this.isPlayerAnAgentInCurrentSocialNetwork()) {
                this.router.navigate(['dashboard/service-status/status']);
            } else {
                this.router.navigateByUrl(this.menuRoute);
            }
        }
    }

    get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    private getCurrentAttendance(): IRegistreService {
        const currentGroupID: string = this.session.getSelectedGroupID();
        const currentAtt: IRegistreService = this.attendanceDB.get(currentGroupID);
        return currentAtt;
    }

    public getIdConversation(): string {
        const startServiceChatInteraction = this.getInitInteractionServiceOnCurrentGroup()
        const idConversation = getSocialCCThreadId(startServiceChatInteraction);
        return idConversation
    }

    public getInitInteractionServiceOnCurrentGroup(): StartServiceChat {
        const currentGroupId = this.session.getSelectedGroupID()
        return this.getInitInteractionService(currentGroupId)
    }

    public getInitInteractionService(idGroup: string, throwError: boolean = true): StartServiceChat {
        const json = this.getInitInteractionServiceJSON(idGroup);
        return UberCache.unsafeUberFactory(json.primaryID, throwError) as StartServiceChat
    }

    public getInitInteractionServiceJSON(idGroup: string): IServiceChatInitiatedInteractionJSON {
        const json = this.attendanceDB.get(idGroup)?.interaction;
        return json;
    }

    getCurrentIsland(): IServiceIslandServer {
        const currentAtt = this.getCurrentAttendance();
        if (isInvalid(currentAtt))
            return undefined;

        return this.islandDB[currentAtt.idIsland];
    }

    getCurrentIslandId(): string | undefined {
        try {
            return this.getCurrentIsland().idNS;
        } catch { }
    }

    getIslandCommGroupId(snGroupId: string): string {
        const island: IServiceIslandServer = Object.values(this.islandDB)
            .find(island => getSNFromNS(island) == snGroupId)
        if (island) {
            return island.idISlandCommunicationGroup
        }
    }

    getIslandForGroup(groupId: string): IServiceIslandServer {
        const idIsland = this.getIdIslandForGroup(groupId);
        return this.islandDB[idIsland];
    }

    getFormAnswersList(): IGeneralAnswerListItem[] {
        return this.formAnswersList;
    }

    setFormAnswersList(list: IGeneralAnswerListItem[]) {
        this.formAnswersList = list;
    }

    get lastStatusClocktick() { return this._lastStatusClocktick; };

    setLastStatusClocktick(value: number) {
        this._lastStatusClocktick = value;
    }

    async loadAllAttendanceVariablesData(idGroup: string): Promise<void> {
        if (!this.attendanceDB.has(idGroup)) return;

        const { APIExecutionInjectionClientBasic: data, formAnswers } = await this.getAllAttendanceVariablesData(idGroup);

        this.upsertAttendanceEntry(idGroup, {
            allComputedValues: data.allFieldMap,
            attData: data,
            formAnswers
        });
    }

    public async getOrLoadAttData(idGroup: string = this.session.getSelectedGroupID()): Promise<IAPIExecutionInjectionClientBasic> {
        if (this.hasLoadedAllComputedInfoForAttendance(idGroup)) {
            return this.attendanceDB.get(idGroup)?.attData;
        }

        await this.loadAllAttendanceVariablesData(idGroup);

        return this.attendanceDB.get(idGroup)?.attData;
    }

    async getAllAttendanceVariablesData(idGroup: string)
        : Promise<{
            APIExecutionInjectionClientBasic: IAPIExecutionInjectionClientBasic,
            formAnswers?: TGeneralFormServerAnswerArray
        }> {
        const finalHash: TComputedInfo = {};
        const attSvcPack: IAttendentServicePackClient = this.getAttendentServicePackForGroup(idGroup);
        if (!attSvcPack) {
            return {
                APIExecutionInjectionClientBasic: {
                    ...getDefaultICustomUserFunctionParams(),
                    allFieldMap: finalHash,
                    islandData: null
                }
            };
        }

        const interactionService = this.getInitInteractionService(idGroup, false);
        if (!interactionService) {
            return {
                APIExecutionInjectionClientBasic: {
                    ...getDefaultICustomUserFunctionParams(),
                    allFieldMap: finalHash,
                    islandData: null
                }
            };
        }

        const threadId = getSocialCCThreadId(interactionService)
        const { responses: answers, allFieldMap } = await this.generalFormSvc.getAnswersForConversation(threadId);

        const customerAvatar = interactionService.getParticipant().getAvatar();
        const costumerContacts: TCheckedContactArray = customerAvatar.getContacts();
        costumerContacts.forEach((c) => {
            const metadataName = getMetadataNameByTypeOfContact(c.type);
            finalHash[metadataName] = c.address;
        });
        objectShallowReplace(finalHash, allFieldMap);

        /**
         * Repostas dos formulários
         */
        for (const formAnswer of answers) {
            for (const answer of formAnswer.responses) {
                addFieldToHash(finalHash, answer);
                // finalHash[answer.propertyName] = answer.value.toString();
            }
        }

        /**
         * Valores das variáveis
         */
        const canonicalIds = isValidArray(attSvcPack.canonicalIds) ? attSvcPack.canonicalIds : attSvcPack.canonicals.map(c => c.idCanonical);
        const variableIds: string[] = [...attSvcPack.variableTags, ...canonicalIds];
        const variablesToLoad: string[] = variableIds.filter(vID => isValidString(vID) && !(vID in finalHash));

        await this.variablesHashCache.hydrate(variablesToLoad);

        const allVariablesValues: TColmeiaTagArray = this.variablesHashCache.toArray();

        // (await this.lookupSvc.getBatchNonSerializables<TColmeiaTagArray>(variableIds)).filter((v) => !(v.idNS in finalHash));

        for (const cVariable of allVariablesValues) {
            finalHash[cVariable.idNS] = cVariable.value;
        }

        const island: IServiceIslandServer = this.getIslandForGroup(idGroup)
        const serviceInteraction: IServiceChatInitiatedInteractionJSON = interactionService.toJSON();
        const attendentEmail: string = this.session.getPlayer().getEmail()

        const provider: IDeliveryTarget = interactionService.get360Providers()?.[0];

        const APIExecutionInjectionClientBasic: IAPIExecutionInjectionClientBasic = {
            ...getDefaultICustomUserFunctionParams(),
            address: provider?.target,
            channelAddress: provider?.address,
            allFieldMap: finalHash,
            channel: provider?.providerType,
            idConversation: getIDConversationFromThreadInfo(serviceInteraction.threadInfo),
            idTicket: serviceInteraction.idTicket,
            islandData: {
                islandID: this.getIdIslandForGroup(idGroup),
                islandName: island?.nName,
                customerName: Serializable.getJText(serviceInteraction.participant.avatar),
                customerAvatarID: serviceInteraction.participant.avatar.primaryID,
                currentServiceBeginTimestamp: serviceInteraction.clockTick,
                currentServiceTimeTimestamp: getClock() - serviceInteraction.clockTick,
                attendentEmail,
            },
        };

        return { APIExecutionInjectionClientBasic, formAnswers: answers };
    }

    public getAgentNameFromCurrentAttance() {
        return this.getCurrentAttendance().interaction.agentName;
    }

    public getAllComputedInfoForAttendance(idGroup: string): TComputedInfo {
        return this.attendanceDB.get(idGroup)?.allComputedValues || {};
    }

    public getFormAnswersForCurrentAttendance(): TGeneralFormServerAnswerArray {
        return this.attendanceDB.get(this.session.getSelectedGroupID())?.formAnswers || [];
    }

    public hasLoadedAllComputedInfoForAttendance(idGroup: string): boolean {
        const values = this.attendanceDB.get(idGroup)?.allComputedValues;

        if (values) {
            return Object.keys(values).length > 0;
        }

        return false;
    }

    public getAllComputedInfoForCurrentAttendance(): TComputedInfo {
        return this.getAllComputedInfoForAttendance(this.session.getSelectedGroupID());
    }

    public getMaxFileSizePerChannel(idGroup: string): number {
        const provider: EDelivery360Action = this.getInitInteractionService(idGroup, false)?.get360ProviderType();

        return isValidRef(delivery360ConfigDB[provider]) ?
            delivery360ConfigDB[provider].maxFileBytesLength
            : -1;
    }

    public attInteractionSent(_interaction: Interaction) {
        const interactionInfo: IOldestRecentInteractionInfo = {
            clockTick: _interaction.getClockTick(),
            idAvatar: _interaction.getAvatarID(),
            isMessageSentByHumanAttendant: true
        };
        this.oldestRecentInteractionSvc.upsertInteraction(_interaction.getGroupID(), interactionInfo);
        this.matchAttendancesInactivityThresholds();
    }

    isOnChatScreen(): boolean {
        return this.location.path().startsWith(`/${routeList.groups.path}/${routeList.groups.children.idGroup.children.chat}`);
    }

    public getIsland(idNS: string): IServiceIslandServer {
        return this.islandDB[idNS];
    }

    public async getCurrentAttendanceTarget(): Promise<string> {
        const idGroup = this.session.getSelectedGroupID();

        if (!this.isAttendingOnGroup(idGroup)) {
            return '';
        }

        const idReplyInteraction: string = this.getReplyInteractionForGroup(idGroup);

        if (!UberCache.testCache(idReplyInteraction)) {
            return '';
        }

        const attendanceInteraction: Interaction = Interaction.staticFactory(idReplyInteraction);
        const costumerContacts: TCheckedContactArray = attendanceInteraction.getParticipant().getAvatar().getContacts();
        let selectedContact = costumerContacts.length === 1 ? costumerContacts[0] : null;

        if (!selectedContact) {
            return '';
        }

        let target = '';
        let isPhoneNumber = false;

        if (isValidString(selectedContact.address)) {
            target = selectedContact.address;
            isPhoneNumber = selectedContact.type === ETypeOfContact.mobile;
        } else {
            target = attendanceInteraction.get360Providers()[0]?.target || '';
            isPhoneNumber = attendanceInteraction.get360ProviderType() === EDelivery360Action.Delivery360WhatsApp;
        }

        return isPhoneNumber
            ? (getNoDDDPhone(target) || '')
            : target
    }

    public canServiceMakeActiveCall(startServiceInteraction: StartServiceChat): boolean {
        const providerType = startServiceInteraction.get360ProviderType();
        const contactType = getTypeOfContact(providerType);

        return this.availableOpenActiveCallContactTypes.includes(contactType);
    }
}
