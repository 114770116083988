import {TExtendedParticipantArray} from "@colmeia/core/src/core-constants/types";
import { IRootBase } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { NShareSignal, TShareMessageArray } from "app/model/signal/share-signal";

export namespace NShare {

    export interface IShareClientInstance {
        setSharingAmountCallback(count: number): void;
        closeShareWindowCallback(): void;
    }

    export interface IShareParameter extends IRootBase {
        clientCallback: IShareClientInstance;
    }

    export class ShareHandler extends MainHandler {

        constructor(
            parameters: IShareParameter,
            private sharingItems: Set<NShareSignal.IShareMessage> = new Set(),
            private participants: TExtendedParticipantArray = [],
        ) {
            super(parameters)
        }

        private getParameter(): IShareParameter {
            return <IShareParameter>(super.getComponentParameter());
        }

        shareSucceded(): void {
            this.removeAllSharingItems();
            console.log("zerando os participantes")
            console.log(this.participants)
        }

        setParticipants(participants: TExtendedParticipantArray): void {
            this.participants = participants;
        }

        getParticipants(): TExtendedParticipantArray {
            return this.participants;
        }

        getSharedItems(): TShareMessageArray {
            return [...this.sharingItems]
        }

        getClientInstance(): IShareClientInstance {
            return this.getParameter().clientCallback;
        }

        removeSharingItem(item: NShareSignal.IShareMessage): void {
            this.sharingItems.delete(item);
            this.updateAmountOnParent();
            this.resetShareWindowIfNoMoreItems();
        }

        removeAllSharingItems(): void {
            this.sharingItems.clear();
            this.participants = [];
            // this.participants.forEach(p => this.participants.())
            this.updateAmountOnParent();
            this.getClientInstance().closeShareWindowCallback();
        };

        resetShareWindowIfNoMoreItems(): void {
            if (this.sharingItems.size === 0) {
                this.getClientInstance().closeShareWindowCallback();
            }
        }

        addSharingItem(item: NShareSignal.IShareMessage): void {
            const foundItem = [...this.sharingItems].findIndex(elem => item.resource.getPrimaryID() === elem.resource.getPrimaryID()) > -1;
            if (!foundItem)
                this.sharingItems.add(item);
            this.updateAmountOnParent();
        }

        private updateAmountOnParent(): void {
            this.getClientInstance().setSharingAmountCallback(this.sharingItems.size)
        }

    }

}
