import { Injectable } from '@angular/core';
import { EEventName, EMobileNativeEvents, IMobileNativeData } from '@colmeia/core/src/client-shared/client-native.model';


@Injectable({
    providedIn: 'root'
})
export class NativeEventEmitterService {
    /**
     * manda mensagens do angular pro expo
     * @param data 
     */
    public sendMsg(data: IMobileNativeData) {
        (window as any).ReactNativeWebView?.postMessage(JSON.stringify(data));
    }

    /**
     * manda mensagens do angular pro expo
     * @param eventName 
     * @param data 
     */
    public emit<T extends object>(eventName: EEventName, data: T) {
        this.sendMsg({
            type: EMobileNativeEvents.event,
            payload: {
                eventName,
                data,
            }
        })
    }
}