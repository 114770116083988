import { Component, OnInit, Inject, Optional } from '@angular/core';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { NSPickerHandler, INSPickerHandlerClientCallback, INSPickerHandlerParameter, ValueOf, TNsPropertyName, TNsFromNSType } from 'app/handlers/ns-picker/ns-picker.handler';
import { IServerColmeiaTag, ETagType } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { INonSerializable, ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { Observable } from 'rxjs';
import { NSPickerSelectionsHandler } from 'app/handlers/ns-picker/ns-picker-selections.handler';
import { NSPickerSelectablesHandler } from 'app/handlers/ns-picker/ns-picker-modal-selectables.handler';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ITagPickerHandler, ETagPickerHandlerMode, TagPickerHandler } from 'app/handlers/tag-picker-handler';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { isValidRef, isValidFunction, accentList } from '@colmeia/core/src/tools/utility';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericDashboardPaginationHandler } from 'app/components/dashboard/dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.handler';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { nonSerializableFriendlyNameTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly';
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { metadataNamesTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/metadata';
import { isILocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { DashboardFormCreateComponent } from 'app/components/dashboard/dashboard-form-create/dashboard-form-create.component';
import { take } from 'rxjs/operators';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ComponentType } from '@angular/cdk/portal';
import { CanonicalEditComponent } from 'app/components/dashboard/canonical/canonical-edit/canonical-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DashboardWabaEditComponent } from 'app/components/dashboard/dashboard-waba/dashboard-waba-edit/dashboard-waba-edit.component';
import { uniqueId } from 'lodash';


@Component({
    selector: 'app-ns-picker-modal',
    templateUrl: './ns-picker-modal.component.html',
    styleUrls: ['./ns-picker-modal.component.scss'],
})
export class NsPickerModalComponent extends RootComponent<
    | 'add'
    | 'cancel'
    | 'selectionWay'
    | EMetadataNames
> implements OnInit, INSPickerHandlerClientCallback {

    public nsTypeName: string;
    public paginationContentHandler: GenericDashboardPaginationHandler;
    public loading: boolean;
    public handler: NSPickerHandler;
    public nsPickerSelectionsHandler: NSPickerSelectionsHandler;
    public nsPickerSelectablesHandler: NSPickerSelectablesHandler;
    public tagPickerHandler: ITagPickerHandler;
    public showTaggableComponent: boolean = false;
    public mapNames: Map<INonSerializable, string> = new Map();
    public get parameter(): INSPickerHandlerParameter {
        return this.handler.getComponentParameter();
    }
    private _selectedProperty: ValueOf<INonSerializable>;

    dialogRef: MatDialogRef<NSPickerHandler> | ColmeiaWindowRef;

    constructor(
        @Optional() matDialogRef: MatDialogRef<NSPickerHandler>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dataToComponent: IColmeiaDialogComponentData<NSPickerHandler>,
        private colmeiaWindow: ColmeiaWindowService,
        @Optional() private colmeiaWindowRef: ColmeiaWindowRef,
        private matDialog: MatDialog

    ) {

        super({
            ...pickTranslations(gTranslations.common, [
                'add',
                'cancel',
            ]),
            ...pickTranslations(gTranslations.fragments, [
                'selectionWay',
            ]),
            ...metadataNamesTranslations,
        });

        if (dataToComponent && !colmeiaWindowRef) {
            this.handler = dataToComponent.getParamsToChildComponent();
            this.dialogRef = matDialogRef;
        }
        else if (colmeiaWindowRef) {
            this.handler = colmeiaWindowRef.data;
            this.dialogRef = colmeiaWindowRef;
        }
        // console.log({
        // 	useNsPropertySelectionConfig: this.parameter.useNsMatchByCustomerChoice,
        // 	isValidRef: this.hasUseSelectProperty(),
        // 	label: this.getSelectPropertyLabel
        // })
        if (this.hasUseSelectProperty()) {
            this._selectedProperty = this.parameter.useNsMatchByCustomerChoice!.config!.value;
        }
    }

    public async ngOnInit() {
        const beforeClosed: Observable<any> = this.dialogRef.beforeClosed();
        this.handler.addClient(this);

        const { serializableId, idField } = nonSerializableFriendlyNameTranslations[this.parameter.nsType];
        this.nsTypeName = Serializable.staticFactory(serializableId).getSerializableText(idField);

        beforeClosed.subscribe(() => {
            this.handler.onCancelCallback();
        });


        this.nsPickerSelectablesHandler = new NSPickerSelectablesHandler({
            allNonSerializables: this.handler.allNonSerializables,
            nonSerializables: this.handler.nonSerializables,
            allowMultipleNonSerializableSelections: this.handler.allowMultipleNonSerializableSelections,
            clientCallback: this,
        });


        this.nsPickerSelectionsHandler = new NSPickerSelectionsHandler({
            nonSerializables: this.nonSerializables,
            maxSelections: this.handler.maxSelections,
            clientCallback: {
                onRemoveNSCallback: this.onRemoveNSCallback.bind(this),
            },
        });



        this.loadParameter();
        await this.handler.setSearchTags([]);

        //
        this.loading = false;
    }


    public mapName(ns: INonSerializable): string {
        if (!this.mapNames.has(ns)) {
            let name: string = (isValidRef(this.parameter.clientCallback) && isValidFunction(this.parameter.clientCallback.mapName)) ?
                this.parameter.clientCallback.mapName(ns)
                : ns.nName;

            if (isILocalCanonical(ns) && isValidRef(ns.globalCanonical)) {
                name += ` <span class="slc-modal-canon-type-name">${this.translations[ns.globalCanonical].value}</span>`;
            }

            this.mapNames.set(ns, name);
        }
        return this.mapNames.get(ns);
    }

    public loadParameter(): void {
        const nsType = this.handler.nsType;

        this.tagPickerHandler = new TagPickerHandler({
            tagType: ETagType.marker,
            tagsData: {
                nsType,
                demandedTag: this.parameter.demandedTag,
            },
            mode: ETagPickerHandlerMode.query,
            selectedTags: [],
            clientCallback: this,
            compact: false
        });
    }


    public onFinishSelectionCallback(selectedTags: IServerColmeiaTag[]) {
        this.handler.setSearchTags(selectedTags.map(tag => tag.idNS));
    }

    public onLoadNonSerializables(): void {
        this.nsPickerSelectablesHandler.safeSetNonSerializables(this.handler.nonSerializables);
    }

    public switchNonSerializableCallback(nonSerializable: INonSerializable): void {
        this.handler.switchNonSerializable(nonSerializable);

        this.nsPickerSelectablesHandler.safeSetNonSerializables(this.handler.nonSerializables);
    }
    public onSelectSearchTags() {
        this.nsPickerSelectablesHandler.allNonSerializables = (this.handler.allNonSerializables);
        this.nsPickerSelectablesHandler.safeSetNonSerializables(this.handler.nonSerializables);
    }
    public onSelectCallback() {
        this.nsPickerSelectionsHandler.safeSetNonSerializables(
            this.nsPickerSelectablesHandler.nonSerializables
        );
    }

    public onListedNonSerializables(nonSerializables: INonSerializable[]): void {
        this.nsPickerSelectablesHandler.onUpdate();
    }

    public onRemoveNSCallback(ns: INonSerializable): void {
        this.handler.removeNonSerializable(ns);
        this.nsPickerSelectablesHandler.safeSetNonSerializables(
            this.handler.nonSerializables
        );

    }

    public get title(): string {
        return this.handler.title;
    }

    public get allNonSerializables(): INonSerializable[] {
        return this.handler.allNonSerializables;
    }

    public get nonSerializables(): INonSerializable[] {
        return this.handler.nonSerializables;
    }

    public get maxSelections(): number {
        return this.handler.maxSelections
    }

    public get allowCreateButton(): boolean | undefined {
        return this.handler.allowCreateButton;
    }

    public get useColmeiaWindowSvc(): boolean | undefined {
        return this.handler.useColmeiaWindowSvc;
    }

    public save(): void {
        this.handler.onSaveCallback();
        this.dialogRef.close();
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public createNs(): void {
        switch (this.handler.nsType) {
            case ENonSerializableObjectType.formSchemma:
                this.openCreateNsModal(DashboardFormCreateComponent, {
                    title: 'Criar Metadado',
                    group: this.parameter.title || 'Seleção de Metadado',
                    windowIdentifier: this.parameter.colmeiaWindowConfig?.windowGroup + (this.parameter.title || "Seleção de Metadado")
                });
                break;

            case ENonSerializableObjectType.canonical:
                this.openCreateNsModal(DashboardFormCreateComponent);
                break;

            case ENonSerializableObjectType.waba:
                this.openCreateNsModal(DashboardWabaEditComponent, {
                    title: 'Nova WABA',
                    group: 'Whatsapp Business Account',
                    windowIdentifier: uniqueId()
                })

            default:
                break;
        }
    }

    openCreateNsModal<T extends {}>(nsComponent: ComponentType<T>, windowConfig?: { title: string, group: string, windowIdentifier: string }) {
        let windowOrDialogRef: ColmeiaWindowRef | MatDialogRef<unknown, INonSerializable>;

        if (isValidRef(windowConfig)) {
            windowOrDialogRef = this.colmeiaWindow.open<T>(nsComponent, {
                width: "90vw",
                maxWidth: "1600px",
                ...windowConfig
            });
        } else {
            windowOrDialogRef = this.matDialog.open(CanonicalEditComponent)
        }

        // TODO = criar interface para forçar tipagem do retorno do .close do colmeia window
        windowOrDialogRef.afterClosed().pipe(take(1)).subscribe((ns: INonSerializable) => {
            if (isValidRef(ns)) {
                this.switchNonSerializableCallback(ns);
                this.save();
            }
        });
    }

    hasUseSelectProperty() {
        return isValidRef(this.parameter.useNsMatchByCustomerChoice?.allowPropertyValueSelection) && isValidRef(this.parameter.useNsMatchByCustomerChoice?.config)
    }

    get getSelectionOptions() {
        return this.parameter.useNsMatchByCustomerChoice?.config?.possibleValues
    }

    get selectedProperty() {
        return this._selectedProperty;
    }
    async selectedPropertyChange(event: MatSelectChange) {
        const propValue = event.value;
        // console.log({beforeMatch: this.parameter.match, selectedPropertyBefore: this._selectedProperty})
        this._selectedProperty = propValue;

        this.getUseNsMatchByCustomerChoiceConfig?.setMatch!(propValue);
        this.handler.resetCursor();
        await this.handler.loadAllNonSerializables()

        // console.log({afterMatch: this.parameter.match, selectedPropertyAfter: this._selectedProperty})
    }

    get getUseNsMatchByCustomerChoiceConfig() {
        return this.parameter.useNsMatchByCustomerChoice?.config
    }

    get getSelectPropertyLabel() {
        return this.getUseNsMatchByCustomerChoiceConfig?.label
    }
}
