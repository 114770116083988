import { Component, Input, OnInit } from '@angular/core';
import { Memoize } from 'typescript-memoize';
import {DateService, EDateTypes} from "../../../services/date.service";

export enum EDateHeaderSize {
    None,
    xSmall,
    Small,
    Normal,
    Big
}

@Component({
    selector: 'app-date-header',
    templateUrl: './date-header.component.html',
    styleUrls: ['./date-header.component.scss']
})
export class DateHeaderComponent implements OnInit {
    @Input() date: number;
    @Input() size: EDateHeaderSize;
    private sizeToFontSize: {[key: string]: string} = {
        [EDateHeaderSize.None]: '0',
        [EDateHeaderSize.xSmall]: '$smaller-font',
        [EDateHeaderSize.Small]: '0.96rem',
        [EDateHeaderSize.Normal]: '1rem',
        [EDateHeaderSize.Big]: '1.2rem'
    }

    constructor(
        private dateSvc: DateService,
    ) { }

    ngOnInit() {
        this.initSize()
    }

    initSize(): void {
        if(!this.size) {
            this.size = EDateHeaderSize.Normal
        }
    }

    @Memoize()
    getExtraStyles(size: EDateHeaderSize): Object {
        return {
            'font-size': this.sizeToFontSize[size]
        }
    }

    getDay(): string {
        return this.dateSvc.getDateFormated(this.date, EDateTypes.Day)
    }

    getMonth(): string {
        return this.dateSvc.getDateFormated(this.date, EDateTypes.Month)
    }

    getHour(): string {
        return this.dateSvc.getDateFormated(this.date, EDateTypes.Hour)
    }

}
