import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { IGeneralFormAnswer, IGeneralFormAnswerServer } from '@colmeia/core/src/general-form/general-form-answer';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';
import { IMetaEngagement } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { TComputedInfo } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { MainHandler } from 'app/handlers/main-handler';
import { IComponentParameter } from 'app/model/component-comm/basic';
import { EGeneralFormViewType } from 'app/model/general-form.model';

export enum EAnnotationsType {
    allSchemas = 'allSchemas',
    onlyAnswersFromInteraction = 'onlyAnswersFromInteraction'
}
export interface IAnnotationsModalParameters extends IComponentParameter {
    type: EAnnotationsType;
    serializableId: TGlobalUID;
    onSaveAnnotationCallback(annotation: IGeneralFormAnswerServer): Promise<void> | void;
    validateAnswer?(annotation: IGeneralFormAnswer): boolean;
    persist: boolean; // if true it will automatically save before do the callback, else will only pass the IGeneralFormAnswer to callback
    selectedSchema?: IFormSchema;
    answer?: IGeneralFormAnswer;
    openOnCreate?: boolean;
    viewType?: EGeneralFormViewType;
    engagement?: IMetaEngagement;
    hideCreateTab?: boolean;
    computedInfo?: TComputedInfo,
    idGroup?: TGlobalUID,
    disableAutoClose?: boolean,
    targetDatabaseId?: string,
}

export class AnnotationsModalHandler extends MainHandler {

    constructor(private parameters: IAnnotationsModalParameters){
        super(parameters);
        parameters.openOnCreate = isValidRef(parameters.openOnCreate) && parameters.openOnCreate;
    }

    getType(): EAnnotationsType {
        return this.parameters.type
    }

    getComponentParameter(): IAnnotationsModalParameters {
        return this.parameters
    }

    get serializableId(): TGlobalUID {
        return this.getComponentParameter().serializableId
    }

    get onSaveAnnotationCallback() {
        return this.getComponentParameter().onSaveAnnotationCallback
    }

    get selectedSchema(): IFormSchema {
        return this.getComponentParameter().selectedSchema
    }

    get answer(): IGeneralFormAnswer {
        return this.getComponentParameter().answer
    }

    get openOnCreate(): boolean {
        return this.getComponentParameter().openOnCreate
    }

    get viewType(): EGeneralFormViewType {
        return this.getComponentParameter().viewType
    }

    get persist(): boolean {
        return this.getComponentParameter().persist;
    }

    get engagement(): IMetaEngagement {
        return this.getComponentParameter().engagement;
    }

    get targetDatabaseId(): string {
        return this.getComponentParameter().targetDatabaseId;
    }

    get validateAnswer() {
        return this.getComponentParameter().validateAnswer
    }

}
