<div class="header-modules">
    <section class="button-links">
        <button
            type="button"
            (click)="goToHome()"
            matTooltip="Home"
            matTooltipPosition="right"
            matRipple
        >
            <mat-icon class="mat-20">home</mat-icon>
        </button>
    
        <button
            type="button"
            (click)="goToGroup()"
            matTooltip="Grupo"
            matTooltipPosition="right"
            matRipple
        >
            <mat-icon class="mat-20">group</mat-icon>
        </button>

        <button
            *ngIf="hasTicketsAccess"
            type="button"
            (click)="onClickTickets($event)"
            matTooltip="Tickets"
            matTooltipPosition="right"
            matRipple
        >
            <mat-icon class="mat-20">confirmation_number</mat-icon>
        </button>
    </section>

    <button
        type="button"
        class="chat-open"
        [class.is-current]="selectedSideScreenId === ESideMenuScreen.Chat"
        [class.group-loading]="groupLoading$ | async"
        (click)="onChatClick()"
        [matTooltip]="'Chat'"
        matTooltipPosition="right"
        matRipple
    >
        <mat-spinner *ngIf="groupLoading$ | async" color="accent" [strokeWidth]="3" diameter="34"></mat-spinner>
        <mat-icon>chat</mat-icon>
    </button>
</div>

<div class="modules">
    <ng-container *ngFor="let module of modulesList | async">
        <button
            type="button"
            [serializable]="module.primaryId"
            [class.is-current]="isCurrentModule(module)"
            (click)="onModuleClick(module)"
            [matTooltip]="module.title"
            matTooltipPosition="right"
            matRipple
        >
            <img [src]="module.icon">
        </button>
    </ng-container>
</div>
