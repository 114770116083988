import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ImgSecureDownloadService, TSafeUrlContainer} from "../services/auth/img-secure-download.service";


@Pipe({
    name: 'imgSecureDownload'
})
export class ImgSecureDownloadPipe implements PipeTransform {
    constructor(private imageSecureSvc: ImgSecureDownloadService) { }

    transform(niceUrl: string, secContext: SecurityContext = SecurityContext.URL): Observable<SafeUrl> {
        return this.imageSecureSvc.download(niceUrl)
            .pipe(
                map((safeUrlContainer: TSafeUrlContainer) => safeUrlContainer[secContext]))
    }
}
