import { TAdvancedPredicate, TLocalPredicate } from "@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service";

const lastChar = String.fromCharCode(-1)

export function makeSearchPredicate(predicate: TAdvancedPredicate): TLocalPredicate[] {
    return [
        { property: predicate.property, predicate: predicate.predicate, operator: '>=' },
        { property: predicate.property, predicate: predicate.predicate + lastChar, operator: '<=' },
    ]
}

export function makePseudoDifferentFieldPredicate(predicate: TAdvancedPredicate): TLocalPredicate[] {
    return [
        { property: predicate.property, predicate: predicate.predicate, operator: '>' },
        { property: predicate.property, predicate: predicate.predicate, operator: '<' },
    ]
}
