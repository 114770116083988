import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CardBrand, CreditCardTypeInfo } from "credit-card-type";
import * as creditCardType from 'credit-card-type';
import * as creditCardValidator from 'card-validator';
import { IPublicMedium } from "@colmeia/core/src/comm-interfaces/billing-interfaces";
import { creditCardBrandSVG } from "../../../../model/billing/helpers";
import { RootComponent } from 'app/components/foundation/root/root.component';
import { constant } from '@colmeia/core/src/business/constant';
import {sellAbleScreenOptions} from "@colmeia/core/src/shared-business-rules/const-text/views/sellable";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

export type onCreditCardChangeCallback = (rawVal: string, formatedVal: string, brand: CardBrand | string, code: number, valid: boolean) => void;

const noop: onCreditCardChangeCallback = (rawVal: string, formatedlVal: string, brand: CardBrand | string, code: number, valid: boolean) => { };
// @TODO ver se pode ser apagado
// declare interface CreditCardValidationResult {
//     card: {
//         niceType: string,
//         type: string,
//         gaps: Array<number>,
//         lengths: Array<number>,
//         code: {
//             name: string,
//             size: number
//         }
//     },
//     isPotentiallyValid: boolean;
//     isValid: boolean;
// }
//
// declare class creditCardValidator {
//     static number(card: string): CreditCardValidationResult;
// }

@Component({
    selector: 'app-credit-card-input',
    templateUrl: './credit-card-input.component.html',
    styleUrls: ['./credit-card-input.component.scss']
})
export class CreditCardInputComponent extends RootComponent<
    'creditCardNumber'
> implements OnInit {
    @Input() lastfour: boolean;
    @Input() disabled: boolean;
    @Input() onChange: onCreditCardChangeCallback;
    @Input() publicMedium: IPublicMedium;
    @ViewChild('normalModeInput', {
        static: true
    }) normalModeInput: ElementRef;


    private isValid: boolean = false;

    private infos: CreditCardTypeInfo = null;

    constructor() {
        super({
            creditCardNumber: gTranslations.sellable.creditCardNumber,
        });
    }

    ngOnInit() { }

    get showBrand(): boolean {
        return (this.isLastFourMode && this._brand && this._brand.length > 0)
            || (this.infos !== null);
    }

    get _code(): number {
        return (this.infos) ? this.infos.code.size : 4;
    }

    get _brand(): CardBrand | string {
        if (this.isLastFourMode) {
            return (this.publicMedium) ? this.publicMedium.brand : '';
        }
        return (this.infos) ? this.infos.type : null;
    }

    get brandUrl(): string {
        return creditCardBrandSVG(this._brand);
    }

    get brandClasses(): string {
        return this._brand;
    }

    get _disabled(): boolean {
        return (this.isLastFourMode) ? true : ((this.disabled !== undefined) ? this.disabled : false);
    }

    private guessCardInfo(str: string): void {
        const query = creditCardType(str);
        this.infos = (query.length) ? query.pop() : null;
    }

    private validateCard(val: string): void {
        this.isValid = creditCardValidator.number(val).isValid;
    }

    onKeyUp(event: Event) {
        if (this.isNormalMode) {
            const val: string = (<HTMLInputElement>this.normalModeInput.nativeElement).value;

            this.guessCardInfo(val);
            this.validateCard(val);
            this.formatCard(val);

            const callback = this.onChange || noop;
            const freshVal = (<HTMLInputElement>this.normalModeInput.nativeElement).value;

            callback(
                freshVal.replace(/\s/g, ''),
                freshVal,
                this._brand,
                this._code,
                this.isValid
            );
        }
    }

    private formatCard(val: string): void {
        const brand = creditCardType.getTypeInfo(this.infos.type);
        let format: string = '';
        if (brand) {
            let count = 0;
            const size = brand.lengths[brand.lengths.length - 1];
            for (let i = 0; i < brand.gaps.length; i++) {
                if (!i) {
                    count += brand.gaps[i];
                    format += Array(brand.gaps[i]).fill('$').join('') + ' ';
                } else {
                    const am = brand.gaps[i] - brand.gaps[i - 1];
                    count += am;
                    format += Array(am).fill('$').join('') + ' '
                }
            }
            format += Array(size - count).fill('$').join('');
        } else {
            format = Array(30).fill('$').join('');
        }
        let result = format;
        for (const digit of val) {
            const charcode = digit.charCodeAt(0);
            if (charcode >= 48 && charcode <= 57) {
                result = result.replace('$', digit);
            }
        }
        (<HTMLInputElement>this.normalModeInput.nativeElement).value = result.replace(/\$/g, '').trim();
    }

    get isNormalMode(): boolean {
        return (this.lastfour !== undefined)
            ? !this.lastfour
            : true;
    }

    get isLastFourMode(): boolean {
        return (this.lastfour !== undefined)
            ? this.lastfour
            : false;
    }

    get lastFourValue(): string {
        return (this.publicMedium)
            ? `•••• ${this.publicMedium.lastfour}`
            : '';
    }
}
