import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { IAvatarConnectionInteractionJSON} from '../../comm-interfaces/barrel-comm-interfaces';
import { ConnectionToSerializable } from './to-serializable';

export class ToAvatar extends ConnectionToSerializable {

    

    protected constructor(primaryID: TGlobalUID, participant: Participant, idSubscriptionTYpe: TGlobalUID, idAvatar: TGlobalUID){
        super(primaryID, participant, idSubscriptionTYpe, idAvatar);
    };

    public toJSON(): IAvatarConnectionInteractionJSON {
        return super.toJSON();
    };

    public getUDAvatar(): TGlobalUID {return super.getReferencedSerializableID()};

    
	public static staticFactory(idConnection: TGlobalUID): ToAvatar {
	    return <ToAvatar> ConnectionToSerializable.staticFactory(idConnection);
    };
    
    public static factoryMessage(json: IAvatarConnectionInteractionJSON,  participant: Participant): ToAvatar {
        let toAvatar: ToAvatar = <ToAvatar>ConnectionToSerializable.searchCachedInteraction(json.primaryID);
        if (!toAvatar) {
            toAvatar = new ToAvatar(json.primaryID, 
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                json.idSubscriptionType,
                json.subs.toSubject);
        };
        toAvatar.rehydrate(json);
        return toAvatar;
    };
}
