
export enum EQualityOfCEP {
    conclusive = 'Conclusivo',
    invalid = 'Invalido',
    promotional = 'Promocional',
    correiosExclusive = 'Uso Correios',
    generic = 'Generico',
    caixaPostal = 'Caixa Postal'
}

