import { Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { constant } from "@colmeia/core/src/business/constant";
import { ChatbarHandler, IChatBarParameter } from "./chat-bar-handler";
import { ChatMessageHandler } from "./chat-message.handler";
import { MMconstant } from "@colmeia/core/src/multi-media/multimedia-constant";
import { MultimediaTag } from "@colmeia/core/src/multi-media/multi-media-tag";
import { InteractionType } from "@colmeia/core/src/interaction/interaction-type";
import { ReplyChatHandler } from "./reply-chat-handler";
import { EServiceGroupIntType } from "@colmeia/core/src/business/constant.enums";
import {ClientTransactService} from "../../services/client.transact.service";
import {SessionService} from "../../services/session.service";
import {ChatBackboneModel} from "../../model/chat-backbone.model";
import {clientConstants} from "../../model/constants/client.constants";
import {NoopActionBarComponent} from "../../components/chat/action-bar/noop-action-bar/noop-action-bar.component";
import { RecoverySystemService } from "app/services/recovery-system.service";
import { StartServiceChatHandler, IStartServiceParam } from "../start-service-chat-handler";

@Injectable({
    providedIn: 'root'
})
export class ChatBarInteractionHandlerService {

    public static MMTagForIType(idInteractionType: TGlobalUID): TGlobalUID {
        return  MMconstant.tag.photo;
    }

    public static WindowComponentForIType(idInteractionType: TGlobalUID): any {
        return NoopActionBarComponent;
    }

    constructor(
        private sessionSVC: SessionService,
        private clientTransactSvc: ClientTransactService,
        private recoverySvc: RecoverySystemService,
    ) {}

    public handlerFor(
        idInteractionType: TGlobalUID,
        interaction?: Interaction,
        parentInteraction?: Interaction,
        forceEdit: boolean = false,
        idInternalSupportGroup: string = null,
        rememberMeReply: boolean = false,
    ): ChatbarHandler {
        const isEditing: boolean = forceEdit || isValidRef(interaction) && idInteractionType !== constant.interactionType.standard.citation;
        const idGroup: TGlobalUID = this.sessionSVC.getSelectedGroupID();
        let handler: ChatbarHandler;

        const parameter: IChatBarParameter = {
            interactionType: (isEditing) ? interaction.getInteractionType() : InteractionType.staticFactory(idInteractionType || constant.interactionType.standard.message),
            editingInteraction: interaction,
            idGroup,
            chatBackboneModel: ChatBackboneModel.getBackboneModel(idGroup),
            numberOfFilesAllowed: clientConstants.multimedia.maxNumberOFUploadFile,
            parentInteraction,
            isEditing,
            multimediaTag: MultimediaTag.staticFactory(
                ChatBarInteractionHandlerService.MMTagForIType(idInteractionType)
            ),
            clientCallback: null,
            windowComponent: ChatBarInteractionHandlerService.WindowComponentForIType(idInteractionType),
            servicePack: {
                rollbackServices: this.clientTransactSvc,
                recoveryService: this.recoverySvc,
                sessionService: this.sessionSVC,
            },
        };
        switch (idInteractionType) {
            case constant.interactionType.standard.citation:
            case constant.timedType.rememberMe:
                handler = new ReplyChatHandler(parameter,rememberMeReply);
                break;
            case EServiceGroupIntType.startServiceChat:
                const startServiceParam: IStartServiceParam = {
                    ...parameter,
                    idInternalSupportGroup,
                }
                handler = new StartServiceChatHandler(startServiceParam);
                break;
            default:
                handler = new ChatMessageHandler(parameter);
                break;
        }

        return handler;
    }
}
