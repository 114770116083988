import {InjectionToken} from "@angular/core";
import {ChatActionBarStateService} from "../services/chat-action-bar-state.service";
import {Observable} from "rxjs";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";

export const ChatActionBarStateServiceID = new InjectionToken(
    'ChatActionBarStateServiceID',
    {
        providedIn: 'root',
        factory: () => new ChatActionBarStateService()
    }
);


export interface IChatBarResetedListener {
    chatBarResetedListener(): Observable<TGlobalUID>
}

export interface IChatBarResetProvider {
    chatBarReseted(currentInteractionID: TGlobalUID): void;
}
