import { animate, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { constant } from "@colmeia/core/src/business/constant";
import { EObjectType } from "@colmeia/core/src/business/constant.enums";
import { Group } from "@colmeia/core/src/business/group";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { ISearchInfo, ISearchResult, TSearchResultArray } from "@colmeia/core/src/comm-interfaces/ds-interfaces";
import { allAtendeeStatusDB, EAttendantStatusColor } from "@colmeia/core/src/comm-interfaces/service-group-interfaces";
import { TArrayID, TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { MultimediaInstance } from "@colmeia/core/src/multi-media/barrel-multimedia";
import { groupTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/group";
import { searchViewTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/search";
import {
    ESearchAllowedType,
    ESearchScope
} from "@colmeia/core/src/shared-business-rules/search";
import { EScreenGroups } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { isValidArray, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { verticalAppear } from "app/components/dashboard/dashboard-animations";
import { InitSystemService } from "app/services/init-system.service";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Memoize } from "typescript-memoize";
import { NDropDown } from "../../../handlers/dropdown.handler";
import { HandlerHexagonon } from "../../../handlers/hexagono.handler";
import { EParticipantClickTypeMode } from "../../../handlers/participant-selector.handler";
import { HandlerSearch, ISearchComponentParamater } from "../../../handlers/search-handler";
import { IListenerSubscription } from "../../../model/signal/ps-interfaces";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { ConstantService } from "../../../services/constant.service";
import {
    ExecutableItem,
    ExecutableType, TExecutableItemArray
} from "../../../services/controllers-services/security-controller/executable-item";
import { SafeMenuBuilderServices } from "../../../services/controllers-services/security-controller/safe-menu-builder";
import { RouterManagerService } from "../../../services/router-manager.service";
import { RoutingService } from "../../../services/routing.service";
import { SearchResultService } from "../../../services/search-result.service";
import { ISearchRemoteEnvConfig, SearchService } from "../../../services/search.service";
import { SessionService } from "../../../services/session.service";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { UniversalRehydrator } from "../../../services/universal-rehydrator";
import {
    commonScreenOptionsTranslations,
    commonScreenOptionsTranslations2,
    allCommonScreenOptionsTranslations
} from "@colmeia/core/src/shared-business-rules/const-text/views/txt-common";
import { ArrayUtils } from "@colmeia/core/src/tools/utility/arrays/array-utils";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { EContentLocale, EEntityType, INonSerializableSearcheableContent, TFullTextSearchContentLocale } from "@colmeia/core/src/shared-business-rules/non-serializable-id/ns-elastic-model";
import { EBotContentEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { EMetadataEngagementType } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { EBPMAction } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { nonSerializableFriendlyNameTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly';
import { routeID, routeList } from "app/model/routes/route-constants";
import { EBPMType } from "@colmeia/core/src/shared-business-rules/BPM/bpm-model";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { minimumCharLengthSearchFire } from "app/components/navigation/main-header/new-main-header.component";
import { IGroupJSON } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
// import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
// import { INonSerializableSearcheableContent } from "@colmeia/core/src/shared-business-rules/non-serializable-id/ns-elastic-model";

export enum ESearchContentType {
    GlobalContent = 'globalContent',
    SocialNetworkContent = 'socialNetworkContent',
}
export interface ISearchTypeContent {
    name: string
    type: ESearchContentType,
}

interface INonSerializableSearcheableContentClient extends INonSerializableSearcheableContent {
    nsTypeName?: string;
}

/**
 * Search bar in group chat
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "app-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"],
    // providers: [SearchService],
    animations: [
        trigger("itemState", [
            state("visible", style({ opacity: "1" })),
            transition("void => *", [
                style({ opacity: "0" }),
                animate("2s ease-out")
            ])
        ]),
        verticalAppear
    ]
})
export class SearchComponent
    implements OnInit, OnDestroy, OnChanges, IListenerSubscription {
    public visibleResults: boolean = true;
    public proxySubscription: Subscription;

    _searchHandler: HandlerSearch;

    public get searchHandler(): HandlerSearch {
        return this._searchHandler
    }

    @Input() public set searchHandler(value: HandlerSearch) {
        this._searchHandler = value;
        this.onSearchHandlerChange();
    }

    @Output() searchClosed: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild("searchInputHtml", { static: true }) searchInputHtml: ElementRef;

    get selectedSearchType(): ESearchContentType {
        return this.searchHandler.getSearchParameter().selectedSearchType! || ESearchContentType.SocialNetworkContent;
    }

    public searchInput: string = "";
    public searching: boolean = false;
    public searchFired: boolean = false;
    public currentGroup: Group;
    public avatars: TSearchResultArray = [];
    public interactions: TSearchResultArray = [];
    public groups: TSearchResultArray = [];
    public userSelectedList: TSearchResultArray = [];
    public searchInfo: ISearchInfo;
    public selectionMaxNumber: number;
    public hexagonHandler: HandlerHexagonon;
    private searchSubscription: Subscription;
    private isShowSearchOptions: boolean = false;
    private selectedWhere: ExecutableItem;
    private selectedSearchFor: ExecutableItem;
    public fullTextSearchResults: Map<string, INonSerializableSearcheableContentClient[]> = new Map();
    public fullTextSearchResultsTotal: number | undefined;
    public maxSearchResults: number = constant.elasticSearch.maxSearchResults;

    localeTranslation: Record<TFullTextSearchContentLocale, string> = {
        [EContentLocale.name]: 'Nome',
        [EBotContentEvent.preContent]: 'Pré Conteúdo',
        [EBotContentEvent.posContent]: 'Pós Conteúdo',
        [EMetadataEngagementType.richFillForm]: 'Mensagens Avançadas',
        [EMetadataEngagementType.NewCondition]: 'Condicional',
        [EBPMAction.action]: 'Ação',
        [EContentLocale.botTextToShow]: 'Texto à exibir',
        [EContentLocale.botMenuItemAdvancedMessage]: 'Mensagens Avançadas',
        [EContentLocale.botEventMessage]: 'Mensagem de Evento'
    }

    botTranslations: Record<ENextGenBotElementType, string> = {
        [ENextGenBotElementType.atendanceIsland]: 'Ilha de Atendimento',
        [ENextGenBotElementType.botMenuItem]: 'Item de Menu',
        [ENextGenBotElementType.formTransaction]: 'Gerador de Conteúdo por Formulário',
        [ENextGenBotElementType.menuContainer]: 'Menu',
        [ENextGenBotElementType.metadata]: 'Metadado',
        [ENextGenBotElementType.nlpTransaction]: 'Gerador de Conteúdo por NLP',
        [ENextGenBotElementType.root]: 'Root do Bot'
    }
    constructor(
        private session: SessionService,
        private cdRef: ChangeDetectorRef,
        private routingSvc: RoutingService,
        private searchService: SearchService,
        private listener: SignalListenerService,
        private routerSvc: RouterManagerService,
        public constantService: ConstantService,
        private infraService: UniversalRehydrator,
        private searchResultService: SearchResultService,
        private initSvc: InitSystemService
    ) {

    }

    ngOnInit() {
        // init searchInfo
        this.onSearchHandlerChange();

        // listener subscription change
        this.listener.listenSubscriptionChanges(this);

        // listening for search results
        this.listenForSearchResults();
        this.initSelect();

        this.translateLabels();

        this.searchService.searchRequestingStateSubject.subscribe((state: boolean) => {
            this.searching = state;
            this.cdRef.markForCheck();
        });
    }

    private onSearchHandlerChange() {
        this.bindSearchHandler(this.searchHandler);

        const searchRemoteEnvConfig: ISearchRemoteEnvConfig = this.searchHandler.getSearchParameter().searchRemoteEnvConfig;

        if (isValidRef(searchRemoteEnvConfig)) {
            this.searchService.setRemoteEnv(searchRemoteEnvConfig)
        }

        const customerParameter: ISearchComponentParamater = this.searchHandler.getSearchParameter();
        this.searchInfo = {
            auxOptionID: this.getDefaultAuxOptionID(),
            searchContext: customerParameter.searchScope,
            idAgentIsland: customerParameter.idAgentIsland,
            text: ""
        };

        this.selectionMaxNumber = customerParameter.maxSelectionableNumber;

        // init handlers
        this.initDropdownHandlers(customerParameter.searchTypes);
    }

    public getAgentColor(avatar: ISearchResult): EAttendantStatusColor {
        return avatar.status ? allAtendeeStatusDB[avatar.status].color : EAttendantStatusColor.green
    }

    private bindSearchHandler(newHandler: HandlerSearch, oldHandler?: HandlerSearch) {
        if (isValidRef(this.proxySubscription)) {
            this.proxySubscription.unsubscribe();
            this.proxySubscription = undefined;
        }

        if (newHandler.isProxySearch()) {
            this.proxySubscription = newHandler.getSearchParameter()
                .observableForTyping
                .subscribe((inputText) => {
                    this.searchInput = inputText;
                    if (this.searchInput.length >= minimumCharLengthSearchFire) {
                        this.searchNext();
                    }
                })
        };

        newHandler.setSlave(this);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (isValidRef(changes.searchHandler) && changes.searchHandler.previousValue !== changes.searchHandler.currentValue) {
            this.bindSearchHandler(changes.searchHandler.currentValue, changes.searchHandler.previousValue);
            if (this.searchInput) {
                this.searchNext();
            }
        }
    }

    private getDefaultAuxOptionID(): TGlobalUID {
        const customerParameter: ISearchComponentParamater = this.searchHandler.getSearchParameter();
        return customerParameter.defaultOption
            ? customerParameter.defaultOption
            : ESearchAllowedType.all;
    }

    initSelect(): any {
        // init searchfor
        const defaultForOption = this.searchHandler.getSearchParameter()
            .defaultOption;
        const defaultSearchForID = defaultForOption
            ? defaultForOption
            : ESearchAllowedType.all;
        this.selectedSearchFor = this.getSearchForItems().find(
            execItem =>
                execItem.getSerializable().getPrimaryID() === defaultSearchForID
        );

        // init searchWhere
        const defaultSearchWhereOption = this.searchHandler.getSearchParameter()
            .searchScope;
        const defaultSearchWhereID = defaultSearchWhereOption
            ? defaultSearchWhereOption
            : ESearchScope.allSocialNetwork;

        this.selectedWhere = this.getSearchWhereItems().find(
            execItem =>
                execItem.getSerializable().getPrimaryID() ===
                defaultSearchWhereID
        );
    }

    public onInputFocus(): void {
        this.visibleResults = true;
    }

    getElementsFromScreenGroup(type: ExecutableType, screenGroup: TGlobalUID) {
        const menu: SafeMenuBuilderServices = new SafeMenuBuilderServices(
            this.session.getMenuSafeBuilderData()
        );
        const allItems = menu.getGenericScreenGroupItems(type, screenGroup);
        return allItems;
    }
    // #region get select items
    @Memoize()
    getSearchForItems(): TExecutableItemArray {
        const allItems = this.getElementsFromScreenGroup(
            ExecutableType.searchForDropdown,
            EScreenGroups.SearchBar
        );
        return allItems;
    }

    @Memoize()
    getSearchWhereItems(): TExecutableItemArray {
        const allItems = this.getElementsFromScreenGroup(
            ExecutableType.searchWhereDropdown,
            EScreenGroups.SearchBarScope
        );
        return allItems;
    }
    getSelectSerializableText(item: ExecutableItem): string {
        return item.getSerializable().getSerializableText(constant.serializableField.name)
    }
    // #endregion

    // #region selecting items from mat-select
    selectSearchFor(selectedItem: ExecutableItem | "all") {
        if (selectedItem === "all") {
            this.searchInfo.auxOptionID = this.getDefaultAuxOptionID();
            this.selectedSearchFor = undefined;
        } else {
            this.searchInfo.auxOptionID = selectedItem
                .getSerializable()
                .getPrimaryID();
            this.selectedSearchFor = selectedItem;
        }

        this.searchNext();
        this.cdRef.markForCheck();
    }

    selectSearchWhere(selectedItem: ExecutableItem) {
        this.searchInfo.searchContext = selectedItem
            .getSerializable()
            .getPrimaryID();
        this.selectedWhere = selectedItem;
        this.searchNext();
        this.cdRef.markForCheck();
    }
    // #endregion

    listenForSearchResults(): void {
        this.searchSubscription = this.searchService
            .getSearchResults()
            .pipe(filter(searchResults => searchResults != null))
            .subscribe((searchResults: TSearchResultArray) => {
                // clear result list
                this.groups = [];
                this.avatars = [];
                this.interactions = [];

                // adding search result to their respective array
                for (const searchResult of searchResults) {
                    switch (searchResult.idObjectType) {
                        case constant.objectType.avatar:
                            this.avatars.push(searchResult);
                            break;
                        case constant.objectType.interaction:
                            this.interactions.push(searchResult);
                            break;
                        case constant.objectType.group:
                            this.groups.push(searchResult);
                            break;
                    }
                }

                this.visibleResults = !!searchResults.length;
                this.searchFired = true;
                this.cdRef.markForCheck();
            });
    }

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
        this.searchSubscription.unsubscribe();
    }

    getPopularityIcon(group: ISearchResult): string {
        return ''
    }

    public changeSubscriptionSignCallback(
        subscriptionSignal: SubscriptionSignal
    ): void {
        this.currentGroup = subscriptionSignal.getGroup();
        this.searchNext();
    }

    public canApplyClasses(): boolean {
        return this.getClasses() ? true : false;
    }

    public getClasses(): string {
        return this.searchHandler && this.searchHandler.getExtraCssClasses()
            ? this.searchHandler.getExtraCssClasses().join(" ")
            : null;
    }

    public getImageFromDisplay(display: ISearchResult): string {
        if (display.idMedia) {
            return this.constantService.getFileUrl() + display.idMedia;
        } else {
            return display.text.substr(0, 1);
        }
    }

    public canShowCloseButton(): boolean {
        return !!this.searchInput.length || this.searchHandler.showCloseBtn();
    }

    public canShowSelectionableBlock(): boolean {
        return (
            this.isSelectionable() &&
            this.searchHandler.getSearchParameter().clickAction ===
            EParticipantClickTypeMode.Selectionable
        );
    }

    public isSelectionable(): boolean {
        return this.searchHandler
            ? this.searchHandler.getSearchParameter().clickAction ===
            EParticipantClickTypeMode.Selectionable
            : false;
    }

    // IHexagonCallback Compliance
    public onSelectedFile(
        identifier: TGlobalUID,
        multimediaInstance: MultimediaInstance
    ): void { }
    public onClearFile(identifier: TGlobalUID): void { }

    public getgroups(): TSearchResultArray {
        return this.groups;
    }

    /**
     * Gets serializable name from primaryId
     * @param  {TGlobalUID} primaryId
     * @returns string
     */
    public getName(primaryId: TGlobalUID): string {
        return Serializable.staticFactory(primaryId).getName();
    }



    public async searchNext(): Promise<void> {
        this.searchInfo.text = this.searchInput;
        if (this.searchInfo.searchContext == ESearchScope.thisLevelOnly) {
            this.searchInfo.primaryID = this.currentGroup.getGroupID();
        }

        if (isValidString(this.searchHandler.getSearchParameter().menuID)) {
            this.searchInfo.menuID = this.searchHandler.getSearchParameter().menuID;
        }

        if (this.selectedSearchType && this.searchInfo.text.length > 0) {
            if (this.selectedSearchType == ESearchContentType.GlobalContent) {
                this.resetGlobalSearchResults();
                const { map, resultsTotal } = await this.searchService.fullTextSearch(this.searchInfo);
                this.fullTextSearchResults = map;
                this.fullTextSearchResultsTotal = resultsTotal;
                // Placing nsTypeName in searchable content
                this.fullTextSearchResults.forEach((value) => {
                    value.forEach(itemInValue => {
                        const { serializableId, idField } = nonSerializableFriendlyNameTranslations[itemInValue.nsType];
                        itemInValue.nsTypeName = Serializable.staticFactory(serializableId).getSerializableText(idField);
                    })
                })

                this.cdRef.markForCheck()
            } else if (this.selectedSearchType == ESearchContentType.SocialNetworkContent) {
                this.searchService.search(this.searchInfo, this.currentGroup, this.searchHandler.getSearchParameter().requestModifierCallback);
            } else {
                console.error('selecione um tipo de busca!')
            }
        }
        this.searchFired = false;

    }

    /**
     * Sends result to searchResultService subscribers
     * indicating which ISearchResultMsgDisplay was clicked
     * (components like group-chat are subscribed to it)
     * @param {ISearchResultMsgDisplay} msg [description]
     */
    public sendResult(result: ISearchResult): void {
        this.addSelectedItem(result);
        this.searchResultService.send(result);
    }

    /**
     * closes search results
     * @returns void
     */
    public closeSearchBox(): void {
        this.userSelectedList = [];
        this.searchInput = "";
        this.searchNext();
        this.sendResult(null);
        this.searchClosed.emit();
        this.fullTextSearchResultsTotal = undefined;
        this.resetGlobalSearchResults();
    }

    //
    // Hexagon section
    //
    public buildHexagonHandler(selectedItem: ISearchResult): HandlerHexagonon {
        return HandlerHexagonon.newHandler({
            size: "smd",
            forceImage: selectedItem.idMedia
        });
    }

    public buildHexagonHandlerMentions(
        selectedItem: ISearchResult
    ): HandlerHexagonon {
        return HandlerHexagonon.newHandler({
            forceImage: selectedItem.interactionAvatarDetail.idMediaAvatar,
            size: "smd",
        });
    }

    /**
     * User clicked on cancel
     * @returns void
     */
    public onSelectedItemsCancel(): void {
        this.closeSearchBox();
        this.searchHandler.onSelectedCancel();
        this.cdRef.markForCheck();
    }

    /**
     * Sends to parent component user selected items
     * @returns void
     */
    public onSelectedItemsSave(): void {
        this.searchHandler.onSelectedSave(
            this.userSelectedList,
            this.infraService
        );
        this.closeSearchBox();
        this.searchHandler.onFinishSelection();
        this.cdRef.markForCheck();
    }

    /**
     * Removes selected item from array
     * @param  {ISearchResultMsgDisplay} selectedItem
     * @returns void
     */
    public removeSelectedItem(selectedItem: ISearchResult): void {
        this.userSelectedList = this.userSelectedList.filter(
            item => item.primaryID !== selectedItem.primaryID
        );
        this.searchHandler.onRemoveResult(selectedItem, this.infraService);
        this.cdRef.markForCheck();
    }

    /**
     * Adds selected result to selectedList
     * @param  {ISearchResultMsgDisplay} result
     * @returns void
     */
    private addSelectedItem(result: ISearchResult): void {
        if (
            result &&
            this.selectionMaxNumber &&
            this.userSelectedList.length < this.selectionMaxNumber &&
            !this.userSelectedList.some(
                item => item.primaryID === result.primaryID
            )
        ) {
            this.userSelectedList.push(result);
            this.searchHandler.onSelectedResult(result, this.infraService);
        }
        this.cdRef.markForCheck();
    }

    //#region Dropdown

    private whereDropdownHandler: NDropDown.DropDownHandler;
    private searchForDropdownHandler: NDropDown.DropDownHandler;
    private itemToDropDown: Map<
        ExecutableItem,
        NDropDown.DropDownHandler
    > = new Map();

    public onSearchForClicked(event: MouseEvent): void {
        this.searchForDropdownHandler.toggleVisibility(event);
    }

    public onWhereClicked(event: MouseEvent): void {
        this.whereDropdownHandler.toggleVisibility(event);
    }

    public getSearchForDropDownHandler(): NDropDown.DropDownHandler {
        return this.searchForDropdownHandler;
    }

    public getWhereDropDownHandler(): NDropDown.DropDownHandler {
        return this.whereDropdownHandler;
    }

    private initDropdownHandlers(searchType: TArrayID): void {
        // building dropdown handlers
        this.whereDropdownHandler = this.buildDropdownHandler(
            ExecutableType.searchWhereDropdown,
            EScreenGroups.SearchBarScope,
            this.searchHandler.getSearchParameter().searchScope
        );
        this.selectedWhere = this.whereDropdownHandler.getSelectedItem();

        this.searchForDropdownHandler = this.buildDropdownHandler(
            ExecutableType.searchForDropdown,
            EScreenGroups.SearchBar,
            this.searchHandler.getSearchParameter().defaultOption
                ? this.searchHandler.getSearchParameter().defaultOption
                : ESearchAllowedType.all
        );
        this.selectedSearchFor = this.searchForDropdownHandler.getSelectedItem();

        // set hashmap with items -> handler to make easier handle callback
        this.whereDropdownHandler.getAllItems().forEach(element => {
            this.itemToDropDown.set(element, this.whereDropdownHandler);
        });

        if (searchType.length) {
            const filteredItems = this.searchForDropdownHandler
                .getAllItems()
                .filter(item => searchType.includes(item.getPrimaryID()));
            this.searchForDropdownHandler.setExecutableItems(filteredItems);
        }

        this.searchForDropdownHandler.getAllItems().forEach(element => {
            this.itemToDropDown.set(element, this.searchForDropdownHandler);
        });
    }

    public buildDropdownHandler(
        type: ExecutableType,
        screenGroup: TGlobalUID,
        selected: TGlobalUID
    ): NDropDown.DropDownHandler {
        // for testing only
        const allItems = this.getElementsFromScreenGroup(type, screenGroup);
        // gets default executableItem selected
        let defaultSelected: ExecutableItem;
        if (selected) {
            defaultSelected = allItems.find(item => item.is(selected));
        }

        const parameter: NDropDown.IDropDownComponentParamater = {
            ...NDropDown.DropDownHandler.getDefaultDropDownParameter(),
            color: NDropDown.EColorType.Grey,
            selectable: true,
            clientInstance: this,
            mutuallyExclusive: true,
            allItems: allItems,
            defaultSelectedItems: [defaultSelected],
            position: NDropDown.EDropDownPosition.Left
        };

        const handler: NDropDown.DropDownHandler = new NDropDown.DropDownHandler(
            parameter
        );
        return handler;
    }

    public getSelectedWhereText(): string {
        return this.selectedWhere ? this.selectedWhere.getName() : "";
    }

    public getSearchForText(): string {
        return this.selectedSearchFor ? this.selectedSearchFor.getName() : "";
    }

    toggleShowSearchOptions(): void {
        this.isShowSearchOptions = !this.isShowSearchOptions;
    }

    public onDropdownOptionClicked(
        descriptor: NDropDown.IDropDownClickedDescriptor
    ): void {
        // gets dropdown we clicked
        const dropdown = this.itemToDropDown.get(descriptor.getItemClicked());

        // sets searchInfo
        if (dropdown === this.whereDropdownHandler) {
            // handler is where
            this.selectedWhere = descriptor.getItemClicked();
            this.searchInfo.searchContext = descriptor
                .getItemClicked()
                .getPrimaryID();
        } else {
            // handler is searchFor
            this.selectedSearchFor = descriptor.getItemClicked();
            this.searchInfo.auxOptionID = descriptor
                .getItemClicked()
                .getPrimaryID();
        }
        this.searchNext();
        this.cdRef.markForCheck();
    }

    //#endregion


    canShowEditBtn(): boolean {
        return this.searchHandler.getIdEditableTypes()
    }

    //#region ClickHandlers
    onSearchResultClicked(result: ISearchResult): void {
        switch (this.searchHandler.getSearchParameter().clickAction) {
            case EParticipantClickTypeMode.SendToParent:
                this.searchHandler.onSelectedSave([result], this.infraService);
                break;

            case EParticipantClickTypeMode.Selectionable:
                this.addSelectedItem(result);
                break;

            case EParticipantClickTypeMode.GotoTarget:
                this.handleSpecificExtendedParticipant(result);
                break;
            default:
                break;
        }
        this.searchHandler.onFinishSelection();
        this.visibleResults = false;
        if (this.searchHandler.isResetAfterClick()) {
            this.searchInput = "";
        }
    }

    handleSpecificExtendedParticipant(result: ISearchResult): any {
        const resultType = <EObjectType>result.idObjectType;

        switch (resultType) {
            case EObjectType.group:
                this.routerSvc.goToGroup(result.primaryID);
                break;

            case EObjectType.interaction:
                this.routerSvc.goToInteractionID(
                    result.interactionAvatarDetail.idGroup,
                    result.primaryID
                );
                break;
            default:
                break;
        }

        this.closeSearchBox();
    }

    async goToGroup(group: ISearchResult): Promise<void> {
        if (this.isSelectionable()) {
            this.addSelectedItem(group);
        } else {
            await this.routerSvc.goToGroup(group.primaryID);
            this.closeSearchBox();
        }
    }

    async goToInteraction(interaction: ISearchResult): Promise<void> {
        await this.routerSvc.goToInteractionID(
            interaction.interactionAvatarDetail.idGroup,
            interaction.primaryID
        );
        this.closeSearchBox();
    }
    //#endregion

    private labels: {
        selected: string;
        cancel: string;
        select: string;
        groups: string;
        people: string;
        interactions: string;
        searchingFor: string;
        in: string;
        edit: string;
        placeholder: string;
        close: string;
    } = {
            groups: '',
            cancel: '',
            people: '',
            close: '',
            selected: '',
            select: '',
            interactions: '',
            searchingFor: '',
            in: '',
            edit: '',
            placeholder: ''
        };

    private translateLabels(): void {
        this.labels.cancel = Serializable.getTranslation(commonScreenOptionsTranslations.cancel);
        this.labels.people = Serializable.getTranslation(commonScreenOptionsTranslations.people);
        this.labels.searchingFor = Serializable.getTranslation(searchViewTranslations.searchingFor);
        this.labels.groups = Serializable.getTranslation(groupTranslations.groups);
        this.labels.interactions = Serializable.getTranslation(commonScreenOptionsTranslations2.interactions);
        this.labels.selected = Serializable.getTranslation(allCommonScreenOptionsTranslations.selected);
        this.labels.select = Serializable.getTranslation(searchViewTranslations.select);
        this.labels.in = Serializable.getTranslation(searchViewTranslations.in);
        this.labels.close = Serializable.getTranslation(commonScreenOptionsTranslations.close);
        this.labels.edit = Serializable.getTranslation(commonScreenOptionsTranslations2.edit);
        this.labels.placeholder = Serializable.getTranslation(searchViewTranslations.placeHolderAll);
    }

    get placeHolderLabel(): string {
        return this.labels.placeholder;
    }
    get selectedLabel(): string {
        return this.labels.selected;
    }
    get cancelLabel(): string {
        return this.labels.cancel;
    }
    get selectLabel(): string {
        return this.labels.select;
    }
    get groupsLabel(): string {
        return this.labels.groups;
    }
    get peopleLabel(): string {
        return this.labels.people;
    }
    get interactionsLabel(): string {
        return this.labels.interactions;
    }
    get searchingForLabel(): string {
        return this.labels.searchingFor;
    }
    get inLabel(): string {
        return this.labels.in;
    }
    get editLabel(): string {
        return !this.isShowSearchOptions
            ? this.labels.edit
            : this.labels.close
    }

    public resetGlobalSearchResults() {
        this.fullTextSearchResults = new Map();
    }

    public hasAnySNResults(): boolean {
        return isValidArray(this.groups) || isValidArray(this.avatars) || isValidArray(this.interactions);
    }

    public hasAnyGlobalResults(): boolean {
        return this.fullTextSearchResults.size > 0
    }

    public isSnSearch() {
        return this.selectedSearchType === ESearchContentType.SocialNetworkContent;
    }

    public isGlobalSearch() {
        return this.selectedSearchType === ESearchContentType.GlobalContent;
    }

    public isBot(searchableContent: INonSerializableSearcheableContent) {
        return searchableContent.nsType === ENonSerializableObjectType.bot;
    }

    public handleGlobalSearchRowClick(nsType: ENonSerializableObjectType, content: INonSerializableSearcheableContent) {
        switch (nsType) {
            case ENonSerializableObjectType.bot:
                this.routingSvc.goToRootGraphElement(content.idBPMRoot!, EBPMType.bot);
                this.closeSearchBox();
                break;

            case ENonSerializableObjectType.contentGenerator:
                this.routingSvc.goToContentGeneratorEdit(content.id);
                this.closeSearchBox();
                break;
        }
    }

    public getGroupName(group: ISearchResult): string {
        const groupJSON = group.universalJSON as IGroupJSON;

        if (groupJSON && groupJSON.groupType === constant.groupType.functionalRoot.corporate) {
            return Serializable.getJText(groupJSON, constant.serializableField.name);
        }

        return group.text;
    }
}
