import { MainHandler } from "../main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";
import { INonSerializableHeader, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { TColmeiaExtraPallete } from "app/utils/theme.types";
import { ThemePalette } from "@angular/material/core";
import { $string } from "@colmeia/core/src/tools/utility-types";
import { MatIcon } from "@angular/material/icon";
import { isValidFunction } from "@colmeia/core/src/tools/utility";


export interface INSPickerSelectionsHandlerClientCallback {
    onRemoveNSCallback(ns: INonSerializableHeader): void;
    onSelectableClick?(ns: INonSerializableHeader): void;
    hasSelectableClick?(): boolean;
    shouldDisableDelete?(ns: INonSerializableHeader): boolean;
    prefixMap?(ns: INonSerializable): NSSelecionPrefix.Item;
}

export interface INSPickerSelectionsHandlerParameter extends IComponentParameter {
    clientCallback: INSPickerSelectionsHandlerClientCallback;
    nonSerializables: INonSerializableHeader[];
    prefixMap?: { [key in string]: NSSelecionPrefix.Item }
    maxSelections: number;
}

export declare namespace NSSelecionPrefix {
    type Color = ThemePalette | TColmeiaExtraPallete;
    type Icon = $string;
    interface Item {
        icon?: Icon;
        size?: number
        color?: Color;
        customColor?: $string;
        textColor?: string;
        backgroundColor?: $string;
    }
}


export interface INSPickerSelectionsHandlerSlave {
    onSetNonSerializables(): void
}


interface INSPickerSelectionsHandler {
    safeSetNonSerializables(nonSerializables: INonSerializableHeader[]): void;

}
export class NSPickerSelectionsHandler extends MainHandler {

    slave: INSPickerSelectionsHandlerSlave;
    nonSerializables: INonSerializableHeader[];
    allowMultipleNonSerializableSelections: boolean;


    get maxSelections(): number {
        return this.getComponentParameter().maxSelections;
    }

    constructor(parameter: INSPickerSelectionsHandlerParameter) {
        super(parameter);

        this.nonSerializables = parameter.nonSerializables;

        this.allowMultipleNonSerializableSelections = this.maxSelections > 1;
    }
    
    safeSetNonSerializables(nonSerializables: INonSerializableHeader[]): void {
        this.nonSerializables = [...nonSerializables];

        if (this.slave)
            this.slave.onSetNonSerializables();
    }
    getComponentParameter(): INSPickerSelectionsHandlerParameter {
        return super.getComponentParameter() as INSPickerSelectionsHandlerParameter;
    }

    onRemoveNSCallback(ns: INonSerializableHeader): void {
        this.getComponentParameter().clientCallback.onRemoveNSCallback(ns);
    }

    setSlave(slave: INSPickerSelectionsHandlerSlave) {
        this.slave = slave;

        this.slave.onSetNonSerializables();
    }

    get parameters() {
        return this.getComponentParameter()
    }

    hasSelectableClick() {
        return this.parameters.clientCallback.hasSelectableClick
            ? this.parameters.clientCallback.hasSelectableClick()
            : isValidFunction(this.parameters.clientCallback.onSelectableClick)
        ;
    }

}