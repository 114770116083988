import { Injectable } from "@angular/core";
import { ColmeiaDialogService } from "./dialog/dialog.service";
import {
    AddToProdDialogComponent,
} from "../components/deploy-prod/add-to-prod-dialog/add-to-prod-dialog.component";
import { INSerDescription, INonSerializableBatchSaveDescriptor } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { EBatchSaveType, IBatchSaveToDeployNser } from "@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-req-res";

export type TPatchConfig = {
    type?: EBatchSaveType,
    allowedSaveTypes?: EBatchSaveType[]
}


@Injectable({
    providedIn: 'root'
})
export class AddToPatchService {

    constructor(
        private dialog: ColmeiaDialogService
    ) { }


    add(descriptors: IBatchSaveToDeployNser[], config?: Partial<TPatchConfig>): void {
        this.dialog.open<AddToProdDialogComponent, INonSerializableBatchSaveDescriptor>({
            componentRef: AddToProdDialogComponent,
            dataToComponent: {
                data: <INonSerializableBatchSaveDescriptor>{
                    descriptors,
                    type: config?.type,
                    allowedTypes: config?.allowedSaveTypes
                }
            }
        });
    }
}
