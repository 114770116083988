import { ToAvatar } from "../to-avatar";
import { TGlobalUID } from "../../../core-constants/types";
import { Participant } from "../../../business/participant";
import { constant } from "../../../business/constant";


export class TeamMembershipRequest extends ToAvatar {

    private constructor(primaryID: TGlobalUID, participant: Participant, idAvatar: TGlobalUID) {
        super(primaryID, participant, constant.subscription.request.avatar.teamMembershipRequest, idAvatar)
    };

    public static getNewTeamMembershipRequest(personalGroupParticipant: Participant, idAvatar: TGlobalUID): TeamMembershipRequest {
        return new TeamMembershipRequest(null, personalGroupParticipant, idAvatar)
    };

};
