<section 
  class="group-section"
  [class.current]="isCurrentGroup"
  [class.restricted-width]="restrictedWidth"
  [class.loading]="(isLoading | async) && !(isLoading | async)?.expired"
  [class.shake-effect]="shakeEffect">
  <ng-container *ngIf="isLoading | async as loading">
    <mat-progress-bar *ngIf="!loading.expired" mode="query" color="primary">
    </mat-progress-bar>  
  </ng-container>

  <ng-container *ngIf="attStatusEvent$ | async as attStatusEvent">
    <span *ngIf="attStatusEvent && !attStatusEvent.expired && !attStatusEvent.isStart" class="att-action-status">{{ attStatusEvent.message }}</span>
  </ng-container>

  <div class="inactivity-badge-container" [visible-vertical]="shouldShowBadge" [style.background-color]="thresholdColor"
    [style.color]="thresholdColor && getContrastColor(thresholdColor)">
    <mat-icon>priority_high</mat-icon>
  </div>

  <div class="group-logo">
    <customer-avatar *ngIf="isAttendanceGroup" [fullName]="getCardTitle()" [identifier]="getGroupID()"></customer-avatar>
    <hexagon *ngIf="!isAttendanceGroup" [hexagonHandler]="hexagonHandler"></hexagon>
  </div>
  <div class="group">
    <div class="group-info">
      <h5 class="card-title"
        [innerHTML]="getHighlightedCardTitle()">       
      </h5>
      <span class="last-message-time">{{ presentableTime() }}</span>
    </div>
    
    <div class="group-last-message">
      <p class="display-message">{{ this.getDisplay() }}</p>
      
      <ng-container *ngIf="!isAttendanceGroup">
        <ng-container *ngTemplateOutlet="unreadMessages"></ng-container>
      </ng-container>
    </div>
    
    <div *ngIf="handler.island" class="group-settings">
      <span #islandName class="island-name" [matTooltip]="handler.island.nName" [matTooltipDisabled]="!isIslandNameOverflowing">
        {{ handler.island.nName }}
      </span>

      <ng-container *ngTemplateOutlet="customerNotAnswerCount"></ng-container>
      <ng-container *ngTemplateOutlet="unreadMessages"></ng-container>
    </div>
  </div>
</section>

<ng-template #unreadMessages>
  <span class="unread-messages" [visible-horizontal]="hasIncomingMessages()" [matTooltip]="'Mensagens não lidas: '+ incomingMessagesCount()">
    {{ incomingMessagesCount() }}
  </span>
</ng-template>

<ng-template #customerNotAnswerCount>
  <span class="client-notifications" *ngIf="!!customerDoesNotAnswerCount()">
    <mat-icon class="mat-14">notifications</mat-icon>
    {{ customerDoesNotAnswerCount() }}
  </span>
</ng-template>