import { DateTime } from 'luxon';
import { msToSec } from '../../time/time-utl';
import { getOnlyDigits } from '../utility';
import { EValidFormatFinal, IDurationObject, TDateUtilsFormatType } from "./date-utils.types";

export class DateUtils {

    private date: DateTime

    private constructor(date: string, format: TDateUtilsFormatType) {
        this.date = this.validateAndBuild(date, format)
    }

    static createWithCustomFormat(date: string, format: string): DateUtils {
        return new DateUtils(date, format)
    }

    static createWithISOFormat(date: string): DateUtils {
        return new DateUtils(date, EValidFormatFinal.ISO8601)
    }

    static createWithIgnoringSeparators(date: string): DateUtils {
        const dateWithOnlyDigits = getOnlyDigits(date)
        const noseparatorDate: DateUtils = new DateUtils(dateWithOnlyDigits, EValidFormatFinal.ddmmyyyy)
        return noseparatorDate
    }

    static fromMiliSeconds(timeStamp: number): DateTime {
        return DateTime.fromSeconds(msToSec(timeStamp))
    }

    static now(): DateUtils {
        return DateUtils.createWithISOFormat(DateTime.now().toString())
    }

    isValid(): boolean {
        return this.date.isValid
    }

    getISODate(): string {
        return this.date.toISO()
    }

    isToday(): boolean {
        return this.date.hasSame(DateTime.local(), "day")
    }

    isBigger(anotherDate: DateUtils): boolean {
        return this.date > anotherDate.getDate()
    }

    isBiggerYearThan(year: number): boolean {
        return this.date.toObject().year > year
    }

    isSmaller(anotherDate: DateUtils): boolean {
        return this.date < anotherDate.getDate()
    }

    isSmallerYearThan(year: number): boolean {
        return this.date.toObject().year < year
    }

    isFuture(): boolean {
        return this.date > DateTime.now()
    }

    isPast(): boolean {
        return this.date < DateTime.now()
    }

    add(duration: IDurationObject): DateUtils {
        return DateUtils.createWithISOFormat(this.getDate().plus(duration).toString())
    }

    subtract(duration: IDurationObject): DateUtils {
        return DateUtils.createWithISOFormat(this.getDate().minus(duration).toString())
    }

    diff(anotherDate: DateUtils): IDurationObject {
        return this.getDate().diff(anotherDate.getDate()).toObject()
    }

    toMillis(): number {
        return this.getDate().toMillis()
    }

    private getDate(): DateTime {
        return this.date
    }

    private validateAndBuild(dateFromUser: string, format: TDateUtilsFormatType): DateTime {
        // const bestDateInputCustom = !isValidString(format) ? getOnlyDigits(dateFromUser) : dateFromUser 
        const date: DateTime = format === EValidFormatFinal.ISO8601
            ? DateTime.fromISO(dateFromUser)
            : DateTime.fromFormat(dateFromUser, format)
        return date
    }

    getFormat(format: string): string {
        return this.date.toFormat(format)
    }

    toSortedDateTime(): string {
        return this.date.toFormat("yyyyMMddHHmmss")
    }

    static isSameDay(d1, d2) {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }
}
