import { isValidRef } from "../../../tools/utility"
import { EBPMType } from "../../BPM/bpm-model"
import { GraphElement } from "../../graph/essential/graph-element"
import { gEdgeStylesBOT, gEdgeStylesCRM, gEdgeStylesMKT, gEdgeStylesNSTAI, gToolbarElementsBot, gToolbarElementsCRM, gToolbarElementsMKT, gToolbarElementsNestedAI } from "./config-toolbar.constants"
import { createToolbarElementsDB } from "./config-toolbar.functions"
import { IBasicToolbarElement, IConnectorStyles, TBPMConnectionStyleMap, TBPMConnectionTypes, TReferencedObject, TToolbarElementsDB } from "./config-toolbar.types"

export class BPMConfigToolbarSelector {
    private static bpmTypeToToolbarElementsMap: { [key in EBPMType]?: IBasicToolbarElement[] } = {
        [EBPMType.crm]: gToolbarElementsCRM,
        [EBPMType.bot]: gToolbarElementsBot,
        [EBPMType.marketing]: gToolbarElementsMKT,
        [EBPMType.nestedAI]: gToolbarElementsNestedAI,
    }
    private static bpmTypeToConnectionsMap: { [key in EBPMType]?: TBPMConnectionStyleMap } = {
        [EBPMType.crm]: gEdgeStylesCRM,
        [EBPMType.bot]: gEdgeStylesBOT,
        [EBPMType.marketing]: gEdgeStylesMKT,
        [EBPMType.nestedAI]: gEdgeStylesNSTAI,
    }
    private static bpmTypeToHashedToolbarElementsMap: { [key in EBPMType]?: TToolbarElementsDB } = {}

    static isValidConnectionInBPM(bpmType: EBPMType, fromType: TReferencedObject, toType: TReferencedObject): boolean {
        const targetElement: IBasicToolbarElement = BPMConfigToolbarSelector.getOneToolbarElement(bpmType, fromType)
        return isValidRef(targetElement.canConnectTo[toType]) || targetElement.canDragOn?.includes(toType);
    }

    static canDragOn(draggedType: TReferencedObject, toType: TReferencedObject, bpmType: EBPMType = EBPMType.bot): boolean {
        const droppedToolbarElements: IBasicToolbarElement<TReferencedObject>[] = BPMConfigToolbarSelector.getAllToolbarElementsOfRef(bpmType, draggedType);
        return droppedToolbarElements.some(toolbarElement => toolbarElement.canDragOn?.includes(toType));
    }

    static isValidNodeInBPM(bpmType: EBPMType, elementType: TReferencedObject, connectionType: TReferencedObject): boolean {
        console.log('isValidNodeInBPM', { elementType, connectionType });

        const targetElement: IBasicToolbarElement = BPMConfigToolbarSelector.getOneToolbarElement(bpmType, elementType)

        return isValidRef(targetElement)
            && isValidRef(targetElement.canConnectFrom)
            && Object.values(targetElement.canConnectFrom)
                .flat()
                .some(targetConnection => targetConnection == connectionType)
    }

    static getConnectionStyle(bpmType: EBPMType, connectionType: TBPMConnectionTypes): IConnectorStyles {
        const style: IConnectorStyles = BPMConfigToolbarSelector.bpmTypeToConnectionsMap[bpmType][connectionType]
        if (isValidRef(style)) {
            return style
        }
        const oldStyle = BPMConfigToolbarSelector.getOneToolbarElement(bpmType, connectionType).connectorStyle
        return oldStyle
    }

    static isSequencedNode(bpmType: EBPMType, element: GraphElement) {
        const hostedType: TReferencedObject = element.getHostedType();
        return BPMConfigToolbarSelector.getOneToolbarElement(bpmType, hostedType).isSequenced
    }

    static getConfigToolbarElements(bpmType: EBPMType): IBasicToolbarElement[] {
        return BPMConfigToolbarSelector.bpmTypeToToolbarElementsMap[bpmType]
    }

    static getOneToolbarElement(bpmType: EBPMType, bpmReferencedObj: TReferencedObject): IBasicToolbarElement {
        return BPMConfigToolbarSelector.getHashedConfigToolbarElements(bpmType)[bpmReferencedObj]
    }

    static getAllToolbarElementsOfRef(bpmType: EBPMType, bpmReferencedObj: TReferencedObject): IBasicToolbarElement[] {
        return BPMConfigToolbarSelector.bpmTypeToToolbarElementsMap[bpmType].filter(te => te.referencedObject === bpmReferencedObj);
    }

    private static getHashedConfigToolbarElements(bpmType: EBPMType): TToolbarElementsDB {
        let toolbarDB: TToolbarElementsDB = BPMConfigToolbarSelector.bpmTypeToHashedToolbarElementsMap[bpmType]
        if (!toolbarDB) {
            toolbarDB = BPMConfigToolbarSelector.bpmTypeToHashedToolbarElementsMap[bpmType] = createToolbarElementsDB(
                BPMConfigToolbarSelector.getConfigToolbarElements(bpmType)
            );
        }
        return toolbarDB
    }
}