import { typedScreenOptions } from "@colmeia/core/src/tools/barrel-tools";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";



export const nlpTransactionScreenOptions = typedScreenOptions({
  idSerializable: EAllSerializable.NlpTransaction,
  fields: {
      availableInfoText: 1, // Gerador de conteúdo
      lowPriority: 18, // low_priority
  }
});

const nlpTransaction = getTranslationsFields(nlpTransactionScreenOptions);

export const allNlpTranslatins = {
  ...nlpTransaction
}