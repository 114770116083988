import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { FeatureCarrier } from "@colmeia/core/src/interaction/interactions/feature-carrier-interaction";


export interface IFeatureCarrierParameter {
    interaction: Interaction;
}

export class FeatureCarrierHandler {

    constructor(private parameters: IFeatureCarrierParameter) { }

    getInteraction(): FeatureCarrier {
        return <FeatureCarrier>this.parameters.interaction;
    }
}
