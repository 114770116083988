import {Participant} from '../business/participant';
import {constant, TGlobalUID} from '../business/constant';
import {Interaction} from './interaction'
import {InteractionType} from './interaction-type';
import {IGrantSpokePersonStatusJSON} from '../comm-interfaces/interaction-interfaces';


export class GrantSpokePersonStatus extends Interaction {

    private grantee: Participant;
    private spokePersonParticipant: Participant;

    private constructor(primaryID: TGlobalUID, grantor: Participant, grantee: Participant) {
        super(primaryID, grantor, InteractionType.staticFactory(constant.interactionType.special.grantSpokeStatus));
        this.grantee = grantee;
    };

    public setGrantee(grantee: Participant): void {this.grantee = grantee; }
    public getGrantee(): Participant {return this.grantee; }

    public getSpokePersonParticipant(): Participant {
        return this.spokePersonParticipant;
    }

    public toJSON(): IGrantSpokePersonStatusJSON {
        return {
            ...super.toJSON(), 
            idGranteeParticipant: this.grantee.getParticipantID()
        };
    };

    public rehydrate(json: IGrantSpokePersonStatusJSON): void {
        super.rehydrate(json);
        if (json.spokePersonParticipant) {
            this.spokePersonParticipant = Participant.factoryMessage(json.spokePersonParticipant);
        }
    };

    public static staticFactory(idGrantSpokeStatus: TGlobalUID): GrantSpokePersonStatus {
        return <GrantSpokePersonStatus>Interaction.staticFactory(idGrantSpokeStatus);
    };

    public static factoryMessage(json: IGrantSpokePersonStatusJSON, participant: Participant = null): GrantSpokePersonStatus {
        let status: GrantSpokePersonStatus = <GrantSpokePersonStatus>Interaction.searchCachedInteraction(json.primaryID);
        if (status == null) 
            status = new GrantSpokePersonStatus(json.primaryID, 
                                        participant? participant: Participant.staticFactory(json.participant.primaryID),
                                        Participant.staticFactory(json.idGranteeParticipant));
        status.rehydrate(json);
        return status;
    };

    public static getNewGrantSpokePersonStatus(grantor: Participant, grantee: Participant): GrantSpokePersonStatus {
        return new GrantSpokePersonStatus(null, grantor, grantee);
    }






    



}