import { Injectable } from "@angular/core";
import { ServerCommunicationService } from "../server-communication.service";
import { RequestBuilderServices } from "../request-builder.services";
import { SessionService } from "../session.service";
import { IInfraParameters } from "app/model/component/client-infra-comm";
import {
    ICSVToSchemma, IProcessFileMetadada, IProcessFileRequest, IGetAllFileMetadata, TGeneralFileMetadataArray,
    IGetKBFilesMetadata, TFileFieldArray, IGeneralFileMetadata, IDeleteFileRequest, ICheckIfFileIsUsedInSomeDBRequest,
    ICheckIfFileIsUsedInSomeDBResponse, IDeleteFileContentResponse, IDeleteFileContentRequest, IProcessFileResponse
} from "@colmeia/core/src/request-interfaces/files-interfaces";
import { apiRequestType, EFileRequest } from "@colmeia/core/src/request-interfaces/message-types";
import { isInvalid, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { MultimediaInstance, MMconstant } from "@colmeia/core/src/multi-media/barrel-multimedia";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { MultimediaService } from "../multimedia.service";
import { idSchema, idPropertyLabel, idPropertyText } from '@colmeia/core/src/shared-business-rules/knowledge-base/kb-constants';
import { routeList, routeID } from "app/model/routes/route-constants";
import { RoutingService } from "../routing.service";
import { ITaggable } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { ITagableSearch } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { INonSerializableHeader, INonSerializable, ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { EJOBType, IJobDeleteFileServer } from "@colmeia/core/src/shared-business-rules/jobs/jobs-model";
import { kebabCase } from "lodash"

const baseRoute = routeList.dashboard.children.service.children.integration;
@Injectable({
    providedIn: 'root'
})
export class DashboardFileService {
    private _selectedFile: IGeneralFileMetadata;

    constructor(
        private api: ServerCommunicationService,
        private rbs: RequestBuilderServices,
        private session: SessionService,
        private multimediaSvc: MultimediaService,
        private routeSvc: RoutingService,
    ) { }


    private buildInfra(): IInfraParameters {
        return this.rbs.getNoCallBackSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );
    }

    public get selectedFile(): IGeneralFileMetadata {
        const selectedFile = this._selectedFile;
        this._selectedFile = undefined;
        return selectedFile;
    }

    public async getFileMetadataList(search: ITagableSearch, idKB: string = null, cursor: string = null): Promise<TGeneralFileMetadataArray> {
        const infra: IInfraParameters = this.buildInfra();

        const request: IGetKBFilesMetadata = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.getFilesMetadata, infra),
            ...search,
            idKB,
            cursor,
        }

        const response = await this.api.managedRequest(infra, request);

        return (<IGetAllFileMetadata>response.response).files;
    }

    public async processFileMetadata({
        customFileName, file, idSchemma,
        mapper, taggable, idKB = null, currentFileId, startedAsAnEmptyDB
    }: {
        customFileName: string,
        file: MultimediaInstance,
        idSchemma: string,
        mapper: ICSVToSchemma,
        taggable: ITaggable,
        idKB: TGlobalUID,
        currentFileId?: string,
        startedAsAnEmptyDB?: boolean,
    }): Promise<IProcessFileResponse | undefined> {
        const processFileMetadata: IProcessFileMetadada = {
            nsType: ENonSerializableObjectType.fileMetadata,
            fileName: file?.getClientFileInfo().getCurrentName() || kebabCase(customFileName) + '.csv',
            nName: customFileName, // @TODO must be input of user.
            file: {
                idMedia: file?.getIdMedia(),
                idMediaType: null,
                mymeType: null,
                size: file?.getClientFileInfo().getClientCachedFile().getFile().size,
            },
            idSchemma,
            mapper,
            startedAsAnEmptyDB,
        };

        if (taggable.tags) {
            processFileMetadata.tags = taggable.tags;
        }

        const infra: IInfraParameters = this.buildInfra();
        const request: IProcessFileRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.processFile, infra),
            processFileMetadata: processFileMetadata,
            idKB: idKB,
            taggable,
            currentFileId,
            isUpdate: isValidString(currentFileId)
        };

        const { response, executionOK } = await this.api.managedRequest(infra, request);

        return response as IProcessFileResponse | undefined;
    }

    public async processFileMetadataKB(file: MultimediaInstance, idKB: string, taggable: ITaggable): Promise<boolean> {
        await this.multimediaSvc.genericUploadFile(file, MMconstant.tag.csvUpload);

        const [headers, fileFields]: [TArrayID, TFileFieldArray] = await this.multimediaSvc.getCsvData(file, false);

        const out = await this.processFileMetadata({
            customFileName: file.getFileName(),
            file,
            idSchemma: idSchema,
            mapper: this.getKBMapper(headers),
            taggable,
            idKB
        });
        return isValidRef(out);
    }

    private getKBMapper(headers: TArrayID): ICSVToSchemma {
        const mapper: ICSVToSchemma = {};

        mapper[idPropertyText] = {
            name: headers[0],
            pos: 0,
        };

        mapper[idPropertyLabel] = {
            name: headers[1],
            pos: 1,
        };

        return mapper;
    }

    goToFileDetails(file: IGeneralFileMetadata, pathRoute?: string) {
        this._selectedFile = file;
        this.routeSvc.navigateToId(
            routeID.dashboard,
            pathRoute,
            baseRoute.path,
            baseRoute.children.fileDetail.path.replace(`${baseRoute.children.fileDetail.routeParam}`, file.idNS)
        );
    }

    public async deleteFileContents(
        idFileDatabase: string,
        idMediaOfCsvUsedToDelete: string,
        idPropertyToCsvHeaderNameMapper: ICSVToSchemma
    ): Promise<string> {
        const res: IDeleteFileContentResponse = <IDeleteFileContentResponse>await this.api.doRequest<IDeleteFileContentRequest>(
            EFileRequest.deleteFileContent, {
            data: {
                idFileDatabase,
                idMediaOfCsvUsedToDelete,
                idPropertyToCsvHeaderNameMapper
            }
        })
        return res?.jobIdNS
    }

    public async delete(idFile: string): Promise<void> {
        await this.api.doRequest<IDeleteFileRequest>(EFileRequest.deleteFile, {
            idFile
        })
    }

    public async isFileUsed(idMedia: string): Promise<string> {
        const infra: IInfraParameters = this.buildInfra();
        const request: ICheckIfFileIsUsedInSomeDBRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.checkIfFileIsBeingUsedInSomeDatabase, infra),
            data: {
                idMedia
            }
        };
        const response = await this.api.managedRequest(infra, request);
        return response.executionOK && (response.response as ICheckIfFileIsUsedInSomeDBResponse).databaseName;
    }
}

