import {TGlobalUID} from '../business/constant';

import {IUniversalJSON, IContainerObjectJSON
} from './business-interfaces';

export type IMultimediaTagJSONArray = Array<IMultimediaTagJSON>;
export type IMultimediaTypeJSONArray = Array<IMultimediaTypeJSON>;


export interface IMultimediaInstanceJSONBase {
    mimeType: string;
    idMedia: string,
    fileName: string;
    fileSize: number;
    fileHash: string,
}

export interface IMultimediaInstanceJSON extends IMultimediaInstanceJSONBase {
    multiMediaKey: TGlobalUID;
    idMultiMediaTag: TGlobalUID;
    idThumbnailParentKey?: TGlobalUID;
};


export interface IMultimediaObjectJSON extends IContainerObjectJSON {
    multiMediaInstanceArray: Array<IMultimediaInstanceJSON>;
};

export interface IMultimediaTagJSON  extends IUniversalJSON{
    idMultimediaType: TGlobalUID;
};

export interface IMultimediaTypeJSON extends IUniversalJSON {
    htmlType: string;
    multimediaNature: string;
}

