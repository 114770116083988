import { $Extends } from "@colmeia/core/src/tools/utility/types/validate";
import { Assert, DeepMap, GetByKey, IsEqual, Narrowable, SpecificOverload, UnNarrow } from "@colmeia/core/src/tools/utility-types";
import type { Custom } from "@colmeia/core/src/tools/utility/types/generic";
import * as _ from 'lodash';
import { narrow } from "@colmeia/core/src/tools/utility/functions/narrow";

export namespace TextTemplate {

    export const defaultTemplateOptions = {
        interpolate: /{{(?<name>[\s\S]+?)}}/g,
    }

    export function buildInterpolatePattern(config?: TemplateVariableConfig) {
        if (config === undefined) return defaultTemplateOptions.interpolate;
        return new RegExp(
            defaultTemplateOptions.interpolate.source
                .replace(defaultTemplateConfig.prefix, _.escapeRegExp(config.prefix))
                .replace(defaultTemplateConfig.suffix, _.escapeRegExp(config.suffix))
            ,
            defaultTemplateOptions.interpolate.flags
        );
    }

    
    type OnlyLiterals<T extends Narrowable, NonLiteral extends Narrowable = UnNarrow<T>, IsNonLiteral extends boolean = IsEqual<T, NonLiteral>> = [IsNonLiteral] extends [false] ? NonLiteral : never;

    type ExtractVariables<
        T extends string = string,
        Config extends TemplateVariableConfig = DefaultTemplateConfig,
        Current extends string[] = [],
        Items extends string[] = T extends `${infer Prefix}${VariableText<infer Item, Config>}${infer Suffix}` ? ExtractVariables<Suffix, Config, [...Current, Item]> : Current,
    > = Items;

    type TrimChars = ' ' | '\n'
    type Trim<T> =
        T extends `${TrimChars}${infer Text}` ? Trim<Text> :
        T extends `${infer Text}${TrimChars}` ? Trim<Text> :
        T
        ;

    export interface TemplateVariableConfig {
        prefix: string;
        suffix: string;
    }

    const defaultTemplateConfig = narrow<TemplateVariableConfig>()({ prefix: '{{', suffix: '}}' });

    type DefaultTemplateConfig = typeof defaultTemplateConfig;

    type VariableText<
        T extends string = string,
        Config extends TemplateVariableConfig = DefaultTemplateConfig
    > = `${Config['prefix']}${T}${Config['suffix']}`;


    export type ComputeVariables<
        T extends string = string,
        Config extends TemplateVariableConfig = DefaultTemplateConfig,
        Items extends string[] = ExtractVariables<T, Config>,
        TrimmedItems = Custom.Chain<Items, [
            Custom.$Utility.Map<Custom.$Utility.Split<' '>>,
            Custom.$Utility.Map<Custom.$Utility.Join<''>>,
        ]>,
        Properties extends string = Assert<GetByKey<TrimmedItems, number>, string>
    > = { [key in Properties]: string | number };

    export const template = buildTemplate();

    export function buildTemplate<
        Config extends $Extends<Config, TemplateVariableConfig> = DefaultTemplateConfig,
    >(config?: Config) {
        return template;

        function template<T extends string>(text: T & SpecificOverload<T>): (source: ComputeVariables<T, Config>) => string;
        function template(text: string): (source: Record<string, string | number>) => string;
        function template(text: string) {
            return _.template(text, {
                ...defaultTemplateOptions,
                interpolate: buildInterpolatePattern(config),
            })
        }
    }


    const patternNameText = 'name'
    const patternWithName = /(?<name>.*)/;
    const patternWithNumericBackReference = /(\1)/;
    const patternWithNameBackReference = /\k<name>/;

    function buildPatternOfName(name: string) {
        return patternWithName.source.replace(patternNameText, name);
    }

    function buildBackReferenceOfPatternIndex(index: number) {
        return patternWithNumericBackReference.source.replace('1', String(index));
    }

    function buildNameBackReferenceOfPatternName(name: string) {
        return patternWithNameBackReference.source.replace(patternNameText, name);
    }

    export function buildTemplateExtractor(text: string) {
        const map: DeepMap<[name: string, index: number]> = new Map();
        let index = 0;

        const out = text.replace(defaultTemplateOptions.interpolate, (item, inputName) => {
            index++;
            const name = inputName.trim();
            if (!map.has(name)) {
                map.set(name, index);
                return buildPatternOfName(name);
            }
            return buildNameBackReferenceOfPatternName(name);
        })

        return new RegExp(out);
    }
}


export import template = TextTemplate.template;
