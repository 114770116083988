import { EBotEventType, IBotEvent, TBotEventArray } from "@colmeia/core/src/shared-business-rules/bot/bot-event-model";
import { ENextGenBotElementType } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EIslandEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-other-event-config-db";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { TVarEditorVariables } from "app/handlers/var-editor.handler";

export interface IBotEventHandlerParameters {
    events: TBotEventArray;
    branchType: ENextGenBotElementType | EIslandEvent;
    variables?: TVarEditorVariables;
    idKB?: string;
}

export class BotEventsHandler {

    constructor(private parameters: IBotEventHandlerParameters){ }

    get branchType(): ENextGenBotElementType | EIslandEvent {
        return this.parameters.branchType;
    }

    get events(): TBotEventArray {
        return this.parameters.events;
    }

    get idKB(): string {
        return this.parameters.idKB;
    }

    get variables(): TVarEditorVariables {
        return this.parameters.variables
    }

    setEvent(event: IBotEvent): void {
		const idx = this.events.findIndex((ev: IBotEvent) => ev.idAsset === event.idAsset);
		if(idx == -1){
			this.events.push(event);
		} else {
			this.events[idx] = event;
		}
	}

    removeEvent(event: IBotEvent): void {
        const idx = this.events.findIndex((ev) => ev.idAsset === event.idAsset);
		this.events.splice(idx, 1);
    }

    hasIncludedEventType(eventType: EBotEventType): boolean {
        const foundEvent = this.events.find(ev => ev.type === eventType);
        return isValidRef(foundEvent);
    }

}
