import { SignalType } from './signal-constants';


export abstract class MainSignal {

    private signalType: SignalType;
    private status: boolean;
    private carrier: any;


    constructor(observableType: SignalType, status: boolean, carrier: any = null) {
        this.signalType = observableType;
        this.status = status;
        this.carrier = carrier;
    };

    public isSucces(): boolean {return this.status; };

    public callMe(signalType: SignalType): boolean {
        return this.signalType == signalType;
    }

    public setFail(): void {this.status = false;};
    public setSuccess(): void {this.status = true;};

    public setObservableInformation(info: any): void {
        this.carrier = info;
    };

    public getObservableInformation(): any {
        return this.carrier;
    };
};











