import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-body',
    templateUrl: './modal-body.component.html',
    styleUrls: ['./modal-body.component.scss']
})
export class ModalBodyComponent implements OnInit {
    @Input() title: string;
    @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) { }
    
    ngOnInit() { }

    onCloseClicked() {
        this.modalClosed.emit()
    }
}
