<div class="wrapper">
  <div class="top-container">
    <div class="top-left-content">
      <div class="text-styles-buttons">
        <ng-container *ngIf="!shouldHideBBCode">
          <ng-container *ngFor="let item of textStyleModifiers | keyvalue: textFormatSort">
            <button
              *ngIf="item.value.isEnabled"
              class="text-style-button"
              [ngClass]="'tsb-'+item.key"
              [class.is-active]="isActiveTextStyleActive(item.key)"
              (click)="handleTextStyleClick($event, item.key)"
              [matTooltip]="item.value.tooltip"
              matTooltipPosition="above">
              <mat-icon class="mat-18">{{ item.value.matIcon }}</mat-icon>
            </button>
          </ng-container>
        </ng-container>
        <div class="text-area-tools">
          <div class="l-left-tools">
            <button *ngIf="!shouldHideEmojis" class="toggle-emoji" [class.active]="varEditorUIConfig.showEmojiBox" (click)="toggleEmojiBox()">
              <mat-icon class="mat-22">tag_faces</mat-icon>
            </button>
      
            <button class="toggle-raw" [class.active]="varEditorUIConfig.viewRawCode" (click)="toggleViewRaw()">
              <mat-icon class="mat-24">raw_on</mat-icon>
            </button>
      
            <div class="font-size-buttons" [matTooltip]="varEditorUIConfig.fontSize+'%'" matTooltipPosition="above">
              <button class="font-size-button" (click)="updateTextSize(varEditorUIConfig.fontSize - textUpdateStep)">
                <mat-icon class="mat-14">text_decrease</mat-icon>
              </button>
              <button class="font-size-button" (click)="updateTextSize(varEditorUIConfig.fontSize + textUpdateStep)">
                <mat-icon class="mat-14">text_increase</mat-icon>
              </button>
              <button *ngIf="varEditorUIConfig.fontSize !== varEditorUIConfigDefault.fontSize" (click)="updateTextSize(varEditorUIConfigDefault.fontSize)" class="font-size-button l-reset">
                <mat-icon class="mat-14">restart_alt</mat-icon>
              </button>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="top-right-content">
      <div class="text-length-count">
        <span *ngIf="rawTextCharCount !== amoutCharacters" class="chars-count" helperInfo="Alguns emojis ocupam mais de um espaço" hiIndicatorStyle="right: -20px;">caracteres: {{ rawTextCharCount }}</span>
        <span *ngIf="shouldShowCanonicalAlert()" class="chars-count" helperInfo="Certifique-se que o valor retornado pelos canônicos no momento do envio não irá ultrapassar o limite de caracteres" hiIndicatorStyle="right: -20px;" hiIcon="error_outline">canônicos: </span>
        {{ amoutCharacters }} / {{ maxCharsLength }}
      </div>
    </div>
  </div>
  <div class="content-wrapper">
    <div 
      #tiptapContainer
      class="var-editor-tiptap">
    </div>
  </div>
</div>

<ng-template #mentionsListTpl>
  <mat-card class="variables-card">
    <ul class="variables-list">
      <ng-container *ngFor="let item of popoverVariablesList; index as i">
        <li (click)="selectVariable(item)" (mouseenter)="hoverSuggestion(i)" role="button" aria-pressed="false" class="variables-list-item" [class.selected]="selectedMentionIdx === i">{{ item.variableWithoutBrackets }}</li>
      </ng-container>
    </ul>
  </mat-card>
</ng-template>
