import { Injectable } from '@angular/core';
import { constant } from '@colmeia/core/src/business/constant';
import { Participant } from '@colmeia/core/src/business/participant';
import { IChainedInteractionJSON, IInteractionJSON } from "@colmeia/core/src/comm-interfaces/interaction-interfaces";
import { ISubscription, ISubscriptionInteractionJSON } from "@colmeia/core/src/comm-interfaces/subscription-interfaces";
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { Interaction, TInteractionArray } from "@colmeia/core/src/interaction/interaction";
import { InteractionType } from "@colmeia/core/src/interaction/interaction-type";
import { DottedTeamMembershipRequest } from '@colmeia/core/src/interaction/subscription/request/dotted-team-member';
import { EmployeeRequest } from "@colmeia/core/src/interaction/subscription/request/employee-request";
import { FriendshipRequest } from '@colmeia/core/src/interaction/subscription/request/friendship';
import { InviteToGroupRequest } from "@colmeia/core/src/interaction/subscription/request/invite-group";
import { PrivateMessageRequest } from "@colmeia/core/src/interaction/subscription/request/private-message";
import { RealTimeRequest } from "@colmeia/core/src/interaction/subscription/request/real-time";
import { TeamMembershipRequest } from '@colmeia/core/src/interaction/subscription/request/team-member';
import { SubscriptionType } from "@colmeia/core/src/interaction/subscription/subscription-type";
import { ConnectionToSerializable } from "@colmeia/core/src/interaction/subscription/to-serializable";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import {
    ISerializableHeaderRequest,
    ISubscriptionsStatusRequest, IUnsubscribeRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import {
    ISerializableHeaderResponse,
    ISubscriptionsStatusResponse
} from "@colmeia/core/src/request-interfaces/response-interfaces";
import { TSerializableHeaderQuery, TSerializableHeaderResponse } from "@colmeia/core/src/serializable/header";
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { IInfraParameters } from "../model/client-infra-comm";
import { GroupChatServices } from "./group-chat.services";
import { RequestBuilderServices } from "./request-builder.services";
import { SerializableHeaders } from "./serializable-headers";
import { ServerCommunicationService } from "./server-communication.service";
import { SessionService } from "./session.service";
import { SusbcriptionContainerService } from "./subscriptions.service";
@Injectable({
    providedIn: 'root'
})
export class SubscriptionInteractionInfoBuilder {

    constructor(
        private sessionSVC: SessionService,
        private groupChatSVC: GroupChatServices,
        private rbs: RequestBuilderServices,
        private serverAPI: ServerCommunicationService,
        private sub: SusbcriptionContainerService,
        private dialogSvc: ColmeiaDialogService,
    ) { }

    public async fetchGroupsAndAvatarsFromInteractions(interactionArray: TInteractionArray): Promise<TSerializableHeaderResponse> {
        const toQuery = interactionArray.reduce(
            (acc: TSerializableHeaderQuery, val: Interaction) => {
                const iType: InteractionType = val.getInteractionType();
                if (iType.isSubscription()) {
                    const SubType: SubscriptionType = (<ConnectionToSerializable>val).getSubscriptionType();
                    const subInfo: ISubscription = (<ConnectionToSerializable>val).getSubsjectInfo();
                    if (SubType.isGroupToSubject()) {
                        acc.push(
                            {
                                serializableID: val.toJSON().idGroup,
                                objectTypeID: constant.objectType.group
                            },

                            {
                                serializableID: subInfo.idAvatarSubscriber,
                                objectTypeID: constant.objectType.avatar
                            },
                            {
                                serializableID: subInfo.toSubject,
                                objectTypeID: constant.objectType.group
                            }
                        );
                    }
                } else if (val.toJSON().idInteractionType === constant.interactionType.subscription.stdResponse
                    && (<IChainedInteractionJSON>val.toJSON()).idInteractionTypeParent === constant.interactionType.subscription.subscription) {
                    acc.push({
                        serializableID: val.toJSON().participant.avatar.primaryID,
                        objectTypeID: constant.objectType.avatar
                    })
                }
                return acc;
            },
            []
        );
        return this.serializablesHeader([... new Set(toQuery)]);
    }

    public async fetchSerializableHeadersIfNeededFromJSON(interaction: IInteractionJSON): Promise<void> {
        const iType = InteractionType.staticFactory(interaction.idInteractionType);
        if (iType.isSubscription()) {
            const subType: SubscriptionType = SubscriptionType.staticFactory((<ISubscriptionInteractionJSON>interaction).idSubscriptionType);
            if (subType.isGroupToSubject()) {
                const subInfo: ISubscription = (<ISubscriptionInteractionJSON>interaction).subs;
                if (!subInfo) {
                    return;
                }
                const toQuery: TSerializableHeaderQuery = [
                    {
                        serializableID: subInfo.idAvatarSubscriber,
                        objectTypeID: constant.objectType.avatar
                    },
                    {
                        serializableID: subInfo.toSubject,
                        objectTypeID: constant.objectType.group
                    }
                ];
                const mustBeQueried: TSerializableHeaderQuery = SerializableHeaders.filterSerializableHeadersNeeded(toQuery);
                if (mustBeQueried.length) {
                    SerializableHeaders.appendSerializableHeaders(
                        await this.serializablesHeader(mustBeQueried)
                    )
                }
            }
        } else if (iType.is(constant.interactionType.subscription.stdResponse)) {
            const avatarID = interaction.participant.avatar.primaryID;
            const groupID = interaction.idGroup;
            const query: TSerializableHeaderQuery = SerializableHeaders.filterSerializableHeadersNeeded([
                {
                    serializableID: avatarID,
                    objectTypeID: constant.objectType.avatar
                },
                {
                    serializableID: groupID,
                    objectTypeID: constant.objectType.group
                }
            ]);
            if (query.length) {
                SerializableHeaders.appendSerializableHeaders(
                    await this.serializablesHeader(query)
                );
            }
        }

    }

    public async fetchSerializableHeadersIfNeeded(interaction: Interaction): Promise<void> {
        return this.fetchSerializableHeadersIfNeededFromJSON(interaction.toJSON());
    }

    public async serializablesHeader(toQuery: TSerializableHeaderQuery): Promise<TSerializableHeaderResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters(),
            request: ISerializableHeaderRequest = {
                ... this.rbs.secureBasicRequest(apiRequestType.serializable.header),
                query: SerializableHeaders.filterSerializableHeadersNeeded(toQuery)
            },
            response = await this.serverAPI.managedRequest(infra, request);

        if (response.executionOK) {
            return (<ISerializableHeaderResponse>response.response).serializables
        }

        return {};
    }


    async getFollowableStatus(primaryID: TGlobalUID, objectType: TGlobalUID) {

        const infra: IInfraParameters = this.rbs.getContextNoCallBackSpinnningParameters();
        const req: ISubscriptionsStatusRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.subscription.status),
            subject: primaryID,
            from: null,
            subjectObjectType: objectType
        };
        req.from = req.idAvatar;
        const res = await this.serverAPI.managedRequest(infra, req);

        if (res.executionOK) {
            return (<ISubscriptionsStatusResponse>res.response).statuses;
        }

        return [];
    }

    public async unfollow(subType: TGlobalUID, resourceID: TGlobalUID, resourceObjectType: TGlobalUID): Promise<boolean> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const req: IUnsubscribeRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.subscription.unsubscribe),
            subType,
            subject: resourceID,
            from: null,
            subjectObjectType: resourceObjectType
        };
        req.from = req.idAvatar;
        const res = await this.serverAPI.managedRequest(infra, req);
        return res.executionOK;
    }

    public async follow(subscriptionTypeId: TGlobalUID, idObjectType: TGlobalUID, primaryID: TGlobalUID): Promise<boolean> {
        const participant: Participant = this.sessionSVC.getCurrentPersonalParticipant();
        let executionOk: boolean = false;
        let response: FriendlyMessage;
        if (idObjectType == constant.objectType.avatar) {
            switch (subscriptionTypeId) {
                case constant.subscription.request.avatar.realTime: {
                    const interaction = RealTimeRequest.getNewRealTimeRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
                case constant.subscription.request.friendship: {
                    const interaction = FriendshipRequest.getNewFriendshipRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
                case constant.subscription.request.dottedTeamMembership: {
                    const interaction = DottedTeamMembershipRequest.getNewDottedTeamMembershipRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
                case constant.subscription.request.avatar.teamMembershipRequest: {
                    const interaction = TeamMembershipRequest.getNewTeamMembershipRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
                case constant.subscription.request.inviteToGroup: {
                    const interaction = InviteToGroupRequest.getNewInviteToGroupRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
                case constant.subscription.request.privateMessage: {
                    const interaction = PrivateMessageRequest.getNewPrivateMessageRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
            }
        } else if (idObjectType == constant.objectType.interaction) {
            //switch (subscriptionTypeId) {
            // break;
            //}
        } else if (idObjectType == constant.objectType.group) {
            switch (subscriptionTypeId) {
                case constant.subscription.request.group.groupParticipation: {
                    // await this.openSecurityTasksEdit(SecurityTasksEditHandler.factory({
                    //     participant,
                    //     idGroup: primaryID,
                    //     clientCallback: {},
                    //     action: ESecurityTaskAction.create
                    // }));

                }
                    break;
                case constant.subscription.request.group.employee: {
                    const interaction = EmployeeRequest.getNewEmployeeRequest(
                        participant,
                        primaryID
                    );
                    response = await this.groupChatSVC.saveInteraction(interaction, [], null);
                    executionOk = response.isOk();
                }
                    break;
            }
        }
        return executionOk;
    }
}
