import { Injectable, NgZone } from "@angular/core";
import { getUniqueStringID } from "@colmeia/core/src/tools/utility";
import { ELocalQueueName } from "@colmeia/core/src/core-queue/core-queue-service";
import {QueuService} from "./queue.service";

export interface IAndroidBackButtonAction {
    onAndroidBackButtonPressed: () => void
}

@Injectable({
    providedIn: 'root'
})
export class UserActionService {

    constructor (
        private queuServices: QueuService
    ) {}

    public addAction(component: IAndroidBackButtonAction): void {
        this.queuServices.enqueue(ELocalQueueName.androidBackButton, {data: component, id: getUniqueStringID(10)})
    }

    public closeLastAction(): boolean {
        const entry = this.queuServices.getNextEntry(ELocalQueueName.androidBackButton);
        console.log("ENTRY RETORNADA:", entry);
        let hasClosed: boolean = false;
        if (entry) {
            const component:IAndroidBackButtonAction = <IAndroidBackButtonAction>entry.data;
            component.onAndroidBackButtonPressed();
            hasClosed = true;
        };
        return hasClosed;
    }
}
