import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBotSubject } from '@colmeia/core/src/shared-business-rules/bot/bot-interfaces';
import { RootComponent } from 'app/components/foundation/root/root.component';
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { isValidTrimmedString, typedClone } from '@colmeia/core/src/tools/utility';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
	selector: 'app-bot-subject',
	templateUrl: './bot-subject.component.html',
	styleUrls: ['./bot-subject.component.scss']
})
export class BotSubjectComponent extends RootComponent<
    'action' | 
    'subject' | 
    'save' |
    'cancel' |
    'newBotSubjet' |
    'newBotAction'
> implements OnInit {
    botSubject: IBotSubject;

    subjectsList: string[];
    actionsList: string[];
    
    separatorKeysCodes: number[] = [ENTER, COMMA, SPACE]

	constructor(
        private dialogRef: MatDialogRef<BotSubjectComponent, IBotSubject>,
        @Inject(MAT_DIALOG_DATA) botSubject: IBotSubject,
    ) {
        super({
            action: gTranslations.common.action,
            subject: gTranslations.common.subject,
            save: gTranslations.common.save,
            cancel: gTranslations.common.cancel,
            newBotSubjet: gTranslations.common.newBotSubjet,
            newBotAction: gTranslations.common.newBotAction
        });
        
        this.botSubject = typedClone(botSubject);
    }

    setupLists() {
        this.subjectsList = isValidTrimmedString(this.botSubject.subject)
            ? this.botSubject.subject.trim().split(' ')
            : [];

        this.actionsList = isValidTrimmedString(this.botSubject.action)
            ? this.botSubject.action.trim().split(' ')
            : [];
    }

    updateBotSubject() {
        this.botSubject.subject = this.subjectsList.join(' ');
        this.botSubject.action = this.actionsList.join(' ');
    }

    ngOnInit() {
        this.setupLists();
    }

    save(): void {
        this.dialogRef.close(this.botSubject);
    }

    add(event: MatChipInputEvent, list: string[]): void {
        const input = event.chipInput.inputElement;
        const value = event.value;

        if ((value || '').trim()) {
            list.push(value.trim());
            this.updateBotSubject();
        }

        if (input) {
            input.value = '';
        }
    }

    remove(target: string, list: string[]): void {
        const index = list.indexOf(target);

        if (index >= 0) {
            list.splice(index, 1);
            this.updateBotSubject();
        }
    }
}
