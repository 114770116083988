<ng-container *ngIf="socialNetworkNavigation$ | async as snNav">
  <div class="sn-loading" *ngIf="!snNav.expired">
    <div class="container">
      <hexagon [hexagonHandler]="snNav.hexagon"></hexagon>
    </div>
  </div>
</ng-container>
<section class="group-home">
  <div class="group-home__container">
    <section
      *ngIf="!isInAllRoot"
      class="header group-header__container"
      [ngStyle]="bannerStyle"
    >
      <div class="group-header__wrapper">
        <div class="group-header__hexagon-container">
          <hexagon
            (click)="goToChat()"
            [hexagonHandler]="groupHexagonHandler"
          ></hexagon>
          <img
            *ngIf="hasAnnotation()"
            class="group-header__hexagon-container--has-annotation"
            (click)="openAnnotations()"
            src="./assets/icons/annotation-circle.svg"/>

        </div>

        <div class="group-header__info">
          <h2 class="group-name" (click)="goToChat()">{{ group ? group.getName() : '' }}</h2>

          <div class="nav-container">
            <div class="nav-container__btns">
              <a
                *ngFor="let navOption of navButtons"
                (click)="onBtnClicked(navOption.option)"
                [class.nav-container__btn--selected]="isButtonSelected(navOption.option)"
                >{{ getTranslation(navOption.translation) }}</a
              >
            </div>
            <div class="group-buttons">
                <cm-follow-button class="group-buttons__btn" [handler]="followButtonHandler"></cm-follow-button>

                <app-more-options-three-dots
                  class="group-buttons__more-options"
                  *ngIf="canShowMoreOptions()"
                  [handler]="moreOptionsHandler"
                  [isWhiteMoreOptions]="true">
                </app-more-options-three-dots>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="breadcrumb breadcrumb__container" *ngIf="!isInAllRoot">
      <i class="material-icons icon-home">home</i>
      <cm-breadcrumb *ngIf="showBreadcrumb" [descriptor]="breadCrumbDescriptor"></cm-breadcrumb>
    </div>

    <div class="filters filters__centered">
      <div class="group-filter__container" *ngIf="!isButtonSelected(navButtonsEnum.About)">
          <h3>{{ translations.filterGroupTitle }}</h3>
          <button class="group-filter__container--filter-btn" type="button" (click)="toggleFilter()">
              <i class="material-icons arrow" *ngIf="!canShowFilter">keyboard_arrow_down</i>
              <i class="material-icons arrow" *ngIf="canShowFilter">keyboard_arrow_up</i>
          </button>
        </div>
      <ul
        class="filters__container"
        [ngClass]="{ 'filters__container--hidden': showArrowUp }"
        *ngIf="!isButtonSelected(navButtonsEnum.About) && canShowFilter"
      >
        <li class="filters__box" *ngFor="let filter of allFilters">
          <label [for]="filter.getPrimaryID()" class="filters__title">{{ filter.getName() }}</label>
          <button
            [id]="filter.getPrimaryID()"
            class="filters__item btn--sm btn--white"
            (click)="onFilterClicked($event, filter)"
          >
            <span class="filter__selected">{{ getSelectedOptionName(filter) }}</span>
            <i class="material-icons">keyboard_arrow_down</i>
          </button>
        </li>
      </ul>
    </div>

    <div class="group-description cards" *ngIf="isButtonSelected(navButtonsEnum.About)">
      <app-card [cardDescriptor]="aboutCardDescriptor">{{ group ? group.getDescription() : ''}}</app-card>
      <app-card [cardDescriptor]="publicationsCardDescriptor"></app-card>
    </div>

    <app-group-card-list></app-group-card-list>
  </div>
</section>
