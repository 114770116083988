import { ENonSerializableObjectType, INonSerializable } from "../non-serializable-id/non-serializable-id-interfaces";
import { EMetadataNames } from "../metadata/metadata-db";

export interface ILocalCanonical extends INonSerializable {
    nsType: ENonSerializableObjectType.canonical;
    globalCanonical: EMetadataNames;
    isNotVisibleByAgent?: boolean;
    isLGPDSensitive?: boolean;
}


export type TCanonicalDB = {[idProperty: string]: IServerLocalCanonical};
export interface IServerLocalCanonical extends ILocalCanonical, INonSerializable {
    nsType: ENonSerializableObjectType.canonical;
};

export function isILocalCanonical(ns: INonSerializable): ns is ILocalCanonical {
    return ns.nsType === ENonSerializableObjectType.canonical;
}

export type ILocalCanonicalArray = Array<ILocalCanonical>;
export type IServerLocalCanonicalArray = Array<IServerLocalCanonical>;
