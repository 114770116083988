import { typedScreenOptions,  typedFields } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { EBBCodeStyles } from "../../bbcode/bbcode-types";

export const bbCodeStylesTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.BBCodeFields,
    fields: typedFields<EBBCodeStyles>()({
        [EBBCodeStyles.Italic]: 1, // Itálico
        [EBBCodeStyles.Bold]: 2, // Negrito
        [EBBCodeStyles.Strikethrough]: 3, // Tachado
        [EBBCodeStyles.Code]: 4, // Código
        [EBBCodeStyles.Monospace]: 5, // Monoespaço
        [EBBCodeStyles.Menu]: 6, // Menu
    })
}));

export const bbCodeTranslations = {
    ...bbCodeStylesTranslations,
};