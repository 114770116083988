import {Participant} from '../../business/participant';
import {TGlobalUID} from '../../business/constant';
import {Interaction} from '../interaction'
import {InteractionType} from '../interaction-type';
import { EWalletOperationsIT } from '../../business/constant.enums';
import { EWalletOperation, IAmount, IWalletOperationInteractionJSON } from '../../comm-interfaces/finance-interfaces';


export abstract class WalletOperationInteraction extends Interaction {
    private walletOperation: EWalletOperation;
    private amount: IAmount;
    private idSenderAvatar: TGlobalUID;
    

    protected constructor(primaryID: TGlobalUID, participant: Participant, walletOperation: EWalletOperation) {
        super(primaryID, participant, InteractionType.staticFactory(EWalletOperationsIT.operation));
        this.walletOperation = walletOperation;
        this.idSenderAvatar = participant.getAvatar().getAvatarID();
    };

    public rehydrate(json: IWalletOperationInteractionJSON): void {
        super.rehydrate(json);
        this.walletOperation = json.walletOperation;
        this.amount = json.amount;
        this.idSenderAvatar = json.wallet.idSenderAvatar
    };

    public getSenderAvatarID(): TGlobalUID {return this.idSenderAvatar; };

    public getAmountValue(): number {
        return this.amount.amount;
    };

    public setAmountValue(amount: IAmount) {
        this.amount = amount;
    };

    public toJSON(): IWalletOperationInteractionJSON {
        return {
            ...super.toJSON(),
            walletOperation: this.walletOperation,
            amount: this.amount,
            wallet: {
                idSenderAvatar: this.idSenderAvatar,
                idSenderPlayer: null,
                pairIDPlayer: null,
            }
        };
    };

    public getTransactionType(): EWalletOperation {
        return this.walletOperation;
    };
};