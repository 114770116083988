import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { GeneralFormFieldInjectionToken, IGeneralFormFieldData, GeneralFormFieldRenderer } from 'app/model/general-form.model';
import { GeneralFormField } from '@colmeia/core/src/general-form/general-form-answer';
import { GeneralFormFieldService } from '../services/general-form-field.service';
import { GeneralFormFieldViewerHandler } from '../general-form-field-viewer/general-form-field-viewer.handler';
import { SchemaProperty } from '@colmeia/core/src/general-form/general-form-interface';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-general-form-object-field',
    templateUrl: './general-form-object-field.component.html',
    styleUrls: ['./general-form-object-field.component.scss']
})
export class GeneralFormObjectFieldComponent extends GeneralFormFieldRenderer<null> {

    expanded: boolean = false;
    title: string;

    constructor(
        generalFormFieldService: GeneralFormFieldService,
        @Inject(GeneralFormFieldInjectionToken) public injectedToken: IGeneralFormFieldData,
        private cdr: ChangeDetectorRef
    ) {
        super(generalFormFieldService, injectedToken);
        this.value = this.value || {};
        if (injectedToken.isList) {
            this.title = `Elemento ${injectedToken.childIndex + 1}`;
        } else {
            this.title = this.field.prompt;
        }

        
    }

    public initHandlers(): void {
        this.handlers = this.field.nestedSchema
            .map(item => this.getGeneralFormFieldViewerHandler(item))
        ;
    }

    public handlers: GeneralFormFieldViewerHandler[];

    public getGeneralFormFieldViewerHandler(property: SchemaProperty) {
        return new GeneralFormFieldViewerHandler({
            schemaProp: property,
            value: this.value[property.propertyName],
            viewType: this.viewType,
            fieldCreation: item => this.onInnerFieldCreation(item),
            fControl: undefined, // this.fGroup.get(property.idProperty) as FormControl
            generalFormEvaluator: this.injectedToken.generalFormEvaluator,
            fGroup: undefined, // this.injectedToken.fGroup
            answer: undefined, // this.injectedToken.answer
            fields: this.injectedToken.fields,
            field: this.field,
            schemaForm: this.injectedToken.schemaForm,
            clientCallback: this,
            idStartInteraction: this.injectedToken.idServiceInteraction,
        });
    }

    public get isExpanded(): boolean {
        return this.expanded && (this.canDisplayFormGroup ? !this.isDisabled : true); 
    }

    async ngOnInit() {
        this.init();
    }
    async init() {
        super.ngOnInit();
        if (this.injectedToken.parentExpandEvent) {
            this.injectedToken.parentExpandEvent.subscribe(expanded => {
                this.expanded = expanded;
                this.cdr.detectChanges();
            });
        }

        this.initHandlers()
    }


    async ngOnDestroy() {
        super.ngOnDestroy();
    }

    onInnerFieldCreation(innerField: GeneralFormField) {
        this.field.addChild(innerField);
    }

    expandChange(expanded: boolean) {
        this.expanded = expanded;
        if (this.injectedToken.childExpandEvent) {
            this.injectedToken.childExpandEvent.next(expanded);
        }
    }
}
