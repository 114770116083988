import { Injectable } from '@angular/core';
import { constant } from '@colmeia/core/src/business/constant';
import { Participant } from '@colmeia/core/src/business/participant';
import { InteractionRehydrator } from '@colmeia/core/src/interaction/interaction-rehydrator';
import { isValidArray } from '@colmeia/core/src/tools/utility';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { Interaction, TInteractionArray } from '@colmeia/core/src/interaction/interaction';
import { FeedbackInteraction } from '@colmeia/core/src/interaction/interactions/feedback-interaction';
import { Message, Citation, VoteInteraction } from '@colmeia/core/src/interaction/interactions/standard-interaction';
import { DeleteInteraction } from '@colmeia/core/src/interaction/interactions/deleted-interaction';
import { Spokeperson } from '@colmeia/core/src/interaction/spoke-person';
import { GrantSpokePersonStatus } from '@colmeia/core/src/interaction/grant-spoke-status';
import { IInteractionJSONArray } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { PublishingInteractionOnGroup } from '@colmeia/core/src/interaction/interactions/multi-group-publisher';
import {SessionService} from "./session.service";

/**
 * Service that works as helpers for interactions and handles functions like create and send
 */
@Injectable()
export class InteractionService {

    constructor(private sessionService: SessionService) { }

    /**
     * Single function to create interactions
     * @param  {InteractionType} interactionType  [description]
     * @param  {Participant}     participant      [description]
     * @param  {Interaction}     parentInteracion [description]
     * @param  {Feedback}        feedback         [description]
     * @return {Interaction}                      [description]
     */
    public createNewInteraction(interactionType: InteractionType, participant: Participant,
                                parentInteracion: Interaction, feedback? : Feedback): Interaction {
        return this.factoryInteraction(interactionType, participant, parentInteracion, feedback);
    }

    /**
     * Create interaction based on the interactionType it receives
     * @param  {InteractionType} interactionType  [description]
     * @param  {Participant}     participant      [description]
     * @param  {Interaction}     parentInteracion [description]
     * @param  {Feedback}        feedback         [description]
     * @return {Interaction}                      [description]
     */
    private factoryInteraction(interactionType: InteractionType, participant: Participant,
                               parentInteracion: Interaction, feedback? : Feedback): Interaction {

        switch (interactionType.getPrimaryID()) {

            // Message
            case constant.interactionType.standard.message:
                return Message.getNewMessage(participant, '');

            // Citation
            case constant.interactionType.standard.citation:
                return Citation.getNewCitation(participant, parentInteracion, '');


            // Feedback Interaction
            case constant.interactionType.feedback.react:
            case constant.interactionType.feedback.denounce:
                return FeedbackInteraction.getNewFeedbackInteraction(participant, interactionType, parentInteracion, null);

            // Spokeperson
            case constant.interactionType.special.setSpokeAvatar:
                return Spokeperson.getNewSpokeperson(participant, '');

            // GrantSpokePersonStatus
            case constant.interactionType.special.grantSpokeStatus:
                return GrantSpokePersonStatus.getNewGrantSpokePersonStatus(participant, null);

            // Vote
            case constant.interactionType.feedback.vote:
                if (feedback)
                    return VoteInteraction.getNewVote(participant,  parentInteracion, feedback);
                else
                    return VoteInteraction.getNewVote(participant,  parentInteracion, Feedback.staticFactory(constant.feedback.opcao1));

            case constant.interactionType.standard.deleteInteraction:
                return DeleteInteraction.getNewDeleteInteraction(participant, parentInteracion);

            // Multi group publish
            case constant.interactionType.sharing.startPublishGroup:
                return PublishingInteractionOnGroup.getNewPublishingInteractionOnGroup(participant, '');

            default:
                throw Error('InteractionService | createNewInteraction | Não consegui criar essa interação');
        }
    }

    /**
     * The function will handle all the clicks we have in interaction.
     * We have to analyse what interaction type we have and make a decision
     * @param {Interaction} interaction [description]
     */
    // TODO Parar ser usado quando tivermos o menu de 3 pontinhos vamos chamar essa funlção e passar o interactionType,
    // para fazer um roteamento do que cada interação fará no sistema
    public handleClickInteraction(interaction: Interaction): void {
        // Personal Group interactions
        if (interaction.getParticipant().getGroup().getGroupID() == this.sessionService.getPlayer().getPersonalGroup().getGroupID()) {
        }
    }

    /**
     * Rehydrate all interactions received
     * @param  {IInteractionJSONArray} interactions [description]
     * @return {Interaction[]}                      [description]
     */
    public rehydrateInteractions(interactions: IInteractionJSONArray, alreadyRehydrated: TInteractionArray): Interaction[] {
        let rehydratedInteractions: Interaction[] = [];
        if (isValidArray(interactions)) {
            rehydratedInteractions = InteractionRehydrator.rehydrator(interactions, alreadyRehydrated);
        };
        return rehydratedInteractions;
    }
}
