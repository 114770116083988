import { EPaymentProviderType, IPaymentMessageActionsConfigs } from "../metadata/meta-engagement";
import { IPaymentCardShoppingItemMap } from "../user-function/user-function-model";
import { IPaymentsConnectionAuth } from "./payments-requests";

export enum EPaymentItemAttributes {
  idProduct = 'idProduct',
  price = 'price',
  description = 'description',
  name = 'name',
  quantity = 'quantity',
  
}

export interface IPaymentItem {
  [EPaymentItemAttributes.idProduct]: string;
  [EPaymentItemAttributes.price]: number;
  [EPaymentItemAttributes.description]: string;
  [EPaymentItemAttributes.name]: string;
  [EPaymentItemAttributes.quantity]: number;
}

export interface IPaymentAttrMap {
  idProperty: string;
  paymentAttribute: EPaymentItemAttributes
}

export type TIPaymentAttrMapArray = Array<IPaymentAttrMap>

export interface IPaymentMap {
  idMetadata: string;
  mapping: TIPaymentAttrMapArray
}

export interface IPaymentShoppingCartItemController {
  isValidItem(item: IPaymentItem): boolean;
  addItem(items: IPaymentItem): void;
  delItem(item: IPaymentItem): boolean;
  hasItem(item: IPaymentItem): boolean;
  dehydrate(): IPaymentItem[];
}

export enum EPaymentOrderStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  canceled = 'canceled',
  expired = 'expired',
  failed = 'failed',
  refunded = 'refunded',
  chargedBack = 'charged_back',
  
} 

export const PaymentStatusFailed: EPaymentOrderStatus[] = [
  EPaymentOrderStatus.failed,
  EPaymentOrderStatus.canceled
]
export interface IPaymentProvider {
  paymentProviderIdNS: string;
  isOn: boolean;
}

export interface IPaymentPayer {
  fullName?: string;
  cpfOrCnpj?: string;
  email?: string;
}

export interface IPaymentExternalGatewayData extends IPaymentProvider  {
  externalGatewayId: string;
  externalUrlCheckout: string;
  externalPixCode?: string;
  type: EPaymentProviderType;
  paymentTransactionId: string;
  orderId: string;
  currentPaymentStatus: EPaymentOrderStatus;
  idSocialContext: string,
  clockTick: number;
  lastTouch: number;
  config: IPaymentsConnectionAuth;
}

export interface IPaymentTransaction {
  paymentTransactionId: string;
  items: IPaymentItem[];
  itemsMap: IPaymentCardShoppingItemMap[];

  botIdNS?: string;
  conversationIdNS: string;
  channelIdNS: string;
  channelName?: string;
  address: string;
  idSocialContext: string;
  ordemName: string;

  paymentFail?: IPaymentMessageActionsConfigs;
  paymentSuccess?: IPaymentMessageActionsConfigs;
  target: string;
  payer: IPaymentPayer;
  clockTick: number;
  lastTouch: number;
  paymentProviders: IPaymentExternalGatewayData[];
  orderId: string;
}

export interface IPaymentTransactionStatus {
  statusColmeia: EPaymentOrderStatus;
  paymentTransactionId: string;
  paymentOrderId: string;
  createdAt: number;
  idSocialContext: string;
  statusGateway: string;
  paymentProviderType: EPaymentProviderType;
  externalGatewayId: string;
}
