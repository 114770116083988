import { TNserValidator } from "../validate-nser";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { ENextGenBotElementType } from "../../../bot/bot-model";
import {
    botTransactionKnowledgeBaseError,
    botTransactionSelectMetadataError, missingLLMProviderError, needToFillAccuracyEvent,
    nserInsertNameError,
    nserSmallNameError,
    screenOptionToError
} from "../error-messages";
import { minLength, nserNameMinLength } from "../helpers";
import { isInvalid, isValidRef, isValidArray } from "../../../../tools/utility";
import { IFormTransactionServer, INLPTransactionServer, ITransactionServer } from "../../../knowledge-base/bot-transaction/bot-transaction";
import { EBotEventType, IBotEvent } from "../../../bot/bot-event-model";
import { validateBotAsset } from "./bot-asset";
import { isNLP } from "../../../bot/bot-function-model-helper";
import { IEngagementConfigSolicitation, IWAFEngagementConfig } from '../../../metadata/meta-engagement';
import { IBPMActionSolicitation } from '../../../BPM/bpm-action-model';
import { botTransactionErrors } from '../error-fields';
import { ESolicitationDestination } from "../../../you-control/solicitation-tasks-model";
import { WAFValidator } from "@colmeia/core/src/shared-business-rules/waf/waf.validator";
import { KBAssetType } from "../../../bot/bot-asset-model";

const errors = screenOptionToError(botTransactionErrors);

export const botTransactionValidator: TNserValidator = (nser: ITransactionServer, friendly: FriendlyMessage) => {
    if (!nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    if (isInvalid(nser.idSchemma) && (nser.botLevel === ENextGenBotElementType.formTransaction)) {
        friendly.addReturnResponse(botTransactionSelectMetadataError)
    }
    if (isValidRef(nser.submitOnComplete) && nser.submitOnComplete) {
        // if(isInvalid(nser.idEndPointRoute)){
        //     friendly.addReturnResponse(botTransactionSelectRouteError);
        // }
    }

    if (isNLP(nser.botLevel)) {
        const nlp = (<INLPTransactionServer>nser).nlp;
        if (isInvalid(nlp.idKB))
            friendly.addReturnResponse(botTransactionKnowledgeBaseError);

        if (!nser.events.find(evt => evt.type === EBotEventType.accuracyEvent))
            friendly.addReturnResponse(needToFillAccuracyEvent);

        const hasGenerative = Object.values(nlp.assets).some(assetArray =>
          assetArray.some(asset => isValidRef(asset.generativeConfiguration) )
        )
        /**
         * If there is any intent with action to generative the NLP form must has a LLM provider selected
         */
        if (hasGenerative && !nlp.providers?.llm?.idConnection) {
            friendly.addReturnResponse(missingLLMProviderError);
        }
    }

    if (nser.botLevel === ENextGenBotElementType.formTransaction) {
        const ns = <IFormTransactionServer>nser;
        const solicConfig: IEngagementConfigSolicitation = ns.engagement.engagementConfig?.metaSolic as IEngagementConfigSolicitation;
        if (isValidRef(solicConfig)) {
            const solicAction = solicConfig.evaluators[0]?.action as IBPMActionSolicitation;
            if (isValidRef(solicAction)) {
                if (!solicAction.destType)
                    friendly.addReturnResponse(errors.emptySolicDestType);
                else
                    switch (solicAction.destType) {
                        case ESolicitationDestination.AllFromGroup:
                            if (!solicAction.destId) friendly.addReturnResponse(errors.emptySolicGroup);
                            break;
                        case ESolicitationDestination.IslandRandom:
                            if (!solicAction.destId) friendly.addReturnResponse(errors.emptySolicIsland);
                            break;
                        case ESolicitationDestination.ToAvatar:
                            if (!solicAction.destId) friendly.addReturnResponse(errors.emptySolicAgent);
                            break;
                    }
            } else {
                friendly.addReturnResponse(errors.emptySolicAction);
            }
        }


        const wppFlowsConfig = ns.engagement.engagementConfig?.whatsAppFlow as IWAFEngagementConfig;
        if (isValidRef(wppFlowsConfig) && !WAFValidator.validateFlowEngagement(ns.engagement)) {
            friendly.addReturnResponse(
                WAFValidator.getFlowEngagementErrors(ns.engagement)
            );
        }
    }

    const events = nser.events;
    if (isValidArray(events)) {
        events.forEach(evt => {
            const errors = validateBotAsset(<IBotEvent>evt);
            if (isValidArray(errors)) {
                console.log(errors);
            }
        })
    }

    return friendly;
}
