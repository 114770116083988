import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import {HandlerHexagonon} from "../handlers/hexagono.handler";

export interface ToolIcon {
    url: string;
    backgroundColor?: string;
}

export interface IToolsHanlder {
    name: string;
    sections: TToolsData;
    selector?: string;
    includeArrowMobile?: boolean;
    visualizationMode: EToolsVisualizationMode;
    onVisibilityChange?: (visible: boolean) => void;
}

export enum EToolsVisualizationMode {
    List = 1,
    Page = 2,
}

export interface ToolSection {
    header?: string;
    centerHeader?: boolean;
    headerMobileOnly?: boolean;
    rightButtonIcon?: string | ToolIcon;
    onRightIconClick?: () => void;
    hexagons?: HandlerHexagonon[];
    serializables: TSerializableArray;
    onItemClick: (hexagon: HandlerHexagonon) => void;
    getItemURL?: (hexagon: HandlerHexagonon) => void;
    itemURL?: string;
}

export type TToolsData = Array<ToolSection>;
