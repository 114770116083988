import { Serializable } from "../business/serializable";
import { Group } from '../business/group';

import { constant, TGlobalUID } from '../business/constant';
import * as security_constants from '../core-constants/security-constant';
import { TGroupArray, TArrayID } from "../core-constants/types";
import { TGroupGenealogy, IUniversalJSON, TArrayIDJSON, IUniversalJSONArray, TDriverFeedbackArray, IGroupJSON, IGroupParentJSON } from "../comm-interfaces/business-interfaces";
import { GroupType } from "../business/group-type";
import { ObjectType } from "../business/object-type";
import { TPersonalResponseArray, IPersonalResponse, TIDueDateAppointmentArray, IDueDateAppointment, TGoPermissionArray, IInteractionJSON } from "../comm-interfaces/interaction-interfaces";
import { isValidArray, getClock, isValidRef, isValidAndEqual, throwCustomFieldError, isInvalidArray, isInvalid, isEmptyObject } from "../tools/utility";
import { Avatar } from "../business/avatar";
import { Interaction, TInteractionArray } from "../interaction/interaction";
import { TInteractionTypeArray, InteractionType } from "../interaction/interaction-type";
import { TFeedbackArray } from "../interaction/feedback";
import { SecurityItem } from "../security/security-item";
import { TSecurityItemArray, SecuritySettings } from "../security/security-settings";
import { ISocketInteractionResponse, IResponse } from "../request-interfaces/response-interfaces";
import { socketConfig } from "../core-constants/socket.conf";
import { ESCode } from "../error-control/barrel-error";
import { UberCache } from "../persistency/uber-cache";


export function isAllRoot(idGroup: TGlobalUID): boolean {
    return idGroup == constant.entity.rootGroups.root;
};

export function isRoot(idGroupType: TGlobalUID): boolean {
    return GroupType.staticFactory(idGroupType).isRoot();
}

export function getFirstLevelChildren(idGroup: TGlobalUID, groupArray: TGroupArray): TGroupArray {
  return groupArray.filter((group) => {return group.isMyParentOfLevel(idGroup) == 0})
};


export function getChildrenGroupsOfType(childGroups: TGroupArray, idGroupType: TGlobalUID): TGroupArray {
    return childGroups.filter(childGroup => childGroup.getGroupType().getPrimaryID() === idGroupType);
}


export function needToAskToParticipate(group: Group): boolean {
    return group.getSecuritySettings() ? group.getSecuritySettings().isSecurityRequested(security_constants.domain.enrollmentRule.subscriptionAfterApproval) : false;
};

export function onlyInviteesCanParticipant(group: Group): boolean {
    return group.getSecuritySettings() ? group.getSecuritySettings().isSecurityRequested(security_constants.domain.enrollmentRule.onlyInvitedCanParticipate) : false;
};

export function automaticParticipation(group: Group): boolean {
    return ! (needToAskToParticipate(group) || onlyInviteesCanParticipant(group));
}

export function getStandardGroup(groupArray: TGroupArray): TGroupArray {
    return groupArray.filter((g) => {return g.getGroupType().isCommon()});
};

export function getPersonalGroups(groupArray: TGroupArray): TGroupArray {
    return groupArray.filter((g) => {return g.getGroupType().isPersonal() || g.getMySocialNetworkGroupType().isPersonal()});
};


export function getFunctionalGroups(groupArray: TGroupArray): TGroupArray {
    return groupArray.filter((g) => {return g.getGroupType().isFunctional()});
};

export function getChatGroupFilter(groupArray: TGroupArray): TGroupArray {
    return getStandardGroup(groupArray).filter((grp) => {return grp.getGroupType().isNot(
        constant.groupType.standard.channel)});
}

export function noRootGenealogy(genealogy: TGroupGenealogy): TGroupGenealogy {
    if (isValidArray(genealogy)) {
        return genealogy.filter((g) => 
        {return !GroupType.staticFactory(g.idGroupTypeParent).isRoot(); }); 
    };
    return [];
};

export function getSocialNetworkFromGenealogy(idGroup: TGlobalUID,  genealogy: TGroupGenealogy): TGlobalUID {
    if (isInvalidArray(genealogy)) {
        return idGroup;
    }
    const gen: IGroupParentJSON = genealogy.find((g) => {
        return GroupType.staticFactory(g.idGroupTypeParent).isFunctionalRoot()
    });
    // Se não houver functionalRoot, já é o functionalRoot, pois acima só roots.
    return gen ? gen.idGroupParent : idGroup;
};

export function getGroupParentID(group: IGroupJSON): TGlobalUID {
    return isValidArray(group.genealogy) ? group.genealogy[0].idGroupParent : null;
};


export function getParentLevel(genealogy: TGroupGenealogy, level: number = 0): TGlobalUID {
    return genealogy.find((g) => {return g.level == level;}).idGroupParent;
};

export function isValidGenealogy(genealogy: TGroupGenealogy): boolean {
    
    return isValidArray(genealogy) && 
         ! genealogy.some((g) => {return isInvalid(g.idGroupTypeParent) || (isInvalid(g.name) && !GroupType.staticFactory(g.idGroupTypeParent).isRoot())})
}
    
export type TPrimaryIdToGroupJsonDB = { [primaryID in string]: IGroupJSON };

export function fixGenealogy(idGroup: TGlobalUID, genealogy: TGroupGenealogy, db: TPrimaryIdToGroupJsonDB): void {
    const content: Group = UberCache.unsafeUberFactory(idGroup, false) as Group;
    if (isInvalid(content)) return; // isso é apenas enquanto o sistema carrega
    const idGroupParent: TGlobalUID = content.getGroupParentID();
    const groupParent: Group = UberCache.unsafeUberFactory(idGroupParent, false) as Group;
    

    if (isValidRef(db[idGroup]) && isValidArray((db[idGroup]).genealogy)) {
        genealogy.splice(0);
        genealogy.push(...(db[idGroup]).genealogy);
    } else if (!isEmptyObject(groupParent)) {
        genealogy.splice(0);
        genealogy.push(...[
            groupToGenealogyEntry(groupParent), //
            ...groupParent.getPlainGroupGenealogy() // 
        ]);
    };
}


export function groupToGenealogyEntry(group: Group): IGroupParentJSON {
    return {
        idGroupParent: group.getGroupID(),
        idGroupTypeParent: group.getGroupType().getPrimaryID(),
        name: group.getName(),
        level: null,
    }
}


export function getGroupSocialNetworkID(group: Group): TGlobalUID {
    if (group.getGroupType().isRoot()) {
        return constant.entity.rootGroups.root;
    } else if (group.getGroupType().isFunctionalRoot()) {
        return group.getPrimaryID();
    } else if (isValidArray(group.getPlainGroupGenealogy())) {
        const gen: IGroupParentJSON = group.getPlainGroupGenealogy().find((g) => { return GroupType.staticFactory(g.idGroupTypeParent).isFunctionalRoot()});
        if (gen) {
            return gen.idGroupParent;
        }
    }
    throwCustomFieldError(ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true, 'getSocialNetworkID');
}

export function getSocialNetworkID(group: IGroupJSON): TGlobalUID {
    const groupType: GroupType = GroupType.staticFactory(group.groupType);
    if (groupType.isRoot()) {
        return constant.entity.rootGroups.root;
    } else if (groupType.isFunctionalRoot()) {
        return group.primaryID;
    } else if (isValidArray(group.genealogy)) {
        const gen: IGroupParentJSON = group.genealogy.find((g) => {return GroupType.staticFactory(g.idGroupTypeParent).isFunctionalRoot()});
        if (gen) {
            return gen.idGroupParent;
        }
    }
    throwCustomFieldError(ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true, 'getSocialNetworkID');
};


export function flatGenealogy(genealogy: TGroupGenealogy): TArrayID {
    return genealogy.map((g) => {return g.idGroupParent});
}

export function flatGenealogyGroupType(genealogy: TGroupGenealogy): TArrayID {
    return genealogy.map((g) => {return g.idGroupTypeParent});
}



export function flatDWGenealogy(genealogy: TGroupGenealogy, idGroup: TGlobalUID): TArrayID {
    return [idGroup].concat(genealogy.map((g) => {return g.idGroupParent}));
}


export function isGroupOnGenealogy(idGroup: TGlobalUID, genealogy: TGroupGenealogy): boolean {
    return genealogy.some((g) => {return g.idGroupParent == idGroup; }) ;
}

export function sameUniversal(u1: IUniversalJSON, u2: IUniversalJSON): boolean {
    return u1.primaryID == u2.primaryID;
};


// filters an array of groups of a certain type
export function getGroupsOfType(groups: TGroupArray, groupTypeID: TGlobalUID): TGroupArray {
    return groups.filter(group => group.getGroupType().getPrimaryID() === groupTypeID);
};

// excludes an array of groups of a certain type
export function excludeGroupsOfType(groups: TGroupArray, groupTypes: TGlobalUID): TGroupArray {
    return groups.filter(group => group.getGroupType().getPrimaryID() !== groupTypes);
};

export function isSecurityCarriers(serializable: Serializable): boolean {
    return ObjectType.staticFactory(serializable.getObjectTypeID()).is(
        constant.objectType.avatar, constant.objectType.group
    );
};

export function addDefaultToSecuritySettings(securitySettings: SecuritySettings): void {
    securitySettings.addHash(SecurityItem.getNewSecurityItem(security_constants.domain.avatarPrivacy.friendship.open));
    securitySettings.addHash(SecurityItem.getNewSecurityItem(security_constants.domain.avatarPrivacy.addInPrivateMessage.open));
    securitySettings.addHash(SecurityItem.getNewSecurityItem(security_constants.domain.avatarPrivacy.teamMembership.afterApproval));
};

export function hasDemandedSecurity(items: TSecurityItemArray, privilege: TGlobalUID): boolean {
    return items.some((p) => {return p.getGroupSecurityDomain().is(privilege) && p.isSelected()})
};

export function isParticipantBindToParent(securityItems: TSecurityItemArray): boolean {
    return hasDemandedSecurity(securityItems, security_constants.domain.securityChain.securityAttachedToParent);
}


export function addPersonalResponseToInteraction(interaction: IInteractionJSON , goPermissions: TGoPermissionArray, idGroup: TGlobalUID, ...idAvatarArray: TArrayIDJSON): void {
    const iType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType);
    if (iType.canAddAPersonalResponseToThis()) {
        if (! interaction.personalResponse) {
            interaction.personalResponse = [];
        };
        addToPersonalResponse(interaction.personalResponse, goPermissions, idGroup, ...idAvatarArray);
    };
};


export function addToPersonalResponse(personalResponse: TPersonalResponseArray, goPermissions: TGoPermissionArray, idGroup: TGlobalUID, ...idAvatarArray: TArrayIDJSON): void {
    const personal: IPersonalResponse = getPersonalResponseForThisGroup(personalResponse, idGroup);
    if (personal) {
        if (!isValidArray(personal.idAvatarArray)) {
            personal.idAvatarArray = [];
        }
        for (const idAvatar of idAvatarArray) {
            if (!personal.idAvatarArray.some((avt) => {return avt == idAvatar})) {
                personal.idAvatarArray.push(idAvatar);
            };
        };
        if (!isValidArray(personal.goPermissions)) {
            personal.goPermissions = [];
        };
        for (const permission of goPermissions) {
            if (!personal.goPermissions.some((per) => {return per.idPermissionRole == permission.idPermissionRole})) {
                personal.goPermissions.push(permission);
            };
        };
    } else {
        personalResponse.push({idGroup: idGroup, idAvatarArray: idAvatarArray, goPermissions: goPermissions});
    };   
};


export function idGroupIsRoot(idGroup: TGlobalUID): boolean {
    return idGroup == constant.entity.rootGroups.enterprise || idGroup == constant.entity.rootGroups.tribes;
};

export function excludeMyAvatarOf(idAvatar: TGlobalUID, avatars: TArrayIDJSON): TArrayIDJSON {
    return avatars.filter((avt) => {return avt != idAvatar});
};

export function getUniversalFromArray<T extends IUniversalJSON>(primaryID: TGlobalUID, universalArray: IUniversalJSONArray): T {
    return <T>universalArray.find((universal) => {return universal.primaryID == primaryID});
}


export function isReplicatingGroup(interaction: Interaction): boolean {
    return interaction.getPublishingGroups()[0].replicate;
};


export function isPersonalOnlyToMe(personalResponse: TPersonalResponseArray, idGroup: TGlobalUID, idAvatar: TGlobalUID): boolean {
    let toMe: boolean = false;
    if (isValidArray(personalResponse)) {
        const prGroup: IPersonalResponse = getPersonalResponseForThisGroup(personalResponse, idGroup);
        if (prGroup && isValidArray(prGroup.idAvatarArray) && prGroup.idAvatarArray.length == 1) {
            toMe = prGroup.idAvatarArray[0] == idAvatar;
        };
    };
    return toMe;
};



export function getPersonalResponseForThisGroup(personalResponse: TPersonalResponseArray, idGroup: TGlobalUID): IPersonalResponse {
    if (isValidArray(personalResponse)) {
        return personalResponse.find((pr) => {return pr.idGroup == idGroup});
    };
    return null;
};

export function getPersonalResponseArrayForThisGroup(personalResponse: TPersonalResponseArray, idGroup: TGlobalUID): TPersonalResponseArray {
    const personal: IPersonalResponse = getPersonalResponseForThisGroup(personalResponse, idGroup);
    return personal ? [personal]: [];
};

export function getAllGroupsFromPersonalResponse(interaction: IInteractionJSON): TArrayID {
    return isValidArray(interaction.personalResponse) ? interaction.personalResponse.map((p) => {return p.idGroup;}) : [];
};




export function getAvatarsApointmentsForThisGroup(appointments: TIDueDateAppointmentArray, idGroup: TGlobalUID): TArrayID {
    let avatarArray: TArrayID = [];
    if (isValidArray(appointments)) {
        const appointment: IDueDateAppointment = appointments.find((app) => {return app.idGroup == idGroup});
        if (appointment) {
            avatarArray = appointment.idAvatarArray;
        };
    };
    return avatarArray;
};

export function allRespondedForThisGroupAppointment(
    idGroup: TGlobalUID, 
    appointmentList: TIDueDateAppointmentArray, 
    children: TInteractionArray): boolean {
    
    const avatars = getAvatarsApointmentsForThisGroup(appointmentList, idGroup);
    const allAvatarsResponded = avatars.every(
        avatarId => answeredToThisAppointment(
            idGroup, 
            avatarId, 
            appointmentList, 
            children))

    return allAvatarsResponded;
}

export function isThisAppointmentToThisAvatar(
    idGroup: TGlobalUID, 
    idAvatar: TGlobalUID, 
    appointmentList: TIDueDateAppointmentArray): boolean {

    const avatars: TArrayID = getAvatarsApointmentsForThisGroup(appointmentList, idGroup);
    const isAppointmentToMe = avatars.some(avatar => avatar === idAvatar);

    return isAppointmentToMe;
}

export function answeredToThisAppointment(
    idGroup: TGlobalUID,
    idAvatar: TGlobalUID,
    appointmentList: TIDueDateAppointmentArray, 
    childrenInteractions: TInteractionArray): boolean {
    
    const isThisAppointmentToMe = isThisAppointmentToThisAvatar(idGroup, idAvatar, appointmentList);
    
    const isResponded = childrenInteractions.some(
        interaction => 
            isThisAppointmentToMe
            && interaction.isProcessedByServer() 
            && interaction.getParticipant().getAvatar().is(idAvatar))

    return isResponded;
}


export function getChatViewModeInteractionsOnly(isChatViewMode: boolean, interactions: TInteractionArray): TInteractionArray {
    return interactions.filter(() => isChatViewMode ? false : true)
}

export function getOnlyServerProcessedInteractions(interactions: TInteractionArray): TInteractionArray {
    return interactions.filter(interaction => interaction.isProcessedByServer())
}

export function isPendencyInteraction(groupID: TGlobalUID, interaction: Interaction, myAvatar: Avatar, onlyOpened: boolean): boolean {
    const interactionParticipant = interaction.getParticipant()
    const isSameAvatar = myAvatar.iss(interactionParticipant.getAvatar());
    const isDueDateStillOpened = onlyOpened ? interaction.isEventOcurred(false, getClock()) : true;
    
    return isDueDateStillOpened
        && interaction.isTimedType(constant.timedType.dueDate)
        && !isSameAvatar
        && !answeredToThisAppointment(
            groupID,
            myAvatar.getAvatarID(),
            interaction.getAppointments(),
            interaction.getChildren(groupID))
}

export function isDemandInteraction(groupID: TGlobalUID, interaction: Interaction, myAvatar: Avatar, onlyOpened: boolean): boolean {
    const interactionParticipant = interaction.getParticipant()
    const isSameAvatar = myAvatar.iss(interactionParticipant.getAvatar());
    const isDueDateStillOpened = onlyOpened ? interaction.isEventOcurred(false, getClock()) : true;
    
    return isDueDateStillOpened 
        && interaction.isTimedType(constant.timedType.dueDate)
        && isSameAvatar
}

export function isInteractionAlreadyFeedbacked(idGroup: TGlobalUID, interaction: Interaction): boolean {
    return interaction.isFeedbackedInteraction(idGroup);
}

export function getAllInteractionTypes(toExclude: TInteractionTypeArray): TInteractionTypeArray {
    return InteractionType.getInteractionTypeArray()
            .filter(interactionType => !toExclude.includes(interactionType))
}

export function getAllDrivenFeedbacks(toExclude: TArrayID = []): TDriverFeedbackArray {
    return InteractionType.getInteractionTypeArray()
        .filter(intType => intType.isFeedback())
        .map(intType => intType.getPrimaryID())
        .filter(intTypePrimaryId => !toExclude.includes(intTypePrimaryId))
        .map((intTypePrimaryId, idx) => ({
            idDriverInteraction: intTypePrimaryId,
            priority: idx,
        }))
};

export function getOnlyDisplayableFeedbacks(feedbacks: TFeedbackArray): TFeedbackArray {
    return feedbacks.filter((f) => {return f.isFeedbackDisplayedOnReactionBar()});
}



export function isReadCallbacked(message: ISocketInteractionResponse): boolean {
    if (isValidRef(message.interaction) && message.responseType === socketConfig.responseTypes.normalInteraction) {
        const interactionType: InteractionType = InteractionType.staticFactory(message.interaction.idInteractionType)
        return interactionType.isReadInformationAvailable() && !interactionType.isTrackerInformation();
    };
    return false;
};


export function isMessageTypeCheckPlayerOnline(messageType: string): boolean {
    return isValidAndEqual(messageType, socketConfig.responseTypes.checkIfPlayerOnline);
}

export function canGroupTypeShowParticipantList(group: Group): boolean {
    return group.getGroupType().isNot(constant.groupType.functional.broadcast);
}

export function isCheckPlayerOnline(message: IResponse): boolean {
    return message && isMessageTypeCheckPlayerOnline(message.responseType);
};


