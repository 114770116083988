import { AlertComponent, EAppAlertTypes, IAppAlertSettings } from 'app/components/alert/alert.component';
import { ComponentType } from '@angular/cdk/portal';
import { EmbeddedViewRef, Injectable, TemplateRef } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Serializable } from "@colmeia/core/src/business/serializable";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { secToMS } from "@colmeia/core/src/time/time-utl";
import { defaultFields, getIfNotValid, isValidFunction, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { AsyncJobProcessAlertComponent } from 'app/components/async-job-process-alert/async-job-process-alert.component';
import { LoadingSnackbarComponent } from 'app/components/dashboard/loading-snackbar/loading-snackbar.component';
import { take } from 'rxjs/operators';

export { EAppAlertTypes };

export enum SnackDefaultDuration {
    Quick = 200,
    Normal = 500,
    Long = 5000,
    ExtraLong = 10000,
    Forever = -1
}

export type SnackBarDuration = SnackDefaultDuration | number;
export type SnackBarCallback = () => void;

export enum SnackVerticalPosition {
    Bottom = 'bottom',
    Top = 'top'
}

export interface ISnackSettingsBase {
    duration?: SnackBarDuration;
    vertical?: SnackVerticalPosition;
    options?: MatSnackBarConfig;
    type?: EAppAlertTypes;
    stroked?: boolean;
};

export interface ISnackSettingsMessage extends ISnackSettingsBase, Omit<IAppAlertSettings, 'type' | 'content'> {
    message: string;
    action?: string;
    dismissCallback?: SnackBarCallback;
}

export interface ISnackSettingsTemplate extends ISnackSettingsBase {
    template: TemplateRef<unknown>;
}

export interface ISnackSettingsComponent extends ISnackSettingsBase {
    component: ComponentType<unknown>;
}

export type TSnackBarSettings = ISnackSettingsMessage | ISnackSettingsTemplate | ISnackSettingsComponent;

export type TSnackBarRefTypes = MatSnackBarRef<SimpleSnackBar> | MatSnackBarRef<EmbeddedViewRef<any>> | MatSnackBarRef<unknown>;

export type TDefaultMessageOptions = Omit<ISnackSettingsMessage, 'duration' | 'message'> & { };

export interface ILoadingSnackBarConfig {
    label: string;
    duration?: number;
}

export interface ILoadingSnackBarData extends ILoadingSnackBarConfig{

}

@Injectable({
    providedIn: 'root'
})
export class SnackMessageService {

    private EAppAlertTypesClassMap: Record<EAppAlertTypes, string> = {
        [EAppAlertTypes.Primary]: 'app-alert-primary',
        [EAppAlertTypes.Accent]: 'app-alert-accent',
        [EAppAlertTypes.Error]: 'app-alert-error-darker',
        [EAppAlertTypes.Success]: 'app-alert-success-darker',
        [EAppAlertTypes.Warning]: 'app-alert-warning-darker',
        [EAppAlertTypes.Information]: 'app-alert-info-darker'
    }

    constructor(
        public snackBar: MatSnackBar
    ) {}

    public dismiss() {
        this.snackBar.dismiss();
    }

    private detectClass(type: EAppAlertTypes, stroked: boolean): string[] {
        const classes: string[] = [this.EAppAlertTypesClassMap[type]];

        if(stroked) classes.push('stroked');

        return classes;
    }

    open(settings: TSnackBarSettings): TSnackBarRefTypes {
        defaultFields(settings, { type: EAppAlertTypes.Primary });

        if(isMessageSnack(settings) && isValidRef(settings.type)) {
            return this.openDefaultMessage(settings.type, settings)(settings.message)
        }

        const snackOptions: MatSnackBarConfig = {
            verticalPosition: getIfNotValid(settings.vertical, SnackVerticalPosition.Bottom),
            duration: getIfNotValid(settings.duration, SnackDefaultDuration.ExtraLong),
            ...(isValidRef(settings.options) ? settings.options : {})
        };
        const settingsClasses = Array.isArray(snackOptions.panelClass)
            ? snackOptions.panelClass
            : [snackOptions.panelClass];

        snackOptions.panelClass = [
            ...settingsClasses,
            ...this.detectClass(settings.type, settings.stroked)
        ];
        let snack: TSnackBarRefTypes;

        if(isMessageSnack(settings)) {

            snack = this.snackBar.open(settings.message, settings.action, snackOptions);
        }

        if(isTemplateSnack(settings)) {
            snack = this.snackBar.openFromTemplate(settings.template, snackOptions);
        }

        if(isComponentSnack(settings)) {
            snack = this.snackBar.openFromComponent(settings.component, snackOptions);
        }

        snack.afterDismissed().pipe(take(1)).subscribe(_s => {
            if(isMessageSnack(settings) && isValidFunction(settings.dismissCallback)) settings.dismissCallback();
        });

        return snack;
    }

    openComponent<C = unknown>(settings: ISnackSettingsComponent): MatSnackBarRef<C> {
        return this.open(settings) as MatSnackBarRef<C>;
    }

    openTemplate(settings: ISnackSettingsTemplate): MatSnackBarRef<EmbeddedViewRef<any>> {
        return this.open(settings) as MatSnackBarRef<EmbeddedViewRef<any>>;
    }

    openError(errorMsg: string, duration?: number | TDefaultMessageOptions, options?: TDefaultMessageOptions): MatSnackBarRef<AlertComponent> {
        return this.openDefaultMessage(EAppAlertTypes.Error, duration, options)(errorMsg);
    }

    public openDefaultMessage = (type: EAppAlertTypes, duration?: number | TDefaultMessageOptions, options?: TDefaultMessageOptions) => {
        const closeBtnLbl: string = Serializable.getTranslation(gTranslations.common.close);
        options = bindOptionalOptions(duration, options);
        return (message: string): MatSnackBarRef<AlertComponent> => {
            return this.openComponent({
                component: AlertComponent,
                duration: bindDuration(duration) ?? undefined,
                options: {
                    ...options?.options,
                    panelClass: 'snack-app-alert',
                    data: <IAppAlertSettings>{
                        action: closeBtnLbl,
                        ...options,
                        message,
                        type,
                        darker: isValidRef(options?.darker) ? options.darker : true
                    }
                }
            }) as MatSnackBarRef<AlertComponent>;
        }

    }

    openSuccess(msg: string, duration?: number | TDefaultMessageOptions, options?: TDefaultMessageOptions): MatSnackBarRef<AlertComponent> {
        return this.openDefaultMessage(EAppAlertTypes.Success, duration, options)(msg);
    }

    openInfo(msg: string, duration?: number | TDefaultMessageOptions, options?: TDefaultMessageOptions): MatSnackBarRef<AlertComponent> {
        return this.openDefaultMessage(EAppAlertTypes.Information, duration, options)(msg);
    }

    openWarning(msg: string, duration?: number | TDefaultMessageOptions, options?: TDefaultMessageOptions): MatSnackBarRef<AlertComponent> {
        return this.openDefaultMessage(EAppAlertTypes.Warning, duration, options)(msg);
    }

    openAsyncProcessAlert() {
        return this.openComponent({
            component: AsyncJobProcessAlertComponent,
            options: {
                panelClass: "snack-async-proccess-alert",
                duration: -1
            }
        })
    }

    loadingSnack(config: ILoadingSnackBarConfig): MatSnackBarRef<LoadingSnackbarComponent> {
        const snackRef: MatSnackBarRef<LoadingSnackbarComponent> = this.open({
            component: LoadingSnackbarComponent,
            type: EAppAlertTypes.Primary,
            duration: config.duration ?? -1,
            options: {
                data: {
                    ...config
                },
            }
        }) as MatSnackBarRef<LoadingSnackbarComponent>;

        return snackRef;
    }
}

function isMessageSnack(settings: TSnackBarSettings): settings is ISnackSettingsMessage {
    return isValidString((settings as ISnackSettingsMessage).message);
}

function isTemplateSnack(settings: TSnackBarSettings): settings is ISnackSettingsTemplate {
    return isValidRef((settings as ISnackSettingsTemplate).template)
}

function isComponentSnack(settings: TSnackBarSettings): settings is ISnackSettingsComponent {
    return isValidRef((settings as ISnackSettingsComponent).component)
}

const bindDuration = (duration: number | TDefaultMessageOptions): number => typeof duration === 'number' ? duration : null;
const bindOptionalOptions = (duration: number | TDefaultMessageOptions, options: TDefaultMessageOptions): TDefaultMessageOptions => typeof duration === 'object' ? duration : options;
