import { TGlobalUID } from "../business/constant";
import { TPublicMediumArray } from "./billing-interfaces";
import { ESocialMedia } from "../business/social";
import { ITurnGpsOnJSON } from "./interaction-interfaces";
import { IPushNotificationPolicy, TNotificationDeviceArray } from "../business/push-notifications";

export interface IGPSConfigurationStatus {
    gpsOn: boolean;
    json: ITurnGpsOnJSON;
};

export class IPersonalSettingsConfiguration {
    gpsStatus: IGPSConfigurationStatus;
    socialBindings: Array<ESocialMedia>; // SocialMediaServer.getAllActiveBinds(idPlayer)
    paymentMediums: TPublicMediumArray; //BillingController getPaymentsMediumResponse
    notificationsPolicy: IPushNotificationPolicy;
    notificationDevices: TNotificationDeviceArray
}