import {constant, TGlobalUID} from '../business/constant';
import {InteractionType} from './interaction-type';
import {UberCache} from '../persistency/uber-cache';
import {Serializable} from '../business/serializable';
import {IFeedbackJSON} from '../comm-interfaces/business-interfaces';
import { isValidArray } from '../tools/utility';

export type TFeedbackArray = Array<Feedback>;

export class Feedback extends Serializable {
    private static feedbackArray: TFeedbackArray = [];
    private positive: boolean;
    private offensive: boolean;
    private points: number;
    private interactionType: InteractionType;
    private sendAll: boolean;
    private sendPersonal: boolean;
    private sendWorker: boolean;
    private feedDisp: boolean;


    private constructor(json: IFeedbackJSON) {
        super(constant.objectType.feedback, json.primaryID);
        this.positive = json.positive;
        this.offensive = json.offensive;
        this.points = json.points;
        this.interactionType = InteractionType.staticFactory(json.idInteractionType);
        this.sendAll = json.sendAll;
        this.sendPersonal = json.sendPersonal;
        this.sendWorker = json.sendWorker;
        Feedback.feedbackArray.push(this);
    };

    
    public toJSON(): IFeedbackJSON {
        let json: IFeedbackJSON = {
            ...super.toJSON(),
            positive : this.positive,
            offensive : this.offensive,
            points : this.points,
            idInteractionType : this.interactionType.getInteractionTypeID(),
            sendAll: this.sendAll,
            sendPersonal: this.sendPersonal,
            sendWorker: this.sendWorker,
            displayable: this.feedDisp,
        };
       
        return json;
    };

    public getInteractionType(): InteractionType {return this.interactionType};
    public getInteractionTypeID(): TGlobalUID {return this.interactionType.getInteractionTypeID(); };
    
    public rehydrate(json: IFeedbackJSON): void {
        super.rehydrate(json);
        this.positive = json.positive;
        this.offensive = json.offensive;
        this.points = json.points;
        this.interactionType = InteractionType.staticFactory(json.idInteractionType);
        this.feedDisp = json.displayable;
    };

    public getFeedbackID(): TGlobalUID {return super.getPrimaryID()};

    public getFeedbackName(): string { return super.getName(); }
    public isPositive(): boolean { return this.positive; }
    public isOffensive(): boolean { return this.offensive; }
    public getPoints(): number { return this.points; }

    public isSendAble(): boolean {return (this.sendAll || this.sendPersonal || this.sendWorker) ? true : false; };
    public isSendAll(): boolean {return this.sendAll; };
    public isSendPersonal(): boolean {return this.sendPersonal; };
    public isSendWorker(): boolean {return this.sendWorker; };

    public getBigIcon(): string {
        return this.getSerializableText(constant.serializableField.auxiliars.aux01)
    }

    public isFeedbackDisplayedOnReactionBar(): boolean {return this.feedDisp};

    public isSendOnFeedbackCarrier(): boolean {
        return this.isNot(constant.interactionType.annotation.group.archive);
    }

    public static factoryMessage(json: IFeedbackJSON): Feedback {
        let feedback: Feedback = <Feedback>UberCache.uberFactory(json.primaryID, constant.objectType.feedback, false);
        if (feedback == null)
            feedback = new Feedback(json);
        feedback.rehydrate(json);
        return feedback;
    };

    
    public static getInteractionFeedback(interactionType: InteractionType, minimumRequest: number = null): TFeedbackArray {
        if (minimumRequest === null) {
            minimumRequest = Feedback.getMinumumFeedbackPoints(interactionType);
        };
        
        return Feedback.feedbackArray.filter((feedback) => {
            return feedback.getInteractionType().iss(interactionType) && feedback.getPoints() >= minimumRequest
        }).sort((x ,y) => {return y.getPoints() - x.getPoints()});
    };

    private static getMinumumFeedbackPoints(interactionType: InteractionType): number {
        const feedbacks: TFeedbackArray = Feedback.feedbackArray
            .filter((feedback) => {return feedback.getInteractionType().iss(interactionType)})
            .sort((x,y) => {return x.getPoints() - y.getPoints()});

        return isValidArray(feedbacks) ? feedbacks[0].getPoints() : 0;
    };

       
    public static getMinimumForAvatarVote(idInteractionType: TGlobalUID,  numberOfAvatarOptions: number): number {
        let voteArray: TFeedbackArray = Feedback.getOrderedByPointsVotes(idInteractionType);
        let ret = voteArray[numberOfAvatarOptions-1].getPoints();
        return ret;
    };

    public static getOrderedByPointsVotes(idInteractionType: TGlobalUID): TFeedbackArray {
        return Feedback.getInteractionFeedback(InteractionType.staticFactory(idInteractionType), 0);
    };

  

    public static staticFactory(idFeedback: TGlobalUID): Feedback {
        return <Feedback>UberCache.uberFactory(idFeedback, constant.objectType.feedback, true);
    };

    public hasIcon(): boolean {
        return this.getIcon() !== null;
    }

    public static resetFeedbackArray() {
        Feedback.feedbackArray = [];
    }
};
