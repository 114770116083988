import {ITranslationHash} from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import {
    EActionOnComplete,
    TBotTransactionComponentsArray
} from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import {SchemaProperty} from "@colmeia/core/src/general-form/general-form-interface";
import {IMLLuisEntity} from "@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces";
import {EBotActionType} from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import {KBAssetType} from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import {ENextGenBotElementType} from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { genericTypedSuggestions } from "@colmeia/core/src/tools/type-utils";

export const BotTransactionTypeTranslations = genericTypedSuggestions<ITranslationHash>()({
    [ENextGenBotElementType.formTransaction]: gTranslations.bot[ENextGenBotElementType.formTransaction],
    [ENextGenBotElementType.nlpTransaction]: gTranslations.bot[ENextGenBotElementType.nlpTransaction],
})

export const ActionOnCompleteTranslations = genericTypedSuggestions<ITranslationHash>()({
    actionOnComplete: gTranslations.bot.actionOnComplete,
    [EActionOnComplete.return]: gTranslations.bot[EActionOnComplete.return],
    [EActionOnComplete.keepUntilCommand]: gTranslations.bot[EActionOnComplete.keepUntilCommand]
})

export const AssetTypeTranslations = genericTypedSuggestions<ITranslationHash>()({
    [KBAssetType.media]: gTranslations.bot[KBAssetType.media],
    [KBAssetType.content]: gTranslations.bot[KBAssetType.content],
    [EBotActionType.contentGenerator]: gTranslations.bot[EBotActionType.contentGenerator],
    [EBotActionType.goBot]: gTranslations.bot[EBotActionType.goBot],
    [KBAssetType.contentAPICall]: gTranslations.bot[KBAssetType.contentAPICall],
});

export interface IBotTransactionComponent {
    field: SchemaProperty,
    entity: IMLLuisEntity,
}

export interface IComponentsHistory {
    [idSchema: string]: IKBComponentHistory;
}

export interface IKBComponentHistory {
    [idKB: string]: TBotTransactionComponentsArray;
}
