import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { ISocialMediaConnectionServer } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { isSocialMediaType } from '@colmeia/core/src/shared-business-rules/social-media/social-media.functions';
import { DeliveryProvider, TArrayID, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { ERandonCharType, getReadableUniqueID, getUniqueStringID, isInvalid, isInvalidArray, isInvalidString, isValidRef, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { Crypt } from '../../security/crypt';
import { IWAFAbstractComponent, IWAFClient, IWAFContentHeadderConfiguration, IWAFFooterConfiguration, IWAFOutputComponent, IWAFSchemaClient, IWAFSchemaServer, IWAFScreen, IWAFServer, TIWAFFieldConfigurationALL, TIWAFFieldConfigurationArray, WAFComponentTypeToInterface } from './waf.model';
import { CurrentWAFEngineVersion, EWAFComponents, EWAFFieldTypes, EWAFFontWeight, EWAFStatus, FlowConfigurationType, IDynamicField, IFlowAreaV1, IFlowAreaV2, IFlowField, IWAFEngagementFlowConfiguration, IWAFFlowConfiguration, IWAFIDynamicFieldType, IWAFMultipleOptions, IWAFProvider, IWAFTemplateFlowConfiguration, ImageScaleType, OverwriteDynamicFieldsContent, TFlowEngamentSettings, TIEngagementFlowConfigurationArray, TIFlowFieldArray, WAFInputType } from './waf.types';
import { IInputFieldDynamicConfiguration, IVisibleConfiguration, IWAFMultipleOptionsDynamicConfiguration, TIWAFConfigurationAll } from './waf.dynamic';
import { Miliseconds } from '../../time/time-utl';
import { hasWhatsAppFlows } from '../social-media/social-media.constants';
import { EMetadataEngagementType, IMetaEngagement, IWAFEngagementConfig } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { getEngagementConfigOfType } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { WhatsApp } from '@colmeia/core/src/shared-business-rules/social-media/whatsapp-interface';
import { throwErrorText } from '../../tools/utility/functions/error.functions';
import { emptyContentBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { SchemaProperty } from '../../general-form/general-form-interface';

export interface IExtraWafExecutionParameter {
    idMetaEngagement: string;
}

export interface IExtraWafExecutionParameter {
    idMetaEngagement: string;
}

export class WAFUtils {
    static newEmptyFlow(): IWAFClient {
        return {
            nsType: ENonSerializableObjectType.waf,
            nName: '',
        };
    }

    static newEmptyFlowSchema(idParent: IdDep<ENonSerializableObjectType.waf>): IWAFSchemaClient {
        return {
            nsType: ENonSerializableObjectType.wafSchema,
            nName: '', // nome é sobrescrito depois
            providers: [],
            wafVersion: CurrentWAFEngineVersion,
            status: EWAFStatus.UNREGISTERED,
            idParent,
            screens: []
        };
    }

    public static hasMutableState = (state: EWAFStatus) =>
        [EWAFStatus.DRAFT, EWAFStatus.UNREGISTERED, EWAFStatus.ERROR].includes(state);

    public static hasImutableState = (state: EWAFStatus) =>
        !WAFUtils.hasMutableState(state);

    public static isValidSchema = (status: EWAFStatus) =>
        [EWAFStatus.PUBLISHED, EWAFStatus.DRAFT, EWAFStatus.DEPRECATED].includes(status);

    public static isInvalidSchema = (status: EWAFStatus) => !WAFUtils.isValidSchema(status);

    public static isInvalidStatus = (status: EWAFStatus) =>
        [EWAFStatus.BLOCKED, EWAFStatus.ERROR].includes(status);

    public static isValidStatus = (status: EWAFStatus) =>
        !WAFUtils.isInvalidStatus(status);

    public static getGeneralState = (statuses: EWAFStatus[]) => {
        const priorityMap: { [key in EWAFStatus]: number } = {
            [EWAFStatus.BLOCKED]: 0,
            [EWAFStatus.ERROR]: 1,
            [EWAFStatus.THROTTLED]: 2,
            [EWAFStatus.DELETED]: 3,
            [EWAFStatus.DEPRECATED]: 4,
            [EWAFStatus.PUBLISHED]: 5,
            [EWAFStatus.DRAFT]: 6,
            [EWAFStatus.UNREGISTERED]: 7,
        };

        // Find the status with the highest priority
        return statuses.reduce((highest, current) => {
            return (priorityMap[current] < priorityMap[highest]) ? current : highest;
        }, statuses[0]);
    }

    public static hasGeneralImutableState = (schema: IWAFSchemaClient) =>
        WAFUtils.hasImutableState(schema.status) &&
        schema.providers.filter((provider) =>
            WAFUtils.hasImutableState(provider.status)
        ).length > 0;

    public static hasGeneralMutableState = (schema: IWAFSchemaClient) =>
        WAFUtils.hasMutableState(schema.status) ||
        schema.providers.filter((provider) =>
            WAFUtils.hasMutableState(provider.status)
        ).length > 0;

    public static getHash(schema: IWAFSchemaServer): string {
        const { header, footer, fields } = schema;
        return Crypt.sha256Str(JSON.stringify({ header, footer, fields }));
    }

    public static unUnpdatedHash = (schema: IWAFSchemaServer) =>
        WAFUtils.getHash(schema) !== schema.hash;

    public static updatedHash = (schema: IWAFSchemaServer) =>
        !WAFUtils.unUnpdatedHash(schema);

    public static cannotGeneralUpdate = (schema: IWAFSchemaServer) =>
        WAFUtils.hasGeneralImutableState(schema) && WAFUtils.updatedHash(schema);

    public static cannotGeneralUpdateStatus = (schema: IWAFSchemaServer) =>
        WAFUtils.hasGeneralImutableState(schema) && WAFUtils.updatedHash(schema);

    public static cannotUpdateStatus = (schema: IWAFSchemaServer, newStatus: EWAFStatus) =>
        WAFUtils.hasGeneralImutableState(schema)
        && WAFUtils.hasMutableState(newStatus);

    public static canUpdateStatus = (schema: IWAFSchemaServer, newStatus: EWAFStatus) =>
        !WAFUtils.cannotUpdateStatus(schema, newStatus);

    private static componentOutputs: Record<EWAFComponents, boolean> = {
        [EWAFComponents.TEXT_HEADING]: false,
        [EWAFComponents.TEXT_SUBHEADING]: false,
        [EWAFComponents.TEXT_BODY]: false,
        [EWAFComponents.TEXT_CAPTION]: false,
        [EWAFComponents.TEXT_INPUT]: true,
        [EWAFComponents.TEXT_AREA]: true,
        [EWAFComponents.CHECKBOX_GROUP]: true,
        [EWAFComponents.RADIO_BUTTONS_GROUP]: true,
        [EWAFComponents.OPT_IN]: true,
        [EWAFComponents.DROPDOWN]: true,
        [EWAFComponents.DATE_PICKER]: true,
        [EWAFComponents.EMBEDDED_LINK]: false,
        [EWAFComponents.IMAGE]: false,
        [EWAFComponents.FORM]: false,
        [EWAFComponents.FOOTER]: false
    }

    public static componentHasOutput(component: EWAFComponents): boolean {
        return WAFUtils.componentOutputs[component];
    }

    public static isWAFServer(obj: any): obj is IWAFServer {
        return obj.nsType === ENonSerializableObjectType.waf && isValidString(obj.idNS);
    }

    public static isWAFSchemaServer(obj: any): obj is IWAFSchemaServer {
        return obj.nsType === ENonSerializableObjectType.wafSchema && isValidString(obj.idNS);
    }

    public static isWAFFooterConfiguration(obj: any): obj is IWAFFooterConfiguration {
        return 'cta' in obj;
    }

    public static isWAFContentHeaderConfiguration(obj: any): obj is IWAFContentHeadderConfiguration {
        return 'title' in obj;
    }

    public static isValidChannelProvider(channel: ISocialMediaConnectionServer | undefined): boolean {
        if (!channel) return false;

        if (!isSocialMediaType(DeliveryProvider.whatsApp)(channel)) return false;

        if (!isValidString(channel.detail.whatsAppBusinessAccountId)) return false;
        if (!hasWhatsAppFlows(channel.provider)) return false;

        return true;
    }

    public static getFlowSchemaIdFromToken(token: string): string {
        return token.split('_')[1].split('?')[0];
    }

    public static insertFieldsIds(model: IWAFSchemaServer) {
        model.screens!.forEach(screen => {
            screen.fields.forEach(field => {
                field.idFlowField = field.idFlowField || getReadableUniqueID(6);
            });
            screen.formProperties = screen.formProperties || {
                initValues: getReadableUniqueID(6, ERandonCharType.alphabetOnly),
                errorMessages: getReadableUniqueID(6, ERandonCharType.alphabetOnly),
            };
        })
    }

    public static fromTypetoBasicFieldType<T>(type: T): EWAFFieldTypes {
        if (isInvalid(type)) {
            return EWAFFieldTypes.string;
        } else if (typeof type === 'string') {
            return EWAFFieldTypes.string;
        } else if (typeof type === 'string') {
            return EWAFFieldTypes.string;
        } else if (typeof type === 'number') {
            return EWAFFieldTypes.number;
        } else if (typeof type === 'boolean') {
            return EWAFFieldTypes.boolean;
        } else if (Array.isArray(type)) {
            return EWAFFieldTypes.array;
        } else {
            return EWAFFieldTypes.object;
        }
    }

    public static fromFieldTypeToNativeType<T>(type: EWAFFieldTypes, value: T, fieldType?: EWAFFieldTypes): IWAFIDynamicFieldType {
        if (fieldType) {
            return { type: fieldType };
        }

        const iter = (t: any) => {
            const result: Record<string, IWAFIDynamicFieldType> = {};
            Object.keys(t).forEach(key => {
                result[key] = WAFUtils.fromFieldTypeToNativeType(
                    WAFUtils.fromTypetoBasicFieldType((<any>t)[key]),
                    (<any>t)[key]
                )
            });
            return result;
        };

        const mapping: Record<EWAFFieldTypes, (value: T) => IWAFIDynamicFieldType> = {
            [EWAFFieldTypes.string]: (_) => ({ type: 'string' }),
            [EWAFFieldTypes.number]: (_) => ({ type: 'number' }),
            [EWAFFieldTypes.interger]: (_) => ({ type: 'integer' }),
            [EWAFFieldTypes.enum]: (_) => ({ type: 'enum' }),
            [EWAFFieldTypes.boolean]: (_) => ({ type: 'boolean' }),
            [EWAFFieldTypes.object]: (t) => ({
                type: 'object',
                properties: iter(t),
            }),
            [EWAFFieldTypes.array]: (t) => ({
                type: 'array', items: WAFUtils.fromFieldTypeToNativeType(
                    WAFUtils.fromTypetoBasicFieldType((<Array<any>>t)[0]),
                    (<Array<any>>t)[0])
            })
        }

        return mapping[type](value);
    }

    public static newDynamicField<T>(defaultValue: T, typeName?: string): IDynamicField<T> {
        if (defaultValue /* checa o name of namedNumber pra ver se e milisecond */) {
            return {
                default: defaultValue,
                id: getReadableUniqueID(6),
                typeName
            }
        }
        return {
            default: defaultValue,
            id: getReadableUniqueID(6),
        };
    }

    public static isDynamicField(field: any): field is IDynamicField<any> {
        if (!field) return false;
        return isValidRef(field.id) && isValidRef(field.default);
    }

    public static isMultipleOptions(field: IDynamicField<any>): field is IDynamicField<IWAFMultipleOptions> {
        if (!field) return false;
        if (!Array.isArray(field.default) || field.default.length == 0) return false;
        const result = field.default.filter((value: any) => isValidRef(value?.id)
            && isValidRef(value?.title)
            && isValidRef(value?.enabled));
        return result.length >= field.default.length;
    }

    public static isMillisecond(field: IDynamicField<any>): field is IDynamicField<Miliseconds> {
        if (!field) return false;
        if (typeof field.default != 'number') return false;
        if (field.typeName !== 'milisecond') return false;
        return !isNaN(new Date(field.default) as any);
    }

    public static isInvalidDates(field: IDynamicField<any>): field is IDynamicField<Array<string>> {
        if (!field) return false;
        if (!Array.isArray(field.default) || field.default.length == 0) return false;
        if (typeof field.default[0] != 'number') return false;
        const result = field.default.filter((date: number) => {
            return !isNaN(new Date(date) as any);
        });
        return result.length >= field.default.length;
    }

    public static newEmptyComponent(type: EWAFComponents): TIWAFFieldConfigurationALL {
        const component: IWAFAbstractComponent = {
            type,
            configuration: {}
        };

        if (
            isWAFComponentType(EWAFComponents.TEXT_HEADING, component)
            || isWAFComponentType(EWAFComponents.TEXT_SUBHEADING, component)
            || isWAFComponentType(EWAFComponents.TEXT_BODY, component)
            || isWAFComponentType(EWAFComponents.TEXT_CAPTION, component)
            || isWAFComponentType(EWAFComponents.EMBEDDED_LINK, component)
            || isWAFComponentType(EWAFComponents.TEXT_INPUT, component)
            || isWAFComponentType(EWAFComponents.TEXT_AREA, component)
            || isWAFComponentType(EWAFComponents.CHECKBOX_GROUP, component)
            || isWAFComponentType(EWAFComponents.RADIO_BUTTONS_GROUP, component)
            || isWAFComponentType(EWAFComponents.DROPDOWN, component)
            || isWAFComponentType(EWAFComponents.OPT_IN, component)
            || isWAFComponentType(EWAFComponents.DATE_PICKER, component)
        ) {
            component.configuration.visible = WAFUtils.newDynamicField(true);
        }

        if (
            isWAFComponentType(EWAFComponents.TEXT_HEADING, component)
            || isWAFComponentType(EWAFComponents.TEXT_SUBHEADING, component)
            || isWAFComponentType(EWAFComponents.TEXT_BODY, component)
            || isWAFComponentType(EWAFComponents.TEXT_CAPTION, component)
        ) {
            component.configuration.text = WAFUtils.newDynamicField('');

            if (
                isWAFComponentType(EWAFComponents.TEXT_BODY, component)
                || isWAFComponentType(EWAFComponents.TEXT_CAPTION, component)
            ) {
                component.fontWeight = EWAFFontWeight.NORMAL;
                component.strikethrough = false;
            }
        }

        if (isWAFComponentType(EWAFComponents.EMBEDDED_LINK, component)) {
            // @todo implementar futuramente
        }

        if (
            isWAFComponentType(EWAFComponents.TEXT_INPUT, component)
            || isWAFComponentType(EWAFComponents.TEXT_AREA, component)
            || isWAFComponentType(EWAFComponents.CHECKBOX_GROUP, component)
            || isWAFComponentType(EWAFComponents.RADIO_BUTTONS_GROUP, component)
            || isWAFComponentType(EWAFComponents.DROPDOWN, component)
            || isWAFComponentType(EWAFComponents.OPT_IN, component)
            || isWAFComponentType(EWAFComponents.DATE_PICKER, component)
        ) {
            component.configuration.label = WAFUtils.newDynamicField('');
            component.configuration.required = WAFUtils.newDynamicField(true);
        }

        if (isWAFComponentType(EWAFComponents.TEXT_INPUT, component)) {
            component.inputType = WAFInputType.TEXT;
            component.configuration.minChars = WAFUtils.newDynamicField(0);
            component.configuration.maxChars = WAFUtils.newDynamicField(100);
            component.configuration.helperText = WAFUtils.newDynamicField('');
        }

        if (isWAFComponentType(EWAFComponents.TEXT_AREA, component)) {
            component.configuration.maxLength = WAFUtils.newDynamicField(500);
            component.configuration.helperText = WAFUtils.newDynamicField('');
            component.configuration.enabled = WAFUtils.newDynamicField(true);
        }

        if (isWAFComponentType(EWAFComponents.CHECKBOX_GROUP, component)) {
            component.configuration.options = WAFUtils.newDynamicField([]);
            component.configuration.minSelectItems = WAFUtils.newDynamicField(0);
            component.configuration.maxSelectItems = WAFUtils.newDynamicField(20);
        }

        if (isWAFComponentType(EWAFComponents.RADIO_BUTTONS_GROUP, component)) {
            component.configuration.options = WAFUtils.newDynamicField([]);
            component.configuration.enabled = WAFUtils.newDynamicField(true);
        }

        if (isWAFComponentType(EWAFComponents.DROPDOWN, component)) {
            component.configuration.options = WAFUtils.newDynamicField([]);
        }

        if (isWAFComponentType(EWAFComponents.DATE_PICKER, component)) {
            component.configuration.minDate = WAFUtils.newDynamicField('1990-12-07', 'date'); // valor mínimo default
            component.configuration.maxDate = WAFUtils.newDynamicField('2030-02-12', 'date'); // valor máximo default
            component.configuration.unavailableDates = WAFUtils.newDynamicField([]);
            component.configuration.helperText = WAFUtils.newDynamicField('');
        }

        if (isWAFComponentType(EWAFComponents.IMAGE, component)) {
            component.configuration.imageSource = WAFUtils.newDynamicField('');
            component.configuration.accessbilityText = WAFUtils.newDynamicField('');
            component.configuration.width = WAFUtils.newDynamicField(0);
            component.configuration.height = WAFUtils.newDynamicField(0);
            component.configuration.aspectRatio = WAFUtils.newDynamicField(1);
            component.scaleType = ImageScaleType.CONTAIN;
        }

        return component as TIWAFFieldConfigurationALL;
    }

    public static getFlowsEngagementConfig(engagement: IMetaEngagement): IWAFEngagementConfig {
        return getEngagementConfigOfType<IWAFEngagementConfig>(engagement, EMetadataEngagementType.whatsAppFlow);
    }

    public static async isBegingOfAUsefulFlowArea(idProperty: string, engagement: IMetaEngagement): Promise<boolean> {

        if (WAFUtils.beginingOfAFlowArea(idProperty, engagement)) {
            return true // retorna a checagem se o canal esta habilitado
        }
        return false

    }

    public static beginingOfAFlowArea(idProperty: string, engagement: IMetaEngagement): IWAFEngagementFlowConfiguration | undefined {
        const flowEngagement = WAFUtils.getFlowsEngagementConfig(engagement);

        if (isInvalid(flowEngagement)) {
            return undefined;
        };

        return WAFUtils.findFlowConfigurationByIdField(idProperty, flowEngagement.flowConfigurations);
    }


    public static containsFlowAreaV1(config: IWAFEngagementConfig): boolean {
        return config.flowAreas !== undefined;
    }

    public static currentFlowIsInFlowAreaV1(config: IWAFEngagementConfig, idProperty: string): boolean {
        if (!config.flowAreas) return false;

        if (config.flowAreas.some(area => area.fields.some(field => field.idField === idProperty))) {
            return true;
        }
        return false;
    }

    public static findFlowConfigurationByIdField(idField: string, flowConfigurations: TIEngagementFlowConfigurationArray): IWAFEngagementFlowConfiguration | undefined {
        if (isInvalidArray(flowConfigurations)) return undefined;

        for (const flowConfig of flowConfigurations) {
            if (WAFUtils.idFieldIsFromFlowConfig(idField, flowConfig)) {
                return flowConfig;
            }
        }
    }

    public static idFieldIsFromFlowConfig(idField: string, flowConfig: IWAFEngagementFlowConfiguration): boolean {
        if (isInvalid(flowConfig) || isInvalid(flowConfig.flowAreas)) return false;
        return flowConfig.flowAreas.some(area => WAFUtils.idFieldIsFromFlowArea(idField, area));
    }

    public static idFieldIsFromFlowArea(idField: string, flowArea: IFlowAreaV2): boolean {
        if (isInvalid(flowArea) || isInvalid(flowArea.fields)) return false;
        return flowArea.fields.some(f => f.idField === idField);
    }

    public static findConfigByIdScreen(idScreen: string, flowEngagement: IWAFEngagementConfig): IWAFEngagementFlowConfiguration | undefined {
        if (isInvalid(flowEngagement)) {
            return undefined;
        }

        if (isInvalidArray(flowEngagement?.flowConfigurations)) {
            return undefined;
        }

        for (let config of flowEngagement.flowConfigurations!) {
            if (config.flowAreas.length === 0) {
                break;
            }

            if (!config.formFlowOn) {
                break;
            }
            if (WAFUtils.screenIsFromThisConfig(idScreen, config)) {
                return config;
            }
        }

        return undefined;
    }


    public static screenIsFromThisConfig(idScreen: string, flowConfig: IWAFEngagementFlowConfiguration): boolean {
        if (isInvalid(flowConfig) || isInvalid(flowConfig.flowAreas)) return false;
        return flowConfig.flowAreas.some(area => area.screenId === idScreen);
    }

    public static flowEngagementSettingsIsFlowAreaV1(component: TFlowEngamentSettings | undefined): component is IFlowAreaV1 {
        if (isInvalid(component)) {
            return false;
        }
        return (<IFlowAreaV1>component).fields && isValidString(component.idFlowSchema);
    }

    public static flowEngagementSettingsIsFlowConfig(component: TFlowEngamentSettings | undefined): component is IWAFEngagementFlowConfiguration {
        if (isInvalid(component)) {
            return false;
        }
        if ((<IWAFEngagementFlowConfiguration>component).flowAreas) {
            return true;
        }
        return false;
    }

    public static generateFlowToken(): string {
        const flowToken: string = getUniqueStringID();
        return flowToken;
    }

    public static findFlowConfigurationByIdScreen(idScreen: string, flowEngagement: IWAFEngagementConfig): IWAFEngagementFlowConfiguration | undefined {
        if (isInvalid(flowEngagement)) {
            return undefined;
        }

        if (isInvalidArray(flowEngagement?.flowConfigurations)) {
            return undefined;
        }

        for (let configuration of flowEngagement.flowConfigurations) {
            if (!configuration.formFlowOn) {
                break;
            }
            for (let area of configuration.flowAreas) {
                if (!area.enabled) {
                    break;
                }
                if (area.screenId === idScreen) {
                    return configuration;
                }
            }
        }
        return undefined;
    }

    public static getAllFlowFieldsByPorperty(idProperty: string, engagement: IMetaEngagement): TArrayID {
        const config = engagement.engagementConfig[EMetadataEngagementType.whatsAppFlow] as IWAFEngagementConfig;

        const configuration = WAFUtils.findFlowConfigurationByIdField(idProperty, config.flowConfigurations);
        if (!configuration) return [];
        return WAFUtils.getAllColmeiaFieldsByFlowConfiguration(configuration);
    }

    public static getAllColmeiaFieldsByFlowConfiguration(flowConfiguration: IWAFFlowConfiguration): TArrayID {
        return flowConfiguration?.flowAreas.map(area => area.fields.map(f => f.idField)).flat() ?? [];
    }

    public static getAllColmeiaFieldsByFlowArea(flowArea: IFlowAreaV2): TArrayID {
        return flowArea.fields.map(f => f.idField) ?? [];
    }

    public static isFlowMessage(field: any): field is WhatsApp.Message.Hook.NfmReply {
        return isValidRef(field)
            && isValidString(field.body)
            && isValidString(field.name) && field.name === 'flow'
            && isValidString(field.response_json);
    }

    public static isTestMessage(flowToken: string): boolean {
        if (isInvalidString(flowToken)) return false;
        if (flowToken.startsWith('test_')) return true;
        return false;
    }


    public static pushProvider(waf: IWAFSchemaServer, providers: string[]): IWAFSchemaServer {
        const currentProviders = waf.providers.map(i => i.idProvider);
        providers.forEach(provider => {
            if (!currentProviders.includes(provider)) {
                waf.providers.push({
                    status: EWAFStatus.UNREGISTERED,
                    provider: DeliveryProvider.whatsApp,
                    idProvider: provider
                });
            }
        });

        return waf;
    }

    public static updateState(waf: IWAFSchemaServer, newStatus: EWAFStatus) {
        if (WAFUtils.hasImutableState(waf.status)) {
            throwErrorText(`cannot update status to ${newStatus} for flow ${waf.idNS}`);
        }
        waf.status = newStatus;
    }

    public static updateProviderState(provider: IWAFProvider, newStatus: EWAFStatus) {
        if (WAFUtils.hasImutableState(provider.status)) {
            throwErrorText(
                `cannot update status to ${newStatus} for provider ${provider.phoneId}`
            );
        }
        provider.status = newStatus;
    }

    public static isVisibleConfig(config: any): config is IVisibleConfiguration {
        return isValidRef(config.visible);
    }

    public static isInputConfig(config: any): config is IInputFieldDynamicConfiguration {
        return isValidRef(config.required);
    }

    public static isMultipleOptionsConfig(config: any): config is IWAFMultipleOptionsDynamicConfiguration {
        return isValidRef(config.options);
    }

    public static isMultipleChoiceComponent(type: EWAFComponents): boolean {
        return [
            EWAFComponents.RADIO_BUTTONS_GROUP,
            EWAFComponents.DROPDOWN,
            EWAFComponents.CHECKBOX_GROUP
        ].includes(type);
    }
    
    /**
     * o checkbox não está aqui porque nele é possível selecionar mais de uma opção
     */
    public static isMultipleChoiceSelectOnlyOneComponent(type: EWAFComponents): boolean { 
        return [
            EWAFComponents.RADIO_BUTTONS_GROUP,
            EWAFComponents.DROPDOWN
        ].includes(type);
    }

    public static newFlowScreen(): IWAFScreen {
        return {
            screenId: getReadableUniqueID(12, ERandonCharType.alphabetOnly),
            friendlyName: "",
            formProperties: {
                errorMessages: getReadableUniqueID(6, ERandonCharType.alphabetOnly),
                initValues: getReadableUniqueID(6, ERandonCharType.alphabetOnly),
            },
            header: {
                title: WAFUtils.newDynamicField('Formulário'),
            },
            fields: [],
            footer: {
                cta: WAFUtils.newDynamicField('Enviar'),
            },
        };
    }

    public static newEngagementFlowConfig(): IWAFEngagementFlowConfiguration {
        return {
            id: getReadableUniqueID(12),
            idFlowSchema: '',
            formFlowOn: true,
            message: {
                body: emptyContentBasicAsset('Por favor, preencha esse formulário.'),
                cta: emptyContentBasicAsset('Abrir')
            },
            flowAreas: [],
            type: FlowConfigurationType.Engagegement
        };
    }

    public static newTestFlowConfig(): IWAFTemplateFlowConfiguration {
        return {
            id: getReadableUniqueID(12),
            idFlowSchema: '',
            formFlowOn: true,
            flowAreas: [],
            type: FlowConfigurationType.Template
        };
    }

    public static newFlowArea(): IFlowAreaV2 {
        return {
            id: getReadableUniqueID(12),
            fields: [],
            screenId: '',
            enabled: true,
            dynamicFieldsContent: {},
            ForceShowArea: false
        };
    }

    public static countComponentDynamicFields(component: IWAFOutputComponent, flowArea: IFlowAreaV2): number {
        const dynamicConfigKeys = Object.keys(flowArea.dynamicFieldsContent);
        let count = 0;

        for (let prop in component.configuration) {
            const dynamicConfig: IDynamicField<unknown> = component.configuration[prop as keyof TIWAFConfigurationAll];

            if (dynamicConfigKeys.includes(dynamicConfig.id)) {
                count++;
            }
        }

        return count;
    }

    public static filterInvalidDynamicFields(dynamicFields: OverwriteDynamicFieldsContent, schema: IWAFSchemaServer) {
        if (isInvalidArray(schema.screens)) return;

        const components = schema.screens!.flatMap(screen => screen.fields);

        const existingDynamicFieldIds = components.flatMap(component => {
            const ids: string[] = [];

            for (let configProp in component.configuration) {
                const dynamicField: IDynamicField<unknown> = component.configuration[configProp as keyof TIWAFConfigurationAll];

                if (dynamicField.id) ids.push(dynamicField.id);
            }

            return ids;
        });

        for (let dynamicFieldId in dynamicFields) {
            if (!existingDynamicFieldIds?.includes(dynamicFieldId)) {
                delete dynamicFields[dynamicFieldId];
            }
        }
    }

    public static findComponentById(id: string, schemaFields: TIWAFFieldConfigurationArray): TIWAFFieldConfigurationALL | undefined {
        return schemaFields.find(component => component.idFlowField === id);
    }


    public static isFlowMuliPage(waf: IWAFSchemaServer): boolean {
        return waf && (isValidRef(waf.screens) && waf.screens.length > 1)
            && isInvalid(waf.fields) && isInvalid(waf.header)
            && isInvalid(waf.footer) && isInvalid(waf.formProperties);
    }

    public static findFieldById(id: string, wafScreen: IWAFScreen): TIWAFFieldConfigurationALL | undefined {
        return WAFUtils.findComponentById(id, wafScreen.fields);
    }


    public static findFirstArea(
        waf: IWAFSchemaServer, flowConfig: IWAFEngagementFlowConfiguration,
        idProperty: string,
        invisibleFields: string[],
    ): IFlowAreaV2 | undefined {
        if (isValidRef(flowConfig)) {
            if (waf.screens && waf.screens.length > 0) {
                const screen = waf.screens[0];
                if (screen.fields.every(field => invisibleFields.includes(field?.idFlowField!))) {
                    return WAFUtils.findNextArea(flowConfig, screen.screenId, idProperty, invisibleFields);
                }
                else {
                    const flowArea = flowConfig.flowAreas.find(fa => fa.screenId === screen.screenId);
                    return flowArea;
                }
            }
        }

        return undefined;
    }

    public static findNextArea(
        flowConfig: IWAFFlowConfiguration,
        currentIdScreen: string,
        nextIdProperty?: string,
        invisibleFields?: string[]
    ): IFlowAreaV2 | undefined {
        let nextArea: IFlowAreaV2 | undefined = undefined;
        if (nextIdProperty) {
            nextArea = flowConfig.flowAreas.find(area => area.fields.some(field => field.idField === nextIdProperty));
        } else {
            const currentScreenIndex = flowConfig.flowAreas.findIndex(area => area.screenId === currentIdScreen);

            if (currentScreenIndex === -1) return undefined;

            if (currentScreenIndex === flowConfig.flowAreas.length - 1) {
                return undefined;
            }
            const candidate = flowConfig.flowAreas[currentScreenIndex + 1];
            if (candidate.fields.every(field => invisibleFields?.includes(field.idField!)) && !candidate.ForceShowArea) {
                return WAFUtils.findNextArea(flowConfig, candidate.screenId, nextIdProperty, invisibleFields);
            } else {
                return candidate;
            }
        }

        return nextArea;
    }

    public static findScreenByArea(
        flowArea: IFlowAreaV2,
        wafScheam: IWAFSchemaServer
    ): IWAFScreen | undefined {
        return wafScheam.screens?.find(screen => screen.screenId === flowArea.screenId);
    }

    public static findFieldinFlowConfiguration(idProperty: string, flowConfig: IWAFEngagementFlowConfiguration): IFlowField | undefined {
        return flowConfig.flowAreas.map(area => area.fields.find(field => field.idField === idProperty)).find(field => isValidRef(field));
    }

    public static findFieldinFlowEngagement(idProperty: string, engagement: IWAFEngagementConfig): IFlowField | undefined {
        return engagement.flowConfigurations.map(config => this.findFieldinFlowConfiguration(idProperty, config)).find(field => isValidRef(field));
    }

    public static findScreenById(id: string, waf: IWAFSchemaServer): IWAFScreen | undefined {
        return waf.screens?.find(screen => screen.screenId === id);
    }

    public static findflowAreaByIdScreen(id: string, waf: IWAFSchemaServer): IWAFScreen | undefined {
        if (WAFUtils.isFlowMuliPage(waf)) {
            return waf.screens?.find(screen => screen.screenId === id);
        }
        return waf as IWAFScreen;
    }

    public static recreateFieldMetadata(flowField: IFlowField, field: TIWAFFieldConfigurationALL) {
        if (isInvalid(flowField.metadata)) {
            if (field) {
                flowField.metadata = {
                    isOptional: !(<IInputFieldDynamicConfiguration>field.configuration)?.required?.default ?? false,
                    type: field.type
                }
            }
        }
    }

    public static getFlowFieldByComponent(flowFields: TIFlowFieldArray, component: IWAFOutputComponent): IFlowField | undefined {
        return flowFields.find(field => field.idFlowField === component.idFlowField);
    }

    public static mapFlowAreaFields(flowArea: IFlowAreaV2, formField: SchemaProperty, component: IWAFOutputComponent): IFlowField {
        const newFlowField: IFlowField = {
            idFlowField: component.idFlowField!,
            idField: formField.idProperty,
            metadata: {
                isOptional: !(<IInputFieldDynamicConfiguration>component.configuration)?.required?.default ?? false,
                type: component.type
            }
        };

        const existingFlowField = WAFUtils.getFlowFieldByComponent(flowArea.fields, component);

        if (existingFlowField) {
            // if I simply remove and then add the new flow field, it causes a bug on the client
            // that's why I'm using Object.assign
            Object.assign(existingFlowField, newFlowField);
        } else {
            flowArea.fields.push(newFlowField);
        }

        return existingFlowField || newFlowField;
    }

    public static fieldIsOptional(field: TIWAFFieldConfigurationALL): boolean {
        return !(<IInputFieldDynamicConfiguration>field.configuration)?.required?.default || false;
    }

    public static canEditSchema(schema: IWAFSchemaClient): boolean {
        const cantEditStatus = [EWAFStatus.PUBLISHED, EWAFStatus.DEPRECATED, EWAFStatus.BLOCKED, EWAFStatus.THROTTLED, EWAFStatus.DELETED];

        return !schema.providers.some(provider => cantEditStatus.includes(provider.status));
    }

    public static getDefaultValueForEachComponent(type: EWAFComponents): any {
        switch (type) {
            case EWAFComponents.TEXT_INPUT:
            case EWAFComponents.TEXT_AREA:
            case EWAFComponents.RADIO_BUTTONS_GROUP:
            case EWAFComponents.DROPDOWN:
            case EWAFComponents.DATE_PICKER:
                return '';
            case EWAFComponents.CHECKBOX_GROUP:
                return [];
            case EWAFComponents.OPT_IN:
                return false;
            default:
                return '';
        }
    }
}

export const isValidMetaChannelProvider = WAFUtils.isValidChannelProvider;

export function isWAFComponentType<Type extends EWAFComponents>(type: Type, component: IWAFAbstractComponent): component is WAFComponentTypeToInterface[Type] {
    return component.type === type
}

export function isWAFOutputComponent(component: IWAFAbstractComponent): component is IWAFOutputComponent {
    return [
        EWAFComponents.TEXT_INPUT,
        EWAFComponents.TEXT_AREA,
        EWAFComponents.CHECKBOX_GROUP,
        EWAFComponents.RADIO_BUTTONS_GROUP,
        EWAFComponents.OPT_IN,
        EWAFComponents.DROPDOWN,
        EWAFComponents.DATE_PICKER,
    ].includes(component.type);
}

