import { IBotMenuContainerServer } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { TVarEditorVariables } from "app/handlers/var-editor.handler";
import { IComponentParameterWithOptionalClient } from "app/model/component-comm/basic";

export interface IBotMenuContainerWhatsappConfigurationClientCallback {
    
}


export interface IBotMenuContainerWhatsappConfigurationParameters extends IComponentParameterWithOptionalClient<IBotMenuContainerWhatsappConfigurationClientCallback> {
    entity: IBotMenuContainerServer;
    getVariables: () => TVarEditorVariables;
}

function initDefaultParameters() {
    const defaultParameters: Partial<IBotMenuContainerWhatsappConfigurationParameters> = {
    
    };
    return defaultParameters;
}

export class BotMenuContainerWhatsappConfigurationHandler extends MainHandler<IBotMenuContainerWhatsappConfigurationParameters> {
    constructor(parameter: IBotMenuContainerWhatsappConfigurationParameters) { defaultFields(parameter, initDefaultParameters()); super(parameter) }
    
}