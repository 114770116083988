export enum EInteractionBasic {
    ScreenGroup = 'basicInteractionItems'
}

export enum ENotificationBtns {
    Respond = 'notificationMessage.respond'
}

export enum EBulkInviteVisualElements {
    BulkInviteTemplateParams = 'bulkInviteTemplateParams',
    BulkInviteFieldAdder = 'bulkInviteFieldAdder',
    BulkInvitePeopleEmail = 'bulkInvitePeopleEmail',
    bulkInvitePeopleEmailByFeedbackStatus = 'bulkInvitePeopleEmailByFeedbackStatus'
}

export enum EChatBtnsVisualElements {
    Open =   'chatMessage.open',
    Edit =   'chatMessage.edit',
    Remove = 'chatMessage.remove',
    Reply =  'chatMessage.reply',
    Feedbacks = 'chatMessage.feedbacks',
    follow = 'chatMessage.follow',
    Sell = 'chatMessage.sell',
    Share = 'chatMessage.share',
    Annotate = 'Annotations',
    BigIconOptions = 'chatMessage.bigIconOptions',
    RememberMeReply = 'chatMessage.rememberMe',
}

export enum ESurveyOptions {
    screenGroup = 'preferenceItem',
    close =       'surveyOptions.close',
    edit =        'surveyOptions.edit',
    remove =      'surveyOptions.remove',
    preview =     'surveyOptions.preview',
    addQuestion = 'surveyOptions.add',
    addOption =   'surveyOptions.addOption',
    share =       'surveyOptions.share'
}

export enum ETreeChatBotOptions {
    preview =     'treeChatBot.preview',
    addQuestion = 'treeChatBot.add',
    addOption =   'treeChatBot.addOption',
}

export enum ESurveyChatOptions {
    screenGroup = 'surveyChatOptions',
    view = 'surveyChat.view',
    fill = 'surveyChat.fill'
}

export enum EFormOptions {
    screenGroup =    'formulary',
    createForm =     'formulary.create',
    createFormItem = 'formulary.createItem',
    createDropdownItem = 'formulary.createDropdownItem',
}

export enum EBigIcon {
    BigIcon = 'bigIcon'
}

export enum HardwareLayers {
    MicrophoneHardware = "microphoneHardware",
}

export enum EFaceCompanyMoreOptions {
    EditHTMLCSS = 'fachtmlcss',
    AddCover = 'facaddcover',
    AddService = 'facaddservice',
}

export enum EStartServiceChatMoreOptions {
    Finish = 'finishServiceChat',
    OpenActiveCall = 'openActiveCallChat'
}