import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { isValidNumber, isValidRef, values } from '@colmeia/core/src/tools/utility';
import { map, skip } from 'rxjs/operators';

export enum SpinType {
    fullScreen = 'fullScreen',
    halfScreen = 'halfScreen',
    none = 'none',
    groupNav = 'groupNav',
    socialNetworkChange = 'socialNetworkChange',
};


export interface ISpinningEvent {
    show: boolean,
    spinType: SpinType;
}

export type TLoadingState = Record<SpinType, number>


@Injectable()
export class ScreenSpinnerService {
    private _loadingState: TLoadingState = {
        [SpinType.fullScreen]: 0,
        [SpinType.halfScreen]: 0,
        [SpinType.none]: 0,
        [SpinType.groupNav]: 0,
        [SpinType.socialNetworkChange]: 0,
    }

    private status: Subject<ISpinningEvent> = new BehaviorSubject({
        show: false,
        spinType: SpinType.fullScreen
    });

    public get lastStatus$(): Observable<ISpinningEvent> { return this.status.asObservable(); }
    public get lastLoading$(): Observable<boolean> { return this.status.asObservable().pipe(map( s => s.show )); }
    public get lastType$(): Observable<SpinType> { return this.status.asObservable().pipe(map( s => s.spinType )); }

    private _loading: Subject<boolean> = new BehaviorSubject(false);
    get loading$(): Observable<boolean> { return this._loading.asObservable() };

    constructor() {
        this.status.pipe(skip(1)).subscribe((ev) => {
            const weight = this._loadingState[ev.spinType] + (ev.show ? 1 : -1);

            this._loadingState[ev.spinType] = weight < 0 ? 0 : weight;

            const hasAnyLoading = values(this._loadingState).some( n => n > 0 );
            this._loading.next(hasAnyLoading);

            window.requestAnimationFrame(() => {
                const hasAnyLoading = values(this._loadingState).some( n => n > 0 );

                hasAnyLoading
                    ? document.body.classList.add('loading')
                    : document.body.classList.remove('loading');
            });


        })
    }

    /**
     * app-component listens to events on loading screen
     * @returns Observable
     */
    public getLoadingListener(): Observable<ISpinningEvent> {
        return this.status.asObservable();
    }

    /**
     * shows loading window
     */
    public show<T extends number | void, R = T extends number ? ReturnType<typeof setTimeout> : void>(status?: Partial<ISpinningEvent>, timeout?: T): R {
        status = isValidRef(status)
            ? { show: true, ...status }
            : { show: true, spinType: SpinType.fullScreen } ;

        this.status.next(status as ISpinningEvent);

        if(isValidNumber(timeout as number)) {
            return setTimeout(() => {
                this.status.next({ ...status, show: false } as ISpinningEvent)
            }, timeout as number) as unknown as R;
        }
    }

    /**
     * hides loading window
     */
    public hide(status?: Partial<ISpinningEvent>) {
        status = isValidRef(status)
            ?  {...status, show: false }
            : { show: false, spinType: SpinType.fullScreen };

        this.status.next(status as ISpinningEvent);
    }

    public getLoadingState(): TLoadingState {
        return this._loadingState;
    }
}
