import { EAllSerializable } from "./all-serializables";
import { IScreenOptions } from "./atext-types";



export const assetScreenElements: IScreenOptions = {
    idSerializable: EAllSerializable.assetScreenElements,
    fields: {
        title: 1,
        command: 2,
    }
} 