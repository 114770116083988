import { Injectable } from '@angular/core';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { Colmeia } from "@colmeia/core/src/shared-business-rules/colmeia/colmeia-req-resp";
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';

@Injectable({
    providedIn: 'root'
})
export class ColmeiaService {

    constructor(
        private sessionSvc: SessionService,
        private api: ServerCommunicationService,
    ) { }

    public isColmeiaStaff(): boolean {
        return this.sessionSvc.isColmeiaTeam();
    }

    public downloadFile(input: Colmeia.DownloadFile.Input): Promise<Colmeia.DownloadFile.Output> {
        return this.api.quick<Colmeia.DownloadFile.Request, Colmeia.DownloadFile.Response>()({
            requestType: apiRequestType.dashboardExtra.colmeia.downloadFile,
            ...input,
        })
    }

    public broker<Input extends Colmeia.Broker.Input>(input: Input): Promise<Colmeia.Broker.Output & Colmeia.Broker.Basic<Input['action']>> {
        return this.api.quick<Colmeia.Broker.Request, Colmeia.Broker.Response>()({
            requestType: apiRequestType.dashboardExtra.colmeia.broker,
            ...input,
        })
    }

    public deleteCoordinator = this.api.safeSendRequest<Colmeia.DeleteCoordinator.Request, Colmeia.DeleteCoordinator.Response>(apiRequestType.dashboardExtra.colmeia.deleteCoordinator);
    public getCoordinatorInfo = this.api.safeSendRequest<Colmeia.GetCoordinatorInfo.Request, Colmeia.GetCoordinatorInfo.Response>(apiRequestType.dashboardExtra.colmeia.getCoordinatorInfo);
}
