import { Component, Input, OnInit } from '@angular/core';
import { constant } from '@colmeia/core/src/business/constant';
import { TInteractionTypeID } from '@colmeia/core/src/business/constant.enums';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import {SessionService} from "../../services/session.service";

enum EShareType {
    Message,
    Citation,
    Avatar,
    Group,
    Undefined,
}

export interface IInteractionPreviewerHandler {
    getInteraction(): Interaction;
}

@Component({
    selector: 'app-interaction-previewer',
    templateUrl: './interaction-previewer.component.html',
    styleUrls: ['./interaction-previewer.component.scss']
})
export class InteractionPreviewerComponent implements OnInit {
    @Input() handler: IInteractionPreviewerHandler

    constructor(
        private sessionSvc: SessionService,
    ) {}

    ngOnInit() {}

    isNormalMessage() {
        return this.handler.getInteraction().getMultimediaObject() === null;
    }

    getMessage(): string {
        return this.handler.getInteraction().getMessage()
    }

    isMessage(): number {
        return EShareType.Message;
    }

    isCitation(): number {
        return EShareType.Citation;
    }

    isAvatar(): number {
        return EShareType.Avatar;
    }

    isGroup(): number {
        return EShareType.Group;
    }

    messageType(): EShareType {
        const interactionTypeID: TInteractionTypeID = <TInteractionTypeID>this.handler
            .getInteraction()
            .getInteractionType()
            .getPrimaryID();

        switch (interactionTypeID) {
            case constant.interactionType.standard.message:
                return EShareType.Message;
            case constant.interactionType.standard.citation:
                return EShareType.Citation;
            default:
                return EShareType.Undefined;
        }
    }

    isMyInteraction(): boolean {
        return this.handler
            .getInteraction()
            .getParticipant()
            .getAvatar()
            .iss(this.sessionSvc.getSelectedAvatar());
    }
}
