import { I360Media } from "@colmeia/core/src/core-constants/bot";
import { GenerativeChunkMetadata } from "@colmeia/core/src/shared-business-rules/generative/generative.req-resp";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { Miliseconds } from "@colmeia/core/src/time/time-utl";
import { ValueOf } from "@colmeia/core/src/tools/utility-types";
import { Define } from "@colmeia/core/src/tools/utility/types/entities/define";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader, IdChunk, IdVector } from "../non-serializable-id/non-serializable-id-interfaces";
import { IEmbeddingProvider, ILLMProvider } from "@colmeia/core/src/shared-business-rules/knowledge-base/luis-core-interfaces";

export const MAX_CHUNK_SIZE = 3000;
export const MAX_CHUNK_OVERLAP = 0.1 * MAX_CHUNK_SIZE;



//
export interface IGenerativePromptClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.generativePrompt;
    /**
     * Lower temperature (e.g., 0.1 - 0.4)
     * - Produces more focused, conservative, and consistent responses. This is useful when the marketer needs factual information, precise answers, or messaging that adheres closely to a specific format or brand guideline.
     *
     * Moderate temperature (e.g., 0.5 - 0.7)
     * - Strikes a balance between creativity and consistency. This setting can be useful for general content generation, where a blend of accuracy and inventiveness is desired.
     *
     * Higher temperature (e.g., 0.8 - 1.0)
     * - Generates more creative, diverse, and unexpected outputs. Marketers may prefer this setting when brainstorming innovative campaign ideas, crafting engaging social media content, or seeking fresh perspectives on a topic.
     *
     * Source: https://www.linkedin.com/pulse/temperature-check-guide-best-chatgpt-feature-youre-using-berkowitz/
     */
    temperature: number;
    text: string;
    idFunction?: string;
}

export interface IGenerativePromptServer extends INonSerializable, IGenerativePromptClient {
    nsType: ENonSerializableObjectType.generativePrompt;
}


//

export interface IGenerativeVectorSplitterURLConfig {
    url: string;
    isDynamic: boolean;
}

export interface IGenerativeVectorSplitterConfig {
    type: TextSplitterType;
    chunkSize: number;
    chunkOverlap: number;

    /**
     * Present in TextSplitterType.RecursiveCharacterTextSplitter
     */
    separators?: string[];
    /**
     * Present in TextSplitterType.CharacterTextSplitter
     */
    separator?: string;
    /**
     * Present in TextSplitterType.TokenTextSplitter
     */
    encodingName?: string;
}



export interface IGenerativeVectorProvider {
    llm?: ILLMProvider;
    embedding?: IEmbeddingProvider;
}

export interface IGenerativeVectorClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.contentVector;
    loader: EVectorLoader;
    documentMedia?: I360Media;
    splitterConfig: IGenerativeVectorSplitterConfig;
    vectorDBType: EVectorDBType;
    providers?: IGenerativeVectorProvider;
    embeddingModel?: TEmbeddingModel;
    urls: IGenerativeVectorSplitterURLConfig[];
}

export interface IGenerativeVectorServer extends IGenerativeVectorClient, INonSerializable {
    nsType: ENonSerializableObjectType.contentVector;
    idNS?: IdVector;
}

//////////////

export enum TextSplitterType {
    /**
     * Implementation of splitting text that looks at characters.
     */
    CharacterTextSplitter = 'CharacterTextSplitter',

    /**
     * Require regular expressions
     * Implementation of splitting text that looks at characters.
     * Recursively tries to split by different characters to find one that works.
     */
    RecursiveCharacterTextSplitter = 'RecursiveCharacterTextSplitter',

    /**
     * Implementation of splitting text that looks at tokens.
     */
    TokenTextSplitter = 'TokenTextSplitter',

    /**
     * Implementation of splitting markdown files based on specified headers.
     */
    MarkdownHeaderTextSplitter = 'MarkdownHeaderTextSplitter',
}

export enum EncodingType {
    gpt2 = "gpt2",
    r50k_base = "r50k_base",
    p50k_base = "p50k_base",
    p50k_edit = "p50k_edit",
    cl100k_base = "cl100k_base"
}

export enum EVectorLoader {
    PDF = 'PDF',
    URL = 'URL',
    CSV = 'CSV',
    API = 'API',
    MANUAL = 'MANUAL',
}

export enum EInputSource {
    Human = 'H',
    AI = 'AI',
    SystemPrompt = 'S',
    FunctionCall = 'FC',
    FunctionCallResult = 'FCR'
}

export enum EChatLLMModel {
  ChatGPT3_5Turbo = "gpt-3.5-turbo",
  ChatGPT4o = "gpt-4o",
  Gemini10Pro = "gemini-1.0-pro",
  Gemini10Pro001 = "gemini-1.0-pro-001",
  Gemini10Pro002 = "gemini-1.0-pro-002",
  Gemini15Pro = "gemini-1.5-pro-001",
  Gemini15Flash = "gemini-1.5-flash-001"
}

export enum EOpenAIEmbeddingModel {
    TextEmbeddingAda002 = "text-embedding-ada-002",
    TextEmbedding3Small = "text-embedding-3-small",
    TextEmbedding3Large = "text-embedding-3-large",
}

export enum EGoogleEmbeddingModel {
    TextEmbeddingGecko001 = "embedding-001",
    TextEmbedding004 = "text-embedding-004"
}

export enum EEmbeddingProvider {
    OpenAI = 'OpenAIGPT',
    Google = 'GoogleGemini',
}

// Add an intersection of enums if new model types are added
export type  TEmbeddingModel = EOpenAIEmbeddingModel | EGoogleEmbeddingModel;


export interface IPredictOnGenerative {
    keyword: string; // idChunk secundário
    contentWeight: number;
    keywordWeight: number;
    idPredictConfig: IdChunk;
}

export interface IGenerativeVectorChunkClient extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.contentVectorChunk;
    idParent: IdDep<ENonSerializableObjectType.contentVector>;

    elementOrder: number;
    metadata: GenerativeChunkMetadata; // tirar do índice
    question?: string; // deixar obrigatorio  -- Qual a melhor pergunta que descreve o conteúdo abaixo?
    pageContent: string; // tirar do índice
    // @TODO
    // checar se tem algo relevante como posicao inicial e final

    // descontinuar esses dois
    predictOnKeyword?: boolean;
    predictConfig?: IPredictOnGenerative;
}

export enum EVectorDBType {
    PostgreSQL = 'pgV',
    Pinecone = 'pin',
    Chroma = 'chrom',
    QDrant = 'qdrant',
}

export interface IGenerativeVectorChunkServer extends INonSerializable, IGenerativeVectorChunkClient {
    nsType: ENonSerializableObjectType.contentVectorChunk;
    idParent: IdVector;
}

export interface PGVector {
    idChunk: IdChunk;
    idVector: IdVector;
    provider: EEmbeddingProvider;
    vector: number[];
    // weight?: number;
    // idSupportedChunk?: IdChunk;
}

export interface SplitterConfigRecursiveCharacterTextSplitter extends IGenerativeVectorSplitterConfig {
    type: TextSplitterType.RecursiveCharacterTextSplitter;
    separators: string[];
}

export interface SplitterConfigCharacterTextSplitter extends IGenerativeVectorSplitterConfig {
    type: TextSplitterType.CharacterTextSplitter;
    separator: string;
}
export interface SplitterConfigTokenTextSplitter extends IGenerativeVectorSplitterConfig {
    type: TextSplitterType.TokenTextSplitter;
    encodingName: string;
}
export interface SplitterConfigMarkdownHeaderTextSplitter extends IGenerativeVectorSplitterConfig {
    type: TextSplitterType.MarkdownHeaderTextSplitter;
}

//

type MapSplitterConfig = Define<{ [type in TextSplitterType]?: IGenerativeVectorSplitterConfig & { type: type } }, {
    [TextSplitterType.RecursiveCharacterTextSplitter]: SplitterConfigRecursiveCharacterTextSplitter
    [TextSplitterType.CharacterTextSplitter]: SplitterConfigCharacterTextSplitter
    [TextSplitterType.TokenTextSplitter]: SplitterConfigTokenTextSplitter
    [TextSplitterType.MarkdownHeaderTextSplitter]: SplitterConfigMarkdownHeaderTextSplitter
}>
type TSplitterConfig = ValueOf<MapSplitterConfig>;

