import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-noop-action-bar',
    templateUrl: './noop-action-bar.component.html',
    styleUrls: ['./noop-action-bar.component.scss']
})
export class NoopActionBarComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
