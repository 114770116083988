<div [ngSwitch]="messageType()" class="share_message__container">
  <div *ngSwitchCase="isMessage()"
      class="share__message__container--msg"
      [ngClass]="isMyInteraction() ? 'message__color--mine': 'message_color--third-party'">
    <div *ngIf="isNormalMessage()">
      {{ getMessage() }}
    </div>
    <div *ngIf="!isNormalMessage()">
      <i class="material-icons">slideshow</i>
    </div>
  </div>
  <div *ngSwitchCase="isCitation()" class="share__message__container--citation">
    <span class="share__message--date">{{ date }}</span>
    {{ getMessage() }}
  </div>
  <p *ngSwitchDefault>
  </p>
</div>
