import { IBPMMarketingEndServer } from '../../../BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { MktHostNode } from './mkt-host-node';

export class MktEndHost extends MktHostNode {
    constructor(mktEnd: IBPMMarketingEndServer) {
        super(mktEnd)
    }

    static create(mktEnd: IBPMMarketingEndServer): MktEndHost {
        return new MktEndHost(mktEnd)
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        return nodeTypeToDrag === ENonSerializableObjectType.bpmMarketingAcknowledge
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return  target === ENonSerializableObjectType.bpmMarketingRoot
    }
}
