import { AfterViewInit, Component, Inject, Injector, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IRoutedDlgParameters } from 'app/services/window-navigation/window-navigation.service';
import { ColmeiaWindowRef } from '../colmeia-window/colmeia-window-ref';

@Component({
  selector: 'app-colmeia-routed-window-wrapper',
  templateUrl: './colmeia-routed-window-wrapper.component.html',
  styleUrls: ['./colmeia-routed-window-wrapper.component.scss']
})
export class ColmeiaRoutedWindowWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild('injectedComponent', { read: ViewContainerRef }) containerRef: ViewContainerRef;
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IRoutedDlgParameters<INonSerializable>,
    private injector: Injector,
    private colmeiaWindowRef: ColmeiaWindowRef
    ) {
      data.dialogRef = this.colmeiaWindowRef;
    }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.containerRef.createComponent(this.data.component, {injector: this.injector})
  }
}
