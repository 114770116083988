<section class="chip-autocomplete__wrapper">
  <!-- <mat-form-field class="chip__container" appearance="outline"> -->
  <label class="chip-autocomplete--label">
    {{ handler.getPlaceHolder() }}
  </label>
  <div class="chip-autocomplete--input-wrapper">
    <mat-chip-grid #chipList>
      <mat-chip-row
        *ngFor="let element of handler.getChosenElements()"
        [selectable]="handler.isSelectable()"
        [removable]="handler.isRemovable()"
        (removed)="remove(element)"
      >
        {{ element.getName() }}
        <mat-icon matChipRemove *ngIf="handler.isRemovable()">cancel</mat-icon>
      </mat-chip-row>
      <input
        class="chip-autocomplete--input"
        #userInput
        [formControl]="elementCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="handler.isAddOnBlur()"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option
      *ngFor="let element of (filteredElements$ | async)"
      [value]="element"
    >
      {{ element }}
    </mat-option>
  </mat-autocomplete>
  <!-- </mat-form-field> -->
</section>
