import { Avatar } from "@colmeia/core/src/business/avatar";
import { EObjectType } from "@colmeia/core/src/business/constant.enums";
import { Group } from "@colmeia/core/src/business/group";
import { Participant } from "@colmeia/core/src/business/participant";
import { Player } from "@colmeia/core/src/business/player";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { getMethodsOfClass } from "@colmeia/core/src/tools/utility";
import { ChatBackboneComponent } from "app/components/backbone/chat-backbone.component";
import { ChatMediaRecorder } from "app/components/chat-media-recorder/chat-media-recorder.component";
import { ChatActionBarComponent } from "app/components/chat/action-bar/chat-action-bar.component";
import { ChatActionBarService } from "app/components/chat/action-bar/chat-action-bar.service";
import { AttachmentComponent } from "app/components/chat/chat-attachment/attachment.component";
import { GroupChatLeftNavSideComponent } from "app/components/chat/group-chat-left-side-bar/group-chat-left-side-bar.component";
import { GroupChatRightNavSideComponent } from "app/components/chat/group-chat-right-side-bar/group-chat-right-side-bar.component";
import { GroupOrderSelectorShortcutComponent } from "app/components/chat/group-order-selector-shortcut/group-order-selector-shortcut.component";
import { GroupSelectorShortcutComponent } from "app/components/chat/group-selector-shortcut/group-selector-shortcut.component";
import { MessagesContainerComponent } from "app/components/chat/message-container/message-container.component";
import { MessageInstanceIteratorComponent } from "app/components/chat/message-container/message-instance-iterator/message-instance-iterator.component";
import { FinishServiceMessageComponent } from "app/components/chat/message-container/message-instance/finish-service-message/finish-service-message.component";
import { GroupServiceComponent } from "app/components/chat/message-container/message-instance/group-service/group-service.component";
import { MenuPrintComponent } from "app/components/chat/message-container/message-instance/menu-print/menu-print/menu-print.component";
import { MessageInstance } from "app/components/chat/message-container/message-instance/message-instance.component";
import { ReadConfirmationComponent } from "app/components/chat/message-container/message-instance/read-confirmation/read-confirmation.component";
import { StatusIconsComponent } from "app/components/chat/message-container/message-instance/status-icons/status-icons.component";
import { SNConfigService } from "app/components/dashboard/social-network-config/sn-config.service";
import { EClientErrorGroup, EClientErrorType, TClientErrorIdentificationConfigHash } from "app/errors/client-error.model";
import { AttendanceService } from "app/services/attendance.service";

function buildPossibleMethodsFragments(cls: new (...[]) => unknown): string[] {
    return getMethodsOfClass(cls).map(method => `${cls.name}.${method}`)
}

export const errorGroupIdentifications: TClientErrorIdentificationConfigHash<EClientErrorGroup> = {
    [EClientErrorGroup.Attendance]: {
        stackFragmentsMatch: buildPossibleMethodsFragments(AttendanceService),
    },
    [EClientErrorGroup.Dashboard]: {},

}


export const errorIdentifications: TClientErrorIdentificationConfigHash<EClientErrorType> = {
    [EClientErrorType.SocialNetworkDataLoad]: {
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(SNConfigService)
        ],
    },
    [EClientErrorType.AppFileLoad]: {
        messagesFragmentsMatch: [
            "Loading chunk"
        ]
    },
    [EClientErrorType.Participant]: {
        messagesFragmentsMatch: [
            "getAvatarPersonalParticipantID",
            "getAvatarPersonalGroupID",
            "getParticipantInfoFromGroupParticipant",
            "getParticipantInfoFromGroupAvatar",
            "getPlayerIDFromGroupAvatar",
            `idObjectType -> ${EObjectType.participant}`,
        ],
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(Participant as any),
        ]
    },
    [EClientErrorType.Group]: {
        messagesFragmentsMatch: [
            `idObjectType -> ${EObjectType.group}`,
        ],
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(Group as any),
        ]
    },
    [EClientErrorType.Avatar]: {
        messagesFragmentsMatch: [
            `idObjectType -> ${EObjectType.avatar}`,
        ],
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(Avatar as any),
        ],
    },
    [EClientErrorType.Player]: {
        messagesFragmentsMatch: [
            `idObjectType -> ${EObjectType.player}`,
        ],
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(Player as any),
        ]
    },
    [EClientErrorType.Interaction]: {
        messagesFragmentsMatch: [
            `idObjectType -> ${EObjectType.interaction}`,
        ],
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(Interaction as any),
        ]
    },
    [EClientErrorType.TypeError]: {
        classInstanceMatch: TypeError,
    },
    [EClientErrorType.Chat]: {
        stackFragmentsMatch: [
            ...buildPossibleMethodsFragments(ChatActionBarComponent as any),
            ...buildPossibleMethodsFragments(ChatBackboneComponent as any),
            ...buildPossibleMethodsFragments(MessagesContainerComponent as any),
            ...buildPossibleMethodsFragments(MessageInstanceIteratorComponent as any),
            ...buildPossibleMethodsFragments(MessageInstance as any),
            ...buildPossibleMethodsFragments(GroupServiceComponent as any),
            ...buildPossibleMethodsFragments(FinishServiceMessageComponent as any),
            ...buildPossibleMethodsFragments(StatusIconsComponent as any),
            ...buildPossibleMethodsFragments(ReadConfirmationComponent as any),
            ...buildPossibleMethodsFragments(MenuPrintComponent as any),
            ...buildPossibleMethodsFragments(GroupChatLeftNavSideComponent as any),
            ...buildPossibleMethodsFragments(GroupChatRightNavSideComponent as any),
            ...buildPossibleMethodsFragments(AttachmentComponent as any),
            ...buildPossibleMethodsFragments(ChatActionBarService as any),
            ...buildPossibleMethodsFragments(ChatMediaRecorder as any),
            ...buildPossibleMethodsFragments(GroupOrderSelectorShortcutComponent as any),
            ...buildPossibleMethodsFragments(GroupSelectorShortcutComponent as any),
        ]
    },
    [EClientErrorType.NoType]: {},
};
