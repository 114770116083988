import { TMultipleCursor } from "../../request-interfaces/request-interfaces";
import { createKeyChecker, isInEnum, isKeysIn, isKeysInAndValid, isValidRef, safeStringifyAnything, typedCloneLodash } from "../../tools/utility";
import { Empty } from "../../tools/utility-types";
import { EInsideNSObject, INonSerializable, INonSerializableHeader, TDpendencyObjectType } from "./non-serializable-id-interfaces";

export const CMultipleCursor = '__MCURSOR';

export function toMultipleCursor(cursor: Empty<string>): TMultipleCursor {
    return { [CMultipleCursor]: cursor };
}

export function isInsideElement(type: TDpendencyObjectType): boolean {
    return isInEnum(EInsideNSObject, type)
}

const checkIfHasSavedNSKeys = createKeyChecker<Partial<INonSerializable>>(['idNS', 'ident', 'lastTouch']);
export function isNSServer(nser: INonSerializable | INonSerializableHeader | undefined): nser is INonSerializable {
    return isValidRef(nser) && checkIfHasSavedNSKeys(nser);
}

export const isSavedNS = isNSServer;
export const isNSSaved = isNSServer;

export function isNewNS(nser: INonSerializable | INonSerializableHeader | undefined) {
    return !isSavedNS(nser);
}
export const isNSNew = isNewNS;
