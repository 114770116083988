import { Component, Input, OnInit } from "@angular/core";

export enum ECmBtnColorType {
    White = 'white',
    Main = 'main',
    LightBlue = 'lightBlue',
    Green = 'green',
    DarkGreen = 'darkGreen',
    Grey = 'grey',
    Transparent = 'transparent',
    Default = 'default',
}

@Component({
    selector: "cm-button",
    templateUrl: "./cm-button.component.html",
    styleUrls: ["./cm-button.component.scss"]
})
export class CmButtonComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() color: ECmBtnColorType;
    @Input() classes: string;

    constructor() {}

    ngOnInit() {
    }
    
    getBtnColor(): ECmBtnColorType {
        return this.color || ECmBtnColorType.Main;
    }
    
    getClasses() {
        if (this.classes) {
            return this.classes
        }
    }
}
