<div *ngIf="handler.isAnyStatusAvailable()" class="status-icons__container flex-container">

  <button class="status__icon additional-info" (click)="openSharedMsgsModal()" *ngIf="canShowShared()"
    matTooltip="Compartilhamentos">
    <span>{{ getNumberOfShares() }}</span>
    <i class="material-icons">share</i>
  </button>

  <button class="status__icon  additional-info" *ngIf="canShowEditedMessage()" (click)="openEditedMsgs()">
    <span>{{ getNumberOfEditedMsgs() }}</span>
    <i class="material-icons">edit</i>
  </button>

  <button class="status__icon" *ngIf="canShowAnnotation()" (click)="openAnnotations()" matTooltip="Anotações">
    <mat-icon>bookmarks</mat-icon>
    <!-- <img class="material-icons" matTooltip="Anotações" src="./assets/icons/annotations-icon.svg" /> -->
  </button>

  <i class="bomb" *ngIf="handler.isSuicidal()" matTooltipClass="mat-tooltip"
    matTooltip="{{ getDissapearLbl() }}: {{ handler.getDueDate() | date:'medium' }}">
    <app-bomb-icon></app-bomb-icon>
  </i>
</div>

<cm-modal [handler]="getEditedMsgsModalHandler()"></cm-modal>
<cm-modal [handler]="getSharedMsgsModalHandler()"></cm-modal>