import { INotificationHardwareAbstraction } from "../hardware-interfaces";
import { EHardwareResourceStatus } from "../cordova/vendor/hardware-status";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class BrowserNotifications implements INotificationHardwareAbstraction{
    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return Promise.resolve(EHardwareResourceStatus.GRANTED);
    }

    isAllowed(): Promise<boolean> {
        return Promise.resolve(true);
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return Promise.resolve(EHardwareResourceStatus.GRANTED);
    }

}
