
<!-- <app-loading></app-loading> -->
<!-- <ngx-pull-to-refresh
    *ngIf="systemReady && isMobile"
	spinnerColor="#26dcba"
    spinnerSize="32"
	distanceForRefresh="40"
	[isEnable]="true"
	(refresh)="myRefreshEvent($event, 'refresh')"
	(loadMore)="onRefresh()"
	style="display:block;height:100%;"
>
    <router-outlet *ngIf="systemReady"></router-outlet>
</ngx-pull-to-refresh> -->

<router-outlet *ngIf="systemReady"></router-outlet>

