import { Injectable } from "@angular/core";
import { getDefaultTimeWindow } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";
import { CRM_USER_CONFIG_LOCAL_STORAGE_KEY } from "app/crm-service-tickets-view/crm-user-config/crm-user-config.constants";
import { ECRMTicketViewMode, IUserTicketUIConfig } from "app/crm-service-tickets-view/crm-user-config/crm-user-config.model";
import { HardwareLayerService } from "app/services/hardware";
import { cloneDeep } from "lodash";



function getInitialUserConfigSettings(): IUserTicketUIConfig {
    return {
        viewMode: ECRMTicketViewMode.Kanban,
        selectedPackageId: '',
        kanban: {
            timeWindow: getDefaultTimeWindow(),
            selectedBoardId: '',
            selectedFilters: {},
            customFilters: [],
            sortedCards: {},
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class CRMUserConfigRepositoryService {
    private userConfig!: IUserTicketUIConfig;

    constructor(
        private hardwareSvc: HardwareLayerService,
    ) {
        this.loadPersisted();
    }

    private loadPersisted() {
        const saved = this.storage.getItem<IUserTicketUIConfig | undefined>(CRM_USER_CONFIG_LOCAL_STORAGE_KEY);

        if (!saved) {
            this.userConfig = getInitialUserConfigSettings();
            this.saveUserConfig();
        } else {
            this.userConfig = saved;
        }

        this.userConfig.viewMode ||= ECRMTicketViewMode.Kanban;
    }

    public saveUserConfig() {
        const toSave = cloneDeep(this.userConfig);

        // Para não bloquear a UI
        requestAnimationFrame(() => {
            this.storage.putItem(CRM_USER_CONFIG_LOCAL_STORAGE_KEY, toSave);
        });
    }

    get storage() {
        return this.hardwareSvc.getStorage();
    }

    public getUserConfig() {
        return this.userConfig;
    }

}
