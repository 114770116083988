import {Interaction} from '../interaction';
import {ChainedInteraction} from '../chained-interaction';
import {IDeleteInteractionJSON} from '../../comm-interfaces/interaction-interfaces';
import {Participant} from '../../business/participant';
import {InteractionType} from '../interaction-type';
import {constant, TGlobalUID} from '../../business/constant';

export class DeleteInteraction extends ChainedInteraction {
    private deleteDate: number;

    protected constructor(primaryID: TGlobalUID, participant: Participant, interactionParent: Interaction, 
                            idDeleteType: TGlobalUID = constant.interactionType.standard.deleteInteraction) {
        super(primaryID, participant, InteractionType.staticFactory(idDeleteType), interactionParent);
    };
    
    
    public rehydrate(json: IDeleteInteractionJSON): void {
        super.rehydrate(json);
        this.deleteDate = json.dateDeleted;
    };

    public toJSON(): IDeleteInteractionJSON {
        return super.toJSON();
    };

    public static factoryMessage(json: IDeleteInteractionJSON, participant: Participant = null): DeleteInteraction {
        let deleted: DeleteInteraction = <DeleteInteraction>Interaction.searchCachedInteraction(json.primaryID);
        if (deleted == null)
            deleted = new DeleteInteraction(json.primaryID, 
                        participant? participant: Participant.staticFactory(json.participant.primaryID), 
                        Interaction.staticFactory(json.idInteractionParent))
        deleted.rehydrate(json);
        return deleted;
    };

    public static getNewDeleteInteraction(participant: Participant, interactionParent: Interaction) {
        let interaction: DeleteInteraction = new DeleteInteraction(null, participant, interactionParent);
        return interaction;
    };
};

