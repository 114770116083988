import { MessageInstanceHandler, IMessageInstanceParameter } from "../message-instance-handler";
import { TSerializableArray } from "@colmeia/core/src/business/serializable";
import { EChatBtnsVisualElements } from "@colmeia/core/src/core-constants/constant-visual-elements.enums";

export class TimedMessageHandler extends MessageInstanceHandler {

    constructor(messageParameter: IMessageInstanceParameter){
        super(messageParameter);
    }

    getMoreOptions(): TSerializableArray{
        return this.getFilteredOptions();
    }

    private getFilteredOptions(): TSerializableArray {
        return [
            ...super.getMoreOptions()
                .filter(option => option.is(
                    EChatBtnsVisualElements.Remove,
                ))
        ]
    }

}
