import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext, isDevMode } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl, ɵDomSanitizerImpl, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { EFileTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

export type TSanitizedUrl = string
type TSecureUrl = SecurityContext.URL
type TSecureStyle = SecurityContext.STYLE
type TSecureResource = SecurityContext.RESOURCE_URL
export type TSafeUrlContainer = {
    [K in SecurityContext]: SafeUrl | SafeStyle | SafeHtml | SafeResourceUrl;
}

@Injectable({
    providedIn: 'root'
})
export class ImgSecureDownloadService {
    imageURLDB: { [urlObjectID: string]: Observable<TSafeUrlContainer> } = {}

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private sanitizerImpl: ɵDomSanitizerImpl
    ) { }

    download(imageUrl: string): Observable<TSafeUrlContainer> {
        if (!isValidString(imageUrl)) {
            return of(<TSafeUrlContainer>{})
        }
        const currentDownload: Observable<TSafeUrlContainer> = this.imageURLDB[imageUrl]
        if (currentDownload) {
            return currentDownload
        }
        this.imageURLDB[imageUrl] = this.isExternalURL(imageUrl)
            ? of(this.getSafeUrl(imageUrl))
            : this.http.get(imageUrl, { responseType: EFileTypes.Blob })
                .pipe(
                    map((blob: Blob) => URL.createObjectURL(blob)),
                    map((urlObject: string) => this.getSafeUrl(urlObject)),
                    shareReplay(1),
                    catchError(_ => of(
                        this.getSafeUrl(`assets/img/http/404.jpg`))))
        const result = this.imageURLDB[imageUrl]
        return result
    }

    private isExternalURL(url: string): boolean {
        return url.startsWith('https://') && (isDevMode() ? true : (!url.includes('colmeia.me') && !url.includes('colmeia.cx')));
    }

    getSafeUrl(urlObject: string): TSafeUrlContainer {
        return {
            [SecurityContext.URL]: this.sanitizer.bypassSecurityTrustUrl(urlObject),
            [SecurityContext.STYLE]: this.sanitizer.bypassSecurityTrustStyle(urlObject),
            [SecurityContext.RESOURCE_URL]: this.sanitizer.bypassSecurityTrustResourceUrl(urlObject),
            [SecurityContext.HTML]: this.sanitizer.bypassSecurityTrustHtml(urlObject),
            [SecurityContext.SCRIPT]: this.sanitizer.bypassSecurityTrustScript(urlObject),
            [SecurityContext.NONE]: this.sanitizer.bypassSecurityTrustStyle(urlObject),
        }
    }

    getSanitizedSafeURL(secContext: SecurityContext, safeUrl: SafeUrl): string {
        return this.sanitizerImpl.sanitize(secContext, safeUrl)
    }
}
