import { IBotActionAsset } from "../bot/bot-action-model";
import { TContentAssetArray } from "../bot/bot-content-model";
import { ITaggable } from '../colmeia-tags/tags';
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { ETypeBPMActionModel } from "../crm/crm-services/_crm-action-model";
import { IYouTaskDestinationSettings } from "../you-control/solicitation-tasks-model";
import { EBPMMarketingActionType } from "./marketing/bpm-marketing.model";

export enum EBPMAction {
    action = 'assetAction',
    validator = 'validator',
    conditionalDisplay = 'condDisplay',

    mktFilter = 'mktFilter',


    // LGPD specific Action
    anomization = 'anonimization',
    getTitularData = 'getTitularData', //
    optOut = 'optOut',  // 
    optIn = 'optIn', // * não seleciona estilo,
    //AddTag = 'crmTAG', // Add tag ten que ir para o modelo padrão com Smartfield com condicional chamar de conditional registry e colocar também funcoes
    solicitation = 'bpmSolic'
}

export type TBPM_LGPDActions =
    | EBPMAction.anomization
    | EBPMAction.getTitularData
    | EBPMAction.optOut
    | EBPMAction.optIn


export interface IBPMActionModel {
    bpmAction: TAllBPMACtion
}
export type TAllBPMACtion = EBPMAction | ETypeBPMActionModel | EBPMMarketingActionType

export interface IMultipleNestedFormConfig {
    // Elmentos Fixos
    maxElements?: number;
    maxElementsLocalCanonical?: string;


    minimumElements?: number; //Preencha ao menos  (nulo respeita a parametrização superior) 
    minElementsLocalCanonical?: string;


    title: TContentAssetArray;
    yesQuestion: TContentAssetArray;
    noQuestion: TContentAssetArray;

    // Conteúdo pós término do Subformulário
    posNestedContent?: TContentAssetArray;

}


export interface IBPMActionAsset extends IBPMActionModel {
    bpmAction: EBPMAction.action;
    action: IBotActionAsset;
    isMultipleFormConfig?: boolean
    multipleCallsConfig?: IMultipleNestedFormConfig;
}

// IMPLEMENTAR
export interface IBPMConditionalDisplay extends IBPMActionModel {
    doNotDisplay: boolean;
    bpmAction: EBPMAction.conditionalDisplay;
}

export interface IBPMValidatorActionOnError {
    errorsCount: number;
    action: IBotActionAsset;
}

export interface IBPMValidatorAction extends IBPMActionModel {
    bpmAction: EBPMAction.validator;
    onSuccess: TContentAssetArray;
    onError: TContentAssetArray;
    actionOnErrors?: IBPMValidatorActionOnError;
}

export interface IHaltValidatorAction extends IBPMValidatorAction {
    messageWhileHalted?: TContentAssetArray;
}

export enum ELGPDActionStyle {
    actNow = 'runNow',
    dwOnly = 'DWentryOnly',
    anonimizationAPI = 'customerAPI'
}


export interface IBPLLGPDBasic extends IBPMActionModel {
    bpmAction: TBPM_LGPDActions;
    actionStyle: ELGPDActionStyle;
    idRoute: string; // SEMPRE LEMBRAR DA DEPENDENCIA
    policyName: string;
}

export interface IBPMLGPDActionOnEngagement extends IBPLLGPDBasic {
    onSuccess: TContentAssetArray;
}

export interface IBPMLGPDActionOnBotAction extends IBPLLGPDBasic {
    /**
     * APENAS NO OPT OUT
     */
    idFunctionOptOut?: IdDep<ENonSerializableObjectType.userFunction>;
}

export enum ERunCampaingConditionalOption {
    run = 'run', ignore = 'ig'
}
export interface IBPMConditionalSendAction extends IBPMActionModel {
    campAction: ERunCampaingConditionalOption
}

export interface IBPMActionSolicitation extends IBPMActionModel, ITaggable, IYouTaskDestinationSettings {
    bpmAction: EBPMAction.solicitation;
}