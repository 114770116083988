<section class="container" [ngClass]="{ 'limit-height': limitHeight }">
        <h4 *ngIf="header">{{ header }}</h4>

        <section class="list-controls" *ngIf="!hideListControls">

            <mat-form-field appearance="fill">
                <mat-label> {{ translations.selectTemplate }} </mat-label>
                <mat-select (selectionChange)="setCurrentTemplateFilter()" [(ngModel)]="currentTemplateFilter">
                    <mat-option [value]="undefined">---</mat-option>
                    <mat-option [value]="emptyTemplate">{{ translations.noTemplate }}</mat-option>
                    <mat-option *ngFor="let item of schemas" [value]="item">{{ item.name }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="refresh">
                <mat-icon (click)="loadData()">refresh</mat-icon>
            </div>

        </section>


        <section class="spinner" *ngIf="showSpinner; else dataLoaded">
            <mat-progress-spinner
                mode="indeterminate"
                diameter="30"
            >
            </mat-progress-spinner>
        </section>

        <ng-template #dataLoaded>
            <app-alert type="info" dense class="no-data-alert" *ngIf="annotations.length == 0; else table">
                {{ translations.noAnnotations }}
            </app-alert>

            <ng-template #table>
                <table mat-table [dataSource]="annotations">
                    <ng-container matColumnDef="avatar">
                        <th mat-header-cell *matHeaderCellDef>{{ translations.user }}</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="avatar-data">
                                <hexagon [hexagonHandler]="hexagonHandlers[element.ident.idAvatar]"></hexagon>
                                <div class="avatar-name">{{ element.userName }}</div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="text">
                        <th mat-header-cell *matHeaderCellDef>{{ translations.text }}</th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-raised-button
                                (click)="seeAnnotation(element)"
                                color="primary"
                                *ngIf="element.idSchemma"
                            >{{ translations.seeAnnotation }}</button>

                            <div class="annotation-text">
                                {{ element.text }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="template">
                        <th mat-header-cell *matHeaderCellDef>{{ translations.template }}</th>
                        <td mat-cell *matCellDef="let element">{{ element.templateName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>{{ translations.date }}</th>
                        <td mat-cell *matCellDef="let element">{{ element.date }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-template>
        </ng-template>
    </section>
