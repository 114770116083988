import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { copyToClickBoard } from 'app/utils/copy';

@Injectable({
  providedIn: 'root'
})
export class CopyToClipboardService {

  constructor(private snackSvc: MatSnackBar) { }

  public async copy(value: string) {
    await copyToClickBoard(value);
    this.snackSvc.open("Copiado para a área de transferência.", "Fechar", { duration: 3000 })
  }
}
