import { Interaction } from "../../interaction";
import { TGlobalUID } from "../../../business/constant";
import { Participant } from "../../../business/participant";
import { InteractionType } from "../../interaction-type";
import { EGeolocationIntType } from "../../../business/constant.enums";
import { IDisableRealtimeMonitoring } from "../../../comm-interfaces/interaction-interfaces";
import { ChainedInteraction } from "../../chained-interaction";
import { RealTimeGPSTrackerOn } from "./realtime-tracker-on";


export class RealTimeTrackerOff extends ChainedInteraction {
    private enabled: boolean;
    private idMedia: string;
    private nameAvatar: string;
    private idTrackedAvatar: TGlobalUID;

    private constructor(primaryID: TGlobalUID, participant: Participant, enabled: RealTimeGPSTrackerOn) {
        super(primaryID, participant, InteractionType.staticFactory(EGeolocationIntType.endRealTimeTracking), enabled);
    };
    
    public static getNewTurnGPSff(participant: Participant, enabled: RealTimeGPSTrackerOn): RealTimeTrackerOff {
        return new RealTimeTrackerOff(null, participant, enabled)
    }

    public getTrackedAvatarName(): string {
        return  this.nameAvatar;
    };
    public getTrackedIDMedia(): string {return this.idMedia; };
    public getTrackedIDAvatar(): TGlobalUID {return this.idTrackedAvatar; };

    public getTurnGPSOnID(): TGlobalUID {
        return this.getPrimaryID();
    }

    public rehydrate(json: IDisableRealtimeMonitoring): void {
        super.rehydrate(json);
        this.idTrackedAvatar = json.idTrackedAvatar;
        this.idMedia = json.idTrackedMediaAvatar;
        this.nameAvatar = json.nameAvatar;
    };

    public toJSON(): IDisableRealtimeMonitoring {
        return {
            ...super.toJSON(),
            nameAvatar: this.nameAvatar,
            idTrackedMediaAvatar: this.idMedia,
            idTrackedAvatar: this.idTrackedAvatar
        };
    }; 

    public isExpired(): boolean {return false};

    public static factoryMessage(json: IDisableRealtimeMonitoring, participant: Participant = null): RealTimeTrackerOff {
        let interaction: RealTimeTrackerOff = <RealTimeTrackerOff>Interaction.searchCachedInteraction(json.primaryID);
        if (interaction == null)
            interaction = new RealTimeTrackerOff(json.primaryID,
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                RealTimeGPSTrackerOn.staticFactory(json.idInteractionParent));
        interaction.rehydrate(json);
        return interaction;
    };

}