import { IAudioHardwareAbstraction } from "../hardware-interfaces";
import { Injectable } from "@angular/core";
import { Window } from '../cordova/cordova-plugins-definitions';

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class CordovaAudio implements IAudioHardwareAbstraction {

    playSound(path: string) {
        let fileUrl = `${window.cordova.file.applicationDirectory}www${path}`;
        let media = new window.Media(fileUrl, () => media.release());
        media.play();
        console.log('playing cordova audio with duration', fileUrl);
    }
}
