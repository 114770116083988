import {Component, OnInit, Input, ChangeDetectionStrategy, Injector, ChangeDetectorRef} from '@angular/core';
import { isSecondLevelInteraction } from '@colmeia/core/src/rules/interaction-filter';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { ChainedInteraction } from '@colmeia/core/src/interaction/chained-interaction';
import { EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import {
    MessageInstanceHandler,
    TMessageInstanceHandlerArray,
    EMessageIteratorAnimationState
} from "../../../../handlers/message-instance-handler/message-instance-handler";
import {IChatBackboneInputData, BackboneInputDataToken} from "../../../../handlers/chat-backbone.handler";
import { MessageInstance } from '../message-instance/message-instance.component';
import { Memoize } from 'typescript-memoize';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { constant } from '@colmeia/core/src/business/constant';
import {EmbeddedChatService} from "../../../../services/embedded-chat.service";
import { AttendanceService } from 'app/services/attendance.service';
import { SessionService } from 'app/services/session.service';
import { IGroupServiceParameter } from 'app/model/group-service.model';
import { HardwareLayerService } from 'app/services/hardware';
import { AnnotationsService } from 'app/services/annotations.service';
import { AnnotationsModalHandler, EAnnotationsType } from 'app/handlers/annotations-modal-handler';
import { nop } from '@colmeia/core/src/tools/barrel-tools';
import { getMessageInstanceOptionsConfig } from 'app/model/message-instance-config-db';

@Component({
    selector: 'app-message-instance-iterator',
    templateUrl: './message-instance-iterator.component.html',
    styleUrls: ['./message-instance-iterator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('hideIterator', [
            state(EMessageIteratorAnimationState.visible, style({opacity: 1})),
            state(EMessageIteratorAnimationState.hidden, style({opacity: 0, height: 0, display: 'none'})),
            transition(`${EMessageIteratorAnimationState.hidden} => ${EMessageIteratorAnimationState.visible}`, animate('600ms 0s ease-in-out', keyframes([
                style({display: '*', opacity: 0.1, offset: 0}),
                style({height: '*', opacity: 0.3, offset: 0.3}),
                style({opacity: 0.6, offset: 0.6})
            ]))),
            transition(`${EMessageIteratorAnimationState.visible} => ${EMessageIteratorAnimationState.hidden}`, animate('400ms 0s ease-out'))])
    ]
})
export class MessageInstanceIteratorComponent implements OnInit {
    
    @Input() arrayMessageHandlers: TMessageInstanceHandlerArray;
    @Input() chatBackboneInputData: IChatBackboneInputData;
    @Input() iteratorAnimationState = EMessageIteratorAnimationState.visible;
    @Input() isRootIterator = false;

    public isMobile: boolean;

    constructor(
        private _injector: Injector,
        private cdr: ChangeDetectorRef,
        private embedded: EmbeddedChatService,
        private attendanceService: AttendanceService,
        private sessionService: SessionService,
        private hw: HardwareLayerService,
        private annotationsSvc: AnnotationsService
    ) {
        this.isMobile = this.hw.isMobile();
    }

    ngOnInit() {}

    public _MessageInstance: typeof MessageInstance = MessageInstance;

    @Memoize()
    getInjectorForHandler(handler: MessageInstanceHandler): Injector {
        return Injector.create([{
            provide: MessageInstanceHandler,
            useValue: handler
        }, {
            provide: BackboneInputDataToken,
            useValue: this.chatBackboneInputData
        }], this._injector);
    }

    public showMessage(messageHandler: MessageInstanceHandler): boolean {//@TODO ver isso urgente..
        return !this.canHaveBorder(messageHandler)
            // && (!this.embedded.mustAnswerEmbeddedMenu() || this.embedded.isThisInteractionTheLastMenu(messageHandler.getInteraction()));
    }

    getInteractionHandlerArray(): TMessageInstanceHandlerArray {
        return this.arrayMessageHandlers
    }

    canHide(messageHandler: MessageInstanceHandler): boolean {
        return false
    }

    canSetMarginLeft(messageHandler: MessageInstanceHandler): boolean {
        const isChatViewMode = messageHandler.getChatBackbone().isChatViewMode();
        return this.isFirstHop(messageHandler.getInteraction())
            && isSecondLevelInteraction(messageHandler.getInteraction(), isChatViewMode)
            && !messageHandler.getInteraction().getInteractionType().isFaceCompany();
    }

    canHaveBorder(messageHandler: MessageInstanceHandler): boolean {
        return !this.isRootNode(messageHandler)
            && !messageHandler.getChatBackbone().isChatViewMode();
    }

    canSetBackground(idx: number, messageHandler: MessageInstanceHandler): boolean {
        const prevParticipantIsTheSame = this.isRootNode(messageHandler) && !messageHandler.isShowAvatarName()
        return prevParticipantIsTheSame;
    }

    isRootNode(messageHandler: MessageInstanceHandler): boolean {
        const isRootNode: boolean = !messageHandler.getInteraction().isChainedOrTreeWithParent();
        return isRootNode;
    }

    private canPositionStickyTop(messageHandler: MessageInstanceHandler): boolean {
        const isStartService = messageHandler.getInteraction().getInteractionType().is(constant.interactionType.serviceGroup.startServiceChat);

        if (!isStartService) {
            return false;
        }

        // permite apenas que a primeira mensagem de início de atendimento fique sticky
        for (let i = this.arrayMessageHandlers.length - 1; i--; i >= 0) {
            const msgHandler = this.arrayMessageHandlers[i];

            if (msgHandler.getInteraction().getInteractionType().is(constant.interactionType.serviceGroup.startServiceChat)) {
                return msgHandler === messageHandler;
            }
        }

        return false;
    }

    private canPositionStickyBottom(messageHandler: MessageInstanceHandler): boolean {
        const isFinishService = messageHandler.getInteraction().getInteractionType().is(constant.interactionType.serviceGroup.finishService);

        if (!isFinishService) {
            return false;
        }

        // permite apenas que a primeira mensagem de atendimento finalizado fique sticky
        for (let i = this.arrayMessageHandlers.length - 1; i--; i >= 0) {
            const msgHandler = this.arrayMessageHandlers[i];

            if (msgHandler.getInteraction().getInteractionType().is(constant.interactionType.serviceGroup.startServiceChat)) {
                return false;
            }

            if (msgHandler.getInteraction().getInteractionType().is(constant.interactionType.serviceGroup.finishService)) {
                return msgHandler === messageHandler;
            }
        }

        return false;
    }

    isFirstHop(interaction: Interaction): boolean {
        const parentInteraction =
                (<ChainedInteraction>interaction).isChainedOrTreeWithParent() ?
                (<ChainedInteraction>interaction).getInteractionParent() :
                null;
        const hasParentInteraction = interaction && parentInteraction
        return hasParentInteraction
            ? interaction.getHopNumber() === 1
            : false;
    }

    getContainerClasses(messageHandler: MessageInstanceHandler): string {
        const result = [];

        if (this.canHide(messageHandler)) {
            result.push('hidden');
        }

        if (this.canHaveBorder(messageHandler)) {
            result.push('bordered');

            if (messageHandler.getInteraction().getInteractionType().isFaceCompany()) {
                result.push('bordered--orange');
            } else if (messageHandler.getInteraction().getInteractionType().isChained()) {
                const interaction = messageHandler.getInteraction() as ChainedInteraction;
                const parent = interaction.getInteractionParent();

                if (parent.getInteractionType().is(EServiceGroupIntType.startServiceChat)) {
                    result.push('bordered--start-service-chat');
                }
            }

        }

        if (this.canSetMarginLeft(messageHandler)) {
            result.push('message__margin-left');
        }

        if (this.canPositionStickyTop(messageHandler)) {
            result.push('position-sticky');
        }

        if (this.canPositionStickyBottom(messageHandler)) {
            result.push('position-sticky-bot');
        }

        return result.join(' ');
    }
  
    markForCheck(): void {
        this.cdr.markForCheck();
    }
    
    /**
     * Gera IGroupServiceParameter falso para exibir o banner de início de atendimento quando 
     * ele ainda não foi carregado no scroll
     */
    getFakeGroupServiceParameter(): IGroupServiceParameter | null {
        const groupId = this.sessionService.getSelectedGroupID();
        const isAttendanceGroup = groupId && this.attendanceService.isInAttendDB(groupId);

        if (
            !isAttendanceGroup
            // se já houver interaction de início de atendimento no chat
            || this.arrayMessageHandlers.some(messageHandler => messageHandler.getInteraction().getInteractionType().isStartServiceChat())
        ) {
            return null;
        }
        
        const startServiceChat = this.attendanceService.getInitInteractionService(groupId);

        return {
            interaction: startServiceChat,
            toggleChildren: () => undefined,
        }
    }

    public canShowAnnotation(groupServiceParam: IGroupServiceParameter): boolean {
        const interaction = groupServiceParam.interaction;

        const messageInstanceOptionsConfig = getMessageInstanceOptionsConfig(interaction.getInteractionType().getPrimaryID())

        return interaction.hasAnnotation() && !!messageInstanceOptionsConfig.annotations;
    }

    openAnnotations(groupServiceParam: IGroupServiceParameter): void {
        const interactionPrimaryID = groupServiceParam.interaction.getPrimaryID();
        const isAttending = this.attendanceService.isAttending();

        const handler = new AnnotationsModalHandler({
            type: EAnnotationsType.onlyAnswersFromInteraction,
            persist: true,
            serializableId: interactionPrimaryID,
            onSaveAnnotationCallback: nop,
            clientCallback: nop,
            hideCreateTab: isAttending
        });

        this.annotationsSvc.openFor(handler);
    }
}
