import { VLReturnTypedMessage } from "@colmeia/core/src/shared-business-rules/user-function/user-function-model";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";

export interface IUserFunctionsMessageClientCallback {
    
}
export interface IUserFunctionsMessageParameters extends IComponentParameter {
    typedMessage: VLReturnTypedMessage;
}


const defaultParameters: Partial<IUserFunctionsMessageParameters> = {
    
};

export class UserFunctionsMessageHandler extends MainHandler<IUserFunctionsMessageParameters> {
    private constructor(parameter: IUserFunctionsMessageParameters) { defaultFields(parameter, defaultParameters); super(parameter) }
    public static factory(parameter: IUserFunctionsMessageParameters) { return new UserFunctionsMessageHandler(parameter); }
}