


import { $SharedServices } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services";
import { isAllowingTemplateDelete } from "@colmeia/core/src/shared-business-rules/social-media/social-media.functions";
import { IWhatsAppSocialMediaConnectionServer, TDeliveryProvidersWithWhatsApp } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { $any, GetByKey, Narrow, NoInfer, UnionToIntersection } from "@colmeia/core/src/tools/utility-types";
import { addFnNameOnSource, ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { $Narrow, $NarrowV2, CNarrow, KeyOfListWithPreservedOrder, Toolbelt, ToolbeltPure, ValueOfListWithPreservedOrder } from "@colmeia/core/src/tools/utility/types/validate";
import { DeliveryProvider } from "../../../core-constants/types";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../../non-serializable-id/non-serializable-types";
import { INSWhatsAppTemplateServer, ITemplateProvider, IWhatsAppTemplateApproval } from "../../social-media/template-model";
import { notImplementedSharedFunction as notImplementedSharedMethod } from "../shared-services.basic-functions";
import { SmartersInterface } from "@colmeia/core/src/shared-business-rules/social-media/smarters/smarters-interface";
import { WhatsApp } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-interface";
import { mapKeys, mapValues, values } from "@colmeia/core/src/tools/barrel-tools";



export type SMProvidersConfig = { [name in DeliveryProvider]: { isProfileConfigurable?: boolean } };

// 

class Service {
    public getSettings(id: IdDep<ENonSerializableObjectType.socialMediaConnection>): Promise<WhatsApp.Profile.Settings | undefined> {
        notImplementedSharedMethod(id);
    }

    getProvidersConfig(): Promise<SMProvidersConfig> {
        notImplementedSharedMethod();
    }
}

// 

@ToStatic
export class SocialMediaSharedService extends toStatic(Service) {
    public static config: SMProvidersConfig;

    static async init() {
        SocialMediaSharedService.config = await SocialMediaSharedService.getProvidersConfig();
    }

}


