import { TArrayIDJSON } from '../comm-interfaces/business-interfaces';
import { IInteractionJSON } from '../comm-interfaces/interaction-interfaces';
import { TGlobalUID } from '../core-constants/types';


export interface IMinimumAuthorizeInfo {
    avatarArray: TArrayIDJSON;
};



export class PlayerAuthorizeOnlyInfo {
    private info: IMinimumAuthorizeInfo;
    private idPlayer: TGlobalUID;

    constructor (idPlayer: TGlobalUID, info: IMinimumAuthorizeInfo) {
        this.info = info;
        this.idPlayer = idPlayer;
    }

    public avatarBelongsToPlayer(idAvatar: TGlobalUID): boolean {
        return this.info.avatarArray.some((a) => {return a == idAvatar});
    };
    public interactionMadeByMe(interaction: IInteractionJSON): boolean {
        return this.avatarBelongsToPlayer(interaction.participant.avatar.primaryID);
    }
    public is(idPlayer: TGlobalUID): boolean {return this.idPlayer === idPlayer};
    public getIDPLayer(): TGlobalUID {return this.idPlayer;};
    
};
