<h3 mat-dialog-title>{{ translations.subject }}</h3>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>{{ translations.subject }}</mat-label>

    <mat-chip-grid #subjectChipList>
      <mat-chip-row *ngFor="let subject of subjectsList" [selectable]="true" [removable]="true" (removed)="remove(subject, subjectsList)">
        {{ subject }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input [placeholder]="translations.newBotSubjet.value"
        [matChipInputFor]="subjectChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, subjectsList)">
    </mat-chip-grid>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ translations.action }}</mat-label>

    <mat-chip-grid #actionChipList>
      <mat-chip-row *ngFor="let action of actionsList" [selectable]="true" [removable]="true" (removed)="remove(action, actionsList)">
        {{ action }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input [placeholder]="translations.newBotAction.value"
        [matChipInputFor]="actionChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, actionsList)">
    </mat-chip-grid>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]>{{ translations.cancel }}</button>
  <button mat-flat-button color="primary" (click)="save()">{{ translations.save }}</button>
</mat-dialog-actions>
