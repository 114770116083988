import { Injectable } from '@angular/core';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { constant } from '@colmeia/core/src/business/constant';
import { EObjectType } from '@colmeia/core/src/business/constant.enums';
import { Group } from '@colmeia/core/src/business/group';
import { IAvatarJSON, IGroupJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { TExtendedParticipant, TExtendedParticipantArray } from '@colmeia/core/src/core-constants/types';
import { IListDeployedServicesRequest } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { IListDeployedServicesResponse } from "@colmeia/core/src/dashboard-control/dashboard-response-interfaces";
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { IListSerializablesRemoteRequest, IListSerializablesRemoteResponse } from '@colmeia/core/src/request-interfaces/lookup-ns-request';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { TSerializableHeaderResponse } from '@colmeia/core/src/serializable/header';
import { IServiceDeployedServices } from '@colmeia/core/src/shared-business-rules/deployed-services/deployed-services';
import { ISaveDeployedServiceRequest } from '@colmeia/core/src/shared-business-rules/deployed-services/deployed-services-req-resp';
import { toMultipleCursor } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isValidObject, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { ClientInfraResponse, IInfraParameters } from 'app/model/component/client-infra-comm';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { RoutingService } from 'app/services/routing.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';
import { SubscriptionInteractionInfoBuilder } from 'app/services/subscription-info.service';

const baseRoute = routeList.dashboard.children.service.children.deployedServices;

@Injectable({
    providedIn: 'root'
})
export class DeployedServicesService {
    static getInitialDeployedService(): IServiceDeployedServices {
        return {
            nName: '',
            parameters: {
                serviceType: undefined,
                isActive: undefined,
                config: {
                    idBotRoot: undefined,
                    expireBotComm: undefined
                }
            },
            nsType: undefined,
            ident: undefined,
            messages: {
                expirationMessage: undefined,
                lastRequestStillUnprocessed: undefined,
                resetMessage: undefined,
                transcriptionMessage: undefined,
                unableToResetSessionMessage: undefined,
                failToTranscriptMessage: undefined,
                feedbackDuringCustomerAllocation: undefined
            }
        }
    }


    private _selectedDepSvc: IServiceDeployedServices;

    private deployedServicesList: IServiceDeployedServices[];

    constructor(
        private routeSvc: RoutingService,
        private rbs: RequestBuilderServices,
        private session: SessionService,
        private api: ServerCommunicationService,
        private subscriptionsSVC: SubscriptionInteractionInfoBuilder,
    ) { }

    private buildInfra(): IInfraParameters {
        return this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );
    }

    async getGroupsByIdFromRemoteServer(idNSRemoteEnvAuthProvider: string, idGroup: string): Promise<TSerializableHeaderResponse | undefined> {
        const result = await this.api.sendRequest<
            IListSerializablesRemoteRequest,
            IListSerializablesRemoteResponse
        >(apiRequestType.serializable.getListOnRemoteEnv)({
            idNSRemoteEnvAuthProvider,
            serializableIdList: [{
                serializableID: idGroup,
                objectTypeID: constant.objectType.group
            }],
        });
        return result?.serializableList
    }

    /**
     * gets group from remote server
     * @param idNSRemoteEnvAuthProvider
     * @param idGroup
     */
    async getGroupByIdFromRemoteServer(idNSRemoteEnvAuthProvider: string, idGroup: string): Promise<TExtendedParticipant | undefined> {
        if (idNSRemoteEnvAuthProvider && idGroup) {
            const groups: TSerializableHeaderResponse | undefined = await this.getGroupsByIdFromRemoteServer(idNSRemoteEnvAuthProvider, idGroup)
            if (isValidObject(groups)) {
                const group: IGroupJSON = <IGroupJSON>groups![idGroup]
                const extendedParticipant: TExtendedParticipant = Group.factoryMessage(group);

                return extendedParticipant;
            }
        }
        return undefined
    }

    async getExtendedParticipantById(idAvatarOrGroup: string, objectTypeID: EObjectType.group | EObjectType.avatar = EObjectType.group): Promise<TExtendedParticipant> {
        const selectedParticipants: TExtendedParticipantArray = [];
        if (isValidRef(idAvatarOrGroup)) {
            if (UberCache.testCache(idAvatarOrGroup)) {
                let participant: TExtendedParticipant;
                if (objectTypeID === EObjectType.group)
                    participant = Group.staticFactory(idAvatarOrGroup);
                else if (objectTypeID === EObjectType.avatar)
                    participant = Avatar.staticFactory(idAvatarOrGroup);
                selectedParticipants.push(participant);
            } else {
                const query = [{
                    serializableID: idAvatarOrGroup,
                    objectTypeID: objectTypeID
                }];
                const response: TSerializableHeaderResponse = await this.subscriptionsSVC.serializablesHeader(query);

                let extendedParticipant: TExtendedParticipant;
                if (objectTypeID === EObjectType.group)
                    extendedParticipant = Group.factoryMessage(<IGroupJSON>response[idAvatarOrGroup]);
                else if (objectTypeID === EObjectType.avatar)
                    extendedParticipant = Avatar.factoryMessage(<IAvatarJSON>response[idAvatarOrGroup]);
                selectedParticipants.push(extendedParticipant);
            }

            return selectedParticipants[0];
        } else {
            return undefined;
        }
    }

    async saveDeployedService(deployedService: IServiceDeployedServices): Promise<boolean> {

        deployedService.parameters.hasCustomRoute = isValidString(deployedService.parameters.idWhatsAppServiceRouting);

        const infra: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(this.rbs.getPlayerID(), this.rbs.getAvatarID());
        const request: ISaveDeployedServiceRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.deployedService.saveDepServ, infra),
            deployedService,
        };

        const response: ClientInfraResponse = await this.api.managedRequest(infra, request);

        return response.executionOK;
    }

    getAllDeployedServicesRequest(cursor: string = null): IListDeployedServicesRequest {
        const infra: IInfraParameters = this.buildInfra();
        const request: IListDeployedServicesRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.deployedService.listDepServs, infra),
            multipleCursor: toMultipleCursor(cursor),
            nsType: ENonSerializableObjectType.deployedServices
        };
        return request;
    }

    async getAllDeployedServices(cursor: string = null): Promise<IServiceDeployedServices[]> {
        const infra: IInfraParameters = this.buildInfra();
        const request: IListDeployedServicesRequest = this.getAllDeployedServicesRequest(cursor);

        const response: ClientInfraResponse = await this.api.managedRequest(infra, request);

        if (response.executionOK) {
            const deployedServices: IServiceDeployedServices[] = <IServiceDeployedServices[]>(<IListDeployedServicesResponse>response.response).nonSerializableArray;
            this.deployedServicesList = deployedServices;
            return deployedServices;
        } else {
            return undefined;
        }
    }

    get selectedDepService(): IServiceDeployedServices {
        const selectedDepSvc = this._selectedDepSvc;
        this._selectedDepSvc = undefined;
        return selectedDepSvc;
    }

    getRoute(end: string) {
        return this.routeSvc.mountFullRoute(
            routeID.dashboard,
            [
                routeList.dashboard.children.service.path,
                baseRoute.path,
                end
            ]
        )
    }

    getListRoutePath(): string {
        return this.getRoute(baseRoute.children.list);
    }

    getCreateRoutePath(): string {
        return this.getRoute(baseRoute.children.create);
    }

    getEditRoutePath(idNS: string): string {
        return this.getRoute(baseRoute.children.edit.path.replace(baseRoute.children.edit.routeParam, idNS));
    }

    goToDetails(selectedDepServ: IServiceDeployedServices): void {
        this._selectedDepSvc = selectedDepServ;
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.service.path,
            baseRoute.path,
            baseRoute.children.edit.path.replace(baseRoute.children.edit.routeParam, selectedDepServ.idNS)
        );
    }

    goToCreate(): void {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.service.path,
            baseRoute.path,
            baseRoute.children.create
        );
    }
}
