import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import {
    TMultimediaInstanceArray,
    MultimediaInstance,
    MultimediaObject,
    MMconstant
} from '@colmeia/core/src/multi-media/barrel-multimedia';
import { IMessagesContainerComponent } from '../../message-container.component';
import { EPartialMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';

export enum EMultimediaPreviewType {
    None,
    Icon,
    All
}

export interface IMultimediaPreviewParameter {
    type: EMultimediaPreviewType;
    msgContainerInstance: IMessagesContainerComponent;
    mmObject: MultimediaObject;
    multimediaList: TMultimediaInstanceArray;
    canOpenMediaModal: boolean;
    messageDate: string;
    messageTitle: string;
    maxNumberMultimediaToShow: number;
    missingMediaNumber: number;
    sizeDefinedByParent?: boolean;
    useThumbnails?: boolean;
    onStorageRenewClick?: (idMedia: string) => void
}

export class MultimediaPreviewHandler {
    constructor(
        private parameters: IMultimediaPreviewParameter
    ) {
        if (parameters.useThumbnails === undefined) {
            parameters.useThumbnails = true;
        }
    }

    static new(parameters: Partial<IMultimediaPreviewParameter>): MultimediaPreviewHandler {
        return new MultimediaPreviewHandler({
            canOpenMediaModal: true,
            maxNumberMultimediaToShow: 1,
            messageDate: '',
            messageTitle: '',
            missingMediaNumber: null,
            mmObject: null,
            msgContainerInstance: null,
            multimediaList: [],
            type: EMultimediaPreviewType.All,
            useThumbnails: true,
            ...parameters,
        });
    }

    get useThumbnails(): boolean {
        return this.parameters.useThumbnails;
    }

    setType(type: EMultimediaPreviewType): any {
        this.parameters.type = type;
    }

    getType(): EMultimediaPreviewType {
        return this.parameters.type;
    }

    getMmObject(): MultimediaObject {
        return this.parameters.mmObject
    }

    getAllMultimediaByType(type: EPartialMimeTypes): TMultimediaInstanceArray {
        const multimedias: TMultimediaInstanceArray = [];
        const filterMimeType = (mmInstance: MultimediaInstance) => mmInstance.getMimeType().startsWith(type);

        if (isValidRef(this.getMmObject())) {
            multimedias.push(
                ...this.getMmObject()
                    .getAllMultimediaInstance()
                    .filter(filterMimeType)
            );
        }

        if (isValidArray(this.parameters.multimediaList)) {
            multimedias.push(
                ...this.parameters.multimediaList.filter(filterMimeType)
            );
        }

        return multimedias;
    }

    getAllMultimediaByTag(mmTag: TGlobalUID): TMultimediaInstanceArray {
        const multimedias: TMultimediaInstanceArray = [];

        if (isValidRef(this.getMmObject())) {
            multimedias.push(
                ...this.getMmObject().getAllMultimediaInstanceWithTag(mmTag)
            )
        }

        if (isValidArray(this.parameters.multimediaList)) {
            multimedias.push(
                ...this.getMultimediaList().filter(mmInstance => mmInstance.getMultimediaTag().is(mmTag))
            )
        }

        return multimedias;
    }

    getMultimediaList(): TMultimediaInstanceArray {
        return isValidArray(this.parameters.multimediaList)
            ? this.parameters.multimediaList
            : []
    }

    getMessageDate(): string {
        return this.parameters.messageDate;
    }

    getFormatedMessageTitle(): string {
        return this.parameters.messageTitle
    }

    getMessage(): string {
        return '';
    }

    getMaxDisplayMediaNumber(): number {
        return this.parameters.maxNumberMultimediaToShow
    }

    canOpenMediaModal(): boolean {
        return this.parameters.canOpenMediaModal;
    }

    isVideo(media: MultimediaInstance): boolean {
        return this.parameters.mmObject.isMultimediaVideo(media.getThumbnailParentKey())
    }

    getMissingMediaNumber(): number {
        const missingMediaCount = this.getAllMultimediaByTag(MMconstant.tag.photo).length - this.parameters.maxNumberMultimediaToShow
        return isValidRef(this.parameters.missingMediaNumber)
            ? this.parameters.missingMediaNumber
            : missingMediaCount > 0
                ? missingMediaCount
                : 0
    }

    getMsgContainerInstance(): IMessagesContainerComponent {
        return this.parameters.msgContainerInstance
    }

    isSizeDefinedByParent(): boolean {
        return this.parameters.sizeDefinedByParent;
    }

    getOnStorageRenewClick() {
        return this.parameters.onStorageRenewClick;
    }
}
