import { IResponse } from "../../request-interfaces/response-interfaces";

export interface IRateLimitParamenters {
    stopNow: number;
    allowedPerMinute: number;
    allowedPenalties: number;
    penalityTimeMS: number;
    timeBetweenHaltedMessagesMS: number;
}

export interface ILimitByEndpoint extends IRateLimitParamenters {
    endpoint: string;
}

export interface IRateLimitEndpointsConfig {
    [endpoint: string]: ILimitByEndpoint;
}

export enum ERateAction {
    ok = 'ok',
    messageCustomerAndIgnore = 'msgAndIgnore',
    ignore = 'ignore'
}
