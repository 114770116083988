import {Participant} from '../../business/participant';
import {TGlobalUID, constant} from '../../business/constant';
import { WalletOperationInteraction } from './wallet-operation';
import { UberCache } from '../../persistency/uber-cache';
import { EWalletOperation, IWalletTransferInteractionJSON, IWalletOperationInteractionJSON } from '../../comm-interfaces/finance-interfaces';


export class WalletTransferInteraction extends WalletOperationInteraction {
    private idReceiveAvatar: TGlobalUID;
    
    
    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super (primaryID, participant, EWalletOperation.transfer);
    };

    public rehydrate(json: IWalletTransferInteractionJSON): void {
        super.rehydrate(json);
        this.idReceiveAvatar = json.wallet.idReceiveAvatar;
    };

    public toJSON(): IWalletTransferInteractionJSON {
        const wallet: IWalletOperationInteractionJSON = super.toJSON();
        return {
            ...wallet,
            wallet: {
                ...wallet.wallet,
                idReceiveAvatar: this.idReceiveAvatar,
                idReceivedPlayer: null,
            }
        };
    };

    public getIDReceiver(): TGlobalUID {return this.idReceiveAvatar; };

    private setIDReceiver(id: TGlobalUID) {
        this.idReceiveAvatar = id;
    };

    public static staticFactory(idCharge: TGlobalUID): WalletOperationInteraction {
        return <WalletTransferInteraction>UberCache.uberFactory(idCharge, constant.objectType.interaction, true);
    };

    public static factoryMessage(json: IWalletTransferInteractionJSON, participant: Participant = null): WalletTransferInteraction {
        let charge: WalletTransferInteraction = <WalletTransferInteraction>WalletOperationInteraction.searchCachedInteraction(json.primaryID);
        if (charge == null)
           charge =  new WalletTransferInteraction(json.primaryID, participant? participant: Participant.staticFactory(json.participant.primaryID));
        charge.rehydrate(json);
        return charge;
    };


    public static newWalletTransfer(participant: Participant, toAvatarID: TGlobalUID): WalletTransferInteraction {
        const walletTransfer: WalletTransferInteraction = new WalletTransferInteraction(null, participant);
        walletTransfer.setIDReceiver(toAvatarID);
        return walletTransfer;
    }

}