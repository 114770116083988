import { INotificationHardwareAbstraction } from "../hardware-interfaces";
import { EHardwareResourceStatus } from "../cordova/vendor/hardware-status";
import { Injectable } from "@angular/core";
import { Window } from './cordova-plugins-definitions';

declare var window: Window;


@Injectable({
    providedIn: "root"
})
export class CordovaNotifications implements INotificationHardwareAbstraction{

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .getRemoteNotificationsAuthorizationStatus((status: EHardwareResourceStatus) => {
                    resolve(status);
                }, (error) => {
                    resolve(EHardwareResourceStatus.DENIED);
                });
        });
    }

    isAllowed(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .isRemoteNotificationsEnabled((status: boolean) => {
                    resolve(status);
                }, (error) => {
                    resolve(false);
                });
        });
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        const perm: any = window.cordova.plugins.diagnostic;

        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .requestRemoteNotificationsAuthorization({
                    errorCallback: () => resolve(EHardwareResourceStatus.DENIED),
                    successCallback: () => resolve(EHardwareResourceStatus.GRANTED),
                    omitRegistration: false,
                    types: [
                        perm.remoteNotificationType.ALERT,
                        perm.remoteNotificationType.SOUND,
                        perm.remoteNotificationType.BADGE
                    ]
                });
        });
    }

}
