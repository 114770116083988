import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { HardwareLayerService } from 'app/services/hardware';
import { isValidArray } from '@colmeia/core/src/tools/utility';
import { SessionService } from "../../services/session.service";
import { DashboardWindowBarService } from 'app/components/dashboard/dashboard-foundation/colmeia-window-bar/colmeia-window-bar.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuDashboardService } from 'app/components/menu-dashboard/menu-dashboard.service';
import { ModuleNavService } from 'app/components/navigation/module-nav.service';

@Component({
    selector: 'app-main-header-view',
    templateUrl: './main-header-view.component.html',
    styleUrls: ['./main-header-view.component.scss']
})
export class MainHeaderViewComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('mainContent', {static: true}) mainContent: ElementRef;
    @ViewChild(MatSidenav) sideNav: MatSidenav;
    sideNavTemporaryDisabled = true;

    routerSubs: Subscription;

    extraClasses: string= '';
    showMainHeader: boolean;
    menuMode: string;

    constructor(
        private hw: HardwareLayerService,
        private session: SessionService,
        private dashboardWindowBarSvc: DashboardWindowBarService,
        private _cdr: ChangeDetectorRef,
        private router: Router,
        private dashboardMenuSvc: MenuDashboardService,
        private moduleNavService: ModuleNavService
    ) {
        const extraClasses = [];

        if (hw.isBrowser()) {
            extraClasses.push('browser');
        } else {
            extraClasses.push('app');
        }

        if (navigator.userAgent.toLowerCase().indexOf('android') > 1) {
            extraClasses.push('android');
        } else if (/iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream) {
            extraClasses.push('ios');
        }

        if (isValidArray(extraClasses)) {
            this.extraClasses = extraClasses.join(' ');
        }

        this.showMainHeader = !session.isEmbeddedChat();
        // this.showMainHeader = true;
    }

    ngOnInit() {
        this.routerSubs = this.router.events.subscribe(() => { if (this.moduleNavService.isMobileLayout) this.closeMenu(); });
    }

    ngOnDestroy(): void {
        this.routerSubs?.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.dashboardWindowBarSvc.setup();
        this.checkWindowSize();
        this._cdr.detectChanges();

        // truque para impedir que rode a animação ao trocar de rota (o componente é destruído e instanciado de novo)
        setTimeout(() => {
            this.sideNavTemporaryDisabled = false;
        }, 10);
    }

    @HostListener('window:resize')
    checkWindowSize() {
        if (this.moduleNavService.isMobileLayout) {
            this.menuMode = 'over';
            this.closeMenu();
        } else {
            this.menuMode = 'side';
            this.openMenu();
        }
    }

    openMenu() {
        this.sideNav?.open();
    }

    closeMenu() {
        this.sideNav?.close();
    }

    toggleMenu() {
        this.sideNav?.toggle();
    }
}
