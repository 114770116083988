<div class="container" [class.is-warning]="isWarning()" [class.is-success]="isSuccess()" [class.is-error]="isError()"
  [class.is-internal]="isInternalError">
  <div class="icon-container">
    <mat-icon class="icon warning-icon mat-48">error_outline</mat-icon>
    <mat-icon class="icon success-icon mat-48">done</mat-icon>
    <mat-icon class="icon error-icon mat-48">error</mat-icon>
  </div>
  <h3 class="title cm__title">{{ globalWarningTitle }}</h3>

  <mat-dialog-content>
    <div class="error-description-container">
      <p *ngFor="let message of getMessages()" class="error-description">
        <app-bbcode-viewer [text]="message"></app-bbcode-viewer>
      </p>
      <p *ngIf="isInternalError && customErrorIDs.length" class="custom-errors-ids">
        <span class="error-id" *ngFor="let errorId of customErrorIDs">
          {{ errorId }}
        </span>
      </p>
    </div>
    <div class="additional-info" [visible-horizontal]="showDevData">
      <div>
        <textarea spellcheck="false" *ngFor="let message of additionalText">{{ message }}</textarea>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="help-buttons" *ngIf="isLoggedIn && isInternalError">
      <button mat-button *ngIf="!!additionalText.length" (click)="showDevData = !showDevData"
        matTooltip="Exibir mais informações">
        <mat-icon>info</mat-icon>
      </button>
      <button mat-button *ngIf="!!errorDump" (click)="copyErrorDump()" matTooltip="Copiar objeto para depuração">
        <mat-icon>bug_report</mat-icon>
      </button>
    </div>
    <button mat-button (click)="close()">
      {{ translations.close }}
    </button>
  </mat-dialog-actions>
</div>