import { HandlerHexagonon, IHexagononParameter, TvalidExagononSize } from './hexagono.handler';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { MMconstant } from '@colmeia/core/src/multi-media/multimedia-constant';
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { EColorComponent } from "../model/component/color-component.model";

function getDefaultHexagonParameter(): IHexagononParameter {
    return {
        serializable: null,
        clientCallback: null,
        multimediaInstance: null,
        size: 'xsmd',
        isSelectionable: true,
        idMultimediaTag: MMconstant.tag.photo,
    }
}

export function serializableToHexagononHandler(
    serializable: Serializable,
    size: TvalidExagononSize = 'xsmd'
): HandlerHexagonon {
    //DANN95
    return new HandlerHexagonon({
        ...getDefaultHexagonParameter(),
        serializable: serializable,
        multimediaInstance: serializable.getMultimediaInstanceWithIDTag(MMconstant.tag.photo),
        size
    });
};

export function serializableToHexagononHandlerWithColor(
    serializable: Serializable,
    color: EColorComponent
): HandlerHexagonon {
    return new HandlerHexagonon({
        ...getDefaultHexagonParameter(),
        color: color,
        serializable: serializable,
        multimediaInstance: serializable.getMultimediaInstanceWithIDTag(MMconstant.tag.photo),
    });
}

export function serializableWithPhotoToHexagonHandler(
    serializable: Serializable,
    size: TvalidExagononSize = 'xsmd',
    forceImage?: string,
    isSelectionable = true
): HandlerHexagonon {
    return new HandlerHexagonon({
        clientCallback: null,
        serializable: serializable,
        size: size,
        isSelectionable,
        idMultimediaTag: MMconstant.tag.photo,
        multimediaInstance: serializable.getMultimediaInstanceWithIDTag(MMconstant.tag.photo),
        forceImage: forceImage,
    });
}

export function mediaIDToHexagonHandler(multimidiaID: TGlobalUID, size: TvalidExagononSize = 'xxs'): HandlerHexagonon {
    return new HandlerHexagonon({
        idMultimediaTag: MMconstant.tag.photo,
        forceImage: multimidiaID,
        clientCallback: null,
        size,
    });
}
