import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { EUnitTypeID } from '@colmeia/core/src/business/constant.enums';
import { getEngangementOfTypeForIDField, getSmartFieldOfType } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { TICRMTicketDataBasicArray } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { ETokenStatus } from '@colmeia/core/src/shared-business-rules/embedded/embedded-req-res';
import { ELayoutElement, EMetadataEngagementType, IFieldCRMEngagement, ILayoutFieldMapper, ILayoutFieldMapperInputMask, IMetaEngagement, IRichFormExperience, TIChoiceConfigureArray } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ESmartField } from '@colmeia/core/src/shared-business-rules/smart/smart-fields';
import { msToSec } from '@colmeia/core/src/time/time-utl';
import { getClock, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { CRMTicketsService } from 'app/crm-service-tickets-view/crm-tickets.service';
import { GeneralFormFieldInjectionToken, GeneralFormFieldRenderer, IGeneralFormFieldData } from 'app/model/general-form.model';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { TwoFactorAuthService } from 'app/services/two-factor-auth.service';
import { Subscription } from 'rxjs';
import { GeneralFormFieldService } from '../services/general-form-field.service';

const longTextWordsLength = 3;
const longTextOffsetLength = 32;

@Component({
    selector: 'app-general-form-string-field',
    templateUrl: './general-form-string-field.component.html',
    styleUrls: [
        './general-form-string-field.component.scss',
    ]
})
export class GeneralFormStringFieldComponent extends GeneralFormFieldRenderer<null> {


    type: string = 'text';
    // fGroup: FormGroup
    errorMsg: string = ''
    subscription: Subscription;
    // generalFormEvaluator?: GeneralFormEvaluator
    // schemaForm: TSchemaPropertyArray;
    // answers: TFieldAnswers;
    // fControl: FormControl;
    separatorKeysCodes: number[] = [ENTER];
    engagement: IMetaEngagement;
    advancedMessagesEngagementConfig: IRichFormExperience;
    smartLayoutConfig: ILayoutFieldMapper;
    smartCrmSelectionConfig: IFieldCRMEngagement;

    isCRM: boolean = false;
    isLoadingCustomSelectValues: boolean = false;

    ticketsList: TICRMTicketDataBasicArray = [];

    public inputMask?: string;

    @ViewChild("answerMultipleInput", { static: false })
    answerMultipleInputRef: ElementRef<HTMLInputElement>;

    public hasCustomSelectValues: boolean = false;
    public customSelectValues: { value: string, label: string }[] = [];

    public useTextArea: boolean = false;

    constructor(
        generalFormFieldService: GeneralFormFieldService,
        @Inject(GeneralFormFieldInjectionToken) injectedToken: IGeneralFormFieldData,
        private twoFactorAuthSvc: TwoFactorAuthService,
        private embeddedSvc: EmbeddedChatService,
        private crmTicketsSvc: CRMTicketsService,
    ) {
        super(generalFormFieldService,injectedToken);
        // this.generalFormEvaluator = injectedToken.generalFormEvaluator
        // this.answers = injectedToken.answers
        // this.schemaForm = injectedToken.schemaForm
        // this.fGroup = injectedToken.fGroup
        // this.fControl = injectedToken.fControl
        this.engagement = this.generalFormEvaluator.getEngagement()
    }

    async ngOnInit() {
        super.ngOnInit();
        if (this.field.idUnity === EUnitTypeID.numberType) {
            this.type = 'number';
        }

        if (this.field.isNotVisibleByAgent) {
            this.type = 'password';
        }

        this.checkIfHasAdvancedMessagesEngagement();
        this.checkIfHasSmartLayout();
        this.checkIfHasCRMTicketSelection();

        if(this.isEdit && this.field.multipleAnswers && isValidRef(this.field.value)) {
            for(const value of this.field.value) {
                this.field.createChild(null, true, value);
            }

            this.field.value = undefined;
        }

        this.useTextArea = !this.isEdit && !this.field.isNotVisibleByAgent && isValidString(this.field.value) && this.hasLongTextValue() && !this.isCRM;
    }

    private checkIfHasAdvancedMessagesEngagement() {
        this.advancedMessagesEngagementConfig = getEngangementOfTypeForIDField(this.field.idProperty, this.engagement, EMetadataEngagementType.richFillForm);

        this.hasCustomSelectValues = isValidRef(this.advancedMessagesEngagementConfig);

        if (this.hasCustomSelectValues) {
            this.customSelectValues = this.getAdvancedMessages().map((p) => {
                return { label: p.optionText, value: p.returningID }
            })
        }
    }

    private checkIfHasSmartLayout() {

        this.smartLayoutConfig = getEngangementOfTypeForIDField(this.field.idProperty, this.engagement, EMetadataEngagementType.layout);

        if(!isValidRef(this.smartLayoutConfig)) return;

        switch(this.smartLayoutConfig.type) {
            case ELayoutElement.inputMask:
                this.inputMask = (this.smartLayoutConfig as ILayoutFieldMapperInputMask).formatMask
                break;
            case ELayoutElement.secret:
                this.type = "password"
                break;
        }
    }

    private hasLongTextValue(): boolean {
        const words = this.field.value.split(" ");

        return words.length >= longTextWordsLength || this.field.value.length >= longTextOffsetLength;
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    isStringField() {
        this.field.idUnity === EUnitTypeID.stringType
    }

    isDateField() {
        this.field.idUnity === EUnitTypeID.dateType
    }

    checkControl(){
        console.log({fControl: this.fControl});
    }

    public getStatus(): ETokenStatus {
        return this.fControl.errors?.status;
    }

    public isTokenError(): boolean {
        return this.embeddedSvc.isAnEmbedInstance() && isValidRef(this.getStatus());
    }

    public canResendToken(): boolean {
        return;
    }

    public hasWaitTime(): boolean {
        return isValidRef(this.getWaitTime());
    }

    public getWaitTime(): number {
        const expirationTimeMS: number = this.twoFactorAuthSvc.getExpirationTime(this.fGroup, this.field.idProperty);
        if (expirationTimeMS) return Math.trunc(msToSec(expirationTimeMS - getClock()));
    }

    addMultipleAnswerItem(event: MatChipInputEvent) {
        const value = event.value?.trim();

        if (isValidRef(value)) {
            this.field.createChild(null, true, value);
        }

        event.input.value = '';
    }

    removeMultipleFieldValue(index: number) {
        this.field.children.splice(index, 1);
    }

    public focusAnswerMultipleInput() {
        this.answerMultipleInputRef?.nativeElement?.focus();
    }

    getAdvancedMessages(): TIChoiceConfigureArray {
        return this.advancedMessagesEngagementConfig.multipleChoice
    }

    get advancedMultipleValue(): string {
        return this.field.value;
    }

    onCustomValueChange(value: MatSelectChange) {
        this.setValue(value.value);
    }

    onBlur(ev: Event) {
        const target = (ev.target) as HTMLInputElement;

        this.setValue(target.value);
    }
    
    private setValue(value: string) {
        if(this.canDisplayFormGroup) {
            this.fControl.setValue(value);
        }

        this.field.value = value;
    }

    private checkIfHasCRMTicketSelection() {
        this.smartCrmSelectionConfig = getSmartFieldOfType(this.field.idProperty, this.engagement, ESmartField.CRM);

        this.isCRM = isValidRef(this.smartCrmSelectionConfig);

        if (this.isCRM) {
            this.hasCustomSelectValues = true;
            this.loadTickets();
        }

    }


    private async loadTickets() {
        this.isLoadingCustomSelectValues = true;
        this.customSelectValues = [];
        const attSelectedTicket = this.crmTicketsSvc.getSelectedTicketForAttendance(this.idStartInteraction)

        if (isValidString(attSelectedTicket)) {
            this.setValue(attSelectedTicket);
        }

        // pre conteúdo poderia abrir ao focar a primeira vez no input?

        this.ticketsList = await this.crmTicketsSvc.getTicketsForAttendance({
            idStartInteracrion: this.idStartInteraction,
            timeWindow: this.smartCrmSelectionConfig.timeWindow,
            useVisualizerIdForTitle: this.smartCrmSelectionConfig.idVisualizer,
            showAllCustomerTicketsToAgent: this.smartCrmSelectionConfig.showAllCustomerTicketsToAgent,
            showProjectKey: this.smartCrmSelectionConfig.showProjectKey,
        });

        this.customSelectValues = this.ticketsList.map(t => ({ label: t.title, value: t.idTicket }))

        this.isLoadingCustomSelectValues = false;
    }

}
