import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SecurityContext, SimpleChange, SimpleChanges } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { TSafeVarNameAndType } from 'app/components/dashboard/asset-adder/asset-adder.component';
import { detectSimpleChange, highlightTemplate } from 'app/model/client-utility';

@Directive({
    selector: '[highlightTemplate]'
})
export class ColmeiaTemplateDirective implements OnInit, OnChanges {

    @Input()
    highlightTemplate: string;

    @Input()
    safeVars: TSafeVarNameAndType = [];

    @HostBinding('class')
    containerClass = 'variables-container';

    constructor(
        private renderer: Renderer2,
        private hostElement: ElementRef<HTMLElement>,
        private sanitizer: DomSanitizer) { }

    public ngOnInit() {
        this.parseTemplate();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if(detectSimpleChange(changes.highlightTemplate) || detectSimpleChange(changes.safeVars)) {
            this.parseTemplate();
        }
    }

    private parseTemplate() {
        if(!isValidString(this.highlightTemplate)) return;

        const $el = this.renderer.createElement("div");
        const highlightedTemplateHTML = highlightTemplate(this.highlightTemplate, this.safeVars);
        const safeHTML: SafeHtml | null = this.sanitizer.sanitize(SecurityContext.HTML, highlightedTemplateHTML);

        $el.innerHTML = safeHTML;

        this.hostElement.nativeElement.innerHTML = "";
        this.renderer.appendChild(this.hostElement.nativeElement, $el);
    }
}
