import { trigger } from '@angular/animations';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IListNonSerializablesMatch } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { EDefaultTag, FormRequestType, IMapCurrentDashboardSharedRequests, IntegrationRequestType, IServerColmeiaTag, ITaggable, SaveFormRequestType, SaveTransformerRequestType, TTagAssignmentArray, TransformerRequestType } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NsTypeToInterface } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { EFunctionContext } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { EScreenGroups } from '@colmeia/core/src/shared-business-rules/visual-constants';
import { genericTypedSuggestions } from '@colmeia/core/src/tools/type-utils';
import { createTypeGuard, empty, getClock, isInvalid, isValidFunction, isValidRef, lodashDebounce, useTypeGuardByInstructions } from '@colmeia/core/src/tools/utility';
import { DefineReadableType, Nullish, OldRequireOnlyOne, UnionToIntersection } from '@colmeia/core/src/tools/utility-types';
import { CodeEditorDialogHandler } from 'app/components/dashboard/code-editor/code-editor-dialog/code-editor-dialog.handler';
import { DynamicComponentHandler } from 'app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler';
import { DashboardMenuComponent, IDashboardMenuItem } from 'app/components/dashboard/dashboard-menu/dashboard-menu.component';
import { NSPickerComponent } from 'app/components/dashboard/ns-picker/ns-picker/ns-picker.component';
import { SNConfigService } from 'app/components/dashboard/social-network-config/sn-config.service';
import { MainHandler } from 'app/handlers/main-handler';
import { INonSerializableSelectHandler } from 'app/handlers/non-serializable-select.handler';
import { INSPickerHandlerClientCallback, INSPickerHandlerParameter, NSPickerHandler, TNSerFilter } from 'app/handlers/ns-picker/ns-picker.handler';
import { getDashboardInfoFromCurrentDashboard } from 'app/model/client-utility';
import { EDashboardIdentifier } from 'app/model/dashboard/dash-identifier';
import { TRapCurrentDashboardClientRoutes, mapCurrentDashboardClient, routePageToFunctionContextMap } from 'app/model/dashboard/db/dashboard-db-mapper';
import { IMapCurrentDashboardClient } from 'app/model/dashboard/db/dashboard-db-types';
import { routeList } from 'app/model/routes/route-constants';
import { Dictionary, invert } from 'lodash';
import { BotTransactionService } from '../bot-transaction.service';
import { CanonicalService } from '../canonical.service';
import { CustomEventsService } from '../custom-events.service';
import { ColmeiaDialogService, IColmeiadialogServiceParams } from '../dialog/dialog.service';
import { GenericNonSerializableService } from '../generic-ns.service';
import { KnowledgeBaseService } from '../knowledge-base.service';
import { RequestBuilderServices } from '../request-builder.services';
import { RoutingService } from '../routing.service';
import { ServerCommunicationService } from '../server-communication.service';
import { ServiceIslandService } from '../service-island.service';
import { SessionService } from '../session.service';
import { SnackMessageService } from '../snack-bar';
import { TagsService } from '../tags.service';
import { DashboardBotsService } from './dashboard-bots.service';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

type TQuicklyCreateNSPickerHandlerSelectedId = { selectedId: string };
type TQuicklyCreateNSPickerHandlerSelectedIds = { selectedIds: string[] };

type TQuicklyCreateNSPickerHandlerWithoutClientCallback<NsType extends ENonSerializableObjectType> = DefineReadableType<Partial<INSPickerHandlerParameter<NsType>>,
    {
        title?: string;
        nsType: NsType;

        idParent?: string;
        useDemandedTag: boolean;
        maxSelections?: number;
        match?: IListNonSerializablesMatch[];
    } & OldRequireOnlyOne<TQuicklyCreateNSPickerHandlerSelectedId & TQuicklyCreateNSPickerHandlerSelectedIds>
>

export type TQuicklyCreateNSPickerHandler<NsType extends ENonSerializableObjectType> = DefineReadableType<Partial<INSPickerHandlerParameter<NsType>>,
    TQuicklyCreateNSPickerHandlerWithoutClientCallback<NsType> & { clientCallback: INSPickerHandlerClientCallback<NsTypeToInterface[NsType]>; }
>;

export type TCurrentSelectedDashboard = TRapCurrentDashboardClientRoutes | Nullish;

@Injectable({
    providedIn: 'root'
})
export class DashBoardService implements OnDestroy {
    public tmpData: any;

    private _currentDashboard: TCurrentSelectedDashboard;
    private _currentDashboard$ = new Subject<TCurrentSelectedDashboard>();

    public get currentDashboard() {
        return this._currentDashboard;
    }
    public set currentDashboard(dashboardRoute: TCurrentSelectedDashboard) {
        this._currentDashboard = dashboardRoute;
        this._currentDashboard$.next(dashboardRoute);
    }

    public get currentDashboard$() {
        return this._currentDashboard$.asObservable();
    }

    private currentDashboarMenuItem: Serializable;
    public selectedTagsDictionary: Dictionary<IServerColmeiaTag[]> = {};
    private mapRouteToSearch: Map<string, string>;
    public genericNonSerializableService: GenericNonSerializableService;
    public mapIdSocialNetworkToScreenGroupToAllowedMenuIDs: Map<string, Map<string, string[]>> = new Map();

    private dynamicDialogComponent: {};
    private routerSubs: Subscription;

    constructor(
        private session: SessionService,
        private api: ServerCommunicationService,
        private rbs: RequestBuilderServices,
        private routeSvc: RoutingService,
        private botTransactionService: BotTransactionService,
        private canonicalService: CanonicalService,
        private knowledgeBaseService: KnowledgeBaseService,
        private serviceIslandService: ServiceIslandService,
        private tagsService: TagsService,
        private dashboardBotsService: DashboardBotsService,
        private snConfigSvc: SNConfigService,
        private snackSvc: SnackMessageService,
        private attendanceEvents: CustomEventsService,
        private dialogSvc: ColmeiaDialogService,
        private matDialogSvc: MatDialog,
        private overlay: Overlay,
        private injector: Injector,
        private routingSvc: RoutingService,
        private router: Router,
    ) {
        this.genericNonSerializableService = new GenericNonSerializableService(null, null, {
            api,
            rbs,
            routeSvc,
            session
        });

        this.routerSubs = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkRouteChange()
            }
        });
    }

    ngOnDestroy() {
        this.routerSubs.unsubscribe();
    }

    public async openCodeEditor(handler: CodeEditorDialogHandler) {
        const component = await import('app/components/dashboard/code-editor/code-editor-dialog/code-editor-dialog.component').then(response => response.CodeEditorDialogComponent);
        return this.dynamicDialog(
            component,
            handler,
            undefined,
            {
                panelClass: 'no-padding',
            }
        );
    }

    menuComponent?: DashboardMenuComponent;
    updateCurrentDashboardByRoute(): void {
        this.menuComponent?.updateCurrentDashboardByRoute();
    }

    public async openJSONDialog(content: {}) {
        return this.openCodeEditor(new CodeEditorDialogHandler({ content, language: 'json', clientCallback: {}, readonly: true }))
    }

    getBestUserFunctionContext(): EFunctionContext {
        return routePageToFunctionContextMap[this.currentDashboard];
    }

    public setDynamicDialogComponent(component: {}) {
        this.dynamicDialogComponent = component;
    }

    public async dynamicDialog<Component, Handler, T>(
        component: Component,
        handler?: Handler,
        setDialogRef?: (ref: MatDialogRef<any>) => void,
        params?: Omit<IColmeiadialogServiceParams<Component, Handler>, 'dataToComponent' | 'componentRef'>
    ): Promise<void> {
        if (!this.dynamicDialogComponent) {
            const { DynamicDialogComponent } = await import('app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component')
            this.setDynamicDialogComponent(DynamicDialogComponent)
            // this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)('You must set dynamic dialog component');
            // return;
        }

        const ref = this.dialogSvc.open({
            ...params,
            componentRef: this.dynamicDialogComponent as any,
            hideHeader: true,
            dataToComponent: {
                data: DynamicComponentHandler.factory({
                    component,
                    handler: (handler as unknown) as MainHandler,
                }),
            },
        })
        if (isValidFunction(setDialogRef)) setDialogRef(ref);
        return ref.afterClosed().toPromise();
    }

    public isValueAnAllowedNsTypeOnGoTo = createTypeGuard<keyof typeof DashBoardService.prototype.nsTypeToGenericNonSerializableService>((value: any) => value in this.nsTypeToGenericNonSerializableService)

    public nsTypeToGenericNonSerializableService = genericTypedSuggestions<{ [nsType in ENonSerializableObjectType]?: GenericNonSerializableService }>()({
        [ENonSerializableObjectType.contentGenerator]: this.botTransactionService,
        [ENonSerializableObjectType.canonical]: this.canonicalService,
        [ENonSerializableObjectType.knowledgeBase]: this.knowledgeBaseService,
        [ENonSerializableObjectType.serviceIsland]: this.serviceIslandService,
        [ENonSerializableObjectType.colmeiaTags]: this.tagsService,
        [ENonSerializableObjectType.bot]: this.dashboardBotsService,
    } as const)


    public getGenericNonSerializableService(): GenericNonSerializableService {
        return this.genericNonSerializableService;
    }

    public setSelectedTagsForDashboardRoute(selectedTags: IServerColmeiaTag[]) {
        this.selectedTagsDictionary[this.routeSvc.getCurrentPath()] = selectedTags;
    }


    public getSelectedTags(): IServerColmeiaTag[] {
        return this.selectedTagsDictionary[this.routeSvc.getCurrentPath()];
    }


    public get hasSearch(): boolean {
        return window.HAS_SEARCH;
    }

    public setSearch(search: string): void {
        this.mapRouteToSearch.set(this.routeSvc.getCurrentPath(), search);
        this.updateSearchStorage();
    }

    public updateSearchStorage = lodashDebounce(() => {
        sessionStorage.setItem('search', JSON.stringify([...this.mapRouteToSearch.entries()]))
    }, 200)

    public removeSearch(): void {
        this.mapRouteToSearch.delete(this.routeSvc.getCurrentPath());
        this.updateSearchStorage();
    }

    public mountSearchFromSession(): void {
        this.mapRouteToSearch = new Map(JSON.parse(sessionStorage.getItem('search') ?? '[]'))
    }

    public getSearch(): string {
        if (isInvalid(this.mapRouteToSearch)) this.mountSearchFromSession();
        return this.mapRouteToSearch.get(this.routeSvc.getCurrentPath())
    }

    public getDashboardRoutes(): string[] {
        const dashboardRoutes: TRapCurrentDashboardClientRoutes[] = [];

        for (let name in routeList.dashboard.children) {
            const pathItem = routeList.dashboard.children[name].path
            dashboardRoutes.push(pathItem)
        }

        return dashboardRoutes
    }

    public listenSetCurrentDashboarMenuItem: () => void;
    public waitSetCurrentDashboarMenuItem(): Promise<void> {
        return new Promise((resolve) => {
            this.listenSetCurrentDashboarMenuItem = () => resolve()
            setTimeout(this.listenSetCurrentDashboarMenuItem, 0);
        })
    }

    public setCurrentDashboarMenuItem(value: Serializable, menuComponent?: DashboardMenuComponent): void {
        this.currentDashboarMenuItem = value;
        if (isValidFunction(this.listenSetCurrentDashboarMenuItem)) {
            this.listenSetCurrentDashboarMenuItem();
        }
        if (isValidRef(menuComponent)) this.menuComponent = menuComponent;
    }


    public getCurrentDashboarMenuItem(): Serializable {
        return this.currentDashboarMenuItem;
    }

    public isDashboard(dashboard: EDashboardIdentifier) {
        return this.currentDashboard === dashboard;
    }

    public get defaultTag(): EDefaultTag {
        return this.slowGetDashboardInfo().defaultTag as EDefaultTag;
    }

    public get safeDefaultTag(): EDefaultTag {
        return this.slowGetDashboardInfo()?.defaultTag as EDefaultTag;
    }

    public slowGetDashboardInfoFromCurrentDashboard(currentDashboard: string): IMapCurrentDashboardClient {
        return getDashboardInfoFromCurrentDashboard(currentDashboard);
    }

    public slowGetDashboardInfo(): IMapCurrentDashboardClient {
        return this.slowGetDashboardInfoFromCurrentDashboard(this._currentDashboard);
    }

    public getIntegrationRequestType(): IntegrationRequestType {
        const result = (this.slowGetDashboardInfo().sharedRequests as Required<IMapCurrentDashboardSharedRequests>).integration as IntegrationRequestType;
        return result
    }

    public getSaveFormRequestType(): SaveFormRequestType {
        const result = (this.slowGetDashboardInfo().sharedRequests as Required<IMapCurrentDashboardSharedRequests>).saveForm as SaveFormRequestType;
        return result
    }


    public getFormRequestType(): FormRequestType {
        const result = (this.slowGetDashboardInfo().sharedRequests as Required<IMapCurrentDashboardSharedRequests>).form as FormRequestType;
        return result
    }

    public getTransformerRequestType(): TransformerRequestType {
        const result = (this.slowGetDashboardInfo().sharedRequests as Required<IMapCurrentDashboardSharedRequests>).transformer as TransformerRequestType;
        return result;
    }

    public getSaveTransformerRequestType(): SaveTransformerRequestType {
        const result = (this.slowGetDashboardInfo().sharedRequests as Required<IMapCurrentDashboardSharedRequests>).saveTransformer as SaveTransformerRequestType;
        return result;
    }

    public setTmpData(data) {
        this.tmpData = data;
    }

    public getTmpData() {
        const tmpData = this.tmpData
        this.tmpData = undefined;
        return tmpData;
    }

    getDefaultTags(): TTagAssignmentArray {
        return this.factoryTags(this.defaultTag);
    }

    factoryTags(idTag: EDefaultTag): TTagAssignmentArray {
        return [{
            clockTick: getClock(),
            idAvatar: this.session.getSelectedAvatarID(),
            idTag,
        }];
    }

    public getDefaultTaggable(): ITaggable {
        return {
            tags: this.getDefaultTags()
        }
    }

    async openNSPicker<Type extends ENonSerializableObjectType>(handler: NSPickerHandler<Type>) {
        const { NsPickerModalComponent } = await import('app/components/dashboard/ns-picker/modal/modal/ns-picker-modal.component');

        return this.matDialogSvc.open(NsPickerModalComponent, {
            data: {
                getParamsToChildComponent: () => handler,
            },
            minWidth: "320px",
            maxWidth: "50vw",
        });
    }

    public async pickNS<Type extends ENonSerializableObjectType>(nsType: Type, parameters?: Partial<INSPickerHandlerParameter<Type>>): Promise<NsTypeToInterface[Type] | undefined> {
        let ns: NsTypeToInterface[Type] | undefined;
        const picker = this.easyCreateNSPickerHandler({
            nsType,
            clientCallback: {
                onSaveNSCallback: async (nser) => {
                    ns = nser;
                },
            },
            useDemandedTag: false,
            selectedId: empty,
        },
            parameters
        );
        const ref = await this.openNSPicker(picker);
        await ref.afterClosed().toPromise();
        return ns;
    }

    public easyCreateNSPickerHandler<NsType extends ENonSerializableObjectType>(params: TQuicklyCreateNSPickerHandler<NsType>, extra?: Partial<INSPickerHandlerParameter<NsType>>): NSPickerHandler<NsType> {
        let nonSerializablesIds = [];

        if (useTypeGuardByInstructions<Pick<UnionToIntersection<TQuicklyCreateNSPickerHandlerSelectedId>, 'selectedId'>>({ selectedId: 'string' }, params))
            nonSerializablesIds = [params.selectedId]
                ;
        if (useTypeGuardByInstructions<Pick<UnionToIntersection<TQuicklyCreateNSPickerHandlerSelectedIds>, 'selectedIds'>>({ selectedIds: 'array' }, params))
            nonSerializablesIds = params.selectedIds
                ;

        const { title, nsType, clientCallback, idParent, useDemandedTag, maxSelections, match }: TQuicklyCreateNSPickerHandler<NsType> = params;
        return new NSPickerHandler<NsType>({
            title,
            nsType,
            nonSerializablesIds,
            clientCallback,
            idParent,
            demandedTag: useDemandedTag ? this.defaultTag : undefined,
            genericNonSerializableService: this.genericNonSerializableService,
            maxSelections,
            match,
            ...(isValidRef(extra) ? extra : {})
        });
    }

    public reset(): void {
        this.mapIdSocialNetworkToScreenGroupToAllowedMenuIDs = new Map();
    }

    public getFirstIdAngularRoute(idAngularRoute: string): string {
        return idAngularRoute.split('/')[0];
    }

    public async getRouteAccessInfo(dashboardRoute: string, idAngularRoute: string) {
        const allowedScreenGroupItems: Serializable[] = await this.getAllowedScreenGroupItems(dashboardRoute);
        const isAllowed: boolean = await this.isAllowedDashboardRoute(
            dashboardRoute, // conversational-commerce
            idAngularRoute, // catalog
            allowedScreenGroupItems,
        );

        return {
            isAllowed,
            allowedScreenGroupItems,
        }
    }

    public async isAllowedDashboardRoute(route: string, idAngularRoute: string, allowedScreenGroupItems?: Serializable[]): Promise<boolean> {
        allowedScreenGroupItems ??= await this.getAllowedScreenGroupItems(route);
        const allowedRouteItem = allowedScreenGroupItems.find((allowedItem: Serializable) => idAngularRoute === allowedItem.getAngularRouteID());
        const isAllowed: boolean = isValidRef(allowedRouteItem);
        return isAllowed;
    }

    public async getAllowedScreenGroupItems(route: string): Promise<Serializable[]> {
        const info: IMapCurrentDashboardClient = Object.values(mapCurrentDashboardClient).find((item: IMapCurrentDashboardClient) => item.route === route);
        const screenGroupName: EScreenGroups = info.screenGroup.name;
        const allowedIdMenus: string[] = await this.getAllowedMenuIDs(screenGroupName);
        const allowedScreenGroupItems: Serializable[] = Serializable.getVisualElementFromScreenGroup(screenGroupName)
            .filter((screenGroupItem: Serializable) => allowedIdMenus.includes(Serializable.slowGetIdMenu(screenGroupItem.getPrimaryID(), screenGroupName)))
            ;
        return allowedScreenGroupItems;
    }


    public async getAllowedMenuIDs(screenGroup: string): Promise<string[]> {
        if (!this.session.getSelectedAvatar()) {
            await this.session.getAvatarAsync();
        }
        if (this.snConfigSvc.shouldLoadAccess) {
            await this.snConfigSvc.loadAccess();
        }
        return this.getAllowedMenuIDsSync(screenGroup);
    }

    public getAllowedMenuIDsSync(screenGroup: string): string[] {
        if (!this.snConfigSvc.mapIdMenuInfo) return [];
        return [...this.snConfigSvc.mapIdMenuInfo.keys()].filter(idMenu => this.snConfigSvc.mapIdMenuInfo.get(idMenu).hasAccess && this.snConfigSvc.mapIdMenuInfo.get(idMenu).idMenuParent === screenGroup)
    }

    public getNewNSPicker(previousPicker: INonSerializableSelectHandler, clientCallback: INSPickerHandlerClientCallback): NSPickerHandler {
        const handler: NSPickerHandler = this.easyCreateNSPickerHandler({
            title: previousPicker.title,
            nsType: previousPicker.nsType,
            selectedId: previousPicker.selectedNS,
            clientCallback,
            idParent: previousPicker.idParent,
            useDemandedTag: false
        });

        return handler;
    }

    public createNSPicker({
        nsType,
        clientCallback,
        idParent,
        useDemandedTag,
        title,
        filter
    }: {
        nsType: ENonSerializableObjectType,
        clientCallback: INSPickerHandlerClientCallback,
        idParent?: string,
        useDemandedTag: boolean,
        title?: string,
        filter?: TNSerFilter
    }) {

        const nsPickerParameter: INSPickerHandlerParameter = {
            title,
            nsType,
            nonSerializablesIds: [],
            clientCallback,
            genericNonSerializableService: this.genericNonSerializableService,
            idParent,
            demandedTag: useDemandedTag ? this.defaultTag : undefined,
            filter
        };

        return new NSPickerHandler(nsPickerParameter);
    }

    /**
     * limpa o valor do dash atual caso acesse outra página
     */
    private checkRouteChange(): void {
        if (!this.isOnDashboard()) {
            this.currentDashboard = null;
        }
    }

    public isOnDashboard(): boolean {
        return this.routingSvc.getCurrentPath().split('/').indexOf(routeList.dashboard.path) !== -1;
    }
}
