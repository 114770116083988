import { ITransactionServer } from "../../../knowledge-base/bot-transaction/bot-transaction";
import { CrmHostNode } from "./crm-host-node";

export class CRMContentGeneratorHost extends CrmHostNode {
    constructor(contentGenerator: ITransactionServer) {
        super(contentGenerator)
    }

    create(contentGenerator: ITransactionServer): CRMContentGeneratorHost {
        return new CRMContentGeneratorHost(contentGenerator)
    }
}