import { typedClone } from '@colmeia/core/src/tools/utility';
import { Injectable } from '@angular/core';
import { IServerColmeiaTag, TColmeiaTagArray } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { EVarEditorEntityType } from 'app/handlers/var-editor.handler';

@Injectable({
    providedIn: 'root'
})
export class VariablesService {

    selectedVariableTags: IServerColmeiaTag[] = [];
    constructor() { }

    public getAssetAdderVariables() {
        return {
            [EVarEditorEntityType.NonSerializable]: this.selectedVariableTags.map(variable => ({ idProperty: variable.idNS, variable: undefined })),
        };
    }

    public getSelectedVariables(): IServerColmeiaTag[] {
        return typedClone(this.selectedVariableTags);
    }

    public setSelectedVariables(selectedVariableTags: IServerColmeiaTag[]) {
        this.selectedVariableTags = typedClone(selectedVariableTags);
    }
}
