import { TNserUID } from "@colmeia/core/src/core-constants/types";
import { IBasicNotificationPayload } from "@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model";

export enum ENotificationPayloadClientType {
    open = 'open',
    replace = 'replace'
}
export interface INotificationPayloadClientData {
    viewType: ENotificationPayloadClientType
    alreadyClosedByUser: boolean,
    photo: string,
    icon: string,
    linkToFollow: string,
    counter: number,
}
export interface INotificationPayloadClient extends IBasicNotificationPayload, INotificationPayloadClientData {
}
export type TNotificationId = TNserUID
