<h3>At a Glance</h3>

<ng-container *ngIf="isLoading; else dataLoaded">
  <mat-progress-spinner mode="indeterminate" diameter="30">
  </mat-progress-spinner>
</ng-container>

<ng-template #dataLoaded>
  <ng-container *ngIf="widgetEventConfigList.length; else noData">
    <ng-container *ngFor="let event of widgetEventConfigList; let i = index">
      <article class="event">
        <div>
          <h4>{{ getName(event.idCanonical) }}</h4>
          <p>{{ getCanonicalValue(event.idCanonical) }}</p>
        </div>
      
        <ng-container *ngIf="event.generateEvent">
          <button mat-stroked-button color="primary" (click)="dispatchEvent(event)">
            {{ event.buttonLabel }}
          </button>
        </ng-container>
      </article>

      <mat-divider *ngIf="i < widgetEventConfigList.length"></mat-divider>
    </ng-container>
  </ng-container>
  
  <ng-template #noData>
    <p>Nenhum evento encontrado</p>
  </ng-template>
</ng-template>