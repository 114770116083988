<div id="gp-members-container">
  <div *ngIf="loadingComponent; else participantSelector" class="loading__wrapper">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
  <ng-template #participantSelector>
    <app-participant-selector
    *ngIf="participantSelectorHandler"
    [participantSelectorHandler]="participantSelectorHandler">
    </app-participant-selector>
  </ng-template>
</div>

