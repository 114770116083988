import { Component, Inject, OnInit } from '@angular/core';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupService } from 'app/services/lookup.service';
import { isInvalid, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { IGetBotConversationResponse, TBotOrUserResponse } from '@colmeia/core/src/request-interfaces/lookup-generic';
import { IBotChatMessage, IGroupServiceParameter } from 'app/model/group-service.model';
import { EHexagonSizes, HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { DateService } from 'app/services/date.service';
import { IBotAnswerDSRegister } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp';
import { IBotLogContent } from '@colmeia/core/src/shared-business-rules/bot/bot-interfaces';
import { T360MediaArray } from '@colmeia/core/src/core-constants/bot';
import { MultimediaObject } from '@colmeia/core/src/multi-media/multi-media-object';
import { EMultimediaPreviewType, MultimediaPreviewHandler } from 'app/components/chat/message-container/message-instance/multimedia-preview/multimedia-preview.handler';
import { clientConstants } from 'app/model/constants/client.constants';
import { HandlerFactoryService } from 'app/handlers/handler-factory.service';

interface IBotChatMessageToBotConversationLog extends IBotAnswerDSRegister, IBotLogContent, IBotChatMessage, RootComponent<any> { }

export interface GroupServiceBotHistoryParameters {
    botThreadId?: string;
    groupServiceParameter: IGroupServiceParameter;
    onLoadBotHistory?(botHistory: Array<IBotChatMessage>): void;
    botHistory?: Array<IBotChatMessage>;
}

@Component({
    selector: 'group-service-bot-history',
    templateUrl: './group-service-bot-history.component.html',
    styleUrls: ['./group-service-bot-history.component.scss']
})
export class GroupServiceBotHistoryComponent extends RootComponent<'botHistory' | 'noBotHistory'> implements OnInit {
    public botHistory: Array<IBotChatMessage> | undefined;
    private botThreadId: string | undefined;
    private groupServiceParameter: IGroupServiceParameter;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataToComponent: GroupServiceBotHistoryParameters,
        private lookupSvc: LookupService,
        private dateSvc: DateService,
        private handlerFactorySvc: HandlerFactoryService,
    ) {
        super({
            botHistory: gTranslations.fragments.botHistory,
            noBotHistory: gTranslations.fragments.noBotHistory,
        });

        this.botThreadId = dataToComponent.botThreadId;
        this.groupServiceParameter = dataToComponent.groupServiceParameter;
        this.botHistory = dataToComponent.botHistory;
    }

    ngOnInit() {
        this.loadThreadInfo();
    }
    
    private async loadThreadInfo() {
        if (isValidRef(this.botHistory)) return
    
        if (isInvalid(this.botThreadId)) return
    
        let conversationResponse: IGetBotConversationResponse = await this
            .lookupSvc
            .getBotConversation(this.botThreadId)
    
        if (isInvalid(conversationResponse))
            return this.botHistory = []
    
        this.loadBotHistory(conversationResponse.conversationHistory);
    }

    private loadBotHistory(conversationContainer: TBotOrUserResponse[]) {
        const userName = this.groupServiceParameter.interaction.getParticipant().getAvatar().getName();

        const hexagonBot: HandlerHexagonon = this.buildHexagonHandlerForBot()
        const hexagonCustomer: HandlerHexagonon = this.buildHexagonHandlerForCustomer()

        const botHistory: IBotChatMessage[] = conversationContainer
            .map((conversation: TBotOrUserResponse) => {
                const { isBot } = conversation

                if (isBot) return {
                    ...conversation,
                    userName: 'Bot'
                }

                if (!userName) return {
                    ...conversation,
                    userName: `@${ this.groupServiceParameter.interaction.getParticipant().getName() }`
                }

                return {
                    ...conversation,
                    userName
                }
            })
            .map((conversation: IBotChatMessageToBotConversationLog) => {
                const { clockTick } = conversation

                if (!clockTick) return conversation

                const time = this.dateSvc.getBestDate(new Date(clockTick))

                return {
                    time,
                    ...conversation
                }

            })
            .map((conversation: IBotChatMessageToBotConversationLog) => {
                const { isBot } = conversation

                return {
                    ...conversation,
                    hexagonHandler: isBot ? hexagonBot : hexagonCustomer
                }
            })
            .map((conversation: IBotChatMessageToBotConversationLog) => {
                let multimediaHandler: MultimediaPreviewHandler;
                const { time, text } = conversation;

                if (!conversation.isBot) {
                    multimediaHandler = this.buildMultimediaPreviewHandlerFromMedias(conversation.medias, time, text)
                } else {
                    multimediaHandler = this.buildMultimediaPreviewHandlerFromMedias([conversation.media], time, text)
                }

                return {
                    ...conversation,
                    multimediaHandler
                }
            });

        this.botHistory = botHistory;
        this.dataToComponent.onLoadBotHistory?.(botHistory);
    }

    private buildHexagonHandlerForBot(): HandlerHexagonon {
        return HandlerHexagonon.newHandler({
            size: EHexagonSizes.xs,
            forceURL: './assets/icons/feature-icons/chatbot.png'
        });
    }

    private buildHexagonHandlerForCustomer(): HandlerHexagonon {
        return HandlerHexagonon.newHandler({
            size: EHexagonSizes.xs,
            serializable: this.groupServiceParameter.interaction.getParticipant().getAvatar()
        });
    }

    private buildMultimediaPreviewHandlerFromMedias(medias: T360MediaArray, time: string, title: string): MultimediaPreviewHandler {
        const mmObject = medias ? MultimediaObject.getNewMultimediaObjectFrom360(medias) : undefined;
        return this.buildMultimediaHandler(mmObject, time, title);
    }

    // private buildMultimediaPreviewHandlerFromIdMedia(idMedia: string, time: string, title: string): MultimediaPreviewHandler {
    //     const mmObject = MultimediaObject.getNewMultimediaObjectFromIdMedia(idMedia);
    //     return this.buildMultimediaHandler(mmObject, time, title);
    // }

    private buildMultimediaHandler(mmObject: MultimediaObject, time: string, title: string): MultimediaPreviewHandler {
        return this.handlerFactorySvc.newMultimediaPreview({
            type: EMultimediaPreviewType.All,
            canOpenMediaModal: true,
            maxNumberMultimediaToShow: clientConstants.multimedia.maxDisplayedMediaNumber,
            messageDate: time,
            messageTitle: title,
            mmObject,
            missingMediaNumber: null,
            msgContainerInstance: null,
            multimediaList: [],
            useThumbnails: false
        });
    }
}
