import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { deprecatedSuggestions, genericTypedSuggestions } from "../../../tools/type-utils";
import { IDeployedEmbeddedConfigs } from "../../deployed-services/deployed-services";


/* @TODO remover serializables repetidos
defaultConfig == default
*/

const deployedServiceScreenOptions1 = typedScreenOptions({
    idSerializable: EAllSerializable.DeployedServices,
    fields: {
        deployedServices: 1, // 'SNManagement/deployedServices' 1
        details: 2, // 'servicesDeploy' 19
        customService: 3, // 'servicesDeploy' 33
        activateService: 4, // 'servicesDeploy' 24
        generateRoutingKey: 5, // 'servicesDeploy' 25
        routingKey: 6, // 'servicesDeploy' 26
        availableChannels: 7, // 'servicesDeploy' 27
        welcomeText: 8, // 'servicesDeploy' 34
        copyRoute: 9, // 'servicesDeploy' 35
        copied: 10, // 'servicesDeploy' 36
        preferences: 11, // 'servicesDeploy' 37
        serviceName: 12, // 'servicesDeploy' 38
        add: 13, // 'SNManagement/deployedServices' 19
        editing: 14, // 'SNManagement/deployedServices' 23
        selectRoot: 15, // 'Bots' 36
        addException: 16, // 'servicesDeploy' 39
        selectAFile: 17, // 'campaignsAction' 21
        selectTheForm: 18, // 'SNManagement/integration' 28
        addBots: 19, // 'servicesDeploy' 40
        addRoutes: 20, // 'servicesDeploy' 42
        expireTimeInHours: 21, // 'servicesDeploy' 43
        serviceType: 22, // 'servicesDeploy' 20
        defaultConfig: 23, // 'servicesDeploy' 21
        group: 24, // 'servicesDeploy' 23
        save: 25, // 'servicesDeploy' 28
    } as const
})

const deployedServiceScreenOptions2 = typedScreenOptions({
    idSerializable: EAllSerializable.DeployedServicesTwo,
    fields: {
        default: 1, // 'servicesDeploy' 21
        active: 2, // 'servicesDeploy' 31
        macroPackages: 3, // 'SNManagement/macros' 19
        addFiles: 4, // 'servicesDeploy' 41
        loadServiceListError: 5, // 'SNManagement/deployedServices' 19
        warningNoChannelsTitle: 6, // Serviço sem canal
        warningNoChannels: 7, // O serviço está [b]sem canal selecionado[/b], deseja salvar?
    } as const
})

const deployedServiceDeployedEmbeddedConfigs = typedScreenOptions({
    idSerializable: EAllSerializable.DeployedEmbeddedConfigs,
    fields: genericTypedSuggestions<{ [key in (keyof IDeployedEmbeddedConfigs)]?: number }>()({
        sdkMetadataId: 1, // Metadado para Integração com Webchat
        botAvatar: 2, // Avatar do bot inválido
        initialMetadataId: 3, // Formulário preenchido pelo Cliente
    } as const)
})


export const deployedServiceTranslations = {
    ...getTranslationsFields(deployedServiceDeployedEmbeddedConfigs),
    ...getTranslationsFields(deployedServiceScreenOptions1),
    ...getTranslationsFields(deployedServiceScreenOptions2)
};

// //