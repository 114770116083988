import { EConnectionType } from '@colmeia/core/src/shared-business-rules/connections/endpoint-model';
import { MS } from '@colmeia/core/src/time/time-utl';
import { createOptions } from '@colmeia/core/src/tools/utility';
import { genericTypedSuggestions } from '../../tools/type-utils';
import { FindKeysWithValueOf, ValueOf } from '../../tools/utility-types';
import { TKBAssetArray } from '../bot/bot-action-model';
import { EAccuracy } from '../bot/bot-improvement';
import { ENonSerializableObjectType, INonSerializableHeader, INonSerializableIdentification, IdChunk, IdKB, IdVector, NonSerializable } from '../non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../non-serializable-id/non-serializable-types';
import { EMLLuisTrainResultType, IMLLuisApp, IMLLuisPredictIntent, IMLLuisPredictResult, IMLLuisUtterenceExample, IdIntent, IntentId } from './luis-core-interfaces';

export type TIKnowledgeDBServerArray = Array<IKnowledgeDBServer>;

export interface INLPActivityFilterData extends INLPActivityData {
    between: {
        begin: number;
        end: number;
    }
}

export interface INLPActivityData {
    accuracyBehaviour: EAccuracy;
    isPositive: boolean;
    hasFeedback: boolean;
    idBot: string;
    idTransaction: string;
    score: number;
    isMenuProc: boolean;
}

export interface IKnowledgeDB extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.knowledgeBase,
    /**
     * CLU Connection
     */
    idConnection?: IdDep<ENonSerializableObjectType.connection>;
    /**
     * @deprecated connectionType
     */
    connectionType?: EConnectionType;

    description?: string;
    mustBeTrained?: boolean;
    lastTrain?: {
        at: MS;
        took: MS;
        average: MS;
        status: EMLLuisTrainResultType | undefined;
    };
    // função que pode alterar os resultados dos scores das intenções para fins de customização
    idFunctionMut?: IdDep<ENonSerializableObjectType.userFunction>;
};



export interface IKBInfo {
    fileCount: number;
    lineCount: number;
};

export interface IKBRelation {
    idTransaction: string;
    idKB: string;
    intentId: string;
}

export enum EUtteranceExistsOption {
    No = 'no',
    Verbatim = 'verbatim',
    Nlp = 'nlp',
}

export interface IIntentByTextAux extends IBasicIntentData {
    chainedKb?: IChainedKBAux[]
}

export interface IIntentByTextResponse extends IMLLuisPredictIntent {
    chainedKb?: IChainedKB[]
}

export interface IChainedKBAux {
    IdKb: string;
    KbName: string;
    nestedResults: Promise<IMLLuisPredictResult[]> | IMLLuisPredictResult[];
}

export interface IChainedKB {
    IdKb: string;
    KbName: string;
    nestedResults: IMLLuisPredictIntent[];
}

export interface IBasicIntentData extends IMLLuisPredictIntent {
}

export interface IPrintExists {
    idUtterance: string;
    userSentence: string;
    intentId: string;
    intentName: string;
    exists: EUtteranceExistsOption;
    score: number;
}

export enum ECloudNaturalLanguageSyntaxLabel {
    UNKNOWN = 'UNKNOWN',
    ABBREV = 'ABBREV',
    ACOMP = 'ACOMP',
    ADVCL = 'ADVCL',
    ADVMOD = 'ADVMOD',
    AMOD = 'AMOD',
    APPOS = 'APPOS',
    ATTR = 'ATTR',
    AUX = 'AUX',
    AUXPASS = 'AUXPASS',
    CC = 'CC',
    CCOMP = 'CCOMP',
    CONJ = 'CONJ',
    CSUBJ = 'CSUBJ',
    CSUBJPASS = 'CSUBJPASS',
    DEP = 'DEP',
    DET = 'DET',
    DISCOURSE = 'DISCOURSE',
    DOBJ = 'DOBJ',
    EXPL = 'EXPL',
    GOESWITH = 'GOESWITH',
    IOBJ = 'IOBJ',
    MARK = 'MARK',
    MWE = 'MWE',
    MWV = 'MWV',
    NEG = 'NEG',
    NN = 'NN',
    NPADVMOD = 'NPADVMOD',
    NSUBJ = 'NSUBJ',
    NSUBJPASS = 'NSUBJPASS',
    NUM = 'NUM',
    NUMBER = 'NUMBER',
    P = 'P',
    PARATAXIS = 'PARATAXIS',
    PARTMOD = 'PARTMOD',
    PCOMP = 'PCOMP',
    POBJ = 'POBJ',
    POSS = 'POSS',
    POSTNEG = 'POSTNEG',
    PRECOMP = 'PRECOMP',
    PRECONJ = 'PRECONJ',
    PREDET = 'PREDET',
    PREF = 'PREF',
    PREP = 'PREP',
    PRONL = 'PRONL',
    PRT = 'PRT',
    PS = 'PS',
    QUANTMOD = 'QUANTMOD',
    RCMOD = 'RCMOD',
    RCMODREL = 'RCMODREL',
    RDROP = 'RDROP',
    REF = 'REF',
    REMNANT = 'REMNANT',
    REPARANDUM = 'REPARANDUM',
    ROOT = 'ROOT',
    SNUM = 'SNUM',
    SUFF = 'SUFF',
    TMOD = 'TMOD',
    TOPIC = 'TOPIC',
    VMOD = 'VMOD',
    VOCATIVE = 'VOCATIVE',
    XCOMP = 'XCOMP',
    SUFFIX = 'SUFFIX',
    TITLE = 'TITLE',
    ADVPHMOD = 'ADVPHMOD',
    AUXCAUS = 'AUXCAUS',
    AUXVV = 'AUXVV',
    DTMOD = 'DTMOD',
    FOREIGN = 'FOREIGN',
    KW = 'KW',
    LIST = 'LIST',
    NOMC = 'NOMC',
    NOMCSUBJ = 'NOMCSUBJ',
    NOMCSUBJPASS = 'NOMCSUBJPASS',
    NUMC = 'NUMC',
    COP = 'COP',
    DISLOCATED = 'DISLOCATED',
    ASP = 'ASP',
    GMOD = 'GMOD',
    GOBJ = 'GOBJ',
    INFMOD = 'INFMOD',
    MES = 'MES',
    NCOMP = 'NCOMP',
}

export interface INLPConfig {
    useLemma: boolean;
    useParseLabels: ECloudNaturalLanguageSyntaxLabel[];
}


export const syntaxLabelConfigOptions = createOptions(
    'required',
    'excluded',

    'forward',
    'configurable',
)



export const syntaxLabelConfigOptionsRequiredKeepOptions = genericTypedSuggestions<{ [key in ValueOf<typeof syntaxLabelConfigOptions>]?: true }>()({
    // Mandamos para o Luis, porém removemos da tela no client
    [syntaxLabelConfigOptions.excluded]: true,
    // Mandamos para o Luis e mostramos na tela
    [syntaxLabelConfigOptions.required]: true,
});

export const syntaxLabelConfigOptionsKeepOptions = genericTypedSuggestions<{ [key in ValueOf<typeof syntaxLabelConfigOptions>]?: true }>()({
    ...syntaxLabelConfigOptionsRequiredKeepOptions,
    [syntaxLabelConfigOptions.configurable]: true,
});

export interface ISyntaxLabelDBRequired {
    type: typeof syntaxLabelConfigOptions.required;
}
export interface ISyntaxLabelDBExcluded {
    type: typeof syntaxLabelConfigOptions.excluded;
}
export interface ISyntaxLabelDBConfigurable {
    type: typeof syntaxLabelConfigOptions.configurable;
}
export interface ISyntaxLabelDBForward {
    type: typeof syntaxLabelConfigOptions.forward;
    forwardTo: ECloudNaturalLanguageSyntaxLabel;
}


export type TSyntaxLabelDB =
    | ISyntaxLabelDBRequired
    | ISyntaxLabelDBExcluded
    | ISyntaxLabelDBConfigurable
    | ISyntaxLabelDBForward
    ;


export function syntaxLabelDBForwardGuard(sentence: TSyntaxLabelDB): sentence is ISyntaxLabelDBForward {
    return sentence.type === syntaxLabelConfigOptions.forward;
}


export const LUIS_NONE_INTENT: string = 'None';
export const LUIS_MIN_INTENT_UTTERANCES: number = 5;
export type TIntentsUtterancesHash = { [key: string]: IMLLuisUtterenceExample[] };


export const defaultNLPConfig = genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxLabel]: TSyntaxLabelDB }>()({
    [ECloudNaturalLanguageSyntaxLabel.UNKNOWN]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.ACOMP]: { type: syntaxLabelConfigOptions.configurable }, // Predicativo do sujeito ou objeto
    [ECloudNaturalLanguageSyntaxLabel.ADVMOD]: { type: syntaxLabelConfigOptions.configurable }, // Adjunto adverbial
    [ECloudNaturalLanguageSyntaxLabel.ADVCL]: { type: syntaxLabelConfigOptions.configurable }, // Oração subordinada adverbial
    [ECloudNaturalLanguageSyntaxLabel.ADVPHMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.AMOD]: { type: syntaxLabelConfigOptions.configurable }, // Adjunto adnominal
    [ECloudNaturalLanguageSyntaxLabel.APPOS]: { type: syntaxLabelConfigOptions.configurable }, // Aposto
    [ECloudNaturalLanguageSyntaxLabel.ATTR]: { type: syntaxLabelConfigOptions.configurable }, // Atributo
    [ECloudNaturalLanguageSyntaxLabel.AUX]: { type: syntaxLabelConfigOptions.configurable }, // Verbo auxiliar
    [ECloudNaturalLanguageSyntaxLabel.AUXPASS]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.AUX }, //
    [ECloudNaturalLanguageSyntaxLabel.CC]: { type: syntaxLabelConfigOptions.configurable }, // Conjunção
    [ECloudNaturalLanguageSyntaxLabel.CCOMP]: { type: syntaxLabelConfigOptions.configurable }, // Verbo
    [ECloudNaturalLanguageSyntaxLabel.CONJ]: { type: syntaxLabelConfigOptions.configurable }, // Termos adicionais no formato de lista
    [ECloudNaturalLanguageSyntaxLabel.CSUBJ]: { type: syntaxLabelConfigOptions.required }, // Sujeito
    [ECloudNaturalLanguageSyntaxLabel.CSUBJPASS]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.CSUBJ }, //
    [ECloudNaturalLanguageSyntaxLabel.DEP]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.DET]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.ATTR }, //
    [ECloudNaturalLanguageSyntaxLabel.DISCOURSE]: { type: syntaxLabelConfigOptions.configurable }, // Interjeição
    [ECloudNaturalLanguageSyntaxLabel.DOBJ]: { type: syntaxLabelConfigOptions.required }, //
    [ECloudNaturalLanguageSyntaxLabel.EXPL]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.GOESWITH]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.IOBJ]: { type: syntaxLabelConfigOptions.required }, //
    [ECloudNaturalLanguageSyntaxLabel.MARK]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.CC },  //
    [ECloudNaturalLanguageSyntaxLabel.MWE]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.PREP }, //
    [ECloudNaturalLanguageSyntaxLabel.MWV]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NEG]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.ADVMOD }, //
    [ECloudNaturalLanguageSyntaxLabel.NN]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.AMOD }, // * Mapear para AMOD
    [ECloudNaturalLanguageSyntaxLabel.NPADVMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NSUBJ]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.CSUBJ }, //
    [ECloudNaturalLanguageSyntaxLabel.NSUBJPASS]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.CSUBJ }, //
    [ECloudNaturalLanguageSyntaxLabel.NUM]: { type: syntaxLabelConfigOptions.configurable }, // Numeral
    [ECloudNaturalLanguageSyntaxLabel.NUMBER]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.NUM }, // * Mapear para NUM
    [ECloudNaturalLanguageSyntaxLabel.P]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PARATAXIS]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PARTMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PCOMP]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.POBJ]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.ADVMOD }, //
    [ECloudNaturalLanguageSyntaxLabel.POSS]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.PREF }, //
    [ECloudNaturalLanguageSyntaxLabel.POSTNEG]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PRECOMP]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PRECONJ]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PREDET]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PREF]: { type: syntaxLabelConfigOptions.configurable }, // Pronome
    [ECloudNaturalLanguageSyntaxLabel.PRONL]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PRT]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.PS]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.QUANTMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.RCMOD]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.CCOMP }, // * Mapear para CCOMP
    [ECloudNaturalLanguageSyntaxLabel.RCMODREL]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.RDROP]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.REF]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.REMNANT]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.REPARANDUM]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.ROOT]: { type: syntaxLabelConfigOptions.required }, //
    [ECloudNaturalLanguageSyntaxLabel.SNUM]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.SUFF]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.TMOD]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.ADVMOD }, // * Mapear para ADVMOD
    [ECloudNaturalLanguageSyntaxLabel.TOPIC]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.VMOD]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.CCOMP }, // * Mapear para CCOMP
    [ECloudNaturalLanguageSyntaxLabel.VOCATIVE]: { type: syntaxLabelConfigOptions.configurable }, // Vocativo
    [ECloudNaturalLanguageSyntaxLabel.XCOMP]: { type: syntaxLabelConfigOptions.forward, forwardTo: ECloudNaturalLanguageSyntaxLabel.ROOT }, // * Mapear para ROOT
    [ECloudNaturalLanguageSyntaxLabel.SUFFIX]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.TITLE]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.AUXCAUS]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.AUXVV]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.DTMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.FOREIGN]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.KW]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.LIST]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NOMC]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NOMCSUBJ]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NOMCSUBJPASS]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NUMC]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.COP]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.DISLOCATED]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.ASP]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.GMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.GOBJ]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.INFMOD]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.MES]: { type: syntaxLabelConfigOptions.excluded }, //
    [ECloudNaturalLanguageSyntaxLabel.NCOMP]: { type: syntaxLabelConfigOptions.excluded }, //

    [ECloudNaturalLanguageSyntaxLabel.ABBREV]: { type: syntaxLabelConfigOptions.configurable }, // rechecar
    [ECloudNaturalLanguageSyntaxLabel.PREP]: { type: syntaxLabelConfigOptions.configurable }, // rechecar
}); // as const

// * Ignorar = Excluir
// + = Não deixar arrancar

export type TCloudNaturalLanguageSyntaxLabelForwarded = FindKeysWithValueOf<typeof defaultNLPConfig, { type: Exclude<ValueOf<typeof syntaxLabelConfigOptions>, typeof syntaxLabelConfigOptions.forward> }>;
export type TFinalSyntaxLabel = TCloudNaturalLanguageSyntaxLabelForwarded;

export const configurableLabels: ECloudNaturalLanguageSyntaxLabel[] = Object.keys(defaultNLPConfig).
    filter(label => defaultNLPConfig[label].type === syntaxLabelConfigOptions.configurable) as ECloudNaturalLanguageSyntaxLabel[];


export function getFinalSyntaxLabelForwarded(previousLabel: ECloudNaturalLanguageSyntaxLabel): TCloudNaturalLanguageSyntaxLabelForwarded {
    const forwardedLabel: TCloudNaturalLanguageSyntaxLabelForwarded = (defaultNLPConfig[previousLabel].type === syntaxLabelConfigOptions.forward
        ? (defaultNLPConfig[previousLabel] as ISyntaxLabelDBForward).forwardTo
        : previousLabel) as TCloudNaturalLanguageSyntaxLabelForwarded
        ;
    return forwardedLabel;
}

export function getFinalSyntaxLabel(previousLabel: ECloudNaturalLanguageSyntaxLabel): TFinalSyntaxLabel {
    const forwardedLabel = getFinalSyntaxLabelForwarded(previousLabel);
    if (!syntaxLabelConfigOptionsKeepOptions[forwardedLabel]) return;
    return forwardedLabel as TFinalSyntaxLabel;
}

// parseLabel => analise sintatica
// token => original
// lemma =>
// partOfSpeech => analise morfologica

export function initNLPConfig() {
    return genericTypedSuggestions<INLPConfig>()({
        useLemma: true,
        useParseLabels: configurableLabels,
    });
}


export interface IKnowledgeDBServer extends NonSerializable<ENonSerializableObjectType.knowledgeBase>, IKnowledgeDB {
    app: IMLLuisApp;
    ident: INonSerializableIdentification;
    nlpConfig: INLPConfig;
}


export type IIntentAsset = {
    [intentId in IdIntent]: TKBAssetArray;
}

// LUIS ML Entity Result

export interface IIntentCount {
    intentName: string;
    intentId: string;
}


export interface ICloudNaturalLanguageEntity {
    type: ECloudNaturalLanguageEntityType;
    content: string;
}

export interface ICloudNaturalLanguageSyntax {
    token: string;
    parseLabel: TCloudNaturalLanguageSyntaxLabelForwarded;
    lemma: string;

}

export interface ICloudNaturalLanguageSyntaxProcessed extends ICloudNaturalLanguageSyntax {
    finalToken: string;
    shouldRemove: boolean;
}


export interface ICloudNaturalLanguageSyntaxWithPartOfSpeech extends ICloudNaturalLanguageSyntax {
    partOfSpeech: ECloudNaturalLanguageSyntaxTag;
}


export enum ECloudNaturalLanguageEntityType {
    UNKNOWN = 'UNKNOWN',
    PERSON = 'PERSON',
    LOCATION = 'LOCATION',
    ORGANIZATION = 'ORGANIZATION',
    EVENT = 'EVENT',
    WORK_OF_ART = 'WORK_OF_ART',
    CONSUMER_GOOD = 'CONSUMER_GOOD',
    OTHER = 'OTHER',
    PHONE_NUMBER = 'PHONE_NUMBER',
    ADDRESS = 'ADDRESS',
    DATE = 'DATE',
    NUMBER = 'NUMBER',
    PRICE = 'PRICE',
}


export enum ECloudNaturalLanguageSyntaxTag {
    UNKNOWN = 'UNKNOWN',
    ADJ = 'ADJ',
    ADP = 'ADP',
    ADV = 'ADV',
    CONJ = 'CONJ',
    DET = 'DET',
    NOUN = 'NOUN',
    NUM = 'NUM',
    PRON = 'PRON',
    PRT = 'PRT',
    PUNCT = 'PUNCT',
    VERB = 'VERB',
    X = 'X',
    AFFIX = 'AFFIX',
}



export enum ECloudNaturalLanguageAnalysisOptionType {
    TYPE_UNSPECIFIED = 'TYPE_UNSPECIFIED',
    PLAIN_TEXT = 'PLAIN_TEXT',
    HTML = 'HTML',
}

export enum ECloudNaturalLanguageAnalysisLangugage {
    PORTUGUESE = 'pt',
    ENGLISH = 'en',
}


export interface ICloudNaturalLanguageAnalysisOptions {
    type: ECloudNaturalLanguageAnalysisOptionType
    language: ECloudNaturalLanguageAnalysisLangugage
}


export interface GenerativeKB {
    id: string;
    idKB: IdKB;
    idIntent: IntentId;
    /**
     * Embedding keyword
     */
    vector: number[];
}

