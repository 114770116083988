import { getUniqueStringID, isInvalidString } from "../../../tools/utility";
import { TReferencedObject } from "../../graph-transaction/toolbar/config-toolbar.types";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { IGraphElementRenderData } from "./diagram.interfaces";
import { GraphElement } from "./graph-element";
import { GraphEventListener } from './graph-element-listener';
import { TEventRegistryArray } from "./graph-element-listener.types";
import { IBasicElementJSON } from "./graph-interfaces";
import { EGraphElementType, TGraphPropertyArray, TGraphPropertyData, TRenderData } from "./graph-types";
import { IHostObjectInterface } from "./host-object";


export interface ISimpleElementCreate {
    hostObject: IHostObjectInterface, // 
    renderData?: TRenderData,
}

export interface IBasicElementCreate extends ISimpleElementCreate {
    events?: TEventRegistryArray; // 
}


export interface IInstatiateDragableComponent {
    isSelectionableOnly: boolean;
    selectorCriteria?: ENonSerializableObjectType;
    rootNode: GraphElement;
    isAlreadyExist: boolean;
    // upserterNonSerializableDialog: 
}
export type TBasicElementArray = BasicElement[];
export type TBasicElementId = string
export type TBasicElementIdArray = string[]
export type BasicElementAny = BasicElement;

export class BasicElement extends GraphEventListener {
    private state: IBasicElementJSON;
    private object: IHostObjectInterface;

    protected get elementType(): EGraphElementType { return this.state.elementType }
    protected set elementType(value: EGraphElementType) { this.state.elementType = value }

    protected get renderData(): IGraphElementRenderData { return this.state.renderData }
    protected set renderData(value: IGraphElementRenderData) { this.state.renderData = value }

    constructor(private name: string, json: IBasicElementJSON, hostObject: IHostObjectInterface) {
        super([]);
        if (isInvalidString(json.idGraphElement)) {
            json.idGraphElement = getUniqueStringID();
        }
        this.setHostObject(hostObject);
        this.state = json;
    }

    public getHostedID(): string { return this.object.getHostedID() }

    public getName(): string {
        return this.name || this.getHostedName();
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getGraphElementID(): string {
        return this.state.idGraphElement;
    }

    public isRoot(): boolean { return this.state.elementType == EGraphElementType.root }
    public isChildNode(): boolean { return this.state.elementType == EGraphElementType.node }
    public isNode(): boolean { return this.isRoot() || this.isChildNode() }
    public isConnection(): boolean { return this.state.elementType == EGraphElementType.predicate }


    public isElementType(elementType: EGraphElementType): boolean { return this.state.elementType === elementType; };
    public isSameType(element: BasicElement): boolean { return this.isElementType(element.getElementType()) };

    public getElementType(): EGraphElementType { return this.elementType; };

    public isSameGraphElement(graph: BasicElement): boolean { return this.state.idGraphElement === graph.getGraphElementID() }

    public isSameHostedObject(element: BasicElement): boolean { return this.object.getHostedID() == element.object.getHostedID() };
    public isSameObjectType(type: TReferencedObject): boolean { return type === this.object.getHostedType() }

    public own() { return this; };
    public getHostObject(): IHostObjectInterface { return this.object; };
    public getHostedName(): string { return this.object.getHostedName(); };
    public getHostedObject<T extends Object>(): T { return <T>this.object.getHostedObject() };
    public getHostedValue(): TGraphPropertyData { return this.object.getHostedValue(); };
    public getProperties(): TGraphPropertyArray { return this.object.getPropertyArray(); };

    public setHostObject(object: IHostObjectInterface) {
        this.object = object;
    }

    public getRenderData(): IGraphElementRenderData { return this.renderData as IGraphElementRenderData; };
    public setRenderData(renderData: IGraphElementRenderData): void {
        this.renderData = renderData;
    }

    public toJSON(): IBasicElementJSON {
        return {
            ...this.state,
            name: this.name,
            idHostedObject: this.getHostedID()
        };
    }

    public getHostedType(): TReferencedObject {
        return <TReferencedObject>this.object.getHostedType()
    }

    protected rehydrate(json: IBasicElementJSON) {

    }
};