<section class="text-icon-white-box" [ngClass]="backgroundColor">
  <hexagon [hexagonHandler]="hexagonHandler" *ngIf="hexagonHandler">
  </hexagon>

  <section class="text" *ngIf="text" [ngClass]="{
    'big': bigText
  }">
    {{ text }}
  </section>

  <h4 *ngIf="title">{{ title }}</h4>
</section>