import { EAllSerializable, getTranslationsFields } from "../../const-text/all-serializables";
import { IBasicElementJSON } from "../../graph/essential/graph-interfaces";
import { typedScreenOptions } from "../../../tools/utility";
import { deprecatedSuggestions, genericTypedSuggestions } from "../../../tools/type-utils";



export const attNSerErrorFields = typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceErrors,
    fields: {
        idServiceGroup: 1,
        idISlandCommunicationGroup: 2,
        islandType: 3,
        schemaId: 4,
        missingCalendar: 5, // Selecione um Calendário de atendimento
        missingTimeRestrictionMessage: 6, // Insira uma mensagem quando ilhas indisponíveis
        missingEnqueuedMessage: 7, // Insira uma mensagem quando enviado para fila
        missingCalendarTimezone: 8, // Selecione um fuso horário para o calendário
        missingEvents: 8, // Selecione os eventos de atendimento
        invalidNamedAccountConfig: 9 // Configuração inválida de conta nomeada
    }
} as const);

export const servicePackErrors = typedScreenOptions({
    idSerializable: EAllSerializable.ServicePackErrors,
    fields: {
        macros: 1,
        serviceGroups: 2,
        variableTags: 3,
        metadatas: 4,
        clientRegisterMetadatas: 5,
        transferableIslands: 6,
        finalizationMetadata: 7,
        allowedActiveChannel: 8,
    }
} as const);

export const commNSerErrorFields = typedScreenOptions({
    idSerializable: EAllSerializable.CommErrors,
    fields: {
        insertPhoneNumber: 1,
        invalidPhoneNumber: 2,
        insertKey: 3,
        insertToken: 4,
        insertDomain: 5,
        insertScenario: 6,
        insertUsername: 7,
        insertPassword: 8,
        insertEmail: 9,
        insertPageID: 10,
        service: 11,
        templateName: 12,
        templateTemplate: 13,
    }
} as const);
export const deployedServicesErrors = typedScreenOptions({
    idSerializable: EAllSerializable.DeployedServicesErrors,
    fields: genericTypedSuggestions<{ [key in (string)]: number }>()({
        serviceType: 1, // Insira uma tipo de serviço válido
        config: 2, // Insira uma configuração válida
        isActive: 3,
        comm: 4,
        idWhatsAppServiceRouting: 5,
        welcomeText: 6,
        idBotFile: 7, // Insira um bot de um banco de dados válido
        idBotRote: 8,
        sdkMetadataId: 9, // Metadado para Integração com Webchat
        botAvatar: 10, // Avatar do bot inválido
        initialMetadataId: 11, // Formulário preenchido pelo Cliente
        allowedDomains: 12,
        userChatContainerGroup: 13, // Sem grupo de container para conversações
        customerFormulary: 14, // Selecione um formulário para o WebChat
    } as const)
} as const);



export const accessRoleErrors = typedScreenOptions({
    idSerializable: EAllSerializable.AccessRoleErrors,
    fields: {
        idGroup: 1, // Escolha um grupo
        accessType: 2, // Selecione o tipo de regra
        controledMenus: 3, // Escolha um menu de acesso
    }
} as const);

export const socialNetworkErrors = typedScreenOptions({
    idSerializable: EAllSerializable.SocialNetworkErrors,
    fields: {
        invalidDomain: 1, // Domínio inválido
    }
} as const);

export const botTransactionErrors = typedScreenOptions({
    idSerializable: EAllSerializable.BotTransactionErrors,
    fields: {
        selectMetadata: 1,
        selectRoute: 2,
        selectKnowledgeBase: 3,
        insertConfirmMessage: 4,
        smallConfirmMessage: 5,
        insertIgnoreMessage: 6,
        smallIgnoreMessage: 7,
        fillAccuracy: 8, // É necessário inserir um evento do tipo acurácia
        emptySolicGroup: 9, // Selecione um grupo na configuração de disparo da pendência
        emptySolicIsland: 10, // Selecione uma ilha na configuração de disparo da pendência
        emptySolicAgent: 11, // Selecione um agente na configuração de disparo da pendência
        emptySolicDestType: 12, // Selecione um tipo de destinatário na configuração de disparo da pendência
        emptySolicAction: 13, // Adicione uma ação a configuração de disparo da pendência
        invalidWppFlows: 14, // Configuração de WhatsApp Flows inválida
        missingLLMProvider: 15, // Selecione um provedor de Large Language Model (LLM)
        invalidFlowAreaField: 16, // Nenhum componente do Flow está associado ao formulário. Associe ao menos um campo ou desabilite o Flow
        invalidMultipleChoiceComponent: 17 // Configure as opções de múltipla escolha de todos os componentes de WhatsApp Flows
    }
} as const);


export const attendanceEventsErrors = typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceEventsErrors,
    fields: {
        missingEvent: 1, // Preencha todos os eventos
    }
} as const);


export const serviceIslandErrors = typedScreenOptions({
    idSerializable: EAllSerializable.ServiceIslandErrors,
    fields: {
        maximumLoadGreaterThanUnit: 1, // Carga máxima não pode ser maior do que a unitária
        namedAccount: 2, // Contas nomeadas inválidas
        invalidWorkLoadSettings: 3, // Configuração inválida da carga de trabalho
        invalidServicePack: 4, // Selecione o pacote de recursos do atendente
    }
} as const);


export const serviceIslandErrorsTranslations = getTranslationsFields(serviceIslandErrors);

export const botErrors = typedScreenOptions({
    idSerializable: EAllSerializable.BotErrors,
    fields: {
        content: 1, // Preencha o texto para a exibição
        emptyWhatsappMediaHeader: 2, // Selecione um arquivo para o Whatsapp header
        emptyWhatsappTextHeader: 3, // O texto do Whatsapp header não pode ser vazio
        emptyWhatsappFooter: 4, // O texto do Whatsapp footer não pode ser vazio
        invalidDocumentType: 5, // O documento escolhido possui um tipo inválido
        invalidVideoType: 6, // O vídeo escolhido possui um tipo inválido
        invalidImageType: 7 // A imagem escolhida possui um tipo inválido
    }
} as const);

export const eventErrors = typedScreenOptions({
    idSerializable: EAllSerializable.EventErrors,
    fields: {
        insertText: 1,
        insertMinutesExpire: 2,
        insertAttempts: 3,
        insertYesQuestion: 4,
        smallYesQuestion: 5,
        insertNoQuestion: 6,
        smallNoQuestion: 7
    }
} as const);

export const assetErros = typedScreenOptions({
    idSerializable: EAllSerializable.AssetErros,
    fields: {
        insertMessage: 1,
        smallMessage: 2,
        insertMedia: 3,
        insertRoute: 4
    }
} as const);

export const actionsErrors = typedScreenOptions({
    idSerializable: EAllSerializable.ActionsErrors,
    fields: {
        dataIncorrect: 1
    }
} as const);


export const graphElementErrors = typedScreenOptions({
    idSerializable: EAllSerializable.GraphElement,
    fields: deprecatedSuggestions<Record<keyof IBasicElementJSON, number>>()({
        elementType: 1, // Escolha um tipo de elemento não preenchido
        properties: 2, // Insira as propriedados do elemento não existentes
        renderData: 3, // Dados de renderização não existentes
        idGraphElement: 4,
        idHostedObject: 5,
        name: 6,
    }).ok,
});

export const campaignErrors = typedScreenOptions({
    idSerializable: EAllSerializable.Campaign,
    fields: deprecatedSuggestions<Record<string, number>>()({
        noTemplateOnWhatsAppChannel: 1, // Sem template no canal whatsapp
        noActionType: 2, // Sem o tipo da ação
        noActionName: 3, // Sem nome da ação
        noIdSchemma: 4, // Sem o formulário
        channelIsAlreadyBeingDeleted: 5, // O canal já está sendo deletado
        noTemplateOnEmailChannel: 6, // Sem template no canal email
    }).ok,
});

export const campaignErrorsTranslations = getTranslationsFields(campaignErrors)

export const corporateSearchErrors = typedScreenOptions({
    idSerializable: EAllSerializable.CorporateSearchErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        emptyMetadata: 1, // Metadado não selecionado
        emptyDatabase: 2, // Banco de dados não selecionado
        emptyReturnField: 3, // Campo de retorono não selecionado
        invalidTitle: 4, // Configuração de título inválida
        invalidSuccessMessage: 5, // Configuração de messagem "Sucesso na busca" inválida
        invalidEmptyResultMessage: 6, // Configuração de messagem "Sem resultados" inválida
        searchFieldsInvalid: 7, // Configuração de busca inválida
        sortByInvalid: 8, // Configuração de ordenação inválida
        displayFieldsInvalid: 9, // Configuração de exibição inválida
    }).ok,
});



export const smartFlowRTFParamsErrors = typedScreenOptions({
    idSerializable: EAllSerializable.SmartflowRTFParamErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        emptyMetadata: 1, // Configuração incorreta do metadado.
        emptyAnswerResponse: 2, // Resposta não salva.
        invalidConditions: 3, // Alguma condição não está configurada corretamente.
    }).ok,
});

export const attendantServicePackErrors = typedScreenOptions({
    idSerializable: EAllSerializable.AttendantServicePackErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        invalidMacros: 1, // Configuração de macros inválida
        emptyFinalizationFrom: 2, // Selecione um formulário de finalização
        emptyDatabaseId: 3, // Selecione um banco de dados em seu Registro de Lista Dinâmica
    }).ok,
});

export const attendanceCopilotErrors = typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceCopilotErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        invalidDeployedServiceId: 1, // Serviço não selecionado
        invalidCopilotContext: 2, // Selecione o contexto do copilot
    }).ok,
});

export const userFunctionErrors = typedScreenOptions({
    idSerializable: EAllSerializable.UserFunctionErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        invalidType: 1, // Escolha o tipo da função
        invalidCustomCode: 2, // Código da função inválida
        invalidGlobalType: 3, // Escolha qual função global será executada
    }).ok,
});

export const configurationSetErrors = typedScreenOptions({
    idSerializable: EAllSerializable.ConfigurationSetErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        invalidGroup: 1, // Grupo válido
        invalidConfigType: 2, // Tipo inválido
        invalidTagSet: 3, // Tags vazias
        ivalidAllowCS: 4, // Configuração de Buscas inválida
    }).ok,
});

export const agentFileErrors = typedScreenOptions({
    idSerializable: EAllSerializable.AgentFileErrors,
    fields: deprecatedSuggestions<Record<string, number>>()({
        invalidMaxWeight: 1, // Digite uma carga máxima
        invalidIdAvatarAgent: 2, // Selecione uma pessoa
    }).ok,
});

export const wabaErrors = typedScreenOptions({
    idSerializable: EAllSerializable.Waba,
    fields: deprecatedSuggestions<Record<string, number>>()({
        invalidWabaId: 2,
    }).ok,
})
