import { Interaction } from '../interaction/interaction';
import { IPanable, Pane} from '../business/pane';
import { InteractionType } from '../interaction/interaction-type';
import { GroupSecurityDomain } from './group-security-domain';
import { Participant } from '../business/participant';
import {constant, TGlobalUID} from '../business/constant';
import {ISecurityItemJSON} from '../comm-interfaces/business-interfaces';


export class SecurityItem implements IPanable {
    private groupSecurityDomain: GroupSecurityDomain;
    private enable: boolean;
    private displayable: boolean;
    
    public toogle(): void {this.enable = !this.enable}
    public enableSecItem(): void {this.enable = true}
    public disableSecItem(): void {this.enable = false}
    public isSelected(): boolean {return this.enable}
    public setSelected(selection: boolean): void {this.enable = selection}

    public is(idSecurityDomain: TGlobalUID): boolean {
        return this.groupSecurityDomain.is(idSecurityDomain);
    }

    public setDisplayable(isDisplayable: boolean): void  {this.displayable = isDisplayable;}
    public isDisplayable() : boolean {return this.displayable;}
    
    public getPaneableID(): TGlobalUID {return this.groupSecurityDomain.getGroupSecurityDomainID()}
    public getPane(): Pane {return this.getGroupSecurityDomain().getPane()}

    private constructor(groupSecurityDomain: GroupSecurityDomain) {
        this.groupSecurityDomain = groupSecurityDomain;
        this.enable = false;
    }

    public getGroupSecurityDomain(): GroupSecurityDomain {
        return this.groupSecurityDomain;
    }

    public getName(): string {
        return this.groupSecurityDomain.getName();
    }

    public getDescription(): string {
        return this.groupSecurityDomain.getDescription();
    }

    public getIDentifiedScreenGroup(param: string): string {
        return this.groupSecurityDomain.getScreenGroupWithIdentifierOrAny(param);
    }

    public getSecurityDomainPrimaryID(): TGlobalUID {
        return this.groupSecurityDomain.getPrimaryID();
    }

    public toJSON(): ISecurityItemJSON {
        let json: ISecurityItemJSON = {
            idSecurityDomain: this.groupSecurityDomain.getGroupSecurityDomainID()
        };
        return json;
    }

    public rehydrate(json: ISecurityItemJSON): void {
        this.enable = true;
        this.groupSecurityDomain = GroupSecurityDomain.staticFactory(json.idSecurityDomain);
    }

    public static getNewSecurityItem(idDomain: TGlobalUID): SecurityItem {
        return new SecurityItem(GroupSecurityDomain.staticFactory(idDomain));
    }

    public static factoryMessage(json: ISecurityItemJSON): SecurityItem {
        let groupSecurityItem: SecurityItem = Object.create(SecurityItem.prototype);
        groupSecurityItem.rehydrate(json);
        return groupSecurityItem;
    }
}
