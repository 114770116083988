<div class="form-row">
  <input type="text" placeholder="{{ translations.price }}" [(ngModel)]="sellingPrice" class="input-cc">
</div>
<div class="form-row">
  <select [(ngModel)]="sellingInstallments" class="input-cc">
    <option *ngIf="isValidInstallmentOption(1)" value="1">1x</option>
    <option *ngIf="isValidInstallmentOption(2)" value="2">2x</option>
    <option *ngIf="isValidInstallmentOption(3)" value="3">3x</option>
    <option *ngIf="isValidInstallmentOption(4)" value="4">4x</option>
    <option *ngIf="isValidInstallmentOption(5)" value="5">5x</option>
    <option *ngIf="isValidInstallmentOption(6)" value="6">6x</option>
    <option *ngIf="isValidInstallmentOption(7)" value="7">7x</option>
    <option *ngIf="isValidInstallmentOption(8)" value="8">8x</option>
    <option *ngIf="isValidInstallmentOption(9)" value="9">9x</option>
    <option *ngIf="isValidInstallmentOption(10)" value="10">10x</option>
    <option *ngIf="isValidInstallmentOption(11)" value="11">11x</option>
    <option *ngIf="isValidInstallmentOption(12)" value="12">12x</option>
  </select>
</div>
<div class="form-row">
  <input type="text" placeholder="{{ translations.amount }}" [(ngModel)]="sellingSku" class="input-cc">
</div>
<div class="form-row">
  <cm-date-picker [handler]="datePickerHandler"></cm-date-picker>
</div>
<br>
<hr>

<div *ngIf="sellingInteraction">
  <app-interaction-previewer [handler]="getInteractionPreviewerHandler()">
  </app-interaction-previewer>
</div>

<br>

<button (click)="configure()" class="add-btn">
  {{ translations.configureAsSell }}
</button>
<button mat-dialog-close #closeBtn style="display: none;">{{ translations.close }}</button>
