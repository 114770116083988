import { EUnitTypeID } from "../../../business/constant.enums";
import { typedScreenOptions, typedFields } from "../../../tools/utility";
import { Meta } from "../../catalog/meta-interface";
import { getTranslationsFields, EAllSerializable } from "../all-serializables";

export const visibilityEnumTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.CatalogVisibility,
    fields: typedFields<Meta.Catalog.Visibility>()({
        [Meta.Catalog.Visibility.Published]: 1, // Publicado
        [Meta.Catalog.Visibility.Staging]: 2, // Em criação
    })
}));



export const catalogTranslations = {
    ...visibilityEnumTranslations
};