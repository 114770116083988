import { IToolbarElement } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { IComponentParameter } from "app/model/component-comm/basic";
import { DiagramDialogComponentHandler } from "../diagram-editor/diagram-editor.types";


export interface IBpmGraphToolbarElementSelectorHandler extends IComponentParameter {
    title: string;
    toolbarElements: IToolbarElement[];
    clientCallback: undefined
}

export class BpmGraphToolbarElementSelectorHandler extends DiagramDialogComponentHandler<IToolbarElement>  {
    constructor(parameters: IBpmGraphToolbarElementSelectorHandler) { super(parameters); }
    public getComponentParameter(): IBpmGraphToolbarElementSelectorHandler { return super.getComponentParameter() as IBpmGraphToolbarElementSelectorHandler }
}
