<section class="transfer-dialog">
  <app-participant-selector
  [participantSelectorHandler]="getParticipantSelectorHandler()">
  </app-participant-selector>

  <div class="amount">
      <label class="amount--title">{{ labels.amountTitle }}</label>
      <div class="amount__input-container">
        <mat-icon class="amount__input-container--icon">attach_money</mat-icon>
        <input class="amount__input-container--input" [(ngModel)]="value"/>
      </div>
  </div>

  <cm-button
  [color]="getBtnColor()"
  class="transfer-dialog__confirm-btn"
  (click)="submitTransfer()">
    {{ labels.confirm }}
  </cm-button>
</section>
