import { TStatusInteractionProp } from "@colmeia/core/src/shared-business-rules/crm/crm-utils";

export const statusInteractionProps: TStatusInteractionProp[] = [
    "idSeverityItem",
    "idTicketStateItem",
    "idSupportLevelItem",
    "idPhaseItem",
    "idCloseStateItem",
    "idUrgencyLevelItem",
];

export const crmTicketsColmeiaWindowId = "tickets-window";
