import { AnimationEvent } from '@angular/animations';
import { CdkAccordionItem } from "@angular/cdk/accordion";
import { UniqueSelectionDispatcher } from "@angular/cdk/collections";
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { isValidNumber } from "@colmeia/core/src/tools/utility";
import { merge, Subject, Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { TVisibleContentState } from "./visible.animations";

@Directive({
    host: {
        '[style.overflow]': '_overflow'
    }
})
export class VisibleComponentBase extends CdkAccordionItem implements OnInit, OnChanges, OnDestroy {
    private _timeout: NodeJS.Timeout;

    public set visible(value: boolean) {
        if(isValidNumber(this.timeout)) {
            clearTimeout(this._timeout);
            this._timeout = setTimeout(() => {
                this.expanded = value;
            }, this.timeout);

            return;
        }

        this.expanded = value;
    }

    @Input()
    timeout: number;

    @Output()
    change: EventEmitter<boolean> = new EventEmitter();

    _overflow: null | "hidden" = "hidden";
    private cdr: ChangeDetectorRef;

    readonly _contentAnimationStart = new Subject<AnimationEvent>();
    readonly _contentAnimationDone = new Subject<AnimationEvent>();

    /** An event emitted after the body's expansion animation happens. */
    @Output() readonly afterExpand = new EventEmitter<void>();

    /** An event emitted after the body's collapse animation happens. */
    @Output() readonly afterCollapse = new EventEmitter<void>();

    constructor(
        _changeDetectorRef: ChangeDetectorRef,
        readonly elementRef: ElementRef,
        protected _expansionDispatcher: UniqueSelectionDispatcher,
        ) {
        super(
            undefined,
            _changeDetectorRef,
            _expansionDispatcher,
        );

        this.cdr = _changeDetectorRef;
    }

    ngOnInit() {
        merge(this.opened, this.closed).subscribe(() => {
            this.cdr.markForCheck();
        });

        this._contentAnimationDone
        .pipe(
            distinctUntilChanged((x, y) => {
                return x.fromState === y.fromState && x.toState === y.toState;
            }),
        )
        .subscribe(event => {
            if (event.fromState !== 'void') {
                if (event.toState === 'expanded') {
                this.afterExpand.emit();
                } else if (event.toState === 'collapsed') {
                this.afterCollapse.emit();
                }
            }
        });
    }

    ngOnChanges() {
        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    public hide(): void {
        super.close();
    }

    public show(): void {
        super.open();
    }

    _getExpandedState(): TVisibleContentState {
        return this.expanded ? 'expanded' : 'collapsed';
    }

    /**
     * esse timeout é usado para evitar o erro ExpressionChangedAfterItHasBeenCheckedError
     * @param event 
     */
    onAnimationDone(event: AnimationEvent) {
        setTimeout(() => {
            this._overflow = this.expanded ? null : 'hidden'
        });
        
        this._contentAnimationDone.next(event);
    }

    onAnimationStart(event: AnimationEvent) {
        this._overflow = "hidden";
        this._contentAnimationStart.next(event);
    }
}
