import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { HighLevelMessage } from '../high-level-message';
import { MessageInstanceHandler } from 'app/handlers/message-instance-handler/message-instance-handler';
import { SignalListenerService } from 'app/services/signal/signal-listener';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-read-confirmation',
	templateUrl: './read-confirmation.component.html',
	styleUrls: ['./read-confirmation.component.scss']
})
export class ReadConfirmationComponent extends HighLevelMessage implements OnInit {

	private interaction: Interaction;

    constructor(
        private cdr: ChangeDetectorRef,
        private messageHandler: MessageInstanceHandler,
        private listener: SignalListenerService,
    ) {
        super({currentCDR: cdr, listener: listener, messageHandler: messageHandler})
        this.interaction = messageHandler.getInteraction();
    }

	ngOnInit() {
	}

	get confirmationIcon(): { icon: string, color?: string } {
		const groupID = this.messageHandler.getGroupID();
		if(this.interaction.isReadByAll(groupID)){
			return { icon: "done_all", color: "#2fc0c1" }
			//return '<i class="material-icons" style="color:#2fc0c1;">done_all</i>';
			//return './assets/icons/read-confirmation/check-readed.svg';
		} else if(this.interaction.isReceivedByAll(groupID)) {
			return { icon: "done_all", color: "#737373" }
			//return '<i class="material-icons">done_all</i>';
			//return './assets/icons/read-confirmation/check-received.svg';
		} else {
			return { icon: "done", color: "#737373" }
			//return '<i class="material-icons">done</i>';
			//return './assets/icons/read-confirmation/check-sent.svg';
		}
	}

}
