import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { IGenerativeVectorChunkServer } from "@colmeia/core/src/shared-business-rules/generative/generative-model";
import { defaultNserValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/nser";
import { isInvalid } from '@colmeia/core/src/tools/utility';
import { contentVectorTranslations } from '../../../const-text/views/vectors';

export function contentVectorChunkValidator(ns: IGenerativeVectorChunkServer, friendly: FriendlyMessage): FriendlyMessage {

    defaultNserValidator(ns, friendly);

    if (isInvalid(ns.pageContent) || ns.pageContent.trim() === "") {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noChunkText);
    }

    return friendly;
}
