
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { DatePipe, Location } from "@angular/common";
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, forwardRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { IRFieldResponse, TGeneralFieldArray } from "@colmeia/core/src/general-form/general-form-answer";
import {
    IColumnFileMetadata, IDownloadFileContentRequest, IFileField, IGeneralFileMetadata,
    IGetFileContentRequest, IGetFileContentResponse,
    IGetFileErrorContentRequest, IGetFileErrorContentResponse
} from "@colmeia/core/src/request-interfaces/files-interfaces";
import { apiRequestType, EFileRequest } from "@colmeia/core/src/request-interfaces/message-types";
import { IResponseCursor } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IServerColmeiaTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { fileDetailTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/file-detail";
import { EFileError, fileProcessorConfig, IFileProcessorConfig, TEFileError } from "@colmeia/core/src/shared-business-rules/files/file-helper";
import { IFileRowError, IFileRowLine, SchemaPropertyServer, TFileRowErrorArray, TRowLineArray } from "@colmeia/core/src/shared-business-rules/files/files";
import { TGraphPropertyData } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-types";
import {
    add, getSlicedArray,
    humanReadableFileSize,
    isInvalid,
    isInvalidArray, isValidArray, isValidString,
    noAccentsAndLower,
    nop
} from "@colmeia/core/src/tools/utility";
import { VisibleHorizontalComponent } from 'app/components/visible/visible-horizontal.component';
// import { VisibleHorizontalComponent } from 'app/components/visible/visible.component';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { GenericDashboardEditHandler } from 'app/handlers/generic-dashboard-edit.handler';
import { MAX_TIMEOUT } from 'app/model/constants/general.constants';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GeneralFormService } from 'app/services/general-form.service';
import { copyToClickBoard } from 'app/utils/copy';
import { debounce } from 'lodash';
import { Subscription } from 'rxjs';
import { delay, distinctUntilChanged, first, retry, take, timeout } from 'rxjs/operators';
import { ISelectedTagsListHandler } from "../../../handlers/tag-picker-handler";
import { ClientInfraResponse, IInfraParameters } from "../../../model/client-infra-comm";
import { DashboardFileService } from "../../../services/dashboard/dashboard-file.service";
import { LookupService } from "../../../services/lookup.service";
import { RequestBuilderServices } from "../../../services/request-builder.services";
import { ServerCommunicationService } from "../../../services/server-communication.service";
import { SessionService } from "../../../services/session.service";
import { UserSettingsService } from "../../../services/user-settings.service";
import { RootComponent } from "../../foundation/root/root.component";
import { animationReady, appearFromLeft } from "../dashboard-animations";
import { DashboardWindowEditorRef } from '../dashboard-foundation/colmeia-window/colmeia-window-edit-ref';
import { GenericDashboardPaginationHandler } from "../dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.handler";
import {
    GenericDashboardPaginationParameter,
    IGDPaginationClientCallback,
    IGDPerPageOptions
} from "../dashboard-foundation/generic-dashboard-pagination/generic-dashboard-pagination.parameter";
import { DashboardIntegrationCreateComponent } from "../dashboard-integration-create/dashboard-integration-create.component";
import { DashboardIntegrationHandler, EDatabaseProcessOperation } from "../dashboard-integration-create/dashboard-integration-create.handler";
import { NSPickerHandler } from './../../../handlers/ns-picker/ns-picker.handler';
import { FileDetailVirtualScrollStrategy } from './dashboard-file-details.scroll-strategy';

export function _customSizeVirtualScrollStrategyFactory(fixedSizeDir: DashboardFileDetailComponent) {
    return fixedSizeDir._scrollStrategy;
}

interface IFileDetailPrintField {
    name: string;
    value: string;
    isError?: boolean;
}

type TErrorRow = {
    line: number;
    fields: IFileDetailPrintField[];
};

type TLocalGraphDataRow = { name: string; value: TGraphPropertyData }[];
type TLocalGraphDataRows = TLocalGraphDataRow[];

type TErrorRowArray = TErrorRow[]
@Component({
    selector: 'app-dashboard-file-detail',
    templateUrl: './dashboard-file-detail.component.html',
    styleUrls: ['./dashboard-file-detail.component.scss'],
    providers: [{
        provide: VIRTUAL_SCROLL_STRATEGY,
        useFactory: _customSizeVirtualScrollStrategyFactory,
        deps: [forwardRef(() => DashboardFileDetailComponent)]
    }],
    animations: [appearFromLeft],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFileDetailComponent extends RootComponent<
    'fileName' |
    'fileSize' |
    'columnName' |
    'formField' |
    'nullFields' |
    'cardinality' |
    'savedLines' |
    'linesWithError' |
    'creationDate' |
    'fileDetails1' |
    'noFilesToShow' |
    'selectCSVFile' |
    'errorLoadingFiles'
> implements OnInit, IGDPaginationClientCallback {

    public animationState = animationReady;
    displayedColumns: string[] = ['columnName', 'formField', 'nullFields', 'cardinality'];
    paginationContentHandler: GenericDashboardPaginationHandler;
    paginationErrorHandler: GenericDashboardPaginationHandler;
    contentFileRows: TLocalGraphDataRows;
    filterByValueRows: TLocalGraphDataRows;
    canShowGoBack: boolean = true;
    public file: IGeneralFileMetadata;
    private currentPage: number = 0;
    private perPage: number = 1;
    selectedTagsListHandler: ISelectedTagsListHandler;
    loading: boolean = true;
    fileContent = {
        columns: [] as string[],
        rows: [] as IFileRowLine[][]
    };
    cursorContent?: string = null;
    cursorErros?: string = null;
    rowsContentFile: TRowLineArray = [];
    rowsContentFileSplited;
    perPageOptions: IGDPerPageOptions;
    rowsErrorFile: TFileRowErrorArray = [];
    metadataVisualizationPicker: NSPickerHandler;
    errorsRows: TErrorRowArray;
    propertyNameList: Map<number, string> = new Map()

    public showFileUpsert: boolean;
    public expandedErrorsRows: Record<number | string, boolean> = {};
    public expandedContentRows: Map<string, boolean> = new Map();
    public isAllRowContentExpanded: boolean = false;
    public isAllErrorExpanded: boolean = false;

    _scrollStrategy = new FileDetailVirtualScrollStrategy();

    @ViewChildren("cTR")
    cTR: QueryList<ElementRef<HTMLElement>>;

    @ViewChildren("extraContent")
    extraContentList: QueryList<VisibleHorizontalComponent>;

    public filterByValue: string = '';
    public genericEditHandler: GenericDashboardEditHandler;
    public aHrefDownload$: HTMLAnchorElement;
    public downloadingFile: boolean = false;
    public integrationHandler: DashboardIntegrationHandler;

    constructor(
        private httpClient: HttpClient,
        private settingsSvc: UserSettingsService,
        private dashFileSvc: DashboardFileService,
        private location: Location,
        private rbs: RequestBuilderServices,
        private cdr: ChangeDetectorRef,
        private api: ServerCommunicationService,
        private session: SessionService,
        private lookupSvc: LookupService,
        private dialogSvc: MatDialog,
        private dashboardSvc: DashBoardService,
        private generalFormSvc: GeneralFormService,
        private snackSvc: MatSnackBar,
        private editor: DashboardWindowEditorRef<IGeneralFileMetadata>,
        private renderer: Renderer2,
    ) {
        super({
            ...fileDetailTranslations,
        });

        // virtualScroll.setExpandedItemsCount(x);

        // if (isValidRef(dataFromDialogSvc)) {
        this.file = this.editor.object;
        this.genericEditHandler = this.editor.editHandler
        // this.canShowGoBack = false;
        // } else {
        //     this.file = this.dashFileSvc.selectedFile;
        // }
    }

    public get isEdit(): boolean {
        return this.editor.isEdit;
    }

    public get hasContentFileRows() {
        return this.contentFileRows.length;
    }

    public init(): void {
        this.initIntegrationHandler();
        this.initSelectedTagsListHandler();
        this.generateContentPaginationHandler();
        this.getFileContent();
        this.initErrorRows();
        this.verifyIfCanUpsert();
        this.initMetadataVisualizationPicker();
        this.bindState();
    }

    private contentListChangeSub: Subscription;

    private listenForContentChange() {
        this.contentListChangeSub = this.cTR?.changes.pipe(distinctUntilChanged(), delay(200)).subscribe(() => {
            if (this.isAllErrorExpanded) return

            this.cTR.forEach((ctr, i) => {
                this._scrollStrategy.setItemSize(i, ctr.nativeElement.getBoundingClientRect().height);

                const c = this.extraContentList.get(i);
                c?._contentAnimationDone.pipe(first()).subscribe(() => {
                    this._scrollStrategy.setItemSize(i, ctr.nativeElement.getBoundingClientRect().height);
                });
            });
        });
    }

    private updateContentItemsSize() {
        this.cTR.forEach((ctr, i) => {
            this._scrollStrategy.setItemSize(i, ctr.nativeElement.getBoundingClientRect().height);
        });
    }

    public async verifyIfCanUpsert(): Promise<void> {
        const schema: SchemaPropertyServer = await this.lookupSvc.getSingleLookupElement<SchemaPropertyServer>(this.file.idSchemma);
        this.showFileUpsert = schema.schemma.form.some(field => field.isPrimaryKey);
    }

    private initMetadataVisualizationPicker() {
        this.metadataVisualizationPicker = this.dashboardSvc.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.formSchemma,
            useDemandedTag: undefined,
            selectedId: this.file.idSchemma,
            clientCallback: {},
            title: "Metadado"
        }, { disabledTitle: "Metadado" });
    }

    ngOnInit() {
        if (!this.isEdit) return;
        this.init();
        this.aHrefDownload$ = this.renderer.createElement('a');
    }

    public initIntegrationHandler(): void {
        this.integrationHandler = DashboardIntegrationHandler.factory({
            type: EDatabaseProcessOperation.upsert,
            currentFile: this.file,
            clientCallback: { onRefreshFile: () => this.refreshFile() }
        });
    }


    public async refreshFile(): Promise<void> {
        this.file = (await this.lookupSvc.getBatchNonSerializables<IGeneralFileMetadata[]>([this.file.idNS]))[0];
        this.init();
    }

    async initErrorRows() {
        await this.getFileErrors();
        this.createErrorsRows();
        this.bindState();
    }


    public initContentFileRows(): void {
        this.contentFileRows = this.getContentFileRows();
    }

    getContentFileRows(): TLocalGraphDataRows {
        const ret: TLocalGraphDataRows = [];
        const pageRows: IFileRowLine[] = isValidArray(this.fileContent.rows[this.currentPage])
            ? this.fileContent.rows[this.currentPage]
            : []
            ;
        pageRows
            .forEach((row: IFileRowLine) => {
                const page: TLocalGraphDataRow = [];

                row.rows.forEach((fieldResponse: IRFieldResponse, idx: number) => {
                    page.push({ name: fieldResponse.propertyName, value: fieldResponse.value });
                });

                ret.push(page);
            });
        return ret;
    }

    buildSearchFilterContentRows = debounce(async () => {
        if (!this.filterByValue.length) {
            this.filterByValueRows = [];
            await this.getFileContent();
            return;
        }

        // this.generalFormSvc.searchInFile()

        this.cursorContent = undefined;

        await this.getFileContent();

        const searchTokens = this.getSeachContentTokens().map(noAccentsAndLower);

        this.filterByValueRows = (
            this.fileContent.rows
                .reduce((finalArr, row) => [...row, ...finalArr], [])
                .filter(row => row.rows.some(({ value }) => this.hasSearchTerm(noAccentsAndLower(value.toString()), searchTokens)))
                .sort((a, b) => {
                    const al = a.rows.reduce((count, { value }) =>
                        count + this.countSearchTerm(noAccentsAndLower(value.toString()), searchTokens),
                        0
                    );

                    const bl = b.rows.reduce((count, { value }) =>
                        count + this.countSearchTerm(noAccentsAndLower(value.toString()), searchTokens),
                        0
                    );

                    return bl - al;
                })
                .map((row) => row.rows.reduce((finalArr, v) => (finalArr.push(v.value), finalArr), []))

        )
        this.markForCheck();
    }, 200);

    getSeachContentTokens(): string[] {
        return this.filterByValue.toLowerCase().split(" ").filter((str) => isValidString(str, 10));
    }

    countSearchTerm(value: string, searchTokens: string[] = this.getSeachContentTokens()): number {
        return new RegExp(searchTokens.join("|"), "gi").exec(value)?.length || 0;
    }

    hasSearchTerm(value: string, searchTokens: string[] = this.getSeachContentTokens()): boolean {
        return new RegExp(searchTokens.join("|"), "gi").test(value);
    }

    highlightSearchValue(value: string): string {
        return this.highlightSearchValues(value, this.getSeachContentTokens());
    }

    private highlightSearchValues(content: string, terms: string[]): string {
        return content.replace(
            new RegExp(terms.join("|"), "gi"),
            '<span class="file-detail-search-highlight">$&</span>'
        );
    }


    public fieldsFromRowObj(rowObj: IFileField): IFileDetailPrintField[] {
        const fields: IFileDetailPrintField[] = Object
            .keys(rowObj)
            .map(add('name', name => name))
            .map(add('value', field => rowObj[field.name]))
            ;
        return fields;
    }

    public responseFieldsToFields(fields: TGeneralFieldArray, validateError: TEFileError): IFileDetailPrintField[] {
        return fields.map((field: IRFieldResponse) => {
            const config: IFileProcessorConfig = fileProcessorConfig as IFileProcessorConfig;
            const canonicalError: EFileError = (config.globalCanonicalToFileError)[field.idGlobalCanonical];
            return {
                name: field.propertyName,
                value: field.raw,
                isError: validateError.includes(canonicalError),
            }
        });
    }

    public isEmptyField<T>(value: T): boolean {
        return isInvalid(value);
    }

    createErrorsRows() {
        this.errorsRows = this.rowsErrorFile
            .sort((row: IFileRowError, nextRow: IFileRowError) => row.frow - nextRow.frow)
            .map((it: IFileRowError) => {
                const fields: IFileDetailPrintField[] = isValidArray(it.fields)
                    ? this.responseFieldsToFields(it.fields, it.validateError)
                    : this.fieldsFromRowObj(it.rowObj)
                    ;
                return {
                    line: it.frow,
                    fields,
                };
            })
            ;
    }

    getErrorsRows() {
        return this.errorsRows;
    }

    get hasErrors(): boolean {
        return isValidArray(this.errorsRows);
    }

    generateFileContent() {
        this.fileContent.columns = [];
        this.file.dataProfile.forEach((item: IColumnFileMetadata) => {
            this.fileContent.columns.push(item.prompt);
        });
    }

    generateContentPaginationHandler(): void {
        const perPageOptions: IGDPerPageOptions = {
            current: 50,
            options: [10, 30, 50, 100]
        }

        this.perPage = perPageOptions.current;

        const paginatorParameter: GenericDashboardPaginationParameter = {
            totalItems: this.rowsContentFile.length,
            clientCallback: this,
            perPage: perPageOptions,
            index: this.currentPage,
            hasMoreEntitiesToLoad: true,
            showCounter: true
        };
        this.paginationContentHandler = new GenericDashboardPaginationHandler(paginatorParameter);
    }
    onAmountPerPageChange(amount: number) {
        this.perPage = amount;
        this.splitRows();
        this.bindState();
    }
    async loadMoreItems(): Promise<void> {
        await this.getFileContent(true);
        this.bindState();
    }
    onPageIndexChange(index: number) {
        this.currentPage = index;
        this._scrollStrategy.setCurrentPage(index);
        this.initContentFileRows();
        this.bindState();
    }

    private cursorSet: Set<string> = new Set();

    private updateCursorsContent(response: IResponseCursor): void {
        this.cursorContent = response.cursor || null;

        if (isValidString(this.cursorContent)) {
            this.cursorSet.add(this.cursorContent)
        }

        this.updatePaginationCanLoadMoreEntitiesContent();
    }

    public printFieldValue(value: string) {
        return value || 'Campo vazio';
    }

    private updatePaginationCanLoadMoreEntitiesContent(): void {
        const isEmptyCursor = this.cursorContent !== null;
        this.paginationContentHandler.setHasMoreEntitiesToLoad(isEmptyCursor);
    }

    async getFileErrors() {
        const infra: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );

        const messageType: EFileRequest = apiRequestType.files.getContentErrorFile;
        const request: IGetFileErrorContentRequest = {
            ...this.rbs.createRequestFromInfraParameters(messageType, infra),
            idFile: this.file.idNS,
            search: this.filterByValue,
            cursor: this.cursorErros
        };

        const response: ClientInfraResponse = await this.api.managedRequest(infra, request);


        if (response.executionOK) {
            const result = response.response as IGetFileErrorContentResponse;
            this.rowsErrorFile = result.rows;
        }
    }

    async getFileContent(incremental: boolean = false) {
        const infra: IInfraParameters = this.rbs.getNoCallBackSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );

        const messageType: EFileRequest = apiRequestType.files.getContentFile;
        const request: IGetFileContentRequest = {
            ...this.rbs.createRequestFromInfraParameters(messageType, infra),
            idFile: this.file.idNS,
            search: this.filterByValue,
            cursor: this.cursorContent
        };

        const response: ClientInfraResponse = await this.api.managedRequest(infra, request);

        if (response.executionOK) {
            const result = response.response as IGetFileContentResponse;
            this.updateCursorsContent(<IResponseCursor>result);
            if (incremental) {
                this.rowsContentFile = [
                    ...this.rowsContentFile,
                    ...result.rows as TRowLineArray
                ];
            } else {
                this.rowsContentFile = result.rows as TRowLineArray;
            }
            this.paginationContentHandler.setTotalItems(this.rowsContentFile.length);
            this.splitRows();
            this.generateFileContent();
        }
        this.initContentFileRows();
    }
    splitRows() {
        this.fileContent.rows = getSlicedArray(this.rowsContentFile.sort((row: IFileRowLine, nextRow: IFileRowLine) => row.row - nextRow.row), this.perPage);
    }

    private async initSelectedTagsListHandler(): Promise<void> {
        if (isInvalidArray(this.file.tags))
            return;

        const selectedTags: IServerColmeiaTag[] = await this.getSelectedTags();

        this.selectedTagsListHandler = {
            hideRemove: true,
            isModal: false,
            onRemoveCallback: nop,
            selectedTags,
        }
    }

    private async getSelectedTags(): Promise<IServerColmeiaTag[]> {
        const tagIDS: TArrayID = this.file.tags.map(tag => tag.idTag);
        const tags: IServerColmeiaTag[] = await this.lookupSvc.getBatchNonSerializables<IServerColmeiaTag[]>(tagIDS);
        return tags;
    }

    public getDate(clocktick: number): string {
        return new DatePipe(this.settingsSvc.getSelectedLocale())
            .transform(
                clocktick,
                this.settingsSvc.getDateHourFormat());
    }

    public getFileSize(bytes: number): string {
        if (isInvalid(bytes)) {
            return '';
        }

        return humanReadableFileSize(bytes);
    }

    public goBack(): void {
        this.location.back();
    }

    //TODO: implement open delete database component
    openDatabaseDelete() {
        this.integrationHandler.setType(EDatabaseProcessOperation.delete)
        this.dialogSvc.open<DashboardIntegrationCreateComponent, DashboardIntegrationHandler>(DashboardIntegrationCreateComponent, {
            data: this.integrationHandler,
            width: "60vw",
            autoFocus: false
        })
    }

    openUpsertModal() {
        this.integrationHandler.setType(EDatabaseProcessOperation.upsert)
        this.dialogSvc.open<DashboardIntegrationCreateComponent, DashboardIntegrationHandler>(DashboardIntegrationCreateComponent, {
            data: this.integrationHandler,
            width: "60vw",
            autoFocus: false
        })
    }

    public async copyToClipboard(value: string) {
        await copyToClickBoard(value);

        this.snackSvc.open("Copiado para a área de transferência.", "Fechar", { duration: 3000 })
    }

    public toggleRowErrorShow(rowIndex: number) {
        this.expandedErrorsRows[rowIndex] = !this.expandedErrorsRows[rowIndex];
    }

    public isExpandedContentRow(index: number) {
        return this.isContentExpanded(index) || this.isAllRowContentExpanded;
    }

    private isContentExpanded(index: number): boolean {
        return !!this.expandedContentRows.get(FileDetailVirtualScrollStrategy.getKeyForIndex(this.currentPage, index));
    }

    setContentExpanded(index: number, value: boolean) {
        this.expandedContentRows.set(FileDetailVirtualScrollStrategy.getKeyForIndex(this.currentPage, index), value);
    }

    public toggleRowContentShow(rowData: any[], indexOnDataSource: number, cTR: HTMLElement) {
        const isExpanded = this.isExpandedContentRow(indexOnDataSource);
        this.setContentExpanded(indexOnDataSource, !isExpanded);

        const vc = this.extraContentList.toArray().find(c => {
            const el = c.elementRef.nativeElement as HTMLElement;

            return +el.dataset.sourceIndex === indexOnDataSource
        });
        vc._contentAnimationDone.pipe(first()).subscribe(() => {
            this._scrollStrategy.setItemSize(indexOnDataSource, cTR.offsetHeight);
        });
    }

    toggleAllContentRows() {
        if (!this.extraContentList.first) return;

        this.isAllRowContentExpanded = !this.isAllRowContentExpanded;

        this.extraContentList.first._contentAnimationDone.pipe(take(1)).subscribe(() => {
            this._scrollStrategy.setFixedItemsSize(
                this.isAllRowContentExpanded
                    ? this.cTR.first.nativeElement.getBoundingClientRect().height
                    : undefined
            );

            this._scrollStrategy.onDataChanged();
        });

        this.cdr.markForCheck();
    }

    hasErrorField(column: TErrorRow): boolean {
        return column.fields.some(f => f.isError);
    }

    hasEmptyField(column: TErrorRow): boolean {
        return column.fields.some(f => this.isEmptyField(f));
    }

    @ViewChild("contentHeader", { static: false })
    contentHeader: ElementRef<HTMLElement>

    @ViewChild("contentPagination", { static: false })
    contentPagination: ElementRef<HTMLElement>

    public calculateTBodyContentHeight(): string {
        const tabHeaderHeight = 50;
        const tableTopMargin = 14;
        const headerHeight = this.contentHeader?.nativeElement?.clientHeight || 0;
        const paginationHeight = this.contentPagination?.nativeElement?.clientHeight || 0;

        return `calc(70vh - ${tabHeaderHeight + tableTopMargin + headerHeight + paginationHeight}px)`
    }

    @ViewChild("contentErrorsRowsHeader", { static: false })
    contentErrorsRowsHeader: ElementRef<HTMLElement>

    public calculateTBodyErrorsRowsHeight(): string {
        const tabHeaderHeight = 50;
        const tableTopMargin = 14;
        const headerHeight = this.contentErrorsRowsHeader?.nativeElement?.clientHeight || 0;

        return `calc(70vh - ${tabHeaderHeight + tableTopMargin + headerHeight}px)`
    }

    private bindState() {
        this.updatePaginationCanLoadMoreEntitiesContent();
        this._scrollStrategy.onDataChanged();
        if (!this.contentListChangeSub) this.listenForContentChange();
        this.markForCheck();
    }

    public markForCheck() {
        this.cdr.markForCheck();
    }

    public async downloadFileBlob() {
        // this.httpClient.get(`${clientConstants.FILE_URL}/database/${this.file.idNS}`, {responseType: 'blob'})
        //     .subscribe(data => saveAs(data))

        const req: IDownloadFileContentRequest = {
            ...(this.rbs.secureBasicRequest(EFileRequest.downloadFileContent)),
            ...{ idFileDatabase: this.file.idNS, databaseName: this.file.fileName }
        };

        const SERVER_URL = this.api.getServerURL(EFileRequest.downloadFileContent);
        this.downloadingFile = true;

        this.httpClient.post(SERVER_URL, req, { ...this.api.getHttpOptions() }).pipe(
            retry(0),
            timeout(MAX_TIMEOUT)
        ).subscribe((res) => {
            console.log({ res });
        });
    }
}

