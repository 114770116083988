export const groupConstants = {
    name: {
        minLength: 3,
        maxLength: 100
    },
    description: {
        minLength: 3,
        maxLength: 200
    }
};
