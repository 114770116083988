<div class="cm__container">
  <mat-form-field appearance="outline" class="cm__form__field">
    <mat-label>{{ name }}(s)</mat-label>
    <mat-chip-grid #chipGrid aria-label="{{ name }}(s)">
      <mat-chip-row *ngFor="let item of items; let i = index" [selectable]="true" [removable]="true"
        (removed)="removeItem(i)">
        {{ item }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input placeholder="Novo {{ name }}" [matChipInputFor]="chipGrid" (matChipInputTokenEnd)="addItem($event)"
        [matChipInputAddOnBlur]="true" />
    </mat-chip-grid>
    <mat-hint *ngIf="showItemAddTip">
      Pressione <strong>enter</strong> após digitar para adicionar
    </mat-hint>
  </mat-form-field>
</div>