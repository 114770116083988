import {Participant} from '../../business/participant';
import {constant, TGlobalUID} from '../../business/constant';
import {Interaction} from '../interaction'
import {InteractionType} from '../interaction-type';
import {UberCache} from '../../persistency/uber-cache';
import { EServiceChatType, IServiceChatInitiatedInteractionJSON } from '../../comm-interfaces/service-group-interfaces';
import { IBasicUniversalInfo } from '../../comm-interfaces/aux-interfaces';
import { EServiceGroupIntType } from '../../business/constant.enums';
import { isValidRef } from '../../tools/utility';
import { EIslandType } from '../../shared-business-rules/attendance-island/attendance-island';



export class StartServiceChat extends Interaction {
    private idServiceGroup: TGlobalUID;
    private serviceAvatar: IBasicUniversalInfo;
    private serviceType: EIslandType;
    private idMacroPackage: string;
    private idInternalSupportGroup: string;
    private idIsLand: string;
    private agentName: string | undefined;
    private idSourceIsland: string | undefined;
    private hasQueueMsg: boolean | undefined;

    private constructor(primaryID: TGlobalUID, participant: Participant, idInternalSupportGroup: string = null) {
        super(primaryID, participant, InteractionType.staticFactory(EServiceGroupIntType.startServiceChat));
        if(isValidRef(idInternalSupportGroup)){
            this.idInternalSupportGroup = idInternalSupportGroup;
            this.serviceType = EIslandType.internalSupport;
        }
    };

    public getClient() {
        return this.serviceAvatar
    }

    

    public rehydrate(json: IServiceChatInitiatedInteractionJSON): void {
        super.rehydrate(json);
        this.idServiceGroup = json.idServiceGroup;
        this.serviceAvatar = json.serviceAvatar;
        this.serviceType = json.serviceType;
        this.idMacroPackage = json.idMacroPackage;
        this.idIsLand = json.idIsland;
        this.agentName = json.agentName;
        this.idSourceIsland = json.idSourceIsland;
        this.hasQueueMsg = json.hasQueueMsg;
    };

    public toJSON(): IServiceChatInitiatedInteractionJSON {
        const json: IServiceChatInitiatedInteractionJSON = {
            ...super.toJSON(),
            idServiceGroup: this.idServiceGroup,
            serviceAvatar: this.serviceAvatar,
            serviceType: this.serviceType,
            idMacroPackage: this.idMacroPackage,
            idInternalSupportGroup: this.idInternalSupportGroup,
            idIsland: this.idIsLand,
            hasQueueMsg: this.hasQueueMsg,
        }

        return json;
    }

    public getIdMacroPackage(): string {
        return this.idMacroPackage;
    }

    public getIdIsland(): string {
        return this.idIsLand
    }
    
    public getIdSourceIsland(): string | undefined {
        return this.idSourceIsland;
    }

    public static factoryMessage(json: IServiceChatInitiatedInteractionJSON, participant: Participant = null): StartServiceChat {
        let definition: StartServiceChat = <StartServiceChat>Interaction.searchCachedInteraction(json.primaryID);
        if (definition == null)
           definition = <StartServiceChat> new StartServiceChat(json.primaryID, 
                            participant? participant: Participant.staticFactory(json.participant.primaryID));
        definition.rehydrate(json);
        return definition;
    };

    public getServiceGroupID(): TGlobalUID {return this.idServiceGroup; };
    public getTargetedAvatar(): IBasicUniversalInfo {return this.serviceAvatar; };
    public getServiceType(): EIslandType {return this.serviceType; };
    public getAgentName(): string | undefined { return this.agentName; };

    public setIdInternalSupportGroup(idInternalSupportGroup: string): void {
        this.idInternalSupportGroup = idInternalSupportGroup;
    }

    public getIdInternalSupportGroup(): string {
        return this.idInternalSupportGroup;
    }

    public isFinished(idGroup: string): boolean {
        return this.getChildren(idGroup).some(interaction => interaction.getInteractionType().is(EServiceGroupIntType.finishService));
    };

    public static staticFactory(idThread: TGlobalUID): StartServiceChat {
        return <StartServiceChat>UberCache.uberFactory(idThread, constant.objectType.interaction, true);
    };

    public static factory(participant: Participant, idInternalSupportGroup: string): StartServiceChat {
        const interaction: StartServiceChat = new StartServiceChat(null, participant, idInternalSupportGroup);
        return interaction;
    }

};
