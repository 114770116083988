import { Injectable } from '@angular/core';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { EChatBtnsVisualElements } from '@colmeia/core/src/core-constants/constant-visual-elements.enums';
import { Group } from '@colmeia/core/src/business/group';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import {AppBarShortcuts, EGroupNavigation, EGroupType, EScreenGroups} from "@colmeia/core/src/shared-business-rules/visual-constants";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { BigIconService } from 'app/services/big-icon.service';
import { routeID } from 'app/model/routes/route-constants';
import { AnnotationsService } from 'app/services/annotations.service';
import { RouterManagerService } from 'app/services/router-manager.service';
import { GlobalWarningService, IInteractiveButton, EInteractiveButton } from 'app/services/global-warning.service';
import { GroupChatServices } from 'app/services/group-chat.services';
import { SessionService } from 'app/services/session.service';
import { IAnnotationsModalParameters, AnnotationsModalHandler, EAnnotationsType } from 'app/handlers/annotations-modal-handler';
import { AnnotationsModalComponent } from 'app/components/annotation/annotations-modal/annotations-modal.component';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { SignalListenerService } from 'app/services/signal/signal-listener';
import { IListenerSubscription } from 'app/model/signal/ps-interfaces';
import { SubscriptionSignal } from 'app/model/signal/subscription-signal';
import { GroupEditorCreatorService } from 'app/services/group-editor-creator.service';
import { IDataBuilder, SafeMenuBuilderServices } from 'app/services/controllers-services/security-controller/safe-menu-builder';
import { isValidArray } from '@colmeia/core/src/tools/utility';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface IGroupCardItemDescriptor {
    title: string;
    imagePath: string;
}

@Injectable({
    providedIn: 'root'
})
export class GroupCardItemService {

    iconsFolder: string = 'assets/icons/group-type';
    groupTypeToImagePath: { [groupTypeID: string]: IGroupCardItemDescriptor } = {
        [constant.groupType.functional.broadcast]: {
            title: Serializable.staticFactory(constant.groupType.functional.broadcast).getName(),
            imagePath: `${this.iconsFolder}/icon_broadcast.svg`
        },
        [constant.groupType.standard.anonymous]: {
            title: Serializable.staticFactory(constant.groupType.standard.anonymous).getName(),
            imagePath: `${this.iconsFolder}/icon_grupoanonimo.svg`
        },
        [constant.groupType.functional.distributionList]: {
            title: Serializable.staticFactory(
                constant.groupType.functional.distributionList
            ).getName(),
            imagePath: `${this.iconsFolder}/icon_listadedistribuicao.svg`
        },
        [constant.groupType.functional.featureCreator]: {
            title: Serializable.staticFactory(
                constant.groupType.functional.featureCreator
            ).getName(),
            imagePath: `${this.iconsFolder}/icon_novafuncionalidade.svg`
        },
        [constant.groupType.standard.channel]: {
            title: Serializable.staticFactory(constant.groupType.standard.channel).getName(),
            imagePath: `${this.iconsFolder}/icon_novocanal.svg`
        },
        [constant.groupType.functional.spokenGroup]: {
            title: Serializable.staticFactory(constant.groupType.functional.spokenGroup).getName(),
            imagePath: `${this.iconsFolder}/icon_portavoz.svg`
        },
        [constant.groupType.functionalRoot.corporate]: {
            title: Serializable.staticFactory(
                constant.groupType.functionalRoot.corporate
            ).getName(),
            imagePath: `${this.iconsFolder}/icon_corporate.svg`
        },
        [constant.groupType.personalGroupTypes.avatarPersonalGroup]: {
            title: '',
            imagePath: ``
        },
        [constant.groupType.standard.standardGroup]: {
            title: '',
            imagePath: ``
        }
    };

    constructor(
        private bigIconSvc: BigIconService,
        private dialogSvc: ColmeiaDialogService,
        private routerSvc: RouterManagerService,
        private warningScreenSvc: GlobalWarningService,
        private chatSVC: GroupChatServices,
        private session: SessionService,
        private gcreatorSVC: GroupEditorCreatorService,
        private snackSvc: MatSnackBar,
    ) {}

    private getGroupCardDescriptor(groupTypeID: TGlobalUID): IGroupCardItemDescriptor {
        return this.groupTypeToImagePath[groupTypeID]
    }

    getGroupTypeImgSrc(groupTypeID: TGlobalUID): string {
        return this.getGroupCardDescriptor(groupTypeID) ?
            this.getGroupCardDescriptor(groupTypeID).imagePath
            : ''
    }

    getGroupTypeName(groupTypeID: TGlobalUID): string {
        return this.getGroupCardDescriptor(groupTypeID) ?
            this.getGroupCardDescriptor(groupTypeID).title
            : ''
    }

    public handlerMoreOptionsSelect(option: Serializable, group: Group, updateAnnotations: Function = () => {}): void {
        switch (option.getPrimaryID()) {
            case EGroupType.SubGroupEdit:
                this.routerSvc.goToGroup(
                    group.getGroupID(),
                    routeID.features.editSubGroup
                );
                break;
            case EGroupNavigation.options:
                this.bigIconSvc.openFromSerializables(
                    [
                        Serializable.staticFactory(constant.interactionType.annotation.group.archive),
                        Serializable.staticFactory(EChatBtnsVisualElements.Annotate),
                    ],
                    group.getPrimaryID(),
                    (feedback: Feedback) => this.onBigIconOptionClick(group, feedback, updateAnnotations),
                );
                break;

            case EGroupNavigation.createGroup:
                const safeMenuService = new SafeMenuBuilderServices(this.getDataBuilder(group));
                const menuOptions = safeMenuService.getDropDownFeatures(EScreenGroups.Features).map((e) => e.getSerializable());

                if(!isValidArray(menuOptions)) {
                    this.snackSvc.open("Nenhuma opção disponível para esse grupo.", "Fechar", { duration: 4000 });
                    return;
                }

                this.bigIconSvc.openFromSerializables(
                    menuOptions,
                    group.getPrimaryID(),
                    (feedback: Feedback) => this.openGroupCreation(feedback, group),
                );
            break;
            default:
                break;
        }
    }

    private openGroupCreation(serializable: Serializable, group: Group) {
        this.gcreatorSVC.create(
            serializable,
            group,
            this
        );
    }

    onGroupSaved() {}

    private async onBigIconOptionClick(group: Group, feedback: Feedback, updateAnnotations: Function) {

        switch (feedback.getPrimaryID()) {
            case EChatBtnsVisualElements.Annotate:
                this.openAnnotations(
                    group,
                    updateAnnotations,
                );
                break;

            case constant.interactionType.annotation.group.archive:
                /*@TODO warning*/


                const interactiveButtons : IInteractiveButton[] = [
                    <IInteractiveButton>{
                        option: EInteractiveButton.Cancel,
                    },
                    <IInteractiveButton>{
                        option: EInteractiveButton.Yes,
                        color: 'primary'
                    },
                ]
                let message: string = Serializable.getTranslation(gTranslations.fragments.archiveGroup)
                const confirmPromisse = this.warningScreenSvc.showInteractivePrompt({ title: gTranslations.fragments.archiveGroup, message, options: interactiveButtons })


                if (await confirmPromisse === EInteractiveButton.Yes) {
                    this.chatSVC.saveReaction(
                        group.getProjectionID(),
                        constant.interactionType.security.newGroupCreated,
                        feedback.getPrimaryID(),
                        feedback.getInteractionTypeID(),
                        this.session.getPlayerInfo().getParticipantID(group.getPrimaryID(), this.session.getSelectedAvatarID()),
                        group.getPrimaryID()
                    );
                }
            break;
        }
    }

    public openAnnotations(group: Group, onSaveAnnotationCallback: Function): void {
        const annotationData: IAnnotationsModalParameters = {
            type: EAnnotationsType.allSchemas,
            persist: true,
            serializableId: group.getPrimaryID(),
            onSaveAnnotationCallback: () => onSaveAnnotationCallback(),
            clientCallback: undefined
        };

        this.dialogSvc.open<AnnotationsModalComponent, AnnotationsModalHandler>({
            componentRef: AnnotationsModalComponent,
            dataToComponent: { data: new AnnotationsModalHandler(annotationData) },
            hideHeader: true
        });
    }

    private getDataBuilder(group: Group): IDataBuilder {
        const idGroup = group.getPrimaryID();
        return {
            currentGroup: group,
            currentParticipant: this.session.getParticipant(idGroup),
            currentAvatar: this.session.getSelectedAvatar(),
            playerInfo: this.session.getPlayerInfo(),
            idGroup,
            isEmpty: false,
            sessionService: this.session,
        }
    }
}
