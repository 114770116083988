import { TSerializableHeaderQuery, TSerializableHeaderResponse } from "@colmeia/core/src/serializable/header";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { TGlobalUID } from "../business/constant";
import { IGeneralFormAnswer } from "../general-form/general-form-answer";
import { IFormSchema } from "../general-form/general-form-interface";
import { EHTTPMethod } from "../shared-business-rules/connections/endpoint-model";
import { DeepFindValuesOfType } from "../tools/utility-types";
import { IFriendlyMessageJSON } from "./business-interfaces";

export const colmeiaServiceRequestType = {
    authenticate: 'authenticate',
    serializableChecker: 'serializableChecker',
    getSerializableList: 'getSerializableList',
    fbForm: {
        get: 'fbMessenger.getForm',
        fill: 'fbMessenger.fillForm'
    },
    botMock: 'botmock',
    deploy: {
        queryNSer: 'cm.srv.prom.qnser',
        saveWithBackup: 'cm.serv.prom.sv',
        getAllChildrenElements: 'cm.serv.getChildren',
        deleteElements: 'cm.serv.deleteElements',
    },
    embedded: {
        handshake: 'em.handshake',
        sendToken: 'em.sendToken',
        firstForm: 'em.firstForm',
        noFormTrack: 'em.noFormTrack',
        validateToken: 'em.validateToken',
        finishConversation: 'em.finishConversation',
    },
    search: {
        searchOnEnv: 'searchOnEnv'
    },
    nonSerializables: {
        nsListFromEnv: 'nsListFromEnv',
        getGenericFromEnv: 'getGenericFromEnv',
    },
    bi: {
        tableauToken: 'bi.tableauToken',
    }
} as const;

export interface IBasicRequestData {
    url: string;
    method: EHTTPMethod;
}
export interface IRequestExtraURIParams {
    extraUriData?: string
}
export interface IBasicFullRequestObj extends IRequestExtraURIParams {
    body: object,
    headers: object,
    query: object,
}
export interface IBasicFullRequest extends IRequestExtraURIParams {
    body?: string;
    headers?: string;
    query?: string
}

export interface IBasicRequest extends IBasicRequestData, IBasicFullRequest {
}

export interface IBasicRequestWithBodyAndHeaderObject extends IBasicRequestData, IBasicFullRequest {
}

type ColmeiaServiceRequestType = DeepFindValuesOfType<typeof colmeiaServiceRequestType, string>;
export interface IRequestBodyContainer {
    body: string;
}

export interface IBasicRequest extends Partial<IRequestBodyContainer>, IRequestExtraURIParams {
    url: string;
    method: EHTTPMethod;
    headers?: string;
}

export interface IColmeiaRequestAPI {
    requestType: string;
    idSocialContextDestination?: string;
    idAvatarDestination?: string;
    extraPath?: string;
    xColmeiaReferrer?: string;
}

export interface IColmeiaServiceListSerializablesRequest extends IColmeiaRequestAPI {
    serializableIdList: TSerializableHeaderQuery
}
export interface IColmeiaServiceListSerializablesResponse extends IColmeiaResponseAPI {
    serializableList: TSerializableHeaderResponse
}
export interface ISerializableCheck {
    primaryID: TGlobalUID,
    exists: boolean
}

export interface IColmeiaServiceSerializableCheckRequest extends IColmeiaRequestAPI {
    serializableIdList: Array<TGlobalUID>
}

export interface IColmeiaServiceSerializableCheckResponse extends IColmeiaResponseAPI {
    result: Array<ISerializableCheck>
}

export interface IColmeiaServiceAuthenticateRequest extends IColmeiaRequestAPI {
    username: string;
    password: string;
}

export interface IColmeiaServiceAuthenticateResponse extends IColmeiaResponseAPI {
    success: boolean;
    token?: string;
    expire?: number;

}

export interface IColmeiaResponseAPI {
    requestType: string;
    friendly: IFriendlyMessageJSON;
}

export interface IGetFBFormRequest extends IColmeiaRequestAPI {
    idMessage: string;
}

export interface IGetFBFormResponse extends IColmeiaResponseAPI {
    schema: IFormSchema;
    exists: boolean;
}

export interface IFillFBFormRequest extends IColmeiaRequestAPI {
    schema: IGeneralFormAnswer;
}
export interface ISuccessfullStatus {
    success: boolean;
}
export interface IFillFBFormResponse extends IColmeiaResponseAPI, ISuccessfullStatus {

}
