import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { feedbackControlFields, isValidFeedbackName } from '@colmeia/core/src/comm-interfaces/feedback-interfaces';
import {
    IFeedbackDetail,
    IFeedbackDetailResponse,
    TFeedbackDetailArray
} from "@colmeia/core/src/request-interfaces/response-interfaces";
import {ReactDisplayBarHandler} from "../../../../../handlers/react-display-handler";

export interface AvatarReact {
    avatar: string;
}

export interface TFeedbackCursor {
    cursor: string;
    queriedOnce: boolean;
    list: Array<IFeedbackDetail>;
}

export type TFeedbacksHash = {
    [idFeedback: string]: TFeedbackCursor;
}

export interface FeedbackDialogHandlerParameter {
    reactBarHandler: ReactDisplayBarHandler
}

const pseudoAllReactionsID = 'all';

const fakeAllFeedbacksSerializable = {
    primaryID: pseudoAllReactionsID,
    getEmoji() {
        return ``;
    },
    getName() {
        return `TODAS`
    }
};

const sortByFeedbackAmountDescAndAllFeedbacksAsFirst = (a, b) => {
    if (! a.real) {
        return -1;
    }
    if (! b.real) {
        return 1;
    }
    return b.amount - a.amount;
};




export class FeedbackDialogHandler {

    private parameter: FeedbackDialogHandlerParameter;
    private _list;
    private _feedbacks;
    private filter = pseudoAllReactionsID;
    private feedbackCursorHashes: TFeedbacksHash;
    private requestingApi: boolean;

    constructor(parameter: FeedbackDialogHandlerParameter) {
        this.requestingApi = false;
        this.parameter = parameter;
        this._list = [];
        this._feedbacks = [];
        this.feedbackCursorHashes = {
            all: {
                queriedOnce: false,
                cursor: '',
                list: []
            }
        };
    }

    async init() {
        await this.fetchFeedbacks();
    }

    getCurrentFilter() {
        return this.filter;
    }

    getFeedbacks() {
        return this._feedbacks;
    }

    setFilter(filter: string) {
        this.filter = filter;
        if (! this.feedbackCursorHashes[filter].queriedOnce)
            this.fetchFeedbacks();
    }

    getFilteredAvatars() {
        return this.feedbackCursorHashes[this.filter].list;
    }

    async fetchFeedbacks() {
        const filter = this.filter;
        const notQueriedOnceOrHasCursor = (! this.feedbackCursorHashes[filter].queriedOnce || this.feedbackCursorHashes[filter].cursor.length);
        if (!this.requestingApi && notQueriedOnceOrHasCursor) {
            this.requestingApi = true;
            const query = await this.parameter.reactBarHandler.getInteractionDetails(
                this.feedbackCursorHashes[filter].cursor,
                (filter === pseudoAllReactionsID) ? null : filter
            );
            if (! this._feedbacks.length) {
                this.fillFeedbacks((<IFeedbackDetailResponse>query).figures);
            }
            this.updateHashAfterQuery(filter, (<IFeedbackDetailResponse>query).cursor, (<IFeedbackDetailResponse>query).feedbackDetail);
        }
    }

    private fillFeedbacks(feeds: any) {
        this._feedbacks = Object.keys(feeds)
            .concat([pseudoAllReactionsID])
            .filter(isValidFeedbackName)
            .filter(feed => feeds[feed] > 0)
            .map(k => ({
                real: (k !== pseudoAllReactionsID),
                amount: (k === pseudoAllReactionsID) ? feeds[feedbackControlFields.total] : feeds[k],
                serializable: (k === pseudoAllReactionsID) ? fakeAllFeedbacksSerializable : Feedback.staticFactory(k)
            })
            ).sort(sortByFeedbackAmountDescAndAllFeedbacksAsFirst);
        this.generateFeedbackCursorHash();
    }

    private generateFeedbackCursorHash() {
        this._feedbacks.forEach(
            f => {
                this.feedbackCursorHashes[f.serializable.primaryID] = {
                    cursor: '',
                    queriedOnce: false,
                    list: []
                };
            }
        )
    }

    private updateHashAfterQuery(feedbackId: TGlobalUID, cursor: string, results: TFeedbackDetailArray) {
        this.requestingApi = false;
        if (! this.feedbackCursorHashes[feedbackId].queriedOnce)
            this.feedbackCursorHashes[feedbackId].queriedOnce = true;
        this.feedbackCursorHashes[feedbackId].cursor = cursor || ``;
        this.feedbackCursorHashes[feedbackId].list.push(... results);
    }
}
