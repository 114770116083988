import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { getClock } from "@colmeia/core/src/tools/utility";
import { IBillOptions } from '@colmeia/core/src/comm-interfaces/finance-interfaces';
import { DatePickerHandler } from "../../../handlers/date-picker.handler";
import { GroupChatServices } from "../../../services/group-chat.services";
import { IColmeiaDialogComponentData } from "../../dialogs/dialog/dialog.component";
import { IInteractionPreviewerHandler } from "../../../handlers/interaction-previewer-handler";
import { RootComponent } from 'app/components/foundation/root/root.component';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

export interface IConfigureSellableSignature {
    interaction: Interaction | null;
}

@Component({
    selector: 'app-configure-sellable-item',
    templateUrl: './configure-sellable-item.component.html',
    styleUrls: ['./configure-sellable-item.component.scss']
})
export class ConfigureSellableItemComponent extends RootComponent< 
    'configureAsSell' | 'close' | 'amount' | 'price' 
> implements OnInit {
    @ViewChild('closeBtn', {
        static: true
    }) closeBtn: ElementRef;
    private price: string = '0.00';
    sellingInteraction: Interaction;
    public billOptionsChoosing: IBillOptions;

    get sellingPrice(): string {
        return this.price;
    }

    set sellingPrice(raw: string) {
        const nums = raw.match(/\d+/g);
        const num = (nums) ? nums.join('') : '0.00';
        let result: string;
        const str = `${num}`;

        if (str.length > 2) {
            result = (<any>str.substr(0, str.length - 2)) * 1 + '.' + str.substr(str.length - 2);
        } else if (num.length == 2) {
            result = `0.${str}`;
        } else {
            result = `0.0${str}`;
        }
        this.price = result;
        this.billOptionsChoosing.price = result;
    }

    get sellingInstallments(): number {
        return this.billOptionsChoosing.maxInstallment;
    }

    set sellingInstallments(installments: number) {
        this.billOptionsChoosing.maxInstallment = installments;

    }

    get sellingSku(): number {
        return this.billOptionsChoosing.quantityOffered;
    }

    set sellingSku(sku: number) {
        this.billOptionsChoosing.quantityOffered = sku;
    }

    get sellingExpireIn(): number {
        return this.billOptionsChoosing.endOffering;
    }

    set sellingExpireIn(expire: number) {
        this.billOptionsChoosing.endOffering = expire;
    }

    public datePickerHandler: DatePickerHandler = new DatePickerHandler({
        current: getClock(),
        min: getClock(),
        onPick: (time => {
            this.sellingExpireIn = time;
        })
    });

    constructor(
        private groupChatService: GroupChatServices,
        @Inject(MAT_DIALOG_DATA) data: IColmeiaDialogComponentData<IConfigureSellableSignature>,
    ) {
        super({
            configureAsSell: gTranslations.sellable.configureAsSell,
            close: gTranslations.common.close,
            amount: gTranslations.common.amount,
            price: gTranslations.sellable.price,
        });
        this.sellingInteraction = data.getParamsToChildComponent().interaction;
        this.billOptionsChoosing = this.sellingInteraction.getBillableOptions() || this.emptyBillOptions();
        this.price = this.billOptionsChoosing.price;
    }

    ngOnInit() { }

    getInteractionPreviewerHandler(): IInteractionPreviewerHandler {
        return {
            getInteraction: () => this.sellingInteraction,
        }
    }

    isValidInstallmentOption(amount: number) {
        return true;
    }

    emptyBillOptions(): IBillOptions {
        const billOptions: IBillOptions = {
            price: null,
            quantityOffered: null,
            maxInstallment: 1,
            endOffering: getClock(),
        };
        return billOptions;
    }

    configure() {
        this.sellingInteraction.setBillableOptions(this.billOptionsChoosing);
        this.groupChatService.saveInteraction(this.sellingInteraction, null, null);
        (<HTMLInputElement>this.closeBtn.nativeElement).dispatchEvent(new Event('click'));
    }
}
