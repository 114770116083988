import { Injectable } from '@angular/core';
import { MMconstant } from '@colmeia/core/src/multi-media/multimedia-constant';
import {EditedMsgsViewerComponent} from "../components/chat/message-container/message-instance/edited-msgs-viewer/edited-msgs-viewer.component";
import {SharedMsgsViewerComponent} from "../components/chat/message-container/message-instance/shared-msgs-viewer/shared-msgs-viewer.component";
import {
    ISharedMsgsViewerParameter,
    SharedMsgsViewerHandler
} from "../components/chat/message-container/message-instance/shared-msgs-viewer/shared-msgs-viewer.handler";
import {
    EditedMsgsHandler,
    IEditedMsgsParameter
} from "../components/chat/message-container/message-instance/edited-msgs-viewer/edited-msgs-viewer.handler";
import {IParticipantRoleSelectorParameter, ParticipantRoleSelectorHandler} from "./participant-role-selector.handler";
import {
    IStatusIconsParameter,
    StatusIconsHandler
} from "../components/chat/message-container/message-instance/status-icons/status-icons.handler";
import {
    IMultimediaPreviewParameter,
    MultimediaPreviewHandler
} from "../components/chat/message-container/message-instance/multimedia-preview/multimedia-preview.handler";
import {IDeployedFeature} from "../services/controllers-services/tool-bar-controller/tool-bar.service";
import {EHexagonSizes, HandlerHexagonon, IHexagononParameter} from "./hexagono.handler";
import {EColorComponent} from "../model/component/color-component.model";
import {CmModalHandler} from "./cm-modal-handler";
import {FeatureItemDetailsHandler} from "./feature-item-detail-handler";
import { ParticipantRoleSelectorComponent } from 'app/components/foundation/participant-selector/participant-role-selector/participant-role-selector.component';
import { DatePickerHandler, IDatePickerHandlerParameter, EDatePickerColor } from './date-picker.handler';
import { ECmModalState, ICmModalParameter } from 'app/model/cm-modal.model';
import { IFeatureItemDetailsClient } from 'app/model/feature-list.model';
import {IChipAutocompleteParameter} from "../model/chip-autocomplete.model";
import {ChipAutocompleteHandler} from "./chip-autocomplete.handler";


type TModalComponentInstance = typeof EditedMsgsViewerComponent | typeof SharedMsgsViewerComponent | typeof ParticipantRoleSelectorComponent
type TModalComponentHandler = EditedMsgsHandler | SharedMsgsViewerHandler | ParticipantRoleSelectorHandler

@Injectable({
    providedIn: 'root'
})
export class HandlerFactoryService {

    constructor() { }

    getCmModalHandler(
        open: boolean = false,
        instanceProjection: TModalComponentInstance,
        handler:  TModalComponentHandler,
    ): CmModalHandler {
        return this.newCmModal({
            data: handler,
            state: open
                ? ECmModalState.Open
                : ECmModalState.Closed,
            componentProjectionInstance: instanceProjection,
        })
    }

    newStatusIconsHandler(param: IStatusIconsParameter): StatusIconsHandler {
        const defaultParam: IStatusIconsParameter = {
            messageHandlerInstance: null,
            clientInstance: null,
        }
        return new StatusIconsHandler({
            ...defaultParam,
            ...param,
        });
    }

    newParticipantRoleSelectorHandler(param: Partial<IParticipantRoleSelectorParameter>): ParticipantRoleSelectorHandler {
        const defaultParam: IParticipantRoleSelectorParameter = {
            participant: null,
            groupId: null,
            clientInstance: null,
        }
        return new ParticipantRoleSelectorHandler({
            ...defaultParam,
            ...param,
        });
    }
    newSharedMsgsViewer(param: Partial<ISharedMsgsViewerParameter>): SharedMsgsViewerHandler {
        const defaultParam: ISharedMsgsViewerParameter = {
            shares: [],
            clientInstance: null,
        }
        return new SharedMsgsViewerHandler({
            ...defaultParam,
            ...param,
        });
    }

    newEditedMsgs(param: IEditedMsgsParameter): EditedMsgsHandler {
        const defaultParam: IEditedMsgsParameter = {
            messages: [],
            clientInstance: null,
        }
        return new EditedMsgsHandler({
            ...defaultParam,
            ...param,
        });
    }

    newChipAutocomplete(parameter: Partial<IChipAutocompleteParameter>): ChipAutocompleteHandler {
        const defaultParameter: IChipAutocompleteParameter  = {
            clientId: null,
            mutuallyExclusive: false,
            clientInstance: null,
            visible: true,
            selectable: true,
            removable: true,
            addOnBlur: true,
            placeholder: null,
            chosenElements: [],
            allElements: [],
        }

        return new ChipAutocompleteHandler({
            ...defaultParameter,
            ...parameter,
        })
    }

    newCmModal(parameter: Partial<ICmModalParameter>) {
        const defaultParameter: ICmModalParameter = {
            state: ECmModalState.Closed,
            componentProjectionInstance: null,
            settings: {
                panelClass: `customizations-mat-dialog`
            },
            data: null
        };

        return new CmModalHandler({
            ...defaultParameter,
            ...parameter,
        });
    }

    newMultimediaPreview(parameter: IMultimediaPreviewParameter): MultimediaPreviewHandler {
        return new MultimediaPreviewHandler(parameter)
    }

    newFeatureItemDetails(instance: IFeatureItemDetailsClient, feature: IDeployedFeature, omniChannelDefault: boolean = false): FeatureItemDetailsHandler {
        return new FeatureItemDetailsHandler({
            feature,
            clientInstance: instance,
            omniChannelDefault,
        });
    }

    newDatePicker(parameter: Partial<IDatePickerHandlerParameter>): DatePickerHandler {
        const defaultParameter: IDatePickerHandlerParameter = {
            onPick: (time: number) => console.log('newDatePicker.onPick time: ', time),
            colorType: EDatePickerColor.White
        };
        return new DatePickerHandler({
            ...defaultParameter,
            ...parameter
        });
    }

    newHexagon(parameter: Partial<IHexagononParameter>): HandlerHexagonon {
        const defaultParameter: IHexagononParameter = {
            serializable: null,
            color: EColorComponent.White,
            size: EHexagonSizes.xsmd,
            clientCallback: null,
            isSelectionable: true,
            idMultimediaTag: MMconstant.tag.photo,
            multimediaInstance: null,
            forceImage: null,
        };
        return new HandlerHexagonon({
            ...defaultParameter,
            ...parameter
        });
    }
}
