import { EventEmitter, Injectable, Output } from '@angular/core';
import { RoutingService } from './routing.service';
import { RequestBuilderServices } from './request-builder.services';
import { SessionService } from './session.service';
import { ServerCommunicationService } from './server-communication.service';
import { ICampaignServer, TICampaignServerArray, ICampaign, ICampaingActionHeader } from '@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model';
import { routeList, routeID } from 'app/model/routes/route-constants';
import { IInfraParameters } from 'app/model/client-infra-comm';
import { ISaveCampaignRequest, ICampaignsListRequest, ICampaignsListResponse, ICampaingExecutionRequest, ICampaignListRequest, ICampaignListResponse } from '@colmeia/core/src/shared-business-rules/campaigns/campaign-req-resp';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ICampaignExecutionHeader, ICampaignExecutionServer } from '@colmeia/core/src/shared-business-rules/campaigns/capaing-exec-interfaces';
import { ISaveRunCampaignForActiveCallRequest, ISaveRunCampaignForActiveCallResponse } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-req-resp';
import { isValidNumber, isValidRef } from '@colmeia/core/src/tools/utility';
import { Observable, Subject } from 'rxjs';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { DashboardCampaignsActionComponent, IDashboardCampaignsActionDialogData } from 'app/components/dashboard/dashboard-campaigns/dashboard-campaigns-action/dashboard-campaigns-action.component';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';
import { TGeneralFileMetadataArray } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { take } from 'rxjs/operators';

const baseRoute = routeList.dashboard.children.marketing.children.campaigns
const baseRouteExecution = routeList.dashboard.children.marketing.children.sendCampaign

type TColmeiaWindowActionRef = ColmeiaWindowRef<DashboardCampaignsActionComponent>;

@Injectable({
	providedIn: 'root'
})
export class DashboardCampaignsService {

	private _selectedCampaign: ICampaignServer;
	private _selectedCampaignExecution: ICampaignExecutionServer;
	@Output() public setEdit = new EventEmitter<boolean>();
	private _savedCampaign: Subject<ICampaignServer> = new Subject();

	get savedCampaign$(): Observable<ICampaignServer> {
		return this._savedCampaign.asObservable();
	}

	private openedWindows: Map<string, Set<TColmeiaWindowActionRef>> = new Map();

	constructor(
		private routeSvc: RoutingService,
		private rbs: RequestBuilderServices,
		private session: SessionService,
		private api: ServerCommunicationService,
		private colmeiaWindow: ColmeiaWindowService
	) { }

	openActionEdit(
		campaign: ICampaignServer,
		action: ICampaingActionHeader,
		schemas: IFormSchema[],
		files: TGeneralFileMetadataArray,
		index?: number
	) {
		let isEdit = isValidNumber(index, 0);

		let campaignSet = this.openedWindows.get(campaign.idNS);

		if (!campaignSet) {
			campaignSet = new Set<TColmeiaWindowActionRef>();
			this.openedWindows.set(campaign.idNS, campaignSet);
		}

		const windowRef = this.colmeiaWindow.open<
			DashboardCampaignsActionComponent,
			IDashboardCampaignsActionDialogData
		>(DashboardCampaignsActionComponent, {
			data: {
				action,
				campaign,
				schemas,
				files,
			},
			width: "90vw",
			maxWidth: "1600px",
			panelClass: "no-bottom-padding",
			title: isEdit ? action.actionName : "Nova Ação",
			group: campaign.nName,
			windowIdentifier: action.idCampaigAction,
		});

		campaignSet.add(windowRef);

		windowRef.afterClosed().pipe(take(1)).subscribe(() => {
			campaignSet.delete(windowRef);
		})
	}

	updateActionWindowCampaignRefs(campaign: ICampaignServer) {
		const campaignSet = this.openedWindows.get(campaign.idNS);

		if (!isValidRef(campaignSet)) return;

		for (const windowref of campaignSet) {
			windowref.componentInstance.updateCampaignRef(campaign);
		}
	}

	private buildInfra(spinner: boolean = false): IInfraParameters {
		const playerId: string = this.session.getPlayerID();
		const avatarId: string = this.session.getSelectedAvatarID();

		return spinner
			? this.rbs.getNoCallBackSpinnningParameters(playerId, avatarId)
			: this.rbs.getNoCallBackNoSpinnningParameters(playerId, avatarId)
	}

	async getAllCampaigns(cursor: string = null): Promise<TICampaignServerArray> {
		const infra: IInfraParameters = this.buildInfra();
		const request: ICampaignsListRequest = {
			...this.rbs.createRequestFromInfraParameters(apiRequestType.campaigns.listCampaigns, infra),
			cursor,
		}

		const response = await this.api.managedRequest(infra, request);
		if (response.executionOK) {
			return (<ICampaignsListResponse>response.response).campaigns;
		} else {
			return [];
		}
	}
	async getCampaignByIdAction(idCampaigAction: string, cursor: string = null): Promise<ICampaignServer> {
		const infra: IInfraParameters = this.buildInfra();
		const request: ICampaignListRequest = {
			...this.rbs.createRequestFromInfraParameters(apiRequestType.campaigns.listSingleByAction, infra),
			idCampaigAction,
			cursor,
		}

		const response = await this.api.managedRequest(infra, request);

		if (response.executionOK) {
			return (<ICampaignListResponse>response.response).campaign;
		} else {
			return undefined;
		}
	}


	async saveCampaign(campaign: ICampaign, isNew: boolean = false): Promise<boolean> {
		const infra: IInfraParameters = this.buildInfra(true);
		const request: ISaveCampaignRequest = {
			...this.rbs.createRequestFromInfraParameters(apiRequestType.campaigns.saveCampaing, infra),
			campaign,
			isNew,
		}

		const response = await this.api.managedRequest(infra, request);

		this._savedCampaign.next(campaign as ICampaignServer);

		return response.executionOK;
	}

	public async saveRunCampaignForActiveCall(header: ICampaignExecutionServer): Promise<boolean> {
		const response = await this.api.doRequest<ISaveRunCampaignForActiveCallRequest>(apiRequestType.campaigns.saveRunCampaignForActiveCall, {
			header,
		}) as ISaveRunCampaignForActiveCallResponse

		return isValidRef(response);
	}


	async runCampaign(fireHeader: ICampaignExecutionHeader): Promise<boolean> {
		const infra: IInfraParameters = this.buildInfra();
		const request: ICampaingExecutionRequest = {
			...this.rbs.createRequestFromInfraParameters(apiRequestType.campaigns.runCampaign, infra),
			fireHeader,
		}

		const response = await this.api.managedRequest(infra, request);
		return response.executionOK;
	}


	get selectedCampaign(): ICampaignServer {
		const selectedCampaign = this._selectedCampaign;
		this._selectedCampaign = undefined;
		return selectedCampaign;
	}
	get selectedCampaignExecution(): ICampaignExecutionServer {
		const selectedCampaignExecution = this._selectedCampaignExecution;
		return selectedCampaignExecution;
	}


	goToCreateCampaign(): void {
		this._selectedCampaign = undefined;
		this.routeSvc.navigateToId(
			routeID.dashboard,
			routeList.dashboard.children.marketing.path,
			baseRoute.path,
			baseRoute.children.create
		)
	}
	goToCampaignDetails(campaign: ICampaignServer): void {
		this._selectedCampaign = campaign;
		this.routeSvc.navigateToId(
			routeID.dashboard,
			routeList.dashboard.children.marketing.path,
			baseRoute.path,
			baseRoute.children.edit.path.replace(baseRoute.children.edit.routeParam, campaign.idNS)
		)
	}

	goToSendCampaign(): void {

		this.routeSvc.navigateToId(
			routeID.dashboard,
			routeList.dashboard.children.marketing.path,
			baseRouteExecution.path,
			baseRouteExecution.children.create.path
		);
	}
	goToSendCampaignDetails(campaignExecution: ICampaignExecutionServer): void {
		this._selectedCampaignExecution = campaignExecution;
		this.routeSvc.navigateToId(
			routeID.dashboard,
			routeList.dashboard.children.marketing.path,
			baseRouteExecution.path,
			baseRouteExecution.children.details.path.replace(baseRouteExecution.children.details.routeParam, campaignExecution.idNS)
		);
	}

	async getActionById(actionId: string, cursor: string = null): Promise<ICampaingActionHeader> {
		const campaign = await this.getCampaignByIdAction(actionId, cursor);

		return campaign.campaingActions.find(action => action.idCampaigAction === actionId);
	}



}
