import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { typedScreenOptions } from "../../../tools/utility";
import { deprecatedSuggestions } from "../../../tools/type-utils";

export enum EGroupOrderSelectorMode {
    timeWithoutAnswer = 'timeWithoutAnswer', // new, last | last, new | agent = you, client = others
    lastMessage = 'lastMessage', // new, last | last, new // lastTouch
    startServiceChat = 'incomingOrder', // new, last | last, new // clockTick
    alphabeticOrder = 'alphabeticOrder'
}

const groupOrderSelectorModeScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.GroupOrderSelectorMode,
    fields: deprecatedSuggestions<Record<EGroupOrderSelectorMode, number>>()({
        [EGroupOrderSelectorMode.timeWithoutAnswer]: 1, // Tempo sem resposta
        [EGroupOrderSelectorMode.lastMessage]: 2, // Última mensagem
        [EGroupOrderSelectorMode.startServiceChat]: 3, // Ordem de entrada
        [EGroupOrderSelectorMode.alphabeticOrder]: 4, // Order alfabetica
    }).ok,
} as const);


export const groupOrderSelectorModeTranslations = getTranslationsFields(groupOrderSelectorModeScreenOptions);