
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { ICorporateSearchBodyServer } from '@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ISearchActiveCallResult } from '@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model';
import { EAttendanceHistorySearchType } from '@colmeia/core/src/shared-business-rules/search';
import { ETooltipInfoKey } from '@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { CustomerFinderComponent, TCustomerResultArray } from 'app/components/customer-finder/customer-finder.component';
import { TSResultRecord } from '@colmeia/core/src/shared-business-rules/corporate-search/corporate-search-results-interface';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';

export type TCustomerFinderCSBodyInput = IdDep<ENonSerializableObjectType.corporateSearchBody> | ICorporateSearchBodyServer;

export interface ICustomerFinderAllowedSearchSources {
  standard?: {
    customersWithOpenCaseOnly?: boolean
  };
  infoSquare?: {
    csBodyAlloweds?: TCustomerFinderCSBodyInput[]
  };
}

export interface ICustomerCreationFullfiledFields {
  name?: string,
  email?: string,
  phoneNumber?: string,
  fbId?: string,
  colmeiaId?: string,
  idAvatar?: TGlobalUID
}

export interface IListHasIdCampaignAction {
  listIdCampaignAction: string;
  addContactToListCallback(customer: ISearchActiveCallResult): Promise<void>;
}

export enum ECustomerFinderConsumers {
  ActiveCall = 'DashboardActiveCallInfoComponent',
}

export interface ICustomerFindHandler {
  target?: string;
  maxSelections?: number;
  allowedSearchSources: ICustomerFinderAllowedSearchSources;
  searchTypes?: EAttendanceHistorySearchType[];
  onCustomerSelected?<T extends ISearchActiveCallResult = ISearchActiveCallResult>(items: T[]): void;
  onResult?(items: TCustomerResultArray): void;
  onCustomerCreationRequest?(fulfilledFields: ICustomerCreationFullfiledFields): void;
  helpTipKey?: ETooltipInfoKey;
  enableEditContact?: boolean;
  shouldDisableSearchingMessage?: boolean;
  slave?: CustomerFinderComponent;
  listHasIdCampaignAction?: IListHasIdCampaignAction;
  openedFrom?: ECustomerFinderConsumers;
  isLoading?: () => boolean;
  disableStandardSearchOnInfoSquareClick?: boolean;
  onInfoSquareRowClick?: (record: TSResultRecord, metadata: IFormSchema) => void
}
