import { Injectable } from '@angular/core';
import { Group } from '@colmeia/core/src/business/group';
import { ISearchInfo, TSearchResultArray } from '@colmeia/core/src/comm-interfaces/ds-interfaces';
import { apiRequestType, ESearchRequestTypes } from "@colmeia/core/src/request-interfaces/message-types";
import { IFullTextSearchRequest, ISearchRemoteEnvRequest, ISearchRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IFullTextSearchResponse, ISearchResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { INonSerializableSearcheableContent } from '@colmeia/core/src/shared-business-rules/non-serializable-id/ns-elastic-model';
import { isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { ArrayUtils } from '@colmeia/core/src/tools/utility/arrays/array-utils';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { clientConstants } from "../model/constants/client.constants";
import { RequestBuilderServices } from "./request-builder.services";
import { ServerCommunicationService } from "./server-communication.service";
import { SessionService } from "./session.service";

interface ISearchInput {
    group: Group;
    userInput: ISearchInfo;
    requestModifierCallback?: (request: ISearchRequest) => ISearchRequest
}

export interface ISearchRemoteEnvConfig {
    idNSRemoteEnvAuthProvider: string;
}

/**
 * Serves search functions, local and server request
 */
@Injectable()
export class SearchService {
    private searchInput$: BehaviorSubject<ISearchInput>;
    private searchResults$: Observable<TSearchResultArray>;

    idNSRemoteEnvAuthProvider: string;

    public searchRequestingStateSubject: Subject<boolean> = new Subject();

    constructor(
        private session: SessionService,
        private rbs: RequestBuilderServices,
        private server: ServerCommunicationService
    ) {
        this.searchInput$ = new BehaviorSubject<ISearchInput>(<ISearchInput>{ userInput: { text: '' } });
        this.searchResults$ = new Observable<TSearchResultArray>();
    }

    async fullTextSearch(searchInfo: ISearchInfo): Promise<{
        map: Map<ENonSerializableObjectType, INonSerializableSearcheableContent[]>, 
        resultsTotal: number | undefined
    }> {
        const response: IFullTextSearchResponse | undefined = await this.server.sendRequest<IFullTextSearchRequest, IFullTextSearchResponse>(ESearchRequestTypes.fullTextSearch)({
            data: {
                textToSearch: searchInfo.text
            }
        });
        const groupedResults = ArrayUtils.groupByPropertyName<ENonSerializableObjectType, INonSerializableSearcheableContent>(response?.results || [], 'nsType')
        return {map: groupedResults, resultsTotal: response?.results.length}
    }

    /**
     * Adds user search input to the stream,
     * if group is null a global search is performed
     *
     * @param  {string} userInput
     * @param  {Group=null} group
     * @returns void
     */
    public search(
        userInput: ISearchInfo,
        group: Group = null,
        requestModifierCallback?: (request: ISearchRequest) => ISearchRequest
    ): void {
        this.searchInput$.next({ userInput, group, requestModifierCallback });
    }

    /**
     * gets search results from observable
     * @returns Observable
     */
    public getSearchResults(): Observable<TSearchResultArray> {
        return this.searchInput$.pipe(
            // wait 300ms after each keystroke before considering the term
            debounceTime(this.searchInput$.getValue().userInput.getFirstElementsWithNoTyping ? 0 : clientConstants.searchDelayTimeMS),
            // ignore new term if same as previous term
            distinctUntilChanged(),
            // ignore empty search
            filter((searchInput: ISearchInput) => searchInput.userInput.text.length > 0),
            // switch to new search observable each time the term changes
            switchMap((searchInput: ISearchInput) => {
                return this.searchAll(searchInput)
            })
        );
    }

    /**
     * Search both in server and in client cache,
     * if no groupId is specified the search performed will be global search
     *
     * @param  {TGlobalUID}                     idObjectType    [description]
     * @param  {number}                         idFieldType     [description]
     * @param  {string}                         searchInput     [description]
     * @param  {TSearchFunction}                compareFunction [description]
     * @param  {any}                            aux             [description]
     * @return {Observable<TSerializableArray>}                 [description]
     */
    private searchAll(
        searchInput: ISearchInput,
    ): Observable<TSearchResultArray> {
        // ignore empty search
        if (!searchInput.userInput.getFirstElementsWithNoTyping) {
            searchInput.userInput.text = searchInput.userInput.text.trim();
        }
        if (searchInput.userInput.text.length === 0 && !searchInput.userInput.getFirstElementsWithNoTyping) {
            return of([]);
        }

        // searching both cache and server
        const uniqueResults: TSearchResultArray = [];
        const cacheData$ = of(<TSearchResultArray>[]);
        const serverData$ = this.searchServer(
            searchInput,
        );

        return serverData$;
    }

    /**
     * Performs a search into server
     *
     * @param  {string} searchInput
     * @param  {boolean} isGlobal
     * @returns Observable
     */
    public searchServer(
        searchInput: ISearchInput,
    ): Observable<TSearchResultArray> {
        // building request

        const parameter: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(this.session.getPlayerID(), this.session.getAvatarID());

        const requestType: string = isValidString(this.idNSRemoteEnvAuthProvider)
            ? apiRequestType.searchRemoteEnv
            : apiRequestType.search;

        let requestData: ISearchRequest | ISearchRemoteEnvRequest = {
            ...this.rbs.createRequestFromInfraParameters(requestType, parameter),
            searchInfo: searchInput.userInput,
            idNSRemoteEnvAuthProvider: this.idNSRemoteEnvAuthProvider,
        };

        if (isValidRef(searchInput.requestModifierCallback)) {
            requestData = searchInput.requestModifierCallback(requestData);
        }

        this.searchRequestingStateSubject.next(true);

        // searching for some text on server
        return fromPromise(
            this.server
                .managedRequest(parameter, requestData)
                .then((clientInfra: ClientInfraResponse) => {
                    this.searchRequestingStateSubject.next(false);

                    if (clientInfra.executionOK) {
                        const results: ISearchResponse = <ISearchResponse>clientInfra.response;
                        const searchResults = <TSearchResultArray>results.searchResults;
                        return searchResults;
                    };
                    return [];
                })
        );
    }

    public setRemoteEnv({ idNSRemoteEnvAuthProvider }: ISearchRemoteEnvConfig) {
        this.idNSRemoteEnvAuthProvider = idNSRemoteEnvAuthProvider;
    }

}
