import {
    ErrorGeolocationCallback, IGeolocationError,
    IGeolocationHardwareAbstraction,
    SuccessGeolocationCallback
} from '../hardware-interfaces';
import { EHardwareResourceStatus } from "../cordova/vendor/hardware-status";
import { mergePositionOptionDefaults } from "../hardware.utils";
import { ILocationCoordinates } from "@colmeia/core/src/tools/geo-util";
import { getClock } from "@colmeia/core/src/tools/utility";
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserGeolocation implements IGeolocationHardwareAbstraction {

    setEmergencyMode(active: boolean): void {
        //for now, do nothing...
    }

    private normalizeLocation(coords: GeolocationCoordinates): ILocationCoordinates {
        return {
            ...coords,
            activity: null,
            batery: null,
            powerSaveMode: false,
            uuid: null,
            isMoving: false,
            gpsTimestamp: getClock(),
        }
    }

    private normalizeLocationError(err: GeolocationPositionError): IGeolocationError {
        return {
            message: err.message,
            gpsTimestamp: getClock()
        }
    }

    clearWatchPosition(id: number): void {
        navigator.geolocation.clearWatch(id);
    }

    getPosition(successCallback: SuccessGeolocationCallback, errorCallback: ErrorGeolocationCallback, options: PositionOptions): void {
        navigator.geolocation
            .getCurrentPosition((pos: GeolocationPosition) => {
                successCallback(this.normalizeLocation(pos.coords));
            }, (error: GeolocationPositionError) => {
                errorCallback(this.normalizeLocationError(error));
            }, mergePositionOptionDefaults(options));
    }

    watchPosition(successCallback: SuccessGeolocationCallback, errorCallback: ErrorGeolocationCallback, options?: PositionOptions): number {
        return navigator.geolocation
            .watchPosition((pos: GeolocationPosition) => {
                successCallback(this.normalizeLocation(pos.coords));
            }, (err: GeolocationPositionError) => {
                errorCallback(this.normalizeLocationError(err));
            }, mergePositionOptionDefaults(options));
    }

    isEnabled(): Promise<boolean> {
        return Promise.resolve(('geolocation' in navigator));
    }

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            this.getPosition((pos) => {
                resolve(EHardwareResourceStatus.GRANTED);
            }, (err) => {
                resolve(EHardwareResourceStatus.DENIED);
            }, {});
        });
    }

    isAllowed(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                this.getPosition((position => {
                    resolve(true);
                }), error => {
                    resolve(false);
                }, {});
            } catch (e) {
                resolve(false);
            }
        });
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            this.getPosition((pos) => {
                resolve(EHardwareResourceStatus.GRANTED);
            }, (err) => {
                resolve(EHardwareResourceStatus.DENIED);
            }, {});
        });
    }

    getLastKnownPosition(): ILocationCoordinates {
        return null;
    }

    start() {
        return Promise.resolve();
    }

    stop() {
        return Promise.resolve();
    }
}
