import {
  animate, animateChild, AnimationTriggerMetadata, group, query, state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const colmeiaWindowAnimations: {
  readonly windowContainer: AnimationTriggerMetadata;
} = {
  windowContainer: trigger('windowContainer', [
    state('void, exit', style({ opacity: 0, transform: 'scale(0.7)' })),
    state('enter', style({ transform: 'none' })),
    transition(
      '* => enter',
      group([
        animate('150ms cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'none', opacity: 1 })),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
    transition(
      '* => void, * => exit',
      group([
        animate('75ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ opacity: 0 })),
        query('@*', animateChild(), { optional: true }),
      ]),
    ),
  ]),
};