import { Serializable } from '../../business/serializable';
import { isInEnum } from '../../tools/barrel-tools';
import { metadataNamesTranslations } from '../const-text/views/metadata';
import { EMetadataNames } from './metadata-db';
import { IGeneralMetadata, IMetadataDB } from './metadata-interface';
export type TMessageMetadataArray = Array<MetadataMessage>;


export class MetadataMessage {

    private static dbMetada: IMetadataDB = {};

    private idMetadata: EMetadataNames;
    
    
    private message: string;
    public sanitizer: IGeneralMetadata['sanitizer'];

    constructor(metadata: IGeneralMetadata) {
        this.idMetadata = metadata.idMetadata;
        this.message = Serializable.getTranslation(metadataNamesTranslations[metadata.idMetadata]);
        MetadataMessage.dbMetada[this.idMetadata] = this;
    };

    public getMetadataID(): string {return this.idMetadata; };
    
    public getMetadataName(): string {
        return this.message;
    };

    public getErrorMessage(): string {
        return this.message;
    };


    public static factory(metadata: IGeneralMetadata): MetadataMessage {
        return new MetadataMessage(metadata)
    }

    public static getMessageMetadataArray(): TMessageMetadataArray {
        return Object.values(MetadataMessage.dbMetada)
    }

    public static resetDbMetadata() {
        MetadataMessage.dbMetada = {};
    }

    public static getMeta(idMeta: EMetadataNames): MetadataMessage {
        return MetadataMessage.dbMetada[idMeta]
    }

    public static isMetadataName(item: string): item is EMetadataNames {
        return isInEnum(EMetadataNames, item as EMetadataNames)
    }

};
