<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">
  
  <mat-tab-group class="boxed-tabs boxed-dense tabs-wrap" animationDuration="200ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon mat-18">play_circle_filled</mat-icon>
        Ação
      </ng-template>
      <div class="container">
        <app-new-condition-editor
          *ngIf="evaluatorsOfActions"
          [handler]="evaluatorsOfActions"
          [autoOpenWhenEmpty]="false"
          [autoOpenFirstItem]="true">
        </app-new-condition-editor>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon mat-18">timer</mat-icon>
        Pausa
      </ng-template>
      <div class="flat-box">
        <app-timestamp-picker [handler]="timestampHandler">
        </app-timestamp-picker>
      </div>
    </mat-tab>


  </mat-tab-group>

</cm-generic-dashboard-edit>