import { isValidRef } from "../../../tools/utility";
import { HostNodeFactory } from "../../graph-transaction/host/factory/host-node.factory";
import { NSGenericHost } from "../../graph-transaction/host/ns/ns.host";
import { BasicElement } from "./basic-element";
import { BasicElementInterfaceFactory } from "./basic-element-interface.factory";
import { IBasicElementCreateInfo, IBasicElementFactoryInfoPredicateData, IBasicElementRootAndNodeData, TBasicElementFactoryInfo } from "./basic-element.types";
import { IBasicElementClient } from "./graph-basic-element-interfaces";
import { GraphElement } from "./graph-element";
import { IGraphElementJSON, IPredicateElementJSON } from './graph-interfaces';
import { GraphRoot } from "./graph-root-element";
import { EGraphElementType } from "./graph-types";
import { GraphPredicate } from "./predicate";



export class BasicElementFactory {
    public static createGraphNode(type: EGraphElementType.predicate, info: IBasicElementFactoryInfoPredicateData): GraphPredicate;
    public static createGraphNode(type: EGraphElementType.node, info: IBasicElementRootAndNodeData): GraphElement;
    public static createGraphNode(type: EGraphElementType.root, info: IBasicElementRootAndNodeData): GraphRoot;
    public static createGraphNode(type: EGraphElementType, info: TBasicElementFactoryInfo): BasicElement;

    public static createGraphNode(
        type: EGraphElementType,
        info: TBasicElementFactoryInfo
    ): BasicElement {
        switch (type) {
            case EGraphElementType.node: {
                return GraphElement.create({
                    ...info,
                })
            }
            case EGraphElementType.root: {
                return GraphRoot.create({
                    ...info,
                })
            }

            case EGraphElementType.predicate: {
                return new GraphPredicate(info.graphJSON as IPredicateElementJSON);
            }

            default:
                throw new Error("BasicElementFactory.create type nao implementado!!!");
        }
    }



    /**
     * this is a graphNode creator requires less information(ex: no graphJSON, providing lower coupling with extenal callers)
     * @param type 
     * @param info 
     * @returns 
     */
    public static create(type: EGraphElementType, info: IBasicElementCreateInfo): BasicElement {
        const basicElement: IBasicElementClient = BasicElementInterfaceFactory.create(
            type,
            {
                name: info.name,
                renderData: info.renderData,
                existingBasicElementJSON: info.element,
                idParent: isValidRef(info.graphProcessor)
                    ? info.graphProcessor?.getRootElementId()
                    : info.idParent,
            })

        const nserHost: NSGenericHost = isValidRef(info.hostNser)
            ? HostNodeFactory.create({ bpmType: info.bpmType, ns: info.hostNser })
            : null

        const createInfo = {
            name: basicElement.nName,
            hostObject: nserHost,
            graphJSON: <IGraphElementJSON>{
                ...basicElement.element,
                isExternalElement: info.isExternalElement,
            },
            ruleProcessor: info.graphProcessor,
        }

        const result = BasicElementFactory.createGraphNode(type, createInfo)
        return result
    }
}
