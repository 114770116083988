import { IInteractionJSON } from "../comm-interfaces/interaction-interfaces";
import { isValidRef } from "../tools/utility";
import { UserDefaultSettings } from "../business/barrel-business";

export type TInteractionCustomSoundHash = {
    [idInteractionType: string]: TSoundOptions;
}

export type TSoundOptions = {
    focused: string;
    notFocused: string;
}

let InteractionCustomSounds: TInteractionCustomSoundHash = {

}

export class NotificationSoundService {

    getInteractionSound(interaction: IInteractionJSON, isFocused: boolean) {
        let customSound = InteractionCustomSounds[interaction.idInteractionType];
        if (isValidRef(customSound)) {
            return isFocused ? customSound.focused : customSound.notFocused;
        }
        return UserDefaultSettings.getDefaultNotificationSound(isFocused);
    }

    getInteractionSoundOptions(interaction: IInteractionJSON): TSoundOptions {
        let customSound = InteractionCustomSounds[interaction.idInteractionType];
        if (isValidRef(customSound)) {
            return customSound;
        }
        return {
            focused: UserDefaultSettings.getDefaultNotificationSound(true),
            notFocused: UserDefaultSettings.getDefaultNotificationSound(false)
        }
    }

}