<div [@hideIterator]="iteratorAnimationState">
  <div *ngIf="!isMobile && isRootIterator && getFakeGroupServiceParameter() as groupServiceParam" class="message__container position-sticky">
    <app-group-service [groupServiceParameter]="groupServiceParam">
      <button *ngIf="canShowAnnotation(groupServiceParam)" (click)="openAnnotations(groupServiceParam)" matTooltip="Anotações" class="status__icon">
        <mat-icon>bookmarks</mat-icon>
      </button>
    </app-group-service>
  </div>

  <div *ngFor="let messageHandler of getInteractionHandlerArray();"
    [class]="getContainerClasses(messageHandler)"
    class="message__container"
  >
    <!-- <app-message-instance [chatBackboneInputData]="chatBackboneInputData" [messageHandler]="messageHandler">
    </app-message-instance> -->

    <ng-container [ngComponentOutlet]="_MessageInstance" [ngComponentOutletInjector]="getInjectorForHandler(messageHandler)"></ng-container>
  </div>
</div>
