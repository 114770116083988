import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";




const annotationsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Annotations,
    fields: {
        noAnnotations: 1, // Não há anotações
        selectTemplate: 2, // Selecione o template
        noTemplate: 3, // Sem template
        user: 4, // Usuário(a)
        seeAnnotation: 5, // Ver anotação
        template: 6, // Template
        list: 7, // Lista
        header: 8, // Anotações
        createTemplate: 9, // Criar template de anotação
        createAnnotation: 10, // Criar anotação
        editAnnotation: 11, // Editar anotação
        typeLabel: 12, // Selecione o tipo
        saveSuccess: 13, // A anotação foi salva
        saveError: 14, // Ocorreu um erro ao salvar a anotação
        typeYourAnnotation: 15, // Digite sua anotação
    }
});

export const annotationsTranslations = getTranslationsFields(annotationsScreenOptions);