import { NonSerializableClass } from "./non-serializable-class";
import { INonserializableSchemaResponseServer, IFormSchema } from "../general-form/general-form-interface";

export class NonSerializableClassForm extends NonSerializableClass<INonserializableSchemaResponseServer> {

    private schema: IFormSchema

    protected constructor (ns: INonserializableSchemaResponseServer) {
        super(ns);
        this.schema = ns.schemma
    }


    public getSchema(): IFormSchema {return this.schema}


    public static getNonSerializableForm(nonSerializable: INonserializableSchemaResponseServer):  NonSerializableClassForm{
        const n = new NonSerializableClassForm(nonSerializable);
        n.rehydrate(nonSerializable);
        return n;
    }




}