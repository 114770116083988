import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { IGeneralFileMetadata } from "@colmeia/core/src/request-interfaces/files-interfaces";

export enum EDatabaseProcessOperation {
    create = 'create',
    delete = 'delete',
    download = 'download',
    upsert = 'upsert'
}

export interface IDashboardIntegrationClient {
    onRefreshFile(): Promise<void>;
}

export interface IDashboardIntegrationParameters extends IComponentParameter {
    type: EDatabaseProcessOperation
    currentFile?: IGeneralFileMetadata;
    clientCallback: IDashboardIntegrationClient;
}

export class DashboardIntegrationHandler extends MainHandler {
    private constructor(parameter: IDashboardIntegrationParameters) { 
        super(parameter) 
    }
    public static factory(parameter: IDashboardIntegrationParameters) { return new DashboardIntegrationHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IDashboardIntegrationParameters }
    setType(type: EDatabaseProcessOperation) {
        (<IDashboardIntegrationParameters>super.getComponentParameter()).type = type
    }
}