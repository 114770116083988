
<h1 mat-dialog-title [class.with-icon]="handler.matIcon">
  <mat-icon *ngIf="handler.matIcon" [color]="handler.matIcon.color" [style.color]="handler.matIcon.color">{{ handler.matIcon.icon }}</mat-icon>
  {{ handler.title }}
</h1>
<mat-dialog-content class="mat-typography">
  <p><app-bbcode-viewer style="color: rgba(var(--foreground-color-rgb), .9);" [text]="handler.message"></app-bbcode-viewer></p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ng-container *ngFor="let button of buttonsToShow">
    <ng-container *ngIf="button.enableQuestion">
      <mat-form-field class="enable-question standard-form-field" [style.width.px]="button.enableQuestion.width">
        <mat-label>{{ button.enableQuestion.prompt }}</mat-label>
        <input matInput [(ngModel)]="button.enableQuestion.typedValue">
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="button.style === 'flat' || !button.style || button.style !== 'stroked'">
      <pre>{{button.style | json}}</pre>
      <button
        mat-flat-button
        class="button-w-icon"
        [color]="button.color"
        (click)="buttonPressed(button)"
        [disabled]="isDisabled(button)"
        [matTooltip]="button.tooltip"
        style="margin-left: 8px;"
        >
        <div class="action-button">
          <ng-container *ngIf="button.matIcon">
            <mat-icon>{{ button.matIcon }}</mat-icon>&nbsp;
          </ng-container>
          {{button.text}}
        </div>
      </button>

    </ng-container>

    <ng-container *ngIf="button.style === 'outline' || button.style === 'stroked'">
      <button
      mat-stroked-button
      class="button-w-icon "
      [color]="button.color"
      (click)="buttonPressed(button)"
      [disabled]="isDisabled(button)"
      [matTooltip]="button.tooltip"
      style="margin-left: 8px;"
      >
        <div class="action-button">
          <ng-container *ngIf="button.matIcon">
            <mat-icon>{{ button.matIcon }}</mat-icon>&nbsp;
          </ng-container>
          {{button.text}}
        </div>
    </button>
    </ng-container>

  </ng-container>
</mat-dialog-actions>
