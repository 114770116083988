import { Injectable } from '@angular/core';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { Group } from '@colmeia/core/src/business/group';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { EChatBtnsVisualElements } from '@colmeia/core/src/core-constants/constant-visual-elements.enums';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { GpsMessageService } from './gps-message/gps-message.service';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { NotificationMessageService } from "./notification-message/notification-message.service";
import { GroupServiceMenuItemsService } from './group-service/group-service-menu-items.service';
import { ChatBackboneModel } from "../../../../model/chat-backbone.model";
import { SessionService } from "../../../../services/session.service";
import { GroupChatServices } from "../../../../services/group-chat.services";
import { SignalPublisherService } from "../../../../services/signal/signal-publisher";
import { FeatureStateEmissorService } from "../../../../services/features-emissor.service";
import { ColmeiaDialogService, IColmeiadialogServiceParams } from "../../../../services/dialog/dialog.service";
import { BigIconService } from "../../../../services/big-icon.service";
import { NShareSignal } from "../../../../model/signal/share-signal";
import {
    ConfigureSellableItemComponent,
    IConfigureSellableSignature
} from "../../../billing/configure-sellable-item/configure-sellable-item.component";
import { AnnotationsService } from 'app/services/annotations.service';
import { nop } from '@colmeia/core/src/tools/utility';
import { GlobalWarningService, IInteractiveButton, EInteractiveButton } from 'app/services/global-warning.service';
import { AnnotationsModalHandler, EAnnotationsType, IAnnotationsModalParameters } from 'app/handlers/annotations-modal-handler';
import { FeatureCarrierService } from './feature-carrier/feature-carrier.service';
import { EMessageStandard, EGeolocationIntType, EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import { deleteInteraction } from '@colmeia/core/src/shared-business-rules/visual-constants';
import { ITranslationConfig } from '@colmeia/core/src/shared-business-rules/translation/translation-engine';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

export interface IMessageInstanceSelectChosenOption {
    interactionClicked: Interaction,
    optionSelected: Serializable
}

export interface IMsgInstanceEditorStarter {
    onMsgHighLightStarted(): void;
    getInstanceID(): TGlobalUID;
}

export interface IMsgInstanceEditorEnder {
    onMsgHighLightComplete(): void;
}

type TMsgInstanceEditorOwner = IMsgInstanceEditorStarter

export interface IMessageInstanceSelectChosenOptionStandardMsg extends IMessageInstanceSelectChosenOption {
    currentGroup: Group;
    backBoneModel: ChatBackboneModel,
    clientInstance: TMsgInstanceEditorOwner;
}

type TMsgInstanceChatBtnsID = EChatBtnsVisualElements.Feedbacks
    | EChatBtnsVisualElements.Edit
    | EChatBtnsVisualElements.Reply
    | EChatBtnsVisualElements.Remove
    | EChatBtnsVisualElements.Share
    | EChatBtnsVisualElements.Sell
    | EChatBtnsVisualElements.Annotate
    | EChatBtnsVisualElements.BigIconOptions
    | EChatBtnsVisualElements.RememberMeReply
    ;

@Injectable(
//     {
//     providedIn: 'root'
// }
)
export class MessageInstanceService {
    constructor(
        private sessionSvc: SessionService,
        private chatSvc: GroupChatServices,
        private signalPublisherSvc: SignalPublisherService,
        private featuresSvc: FeatureStateEmissorService,
        private colmeiaDialogSvc: ColmeiaDialogService,
        private groupChatSvc: GroupChatServices,
        private featureCarriersvc: FeatureCarrierService,
        private gpsMessageService: GpsMessageService,
        private notificationMessageService: NotificationMessageService,
        private bigIcon: BigIconService,
        private groupServiceMenuItemSvc: GroupServiceMenuItemsService,
        private annotationsSvc: AnnotationsService,
        private warningScreenSvc: GlobalWarningService,
    ) { }

    handleSelectedOptionClicked(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg, ignoreUID: TGlobalUID): void {
        this.handleClickByButtonID(chosenOption, ignoreUID);
        this.handleClickByinteractionType(chosenOption, ignoreUID);
        this.handleByFeedback(chosenOption, ignoreUID);
    }

    handleByFeedback(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg, ignoreUID: TGlobalUID): any {
        const optionClicked: Serializable = chosenOption.optionSelected
        const interactionClicked: Interaction = chosenOption.interactionClicked
        const isOptionClickedFeedback: boolean = optionClicked.getObjectTypeID() === constant.objectType.feedback
        if (isOptionClickedFeedback) {
            const feedback: Feedback = <Feedback>optionClicked
            const feedbackInteractionType: InteractionType = feedback.getInteractionType()
            this.sendReactionToServer(
                interactionClicked,
                feedbackInteractionType,
                feedback)
        }
    }

    handleClickByButtonID(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg, ignoreUID: TGlobalUID): void {
        const optionClicked: Serializable = chosenOption.optionSelected
        const interactionClicked: Interaction = chosenOption.interactionClicked
        const clickedButtonID: TMsgInstanceChatBtnsID = <TMsgInstanceChatBtnsID>optionClicked.getPrimaryID()
        switch (clickedButtonID) {
            case EChatBtnsVisualElements.Sell:
                this.handleSellItem(chosenOption);
                break;

            case EChatBtnsVisualElements.Edit:
                this.signalPublisherSvc.emitUserExclusiveAction(chosenOption.clientInstance.getInstanceID());
                chosenOption.clientInstance.onMsgHighLightStarted();
                this.featuresSvc.editChatBarFeature(
                    chosenOption.interactionClicked,
                );
                break;

            case EChatBtnsVisualElements.Reply:
                this.signalPublisherSvc.emitUserExclusiveAction(chosenOption.interactionClicked.getPrimaryID());
                chosenOption.clientInstance.onMsgHighLightStarted();
                this.featuresSvc.replyChatBar(
                    chosenOption.interactionClicked
                );
                break;

            case EChatBtnsVisualElements.Share:
                this.signalPublisherSvc.specificSignalEmissorOnGenericStream(
                    new NShareSignal.ShareSignal({
                        resource: interactionClicked,
                        currentGroupID: this.sessionSvc.getSelectedGroupID()
                    })
                );
                break;

            case EChatBtnsVisualElements.Remove:
                this.deleteInteraction(interactionClicked);
                break;

            case EChatBtnsVisualElements.Annotate:
                this.annotationsSvc.openFor(
                    new AnnotationsModalHandler({
                        type: EAnnotationsType.allSchemas,
                        persist: true,
                        serializableId: interactionClicked.getPrimaryID(),
                        onSaveAnnotationCallback: nop,
                        clientCallback: undefined
                    })
                )
                break;

            case EChatBtnsVisualElements.BigIconOptions:
                this.bigIcon.openFromSerializables(
                    [
                        Serializable.staticFactory(EChatBtnsVisualElements.Annotate)
                    ],
                    this.sessionSvc.getSelectedGroupID(),
                    this.onBigIconOptionClick.bind(this, interactionClicked)
                )
                break;

            case EChatBtnsVisualElements.RememberMeReply:
                this.signalPublisherSvc.emitUserExclusiveAction(chosenOption.interactionClicked.getPrimaryID());
                chosenOption.clientInstance.onMsgHighLightStarted();
                this.featuresSvc.replyChatBar(
                    chosenOption.interactionClicked,
                    true,
                );
                break;
            default:
                break;
        }
    }

    onBigIconOptionClick(interactionClicked: Interaction, serializable: Serializable) {
        switch (serializable.getPrimaryID()) {
            case EChatBtnsVisualElements.Annotate:
                const annotationData: IAnnotationsModalParameters = {
                    type: EAnnotationsType.allSchemas,
                    persist: true,
                    serializableId: interactionClicked.getPrimaryID(),
                    onSaveAnnotationCallback: () => this.annotationsSvc.messageInstanceChangeDetection(interactionClicked, this.sessionSvc.getSelectedGroupID()),
                    clientCallback: undefined
                }
                this.annotationsSvc.openFor(new AnnotationsModalHandler(annotationData));
                break;
        }

    }

    handleClickByinteractionType(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg, ignoreUID: TGlobalUID): void {
        const interactionClicked: Interaction = chosenOption.interactionClicked;
        const interactiontypeID: TGlobalUID = interactionClicked.getInteractionType().getPrimaryID();
        switch (interactiontypeID) {
            case EMessageStandard.message:
            case EMessageStandard.citation:
                this.handleMessageInstanceMessageInstanceOptionClicked(chosenOption);
                break;
            case constant.interactionType.standard.featureCarrier:
                this.featureCarriersvc.onDropdownOptionClicked(chosenOption);
                break;
            case EGeolocationIntType.beginRealtimeTracking:
                this.gpsMessageService.onDropdownOptionClicked(chosenOption);
                break;
            case constant.interactionType.subscription.subscription:
                this.notificationMessageService.handleOptionClicked(chosenOption);
                break;
            case EServiceGroupIntType.startServiceChat:
                this.groupServiceMenuItemSvc.onDropdownOptionClicked(chosenOption);
            default:
                break;
        }
    }

    private handleMessageInstanceMessageInstanceOptionClicked(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg): void {
        const clickedID: TGlobalUID = chosenOption.optionSelected.getPrimaryID()
        switch (clickedID) {
            case EChatBtnsVisualElements.Feedbacks:
                this.handleMessageInstanceFeedbacksClicked(chosenOption)
                break;
            default:
                break;
        }
    }

    private handleSellItem(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg): void {
        const interactionClicked: Interaction = chosenOption.interactionClicked;
        const dialogParams: IColmeiadialogServiceParams<ConfigureSellableItemComponent, IConfigureSellableSignature> = {
            componentRef: ConfigureSellableItemComponent,
            dataToComponent: { data: { interaction: interactionClicked } }
        };
        this.colmeiaDialogSvc.open<ConfigureSellableItemComponent, IConfigureSellableSignature>(dialogParams)
    }

    private handleMessageInstanceFeedbacksClicked(chosenOption: IMessageInstanceSelectChosenOptionStandardMsg): void {
        const onFeedbackSelected = (feedback: Feedback) => {
            this.sendReactionToServer(chosenOption.interactionClicked, feedback.getInteractionType(), feedback);
        };
        this.bigIcon.openFromFeedbackDrivers(
            chosenOption.currentGroup.getDriverFeedbacks(),
            chosenOption.currentGroup,
            onFeedbackSelected
        );
    }

    private async sendReactionToServer(interactionClicked: Interaction, feedbackInteractionType: InteractionType, feedback: Serializable): Promise<void> {
        const selectedGroupID: TGlobalUID = this.sessionSvc.getSelectedGroupID();
        const participantID: TGlobalUID = this.sessionSvc
            .getParticipant(selectedGroupID)
            .getPrimaryID();
        this.groupChatSvc.saveReaction(
            interactionClicked.getPrimaryID(),
            interactionClicked.getInteractionType().getPrimaryID(),
            feedback.getPrimaryID(),
            feedbackInteractionType.getPrimaryID(),
            participantID,
            this.sessionSvc.getSelectedGroupID()
        );
    }

    private async deleteInteraction(interactionClicked: Interaction): Promise<void> {
        if (!(await this.confirmDelete())) return;

        this.chatSvc.deleteInteraction(
            interactionClicked,
            this.sessionSvc.getParticipant(this.sessionSvc.getSelectedGroupID())
        );
    }

    private async confirmDelete(): Promise<boolean> {
        const interactiveButtons : IInteractiveButton[] = [
            <IInteractiveButton>{
                option: EInteractiveButton.Cancel,
            },
            <IInteractiveButton>{
                option: EInteractiveButton.Remove,
                color: 'warn'
            }
        ]

        const message: string = Serializable.getTranslation(gTranslations.fragments.askDeleteInteraction)
        const confirmPromisse = await this.warningScreenSvc.showInteractivePrompt({ title: gTranslations.fragments.delete, message, options: interactiveButtons })

        return confirmPromisse === EInteractiveButton.Remove;
    }

    public isAttending(): boolean {
        return this.chatSvc.isAttending();
    }

}
