import * as Joi from 'joi';
import { AfterNarrow, Assert, Compute, GetByKey, HasKey, IObjectLiteralMetadata, IsEmpty, IsNever, ObjectLiteralMetadata, TFunction } from "../../../tools/utility-types";
import { $$ } from "../../../tools/utility/types/error";
import { $Narrow } from '../../../tools/utility/types/validate';

export type MapJoiSchema<T, Information extends {} = {}> = {
    [key in keyof T]:
        T[key] extends TFunction ?
            <Source extends Parameters<T[key]>>(...source: Source) => (MapJoiSchema<ReturnType<T[key]>, Compute<Information & { [name in key]: Source }>> )
        : T[key]
}

type MapJoi<T> = {
    [key in keyof T]:
        T[key] extends TFunction ? 
        ReturnType<T[key]> extends Joi.AnySchema ?
        () => MapJoiSchema<ReturnType<T[key]>>
        : T[key]
        : T[key]
}

export const $Joi: MapJoi<typeof Joi> = Joi as unknown as MapJoi<typeof Joi>;

type IsEmptyArray<T> = 
    IsEmpty<T> extends false ?
    T extends [] ? false : 
    T extends (infer V)[] ? IsEmpty<V>: false
    : false
;

export type ToJoiSchema<T extends {}, DataMetadata extends IObjectLiteralMetadata = never, Metadata = ObjectLiteralMetadata<T>> = 
    [T] extends [object] ? { [key in keyof T]: ToJoiSchema<T[key], Assert<GetByKey<Metadata, key>, IObjectLiteralMetadata>> } :
    (IsNever<DataMetadata> extends true ? {} : DataMetadata['isOptional'] extends false ? { required: [] } : { optional: [] }) extends infer Information ? 
        [T] extends [string] ? MapJoiSchema<Joi.StringSchema, Information & ([$$.IsEnumValue<T, false>] extends [$$<infer NotEnum>] ? {} : { valid: T[] })> : 
        [T] extends [number] ? MapJoiSchema<Joi.NumberSchema, Information> : 
        [T] extends [boolean] ? MapJoiSchema<Joi.BooleanSchema, Information> : 
        [T] extends [Date] ? MapJoiSchema<Joi.DateSchema, Information> : 
        [T] extends [TFunction] ? MapJoiSchema<Joi.FunctionSchema, Information> : 
    never
    : never
;

type AssignMappedJoiSchema<T extends MapJoiSchema<Joi.AnySchema, unknown>> = 
    T extends MapJoiSchema<infer Schema, infer Information> ?
        T & { info?: Information }
    : never
;
type DeepAssignMappedJoiSchema<T extends object> = 
    // @ts-expect-error
    T extends object ? { [key in keyof T]: AssignMappedJoiSchema<T[key]> } : never
;

export function signJoiValidators<T extends { [key in string]: MapJoiSchema<Joi.AnySchema, unknown> }>(source: T): DeepAssignMappedJoiSchema<T> {
    // @ts-expect-error
    return source;
}

export type ParseSchema<T> = DeepAssignMappedJoiSchema<ToJoiSchema<T>>;

export type SimpleValidateJoi<T, Source, ToSchema = ToJoiSchema<T>> =
    Source extends object ?
    { [key in keyof ToSchema]:
        key extends string ?
        GetByKey<Source, key> extends MapJoiSchema<infer VSchema, infer VInformation> ?
        GetByKey<ToSchema, key> extends MapJoiSchema<infer TSchema, infer TInformation> ? 
        VSchema extends TSchema ?
        {
            [parameter in keyof TInformation]:
            parameter extends string & keyof VInformation ?
                IsEmptyArray<VInformation[parameter]> extends true ? $$<[`Value in '${parameter}' must not be an empty array`]> : 
                HasKey<VInformation, parameter> extends false ? $$<`Missing '${parameter}' parameter`> : 
                VInformation[parameter] extends TInformation[parameter] ? unknown : $$<['Wrong', parameter, 'parameter']>
            : never
        }
        : $$<['Schema must be ', TSchema]>
        : never
        : never
        : never
    }
    : unknown
;

export function simpleValidateJoi<T>() {
    type ToSchema = ToJoiSchema<T>;
    return <Source>(source:
        & $Narrow<Source, ToSchema>
        & AfterNarrow<
            SimpleValidateJoi<T, Source, ToSchema>
        >
        ): Source => {
        return source as unknown as Source;
    }
}
