import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { IWhatsAppTemplateApproval } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { ISocialMediaConnectionServer } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { TDeepMap } from "@colmeia/core/src/tools/utility-types";

export interface IWhatsappTemplateApprovalParameters extends IComponentParameter {
    approval: IWhatsAppTemplateApproval;
    clientCallback: {
        submitTemplate?(idConnection: string): Promise<boolean>;
        refresh?(): void;
        refreshTemplate?(): Promise<void>;
        close?(): void;
        onDeleteProvider?(idProvider: string): void;
        onChangeName?(): void;
    }
    idConnection?: string;
    name?: string;
    mapConnections?: TDeepMap<[idProvider: string, connections: ISocialMediaConnectionServer[]]>;
    differences?: string;
}

const defaultParameters: Partial<IWhatsappTemplateApprovalParameters> = {
    
};

export class WhatsappTemplateApprovalHandler extends MainHandler {
    private constructor(parameter: IWhatsappTemplateApprovalParameters) { defaultFields(parameter, defaultParameters); super(parameter) }
    public static factory(parameter: IWhatsappTemplateApprovalParameters) { return new WhatsappTemplateApprovalHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IWhatsappTemplateApprovalParameters }
}