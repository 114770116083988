

import { validateNonserializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/validate-nser";
import { createCache, deepUnique, formatMessage, getIgnoringArray, ifTrueExecute, isInvalid, isValidRef, MapBy, mapKeys, mapValues, recursiveKeys, recursiveValues, swap } from "@colmeia/core/src/tools/utility";
import { cast } from "@colmeia/core/src/tools/utility/functions/cast";
import { makePseudoDifferentFieldPredicate, makeSearchPredicate } from "@colmeia/core/src/tools/utility/functions/makeSearchPredicate";
import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { DefineDiscriminatedMap } from "@colmeia/core/src/tools/utility/types";
import { CNarrow } from "@colmeia/core/src/tools/utility/types/validate";
import * as _ from "lodash";
import { AfterNarrow, Compute, DeepPartial, IsEqual, isPrimitive, MetaGetDeepTypedProperty, Nullish, Primitive } from "../../../tools/utility-types";
import type { Merger } from "../../../tools/utility/types/entities/merger";
import { ENonSerializableObjectType, INonSerializable } from "../../non-serializable-id/non-serializable-id-interfaces";
import type { NsTypeToInterface } from "../../non-serializable-id/non-serializable-interface-mapper";
import { IdDep } from "../../non-serializable-id/non-serializable-types";
import { notImplementedSharedFunction as notImplementedSharedMethod } from "../shared-services.basic-functions";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { ObjectUtils } from "@colmeia/core/src/tools/utility/objects/object-utils";
import { MS } from "@colmeia/core/src/time/time-utl";


export type GetIdNS<T extends INonSerializable> = NonNullable<T['idNS']>;

export type GetNS<T extends ENonSerializableObjectType> = [IsEqual<T, ENonSerializableObjectType>] extends [true] ? INonSerializable : NsTypeToInterface[T];

export type GetNSWithOperators<T extends ENonSerializableObjectType, NS extends INonSerializable = GetNS<T>, Out = Omit<MetaGetDeepTypedProperty.MapDeepTypedProperty<NS, { IsPreservingValues: true; IsIgnoringArray: true }>, 'nsType'>, PartialOut = Out> = DeepPartial<{
    [key in keyof PartialOut]: ValueOrOperator<PartialOut[key]>
}>

export type ValueOrOperator<T> = T | IDefinedOperatorInput<T>

// 


export type ISearchNSInput<
    T extends ENonSerializableObjectType = ENonSerializableObjectType,
    InputNS extends INonSerializable = GetNS<T>,
    NS extends GetNSWithOperators<T, InputNS> = GetNSWithOperators<T, InputNS>
> = Merger<Partial<NS>> & {
    nsType?: ValueOrOperator<T>;
    cursor?: string;
    
    limit?: number;
    idSN?: string;
    keysOnly?: boolean;
}

export interface ISearchNSInputKeys<
    T extends ENonSerializableObjectType = ENonSerializableObjectType,
    InputNS extends INonSerializable = GetNS<T>,
    NS extends GetNSWithOperators<T, InputNS> = GetNSWithOperators<T, InputNS>
> extends ISearchNSInput<T, InputNS, NS> {
    keysOnly: true;
}


export type ISearchNSInputIterator<T extends ENonSerializableObjectType = ENonSerializableObjectType, NS extends INonSerializable = GetNS<T>> = ISearchNSInput<T, NS> & {
    maxLimit?: number;
    limitPerIteration?: number;
}



export type ISearchNSCustom<NS extends INonSerializable> = ISearchNSInput<NS['nsType'], NS>

export interface ISearchNSOutput<T extends ENonSerializableObjectType = ENonSerializableObjectType, NS = GetNS<T>> {
    nss: NS[];
    cursor?: string;
}

export interface ISearchNSOutputCustom<NS extends INonSerializable> extends ISearchNSOutput<NS['nsType'], NS> {
}

export const PREDICATE_OPERATOR_ID = '6333fca1cfc1a6b95e408967';
type TCoreFilterOperator = "=" | "<" | ">" | "<=" | ">=" | "HAS_ANCESTOR" | "!=" | "IN" | "NOT_IN";


export namespace NSSharedServiceInsert {
    export interface Input<NSType extends ENonSerializableObjectType, NS extends GetNS<NSType> = GetNS<NSType>> {
        ns: NS;
        nsType: NSType;
    }

    export interface Output<NSType extends ENonSerializableObjectType, NS extends GetNS<NSType> = GetNS<NSType>> {
        isSuccess: boolean;
        ns: NS;
    }

}



class Service {
    /**
     * Executes a search on entities
     * @example 
     * const { nss: ns } = await NSSharedService.search({
     *     nsType: ENonSerializableObjectType.bot,
     *     cursor: '',
     *     limit: 2,
     *     idSN: '',
     *     ident: {
     *         genealogy: '[]',
     *         idAvatar: '',
     *         idGroup: '',
     *     },
     *     botLevel: ENextGenBotElementType.atendanceIsland,
     * });
     */
    public async search<T extends ENonSerializableObjectType, NS extends GetNS<T> = GetNS<T>>(input: ISearchNSInput<T>): Promise<ISearchNSOutput<T, NS>>
    public async search<NS extends INonSerializable>(input: ISearchNSCustom<NS>): Promise<ISearchNSOutputCustom<NS>>
    public async search<T extends ENonSerializableObjectType, NS extends GetNS<T>>(input: ISearchNSInput<T>): Promise<ISearchNSOutput<T, NS>> {
        notImplementedSharedMethod(input);
    }

    public async getByIds<T extends ENonSerializableObjectType, NS extends GetNS<T>>(ids: IdDep<T>[]): Promise<NS[]>
    public async getByIds<NS extends INonSerializable>(ids: string[]): Promise<NS[]>
    public async getByIds(ids: string[]): Promise<INonSerializable[]>
    public async getByIds(ids: string[]): Promise<INonSerializable[]> {
        notImplementedSharedMethod(ids);
    }

    public async getById<T extends ENonSerializableObjectType, NS extends GetNS<T>>(idNS: IdDep<T>): Promise<NS | undefined>
    public async getById<T extends ENonSerializableObjectType>(idNS: IdDep<T>): Promise<GetNS<T> | undefined>
    public async getById<NS extends INonSerializable>(idNS: string): Promise<NS | undefined>
    public async getById(idNS: string): Promise<INonSerializable | undefined> {
        notImplementedSharedMethod(idNS);
    }

    public async insert<NS extends GetNS<NSType>, NSType extends ENonSerializableObjectType>(input: NSSharedServiceInsert.Input<NSType, NS>): Promise<NSSharedServiceInsert.Output<NSType, NS>> {
        notImplementedSharedMethod(input);
    }
}


export interface NSCacheConfig {
    timeout: MS;
}

@ToStatic
export class NSSharedService extends toStatic(Service) {
    static get getNS() { return NSSharedService.getById }
    static get getNSs() { return NSSharedService.getByIds }

    /**
     * Executes a recursive search on entities
     * @example 
     * NSSharedService.recursiveSearch(
     *     {
     *         idSN: '',
     *         limit: 200,
     *         nsType: ENonSerializableObjectType.bot,
     *         ident: {
     *             genealogy: '[]',
     *             idAvatar: '',
     *             idGroup: '',
     *         },
     *         botLevel: ENextGenBotElementType.atendanceIsland,
     *     },
     *     async (nss, cursor) => nss
     * );
     */
    public static async recursiveSearch<T extends ENonSerializableObjectType>(input: ISearchNSInput<T>, fn: (nss: GetNS<T>[], cursor?: string) => Promise<unknown>): Promise<void> {
        const output = await NSSharedService.search(input);
        await Promise.all([fn(output.nss, output.cursor), output.cursor && NSSharedService.recursiveSearch({ ...input, cursor: output.cursor }, fn)])
    }

    public static createCache(config: NSCacheConfig) {
        return createCache<IdDep, INonSerializable>({
            mapper: entity => entity.idNS!,
            searcher: NSSharedService.getNSs,
            ...config,
        });
    }

    public static it<NS extends GetNS<T>, T extends ENonSerializableObjectType = NS['nsType']>(input: ISearchNSInputIterator<T, NS>): AsyncGenerator<NS, void>;
    public static it<T extends ENonSerializableObjectType>(input: ISearchNSInputIterator<T>): AsyncGenerator<GetNS<T>, void>;
    public static async* it<T extends ENonSerializableObjectType>(input: ISearchNSInputIterator<T>): AsyncGenerator<GetNS<T>, void> {
        let output: ISearchNSOutput<T, GetNS<T>> | undefined;
        const { maxLimit, limitPerIteration, ...search } = input;
        const { limit = limitPerIteration } = input;
        let amount = 0;
        do {
            if (isValidRef(maxLimit) && isValidRef(limit)) {
                const remaining = (maxLimit - amount);
                if (!remaining) return;
                const target = remaining > limit ? limit : remaining;
                search.limit = target;
            }
            output = await NSSharedService.search(search);
            search.cursor = output.cursor;
            amount += output.nss.length;
            for (const ns of output.nss) yield ns;
        } while (output?.cursor);
    }

    public static validate(ns: INonSerializable, nsType: ENonSerializableObjectType = ns.nsType): void {
        const friendly = validateNonserializable(ns, nsType);
        const [error] = friendly.getReturnErrorTranslations();
        if (!error) return;
        const text = Serializable.getTranslation(error);
        if (!text) return;
        GenericSharedService.throw(!error.additionalValue ? text : `${text}${error.additionalValue}`);
    }

    static async getAutomaticDependencies(ns: INonSerializable) {
        const ids = recursiveValues(ns)
            .filter(isString)
            .filter(id => id !== ns.idNS)
        ;
        const nss = await NSSharedService.getNSs(ids);
        const mapNSs = MapBy.factory(nss).by(item => [item.idNS!, item]).value();
        const paths = ObjectUtils.getAllValuePathsInObject(ns, nss.map(item => item.idNS));
        
        const swappedENonSerializableObjectType = swap(ENonSerializableObjectType)
        const items = paths
            .map(path => ({ path }))
            .map(add('id', (item): string => _.get(ns, item.path)))
            .map(add('ns', item => mapNSs.get(item.id)!))
            .filter(item => item.ns.nsType !== ENonSerializableObjectType.colmeiaTags)
            
        ;
        const output = items
            .map(item => ({ ...item }))
            .map(add('text', item => `${item.path}: IdDep<ENonSerializableObjectType.${swappedENonSerializableObjectType[item.ns.nsType] as string}>` as const))
        ;
        const ident = ' '.repeat(4);
        const text = `{\n${output.map(item => item.text).map(item => ident + item).join('\n')}\n}`;
        return {
            text,
            items,
        };
        // 
    }

}

function add<Name extends string, T extends object, U>(name: Name, fn: (item: T, index: number, items: T[]) => U) {
    return execute;
    function execute(item: T, index: number, items: T[]): Compute<T & { [key in Name]: U }>;
    function execute(item: T, index: number, items: T[]): T {
        Reflect.set(item, name, fn(item, index, items));
        return item;
    }
}

function isString(item: unknown): item is string {
    return typeof item === 'string';
}

export type IDefinedOperatorValue = IBasicOperatorValue | IBasicOperatorValue[];
export type IBasicOperatorValue = string | number | boolean;
export class IDefinedOperatorInput<Value = IDefinedOperatorValue> {
    private [PREDICATE_OPERATOR_ID]!: IDefinedOperator<Value>;
    constructor(content: IDefinedOperator<Value>) {
        this[PREDICATE_OPERATOR_ID] = content;
    }
    private setOrder(order: number) {
        this[PREDICATE_OPERATOR_ID].order = order;
    }
    static addOrder<Fn extends {
        (input?: IDefinedOperatorInput<Value>): IDefinedOperatorInput<Value>;
    }, Value = any>(order: number): Fn {
        function execute(input?: IDefinedOperatorInput<any>): IDefinedOperatorInput<any> {
            input?.setOrder(order);
            return input ?? new IDefinedOperatorInput({
                operator: undefined,
                value: undefined,
                order,
            });
        }
        return cast(execute)
    }
    static createBackup() {
        // getBackupSearch
    }
}


export interface TAdvancedPredicate {
    predicate: IDefinedOperatorValue,
    operator: TCoreFilterOperator,
    property: string,
}
export interface TLocalPredicate {
    predicate: IDefinedOperatorValue,
    operator: TCoreFilterOperator,
    property: string,
}


export interface TLocalPredicateWithDefinedOperators extends TLocalPredicate {
    definedOperator?: IDefinedOperator<unknown, EDefinedOperatorType>
}

export function getPredicateOperators<T extends { [key in string]: any }>(source: { [key in keyof T]?: T[key] | (T[key] extends Primitive ? IDefinedOperatorInput<T[key]> : never) }) {
    const predicates = recursiveKeys(source, (item, key) => isDefinedOperator(item))
        .map((property): TLocalPredicate => ({ property: property as Extract<typeof property, string>, operator: '=' as const, predicate: _.get(source, property) as IDefinedOperatorValue }))
        .map((item): TLocalPredicateWithDefinedOperators => ({ ...item, definedOperator: isDefinedOperator(item.predicate) ? getDefinedOperator(item.predicate) : undefined }))
    ;
    return predicates;
}

export function createDirectlyPredicatesV2<T extends { [key in string]: any }>(source: { [key in keyof T]?: T[key] | (T[key] extends Primitive ? IDefinedOperatorInput<T[key]> : never) }) {
    const predicates = recursiveKeys(source, (item, key) => isDefinedOperator(item))
        .map((property): TLocalPredicate => ({ property: property as Extract<typeof property, string>, operator: '=' as const, predicate: _.get(source, property) as IDefinedOperatorValue }))
        .map((item): TAdvancedPredicate[] => {
            if (isDefinedOperator(item.predicate)){
                const { property } = item;
                const field = getDefinedOperator(item.predicate);
                if (isInvalid(field.value)) return [];
                const predicate: TAdvancedPredicate = {
                    operator: field.operator,
                    property,
                    predicate: field.value as never,
                }
                if (isSearchOperator(field)) return makeSearchPredicate(predicate);
                if (isDSOperator(EDefinedOperatorType.Different, field)) return makePseudoDifferentFieldPredicate(predicate);
                return [predicate]
            }

            return [item];
        })
        .flat()
    ;
    return predicates as TLocalPredicate[];
}


export interface IDefinedOperator<Value = IDefinedOperatorValue, Type extends EDefinedOperatorType = EDefinedOperatorType> {
    type?: Type;
    operator: TCoreFilterOperator | undefined;
    value: Value | undefined;
    order?: number;
}



export enum EDefinedOperatorType {
    SearchText = 'SearchText',
    Different = 'Different',
}

interface IDefinedOperatorTextSearch extends IDefinedOperator<string> {
    type: EDefinedOperatorType.SearchText;
    operator: '=';
}
interface IDefinedOperatorDifferent extends IDefinedOperator<string> {
    type: EDefinedOperatorType.Different;
    operator: '=';
}

type DefineOperators = DefineDiscriminatedMap<IDefinedOperator, 'type', {
    [EDefinedOperatorType.SearchText]: IDefinedOperatorTextSearch;
    [EDefinedOperatorType.Different]: IDefinedOperatorDifferent;
}>

function isSearchOperator(operator: IDefinedOperator<unknown>): operator is IDefinedOperatorTextSearch {
    return isDSOperator(EDefinedOperatorType.SearchText, operator);
}

export function isDSOperator<Type extends EDefinedOperatorType>(type: Type, operator: IDefinedOperator<unknown>): operator is DefineOperators[Type] {
    return operator.type === type;
}

type IDefinedOperatorOptions = Omit<IDefinedOperator, 'value'>;


export function defineOperator<IsArray extends boolean = false>(options: IDefinedOperatorOptions) {
    type DefinedValue = (IsArray extends true ? IDefinedOperatorValue[] : IDefinedOperatorValue)

    type GetOutput<T> = IsArray extends true ? T extends unknown[] ? T[number] : never : T;

    return execute
    
    function execute<Value>(value:  & CNarrow<Value> & AfterNarrow<Value extends DefinedValue ? unknown : DefinedValue>): IDefinedOperatorInput<GetOutput<Value>>
    function execute<Value extends DefinedValue>(value: Value): IDefinedOperatorInput<Value> {
        return new IDefinedOperatorInput({
            ...options,
            value,
        });
    }
}

export function getDefinedOperatorFromUnknown(field: unknown) {
    if (isDefinedOperator(field)) return getDefinedOperator(field);
}

export function getDefinedOperator<Value>(field: IDefinedOperatorInput<Value>): IDefinedOperator<Value> {
    return field[PREDICATE_OPERATOR_ID];
}

export function isDefinedOperator<Value>(field: Nullish | {} | IDefinedOperatorInput<Value>): field is IDefinedOperatorInput<Value> {
    if (isPrimitive(field)) return false;
    return PREDICATE_OPERATOR_ID in field;
}

export function getPredicatesCount<T extends { [key in string]: number }>(predicates: TLocalPredicate[], nss: INonSerializable[]): T;
export function getPredicatesCount(predicates: TLocalPredicate[], nss: INonSerializable[]): { [key in string]: number } {
    return _.chain(predicates).mapKeys(item => item.property).mapValues(predicate => nss.map(ns => getIgnoringArray(ns, predicate.property)).flat().filter(item => item === predicate.predicate).length).entries().orderBy(item => item[1]).fromPairs().value()
}

export function getFieldsCount<T extends { [key in string]: unknown[] }>(fields: string[], nss: INonSerializable[]): T;
export function getFieldsCount(fields: string[], nss: INonSerializable[]): { [key in string]: unknown[] } {
    return _.chain(fields).mapKeys(field => field).mapValues(field => nss.map(ns => getIgnoringArray(ns, field)).flat()).mapValues(items => deepUnique(items).filter(isValidRef)).entries().orderBy(item => item[1].length, 'desc').fromPairs().value()
}


