import { Component, OnInit } from '@angular/core';
import { EBPMMarketingActionType, IBPMMarketingEndServer, TBpmMarketingNaturalNsTypes, TBpmMarketingNsTypes } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { MKTEndBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { NewConditionEditorHandler, NewConditionEditorParameter } from '../../new-condition-editor/new-condition-editor.component';
import { MarketingBpmBase } from '../marketing-bpm-base.component';

@Component({
    selector: 'app-marketing-bpm-end',
    templateUrl: './marketing-bpm-end.component.html',
    styleUrls: ['./marketing-bpm-end.component.scss']
})
export class MarketingBpmEndComponent extends MarketingBpmBase<MKTEndBpmGraphCreateHandler, IBPMMarketingEndServer> implements OnInit {
    nsType = ENonSerializableObjectType.bpmMarketingEnd as TBpmMarketingNaturalNsTypes;

    evaluatorsOfActions: NewConditionEditorHandler;

    getNS(): IBPMMarketingEndServer {
        return this.handler.getMarketingEnd()
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
