import { Location } from "@angular/common";
import { Injectable, Injector } from '@angular/core';
import { createServiceLogger } from "app/model/client-utility";
import { AppService } from "app/services/app.service";
import { Subject } from 'rxjs';
import { ColmeiaAppContract } from "../../preloader/interfaces";
import { GroupNotificationSignal } from "../model/signal/notification-signal";
import { AuthService } from "./auth/auth.service";
import { HardwareLayerService } from "./hardware";
import { RouterManagerService } from "./router-manager.service";
import { SessionService } from "./session.service";
import { SignalListenerService } from "./signal/signal-listener";
import { UserActionService } from "./user-action.service";

@Injectable({
    providedIn: 'root'
})
export class InitSystemService {
    private log = createServiceLogger('InitSystemService');
    ready = new Subject<boolean>();

    constructor(
        private hw: HardwareLayerService,
        private listener: SignalListenerService,
        private location: Location,
        private userActionSVC: UserActionService,
        private _injector: Injector,
        private auth: AuthService,
        private appService: AppService
    ) {
        this.log('Chamando metodos para inicializar player e pegar constantes necessarias ao sistema');
    }

    /**
     * It's called by appComponent to start the system and get constants from server
     */
    public async initSystem(): Promise<void> {
        this.ready.next(true);
        this.listenToEvents();
        const app: ColmeiaAppContract = window['_COLMEIA_'];
        if (app.isCRMEmbeddedMode() || app.isChatEmbeddedMode()) {
            this.auth.hideInitializerSpinner();
        } else {
            console.log({ InitSystemServiceLoginPlayerFromTokenTimestamp: (new Date()).toISOString() });
            if (!this.appService.isWidgetApp()) {
                await this.auth.loginPlayerFromToken();
            }
        }
    }

    private listenToEvents(): void {
        if (this.hw.isMobile()) {
            this.listener.listenToGroupNotification(async (signal: GroupNotificationSignal) => {
                const router = this._injector.get<RouterManagerService>(RouterManagerService);
                router.goToInteractionID(signal.getGroupID(), signal.getInteractionId())
            });
            this.listener.listenToBackButtonPressed((evt: Event) => {
                const session = this._injector.get<SessionService>(SessionService);
                if (!this.userActionSVC.closeLastAction()) {
                    if (session.isAtRootGroup()) {
                        return;
                    }
                    this.location.back();
                }
            });
        }
    }
}
