import { PhoneNumber } from "@colmeia/core/src/business/phone-number/phone-number";
import { IPhoneNumberTimezoneInfo, IPhoneNumberTimezoneTimeInfo } from "@colmeia/core/src/business/phone-number/timezone.types";
import { Minutes, minToHour, Hours } from "@colmeia/core/src/time/time-utl";
import { getDate } from "@colmeia/core/src/tools/barrel-tools";
import { $RegExp } from "@colmeia/core/src/tools/utility-types";
import { getGroupsFromPattern } from '@colmeia/core/src/tools/utility/functions/getGroupsFromPattern';


export class TimeZone {

    static min: Minutes = (getDate().getTimezoneOffset()) * -1;
    static hour: Hours = minToHour(TimeZone.min);

    public static getTimezone(phone: string): IPhoneNumberTimezoneTimeInfo {
        return (new PhoneNumber(phone)).getInfo().time;
    }

    public static getTimeObj(phone: string): IPhoneNumberTimezoneInfo {
        return (new PhoneNumber(phone)).getInfo();
    }

    public static getOffset(phone: string): number {
        return (new PhoneNumber(phone)).getInfo().offset;
    }

    public static getHourForPhone(phone: string): number {
        const data = TimeZone.getTimeObj(phone);
        return parseInt(data.time.hour);
    }


    public static getTimeZoneOffsetByPhone(phone: string) {
        const { zone } = TimeZone.getTimezone(phone);
        return TimeZone.getZoneFromPattern(zone);
    }

    static zoneGetter = getGroupsFromPattern(new $RegExp('GMT(?<number>.+)'))
    private static getZoneFromPattern(zone: string): number {
        const target = TimeZone.zoneGetter(zone)?.number ?? 0;
        return +target;
    }

}