import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { EUtteranceExistsOption } from "../../knowledge-base/kb-inferfaces";

export const chainedBasesScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ChainedBases,
    fields: {
        
    }
} as const);


export const utteranceExistsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.EUtteranceExistsOption,
    fields: {
        [EUtteranceExistsOption.No]: 1, // Não
        [EUtteranceExistsOption.Verbatim]: 2, // Verbatim
        [EUtteranceExistsOption.Nlp]: 3, // NLP
    }
} as const);


export const allChainedBases = {
    ...getTranslationsFields(chainedBasesScreenOptions),
    ...getTranslationsFields(utteranceExistsScreenOptions),
};

