import { GenericPlatform } from "../hardware-interfaces";
import { MultiMediaType } from "@colmeia/core/src/multi-media/multi-media-type";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class BrowserGeneric implements GenericPlatform {

    getMultimediaAllowedMIMETypes(): Array<string> {
        return MultiMediaType.getMultimediaTypeArray()
            .map(type => type.getHTMLType());
    }

}
