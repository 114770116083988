

function initJoinAndResetTypedProperties(properties: string[]): () => string {
    return (): string => {
        const result: string = properties.join('.');
        properties.splice(0);
        return result;
    }
}
export function getTypedProperty<T>(): T {
    const properties: string[] = [];
  
    const joinAndResetTypedProperties: () => string = initJoinAndResetTypedProperties(properties);
  
    const me: any = new Proxy({}, {
        get: (_target, propertyName) => {
            switch (propertyName) {
                case Symbol.toPrimitive: {
                    return joinAndResetTypedProperties;
                };
                default: {
                  properties.push(propertyName as string);
                  return me;
                }
            }
        },
    });
  
    return me as T;
  }
  