

import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { INonSerializable } from "../../non-serializable-id/non-serializable-id-interfaces";
import { notImplementedSharedFunction as notImplementedSharedMethod } from "../shared-services.basic-functions";

export interface InsertCatalogInput {
    name: string;
    id: string;
}
export interface InsertCatalogOutput {
    outId: string;
}

// 
class Service {

    public insert(input: InsertCatalogInput): Promise<InsertCatalogOutput> {
        notImplementedSharedMethod(input);
    }

    public getOne(input: { id: string }): Promise<INonSerializable | undefined> {
        notImplementedSharedMethod(input);
    }
    
}


@ToStatic
export class MockSharedService extends toStatic(Service) {
    public static test(input: InsertCatalogInput): Promise<InsertCatalogOutput> {
        notImplementedSharedMethod(input);
    }
}