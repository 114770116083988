<ng-container *ngIf="!loading else loadingTpl">
    <h5 style="margin-bottom: 8px;">Respostas:</h5>
    <app-general-form-viewer
        [answer]="answer"
        [schema]="metadata.schemma"
        [viewType]="viewType"
        style="margin-bottom: -12px;"
    ></app-general-form-viewer>
    <mat-divider style="margin-bottom: 12px;"></mat-divider>
    <ng-container *ngIf="isResolved">
        <ng-container *ngIf="resolverAvatar">
            <div class="resolver-avatar-container">
                <hexagon [hexagonHandler]="avatarHexagonHandler"></hexagon>
                <div class="l-avatar-name">{{ resolverAvatar.getName() }}</div>
            </div>
        </ng-container>
    </ng-container>

    <mat-form-field *ngIf="!isResolved || !!getComment" appearance="fill" style="width: 100%;">
        <mat-label>Comentário:</mat-label>
        <textarea matInput
            [readonly]="readOnly"
            cdkTextareaAutosize
            [(ngModel)]="receiverDescription"
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
        ></textarea>
    </mat-form-field>

    <ng-container *ngIf="isResolved">
        <app-alert type="success" darker dense>
            Resolvido
        </app-alert>
    </ng-container>

    <ng-container *ngIf="!isResolved">
        <div class="edit-buttons--wrapper">
            <button mat-flat-button [loading]="saving" [disabled]="readOnly" color="accent" (click)="doneSolicitation()">Finalizar</button>
        </div>
    </ng-container>
    
</ng-container>

<ng-template #loadingTpl>
    <div class="loading-container">
        <mat-spinner style="margin: 12px;" diameter="18"></mat-spinner>
        <h5>Carregando informações...</h5>
    </div>
</ng-template>
