import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { BBCode } from '@colmeia/core/src/shared-business-rules/bbcode/bbcode-main';
import { bbCodeHTMLTagsArray } from '@colmeia/core/src/shared-business-rules/bbcode/functions/bbcode-functions-simple';
import { addBracket, IEditorVariable, removeBracket, TIEditorVariableArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { isValidNumber, isValidString } from '@colmeia/core/src/tools/utility';
import { evaluateHTML, isElementNode, variableRegex } from 'app/model/client-utility';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.js';
import "quill-mention";
import { IEditorVariableClient } from '../../var-editor/var-editor.component';
import { VariableBlot } from "./variable.blot";

@Component({
    selector: 'app-var-editor-text-area-quill',
    templateUrl: './var-editor-text-area-quill.component.html',
    styleUrls: ['./var-editor-text-area-quill.component.scss']
})
export class VarEditorTextAreaQuillComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    #viewInitialized: boolean = false;
    #currentRaw: string;

    @Input()
    allVariables: IEditorVariableClient[] = [];

    @Input()
    maxLength: TIEditorVariableArray = [];

    @Input()
    placeholder: TIEditorVariableArray = [];

    @Input()
    content: string;
    @Output()
    contentChange: EventEmitter<string> = new EventEmitter();

    @ViewChild(QuillEditorComponent, { static: true })
    editor: QuillEditorComponent

    #htmlContentCache: string;
    #contentObserver: MutationObserver;

    public readonly modules: QuillModules = {
        'emoji-shortname': true,
        'emoji-textarea': true,
        'emoji-toolbar': true,
        mention: {
            blotName: VariableBlot.blotName,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["#"],
            isolateCharacter: true,
            positioningStrategy: 'fixed',
            showDenotationChar: false,
            renderItem: (item: { id: string, value: string }) => {
                const variable = this.allVariables.find(v => v.idProperty === item.id);
                const el = VariableBlot.createByVariable({
                    ...variable,
                    tooltip: undefined,
                });

                return el.outerHTML;
            },
            onSelect: (item: DOMStringMap, insertItem: Function) => {
                const editor = this.editor.quillEditor
                const variable = this.allVariables.find(v => v.idProperty === item.id);

                insertItem(VariableBlot.editorVariableToDomStringMap(variable))

                editor.insertText(editor.getLength() - 1, '', 'user')
            },
            source: (searchTerm: string, renderList: Function) => {
                const values = this.allVariables.map((v => ({
                    id: v.idProperty,
                    value: removeBracket(v.variable)
                })));

                if (searchTerm.length === 0) {
                    renderList(values, searchTerm)
                } else {
                    const matches = []

                    values.forEach((entry) => {
                        if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                            matches.push(entry)
                        }
                    })
                    renderList(matches, searchTerm)
                }
            }
        },
        toolbar: {
            container: ['bold', 'italic', 'strike', 'code', 'emoji']
        },
    }

    constructor() { }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.#contentObserver.disconnect();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.content) {
            this.handleContentInputChange(changes.content);
        }
    }

    ngAfterViewInit(): void {
        this.#viewInitialized = true;

        this.editor.onEditorCreated.subscribe(() => {
            this.editor.quillEditor.keyboard.addBinding({ key: 'B', shortKey: true }, (range) => {
                this.editor.quillEditor.formatText(range, 'bold', true);
            });

            this.parseAndSetContent();

            const target: HTMLElement = this.editor.editorElem.querySelector('.ql-editor');
            let firstMutted = false;
            this.#contentObserver = new MutationObserver(() => {
                if (!firstMutted) {
                    firstMutted = true;
                    return;
                }

                this.preSanitizationVariablesMentions(target);

                const htmlMarkup: string = target.innerHTML;

                this.updateValue(htmlMarkup);
                this.editor.content = htmlMarkup;
            });

            this.#contentObserver.observe(target, {
                childList: true,
                characterData: true,
                subtree: true
            });
        });
    }

    private preSanitizationVariablesMentions(target: HTMLElement) {
        this.removeInvalidVariablesMentions(target);
        this.removeNotAllowedVariablesMentions(target);
    }

    /**
     * Fix para variáveis removidas com o ctrl+backspace.
     * Variáveis removidas dessa forma, não remove o nó completo
     * apenas o innerHTML deixando o container no html.
     */
    private removeInvalidVariablesMentions(target: HTMLElement) {
        const allVariableMentions = target.querySelectorAll(`.${VariableBlot.className}`);

        allVariableMentions.forEach(el => {
            if (!isValidString(el.textContent, 2)) {
                el.parentElement.removeChild(el);
            }
        });
    }

    /**
     * Previne inserção de váriaveis que não estão na lista
     * ex: copy/paste
     */
    removeNotAllowedVariablesMentions(target: HTMLElement) {
        const allVariableMentions = target.querySelectorAll(`.${VariableBlot.className}`);

        allVariableMentions.forEach((el: HTMLElement) => {
            if (el.nodeType !== Node.ELEMENT_NODE) return;

            const variableId: string = el.dataset?.id;
            const isAllowedVariable: boolean = this.allVariables.some(v => v.idProperty === variableId);

            if (!isAllowedVariable) {
                el.parentElement.removeChild(el);
                return;
            }
        });
    }

    private setHTMLCacheFromInput(value: string) {
        this.#htmlContentCache = BBCode.parseHTML(value);
    }

    private handleContentInputChange(change: SimpleChange) {
        const isFirstChange: boolean = change.isFirstChange();
        const hasValueChange: boolean = isValidString(this.#currentRaw) && change.currentValue !== this.#currentRaw;

        if (isFirstChange || hasValueChange) {
            this.parseAndSetContent();
        }
    }

    private async parseAndSetContent() {
        const hasValueChange: boolean = isValidString(this.#currentRaw) && this.content !== this.#currentRaw;

        if (!(this.#viewInitialized || hasValueChange)) return;

        const variablesParsedHTML: string = this.rebuildVariablesHTML(this.content);
        const finalHTML: string = this.linesToBR(variablesParsedHTML);
        this.setHTMLCacheFromInput(finalHTML);
    }

    insertVariable(variable: IEditorVariable, index?: number, isUserAction: boolean = true) {
        const editor: Quill = this.editor.quillEditor;
        const quillMention = editor.getModule('mention')

        if (isValidNumber(index, 0)) {
            const selection = editor.getSelection(true);

            selection.index = index;
            selection.length = 0;

            editor.setSelection(selection);
        }

        quillMention.insertItem(VariableBlot.editorVariableToDomStringMap(variable), true);

        if (isUserAction) {
            editor.insertText(editor.getLength() - 1, '', 'user');
        }
    }

    private rebuildVariablesHTML(value: string): string {
        const result = value.replace(variableRegex, variableName => {
            const variable = this.allVariables.find((v) => v.variable === variableName);

            if (!variable) {
                return '';
            }

            return this.getVariableHTML(variable);
        });
        return result;
    }

    private linesToBR(input: string): string {
        return input.replaceAll('\n', '<br>');
    }

    private getVariableHTML(variable: IEditorVariable): string {
        const blotNode: HTMLElement = VariableBlot.createByVariable(variable);

        return blotNode.outerHTML;
    }

    get htmlContent(): string {
        return this.#htmlContentCache;
    }

    private updateValue(htmlMarkup: string) {
        // console.log("raw", { htmlMarkup });
        htmlMarkup = this.clearEmptyTextFormatMarkups(htmlMarkup);
        // console.log('empty bb removed?',{ htmlMarkup });
        htmlMarkup = BBCode.htmlToBBCode(htmlMarkup);
        // console.log({ htmlMarkup });
        htmlMarkup = this.sanitizeHtmlLines(htmlMarkup);
        // console.log({ htmlMarkup });
        htmlMarkup = this.mentionsToVariables(htmlMarkup);
        // console.log({ htmlMarkup });
        const bbCode = this.cleanHTML(htmlMarkup);
        // console.log({ bbCode });

        this.#currentRaw = bbCode;
        this.contentChange.emit(bbCode);
    }

    private clearEmptyTextFormatMarkupsNode(container: HTMLElement) {
        container.childNodes.forEach(el => {
            if (!isElementNode(el)) return;

            const isBBCodeElement: boolean = bbCodeHTMLTagsArray.includes(el.nodeName.toLocaleLowerCase());
            const isEmpty = el.textContent.charCodeAt(0) === 65279 && el.textContent.length === 1;

            if (isBBCodeElement && isEmpty) {
                container.removeChild(el);
            }

            if (el.childNodes.length) {
                this.clearEmptyTextFormatMarkupsNode(el);
            }
        });
    }

    private clearEmptyTextFormatMarkups(htmlMarkup: string): string {
        const container = evaluateHTML(htmlMarkup);

        this.clearEmptyTextFormatMarkupsNode(container);

        return container.innerHTML;
    }

    private sanitizeHtmlLines(htmlMarkup: string) {
        const htmlToLines = this.parseMarkupLines(htmlMarkup);
        const trimLines = this.trimLines(htmlToLines);
        return trimLines;
    }

    private parseMarkupLines(htmlMarkup: string): string {
        return htmlMarkup
            .replace(/<p><br><\/p>/g, '\n')
            .replace(/<p>/g, '')
            .replace(/<\/p>/g, '\n');
    }

    private trimLines(htmlMarkup: string): string {
        const lines = htmlMarkup.split('\n');
        const lastLine: string = lines[lines.length - 1];

        if (lines.length > 1 && lastLine.length === 0) {
            lines.pop();
        }

        return lines.join('\n');
    }

    private parseMentionToVariablesOnNode(container: HTMLElement) {

        container.childNodes.forEach((node: HTMLElement) => {
            const isMentionBlot = VariableBlot.is(node);

            if (isMentionBlot && isValidString(node.dataset?.value)) {
                const variableName: string = addBracket(node.dataset.value);
                const variableNameTextNode = document.createTextNode(variableName);

                container.replaceChild(variableNameTextNode, node);

                return;
            }

            if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length) {
                this.parseMentionToVariablesOnNode(node);
            }
        });
    }

    private mentionsToVariables(input: string): string {
        const container = evaluateHTML(input);

        this.parseMentionToVariablesOnNode(container);

        return container.innerHTML;
    }

    /**
     * Remove elementos não desejados que não foram 
     * transformados pela função BBCode.htmlToBBCode
     */
    private cleanHTML(input: string): string {
        const container = evaluateHTML(input);

        container.childNodes.forEach((node: HTMLElement) => {
            if (node.nodeType !== Node.ELEMENT_NODE) return;

            const textRawNode = document.createTextNode(node.textContent);
            container.replaceChild(textRawNode, node);
        });

        return container.innerHTML;
    }
}
