<section class="wrapper">
  <header class="group-header">
    <a class="group-photo" (click)="goToGroup()">
      <hexagon [hexagonHandler]="hexagonHandler"></hexagon>
      <img
        class="group-type--icon"
        *ngIf="showGroupTypePhoto"
        [src]="groupTypeImg"
        [matTooltip]="groupTypeName"
        alt="group type"
      />
    </a>
    <a class="group-header--title" (click)="goToGroup()">
      <h4>{{ group.getName() }}</h4>
    </a>
    <span class="group-header pvt" *ngIf="isGroupLockedToMe">
      <mat-icon>lock</mat-icon>
    </span>
    <app-more-options-three-dots
      *ngIf="mustShowMoreOptions"
      class="group-header dots"
      xPosition="before"
      [handler]="moreOptionsHandler"
    ></app-more-options-three-dots>
  </header>
  <div class="content">
    <div class="carousel">
      <app-group-card-item-carousel [multimediaList]="mmList"></app-group-card-item-carousel>
    </div>
    <p class="content--group-description">
      {{ showGroupDescription ? groupDescription : ''  }}
      <button class="content--read-more" *ngIf="showGroupDescription && showReadMore" (click)="toggleTextToShow()">
        {{ showAllGroupDescription ? translations.readLess : translations.readMore }}
      </button>
    </p>
  </div>
  <div class="footer">
    <app-group-members *ngIf="showStats"
      [members]="members"
      [maxToShow]="3"
    >
    </app-group-members>
    <cm-follow-button [handler]="followBtnHandler"></cm-follow-button>
  </div>
</section>
