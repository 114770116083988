
export enum EClientErrorGroup {
    Attendance = 'attendance',
    Dashboard = 'dashboard',
}

export enum EClientErrorType {
    NoType = 'no-type',
    TypeError = 'type-error',
    AppFileLoad = 'app-file-load',
    SocialNetworkDataLoad = 'social-network-dataload',
    Participant = 'participant',
    Group = 'group',
    Avatar = 'avatar',
    Player = 'player',
    Interaction = 'interaction',
    Chat = 'chat'
}

export type TClientErrorIdentifiers = EClientErrorGroup | EClientErrorType;

export type TClientErrorIdentificationConfig = {
    messagesFragmentsMatch?: string[],
    stackFragmentsMatch?: string[],
    classInstanceMatch?: typeof Error,
};

export type TClientErrorIdentificationConfigHash<T extends TClientErrorIdentifiers> = {
    [key in T]: TClientErrorIdentificationConfig
}

export interface IClientErrorClassification {
    type: EClientErrorType;
    group?: EClientErrorGroup;
    matches: TIClientErrorMatchDetails[];
}

export interface IClientErrorMatchDetailsBase {
    score: number,
}

export interface IClientErrorGroupMatchDetails extends IClientErrorMatchDetailsBase {
    group: EClientErrorGroup,
}

export interface IClientErrorTypeMatchDetails extends IClientErrorMatchDetailsBase {
    type: EClientErrorType
}


export type TIClientErrorMatchDetails = IClientErrorGroupMatchDetails | IClientErrorTypeMatchDetails
export type TIClientErrorMatchDetailsItems = keyof Omit<IClientErrorGroupMatchDetails & IClientErrorTypeMatchDetails, 'score'>;
