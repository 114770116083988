import { getTranslationsFieldsAndScreenOptions, EAllSerializable, getTranslationsFields } from "../all-serializables";
import { typedScreenOptions } from "../../../tools/utility";
import { EGroupContainer } from "../../group-container/group-container-config";
import { EScreenGroups } from "../../visual-constants";
import { genericTypedSuggestions } from "../../../tools/type-utils";


export const groupContainerAccessTypeScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.SecurityControlAccessType,
    fields: genericTypedSuggestions<Record<EGroupContainer, number>>()({
        'conPartRequest': 1, // Request de Participante
        'dashMenus': 2, // Acesso a grupos
    })
})

const security = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.SecurityControl,
    fields: {
        'chooseASubMenu': 1, // 'dashboardGroupContainerEdit' 19
        'chooseAMenu': 2, // 'dashboardGroupContainerEdit' 20
        'chooseAGroup': 3, // 'dashboardGroupContainerEdit' 21
        'typeTheRuleName': 4, // 'dashboardGroupContainerEdit' 22
        'createRule': 5, // 'dashboardGroupContainerEdit' 23
        'selectAnAccess': 6, // 'dashboardGroupContainerEdit' 24
        'groupPermissions': 7, // 'dashboardSecurityControlEdit' 25
        'accessType': 8, // Tipo de acesso
    },
});

export const screenGroupsTranslations = getTranslationsFields(
    typedScreenOptions({
        idSerializable: EAllSerializable.Dashboard,
        fields: {
            [EScreenGroups.Environments]: 1,
            [EScreenGroups.YourColmeia]: 2,
            [EScreenGroups.Ai]: 3,
            [EScreenGroups.MenuSN]: 4,
            [EScreenGroups.Marketing]: 5,
            [EScreenGroups.Communication]: 6,
            [EScreenGroups.Service]: 7,
            [EScreenGroups.Analytics]: 8,
            [EScreenGroups.Jobs]: 9,
            [EScreenGroups.Features]: 10,
            [EScreenGroups.CRMServices]: 11,
            [EScreenGroups.SmartFlow]: 12,
            [EScreenGroups.AnalyticsHistory]: 13,
        }
    })
)





export const securityControlScreenOptions = security.screenOptions;
export const securityControlTranslations = {
    ...security.translations,
    ...getTranslationsFields(groupContainerAccessTypeScreenOptions),
    ...(screenGroupsTranslations),
};

