import { IBotActionAssetContainer, TIBotActionAssetContainerArray } from "../../bot/bot-action-model";
import { KAssetType } from "../../bot/bot-asset-model";
import { EBotContentEvent } from "../../bot/bot-content-model";
import { EBotEventType } from "../../bot/bot-event-model";
import { ENextGenBotElementType } from "../../bot/bot-model";
import { EIslandEvent } from "../../bot/bot-other-event-config-db";
import { EBotActionType } from "../../bot/new-bot-action";
import { EBPMType } from "../../BPM/bpm-model";
import { ESubscribedTo, IBPMBaseNodeServer, TBPMListenerItems } from "../../BPM/common/common-bpm";
import { TBpmMarketingNsTypes } from "../../BPM/marketing/bpm-marketing.model";
import { ICRMBridgeServer, TCrmBpmNodes } from "../../crm/crm-services/crm-bpm-model";
import { TRenderData } from "../../graph/essential/graph-types";
import { ITransactionServer } from "../../knowledge-base/bot-transaction/bot-transaction";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { BotTransactionHostNode } from "../host/bot/bot-transaction.host";
import { BotRootHostNode } from "../host/bot/bot.root.host";
import { MenuContainerHostNode } from "../host/bot/menu-container-host.node";
import { MenuItemHostNode } from "../host/bot/menu-item-host-node";
import { CrmNotificationHost } from "../host/crm/crm-notification.host";
import { CrmProcessingHostNode } from "../host/crm/crm-processing-host-node";
import { CrmRootHost } from "../host/crm/crm-root.host";
import { CrmUserEventHost } from "../host/crm/crm-user-event.host";
import { MktActionHost } from "../host/mkt/mkt-action.host";
import { MktAckHost } from "../host/mkt/mkt-akc.host";
import { MktEndHost } from "../host/mkt/mkt-end.host";
import { MktListenerHost } from "../host/mkt/mkt-listener.host";
import { MktPauseHost } from "../host/mkt/mkt-pause.host";
import { MktRootHost } from "../host/mkt/mkt-root.host";
import { NestedAIColmeiaFormsHost } from '../host/nested-ai/nested-ai-colmeia-forms-node.host';
import { NestedAIMenuContainerHostNode } from '../host/nested-ai/nested-ai-menu-container-node.host';
import { NestedAIRootHost } from '../host/nested-ai/nested-ai-root.host';

// AGRUPAR SUB FERRAMENTAS
export enum EToolbarGroupType {
    BotRelated = 'bot',
    CRMRelated = 'crm',
    MarketingRelated = 'mkt',
    NestedAIRelated = 'nestedAI',
    Text = 'text',
    API = 'api',
}

export enum EAdditionalPredicateType {
    Parent = 'idParent',
}

export enum EAdditionalInternalType {
    Timer = 'timer',
}

export enum EAdditionalBotObjects {
    menuItemConditionalDisplay = 'menuItemConditionalDisplay',
}

export enum EAdditionalNestedAIActions {
    openIntentsTab = 'openIntentsTab',
}

/**
 * CRM
 */
export type TBPMCRMElementTypes =
    | TCrmBpmNodes
    | TBPMListenerItems
export type TBPMCRMElements = CrmRootHost | CrmUserEventHost | CrmNotificationHost | CrmProcessingHostNode
export type TBPMCRMNser = ICRMBridgeServer | ITransactionServer
export type TBPMCRMConnectionTypes = ESubscribedTo.contentGenerator | EAdditionalPredicateType.Parent

/**
 * BOT
 */
export type TBPMBOTElements = MenuContainerHostNode | MenuItemHostNode | BotRootHostNode | BotTransactionHostNode
export type TBPMBOTElementTypes = ENextGenBotElementType.root
    | ENextGenBotElementType.menuContainer
    | ENextGenBotElementType.botMenuItem
    | ENextGenBotElementType.formTransaction
    | ENonSerializableObjectType.contentGenerator
export type TBPMBOTConnectionTypes = EBotActionType.contentGenerator | EAdditionalPredicateType.Parent
type TBotObjects = ENextGenBotElementType | KAssetType | EBotContentEvent | EIslandEvent | EAdditionalBotObjects | EBotEventType;

/**
 * MKT
 */
export type TBPMMKTElementTypes = TBpmMarketingNsTypes
    | TBPMListenerItems;

export type TBPMMKTElements = MktRootHost | MktActionHost | MktPauseHost | MktAckHost | MktListenerHost | MktEndHost
export type TBPMMKTConnectionTypes = EAdditionalPredicateType.Parent
type TMktObjects = ESubscribedTo;

/**
 * NestedAI
 */
export type TBPMNestedAIElementTypes = ENonSerializableObjectType.contentGenerator | ENonSerializableObjectType.bot; // Used at INestedAIHostFactoryInfo
export type TBPMNestedAIElements = NestedAIRootHost | NestedAIMenuContainerHostNode | NestedAIColmeiaFormsHost; // Used at createNestedAI -> host-node.factory.ts
export type TBPMNestedAIConnectionTypes = EBotActionType.contentGenerator | EBotActionType.goActionTree

export type TBPMElementTypes = TBPMBOTElementTypes | TBPMCRMElementTypes | TBPMMKTElementTypes;
export type TBPMConnectionTypes = TBPMBOTConnectionTypes | TBPMCRMConnectionTypes | TBPMNestedAIConnectionTypes

export type TReferencedObjectArray = Array<TReferencedObject>;
export type TReferencedObject = ENonSerializableObjectType | EAdditionalPredicateType | TBotObjects | TMktObjects | EAdditionalInternalType;


export enum EBPMElementMode {
    ArrowSourced = 'arrowSourced',
    InternalElement = 'internalElement',
    Element = 'element',
}

export enum EArrowSourcedSpot {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
}

export enum EArrowSourcedLineType {
    Straight = 'straight',
    Orthogonal = 'smoothstep',
    Bezier = '',
}
export type TBasicToolbarConnectConfig = { [to in TReferencedObject]?: TReferencedObject[] };

export type TBPMConnectionStyleMap = { [key in TBPMConnectionTypes]?: IConnectorStyles }
export interface IBPMConnectionStyle {
    connectionType: TBPMConnectionTypes,
    style: IConnectorStyles
}

interface IShapeStyle {
    strokeWidth?: number,
    strokeColor?: string,
    fill?: string;
    opacity?: number;
}

export interface ISpotConnectorDecorator {
    shape: string;
    width?: number;
    height?: number;
    pivot?: {
        x?: number,
        y?: number
    }
    style?: IShapeStyle
}

export interface IConnectorStyles {
    style?: object;
    type?: EArrowSourcedLineType;
    animated?: boolean;
    reverseAnimation?: boolean
}

export enum EConnectorPointShapes {
    Square = 'Square',
    Circle = 'Circle',
    Diamond = 'Diamond',
    IndentedArrow = 'IndentedArrow',
    OutdentedArrow = 'OutdentedArrow'
}

export type TIBasicToolbarElementArray = IBasicToolbarElement[]

export interface IBasicToolbarElement<ReferencedObject extends TReferencedObject = TReferencedObject> {
    subjectArea: EBPMType;
    icon: string;
    referencedObject: ReferencedObject;
    groupType: EToolbarGroupType;
    renderData?: TRenderData;
    width: number;
    height: number;
    mode: EBPMElementMode;

    canDragOn?: ReferencedObject[]; // Asset only
    canConnectFrom?: TBasicToolbarConnectConfig;
    canConnectTo?: TBasicToolbarConnectConfig;

    canDelete: boolean;
    title?: string;
    isSequenced?: boolean;
    moveChildrenTogether?: boolean;
    //
    sourceSpot?: EArrowSourcedSpot,
    targetSpot?: EArrowSourcedSpot,
    connectorStyle?: IConnectorStyles,
    /**
     * Define se o elemento não será renderizado na toolbar
     */
    notVisibleInToolbar?: boolean;
}


export interface IToolbarElement extends IBasicToolbarElement {
    renderData: TRenderData;
}

export type TToolbarElementsDB = { [key in TReferencedObject]?: IBasicToolbarElement };

export type TGraphActionContainer = TIBotActionAssetContainerArray | IBPMBaseNodeServer;