import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

export const bpmGraphScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.BPMGraph,
    fields: {
        bpmBOT: 1, // BPM Bots
        bpmCRM: 2, // Configurações de serviços - CRM
        bpmMKT: 3, // BPM Marketing
        bpmNestedAI: 4, // BPM Nested AI
        bpmGraphIcon: 18, // bubble_chart
    }
} as const);


export const bpmGraphTranslations = getTranslationsFields(bpmGraphScreenOptions);


