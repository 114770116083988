import {Participant} from '../../business/participant';
import {constant, TGlobalUID} from '../../business/constant';
import {Interaction} from '../interaction'
import {InteractionType} from '../interaction-type';
import {UberCache} from '../../persistency/uber-cache';
import { IFinishChatService} from '../../comm-interfaces/service-group-interfaces';
import { ChainedInteraction } from '../chained-interaction';
import { EServiceGroupIntType } from '../../business/constant.enums';
import { StartServiceChat } from './start-service-chat';
import { isInvalid } from '../../tools/utility';


export class FinishServiceCall extends ChainedInteraction {

    private constructor(primaryID: TGlobalUID, participant: Participant, startService: StartServiceChat) {
        super(primaryID, participant, InteractionType.staticFactory(EServiceGroupIntType.finishService), startService);
    };

    public rehydrate(json: IFinishChatService): void {
        super.rehydrate(json);
    };

    public static staticFactory(isStopService: TGlobalUID): FinishServiceCall {
        return <FinishServiceCall>UberCache.uberFactory(isStopService, constant.objectType.interaction, true);
    };

    public static factoryMessage(json: IFinishChatService, participant: Participant = null): FinishServiceCall {
        let finish: FinishServiceCall = <FinishServiceCall>Interaction.searchCachedInteraction(json.primaryID);
        if (isInvalid(finish)) {
           finish = new FinishServiceCall(json.primaryID, 
                            participant? participant: Participant.staticFactory(json.participant.primaryID),
                            StartServiceChat.staticFactory(json.idInteractionParent));
        }
        finish.rehydrate(json);
        return finish;
    };

    public static factory(participant: Participant, startService: StartServiceChat): FinishServiceCall {
        return new FinishServiceCall(null, participant, startService);
    }
};
