import { Component, OnInit } from '@angular/core';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { EYouTaskRequestType, EYouTaskStatus, EYouTasksType, IYouTaskGroupApprovalServer, IYouTaskSocialNetworkServer, IYouUserInput, IYouUserInputForSNAppr, TAllYouTasksServer } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { getClock, isValidArray, isValidRef, isValidString, typedClone } from '@colmeia/core/src/tools/utility';
import { YouTasksClientService } from 'app/components/dashboard/security/solicitation-tasks.service';
import { SessionService } from 'app/services/session.service';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { IYouTasksComponentBase } from '../you-task.client-db';
import { YouTasksEditHandler } from '../you-tasks-edit.handler';
import { EObjectType } from '@colmeia/core/src/business/constant.enums';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { SerializableHeaders } from 'app/services/serializable-headers';
import { SnackMessageService } from 'app/services/snack-bar';
import { HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { INSPickerHandlerClientCallback, INSPickerHandlerParameter, NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { IUserApprovalProfileServer } from '@colmeia/core/src/shared-business-rules/user-approval-profile/user-approval-profile';
import { LookupService } from 'app/services/lookup.service';

@Component({
    selector: 'cm-security-tasks-edit',
    templateUrl: './security-tasks-edit.component.html',
    styleUrls: ['./security-tasks-edit.component.scss']
})
export class SecurityTasksEditComponent implements OnInit, IYouTasksComponentBase {

    static nsType = ENonSerializableObjectType.yourTasks;

    public receiverDescription: string = '';
    public acceptRequest = EYouTaskStatus.done;
    public rejectRequest = EYouTaskStatus.rejected;
    public processingRequest = EYouTaskStatus.processing;
    public nsClone: TAllYouTasksServer;
    public savingFor?: EYouTaskStatus;
    public userProfilesList: Array<TGlobalUID>;

    saving: boolean = false;

    get parameters() { return this.colmeiaWindowRef.data.getComponentParameter() };
    get nss(): TAllYouTasksServer[] {
        return this.parameters.nss;
    }

    get readOnly(): boolean {
        return this.colmeiaWindowRef.data.isResolved();
    }

    get isResolved(): boolean {
        return this.colmeiaWindowRef.data.isResolved();
    }

    get handler(): YouTasksEditHandler {
        return this.colmeiaWindowRef.data;
    }

    get avatarHexagonHandler(): HandlerHexagonon | undefined{
        return this.handler.avatarHexagonHandler;
    }

    get resolverAvatar(): Avatar | undefined{
        return this.handler.resolverAvatar;
    }

    get getComment(): string {
        return this.nss[0]?.inputs?.[0]?.description;
    }

    get taskStatus(): EYouTaskStatus {
        return this.nsClone.solicitationStatus;
    }

    get taskType(): EYouTasksType {
        return this.nss[0].details.taskType;
    }
    
    get details() {
        return this.nss[0].details;
    }

    public constructor(
        private sessionSvc: SessionService,
        private solicitationSvc: YouTasksClientService,
        private colmeiaWindowRef: ColmeiaWindowRef<SecurityTasksEditComponent, 
            unknown, YouTasksEditHandler>,
        private snackSvc: SnackMessageService,
        private dashboardSvc: DashBoardService,
        private lookupService: LookupService
    ) {} 

    public async ngOnInit(): Promise<void> {
        const ns = <IYouTaskGroupApprovalServer>this.parameters.nss[0]
        this.nsClone = typedClone(this.parameters.nss[0] as IYouTaskGroupApprovalServer);


        const details_ = ns.details;

        if (this.isResolved) {
            this.receiverDescription = this.getComment;
        }

        await this.handler.loadResolvedTaskData();

        console.log({
            details: details_,
            groupNameValid: isValidString(details_.groupName),
            isSocialNetworkSolicitation: this.isSocialNetworkSolicitation()
        })

        if (this.isSocialNetworkSolicitation()) {
            this.initializeNSPickerHandler();
        }

        if (isValidString(details_.groupName)) return;

        const hash = await SerializableHeaders.fetch([{ serializableID: details_.idGroup, objectTypeID: EObjectType.group }])
        const groupJson = hash[details_.idGroup]
        details_.groupName ??= Serializable.factoryMessage(groupJson).getName();
    }

    async onSave(requestAnswer?: EYouTaskStatus) {
        this.saving = true;
        this.savingFor = requestAnswer;
        const result = await this.saveOnBoardingSolicitation(requestAnswer);
        console.log({result})
        if (isValidRef(result)) {
            this.colmeiaWindowRef?.close();
            this.parameters?.reloadCallback?.();
        }

        this.savingFor = undefined;
        this.saving = false;
    }

    async saveOnBoardingSolicitation(requestAnswer?: EYouTaskStatus) {
        if (isValidString(this.receiverDescription) || isValidRef(this.userProfilesList)) {
            const userInput: IYouUserInput = {
                idAvatar: this.sessionSvc.getAvatarID(),
                description: this.receiverDescription,
                clockTick: getClock()
            }
            if (this.isSocialNetworkSolicitation()) {
                (userInput as IYouUserInputForSNAppr).userProfilesList = this.userProfilesList;
            }
            this.nsClone.inputs = [userInput]
        };

        this.nsClone.solicitationStatus = requestAnswer!;
        
        return this.solicitationSvc.saveTasks(
            this.nsClone as IYouTaskGroupApprovalServer,
            EYouTaskRequestType.solicitationResponse
        );
    }


    userApprovalProfilePicker: NSPickerHandler;

    private async initializeNSPickerHandler(): Promise<void> {
        const snInputs = (this.nss[0] as IYouTaskSocialNetworkServer).inputs;
        const ids = snInputs.reduce((acc: string[], input) => {
            if (isValidArray(input.userProfilesList)) {
                const updated = [...acc, ...input.userProfilesList]
                return updated;
            }
            return acc;
        }, []);

        const nsPickerParameter: INSPickerHandlerParameter = {
            title: "Atribuir Perfil de Usuário",
            nsType: ENonSerializableObjectType.userApprovalProfile,
            clientCallback: this,
            //@ts-ignore
            idParent: null,
            demandedTag: undefined,
            genericNonSerializableService: this.dashboardSvc.getGenericNonSerializableService(),
            // nonSerializables: snInputs.reduce((acc, input) => {
            //     if (isValidArray(input.userProfilesList)) {
            //         const updated = [...acc, ...input.userProfilesList]s
            //         return updated;
            //     }
            //     return acc;
            // }, []),
            nonSerializables: await this.lookupService.getNSs(ids),
            disable: this.isResolved,
            disabledTitle: 'Perfil(is) selecionado(s)',
            maxSelections: 50 
        };

        this.userApprovalProfilePicker = new NSPickerHandler(nsPickerParameter);
    }
    onSaveCallback(nonSerializables: IUserApprovalProfileServer[], nsType: ENonSerializableObjectType) {
        console.log({nonSerializables, nsType})
        this.userProfilesList = nonSerializables.map(ns => ns.idNS!);
    }

    isSocialNetworkSolicitation() {
        return this.taskType === EYouTasksType.socialNetworkParticipationApproval;
    }

    isGroupSolicitation() {
        return this.taskType === EYouTasksType.groupParticiparionApproval;
    }

    isFormSolicitation() {
        return this.taskType === EYouTasksType.formSolicitation;
    }
}
