import { ToAvatar } from "../to-avatar";
import { TGlobalUID } from "../../../core-constants/types";
import { Participant } from "../../../business/participant";
import { constant } from "../../../business/constant";


export class DottedTeamMembershipRequest extends ToAvatar {


    private constructor(primaryID: TGlobalUID, participant: Participant, idAvatar: TGlobalUID) {
        super(primaryID, participant, constant.subscription.request.dottedTeamMembership, idAvatar)
    };

    public static getNewDottedTeamMembershipRequest(personalGroupParticipant: Participant, idAvatar: TGlobalUID): DottedTeamMembershipRequest {
        return new DottedTeamMembershipRequest(null, personalGroupParticipant, idAvatar)
    };

};
