import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GroupChatLeftNavSideComponent } from 'app/components/chat/group-chat-left-side-bar/group-chat-left-side-bar.component';
import { DashboardMenuHandler } from 'app/components/dashboard/dashboard-menu/dashboard-menu.handler';
import { IMenuDashboardOption, MenuDashboardService } from 'app/components/menu-dashboard/menu-dashboard.service';
import { ESideMenuScreen, ModuleNavService, TSideMenuScreenId } from 'app/components/navigation/module-nav.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-module-nav-menus',
  templateUrl: './module-nav-menus.component.html',
  styleUrls: ['./module-nav-menus.component.scss']
})
export class ModuleNavMenusComponent implements OnInit, OnDestroy {
    modulesList: Observable<DashboardMenuHandler[]>;

    @HostBinding('class.hidden') get hideMenus() {
        return this.moduleNavService.hideMenus;
    }

    @ViewChild(GroupChatLeftNavSideComponent) private groupChatLeftNavSideComponent: GroupChatLeftNavSideComponent;

    public selectedSideScreenId: TSideMenuScreenId;

    private selectedDashSub: Subscription;
    private selectedSideScreenSub: Subscription;
    
    @ViewChild('scrollContainer', { static: true }) private scrollContainer: ElementRef<HTMLElement>;
    private lastScrollValue: number;
    private ignoreNextScroll: boolean;

    ESideMenuScreen = ESideMenuScreen;

    constructor(
        private dashboardMenuSvc: MenuDashboardService,
        private moduleNavService: ModuleNavService
    ) { }

    ngOnInit() {
        this.setModuleList();

        this.selectedDashSub = this.dashboardMenuSvc.selectedDashboard$.subscribe(() => {
            this.setModuleList();
        });
        
        this.selectedSideScreenSub = this.moduleNavService.getSelectedSideScreen$().subscribe(screenId => {
            this.selectedSideScreenId = screenId;
            
            if (screenId === ESideMenuScreen.Chat) {
                // conserta um bug que faz o chat não ocupar todo espaço disponível
                setTimeout(() => this.groupChatLeftNavSideComponent.virtualScrollViewport.checkViewportSize(), 0);
            }
        });        
    }

    ngOnDestroy(): void {
        this.selectedDashSub.unsubscribe();
        this.selectedSideScreenSub.unsubscribe();
    }
    
    private setModuleList(): void {
        this.modulesList = this.dashboardMenuSvc.allOptions$.pipe(map(itens => {
            this.ignoreNextScroll = true;

            return this.getModulesHandlers(itens.filter(i => i.modulePath))
        }));
    }

    private getModulesHandlers(modules: IMenuDashboardOption[]) {
        const currentModule = this.dashboardMenuSvc.selectedDashboard;

        let items: DashboardMenuHandler[] = modules.map(module => DashboardMenuHandler.factory({ module, clientCallback: this }));
        items = items.filter(item => 
            currentModule === item.parameters.module.modulePath
            || currentModule === item.parameters.module.belongsToPath
        );


        return items;
    }

    onScroll() {
        if (this.ignoreNextScroll) {
            this.restoreScroll();
            this.ignoreNextScroll = false;
            return;
        }

        this.lastScrollValue = this.scrollContainer.nativeElement.scrollTop;
    }

    private restoreScroll() {
        this.scrollContainer.nativeElement.scrollTop = this.lastScrollValue;
    }
} 


