import { Injectable } from "@angular/core";
import { TDriverFeedbackArray } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { Group } from "@colmeia/core/src/business/group";
import { Feedback } from "@colmeia/core/src/interaction/feedback";
import { MatDialogRef } from "@angular/material/dialog";
import {ColmeiaDialogService} from "./dialog/dialog.service";
import {
    BigIconChooseDialogComponent,
    BigIconChooseHandler
} from "../components/dialogs/big-icon-choose-dialog/big-icon-choose-dialog.component";
import { TSerializableArray } from "@colmeia/core/src/persistency/uber-cache";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { Serializable } from "@colmeia/core/src/business/serializable";

export type OnFeedbackBigIconSelected = (feedback: Feedback) => void;
export type OnSerializableSelected = (serializable: Serializable) => void;

@Injectable({
    providedIn: 'root'
})
export class BigIconService {
    private ref: MatDialogRef<any>;

    constructor(
        private dialog: ColmeiaDialogService,
    ) {}

    openFromFeedbackDrivers(drivers: TDriverFeedbackArray, forGroup: Group, callback: OnFeedbackBigIconSelected, disableChangeDriver: boolean = false) {
        const data: BigIconChooseHandler = {
            idGroup: forGroup.getGroupID(),
            drivers,
            callback: this.wrapCallback(callback),
        };

        if (disableChangeDriver) {
            data.disableChangeDriver = true;
        }

        this.ref = this.dialog.open<BigIconChooseDialogComponent, BigIconChooseHandler>({
            dataToComponent: {
                data
            },
            componentRef: BigIconChooseDialogComponent,
            title: 'Opções'
        });
    }

    openFromSerializables(
        serializables: TSerializableArray,
        idGroup: TGlobalUID,
        callback: OnSerializableSelected
    ) {
        const data: BigIconChooseHandler = {
            idGroup,
            serializables,
            callback: this.wrapCallback(callback)
        }

        this.ref = this.dialog.open<BigIconChooseDialogComponent, BigIconChooseHandler>({
            dataToComponent: { data },
            componentRef: BigIconChooseDialogComponent,
            title: 'Opções'
        });
    }

    wrapCallback(callback: OnFeedbackBigIconSelected | OnSerializableSelected) {
        return (callbackArg: any) => {
            callback(callbackArg);
            if (this.ref) {
                this.ref.close();
            }
        }
    }
}
