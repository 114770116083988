import { IPaneJSON } from '../comm-interfaces/barrel-comm-interfaces';
import { UberCache } from '../persistency/uber-cache';
import { hashToArray } from '../tools/utility';
import { constant, TGlobalUID } from './constant';
import { Serializable } from './serializable';
import { IHashTableAlias } from '../tools/utility-types';

export type TPanableArray = Array<IPanable>;
export type TPaneArray = Array<Pane>

export interface IPanable {
    toogle(): void;
    isSelected(): boolean;
    getPane(): Pane;
    getPaneableID(): TGlobalUID;
    setSelected(selection: boolean): void;
};

export class Pane extends Serializable {
    private mutuallyExclusive: boolean;
    private mutuallyExclusiveWithOffOption: boolean;

    private constructor(idPane: TGlobalUID, name: string, muttualyExclusive: boolean, mutuallyExclusiveWithOffOption: boolean) {
        super(constant.objectType.pane, idPane);
        super.setName(name);
        this.mutuallyExclusive = muttualyExclusive;
        this.mutuallyExclusiveWithOffOption = mutuallyExclusiveWithOffOption;
    };

    public rehydrate(json: IPaneJSON): void {
        super.rehydrate(json);
        this.mutuallyExclusive = json.muttualy_exclusive;
    };

    public isMutuallyExclusive(): boolean {
        return this.mutuallyExclusive;
    }

    public getPaneID(): TGlobalUID {return super.getPrimaryID()};

    public checkSelection(panes: TPanableArray): boolean {
        let paneSelected: TPanableArray;
        if (this.mutuallyExclusive) {
            paneSelected = panes.filter((pane)=>pane.getPane().getPaneID() == this.getPaneID() && pane.isSelected());
            return paneSelected.length < 2;
        } else {
            return true;
        }
    };

    public correctSelection(panes: TPanableArray, currentSelected: IPanable): void {
        if (this.mutuallyExclusive) {
            currentSelected.setSelected(true);
            for (let pane of panes) {
                pane.setSelected(pane.getPaneableID() == currentSelected.getPaneableID())
            };
        } else if (this.mutuallyExclusiveWithOffOption) {
            if (currentSelected.isSelected()) {
                currentSelected.setSelected(false);

            } else {
                currentSelected.setSelected(true);
                for (let pane of panes) {
                    if (pane.getPaneableID() != currentSelected.getPaneableID()) {
                        pane.setSelected(false);
                    };
                };
            };
        } else {
            currentSelected.toogle();
        };
    };

    public toJSON(): IPaneJSON {
        let json: IPaneJSON = {...super.toJSON(),
            muttualy_exclusive: this.mutuallyExclusive,
            mutuallyExclusiveWithOffOption: this.mutuallyExclusiveWithOffOption};
        return json;
    };

    public static getPaneArray(): TPaneArray {
        return <TPaneArray>UberCache.getSerializableArray(constant.objectType.pane);
    };

    public static checkWholeSelection(panesArray: TPanableArray): TPaneArray  {
        let usedPanes: IHashTableAlias= {};
        let filtered: TPanableArray;
        let paneWithErrors: TPaneArray = [];
        panesArray.forEach((paneAble) => {usedPanes[paneAble.getPane().getPaneID()] = paneAble.getPane()});
        for (let pane of (hashToArray(usedPanes) as TPaneArray)) {
            if (!pane.isMutuallyExclusive()) {
                filtered = panesArray.filter((paneAble) => {
                        paneAble.getPane().getPaneID() == pane.getPaneID() &&  paneAble.isSelected()});
                if (filtered.length > 1) {
                    paneWithErrors.push(pane);
                };
            };
        };
        return paneWithErrors;
    };

    public static staticFactory(idPane: TGlobalUID): Pane {
        return <Pane>UberCache.uberFactory(idPane, constant.objectType.pane, true)
    };

    public static factoryMessage(json: IPaneJSON): Pane {
        let pane: Pane = <Pane>UberCache.uberFactory(json.primaryID, constant.objectType.pane, false);
        if (pane == null)
            pane = new Pane(json.primaryID, Pane.getJText(json, constant.serializableField.name),
                 json.muttualy_exclusive, json.mutuallyExclusiveWithOffOption);
        pane.rehydrate(json);
        return pane;
    };


};
