
export function prettyPrint<T>(source: T) {
    return JSON.stringify(source, null, 2)
        .replace(/"/g, '')
        .slice(1, -1)
        .trim()
        .split(/,\n/)
        .map(item => item.trim())
        .join('\n')
    ;
}
