<cm-generic-dashboard-edit *ngIf="genericEdit" [handler]="genericEdit">
  <mat-dialog-content>
    <mat-tab-group class="boxed-tabs boxed-dense tabs-wrap mat-tab-padding-2x" animationDuration="200ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Configurações gerais
        </ng-template>
        <ng-template matTabContent>
          <app-crm-graph-basic-data *ngIf="basicCRMHandler" [handler]="basicCRMHandler"></app-crm-graph-basic-data>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon mat-18">settings</mat-icon>&nbsp;
          Engajamento
        </ng-template>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let entry of getForms();">
            <mat-expansion-panel-header>
              <mat-expansion-panel-title>
                {{ nameForMetadata(entry.idMetadata) }}
              </mat-expansion-panel-title>
            </mat-expansion-panel-header>
            <meta-engagement *ngIf="engagementHandlersHash[entry.idMetadata]"
              [handler]="engagementHandlersHash[entry.idMetadata]"></meta-engagement>
            <mat-action-row>
              <mat-checkbox [(ngModel)]="entry.required">
                Obrigatório
              </mat-checkbox>
              <button mat-stroked-button class="button-w-icon" color="warn" (click)="removeMetadata(entry.idMetadata)">
                <mat-icon>delete</mat-icon>&nbsp; Remover
              </button>
            </mat-action-row>
          </mat-expansion-panel>
        </mat-accordion>
        <app-ns-picker *ngIf="addMetadataNSPicker" [handler]="addMetadataNSPicker"
          class="add-metadata mat-elevation-z2"></app-ns-picker>
      </mat-tab> -->
    </mat-tab-group>
  </mat-dialog-content>
</cm-generic-dashboard-edit>