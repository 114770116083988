import { MetaTranslation } from "@colmeia/core/src/shared-business-rules/const-text/meta-translation";
import { constant } from "../../../business/constant";
import { DeliveryProvider } from "../../../core-constants/types";
import { suggestions } from "../../../tools/type-utils";
import { IdField, typedFields, typedScreenOptions } from "../../../tools/utility";
import { defineTranslations, EAllSerializable, getTranslationsFields } from "../all-serializables";


const contentVectorGeneralTranslations = defineTranslations({
    idSerializable: EAllSerializable.ContentVectors,
    fields: {
        loader: 1, // Fonte de dados
        splitter: 2, // Divisor de texto
        embeddingProvider: 3, // Provedor de Vetores
        chunkSize: 4, // Tamanho do Chunk
        chunkOverlap: 5, // Tamanho da sobreposição entre Chunks
        file: 6, // Arquivo
        preview: 7, // Pré-visualização
        generativo: 8, // Generativo
        temperature: 9, // Temperature
        prompt: 10, // Prompt
        vector: 11, // Vetor
        vectors: 12, // Vetores
        separator: 13, // Separador
        separators: 14, // Separadores
        encoding: 15, // Codificador
    },
});


const contentVectorErrorsTranslations = defineTranslations({
    idSerializable: EAllSerializable.ContentVectorsErrors,
    fields: {
        noLoad: MetaTranslation.field(1, "Fonte de dados não selecionada"),
        noSplitter: MetaTranslation.field(2, "Divisor de texto não selecionado"),
        noEmbeddingProvider: MetaTranslation.field(3, "Provedor de embedding não selecionado"),
        noFile: MetaTranslation.field(4, "Arquivo não selecionado"),
        noPromptTemperature: MetaTranslation.field(5, "Temperatura inválida, informe um valor entre 0 e 1"),
        noPromptText: MetaTranslation.field(6, "O campo texto é obrigatório e não pode ser vazio"),
        noPromptUserFunction: MetaTranslation.field(7, "Selecione uma função para o conteúdo dinâmico"),
        noChunkText: MetaTranslation.field(8, "O campo texto é obrigatório e não pode ser vazio"),
        noLLMProvider: MetaTranslation.field(9, "Provedor de LLM não selecionado"),
    },
});


export const contentVectorTranslations = {
    ...contentVectorGeneralTranslations,
    ...contentVectorErrorsTranslations,
}

