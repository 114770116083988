<header>
  <span>Tickets: 8</span>

  <button (click)="navigateToTickets()" mat-mini-fab matTooltip="Ver todos tickets" aria-label="Ver todos tickets" class="round-button">
    <mat-icon class="mat-20">arrow_forward</mat-icon>
  </button>
</header>

<div class="body-container nice-scroll-square-divider">
  <mat-accordion [multi]="true">
    <mat-expansion-panel *ngFor="let filter of filters">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>{{ filter.icon }}</mat-icon>
          {{ filter.name }}

          <button (click)="showKanban($event, filter)" mat-mini-fab matTooltip="Ver kanban" aria-label="Ver kanban" class="round-button">
            <mat-icon class="mat-20">view_column</mat-icon>
          </button>
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <mat-selection-list (selectionChange)="onSelectionChange($event)" [class.none-selected]="!selectedIds.length">
        <ng-container *ngFor="let item of filter.options">
          <mat-list-option [value]="item.id" [selected]="isOptionSelected(item)" togglePosition="after" checkboxPosition="before">
            <div class="filter-option">
              <span>{{ item.nName }}</span>
              <span class="count">{{ item.count }}</span>
            </div>
          </mat-list-option>
        </ng-container>
      </mat-selection-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="quick-filter">
  <ng-container *ngFor="let slot of quickFilterSlots">
    <article [class.has-config]="slotHasConfig(slot)" [class.active]="slotIsActive(slot)" class="slot">
      <button (click)="onClickQuickFilterSlot(slot)" class="slot-btn" matRipple>
        <mat-icon class="mat-18">filter_alt</mat-icon>
        {{ slot }}
      </button>

      <button mat-icon-button [matMenuTriggerFor]="slotMenu" class="menu-btn">
        <mat-icon class="mat-18">more_vert</mat-icon>
      </button>

      <mat-menu #slotMenu="matMenu">
        <button (click)="clearQuickFilter(slot)" mat-menu-item>
          <mat-icon>delete</mat-icon>
          <span>Remover</span>
        </button>
      </mat-menu>
    </article>
  </ng-container>
</div>