import { IInteractionJSON } from './interaction-interfaces';
import { TGlobalUID } from '../business/constant';
import { TArrayID } from '../core-constants/types';


export type TSubscriptionInteractionArray = Array<ISubscriptionInteractionJSON>;

export interface ISubscriptionInteractionJSON extends IInteractionJSON {
    idSubscriptionType: TGlobalUID,
    muteUntilClock: number,
    subject: TGlobalUID,  // preenchido pelo client, não necessariamente será igual ao campo subs
    validUntilClock: number;
    subs?: ISubscription;
};

export interface IAvatarConnectionInteractionJSON extends ISubscriptionInteractionJSON {};
export interface IGroupConnectionInteractionJSON extends ISubscriptionInteractionJSON {};


export interface ISubscription {
    idSubscription: TGlobalUID;
    idSocialContext: TGlobalUID;
    fromSubscriber: TGlobalUID;
    toSubject: TGlobalUID;
    idAvatarSubscriber: TGlobalUID;
    idPlayerSubscriber: TGlobalUID;
    pairSubscription: TArrayID;
    fromGroupGenealogy: TArrayID;
    toGroupGenealogy: TArrayID;
    
    followGenealogy?: TArrayID;
};

export interface ISubjectSubscriber {
    fromSubscriber: TGlobalUID;
    toSubject: TGlobalUID;
    idFromObjectType: TGlobalUID;
    idToObjectType: TGlobalUID;
};

export interface IInteractionActors extends ISubjectSubscriber {
    isSubscription: boolean;
};


export interface ISubjectSubscriberInfo extends ISubjectSubscriber {
    text: string;
}


