import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { TSerializableArray } from '@colmeia/core/src/persistency/uber-cache';
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import {SpinType} from "../services/screen-spinner.service";
import {IComponentParameter, IRootCallBack} from "./component-comm/basic";


export enum DefaulActionOnError {
    useButtonsToDecide = 1,
    ignore,
    standardErrorMessage,
    callBackOnError,
}

export type InfraErrorCallbackFunction = (infraError: InfraError) => void;

export interface StandardRejectFromServer {
    friendlyError: FriendlyMessage;
};


export interface InfraError {
    standardRejection: StandardRejectFromServer;
};


export interface InfraClientCallBack extends IRootCallBack {
    getClientCallBack: InfraErrorCallbackFunction;
};


export interface IInfraOptions {
    optionButtons: TSerializableArray;
    defaultActionOnError: DefaulActionOnError;
    spinType: SpinType;
}


export interface IInfraParameters extends IComponentParameter, IInfraOptions {
    idPlayer: TGlobalUID,
    idAvatar: TGlobalUID,
    clientCallback: InfraClientCallBack;
};



export interface ClientInfraResponse {
    executionOK: boolean;
    friendlyMessage: FriendlyMessage;
    response?: IResponse;
    responseButton: TGlobalUID;
};



