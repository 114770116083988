import { MultimediaTag } from './multi-media-tag';
import { IMultimediaInstanceJSON } from '../comm-interfaces/multi-media-interfaces';
import {constant, TGlobalUID} from '../business/constant';
import { newGlobalUID } from '../persistency/uber-cache';
import { MMconstant } from './barrel-multimedia';
import { SelectedFile } from './selected-file';
import { IServerFileInfo } from '../comm-interfaces/aux-interfaces';
import { getUniqueStringID, isValidString, isValidRef } from '../tools/utility';
import { EMultimediaNature, MultiMediaType } from './multi-media-type';
import { I360Media } from '../core-constants/bot';
import {EMimeTypes} from "./file-interfaces";

export type TMultimediaInstanceArray =  Array<MultimediaInstance>;

export class MultimediaInstance {
    
    private multimediaKey: string;
    private multimediaTag: MultimediaTag;
    private fileName: string;
    private displayMediaName: string;
    private idMedia: string;
    private mimeType: TGlobalUID;
    private hashFile: string;
    private selectedFile: SelectedFile;
    private idThumbnailParentKey: TGlobalUID;
    private idMultimidiaInstance: TGlobalUID;


    private constructor(
        multimediaTag: MultimediaTag,
        idMedia: string,
        displayMediaName?: string,
        private fileSize: number = 0,
    ) {
        this.multimediaTag = multimediaTag;
        this.idMultimidiaInstance = getUniqueStringID();
        this.multimediaKey = this.generateNewFileID();
        this.idMedia = idMedia;
        this.displayMediaName = displayMediaName;
    };


    
    private generateNewFileID(): string {return newGlobalUID()};

    public isSmartShare(): boolean {
        return this.selectedFile && this.selectedFile.isSmartShare();
    }

    public getMapInstanceID(): string {
        return this.idMultimidiaInstance;
    };
    
    public setClientFileInfo(file: SelectedFile): void {
        this.selectedFile = file;
    };

    public getClientFileInfo(): SelectedFile {
        return this.selectedFile;
    };

    public setThumbnailParentKey(idMultimediaParentKey: TGlobalUID): void {
        this.idThumbnailParentKey = idMultimediaParentKey;
    };

    public getThumbnailParentKey(): TGlobalUID {return this.idThumbnailParentKey; };

    public canUpload(): boolean {
        return this.selectedFile && ! this.isSmartShare();
    };

    public isChanged(): boolean {
        return this.selectedFile ? true: false;
    };

    public getFileName(): string {
        return this.fileName
    };

    public getSelectedFile() {
        return this.selectedFile;
    }

    private removeExtensionFromFile(fileName: string): string {
        const split = fileName.split('.');
        return split.length > 1
            ? split.slice(0, -1).join('.')
            : fileName;
    }

    public getMyThumbnailFileName() : string {
        const fileWithoutExt: string = this.removeExtensionFromFile(this.getClientFileInfo().getCurrentName());
        return MMconstant.thumnailPrefix + fileWithoutExt + '.' +  MMconstant.extension.png
    };


    public getMimeType(): string {
        if (this.isChanged()) {
            return this.selectedFile.getClientCachedFile().getMimeType()
        } else {
            return this.mimeType;
        }  
    }

    public isVideo(): boolean {
        const mimeType = this.getMimeType();
        return mimeType && MultiMediaType.getMutimediaTypeFromMime(mimeType).isVideo();
    }

    public isAudio(): boolean {
        const mimeType = this.getMimeType();
        return mimeType && MultiMediaType.getMutimediaTypeFromMime(mimeType).isAudio();
    }

    public isImage(): boolean {
        const mimeType = this.getMimeType();
        return mimeType && MultiMediaType.getMutimediaTypeFromMime(mimeType).isImage();
    }

    public isUnknown(): boolean {
        const mimeType = this.getMimeType();
        return mimeType && MultiMediaType.getMutimediaTypeFromMime(mimeType).isUnknown();
    }

    public setMimeType(mimeType: string) {
        this.mimeType = mimeType;
    }

    public getTag(): MultimediaTag {return this.multimediaTag};
    public getFileSize(): number {return this.fileSize};
    
    public getMultiMediaInstanceUID(): string {
        // Impedir codigo legado de subir imagem sem extensão
        if(this.multimediaKey.split('.').length <= 1) {
            // throw new Error("MultimediaInstance.getMultiMediaInstanceUID Agora precisa ter a extensão junto com o hash da imagem");
        }
        return this.multimediaKey.replace('#', '%23')
    }
    public getMultimediaTag(): MultimediaTag {return this.multimediaTag;};
    public createNewMediaInstanceUID(fileExtension: string): void {
        this.multimediaKey = this.generateNewFileID() + '.' + fileExtension;
    }

    public getMultmediaKey(): string {return this.multimediaKey;};

    /**
     * Returns file extension(ex: mp4)
     * @returns string
     */
    public getInstanceFileExtension(): string {
        return this.fileName.split('.').pop();
    }

    public getFileIdMedia(): string {
        let idMedia: TGlobalUID;
        if (this.selectedFile) {
            idMedia = this.getClientFileInfo().getClientCachedFile().getFileUrl();
        } else {
            idMedia = this.idMedia;
        }
        return idMedia;    
    }

    setIdMedia(idMedia: TGlobalUID): void {this.idMedia = idMedia}
    getIdMedia(): TGlobalUID {return this.idMedia}

    /**
     * returns idMedia if already set
     * otherwise returns localFileUrl
     * @returns string
     */
    public getBestMedia(): string {
        return this.getFileIdMedia();
    }

    public static factoryMessage(mmInstance: IMultimediaInstanceJSON): MultimediaInstance {
        let mi: MultimediaInstance = MultimediaInstance.getNewMultimediaInstance(
                                          MultimediaTag.staticFactory(mmInstance.idMultiMediaTag), mmInstance.idMedia);
        mi.multimediaKey = mmInstance.multiMediaKey;
        mi.fileName = mmInstance.fileName;
        mi.mimeType = mmInstance.mimeType;
        mi.hashFile = mmInstance.fileHash;
        mi.fileSize = mmInstance.fileSize;
        mi.idThumbnailParentKey = mmInstance.idThumbnailParentKey;
        return mi;
    };

    public static getNewMultimediaInstance(multimediaTag: MultimediaTag, idMediaKey: string): MultimediaInstance {
        return new MultimediaInstance(multimediaTag, idMediaKey);
    };



    public static getNewMultimediaInstanceFromSelectedFile(selectedFile: SelectedFile): MultimediaInstance {
        const mmInstance = new MultimediaInstance(
            MultimediaTag.staticFactory(selectedFile.getIDMediaTag()),
            selectedFile.getIDMedia(),
            selectedFile.getCurrentName(),
            MultimediaInstance.getSizeOfFile(selectedFile),
        );
        mmInstance.setClientFileInfo(selectedFile);
        return mmInstance;
    };

    static getSizeOfFile(selectedFile: SelectedFile): number | undefined {
        try {
            return selectedFile.getClientCachedFile().getInfoToServer().size;
        } catch {}
    }

    public iss(multimediaInstance: MultimediaInstance): boolean {
        return this.multimediaKey == multimediaInstance.getMultmediaKey();
    };

    public is(multimediaKey: TGlobalUID): boolean {
        return this.multimediaKey == multimediaKey;
    };

    public isVCard(): boolean {
        return this.getMultimediaType().isVCard();
    }

    public isPDF(): boolean {
        return this.getMultimediaType().isPDF();
    }

    public toJSON(): IMultimediaInstanceJSON {
        let json: IMultimediaInstanceJSON = {
            multiMediaKey: this.multimediaKey,
            idMultiMediaTag: this.multimediaTag.getMultiMediaTagID(),
            fileName: this.fileName,
            fileSize: this.fileSize,
            idMedia: this.idMedia,
            mimeType: this.mimeType, 
            fileHash: this.hashFile,
            idThumbnailParentKey: this.idThumbnailParentKey,
        };

        if (this.selectedFile) {
            const basicInfo: IServerFileInfo = this.selectedFile.getClientCachedFile().getInfoToServer();
            json.fileHash = basicInfo.hash,
            json.mimeType = basicInfo.type,
            json.idMedia = basicInfo.idMedia,
            json.fileSize = basicInfo.size,
            json.idMultiMediaTag = this.selectedFile.getIDMediaTag(),
            json.fileName = this.selectedFile.getCurrentName();
        }

        return json;
    };
  
    public hasNature(mmNature: EMultimediaNature) {
        return MultiMediaType.getMutimediaTypeFromMime(this.getMimeType()).getMultimediaNature() == mmNature;
    }

    public getI360Media(): I360Media {
        return {
            idMedia: this.getHashFromSelectedFile(),
            mymeType: this.getMimeType(),
            idMediaType: this.getMediaTypeID(),
            displayMediaName: this.displayMediaName,
            size: this.fileSize
        }
    }

    public getI360ThumbMedia(size: number): I360Media {
        return {
            idMedia: this.idMedia,
            displayMediaName: this.displayMediaName,
            idMediaType: MMconstant.type.image,
            mymeType: EMimeTypes.Png,
            size,
            isThumb: true
        }
    }

    public getMultimediaType(): MultiMediaType {
        return MultiMediaType.staticFactory(this.getMediaTypeID());
    }

    public getMediaTypeID(): TGlobalUID {
        return MultiMediaType.gertMultimediaIDFromMime(this.getMimeType());
    }

    public getHashFromSelectedFile(): string {
        if (this.selectedFile) {
            return this.selectedFile.getHashFile();
        };
        return null;
    }

    public _setFileSize(size: number): void {
        this.fileSize = size;
    }

    public setHashFileAsMediaID(): void {
        this.hashFile = this.getIdMedia();
    }

    public setFileNameAsMediaID(): void {
        this.fileName = this.getIdMedia();
    }
};
