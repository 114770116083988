import { values } from "../../../tools/utility";
import { IBasicToolbarElement, TBasicToolbarConnectConfig, TReferencedObject, TToolbarElementsDB } from "./config-toolbar.types";

export function createToolbarElementsDB(elements: IBasicToolbarElement[]): TToolbarElementsDB {
    const hash: TToolbarElementsDB = {};
    for (const element of elements) hash[element.referencedObject] = element;
    return hash;
}

export function enumToCanDragOn<T extends Record<string, TReferencedObject>>(items: T): TBasicToolbarConnectConfig {
    return toCanDragOn(values(items));
}

export function toCanDragOn(items: TReferencedObject[]): TBasicToolbarConnectConfig {
    const canDragOn: TBasicToolbarConnectConfig = {};
    items.forEach(item => canDragOn[item] = []);
    return canDragOn;
}
