import { RootComponent } from 'app/components/foundation/root/root.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TColmeiaDialogComponentDescriptor } from "../../../dialogs/dialog/dialog.component";
import { NsDeleterService } from "../../../../services/ns-deleter-service";
import {
    INSDependencyDescription, TDpendencyObjectType, INonSerializable, ENonSerializableObjectType,
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { EDependencyMode } from "@colmeia/core/src/shared-business-rules/delete-core/delete-interfaces";
import {
    getReadableNonSerializableDependencyType,
} from "@colmeia/core/src/shared-business-rules/enum-db";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { isInsideElement } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { LookupService } from 'app/services/lookup.service';
import { GenericTableHandler, IGenericTableRowFieldText, IGenericTableRowFieldTinyIcon, GenericTableFieldCreator, IOnRowFieldClick } from 'app/handlers/generic-table/generic-table.handler';
import { isIn, isKeyIn, isValidRef } from '@colmeia/core/src/tools/utility';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IServerColmeiaTag, ETagType } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { SessionService } from 'app/services/session.service';
import { getSNFromNS } from '@colmeia/core/src/shared-business-rules/non-serializable-id/ns-client-functions';
import { OnChange } from 'app/model/client-utility';
import { nonSerializableFriendlyNameTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly';
import { Serializable } from '@colmeia/core/src/business/serializable';



interface Row {
    name: IGenericTableRowFieldText;
    type: IGenericTableRowFieldText;

    dependencies: IGenericTableRowFieldTinyIcon;
    goTo: IGenericTableRowFieldTinyIcon;
}

@Component({
    selector: 'app-dependencies-dialog',
    templateUrl: './dependencies-dialog.component.html',
    styleUrls: ['./dependencies-dialog.component.scss']
})
export class DependenciesDialogComponent extends RootComponent<
    | 'actions'
    | 'objectType'
    | 'name'
> implements OnInit {

    private modeByIndex: { [key: number]: EDependencyMode } = {
        0: EDependencyMode.DependsOnMe,
        1: EDependencyMode.IDependOn
    };

    @OnChange()
    currentElement: INSDependencyDescription;

    public onChangeCurrentElement(): void {
        this.data.contentText = this.currentElement.name;
    }

    public mode: EDependencyMode = EDependencyMode.DependsOnMe;
    public dependencies: { [EDependencyMode.IDependOn]: INSDependencyDescription[], [EDependencyMode.DependsOnMe]: INSDependencyDescription[] } = {
        [EDependencyMode.DependsOnMe]: [],
        [EDependencyMode.IDependOn]: []
    };
    public selectedIndex: number = 0;
    public childDependencies: boolean = false;
    public displayedColumns: string[] = ['name', 'type', 'actions'];
    private isRequesting: boolean = false;
    public genericTableHandler: GenericTableHandler<Row>;
    public mapRowToDescriptor: WeakMap<Row, INSDependencyDescription> = new WeakMap();

    constructor(
        private deleteSVC: NsDeleterService,
        private dashboardSvc: DashBoardService,
        private lookupSvc: LookupService,
        private matSnackSvc: MatSnackBar,
        private sessionSvc: SessionService,
        private ref: MatDialogRef<DependenciesDialogComponent, INSDependencyDescription>,
        @Inject(MAT_DIALOG_DATA)
        public data: TColmeiaDialogComponentDescriptor<INSDependencyDescription>
    ) {

        super({
            actions: gTranslations.common.actions,
            objectType: gTranslations.fragments.objectType,
            'name': gTranslations.common.name,
        });

        this.currentElement = data.getParamsToChildComponent();

    }

    public initGenericTable(): void {
        this.genericTableHandler = GenericTableHandler.factory<Row>({
            enableEqualSize: true,
            clientCallback: this,
            rowNames: {
                name: this.translations.name,
                type: this.translations.objectType,

                dependencies: "Dependência",
                goTo: 'Ir para o item',
            },
            enableRefresh: true,
        });
    }

    public async onRowFieldClick(information: IOnRowFieldClick<Row>) {
        const descriptor: INSDependencyDescription = this.mapRowToDescriptor.get(information.row);

        switch (information.columnName) {
            case 'goTo': {
                this.goTo(descriptor);
            };
            break;
            case 'dependencies': {
                if (!(await this.canViewItem(descriptor))) {
                    this.matSnackSvc.open("Não é permitido ver esse elemento.", "Fechar", { duration: 4000 });
                    return;
                }
                

                this.viewItem(descriptor);
            };
            break;
        }
    }


    public refresh(): void {
        this.initGenericTable();
    }
    

    public async canViewItem(descriptor: INSDependencyDescription) {
        if (descriptor.type === ENonSerializableObjectType.colmeiaTags) {
            const ns: IServerColmeiaTag = await this.lookupSvc.getSingleLookupElement<IServerColmeiaTag>(descriptor.id);
            if (ns.tagType === ETagType.colmeia) return false;
        }

        return true;
    }
    
    public getEntityTypeText(type: TDpendencyObjectType) {
        // if (isIn(nonSerializableFriendlyNameTranslations)(type)) {
        //     return Serializable.getTranslation(nonSerializableFriendlyNameTranslations[type]) ?? type;
        // }
        return type;
    }

    public async getRows(): Promise<Row[]> {
        await this.request();
        const entities: INSDependencyDescription[] = this.dependencies[this.mode];
        
        const rows = entities.map((entity: INSDependencyDescription) => ({
            name: GenericTableFieldCreator.text(entity.name),
            type: GenericTableFieldCreator.text(this.getEntityTypeText(entity.type)),
            dependencies: { ...GenericTableFieldCreator.icon('playlist_add_check'), enableOnClick: true } as IGenericTableRowFieldTinyIcon,
            goTo: { ...GenericTableFieldCreator.icon('near_me'), enableOnClick: true } as IGenericTableRowFieldTinyIcon,
        }) );

        rows.forEach((row, index) => this.mapRowToDescriptor.set(row, entities[index]));

        return rows;
    }
    
    public async ngOnInit() {
        this.initGenericTable();
    }

    private requestingPromise: Promise<void>;

    private async request(): Promise<void> {
        if (isValidRef(this.requestingPromise)) {
            return this.requestingPromise;
        }
        this.requestingPromise = (async () => {
            this.isRequesting = true;
            const dependencies: INSDependencyDescription[] = await this.deleteSVC.getDependenciesRows(this.currentElement.id, this.mode, this.childDependencies);
            this.dependencies[this.mode] = dependencies;
            this.isRequesting = false;
        })();
        await this.requestingPromise;
        this.requestingPromise = undefined;
        return;
    }

    public async onTabIndexChange(index: number): Promise<void> {
        this.mode = this.modeByIndex[index];
        this.initGenericTable();
    }

    public async onChildChange(): Promise<void> {
        this.initGenericTable();
    }

    private async changeTab(index: number) {
        this.selectedIndex = index;
        await this.onTabIndexChange(index);
    }

    public async viewItem(descriptor: INSDependencyDescription): Promise<void> {
        if (!this.isRequesting) {
            this.currentElement = descriptor;
            this.changeTab(0);
        }
    }

    public showGoTo(descriptor: INSDependencyDescription): boolean {
        return this.dashboardSvc.isValueAnAllowedNsTypeOnGoTo(descriptor.type);
    }

    public async goTo(descriptor: INSDependencyDescription): Promise<void> {
        if (this.dashboardSvc.isValueAnAllowedNsTypeOnGoTo(descriptor.type)) {
            const id: string = descriptor.id;
            const res: INonSerializable[] = await this.lookupSvc.getBatchNonSerializables([id]);
            const ns: INonSerializable = res && res[0];
            this.dashboardSvc.nsTypeToGenericNonSerializableService[descriptor.type].goToDetails(ns);
            this.ref.close();
        } else {
            this.matSnackSvc.open("Não é possível ir ao elemento, estamos trabalhando nisso.", "Fechar", { duration: 4000 });
        }
    }

    public readableDependencyObject(type: TDpendencyObjectType): string {
        return isInsideElement(type) ? "inside": getReadableNonSerializableDependencyType(type);
    }

}
