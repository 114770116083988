import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from "@angular/core";
import { defaultTimeWindows, ECRMTimeWindowField, ECRMTimeWindows, ICRMTimeWindowConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";
import { DatePickerHandler, EDatePickerMode, EDatePickerType } from "app/handlers/date-picker.handler";
import { DatetimeConversions } from "app/services/datetime-conversions";
import { UserSettingsService } from "app/services/user-settings.service";
import { DatePickerComponent } from "../foundation/date-picker/date-picker.component";
import { cloneDeep, values } from "lodash";

@Component({
    selector: 'app-time-window-picker',
    styleUrls: ['./ticket-time-window-picker.component.scss'],
    templateUrl: './ticket-time-window-picker.component.html'
})
export class TimeWindowPicker implements OnInit, OnChanges {

    @Input()
    public loading?: boolean = false;

    @Input()
    public selectedTimeWindow?: ICRMTimeWindowConfig;

    @Input()
    public hideCustomSelection?: boolean;

    @Output()
    timeWindowSelected = new EventEmitter<ICRMTimeWindowConfig>();

    public customTimeWindowDateRangePicker: DatePickerHandler = this.getDateRangePicker();

    public selectedCustomField!: ECRMTimeWindowField;

    private dateTimeConversor: DatetimeConversions;
    public timeWindowLabels: { [key in ECRMTimeWindows]?: string } = {
        [ECRMTimeWindows.t7]: '7 dias',
        [ECRMTimeWindows.t15]: '15 dias',
        [ECRMTimeWindows.t30]: '30 dias',
    }

    public timeWindowFieldLabels: { [key in ECRMTimeWindowField]?: string } = {
        [ECRMTimeWindowField.updatedAt]: 'Atualizado',
        [ECRMTimeWindowField.createdAt]: 'Criado',
    }

    public timeWindowOptions: ICRMTimeWindowConfig[] = cloneDeep(defaultTimeWindows);

    public timeWindowFields: ECRMTimeWindowField[] = values(ECRMTimeWindowField);

    constructor(
        private userSettingsSvc: UserSettingsService,
    ) {
        this.dateTimeConversor = this.userSettingsSvc.getDatetimeConversor();
    }

    ngOnInit(): void {
        this.customTimeWindowDateRangePicker = this.getDateRangePicker(this.selectedTimeWindow);
        this.selectedCustomField = this.selectedTimeWindow.field;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.hideCustomSelection = coerceBooleanProperty(this.hideCustomSelection);
    }

    private getDateRangePicker(current?: ICRMTimeWindowConfig) {
        const isCustom = current?.id === ECRMTimeWindows.custom;
        return new DatePickerHandler({
            onRangePick: (start: number, end: number) => {
                this.setTimeWindowConfig(this.selectedTimeWindow.field, {
                    field: this.selectedTimeWindow.field,
                    id: ECRMTimeWindows.custom,
                    minTimestamp: start,
                    maxTimestamp: end,
                });
            },
            currentRange: isCustom ? [current.minTimestamp, current.maxTimestamp] : undefined,
            max: Date.now(),
            type: EDatePickerType.Range,
            mode: EDatePickerMode.DATETIME,
        });
    }

    private setTimeWindowConfig(fieldType: ECRMTimeWindowField, config: ICRMTimeWindowConfig) {
        config.field = fieldType;

        this.customTimeWindowDateRangePicker = this.getDateRangePicker(config);
        this.selectedTimeWindow = config;

        this.timeWindowSelected.next(config);
    }

    public getTimeWindowLabel(timeWindow: ICRMTimeWindowConfig = undefined, includeField: boolean = true) {
        timeWindow ||= this.selectedTimeWindow;

        let label: string = ''

        if (includeField) {
            label += this.timeWindowFieldLabels[timeWindow.field] + ' - ';
        }

        if (timeWindow?.id === ECRMTimeWindows.custom) {

            const startDate = this.dateTimeConversor.timeToDateTimeString(timeWindow.minTimestamp);
            const startEnd = this.dateTimeConversor.timeToDateTimeString(timeWindow.maxTimestamp);

            label += `${startDate} - ${startEnd}`;

        } else {
            label += this.timeWindowLabels[timeWindow?.id];
        }

        return label;
    }

    public handleCustomClick(event: PointerEvent, fieldType: ECRMTimeWindowField, customRangePicker: DatePickerComponent) {
        this.selectedTimeWindow.field = fieldType
        customRangePicker.openCalendarDialog(event);
    }

}