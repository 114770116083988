<section class="white-box basic-data">
  <form>
    <div class="field-container">
      <mat-form-field class="full-width-field">
        <input matInput [(ngModel)]="transaction.nName" [placeholder]="translations.name" type="text" name="name" required>
        <mat-error>{{ translations.fieldRequired }}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex-break"></div>

    <!-- <div class="field-container">
      <mat-form-field class="full-width-field">
        <input matInput [(ngModel)]="transaction.userMessage.availableInfoText" [placeholder]="translations.availableInfoText" type="text" name="availableInfoText" required>
        <mat-error>{{ translations.fieldRequired }}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex-break"></div>

    <div class="field-container">
      <mat-form-field class="full-width-field">
        <input matInput [(ngModel)]="transaction.userMessage.missingText" [placeholder]="translations.missingText" type="text" name="missingText" required>
        <mat-error>{{ translations.fieldRequired }}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex-break"></div>

    <div class="field-container">
      <mat-form-field class="full-width-field">
        <input matInput [(ngModel)]="transaction.userMessage.doYouConfirm" [placeholder]="translations.doYouConfirm" type="text" name="doYouConfirm" required>
        <mat-error>{{ translations.fieldRequired }}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex-break"></div>

    <div class="field-container">
      <mat-form-field class="full-width-field">
        <input matInput [(ngModel)]="transaction.userMessage.greetingText" [placeholder]="translations.greetingText" type="text" name="greetingText" required>
        <mat-error>{{ translations.fieldRequired }}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex-break"></div>

    <div class="field-container">
      <mat-form-field class="full-width-field">
        <input matInput [(ngModel)]="transaction.userMessage.exitCommand" [placeholder]="translations.exitCommand" type="text" name="exitCommand" required>
        <mat-error>{{ translations.fieldRequired }}</mat-error>
      </mat-form-field>
    </div>

    <div class="flex-break"></div> -->

<!--    <div class="field-container">-->
<!--      <mat-checkbox class="confirm-data-checkbox" [(ngModel)]="transaction.confirmData" name="confirmData">{{ translations.confirmData }}</mat-checkbox>-->
<!--    </div>-->

    <div class="flex-break"></div>
  </form>
</section>
