import { TReferencedObject } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { IComponentParameter } from "app/model/component-comm/basic";
import { DiagramDialogComponentHandler } from "../../diagram-editor/diagram-editor.types";
import { EGraphElementInfoBotHandlerTabs } from "../bot-graph-element-info-bot/bot-graph-element-info-bot.handler";

export interface IBpmGraphElementInfoClientCallback {
    close(): void;
}

export enum EBpmGraphElementInfoMode {
    Create = 'create',
    Edit = 'edit',
}
export interface IBpmGraphElementInfoEntity {
    mode: EBpmGraphElementInfoMode,
    botType: TReferencedObject,
    entity?: INonSerializable,
    idParentHosted?: string,
    tabToOpen?: EGraphElementInfoBotHandlerTabs;
    preSelectedAction?: TReferencedObject;
}
export interface IBpmGraphElementInfoEntityCreate extends IBpmGraphElementInfoEntity {

}
export interface IBpmGraphElementInfoEntityEdit extends IBpmGraphElementInfoEntity {
}

export interface IBpmGraphElementInfoParameters extends IComponentParameter {
    workElement: IBpmGraphElementInfoEntityEdit | IBpmGraphElementInfoEntityCreate;
    clientCallback: IBpmGraphElementInfoClientCallback;
    hasSaved?: boolean;
}

export class BpmGraphElementInfoHandler extends DiagramDialogComponentHandler {
    private constructor(parameters: IBpmGraphElementInfoParameters) { super(parameters); }
    public getComponentParameter(): IBpmGraphElementInfoParameters { return super.getComponentParameter() as IBpmGraphElementInfoParameters }
    public static factory(parameters: IBpmGraphElementInfoParameters) { return new BpmGraphElementInfoHandler(parameters); }
}
