import {Interaction} from '@colmeia/core/src/interaction/interaction';
import {Injectable} from '@angular/core';
import {FriendlyMessage} from '@colmeia/core/src/error-control/friendly-message';
import {EConfirmationType, TGlobalUID} from '@colmeia/core/src/core-constants/types';
import {Participant} from '@colmeia/core/src/business/participant';
import {DeleteInteraction} from '@colmeia/core/src/interaction/interactions/deleted-interaction';
import {UberCache} from '@colmeia/core/src/persistency/uber-cache';
import {constant} from '@colmeia/core/src/business/constant';
import {
    IFeedbackCreatorRequest,
    IFeedbackGetDetailRequest,
    ISingleInteractionRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import {apiRequestType} from "@colmeia/core/src/request-interfaces/message-types";
import {IConfirmationInfo, IFeedbackDetailResponse} from "@colmeia/core/src/request-interfaces/response-interfaces";
import {getClock, throwPropagateErrorField} from '@colmeia/core/src/tools/utility';
import {ESCode} from '@colmeia/core/src/error-control/barrel-error';
import {SessionService} from "./session.service";
import {RequestBuilderServices} from "./request-builder.services";
import {ServerCommunicationService} from "./server-communication.service";
import {SocketService} from "./socket.service";
import {MultimediaService} from "./multimedia.service";
import {HardwareLayerService} from "./hardware";
import {TMultimediaInstanceArray} from "@colmeia/core/src/multi-media/multi-media-instance";
import {ClientInfraResponse} from "../model/component/client-infra-comm";
import {MultimediaObject} from "@colmeia/core/src/multi-media/multi-media-object";
import {IInfraParameters} from "../model/client-infra-comm";
import {AttendanceService} from './attendance.service';
import {ChainedInteraction} from "@colmeia/core/src/interaction/chained-interaction";
import { Citation } from '@colmeia/core/src/interaction/interactions/standard-interaction';
import { InteractionPersistorServices } from './interaction-persistor.service';
import { EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import { sendInteractionOnAttending, getInteractionThreadInfo } from '@colmeia/core/src/rules/interaction-filter';
import { IConfirmFeedbackFilterRequest, IConfirmFeedbackFilterResponse } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter-interfaces';
import { TServerCheckFeedbackArray } from '@colmeia/core/src/rules/dynamic-filter/dynamic-filter';
import {EmbeddedChatService} from "./embedded-chat.service";
import { SupervisorAgentService } from 'app/supervisor-agent.service';
import { ICustomerAgentDataWithClient } from '@colmeia/core/src/shared-business-rules/attendence-follow-up/attendence-follow-up-req-res';
import { EConversationStatusCC } from '@colmeia/core/src/comm-interfaces/service-group-interfaces';
import { Subject } from 'rxjs';
import { IUserErrorJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { errorCodes } from '@colmeia/core/src/error-control/error-definition';
import { GroupShortcutHandlerService } from 'app/services/group-shortcut-handler.service';

const spinReaction = {
    [constant.interactionType.annotation.group.archive] : true
};

export interface ISaveInteractionResponse {
    friendlyMessage: FriendlyMessage,
    isAlreadyAttendanceFinished: boolean
}

@Injectable()
export class GroupChatServices {
    private session: SessionService;
    private rbs: RequestBuilderServices;
    private serverAPI: ServerCommunicationService;
    private socket: SocketService;
    private persistor: InteractionPersistorServices;
    private static alreadySentReadConf: {[idPublishingGroup: string]: number} = {};
    private groupShortchutSvc: GroupShortcutHandlerService;

    constructor(
        private multimediaService: MultimediaService,
        private hardware: HardwareLayerService,
        private attendanceSvc: AttendanceService,
        private sessionSVC: SessionService,
        private embedded: EmbeddedChatService,
        private supervisorAgentService: SupervisorAgentService
    ) { };

    public async deleteInteraction(interaction: Interaction, participant: Participant): Promise<FriendlyMessage> {
        const deleteInteraction: DeleteInteraction = DeleteInteraction.getNewDeleteInteraction(participant, interaction);
        const friendly: FriendlyMessage = await this.saveInteraction(deleteInteraction, [], null);
        return friendly;
    }

    public setGroupShortchutSvc(service: GroupShortcutHandlerService) {
        this.groupShortchutSvc = service;
    }

    public setDependencySessionService(sessionService: SessionService): void {this.session = sessionService; };
    public setDependencyRequestBuilderServices(rbs: RequestBuilderServices): void {this.rbs = rbs; };
    public setDependencyServerAPI(serverAPI: ServerCommunicationService): void {this.serverAPI = serverAPI; };
    public setDependencySocket(socket: SocketService): void {this.socket = socket};
    public setDependencySocketPersistor(persistor: InteractionPersistorServices): void {this.persistor = persistor;};

    public hasMultimedia(multimediaInstanceArray: TMultimediaInstanceArray): boolean {
        return multimediaInstanceArray && multimediaInstanceArray.length > 0;
    };

    public async saveReaction(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedbackID: TGlobalUID,
        feedbackInteractionTypeID: TGlobalUID, idParticipant: TGlobalUID, idGroup: TGlobalUID): Promise<ClientInfraResponse> {

        const infra: IInfraParameters = spinReaction[feedbackID] ? this.rbs.getContextNoCallBackSpinnningParameters()
             : this.rbs.getContextNoCallBackNoSpinnningParameters();

        const request: IFeedbackCreatorRequest = {
            ...this.rbs.secureBasicRequestForCustomGroup(apiRequestType.feedback.feedbackCreator, idGroup),
            feedbackData: {
                idFeedback: feedbackID,
                idParticipant: idParticipant,
                idInteractionFeedbackType: feedbackInteractionTypeID,
                idInteractionTypeParent: interactionTypeID,
                idInteractionParent: interactionID,
                threadInfo: getInteractionThreadInfo(interactionID)
            }
        };
        if(this.sessionSVC.isEmbeddedChat()) {
            request.feedbackData.idHandShake = this.embedded.getHandshakeID();
        }
        const response: ClientInfraResponse = await this.serverAPI.managedRequest(infra, request);
        if (response.executionOK && response.response) {
            if (response.executionOK) {
                for (const idInteraction in response.response.sentInteractions) {
                    this.socket.checkIFRecevedBySocket(response.response.sentInteractions[idInteraction]);
                }
            };
        }

        return response;
    };

    public async sendReadInformation(idPublishingGroup: string, idInteractionType: TGlobalUID): Promise<void> {
        if (! GroupChatServices.alreadySentReadConf[idPublishingGroup]) {
            GroupChatServices.alreadySentReadConf[idPublishingGroup] = getClock();
            const confirmation: IConfirmationInfo = {
                idPubGroups: idPublishingGroup, type: EConfirmationType.read, idInteractionType
            };
            this.socket.sendConfirmation(confirmation);
        };
    }

    public async interactionDetails(idInteraction: TGlobalUID, cursor: string, idFeedback: TGlobalUID): Promise<IFeedbackDetailResponse | boolean> {
        const infra = this.rbs.getContextNoCallBackNoSpinnningParameters(),
              request: IFeedbackGetDetailRequest = {
                  idFeedback: idFeedback,
                  ...this.rbs.secureBasicRequest(apiRequestType.feedback.details),
                  idInteraction,
                  cursor
              },
              infraResponse = await this.serverAPI.managedRequest(infra, request);
        if (infraResponse.executionOK) {
           return (<IFeedbackDetailResponse> infraResponse.response);
        }
        return false;
    }

    private interactionToCitation(interaction: Interaction, idInteractionParent: TGlobalUID): ChainedInteraction {
        const parent: Interaction = Interaction.staticFactory(idInteractionParent);
        interaction.removeUberCache(true); // ROLLBACK
        const newInteraction: Citation = Citation.getNewCitation(
            interaction.getParticipant(),
            parent,
            interaction.getMessage()
        );

        if (interaction.getWebChatConfig()) {
            newInteraction.setWebChatConfig(interaction.getWebChatConfig());
        }

        if (interaction.getIslandEventsId()) {
            newInteraction.setIslandEventsId(interaction.getIslandEventsId());
        }

        return newInteraction;
    }

    private assembleHandshakeInfo(interaction: Interaction): void {
        interaction.setEmbedded(this.embedded.getHandshakeID());
    }

    private assembleHandshakeInfoForAttendant(interaction: Interaction, attendenceIdInteraction: string): void {
        const startInteraction: Interaction = Interaction.staticFactory(attendenceIdInteraction);
        if(startInteraction.isEmbedded()) {
            interaction.setEmbedded(startInteraction.getEmbedded());
        }
    }

    public async safeSaveInteraction(
        interaction: Interaction,
        multimediaInstanceArray: TMultimediaInstanceArray = [], 
        idGeneralAnswer: TGlobalUID = null
    ): Promise<ISaveInteractionResponse> {
        const friendlyMessage = await this.saveInteraction(
            interaction,
            multimediaInstanceArray,
            idGeneralAnswer,
            [errorCodes.client.allow.interactionOwnerCanUpdateInteraction]
        );

        /**
         * Caso o atendente tente novamente enviar uma intereção, essa mensagem de erro quer
         * dizer que o atendimento já foi finalizado corretamente, só que o evento do socket não chegou,
         * nesse caso pode prosseguir com o redirect para o próximo grupo disponível e também com a
         * remoção do grupo da barra lateral.
         */
        const errorsArray: IUserErrorJSON[] = friendlyMessage.getFriendlyArray();
        const isAlreadyFinished: boolean = errorsArray.some(err => err.errorID === errorCodes.client.allow.interactionOwnerCanUpdateInteraction);

        if (isAlreadyFinished) {
            const groupId = this.sessionSVC.getSelectedGroupID();

            await this.attendanceSvc.navigateToNextAvailableGroup([groupId]);
            this.groupShortchutSvc.removeShortcut(groupId);
        }

        return {
            friendlyMessage,
            isAlreadyAttendanceFinished: isAlreadyFinished
        };
    };

    public async saveInteraction(
        interaction: Interaction, 
        multimediaInstanceArray: TMultimediaInstanceArray = [], 
        idGeneralAnswer: TGlobalUID = null,
        ignoreErrorIDs: string[] = []
    ): Promise<FriendlyMessage> {
        const interactionMustBeConvertedToChained: boolean = (this.isAttending() || this.isSupervising())
            && sendInteractionOnAttending(interaction) 
            && this.interactionMustBeConvertedToChained(interaction)

        if (interactionMustBeConvertedToChained) {
            const attendenceIdInteraction: string = this.isSupervising() 
                ? this.supervisorAgentService.getAttendenceIdInteraction()
                : this.attendanceSvc.getReplyInteractionForGroup(this.session.getSelectedGroupID())

            interaction = this.interactionToCitation(
                interaction,
                attendenceIdInteraction
            );

            this.assembleHandshakeInfoForAttendant(interaction, attendenceIdInteraction);
        } else if(this.sessionSVC.isEmbeddedChat()) {
            this.assembleHandshakeInfo(interaction);
        }

        if (this.isSupervising()) {
            interaction.setInterlocutor(EConversationStatusCC.supervisorMessage);
            interaction.setAttendantPersonalMessage()
        } else if(this.isAttending()) {
            interaction.setAttendentIfOnTheChain();
            interaction.setInterlocutor(EConversationStatusCC.attMessage);
            interaction.setClientGenerated();
        }
    

        console.log({
            interaction, 
            interactionMustBeConvertedToChained, 
            isSupervising: this.isSupervising(),
            isAttending: this.isAttending()
        });

        const hasMultimedia: boolean = this.hasMultimedia(multimediaInstanceArray);
        let infraSucess: boolean = true;
        let friendly: FriendlyMessage = null;
        let multimediaObject: MultimediaObject;
        const infraParamenter: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getAvatarID());

        if (hasMultimedia) {
            multimediaObject = MultimediaObject.createMultimediaForSerializable(interaction);
            multimediaObject.replaceMultimediaInstanceArray(multimediaInstanceArray);

            try {
                infraSucess = await this.multimediaService.uploadSerializableMedia(
                    infraParamenter,
                    interaction,
                    multimediaObject,
                    interaction.getParticipant().getGroup().getGroupID());
            } catch (error) {
                throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, error, 'saveInteraction');
            }
        }

        if (infraSucess) {
            if (hasMultimedia) {
                interaction.setMultimediaObject(multimediaObject);
            };
            interaction.dataSent();

            if (this.hardware.getGeolocation().getLastKnownPosition()) {
                interaction.setCoordinates(this.hardware.getGeolocation().getLastKnownPosition());
            };

            const sendRequest: ISingleInteractionRequest = this.rbs.getInteractionRequest(interaction, idGeneralAnswer);
            const clientResponse: ClientInfraResponse = await this.serverAPI.managedRequest(
                infraParamenter,
                sendRequest,
                true,
                { ignoreErrorIDs }
            );

            if (clientResponse.executionOK && clientResponse.response) {
                for (const idInteraction in clientResponse.response.sentInteractions) {
                    this.socket.checkIFRecevedBySocket(clientResponse.response.sentInteractions[idInteraction]);
                }

                if(this.isAttending()) {
                    this.attendanceSvc.attInteractionSent(interaction);
                }
            } else {
                this.attendanceSvc.clearAttendanceOverlay();
                UberCache.removeFromCache(interaction.getInteractionID());
            }
            friendly = clientResponse.friendlyMessage;
        } else {
            friendly = new FriendlyMessage('saveInteraction', false);
        };
        return friendly;
    };

    public isAttending(): boolean {
        return this.session.iAmAttendingOnCurrentGroup();
    }

    isSupervising(): boolean {
        return this.supervisorAgentService.isSupervisingAttendance(this.session.getSelectedGroupID())
    }

    private interactionMustBeConvertedToChained(interaction: Interaction): boolean {
        return ! interaction.isChainedOrTreeWithParent();
    }

    public async confirmFeedbackFilter(toBeChecked: TServerCheckFeedbackArray): Promise<IConfirmFeedbackFilterResponse> {
        const confirm: IConfirmFeedbackFilterRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.realTimeFilter.confirmInteractions),
            toBeChecked,
        };

        const infraParamenter: IInfraParameters = this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getAvatarID());

        const response = await this.serverAPI.managedRequest(infraParamenter, confirm);
        if (response.executionOK) {
            return response.response as IConfirmFeedbackFilterResponse;
        }
        return null;
    }
};
