import { ICameraHardwareAbstraction } from '../hardware-interfaces';
import { EHardwareResourceStatus } from "../cordova/vendor/hardware-status";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class BrowserCamera implements ICameraHardwareAbstraction {
    getCameraMedia(): Promise<File> {
        return undefined;
    }

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return undefined;
    }

    isAllowed(): Promise<boolean> {
        return undefined;
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return undefined;
    }
}
