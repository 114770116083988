import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { TEnumPickerFieldMaps } from "../../enum-db";
import { genericTypedSuggestions } from "../../../tools/type-utils";
import { typedScreenOptions } from "../../../tools/utility";

const idSerializable: EAllSerializable = EAllSerializable.FileDetail;

const fields = genericTypedSuggestions<TEnumPickerFieldMaps>()({
    fileName: 1, // 'SNManagement/integration' 30
    fileSize: 2, // 'SNManagement/integration2' 19
    columnName: 3, // 'SNManagement/integration' 33
    formField: 4, // 'SNManagement/integration' 34
    nullFields: 5, // 'SNManagement/integration' 35
    cardinality: 6, // 'SNManagement/integration' 36
    savedLines: 7, // 'SNManagement/integration' 22
    linesWithError: 8, // 'SNManagement/integration' 23
    creationDate: 9, // 'SNManagement/integration' 24
    fileDetails1: 10, // 'SNManagement/integration2' 20
    noFilesToShow: 11, // Não há bancos de dados para mostrar
    selectCSVFile: 12, // Selecione o banco de dados (.csv)
    errorLoadingFiles: 13, // Erro ao carregar os bancos de dados
    invalidFileType: 14, // O formato do arquivo é inválido. Apenas o formato .csv é suportado
} as const);

const screenOptions = typedScreenOptions({
    idSerializable,
    fields,
});

export const fileDetailScreenOptions = screenOptions;
export const fileDetailTranslations = getTranslationsFields(screenOptions);


