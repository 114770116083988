import {IBasicRenderInfo, EBasicRenderInfoType} from './small-serializable'
import { INonSerializable } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { getBestIdMediaFromNS } from '../rules/mm-functions';
import { isThisOneOfThat, typedClone } from '../tools/utility';

export class NonSerializableClass<T extends INonSerializable> implements IBasicRenderInfo {

    private nonS: INonSerializable;

    protected constructor(nonSerialuzable: T) {
        this.nonS = typedClone<T>(nonSerialuzable);
    };

    public toJSON(): INonSerializable {
        return this.nonS;
    }

    public getBasicInfoType(): EBasicRenderInfoType {
        return EBasicRenderInfoType.nonSerializable;
    }
    public getName(): string {
        return this.nonS.nName;
    }
    public getIdMedia(): string {
        return getBestIdMediaFromNS(this.nonS)
    }
    public getPrimaryID(): string {
        return this.nonS.idNS
    }
    public getObjectTypeID(): string {
        return this.nonS.nsType;
    }
    public is(...idArray: string[]): boolean {
        return isThisOneOfThat(this.nonS, ...idArray);
    }
    public isNot(...idArray: string[]): boolean {
        return ! this.is(...idArray);
    };

    public rehydrate(json: INonSerializable) {};

    public static getNonSerializable<T extends INonSerializable>(nonSerializable: T): NonSerializableClass<T> {
        const n = new NonSerializableClass<T>(nonSerializable);
        n.rehydrate(nonSerializable);
        return n;
    }

    public getRawObject(): T {
        return <T> this.nonS;
    }


}
