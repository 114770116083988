import {IGroupParentJSON, TGroupGenealogy, IGroupJSON} from "@colmeia/core/src/comm-interfaces/business-interfaces";
import {Group} from "@colmeia/core/src/business/group";
import {BreadcrumbItemList} from "./breadcrumb.model";



export function getBreadCrumbFromGenealogy(genealogy: TGroupGenealogy): BreadcrumbItemList {
    return genealogy.map(gen => ({primaryId: gen.idGroupParent, url: null,  title: gen.name }));
}

