import { IFileOpenerAbstraction, EMobileBrowserTarget } from "../hardware-interfaces";
import { Injectable } from "@angular/core";
import { EMimeTypes } from "@colmeia/core/src/multi-media/file-interfaces";
import { BrowserRedirect } from "./browser-redirect";
import {MultimediaService} from "../../multimedia.service";
import {ImgSecureDownloadService} from "../../auth/img-secure-download.service";
import {MultimediaInstance} from "@colmeia/core/src/multi-media/multi-media-instance";
import {ImgSecureDownloadPipe} from "../../../pipes/img-secure-download.pipe";

@Injectable({
    providedIn: 'root'
})
export class BrowserFileOpener implements IFileOpenerAbstraction {
    private multimediaSvc: MultimediaService;

    constructor(
        private imageSecureSvc: ImgSecureDownloadService,
        private redirector: BrowserRedirect,
    ) {}

    setMultimediaService(multimediaSvc: MultimediaService) {
        this.multimediaSvc = multimediaSvc;
    }

    generateBlobUrl(mmInstance: MultimediaInstance): Promise<string> {
        return new Promise((resolve, reject) => {
            let pipe = new ImgSecureDownloadPipe(this.imageSecureSvc);
            pipe.transform(this.getMultimediaUrl(mmInstance)).subscribe((safeUrl: {changingThisBreaksApplicationSecurity: string}) => {
                let url: string = safeUrl.changingThisBreaksApplicationSecurity;
                resolve(url);
            }, reject);
        });
    }

    open(mmInstance: MultimediaInstance) {
        switch(mmInstance.getMimeType()) {
            case EMimeTypes.PDF: {
                this.generateBlobUrl(mmInstance).then(url => this.redirectToFile(url));
                break;
            }

            default: {
                const link = document.createElement('a');
                this.generateBlobUrl(mmInstance).then(url => {
                    link.href = url;
                    link.download = mmInstance.getFileName();
                    document.body.append(link);
                    link.click();
                    link.remove();
                });
                break;
            }
        }
    }

    public getMultimediaUrl(mmInstance: MultimediaInstance): string {
        return this.multimediaSvc.getMultimediaBestUrl(mmInstance);
    }

    private async redirectToFile(url: string) {
        this.redirector.redirect({
            url,
            mobileTarget: EMobileBrowserTarget.Blank
        });
    }
}
