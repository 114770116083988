import { IClientFileInfo } from "./file-interfaces";
import { TGlobalUID } from "../core-constants/types";
import { ClientCachedFile } from "./client-cached";



export class SelectedFile {

    

    private cachedFile: ClientCachedFile;
    private clientInfo: IClientFileInfo

    constructor(cachedFile: ClientCachedFile, clientInfo: IClientFileInfo) {
        this.clientInfo = clientInfo;
        this.cachedFile = cachedFile;
    };

    public getIDMediaTag(): TGlobalUID {
        return this.clientInfo.idMediaTag;
    }

    public getCurrentName(): string {
        return this.clientInfo.currentName;
    };

    public getClientInfo(): IClientFileInfo {
        return this.clientInfo;
    };
    
    public isSmartShare(): boolean {
        return this.cachedFile.isSmarShare();
    }

    public getIDMedia(): TGlobalUID {
        return this.cachedFile.getIDMedia();
    };

    public getClientCachedFile(): ClientCachedFile {
        return this.cachedFile;
    };


    public getMultimediaKey(): TGlobalUID {
        return this.cachedFile.getInfoToServer().mmKey;
    }

    
    public static newClientFileInfo(cachedFile: ClientCachedFile, clientInfo: IClientFileInfo): SelectedFile {
        return new SelectedFile(cachedFile, clientInfo);
    };

    public getThumbnailBlob(): Blob {
        return this.cachedFile.getThumbnailBlob();
    };

    public getHashFile(): string {
        return this.cachedFile.getInfoToServer().hash;
    }
    


}
 
