import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { sharedUriFragments } from '@colmeia/core/src/core-constants/routes-shared';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { entries, isValidArray, isValidString, keys } from '@colmeia/core/src/tools/utility';
import { Compute, DeepFindValuesOfType, Filter, Split } from '@colmeia/core/src/tools/utility-types';
import { createServiceLogger } from 'app/model/client-utility';
import { filter } from 'rxjs/operators';
import { routeID, routeList, routSet, TBPMRoutesConfig } from "../model/routes/route-constants";
import { FeatureStateEmissorService } from "./features-emissor.service";
import { GroupPersistorServices } from "./group-persistor.services";
import { $BuildedRoute, RoutingBuilder } from "./routing-builder";
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';

/**
 * Service to proxy routing inside the application
 */
@Injectable()
export class RoutingService {
    private log = createServiceLogger('RoutingService', '#14adff');

    history: string[] = []

    constructor(
        public router: Router,
        private stateEmissor: FeatureStateEmissorService,
        private groupFunctionsSvc: GroupPersistorServices,
        @Inject(DOCUMENT) private document: any,
        public activatedRoute: ActivatedRoute,
        public location: Location
    ) {
        this.listenToRouteChange();
    }

    goToPath(path: string): Promise<boolean> {
        return this.router.navigateByUrl(path);
    }

    private listenToRouteChange() {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(
                (e: NavigationEnd) => {
                    this.history = [...this.history, e.urlAfterRedirects];
                })
            ;
    }

    getPreviousRoute(): string {
        const history = this.getHistory();

        return history[history.length - 2]
    }



    getHistory(): string[] {
        return this.history;
    }


    //#region RouteChecker
    public isItMyRoute(pathRouteToCheck: string): boolean {
        return this.getRouteTokens(pathRouteToCheck)
            .map((token: string) => this.router.url.includes(token)) // Check if route includes token
            .reduce((previous: boolean, actual: boolean) => previous && actual); // Verify if all the token are included
    }

    public isInsideChat(groupId: TGlobalUID): boolean {
        return groupId
            ? this.isItMyRoute(RoutingBuilder.groupChat(groupId))
            : false
    }

    public isInsideGroupHome(groupId: TGlobalUID): boolean {
        this.log({ route: RoutingBuilder.groupHome(groupId), url: this.router.url });

        const isHome = '/' + RoutingBuilder.groupHome(groupId) === this.router.url
        return isHome;
    }


    /**
     * Transform the route in all the tokens excluded the parameters (:param)
     * @param  {string}   route [description]
     * @return {string[]}       [description]
     */
    private getRouteTokens(route: string): string[] {
        return route
            .split('/')
            .filter((token: string) => token.length > 1) // Filter if the token is smaller than 2 chars
            .filter((token: string) => !token.includes(':'));
    }

    /**
     * navigates to some routeID
     * @param  {string} idRoute
     * @param  {string[]} ...params
     * @returns Promise
     */
    public async navigateToId(idRoute: string, ...params: string[]): Promise<void> {
        const fullRoute: string = this.mountFullRoute(idRoute, params);
        this.log('NavigateToId:', { idRoute, fullRoute }, ...params);

        // if(fullRoute == 'groups/ALL-ROOT/home'){debugger}
        // if user is trying to navigate to chat
        // we have to send via socket a notification to server
        if (routSet[idRoute] && routSet[idRoute].enterGroup) {
            await this.groupFunctionsSvc.enterGroupChat(params[0]);
        }

        if (fullRoute)
            // 'home/'+
            await this.router.navigate([fullRoute]);
    };

    public getURLToNavigateToId(idRoute: string, ...params: string[]): string {
        const fullRoute: string = this.mountFullRoute(idRoute, params);
        return fullRoute;
    };

    /**
     * navigates to some routeID, but just replace the current history entry
     * @param  {string} idRoute
     * @param  {string[]} ...params
     * @returns Promise
     */
    public replaceToId(idRoute: string, params: string[]): void {
        const fullRoute: string = this.mountFullRoute(idRoute, params);
        this.log('NavigateToId:', { idRoute, fullRoute, replace: true }, ...params);

        // if user is trying to navigate to chat
        // we have to send via socket a notification to server
        if (routSet[idRoute] && routSet[idRoute].enterGroup) {
            this.groupFunctionsSvc.enterGroupChat(params[0]);
        }

        if (fullRoute)
            // 'home/'+
            this.router.navigate([fullRoute], { replaceUrl: true });
    };

    public mountFullRoute(idRoute: string, params: string[]): string {
        let fullRoute: string;
        // logging out does not have any route,
        // authService logs out and redirects user to home
        if (idRoute === routeID.auth.login) {
            fullRoute = routeList.auth.login;
        } else if (idRoute === sharedUriFragments.signUp.id) {
            fullRoute = routeList.auth.signUp;
        } else if (routSet[idRoute] && routSet[idRoute].specialFeature) {
            this.stateEmissor.changeChatBarToFeature(idRoute);
        } else if (idRoute == routeID.dashboard) {
            fullRoute = RoutingBuilder.dashboardPage(params ? params : [routeList.dashboard.children.home]);
        } else if (idRoute === routeID.home) {
            fullRoute = routeList.home.path;
        }
        else {
            fullRoute = this.getRoutePath(idRoute, ...params);
        };

        return fullRoute;
    }

    /**
     * Gets full route from idRoute
     * @param  {string} idRoute
     * @param  {string[]} params
     * @returns string
     */
    public getRoutePath(idRoute: string, ...params: string[]): string {
        // const participant: Participant = this.clientSubscriptionService.getSelectedParticipant();
        const fullRoute: string = RoutingBuilder.getRoute(idRoute, ...params);

        if (!fullRoute) {
            console.error(`RoutingService.navigateToId route empty for idRoute:[${idRoute}] you need to create it in RoutingBuilder`);
            return;
        }
        return fullRoute;
    }

    async navigateToAttendentStatusPage() {
        this.navigateToId(
            routeList.dashboard.path,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.current
        );
    }

    public goToGroupHome(groupId: TGlobalUID): void {
        this.navigateToId(
            routeID.groups.home,
            groupId);
    }


    public goToGroupContainerList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.groupContainer.path,
            routeList.dashboard.children.menuSN.children.groupContainer.children.list
        );
    }

    public goToGroupContainerCreate(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.groupContainer.path,
            routeList.dashboard.children.menuSN.children.groupContainer.children.create
        );
    }
    public goToGroupContainerEdit(idNS: string): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.groupContainer.path,
            routeList.dashboard.children.menuSN.children.groupContainer.children.edit,
            idNS
        );
    }

    public gotToShareServicesList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.shareServices.path,
            routeList.dashboard.children.menuSN.children.shareServices.children.list
        );
    }

    public gotToShareServicesEdit(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.shareServices.path,
            routeList.dashboard.children.menuSN.children.shareServices.children.edit,
            idNS
        );
    }

    public goToFaceCompanyList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.shareFacecompany.path,
            routeList.dashboard.children.menuSN.children.shareFacecompany.children.list
        );
    }

    public goToFaceCompanyEdit(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.shareFacecompany.path,
            routeList.dashboard.children.menuSN.children.shareFacecompany.children.edit,
            idNS
        );
    }
    public goToUserProfileList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.userProfiles.path,
        );
    }
    public goToUserProfileEdit(idNS: TGlobalUID): void {
        const editOrCreate = idNS
            ? routeList.dashboard.children.menuSN.children.userProfiles.children.edit
            : routeList.dashboard.children.menuSN.children.userProfiles.children.create;

        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.userProfiles.path,
            editOrCreate,
            idNS || ''
        );
    }
    //@Todo Jurgilas criar serviço a parte para o call center
    public goToAgentStatusList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.agentStatus.path,
            routeList.dashboard.children.serviceStatus.children.agentStatus.children.list
        );
    }

    public goToAgentStatusEdit(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.agentStatus.path,
            routeList.dashboard.children.serviceStatus.children.agentStatus.children.edit,
            idNS
        );
    }

    public goToAttendantResourcesList() {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.attSupport.path,
            routeList.dashboard.children.serviceStatus.children.attSupport.children.servicePackList
        );
    }

    public goToAgentFileList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.agentsFile.path,
            routeList.dashboard.children.serviceStatus.children.agentsFile.children.list
        );
    }

    public goToAgentFileEdit(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.agentsFile.path,
            routeList.dashboard.children.serviceStatus.children.agentsFile.children.edit,
            idNS
        );
    }


    public goToActiveCall(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.activeCall.path,
            routeList.dashboard.children.serviceStatus.children.activeCall.children.details.path,
            idNS
        );
    }


    public goToActiveSendEdit(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.activeSends.path,
            routeList.dashboard.children.serviceStatus.children.activeSends.children.details.path,
            idNS
        );
    }

    public bpmGraphRoutes: DeepFindValuesOfType<typeof routeList, string>[] = [];

    public goToGraphElementList() {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.bpmGraph.path,
        );
    }

    private bpmRoutesConfigs: {
        [key in EBPMType]?: {
            basePath: string,
            pathsConfigs: TBPMRoutesConfig
        }
    } = {
            [EBPMType.bot]: {
                basePath: routeList.dashboard.children.smartFlow.path,
                pathsConfigs: routeList.dashboard.children.smartFlow.children.bpmGraph
            },
            [EBPMType.crm]: {
                basePath: routeList.dashboard.children.crmServices.path,
                pathsConfigs: routeList.dashboard.children.crmServices.children.servicesConfigs
            },
            [EBPMType.marketing]: {
                basePath: routeList.dashboard.children.marketing.path,
                pathsConfigs: routeList.dashboard.children.marketing.children.marketingBPM
            },
            [EBPMType.nestedAI]: {
                basePath: routeList.dashboard.children.ai.path,
                pathsConfigs: routeList.dashboard.children.ai.children.nestedAI
            }
        }

    public goToRootGraphElement(
        idGraphElement: TGlobalUID,
        bpmType: EBPMType,
        hasImport: boolean = false,
        idHostedObject?: TGlobalUID,
        replace?: boolean
    ): void {
        const route = this.bpmRoutesConfigs[bpmType];
        const params: string[] = [
            route.basePath,
            route.pathsConfigs.path,
            route.pathsConfigs.children.edit.path,
            idGraphElement,
            bpmType
        ];

        replace
            ? this.replaceToId(routeID.dashboard, params)
            : this.navigateToId(routeID.dashboard, ...params);
    }

    public goToCreateGraphElement(bpmType: EBPMType): void {
        const route = this.bpmRoutesConfigs[bpmType];
        this.navigateToId(
            routeID.dashboard,
            route.basePath,
            route.pathsConfigs.path,
            route.pathsConfigs.children.create.path,
            bpmType,
        );
    }

    public goToContentGeratorList() {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.ai.path,
            routeList.dashboard.children.ai.children.botTransactions.path,
            routeList.dashboard.children.ai.children.botTransactions.children.list.path,
        );
    }

    public goToContentGeneratorEdit(id: string) {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.botTransactions.path,
            routeList.dashboard.children.smartFlow.children.botTransactions.children.details.path.replace(
                routeList.dashboard.children.smartFlow.children.botTransactions.children.details.routeParam,
                id
            ),
        );
    }

    public goToNamedAccountEdit(idNS: TGlobalUID): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.service.path,
            routeList.dashboard.children.service.children.namedAccounts.path,
            routeList.dashboard.children.service.children.namedAccounts.children.edit,
            idNS
        );
    }

    public goToNamedAccountList(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.service.path,
            routeList.dashboard.children.service.children.namedAccounts.path,
            routeList.dashboard.children.service.children.namedAccounts.children.list,
        );
    }

    public goToGeneralCallCenterQueue(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.agents.path,
            routeList.dashboard.children.serviceStatus.children.agents.children.queue.path,
        );
    }


    public goToCallCenterQueue(idIsland: string): void {
        this.updateQueryParams({ type: "queue", idIsland }, "merge", true);
    }



    public goToCallCenterWork(idIsland: string): void {
        this.updateQueryParams({ type: "work", idIsland }, "merge", true);
    }

    public getSocialNetworkUserSettingsUrl() {
        return this.getURLToNavigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.userSettings.path
        )
    }

    public goToSocialNetworkUserSettings(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.menuSN.path,
            routeList.dashboard.children.menuSN.children.userSettings.path,
        );
    }

    public getJobSocialNetworkBatchApprovalUrl() {
        return this.getURLToNavigateToId(
            routeID.dashboard,
            routeList.dashboard.children.jobs.path,
            routeList.dashboard.children.jobs.children.approveSocialNetworkParticipationInBatch
        )
    }

    public goToJobSocialNetworkBatchApproval(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.jobs.path,
            routeList.dashboard.children.jobs.children.approveSocialNetworkParticipationInBatch
        );
    }

    public getJobUserProfileUpdateApprovalUrl() {
        return this.getURLToNavigateToId(
            routeID.dashboard,
            routeList.dashboard.children.jobs.path,
            routeList.dashboard.children.jobs.children.userGroupProfileUpdate
        )
    }

    public goToJobUserProfileUpdate(): void {
        this.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.jobs.path,
            routeList.dashboard.children.jobs.children.userGroupProfileUpdate
        );
    }

    public goToGroupCreate(groupId: TGlobalUID): void {
        this.navigateToId(routeID.features.newSubGroup, groupId);
    }

    public goToGroupEdit(groupId: TGlobalUID): void {
        this.navigateToId(routeID.features.editSubGroup, groupId);
    }

    public goToGroupChat(groupId: TGlobalUID): void {
        this.navigateToId(routeID.groups.chat, groupId);
    }

    public goToAvatarProfile(avatarId: TGlobalUID): void {
        this.navigateToId(routeID.player.profile, avatarId);
    }

    public goToNestedAIBPM(idBPM?: string) {
        if (isValidString(idBPM)) {
            this.navigateToId(
                routeList.dashboard.path,
                routeList.dashboard.children.ai.path,
                routeList.dashboard.children.ai.children.nestedAI.path,
                routeList.dashboard.children.ai.children.nestedAI.children.edit.path,
                idBPM,
                EBPMType.nestedAI,
            );
            return;
        }

        this.navigateToId(
            routeList.dashboard.path,
            routeList.dashboard.children.ai.path,
            routeList.dashboard.children.ai.children.nestedAI.path,
            routeList.dashboard.children.ai.children.nestedAI.children.create.path,
            EBPMType.nestedAI,
        );
    }

    //
    // MUST consider moving this functions below to scrollService
    //

    /**
     * Scroll to element with id 'id'
     * @param {string} id [description]
     */
    public scrollTo(id: string): void {
        // const pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(this.document, id);
        // this.pageScrollService.start(pageScrollInstance);
    }

    /**
     * Scrolls to HTMLElement with a certain id, like '#player'
     * @param {string}                   id [description]
     * @param {ScrollIntoViewOptions |  boolean}     options [description]
     */
    public scrollIntoView(id: string, options?: ScrollIntoViewOptions | boolean): void {
        const element: HTMLElement = <HTMLElement>document.querySelector(id);

        if (!element) {
            this.log('ScrollIntoView: element not found: ', id);
            return;
        }

        options ? element.scrollIntoView(options) : element.scrollIntoView();
    }

    public getCurrentPath(): string {
        return this.router.url;
    }

    public amIAtThisPath(path: string): boolean {
        return path === this.getCurrentPath();
    }

    public createRouteHandlerByPicker<Route extends string>(getPath: ($proxy: typeof RoutingBuilder.$pickRouteList) => $BuildedRoute<Route>) {
        const path = RoutingBuilder.toRouteString(getPath(RoutingBuilder.$pickRouteList))
        return this.createRouteHandler(path);
    }

    public createRouteHandler<Route extends string>(route: Route) {
        const path = route
        type ParamNames = Filter<Split<typeof path, '/'>, `:${string}`> extends infer $TextParams ? $TextParams extends `:${infer $Params}` ? $Params : never : never;
        type Params = { [key in ParamNames]: string }

        const goTo = (params: Compute<Params>): Promise<boolean> => this.goToPath(keys(params).reduce((path: Route, param: ParamNames) => path.replace(`:${param}`, params[param]), path));
        const getParams: () => Compute<Params> = () => this.getAllParamsFromSnapshot(this.activatedRoute.snapshot) as Params;

        return {
            goTo,
            getParams,
        }
    }

    public getRouteParam(name: string): string {
        const params: { [param in string]: string } = this.getAllParamsFromSnapshot(this.activatedRoute.snapshot);
        return params[name];
    }

    public getRouteParamAndSlice(name: `:${string}`): string {
        return this.getRouteParam(name.slice(1));
    }

    getAllParamsFromSnapshot<T extends Record<string, string>>(snapshot: ActivatedRouteSnapshot, result?: {}): T;
    getAllParamsFromSnapshot(snapshot: ActivatedRouteSnapshot, result = {}): Record<string, string> {
        entries(snapshot.params).forEach(([key, value]) => {
            result[key] = value;
        })

        if (isValidArray(snapshot.children)) {
            snapshot.children.forEach(s => this.getAllParamsFromSnapshot(s, result));
        }

        return result;
    }

    isActive(route: string, allParams: Record<string, string>, subset: boolean = false): boolean {
        let finalRoute = route;

        for (const [key, value] of entries(allParams)) {
            finalRoute = finalRoute.replace(`:${key}`, value)
        }

        const path = this.location.path();

        return subset ? path.startsWith(finalRoute) : this.location.isCurrentPathEqualTo(finalRoute);
    }

    getRouteParams(url: string): string[] {
        return /(\:[A-Za-z-_]+)/.exec(url);
    }

    isOnChatScreen(): boolean {
        return this.location.path().startsWith(`/${routeList.groups.path}/${routeList.groups.children.idGroup.children.chat}`);
    }

    isOnDashboardScreen(): boolean {
        return this.location.path().startsWith(`/${routeList.dashboard}`);
    }

    public gotoContactList(
        idContactList?: IdDep<ENonSerializableObjectType.contactList>,
        replace?: boolean
    ): void {
        const params: string[] = [
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.contactList.path,
        ];

        if (isValidString(idContactList)) {
            params.push(
                routeList.dashboard.children.serviceStatus.children.contactList.children.details.path,
                idContactList
            )
        }

        replace
            ? this.replaceToId(routeID.dashboard, params)
            : this.navigateToId(routeID.dashboard, ...params);
    }

    isAccessAllowedUnauhtorized(pathname: string): boolean {
        const unauthorizedAccessAllowedRoutes = [
            routeID.auth.parent,
            routeID.deleteAccount,
            routeID.privacyPolicy,
            'vendor/fb-messenger',
            routeList.embed.path,
            'widget',
        ];

        return unauthorizedAccessAllowedRoutes.some(r => pathname.includes(r));
    }

    updateQueryParams(newParams: Record<string, string>, queryParamsHandling: "merge" | "preserve" | "" = "merge", replaceUrl: boolean = false): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: newParams,
            queryParamsHandling,
            replaceUrl
        });
    }

    removeQueryParams(paramKeys: string[], replaceUrl: boolean = false) {
        const currentParams = { ...this.activatedRoute.snapshot.queryParams };
        for (const key of paramKeys) {
            delete currentParams[key];
        }
        this.updateQueryParams(currentParams, "", replaceUrl);
    }
}
