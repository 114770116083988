import { I360Media } from '../../core-constants/bot';
import { TJimpFontInfos } from '../../jimp/jimp.constants';
import { isValidMedia } from '../../rules/mm-functions';
import { getReadableUniqueID, isValidRef } from "../../tools/utility";
import { Explicit } from '../../tools/utility/types';
import { IBPConditionalEvaluator } from '../BPM/bpm-model';
import { ICompiledTemplateWithVariables } from '../metadata/metadata-util-interfaces';
import { IBasicAsset, IGroupChangeAbleBasicAsset, KBAssetType } from './bot-asset-model';



export enum EBotContentEvent {
    preContent = 'precont', posContent = 'poscont',
    // Confirmacoes
    mainContent = 'maincontent',

    error = 'error',
    notFound = 'notFound',
    header = 'header',
    invalidContent = 'invalidContent',
    optionTitle = 'opt.ttl',
    optionDescription = 'opt.desc',
}


export type TContentAssetArray = Array<IContentBasicAsset>;
export type TSingleContentAsset = [] | [IContentBasicAsset];

export enum EMessageRecency {
    everyTime = 'asEveryTime', // ACTION
    once = 'asOnce',
    onceInTheSession = 'asSess',
    replayAfterDays = 'asTimed', // ATÉ AQUI POR ENQUANTO PARA O CONTENT --
    conditional = 'conditional', // ÚNICO QUE FUNCIONA PARA ACTION
    // Mostra Conteúdo: 'Sempre' || 'Uma única vez' || 'Uma única vez na sessão' || 'Após dias' 
}

export const DEFAULT_MESSAGE_RECENCY: EMessageRecency = EMessageRecency.everyTime;

export interface IRandomMessage {
    idFirstAsset: string;
    tag: string;  // Nome da Tag
}

export interface IChangeableAssetParameter extends IGroupChangeAbleBasicAsset, IGroupChangeAbleContentBasicAsset { };

export interface IGroupChangeAbleContentBasicAsset {
    isConditionalContent?: boolean;
    displayCondition?: IBPConditionalEvaluator;
    // agruparemos entre tags para gerar mensagens randomica entre determinada tag
    randomDisplayControl?: IRandomMessage
    waitTimeSeconds?: number;
    botContentType?: EBotContentEvent;
}

export interface ITextOnImage {
    color: string;
    size: string;
}

export type TTextOnImage = Explicit<
    ITextOnImage,
    TJimpFontInfos extends infer Info ?
    { [key in keyof Info as (Exclude<key, keyof Pick<TJimpFontInfos, 'name'>>)]: Info[key] }
    : never
>;

export interface IContentBasicAsset extends IBasicAsset, IGroupChangeAbleContentBasicAsset {
    type: KBAssetType;
    media?: I360Media; // arquivo
    hasTextOnImage?: boolean;
    textOnImage?: ITextOnImage;
    content: string;  // resposta
    sectionName?: string;
    description?: string;
    variablesTemplate?: ICompiledTemplateWithVariables;
}

export interface IContentEmailHTMLTemplate extends IContentBasicAsset {
    isEmailHTMLTemplate: true;
}

export function emptyContentBasicAsset(content: string = '', type: KBAssetType = KBAssetType.content): IContentBasicAsset {
    return {
        content,
        type,
        idAsset: getReadableUniqueID(),
    }
}

export function hasValidContent(asset: IContentBasicAsset | undefined): boolean {
    return isValidRef(asset) && (asset.content.length > 0 || isValidMedia(asset.media));
}

export interface IBotActionAPIAsset extends IContentBasicAsset {
    idElement: string;
}

export interface IFlowContentAsset extends IContentBasicAsset {
}
