

import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { EObjectType } from "../../../business/constant.enums";
import { IUniversalJSON } from "../../../comm-interfaces/barrel-comm-interfaces";
import { notImplementedSharedFunction as notImplementedSharedMethod } from "../shared-services.basic-functions";

export interface InsertCatalogInput {
    name: string;
    id: string;
}
export interface InsertCatalogOutput {
    outId: string;
}

// 
class Service {

    public getById(id: string, objectType?: EObjectType): Promise<IUniversalJSON | undefined> {
        notImplementedSharedMethod([id, objectType]);
    }

    public getByIds(ids: string[]): Promise<IUniversalJSON[]> {
        notImplementedSharedMethod(ids);
    }
    
}

@ToStatic
export class SerializableSharedService extends toStatic(Service) {}
