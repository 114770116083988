import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'customer-avatar',
    templateUrl: './customer-avatar.component.html',
    styleUrls: ['./customer-avatar.component.scss']
})
export class CustomerAvatarComponent implements OnChanges {
    private _fullName: string;

    @Input()
    set fullName(value: string) {
        // limpa a string
        this._fullName = value.replace(/\s+/g, ' ');
    }

    get fullName(): string {
        return this._fullName;
    }

    @Input()
    public identifier: string;

    public initials: string;
    public backgroundColor: string;

    public ngOnChanges() {
        this.initials = this.getInitials(this.fullName);
        this.backgroundColor = this.stringToColor(this.identifier);
    }

    private getInitials(fullName: string) {
        let initials;
        const names = fullName.split(' ');

        if (names.length > 1) {
            initials = names[0][0] + (names[1][0] || '');
        } else {
            initials = names[0][0];
        }

        initials = initials.toUpperCase();

        return initials;
    }

    private stringToColor(string: string): string {
        const hash = this.sdbm(string)
        const h = hash % 360;
        const s = 20 + (hash % 30);
        const l = 50;

        return `hsl(${h},${s}%,${l}%)`;
    }

    /**
     * algoritmo de hashing SDBM (http://www.cse.yorku.ca/~oz/hash.html)
     */
    private sdbm(string: string) {
        let hash = 0;

        for (let i = 0; i < string.length; i++) {
            hash = string.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
        }

        return hash >>> 0;
    }
}
