import { constant, TGlobalUID } from '../business/constant';
import { Serializable } from '../business/serializable';
import { IMultimediaTypeJSON } from '../comm-interfaces/multi-media-interfaces';
import { clientErrorCodes } from '../error-control/client-error.constants';
import { UberCache } from '../persistency/uber-cache';
import { throwCustomFieldError, throwErrorIfTrueField } from '../tools/utility';
import {MMconstant} from "./multimedia-constant";



export type TMultiMediaTypeArray = Array<MultiMediaType>;

export enum EMultimediaNature {
    Video = 'V',
    Audio = 'A',
    Image = 'I',
    SpreadSheet = 'S',
    Document = 'D',
    unknown = 'U'
}

export class MultiMediaType extends Serializable {
    private htmlType: string;
    private multimediaNature: string;
    private static multimediaTypeArray: TMultiMediaTypeArray = [];
    
    private constructor(idMultimediaType: TGlobalUID) {
        super(constant.objectType.multiMediaType, idMultimediaType)
        MultiMediaType.multimediaTypeArray.push(this);
    };

    public getHTMLType(): string {return this.htmlType;};
    public getMultimediaTypeID(): TGlobalUID {return super.getPrimaryID()};
    public getMultimediaNature(): string {return this.multimediaNature;};

    public static staticFactory(idObjectMultimediaType: TGlobalUID): MultiMediaType {
        return <MultiMediaType>UberCache.uberFactory(idObjectMultimediaType, constant.objectType.multiMediaType, true);
    };

    public static getMutimediaTypeFromMime(mime: string | undefined, lookup: boolean = false): MultiMediaType | undefined {

        const type: MultiMediaType | undefined = MultiMediaType.multimediaTypeArray.find((type) => {
            return mime && (mime.length > type.getHTMLType().length ? mime.includes(type.getHTMLType()) : type.getHTMLType().includes(mime));
        });
        if (!type && !lookup) {
            return MultiMediaType.staticFactory(MMconstant.type.unknonw)
        }
        return type;
    }

    public static gertMultimediaIDFromMime(mime: string, lookup: boolean = false): string {
        const mtype = MultiMediaType.getMutimediaTypeFromMime(mime, lookup);
        return mtype? mtype.getMultimediaTypeID() : null;
    }

    public isUnknown(): boolean {return this.multimediaNature == EMultimediaNature.unknown;};
    public isVideo(): boolean {return this.multimediaNature == EMultimediaNature.Video;};
    public isAudio(): boolean {return this.multimediaNature == EMultimediaNature.Audio;};
    public isImage(): boolean {return this.multimediaNature == EMultimediaNature.Image;};
    public isSpreadSheet(): boolean {return this.multimediaNature == EMultimediaNature.SpreadSheet;};
    public isDocument(): boolean {return this.multimediaNature == EMultimediaNature.Document;};
    public isVCard(): boolean {return this.is(MMconstant.type.vcard); }
    public isPDF(): boolean { return this.is(MMconstant.type.PDF); }
    public isThumbnailable(): boolean {return this.isVideo() || this.isImage(); };

    public rehydrate(json: IMultimediaTypeJSON): void {
        super.rehydrate(json);
        this.htmlType = json.htmlType;
        this.multimediaNature = json.multimediaNature;
    }

    public static factoryMessage(json: IMultimediaTypeJSON): MultiMediaType {
        let mmType: MultiMediaType =  <MultiMediaType>UberCache.uberFactory(json.primaryID, constant.objectType.multiMediaType, false);
        if (mmType == null) 
            mmType = new MultiMediaType(json.primaryID);
        mmType.rehydrate(json);
        return mmType;
    };

    public toJSON(): IMultimediaTypeJSON {
        return { ...super.toJSON(), 
            htmlType: this.htmlType,
            multimediaNature: this.multimediaNature
        }
    };

    public static getMultimediaTypeArray(): TMultiMediaTypeArray {
        return MultiMediaType.multimediaTypeArray;
    };
};

