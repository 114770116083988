import { TArrayID, TNserUID, TNserUIDArray } from '../../core-constants/types';
import { TSchemaPropertyArray } from '../../general-form/general-form-interface';
import { getExactWordRegex, isInvalid, isValidArray, isValidRef, keys, replaceAll, replaceAllByRegex, replaceString, values } from '../../tools/utility';
import { IAPIExecutionInjectionClientBasic, ICustomUserFunctionParams, TUserFunctionCode } from './user-function-model';
import type { Node, StringLiteralLike, CallExpression } from 'typescript'
import { defineConfig } from '@colmeia/core/src/tools/utility-types';
import { Define } from '@colmeia/core/src/tools/utility/types/entities/define';
import { IntersectPartialExplicit, MapDiscriminatedUnion } from '@colmeia/core/src/tools/utility/types';
import type * as ts from "typescript";
import { constant } from '../../business/constant';
import { getStringByteLength } from '../../tools/utility/strings/string-byte-length.string-util';

const property = /["'](\w+)["']\s*:/gi
const rtfPattern = /(\brtf\b.*{)([^}]+)}/;

enum ERTFReturnCase {
    Variable = 'variable', // rtf, // rtf: { ...rtf, $1 }
    Object = 'object', // rtf: {...}, // rtf: { ..., $1 }
    Empty = 'Empty', // rtf: undefined, // rtf: { $1 }
    Nothing = 'nothing', // 
}
const ______BLOCK______: string = '______BLOCK______';

let tss: typeof ts;

export namespace UserFunctionExtractor {

    async function getTS() {
        if (tss) return tss;

        return tss = await import('typescript');
    }

    async function searchNode(node: Node, items: StringLiteralLike[] = []) {
        const ts = await getTS();


        if (ts.isStringLiteralLike(node)) {
            items.push(node);
        }

        const promises: Promise<StringLiteralLike[]>[] = [];
        ts.forEachChild(node, node => {
            const promise = searchNode(node, items);
            promises.push(promise);
        });
        await Promise.all(promises);
        return items;
    }

    async function searchCallExpression(node: Node, items: CallExpression[] = []) {
        const ts = await getTS();


        if (ts.isCallExpression(node)) {
            items.push(node);
        }

        const promises: Promise<CallExpression[]>[] = [];
        ts.forEachChild(node, node => {
            const promise = searchCallExpression(node, items);
            promises.push(promise);
        });
        await Promise.all(promises);
        return items;
    }

    export async function getNodes(code: string): Promise<StringLiteralLike[]> {
        const ts = await getTS();

        const sourceFile = ts.createSourceFile('source.ts', code, ts.ScriptTarget.ESNext, true);
        const nodes = await searchNode(sourceFile);
        return nodes.filter(item => item.text);
    }

    export async function getCallExpressions(code: string): Promise<CallExpression[]> {
        const ts = await getTS();

        const sourceFile = ts.createSourceFile('source.ts', code, ts.ScriptTarget.ESNext, true);
        const nodes = await searchCallExpression(sourceFile);
        return nodes;
    }

    export async function getLinesAndColumns(nodes: StringLiteralLike[]) {
        const ts = await getTS();

        return nodes.map(item => ts.getLineAndCharacterOfPosition(item.getSourceFile(), item.getStart()))
    }

    export async function getIds(code: string): Promise<string[]> {
        const ids = (await getNodes(code)).map(node => node.text).filter( id => getStringByteLength(id) < constant.maxKeyPathElementNameByteLength);
        return ids;
    }
}



export function getAllNonSerializableIdsFromUserFunction(customFunctionTS: string): TNserUIDArray {
    const quotesRegex = /[\"\']/g;
    const detectIdsRegex = /[\"\'][a-zA-Z0-9]{30,32}[\'\"]/g;
    const possibleIds: string[] = customFunctionTS
        .match(detectIdsRegex)
    const ids = isValidArray(possibleIds)
        ? possibleIds.map((result) => replaceAllByRegex(result, quotesRegex, ""))
        : []

    return ids
}

export function setRTFCanonicals(code: TUserFunctionCode, formFieldList: TSchemaPropertyArray): TUserFunctionCode {
    const rtfObject: string = formFieldList
        .map(f => `'${f.idLocalCanonical}': '', // ${f.prompt}\n`)
        .reduce((acc, curr) => `${acc}${curr}`, '')
    // .slice(0, -1)

    const rtfPattern: IRTFPatternConfig = getRTFPattern(code);
    console.log({ rtfPattern });

    if (isInvalid(rtfPattern)) {
        return code;
    }

    const replace1 = code.replace(rtfPattern.pattern, rtfPattern.replace)
    const replace2 = replace1.replace(______BLOCK______, rtfObject)
    return replace2;
}


interface IRTFPatternConfig {
    type: ERTFReturnCase;
    pattern?: RegExp;
    replace: string;
}



type RTFPatternsConfig = { [key in ERTFReturnCase]: IRTFPatternConfig };

// KEEP TESTS SEQUENCY!
const rtfPatternsConfig = defineConfig<MapDiscriminatedUnion<RTFPatternsConfig, 'type'>, RTFPatternsConfig>({
    [ERTFReturnCase.Object]: {
        type: ERTFReturnCase.Object,
        pattern: /(\brtf\b[^:]*:.*{)([^}]*)}/,
        replace: `$1$2, ${______BLOCK______} }`,
    },
    [ERTFReturnCase.Empty]: {
        type: ERTFReturnCase.Empty,
        pattern: /(\brtf\b[^:]*:.*\b)(undefined|null)\b/,
        replace: `$1{ ${______BLOCK______} }`,
    },
    [ERTFReturnCase.Variable]: {
        type: ERTFReturnCase.Variable,
        pattern: /(return[^]*)(\brtf\b)/,
        replace: `$1$2: { ...rtf, ${______BLOCK______} }`,
    },
    [ERTFReturnCase.Nothing]: {
        type: ERTFReturnCase.Nothing,
        pattern: /(return[^{]+){([^}]*)}/,
        replace: `$1{ $2, ${______BLOCK______} }`,
    },
});
function getRTFPattern(code: TUserFunctionCode): IRTFPatternConfig {
    return values(rtfPatternsConfig).find(config => isValidRef(config.pattern) ? config.pattern.test(code) : true)
}


export function getRTFCanonicals(code: string): TArrayID {
    const regexReturn = getExactWordRegex('return')
    const regexRtf = getExactWordRegex('rtf')
    const rtf = code.toLocaleLowerCase().split(regexReturn)[1]?.split(regexRtf)[1];

    console.log({ code, rtf, regexReturn, regexRtf });

    const match: RegExpMatchArray = rtf?.match(property);

    return isValidRef(match) ? match.map(r => sanitizeProperty(r)) : [];
}

// as propriedades podem ter " ou ' como delimitdores
// alem disso, a regez traz o : que precisa ser eliminado
function sanitizeProperty(property: string): string {
    return replaceString(replaceString(replaceString(property, ':', ''), "'", '').trim(), '"', '').trim();
}

function getDefaultIAPIExecutionInjectionClientBasic(initialValue = undefined): IAPIExecutionInjectionClientBasic {
    return {
        idSocialContext: initialValue,
        idConversation: initialValue,
        provider: initialValue,
        address: initialValue, // endereco do CLIENTE
        channel: initialValue,
        channelAddress: initialValue,
        islandData: initialValue,
        allFieldMap: {},
        multipleFormInfo: {},
        idAvatar: initialValue,
        idSocialKey: initialValue
    }
}

export function getDefaultICustomUserFunctionParams(initialValue = undefined): ICustomUserFunctionParams {
    return {
        ...getDefaultIAPIExecutionInjectionClientBasic(initialValue),
        customerFirstMessage: '',
        value: '',
        intents: undefined,
    }
}
