import { TMarkupGenerator, IMarkupGeneratorOptions, EBBCodeStyles, EBBCodeMenuMode, EBBCodeSubStyles, BBCodeStyleTags } from "../bbcode-types";
import { BBTag, BBCodeParser } from "@affilicon/bbcode-parser";
import { keys, isValidRef, generateIncrement, invertObj, values, isNumeric } from "../../../tools/utility";
import { EActionTreeIncrementor } from "../../bot/bot-interfaces";
import { reverse } from "lodash";


export interface IBBCodeParseStringOptions {
    insertLineBreak?: boolean
    stripTags?: boolean;
}

export const defaultBBCodeParseStringOptions: IBBCodeParseStringOptions = { insertLineBreak: false }
export function parseString(parser: BBCodeParser, content: string, options: IBBCodeParseStringOptions = defaultBBCodeParseStringOptions): string {
    return parser.parseString(content || '', options.stripTags, options.insertLineBreak);
}

export function createMarkupGenerator(generator: TMarkupGenerator) {
    return (tag: BBTag, content: string, attrs: string[]) => generator({
        tag, content, attrs: Object.values(attrs)
    });
}


export function markupKeep(options: IMarkupGeneratorOptions) {
    return options.content;
}
export function markupNOOP(options: IMarkupGeneratorOptions) {
    return options.content;
}

export const noopMarkupStyles: Required<BBCodeStyleTags> = {
    [EBBCodeStyles.Strikethrough]: markupNOOP,
    [EBBCodeStyles.Italic]: markupNOOP,
    [EBBCodeStyles.Bold]: markupNOOP,
    [EBBCodeStyles.Code]: markupNOOP,
    [EBBCodeStyles.Menu]: markupNOOP,
    [EBBCodeStyles.Monospace]: markupNOOP,
};


export const keepMarkupStyles: Required<BBCodeStyleTags> = {
    [EBBCodeStyles.Strikethrough]: markupNOOP,
    [EBBCodeStyles.Italic]: markupNOOP,
    [EBBCodeStyles.Bold]: markupNOOP,
    [EBBCodeStyles.Code]: markupNOOP,
    [EBBCodeStyles.Menu]: markupNOOP,
    [EBBCodeStyles.Monospace]: markupNOOP,
};



export function createRepeatedMarkupGenerator(delimiter: string): TMarkupGenerator {
    return (options: IMarkupGeneratorOptions) => `${delimiter}${options.content}${delimiter}`;
}

export function emptyMarkupGenerator(options: IMarkupGeneratorOptions): string {
    return '';
}

export function createParser<Name extends string>(definitions: { [key in Name]: TMarkupGenerator }): BBCodeParser {
    return new BBCodeParser(createTags(definitions) as unknown as BBTag[]);
}

export function createTags<Name extends string>(definitions: { [key in Name]: TMarkupGenerator }): Record<Name, BBTag> {
    const tags = {} as Record<Name, BBTag>;
    
    keys(definitions).forEach((name: Name) => {
        const generator: TMarkupGenerator = definitions[name];
        tags[name] = BBTag.createTag(name, createMarkupGenerator(generator), false);
    });
    return tags;
}

export function createTagStyles<Name extends EBBCodeStyles>(definitions: { [key in Name]: TMarkupGenerator }): Record<Name, BBTag> {
    return createTags(definitions);
}


function generateIncrementComposed(
    type: EActionTreeIncrementor,
    index: number,
    convertNumbersToEmoji?: boolean,
) {
    const text = generateIncrement(type, index, convertNumbersToEmoji);
    return `[b]${text}${!convertNumbersToEmoji ? ' -' : ''}[/b]`;
    // 
}

export function whatsAppMenuMarkupGenerator(options: IMarkupGeneratorOptions) {
    return textMenuMarkupGenerator(options);
}

export function textMenuMarkupGenerator(options: IMarkupGeneratorOptions) {
    let index: number = 0;
    const [incrementInputType, mode, inputIndex]: string[] = options.attrs[0].split('-');
    const inputIndexNumber: number = Number(isNumeric(mode) ? mode : inputIndex);
    if (!isNaN(inputIndexNumber)) {
        index = inputIndexNumber;
    }
    const incrementorType: EActionTreeIncrementor = isValidRef(incrementInputType) ? EActionTreeIncrementor[incrementInputType] : EActionTreeIncrementor.numeric;
    const hasEnhanced: boolean = mode === EBBCodeMenuMode.Enhanced;
    const parser: BBCodeParser = createParser({
        [EBBCodeSubStyles.MenuItem]: (options) => `${generateIncrementComposed(
            incrementorType,
            index++,
            hasEnhanced
        )} ${options.content}`,
    });
    return parseString(parser, options.content);
}


export function createHTMLMarkupTag(tag: string): TMarkupGenerator {
    return (options: IMarkupGeneratorOptions) => `<${tag}>${options.content}</${tag}>`;
}

export const bbcodeHTMLParserDefinitions: Required<BBCodeStyleTags> = {
    [EBBCodeStyles.Italic]: createHTMLMarkupTag('i'),
    [EBBCodeStyles.Bold]: createHTMLMarkupTag('strong'),
    [EBBCodeStyles.Strikethrough]: createHTMLMarkupTag('s'),
    [EBBCodeStyles.Code]: createHTMLMarkupTag('code'),
    [EBBCodeStyles.Monospace]: options => `<p style="font-family:'Lucida Console', monospace">${options.content}</p>`,
    [EBBCodeStyles.Menu]: options => options.content,
}

export const bbCodeToHTMLTagMap: Record<EBBCodeStyles, string> = {
    [EBBCodeStyles.Italic]: "em",
    [EBBCodeStyles.Bold]: "strong",
    [EBBCodeStyles.Strikethrough]: "s",
    [EBBCodeStyles.Code]: "code",
    [EBBCodeStyles.Monospace]: "",
    [EBBCodeStyles.Menu]: "",
};

export const htmlToBBCodeTagMap: Record<string, EBBCodeStyles> = invertObj(bbCodeToHTMLTagMap);

export const bbCodeHTMLTagsArray: string[] = values(bbCodeToHTMLTagMap);

export const regexHTMLToBBCodeKeysReplace: RegExp = new RegExp(`\\<\/?(${bbCodeHTMLTagsArray.join('|')})\\>`, 'gm');
