import {Interaction} from '../interaction';
import { constant, TGlobalUID } from '../../business/constant';
import { Participant } from '../../business/participant';
import { InteractionType } from '../interaction-type';
import { ITrackerInteractionJSON, IInteractionJSON } from '../../comm-interfaces/barrel-comm-interfaces';


export abstract class Tracker extends Interaction {

    private trackerType: number;

    protected constructor (primaryID: TGlobalUID, participant: Participant, trackerType: number) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.changeStateSynch.trackers));
        this.trackerType = trackerType;
    }

    public getTrackerType(): number {return this.trackerType};

    public toJSON(): ITrackerInteractionJSON {
        let interaction: IInteractionJSON = super.toJSON();
        return {...interaction, 
            trackerType: this.trackerType,
        }; 
    };

};
