import {Serializable} from '../business/serializable';
import {constant, TGlobalUID} from '../business/constant';
import {UberCache} from '../persistency/uber-cache';
import { IErrorDomainJSON } from '../comm-interfaces/business-interfaces';

export type TErrorDomainArray = Array<ErrorDomain>;

export class ErrorDomain extends Serializable {

    private errorType: string;

    public toJSON(): IErrorDomainJSON {
        return {...super.toJSON(), errorType: this.errorType};
    };
    
    private constructor(primaryID: TGlobalUID) {
        super(constant.objectType.errorDomain, primaryID);
    };

    public rehydrate(json: IErrorDomainJSON) {
        super.rehydrate(json);
        this.errorType = json.errorType;
    };

    public isInternalError(): boolean {return this.errorType == constant.errorType.bug;};
    public isSecurityIssue(): boolean {
        return this.errorType == constant.errorType.securityIssue 
                || this.errorType == constant.errorType.featureNotCoveredByContract
    };
    public isNonBusinessMessage(): boolean {return this.isInternalError() || this.isSecurityIssue();};
    public isBusinessError(): boolean {return ! this.isNonBusinessMessage();};
    public isDisplayMessage(): boolean {return this.errorType === constant.errorType.message};
    
    public static factoryMessage(json: IErrorDomainJSON): ErrorDomain {
        let errorDomain: ErrorDomain = <ErrorDomain>UberCache.uberFactory(json.primaryID, constant.objectType.errorDomain, false);
        if (errorDomain == null)
            errorDomain = new ErrorDomain(json.primaryID);
        errorDomain.rehydrate(json);
        return errorDomain;
    };
      
    public static staticFactory(idError: TGlobalUID): ErrorDomain {
        return <ErrorDomain>UberCache.uberFactory(idError, constant.objectType.errorDomain, true);
    };
    
    public static getGroupTypeArray(): TErrorDomainArray {
        return <TErrorDomainArray>UberCache.getSerializableArray(constant.objectType.errorDomain);
    };
};
