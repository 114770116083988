import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { Compute } from "@colmeia/core/src/tools/utility-types";
import { DefinePartial } from "@colmeia/core/src/tools/utility/types/entities/define";
import { $$ } from "@colmeia/core/src/tools/utility/types/error";
import { $Extends } from "@colmeia/core/src/tools/utility/types/validate";
import { pick } from "../../tools/utility";
import { ITranslationConfig, ITranslationHash } from "../translation/translation-engine";
import { IScreenOptions } from "./atext-types";

export enum EAllSerializable {

    BotEventType = 'botEvent',
    BotEventType2 = 'botEvent2',
    BotEngagement = 'botEngagement',
    botEngagement2 = 'botEngagement2',
    GenerationCoordinator = 'GenerationCoordinator',
    BotTransaction = 'BotTransaction',
    NlpTransaction = 'NlpTransaction',
    assetScreenElements = 'assetScreen',
    MetadataUnities = 'metadataUnities',
    Dashboard = 'Dashboard',
    /* Erros de validação: */
    NonSerErrors1 = 'nserErr1',
    AttendanceErrors = 'attErr1',
    CommErrors = 'nserCommErr1',
    ServicePackErrors = 'servicePackErr1',
    DeployedServicesErrors = 'deployedServicesErr1',
    AccessRoleErrors = 'accessRolesErr1',
    BotTransactionErrors = 'botTransactionsErr',
    AttendanceEventsErrors = 'attendanceEventsErr',
    ServiceIslandErrors = 'serviceIslandErr',
    BotErrors = 'botErr',
    EventErrors = 'evtErr',
    AssetErros = 'assetErr',
    ActionsErrors = 'actErr',
    GraphElement = 'graphElement',
    ServiceChatTypeEnum = 'serviceChatTypeEnum',
    FaceCompanyServiceTypeEnum = 'faceCompanyServiceTypeEnum',
    SocialNetworkErrors = 'SocialNetworkErrors',
    NonSerializableFriendlyName = 'nonSerializableFriendlyName',
    NonSerializableFriendlyName1 = 'NonSerializableFriendlyName1',
    NonSerializableFriendlyName2 = 'NonSerializableFriendlyName2',
    ChainedBases = 'ChainedBases',
    EUtteranceExistsOption = 'EUtteranceExistsOption',
    LGPDOperations = 'LGPDOperations',
    LGPDActionStyle = 'LGPDActionStyle',
    API = 'API',
    APIHeaderData = 'APIHeaderData',
    JobStatus = 'JobStatus',
    /*NEW ::*/

    DashboardsConfig = 'DashboardsConfig',
    VisualElements = 'VisualElements',
    ColmeiaHelpTips = 'ColmeiaHelpTips',
    HelpTipsView = 'HelpTipsView',
    ColmeiaProducts = 'ColmeiaProducts',
    ColmeiaProductsView = 'ColmeiaProductsView',
    PresenceStatus = 'presenceStatus',
    DeployedEmbeddedConfigs = 'DeployedEmbeddedConfigs',
    /*Common*/
    Common = 'common',
    Common2 = 'common2',
    Common3 = 'common3',
    Common4 = 'common4',
    Common5 = 'common5',
    CommonIcons = 'commonIcons',
    Sellable = 'Sellable',

    ConnectionOptions = 'connOptions',
    ConnectionOptionsTwo = 'ConnectionOptionsTwo',
    ConnectionEditEnumOptions = 'ConnectionEditEnumOptions',
    GroupOrderSelectorMode = 'GroupOrderSelectorMode',

    CallCenterAllAgents = 'CallcenterAllAgents',
    LuisTrainingResultType = 'LuisTrainingResultType',
    KBStatusErrorTranslations = 'KBStatusErrorTranslations',
    KBLuisErrorTranslations = 'KBLuisErrorTranslations',
    CallCenter = 'CallCenter',
    CallCenter2 = 'CallCenter2',

    AttendantStatusColor = 'AttendantStatusColor',
    AttIslandType = 'AttIslandType',
    AttAllocationType = 'AttAlocationType',

    /*Views*/
    GeneralForm = 'GeneralForm',
    DashboardGeneralForm = 'DashboardGeneralForm',
    DashboardGeneralVisualizer = 'DashboardGeneralVisualizer',
    BotActionView = 'botActionView',
    GpsTrackingView = 'gpsTrackingView',
    NonSerializable = 'NonSerializableView',
    Share = 'shareView',
    Settings = 'settingsView',
    Connections = 'Connections',
    chatSideBarConstants = 'sideBarLeft.constants',
    ConnectionsType = 'ConnectionsType',
    DeployedServices = 'DeployedServices',
    DeployedServicesTwo = 'DeployedServices2',
    FileDetail = 'FileDetail',
    Chat = 'chatView',
    Groups = 'groupsView',
    GroupsTwo = 'groupsView2',
    Search = 'searchView',
    Attendance = 'attendanceView',
    AttendanceTwo = 'attendanceView2',
    Integration = 'Integration',
    KnowledgeBase = 'KnowledgeBase1',
    KnowledgeBase2 = 'KnowledgeBase2',
    KnowledgeBase3 = 'KnowledgeBase3',
    KBOmniSenseMethods = 'KBOmniSenseMethods',
    KnowledgeBaseAppConfig = 'KnowledgeBaseAppConfig',
    Macro = 'Macro',
    SecurityControl = 'SecurityControl',
    SecurityControlAccessType = 'SecurityControlAccessType',
    ServiceStatus = 'ServiceStatus',
    SocialNetwork = 'SocialNetwork',
    SocialNetworkInfo = 'SocialNetworkInfo',
    SocialNetworkConfig = 'SocialNetworkConfig',
    SocialNetworkUserSettings = 'DefinicoesUsuario',
    SocialNetworkPrimaryCEPSearchType = 'SocialNetworkPrimaryCEPSearchType',
    SocialMedia = 'SocialMedia',
    WhatsAppTemplate = 'WhatsAppTemplate',
    Canonical = 'Canonical',
    DashboardView = 'DashboardView',
    Tag = 'Tag',
    MetaEngagement = 'MetaEngagement',
    MetaEngagementSourcePlaceholds = 'MetaEngagementSourcePlaceholds',
    BotView1 = 'BotView1',
    BotView2 = 'BotView2',
    Generative = 'Generative',
    /*Messages*/
    Warnings = 'warnings.svc',
    /*Fragments*/
    FragmentsOne = 'fragments1',
    FragmentsTwo = 'fragments2',
    FragmentsThree = 'fragments3',
    FragmentsFour = 'fragments4',
    FragmentsFive = 'fragments5',
    FragmentsSix = 'fragments6',
    FragmentsSeven = 'fragments7',
    FragmentsEight = 'fragments8',
    SharedEnumOne = 'sharedenum1',
    MetadataNames = 'MetadataNames',
    MetadataNames2 = 'MetadataNames2',
    SendCampaign = 'SendCampaignView',
    Campaign = 'Campaign',
    SalesforceCredentials = 'salesforceCredentials',
    ConfirmationTypeTranslations = 'confirmationTypeTranslations',
    ActiveAgent = 'ActiveAgent',
    Annotations = 'AnnotationsView',
    Transformations = 'transformations',
    UserFunction = 'UserFunction',
    AvailablePrebuildEntityName = 'AvailablePrebuildEntityName',
    AvailablePrebuildEntityDescription = 'AvailablePrebuildEntityDescription',
    AvailablePrebuildEntityExample = 'AvailablePrebuildEntityExample',

    ChatOptionsGoToDashboard = 'GoToDashboard',

    CloudNaturalLanguageSyntaxTag = 'CloudNaturalLanguageSyntaxTag',
    CloudNaturalLanguageEntityType = 'CloudNaturalLanguageEntityType',


    CloudNaturalLanguageSyntaxLabel = 'CloudNaturalLanguageSyntaxLabel',
    CloudNaturalLanguageSyntaxLabel2 = 'CloudNaturalLanguageSyntaxLabel2',
    CloudNaturalLanguageSyntaxLabel3 = 'CloudNaturalLanguageSyntaxLabel3',
    CloudNaturalLanguageSyntaxLabel4 = 'CloudNaturalLanguageSyntaxLabel4',

    CRMConfigServerErrors = 'CRMConfigErrors',
    CRMConfigEntityErrors = 'CRMConfigEntityErrors',
    CRMConfigItemEntityErrors = 'CRMConfigItemEntityErrors',
    CRMSRPackageEntityFields = 'CRMSRPackageEntityFields',
    CRMESubscribedTo = 'CRMESubscribedTo',
    CRMDBType = 'CRMDBType',
    CRMSeverityLevel = 'CRMSeverityLevel',
    CRMUrgencyLevel = 'CRMUrgencyLevel',
    CRMSupportLevel = 'CRMSupportLevel',
    CRMServiceManagementPhases = 'CRMServiceManagementPhases',
    CRMTicketState = 'CRMTicketState',
    CRMCloseState = 'CRMCloseState',
    CRMBPMActions = 'CRMBPMActions',
    BPMMktActions = 'MKTBPMActions',
    CRMTicketGenerator = 'CRMTicketGenerator',

    ActiveSends = 'SendActive',

    CloudNaturalLanguageAnalysisOptionType = 'CloudNaturalLanguageAnalysisOptionType',
    CloudNaturalLanguageAnalysisLangugage = 'CloudNaturalLanguageAnalysisLangugage',


    AttendanceEvents = 'AttendanceEvents',
    AttendanceCopilot = 'AttendanceCopilot',
    BBCodeFields = 'BBCodeFields',


    /* Errors */
    ServerErrors = 'ServerErrors',
    ServerErrors2 = 'ServerErrors2',
    ServerErrors3 = 'ServerErrors3',
    ClientErrors = 'ClientErrors',

    DataExtractor = 'DataExtractor',

    BPMGraph = 'BPMGraph',

    ActiveCall = 'ActiveCall',
    SendActiveMethod = 'SendActiveMethod',

    AcOnTools = 'acOnTools',

    CorporateSearchErrors = "CorporateSearchErrors",
    SmartflowRTFParamErrors = "SmartflowRTFParamErrors",
    ESmartField = 'ESmartField',
    WhatsappAdvancedTemplateOptions = 'WhatsappAdvancedTemplateOptions',
    WhatsappLanguages1 = 'WhatsappLanguages1',
    WhatsappLanguages2 = 'WhatsappLanguages2',
    WhatsappLanguages3 = 'WhatsappLanguages3',
    WhatsappCategory = 'WhatsappCategory',
    WhatsappCategoryV14 = 'WhatsappCategoryV14',
    SocialMediaConnectionErrors = 'SocialMediaConnectionErrors',
    SubmitWhatsAppTemplateError = 'submitWhatsAppTemplate',
    WhatsAppTemplateValidationError = 'WhatsAppTemplateValidationError',
    WhatsAppTemplateApprovalStatus = 'WhatsAppTemplateApprovalStatus',
    WhatsAppTemplateRejectedReason = 'WhatsAppTemplateRejectedReason',
    Waba = 'whatsappBusinessAccount',
    MarketingTemplates = 'MarketingTemplates',
    MarketingTemplateErrors = 'MarketingTemplateErrors',
    DeliveryProvider = 'DeliveryProvider',
    AdHocSubMenus = 'AdHocSubMenus',
    ContractItemError = 'ContractItemError',
    AttendantServicePackErrors = 'AttendantServicePackErrors',
    AttendanceCopilotErrors = 'AttendanceCopilotErrors',
    UserFunctionErrors = 'UserFunctionErrors',
    ConfigurationSetErrors = 'ConfigurationSetErrors',
    AgentFileErrors = 'AgentFileErrors',
    ColmeiaBroker = 'ColmeiaBroker',
    CatalogVisibility = 'CatalogVisibility',
    AttendanceEntryCancelReason = 'AttendanceEntryCancelReason',
    ContentVectors = 'ContentVectors',
    ContentVectorsErrors = 'ContentVectorsErrors',

    WhatsappFlowsTranslations = 'WhatsappFlowsTranslations'
}

export enum EAllSerializable {
    KBErrors = 'KBErrors',
    UtteranceErrors = 'UtteranceErrors',

    BackupPackage = 'BackupPackage',
    BackupSnapshot = 'BackupSnapshot',

    BackupPackageError = 'BackupPackageError',
    BackupSnapshotError = 'BackupSnapshotError',
    CoordinatorDeletion = 'CoordinatorDeletion',
    WhatsAppVertical = 'WhatsAppVertical',
    KBAssetTypeTranslations = 'KBAssetTypeTranslations',
}

// analytics
export enum EAllSerializable {
    AnalyticsAgent = 'AnalyticsAgent',
    AnalyticsAttendance = 'AnalyticsAttendance',
    AnalyticsBot = 'AnalyticsBot',
    AnalyticsForms = "AnalyticsForms",
    AnalyticsLGPD = 'AnalyticsLGPD',
    marketing = 'marketing',
    AnalyticsMacroView = 'AnalyticsMacroView',
    AnalyticsAi = 'AnalyticsAi',
    AnalyticsCustomReports = 'AnalyticsCustomReports',
    GenericServiceStatus = 'GenericServiceStatus',
    AnalyticsCorporateAttendance = 'AnalyticsCorporateAttendance',
    AnalyticsCorporateMarketing = 'AnalyticsCorporateMarketing'
}

// @ts-expect-error
export interface PseudoInterface<E extends {}, T extends {} = {}, OtherT extends {} = {}> extends OtherT, T, E { }


type ConfigItem<IdSerializable extends EAllSerializable, Field extends number> = PseudoInterface<
    ITranslationConfig,
    {
        serializableId: IdSerializable,
        idField: Field extends MetaTranslation.Field<infer Num> ? Num : Field;
    }
    ,
    Field extends MetaTranslation.Field
    ? {
        meta: Field
    }
    : {}
>
    ;
//
export type GetTranslationsFields<T extends IScreenOptions> = {
    [key in keyof T['fields']]: ConfigItem<T['idSerializable'], T['fields'][key]>
}
export function getTranslationsFields<T extends IScreenOptions>(screenOptions: T): Compute<GetTranslationsFields<T>> {
    const translations: ITranslationHash = {};

    for (let name in screenOptions.fields) {
        const idField: number = screenOptions.fields[name];
        const config: ITranslationConfig = {
            serializableId: screenOptions.idSerializable,
            fieldName: name,
            idField,
        };
        if (MetaTranslation.isField(idField)) {
            config.meta = idField;
        }
        translations[name] = config;
    }

    return (translations) as GetTranslationsFields<T>;
}



export function pickTranslations<T extends ITranslationHash, P extends keyof T>(source: T, properties: P | P[]): Pick<T, P> {
    return pick(source, [].concat(...[properties]) as P[]);
}

export function precisePickTranslations<T extends ITranslationHash, P extends keyof T>(source: T, ...args: P[]) {
    return pick(source, [].concat(...args) as P[]);
}

export function autoPickTranslations<S extends ITranslationConfig, A extends keyof S>(source: S, ...args: A[] | [A[]]) {
    return pick(source, [].concat(...args) as A[]);
}




export type MetaFieldsToNumbers<T> = { [key in keyof T]: T[key] extends MetaTranslation.Field<infer Num, infer Text> ? Num : T[key] }
export function defineTranslations<T extends $Extends<T, IScreenOptions>>(config: T & IScreenOptions & DefinePartial<IScreenOptions, { fields: T['fields'] & $$.NonRepeatedValues<MetaFieldsToNumbers<T['fields']>> }>): Compute<GetTranslationsFields<T>>
export function defineTranslations<T extends IScreenOptions>(screenOptions: T) {
    return getTranslationsFields(screenOptions)
}

export function getTranslationsFieldsAndScreenOptions<T extends IScreenOptions>(screenOptions: T) {
    const result = ({
        screenOptions,
        translations: getTranslationsFields(screenOptions),
    } as const);


    result as {
        screenOptions: IScreenOptions,
        translations: ITranslationHash
    };

    return result;
}
