import {MainSignal} from "../../../model/signal/main-signal";
import {observableList, SignalType} from "../../../model/signal/signal-constants";

export interface IPhoneEvent {
    event: string;
    signal: CordovaHardwareEventConstructor,
    preventDefault: boolean
}

export interface CordovaHardwareEventConstructor {
    new(evt: Event): MainSignal;
}

export class CordovaHardwareEvent extends MainSignal {
    constructor(type: SignalType, evt: Event) {
        super(type, true, evt);
    }

    public getEvent(): Event {
        return this.getObservableInformation();
    }
}

export class PausedApplication extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.state.paused, evt);
    }
}

export class ResumedApplication extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.state.resumed, evt);
    }
}

export class MenuButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.menu, evt);
    }
}

export class SearchButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.search, evt);
    }
}

export class StartCallButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.call.start, evt);
    }
}

export class EndCallButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.call.end, evt);
    }
}

export class VolumeDownButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.volume.down, evt);
    }
}

export class VolumeUpButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.volume.up, evt);
    }
}

export class BackButtonPressed extends CordovaHardwareEvent{
    constructor (evt: Event) {
        super(observableList.mobile.buttons.back, evt);
    }
}

export class CordovaActivated extends CordovaHardwareEvent {
    constructor (evt: Event) {
        super(observableList.mobile.state.activated, evt);
    }
}

export const cordovaHardwareEvents: Array<IPhoneEvent> = [
    {
        event: "pause",
        signal: PausedApplication,
        preventDefault: true
    },
    {
        event: "resume",
        signal: ResumedApplication,
        preventDefault: true
    },
    {
        event: "menubutton",
        signal: MenuButtonPressed,
        preventDefault: true
    },
    {
        event: "searchbutton",
        signal: SearchButtonPressed,
        preventDefault: true
    },
    {
        event: "startcallbutton",
        signal: StartCallButtonPressed,
        preventDefault: true
    },
    {
        event: "endcallbutton",
        signal: EndCallButtonPressed,
        preventDefault: true
    },
    {
        event: "volumedownbutton",
        signal: VolumeDownButtonPressed,
        preventDefault: false
    },
    {
        event: "volumeupbutton",
        signal: VolumeUpButtonPressed,
        preventDefault: false
    },
    {
        event: "activated",
        signal: CordovaActivated,
        preventDefault: true
    },
    {
        event: "backbutton",
        signal: BackButtonPressed,
        preventDefault: false
    }
];
