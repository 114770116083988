import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { isInvalid, isValidNumber, isValidString } from "../../../../tools/utility";
import { isClientValidNamedAccountConfig, isValidNamedAccountConfig } from "../../../attendance-island/attedance-helpers";
import { EIslandType, IIslandAttendant, IServiceIsland } from "../../../attendance-island/attendance-island";
import { IAttendanceCalendar } from "../../../attendance-island/ns-sheduler";
import { INonSerializable } from "../../non-serializable-id-interfaces";
import { serviceIslandErrorsTranslations } from "../error-fields";
import {
    maximumLoadGreaterThanUnitError,
    namedAccountError, nserCalendarTimezoneRequired,
    nserCommGroupError,
    nserInsertNameError,
    nserIslandCalendarError, nserIslandEventsError, nserIslandMetadataError, nserIslandNamedAccountError, nserIslandTypeError,
    nserServiceGroupError, nserSmallNameError
} from "../error-messages";
import { minLength, nserNameMinLength } from "../helpers";
import { TNserValidator } from "../validate-nser";
import { IPresenceStatusServer } from './../../../attendent-service-pack/attendente-service-pack';
import { defaultNserValidator } from "./nser";



/*
export interface IServiceIsland extends INonSerializableHeader {
    idServiceGroup: TGlobalUID; // A partir desse grupo, serão criado todos os grupos para atendimento
    idISlandCommunicationGroup: TGlobalUID; // Normalmente um grupo de Broadcast
    islandType: EServiceChatType;
    schemaId?: string;
    servicePackId: string;
    crmIntegration?: ICRMIntegration;
    concurrentAppointments?: number;  // numero atendimebtis
    removeAccessWhenFinished?: number; // Em dias. 0 logo apRemove o acesso do atendente após o término
}
* */

export const attendantCalendarValidator: TNserValidator = (nser: IAttendanceCalendar, friendly: FriendlyMessage) => {

    if (! nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if(!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    // const hasInvalidOffsets = checkOffsetFromIntervals(nser)

    // if (hasInvalidOffsets) {
    //     friendly.addReturnTranslationResponse(gTranslations.attendance.invalidCalendarOffsets);
    // }

    if(isInvalid(nser.timezone)) {
        friendly.addReturnResponse(nserCalendarTimezoneRequired);
    }

    return friendly;
}



// type CalendarWithDeepPaths = Compute<MetaGetDeepTypedProperty.MapDeepTypedProperty<IAttendanceCalendar, {
//     IsIgnoringArray: true;
// }>>

// type RemoveParentKey<T> = T extends string ? Join<UnShift<Split<T, '.'>>, '.'> : never;

// type IntervalPaths =
//     DeepFindValuesOfTypeV2<
//         DeepFindValuesOfTypeV2<CalendarWithDeepPaths, IInterval>,
//         $$<unknown>
//     > extends $$<infer Messages> ?
//     UnionToArray<RemoveParentKey<Messages>>
//     : never
// ;

// const deepPathOfIntervals: IntervalPaths = [
//     'exceptions.intervals',
//     'attendanceDays.0',
//     'attendanceDays.1',
//     'attendanceDays.2',
//     'attendanceDays.3',
//     'attendanceDays.4',
//     'attendanceDays.5',
//     'attendanceDays.6',
// ]

// export function getIntervalsFromCalendar(nser: IAttendanceCalendar): IInterval[] {
//     const intervals: IInterval[] = flattenDeep(getDeepPropertyWithIgnoredArray(nser, deepPathOfIntervals)).filter(item => item).map(item => item as IInterval)
//     return intervals
// }

// function checkOffsetFromIntervals(nser: IAttendanceCalendar): boolean {
//     const intervals = getIntervalsFromCalendar(nser);
//     let offset: number = intervals?.[0]?.offset;
//     return intervals.some(interval => interval.offset !== offset);
// }

export const serviceIslandValidator: TNserValidator = (nser: IServiceIsland, friendly: FriendlyMessage) => {

    if (! nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if(!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }
    
    if (isInvalid(nser.islandType) || !Object.values(EIslandType).includes(nser.islandType)) {
        friendly.addReturnResponse(nserIslandTypeError);
    }

    if (!isValidString(nser.idServiceGroup)) {
        friendly.addReturnResponse(nserServiceGroupError); // Insira um grupo de atendimento
    }

    if (nser.islandType !== EIslandType.crmColmeia) {
    
        if (!isValidString(nser.idISlandCommunicationGroup)) {
            friendly.addReturnResponse(nserCommGroupError); // Insira um grupo de comunicação
        }
    
        if (isInvalid(nser.transferForm)) {
            friendly.addReturnResponse(nserIslandMetadataError); // Selecione um metadado de transferência
        }
    
        if (!isValidString(nser.idCalendar)) {
            friendly.addReturnResponse(nserIslandCalendarError); // Selecione um Calendário de atendimento
        }
    
        if (!isValidString(nser.eventsId)) {
            friendly.addReturnResponse(nserIslandEventsError); // Selecione os eventos de atendimento
        }
    }

    if(nser.useNamedAccount && !isValidNamedAccountConfig(nser.namedAccount)){
        friendly.addReturnResponse(nserIslandNamedAccountError);
    }

    // if (! hasValidContent(nser.messages.enqueueMessage)) {
    //     friendly.addReturnResponse(nserIslandInsertEnqueuedMessage);
    // }

    // if (! hasValidContent(nser.messages.timeRestriction)) {
    //     friendly.addReturnResponse(nserIslandInsertTimeRestritionMessage);
    // }

    if (!isClientValidNamedAccountConfig(nser.namedAccount)) {
        friendly.addReturnResponse(namedAccountError);
    }

    if(!isValidNumber(nser.weight) || !isValidNumber(nser.maxWeight)) {
        friendly.addReturnTranslationResponse(serviceIslandErrorsTranslations.invalidWorkLoadSettings);
    } else if (nser.weight > nser.maxWeight) {
        friendly.addReturnResponse(maximumLoadGreaterThanUnitError);
    }

    if(!isValidString(nser.servicePackId)) {
        friendly.addReturnTranslationResponse(serviceIslandErrorsTranslations.invalidServicePack);
    }

    return friendly;
};

export const attendantValidator: TNserValidator = (nser: IIslandAttendant, friendly: FriendlyMessage) => {
    if (isInvalid(nser.idAvatar)) {

    }
    return friendly;
};

export function attendantStatus(nser: IPresenceStatusServer, friendly: FriendlyMessage) {
    defaultNserValidator(nser as INonSerializable, friendly);
    
    return friendly;
}