import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EUnitTypeID } from '@colmeia/core/src/business/constant.enums';
import { GeneralFormField, IGeneralFormAnswerServer, IRFieldResponse, TGeneralFormFieldAnswerValue } from '@colmeia/core/src/general-form/general-form-answer';
import { GeneralFormEvaluator } from '@colmeia/core/src/general-form/general-form-evaluator';
import { SchemaProperty, TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { nonNullable } from '@colmeia/core/src/tools/type-utils';
import { empty, isValidRef } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { OnChange } from 'app/model/client-utility';
import { EGeneralFormViewType, GeneralFormFieldInjectionToken, GeneralFormFieldRenderer, IGeneralFormDynamicField, IGeneralFormFieldData, TFieldAnswers } from 'app/model/general-form.model';
import { Subject, Subscription } from 'rxjs';
import { GeneralFormBooleanFieldComponent } from '../general-form-boolean-field/general-form-boolean-field.component';
import { GeneralFormDateFieldComponent } from '../general-form-date-field/general-form-date-field.component';
import { GeneralFormImageFieldComponent } from '../general-form-image-field/general-form-image-field.component';
import { GeneralFormMultipleChoiceFieldComponent } from '../general-form-multiple-choice-field/general-form-multiple-choice-field.component';
import { GeneralFormObjectFieldComponent } from '../general-form-object-field/general-form-object-field.component';
import { GeneralFormStringFieldComponent } from '../general-form-string-field/general-form-string-field.component';
import { GeneralFormFieldService } from '../services/general-form-field.service';
import { GeneralFormFieldViewerHandler } from './general-form-field-viewer.handler';

export const SelfHandledMultipleAnswer: EUnitTypeID[] = [
    EUnitTypeID.stringType,
    EUnitTypeID.numberType
];


@Component({
    selector: 'app-general-form-field-viewer',
    templateUrl: './general-form-field-viewer.component.html',
    styleUrls: ['./general-form-field-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GeneralFormFieldViewerComponent extends RootComponent<'add'> {
    component: any = null;
    fieldInjector: Injector;
    answers: TFieldAnswers = [];
    isEdit: boolean = true;
    private field: GeneralFormField;


    get parameters() { return this.handler.getComponentParameter() }
    get generalFormEvaluator(): GeneralFormEvaluator { return this.parameters.generalFormEvaluator }
    get answer(): IGeneralFormAnswerServer { return this.parameters.answer }
    get schemaProp(): SchemaProperty { return this.parameters.schemaProp }
    get viewType(): EGeneralFormViewType { return this.parameters.viewType }
    get fieldCreation() { return this.parameters.fieldCreation }
    get previousAnswer(): IRFieldResponse { return this.parameters.previousAnswer }
    get fields(): GeneralFormField[] { return this.parameters.fields }
    get schemaForm(): TSchemaPropertyArray { return this.parameters.schemaForm }
    get fGroup(): UntypedFormGroup { return this.parameters.fGroup }
    get fControl(): UntypedFormControl { return this.parameters.fControl }
    get idStartInteraction(): string | undefined { return this.parameters.idStartInteraction }


    _value: TGeneralFormFieldAnswerValue;
    @Input()
    set value(value) {
        this._value = value

        if (this.field) {
            this.field.value ??= value;
        }
    }
    get value() {
        return this._value
    }


    @OnChange()
    @Input()
    handler: GeneralFormFieldViewerHandler;

    appropriateComponentHash: { [key in EUnitTypeID]: { prototype: GeneralFormFieldRenderer<string> } } = {
        [EUnitTypeID.stringType]: GeneralFormStringFieldComponent,
        [EUnitTypeID.numberType]: GeneralFormStringFieldComponent,
        [EUnitTypeID.logicalType]: GeneralFormBooleanFieldComponent,
        [EUnitTypeID.dateType]: GeneralFormDateFieldComponent,
        [EUnitTypeID.imageType]: GeneralFormImageFieldComponent,
        [EUnitTypeID.objectType]: GeneralFormObjectFieldComponent,
        [EUnitTypeID.multipleChoiceType]: GeneralFormMultipleChoiceFieldComponent,
        [EUnitTypeID.geoReferencingOrZipType]: GeneralFormStringFieldComponent,
        [EUnitTypeID.groupInput]: empty,
        [EUnitTypeID.avatarInput]: empty,
    }
    subscription: Subscription;


    constructor(
        private generalFormFieldService: GeneralFormFieldService,
        private injector: Injector,
    ) {
        super({
            add: gTranslations.common.add
        })
    }

    get singleField() {
        return this.generalFormFieldService.isSelfHandledMultipleAnswer(this.schemaProp.idUnity) || !this.schemaProp.multipleAnswers;
    }

    async ngOnInit() { }

    public onChangeHandler(): void {
        this.value = this.parameters.value;
        this.init();
    }

    public init(): void {
        this.isEdit = this.viewType !== EGeneralFormViewType.view;
        this.field = new GeneralFormField(this.schemaProp, this.value, isValidRef(this.previousAnswer) ? this.previousAnswer : undefined);

        this.fieldCreation(this.field);

        this.loadAppropriateComponent();

        if (this.singleField) {
            this.fieldInjector = this.createInjector(this.field);
        } else {
            console.log({ value: this.value })
            if (isValidRef(this.value)) {
                (this.value as any[]).forEach(this.addChild);
            } else {
                this.addChild();
            }
        }
    }

    loadAppropriateComponent() {
        this.component = this.getAppropriateComponent();
    }

    getAppropriateComponent() {
        return nonNullable(this.appropriateComponentHash[this.schemaProp.idUnity])
    }

    createInjector(field: GeneralFormField): Injector {
        const token: IGeneralFormFieldData = {
            field,
            value: field.value,
            viewType: this.viewType,
            parentExpandEvent: new Subject<boolean>(),
            childExpandEvent: new Subject<boolean>(),
            isList: !this.singleField,
            childIndex: this.answers.length,
            isEdit: this.isEdit,
            generalFormEvaluator: this.generalFormEvaluator,
            root: this.fGroup,
            fGroup: this.fGroup,
            fControl: this.fControl,
            answers: this.answers,
            fields: this.fields,
            schemaForm: this.schemaForm,
            idServiceInteraction: this.idStartInteraction,
        }

        return Injector.create({
            providers: [{
                provide: GeneralFormFieldInjectionToken,
                useValue: token
            }],
            parent: this.injector
        });
    }

    addChild = (value?: any) => {
        // close all children
        this.answers.forEach(answer => {
            answer.injector.get(GeneralFormFieldInjectionToken).parentExpandEvent.next(false);
        });

        const field = this.field.createChild(null, true, value);
        const injector = this.createInjector(field);
        const child: IGeneralFormDynamicField = { field, injector };
        this.answers.push(child);

        // Listen for expand events from child
        child.injector.get(GeneralFormFieldInjectionToken).childExpandEvent.subscribe(expanded => {
            this.handleChildExpandChange(child, expanded);
        });
    }

    handleChildExpandChange(child: IGeneralFormDynamicField, isExpanded: boolean) {
        const childIndex = child.injector.get(GeneralFormFieldInjectionToken).childIndex;
        if (isExpanded) {
            for (let index = 0; index < this.answers.length; index++) {
                if (index === childIndex) {
                    continue;
                }
                const token = this.answers[index].injector.get(GeneralFormFieldInjectionToken);
                token.parentExpandEvent.next(false);
            }
        }
    }

    deleteAnswer(index: number) {
        const field: GeneralFormField = this.answers[index].field;
        this.answers.splice(index, 1);
        this.field.deleteChild(field);
        let counter = 0;
        this.answers.forEach(answer => answer.injector.get(GeneralFormFieldInjectionToken).childIndex = counter++);
    }
}
