import { Component, Input, OnInit } from '@angular/core';
import { EBPMAction } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { IBotActionAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-action-model';
import { IBasicAsset, KAssetTypeClient, KBAssetType, KBAssetTypeClientOnly, TBasicAssetArray } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { EBotContentEvent, IContentBasicAsset, TContentAssetArray } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { IBotEvent, TBotEventArray } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { isLeafAction, isRootAction } from '@colmeia/core/src/shared-business-rules/bot/bot-filter-dictionary';
import { ENextGenBotElementType, IBotMenuItemServer, IBotRootServer, INextGenBotServer, getInitialActionWithId } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { getBotActionsByCharacteristic } from '@colmeia/core/src/shared-business-rules/bot/bot-other-client-functions';
import { EBotActionType, TBotActionTypeArray } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { IServerLocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { IServerColmeiaTag, TColmeiaTagArray } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { pickTranslations } from '@colmeia/core/src/shared-business-rules/const-text/all-serializables';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { IVariable } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isInvalid, isValidAndEqual, isValidArray, isValidNumber, isValidRef } from '@colmeia/core/src/tools/utility';
import { CanonicalPickerComponent } from 'app/components/canonical-picker/canonical-picker.component';
import { AssetAdderComponent } from 'app/components/dashboard/asset-adder/asset-adder.component';
import { IBotActionHandler } from 'app/components/dashboard/bot-action-editor/bot-action-editor.component';
import { DynamicComponentHandler } from 'app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler';
import { NewConditionEditorComponent, NewConditionEditorHandler, NewConditionEditorParameter } from 'app/components/dashboard/new-condition-editor/new-condition-editor.component';
import { IVariableInputHandler, VariableTagsComponent } from 'app/components/dashboard/variable-tags/variable-tags.component';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { BotEditConfigurationHandler, IBotEditConfigurationHandlerParameter } from 'app/handlers/bot-edit-configuration.handler';
import { CanonicalPickerHandler, ICanonicalPickerHandlerClientCallback } from 'app/handlers/canonical/canonical-picker.handler';
import { MainHandler } from 'app/handlers/main-handler';
import { EVarEditorEntityType } from 'app/handlers/var-editor.handler';
import { IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { BotEventsHandler } from 'app/model/dashboard/bot/bot-events.handler';
import { BpmService } from 'app/services/bpm/bpm.service';
import { MenuPreviewService } from 'app/services/dashboard/menu-peview.service';
import { LookupService } from 'app/services/lookup.service';
import { BotActionComponent } from '../../../bots/bot-action/bot-action.component';
import { BotConfigurationComponent } from '../../../bots/bot-edit/bot-configuration/bot-configuration.component';
import { BotEventsComponent } from '../../../bots/bot-events/bot-events.component';
import { BotGraphElementInfoBotHandler, EGraphElementInfoBotHandlerTabs, botEditTabsIcons } from './bot-graph-element-info-bot.handler';
import { ColmeiaService } from 'app/components/dashboard/colmeia/colmeia.service';

function isBotRoot(bot): bot is IBotRootServer {
    return bot.botLevel === ENextGenBotElementType.root;
}

type TBotGraphElementInfoBotComponentTab = { title: string; handler: DynamicComponentHandler; expanded: boolean };
const allAssetTypes = [...Object.values(KBAssetType), KBAssetTypeClientOnly.sticker];


@Component({
    selector: 'app-bot-graph-element-info-bot',
    templateUrl: './bot-graph-element-info-bot.component.html',
    styleUrls: ['./bot-graph-element-info-bot.component.scss']
})
export class BotGraphElementInfoBotComponent extends RootComponent<any> implements OnInit {

    @Input()
    public handler: BotGraphElementInfoBotHandler;
    public get parameters() { return this.handler.getComponentParameter() }
    public get entity(): INextGenBotServer { return this.parameters.entity }
    public handlers: TBotGraphElementInfoBotComponentTab[];
    public bpmEvaluatorHandler: NewConditionEditorHandler;
    public botEventsHandler: BotEventsHandler;
    public assetAdderHandler: IAssetAdderHandler;
    public preContentHandler: IAssetAdderHandler;
    public posContentHandler: IAssetAdderHandler;
    public canonicalPickerHandler: CanonicalPickerHandler;
    rootAction: TBotActionTypeArray = getBotActionsByCharacteristic(isRootAction);
    leafActions: TBotActionTypeArray = getBotActionsByCharacteristic(isLeafAction);
    public eventActionHandler: IBotActionHandler;
    public menuContainerConfigurationHandler: BotEditConfigurationHandler;
    selectedVariableTags: TColmeiaTagArray = [];
    variableTagsHandler: IVariableInputHandler;
    public botEditTabsIcons = botEditTabsIcons;

    public selectedTabIndex: number = 0;
    private botTabsMap: EGraphElementInfoBotHandlerTabs[] = [
        EGraphElementInfoBotHandlerTabs.initialConfiguration,
        EGraphElementInfoBotHandlerTabs.conditionalDisplay,
        EGraphElementInfoBotHandlerTabs.actionConfiguration,
        EGraphElementInfoBotHandlerTabs.canonicals,
        EGraphElementInfoBotHandlerTabs.variables,
        EGraphElementInfoBotHandlerTabs.preContent,
        EGraphElementInfoBotHandlerTabs.posContent,
        EGraphElementInfoBotHandlerTabs.events
    ];

    public constructor(
        private bpmSvc: BpmService,
        private lookupSvc: LookupService,
        private menuPreviewSvc: MenuPreviewService,
        private colmeiaSvc: ColmeiaService,
    ) {
        super({
            ...pickTranslations(gTranslations.bot, [
                'optionAction',
            ])
        })
    }

    public ngOnInit() {
        this.init();
    }

    public async init(): Promise<void> {
        this.handlers = [];

        this.initBotConfigurationHandler();

        switch (this.entity.botLevel) {
            case ENextGenBotElementType.root:
                await this.initBotRootHandlers();
            case ENextGenBotElementType.botMenuItem:
                this.initActionConfigurationHandler();

                if (this.entity.botLevel === ENextGenBotElementType.botMenuItem) {
                    this.initConditionalDisplayConfigurationHandler();
                };
                break;
        }

        this.initAssetHandlers();
        this.initBotEventsHandler();

        const selectedTabIndex = this.handlers.findIndex(h => h.expanded);
        if (isValidNumber(selectedTabIndex, 0)) {
            this.selectedTabIndex = selectedTabIndex
        }
    }

    private initConditionalDisplayConfigurationHandler() {
        const entity = this.entity as IBotMenuItemServer;

        if (!isValidArray(entity.condDisplay, 0)) {
            entity.condDisplay = [];
        }

        const parameter: NewConditionEditorParameter = {
            field: undefined,
            fields: [],
            clientCallback: undefined,
            allowedActionTypes: {
                [EBPMAction.conditionalDisplay]: true,
            },
            currentConditions: entity.condDisplay,
            allowedSourceInfo: [
                ESourceOfInfo.canonical,
                ESourceOfInfo.bot,
                ESourceOfInfo.metrics,
                ESourceOfInfo.serviceScheduler,
                ESourceOfInfo.botTracker,
            ],
            disableAutoAdd: true,
        }

        this.bpmEvaluatorHandler = new NewConditionEditorHandler(parameter);
        this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.conditionalDisplay, this.bpmEvaluatorHandler, NewConditionEditorComponent);
    }

    public mapCanonicalEntity(ns: IServerLocalCanonical): IVariable {
        return {
            text: ns.nName,
            idProperty: ns.idNS,
        };
    }

    public initActionConfigurationHandler(): void {
        this.eventActionHandler = null;
        let actionTypes: TBotActionTypeArray;
        let actionConfig: IBotActionAsset;
        const preSelectedActionBase = { ...getInitialActionWithId(), type: this.parameters.preSelectedAction as EBotActionType };
        let hasPreSelectedActionAndIsDiffFromActual: boolean;
        let rootNS = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor().getRootElement()?.getHostedObject() as INonSerializable;

        switch (this.entity.botLevel) {
            case ENextGenBotElementType.root:
                actionTypes = this.rootAction;
                hasPreSelectedActionAndIsDiffFromActual = this.parameters.preSelectedAction && this.parameters.preSelectedAction !== (<IBotRootServer>this.entity).firstAction.type

                if (hasPreSelectedActionAndIsDiffFromActual) {
                    (<IBotRootServer>this.entity).firstAction = { ...preSelectedActionBase }
                }
                actionConfig = (<IBotRootServer>this.entity).firstAction;
                rootNS = this.entity;
                break;
            case ENextGenBotElementType.botMenuItem:
                actionTypes = this.leafActions;
                hasPreSelectedActionAndIsDiffFromActual = this.parameters.preSelectedAction && this.parameters.preSelectedAction !== (<IBotMenuItemServer>this.entity).action.type

                if (hasPreSelectedActionAndIsDiffFromActual) {
                    (<IBotMenuItemServer>this.entity).action = { ...preSelectedActionBase }
                }
                actionConfig = (<IBotMenuItemServer>this.entity).action;
                break;
        };

        this.eventActionHandler = {
            actionTypes,
            botRoot: <IBotRootServer>rootNS,
            actionHost: this.entity,
            botAction: actionConfig,
            botMenuItem: this.entity.botLevel === ENextGenBotElementType.botMenuItem ? (this.entity as IBotMenuItemServer) : undefined,
            actionTitle: this.translations.optionAction.value,
            isEventAction: false,
            idCurrentBot: this.entity.idNS,
        }

        if (isValidRef(this.eventActionHandler)) {
            this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.actionConfiguration, this.eventActionHandler, BotActionComponent);
        }
    }


    public initBotConfigurationHandler(): void {

        const botEditParameter: IBotEditConfigurationHandlerParameter = {
            botElement: this.entity,
            clientCallback: this,
            getAssetAdderVariables: () => this.getAssetAdderVariables()
        };
        this.menuContainerConfigurationHandler = new BotEditConfigurationHandler(botEditParameter);

        this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.initialConfiguration, this.menuContainerConfigurationHandler, BotConfigurationComponent);
    }


    public onBotElementConfigurationMetadataChange(idMetadata: string) { }

    public get showContent(): boolean {
        return isValidRef(this.botEventsHandler) && isValidRef(this.posContentHandler) && isValidRef(this.preContentHandler) && isValidArray(this.handlers);
    }

    assetAdderHandlerAssetsContent(isPos: EBotContentEvent): TBasicAssetArray {
        return this.entity.events.filter(asset => isValidAndEqual((<IContentBasicAsset>asset).botContentType, isPos));
    }

    private initBotEventsHandler(): void {
        this.botEventsHandler = new BotEventsHandler({
            branchType: this.entity.botLevel,
            events: <TBotEventArray>this.entity.events,
        });
        this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.events, this.botEventsHandler, BotEventsComponent);
    }

    private get enabledAssetsToAssetHandler(): Array<KAssetTypeClient> {
        return allAssetTypes;
    }

    private getAssetContentHandler(content: EBotContentEvent): IAssetAdderHandler {
        const targetAsset = {
            [EBotContentEvent.posContent]: 'posContentHandler',
            [EBotContentEvent.preContent]: 'preContentHandler',
        };

        const enableChangePositions: boolean = EBotContentEvent.preContent === content || EBotContentEvent.posContent === content;

        return {
            enableChangePositions,
            showConditionalDisplay: true,
            alloweedGrouping: true,
            assets: this.assetAdderHandlerAssetsContent(content),
            assetTypesEnabled: this.enabledAssetsToAssetHandler,
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                const asset: IBasicAsset = assets[index];
                this.botEventsHandler.removeEvent(<IBotEvent>asset);
                const preOrPos = targetAsset[content];
                this[preOrPos].assets = this.assetAdderHandlerAssetsContent(content);

                this.updateMenuPreview();

                return Promise.resolve(true);
            },
            saveAsset: (newAsset: IBasicAsset, assets: TBasicAssetArray) => {
                (<IContentBasicAsset>newAsset).botContentType = content;
                this.botEventsHandler.setEvent(<IBotEvent>newAsset);
                const preOrPos = targetAsset[content];
                this[preOrPos].assets = this.assetAdderHandlerAssetsContent(content);

                this.updateMenuPreview();

                return Promise.resolve(true);
            },
            onChangeAssetsPositions: (assets: TBasicAssetArray): void => {
                this.removeEventsFromEvents(assets);
                this.insertEventsOnEvents(assets);
            },
            variables: this.getAssetAdderVariables(),
        }
    }

    updateMenuPreview() {
        if (this.entity.botLevel === ENextGenBotElementType.menuContainer) {
            if (this.entity.events) {
                const preContentEvents = (this.entity.events as TContentAssetArray).filter(event => event.botContentType === EBotContentEvent.preContent);

                this.menuPreviewSvc.updateMenuPreview({ menuPreContent: preContentEvents.length > 0 ? preContentEvents : undefined })

            }
        }
    }

    public getAssetAdderVariables() {
        return {
            [EVarEditorEntityType.NonSerializable]: this.selectedVariableTags.map(variable => ({ idProperty: variable.idNS, variable: undefined })),
        };
    }

    public removeEventsFromEvents(removeAssets: TBasicAssetArray): void {
        const mapEventToKeep: Map<IBasicAsset, boolean> = new Map();
        const events: TBasicAssetArray = this.entity.events;

        for (const removeAsset of removeAssets) {
            if (!mapEventToKeep.has(removeAsset)) {
                mapEventToKeep.set(removeAsset, false);
            }
        }

        for (const event of events) {
            if (!mapEventToKeep.has(event)) {
                mapEventToKeep.set(event, true);
            }
        }

        events.splice(0);
        events.push(...[...mapEventToKeep.keys()].filter((event: IBasicAsset) => mapEventToKeep.get(event)));
    }

    public insertEventsOnEvents(assets: TBasicAssetArray): void {
        const events: TBasicAssetArray = this.entity.events;
        events.push(...assets);
    }

    public initDynamicHandler(
        title: EGraphElementInfoBotHandlerTabs,
        handler: {},
        component: {},
        inputs: object = {}) {
        this.handlers.splice(this.botTabsMap.indexOf(title), 0, {
            title,
            expanded: this.isSelectedTab(title),
            handler: DynamicComponentHandler.factory({
                component,
                handler: handler as MainHandler,
                inputs,
            }),
        });
    }

    public isSelectedTab(tab: EGraphElementInfoBotHandlerTabs): boolean {
        return tab === this.parameters.tab;
    }

    public getNSs = this.lookupSvc.createNSCacheImplementation();


    public get variableTags(): string[] {
        if (isBotRoot(this.entity)) {
            if (isInvalid(this.entity.variableTags))
                this.entity.variableTags = []
                    ;
            return this.entity.variableTags;
        }
    }

    public async initBotRootHandlers(): Promise<void> {
        this.initVariableTagHandler();

        if (isBotRoot(this.entity)) {
            if (isInvalid((this.entity as IBotRootServer).canonicals))
                this.entity.canonicals = isValidArray(this.entity.canonicalIds) ? this.entity.canonicalIds.map(idCanonical => ({ idCanonical, isSafe: false })) : []
                    ;

            this.canonicalPickerHandler = new CanonicalPickerHandler({
                canonicalIds: (this.entity as IBotRootServer).canonicals.map(canonical => canonical.idCanonical),
                canonicalsConfig: (this.entity as IBotRootServer).canonicals,
                enableCanonicalsConfig: true,
                clientCallback: <ICanonicalPickerHandlerClientCallback>{
                    mapCanonicalToVariable: (ns: IServerLocalCanonical): IVariable => this.mapCanonicalEntity(ns),
                },
                enableSyncWithCanonicalsService: true,
            });

            this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.canonicals, this.canonicalPickerHandler, CanonicalPickerComponent);
        }

    }


    private async initVariableTagHandler(): Promise<void> {
        if (isBotRoot(this.entity)) {
            const bot: IBotRootServer = this.entity;
            this.selectedVariableTags.splice(0);
            this.selectedVariableTags.push(...(await this.getNSs(this.variableTags) as IServerColmeiaTag[]));

            this.variableTagsHandler = {
                selectedVariableTags: this.selectedVariableTags,
                onUpdateSelectedVariables: () => {
                    bot.variableTags = this.selectedVariableTags.map(tag => tag.idNS);
                },
                canCopyVariable: true,
                syncWithVariablesService: true
            }
            this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.variables, this.variableTagsHandler, VariableTagsComponent, undefined);
        }
    }

    public initAssetHandlers(): void {
        this.assetAdderHandler = this.getAssetAdderHandler();
        this.preContentHandler = this.getAssetContentHandler(EBotContentEvent.preContent);
        this.posContentHandler = this.getAssetContentHandler(EBotContentEvent.posContent);
        this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.preContent, this.preContentHandler, AssetAdderComponent, { schemaVariables: [] });
        this.initDynamicHandler(EGraphElementInfoBotHandlerTabs.posContent, this.posContentHandler, AssetAdderComponent, { schemaVariables: [] });
    }

    private get assetAdderHandlerAssets(): TBasicAssetArray {
        return this.entity.events.filter(asset => this.enabledAssetsToAssetHandler.includes(asset.type));
    }


    private getAssetAdderHandler(): IAssetAdderHandler {
        return {
            assets: this.assetAdderHandlerAssets,
            assetTypesEnabled: this.enabledAssetsToAssetHandler,
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                const asset: IBasicAsset = assets[index];
                this.botEventsHandler.removeEvent(<IBotEvent>asset);
                this.assetAdderHandler.assets = this.assetAdderHandlerAssets;
                return Promise.resolve(true);
            },
            saveAsset: (newAsset: IBasicAsset, assets: TBasicAssetArray) => {
                this.botEventsHandler.setEvent(<IBotEvent>newAsset);
                this.assetAdderHandler.assets = this.assetAdderHandlerAssets;
                return Promise.resolve(true);
            },
            variables: this.getAssetAdderVariables(),
        }
    }
}
