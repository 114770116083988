import {ServerCommunicationService} from "./server-communication.service";
import {RequestBuilderServices} from "./request-builder.services";
import {GlobalWarningService} from "./global-warning.service";
import {ScreenSpinnerService} from "./screen-spinner.service";
import {Injectable} from "@angular/core";
import {IRequest} from "@colmeia/core/src/request-interfaces/request-interfaces";
import {ClientInfraResponse, IInfraParameters} from "../model/client-infra-comm";

@Injectable({
    providedIn: 'root'
})
export abstract class AbstractHttpService {

    constructor(
        protected http: ServerCommunicationService,
        protected rbs: RequestBuilderServices,
        protected warning: GlobalWarningService,
        protected spinner: ScreenSpinnerService
    ) {}

    protected basicRequestForSN(apiRequestType: string): IRequest {
        return this.rbs.secureBasicRequestForCurrentSN(apiRequestType);
    }

    protected basicRequest(apiRequestType: string): IRequest {
        return this.rbs.secureBasicRequest(apiRequestType);
    }

    protected async request(infra: IInfraParameters, req: IRequest): Promise<ClientInfraResponse> {
        return this.http.managedRequest(infra, req);
    }

}
