import { Component, OnInit, Input } from "@angular/core";
import {
    VarEditorHandler,
    IVarEditorHandlerParameter,
    IButtonEditorInstance,
} from "../../../handlers/var-editor.handler";
import { ColmeiaDialogService } from "../../../services/dialog/dialog.service";
import { VarEditorComponent } from "../var-editor/var-editor.component";
import { ColmeiaDialogComponent } from "../../dialogs/dialog/dialog.component";
import { MatDialogRef } from "@angular/material/dialog";
import { ThemePalette } from "@angular/material/core";

@Component({
    selector: "app-var-editor-btn",
    templateUrl: "./var-editor-btn.component.html",
    styleUrls: ["./var-editor-btn.component.scss"],
})
export class VarEditorBtnComponent implements OnInit, IButtonEditorInstance {
    @Input()
    public color: ThemePalette = "primary";
    private _handler: VarEditorHandler;

    @Input()
    set handler(val: VarEditorHandler) {
        this._handler = val;
        if (this._handler) {
            this._handler.setButtonInstance(this);
        }
    }

    get handler(): VarEditorHandler {
        return this._handler;
    }

    @Input() canClick: boolean = true;

    private dialogRef: MatDialogRef<ColmeiaDialogComponent<{}>, any>;

    constructor(private colmeiaDialogSvc: ColmeiaDialogService) {}

    ngOnInit() {}

    forceOpenDialog() {
        this.openDialog();
    }

    openDialog() {
        const newDialogRef = this.colmeiaDialogSvc.open<
            VarEditorComponent,
            VarEditorHandler
        >({
            componentRef: VarEditorComponent,
            hideHeader: true,
            dataToComponent: {
                data: this.handler,
            },
            panelClass: "average-size"
        });

        this.dialogRef = newDialogRef;
    }
}
