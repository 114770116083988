import hotkeys from 'hotkeys-js';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DevService {

    private _enabled$: Subject<boolean> = new Subject();
    public enabled$(): Observable<boolean> { return this._enabled$.asObservable(); }
    private isEnabled: boolean = false;
    public get enabled(): boolean { return this.isEnabled }

    constructor() {
        this.start();
    }

    private start() {
        hotkeys("alt+shift+d", this.toggleState);
    }

    private toggleState = () => {
        this.isEnabled = !this.isEnabled;

        this._enabled$.next(this.isEnabled);
    }
}
