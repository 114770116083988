import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { AnimationsFramesGroup, IPoint, moveEventsOf, SubscriptionGroup } from 'app/model/client-utility';
import { delay, take, tap } from 'rxjs/operators';
import { ColmeiaWindowRef } from '../colmeia-window/colmeia-window-ref';
import { ColmeiaWindowService } from '../colmeia-window/colmeia-window.service';
import { HardwareLayerService } from 'app/services/hardware';

@Component({
    selector: 'app-colmeia-window-bar',
    templateUrl: './colmeia-window-bar.component.html',
    styleUrls: ['./colmeia-window-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.has-items]": "hasItems",
        "[class.has-visible-items]": "hasVisibleItems",
        "[class.is-all-minimized]": "isAllMinimized",
    }
})
export class ColmeiaWindowBarComponent implements OnInit, AfterViewInit, OnDestroy {

    private subscriptions = new SubscriptionGroup();
    public hasItems: boolean = false;
    public hasVisibleItems: boolean = false;
    public isAllMinimized: boolean = true;

    public groupsHash: Record<string, ColmeiaWindowRef[]> = {};
    public orphansNotLikeWayne: ColmeiaWindowRef[] = [];

    public isItemsVisible: boolean = false;
    public isMoving: boolean = false;

    private lastPointerTranslate: IPoint  = { x: 0, y: 0 };
    private animationsFrames: AnimationsFramesGroup = new AnimationsFramesGroup();
    public lastButtonYTranslate: number = 0;
    public isAboveWindowHalf: boolean = false;

    @ViewChild('fabButton', { read: ElementRef })
    fabButton: ElementRef<HTMLButtonElement>;

    @ViewChild('wrapper')
    wrapperRef: ElementRef<HTMLElement>;

    @ViewChild('container')
    containerRef: ElementRef<HTMLElement>;

    constructor(
        private cdr: ChangeDetectorRef,
        public windowSvc: ColmeiaWindowService,
        private hw: HardwareLayerService
    ) {
    }

    ngOnInit(): void {
        this.windowSvc.activeWindows$()
        .pipe(
            tap((windows) => {
                this.hasItems = !!windows.length;
                this.hasVisibleItems = !this.windowSvc.isAllMinimized();
                this.cdr.markForCheck();
            }),
            delay(10)
        ).subscribe((windowsRefArray) => {
            const oldQuantity = this.getGroupsHashWindowsLength();
            this.groupsHash = {};

            windowsRefArray
            .filter( ref => isValidString(ref.group))
            .forEach( ref => {
                if(!this.groupsHash[ref.group]) {
                    this.groupsHash[ref.group] = [];
                }

                this.groupsHash[ref.group].push(ref);
            });

            this.orphansNotLikeWayne = windowsRefArray.filter( ref => !isValidString(ref.group));

            if (this.getGroupsHashWindowsLength() > oldQuantity) {
                // has added a new window
                this.isItemsVisible = false;
            }
        });

        this.subscriptions.from(
            this.windowSvc.anyUpdate$()
        ).subscribe(() => {
            const hasItems: boolean = !!this.windowSvc.activeWindows.length;
            this.isAllMinimized = hasItems && this.windowSvc.isAllMinimized();
            this.hasVisibleItems = hasItems && !this.windowSvc.isAllMinimized();

            if (this.isAllMinimized) {
                this.hideItems();
            }
            this.cdr.markForCheck();
        });
    }

    private getGroupsHashWindowsLength(): number {
        return Object.values(this.groupsHash).reduce((length, group) => {
            return length + group.length;
        }, 0);
    }

    ngAfterViewInit(): void {
        const $button: HTMLButtonElement = this.fabButton.nativeElement;
        const $container: HTMLElement = this.containerRef.nativeElement;
        const topThreshold: number = (window.innerHeight * -1) + 78 + $button.getBoundingClientRect().height;
        const windowHeightHalf = window.innerHeight / 2;
        const resetState = () => {
            this.isMoving = false;
            this.showItems();
            this.cdr.markForCheck();
        }

        moveEventsOf($button, {
            start: () => {
                this.lastPointerTranslate = { y: 0, x: 0 };
                this.isMoving = true;
                this.hideItems();
                this.cdr.markForCheck();
            },
            move: async (_event: PointerEvent, pointerTranslate: IPoint) => {
                this.lastPointerTranslate = pointerTranslate;

                if(Math.abs(this.lastPointerTranslate.y) < 10) {
                    return;
                }

                this.animationsFrames.run(0, () => {
                    const { y } = this.lastPointerTranslate;
                    $button.style.transform = `translateY(${y}px)`;
                });

            },
            end: async (_event) => {
                if(Math.abs(this.lastPointerTranslate.y) < 10) {
                    resetState();
                    return;
                }

                this.animationsFrames.run(1, () => {
                    this.lastButtonYTranslate = this.lastButtonYTranslate + this.lastPointerTranslate.y;

                    this.lastButtonYTranslate = this.lastButtonYTranslate < 0 ? this.lastButtonYTranslate : 0;
                    this.lastButtonYTranslate = this.lastButtonYTranslate < topThreshold ? topThreshold : this.lastButtonYTranslate;

                    this.isAboveWindowHalf = Math.abs(this.lastButtonYTranslate) > windowHeightHalf

                    $container.style.top = `${this.lastButtonYTranslate}px`;
                    $button.style.transform = `translateY(0)`;

                    resetState();
                });

            }
        })
    }

    ngOnDestroy() {
        this.subscriptions.destroy();
    }

    showItems() {
        if(this.isMoving || this.hw.isMobile()) return;

        this.isItemsVisible = true;
        this.cdr.markForCheck();
    }

    hideItems() {
        if(this.isMoving || this.hw.isMobile()) return;

        this.isItemsVisible = false;
        this.cdr.markForCheck();
    }

    toggleItems() {
        if (this.isMoving || !this.hw.isMobile()) return;

        this.isItemsVisible = !this.isItemsVisible;
        this.cdr.markForCheck();
    }

    closeGroup(group: string) {
        this.windowSvc.activeWindows.filter( ref => ref.group === group ).forEach( ref => {
            ref.close();
        });
    }

    closeWindow($event: PointerEvent, windowRef: ColmeiaWindowRef) {
        $event.stopPropagation();
        windowRef.afterRestore().pipe(take(1)).subscribe(() => {
            windowRef.close();
        });

        windowRef.restore();
    }

    public goTopOrMinimize(windowRef: ColmeiaWindowRef) {
        windowRef.goTopOrMinimize();
    }

    public minimizeAll() {
        this.windowSvc.isAllMinimized() ? this.windowSvc.maxmizeAll() : this.windowSvc.minimizeAll();
    }
}
