import { IPhoneEvent } from './cordova-events';
import {SignalPublisherService} from "../../signal/signal-publisher";


export class CordovaPhoneEvents {
    public static boot(events: Array<IPhoneEvent>, emiter: SignalPublisherService) {
        events.forEach(
            desiredEvent => {
                console.log("BOOTANDO EVENTOS!")
                document.addEventListener(desiredEvent.event, (_receivedEvent) => {

                    if (desiredEvent.preventDefault) {
                        _receivedEvent.preventDefault();
                    }

                    emiter.specificSignalEmissorOnGenericStream(new desiredEvent.signal(_receivedEvent));

                }, false);
            }
        );
    }
}
