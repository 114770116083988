<div class="active__call__selected__campaign__container">
  <div class="active__call__selected__campaign__title">{{ selectedCampaign.actionName }}</div>
  <div class="active__call__selected__campaign__description">Lorem ipsum, dolor sit amet</div>
</div>

<div class="active__call__selected__campaign__text">Lorem ipsum, dolor sit amet consectetur adipisicing elit.
  Doloremque tenetur eveniet molestiae. Iure numquam quia magni deleniti ad laboriosam debitis. Libero laudantium
  illum beatae iste recusandae corporis! Optio, dolorum sapiente.
</div>

<br>
<br>
<br>

<mat-divider></mat-divider>

<br>
<br>
<br>

<div class="active__call__selected__campaign__title">Variáveis</div>
<br>

<div class="active__call__selected__campaign__search">
  <mat-form-field appearance="outline">
    <mat-label>Data</mat-label>
    <input matInput>
  </mat-form-field>
</div>
