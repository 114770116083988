import { Subject } from 'rxjs';
import {IGroupJSON} from "@colmeia/core/src/comm-interfaces/business-interfaces";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {Serializable} from "@colmeia/core/src/business/serializable";

// Use it in footer component
export interface ISocialNetwork extends IGroupJSON {
  name: string;
  photoURL: string;
}

// Use it in group header
export interface IBreadcrumbingRoute {
  name: string;
  link: string;
};

export enum EMsgDialogType {
    None,
    Error,
    Warning,
    Success
};


export interface INodeFileContainer {
	nodeId: TGlobalUID;
	fileList: FileList;
};

// interface to represent an item inside dropdown-menu
export interface IDropdownMenuItem {
  title: string;
  pageId?: string;
  item?: Serializable;
  itemEventHandler: Subject<IDropdownMenuItem>;
  submenu?: Array<Object>;
};

export type TIDropdownMenuItemArray = Array<IDropdownMenuItem>;

export enum ModalState {
    open = 1,
    close = 2
};
