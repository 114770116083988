import { secToMS } from '@colmeia/core/src/time/time-utl';
import { isValidNumber } from '@colmeia/core/src/tools/barrel-tools';
import { EAppAlertTypes, SnackMessageService } from 'app/services/snack-bar';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { MultiMediaType } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { ClientCachedFile } from '@colmeia/core/src/multi-media/client-cached';
import { TSelectedFileArray, FILE_EXTENSION_TO_MIMETYPE } from '@colmeia/core/src/multi-media/file-interfaces';
import { SelectedFile } from '@colmeia/core/src/multi-media/selected-file';
import {IMultimediaClientParameter, MultimediaHandler} from "../../../handlers/multimedia-handler";
import {MultimediaService} from "../../../services/multimedia.service";
import {HardwareLayerService} from "../../../services/hardware";
import {clientConstants} from "../../../model/constants/client.constants";
import { getFileExtension, isValidArray, prettyBytes } from '@colmeia/core/src/tools/utility';
import { OnChange } from 'app/model/client-utility';
import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { mbToBytes } from '@colmeia/core/src/rules/mm-functions';
import { StickerService } from 'app/services/sticker.service';


@Component({
    selector: 'app-multimedia-instance-uploader',
    templateUrl: './multimedia-instance-uploader.component.html',
    styleUrls: ['./multimedia-instance-uploader.component.scss']
})
export class MultimediaInstanceUploaderComponent implements OnInit {
    
    @OnChange()
    @Input()
    multimediaHandler?: MultimediaHandler;

    onChangeMultimediaHandler() {
        this.multimediaHandler!.setSlave(this);
        this.initAcceptedTypes();
    }

    @ViewChild('mmFileInput', {static: true}) mmFileInput: ElementRef;

    public acceptedTypesArray: string[];
    public acceptedTypes: string;

    constructor (
        private multimediaService: MultimediaService,
        private hw: HardwareLayerService,
        private snackSvc: SnackMessageService,
        private stickerSvc: StickerService
    ) { }

    ngOnInit() {
        
        // listens for upload progress events and emits it to parent components
    }

    initAcceptedTypes() {
        this.acceptedTypesArray = this.multimediaHandler!.getAcceptedFileMimes();
        this.acceptedTypes = MultimediaService.getInputAcceptedFileTypes(this.acceptedTypesArray);
    }

    onFileUploadClicked() {
        this.mmFileInput.nativeElement.click();
    }

    /**
     * Clear user selected files
     * @returns void
     */
    public clearSelectedFiles(): void {
        if (this.mmFileInput &&
            this.mmFileInput.nativeElement.value &&
            this.mmFileInput.nativeElement.value.length) {
            this.mmFileInput.nativeElement.value = '';
        };
        if(this.multimediaHandler){
            this.multimediaHandler.clearFiles();
        }
    }

    /**
     * Clears files when user clicks on uploader
     * @returns void
     */
    public onClick(): void {
        this.clearSelectedFiles();
    };

    /**
     * When user added the file we save it in local attribute for posterior upload
     * @param {[type]} event [description]
     */
    public async onFileChanged(event): Promise<void> {
        this.handleFileChange(event.target.files);
    }
    public async handleFileChange(files: Array<File>): Promise<void> {
        files = [...files];
        // checks for empty fileList
        console.log('onFileChanged: ', files);
        if (files.length === 0)
            return;

        files = await this.filterDeniedFiles(files);

        // sets fileList attribute to be used in uploadFiles
        let count: number = 0;
        const fileSelectionArray: TSelectedFileArray = [];

        while (count < this.multimediaHandler.getComponentParameter().maxNumberOfFiles && count < files.length) {
            let thumbnail: Blob;
            const file: File = files[count];
            const mmType: MultiMediaType = this.getBestMimeType(file.name, file.type);
            const isThumbnailable: boolean = mmType.isThumbnailable();
            const idTag: TGlobalUID = this.multimediaHandler.getComponentParameter().idMultimediaTag;

            if (isThumbnailable) {
                thumbnail = await this.generateThumbnail(file);
            };

            const cachedFile: ClientCachedFile = this.multimediaService.getSetFileInfo(file, file.name, thumbnail);
            if (this.multimediaHandler.getComponentParameter().generateHashNow) {
                await this.multimediaService.processFileInfo(cachedFile);
            };

            const fileInfo: SelectedFile = SelectedFile.newClientFileInfo(
                cachedFile,
                {
                    idMediaTag: idTag,
                    currentName: file.name
                });

            fileSelectionArray.push(fileInfo);
            ++count;
        };
        this.multimediaHandler.onFileSelection(fileSelectionArray);
    }

    private async filterDeniedFiles(files: File[]): Promise<File[]> {
        let deniedFilesByType: File[] = [];
        let deniedFilesBySize: File[] = [];

        // [files, deniedFilesByType] = this.filterByTypes(files);
        [files, deniedFilesBySize] = this.filterBySize(files);

        if(isValidArray(deniedFilesByType) || isValidArray(deniedFilesBySize)) {
            this.showUnsuportedFileTypes(deniedFilesByType, deniedFilesBySize);
        }

        if (this.isUploadingStickers() && !(await this.validateStickers(files))) {
            return [];
        }

        return files;
    }

    private isUploadingStickers(): boolean {
        // detecta se está fazendo upload de stickers pelo filtro de mime type
        return this.acceptedTypesArray.includes(EMimeTypes.Sticker);
    }

    private async validateStickers(files: File[]): Promise<boolean> {
        let error;

        for (const file of files) {
            if (file.type !== EMimeTypes.Webp) {
                error = "A figurinha precisa ser um arquivo de imagem do formato WebP.";
                break;
            }

            if (await this.stickerSvc.isWebPAnimated(file)) {
                if (file.size > mbToBytes(0.5)) {
                    error = "A figurinha animada deve ter menos de 500 KB.";
                    break;
                }
            } else {
                if (file.size > mbToBytes(0.1)) {
                    error = "A figurinha deve ter menos de 100 KB.";
                    break;
                }
            }

            const [width, height] = await this.stickerSvc.getImageSize(file);

            if (width !== 512 || height !== 512) {
                error = "As dimensões da imagem da figurinha devem ser exatamente 512x512 pixels.";
                break;
            }
        }

        if (!error) {
            return true;
        }

        this.snackSvc.open({
            message: error,
            duration: secToMS(16),
            action: "Fechar",
            type: EAppAlertTypes.Warning,
            options: {
                panelClass: "denied-files-upload"
            }
        });

        return false;
    }

    private filterByTypes(files: File[], ): [File[], File[]] {
        if(!isValidArray(this.acceptedTypesArray)) return [files, []];

        const deniedFilesByType: File[] = [];
        if(isValidArray(this.acceptedTypesArray)) {
            files = files.filter((file) => {
                const allowed: boolean = this.acceptedTypes.includes(file.type) || this.acceptedTypes.includes(getFileExtension(file.name));
                if(!allowed) deniedFilesByType.push(file);

                return allowed;
            });
        }

        return [files, deniedFilesByType];
    }

    private filterBySize(files: File[], ): [File[], File[]] {
        const parameters: IMultimediaClientParameter = this.multimediaHandler.getComponentParameter();
        if(!isValidNumber(parameters.maxFileBytesSize)) return [files, []];

        const deniedFilesBySize: File[] = [];
        files = files.filter((file) => {
            const allowed: boolean = file.size <= parameters.maxFileBytesSize;
            if(!allowed) deniedFilesBySize.push(file);

            return allowed;
        });
        return [files, deniedFilesBySize];
    }

    private showUnsuportedFileTypes(deniedFilesByType: File[], deniedFilesBySize: File[]) {
        const { maxFileBytesSize } = this.multimediaHandler.getComponentParameter();

        this.snackSvc.open({
            message:
                `Alguns arquivos não foram permitidos:\n\n` +
                (deniedFilesByType.length
                    ? `Formato não permitido:\n` + `${deniedFilesByType.map( file => ` • ${file.name} – ${file.type}\n`)}\n`
                    : '') +
                (deniedFilesBySize.length
                    ? (
                        `Excedeu o tamanho permitido:\n` + `${deniedFilesBySize.map( file => ` • ${file.name} – ${prettyBytes(file.size, { binary: true })}\n`)}\n` +
                        `Tamanho máximo permitido: ${prettyBytes(maxFileBytesSize, { binary: true })}`
                    )
                    : ''),
            duration: secToMS(16),
            action: "Fechar",
            type: EAppAlertTypes.Warning,
            options: {
                panelClass: "denied-files-upload"
            }
        });
    }

    private getBestMimeType(fileName: string, type: string): MultiMediaType {
        const isMimeTypeExists = type != '';
        if(isMimeTypeExists) {
            return MultiMediaType.getMutimediaTypeFromMime(type)
        }
        const parts: Array<string> = fileName.split('.')
        const extension: string = parts[parts.length - 1]
        const mimeType: string | undefined = FILE_EXTENSION_TO_MIMETYPE[extension]
        return MultiMediaType.getMutimediaTypeFromMime(mimeType)
    }

    private async generateThumbnail(file: File): Promise<Blob> {
        // creating canvas
        const canvas: HTMLCanvasElement = document.createElement('canvas');

        // checking media type
        const type = file.type.match('image') ? 'img' : 'video';
        const media: HTMLImageElement | HTMLVideoElement = document.createElement(type);
        await this.forMediaToLoad(media, file);

        // store image in canvas
        this.putResizedMediaIntoCanvas(canvas, media);

        // waits for blob
        const thumbnailBlob: Blob = await this.forBlob(canvas);
        return thumbnailBlob;

        // TESTING
        // if (thumbnailBlob) {
        //     const url = URL.createObjectURL(thumbnailBlob);
        //     thumbnail.src = url;
        //     this.thumbnailUrl = url;
        //     // await this.forMediaToLoad(thumbnail);
        // }
    }

    private putResizedMediaIntoCanvas(canvas: HTMLCanvasElement, media: HTMLImageElement | HTMLVideoElement): void {
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        // resize media
        const [width, height] = this.resizeMedia(media);

        // draw image on canvas
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(media, 0, 0, width, height);
    }

    private resizeMedia(media: HTMLImageElement | HTMLVideoElement): Array<number> {
        // resizes image keeping ratio
        const MAX_WIDTH = clientConstants.multimedia.thumbnail.maxWidth;
        const MAX_HEIGHT = clientConstants.multimedia.thumbnail.maxHeight;
        let width = (media instanceof HTMLImageElement) ? media.width : media.videoWidth;
        let height = (media instanceof HTMLImageElement) ? media.height : media.videoHeight;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }

        return [width, height];
    }


    private forMediaToLoad(media: HTMLImageElement | HTMLVideoElement, file: File): Promise<boolean> {
        return new Promise<boolean>((res) => {
            if (media instanceof HTMLImageElement) {
                media.onload = () => {
                    return res(true);
                };
            } else {
                // is video
                media.addEventListener('loadeddata', () => {
                    return res(true);
                }, true);
                media.preload = 'metadata';
                media.load();
            }
            media.src = window.URL.createObjectURL(file);
        });
    }

    private forBlob(canvas: HTMLCanvasElement): Promise<Blob> {
        return new Promise((res) => {
            canvas.toBlob((blob: Blob) => res(blob));
        });
    }

    // DONOTREMOVE: made just for testing
    // private thumbnailUrl: string = '';
    // public getThumbnailUrl(): string | SafeUrl {
    //     return this.sanitize(this.thumbnailUrl);
    // }
    // public sanitize(url: string): SafeUrl {
    //     return this.sanitizer.bypassSecurityTrustUrl(url);
    // }
}
