import { generateNormalizedPWD, isValidString, TArrayIElementPWD } from "../../tools/utility";

export interface ICreateDocumentValidator {
    multiply: (char: string, index: number, chars: string[]) => number;
    reverse: boolean;
    specialCases: string[];
    amountDigits: number | number[];
}


export function createDocumentValidator(parameters: ICreateDocumentValidator) {
    return (value: string) => {
        value = sanitize(value);
        parameters.amountDigits = [parameters.amountDigits].flat();
        
        const isValidDigits = parameters.amountDigits.some(amountDigit =>
            isValidString(value, amountDigit) && (value.length === amountDigit)    
        );

        return (
            ! parameters.specialCases.includes(value)
            && isValidDigits
            && [-2, -1].every((until) => compute(value, until, parameters.multiply, parameters.reverse).toString() === value.slice(until)[0])
        );
    };
}

export function generateNormalizedPassword(elements: TArrayIElementPWD, validSpecials?: string): string {
    return generateNormalizedPWD(elements, validSpecials);
}


export function isValidCPFOrCNPJ(doc: string): boolean {
    return isValidCPF(doc) || isValidCNPJ(doc)
}

export const isValidCNPJ: (cnpj: string) => boolean = createDocumentValidator({
    multiply: (char, index, chars) => index % 8 + 2,
    reverse: true,
    specialCases: [
        "00000000000000",
    ],
    amountDigits: 14,
});

export const isValidCPF: (cpf: string) => boolean = createDocumentValidator({
    multiply: (char, index, chars) => ((chars.length + 1) - index),
    reverse: false,
    specialCases: [
        '00000000000',
        "11111111111",
        "22222222222",
        "33333333333",
        "44444444444",
        "55555555555",
        "66666666666",
        "77777777777",
        "88888888888",
        "99999999999",
    ],
    amountDigits: [11],
});


function sanitize(value: string): string {
    return value.replace(/\.|-|\//g, '');
}

function compute(digits: string, until: number, multiply: (char: string, index: number, chars: string[]) => number, reverse: boolean): number {
    const sum: number = digits.split('')
        .slice(0, until)
        .sort(() => reverse ? -1 : 0)
        .map((char: string, index: number, chars: string[]) => Number(char) * (multiply(char, index, chars)))
        .reduce((sum, digit) => sum + digit, 0)
    ;
    const result: number = sum * 10 % 11;
    const equals: number[] = [10, 11];
    return equals.some(number => result === number) ? 0 : result;
}
