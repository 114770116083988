<ng-container *ngIf="!loading">
  <ng-container *ngIf="handler">
    <app-colmeia-window-top-bar *ngIf="useColmeiaWindowSvc"></app-colmeia-window-top-bar>

    <section class="tag-picker">
      <mat-dialog-content style="padding-top: 6px;">
        <div class="modal-top">
          <div class="modal-top--line">
            <span>
              {{ nsTypeName }}
            </span>
          </div>
        </div>

        <app-non-serializable-taggable *ngIf="tagPickerHandler" [tagPickerHandler]="tagPickerHandler">
        </app-non-serializable-taggable>

        <div *ngIf="hasUseSelectProperty()">
          <mat-form-field appearance="fill" class="full-width-field">
            <mat-label>{{getSelectPropertyLabel}}</mat-label>
            <mat-select
              (selectionChange)="selectedPropertyChange($event)"
              [ngModel]="selectedProperty"
            >
              <mat-option
                *ngFor="let option of getSelectionOptions"
                [value]="option.propertyTypeValue"
              >
                {{ option.translation }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-ns-picker-modal-selectables *ngIf="nsPickerSelectablesHandler" [handler]="nsPickerSelectablesHandler" [nsPickerHandler]="handler">
        </app-ns-picker-modal-selectables>

        <div class="modal-section">
          <ng-container *ngIf="nsPickerSelectionsHandler">
            <app-ns-picker-selections [handler]="nsPickerSelectionsHandler" [nsPickerHandler]="handler"></app-ns-picker-selections>
          </ng-container>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="mat-dialog-actions" align="end">
        <button *ngIf="handler.allowCreateButton" mat-flat-button color="accent" (click)="createNs()" class="create-ns-btn">Criar</button>
        <button mat-button (click)="cancel()">{{ translations.cancel }}</button>
        <!-- Bloqueando apenas em seleção única para previnir problemas em caso de listas que podem ser vazias -->
        <button mat-flat-button [disabled]="maxSelections === 1 && !nonSerializables.length" color="primary" (click)="save()">{{ translations.add }}</button>
      </mat-dialog-actions>

    </section>

  </ng-container>


</ng-container>

<ng-template #spinner>
  <app-dashboard-spinner></app-dashboard-spinner>
</ng-template>
