import {Participant, constant} from '../../business/barrel-business';
import {Interaction} from '../interaction';
import {ChainedInteraction} from '../chained-interaction';
import { Feedback  } from '../feedback';
import { FeedbackInteraction } from './feedback-interaction';
import {InteractionType} from '../interaction-type';
import { TGlobalUID } from '../../business/constant';


export class Message extends Interaction {
    private constructor(primaryID: TGlobalUID, participant: Participant, message: string) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.standard.message));
        this.setMessage(message);
    }

    public static getNewMessage(participant: Participant, message: string) {
        return new Message(null, participant, message);
    };


}

export class Citation extends ChainedInteraction {
    private constructor(primaryID: TGlobalUID, participant: Participant, interactionParent: Interaction,  message: string) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.standard.citation), 
                                          interactionParent);
        this.setMessage(message);
    }


    public static getNewCitation(participant: Participant, interactionParent: Interaction, message: string) {
        return new Citation(null, participant, interactionParent, message);
    };
};



export class VoteInteraction extends FeedbackInteraction {
    protected constructor(primaryID: TGlobalUID, participant: Participant, interactionParent: Interaction, feedback: Feedback, feedbacktype: TGlobalUID = constant.interactionType.feedback.vote) {
        super(primaryID, participant, InteractionType.staticFactory(feedbacktype), interactionParent, feedback.getFeedbackID());
    };


    public static getNewVote(participant: Participant, interactionParent: Interaction, feedback: Feedback) {
        let interaction: VoteInteraction = new VoteInteraction(null, participant, interactionParent, feedback);
        return interaction;
    };


};




