import { Injectable } from '@angular/core';
import { isValidString } from '@colmeia/core/src/tools/barrel-tools';

@Injectable()
export class HighlightMatchingStringCharacters {        
    
    // CSS Sample
    // .highlighted-text {
    //     background-color: yellow; 
    // }

    // HTML Sample
    // <h5 class="card-title"
    //     [innerHTML]="getCardTitle()">       
    // </h5>
    
    public getHighlightedString(originalString: string, textToBeHighlighted: string | undefined): string {
        if (isValidString(textToBeHighlighted)) {
            const regex = new RegExp(`(${textToBeHighlighted})`, 'gi');
            return originalString.replace(regex, '<span class="highlighted-text">$1</span>');
        } 
        return originalString
    }
}
