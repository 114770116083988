import {constant} from './constant';
import {Serializable} from './serializable';
import {Player} from './player';
import {Participant} from './participant';
import {ETypeOfContact, IAvatarJSON, ISecuritySettingsJSON, TCheckedContactArray} from '../comm-interfaces/business-interfaces';
import {UberCache, newGlobalUID} from '../persistency/uber-cache';
import {TGlobalUID} from './constant';
import { addDefaultToSecuritySettings } from '../rules/filters';
import { SecuritySettings } from '../security/security-settings';
import { ENonSerializableObjectType } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '../shared-business-rules/non-serializable-id/non-serializable-types';

export const playerRelated: string = constant.avatarRelatedTo.player;
export const groupRelated: string = constant.avatarRelatedTo.group;
export type TAvatarArray = Array<Avatar>;

export class Avatar extends Serializable {

    
    private  player: Player;
    private  original: boolean = false;
    private  relatedTo: string;
    private  idParentEntity: TGlobalUID;
    private  privacyOptions: SecuritySettings;
    private  idSecuritySettings: TGlobalUID;
    private  participantOnPersonalGroup: Participant;
    private  idPersonalGroup: TGlobalUID;
    private  idProjection: TGlobalUID;
    private  contacts: TCheckedContactArray;
    private isAnonymouAvatar: boolean;
    private userProfilesList?: IdDep<ENonSerializableObjectType.userApprovalProfile>[];


    private constructor(play: Player, idAvatar: TGlobalUID, nickName: string, relatedTo: string) {
        super(constant.objectType.avatar, idAvatar);
        this.player = play;
        this.relatedTo = relatedTo;
        if (play)
            this.idParentEntity = play.getPlayerID();
        
        super.setName(nickName);
    };


    public toJSON(): IAvatarJSON {
        let json: IAvatarJSON = {...super.toJSON(),
            original: this.original, 
            relatedTo: this.relatedTo,
            idParentEntity: this.getPlayer()? this.getPlayer().getPlayerID(): this.getParentEntityID(),
            securitySettings: this.getSecuritySettingsJSON(),
            idSecuritySettings: this.idSecuritySettings,
            participantOnPersonalGroup: null,
            idPersonalGroup: this.idPersonalGroup,
            idProjection: this.idProjection,
            contacts: this.contacts,
            userProfilesList: this.userProfilesList
        };
        return json;
    };

    private getSecuritySettingsJSON(): ISecuritySettingsJSON {
        return this.privacyOptions? this.privacyOptions.toJSON(): null;
    }

    public isOptionChecked(idSecurityDomain: TGlobalUID): boolean {
        return SecuritySettings.hasDomain(this.getSecuritySettingsJSON(), idSecurityDomain);
    };

    
    public rehydrate(json: IAvatarJSON): void {
        super.rehydrate(json);
        this.original = json.original;
        this.relatedTo = json.relatedTo;
        this.idParentEntity = json.idParentEntity;
        this.idSecuritySettings = json.idSecuritySettings;
        this.idPersonalGroup = json.idPersonalGroup;
        this.idProjection = json.idProjection;
        this.contacts = json.contacts || [];
        this.isAnonymouAvatar = json.isTemporaryUser;
        if (json.securitySettings)
            this.privacyOptions = SecuritySettings.factoryMessage(json.securitySettings);

        if (json.participantOnPersonalGroup) {
            this.participantOnPersonalGroup = Participant.factoryMessage(json.participantOnPersonalGroup);
        };
        this.userProfilesList = json.userProfilesList;
    };

    public getProjectionID(): TGlobalUID {
        return this.idProjection;
    }

    setAnonymous(isAnon: boolean): void {
        this.isAnonymouAvatar = isAnon 
    }

    public replaceSecuritySettings(privacyOptions: SecuritySettings): void {
        this.privacyOptions = privacyOptions;
    };

    public getSecuritySettings(): SecuritySettings {return this.privacyOptions;};


    public getAvatarID(): TGlobalUID {return super.getPrimaryID();};
    public getPlayer(): Player {return this.player;};
    
    public isOriginal(): boolean {return this.original;};
    public setOriginal(original: boolean): void {this.original = original;};
    
    // dados
    public getName(): string {
        if (this.isAnonymouAvatar) {
            return "Anônimo"
        } else {
            return super.getName();
        }
    }
    public getNickName(): string {return super.getName();};
    public setNickName(nickName: string): void {super.setName(nickName);}
    public getSignature(): string {return super.getSerializableText(constant.serializableField.personalData.signature);};
    public setSignature(signature: string): void {this.addSerializableText(constant.serializableField.personalData.signature, signature)};
    public getTelephone(): string {return super.getSerializableText(constant.serializableField.personalData.telephone);};
    public setTelephone(telephone: string): void {
        this.addSerializableText(constant.serializableField.personalData.telephone, telephone);
    };
    public getBirthDate(): string {
        return super.getSerializableText(constant.serializableField.personalData.birthdate);
    };
    public setBirthDate(birthDate: string): void {
        this.addSerializableText(constant.serializableField.personalData.birthdate, birthDate);
    };
    public getCity(): string {
        return super.getSerializableText(constant.serializableField.personalData.city);
    };
    public setCity(city: string): void {
        this.addSerializableText(constant.serializableField.personalData.city, city);
    };
    public getCountry(): string {
        return super.getSerializableText(constant.serializableField.personalData.country);
    };
    public setCountry(country: string): void {
        this.addSerializableText(constant.serializableField.personalData.country, country);
    };
    public getContacts(): TCheckedContactArray {
        return this.contacts
    }
    public getCheckedContacts(): TCheckedContactArray {
        return this.contacts.filter( c => c.isChecked );
    }
    public getContactsTypes(): ETypeOfContact[] {
        return this.getContacts().map( c => c.type );
    }
    public getCheckedContactsTypes(): ETypeOfContact[] {
        return this.getCheckedContacts().map( c => c.type );
    }
    
    public isRelatedTo(relatedTo: string): boolean {return this.relatedTo == relatedTo;};
    public isRelatedToPlayer(): boolean {return this.relatedTo == playerRelated;};
    public isSpokeAvatar(): boolean {return this.relatedTo == groupRelated;};
    
    public getParentEntityID(): TGlobalUID {return this.idParentEntity;};
    public setParentEntityID(idParentEntity: TGlobalUID): void {this.idParentEntity = idParentEntity;};

    public static getNewAvatar(player: Player, nickName: string, relatedTo: string): Avatar {
        const avatar: Avatar = new Avatar(player, newGlobalUID(), nickName, relatedTo);
        const securitySettings: SecuritySettings = new SecuritySettings();
        addDefaultToSecuritySettings(securitySettings);
        avatar.replaceSecuritySettings(securitySettings);
        return avatar;
    };

    public static staticFactory(idAvatar: TGlobalUID) {
        return  <Avatar>UberCache.uberFactory(idAvatar, constant.objectType.avatar, true);
    };

    public static factoryMessage(json: IAvatarJSON): Avatar {
        let avatar: Avatar = <Avatar>UberCache.uberFactory(json.primaryID, constant.objectType.avatar, false);
        let player: Player = null;
        if (avatar == null) {
            // RehydgetGroupInforate Player
            if (json.relatedTo == playerRelated && UberCache.testCache(json.idParentEntity)) {
                player = Player.staticFactory(json.idParentEntity);
            }
            
            avatar = new Avatar(player, json.primaryID, 
                        Avatar.getJText(json, constant.serializableField.name), 
                        json.relatedTo);
        };
        avatar.rehydrate(json);
        return avatar;
    };

    getUserProfileList() {
        return this.userProfilesList;
    }
    setUserProfilesList(list: IdDep<ENonSerializableObjectType.userApprovalProfile>[]) {
        this.userProfilesList = list;
    }

};


