import { IServiceIslandServer, INamedAccountIslandConfig, IPriorityIsland, EPriorizationType, ICustomerReturning, IServiceIsland, IAttEngagementThresholds, TIAttEngagementThresholdsArray } from "./attendance-island";
import { isValidAndEqual, isValidRef, isValidString } from "../../tools/utility";
import { Interaction } from "../../interaction/interaction";
import { getLastUnansweredClientInteraction } from "../../rules/thread-conversation-functions";
import { msToMin, msToSec } from "../../time/time-utl";
import { IInteractionJSON } from "../../comm-interfaces/barrel-comm-interfaces";

export function isExternalCRM(island: IServiceIslandServer): boolean {
    return isValidRef(island.crmIntegration) && isValidString(island.crmIntegration.fieldName);
}

export function isClientValidNamedAccountConfig(namedAccount?: INamedAccountIslandConfig): boolean {
    return isEmptyNamedAccount(namedAccount) || isValidNamedAccountConfig(namedAccount)
}


export function isValidNamedAccountConfig(namedAccount?: INamedAccountIslandConfig): boolean {
    return isValidRef(namedAccount) && isValidRef(namedAccount.whatToDo) && (namedAccount.whatToDo.anyAgent || isValidRef(namedAccount.whatToDo.goToIsland));
}

export function isValidNamedAccountForIsland(island: IServiceIsland): boolean {
    return isValidAndEqual(island?.useNamedAccount, true) && isValidNamedAccountConfig(island.namedAccount)
}


export function isEmptyNamedAccount(namedAccount: INamedAccountIslandConfig): boolean {
    return !namedAccount.applyRuleOnlyIfNotLogged && !namedAccount.contingenceAfterMinutes && (!namedAccount.whatToDo || (!namedAccount.whatToDo.anyAgent && !namedAccount.whatToDo.goToIsland));
}


export function initNamedAccount(): INamedAccountIslandConfig {
    return {
        applyRuleOnlyIfNotLogged: false,
        contingenceAfterMinutes: 0,
        whatToDo: {
            anyAgent: false,
            goToIsland: undefined
        }
    }
}

export function initIslandPriorizationObj(): IPriorityIsland {
    return {
        type: EPriorizationType.prioritize, // Prioritizar, desprioritizar
        gradientMinutes: 0,  // Gradiente (minutos) - (máximo 200)
        percentage: 50,  // 0..100, inteiro  ==>   Utililzar a regra em % das alocações
    }
}

export function initIslandcustomerReturnControl(): ICustomerReturning {
    return {
        prioritizeSameAgentUntilHR: 1,
        applyToAllIsland: false,
        prioritization: initIslandPriorizationObj()
    }
}

export function getDefaultInactivityAlert(untilMinutes: number = 1): IAttEngagementThresholds {
    return {
        color: "#333333",
        untilMinutes,
        sound: "",
    }
}

export interface IOldestRecentInteractionInfo {
    clockTick: number;
    idAvatar: string;
    isMessageSentByHumanAttendant?: boolean; // Quando retornamos a informação do server podemos determinar se a mensagem foi manualmente enviada pelo atendente utilizando o campo pos_generated.
}

export interface IOldestRecentInteractionInfoResponse extends IOldestRecentInteractionInfo {
    idInteractionParent: string;
    idInteraction: string;
}

export function matchInactivityAlertFor(interaction: Interaction | IInteractionJSON | IOldestRecentInteractionInfo, attendantAvatarID: string, inactivityAlertThresholds: TIAttEngagementThresholdsArray): IAttEngagementThresholds {
    if (!(interaction instanceof Interaction)) { // Significa que a interação  chegou via socket ou foi recuperada atraves do OldestRecentInteractionService
        if ((interaction as IOldestRecentInteractionInfo).isMessageSentByHumanAttendant)
            return undefined;

        return matchThresholdForClockTick(interaction.clockTick, inactivityAlertThresholds);
    }

    const lastUnansweredClientInteraction: Interaction = getLastUnansweredClientInteraction(interaction, attendantAvatarID);

    return lastUnansweredClientInteraction
        ? matchThresholdForClockTick(lastUnansweredClientInteraction.getClockTick(), inactivityAlertThresholds)
        : undefined;
}

function matchThresholdForClockTick(clocktick: number, threshholds: TIAttEngagementThresholdsArray) {
    const minWithoutResponse = msToMin(Date.now() - clocktick);
    const iteration = [...threshholds].sort((a, b) => a.untilMinutes - b.untilMinutes).reverse();

    const threshold: IAttEngagementThresholds = iteration.reduce((holded, threshold) => {
        if (minWithoutResponse > threshold.untilMinutes && !holded) return threshold;
        return holded;
    }, undefined);

    return threshold;
}
