<div class="container">
  <span class="text">
    Grupos de conversação
    <!-- {{translations.goBack}} "{{currentGroupName}}" -->
  </span>

  <div class="close-container">
    <button type="button" feedbackduration="1000" tapfeedback="circular" class="close" (click)="close()">
      <i class="material-icons">close</i>
    </button>
  </div>

</div>
