import { Serializable } from '@colmeia/core/src/business/serializable';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { MMconstant } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { constant } from '@colmeia/core/src/business/constant';
import {clientConstants} from "../model/constants/client.constants";
import {IComponentParameter, IComponentParameterWithOptionalClient, IRootCallBack} from "../model/component-comm/basic";
import { defaultFields } from '@colmeia/core/src/tools/utility';
import { PreserveOnlyPartialProperties } from '@colmeia/core/src/tools/utility-types';

export interface IHandlerAllowingDebouncer {
    shouldUseDebouncer?: boolean;
}

export class MainHandler<CP extends IComponentParameterWithOptionalClient = IComponentParameterWithOptionalClient> {

    constructor (
        private parameter: CP,
        defaultParameter?: PreserveOnlyPartialProperties<CP>,
    ) {
        this.parameter = parameter;
        if (defaultParameter) defaultFields(this.parameter, defaultParameter as {} as Partial<CP>);
    };

    public hasCallback(): boolean {
        return this.parameter.clientCallback ? true : false;
    }

    public getComponentClient(): MainHandler<CP> { return this; };

    public getBestIDMedia(serializable: Serializable, idMediaTag: TGlobalUID = null): TGlobalUID {
        let idMedia: TGlobalUID;
        if (serializable && serializable.getMultimediaObject()) {
            if (idMediaTag) {
                idMedia = serializable.getMultimediaObject().getMultimediaIDwithIDTag(idMediaTag);
            } else {
                idMedia = serializable.getMultimediaObject().getMultimediaIDwithIDTag(MMconstant.tag.photo);
            };
        };
        return idMedia;
    };

    public getThreeDotedField(serializable: Serializable, idField: number = constant.serializableField.name): string {
        const name: string = this.getField(serializable, idField);
        return name.length > clientConstants.UI.sizeForThreeDots? name.substr(0, clientConstants.UI.sizeForThreeDots) + '...' : name;
    };

    public getField(serializable: Serializable, idField: number = constant.serializableField.name): string {
        let name: string = '';
        if (serializable) {
            name = serializable.getSerializableText(idField);
        };
        return name;
    };

    public getComponentParameter(): CP {
        return this.parameter;
    };

    public setCallback(callback: IRootCallBack): void {
        this.parameter.clientCallback = callback;
    };

}
export class MainHandlerWithPrivateConstructor extends MainHandler {
    private constructor(parameter: IComponentParameter) { super(parameter) }
    public static factory(parameter: IComponentParameter): MainHandlerWithPrivateConstructor { return new MainHandlerWithPrivateConstructor(parameter) }
}
