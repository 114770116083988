import { TDeleteFriendlyMessageArray } from "../../comm-interfaces/business-interfaces";
import { FriendlyMessage } from '../../error-control/friendly-message';
import { IRequest } from '../../request-interfaces/request-interfaces';
import { IResponse } from "../../request-interfaces/response-interfaces";
import { ENonSerializableObjectType, TEDependencyEntryTypeArray, TNSDependencyDescriptionArray } from "../non-serializable-id/non-serializable-id-interfaces";


export enum ECustomDeleteType {
    route = 'connRouteDeletion',
}

export interface IDeleteExecuteAditionalParams {
    ignoreDependencies: TEDependencyEntryTypeArray;
    isGeneralDeletion?: boolean;
    onUnableToDeleteCheckIfShouldReturn?(message: FriendlyMessage): void;
}

export interface ICheckDeletion {
    deletingID: string;
    justCheckIFCanDelete: boolean;
    checkChildren: boolean;
}

export interface IDeleteNSRequest extends IRequest, ICheckDeletion {

};

export interface IDeleteBatchNSRequest extends IRequest {
    idsToDelete: string[]
}

export interface ICheckDeleteNSRequest extends IRequest, ICheckDeletion { };

export enum EDependencyMode {
    DependsOnMe = 'onme',
    IDependOn = 'idon'
}

export interface ICheckDependenciesNSRequest extends IRequest, ICheckDependenciesNSData {
}

export interface ICheckDependenciesNSData {
    idNS: string;
    nsType?: ENonSerializableObjectType;
    dependencyMode: EDependencyMode;
    checkChildren: boolean;
}

export interface ICheckDependenciesNSResponse extends IResponse {
    dependencies: TNSDependencyDescriptionArray;
}

export interface ICheckDeleteNSResponse extends IResponse {
    deletable: ICanDelete;
}

export interface ICanDelete {
    canDeleteResource: boolean;
    friendlyDeleteMessage: TDeleteFriendlyMessageArray;
    canAutomaticDelete: boolean;
}