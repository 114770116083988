import { asConst } from "../../../tools/type-utils";
import { typedScreenOptions } from "../../../tools/utility";
import { IntersectPartialExplicit } from "../../../tools/utility/types";
import { EBPMSubEvent } from "../../BPM/common/common-bpm";
import { EBPMConditionInformationSource, EBotConditionInformationSource, EBotTrackConditions, ECustomerVisitMetrics, EMKTConditionInformationSource, TAllConditionInformationSources } from "../../metadata/meta-engagement";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { IScreenOptions } from "../atext-types";

const metaEngagementScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.MetaEngagement,
    fields: {
        field: 1,  // BotTransaction 47
        canonical: 2, // GeneralForm 24
        smartPhoto: 3, // metaEngagement 19
        smartAddress: 4, // metaEngagement 20
        smart: 5, // metaEngagement 21
    }
} as const);

const metaEngagementSourcePlacehols = asConst<IntersectPartialExplicit<IScreenOptions, { fields: { [key in TAllConditionInformationSources]: number } }>>()({
    idSerializable: EAllSerializable.MetaEngagementSourcePlaceholds,
    fields: {
        [EMKTConditionInformationSource.iddleTime]: 1, // Tempo sem atividade
        [EMKTConditionInformationSource.lastStatus]: 2, // Status
        [EBotConditionInformationSource.lastAddressSearch]: 3, // Último resultado da busca de endereço
        [EBotConditionInformationSource.currentChannel]: 4, // Canal atual
        [ECustomerVisitMetrics.botToday]: 5, // Bot: Primeira visita
        [ECustomerVisitMetrics.bot7Days]: 6, // Bot: 7 Dias
        [ECustomerVisitMetrics.bot30Days]: 7, // Bot: 30 Dias
        [ECustomerVisitMetrics.bot90Days]: 8, // Bot: 90 Dias
        [ECustomerVisitMetrics.bot180Days]: 9, // Bot: 180 Dias
        [ECustomerVisitMetrics.bot360Days]: 10, // Bot: 360 Dias
        [ECustomerVisitMetrics.serviceToday]: 11, // Serviço: Primeira visita
        [ECustomerVisitMetrics.service7Days]: 12, // Serviço: 7 dias
        [ECustomerVisitMetrics.service30Days]: 13, // Serviço: 30 dias
        [ECustomerVisitMetrics.service90Days]: 14,//  Serviço: 90 dias
        [ECustomerVisitMetrics.service180days]: 15, //  Serviço: 180 dias
        [ECustomerVisitMetrics.serviceYear]: 16, // Serviço: 360 dias
        [ECustomerVisitMetrics.botTot]: 17, // Bot: Total de visitas
        [ECustomerVisitMetrics.serviceTotal]: 18, // Serviço: Total de visitas
        [EMKTConditionInformationSource.customerIdleTime]: 19, // Tempo sem atividade do cliente
        [EBotTrackConditions.Name]: 20, // Tempo sem atividade do cliente
        [EBotTrackConditions.Parameter]: 21, // Tempo sem atividade do cliente
        [EBPMSubEvent.expire]: 22, // Expiração
        [EBPMConditionInformationSource.event]: 23, // Evento
    }
} as const);



export const metaEngagementTranslations = getTranslationsFields(metaEngagementScreenOptions);
export const metaEngagementSourcePlaceholsTranslations = getTranslationsFields(metaEngagementSourcePlacehols);