import { ChatbarHandler, IChatBarParameter } from './chat-bar-handler';
import { Citation } from '@colmeia/core/src/interaction/interactions/standard-interaction';
import { Interaction } from '@colmeia/core/src/interaction/interaction';

export interface IReplyChatHandlerParameter extends ChatbarHandler {
    
}
export class ReplyChatHandler extends ChatbarHandler {

    constructor(chatBarParameter: IChatBarParameter, private replyWithRemember: boolean) {
        super(chatBarParameter);
    }

    public getNewInteraction(): Citation {
        return Citation.getNewCitation(this.getParticipant(), this.getInteractionParent(), '');
    }

    public getInteraction(): Interaction {
        return this.getComponentParameter().isEditing
            ? this.getEditingInteraction()
            : this.getNewInteraction();
    }

    public get isReplyWithRemember(): boolean {
        return this.replyWithRemember;
    }

}
