import {Injectable} from "@angular/core";
import {SignalPublisherService} from "./signal/signal-publisher";
import {ChatBarInteractionHandlerService} from "../handlers/chat-bar-interactions/chat-bar-interaction-handler.service";
import {Interaction} from "@colmeia/core/src/interaction/interaction";
import {FeatureSignal} from "../model/signal/state-signals/feature-signal";
import {constant, TGlobalUID} from "@colmeia/core/src/business/constant";
import {EServiceGroupIntType} from "@colmeia/core/src/business/constant.enums";
import {ChainedInteraction} from "@colmeia/core/src/interaction/chained-interaction";
import {ChatbarHandler} from "../handlers/chat-bar-interactions/chat-bar-handler";

@Injectable()
export class FeatureStateEmissorService {

    constructor (
        private emissor: SignalPublisherService,
        private handlerSVC: ChatBarInteractionHandlerService
    ) { }

    editChatBarFeature(editingInteraction: Interaction): void {
        const [chosenInteraction, chosenInteractionParent] = this.getBestInteractionChain(true, editingInteraction);
        this.emissor.specificSignalEmissorOnGenericStream(new FeatureSignal(this.handlerSVC.handlerFor(editingInteraction.getInteractionType().getPrimaryID(), chosenInteraction, chosenInteractionParent, true)));
    }

    public replyChatBar(replyInteraction: Interaction, rememberMeReply: boolean = false): void {
        const handler: ChatbarHandler = this.handlerSVC.handlerFor(
            constant.interactionType.standard.citation,
            null,
            replyInteraction,
            false,
            null,
            rememberMeReply,
        );
        this.emissor.specificSignalEmissorOnGenericStream(new FeatureSignal(handler));
    }

    public changeChatBarToFeature(idInteractionType: TGlobalUID, interaction?: Interaction, parentInteraction?: Interaction): void {
        const handler: ChatbarHandler = this.handlerSVC.handlerFor(idInteractionType, interaction, parentInteraction);
        this.emissor.specificSignalEmissorOnGenericStream(new FeatureSignal(handler));
    }


    public chatBarToStartService(idInternalSupportGroup: string): void {
        const handler: ChatbarHandler = this.handlerSVC.handlerFor(
            EServiceGroupIntType.startServiceChat,
            null,
            null,
            null,
            idInternalSupportGroup
        );
        this.emissor.specificSignalEmissorOnGenericStream(new FeatureSignal(handler));
    }

    private getBestInteractionChain(isEdit: boolean, interaction: Interaction): [Interaction, Interaction] {
        const isChildInteraction = interaction && interaction.isChainedOrTreeWithParent();
        const parentInteraction = isChildInteraction
            ? (<ChainedInteraction>interaction).getInteractionParent()
            : null;
        const chosenInteraction = isEdit ? interaction : null;
        const chosenInteractionParent = isEdit ? parentInteraction : interaction;

        return [chosenInteraction, chosenInteractionParent]
    }
}
