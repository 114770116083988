<h4 mat-dialog-title>
  {{ isEditing ? 'Editar contato' : 'Adicionar contato' }}
</h4>

<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Nome</mat-label>
    <input matInput [formControl]="nameFormControl">
    <mat-error *ngIf="nameFormControl.hasError('required')">
      O nome é <strong>obrigatório</strong>.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput type="email" [formControl]="emailFormControl">
    <mat-error *ngIf="emailFormControl.hasError('localEmail')">
      Digite um email válido
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%;">
    <mat-label>Tipo de contato</mat-label>
    <mat-select [(value)]="typeOfContact" prefix="+">
      <mat-option *ngFor="let contactType of contactTypes | keyvalue" [value]="contactType.key">
        {{contactType.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="phone-wrapper" [ngClass]="{'phone-wrapper-international': isInternationalContact()}">

    <mat-form-field appearance="outline" class="phone-ddi" [ngClass]="{'has-ddd': hasDdd}">
      <mat-label>DDI</mat-label>
      <mat-select #ddiSelect (ngModelChange)="handleDdiChange($event)" [formControl]="ddiFormControl" panelWidth="null">
        <mat-option selected [value]="brazilPhoneData">
          {{'+' + brazilPhoneData.code+ ' - ' + brazilPhoneData.iso}}
        </mat-option>
        <mat-option *ngFor="let country of allCountriesPhoneCodes" [value]="country">
          {{ '+' + country.code+ ' - ' + country.iso }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ddiFormControl.hasError('required')">
        Campo DDI é obrigatório
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="hasDdd" class="phone-ddd" appearance="outline">
      <mat-label>DDD</mat-label>
      <input #dddInput matInput [formControl]="dddFormControl" (ngModelChange)="handleDddChange()" placeholder="Ex: 11"
        [mask]="dddMask">
      <mat-error *ngIf="dddFormControl.hasError('required')">
        Campo DDD é obrigatório
      </mat-error>

    </mat-form-field>

    <mat-form-field class="phone-number" [ngClass]="{'has-ddd': hasDdd}" appearance="outline" *ngIf="showNumberInput">
      <mat-label>Número</mat-label>
      <input matInput #numberInput [formControl]="numberFormControl" [mask]="numberMask"
        (ngModelChange)="handleNumberChange()">
      <mat-error *ngIf="numberFormControl.hasError('phoneNumberLength') && !numberFormControl.hasError('required')">
        Digite um número válido
      </mat-error>
      <mat-error *ngIf="numberFormControl.hasError('required')">
        Digite um número.
      </mat-error>
    </mat-form-field>
  </div>

  <app-alert *ngIf="duplicatedContact || invalidNumber" type="error">
    <ul style="margin: 0;">
      <!-- <li *ngIf="ddiFormControl.hasError('required')">- Campo DDI é obrigatório.</li>
        <li *ngIf="dddFormControl.hasError('required')">- Campo DDD é obrigatório.</li>
        <li *ngIf="numberFormControl.hasError('phoneNumberLength') && !numberFormControl.hasError('required')">- Digite um número válido.</li>
        <li *ngIf="numberFormControl.hasError('required')">- O Campo número é obrigatório.</li> -->

      <li *ngIf="duplicatedContact">- Já existe um contato com esse número.</li>
      <li *ngIf="invalidNumber">- Esse número não é válido para o país selecionado: {{ddiFormControl.value.iso}}</li>
    </ul>

  </app-alert>


  <mat-form-field appearance="outline" *ngIf="showFBIdInput">
    <mat-label>ID no Facebook</mat-label>
    <input matInput [formControl]="fbIdInputControl">
    <mat-error *ngIf="fbIdInputControl.hasError('required')">
      Esse campo é <strong>obrigatório</strong>.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="showColmeiaIdInput">
    <mat-label>ID na Colmeia</mat-label>
    <input matInput [formControl]="colmeiaIdInputControl">
    <mat-error *ngIf="colmeiaIdInputControl.hasError('required')">
      Esse campo é <strong>obrigatório</strong>.
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button [disabled]="!validateFields()" class="save-button" mat-flat-button color="primary" (click)="handleSubmit()">
    {{isEditing ? 'Salvar': 'Adicionar' }}
  </button>
</mat-dialog-actions>
