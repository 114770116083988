export interface IEditedMsgsClient { 
    editedMsgsWindowClosedCallback(): void;
}

export type TEditedMsgViewerDescriptorArray = Array<IEditedMsgViewerDescriptor>;

export interface IEditedMsgViewerDescriptor {
    date: Date;
    message: string;
}

export interface IEditedMsgsParameter {
    messages: IEditedMsgViewerDescriptor[];
    clientInstance: IEditedMsgsClient;
}

export class EditedMsgsHandler {
    constructor(private parameters: IEditedMsgsParameter) { }

    getMessages(): TEditedMsgViewerDescriptorArray {
        return this.parameters.messages
    }

    closeClicked(): void {
        if (this.parameters.clientInstance)
            this.parameters.clientInstance.editedMsgsWindowClosedCallback();
    }
}
