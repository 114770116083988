import { Component, OnInit } from "@angular/core";
import {
    EParticipantClickTypeMode,
    EParticipantSelectorAction,
    ESelectionType,
    IGroupParticipantsControl,
    IParticipantSelectorClient,
    IParticipantSelectorParameter,
    ParticipantSelectorHandler,
    TParticipantSelectorActionList
} from "../../../../handlers/participant-selector.handler";
import { GroupEditor } from "../../../../services/group-editor";
import { SessionService } from "../../../../services/session.service";
import { GroupPersistorServices, IGetCreateEditSupportData } from "../../../../services/group-persistor.services";
import { AvatarService, TAvatarResponseClient } from "../../../../services/avatar.service";
import { clientConstants } from "../../../../model/constants/client.constants";
import { TArrayAvatar, TExtendedParticipant, TExtendedParticipantArray } from "@colmeia/core/src/core-constants/types";
import { GroupType } from "@colmeia/core/src/business/group-type";
import { constant } from "@colmeia/core/src/business/constant";
import { ESearchAllowedType } from "@colmeia/core/src/shared-business-rules/search";
import { isValidRef, isValidString } from "@colmeia/core/src/tools/barrel-tools";
import { TSortBy } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { EListNonSerializablesSortOrder } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";

export const DEFAULT_PARTICIPANTS_PER_PAGE: number = 50;
export const DEFAULT_SORT_BY: TSortBy = [
    {
        fieldName: 'clockTickBegin',
        direction: EListNonSerializablesSortOrder.Descending
    }
]
@Component({
    selector: 'app-group-members-tab',
    templateUrl: './group-members-tab.component.html',
    styleUrls: ['./group-members-tab.component.scss']
})
export class GroupMembersTabComponent implements OnInit, IParticipantSelectorClient {
    participantSelectorHandler: ParticipantSelectorHandler;
    private groupParticipantsControl: IGroupParticipantsControl = {
        isNewGroup: false,
        selectionType: ESelectionType.groupSelector,
        currentParticipants: [],
        newParticipants: [],
        deletedParticipants: [],
    };
    private currentParticipants: TExtendedParticipantArray = [];
    private isDistributionList: boolean;
    public loadingComponent: boolean;

    constructor(
        private editor: GroupEditor,
        private sessionSVC: SessionService,
        private groupFunctionService: GroupPersistorServices,
        private avatarSvc: AvatarService

    ) {
        this.loadingComponent = true;
        this.isDistributionList = this.editor.isDistributionList();
        this.buildParticipantSelectorHandler(DEFAULT_PARTICIPANTS_PER_PAGE, undefined, DEFAULT_SORT_BY);
    }

    ngOnInit() {}

    async getGroupMembers(amountItemsPerPage: number, cursor?: string, sortBy?: TSortBy): TAvatarResponseClient {
        const groupId = this.editor.getGroup().getGroupID()

        const response = await this.avatarSvc.getAvatarsFromGroup(amountItemsPerPage, groupId, cursor, sortBy);

        if (!isValidRef(response)) return;

        if (!response.avatars.length || (response.avatars.length && response.avatars.length < amountItemsPerPage && isValidString(response.cursor))) {

            const complement = await this.getGroupMembers(amountItemsPerPage - response.avatars.length, response.cursor, sortBy);

            if (complement) {
                return {
                    avatars: [...response.avatars, ...complement.avatars], 
                    cursor: complement.cursor, 
                    idAvatarToClocktickBegin: {...response.idAvatarToClocktickBegin, ...complement.idAvatarToClocktickBegin}
                }
            }
        }
        return response;
    }

    private async buildParticipantSelectorHandler(amountItemsPerPage: number = DEFAULT_PARTICIPANTS_PER_PAGE, cursor?: string,  sortBy?: TSortBy): Promise<void> {
        const groupId = this.editor.getGroup().getGroupID()
        const groupType: GroupType = this.editor.getGroupType();
        const createEditSupportData: IGetCreateEditSupportData = this.editor.getSupportData();
        const grantorParticipantRoles = this.sessionSVC.getPlayerInfo()
            .getGroupAvatarRole(groupId, this.sessionSVC.getSelectedAvatarID())
            .map(role => role.getPrimaryID());

        const groupMembersObj = await this.getGroupMembers(amountItemsPerPage, cursor, sortBy);

        this.groupParticipantsControl.currentParticipants = [
            ...this.groupParticipantsControl.currentParticipants,
            ...( !this.isDistributionList ? groupMembersObj?.avatars || [] : createEditSupportData.distributionListGroups)
        ]
        this.currentParticipants = [
            ...this.currentParticipants,
            ...( !this.isDistributionList ? groupMembersObj?.avatars || [] : createEditSupportData.distributionListGroups)
        ]

        const amIAdmin = this.sessionSVC.isAdminOnCurrentGroup();
        const roleBtn = amIAdmin ? EParticipantSelectorAction.Role : EParticipantSelectorAction.None;
        const removeBtn = amIAdmin
            ? EParticipantSelectorAction.Remove
            : EParticipantSelectorAction.None;
        const typesOfActions: TParticipantSelectorActionList = [roleBtn, removeBtn];

        const parameter: IParticipantSelectorParameter = {
            typesOfActionsOnEachElementSelected: typesOfActions,
            typesAllowed: [ESearchAllowedType.people],
            // alreadySelectedList: this.isDistributionList ? createEditSupportData.distributionListGroups : createEditSupportData.avatarArray,
            alreadySelectedList: this.isDistributionList ? createEditSupportData.distributionListGroups : this.currentParticipants,
            clientCallback: this,
            operationMode: EParticipantClickTypeMode.SendToParent,
            maxNumberOfParticipants: clientConstants.maxParticipantsPerGroup,
            isEditableTypesSelected: false,
            groupId,
            groupParticipantsControl: this.groupParticipantsControl,
            participantRoles: {
                allParticipantRoles: createEditSupportData.rolesParticipant,
                grantorParticipantRoles: grantorParticipantRoles
            },
            cursor: groupMembersObj?.cursor,
            sortBy: sortBy,
            usePagination: true,
            idAvatarToClocktickBegin: groupMembersObj?.idAvatarToClocktickBegin
        };

        if (groupType.is(constant.groupType.functional.distributionList))
            parameter.typesAllowed = [ESearchAllowedType.groups];

        this.participantSelectorHandler = new ParticipantSelectorHandler(parameter);
        this.loadingComponent = false;
    
    }

    async loadMoreParticipants(amountItemsPerPage?: number, cursor: string | undefined = undefined, sortBy?: TSortBy): Promise<void> {
        if (!cursor) {
            this.currentParticipants = [];
            this.groupParticipantsControl.currentParticipants = [];
        }
        return await this.buildParticipantSelectorHandler(amountItemsPerPage, cursor, sortBy);
    }

    async onParticipantsSelected(selected: Array<TExtendedParticipant>, excluded: Array<TExtendedParticipant>) {
        const diffToAdd = selected.filter(el => !this.currentParticipants.includes(el));
        const diffToRemove = this.currentParticipants.filter(el => !selected.includes(el));

        const [isAdded, isRemoved] = await Promise.all([
            this.addNewParticipants(diffToAdd),
            this.removeParticipants(diffToRemove),
        ]);

        return {
            isAdded: !!isAdded,
            isRemoved: !!isRemoved,
        }
    }

  async removeParticipants(selected: Array<TExtendedParticipant>): Promise<boolean | void> {
        for (let participant of selected) {
            const idToDeleted = this.currentParticipants.findIndex(el => el.getPrimaryID() === participant.getPrimaryID());
            if (idToDeleted > -1) {
                const out = this.isDistributionList
                    ? await this.groupFunctionService.removeGroupFromDL(participant.getPrimaryID(), this.editor.getGroup().getGroupID())
                    : await this.groupFunctionService.removeAvatar(participant.getPrimaryID(), this.editor.getGroup().getGroupID());
                

                if (out) {
                    this.currentParticipants.splice(idToDeleted, 1);
                }
                return out;
            }
        }
    }

    async addNewParticipants(selected: Array<TExtendedParticipant>): Promise<boolean | void> {
        for (let participant of selected) {
            this.currentParticipants.push(participant);
            const out = this.isDistributionList
                ? await this.groupFunctionService.addGroupToDL(participant.getPrimaryID(), this.editor.getGroup().getGroupID())
                : await this.groupFunctionService.addAvatar(participant.getPrimaryID(), this.editor.getGroup().getGroupID());
            return out;
        }
    }
}
