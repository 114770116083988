import { GroupType } from "@colmeia/core/src/business/group-type";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { $SharedServices } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { refreshClientConstants } from "../../app/model/constants/client.constants";
import { mapCurrentDashboardClient } from "../../app/model/dashboard/db/dashboard-db-mapper";
import { environment } from "../../environments/environment-client";
import { SerializableHeaders } from "../../app/services/serializable-headers";
import { DSOperators } from "@colmeia/core/src/tools/utility/functions/DSOperators";
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import { isDevEnvironment } from "app/model/is-dev-env";
import * as utility from "@colmeia/core/src/tools/utility";
import { getPath, jsonDifferences, formatMessage } from "@colmeia/core/src/tools/utility";
import { CodeEditorDialogHandler } from "app/components/dashboard/code-editor/code-editor-dialog/code-editor-dialog.handler";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { serializableToYAML } from "@colmeia/core/src/shared-business-rules/visual-element/visual-element.utils";
import { routeID, routeList } from 'app/model/routes/route-constants';
import { $add } from "@colmeia/core/src/tools/utility-types";
import { RouteCreator, RoutingBuilder } from "app/services/routing-builder";
import { UserFunctionExtractor } from "@colmeia/core/src/shared-business-rules/user-function/user-function-utils";
import { EIdMenus } from "@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus";
import { CustomError } from "@colmeia/core/src/error-control/custom-error";
import { getSNFromNS } from "@colmeia/core/src/shared-business-rules/non-serializable-id/ns-client-functions";
import { parseLibGooglePhoneNumber } from "@colmeia/core/src/shared-business-rules/social-cc/config-cell";
import { encryptPlayerPassword } from "@colmeia/core/src/shared-business-rules/password/password-functions";


export function setWindowClient() {
    Object.assign(window, {
        RouteCreator,
        RoutingBuilder,
        Serializable,
        GroupType,
        getPath,
        DSOperators,
        mapCurrentDashboardClient,
        SerializableHeaders,
        routeID,
        routeList,
        gTranslations,
        UberCache,
        environment,
        isDevEnvironment,
        jsonDifferences,
        formatMessage,
        refreshClientConstants,
        serializableToYAML,
        CodeEditorDialogHandler,
        ENonSerializableObjectType,
        utility,
        UserFunctionExtractor,
        $add,
        EIdMenus,
        getSNFromNS,
        parseLibGooglePhoneNumber,
        encryptPlayerPassword,
        CustomError,
        ...$SharedServices,
    });
}

setWindowClient()
