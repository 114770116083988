import { AfterViewInit, Component, ElementRef, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { TAllUserFunctionRuntimeFunctions } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { CodeEditorComponent } from 'app/components/dashboard/code-editor/code-editor.component';
import { entries } from 'lodash';
import { first } from 'rxjs/operators';
import { FunctionCreateClientModel } from '../functions-create/functions-create.model';
import { runtimeFunctionsClientDB, TRuntimeFunctionConfigDB, TUFRuntimeMethodConfig } from '../user-functions-client-db';

type TUserFunctionDocConfigGenerated = TUFRuntimeMethodConfig<TAllUserFunctionRuntimeFunctions> & {
  sampleCode?: string;
}

@Component({
  selector: 'app-functions-docs',
  templateUrl: './functions-docs.component.html',
  styleUrls: ['./functions-docs.component.scss'],
})
export class FunctionsDocsComponent implements OnInit, AfterViewInit {
  initialSelectedMethod: TAllUserFunctionRuntimeFunctions;
  initialSelectedIndex: number = 0;

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  docsConfigs: TRuntimeFunctionConfigDB = runtimeFunctionsClientDB;

  docsConfigsArray: TUserFunctionDocConfigGenerated[] = this.genConfigArray();

  @ViewChildren(CodeEditorComponent)
  codeEditorList: QueryList<CodeEditorComponent>;

  @ViewChild("dialogContent") dialogContent: ElementRef<HTMLDivElement>;

  private genConfigArray(): TUserFunctionDocConfigGenerated[] {
    return entries(this.docsConfigs).map(([funcName, commonConfig]) => {
      return {
        ...commonConfig,
        sampleCode: FunctionCreateClientModel.getFunctionDocCode(funcName as TAllUserFunctionRuntimeFunctions)
      }
    });
  }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) targetMethod: TAllUserFunctionRuntimeFunctions
    ) {
    if(isValidString(targetMethod)) {
      this.initialSelectedMethod = targetMethod;
      this.initialSelectedIndex = this.findMethodIndex(targetMethod);
    }
  }

  private findMethodIndex(method: TAllUserFunctionRuntimeFunctions): number {
    return this.docsConfigsArray.findIndex(i => i.value === method);
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.tabGroup.selectedIndexChange.subscribe((index) => {
      this.dialogContent.nativeElement.scroll({
        top: 0,
        behavior: 'smooth'
      });

      this.tabGroup.animationDone.pipe(first()).subscribe(() => {
        this.codeEditorList.get(index).layout();
      });
    });

  }

}
