import { ECatalogType, INSCatalogClient, INSCatalogServer, TCatalogField } from "@colmeia/core/src/shared-business-rules/catalog/catalog.interfaces";
import { initNserClientNoCheck } from "../../nser/blank-ns";
import { isInEnumFactory } from "../../tools/utility/functions/is-in-enum-factory";
import { getCanonicalConfig } from "../const-text/views/canonical";
import { EMetadataNames } from "../metadata/metadata-db";
import { isNSServer } from "../non-serializable-id/non-serializable-functions";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { allCatalogFields } from "./catalog.constants";

export function isCatalogServer(ns: INSCatalogClient): ns is INSCatalogServer {
    return isNSServer(ns);
}
// export function getEmptyCatalogMap() {
//     const map = {} as TBasicCatalogConfigMap;
//     return map;
// }

export function getMetaCatalogFieldProperty(field: TCatalogField) {
    return getMetaCatalogFieldConfig(field)?.key;
}

export function getMetaCatalogFieldConfig(field: TCatalogField) {
    return getCanonicalConfig(field).catalogConfig?.[ECatalogType.Meta];
}

const catalogFieldSet = new Set<EMetadataNames>(allCatalogFields)
export function isCatalogField(field: EMetadataNames): field is TCatalogField {
    return catalogFieldSet.has(field);
}

export function getCatalogFieldAsserted(field: EMetadataNames): TCatalogField {
    if (!isCatalogField(field)) throw new Error('Must be a catalog field');
    return field;
}



export function initNSCatalogClient(): INSCatalogClient {
    const ns: INSCatalogClient = {
        ...initNserClientNoCheck(ENonSerializableObjectType.catalog, '', {}),
        providersInfo: {
            [ECatalogType.Meta]: {
                scheduleHour: 0,
            }
        },
        providers: {},
    };
    return ns;
}

