import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-attendance-active-method-custom-message',
    templateUrl: './attendance-active-method-custom-message.component.html',
    styleUrls: ['./attendance-active-method-custom-message.component.css']
})
export class AttendanceActiveMethodCustomMessageComponent implements OnInit {

    @Input() selectedCampaign

    constructor() { }

    ngOnInit() {
    }

}
