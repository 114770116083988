import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {EHardwareUpdateDeviceType} from "../../../services/hardware";
import {IColmeiaDialogComponentData} from "../dialog/dialog.component";
import {IUpdatedDialogData} from "../../../services/hardware/shared/base-updater";
import {RootComponent} from "../../foundation/root/root.component";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-updated-dialog',
    templateUrl: './updated-dialog.component.html',
    styleUrls: ['./updated-dialog.component.scss']
})
export class UpdatedDialogComponent extends RootComponent<
    'mMessage' |
    'mTitle' |
    'message' |
    'title'
> implements OnInit {

    private platform: EHardwareUpdateDeviceType;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) { platform }: IUpdatedDialogData,
        private cdr: ChangeDetectorRef
    ) {
        super({
            mMessage: gTranslations.fragments.waitUntilUpdate,
            mTitle: gTranslations.fragments.otimization,
            message: gTranslations.fragments.restart,
            title: gTranslations.fragments.versionNumber
        }, true, cdr);
        this.platform = platform;
    }

    ngOnInit() {}

    private get isMobile(): boolean {
        return this.platform === EHardwareUpdateDeviceType.Mobile;
    }

    get title(): string {
        return this.isMobile
            ? this.translations.mTitle.value
            : this.translations.title.value;
    }

    get message(): string {
        return this.isMobile
            ? this.translations.mMessage.value
            : this.translations.message.value;
    }

    public reload() {
        window.location.reload();
    }
}
