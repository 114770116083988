import { Injectable, NgZone } from "@angular/core";
import { ServerCommunicationService } from "../../../services/server-communication.service";
import {
    IEmbeddedChatFirstFormAnswerRequest,
    IEmbeddedChatFirstFormAnswerResponse,
    IEmbeddedChatHandsShakeRequest,
    IEmbeddedChatHandsShakeResponse,
    IEmbeddedChatNoFormTrackRequest,
    IEmbeddedChatSendTokenRequest,
    IEmbeddedChatSendTokenResponse,
    IEmbeddedChatValidateTokenRequest,
    IEmbeddedChatValidateTokenResponse,
    IEmbeddedNoFormTrackResponse
} from "@colmeia/core/src/shared-business-rules/embedded/embedded-req-res";
import { colmeiaServiceRequestType } from "@colmeia/core/src/comm-interfaces/colmeia-services-req-res";
import { IGeneralFormAnswer, IGeneralFormAnswerJson } from "@colmeia/core/src/general-form/general-form-answer";
import ColmeiaApp from "../../../../preloader/app";
import { ScreenSpinnerService, SpinType } from "../../../services/screen-spinner.service";
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { AuthService } from "app/services/auth/auth.service";
import { HardwareLayerService } from "app/services/hardware";
import { TimeZone } from '@colmeia/core/src/time/timezone';
import { EWebChatEmbeddedType } from "@colmeia/core/src/shared-business-rules/embedded/webchat-embedded.model";

export type TSubscribeToPostMessageLoginCallback = (response: IEmbeddedChatHandsShakeResponse) => void | Promise<void>;

@Injectable({
    providedIn: 'root'
})
export class EmbeddedChatApi {

    constructor(
        private api: ServerCommunicationService,
        private hw: HardwareLayerService,
        // private auth: AuthService,
    ) { }

    private alreadyEmittedLogin: boolean = false;

    public subscribeToLoginPostMessage(
        idService: string,
        sdkAnswer: IGeneralFormAnswerJson,
        unique: string,
        origin: string,
        auth: AuthService,
        type: EWebChatEmbeddedType,
        idBot: string,
        callback: TSubscribeToPostMessageLoginCallback
    ): void {
        (window['_COLMEIA_'] as ColmeiaApp).subscribeToEmbeddedLoginEvent(origin,
            async (evt: MessageEvent) => {
                console.log({ evt });

                if (!evt) {
                    return;
                }

                if (evt.origin !== origin) {
                    return;
                }

                if (!this.alreadyEmittedLogin) {
                    this.alreadyEmittedLogin = true;
                    const handshakeResponse: IEmbeddedChatHandsShakeResponse = await this.submitHandshakeWithSDKMetadata(idService, sdkAnswer, unique, type, idBot);
                    callback(handshakeResponse);
                }
            });
    }

    public async submitHandshakeWithSDKMetadata(
        idService: string,
        sdkAnswer: IGeneralFormAnswerJson,
        unique: string,
        type: EWebChatEmbeddedType,
        idBot: string,
    ): Promise<IEmbeddedChatHandsShakeResponse> {
        const req: IEmbeddedChatHandsShakeRequest = {
            requestType: colmeiaServiceRequestType.embedded.handshake,
            idService,
            sdkAnswer,
            idUserToken: unique,
            timezoneHR: TimeZone.hour,
            type,
            idBot,
            idBrowserFingertip: this.hw.getDeviceUID()
        };
        const resp = this.api.colmeiaServicesRequest<IEmbeddedChatHandsShakeRequest, IEmbeddedChatHandsShakeResponse>(req);
        console.log({ submitHandshakeWithSDKMetadata: resp });
        return resp
    }

    public async submitFirstForm(uniqueId: string, idHandshake: string, answer: IGeneralFormAnswer): Promise<IEmbeddedChatFirstFormAnswerResponse> {
        try {
            const req: IEmbeddedChatFirstFormAnswerRequest = {
                answer,
                idUserToken: uniqueId,
                idHandShake: idHandshake,
                requestType: colmeiaServiceRequestType.embedded.firstForm,
                idBrowserFingertip: this.hw.getDeviceUID()
            };
            return this.api.colmeiaServicesRequest<IEmbeddedChatFirstFormAnswerRequest, IEmbeddedChatFirstFormAnswerResponse>(req);
        } catch (err) {
            return undefined;
        }
    }

    public async validateToken(
        idHandShake: string,
        idUserToken: string,
        idProperty: string,
        token: string,
    ) {
        const req: IEmbeddedChatValidateTokenRequest = {
            idHandShake,
            idUserToken,
            idProperty,
            value: token,
            requestType: colmeiaServiceRequestType.embedded.validateToken,
            idBrowserFingertip: this.hw.getDeviceUID(),
        };
        return this.api.colmeiaServicesRequest<IEmbeddedChatValidateTokenRequest, IEmbeddedChatValidateTokenResponse>(req);
    }

    public async sendToken(
        idHandShake: string,
        idUserToken: string,
        idProperty: string,
        value: string,
    ) {
        const req: IEmbeddedChatSendTokenRequest = {
            idHandShake,
            idUserToken,
            idProperty,
            value,
            requestType: colmeiaServiceRequestType.embedded.sendToken,
            idBrowserFingertip: this.hw.getDeviceUID(),
        };
        return this.api.colmeiaServicesRequest<IEmbeddedChatSendTokenRequest, IEmbeddedChatSendTokenResponse>(req);
    }
}
