import { Injector, StaticProvider, Type } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { KanbanCard } from "@colmeia/core/src/shared-business-rules/kanban/kanban-card";
import { KanbanColumn } from "@colmeia/core/src/shared-business-rules/kanban/kanban-column";
import { ICardFilter, ICardSorter, IKanbanCard, IKanbanCardData, IKanbanColumn, IKanbanColumnData, TCardFilterArray, TCardSorterArray, TColumnsFilterFunction, TKanbanCardArray, TKanbanColumnArray } from "@colmeia/core/src/shared-business-rules/kanban/kanban-shared-model";
import { arrayToMap, isInEnum, isValidFunction, mapToArray, objectShallowReplace, uniqBy, values } from "@colmeia/core/src/tools/barrel-tools";
import { AcceptPromise } from "@colmeia/core/src/tools/utility-types";
import { ColmeiaWindowService } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service";
import { ServerCommunicationService } from "app/services/server-communication.service";
import { cloneDeep } from "lodash";
import { Subject } from "rxjs";
import { EKanbanStandardCardFilters, EKanbanStandardCardSort, kanbanStandardCardFilters, kanbanStandardCardSort } from "./kanban-filter-n-sort";

type TCardFilterId = EKanbanStandardCardFilters | string;
type TCardSortId = EKanbanStandardCardSort | string;

export interface IKanbanCardRendererProps {
    kanbanService: KanbanService;
    column: IKanbanColumn;
    card: IKanbanCard;
}

export type TKanbanCardRenderer = |
    (new (props: IKanbanCardRendererProps) => unknown) |
    ((props: IKanbanCardRendererProps) => unknown)

export interface IKanbanDialogData<
    CardData extends IKanbanCardData = IKanbanCardData,
    ColumnData extends IKanbanColumnData = IKanbanColumnData
> {
    card: IKanbanCard<CardData>;
    currentColumn: IKanbanColumn<ColumnData>;
}

export interface IKanbanCanDropReturn {
    canDrop: boolean;
    messages?: string[]
}

export interface IKanbanCardMovedEvent<TD extends IKanbanCardData = IKanbanCardData, CD extends IKanbanColumnData = IKanbanColumnData> {
    card: IKanbanCard<TD>;
    sourceColumn: IKanbanColumn<CD>;
    sourceTopCard?: IKanbanCard<TD>;
    sourceBottomCard?: IKanbanCard<TD>;
    targetColumn: IKanbanColumn<CD>;
    targetTopCard?: IKanbanCard<TD>;
    targetBottomCard?: IKanbanCard<TD>;
}

export abstract class KanbanService<
    CardData extends IKanbanCardData = IKanbanCardData,
    ColumnData extends IKanbanColumnData = IKanbanColumnData
> {
    protected abstract readonly service: new (...args: any[]) => KanbanService;
    protected abstract readonly card: new (cardData: IKanbanCardData) => KanbanCard<CardData>;
    protected abstract readonly column: new (columnData: IKanbanColumnData) => KanbanColumn<ColumnData>;
    protected abstract readonly dialogComponent: Type<unknown>;

    private cards: TKanbanCardArray = [];
    private columns: TKanbanColumnArray = [];
    private currentCardsData: Map<string, CardData> = new Map();
    private currentColumnsData: Map<string, ColumnData> = new Map();

    /**
     * Eventos
     */
    protected columnsListUpdated = new Subject<void>();
    public columnsListUpdated$ = () => this.columnsListUpdated.asObservable()

    protected _columnChanged = new Subject<{
        /**
         * Caso seja um array vazio, todas as colunas precisam renderizar
         */
        columnsChanged?: TKanbanColumnArray
    }>();
    public columnChanged$ = () => this._columnChanged.asObservable()

    protected _cardChanged = new Subject<{
        /**
         * Caso seja um array vazio, todas os card precisam renderizar
         */
        cardsChanged?: TKanbanCardArray
    }>();
    public cardChaged$ = () => this._cardChanged.asObservable()
    // Eventos

    /**
     * Filtros e ordenação
     */
    private columnsFilterFunction?: TColumnsFilterFunction<ColumnData>;

    private customCardFilters: Record<string, ICardFilter> = {};
    private activeCardFilters: Set<string> = new Set();
    private currentCardFilter?: ICardFilter;

    private customCardSorts: Record<string, ICardSorter> = {};
    private currentCardSort?: ICardSorter;

    protected readonly useColmeiaWindow: boolean = false;
    protected getWindowInfo?: (card: IKanbanCard) => { title: string, group: string };

    /**
     * Métodos específicos do cliente
     */

    abstract onCardMoved(event: IKanbanCardMovedEvent): AcceptPromise<boolean>;
    abstract getCardRenderer(): TKanbanCardRenderer | undefined;

    constructor(
        protected apiSvc: ServerCommunicationService,
        protected dialogSvc: MatDialog,
        protected colmeiaWindowSvc: ColmeiaWindowService
    ) { }

    findCard(idCard: string): IKanbanCard<CardData> | undefined {
        return this.cards.find(c => c.getCardID() === idCard) as IKanbanCard<CardData>
    }

    async canDropCardOnColumn(element: IKanbanCard, targetColumn: IKanbanColumn): Promise<IKanbanCanDropReturn> {
        const elementRes = element.canDropOnColumn(targetColumn);
        const columnRes = targetColumn.canDropCard(element);

        return { canDrop: elementRes && columnRes };
    }

    public loadColumnsAndCards(columns: ColumnData[] = mapToArray(this.currentColumnsData), cards: CardData[] = mapToArray(this.currentCardsData)) {
        // não alterar a ordem, as colunas precisam de todos os cards instanciados
        this.loadCards(cards);
        this.loadColumns(columns);
        this.columnsListUpdated.next();
    }

    public resetCards() {
        for (const column of this.columns) {
            column.removeAllCards();
        }
    }

    public loadCards(cards: CardData[] = mapToArray(this.currentCardsData)) {
        this.currentCardsData = arrayToMap(cloneDeep(cards), 'cardId');
        this.cards = cards.map(cardData => new this.card(cardData));
    }

    private loadColumns(columns: ColumnData[] = mapToArray(this.currentColumnsData)) {
        this.currentColumnsData = arrayToMap(cloneDeep(columns), 'columnId');
        this.columns = columns.map(columnData => {
            const columnInstance = new this.column(columnData);

            for (const card of this.cards) {
                const cardColumns = card.getColumnsIds();

                if (cardColumns.includes(columnInstance.getColumnID())) {
                    columnInstance.addCard(card);
                }
            }

            return columnInstance;
        });
    }

    public getVisibleColumns() {
        let columnsCopy = [...this.columns];

        if (isValidFunction(this.columnsFilterFunction)) {
            columnsCopy = columnsCopy.filter(this.columnsFilterFunction);
        }

        return uniqBy(columnsCopy, (c) => c.getColumnID());
    }

    public setGlobalFilterColumns(columnFilterFunction: TColumnsFilterFunction<ColumnData>) {
        this.columnsFilterFunction = columnFilterFunction;
        this.columnsListUpdated.next();
    }

    public getColumnsOfCard(card: IKanbanCard): TKanbanColumnArray {
        const cardColumnsIds = card.getColumnsIds();
        return this.columns.filter(column => cardColumnsIds.includes(column.getColumnID()))
    }

    public addCardsFilter(filterId: TCardFilterId, ...filterParams: any[]): void {
        const cardFilter = this.getCardFilter(filterId)

        if (!cardFilter) {
            console.warn(`Kaban column filter: filter not found ${filterId}`)
            return;
        }

        this.activeCardFilters.add(filterId);

        this.columns.forEach(column => {
            column.addCardFilter(cardFilter, ...filterParams);
        });

        this._columnChanged.next({ columnsChanged: [] });
    }

    public removeCardsFilter(filterId: TCardFilterId): void {
        const cardFilter = this.getCardFilter(filterId)

        if (!cardFilter) {
            console.warn(`Kaban column filter: filter not found ${filterId}`)
            return;
        }

        this.activeCardFilters.delete(filterId);

        this.columns.forEach(column => {
            column.removeCardFilter(cardFilter.filterId);
        });

        this._columnChanged.next({ columnsChanged: [] });
    }

    private getCardFilter(filterId: TCardFilterId): ICardFilter {
        return isInEnum(EKanbanStandardCardFilters, filterId as EKanbanStandardCardFilters)
            ? kanbanStandardCardFilters[filterId]
            : this.customCardFilters[filterId];
    }

    public registryCustomCardFilter(cardFilterConfig: ICardFilter): ICardFilter {
        return this.customCardFilters[cardFilterConfig.filterId] = cardFilterConfig;
    }

    public registryCustomCardSort(cardSortConfig: ICardSorter): ICardSorter {
        return this.customCardSorts[cardSortConfig.sortId] = cardSortConfig;
    }

    getCustomCardFilterList(): ICardFilter[] {
        return values(this.customCardFilters);
    }

    getActiveCustomCardFilterList(): ICardFilter[] {
        return [...this.activeCardFilters].map(filterId => this.getCardFilter(filterId));
    }

    public addCardsSort(sortId: EKanbanStandardCardSort | string): void {
        this.currentCardSort = this.getCardSort(sortId);

        if (!this.currentCardSort) {
            console.warn(`Kaban column sorting: sort not found ${sortId}`)
            return;
        }

        this.columns.forEach(column => {
            column.addCardSort(this.currentCardSort);
        });

        this._columnChanged.next({ columnsChanged: [] });
    }

    private getCardSort(sortId: TCardSortId): ICardSorter {
        return isInEnum(EKanbanStandardCardSort, sortId as EKanbanStandardCardSort)
            ? kanbanStandardCardSort[sortId]
            : this.customCardSorts[sortId];
    }

    public applyCardsFilterForColumn(column: IKanbanColumn, filterId: EKanbanStandardCardFilters | string, ...filterParams: any[]): void {
        const cardFilter = this.getCardFilter(filterId);

        column.addCardFilter(cardFilter, ...filterParams);

        this._columnChanged.next({ columnsChanged: [column] });
    }

    public applyCardsSortForColumn(column: IKanbanColumn, sortId: EKanbanStandardCardSort | string): void {
        const cardSort = this.getCardSort(sortId);

        column.addCardSort(cardSort);

        this._columnChanged.next({ columnsChanged: [column] });
    }

    protected changeCardColumn(event: IKanbanCardMovedEvent<CardData, ColumnData>) {
        event.sourceColumn.removeCard(event.card);
        event.card.removedFromColumn(event.targetColumn.getColumnID());

        event.targetColumn.addCard(event.card);
        event.card.addedToColumn(event.targetColumn.getColumnID());

        this._cardChanged.next({ cardsChanged: [event.card] });
        this._columnChanged.next({ columnsChanged: [event.sourceColumn, event.targetColumn] });
    }

    public addCardToColumn(
        card: IKanbanCard<CardData>,
        targetColumn: IKanbanColumn<ColumnData>,
    ): void {
        targetColumn.addCard(card);
        card.addedToColumn(targetColumn.getColumnID());

        this._cardChanged.next({ cardsChanged: [card] });
        this._columnChanged.next({ columnsChanged: [targetColumn] });
    }

    public removeCardFromColumn(
        card: IKanbanCard<CardData>,
        targetColumn: IKanbanColumn<ColumnData>,
    ): void {
        targetColumn.removeCard(card);
        card.removedFromColumn(targetColumn.getColumnID());

        this._columnChanged.next({ columnsChanged: [targetColumn] });
    }

    public async moveCard(event: IKanbanCardMovedEvent<CardData, ColumnData>): Promise<void> {
        const { card, sourceColumn, targetColumn } = event;
        const isDiffColumn: boolean = sourceColumn.getColumnID() !== targetColumn.getColumnID();

        event.card.setIsLoading(true);

        if (isDiffColumn) {
            this.changeCardColumn(event);
        }

        const result = await this.onCardMoved(event);

        if (result) {
            card.setLastUpdate(Date.now());
        }

        if (isDiffColumn && !result) {
            this.changeCardColumn({
                card: event.card,
                sourceColumn: event.targetColumn,
                sourceTopCard: event.targetTopCard,
                sourceBottomCard: event.targetBottomCard,

                targetColumn: event.sourceColumn,
                targetTopCard: event.sourceTopCard,
                targetBottomCard: event.sourceBottomCard,
            });
        }

        event.card.setIsLoading(false);
    }

    public getFilters(): TCardFilterArray {
        return [
            kanbanStandardCardFilters[EKanbanStandardCardFilters.recentlyUpdated],
            ...Object.values(this.customCardFilters),
        ];
    }

    public getSorts(): TCardSorterArray {
        return [
            kanbanStandardCardSort[EKanbanStandardCardSort.lastUpdate],
            kanbanStandardCardSort[EKanbanStandardCardSort.alphabeticalOrder],
            ...Object.values(this.customCardSorts),
        ];
    }

    public findColumnByID(columnId: string): IKanbanColumn<ColumnData> | undefined {
        return this.columns.find(column => column.getColumnID() === columnId) as IKanbanColumn<ColumnData>;
    }

    onCardClick(card: IKanbanCard<CardData>, column: IKanbanColumn<ColumnData>, event: MouseEvent | PointerEvent): void {
        const providers: StaticProvider[] = [
            {
                provide: this.service,
                useValue: this,
            },
            {
                provide: this.card,
                useValue: card,
            },

            {
                provide: this.column,
                useValue: column,
            }
        ];

        if (this.useColmeiaWindow) {
            event.stopPropagation();
            this.openWindow(providers, card);
        } else {
            this.openDialog(providers);
        }
    }

    private openDialog(providers: StaticProvider[]) {
        const injector = Injector.create({
            providers
        });

        this.dialogSvc.open(this.dialogComponent, {
            injector
        });
    }

    private openWindow(providers: StaticProvider[], card: IKanbanCard<CardData>) {
        const { title, group } = this.getWindowInfo(card);

        this.colmeiaWindowSvc.open(this.dialogComponent, {
            windowIdentifier: card.getCardID(),
            title,
            group,
            injectProviders: providers
        });
    }

    isActiveFilter(filterId: string) {
        const activeFilters = this.getActiveCustomCardFilterList();
        return activeFilters.some(filter => filter.filterId === filterId)
    }

    updateColumnsListUI() {
        this.columnsListUpdated.next();
    }

    updateColumnsUI() {
        this._columnChanged.next({ columnsChanged: [] });
    }

    updateCardsUI() {
        this._cardChanged.next({ cardsChanged: [] });
    }

    updateCardData(card: IKanbanCard, data: Partial<CardData>) {

        const currentData = card.getData();
        objectShallowReplace(currentData, data);
        card.updateData(currentData);

        const current = this.currentCardsData.get(card.getCardID())
        objectShallowReplace(current, data)

    }

}

export type TKanbanRulesBase = typeof KanbanService;

