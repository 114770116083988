import { ITransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { INextGenBotServer } from "../../../bot/bot-model";
import { ESubscribedTo } from "../../../BPM/common/common-bpm";
import { TBpmMarketingNsTypes } from "../../../BPM/marketing/bpm-marketing.model";
import { TReferencedObject } from "../../toolbar/config-toolbar.types";
import { MktHostNode } from "./mkt-host-node";

export class MktBotElementHost extends MktHostNode {
    constructor(ns: INextGenBotServer) {
        super(ns)
    }

    static create(ns: INextGenBotServer): MktBotElementHost {
        return new MktBotElementHost(ns)
    }

    getHostedType(): TBpmMarketingNsTypes {
        return (this.getNonSerializable() as INextGenBotServer).botLevel as TBpmMarketingNsTypes;
    }

    isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return true;
    }

    public mustUpdateConnections(connectionType: ESubscribedTo): boolean {
        return false;
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return true
    }

    thereIsMoreChildrenNodesToCreate(): boolean {
        return false;
    }

    public isPredicateDrawable() {
        return true;
    }
}
