import { Component, Inject } from '@angular/core';
import { MMconstant, MultimediaInstance, MultimediaObject } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { EPartialMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { EHexagonUploaderMode, HexagonUploaderParameterDefaults } from 'app/components/foundation/hexagon-uploader/hexagon-uploader.model';
import { HexagonUploaderHandler } from 'app/handlers/hexagon-uploader-handler';
import { EHexagonFormat, EHexagonSizes } from 'app/handlers/hexagono.handler';
import { GeneralFormFieldInjectionToken, GeneralFormFieldRenderer, IGeneralFormFieldData } from 'app/model/general-form.model';
import { HardwareLayerService } from 'app/services/hardware/hardware-layer.service';
import { MultimediaService } from 'app/services/multimedia.service';
import { GeneralFormFieldService } from '../services/general-form-field.service';

@Component({
    selector: 'app-general-form-image-field',
    templateUrl: './general-form-image-field.component.html',
    styleUrls: [
        './general-form-image-field.component.scss',
    ]
})
export class GeneralFormImageFieldComponent extends GeneralFormFieldRenderer<null> {
    hexagonUploaderHandler : HexagonUploaderHandler;

    constructor(
        generalFormFieldService: GeneralFormFieldService,
        @Inject(GeneralFormFieldInjectionToken) injectedToken: IGeneralFormFieldData,
        private multimediaSvc : MultimediaService,
        private hardwareLayerSvc: HardwareLayerService
    ) { super(generalFormFieldService, injectedToken); }

    async ngOnInit() {
        super.ngOnInit();
        this.hexagonUploaderHandler = this.generateHexagonUploaderHandler();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    generateHexagonUploaderHandler = () =>{
        let multimediaObject = MultimediaObject.getNewMultimediaObjectFromIdMedia(this.field.value)
        return HexagonUploaderHandler.createNonSerializable(
            {
                ...HexagonUploaderParameterDefaults,
                size: EHexagonSizes.xs,
                idTag: MMconstant.tag.photo,
                fileMode: true,
                multimediaObject: multimediaObject,
                mode: this.isEdit ? EHexagonUploaderMode.Upload : EHexagonUploaderMode.View ,
                format: EHexagonFormat.RoundedSquare,
                idMediaEditing: isValidRef(this.field.value) ? this.field.value : null,
                onFileSelected: (mn:MultimediaInstance)=>{
                    this.multimediaSvc.genericUploadFile(mn, MMconstant.tag.photo);
                    this.field.value = mn.getI360Media().idMedia;

                    if(this.canDisplayFormGroup)
                        this.fControl.setValue(this.field.value);
                },
                onMediaDeleted: ()=>{
                    this.field.value = '';

                    if(this.canDisplayFormGroup)
                        this.fControl.setValue('');
                },
            }

        );
    }

    openImgModal(){
        if(!this.isEdit){
            this.hardwareLayerSvc.getMediaViewer().view([this.getMMInstance()], 0, this.field.prompt);
        }
    }

    getMMInstance():MultimediaInstance{
        let mm : MultimediaInstance = MultimediaObject.getNewMultimediaObjectFromIdMedia(this.field.value).getAllMultimediaInstance()[0];
        mm.setMimeType(EPartialMimeTypes.Image)
        return mm;

    }

}
