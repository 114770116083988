import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { EAppBaseURL, getEnvironmentByAppUrl } from '@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model';
import { DEPLOYED_SERVICE_ID } from 'app/components/dashboard/dashboard-deployed-services/dashboard-deployed-service-edit/dashboard-deployed-service-edit.component';
import { ColorThemeBuilderService } from 'app/services/color-theme-builder.service';
import { EmbeddedChatService } from 'app/services/embedded-chat.service';
import { Observable } from 'rxjs';
import { ColorThemeBuilderEditDialog } from '../color-theme-builder-edit/color-theme-builder-edit-dialog.component';
import { SubscriptionGroup } from 'app/model/client-utility';
import { BrandTheme } from '../brand-theme.functions';

@Component({
  selector: 'app-webchat-preview-viewer',
  templateUrl: './webchat-preview-viewer.component.html',
  styleUrls: ['./webchat-preview-viewer.component.scss']
})
export class WebchatPreviewViewer implements OnInit {

  public isLoading: boolean = true;
  colmeiaChat: any;

  @ViewChild('webChatContainer', { static: true })
  webChatContainer: ElementRef<HTMLDivElement>;

  private subscriptions = new SubscriptionGroup();

  constructor(
    @Inject(DEPLOYED_SERVICE_ID)
    public idService: string,
    private embededSvc: EmbeddedChatService,
    private renderer: Renderer2,
    private el: ElementRef,
    private colorThemeBuilderComponent: ColorThemeBuilderEditDialog,
    private colorBuilderThemeService: ColorThemeBuilderService,

  ) { }

  async ngOnInit() {
    if (!this.idService) return;

    await this.embededSvc.initColmeiaChatScript(this.el, this.renderer);

    this.generateWebchat();
    this.isLoading = false;
  }


  getCurrentBaseUrl() {
    const url = new URL(window.location.href);
    const baseUrl = <EAppBaseURL>url.origin;
    return baseUrl;
  }

  async generateWebchat() {

    const url = this.getCurrentBaseUrl();
    const environment = getEnvironmentByAppUrl(url);

    try {

      this.colmeiaChat = new (window as any).ColmeiaChat({
        serviceId: this.idService,
        host: this.webChatContainer.nativeElement,
        environment,
        mode: 'inline',
        style: {
          '--chat-box-height': '100%',
          'height': '100%'
        },
      });

      let htmlDoc: HTMLElement;

      this.colmeiaChat.addEventListener('error', console.error);

      this.subscriptions.from(this.colorThemeBuilderComponent.onThemeUpdated$)
        .subscribe((theme) => {
          BrandTheme.applyThemeOnElement(htmlDoc, theme);
        });

      this.colmeiaChat.addEventListener('ready', () => {

        const iframe = (this.colmeiaChat.element.shadowRoot.children[1].children[0].children[0].children[0] as HTMLIFrameElement);

        const current = iframe.onload;
        iframe.onload = (ev) => {

          current.call(iframe, ev);

          htmlDoc = iframe.contentDocument.documentElement;

          BrandTheme.applyThemeOnElement(htmlDoc, this.colorThemeBuilderComponent.getEditedTheme());

        }
      });


    } catch (error) {
      console.error(error);
    }
  }

}
