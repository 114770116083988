export const constRosetta = {
    language :  {
        defaultEntry: '1@22',
        portuguese: '1@22',
        english: '2@22',
        greek: '3@22',
        german: '4@22',
        chinese: '5@22',
        spanish: '6@22',
    },
};


