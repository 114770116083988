import { ENonSerializableObjectType, IdCanonical, IdSchema } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { TGlobalUID } from '../../core-constants/types';
import { IBPMLGPDActionOnBotAction, TBPM_LGPDActions } from '../BPM/bpm-action-model';
import { ICRMCTicketStatusInteractionInsert } from '../crm/crm-entities';
import { IExecutableAction } from './bot-asset-model';
import { EMacroProcess } from './bot-interfaces';
import { EBotActionType } from './new-bot-action';

// ACTION
export type TBotActionArray = Array<IBotActionAsset>;
export type TKBAssetArray = TBotActionArray;

// Para o modelo do diagrama
export interface IBotActionAssetContainer {
    action?: IBotActionAsset,
    subElementId?: string
}
export type TIBotActionAssetContainerArray = Array<IBotActionAssetContainer>;

export interface IOutputConfig {
    size: number;
    delimiterCount: number;
    delimiters: Array<string>;
}

export interface IBotActionAsset extends IExecutableAction {
    type: EBotActionType;
    idElement?: string; //id do NS que está apontando (menu, bot, ilha, etc..)

    LGPDEvaluator?: TBPM_LGPDActions;  // DEPRECADO
    macroProcess?: EMacroProcess // para passagem na execução do bot, não para ser configurado
    dontExecuteFinalizationEvent?: boolean;

    useFirstUseInput?: boolean; // APENAS PARA NLP
    firstMessageIdCanonical?: IdCanonical; // APENAS PARA NLP

    hasOutputFormat?: boolean;
    outputFormatConfig?: IOutputConfig;
    idFunctionPre?: IdDep<ENonSerializableObjectType.userFunction>
    idFunctionPos?: IdDep<ENonSerializableObjectType.userFunction>
};


export interface IBotActionAssetTemplate extends IBotActionAsset {
    type: EBotActionType.goIslandOnTemplate;
    finishBotIfNotAvailableIsland?: boolean;
}

export interface IBotActionLGPD extends IBotActionAsset {
    type: EBotActionType.LGPD;
    lgpdConfig: IBPMLGPDActionOnBotAction;
}



export interface ICRMTransferOptions {
    enabled?: boolean;
    idIsland: string;
    idAgentOwner?: TGlobalUID;
}


export interface IBotActionCRMTicketCreate extends IBotActionAsset {
    /**
     * Pacote de itens
     */
    idElement: string;
    /**
     * Atualização dos status do ticket
     */
    statusInsert: ICRMCTicketStatusInteractionInsert;

    transfer: ICRMTransferOptions;
}

export const initialAction: IBotActionAsset = {
    idAsset: undefined,
    type: undefined,
    idElement: undefined,
    contentArray: []
}
