import { TGlobalUID } from '../../business/constant';
import { EDelivery360Action } from '../../comm-interfaces/interaction-interfaces';
import { TContentAssetArray } from '../bot/bot-content-model';
import { TPropertyKeyArray } from '../metadata/metadata-util-interfaces';
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from '../non-serializable-id/non-serializable-id-interfaces';

export interface ICreateNewActiveCallContact {
    name: string;
    email: string;
}

export enum EActiveCallTypeOfContact {
    active = 'act', receptive = 'rec'
}


export interface INewAgentCallBatch extends INonSerializableHeader {
    nsType: ENonSerializableObjectType.activeCallBatch
    idCampaignAction?: TGlobalUID
}
export interface INewAgentCallBatchServer extends INonSerializable, INewAgentCallBatch {
    nsType: ENonSerializableObjectType.activeCallBatch
}

export interface IActiveCallCustomMessage {
    messege: TContentAssetArray;
}

export interface IPreDefinedCampaignMessage {
    templateLocalCanonicals: TPropertyKeyArray;  // CanonicalsLocais do TEMPLATE
    campaingMessageVariables: TPropertyKeyArray; // Mensagens das campanhas
}

export interface IActiveCampaignMessage extends IPreDefinedCampaignMessage {
    customMessage: TContentAssetArray; // Messagens custom
}

export enum ESendActiveMethod {
    templateOnly = 'tempOnly', // templateLocalCanonicals
    campaignOnly = 'conly', // campaignMessageVariables - (campaignMessageVariables () templateLocalCanonicals)
    customMessage = 'cuMess' // templateLocalCanonicals
}
export interface INewContact extends INonSerializableHeader {
    target: string;
    idAvatarContact?: string;
    isPartial?: boolean;
}
export interface INewAgentCallContact extends INewContact {
    channel?: EDelivery360Action;
    sentAt?: number;  // Quando foi enviado
    idCampaigAction?: string;
    sendMethod?: ESendActiveMethod;
    activeMessage?: IActiveCampaignMessage;
    scheduleBeginning?: number; // Enviar a mensagem: [Picker Data / Hora]  =>  Quer dizer que quando criar o NS já será chamado a função
    responseToMe?: boolean; // Direcione a resposta do cliente para mim
    idRTFBody?: string;
}

export interface INewAgentCallContactServer extends INonSerializable, INewAgentCallContact {
    idSocialContext2FA?: TGlobalUID; // apenas para ser usado pelo 2FA
    idAvatar2FA?: TGlobalUID;
    idWaitingEvent?: string;
}

export interface ISearchActiveCallResult {
    target: string;
    channel: EDelivery360Action;
    name: string;
    idAvatar: TGlobalUID;
    email: string;
    idSocialKey: string,
    businessIDList?: string[]
}

export interface INewAgentCallContactItem extends INonSerializable, ISearchActiveCallResult { }

export type TISearchActiveCallResultArray = Array<ISearchActiveCallResult>;
