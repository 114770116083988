
import { isValidMedia } from "@colmeia/core/src/rules/mm-functions";
import { getInitialActionWithId } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { compileText, getVariablesOfTemplate, regexDoubleBrackets, TIEditorVariableArray } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { IQuickReplyAsset } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { ColmeiaTemplateComponents, ICTAConfigOnAsset } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { WhatsApp } from '@colmeia/core/src/shared-business-rules/social-media/whatsapp-interface';
import { empty, isValidString, replaceString, typedClone } from "@colmeia/core/src/tools/barrel-tools";
import { getUniqueStringID, isValidArray, isValidRef } from "../../tools/utility";
import { isValidBPMEvaluators } from "../BPM/bpm-functions";
import { ICompiledTemplateWithVariables } from "../metadata/metadata-util-interfaces";
import { eBotActionDB } from "./bot-action-config-db";
import { IBotActionAsset, TBotActionArray } from "./bot-action-model";
import { IBasicAsset, KAssetType, KBAssetType } from "./bot-asset-model";
import { emptyContentBasicAsset, IContentBasicAsset, TContentAssetArray } from "./bot-content-model";
import { IBotEvent } from "./bot-event-model";
import { EBotActionType } from "./new-bot-action";

export function isValidBasicAsset(asset: IBasicAsset): boolean {
    return isValidRef(asset) && isValidRef(asset.type)
}

export function isValidContent(content: IContentBasicAsset | undefined): boolean {
    const hasContent = isValidString(content?.content) || isValidMedia(content?.media);
    return isValidBasicAsset(content) && (hasContent && isValidString(content?.idAsset))
}


export function getBlankContentAssetFromCompiledTemplate(variablesTemplate: ICompiledTemplateWithVariables): IContentBasicAsset {
    return {
        idAsset: '_GEN_' + getUniqueStringID(10),
        type: KBAssetType.content,
        content: '',
        variablesTemplate
    }
}

export function isValidContentArray(contentArray: TContentAssetArray): boolean {
    return isValidArray(contentArray) && contentArray.every((c) => {return isValidContent(c)});
}

export function isActionWithTypeAndWithoutMandatoryOtherFields(action: IBotActionAsset) {
    return isValidBasicAsset(action) && !isValidAssetAction(action)
}

export function isValidAssetAction(action: IBotActionAsset): boolean {
    if(! isValidBasicAsset(action)) {
        return false;
    }
    const oktoNotHavElement: boolean = ((isValidRef(eBotActionDB[action.type]) && !eBotActionDB[action.type].hasIDElemt));
    return isValidRef(action.idElement) || oktoNotHavElement;
}

export function isValidAssetActionArray(actions: TBotActionArray): boolean {
    return isValidArray(actions) && actions.every(act => isValidAssetAction(act));
}

export function hasConditionalContentEvaluator(asset: IContentBasicAsset): boolean {
    return asset?.isConditionalContent && isValidBPMEvaluators([asset.displayCondition])
}

export function checkAssetForBrokenVariables(asset: IContentBasicAsset, variables: TIEditorVariableArray): boolean {
    // verifica se possui variáveis quebradas
    // elas quebram quando renomeamos um canônico, pois o nome não é alterado dentro do IContentBasicAsset
    try {
        // estoura um exception quando encontra variável quebrada
        compileText(
            asset.content,
            variables
        );

        return true;
    }
    catch {
        return false;
    }
}

export function fixBrokenVariablesOnAssetContent(asset: IContentBasicAsset, variables: TIEditorVariableArray): string {
    let content = asset.content;
    const variableMatches = getVariablesOfTemplate(content, regexDoubleBrackets);

    if (!variables.length) {
        return content;
    }

    for (let i = 0; i < variableMatches.length; i++) {
        const idProperty = asset.variablesTemplate.variables[i].idProperty;

        const oldName = variableMatches[i];
        const updatedName = variables.find((m) => { return m.idProperty === idProperty }).variable;

        if (oldName !== updatedName) {
            content = replaceString(content, oldName, updatedName);
        }
    }

    return content;
}

export const initialEvent: IBotEvent = {
    idAsset: undefined,
    type: undefined,
    eventAction: undefined,
    eventText: undefined,
    contentArray: [],
    preCondition: {
        idTreeVisited: undefined,
        checkType: undefined,
    },
}

export function getBotInitialEvent(override: Partial<IBotEvent> = {}): IBotEvent {
    return typedClone({
        ...initialEvent,
        idAsset: getUniqueStringID(10),
        ...override,
    })
}

export function IBasicAsset(override: Partial<IBasicAsset> & { type: KAssetType }): IBasicAsset {
    return {
        idAsset: getUniqueStringID(10),
        ...override,
    }

}

export function initCTAAsset(): ICTAConfigOnAsset {
    return {
        type: WhatsApp.Message.Interactive.Type.CallToActionURL,
        header: initCTAAsset.header(),
        body: initCTAAsset.body(),
        footer: initCTAAsset.footer(),
        buttons: [
            {
                buttonText: '',
                action: getInitialActionWithId(EBotActionType.quickVisitWebsite) as IQuickReplyAsset,
            },
        ],
    }
}


export namespace initCTAAsset {
    export function component(): ColmeiaTemplateComponents.Base {
        return {
            shouldShow: false,
            content: emptyContentBasicAsset(),
        };
    }

    export function body(): ColmeiaTemplateComponents.Base {
        return {
            ...component(),
            shouldShow: true,
        };
    }

    export function header(format: WhatsApp.Message.Template.Structure.Format = empty): ColmeiaTemplateComponents.Header.Basic {
        return {
            ...component(),
            format,
        };
    }

    export function footer(): ColmeiaTemplateComponents.Footer.Basic {
        return component();
    }

}