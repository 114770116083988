import { TSerializableArray } from "@colmeia/core/src/persistency/uber-cache";
import { MessageInstanceHandler, IMessageInstanceParameter } from "./message-instance-handler";

export class NoOptionsMessageHandler extends MessageInstanceHandler {

    constructor(messageParameter: IMessageInstanceParameter){
        super(messageParameter);
    }

    getMoreOptions(): TSerializableArray {
        return [];
    }
}
