import { Directive, Input, ComponentRef, ViewContainerRef, TemplateRef } from '@angular/core';
import {ColmeiaSpinnerComponent} from "../../legacy/spinner/colmeia-spinner.component";


@Directive({
    selector: '[cmSpinner]'
})
export class ColmeiaSpinnerDirective {
    @Input() cmSpinnerDiameter: number;
    loadingComponent: ComponentRef<ColmeiaSpinnerComponent>;

    constructor(
        private vcRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) {}

    @Input()
    set cmSpinner(loading: boolean) {
        this.vcRef.clear();
        if (loading) {
            this.loadingComponent = this.vcRef.createComponent(ColmeiaSpinnerComponent);
            if(this.cmSpinnerDiameter)
                this.loadingComponent.instance.diameter = this.cmSpinnerDiameter;
        } else {
            this.vcRef.createEmbeddedView(this.templateRef);
        }
    }
}
