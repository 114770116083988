import {ChainedInteraction} from './chained-interaction';
import { TGlobalUID, constant } from '../business/constant';
import { Participant } from '../business/participant';
import { Interaction } from './interaction';
import { InteractionType } from './interaction-type';
import { IShareInteractionJSON } from '../comm-interfaces/interaction-interfaces';
import { isValidArray } from '../tools/utility';


export class ShareInteraction extends ChainedInteraction {

    protected constructor(primaryID: TGlobalUID, participant: Participant, interactionParent: Interaction) {
        super(primaryID, participant, 
            InteractionType.staticFactory(constant.interactionType.sharing.shareInteraction), interactionParent);
    };

    public rehydrate(json: IShareInteractionJSON): void {
        super.rehydrate(json);
    };


    public static factoryMessage(json: IShareInteractionJSON, participant: Participant = null): ShareInteraction {
        let share: ShareInteraction = <ShareInteraction>Interaction.searchCachedInteraction(json.primaryID);
        if (share == null)
            share = new ShareInteraction(json.primaryID, 
                        participant? participant: Participant.staticFactory(json.participant.primaryID),
                        Interaction.staticFactory(json.idInteractionParent));
        share.rehydrate(json);
        return share;
    };

    

    public static staticFactory(idFeedback: TGlobalUID): ShareInteraction {
        return <ShareInteraction>Interaction.staticFactory(idFeedback);
    };

    public static getNewShareInteraction(participant: Participant, interactionParent: Interaction): ShareInteraction{
        return new ShareInteraction(null, participant, interactionParent);
    };

  

};
