import { ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { kebabCase } from "lodash";
import { constant, TGlobalUID } from "../../../business/constant";
import { Serializable } from "../../../business/serializable";
import { IUniversalJSON } from "../../../comm-interfaces/barrel-comm-interfaces";
import { TSerializableHeader } from "../../../serializable/header";
import { getReadableUniqueID, isInvalidArray, isValidRef, mapValues, pascalCase, SafeId, values } from "../../../tools/utility";
import { IMapCurrentDashboardBusiness, initDefaultColmeiaTag, IServerColmeiaTag, mapCurrentDashboard } from "../../colmeia-tags/tags";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { EDefineDashboard, EScreenGroups } from "../../visual-constants";
import { IVisualElementUpsertData } from "../../visual-element/visual-element-req-resp";
import { ISerializableYAML } from "../../visual-element/visual-element.types";
import { serializableInstanceToYAML } from "../../visual-element/visual-element.utils";
import { notImplementedSharedFunction } from "../shared-services.basic-functions";
import { NSSharedService } from "./ns.shared.service";

export namespace InsertMenu {
    export interface Entity {
        name: string;
        description: string;
        icon: string;
        primaryId: TGlobalUID;
    }
    export interface Input extends Entity {
        isMock?: boolean;
    }
    export interface Output {
        tag: IServerColmeiaTag;
        element: ISerializableYAML;
    }
}
export namespace HasElement {
    export interface Input {
        primaryId: TGlobalUID;
    }
    export type Output = boolean;
}

export namespace GetVisualElements {
    export interface Input {
        primaryIds: TGlobalUID[];
    }
    export interface Output {
        elements: { [primaryId in string]: ISerializableYAML }
    }
}
export namespace GetSerializables {
    export interface Input {
        elements: TSerializableHeader[];
    }
    export interface Output {
        elements: { [primaryId in string]: IUniversalJSON }
    }
}
export namespace InsertMenuItem {
    export interface Entity {
        name: string;
        title: string;
        icon: string;
        menuPrimaryId: string;
    }
    export interface Input extends Entity {
        isMock?: boolean;
    }
    export interface Output {
        element: ISerializableYAML;
    }
}
class Service {
    
    getSerializables(input: GetSerializables.Input): Promise<GetSerializables.Output> {
        notImplementedSharedFunction(input);
        // 
    }

    insert(input: IVisualElementUpsertData): Promise<void> {
        notImplementedSharedFunction(input);
    }

    incrementCache(): Promise<string> {
        notImplementedSharedFunction([]);
    }

    trace(name: string): Promise<void> {
        notImplementedSharedFunction(name);
    }

}

@ToStatic
export class MenuSharedService extends toStatic(Service) {

    public static async getVisualElements(input: GetVisualElements.Input): Promise<GetVisualElements.Output> {
        const { elements } = await MenuSharedService.getSerializables({
            elements: input.primaryIds.map(serializableID => ({ serializableID, objectTypeID: constant.objectType.visualElement }))
        });

        return {
            elements: mapValues(elements, element => {
                const serializable = Serializable.factoryMessage(element)
                const yaml = serializableInstanceToYAML(serializable);
                return yaml
            })
        }
        // 
    }


    public static async getVisualElement(primaryId: string): Promise<ISerializableYAML | undefined> {
        try {
            const { elements } = await MenuSharedService.getVisualElements({
                primaryIds: [primaryId]
            });
    
            return elements[primaryId];
        } catch {
        }
        // 
    }

    public static async hasElement(input: HasElement.Input): Promise<HasElement.Output> {
        try {
            const previousElement = await MenuSharedService.getVisualElement(input.primaryId)
            return isValidRef(previousElement);
        } catch {
            return false
        }
    }

    /**
     * Insert main menu
     * @param input
     * @example
     * MenuSharedService.insertMenu({
     *     primaryId: 'ConversationalCommerce',
     *     description: 'Divulgue e venda seus produtos nos meios de comunicação mais populares com a melhor tecnologia.',
     *     name: 'Conversational Commerce',
     *     icon: './assets/icons/dashboard/analytics.svg',
     * })
     */
    public static async insertMenu(input: InsertMenu.Input) {
        
        if (!input.isMock) {
            const hasElement = await MenuSharedService.hasElement({
                primaryId: input.primaryId
            })

            if (hasElement) {
                throw new Error('Element already inserted');
            }
        }

        const element: ISerializableYAML = {
            primaryID: input.primaryId,
            fields: [
                {
                    id: constant.serializableField.description,
                    text: input.description,
                },
                {
                    id: constant.serializableField.auxiliars.aux17,
                    text: input.name,
                },
                {
                    id: constant.serializableField.auxiliars.aux18,
                    text: input.icon,
                },
            ],
            screenGroups: [
                {
                    screenGroup: EScreenGroups.MenuDashboard,
                },
            ],
        };

        const tag = await MenuSharedService.getOrCreateTag({ idTag: input.primaryId, isMock: input.isMock });

        if (!input.isMock) await MenuSharedService.insert({
            elements: [element],
        });

        return {
            tag,
            element,
        }
    }

    public static async getOrCreateTag({ idTag, isMock }: { idTag: string; isMock?: boolean }): Promise<IServerColmeiaTag> {
        const newTag = initDefaultColmeiaTag(idTag);
        if (isMock) {
            newTag.idNS ??= getReadableUniqueID(); 
            return newTag;
        }
        const { nss: [previousTag] } = await NSSharedService.search({
            nsType: ENonSerializableObjectType.colmeiaTags,
            nName: newTag.nName,
        });

        if (previousTag) return previousTag;

        const toInsertTag = previousTag ?? newTag;

        const { ns: tag } = await NSSharedService.insert({
            ns: toInsertTag,
            nsType: ENonSerializableObjectType.colmeiaTags
        });

        return tag;
    }

    /**
     * @example
     * MenuSharedService.insertMenuItem({
     *     name: "Catalogo",
     *     icon: "call",
     *     menuPrimaryId: "ConversationalCommerce"
     * })
     * @param @type InsertMenuItem.Input
     * @returns
     * @type InsertMenuItem.Output
     * @example
     * {
     *   element: [
     *       {
     *           primaryID: "ConversationalCommerceCatalog",
     *           fields: [
     *               {
     *                   id: 1,
     *                   text: "Catálogo"
     *               },
     *               {
     *                   id: 18,
     *                   text: "call"
     *               }
     *           ],
     *           screenGroups: [
     *               {
     *                   screenGroup: "ConversationalCommerce",
     *                   idMenu: "conversational-commerce-catalog"
     *               }
     *           ],
     *           route: "catalog"
     *       }
     *   ]
     * }
     */
    public static async insertMenuItem(input: InsertMenuItem.Input): Promise<InsertMenuItem.Output> {
        const primaryID = pascalCase(`${input.menuPrimaryId}_${input.name}`);

        const element: ISerializableYAML = {
            primaryID,
            fields: [
                {
                    id: constant.serializableField.name,
                    text: input.title,
                },
                {
                    id: constant.serializableField.icon,
                    text: input.icon,
                },
            ],
            screenGroups: [
                {
                    screenGroup: input.menuPrimaryId,
                    idMenu: kebabCase(primaryID),
                },
            ],
            route: kebabCase(input.name),
        };

        if (!input.isMock) await MenuSharedService.insert({
            elements: [element],
        });
        
        return {
            element,
        }
    }


    public static async getMenuMock() {
        const menu = await MenuSharedService.insertMenu({
            primaryId: 'MenuMock',
            description: 'Description example',
            name: 'Some Mock Menu',
            icon: './assets/icons/dashboard/analytics.svg',
            isMock: true,
        });
        
        const item = await MenuSharedService.insertMenuItem({
            name: "Item",
            icon: "call",
            title: "O título",
            menuPrimaryId: menu.element.primaryID,
            isMock: true,
        });
    
        return {
            menu,
            item,
        }
    }



    /**
     * Core
     * EDefaultTag
     * EScreenGroups
     * mapCurrentDashboard
     * 
     * Client
     * mapCurrentDashboardClient
     * 
     */
    public static async checkMenu(primaryID: string) {
        const menu = await MenuSharedService.getVisualElement(primaryID)
        if (!menu) return [`Elemento ${primaryID} não inserido`];

        const dashboardItems = values(EDefineDashboard);
        const dashboardItem: EDefineDashboard | undefined = dashboardItems.find(item => item === primaryID);

        const errors: string[] = [];

        if (!dashboardItem) {
            errors.push('Inserir no enum EDefineDashboard')
            return errors;
        }

        const config: IMapCurrentDashboardBusiness = mapCurrentDashboard[dashboardItem];
        
        if (!config.defaultTag) {
            errors.push('Sem tag no enum EDefaultTag');
        }

        const tag = await NSSharedService.getById(config.defaultTag);

        if (!tag) {
            errors.push('Tag não inserida');
        }

        if (!config.screenGroup.name) {
            errors.push(['Sem screen group no enum EScreenGroups', 'inserir', menu.screenGroups?.map(item => item.screenGroup)].join(' '));
        }

        const items = Serializable.findByScreenGroup(config.screenGroup.name)
        
        if (isInvalidArray(items)) {
            errors.push('Sem itens no screen group');
        }

        return errors;
    }
}
