import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ChatActionBarWindowService } from 'app/services/chat-action-bar-window.service';
import { IInternalSupportWindowHandler } from 'app/handlers/chat-bar-interactions/internal-support-window-handler';
import { EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import { HandlerHexagonon, EHexagonSizes } from 'app/handlers/hexagono.handler';
import { DeployedServicesService } from 'app/components/dashboard/dashboard-deployed-services/deployed-services.service';
import { Group } from '@colmeia/core/src/business/group';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
	selector: 'app-internal-support-window',
	templateUrl: './internal-support-window.component.html',
	styleUrls: ['./internal-support-window.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalSupportWindowComponent	extends RootComponent<
	'openGroupInternalCall'
> implements OnInit {

	handler: IInternalSupportWindowHandler;
	hexagonHandler: HandlerHexagonon;
	group: Group;
	loading = true;

	constructor(
		private windowSvc: ChatActionBarWindowService,
		private deployedSvc: DeployedServicesService,
		private cdr: ChangeDetectorRef,
	) {
		super({
			'openGroupInternalCall': gTranslations.fragments.internalSupportWindow,
		});
		this.windowSvc.subscribeToCarrier((val: IInternalSupportWindowHandler) => {
			this.handler = val;
			this.load();
		}, EServiceGroupIntType.startServiceChat);
	}


	ngOnInit() {
		this.load();
	}


	async load() {
		this.loading = true;
		this.cdr.markForCheck();

		this.group = await <Group>await this.deployedSvc.getExtendedParticipantById(this.handler.interaction.getIdInternalSupportGroup());
		this.buildHexagonHandler();

		this.loading = false;
		this.cdr.markForCheck();
	}

	private async buildHexagonHandler(): Promise<void> {
		this.hexagonHandler = HandlerHexagonon.newHandler({
			serializable: this.group,
			size: EHexagonSizes.xsmd
		});
	}

}
