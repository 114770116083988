import { Injectable } from '@angular/core';
import { SecuritySettings } from '@colmeia/core/src/security/security-settings';
import { isSecurityCarriers } from '@colmeia/core/src/rules/filters';
import {TFieldTextArray} from "@colmeia/core/src/comm-interfaces/business-interfaces";
import {IMultimediaObjectJSON} from "@colmeia/core/src/comm-interfaces/multi-media-interfaces";
import {Serializable} from "@colmeia/core/src/business/serializable";
import {fullCloneObject} from "@colmeia/core/src/tools/utility";
import {TSecurityCarries} from "@colmeia/core/src/core-constants/types";
import {MultimediaObject} from "@colmeia/core/src/multi-media/multi-media-object";

export type TIRollbackTransactionArray = Array<IRollbackTransaction>;

export interface IRollbackTransaction {
    idRollbackType: rollbackType;
    serializableTextArray: TFieldTextArray;
    multimediaObject: IMultimediaObjectJSON;
    serializable: Serializable;
    securitySettings: SecuritySettings;
    businessID: string;
};

export enum rollbackType {
    default,
    header_mm_security,
    interaction,
    multimediaObject,
    objectField,
    group,
}

@Injectable()
export class ClientTransactService {


    constructor() {};

    public getRollbackTransaction(serializable: Serializable, idRollbackType: rollbackType): IRollbackTransaction {
        const rollback: IRollbackTransaction = {
            serializable: serializable,
            businessID: serializable.getBusinessID(),
            idRollbackType: idRollbackType,
            multimediaObject: serializable.getMultimediaObject()? serializable.getMultimediaObject().toJSON(): null,
            serializableTextArray: fullCloneObject(serializable.getSerializableTextArray()),
            securitySettings: null
        };

        switch (idRollbackType) {
            case rollbackType.header_mm_security: {
                if (isSecurityCarriers(serializable)) {
                    rollback.securitySettings = (<TSecurityCarries>serializable).getSecuritySettings();
                };
            };
            break;

        };
        return rollback;
    };


    public rollback(rollbackTransaction: IRollbackTransaction): void {
        rollbackTransaction.serializable.replaceSerializableTextArray(rollbackTransaction.serializableTextArray);

        if(rollbackTransaction.multimediaObject)
            rollbackTransaction.serializable.setMultimediaObject(MultimediaObject.factoryMessage(rollbackTransaction.multimediaObject));

        switch (rollbackTransaction.idRollbackType) {

            case rollbackType.header_mm_security: {
                if (isSecurityCarriers(rollbackTransaction.serializable)) {
                    (<TSecurityCarries>rollbackTransaction.serializable).replaceSecuritySettings(rollbackTransaction.securitySettings);
                    rollbackTransaction.securitySettings.addUberCache();
                };
            };

            break;
        };
    };

}


