import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { KAssetType } from "@colmeia/core/src/shared-business-rules/bot/bot-asset-model";
import { getBotEventsByCharacteristic } from '@colmeia/core/src/shared-business-rules/bot/bot-client-functions';
import { eBotEventDB, getBotEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-event-config-db';
import { EBotEventType, IBotCommandText, IBotEvent, TBotEventArray } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { getMatchFunctionToEvents } from '@colmeia/core/src/shared-business-rules/bot/bot-other-client-functions';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { pickTranslations } from "@colmeia/core/src/shared-business-rules/const-text/all-serializables";
import { botEventEnum } from "@colmeia/core/src/shared-business-rules/const-text/enums";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { botEnumTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/bot';
import { getEnumOption } from '@colmeia/core/src/shared-business-rules/enum-db';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { isValidRef, typedClone, values } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { BotEventsHandler } from 'app/model/dashboard/bot/bot-events.handler';
import { IBotEventModalData, IOptionBotAssetArray } from 'app/model/dashboard/bot/dashboard-bot.model';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { BotEventModalComponent } from '../bot-event-modal/bot-event-modal.component';
import { getBotInitialEvent } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-bot-events',
	templateUrl: './bot-events.component.html',
	styleUrls: ['./bot-events.component.scss']
})
export class BotEventsComponent extends RootComponent<
	'events' |
	'event' |
	'actions' |
	'add' |
	'addEvent' |
	'takeActionAfter' |
	'minutes' |
	'attemptsNumber' |
	'command' |
	'selectActionText' |
	'preCondition' |
	'selectPreConditionItem'
> implements OnInit {

	private eventsArr: EBotEventType[] = values(EBotEventType);

	eventNames: { [eventType: string]: string } = {};
	displayableColumns = ['events', 'actions'];

	@Input()
	public schemaVariables: TIVariablesArray = [];

	@Input() handler: BotEventsHandler;

	private eventTypes: IOptionBotAssetArray;
	public allBotEvents = values(EBotEventType);

	constructor(
		private dialogSvc: ColmeiaDialogService,
		private cdr: ChangeDetectorRef,
		private viewContainerRef: ViewContainerRef
	) {
		super({
			...pickTranslations(gTranslations.bot, [
				'events',
				'event',
				'addEvent',
				'takeActionAfter',
				'minutes',
				'attemptsNumber',
				'command',
				'selectActionText',
				'preCondition',
				'selectPreConditionItem'
			]),
			...pickTranslations(gTranslations.common, [
				'add',
				'actions'
			]),
			...botEnumTranslations
		});
	}

	ngOnInit() {
		this.loadEventsNames();
		this.resetEventTypes();
	}

	private loadEventsNames() {
		this.eventsArr.forEach(eventType => {
			const eventName = getEnumOption(eventType, botEventEnum.fields, botEventEnum.idSerializable);
			this.eventNames[eventType] = eventName;
		});
	}

	private resetEventTypes() {
		this.eventTypes = [];

		const allEventTypes = getBotEventsByCharacteristic(getMatchFunctionToEvents(this.handler.branchType));
		allEventTypes.forEach(eventType => {
			const eventName = getEnumOption(eventType, botEventEnum.fields, botEventEnum.idSerializable);
			const eventConfig = getBotEvent(eventType);

			this.eventTypes.push({
				type: eventType,
				text: eventName,
				disabled: this.handler.hasIncludedEventType(eventType) && !eventConfig.canRepeatEvent,
			});
		});
	}

	get botEvents(): TBotEventArray {
		const assetEvents: KAssetType[] = Object.values(EBotEventType) as KAssetType[];
		return this.handler.events.filter(asset => assetEvents.includes(asset.type)).sort((a, b) => this.getEventName(a).localeCompare(this.getEventName(b)));
	}

	getEventName(botEvent: IBotEvent): string {
		return botEvent.type === EBotEventType.commandText
			? `${this.eventNames[botEvent.type]} (${(<IBotCommandText>botEvent).commandText})`
			: this.eventNames[botEvent.type];
	}

	openEventModal(botEvent: IBotEvent, isEdit: boolean = false): void {
		this.resetEventTypes();

		this.dialogSvc.open<BotEventModalComponent, IBotEventModalData>({
			title: this.translations[botEvent.type],
			componentRef: BotEventModalComponent,
			viewContainerRef: this.viewContainerRef,
			panelClass: "average-size",
			dataToComponent: {
				data: {
					isEdit,
					itemLevel: this.handler.branchType,
					botEvent: isValidRef(botEvent) ? typedClone(botEvent) : undefined,
					schemaVariables: this.schemaVariables,
					idKB: this.handler.idKB,
					eventTypes: this.eventTypes,
					onSaveEventCallback: (botEvent: IBotEvent) => {
						this.handler.setEvent(botEvent);
						this.resetEventTypes();
						this.cdr.markForCheck();
					}
				},
			},
		});
	}

	initBotEvent(type: EBotEventType) {
		return getBotInitialEvent({ type });
	}

	removeEvent(event: IBotEvent): void {
		this.handler.removeEvent(event);
		this.resetEventTypes();
		this.cdr.markForCheck();
	}

	public get availableEvents(): (EBotActionType | EBotEventType)[] {
		const allCurrentEvents = this.botEvents.map(e => e.type) as (EBotActionType | EBotEventType)[];
		const availableBotEvents = this.eventTypes.map(et => et.type)
			.filter(eventType => eBotEventDB[eventType].canRepeatEvent || !allCurrentEvents.includes(eventType));

		return availableBotEvents;
	}
}
