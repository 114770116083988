import { IServerFileInfo } from "../comm-interfaces/aux-interfaces";
import { TGlobalUID } from "../core-constants/types";
import { asConst, suggestions } from "../tools/type-utils";
import { Split } from "../tools/utility-types";
import { Explicit } from "../tools/utility/types";
import { Define } from "../tools/utility/types/entities/define";
import { SelectedFile } from "./selected-file";

export enum EUniqueFileType {
    CsvMimes = '.csv, text/csv',
    Json = 'application/json, text/json, */*+json',
}

export enum EPartialMimeTypes {
    Image = 'image',
    Video = 'video',
    Audio = 'audio',
    Application = 'application',
    Text = 'text',
}

export type TMimeTypePrefixes = Split<EMimeTypes, '/'>[0]
; asConst<{ [key in Capitalize<TMimeTypePrefixes>]: TMimeTypePrefixes }>()(EPartialMimeTypes);

export type TPartialMimeTypesArray = Array<EPartialMimeTypes>
export const THUMBNAILABLE_MIMES: TPartialMimeTypesArray = [EPartialMimeTypes.Image, EPartialMimeTypes.Video]

export type MediaMimeTypes = Exclude<EPartialMimeTypes, EPartialMimeTypes.Text>;
export type TSpreedSheets = EMimeTypes.Csv | EMimeTypes.XLS | EMimeTypes.XLSX;
export enum EMimeTypes {
    Jpeg = 'image/jpeg',
    Png = 'image/png',
    Webp = 'image/webp',
    Sticker = 'image/webp;sticker=true',
    Mp4 = 'video/mp4',
    Wav = 'audio/wav',
    Mp3 = 'audio/mp3',
    Ogg = 'audio/ogg',
    mpeg = 'audio/mpeg',
    Webm = 'audio/webm',

    json = 'application/json',
    Csv = 'text/csv',
    PDF = 'application/pdf',
    XLS = 'application/vnd.ms-excel',
    XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    DOC = 'application/msword',
    DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    M4a = 'audio/mp4',

    CorelEnvoy = 'application/envoy',
    FractalImageFile = 'application/fractals',
    WindowsPrintSpoolFile = 'application/futuresplash',
    HTMLApplication = 'application/hta',
    AtariSTProgram = 'application/internet-property-stream',
    BinHexEncodedFile = 'application/mac-binhex40',
    BinaryFile = 'application/octet-stream',
    BinaryDiskImage = 'application/octet-stream',
    JavaClassFile = 'application/octet-stream',
    DiskMasherImage = 'application/octet-stream',
    ExecutableFile = 'application/octet-stream',
    LHARCCompressedArchive = 'application/octet-stream',
    LZHCompressedFile = 'application/octet-stream',
    CALSRasterImage = 'application/oda',
    ActiveXScript = 'application/olescript',
    OutlookProfileFile = 'application/pics-rules',
    CertificateRequestFile = 'application/pkcs10',
    CertificateRevocationListFile = 'application/pkix-crl',
    AdobeIllustratorFile = 'application/postscript',
    PostscriptFile = 'application/postscript',
    RichTextFormatFile = 'application/rtf',
    SetPaymentInitiation = 'application/set-payment-initiation',
    SetRegistrationInitiation = 'application/set-registration-initiation',
    OutlookMailMessage = 'application/vnd.ms-outlook',
    SerializedCertificateStoreFile = 'application/vnd.ms-pkicertstore',
    WindowsCatalogFile = 'application/vnd.ms-pkiseccat',
    StereolithographyFile = 'application/vnd.ms-pkistl',
    PowerPointTemplate = 'application/vnd.ms-powerpoint',
    PowerPointSlideShow = 'application/vnd.ms-powerpoint',
    PowerPointPresentation = 'application/vnd.ms-powerpoint',
    MicrosoftProjectFile = 'application/vnd.ms-project',
    WordPerfectMacro = 'application/vnd.ms-works',
    MicrosoftWorksDatabase = 'application/vnd.ms-works',
    MicrosoftWorksSpreadsheet = 'application/vnd.ms-works',
    MicrosoftWorksWordProcessorDocument = 'application/vnd.ms-works',
    WindowsHelpFile = 'application/winhlp',
    BinaryCPIOArchive = 'application/x-bcpio',
    ComputableDocumentFormatFile = 'application/x-cdf',
    UnixCompressedFile = 'application/x-compress',
    GzippedTarFile = 'application/x-compressed',
    UnixCPIOArchive = 'application/x-cpio',
    PhotoshopCustomShapesFile = 'application/x-csh',
    KodakRAWImageFile = 'application/x-director',
    AdobeDirectorMovie = 'application/x-director',
    MacromediaDirectorMovie = 'application/x-director',
    DeviceIndependentFormatFile = 'application/x-dvi',
    GnuTarArchive = 'application/x-gtar',
    GnuZippedArchive = 'application/x-gzip',
    HierarchicalDataFormatFile = 'application/x-hdf',
    InternetSettingsFile = 'application/x-internet-signup',
    IISInternetServiceProviderSettings = 'application/x-internet-signup',
    ARCPlusArchitecturalFile = 'application/x-iphone',
    JavaScriptFile = 'application/x-javascript',
    LaTexDocument = 'application/x-latex',
    MicrosoftAccessDatabase = 'application/x-msaccess',
    WindowsCardSpaceFile = 'application/x-mscardfile',
    CrazyTalkClipFile = 'application/x-msclip',
    DynamicLinkLibrary = 'application/x-msdownload',
    MicrosoftMediaViewerFile = 'application/x-msmediaview',
    Steuer2001File = 'application/x-msmediaview',
    MultimediaViewerBookSourceFile = 'application/x-msmediaview',
    WindowsMetaFile = 'application/x-msmetafile',
    MicrosoftMoneyFile = 'application/x-msmoney',
    MicrosoftPublisherFile = 'application/x-mspublisher',
    TurboTaxTaxScheduleList = 'application/x-msschedule',
    FTRMediaFile = 'application/x-msterminal',
    MicrosoftWriteFile = 'application/x-mswrite',
    MSXComputersArchiveFormat = 'application/x-perfmon',
    PerformanceMonitorCounterFile = 'application/x-perfmon',
    ProcessMonitorLogFile = 'application/x-perfmon',
    AvidPersistentMediaRecordFile = 'application/x-perfmon',
    PegasusMailDraftStoredMessage = 'application/x-perfmon',
    PersonalInformationExchangeFile = 'application/x-pkcs12',
    PKCS12CertificateFile = 'application/x-pkcs12',
    CertificateRequestResponseFile = 'application/x-pkcs7-certreqresp',
    PKCS7CertificateFile = 'application/x-pkcs7-mime',
    DigitallyEncryptedMessage = 'application/x-pkcs7-mime',
    DigitallySignedEmailMessage = 'application/x-pkcs7-signature',
    BashShellScript = 'application/x-sh',
    UnixSharArchive = 'application/x-shar',
    FlashFile = 'application/x-shockwave-flash',
    StuffitArchiveFile = 'application/x-stuffit',
    System5Release4CPIOFile = 'application/x-sv4cpio',
    System5Release4CPIOChecksumData = 'application/x-sv4crc',
    ConsolidatedUnixFileArchive = 'application/x-tar',
    TclScript = 'application/x-tcl',
    LaTeXSourceDocument = 'application/x-tex',
    LaTeXInfoDocument = 'application/x-texinfo',
    UnformattedManualPage = 'application/x-troff',
    TuringSourceCodeFile = 'application/x-troff',
    TomeRaider2EbookFile = 'application/x-troff',
    UnixManual = 'application/x-troff-man',
    ReadmeTextFile = 'application/x-troff-me',
    ThreedsMaxScriptFile = 'application/x-troff-ms',
    UniformStandardTapeArchiveFormatFile = 'application/x-ustar',
    SourceCode = 'application/x-wais-source',
    InternetSecurityCertificate = 'application/x-x509-ca-cert',
    SecurityCertificate = 'application/x-x509-ca-cert',
    DERCertificateFile = 'application/x-x509-ca-cert',
    PublicKeySecurityObject = 'application/ynd.ms-pkipko',
    ZippedFile = 'application/zip',
}

export enum EFileTypes {
    Blob = 'blob'
}

export enum EFileExtensions {
    Csv = 'csv'
}
export const FILE_EXTENSION_TO_MIMETYPE: { [extension in string]?: EMimeTypes } = {
    [EFileExtensions.Csv]: EMimeTypes.Csv
};

export type TMimeTypeArray = Array<string>;
export type TValidImage = string;

export const onlyDocumentAllowed: TMimeTypeArray = [
    EMimeTypes.Csv,
    EMimeTypes.PDF,
    EMimeTypes.XLS,
    EMimeTypes.XLSX,
    EMimeTypes.DOC,
    EMimeTypes.DOCX
];

export const onlyVideoAllowed: TMimeTypeArray = [
    EMimeTypes.Mp4,
];

export const onlyImageAllowed: TMimeTypeArray = [
    EMimeTypes.Jpeg,
    EMimeTypes.Png,
    EMimeTypes.Webp
];

export const onlyStickerAllowed: TMimeTypeArray = [
    EMimeTypes.Sticker
];

export type TSelectedFileArray = Array<SelectedFile>;
// export type TFileArray = Array<File>;
export type TComponentFileInfoArray = Array<IClientFileInfo>;
export type TIComponentFileInfoArray = Array<IServerFileInfo>;

export interface ICachedFile {
    file: Blob;
    thumbnail: Blob;
    isProcessed: boolean;
    fileLocalUrl: string;
    serverInfo: IServerFileInfo;
};

export interface IClientFileInfo {
    currentName: string;
    idMediaTag: TGlobalUID;
};

export declare interface MediaRecorderErrorEvent extends Event {
    name: string;
}

export declare interface MediaRecorderDataAvailableEvent extends Event {
    data: any;
}

export interface MediaRecorderEventMap {
    'dataavailable': MediaRecorderDataAvailableEvent;
    'error': MediaRecorderErrorEvent;
    'pause': Event;
    'resume': Event;
    'start': Event;
    'stop': Event;
    'warning': MediaRecorderErrorEvent;
}

export type TMediaRecorderState = 'inactive' | 'recording' | 'paused';

