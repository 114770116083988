<section
  class="card"
  [ngClass]="getThemeClass()"
>
  <header [style.margin-bottom]="arrowDisplayNone() ? '0' : '40px'">
    <h2 *ngIf="canShowTitle()" class="title" [ngClass]="{
      'card__item--grey': isTitleGrey(),
      'fs-sm': isSmallFontSize(),
      'fs-md': isMediumFontSize()
    }">{{ getTitle() }}</h2>
    <cm-card-button
      *ngFor="let button of safeButtons()"
      [callback]="button.onClickCallback"
      [icon]="button.icon"
      [visible-horizontal]="button.visible"
    >
    </cm-card-button>
    <button type="button" (click)="toggleCard()" [ngStyle]="{'display': arrowDisplayNone() }">
      <i class="material-icons arrow" *ngIf="!canShowContent()" [ngClass]="{'card__item--grey': isTitleGrey()}">keyboard_arrow_down</i>
      <i class="material-icons arrow" *ngIf="canShowContent()" [ngClass]="{'card__item--grey': isTitleGrey()}">keyboard_arrow_up</i>
    </button>
  </header>
  <section class="card__content" [style.display]="canShowContent() ? 'block' : 'none' ">
      <ng-content></ng-content>
  </section>
</section>
