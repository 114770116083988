<div class="wrapper" [@.disabled]="disableAnimation">
  <section>
    <section *ngIf="includeHeader">
      <header class="modal__header">
        <h4 class="modal__title">{{ dataFromColmeiaDialogSvc?.title }}</h4>
        <button mat-icon-button (click)="close()" *ngIf="hasCloseButton"><mat-icon>close</mat-icon></button>
      </header>
      <hr class="vline" *ngIf="lineUnderHeader">
      <h5 *ngIf="dataFromColmeiaDialogSvc?.contentText" class="modal__content">{{ dataFromColmeiaDialogSvc?.contentText }}</h5>
    </section>
    <ng-container *ngComponentOutlet="dataFromColmeiaDialogSvc.componentOrTemplateRefToOpen"></ng-container>
  </section>
</div>
