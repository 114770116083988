import { Interaction, TInteractionArray } from '@colmeia/core/src/interaction/interaction';

import { Participant } from '@colmeia/core/src/business/participant';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { Group } from '@colmeia/core/src/business/group';
import { getClock, isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import {
    allRespondedForThisGroupAppointment,
    answeredToThisAppointment,
    getChatViewModeInteractionsOnly,
    isPersonalOnlyToMe,
} from '@colmeia/core/src/rules/filters';
import { MessageHandlerCallbak } from './message-handler-callback';
import { TDriverFeedbackArray } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { includedInteractions } from '@colmeia/core/src/rules/interaction-filter';
import { TInteractionTypeArray } from '@colmeia/core/src/interaction/interaction-type';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { IFeedbackDetailResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { Serializable, TSerializableArray } from '@colmeia/core/src/business/serializable';
import { EChatBtnsVisualElements } from '@colmeia/core/src/core-constants/constant-visual-elements.enums';
import { environment } from 'environments/environment-client';
import { IComponentParameter } from "../../model/component-comm/basic";
import { IReactDisplayCallback, ReactDisplayBarHandler } from "../react-display-handler";
import { EChatViewStyle } from "../../components/backbone/chat-backbone.component";
import { ChatBackboneModel, NChatBackBone } from "../../model/chat-backbone.model";
import { NavigatorServices } from "../../services/controllers-services/navigator/navigator.service";
import { SessionService } from "../../services/session.service";
import { ContractService } from "../../services/controllers-services/contract-services/contract.service";
import { MainHandler } from "../main-handler";
import { ReactBarHandler } from "../react-bar-handler";
import { AttendanceService } from 'app/services/attendance.service';

export type TMessageInstanceHandlerArray = Array<MessageInstanceHandler>;

interface IChangeDetectorCallable {
    changeAllSiblingsDetection(): void;
}

export enum EMessageIteratorAnimationState {
    visible = '1',
    hidden = '2',
}

export type TIMessageEndPointCallbackArray = Array<IMessageEndPointCallback>;


export interface IMessageEndPointCallback extends IChangeDetectorCallable {
    scrollTop(): void;
    detectChangesOnFirstLevelMsgs(): void;
    onMessageReact(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedback: Feedback): void;
    callMessageContainerChangeDetector(refreshVector: boolean): void;
    changeSpecificSibling(interactionID: TGlobalUID);
    removeInteraction(interaction: Interaction): void;
    onScroolTop(): void;
    getDisplayBarReactHandler(): ReactDisplayBarHandler;
    onNewInteractionArrivedCallback(isChildInteraction: boolean): void;
    changeDectionChildren(): boolean
};

export interface IMessageHandlerCallback extends IMessageEndPointCallback {
    getPreviousMessageHandler(handler: MessageInstanceHandler): MessageInstanceHandler;
    getNextMessageHandler(handler: MessageInstanceHandler): MessageInstanceHandler;
    getInteractionHandlerArray(): TMessageInstanceHandlerArray;
    getHandlerKey(): TGlobalUID;
    getEditingInteraction(): Interaction;
    destroyDatabases(handlerKey: string): void;
};

export interface IMessageInstanceHandlerEndpoint {
    changeDetectionOnMessageInstance(): void;
}

export interface IMessageInstanceParameter extends IComponentParameter {
    interaction: Interaction;
    clientCallback: IMessageHandlerCallback;
    diaplayOptions: EChatViewStyle;
    drivenInteractionFeedbacks: TInteractionTypeArray;
    chatBackbone: ChatBackboneModel;
    navigatorInstance: NavigatorServices;
    sessionSvc: SessionService;
    contractSvc: ContractService;
    attendanceSvc: AttendanceService;
};

export interface ISlaveMessageInstance extends IChangeDetectorCallable {
    setSlaveInstance(): void;
};

export class MessageInstanceHandler extends MainHandler implements IReactDisplayCallback, IMessageInstanceHandlerEndpoint {
    private slave: ISlaveMessageInstance;
    private reactHandler: ReactBarHandler;
    private reactDisplayHandler: ReactDisplayBarHandler;
    private painted: boolean = false;
    private nextLevelHandler: MessageHandlerCallbak;

    private static allowedOptionsForEmbeddedChat: EChatBtnsVisualElements[] = [
        EChatBtnsVisualElements.Reply,
        EChatBtnsVisualElements.Feedbacks
    ];
    constructor(messageParameter: IMessageInstanceParameter) {
        super(messageParameter);
        const interaction: Interaction = this.getInteraction();

        this.reactHandler = new ReactBarHandler({
            idGroup: this.getGroupID(),
            interactionID: interaction.getPrimaryID(),
            interactionTypeID: interaction.getInteractionType().getPrimaryID(),
            isBigIcon: interaction.isBigIconFeedbacks(),
            allInteractionTypes: this.getDefaultFeedbackOptions(),
            clientCallback: this
        });

        this.resetDisplayBarHandler();

        this.nextLevelHandler = MessageHandlerCallbak.newInstance(
            this.getChildrenInteractionsFiltered(),
            this.getChatBackbone(),
            false,
            messageParameter.navigatorInstance,
            this.getInteraction(),
            messageParameter.chatBackbone.getQueueServices(),
            messageParameter.sessionSvc,
            messageParameter.contractSvc,
            messageParameter.attendanceSvc
        );
    };

    getChildrenInteractionsFiltered(): TInteractionArray {
        const interactions = this.getInteraction().getChildren(this.getGroupID())

        let filteredInteractions = getChatViewModeInteractionsOnly(
            this.getChatBackbone().isChatViewMode(),
            interactions)

        filteredInteractions = includedInteractions(filteredInteractions);

        return filteredInteractions
    }

    async getInteractionDetails(
        idInteraction: TGlobalUID, cursor: string, idFeedback: TGlobalUID
    ): Promise<IFeedbackDetailResponse | boolean> {
        return await this.getChatBackbone().getInteractionDetails(idInteraction, cursor, idFeedback);
    }

    public getNextLevelHandler(): MessageHandlerCallbak {return this.nextLevelHandler};
    public getGroup(): Group {return this.getChatBackbone().getGroup()};
    public getChatBackBoneViewMode(): NChatBackBone.EViewMode {
        return null; //this.getChatBackbone().getViewMode()
    };
    public getGroupID(): TGlobalUID { return this.getGroup().getGroupID(); };
    public isPreviousMsgPainted(): boolean {
        const previousHandler: MessageInstanceHandler = this.getPreviousMessageHandler();
        return previousHandler &&  previousHandler.painted;
    }
    public getSetPainted(flag: boolean): boolean {
        this.painted = flag;
        return flag;
    }
    public getPainted(): boolean {
        return this.painted;
    }
    public socketDeleteInteraction(interaction: Interaction): void {
        this.getComponentParameter().clientCallback.removeInteraction(interaction);
    }

    public getChatBackbone(): ChatBackboneModel {
        return this.getComponentParameter().chatBackbone;
    };

    public setSlaveInstance(slave: ISlaveMessageInstance): void {
        this.slave = slave;
    }

    public getNextMessageHandler(): MessageInstanceHandler {
        return this.getComponentParameter().clientCallback.getNextMessageHandler(this);
    }

    public getPreviousMessageHandler(): MessageInstanceHandler {
        return this.getComponentParameter().clientCallback.getPreviousMessageHandler(this);
    };

    public isShowAvatarName(): boolean {
        const previousHandler: MessageInstanceHandler = this.getPreviousMessageHandler();

        const prevParticipantIsTheSame = previousHandler
            && this.getInteraction().getParticipant().iss(
                previousHandler.getInteraction().getParticipant());

        return !prevParticipantIsTheSame;
    };

    public getReactHandler(): ReactBarHandler {
        return this.reactHandler;
    };

    public getDisplayBarHandler(): ReactDisplayBarHandler {
        return this.reactDisplayHandler;
    }

    public resetDisplayBarHandler(): void {
        this.reactDisplayHandler = this.newDisplayBarHandler()
    }

    private newDisplayBarHandler(): ReactDisplayBarHandler {
        return new ReactDisplayBarHandler({
            isSmall: true,
            clientCallback: this,
            interaction: this.getInteraction(),
            idGroup: this.getGroupID()
        });
    }

    public getComponentParameter(): IMessageInstanceParameter {
        return <IMessageInstanceParameter>super.getComponentParameter();
    };

    public getInteraction(): Interaction {return this.getComponentParameter().interaction; };
    public getMessage(): string {return this.getComponentParameter().interaction.getMessage(); };
    public getIsServerProcessed(): boolean {return this.getComponentParameter().interaction.isProcessedByServer(); };
    public getInteractionID(): TGlobalUID {return this.getInteraction().getInteractionID(); };

    public updateInteraction(interaction: Interaction): void {
        this.getComponentParameter().interaction = interaction;
    };

    public getInteractionParticipant(): Participant {
        return this.getInteraction().getParticipant();
    }

    public getReactingParticipant(): Participant {
        return this.getChatBackbone().getParticipant();
    };

    private getCallback(): IMessageHandlerCallback {
        return this.getComponentParameter().clientCallback;
    }

    detectChangesOnFirstLevelMsgs(): void {
        this.getCallback().detectChangesOnFirstLevelMsgs()
    }

    public onReactBarReaction(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedbcak: Feedback) {
        this.getCallback().onMessageReact(interactionID, interactionTypeID, feedbcak);
    };

    // Isso será mutável de acordo com o tipo de interação
    public getDefaultFeedbackOptions(): TDriverFeedbackArray {
        let iTypes: TDriverFeedbackArray;

        if (this.getInteraction().hasDriverFeedbacks()) {
            iTypes = this.getInteraction().getDriverFeedbacks();
        } else {
            iTypes = this.getDrivenInteractionTypeArray();
        };

        return iTypes;
    };

    public getDrivenInteractionTypeArray(): TDriverFeedbackArray {
        return  this.getChatBackbone().getDrivenInteractionTypeArray();
    }

    public isYourAttentionRequired(): boolean {
        return this.getInteraction().getAllIDAvatarCopied()
            .some((avt) => {return this.getInteractionParticipant().getAvatar().is(avt); });
    };

    public changeDetectionOnMessageInstance(): void {
        this.getNextLevelHandler().markForCheckEndPoints()
    };

    // RESPONSIBILIDADE PARA HTML
    public isYourAnswerRequired(): boolean {return false};
    public isDirectToASubGroup(): boolean {return this.getInteraction().isToSubgroup(); };
    public isDirectToAll(): boolean { return ! this.isDirectToASubGroup(); };
    public isTimeBounded(): boolean {return this.getInteraction().isTimed(); };
    public isTranscription(): boolean {return this.getInteraction().getTranscription() ? true : false};

    public isServerReceived(): boolean {return this.getInteraction().isWaitingResponse(); };
    public isRefreshNeeded(): boolean {return !this.isServerReceived(); };
    public isDeliveredToAll(): boolean {return false; };
    public isReadByAll(): boolean {return false; };

    public isPersonalResponseToMe(): boolean {
        // return this.getInteraction().getPersonalResponse()
        //     .some((per) => {
        //         return this.getChatBackbone().get//
        //     });
        return isPersonalOnlyToMe(
            this.getInteraction().getPersonalResponse(),
            this.getGroupID(),
            this.getReactingParticipant().getAvatar().getAvatarID())
    };

    isDueDate(): boolean {
        return this.getInteraction().isTimedType(constant.timedType.dueDate);
    }

    isFutureDueDate(): boolean {
        return this.isDueDate() &&
            this.getInteraction().isEventOcurred(false, getClock());
    }

    isPastDueDate(): boolean {
        return this.isDueDate() &&
            this.getInteraction().isEventOcurred(true, getClock());
    }

    isFinishedTask(): boolean {
        return this.isDemandFinished() || this.isPendencyFinished();
    }

    private isPendencyFinished(): boolean {
        const isTimed = this.isTimeBounded();
        const isInteractionFromSameAvatar = this.isInteractionFromSameAvatar();

        const answeredByMe = answeredToThisAppointment(
            this.getGroupID(),
            this.getReactingParticipant().getAvatar().getAvatarID(),
            this.getInteraction().getAppointments(),
            this.getInteraction().getChildren(this.getGroupID())
        )
        const pendencyFinished = isTimed && !isInteractionFromSameAvatar && answeredByMe;

        return pendencyFinished;
    }

    private isDemandFinished(): boolean {
        const isTimed = this.isTimeBounded();
        const isInteractionFromSameAvatar = this.isInteractionFromSameAvatar();

        const allResponded = allRespondedForThisGroupAppointment(
            this.getGroupID(),
            this.getInteraction().getAppointments(),
            this.getInteraction().getChildren(this.getGroupID()))
        const isDemandFinished = isInteractionFromSameAvatar && isTimed && allResponded;

        return isDemandFinished;
    }

    public isInteractionFromSameAvatar(): boolean {
        return this.getReactingParticipant().getAvatar().iss(
            this.getInteraction().getParticipant().getAvatar())
    }

    isSuicidal(): boolean {
        return this.getInteraction().isTimedType(constant.timedType.suicidal);
    }

    isEditedMessage(): boolean {
        return this.getInteraction().isEdited(constant.serializableField.chat_text);
    }

    private getDefaultOptions(): TSerializableArray {
        const chatItems: TSerializableArray = [
            Serializable.staticFactory(EChatBtnsVisualElements.Feedbacks)
        ];

        if(environment.allDevFeatures) {
            chatItems.push(
                Serializable.staticFactory(EChatBtnsVisualElements.Remove),
                Serializable.staticFactory(EChatBtnsVisualElements.Share),
                Serializable.staticFactory(EChatBtnsVisualElements.BigIconOptions),
                Serializable.staticFactory(EChatBtnsVisualElements.RememberMeReply)
            );
        }

        if (environment.allDevFeatures
            && this.getComponentParameter().contractSvc.getContract().isCanSellItens()
            && this.getComponentParameter().sessionSvc.interactionMadeByMe(this.getInteraction().toJSON())
            && ! this.getComponentParameter().sessionSvc.getSelectedGroup().getGroupType().isPersonal()
        ) {
            chatItems.push(
                Serializable.staticFactory(EChatBtnsVisualElements.Sell)
            );
        }

        if (this.getComponentParameter().sessionSvc.getPlayerInfoServices().interactionMadeByMe(this.getInteraction().toJSON())) {
            chatItems.push(Serializable.staticFactory(EChatBtnsVisualElements.Edit));
        }

        if (this.canReplyToMessage()) {
            chatItems.push(
                Serializable.staticFactory(EChatBtnsVisualElements.Reply),
            );
        }

        return chatItems;
    }

    public getMoreOptions(): TSerializableArray {
        return this.getComponentParameter().sessionSvc.isEmbeddedChat()
            ? MessageInstanceHandler.allowedOptionsForEmbeddedChat.map(id => Serializable.staticFactory(id))
            : this.getDefaultOptions();
    }

    public hasNextLevel(): boolean {
        return this.getNextLevelHandler()
            && isValidArray(this.getNextLevelHandler().getInteractionHandlerArray());
    };

    public canReplyToMessage(): boolean {
        const interaction = this.getInteraction();

        return this.getComponentParameter().attendanceSvc.canInteractionReplyToMessage(interaction);
    }
};
