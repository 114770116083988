import {Injectable} from '@angular/core';
import {
    EDeviceType,
    INotificationDevice,
    IPushNotificationPolicy,
    TNotificationDeviceArray
} from "@colmeia/core/src/business/push-notifications";
import {getClock, isValidRef} from "@colmeia/core/src/tools/utility";
import {apiRequestType} from "@colmeia/core/src/request-interfaces/message-types";
import {
    IPNDeviceAllRequest,
    IPNGetAllDeviceResponse,
    IPNPolicyGetResponse,
    IPNPolicyUpsertRequest,
    IPNRequestPolicy,
    IPNUpsertDeviceRequest
} from "@colmeia/core/src/request-interfaces/pn-api-interface";
import {RequestBuilderServices} from "./request-builder.services";
import {ServerCommunicationService} from "./server-communication.service";
import {HardwareLayerService} from "./hardware";
import {IInfraParameters} from "../model/client-infra-comm";
import { stringify } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

    constructor(
        private rbs: RequestBuilderServices,
        private serverAPI: ServerCommunicationService,
        private hw: HardwareLayerService
    ) {}

    async createDevice(deviceName: string, deviceUID: string, token: string, type: EDeviceType) {
        const request: IPNUpsertDeviceRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.pushNotification.devices.upsert),
            header: {
                deviceName,
                deviceUID,
                token,
                type,
                cancelClock: 0
            }
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);

        if (response.executionOK) {
            return (<IPNPolicyGetResponse>response.response).policy;
        }

        return null;
    }

    async reactivateDevice(device: INotificationDevice) {
        const request: IPNUpsertDeviceRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.pushNotification.devices.upsert),
            header: {
                ...device,
                cancelClock: 0
            }
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);

        if (response.executionOK) {
            return (<IPNPolicyGetResponse>response.response).policy;
        }

        return null;
    }

    async deactivateDevice(device: INotificationDevice) {
        const request: IPNUpsertDeviceRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.pushNotification.devices.upsert),
            header: {
                ...device,
                cancelClock: getClock()
            }
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);

        if (response.executionOK) {
            return (<IPNPolicyGetResponse>response.response).policy;
        }

        return null;
    }

    async updatePolicy(sleepUntil: number) {
        const request: IPNPolicyUpsertRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.pushNotification.policy.upsert),
            sleepUntil: sleepUntil
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);

        if (response.executionOK) {
            return (<IPNPolicyGetResponse>response.response).policy;
        }

        return null;
    }

    async getPNPolicy(): Promise<IPushNotificationPolicy> {
        const request: IPNRequestPolicy = {
            ...this.rbs.secureBasicRequest(apiRequestType.pushNotification.policy.get)
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);

        if (response.executionOK) {
            return (<IPNPolicyGetResponse>response.response).policy;
        }

        return null;
    }

    async getDevices(): Promise<TNotificationDeviceArray> {
        const request: IPNDeviceAllRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.pushNotification.devices.get)
        };
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const response = await this.serverAPI.managedRequest(infra, request);
        
        if (response.executionOK) {
            return (<IPNGetAllDeviceResponse>response.response).devices;
        }

        return [];
    }
}
