import { IServerColmeiaTag, ITaggable } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import {
    ENonSerializableObjectType,
    INonSerializable,
    INonSerializableHeader
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { defaultFields } from "@colmeia/core/src/tools/utility";
import { AcceptPromise } from "@colmeia/core/src/tools/utility-types";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { IComponentParameter } from "../model/component-comm/basic";
import { IHandlerAllowingDebouncer, MainHandler } from "./main-handler";
import {
    AllNsOptionsBoolean,
    NsMoreOptionsHandler
} from "./ns-more-options.handler";
import { TaggableHandler } from "./taggable.handler";
import { Compute } from "Any/_api";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { ColmeiaWindowRef } from "app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref";
import { IAppAlertSettings } from 'app/components/alert/alert.component';

export interface IGenericDashboardEditPageClientCallback {
    onGenericBackButtonPressed?(): Promise<void>;
    onGenericSaveButtonPressed?(): Promise<void | boolean>;
    onGenericDeleteClicked?(): Promise<void>;
    onGenericSaveTags?(selectedTags: IServerColmeiaTag[]): Promise<void>;
    onGenericCopyClicked?(ns: INonSerializable): Promise<void>;
    onValidationRejected?(messages?: string[]): AcceptPromise<void>;

    preventSave?: boolean;
}

export interface IGenericDashboardEditStyles {
    taggable: Partial<CSSStyleDeclaration>;
}

export interface IGenericDashboardEditCustomButton {
    icon?: string | (() => string);
    text?: string;
    color?: string;
    tooltip?: string;
    isHidden?: () => boolean;
    onClick?: () => void;
}

export interface IPoint {
    y: number;
    x: number;
}

/**
 * x,y a partir do offset do ponto inicial
 */
export interface IWindowTranslation extends IPoint { }

interface IGenericDashboardEditHandlerParameterBox {
    moreOptions?: Partial<AllNsOptionsBoolean>;
    title?: string;
    hasDeleteButton?: boolean;
    removeHexagon?: true;
    removeMoreOptions?: true;
    removeSaveButton?: true;
    removeEntireBox?: true;
}


export interface GenericDashboardWindowButton extends IGenericDashboardEditCustomButton {
    icon: string | (() => string);
    onClick: () => void;
    color?: never;
}

export interface IGenericDashboardEditHandlerParameter
    extends IComponentParameter,
    IGenericDashboardEditHandlerParameterBox, IHandlerAllowingDebouncer {
    nsType: ENonSerializableObjectType;
    clientCallback: IGenericDashboardEditPageClientCallback;
    nser: INonSerializableHeader;
    customTaggableElement?: ITaggable;
    buttons?: IGenericDashboardEditCustomButton[];
    isWindowMode?: boolean;
    windowButtons?: GenericDashboardWindowButton[];
    customSaveButton?: IGenericDashboardEditCustomButton;
    requestRemovalConfirmation?: boolean;
    removeGoBackButton?: true;
    removeTaggable?: true;
    headerless?: boolean;
    backButtonIcon?: string;
    allowEditTitleInHeader?: boolean;
    autoValidate?: boolean;
    moreOptionsHandler?: NsMoreOptionsHandler;
    blockSave?(): boolean;
    hasChange?(): boolean;
    replaceEditTitle?: string;
    disableSNCheck?: boolean;
    classes?: {
        header?: string;
    };
    helpTipKey?: ETooltipInfoKey;
    disableTitleChange?: boolean;
    dialogRef?: ColmeiaWindowRef;
    alert?: IAppAlertSettings
}

export interface IGenericDashboardEditComponent {
    validationErrors: string[];
    onSaveClickedValidation(): boolean;
    onNsMoreOptionsCopyClicked(ns: INonSerializable);
    onNsMoreOptionsDeleteClicked(
        nser: INonSerializable
    ): Promise<boolean | void>;
}

function getDefaultParameters() {
    const defaultParameters: Partial<IGenericDashboardEditHandlerParameter> = {
        customSaveButton: {
            text: 'Salvar',
            icon: 'save',
            color: 'green',
        },
        buttons: [],
        windowButtons: [],
        requestRemovalConfirmation: true,
        // allowEditTitleInHeader: true,
    }
    return defaultParameters;
}

export class GenericDashboardEditHandler extends MainHandler {
    private component: IGenericDashboardEditComponent;
    private _hiddenContent = false;
    private _save: Subject<void> = new Subject();

    public save$: Observable<void> = this._save.asObservable();
    public _taggableHandler: ReplaySubject<TaggableHandler> =
        new ReplaySubject<TaggableHandler>(1);
    public taggableHandler$: Observable<TaggableHandler> =
        this._taggableHandler.asObservable();

    public _moreOptionsHandler: ReplaySubject<NsMoreOptionsHandler> =
        new ReplaySubject<NsMoreOptionsHandler>(1);
    public moreOptionsHandler$: Observable<NsMoreOptionsHandler> =
        this._moreOptionsHandler.asObservable();
    public get isContentHidden(): boolean {
        return this._hiddenContent;
    }

    private _component$: Subject<IGenericDashboardEditComponent> =
        new Subject();
    public component$(): Observable<IGenericDashboardEditComponent> {
        return this._component$.asObservable();
    }

    public startWindowMovement: Observable<void>;
    public windowMovement: Observable<IWindowTranslation>;
    public endWindowMovement: Observable<void>;

    private _nsTypeName: string;

    constructor(parameter: IGenericDashboardEditHandlerParameter) {
        super(parameter);
        defaultFields(parameter, getDefaultParameters());
    }

    getComponentParameter(): IGenericDashboardEditHandlerParameter {
        return <IGenericDashboardEditHandlerParameter>(
            super.getComponentParameter()
        );
    }

    setSlave(component: IGenericDashboardEditComponent): void {
        this.component = component;
        this._component$.next(this.component);
    }

    getComponent(): IGenericDashboardEditComponent {
        return this.component;
    }

    validateBeforeSave(): boolean {
        return this.component.onSaveClickedValidation();
    }

    setHiddenContent(value: boolean): void {
        this._hiddenContent = value;
    }

    setNsTypeName(name: string) {
        this._nsTypeName = name;
    }

    getNsTypeName(): string {
        return this._nsTypeName;
    }
}
