import {Participant} from '../business/participant';
import {constant, TGlobalUID} from '../business/constant';
import {InteractionType} from './interaction-type';
import {IChainedInteractionJSON} from '../comm-interfaces/interaction-interfaces';
import {UberCache} from '../persistency/uber-cache';
import {Interaction} from './interaction';
import { Feedback } from './feedback';
import { putFeedbackData, isSecondLevelInteraction } from '../rules/interaction-filter';
import { isValidRef, isInvalid } from '../tools/utility';
import { TUpdateTreePosition } from '../comm-interfaces/service-group-interfaces';

export class ChainedInteraction extends Interaction {
    private interactionParent: Interaction; 
    private feedback: Feedback;
    private position: number;

    protected constructor(primaryID: TGlobalUID, participant: Participant, interactionType: InteractionType, interactionParent: Interaction) {
        super(primaryID, participant, interactionType);
        this.interactionParent = interactionParent;
        if (isInvalid(this.position)) {
            this.position = 0;
        }
    };

    public toJSON(): IChainedInteractionJSON {
        const isFeedback: boolean = this.feedback ? true : false;
        const hasParent: boolean = this.isChainedOrTreeWithParent();
        const json: IChainedInteractionJSON = {
            ...super.toJSON(),
            idFeedback: isFeedback? this.getFeedback().getPrimaryID() : null,
            feedback: null, 
            idInteractionParent : hasParent? this.interactionParent.getInteractionID(): null,
            idInteractionTypeParent : hasParent? this.interactionParent.getInteractionType().getInteractionTypeID(): null,
            treePos: this.position
        };
        putFeedbackData(json);
        return json;
    };

    public rehydrate(json: IChainedInteractionJSON): void {
        super.rehydrate(json);
        this.position = json.treePos;

        let interactionType: InteractionType = InteractionType.staticFactory(json.idInteractionType);

        if (interactionType.isChainedOrTreeWithParent(json)) {
            this.interactionParent = Interaction.staticFactory(json.idInteractionParent);
        };

        if (json.isFeedback) {
            this.feedback = Feedback.staticFactory(json.idFeedback);
        };

        if (this.interactionParent && isSecondLevelInteraction(this)) {
            const idGroup: TGlobalUID = json.publishingGroups[0].idGroup;
            this.interactionParent.addChildInteraction(idGroup, this);
        };

    };

    public getFeedback(): Feedback {return this.feedback};
    
    public setFeedback(idFeedback: TGlobalUID): void { 
        this.feedback = Feedback.staticFactory(idFeedback);
    };

    public getWhatYouVotedFor(): Interaction {
        return this.getInteractionParent();
    }


    public static staticFactory(idInteraction: TGlobalUID): ChainedInteraction {
        return <ChainedInteraction>UberCache.uberFactory(idInteraction, constant.objectType.interaction, true);
    };

    public static factoryMessage(json: IChainedInteractionJSON, participant: Participant = null): ChainedInteraction {
        let chainedInteraction: ChainedInteraction = <ChainedInteraction>UberCache.uberFactory(json.primaryID, constant.objectType.interaction, false);
        InteractionType.staticFactory(json.idInteractionTypeParent).
               checkInteracionAllowed(InteractionType.staticFactory(json.idInteractionType));
        if (chainedInteraction == null)
            chainedInteraction = new ChainedInteraction(json.primaryID, 
                                        participant? participant: Participant.staticFactory(json.participant.primaryID),
                                        InteractionType.staticFactory(json.idInteractionType),
                                        Interaction.staticFactory(json.idInteractionParent));
                
        chainedInteraction.rehydrate(json);
        return chainedInteraction;
    };

    
    public getInteractionParent(): Interaction {
        return this.interactionParent;
    };

    public getInteractionParentID(): TGlobalUID {
        return this.interactionParent.getInteractionID();
    }

    //// Position Control
    public getLastPosition(groupId: TGlobalUID): number {
        return this.getChildren(groupId).length;
    };

    public getPosition(): number {return this.position;};
    public setPosition(pos: number): void {this.position = pos};


    public setRefresh(position: number, groupId: TGlobalUID): TUpdateTreePosition {
        const parent = <ChainedInteraction>this.getInteractionParent();
        if (parent) {
            let actual: ChainedInteraction = <ChainedInteraction>parent.getChildren(groupId).find((x: ChainedInteraction) => {return x.getPosition() === position && x.issNot(this)});
            if (! actual) {
                ///@@ todo jose arrancar isso apos bot corrigido
                actual = <ChainedInteraction>parent.getChildren(groupId).find((x: ChainedInteraction) => {return isInvalid(x.getPosition())})
            }
            if (actual) {
                actual.setPosition(this.getPosition());
                this.setPosition(position);
                return [{
                    idTreeInteraction: actual.getPrimaryID(),
                    position: actual.getPosition(),
                },
                {
                    idTreeInteraction:  this.getInteractionID(),
                    position: this.getPosition()
                }]
            }
        };
        return [];
    };

    public getProfoundClockTick(idGroup: TGlobalUID, level: number): number {
        let clockTick: number = super.getClockTick();

        for (const interaction of this.getChildren(idGroup)) {
            const profound: number = interaction.getProfoundClockTick(idGroup, level + 1);
            if (profound > clockTick) {
                clockTick = profound;
            };
        };
        return clockTick;
    };



};

