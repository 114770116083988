import { Directive, HostListener, Input } from '@angular/core';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { CopyToClipboardService } from 'app/services/copy-to-clipboard.service';

@Directive({
    selector: '[copyToClipboard]'
})
export class CopyToClipboardDirective {

    @Input() copyToClipboard: string;
    @Input() preventPropagation = false;

    constructor(private copyToClipboardSvc: CopyToClipboardService) { }

    @HostListener('click', ['$event']) onClick(event: MouseEvent) {
        if (this.preventPropagation) {
            event.stopPropagation();
        }
        
        if(!isValidString(this.copyToClipboard)) return;
        this.copyToClipboardSvc.copy(this.copyToClipboard)
    }
}
