<section class="follow__button">
  <cm-button
    [color]="btnColor"
    appClickStopPropagation
    (click)="onOptionClicked()"
    *ngIf="!showChatButton"
    [classes]="statuses ? 'cursor-default' : ''"
  >
    <span class="label--follow">{{ getSubscriptionLabel() }}</span>
    <mat-icon class="mat-18">{{getStatusIcon()}}</mat-icon>
  </cm-button>

  <a class="chat__button" *ngIf="showChatButton" [attr.href]="chatURL" onclick="return false;">
    <cm-button (click)="goToChat()" [color]="BTNCOLOR.Blue">
      <span>
        <i class="material-icons">chat</i> {{ translations.chat }}
      </span>
    </cm-button>
  </a>
</section>
