import { Guard } from "@colmeia/core/src/tools/utility/functions/guard";
import { DeliveryProvider, NamedString } from "../../core-constants/types";
import { EMimeTypes, EPartialMimeTypes, MediaMimeTypes, TMimeTypePrefixes } from "../../multi-media/file-interfaces";
import { asConst } from "../../tools/type-utils";
import { isIn, isInEnum, isInvalidArray, isValidRef, pickKeys } from "../../tools/utility";
import { $string, RequiredSet, ValueOf } from "../../tools/utility-types";
import { EBotActionType } from "../bot/new-bot-action";
import { EFeatureContext, providersConfigDB } from "./social-media.constants";
import { EColmeiaWATemplateApprovalStatus, EConnectionCredentialStatus, EEmailTemplateType, IEmailTemplate, IEmailTemplateVariables, IQuickReplyOption, ISocialMediaConnectionServer, MapWhatsAppSocialMediaConnectionServer, TColmeiaWhatsAppTemplateApprovalStatus } from "./social-media.model";
import { IWhatsAppTemplateParameter } from "./template-model";
import { WhatsApp } from "./whatsapp-interface";

export function isEmailTemplateVariables(source: IEmailTemplate): source is IEmailTemplateVariables {
    return source?.type === EEmailTemplateType.variables;
}


const $channelType = pickKeys<IWhatsAppTemplateParameter>().channelType;

export function isTemplateSent(template: IWhatsAppTemplateParameter, idProvider?: string): boolean {
    return template.providers?.some(item => isTemplateSentByStatus(item.colmeiaApprovalStatus) && (!idProvider || item.idProvider === idProvider));
}

export function isTemplateSentByStatus<T extends TColmeiaWhatsAppTemplateApprovalStatus>(status: T | undefined): status is Exclude<T, undefined | EColmeiaWATemplateApprovalStatus.Notsent> {
    return isValidRef(status) && status !== EColmeiaWATemplateApprovalStatus.Notsent;
}
export function getCredentialStatus(credentialStatus: EConnectionCredentialStatus | undefined): EConnectionCredentialStatus {
    return credentialStatus ?? EConnectionCredentialStatus.UnChecked;
}

export function isInvalidCredentialStatus(credentialStatus: EConnectionCredentialStatus | undefined): credentialStatus is EConnectionCredentialStatus.Invalid {
    return credentialStatus === EConnectionCredentialStatus.Invalid;
}

export function getConnectionStatus(connection: ISocialMediaConnectionServer): EConnectionCredentialStatus {
    return getCredentialStatus(connection.detail.credentialStatus);
}

export function canSendTemplateToApproval(idProvider: string, template: IWhatsAppTemplateParameter): boolean {
    return (
        !isTemplateSent(template, idProvider)
        && isInEnum(WhatsApp.Template.Categories, template.category)
        && isInEnum(WhatsApp.Template.Language, template.language)
    )
}

export function isMessengerDeliveryProvider(action: DeliveryProvider): boolean {
    return (action === DeliveryProvider.facebook) || (action === DeliveryProvider.instagram);
}

const templateProvidersConfig = providersConfigDB.filter(item => item.feature === EFeatureContext.Template);

export const providersWithNoHeaderAndFooter = new Set<DeliveryProvider>(
    templateProvidersConfig
        .filter(item => !item.content.shouldAllowHeaderAndFooter)
        .map(item => item.deliveryProvider)
);

export function hasHeaderAndFooterOnWhatsAppProvider(deliveryProvider: DeliveryProvider) {
    return !providersWithNoHeaderAndFooter.has(deliveryProvider);
}

export const providersWithDelete = new Set<DeliveryProvider>(
    templateProvidersConfig
        .filter(item => item.content.shouldAllowDelete)
        .map(item => item.deliveryProvider)
);


export function isAllowingTemplateDelete(deliveryProvider: DeliveryProvider | undefined) {
    if (!deliveryProvider) return false;
    return providersWithDelete.has(deliveryProvider);
}



export function shouldRenderTemplateOption(option: IQuickReplyOption): boolean {
    if (option.action.type === EBotActionType.quickCallPhone) return false;
    if (option.action.type === EBotActionType.quickVisitWebsite && isInvalidArray(option.buttonDataContent?.variablesTemplate?.variables)) {
        return false;
    }

    return true;
}


export const hashMimeTypePrefixToType = asConst<{ [key in MediaMimeTypes]: WhatsApp.Message.Type }>()({
    [EPartialMimeTypes.Image]: WhatsApp.Message.Type.Image,
    [EPartialMimeTypes.Video]: WhatsApp.Message.Type.Video,
    [EPartialMimeTypes.Application]: WhatsApp.Message.Type.Document,
    [EPartialMimeTypes.Audio]: WhatsApp.Message.Type.Audio,
    [EPartialMimeTypes.Text]: WhatsApp.Message.Type.Document, // CSV
} as const)

export function getMimeTypePrefix(mimeType: TMimeType): MimeTypePrefix {
    const [prefix] = mimeType.split('/');
    return prefix;
}

export type MimeType = NamedString<'MimeType'>;
export type TMimeType = MimeType | EMimeTypes;
export type MimeTypePrefix = $string | TMimeTypePrefixes;

export type WhatsAppMediaTypes = ValueOf<typeof hashMimeTypePrefixToType>;

export function getWhatsAppTypeFromMime(mimeType: TMimeType): WhatsAppMediaTypes | undefined {
    const prefix = getMimeTypePrefix(mimeType);
    if (isIn(hashMimeTypePrefixToType)(prefix)) return hashMimeTypePrefixToType[prefix];
}

const whatsAppMediaTypes: Set<WhatsApp.Message.Type> = RequiredSet.create<WhatsAppMediaTypes>()([
    WhatsApp.Message.Type.Audio,
    WhatsApp.Message.Type.Document,
    WhatsApp.Message.Type.Image,
    WhatsApp.Message.Type.Video,
])

export function isWhatsAppMediaType(type: WhatsApp.Message.Type): type is WhatsAppMediaTypes {
    return whatsAppMediaTypes.has(type);
}


export function isSocialMediaType<Provider extends keyof MapWhatsAppSocialMediaConnectionServer>(
    provider: Provider
) {
    return (ns: ISocialMediaConnectionServer | undefined): ns is MapWhatsAppSocialMediaConnectionServer[Provider] => {
        return ns?.provider === provider;
    }
}
