import { TMatchEventBranchType, TMatchBotEventFunction, isRootEvent, isFormEvent, isLeafEvent, isNlpTransactionEvent, isFormTransactionEvent, isIslandEvent, isMenuContainerEvent } from "./bot-filter-dictionary";
import { ENextGenBotElementType } from "./bot-model";
import { TBotActionCharacteristicFilter, TActionDB, geActionDB } from "./bot-action-config-db";
import { TBotActionTypeArray, EBotActionType } from "./new-bot-action";
import { EIslandEvent } from "./bot-other-event-config-db";

const noop = undefined;
export const hashBranchTypeToMatch: { [key in TMatchEventBranchType]: TMatchBotEventFunction } = {
    [ENextGenBotElementType.root]: isRootEvent,
    [ENextGenBotElementType.metadata]: isFormEvent,
    [ENextGenBotElementType.botMenuItem]: isLeafEvent,
    [ENextGenBotElementType.menuContainer]: isMenuContainerEvent,
    [ENextGenBotElementType.nlpTransaction]: isNlpTransactionEvent,
    [ENextGenBotElementType.formTransaction]: isFormTransactionEvent,
    [EIslandEvent.island]: isIslandEvent,
    [ENextGenBotElementType.atendanceIsland]: noop,
};

export function getMatchFunctionToEvents(branchType: TMatchEventBranchType) {
    return hashBranchTypeToMatch[branchType];
}


export function getBotActionsByCharacteristic(match: TBotActionCharacteristicFilter): TBotActionTypeArray {
    const allAction: TBotActionTypeArray = [];
    const db: TActionDB = geActionDB();
    for (const action in db) {
        if (match(db[action])) {
            allAction.push(<EBotActionType>action);
        };
    };
    return allAction;
}