import { initialAction } from "@colmeia/core/src/shared-business-rules/bot/bot-action-model";
import { EBotEventType, IBotEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-event-model";
import { EActionTreeIncrementor, IBotSubject } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { ENextGenBotElementType, getInitialActionWithId, IBotElement, IBotElementWithRenderOptions, IBotMenuItem, IBotRoot, initDefaultRenderOptions } from "@colmeia/core/src/shared-business-rules/bot/bot-model";
import { EIslandEvent } from "@colmeia/core/src/shared-business-rules/bot/bot-other-event-config-db";
import { EBotActionType } from "@colmeia/core/src/shared-business-rules/bot/new-bot-action";
import { EIntegrationTypes } from "@colmeia/core/src/shared-business-rules/metadata/metadata-interface";
import { TIVariablesArray } from "@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { getUniqueStringID, typedClone, typedCloneLodash } from "@colmeia/core/src/tools/utility";
import { TVarEditorVariables } from "app/handlers/var-editor.handler";

export interface IBotSubjectHandler {
    botSubject: IBotSubject;
    onCloseDialog(): void;
}

export interface IBotChildCreateCreateModalData {
    levelsAbleToAdd: ENextGenBotElementType[];
    onOptionClickCallback(option: ENextGenBotElementType): void;
}
export interface IIntegrationTypeData {
    id: EIntegrationTypes;
    text: string;
}
export type TIntegrationTypeArray = IIntegrationTypeData[];

export type IOptionBotAssetArray = IOptionBotAsset[];
export interface IOptionBotAsset {
    type: EBotActionType | EBotEventType;
    text: string;
    disabled?: boolean;
}


export { initialAction };

export interface IBotEventModalData {
    itemLevel: ENextGenBotElementType | EIslandEvent,
    botEvent: IBotEvent,
    isEdit: boolean;
    eventTypes: IOptionBotAssetArray;
    variables?: TVarEditorVariables;
    schemaVariables: TIVariablesArray;
    idKB?: string;
    onSaveEventCallback(botEvent: IBotEvent): void;
}

export const initialBotElement: Readonly<IBotElement> = ({ // Object.freeze
    nsType: ENonSerializableObjectType.bot,
    nName: '',
    events: [],
    botLevel: undefined,
});

export function initBotElementWithRenderOptions(): IBotElementWithRenderOptions {
    return {
        renderOptions: initDefaultRenderOptions(),
    }
}




export function initBotRootElement(): IBotRoot {
    const initalBotRootElement: Readonly<IBotRoot> = ({ // Object.freeze
        ...initialBotElement,
        allBotSubject: [],
        botLevel: ENextGenBotElementType.root,
        events: [],
        firstAction: getInitialActionWithId(),
        // multipleChoiceAdvancedTitle: emptyContentBasicAsset(),
        incrementor: EActionTreeIncrementor.numeric,
        tags: [],
        canonicalIds: [],
        canonicals: [],
        ...initBotElementWithRenderOptions(),
    });

    return typedCloneLodash(initalBotRootElement);
}

export function initBotMenuItem(): IBotMenuItem {
    const initalBotMenuItem: Readonly<IBotMenuItem> = ({ // Object.freeze
        ...initialBotElement,
        botLevel: ENextGenBotElementType.botMenuItem,
        action: getInitialActionWithId(),
        botSubject: {
            action: undefined,
            subject: undefined,
        },
    });
    return typedCloneLodash(initalBotMenuItem);
}

