import { EBotActionType } from "./new-bot-action"
import { EBotEventType } from "./bot-event-model"
import { getDeepTypedProperty } from "../../tools/utility"
import { isEventAsset } from "./bot-function-model-helper"

export enum EOnConfirmationFields {
	question = 'eventText',

	warning = 'warningText',

	yesAction = 'yesAction',
	noAction = 'noAction',

	onInsuficientAccuracy = 'onInsuficientAccuracy',
	onInconclusiveAccuracy = 'onInconclusiveAccuracy',
	onConclusiveAccuracy = 'onConclusiveAccuracy',

}



export type TOnConfirmationFields = typeof EOnConfirmationFields;

export type TISconfigurableDB = {
	[key in EBotEventType]?: string
}

export const allConfigurablesEvent: TISconfigurableDB = {
	[EBotEventType.insuficientAccuracy]: "Deseja customizar os eventos?", //checkBox text
	[EBotEventType.inconclusiveAccuracy]: "Deseja customizar os eventos?", //checkBox text
	[EBotEventType.conclusiveAccuracy]: "Deseja customizar os eventos?", //checkBox text

	[EBotEventType.reuseFormInformation]: "Deseja customizar os eventos?",
	[EBotEventType.onConfirmation]: "Deseja customizar os eventos?",
}

interface IActionMeaning {
	isTitle?: true,
	eventOrAction: EBotActionType | EBotEventType
}

export type TActionDB = {
	[key in EBotEventType]?: { [key in keyof TOnConfirmationFields]?: IActionMeaning }
}


export const EventActionMap: TActionDB = {

	[EBotEventType.accuracyEvent]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined, isTitle: true
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.onInsuficientAccuracy)]: {
			eventOrAction: EBotEventType.insuficientAccuracy
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.onInconclusiveAccuracy)]: {
			eventOrAction: EBotEventType.inconclusiveAccuracy
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.onConclusiveAccuracy)]: {
			eventOrAction: EBotEventType.conclusiveAccuracy
		},
	},

	[EBotEventType.insuficientAccuracy]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined, isTitle: true
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: {
			eventOrAction: EBotActionType.redoAnswer
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: {
			eventOrAction: EBotActionType.goBack
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: {
			eventOrAction: EBotActionType.goBack
		},
	},

	[EBotEventType.inconclusiveAccuracy]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined, isTitle: true
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: {
			eventOrAction: EBotActionType.continueFlow
		},

		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: {
			eventOrAction: EBotActionType.redoAnswer
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: {
			eventOrAction: EBotActionType.redoAnswer
		},

	},

	[EBotEventType.conclusiveAccuracy]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined,
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: {
			eventOrAction: EBotActionType.continueFlow
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: {
			eventOrAction: EBotActionType.redoAnswer
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: {
			eventOrAction: EBotActionType.continueFlow
		},
	},

	[EBotEventType.reuseFormInformation]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined, isTitle: true
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: {
			eventOrAction: EBotActionType.reuseForm
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: {
			eventOrAction: EBotActionType.fillForm
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: {
			eventOrAction: EBotActionType.reuseForm
		},
	},

	[EBotEventType.onConfirmation]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined, isTitle: true
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: {
			eventOrAction: EBotActionType.continueFlow
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: {
			eventOrAction: EBotActionType.redoAnswer
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.warning)]: {
			eventOrAction: EBotActionType.continueFlow
		},
	},

	[EBotEventType.isUsefullInsight]: {
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.question)]: {
			eventOrAction: undefined, isTitle: true
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.yesAction)]: {
			eventOrAction: EBotActionType.yesAnswer
		},
		[getDeepTypedProperty<TOnConfirmationFields>((field: TOnConfirmationFields) => field.noAction)]: {
			eventOrAction: EBotActionType.noAnswer
		},
	}
} as const