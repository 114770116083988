import { checkEarlyReturnSwitch } from "../../tools/utility-types";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { ICRMStatusNames, ICRMStep, ICRMTicketDataBasic } from "./crm-entities";
import { ECRMCanonicalItemsTypes } from "./crm-services/crm-config-canonical-model";
import { ICRMProjectServer, TCRMConfigHeaderNsTypes, TCRMConfigItemsNsTypes } from "./crm-services/crm-config-ds-model";
import { ICRMProjectConfiguration } from './crm-services/crm-config.model';
import { ICRMProjectAttributeConfig } from './crm-services/crm-config.model';
import { ICRMPackagesMap } from "./crm-services/crm-config.model";

export function isCrmNSType(nsType: ENonSerializableObjectType): boolean {
    return nsType.substring(0,3) === 'crm';
}

export function getItemStatusIdByType(ticketData: ICRMTicketDataBasic, type: ECRMCanonicalItemsTypes): string {
    switch (type) {
        case ECRMCanonicalItemsTypes.CloseState:
            return ticketData.currentStatus.idCloseState;
        case ECRMCanonicalItemsTypes.Phase:
            return ticketData.currentStatus.idPhase;
        case ECRMCanonicalItemsTypes.Severity:
            return ticketData.currentStatus.idSeverity;
        case ECRMCanonicalItemsTypes.SupportLevel:
            return ticketData.currentStatus.idSupportLevel;
        case ECRMCanonicalItemsTypes.TicketState:
            return ticketData.currentStatus.idTicketState;
        case ECRMCanonicalItemsTypes.UrgencyLevel:
            return ticketData.currentStatus.idUrgencyLevel;
    }

    checkEarlyReturnSwitch()(type);

}

const itemTypeToIdKey: Record<ECRMCanonicalItemsTypes, keyof ICRMStep> = {
    [ECRMCanonicalItemsTypes.Severity]: "idSeverity",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "idUrgencyLevel",
    [ECRMCanonicalItemsTypes.SupportLevel]: "idSupportLevel",
    [ECRMCanonicalItemsTypes.Phase]: "idPhase",
    [ECRMCanonicalItemsTypes.TicketState]: "idTicketState",
    [ECRMCanonicalItemsTypes.CloseState]: "idCloseState"
};

export function getItemStatusKeyByType(type: ECRMCanonicalItemsTypes): string {
    return itemTypeToIdKey[type];
}


const itemTypeToIdNameKey: Record<ECRMCanonicalItemsTypes, keyof ICRMStatusNames> = {
    [ECRMCanonicalItemsTypes.Severity]: "severityName",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "urgencyLevelName",
    [ECRMCanonicalItemsTypes.SupportLevel]: "supportLevelName",
    [ECRMCanonicalItemsTypes.Phase]: "phaseName",
    [ECRMCanonicalItemsTypes.TicketState]: "ticketStateName",
    [ECRMCanonicalItemsTypes.CloseState]: "closeStateName",
};

export function getItemStatusNameKeyByType(type: ECRMCanonicalItemsTypes): string {
    return itemTypeToIdNameKey[type];
}


export const itemTypeToPackageMapProp: Record<ECRMCanonicalItemsTypes, keyof ICRMPackagesMap> = {
    [ECRMCanonicalItemsTypes.Severity]: "severity",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "urgencyLevel",
    [ECRMCanonicalItemsTypes.SupportLevel]: "supportLevel",
    [ECRMCanonicalItemsTypes.Phase]: "phase",
    [ECRMCanonicalItemsTypes.TicketState]: "ticketState",
    [ECRMCanonicalItemsTypes.CloseState]: "closeState",
};

export function getProjectConfigByItemType(crmProject: ICRMProjectServer, type: ECRMCanonicalItemsTypes): ICRMProjectAttributeConfig | undefined {
    return crmProject[itemTypeToPackageMapProp[type]];
}

export const itemTypeToConfigMapProp: Record<ECRMCanonicalItemsTypes, keyof ICRMProjectConfiguration> = itemTypeToPackageMapProp;

export const itemNSTypeToPackageMapProp: Record<TCRMConfigHeaderNsTypes | TCRMConfigItemsNsTypes, keyof ICRMPackagesMap> = {
    [ENonSerializableObjectType.crmHeaderSeverity]: "severity",
    [ENonSerializableObjectType.crmHeaderUrgencyHeaderLevel]: "urgencyLevel",
    [ENonSerializableObjectType.crmHeaderSupportLevel]: "supportLevel",
    [ENonSerializableObjectType.crmHeaderPhase]: "phase",
    [ENonSerializableObjectType.crmHeaderState]: "ticketState",
    [ENonSerializableObjectType.crmHeaderCloseState]: "closeState",
    [ENonSerializableObjectType.crmItemSeverity]: "severity",
    [ENonSerializableObjectType.crmItemUrgencyLevel]: "urgencyLevel",
    [ENonSerializableObjectType.crmItemSupportLevel]: "supportLevel",
    [ENonSerializableObjectType.crmItemPhase]: "phase",
    [ENonSerializableObjectType.crmItemState]: "ticketState",
    [ENonSerializableObjectType.crmItemCloseState]: "closeState"
};

export function getProjectConfigByItemNSType(crmProject: ICRMProjectServer, nsType: ENonSerializableObjectType): ICRMProjectAttributeConfig | undefined {
    const configKey = itemNSTypeToPackageMapProp[nsType];
    const config = crmProject[configKey] as ICRMProjectAttributeConfig;
    return config;
}

export type TStatusInteractionProp = `id${string}Item`;

const itemPropToNameMap: Record<TStatusInteractionProp, string> = {
    "idPhaseItem": "fase",
    "idSeverityItem": "severidade",
    "idCloseStateItem": "status de fechamento",
    "idTicketStateItem": "status de ticket",
    "idSupportLevelItem": "nível de suporte",
    "idUrgencyLevelItem": "nível de urgência"
};

export function getCRMStatusNameByProperty(changedProp: string): string {
    return itemPropToNameMap[changedProp];
}


const itemTypeToNameMap: Record<ECRMCanonicalItemsTypes, string> = {
    [ECRMCanonicalItemsTypes.Phase]: "fase",
    [ECRMCanonicalItemsTypes.Severity]: "severidade",
    [ECRMCanonicalItemsTypes.CloseState]: "status de fechamento",
    [ECRMCanonicalItemsTypes.TicketState]: "status de ticket",
    [ECRMCanonicalItemsTypes.SupportLevel]: "nível de suporte",
    [ECRMCanonicalItemsTypes.UrgencyLevel]: "nível de urgência"
};

export function getCRMStatusNameByType(type: ECRMCanonicalItemsTypes): string {
    return itemTypeToNameMap[type];
}