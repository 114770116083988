import * as Jimp from 'jimp';
import { $ValueOf, Assert, Compute, DeepReMapObject } from "../tools/utility-types";

type $Fonts = Pick<typeof Jimp, Extract<keyof typeof Jimp, `FONT${string}`>>;

export enum EJimpFonts {
    FontSans8Black = "FONT_SANS_8_BLACK",
    FontSans10Black = "FONT_SANS_10_BLACK",
    FontSans12Black = "FONT_SANS_12_BLACK",
    FontSans14Black = "FONT_SANS_14_BLACK",
    FontSans16Black = "FONT_SANS_16_BLACK",
    FontSans32Black = "FONT_SANS_32_BLACK",
    FontSans64Black = "FONT_SANS_64_BLACK",
    FontSans128Black = "FONT_SANS_128_BLACK",
    FontSans8White = "FONT_SANS_8_WHITE",
    FontSans16White = "FONT_SANS_16_WHITE",
    FontSans32White = "FONT_SANS_32_WHITE",
    FontSans64White = "FONT_SANS_64_WHITE",
    FontSans128White = "FONT_SANS_128_WHITE",
}

// type _$EJimpFonts =
//     Compute<
//         UnionToIntersection<
//         $ValueOf<
//             { [key in keyof $Fonts]: { [$key in key as PascalCase<never, $Words<$key>>]: key } }
//         >
//         >
//     >
// ;
// suggestions<_$EJimpFonts>()(EJimpFonts);

export enum EMashupSize {
    size8 = "8",
    size10 = "10",
    size12 = "12",
    size14 = "14",
    size16 = "16",
    size32 = "32",
    size64 = "64",
    size128 = "128",
}

export enum EMashupColor {
    Black = "BLACK",
    White = "WHITE",
}

// suggestions<DeepReMapObject<{ [key in TJimpFontInfos['size']]: { [size in key as `size${Capitalize<key>}`]: size } }>>()(
//     EMashupSize
// );

// suggestions<DeepReMapObject<{ [key in TJimpFontInfos['color']]: { [color in key as Capitalize<Lowercase<key>>]: color } }>>()(
//     EMashupColor
// );



export type TJimpFontInfo = {
    [$FontName in EJimpFonts]:
        $ExtractJimpFontInfo<Assert<$FontName, `FONT_${string}`>> extends infer Info ?
        Info
        : never
}
export type TJimpFontInfos = $ValueOf<TJimpFontInfo>;

export type JimpInfos = DeepReMapObject<{
    [$FontName in keyof TJimpFontInfo]:
        TJimpFontInfo[$FontName] extends IJimpFontInfo<infer Name, infer Size, infer Color> ?
        {
            [name in Name]: Compute<{
                [size in Size]: Compute<
                    Assert<{ [color in Color]: true }, IJimpHashInfo>
                >
            }>
        }
        : never
}>;


export type HashJimp<T> = DeepReMapObject<{
    [$FontName in keyof TJimpFontInfo]:
        TJimpFontInfo[$FontName] extends IJimpFontInfo<infer Name, infer Size, infer Color> ?
        {
            [name in Name]: Compute<{
                [size in Size]: Compute<
                    { [color in Color]: T }
                >
            }>
        }
        : never
}>;


export interface IJimpHashInfo {
    BLACK?: boolean,
    WHITE?: boolean,
}
export const jimpInfos: JimpInfos = {
    SANS: {
        8: {
            BLACK: true,
            WHITE: true,
        },
        10: {
            BLACK: true,
        },
        12: {
            BLACK: true,
        },
        14: {
            BLACK: true,
        },
        16: {
            BLACK: true,
            WHITE: true,
        },
        32: {
            BLACK: true,
            WHITE: true,
        },
        64: {
            BLACK: true,
            WHITE: true,
        },
        128: {
            BLACK: true,
            WHITE: true,
        },
    },
}

export interface IJimpFontInfo<Name extends string, Size extends string, Color extends string> {
    name: Name;
    size: Size;
    color: Color;
}

export type GetFont<Name extends keyof JimpInfos, Size extends (keyof JimpInfos[Name] & string), Color extends (keyof JimpInfos[Name][Size] & string)> = IJimpFontInfo<Name, Size, Color>


export type $JimpFontInfo<Name extends string, Size extends string, Color extends string> = `FONT_${Name}_${Size}_${Color}`;

type $ExtractJimpFontInfo<T extends `FONT_${string}`> =
    T extends $JimpFontInfo<infer Name, infer Size, infer Color> ?
        Compute<
        IJimpFontInfo<
        Name,
        Size,
        Color
        >
        >
    : never
;

// type $Words<T extends string> = Split<T, '_'>;
