import {Serializable} from '../business/serializable';
import {constant, TGlobalUID} from '../business/constant';
import {ILanguageJSON} from '../comm-interfaces/business-interfaces';
import {UberCache} from '../persistency/uber-cache';

export type TLanguageArray = Array<Language>;

export class Language extends Serializable {
    private readableName: string;
    private googleCode: string;

    private constructor(idLanguage: TGlobalUID, name: string, readableName: string) {
        super(constant.objectType.language, idLanguage);
        this.readableName = readableName
    }

    public toJSON(): ILanguageJSON {
        let json: ILanguageJSON = {
             ...super.toJSON(),
              readableName: this.readableName,
              googleCode: this.googleCode
            }
        return json;
    };

    public getLanguageID(): TGlobalUID {return super.getPrimaryID()};
    public getLanguageName(): string {return super.getName()}
    public getReadableLanguageName(): string {return this.readableName};
    public getGoogleLanguageCode(): string {return this.googleCode; };

    public rehydrate(json: ILanguageJSON): void {
        super.rehydrate(json);
        this.readableName = json.readableName;
        this.googleCode = json.googleCode;
    };

    public static factoryMessage(json: ILanguageJSON): Language {
        let language: Language = <Language>UberCache.uberFactory(json.primaryID, constant.objectType.language, false);
        if (language == null) {
            language =  new Language(json.primaryID, 
                                    Language.getJText(json,constant.serializableField.name),
                                    json.readableName);
        };
        language.rehydrate(json);
        return language;
    }

    public static staticFactory(idLanguage: TGlobalUID): Language {
        return <Language>UberCache.uberFactory(idLanguage, constant.objectType.language, true);
    };

    public static getLanguageArray(): TLanguageArray {
        return <TLanguageArray>UberCache.getSerializableArray(constant.objectType.language);
    };

};
