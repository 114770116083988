import { Component, Inject } from '@angular/core';
import { GeneralFormField } from '@colmeia/core/src/general-form/general-form-answer';
import { getEngangementOfTypeForIDField } from '@colmeia/core/src/shared-business-rules/bot/engagement-function';
import { ELayoutElement, EMetadataEngagementType, ILayoutFieldMapper, IMetaEngagement } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { formatDataTime, isInvalid, updateFieldSubset, isValidNumber } from '@colmeia/core/src/tools/utility';
import { verticalAppear } from 'app/components/dashboard/dashboard-animations';
import { GeneralFormFieldInjectionToken, GeneralFormFieldRenderer, IGeneralFormFieldData } from 'app/model/general-form.model';
import { GeneralFormFieldService } from '../services/general-form-field.service';

@Component({
    selector: 'app-general-form-date-field',
    templateUrl: './general-form-date-field.component.html',
    styleUrls: ['./general-form-date-field.component.scss'],
    animations: [verticalAppear]
})
export class GeneralFormDateFieldComponent extends GeneralFormFieldRenderer<null> {

    public showAsCalendar: boolean = false;
    private engagement: IMetaEngagement;

    constructor(
        generalFormFieldService: GeneralFormFieldService,
        @Inject(GeneralFormFieldInjectionToken) injectedToken: IGeneralFormFieldData,
    ) {
        super(generalFormFieldService, injectedToken);
        this.engagement = this.generalFormEvaluator.getEngagement()
    }

    async ngOnInit() {
        super.ngOnInit();

        if (typeof this.field.value === 'number')
            this.fillNewDateFormat(this.field.value, this.field)
        ;

        this.showAsCalendar = this.isEdit && getEngangementOfTypeForIDField<ILayoutFieldMapper>(
            this.field.idProperty,
            this.engagement,
            EMetadataEngagementType.layout
        )?.type === ELayoutElement.calendar;

        if(this.showAsCalendar && !isValidNumber(this.field.offset)) {
            this.date = new Date();
            this.fillNewDateFormat(this.date.getTime(), this.field);
        }
    }

    public getFieldPropertiesByTime(time: number): Pick<GeneralFormField, 'clockTick' | 'offset' | 'value'> {
        return {
            value: formatDataTime(new Date(time)),
            clockTick: Date.now(),
            offset: time,
        };
    }

    public date: Date;
    public set time(date: Date) {
        this.date = date;
        this.fillNewDateFormat(this.date.getTime(), this.field);
    }
    public get time(): Date {
        if (isInvalid(this.date)) this.date = new Date(this.field.offset);
        return this.date;
    }

    public fillNewDateFormat(time: number, field: GeneralFormField): void {
        const props = this.getFieldPropertiesByTime(time);

        updateFieldSubset(field, props);
    }
}
