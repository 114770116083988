import {Participant} from '../business/participant';
import {Avatar,groupRelated} from '../business/avatar';
import {constant, TGlobalUID} from '../business/constant';
import {Interaction} from './interaction'
import {InteractionType} from './interaction-type';
import {ISetSpokeAvatarJSON} from '../comm-interfaces/interaction-interfaces';
import {UberCache} from '../persistency/uber-cache';


export class Spokeperson extends Interaction {

    private spokeAvatar: Avatar;

    private constructor(primaryID: TGlobalUID, participant: Participant, nickName: string) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.special.setSpokeAvatar));
        super.setName(nickName);
        this.spokeAvatar = Avatar.getNewAvatar(null, nickName, groupRelated);
        this.spokeAvatar.setParentEntityID(participant.getGroupID());
    };

    public setSpokeAvatar(avatar: Avatar): void { this.spokeAvatar = avatar; }
    public getSpokeAvatar(): Avatar { return this.spokeAvatar; }


    public toJSON(): ISetSpokeAvatarJSON {
        return {...super.toJSON(), idSpokeAvatar: this.spokeAvatar.getAvatarID(),
                    avatar: this.spokeAvatar.toJSON()};
    };

    public rehydrate(json: ISetSpokeAvatarJSON): void {
        super.rehydrate(json);
        this.spokeAvatar = <Avatar>UberCache.uberFactory(json.idSpokeAvatar, constant.objectType.avatar, false);
    };

    public static getNewSpokeperson(participant: Participant, nickName: string): Spokeperson {
        return new Spokeperson(null, participant, nickName);
    }

    public static staticFactory(idSpokePerson: TGlobalUID): Spokeperson {
        return <Spokeperson>Interaction.staticFactory(idSpokePerson);
    };

    public static factoryMessage(json: ISetSpokeAvatarJSON, participant: Participant = null): Spokeperson {
        let spoke: Spokeperson = <Spokeperson>Interaction.searchCachedInteraction(json.primaryID);
        if (spoke == null) 
            spoke = new Spokeperson(json.primaryID, 
                                        participant? participant: Participant.staticFactory(json.participant.primaryID),
                                        Spokeperson.getJText(json, constant.serializableField.name));
        spoke.rehydrate(json);
        return spoke;
    };






    



}
