import { Component, OnInit } from '@angular/core';
import { EBPMMarketingActionType, IBPMMarketingAcknowledgeServer, TBpmMarketingNaturalNsTypes } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { MKTAckBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { IBPMListenerHandler } from '../../bpm/bpm-listener/bpm-listener.handler';
import { NewConditionEditorHandler, NewConditionEditorParameter } from '../../new-condition-editor/new-condition-editor.component';
import { MarketingBpmBase } from '../marketing-bpm-base.component';

@Component({
    selector: 'app-marketing-bpm-ack',
    templateUrl: './marketing-bpm-ack.component.html',
    styleUrls: ['./marketing-bpm-ack.component.scss']
})
export class MarketingBpmAckComponent extends MarketingBpmBase<MKTAckBpmGraphCreateHandler, IBPMMarketingAcknowledgeServer> implements OnInit {
    nsType = ENonSerializableObjectType.bpmMarketingAcknowledge as TBpmMarketingNaturalNsTypes

    evaluatorsOfActions: NewConditionEditorHandler;
    bpmListenerHandler: IBPMListenerHandler;
    metadata: SchemaPropertyServer

    ngOnInit() {
        super.ngOnInit();
        this.initConditionsEditor();
        this.initBPMListenerHandler();
    }

    private initConditionsEditor() {
        const parameter: NewConditionEditorParameter = {
            field: undefined,
            fields: this.metadata?.schemma.form || [],
            clientCallback: undefined,
            allowedActionTypes: {
                [EBPMMarketingActionType.messageWithCampaing]: true,
                [EBPMMarketingActionType.goCampaignWithBot]: true,
            },
            currentConditions: this.ns.listenConditions,
            allowedSourceInfo: [ESourceOfInfo.campaignAction, ESourceOfInfo.canonical, ESourceOfInfo.property],
            disableAutoAdd: true,
        }

        this.evaluatorsOfActions = new NewConditionEditorHandler(parameter);
    }

    private initBPMListenerHandler() {
        this.bpmListenerHandler = {
            listener: this.ns,
            disabled: this.isEdit,
            clientCallback: {
                onFormLoad: metadata => {
                    this.metadata = metadata;
                    this.initConditionsEditor();
                }
            }
        }
    }

    getNS(): IBPMMarketingAcknowledgeServer {
        return this.handler.getMarketingAck();
    }

    nsFactory(): IBPMMarketingAcknowledgeServer {
        return super.nsFactory({
            idSubscribeTo: undefined,
            type: this.handler.getParams().eSubscribeType,
        } as Partial<IBPMMarketingAcknowledgeServer>) as IBPMMarketingAcknowledgeServer;
    }

}
