import { OverlayRef } from "@angular/cdk/overlay";
import { secToMS } from "@colmeia/core/src/time/time-utl";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { Subject } from "rxjs";
import { TNotificationDialogConfig } from "./notification-dialog.model";

const animationDurationMS = 200;

export class NotificationDialogRef<D = unknown> {
    static overlayDisposeTimeoutMS: number = secToMS(60);

    public visible: boolean = false;
    public closed = new Subject<{ ref: NotificationDialogRef, byUser: boolean }>();
    public _update = new Subject<NotificationDialogRef>();
    public _offsetY: number;

    private autoCloseTimeout: NodeJS.Timeout;
    private overlayDisposeTimeout: NodeJS.Timeout;
    private hideTimeout: NodeJS.Timeout;

    constructor(
        readonly overlay: OverlayRef,
        public config: TNotificationDialogConfig<D>
    ) {}

    public show() {
        clearTimeout(this.overlayDisposeTimeout)
        clearTimeout(this.hideTimeout)

        this.visible = true;
        this.enableTransition();

        this.setupAutoCloseTimeout();
    }

    public update() {
        this._update.next();
        this.setupAutoCloseTimeout();
    }

    private enableTransition(enable: boolean = true) {
        const { overlayElement } = this.overlay;
        overlayElement?.classList[enable ? 'add' : 'remove']("enable-transition");
    }

    private setupAutoCloseTimeout() {
        if (!isValidRef(this.config.duration)) return;

        clearTimeout(this.autoCloseTimeout);

        this.autoCloseTimeout = setTimeout(() => {
            this.close();
        }, this.config.duration);
    }

    close(byUser: boolean = false) {
        clearTimeout(this.autoCloseTimeout);
        clearTimeout(this.hideTimeout);
        clearTimeout(this.overlayDisposeTimeout);

        this.visible = false;
        this._updateStyle();

        this.hideTimeout = setTimeout(() => {
            this.closed.next({ ref: this, byUser });
            this.enableTransition(false);
        }, animationDurationMS);

        this.overlayDisposeTimeout = setTimeout((overlay) => {
            overlay.dispose()
        }, NotificationDialogRef.overlayDisposeTimeoutMS, this.overlay);
    }

    _updateStyle() {
        this.overlay.overlayElement?.style.setProperty('transform', `translate(${ this.visible ? "0" : "150%" },${this._offsetY}px)`);
    }
}
