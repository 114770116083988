import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isInvalidArray, isValidArray } from '@colmeia/core/src/tools/utility';
import { EHexagonSizes, HandlerHexagonon } from "../../../handlers/hexagono.handler";
import { EToolsVisualizationMode, IToolsHanlder, ToolSection, TToolsData } from "../../../model/tools.model";

@Component({
    selector: 'app-tools',
    templateUrl: './tools.component.html',
    styleUrls: ['./tools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolsComponent {
    public hexagonList: HandlerHexagonon[];
    public EToolsVisualizationMode = EToolsVisualizationMode;

    private _handler: IToolsHanlder;
    @Input()
    set handler(value: IToolsHanlder) {
        console.log({ToolsComponent: value});
        
        this._handler = value;

        if (isValidArray(this._handler.sections)) {
            this._handler.sections.forEach((section: ToolSection) => {
                if (isInvalidArray(section.hexagons)) {
                    section.hexagons = section.serializables.map(s => HandlerHexagonon.newForIcon(s, EHexagonSizes.xsmd));
                }
            });
        } else {
            this._handler.sections = [];
        }
    }
    get handler(): IToolsHanlder {
        return this._handler;
    }

    get sections(): TToolsData {
        return this.handler.sections;
    }

    get visualizationType(): EToolsVisualizationMode {
        return this.handler.visualizationMode;
    }
}
