import { Directive, ElementRef, Host, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';

type TLoadingIndicatorPosition = "left" | "center" | "right";

@Directive({
  selector: '[loading]'
})
export class LoadingDirective implements OnInit, OnChanges {
  private static PrefixClasses = "loading-directive";
  private static ContainerClassName = `${LoadingDirective.PrefixClasses}-element`;
  private static ActiveClassName = `${LoadingDirective.PrefixClasses}-active`;

  private hostRef: HTMLElement;

  @Input() loading: boolean = false;
  @Input() loadingPos: TLoadingIndicatorPosition = "center";

  constructor(
    private el: ElementRef<any>,
    @Host() @Self() @Optional() private matFormField: MatFormField
  ) { }

  public ngOnInit() {
    this.hostRef = this.matFormField?._elementRef.nativeElement || this.el.nativeElement;
    this.hostRef.classList.add(LoadingDirective.ContainerClassName);
    this.hostRef.classList.add(`${LoadingDirective.PrefixClasses}-${this.loadingPos}`);
    this.updateHostClasses();
  }

  public ngOnChanges(_changes: SimpleChanges) {
    this.updateHostClasses();
  }

  private updateHostClasses() {
    this.loading
      ? this.hostRef?.classList.add(LoadingDirective.ActiveClassName)
      : this.hostRef?.classList.remove(LoadingDirective.ActiveClassName);
  }
}
