import { Injectable } from '@angular/core';
import { INonSerializable, ENonSerializableObjectType, TINonSerializableArray } from '../../../../../../core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { BotTransactionService } from 'app/services/bot-transaction.service';
import { ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';

@Injectable({
    providedIn: 'root'
})
export class BpmNestedAIApiService {

    constructor(private botTransactionSvc: BotTransactionService) { }

    async save(nser: INonSerializable): Promise<TINonSerializableArray> {
        switch (nser.nsType) {
            case ENonSerializableObjectType.contentGenerator:
                // should check if is root ?
                return [await this.botTransactionSvc.save(<ITransactionServer>nser)]
                break;
            default:
                throw new Error("BpmNestedAIApiService: unknown ENonSerializableObjectType");
        }
    }
}
