import { isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { IMacroEntry } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { IMacroPackageServer } from "../../../attendent-service-pack/attendente-service-pack";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";
import { attendantServicePackErrors } from '../error-fields';
import { screenOptionToError } from '../error-messages';
import { IMetadataRegister } from '../../../metadata/meta-engagement';

const errors = screenOptionToError(attendantServicePackErrors);

export const attendantServicePackValidator: TNserValidator = (nser: IMacroPackageServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);

  if (nser.macros.some(macro => !isValidMacro(macro))) {
    friendly.addReturnResponse(errors.invalidMacros)
  }

  if (!isValidRef(nser.finalizationMetadata)) {
    friendly.addReturnResponse(errors.emptyFinalizationFrom)
  }

  const metadata: IMetadataRegister = nser.attendantDynamicListRegisterMedatadas.find(metadataRegister => ('isDynamicList' in metadataRegister));
  if (metadata?.isDynamicList === true && !metadata?.idDatabase) {
    friendly.addReturnResponse(errors.emptyDatabaseId);
  }

  return friendly
}

function isValidMacro(macro: IMacroEntry): boolean {
  return isValidString(macro.name) && isValidString(macro.text);
}