import { IComponentParameter } from "app/model/component-comm/basic";
import { MainHandler } from "app/handlers/main-handler";
import { IConfigureCanonical } from "@colmeia/core/src/shared-business-rules/bot/bot-model";

export interface ICanonicalPickerEditCanonicalConfigParameters extends IComponentParameter {
    config: IConfigureCanonical;
}

export interface ICanonicalPickerSlave {
    updatePossibleTemplateVariables(): Promise<void>
}

export class CanonicalPickerEditCanonicalConfigHandler extends MainHandler {
    private constructor(parameter: ICanonicalPickerEditCanonicalConfigParameters) { super(parameter) }
    public static factory(parameter: ICanonicalPickerEditCanonicalConfigParameters) { return new CanonicalPickerEditCanonicalConfigHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as ICanonicalPickerEditCanonicalConfigParameters }
    public slave: ICanonicalPickerSlave;
}
