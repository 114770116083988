import { Injectable } from '@angular/core';
import { I360Media } from '@colmeia/core/src/core-constants/bot';
import { IFormSchema } from '@colmeia/core/src/general-form/general-form-interface';
import { apiRequestType, EJobProcess } from '@colmeia/core/src/request-interfaces/message-types';
import { IUserFunctionsGetPayloadRequest, IUserFunctionsGetPayloadResponse } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { EFilterConversationsOptions, IAllCustomerActivityRequest, IAllCustomerActivityResponse, IConversationDetailsWithMedia, IConversationResponse, ICustomerConversationRenewStorageLinkRequest, ICustomerConversationRenewStorageLinkResponse, ICustomerConversationRequest } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IUserFunctionRequestPayloadEntity } from '@colmeia/core/src/shared-business-rules/user-function/user-function.interfaces';
import { entries, isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { GeneralFormService } from 'app/services/general-form.service';
import { LookupService } from 'app/services/lookup.service';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { Observable, ReplaySubject } from 'rxjs';
import { DEFAULT_CONVERSATIONS_PER_PAGE } from './attendance-conversation-history-view/attendance-conversation-history-view.component';

@Injectable({
    providedIn: 'root'
})
export class AttendanceConversationService {

    isSearching: boolean;

    private schemaCacheMap: Map<string, ReplaySubject<IFormSchema>> = new Map();
    public nsCache: Record<string, INonSerializable> = {};
    public schemaCache: Record<string, IFormSchema> = {};
    private conversationsCache: Map<string, IConversationDetailsWithMedia> = new Map();
    private userFunctionsApiRequests: Map<string, IUserFunctionRequestPayloadEntity[]> = new Map();

    constructor(
        private lookupSvc: LookupService,
        private generalFormSvc: GeneralFormService,
        private api: ServerCommunicationService,
        private rbs: RequestBuilderServices
    ) { }

    public async getNSs(idNSs: string[]): Promise<Record<string, INonSerializable>> {
        const response: Record<string, INonSerializable> = {};

        entries(this.nsCache).forEach(([idNS, ns]) => {
            if (idNSs.includes(idNS)) {
                response[idNS] = ns;
            }
        });

        const toLoad: string[] = [...idNSs].filter((idNS) => !response[idNS]);

        if (isValidArray(toLoad)) {
            const nss = await this.lookupSvc.getBatchNonSerializables(toLoad);

            nss.forEach((ns) => {
                this.nsCache[ns.idNS] = ns;
                response[ns.idNS] = ns;
            });
        }

        return response;
    }

    public getSchema(idSchema: string): Observable<IFormSchema> {
        let subject: ReplaySubject<IFormSchema> = this.schemaCacheMap.get(idSchema);
        if (subject) { return subject; }

        subject = new ReplaySubject<IFormSchema>(1);

        this.schemaCacheMap.set(idSchema, subject);

        this.generalFormSvc.getSpecificSchema(idSchema).then((schema) => {
            subject.next(schema);
        });

        return subject;
    }

    public async getConversationHistories(idSocialKey: string, begin: number, end: number, cursor: string | undefined = undefined, perPage: number = DEFAULT_CONVERSATIONS_PER_PAGE, filterConversationsBy?: EFilterConversationsOptions): Promise<IAllCustomerActivityResponse> {
        try {
            this.isSearching = true;
            const request: IAllCustomerActivityRequest = {
                ...this.rbs.secureBasicRequest(apiRequestType.analytics.htmlReports.allCustomerContactHeader),
                idSocialKey,
                begin,
                end,
                limit: perPage,
                cursor,
                filterConversationsBy
            };
            const response = await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);
            return response?.response as IAllCustomerActivityResponse;
        } catch (err) {
            throw err;
        } finally {
            this.isSearching = false;
        }

    }

    public async getConversation(idConversation: string, target?: string): Promise<IConversationDetailsWithMedia> {
        try {
            this.isSearching = true;
            const fromCache = this.conversationsCache.get(idConversation);

            if (isValidRef(fromCache)) {
                return fromCache;
            }

            const request: ICustomerConversationRequest = {
                ...this.rbs.secureBasicRequest(apiRequestType.analytics.htmlReports.contactDetail),
                idConversation,
                target
            };
            const response = await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);
            const conversationDetails: IConversationDetailsWithMedia = (response.response as IConversationResponse).response;

            this.conversationsCache.set(idConversation, conversationDetails);
            return conversationDetails;
        } catch (err) {
            throw err;
        } finally {
            this.isSearching = false;
        }
    }

    public removeConversationFromCache(idConversation: string) {
        this.conversationsCache.delete(idConversation);
    }

    public removeUserFunctionLogsFromCache(idConversation: string) {
        this.userFunctionsApiRequests.delete(idConversation);
    }

    public async getUserFunctionApiRequests(idConversation: string, target: string) {
        try {
            this.isSearching = true;

            const fromCache = this.userFunctionsApiRequests.get(idConversation);

            if (isValidRef(fromCache)) {
                return fromCache;
            }

            const request: IUserFunctionsGetPayloadRequest = {
                ...this.rbs.secureBasicRequest(EJobProcess.getPayloads),
                idConversation,
                target,
            }
            const response = await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);
            const entities: IUserFunctionRequestPayloadEntity[] = (response.response as IUserFunctionsGetPayloadResponse).entities;

            this.userFunctionsApiRequests.set(idConversation, entities);
            return entities;
        } catch (error) {
            console.error({ error });
        } finally {
            this.isSearching = false;
        }
    }

    async renewExpiredStorageMedia(botLogId: string, idMedia: string): Promise<I360Media> {
        try {
            const request: ICustomerConversationRenewStorageLinkRequest = {
                ...this.rbs.secureBasicRequest(apiRequestType.analytics.htmlReports.renewStorageLink),
                botLogId,
                idMedia,
            };
            const response = await this.api.managedRequest(this.rbs.getContextNoCallBackSpinnningParameters(), request);

            return (response.response as ICustomerConversationRenewStorageLinkResponse).new360Media;;
        } catch (err) {
            throw err;
        } finally {
            this.isSearching = false;
        }
    }
}

