import { MainSignal } from './main-signal';
import { observableList } from './signal-constants';
import {TExecutableItemArray} from "../../services/controllers-services/security-controller/executable-item";
import {TGlobalUID, TGroupArray} from "@colmeia/core/src/core-constants/types";
import {Participant, TParticipantArray} from "@colmeia/core/src/business/participant";
import {Avatar} from "@colmeia/core/src/business/avatar";
import {Group} from "@colmeia/core/src/business/group";
import {TThreadArray} from "@colmeia/core/src/interaction/thread";
import {PlayerCachedInfo} from "@colmeia/core/src/business/player-cached";
import {GroupSubscription} from "../group-subscription.model";
import {
    IDataBuilder,
    SafeMenuBuilderServices
} from "../../services/controllers-services/security-controller/safe-menu-builder";
import {SecurityGroupNavigator} from "../../services/controllers-services/security-controller/security-group-navigator";
import {hashToArray} from "@colmeia/core/src/tools/utility";
import {UberCache} from "@colmeia/core/src/persistency/uber-cache";
import {EScreenGroups} from "@colmeia/core/src/shared-business-rules/visual-constants";

export class SubscriptionSignal extends MainSignal {

    private dropDownMenu: TExecutableItemArray = [];
    private childrenGroup: TExecutableItemArray = [];
    private requestedGroups: TExecutableItemArray = [];
    private chatBarGroupList: TExecutableItemArray = [];
    private idGroup: TGlobalUID;
    private participant: Participant;
    private avatar: Avatar;
    private group: Group;
    private participants: TParticipantArray = [];
    private allThreads: TThreadArray = [];
    private allChannels: TGroupArray = [];
    private playerCached: PlayerCachedInfo;


    constructor(
                selectedAvatar: Avatar,
                subscription: GroupSubscription,
                safeMenuData: IDataBuilder,
                empty: boolean = false
    ) {
        super(observableList.subscription, true, subscription);

        this.playerCached = safeMenuData.playerInfo;

        if (!empty) {
            const dropDown: SafeMenuBuilderServices = new SafeMenuBuilderServices(safeMenuData);

            this.dropDownMenu = dropDown.getDropDownFeatures(EScreenGroups.AppbarShortcuts);

            // ChildGroups
            const secureSubGroups: SecurityGroupNavigator = new SecurityGroupNavigator(safeMenuData);
            this.childrenGroup = secureSubGroups.getSecureGroups(subscription.getGroupChilds());
            this.requestedGroups = secureSubGroups.getSecureGroups(subscription.getRequestedGroups());

            // chatBar
            this.chatBarGroupList = secureSubGroups.getChatBarGroupList();

            // build local variables
            this.avatar = selectedAvatar;
            this.idGroup = subscription.getGroup().getGroupID();
            this.participant = safeMenuData.currentParticipant;
            this.group = subscription.getGroup();

            this.participants = this.getAllSelectedGroupParticipants();
            this.allChannels = subscription.getAllChannels();
            this.allThreads = subscription.getAllThreads();
        }
        // DropDown Menu Building
    };

    public getObservableInformation(): GroupSubscription {return <GroupSubscription>super.getObservableInformation(); };

    public getGroupSubscription(): GroupSubscription {return this.getObservableInformation(); };


    public getGroup(): Group {return this.group; };
    public getGroupID(): TGlobalUID {return this.idGroup; };
    public getParticipant(): Participant {return this.participant; };
    public getSubscriptionAvatar(): Avatar {return this.avatar; };
    public hasParticipant(): boolean {return this.participant ? true : false; };

    public getAllSelectedGroupParticipants(): TParticipantArray {
        const participants: {[idParticipant: string]: Participant} = {};
        for (const interaction of this.getGroupSubscription().getInteractions()) {
            const idParticipant: TGlobalUID = interaction.getParticipant().getParticipantID();
            if (UberCache.testCache(idParticipant)) {
                participants[idParticipant] = Participant.staticFactory(idParticipant);
            };
        };
        return <TParticipantArray>hashToArray(participants);
    };


    public getChatBarGroupList(): TExecutableItemArray {return this.chatBarGroupList; };
    public getSafeGroupChildren(): TExecutableItemArray {return this.childrenGroup; };
    public getSafeRequestedGroups(): TExecutableItemArray {return this.requestedGroups; };
    public getDropDownListMenu(): TExecutableItemArray { return this.dropDownMenu; };

    public getParticipants(): TParticipantArray {return this.participants; };

    public getAllThreads(): TThreadArray {return this.allThreads; };
    public getAllChannels(): TGroupArray {return this.allChannels; };

    public hasParticipantOnGroup(idGroup): boolean {
        return this.playerCached.belongsToGroup(idGroup);
    }

    // public getParticipantFromAvatar(avatarId: TGlobalUID, groupId: TGlobalUID) {
    //     return this.playerCached.getParticipantID(groupId, avatarId)
    // }

};
