import { isValidArray } from "../../../tools/utility";
import { ECommonEvents } from "./graph-types";
import { TEventRegistryArray, GetCommonEventPayload, TRemoveEmit, IEventRegistry } from "./graph-element-listener.types";

export class GraphEventListener {

    private events: TEventRegistryArray;

    constructor(events: TEventRegistryArray) {
        this.events = events || [];
    }

    public hasEvents(): boolean {
        return isValidArray(this.events)
    };

    public getEvents(): TEventRegistryArray {
        return this.events;
    }
   
    public on<EventName extends ECommonEvents>(eventName: EventName, callback: (payload: GetCommonEventPayload<EventName>) => void): TRemoveEmit {
        const event: IEventRegistry<EventName> = {
            eventName,
            callback,
        }
        this.events.push(event);
        return this.createRemoveEvent(event);
    }

    public emit<EventName extends ECommonEvents>(eventName: EventName, payload: GetCommonEventPayload<EventName>) {
        this.events.filter(event => event.eventName === eventName).forEach(event => event.callback(payload))
    }

    public createRemoveEvent(event: IEventRegistry) {
        return (): void => {
            this.events.splice(this.events.indexOf(event), 1)
        }
    }

}