import { MessageInstanceHandler, IMessageHandlerCallback, IMessageEndPointCallback, IMessageInstanceParameter } from 'app/handlers/message-instance-handler/message-instance-handler';
import { ChangeDetectorRef } from '@angular/core';
import { MessageHandlerCallbak } from 'app/handlers/message-instance-handler/message-handler-callback';
import { SignalListenerService } from 'app/services/signal/signal-listener';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { getUniqueStringID } from '@colmeia/core/src/tools/utility';
import { IChangeInterfaceListener } from 'app/model/signal/ps-interfaces';
import { InterfaceInfoSignal } from 'app/model/signal/interface-signal';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { ReactDisplayBarHandler } from 'app/handlers/react-display-handler';
import { getPubIDForGroup } from '@colmeia/core/src/rules/interaction-filter';


export interface IWorkingStructure {
    messageHandler: MessageInstanceHandler;
    currentCDR: ChangeDetectorRef;
    listener: SignalListenerService;
 }

export abstract class HighLevelMessage implements IChangeInterfaceListener, IMessageEndPointCallback {
  
    private structures: IWorkingStructure;
    private parentMessageCallback: IMessageHandlerCallback; // Pode ser do MessageContainer
    private nestedInteractionsCallback: MessageHandlerCallbak;
    private instanceUID: TGlobalUID;
    private isMessageBeingEdited: boolean = false;
    private messageInstanceParamenter: IMessageInstanceParameter;

    constructor(working: IWorkingStructure) {
        this.instanceUID = getUniqueStringID();
        this.structures = working;
        this.parentMessageCallback = working.messageHandler.getComponentParameter().clientCallback;
        this.messageInstanceParamenter = working.messageHandler.getComponentParameter();
        this.nestedInteractionsCallback = this.getMessageHandler().getNextLevelHandler();
        this.addEndPoint(this);
        this.getListener().listenToInterfaceChanges(this, this.instanceUID);
        this.sendReadConfirmation();
    };

    private sendReadConfirmation(): void {
        try {
            const interaction: Interaction =  this.getMessageHandler().getInteraction();
            const idGroup = this.getGroupID();
            if (!interaction.isReadByMe(idGroup)) {
                interaction.setReadByMe(idGroup);
                const idPublishingGroup: string = getPubIDForGroup(idGroup,interaction.getPublishingGroups())
                this.messageInstanceParamenter.chatBackbone.getGroupChatService()
                    .sendReadInformation(idPublishingGroup, interaction.getInteractionType().getInteractionTypeID())
            }
            
        } catch(err) {
            
        }
        
    };
 
    public finish(): void {
        this.nestedInteractionsCallback.removeEndpoint(this);
        this.getListener().destroySubscriptions(this);
    }

    public getInstanceID(): TGlobalUID {return this.instanceUID; };

    // Funcoes que pegam o que os componentes tem como private mas que não podem 
    // ser guardados aqui devido a herança
    public getMessageHandler(): MessageInstanceHandler {return this.structures.messageHandler;};
    private getListener(): SignalListenerService {return this.structures.listener; };
    private getCDR(): ChangeDetectorRef {return this.structures.currentCDR}


    public  getNextHandler(): MessageHandlerCallbak {return this.nestedInteractionsCallback; };
    public  getParentMessageCallback(): IMessageHandlerCallback {return this.parentMessageCallback ;}

    private addEndPoint(endPoint: IMessageEndPointCallback): void {
        this.nestedInteractionsCallback.addEndpoint(endPoint);
    };


    public receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        this.onMsgHighLightComplete();
    };

    public onMsgHighLightStarted(): void {
        this.isMessageBeingEdited = true
        this.getCDR().markForCheck();
    }

    public onMsgHighLightComplete(): void {
        this.isMessageBeingEdited = false
        this.getCDR().markForCheck();
    }

    public isMsgBeingEdited(): boolean {
        return this.isMessageBeingEdited
    }

    /// Métodos da Interface 
    public changeAllSiblingsDetection(): void {
        this.getCDR().markForCheck();
    };
    public scrollTop(): void {};
    public detectChangesOnFirstLevelMsgs(): void {
        this.getMessageHandler().detectChangesOnFirstLevelMsgs();
    };
    
    public onMessageReact(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedback: Feedback): void {
        this.getMessageHandler().getChatBackbone().saveReaction(
            interactionID,
            interactionTypeID,
            this.getGroupID(),
            feedback.getInteractionTypeID(),
            feedback.getFeedbackID(),
            this.getMessageHandler().getChatBackbone().getParticipant()
        );
    };

    public getGroupID(): TGlobalUID {
        return this.getMessageHandler().getGroupID();
    };

    public callMessageContainerChangeDetector(refreshVector: boolean): void {
        this.getMessageHandler().getComponentParameter().clientCallback.callMessageContainerChangeDetector(refreshVector);
    };
    
    public changeSpecificSibling(idInteraction: TGlobalUID): void {
        const array = this.getMessageHandler().getNextLevelHandler().getInteractionHandlerArray();
        const handler: MessageInstanceHandler = array
            .find((h) => {return h.getInteraction().is(idInteraction)});
        if (handler) {
            handler.changeDetectionOnMessageInstance();
        };
    };

    public changeDectionChildren(): boolean {
        let processed: boolean;
        const array = this.getMessageHandler().getNextLevelHandler().getInteractionHandlerArray();
        array.forEach((handler) => {
            processed = true;
            handler.changeDetectionOnMessageInstance();
        });
        return processed;
    }

    public removeInteraction(interaction: Interaction): void {
        this.getMessageHandler().getNextLevelHandler().removeInteraction(interaction);
    };

      
    public onScroolTop(): void {
        this.getMessageHandler().getNextLevelHandler().onScroolTop();
    };


    public getDisplayBarReactHandler(): ReactDisplayBarHandler {
        return this.getMessageHandler().getDisplayBarHandler();
    };

    // porque o endpoint messageContainer necessita dessa e usa a mesma interface..
    public onNewInteractionArrivedCallback(isChildInteraction: boolean): void {};

    public getInteraction(): Interaction {
        return this.structures.messageHandler.getComponentParameter().interaction;
    }


}