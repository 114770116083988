import { Component, Input, ViewChild } from '@angular/core';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { NgForm } from '@angular/forms';
import { INLPTransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
    selector: 'bot-transaction-accuracy-data',
    templateUrl: './bot-transaction-accuracy-data.component.html',
    styleUrls: ['./bot-transaction-accuracy-data.component.scss']
})
export class BotTransactionAccuracyDataComponent extends RootComponent<
    'title' |
    'ignoreAccuracyUntil' |
    'ignoredMessage' |
    'confirmAccuracyUntil' |
    'confirmMessage' |
    'fieldRequired' 
> {

    @Input() transaction: INLPTransactionServer;

    @ViewChild(NgForm) form: NgForm;

    constructor() { 
        super({
            title: gTranslations.bot.title,
            ignoreAccuracyUntil: gTranslations.bot.ignoreAccuracyUntil,
            ignoredMessage: gTranslations.bot.ignoredMessage,
            confirmAccuracyUntil: gTranslations.bot.confirmAccuracyUntil,
            confirmMessage: gTranslations.bot.confirmMessage,
            fieldRequired: gTranslations.common.fieldRequired
        });
    }

    isInvalid(): boolean {
        this.form.onSubmit(null);
        return this.form.invalid;
    }

}
