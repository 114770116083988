import { Directive, InjectionToken, Injector } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { EUnitTypeID } from "@colmeia/core/src/business/constant.enums";
import { Serializable } from "@colmeia/core/src/business/serializable";
import {
    GeneralFormField,
    IGeneralFormAnswer,
    IGeneralFormAnswerJson,
    IRFieldResponse,
    TGeneralFormFieldAnswerValue
} from "@colmeia/core/src/general-form/general-form-answer";
import { GeneralFormEvaluator } from "@colmeia/core/src/general-form/general-form-evaluator";
import {
    IFormChoice,
    IFormSchema,
    SchemaProperty,
    TSchemaPropertyArray
} from "@colmeia/core/src/general-form/general-form-interface";
import { EBPMAction } from "@colmeia/core/src/shared-business-rules/BPM/bpm-action-model";
import { getValidEvaluatorsForField } from "@colmeia/core/src/shared-business-rules/bot/engagement-function";
import { SchemaPropertyServer } from "@colmeia/core/src/shared-business-rules/files/files";
import { EMetaEngagementConditionType, EMetadataEngagementType } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { EJSONPropertyNameType } from "@colmeia/core/src/shared-business-rules/metadata/metadata-transform";
import {
    ENonSerializableObjectType,
    INonSerializable
} from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ITranslationConfig, ITranslationHash } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { nonNullable, suggestions } from "@colmeia/core/src/tools/type-utils";
import { getReadableUniqueID, isValidRef, keys } from "@colmeia/core/src/tools/utility";
import { RootComponent } from "app/components/foundation/root/root.component";
import { GeneralFormFieldService } from "app/components/general-form/services/general-form-field.service";
import { Subject } from "rxjs";
import { EFormControlStatus } from "./client-utility-types";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";


export interface IClientModalData {
    serializable: Serializable
    onSaveAnnotationCallback(): void
    selectedSchema?: IFormSchema
}

export const validUnities: string[] = [
    EUnitTypeID.dateType,
    EUnitTypeID.logicalType,
    EUnitTypeID.numberType,
    EUnitTypeID.objectType,
    EUnitTypeID.stringType,
    EUnitTypeID.multipleChoiceType,
];

export type TOptionArray = { label: string, value: string }[];

export interface ISchemaInjectionToken {
    title: string;
    onSave: () => void;
    schema: IFormSchema;
}

export const SchemaInjectionToken = new InjectionToken<ISchemaInjectionToken>('general-form.data');


export function createEmptySchemaPropertyServer(idGroup: string): SchemaPropertyServer {
    const idNS: string = getReadableUniqueID(30);
    const ns: SchemaPropertyServer = {
        nName: '',
        tags: [],
        schemma: createEmptyFormSchema(idNS, idGroup),
        idNS,
        ident: null,
        nsType: ENonSerializableObjectType.formSchemma,
    };
    return ns;
}

export function createEmptySchemaProperty(): SchemaProperty {
    return {
        idProperty: getReadableUniqueID(10),
        idUnity: <EUnitTypeID>'',
        prompt: '',
        propertyName: ''
    }
}

export function createEmptyFormSchema(idProperty?: string, idGroup?: string): IFormSchema {
    return {
        name: '',
        form: [],
        idSchemma: idProperty || getReadableUniqueID(),
        visibility: {
            idGroup,
            myself: true
        },
    };
}

export function countInnerFields(prop: SchemaProperty): number {
    if (prop.idUnity == EUnitTypeID.objectType) {
        let numFields = prop.nestedSchema.length;
        prop.nestedSchema.forEach(nestedField => numFields += countInnerFields(nestedField));
        return numFields;
    }
    return 0;
}

export function countTotalFields(schema: IFormSchema): number {
    let numFields = schema.form.length;
    schema.form.forEach(field => numFields += countInnerFields(field));
    return numFields;
}


export interface IGeneralFormDynamicField {
    field: GeneralFormField;
    injector: Injector;
}

export type TFieldAnswers = Array<IGeneralFormDynamicField>;



export interface IGeneralAnswerListItemServer extends IGeneralAnswerListItem, INonSerializable {
}


export interface IGeneralAnswerListItem extends IGeneralFormAnswer {
    templateName: string;
    userName: string;
    date: string;
}

export interface IAnnotationPreviewData {
    annotation: IGeneralFormAnswer
    template: IFormSchema
}


export class GeneralFormAnswer implements IGeneralFormAnswer {
    primaryID: string;
    idSchemma: string;
    responses: IRFieldResponse[];
    text?: string;
    json: IGeneralFormAnswerJson;

    constructor(answer: IGeneralFormAnswer) {
        Object.assign(this, answer);
    }
}

export function isObjectProp(schemaProp: SchemaProperty): boolean {
    return schemaProp.idUnity === EUnitTypeID.objectType;
}

export function createAllChildPropsArray(schemaProp: SchemaProperty): string[] {
    const props: string[] = [];

    if (isObjectProp(schemaProp)) {
        schemaProp.nestedSchema.forEach(nestedProp => {
            if (isObjectProp(nestedProp)) {
                props.push(...createAllChildPropsArray(nestedProp));
            } else {
                props.push(nestedProp.idProperty);
            }
        });
    }

    return props;
}

export enum EGeneralFormViewType {
    view = 'view',
    edit = 'edit',
    save = 'save',
    upsert = 'upsert',
    callback = 'callback'
}

export interface IGeneralFormFieldDataState {
    fGroup: UntypedFormGroup;
    field: GeneralFormField;
    value: TGeneralFormFieldAnswerValue;
    viewType: EGeneralFormViewType;
    isEdit: boolean;
    generalFormEvaluator?: GeneralFormEvaluator;
    answers: TFieldAnswers;
    fields: GeneralFormField[];
    schemaForm: TSchemaPropertyArray;
    fControl: UntypedFormControl;
    root: UntypedFormGroup;
}

export interface IGeneralFormFieldData extends IGeneralFormFieldDataState {
    parentExpandEvent?: Subject<boolean>; //
    childExpandEvent?: Subject<boolean>; //
    isList?: boolean; //
    childIndex?: number; //
    idServiceInteraction?: TGlobalUID;
}

export const GeneralFormFieldInjectionToken = new InjectionToken<IGeneralFormFieldData>('general-form-field.data');


@Directive()
export class GeneralFormFieldRenderer<T extends string> extends RootComponent<T> {

    public get root(): UntypedFormGroup { return this.state.root }
    public get fGroup(): UntypedFormGroup { return this.state.fGroup }
    public get field(): GeneralFormField { return this.state.field }
    public get value(): TGeneralFormFieldAnswerValue { return this.state.value }
    public set value(value: TGeneralFormFieldAnswerValue) { this.state.value = value }
    public get viewType(): EGeneralFormViewType { return this.state.viewType }
    public get isEdit(): boolean { return this.state.isEdit }
    public get generalFormEvaluator(): GeneralFormEvaluator { return this.state.generalFormEvaluator }
    public get answers(): TFieldAnswers { return this.state.answers }
    public get fields(): GeneralFormField[] { return this.state.fields }
    public get schemaForm(): TSchemaPropertyArray { return this.state.schemaForm }
    public get fControl(): UntypedFormControl { return this.state.fControl }
    public get idStartInteraction(): string | undefined { return this.state.idServiceInteraction }
    public canDisplayField: boolean = true;
    public isRequired: boolean;


    public errorMsg: string = ''
    public _generalFormFieldService: GeneralFormFieldService

    constructor(generalFormFieldService: GeneralFormFieldService, public state: IGeneralFormFieldData, translations?: ITranslationHash) {
        super(translations as { [key in T]: ITranslationConfig });

        this.field.canDisplayField = this.canDisplayField;
        this._generalFormFieldService = generalFormFieldService;
        this.fControl?.setValue?.(this.field.value);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this._generalFormFieldService.removeListenUpdateDisplayFormField(this)

        const index = this.fields.indexOf(this.field);
        if (index !== -1) this.fields.splice(index, 1);
    }

    public statusIcons: { [key in EFormControlStatus]: string } = {
        [EFormControlStatus.VALID]: 'done',
        [EFormControlStatus.INVALID]: 'close',
        [EFormControlStatus.DISABLED]: 'block',
        [EFormControlStatus.PENDING]: 'pending',
    }
    public get statusIcon(): string {
        return this.fControl && this.statusIcons[this.fControl.status];
    }

    public get isDisabled(): boolean {
        return this.fControl && this.fControl.status === EFormControlStatus.DISABLED;
    }

    async ngOnInit() {
        await this.updateDisplayFormField();
        this.listenForFormValueChanges();
        this.evaluateFieldRequirement();
    }

    public subUpdateDisplayFormField = async (): Promise<void> => {
        await this.updateDisplayFormField();
    }

    listenForFormValueChanges() {
        if (!this.canDisplayFormGroup) return;
        this._generalFormFieldService.addListenUpdateDisplayFormField(this);
    }

    public subCheckIfCanEnableFormFieldByOrder = async (): Promise<void> => {
        if (!this.canDisplayFormGroup) return;
        await this._generalFormFieldService.checkIfCanEnableFormFieldByOrder(
            this.fGroup,
            this.generalFormEvaluator,
            this.schemaForm,
            this.field,
            this.fields,
        )
    }

    public async onFocus(): Promise<void> {
        if (!this.canDisplayFormGroup) return;
        return this._generalFormFieldService.onFocusField(
            this.fGroup,
            this.field.idProperty,
        );
    }

    async updateDisplayFormField(): Promise<boolean> {
        if (!this.canDisplayFormGroup) return;
        this.canDisplayField = await this._generalFormFieldService.canDisplayFormField(
            this.fGroup,
            this.generalFormEvaluator,
            this.schemaForm,
            this.field,
            this.fields,
        );

        this.field.canDisplayField = this.canDisplayField;
        return this.canDisplayField;
    }

    public get canDisplayFormGroup(): boolean {
        return isValidRef(this.fGroup) && isValidRef(this.fControl);
    }

    getErrorMsg(): string {
        return this.fControl.errors?.errorMsg
    }

    // removeFieldFromCacheByIdProperty(idProperty: string): void {
    //     const indexes: number[] = this.fields
    //         .filter((field, index) => field.idProperty === idProperty)
    //         .map((field, index) => index)
    //     ;
    //     indexes.forEach(index => this.fields.splice(index, 1))
    // }

    // addFieldToCache(field: GeneralFormField) {
    //     this.removeFieldFromCacheByIdProperty(field.idProperty);
    //     this.fields.push(field);

    //     this.updateFieldsRows();
    // }

    // updateFieldsRows() {
    //     this.fields.forEach((field, row) => field.row = row);
    // }

    private evaluateFieldRequirement() {
        const engagement = this.generalFormEvaluator.getEngagement();
        
        const evaluators = getValidEvaluatorsForField(this.field.idProperty, EBPMAction.validator, engagement, EMetadataEngagementType.NewCondition);
        
        this.isRequired = evaluators?.some(evaluator => {
            return evaluator.matchConditions.some(condition => condition.metaConditionType === EMetaEngagementConditionType.notNull)
        });
    }
}

export interface IPropValueHash {
    [idProperty: string]: TGeneralFormFieldAnswerValue;
}


const propertyNameTypeToProperty = suggestions<{ [key in EJSONPropertyNameType]: keyof SchemaProperty }>()( {
    [EJSONPropertyNameType.propertyName]: 'propertyName',
    [EJSONPropertyNameType.idProperty]: 'idProperty',
    [EJSONPropertyNameType.localCanonical]: 'idLocalCanonical',
})


export function buildPropValueMap(fields: TSchemaPropertyArray, value: IGeneralFormAnswerJson, propertyType: EJSONPropertyNameType = EJSONPropertyNameType.propertyName): IPropValueHash {
    return fields.reduce((acc, field) => {
        const property = nonNullable(propertyNameTypeToProperty[propertyType]);
        const fieldProperty: string = nonNullable(field[property]);

        acc[field.idProperty] = value[fieldProperty];
        return acc;
    }, {});
}

export function getJSONAnswerPropertyTypeByResponses(responses: TSchemaPropertyArray, json: IGeneralFormAnswerJson): EJSONPropertyNameType {
    const jsonPropertyNameTypes: EJSONPropertyNameType[] = keys(propertyNameTypeToProperty);

    for (const response of responses) {
        const found: EJSONPropertyNameType = jsonPropertyNameTypes.find((propertyType: EJSONPropertyNameType) => {
            const property = nonNullable(propertyNameTypeToProperty[propertyType]);
            const fieldProperty = nonNullable(response[property]);
            const answer: TGeneralFormFieldAnswerValue = json[fieldProperty];
            return answer;
        });

        if (isValidRef(found)) return found;
    }
}

export interface IChoiceDisplay {
    choice: IFormChoice
}


