import { isValidFunction, isValidRef } from "@colmeia/core/src/tools/barrel-tools";
import {MainHandler} from "../../../../handlers/main-handler";
import {GenericDashboardPaginationParameter} from "./generic-dashboard-pagination.parameter";

export class GenericDashboardPaginationHandler extends MainHandler {

    constructor(parameter: GenericDashboardPaginationParameter) {
        super(parameter);
    }

    getComponentParameter(): GenericDashboardPaginationParameter {
        return <GenericDashboardPaginationParameter>super.getComponentParameter();
    }

    getLastPageIndex(): number {
        const param: GenericDashboardPaginationParameter = this.getComponentParameter();
        const calc: number = Math.ceil((param.totalItems / param.perPage.current));
        return calc > 0 ? calc - 1 : 0;
    }

    setTotalItems(amount: number): void {
        this.getComponentParameter().totalItems = amount;
    }

    setHasMoreEntitiesToLoad(value: boolean): void {
        this.getComponentParameter().hasMoreEntitiesToLoad = value;
    }

    isAtLastPage(): boolean {
        const param: GenericDashboardPaginationParameter = this.getComponentParameter();

        return isValidFunction(param.clientCallback.isAtLastPage)
            ? param.clientCallback.isAtLastPage()
            : param.index === this.getLastPageIndex();
    }

    hasSorting(): boolean {
        return isValidRef(this.getComponentParameter().sort);
    }

    getPageItens<T extends any>(itens: Array<T>): Array<T> {
        const { perPage: { current: itensPerPage }, index: currentPage } = this.getComponentParameter();
        const startIndex = itensPerPage * currentPage;
        const endIndex = startIndex + itensPerPage;
        return itens.slice(startIndex, endIndex);
    }
}
