import { TArrayID } from '../core-constants/types';
import { hashToArray } from '../tools/utility';

export const feedbackControlFields = {
    total: '.t',
    idGroup: '.g',
    idInteraction: '.i',
};

export const noFeedbackFields: TArrayID = <TArrayID>hashToArray(feedbackControlFields);

export interface TInteractionFeedbackDisplayGroup {
    [idInteraction: string] : IFeedbackFigures;
};

export interface IFeedbackFigures {
    [idGroup: string]: ICacheFeedbackInteraction;
};

export interface ICacheFeedbackInteraction {
    [idFeedback: string]: number;
};

export function countFeedbacks(cache: ICacheFeedbackInteraction) {
    return Object.keys(cache)
        .filter(isValidFeedbackName)
        .map(key => cache[key])
        .reduce((acc, val) => {
            acc += parseInt(`${val}`); //yeah, they are comming as string...
            return acc;
        }, 0);
}

export function isValidFeedbackName(feeName: string): boolean {
    return !feeName.startsWith('.');
}