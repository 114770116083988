import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: "app-flat-icon",
    templateUrl: "./flat-icon.component.html",
    styleUrls: ["./flat-icon.component.scss"],
})
export class FlatIconComponent implements OnInit {
    @Input() icon: string;
    @Input() color: string = '#324b4d';
    @Input() hover: boolean = true;

    constructor() {}

    ngOnInit() {}
}
