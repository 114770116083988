import {
    IFileSystemLayer,
    IMicrophoneHardwareAbstraction,
    IRecordMicrophoneResult
} from '../hardware-interfaces';
import * as MediaRecorderPoly from 'audio-recorder-polyfill';
import {
    EMimeTypes,
    MediaRecorderDataAvailableEvent,
    MediaRecorderErrorEvent,
    MediaRecorderEventMap,
    TMediaRecorderState
} from '@colmeia/core/src/multi-media/file-interfaces';
import { EHardwareResourceStatus} from "../cordova/vendor/hardware-status";
import { Injectable } from '@angular/core';
import { Window } from '../cordova/cordova-plugins-definitions';
import {clientConstants} from "../../../model/constants/client.constants";

declare var window: Window;

export enum EMicrophoneBrowserExceptionCode {
    NoPermission = 0,
    NotFound = 8
}

export enum EMicrophoneBrowserStatus {
    Inactive = 'inactive'
}

export class MicrophoneException extends Error {
    code: EMicrophoneBrowserExceptionCode;

    constructor(msg, code: EMicrophoneBrowserExceptionCode) {
        super(msg);
        this.code = code;
    }

    getCode(): EMicrophoneBrowserExceptionCode {
        return this.code;
    }
}

@Injectable({
    providedIn: 'root'
})
export class BrowserMicrophone implements IMicrophoneHardwareAbstraction {

    private recorder: MediaRecorder;
    private static readonly fileName: string = 'recordingAudio.wav';
    private static readonly mime: EMimeTypes = EMimeTypes.Wav;
    private authorizationStatus: EHardwareResourceStatus;

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return Promise.resolve(EHardwareResourceStatus.GRANTED);
    }

    isAllowed(): Promise<boolean> {
       return Promise.resolve(true);
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return Promise.resolve(EHardwareResourceStatus.GRANTED);
    }

    startRecording(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const mustUseNativeRecorder = clientConstants.recorder.useOnlyPolyfill
                ? false
                : window.MediaRecorder != null;
                this.recorder = (mustUseNativeRecorder)
                ? new MediaRecorder(stream, { mimeType: BrowserMicrophone.mime})
                : new MediaRecorderPoly(stream);
                this.recorder.start();
                this.authorizationStatus = EHardwareResourceStatus.GRANTED;
                resolve(true);
            }catch (err) {
                if ('code' in err && err.code === EMicrophoneBrowserExceptionCode.NotFound){
                    this.authorizationStatus = EHardwareResourceStatus.NOT_REQUESTED
                } else if ('code' in err && err.code === EMicrophoneBrowserExceptionCode.NoPermission) {
                    this.authorizationStatus = EHardwareResourceStatus.DENIED;
                } else {
                    this.authorizationStatus = EHardwareResourceStatus.DENIED;
                }

                resolve(false)
            }
        });
    }

    stopRecording(fs?: IFileSystemLayer): Promise<IRecordMicrophoneResult> {
        if(fs) {
            return new Promise<IRecordMicrophoneResult>((resolve, reject) => {
                if (!this.recorder || this.recorder.state === EMicrophoneBrowserStatus.Inactive)
                    return;

                this.recorder.addEventListener('dataavailable', evt => {
                    resolve({
                        file: fs.newFile([evt.data], BrowserMicrophone.fileName, {
                            type: BrowserMicrophone.mime
                        })
                    })
                });
                this.recorder.stop();
                this.recorder.stream
                    .getTracks()
                    .forEach(track => {
                        track.stop();
                    });
            });
        }
        return Promise.resolve(null);
    }

    cancelRecording(): void {
        if(this.recorder){
            this.recorder.stop();
            this.recorder.stream
                .getTracks()
                .forEach(track => {
                    track.stop();
            });
        }
    }
}

declare class MediaRecorder extends EventTarget {

    readonly mimeType: string;
    readonly state: TMediaRecorderState;
    readonly stream: MediaStream;
    ignoreMutedMedia: boolean;
    videoBitsPerSecond: number;
    audioBitsPerSecond: number;

    ondataavailable: (event: MediaRecorderDataAvailableEvent) => void;
    onerror:  (event: MediaRecorderErrorEvent) => void;
    onpause:  () => void;
    onresume: () => void;
    onstart: () => void;
    onstop:  () => void;

    constructor(stream: MediaStream, configs?: Object);

    start();
    stop();
    resume();
    pause();
    isTypeSupported(type: string): boolean;
    requestData();

    addEventListener<K extends keyof MediaRecorderEventMap>(type: K, listener: (this: MediaStream, ev: MediaRecorderEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void;
    removeEventListener<K extends keyof MediaRecorderEventMap>(type: K, listener: (this: MediaStream, ev: MediaRecorderEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
    removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void;
}
