import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader } from "../non-serializable-id/non-serializable-id-interfaces";
import { TGlobalUID } from "../../business/constant";
import { TArrayID } from "../../core-constants/types";
import { ApiRequestType } from "../../request-interfaces/message-types";
import { EIdMenus } from "../colmeia-tags/id-menus";
import { EScreenGroups } from "../visual-constants";
import { AfterNarrow, Compute, EntriesHash, FindKeysWithValueOf, IsEqual, Narrowable, Primitive, ValueOf } from "../../tools/utility-types";
import { Explicit, IntersectPartialExplicit } from "../../tools/utility/types";
import { EAdHocSubMenus } from "./social-network-config-ad-hoc-submenus";
import { ISocialNetworkSettings } from "./social-network-user-settings.model";
import { TIRangeBillingArray } from "../billing/billing-ns-model";
import { $Extends, ValueOfListWithPreservedOrder } from "@colmeia/core/src/tools/utility/types/validate";
import { keys } from "@colmeia/core/src/tools/utility/functions/keys";

export enum EIdMenuContractAccessType {
    EIdMenus = 'EIdMenus',
    EScreenGroups = 'EScreenGroups',
    EAdHocSubMenus = 'EAdHocSubMenus',
}



export interface IScreenGroupMenuContractConfig extends IMenuContractConfig {
    type: EIdMenuContractAccessType.EScreenGroups;
    idMenu: EScreenGroups;
}

export interface IMenuItemContractConfig extends IMenuContractConfig {
    type: EIdMenuContractAccessType.EIdMenus;
    idMenu: EIdMenus;
    idMenuParent: EScreenGroups;
}

export interface ISubMenuContractConfig extends IMenuContractConfig {
    type: EIdMenuContractAccessType.EAdHocSubMenus;
    idMenu: EAdHocSubMenus;
    idMenuParent: EIdMenus;
}

export type TMapIdMenuToConfig = Explicit<{ [key in TIdMenuContractAccess]: IntersectPartialExplicit<IMenuContractConfig, { idMenu: key }> },
    Compute<
        & { [key in EScreenGroups]: IntersectPartialExplicit<IScreenGroupMenuContractConfig, { idMenu: key }> }
        & { [key in EIdMenus]: IntersectPartialExplicit<IMenuItemContractConfig, { idMenu: key }>; }
        & { [key in EAdHocSubMenus]: IntersectPartialExplicit<ISubMenuContractConfig, { idMenu: key }>; }
    >
>;

export type TIdMenuContractAccess =
    | EIdMenus
    | EScreenGroups
    | EAdHocSubMenus
    ;


export enum EMenuItemNoConfigDefaultAction {
    ExplicitlyGranted = 'ExplicitlyGranted',
    EverythingThatHasNotBeenDenied = 'EverythingThatHasNotBeenDenied',
}




export interface IMenuContractAccessItem {
    hasAccess: boolean;
    onNoConfigDefaultAction?: EMenuItemNoConfigDefaultAction;
    expireAt?: number;
    idMenuParent?: TIdMenuContractAccess;
    maxAllowedUser?: number; // Dashboards // Customer Care -> Status
    rangePricing?: TIRangeBillingArray;
}

export type TMenuContractAccess = {
    [idMenu in TIdMenuContractAccess]?: IMenuContractAccessItem
}


export type TMenuContractAccessProcessed = {
    [idMenu in TIdMenuContractAccess]?: IProcessedContractItem
};

export interface IMenuContractConfig {
    type?: EIdMenuContractAccessType;
    idMenu?: TIdMenuContractAccess;
    idMenuParent?: TIdMenuContractAccess;
}

export interface IProcessedContractAdditionalConfig {
    hasForceAccessOnChildren?: boolean;
    hasAdmin?: boolean;


    hasAccessOnConfig?: boolean;
    hasAccessOnGroups?: boolean;

    hasToAllowDefaultAccess?: boolean;
}
export interface IProcessedContractItem extends IMenuContractAccessItem, IMenuContractConfig, IProcessedContractAdditionalConfig {
    hasSpecialRule?: boolean;
}


export interface IContractSocialNetwork {
    contract?: TMenuContractAccess;

    isAccountActive: boolean;
    isQueueConsuming: boolean;
    allowBotVoiceCommand?: boolean;
}

export interface ISocialContractConfiguration extends IContractSocialNetwork {
    type: ETypeConfig;
    idSocialContext: TGlobalUID;
    name: string;
    idPartition: number;
    // Atenção.. esse campo é utilizado apenas para injetar os dados de outro NS
    // Não é persistido
    snConfig?: ISocialNetworkSettings;
}

export interface ISocialNetworkConfigServer extends INonSerializable, ISocialContractConfiguration {
    nsType: ENonSerializableObjectType.socialNetworkConfig;
}


export enum ETypeConfig {
    basicContract = 'basicContract',
}

export type TSNConfigurationArray = Array<ISocialContractConfiguration>;
export type TSNConfigurationServerArray = Array<ISocialNetworkConfigServer>;


//


type HasValueRepetition<T extends string | number> = IsEqual<ValueOf<{
    [key in T]: [key extends unknown ? [key] : never, [key]] extends [infer First, infer Second] ? IsEqual<First, Second> : never
}>, true> extends true ? false : true;

type FindValueRepetition<T extends string | number> = ValueOf<{
    [key in T]: [key extends unknown ? [key] : never, [key]] extends [infer First, infer Second] ? IsEqual<First, Second> extends false ? key : never : never
}>;
