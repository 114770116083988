import {Injectable} from "@angular/core";
import {TExtendedParticipant, TExtendedParticipantArray} from "@colmeia/core/src/core-constants/types";
import {UberCache} from "@colmeia/core/src/persistency/uber-cache";
import {constant} from "@colmeia/core/src/business/constant";
import {Serializable} from "@colmeia/core/src/business/serializable";
import {SerializableHeaders} from "./serializable-headers";

@Injectable({
    providedIn: 'root'
})
export class ExtendedParticipantService {
    public async getExtendedParticipants(ids: string[]): Promise<TExtendedParticipantArray> {
        const idsToQuery: string[] = [];
        const selectedParticipants: TExtendedParticipantArray = [];
        for(const id of ids) {
            if(UberCache.testCache(id)) {
                selectedParticipants.push(
                    Serializable.staticFactory(id) as TExtendedParticipant
                );
            } else {
                idsToQuery.push(id);
            }
        }
        if(idsToQuery.length) {
            const fromRemote = await SerializableHeaders.fetch(idsToQuery.map(id => ({
                ignoreCache: true,
                objectTypeID: constant.objectType.group,
                serializableID: id
            })));
            for(const json of Object.values(fromRemote)) {
                selectedParticipants.push(
                    Serializable.factoryMessage(json) as TExtendedParticipant
                );
            }
        }
        return selectedParticipants;
    }
}
