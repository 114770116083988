import { SharedServices } from '@colmeia/core/src/shared-business-rules/shared-services/shared-services.entities';
import { getTargetInstance, isValidFunction, keys } from '@colmeia/core/src/tools/utility';
import { getSharedServiceKeys } from '@colmeia/core/src/tools/utility/functions/toStatic';
import { $SharedServices } from './shared-services';
import { isIgnoringImplementation } from './shared-services.basic-functions';



export interface IDefineServiceIteration {
    service: ISharedService;
    serviceName: keyof typeof $SharedServices;
    methodName: string;
    method: ISharedServiceMethod;
    instance: unknown;
    fnName: string;
}


export function defineSharedServices(onIterate: (info: IDefineServiceIteration) => void, options?: { shouldCallAfterInit?: boolean }) {
    const names = keys($SharedServices)
    for (const name of names) {
        const service = getSharedService(name);
        const instance = getTargetInstance(service);
        for (const methodName of getServiceMethods(name)) {
            // 
            const method = service[methodName];
            const fnName = method.fnName ?? `${name}.${methodName}`
            if (isIgnoringImplementation(fnName, service as {})) continue;
            const info: IDefineServiceIteration = {
                service,
                methodName,
                method,
                instance,
                serviceName: name,
                fnName,
            }
            onIterate(info);
        }
    }

    return Promise.all(names.map(async name => {
        const service = getSharedService(name);

        if (options?.shouldCallAfterInit) {
            await initService(service);
        }
    }));
}

async function initService(service: ISharedService) {
    try {
        await service.init?.();
    } catch {}
}

export function getServiceMethods(name: keyof typeof $SharedServices) {
    const service = getSharedService(name);  
    const set = getSharedServiceKeys(service)! ?? new Set();
    return [...set].filter(key => isValidFunction(service[key]))
}



export interface ISharedServiceMethod {
    fnName: string
    (input: {}): Promise<{}>;
}
export type ISharedService = ({ name: string; init?(): void }) & ({ [key in string]: ISharedServiceMethod });
export function getSharedService(name: keyof typeof $SharedServices) {
    return (SharedServices[name]) as {} as ISharedService;
}


