import { TIAnalyticsBiReportItemServerArray } from '@colmeia/core/src/shared-business-rules/analytics-bi-report-item/analytics-bi-report-item.model';
import { INonSerializableSearcheableContent } from '@colmeia/core/src/shared-business-rules/non-serializable-id/ns-elastic-model';
import { TTooltipInfoModelMap } from '@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model';
import { TGlobalUID } from '../business/constant';
import { ESocialMedia } from '../business/social';
import { TSubtypeStatusHash } from "../business/subscriptions";
import { IClientInfoPlayer, IGroupJSON, IGroupJSONArray, IInteractionJSON, IInteractionJSONArray, IParticipantJSON, IPaymentMediumInteractionJSON, IPlayerAccessCached, IPlayerJSON, IUniversalJSONArray, TAvatarJSONArray, TISenderSignatureArrayJSON, TSearchResultArray } from '../comm-interfaces/barrel-comm-interfaces';
import { TPublicMediumArray } from "../comm-interfaces/billing-interfaces";
import {
    IAvatarJSON, IFriendlyMessageJSON, IParticipantJSONArray, ISecuritySettingsJSON,
    TGroupArrayJSON
} from '../comm-interfaces/business-interfaces';
import { IColmeiaResponseAPI } from '../comm-interfaces/colmeia-services-req-res';
import { ICacheFeedbackInteraction } from '../comm-interfaces/feedback-interfaces';
import { TArrayWalletOperation } from '../comm-interfaces/finance-interfaces';
import { IServiceChatInitiatedInteractionJSON } from '../comm-interfaces/service-group-interfaces';
import { IPersonalSettingsConfiguration } from '../comm-interfaces/settings-interface';
import {
    EConfirmationType, TArrayID, TInteractionSharedTo, TNserUID
} from '../core-constants/types';
import { IDeploymentEnvironmentWorkspace, TBIReportPanel } from '../dashboard-control/business-intelligence/business-intelligence.model';
import { IListNonSerializablesResponse } from '../dashboard-control/dashboard-response-interfaces';
import { IPowerBiAppConfigData, IPowerBIDashboardReportDetailsAndEmbededToken } from '../dashboard-control/power-bi/power-bi-dashboard-core.model';
import { TIComponentFileInfoArray } from '../multi-media/file-interfaces';
import { TSerializableHeaderResponse } from "../serializable/header";
import { IAgentLoginInfo } from '../shared-business-rules/attendent-service-pack/attendent-sp-req-resp';
import { TCanonicalDB } from "../shared-business-rules/canonical-model/local-canonical";
import { IHttpConnectionResult } from '../shared-business-rules/connections/endpoint-model';
import { ICorporateSearchBodyServer, ICorporateSearchHeaderServer, TICorporateSearchServerArray } from '../shared-business-rules/corporate-search/corporate-search-model';
import { TFacNewsArticleArray } from "../shared-business-rules/face-company/face-company-interfaces";
import { TFeatureGroupServerArray } from '../shared-business-rules/group-features/group-featues-model';
import { IFlowInjectionBodyServer, IFlowInjectionHeaderServer } from '../shared-business-rules/injection-flow/injection-flow';
import { ERateAction } from '../shared-business-rules/rate-limit/rate-limit.interface';
import { IContractSocialNetwork } from '../shared-business-rules/social-network/social-network-config';
import { IUserApprovalProfileServer } from '../shared-business-rules/user-approval-profile/user-approval-profile';
// import { IPushNotificationClient } from "../business/push-notifications";
import { IUserFunctionModelServer, TVLReturn } from '../shared-business-rules/user-function/user-function-model';
import { TSolicitationGroupIdToTask } from '../shared-business-rules/you-control/solicitation-tasks-model';
import { TILocationCoordinates } from '../tools/geo-util';
import { MergeInterfaces } from '../tools/utility-types';
import { Merger } from '../tools/utility/types/entities/merger';
import { GenericResponse, TMultipleCursor, WithCursor } from './request-interfaces';
import { IOldestRecentInteractionInfo, IOldestRecentInteractionInfoResponse } from '../shared-business-rules/attendance-island/attedance-helpers';
import { INonSerializable } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';


export interface ISentInteraction {
    idInteraction: TGlobalUID, idInteractionType: TGlobalUID
};
export type TSentInteraction = { [idInteraction: string]: ISentInteraction };

export interface IResponse {
    responseType: string,
    friendlyError: IFriendlyMessageJSON,
    ackCode?: string;
    deliveryTag?: number;
    crc?: number;
    sentInteractions?: TSentInteraction;
    isDashboardResponse?: true;
    cursor?: string;
    hasSearch?: boolean;
    hasSort?: boolean;
    jsError?: Error,
    idRequest?: string
    idJob?: string;
    rateLimitBlockAction?: ERateAction;
    rateLimitBlockUntil?: number;
};

export interface IChangePassordResponse extends IResponse {
    authorization: string;
}

export interface IParticipantInteractionResponse extends IResponse {
    interaction: IInteractionJSON;
}

export interface IResponseCursor extends IResponse, WithCursor {
    cursor: string | undefined;
}

export interface ResponseCursor<Type extends string = string> extends Merger<GenericResponse<Type>, IResponseCursor> {

}

export interface IResponseMultipleCursor extends IResponse {
    multipleCursor: TMultipleCursor;
}


export interface IWalletBalanceResponse extends IResponse {
    balance: TArrayWalletOperation;
};


export interface IGetGPSTrackerEventsResponse extends IResponse {
    trackerEvents: TILocationCoordinates;
    cursor?: string;
};


export interface IUserSettingsResponse extends IResponse {
    personalSettings: IPersonalSettingsConfiguration;
}


export interface IFeedbackCreatorResponse extends IResponse { };

export type TFeedbackDetailArray = Array<IFeedbackDetail>;
export interface IFeedbackDetail {
    idFeedback: TGlobalUID;
    avatarName: string;
    idMediaAvatar: TGlobalUID;
    clockTick: number;
};

export interface IFeedbackDetailResponse extends IResponse {
    cursor: string;
    feedbackDetail: TFeedbackDetailArray;
    figures: ICacheFeedbackInteraction;
};

export interface INavigationResponse extends IResponse {
    groupArray: TGroupArrayJSON;
    groupData: IGetGroupDataResponse;
    participant?: IParticipantJSONArray;
    idSocialNetwork: TGlobalUID;
    socialData: ISocialNetworkData;
    login: IAgentLoginInfo;
    isReadyToAttend: boolean;
    avatarOpenGroupParticipateSolicitations: TSolicitationGroupIdToTask,
    allHelpItems?: TTooltipInfoModelMap;
};

export interface ICreateEditAvatarResponse extends IResponse {
    avatar: IAvatarJSON;
};

export interface IApproverData {
    idAvatar: TGlobalUID;
    idPlayer: TGlobalUID;
}

export interface IInitResponse extends IResponse {
    uber: {
        isUpToDate: boolean;
        uberData: string;
        cacheVersion: number;
    },
    configInteractions: IInteractionJSONArray,
    approver: IApproverData;
};

export interface IBatchInteractionInsertResponse extends IResponse {
    numberOfInteractionsSaved: number;
    interactionsProcessedIDs: TArrayID;
    interactionsPublishIDs: TArrayID;
}

export interface IForgotPassResponse extends IResponse {
    playerName?: string;
    authorization?: string;
}

export interface ISignUpFirstStepResponse extends IResponse { }

export interface ISignUpResponseBase {
    player: IPlayerJSON;
    playerInfo: IPlayerAccessCached;
    offset: TGlobalUID;
    tribe: IGroupJSON;
    enterprise: IGroupJSON;
    authorization: string;
    allRoot: IGroupJSON;
    playerSN: ISocialNetworkData;
    avatarInfoArray: TAvatarJSONArray;
    redirectToGroupId: TGlobalUID;
    allPlayerSN: TGroupArrayJSON;
    firstNavigation: INavigationResponse;
    hasOpenCase: boolean;
}

export interface ISignUpResponse extends IResponse, ISignUpResponseBase {

};

export interface IExternalCRMSignupResponse extends ISignUpResponse {
    service: IServiceChatInitiatedInteractionJSON;
    isAlreadyClosed: boolean
}


export interface ISmartshareQueryResponse extends IResponse {
    files: TIComponentFileInfoArray;
};

export interface ISerializableHeaderOutput {
    serializables: TSerializableHeaderResponse;
};

export interface ISerializableHeaderResponse extends IResponse, ISerializableHeaderOutput {
};

export type IGetSetMMInformationResponse = ISmartshareQueryResponse;

export interface ICreateParticipantResponse extends IResponse {
    participant: IParticipantJSON
}

export interface IGetGroupAvatarsResponse extends IResponse {
    avatars: TAvatarJSONArray;
};


export interface IGetGroupByIdResponse extends IResponse {
    group: IGroupJSON
}

export interface IGetGroupInteractionsResponse extends IResponse {
    interactions: IInteractionJSONArray
}

export interface IGetGroupChildResponse extends IResponse {
    group: IGroupJSON;
    childGroups: IGroupJSONArray;
};

export interface IGetAvatarsOnLineOnGroupResponse extends IResponse {
    onlineAvatars: TISenderSignatureArrayJSON;
}


export interface IParticipantRolesPair {
    idRoles: TArrayID;
    idAvatar: TGlobalUID;
}

export type TParticipantRolePair = Array<IParticipantRolesPair>;

export interface IGetGroupSecuritySettingsResponse extends IResponse {
    securitySettings: ISecuritySettingsJSON;
    // validAvatars: TAvatarJSONArray;
    distributionGroups: TGroupArrayJSON;
    rolesParticipant: TParticipantRolePair;
    hasVinculatedSecuritySettings: boolean;
    currentGroupState: IGroupJSON;
};

export interface IGetGroupParticipantResponse extends IResponse {
    participants: IParticipantJSONArray
};
export interface IAvatarWithParticipationClockTick extends IAvatarJSON {
    clockTickBegin: number;
    clockTickCurrent: number;
}
export interface IGetAvatarsThatParticipateInAGroupResponse extends IResponse {
    avatarsThatParticipate: IAvatarWithParticipationClockTick[],
}

export interface IGetRecoverGroupDataResponse extends IResponse {
    recoveryData: TGroupRecoveryData;
}

export interface IRecoveryTopicResponse extends IResponse {
    interactions: IInteractionJSONArray;
}

export type TGroupRecoveryData = Array<IGetGroupDataContent>;


export interface ISocialNetworkData {
    allPlayerParticipantOnSN: IParticipantJSONArray;
    allPlayerGroupOnSN: IGroupJSONArray;
    isPlayerAnAgent: boolean;
    purchase: IContractSocialNetwork;
}

export interface IGetSocialNetworkData extends IResponse, ISocialNetworkData {
}


export interface IGetGroupDataContent {
    group: IGroupJSON;
    interactions: IInteractionJSONArray;
    childGroups: IGroupJSONArray;
    participant: IParticipantJSON;
    groupFeatures: TFeatureGroupServerArray;
    cursor: string;
};


export interface IGetGroupDataResponse extends MergeInterfaces<IResponse, IGetGroupDataContent> { };

export interface ISocketPlayerConnectedResponse extends IResponse {
    crc: number
}

export interface ISocketGroupSubscriptionResponse extends IResponse {
    participant: IParticipantJSON,
}


export interface IMessageSendRules {
    retries: number;
    addToQueueFirst: boolean;
    requeue: boolean;
}

export type TSocketResponseArray = Array<ISocketInteractionResponse>;

export interface ISocketReceiveConfirmation {
    responseType: string;
    idPlayer: TGlobalUID;
    ackCode: string;
    isOnFocus: boolean;
    pubGroups: TArrayID;
}

export interface IAppWidgetResponse extends IResponse, ISignUpResponse {

}

export interface ISocketInteractionResponse extends IResponse {
    interaction: IInteractionJSON;
    idToAvatar?: TGlobalUID;
    idToPlayer?: TGlobalUID;
    idServer: string;
    tick: number;
    isOnFocus?: boolean;
    rules?: IMessageSendRules;
    idPubGroup: string;
    idMessage?: string;
    idReceiverIdentifier?: string;
}

export interface ISocketReadyEvent {
    clientInfo: IClientInfoPlayer;
};

export interface IConfirmationInfo {
    idPubGroups: string;
    idInteractionType: TGlobalUID;
    type: EConfirmationType
}

export type TConfirmationInfoArray = Array<IConfirmationInfo>;

export interface IConfirmReadMessage {
    idPlayer: TGlobalUID;
    confirmations: TConfirmationInfoArray;
}

export interface IFocusInfo {
    isFocus: boolean;

}

export interface ISocketInteractionClientComm extends IResponse {
    messages: TSocketResponseArray;
}

export interface IOnlineConfirmationMessage extends IResponse {
    idPlayer: TGlobalUID;
    rules?: IMessageSendRules;
}

export enum EArakiriReason {
    licenceRestrictedUser = 'LRU',
    needToChangePWD = 'pwd'
}
export interface IKillOtherMachines extends IResponse {
    idPlayer: TGlobalUID;
    idQueueInstanceToBeIgnored: string
    rules: IMessageSendRules;
    reason: EArakiriReason;

}


export interface IGroupCreationResponse extends IResponse {
    group: IGroupJSON,
    participant: IParticipantJSON
    socialData?: ISocialNetworkData // preenchido apenas se for social-network
}

export interface IGetInteractionByIdResponse extends IResponse {
    interaction: IInteractionJSON
}

export interface IGetInviteeDataToSignUpResponse extends IResponse {
    email: string
}

export interface IGetUnresolvedIDsResponse extends IResponse {
    serializableArray: IUniversalJSONArray;
}

export interface ISearchResponseData {
    searchResults: TSearchResultArray;

}
export interface IFullTextSearchResponse extends IResponse {
    results: INonSerializableSearcheableContent[]
}
export interface ISearchResponse extends IResponse, ISearchResponseData {
}
export interface ISearchOnRemoteEnvResponse extends ISearchResponse {
}

export interface ISearchOnCurrentEnvResponse extends IColmeiaResponseAPI, ISearchResponseData { }
export interface IPollResponse extends IResponse {
    result: string;
}

export interface IErrorLoggerResponse extends IResponse {
    result: string;
}

export type TCheckIfSharedResponseArray = Array<ICheckIfSharedResponse>;

export interface ICheckIfSharedResponse extends IResponse {
    sharedState: TInteractionSharedTo;
}

export interface IShareSendResponse extends IResponse {
    sharesCount: number;
}

export interface IActiveSocialBinds extends IResponse {
    activeBinds: Array<ESocialMedia>;
}

export interface IUnbindSocialMediaResponse extends IResponse {
    success: boolean
}


export interface IPaymentMediumResponse extends IResponse {
    results: TPublicMediumArray;
};

export interface IRawMediumResponse extends IResponse {
    result: IInteractionJSON;
}

export interface ExternalRequestResponse extends IResponse {
    status: number;
    body: any;
}

export interface CheckoutResponse extends IResponse {
    success: boolean;
    error?: Array<string>;
}

export interface DefaultMediumResponse extends IResponse {
    medium: IPaymentMediumInteractionJSON;
}

export interface GetMediumResponse extends IResponse {
    medium: IPaymentMediumInteractionJSON;
}

export interface ISetSerializableMultimediaHeaderResponse extends IResponse {
    updatedMedia: IUniversalJSONArray
}

export interface ISubscriptionsStatusResponse extends IResponse {
    statuses: TSubtypeStatusHash;
}

export interface IUnsubscribeResponse extends IResponse { }

export interface ISignedUrlResponse {
    url: string;
}

export interface IFacNewsResponse extends IResponse {
    articles: TFacNewsArticleArray;
}

export interface IGetCanonicalDBResponse extends IResponse {
    database: TCanonicalDB;
}

export interface IPowerBIDashboardAdditionalConfigResponse extends IResponse {
    result: IPowerBIDashboardReportDetailsAndEmbededToken
}
export interface IPowerBIDashboardWorkspaceInfoResponse extends IResponse {
    workspaceInfo: IDeploymentEnvironmentWorkspace
}
export interface IPowerBIDashboardConfigInfoResponse extends IResponse {
    config: IPowerBiAppConfigData
}
export interface IPowerBIDashboardSetWorkspaceInfoResponse extends IResponse {
    savedWorkspaceInfo: IDeploymentEnvironmentWorkspace
}

export interface IPowerBIUpsertReportInfoResponse extends IResponse {
    report: TBIReportPanel
}

export interface IPowerBIDeleteReportInfoResponse extends IResponse {
    reportIdDeleted: string
}

export interface IUserFunctionsSaveResponse extends IResponse {
    userFunction: IUserFunctionModelServer
}

export interface IUserFunctionsGetAllResponse extends IResponse, IListNonSerializablesResponse<IUserFunctionModelServer> {
    // userFunctionList: TIUserFunctionModelServerArray
}

export interface IUserFunctionCompileResponse extends IResponse {
    isValidCode: boolean,
    error: string
}

export interface IUserFunctionRun {
    result: TVLReturn
}

export interface IUserFunctionRunResponse extends IUserFunctionRun, IResponse {
}

export interface IUserFunctionServiceRunResponse extends IUserFunctionRun, IColmeiaResponseAPI {
}

export interface IUserFunctionsEditResponse extends IResponse {
    userFunctionNser: IUserFunctionModelServer
}

export interface IUserFunctionRunRawResponse extends IResponse {
    rawResponse: IHttpConnectionResult
}

// ICorporateSearch
export interface ICorporateSearchSaveResponseData {
    parent: ICorporateSearchHeaderServer,
    children: ICorporateSearchBodyServer[]
}

export interface ICorporateSearchSaveResponse extends IResponse, ICorporateSearchSaveResponseData {
}

export interface ICorporateSearchGetAllResponse extends IResponse {
    corporateSearchList: TICorporateSearchServerArray
}

// Analytics
export interface IGetAnalyticsReportsInfoResponse extends IResponse {
    ignoredBySecurity: number,
    dashboardAnalyticsItems: TIAnalyticsBiReportItemServerArray
}
export interface IUpsertAnalyticsReportInfoResponse extends IResponse {
    upsertSuccess: boolean,
}

// RTFParams
export interface IRTFParameterizedSaveHeaderResponse extends IResponse {
    header: IFlowInjectionHeaderServer
}

export interface IRTFParameterizedSaveBodyResponse extends IResponse {
    body: IFlowInjectionBodyServer
}

export interface IRTFParameterizedGetAllHeaderResponse extends IListNonSerializablesResponse<IFlowInjectionHeaderServer> {
}

export interface IRTFParameterizedGetAllBodyResponse extends IListNonSerializablesResponse<IFlowInjectionBodyServer> {
}

export interface IGetAnalyticsReportsInfoResponse extends IResponse {
    ignoredBySecurity: number,
    dashboardAnalyticsItems: TIAnalyticsBiReportItemServerArray
}
export interface IUpsertAnalyticsReportInfoResponse extends IResponse {
    upsertSuccess: boolean,
}

export interface IUserApprovalProfileResponse extends IResponse {
    ns: IUserApprovalProfileServer
}

export interface IEditAvatarProfileListResponse extends IResponse {
}

export interface IEditUserGroupProfileResponse extends IResponse {
    /**
     * sera retornado apenas se isAnyJobStillProcessing = false
     */
    ns?: IUserApprovalProfileServer;
    /**
     * retorna true se um job tiver sido criado
     */
    isAnyJobStillProcessing?: boolean;
}

export interface IGetOneUserGroupProfileResponse extends IResponse, IEditUserGroupProfileResponse {

}

export interface IGetOldestRecentInteractionsInfoResponse extends IResponse {
    lastInteractionsInfo: IOldestRecentInteractionInfoResponse[]
}

export interface INonSerializableSaveResponse extends IResponse {
    ns: INonSerializable;
}
