<div class="enum-picker-container">
  <ng-container *ngIf="!parameters.buttonMode; else buttonMode">
    <mat-form-field
      *ngIf="isSingleMode"
      [appearance]="appearance"
      class="no-padding-bottom"
    >
      <mat-label>{{ title }}</mat-label>
      <mat-select [(ngModel)]="singleValue" (selectionChange)="onSingleChanged()" [disabled]="isDisabled"
        [required]="required">
        <mat-option *ngIf="allowClear">--</mat-option>
        <mat-option *ngFor="let option of options" [value]="option.enumValue">
          {{ option.text }}
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="error" class="error">{{ error }}</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="!isSingleMode" [appearance]="appearance" class="no-padding-bottom"
      style="padding-bottom: 16px">
      <mat-label>{{ title }}</mat-label>
      <mat-select multiple #multipleSelect (closed)="onCloseSelect()" (selectionChange)="onMultipleChanged()" (ngModelChange)="onMultiselectNgModelChange($event)"
        [ngModel]="multipleValues" [disabled]="isDisabled" [required]="required" style="position: relative;">
        <mat-option *ngFor="let option of options" [value]="option.enumValue">
          {{ option.text }}
        </mat-option>
        <div *ngIf="useActionBtns()" class="enumpicker-action-buttons">
          <button mat-stroked-button (click)="reset()">Resetar</button>
        </div>
      </mat-select>
      <mat-hint *ngIf="error" class="error">{{ error }}</mat-hint>
    </mat-form-field>
  </ng-container>
</div>

<ng-template #buttonMode>
  <button mat-stroked-button class="button-mode-button button-w-icon" color="primary" [matMenuTriggerFor]="menu">
    <div class="" style="display: flex; align-items: center;">
      {{ singleValue ? optionsHash[singleValue].text : title }}
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="allowClear" (click)="changeSingleOption(null)">
      --
    </button>
    <ng-container *ngFor="let option of options">
      <button mat-menu-item *ngIf="option.enumValue !== singleValue" (click)="changeSingleOption(option.enumValue)">
        {{ option.text }}
      </button>
    </ng-container>
  </mat-menu>
</ng-template>
