import {constant, TGlobalUID} from "./constant";

export interface IInteractionMapInfo {
    idInteraction: TGlobalUID;
    idInteractionType: TGlobalUID;
}


export interface ICurrentSubscriptionStatus {
    idSubscriptionType: TGlobalUID;
    from: TGlobalUID;
    idSubject: TGlobalUID;
    followStatus: EFollowStatus;
    idFeedback: TGlobalUID;
    clockTick: number;
    requestSent?: true;
    subscription?: IInteractionMapInfo;
}

export type TCurrentSubscriptionStatusArray = Array<ICurrentSubscriptionStatus>;

export enum EFollowStatus {
    NotFollowing = 0,
    Following = 1,
    FollowRequested = 2,
}

export const SubscriptionStatusTranslatinoAux = [
    constant.serializableField.auxiliars.aux01, // NotFollowing
    constant.serializableField.auxiliars.aux02, // Following
    constant.serializableField.auxiliars.aux03 // FollowRequested
];

export type TSubtypeStatusHash = {
    [subTypeID: string]: EFollowStatus;
}