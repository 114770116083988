import { suggestions } from "../../../tools/type-utils";
import { IdField, typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

export const errorsScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.ServerErrors,
    fields: {
        cannotUnactiveAttendantFromIslandBecauseIsAttending: 1, // Não é possível se ausentar atendimento com clientes ativos
        yourOrganizationDoesNotYetHaveAccessToTheBeehiveGetInTouchWithAnAgentToTestAndLearnAboutOurPlansThankYou: 2, // Sua organização ainda não tem acesso à Colmeia. Entre em contato conosco para fazer testes e conhecer os nossos planos. Obrigado!
        oneOfDependenciesCannotBeAutomaticDeleted: 3, // Uma das dependências não pode ser automaticamente removida, por favor remova sua referência manualmente.
        invalidDependencyArrayType: 4, // Ocorreu um erro ao tentar ao remover uma das dependências, se isso ocorrer outra vez, procure um administrador.
        hasReferencesContinueOrSee: 5, // Este objeto é referenciado por outros, deletar mesmo assim? Se você deseja pode visualizar as dependencias clique em ver dependencias
        authProviderError: 6, //Erro de autenticação do provedor,
        cannotReturnCaseToQueueIfAvaialable: 7,

        thisMenuIsAlreadyControlledByASecurityGroup: 8, // Esse menu já é controlado por um grupo de segurança
        thisGroupIsAlreadyControlledByAnAccess: 9, // Esse grupo já é utilizado em outra regra de segurança

        transformConfigurationErrorIsland: 10,  // Erro na configuração de transformações da ilha de atendimento
        transformConfigurationErrorConnectionRoute: 11,  // Erro na configuração de transformações da rota de conexão
        sendInternalPromoteError: 12, // Erro ao promover itens
        transformConfigurationErrorProvider: 13, // Erro na configuração de transformações de provedor

        cannotRemoveAttendantFromIslandBecauseIsAttending: 14, // O atendente não pode ser retirado da ilha pois está em atendimento
        wrongPassword: 15, // Senha não confere
        minimumUtterances: 16,// Todas as intenções devem ter 5 exemplos válidos
        fillUserSentence: 17, // Preencha a sentença 
        thereAreEqualSentences: 18,// Existem sentenças iguais

        impossibleToTransferCustomerToQueueDueTimeRestriction: 19, // Não é possível transferir o cliente para esta ilha, há uma restrição de tempo.
        errorDuringBotAlterationPleaseRestartIt: 20, //Erro durante a alteração do Bot. Por favor, reinicia a alteração do bot pelo menu
        cannotAddToAgentsWithTheSameAvatar: 21,// Não pode adicionar dois agentes com o mesmo avatar
        youAreNotAnAgent: 22, // Você não é um agente
        youAreNotOnAnIsland: 23, // Você não está em uma ilha
        luisCantUpdateAppConnection: 24, // Não foi possível atualizar as configurações do app no luiss
        intentAlreadyExists: 24, // A intenção já existe
        itIsNotPossibleToCreateTwoAttendantsForTheSameUser: 25, // Não é possível criar dois atendentes para o mesmo usuário
        differentNsType: 26, // nsType da rota diferente da requisição
        transactionNotFound: 27, // Gerador de conteúdo não encontrado
        transactionMustBeANlpTransaction: 28, // O gerador de conteúdo deve ser do tipo NLP
        fileAlreadyExists: 29, // O Banco de dados já existe
        cantUploadFileCheckIfItCorrespondsToMetadata: 30, // Erro ao fazer upload do banco de dados. Cheque se ele corresponde ao formulário
        noIdPartition: 31, // Id da partição inválido
        cannotInsertMoreThanOneProviderColmeia: 32, // Não é possível inserir mais de um provedor colmeia
        invalidPhoneNumber: 33, // Número de telefone inválido
        invalidUserFunction: 34, // Função inválida
        errorExecutingUserFunction: 35, // Erro ao executar o código
        errorDomainFunctionRun: 36, // "nao foi possivel rodar a funcao pois o dominio nao esta na whitelist"
        errorCompilingFunction: 37, // "nao foi possivel compilar a funcao"
        entityNotFound: 38, // Entidade não encontrada
        entityNotFoundOrInvalid: 39, // Entidade não encontrada ou inválida
        expiredEntity: 40, // Entidade expirada
        noBPMTag: 41, // BPM Sem mapeamento de tags
        agentNeedToBeOffline: 42, // O agente precisa estar deslogado para executar essa operação.
        cannotExecuteActionAgentIsActive: 43, // Não é possível executar essa ação pois o agente está em operação na ilha
        cannotFinishAllCasesSupervisor: 44, // Não é possível executar essa ação pois o agente está em operação na ilha
        invalidRequestParams: 45, // Parâmetros inválidos
    }
});

export const errorsScreenOptions2 = typedScreenOptions({
    idSerializable: EAllSerializable.ServerErrors2,
    fields: suggestions<{ [key in string]: IdField }>()({
        closedChannel: 1, // O canal de comunicação com cliente está fechado. Por favor feche o atendimento e inicie a chamada pela chamada ativa
        originIpWithoutAccess: 2, // Ip de origem nao possui acesso a API!!!
        deprecated: 3, // Deprecado
        visualElementError: 4, // Erro ao inserir os elementos
        noPrimaryId: 5, // Não há primaryID para inserção
        cannotDeclareASerializableScopeTwoTimesErrorOnPrimaryId: 6, // Não é possível declarar um escopo serializável duas vezes! Erro no primaryID: 
        isNotColmeiaProvider: 7, // Não é provedor colmeia
        providerNotSelected: 8, // Provedor não selecionado
        cantTrainKnowledgeBase: 9, // Não foi possível treinar a base
        entityWithoutTags: 10, // Entidade sem tags
        entityWithoutName: 11, // Entidade sem nome
        noParent: 12, // Entidade sem pai
        cantMigrate: 13, // Não é possível migrar essa entidade
        atLeastSomeAuthNOTSet: 14, // connection-route nao tem $auth em nenhum dos fields
        attendenceForClientDoesNotExist: 15, // nao existe atendimento para cliente
        channelNotSelected: 16,
        messageContentNotExist: 17, // o conteudo da mensagem de alguma das acoes nao existe
        duplicatedTemplateName: 18,
        templateAlreadySent: 19,
        isNotWhatsAppConnection: 20,
        cannotSendTemplateToApproval: 21,
        templatesWithTheSameContent: 23, // Já existe algum template cadastrado com o mesmo conteúdo
        agentNotAllowedToSearch: 24,
        messageTooLarge: 25, // Mensagem muito grande (acima de 1600 caracteres).
    }),
});

export const errorsScreenOptions3 = typedScreenOptions({
    idSerializable: EAllSerializable.ServerErrors3,
    fields: suggestions<{ [key in string]: IdField }>()({
        socialNetworkOnRemoteEnvironmentDoesNotExist: 1,
        avatarOnRemoteEnvironmentDoesNotExist: 2,
        customerTypeSearchNotExist: 3, // campo customerSearchType 'e obrigatorio!!!
        targetAvatarDoesNotExist: 4, // o contato ainda nao existe favor cadastra-lo!
        channelWithSameAddressAlreadyExists: 5, // channel with same address already exists!!! 
        notPossibleToPerformColmeiaSubscriptionToPage: 6, // it was not possible to subscribe colmeia-app to facebook-page, please check if your facebook-user has at least tester role on colmeia-app since colmeia does not have instagram_basic advanced access this is required!
        notPossibleToGetInstagramBusinessAccountId: 7, // it was not possible for colmeia to get your instagram_business_account_id, please check if your facebook-user has at least tester role on colmeia-app since colmeia-app does not have instagram_basic-advanced-access approved, this step is required to proceed!
        channelLinkedWithPageAndSubscribedToAppAlreadyExists: 8, // nao foi possivel fazer subscribe do app da colmeia com a pagina pois ja existe um canal conectado com a pagina
        channelSubscribedCannotDelete: 9, // nao foi possivel deletar este canal pois ele ainda esta vinculado com uma pagina!
    }),
});

export const errorTranslations = {
    ...getTranslationsFields(errorsScreenOptions),
    ...getTranslationsFields(errorsScreenOptions2),
    ...getTranslationsFields(errorsScreenOptions3),
};
