import { StartServiceChat } from "@colmeia/core/src/interaction/service-group/start-service-chat";
import { IBotLogContent } from "@colmeia/core/src/shared-business-rules/bot/bot-interfaces";
import { MultimediaPreviewHandler } from "app/components/chat/message-container/message-instance/multimedia-preview/multimedia-preview.handler";
import { HandlerHexagonon } from "app/handlers/hexagono.handler";

export interface IGroupServiceParameter {
    interaction: StartServiceChat;
    toggleChildren(): void;
}

export interface IBotChatMessage extends IBotLogContent {
    text?: string;
    isBot: boolean;
    userName: string;
    time?: string;
    menuTitle?: string;
    isInvalidOption?: true;
    isDesambiguation?: true;
    isReturn?: true;
    menuItem?: string;
    hexagonHandler: HandlerHexagonon;
    multimediaHandler: MultimediaPreviewHandler;
}
