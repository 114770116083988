import { baseDomainRegex, validURLRegex } from "./connections-constants";
import {
    IConnectionClient, IConnectionDomain, IConnectionServer,
} from "./endpoint-model";
import { TConnectionAuthentication } from "./connections-auth-model";
import { Nullish } from "@colmeia/core/src/tools/utility-types";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";

export function getDomainById(connection: IConnectionServer, idDomain: string): IConnectionDomain {
    const domain: IConnectionDomain = connection.allowedDomains.find(
        domain => domain.identifier === idDomain
    );
    return domain;
}


export function credential2Regex(authValue: string): RegExp {
    const str = `${authValue}`
        .split('')
        .map(s => `[${s}]`)
        .join('');
    return new RegExp(str, 'g');
}
/*@TODO remover isto, vair dar mto errado, vide Salesforce, uma vez que as chaves das interfaces são "password" ele vai dar replace em tudo que for password.*/
export function replaceCredentials(str: string, auth: TConnectionAuthentication): string {
    let result: string = `${str}`;

    Object.keys(auth).forEach((authField: string) => {
        result = result.replace(credential2Regex(authField), auth[authField]);
    });

    return result;
}


export function isValidJSON(str: string): boolean {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

export function isValidDomain(str: string): boolean {
    try {
        const url = new URL(str);
        return true;
    } catch (error) {
        return false;
    }
}

export function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function isValidURL(url: string): boolean {
    return validURLRegex.test(url);
}

export function safeStringfyPrettier(object: object) {
    let result = '';
    try {
        result = JSON.stringify(object, null, 2);
    } catch (e) {

    } finally {
        return result;
    }
}

export function safeParseJSON<T extends {}>(str: string | Nullish, defaultValue: string = '{}'): T {
    try {
        return JSON.parse(str || defaultValue);
    } catch (e) {
        return JSON.parse(defaultValue);
    }
}

export function safeParseJSONAndReturnUndefinedIfNecessary<T extends object>(str: string | Nullish): T | undefined {
    if (!str) return;
    try {
        return JSON.parse(str);
    } catch { }
}

export function getDomainOfId(connection: IConnectionClient, idDomain: string) {
    return connection.allowedDomains.find(d => d.identifier === idDomain);
}


export function initConnectionClient(): IConnectionClient {
    return {
        nsType: ENonSerializableObjectType.connection,
        nName: '',
        allowedDomains: [],
        auth: {
            type: undefined,

        },
        medias: null,
    };
}
