import { Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ENextGenBotElementType, IBotMenuContainerServer, IBotRootServer, INextGenBotServer } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { ISaveHostedFromGraph } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { ENonSerializableObjectType, TNonSerializableArray } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ISaveBotSerializableRequest, ISaveBotSerializableResponse, ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { isInvalid, isValidRef } from '@colmeia/core/src/tools/utility';
import { BotNavigationControl } from 'app/model/dashboard/bot/bot-navigation-control';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { BpmService } from 'app/services/bpm/bpm.service';
import { GenericNonSerializableService } from '../generic-ns.service';
import { RequestBuilderServices } from '../request-builder.services';
import { RoutingService } from '../routing.service';
import { ServerCommunicationService } from '../server-communication.service';
import { SessionService } from '../session.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardBotsService extends GenericNonSerializableService<typeof routeList.dashboard.children.smartFlow.children.bots> {
    private navigationControl: BotNavigationControl;
    private _nodeCreateOption: ENextGenBotElementType;
    childrenQuantity: number;

    constructor(
        api: ServerCommunicationService,
        rbs: RequestBuilderServices,
        session: SessionService,
        routeSvc: RoutingService,
    ) {
        super(
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.bots,
            { api, rbs, session, routeSvc },
        );
        this.navigationControl = new BotNavigationControl();
    }


    async getBotElements(searchTaggable: ITagableSearch = null, idParent: TGlobalUID = null, cursor = null): Promise<TNonSerializableArray> {
        if (isInvalid(idParent)) {
            this.navigationControl.clear();
        }
        const botElements = await this.getChildren(idParent, ENonSerializableObjectType.bot, cursor, searchTaggable);

        if (isValidRef(botElements)) {
            this.childrenQuantity = botElements.length;
        }

        return botElements;
    }

    async saveBotElement(botElement: INextGenBotServer, isEditing: boolean = false, idParent?: string, skipNavigation?: boolean): Promise<boolean> {
        // debugger
        // if (!isEditing && botElement.botLevel !== ENextGenBotElementType.root && isInvalid(botElement.idParent))
        //     botElement.idParent = isInvalid(idParent) ? (this.navigationControl.getLastItem() as INextGenBotServer).idNS : idParent
        //         ;

        // const response: ISaveBotSerializableResponse = await this.saveBotElementDetailedResponse(botElement, isEditing, idParent, skipNavigation);
        // if (!skipNavigation && !isEditing && isValidRef(response)) {
        //     this.navigationControl.push(botElement);
        // }

        return
    }

    async saveBotElementDetailedResponse(botElement: ISaveHostedFromGraph, isEditing: boolean = false, idParent?: string, skipNavigation?: boolean): Promise<ISaveBotSerializableResponse> {
        if (!isEditing && (<IBotRootServer>botElement.element).botLevel !== ENextGenBotElementType.root && isInvalid(botElement.element.idParent)) {
            botElement.element.idParent = isInvalid(idParent)
                ? (this.navigationControl.getLastItem() as INextGenBotServer).idNS
                : idParent;
        }

        // const idGraphRoot = this.bpmService.getCurrentStrategy().getGraphRulesProcessor().getRootElementId()
        // console.log({ 'idGraphRoot': idGraphRoot });
        // if (!isValidRef(botElement.idGraphRoot)) {
        //     debugger
        // }

        const request: ISaveBotSerializableRequest = {
            ...this.baseRequest(apiRequestType.botTransaction.saveBot),
            ns: botElement.element,
            idGraphRoot: botElement.idGraphRoot,
        };

        const response: ISaveBotSerializableResponse = await this.send(request);

        if (!skipNavigation && !isEditing && isValidRef(response)) {
            this.navigationControl.push(<INextGenBotServer>botElement.element);
        }

        return response;
    }

    getNavigationControl() {
        return this.navigationControl;
    }

    async removeBotElement(idNS: TGlobalUID) {
        console.log('REMOVING NonSerializable: ', idNS);
    }

    getParentName(idBotElement: string): string {
        return this.navigationControl.getParentElementName(idBotElement);
    }

    get lastNavigatorItem(): INextGenBotServer {
        return this.navigationControl.getLastItem();
    }

    get idBotRoot(): string | undefined {
        return (this.navigationControl.getRoot() as INextGenBotServer)?.idNS;
    }

    setBotRootOnCreateBot(botRoot: IBotRootServer) {
        this.navigationControl.clear();
        this.navigationControl.push(botRoot);
    }

    goBack(isCancelCreate: boolean = false): void {
        const lastElement: INextGenBotServer = this.navigationControl.pop();

        if (isCancelCreate) {
            this.cancelCreate(lastElement);
        } else {
            switch (lastElement.botLevel) {
                case ENextGenBotElementType.menuContainer:
                case ENextGenBotElementType.metadata:
                    // case ENextGenBotElementType.menuProxyTransaction:
                    this.goToEditRoot();
                    break;
                case ENextGenBotElementType.root:
                    this.goToBotRootList();
                    break;
                case ENextGenBotElementType.botMenuItem:
                    this.goToEditNode();
                    break;
            }
        }
    }

    private cancelCreate(lastElement: INextGenBotServer): void {
        switch (lastElement.botLevel) {
            case ENextGenBotElementType.menuContainer:
            case ENextGenBotElementType.metadata:
                // case ENextGenBotElementType.menuProxyTransaction:
                this.goToEditNode(lastElement);
                break;
            case ENextGenBotElementType.root:
                this.goToEditRoot(<IBotRootServer>lastElement);
                break;
            default:
                this.goToBotRootList();
                break;
        }
    }


    get nodeCreateOption(): ENextGenBotElementType {
        const nodeCreateOption = this._nodeCreateOption;
        this._nodeCreateOption = undefined;
        return nodeCreateOption;
    }

    goToBotRootList(): void {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.list.path
        );
    }

    goToCreateRoot(): void {
        this.navigationControl.clear();
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.create.path,
        )
    }

    goToEditRoot(botRoot?: IBotRootServer): void {
        let botElement;
        if (isValidRef(botRoot)) {
            this.navigationControl.push(botRoot);
            botElement = botRoot;
        } else {
            botElement = this.navigationControl.getLastItem();
        }

        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.details.path.replace(this.baseRoute.children.details.routeParam, botElement.idNS),
        );
    }

    goToCreateNode(option: ENextGenBotElementType): void {
        this._nodeCreateOption = option;
        const botRoot = this.navigationControl.getRoot() as INextGenBotServer;

        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.nodes.path
                .replace(this.baseRoute.children.nodes.routeParam, botRoot.idNS),
            this.baseRoute.children.nodes.children.create.path
                .replace(this.baseRoute.children.nodes.children.create.path, option),
            this.baseRoute.children.create.path

        );
    }

    goToEditNode(botNode?: INextGenBotServer): void {

        let botElement;
        if (isValidRef(botNode)) {
            this.navigationControl.push(botNode);
            botElement = botNode;
        } else {
            botElement = this.navigationControl.getLastItem();
        }

        //const botRoot = this.navigationControl.getRoot() as INextGenBotServer;

        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            // this.baseRoute.children.nodes.path
            //     .replace(this.baseRoute.children.nodes.routeParam, botRoot.idNS),
            this.baseRoute.children.nodes.children.details.path
                .replace(this.baseRoute.children.nodes.children.details.routeParam, botElement.idNS),
        );
    }

    goToCreateLeaf(): void {
        const botNode: IBotMenuContainerServer = <IBotMenuContainerServer>this.navigationControl.getLastItem();
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.nodes.path
                .replace(this.baseRoute.children.nodes.routeParam, (this.navigationControl.getRoot() as INextGenBotServer).idNS),
            this.baseRoute.children.nodes.children.leafs.path
                .replace(this.baseRoute.children.nodes.children.leafs.routeParam, botNode.idNS),
            this.baseRoute.children.nodes.children.leafs.children.create.path,
        );
    }

    goToEditLeaf(botLeaf: INextGenBotServer): void {
        const botNode: IBotMenuContainerServer = <IBotMenuContainerServer>this.navigationControl.getLastItem();
        this.navigationControl.push(botLeaf);

        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.nodes.path
                .replace(this.baseRoute.children.nodes.routeParam, (this.navigationControl.getRoot() as INextGenBotServer).idNS),
            this.baseRoute.children.nodes.children.leafs.path
                .replace(this.baseRoute.children.nodes.children.leafs.routeParam, botNode.idNS),
            this.baseRoute.children.nodes.children.leafs.children.details.path
                .replace(this.baseRoute.children.nodes.children.leafs.children.details.routeParam, (botLeaf as IBotMenuContainerServer).idNS)
        );
    }

    public goToList(): void {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path
        );
    }
}
