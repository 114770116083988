<ng-container *ngIf="handler">
  <section class="general-form-field-container" [ngClass]="{ viewMode: !isEdit }">
    <section
      *ngIf="singleField; else multipleField"
      class="general-form-single-field"
    >
      <ng-container 
        *ngIf="component && fieldInjector"
      >
        <ng-container
          *ngComponentOutlet="component; injector: fieldInjector"
        ></ng-container>
      </ng-container>
      
    </section>
  
    <ng-template #multipleField>
      <div class="general-form-field general-form-multiple-field">
        <mat-expansion-panel class="stroked-inset" style="margin-left: 2px;">
          <mat-expansion-panel-header>
            {{ schemaProp.prompt }}
          </mat-expansion-panel-header>
          <div
            *ngFor="let item of answers; let index = index"
            class="general-form-field-answer-container"
          >
            <div class="general-form-field-answer">
              <ng-container
                *ngComponentOutlet="component; injector: item.injector"
              ></ng-container>
            </div>
            <button
              *ngIf="isEdit"
              mat-icon-button
              class="delete-button"
              (click)="deleteAnswer(index)">
              <mat-icon>delete</mat-icon
              >
            </button>
          </div>
          <button mat-stroked-button color="primary" (click)="addChild()" *ngIf="isEdit">
            {{ translations.add }}
          </button>
        </mat-expansion-panel>
      </div>
    </ng-template>
  </section>
  
</ng-container>

