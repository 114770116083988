import { sum } from "lodash";
import { PercentageCalculator } from "../../tools/utility";
import { ICampaignExecutionHeader, IRunCampaignCadenceConfig } from "./capaing-exec-interfaces";


export function getAvailableCadenceProportionForCampaign(campaignExecution: ICampaignExecutionHeader): number {
    return getAvailableCadenceProportion(campaignExecution.cadence);
}

export function getAvailableCadenceProportion(cadenceConfig: IRunCampaignCadenceConfig) {
    const result = PercentageCalculator.percentageConstant - sum(cadenceConfig.proportionalCadence.proportions.map(proportion => PercentageCalculator.toPercentage(proportion.proportion)));
    return result;
}

export function getAvailableCadenceProportionWithPercentage(cadenceConfig: IRunCampaignCadenceConfig) {
    const result = PercentageCalculator.percentageConstant - sum(cadenceConfig.proportionalCadence.proportions.map(proportion => proportion.proportion));
    return result;
}