import { Component, Input, OnInit } from '@angular/core';
import { NonSerializableFactory } from '@colmeia/core/src/nser/non-serializable.factory';
import { TBPMCRMElementTypes } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { getStringHoursMinutesSeconds, getTimestampOfTimeString } from '@colmeia/core/src/time/time-utl';
import { isValidNumber, isValidRef } from '@colmeia/core/src/tools/utility';
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from 'app/handlers/generic-dashboard-edit.handler';
import { ENsMoreOptions } from 'app/handlers/ns-more-options.handler';
import { CRMNotificationBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { BpmCrmApiService } from 'app/services/bpm/api/bpm-crm-api.service';
import { ICRMGraphBasicData } from '../crm-graph-basic-data/crm-graph-basic-data.component';
import { BpmService } from 'app/services/bpm/bpm.service';
import { ICRMNotificationServer } from '@colmeia/core/src/shared-business-rules/crm/crm-services/crm-bpm-model';

@Component({
    selector: 'app-crm-graph-create-notification',
    templateUrl: './crm-graph-create-notification.component.html',
    styleUrls: ['./crm-graph-create-notification.component.scss']
})
export class CrmGraphCreateNotificationComponent implements OnInit {

    @Input() handler: CRMNotificationBpmGraphCreateHandler;

    public genericEdit: GenericDashboardEditHandler;
    public crmNotification: ICRMNotificationServer;
    public isEditing: boolean;
    private nsType: TBPMCRMElementTypes = ENonSerializableObjectType.crmServiceConfigNotification;

    public initialDeadlineCK: string;
    public basicCRMHandler: ICRMGraphBasicData

    constructor(
        private bpmCrmApiService: BpmCrmApiService,
        private bpmSvc: BpmService,
    ) { }

    ngOnInit(): void {
        const crmNotification = this.handler.getCrmNotification();
        this.isEditing = isValidRef(crmNotification)

        this.crmNotification = this.isEditing
            ? crmNotification
            : <ICRMNotificationServer>NonSerializableFactory.create(this.nsType,
                { name: '', idParent: this.handler.getRootCrmHostedId() })

        this.initBasicCRMHandler();
        this.initGenericHomeDashboard();

        this.initialDeadlineCK = isValidNumber(this.crmNotification.deadlineCK, 0) ? getStringHoursMinutesSeconds(this.crmNotification.deadlineCK) : undefined;;
    }

    initBasicCRMHandler() {
        this.basicCRMHandler = {
            element: <ICRMNotificationServer>this.crmNotification,
            saveNSCallback: (_botItem: INonSerializable) => { }
        }
    }

    private initGenericHomeDashboard() {
        this.genericEdit = new GenericDashboardEditHandler({
            nsType: this.nsType,
            autoValidate: true,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
                onGenericSaveButtonPressed: this.onSaveElement,
                onGenericBackButtonPressed: () => {
                    this.handler.getDialogRef().close();
                }
            },
            nser: this.crmNotification,
            allowEditTitleInHeader: true,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
            }
        });
    }

    private onSaveElement = async () => {
        const processor = this.bpmSvc.getCurrentStrategy().getGraphRulesProcessor();
        const idParentGraphNode = processor.findNodeElementByHostedId(this.handler.getRootCrmHostedId() || this.crmNotification.idParent)?.getGraphElementID();

        const [result] = await this.bpmCrmApiService.save(this.crmNotification, idParentGraphNode)

        if (!result) return;

        this.handler.closeDialogAndSendResultToParentComponent({ userHasClickedSave: true, nonSerializable: result });
    }

    public inputTimeChange(event: Event) {
        this.crmNotification.deadlineCK = getTimestampOfTimeString((event.target as HTMLInputElement).value);
    }
}
