import { TGlobalUID } from "../../../core-constants/types";
import { Participant } from "../../../business/participant";
import { constant } from "../../../business/constant";
import { ToGroup } from "../to-group";


export class EmployeeRequest extends ToGroup {

    private constructor(primaryID: TGlobalUID, participant: Participant, idGroupToParticipate: TGlobalUID) {
        super(primaryID, participant, constant.subscription.request.group.employee, idGroupToParticipate)
    };

    public static getNewEmployeeRequest(personalGroupParticipant: Participant, idGroupToParticipate: TGlobalUID): EmployeeRequest {
        return new EmployeeRequest(null, personalGroupParticipant, idGroupToParticipate)
    };

};
