import { Injectable } from "@angular/core";
import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { ICampaingActionHeader, ICMassCommunicationAction } from "@colmeia/core/src/shared-business-rules/campaigns/campaign-type-model";
import { ECampaignActionType, ICAEmailChannelSchedulle, ICAWhatsAppSchedule, ISetOmniChannelSchedule, MapEDelivery360ActionToICampaignOmniChannelSchedule } from "@colmeia/core/src/shared-business-rules/campaigns/campaing-comm-strategy";
import { SchemaPropertyServer } from "@colmeia/core/src/shared-business-rules/files/files";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isEmailTemplateVariables } from "@colmeia/core/src/shared-business-rules/social-media/social-media.functions";
import { IEmailSocialMediaSendGridConnectionServer, IEmailTemplate, ISocialMediaConnectionServer, IWhatsAppTemplate, IWhatsAppDetail, MapTypeToSocialMediaConnection } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { INSEmailTemplateServer, INSWhatsAppTemplateServer } from "@colmeia/core/src/shared-business-rules/social-media/template-model";
import { isInvalid, isInvalidArray, isValidRef } from "@colmeia/core/src/tools/utility";
import { LookupService } from "app/services/lookup.service";
import { TemplatesService } from "../../marketing/dashboard-channels-templates-list/templates.service";

@Injectable({
    providedIn: 'root'
})
export class DashboardCampaignsActionService {


    constructor(
        private lookupSvc: LookupService,
        private templateSvc: TemplatesService,
    ) { }
    public static actionsWithVariables: EDelivery360Action[] = [
        EDelivery360Action.Delivery360WhatsApp,
        EDelivery360Action.Delivery360Email,
    ]

    public getNSs = this.lookupSvc.createNSCacheImplementation();
    public getNS(id: string) {
        return this.lookupSvc.getSingleLookupElement(id);
    };
    public mapIdNSToNS: Map<string, INonSerializable> = this.getNSs.cache;

    public shouldValidateTemplateVariables(inputAction: ICampaingActionHeader): inputAction is ICMassCommunicationAction {
        const action = inputAction as ICMassCommunicationAction;

        if (action.actionType !== ECampaignActionType.massComm) {
            return false;
        }

        if (isInvalidArray(action.commStrategy)) {
            return false;
        }

        return action.commStrategy.some(strategy => DashboardCampaignsActionService.actionsWithVariables.includes(strategy.channelType));
    }


    public async getSchemaVariables(idSchemma: string): Promise<string[]> {
        const schema = (await this.getNS(idSchemma) as SchemaPropertyServer);
        if (isInvalid(schema)) return [];
        return schema.schemma.form.filter(field => isValidRef(field.idLocalCanonical)).map(field => field.idLocalCanonical);
    }

    public async validateTemplateVariables(action: ICMassCommunicationAction): Promise<boolean> {
        const allFilledVariables: string[] = [
            ...(isValidRef(action.constants) ? action.constants.filter(variable => variable.value).map(item => item.idLocalCanonical) : []),
            ...isValidRef(action.idSchemma) ? await this.getSchemaVariables(action.idSchemma) : [],
        ];
        const templateVariables: string[] = await this.getTemplateVariables(action);
        const set = new Set(allFilledVariables)
        return templateVariables.every(variable => set.has(variable));
    }


    private mapStrategyByAction: Map<EDelivery360Action, ISetOmniChannelSchedule> = new Map();
    private updateMapStrategyByAction(action: ICMassCommunicationAction): void {
        this.mapStrategyByAction.clear();
        action.commStrategy.map(strategy => this.mapStrategyByAction.set(strategy.channelType, strategy));
    }
    private getStrategyByDeliveryAction<Action extends EDelivery360Action, Strategy extends ISetOmniChannelSchedule = MapEDelivery360ActionToICampaignOmniChannelSchedule[Action]>(action: Action): Strategy {
        return this.mapStrategyByAction.get(action) as Strategy;
    }

    public async getTemplateVariables(action: ICMassCommunicationAction): Promise<string[]> {
        this.updateMapStrategyByAction(action);

        return [
            ...(await this.getAllowedWhatsappCanonicals(this.getStrategyByDeliveryAction(EDelivery360Action.Delivery360WhatsApp))),
            ...(await this.getAllowedEmailCanonicals(this.getStrategyByDeliveryAction(EDelivery360Action.Delivery360Email)))
        ]
    }

    public async getSocialMediaConnection<Action extends EDelivery360Action>(idSocialMediaConnection: string) {
        type SocialMedia = MapTypeToSocialMediaConnection[Action]
        const ns = await this.getNS(idSocialMediaConnection) as SocialMedia;
        return ns;
    }

    public async getAllowedEmailCanonicals(strategy: ICAEmailChannelSchedulle): Promise<string[]> {
        try {
            const templateNS: INSEmailTemplateServer = await this.templateSvc.get<INSEmailTemplateServer>(strategy.idTemplateEmail)
            const template: IEmailTemplate = templateNS?.parameter;
            if (isEmailTemplateVariables(template)) {
                return template.localCanonicals.map(canonical => canonical.idProperty);
            }
            return [];
        } catch (err) {
            console.log(err)
            return [];
        }

    }

    public async getAllowedWhatsappCanonicals(whatsAppStrategy: ICAWhatsAppSchedule): Promise<string[]> {
        if (isInvalid(whatsAppStrategy?.idWAFallbackTemplate)) return [];

        const socialMedia: ISocialMediaConnectionServer = await this.getNS(whatsAppStrategy.idSocialMediaConnection) as ISocialMediaConnectionServer;

        if (isInvalid(socialMedia)) return [];

        const templateNS: INSWhatsAppTemplateServer = await this.templateSvc.get<INSWhatsAppTemplateServer>(whatsAppStrategy?.idWAFallbackTemplate)
        const template: IWhatsAppTemplate = templateNS?.parameter;

        if (isInvalidArray(template?.allVariables)) return [];

        return template.allVariables.map(variable => variable.idProperty);
    }
}
