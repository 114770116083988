import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { EDelivery360Action, TThreadInfoArray } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { IDeliveryTarget, TArrayID } from '@colmeia/core/src/core-constants/types';
import { GeneralFormField, IGeneralFormAnswer, IGeneralFormAnswerSaveRequest, IGeneralFormAnswerSaveResponse, IGetSerializableAnswerRequest, IGetSerializableAnswerResponse, IRFieldResponse, TGeneralFormServerAnswerArray } from '@colmeia/core/src/general-form/general-form-answer';
import { IFormSchema, TFormSchemmaArray } from '@colmeia/core/src/general-form/general-form-interface';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { ICreateNewSchemmaRequest, IGetSchemmaRequest, IGetSChemmaResponse, IGetSpecificSchemmaResponse, IGetSpeciSchemmaRequest } from '@colmeia/core/src/request-interfaces/files-interfaces';
import { ISearchInFileRequest, ISearchInFileResponse, ELookupType } from '@colmeia/core/src/request-interfaces/lookup-request';
import { apiRequestType, EFileRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { getIDConversationFromThreadInfo } from '@colmeia/core/src/rules/thread-conversation-functions';
import { ILocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import { ISchemaPropertyClient, SchemaPropertyServer } from "@colmeia/core/src/shared-business-rules/files/files";
import { IMetaEngagement } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { EMetadataNames } from '@colmeia/core/src/shared-business-rules/metadata/metadata-db';
import { isInvalid, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { DeepFindValuesOfType } from '@colmeia/core/src/tools/utility-types';
import { IInfraParameters } from 'app/model/component/client-infra-comm';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { AttendanceService } from './attendance.service';
import { LookupService } from './lookup.service';
import { RequestBuilderServices } from './request-builder.services';
import { RoutingService } from './routing.service';
import { ServerCommunicationService } from './server-communication.service';
import { SessionService } from './session.service';

export interface ICustomSaveAnswerParams {
    requestType: DeepFindValuesOfType<typeof apiRequestType.files, string>
    answer: IGeneralFormAnswer
    ignoreAttendance: boolean
    idNS?: TGlobalUID
    engagement?: IMetaEngagement
    idGroup?: string,
    targetDatabaseId?: string,
}

const formsRoute = routeList.dashboard.children.smartFlow.children.forms;

@Injectable({
    providedIn: 'root'
})
export class GeneralFormService {

    constructor(
        private api: ServerCommunicationService,
        private rbs: RequestBuilderServices,
        private session: SessionService,
        private routeSvc: RoutingService,
        private activatedRoute: ActivatedRoute,
        private attendanceService: AttendanceService,
        private lookupSvc: LookupService,
    ) { }

    private _selectedSchema: SchemaPropertyServer;

    get selectedSchema(): SchemaPropertyServer {
        const schema = this._selectedSchema;
        this._selectedSchema = null;
        return schema;
    }

    public searchInFile = this.api.sendRequest<ISearchInFileRequest, ISearchInFileResponse>(ELookupType.searchInFile)

    private buildInfra(spinner: boolean = true): IInfraParameters {
        if (spinner) {
            return this.rbs.getNoCallBackSpinnningParameters(
                this.session.getPlayerID(),
                this.session.getSelectedAvatarID()
            );
        } else {
            return this.rbs.getNoCallBackNoSpinnningParameters(
                this.session.getPlayerID(),
                this.session.getSelectedAvatarID()
            );
        }

    }

    async saveSchema(requestType: DeepFindValuesOfType<typeof apiRequestType, string>, nonSerializable: ISchemaPropertyClient): Promise<boolean> {
        const infra: IInfraParameters = this.buildInfra();

        const request: ICreateNewSchemmaRequest = {
            ...this.rbs.createRequestFromInfraParametersForGroup(requestType, infra, this.session.getCurrentSocialNetworkID()),
            ns: nonSerializable,
        };

        const response = await this.api.managedRequest(infra, request);
        return response.executionOK;
    }

    getSchemasNSRequest(
        demandedTag: string,
        searchedTags: TArrayID = [],
        idGroup: TGlobalUID = null,
        cursor: string = null
    ): IGetSchemmaRequest {
        if (isInvalid(idGroup)) {
            idGroup = this.session.getCurrentSocialNetworkID();
        }
        const infra: IInfraParameters = this.buildInfra(false);
        const request: IGetSchemmaRequest = {
            ...this.rbs.createRequestFromInfraParameters(EFileRequest.retrieveNSSchemma, infra),
            demandedTag,
            searchedTags,
            cursor,
        };
        request.idCurrentGroup = idGroup;
        return request;
    }

    getSchemasRequest(
        demandedTag: string,
        searchedTags: TArrayID = [],
        idGroup: TGlobalUID | null = null,
        cursor: string | null = null
    ): IGetSchemmaRequest {
        if (isInvalid(idGroup)) {
            idGroup = this.session.getCurrentSocialNetworkID();
        }
        const infra: IInfraParameters = this.buildInfra(false);
        const request: IGetSchemmaRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.retrieveSchemma, infra),
            demandedTag,
            searchedTags,
            cursor,
        };
        request.idCurrentGroup = idGroup;
        return request;
    }

    async getSchemas(
        demandedTag: string | undefined,
        searchedTags: TArrayID = [],
        idGroup: TGlobalUID | null = null,
        cursor: string | null = null
    ): Promise<TFormSchemmaArray> {
        const infra: IInfraParameters = this.buildInfra(false);
        const request: IGetSchemmaRequest = this.getSchemasRequest(demandedTag, searchedTags, idGroup, cursor);
        const response = await this.api.managedRequest(infra, request);
        if (response.executionOK) {
            const result = response.response as IGetSChemmaResponse;
            return result.schemmas;
        }
        return [];
    }

    async getSpecificSchema(idSchema: string): Promise<IFormSchema | null> {
        const infra: IInfraParameters = this.buildInfra(false);
        const request: IGetSpeciSchemmaRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.getSpecificSchemma, infra),
            idSchemma: idSchema,
        }

        const response = await this.api.managedRequest(infra, request);
        if (response.executionOK) {
            return (response.response as IGetSpecificSchemmaResponse).schemma;
        }
        return null;
    }

    async customSaveAnswer({
        requestType,
        answer,
        idNS,
        engagement,
        targetDatabaseId,
        ignoreAttendance = false,
        idGroup = this.session.getSelectedGroupID()
    }: ICustomSaveAnswerParams): Promise<IGeneralFormAnswerSaveResponse | undefined> {
        const infra: IInfraParameters = this.buildInfra();
        const request: IGeneralFormAnswerSaveRequest = {
            ...this.rbs.createRequestFromInfraParameters(requestType, infra),
            form: answer,
            idNS,
            engagement,
            dynamicListDatabaseID: targetDatabaseId,
        }

        if (this.attendanceService.isAttendingOnGroup(idGroup)) {
            request.aditionalAttendenceInfo = (await this.attendanceService.getAllAttendanceVariablesData(idGroup)).APIExecutionInjectionClientBasic;
        }

        if (!ignoreAttendance && this.isAttending()) {
            request.idConversation = this.getIDConversation(idGroup);
        }

        const response = await this.api.managedRequest(infra, request)
        return response.executionOK ? (response.response as IGeneralFormAnswerSaveResponse) : undefined;
    }

    public isAttending(): boolean {
        return this.attendanceService.isLoggedIn && this.attendanceService.hasAnyConversation();
    }

    public getIDConversation(idGroup: string = this.session.getSelectedGroupID()): string {
        const interaction: Interaction = this.attendanceService.getReplyInteraction(idGroup);
        const threadInfos: TThreadInfoArray = interaction.getThreadInfo();
        const idConversation: string = getIDConversationFromThreadInfo(threadInfos);
        return idConversation;
    }

    async getAnswersForUniqueId(id: TGlobalUID, hasSecurityFilter: boolean = false): Promise<TGeneralFormServerAnswerArray> {
        const infra: IInfraParameters = this.buildInfra(false);

        const request: IGetSerializableAnswerRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.getAnswerForSerializable, infra),
            primaryID: id,
            cursor: null,
            hasSecurityFilter
        }
        const response = await this.api.managedRequest(infra, request);
        if (response.executionOK) {
            const result = response.response as IGetSerializableAnswerResponse;
            return result.responses;
        }

        return null;
    }

    async getAnswersForConversation(idConversation: TGlobalUID): Promise<IGetSerializableAnswerResponse | null> {
        const infra: IInfraParameters = this.buildInfra(false);

        const request: IGetSerializableAnswerRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.files.getAnswerForConversation, infra),
            primaryID: idConversation,
            cursor: null
        }
        const response = await this.api.managedRequest(infra, request);
        if (response.executionOK) {
            return response.response as IGetSerializableAnswerResponse;
        }

        return null;
    }
    public async addGlobalCanonicals(form: IFormSchema): Promise<void> {
        const localCanonicalsIds: string[] = form.form.filter(f => isValidString(f.idLocalCanonical)).map(f => f.idLocalCanonical);
        const localCanonicals: ILocalCanonical[] = await this.lookupSvc.getBatchNonSerializables<ILocalCanonical[]>(localCanonicalsIds)

        form.form.forEach((field: GeneralFormField) => {
            const localCanonical: ILocalCanonical = localCanonicals.find(lc => lc.idNS === field.idLocalCanonical);

            if (!isValidRef(localCanonical)) return;

            field.idGlobalCanonical = localCanonical.globalCanonical;
        });
    }

    public async mergeAnswersWithChannel(canonical: EMetadataNames, responses: IRFieldResponse[], form: IFormSchema): Promise<void> {
        const interaction = this.attendanceService.getReplyInteraction() as StartServiceChat;
        const provider: IDeliveryTarget = interaction.get360Providers()[0];
        const channel: EDelivery360Action = provider.providerType;
        const target: string = provider.target;

        await this.addGlobalCanonicals(form);
        if (!this.hasCanonicalOnForm(canonical, form)) return;
        if (this.hasAlreadyFilledCanonicalOnForm(canonical, form, responses)) return;

        const field: GeneralFormField = form.form.find((field: GeneralFormField) => field.idGlobalCanonical === canonical) as GeneralFormField;
        const response: IRFieldResponse = {
            idProperty: field.idProperty,
            raw: target,
            value: target,
            idLocalCanonical: field.idLocalCanonical,
            idGlobalCanonical: field.idGlobalCanonical,
        }

        responses.push(response);
    }


    public hasAlreadyFilledCanonicalOnForm(canonical: EMetadataNames, form: IFormSchema, answers: IRFieldResponse[]): boolean {
        const idPropertyToField: Map<string, GeneralFormField> = new Map();
        for (const field of form.form) {
            idPropertyToField.set(field.idProperty, field as GeneralFormField);
        }
        return answers.some(answer => idPropertyToField.has(answer.idProperty) && idPropertyToField.get(answer.idProperty).idGlobalCanonical === canonical);
    }

    public hasCanonicalOnForm(canonical: EMetadataNames, form: IFormSchema): boolean {
        return form.form.some((field: GeneralFormField) => field.idGlobalCanonical === canonical);
    }

    goToCreateSchema(pathRoute: string) {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            pathRoute,
            formsRoute.path,
            formsRoute.children.create
        );

    }

    goToEditSchema(schema: SchemaPropertyServer, pathRoute: string) {
        this._selectedSchema = schema;
        this.routeSvc.navigateToId(
            routeID.dashboard,
            pathRoute,
            formsRoute.path,
            formsRoute.children.edit.path.replace(`${formsRoute.children.edit.routeParam}`, schema.schemma.idSchemma),
        )
    }
}
