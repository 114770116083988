import { IInteractionJSON, IParticipation, IPlayerAccessCached, TArrayIDJSON, TParticipationArray } from '../comm-interfaces/barrel-comm-interfaces';
import { permission } from '../core-constants/security-constant';
import { TArrayID } from '../core-constants/types';
import { ESCode } from '../error-control/barrel-error';
import { hasAdminPrivilege, hasPermissionInRoles, hasPermissionInRolesIDs, hasRoleIDToBellongToGroup, staticFactoryRoles } from '../rules/sec-functions';
import { TRoleArray } from '../security/role';
import { isInvalid, isValidArray, isValidRef, throwErrorIfTrueField } from '../tools/utility';
import { TGlobalUID } from './constant';
import { getSocialNetworkIdFromGenealogy } from './nser-identication';
import { IMinimumAuthorizeInfo, PlayerAuthorizeOnlyInfo } from './player-minimun-auth';

export interface ISocialNetworkStats {
    idSocialNetwork: TGlobalUID;
    grps: number;
    admins: number;
    roots: number;
    isPersonal: boolean;
    isRootGroup: boolean;
}
export type TSocialNetworkHash = { [idSocialNetwork: string]: ISocialNetworkStats };

type TSocialNetStatArray = Array<ISocialNetworkStats>;

export class PlayerAccessCached {
    private playerInfo: IPlayerAccessCached;
    private throwIfNotFound: boolean;
    private secArray: TParticipationArray;
    private myAvatars: { [idAvatar: string]: TGlobalUID };
    private idOriginalAvatar: TGlobalUID;
    private allSN: TSocialNetworkHash;
    private allSNArray: TSocialNetStatArray;

    constructor(playerInfo: IPlayerAccessCached, throwIfNotFound: boolean = true) {
        this.playerInfo = playerInfo;
        this.throwIfNotFound = throwIfNotFound;
        this.getGroupArrayID().forEach((idGroup) => {
            this.playerInfo.part[idGroup].grp = idGroup;
        })
        this.myAvatars = {};
        this.allSN = {};
        this.allSNArray = [];
        this.loadSupportData();
    };

    public isThrow(): boolean { return this.throwIfNotFound; };

    public toAuthorizeOnlyInfo(): PlayerAuthorizeOnlyInfo {
        const min: IMinimumAuthorizeInfo = { avatarArray: this.getAvatarArrayID() };
        return new PlayerAuthorizeOnlyInfo(this.getPlayerID(), min);
    };

    public getPlayerInfoJSON(): IPlayerAccessCached { return this.playerInfo; };

    public getPlayerID(): TGlobalUID { return this.playerInfo.idPlayer; };

    public getOriginalAvatarID(): TGlobalUID {
        return this.idOriginalAvatar;
    };

    public getGroupInfoArray(): TParticipationArray {
        return this.secArray;
    };

    private loadSupportData(): void {
        this.secArray = this.getGroupArrayID().map((idGroup) => {
            const part: IParticipation = this.playerInfo.part[idGroup];
            if (((part.tpg && part.tpg.isa) || part.isp) && !this.myAvatars[part.avt]) {
                this.myAvatars[part.avt] = part.grp;
            };
            this.idOriginalAvatar = part.avt;
            const idSocialNetwork: TGlobalUID = this.getSocialNetworkFromMap(idGroup, part);
            if (!this.allSN[idSocialNetwork]) {

                const isPersonal: boolean = this.playerInfo.part[idSocialNetwork] && (this.playerInfo.part[idSocialNetwork].isp || // Atenção.. para slow changes
                    (this.playerInfo.part[idSocialNetwork].tpg && this.playerInfo.part[idSocialNetwork].tpg.isp));

                this.allSN[idSocialNetwork] = {
                    admins: 0, roots: 0, grps: 0, idSocialNetwork: idSocialNetwork,
                    isPersonal, isRootGroup: idGroup === idSocialNetwork
                }
            }
            ++this.allSN[idSocialNetwork].grps;
            if (hasAdminPrivilege(part.rls)) {
                ++this.allSN[idSocialNetwork].admins;
            };
            if (hasRoleIDToBellongToGroup(part.rls)) {
                ++this.allSN[idSocialNetwork].roots;
            };

            return part;
        })
        this.allSNArray = Object.values(this.allSN);

    };

    public getGroupArrayID(): TArrayIDJSON {
        return Object.keys(this.playerInfo.part);
    };

    public getYourRelevantRootSN(): TGlobalUID {
        const net: TSocialNetStatArray = this.allSNArray.sort((x, y) => { return y.roots - x.roots });
        return isValidArray(net) ? net[0].idSocialNetwork : null;
    };

    public getAllPlayerSN(): TArrayID {
        return this.allSNArray.map((g) => { return g.idSocialNetwork });
    }

    public tryToSelectSocialNetwork(idSN: string, stats: ISocialNetworkStats[]) {
        const ids: string[] = stats.map(stat => stat.idSocialNetwork);
        if (idSN && ids.includes(idSN)) return idSN;
        return isValidArray(ids) ? ids[0] : null;
    }

    public getYourRelevantNonPersonalAdminSN(idSN: string): TGlobalUID {
        const net: TSocialNetStatArray = this.allSNArray.filter((p) => { return !p.isPersonal })
            .sort((x, y) => { return y.admins - x.admins });
        return this.tryToSelectSocialNetwork(idSN, net);
    };

    public getYourRelevantNonPersonalGroupsSN(idSN: string): TGlobalUID {
        const net: TSocialNetStatArray = this.allSNArray.filter((p) => { return !p.isPersonal })
            .sort((x, y) => { return y.grps - x.grps });
        return this.tryToSelectSocialNetwork(idSN, net);
    };

    public getAvatarsGroupIDs(idAvatar: TGlobalUID): TArrayIDJSON {
        return this.getGroupInfoArray().filter((info) => { return info.avt === idAvatar }).map((info) => { return info.grp });
    }

    public getPlayerPersonalGroupID(): TGlobalUID {
        return this.playerInfo.idPersonalGroup;
    };

    public getParticipantID(idGroup: TGlobalUID, idAvatar: TGlobalUID, logicalOperaction: boolean = false): TGlobalUID {
        let info: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, logicalOperaction);
        return info && info.avt == idAvatar ? info.par : null;
    };

    public getUnsafeParticipantID(idGroup: TGlobalUID): TGlobalUID {
        let info: IParticipation = this.getGroupAccessMap(idGroup, true);
        return info ? info.par : null;
    };

    public getParticipantIDPersonalGroup(): TGlobalUID {
        let info: IParticipation = this.getGroupAccessMap(this.playerInfo.idPersonalGroup);
        return info.par;
    };

    public getAvatarArrayID(): TArrayIDJSON {
        return Object.keys(this.myAvatars);
    };


    public avatarBelongsToPlayer(idAvatar: TGlobalUID): boolean {
        return isValidRef(this.myAvatars[idAvatar])
    };

    public interactionMadeByMe(interaction: IInteractionJSON): boolean {
        return this.avatarBelongsToPlayer(interaction.participant.avatar.primaryID);
    };


    public getAvatarGroup(idGroup: TGlobalUID, logicalOperaction: boolean = false): TGlobalUID {
        let info: IParticipation = this.getGroupAccessMap(idGroup, logicalOperaction);
        return info ? info.avt : null;
    };

    public getParticipantGroup(idGroup: TGlobalUID, logicalOperaction: boolean = false): TGlobalUID {
        let info: IParticipation = this.getGroupAccessMap(idGroup, logicalOperaction);
        return info ? info.par : null;
    };

    public gerSocialNetworkFromIDGroup(idGroup: TGlobalUID, logicalOperaction: boolean = false): TGlobalUID {
        const part: IParticipation = this.getGroupAccessMap(idGroup, logicalOperaction);
        if (part) {
            return this.getSocialNetworkFromMap(idGroup, part);
        };
        return null;
    }

    private getSocialNetworkFromMap(idGroup: TGlobalUID, part: IParticipation): TGlobalUID {
        return part.gen.length === 0 ? idGroup : getSocialNetworkIdFromGenealogy(part.gen);
    }

    public isOnGenealogy(idGroupChild: TGlobalUID, idGroupParent: TGlobalUID, returnItSelf: boolean = false): boolean {
        const par: IParticipation = this.getGroupAccessMap(idGroupChild);
        return (par && par.gen.some((p) => { return p === idGroupParent }) || (returnItSelf && idGroupChild === idGroupParent));
    };

    public getAllGroupOnGenealogy(idGroupParent: TGlobalUID, includeItself: boolean = false): TArrayID {
        return this.getGroupInfoArray().filter((info) => { return this.isOnGenealogy(info.grp, idGroupParent, includeItself) }).map((info) => { return info.grp });
    };

    public getShallowerGroup(idGroup: TGlobalUID): TGlobalUID {
        const partArray: TParticipationArray = this.getGroupInfoArray().filter((f) => { return this.isOnGenealogy(f.grp, idGroup, true) });
        if (isValidArray(partArray)) {
            return partArray.sort((x, y) => { return x.gen.length - y.gen.length })[0].grp;
        } else {
            return idGroup;
        };
    };

    public getIDParticipantsFromGenealogy(idGroupParent: TGlobalUID, includeItself: boolean = false): TArrayID {
        return this.getGroupInfoArray().filter((info) => { return this.isOnGenealogy(info.grp, idGroupParent, includeItself) }).map((info) => { return info.par });
    }

    public hasAccessBellow(idGroup: TGlobalUID, idAvatar: TGlobalUID): boolean {
        const par: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, true);

        return (par && hasRoleIDToBellongToGroup(par.rls)) ||
            this.getGroupInfoArray().filter((info) => { return this.isOnGenealogy(info.grp, idGroup) })
                .some((par) => { return par.avt === idAvatar && hasRoleIDToBellongToGroup(par.rls) })
    }

    public getGroupParticipantRole(idGroup: TGlobalUID, idParticipant: TGlobalUID, logicalOperaction: boolean = false): TRoleArray {
        let info: IParticipation = this.getParticipantInfoFromGroupParticipant(idGroup, idParticipant, logicalOperaction);
        const role: TRoleArray = info ? staticFactoryRoles(info.rls) : [];
        return role;
    };

    public isAdminGroup(idGroup: TGlobalUID, idAvatar: TGlobalUID): boolean {
        const info: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, true);
        return info && isValidArray(info.rls) ? hasPermissionInRolesIDs(info.rls, permission.hasAdminPrivilege) : false;
    };

    public getGroupAvatarRole(idGroup: TGlobalUID, idAvatar: TGlobalUID, logicalOperaction: boolean = false): TRoleArray {
        const info: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, logicalOperaction);
        return info ? staticFactoryRoles(info.rls) : [];
    };

    public getGroupAvatarRoleIDS(idGroup: TGlobalUID, idAvatar: TGlobalUID, logicalOperaction: boolean = false): TArrayID {
        let info: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, logicalOperaction);
        return info ? info.rls : [];
    };

    public hasPermission(idGroup: TGlobalUID, idAvatar: TGlobalUID, privileges: TGlobalUID): boolean {
        return this.hasPermissionFromArray(idGroup, idAvatar, privileges.split(';'));
    };

    public hasPermissionFromArray(idGroup: TGlobalUID, idAvatar: TGlobalUID, privileges: TArrayID): boolean {
        let ok: boolean;
        for (let permission of privileges) {
            ok = permission && hasPermissionInRoles(this.getGroupAvatarRole(idGroup, idAvatar), permission);
            if (ok)
                return ok;
        };
        return false;
    };

    public belongsToGroup(idGroup: TGlobalUID): boolean {
        const accessMap: IParticipation = this.getGroupAccessMap(idGroup, true);
        return accessMap ? hasRoleIDToBellongToGroup(accessMap.rls) : false;
    };

    public getAvatarPersonalParticipantID(idAvatar: TGlobalUID): TGlobalUID {

        throwErrorIfTrueField(this.throwIfNotFound && isInvalid(this.myAvatars[idAvatar]), ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true,
            'getAvatarPersonalParticipantID', idAvatar, 'not found');

        const part: IParticipation = this.playerInfo.part[this.myAvatars[idAvatar]];
        return part.par;
    };


    public getAvatarPersonalGroupID(idAvatar: TGlobalUID): TGlobalUID {

        throwErrorIfTrueField(this.throwIfNotFound && isInvalid(this.myAvatars[idAvatar]), ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true,
            'getAvatarPersonalGroupID', idAvatar, 'not found');
        return this.myAvatars[idAvatar];

    };

    public avatarBelongsToGroup(idGroup: TGlobalUID, idAvatar: TGlobalUID): boolean {
        const grp: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, true);
        return grp ? hasRoleIDToBellongToGroup(grp.rls) : false;
    }

    public getParticipantInfoFromGroupParticipant(idGroup: TGlobalUID, idParticipant: TGlobalUID, logicalOperaction: boolean = false): IParticipation {
        const part: IParticipation = this.getGroupAccessMap(idGroup, logicalOperaction);

        throwErrorIfTrueField(this.throwIfNotFound && !logicalOperaction && !part && part.par !== idParticipant, ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true,
            'getParticipantInfoFromGroupParticipant', 'pair', idGroup, '/', idParticipant, 'not found');

        return part && part.par === idParticipant ? part : null;
    };

    public getParticipantFromGroup(idGroup: TGlobalUID, idAvatar: TGlobalUID, dontThrow?: boolean): TGlobalUID {
        const par: IParticipation = this.getParticipantInfoFromGroupAvatar(idGroup, idAvatar, dontThrow);
        return par.par;
    }

    public getParticipantInfoFromGroupAvatar(idGroup: TGlobalUID, idAvatar: TGlobalUID, logicalOperaction: boolean = false): IParticipation {
        const part: IParticipation = this.getGroupAccessMap(idGroup, logicalOperaction);

        throwErrorIfTrueField(this.throwIfNotFound && !logicalOperaction && !part && part.avt !== idAvatar, ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true,
            'getParticipantInfoFromGroupAvatar', 'pair', idGroup, '/', idAvatar, 'not found');

        return part && part.avt === idAvatar ? part : null;
    };

    public getGroupAccessMap(idGroup: TGlobalUID, logicalOperaction: boolean = false): IParticipation {
        let info: IParticipation = this.playerInfo.part[idGroup];
        throwErrorIfTrueField(this.throwIfNotFound && !logicalOperaction && !info, ESCode.server1.fromServer, ESCode.server1.f.cache.noPlayerCached, true,
            'getPlayerIDFromGroupAvatar', 'IDGroup', idGroup, 'not found');

        return info;
    };

    protected setPlayerInfo(playerInfo: IPlayerAccessCached): void {
        this.playerInfo = playerInfo;
    };

    public isMyParticipant(idParticipant: TGlobalUID): boolean {
        return this.getGroupInfoArray().some((part) => { return part.par === idParticipant })
    };

    public hasChangesOnGroupVisibilityOfPlayer(player: PlayerAccessCached): boolean {
        return this.playerGroupAreDifferent(this, player) ||
            this.playerGroupAreDifferent(player, this);
    };

    private playerGroupAreDifferent(player1: PlayerAccessCached, player2: PlayerAccessCached): boolean {
        return player1.getGroupInfoArray()
            .some((t) => {
                return !player2.getGroupInfoArray().some((a) => {
                    return t.par === a.par && hasRoleIDToBellongToGroup(t.rls) === hasRoleIDToBellongToGroup(a.rls)
                })
            });
    };

    public getAllSocialNetworks(): TSocialNetworkHash { return this.allSN; }

    public hasSocialNetwork(idSocialContext: TGlobalUID): boolean { return isValidRef(this.allSN[idSocialContext]) }



};
