import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, SecurityContext} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {onlyImageAllowed} from '@colmeia/core/src/multi-media/file-interfaces';
import {TMultimediaInstanceArray} from '@colmeia/core/src/multi-media/multi-media-instance';
import {MMconstant} from '@colmeia/core/src/multi-media/multimedia-constant';
import {Observable, Subscription} from 'rxjs';
import {Memoize} from 'typescript-memoize';
import {constant, TGlobalUID} from '@colmeia/core/src/business/constant';
import {Group} from '@colmeia/core/src/business/group';
import {Serializable} from '@colmeia/core/src/business/serializable';
import {INavigationRules} from '@colmeia/core/src/core-constants/types';
import {TSerializableArray} from '@colmeia/core/src/persistency/uber-cache';
import {noRootGenealogy, groupToGenealogyEntry, fixGenealogy, isValidGenealogy} from "@colmeia/core/src/rules/filters";
import {isInvalid, isValidRef, isValidArray, isValidString, nop} from '@colmeia/core/src/tools/utility';
import {environment} from 'environments/environment-client';
import {hasPermissionInRoles} from '@colmeia/core/src/rules/sec-functions';
import {GroupType} from '@colmeia/core/src/business/group-type';
import {IChangeInterfaceListener, IListenerSubscription} from "../../../model/signal/ps-interfaces";
import {FollowButtonHandler} from "../../../handlers/follow-button-handler";
import {HandlerSearch, ISearchComponentParamater} from "../../../handlers/search-handler";
import {SubscriptionSignal} from "../../../model/signal/subscription-signal";
import {
    ExecutableItem,
    TExecutableItemArray
} from "../../../services/controllers-services/security-controller/executable-item";
import {NDropDown} from "../../../handlers/dropdown.handler";
import {BreadcrumbItem, BreadcrumbItemList, IBreadcrumbDescriptor} from "../../../model/breadcrumb.model";
import {ConstantService} from "../../../services/constant.service";
import {RouterManagerService} from "../../../services/router-manager.service";
import {SignalListenerService} from "../../../services/signal/signal-listener";
import {NavigatorServices} from "../../../services/controllers-services/navigator/navigator.service";
import {SessionService} from "../../../services/session.service";
import {MultimediaService} from "../../../services/multimedia.service";
import {HandlerFactoryService} from "../../../handlers/handler-factory.service";
import {ImgSecureDownloadService, TSafeUrlContainer} from "../../../services/auth/img-secure-download.service";
import {InterfaceInfoSignal} from "../../../model/signal/interface-signal";
import {NChatBackBone} from "../../../model/chat-backbone.model";
import {SafeMenuBuilderServices} from "../../../services/controllers-services/security-controller/safe-menu-builder";
import {DatePickerHandler, IDatePickerHandlerParameter} from "../../../handlers/date-picker.handler";
import {EParticipantClickTypeMode} from "../../../handlers/participant-selector.handler";
import {clientConstants} from "../../../model/constants/client.constants";
import {getBreadCrumbFromGenealogy, } from "../../../model/client-filter";
import {RoutingBuilder} from "../../../services/routing-builder";
import {IFollowButtonHandlerParameter} from "../../../model/follow-button.model";
import {EHexagonSizes, HandlerHexagonon} from "../../../handlers/hexagono.handler";
import {MultimediaHandler} from "../../../handlers/multimedia-handler";
import {routeID, routeList} from "../../../model/routes/route-constants";
import {NCardModel} from "../../../model/card.model";
import {ActivatedRoute} from '@angular/router';
import {getNormalizedParameter} from 'app/model/client-utility';
import {RootComponent} from 'app/components/foundation/root/root.component';
import {MoreOptionsThreeDotsHandler} from 'app/handlers/more-options-three-dots.handler';
import {GroupCardItemService} from '../group-card-item/group-card-item.service';
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {
    ESearchAllowedType,
    ESearchScope
} from "@colmeia/core/src/shared-business-rules/search";
import {allGroupHomeButtons, EGroupHomeButtons, IGroupHomeButton} from "../../../model/group-home-buttons";
import {afterBefore, EGroupNavigation, EGroupType} from "@colmeia/core/src/shared-business-rules/visual-constants";
import {navigationRules} from "@colmeia/core/src/core-constants/security-constant";
import { SocialNetworkDatabaseService } from 'app/services/social-network-database.service';
import { TIDropdownMenuItemArray } from 'app/model/misc.model';
import { SocialNetworkNavigationStart } from 'app/services/controllers-services/navigator/navigator';
import { map } from 'rxjs/operators';

interface IFilterOptions {
    selectedOptionDate: number;
    selectedComboOptionId: TGlobalUID;
}

interface IFilterIdToOptions {
    [idFilter: string]: IFilterOptions;
}

const rootHome = '/' + RoutingBuilder.getRoute(
    routeID.groups.home,
    constant.entity.rootGroups.root
);

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-group-home',
    templateUrl: './group-home.component.html',
    styleUrls: ['./group-home.component.scss']
})
export class GroupHomeComponent extends RootComponent<'filterGroupTitle'> implements OnInit, AfterViewInit, IListenerSubscription, NDropDown.IDropdownOptionClickedClient, IChangeInterfaceListener {

    public followButtonHandler: FollowButtonHandler;
    private _filtersCardDescriptor: NCardModel.ICardDescriptor;
    private searchHandler: HandlerSearch;
    public allFilters: TExecutableItemArray;
    private dropDownHandlers: Map<ExecutableItem, NDropDown.DropDownHandler>;
    private filterOptions: IFilterIdToOptions;
    breadCrumbDescriptor: IBreadcrumbDescriptor;
    groups: TExecutableItemArray;
    safeBannerUrl: SafeUrl;
    imgSafeSubscription: Subscription;
    public canShowFilter: boolean = false;
    private routeParamSubscription: Subscription;
    public showArrowUp: boolean = false;
    public isInAllRoot: boolean = false;
    public bannerStyle: Object = {};
    public groupHexagonHandler: HandlerHexagonon;
    public aboutCardDescriptor: NCardModel.ICardDescriptor;
    public publicationsCardDescriptor: NCardModel.ICardDescriptor;
    public group: Group;
    public showBreadcrumb: boolean = false;
    public moreOptionsHandler: MoreOptionsThreeDotsHandler;

    public featuresDropdownList: TIDropdownMenuItemArray = [];
    public socialNetworkNavigation$: Observable<{ event: SocialNetworkNavigationStart, hexagon: HandlerHexagonon } | undefined>;

    constructor(
        private constantSvc: ConstantService,
        private routingManager: RouterManagerService,
        private cdrRef: ChangeDetectorRef,
        private listener: SignalListenerService,
        private navigatorService: NavigatorServices,
        private session: SessionService,
        private multimediaServices: MultimediaService,
        private handlerFactory: HandlerFactoryService,
        private imageSecureSvc: ImgSecureDownloadService,
        private routerSvc: RouterManagerService,
        private activatedRoute: ActivatedRoute,
        private grpCardItemSvc: GroupCardItemService,
        private socialNetworkSvc: SocialNetworkDatabaseService,
        private navigatorSvc: NavigatorServices,
        private route: ActivatedRoute,
    ) {
        super({
            filterGroupTitle: gTranslations.fragments.filterGroupTitle
        });
    }

    ngOnInit() {
        this.allFilters = [];
        this.searchHandler = this.buildSearchHandler();
        this.dropDownHandlers = new Map<ExecutableItem, NDropDown.DropDownHandler>();
        this.listener.listenSubscriptionChanges(this);
        this.listener.listenToInterfaceChanges(this);
        this.aboutCardDescriptor = {
            ...NCardModel.getDefaultCardDescriptor(),
            title: 'Sobre',
            titleSize: NCardModel.ETitleSize.Small
        };
        this.publicationsCardDescriptor =  {
            ...NCardModel.getDefaultCardDescriptor(),
            title: 'Publicações',
            titleSize: NCardModel.ETitleSize.Small
        };
        this.moreOptionsHandler = this.getMoreOptionsHandler();
        this.subscribeToRouteParamChange();

        this.socialNetworkNavigation$ = this.navigatorSvc.lastSocialNetworkNavigation$
        .pipe(map( event => {
            const isNavigationStart: boolean = event instanceof SocialNetworkNavigationStart;

            if(isNavigationStart) {
                setTimeout(() => {
                    this.cdrRef.markForCheck();
                }, event.expireTS + 1);
            }

            return isNavigationStart
                ? {
                    event,
                    hexagon: this.handlerFactory.newHexagon({
                        serializable: event.targetGroup,
                        size: EHexagonSizes.lg,
                        idMultimediaTag: MMconstant.tag.photo
                    })
                }
                : undefined
        }));

    }

    ngAfterViewInit() {
        this.updateDisplayableItens();
        this.cdrRef.markForCheck();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.listener.destroySubscriptions(this);
        if(this.imgSafeSubscription) {
            this.imgSafeSubscription.unsubscribe()
        }
        this.unsubscribeFromRouteParamChange();
    }

    private subscribeToRouteParamChange(): void {
        this.routeParamSubscription = this.activatedRoute.parent.params.subscribe(async res => {
            const params = res;
            const idGroup = params[getNormalizedParameter('/' + routeList.parameters.groupID)];
            const selectedGroup: Group = this.session.getSelectedGroup();
            if (idGroup && selectedGroup && selectedGroup.isNot(idGroup)) {
                this.navigatorService.navigateToGroupID(idGroup);
            };
        });
    }

    private unsubscribeFromRouteParamChange(): void {
        if (isValidRef(this.routeParamSubscription)) {
            this.routeParamSubscription.unsubscribe();
        }
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (sign.languageChanged()) {
            this.generateFiltersCardDescriptor();
            this.cdrRef.markForCheck();
        }
    }

//#region TOREMOVE
    private chatBackboneData: NChatBackBone.IChatBackboneClientData;

    setViewMode(viewMode: NChatBackBone.EViewMode): void {
        this.chatBackboneData = this.buildChatBackboneData(viewMode);
    }

    private buildChatBackboneData(viewMode: NChatBackBone.EViewMode): NChatBackBone.IChatBackboneClientData {
        return {
            visible: true,
            viewMode: viewMode,
            rootInteraction: undefined, // @TODO Daniel ver isto:!!!!!!
        };
    }

    getChatBackboneData(): NChatBackBone.IChatBackboneClientData {
        return this.chatBackboneData;
    }

    setRootInteraction(subscriptionSignal: SubscriptionSignal): void {
            this.setViewMode(NChatBackBone.EViewMode.None);
    }

//#endregion

//#region Header
    downloadBackGroundBanner(): void {
        if(!isValidString(this.getUnsafeBannerUrl())) {
            this.safeBannerUrl = null
            return
        }

        this.imgSafeSubscription = this.imageSecureSvc
            .download(this.getUnsafeBannerUrl())
            .subscribe((url: TSafeUrlContainer) => {
                this.safeBannerUrl = url[this.getStyleSecureContext()];
                this.updateDisplayableItens();
            })
    }

    getBannerStyle(): Object {
        if (isInvalid(this.safeBannerUrl)) return {}
        const sanitizedUrl = this.imageSecureSvc.getSanitizedSafeURL(this.getStyleSecureContext(), this.safeBannerUrl)
        return {backgroundImage: `url(${sanitizedUrl})`}
    }

    getStyleSecureContext(): SecurityContext {
        return SecurityContext.STYLE
    }

    getUnsafeBannerUrl(): string {
        const group = this.getCurrentGroup();
        if (isInvalid(group)) {
            return null;
        }
        const bannerUri: string = group.getMultimediaIDwithIDTag(MMconstant.tag.banner);
        if(!isValidRef(bannerUri)) {
            return ''
        }
        const bannerUrl: string = `${
            this.constantSvc.getFileUrl()
        }${
            bannerUri
        }`
        return bannerUrl;
    }

    private updateDisplayableItens() {
        this.bannerStyle = this.getBannerStyle();
        this.groupHexagonHandler = HandlerHexagonon.newHandler({
            serializable: this.getCurrentGroup(),
            size: EHexagonSizes.md,
            // idMultimediaTag: MMconstant.tag.photo
        });

        this.group = this.getCurrentGroup();
        this.isInAllRoot =  this.group.getPrimaryID() == constant.entity.rootGroups.root;
        this.showBreadcrumb = this.getCurrentGroupID() !== constant.entity.rootGroups.root;

    }

//#endregion

    public changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        this.generateFiltersCardDescriptor();
        // TOREMOVE: just for testing
        this.setRootInteraction(subscriptionSignal);
        this.groups = subscriptionSignal.getSafeRequestedGroups();

        this.filterOptions = {};

        // init filters
        const menu: SafeMenuBuilderServices = new SafeMenuBuilderServices(this.session.getMenuSafeBuilderData());
        this.allFilters = menu.getNavigationBarFilters(EGroupNavigation.whatYouWantSee)
            .filter(f => f.getPrimaryID() !== navigationRules.typeOfMessages);
        //@TODO remove this crap
            // .filter(filter => filter.getPrimaryID() != hiveScreen.groupNavigation.attention);

        this.allFilters.forEach((filter: ExecutableItem) => {
            this.dropDownHandlers.set(filter, this.buildDropdownHandler());
            const dropDownHandler = this.dropDownHandlers.get(filter);

            // init filter options
            this.filterOptions[filter.getPrimaryID()] = {
                selectedComboOptionId: afterBefore.after,
                selectedOptionDate: (new Date()).getTime()
            }

            // setting options for handler
            dropDownHandler.setExecutableItems(
                this.navigatorService.isInteractionTypeFilter(filter) ?
                menu.getInteractionTypeFilterAvailable() :
                menu.getNavigationBarFilters(filter.getPrimaryID())
            );
            dropDownHandler.setSelectedItem(
                dropDownHandler.getAllItems().find(item => this.navigatorService.isDefaultSelected(item)))
        });
        this.generateFollowButtonHandler();
        this.resetBreadCrumb(subscriptionSignal);
        this.downloadBackGroundBanner();
        this.updateDisplayableItens();
        this.moreOptionsHandler = this.getMoreOptionsHandler();
        this.cdrRef.markForCheck();
    }

    public getCurrentGroupID(): any {
        return this.getCurrentGroup().getPrimaryID();
    }

    public getCurrentGroup(): Group {
        return this.session.getSelectedGroup();
    }

    public getCurrentGroupName(): string {
        return this.getCurrentGroup().getName();
    }


//#region Buttons

    navButtons: IGroupHomeButton[] = allGroupHomeButtons;
    navButtonsEnum: typeof EGroupHomeButtons = EGroupHomeButtons;
    currentButton: EGroupHomeButtons = EGroupHomeButtons.Groups;

    onBtnClicked(clicked: EGroupHomeButtons): void {
        this.currentButton = clicked;
    }

    public isButtonSelected(button: EGroupHomeButtons): boolean {
        return button === this.currentButton;
    }


//#endregion

//#region Filters

    @Memoize()
    getGroupsCardDescriptor(): NCardModel.ICardDescriptor {
        return NCardModel.getDefaultCardDescriptor({
            title:  Serializable.staticFactory(ESearchAllowedType.groups).getName(),
            titleSize: NCardModel.ETitleSize.Small,
            theme: NCardModel.ECardTheme.Invisible,
        })
    }

    private generateFiltersCardDescriptor() {
        this._filtersCardDescriptor = NCardModel.getDefaultCardDescriptor({
            title: Serializable
                .staticFactory(EGroupNavigation.whatYouWantSee)
                .getSerializableText(constant.serializableField.auxiliars.aux03),
            contentsShown: (this._filtersCardDescriptor)
                ? this._filtersCardDescriptor.contentsShown
                : false,
            theme: NCardModel.ECardTheme.Invisible,
            titleSize: NCardModel.ETitleSize.Small
        });
    }
    get filtersCardDescriptor(): NCardModel.ICardDescriptor {
        return this._filtersCardDescriptor;
    }

    public onChangeSelectedComboId(filter: Serializable, optionSelected: TGlobalUID): void {
        this.filterOptions[filter.getPrimaryID()].selectedComboOptionId = optionSelected;
        this.onDropdownOptionClicked();
    }

    public getSelectedComboOptionId(filter: Serializable): TGlobalUID {
        return this.filterOptions[filter.getPrimaryID()].selectedComboOptionId;
    }

    public isComboOptionSelected(filter: Serializable, option: Serializable): boolean {
        return this.filterOptions[filter.getPrimaryID()].selectedComboOptionId === option.getPrimaryID();
    }

    public setOptionDate(filter: Serializable, date: number): void {
        this.filterOptions[filter.getPrimaryID()].selectedOptionDate = date;
        this.onDropdownOptionClicked();
    }

    public getOptionDate(filter: Serializable): number {
        return this.filterOptions[filter.getPrimaryID()].selectedOptionDate;
    }

    generateDatePickerHandlerForFilter(filter: Serializable): DatePickerHandler {
        const self = this;
        const parameter: IDatePickerHandlerParameter = {
            onPick(time: number) {
                self.setOptionDate(filter, time)
            },
            current: self.getOptionDate(filter)
        };
        return new DatePickerHandler(parameter);
    }

    public getNavigation(): INavigationRules {
        return this.navigatorService.getCurrentNavRules();
    }

    public async onDropdownOptionClicked(): Promise<void> {
        this.allFilters.forEach((filter: ExecutableItem) => {
            const selectedOption: ExecutableItem = this.getSelectedOption(filter);
            const dropDownHandler = this.dropDownHandlers.get(filter);

            // where
            if (this.navigatorService.isWhereFilter(filter) && dropDownHandler.containsItem(selectedOption)) {
                this.navigatorService.setWhere(selectedOption);
            // attention
            } else if (this.navigatorService.isAttentionFilter(filter) && dropDownHandler.containsItem(selectedOption)) {
                this.navigatorService.setTimedChoice(selectedOption);
                //this.navigatorService.setTimedWhenOcurrs(this.filterOptions[filter.getPrimaryID()].selectedOptionDate);
                this.navigatorService.setTimedWhenOcurrs(null);
                this.navigatorService.setTimedAfter(
                    this.filterOptions[filter.getPrimaryID()].selectedComboOptionId === afterBefore.after);
            // followUp
            } else if (this.navigatorService.isFollowUp(filter) && dropDownHandler.containsItem(selectedOption)) {
                this.navigatorService.setFollowUp(selectedOption)
            // popularity
            } else if (this.navigatorService.isPopularityFilter(filter) && dropDownHandler.containsItem(selectedOption)) {
                this.navigatorService.setPopularity(selectedOption);
            // interactionType
            } else if (this.navigatorService.isInteractionTypeFilter(filter) && dropDownHandler.containsItem(selectedOption)) {
                this.navigatorService.setInteractionType(selectedOption.getPrimaryID());
                this.navigatorService.setInteractionTime(new Date(this.filterOptions[filter.getPrimaryID()].selectedOptionDate).getTime());
                this.navigatorService.setInteractionAfter(
                    this.filterOptions[filter.getPrimaryID()].selectedComboOptionId === afterBefore.after);
            }
        });

        // send results to navigator service
        await this.navigatorService.runNavigationSelection(this.getNavigation());
        this.cdrRef.markForCheck();
    }

    public getAllFilters(): TExecutableItemArray {
        return this.allFilters;
    }

    public canShowFilterOptions(filter: ExecutableItem): boolean {
        const selectedOption: ExecutableItem = this.getSelectedOption(filter);
        if (this.navigatorService.isOptionableSelection(filter, selectedOption)) {
            return true;
        }
        return false;
    }

    public onFilterClicked(event: MouseEvent, filter: ExecutableItem): void {
        for (const [execItem, handler] of this.dropDownHandlers.entries()) {
            if (!execItem.iss(filter.getSerializable()))
                handler.hide();
        }
        this.dropDownHandlers.get(filter).toggleVisibility(event);
        // this.cdrRef.markForCheck();
    }

    public getSelectedOption(filter: ExecutableItem): ExecutableItem {
        const currentDropDownHandler = this.dropDownHandlers.get(filter);
        return currentDropDownHandler && currentDropDownHandler.getSelectedItem() ?
            currentDropDownHandler.getSelectedItem() :
            null
    }

    public getSelectedOptionName(filter: ExecutableItem): string {
        return this.getSelectedOption(filter) ?
            this.getSelectedOption(filter).getName() :
            '';
    }

    public getDropDownHandler(filter: ExecutableItem): NDropDown.DropDownHandler {
        return this.dropDownHandlers.get(filter);
    }

    public buildDropdownHandler(): NDropDown.DropDownHandler {
        const parameter: NDropDown.IDropDownComponentParamater = {
            ...NDropDown.DropDownHandler.getDefaultDropDownParameter(),
            position: NDropDown.EDropDownPosition.Right,
            selectable: true,
            mutuallyExclusive: true,
            clientInstance: this,
        };
        const handler: NDropDown.DropDownHandler = new NDropDown.DropDownHandler(parameter);
        return handler;
    }

//#endregion

//#region Search
    public getSearchHandler(): HandlerSearch {
        return this.searchHandler;
    }

    public buildSearchHandler(): HandlerSearch {
        const seachType: ISearchComponentParamater = {
                        searchTypes: [ESearchAllowedType.groups, ESearchAllowedType.people, ESearchAllowedType.posts, ESearchAllowedType.myStuff, ESearchAllowedType.fromAvatar, ESearchAllowedType.all],
                        clickAction: EParticipantClickTypeMode.GotoTarget,
                        maxSelectionableNumber: clientConstants.maxParticipantsPerGroup,
                        defaultOption: ESearchAllowedType.all,
                        extraCssClasses: ['group-home-component'],
                        showCloseBtn: false,
                        isEditableTypes: true,
                        searchScope: ESearchScope.allSocialNetwork,
                        clientCallback: null,
        };
        return new HandlerSearch(seachType);
    }
//#endregion

//#region Breadcrumb
    newBreadCrumbDescriptor(): IBreadcrumbDescriptor {
        return {
            items: this.getBreadcrumbList(),
            breadCrumbClicked: (item: BreadcrumbItem) => this.routingManager.goToGroup(item.primaryId),
        }
    }

    private resetBreadCrumb(subscriptionSignal: SubscriptionSignal): void {
        const group = subscriptionSignal.getGroup()

        if (!isValidGenealogy(group.getPlainGroupGenealogy())) {
            fixGenealogy(group.getPrimaryID(), group.getPlainGroupGenealogy(), this.socialNetworkSvc.getGroupDB())
        }

        const list: BreadcrumbItemList = [
            ...getBreadCrumbFromGenealogy([groupToGenealogyEntry(group)].concat(group.getPlainGroupGenealogy()))
        ].reverse();

        this.breadCrumbDescriptor = {
            breadCrumbClicked: (item) => {
                this.routerSvc.goToGroup(item.primaryId)
            },
            items: list
        };
    }

    private getBreadcrumbList(): BreadcrumbItemList {
        const transformGroupToBreadcrumbItem = (group: Group) => ({
            primaryId: group.getGroupID(),
            url: `${RoutingBuilder.groupHome(group.getPrimaryID())}`,
            title: group.getName(),
        });
        const currentGroup = this.getCurrentGroup(),
              isFunctionalRoot = currentGroup.getGroupType().isFunctionalRoot(),
              genealogy = currentGroup.getPlainGroupGenealogy();

        if (isFunctionalRoot)
            return [currentGroup].map(transformGroupToBreadcrumbItem);

        const normalizedGenealogy = noRootGenealogy(genealogy)
            .reverse()
            .map(g => Group.staticFactory(g.idGroupParent))
            .concat(currentGroup)
            .map(transformGroupToBreadcrumbItem);

        return normalizedGenealogy
    }
//#endregion

    toggleFiltersBtns(): void { this.showArrowUp = !this.showArrowUp }

    private generateFollowButtonHandler() {
        const parameter: IFollowButtonHandlerParameter = {
            idObjectType: constant.objectType.group,
            primaryID: this.getCurrentGroup().getPrimaryID(),
            canShowChatButton: true,
            group: this.getCurrentGroup(),
        };
        this.followButtonHandler = new FollowButtonHandler(parameter);
    }

    image: SafeUrl = ''
    @Memoize()
    getMMHandler(): MultimediaHandler {
        return new MultimediaHandler({
            idPlayer: null,
            idAvatar: null,
            generateHashNow: true,
            idMultimediaTag: MMconstant.tag.photo,
            mimeTypeFilter: onlyImageAllowed,
            maxNumberOfFiles: 1,
            multimediaService: this.multimediaServices,
            clientCallback: {
                onClearMultimedia: () => nop,
                onMultimediaSelected: (fileSelection: TMultimediaInstanceArray) => {
                    this.cdrRef.markForCheck()
                }
            },
        });
    }

    toggleFilter(): void{
        this.canShowFilter = !this.canShowFilter
    }

    isDev(): boolean {
        return !environment.production
    }

    goToChat(): void {
        if(this.canGoToChat()){
            this.routingManager.goToGroup(
                this.getCurrentGroup().getGroupID(),
                routeID.groups.chat,
            );
        }
    }

    canGoToChat(): boolean {
        const currentGroupType: GroupType = this.getCurrentGroup().getGroupType();
        const currentGroupIsRoot: boolean = currentGroupType.isRoot();
        const currentGroupIsFunctionalRoot: boolean = currentGroupType.isFunctionalRoot();
        const currentGroupisPersonalRoot: boolean = currentGroupType.isPersonalRoot();
        const isNotFunctionalRootOrRootOrPernsonalRoot: boolean = (! currentGroupIsRoot && ! currentGroupIsFunctionalRoot && ! currentGroupisPersonalRoot);

        return isNotFunctionalRootOrRootOrPernsonalRoot;
    }

    private getMoreOptionsHandler(): MoreOptionsThreeDotsHandler {
        const moreOptionsHandler = MoreOptionsThreeDotsHandler.new({
            clientInstance: {
                onOptionSelectedCallback: (option) => {
                    this.grpCardItemSvc.handlerMoreOptionsSelect(option, this.group, this.updateAnnotations.bind(this));
                }
            },
            options: this.getMoreOptions()
        });
        return moreOptionsHandler;
    }

    private getMoreOptions(): TSerializableArray {
        const groupID = this.group.getGroupID();
        const participantRoles = this.session.getPlayerInfo().getGroupAvatarRole(groupID, this.session.getSelectedAvatarID(), true);
        const groupType: GroupType = this.group.getGroupType();

        if (groupType.isPersonalRoot() || groupType.isPersonal()) {
            return [];
        }

        const options = [
            Serializable.staticFactory(EGroupType.SubGroupEdit),
            Serializable.staticFactory(EGroupNavigation.options),
            Serializable.staticFactory(EGroupNavigation.createGroup),
        ].filter((serializable: Serializable) => {
            return isValidArray(participantRoles) && (!serializable.getPrivilegeNeeded() || hasPermissionInRoles(participantRoles, serializable.getPrivilegeNeeded()));
        });
        return options;
    }

    public canShowMoreOptions(): boolean {
        return isValidRef(this.moreOptionsHandler)
            && this.moreOptionsHandler.getMenuOptions().length > 0;
    }

    hasAnnotation(): boolean {
        return this.getCurrentGroup().hasAnnotation();
    }

    openAnnotations(): void {
        this.grpCardItemSvc.openAnnotations(
            this.getCurrentGroup(),
            this.updateAnnotations.bind(this),
        );
    }

    updateAnnotations(): void {
        this.getCurrentGroup().setAnnotation(true);
        this.cdrRef.markForCheck();
    }
}
