import {ITranslationConfig} from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {EProfileItemClick} from "@colmeia/core/src/shared-business-rules/visual-constants";

export interface IProfileItemClick {
    translation: ITranslationConfig;
    option: EProfileItemClick;
}

export const allProfileItems: IProfileItemClick[] = [
    {
        option: EProfileItemClick.profile,
        translation: gTranslations.fragments.myProfile
    },
    {
        option: EProfileItemClick.settings,
        translation: gTranslations.common.settings
    },
    {
        option: EProfileItemClick.about,
        translation: gTranslations.fragments.about
    },
    {
        option: EProfileItemClick.logout,
        translation: gTranslations.fragments.logout
    }
];
