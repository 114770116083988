import { IFileSystemLayer, IMicrophoneHardwareAbstraction, IRecordMicrophoneResult } from '../hardware-interfaces';
import { EMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { throwCustomFieldError } from '@colmeia/core/src/tools/utility';
import {EHardwareResourceStatus} from "./vendor/hardware-status";

declare var WavAudioEncoder: any;
type TBooleanPromiseResolver = (value?: boolean | PromiseLike<boolean>) => void;

import { CordovaPermissionsService} from "./permissions/cordova-permissions.service";
import { clientErrorCodes } from '@colmeia/core/src/error-control/client-error.constants';
import { Injectable } from '@angular/core';
import {
    Window,
    AIC_AUDIOSOURCE_TYPE,
    AIC_CHANNELS,
    AIC_FORMAT,
    AIC_SAMPLERATE,
    AudioInputPlugin,
    CordovaMicrophoneSettings,
    InputCaptureDataEvent
} from './cordova-plugins-definitions';

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class CordovaMicrophone implements IMicrophoneHardwareAbstraction {

    constructor(
        private permissions: CordovaPermissionsService
    ){}

    private receivedData: number = 0;
    private receivedBuffer: Array<any> = [];

    /* Configurations: */
    private static readonly rate: AIC_SAMPLERATE = AIC_SAMPLERATE.CD_AUDIO_44100Hz;
    private static readonly buffer: number = 16384;
    private static readonly channels: AIC_CHANNELS = AIC_CHANNELS.MONO;
    private static readonly format: AIC_FORMAT = AIC_FORMAT.PCM_16BIT;
    private static readonly normalizeFactor: number = 32767.0;
    private static readonly chunks: number = 10;
    private static readonly sourceType: AIC_AUDIOSOURCE_TYPE = AIC_AUDIOSOURCE_TYPE.DEFAULT;
    private static readonly mime: string = EMimeTypes.Wav;

    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .getMicrophoneAuthorizationStatus((status: EHardwareResourceStatus) => {
                   resolve(status);
                }, (error) => {
                    throw new Error(error);
                });
        });
    }

    isAllowed(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .isMicrophoneAuthorized((status: boolean) => {
                    resolve(status);
                }, (error) => {
                    throw new Error(error);
                });
        });
    }

    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            window.cordova
                .plugins
                .diagnostic
                .requestMicrophoneAuthorization((status: EHardwareResourceStatus) => {
                    resolve(status);
                }, (error) => {
                    throw new Error(error);
                });
        });
    }

    async startRecording(): Promise<boolean> {

        const authStatus: EHardwareResourceStatus = await this.getAuthorizationStatus();

        if([EHardwareResourceStatus.GRANTED, EHardwareResourceStatus.AUTHORIZED].indexOf(authStatus) === -1){
            return Promise.resolve(false);
        } else{
            return new Promise<boolean>((resolve, reject) => {
                try {
                    this.handleAudioInputEvent(resolve);
                } catch (e) {
                    this.handleNotPermittedToStartRecording(resolve);
                }
            });
        }
    }

    stopRecording(fs: IFileSystemLayer): Promise<IRecordMicrophoneResult> {
        return new Promise<IRecordMicrophoneResult>((resolve, reject) => {
            try {
                window.audioinput.stop();
                const encoder = new WavAudioEncoder(
                    CordovaMicrophone.rate,
                    CordovaMicrophone.channels
                );
                encoder.encode([this.receivedBuffer]);
                const blob = encoder.finish(CordovaMicrophone.mime);
                this.resetReceivedData();
                resolve({
                    file: fs.newFile([blob], 'recordedAudio', {
                        type: CordovaMicrophone.mime
                    })
                });
            } catch (e) {
                console.log('e o erro é:', e);
                throwCustomFieldError(
                    clientErrorCodes.errorPrimaryID,
                    clientErrorCodes.fields.hardware.microphone.generic, true,
                    'cordovaStartRecord'
                );
            }
        });
    }

    cancelRecording(): void {

        try {
            window.audioinput.stop();
            this.resetReceivedData();
        } catch (e) {
            console.log(e);
        }
    }

    private handleNotPermittedToStartRecording(resolve: TBooleanPromiseResolver): any {
        // ask user for Permission:
        window.audioinput.getMicrophonePermission(
            (authorized, messageAuthorized) => {
                if (authorized) {
                    this.handleAudioInputEvent(resolve);
                } else {
                    throwCustomFieldError(
                        clientErrorCodes.errorPrimaryID,
                        clientErrorCodes.fields.hardware.microphone.notAllowed, true,
                        'cordovaStartRecord'
                    );
                }
            }
        );
    }

    private handleAudioInputEvent = (resolve: TBooleanPromiseResolver) => {
        const config: CordovaMicrophoneSettings = {
            sampleRate: CordovaMicrophone.rate,
            bufferSize: CordovaMicrophone.buffer,
            channels: CordovaMicrophone.channels,
            format: CordovaMicrophone.format,
            normalize: true,
            normalizationFactor: CordovaMicrophone.normalizeFactor,
            streamToWebAudio: false,
            audioContext: null,
            concatenateMaxChunks: CordovaMicrophone.chunks,
            audioSourceType: CordovaMicrophone.sourceType
        };
        window.audioinput.start(config);
        window.addEventListener('audioinput', this.onAudioInput, false);
        resolve(true);
    };

    onAudioInput = (event: InputCaptureDataEvent) => {
        this.receivedData += event.data.length;
        this.receivedBuffer.push(...(<InputCaptureDataEvent>event).data);
    };

    private resetReceivedData(): void {
        this.receivedData = 0;
        this.receivedBuffer = [];
        window.addEventListener('audioinput', () => {});
    }
}
