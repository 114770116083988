import { isValidRef, typedClone, values } from "../../../../tools/utility";
import { IBotActionAsset, initialAction } from "../../../bot/bot-action-model";
import { ENextGenBotElementType, IBotMenuContainerServer, IBotMenuItemServer, IBotRootServer } from "../../../bot/bot-model";
import { EBotActionType } from "../../../bot/new-bot-action";
import { EBPMType } from "../../../BPM/bpm-model";
import { GraphElement } from "../../../graph/essential/graph-element";
import { IGraphConnectionData, TIGraphConnectionDataArray } from "../../../graph/essential/graph-types";
import { INonSerializable } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { BPMConfigToolbarSelector } from "../../toolbar/bpm-config-toolbar-selector";
import { IBasicToolbarElement, TReferencedObject } from "../../toolbar/config-toolbar.types";
import { NSGenericHost } from "../ns/ns.host";
import { IAddedConnection, IBOTHostFactoryInfo, IHostNodeDeletionParams, IHostNodeDeletionResult } from "../ns/ns.host.types";
import { BotHostNode } from "./bot.host";



export class MenuContainerHostNode extends BotHostNode {
    constructor(ns: IBotMenuContainerServer) {
        super(ns);
    }

    public getNonSerializable(): IBotMenuContainerServer {
        return super.getNonSerializable() as IBotMenuContainerServer;
    }

    public handleRemoveSubTree(element: GraphElement): INonSerializable[] {
        const allGraphElements = values(element.getRuleProcessor().getAllGraphElements()) as GraphElement[];
        const toSave: INonSerializable[] = [];

        allGraphElements.filter(graphElement => {
            const hostObjectType = graphElement.getHostedType();
            return (
                hostObjectType === ENextGenBotElementType.botMenuItem ||
                hostObjectType === ENextGenBotElementType.root
            );
        }).forEach(graphElement => {

            type workNS = IBotMenuItemServer | IBotRootServer;

            const hostObjectType = graphElement.getHostedType();
            const hostObjectNS: workNS = graphElement.getHostObject().getNonSerializable() as workNS;

            const action: IBotActionAsset = (
                hostObjectType === ENextGenBotElementType.root
                    ? (hostObjectNS as IBotRootServer).firstAction
                    : (hostObjectNS as IBotMenuItemServer).action
            );

            const hasActionConfigureToThisElement = action.type === EBotActionType.goActionTree && action.idElement === element.getHostedID();

            if (!hasActionConfigureToThisElement) return;

            switch (hostObjectType) {
                case ENextGenBotElementType.root:
                    (hostObjectNS as IBotRootServer).firstAction = typedClone(initialAction);
                    break;
                case ENextGenBotElementType.botMenuItem:
                    (hostObjectNS as IBotMenuItemServer).action = typedClone(initialAction);
                    break;
            }

            graphElement.changeDetection();

            toSave.push(hostObjectNS);
        });
        return toSave;
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        const toolbarElement: IBasicToolbarElement<TReferencedObject> = BPMConfigToolbarSelector.getOneToolbarElement(EBPMType.bot, this.getElementType())
        const dragToolbarElement: IBasicToolbarElement<TReferencedObject> = BPMConfigToolbarSelector.getOneToolbarElement(EBPMType.bot, nodeTypeToDrag)

        const isConnectable = isValidRef(toolbarElement.canConnectTo[nodeTypeToDrag]);
        const canDragOn = dragToolbarElement.canDragOn?.includes(this.getElementType());

        return canDragOn || isConnectable
    }

    public static create(info: IBOTHostFactoryInfo): MenuContainerHostNode {
        const instance = new MenuContainerHostNode(info.ns as IBotMenuContainerServer);
        return instance
    }

    public getSequencialPosition() {
        return undefined;
    }

    public getHostedType(): TReferencedObject {
        return ENextGenBotElementType.menuContainer;
    }

    public getActionContainer() {
        return [{ action: undefined }];
    }

    public isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return false
    }

    public isParenthoodPredicateDrawable(target: TReferencedObject): boolean {
        return target === ENextGenBotElementType.botMenuItem;
    }

    setAction(action: IBotActionAsset) { }

    public addConnectionTo(_target: NSGenericHost, _connectionType: TReferencedObject): IAddedConnection {
        return { isConnectionAdded: true }
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        return [{
            connectionType: null,
            targetHostId: null,
            subElementId: null
        }]
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        return false
    }

    canRemoveNode(data: IHostNodeDeletionParams): IHostNodeDeletionResult {
        const allowed = data.isExternalElement
            ? data.numOfConnectionsFromNeighboursToMe == 0
            : true

        return {
            allowed,
            explanationIfNotAllowed: 'menus-externos nao podem ser deletados, favor deletar o elemento pai'
        }
    }

}