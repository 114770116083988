import { Injectable } from '@angular/core';
import { ICRMTicketDataWithCustomerName, TTicketInteraction } from '@colmeia/core/src/shared-business-rules/crm/crm-entities';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { RoutingService } from 'app/services/routing.service';

export interface ITicketNoficiationInteractionStreamData {
    ticketData: ICRMTicketDataWithCustomerName;
    interaction: TTicketInteraction;
}

@Injectable({
    providedIn: 'root'
})
export class CRMTicketRouterService {


    constructor(
        private routeSvc: RoutingService,
    ) {

    }

    public getTicketViewPath(ticket: ICRMTicketDataWithCustomerName): string {
        const { path, protocolParam, idTicketParams } = routeList.dashboard.children.serviceStatus.children.tickets.children.view

        return path
            .replace(protocolParam, `${ticket.projectKey}-${ticket.protocol}`)
            .replace(idTicketParams, ticket.idTicket);
    }

    public navigateToTicket(ticket: ICRMTicketDataWithCustomerName) {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.tickets.path,
            this.getTicketViewPath(ticket),
        );
    }

    public getTicketFullPath(ticket: ICRMTicketDataWithCustomerName): string {
        return this.routeSvc.mountFullRoute(routeID.dashboard, [
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.tickets.path,
            this.getTicketViewPath(ticket),
        ])
    }

}
