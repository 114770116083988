<div *ngIf="parameters.isWindowMode;" class="move-window-trigger dashbroad-move-window-trigger">
</div>

<section class="dashboard-page" [class.window-mode]="parameters.isWindowMode">
  <header *ngIf="!headerless" class="header" [ngClass]="
      handler?.parameter?.classes?.header
        ? handler.parameter?.classes.header
        : ''
    ">
    <div class="top-header">
      <ng-content select="[top-header]"></ng-content>
      <div class="window-control-container" *ngIf="!removeGoBackButton">
        <button matRipple (click)="$event.stopPropagation(); goBack()" class="window-button">
          <mat-icon>{{ backButtonIcon }}</mat-icon>
        </button>

        <button *ngFor="let button of parameters.windowButtons" matRipple
          (click)="$event.stopPropagation(); button.onClick($event)" class="window-button" [matTooltip]="button.text">
          <mat-icon>{{ getCustomButtonIcon(button) }}</mat-icon>
        </button>
      </div>

      <div class="top-center-container" *ngIf="parameters.allowEditTitleInHeader && nsTypeName">
        <h3 class="title">
          {{ parameters.replaceEditTitle || nsTypeName }}
          <div *ngIf="helpTipKey" class="helper-info" [helperInfo]="helpTipKey" [hiPopover]="true"
            hiHorizontalAlign="left" hiIconSize="16"></div>
        </h3>
      </div>
      <div class="top-header-actions">
        <app-non-serializable-taggable-editor *ngIf="hasTaggable" class="tagEditor"
          [class.window-mode]="parameters.isWindowMode" [taggableHandler]="taggableHandler">
        </app-non-serializable-taggable-editor>
        <ng-content select="[top-header-actions]"></ng-content>
      </div>
    </div>

    <div class="box-title" *ngIf="hasBox">
      <div class="text-n-options">
        <div class="more-options" *ngIf="hasMoreOptions">
          <cm-ns-more-options *ngIf="nsMoreOptionsHandler" [handler]="nsMoreOptionsHandler"></cm-ns-more-options>
        </div>
        <div *ngIf='!this.parameters.removeHexagon' class='box-img'>
          <app-hexagon-uploader *ngIf="botElementHexagonHandler" [handler]="botElementHexagonHandler">
          </app-hexagon-uploader>
        </div>
        <h3 *ngIf="hasTitle" class="title"> {{ parameters.title }} </h3>
        <div *ngIf="parameters.allowEditTitleInHeader" #inputNameContainer class="input-name-container"
          [class.has-change]="hasTitleChange()" (click)="titleNameInput.focus()">
          <mat-icon class="edit-indicator">create</mat-icon>
          <input #titleNameInput cdkFocusInitial [(ngModel)]="parameters.nser.nName"
            (focus)="inputNameContainer.classList.add('focused')"
            (blur)="inputNameContainer.classList.remove('focused')" (keyup.esc)="hasTitleChange() && titleNameReset()"
            placeholder="Nome" />
          <button mat-button class="cancel-button" (click)="titleNameReset()" [disabled]="!hasTitleChange()"
            [class.visible]="hasTitleChange()">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>

      <app-alert class="gde-alert" *ngIf="parameters.alert" [type]="parameters.alert.type"
        [action]="parameters.alert.action" (onAction)="this.parameters.alert.actionCallback()">
        <small>{{parameters.alert.message}}</small>
      </app-alert>

      <div class="buttons">
        <ng-container *ngFor="let button of buttons">
          <icon-button *ngIf="!button.isHidden?.()" class="button__item" [text]="button.text" [color]="button.color"
            [icon]="getCustomButtonIcon(button)" (click)="button.onClick()" [matTooltip]="button.tooltip"></icon-button>
        </ng-container>

        <button *ngIf="parameters.hasDeleteButton" mat-button (click)="remove()">
          {{ translations.remove }}
        </button>
        <div class="button-container">
          <button *ngIf="hasSaveButton" mat-flat-button color="accent" class="save-button"
            [class.has-change]="hasChange()" [matTooltip]="hasChange() ? 'Alteração detectada' : null" [loading]="saving"
            [disabled]="blockSave || saving || preventSave" (click)="emitSave(saveButtonAlert)">
            {{ customSaveButton.text }}
          </button>
          <button-alert #saveButtonAlert></button-alert>
        </div>
      </div>
    </div>
  </header>

  <!-- <ng-content></ng-content> -->
  <ng-container>
    <div [class.window-content]="parameters.isWindowMode" [class.minified]="handler.isContentHidden">
      <ng-content></ng-content>
    </div>
  </ng-container>
</section>

<ng-template #dialogErrors>
  <cm-non-serializable-errors-display [errors]="validationErrors">
  </cm-non-serializable-errors-display>
</ng-template>
