import { Component, OnInit, Input } from "@angular/core";
import { Location } from "@angular/common";
import { isValidFunction, isValidRef } from "@colmeia/core/src/tools/utility";

@Component({
    selector: "app-go-back",
    templateUrl: "./go-back.component.html",
    styleUrls: ["./go-back.component.scss"],
})
export class GoBackComponent implements OnInit {

    @Input()
    enableOnHoverEffect?: true;
    @Input()
    callBack?: () => void;
    @Input()
    executeCallbackOnly: boolean = false;

    public constructor(private location: Location) { }

    ngOnInit() { }

    public goBack() {
        if (isValidFunction(this.callBack)) {
            this.callBack();
            if (this.executeCallbackOnly) return;
        }
        this.location.back();
    }

    get enableEffect() {
        return isValidRef(this.enableOnHoverEffect);
    }
}
