import { NSGenericHost } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ENextGenBotElementType, INextGenBotServer } from '../../../bot/bot-model';
import { ESubscribedTo, IBPMBaseNodeServer } from '../../../BPM/common/common-bpm';
import { IBasicBPMMarketingModelClient, IBPMMarketingAcknowledgeServer, TBpmMarketingNserServer, TBpmMarketingNsTypes } from '../../../BPM/marketing/bpm-marketing.model';
import { IGraphConnectionData } from '../../../graph/essential/graph-types';
import { TGraphActionContainer, TReferencedObject } from '../../toolbar/config-toolbar.types';
import { INodeDeletedConnection } from '../ns/ns.host.types';


export class NestedAItHostNode extends NSGenericHost { // Generic host
    constructor(nestedAINser: INonSerializable) {
        super(nestedAINser);
    }

    static create(nestedAINser: INonSerializable): NestedAItHostNode {
        return new NestedAItHostNode(nestedAINser)
    }

    isNodeCreatable(): boolean {
        const ns = super.getNonSerializable();
        const botLevel = (ns as INextGenBotServer).botLevel;

        return ns.nsType === ENonSerializableObjectType.contentGenerator
            || botLevel === ENextGenBotElementType.menuContainer
    }

    public getHostedType(): TReferencedObject {
        throw new Error('Method not implemented.');
    }

    public isRoot(): boolean {
        return false;
    }
}