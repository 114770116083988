import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { visibleComponentAnimations } from './visible.animations';
import { VisibleComponentBase } from './visible.base';


@Component({
    selector: '[visible]',
    template: '<ng-content></ng-content>',
    // exportAs: "visible",
    encapsulation: ViewEncapsulation.None,
    animations: [
        visibleComponentAnimations.contentExpansionBoth,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[@contentExpansionBoth]': '_getExpandedState()',
        '(@contentExpansionBoth.start)': 'onAnimationStart($event)',
        '(@contentExpansionBoth.done)': 'onAnimationDone($event)'
    }
})
export class VisibleComponent extends VisibleComponentBase {

    @Input('visible')
    set _visible(value: boolean) {
        this.visible = coerceBooleanProperty(value);
    }

    constructor(
        _changeDetectorRef: ChangeDetectorRef,
        readonly elementRef: ElementRef,
        protected _expansionDispatcher: UniqueSelectionDispatcher,
    ) {
        super(
            _changeDetectorRef,
            elementRef,
            _expansionDispatcher,
        );
    }
}
