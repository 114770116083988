import { Injectable } from '@angular/core';
import { UserDefaultSettings } from '@colmeia/core/src/business/user-default-settings';
import { getDateFormatMask, TDateMaskElement } from '@colmeia/core/src/time/time-utl';
import {SignalPublisherService} from "./signal/signal-publisher";
import {InterfaceInfoSignal} from "../model/signal/interface-signal";
import {DatetimeConversions} from "./datetime-conversions";

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {

    constructor(private emissor: SignalPublisherService) {}

    setLanguage(locale: string) {
        const profile: UserDefaultSettings = UserDefaultSettings.getCurrentProfile();
        profile.setDefaultLanguage(locale);
        const sig: InterfaceInfoSignal = new InterfaceInfoSignal({
            changeLanguage: true
        });
        this.emissor.specificSignalEmissorOnGenericStream(sig);
    }

    getLanguage(): string {
        return UserDefaultSettings.getDefaultLanguageID();
    }

    getSelectedLocale(): string {
        const profile: UserDefaultSettings = UserDefaultSettings.getCurrentProfile();
        return profile.getAngularLocale();
    }

    getDateFormat(): string {
        return UserDefaultSettings.getCurrentProfile().getDateFormat()
    }

    getDatePattern(): RegExp {
        return UserDefaultSettings.getCurrentProfile().getDatePattern()
    }

    getDatetimeConversor(): DatetimeConversions {
        return new DatetimeConversions(this);
    }

    getDateFormatMask(): TDateMaskElement {
        return getDateFormatMask(this.getDateFormat());
    };

    getTwentyFourTimeFormat(): string {
        return 'HH:mm';
    }

    getTwentyFourTimeFormatWithSeconds(): string {
        return UserDefaultSettings.getCurrentProfile().getHourFormat();
    }

    getTwelveTimeFormat(): string {
        return `hh:mm`;
    }

    getDateHourFormat(): string {
        return 'dd/MM/yy HH:mm';
    }

    getDateHourSecondsFormat(): string {
        return 'dd/MM/yy HH:mm:ss';
    }

    public getNoWindowFocusSound(): Array<string> {
        return UserDefaultSettings.getCurrentProfile().getNoWindowFocusSound();
    }

    public getNoGroupFocusSound(): Array<string> {
        return UserDefaultSettings.getCurrentProfile().getNoGroupFocusSound();
    }

    getNotificationSound(isFocused: boolean): string {
        return UserDefaultSettings.getDefaultNotificationSound(isFocused);
    }

    public getDialogNotificationSound(): Array<string> {
        return UserDefaultSettings.getDialogNotificationSound()
    }
}
