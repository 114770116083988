import { TNserUID } from '@colmeia/core/src/core-constants/types';
import { TGlobalUID } from '../business/constant';
import { TBasicUniveralInfoArray } from '../comm-interfaces/aux-interfaces';
import { IFileRowLine } from '../shared-business-rules/files/files';
import { EGroupContainer } from '../shared-business-rules/group-container/group-container-config';
import { INonSerializable } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { GenericRequest, GenericResponse, IControlNavigation, IRequest } from './request-interfaces';
import { IResponse, IResponseCursor } from './response-interfaces';

export interface IGetLookupResponse extends IResponseCursor {
    lookups: TBasicUniveralInfoArray;
}

export interface ISaveGenericNSResponse extends IResponse {
    nserList: INonSerializable[]
    createdNserIDs?: TNserUID[]
}

export interface IContainerStatus {
    [idGroup: string]: EGroupContainer
};

export interface IGetGroupContainerLookupResponse extends IGetLookupResponse {
    currentStatus: IContainerStatus
};


export enum ELookupType {
    openInviteCampaigns = 'openCamp',
    goActionTree = 'goActionTree',
    goHumanActionTree = 'goHumanActionTree',
    getBots = 'getBots',
    getBotConversation = 'getBotConversation',
    getAllAvatarFromSN = 'getAllAvatarFromSN',
    getAllGroupFromAvatar = 'getGrpAvatar',
    getContainerGroups = 'getContainerGroup',
    getUsedFeedbackAndInteraction = 'getUsedFI',
    getServGroupsFromServPack = 'getGroupsServPack',
    nonSerializableGeneric = 'nsGeneric',
    searchInFile = 'searchInFile',
    testDocTransformer = 'testDocTransformer'
};

export interface ISearchInRawFile extends IControlNavigation {
    idFile: string;
    content: string;
}

export interface ISearchInRawFileOutput extends IControlNavigation {
    lines: IFileRowLine[];
}

export interface ISearchInFileRequest extends GenericRequest<ELookupType.searchInFile>, ISearchInRawFile {

};

export interface ISearchInFileResponse extends GenericResponse<ELookupType.searchInFile>, ISearchInRawFileOutput {

};

export interface IGetLookupInformationRequest extends IRequest {
    cursor: string;
    lookupType: ELookupType;
    limit?: number;
};

export interface IGetLookupAvatarGroupsRequest extends IGetLookupInformationRequest {
    idAvatarToBeSearched: TGlobalUID;
};



export interface ILookupBasicInfo {
    primaryID: TGlobalUID;
    clockTick: number;
    name: string;
    idMedia: TGlobalUID;
};

