import { generalErrors } from "@colmeia/core/src/shared-business-rules/const-text/errors/general-errors";
import { FriendlyMessage } from "../../../error-control/friendly-message";
import { EnumObject, EnumValue, ETypeOf, fakeAssertsGuard, getProperty, isInEnum, isInvalid, isValidObject, isValidRef, isValidSize, isValidString, isValidStringAndSize, keys, listFormat, typeOf } from "../../../tools/utility";
import { $ValueOf, Assert, checkEarlyReturnSwitch, Compute, Differences, FromSimpleTypeOfName, GetByKey, IObjectLiteralMetadata, IsEmpty, IsEqual, ObjectLiteralMetadata, ToTypeOf, ValueOf } from "../../../tools/utility-types";
import { Explicit, IntersectPartialExplicit, PartialExplicit } from "../../../tools/utility/types";
import { Define } from "../../../tools/utility/types/entities/define";
import { $$ } from "../../../tools/utility/types/error";
import { ITranslationConfig, TTranslationOrMessage } from "../../translation/translation-engine";
import { INonSerializable, INonSerializableHeader } from "../non-serializable-id-interfaces";

export const entityNameMinLength = 4;
export const nserNameMinLength = entityNameMinLength;
export const minLength = (name: string, length: number) => isValidRef(name) && name.length >= length;


export function isValidEnum<E extends Object, V extends E>(enumerable: Object, value: V): boolean {
    return Object.values(enumerable).includes(value);
}

export const nserValidationNOOP = (nser: INonSerializableHeader, friendly: FriendlyMessage) => friendly;




interface ILiteralJoi<Type extends TAssertNserFieldType = TAssertNserFieldType> {
    type?: Type
}

interface ILiteralJoiNumber extends ILiteralJoi, ILiteralJoiWithRange {
    type?: ETypeOf.Number;
}

export interface ILiteralJoiWithRange {
    min?: number;
    max?: number;
}

interface ILiteralJoiString extends ILiteralJoi, ILiteralJoiWithRange {
    type?: ETypeOf.String;

    regex?: RegExp;
}


interface ILiteralJoiArray extends ILiteralJoi, ILiteralJoiWithRange {
    type?: ETypeOf.Array;
}


interface ILiteralJoiEnum<Entity extends EnumObject = EnumObject> extends ILiteralJoi {
    type?: EAdditionalTypeOf.Enum;
    entity: Entity;
}


export enum EAdditionalTypeOf {
    Enum = 'enum',
}

type TAssertNserFieldType = ETypeOf | EAdditionalTypeOf;

export interface IAssertNserField {
    type: TAssertNserFieldType;
    isRequired: boolean;
    custom?: (source: ICustomAssert) => void;
    literalJoi?: ILiteralJoi;
    message?: TTranslationOrMessage;
}

type TDefaultMapAssertEntityField<Type extends IAssertNserField['type']> = IntersectPartialExplicit<IAssertNserField, { type: Type; literalJoi?: IntersectPartialExplicit<ILiteralJoi, { type?: Type }> }>;;

export type TMapAllAssertEntityField = Explicit<{ [key in IAssertNserField['type']]?: IntersectPartialExplicit<IAssertNserField, { type: key; literalJoi?: ILiteralJoi | IntersectPartialExplicit<ILiteralJoi, { type?: key }> }> }, {
    [ETypeOf.String]: IntersectPartialExplicit<IAssertNserField, {
        type: ETypeOf.String;
        literalJoi?: ILiteralJoiString;
    }>;
    [ETypeOf.Number]: IntersectPartialExplicit<IAssertNserField, {
        type: ETypeOf.Number;
        literalJoi?: ILiteralJoiNumber;
    }>;
    [ETypeOf.Array]: IntersectPartialExplicit<IAssertNserField, {
        type: ETypeOf.Array;
        literalJoi?: ILiteralJoiArray;
    }>;
    [EAdditionalTypeOf.Enum]: IntersectPartialExplicit<IAssertNserField, {
        type: EAdditionalTypeOf.Enum;
        literalJoi: ILiteralJoiEnum;
    }>;
    [ETypeOf.Object]: TDefaultMapAssertEntityField<ETypeOf.Object>;
}>

type TAssertNserField = $ValueOf<TMapAllAssertEntityField>


interface ICustomAssert {
    entity: object;
    value: any;
    key: string;
    friendlyMessage: FriendlyMessage;
    addError(input?: IAddError): void;
}

export type GetLiteralJoiType<LiteralType extends TAssertNserFieldType> =
    LiteralType extends keyof TMapAllAssertEntityField ?
    TMapAllAssertEntityField[LiteralType]['literalJoi'] extends infer LiteralJoi ?
    IsEmpty<LiteralJoi> extends false ? LiteralJoi : ILiteralJoi
    : never
    : ILiteralJoi
;



export type ToAssertTypeOf<T> = (T extends EnumValue ? $$.CheckIfIsEnumValue<T, true> : false) extends true ? EAdditionalTypeOf.Enum : ToTypeOf<T>;

type _AssertNser<T extends object, MapMetadata extends { [key in keyof T]: IObjectLiteralMetadata } = Required<ObjectLiteralMetadata<T>>> =
    Assert<Required<{
        [key in keyof T]-?:
        [GetByKey<MapMetadata[key]['value'], key>] extends [infer Value] ?
        [ToAssertTypeOf<Value>] extends [infer LiteralType] ?
        LiteralType extends TAssertNserFieldType ?
        [key] extends [string] ?
        [key] extends [keyof T] ?
        Define<IAssertNserField,
            & {
                custom?: (source: Explicit<ICustomAssert, { entity: T, value: NonNullable<T[key]>, key: key; friendlyMessage: FriendlyMessage; addError: ICustomAssert['addError'] }>) => void;
                type: LiteralType;
                isRequired: MapMetadata[key]['isOptional'] extends true ? false : true;
                literalJoi?:
                    LiteralType extends keyof TMapAllAssertEntityField ?
                    & (GetLiteralJoiType<LiteralType>)
                    & (
                        LiteralType extends EAdditionalTypeOf.Enum ?
                            PartialExplicit<ILiteralJoiEnum, { entity: { [key in string]: Assert<Value, EnumValue> } }>
                        : unknown
                    ) : ILiteralJoi
                ;
                message?: TTranslationOrMessage;
            }
            & (LiteralType extends EAdditionalTypeOf.Enum ? Pick<TMapAllAssertEntityField[LiteralType], 'literalJoi'> : unknown)
        >
        : never
        : never
        : never
        : never
        : never
    }>, { [key in keyof T]-?: {} }>
;

export type AssertNser<T extends E, E extends object> = _AssertNser<Differences<E, T>>;
export type AssertEntity<T extends object> = _AssertNser<T>;

export function validateEntity<T extends E, E extends object = object, Diff extends Partial<T> = IsEqual<E, object> extends true ? T : Differences<E, T>>(assert: AssertEntity<Diff>) {
    return (entity: T): string[] => {
        const friendly: FriendlyMessage = new FriendlyMessage('validateEntity', true);
        assertEntity(assert, friendly)(entity);
        return friendly.getErrorsAsString();
    }
}

function $toTypeOf(type: TAssertNserFieldType): ETypeOf {
    if (type === EAdditionalTypeOf.Enum) return ETypeOf.String
    return type;
}

interface IAddError {
    translation?: ITranslationConfig;
    message?: string;
    isHidingField?: boolean;
}

export function assertEntity<T extends E, E extends object = object, Diff extends Partial<T> = IsEqual<E, object> extends true ? T : Differences<E, T>>(assert: AssertEntity<Diff>, friendly: FriendlyMessage) {
    return (entity: T): void => {
        keys(assert).map((key: Assert<keyof T, string>) => {
            const configAssert: IAssertNserField = getProperty(assert)(key);
            const value = getProperty(entity)(key);

            if (configAssert.isRequired && isInvalid(value)) {
                if (isInvalid(value)) {
                    addError({
                        translation: generalErrors.missingField
                    });
                }
            }

            if (isInvalid(value)) return;

            if (typeOf(value) !== $toTypeOf(configAssert.type)) {
                addError();
            }

            if (isValidObject(configAssert.literalJoi)) {
                validateLiteralJoi();
            }

            configAssert?.custom?.({
                entity,
                value,
                key,
                friendlyMessage: friendly,
                addError,
            });




            function validateLiteralJoi() {
                const config = configAssert as TAssertNserField;

                type TypesWithLiterals = $ValueOf<{
                    [key in keyof TMapAllAssertEntityField]:
                        IsEqual<TMapAllAssertEntityField[key]['literalJoi'], ILiteralJoi & ILiteralJoi<key>> extends false ? key : never
                }>;

                fakeAssertsGuard<TypesWithLiterals>(config.type)

                function getRangeError(suffix?: string): string {
                    const literalJoi = config.literalJoi as ILiteralJoiWithRange;
                    return listFormat([isValidStringOrNumberConcat(literalJoi.min, { prefix: 'no mínimo ' }), isValidStringOrNumberConcat(literalJoi.max, { prefix: 'no máximo ' })].filter(item => isValidString(item)).map(item => isValidStringOrNumberConcat(item, { suffix: suffix ? ` ${suffix}` : '' })), 'pt');
                }


                switch (config.type) {
                    case ETypeOf.String: {
                        if (isValidRef(config.literalJoi.min) && !isValidStringAndSize(value as unknown as string, config.literalJoi.min, config.literalJoi.max)) {
                            addError({
                                message: `Texto deve ter ${getRangeError('caracteres')}`
                            })
                        }
                        return;
                    };


                    case ETypeOf.Array: {
                        fakeAssertsGuard<unknown[]>(value);

                        if (!isValidSize(value.length, config.literalJoi.min, config.literalJoi.max)) addError({
                            message: `Lista deve ter ${getRangeError('itens')}`,
                        });

                        return;
                    };

                    case ETypeOf.Number: {
                        fakeAssertsGuard<number>(value);

                        if (!isValidSize(value, config.literalJoi.min, config.literalJoi.max)) addError({
                            message: `Número deve estar entre ${getRangeError('de tamanho')}`,
                        });

                        return;
                    };

                    case EAdditionalTypeOf.Enum: {
                        fakeAssertsGuard<EnumValue>(value)

                        if (!isInEnum(config.literalJoi.entity, value)) {
                            addError({
                                message: `Valor não permitido`,
                            })
                        }
                        return;
                    };
                }

                checkEarlyReturnSwitch()(config)
            }
            function addError(input: IAddError = {}): void {
                if (configAssert.message) {
                    input = typeof configAssert.message === 'string'
                        ? ({ message: configAssert.message })
                        : ({ translation: configAssert.message, isHidingField: true })
                    ;
                }

                const { translation, message }: IAddError = sanitize(input);

                friendly.addValidationError(
                    translation,
                    message
                );

                function sanitize({ translation, message, isHidingField }: IAddError) {
                    translation ??= generalErrors.invalidField;
                    message = message ? (translation === generalErrors.invalidField) ? `${key} - ${message}` : message : isHidingField ? '' : key

                    return {
                        translation,
                        message,
                    }
                }
            }
        })
    }

}


function isValidStringOrNumberConcat(value: string | number, { prefix, suffix }: { prefix?: string, suffix?: string }, defaultValue = '') {
    return isValidRef(value) ? `${prefix ?? ''}${value}${suffix ?? ''}` : defaultValue
}


export function literalTypeGuard<LiteralType extends ETypeOf, TAssertEntity extends IAssertNserField>(type: LiteralType) {
    const guardAssertEntityLiteralJoi = (assertEntity: IAssertNserField): boolean => { return true; };
    const guardValueType = (value: any): boolean => { return true; };
    return {
        type,
        guardAssertEntityLiteralJoi,
        guardValueType,
    } as Compute<ValueOf<{ [key in LiteralType]: { type: key; guardAssertEntityLiteralJoi: GuardAssertEntityLiteralJoi<key>; guardValueType: GuardValueType<key> } }>>

    type GuardAssertEntityLiteralJoi<LiteralType extends ETypeOf> = (assertEntity: IAssertNserField) => assertEntity is IntersectPartialExplicit<IAssertNserField, { literalJoi: GetLiteralJoiType<LiteralType> }>
    type GuardValueType<LiteralType extends ETypeOf> = (value: any) => value is FromSimpleTypeOfName<LiteralType>

}

// export function assertNser<T extends INonSerializable, TAssert extends AssertNser<T, INonSerializable>, Assertion extends TAssert>(nser: T, friendly: FriendlyMessage, assert: TAssert): void
export function assertNser<T extends INonSerializable, TAssert extends AssertNser<T, INonSerializable>, Assertion>(nser: T, friendly: FriendlyMessage, assert:
        Assertion
        // & $Narrow<Assertion, TAssert>
        // & AfterNarrow<TAssert>
        // & AfterNarrow<Assertion extends unknown ?
        //     {
        //         [key in keyof Assertion]:
        //             Assertion[key] extends infer Value ?
        //             Value extends TMapAllAssertEntityField[EAdditionalTypeOf.Enum] ?
        //             // @ts-expect-error
        //             { literalJoi: { entity: $$.CheckIfHasAllEnumValues<Value['literalJoi']['entity'], GetByKey<T, key>> } }
        //             : unknown
        //             : never
        //     }
        // : unknown>
    ) {
    // @ts-ignore
    return assertEntity<T, INonSerializable>(assert as unknown as TAssert, friendly)(nser);
}


