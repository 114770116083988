import { Component, Input, OnInit } from "@angular/core";
import { MultimediaInstance, TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/barrel-multimedia";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import {ConstantService} from "../../../services/constant.service";

@Component({
    selector: "app-group-card-item-carousel",
    templateUrl: "./group-card-item-carousel.component.html",
    styleUrls: ["./group-card-item-carousel.component.scss"]
})
export class GroupCardItemCarouselComponent implements OnInit {
    @Input() multimediaList: TMultimediaInstanceArray;

    constructor(private constantSvc: ConstantService) {}

    ngOnInit() {}

    canShowPhoto(mmInstance: MultimediaInstance): boolean {
        return isValidRef(this.getPhoto(mmInstance));
    }

    getPhoto(mmInstance: MultimediaInstance): string {
        const bannerUri: string = mmInstance.getIdMedia();

        if (!isValidRef(bannerUri)) {
            return null;
        }

        const finalUrl: string = `${this.constantSvc.getFileUrl()}${bannerUri}`;
        return finalUrl;
    }

    getMedia(media) {
        return media;
    }

    canShowCarousel(): boolean {
        return this.multimediaList.length > 0;
    }

    canShowDots(): boolean {
        return this.multimediaList.length > 1;
    }
}
