<ng-container *ngIf="canDisplayFormGroup; else noFormGroup">
  <div [formGroup]="fGroup" [hidden]="!canDisplayField">
    <mat-form-field appearance="outline" class="field" *ngIf="isEdit; else viewMode">
      <mat-label>{{ field.prompt }}</mat-label>
      <mat-select [disabled]="isDisabled" [formControlName]="field.idProperty" [(ngModel)]="field.value" [required]="isRequired">
        <mat-option [value]="undefined">---</mat-option>
        <mat-option *ngFor="let item of field.choices" [value]="item.text">{{ item.text }}</mat-option>
      </mat-select>

      <mat-icon matSuffix>{{ statusIcon }}</mat-icon>
      <mat-error>
        {{ getErrorMsg() }}
      </mat-error>

    </mat-form-field>
  </div>
</ng-container>
<ng-template #noFormGroup>
  <div [hidden]="!canDisplayField">
    <mat-form-field appearance="outline" class="field" *ngIf="isEdit; else viewMode">
      <mat-label>{{ field.prompt }}</mat-label>
      <mat-select [disabled]="isDisabled" [(ngModel)]="field.value" [required]="isRequired">
        <mat-option [value]="undefined">---</mat-option>
        <mat-option *ngFor="let item of field.choices" [value]="item.text">{{ item.text }}</mat-option>
      </mat-select>

    </mat-form-field>
  </div>
</ng-template>

<ng-template #viewMode>
  <mat-form-field appearance="outline" class="field">
    <mat-label>{{ field.prompt }}</mat-label>
    <input type="text" matInput readonly [value]="viewModeValue">
  </mat-form-field>
</ng-template>
