import { Injectable } from "@angular/core";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { IDelDependency } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { apiRequestType, ApiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { EDependencyMode, ICheckDeleteNSRequest, ICheckDeleteNSResponse, ICheckDeletion, ICheckDependenciesNSRequest, ICheckDependenciesNSResponse, IDeleteBatchNSRequest, IDeleteNSRequest } from "@colmeia/core/src/shared-business-rules/delete-core/delete-interfaces";
import { ENonSerializableObjectType, INonSerializable, INSDependencyDescription } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { isValidString } from "@colmeia/core/src/tools/utility";
import { SnackMessageService } from "app/services/snack-bar";
import { flattenDeep } from "lodash";
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { EInteractiveButton, GlobalWarningService, INTERACTIVE_NO, INTERACTIVE_YES_WARN } from "./global-warning.service";
import { NsDependenciesService } from "./ns-dependencies.service";
import { RequestBuilderServices } from "./request-builder.services";
import { ServerCommunicationService } from "./server-communication.service";

export enum ENserDeleteResult {
    Aborted = 'aborted',
    Success = 'success',
    Failed = 'failed',
    Async = 'async',
}

@Injectable({
    providedIn: 'root'
})
export class NsDeleterService {

    constructor(
        private rbs: RequestBuilderServices,
        private api: ServerCommunicationService,
        private warning: GlobalWarningService,
        private dependencies: NsDependenciesService,
        private snackMessageSvc: SnackMessageService,
    ) { }

    private generateInfraParameters(): IInfraParameters {
        return this.rbs.getContextNoCallBackSpinnningParameters();
    }

    public async getDependenciesID(nsType: ENonSerializableObjectType, idNS: string, child: boolean = false): Promise<TArrayID> {

        const checkDeletion: ICheckDeletion = {
            deletingID: idNS,
            justCheckIFCanDelete: false,
            checkChildren: child
        };

        const req: ICheckDeleteNSRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.checkDelete),
            ...checkDeletion
        };


        const infra = this.generateInfraParameters();
        const infraRes: ClientInfraResponse = await this.api.managedRequest(infra, req);
        const response: ICheckDeleteNSResponse = <ICheckDeleteNSResponse>infraRes.response;

        const nestedIds: string[][][] = response
            .deletable
            .friendlyDeleteMessage
            .map(deleteMessage => deleteMessage.dependencies)
            .map(
                (delDependency: IDelDependency[]) =>
                    delDependency.map(dependency => dependency.allDependencies)
            )
            ;

        const ids: string[] = flattenDeep(nestedIds);

        return ids;
    }

    async getDependenciesRows(idNS: string, dependencyMode: EDependencyMode, childDependencies: boolean, nsType?: ENonSerializableObjectType): Promise<INSDependencyDescription[]> {
        const req: ICheckDependenciesNSRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.checkDependencies),
            dependencyMode,
            idNS,
            checkChildren: childDependencies,
            nsType,
        };
        const infra: IInfraParameters = this.generateInfraParameters()
        const infraRes: ClientInfraResponse = await this.api.managedRequest(infra, req);
        const response: ICheckDependenciesNSResponse = <ICheckDependenciesNSResponse>infraRes.response;
        return response.dependencies;
    }

    public async deleteWithoutConfirmation(nser: INonSerializable, requestType: ApiRequestType = apiRequestType.nonSerializable.delete) {
        const infra: IInfraParameters = this.generateInfraParameters()
        const request: IDeleteNSRequest = {
            ...this.rbs.secureBasicRequest(requestType),
            deletingID: nser.idNS,
            checkChildren: false, //@TODO ver isto
            justCheckIFCanDelete: false
        };
        const response = await this.api.managedRequest(infra, request);
        return {
            response,
            request,
            infra,
            idJob: response.response.idJob
        };
    }
    public async delete(
        nser: INonSerializable,
        requestType?: ApiRequestType,
        askAreYouSure: boolean = true
    ): Promise<{ result: ENserDeleteResult, idJob?: string }> {
        try {

            if (askAreYouSure && !(await this.warning.askSureNSerRemoval(nser.nName))) {
                return { result: ENserDeleteResult.Aborted }
            }

            const { response: responseDelete, infra, request, idJob } = await this.deleteWithoutConfirmation(nser, requestType);

            const isAsyncProcess: boolean = isValidString(idJob);

            if(isAsyncProcess) {
                return { result: ENserDeleteResult.Async, idJob: idJob }
            }

            if (!responseDelete.friendlyMessage.isDeleteDependenciesMessage()) {
                return responseDelete.friendlyMessage.isOk()
                    ? { result: ENserDeleteResult.Success }
                    : { result: ENserDeleteResult.Failed };
            }



            if ( !responseDelete.friendlyMessage.canAutoDelete()) {
                const msg: string = Serializable.getTranslation(gTranslations.errors.oneOfDependenciesCannotBeAutomaticDeleted);
                this.warning.showError(msg);
                return { result: ENserDeleteResult.Failed };
            }
            const clickedBtn: EInteractiveButton = await this.warning.showDeleteDependenciesConfirmation(nser.nName);

            if (clickedBtn === EInteractiveButton.SeeDependencies) {
                this.dependencies.queryDependencies(nser.idNS, nser.nName, nser.nsType);
                return;
            }

            if (clickedBtn === EInteractiveButton.No || clickedBtn === EInteractiveButton.Cancel)
                return { result: ENserDeleteResult.Aborted };
            request.requestType = apiRequestType.nonSerializable.deleteAndClearReferences;

            const responseDeleteAndClearReferences = await this.api.managedRequest(infra, request);
            return responseDeleteAndClearReferences.friendlyMessage.isOk()
                ? { result: ENserDeleteResult.Success }
                : { result: ENserDeleteResult.Failed };

        } catch (e) {
            return { result: ENserDeleteResult.Failed };
        }
    }


    public async deleteBatch(nss: INonSerializable[]): Promise<boolean> {
        const title = nss.length === 1
            ? `Remover item: ${nss[0].nName}`
            : `Remover ${nss.length} itens.`

        const desc = nss.length === 1
            ? `Você tem certeza que deseja remover [b]${nss[0].nName}[/b]?`
            : `Você tem certeza que deseja remover [b]${nss.length}[/b] itens?`

        const sure: boolean = await this.warning.askMessage({
                title, message: desc, yes: null, no: null, matIcon: {
                    icon: "warning",
                    color: "warn"
                }, interactiveButtons: [
                    INTERACTIVE_NO,
                    INTERACTIVE_YES_WARN,
                ]
            });

        if(!sure) return;

        return this.deleteBatchWithoutConfirmation(nss.map( ns => ns.idNS ));
    }

    public async deleteBatchWithoutConfirmation(nssID: string[]): Promise<boolean> {
        const infra: IInfraParameters = this.generateInfraParameters()
        const request: IDeleteBatchNSRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.deleteBatch),
            idsToDelete: nssID,
        };
        const response = await this.api.managedRequest(infra, request);

        return response.executionOK;
    }
}
