
export enum EPaletteType { 
    PrimaryPalette = 'primaryPalette',
    AccentPalette = 'accentPalette',
    ForegroundPalette = 'foregroundPalette'
}

export enum EPaletteProperty { 
    Palette = 'palette',
    Contrast = 'contrast',
}

export interface ICSSUnityProperty { 
    prefix: string;
    suffix: string;
}

export interface IColorPalleteBase {
    50?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
    A100?: string;
    A200?: string;
    A400?: string;
    A700?: string;
    // default?: string;
    // lighter?: string;
    // darker?: string;
    // text?: string;
    // "default-contrast"?: string;
    // "lighter-contrast"?: string;
    // "darker-contrast"?: string;
}

export interface IColorPallete {
    palette?: IColorPalleteBase;
    contrast?: IColorPalleteBase;
}

export interface IForegroundColorPaletteBase {
    background?: string;
    base?: string;
    divider?: string;
    surface?: string;
    dividers?: string;
    disabled?: string;
    "disabled-button"?: string;
    "disabled-text"?: string;
    "hint-text"?: string;
    "secundary-text"?: string;
    icon?: string;
    icons?: string;
    text?: string;
    "slider-off"?: string;
    "slider-off-active"?: string;
    "slider-min"?: string;
    "modal-bg"?: string;
}

export interface IForegroundColorPalette {
    foregroundPalette?: IForegroundColorPaletteBase;
    foregroundContrast?: IForegroundColorPaletteBase;
}

// export interface IForegroundCollorPaletteWithType extends IForegroundColorPalette {
//     paletteType?: EPaletteType
// }

export interface ICollorPaletteWithType extends IColorPallete {
    paletteType?: EPaletteType
}

export interface IBrandTheme {
    fontFamily: string;
    primary: IColorPallete;
    accent: IColorPallete;
    success?: IColorPallete;
    info?: IColorPallete;
    alert?: IColorPallete;
    warn?: IColorPallete;
    foreground?: IForegroundColorPalette;
}