import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { isInEnum, isValidArray, isValidString } from "../../../../tools/utility";
import { EConfigurationSet, IConfigTagUsedDB, IConfigurationSetServer, TIConfigTagUsedDBArray } from "../../../configuration-set/config-set-model";
import { ECSContentComparison } from "../../../corporate-search/corporate-search-model";
import { configurationSetErrors } from "../error-fields";
import { screenOptionToError } from "../error-messages";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";

const errors = screenOptionToError(configurationSetErrors);

export const configurationSetValidator: TNserValidator = (nser: IConfigurationSetServer, friendly: FriendlyMessage) => {
  defaultNserValidator(nser, friendly);

  if(!isInEnum(EConfigurationSet, nser.configType)) {
    friendly.addReturnResponse(errors.invalidConfigType);
  }

  if(!isValidString(nser.idGranteeGroup)) {
    friendly.addReturnResponse(errors.invalidGroup);
  }

  switch(nser.configType) {
    case EConfigurationSet.tagSecurity:
      if(!isValidArray(nser.tagSet)) {
        friendly.addReturnResponse(errors.invalidTagSet);
      }
      break;

    case EConfigurationSet.allowedDB:
      if(!isValidConfigSetAllowedDBs(nser.allowCS)) {
        friendly.addReturnResponse(errors.ivalidAllowCS);
      }
      break;
  }

  return friendly;
}

function isValidConfigSetAllowedDBItem(input: IConfigTagUsedDB): boolean {
  return isValidString(input.idCorporateSearchBody) && isInEnum(ECSContentComparison, input.comparison)
}


function isValidConfigSetAllowedDBs(configAllowedSets: TIConfigTagUsedDBArray): boolean {
  return isValidArray(configAllowedSets) && configAllowedSets.every( c => isValidConfigSetAllowedDBItem(c));
}