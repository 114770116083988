<div *ngIf="partialContacts.length > 0" style="margin-bottom: 2rem">
    <span>
        Há {{partialContacts.length}} 
        {{
            partialContacts.length === 1 
            ? 'contato parcial selecionado'
            : 'contatos parciais selecionados' 
        }}
        
    </span>
    <br/>
    <button 
        (click)="sendPartial()" 
        mat-flat-button color="primary" 
        class="button-w-icon send__button" 
        style="margin-top: 8px"
    >
        <mat-icon>send</mat-icon> 
        <span>Configurar ação e enviar</span>
    </button>
</div>
<div>
    <span>
        Há {{ completeContacts.length }} 
        {{ 
            completeContacts.length === 1 
            ? 'contato completo selecionado' 
            : 'contatos completos selecionados' 
        }} 
    </span>
    <br/>
    <div class="complete-contact-btn-wrapper">
        <button 
            (click)="send()" 
            mat-flat-button 
            color="primary" 
            class="button-w-icon send__button" 
        >
            <mat-icon>send</mat-icon>
            <span>Enviar agora</span>
        </button>
        <button 
            (click)="initScheduleHandler()" 
            mat-flat-button 
            color="primary" 
            class="button-w-icon send__button" 
        >
            <mat-icon>schedule_send</mat-icon> 
            <span>Agendar envio</span>
        </button>
    </div>
    <cm-date-picker
        *ngIf="scheduleHandler"
        [handler]="scheduleHandler"
        class="full-width"
        >
    </cm-date-picker>
    <button 
        *ngIf="hasScheduleValue()"
        (click)="schedule()" 
        mat-flat-button 
        color="accent" 
        class="button-w-icon send__button" 
    >
        <mat-icon>schedule</mat-icon> 
        <span>Agendar</span>
    </button>
</div>