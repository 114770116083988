import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { IWhatsappBusinessAccountClient, IWhatsappBusinessAccountServer } from "@colmeia/core/src/shared-business-rules/waba/waba.model";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import { ENonSerializableObjectType, NonSerializable } from "../non-serializable-id/non-serializable-id-interfaces";

export interface IWhatsappBusinessAccountRequest extends IRequest {
    ns: IWhatsappBusinessAccountClient | IWhatsappBusinessAccountServer
}

export interface IWhatsappBusinessAccountResponse extends IResponse {
    ns: IWhatsappBusinessAccountServer
}

export enum EWABACustomWebhookStatus {
    REGISTERED = "REGISTERED",
    UNREGISTERED = "UNREGISTERED",
}

export interface IWABACustomWebhookStatusRequest extends IRequest {
    data: {
        wabaId: string;
    }
}

export interface IWABACustomWebhookStatusResponse extends IResponse {
    data: {
        status: EWABACustomWebhookStatus;
    }
}

export interface IUnregisterWABACustomWebhookRequest extends IRequest {
    data: {
        wabaId: string;
    }
}

export interface IUnregisterWABACustomWebhookResponse extends IResponse {
    data: {
        wabaId: string;
        success: boolean;
    }
}

export enum ECreditLineStatus {
    UNSHARED = "UNSHARED",
    APPROVED = "APPROVED",
    DELETED = "DELETED",
}

export interface IWabaCreditLineStatusRequest extends IRequest {
    data: {
        creditLineId?: string;
    }
}

export interface IWabaCreditLineStatusResponse extends IResponse {
    data: {
        status: ECreditLineStatus;
    }
}

export interface IAttachWabaCreditLineRequest extends IRequest {
    data: {
        wabaId: string;
        idNs: IdDep<ENonSerializableObjectType.waba>;
    }
}

export interface IAttachWabaCreditLineResponse extends IResponse {
    data: {
        creditLineId: string;
        creditLineStatus: ECreditLineStatus;
    }
}
export interface IRevokeWabaCreditLineRequest extends IRequest {
    data: {
        creditLineId: string;
    }
}

export interface IRevokeWabaCreditLineResponse extends IResponse {
    data: {
        creditLineId: string;
        creditLineStatus: ECreditLineStatus;
    }
}
