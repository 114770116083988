import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces"
import { IdDep } from "../non-serializable-id/non-serializable-types"

export const gFacebookAPIConfig = {
    apiDomain: 'https://graph.facebook.com',
    version: 'v17.0',
} as const


export namespace FacebookIntegration {
    export enum Version {
        V15 = 'v15.0',
        V16 = 'v16.0',
        V17 = 'v17.0',
        V18 = 'v18.0',
        V20 = 'v20.0',
    }
}

export interface IFacebookPageNameAndID {
    idNS: IdDep<ENonSerializableObjectType.metaBusinessPages>,
    name: string
}
export class FacebookIntegrationModel {
    static getAPIVersion(): string {
        return gFacebookAPIConfig.version
    }

    static getUrl(version: string = gFacebookAPIConfig.version) {
        return `${gFacebookAPIConfig.apiDomain}/${version}` as const
    }

    static getWhatsAppURL(version = FacebookIntegration.Version.V17) {
        return FacebookIntegrationModel.getUrl(FacebookIntegration.Version.V17);
    }


    static getMessagesUrl(): string {
        return `${FacebookIntegrationModel.getUrl()}/me/messages`
    }

    static getAccessTokenUrl(): string {
        return `${FacebookIntegrationModel.getUrl()}/oauth/access_token`
    }
}
