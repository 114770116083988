import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { constant } from '../../business/constant';
import { IFeedbackCarrierTrackerJSON } from '../../comm-interfaces/barrel-comm-interfaces';
import { ChainedInteraction } from '../chained-interaction';
import { InteractionType } from '../interaction-type';
import { Interaction } from '../interaction';
import { ICacheFeedbackInteraction } from '../../comm-interfaces/feedback-interfaces';

export class FeedbackFiguresCarrier extends ChainedInteraction {
    private feedbacks: ICacheFeedbackInteraction;

    private constructor (primaryID: TGlobalUID, participant: Participant, parent: Interaction) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.changeStateSynch.feedbackFiguresCarrier), parent);
    };

    public getFeedbackFigures(): ICacheFeedbackInteraction {
        return this.feedbacks;
    };

    public rehydrate(json: IFeedbackCarrierTrackerJSON): void {
        super.rehydrate(json);
        this.feedbacks = json.feedbacks;
    };

    public static factoryMessage(json: IFeedbackCarrierTrackerJSON, participant: Participant = null): FeedbackFiguresCarrier {
        const carrier: FeedbackFiguresCarrier = new FeedbackFiguresCarrier(json.primaryID,
            participant? participant: Participant.staticFactory(json.participant.primaryID),
            Interaction.staticFactory(json.idInteractionParent));

        carrier.rehydrate(json);

        return carrier;
    };
};
