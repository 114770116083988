<app-alert [hideIcon]="true" class="async-job-warn" type="info">
  <div class="container">
    <div class="message">
      <strong>ATENÇÃO</strong><br>
      <br>
      Esse processo é gerado através de um <strong>Job</strong> e você verá o resultado nesta tela apenas após o término do processamento. <br>
      <br>
      Iremos avisá-lo do progresso via <strong>notificações</strong>.
    </div>
    <button mat-flat-button color="primary" (click)="close()">
      OK
    </button>
  </div>
</app-alert>
