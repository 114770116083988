import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IGPSConfigurationStatus } from '@colmeia/core/src/comm-interfaces/settings-interface';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { isValidRef } from "@colmeia/core/src/tools/utility";
import {IChangeInterfaceListener} from "../../../../model/signal/ps-interfaces";
import {SnackMessageService} from "../../../../services/snack-bar";
import {GeolocationHandlerInteractorService} from "../../../../services/geolocation-handler-interactor.service";
import {SignalPublisherService} from "../../../../services/signal/signal-publisher";
import {HardwareLayerService} from "../../../../services/hardware";
import {SignalListenerService} from "../../../../services/signal/signal-listener";
import {InterfaceInfoSignal} from "../../../../model/signal/interface-signal";
import {EHardwareResourceStatus} from "../../../../services/hardware/cordova/vendor/hardware-status";
import {ActivateGeoSignal, DeactivateGeoSignal} from "../../../../model/signal/geo-signals";
import {SettingsModalService} from "../../settings-modal/settings-modal.service";
import {settingsViewScreenOptions} from "@colmeia/core/src/shared-business-rules/const-text/views/settings";


@Component({
    selector: 'app-location-settings',
    templateUrl: './location-settings.component.html',
    styleUrls: ['./location-settings.component.scss']
})
export class LocationSettingsComponent implements OnInit, OnDestroy, IChangeInterfaceListener {
    canShowLocationEnabledToggle: boolean = true;
    labels = {
        enableLocation: ''
    };
    private _isPLayerBeingGPSTracked: boolean;
    gpsStatus: IGPSConfigurationStatus;

    constructor(
        private cdRef: ChangeDetectorRef,
        private snackSvc: SnackMessageService,
        private settingsModal: SettingsModalService,
        private locationHandlerInteractorSvc: GeolocationHandlerInteractorService,
        private emissor: SignalPublisherService,
        private hw: HardwareLayerService,
        private listener: SignalListenerService
    ) { }

    ngOnInit() {
        this.translateLabels();
        this.initSettings();
        this.listener.listenToInterfaceChanges(this);
    }

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (isValidRef(sign.getInterfaceSignal().changeLanguage)) {
            this.translateLabels();
        }
    }

    private translateLabels(): void {
        this.labels.enableLocation = Serializable
            .staticFactory(settingsViewScreenOptions.idSerializable)
            .getSerializableText(settingsViewScreenOptions.fields.location);
    }


    initSettings(): void {
        this.settingsModal
            .getGpsDataSettings()
            .pipe(first())
            .subscribe(async (value: IGPSConfigurationStatus) => {
                const isAllowed = await this.hw.getGeolocation().isAllowed();
                this.gpsStatus = value;
                this._isPLayerBeingGPSTracked = value.gpsOn && isAllowed;
                this.cdRef.markForCheck();
            },
            err => {this.snackSvc.openError(err)})
    }

    get isPLayerBeingGPSTracked(): boolean {
        return this._isPLayerBeingGPSTracked;
    }
    set isPLayerBeingGPSTracked(isAboutToBeTracked: boolean) {
        (async (isAboutToBeTracked: boolean) => {
            await this.handleToggleChanged(isAboutToBeTracked);
            this.cdRef.markForCheck();
        })(isAboutToBeTracked);
    };

    async handleToggleChanged(isAboutToBeTracked: boolean): Promise<void> {
        try {
            let result: FriendlyMessage;
            this.canShowLocationEnabledToggle = false;

            const isAllowed: boolean = await this.hw.getGeolocation().isAllowed();


            if (isAllowed) {
                if(isAboutToBeTracked) {
                    result = await this.locationHandlerInteractorSvc.locationTurnOnTriggedByUser();
                    this.hw.getGeolocation().start();
                } else {
                    result = await this.locationHandlerInteractorSvc.locationTurnOffTriggedByUser(this.gpsStatus);
                    this.hw.getGeolocation().stop();
                }
            } else {
                const auth: EHardwareResourceStatus = await this.hw.getGeolocation().requestAuthorization();
                if (auth === EHardwareResourceStatus.GRANTED) {
                    result = await this.locationHandlerInteractorSvc.locationTurnOnTriggedByUser();
                    this.hw.getGeolocation().start();
                } else {
                    this.snackSvc.openError('É necessário autorizar geolocalização para isto.');
                }
            }

            if(result && result.isOk()) {
                this._isPLayerBeingGPSTracked = isAboutToBeTracked;
            }

        } catch (error) {
            this.snackSvc.openError(error)
        } finally {
            this.canShowLocationEnabledToggle = true;
        }
    }

    emitSignal(): void {
        this.emissor.specificSignalEmissorOnGenericStream(
            (this._isPLayerBeingGPSTracked)
                ? new DeactivateGeoSignal()
                : new ActivateGeoSignal()
        );
    }


}
