import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { NonSerializableService } from 'app/services/non-serializable.service';
import { IBasicUniversalInfo, TBasicUniveralInfoArray } from '@colmeia/core/src/comm-interfaces/aux-interfaces';
import { INonSerializableSelectHandler } from 'app/handlers/non-serializable-select.handler';
import { MultimediaService } from 'app/services/multimedia.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-non-serializable-select',
	templateUrl: './non-serializable-select.component.html',
	styleUrls: ['./non-serializable-select.component.scss']
})
export class NonSerializableSelectComponent implements OnInit {

	private _handler: INonSerializableSelectHandler;

	@Input() set handler(selectHandler: INonSerializableSelectHandler) {
		this._handler = selectHandler;
		this.updateNSList();
	}

	get handler(): INonSerializableSelectHandler {
		return this._handler;
	}

	nonSerializables: TBasicUniveralInfoArray;

	constructor(
		private nsSelectSvc: NonSerializableService,
		private cdr: ChangeDetectorRef,
		private mmSvc: MultimediaService,
	) { }

	ngOnInit() { }

	private async updateNSList(): Promise<void> {
		this.nonSerializables = undefined;
		this.nonSerializables = await this.nsSelectSvc.getNonSerializablesBasicInfo(this.handler.idParent, this.handler.nsType);
		this.cdr.markForCheck();
	}

	getMediaUrl(ns: IBasicUniversalInfo): string {
		return this.mmSvc.getMultimediaUrl(ns.idMedia);
	}

	onNSSelect(event: MatSelectChange): void {
		this.handler.onNSSelected(event.value);
	}

	compareNSIds(nsFromList: IBasicUniversalInfo, myNSID: string): boolean {
		return nsFromList && myNSID
			? nsFromList.primaryID === myNSID
			: false;
	}

}
