import { ICRMTimeWindowConfig } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-time-window.model";

export enum ECRMTicketViewMode {
    Kanban = 'kanban',
    List = 'list',
    Table = 'Table',
    Details = 'Details',
}

interface IExpirableItem {
    clockTick: number;
}

export interface ILocalFilterConfig {
    statusId: string;
    label: string;
}

export interface ICardSortData extends IExpirableItem {
    /**
     * O que é? Não sei, não me pergunte,
     * mas ele é a metade da diferença
     * entre o peso do card de cima e o de baixo.  
     */
    weightOffset: number;
}

export interface IColumnUIState {
    columnId: string;
    collapsed: boolean;
}

export interface IKanbanCardSortData {
    cardId: string;
    clockTick: number;
}

export type TIKanbanCardSortDataArray = Array<IKanbanCardSortData>;

export type TKanbanSortedCards = {
    /**
     * Porque o clocktick? Pra remover itens com mais de 1 mês que não aparecem
     * na tela do agente com o ituito de manter baixo o consumo de storage a longo prazo.
     */
    [columnId in string]: TIKanbanCardSortDataArray;

    // {
    //     [cardId in string]: ICardSortData;
    // }
};

export interface ICRMKanbanUserSettings {
    selectedBoardId: string; // id do pacote de itens;

    timeWindow: ICRMTimeWindowConfig;

    selectedFilters: {
        [selectedPackageId in string]: {
            /**
             * itemPkgId tem o comportamento de OR entre os pacotes e o mesmo para os items
             * exemplo:
             * 
             * @example 
             * {
             *   [idSeveridadeHeader]: [severidade1, severidade2],
             *   [idTicketStatusHeader]: [novo, reaberto]
             * }
             * Resulta em:
             * (severidade1 || severidade2) && (novo || reaberto)
             * Nesse caso irá mostrar os items que tem severidade "severidade1" ou "severidade2" e status "novo" ou "reaberto"
             */
            [itemPkgId in string]: string[]
        }

    },
    customFilters: unknown[];
    sortedCards: TKanbanSortedCards;

}

export interface IUserTicketUIConfig {
    selectedPackageId: string;
    viewMode: ECRMTicketViewMode;
    kanban: ICRMKanbanUserSettings;
}