

<ng-container *ngIf="canDisplayFormGroup; else noFormGroup">
  <div [formGroup]="fGroup" [visible-horizontal]="canDisplayField">
    <mat-form-field appearance="fill" (click)="field.multipleAnswers && focusAnswerMultipleInput()">
      <mat-label>{{ field.prompt }}</mat-label>

      <ng-container *ngIf="field.multipleAnswers">
        <mat-chip-grid #answersChipList>
          <mat-chip-row
            *ngFor="let answerItem of !isEdit ? field.value : field.children; index as i"
            [selectable]="isEdit"
            [removable]="isEdit"
            (removed)="removeMultipleFieldValue(i)">
            {{ !isEdit ? answerItem : answerItem.value }}
            <mat-icon matChipRemove *ngIf="isEdit">cancel</mat-icon>
          </mat-chip-row>
          <input
            *ngIf="isEdit"
            [type]="type"
            cdkFocusInitial
            placeholder="Adicionar..."
            (focus)="onFocus()"
            #answerMultipleInput
            value=""
            [mask]="inputMask"
            [formControlName]="field.idProperty"
            [matChipInputFor]="answersChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addMultipleAnswerItem($event)">
        </mat-chip-grid>
      </ng-container>

      <input
        *ngIf="!field.multipleAnswers && !advancedMessagesEngagementConfig && !useTextArea && !hasCustomSelectValues"
        #fieldInput
        matInput
        [required]="isRequired"
        [type]="type"
        [mask]="inputMask"
        (keyup)="field.value = fieldInput.value"
        [formControlName]="field.idProperty"
        [placeholder]="field.prompt"
        (focus)="onFocus()"
        (blur)="onBlur($event)"
        [readonly]="!isEdit"
      />

      <!-- Só aparece em modo de edição -->
      <textarea
        *ngIf="useTextArea"
        matInput
        readonly
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        (blur)="onBlur($event)"
        cdkAutosizeMaxRows="5">{{ field.value }}</textarea>

      <ng-container *ngIf="hasCustomSelectValues">
        <mat-select
          *ngIf="!isLoadingCustomSelectValues else loadingCustomSelectValues"
          [value]="field.value"
          [disabled]="isDisabled"
          (selectionChange)="onCustomValueChange($event)">
          <mat-option *ngFor="let item of customSelectValues" [value]="item.value">{{item.label}}</mat-option>
        </mat-select>
      </ng-container>


      <mat-icon matSuffix>{{ statusIcon }}</mat-icon>

      <mat-error>
        {{ getErrorMsg() }}
      </mat-error>

      <mat-hint *ngIf="field.multipleAnswers && isEdit">Digite e pressione ENTER para adicionar...</mat-hint>
    </mat-form-field>
  </div>

</ng-container>

<ng-template #noFormGroup>
  <div [visible-horizontal]="canDisplayField">
    <mat-form-field appearance="fill" (click)="field.multipleAnswers && focusAnswerMultipleInput()">
      <mat-label>{{ field.prompt }}</mat-label>

      <ng-container *ngIf="field.multipleAnswers">
        <mat-chip-grid #answersChipList>
          <mat-chip-row
            *ngFor="let answerItem of !isEdit ? field.value : field.children; index as i"
            [selectable]="isEdit"
            [removable]="isEdit"
            (removed)="removeMultipleFieldValue(i)">
            {{ !isEdit ? answerItem : answerItem.value }}
            <mat-icon matChipRemove *ngIf="isEdit">cancel</mat-icon>
          </mat-chip-row>
          <input
            *ngIf="isEdit"
            [type]="type"
            cdkFocusInitial
            placeholder="Adicionar..."
            (focus)="onFocus()"
            #answerMultipleInput
            [mask]="inputMask"
            [matChipInputFor]="answersChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addMultipleAnswerItem($event)">
        </mat-chip-grid>
      </ng-container>

      <input
        *ngIf="!field.multipleAnswers"
        matInput
        [required]="isRequired"
        [type]="type"
        [mask]="inputMask"
        [(ngModel)]="field.value"
        (blur)="onBlur($event)"
        [placeholder]="field.prompt"
        (focus)="onFocus()"
      />

      <ng-container *ngIf="hasCustomSelectValues">

        <mat-select
          *ngIf="!isLoadingCustomSelectValues else loadingCustomSelectValues"
          [disabled]="isDisabled"
          [(ngModel)]="field.value">
          <mat-option *ngFor="let item of customSelectValues" [value]="item.value">{{item.label}}</mat-option>
        </mat-select>

      </ng-container>

      <mat-icon matSuffix>{{ statusIcon }}</mat-icon>

      <mat-hint *ngIf="field.multipleAnswers && isEdit">Digite e pressione ENTER para adicionar...</mat-hint>
    </mat-form-field>
  </div>
</ng-template>

<ng-container *ngIf="isTokenError()">
  <div class="wait__to__send__message" *ngIf="hasWaitTime()">Aguarde {{ getWaitTime() }} segundos para reenviar</div>
  <button mat-button *ngIf="canResendToken()" (click)="resendToken()"><u>Reenviar token</u></button>
</ng-container>

<ng-template #loadingCustomSelectValues>
  <mat-spinner diameter="18"></mat-spinner>
</ng-template>
