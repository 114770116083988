import { EAdditionalPredicateType } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import React, { useEffect, useState } from "react";
import { Handle, Position, useEdges } from "reactflow";
import { IBasicEdgeData, IBasicNodeData } from "../react-nodes.model";

export interface IStandardNodeData extends IBasicNodeData {}

export function StandardNode({ data }: { data: IStandardNodeData }) {
    const [html, setHTML] = useState("");
    const edges = useEdges<IBasicEdgeData>();

    useEffect(() => {
        setHTML(data.htmlHandler.renderHTML(data.graphElement));
    }, []);

    return (
        <>
            {data.connectionPorts.map((connectionPort) => {
                let className = `${connectionPort.referencedObject}`;

                const isConnected = edges.some((edge) => {
                    return connectionPort.type === "source"
                        ? edge.sourceHandle === connectionPort.id &&
                              edge.source ===
                                  data.graphElement.getGraphElementID()
                        : edge.targetHandle === connectionPort.id &&
                              edge.target ===
                                  data.graphElement.getGraphElementID();
                });

                if (isConnected) {
                    className += " has-connection";
                }

                return (
                    <Handle
                        key={connectionPort.id + connectionPort.type}
                        id={connectionPort.id}
                        isConnectable={
                            connectionPort.id !==
                            EAdditionalPredicateType.Parent
                        }
                        type={connectionPort.type}
                        position={connectionPort.spot as unknown as Position}
                        className={className}
                    />
                );
            })}
            {html && (
                <div
                    className="standard-reactflow-node-wrapper"
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                ></div>
            )}
        </>
    );
}
