import { OnInit, ChangeDetectorRef, OnDestroy, Directive } from "@angular/core";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { Subject, Subscription, PartialObserver } from "rxjs";
import { Translation } from "../translation/translation-helper";
import { AppComponent } from "app/app.component";
import { SignalListenerService } from "app/services/signal/signal-listener";
import { IChangeInterfaceListener } from "app/model/signal/ps-interfaces";
import { InterfaceInfoSignal } from "app/model/signal/interface-signal";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";

@Directive()
export class RootComponent<T extends string, O extends { [key in T]: ITranslationConfig } = { [key in T]: ITranslationConfig }> implements OnInit, OnDestroy, IChangeInterfaceListener {

    protected onTranslation = new Subject<void>();
    private translationSub: Subscription = new Subscription();
    private _listener: SignalListenerService;
    public translations: O;

    constructor(
        private _translations: O = {} as O,
        autoTranslate: boolean = true,
        private cdrForTranslation: ChangeDetectorRef = null
    ) {
        if (isValidRef(_translations) && autoTranslate) {
            this.translations = new Proxy<O>(this._translations, {
                get: (target, name): ITranslationConfig => name in target ? target[name] : { value: name }
            });

            this.translate();
            this._listener = AppComponent.injector.get(SignalListenerService);
            this._listener.listenToInterfaceChanges(this);
        }
    }

    protected translate() {
        let key: string, config: ITranslationConfig;
        try {
            for ([key, config] of Object.entries<ITranslationConfig>(this.translations as { [s: string]: ITranslationConfig; })) {
                const value = Serializable.getTranslation(config);
                this.translations[key] = new Translation(config.serializableId, config.idField, value);
            }
        } catch (e) {
            console.table({
                key,
                config
            });
        }
        if (this.cdrForTranslation) {
            this.cdrForTranslation.markForCheck();
        }
        this.onTranslation.next();
    };

    protected subscribeToTranslations(callback: any) {
        this.translationSub.add(this.onTranslation.subscribe(callback));
    }

    ngOnInit() {}

    ngOnDestroy() {
        if (this._listener) {
            this._listener.destroySubscriptions(this);
        }
        this.translationSub.unsubscribe();
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if (sign.languageChanged) {
            this.translate();
        }
    }

    getTranslation(translation: ITranslationConfig): string {
        return Serializable.getTranslation(translation);
    }
}
