import {Participant} from '../../business/participant';
import {constant, TGlobalUID} from '../../business/constant';
import {Role} from '../../security/role';
import {Interaction} from '../interaction';
import {ChainedInteraction} from '../chained-interaction';
import {InteractionType} from '../interaction-type';



export class NewParticipantInteraction extends Interaction {
    constructor(primaryID: TGlobalUID, participant: Participant, grantor: GrantorInteraction ) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.security.newParticipant));
    };
};

export class GrantorInteraction extends Interaction {
    constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.security.grantorRegister));
    };
};


export class GranteeInteraction extends ChainedInteraction { 
    private role: Role;
    constructor(primaryID: TGlobalUID, participant: Participant, interactionParent: Interaction,  role: Role) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.security.newRole), interactionParent);
        this.role = role;
    };

    public getRole(): Role {
        return this.role;
    };
};



export class NewGroupInteraction extends Interaction {
    constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.security.newGroupCreated));
    };
};

