import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/multi-media-instance';
import { SelectedFile } from '@colmeia/core/src/multi-media/selected-file';
import { ClientCachedFile } from '@colmeia/core/src/multi-media/client-cached';
import { Subscription } from 'rxjs';
import { AttachBarHandler, IForceUploader } from "../../../handlers/attach-bar-handler";
import { IMultimediaComponentCallback, MultimediaHandler } from "../../../handlers/multimedia-handler";
import { FileUploadSignal } from "../../../model/signal/file-upload-signal";
import { HardwareLayerService } from "../../../services/hardware";
import { MultimediaService } from "../../../services/multimedia.service";
import { ServerCommunicationService } from "../../../services/server-communication.service";
import { MultimediaInstanceUploaderComponent } from "../../foundation/multimedia-instance-uploader/multimedia-instance-uploader.component";
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { IMultimediaPreviewParameter, MultimediaPreviewHandler, EMultimediaPreviewType } from 'app/components/chat/message-container/message-instance/multimedia-preview/multimedia-preview.handler';
import { clientConstants } from 'app/model/constants/client.constants';
import { MultimediaObject } from '@colmeia/core/src/multi-media/multi-media-object';

type TAttachmentCache = Map<string, FileUploadSignal>;

@Component({
    selector: 'chat-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit, OnDestroy, AfterViewInit, IMultimediaComponentCallback, IForceUploader {
    @ViewChild('mmUploader', {
        static: true
    }) mmUploader: MultimediaInstanceUploaderComponent;
    private groupToBulkInvite: string;
    private templateToBulkInvite: string;
    public multimediaHandler: MultimediaHandler;
    private uploadSubscription: Subscription;
    private fileCache: TAttachmentCache = new Map();
    @ViewChild('template', {
        static: true
    }) template: ElementRef;    // Remover posteriormente
    @ViewChild('group', {
        static: true
    }) group: ElementRef;       // remover posteriormente
    @Input() handler: AttachBarHandler;
    multimediaPreviewHandlers: MultimediaPreviewHandler[] = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        private multimediaSvc: MultimediaService,
        private serverCommSvc: ServerCommunicationService,
        private hardwareLayerSvc: HardwareLayerService,
    ) { }

    ngOnInit() {
        // Criar Multimedia do MultimediaUploader
        const parameter = this.handler.getComponentParameter();
        this.multimediaHandler = new MultimediaHandler({
            idPlayer: parameter.idPlayer,
            idAvatar: parameter.idAvatar,
            generateHashNow: parameter.generateHashNow,
            idMultimediaTag: parameter.idMultimediaTag,
            mimeTypeFilter: parameter.mimeTypeFilter,
            clientCallback: this,
            maxNumberOfFiles: parameter.maxNumberOfFiles,
            maxFileBytesSize: parameter.maxFileBytesSize,
            multimediaService: this.multimediaSvc,
        });

        this.templateToBulkInvite = 'd-c85ccbe1227748359dabd1c25738ad29';
        this.groupToBulkInvite = '5514';

        // listening to upload events
        this.uploadSubscription = this.serverCommSvc
            .uploadStatusListener()
            .subscribe((progress: FileUploadSignal) => {
                this.updatePercentual(progress)
            });

        if (this.handler.isUploadWindowOpen()) {
            this.cdRef.detectChanges();
            this.onFileUploadClicked();
        }

        // this.handler.setComponent(this);
    }

    public setMMObject(mmObject: MultimediaObject) {
        const mmPreviewHandler = this.getMultimediaPreviewHandler({ mmObject });
        this.multimediaPreviewHandlers.push(mmPreviewHandler);
    }


    private getMultimediaPreviewHandler(parameters: Partial<IMultimediaPreviewParameter>): MultimediaPreviewHandler {
        return new MultimediaPreviewHandler({
            type: EMultimediaPreviewType.All,
            canOpenMediaModal: true,
            maxNumberMultimediaToShow: clientConstants.multimedia.maxDisplayedMediaNumber,
            missingMediaNumber: 0,
            msgContainerInstance: undefined,
            multimediaList: [],
            useThumbnails: true,
            ...parameters
        } as IMultimediaPreviewParameter);
    }

    ngAfterViewInit(): void {
        this.handler.setComponent(this);
    }

    ngOnDestroy(): void {
        this.onClearMultimedia();
        this.uploadSubscription.unsubscribe();
    };

    onFileUploadClicked(): void {
        this.mmUploader.onFileUploadClicked();
    }

    public getMultimediaDescription(): string {
        const description = Serializable.staticFactory(this.multimediaHandler.getComponentParameter().idMultimediaTag).getName();
        return description ? description : "Bancos de dados CSV";
    }

    // IMultimediaComponentCallback compliance
    onMultimediaSelected(mmFiles: TMultimediaInstanceArray) {
        const selectedFiles: SelectedFile[] = mmFiles.map(file => file.getClientFileInfo()).filter(isValidRef);

        for (const file of selectedFiles) {
            const uploadSignal = new FileUploadSignal(file, file.isSmartShare() ? 100 : null);
            this.fileCache.set(file.getClientCachedFile().getHash(), uploadSignal);
            this.updatePercentual(uploadSignal);
        }

        this.handler.onMultimediaSelected(mmFiles);
        this.cdRef.markForCheck();
    }

    onClearMultimedia(): void {
        this.fileCache.clear();
        this.handler.clearFiles();
        this.cdRef.markForCheck();
    }


    /**
     * Returns files from cache
     * @returns TFileArray
     */
    public getFileHashList(): string[] {
        const files: string[] = [];
        // tslint:disable-next-line:forin
        for (const file of this.fileCache.keys()) {
            files.push(file);
        }
        this.detectChanges();
        return files;
    }

    public getFileName(hash: string) {
        return this.fileCache.get(hash).getObservableInformation().getCurrentName();
        // return (this.fileCache.get(hash).getObservableInformation().getCurrentName().length <= 30) ?
        //         this.fileCache.get(hash).getObservableInformation().getCurrentName() :
        //         this.fileCache.get(hash).getObservableInformation().getCurrentName().slice(0, 30) + '...';
    };

    public getGroupToBulkInvite(): string {
        return this.groupToBulkInvite;
    }

    public setGroupToBulkInvite(value: string): void {
        this.groupToBulkInvite = value;
    }

    public getTemplateToBulkInvite(): string {
        return this.templateToBulkInvite;
    }

    public setTemplateToBulkInvite(value: string): void {
        this.templateToBulkInvite = value;
    }

    // public getMultimediaHandler(): MultimediaHandler {
    //     return this.actionChat.getAttachHandler();
    // };


    /**
     * Constraints to show/hide upload progress
     * @param  {File} file
     * @returns boolean
     */
    public canShowUploadProgress(hash: string): boolean {
        return this.fileCache.get(hash).getPercentage() != null;
    }

    /**
     * Updates percentage of upload to file
     * @param  {FileUploadSignal} signal
     * @returns void
     */
    public updatePercentual(signal: FileUploadSignal): void {
        if (this.fileCache.has(signal.getObservableInformation().getClientCachedFile().getHash())) {
            this.fileCache
                .get(signal.getObservableInformation().getClientCachedFile().getHash())
                .setPercentage(signal.getPercentage());
            this.cdRef.markForCheck();
        }
    }

    /**
     * Gets upload percentage
     * @param  {File} file
     * @returns number
     */
    public getPercentage(hash: string): number {
        const signal: FileUploadSignal = this.fileCache.get(hash);
        //let percent: number = 0;
        // if (!signal) {
        //     percent = 0;
        // } else if (signal.getObservableInformation().isSmartShare()) {
        //     percent = 100;
        // } else {
        //     percent = signal.getPercentage();
        // };
        return signal.getPercentage();
    }

    public getUploadSignal(hash: string): FileUploadSignal {
        return this.fileCache.get(hash);
    }

    /**
     * Getting progress bar color
     * @param  {File} file
     * @returns string
     */
    public getProgressBarColor(hash: string): string {
        const cached: ClientCachedFile = this.fileCache.get(hash)
            .getObservableInformation()
            .getClientCachedFile();
        let color: string = 'white';
        if (cached.isSmarShare() &&
            this.fileCache.get(hash).getPercentage() == 100) {
            color = 'goldenrod';
        }
        return color;
    };

    detectChanges(): void {
        this.cdRef.markForCheck();
    }

    async onCameraClicked(): Promise<void> {
        const photo = await this.hardwareLayerSvc.getCamera().getCameraMedia(this.hardwareLayerSvc.getFilesystem());
        this.mmUploader.handleFileChange([photo]);
    }

    public get mustShowCameraOption(): boolean {
        return this.hardwareLayerSvc.isMobile();
    }

    public forceUpload(files: FileList): void {
        if (!this.mmUploader.multimediaHandler) {
            this.mmUploader.multimediaHandler = this.multimediaHandler;
        }

        for (let i = 0; i < files.length; i++) {
            if (files.item(i).type === 'image/png') {
                this.mmUploader.handleFileChange([files.item(i)]);
            }
        }
    }
};
