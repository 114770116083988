import { ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { Observable, Subject, Subscription } from 'rxjs';
import { TNotificationDialogActionCallback } from './notification-dialog.model';
import { NotificationDialogRef } from './notification-dialog.ref';
import { TAppColorThemes } from '@colmeia/core/src/ui/ui.model';

const themeToClassMap: Record<TAppColorThemes, string> = {
  'primary': 'app-alert-primary',
  'accent': 'app-alert-accent',
  'info': 'app-alert-info',
  'success': 'app-alert-success',
  'warn': 'app-alert-error',
  'alert': 'app-alert-warning',
}

@Component({
  selector: 'notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit, OnDestroy {
  label: string;
  message: string;
  matIcon?: string;
  iconUrl?: string;
  hexagon?: HandlerHexagonon;
  mainAction?: TNotificationDialogActionCallback;
  updateSubscription: Subscription;
  hasAutoClose: boolean = false;
  duration?: number;
  customContainerStyle: Partial<CSSStyleDeclaration> = {};
  theme?: TAppColorThemes;
  customClasses: string[] = [];

  constructor(
    private ref: NotificationDialogRef,
    private _element: ElementRef,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.bind();

    this.updateSubscription = this.ref._update.subscribe(() => {
      this.bind();
      this.bounce();
    });
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  private bind() {
    this.hasAutoClose = false;
    this.cdr.detectChanges();

    const { label, message, icon, mainAction, customStyle, duration, theme } = this.ref.config;

    if (isValidString(theme)) {
      this.customClasses = [themeToClassMap[theme], 'alert-darker'];
    }

    if (isValidRef(customStyle?.container)) {
      this.customContainerStyle = this.sanitizer.sanitize(SecurityContext.STYLE, customStyle.container);
    }
    this.label = label;
    this.message = message;
    this.mainAction = mainAction;
    this.hasAutoClose = isValidRef(duration);
    this.duration = duration;

    if (isValidRef(icon)) {
      this.defineIcon();
    }
  }

  private bounce() {
    this._element.nativeElement.classList.remove("_bounce");

    setTimeout((el) => {
      el.classList.add("_bounce");
    }, 300, this._element.nativeElement);
  }

  private defineIcon() {
    const { icon: {
      matIcon, url, hexagonon
    } } = this.ref.config;

    if (matIcon) {
      this.matIcon = matIcon;
      return;
    }

    if (url) {
      this.iconUrl = url;
      return;
    }

    if (hexagonon) {
      this.hexagon = hexagonon;
      return;
    }
  }

  close($event: PointerEvent) {
    $event.stopPropagation();
    this.ref.close(true);
  }

  callMainAction() {
    this.mainAction(this.ref);
  }
}
