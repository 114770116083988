
export enum EMobileNativeEvents {
    reqPushToken = 'reqPushToken',
    event = 'event' ,
    debug = 'debug'
}

export enum EEventName {
    expoPushToken = 'expoPushToken',
    authToken = 'authToken',
    logout = 'logout',
    navigate = 'navigate',
    location = 'location',
    audio = 'audio',
    notification = 'notification',
    log = 'log'
}

export interface IMobileNativeData {
    type: EMobileNativeEvents,
    payload?: IEventPayload
}

export interface IEventPayload<T extends object = object> {
    eventName: EEventName,
    data: T
}

export interface IPushToken {
    pushToken: string,
    deviceName: string
}

export interface IAuthToken {
    token: string,
}

export interface IMobileRedirect {
    url: string
}

export interface IReactNativeLocation {
    location?: {
        timestamp: number,
        coords: TExpoLocationObjectCoords
    },
    isAllowed?: boolean,
    isLocationOn?: boolean,
    locationConfig?: TGeoLocationConfig,
    requestPermission?: boolean,
    permissionResponse?: boolean
}

export type TExpoLocationObjectCoords = {
    /**
     * The latitude in degrees.
     */
    latitude: number;
    /**
     * The longitude in degrees.
     */
    longitude: number;
    /**
     * The altitude in meters above the WGS 84 reference ellipsoid. Can be `null` on Web if it's not available.
     */
    altitude: number | null;
    /**
     * The radius of uncertainty for the location, measured in meters. Can be `null` on Web if it's not available.
     */
    accuracy: number | null;
    /**
     * The accuracy of the altitude value, in meters. Can be `null` on Web if it's not available.
     */
    altitudeAccuracy: number | null;
    /**
     * Horizontal direction of travel of this device, measured in degrees starting at due north and
     * continuing clockwise around the compass. Thus, north is 0 degrees, east is 90 degrees, south is
     * 180 degrees, and so on. Can be `null` on Web if it's not available.
     */
    heading: number | null;
    /**
     * The instantaneous speed of the device in meters per second. Can be `null` on Web if it's not available.
     */
    speed: number | null;
};

export interface IReactNativeAudio {
    permissionStatus?: boolean,
    requestPermission?: boolean,
}

export interface IReactNativeNotification {
    permission: boolean,
}

export type TGeoLocationConfig = {
    /**
     * Distance interval to collect location from expo in Meters.
     */
    maxDistance: number;
    /**
     * Time interval to collect location from expo in Miliseconds.
     */
    timeInterval: number;
} 

/**
 * Distance in Meters
 */
export enum EGeoDistanceAccuracy {
    Lowest = 3000,
    Low = 1000,
    Balanced = 100,
    High = 10,
}

export const DEFAULT_GEO_DISTANCE = 10;
export const DEFAULT_GEO_TIME = 10 * 1000 * 60; // 10mins in Miliseconds

export interface IReactNativeLog {
    identifier: string,
    data: any,
    isoDate: string
}
