import {Interaction} from '../interaction';
import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { InteractionType } from '../interaction-type';
import { constant } from '../../business/constant';
import {ISubscription, ISubscriptionInteractionJSON} from '../../comm-interfaces/barrel-comm-interfaces';
import { SubscriptionType } from './subscription-type';

export abstract class ConnectionToSerializable extends Interaction {

    private subs: ISubscription;

    private subscriptionType: TGlobalUID;
    private subjectPrimaryID: TGlobalUID;
    private muteUntilClock: number;
    private validUntilClock: number;


    protected constructor(primaryID: TGlobalUID, participant: Participant, subscriptionType: TGlobalUID, idSerializable: TGlobalUID){
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.subscription.subscription));
        this.subscriptionType = subscriptionType;
        this.subjectPrimaryID = idSerializable;
    };

    public toJSON(): ISubscriptionInteractionJSON {
        return {
            ...super.toJSON(),
            subject: this.subjectPrimaryID,
            idSubscriptionType: this.subscriptionType,
            muteUntilClock: this.muteUntilClock,
            validUntilClock: this.validUntilClock,
        };
    };

    public getSubsjectInfo(): ISubscription {
        return this.subs;
    }

    public getSubscriptionType(): SubscriptionType {return SubscriptionType.staticFactory(this.subscriptionType);};
    public getSubscriptionTypeID(): TGlobalUID {return this.subscriptionType;};
    public getReferencedSerializableID(): TGlobalUID {return this.subjectPrimaryID;};
    public getValidUntilClock(): Date {return new Date(this.validUntilClock);};

    public rehydrate(json: ISubscriptionInteractionJSON): void {
        super.rehydrate(json);
        this.subscriptionType = json.idSubscriptionType;
        this.muteUntilClock = json.muteUntilClock;
        this.validUntilClock = json.validUntilClock;
        this.subs = json.subs;
	};


	public static staticFactory(idConnection: TGlobalUID): ConnectionToSerializable {
	    return <ConnectionToSerializable>Interaction.staticFactory(idConnection);
    };
    
 
};
