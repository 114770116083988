import {Injectable} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

/// <reference path="../definitions/diagnostic.d.ts" />

export interface IPermissionPrompt {

}

@Injectable({
    providedIn: 'root'
})
export class CordovaPermissionsService {
    constructor(
        matDialog: MatDialog
    ) {

    }
    static prompt(parameter: string) {

    }
}
