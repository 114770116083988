import { ChatbarHandler, IChatBarParameter } from "./chat-bar-interactions/chat-bar-handler";
import { StartServiceChat } from "@colmeia/core/src/interaction/service-group/start-service-chat";
import { IRollbackTransaction } from "app/services/client.transact.service";

export interface IStartServiceParam extends IChatBarParameter { 
    idInternalSupportGroup: string
}

export class StartServiceChatHandler extends ChatbarHandler {

    constructor(chatBarParameter: IStartServiceParam) {
        super(chatBarParameter);
    };

    public getNewInteraction(): StartServiceChat {
        const interaction: StartServiceChat = StartServiceChat.factory(super.getParticipant(), this.getComponentParameter().idInternalSupportGroup);
        return interaction;
    }

    public getInteraction(): StartServiceChat {
        return this.getComponentParameter().isEditing
            ? <StartServiceChat>this.getEditingInteraction()
            : this.getNewInteraction();
    }

    public getComponentParameter(): IStartServiceParam {
        return <IStartServiceParam>super.getComponentParameter();
    }

    // TODO;
    public getRollbackTransaction(): IRollbackTransaction {
        return null;
    }
};
