import { Injectable } from '@angular/core';
import { TGlobalUID, TExtendedParticipantArray, TArrayID } from '@colmeia/core/src/core-constants/types';
import { getClock, getDate, isValidRef } from '@colmeia/core/src/tools/utility';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { IParticipantJSON } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { constant} from '@colmeia/core/src/business/constant';
import { Group } from '@colmeia/core/src/business/group';
import { TInteractionArray, Interaction } from '@colmeia/core/src/interaction/interaction';
import { IInteractionJSON, IChainedInteractionJSON } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import {
    IBatchTrackerInteractionRequest,
    ICreateParticipantRequest, IGetGroupAvatars, IGetGroupChildRequest, IGetGroupDataRequest, IGroupCreationRequest,
    IRequest, ISetSerializableMultimediaHeader, ISingleInteractionRequest, ILoginTokenRequest, IGroupEditRequest, GenericRequest
} from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType, ESocialNetworkMessages, ApiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { ELookupType, IGetLookupInformationRequest, IGetLookupAvatarGroupsRequest } from '@colmeia/core/src/request-interfaces/lookup-request';
import { ISetRoleRequest, IRemoveFromGroupRequest, IRemoveUserFromSNRequest } from '@colmeia/core/src/request-interfaces/security-interfaces';
import { SessionService } from './session.service';
import { SusbcriptionContainerService } from './subscriptions.service';
import { HardwareLayerService } from './hardware';
import { IInfraParameters, DefaulActionOnError, IInfraOptions, StandardRejectFromServer } from 'app/model/component/client-infra-comm';
import { SpinType } from './screen-spinner.service';
import { InfraClientCallBack, ClientInfraResponse } from 'app/model/client-infra-comm';
import { ILocationRetrieverRequest } from './location-retriever.service';
import { TISerializableUpdateArray, ISerializableUpdate } from './multimedia.service';
import { InteractionType } from '@colmeia/core/src/interaction/interaction-type';
import { getInteractionThreadInfo } from '@colmeia/core/src/rules/interaction-filter';
import { asConst, genericTypedSuggestions } from '@colmeia/core/src/tools/type-utils';
import { cast } from '@colmeia/core/src/tools/utility/functions/cast';


@Injectable()
export class RequestBuilderServices {

    private sessionSvc: SessionService;
    private subsServices: SusbcriptionContainerService;

    constructor(
        private hardware: HardwareLayerService
    ) { };

    public getGroupID() : TGlobalUID {return this.subsServices.getSelectedGroupID(); };
    public getPlayerID(): TGlobalUID {
        return this.sessionSvc ? this.sessionSvc.getPlayerID() : undefined;
    };

    public getAvatarID(): TGlobalUID {
        return this.sessionSvc ? this.sessionSvc.getAvatarID() : undefined;
    };

    // Problema cíclico
    public setSessionService(sessionService: SessionService): void {
        this.sessionSvc = sessionService;
    };

    public setDependencySubscribeServices(subsServices: SusbcriptionContainerService): void {
        this.subsServices = subsServices;
    };

    public secureBasicRequestForCustomGroup<RequestType extends string>(requestType: RequestType, customGroupID: TGlobalUID): GenericRequest<RequestType> {
        const normalRequest: IRequest = this.secureBasicRequest(requestType);

        normalRequest.idCurrentGroup = customGroupID;

        return normalRequest as GenericRequest<RequestType>;
    }

    public secureBasicRequestForCurrentSN(requestType: string): IRequest {
        return this.secureBasicRequestForCustomGroup(requestType, this.sessionSvc.getCurrentSocialNetworkID());
    }

    public secureBasicRequest<RequestType extends string, Additional extends object>(requestType: RequestType, additional?: Additional) {
        return genericTypedSuggestions<IRequest>()(
            {
                ...this.createRequest(
                    requestType,
                    this.sessionSvc ? this.sessionSvc.getPlayerID() : undefined,
                    this.sessionSvc ? this.sessionSvc.getAvatarID() : undefined,
                ),
                ...(isValidRef(additional) ? additional : {}) as Additional
            },
        );
    }

    public getLookupRequest(lookupType: ELookupType, cursor: string): IGetLookupInformationRequest {
        return {...this.createRequest(
                apiRequestType.lookup,
            this.sessionSvc ? this.sessionSvc.getPlayerID() : undefined,
            this.sessionSvc ? this.sessionSvc.getAvatarID() : undefined
            ),
            lookupType: lookupType,
            cursor
        }
    }

    public getAvatarGroupsLookupRequest(idAvatar, cursor: string): IGetLookupAvatarGroupsRequest {
        return {
            ...this.getLookupRequest(ELookupType.getAllGroupFromAvatar, cursor),
            idAvatarToBeSearched: idAvatar
        }
    }

    //// requests
    public createRequest<RequestType extends string>(requestType: RequestType, idPlayer: TGlobalUID, idAvatar: TGlobalUID): IRequest {
        return {
            requestType: requestType,
            dateTime: getClock(),
            timezone: getDate().getTimezoneOffset(),
            idPlayer: idPlayer,
            idCurrentGroup: this.subsServices?.getSelectedGroupID(),
            idAvatar: idAvatar,
            coordinates: this.hardware.getGeolocation().getLastKnownPosition(),
            uid: this.hardware.getDeviceUID(),
            browserId: this.hardware.getBrowserId(),
        };
    };

    public getCreateParticipantRequest(avatar: Avatar, groupId: string): ICreateParticipantRequest {
        const myParticipantJson: IParticipantJSON = {
            primaryID: null,
            idRoles: null,
            idObjectType: constant.objectType.participant,
            idGroup: groupId,
            avatar: avatar.toJSON(),
            serializableTextArray: [],
            clockTick: getClock(),
            visualPriority: 0,
            timeZone: 0,
            idAvatar: avatar.getAvatarID(),
        };

        const createParticipantRequest: ICreateParticipantRequest = {
            ...this.createRequest(apiRequestType.createParticipant, avatar.getParentEntityID(), avatar.getAvatarID()),
            participant: myParticipantJson,

        };
        return createParticipantRequest;
    };

    public getGroupChildRequest(type: number, idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID): IGetGroupChildRequest {
        return {
            ...this.createRequest(apiRequestType.groups.getGroupChilds, idPlayer, idAvatar),
            typeSelection: type,
            idGroup: idGroup,
        };
    };

    public getGroupAvatarsRequest(idGroup: TGlobalUID, idPlayer: TGlobalUID, idAvatar: TGlobalUID, amountItemsPerPage?: number, cursor: string | undefined = undefined): IGetGroupAvatars {
        return {
            ...this.createRequest(apiRequestType.avatar.getGroupAvatars, idPlayer, idAvatar),
            idGroup: idGroup,
            idAvatar: idAvatar,
            cursor,
            amountItemsPerPage
        };
    };

    public getGroupDataRequest(idGroup: TGlobalUID, idPlayer: TGlobalUID,  idAvatar: TGlobalUID, cursor: string): IGetGroupDataRequest {
        return {
            ...this.createRequest(apiRequestType.groups.getGroupData, idPlayer, idAvatar),
            idGroup: idGroup,
            cursor: cursor,
            getInteractions: true,
            processChildren: true
        };
    };

    public getUpdateGroupRequest(group: Group, avatar: Avatar): IGroupEditRequest {
        const req: IGroupEditRequest = {
            ...this.createRequest(
                apiRequestType.groups.editGroup,
                avatar.getParentEntityID(),
                avatar.getAvatarID()
            ),
            avatar: avatar.toJSON(),
            group: group.toJSON(),
        };

        return req;
    }

    public getCreateGroupRequest(
        group: Group,
        avatar: Avatar,
        selectedExtendedParticipants: TExtendedParticipantArray,
        additional?: Partial<IGroupCreationRequest>
    ): IGroupCreationRequest {
        const req: IGroupCreationRequest = {
            ...this.createRequest(apiRequestType.groups.createGroup,
                avatar.getParentEntityID(),
                avatar.getAvatarID()),
            group: group.toJSON(),
            avatar: avatar.toJSON(),
            selectedAvatars: [],
            ...additional,
        };
        for (const extPart of selectedExtendedParticipants) {
            if (extPart.getObjectTypeID() == constant.objectType.avatar) {
                req.selectedAvatars.push({idChosenAvatar: extPart.getPrimaryID()});
            }
        }
        return req;
    };

    public getBatchInsertTrackerInteractionRequest(avatar: Avatar, interactionArray: TInteractionArray): IBatchTrackerInteractionRequest {
        const ret: IBatchTrackerInteractionRequest =  {
            ...this.createRequest(apiRequestType.batchInsertInteractions, avatar.getParentEntityID(), avatar.getAvatarID()),
            interactionArray: [],
        };
        for (const interaction of interactionArray) {
            ret.interactionArray.push(interaction.toJSON());
        };
        return ret;
    };

    public getBasicInteractionRequest(interaction: IInteractionJSON, crc: number,
                                      idPlayer: TGlobalUID, idAvatar: TGlobalUID, idGeneralAnswer: TGlobalUID): ISingleInteractionRequest {
        return {
            ...this.createRequest(apiRequestType.participantInteraction, idPlayer, idAvatar),
            interaction: interaction,
            crc: crc,
            idGeneralAnswer: idGeneralAnswer
        };
    };

    private addThreadInfoToInteraction(json:  IInteractionJSON): void {
        if (InteractionType.staticFactory(json.idInteractionType).isChainedOrTreeWithParent(json)) {
            const aux =  <IChainedInteractionJSON>(json);
            json.threadInfo = getInteractionThreadInfo(aux.idInteractionParent);
        };
    }

   private add360Provider(json: IInteractionJSON): void {
       if (InteractionType.staticFactory(json.idInteractionType).isChainedOrTreeWithParent(json)) {
           const aux =  <IChainedInteractionJSON>(json);
           if (UberCache.testCache(aux.idInteractionParent)) {
               const parent = <Interaction>UberCache.unsafeUberFactory(aux.idInteractionParent);
               json.e360Provider = parent.get360Providers();
           }
       };
   }

    public getInteractionRequest(interaction: Interaction, idGeneralAnswer: TGlobalUID): ISingleInteractionRequest {
        const json: IInteractionJSON = interaction.toJSON();
        this.addThreadInfoToInteraction(json);
        // this.add360Provider(json);

        return {
            ...this.getBasicInteractionRequest(json,
                32,
                interaction.getParentEntityID(),
                interaction.getParticipant().getAvatar().getAvatarID(), idGeneralAnswer),
        };
    };

    public getJSONInteractionRequest(json: IInteractionJSON, idGeneralAnswer: TGlobalUID): ISingleInteractionRequest {
        if (InteractionType.staticFactory(json.idInteractionType).isChainedOrTreeWithParent(json)) {
            const aux =  <IChainedInteractionJSON>(json);
            if (UberCache.testCache(aux.idInteractionParent)) {
                const parent = <Interaction>UberCache.unsafeUberFactory(aux.idInteractionParent);
                if (parent.hasThreadInfo()) {
                    json.threadInfo = parent.getThreadInfo();
                };
            };
        };

        return {
            ...this.getBasicInteractionRequest(json,
                32,
                json.participant.avatar.idParentEntity,
                json.participant.avatar.primaryID, idGeneralAnswer),
        };
    };


    public getRemoveFromGroupRequest(idGroup: string, idAvatar: string): IRemoveFromGroupRequest {
        return {
            ...this.secureBasicRequest(ESocialNetworkMessages.removeFromGroup),
            idGroupToBeRemoved: idGroup,
            idAvatarToBeRemoved: idAvatar
        }
    }

    public getRemoveFromSNRequest(idAvatar: string): IRemoveUserFromSNRequest {
        const snId = this.sessionSvc.getCurrentSocialNetworkID();
        return {
            ...this.getRemoveFromGroupRequest(snId, idAvatar),
            requestType: ESocialNetworkMessages.removeFromThisToBellow,
            removeFromAllTree: true
        }
    }

    public getSecureDefaultInfraParameters(): IInfraParameters {
        return this.getDefaultInfraParameters(
            this.sessionSvc.getPlayerID(),
            this.sessionSvc.getAvatarID());
    }

    public getDefaultInfraParameters(idPlayer: TGlobalUID, idAvatar: TGlobalUID): IInfraParameters {
        return {
            clientCallback: null,
            defaultActionOnError: DefaulActionOnError.ignore,
            optionButtons: [],
            spinType: SpinType.none,
            idPlayer: idPlayer,
            idAvatar: idAvatar
        };
    }

    public getInfraParameters(callBack: InfraClientCallBack, idPlayer: TGlobalUID, idAvatar: TGlobalUID,
                              infraOptions: IInfraOptions): IInfraParameters {
        return {idPlayer: idPlayer,
            idAvatar: idAvatar,
            clientCallback: callBack,
            ...infraOptions};
    };

    public getNoCallBackSpinnningParameters(idPlayer: TGlobalUID, idAvatar: TGlobalUID): IInfraParameters {
        return {
            ...this.getDefaultInfraParameters(idPlayer, idAvatar),
            defaultActionOnError: DefaulActionOnError.standardErrorMessage,
            spinType: SpinType.fullScreen,
        };
    };

    public getNoCallBackNoSpinnningParameters(idPlayer: TGlobalUID, idAvatar: TGlobalUID): IInfraParameters {
        return {
            ...this.getDefaultInfraParameters(idPlayer, idAvatar),
            defaultActionOnError: DefaulActionOnError.standardErrorMessage,
        };
    };

    public getNoDelegateNoSpinningParameters(idPlayer: TGlobalUID, idAvatar: TGlobalUID): IInfraParameters {
        return {
            ...this.getDefaultInfraParameters(idPlayer, idAvatar),
        };
    };

    public getNoDelegateSpinningParameters(idPlayer: TGlobalUID, idAvatar: TGlobalUID): IInfraParameters {
        return {
            ...this.getDefaultInfraParameters(idPlayer, idAvatar),
            defaultActionOnError: DefaulActionOnError.ignore,
            spinType: SpinType.fullScreen,
        };
    };

    // response
    public buildInfraClientResponse(response: IResponse, friendlyMessage: FriendlyMessage,
                                    button: TGlobalUID): ClientInfraResponse {
        return {
            friendlyMessage: friendlyMessage,
            response: response,
            responseButton: button,
            executionOK: friendlyMessage ? friendlyMessage.isOk() : false
        };
    };

    public getStandardRejectionMessage(frienlyError: FriendlyMessage): StandardRejectFromServer {
        return {friendlyError: frienlyError};
    }


    public createRequestFromInfraParameters<T extends IRequest>(requestType: string, infraParameters: IInfraParameters): T {
        return cast(this.createRequest(requestType, infraParameters.idPlayer, infraParameters.idAvatar));
    };

    public createRequestFromInfraParametersForGroup(requestType: string, infraParameters: IInfraParameters, idGroup: string): IRequest {
        const request = this.createRequestFromInfraParameters(requestType, infraParameters);
        request.idCurrentGroup = idGroup;
        return request;
    }

    public buildSimpleInfraResponse(success: boolean): ClientInfraResponse {
        return {executionOK: success, friendlyMessage: null, response: null, responseButton: null};
    };



    // Gera uma série de requests headers para o processo de init sem os dados mínimos solicitados pelas
    // outras requisições
    public createGuestRequest(requestType: string): IRequest {
        return {
            requestType: requestType,
            dateTime: getClock(),
            timezone: getDate().getTimezoneOffset(),
            idPlayer: null,
            idCurrentGroup: null,
            idAvatar: null,
            coordinates: null,
            uid: this.hardware.getDeviceUID(),
            browserId: this.hardware.getBrowserId(),
        }
    }

    public createAuthRequestToAuthService(requestType: string): ILoginTokenRequest {
        return {
            ...this.createGuestRequest(requestType),
            isMobile: this.hardware.isMobile()
        };
    };

    //// new infra requests

    public getContextInfraParameters(callBack: InfraClientCallBack, infraOptions: IInfraOptions): IInfraParameters {
        return {idPlayer: this.getPlayerID(),  idAvatar: this.getAvatarID(),   clientCallback: callBack,
            ...infraOptions};
    };


    public getContextDefaultInfraParameters(): IInfraParameters {
        return {
            clientCallback: null,
            defaultActionOnError: DefaulActionOnError.ignore,
            optionButtons: [],
            spinType: SpinType.none,
            idPlayer: this.getPlayerID(),
            idAvatar: this.getAvatarID(),
        };
    };

    public getContextNoCallBackSpinnningParameters(): IInfraParameters {
        return {
            ...this.getContextDefaultInfraParameters(),
            defaultActionOnError: DefaulActionOnError.standardErrorMessage,
            spinType: SpinType.fullScreen,
        };
    };

    public getContextNoCallBackNoSpinnningParameters(): IInfraParameters {
        return {
            ...this.getContextDefaultInfraParameters(),
            defaultActionOnError: DefaulActionOnError.standardErrorMessage,
        };
    };

    public getContextNoDelegateNoSpinningParameters(): IInfraParameters {
        return {
            ...this.getContextDefaultInfraParameters(),
        };
    };

    public getContextNoDelegateSpinningParameters(): IInfraParameters {
        return {
            ...this.getContextDefaultInfraParameters(),
            defaultActionOnError: DefaulActionOnError.ignore,
            spinType: SpinType.fullScreen,
        };
    };


    public getSetRoleRequest(
        idPlayer: TGlobalUID,
        idAvatar: TGlobalUID,
        idParticipantGrantor: TGlobalUID,
        idAvatarGrantee: TGlobalUID,
        idGroup:    TGlobalUID,
        idNewRoles: TArrayID,
    ): ISetRoleRequest {
        return {
            ...this.createRequest(apiRequestType.security.setRole, idPlayer, idAvatar),
            idParticipantGrantor,
            idAvatarGrantee,
            idGroup,
            idNewRoles
        };
    };

    getLocationsRetrieverRequest(
        retrieverData: ILocationRetrieverRequest
    ): ILocationRetrieverRequest {
        return {
            ...this.secureBasicRequest(apiRequestType.security.getTrackerEvents),
            ...retrieverData,
        }
    }

    getSendFormFilledMultimediaHeaderRequest(responseUpload: TISerializableUpdateArray): ISetSerializableMultimediaHeader {
        return {
            ...this.secureBasicRequest(apiRequestType.multimedia.setMultimediaHeader),
            multimediaHeaders: responseUpload.map((resUpload: ISerializableUpdate) => ({
                idObjectType: constant.objectType.interaction,
                primaryID: resUpload.serializable.getPrimaryID(),
                multimediaObject: resUpload.multimediaObject.toJSON(),
            }))
        };
    };

};

