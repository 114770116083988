import {Injectable} from "@angular/core";
import {BaseUpdater} from "../shared/base-updater";
import {CodePushCordovaPlugin, ILocalPackage, InstallMode, IRemotePackage, Window} from "./cordova-plugins-definitions";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import {IVersionWarning} from "@colmeia/core/src/core-constants/version-control";
import {allSeverityRegistry} from "@colmeia/core/src/core-constants/tracker-qualifiers";
import {EHardwareUpdateDeviceType} from "../hardware-interfaces";
import {SignalPublisherService} from "../../signal/signal-publisher";
import { MatSnackBar } from "@angular/material/snack-bar";

declare var window: Window;

@Injectable({
    providedIn: 'root'
})
export class CordovaUpdater extends BaseUpdater {

    private desiredHash: string = null;

    private debugInfo = {
        success: true,
        desiredHash: null,
        check: {
            init: false,
            finished: false,
            foundHash: null
        },
        download: {
            init: false,
            finished: false,
        },
        install: {
            init: false,
            finished: false
        },
        release: {
            init: false,
            finished: false
        }
    };

    constructor(
        snackSvc: MatSnackBar,
        emissor: SignalPublisherService
    ) {
        super(snackSvc, emissor);
        document.addEventListener("deviceready", () => {
            this.release();
        });
    }

    async update(warn: IVersionWarning): Promise<void> {
        try {
            this.debugInfo.desiredHash = this.desiredHash = warn.desiredHash;
            const pkg: IRemotePackage = await this.checkForUpdates();
            if (this.isValidPackage(pkg)) {
                const localPkg: ILocalPackage = await this.downloadPackage(pkg);
                await this.installPackage(localPkg);
                this.showUpdatedDialog(EHardwareUpdateDeviceType.Mobile);
            }
        } catch (e) {
            this.debugInfo.success = false;
            this.persistor.sendErrorEvent(e, allSeverityRegistry.error,'CordovaUpdater::update', this.debugInfo);
        } finally {
            if (this.debugInfo.success) {
                this.persistor.sendDebug('CordovaUpdater::update', this.debugInfo);
            }
        }
    }

    get CodePush(): CodePushCordovaPlugin {
        return window.codePush;
    }

    private release = () => {
        this.debugInfo.release.init = true;
        this.CodePush.notifyApplicationReady(() => {
            this.debugInfo.release.finished = true;
        });
    };

    private async checkForUpdates() : Promise<IRemotePackage> {
        return new Promise<IRemotePackage>((resolve, reject) => {
            this.debugInfo.check.init = true;
            this.CodePush.checkForUpdate((pkg: IRemotePackage) => {
                resolve(pkg);
            }, this.onUpdateCheckFailed);
        });
    }

    private async downloadPackage(pkg: IRemotePackage): Promise<ILocalPackage> {
        return new Promise<ILocalPackage>((resolve, reject) => {
            this.debugInfo.download.init = true;
            pkg.download((lpkg: ILocalPackage) => {
                this.debugInfo.download.finished = true;
                resolve(lpkg);
            }, this.onPackageDownloadError)
        });
    }

    private isValidPackage(pkg: IRemotePackage): boolean {
        const valid: boolean = isValidRef(pkg) && pkg.packageHash === this.desiredHash;
        this.debugInfo.check.foundHash = (valid) ? pkg.packageHash : null;
        this.debugInfo.check.finished = true;
        return valid;
    }

    private onUpdateCheckFailed = (error) => {
        throw new Error("Check for Package failed");
    };

    private async installPackage(pkg: ILocalPackage): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.debugInfo.install.init = true;
            pkg.install((mode: InstallMode) => {
                this.debugInfo.install.finished = true;
                resolve(true);
            }, this.onInstallFailed, {
                installMode: InstallMode.ON_NEXT_RESTART
            } );
        });
    }

    private onPackageDownloadError = (error) => {
        throw new Error("Download Package failed");
    };


    private onInstallFailed = (error) => {
        throw new Error("Install Package failed");
    };

}
