import { Component, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { isEqual, isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { CRMTicketsService } from 'app/crm-service-tickets-view/crm-tickets.service';
import { HardwareLayerService, IStorageLayer } from 'app/services/hardware';
import { SnackMessageService } from 'app/services/snack-bar';

interface ITicketFilterOption {
    nName: string;
    id: string;
    count: number;
}

interface ITicketFilter {
    name: string;
    icon: string;
    options: ITicketFilterOption[];
}

const ticketFiltersStorageKey = 'ticketFilters';
type TSelectedFilters = string[];
interface IQuickFilterConfigs {
    [key: number]: TSelectedFilters
}

@Component({
    selector: 'tickets-view-nav',
    templateUrl: './tickets-view-nav.component.html',
    styleUrls: ['./tickets-view-nav.component.scss']
})
export class TicketsViewNavComponent implements OnInit {
    filters: ITicketFilter[];
    selectedIds: TSelectedFilters = [];

    quickFilterSlots = [1, 2, 3, 4];
    quickFilterConfigs: IQuickFilterConfigs;

    private storage!: IStorageLayer;

    constructor(
        private crmTicketsService: CRMTicketsService,
        private hardwareSvc: HardwareLayerService,
        private snackSvc: SnackMessageService,
    ) {
        this.filters = [
            {
                name: 'Status',
                icon: 'info',
                options: [
                    {
                        nName: "Novo",
                        id: "1",
                        count: 1
                    },
                    {
                        nName: "Aberto",
                        id: "2",
                        count: 8
                    },
                    {
                        nName: "Pendente",
                        id: "3",
                        count: 3
                    },
                    {
                        nName: "Fechado",
                        id: "4",
                        count: 4
                    },
                ]
            },
            {
                name: 'Urgência',
                icon: 'signal_cellular_connected_no_internet_4_bar',
                options: []
            },
            {
                name: 'Severidade',
                icon: 'speed',
                options: []
            },
        ];

        this.storage = this.hardwareSvc.getStorage();
    }

    ngOnInit() {
        this.initQuickFilters();
    }
    
    private initQuickFilters() {
        const quickFilterConfigs: IQuickFilterConfigs = this.storage.getItem(ticketFiltersStorageKey);
    
        if (isValidRef(quickFilterConfigs)) {
            this.quickFilterConfigs = quickFilterConfigs;
        } else {
            this.quickFilterConfigs = {};
        }
    }

    onSelectionChange(event: MatSelectionListChange) {
        for (let option of event.options) {
            if (option.selected) {
                this.selectedIds.push(option.value);
            } else {
                const i = this.selectedIds.indexOf(option.value);

                this.selectedIds.splice(i, 1);
            }
        }
    }

    isOptionSelected(option: ITicketFilterOption): boolean {
        return this.selectedIds.includes(option.id);
    } 

    onClickQuickFilterSlot(slot: number) {
        if (this.slotHasConfig(slot)) {
            this.selectedIds = [...this.quickFilterConfigs[slot]];
        } else {
            this.saveQuickFilter(slot, this.selectedIds);
        }
    }

    private saveQuickFilter(slot: number, selectedIds: TSelectedFilters) {
        const allConfigs: TSelectedFilters[] = Object.values(this.quickFilterConfigs);

        if (allConfigs.length === 0) {
            this.snackSvc.openError("Nenhum filtro selecionado para salvar");
            return;
        }

        if (allConfigs.some(config => isEqual(config, selectedIds))) {
            this.snackSvc.openError("Já existe um filtro dessa configuração");
            return;
        }

        this.quickFilterConfigs[slot] = [...selectedIds];

        this.storeAllQuickFilters();

        this.snackSvc.openSuccess("Filtro salvo com sucesso!");
    }

    clearQuickFilter(slot: number) {
        this.quickFilterConfigs[slot] = undefined;

        this.storeAllQuickFilters();
    }

    slotHasConfig(slot: number): boolean {
        return this.quickFilterConfigs[slot] !== undefined;
    }

    slotIsActive(slot: number): boolean {
        return isEqual(this.quickFilterConfigs[slot], this.selectedIds);
    }

    private storeAllQuickFilters() {
        this.storage.putItem(ticketFiltersStorageKey, this.quickFilterConfigs);
    }

    navigateToTickets() {
        // this.crmTicketsService.navigateToTickets();
    }

    showKanban(event: Event, filter: ITicketFilter) {
        event.stopPropagation();
    }
}
