import { Injectable } from '@angular/core';
import { MultimediaInstance } from '@colmeia/core/src/multi-media/barrel-multimedia';
import {MultimediaService} from "./multimedia.service";
import { Window } from "./hardware/cordova/cordova-plugins-definitions";

declare var window: Window;

@Injectable({
  providedIn: 'root'
})
export class FullScreenVideoService {
    private multimediaSvc: MultimediaService

    constructor(
    ) { }

    setMultimediaService(multimediaSvc: MultimediaService) {
        this.multimediaSvc = multimediaSvc;
    }

    async showVideo(mm: MultimediaInstance) {
        let streamableSignedUrl = await this.multimediaSvc.getMultimediaSignedTempURL(mm.getIdMedia());
        window.plugins.streamingMedia.playVideo(streamableSignedUrl, {shouldAutoClose: false});
    }
}
