<div class="chat-right-bar">
  <section class="status-options">
    <chat-status></chat-status>
    <chat-options></chat-options>
  </section>
  <section>
    <chat-options-attendance *ngIf="shouldShowAttendanceOptions()"></chat-options-attendance>
  </section>
  <section *ngIf="isMobile && shouldShowAttendanceOptions()" class="attendance-finish-transfer">
    <chat-options-attendant-transferable-islands class="icon-only"></chat-options-attendant-transferable-islands>
    
    <chat-options-finish-attendance></chat-options-finish-attendance>
  </section>
</div>
