import { Component, OnInit } from '@angular/core';
import { EUserMessageType, VLReturnTypedMessage } from '@colmeia/core/src/shared-business-rules/user-function/user-function-model';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { CopyToClipboardService } from 'app/services/copy-to-clipboard.service';
import { EAppAlertTypes } from 'app/services/snack-bar';
import { IUserMessageTypeConfig, userMessageTypeConfig } from '../user-functions.service';
import { UserFunctionsMessageHandler } from './user-functions-message.handler';

@Component({
    selector: 'app-user-functions-message',
    templateUrl: './user-functions-message.component.html',
    styleUrls: ['./user-functions-message.component.scss']
})
export class UserFunctionsMessageComponent implements OnInit {

    public copy = 'Copiar';
    public type: EAppAlertTypes;
    public icon: string;
    public message: string;
    public handler: UserFunctionsMessageHandler;
    public get parameters() {
        return this.handler.getComponentParameter();
    }
    
    public constructor(private clipboardSvc: CopyToClipboardService) {}

    public ngOnInit(): void {
        this.init();
    }

    public init(): void {
        const typedMessage: VLReturnTypedMessage = this.parameters?.typedMessage;
        const messageTypeConfig: IUserMessageTypeConfig = userMessageTypeConfig[typedMessage?.type];
        const { type, icon } = isValidRef(messageTypeConfig)
            ? messageTypeConfig
            : userMessageTypeConfig[EUserMessageType.Standard];

        this.type = type;
        this.icon = icon;
        this.message = typedMessage.message;
    }

    public onCopy() {
        this.clipboardSvc.copy(this.message);
    }

}