import { typedFields, typedScreenOptions } from "../../../tools/utility";
import { EAdHocSubMenus } from "../../social-network/social-network-config-ad-hoc-submenus";
import { EContractItemError } from "../../social-network/social-network-config.functions";
import { ESocialNetworkPrimaryCEPSearchType } from "../../social-network/social-network-user-settings.model";
import { getTranslationsFieldsAndScreenOptions, EAllSerializable, getTranslationsFields } from "../all-serializables";

const socialNetwork = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.SocialNetwork,
    fields: {
        listLabel: 1, // 'SNManagement/users' 24 
        selectAll: 2, // 'SNManagement/users' 22 
        removeGroup: 3, // hiveScreen.snManagement.removeGroup 1

        usersLabel: 4,  // 'SNManagement/users' 1
        removeUser: 5,  // 'SNManagement/removeUser' 1
        seeGroups: 6,  // 'SNManagement/seeGroups' 1
    },
} as const);


const socialNetworkInfo = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.SocialNetworkInfo,
    fields: {
        removedFrom: 1, // Removido de
    },
} as const);

const socialNetworkConfig = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.SocialNetworkConfig,
    fields: {
        socialNetworkConfigurations: 1, // Configurations
        socialNetworkConfigurationsIcon: 18, // miscellaneous_services
    },
} as const);

const socialNetworkUserSettings = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.SocialNetworkUserSettings,
    fields: {
        socialNetworkUserSettings: 1, // Definições de usuário
    },
} as const);

export const adHocSubMenusTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.AdHocSubMenus,
    fields: typedFields<EAdHocSubMenus, { IsLimitingFields: true }>()({
        [EAdHocSubMenus.extraction]: 1, // Extração
        [EAdHocSubMenus.elasticSearch]: 2, // Elastic Search
    })
} as const));

const contractItemErrorTranslations = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.ContractItemError,
    fields: typedFields<EContractItemError, { IsLimitingFields: true }>()({
        [EContractItemError.expired]: 1, // Acesso expirado
    })
} as const));

const socialNetworkPrimaryCEPSearchType = getTranslationsFields(typedScreenOptions({
    idSerializable: EAllSerializable.SocialNetworkPrimaryCEPSearchType,
    fields: typedFields<ESocialNetworkPrimaryCEPSearchType, { IsLimitingFields: true }>()({
        [ESocialNetworkPrimaryCEPSearchType.cep]: 1, // via CEP
        [ESocialNetworkPrimaryCEPSearchType.google]: 2, // Google
    })
} as const));

export const socialNetworkTranslations = {
    ...socialNetworkInfo.translations,
    ...socialNetwork.translations,
    ...socialNetworkConfig.translations,
    ...adHocSubMenusTranslations,
    ...contractItemErrorTranslations,
    ...socialNetworkPrimaryCEPSearchType,
    ...socialNetworkUserSettings.translations
};