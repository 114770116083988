import { Injectable } from '@angular/core';
import { TGlobalUID, constant } from '@colmeia/core/src/business/constant';
import { Group } from '@colmeia/core/src/business/group';
import { IdGroup } from '@colmeia/core/src/core-constants/named-types/named-primary-ids';
import { EYouTaskRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { EYouTaskRequestType, EYouTaskStatus, EYouTasksType, IYouTaskGroupApprovalClient, IYouTasksServer, TAllYouTasksNser, TAllYouTasksServer, TSolicitationGroupIdToTask } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { IYouTaskGetOneRequest, IYouTaskGetOneResponse, IYouTaskSaveBatchRequest, IYouTaskSaveBatchResponse, IYouTaskSaveRequest, IYouTaskSaveResponse } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-req-res.dto';
import { YouTaskCoreService } from '@colmeia/core/src/shared-business-rules/you-control/user-task-core.service';
import { keys } from '@colmeia/core/src/tools/barrel-tools';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SessionService } from 'app/services/session.service';

@Injectable({
    providedIn: 'root'
})
export class YouTasksClientService {
    private onlySolicitationsFromChildGroups: TSolicitationGroupIdToTask = {};

    constructor(
        private sessionSvc: SessionService,
        private api: ServerCommunicationService,
    ) { }

    getGroupSolicitationStatus(groupId: string): EYouTaskStatus | undefined {
        return this.onlySolicitationsFromChildGroups[groupId]?.solicitationStatus
    }

    public setSolicitationsForGroups(solicitations: TSolicitationGroupIdToTask) {
        this.addSNToSolicitations(solicitations);
        this.onlySolicitationsFromChildGroups = solicitations;
    }

    addSNToSolicitations(solicitations: TSolicitationGroupIdToTask) {
        keys(solicitations).forEach(idGroup => {
            const solicitation = solicitations[idGroup];
            const idSN = this.getRootFromSolicitation(solicitation);
            if (!idSN) return;
            solicitations[idSN] = solicitation;
        });
    }

    getRootFromSolicitation(solicitation: IYouTasksServer) {
        if (solicitation.details.taskType !== EYouTasksType.socialNetworkParticipationApproval) return;
        return solicitation.details.targetIdSocialNetwork;
    }

    public initOnBoardingNSClient(
        idGroup: IdGroup,
        group: Group | undefined,
    ): IYouTaskGroupApprovalClient {
        const groupName: string | undefined = group?.getName();
        const player = this.sessionSvc.getPlayer();
        const nName: string = player.getEmail();
        const avatar = this.sessionSvc.getSelectedAvatar();
        const currentIdSN = this.sessionSvc.getCurrentSocialNetworkID() ?? constant.entity.rootGroups.root;
        const idSN = group?.getMySocialNetworkID() ?? undefined;
        
        const solicitationNS: IYouTaskGroupApprovalClient = YouTaskCoreService.factoryGroupApprovalNser({
            nName,
            groupName,
            idGroup,
            idSocialNetwork: currentIdSN,
            targetIdSocialNetwork: idSN,
            tagsIds: [],
            idAvatar: avatar.getPrimaryID(),
            ident: {
                genealogy: [currentIdSN],
                idAvatar: this.sessionSvc.getSelectedAvatarID(),
                idGroup: currentIdSN,
            },
            solicitantName: avatar.getName() || avatar.getNickName(),
            taskType: this.getTaskType(idGroup)
        });

        return solicitationNS;
    }

    getTaskType(idGroup: TGlobalUID) {
        const sn = this.sessionSvc.getCurrentSocialNetworkID() || constant.entity.rootGroups.root;
        if (idGroup === sn || sn === constant.entity.rootGroups.root) {
            return EYouTasksType.socialNetworkParticipationApproval;
        }
        return EYouTasksType.groupParticiparionApproval
    }

    async getSolicitationByID(idNS: IdDep<ENonSerializableObjectType.yourTasks>) {
        return await this.api.sendRequest<IYouTaskGetOneRequest, IYouTaskGetOneResponse>(EYouTaskRequest.getOne)({
            idNS
        });
    }

    async saveTasks(ns: TAllYouTasksNser, taskRequestUserType: EYouTaskRequestType): Promise<IYouTaskSaveResponse | undefined> {
        return await this.api.sendRequest<IYouTaskSaveRequest, IYouTaskSaveResponse>(EYouTaskRequest.save)({
            ns,
            taskRequestUserType,
            //@ts-ignore
            multipleCursor: null
        });
    }

    async saveTasksInBatch(nss: TAllYouTasksServer[], adminAnswer: EYouTaskStatus): Promise<IYouTaskSaveBatchResponse | undefined> {
        return await this.api.sendRequest<IYouTaskSaveBatchRequest, IYouTaskSaveBatchResponse>(EYouTaskRequest.saveBatch)({
            nsList: nss,
            adminAnswerClicked: adminAnswer,
            //@ts-ignore
            multipleCursor: null
        });
    }
}
