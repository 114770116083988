<cm-date-picker
    #customRangePicker
    [handler]="customTimeWindowDateRangePicker"
></cm-date-picker>

<button mat-stroked-button [loading]="loading" color="primary" class="dense" [matMenuTriggerFor]="fieldsMenu">
    <mat-icon class="mat-18">schedule</mat-icon>
        &nbsp;
    <span style="font-size: 12px;font-weight: bold;">{{ getTimeWindowLabel() }}</span>
    <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
</button>


<mat-menu #fieldsMenu="matMenu" class="dense">
    <ng-container *ngFor="let timeWindowField of timeWindowFields">
        <button mat-menu-item class="gs-ticket-menu-btn" [matMenuTriggerFor]="timeWindow">
            <span [class.selected]="selectedTimeWindow.field === timeWindowField" class="group-label">{{ timeWindowFieldLabels[timeWindowField] }}</span>
        </button>


        <mat-menu #timeWindow="matMenu" class="dense">
            <ng-container *ngFor="let timeWindowConfig of timeWindowOptions">
                <button mat-menu-item class="gs-ticket-menu-btn" (click)="setTimeWindowConfig(timeWindowField, timeWindowConfig)">
                    <span class="group-label">{{ getTimeWindowLabel(timeWindowConfig, false) }}</span>
                </button>
            </ng-container>

            <button *ngIf="!hideCustomSelection"
                mat-menu-item
                class="gs-ticket-menu-btn"
                (click)="handleCustomClick($event, timeWindowField, customRangePicker);"
            >
                <span class="group-label">Intervalo</span>
            </button>

        </mat-menu>

    </ng-container>
</mat-menu>
