import { MatSnackBarRef } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-async-job-process-alert',
    templateUrl: './async-job-process-alert.component.html',
    styleUrls: ['./async-job-process-alert.component.scss']
})
export class AsyncJobProcessAlertComponent implements OnInit {

    constructor(
        private snackRef: MatSnackBarRef<AsyncJobProcessAlertComponent>
    ) { }

    ngOnInit(): void {
    }

    close() {
        this.snackRef.dismiss();
    }
}
