import { TGlobalUID } from "../core-constants/types";

export enum EDeviceType {
    Phone = 1,
    Browser = 2
}

export interface IPushNotificationPolicy {
    idPolicy: string;
    idPlayer: TGlobalUID;
    clockTick: number;
    sleepUntil: number;
};


export type TNotificationDeviceArray = Array<INotificationDevice>;


export type TIPushNotificationArray = Array<IPushNotificationToken>;

export interface IPushNotificationToken {
    token: string;
    type: EDeviceType;
    deviceUID: string;
}

export interface INotificationDeviceHeader extends IPushNotificationToken {
    deviceName: string;
    cancelClock: number;
}


export interface INotificationDevice extends INotificationDeviceHeader {
    idPlayer: string;
    idNotificationDevice: string;
    clockTick: number;
};



export interface IUpdatePushNotificationDeviceProperties {
    uniqueID: string;
    deactivateClockTick?: number;
    excludedClockTick: number;
}

export enum EPNEditableFields {
    SleepUntil = 'sleepUntil'
}
