import { EDelivery360Action } from "../comm-interfaces/interaction-interfaces";
import { getConditionValueTokens, isNumberEligibleCondition, isValidBasicCondition, validateDateFormat, validateDateValue } from "../shared-business-rules/bot/conditional-functions";
import { EBPMAction, IBPMActionModel, IBPMValidatorAction } from "../shared-business-rules/BPM/bpm-action-model";
import { IBPConditionalEvaluator } from "../shared-business-rules/BPM/bpm-model";
import { CONDITION_TOKEN_DELIMITER, EMetaEngagementConditionType, IBasicCondition } from "../shared-business-rules/metadata/meta-engagement";
import { TComputedInfo } from "../shared-business-rules/metadata/metadata-utils";
import { isInvalid, isInvalidValidTrimmedString, isNumeric, isValidAndEqual, isValidArray, isValidRef, isValidRegex, isValidString, isValidTrimmedString, noAccentsAndLower } from "../tools/utility";
import { TCheckFieldConditionResult } from "./general-form-interface";

export type TValidateFunction = (cond: IBasicCondition, v: IConditionalData) => Promise<boolean>

export interface IBotFullState {
    computed: TComputedInfo;
    channel?: EDelivery360Action;
    target?: string;
    address?: string;
}



export interface IConditionalData extends IBotFullState {
    currentValue: string;
}


export class ConditionsProcessor {

    public static async checkFieldCondition(validator: IBPConditionalEvaluator, v: IConditionalData, validateCondition: TValidateFunction): Promise<TCheckFieldConditionResult> {
        if (!isValidRef(validator)) {
            return {
                hasAction: false,
            };
        }

        const condition = await ConditionsProcessor.getMatchCondition(v, validator, validateCondition);
        const sucess: boolean = isValidRef(condition);
        const errorMsg = !sucess ? ConditionsProcessor.getErrorMsg(validator.action) : ''

        return {
            errorMsg,
            isSuccess: sucess,
            hasAction: true,
        };
    };

    public static getErrorMsg(action: IBPMActionModel): string {
        switch (action.bpmAction) {
            case EBPMAction.validator: {
                return (<IBPMValidatorAction>action).onError
                    .map(error => error.variablesTemplate.compiledTemplate)
                    .reduce((curr, acc) => `${curr}${acc}`, '')
            }
            default:
                break;
        }

        return ''
    }

    public static async getMatchCondition<T extends IBPMActionModel>(v: IConditionalData, evaluator: IBPConditionalEvaluator, validateCondition: TValidateFunction): Promise<T> {
        if (isInvalid(v.currentValue) && isInvalid(v.computed)) {
            return undefined;
        }

        for (const cond of evaluator.matchConditions) {
            if (!isValidBasicCondition(cond, evaluator.action.bpmAction)) {
                continue;
            }

            let isSuccess: boolean = false;
            let count: number = 0;

            do {
                const cond: IBasicCondition = evaluator.matchConditions[count];
                if (isValidString(cond.idInformationSource)) {
                    const infoSource = v.computed[cond.idInformationSource]
                    v.currentValue = noAccentsAndLower(infoSource?.toString());
                } else {
                    v.currentValue = noAccentsAndLower(v.currentValue?.toString());
                }
                isSuccess = await validateCondition(cond, v)
            } while (isSuccess && ++count < evaluator.matchConditions.length);

            if (isSuccess) {
                return <T>(evaluator.action);
            }
        }
        return undefined;
    }

    public static validateCondition(cond: IBasicCondition, v: IConditionalData): boolean {
        const condition = noAccentsAndLower(cond.condition);
        const canImputBeNumber: boolean = isNumeric(v.currentValue);
        const isNumberCondition = isNumberEligibleCondition(<EMetaEngagementConditionType>cond.metaConditionType) && isNumeric(condition)
        const numberComparison: boolean = canImputBeNumber && isNumberCondition;

        let ret: boolean = false;
        switch (cond.metaConditionType) {

            case EMetaEngagementConditionType.isAllwaysTrue: {
                ret = true;
            };
                break;

            case EMetaEngagementConditionType.isChannel: {
                const list = condition.split(CONDITION_TOKEN_DELIMITER);
                ret = list.includes(v.channel)
            };
                break;

            case EMetaEngagementConditionType.notNull: {
                ret = isValidTrimmedString(v.currentValue);
            };
                break;

            case EMetaEngagementConditionType.metaNull: {
                ret = isInvalidValidTrimmedString(v.currentValue);
            };
                break;

            case EMetaEngagementConditionType.interval: {
                const list = getConditionValueTokens(condition);
                const isListNumber: boolean = canImputBeNumber && list.every((v) => { return isNumeric(v) });
                const limits = list.map((e) => { return isListNumber ? Number(e) : e });
                ret = isListNumber ?
                    (Number(v.currentValue) >= (limits[0] as number) && Number(v.currentValue) <= (limits[1] as number)) :
                    v.currentValue >= limits[0] && v.currentValue <= limits[1];

            };
                break;

            case EMetaEngagementConditionType.contains: {
                const values = getConditionValueTokens(condition);
                ret = values.some((e) => { return v.currentValue.includes(e) })

            };
                break;

            case EMetaEngagementConditionType.literal: {
                ret = numberComparison ? Number(v.currentValue) === Number(condition) :
                    v.currentValue === condition
            };
                break;

            case EMetaEngagementConditionType.greater: {
                ret = numberComparison
                    ? Number(v.currentValue) > Number(condition)
                    : v.currentValue > condition
            }
                break;

            case EMetaEngagementConditionType.smaller: {
                ret = numberComparison ? Number(v.currentValue) < Number(condition) :
                    v.currentValue < condition
            };
                break;

            case EMetaEngagementConditionType.list: {
                const list = getConditionValueTokens(condition);
                const isListNumber: boolean = canImputBeNumber && list.every((v) => { return isNumeric(v) });
                ret = list.some((x) => { return isListNumber ? Number(v.currentValue) === Number(x) : v.currentValue == x })
            }
                break;


            case EMetaEngagementConditionType.regex: {
                if (isValidRegex(cond.condition)) {
                    const regex = new RegExp(cond.condition);
                    ret = regex.test(v.currentValue);
                }

            };
                break;
            case EMetaEngagementConditionType.dateValidation: {
                ret = validateDateFormat(v.currentValue, cond.dateValidation.format);

                if (ret && isValidArray(cond.dateValidation.validation)) {
                    ret = validateDateValue(v.currentValue, cond.dateValidation);
                }

            };
                break;
        }
        const invert: boolean = isValidAndEqual(cond.reverseCondition, true);
        return invert ? !ret : ret;


    }

}