


import { $SharedServices } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services";
import { isAllowingTemplateDelete } from "@colmeia/core/src/shared-business-rules/social-media/social-media.functions";
import { IWhatsAppSocialMediaConnectionServer, TDeliveryProvidersWithWhatsApp } from "@colmeia/core/src/shared-business-rules/social-media/social-media.model";
import { $any, GetByKey, Narrow, NoInfer, UnionToIntersection } from "@colmeia/core/src/tools/utility-types";
import { addFnNameOnSource, ToStatic, toStatic } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { $Narrow, $NarrowV2, CNarrow, KeyOfListWithPreservedOrder, Toolbelt, ToolbeltPure, ValueOfListWithPreservedOrder } from "@colmeia/core/src/tools/utility/types/validate";
import { DeliveryProvider } from "../../../core-constants/types";
import { ENonSerializableObjectType } from "../../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../../non-serializable-id/non-serializable-types";
import { INSWhatsAppTemplateServer, ITemplateProvider, IWhatsAppTemplateApproval } from "../../social-media/template-model";
import { notImplementedSharedFunction as notImplementedSharedMethod } from "../shared-services.basic-functions";


export namespace TemplateDelete {
    export interface Input<T extends ITemplateProvider = ITemplateProvider> {
        name: string;
        provider: T;
        idConnection: IdDep<ENonSerializableObjectType.socialMediaConnection>;
    }

    export interface WhatsAppInput extends Input<IWhatsAppTemplateApproval> {}

    export type Output = boolean;
}



class Service {
    public delete(input: TemplateDelete.Input): Promise<TemplateDelete.Output> {
        notImplementedSharedMethod(input);
    }

    public getUpdatedProvider(
        connection: IWhatsAppSocialMediaConnectionServer,
        template: INSWhatsAppTemplateServer,
    ): Promise<IWhatsAppTemplateApproval | undefined> {
        notImplementedSharedMethod([connection, template]);
    }

}

// 

@ToStatic
export class TemplateSharedService extends toStatic(Service) {
    public static canDelete(provider: DeliveryProvider | undefined): TemplateDelete.Output {
        return isAllowingTemplateDelete(provider);
    }
}


