import { Interaction } from "../../interaction";
import { TGlobalUID, constant } from "../../../business/constant";
import { Participant } from "../../../business/participant";
import { InteractionType } from "../../interaction-type";
import { EGeolocationIntType } from "../../../business/constant.enums";
import { ITurnGpsOnJSON } from "../../../comm-interfaces/interaction-interfaces";
import { UberCache } from "../../../persistency/uber-cache";

export class TurnGPSOn extends Interaction {
    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, InteractionType.staticFactory(EGeolocationIntType.turnGpsOn));
    };
    
    public static getNewTurnGPSOn(participant: Participant): TurnGPSOn {
        return new TurnGPSOn(null, participant)
    }

    public getTurnGPSOnID(): TGlobalUID {
        return this.getPrimaryID();
    }

    public rehydrate(json: ITurnGpsOnJSON): void {
        super.rehydrate(json);
    };

    public toJSON(): ITurnGpsOnJSON {
        return super.toJSON();
    }; 

    public static factoryMessage(json: ITurnGpsOnJSON, participant: Participant = null): TurnGPSOn {
        let interaction: TurnGPSOn = <TurnGPSOn>Interaction.searchCachedInteraction(json.primaryID);
        if (interaction == null)
            interaction = new TurnGPSOn(json.primaryID,
                participant ? participant : Participant.staticFactory(json.participant.primaryID));
        interaction.rehydrate(json);
        return interaction;
    };

}