import { Participant } from "@colmeia/core/src/business/participant";
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { ICacheFeedbackInteraction } from '@colmeia/core/src/comm-interfaces/feedback-interfaces';
import { EConfirmationType, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { ELocalQueueName } from '@colmeia/core/src/core-queue/core-queue-service';
import { ChainedInteraction } from '@colmeia/core/src/interaction/chained-interaction';
import { Feedback } from '@colmeia/core/src/interaction/feedback';
import { Interaction, TInteractionArray } from "@colmeia/core/src/interaction/interaction";
import { DeleteInteraction } from '@colmeia/core/src/interaction/interactions/deleted-interaction';
import { FeedbackFiguresCarrier } from '@colmeia/core/src/interaction/tracker/feedback-carrier-interaction';
import { isFirstLevelInteraction, isSecondLevelInteraction } from '@colmeia/core/src/rules/interaction-filter';
import { cloneArrayKeepingElements, delay, isInvalidArray, isValidArray, isValidRef, safeUnshift } from '@colmeia/core/src/tools/utility';
import { EChatViewStyle } from "../../components/backbone/chat-backbone.component";
import { ChatBackboneModel } from "../../model/chat-backbone.model";
import { clientConstants } from "../../model/constants/client.constants";
import { ContractService } from "../../services/controllers-services/contract-services/contract.service";
import { NavigatorServices } from "../../services/controllers-services/navigator/navigator.service";
import { QueuService } from "../../services/queue.service";
import { SessionService } from "../../services/session.service";
import { IReactBarCallback } from "../react-bar-handler";
import { ReactDisplayBarHandler } from "../react-display-handler";
import {
    IMessageEndPointCallback, IMessageHandlerCallback, MessageInstanceHandler, TIMessageEndPointCallbackArray, TMessageInstanceHandlerArray
} from './message-instance-handler';
import { factoryMessageHandler } from './message-instance-handler-factory';
import { AttendanceService } from "app/services/attendance.service";

interface IMessageHandlerCallbakMap {
    [groupId: string]: MessageHandlerCallbak;
}

export class MessageHandlerCallbak implements IMessageHandlerCallback, IReactBarCallback {

    private static instances: IMessageHandlerCallbakMap = {};
    private msgInstanceEndPointList: TIMessageEndPointCallbackArray = [];
    private rootLevel: boolean;

    // Database
    private servingHandlers: TMessageInstanceHandlerArray;
    private interactionDatabase: TInteractionArray;
    private minBIDStandard: number;
    private toBeArrayHandlers: TMessageInstanceHandlerArray;
    private chatBackbone: ChatBackboneModel;
    private reachedTop: boolean;
    private navigator: NavigatorServices;
    private handlerKey: TGlobalUID;
    private editingInteractin: Interaction;
    private static scrollToID: TGlobalUID;
    private queue: QueuService;
    private contractSvc: ContractService;
    private sessionSvc: SessionService;
    private attendanceSvc: AttendanceService;

    private static extractIdGroupFromComposeKey(composeKey: string): string {
        return composeKey.split('-')[0];
    }

    public static synchronizeWithGroupsBelongsTo(cached: PlayerCachedInfo): void {
        for (const key in MessageHandlerCallbak.instances) {
            const idGroup = MessageHandlerCallbak.extractIdGroupFromComposeKey(key);
            if (!cached.belongsToGroup(idGroup)) {
                delete MessageHandlerCallbak.instances[key];
            }
        }
    }

    public static newInstance(interactionArray: TInteractionArray, chatBackbone: ChatBackboneModel, rootLevel: boolean,
        navigator: NavigatorServices, editingInteraction: Interaction, queue: QueuService,
        sessionSvc: SessionService, contractSvc: ContractService, attendanceSvc: AttendanceService): MessageHandlerCallbak {

        const key: string = MessageHandlerCallbak.composeKey(
            chatBackbone.getGroup().getGroupID(),
            editingInteraction
                ? editingInteraction.getInteractionID()
                : null);

        if (MessageHandlerCallbak.instances[key] == null) {
            MessageHandlerCallbak.instances[key] = new MessageHandlerCallbak(
                key, interactionArray, chatBackbone, rootLevel, navigator, queue, sessionSvc, contractSvc, attendanceSvc);
        }

        const handler = MessageHandlerCallbak.instances[key];
        handler.setEditingInteraction(editingInteraction);
        return handler;
    }

    public static getMessageHandlerCallback(handlerKey: string): MessageHandlerCallbak {
        return MessageHandlerCallbak.instances[handlerKey];
    }

    public static getInstances(): IMessageHandlerCallbakMap {
        return MessageHandlerCallbak.instances
    }

    public getEditingInteraction(): Interaction {
        return this.editingInteractin;
    }

    public static destroyMessageHandlerCallback(handlerKey: string): void {
        const handler: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(handlerKey);
        if (handler) {
            for (const child of handler.getInteractionHandlerArray()) {
                const handlerChild: IMessageHandlerCallback = child.getNextLevelHandler();
                const childkey = handlerChild.getHandlerKey();
                MessageHandlerCallbak.destroyMessageHandlerCallback(childkey);
            };
            handler.destroyDatabases(handlerKey);
        };
    };

    public onNewInteractionArrivedCallback(isChildInteraction: boolean): void { }

    public static composeKey(idGroup: TGlobalUID, idInteraction: TGlobalUID = null): string {
        return idGroup + '-' + (idInteraction ? idInteraction : idGroup);
    }

    private constructor(
        handlerKey: TGlobalUID,
        interactionArray: TInteractionArray,
        chatBackbone: ChatBackboneModel,
        rootLevel: boolean,
        navigator: NavigatorServices,
        queue: QueuService,
        sessionSvc: SessionService,
        contractSvc: ContractService,
        attendanceSvc: AttendanceService) {

        this.queue = queue;
        this.navigator = navigator;
        this.rootLevel = rootLevel;
        this.minBIDStandard = clientConstants.maxBusinessID;
        this.chatBackbone = chatBackbone;
        this.reachedTop = false;
        this.handlerKey = handlerKey;
        this.sessionSvc = sessionSvc;
        this.contractSvc = contractSvc;
        this.attendanceSvc = attendanceSvc;

        if (!isValidArray(this.interactionDatabase)) {
            if (rootLevel) {
                // podemos sair e entrar várias vezes do grupo.. e não podemos zerar o DB neste caso
                this.interactionDatabase = this.filterFirstLevelMessages(interactionArray);

            } else {
                this.interactionDatabase = interactionArray;
            };
        }

        this.interactionDatabase = this.sortInteractions(this.interactionDatabase);
        this.toBeArrayHandlers = null;
        this.servingHandlers = [];

        this.loadCharge();
    };

    public static setScrollToMe(primaryID: TGlobalUID) {
        MessageHandlerCallbak.scrollToID = primaryID;
    }

    public setEditingInteraction(editingInteraction: Interaction): void {
        this.editingInteractin = editingInteraction;
    }

    public getHandlerKey(): TGlobalUID {
        return this.handlerKey;
    }

    private getBestTimestamp(interaction: Interaction): number {
        return this.getDisplayOptions() == EChatViewStyle.topic && this.isRootLevel()
            ? interaction.getProfoundClockTick(this.chatBackbone.getGroup().getGroupID(), 0)
            : interaction.getClockTick();
    };

    private async loadCharge(): Promise<number> {
        if (this.reachedTop) {
            return 0;
        };
        let count: number = 0;
        let addedInteractions: number = 0;
        let interactions: TInteractionArray = [];
        const smoothOn: boolean = this.servingHandlers.length > 0;

        // add on interactions array sorted by interaction.clocktick desc
        const onlyAllowRootLevelPagination = (addedInteractions: number) => this.isRootLevel()
            ? addedInteractions < clientConstants.loadGroups.numberOfInteractionsPerCharge
            : true
        while (count < this.interactionDatabase.length && onlyAllowRootLevelPagination(addedInteractions)) {
            const interaction: Interaction = this.interactionDatabase[count];
            const bestTimestampInteraction: number = this.getBestTimestamp(interaction)

            if (bestTimestampInteraction < this.minBIDStandard) {
                ++addedInteractions;
                this.minBIDStandard = bestTimestampInteraction;
                interactions.push(interaction);
            };
            ++count;
        };

        // se nao adicionou interacoes ainda aqui adiciona interacoes do servidor se for rootLevel e nao for reachedTop
        const shouldAddFromServer = addedInteractions == 0 && isValidArray(this.interactionDatabase)
        if (shouldAddFromServer && this.isRootLevel() && !this.reachedTop) {
            const previousInteractionDatabaseLength: number = this.interactionDatabase.length;
            const groupId: string = this.chatBackbone.getGroup().getGroupID()
            await this.navigator.chargeMoreInteractions(groupId, this.interactionDatabase);

            if (previousInteractionDatabaseLength == this.interactionDatabase.length) {
                this.reachedTop = true;
            } else {
                this.interactionDatabase = this.filterFirstLevelMessages(this.interactionDatabase);
                this.interactionDatabase = this.sortInteractions(this.interactionDatabase);

                const innerAdded = await this.loadCharge();

                this.callMessageContainerChangeDetector(true);

                return innerAdded;
            };
        } else {
            // ja adicionou interacoes do servidor
            interactions = this.sortInteractions(interactions);
            interactions = await this.chatBackbone.getFilteredInteractionsByInteractions(interactions)

            // antes do change detector funcionar com os resultados parciais, precisamos ter um vetor funcional
            // que possa ser usado para colocar as quebras de avatar
            this.toBeArrayHandlers = cloneArrayKeepingElements<TMessageInstanceHandlerArray>(this.servingHandlers);
            const isEmbed = this.sessionSvc.isEmbeddedChat();

            for (const interaction of interactions) {

                // Esta validação foi inserida porque duplicava as mensagens quando scrollava no embedded.
                const alreadyAdded = this.servingHandlers.some(sh => sh.getInteraction().getPrimaryID() === interaction.getPrimaryID())
                if (alreadyAdded) {
                    continue;
                } else {
                    const handler: MessageInstanceHandler = this.createNewMessageHandler(interaction)
                    this.chatBackbone.addToAllHandlers(handler);
                    this.toBeArrayHandlers = safeUnshift(this.toBeArrayHandlers, handler);
                    this.servingHandlers = safeUnshift(this.servingHandlers, handler);

                    if (smoothOn) {
                        this.callMessageContainerChangeDetector(false);
                        await delay(clientConstants.UI.smoothScrollFeeling);
                    }
                }
            }
        }

        this.toBeArrayHandlers = null;
        return addedInteractions;
    };

    private filterFirstLevelMessages(interactions: TInteractionArray): TInteractionArray {
        const isChatView = this.chatBackbone.getDisplayOptions().viewStyle == EChatViewStyle.chat;
        return interactions.filter(i => isFirstLevelInteraction(i, isChatView));
    }

    public getRecursevelyHandlerForInteraction(interaction: Interaction): MessageInstanceHandler {
        let handler: MessageInstanceHandler = this.servingHandlers.find((h) => { return h.getInteraction().iss(interaction) });
        if (!handler) {
            for (const hand of this.servingHandlers) {
                handler = hand.getNextLevelHandler().getRecursevelyHandlerForInteraction(interaction)
                if (handler) {
                    return handler;
                };
            };
        };
        return handler;
    };

    public removeInteraction(interaction: Interaction): boolean {
        const idxDB: number = this.interactionDatabase.findIndex((i) => { return i.iss(interaction); });
        if (idxDB > -1) {
            this.interactionDatabase.splice(idxDB, 1);
        };
        const idxView = this.servingHandlers.findIndex((hand) => { return hand.getInteraction().iss(interaction) });
        if (idxView > -1) {
            this.chatBackbone.removeFromAllHandlers(this.servingHandlers[idxView].getInteraction());
            this.servingHandlers.splice(idxView, 1);
        };
        this.procedeDeletion(interaction);
        return idxDB > -1;
    };

    public removeInteractionWithQueue(interaction: Interaction, idDeletor: TGlobalUID): void {
        if (this.removeInteraction(interaction)) {
            this.removeMessageFromQueue(idDeletor);
        };
    }

    private procedeDeletion(deleting: Interaction): void {
        const idGroup: TGlobalUID = this.chatBackbone.getCurrentGroupID();

        if (deleting.isChainedOrTreeWithParent()) {
            let parent: Interaction = (<ChainedInteraction>deleting).getInteractionParent();
            parent.removeChildrenInteraction(idGroup, deleting);
        };

        // deleting está incluso no array
        for (let interaction of Interaction.getWholeInteractionChain(idGroup, deleting)) {
            interaction.excludeFromGroup(idGroup);
            if (!interaction.belongsToAnotherGroup(idGroup)) {
                interaction.removeUberCache();
            }
        };

        deleting.excludeFromGroup(idGroup);
        if (!deleting.belongsToAnotherGroup(idGroup)) {
            deleting.removeUberCache();
        };

        this.markForCheckEndPoints();
        this.detectChangesOnFirstLevelMsgs();
    };

    private callBackArrivedInteractions(interaction: Interaction): boolean {
        let refreshed: boolean = false;
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                refreshed = true;
                endpoint.onNewInteractionArrivedCallback(interaction.isChainedOrTreeWithParent())
            }
        })
        return refreshed;
    }

    public addInteraction(interaction: Interaction): boolean {
        let alreadDisplayed: boolean = false;
        let operationalInteractin: Interaction = interaction;

        if (interaction.getInteractionType().isFeedbackCarrier()) {
            const idGroup = this.chatBackbone.getGroup().getGroupID()
            const feedBack: ICacheFeedbackInteraction = (<FeedbackFiguresCarrier>interaction).getFeedbackFigures()
            this.editingInteractin.replaceFeedbackFigures(
                idGroup,
                feedBack);
            interaction.removeUberCache();
            this.editingInteractin.removeChildrenInteraction(this.chatBackbone.getGroup().getGroupID(), interaction);

            // Controle de Entrega no Cliente
            if (!interaction.isChainedOrTreeWithParent()) {
                operationalInteractin = (<FeedbackFiguresCarrier>interaction).getInteractionParent();
            }

            // Executa callback para subscritos no evento de chegada de likes
            this.chatBackbone.publishReactEvents(<FeedbackFiguresCarrier>interaction);

        } else if (interaction.getInteractionType().isStoredInClientDatabase()) {
            alreadDisplayed = this.updateInteractionDatabase(interaction);
        };

        this.markForCheckEndPoints();
        if (this.changeDectionChildren() || !this.isInteractionOperational(operationalInteractin.getInteractionID())) {
            this.removeMessageFromQueue(interaction.getInteractionID());
        }
        this.callBackArrivedInteractions(interaction);
        this.detectChangesOnFirstLevelMsgs();
        this.scrollTopAttempt();
        return alreadDisplayed;
    }

    public setReceiveReadUpdate(receiveType: EConfirmationType): void {
        this.getEditingInteraction().setReceive(this.getGroupId(), receiveType);
        this.changeAllSiblingsDetection();
    }

    private removeMessageFromQueue(idInteraction: TGlobalUID): void {
        this.queue.removeFromQueue(ELocalQueueName.publishControl, idInteraction);
    };

    private updateInteractionDatabase(interaction: Interaction): boolean {
        let alreadDisplayed: boolean = false;

        let idx: number = this.interactionDatabase.findIndex((i) => { return i.iss(interaction); });
        if (idx > -1) {
            this.interactionDatabase[idx] = interaction;
        } else {
            this.interactionDatabase.unshift(interaction);
        };

        idx = this.servingHandlers.findIndex((hand) => { return hand.getInteraction().iss(interaction) });
        if (idx > -1) {
            alreadDisplayed = true;
            this.servingHandlers[idx].updateInteraction(interaction);
            this.chatBackbone.addToAllHandlers(this.servingHandlers[idx]);
        } else {
            const handler: MessageInstanceHandler = this.createNewMessageHandler(interaction);
            this.servingHandlers.push(handler);
            const lastIdx = this.servingHandlers.length - 1;
            this.chatBackbone.addToAllHandlers(this.servingHandlers[lastIdx]);
        };

        return alreadDisplayed;
    }

    public isInteractionOperational(idInteraction: TGlobalUID): boolean {
        const onDB: boolean = this.interactionDatabase.some((i) => { return i.is(idInteraction); });
        if (!onDB) {
            return false;
        }
        const onView: boolean = this.servingHandlers.some((hand) => { return hand.getInteraction().is(idInteraction) });
        if (onView && isInvalidArray(this.msgInstanceEndPointList)) {
            return false;
        }
        return true;
    }

    public addEndpoint(endPoint: IMessageEndPointCallback) {
        this.msgInstanceEndPointList.push(endPoint);
        this.onEndpointChanged();
    }

    public getEndPoints(): TIMessageEndPointCallbackArray {
        return this.msgInstanceEndPointList
    }

    setEndPoints(savedEndPoints: TIMessageEndPointCallbackArray): void {
        this.msgInstanceEndPointList = savedEndPoints;
    }

    public removeEndpoint(endPoint: IMessageEndPointCallback) {
        const idx = this.msgInstanceEndPointList.findIndex(elem => endPoint === elem)
        if (idx > -1) {
            this.msgInstanceEndPointList.splice(idx, 1);
        }
    }

    public markForCheckEndPoints(): boolean {
        let updated: boolean = false;
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                updated = true;
                endpoint.changeAllSiblingsDetection();
            };
        });
        return updated;
    }

    changeDectionChildren(): boolean {
        let updated: boolean = false;
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                updated = true;
                endpoint.changeDectionChildren();
            }
        });
        return updated;
    };

    private onEndpointChanged(): void {
        this.markForCheckEndPoints();
        this.scrollTopAttempt();
    }

    public refreshVector(): void {
        this.servingHandlers = this.sortHandlers(this.servingHandlers);
    };

    private sortHandlers(handlers: TMessageInstanceHandlerArray): TMessageInstanceHandlerArray {
        return handlers.sort((x, y) => { return this.getBestTimestamp(x.getInteraction()) - this.getBestTimestamp(y.getInteraction()); })
    }

    private sortInteractions(interactionArray: TInteractionArray): TInteractionArray {
        return interactionArray.sort((x, y) => { return this.getBestTimestamp(y) - this.getBestTimestamp(x); });
    };

    getGroupId(): TGlobalUID { return this.chatBackbone.getGroup().getGroupID() }

    public isRootLevel(): boolean { return this.rootLevel; };
    public getDisplayOptions(): EChatViewStyle { return this.chatBackbone.getDisplayOptions().viewStyle; };
    public getParticipant(): Participant { return this.chatBackbone.getParticipant(); };
    public getInteractionHandlerArray(): TMessageInstanceHandlerArray {
        return this.servingHandlers;
    };

    public callMessageContainerChangeDetector(refreshVector: boolean): void {
        const endPoints: TIMessageEndPointCallbackArray = this.msgInstanceEndPointList;
        endPoints.some((endpoint) => {
            if (endpoint) {
                endpoint.callMessageContainerChangeDetector(refreshVector)
                return true;
            };
        });
    };

    changeAllSiblingsDetection(): void {
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                endpoint.changeAllSiblingsDetection();
            };
        });
    };

    changeSpecificSibling(interactionID: TGlobalUID) {
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                endpoint.changeSpecificSibling(interactionID)
            }
        });
    }




    public async onScroolTop(): Promise<number> {
        // this.reachedTop = false
        const added: number = await this.loadCharge();
        this.detectChangesOnFirstLevelMsgs();
        return added;
    }

    public getPreviousMessageHandler(handler: MessageInstanceHandler): MessageInstanceHandler {
        const working: TMessageInstanceHandlerArray = this.toBeArrayHandlers ? this.toBeArrayHandlers : this.servingHandlers;
        const int: Interaction = handler.getInteraction();
        let idx: number = working.findIndex((hand) => { return hand.getInteraction().iss(int); });
        return working[--idx];
    };

    public getNextMessageHandler(handler: MessageInstanceHandler): MessageInstanceHandler {
        const working: TMessageInstanceHandlerArray = this.toBeArrayHandlers ? this.toBeArrayHandlers : this.servingHandlers;
        const int: Interaction = handler.getInteraction();
        let idx: number = working.findIndex((hand) => { return hand.getInteraction().iss(int); });
        if (++idx < working.length) {
            return working[idx];
        };
        return null;
    };

    /// Suporting
    private createNewMessageHandler(interaction: Interaction): MessageInstanceHandler {
        return factoryMessageHandler({
            interaction: interaction,
            chatBackbone: this.chatBackbone,
            clientCallback: this,
            diaplayOptions: this.getDisplayOptions(),
            drivenInteractionFeedbacks: [],
            navigatorInstance: this.navigator,
            contractSvc: this.contractSvc,
            sessionSvc: this.sessionSvc,
            attendanceSvc: this.attendanceSvc,
        });
    };

    // REACT BAR COMPLIACE INTERACTIONX
    public onReactBarReaction(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedback: Feedback): void {
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                endpoint.onMessageReact(interactionID, interactionTypeID, feedback)
            }
        });
    };

    detectChangesOnFirstLevelMsgs(): void {
        this.msgInstanceEndPointList.forEach((endpoint) => {
            if (endpoint) {
                endpoint.callMessageContainerChangeDetector(false);
            }
        });
    }

    ///  MessageHandlerCompliance Interaction
    public onMessageReact(interactionID: TGlobalUID, interactionTypeID: TGlobalUID, feedback: Feedback): void {
        // this.msgInstanceEndPointList.forEach(endpoint =>
        //     endpoint.onMessageReact(interaction, feedback));
    };

    public getDisplayBarReactHandler(): ReactDisplayBarHandler { return null; };



    public destroyDatabases(handlerKey: string) {
        this.interactionDatabase = [];
        this.servingHandlers = [];
        delete MessageHandlerCallbak.instances[handlerKey];
    }



    public static reset(): void {
        MessageHandlerCallbak.instances = {};
    }

    private scrollTopAttempt(): void {
        if (this.editingInteractin
            && this.editingInteractin.is(MessageHandlerCallbak.scrollToID)
        ) {
            this.msgInstanceEndPointList.forEach((endpoint) => {
                if (endpoint) {
                    endpoint.scrollTop()
                };
            });
        }
    }

    scrollTop(): void { }


    public static getInteractionCallback(idGroup: TGlobalUID, interaction: Interaction): MessageHandlerCallbak {
        const containerController: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
            MessageHandlerCallbak.composeKey(idGroup, null));

        if (!containerController) {
            return null;
        }

        const isChatMode: boolean = containerController.getDisplayOptions() == EChatViewStyle.chat;
        if (interaction.getInteractionType().isDeleteInteraction()) {

            const beingDeleted: Interaction = (<DeleteInteraction>interaction).getInteractionParent();
            if (!isChatMode && beingDeleted.isChainedOrTreeWithParent()) {
                const grandParent: Interaction = (<ChainedInteraction>beingDeleted).getInteractionParent();
                const controller: MessageHandlerCallbak = MessageHandlerCallbak.getMessageHandlerCallback(
                    MessageHandlerCallbak.composeKey(idGroup, grandParent.getInteractionID()));
                return controller;
            } else {
                return containerController;
            };

        } else if (isSecondLevelInteraction(interaction, isChatMode)) {
            const chained: ChainedInteraction = <ChainedInteraction>interaction;
            const parentId = chained.getInteractionParent().getInteractionID();
            const parentController: MessageHandlerCallbak =
                MessageHandlerCallbak.getMessageHandlerCallback(
                    MessageHandlerCallbak.composeKey(idGroup, parentId));

            return parentController;

        }

        return containerController;
    }

}
