<div>
    <mat-form-field style="margin-bottom: 16px; margin-top: 16px" appearance="fill" class="full-width-field no-padding-bottom">
        <mat-label>Solicitante:</mat-label>
        <input matInput [ngModel]="nsClone?.nName" type="text" readonly>
    </mat-form-field>

    <mat-form-field style="margin-bottom: 16px;" appearance="fill" class="full-width-field no-padding-bottom">
        <mat-label>Grupo solicitado:</mat-label>
        <input matInput [ngModel]="nsClone?.details?.groupName" type="text" readonly>
    </mat-form-field>

    <mat-form-field *ngIf="nsClone?.details?.description" style="margin-bottom: 16px;" appearance="fill" class="full-width-field no-padding-bottom">
        <mat-label>Descrição da solicitação:</mat-label>
        <input matInput [ngModel]="nsClone?.details.description" type="text" readonly>
    </mat-form-field>

    <mat-divider style="margin-bottom: 12px;"></mat-divider>

    <ng-container *ngIf="isResolved">
        <ng-container *ngIf="resolverAvatar">
            <div class="resolver-avatar-container">
                <hexagon [hexagonHandler]="avatarHexagonHandler"></hexagon>
                <div class="l-avatar-name">{{ resolverAvatar.getName() }}</div>
            </div>
        </ng-container>
    </ng-container>

    <mat-form-field *ngIf="!isResolved || !!getComment" appearance="fill" style="width: 100%;">
        <mat-label>Comentário:</mat-label>
        <textarea matInput
            [readonly]="readOnly"
            cdkTextareaAutosize
            [(ngModel)]="receiverDescription"
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="10"
        ></textarea>
    </mat-form-field>

    <mat-divider *ngIf="isSocialNetworkSolicitation() && userApprovalProfilePicker" style="margin-bottom: 16px; margin-top: -5.5px"></mat-divider>

    <app-ns-picker 
        *ngIf="isSocialNetworkSolicitation() && userApprovalProfilePicker" 
        style="margin: 0; margin-bottom: 16px;" 
        [handler]="userApprovalProfilePicker"
        >
    </app-ns-picker>

    <ng-container *ngIf="isResolved">
        <ng-container [ngSwitch]="taskStatus">
            <ng-container *ngSwitchCase="acceptRequest">
                <app-alert type="success" dense darker>Aceita</app-alert>
            </ng-container>
            <ng-container *ngSwitchCase="rejectRequest">
                <app-alert type="error" dense>Rejeitada</app-alert>
            </ng-container>
            <ng-container *ngSwitchCase="processingRequest">
                <app-alert type="warning" dense>Processando solicitação</app-alert>
            </ng-container>
        </ng-container>
    </ng-container>

    <div *ngIf="!isResolved" class="edit-buttons--wrapper">
        <button *ngIf="saving ? (savingFor === rejectRequest) : true" mat-stroked-button [loading]="saving" [disabled]="readOnly" color="primary" (click)="onSave(rejectRequest)" style="margin-right: 16px;">Recusar</button>
        <button *ngIf="saving ? (savingFor === acceptRequest) : true" mat-flat-button [loading]="saving" [disabled]="readOnly" color="accent" (click)="onSave(acceptRequest)">Aceitar</button>
    </div>
    
</div>
