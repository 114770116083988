import { EBotContentEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { EMetadataEngagementType, IEngagementConfigPosCondition, IEngagementConfigPosForm } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { Component, Host, Inject, Input, OnInit, Optional } from '@angular/core';
import { isNSTransactionServer, ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IBotTransactionSection } from 'app/model/dashboard/bot/bot-transaction-visualizer.model';
import { IComponentParameter } from 'app/model/component-comm/basic';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IColmeiaDialogComponentData } from 'app/components/dialogs/dialog/dialog.component';
import { EBotEventType } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { getAssetOfType } from '@colmeia/core/src/shared-business-rules/bot/bot-client-functions';
import { EBPMAction } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { BotTransactionBaseDataComponent } from 'app/components/dashboard/ai-pages/bot-transaction-edit/bot-transaction-edit.component';
import { isValidRef } from '@colmeia/core/src/tools/barrel-tools';

export interface IBotTransactionVisualizerParameter extends IComponentParameter {
    botTransactionNS: ITransactionServer
}

@Component({
    selector: 'bot-transaction-visualizer',
    templateUrl: './bot-transaction-visualizer.component.html',
    styleUrls: ['./bot-transaction-visualizer.component.scss']
})
export class BotTransactionVisualizerComponent implements OnInit {
    @Input()
    public ns: INonSerializable;
    public botTransaction: ITransactionServer;

    @Input()
    private autoInit = true;

    public sections: IBotTransactionSection[];
    public title: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dataToComponent: IColmeiaDialogComponentData<INonSerializable>,
        @Optional() @Host() private botTransactionEditComponent: BotTransactionBaseDataComponent
    ) {
        if (dataToComponent) {
            this.ns = dataToComponent.getParamsToChildComponent?.();

            // if it is open inside a edit component, the title is omitted
            if (!isValidRef(this.botTransactionEditComponent)) {
                this.title = this.ns.nName;
            }
        }
    }

    ngOnInit() {
        if (this.autoInit) {
            this.init();
        }
    }

    public init() {
        if (!isNSTransactionServer(this.ns)) {
            return;
        }

        this.botTransaction = this.ns;

        this.sections = [
            {
                title: 'Pré Conteúdo',
                icon: 'border_top',
                isExpandable: true,
                expanded: true,
                botTransaction: this.botTransaction,
                botContentEventType: EBotContentEvent.preContent
            },
            {
                title: 'Entidades',
                icon: 'settings_input_composite',
                isExpandable: true,
                expanded: true,
                botTransaction: this.botTransaction,
                botEngagementMetadataType: EMetadataEngagementType.richFillForm
            },
            {
                title: 'Pós Conteúdo',
                icon: 'border_bottom',
                isExpandable: true,
                expanded: true,
                botTransaction: this.botTransaction,
                botContentEventType: EBotContentEvent.posContent
            }
        ];

        this.checkForBotEvents();
        this.checkForBotConfig();
    }

    private checkForBotEvents() {
        const reuseFormEvent = getAssetOfType(this.botTransaction.events, EBotEventType.reuseFormInformation);

        if (reuseFormEvent) {
            const reuseSection: IBotTransactionSection = {
                title: 'Eventos',
                icon: 'event',
                isExpandable: true,
                expanded: true,
                botTransaction: this.botTransaction,
                botEventType: EBotEventType.reuseFormInformation
            };

            this.sections.splice(1, 0 , reuseSection);
        }
    }

    private checkForBotConfig() {
        this.checkForBotConfigIntegration();
        this.checkForBotConfigNewCondition();
    }
    
    private checkForBotConfigNewCondition() {
        const conditionConfig = this.botTransaction.engagement?.engagementConfig[EMetadataEngagementType.NewCondition] as IEngagementConfigPosCondition;
    
        if (!conditionConfig) {
            return;
        }
    
        const actionEvaluators = conditionConfig.evaluators.filter(evaluator => evaluator.action.bpmAction === EBPMAction.action);
    
        if (actionEvaluators.length) {
            this.sections.push({
                title: 'Ação do bot',
                icon: 'launch',
                isExpandable: true,
                expanded: true,
                botTransaction: this.botTransaction,
                engagementConfig: conditionConfig,
                botActionType: EBPMAction.action
            });
        }
    }

    private checkForBotConfigIntegration() {
        const integrationConfig = this.botTransaction.engagement?.engagementConfig[EMetadataEngagementType.integration] as IEngagementConfigPosForm;

        if (!integrationConfig) {
            return;
        }

        this.sections.push({
            title: 'Integração',
            icon: 'code',
            isExpandable: false,
            botTransaction: this.botTransaction,
            engagementConfig: integrationConfig,
        });
    }
}
