import { MatDialogRef } from "@angular/material/dialog";
import { TArrayID } from "@colmeia/core/src/core-constants/types";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";
import { DiagramDialogComponentHandler, IBPMDialogResult } from "../../diagram-editor/diagram-editor.types";


export interface BpmGraphNSPickerParameters extends IComponentParameter {
  nsType: ENonSerializableObjectType,
  ignoredNSsIds?: TArrayID,
  clientCallback: undefined
}

export class BpmGraphNSPickerHandler extends DiagramDialogComponentHandler {
  constructor(parameters: BpmGraphNSPickerParameters) { super(parameters); }
  public getComponentParameter(): BpmGraphNSPickerParameters { return super.getComponentParameter() as BpmGraphNSPickerParameters }
}
