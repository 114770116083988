<!-- react bar display -->
<section class="react-bar-display">
  <ul class="emoji-react-list clickable" *ngIf="canShowEmojiList()" (click)="openFeedbacksDialog()">
    <li class="emoji-react-list__item" *ngFor="let idFeedback of feedbackControl">
      <i [ngClass]="{
        'i--sm' :  isSmall(),
        'i--xsm': !isSmall(),
        'material-icons': isIcon()
      }">{{ getEmoji(idFeedback) }}</i>
    </li>
    <span class="emoji-react-counter" *ngIf="getFeebackCountSum()">{{getFeebackCountSum() }}</span>
  </ul>
  
  <cm-modal [handler]="_modalHandler"></cm-modal>
</section>
