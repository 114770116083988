import {Injectable} from "@angular/core";
import {HardwareLayerService} from "../services/hardware";
import {UberCache} from "@colmeia/core/src/persistency/uber-cache";
import {UberCacheOperation} from "@colmeia/core/src/persistency/uber-cache-operations";
import {IUberCacheStorage} from "../model/component-comm/auth";
import {clientConstants} from "../model/constants/client.constants";
import {IInitResponse} from "@colmeia/core/src/request-interfaces/response-interfaces";
import {SessionService} from "../services/session.service";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import {MetadataMessage} from "@colmeia/core/src/shared-business-rules/metadata/metadata-message";
import {Role} from "@colmeia/core/src/security/role";
import {Feedback} from "@colmeia/core/src/interaction/feedback";
import {GroupType} from "@colmeia/core/src/business/group-type";
// declare var window: any;


@Injectable()
export class UberCacheIntializer {

    constructor(
        private hardware: HardwareLayerService,
        private session: SessionService
    ) {}

    async initialize(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            const storageData = this.loadFromStorage();
            try {
                const uberResult: IInitResponse = await window.uberPromise;
                let uberData: string;
                uberData = uberResult.uber.isUpToDate ? storageData.uberData : uberResult.uber.uberData;
                this.session.setApproverID(uberResult.approver);
                this.resetUber();
                this.fillUber(uberData);

                if (!uberResult.uber.isUpToDate) {
                    this.saveToStorage(uberResult.uber.cacheVersion, uberResult.uber.uberData);
                }

                resolve(true);
            } catch (e) {
                console.error('Uber Initialize Error', e);
                resolve(false);
            }
        });
    }

    private resetUber() {
        UberCache.hardReset();
        MetadataMessage.resetDbMetadata();
        Feedback.resetFeedbackArray();
        Role.resetRoleArray();
        GroupType.resetAllGroupTypesArray();
    }

    private loadFromStorage(): IUberCacheStorage {
        return this.hardware.getStorage().getItem<IUberCacheStorage>(clientConstants.storage.commonKeys.uberCache);
    }

    private saveToStorage(cacheVersion: number, uberData: string): void {
        this.hardware.getStorage().putItem<IUberCacheStorage>(
            clientConstants.storage.commonKeys.uberCache,
            {
                cacheVersion,
                uberData
            }
        )
    }

    public fillUber(data: string): boolean {
        try {
            UberCache.loadUberCache(true);
            UberCache.initCacheOperationalContext();
            UberCacheOperation.uberCacheRehydrate(data);
        } catch (e) {
            return false;
        }

        return true;
    }

}
