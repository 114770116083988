import { EventEmitter, Injectable } from '@angular/core';
import { IGroupParticipation, IParticipantJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { EAttendantFollowUpRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ICustomerAgentData, ICustomerAgentDataWithClient, IGetAttendantCurrentCasesRequest, IGetAttendantCurrentCasesResponse, IGetCaseFromCustomerBeingAttendentRequest, IGetCaseFromCustomerBeingAttendentResponse, IJoinAttendanceRequest, IJoinAttendanceResponse, TIAllAttendantCasesArray } from '@colmeia/core/src/shared-business-rules/attendence-follow-up/attendence-follow-up-req-res';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';
import { AttendanceService } from 'app/services/attendance.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { ReplaySubject } from 'rxjs';
import { ServerCommunicationService } from './services/server-communication.service';
import { SessionService } from './services/session.service';
import { ISubscriber, TIdGroupSet, TSupervisedChatMetadata } from './supervisor-agent.model';


@Injectable({
  providedIn: 'root'
})
export class SupervisorAgentService {
  private participantMap: EventEmitter<TIdGroupSet> = new EventEmitter<TIdGroupSet>();
  private chatVisitedGroupIdToCustomerMetadataMap: TSupervisedChatMetadata = new Map()
  private _supervisingGroup: ReplaySubject<string> = new ReplaySubject(1);
  public supervisingGroup$() { return this._supervisingGroup.asObservable() };

  constructor(
    private sessionService: SessionService,
    private serverCommunicationService: ServerCommunicationService,
    private attSvc: AttendanceService,
    private globalWarningSvc: GlobalWarningService,
  ) { }

  addToChatVisitedHistory(idGroup: string, chatMetadata: ICustomerAgentDataWithClient): void {
    this.chatVisitedGroupIdToCustomerMetadataMap.set(idGroup, chatMetadata)
  }

  getSupervisedChatMetadata(idGroup: string): ICustomerAgentDataWithClient {
    return this.chatVisitedGroupIdToCustomerMetadataMap.get(idGroup)
  }

  getAttendenceIdInteraction(): string {
    return this.getSupervisedChatMetadata(this.sessionService.getSelectedGroupID()).idInteractionService
  }

  getPlayerGroupSetListener(): ISubscriber<TIdGroupSet> {
    return this.participantMap
  }

  emitNewParticipantMapArrived(partMap: IGroupParticipation): void {
    const groupSet: Set<string> = new Set(Object.keys(partMap))

    this.participantMap.emit(groupSet)
  }

  async joinAttendence(
    idConversation: string,
    customerData: ICustomerAgentDataWithClient,
  ): Promise<IParticipantJSON> {
    if (this.attSvc.isPlayerAnAgentInCurrentSocialNetwork()) {
      this.globalWarningSvc.throw({
        message: 'Agentes não podem ter acesso a essa funcionalidade, contate seu supervisor',
        isWarning: true,
      })
    }

    this.addToChatVisitedHistory(customerData.idServiceGroup, customerData)

    const result: IJoinAttendanceResponse = <IJoinAttendanceResponse>await this.serverCommunicationService.doRequest<IJoinAttendanceRequest>(
      EAttendantFollowUpRequestType.joinAttendence, {
      joinAttendanceData: {
        idAvatarCustomer: customerData.idAvatarCustomer,
        idConversation,
        idAvatarSupervisor: this.sessionService.getAvatarID(),
        idAvatarAgent: customerData.idAvatarAgent,
        idServiceGroup: customerData.idServiceGroup,
        idInteractionService: customerData.idInteractionService
      },
    });

    this._supervisingGroup.next(customerData.idServiceGroup);
    return result.participantData
  }

  async getOpenCasesFromCustomer(idAvatarClient: string): Promise<ICustomerAgentData[]> {
    const result: IGetCaseFromCustomerBeingAttendentResponse = <IGetCaseFromCustomerBeingAttendentResponse>await this.serverCommunicationService
      .doRequest<IGetCaseFromCustomerBeingAttendentRequest>(
        EAttendantFollowUpRequestType.getCaseFromCustomerBeingAttendent, {
        idAvatarClient
      });
    return result.casesList
  }

  async getAllCasesFromAgent(idAvatarAgent: string): Promise<TIAllAttendantCasesArray> {
    const result: IGetAttendantCurrentCasesResponse = <IGetAttendantCurrentCasesResponse>await this.serverCommunicationService.doRequest<IGetAttendantCurrentCasesRequest>(
      EAttendantFollowUpRequestType.getAllAttendenceFromAgent, {
      idAvatarAgent
    });

    return result.allCases
  }

  isSupervisingAttendance(idGroup: string) {
    return this.chatVisitedGroupIdToCustomerMetadataMap.has(idGroup);
  }
}
