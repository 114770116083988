import { EColmeiaAPIErrors } from '@colmeia/core/src/shared-business-rules/colmeia-apis/api-errors.model';
import { friendlyAction, TGlobalUID } from '../business/constant';
import { Serializable } from '../business/serializable';
import { IFriendlyExtraMessage } from '../comm-interfaces/business-interfaces';
import { UberCache } from '../persistency/uber-cache';
import { isThisOneOfThat, isValidAndEqual, isValidArray, isValidRef } from '../tools/utility';
import { prettyPrint } from '../tools/utility/functions/prettyPrint';
import { ErrorClasses } from './core-error.constants';
import { GenericSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service';

export interface IErrorWithField {
    getIDField(): number;
}

export interface ICustomErrorJSON {
    idField?: number;
    idError: TGlobalUID;
    functionName: string;
    additionalMessages: any[];
}

export interface IStandardErrorClass {
    isError(): boolean;

}

export interface IPropagateErrorClass extends IStandardErrorClass {
    errorRegistrer: Error;
    getErrorPropagate(): Error;
}


export class CustomError extends Error implements ICustomErrorJSON, IStandardErrorClass {
    private ___impossibleToOwnAttribute: boolean;
    idError: TGlobalUID;
    functionName: string;
    additionalMessages: any[];
    private hasError: boolean;
    private isCustomMessageError: boolean;
    public isWarning?: boolean;

    constructor(idError: TGlobalUID, isError: boolean, functionName: string, ...messageArray: any[]) {
        const aux: string = CustomError.formatMessage(idError, functionName, messageArray);
        super(aux);
        this.idError = idError;
        this.functionName = functionName;
        this.additionalMessages = messageArray;
        this.___impossibleToOwnAttribute = true;
        this.hasError = isError;
        this.isCustomMessageError = false;
    };

    public static getNewAPIError(idError: TGlobalUID, description: string, extraInfo?: object) {
        const fnName: string = 'CustomError.getNewAPIError'
        const stack: string = (new Error(fnName)).stack
        return new CustomError(idError, true, `${fnName} description:`, [description, extraInfo, stack]);
    }

    public isAPIError(): boolean {
        return Object.values(EColmeiaAPIErrors).some((error) => error === this.idError);
    }

    public getBusinessResponse(idSerializable: TGlobalUID, idField: number): IFriendlyExtraMessage {
        return {
            idSerializable: idSerializable, idField: idField,
            isError: this.hasError, delegateToInfra: true, friendlyAction: friendlyAction.none,
            returnNowToClient: true, additionalText: CustomError.getTextFromMessage(this.additionalMessages)
        };
    };

    public isError(): boolean { return this.hasError; }
    public isBusinessMessage(): boolean { return !this.hasError; };

    public toJSON(): Error {
        return {
            message: this.getMessage(),
            name: this.getFunctionName(),
        }
    }

    public getMessage(): string {
        const idError: string = this.getSerializableID();
        let idField: number = this.getField();
        let ret: string = '';
        if (UberCache.testCache(idError)) {
            ret = Serializable.staticFactory(idError).getSerializableText(isValidRef(idField) ? idField : 1);
        }
        ret += '\n' + this.getAdditionalText();
        return ret;
    }

    public getField(): number {
        if (this.hasField()) {
            const aux: any = this;
            const idField = (<IErrorWithField>aux).getIDField();
            return idField;
        } else {
            return null;
        };
    };

    title?: string;
    
    public getTitle(): string | undefined {
        return this.title;
    }

    public setTitle(value: string) {
        this.title = value;
    }

    public getCustomMessageErrorFlag(): boolean {
        return this.isCustomMessageError;
    }

    public setCustomMessageErrorFlag(value: boolean = true) {
        this.isCustomMessageError = value;
    }

    public getCustomErrorMessage() {
        if (!this.getCustomMessageErrorFlag()) return;
        return this.getAdditionalMessages().join('\n')
    }


    public getAdditionalText(): string {
        return CustomError.formatMessage(this.idError, this.functionName, this.additionalMessages);
    };

    static formatMessage(
        idError: string,
        functionName: string,
        additionalMessages: any[]
    ) {
        return CustomError.getTextFromMessage(['id:', idError, 'function:', functionName, ...additionalMessages]);
    }


    private static auxMessagePattern?: RegExp;
    static getAuxMessagePattern() {
        CustomError.auxMessagePattern ??= new RegExp(CustomError.formatMessage(`*${/(?<idError>[^]+)/.source}`, `*${/(?<functionName>[^ ]+)/.source}`, [/(?<content>[^]+)/.source]));
        return CustomError.auxMessagePattern
    }

    static showAttendanceErrorMessages(errorMessages: string[]) {
        if (!isValidArray(errorMessages)) return;
        
        GenericSharedService.show({
            message: errorMessages!
                .map(item => CustomError.prettyPrint(item, true))
                .map(item => GenericSharedService.findMetaTemplateTranslationByText(item) ?? item )
                .join('\n')
            ,
            isWarning: true,
        })
    }

    static prettyPrint(message: string, shouldShowMessageOnly?: boolean) {
        const groups = message.match(CustomError.getAuxMessagePattern())?.groups;
        if (!groups) return message;
        const {
            idError,
            functionName,
            content,
        } = groups ?? {};

        return shouldShowMessageOnly ? content : prettyPrint({
            idError,
            functionName,
            content,
        })
    }

    public isSameIdAndField(id: TGlobalUID, field: number): boolean {
        return id === this.getSerializableID() && field === this.getField()
    }
    public getSerializableID(): TGlobalUID { return this.idError; };
    public getFunctionName(): string { return this.functionName; };
    public getAdditionalMessages(): any { return this.additionalMessages; };
    public getErrorClass(): ErrorClasses { return ErrorClasses.Custom; };
    public isServerExec(): boolean { return isThisOneOfThat(this.getErrorClass(), ErrorClasses.ServerErrorPropagated, ErrorClasses.ServerErrorField) };
    public is(eClass: ErrorClasses): boolean { return this.getErrorClass() === eClass };
    public hasField(): boolean { return isThisOneOfThat(this.getErrorClass(), ErrorClasses.CustomField, ErrorClasses.ServerErrorField, ErrorClasses.ServerErrorPropagated); };
    public hasPropagateError(): boolean { return isThisOneOfThat(this.getErrorClass(), ErrorClasses.PropagateError, ErrorClasses.PropagateErrorField, ErrorClasses.ServerErrorPropagated) }

    public static isCustomError(err: any): err is CustomError {
        return (<CustomError>err)?.___impossibleToOwnAttribute;
    };

    public static getTextFromMessage(messages: Array<any>): string {
        return messages.reduce((previous, current) => {
            return (isValidRef(previous) ? previous.toString() : '') +
                (isValidRef(current) ? current.toString() : '') + ' '
        }, '').trim();
    };

    public static isError(error: Error): boolean {
        return !isValidRef(error['hasError']) || isValidAndEqual(error['hasError'], true)
    };

};


export class CustomErrorField extends CustomError implements IErrorWithField {
    private idField: number;
    constructor(idError: TGlobalUID, isError: boolean, idField: number, functionName: string, ...messageArray: any[]) {
        super(idError, isError, functionName, ...messageArray);
        this.idField = idField;
    };

    public getIDField(): number { return this.idField; };
    public getErrorClass(): ErrorClasses { return ErrorClasses.CustomField; };
};


export class ErrorPropagate extends CustomError implements IPropagateErrorClass {
    private error: Error;
    errorRegistrer: Error

    constructor(idError: TGlobalUID, error: Error, functionName: string, ...messageArray: any[]) {
        super(idError, true, functionName, ...messageArray);
        this.errorRegistrer = this.error = error;

    };
    public getErrorPropagate(): Error { return this.error; };
    public getErrorClass(): ErrorClasses { return ErrorClasses.PropagateError; };
};


export class ErrorPropagateField extends ErrorPropagate {
    private idField: number;


    constructor(idError: TGlobalUID, error: Error, idField: number, functionName: string, ...messageArray: any[]) {
        super(idError, error, functionName, ...messageArray);
        this.idField = idField;
    };
    public getIDField(): number { return this.idField; };
    public getErrorClass(): ErrorClasses { return ErrorClasses.PropagateErrorField; };
};
