class MemoryStorageImplementation implements Storage {
    private hash: { [key: string]: string };

    constructor() {
        this.hash = {};
    }

    get length(): number {
        return Object.keys(this.hash).length;
    }

    clear(): void {
        this.hash = {};
    }

    getItem(key: string): string | null {
        return this.hash[key] || null;
    }

    key(index: number): string | null {
        return Object.keys(this.hash)[index] || null;
    }

    removeItem(key: string): void {
        this.hash[key] = undefined;
    }

    setItem(key: string, value: string): void {

    }

}

export const MemoryStorage: Storage = new MemoryStorageImplementation();

export function getLocalStorage(): Storage {
    return window.localStorage;
}

export function getSessiontorage(): Storage {
    return window.sessionStorage;
}

export function getAvailableStorage(): Storage {
    try {
        const local = getLocalStorage();
        if (local) {
            return local;
        }
        throw "LocalStorage not found";
    } catch (err) {
        try {
            const session = getSessiontorage();
            if (session) {
                return session;
            }
            throw "SessionStorage not found";
        } catch (err2) {
            return MemoryStorage;
        }
    }
}

