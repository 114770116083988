import { Component, OnInit, Input } from '@angular/core';
import { FinishServiceCall } from '@colmeia/core/src/interaction/service-group/finish-service';
import { constant } from '@colmeia/core/src/business/constant';
import { EServiceGroupIntType } from '@colmeia/core/src/business/constant.enums';
import {DateService} from "../../../../../services/date.service";
import { RootComponent } from 'app/components/foundation/root/root.component';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
    selector: 'app-finish-service-message',
    templateUrl: './finish-service-message.component.html',
    styleUrls: ['./finish-service-message.component.scss']
})
export class FinishServiceMessageComponent extends RootComponent< 'serviceFinished' > implements OnInit {

    @Input() interaction: FinishServiceCall;

    dateTime: string;
    
    constructor(
        private dateSvc: DateService
    ) { 
        super({
            serviceFinished: gTranslations.fragments.serviceFinished,
        });
    }

    ngOnInit() {
        const clockTick: number = this.interaction.getClockTick();
        this.dateTime = this.dateSvc.getBestDate(new Date(clockTick));
    }

}
