import { EUnitTypeID } from '../../business/constant.enums'
import { strictSuggestions } from '../../tools/type-utils';

export interface IUnityInfo {
    isString?: true;
    isMultipleChoice?: true;
    isNumber?: true;
    isImage?: true;
    isDate?: true;
    isBoolean?: true;
    isObject?: boolean;
    special?: boolean;
    isPrimitive?: true;
}



const dbUnityInfo = strictSuggestions<{ [key in EUnitTypeID]: IUnityInfo }>()({
    [EUnitTypeID.geoReferencingOrZipType]: {},
    [EUnitTypeID.stringType]: {isString: true, isPrimitive: true},
    [EUnitTypeID.numberType]: {isNumber: true, isPrimitive: true},
    [EUnitTypeID.imageType]: {isImage: true},
    [EUnitTypeID.logicalType]: {isBoolean: true, isPrimitive: true},
    [EUnitTypeID.dateType]: {isDate: true, isPrimitive: true},
    [EUnitTypeID.objectType]: {isObject: true},
    [EUnitTypeID.multipleChoiceType]: {isMultipleChoice: true, isPrimitive: true},
    [EUnitTypeID.avatarInput]: {special: true, isString: true},
    [EUnitTypeID.groupInput]: {special: true, isString: true},
});

export function getUnityInfo(idUnity: EUnitTypeID): IUnityInfo {
    return dbUnityInfo[idUnity] || {};
}