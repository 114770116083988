import { IInteractionJSON, IWrapperInteractionJSON } from '../comm-interfaces/interaction-interfaces';
import { constant } from '../business/constant';
import { ETimedInteractionTypeID, EWrapperStatus } from '../business/constant.enums';
import { IServiceChatInitiatedInteractionJSON, EServiceChatType } from '../comm-interfaces/service-group-interfaces';
import { EIslandType } from '../shared-business-rules/attendance-island/attendance-island';


export function isWrappedInteraction(interaction: IInteractionJSON): boolean {
    return interaction.idInteractionType === constant.interactionType.special.wrapperInteraction;
};

export function isRememberNotification(interaction: IInteractionJSON): boolean {
    return isWrappedInteraction(interaction) 
        && (<IWrapperInteractionJSON>interaction).wrapperType === ETimedInteractionTypeID.rememberMe
        && (<IWrapperInteractionJSON>interaction).wrapperStatus === EWrapperStatus.delivered;
}

export function isServiceChatInteraction(interaction: IInteractionJSON): boolean {
    return interaction.idInteractionType === constant.interactionType.serviceGroup.startServiceChat;
}

export function isPanic(interaction: IInteractionJSON): boolean {
    return false;
}

export function isServiceChat(interaction: IInteractionJSON): boolean {
    return isServiceChatInteraction(interaction) && (<IServiceChatInitiatedInteractionJSON>interaction).serviceType !== EIslandType.internalSupport;
}


export function isCallCenterServiceChat(interaction: IInteractionJSON): boolean {
    return isServiceChatInteraction(interaction) && (<IServiceChatInitiatedInteractionJSON>interaction).serviceType !== EIslandType.internalSupport
}

export function isInternalServiceChat(interaction: IInteractionJSON): boolean {
    return isServiceChatInteraction(interaction) && (<IServiceChatInitiatedInteractionJSON>interaction).serviceType === EIslandType.internalSupport;
}