import { ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { EPaletteProperty, EPaletteType, IBrandTheme, ICollorPaletteWithType, IColorPalleteBase, ICSSUnityProperty } from '@colmeia/core/src/shared-business-rules/brand-theme/brand-theme.model';
import { isValidArray, isValidRef, isValidString } from '@colmeia/core/src/tools/barrel-tools';
import { ColorThemeBuilderEditDialog } from 'app/components/color-theme-builder/color-theme-builder-edit/color-theme-builder-edit-dialog.component';
import { ColmeiaDialogService } from './dialog/dialog.service';
import { BrandTheme } from 'app/components/color-theme-builder/brand-theme.functions';
import { ColmeiaWindowService } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window.service';
import { ColmeiaWindowRef } from 'app/components/dashboard/dashboard-foundation/colmeia-window/colmeia-window-ref';

export enum EPreviewType {
    EmbeddedHtml = 'embeddedHtml',
    EmbeddedJs = 'embeddedJs',
    Component = 'component',
    HTMLElement = 'htmlElement',
}

export interface IOppeningColorBuilderData {
    viewRef?: ViewContainerRef;
    injector?: Injector;
    previewType: EPreviewType;
    brandTheme?: IBrandTheme;
    width?: string;
    panelClass?: string | string[];
    previewComponent?: ComponentType<unknown>;
    previewElement?: HTMLElement;
    window?: {
        title: string;
        group: string;
    };
}

export interface IOppeningShadeItemEditorDialog {
    shadeValueString: string;
    paletteType: string;
    event: Event;
}

@Injectable({
    providedIn: 'root'
})
export class ColorThemeBuilderService {

    private _embeddedCodeString: string = '';
    get embeddedCodeString(): string {
        return this._embeddedCodeString;
    }
    set embeddedCodeString(value: string) {
        this._embeddedCodeString = value;
    }

    constructor(
        private dialogSvc: ColmeiaDialogService,
        private colmeiaWindowSvc: ColmeiaWindowService
    ) { }

    openDialog(colorBuilderDialogDataParameters: IOppeningColorBuilderData): Promise<IBrandTheme> {
        return new Promise((resolve, reject) => {
            const dialogRef = this.dialogSvc.open(ColorThemeBuilderEditDialog, {
                viewContainerRef: colorBuilderDialogDataParameters.viewRef,
                panelClass: colorBuilderDialogDataParameters.panelClass || "average-size",
                injector: colorBuilderDialogDataParameters.injector,
                data: {
                    brandTheme: colorBuilderDialogDataParameters.brandTheme,
                    previewType: colorBuilderDialogDataParameters.previewType,
                    previewComponent: colorBuilderDialogDataParameters.previewComponent,
                    previewElement: colorBuilderDialogDataParameters.previewElement,
                },
                width: colorBuilderDialogDataParameters?.width || undefined
            });

            dialogRef.afterClosed().subscribe(result => {
                resolve(result);
            });
        });
    }

    openWindow(colorBuilderDialogDataParameters: IOppeningColorBuilderData): ColmeiaWindowRef<ColorThemeBuilderEditDialog> {
        const windowRef = this.colmeiaWindowSvc.open(ColorThemeBuilderEditDialog, {
            title: colorBuilderDialogDataParameters.window.title,
            group: colorBuilderDialogDataParameters.window.group,
            panelClass: colorBuilderDialogDataParameters.panelClass || "average-size",
            data: {
                brandTheme: colorBuilderDialogDataParameters.brandTheme,
                previewType: colorBuilderDialogDataParameters.previewType,
                previewComponent: colorBuilderDialogDataParameters.previewComponent,
                previewElement: colorBuilderDialogDataParameters.previewElement
            },
            width: colorBuilderDialogDataParameters?.width || undefined
        });

        return windowRef;
    }

    updateSpecificAssocietedCSSProperties(key: string, cssProperty: ICSSUnityProperty, paletteType: EPaletteType, paletteProperty: EPaletteProperty, element: HTMLElement) {
        let specialKeySetting: ICSSUnityProperty | undefined;

        const basePrefixStr = cssProperty.prefix.includes('primary')
            ? '--primary-'
            : '--accent-'

        if(paletteProperty === EPaletteProperty.Contrast) { 
            switch(key) {
                case '500':
                    specialKeySetting = <ICSSUnityProperty>{
                        prefix: basePrefixStr + 'default-contrast',
                        suffix: cssProperty.suffix
                    }
                    this.setCSSProperty(element, specialKeySetting);
                    return;
    
                case '100':
                    specialKeySetting = <ICSSUnityProperty>{
                        prefix: basePrefixStr + 'lighter-contrast',
                        suffix: cssProperty.suffix
                    }
                    this.setCSSProperty(element, specialKeySetting)
                    return;
    
                case '900':
                    specialKeySetting = <ICSSUnityProperty>{
                        prefix: basePrefixStr + 'darker-contrast',
                        suffix: cssProperty.suffix
                    }
                    this.setCSSProperty(element, specialKeySetting)
                    return;
    
                default:
                    return;
            }  
        }

        switch(key) {
            case '500':
                specialKeySetting = <ICSSUnityProperty>{
                    prefix: basePrefixStr + 'default',
                    suffix: cssProperty.suffix
                }
                this.setCSSProperty(element, specialKeySetting);
                return;

            case '100':
                specialKeySetting = <ICSSUnityProperty>{
                    prefix: basePrefixStr + 'lighter',
                    suffix: cssProperty.suffix
                }
                this.setCSSProperty(element, specialKeySetting)
                return;

            case '700':
                specialKeySetting = <ICSSUnityProperty>{
                    prefix: basePrefixStr + 'darker',
                    suffix: cssProperty.suffix
                }
                this.setCSSProperty(element, specialKeySetting)
                return;

            case '900':
                specialKeySetting = <ICSSUnityProperty>{
                    prefix: basePrefixStr + 'text',
                    suffix: cssProperty.suffix
                }
                this.setCSSProperty(element, specialKeySetting)
                return;

            default:
                return;
        }        
    }

    buildCSSPropertyVariable(key: string, value: string, paletteType: EPaletteType, specificPaletteMainProperty: EPaletteProperty): ICSSUnityProperty | undefined {

        const isContrast: boolean = paletteType !== EPaletteType.ForegroundPalette 
            && specificPaletteMainProperty === EPaletteProperty.Contrast;
        const contrastStr = '-contrast'

        let baseStr: string = '';
        switch(paletteType) { 
            case EPaletteType.PrimaryPalette:
                baseStr = '--primary-'
                break;

            case EPaletteType.AccentPalette:
                baseStr = '--accent-'
                break;

            case EPaletteType.ForegroundPalette:
                baseStr = '--foreground-'
                break;
        }

        const cssKey: string = !isContrast 
            ? `${baseStr}${key}`
            : `${baseStr}${key}${contrastStr}`

        return { prefix: cssKey, suffix: value };
    }  



    applyCSSProperties(palette: IColorPalleteBase, paletteType: EPaletteType, specificPaletteMainProperty: EPaletteProperty, element: HTMLElement) {

        const paletteKeys = Object.keys(palette);
        const maxLength: number = paletteKeys?.length || 0;
    
        if (!isValidArray(paletteKeys) || !isValidRef(paletteKeys?.[0])) {
            return; 
        }
    
        for (let i = 0; i < maxLength; ++i) {
            const key = paletteKeys[i];
            const value = palette[key];

            if (!isValidRef(value)) {
                break;
            }
    
            const cssProperty: ICSSUnityProperty | undefined = this.buildCSSPropertyVariable(key, value, paletteType, specificPaletteMainProperty);
            
            if(!cssProperty) { 
                return;
            }

            this.setCSSProperty(element, cssProperty);
            this.updateSpecificAssocietedCSSProperties(key, cssProperty, paletteType, specificPaletteMainProperty, element);
        }
    }    

    setCSSProperty(element: HTMLElement, cssPropertyParam: ICSSUnityProperty) { 
        if (!isValidRef(element)) {
            return;
        }
        element.style.setProperty(cssPropertyParam.prefix, cssPropertyParam.suffix); //<< key and value
    }    

    updatePreviewComponentCSSProperties(element: HTMLElement, colorPalettes: ICollorPaletteWithType[], fontFamily?: string) {

        if (isValidString(fontFamily)) {
            this.setCSSProperty(element, { prefix: '--font-family', suffix: BrandTheme.getFontSafeValue(fontFamily) });
        }

        for(const item of colorPalettes) {
            const { palette, contrast } = item;
            if(!!palette) { 
                this.applyCSSProperties(palette, item.paletteType, EPaletteProperty.Palette, element);
            }
            if(!!contrast) { 
                this.applyCSSProperties(contrast, item.paletteType, EPaletteProperty.Contrast, element);
            }
        }
    }
}
