import { ComponentType } from "@angular/cdk/portal";
import { EventEmitter } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { EArrowSourcedSpot, IBasicToolbarElement, TReferencedObject } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { IContainerSize } from "@colmeia/core/src/shared-business-rules/graph/essential/diagram.interfaces";
import { GraphElement } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-element";
import { GraphRulesProcessor, IVisualBPMComponentCallback } from "@colmeia/core/src/shared-business-rules/graph/essential/graph-rules-processor";
import { INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { isValidFunction } from "@colmeia/core/src/tools/utility";
import { MainHandler } from "app/handlers/main-handler";
import { BpmApiService } from "app/services/bpm/api/bpm-bot-api.service";
import { BpmService } from "app/services/bpm/bpm.service";
import { GlobalWarningService } from "app/services/global-warning.service";
import type { Node } from "reactflow";
import { GraphNodeHTMLHandler } from "./diagram-editor-node-html.handler";
import { ISelectionChange, TReactFlow } from "./react-diagram-editor/react-nodes.model";
import { IExtraDialogData } from 'app/services/bpm/bpm-subject-rules/bpm-rules.types';

export interface IGraphNodeModel {
    graphElement: GraphElement;
    htmlHandler: GraphNodeHTMLHandler;
    node: Node;
}

export interface IDiagramEditorComponent extends IVisualBPMComponentCallback {
    diagram: TReactFlow;
    warningSvc: GlobalWarningService;
    bpmApiSvc: BpmApiService;
    bpmSvc: BpmService;
    activeTools: DiagramEditorTool[];
    commandTools: DiagramEditorTool[];
    drawGraphsToolbar(): void;
    refreshDiagram: EventEmitter<void>;
}

export enum EDiagramEditorEvents {
    SelectionChange = "selectionChange",
}

export interface IDiagramEvents {
    [EDiagramEditorEvents.SelectionChange]: ISelectionChange;
}

export enum EDiagramEditorToolTypes {
    Active = "active",
    Command = "command"
}

export abstract class DiagramEditorTool {
    abstract id: string;
    abstract icon: string;
    abstract visible: boolean;
    abstract title: string;

    type: EDiagramEditorToolTypes;
    hotKey?: string;
    diagramEditor: IDiagramEditorComponent;
    init?(): void;
    configure(diagramEditor: IDiagramEditorComponent) {
        this.diagramEditor = diagramEditor;

        if (isValidFunction(this.init)) {
            this.init();
        }
    }
    onDiagramEvent?<Event extends EDiagramEditorEvents>(
        event: Event,
        data: IDiagramEvents[Event]
    ): void;
    abstract handler(mouseEvent?: MouseEvent): void;
}

export interface IDiagramConfiguration {
    toolbarElements: IBasicToolbarElement[];
    diagramContainerSize: IContainerSize;
    diagramTools?: DiagramEditorTool[];
    initialData: GraphElement[];
    rulesProcessor: GraphRulesProcessor
}

export interface IBPMDiagramModalResult {
    userHasClickedSave: boolean,
    nonSerializable: INonSerializable,
}

export interface Tab {
    title: ITranslationConfig;
    outlet: any;
    active: boolean;
}

export abstract class DiagramDialogComponentHandler<T extends object = IBPMDialogResult> extends MainHandler {
    private dialogRef: MatDialogRef<any, T>;
    public setDialogRef(value: MatDialogRef<any, T>): void { this.dialogRef = value; }
    public getDialogRef(): MatDialogRef<any, T> { return this.dialogRef; }
}

export interface IOpenDialogParams {
    componentToOpen: ComponentType<any>,
    componentHandler: MainHandler,
    nonSerializable?: INonSerializable,
}

export interface IBPMDialogResult {
    userHasClickedSave: boolean,
    nonSerializable: INonSerializable,
    extraData?: IExtraDialogData
}

export interface ICRMProcessingNodeBPMDialogResult extends IBPMDialogResult {
    botMenuItem: INonSerializable,
}

export interface IAddNodeInstruction {
    node: Node;
    htmlHandler: GraphNodeHTMLHandler
}

export interface PointPortModel {
    id: string;
    spot: EArrowSourcedSpot;
    type: 'source' | 'target';
    referencedObject: TReferencedObject;
}

export type TAdditionalInfo = {
    referencedObject: TReferencedObject;
    isSequenced: boolean;
    moveChildrenTogether: boolean;
};
