import { EUnitTypeID } from "../../business/constant.enums";
import { ETypeOfContact } from "../../comm-interfaces/barrel-comm-interfaces";
import { TArrayID } from "../../core-constants/types";
import { IGeneralFormAnswer, IRFieldResponse, TGeneralFieldArray, TGeneralFormAnswerArray, TIRFieldResponseBasis } from "../../general-form/general-form-answer";
import { IFormSchema, SchemaProperty, TSchemaPropertyArray } from "../../general-form/general-form-interface";
import { ICSVToSchemma, IFileField } from "../../request-interfaces/files-interfaces";
import { genericTypedSuggestions } from "../../tools/type-utils";
import {
    arrayUnique,
    getUniqueStringID,
    isEmptyObject, isInEnum, isInvalid, isInvalidArray,
    isInvalidString, isValidArray, isValidFunction, isValidObject, isValidRef,
    isValidString,
    regexMatchAll, replaceString,
    typedClone
} from "../../tools/utility";
import { matchGroupsFromPattern } from "../../tools/utility-types";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { getSmartFieldEngagements } from "../bot/engagement-function";
import { TICampaingVariableConfigArray } from "../campaigns/campaing-interfaces";
import { ILocalCanonical, IServerLocalCanonical, IServerLocalCanonicalArray, TCanonicalDB } from "../canonical-model/local-canonical";
import { TColmeiaTagArray } from "../colmeia-tags/tags";
import { getGreeting } from "../const-text/functions/getGreeting";
import { ICRMTicketDataBasic } from "../crm/crm-entities";
import { TGraphPropertyData } from "../graph/essential/graph-types";
import { ENonSerializableObjectType, INonSerializable } from "../non-serializable-id/non-serializable-id-interfaces";
import { ESmartField } from "../smart/smart-fields";
import { getTypeOfContactFromCanonical } from "../social-cc/social-cc-rules";
import { EMetadataEngagementType, IEngagementConfig, IFieldCRMEngagement, IMetaEngagement } from "./meta-engagement";
import { EMetadataNames, TMetadataNameArray } from "./metadata-db";
import {
    EPropValidationErrorType, ICompiledTemplateWithVariables, IPropertyKey, IVariableClient, TIVariablesArray,
    TPropertyKeyArray, ValidatePropResult
} from "./metadata-util-interfaces";


export type TComputedInfo = { [idNS in string]: string }

export interface IMediaPrintEntry {
    mediaUrl: string;
    title: string;
}

export type TIMediaPrintEntryArray = Array<IMediaPrintEntry>

export interface IPrintStructuredGeneralAnswer {
    mediaArray: TIMediaPrintEntryArray;
    text: string;
}

export interface IPrintPlainGeneralAnswer {
    mediaArray: TIMediaPrintEntryArray;
    messageArray: TArrayID
}


export type TIEditorVariableArray = Array<IEditorVariable>;


export interface IEditorVariable {
    replace?: string;
    isSafe?: boolean;
    variable: string;
    idProperty: string;
    canonical?: ILocalCanonical;
    idLocalCanonical?: string;
    isTagVariable?: boolean;
}

export interface ICompileResponse {
    variablesTemplate?: ICompiledTemplateWithVariables;
    rawMessage: string;
}
export const gPrimaryKeySeparator: string = '|'
export const noMatchFound: string = '_noValue_'
export enum EEmbededStrCommands {
    image = '_eb_:'
};

export interface IEmbededStrCommands {
    [idVariable: string]: EEmbededStrCommands
}

//.*?_eb_:(\S{4,300})/gm
const _urlImage = new RegExp('.*?' + EEmbededStrCommands.image + '(\\S{4,20048})', 'gm');


export interface IReplacedVariableResult {
    message: string;
    urls: TArrayID;
    isEmpty?: boolean;
}

export function nsToVariable(ns: INonSerializable): IEditorVariable {
    return { variable: addBracket(ns.nName), idProperty: removeBracket(ns.idNS) };
}

export function getReadyEmbeddedURLMessage(message: string): IReplacedVariableResult {
    const urls: TArrayID = [];
    const matches = getEmbeddedImageURL(message);

    message = replaceString(message, EEmbededStrCommands.image, '')

    for (const match of matches) {
        urls.push(match);
        message = replaceString(message, match, '');
    };
    return { message, urls };
}

export function getEmbeddedImageURL(message: string): TArrayID {
    if (isInvalid(message)) {
        return [];
    }
    return regexMatchAll(message, _urlImage);

}

export function mergeConstantsOnFieldArray(responses: TGeneralFieldArray, constants: TICampaingVariableConfigArray): void {
    if (isInvalidArray(constants)) {
        return;
    }
    for (const constant of constants) {
        let propAnswer = responses.find((r) => { return r.idLocalCanonical === constant.idLocalCanonical });
        if (!propAnswer) {
            propAnswer = {
                idGlobalCanonical: undefined,
                idLocalCanonical: constant.idLocalCanonical,
                idProperty: getUniqueStringID(5),
                raw: constant.value,
                row: 1,
                value: constant.value
            };
            responses.push(propAnswer);
        }
    }

}



export function getAnswerForProperty(responses: TGeneralFieldArray, property: SchemaProperty, idGlobalCanonical?: EMetadataNames, createIfNotExists: boolean = true): IRFieldResponse {
    let propAnswer = responses.find(answer => answer.idProperty === property.idProperty);
    if (!propAnswer && createIfNotExists) {
        propAnswer = {
            idGlobalCanonical,
            idLocalCanonical: property.idLocalCanonical,
            idProperty: property.idProperty,
            raw: undefined,
            row: 1,
            value: undefined
        };
        responses.push(propAnswer);
    }

    return propAnswer;
}

export function getCanonicalValueFromFieldArray(idCanonical: string, state: TGeneralFieldArray): string {
    const ret = state.find((s) => { return s.idLocalCanonical === idCanonical });
    return <string>ret?.value
};

export function getPropertyValueFromFieldArray(idProperty: string, state: TGeneralFieldArray): string {
    const ret = state.find((s) => { return s.idProperty === idProperty });
    return <string>ret?.value
};





// Finds properties based on a validator. Will search inner object fields.
export function findSchemaProperty(props: SchemaProperty[], validator: (prop: SchemaProperty) => boolean): SchemaProperty {
    for (let prop of props) {
        if (validator(prop)) return prop;

        if (prop.idUnity === EUnitTypeID.objectType) {
            const innerProperty: SchemaProperty = findSchemaProperty(prop.nestedSchema, validator);
            if (innerProperty) return innerProperty;
        }
    }
}



export function getPropertyById(props: SchemaProperty[], id: string): SchemaProperty {
    return findSchemaProperty(props, prop => prop.idProperty === id);
}


export function getPropertyWithLocalCanonical(props: TSchemaPropertyArray, idCanonical: string): SchemaProperty {
    return findSchemaProperty(props, prop => prop.idLocalCanonical === idCanonical);
}



export function getSmartResponse(idPropertyOrLocanCanonical: string, responses: TGeneralFieldArray): IRFieldResponse {
    const resp = getResponseByIDProperty(idPropertyOrLocanCanonical, responses);
    if (resp) {
        return resp
    }
    return responses.find((r) => { return r.idLocalCanonical === idPropertyOrLocanCanonical })
}


export function getResponseByIDPropertyFromAnswerts(idProperty: string, answers: TGeneralFormAnswerArray): IRFieldResponse {
    if (isInvalidArray(answers)) {
        return undefined;
    }
    for (const ans of answers) {
        const resp = getResponseByIDProperty(idProperty, ans.responses);
        if (isValidRef(resp)) {
            return resp;
        }
    }
    return undefined;
}

export function getResponseByIDProperty(idProperty: string, responses: TGeneralFieldArray): IRFieldResponse | undefined {
    return responses.find((r) => { return r.idProperty === idProperty })
}

export function getResponseIndexByIDProperty(idProperty: string, responses: TGeneralFieldArray): number {
    return responses.findIndex((r) => { return r.idProperty === idProperty })
}




export function getResponseByIDGlobalCanonical(globalCanonical: EMetadataNames, responses: TGeneralFieldArray): string {
    if (isValidArray(responses)) {
        const resp = responses.find((r) => { return r.idGlobalCanonical === globalCanonical })
        if (isValidRef(resp)) {
            return resp.raw;
        };
    };
    return undefined;
};



export function getReponseByTypeOfContact(typeOfContact: ETypeOfContact, responses: TGeneralFieldArray): string {
    if (isValidArray(responses)) {
        const resp = responses.find((r) => { return getTypeOfContactFromCanonical(r.idGlobalCanonical) === typeOfContact })
        if (isValidRef(resp)) {
            return resp.raw;
        };
    };
    return undefined;
};







export function getPropertyValueByCanonicalFromServerArray(idCanonical: EMetadataNames, array: TGeneralFormAnswerArray): string {
    if (isInvalidArray(array)) {
        return undefined;
    }
    for (const form of array) {
        const resp = getResponseByIDGlobalCanonical(idCanonical, form.responses)
        if (isValidRef(resp)) {
            return resp;
        }
    }
    return undefined;
}


export type TIFilledPropertyDataArray = Array<IFilledPropertyData>;

export interface IFilledPropertyData {
    prompt: string;
    value: TGraphPropertyData;
    raw: string;
}

export function getFilledPropertyData(answer: IGeneralFormAnswer, form: IFormSchema): TIFilledPropertyDataArray {
    return answer.responses.reduce((acc, response) => {
        const property = getPropertyById(form.form, response.idProperty);
        if (property) {
            acc.push({
                prompt: property.prompt,
                value: response.value,
                raw: response.raw,
            });
        }
        return acc;
    }, []);
};

export interface ICanonicalValue { [idCanonical: string]: TGraphPropertyData }


export interface IBracketType {
    startCharacter: string;
    endCharacter: string;
}

export const textCompiledDelimeters = genericTypedSuggestions<IBracketType>()({
    startCharacter: '{{',
    endCharacter: '}}',
} as const);

export const customFormatChat = genericTypedSuggestions<IBracketType>()({
    startCharacter: '```',
    endCharacter: '```',
} as const);


const regularExpressions = {
    isInsideDelimeters: `${textCompiledDelimeters.startCharacter}([^)]+)${textCompiledDelimeters.endCharacter}`,
    removeDots: /[\.,?!+-;%:]/g
}

function getWordWithoutCharacters(word: string): string {
    const removeAllCharactersRegex = new RegExp(regularExpressions.removeDots);
    return word.replace(removeAllCharactersRegex, '');
}

function isValidVariable(word: string): boolean {
    const reg = new RegExp(regularExpressions.isInsideDelimeters);
    return reg.test(word);
}

export function getVariableWithDelimeters(variable: IVariableClient): IVariableClient {
    return {
        text: `${textCompiledDelimeters.startCharacter}${variable.text}${textCompiledDelimeters.endCharacter}`,
        idProperty: variable.idProperty,
        value: variable.value,
        isSafe: variable.isSafe,
        idLocalCanonical: variable.idLocalCanonical
    }
}

export function getVariablesWithDelimeters(variables: TIVariablesArray): TIVariablesArray {
    return isValidArray(variables) ? variables.map(getVariableWithDelimeters) : [];
}

export function getInvalidVariables(text: string, variables: TIVariablesArray): Array<string> {
    const invalidVariables: Array<string> = [];

    text.split(' ').map(word => {
        const auxWord = getWordWithoutCharacters(word);
        if (isValidVariable(auxWord)
            && !variables.find(v => v.text === auxWord)
            && !invalidVariables.includes(auxWord)) {

            invalidVariables.push(word);
        }
    })

    return invalidVariables;
}


export function getVariableRegex(variable: string): RegExp {
    const regexValidator: string = `${textCompiledDelimeters.startCharacter}${variable.split('').map(l => `[${l}]`).join('')}${textCompiledDelimeters.endCharacter}`;
    return new RegExp(regexValidator, 'g');
}


export function addDelimitersToVariable(variable: string): string {
    return textCompiledDelimeters.startCharacter + variable + textCompiledDelimeters.endCharacter;
}

/**
 * Alias for removeBracket
 */
export const removeDelimitersOfVariable = removeBracket;

export const regexSingleBrackets = /\{(.*?)\}/g;
export const regexDoubleBrackets = /\{{(.*?)\}}/g;

export function whatsAppMessageTemplate(message: string): string {
    return message.split(regexDoubleBrackets).map((item, index) => index % 2 === 0 ? item : `{{${(index + 1) / 2}}}`).join('').trim();
}

export function getAmountWhatsAppVariablesOnMessage(message: string): number {
    const amount = matchGroupsFromPattern(regexDoubleBrackets)(message).length
    return amount;
}



export function prepareWhatsAppContentAsset(asset: IContentBasicAsset): IContentBasicAsset {
    if (asset.variablesTemplate?.compiledTemplate) {
        asset.variablesTemplate.compiledTemplateWithProperties = asset.variablesTemplate.compiledTemplate;
        asset.variablesTemplate.compiledTemplate = whatsAppMessageTemplate(asset.variablesTemplate.compiledTemplate);
    }
    return asset;
}


export function addBracket(text: string, bracket: IBracketType = textCompiledDelimeters): string {
    return bracket.startCharacter + text + bracket.endCharacter;
}




export function compileText(message: string, variables: TIEditorVariableArray, bracketType: IBracketType = textCompiledDelimeters, hasTolerance: boolean = false): ICompileResponse {
    const rawMessage: string = message;
    const matches = getVariablesOfTemplate(message, bracketType.startCharacter === textCompiledDelimeters.startCharacter ? regexDoubleBrackets : regexSingleBrackets);
    const ret: ICompileResponse = { rawMessage: message, variablesTemplate: { variables: [], compiledTemplate: message } }

    if (isValidArray(matches)) {

        for (const match of matches) {
            const variable = variables.find((m) => { return m.variable === match });

            if (!hasTolerance || isValidRef(variable)) {
                message = replaceString(message, match, addBracket(variable.idProperty, textCompiledDelimeters));

                ret.variablesTemplate.variables.push({ idProperty: variable.idProperty, value: variable.variable, replace: variable.replace } as unknown as IPropertyKey)
            }
        }

        ret.rawMessage = rawMessage;
        ret.variablesTemplate.compiledTemplate = message;

    };
    return ret;
}

export function getNewPositionalValues(template: string, positional: TArrayID): TArrayID {
    const matches = getVariablesOfTemplate(template, regexDoubleBrackets);
    const newPositional: TArrayID = [];
    for (const match of matches) {
        const oldIndex: number = parseInt(removeBracket(match));
        newPositional.push(positional[oldIndex - 1]);
    }
    return newPositional;
}

export function compileWhatsApp(message: string, variables: TIEditorVariableArray): ICompileResponse {
    const rawMessage: string = message;
    const matches = getVariablesOfTemplate(message);
    const ret: ICompileResponse = { rawMessage: message, variablesTemplate: { variables: [], compiledTemplate: message } }

    if (isValidArray(matches)) {
        let initVariable: number = 0;
        const usedMatches: { [match: string]: string } = {};
        for (const match of matches) {
            // message = message.replace(message, match, addBracket((++initVariable).toString(), textCompiledDelimeters));
            message = message.replace(match, addBracket((++initVariable).toString(), textCompiledDelimeters));
            usedMatches[initVariable.toString()] = match;
        }

        ret.rawMessage = rawMessage;
        ret.variablesTemplate.compiledTemplate = message;

        for (const index in usedMatches) {
            ret.variablesTemplate.variables.push(
                {
                    idProperty: variables.find((v) => { return v.variable === usedMatches[index] })?.idProperty
                }
            )
        }


    };
    return ret;
}




export function removeBracket(withBracket: string): string {
    const whithoutRight: string = replaceString(withBracket, textCompiledDelimeters.startCharacter, '');
    const noBracket: string = replaceString(whithoutRight, textCompiledDelimeters.endCharacter, '');
    return noBracket;
}




export function replaceCompiledText(variablesTemplate: ICompiledTemplateWithVariables, messageTemplate: string, rows: TGeneralFieldArray): string {
    let message: string = messageTemplate;
    const matches: TArrayID = getVariablesOfTemplate(messageTemplate);

    if (isInvalidArray(matches)) {
        return message;
    }

    for (const match of matches) {
        const idProperty: string = removeBracket(match);
        const field = getSmartResponse(idProperty, rows);
        let value: string = <string>field?.value;

        if (isInvalidString(value)) {
            const el: IPropertyKey = variablesTemplate.variables.find((prop) => { return prop.idProperty === idProperty });
            if (el) {
                value = el.value;
            }
        }

        message = replaceString(message, match, <string>value);
    }
    return message;
}

export function positionalToCanonicals(varTemplate: ICompiledTemplateWithVariables, compiledTemplateWithProperties: string): ICompiledTemplateWithVariables {
    const variablesTemplate = typedClone<ICompiledTemplateWithVariables>(varTemplate);
    variablesTemplate.compiledTemplate = compiledTemplateWithProperties;



    // const matches: TArrayID = getVariablesOfTemplate(variablesTemplate.compiledTemplate);
    // const properties: TArrayID = getVariablesOfTemplate(compiledTemplateWithProperties);

    // for (const match of matches) {
    //     const pos: number = parseInt(removeBracket(match));
    //     const idLocalCanonical: string = variablesTemplate.variables[pos - 1].idProperty;
    //     variablesTemplate.compiledTemplate = replaceString(variablesTemplate.compiledTemplate, match, addBracket(idLocalCanonical));
    // }

    return variablesTemplate;
}


export function getVariablesOfTemplate(message: string, regex: RegExp = regexDoubleBrackets): TArrayID {
    return message.match(regex) || [];
}

export function getPositionalValueFromCanonical(variablesTemplate: ICompiledTemplateWithVariables, rows: TGeneralFieldArray, form: TSchemaPropertyArray, fixedFields: IFileField): TArrayID {
    const positional: TArrayID = [];

    for (const field of variablesTemplate.variables) {
        let value: string = noMatchFound;
        if (isValidString(field.value)) {
            value = field.value
        } else if (isValidRef(fixedFields) && isValidString(fixedFields[field.idProperty])) {
            value = fixedFields[field.idProperty]
        } else {
            const meta: SchemaProperty = form.find((x) => { return x.idLocalCanonical === field.idProperty });
            const row: IRFieldResponse = rows.find((x) => { return x.idProperty === meta.idProperty && isValidRef(<string>x.value) });
            if (isValidRef(row) && isValidRef(row.value)) {
                value = row.value.toString();
            }
        }
        positional.push(value);
    }
    return positional;
}

export function getVariablesValues(
    rows: TGeneralFieldArray,
    fixedFields: IFileField,
    canonicals?: IServerLocalCanonical[],
): IFileField {
    const fields = { ...fixedFields };
    rows.forEach(row => {
        const value = row.value.toString();
        fields[row.idLocalCanonical] = value;
        if (row.idGlobalCanonical) fields[row.idGlobalCanonical] = value;
    });
    canonicals?.forEach(canonical => {
        if (!canonical.globalCanonical) return;
        const value = fields[canonical.globalCanonical];
        if (isInvalid(value)) return;
        fields[canonical.idNS!] = fields[canonical.globalCanonical];
    });
    return fields;
}

export function getPositionalValueFromCanonicalOnly(template: string, variablesTemplate: ICompiledTemplateWithVariables, rows: TGeneralFieldArray, fixedFields: IFileField | undefined): TArrayID {
    const matches = getVariablesOfTemplate(template).map(t => removeBracket(t));
    const positional: TArrayID = [];

    for (const match of matches) {
        const field: IPropertyKey = variablesTemplate.variables.find(f => f.idProperty === match);
        let value: string = noMatchFound;
        if (isValidString(field.value)) {
            value = field.value;

        } else if (isValidRef(fixedFields) && isValidString(fixedFields[field.idProperty])) {
            value = fixedFields[field.idProperty];

        } else {
            const row: IRFieldResponse = rows.find((x) => { return x.idLocalCanonical === field.idProperty && isValidRef(<string>x.value) });
            if (isValidRef(row) && isValidRef(row.value)) {
                value = row.value.toString();
            }
        }
        positional.push(value);
    }

    return positional;
}




export function getPositionalVariablesOFTemplate(variablesTemplate: ICompiledTemplateWithVariables, rows: TGeneralFieldArray): TArrayID {

    const positional: TArrayID = [];

    for (const field of variablesTemplate.variables) {
        let value: string;
        value = field.value ? field.value : <string>getSmartResponse(field.idProperty, rows).value
        positional.push(value);
    }
    return positional;
}

export function getHashFromVariables(variables: TPropertyKeyArray, rows: TGeneralFieldArray): IFileField {
    const hashVariables: IFileField = {};

    for (const field of variables) {
        let value: string;
        value = field.value ? field.value : <string>getSmartResponse(field.idProperty, rows).value

        hashVariables[field.idProperty] = value;
    }
    return hashVariables;
}

export function getHashVariables(variablesTemplate: ICompiledTemplateWithVariables, rows: TGeneralFieldArray): IFileField {
    return getHashFromVariables(variablesTemplate.variables, rows);
}

export function replaceHashVariablesInString(str: string, variables: IFileField): string {
    for (const variableKey of Object.keys(variables)) {
        str = replaceString(str, `${textCompiledDelimeters.startCharacter}${variableKey}${textCompiledDelimeters.endCharacter}`, variables[variableKey])
    }
    return str;
}

export function getVariablesTemplateOld_(text: string, variables: TIVariablesArray): any {
    const allWords = text.split(' ');
    const textCompiled = [];

    const schemaVariablesTemplate: any = {
        idProperties: [],
        textCompiled: '',
    };

    allWords.map(word => {
        const auxWord = getWordWithoutCharacters(word);
        const idProperty = getIdProperty(auxWord, variables);

        if (idProperty) {
            schemaVariablesTemplate.idProperties.push(idProperty);
            textCompiled.push(
                word.replace(auxWord, `${textCompiledDelimeters.startCharacter}${schemaVariablesTemplate.idProperties.length - 1}${textCompiledDelimeters.endCharacter}`)
            );

        } else {
            textCompiled.push(word);
        }
    });

    schemaVariablesTemplate.textCompiled = textCompiled.join(' ');

    return schemaVariablesTemplate;
}


export function getVariablesTemplate(text: string, variables: TIVariablesArray): TPropertyKeyArray {
    const allWords = text.split(' ');
    const textCompiled = [];
    const idProperties: TPropertyKeyArray = [];

    allWords.forEach(word => {
        const auxWord = getWordWithoutCharacters(word);
        const idProperty = getIdProperty(auxWord, variables);

        if (idProperty) {
            idProperties.push({
                idProperty: idProperty
            });
        }
    });
    return idProperties;
}

function getIdProperty(variable: string, possibleTemplateVariables: TIVariablesArray): string {
    const schemaVariable = possibleTemplateVariables.find(v => v.text === variable);
    return isValidRef(schemaVariable)
        ? schemaVariable.idProperty
        : undefined;
}

export function compiledTemplateToSendGridTemplate(template: string, variables: TIVariablesArray): string {
    return replaceMetadataNameWithIdProperty(
        template,
        variables,
        textCompiledDelimeters.startCharacter,
        textCompiledDelimeters.endCharacter
    );
}

export function replaceMetadataNameWithIdProperty(message: string, variables: TIVariablesArray, openChar: string, closeChar: string): string {
    for (const variable of variables) {
        message = message.replace(variable.text, `${openChar}${variable.idProperty}${closeChar}`);
    }

    return message;
}


export function isAllReplaced(message: string): boolean {
    return !message.includes(noMatchFound);
}


export function replaceCompiledMessageByTagContent(message: string, variablesTemplate: ICompiledTemplateWithVariables,
    tags: TColmeiaTagArray, computedInfo: TComputedInfo, embededStr: IEmbededStrCommands, transformer?: (idProperty: string, value: string) => string): string {

    const matches: TArrayID = getVariablesOfTemplate(message);

    if (isInvalidArray(matches)) {
        return message;
    }

    for (const match of matches) {
        const idProperty: string = removeBracket(match);
        let value: string = '';
        const injectedStr: string = embededStr[idProperty]

        if (isValidRef(computedInfo[idProperty])) {
            value = computedInfo[idProperty];

        } else {
            const field = tags.find((r) => { return r.idNS === idProperty });
            value = isValidRef(field) ? <string>field.value : '';
        }

        if (isValidRef(injectedStr)) {
            value = injectedStr + value;
        }

        //Tenta colocar valores que foram fixados
        if (!isValidString(value)) {
            const el: IPropertyKey = variablesTemplate.variables.find((prop) => { return prop.idProperty === idProperty && isValidString(prop.value) });
            if (el) {
                value = el.value;
            } else {
                value = noMatchFound;
            }
        }

        // optimus prime, ih uh ah eh ih uh
        value = isValidFunction(transformer) ? transformer(idProperty, value) : value;

        message = replaceString(message, match, <string>value);
    }
    return message;
}


export function replaceBasedOneDoubleBracketsWithPositionalVariables(message: string, positionalValues: Array<string | number>): string {
    const matches = getVariablesOfTemplate(message);

    if (isInvalidArray(matches)) {
        return message;
    }

    for (const match of matches) {
        const index = parseInt(removeBracket(match)) - 1;
        message = replaceString(message, match, <string>positionalValues[index])
    }
    return message;
}



export function multipleAnswerPropValidation(property: SchemaProperty, answers: Array<any>): ValidatePropResult {
    const allValidation = answers.map(answer => validateProp(property, answer));
    const firstError = allValidation.find(validation => !validation.success);
    return {
        success: isInvalid(firstError),
        error: firstError
            ? firstError.error
            : undefined
    }
}

export function validateProp(property: SchemaProperty, value: any): ValidatePropResult {

    const result: ValidatePropResult = {
        success: true
    };

    switch (property.idUnity) {
        case EUnitTypeID.multipleChoiceType:
            if (!property.choices.some(choice => choice.text == value)) {
                result.success = false;
                result.error = {
                    type: EPropValidationErrorType.UnexpectedPropertyType,
                    propertyName: property.propertyName,
                    aditional: `expected one of: ( ${property.choices.map(c => c.text).join(', ')} ) found: ${value}`
                }
            }
            break;
        case EUnitTypeID.objectType:
            // é um objeto e contém todos os child fields e nenhum a mais nem a menos (dos obrigatórios)
            if (!(typeof value === 'object')) {
                result.success = false;
                result.error = {
                    type: EPropValidationErrorType.UnexpectedPropertyType,
                    propertyName: property.propertyName,
                    aditional: `expected object, found: ${typeof value}`
                };
                return result;
            }
            const allRequiredProperties = property.nestedSchema
                .filter(property => property.required)
                .map(property => property.propertyName);
            for (const objKey in value) {
                if (!property.nestedSchema.find(fld => fld.propertyName === objKey)) {
                    result.success = false;
                    result.error = {
                        type: EPropValidationErrorType.UnexpectedPropertyType,
                        propertyName: objKey,
                        aditional: `Unknown property ${objKey}`
                    };
                    return result;
                }
            }
            if (isValidArray(allRequiredProperties)) {
                result.success = false;
                result.error = {
                    type: EPropValidationErrorType.MissingProperty,
                    propertyName: allRequiredProperties.join(', '),
                    aditional: `Missing the following properties: ${allRequiredProperties.join(', ')}`
                }
            }
            break;
        case EUnitTypeID.stringType:
            if (!(typeof value === 'string')) {
                result.success = false;
                result.error = {
                    type: EPropValidationErrorType.UnexpectedPropertyType,
                    propertyName: property.propertyName,
                    aditional: `expect: ${property.idUnity} found: ${typeof value}`
                }
            }
            break;
        case EUnitTypeID.numberType:
            break;
        case EUnitTypeID.logicalType:
            if (!(value == 0 || value == 1 || typeof value === 'boolean')) {
                result.success = false;
                result.error = {
                    type: EPropValidationErrorType.UnexpectedPropertyType,
                    propertyName: property.propertyName,
                    aditional: `expect: string found ${typeof value}`
                }
            }
            break;
        case EUnitTypeID.avatarInput:
    }
    return result;
}

export function fetchGlobalCanonical(idLocal: string, canonicals: IServerLocalCanonicalArray): EMetadataNames {
    const canon = canonicals.find(cnn => cnn.idNS === idLocal);
    return canon
        ? canon.globalCanonical
        : null;

}

export function hasCanonicalOnAnswer(responses: TGeneralFieldArray): boolean {
    return responses.some(answer => isValidString(answer.idLocalCanonical))
}

export function getLocalCanonicalsFromForm(form: TSchemaPropertyArray): TArrayID {
    return form.filter((f) => { return isValidRef(f.idLocalCanonical) }).map((r) => { return r.idLocalCanonical });
}

export function flatResponses(forms: TGeneralFormAnswerArray): TGeneralFieldArray {
    const responses: TGeneralFieldArray = []
    if (isValidArray(forms)) {
        forms.forEach((shForm) => {
            responses.push(...shForm.responses);
        })
    }
    return responses;
}


export function getComputedInfo(fieldArray: TGeneralFieldArray): TComputedInfo {
    const hash: TComputedInfo = {};
    for (const field of fieldArray) {
        addFieldToHash(hash, field);
    }
    return hash;
}

export function getComputedInfoWithIDPriority(fieldArray: TGeneralFieldArray): TComputedInfo {
    const hash: TComputedInfo = {};
    for (const field of fieldArray) {
        addFieldToHashWithIDPriority(hash, field);
    }
    return hash;
}

export function addFieldToHashWithIDPriority(hash: TComputedInfo, field: IRFieldResponse): void {
    const returningID = isValidString(<string>field.returningID) ? <string>field.returningID : <string>field.value;
    hash[field.idProperty] = returningID;
    if (field.idLocalCanonical) {
        hash[field.idLocalCanonical] = returningID;
    }
    if (field.idGlobalCanonical) {
        hash[field.idGlobalCanonical] = returningID;
    }
}




export function fromComputedToPropertyArray(computed: TComputedInfo): TPropertyKeyArray {
    const prop: TPropertyKeyArray = [];
    if (isValidObject(computed)) {
        Object.keys(computed).forEach((idProperty) => {
            prop.push({
                idProperty: <string>idProperty, value: computed[idProperty]
            });
        })
    }
    return prop;
}

export function computeToCanonicalPair(compute: TComputedInfo): TIRFieldResponseBasis {
    if (isEmptyObject(compute)) {
        return [];
    }
    const ret: TIRFieldResponseBasis = []
    Object.keys((prop) => {
        if (isInEnum(EMetadataNames, prop)) {
            ret.push({
                idGlobalCanonical: prop,
                raw: compute[prop]
            })

        }
    })
    return ret;
}

export function getGlobalCanonicalListFromLocals(local: IServerLocalCanonicalArray): TMetadataNameArray {
    return local.filter((f) => { return isValidRef(f.globalCanonical) }).map((e) => { return e.globalCanonical })
};

export function isAllPropertiesFulfilled(properties: TArrayID, responses: TGeneralFieldArray): boolean {
    return properties.every((idP) => {
        return responses.some((r) => { return r.idProperty === idP })
    })
}

export function getAllFieldsBefore(field: SchemaProperty, fields: TSchemaPropertyArray): TSchemaPropertyArray {
    let reached: boolean = false;

    return fields.filter(fld => {
        if (!reached)
            reached = fld.idProperty === field.idProperty;

        return !reached;
    });
}

export function getAllLocalCanonicalIds(form: TSchemaPropertyArray, result: TArrayID = []): TArrayID {
    form.forEach(field => {
        if (isValidString(field.idLocalCanonical)) {
            result.push(field.idLocalCanonical);
        }

        if (field.idUnity === EUnitTypeID.objectType && isValidArray(field.nestedSchema)) {
            getAllLocalCanonicalIds(field.nestedSchema, result);
        }
    });

    return arrayUnique(result);
}

export function generateDatabaseMapperFromFormSchema(formSchema: IFormSchema): ICSVToSchemma {
    return formSchema.form.reduce<ICSVToSchemma>((obj, property, pos) => {
        obj[property.idProperty] = {
            pos,
            name: property.propertyName,
        };

        return obj
    }, {});
}

export function getEngagementConfig<T extends IEngagementConfig>(engagement: IMetaEngagement, engagementType: EMetadataEngagementType): T {
    return engagement.engagementConfig[engagementType] as T;
}

export function addSchemaAnswer(value: string, idProperty: string, schema: TSchemaPropertyArray, hash: TComputedInfo): void {
    const field = getPropertyById(schema, idProperty);
    addFieldToHash(hash, {
        ...field,
        value,
        raw: value
    } as IRFieldResponse);
}

export function addFieldToHash(hash: TComputedInfo, field: IRFieldResponse): void {
    hash[field.idProperty] = <string>field.value;
    if (field.idLocalCanonical) {
        hash[field.idLocalCanonical] = <string>field.value
    }
    if (field.idGlobalCanonical) {
        hash[field.idGlobalCanonical] = <string>field.value
    }
}

export function fillGrettingWithCustomClocktick(
    targetHash: IFileField,
    variables: TICampaingVariableConfigArray,
    clockTick: number,
    timezone: number = -3,
): void {
    if (isInvalidArray(variables)) {
        return;
    }
    for (const v of variables) {
        if (v.isGreetings) {
            targetHash[v.idLocalCanonical] = getGreeting(timezone, clockTick);
        }
    }

}
export type TGlobalToFieldMap = {
    [global in keyof EMetadataNames]?: SchemaProperty
}

export function mapGlobalToField(schema: TSchemaPropertyArray, localDB: TCanonicalDB, result: TGlobalToFieldMap = {}): TGlobalToFieldMap {
    return schema.reduce((obj, field) => {
        const hasMetaname = field.idLocalCanonical && localDB[field.idLocalCanonical].globalCanonical;

        if (hasMetaname) {
            const metaName = localDB[field.idLocalCanonical].globalCanonical;

            obj[metaName] = field;

        } else if (field.idUnity === EUnitTypeID.objectType && isValidArray(field.nestedSchema)) {
            mapGlobalToField(field.nestedSchema, localDB, result);
        }

        return obj;
    }, result);
}

export function getTicketBasicDataToComputedInfo(ticketFullData: ICRMTicketDataBasic): TComputedInfo {
    return {
        [EMetadataNames.ticketTitle]: ticketFullData.title,
        [EMetadataNames.ticketProtocol]: String(ticketFullData.protocol),
        [EMetadataNames.ticketCloseState]: ticketFullData.currentStatusNames.closeStateName,
        [EMetadataNames.ticketServicePhase]: ticketFullData.currentStatusNames.phaseName,
        [EMetadataNames.ticketSeverity]: ticketFullData.currentStatusNames.severityName,
        [EMetadataNames.ticketSupportLevel]: ticketFullData.currentStatusNames.supportLevelName,
        [EMetadataNames.ticketTicketState]: ticketFullData.currentStatusNames.ticketStateName,
        [EMetadataNames.ticketUrgency]: ticketFullData.currentStatusNames.urgencyLevelName,
        [ENonSerializableObjectType.crmItemCloseState]: ticketFullData.currentStatus.idCloseState,
        [ENonSerializableObjectType.crmItemPhase]: ticketFullData.currentStatus.idPhase,
        [ENonSerializableObjectType.crmItemSeverity]: ticketFullData.currentStatus.idSeverity,
        [ENonSerializableObjectType.crmItemState]: ticketFullData.currentStatus.idTicketState,
        [ENonSerializableObjectType.crmItemSupportLevel]: ticketFullData.currentStatus.idSupportLevel,
        [ENonSerializableObjectType.crmItemUrgencyLevel]: ticketFullData.currentStatus.idUrgencyLevel,
    }
}

export function extractIdTicketFromAnswer(answer: IGeneralFormAnswer, engagement: IMetaEngagement): string {

    const smartCRMs = getSmartFieldEngagements(ESmartField.CRM, engagement);
    const lastCRM = <IFieldCRMEngagement>smartCRMs?.pop();

    if (!lastCRM) return;

    const response = getResponseByIDProperty(lastCRM.idFieldForm, answer.responses);

    return response?.returningID || response?.raw;
}