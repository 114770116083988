import { WAFValidator } from '@colmeia/core/src/shared-business-rules/waf/waf.validator';
import { EDelivery360Action } from '../../comm-interfaces/barrel-comm-interfaces';
import { TSchemaPropertyArray } from "../../general-form/general-form-interface";
import { calculateObjectJSONByteLength, ETypeOf, getIfNotValid, getProperty, getReadableUniqueID, getUniqueStringID, isAllNumeric, isAllValid, isInEnum, isInvalid, isInvalidArray, isInvalidObject, isNumeric, isThisOneOfThat, isValidArray, isValidArrayAndRef, isValidNumber, isValidObject, isValidRef, isValidRegex, isValidString, isValidTrimmedString, swapList, SwapList, typedClone } from "../../tools/utility";
import { EBPMAction } from "../BPM/bpm-action-model";
import { isValidBPM, isValidBPMAction, isValidBPMEvaluators } from "../BPM/bpm-functions";
import { IBPConditionalEvaluator, TIBPConditionalEvaluatorArray } from "../BPM/bpm-model";
import { ECSContentComparison, ECSFormatField } from "../corporate-search/corporate-search-model";
import { SchemaPropertyServer } from "../files/files";
import {
    EAddressFields,
    ECSPredicateType, EHandleOperationsSide, EMetadataEngagementType,
    EMultimediaExtractionMethod,
    EMultimediaProcessType,
    engagementConfigDB,
    ESmartCSFireOption,
    ICRMTransformationConfig,
    IEngagementConfig,
    IEngagementConfigPosCondition,
    IEngagementConfigPosForm,
    IFieldCRMEngagement,
    IFieldEntityMapper,
    IFieldMapper,
    IFieldMultimediaSmart,
    IFieldSmart2FV, IFieldSmartAddress,
    IFieldSmartCorporateSearch, IFieldSmartDocReaderEngagement, IFieldSmartMapper,
    IFieldSmartNPS,
    IFieldSmartPayment,
    IFieldSmartShakingHandsValidator, IFieldUserConfirmationSmart, IFielSmartFieldReuse, ILGPDConditionalMapper, IMetaEngagement,
    INewConditionFieldMapper,
    IPaymentAccountData,
    IPaymentEngagement,
    IPaymentIncomingMessage,
    IPaymentMainConfigs,
    IPaymentMessageActionsConfigs,
    IPaymentOutgoingMessage,
    IPaymentPayerData,
    IPaymentRequirementsData,
    IRealTimeFusionConfig,
    IRealTimeFusionField, IRichFormExperience, ISearchMapperToCS, ISendToChannelByAgentEngagementConfig, isRichFillField, isSmartField, smartFieldConfigDB, TEngagementDescriptor, TIChoiceConfigureArray, TIFieldSmartMapperArray,
    TINewConditionFieldMapperArray
} from "../metadata/meta-engagement";
import { validateEntity } from "../non-serializable-id/validation/helpers";
import { ESmartField, TESmartFieldArray } from "../smart/smart-fields";
import { ETokenType, I2FASettings, MinimumTokenExpireInSeconds } from "../two-factor-validation/two-factor-model";
import { isValidAssetAction, isValidAssetActionArray, isValidContentArray } from "./asset-functions";
import { EBotContentEvent } from "./bot-content-model";
import { hasContentOfTypes, isElementWithRenderOptions } from "./bot-function-model-helper";
import { EMenuMode, IBotElementWithRenderOptions, initDefaultRenderOptions, ISmartAddressDynamicMenuRenderOptions } from './bot-model';
import { TBotActionArray } from '@colmeia/core/src/shared-business-rules/bot/bot-action-model';
import { TBotActionTypeArray } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { EDOCSegment } from '../doc-reader-v2/field-extractors/shared-types';
import { EPaymentItemAttributes, TIPaymentAttrMapArray } from '../payments/payments.types';

interface IValidatorEngagement {
    mustHaveConfig?: boolean;
}

const engagmentValidatorDB: { [meta in EMetadataEngagementType]: IValidatorEngagement } = {
    [EMetadataEngagementType.mktEvents]: { mustHaveConfig: true },
    [EMetadataEngagementType.realTimeFusion]: { mustHaveConfig: true },
    [EMetadataEngagementType.CRMTransformation]: { mustHaveConfig: true },
    [EMetadataEngagementType.NewCondition]: { mustHaveConfig: false },
    [EMetadataEngagementType.entityMapper]: { mustHaveConfig: false },
    [EMetadataEngagementType.smartMapper]: { mustHaveConfig: false },
    [EMetadataEngagementType.richFillForm]: { mustHaveConfig: false },
    [EMetadataEngagementType.integrationByChannel]: { mustHaveConfig: true },
    [EMetadataEngagementType.integration]: { mustHaveConfig: true },
    [EMetadataEngagementType.layout]: { mustHaveConfig: false },
    [EMetadataEngagementType.solicitation]: { mustHaveConfig: false },
    [EMetadataEngagementType.whatsAppFlow]: { mustHaveConfig: true },

};

export const ERROR_MESSAGE = 'errorMsg';

export interface IEngagementRecord<T extends IFieldMapper, Y extends IEngagementConfig> {
    config: Y;
    fields: Array<T>
}

export function isAllEngagementValid(engagement: IMetaEngagement): boolean {
    const allEngangementsUsed: Array<EMetadataEngagementType> = [];
    if (isValidObject(engagement.engagementConfig)) {
        allEngangementsUsed.push(...<Array<EMetadataEngagementType>>Object.keys(engagement.engagementConfig))
    }

    if (isValidArray(engagement.descriptor)) {
        for (const eng of engagement.descriptor) {
            if (allEngangementsUsed.every((e) => { return e !== eng.engagementType })) {
                allEngangementsUsed.push(eng.engagementType)
            }
        }
    }

    for (const type of allEngangementsUsed) {
        if (!isValidEngagementOfType(type, engagement)) {
            return false;
        }
    }
    return true;
}


export function emptyMetaEngagement(): IMetaEngagement {
    return {
        idMetaEngagement: getReadableUniqueID(8),
        descriptor: [],
        engagementConfig: {},
        elementsOrder: []
    }
}

export function getEngagementsOfType<T extends IFieldMapper, Y extends IEngagementConfig>(engagement: IMetaEngagement, engagementType: EMetadataEngagementType): IEngagementRecord<T, Y> {

    if (isValidEngagementOfType(engagementType, engagement)) {
        let config: Y;
        let fields: T[] = [];
        config = isValidRef(engagement.engagementConfig) && isValidEngagementConfig(engagementType, engagement) ? engagement.engagementConfig[engagementType] as Y : undefined;
        fields = getFieldEngagementOfType<Array<T>>(engagement, engagementType);
        return {
            config,
            fields
        };
    };
    return undefined;
}

export function canHaveAdditionalOptions(field: IFieldMapper): boolean {
    const config = engagementConfigDB[field.engagementType];

    if (!config.allowAdditionalOptions?.enabled) {
        return false;        
    }

    if (isRichFillField(field) && !field.hasConfigMultipleChoice) {
        return false;
    }
    
    if (isSmartField(field)) {
        if (!isInEnum(ESmartField, field.smart)) {
            return false;
        }
        
        return canSmartfieldHaveAdditionalOptions(field);
    }

    return true;
}

export function canSmartfieldHaveAdditionalOptions(field: IFieldSmartMapper): boolean {
    const config = smartFieldConfigDB[field.smart];

    return !!config.allowAdditionalOptions?.enabled;
}

export function getAdditionalOptionsActions(field: IFieldMapper): TBotActionTypeArray {
    if (isSmartField(field)) {
        return [...smartFieldConfigDB[field.smart].allowAdditionalOptions?.allowedBotActions];
    }
    
    return [...engagementConfigDB[field.engagementType].allowAdditionalOptions?.allowedBotActions];
}

export function getEngagementConfigOfType<Y extends IEngagementConfig>(engagement: IMetaEngagement, engagementType: EMetadataEngagementType): Y {

    if (isValidEngagementOfType(engagementType, engagement)) {
        const config: Y = (isValidRef(engagement.engagementConfig) && isValidEngagementConfig(engagementType, engagement))
            ? engagement.engagementConfig[engagementType] as Y
            : undefined;
        return config
    };
    return undefined;
}

export function getFieldConfirmationEngagement(idProperty: string, engagement: IMetaEngagement): IFielSmartFieldReuse {
    return getSmartFieldEngagementByIdProperty<IFielSmartFieldReuse>(idProperty, ESmartField.FieldReuse, engagement);
}


export function getEngangementsOfTypeForIDField<T extends TIFieldSmartMapperArray>(idProperty: string, engagement: IMetaEngagement, engagementType: EMetadataEngagementType): T {
    const engagements = getFieldEngagementOfType(engagement, engagementType);
    if (isValidArray(engagements)) {
        return <T>engagements.filter((x) => { return x.idFieldForm === idProperty })
    };
    return undefined;
};

export function getEngangementOfTypeForIDField<T extends IFieldSmartMapper>(idProperty: string, engagement: IMetaEngagement, engagementType: EMetadataEngagementType): T {
    const engagements = getFieldEngagementOfType(engagement, engagementType);
    if (isValidArray(engagements)) {
        return <T>engagements.find((x) => { return x.idFieldForm === idProperty })
    };
    return undefined;
};

export function getEngagementsOfTypeForIDField<T extends IFieldMapper>(idProperty: string, engagement: IMetaEngagement): T[] {
    if (isValidArray(engagement?.descriptor)) {
        return engagement.descriptor.filter((x: T) => { return x.idFieldForm === idProperty }) as T[]
    };
    return undefined;
};

export function getSmartFieldOfTypes(engagement: IMetaEngagement, idProperty: string, ...smarts: TESmartFieldArray): TESmartFieldArray {
    const engagements: TEngagementDescriptor = getFieldEngagementOfType(engagement, EMetadataEngagementType.smartMapper)
        .filter(mapper => mapper.idFieldForm === idProperty);

    if (isValidArray(engagements)) {
        const noFilter: boolean = isInvalidArray(smarts);

        const pertinentSmarts: TESmartFieldArray = engagements.filter((x: IFieldSmartMapper) => {
            return (noFilter || isThisOneOfThat(x.smart, ...smarts) && isValidFieldMapper(x.engagementType, x))
        }).map((x: IFieldSmartMapper) => { return x.smart })

        return pertinentSmarts;

    }
    return [];

}

export function hasSmartFieldOf(engagement: IMetaEngagement, ...smarts: TESmartFieldArray): boolean {
    const engagements: TEngagementDescriptor = getFieldEngagementOfType(engagement, EMetadataEngagementType.smartMapper);
    if (isValidArray(engagements)) {
        const noFilter: boolean = isInvalidArray(smarts);

        const pertinentSmarts: TESmartFieldArray = engagements.filter((x: IFieldSmartMapper) => {
            return (noFilter || isThisOneOfThat(x.smart, ...smarts) && isValidFieldMapper(x.engagementType, x))
        }).map((x: IFieldSmartMapper) => { return x.smart })

        return isValidArray(pertinentSmarts);

    }
    return false;
}



export function getSmartFieldOfType<T extends IFieldMapper>(idProperty: string, engagement: IMetaEngagement, type: ESmartField): T {
    const engagements = <TIFieldSmartMapperArray>getFieldEngagementOfType(engagement, EMetadataEngagementType.smartMapper);
    const smart = engagements.find(e => e.idFieldForm === idProperty && e.smart === type);
    return <T>smart;
}

function hasReturnedEvaluator(many: TINewConditionFieldMapperArray): boolean {
    return isValidArray(many) && many.some(e => isValidArray(e.evaluators));
}


export function getValidEvaluatorForField(idProperty: string, bpmActionType: EBPMAction, engagement: IMetaEngagement, tpEngagement: EMetadataEngagementType = EMetadataEngagementType.NewCondition): IBPConditionalEvaluator {
    const fields = getEngangementsOfTypeForIDField<TINewConditionFieldMapperArray>(idProperty, engagement, tpEngagement);
    if (hasReturnedEvaluator(fields)) {
        for (const field of fields) {
            const condEvaluator = field.evaluators.find((x) => { return x.action.bpmAction === bpmActionType && isValidBPM(x) });
            if (isValidRef(condEvaluator)) {
                return condEvaluator;
            }
        }
    };
    return undefined;
}

export function getValidEvaluatorsForField(idProperty: string, bpmActionType: EBPMAction, engagement: IMetaEngagement, tpEngagement: EMetadataEngagementType = EMetadataEngagementType.NewCondition): IBPConditionalEvaluator[] {
    const fields = getEngangementsOfTypeForIDField<TINewConditionFieldMapperArray>(idProperty, engagement, tpEngagement);
    if (hasReturnedEvaluator(fields)) {
        for (const field of fields) {
            const condEvaluators = getEvaluatorsByBPMType(field.evaluators, bpmActionType);

            if (condEvaluators.length) {
                return condEvaluators;
            }
        }
    };
    return undefined;
}

export function getAllEvaluatorsOnField(idProperty: string, bpmActionType: EBPMAction, engagement: IMetaEngagement, tpEngagement: EMetadataEngagementType = EMetadataEngagementType.NewCondition): TIBPConditionalEvaluatorArray {
    const fields = getEngangementsOfTypeForIDField<TINewConditionFieldMapperArray>(idProperty, engagement, tpEngagement);
    const ret: TIBPConditionalEvaluatorArray = [];
    if (hasReturnedEvaluator(fields)) {
        for (const field of fields) {
            const condEvaluator = getEvaluatorsByBPMType(field.evaluators, bpmActionType);
            if (isValidArray(condEvaluator)) {
                ret.push(...condEvaluator);
            }
        }
    };
    return ret;
}

export function getEvaluatorsByBPMType(evaluators: TIBPConditionalEvaluatorArray, bpmActionType: EBPMAction) {
    return evaluators.filter((x) => { return x.action.bpmAction === bpmActionType && isValidBPM(x) });
}

export function getEvalutorsFromPosForm(engagement: IMetaEngagement): TIBPConditionalEvaluatorArray {
    const engag = getEngagementConfigOfType<IEngagementConfigPosCondition>(engagement, EMetadataEngagementType.NewCondition);
    return engag?.evaluators?.filter(x => isValidBPM(x))
}


export function getSchemaByEngagementOrder(engagement: IMetaEngagement, schema: SchemaPropertyServer): SchemaPropertyServer {
    schema = typedClone(schema);
    schema.schemma.form = sortItemsByIdPropertyOrder(engagement.elementsOrder, schema.schemma.form, 'idProperty');
    return schema;
}
export function getEngagementOnOrder(engagement: IMetaEngagement): IMetaEngagement {
    engagement = typedClone(engagement);
    engagement.descriptor = sortItemsByIdPropertyOrder(engagement.elementsOrder, engagement.descriptor, 'idFieldForm');
    return engagement;
}
export function getSchemaPropertiesByEngagementOrder(engagement: IMetaEngagement, properties: TSchemaPropertyArray): TSchemaPropertyArray {
    properties = typedClone(properties);
    return sortItemsByIdPropertyOrder(engagement.elementsOrder, properties, 'idProperty');
}

function sortItemsByIdPropertyOrder<T extends Record<string, any>>(elementsOrder: string[], elements: T[], property: keyof T) {
    const elementsOrderIndexes: SwapList<string[]> = swapList(elementsOrder);
    elements = elements.sort((element: T, otherElement: T) => {
        return isValidRef(getOrder(otherElement)) ? (getOrder(element) - getOrder(otherElement)) : -1;
    });
    return elements;
    function getOrder(element: T): number {
        const idProperty: string = getProperty(element)(property);
        const idPropertyOrder: number = elementsOrderIndexes[idProperty];
        return idPropertyOrder;
    }
}

export function getSmartFieldEngagementByIdProperty<T extends IFieldSmartMapper>(idProperty: string, smart: ESmartField, engagement: IMetaEngagement): T {
    const engagements: IFieldSmartMapper[] = getSmartFieldEngagements(smart, engagement);
    if (isValidArray(engagements)) {
        return <T>engagements.find((x) => { return x.idFieldForm === idProperty })
    };
}



export function getSmartFieldEngagements<T extends IFieldSmartMapper>(smart: ESmartField, engagement: IMetaEngagement): T[] {
    const engagements = getFieldEngagementOfType(engagement, EMetadataEngagementType.smartMapper);
    if (isValidArray(engagements)) {
        return engagements.filter((x: T) => { return x.smart === smart }) as T[];
    };
}

export function get2FATarget(settings: I2FASettings): string {
    return [settings.idComparingCanonical].find(item => isValidRef(item));
}
export function get2FAEngagementByIdTarget(idLocalCanonicalTarget: string, engagement: IMetaEngagement): IFieldSmart2FV {
    const engagements: IFieldSmartMapper[] = getSmartFieldEngagements(ESmartField.TwoFactorValidation, engagement);
    return engagements?.find?.((item: IFieldSmart2FV) => [item.settings.idComparingCanonical].filter(item => isValidRef(item)).includes(idLocalCanonicalTarget)) as IFieldSmart2FV;
}

export function get2FAEngagementByIdProperty(idProperty: string, engagement: IMetaEngagement): IFieldSmart2FV {
    return getSmartFieldEngagementByIdProperty<IFieldSmart2FV>(idProperty, ESmartField.TwoFactorValidation, engagement);
}

export function getNPSEngagement(idProperty: string, engagement: IMetaEngagement): IFieldSmartNPS {
    return getSmartFieldEngagementByIdProperty<IFieldSmartNPS>(idProperty, ESmartField.NPS, engagement);
}


export function getLGPDEngagement(idProperty: string, engagement: IMetaEngagement): ILGPDConditionalMapper {
    return getSmartFieldEngagementByIdProperty<ILGPDConditionalMapper>(idProperty, ESmartField.LGPD, engagement);
}

export function getSmartConfirmation(idProperty: string, engagement: IMetaEngagement): IFieldUserConfirmationSmart {
    return getSmartFieldEngagementByIdProperty<IFieldUserConfirmationSmart>(idProperty, ESmartField.smartConfirmation, engagement);
}


export function isValidEngagementConfig(type: EMetadataEngagementType, engagement: IMetaEngagement): boolean {
    if (isInvalid(engagement)) {
        return false;
    }
    const info = isValidRef(engagement.engagementConfig) ? engagement.engagementConfig[type] : undefined;

    if (isInvalid(info)) {
        return !engagmentValidatorDB[type].mustHaveConfig;
    }

    switch (type) {
        case EMetadataEngagementType.realTimeFusion: {
            const gather = <IRealTimeFusionConfig>info;
            return isValidString(gather.idInformationGather);
        };

        case EMetadataEngagementType.CRMTransformation: {
            const crm = <ICRMTransformationConfig>info;
            return isAllValid(crm.idRoute, crm.recordType)
        }

        case EMetadataEngagementType.integrationByChannel: {
            const conf = <ISendToChannelByAgentEngagementConfig>info;
            return isValidArray(conf.contentArray)
                && isValidArray(conf.toTargetArray) && isValidRef(conf.idChannel)
                && hasContentOfTypes(conf.contentArray, [EBotContentEvent.preContent, EBotContentEvent.posContent]);
        };

        case EMetadataEngagementType.mktEvents: {
            return true;
        }

        case EMetadataEngagementType.integration: {
            const posProcessing: IEngagementConfigPosForm = <IEngagementConfigPosForm>info;
            return isValidString(posProcessing.idUserFunction);
        };

        case EMetadataEngagementType.whatsAppFlow: {
            return WAFValidator.validateFlowEngagement(engagement);
        };

    }

    // Até não colocar todas as regras de validação, não mexer no que está funcionando
    return true;
}

export function isValidEngagement(engagement: IMetaEngagement, minDescriptorLength: number = 1): boolean {
    if (isInvalid(engagement) || isInvalidArray(engagement.descriptor, minDescriptorLength)) {
        return false;
    }
    const processed: { [et: string]: boolean } = {};
    for (const fieldRule of engagement.descriptor) {
        if (!processed[fieldRule.engagementType]) {
            const ok = isValidEngagementOfType(fieldRule.engagementType, engagement);
            if (!ok) {
                return false;
            }
        };
        processed[fieldRule.engagementType] = true;
    };
    return true;
};


export function isValidEngagementOfType(type: EMetadataEngagementType, engagement: IMetaEngagement): boolean {
    if (isInvalid(engagement)) {
        return false;
    }

    if (!isValidEngagementConfig(type, engagement)) {
        return false;
    }

    const ok = engagement.descriptor.filter((x) => { return x.engagementType === type })
        .every((e) => { return isValidFieldMapper(type, e) })
    return ok;
}





export function getFieldEngagementOfType<T extends TEngagementDescriptor>(engagement: IMetaEngagement, engagementType: EMetadataEngagementType): T {
    if (isValidRef(engagement) && isValidArray(engagement.descriptor)) {
        const fields = <T>engagement.descriptor.filter((x) => { return x.engagementType === engagementType && isValidFieldMapper(engagementType, x) });
        return fields;
    }
    return <T>[];
}

export function isValidFieldMapper(engagementType: EMetadataEngagementType, mapper: IFieldMapper): boolean {
    switch (engagementType) {
        case EMetadataEngagementType.NewCondition: {
            return isValidBPMEvaluators((<INewConditionFieldMapper>mapper).evaluators)
        };

        case EMetadataEngagementType.realTimeFusion: {
            return isValidString((<IRealTimeFusionField>mapper).searchGroupName);
        };

        case EMetadataEngagementType.smartMapper:
            return isValidSmartField(<IFieldSmartMapper>mapper);

        case EMetadataEngagementType.richFillForm:
            return isValidRichForm(<IRichFormExperience>mapper);
    };
    return true;
};

export function isValidSmartField(field: IFieldSmartMapper): boolean {
    if (
        canSmartfieldHaveAdditionalOptions(field)
        && field.isAdditionalOptOn
        && !isValidAssetActionArray(field.additionalActions)
    ) {
        return false;
    }

    switch (field.smart) {
        case ESmartField.Address: {
            const aux = <IFieldSmartAddress>field;
            const commonConditions = isValidRegex(aux.zipCodeRegex) &&
                (aux.allowAddress || aux.allowGeo || aux.allowZip) &&
                isValidContentArray(aux.onAddressNotFound) &&
                isValidContentArray(aux.invalidSearchMethod);

            // Mantem compatibilidade com smartAddress criados antes da implementação do menu completo
            if (isInvalidObject(aux.menuRenderOptions))
                return commonConditions;

            const smartAddressConfigWithRenderOptions = isValidArray(aux.menuRenderOptions.title) &&
                isValidArray(aux.menuRenderOptions.description) &&
                isValidObject(aux.menuRenderOptions.renderOptions);

            let optionalFlags = true;
            if (aux.removeSearchResultsWithInvalidCEP)
                optionalFlags = isValidContentArray(aux.onSearchResultsWithInvalidCEP);

            return commonConditions && smartAddressConfigWithRenderOptions && optionalFlags;
        };

        case ESmartField.LGPD: {
            const condition = <ILGPDConditionalMapper>field;
            return isValidBPMEvaluators(condition.evaluators)
        };

        case ESmartField.NPS: {
            const aux = <IFieldSmartNPS>field;
            return (
                isValidString(aux.lowerRate) &&
                isValidString(aux.higherRate) &&
                isValidBPMAction(aux.action) &&
                (isNumeric(aux.lowerRate) ? isNumeric(aux.higherRate) :
                    (!isNumeric(aux.higherRate) && aux.higherRate.trim().length === 1 && aux.lowerRate.trim().length === 1)))
        };

        case ESmartField.QRCode: {
            return true;
        };

        case ESmartField.TwoFactorValidation: {
            const aux = <IFieldSmart2FV>field;
            return (
                isValidRef(aux.settings) &&
                isValidNumber(aux.settings.tokenSize, 3) &&
                isValidString(aux.settings.idCampaignAction, 10) &&
                isInEnum(ETokenType, aux.settings.tokenType) &&
                isValidString(aux.settings.idComparingCanonical, 5) &&
                // isValidString(aux.settings.idIntegrationCanonical, 5) &&
                // isInEnum(ETypeOfContact, aux.settings.typeOfContact) &&
                isValidNumber(aux.settings.expireInSeconds, MinimumTokenExpireInSeconds) &&
                isValidBPMAction(aux.settings.action)
            )
        };

        case ESmartField.DynamicMenu: {
            const aux = <IFieldSmartCorporateSearch>field;
            const hasCS = isValidString(aux.idCorporateSearch, 10);
            const hasValidFireOption = isInEnum(ESmartCSFireOption, aux.fireOption);
            const hasValidPredicates = (
                isValidArray(aux.dynamicMenuSearch.predicates, 0) &&
                aux.dynamicMenuSearch.predicates.every((c: ISearchMapperToCS) => (
                    isValidString(c.idFormProperty || c.idCanonical) &&
                        !aux.dynamicMenuSearch.matchWithAllSearchFields
                        ? isInEnum(ECSContentComparison, c.comparison) && isInEnum(ECSPredicateType, c.type)
                        : true
                ))
            );

            return hasCS && hasValidFireOption && hasValidPredicates;
        };

        case ESmartField.RTFValidator: {
            const aux = <IFieldSmartShakingHandsValidator>field;
            return (
                isValidString(aux.idRTF) &&
                isValidString(aux.rtfType) &&
                isValidContentArray(aux.onError)
            )
        };

        case ESmartField.FieldReuse: {
            const aux = <IFielSmartFieldReuse>field;
            return (
                isValidString(aux.idComparingLocalCanonical) &&
                    aux.dontAskIfFullfilled
                    ? true
                    : (
                        isValidContentArray(aux.title) &&
                        isValidContentArray(aux.yes) &&
                        isValidContentArray(aux.no)
                    )
            )
        };

        case ESmartField.Multimedia: {
            const aux = <IFieldMultimediaSmart>field;

            type Field = Omit<IFieldMultimediaSmart, 'smart' | 'maxNumberOfFiles' | 'processMedia' | 'processMediaContent' | 'videoValidation'>;
            const errors: string[] = validateEntity<Field, IFieldSmartMapper>({
                maxSizePerFileMB: {
                    type: ETypeOf.Number,
                    isRequired: true,
                },
                multimediaTypeAllowed: {
                    type: ETypeOf.Array,
                    isRequired: true,
                },
                onError: {
                    type: ETypeOf.Object,
                    isRequired: true,
                },
            })(aux);

            if (isValidArray(errors)) return false;
            console.log({ multimediaError: aux.onError });

            return (
                isValidContentArray(aux.onError.failToDecodeMedia) &&
                isValidContentArray(aux.onError.noMedia) &&
                isValidContentArray(aux.onError.notAllowedMedia) &&
                isValidMultimedia(aux)
            )
        };

        case ESmartField.smartConfirmation:
            return true;
        case ESmartField.CRM: {
            const aux = <IFieldCRMEngagement>field;
            return isValidString(aux.idVisualizer)
        }
        case ESmartField.docReader: {
            const aux = <IFieldSmartDocReaderEngagement>field;
            return isValidString(aux.idTransformer) && isInEnum(EDOCSegment, aux.docSegment);
        }
        case ESmartField.payments: {
            const paymentData = <IPaymentEngagement>field;
            return isValidRef(paymentData?.settings);
        }
    }
}

//====> Payments Validation Functions
export interface ICustomizedReturn { 
  isValid?: boolean;
  [ERROR_MESSAGE]?: string;
}

export function paymentValidationChain(field: IFieldSmartPayment): ICustomizedReturn {

  let returningObj: ICustomizedReturn = {
    isValid: false //<< Default
  }

  if(!isValidRef(field)) { 
    returningObj[ERROR_MESSAGE] = 'Os campos não foram preenchidos.';
    return returningObj;
  }

  if(!isValidString(field.paymentOrderName, 3)) {
    returningObj[ERROR_MESSAGE] = 'O preenchimento do nome da ordem de pagamento é obrigatório.';
    return returningObj;
  }
  
  const mainConfigs: IPaymentMainConfigs = field?.paymentMainConfigs;
  const outgoingMsgsConfigs: IPaymentOutgoingMessage = field?.paymentOutgoingMessage;
  const incomingMsgsConfigs: IPaymentIncomingMessage = field?.paymentIncomingMessage

  if(!mainConfigs){ 
    returningObj[ERROR_MESSAGE] = 'Por favor, verifique os campos'
    return returningObj;
  }

  if(!isValidPaymentAccountDataSelection(mainConfigs.paymentAccountData)) {
    returningObj[ERROR_MESSAGE] = 'Selecione pelo menos um provedor pix e um provedor customizado. Não é possível selecionar mais do que um provedor customizado por vez.';
    return returningObj;
  }

  if(!isValidPaymentPayerData(mainConfigs.paymentPayerData)) {
    returningObj[ERROR_MESSAGE] = 'Selecione todos os canônicos de dados do pagador.'
    return returningObj;
  }

  if(!isValidRequirementsData(mainConfigs.paymentRequirementsData)) {
    returningObj[ERROR_MESSAGE] = 'Os canônicos de metadado e pré-função são obrigatórios, assim como o preenchimento do mapa de atributos.';
    return returningObj;
  }

  if(!isValidPaymentOutgoingMessage(outgoingMsgsConfigs)) {
    returningObj[ERROR_MESSAGE] = 'O título da mensagem de envio é obrigatório, assim como o canônico de id da ordem, caso esteja habilitado.';
    return returningObj;
  }

  if(!isValidIncomingMessage(incomingMsgsConfigs)) {
    returningObj[ERROR_MESSAGE] = 'As mensagens de retorno de sucesso, pausa, falha e expiração são obrigatórias. Também não é possível salvar sem configurar o tempo de expiração e os ids das ações de campanha, caso estejam habilitados.';
    return returningObj;
  }
  
  returningObj.isValid = true;
  return returningObj;
}

function isValidPaymentAccountDataSelection(accountData: IPaymentAccountData): boolean { 
  if(!accountData) {
    return false;
  }
  
  const isValidPixProvider: boolean = isValidString(accountData?.pixPaymentProvider?.paymentProviderIdNS);
  const isValidCheckoutProvider: boolean = isValidString(accountData?.checkoutPaymentProvider?.paymentProviderIdNS);
  const isValidOrder: boolean = isValidString(accountData?.orderPaymentProvider?.paymentProviderIdNS);

  return (isValidPixProvider || isValidCheckoutProvider || isValidOrder)
}

function isValidPaymentPayerData(payerData: IPaymentPayerData): boolean { 
  if(!payerData) {
    return false;
  }
  
  return isValidString(payerData.payerCPForCNPJCanonicalId)
    && isValidString(payerData.payerCompleteNameCanonicalId)
    && isValidString(payerData.payerEmailCanonicalId)
}

function getPaymentMapCurrentEnums(): string[] {
  return Object.values(EPaymentItemAttributes)
}

function isValidRequirementsData(requirementsData: IPaymentRequirementsData): boolean { 
  if(!requirementsData) {
    return false;
  }

  const paymentMappingEnumsValuesArray: string[] = getPaymentMapCurrentEnums();
  if(!isValidArrayAndRef(paymentMappingEnumsValuesArray)) {
    return false;
  }

  const mapping: TIPaymentAttrMapArray = requirementsData.mapper?.mapping;

  if(!isValidArrayAndRef(mapping)) {
    return false;
  }

  const mappedAttributes: string[] = mapping.map(item => item.paymentAttribute as string);

  const isValidMapping: boolean = paymentMappingEnumsValuesArray.every(item => mappedAttributes.includes(item));
  
  return isValidString(requirementsData.idPaymentMetadata)
    && isValidString(requirementsData.idPrePaymentUserFunction)
    && isValidMapping
}

function isValidPaymentOutgoingMessage(paymentOutgoingMsg: IPaymentOutgoingMessage): boolean { 
  if(!paymentOutgoingMsg) {
    return false;
  }

  const isValidOutgoingTitleMsg = isValidArrayAndRef(paymentOutgoingMsg?.paymentMainMessageTitle)

  return paymentOutgoingMsg.paymentOutgoingMessageData?.orderIdManagementType === EHandleOperationsSide.customerHandled
    ? isValidOutgoingTitleMsg && isValidString(paymentOutgoingMsg.paymentOutgoingMessageData?.idOrderCanonical)
    : isValidOutgoingTitleMsg
}

function isValidIncomingMessage(paymentIncoming: IPaymentIncomingMessage): boolean {
  if (!paymentIncoming) {
    return false;
  }

  const {
    paymentSuccess,
    paymentWhileHalted,
    paymentFail,
    paymentExpire,
    haltWaitTime,
  } = paymentIncoming;

  const configs = [paymentSuccess, paymentWhileHalted, paymentFail, paymentExpire];
  
  const isValidCampaignActions = (config: IPaymentMessageActionsConfigs): boolean => {
    const canPassWithFailCpgMessage: boolean = config?.enableFailMessageCampaignActionConfig
      ? isValidArrayAndRef(config.failMessageListCampaignActionIds)
      : true;

    if (!canPassWithFailCpgMessage) {
      return false;
    }

    if (config.enableCampaignActionConfig) {
      return isValidArrayAndRef(config.listCampaignActionIds);
    }

    return true;
  };

  for (const config of configs) {
    if (!isValidRef(config)) { 
      return false;
    }

    if (!isValidArrayAndRef(config.messages)) {
      return false;
    }

    if (!isValidCampaignActions(config)) {
      return false;
    }
  }

  if (!haltWaitTime || haltWaitTime < 120 * 1000) {
    return false;
  }

  return true;
}
//====> Payments Validation Functions

function isValidMultimedia(aux: IFieldMultimediaSmart) {
    console.log({ aux });

    if (aux.processMediaContent) {
        const isDefaultMediaConfigsOk = isInEnum(EMultimediaProcessType, aux.processMedia.processType) &&
            isInEnum(EMultimediaExtractionMethod, aux.processMedia.entityIdentificationType) &&
            isValidNumber(aux.processMedia.maxElementsPerEntity) &&
            isValidString(aux.processMedia.idRealTimeFusion) &&
            isValidString(aux.processMedia.idPropertyDestiny) &&
            isValidString(aux.processMedia.idLocalCanoncialForMatch)
        const isFunctionMediaConfigsOk = isInEnum(EMultimediaProcessType, aux.processMedia.processType) &&
            isValidString(aux.processMedia.idFunction)
        return isFunctionMediaConfigsOk || isDefaultMediaConfigsOk
    } else {
        return true
    }
}

export function isValidRichForm(richFill: IRichFormExperience): boolean {
    return (
        isValidContentArray(richFill.contentArray) &&
        (richFill.hasConfigMultipleChoice
            ? isValidRichFormChoiceConfigure(richFill.multipleChoice)
            : true
        )
    );
}

export function isValidRichFormChoiceConfigure(choices: TIChoiceConfigureArray): boolean {
    return isValidArray(choices) && choices.every(choice => isValidString(choice.optionText) && isValidString(choice.returningID));
}

export enum ENPSRole {
    detractor = 'detractor',
    passive = 'passive',
    promoter = 'promoter'
}

export interface INPSMetadataInfo {
    scale: number;
    lowRate: number;
    highRate: number;
    invert: boolean;
}

export interface INPSinfo extends INPSMetadataInfo {
    score: number,
    isValid: boolean;
    npsRole: ENPSRole;
}

export function parseNPSMetadata(nps: IFieldSmartNPS): INPSMetadataInfo {
    const num: boolean = isAllNumeric(nps.higherRate, nps.lowerRate);

    let lowRate: number = num ? parseInt(nps.lowerRate) : nps.lowerRate.charCodeAt(0);
    let highRate: number = num ? parseInt(nps.higherRate) : nps.higherRate.charCodeAt(0);
    let detractorLimit: number = num ? parseInt(nps.detractorLimit) : nps.detractorLimit.charCodeAt(0);
    let passiveLimit: number = num ? parseInt(nps.passiveLimit) : nps.passiveLimit.charCodeAt(0);
    let scale: number = highRate - lowRate;
    let invert: boolean = false;


    if (scale < 0) {
        invert = true;
        scale *= -1;
        const aux = highRate;
        highRate = lowRate;
        lowRate = aux;

        const aux2: number = passiveLimit;
        passiveLimit = detractorLimit;
        detractorLimit = aux2;

    }

    return {
        lowRate, highRate, scale, invert
    }
}

export function parseNPS(nps: IFieldSmartNPS, value: string): INPSinfo {

    value = value.trim();

    const isNumParameters: boolean = isAllNumeric(nps.higherRate, nps.lowerRate);
    const isNum: boolean = isNumeric(value);

    if (isNumParameters !== isNum || !isValidString(value)) {
        return <INPSinfo>{
            isValid: false
        }
    }

    let lowRate: number = isNum ? parseInt(nps.lowerRate) : nps.lowerRate.charCodeAt(0);
    let highRate: number = isNum ? parseInt(nps.higherRate) : nps.higherRate.charCodeAt(0);
    let detractorLimit: number = isNum ? parseInt(nps.detractorLimit) : nps.detractorLimit.charCodeAt(0);
    let passiveLimit: number = isNum ? parseInt(nps.passiveLimit) : nps.passiveLimit.charCodeAt(0);
    let scale: number = highRate - lowRate;
    let invert: boolean = false;


    if (scale < 0) {
        invert = true;
        scale *= -1;
        const aux = highRate;
        highRate = lowRate;
        lowRate = aux;

        const aux2: number = passiveLimit;
        passiveLimit = detractorLimit;
        detractorLimit = aux2;

    }
    const scoreCode: number = isNum ? parseInt(value) : value.charCodeAt(0);

    if (scoreCode > highRate || scoreCode < lowRate) {
        return <INPSinfo>{
            isValid: false
        }
    }

    const score: number = invert
        ? (scoreCode - highRate) / (lowRate - highRate)
        : (scoreCode - lowRate) / (highRate - lowRate)

    const npsRole: ENPSRole = scoreCode <= detractorLimit
        ? (invert ? ENPSRole.promoter : ENPSRole.detractor)
        : scoreCode <= passiveLimit
            ? ENPSRole.passive
            : (invert ? ENPSRole.detractor : ENPSRole.promoter)

    return {
        score: getIfNotValid(score, 0), lowRate, highRate, scale, isValid: true,
        npsRole, invert
    }

}



export function isValidGeoMapperConfig(geo: ISearchMapperToCS): boolean {
    return isValidTrimmedString(geo?.idCorporateSearchProperty, 4) && isValidTrimmedString(geo?.idFormProperty, 4)
}

export function getEngagementOfType<T extends IFieldMapper>(descriptor: TEngagementDescriptor, type: EMetadataEngagementType): T {
    return descriptor?.find(eng => eng.engagementType === type) as T;
}

export function isAdvancedMenu(settings: IBotElementWithRenderOptions, channel: EDelivery360Action) {
    if (isInvalid(settings))
        return false;
    return isElementWithRenderOptions(settings) &&
        settings.renderOptions[channel].menuRenderConfigType === EMenuMode.Full;
}

export function getDefaultSmartAddressRenderOptions(): ISmartAddressDynamicMenuRenderOptions {
    return {
        renderOptions: initDefaultRenderOptions(),
        title: [{ key: EAddressFields.street, fieldFormatType: ECSFormatField.asIs }],
        description: [{ key: EAddressFields.description, fieldFormatType: ECSFormatField.asIs }],
        selectorButton: undefined,
        truncateInfo: false
    };
}

export function createEngagement(engagementType: EMetadataEngagementType, idFieldForm: string, idCanonico: string): IFieldMapper {
    const idFieldMapper: string = getUniqueStringID(15);

    const mapSmartFieldToInitializers: { [key in EMetadataEngagementType]: () => IFieldSmartMapper
        | IFieldEntityMapper
        | IRichFormExperience
        | INewConditionFieldMapper
    } = {
        [EMetadataEngagementType.smartMapper]: () => ({
            idFieldMapper,
            engagementType,
            idFieldForm,
            idCanonico,
        }),
        [EMetadataEngagementType.entityMapper]: () => ({
            idEntity: undefined,
            idFieldMapper,
            engagementType,
            idFieldForm,
            idCanonico
        }),
        [EMetadataEngagementType.richFillForm]: () => ({
            contentArray: [],
            engagementType,
            idFieldMapper,
            idCanonico,
            idFieldForm,
        }),
        [EMetadataEngagementType.CRMTransformation]: () => ({
            idFieldMapper,
            engagementType,
            idFieldForm,
            idCanonico,
        }),
        [EMetadataEngagementType.NewCondition]: () => ({
            idFieldMapper,
            engagementType,
            idFieldForm,
            idCanonico,
            evaluators: []
        }),
        [EMetadataEngagementType.realTimeFusion]: undefined,
        [EMetadataEngagementType.integrationByChannel]: undefined,
        [EMetadataEngagementType.mktEvents]: undefined,
        [EMetadataEngagementType.integration]: undefined,
        [EMetadataEngagementType.layout]: () => ({
            idFieldMapper,
            engagementType,
            idFieldForm,
            idCanonico,
            evaluators: [],
            removePontuation: false
        }),
        [EMetadataEngagementType.solicitation]: undefined,
        [EMetadataEngagementType.whatsAppFlow]: () => ({
            idFieldMapper,
            engagementType,
            idFieldForm,
            idCanonico,
        }),
    }

    const engagement: IFieldMapper = mapSmartFieldToInitializers[engagementType]()
    
    return engagement;
}
