import { Injectable } from '@angular/core';
import { GenericNonSerializableService } from './generic-ns.service';
import { ServerCommunicationService } from './server-communication.service';
import { RequestBuilderServices } from './request-builder.services';
import { SessionService } from './session.service';
import { RoutingService } from './routing.service';
import { routeList } from 'app/model/routes/route-constants';
import { IListTagResponse, IListTagRequest, ITagSaveRequest, ITagSearchParameter } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tag-req-resp';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { IColmeiaTag, IServerColmeiaTag, ETagType, TColmeiaTagArray, initDefaultColmeiaTag } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IUsedTagsData, IUsedTagsRequest, IUsedTagsResponse, ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { toMultipleCursor } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';

@Injectable({
    providedIn: 'root'
})
export class TagsService extends GenericNonSerializableService {

    constructor(
        api: ServerCommunicationService,
        rbs: RequestBuilderServices,
        session: SessionService,
        routeSvc: RoutingService,
    ) {
        super(
            routeList.dashboard.children.smartFlow.path,
            routeList.dashboard.children.smartFlow.children.tags,
            { api, rbs, session, routeSvc },
        )
    }

    getTagsRequest(
        idTagParent: string = null, cursor: string = null, taggable?: ITagableSearch
    ): IListTagRequest {
        const request: IListTagRequest = {
            ...this.baseRequest(apiRequestType.tags.list),
            multipleCursor: toMultipleCursor(cursor),
            idTagParent,
            taggable
        };
        return request;
    }

    async getTags(idTagParent: string = null, cursor: string = null): Promise<IServerColmeiaTag[]> {
        const request = this.getTagsRequest(idTagParent, cursor);
        const response = await this.send<IListTagResponse>(request);

        if (response) {
            return response.tags;
        }

        return null;
    }

    async getTagsByType(search: ITagSearchParameter, cursor: string = null): Promise<IServerColmeiaTag[]> {
        const request: IListTagRequest = {
            ...this.baseRequest(apiRequestType.tags.list),
            ...search,
            multipleCursor: toMultipleCursor(cursor),
        }

        const response = await this.send<IListTagResponse>(request);

        if (response) {
            return response.tags;
        }

        return null;
    }

    async getTagChildren(idParent: string): Promise<IServerColmeiaTag[]> {
        return this.getChildren<IServerColmeiaTag>(idParent, ENonSerializableObjectType.colmeiaTags);
    }

    async createColmeiaTag(name: string): Promise<boolean> {
        const tag: IServerColmeiaTag = initDefaultColmeiaTag(name);
        return this.save(tag);
    }

    async save(tag: IColmeiaTag): Promise<boolean> {
        const request: ITagSaveRequest = {
            ...this.baseRequest(apiRequestType.tags.save),
            ns: tag
        }
        return this.isSuccessfull(request);
    }


    async getUsedTags(data: IUsedTagsData): Promise<TColmeiaTagArray> {

        const request: IUsedTagsRequest = {
            ...this.baseRequest(apiRequestType.nonSerializable.getUsedTags),
            ...data
        };

        const response: IUsedTagsResponse = await this.send<IUsedTagsResponse>(request);

        if (response) {
            return response.usedTags;
        }
    }



}
