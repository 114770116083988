<div class="wrapper">
  <div *ngIf="!hideTitle" class="top-center-container" [class.move-window-trigger]="!disableWindowMoving">
    <h3 class="title">{{ title || windowTitle }}</h3>
  </div>
  <div class="window-control-container">
    <button matRipple (click)="minimizeClick($event)" class="window-button">
      <mat-icon>minimize</mat-icon>
    </button>

    <button matRipple (click)="closeClick($event)" class="window-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
