import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
    selector: 'mat-icon[size]'
})
export class MatIconSizeDirective {

    @Input()
    size: string | number;

    @HostBinding('class')
    get sizeClass(): string { return `mat-${this.size}` };
}
