import {
    isRootAction,
    isFormAction,
    isLeafAction,
} from "./bot-filter-dictionary";
import { TBotEventTypeArray, EBotEventType, IBotEvent } from "./bot-event-model";
import { geEventDB, TEventDB, TBotEventCharacteristicFilter } from "./bot-event-config-db";
import { ENextGenBotElementType } from "./bot-model";
import { isThisOneOfThat, isValidObject, isValidTrimmedString, arrayToHash, getTypedProperty, getDeepTypedProperty, isValidRef, objectShallowReplace, random, isValidArray } from "../../tools/utility";
import { IRealtimeFusionModel } from "../attendance-island/real-time-fusion-model";
import { TContentAssetArray, IContentBasicAsset, IRandomMessage, IChangeableAssetParameter } from "./bot-content-model";
import { DeliveryProvider } from "../../core-constants/types";
import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { MAX_WHATSAPP_BUTTON_TITLE_SIZE } from "../social-media/social-media.model";
import { IBasicAsset, KAssetType, TBasicAssetArray, TKAssetTypeArray } from "./bot-asset-model";


export function getBotEventsByCharacteristic(match: TBotEventCharacteristicFilter): TBotEventTypeArray {
    const allEvents: TBotEventTypeArray = [];
    const db: TEventDB = geEventDB();
    for (const evt in db) {
        if (match(db[evt])) {
            allEvents.push(<EBotEventType>evt);
        };
    };
    return allEvents;
}

export function getMatchFunctionToActions(configType: ENextGenBotElementType) {
    switch (configType) {
        case ENextGenBotElementType.root:
            return isRootAction;
        case ENextGenBotElementType.metadata:
            return isFormAction;
        case ENextGenBotElementType.botMenuItem:
            return isLeafAction;
    }
}

export function getAssetsFromEventOfType(events: TBasicAssetArray, eventType: EBotEventType): TContentAssetArray {
    const evt = getAssetOfType<IBotEvent>(events, eventType);
    if (isValidRef(evt) && isValidArray(evt.contentArray)) {
        return evt.contentArray;
    };
    return undefined;
};

export function getAssetsOfType<T extends TBasicAssetArray>(event: TBasicAssetArray, ...type: TKAssetTypeArray): T {
    if (isValidArray(event)) {
        return <T>event.filter((e) => {return isThisOneOfThat(e.type, ...type)});
    };
    return undefined;
};

export function getAssetOfType<T extends IBasicAsset>(event: TBasicAssetArray, type: KAssetType): T | undefined {
    if (isValidArray(event)) {
        return <T>event.find((e) => {return e.type === type})
    };
    return undefined;
};



export function hasEventsPanel(level: ENextGenBotElementType): boolean {
    return isThisOneOfThat(level, ENextGenBotElementType.root, ENextGenBotElementType.botMenuItem);
}

export function hasActionsPanel(level: ENextGenBotElementType): boolean {
    return isThisOneOfThat(level, ENextGenBotElementType.root, ENextGenBotElementType.botMenuItem);
}

export function isValidInformationGather(info: IRealtimeFusionModel): boolean {
    return false;
}

export function isGroupTaggedMessage(message: IContentBasicAsset): boolean {
    return isValidTag(message.randomDisplayControl)
}

export function isValidTag(tag: IRandomMessage): boolean {
    return isValidObject(tag) && isValidTrimmedString(tag.idFirstAsset, 5) && isValidTrimmedString(tag.tag)
}

export function isSameMessageTag(message: IContentBasicAsset, message2: IContentBasicAsset): boolean {
    return isSameTag(message.randomDisplayControl, message2.randomDisplayControl)
}

export function isSameTag(tag1: IRandomMessage, tag2: IRandomMessage): boolean {
    return isValidTag(tag1) && isValidTag(tag2) && tag1.idFirstAsset == tag2.idFirstAsset && tag1.tag == tag2.tag

}

const driverToUpdate: IChangeableAssetParameter = {
    botContentType: null,
    daysAfter: null,
    displayCondition: null,
    isConditionalContent: null,
    randomDisplayControl: null,
    recency: null,
    waitTimeSeconds: null
}

export function updateGroupedTags(correct: IContentBasicAsset, allMessages: TContentAssetArray): TContentAssetArray {
    const altered: TContentAssetArray = [];
    allMessages.filter(m => m.idAsset !== correct.idAsset && isSameMessageTag(m, correct)).forEach(m => {
        Object.keys(driverToUpdate).forEach((idProperty) => {
            if (isValidRef(correct[idProperty])) {
                m[idProperty] = correct[idProperty]
            }
        })
        altered.push(m);
    });

    return altered;
}

export function updateGroupTag(tag: IRandomMessage, messages: TContentAssetArray, update: Partial<IChangeableAssetParameter>): void {
    messages
    .filter(t => isSameTag(tag, t.randomDisplayControl))
    .forEach((m) => objectShallowReplace(m, update))
}

export function getAllMessagesOfGroup(tag: IRandomMessage, messages: TContentAssetArray): TContentAssetArray {
    return messages.filter( m => isSameTag(tag, m.randomDisplayControl));
}

export function getFirstItemOfGroupIndex(tag: IRandomMessage, messages: TContentAssetArray): number {
    return messages.findIndex( m => isSameTag(tag, m.randomDisplayControl));
}

export function getFirstItemOfGroup(tag: IRandomMessage, messages: TContentAssetArray): IContentBasicAsset {
    return messages[getFirstItemOfGroupIndex(tag, messages)];
}

export enum EMessageFunctionality {
    templateQuickReply = 'templateQuickReply',
    callToActionPhone = 'callToActionPhone',
    callToActionSite = 'callToActionSite',
    messageQuickReply = 'messageQuickReply',
    fullMewnu = 'fullMewnu',
}


//@@Gabriel depois vamos falar se isso faz algum sentido na arquitetura
// Na nova arquitetura devermos passar sempre o array de botoes porque isso é levado em considercao
export function getProviderTextSize(provider: DeliveryProvider, channel: EDelivery360Action, type: EMessageFunctionality): number {
    if (channel === EDelivery360Action.Delivery360WhatsApp) {
        if (provider === DeliveryProvider.twilio) {
            if (type !== EMessageFunctionality.fullMewnu) {
                return MAX_WHATSAPP_BUTTON_TITLE_SIZE
            }
        } else if (provider === DeliveryProvider.infobip) {
            const infobipTypeToSize: { [key in EMessageFunctionality]: number } = {
                [EMessageFunctionality.messageQuickReply]: 20,
                [EMessageFunctionality.fullMewnu]: 24,
                [EMessageFunctionality.callToActionPhone]: 25,
                [EMessageFunctionality.callToActionSite]: 25,
                [EMessageFunctionality.templateQuickReply]: 25,
            }
            return infobipTypeToSize[type];
        }
    }
    
}


export function processGroupedTag(messages: TContentAssetArray): TContentAssetArray {
    const ret: TContentAssetArray = [];
    const usedTag: {[tagName: string]: boolean} = {};
    for (const message of messages) {

        if (isGroupTaggedMessage(message)) {
            if (! usedTag[message.randomDisplayControl.tag]) {
                usedTag[message.randomDisplayControl.tag] = true;
                const allMessagedTags = messages.filter(m => isSameMessageTag(message,m));
                const index = random(allMessagedTags.length);
                ret.push(allMessagedTags[index]);
            }
        } else {
            ret.push(message);
        }
    }
    return ret;
}
