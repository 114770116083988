import { isInvalidString, isValidRef } from "@colmeia/core/src/tools/utility";
import { errorGroupIdentifications, errorIdentifications } from "app/errors/client-error.constants";
import { EClientErrorType, IClientErrorClassification, IClientErrorGroupMatchDetails, IClientErrorTypeMatchDetails, TClientErrorIdentificationConfig, TClientErrorIdentificationConfigHash, TClientErrorIdentifiers, TIClientErrorMatchDetails, TIClientErrorMatchDetailsItems } from "app/errors/client-error.model";


export function getClientErrorClassification(error?: Error): IClientErrorClassification {
    let response: IClientErrorClassification = {
        type: EClientErrorType.NoType,
        matches: []
    }
    const errorMessage: string = error?.message?.toLocaleLowerCase();
    const errorStack: string = error?.stack?.toLocaleLowerCase();
    
    if(!isValidRef(error) || (isInvalidString(errorMessage) && isInvalidString(errorStack))) {
        return response
    }

    let groupMatches: IClientErrorGroupMatchDetails[] = matchClassifications(error, errorGroupIdentifications, 'group');
    let typeMaches: IClientErrorTypeMatchDetails[] = matchClassifications(error, errorIdentifications, 'type');

    if(isValidRef(groupMatches[0]?.group)) {
        response.group = groupMatches[0].group;
    }

    if(isValidRef(typeMaches[0]?.type)) {
        response.type = typeMaches[0].type;
    }

    response.matches = [
        ...groupMatches,
        ...typeMaches,
    ];

    return response;
}

function matchClassifications<T extends TClientErrorIdentifiers, R extends TIClientErrorMatchDetails>(
    error: Error,
    identifications: TClientErrorIdentificationConfigHash<T>,
    classificationType: TIClientErrorMatchDetailsItems
): R[] { 
    let matches: R[] = [];

    for(const classificationStr in identifications) {
        const classification = classificationStr as T;
    
        const configScore = getScoreForConfig(identifications[classification], error)
        
        if(configScore > 0) {
            matches.push({ score: configScore, [classificationType]: classification } as unknown as R)
        }

    }

    return matches.filter( i => i.score > 0 ).sort( (a,b) => b.score - a.score );
}

export function getScoreForConfig(config: TClientErrorIdentificationConfig, error: Error): number {
    let score: number = 0;
    const errorMessage: string = error?.message?.toLocaleLowerCase();
    const errorStack: string = error?.stack?.toLocaleLowerCase();

    const { messagesFragmentsMatch, stackFragmentsMatch, classInstanceMatch } = config;

    messagesFragmentsMatch?.forEach( messageFragment => {
        const match = errorMessage?.includes(messageFragment.toLocaleLowerCase());

        if(match) {
            score++;
        }
    });


    stackFragmentsMatch?.forEach( stackFragment => {
        const match = errorStack?.includes(stackFragment.toLocaleLowerCase());

        if(match) {
            score++;
        }
    });

    if(isValidRef(classInstanceMatch) && error instanceof classInstanceMatch) {
        score++;
    }

    return score;
}