import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces"
import { EMenuMode } from "@colmeia/core/src/shared-business-rules/bot/bot-model"
import { TStringLengthRanges } from "app/components/stringmometer/stringmometer.component"

type TDelivery360MenuModeSectionRangesDB = {
    [channel in EDelivery360Action]: TStringLengthRanges
}

export const Delivery360MenuModeSectionRangesConfig: TDelivery360MenuModeSectionRangesDB = {

    
    [EDelivery360Action.Delivery360Email]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],

    [EDelivery360Action.DeliveryInstagram]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],

    [EDelivery360Action.DeliveryTelegram]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],
    [EDelivery360Action.Delivery360FBMessenger]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],
    [EDelivery360Action.Delivery360SMS]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],
    [EDelivery360Action.Delivery360Voice]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],
    [EDelivery360Action.Delivery360WhatsApp]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],
    [EDelivery360Action.DeliveryColmeia]: [
        {
            label: EMenuMode.Adaptative,
            min: 0,
            // max: 10,
            theme: "primary"
        }
    ],
}
