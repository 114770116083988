import { Injectable } from '@angular/core';
import { FriendlyMessage } from '@colmeia/core/src/error-control/friendly-message';
import { IGPSConfigurationStatus } from '@colmeia/core/src/comm-interfaces/settings-interface';
import {HardwareLayerService} from "./hardware";
import {GeolocationManagerService} from "./geolocation-manager.service";

@Injectable({
    providedIn: 'root'
})
export class GeolocationHandlerInteractorService {
    constructor(
        private locationManagerSvc: GeolocationManagerService,
        private hw: HardwareLayerService
    ) { }

    private async isAllowed(): Promise<boolean> {
        return this.hw.getGeolocation().isAllowed();
    }

    async locationTurnOnTriggedByUser(): Promise<FriendlyMessage> {
        const isGetPermissionToLocationGPSAllowed = await this.isAllowed();
        if (isGetPermissionToLocationGPSAllowed) {
            const response = await this.locationManagerSvc.sendToServerGPSToggledOn();
            return response;
        }
    }

    async locationTurnOffTriggedByUser(gpsStatus: IGPSConfigurationStatus): Promise<FriendlyMessage> {
        const response = await this.locationManagerSvc.sendToServerGPSToggledOff(gpsStatus);
        return response
    }

    async locationTurnedOnByServer(): Promise<void> {
        const isGetPermissionToLocationGPSAllowed = await this.isAllowed();
        if (isGetPermissionToLocationGPSAllowed) {
            return;
        }
    }
}
