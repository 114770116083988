import { InteractionType } from '../../interaction/interaction-type';
import { UserDefaultSettings, Serializable } from '../../business/barrel-business';
import { clockTickToDate, TDateTransform } from '../../time/time-utl';
import { IFeedbackInteractionJSON, IInteractionJSON } from '../../comm-interfaces/interaction-interfaces';
import { SubscriptionType } from '../../interaction/subscription/subscription-type';
import { ISubscriptionInteractionJSON, IUniversalJSON, IInteractionActors } from '../../comm-interfaces/barrel-comm-interfaces';
import { getSubjectSubscriberIDs } from '../subscription/sub-functions';
import { TGlobalUID, constant } from '../../business/constant';
import { PlayerAuthorizeOnlyInfo } from '../../business/player-minimun-auth';
import { Feedback } from "../../interaction/feedback";
import { getBestIDMEdiaFromUniversal } from '../../rules/mm-functions';
import { EServiceGroupIntType } from '../../business/constant.enums';

interface IShortMessageDirection {
    idInteraction: TGlobalUID;
    idInteractionType: TGlobalUID;
    idGroup: TGlobalUID;
    isSubscription: boolean;
    fromYou: boolean;
}

const allSubFields = constant.serializableField.subscription;

export type TShortMessageArray = Array<IShortMessage>;

export interface IShortMessage extends IShortMessageDirection {
    fromName: string;
    toName: string;

    title: string;
    text: string;
    date: string;

    fromIdMedia: string;
    toIdMedia: string;
    idSVG: string;
}

function standardFormatDate(clockTick:  number, format: string): string {
    const d: Date = new Date(clockTick);
    return d.getDate() + '/' + (d.getMonth()+1).toString() + '/' + d.getFullYear().toString()  + ' ' + d.getHours() + ':' + d.getMinutes();
};


export class MessageShrinker {

    private userDefaultSettings: UserDefaultSettings;
    private cached: PlayerAuthorizeOnlyInfo;
    private dateTransformation: TDateTransform;

    constructor(cached: PlayerAuthorizeOnlyInfo, config: UserDefaultSettings, dateTransformation: TDateTransform = standardFormatDate) {
        this.cached = cached;
        this.userDefaultSettings = config;
        this.dateTransformation = dateTransformation;
    };

    private getShortMessageDirection(interaction: IInteractionJSON): IShortMessageDirection {
        return {
            idInteraction: interaction.primaryID,
            idGroup: interaction.idGroup,
            fromYou: this.cached.interactionMadeByMe(interaction),
            isSubscription: InteractionType.staticFactory(interaction.idInteractionType).isSubscription(),
            idInteractionType: interaction.idInteractionType
        };
    };

    public getInteractionActors(interaction: IInteractionJSON, idAvatar: TGlobalUID): IInteractionActors {
        const IType: InteractionType = InteractionType.staticFactory(interaction.idInteractionType);
        if(IType.isSubscription()) {
            return {...getSubjectSubscriberIDs(<ISubscriptionInteractionJSON>interaction), isSubscription: true};
        } else {
            return {
                fromSubscriber: interaction.participant.avatar.primaryID,
                isSubscription: false,
                toSubject: idAvatar,
                idFromObjectType: constant.objectType.avatar,
                idToObjectType: constant.objectType.avatar,
            }
        }
    }

    public getShortMessage(interaction: IInteractionJSON, fromJSON: IUniversalJSON, toJSON: IUniversalJSON): IShortMessage {
        const fromName: string = Serializable.getJText(fromJSON);
        const direction: IShortMessageDirection = this.getShortMessageDirection(interaction)
        const title: string = interaction.idInteractionType === EServiceGroupIntType.startServiceChat
            ? `Novo atendimento: ${fromName}`
            : `Nova mensagem de ${fromName}`

        const shortMessage: IShortMessage = {
            ...direction,
            text:  Serializable.getJText(interaction, constant.serializableField.chat_text),
            date:  clockTickToDate(interaction.clockTick, this.userDefaultSettings.getDateFormat(), this.userDefaultSettings.getHourFormat(), this.dateTransformation),
            fromName,
            fromIdMedia: getBestIDMEdiaFromUniversal(fromJSON),
            toName: Serializable.getJText(toJSON),
            toIdMedia: getBestIDMEdiaFromUniversal(toJSON),
            idSVG: null,
            title
        };

        if (direction.isSubscription) {
            const subs: ISubscriptionInteractionJSON = <ISubscriptionInteractionJSON>interaction;
            const subType: SubscriptionType = SubscriptionType.staticFactory(subs.idSubscriptionType);
            shortMessage.idSVG = subType.getSerializableText(allSubFields.iconNotification);
            shortMessage.text = subType.getSerializableText(shortMessage.fromYou ?  allSubFields.ownerMessage : allSubFields.receiverMessage);
            if (subType.isAvatarFromSubscriber() && subType.isAvatarToSubject()) {
                shortMessage.toIdMedia = shortMessage.fromIdMedia;
                shortMessage.toName = shortMessage.fromName;
            };

        } else if (direction.idInteractionType === constant.interactionType.subscription.stdResponse) {
            const aux: IFeedbackInteractionJSON = <IFeedbackInteractionJSON>interaction;
            if (aux.idFeedback) {
                const feedback: Feedback = Feedback.staticFactory(aux.idFeedback);
                shortMessage.text = feedback.getSerializableText(
                    (direction.fromYou) ? allSubFields.ownerMessage : allSubFields.receiverMessage
                );
            };
            
        };
        return shortMessage;
    }
}
