import { Serializable } from "@colmeia/core/src/business/serializable";
import { TSerializableArray } from "@colmeia/core/src/persistency/uber-cache";

export type TMoreOptionsThreeDotsOption = Serializable;
export type TMoreOptionsThreeDotsOptionList = TSerializableArray;

export interface IMoreOptionsThreeDotsClient {
    onOptionSelectedCallback(option: TMoreOptionsThreeDotsOption): void;
}

export interface IMoreOptionsThreeDotsParameter {
    options: TMoreOptionsThreeDotsOptionList
    clientInstance: IMoreOptionsThreeDotsClient
}

export class MoreOptionsThreeDotsHandler {
    static new(param: Partial<IMoreOptionsThreeDotsParameter>): MoreOptionsThreeDotsHandler {
        return new MoreOptionsThreeDotsHandler({
            clientInstance: null,
            options: [],
            ...param
        })
    }

    constructor(private parameters: IMoreOptionsThreeDotsParameter) { }

    optionSelected(option: TMoreOptionsThreeDotsOption) {
        this.parameters.clientInstance.onOptionSelectedCallback(option)
    }

    getMenuOptions(): TSerializableArray {
        return this.parameters.options
    }
}
