import { EDelivery360Action } from "../../comm-interfaces/interaction-interfaces";
import { BBCodeParser, BBTag } from "@affilicon/bbcode-parser";


export enum EBBCodeStyles {
    Italic = 'i',
    Bold = 'b',
    Strikethrough = 's',
    Code = 'c',
    Monospace = 'm',
    Menu = 'menu',
}

export enum EBBCodeSubStyles {
    MenuItem = 'item',
}

export enum EBBCodeMenuMode {
    Enhanced = 'enhanced'
}

export type BBCodeStyleTags = { [style in EBBCodeStyles]: TMarkupGenerator; };


export interface BBCodeDelivery360ActionParsersDefinitionItem {
    interceptor?: (text: string) => string;
    tags: BBCodeStyleTags;
};

export type BBCodeDelivery360ActionParsersDefinitions = {
    [key in EDelivery360Action]: BBCodeDelivery360ActionParsersDefinitionItem; // { interceptor: (text: string) => string; tags: BBCodeStyleTags };
};
export type BBCodeDelivery360ActionParsers = {
    [key in EDelivery360Action]: BBCodeParser;
};

export interface IMarkupGeneratorOptions {
    tag: BBTag;
    content: string;
    attrs: string[];
}

export type TMarkupGenerator = (options: IMarkupGeneratorOptions) => string;

export interface BBCodeTagExample {
    opening: string;
    close: string;
}

export interface BBCodeTagConfig {
    showOnClientEditor?: true;
}

export type BBCodeTagsDB = {
    [key in EBBCodeStyles]: BBCodeTagConfig;
}