<app-colmeia-window-top-bar></app-colmeia-window-top-bar>

<div class="campaign-action-container">
  <div class="compact-container">
    <div class="content-container nice-scroll">
      <div class="content nice-scroll">
        <mat-form-field class="title-input">
          <mat-label>{{ translations.title }}</mat-label>
          <textarea required matInput mat-autosize [(ngModel)]="action.actionName"></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ translations.description }}</mat-label>
          <textarea class="desc-input" matInput mat-autosize [(ngModel)]="action.actionDescription"></textarea>
        </mat-form-field>
        <ng-container *ngIf="shouldShowBotPickerHandler">
          <app-ns-picker [handler]="botPickerHandler"></app-ns-picker>
        </ng-container>

        <mat-divider></mat-divider>

        <ng-container *ngIf="schemaPickerHandler">
          <app-ns-picker [handler]="schemaPickerHandler"></app-ns-picker>
        </ng-container>

        <mat-form-field class="action-type" appearance="fill">
          <mat-label>{{ translations.actionType }}</mat-label>
          <mat-select [(ngModel)]="action.actionType" [disabled]="!action.idSchemma">
            <ng-container *ngFor="let item of actionTypes">
              <mat-option [disabled]="disableActionType(item.type)" [value]="item.type">
                {{ item.text }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="isMassComm()">
          <app-conditional-expansion-panel [(active)]="action.activeCallback" label="Callback">
            <div style="margin:0 -12px -14px;">
              <app-enum-picker *ngIf="callbackApiTypeEnumPicker"
                [handler]="callbackApiTypeEnumPicker"></app-enum-picker>

              <mat-divider style="margin: 14px 0 6px;"></mat-divider>

              <div [visible-horizontal]="isRouteCallback()">
                <app-ns-picker *ngIf="callbackApiRoutePicker" [handler]="callbackApiRoutePicker"></app-ns-picker>
              </div>
              <div [visible-horizontal]="isFunctionCallback()">
                <app-ns-picker *ngIf="callbackUserFunctionPicker"
                  [handler]="callbackUserFunctionPicker"></app-ns-picker>
              </div>

              <mat-slide-toggle *ngIf="canShowMustConfirmationToggle()"
                [(ngModel)]="action.callback.mustUseConfirmationCallbacksSubset"
                (change)="handleConfirmationCallbackToggle($event)" style="margin-bottom: 8px; margin-top: 4px;">
                Selecionar eventos do Whatsapp
              </mat-slide-toggle>
              <app-enum-picker
                *ngIf="canShowMustConfirmationToggle() && confirmationTypeListHandler && action.callback.mustUseConfirmationCallbacksSubset"
                [handler]="confirmationTypeListHandler">
              </app-enum-picker>

            </div>
          </app-conditional-expansion-panel>
        </ng-container>

        <app-timestamp-picker label="Será considerado como resposta durante" [(time)]="expireActiveHours"
          [elements]="expireActiveHoursPickerElements">
        </app-timestamp-picker>
      </div>
      <div class="action-buttons">
        <div></div>
        <div>
          <button mat-button (click)="cancel()" [disabled]="saving" style="margin-right: 8px;">Fechar</button>

          <div class="button-alert-container">
            <button-alert #saveButtonAlert>
              <mat-icon>{{ lastSaveResult ? 'check' : 'close' }}</mat-icon>
            </button-alert>
            <button mat-flat-button [loading]="saving" [disabled]="saving || isEditing" (click)="save()"
            color="primary">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="large-container">
    <div class="content-container nice-scroll">
      <app-dashboard-campaigns-action-invitees *ngIf="isInvite()" [action]="action"
        [schemaVariables]="possibleSchemmaVariables">
      </app-dashboard-campaigns-action-invitees>

      <app-dashboard-campaigns-action-mass-comm *ngIf="isMassComm()" [action]="action"
        [schemaVariables]="possibleSchemmaVariables" [schema]="currentSchema" [db]="canonicalsDB">
      </app-dashboard-campaigns-action-mass-comm>

      <app-dashboard-campaigns-action-active-call-center *ngIf="isCallCenter()" [action]="action">
      </app-dashboard-campaigns-action-active-call-center>

      <app-dashboard-campaigns-action-personalized-bot *ngIf="isBot()" [action]="action"
        [schemaVariables]="possibleSchemmaVariables">
      </app-dashboard-campaigns-action-personalized-bot>
    </div>
  </div>
</div>
