import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type TContactListArray = Array<{id: string, name: string}>
type TDataReveivedFromParentComponent = {idContact: string, contactList: TContactListArray}

@Component({
    selector: 'app-attendance-active-call-add-contact-to-list',
    templateUrl: './attendance-active-call-add-contact-to-list.component.html',
    styleUrls: ['./attendance-active-call-add-contact-to-list.component.css']
})
export class AttendanceActiveCallAddContactToListComponent implements OnInit {
    clientContactListDataArray$ = Promise.resolve([])

    constructor(
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) data: TDataReveivedFromParentComponent
    ) {
        this.clientContactListDataArray$ = Promise.resolve(data.contactList)
    }

    ngOnInit() { }

    onListNameClicked() {
        // TODO(LAND-147): send request insert contact into clicked list
    }

    onSaveClicked() {
        // TODO(LAND-147): send request to save contact to parent list, esse data.idContact pode ser:
        // 1 - contato do search result
        // 2 - contato criado pelo usuario de backoffice
        this.dialogRef.close()
    }
}
