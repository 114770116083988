import { Serializable } from "@colmeia/core/src/business/serializable";
import { EColmeiaAPIErrors } from "@colmeia/core/src/shared-business-rules/colmeia-apis/api-errors.model";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { isValidRef } from "@colmeia/core/src/tools/barrel-tools";
import { RequireAtLeastOne, throwErrorTranslation, empty, isInvalidString, getIfNotValid, ifValidExecute } from "@colmeia/core/src/tools/utility";
import { CustomErrorField } from "../../../error-control/custom-error";
import { errorCodes } from "../../../error-control/error-definition";
import { gTranslations } from "../../../shared-business-rules/const-text/translations";
import { ITranslationConfig } from "../../../shared-business-rules/translation/translation-engine";
import { getFnName } from "../../get-fn-name";


interface IThrowErrorTranslation {
    translation?: ITranslationConfig;
    fnName?: string;
    messages?: string[];
}

export enum EMsgErrorType {
    error = 'error',
    warning = 'warning',
}

export type TThrowErrorTranslation = RequireAtLeastOne<IThrowErrorTranslation, 'messages' | 'translation'>

export function quickThrowErrorTranslation(source: TThrowErrorTranslation): never {
    const {
        translation = gTranslations.common.error,
        fnName = getFnName(2),
        messages = [],
    } = source;
    return throwErrorTranslation(translation, fnName, ...messages)
};

export function throwWarningTextWithId(idError: EColmeiaAPIErrors, message: string, msgType: EMsgErrorType = EMsgErrorType.warning) {
    throwErrorTextWithId(idError, message, msgType)
}

export function throwErrorTextWithId(
    idError: EColmeiaAPIErrors,
    message: string,
    msgType?: EMsgErrorType,
    level: number | string = 2,
): never {
    const isError = msgType == EMsgErrorType.error || msgType == null;
    const isWarning = msgType == EMsgErrorType.warning;
    throwErrorText(`errorId: "${idError}" description: "${message}"`, level, isError, isWarning);
}

export function throwErrorText(
    message: string,
    level: number | string = 2,
    isError?: boolean,
    isWarning?: boolean,
    title?: string,
): never {
    const fnName = typeof level === 'string' ? level : getFnName(level);

    const error = new CustomErrorField(
        errorCodes.client.internalError,
        isError,
        empty,
        fnName,
        message
    );

    error.setTitle(title);
    error.setCustomMessageErrorFlag(true);
    error.isWarning = isWarning;

    throw error;
};

export function throwBusinessError(
    message: string,
    level: number | string = 2,
): never {

    const fnName = typeof level === 'string' ? level : getFnName(level);

    const error = new CustomErrorField(
        errorCodes.client.businessError,
        false,
        empty,
        fnName,
        message
    );

    error.setCustomMessageErrorFlag(true);
    error.isWarning = true;

    throw error;
};


export function throwErrorMessage(input: GenericSharedService.ThrowInput): never {
    const options = getThrowErrorOptions(input);

    throwErrorText(
        options.message,
        options.level,
        options.isError,
        options.isWarning,
        options.title,
    );
}


export function getThrowErrorOptions(input: GenericSharedService.ThrowInput): ThrowErrorOptions {
    if (isBasicThrowInput(input)) {
        return getThrowErrorOptions({
            message: getBasicThrowInputMessage(input),
        });
    }

    let isError: boolean | undefined;
    if (isValidRef(input.isBusiness)) isError = !input.isBusiness;
    isError ??= !input.isWarning;

    return {
        title: input.title ? getBasicThrowInputMessage(input.title) : undefined,
        message: getBasicThrowInputMessage(input.message),
        level: input.fnName ?? 3,
        isError,
        isWarning: input.isWarning,
    };
}

interface ThrowErrorOptions {
    title?: string;
    message: string;
    level?: number | string;
    isError?: boolean;
    isWarning?: boolean;
}

export function isBasicThrowInput(input: GenericSharedService.ThrowInput): input is GenericSharedService.BasicThrowInput {
    return (typeof input === 'string') || ('serializableId' in input);
}

export function getBasicThrowInputMessage(input: GenericSharedService.BasicThrowInput) {
    if (typeof input === 'string') return input;
    return Serializable.getTranslation(input)!;
}


export function getErrorClassification(error: Error): string {
    if (isInvalidString(error?.message)) {
        return 'invalid error';
    }

    if (error.message.includes('pair rate limit')) {
        return 'Rate limit';
    }

    if (error.message.startsWith('7 PERMISSION_DENIED: This API method requires billing')) {
        return 'no-fb-credit';
    }

    if (error.message.startsWith("ETIMEDOUT")) {
        return "ETIMEDOUT";
    }

    if (error.message.startsWith("Request failed with status code 400") && error.name === 'AxiosError') {
        return "axios-400";
    }

    if (error.message.startsWith("Request failed with status code 500") && error.name === 'AxiosError') {
        return "axios-500";
    }

    if (error.message.startsWith('Cannot read property')) {
        return 'property-of-undefined';
    }

    if (error.message.startsWith('Error: 3 INVALID_ARGUMENT: entity is too big')) {
        return 'ds-entity-oversized';;
    }

    if (error.message.startsWith("Request failed with status code 414")) {
        return 'too-long-414?luis';
    }


    return 'No Classification'
}
