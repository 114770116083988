import { TArrayID, TGlobalUID } from '../../core-constants/types';
import { TFeedbackArray } from '../../interaction/feedback';
import { TAvatarArray, Avatar } from '../../business/avatar';
import { Serializable } from '../../business/serializable';
import { isValidRef, isValidAndEqual, isValidArray, isInvalid, isInvalidArray } from '../../tools/utility';
import { Interaction, TInteractionArray } from '../../interaction/interaction';
import { isPendencyInteraction, isDemandInteraction, isInteractionAlreadyFeedbacked } from '../filters';
import { IFilterlet, IDynamicFilter2, EDynamicMatch, IFilterInterval } from './dynamic-filter-interfaces';

export type TCompareInteration = (x: Interaction, y: Interaction) => Interaction;





export interface IDynamicFilter {
    positiveMatch: boolean;
    feedbacks?: TFeedbackArray;
    avatarList?: TAvatarArray;
    timed?: boolean;
    tasks?: ITasksFilter;
};




export type TSearchFunction = (serializable: Serializable, aux?: any) => boolean;

export interface ITasksFilter {
    isPendency?: boolean;
    isDemand?: boolean;
    isOpened?: boolean;
}

export function isValidTaskFilter(tasks: ITasksFilter): boolean {
    return Object.keys(tasks).some((key) => { return isValidRef(tasks[key]) });
}




export function handleTaskFilter(dinFilter: IDynamicFilter, interaction: Interaction, idGroup: TGlobalUID, myAvatar: Avatar): boolean {
    const visibleStates: boolean[] = [];

    if (dinFilter.tasks.isPendency || dinFilter.tasks.isDemand) {
        if (dinFilter.tasks.isPendency) {
            visibleStates.push(isPendencyInteraction(idGroup, interaction, myAvatar, dinFilter.tasks.isOpened))
        }

        if (dinFilter.tasks.isDemand) {
            visibleStates.push(isDemandInteraction(idGroup, interaction, myAvatar, dinFilter.tasks.isOpened))
        }

    } else if (isValidRef(dinFilter.tasks.isOpened)) {
        visibleStates.push(!isInteractionAlreadyFeedbacked(idGroup, interaction) === isValidAndEqual(dinFilter.tasks.isOpened, true));
    }
    return visibleStates.some((el) => { return isValidAndEqual(el, true) });
};



export function handleTaskFilter2(dinFilter: IFilterlet, interaction: Interaction, idGroup: TGlobalUID, myAvatar: Avatar): boolean {
    const visibleStates: boolean[] = [];

    if (dinFilter.tasks.isPendency || dinFilter.tasks.isDemand) {
        if (dinFilter.tasks.isPendency) {
            visibleStates.push(isPendencyInteraction(idGroup, interaction, myAvatar, dinFilter.tasks.isOpened))
        }
        if (dinFilter.tasks.isDemand) {
            visibleStates.push(isDemandInteraction(idGroup, interaction, myAvatar, dinFilter.tasks.isOpened))
        }
    } else if (isValidRef(dinFilter.tasks.isOpened)) {
        visibleStates.push(!isInteractionAlreadyFeedbacked(idGroup, interaction) === isValidAndEqual(dinFilter.tasks.isOpened, true));
    }
    return visibleStates.some((el) => { return isValidAndEqual(el, true) });
};


export interface IHashInteractionFilterProcessing {
    [idInteraction: string]: IOkInteraction;
}

export interface ICheckFeedbacks {
    positiveMatch: boolean;
    avatarList: TArrayID;
    feedbacks: TArrayID;
};

export interface IServerCheckFeedbacks extends ICheckFeedbacks {
    idGroup: TGlobalUID;
    idInteraction: TGlobalUID;

};

export type TCheckFeedbackArray = Array<ICheckFeedbacks>;
export type TServerCheckFeedbackArray = Array<IServerCheckFeedbacks>;

export interface IOkInteraction {
    ok: boolean;
    checkFeedbacks?: TCheckFeedbackArray;
}

export function complexValidation(interaction: Interaction, filter: IDynamicFilter2, idGroup: TGlobalUID): IOkInteraction {
    let base: { [match: string]: TInteractionArray } = {}; // iteraction result
    let processing: { [match: string]: boolean } = {};  // selected processing type
    let allArray: TInteractionArray; // initial items when its not the first
    let interval: IFilterInterval; // date interval
    let intervalPositive: boolean = true;

    filter.filterlets.forEach((f) => {
        processing[f.match] = true

        if (!interval && f.interval) {
            interval = f.interval
            intervalPositive = f.positiveMatch;
        }
    })

    if (processing[EDynamicMatch.first]) {
        base[EDynamicMatch.first] = [interaction]

        if (interval)
            base[EDynamicMatch.first] = base[EDynamicMatch.first].filter((i) => inInterval(i, interval))
    }

    if (processing[EDynamicMatch.any] || processing[EDynamicMatch.last]) {
        allArray = Interaction.getWholeInteractionChain(idGroup, interaction)

        if (processing[EDynamicMatch.last]) {

            let chose: Interaction = interaction // will be the last iteraction

            allArray.forEach((i) => {
                if (i.getClockTick() > chose.getClockTick()) chose = i
            })

            base[EDynamicMatch.last] = [chose]

            if (interval) base[EDynamicMatch.last] = base[EDynamicMatch.last].filter(isInInterval)
        }

        if (processing[EDynamicMatch.any]) {
            base[EDynamicMatch.any] = interval ? allArray.filter(isInInterval) : allArray
        }
    }

    function isInInterval(iteraction: Interaction) {
        return inInterval(iteraction, interval, intervalPositive)
    }

    const discarded: { [idInteraction: string]: boolean } = {};

    const checkFeedbacks: TCheckFeedbackArray = [];

    for (const dinFilter of filter.filterlets) {

        let sample: TInteractionArray = base[dinFilter.match];

        if (isValidArray(sample) && isValidArray(dinFilter.feedbacks)) {

            sample = sample.filter((i) => {
                if (!discarded[i.getInteractionID()] && i.hasFeedback(idGroup, ...dinFilter.feedbacks.map((idFeedback) => { return idFeedback })) === dinFilter.positiveMatch) {
                    return true;
                } else {
                    discarded[i.getInteractionID()] = true;
                    return false;
                }
            })
        };

        if (isValidArray(sample) && isValidArray(dinFilter.interactionTypes)) {

            sample = sample.filter((i) => {
                if (!discarded[i.getInteractionID()] && i.getInteractionType().is(...dinFilter.interactionTypes) === dinFilter.positiveMatch) {
                    return true;
                } else {
                    discarded[i.getInteractionID()] = true;
                    return false;
                }
            })
        };

        if (isValidArray(sample) && dinFilter.tasks && isValidTaskFilter(dinFilter.tasks)) {

            sample = sample.filter((i) => {
                if (!discarded[i.getInteractionID()] && handleTaskFilter2(dinFilter, i, idGroup, i.getParticipant().getAvatar()) === dinFilter.positiveMatch) {
                    return true;
                } else {
                    discarded[i.getInteractionID()] = true;
                    return false;
                }
            });
        }

        if (isValidArray(dinFilter.avatarList)) {
            if (isValidArray(dinFilter.feedbacks)) {

                checkFeedbacks.push({
                    avatarList: dinFilter.avatarList, feedbacks: dinFilter.feedbacks, positiveMatch: dinFilter.positiveMatch
                })

            } else if (isValidRef(sample)){
                sample = sample.filter((i) => {
                    if (!discarded[i.getInteractionID()] && i.getParticipant().getAvatar().is(...dinFilter.avatarList) === dinFilter.positiveMatch) {
                        return true;
                    } {
                        discarded[i.getInteractionID()] = true;
                        return false;
                    }
                })
            }
        };

        if (!isValidArray(sample)) {
            return { ok: false };
        }
    }

    return {
        ok: true, checkFeedbacks: checkFeedbacks
    };

}
interface IMatchMapper {
    first: Function
    any: Function
    last: Function
}


interface ISetterCreator {
    from: object
    key: string | number
}

function inInterval(interaction: Interaction, interval: IFilterInterval, isPositiveMatch: boolean = true): boolean {
    const isInInterval = (isInvalid(interval.min) || interaction.getClockTick() >= interval.min) &&
        (isInvalid(interval.max) || interaction.getClockTick() < interval.max)
    return isPositiveMatch === isInInterval;
}
