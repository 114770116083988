import { ENextGenBotElementType, IBotMenuContainerServer } from '../../../bot/bot-model';
import { ENonSerializableObjectType } from '../../../non-serializable-id/non-serializable-id-interfaces';
import { TReferencedObject } from '../../toolbar/config-toolbar.types';
import { IHostNodeDeletionParams, IHostNodeDeletionResult } from '../ns/ns.host.types';
import { NestedAItHostNode } from './nested-ai-node.host';

export class NestedAIMenuContainerHostNode extends NestedAItHostNode {

    public static create(info: IBotMenuContainerServer): NestedAIMenuContainerHostNode {
        return new NestedAIMenuContainerHostNode(info);
    }

    public getHostedType(): TReferencedObject {
        return ENextGenBotElementType.menuContainer;
    }

    public isRoot(): boolean {
        return false;
    }

    public getNonSerializable(): IBotMenuContainerServer {
        return super.getNonSerializable() as IBotMenuContainerServer;
    }

    public getActionContainer() {
        return [{ action: undefined }];
    }
}