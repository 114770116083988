import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ElementOverflowService {

    constructor() { }

    public observeOverflow(element: HTMLElement): Observable<boolean> {
        return new Observable(subscriber => {
            const observer = new ResizeObserver((entries) => {
                let el = entries[0].target;
                const isOverflowing = this.isElementOverflowing(el as HTMLElement);

                subscriber.next(isOverflowing);
            });

            observer.observe(element);

            return function unsubscribe() {
                observer.unobserve(element);
            }
        });
    }

    private isElementOverflowing(element: HTMLElement) {
        return element.offsetWidth < element.scrollWidth;
    }
}
