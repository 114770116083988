import {Component, Inject } from '@angular/core';
import {RootComponent} from 'app/components/foundation/root/root.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ILocalCanonical, IServerLocalCanonical } from '@colmeia/core/src/shared-business-rules/canonical-model/local-canonical';
import {fullCloneObject, isValidRef} from '@colmeia/core/src/tools/utility';
import {CanonicalService} from 'app/services/canonical.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MetadataMessage} from '@colmeia/core/src/shared-business-rules/metadata/metadata-message';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { GenericDashboardEditHandler, IGenericDashboardEditPageClientCallback } from 'app/handlers/generic-dashboard-edit.handler';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';

@Component({
    selector: 'app-canonical-edit',
    templateUrl: './canonical-edit.component.html',
    styleUrls: ['./canonical-edit.component.scss']
})
export class CanonicalEditComponent extends RootComponent<
    'createTitle' |
    'editTitle' |
    'name' |
    'globalCanonical' |
    'fieldRequired' |
    'save'
> {
    canonical: ILocalCanonical = <ILocalCanonical>{};
    saving: boolean = false;
    canonicalOptions: {label: string, value: string}[];

    public genericEdit: GenericDashboardEditHandler;
    public title: string;
    public nameFormControl: UntypedFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(3)
    ]);

    constructor(
        public dialogRef: MatDialogRef<CanonicalEditComponent, IServerLocalCanonical>,
        @Inject(MAT_DIALOG_DATA) public data: ILocalCanonical,
        private canonicalSvc: CanonicalService,
    ) {
        super({
            createTitle: gTranslations.canonicals.createTitle,
            editTitle: gTranslations.canonicals.editTitle,
            name: gTranslations.common.name,
            globalCanonical: gTranslations.canonicals.globalCanonical,
            fieldRequired: gTranslations.common.fieldRequired,
            save: gTranslations.common.save
        });

        if (isValidRef(data)) {
            this.canonical = fullCloneObject(data);
        }

        this.title = this.canonical.idNS ? this.translations.editTitle.value : this.translations.createTitle.value;
    }

    ngOnInit() {
        this.canonicalOptions = MetadataMessage.getMessageMetadataArray()
            .map(meta => ({label: meta.getMetadataName(), value: meta.getMetadataID()}));

        this.genericEdit = new GenericDashboardEditHandler({
            // title: '',
            nsType: ENonSerializableObjectType.canonical,
            clientCallback: <IGenericDashboardEditPageClientCallback>{
            } as IGenericDashboardEditPageClientCallback,

            nser: this.canonical,
            removeGoBackButton: true,
            removeSaveButton: true,
            removeHexagon: true,
            allowEditTitleInHeader: true,
        });
    }

    async save() {
        if (this.saving) return;
        this.saving = true;
        const saved = await this.canonicalSvc.saveCanonical(this.canonical);
        this.dialogRef.close(saved);
    }

}
