import { Interaction } from "../interaction";
import {Participant} from '../../business/participant';
import {constant, TGlobalUID} from '../../business/constant';
import { InteractionType } from "../interaction-type";
import { IMenuReplyInteractionJSON } from "./menu-interaction-interfaces";
import { UberCache } from "../../persistency/uber-cache";
import { ChainedInteraction } from "../chained-interaction";
import { MenuInteraction } from "./menu-interaction";


export class MenuReply extends ChainedInteraction  {
    
    private selectedOption: string;

    private constructor(primaryID: TGlobalUID, participant: Participant, menu: MenuInteraction) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.menu.menuReply), menu);
    };
    
    public rehydrate(json: IMenuReplyInteractionJSON): void {
        super.rehydrate(json);
        this.choseSelected(json.selectedOption);
    };

    public getInteractionParent(): MenuInteraction {
        return <MenuInteraction>super.getInteractionParent();
    }

    public choseSelected(chose: string): void { this.selectedOption = chose}

    public getSelectedAnswer(): string {
        return this.selectedOption;
    }

    public toJSON(): IMenuReplyInteractionJSON {
        const json: IMenuReplyInteractionJSON = {
            ...super.toJSON(),
            selectedOption: this.selectedOption,
            idInteractionType: constant.interactionType.menu.menuReply,
        }
        MenuReply.addTextToJSON(json, this.selectedOption, constant.serializableField.chat_text)
        return json;
    }

    public static factoryMessage(json: IMenuReplyInteractionJSON, participant: Participant = null): MenuReply {
        let definition: MenuReply = <MenuReply>Interaction.searchCachedInteraction(json.primaryID);
        if (definition == null)
            definition = <MenuReply> new MenuReply(
               json.primaryID, 
               participant? participant: Participant.staticFactory(json.participant.primaryID),
               MenuInteraction.staticFactory(json.idInteractionParent)
            );
        definition.rehydrate(json);
        return definition;
    };

    public static staticFactory(idThread: TGlobalUID): MenuReply {
        return <MenuReply>UberCache.uberFactory(idThread, constant.objectType.interaction, true);
    };

    public static factory(participant: Participant, menu: MenuInteraction, option: string): MenuReply {
        const interaction: MenuReply = new MenuReply(null, participant, menu);
        interaction.choseSelected(option);
        return interaction;
    }

};


