import { IUniversalJSON, TGroupGenealogy, IAvatarJSON, IDSSearchEngineData } from './business-interfaces';
import { ISearchInteractionAvatarDetail, TGlobalUID, ISearchParticipantDetail, TArrayID } from '../core-constants/types';
import { IPushNotificationPolicy, TNotificationDeviceArray } from '../business/push-notifications';
import { EIdMenus } from '@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus';



export interface IDSSearchGroupAccelerator {
    isArchived: boolean;
    isFirstLevelVisible: boolean;
    personal: boolean;
    functional: boolean;
    root: boolean;
    functionalRoot: boolean;
    commom: boolean;
    support: boolean;
    isGrpContainer: boolean;
}


export interface IDSSearchAccelerator {
    participant?: {
        idPlayer: TGlobalUID
    };
    group?: IDSSearchGroupAccelerator,
};

export interface ISocialContextServiceGroup {
    idSocialContext: TGlobalUID;
    idServiceGroup: TGlobalUID;

}

export type TServiceGroupArray = Array<ISocialContextServiceGroup>;

export type TEmployeeSNArray = Array<IEmployeeInfo>;

export interface IEmployeeInfo {
    idSocialNetwork: TGlobalUID;
    idAvatar: TGlobalUID;
    clockTick: number;
}

export type IAvatarInfo = IAvatarJSON;

export interface ISearchResult extends IDSSearchEngineData {
    idObjectType: TGlobalUID,
    primaryID: TGlobalUID,
    text: string,
    idMedia: TGlobalUID;
    universalJSON: IUniversalJSON;
    interactionAvatarDetail: ISearchInteractionAvatarDetail;
    participantDetail: ISearchParticipantDetail;
};

export interface ISearchInfo {
    auxOptionID?: TGlobalUID,
    searchContext: TGlobalUID;
    primaryID?: TGlobalUID,
    genealogy?: TGroupGenealogy,
    getFirstElementsWithNoTyping?: boolean;
    text: string,
    internalUserRelatedData?: boolean;
    idAgentIsland?: string;
    menuID?: EIdMenus;
};

export interface IUberCacheVersion {
    sociaContext: string,
    DSCacheVersion: number,
};

export enum ELoginStepType {
    SignUp = "signUp",
    ForgotPass = "forgotpass",
}

export interface ILoginStepDescriptor {
    name?: string;
    email: string;
    clockTick: number;
    loginType: ELoginStepType;
};

export type TAllSocialNetworkArray = Array<ISocialNetworkMembership>;

export interface ISocialNetworkMembership {
    idSocialContext: TGlobalUID;
    idAvatar: TGlobalUID;
    idPlayer: TGlobalUID;
    clockTick: number;
    email: string;
};

export type TSearchResultArray = Array<ISearchResult>;

export type TParticipationArray = Array<IParticipation>;

export interface IGrpTypeMap {
    isa?: boolean; // isPersonal Avatar
    isp?: boolean; // isPersonal
}

export interface IParticipation {
    avt: TGlobalUID  // avt
    rls: TArrayID;     // roles
    rig: Array<string>; // idRrights
    par: TGlobalUID;  // idParticipant
    gen: TArrayID; // genealogy
    tpg?: IGrpTypeMap; // Mapa de tipo de grupos

    grp?: TGlobalUID; // idGroup

    isp?: boolean; // APAGAR -- COMPATIBILIDADE
}

export interface IGroupParticipation {
    [idGroup: string]: IParticipation;
};

export interface IPlayerAccessCached {
    idPlayer: TGlobalUID;
    idPersonalGroup: TGlobalUID;
    part: IGroupParticipation;
}

export interface IClientInfoPlayer {
    surrogate: TGlobalUID;
    lastSurrogateClock: number;
    isOnlineLoggingGPS: boolean;
    email: string;
    employerInfo: TEmployeeSNArray;
    notificationPolicy: IPushNotificationPolicy;
    devices: TNotificationDeviceArray;
    aux: {
        idOriginalAvatar: TGlobalUID;
        idPersonalGroup: TGlobalUID;
        idParticipant: TGlobalUID;
    },
}
