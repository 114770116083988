import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Crypt } from "@colmeia/core/src/security/crypt";
import { IWalletBalanceRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IWalletBalanceResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { DatePipe } from '@angular/common';
import { getBalance } from '@colmeia/core/src/rules/finance-rules';
import { TArrayWalletOperation, IWalletOperationInteractionJSON, EWalletOperation, IWalletTransferInteractionJSON }
    from '@colmeia/core/src/comm-interfaces/finance-interfaces';
import {
    ChargeDialogComponent,
    IChargeDialogData,
    IChargeDialogHandler
} from "../../../dialogs/charge-dialog/charge-dialog.component";
import { ColmeiaDialogService, IColmeiadialogServiceParams } from "../../../../services/dialog/dialog.service";
import { RequestBuilderServices } from "../../../../services/request-builder.services";
import { ServerCommunicationService } from "../../../../services/server-communication.service";
import { UserSettingsService } from "../../../../services/user-settings.service";
import { SessionService } from "../../../../services/session.service";
import { IInfraParameters } from "../../../../model/client-infra-comm";
import { HandlerHexagonon } from "../../../../handlers/hexagono.handler";
import {
    ITransferDialogData,
    ITransferDialogHandler,
    TransferDialogComponent
} from "../../../dialogs/transfer-dialog/transfer-dialog.component";
import { ECmBtnColorType } from "../../../foundation/cm-button/cm-button.component";
import { GlobalWarningComponent } from "../../../dialogs/global-warning/global-warning.component";
import { IMsgDialogDescriptor } from "../../../../model/waring-message-interfaces";
import { EMsgDialogType } from "../../../../model/misc.model";
import { RootComponent } from 'app/components/foundation/root/root.component';
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";

export interface IMediaTransfer {
    name: string;
    idMedia: string;
    isReceiving: boolean;
}


@Component({
    selector: 'app-wallet-settings',
    templateUrl: './wallet-settings.component.html',
    styleUrls: ['./wallet-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WalletSettingsComponent extends RootComponent<'loadBalance' | 'transfer' | 'balance' | 'operation' | 'date' | 'availableBalance'> implements OnInit, ITransferDialogHandler, IChargeDialogHandler {

    displayedColumns: string[] = ['id', 'date', 'operation', 'value'];
    dataSource = [];

    public balance: number = 0;

    private datePipe: DatePipe;

    constructor(
        private dialog: ColmeiaDialogService,
        private dialogRef: MatDialog,
        private rbs: RequestBuilderServices,
        private server: ServerCommunicationService,
        private settings: UserSettingsService,
        private session: SessionService,
        private cdr: ChangeDetectorRef
    ) {
        super({
            loadBalance: gTranslations.sellable.loadBalance,
            transfer: gTranslations.sellable.transfer,
            balance: gTranslations.sellable.balance,
            operation: gTranslations.sellable.operation,
            date: gTranslations.common.date,
            availableBalance: gTranslations.sellable.availableBalance,
        });

        this.datePipe = new DatePipe(this.settings.getSelectedLocale());
    }

    ngOnInit() {
        setTimeout(async () => {
            const request: IWalletBalanceRequest = {
                ...this.rbs.secureBasicRequest(apiRequestType.wallet.getStatement)
            };
            const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
            const response = await this.server.managedRequest(infra, request);

            if (response.executionOK) {
                const _res: IWalletBalanceResponse = <IWalletBalanceResponse>response.response;

                const info = this.session.getPlayerInfoServices().getPlayerInfo();

                const operations: TArrayWalletOperation = _res.balance;

                this.balance = getBalance(this.session.getPlayerID(), operations);

                this.dataSource = operations
                    .sort((a: IWalletOperationInteractionJSON, b: IWalletOperationInteractionJSON) => {
                        return b.clockTick - a.clockTick;
                    })
                    .map((op, index) => {

                        const clock = op.clockTick;
                        const date = this.datePipe.transform(clock, this.settings.getDateFormat()),
                            time = this.datePipe.transform(clock, this.settings.getTwentyFourTimeFormat());

                        let transferAvatar: IMediaTransfer;
                        if (info.avatarBelongsToPlayer(op.wallet.idSenderAvatar)) {

                            if (op.walletOperation === EWalletOperation.transfer) {
                                const transfer: IWalletTransferInteractionJSON = <IWalletTransferInteractionJSON>op;
                                transferAvatar = {
                                    name: transfer.wallet.person.nameReceiver,
                                    idMedia: transfer.wallet.person.idMediaReceiver,
                                    isReceiving: false,
                                }
                            }
                        } else {
                            const transfer: IWalletTransferInteractionJSON = <IWalletTransferInteractionJSON>op;
                            transferAvatar = {
                                name: transfer.wallet.person.nameSender,
                                idMedia: transfer.wallet.person.idMediaSender,
                                isReceiving: true,
                            }

                        }
                        return {
                            id: Crypt.sha256Str(op.primaryID).slice(0, 6).toUpperCase() + '...',
                            date: `${date} as ${time}`,
                            operation: (op.walletOperation === EWalletOperation.charge) ? 'Carga' : 'Transferência',
                            value: op.amount.amount,
                            transferAvatar
                        }

                    });
                this.cdr.markForCheck();
            }

        }, 300);
    }

    generateHandler(element: any) {
        if (element.transferAvatar) {
            return HandlerHexagonon.newHandler({
                size: "xs",
                forceImage: element.transferAvatar.idMedia
            });
        }
    }

    getTranferTypeLabel(transferAvatar: IMediaTransfer): string {
        return transferAvatar.isReceiving
            ? 'De:'
            : 'Para:'
    }

    onChargeButtonClick(): void {
        const param: IColmeiadialogServiceParams<ChargeDialogComponent, IChargeDialogData> = {
            title: 'Recarga',
            componentRef: ChargeDialogComponent,
            dataToComponent: {
                data: {
                    handler: this,
                }
            }
        };
        this.dialog.open<ChargeDialogComponent, IChargeDialogData>(param);
    }

    onChargeCallback(): void {
        this.dialogRef.closeAll();
        this.onSuccessTransaction('Solicitação de recarga efetuada');
    }

    onTransferButtonClick(): void {
        const params: IColmeiadialogServiceParams<TransferDialogComponent, ITransferDialogData> = {
            title: 'Transferência',
            dataToComponent: {
                data: {
                    handler: {
                        onSendTransferCallback: this.onSendTransferCallback.bind(this),
                        balance: this.balance,
                    },
                },
            },
            componentRef: TransferDialogComponent
        };
        this.dialog.open<TransferDialogComponent, ITransferDialogData>(params);
    }

    onSendTransferCallback(): void {
        this.dialogRef.closeAll();
        this.onSuccessTransaction('Transação efetuada com sucesso!!');
    }

    getTransferBtnColor(): ECmBtnColorType {
        return ECmBtnColorType.White;
    }

    getBalanceBtnColor(): ECmBtnColorType {
        return ECmBtnColorType.Main;
    }

    onSuccessTransaction(message: string): void {
        const params: IColmeiadialogServiceParams<GlobalWarningComponent, IMsgDialogDescriptor> = {
            title: '',
            componentRef: GlobalWarningComponent,
            dataToComponent: {
                data: {
                    type: EMsgDialogType.Success,
                    messages: [],
                    clientCallback: null,
                }
            }
        };

        this.dialog.open<GlobalWarningComponent, IMsgDialogDescriptor>(params);
    }

    canShowTable(): boolean {
        return this.dataSource.length > 0
    }
}
