import { ENonSerializableListMode, ICustomNSPicker, IListNonSerializablesMatch, IListNonSerializablesRequest, IListNonSerializablesSort } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { IListNonSerializablesResponse } from "@colmeia/core/src/dashboard-control/dashboard-response-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { GenericRequest, IRequest, IRequestCursor, TMultipleCursor } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { toMultipleCursor } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions";
import { ENonSerializableObjectType, INonSerializable } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { ITagableSearch } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp";
import { ITranslationConfig } from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import { getIfNotValid, isValidArray, isValidObject, isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { DeepFindValuesOfType } from "@colmeia/core/src/tools/utility-types";
import { IGenericHomeHandlerParameter } from "app/components/dashboard/dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { IInfraParameters } from "app/model/client-infra-comm";
import { TRapCurrentDashboardClientRoutes } from "app/model/dashboard/db/dashboard-db-mapper";
import { defaultNSRoute, routeID, routeList } from "app/model/routes/route-constants";
import { BpmService } from "app/services/bpm/bpm.service";
import { RequestBuilderServices } from "./request-builder.services";
import { RoutingService } from "./routing.service";
import { SpinType } from "./screen-spinner.service";
import { ServerCommunicationService } from "./server-communication.service";
import { SessionService } from "./session.service";


export interface GenericNSServicePack {
    api: ServerCommunicationService,
    rbs: RequestBuilderServices,
    session: SessionService,
    routeSvc: RoutingService,
}


export interface IAdditionalGenericNonSerializableService {
    options: IAdditionalGenericNonSerializableServiceOptions;
    mapEntity: IGenericHomeHandlerParameter['clientCallback']['mapEntity'];
    getRequest: IGenericHomeHandlerParameter['clientCallback']['getRequest'];
}


export interface IAdditionalGenericNonSerializableServiceOptions {
    readonly nsType: ENonSerializableObjectType;
    readonly requestType: DeepFindValuesOfType<typeof apiRequestType, string>;
    readonly title: DeepFindValuesOfType<typeof gTranslations, ITranslationConfig>;
    readonly currentDashboardRoute: TRapCurrentDashboardClientRoutes;
    readonly defaultNSRoute: DeepFindValuesOfType<typeof routeList, { children: typeof defaultNSRoute }>
}


interface IBaseRoute {
    path: string; children: { create?: { path: string }; details?: { path: string; routeParam: string; }, list: { path: string } }
}
export class GenericNonSerializableService<BaseRoute extends IBaseRoute = IBaseRoute> {

    lastSelectedItem: INonSerializable;
    _selectedNS: INonSerializable;
    private get selectedNS(): INonSerializable {
        return this._selectedNS;
    }
    private set selectedNS(value: INonSerializable) {
        this._selectedNS = value;

        if (isValidRef(value)) this.lastSelectedItem = value;
    }


    protected api: ServerCommunicationService;
    protected rbs: RequestBuilderServices;
    protected session: SessionService;
    protected routeSvc: RoutingService;

    constructor(
        protected mainRoute: string,
        protected baseRoute: BaseRoute,
        servicePack: GenericNSServicePack,
    ) {
        this.api = servicePack.api;
        this.rbs = servicePack.rbs;
        this.session = servicePack.session;
        this.routeSvc = servicePack.routeSvc;
    }



    get selectedItem() {
        const item = this.selectedNS;
        this.selectedNS = null;
        return item;
    }

    protected buildInfra(): IInfraParameters {
        return this.rbs.getNoCallBackSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );
    }

    getRequestForNS(options: { apiRequestType: string, cursor: string, nsType: ENonSerializableObjectType, taggable?: ITagableSearch, idParent?: string }): IListNonSerializablesRequest {
        return {
            ...this.rbs.secureBasicRequestForCustomGroup(options.apiRequestType, this.session.getCurrentSocialNetworkID()),
            multipleCursor: toMultipleCursor(options.cursor || null),
            nsType: options.nsType,
            taggable: options.taggable,
            idParent: options.idParent,
        }
    }

    protected async send<T extends IResponse>(request: IRequest, spinType?: SpinType): Promise<T> {
        const infra = this.buildInfra();
        const result = await this.api.managedRequest(isValidRef(spinType) ? { ...infra, spinType } : infra, request);
        return result.executionOK ? result.response as T : null;
    }

    protected async isSuccessfull(request: IRequest, spinType?: SpinType): Promise<boolean> {
        return isValidRef(await this.send(request, spinType));
    }

    protected baseRequest<T extends string>(requestType: T): GenericRequest<T> {
        return this.rbs.createRequestFromInfraParameters(requestType, this.buildInfra());
    }

    protected getItems(apiRequestType: string, cursor: string = null) {
        const request: IRequestCursor = {
            ...this.baseRequest(apiRequestType),
            cursor
        };
        return this.send(request);
    }

    public async getChildren<T extends INonSerializable>(
        idParent: string,
        nsType: ENonSerializableObjectType,
        cursor: string = null,
        search: ITagableSearch = undefined,
        custom: ICustomNSPicker = undefined,
        listMode?: ENonSerializableListMode,
    ): Promise<T[]> {
        const request: IListNonSerializablesRequest = {
            ...this.getChildrenRequest(idParent, nsType, cursor, search),
            custom,
            listMode,
        };
        const response = await this.send<IListNonSerializablesResponse>(request);
        return response ? response.nonSerializableArray as T[] : null;
    }

    public async getChildrenFullResponse<T extends INonSerializable>(
        idParent: string,
        nsType: ENonSerializableObjectType,
        cursor: string | TMultipleCursor = null,
        search: ITagableSearch = undefined,
        custom: ICustomNSPicker = undefined,
        listMode?: ENonSerializableListMode,
        amountItemsPerPage?: number,
        searchToken?: string,
        match?: IListNonSerializablesMatch[],
        sort?: IListNonSerializablesSort,
        idNSRemoteEnvAuthProvider?: string,
        requestModifierCallback?: (request: IListNonSerializablesRequest) => IListNonSerializablesRequest
    ): Promise<IListNonSerializablesResponse<T>> {
        let request: IListNonSerializablesRequest = {
            ...this.getChildrenRequest(
                idParent,
                nsType,
                cursor,
                search,
                isValidString(idNSRemoteEnvAuthProvider)
                    ? apiRequestType.nonSerializable.listRemoteEnv
                    : apiRequestType.nonSerializable.list),
            custom,
            listMode,
            amountItemsPerPage,
            searchToken,
            idNSRemoteEnvAuthProvider
        };

        if (isValidRef(requestModifierCallback)) {
            request = requestModifierCallback(request);
        }


        if (isValidArray(match)) {
            request.match = match;
        }


        if (isValidObject(sort)) {
            request.sort = sort;
        }


        return this.send<IListNonSerializablesResponse<T>>(request);
    }

    public getChildrenRequest<T extends INonSerializable>(
        idParent: string,
        nsType: ENonSerializableObjectType,
        cursor: string | TMultipleCursor = null,
        search: ITagableSearch = undefined,
        customRequestType?: DeepFindValuesOfType<typeof apiRequestType, string>
    ): IListNonSerializablesRequest {
        const request: IListNonSerializablesRequest = {
            ...this.baseRequest(getIfNotValid(customRequestType, apiRequestType.nonSerializable.list)),
            multipleCursor: isValidString(cursor) ? toMultipleCursor(cursor as string) : cursor as TMultipleCursor,
            idParent,
            taggable: search,
            nsType
        };
        return request;
    }

    public goToCreate() {
        this.selectedNS = null;
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.create.path
        );
    }


    public goToDetails(item: INonSerializable) {
        this.selectedNS = item;
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.details.path.replace(`${this.baseRoute.children.details.routeParam}`, item.idNS),
        );
    }

    public goToList(): void {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.list.path,
        );
    }
}
