import { constant } from '@colmeia/core/src/business/constant';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { TArrayID, TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { Slice, getPropertiesFromReusableProxy, getReusableTypedProperty, isInvalidArray, isValidArray, pickKeys, putBrackets } from '@colmeia/core/src/tools/utility';
import { $ValueOf, Assert, Filter, GetByKey, GetDeepPropertyValue, IsEqual, Join, Replace, Split, Tail, UnionLast, UnionToIntersection } from '@colmeia/core/src/tools/utility-types';
import { StringNumber } from '@colmeia/core/src/tools/utility/types/basic';
import { $$ } from '@colmeia/core/src/tools/utility/types/error';
import { RoutingService } from 'app/services/routing.service';
import * as _ from 'lodash';
import { IBaseRouteListPathPattern, TRouteListPathContainer, routeID, routeList } from "../model/routes/route-constants";
import { CCustomType } from '@colmeia/core/src/core-constants/named-types';

type TProperties<T extends unknown[]> = CCustomType<T>;
type DeepAddProperties<T, Properties extends string[] = []> =
    { [key in keyof T]: (T[key] extends string ? TProperties<[...Properties, key]> : DeepAddProperties<T[key], Assert<[...Properties, key], string[]>>) }
;
export type GetRecursive<T, Properties extends string[] = []> = Properties extends [] ? T : GetRecursive<GetByKey<T, Properties[0]>, Assert<Tail<Properties>, string[]>>;


declare namespace BuildRoute {
    // <Gabriel's *magic*>
    type ValidateProperties<T, Properties extends string[] = [], Current extends unknown[] = []> =
        Properties extends [] ? Current :
        Properties extends [infer Property, ...infer Remaining] ?
            GetByKey<T, Property> extends infer Value ?
                IsEqual<Value, unknown> extends true ?
                [...Current, T extends object ? $$<keyof T> : $$<'No more properties'>, ...Replace<Remaining, $$<'Error'>>]
                : ValidateProperties<Value, Assert<Remaining, string[]>, [...Current, unknown]>
            : never
        : [];

    type Source = typeof routeList
    // </Gabriel's *magic*>

    type FindLastIndex<T extends unknown[], Predicate, Negate extends boolean = false> =
        UnionLast<Filter<
            T,
            Predicate,
            {
                IsNegating: Negate;
                IsIndex: true;
            }
        >> extends infer Result ? Result extends StringNumber ? Result : never : never
    ;

    type SliceUntil<T extends unknown[], Predicate, LastIndex extends StringNumber = FindLastIndex<
        T,
        Predicate,
        true
    >> =
        [T[0], ...Slice<Tail<T>, '0', LastIndex>]
    ;

    type SliceItems<T extends unknown[], Current extends unknown[] = [], Result extends unknown[] = []> =
        T extends [] ? Result : T extends [infer Value, ...infer Values] ? SliceItems<Values, [...Current, Value], [...Result, [...Current, Value]]> : never
    ;
    type GetProperties<T> = T extends TProperties<[...infer Values]> ? Assert<Values, string[]> : never;

    

    type MapGetRecursivePath<Source, T extends string[][],
        List,
        // @ts-expect-error
        Computed extends TRouteListPathContainer = (
            MapGetRecursive<List, T>
        ),
    > = { [key in keyof Computed]: Computed[key] extends IBaseRouteListPathPattern ? Computed[key]['path'] : Computed[key] extends string ? Last<GetByKey<T, key>> extends keyof Pick<IBaseRouteListPathPattern, 'path'> ? '' : Computed[key] : '' };

    type Last<T> = T extends [...infer Items, infer LastItem] ? LastItem : never;

    type MapGetRecursive<Source, T extends string[][]> = { [key in keyof T]: GetRecursive<Source, Assert<GetByKey<T, key>, string[]>> };

    
    type BuildRoute<Properties, List = typeof routeList> =
        Properties extends TProperties<infer Values> ?
        SliceItems<Values> extends infer Current ?
        // @ts-expect-error
        MapGetRecursivePath<List, Current, List> extends infer Computed ?
        Computed extends string[] ?
        `/${Join.SimpleJoin<Computed, '/', ''>}`
        : never
        : never
        : never
        : never
    ;
}

declare const $routeList: DeepAddProperties<typeof routeList>
export type $BuildedRoute<T> = CCustomType<["BuildedRoute", T]>;

export function get$RouteList(): typeof $routeList {
  const properties: string[] = [];
  const $source: any = new Proxy(properties, {
    get: (target: string[], property: string | typeof Symbol.iterator) => {
      if (property === Symbol.iterator) return target[Symbol.iterator].bind(target)
      properties.push(property)
      return $source;
    },
  })
  return $source
}

export type ExtractChildren<PC> = {
    [key in keyof PC]:
        PC[key] extends { path: infer P, children?: infer C } ?
            P extends TProperties<infer Props> ?
            C extends object ?
                (P & ExtractChildren<C>)
            : P & PC[key]
            : unknown
        :
        PC[key]
};

type DeepBuild<T> = { [key in keyof T]: $BuildedRoute<BuildRoute.BuildRoute<T[key], typeof routeList>> & (T[key] extends TProperties<infer P> ? IsEqual<T[key], TProperties<P>> extends true ? unknown : DeepBuild<T[key]> : DeepBuild<T[key]>) }





type BaseRouteListPathPattern<Info> = {
    path?: Info;
    idMenu?: Info;
    children?: RouteListPathContainer<Info>
};
type RouteListPath<Info = string> =
| string
| BaseRouteListPathPattern<Info>
| RouteListPathContainer<Info>
;

type RouteListPathContainer<Info> = {
    [key in string ]: RouteListPath<Info>
};

const $pickRouteProperty = pickKeys<IBaseRouteListPathPattern>();
const $pickRoutePropertyPath = $pickRouteProperty.path;
const $pickRoutePropertyParam = $pickRouteProperty.param;
const $pickRoutePropertyChildren = $pickRouteProperty.children;
export class RoutingBuilder {


    public static $pickRouteList = getReusableTypedProperty<DeepBuild<ExtractChildren<DeepAddProperties<typeof routeList>>>>(properties => {
        return () => RoutingBuilder.createRoute(properties);
    });

    public static toRouteString<T extends string>(value:
            & $BuildedRoute<T>
        ): T {
        return String(value) as T;
    }

    static build<Properties extends [...V], V extends string[]>(...params:  Properties & UnionToIntersection<GetRecursive<BuildRoute.Source, Properties> extends infer Result ? IsEqual<Result, unknown> extends true ? BuildRoute.ValidateProperties<BuildRoute.Source, Properties> : never : unknown>) {
        let targetObject: any;
        let result: string = '';

        params.forEach((key, index) => {
            result += (index === params.length - 1 ? "/"+targetObject[key] : (targetObject?.path ? "/"+targetObject.path : "") );
            targetObject = (targetObject || routeList)[key];
        });

        return result;
    }

    static pickBuilder<BaseParamsReturn>(fn: ((source: typeof $routeList) => BaseParamsReturn)) {
        return <Properties>(otherFn: (source: BaseParamsReturn) => Properties) => {
            const $proxy = get$RouteList();
            fn($proxy);
            otherFn($proxy as unknown as BaseParamsReturn);
            const computed = RoutingBuilder.build(...[...$proxy as unknown as string[]]);
            return computed as BuildRoute.BuildRoute<Properties>;
        };
    }

    public static createRoute(paths: string[]): string {
        const computed = paths
            .map((item, index, items) => [...items.slice(0, index).map(item => [item, $pickRoutePropertyChildren]).flat(), item] )
            .map((route: string[]): string => {
                let got: string = _.get(routeList, route)
                if (!_.isString(got)) got = _.get(routeList, [...route, $pickRoutePropertyPath])
                if (!_.isString(got)) got = _.get(routeList, [...route.slice(0, -2), _.last(route)!])
                return got;
            })
            .filter(item => item)
            .map(item => `/${item}`)
            .join('')
        ;
        return computed;
    }

    public static pick<Route extends string>(getPath: ($proxy: typeof RoutingBuilder.$pickRouteList) => $BuildedRoute<Route>): Route {
        return RoutingBuilder.toRouteString(getPath(RoutingBuilder.$pickRouteList))
    }

    // <More Gabriel's *magic*>
    static builder<Properties extends [...V], V extends string[], AdditionalSource = GetDeepPropertyValue<BuildRoute.Source, Properties>>(...baseParams: Properties & UnionToIntersection<GetRecursive<BuildRoute.Source, Properties> extends infer Result ? IsEqual<Result, unknown> extends true ? BuildRoute.ValidateProperties<BuildRoute.Source, Properties> : never : unknown>) {
        return <AdditionalProperties extends [...V], V extends string[]>(...otherParams: AdditionalProperties & UnionToIntersection<GetRecursive<AdditionalSource, AdditionalProperties> extends infer Result ? IsEqual<Result, unknown> extends true ? BuildRoute.ValidateProperties<AdditionalSource, AdditionalProperties> : never : unknown>) => RoutingBuilder.build.apply(undefined, [...baseParams, ...(isValidArray(otherParams) ? otherParams : [])]);
    }
    // </More Gabriel's *magic*>

    public static getRouteFromSerializable(serializable: Serializable, ...params: TArrayID): string {
        return RoutingBuilder.getRoute(serializable.getAngularRouteID(), ...params);
    }

    public static getRoute(idRoute: string, ...params: TArrayID): string {
        let route: string | null = null;

        switch (idRoute) {
            case routeID.location.locationViewer.id: {
                const avatarID: TGlobalUID = params[0];

                route = putBrackets(
                    routeList.maps.path,
                    routeList.maps.children.locationViewer.path,
                    routeList.maps.children.locationViewer.children.avatars.path,
                    avatarID
                );
            };
            break;

            case routeID.location.locationViewer.service: {
                route = this.getServiceRequestLocation();
            };
            break;

            // Editors
            case routeID.features.editSubGroup:
                {
                    route = putBrackets(
                        routeList.groupAdministrative.path,
                        params[0],
                        routeList.groupAdministrative.children.edit
                    );
                }
                break;

            case routeID.groups.members:
                route = RoutingBuilder.groupMembers(params[0]);
                break;
            case routeID.groups.subgroups:
                route = RoutingBuilder.groupSubgroups(params[0]);
                break;
            case routeID.groups.home:
                route = RoutingBuilder.groupHome(params[0]);
                break;
            case routeID.groups.groupProfile.path:
                route = RoutingBuilder.profileGroup();
                break;
            case routeID.groups.chat:
                route = RoutingBuilder.groupChat(params[0]);
                break;
            case routeID.player.profile:
                route = RoutingBuilder.playerProfile(params[0]);
                break;
            // TOREMOVE: this is temporary and should be removed
            case routeID.player.notifications:
                route = RoutingBuilder.notificationView();
                break;

            case routeID.groups.faceCompany:
                route = RoutingBuilder.groupFaceCompany(params[0]);
                break;

            case routeID.dashboard:
                route = RoutingBuilder.dashboardPage(params);
                break;
            case routeID.landing:
                route = RoutingBuilder.landingPage();
                break;
            case routeID.integrations.salesforce:
                route = RoutingBuilder.salesforce(params[0], params[1]);
                break;

            case routeID.menu:
                route = this.menuDashboard(...params)
                break
            default:
                break;
        }

        return route!;
    }


    public static menuDashboard(...params: string[]): string {
        return putBrackets(routeID.menu, ...params)
    }
    public static salesforce(idTopic: string, name: string): string {
        return putBrackets();
    }

    public static landingPage() {
        return '/';
    }

    public static dashboardPage(pages: string[]) {
        if (isInvalidArray(pages)) {
            pages = [routeList.dashboard.children.services];
        }
        return putBrackets(routeList.dashboard.path, ...pages);
    }

    public static groupFaceCompany(idGroup: TGlobalUID): string {
        return putBrackets(
            this.group(idGroup),
            routeList.groups.children.idGroup.children.companyFace
        );
    }

    public static getSpecificFeatureRoute(featureId: string, idGroup: string, createFeature: string): string {
        const mustCreate: boolean = createFeature === 'true' || createFeature == null;
        return  `/${
            routeList.groups.path
        }/${
            idGroup
        }/${
            routeList.groups.children.idGroup.children.features
        }${
            featureId
        }/${
            mustCreate ? true : false
        }`;
    }

    private static notificationView(): string {
        // routeList.home.path
        return putBrackets(
            routeList.home.path,
            `notifications/view`);
    }

    // '/groups/SocialNetworkRoot/home'
    public static groupSocialNetworkRoot(): string {
        return putBrackets(
            routeList.home.path,
            routeList.groups.path,
            constant.entity.rootGroups.root,
            routeList.groups.children.idGroup.children.home
        );
    }

    // this rout is for AVATAR PROFILE
    private static groupProfileOld(): string {
        return putBrackets(
            routeList.home.path,
            routeList.groups.path,
            constant.entity.rootGroups.root,
        );
    }

    // This is for GROUP PROFILE
    private static profileGroup(): string {
        return putBrackets(
            routeList.home.path,
            routeList.groups.path,
            constant.entity.rootGroups.root,
        );
    }
    // '/home'
    private static getHome(): string {
        return '/';
        return '/' + routeList.home.path
    }

    // '/groups/:idGroup'
    private static group(idGroup: TGlobalUID): string {
        return putBrackets(
            this.getHome(),
            routeList.groups.path,
            idGroup);
    }

    // '/home/groups/:idGroup/locationviewer/avatars/:idAvatar'
    // ${
    //   this.group(idGroup)
    // }/
    private static getAvatarLocation(idAvatar: TGlobalUID): string {
        return `/${
            this.getHome()
        }/${
            routeID.location.locationViewer.id
        }/${
            routeID.location.locationViewer.avatars
        }/${
            idAvatar
        }`
    }

    private static getServiceRequestLocation(): string {
        const route = putBrackets(
            routeList.maps.path,
            routeList.maps.children.locationViewer.path,
            routeID.location.locationViewer.service,
        );

        return route;
    }

    // '/home/player/avatars/:idAvatar'
    private static playerProfile(idAvatar: TGlobalUID): string {
        return putBrackets(
            routeList.player.path,
            routeList.player.children.avatars.path,
            idAvatar,
        );
    }

    // '/groups/:idGroup/groupProfile'
    private static groupProfile(idGroup: TGlobalUID): string {
        return putBrackets(
            this.group(idGroup),
            routeList.groups.children.idGroup.children.groupProfile.path
        );
    }

    // '/groups/:idGroup/chat'
    public static groupChat(idGroup: TGlobalUID): string {
        return putBrackets(
            routeList.groups.path,
            routeList.groups.children.idGroup.children.chat,
            idGroup
        );
    }

    // '/groups/:idGroup/members'
    private static groupMembers(idGroup: TGlobalUID): string {
        return putBrackets(
            this.group(idGroup),
            routeList.groups.children.idGroup.children.members
        );
    }

    // '/groups/:idGroup/subgroups'
    private static groupSubgroups(idGroup: TGlobalUID): any {
        return putBrackets(
            this.group(idGroup),
            routeList.groups.children.idGroup.children.subgroups
        );
    }

    // '/groups/:idGroup/home'
    public static groupHome(idGroup: TGlobalUID): string {
        return putBrackets(
            routeList.groups.path,
            idGroup,
            routeList.groups.children.idGroup.children.home
        );
    }

    private static getFeatureNonCreateGroup(
        idGroup: TGlobalUID,
        featureName: TGlobalUID,
        create: boolean
    ): string {
        return putBrackets(
            this.group(idGroup),
            routeList.groups.children.idGroup.children.featureNonCreateGroup,
            featureName,
            create ? "true" : "false"
        );
    }

    // /groups/:idGroup/create/survey
    private static viewSurvey(idGroup: TGlobalUID): string {
        return putBrackets(
            this.group(idGroup),
            routeList.groups.children.idGroup.children.view.children.survey
        );
    }
}


/**
     * @param route 
     * @returns 
     * @example
     * const navigator: {
     *     (params: { idNS: string }): `/dashboard/colmeia/contracts/${string}`;
     *     route: "/dashboard/colmeia/contracts/:idNS";
     * } = RouteCreator(RouteCreator.routes.dashboard.colmeia.contracts.edit)
     */
 export function RouteCreator<T extends string[]>(route: RouteCreator.State<T>) {
    const fn = RouteCreator.navigatorOf(RouteCreator.toString(route));
    return fn;
}

export namespace RouteCreator {
    
    let service: RoutingService;

    export function setService(input: RoutingService) {
        service = input;
    }
    export const routes = getReusableTypedProperty<AddState<typeof routeList>>(properties => () => RoutingBuilder.createRoute(properties));


    // Definitions bellow

    export function toString<T extends string[]>(route: State<T>): Join<T, '/', '/'>;
    export function toString(input: State<string[]>): string {
        const routes = getPropertiesFromReusableProxy(input);

        const routeWithParam = RoutingBuilder.createRoute([...routes, $pickRoutePropertyParam]);
        const route = RoutingBuilder.createRoute(routes);

        if (routeWithParam !== route) return routeWithParam;
        return route;
    }

    export function navigatorOf<Route extends string>(route: Route) {
        type InputParams = NavigatorOf<Route>;
        type Params = [{}] extends [InputParams] ? void : InputParams;
        
        navigator.route = route;
        navigator.getParams = getParams;
        return navigator;

        function getParams(): InputParams {
            return service.getAllParamsFromSnapshot(service.activatedRoute.snapshot)
        }
        function navigator(params: Params): Promise<boolean> {
            return service.goToPath(build(params));
        }

        function build(params: Params): TemplateOfRoute<Route>
        function build(input: Params): string {
            const params = input as InputParams;
            let output: string = route;
            if (params) {
                for (const paramName in params) output = output.replace(`:${paramName}`, Reflect.get(params, paramName))
            }
            return output;
        }
    }

    // 
    declare class Custom<T> {
        static readonly type: unique symbol;
        [Custom.type]: T;
    }
    export type State<T> = Custom<T>;

    // 


    
    type Children = { [key in string]: Route }
    type Route = string | Children | IBaseRouteListPathPattern;
    // 

    /**
     * Convert routeList into a summarized object
     * @example
     * type Input = { a: { path: 'a'; children: { b: { path: 'b', children: { c: 'c' } } } } }
     * type Output = {
     *     a: State<["a"]> & {
     *         b: State<["a", "b"]> & {
     *             c: State<["a", "b", "c"]> & "c";
     *         };
     *     };
     * }
     */
    type AddState<T, Current extends unknown[] = []> = {} & {
        [key in keyof T]: AddStateItem<T[key], [...Current]>
    };
    type AddStateItem<T, Current extends unknown[]> =
        (T extends string ? State<[...Current, T]> & T : T extends IBaseRouteListPathPattern ? [[...Current, ...(T['path'] extends '' ? [] : [T['path']]), ...(T['param'] extends string ? [`${T['param']}`] : [])]] extends [infer $Current] ? State<$Current> & AddState<T['children'], Extract<$Current, string[]>> : never  : T extends Children ? AddState<T, Current> : never)
    ;
    
    /**
     * Make a template of a route with parameter
     * @example 
     * type Input = '/dashboard/colmeia/contracts/:idNS'
     * type Output = TemplateOfRoute<Input>
     * type Output = `/dashboard/colmeia/contracts/:${string}`
     */
    type TemplateOfRoute<Route extends string, Items extends string[] = RoutesOf<Route>> = Join<{ [key in keyof Items]: Items[key] extends ParamOf ? string : Items[key] }, '/'>
    
    /**
     * Split route
     * @example 
     * type Input = '/dashboard/colmeia/contracts/:idNS'
     * type Output = ["", "dashboard", "colmeia", "contracts", ":idNS"]
     */
    type RoutesOf<Route extends string> = Split<Route, '/'>;

    /**
     * Turn route into an object
     * @example 
     * type Input = '/dashboard/colmeia/contracts/:idNS'
     * type Output = NavigatorOf<Input>
     * type Output = { idNS: string; }
     */
    type NavigatorOf<Route extends string, Keys extends string = ExtractParam<$ValueOf<RoutesOf<Route>>>> = { [key in Keys]: string };


    /**
     * Get params from values
     * @example 
     * type Input = "" | "dashboard" | ":idNS" | "colmeia" | "contracts"
     * type Output = ExtractParam<Input>
     * type Output = "idNS"
     */
    type ExtractParam<T> = T extends ParamOf<infer Param> ? Param : never;

    type ParamOf<Param extends string = string> = `:${Param}`;

    
    /**
     * Turn string[] into string
     * @example
     * type Input = ['a', 'b', 'b']
     * type Output = Join<Input, '/', 'PREFIX: '>
     * type Output = "PREFIX: a/b/b"
     */
    type Join<T extends string[], Delimiter extends string = '', Prefix extends string = '', Current extends string = '', IsFirst extends boolean = true> = T extends [infer Item, ...infer Items] ? Join<Extract<Items, string[]>, Delimiter, Prefix, `${Current}${IsFirst extends true ? Prefix : Delimiter}${Extract<Item, string>}`, false> : Current;
}


