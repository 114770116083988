import { isInvalid } from "@colmeia/core/src/tools/utility";
import {GroupSelectorShortcutHandler} from "../handlers/group-selector-shortcut.handler";

export type GroupSelectorShortcutHandlerList = Array<GroupSelectorShortcutHandler>

export function compareGroupsLastInteraction(group1: GroupSelectorShortcutHandler, group2: GroupSelectorShortcutHandler) {
    return group2.getLastInteractionTimeStamp() - group1.getLastInteractionTimeStamp();
}

export function compareGroupsNamesWithoutTimeStamp(group1: GroupSelectorShortcutHandler, group2: GroupSelectorShortcutHandler) {
    if (group1.getLastInteractionTimeStamp() > 0 || group2.getLastInteractionTimeStamp() > 0){
        return 0;
    }

    const group1Name = group1.getGroupName();
    const group2Name = group2.getGroupName();

    if (isInvalid(group1Name)) {
        if (isInvalid(group2Name)) {
            return 0;
        } else {
            return 1;
        }
    } else if (isInvalid(group2Name)) {
        return -1;
    }

    if (group1.getGroupName().toLocaleLowerCase() < group2.getGroupName().toLocaleLowerCase()){
        return -1;
    }
    if (group1.getGroupName().toLocaleLowerCase() > group2.getGroupName().toLocaleLowerCase()){
        return 1;
    }
}

export function sortGroupShortcutHandlerList(groupList: GroupSelectorShortcutHandlerList): GroupSelectorShortcutHandlerList {
    const sorted = Array.from(groupList);
    sorted.sort(compareGroupsLastInteraction);
    sorted.sort(compareGroupsNamesWithoutTimeStamp);
    return sorted;
}
