<section class="container nice-scroll">
  <div class="loading-overlay" *ngIf="dataLoading">
    <mat-spinner diameter="18"></mat-spinner>
  </div>

  <ng-container *ngIf="generalFormFieldViewerHandlers">
    <div class="field-container" *ngFor="let handler of generalFormFieldViewerHandlers">
      <app-general-form-field-viewer
        [handler]="handler"
        [value]="propValues[handler.getSchemaProperty().idProperty]"
      ></app-general-form-field-viewer>
    </div>
  </ng-container>
  
</section>
<div class="controls" *ngIf="!hideActions">
  <button
    mat-flat-button
    color="primary"
    (click)="saveAnswer()"
    [loading]="loading || internalLoading"
    [disabled]="loading || internalLoading"
    *ngIf="viewType !== viewTypeEnum.view && viewTypeEnum.callback !== viewType"
  >
    {{ getButtonText() }}
  </button>
</div>
