import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../all-serializables"

const config = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.Integration,
    fields: {
        createIntegration: 1, // 'SNManagement/integration' 20
        selectForm: 2, // 'SNManagement/integration' 28
        selecteCSVFile: 3, // 'SNManagement/integration' 29
        fileName: 4, // 'SNManagement/integration' 30
        columns: 5, // 'SNManagement/integration' 31
        save: 6, // 'SNManagement/integration' 32
        saveSuccess: 7, // 'SNManagement/integration' 37
        saveError: 8, // 'SNManagement/integration' 38
        noForms: 9, // serializableId: 'GeneralForm' 27
        errorLoadingSchemas: 10, // serializableId: 'GeneralForm' 28

        integrations: 11, // 'SNManagement/integration' 19
        fileSize: 14, // 'SNManagement/integration2' 19
        linesWithError: 16, // 'SNManagement/integration' 23
        creationDate: 17, // 'SNManagement/integration' 24
        details: 18, // 'SNManagement/integration' 25
        loadIntegrationsError: 19,// 'SNManagement/integration' 26
        noIntegrationsToShow: 20,// 'SNManagement/integration' 27

        availableHeader: 21, // Disponível
        autoAssociate: 22, // Associação automática
        autoAssociateByOrder: 23, // Por ordem
        autoAssociateByName: 24, // Por nome
        autoAssociateManually: 25, // Manual
        linesCount: 26, // Quantidade de registros no banco
        warning: 27, // Atenção!
        databaseAlreadyBeingUsed: 28, // Esse arquivo já está sendo usado no banco de dados
        databaseContent: 29, // conteudo do banco de dados
        thereAreEmptyRows: 30, // Existem <strong>{{ emptyFieldsQuantity }}</strong> registros vazios neste banco de dados
        encodingWarning: 31, // Possível problema na codificação do arquivo
        encodingWarningFull: 32 // Nosso sistema identificou que possivelmente seu arquivo não está codificado em UTF-8, e aceitamos somente esta codificação. Por favor verifique antes de salvar.
    },
});
export const integrationScreenOptions = config.screenOptions;
export const integrationTranslations = config.translations;