
import { EAllSerializable, getTranslationsFields } from "../all-serializables";
import { typedScreenOptions } from "../../../tools/utility";

// 
const sendCampaignScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.SendCampaign,
    fields: {
        insertAName: 1, // Insira um nome
        cantDispatchCampaign: 3, // Não foi possível disparar a campanha
        reSchedule: 4, // Re-agendar
        ifAlreadyCommActive: 5, // Caso haja uma conversação ativa
        dontSendIfTemplateSent: 6, // Se template enviado, não envie a mensagem
    },
} as const);

export const sendCampaignTranslations = getTranslationsFields(sendCampaignScreenOptions);
