import {EInsideNSObject, ENonSerializableObjectType} from "./non-serializable-id-interfaces";
import { EAllSerializable, getTranslationsFields } from "../const-text/all-serializables";
import { genericTypedSuggestions } from "../../tools/type-utils";
import { ITranslationConfig } from "../translation/translation-engine";
import { typedScreenOptions } from "../../tools/utility";


export const friendlyInsideNSNameIDSerializable: string = 'nonSerializableInsideFriendlyName';

export const friendlyInsideNSNameFieldsMap: {[key in EInsideNSObject]: number} = {
    [EInsideNSObject.connRoute]: 1,
    [EInsideNSObject.fieldForm]: 2,
    [EInsideNSObject.campaingAction]: 3,
    [EInsideNSObject.intent]: 4,
    [EInsideNSObject.connDomain]: 5,
    [EInsideNSObject.connAuth]: 6
};






// @gabriel abrir o activeCall em outro serializable