import { Component, Input, ViewChild } from '@angular/core';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { NgForm } from '@angular/forms';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
    selector: 'bot-transaction-basic-data',
    templateUrl: './bot-transaction-basic-data.component.html',
    styleUrls: ['./bot-transaction-basic-data.component.scss']
})
export class BotTransactionBasicDataComponent extends RootComponent<
    'name' |
    'availableInfoText' |
    'missingText' |
    'doYouConfirm' |
    'fieldRequired' |
    'basicDataTitle' |
    'exitCommand' |
    'greetingText' |
    'confirmData' 
>  {

    @Input() transaction: ITransactionServer;

    @ViewChild(NgForm) form: NgForm;

    constructor(

    ) { 
        super({
            name: gTranslations.common.name,
            availableInfoText: gTranslations.bot.availableInfoText,
            missingText: gTranslations.bot.missingText,
            doYouConfirm: gTranslations.bot.doYouConfirm,
            fieldRequired: gTranslations.common.fieldRequired,
            basicDataTitle: gTranslations.bot.basicDataTitle,
            exitCommand: gTranslations.bot.exitCommand,
            greetingText: gTranslations.bot.greetingText,
            confirmData: gTranslations.bot.confirmData,
        });
    }

    isInvalid(): boolean {
        this.form.onSubmit(null);
        return this.form.invalid;
    }

}
