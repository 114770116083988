import { IBasicUniversalInfo } from "../comm-interfaces/aux-interfaces";
import { TGlobalUID, TArrayID } from "../core-constants/types";

// IBasicComponentRender

export enum EBasicRenderInfoType  {
    serializable = 1, small = 2, nonSerializable = 3
}

export interface IBasicRenderInfo {
    getBasicInfoType(): EBasicRenderInfoType;
    getName(): string;
    getIdMedia(): TGlobalUID;
    getPrimaryID(): TGlobalUID;
    getObjectTypeID(): TGlobalUID;
    is(...idArray: TArrayID): boolean;
    isNot(...idArray: TArrayID): boolean 
}

export type TSmallSerializableArray = Array<SmallSerializable>;

export class SmallSerializable implements IBasicRenderInfo {
    
    private basicInfo: IBasicUniversalInfo;
    
    constructor(basic: IBasicUniversalInfo) {
        this.basicInfo = basic;
    };
    
    getName(): string { return this.basicInfo.name; }
    getIdMedia(): TGlobalUID { return this.basicInfo.idMedia; }
    getPrimaryID(): TGlobalUID { return this.basicInfo.primaryID; }
    getInteractionTypeID(): TGlobalUID { return this.basicInfo.idInteractionType; }
    getObjectTypeID(): TGlobalUID { return this.basicInfo.idObjectType; }

    getBasicInfoType():EBasicRenderInfoType {return EBasicRenderInfoType.small;} 
    

    public isNot(...idArray: Array<TGlobalUID>): boolean {
        return ! this.is(...idArray);
    };

    public is(...idArray: Array<TGlobalUID>): boolean {
        for (const primaryID of idArray) {
            if (primaryID && primaryID === this.getPrimaryID()) {
                return true;
            };
        };
        return false;
    };

}