export enum EDocCanonicalCommonProps {
    fullName = "fullName",
    idDocument = "idDocument",
    cpf = "cpf",
    birthLocation = "birthLocation",
    expirationDate = "expirationDate",
    observation = "observation",
    filiation = "filiation",
    fatherName = "fatherName",
    motherName = "motherName",
    issuingAuthority = "issuingAuthority",
    birthDate = "birthDate",
    emissionDate = "emissionDate",
    signatureLocation = "signatureLocation",
}


export enum ECNHCanonical {
  docIdentidadeNumber = "docIdentidadeNumber",
  category = "category",
  firstLicenseDate = "firstLicenseDate",
  nationality = "nationality",
}

export enum ERgCanonical {
    socialName = "socialName",
    originDoc = "originDoc",
    voterIdCard = "voterIdCard",
    socialSecurityNumber = "socialSecurityNumber",
    militaryCertificateNumber = "militaryCertificateNumber",
    cnhNumber = "cnhNumber",
    ctpsNumber = "ctpsNumber",
    nationalPublicHealthCardNumber = "nationalPublicHealthCardNumber",
    gender = "gender",
    rhFactor = "rhFactor",
    registroCivil = "registroCivil",
}

export type TDocCanonical =
  | EDocCanonicalCommonProps
  | ECNHCanonical
  | ERgCanonical;

export const EDocCanonical = {
  ...EDocCanonicalCommonProps,
  ...ECNHCanonical,
  ...ERgCanonical,
};

// export type TFieldHandlers = (blocks: Object, acc: Object) => Object;

const CNHFields = {...EDocCanonicalCommonProps, ...ECNHCanonical};

export type ECNHFields = typeof CNHFields;

// export interface IMultiValueChild {
//     [childFieldName: string]: string;
//     joinedValues: Array<string>;
// }

interface IMultivalueChildExtractions {
    [fieldName: string]: string;
}

export interface IMultiValueExtraction extends IMultivalueChildExtractions {
    joinedValues: string;
}

export function isMultiValueExtration(docInfoValue: any): docInfoValue is IMultiValueExtraction {
    if (typeof docInfoValue === 'object') {
        return "joinedValues" in docInfoValue;
    }

    return false;
}

export type TGenericDocFields = {
    [fieldName in string]: string
};

export type TSanityCheckSignature = (value: string) => boolean;
export type TValidFieldValues = string | number | Date;
export type TFieldExtractorSignature = (value: string) => TValidFieldValues;


// Base interface for the configuration of the extractions
export interface IBaseExtractionConfig {
    fieldName: string;
    isMultiValued?: boolean;
    shouldDelete?: boolean;
    fieldSearchMatchString?: Array<string>;
    children?: IStrategyConfigArray;
}

// Configuração final da configuração que o extrator de documentos vai receber
export interface IStrategyConfig extends IBaseExtractionConfig {

    additionalCheck?: (x: string) => boolean;
    fieldExtractor?: (x: string) => string;
}

// Interface de configuração de extratores para documentos personalizados
export interface ICustomDocumentConfig extends IBaseExtractionConfig {

    // strings que seram criadas pelo usuário como um snippet de código
    additionalCheck?: string;
    fieldExtractor?: string;
}


export enum EDocTypes {
    RG = "RG",
    CNH = "CNH",
    CUSTOM = "CUSTOM"
}

export enum EDOCSegment {
  front = "front",
  back = "back",
}

export type IStrategyConfigArray = Array<IStrategyConfig>;
export type TCustomConfigArray = Array<ICustomDocumentConfig>;
