import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { IdMedia } from '@colmeia/core/src/core-constants/types';
import { jobTypeTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/job';
import { EJOBType, IJobDataExtractionLineToLineServer, IJobServer, TIJobServerArray } from '@colmeia/core/src/shared-business-rules/jobs/jobs-model';
import { ENotificationPayload } from '@colmeia/core/src/shared-business-rules/new-notifications/new-notification-model';
import { NSSharedService } from '@colmeia/core/src/shared-business-rules/shared-services/services/ns.shared.service';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { DatetimeConversions } from 'app/services/datetime-conversions';
import { INotificationPayloadClient } from 'app/services/new-notifications-client.model';
import { NewNotificationsService, TNotificationIdMap } from 'app/services/new-notifications.service';
import { RoutingService } from 'app/services/routing.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { UserSettingsService } from 'app/services/user-settings.service';
import { Subscription } from 'rxjs';
import { jobTypeToRoutMap } from '../dashboard/jobs-page/jobs/jobs.component';
import { OVERLAY_REF_DATA } from '../navigation/main-header/new-main-header.component';

@Component({
    selector: 'app-notification-dialog-box',
    templateUrl: './notification-dialog-box.component.html',
    styleUrls: ['./notification-dialog-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDialogBoxComponent implements OnInit, OnDestroy {
    public onlyUnread: boolean = false;
    private notificationSubscription: Subscription;
    notificationsIdMap: TNotificationIdMap;
    nss: TIJobServerArray;

    dateTimeConversor: DatetimeConversions;

    constructor(
        private notificationSvc: NewNotificationsService,
        private cdr: ChangeDetectorRef,
        private routingSvc: RoutingService,
        private userSettings: UserSettingsService,
        private snackSvc: SnackMessageService,
        @Inject(OVERLAY_REF_DATA) private dialogRef: OverlayRef | undefined
    ) { }

    ngOnInit() {
        this.initDateTimeConversor();

        this.notificationSubscription = this.notificationSvc.listenNewNotificationsToShowOnScreen().subscribe(() => {
            this.cdr.markForCheck();
            this.notificationSvc.clearUnreadCount();

            this.notificationsIdMap = this.getNotifications();
            this.getNSsFromNotifications(this.notificationsIdMap);
        });
        this.notificationSvc.clearUnreadCount();

        this.notificationsIdMap = this.getNotifications();
        this.getNSsFromNotifications(this.notificationsIdMap);
        this.cdr.markForCheck();

    }

    ngOnDestroy() {
        this.notificationSubscription.unsubscribe();
    }

    private initDateTimeConversor() {
        this.dateTimeConversor = new DatetimeConversions(this.userSettings);
    }

    public getNotifications(): TNotificationIdMap {
        const notificationsIdMap = this.notificationSvc.getNotificationsInMemoryGrouped();
        const unreadNotifications = this.notificationSvc.getUnreadNotificationsInMemory();

        if (!this.onlyUnread) {
            return notificationsIdMap;
        }

        const notificationsUnreadIdMap: TNotificationIdMap = {};

        for (const w in notificationsIdMap) {
            notificationsUnreadIdMap[w] = notificationsIdMap[w].filter(ntf => unreadNotifications.includes(ntf));
        }
        return notificationsUnreadIdMap;
    }

    private async getNSsFromNotifications(notificationsIdMap: TNotificationIdMap) {
        this.nss = await NSSharedService.getNSs(Object.keys(notificationsIdMap)) as TIJobServerArray
        this.cdr.markForCheck();
    }

    public getJobTypeGroupName(idNs: string) {
        const ns = this.nss?.find(ns => ns.idNS === idNs);
        if (ns) return Serializable.getTranslation(jobTypeTranslations[ns.jobType]);
    }

    public isJobNotification(notificationType: ENotificationPayload): boolean {
        return notificationType === ENotificationPayload.job;
    }

    public navigateToPage(idNs: string, notification: INotificationPayloadClient) {
        let ns: IJobServer | undefined;
        if (idNs) ns = this.nss?.find(ns => ns.idNS === idNs);

        switch (notification.type) {

            case ENotificationPayload.formSolicitationComplete:
            case ENotificationPayload.adminSolicitationTaskApproval:
                this.routingSvc.navigateToId(
                    routeID.dashboard,
                    routeList.dashboard.children.security.path,
                    routeList.dashboard.children.security.children.tasks.path,
                );
                this.dialogRef?.dispose();
                break;

            case ENotificationPayload.job:
                if (ns && jobTypeToRoutMap[ns.jobType]) {
                    this.routingSvc.navigateToId(
                        routeID.dashboard,
                        routeList.dashboard.children.jobs.path,
                        jobTypeToRoutMap[ns.jobType],
                    );
                }
                this.dialogRef?.dispose();
                break;


        }
    }

    public canShowRedirectBtn(notificationType: ENotificationPayload): boolean {
        switch (notificationType) {
            case ENotificationPayload.job:
            case ENotificationPayload.adminSolicitationTaskApproval:
            case ENotificationPayload.formSolicitationComplete:
                return true;

            default:
                return false;
        }
    }

    private getNsJobServer(idNs: string): IJobServer | undefined {
        if (!idNs) {
            console.log({
                getNsJobServerLog: 'idNs didnt come'
            }); //<< Log left
            return;
        }
        return this.nss?.find(ns => ns.idNS === idNs);
    }

    private getDateStringFormatFromJobNs(idNs: string): string | undefined {
        // return this.getFormatedHourDateString(this.notificationSvc.getWarehouseDataExtractorArrivalTime());

        const serverJob: IJobServer | undefined = this.getNsJobServer(idNs);
        if (!serverJob) {
            console.log({
                getDateStringFormat: 'job ns not found'
            }); //<< Log left
            return;
        }

        try {
            const nsDateTime = new Date(serverJob.clockTick);

            if (isNaN(nsDateTime.getTime())) {
                console.log('new date instance creation has failed');
                return;
            }

            return this.getFormatedHourDateString(nsDateTime);
        } catch (err) {
            console.log({
                errorAtGetDateStringFormat: err
            }); //<< log lfet
            return;
        }
    }

    private getFormatedHourDateString(date: Date | undefined): string | undefined {
        if (!date) {
            return;
        }

        const dateTime = date.getTime();
        if (isNaN(dateTime)) {
            console.log('invalid date');
            return;
        }

        return this.dateTimeConversor.timeToDateTimeString(dateTime);
    }

    public getNotificationSpanMessage(notificationType: ENotificationPayload, idNs: string) {
        if (!!this.isEJobTypeDataExtractionLineToLine(notificationType, idNs) && !!this.getDateStringFormatFromJobNs(idNs)) {
            return `Job: ${this.getJobTypeGroupName(idNs)} (${this.getDateStringFormatFromJobNs(idNs)})`;
        }
        return "Job: " + this.getJobTypeGroupName(idNs);
    }

    public getBbcodeViewerNotificationMessage(idNs: string, notificationValue: INotificationPayloadClient) {
        if (!idNs) {
            console.log({
                getBbcodeViewerNotificationMessage: 'notification key (id) didnt come'
            }); //<< Log left
            return notificationValue?.message || '';
        }

        if (this.isEJobTypeDataExtractionLineToLine(notificationValue.type, idNs)) {
            const ns: IJobServer | undefined = this.getNsJobServer(idNs);
            if (!ns) {
                console.log({
                    getBbcodeViewerNotificationMessageNsLog: 'ns is undefined'
                }); //<< Log left
                return notificationValue?.message || '';
            }

            const lineCount = (ns as IJobDataExtractionLineToLineServer)?.lineCount;
            if (!!lineCount) {
                return `${notificationValue.message} \nNúmero de linhas: ${lineCount}`;
            }
        }
        return notificationValue?.message || '';
    }

    private isEJobTypeDataExtractionLineToLine(notificationType: ENotificationPayload, idNs: string): boolean {
        const ns: IJobServer | undefined = this.getNsJobServer(idNs);
        if (!ns) {
            return false;
        }

        return notificationType === ENotificationPayload.job && EJOBType.dataExtractionLineToLine === ns.jobType;
    }

    public canShowDownloadBtn(notificationType: ENotificationPayload, idNs: string, notification?: INotificationPayloadClient): boolean {
        if (this.isEJobTypeDataExtractionLineToLine(notificationType, idNs)) { //<< only use case so far
            return true;
        }
        return false;
    }

    public downloadCSV(idNs: string) {
        let ns: IJobServer | undefined;
        if (idNs) ns = this.nss?.find(ns => ns.idNS === idNs);
        if (ns) {
            const csvUrl = (ns as IJobDataExtractionLineToLineServer).csvURL;
            window.open(csvUrl);
        } else {
            console.error('CSV URL not found.');
        }
    }

    public handleDownloadBtn(idNs: string) {
        let ns: IJobServer | undefined;
        if (idNs) ns = this.nss?.find(ns => ns.idNS === idNs);
        console.log({ ns })
        const dataExtractor = ns as IJobDataExtractionLineToLineServer;


    }

    public markAllAsRead() {
        this.notificationSvc.resetCounter();

        this.cdr.markForCheck();
    }

    public clearAll() {
        this.notificationSvc.setNotificationsInMemoryGrouped({});
        this.cdr.markForCheck();
    }

    get notificationsLength(): number {
        return this.notificationSvc.getNotificationsInMemoryAsArray().length
        // return this.notificationSvc.getNotificationCount();
    }

    public hasUnread(): boolean {
        const unreadNotifications = this.notificationSvc.getUnreadNotificationsInMemory();
        return !!unreadNotifications.length;
    }

    public toggleReadState(notification: INotificationPayloadClient) {
        if (this.isNotificationUnread(notification)) {
            this.notificationSvc.removeFromUnread(notification);
        } else {
            this.notificationSvc.addToUnread(notification);
        }

        this.cdr.markForCheck();
    }
    public isNotificationUnread(notification: INotificationPayloadClient): boolean {
        return this.notificationSvc.getUnreadNotificationsInMemory().includes(notification);
    }
}


export function getIdMediaFromSignedURL(targetURL: string): IdMedia {
    const url = new URL(targetURL);
    const [, bucketName, idMedia] = url.pathname.split('/');
    return decodeURIComponent(idMedia);
}
