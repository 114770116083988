export class SetUtils<T> {
    private set_: Set<T>;

    constructor(set_: T[]) {
        this.set_ = new Set(set_);
    }

    static new<T>(set_: T[]) {
        return new SetUtils(set_)
    }

    add(item: T) {
        this.set_.add(item);
    }

    addBatch(otherSet: T[]) {
        otherSet.forEach(otherItem => {
            this.set_.add(otherItem)
        })
    }

    contains(element: T): boolean {
        return this.set_.has(element)
    }

    union(otherSet: T[]): T[] {
        return [...new Set([...this.set_, ...otherSet])];
    }

    intersection(otherSet: T[]): T[] {
        return [...new Set([...this.set_].filter(x => otherSet.includes(x)))];
    }

    difference(otherSet: T[]): T[] {
        return [...new Set([...this.set_].filter(x => !otherSet.includes(x)))];
    }

    symmetricDifference(otherSet: T[]): T[] {
        return [...new Set([...this.set_].filter(x => !otherSet.includes(x)).concat([...otherSet].filter(x => !this.set_.has(x))))];
    }

    isSubset(otherSet: T[]): boolean {
        return [...this.set_].every(x => otherSet.includes(x));
    }

    isSuperset(otherSet: T[]): boolean {
        return [...otherSet].every(x => this.set_.has(x));
    }

    getSize(): number {
        return this.set_.size
    }

    hasOneOrMoreElements(): boolean {
        return this.set_.size > 0
    }

    getAll(): T[] {
        return Array.from(this.set_)
    }
}