import { TGlobalUID } from '../business/constant';
import { IInteractionJSON, IThreadInfo } from '../comm-interfaces/interaction-interfaces';
import { EThreadType } from '../business/constant.enums';
import { isValidArray } from '../tools/utility';



export function setFeatureResponse(interaction: IInteractionJSON, idThreadAndFeature: TGlobalUID, idGeneralResponse: TGlobalUID, threadType: EThreadType, threadName: string = '') : void {
    if (! isValidArray(interaction.threadInfo)) {
        interaction.threadInfo = [];
    };

    const idx: number = interaction.threadInfo.findIndex((t) => {return t.threadType == threadType});
    const thread: IThreadInfo  = { 
        idThread: idThreadAndFeature, 
        threadType: threadType, 
        idGeneralResponse: idGeneralResponse,
        threadName: threadName,
    };
    if (idx == -1) {
        interaction.threadInfo.push(thread);
    } else {
        interaction[idx] = thread;
    };
}




