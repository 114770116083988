import { MS } from '@colmeia/core/src/time/time-utl';
import { T360MediaArray } from '../../core-constants/bot';
import { NamedString, TArrayID, TGlobalUID, TPostgresPrimaryKey } from '../../core-constants/types';
import { isInvalid, isValidRef } from "../../tools/utility";
import { CustomType } from '../../tools/utility-types';
import { ICustomEventsServer } from '../attendance-island/custom-events';
import { IBasicAsset, IExecutableAction, KBAssetType } from "../bot/bot-asset-model";
import { IContentBasicAsset } from "../bot/bot-content-model";
import { INextGenBotServer } from "../bot/bot-model";
import { ITaggable } from '../colmeia-tags/tags';
import { ITransactionServer } from "../knowledge-base/bot-transaction/bot-transaction";
import { EBatchSaveType, IBatchSaveToDeployNser, IBatchSaveToDeployNserBasicData } from '../production-deploy/prod-deploy-req-res';
import { IdDep } from './non-serializable-types';
import { NSOrId } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';


export type TENonSerializableObjectArray = Array<ENonSerializableObjectType>;
export type TNonSerializableHash = { [idNS: string]: INonSerializable };
export type TDpendencyObjectType = ENonSerializableObjectType | EInsideNSObject;

export enum ENonSerializableObjectType {

    backupPackage = 'backupPkg',
    backupSnapshot = 'backupSnapshot',

    patchHeader = 'patchProd', // JC
    patchItem = 'patchItem', // JC
    securityControl = 'securityControl',
    knowledgeBase = 'kbase', // GAB
    serviceIsland = 'srvIsland', // GAB
    utterance = 'utter', // DAN
    connection = 'conn', // DAN
    connectionRoute = 'connRoute', // DAN
    deployedServices = 'depServ', //DAN
    colmeiaTags = 'colmTag', // FAB
    contentGenerator = 'botTrans', // JC OK
    attendentServicePack = 'attPack', // JC OK
    clientGeneralAnswer = 'clAns',  // JC OK
    bot = 'bot',  // JC OK
    campaing = 'camp',  // JC OK
    attendantAllowedAvatar = 'attIsland', // JC OK
    runCampaing = 'runCampaign',  // JC OK
    fileMetadata = 'fMeta', // GAB #OK
    canonical = 'canon', // GAB #NO DEPENDENCIES
    socialMediaConnection = 'socConnec', // GAB #NO DEPENDENCIES
    waba = 'whatsappBusinessAccount',
    formSchemma = 'fchemma', // GAB # PRONTO, FAVOR VERIFICAR
    deployTransformer = 'depTrans',
    featureDeployedGroup = 'groupDep',
    deployHeader = 'dePHeader',
    deployProdItem = 'dePItem',
    faceCompany = 'nsFaceC',
    presenceStatus = 'prStatus',
    callcenterAtt = 'ccAtt',
    NLPActivity = 'nlpAct', // registra a atividade de NLP
    activeCallBatch = 'activeCallBatch',
    activeCall1x1 = 'activeCall1x1',

    socialNetworkConfig = 'socialNetworkContract',
    socialNetworkUserSettings = 'snUserSettings',

    attendanceCalendar = 'attCalnd',
    realTimeFusion = 'namedAcc',
    customEvents = 'attendanceEvents',

    userApprovalProfile = 'usrAprvlPrfl',

    job = 'job',
    graphElement = 'bpmGrapM',
    userFunction = 'userFunction',
    colmeiaAPIModel = 'colmeiaAPIModel',

    crmHeaderCloseState = 'crmCloseState',  // ECloseState
    crmItemCloseState = 'crmItemCloseState',

    crmHeaderPhase = 'crmPhase', //EServiceManagementPhases
    crmItemPhase = 'crmItemPhase',

    crmHeaderSeverity = 'crmSeverity', //ESeverityLevel
    crmItemSeverity = 'crmItemSeverity',

    crmHeaderState = 'crmState', //ETicketState
    crmItemState = 'crmItemState',

    crmHeaderSupportLevel = 'crmHeaderSupportLevel',
    crmItemSupportLevel = 'crmItemSupportLevel',

    crmHeaderUrgencyHeaderLevel = 'crmHeaderUrgencyLevel',
    crmItemUrgencyLevel = 'crmItemUrgencyLevel',

    crmConfigPackage = 'crmConfigPackage', //Pacote

    crmServiceConfigRoot = 'crmGraphServiceConfigRoot',
    crmServiceConfigAgentEvent = 'crmGraphServiceConfigUserEvent',
    crmServiceConfigNotification = 'crmGraphServiceConfigNotification',
    crmServiceConfigProcessingNode = 'crmGraphServiceConfigProcessingNode',

    corporateSearchHeader = 'CSHeader',
    corporateSearchBody = 'CSBody',

    smartFlowRTFParamHeader = 'sf-RTFParamH',
    smartFlowRTFParamBody = 'sf-RTFParamB',

    channelTemplate = 'channelTemplate',

    configurationSet = 'configSet',

    // Objetos da Execução do CRM
    crmExecution = 'crmExec',

    bpmMarketingRoot = 'bpmMarketingRoot',
    bpmMarketingAction = 'bpmMarketingAction',
    bpmMarketingPause = 'bpmMarketingPause',
    bpmMarketingAcknowledge = 'bpmMarketingAcknowledge',
    bpmMarketingListener = 'bpmMktListener',
    bpmMarketingEnd = 'bpmMktEnd',
    catalog = 'catlg',
    metaBusinessPages = 'metaBusinessPages',


    /**
     * @description contrato de cliente da colmeia
     */
    billingContract = 'billingContrct',
    /**
     * @description item de contrato da colmeia
     */
    billingContractItem = 'prodctSKU',
    /**
     * @description produto colmeia do menu produtos(template da colmeia de item de contrato)
     */
    product = 'prodct',
    productModule = 'prodctModl',
    yourTasks = 'yourTasks',
    kbCorporateEntity = 'kbCorporateEntity',


    /**
     * colmeia invoice
     */
    billingClosing = 'billClose',
    /**
     * colmeia invoice item
     */
    billingItem = 'billItem',


    analyticsBiReportItem = 'analyticsBiReportItem',

    contentVector = 'contentVector',
    contentVectorChunk = 'contentVectorChunk',
    generativePrompt = 'generativePrompt',

    contactList = 'ctcList',
    contactListItem = 'ctcListItem',

    dataExtractionQuery = 'dataExtracQuery',
    wafSchema = 'wafSchema',
    waf = 'waf',

    attCopilot = 'attCopilot',

    transformer = 'trnsfmr',

    paymentAccount = 'paymentAccount',

    paymentGateway = 'paymentGateway'
};


export type IdChunk = IdDep<ENonSerializableObjectType.contentVectorChunk>;
export type IdVector = IdDep<ENonSerializableObjectType.contentVector>;
export type IdKB = IdDep<ENonSerializableObjectType.knowledgeBase>;
export type IdSMConnection = IdDep<ENonSerializableObjectType.socialMediaConnection>;
export type IdConnection = IdDep<ENonSerializableObjectType.connection>;
export type IdContentGenerator = IdDep<ENonSerializableObjectType.contentGenerator>;
export type IdPrompt = IdDep<ENonSerializableObjectType.generativePrompt>;
export type IdTemplate = IdDep<ENonSerializableObjectType.channelTemplate>;
export type IdSchema = IdDep<ENonSerializableObjectType.formSchemma>;
export type IdCanonical = IdDep<ENonSerializableObjectType.canonical>;
export type IdFunction = IdDep<ENonSerializableObjectType.userFunction>;
export type IdCampaign = IdDep<ENonSerializableObjectType.campaing>;
export type IdCampaignAction = NamedString<'IdCampaignAction'>;

export type IdOrVector = NSOrId<ENonSerializableObjectType.contentVector>;
export type IdOrChunk = NSOrId<ENonSerializableObjectType.contentVectorChunk>;
export type IdFile = IdDep<ENonSerializableObjectType.fileMetadata>;
export type IdDB = IdFile;
export type IdDatabase = IdFile;
export type IdTransformer = IdDep<ENonSerializableObjectType.transformer>;


export enum EInsideNSObject {
    connRoute = 'conn.route',
    connAuth = 'conn.auth',
    connDomain = 'conn.domain',
    fieldForm = 'fchemma.field',
    campaingAction = 'camp.action',
    intent = 'kbase.intent'
}

export enum ENserVisualizationType {
    archived = 'archived'
}

export type TNsId<NsType extends ENonSerializableObjectType> = CustomType<string, NsType>;


export interface IMapInsideDependencyTypes {
    [nsType: string]: TArrayID;
}

const insideDependencies: IMapInsideDependencyTypes = {
    [ENonSerializableObjectType.connection]: [EInsideNSObject.connRoute],
    [ENonSerializableObjectType.formSchemma]: [EInsideNSObject.fieldForm],
    [ENonSerializableObjectType.campaing]: [EInsideNSObject.campaingAction],
    [ENonSerializableObjectType.knowledgeBase]: [EInsideNSObject.intent]
}

export type TNonSerializableArray<T extends INonSerializable = INonSerializable> = Array<T>;

export interface INonSerializableMedia {
    medias?: T360MediaArray;
}


export interface INonSerializableHeader extends INonSerializableMedia, ITaggable {
    nName: string;
    nsType: ENonSerializableObjectType;
    genealogy?: TArrayID; // Genealogia de NonSerializable
    idParent?: string;
    position?: number;
    isVirtual?: boolean;
    visualizationType?: ENserVisualizationType;
    version?: string;
}

export interface NonSerializableHeader<NSType extends ENonSerializableObjectType> {
    nsType: NSType;
}
export interface ISearchTagsOwner {
    searchTags?: TArrayID;
}
export interface INonSerializable extends INonSerializableHeader, ISearchTagsOwner {
    idNS?: string;
    clockTick?: MS;
    lastTouch?: MS;
    //nsType: ENonSerializableObjectType;
    isRoot?: boolean;
    // searchTags?: TArrayID;
    searchTokens?: TArrayID;
    ident: INonSerializableIdentification;
}

export type TINonSerializableArray = Array<INonSerializable>;

export interface NonSerializable<NSType extends ENonSerializableObjectType> extends INonSerializable {
    idNS?: IdDep<NSType>;
    nsType: NSType;
}

// @ts-expect-error
export interface CustomNonSerializable<NS extends Partial<INonSerializable>> extends NS, INonSerializable {
}


export type TNSCache = Map<string, INonSerializable>;

export enum EMigrationOperationType {
    update = 'update',
    create = 'create',
    delete = 'delete'
}

export enum EEnvironmentType {
    local = "local",
    remote = "remote"
}

export interface INserMigrationOperation {
    operationType: EMigrationOperationType;
    envType?: EEnvironmentType;
}
export interface INSerContainer extends INserMigrationOperation {
    ns: INonSerializable,
}
export interface INSerDescription extends IBatchSaveToDeployNserBasicData, Partial<INserMigrationOperation> {
}

export interface INonSerializableBatchSaveDescriptor {
    type: EBatchSaveType,
    descriptors: IBatchSaveToDeployNser[],
    allowedTypes?: EBatchSaveType[]
}

export type TNSDependencyDescriptionArray = Array<INSDependencyDescription>;

export interface INSDependencyDescription {
    name: string;
    id: string;
    type: TDpendencyObjectType;
}

export interface INSChangesHistoryDescription {
    name: string;
    id: string;
}

export interface INonSerializableIdentification {
    idAvatar: TGlobalUID;
    idGroup: TGlobalUID;
    genealogy: TArrayID; // Genealogia de grupos
    target?: string; // Target - id do canal (ex celular do cliente)
}

export function isLocalEnvMigratableNser(envType: EEnvironmentType) {
    return envType === EEnvironmentType.local || envType == null
}
export function isRemoteEnvMigratableNser(envType: EEnvironmentType) {
    return envType === EEnvironmentType.remote
}
export function isMigrationOperationTypeDelete(operationType: EMigrationOperationType) {
    return operationType === EMigrationOperationType.delete
}

export function extractMediasInAsset(asset: IBasicAsset, idsArray: TArrayID): void {
    if (isInvalid(asset))
        return

    const auxCon: IExecutableAction = asset as IExecutableAction;
    if (isValidRef(auxCon.contentArray)) {
        for (const asset of auxCon.contentArray) {
            extractMediasInAsset(asset, idsArray);
        }
    }
    if (asset.type === KBAssetType.media) {
        const aux: IContentBasicAsset = asset as IContentBasicAsset;
        idsArray.push(aux.media.idMedia)
    }
}

export function extractAllMediasFromNSToDeploy(nser: INonSerializable): TArrayID {
    const result: TArrayID = [];

    result.push(...(nser.medias || []).map(media => media.idMedia));

    switch (nser.nsType) {
        case ENonSerializableObjectType.contentGenerator:
            const transatctionEl: ITransactionServer = nser as ITransactionServer;
            for (const evt of (transatctionEl.events || [])) {
                extractMediasInAsset(evt, result);
            }
            break;
        case ENonSerializableObjectType.customEvents:
            const islandAux: ICustomEventsServer = nser as ICustomEventsServer;
            for (const evt of islandAux.events) {
                extractMediasInAsset(evt, result);
            }
            break;
        case ENonSerializableObjectType.bot:
            const botAux: INextGenBotServer = nser as INextGenBotServer;
            for (const evt of (botAux.events || [])) {
                extractMediasInAsset(evt, result);
            }
            break;
    }

    return result;
}
export interface IProdiverDependency {
    idNSProvider: string;
    nsProdiverType?: TDpendencyObjectType;
}
export interface IDependency extends IProdiverDependency {
    idDep: string;
    idNS: string;
    clockTick: number;
    nsType: TDpendencyObjectType;
    nsProdiverType: TDpendencyObjectType;
    deleteClock: number;
    idAvatarDelete?: string;
    type: EDependencyEntryType;
};
export type TEDependencyEntryTypeArray = Array<EDependencyEntryType>;

export enum EDependencyEntryType {
    OnlyProviderConsumerNeeded = 'OnlyProviderConsumerNeeded',

    // Corporate Search
    ColmeiaAPIConnectionRoute = 'clmAPCnnctnRoute',
    UserFunctionToIdCanonicals = 'userFnToIdCanon',
    /* Engaged Form */
    /* Deployed Service*/
    DeployedServiceBot = 'dpSvcBot',
    DeployedServiceChannel = 'dpSvcChann',
    /* Attendant service pack*/
    AttPackageMedatadas = 'attPkgMeds',
    AttPackageClientMedatadas = 'attPkgClMeds',
    AttPackageFinalizationMedatadas = 'attPkgFinMeds',
    AttPackageTransferableIslands = 'attPkgTnsIsl',
    AttPackageCanonical = 'attPkgCanncl',
    /*Channels*/
    /*Service Island*/
    ServiceIslandServicePack = 'svIldSvpck',
    /* KB */
    KnowledgeBaseUtt = 'KbUtt',
    /* Campaign */
    CampaignBot = 'cpgnBot',
    /*Transaction*/
    TransactionKB = 'trnsKB',
    TransactionContextCanonical = 'trnsContxtCanncl',

    /*Engagement*/
    /*Feature Deploy*/
    /* Defaults */
    Genealogy = 'gen',
    Tag = 'tag',
    /* Connection Route */
    /* Bot */
    BotContextCanonical = 'btContxtCanncl',
    BotItemAsset = 'btItmAsst',

    /* File Metadata*/
    /* Metadata */
    /* Run Campaign*/

    /* Assets */
    /* Named Account*/

    // attenvent

    // graphElement
    graph = 'graph'

}
export type TProviderDependencyArray = Array<IProdiverDependency>;


export interface IControlNSSecurity {
    function: (ns: INonSerializable) => boolean;
}

export const notUsedInSecurity = {
    [ENonSerializableObjectType.bot]: {
        function: isRoot
    }
}


function isRoot(non: INonSerializable): boolean {
    return non.isRoot;

}
