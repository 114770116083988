import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import {BreadcrumbItem, BreadcrumbItemList, IBreadcrumbDescriptor} from "../../../model/breadcrumb.model";


@Component({
    selector: 'cm-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
    @Input() descriptor: IBreadcrumbDescriptor;

    constructor() { }

    getItems(): BreadcrumbItemList {
        return isValidRef(this.descriptor) ? this.descriptor.items : [];
    }

    breadCrumbClicked(item: BreadcrumbItem): void {
        this.descriptor.breadCrumbClicked(item)
    }
}
