import { Component, OnInit, Input } from '@angular/core';

enum EArrowIconTypes {
    keyboard_arrow_down = 'keyboard_arrow_down',
    keyboard_arrow_up = 'keyboard_arrow_up'
}

enum EArrowPositions {
    Up = 'Up',
    Down = 'Down'
}

@Component({
    selector: 'app-arrow',
    templateUrl: './arrow.component.html',
    styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent implements OnInit {
    @Input() position: EArrowPositions;

    constructor() {}

    ngOnInit() {}

    getRightArrowIcon(): EArrowIconTypes {
        return this.isUp()
            ? EArrowIconTypes.keyboard_arrow_up
            : EArrowIconTypes.keyboard_arrow_down
    }

    onToggleArrow(): void {
        this.position = this.isUp()
            ? EArrowPositions.Down
            : EArrowPositions.Up
    }

    isUp(): boolean {
        return this.position === EArrowPositions.Up
    }
}
