export namespace NCardModel {

    export enum ECardTitleColor {
        Grey = 1,
        White,
    };

    export enum ECardTheme {
        Default = 1,
        Invisible = 2,
        LightGrey = 3
    }

    export enum ETitleSize {
        Small,
        Medium,
        Big
    }

    export interface ICardDescriptor {
        title: string;
        titleColor: ECardTitleColor;
        titleSize: ETitleSize;
        displayArrow: boolean;
        displayTitle: boolean;
        contentsShown: boolean;
        theme?: ECardTheme;
    };

    export function getDefaultCardDescriptor(extraConfig?: Partial<ICardDescriptor>): ICardDescriptor {
        return {
            title: '',
            titleColor: ECardTitleColor.White,
            titleSize: ETitleSize.Medium,
            displayArrow: true,
            displayTitle: true,
            contentsShown: true,
            theme: ECardTheme.Default,
            ...extraConfig
        };
    }
}
