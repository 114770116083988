import { IStorageLayer } from "../hardware-interfaces";
import { Injectable } from '@angular/core';
import {isValidRef} from "@colmeia/core/src/tools/utility";
import {getAvailableStorage} from "../../../../polyfill/storage";

@Injectable({
    providedIn: 'root'
})
export class SharedStorage implements IStorageLayer {

    private storage: Storage;

    constructor() {
        this.storage = getAvailableStorage();
    }

    getAllStorageKeys(prefix?: string) {
        const keys = Object.keys(this.storage);
        return (isValidRef(prefix)) ? keys.filter(k => k.startsWith(prefix)) : keys;
    }

    clearAllItems(): void {
        this.storage.clear();
    }

    clearItem(key: string): void {
        this.storage.removeItem(key);
    }

    getItem<T extends object>(key: string): T | null {
        return JSON.parse(this.storage.getItem(key));
    }

    hasItem(key: string): boolean {
        return (key in this.storage);
    }

    putItem<T extends object>(key: string, item: T): void {
        this.storage.setItem(key, JSON.stringify(item));
    }

}
