import { Injectable } from '@angular/core';
import { RoutingService } from './routing.service';
import { routeID, routeList } from 'app/model/routes/route-constants';
import { IMacroPackageServer, TIMacroPackageServerArray, IAttendentServicePackClient } from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendente-service-pack';
import { SessionService } from './session.service';
import { RequestBuilderServices } from './request-builder.services';
import { IInfraParameters } from 'app/model/client-infra-comm';
import { IAttendentServicePackSaveRequest, IAttendentServicePackRequest, IAttendentServiceRequestListResponse, IGetAttendentServicePackRequest, IGetAttendentServicePackResponse, IAttendentServicePackResponse }
    from '@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp'
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ServerCommunicationService } from './server-communication.service';
import { ClientInfraResponse } from 'app/model/component/client-infra-comm';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { isValidRef, isValidString } from "@colmeia/core/src/tools/utility";
import { LookupService } from './lookup.service';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';

const baseRoute = routeList.dashboard.children.serviceStatus.children.attSupport;

@Injectable({
    providedIn: 'root'
})
export class DashboardMacroService {

    private _servicePack: IMacroPackageServer;

    constructor(
        private routeSvc: RoutingService,
        private rbs: RequestBuilderServices,
        private session: SessionService,
        private lookupSvc: LookupService,
        private api: ServerCommunicationService,
    ) { }

    private buildInfra(): IInfraParameters {
        return this.rbs.getNoCallBackNoSpinnningParameters(
            this.session.getPlayerID(),
            this.session.getSelectedAvatarID()
        );
    }

    async getAllMacroPackages(search: ITagableSearch, cursor: string | null = null): Promise<TIMacroPackageServerArray> {
        const infra: IInfraParameters = this.buildInfra();
        const request: IAttendentServicePackRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.macros.listMacros, infra),
            ...search,
            cursor,
        };

        const response = await this.api.managedRequest(infra, request);
        if (response.executionOK) {
            return (<IAttendentServiceRequestListResponse>response.response).servicePacks;
        } else {
            return [];
        }
    }

    async getMacrosArray(idsServicePacks: string[]): Promise<IMacroPackageServer[] | undefined> {
        const infra: IInfraParameters = this.buildInfra();
        const request: IGetAttendentServicePackRequest = {
            ...this.rbs.createRequestFromInfraParameters(apiRequestType.macros.getMacros, infra),
            idServicePacks: idsServicePacks,
        };
        const response = await this.api.managedRequest(infra, request);
        if (response.executionOK) {
            return (<IGetAttendentServicePackResponse>response.response).servicePacks;
        }
    }

    async getMacros(idServicePack: string, count: number = 0): Promise<IMacroPackageServer | undefined> {
        const macros: IMacroPackageServer[] | undefined = await this.getMacrosArray([idServicePack]);

        if (!isValidRef(macros) && count < 3) {
            return this.getMacros(idServicePack, count + 1);
        }
        return macros?.[0];
    }

    async saveMacroPackage(servicePack: IAttendentServicePackClient): Promise<IMacroPackageServer> {
        const response = await this.api.sendRequest
            <IAttendentServicePackSaveRequest, IAttendentServicePackResponse>
            (apiRequestType.macros.saveMacro)
            ({ ns: servicePack });

        return response.servicePack;
    }

    get selectedMacroPackage(): IMacroPackageServer {
        return this._servicePack;
    }

    public getSelectedMacroPackageIdByRoute(): string {
        return this.routeSvc.getRouteParam(baseRoute.children.editServicePack.routeParam.slice(1))
    }

    public async getMacroPackage(): Promise<IMacroPackageServer> {
        const idNS: string = this.getSelectedMacroPackageIdByRoute();
        console.log({
            isValidIdNs: isValidString(idNS),
            selectedMacro: this.selectedMacroPackage,
            equalId: this.getSelectedMacroPackageIdByRoute() === this.selectedMacroPackage?.idNS,
            idNS

        })

        return isValidString(idNS)
            ? this.lookupSvc.getSingleLookupElement<IMacroPackageServer>(idNS)
            : this.getEmptyMacroPackage() as IMacroPackageServer;
    }

    public getEmptyMacroPackage(): IAttendentServicePackClient {
        return {
            nsType: ENonSerializableObjectType.attendentServicePack,
            nName: '',
            macros: [],
            serviceGroups: [],
            attendantRegisterMedatadas: [],
            allowedActiveChannel: [],
            clientRegisterMetadatas: [],
            transferableIslands: [],
            finalizationMetadata: undefined,
            canonicals: [],
            variableTags: [],
            attendantDynamicListRegisterMedatadas: [],
        }
    }

    goToServicePackList(): void {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            baseRoute.path,
            baseRoute.children.servicePackList,
        );
    }

    goToServicePackDetails(servicePack: IMacroPackageServer): void {
        this._servicePack = servicePack;
        this.routeSvc.navigateToId(
            routeID.dashboard,
            routeList.dashboard.children.serviceStatus.path,
            baseRoute.path,
            baseRoute.children.editServicePack.path.replace(baseRoute.children.editServicePack.routeParam, servicePack.idNS)
        );
    }

    getGoToServicePackDetailsRoute(servicePack: IMacroPackageServer): string {
        return this.routeSvc.mountFullRoute(
            routeID.dashboard,
            [
                routeList.dashboard.children.serviceStatus.path,
                baseRoute.path,
                baseRoute.children.editServicePack.path.replace(baseRoute.children.editServicePack.routeParam, servicePack.idNS)
            ]
        );
    }

    public setServicePack(servicePack: IMacroPackageServer): void {
        this._servicePack = servicePack;
    }
}
