import { MainHandler } from '../handlers/main-handler';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { IFeedbackDetailResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import {IRootCallBack} from "../model/component-comm/basic";


export interface IReactDisplayCallback extends IRootCallBack {
    getInteractionDetails(idInteraction: TGlobalUID, cursor: string, idFeedback: TGlobalUID): Promise<IFeedbackDetailResponse | boolean>;
};


export interface IReactBoardDisplayParamenter {
    interaction: Interaction;
    clientCallback: IReactDisplayCallback;
    isSmall: boolean;
    idGroup: TGlobalUID;
};



export class ReactDisplayBarHandler extends MainHandler {

    constructor(reactBarParameter: IReactBoardDisplayParamenter) {
        super(reactBarParameter);
    };

    public getComponentParameter(): IReactBoardDisplayParamenter {
        return <IReactBoardDisplayParamenter>super.getComponentParameter();
    };

    public getInteraction(): Interaction {
        return this.getComponentParameter().interaction;
    };


    public setSmall(isSmall: boolean) {
        this.getComponentParameter().isSmall = isSmall;
    }

    public isSmall(): boolean {
        return this.getComponentParameter().isSmall;
    };

    public getGroupID(): TGlobalUID {
        return this.getComponentParameter().idGroup;
    };

    public async getInteractionDetails(cursor: string, idFeedback: TGlobalUID): Promise<IFeedbackDetailResponse | boolean> {
        const _res = await this.getComponentParameter().clientCallback.getInteractionDetails(
            this.getInteraction().getInteractionID(),
            cursor,
            idFeedback
        );
        return _res;
    };
}
