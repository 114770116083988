import { isInvalidString, isValidRef, isValidString, validateEmail, values } from "@colmeia/core/src/tools/utility";
import {
    Component,
    Inject,
    OnInit,
    Optional,
    AfterViewInit,
    ViewChild,
    Output,
    EventEmitter,
    OnDestroy,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ISearchActiveCallResult } from "@colmeia/core/src/shared-business-rules/active-1x1-call/active-1x1-model";
import { EFilterConversationsOptions, IAllCustomerActivityResponse } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp";
import {
    DatePickerHandler,
    EDatePickerMode,
    EDatePickerType,
} from "app/handlers/date-picker.handler";
import { take } from "rxjs/operators";
import { ColmeiaWindowService } from "../dashboard-foundation/colmeia-window/colmeia-window.service";
import {
    ColmeiaWindowInitService,
    DashboardWindowInitiator,
} from "../dashboard-foundation/colmeia-window/colmeia-window-init.service";
import { ColmeiaWindowRef } from "./../dashboard-foundation/colmeia-window/colmeia-window-ref";
import { AttendanceConversationHistoryViewComponent, DEFAULT_CONVERSATIONS_PER_PAGE } from "./attendance-conversation-history-view/attendance-conversation-history-view.component";
import { AttendanceConversationService } from "./attendance-conversation.service";
import { RoutingBuilder } from "app/services/routing-builder";
import { ActivatedRoute } from "@angular/router";
import { ICustomerFindHandler } from "app/components/customer-finder/customer-finder.handler.def";
import { EAttendanceHistorySearchType, ECustomerSearchContactType } from "@colmeia/core/src/shared-business-rules/search";
import { TISODateString } from "@colmeia/core/src/tools/date/date-utils.types";
import { CustomerFinderComponent } from "app/components/customer-finder/customer-finder.component";
import { SNConfigService } from "../social-network-config/sn-config.service";
import { AttendanceService } from "app/services/attendance.service";
import { ETooltipInfoKey } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { IAttendanceConversationHistoryDialogData } from "./attendance-conversation-history.model";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
const lodash = require('lodash');

@Component({
    selector: "app-attendance-conversation-history-search",
    templateUrl: "./attendance-conversation-history-search.component.html",
    styleUrls: ["./attendance-conversation-history-search.component.scss"],
})
export class AttendanceConversationHistorySearchComponent
    implements OnInit, OnDestroy, AfterViewInit {
    datePickerHandler: DatePickerHandler;

    public isInDialog: boolean = false;
    private defaultInitClockHour = 8;
    public now = new Date();
    public beginTimestamp: number = new Date(
        this.now.getFullYear(),
        this.now.getMonth(),
        this.now.getDate(),
        this.defaultInitClockHour
    ).getTime();
    public endTimestamp: number = Date.now();

    public beginDateISO: TISODateString | undefined;
    public endDateISO: TISODateString | undefined;
    public humanAttendanceOnly: boolean | undefined = false;

    public target: string = "";
    public ready: boolean = false;
    public customerFindConfig?: ICustomerFindHandler;

    get isSearching() {
        return this.customerFindConfig?.slave?.isSearching;
    }

    public searchByDateRangeFired = false;

    private windowInitiator: DashboardWindowInitiator<IAttendanceConversationHistoryDialogData>;
    private windowsOpened: Map<object, ColmeiaWindowRef> = new Map();
    private router: any = RoutingBuilder.builder(
        "dashboard",
        "children",
        "serviceStatus",
        "children",
        "conversationHistory",
        "children"
    );

    public historyType = [
        {
            label: "Intervalo de datas",
            value: EAttendanceHistorySearchType.byDateInterval,
        },
        {
            label: "Por usuário ",
            value: EAttendanceHistorySearchType.byCustomer,
        },
        {
            label: "Por agente",
            value: EAttendanceHistorySearchType.byAgent,
        },
    ];

    public selectedHistorySearchType: EAttendanceHistorySearchType =
        EAttendanceHistorySearchType.byCustomer;

    @ViewChild("customerFinder", { static: false })
    customerFinder: CustomerFinderComponent;

    @Output() searchTypeChanged: EventEmitter<any> = new EventEmitter();

    public showInput: boolean;
    public helpTipKey = ETooltipInfoKey.attendanceConversationHistorySearchTitle;

    public isWindow: boolean = false;

    public autoOpenFirstSearchWithUniqueRow: boolean = false;
    public firstRun: boolean = true;

    constructor(
        private attConversation: AttendanceConversationService,
        private colmeiaWindow: ColmeiaWindowService,
        private colmeiaInitService: ColmeiaWindowInitService,
        private route: ActivatedRoute,

        @Optional()
        @Inject(MAT_DIALOG_DATA)
        public dialogData: { target: string },
        @Optional()
        private dialogRef: MatDialogRef<AttendanceConversationHistorySearchComponent>,
        @Optional()
        private windowRef: ColmeiaWindowRef<AttendanceConversationHistorySearchComponent>,
        private snConfigSvc: SNConfigService,
        private attSvc: AttendanceService,
    ) {
        if (isValidRef(dialogRef)) {
            this.isInDialog = true;
        }
        if (isValidString(this.dialogData?.target)) {
            this.target = this.dialogData.target;
        }

        if (isValidRef(this.windowRef)) {
            this.isWindow = true;
            this.target = this.windowRef.data.target;
            this.autoOpenFirstSearchWithUniqueRow = this.windowRef.data.autoOpenFirstSearchWithUniqueRow;
        }
    }

    ngOnInit(): void {

        const idConversation =
            this.route.snapshot.paramMap.get("idConversation");

        if (!idConversation) {
            this.initWindowInitiator("idSocialKey");
        } else {
            this.initWindowInitiator("idConversation");
        }

        this.initDatePicker();

        const isAgent: boolean = this.attSvc.isPlayerAnAgentInCurrentSocialNetwork();
        const snConfig = this.snConfigSvc.getSettings();
        const shouldDisableBySnConfig: boolean = snConfig?.agent.historic.disableSearchByDateInterval;

        if (isAgent && shouldDisableBySnConfig) {
            this.historyType = this.historyType.filter(i => i.value !== EAttendanceHistorySearchType.byDateInterval);
        }
    }

    ngOnDestroy(): void {
    }

    private initDatePicker() {
        this.datePickerHandler = new DatePickerHandler({
            mode: EDatePickerMode.DATETIME,
            type: EDatePickerType.Range,
            currentRange: [],
            onRangePickISODate: (start, end) => {
                this.beginDateISO = start;
                this.endDateISO = end;
                this.searchOnDateChange();
            },
        });
    }

    private searchOnDateChange() {
        if (
            this.selectedHistorySearchType ===
            EAttendanceHistorySearchType.byDateInterval
        ) {
            this.triggerSearchByDateInterval();
        } else if (
            this.selectedHistorySearchType ===
            EAttendanceHistorySearchType.byAgent
        ) {
            if (this.customerFinder.target) {
                this.customerFinder.searchResult = [];
                this.customerFinder.search({
                    searchType: this.selectedHistorySearchType,
                    token: this.customerFinder.target,
                    dateRange: {
                        beginDate: this.beginDateISO!,
                        endDate: this.endDateISO!,
                    },
                });
            }
        }
    }

    triggerSearchByDateInterval() {
        this.customerFinder.search({
            dateRange: {
                beginDate: this.beginDateISO!,
                endDate: this.endDateISO!,
                humanAttendanceOnly: this.humanAttendanceOnly
            },
            token: '',
            searchType: this.selectedHistorySearchType,
        });
    }

    initWindowInitiator(editPath: string) {
        this.windowInitiator = this.colmeiaInitService.setup({
            hasNesting: true,
            component: AttendanceConversationHistoryViewComponent,
            initialPath: this.isInDialog ? this.colmeiaWindow.lastNavigatedUrl : this.router("search"),
            createPath: null,
            editPath: this.router("view", "children", editPath),
            keepQueryParams: true,
            initializeNSObject: () => null,
            dialogConfig: {
                panelClass: ["conversation-dialog-panel", "no-bottom-padding"],
                width: "90vw",
                height: "80vh",
                maxWidth: '90vw'
            },
        });
    }

    async ngAfterViewInit() {
        const idSocialKey = this.route.snapshot.paramMap.get("idSocialKey");
        const idConversation =
            this.route.snapshot.paramMap.get("idConversation");

        if (idSocialKey) {
            this.openWindow(idSocialKey);
        } else if (idConversation) {
            this.openWindow(null, idConversation, null);
        }

        this.customerFindConfig = {
            target: this.target,
            allowedSearchSources: {
                standard: {},
                infoSquare: {}
            },
            searchTypes: values(EAttendanceHistorySearchType),
            onResult: (items) => {
                if (items?.length === 1 && this.firstRun && this.autoOpenFirstSearchWithUniqueRow) {
                    this.handleCustomerClicked(items[0] as ISearchActiveCallResult)
                }

                this.firstRun = false;
            },
            onCustomerSelected: ([item]) => this.handleCustomerClicked(item),
            shouldDisableSearchingMessage: true,
        };
        this.ready = true;
    }

    async handleCustomerClicked(searchItemClicked: ISearchActiveCallResult) {
        const windowAlreadyOpen = this.windowsOpened.get(searchItemClicked);
        if (windowAlreadyOpen) {
            windowAlreadyOpen.restore();
            return;
        }
        this.dialogRef?.close();

        const runtime = await this.openWindow(searchItemClicked.idSocialKey, null, searchItemClicked.target, searchItemClicked.name, searchItemClicked);
        return runtime
    }

    private async openWindow(idSocialKey?: string, idConversation?: string, target?: string, name?: string, customerClicked?: ISearchActiveCallResult) {
        if (!idSocialKey && !idConversation) return;

        const runtimes = [...this.colmeiaWindow.windowRefRuntimeMap.values()];

        const alreadyOpen = runtimes.find(
            (r) => r.sourceObject.idNS === idSocialKey
        );

        if (alreadyOpen) {
            alreadyOpen.dialogRef.restore();
            return;
        }

        const idNS = idSocialKey || idConversation;
        const group = "Históricos de conversação";

        if (idSocialKey) {
            const allConversation: IAllCustomerActivityResponse = await this.getConversations(idSocialKey, undefined, DEFAULT_CONVERSATIONS_PER_PAGE, EFilterConversationsOptions.withHumanAttendance);
            const title = `Histórico de conversação${allConversation.avatarName || name
                ? ` de ${allConversation.avatarName ? lodash.capitalize(allConversation.avatarName) : lodash.capitalize(name)}`
                : ""
                }`;

            const runtime = this.windowInitiator.run(
                { openEditDialog: true },
                {
                    allConversation,
                    idNS,
                    customerAvatarName: allConversation.avatarName,
                    target,
                    customerClicked
                }
            );

            runtime.dialogRef.data = {
                target: this.target,
                loadMoreConversations: async (idSocialKey: string, cursor?: string, perPage?: number, filterConversationsBy?: EFilterConversationsOptions) => await this.getConversations(idSocialKey, cursor, perPage, filterConversationsBy),
                customerClicked
            }
            runtime.dialogRef.group = group;
            runtime.dialogRef.title = title;
            runtime.dialogRef.afterOpened().subscribe(() => {
                if (isValidString(target)) {
                    this.updateUrl(target);
                }
            })
            return runtime
        } else {
            const oneConversation = await this.attConversation.getConversation(
                idConversation, 
            );

            const runtime = this.windowInitiator.run(
                { openEditDialog: true },
                {
                    oneConversation,
                    idNS,
                    customerClicked,
                    target: oneConversation.botConversation.clientAddress
                }
            );

            runtime.dialogRef
                .afterClosed()
                .pipe(take(1))
                .subscribe(() => {
                    this.initWindowInitiator("idSocialKey");
                });
            runtime.dialogRef
                .afterMinimize()
                .pipe(take(1))
                .subscribe(() => {
                    this.initWindowInitiator("idSocialKey");
                });

            runtime.dialogRef.group = group;
            runtime.dialogRef.title = `Histórico de conversação${name
                ? ` de ${lodash.capitalize(name)}`
                : ""
                }`;
            runtime.dialogRef.afterOpened().subscribe(() => {
                if (isValidString(target)) {
                    this.updateUrl(target);
                }
            })
            return runtime
        }
    }

    updateUrl(target: string) {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const searchParams = new URLSearchParams(url.search);
        searchParams.append('target', target);
        url.search = searchParams.toString();
        history.replaceState(null, '', url.toString());
    }

    async getConversations(idSocialKey: string, cursor: string | undefined = undefined, perPage: number = DEFAULT_CONVERSATIONS_PER_PAGE, filterConversationsBy?: EFilterConversationsOptions ): Promise<IAllCustomerActivityResponse> {

        const response = await this.attConversation.getConversationHistories(
            idSocialKey,
            this.beginTimestamp,
            this.endTimestamp,
            cursor,
            perPage,
            filterConversationsBy
        );
        
        return response;
    }

    public searchTypeChangeEmitter(event: EAttendanceHistorySearchType) {
        if (this.selectedHistorySearchType !== event) {
            this.selectedHistorySearchType = event;
            this.beginDateISO = undefined;
            this.endDateISO = undefined;
            this.initDatePicker();

            this.humanAttendanceOnly = event !== EAttendanceHistorySearchType.byDateInterval ? undefined : true;
           
            this.searchTypeChanged.emit();

        }
        this.showTextInput(event);
    }

    public showTextInput(currentSearchType: EAttendanceHistorySearchType) {
        this.showInput =
            currentSearchType === EAttendanceHistorySearchType.byDateInterval
                ? false
                : true;
    }

    public isSearchByDateInterval() {
        return (
            this.selectedHistorySearchType ===
            EAttendanceHistorySearchType.byDateInterval
        );
    }

    public isSearchByAgent() {
        return (
            this.selectedHistorySearchType ===
            EAttendanceHistorySearchType.byAgent
        );
    }

    onHumanConversationsOnlyChange({checked: value}: MatSlideToggleChange) {
        this.humanAttendanceOnly = value;
        
        if (isValidString(this.endDateISO) && isValidString(this.beginDateISO)) {
            this.triggerSearchByDateInterval();
        }
    }
}

