import { Injectable } from '@angular/core';
import { IPushNotificationPolicy, TNotificationDeviceArray } from "@colmeia/core/src/business/push-notifications";
import { ESocialMedia } from '@colmeia/core/src/business/social';
import { TPublicMediumArray } from '@colmeia/core/src/comm-interfaces/billing-interfaces';
import { IGPSConfigurationStatus, IPersonalSettingsConfiguration } from '@colmeia/core/src/comm-interfaces/settings-interface';
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { IUserSettingsRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IUserSettingsResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { Observable, Subject } from 'rxjs';
import { IInfraParameters } from "../../../model/client-infra-comm";
import { RequestBuilderServices } from "../../../services/request-builder.services";
import { ServerCommunicationService } from "../../../services/server-communication.service";


@Injectable({
    providedIn: 'root'
})
export class SettingsModalService {
    private gpsSubject: Subject<IGPSConfigurationStatus> = new Subject();
    private paymentResolver: (value?: TPublicMediumArray | PromiseLike<TPublicMediumArray>) => void;
    private eSocialMediaResolver: (value?: Array<ESocialMedia> | PromiseLike<Array<ESocialMedia>>) => void;
    private notificationsDevicesResolver: (value?: TNotificationDeviceArray | PromiseLike<TNotificationDeviceArray>) => void;
    private notificationsPolicyResolver: (value?: IPushNotificationPolicy | PromiseLike<IPushNotificationPolicy>) => void;
    paymentPromise: Promise<TPublicMediumArray>;
    eSocialMediaPromise: Promise<ESocialMedia[]>;
    devicesPromise: Promise<TNotificationDeviceArray>;
    notificationsPolicyPromise: Promise<IPushNotificationPolicy>;

    constructor(
        private serverComm: ServerCommunicationService,
        private requestBuilderSvc: RequestBuilderServices
    ) {
        this.resetResolvers()
    }

    resetResolvers() {
        this.paymentPromise = new Promise<TPublicMediumArray>(res => this.paymentResolver = res)
        this.eSocialMediaPromise = new Promise<Array<ESocialMedia>>(res => this.eSocialMediaResolver = res)
        this.devicesPromise = new Promise<TNotificationDeviceArray>(res => this.notificationsDevicesResolver = res)
        this.notificationsPolicyPromise = new Promise<IPushNotificationPolicy>(res => this.notificationsPolicyResolver = res)
    }

    async getUserSettings(): Promise<IPersonalSettingsConfiguration> {
        console.log('getUserSettingsFromServer!!');
        this.resetResolvers()
        const infra: IInfraParameters = this.requestBuilderSvc.getContextNoCallBackNoSpinnningParameters();
        const request: IUserSettingsRequest = {
            ...this.requestBuilderSvc.secureBasicRequest(apiRequestType.security.getSettingsData),
        };
        const clientResponse = await this.serverComm.managedRequest(infra, request);

        const result = clientResponse.executionOK
            ? (<IUserSettingsResponse>clientResponse.response).personalSettings
            : null;
        if (result) {
            this.gpsSubject.next(result.gpsStatus);
            this.paymentResolver(result.paymentMediums);
            this.eSocialMediaResolver(result.socialBindings);
            this.notificationsDevicesResolver(result.notificationDevices);
            this.notificationsPolicyResolver(result.notificationsPolicy);
        }
        return result;
    }

    getGpsDataSettings(): Observable<IGPSConfigurationStatus> {
        return this.gpsSubject.asObservable()
    }

    getPaymentMedium(): Promise<TPublicMediumArray> {
        return this.paymentPromise
    }

    getSocialMedia(): Promise<Array<ESocialMedia>> {
        return this.eSocialMediaPromise
    }

    getNotificationsDevices(): Promise<TNotificationDeviceArray> {
        return this.devicesPromise;
    }

    getNotificationsPolicy(): Promise<IPushNotificationPolicy> {
        return this.notificationsPolicyPromise;
    }
}
