import { Component, OnInit } from '@angular/core';
import {HardwareLayerService} from "../../../../services/hardware";

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.css']
})
export class AboutDialogComponent implements OnInit {

  constructor(
      private hw: HardwareLayerService
  ) { }

  ngOnInit() {
  }

  get version(): string {
      return `${this.hw.getVersion().version}`;
  }
}
