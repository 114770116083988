import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const settingsViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Settings, // serializable original: modalSettings
    fields: {
        settings: 1, // Configurações
        bind: 2, // Vincular
        notifications: 3, // Notificações
        language: 4, // Idioma
        payment: 5, // Pagamentos
        location: 6, // Localização
        wallet: 7, // Carteira
        changePassword: 8, // Troca de senha
        informations: 9, // Informações
    }
} as const);

export const settingsTranslations = getTranslationsFields(settingsViewScreenOptions);