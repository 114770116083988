import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ChatActionBarWindowService {

    constructor(){}

    private subscriptions: Array<Subscription> = [];

    private subjectMap: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>();

    public setCarrier<T>(value: T, idInteractionType: string) {
        if (! this.subjectMap.has(idInteractionType)) {
            this.subjectMap.set(idInteractionType, new BehaviorSubject<any>(null));
        }
        this.subjectMap.get(idInteractionType).next(value);
    }

    public subscribeToCarrier<T>(subscription: (val: T) => void, idInteractionType: string): void {
        const subs = this.subjectMap.get(idInteractionType);
        if (subs) {
            this.subscriptions.push(subs.subscribe(subscription));
        }
    }

    public onWindowDestroy(idInteractionType: string = ''): void {}
}
