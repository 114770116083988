import { Serializable } from '../business/serializable';
import {SecurityItem} from './security-item';
import { constant , TGlobalUID} from '../business/constant';
import { ISecuritySettingsJSON } from '../comm-interfaces/business-interfaces';
import {GroupSecurityDomain,TGroupSecurityDomainArray} from './group-security-domain';
import {hashToArray, getUniqueStringID} from '../tools/utility';


export interface ISecurityItemUIDHash {
    [alias: string]: SecurityItem;
};
export interface IHashSecurityItem {
    [idGroupSecurity: string]: SecurityItem;
};
export type TSecurityItemArray = Array<SecurityItem>;


export class SecuritySettings extends Serializable {

    public securityItems: IHashSecurityItem;

    constructor(primaryID: TGlobalUID = getUniqueStringID()) {
        super(constant.objectType.securitySettings, primaryID);
        this.securityItems = {};
    };

    public addHash(securityItem: SecurityItem): void {
        this.securityItems[securityItem.getGroupSecurityDomain().getGroupSecurityDomainID()] = securityItem;
    };

    public isSecurityRequested(idSecurityDomain: TGlobalUID): boolean {
        let securityItem = this.securityItems[idSecurityDomain];
        return securityItem? true: false;
    };

    public resetSecurityItems(): void {
        this.securityItems = {};
    };

    public getSecuritySettingsID(): TGlobalUID {return super.getPrimaryID(); };

    public getSecurityDemandsDescription(): string {
        let description: string = '';
        for (let sec of this.getEditSecurityItems())
            if (sec.isSelected())
                description += sec.getGroupSecurityDomain().getName() + '\n';
        return description;
    };

    public getUncompliantDescription(playerConfirmedSettings: SecuritySettings): string { 
       return  'O Grupo que você está necessita das seguintes confirmações' + 
                this.getSecurityDemandsDescription() + 
                '\n\n Você já efetuou as seguintes Confirmações ' + 
                playerConfirmedSettings.getSecurityDemandsDescription(); 
    };

    public isAllPlayerRequirementsOK(playerConfirmedSettings: SecuritySettings): boolean {
        let groupRequirements: ISecurityItemUIDHash = this.getActiveSecurityItemsHash();
        let playerChecked:     ISecurityItemUIDHash = playerConfirmedSettings.getActiveSecurityItemsHash();

        for (let reqGroup of <TSecurityItemArray>hashToArray(groupRequirements)) {
            if (reqGroup.getGroupSecurityDomain().isPlayerRelated() &&
                      !playerChecked[reqGroup.getGroupSecurityDomain().getGroupSecurityDomainID()])
                return false;
        }
        return true;
    }

    public getActiveSecurityItemsHash(): ISecurityItemUIDHash {
        let iSec: ISecurityItemUIDHash = {};
        for(let key in this.securityItems) {
            let item = this.securityItems[key];
            if(item.isSelected()) {
                iSec[item.getGroupSecurityDomain().getGroupSecurityDomainID()] = item;
            }
        }
        return iSec;
    };

    public getSelectedSecurityItems(): TSecurityItemArray {
        return <TSecurityItemArray>hashToArray(this.getActiveSecurityItemsHash());
    };

    public getSecurityITemArray(): TSecurityItemArray {
        return hashToArray(this.securityItems);
    };

    public getEditSecurityItems(): TSecurityItemArray {
        let securityDomainArray: TGroupSecurityDomainArray = GroupSecurityDomain.getGroupSecurityDomainArray();
        let newSIArray: TSecurityItemArray = [];
        let secItem: SecurityItem;
        for (let domain of securityDomainArray)
            if (this.securityItems[domain.getGroupSecurityDomainID()])
                newSIArray.push(this.securityItems[domain.getGroupSecurityDomainID()])
            else
                newSIArray.push(SecurityItem.getNewSecurityItem(domain.getGroupSecurityDomainID()));
        return newSIArray;
    };
 
    public toJSON(): ISecuritySettingsJSON {
        let json: ISecuritySettingsJSON = <ISecuritySettingsJSON>super.toJSON();
        json.securityItems = [];
        if (this.securityItems) {
            for (let item of (<TSecurityItemArray>hashToArray(this.securityItems))) {
                json.securityItems.push(item.toJSON());
            };
        };
        return json;
    };

    public rehydrate(json: ISecuritySettingsJSON): void {
        super.rehydrate(json);
        for (let item of json.securityItems) {
            this.addHash(SecurityItem.factoryMessage(item));
		};
    };

    public static factoryMessage(json: ISecuritySettingsJSON): SecuritySettings {
        let securitySettings: SecuritySettings = new SecuritySettings(json.primaryID);
        securitySettings.rehydrate(json);
        return securitySettings;
    };

    public static hasDomain(securitySettings: ISecuritySettingsJSON, idSecurityDomain: TGlobalUID): boolean {
        return securitySettings 
            && securitySettings.securityItems
            &&  securitySettings.securityItems.some((sec) => {return sec.idSecurityDomain == idSecurityDomain});
    };
}


