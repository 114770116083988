import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { isInvalidArray } from '@colmeia/core/src/tools/utility';

@Directive({
    selector: '[appHighlightEl]'
})
export class HighlightElDirective {
    static highlightedClassname: string = 'highlighted'

    $elements: HTMLElement[] = [];
    private target: HTMLElement | ElementRef | string;

    @Input()
    highlightClassName: string = HighlightElDirective.highlightedClassname

    @Input()
    set appHighlightEl(target: HTMLElement | ElementRef | string) {
        this.unhighlight();
        this.target = target
        this.searchElements();
    }

    private searchElements() {
        if (!this.target) {
            this.$elements = [];
            return;
        }

        if (typeof this.target === 'string') {
            const nodesList = document.querySelectorAll<HTMLElement>(this.target);

            this.$elements = Array.from(nodesList);

            return;
        }

        this.$elements = this.target instanceof ElementRef
            ? [this.target.nativeElement]
            : [this.target]
    }

    @HostListener('mouseenter') onEnter() {
        if(isInvalidArray(this.$elements)) {
            this.searchElements();
        }

        this.highlight();
    }

    @HostListener('mouseleave') onLeave() {
        this.unhighlight();
    }

    private highlight() {
        for (const el$ of this.$elements) {
            el$.classList.add(this.highlightClassName);
        }
    }

    private unhighlight() {
        for (const el$ of this.$elements) {
            el$.classList.remove(this.highlightClassName);
        }
    }

}
