import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { constant, TGlobalUID } from '@colmeia/core/src/business/constant';
import { Group } from '@colmeia/core/src/business/group';
import { TGroupArray } from '@colmeia/core/src/core-constants/types';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { isGroupOnGenealogy } from '@colmeia/core/src/rules/filters';
import { EYouTaskStatus } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { TSolicitationGroupIdToTask } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { isValidArray } from '@colmeia/core/src/tools/utility';
import { YouTasksClientService } from 'app/components/dashboard/security/solicitation-tasks.service';
import { SecurityContextSignal } from 'app/model/signal/state-signals/sec-contex-signal';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { SocialNetworkDatabaseService } from 'app/services/social-network-database.service';
import { SusbcriptionContainerService } from 'app/services/subscriptions.service';
import { routeID } from "../../../model/routes/route-constants";
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import { IChangeInterfaceListener } from "../../../model/signal/ps-interfaces";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { NavigatorServices } from "../../../services/controllers-services/navigator/navigator.service";
import { SessionService } from "../../../services/session.service";
import { SignalListenerService } from "../../../services/signal/signal-listener";

@Component({
    selector: 'app-group-card-list',
    templateUrl: './group-card-list.component.html',
    styleUrls: ['./group-card-list.component.scss']
})
export class GroupCardListComponent implements OnInit, IChangeInterfaceListener {
    @Input() groupList: TGroupArray;
    isGroupListInputExists: boolean;
    subscriptionSignal: SubscriptionSignal;
    userSubscriptions: TSolicitationGroupIdToTask = {};
    playerSNsExceptPersonal;

    constructor(
        private solicitationsService: YouTasksClientService,
        private cdRef: ChangeDetectorRef,
        private listenerSvc: SignalListenerService,
        private navigator: NavigatorServices,
        private suContainer: SusbcriptionContainerService,
        private session: SessionService,
        private rbs: RequestBuilderServices,
        private serverAPI: ServerCommunicationService,
        private snSvc: SocialNetworkDatabaseService,
    ) { }

    ngOnInit() {
        // this.userSubscriptions = this.session.getSolicitationsForGroups();
        this.getMemberShipForRootGroups()
        this.isGroupListInputExists = isValidArray(this.groupList, 0);
        this.listenerSvc.listenSubscriptionChanges(this);
        this.listenerSvc.listenToInterfaceChanges(this);
        this.listenerSvc.listenToSecurityChangedContext(this);
    }

    getMemberShipForRootGroups() {
        if (this.session.isAtRootGroup()) {
            this.playerSNsExceptPersonal = this.snSvc.getPlayerSocialNetworksExceptPersonal()
        }
    }

    fetchGroupsForMenu(): void {
        this.groupList = this.subscriptionSignal.getSafeRequestedGroups().map(exec => <Group>exec.getSerializable());
    }

    changeOnSecureContextSignCallback(sign: SecurityContextSignal): void {
        // this.userSubscriptions = this.session.getSolicitationsForGroups();
        this.getMemberShipForRootGroups();
        this.fetchGroupsForMenu();
        this.cdRef.markForCheck();
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        const currentGroup: Group = this.session.getSelectedGroup();

        if (sign.getInterfaceSignal().changeGroupHeader) {
            this.cdRef.markForCheck();
        };

        if (sign.getInterfaceSignal().newUniversal) {
            if (UberCache.testCacheForObject(sign.getInterfaceSignal().newUniversal, constant.objectType.group)) {
                const group: Group = Group.staticFactory(sign.getInterfaceSignal().newUniversal);
                if (isGroupOnGenealogy(currentGroup.getGroupID(), group.getPlainGroupGenealogy()) && !this.navigator.isRefreshOnSecuritySignalDisabled()) {
                    this.navigator.navigateToGroupID(this.session.getSelectedGroupID(), routeID.groups.home, true, false);
                };
            };
        };

        if (isValidArray(sign.getInterfaceSignal().archivedGroups)) {
            const currentGroup: Group = this.session.getSelectedGroup();
            let gotoRoot: boolean = false;
            let check: boolean = false;

            for (const idDeleted of sign.getInterfaceSignal().archivedGroups) {
                console.log(idDeleted);
                if (!gotoRoot && isGroupOnGenealogy(idDeleted, currentGroup.getPlainGroupGenealogy())) {
                    gotoRoot = true;
                };
                const index: number = this.groupList.findIndex((g) => { return g.is(idDeleted) });
                if (index > -1) {
                    check = true;
                    this.groupList.splice(index, 1);
                }
            }
            if (gotoRoot) {
                this.navigator.navigateToGroupID(this.session.getCurrentSocialNetworkID(), routeID.groups.home, true, false);
            } else if (check) {
                this.cdRef.markForCheck();
            };
        }
    };

    public changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        this.subscriptionSignal = subscriptionSignal;

        if (this.isGroupListInputExists) return;

        this.fetchGroupsForMenu();
        // this.userSubscriptions = this.session.getSolicitationsForGroups();
        this.getMemberShipForRootGroups();
        this.cdRef.markForCheck();
    }

    get showStats(): boolean {
        return !this.session.getSelectedGroup().getGroupType().isRoot();
    }

    groupSubscription(groupId: TGlobalUID): EYouTaskStatus | undefined {
        return this.solicitationsService.getGroupSolicitationStatus(groupId);
    }

    canShowChatButton(groupId: TGlobalUID): boolean {
        return !!this.playerSNsExceptPersonal?.find(group => group.getPrimaryID() === groupId)
    }
}
