import { get2FAEngagementByIdProperty, getEngangementsOfTypeForIDField, getValidEvaluatorForField, getValidEvaluatorsForField } from '../shared-business-rules/bot/engagement-function';
import { EBPMAction, IBPMConditionalDisplay } from '../shared-business-rules/BPM/bpm-action-model';
import { IBPConditionalEvaluator } from '../shared-business-rules/BPM/bpm-model';
import { IMetaEngagement, EMetadataEngagementType, TIFieldSmartMapperArray, IFieldSmartMapper, IFieldSmart2FV } from '../shared-business-rules/metadata/meta-engagement';
import { getComputedInfo, TComputedInfo } from '../shared-business-rules/metadata/metadata-utils';
import { I2FASettings } from '../shared-business-rules/two-factor-validation/two-factor-model';
import { add, isValidArray, isValidFunction, isValidRef } from '../tools/utility';
import { GeneralFormField, IGeneralFormAnswer } from './general-form-answer';
import { ConditionsProcessor, TValidateFunction } from './general-form-condition-processor';
import { TCheckFieldConditionResult } from './general-form-interface';


interface IGeneralFormEvaluatorOptions {
    shouldIgnoreValidator?: boolean
}
export class GeneralFormEvaluator {

    private constructor(
        private engagement: IMetaEngagement,
        private options: IGeneralFormEvaluatorOptions = { shouldIgnoreValidator: false },
    ) { }

    public getEngagement(): IMetaEngagement {
        return this.engagement;
    }

    public static create(info: {
        engagement: IMetaEngagement,
    } & IGeneralFormEvaluatorOptions): GeneralFormEvaluator {
        const { engagement, ...options } = info;
        return new GeneralFormEvaluator(info.engagement, options)
    }

    public async validateFormField(changedIdProperty: string, value: string, validatorFn: TValidateFunction, computed: TComputedInfo = {}): Promise<TCheckFieldConditionResult> {
        const validator: IBPConditionalEvaluator = getValidEvaluatorForField(changedIdProperty, EBPMAction.validator, this.engagement);
        const sucess: TCheckFieldConditionResult = (this.options.shouldIgnoreValidator && isValidRef(validator)) ? 
        { hasAction: true, isSuccess: Boolean(value), errorMsg: 'Error: empty field' } 
        : await ConditionsProcessor.checkFieldCondition(validator, {currentValue: value, computed}, validatorFn);
        sucess.idProperty = changedIdProperty

        return sucess;
    }

    public async canDisplayFormField(changedIdProperty: string, value: string, validatorFn: TValidateFunction, computed: TComputedInfo = {}): Promise<boolean> {
        const validators: IBPConditionalEvaluator[] = getValidEvaluatorsForField(changedIdProperty, EBPMAction.conditionalDisplay, this.engagement);

        if (!isValidArray(validators)) {
            return true;
        }

        const validated = await Promise.all(
            validators.map(async validator => {
                if(!validator || validator.action.bpmAction != EBPMAction.conditionalDisplay) {
                    return true;
                }
        
                const action = <IBPMConditionalDisplay>validator.action
                if(action.doNotDisplay) {
                    return false;
                }

                const sucess = await ConditionsProcessor.checkFieldCondition(validator, {
                    currentValue: value, computed
                }, validatorFn);

                return sucess.hasAction && sucess.isSuccess;
            })
        );

        return validated.includes(true);
    }

    public async validateForm(formAnswer: IGeneralFormAnswer, validatorFn: TValidateFunction): Promise<TCheckFieldConditionResult[]> {
        const answers = formAnswer.responses.map(resp => ({idProperty: resp.idProperty, value: <string>resp.value}))
        const computed: TComputedInfo = getComputedInfo(formAnswer.responses)
        return this.validateAllFormFields(answers, validatorFn, computed)
    }
    
    public async getFormFieldErrorMsg(changedIdProperty: string, value: string, validatorFn: TValidateFunction): Promise<string> {
        const result = await this.validateFormField(changedIdProperty, value, validatorFn)
        return result.hasAction && result.errorMsg
    }

    public async isFormFieldWithError(changedIdProperty: string, value: string, validatorFn: TValidateFunction): Promise<boolean> {
        const result = await this.validateFormField(changedIdProperty, value, validatorFn)
        console.log({isFormFieldWithError: result});
        
        return this.isFormFieldWithErrorByChecked(result)
    }

    public isFormFieldWithErrorByChecked(fieldChecked: TCheckFieldConditionResult): boolean {
        return fieldChecked.hasAction && fieldChecked.isSuccess == false
    }

    public isSomeFieldWithError(fieldCheckedList: TCheckFieldConditionResult[]): boolean {
        return fieldCheckedList.some(field => this.isFormFieldWithErrorByChecked(field))
    }

    private async validateAllFormFields(formFieldList: {idProperty: string, value: string}[], validatorFn: TValidateFunction, computed: TComputedInfo): Promise<TCheckFieldConditionResult[]> {
        const result = await Promise
            .resolve(formFieldList)
            .then(fields => fields.map(async field => ({ ...field, canDisplay: await this.canDisplayFormField(field.idProperty, field.value, validatorFn, computed) })))
            .then(fields => Promise.all(fields))
            .then(fields => fields.filter(field => field.canDisplay))
            .then(fields => fields.map(async field => await this.validateFormField(field.idProperty, field.value, validatorFn, computed)))
            .then(fields => Promise.all(fields))
        return result
    }
}
