import { screenOptionToError } from "../error-messages";
import { campaignErrors, commNSerErrorFields } from "../error-fields";
import { TNserValidator } from "../validate-nser";
import { FriendlyMessage, isInvalid, isValidString } from "../../../../tools/barrel-tools";
import { ICampaignServer, ICMassCommunicationAction, ICampaingActionHeader } from "../../../campaigns/campaign-type-model";
import { ECampaignActionType, ICampaignOmniChannelSchedule, ICAWhatsAppSchedule, ICAEmailChannelSchedulle } from "../../../campaigns/campaing-comm-strategy";
import { EDelivery360Action } from "../../../../comm-interfaces/interaction-interfaces";

const errors = screenOptionToError(campaignErrors);

export function isMassComm(action: ICampaingActionHeader | undefined): action is ICMassCommunicationAction {
    return action?.actionType === ECampaignActionType.massComm;
}

export function isWhatsAppStrategy(strategy: ICAWhatsAppSchedule): strategy is ICAWhatsAppSchedule {
    return strategy.channelType === EDelivery360Action.Delivery360WhatsApp;
}

export function isEmailStrategy(strategy: ICampaignOmniChannelSchedule): strategy is ICAEmailChannelSchedulle {
    return strategy.channelType === EDelivery360Action.Delivery360Email;
}

const validator: TNserValidator = (nser: ICampaignServer, friendly: FriendlyMessage) => {

    nser.campaingActions.forEach(valdiateActionHeader);
    nser.campaingActions.filter(isMassComm).forEach(validateMassCommunicationAction);

    return friendly;

    function valdiateActionHeader(action: ICampaingActionHeader) {
        if(!isValidString(action.actionName)) {
            friendly.addReturnResponse(errors.noActionName);
        }

        if(isInvalid(action.actionType) || !Object.values(ECampaignActionType).includes(action.actionType)) {
            friendly.addReturnResponse(errors.noActionType);
        }

        if(isInvalid(action.idSchemma)) {
            friendly.addReturnResponse(errors.noIdSchemma);
        }
    }

    function validateMassCommunicationAction(action: ICMassCommunicationAction): void {
        action.commStrategy.forEach((strategy: ICampaignOmniChannelSchedule) => {
            if (isWhatsAppStrategy(strategy) && isInvalid(strategy.idWAFallbackTemplate)) {
                friendly.addReturnResponse(errors.noTemplateOnWhatsAppChannel);
            }
            if (isEmailStrategy(strategy) && isInvalid(strategy.idTemplateEmail)) {
                friendly.addReturnResponse(errors.noTemplateOnEmailChannel);
            }
        })
    }

    
}

export const campaignValidator = validator;