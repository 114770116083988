import {ITranslationConfig} from "@colmeia/core/src/shared-business-rules/translation/translation-engine";
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";

export enum EGroupHomeButtons {
    Groups = 'groups',
    About = 'about'
}

export interface IGroupHomeButton {
    option: EGroupHomeButtons;
    translation: ITranslationConfig;
}

export const allGroupHomeButtons: IGroupHomeButton[] = [
    {
        option: EGroupHomeButtons.Groups,
        translation: gTranslations.common.groups
    }
    // {
    //     option: EGroupHomeButtons.About,
    //     translation: translations.fragments.about
    // }
];
