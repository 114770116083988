<section class="container">
  <div class="reply-window">
    <div class="reply-window__icon">
      <mat-icon>reply</mat-icon>
    </div>
    <div class="message-content">
      <div class="message-content__title">{{ avatarName }}</div>
      <div class="message-content__text">{{ replyMessage }}</div>
      <div class="message-content__time">{{ replyTime }}</div>
    </div>
  </div>

  <div class="date-picker-container" *ngIf="datePickerHandler">
      <span>{{ translations.question }}</span>
      <cm-date-picker class="date-picker" [handler]="datePickerHandler"></cm-date-picker>
  </div>
</section>

