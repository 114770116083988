import { Translation } from './../components/foundation/translation/translation-helper';
import { Pipe, PipeTransform } from '@angular/core';
import { captalizeFirstLetter, isValidRef } from '@colmeia/core/src/tools/utility';

@Pipe({
    name: 'captalizeFirstLetter'
})
export class CaptalizeFirstLetterPipe implements PipeTransform {
    transform(value: string | Translation): string | Translation {
        if(!isValidRef(value)) return value;

        return captalizeFirstLetter(
            value instanceof Translation
            ? value.value
            : value
        );
    }
}
