import {IInteractionJSON} from './interaction-interfaces';
import { TGlobalUID } from '../business/constant';
import { TArrayID } from '../core-constants/types';

export enum EBillType {
    wallet = 'wallet',
    product = 'product',
    service = 'service',
}


export interface IBillOptions {
    price: string;
    quantityOffered: number;
    maxInstallment: number;
    endOffering: number;
    billType?: EBillType;
}



export type TArrayWalletOperation = Array<IWalletOperationInteractionJSON>;

export enum EWalletOperation {
    charge = 'charge',
    transfer = 'transfer'
};

export interface IAmount {
    amount: number;
    currency: string;
};

export interface IWalletPersonShortcut {
    nameSender: string;
    idMediaSender: string;
    nameReceiver: string;
    idMediaReceiver: string;
};

export interface IWalletInfo {
    person?: IWalletPersonShortcut;
    idSenderAvatar: TGlobalUID;
    idSenderPlayer: TGlobalUID;
    pairIDPlayer: TArrayID
}

export interface IWalletOperationInteractionJSON extends IInteractionJSON {
    walletOperation: EWalletOperation,
    amount: IAmount;
    wallet: IWalletInfo;
};

export interface IWalletChargeInteractionJSON extends IWalletOperationInteractionJSON {
  
};

export interface IWalletTransfer extends IWalletInfo {
    idReceiveAvatar: TGlobalUID;
    idReceivedPlayer: TGlobalUID;
}

export interface IWalletTransferInteractionJSON extends IWalletOperationInteractionJSON {
    wallet: IWalletTransfer;
};








