<header>
  <h3 class="title">
    {{ title || 'Jobs' }}
  </h3>
  <button mat-dialog-close style="padding: 8px;">
    <mat-icon>close</mat-icon>
  </button>
</header>


<mat-dialog-content>
  <div class="jobs-list-container">
    <div
      *ngFor="let job of jobs$ | async"
      class="jobs-list-item"
    >
      <header class="job-header">
        <div class="title-n-type">
          <h3 class="job-title">{{ job.nName }}</h3>
          <span class="mat-chip mat-standard-chip job-type">{{ job.jobType }}</span>
        </div>
        <div class="status-container">
          <span class="mat-chip mat-standard-chip job-status">{{ job.status }}</span>
        </div>
      </header>
      <div class="timestamps">
        <div class="timestamp">
          <div class="label">
            Iniciado
            <mat-icon>
              play_circle_outline
            </mat-icon>
          </div>
          <span class="value">{{ getReadableDate(job.started) }}</span>
        </div>
        <!-- <div *ngIf="job.lastJobTouch" class="timestamp">
          <div class="label">
            Última atualização
            <mat-icon>
              update
            </mat-icon>
          </div>
          <span class="value">{{ getReadableDate(job.lastJobTouch) }}</span>
        </div> -->
        <div *ngIf="job.ended" class="timestamp">
          <div class="label">
            Finalizado
            <mat-icon>
              task_alt
            </mat-icon>
          </div>
          <span class="value">{{ getReadableDate(job.ended) }}</span>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="refreshJobs()">
    <mat-icon>refresh</mat-icon>
  </button>
</mat-dialog-actions>
