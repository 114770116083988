import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMultipleNestedFormConfig } from '@colmeia/core/src/shared-business-rules/BPM/bpm-action-model';
import { isValidArray, isValidRef, isValidString, typedClone } from '@colmeia/core/src/tools/utility';
import { IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { KBAssetType, IBasicAsset, KBAssetTypeClientOnly } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { TContentAssetArray, IContentBasicAsset, EBotContentEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { NSPickerHandler } from 'app/handlers/ns-picker/ns-picker.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidMultipleCallsConfig } from '@colmeia/core/src/shared-business-rules/bot/conditional-functions';

type TFormLoopUseMode = 'fixed' | 'dynamic';

@Component({
    selector: 'app-condition-multiple-calls-editor',
    templateUrl: './condition-multiple-calls-editor.component.html',
    styleUrls: ['./condition-multiple-calls-editor.component.scss']
})
export class ConditionMultipleCallsEditorComponent implements OnInit {
    public multipleCallsConfig: IMultipleNestedFormConfig

    public formLoopMaxUseMode: TFormLoopUseMode = 'fixed';
    public formLoopMinUseMode: TFormLoopUseMode = 'fixed';

    public titleAssetHandler: IAssetAdderHandler;
    public yesAssetHandler: IAssetAdderHandler;
    public noAssetHandler: IAssetAdderHandler;
    public posNestedContent: IAssetAdderHandler;

    public multipleCallMinElementsLocalCanonical: NSPickerHandler;
    public multipleCallMaxElementsLocalCanonical: NSPickerHandler;

    constructor(
        private dialogRef: MatDialogRef<ConditionMultipleCallsEditorComponent, IMultipleNestedFormConfig>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IMultipleNestedFormConfig,
        private dashBoardService: DashBoardService,
        private snackBarSvc: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.multipleCallsConfig = typedClone(this.dialogData);
        this.consistency();
        this.defineFormLoopTypes();
        this.initAssetContentHandlers();
        this.initCanonicalPickHandlers();
    }

    private consistency() {
        if(!isValidArray(this.multipleCallsConfig.posNestedContent)) {
            this.multipleCallsConfig.posNestedContent = [];
        }
    }

    private defineFormLoopTypes() {
        this.formLoopMaxUseMode = isValidString(this.multipleCallsConfig.maxElementsLocalCanonical) ? 'dynamic' : 'fixed';
        this.formLoopMinUseMode = isValidString(this.multipleCallsConfig.minElementsLocalCanonical) ? 'dynamic' : 'fixed';
    }

    private initAssetContentHandlers() {
        this.titleAssetHandler = this.buildContentHandlerFor(this.multipleCallsConfig.title);
        this.yesAssetHandler = this.buildContentHandlerFor(this.multipleCallsConfig.yesQuestion);
        this.noAssetHandler = this.buildContentHandlerFor(this.multipleCallsConfig.noQuestion);
        this.posNestedContent = this.buildContentHandlerFor(this.multipleCallsConfig.posNestedContent);
    }

    private buildContentHandlerFor(assets: TContentAssetArray): IAssetAdderHandler {
        return {
            assets,
            assetTypesEnabled: [KBAssetType.contentAPICall, KBAssetType.content, KBAssetType.media, KBAssetTypeClientOnly.sticker],
            removeAsset: (index: number, assets: IBasicAsset[]) => {
                assets.splice(index, 1);
                return Promise.resolve(true)
            },
            saveAsset: (newAsset: IBasicAsset, assets: IBasicAsset[]) => {
                const index = assets.findIndex(ast => ast.idAsset === newAsset.idAsset);
                if(index > -1) {
                    assets[index] = newAsset;
                } else {
                    assets.push(newAsset);
                }
                (<IContentBasicAsset>newAsset).botContentType = EBotContentEvent.mainContent
                return Promise.resolve(true)
            }
        }
    }

    private initCanonicalPickHandlers() {
        this.multipleCallMinElementsLocalCanonical = this.getFormLoopCanonicalElementSize('minElementsLocalCanonical');
        this.multipleCallMaxElementsLocalCanonical = this.getFormLoopCanonicalElementSize('maxElementsLocalCanonical');
    }

    private getFormLoopCanonicalElementSize(target: 'minElementsLocalCanonical' | 'maxElementsLocalCanonical'): NSPickerHandler {
        return this.dashBoardService.easyCreateNSPickerHandler({
            nsType: ENonSerializableObjectType.canonical,
            title: "Canônico *",
            useDemandedTag: false,
            selectedIds: [this.multipleCallsConfig[target]],
            maxSelections: 1,
            clientCallback: {
                onSaveNSCallback: (ns) => {
                    this.multipleCallsConfig[target] = ns?.idNS;
                }
            }
        });
    }

    public formLoopMaxElementSizeChange(value: TFormLoopUseMode) {
        if(value === 'fixed') {
            this.multipleCallsConfig.maxElementsLocalCanonical = undefined;
        } else if(value === 'dynamic') {
            this.multipleCallsConfig.maxElements = undefined;
        }
    }

    public formLoopMinElementSizeChange(value: TFormLoopUseMode) {
        if(value === 'fixed') {
            this.multipleCallsConfig.minElementsLocalCanonical = undefined;
        } else if(value === 'dynamic') {
            this.multipleCallsConfig.minimumElements = undefined;
        }
    }

    public save() {
        if(!isValidMultipleCallsConfig(this.multipleCallsConfig)) {
            this.snackBarSvc.open("Configuração inválida", "Fechar", { duration: 4000 });
            return;
        }

        this.dialogRef.close(this.multipleCallsConfig);
    }
}
