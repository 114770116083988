import { isValidString } from "@colmeia/core/src/tools/utility";
import { isElementNode } from "app/model/client-utility";
import { QuillVarInserterBlot } from "../mat-quill-var-inserter/quill-var-inserter.blot";

const regex = /\${([\d\D][^}]+)}/g;

type TParamVariable = {
    id: string;
    value: string;
};

const turnVarToHtml = (param: string) => {
    return param.replace(
        regex,
        (varName) =>
            QuillVarInserterBlot.create({
                id: varName,
                value: removeCurlyBraces(varName),
                denotationChar: "",
            }).outerHTML
    );
};

const removeCurlyBraces = (value: string): string => {
    const variable = value.split("");
    if (variable[0] === "$") variable.splice(0, 1);
    if (variable[0] === "{") variable.splice(0, 1);

    const lastIndex = variable.length - 1;
    if (variable[lastIndex] === "}") variable.splice(lastIndex, 1);
    return variable.join("");
};

/*
 * Remove elementos não desejados que não foram
 * transformados pela função BBCode.htmlToBBCode
 */
const cleanHTML = (input: string): string => {
    const container = document.createElement("div");
    container.innerHTML = input;

    container.childNodes.forEach((node) => {
        if (!isElementNode(node) || !node.textContent) return;

        const textRawNode = document.createTextNode(node.textContent);
        container.replaceChild(textRawNode, node);
    });

    return container.innerHTML;
};

const mentionsToVariables = (
    input: string,
    varArray: TParamVariable[]
): string => {
    const container = document.createElement("div");
    container.innerHTML = input;

    parseMentionToVariablesOnNode(container, varArray);

    return container.innerHTML;
};

const parseMentionToVariablesOnNode = (
    container: HTMLElement,
    varArray: TParamVariable[]
) => {
    container.childNodes.forEach((node) => {
        if (!isElementNode(node)) return;

        const isMentionBlot = isMention(node);

        if (
            isMentionBlot &&
            isValidString(node.dataset?.value) &&
            node.dataset.id
        ) {
            const variableId: string = node.dataset.id;

            addNewVariable(variableId, varArray);

            const variableNameTextNode = document.createTextNode(variableId);

            container.replaceChild(variableNameTextNode, node);

            return;
        }

        if (node.nodeType === Node.ELEMENT_NODE && node.childNodes.length) {
            parseMentionToVariablesOnNode(node, varArray);
            return;
        }
    });
};

const isMention = (node: HTMLElement) => {
    const isElement: boolean = node.nodeType === Node.ELEMENT_NODE;
    const isSpan = node.nodeName === "SPAN";
    const hasBlotClass = isElement && node.classList.contains("mention");

    return isSpan && hasBlotClass;
};

const addNewVariable = (variable: string, varArray: TParamVariable[]) => {
    const varValue = removeCurlyBraces(variable);

    if (!isNewVar(varValue, varArray)) return;

    varArray.push({ id: variable, value: varValue });
};

const isNewVar = (
    variableName: string,
    varArray: TParamVariable[]
): boolean => {
    return !!!varArray.find((variable: any) => variable.value === variableName);
};

export {
    turnVarToHtml,
    cleanHTML,
    mentionsToVariables,
    TParamVariable,
    isNewVar,
    removeCurlyBraces,
};
