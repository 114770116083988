import { Injectable } from "@angular/core";
import { IMessageInstanceSelectChosenOptionStandardMsg } from "../message-instance.service";
import { ENotificationBtns } from "@colmeia/core/src/core-constants/constant-visual-elements.enums";
import { IInteractionFeedbackDriver } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { constant } from "@colmeia/core/src/business/constant";
import { Feedback } from "@colmeia/core/src/interaction/feedback";
import {GroupChatServices} from "../../../../../services/group-chat.services";
import {SessionService} from "../../../../../services/session.service";
import {BigIconService} from "../../../../../services/big-icon.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationMessageService {

    constructor(
        private chatSVC: GroupChatServices,
        private sessionSVC: SessionService,
        private bigIcon: BigIconService
    ) {}

    public handleOptionClicked(option: IMessageInstanceSelectChosenOptionStandardMsg): void {
        switch (option.optionSelected.getPrimaryID()) {
            case ENotificationBtns.Respond:
                const onFeedbackSelected = (feedback: Feedback) => {
                    const idGroup = this.sessionSVC.getSelectedGroup().getPrimaryID();
                    this.chatSVC.saveReaction(
                        option.interactionClicked.getPrimaryID(),
                        option.interactionClicked.getInteractionType().getPrimaryID(),
                        feedback.getPrimaryID(),
                        feedback.getInteractionTypeID(),
                        this.sessionSVC.getParticipant(idGroup).getPrimaryID(),
                        idGroup
                    );
                };
                const interDriver: IInteractionFeedbackDriver = {
                    idDriverInteraction: constant.interactionType.subscription.stdResponse,
                    priority: 1
                };
                const drivers = [interDriver];
                this.bigIcon.openFromFeedbackDrivers(
                    drivers,
                    this.sessionSVC.getSelectedGroup(),
                    onFeedbackSelected
                );
                break;
        }
    }
}
