import { deprecatedSuggestions } from "@colmeia/core/src/tools/type-utils";
import { typedScreenOptions } from "../../../tools/utility";
import { ECopilotContext } from "../../copilot/copilot.model";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";




export const attCopilotScreenOption = typedScreenOptions({
    idSerializable: EAllSerializable.AttendanceCopilot,
    fields: deprecatedSuggestions<Record<ECopilotContext, number>>()({
      [ECopilotContext.perAttendance]: 1, // Por Atendimento
      [ECopilotContext.perPilot]: 2, // Por piloto
  }).ok
} as const);

export const copilotTranslations = getTranslationsFields(attCopilotScreenOption);
