import { ErrorHandler, Injectable } from "@angular/core";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { allSeverityRegistry } from "@colmeia/core/src/core-constants/tracker-qualifiers";
import { CustomError, ICustomErrorJSON } from "@colmeia/core/src/error-control/custom-error";
import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { getNormalizedError } from "@colmeia/core/src/rules/aux-function";
import { getClock, isValidRef } from "@colmeia/core/src/tools/utility";
import { IClientErrorClassification } from "app/errors/client-error.model";
import { Observable, Subject } from "rxjs";
import { getClientErrorClassification } from "../errors/client-error.functions";
import { clientConstants } from "../model/constants/client.constants";
import { GlobalWarningService } from "./global-warning.service";
import { InteractionPersistorServices } from "./interaction-persistor.service";
import { RequestBuilderServices } from "./request-builder.services";

interface IClientErrorAdditionalInfo extends IRequest {
    idField: number;
    idError: TGlobalUID;
    functionName: string;
    classification: IClientErrorClassification;
}

const errorWhitelist: string[] = [];

/**
 * Service that handles errors that was not caught by the client application
 */
@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    private errorDB: { [key: string]: any } = {}
    private currentTimestamp: number = 0
    private errorWhitelist: string[] = errorWhitelist;

    private _error$: Subject<any> = new Subject();
    getError$(): Observable<any> { return this._error$.asObservable() }

    constructor(
        private reqBuilderSvc: RequestBuilderServices,
        private globalWarningService: GlobalWarningService,
        private interactionPersistorSvc: InteractionPersistorServices,
    ) {
        setInterval(() => this.errorDB = {}, clientConstants.error.maxTimeToClearGlobalErrorHandlerDB)
    }

    /**
     * Global error handler:
     * angular calls this function to handle
     * any exceptions not handled or thrown
     * @param  {any} error
     * @returns void
     */
    handleError(error: any): void {
        !error.message?.startsWith?.("NG0100: ExpressionChangedAfterItHasBeenCheckedError") &&
            console.error(`GlobalErrorHandlerService.handleError: `, error);
        // debugger

        if (this.inWhitelist(error)) {
            return;
        }

        error = getNormalizedError(error);
        this.sendErrorToServer(error)
        this.openErrorWindow(error)
        this._error$.next(error);
    }

    openErrorWindow(error: FriendlyMessage | string): void {
        this.globalWarningService.showError(error);
    }

    sendErrorToServer(error: any): void {
        const isAbleToSendLogErrorToServer: boolean = this.isAbleToSendLogToServerByHashingInput(error)
        if (!isAbleToSendLogErrorToServer) {
            return;
        }
        let isError: boolean = true;
        let additionalInfo: IClientErrorAdditionalInfo;
        const request = this.reqBuilderSvc.secureBasicRequest('error');

        if (CustomError.isCustomError(error)) {
            const customError: ICustomErrorJSON = <ICustomErrorJSON>error;
            isError = CustomError.isCustomError(error);
            additionalInfo = this.getAditionalCustomErrorInfo(customError, request) as IClientErrorAdditionalInfo;
        } else {
            additionalInfo = <IClientErrorAdditionalInfo>request;
        }

        additionalInfo.classification = getClientErrorClassification(error);

        if (isError) {
            this.interactionPersistorSvc.sendErrorEvent(
                error,
                allSeverityRegistry.errorUnhandledClientException,
                'GlobalErrorHandlerService.handleError',
                additionalInfo
            );
        };
    }

    isAbleToSendLogToServerByHashingInput(error: any): boolean {
        const stringFiedError = error.toString()
        const errorCount = this.errorDB[stringFiedError]
        this.errorDB[stringFiedError] = errorCount
            ? errorCount + 1
            : 1
        const isAbleToSendLogErrorToServer = this.errorDB[stringFiedError] == 1
        return isAbleToSendLogErrorToServer
    }

    isAbleToSendLogToServerByFrequency(): boolean {
        const newTimestamp = getClock()
        const delta = newTimestamp - this.currentTimestamp
        this.currentTimestamp = newTimestamp

        return delta > clientConstants.error.maxSendToServerLogTimeInterval
    }

    getAditionalCustomErrorInfo(error: ICustomErrorJSON, request: IRequest): Omit<IClientErrorAdditionalInfo, 'classification'> {
        const idError: TGlobalUID = error.idError;
        const idField: number = error.idField;
        const functionName: string = error.functionName;

        return {
            ...request,
            idError,
            idField,
            functionName,
        };
    }

    inWhitelist(error: any): boolean {
        if (
            isValidRef(error) &&
            isValidRef(error.message) &&
            this.errorWhitelist.includes(error.message)
        ) {
            return true;
        }

        return false;
    }
}
