<mat-dialog-content [formGroup]="form">
  @if (shouldShowButtonText()) {
    <mat-form-field appearance="fill" class="button-text">
      <mat-label>Texto do botão</mat-label>
      <input id="buttonText" matInput [required]="!isAuthTemplate()" [minlength]="buttonTextMinLenght"
      [maxlength]="buttonTextMaxLenght" [readonly]="isTemplateSent" (ngModelChange)="quickReply.buttonText = $event"
      formControlName="buttonText" />

      <mat-error *ngIf="hasErrorFor(buttonText)">
        {{ getErrorMessageFor(buttonText) }}
      </mat-error>

      <div *ngIf="quickReply.buttonText" class="cm__show__text__content">
        <mat-hint>
          {{ quickReply.buttonText.length }} / {{ buttonTextMaxLenght }}
        </mat-hint>
      </div>
    </mat-form-field>
  }

  <cm-bot-action-editor *ngIf="eventActionHandler" [handler]="eventActionHandler" (onActionChange)="onActionChange()"></cm-bot-action-editor>

  <mat-form-field *ngIf="requiredButtonData" appearance="fill" class="button-text"
    [ngClass]="{ 'entity__hidden': shouldHideTextInput() }">
    <mat-label>{{ getButtonDataLabel() }}</mat-label>
    <input id="buttonData" matInput required [(ngModel)]="quickReply.buttonData" [maxlength]="getButtonContentMaxLenghtForInput"
      formControlName="buttonData" />

    <mat-error *ngIf="hasErrorFor(buttonData)">
      {{ getErrorMessageFor(buttonData) }}
    </mat-error>

    <div *ngIf="quickReply.buttonData" class="cm__show__text__content">
      <mat-hint>
        {{ quickReply.buttonData.length }} / {{ getButtonContentMaxLenghtForInput }}
      </mat-hint>
    </div>
  </mat-form-field>


  <asset-adder *ngIf="shouldShowButtonDataContentHandler()" [handler]="buttonDataContentHandler"></asset-adder>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" (click)="save()" [disabled]="disableSaveButton()">Salvar</button>
</mat-dialog-actions>
