<section class="wallet">
	<mat-form-field appearance="outline">
		<mat-label>Digite sua senha atual</mat-label>
		<input matInput type="password" [(ngModel)]="actualPassword" />
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Digite sua nova senha</mat-label>
		<input matInput type="password" [(ngModel)]="newPassword" />
	</mat-form-field>
	<mat-form-field appearance="outline">
		<mat-label>Confirme sua nova senha</mat-label>
		<input matInput type="password" [(ngModel)]="newPasswordConfirm" />
	</mat-form-field>
	<br>
	<button mat-flat-button color="primary" class="button-w-icon" (click)="changePassword()">
		<mat-icon>refresh</mat-icon>&nbsp; Trocar senha
	</button>
</section>