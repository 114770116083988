import {
    Component,
    EventEmitter,
    Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { Group } from "@colmeia/core/src/business/group";
import { allAtendeeStatusDB, IServiceChatInitiatedInteractionJSON } from '@colmeia/core/src/comm-interfaces/service-group-interfaces';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { UberCache } from '@colmeia/core/src/persistency/uber-cache';
import { isInvalid, isInvalidArray, isInvalidString, isValidRef, isValidString } from '@colmeia/core/src/tools/utility';
import { GroupOrderSelectorShortcutHandler } from 'app/handlers/group-order-selector-shortcut.handler';
import { InterfaceInfoSignal } from 'app/model/signal/interface-signal';
import { AttendanceService } from 'app/services/attendance.service';
import { RoutingService } from 'app/services/routing.service';
import { SnackMessageService } from 'app/services/snack-bar';
import { GroupSelectorShortcutHandler, IShortcutParentInstance } from "../../../handlers/group-selector-shortcut.handler";
import { routeID } from "../../../model/routes/route-constants";
import { IChangeInterfaceListener, IListenerSecuredContext, IListenerSubscription } from "../../../model/signal/ps-interfaces";
import { SecurityContextSignal } from "../../../model/signal/state-signals/sec-contex-signal";
import { SubscriptionSignal } from "../../../model/signal/subscription-signal";
import { NavigatorServices } from '../../../services/controllers-services/navigator/navigator.service';
import { ExecutableItem } from "../../../services/controllers-services/security-controller/executable-item";
import { GroupShortcutHandlerService, ILastMessage } from "../../../services/group-shortcut-handler.service";
import { RouterManagerService } from "../../../services/router-manager.service";
import { SessionService } from '../../../services/session.service';
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { SusbcriptionContainerService } from "../../../services/subscriptions.service";
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { environment } from "environments/environment-client";

//<< MINE
import { AttendanceChatSearchCharacters } from "../../../services/attendance-chat-search-characters.service"


@Component({
    selector: 'app-group-chat-left-side-bar',
    templateUrl: './group-chat-left-side-bar.component.html',
    styleUrls: ['./group-chat-left-side-bar.component.scss'],
})
export class GroupChatLeftNavSideComponent implements OnInit, OnDestroy, IListenerSecuredContext,
    IListenerSubscription, IShortcutParentInstance, IChangeInterfaceListener {

    private groupsHandlersHash: { [idGroup: string]: GroupSelectorShortcutHandler } = {};
    private sortedHandlers: Array<GroupSelectorShortcutHandler> = [];
    private filteredShortcuts: Array<GroupSelectorShortcutHandler> = [];
    private mapGroupToURL: Map<Group, string> = new Map();
    public isDevEnvironment = environment.allDevFeatures;

    public searchToken: string

    @Output() groupClick = new EventEmitter<ExecutableItem>();
    @Input() restrictedWidth: boolean = false;

    @ViewChild(CdkVirtualScrollViewport) public virtualScrollViewport: CdkVirtualScrollViewport;

    constructor(
        private listener: SignalListenerService,
        private routerManagerSvc: RouterManagerService,
        private suContainer: SusbcriptionContainerService,
        private attendanceSvc: AttendanceService,        
        private shortcutSVC: GroupShortcutHandlerService,
        private sessionSvc: SessionService,
        private navigatorSvc: NavigatorServices,
        private routingService: RoutingService,
        private snackSvc: SnackMessageService,
        private lettersToHighlight: AttendanceChatSearchCharacters
    ) { }

    ngOnInit() {
        this.listener.listenSubscriptionChanges({
            changeSubscriptionSignCallback: (subscriptionSignal) => this.changeSubscriptionSignCallback(subscriptionSignal)
        });
        this.listener.listenToSecurityChangedContext({
            changeOnSecureContextSignCallback: (context) => this.changeOnSecureContextSignCallback(context)
        });
        this.listener.listenToInterfaceChanges({
            receiveChangeInterfaceCallback: (sign) => this.receiveChangeInterfaceCallback(sign)
        });
        this.orderShortcut.getComponentParameter().clientCallback = this;
    }

    groupTrackBy(_index: number, shortcut: GroupSelectorShortcutHandler) {
        return shortcut.getGroupID();
    }

    public onGroupOrderSelectorChange(): void {
        this.mustResortGroups();
    }

    get shortcuts(): GroupSelectorShortcutHandler[] {

        if (isValidString(this.searchToken)) {
            return this.filteredShortcuts;
        }

        return this.shortcutSVC.getShowableShortcutHandlers();
    }    

    get orderShortcut(): GroupOrderSelectorShortcutHandler {
        return this.shortcutSVC.getGroupOrderShortCurtHandler();
    }

    public changeOnSecureContextSignCallback(context: SecurityContextSignal) {
        this.mustResortGroups()
    };

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        // this.fetchGroupsForMenu();
        this.mustResortGroups();
    }

    public changeSubscriptionSignCallback(subscriptionSignal: SubscriptionSignal): void {
        // this.fetchGroupsForMenu();
        this.mustResortGroups();
    };
    
    public normalizedSearchToken: string = ''
    private updateFilteredShortcuts() {
        if (isInvalidString(this.searchToken)) { 
            this.lettersToHighlight.setSearchingLettersToHighLight('')
            this.normalizedSearchToken = ''
            return 
        }

        const beforeFilterShortcuts: GroupSelectorShortcutHandler[] = this.shortcutSVC.getShowableShortcutHandlers()
                
        this.normalizedSearchToken = this.searchToken.toUpperCase()
        this.filteredShortcuts = beforeFilterShortcuts.filter(shortcut => {
            const normalizedGroupName: string = shortcut.getGroupName().toUpperCase()
            return normalizedGroupName.includes(this.normalizedSearchToken)
        })       
        
        this.lettersToHighlight.setSearchingLettersToHighLight(this.normalizedSearchToken)
    }

    public emitSearch(searchToken: string): void {
        this.updateFilteredShortcuts();
    }    

    public ngOnDestroy(): void {
        this.lettersToHighlight.setSearchingLettersToHighLight('');
        this.listener.destroySubscriptions(this);
    };

    public getSortedGroupList(): Array<GroupSelectorShortcutHandler> {
        return this.sortedHandlers;
    }

    public get selectedOrder() {
        return this.orderShortcut.getComponentParameter().selected;
    }

    public get hasCustomSort(): boolean {
        return isValidRef(this.selectedOrder);
    }

    public getNotAnsweredOrder(group: GroupSelectorShortcutHandler): number {
        const message: ILastMessage = group.getLastMessage()
        if (isInvalid(message)) return 0;
        const interaction: Interaction = this.unsafeInteractionFactoryByMessage(message);
        return this.attendanceSvc.getNotAnsweredTime(interaction);
    }

    public unsafeInteractionFactoryByMessage(message: ILastMessage): Interaction {
        return UberCache.unsafeUberFactory(message.idInteraction, false) as Interaction;
    }

    public unsafeInteractionFactory(idInteraction: string) {
        return UberCache.unsafeUberFactory(idInteraction, false) as Interaction;
    }

    public getHydratedInteractionByJSONs(jsons: IServiceChatInitiatedInteractionJSON[]): Interaction[] {
        const result: Interaction[] = [];
        for (let item of jsons) {
            if (UberCache.testCache(item.primaryID)) result.push(this.unsafeInteractionFactory(item.primaryID))
        }
        return result;
    }

    mustResortGroups(): void {
        this.updateFilteredShortcuts();
        this.shortcutSVC.updateShowableShortcutHandlers();
    }

    public async goToGroupChat($event: PointerEvent, group: Group): Promise<void> {
        $event.preventDefault();

        const currentGroupId: string = this.sessionSvc.getSelectedGroupID();
        const wantedGroupId: string = group.getGroupID();
        const isSameGroup: boolean = currentGroupId === wantedGroupId;
        const isChatUrl: boolean = window.location.pathname.includes(`groups/chat`)

        if (isSameGroup && isChatUrl) {
            this.snackSvc.openInfo("Você já está nessa conversação.", 4000);
            return;
        }
        console.log({ goToGroupChat: group })
        await this.navigatorSvc.navigateToGroupID(
            group.getPrimaryID(),
            routeID.groups.chat
        );

    }

    public getURL(handler: GroupSelectorShortcutHandler) {
        const group = handler.getGroup();

        if (!this.mapGroupToURL.has(handler.getGroup()))
            this.mapGroupToURL.set(group, this.routingService.getURLToNavigateToId(routeID.groups.chat, group.getPrimaryID()))
                ;

        return this.mapGroupToURL.get(group);
    }

    public isGroupSelected(group: Group): boolean {
        return group.getPrimaryID() === this.suContainer.getSelectedGroupID();
    };

    public showGroupOrderSelector(): boolean {
        return allAtendeeStatusDB[this.attendanceSvc.currentStatus]?.showCharOrderSelector && this.attendanceSvc.hasAnyConversation();
    }

    public hasAnyBroadcastIsland(): boolean {
        return this.attendanceSvc.hasAnyBroadcastIsland();
    }
}
