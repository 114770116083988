<!-- Media (image or video)-->
<div class="media"
  *ngIf="canShowMultimedia() && !isTypeSimple()"
  [ngClass]="{'mtop12': hasMessage(),
              'parent-size': isParentDefineSize()}">

  <!-- media display -->
  <ng-container *ngFor="let media of getSlicedMultimediaList(); let i = index">
    <div
      *ngIf="!media.isVCard() && !documents.includes(media)"
      class="media--display"
      [class.media--only-one]="containsOnlyOneMedia()"
      [class.media--blur]="isThumbnail(media)">
      <!-- video play button -->
      <div class="video-play__btn" *ngIf="isVideo(media)">
        <i class="material-icons video-play__icon">
          play_circle_filled
        </i>
      </div>

      <!-- showing media -->
      <app-multimedia-instance 
        #mmItc
        (click)="!media.isUnknown() && !mmItc.hasStorageExpiredUrl && onMediaClicked(i)"
        [mmInstance]="media"
        [onStorageRenewClick]="handler.getOnStorageRenewClick()"
        [style.cursor]=" media.isUnknown() || mmItc.hasStorageExpiredUrl ? null : 'pointer'"
      ></app-multimedia-instance>
    </div>
  </ng-container>


</div>

<div class="media document-container" *ngIf="documents.length > 0">
  <!-- document type preview -->
  <div class="document"
    *ngFor="let doc of documents; let i = index"
  >
    <img class="document-icon" [src]="getFileIconFor(doc)" *ngIf="!(doc.isVCard() || doc.isPDF())"/>
    <div class="document-text">
      <app-multimedia-instance
        #mmItc
        [mmInstance]="doc"
        [onStorageRenewClick]="handler.getOnStorageRenewClick()"
      ></app-multimedia-instance>
      <button *ngIf="doc.isPDF() && !mmItc.hasStorageExpiredUrl" (click)="onMediaClicked(i)" matRipple class="open-document-button">
        <mat-icon>picture_as_pdf</mat-icon>&nbsp;Abrir
      </button>
    </div>
  </div>
</div>

<!-- more media icon -->
<div class="more-media" *ngIf="canShowMoreMediaIcon()">
  <div class="more-media__icon">+{{ getMissingMediaNumber() }}</div>
</div>

<button *ngIf="canShowMultimedia() && isTypeSimple()" (click)="onMediaClicked(0)">
  <i class="material-icons">playlist_play</i>
</button>
