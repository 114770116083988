import {Injectable} from "@angular/core";
import {
    IChangeInterfaceListener,
    IFeatureChangeListener,
    IFilteredChained,
    IFilteredGroup, ILeftBarHideListener,
    IListenerAnsweredMessage,
    IListenerChained,
    IListenerInteraction,
    IListenerMultimediaClicked,
    IListenerParent,
    IListenerSecuredContext,
    IListenerSubscription,
    IListenerTypingInfo,
    ILoginEventListener,
    IShareListener,
    ISocketReadyListener,
    ISystemLogoutListener,
    TAllSubscriptions,
    TListenerActiveSubscriptions,
    IRightBarHideListener
} from "../../model/signal/ps-interfaces";
import {SignalPublisherService} from "./signal-publisher";
import {Subscription} from "rxjs";
import {filter} from "rxjs/operators";
import {observableList} from "../../model/signal/signal-constants";
import {ChainedInteractionSignal} from "../../model/signal/state-signals/chained-signal";
import {ChainedInteraction} from "@colmeia/core/src/interaction/chained-interaction";
import {InteractionSignal} from "../../model/signal/state-signals/interaction-signal";
import {InterfaceInfoSignal} from "../../model/signal/interface-signal";
import {GroupNotificationSignal} from "../../model/signal/notification-signal";
import {BackButtonPressed} from "../hardware/cordova/cordova-events";
import {SecurityContextSignal} from "../../model/signal/state-signals/sec-contex-signal";
import {TypingSignal} from "../../model/signal/trackers/typing-signal";
import {MultimediaPlayerSignal} from "../../model/signal/multimedia-player-signal";
import {DropDownSignal} from "../../model/signal/dropdown-signal";
import {AnswerMessageSignal} from "../../model/signal/answer-message-signal";
import {TGlobalUID} from "@colmeia/core/src/core-constants/types";
import {FeatureSignal} from "../../model/signal/state-signals/feature-signal";
import {LoginSignal} from "../../model/signal/cookie-login-sign";
import {SocketReadySignal} from "../../model/signal/socket-ready-sign";
import {LogoutSignal} from "../../model/signal/logout-sign";
import {NShareSignal} from "../../model/signal/share-signal";
import {ISellItemListener, SellItemSignal} from "../../model/signal/sell-item.signal";
import {ActivateGeoSignal, DeactivateGeoSignal} from "../../model/signal/geo-signals";
import {HideLeftBarSignal} from "../../model/signal/hide-left-bar-signal";
import { HideRightBarSignal } from "app/model/signal/hide-right-bar-signal";
import { MacrosSignal, IMacrosListener } from "app/model/signal/macros-signal";
import { SubscriptionSignal } from "app/model/signal/subscription-signal";

@Injectable({
    providedIn: 'root'
})
export class SignalListenerService {

    private subscriptionDatabase: TAllSubscriptions;

    constructor(private subsEmissor: SignalPublisherService) {
        this.subscriptionDatabase = new Map();
    };

    public destroyAll(): void {
        for (const [key, value] of this.subscriptionDatabase) {
            for (const subs of value) {
                subs.unsubscribe();
            };
        };
    };

    public destroySubscriptions(component: IListenerParent): void {
        const componentSubscriptions: TListenerActiveSubscriptions = this.subscriptionDatabase.get(component);
        if (componentSubscriptions) {
            for (const subs of componentSubscriptions) {
                subs.unsubscribe();
            };
        };
    };

    private addToSubscriptionDatabase(component: IListenerParent, subscription: Subscription): void {
        let componentSubscriptions: TListenerActiveSubscriptions = this.subscriptionDatabase.get(component);
        if (componentSubscriptions) {
            componentSubscriptions.push(subscription)
        } else {
            componentSubscriptions = [subscription];
        };
        this.subscriptionDatabase.set(component, componentSubscriptions);
    }

    public listenToHideLeftBar(callback: ILeftBarHideListener) {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: HideLeftBarSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.interfaceState.hideLeftBar);
            }))
            .subscribe((sign: HideLeftBarSignal) => {
                callback.onLeftBarToggle(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    }

    public listenToHideRightBar(callback: IRightBarHideListener) {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: HideRightBarSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.interfaceState.hideRightBar);
            }))
            .subscribe((sign: HideRightBarSignal) => {
                callback.onRightBarToggle(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    }

    public listenSubscriptionChanges(callback: IListenerSubscription): void {
        const sub: Subscription = this.subsEmissor._getSubscriptionStream()
            .pipe(filter((sign) => {return sign && sign.isSucces()}))
            .subscribe((sign: SubscriptionSignal) => {
                callback.changeSubscriptionSignCallback(sign)}
            );
        this.addToSubscriptionDatabase(callback, sub);
    };


    public listenDirectAndReactChainedInteraction(callback: IListenerChained, aFilter: IFilteredChained): void {
        const sub: Subscription = this.subsEmissor._getChainedInteractionStream().pipe(
            filter((chainedSignal: ChainedInteractionSignal) => { return chainedSignal && chainedSignal.isSucces() &&
                chainedSignal.callMe(observableList.interaction.incommingChained)
                && chainedSignal.getGroupID() == aFilter.idGroup}),
            filter((chainedSignal: ChainedInteractionSignal) => {
                const chained: ChainedInteraction = chainedSignal.getIncomingChained();
                let ok: boolean = false;
                if (chained.getInteractionParent().is(aFilter.idInteractionParent)) {
                    ok = true;
                };
                return ok;
        })).subscribe((chainedSignal: ChainedInteractionSignal) => {
            callback.newIncomingChainedSignCallback(chainedSignal);
        });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenRefreshUIInteractionFiltered(aFilter: IFilteredGroup, callback: IListenerInteraction): void {
        const sub: Subscription = this.subsEmissor._getInteractionStream().pipe(
                filter((interactionSignal: InteractionSignal) => {
            return interactionSignal && interactionSignal.isSucces()
                && interactionSignal.callMe(observableList.interaction.incommingInteraction)
                && interactionSignal.getGroupID() == aFilter.idGroup;
        })).subscribe((interactionSignal: InteractionSignal) => {
            callback.newIncomingInteractionSignCallback(interactionSignal);
        });
        this.addToSubscriptionDatabase(callback, sub);
    };


    public listenToIncomingInteraction(callback: IListenerInteraction): void {
        const sub: Subscription = this.subsEmissor._getInteractionStream().pipe(
            filter((interactionSignal: InteractionSignal) => {
            return interactionSignal && interactionSignal.isSucces()
                && interactionSignal.callMe(observableList.interaction.incommingInteraction)
        })).subscribe((interactionSignal: InteractionSignal) => {
            callback.newIncomingInteractionSignCallback(interactionSignal);
        });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToSecurityChangedContext(callback: IListenerSecuredContext): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((securitySignal: SecurityContextSignal) => {
                return securitySignal && securitySignal.isSucces()
                && securitySignal.callMe(observableList.synch.playerCachedChanged)
            })).subscribe((securitySignal: SecurityContextSignal) => {
                callback.changeOnSecureContextSignCallback(securitySignal);

            });
        this.addToSubscriptionDatabase(callback, sub);
    };


    public listenToTypingFiltered(aFilter: IFilteredGroup, callback: IListenerTypingInfo): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((typing: TypingSignal) => {
                return typing && typing.isSucces() && typing.callMe(observableList.tracker.typing)
                        && typing.getGroupID() == aFilter.idGroup;
            })).subscribe((typing: TypingSignal) => {
                callback.receiveTypingInfoCallback(typing);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenMessageInstanceMultimediaClicked(callback: IListenerMultimediaClicked): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: MultimediaPlayerSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.multiMedia.player);
            }))
            .subscribe((sign: MultimediaPlayerSignal) => {
                callback.setCurrentClickedMultimedia(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenMessageInstanceAnswer(callback: IListenerAnsweredMessage): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: AnswerMessageSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.interaction.answerMessage);
            }))
            .subscribe((sign: AnswerMessageSignal) => {
                callback.setCurrentAnswerMessage(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToChatBarHandlerChange(callback: IFeatureChangeListener, idGroup: TGlobalUID): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: FeatureSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.features.batchUpload) &&
                sign.getChatHandler().getGroupID() == idGroup
            }))
            .subscribe((sign: FeatureSignal) => {
                callback.setFeaturedChatBarHandlerCallback(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToUserLoginEvent(callback: ILoginEventListener): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStreamBehaviour().pipe(
            filter((sign: LoginSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.auth.loginByCookie);
            }))
            .subscribe((sign: LoginSignal) => {
                callback.receiveLoginEventCallback(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToSocketReady(callback: ISocketReadyListener): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: SocketReadySignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.auth.socketReady);
            }))
            .subscribe((sign: SocketReadySignal) => {
                callback.receiveSocketReadtCallback(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToLogoutEvent(callback: ISystemLogoutListener): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: LogoutSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.auth.logout);
            }))
            .subscribe((sign: LogoutSignal) => {
                callback.receiveLogoutEventCallback();
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToShareSignal(cb: IShareListener): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: NShareSignal.ShareSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.share.message);
            }))
            .subscribe((sign: NShareSignal.ShareSignal) => {
                cb.receiveSharedCallback(sign);
            });
        this.addToSubscriptionDatabase(cb, sub);
    }

    public listenToSellItemSignal(cb: ISellItemListener): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: NShareSignal.ShareSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.payments.sell);
            }))
            .subscribe((sign: SellItemSignal) => {
                cb.openSellItemModal(sign.getObservableInformation());
            });
        this.addToSubscriptionDatabase(cb, sub);
    }

    public listenToActivateGeoSignal(cb: Function): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: ActivateGeoSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.geo.activate);
            }))
            .subscribe((sign: SellItemSignal) => {
                cb();
            });
        this.addToSubscriptionDatabase(cb, sub);
    }

    public listenToDeactivateGeoSignal(cb: Function): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: DeactivateGeoSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.geo.deactivate);
            }))
            .subscribe((sign: SellItemSignal) => {
                cb();
            });
        this.addToSubscriptionDatabase(cb, sub);
    }

    public listenToBackButtonPressed(cb: Function): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: BackButtonPressed) => {
                return sign && sign.isSucces() && sign.callMe(observableList.mobile.buttons.back);
            }))
            .subscribe((sign: BackButtonPressed) => {
                cb(sign.getEvent());
            });
        this.addToSubscriptionDatabase(cb, sub);
    }

    public listenToGroupNotification(cb: (signal: GroupNotificationSignal) => void): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStreamBehaviour().pipe(
            filter((sign: GroupNotificationSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.mobile.notification.group);
            }))
            .subscribe((sign: GroupNotificationSignal) => {
                cb(sign);
            });
        this.addToSubscriptionDatabase(cb, sub);
    };

    public listenToInterfaceChanges(callback: IChangeInterfaceListener, uniqueID: string = null): void {
        const sub: Subscription = this.subsEmissor._getGenericStateStream().pipe(
            filter((sign: InterfaceInfoSignal) => {
                return sign && sign.isSucces() && sign.callMe(observableList.interfaceState.stateChange)
                    && (!uniqueID || sign.getObservableInformation().uniqueID !== uniqueID)
            }))
            .subscribe((sign: InterfaceInfoSignal) => {
                callback.receiveChangeInterfaceCallback(sign);
            });
        this.addToSubscriptionDatabase(callback, sub);
    };

    public listenToMacros(callback: IMacrosListener): void {

        const sub: Subscription = this
            .subsEmissor
            ._getGenericStateStream()
            .pipe(
                filter(
                    (sign: MacrosSignal) =>
                        sign
                        && sign.isSucces()
                        && sign.callMe(observableList.macros)
                )
            )
            .subscribe(
                (sign: MacrosSignal) => callback.listenToMacros(sign)
            )



        this.addToSubscriptionDatabase(callback, sub)
    }


};
