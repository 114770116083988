import { TGlobalUID } from '../../core-constants/types';
import { Participant } from '../../business/participant';
import { constant } from '../../business/constant';
import { ITypingBroadcasterJSON } from '../../comm-interfaces/barrel-comm-interfaces';
import { TrackerSignature } from './tracker-signature';



export class TypingBroadcaster extends TrackerSignature {

    private constructor (primaryID: TGlobalUID, participant: Participant) {
        super(primaryID, participant, constant.trackerTypes.typingInfo);
    };

    public toJSON(): ITypingBroadcasterJSON {
        return super.toJSON();
    };

    public rehydrate(json: ITypingBroadcasterJSON): void {
        super.rehydrate(json);
    }


    public static getNewTypingBroadcaster(participant: Participant) {
        return new TypingBroadcaster (null, participant);
    };


};
