import { Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { ESCode } from '@colmeia/core/src/error-control/barrel-error';
import { IGetBotConversationRequest, IGetBotConversationResponse } from '@colmeia/core/src/request-interfaces/lookup-generic';
import { IBatchNonSerializableRemoteRequest, IBatchNonSerializableRequest, IBatchNonSerializableResponse, IGetGenericNSLookup, ISaveGenericNSRequest } from '@colmeia/core/src/request-interfaces/lookup-ns-request';
import { ELookupType, IGetLookupAvatarGroupsRequest, IGetLookupInformationRequest, IGetLookupResponse, ISaveGenericNSResponse, ISearchInFileRequest, ISearchInFileResponse } from '@colmeia/core/src/request-interfaces/lookup-request';
import { apiRequestType } from '@colmeia/core/src/request-interfaces/message-types';
import { ILocalCanonical } from "@colmeia/core/src/shared-business-rules/canonical-model/local-canonical";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader, TNSCache, TNonSerializableArray } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { NsTypeToInterface } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-interface-mapper';
import { IdDep } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { arrayToHash, fakeAssertsGuard, isInvalid, isInvalidArray, isValidArray, isValidRef, isValidString, objectShallowReplace, pickKeys, throwPropagateErrorField, typedClone, values } from '@colmeia/core/src/tools/utility';
import { $ValueOf, Nullable, TDeepMap } from '@colmeia/core/src/tools/utility-types';
import { ClientInfraResponse, IInfraParameters } from "../model/client-infra-comm";
import { RequestBuilderServices } from "./request-builder.services";
import { ServerCommunicationService } from "./server-communication.service";

type Executor<T> = (value: T) => void;
interface ILookupItem<T> {
    id: string;
    resolve: Executor<T>;
    reject: Executor<unknown>;
    idNSRemoteEnvAuthProvider?: string;
    promise?: Promise<T>;
}

interface ICreateNSCacheImplementation {
    timeout?: number;
    shouldUseDebouncer?: boolean;
    idNSRemoteEnvAuthProvider?: string
}

export type TNSHashCache<T extends INonSerializable = INonSerializable> = { [idNS in string]: T };

export type TNSHashCacheImplementation<T extends INonSerializable = INonSerializable> = {
    hydrateWith: (nss: T[]) => void,
    hydrate: <NS = T>(idNS: string[]) => Promise<NS[]>,
    hash: TNSHashCache<T>;
    toArray: <NS = T>() => NS[]
};
interface IGetNS {
    <NSType extends ENonSerializableObjectType, T extends NsTypeToInterface[NSType]>(id: IdDep<NSType>): Promise<T>;
    <T extends INonSerializable>(id: string): Promise<T>;
}

interface IGetNSs {
    <NSType extends ENonSerializableObjectType, T extends NsTypeToInterface[NSType]>(id: IdDep<NSType>[], idNSRemoteEnvAuthProvider?: string): Promise<T[]>;
    <T extends INonSerializable>(ids: string[], idNSRemoteEnvAuthProvider?: string): Promise<T[]>;
}



export interface CacheImplementation<T extends INonSerializable> {
    (ids: string[]): Promise<T[]>
    cache: TNSCache,
    addToCache: (nss: INonSerializable[]) => void;
    getNS: <U extends INonSerializable = T>(ids: string) => Promise<U>
};

const $idNS = pickKeys<INonSerializable>().idNS;

@Injectable({
    providedIn: 'root'
})
export class LookupService {

    public shouldUseDebouncer: boolean = false;
    public clientNSResponseExample: ClientInfraResponse;

    constructor(
        private rbs: RequestBuilderServices,
        private serverAPI: ServerCommunicationService,
    ) { }

    public searchInFile = this.serverAPI.sendRequest<ISearchInFileRequest, ISearchInFileResponse>(ELookupType.searchInFile)

    public async getLookup(lookupType: ELookupType, cursor: string = null): Promise<IGetLookupResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetLookupInformationRequest = {
            ...this.rbs.getLookupRequest(lookupType, cursor),
        }
        let clientResponse: ClientInfraResponse = null;

        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);

        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getLookup');
        } finally {
            if (clientResponse.executionOK) {
                return <IGetLookupResponse>clientResponse.response;
            } else {
                return null;
            }
        }
    }


    public async getBotConversation(idConversation: string): Promise<IGetBotConversationResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetBotConversationRequest = {
            ...this.rbs.getLookupRequest(ELookupType.getBotConversation, null),
            idConversation
        }
        let clientResponse: ClientInfraResponse = null;

        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);
        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getGenericLookup');
        } finally {
            if (clientResponse.executionOK) {
                return clientResponse.response as IGetBotConversationResponse;
            } else {
                return null;
            }
        }
    }

    public async getAvatarGroups(idAvatar: TGlobalUID, cursor: string = null): Promise<IGetLookupResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetLookupAvatarGroupsRequest = this.rbs.getAvatarGroupsLookupRequest(idAvatar, cursor);
        let clientResponse: ClientInfraResponse = null;

        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);
        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getGroupsAvatar');
        } finally {
            if (clientResponse && clientResponse.executionOK) {
                return clientResponse.response as IGetLookupResponse;
            } else {
                return null;
            }
        }
    }


    public async getServicePackGroups(idServicePack: string): Promise<IGetLookupResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        // const request: IGroupFromServicePackRequest = {
        const request: any = {
            ...this.rbs.getLookupRequest(ELookupType.getServGroupsFromServPack, null),
            idServicePack,
        }
        let clientResponse: ClientInfraResponse = null;

        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);
        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getServicePackGroups');
        } finally {
            if (clientResponse.executionOK) {
                return clientResponse.response as IGetLookupResponse;
            } else {
                return null;
            }
        }
    }

    public async getGenericNSLookup(lookupType: ELookupType, nsType: ENonSerializableObjectType, cursor: string = null): Promise<IGetLookupResponse> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IGetGenericNSLookup = {
            ...this.rbs.getLookupRequest(null, cursor),
            lookupType,
            nsType,
        }
        let clientResponse: ClientInfraResponse = null;

        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);

        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getGenericNSLookup');
        } finally {
            if (clientResponse.executionOK) {
                return <IGetLookupResponse>clientResponse.response;
            } else {
                return null;
            }
        }
    }

    public async getCanonicals(ids: TArrayID): Promise<ILocalCanonical[]> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IBatchNonSerializableRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.unAuthenticatedLookup),
            idsNS: ids,
        }
        let clientResponse: ClientInfraResponse = null;
        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);
        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getUnauthenticatedBatchNonSerializables');
        } finally {
            if (clientResponse.executionOK) {
                let nonSers: TNonSerializableArray = (<IBatchNonSerializableResponse>clientResponse.response).nonSerializables;
                if (isValidArray(nonSers)) {
                    return nonSers as ILocalCanonical[];
                } else return [];
            } else {
                return null;
            }
        }
    }

    public async requestBatchNonSerializables<T extends INonSerializable>(ids: TArrayID, cursor: string = null): Promise<IBatchNonSerializableResponse<T>> {
        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IBatchNonSerializableRequest = {
            ...this.rbs.secureBasicRequest(apiRequestType.nonSerializable.getGeneric),
            idsNS: ids,
        }
        let clientResponse: ClientInfraResponse = null;
        clientResponse = await this.serverAPI.managedRequest(infra, request);
        return clientResponse.response as IBatchNonSerializableResponse<T>
    }



    public async getBatchNonSerializables<T extends NsTypeToInterface[NSType], NSType extends ENonSerializableObjectType>(ids: IdDep<NSType>[], shouldUseDebouncer?: boolean, idNSRemoteEnvAuthProvider?: string): Promise<T[]>;
    public async getBatchNonSerializables<T extends INonSerializable>(ids: TArrayID, shouldUseDebouncer?: boolean, idNSRemoteEnvAuthProvider?: string): Promise<T[]>;
    public async getBatchNonSerializables<T extends INonSerializable[], Value extends INonSerializable = $ValueOf<T>>(ids: TArrayID, shouldUseDebouncer?: boolean, idNSRemoteEnvAuthProvider?: string): Promise<T>;
    public async getBatchNonSerializables<T extends INonSerializable[], Value extends INonSerializable = $ValueOf<T>>(ids: TArrayID, shouldUseDebouncer: boolean = this.shouldUseDebouncer, idNSRemoteEnvAuthProvider?: string): Promise<T> {
        if (!isValidArray(ids)) {
            console.warn("Atenção! Requisição de non-serializables em lote vazia!")
            return [] as T;
        }

        if (shouldUseDebouncer) {
            return this.getNSs<T[number]>(ids, idNSRemoteEnvAuthProvider) as Promise<T>;
        }

        let requestType: string = apiRequestType.nonSerializable.getGeneric;


        const infra: IInfraParameters = this.rbs.getContextNoCallBackNoSpinnningParameters();
        const request: IBatchNonSerializableRequest = {
            ...this.rbs.secureBasicRequest(requestType),
            idsNS: ids,
        }

        if (isValidString(idNSRemoteEnvAuthProvider)) {
            request.requestType = apiRequestType.nonSerializable.getGenericEnv;
            (request as IBatchNonSerializableRemoteRequest).idNSRemoteEnvAuthProvider = idNSRemoteEnvAuthProvider;
        }

        let clientResponse: ClientInfraResponse = null;
        try {
            clientResponse = await this.serverAPI.managedRequest(infra, request);
        } catch (err) {
            throwPropagateErrorField(ESCode.server1.fromServer, ESCode.server1.f.genericError, err, 'getBatchNonSerializables');
        } finally {
            if (clientResponse?.executionOK) {
                let nonSers: TNonSerializableArray = (<IBatchNonSerializableResponse>clientResponse.response).nonSerializables;
                if (isValidArray(nonSers)) {
                    const nonSerializables: INonSerializableHeader[] = (<IBatchNonSerializableResponse>clientResponse.response).nonSerializables;
                    return <T>nonSerializables.filter(isValidRef);
                } else {
                    return <T>[];
                }
            } else {
                return <T>[];
            }
        }
    }

    public findNS() {

    }

    public createNSCacheImplementation = <T extends INonSerializable>(options: ICreateNSCacheImplementation = {}) => {
        let current: NodeJS.Timeout;
        const cache: TNSCache = new Map();

        const fn = async <NonSerializable extends INonSerializable = T>(ids: string[]): Promise<NonSerializable[]> => {
            const toFetch: string[] = ids.filter((id: string) => !(cache.has(id)));
            const nss: INonSerializable[] = [];
            if (isValidArray(toFetch)) nss.push(...await this.getBatchNonSerializables(toFetch, options.shouldUseDebouncer, options.idNSRemoteEnvAuthProvider));
            addToCache(nss);
            return ids.map((idNS: string) => cache.get(idNS)).map(typedClone) as NonSerializable[];
        };
        fn.cache = cache;
        fn.addToCache = addToCache;
        fn.getNS = this.buildSingleNSCacheGetter(fn);
        return fn;

        function addToCache(nss: INonSerializable[]): void {
            for (const ns of nss) cache.set(ns.idNS, ns);
            if (current) clearTimeout(current);
            if (options.timeout) current = setTimeout(() => cache.clear(), options.timeout);
        }
    }

    public makeRequest = async <E extends INonSerializable>(items: string[], idNSRemoteEnvAuthProvider?: string): Promise<Map<string, E>> => {
        const map: TDeepMap<[idNS: string, ns: E]> = new Map();
        const nss = await this.getBatchNonSerializables(items, false, idNSRemoteEnvAuthProvider);
        nss.map((ns: E) => map.set(ns.idNS, ns));
        return map;
    }

    public createLookup = this.createRequestDebouncer(this.makeRequest)

    public getNS: IGetNS = this.createLookup(0);
    public getNSs: IGetNSs = this.toMany(this.getNS);

    private toMany<A, B>(fn: (a: A, idNSRemoteEnvAuthProvider?: string) => Promise<B>) {
        return (a: A[], idNSRemoteEnvAuthProvider?: string): Promise<B[]> => Promise.all(a.map(item => fn(item, idNSRemoteEnvAuthProvider))).then(items => items.filter(isValidRef));
    }

    public createRequestDebouncer<E extends object>(makeRequest: (items: string[], idNSRemoteEnvAuthProvider?: string) => Promise<Map<string, E>>) {
        return (time: number): <T extends E>(id: string, idNSRemoteEnvAuthProvider?: string) => Promise<T> => {
            let timeout: NodeJS.Timeout;
            let map: Map<string, ILookupItem<E>> = new Map();

            return lookup;

            async function dispatch(idNSRemoteEnvAuthProvider?: string) {
                const previousMap = map;
                map = new Map();

                const names = [...previousMap.keys()];

                try {
                    // const idNSRemoteEnvAuthProvider: string = previousMap.get(names[0]).idNSRemoteEnvAuthProvider;
                    const response = await makeRequest(names, idNSRemoteEnvAuthProvider);
                    //
                    for (const name of names)
                        previousMap.get(name)?.resolve(response.get?.(name));
                    ;
                } catch (err: unknown) {
                    for (const name of names)
                        previousMap.get(name)?.reject(err);
                    ;
                }
            }

            function lookup<T extends E>(id: string, idNSRemoteEnvAuthProvider?: string) {
                let item = map.get(id) as Nullable<ILookupItem<T>>;
                if (item?.promise) return item.promise;

                const promise = new Promise<T>((resolve, reject) => {
                    fakeAssertsGuard<Map<string, ILookupItem<T>>>(map);
                    clearTimeout(timeout);
                    timeout = setTimeout(dispatch, time, idNSRemoteEnvAuthProvider) as unknown as NodeJS.Timeout;
                    item = {
                        id,
                        resolve,
                        reject,
                    }
                    map.set(id, item);
                });

                if (item) item.promise = promise;

                return promise;
            }
        }
    }

    public buildSingleNSCacheGetter<MultipleGetter extends <NonSerializable extends INonSerializable>(ids: string[]) => Promise<NonSerializable[]>>(multipleGetter: MultipleGetter) {
        return async <NonSerializable extends INonSerializable>(id: string) => (await multipleGetter<NonSerializable>([id]))[0];
    }

    public async getSingleLookupElement<T extends INonSerializable>(idNs: string, shouldUseDebouncer: boolean = this.shouldUseDebouncer): Promise<T> {
        if (shouldUseDebouncer) return this.getNS(idNs);
        const ret = await this.getBatchNonSerializables([idNs])
        return isValidArray(ret) ? <T>ret[0] : null;
    }

    private nsHashCacheMapGetter = this.createNSCacheImplementation();
    private nsHashCacheMap: Map<string, TNSHashCache> = new Map();

    createNSHashCache<T extends INonSerializable = INonSerializable>(namespace?: string): TNSHashCacheImplementation<T> {
        const hasNamespace: boolean = isValidString(namespace);
        let hash: TNSHashCache<T> = hasNamespace ? (this.nsHashCacheMap.get(namespace) as TNSHashCache<T>) : {};

        if (hasNamespace && !hash) {
            hash = {};
            this.nsHashCacheMap.set(namespace, hash);
        }

        return {
            hash,
            hydrateWith: (nss: T[]) => {
                objectShallowReplace(hash, arrayToHash($idNS, nss));
            },
            hydrate: async <NS = T>(idNSs: string[]): Promise<NS[]> => {
                const nsArray = await this.nsHashCacheMapGetter<T>(idNSs);

                const cleanNsArray = nsArray.filter(element => isValidRef(element));
                const hasInvalidEntries = cleanNsArray.length !== nsArray.length
                if (hasInvalidEntries)
                    console.warn("LookupService - Cache com valores undefined!");

                objectShallowReplace(hash, arrayToHash($idNS, cleanNsArray));

                return nsArray as unknown as NS[];

            },
            toArray: <NS = T>(): NS[] => {
                return values(hash) as unknown as NS[];
            }
        }
    }

    async genericNSSave<T extends INonSerializable, Arr extends INonSerializable[] = T[]>(nserList: Arr): Promise<Arr> {
        return await this.serverAPI.sendRequest<ISaveGenericNSRequest, ISaveGenericNSResponse>(apiRequestType.nonSerializable.genericNSSave)({
            nserList
        }).then((response) => {
            return response?.nserList;
        }) as Arr;
    }
}
