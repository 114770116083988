<div class="credit-card__container">
  <img [src]="brandUrl" *ngIf="showBrand" class="brand" [ngClass]="brandClasses" />
  <input
    type="text"
    *ngIf="isNormalMode"
    class="creditcard-input"
    (keyup)="onKeyUp($event)"
    #normalModeInput
    [attr.disabled]="_disabled ? '' : null"
    placeholder="{{ translations.creditCardNumber }}"
  />
  <input
    type="text"
    *ngIf="isLastFourMode"
    class="creditcard-input"
    disabled
    value="{{ lastFourValue }}"
  />
</div>
