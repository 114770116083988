<app-colmeia-window-top-bar *ngIf="isWindow" style="margin-bottom: 24px;"></app-colmeia-window-top-bar>

<section class="dashboard-page">
  <header class="header">
    <h3 class="title">
      Histórico de conversação
      <span class="helper-info" [helperInfo]="helpTipKey" [hiPopover]="true" hiHorizontalAlign="left"></span>
    </h3>
  </header>

  <cm-readonly-content [isDisabled]="isSearching" [message]="'Aguarde carregando...'">
    <div class="agent-search-div">
      <mat-form-field appearance="fill" class="width-50 no-padding-bottom">
        <mat-label>Selecione o tipo de busca</mat-label>
        <mat-select [value]="selectedHistorySearchType" (selectionChange)="searchTypeChangeEmitter($event.value)">
          <mat-option *ngFor="let type of historyType" [value]="type.value">
            {{ type.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="width-50" *ngIf="isSearchByDateInterval() || isSearchByAgent()">
        <cm-date-picker *ngIf="datePickerHandler" class="full-width no-padding-bottom" [handler]="datePickerHandler">
          <div customPrefix>
            <div [visible-vertical]="
                (customerFinder.searchingObservable$ | async) &&
                isSearchByDateInterval()
              ">
              <mat-spinner diameter="24" style="margin-right: 10px"></mat-spinner>
            </div>
          </div>
        </cm-date-picker>
      </div>
    </div>

    <mat-slide-toggle
    *ngIf="isSearchByDateInterval()"
    class="att-filter"
    style="margin-bottom: 16px; "
    [ngModel]="humanAttendanceOnly"
    (change)="onHumanConversationsOnlyChange($event)"
    >
      <span class="human-attendance__toggle_label">
        <mat-icon>support_agent</mat-icon>
        Exibir apenas clientes com atendimento humano
      </span>

    </mat-slide-toggle>


    <app-customer-finder #customerFinder [handler]="customerFindConfig" [searchType]="selectedHistorySearchType"
      *ngIf="customerFindConfig" [beginDate]="beginDateISO" [endDate]="endDateISO" [showInput]="showInput">
    </app-customer-finder>

  </cm-readonly-content>
</section>
