import { IServerColmeiaTag, ITaggable } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { IHandlerAllowingDebouncer, MainHandler } from "./main-handler";
import { IComponentParameter, IRootCallBack } from "app/model/component-comm/basic";
import { Observable, Subject as ReplaySubject } from "rxjs";

export interface ITaggableHandlerClientCallback extends IRootCallBack {
    onChangeTaggableCallback(taggable: ITaggable): void;
    onFinishSelectionCallback?(selectedTags?: IServerColmeiaTag[]);
}

export interface ITaggableHandlerParameter extends IComponentParameter, IHandlerAllowingDebouncer {
	taggable: ITaggable;
    compact?: boolean;
	clientCallback: ITaggableHandlerClientCallback;
    autoOpen?: boolean;
}

export class TaggableHandler extends MainHandler<ITaggableHandlerParameter> implements ITaggableHandlerClientCallback {
    private _taggableChange = new ReplaySubject<ITaggable>();
    public taggableChange$: Observable<ITaggable> = this._taggableChange.asObservable();

    clientCallback: ITaggableHandlerClientCallback;

    constructor(private parameters: ITaggableHandlerParameter) {
        super(parameters);

        this.clientCallback = parameters.clientCallback;
    }

    onChangeTaggableCallback(taggable: ITaggable): void {
        this._taggableChange.next(taggable);
        this.parameters.clientCallback.onChangeTaggableCallback(taggable);
    }

    getTaggable() {
        return this.parameters.taggable;
    }

}
