import { constant } from "../../../business/constant";
import { IGraphElementOffset, IGraphElementSize } from "./diagram.interfaces";

export const graphSerializables = {
    actionTreeGraphProperty: 'actionTreeGraphProperty'
}

export const actionTreePropertyFields = {
    answerText: constant.serializableField.auxiliars.aux01,
    nodeType: constant.serializableField.auxiliars.aux02,
    nodeTypeForm: constant.serializableField.auxiliars.aux03,
    nodeTypeQuestion: constant.serializableField.auxiliars.aux04
}

export const graphElementsMarginPX = 20;

export const graphElementContainerSize: IGraphElementSize = {
    width: 240,
    height: 60,
};
