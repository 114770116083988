import { ILocalNotificationsHardwareAbstraction } from "../hardware-interfaces";
import { IShortMessage } from "@colmeia/core/src/shared-business-rules/small-message/shrink-message";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class BrowserLocalNotification implements ILocalNotificationsHardwareAbstraction {
    notificate(short: IShortMessage) {}
}
