import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { IBasicToolbarElement, IToolbarElement } from '@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types';
import { isValidFunction } from '@colmeia/core/src/tools/utility';
import { DynamicComponentHandler } from 'app/components/dashboard/dashboard-data-extractor/cm-dynamic-component/cm-dynamic-component.handler';
import { DynamicDialogComponent } from 'app/components/dashboard/dashboard-data-extractor/dynamic-dialog/dynamic-dialog.component';
import { MainHandler } from 'app/handlers/main-handler';
import { IBpmGraphCreateParameters, BpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';
import { BpmGraphCreateRootElementComponent } from '../../bpm-graph-create/bpm-graph-create-root.component';
import { BpmGraphElementInfoComponent } from '../../bpm-graph-element-info/bpm-graph-element-info/bpm-graph-element-info.component';
import { BpmGraphNSPickerHandler } from '../../bpm-graph-element-info/bpm-graph-ns-picker/bpm-graph-ns-picker.handler';
import { BpmGraphToolbarElementSelectorComponent } from '../../bpm-graph-toolbar-element-selector/bpm-graph-toolbar-element-selector.component';
import { BpmGraphToolbarElementSelectorHandler } from '../../bpm-graph-toolbar-element-selector/bpm-graph-toolbar-element-selector.handler';
import { IBPMDialogResult, IOpenDialogParams } from '../diagram-editor.types';

type TDialogConfigs = Omit<MatDialogConfig, 'data'> & { panelClass?: string };


@Injectable({
    providedIn: 'root'
})
export class DiagramEditorDialogService {

    constructor(
        private dialogSvc: ColmeiaDialogService,
        private dashboardSvc: DashBoardService,
    ) { }

    public async openDialog<T = IBPMDialogResult>(params: IOpenDialogParams, dialogConfigs?: TDialogConfigs): Promise<T> {
        return this.dynamicDialog<T>(
            params.componentToOpen,
            params.componentHandler,
            (ref) => (params.componentHandler as any).setDialogRef(ref),
            dialogConfigs
        );
    }

    public async openRootNodeDialog(
        toolbarElement: IBasicToolbarElement,
        additional: IBpmGraphCreateParameters,
    ): Promise<boolean> {
        const handler: BpmGraphCreateHandler = BpmGraphCreateHandler.factory({
            clientCallback: {},
            renderData: toolbarElement.renderData,
            ...additional
        });
        const hasSaved = this.dynamicDialog<boolean>(
            BpmGraphCreateRootElementComponent,
            handler, (ref) => {handler.setDialogRef(ref)});
        return hasSaved;
    }

    public dynamicDialog<Reponse>(
        component: ComponentType<BpmGraphCreateRootElementComponent | BpmGraphElementInfoComponent | BpmGraphNSPickerHandler>,
        handler: MainHandler,
        setDialogRef?: (ref: MatDialogRef<any>) => void,
        dialogConfigs: TDialogConfigs = {}
    ): Promise<Reponse> {
        const ref = this.dialogSvc.open<
            DynamicDialogComponent,
            DynamicComponentHandler
        >({
            componentRef: DynamicDialogComponent,
            hideHeader: true,
            panelClass: dialogConfigs.panelClass,
            dataToComponent: {
                data: DynamicComponentHandler.factory({
                    component,
                    handler,
                }),
                width: "80vw",
                maxWidth: "1440px",
                minWidth: "720px",
                ...dialogConfigs,
            },
        })

        if (isValidFunction(setDialogRef)) {
            setDialogRef(ref);
        }

        return this.waitDialog<unknown, Reponse>(ref);
    }

    public waitDialog<T, R = void>(dialogRef: MatDialogRef<T>): Promise<R> {
        return new Promise((resolve) =>
            dialogRef.beforeClosed().subscribe((value: R) => {
                resolve(value || ({} as R));
            })
        );
    }

    public async requestToolbarElementChoice(title: string, toolbarElements: IToolbarElement[]): Promise<IToolbarElement> {
        return this.openDialog({
            componentToOpen: BpmGraphToolbarElementSelectorComponent,
            componentHandler: new BpmGraphToolbarElementSelectorHandler({
                title,
                toolbarElements,
                clientCallback: undefined
            })
        });
    }
}
