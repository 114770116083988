import { DatePipe } from "@angular/common";
import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { EListNonSerializablesSortOrder } from "@colmeia/core/src/dashboard-control/dashboard-request-interfaces";
import { EJobProcess } from "@colmeia/core/src/request-interfaces/message-types";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {
    EJobStatus,
    EJOBType,
    IJobCampaignExecuteServer,
    IJobDataExtractionLineToLineServer,
    IJobDownloadDatabaseUrlServer,
    IJobPatchServer,
    IJobServer,
    TMapAllJobTypes
} from "@colmeia/core/src/shared-business-rules/jobs/jobs-model";
import { IGetJobsRequestData, IRescheduleJobRequestData } from "@colmeia/core/src/shared-business-rules/jobs/jobs-req-res.dto";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { deprecatedSuggestions } from "@colmeia/core/src/tools/type-utils";
import { getFirstValue, isValidRef, isValidString, swap } from "@colmeia/core/src/tools/utility";
import { ValueOf } from "@colmeia/core/src/tools/utility-types";
import { RootComponent } from "app/components/foundation/root/root.component";
import { DatePickerHandler } from "app/handlers/date-picker.handler";
import { routeList } from "app/model/routes/route-constants";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { GenericDashboardRequester } from "app/services/generic-dashboard-requester.service";
import { LookupService } from "app/services/lookup.service";
import { EAppAlertTypes, SnackMessageService } from "app/services/snack-bar";
import { UserSettingsService } from "app/services/user-settings.service";
import {
    EGenericDashboardColumnType, IGenericDashboardCodeEditorColumn, IGenericDashboardColumn, IGenericDashboardErrorColumn, IGenericDashboardIDColumn, IGenericDashboardLinkColumn, IGenericDashboardRequestParameters,
    IGenericDashboardTagColumn,
    IGenericDashboardTextColumn,
    IGenericDashboardTimestampColumn,
    IGenericHomeRow
} from "../../dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { GenericHomeHandler } from "../../dashboard-foundation/generic-dashboard-home/generic-home.handler";
import { JobService } from "../service/job.service";


export const jobTypeToRoutMap = deprecatedSuggestions<{ [key in EJOBType]: Extract<ValueOf<typeof routeList.dashboard.children.jobs.children>, string> }>()({
    [EJOBType.patchApply]: routeList.dashboard.children.jobs.children.environmentDataMigration,
    [EJOBType.machineLearningTrainingProcessing]: routeList.dashboard.children.jobs.children.machineLearning,
    [EJOBType.campaignExecute]: routeList.dashboard.children.jobs.children.campaignExecute,
    [EJOBType.cancelRunCampaign]: routeList.dashboard.children.jobs.children.cancelCampaignSchedules,
    [EJOBType.rescheduleCampaign]: routeList.dashboard.children.jobs.children.campaignReschedules,
    [EJOBType.processElasticSearchDatabase]: routeList.dashboard.children.jobs.children.corporateSearchProcess,
    [EJOBType.fileUploadProcessor]: routeList.dashboard.children.jobs.children.fileUpload,
    [EJOBType.deleteAvatarFromSocialNetwork]: routeList.dashboard.children.jobs.children.deleteAvatarFromSN,
    [EJOBType.downloadDatabaseUrl]: routeList.dashboard.children.jobs.children.fileDownload,
    [EJOBType.deleteNS]: routeList.dashboard.children.jobs.children.deleteNS,
    [EJOBType.deleteFile]: routeList.dashboard.children.jobs.children.deleteFile,
    [EJOBType.deleteFileContents]: routeList.dashboard.children.jobs.children.deleteFileContents,
    [EJOBType.processUntrainedUtterances]: routeList.dashboard.children.jobs.children.deleteFileContents,
    [EJOBType.patchDependencyResolution]: routeList.dashboard.children.jobs.children.patchDependencyResolution,
    [EJOBType.sendAllProvidersToReview]: routeList.dashboard.children.jobs.children.sendAllProvidersToReview,
    [EJOBType.checkTemplateApprovals]: routeList.dashboard.children.jobs.children.checkTemplateApprovals,
    [EJOBType.backupCreateSnapshot]: routeList.dashboard.children.jobs.children.backupCreateSnapshot,
    [EJOBType.backupRestoreSnapshot]: routeList.dashboard.children.jobs.children.backupRestoreSnapshot,
    [EJOBType.dataExtractionLineToLine]: routeList.dashboard.children.jobs.children.dataExtraction,
    [EJOBType.userGroupProfileUpdate]: routeList.dashboard.children.jobs.children.userGroupProfileUpdate,
    [EJOBType.generativeTrainingIntentProcessing]: routeList.dashboard.children.jobs.children.generativeTrainingIntentProcessing,
    [EJOBType.approveSocialNetworkParticipationInBatch]: routeList.dashboard.children.jobs.children.approveSocialNetworkParticipationInBatch,
    [EJOBType.updateWAFSchema]: routeList.dashboard.children.jobs.children.wafSchemaUpdate,
    [EJOBType.migrateWhatsAppSMProvider]: routeList.dashboard.children.jobs.children.migrateWhatsAppSMProvider,
    [EJOBType.fetchContentFromURLsInVector]: routeList.dashboard.children.jobs.children.fetchContentFromURLsInVector,
}).ok;

export const JOBS_DATA_MIGRATION_JOB_ID_PARAM = "jobId"

@Component({
    selector: "app-jobs",
    templateUrl: "./jobs.component.html",
    styleUrls: ["./jobs.component.scss"],
})
export class JobsComponent
    extends RootComponent<"name">
    implements OnInit, AfterViewInit {
    public ghomeHandler: GenericHomeHandler;
    public nsType = ENonSerializableObjectType.job;
    public job: IJobServer;
    public jobStarted: string;
    public jobEnded: string;
    private jobType: EJOBType;
    private selectedDialogRef: MatDialogRef<TemplateRef<any>>;
    public loading: boolean = false;
    public idActionColumn: string = "ID da ação"
    public idRunCampaingColumn: string = "ID do disparo"


    @ViewChild("viewer")
    viewerRef: TemplateRef<any>;

    @ViewChild("reschedule")
    public rescheduleRef: TemplateRef<any>;

    public getNSs = this.lookupSvc.createNSCacheImplementation();
    public getNS = this.lookupSvc.buildSingleNSCacheGetter(this.getNSs);
    public forcedTitles: string[] = []

    constructor(
        private lookupSvc: LookupService,
        private requester: GenericDashboardRequester,
        private settingsSvc: UserSettingsService,
        private dialogSvc: MatDialog,
        private route: ActivatedRoute,
        private jobService: JobService,
        private dashboardSvc: DashBoardService,
        private snackSvc: SnackMessageService,
    ) {
        super({
            name: gTranslations.common.name,
        });
    }


    ngOnInit(): void {
        this.dashboardSvc.updateCurrentDashboardByRoute();
        this.init();
    }


    public mapJobTypeToRoute = jobTypeToRoutMap;

    public mapJobRouteToType = swap(this.mapJobTypeToRoute)

    public async init(): Promise<void> {
        await this.dashboardSvc.waitSetCurrentDashboarMenuItem();

        this.jobType = this.mapJobRouteToType[this.route.snapshot.url[0].path];
        this.initTitles()
        this.loadGHomeHandler();
    }

    initTitles() {
        this.forcedTitles = ["ID", this.translations.name.value, "Status", "Fase", "Erro", "Agendado para", "Início", "Fim"]

        if (this.jobType === EJOBType.dataExtractionLineToLine) {
            this.forcedTitles.push('SQL Query');
        }
        if (this.jobType === EJOBType.downloadDatabaseUrl || this.jobType === EJOBType.dataExtractionLineToLine) {
            this.forcedTitles.push('Download Url');
        }
        if (this.jobType === EJOBType.campaignExecute) this.forcedTitles.push(this.idActionColumn, this.idRunCampaingColumn);
    }

    public ngAfterViewInit() {
        this.openViewIfNeed();

        this.setJob({
            idNS: "DMWVMZbEtiCg1l2RlAlWAxtVzxLDvI",
            isVirtual: false,
            ident: {
                genealogy: ["SiVGIlSZH1fSCI4z9rwzLZCNnWPqbG"],
                idAvatar: "ZWaZyJfmOmOxigoAV3q7itaxRKea2n",
                idGroup: "SiVGIlSZH1fSCI4z9rwzLZCNnWPqbG",
            },
            nsType: ENonSerializableObjectType.job,
            nName: "deploy1",
            clockTick: 1623418773344,
            lastTouch: 1623418773375,
            scheduleAt: 0,
            ended: 0,
            // idPatch: "txwMfIBnYicgqQdjqeuMeVuJldqs9juz",
            // idProvider: "N98ofEbDuRnnz1175O8jWNgrqqsXokM3",
            jobType: EJOBType.patchApply,
            lastJobTouch: 1623418773344,
            started: 0,
            phaseName: "to be started",
            isRoot: true,
            error: undefined,
            isError: false,
            status: EJobStatus.tobestarted,
            idPlayerStartedList: [],
            isJobStillInProgress: true,
        });
        return this.openViewer();
    }

    private async openViewIfNeed() {
        const jobId = this.route.snapshot.queryParamMap.get(JOBS_DATA_MIGRATION_JOB_ID_PARAM);

        if (!isValidString(jobId)) return;

        let job: IJobServer = this.ghomeHandler.getNSList().find(({ idNS }) => idNS === jobId) as IJobServer;

        this.job = isValidRef(job) ? job : await this.getNS<IJobServer>(
            this.job.idNS
        );

        if (isValidRef(this.job)) {
            this.openViewer();
        }
    }

    private setJob(job: IJobServer) {
        this.job = job;
        this.jobStarted =
            job.started === 0 ? "Não iniciado" : this.getDate(job.started);
        this.jobEnded =
            job.ended === 0 ? "Não finalizado" : this.getDate(job.ended);
    }

    public openViewer() {
        // this.selectedDialogRef = this.dialogSvc.open(this.viewerRef, {
        //     panelClass: "small-size",
        // });
    }

    public loadGHomeHandler() {
        const notRescheduleAllowdJobs: EJOBType[] = [
            EJOBType.cancelRunCampaign,
            EJOBType.rescheduleCampaign,
            EJOBType.campaignExecute
        ];

        this.ghomeHandler = new GenericHomeHandler({
            nsType: this.nsType,
            forcedTitles: this.forcedTitles,
            fixedCallbacks: {
            },
            fixedRowCallbacks: {
                custom: notRescheduleAllowdJobs.includes(this.jobType) ? [] : [
                    {
                        custom: {
                            icon: 'schedule',
                            text: "Reschedular",
                        },
                        callback: this.onRescheduleClick
                    }
                ],
                // edit: {
                //     custom: {
                //         text: "Ver",
                //         icon: "visibility",
                //     },
                //     callback: this.onEditClick,
                // },
                deploy: false,
                copyID: true,
                checkDependencies: false,
                delete: true,
            },
            customRowCallbacks: {
                delete: this.delete,
            },
            labels: {
                title: this.dashboardSvc.getCurrentDashboarMenuItem()?.getName(),
            },
            clientCallback: this,
            ordination: {
                allow: true,
                default: {
                    lastTouch: EListNonSerializablesSortOrder.Descending
                }
            }
        });
    }

    rescheduleSource: IRescheduleJobRequestData;

    public resetRescheduleSource(): void {
        this.rescheduleSource = {
            idJob: '',
            toMS: 0,
        };
    }

    public delete = async (job: IJobServer): Promise<void> => {
        await this.jobService.delete(job.idNS)
    }

    rescheduleDialog: MatDialogRef<any>;

    public onRescheduleClick = async (nser: IJobServer): Promise<void> => {
        this.resetRescheduleSource();
        this.initReschedulePickerHandler();
        this.rescheduleSource.idJob = nser.idNS;
        this.rescheduleDialog = this.dialogSvc.open(this.rescheduleRef, {
            panelClass: "small-size",
        });
    }

    public rescheduleToPicker: DatePickerHandler;
    public initReschedulePickerHandler(): void {
        this.rescheduleToPicker = new DatePickerHandler({
            onPick: (number: number) => this.rescheduleSource.toMS = number,
            current: this.rescheduleSource.toMS,
        });
    }

    public openSuccess = this.snackSvc.openDefaultMessage(EAppAlertTypes.Success);
    public openError = this.snackSvc.openDefaultMessage(EAppAlertTypes.Error);

    public async onReschedule(): Promise<void> {

        if (!this.rescheduleSource.idJob) {
            this.snackSvc.openError('Sem job selecionado.');
            return;
        }
        if (!this.rescheduleSource.toMS) {
            this.snackSvc.openError('Sem data selecionada.');
            return;
        }

        await this.jobService.reschedule(this.rescheduleSource.idJob, this.rescheduleSource.toMS);
        this.rescheduleDialog.close();
    }

    public onEditClick = async (nser: IJobServer): Promise<void> => {
        this.setJob(nser);
        this.openViewer();
    };

    public getRequest({ cursors, perPage }: IGenericDashboardRequestParameters): IRequest {
        return this.requester.getRequestForNS<IGetJobsRequestData>({
            apiRequestType: EJobProcess.getAll,
            cursor: getFirstValue(cursors),
            amountItemsPerPage: perPage,
            nsType: this.nsType,
            jobType: this.jobType,
        });
    }

    public isRunCampaignJob(nser: IJobServer): nser is IJobCampaignExecuteServer {
        return nser.jobType === EJOBType.campaignExecute
    }


    private getJobName(nser: IJobServer): string {
        if (this.isRunCampaignJob(nser) && nser.nName === nser.jobType) {
            return nser.campaign.header.nName;
        }

        if (nser.jobType === EJOBType.patchApply && (nser as IJobPatchServer).newDeployDescription?.name) {
            return (nser as IJobPatchServer).newDeployDescription.name;
        }

        return nser.nName;
    }


    public mapEntity = (nser: IJobServer): IGenericHomeRow => {
        const isRunCampaignJob = this.isRunCampaignJob(nser);
        const columns: IGenericDashboardColumn[] = [
            <IGenericDashboardIDColumn>{
                type: EGenericDashboardColumnType.ID,
                title: "ID",
                value: nser.idNS,
            },
            <IGenericDashboardTextColumn>{
                type: EGenericDashboardColumnType.Text,
                title: this.translations.name.value,
                value: this.getJobName(nser),
                isExpanded: true,
                isItemName: true,
            },
            <IGenericDashboardTagColumn>{
                type: EGenericDashboardColumnType.Tag,
                title: "Status",
                value: nser.status,
            },
            <IGenericDashboardTagColumn>{
                type: EGenericDashboardColumnType.Tag,
                title: "Fase",
                value: nser.phaseName,
            },
            <IGenericDashboardErrorColumn>{
                type: EGenericDashboardColumnType.Error,
                title: "Erro",
                value: nser.error || "",
            },
            <IGenericDashboardTimestampColumn>{
                type: EGenericDashboardColumnType.Timestamp,
                title: "Agendado para",
                value: isRunCampaignJob ? nser.campaign.header.scheduleBeginning : nser.scheduleAt,
            },
            <IGenericDashboardTimestampColumn>{
                type: EGenericDashboardColumnType.Timestamp,
                title: "Início",
                value: nser.started,
            },
            <IGenericDashboardTimestampColumn>{
                type: EGenericDashboardColumnType.Timestamp,
                title: "Fim",
                value: nser.ended,
            },
        ];

        if (this.isRunCampaignJob(nser)) {
            columns.push(<IGenericDashboardIDColumn>{
                type: EGenericDashboardColumnType.ID,
                title: this.idActionColumn,
                value: nser.campaign.header.idFiredAction,
            }, <IGenericDashboardIDColumn>{
                type: nser?.lastPredicates?.idCampaignExecution ? EGenericDashboardColumnType.ID : EGenericDashboardColumnType.Text,
                title: this.idRunCampaingColumn,
                value: nser?.lastPredicates?.idCampaignExecution ?? `Sem ${this.idRunCampaingColumn}`,
            })
        }

        if (this.jobType === EJOBType.downloadDatabaseUrl) {
            const job: IJobDownloadDatabaseUrlServer = <IJobDownloadDatabaseUrlServer>nser
            columns.push(<IGenericDashboardLinkColumn>{
                type: EGenericDashboardColumnType.Link,
                title: "Download Url",
                label: isValidString(job.downloadUrl) ? "Download" : '',
                link: job.downloadUrl || "#",
            })
        }

        if (this.jobType === EJOBType.dataExtractionLineToLine) {
            const job: IJobDataExtractionLineToLineServer = <IJobDataExtractionLineToLineServer>nser;
            // Coluna comentada a pedido do Joi
            /* if (isValidString(job.sqlQuery))
                columns.push(<IGenericDashboardCodeEditorColumn>{
                    type: EGenericDashboardColumnType.CodeEditor,
                    title: "SQL Query",
                    value: job.sqlQuery || "",
                    label: "Ver Query",
                    icon: "manage_search",
                    language: "sql",
                }); */
            if (isValidString(job.csvURL))
                columns.push(<IGenericDashboardLinkColumn>{
                    type: EGenericDashboardColumnType.Link,
                    title: "Download Url",
                    label: isValidString(job.csvURL) ? "Download" : '',
                    link: job.csvURL || "#",
                });
        }

        return {
            columns,
            nser,
        };
    };

    public getDate(clocktick: number): string {
        if (clocktick == 0) {
            return null
        }
        return new DatePipe(this.settingsSvc.getSelectedLocale()).transform(
            clocktick,
            this.settingsSvc.getDateHourFormat()
        );
    }

    public async refreshSelecedJob() {
        this.loading = true;
        this.selectedDialogRef.disableClose = true;
        const freshJobData = await this.getNS<IJobServer>(
            this.job.idNS
        );
        this.loading = false;
        this.selectedDialogRef.disableClose = false;

        this.setJob(freshJobData);
    }
}
