import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { ICRMSupervisionAllocatedData, ICRMSupervisionTransferTicketParamsBase, ICRMTicketGetTicketsToBeAllocatedParams, ICRMTicketSupervisionQueueData, IGetTicketSupervisionParams } from "./crm-supervision.model";
import { TGlobalUID } from "@colmeia/core/src/business/constant";
import { ICRMTransferTicketParamsBase } from "./crm-runtime-interfaces";

export enum ECRMTicketSupervisionRequests {
    getAllocatedTickets = 'crm.sv.getAllocatedTickets',
    getQueuesStatus = 'crm.sv.getQueuesStatus',
    transferTicket = 'crm.sv.transferTicket',
}

export interface ICRMTicketGetSupervisionAllocatedTicketsRequest extends IRequest {
    requestType: ECRMTicketSupervisionRequests.getAllocatedTickets;
    ticketQuery: IGetTicketSupervisionParams;
    idAvatarAgent?: TGlobalUID;
}

export interface ICRMTicketGetSupervisionAllocatedTicketsResponse extends IResponse, ICRMSupervisionAllocatedData {
    responseType: ECRMTicketSupervisionRequests.getAllocatedTickets;
}

export interface ICRMSVTicketQueuesStatsRequest extends IRequest, ICRMTicketGetTicketsToBeAllocatedParams {
    requestType: ECRMTicketSupervisionRequests.getQueuesStatus;

}

export interface ICRMSVTicketGetQueuesStatsResponse extends IResponse {
    responseType: ECRMTicketSupervisionRequests.getQueuesStatus;
    data: ICRMTicketSupervisionQueueData;
}


export interface ICRMSVTransferTicketRequest extends IRequest, ICRMSupervisionTransferTicketParamsBase {
    requestType: ECRMTicketSupervisionRequests.transferTicket;
}

export interface ICRMSVTransferTicketResponse extends IResponse {
    responseType: ECRMTicketSupervisionRequests.transferTicket;
}


export interface ICRMSVGetPackagesRequest extends IRequest, ICRMSupervisionTransferTicketParamsBase {
    requestType: ECRMTicketSupervisionRequests.transferTicket;
}

export interface ICRMSVTransferTicketResponse extends IResponse {
    responseType: ECRMTicketSupervisionRequests.transferTicket;
}
