import { Component } from '@angular/core';
import { SettingsModalService } from '../../settings-modal/settings-modal.service';
import { SessionService } from 'app/services/session.service';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { ProfileAvatarStoreService } from 'app/services/profile-avatar-store.service';

@Component({
    selector: 'app-avatar-info-edit',
    templateUrl: './avatar-info-edit.component.html',
    styleUrls: ['./avatar-info-edit.component.scss']
})
export class AvatarInfoEditComponent {

    public avatarName: string;

    public constructor(
        private settingsModalSvc: SettingsModalService,
        private sessionSvc: SessionService,
        private profileAvatarStoreSvc: ProfileAvatarStoreService,
    ) { }

    public ngOnInit(): void {
        this.init();
    }
    
    public init(): void {
        this.avatarName = this.sessionSvc.getSelectedAvatar().getName();
    }

    public isNewAvatarName(): boolean {
        return this.avatarName !== this.sessionSvc.getSelectedAvatar().getName();
    }

    public async save(): Promise<void> {
        if (this.isNewAvatarName()) {
            const response = await this.profileAvatarStoreSvc.editAvatarName(this.avatarName);
            
        }
    }

}
