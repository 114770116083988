<app-alert *ngIf="hasStorageExpiredUrl else itemVisualization" type="warning" [hiddenIcon]="!!onStorageRenewClick">
  <div>
    Desculpe, mas a visualização desse item expirou.
    <ng-container *ngIf="onStorageRenewClick">
      <br>
      <br>
      <button mat-button (click)="onStorageRenewClickWrapper()">Clique aqui parar gerar uma nova</button>
    </ng-container>
  </div>
</app-alert>
  
<ng-template #itemVisualization>
  <div class="wrapper">
    <div class="full-height image-container"
      *ngIf="isImage() && (getMultimediaUrl() | imgSecureDownload | async) as multimediaUrl">
      <ng-container *ngIf="isImage() && multimediaUrl">
        <img class="media image" [ngStyle]="getStyles()" [src]="multimediaUrl" />
      </ng-container>
    </div>

    <div class="full-height" *ngIf="canShowStreamable()" style="position: relative;">
      <audio *ngIf="isAudio()" [ngStyle]="getStyles()" class="media audio" preload="metadata" controls>
        <source [src]="streamableSignedUrl" [type]="getMimeType()" />
      </audio>
      <video #videoPlay *ngIf="isVideo()" [ngStyle]="getStyles()" class="media video" preload="metadata" controls>
        <source #videoSource [src]="streamableSignedUrl" [type]="getMimeType()" />
      </video>
    </div>

    <app-alert *ngIf="isUnknown()" hideIcon darker type="warning" style="margin: 8px">
      <div>
        <strong>Desculpe.</strong><br>
        <ng-container *ngIf="getMimeType() as mime; else reallyUnknown">
          Esse tipo de arquivo não é suportado:
          <br><br>
          <span class="mime-type">{{ getMimeType() }}</span>
        </ng-container>

        <ng-template #reallyUnknown>Não conseguimos identificar o tipo desse arquivo.</ng-template>
      </div>
    </app-alert>

    <div class="full-height document" *ngIf="isDocument">
      <div class="vcard" *ngIf="isVCard; else documentPresentation">
        <cm-vcard-presenter [cardMultimedia]="mmInstance"></cm-vcard-presenter>
      </div>
      <ng-template #documentPresentation>
        <iframe *ngIf="isPDF && safeResourceUrl" class="pdf-preview-container nice-scroll" [src]="safeResourceUrl"
          frameborder="0"></iframe>
      </ng-template>
    </div>

    <ng-container *ngIf="isUnhandled()">
      <a class="unhandled-link" [href]="safeResourceUrl" [download]="fileName">
        {{ fileName }}
      </a>
    </ng-container>
    
  </div>
</ng-template>