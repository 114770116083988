import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { ETypeOfContact, ICheckedContact, TCheckedContactArray } from '@colmeia/core/src/comm-interfaces/business-interfaces';
import { EStartServiceChatMoreOptions } from '@colmeia/core/src/core-constants/constant-visual-elements.enums';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { FinishServiceCall } from '@colmeia/core/src/interaction/service-group/finish-service';
import { StartServiceChat } from '@colmeia/core/src/interaction/service-group/start-service-chat';
import { getNoDDDPhone } from '@colmeia/core/src/shared-business-rules/social-cc/config-cell';
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { AttendanceActiveEditHelperService } from 'app/services/attendance-active-edit-call-init.service';
import { AttendanceService } from 'app/services/attendance.service';
import { GroupShortcutHandlerService } from 'app/services/group-shortcut-handler.service';
import { GroupChatServices } from "../../../../../services/group-chat.services";
import { SessionService } from "../../../../../services/session.service";
import { IMessageInstanceSelectChosenOptionStandardMsg } from '../message-instance.service';

@Injectable({
    providedIn: 'root'
})
export class GroupServiceMenuItemsService {
    constructor(
        private sessionSvc: SessionService,
        private chatSvc: GroupChatServices,
        private attendanceSVC: AttendanceService,
        private activeCallService: AttendanceActiveEditHelperService,
        private matDialogSvc: MatDialog,
        private groupShortchutSvc: GroupShortcutHandlerService,
    ) { }

    async onDropdownOptionClicked(option: IMessageInstanceSelectChosenOptionStandardMsg) {
        if (option.optionSelected.is(EStartServiceChatMoreOptions.Finish)) {
            const interaction: StartServiceChat = option.interactionClicked as StartServiceChat;
            this.finishAttendance(interaction);
            return;
        }

        if (option.optionSelected.is(EStartServiceChatMoreOptions.OpenActiveCall)) {
            const idGroup: TGlobalUID = this.sessionSvc.getSelectedGroupID();
            const attendanceInteraction: Interaction = Interaction.staticFactory(
                this.attendanceSVC.getReplyInteractionForGroup(idGroup)
            );
            const costumerContacts: TCheckedContactArray = attendanceInteraction.getParticipant().getAvatar().getContacts();
            let selectedContact: ICheckedContact = costumerContacts.length === 1 ? costumerContacts[0] : null;

            if (!isValidRef(selectedContact)) {
                selectedContact = (await this.activeCallService.openContactSelector(costumerContacts))[0];
            }

            if (!isValidRef(selectedContact)) return;

            this.activeCallService.openDialog(
                selectedContact.type === ETypeOfContact.mobile
                    ? getNoDDDPhone(selectedContact.address)
                    : selectedContact.address
            );
        }
    }

    async finishAttendance(attendanceInteraction: StartServiceChat): Promise<void> {
        const groupId: string = this.sessionSvc.getSelectedGroupID();

        if (attendanceInteraction.isFinished(groupId)) {
            return;
        }

        this.attendanceSVC.finishAttendanceInit(groupId);

        const finishInteraction: FinishServiceCall = FinishServiceCall.factory(
            this.sessionSvc.getSelectedParticipant(),
            attendanceInteraction,
        );

        const saveInteractionResponse = await this.chatSvc.safeSaveInteraction(finishInteraction);

        if (saveInteractionResponse.isAlreadyAttendanceFinished) {
            this.matDialogSvc.closeAll();
        }
        return;
    }
}
