import { SimpleChange } from "@angular/core";
import { DefineReadableType } from "@colmeia/core/src/tools/utility-types";


export type ToSimpleChangeItem<T, Key extends keyof T> = DefineReadableType<SimpleChange, { 
    previousValue: T[Key];
    currentValue: T[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
}>
export type ToSimpleChange<T extends object> = { [key in keyof T]: ToSimpleChangeItem<T, key> };


export enum EFormControlStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    PENDING = 'PENDING',
    DISABLED = 'DISABLED',
}