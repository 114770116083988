<div class="menu-container" *ngIf="interaction" [class.rendered]="viewInitialized">
  <h3 class="menu-title" [innerHTML]="titleHTML"></h3>
  <ng-container [ngSwitch]="menuMode">
    <div *ngSwitchCase="EMenuMode.Full" class="carrousel-wrapper">
      <ng-container *ngIf="menuOptions.length">
        <div class="carrousel-container">
          <swiper
            #swiper
            class="mat-card-swiper menu-container-swiper"
            [class.has-answer]="hasChoice"
            [pagination]="true"
            [navigation]="true"
            [virtual]="true"
            (slideChange)="onSlideChange($event)"
          >
          <ng-template swiperSlide *ngFor="let option of menuOptions">
            <mat-card class="carrousel-item" [style.opacity]="hasChoice && !isCurrentReply(option) ? '0.4' : null">
              <div class="loading-container" *ngIf="option.media?.idMedia && !option.fileUrl">
                <mat-spinner mode="indeterminate" diameter="20" color="accent"></mat-spinner>
              </div>
              <img mat-card-image *ngIf="option.fileUrl" [src]="option.fileUrl">
              <mat-card-content>
                <p class="option-title">
                  {{ option.title }}
                </p>
              </mat-card-content>
            </mat-card>
          </ng-template>
          </swiper>
        </div>
        <button
          mat-flat-button
          [disabled]="hasChoice"
          class="carrousel-bottom-button"
          color="primary"
          style="margin-bottom: 0;"
          (click)="choiceSelected()">
          Escolher
        </button>
        <mat-divider *ngIf="menuOptionsWithoutMedia.length"></mat-divider>
      </ng-container>
      <button
        *ngFor="let option of menuOptionsWithoutMedia"
        mat-stroked-button
        color="primary"
        [attr.disabled]="hasChoice"
        [style.pointer-events]="hasChoice ? 'none' : null"
        class="carrousel-bottom-button"
        [style.opacity]="hasChoice && !isCurrentReply(option) ? '0.6' : null"
        [class.mat-flat-button]="isCurrentReply(option)"
        (click)="chooseOption(option)">
        {{ option.title }}
      </button>
    </div>
    <ul *ngSwitchDefault class="menu-options" [class.has-choice]="hasChoice">
      <ng-container *ngFor="let option of menuOptions; index as itemIndex">
        <li *ngIf="showOption(option);" class="menu-option-container" [style.transition-delay]="itemIndex * 120 + 'ms'">
          <button
            class="menu-option noselect"
            [class.current-reply]="isCurrentReply(option)"
            [disabled]="hasChoice && !isCurrentReply(option)"
            (click)="chooseOption(option);"
          >
            <mat-spinner *ngIf="loading && hasChoice && isCurrentReply(option)" color="accent" diameter="18"></mat-spinner>
            {{ option.title}}
          </button>
        </li>
      </ng-container>
    </ul>
  </ng-container>
</div>
