import {Role} from '../security/role'
import * as Utility from '../tools/utility';
import {IGroupTypeJSON} from '../comm-interfaces/business-interfaces';
import {Serializable} from './serializable';
import {constant, TGlobalUID} from './constant';
import {UberCache} from '../persistency/uber-cache';
import { SecuritySettings } from '../security/security-settings';
import { SecurityItem } from '../security/security-item';

type TGroupTypeArray = Array<GroupType>;  // esse tipo ficará dentro da instancia de GroupType

export class GroupType extends Serializable{
    private static allGroupTypes: TGroupTypeArray = [];
    private supportGroup        : boolean;
    private root:   boolean;
    private functionalRoot: boolean;
    private acceptParamenters: boolean;
    private adminOnlyParticipants: boolean;
    private allwaysSameParentParticipant: boolean;

    private groupTypeArraySupport  : TGroupTypeArray;
    private firstParticipantRole : Role;
    private firstAdminRoleParticipant: Role; 
    private idSecurityProfile: number;
    

    private defaultSecuritySettings: SecuritySettings;

    public toJSON(): IGroupTypeJSON {
        let json: IGroupTypeJSON = {...super.toJSON(),
            supportGroup: this.supportGroup,
            root: this.root,
            functionalRoot: this.functionalRoot,
            idSecurityProfile: this.idSecurityProfile,
            groupTypeSupportArray: [],
            firstParticipantRole: this.firstParticipantRole.getRoleID(),
            firstAdminRoleParticipant: this.firstAdminRoleParticipant.getRoleID(),
            securitySettings: this.defaultSecuritySettings.toJSON(),
            acceptParamenters: this.acceptParamenters,
            adminOnlyParticipants: this.adminOnlyParticipants,
            allwaysSameParentParticipant: this.allwaysSameParentParticipant,
        };
        
        for (let r of this.groupTypeArraySupport) {
            json.groupTypeSupportArray.push(r.toJSON());
        };
        return json; 
    };
    
    private constructor(json: IGroupTypeJSON) {
        super(constant.objectType.groupType, json.primaryID);
        super.rehydrate(json);
        this.functionalRoot = json.functionalRoot;
        this.supportGroup = json.supportGroup;
        this.idSecurityProfile = json.idSecurityProfile;
        this.firstParticipantRole = Role.staticFactory(json.firstParticipantRole);
        this.firstAdminRoleParticipant = Role.staticFactory(json.firstAdminRoleParticipant);
        this.root = json.root,
        this.groupTypeArraySupport = [];
        this.acceptParamenters = json.acceptParamenters;
        this.allwaysSameParentParticipant = json.allwaysSameParentParticipant;
        this.adminOnlyParticipants = json.adminOnlyParticipants;

        // configuração default dos grupos que irão ser criados desse tipo específico
        this.defaultSecuritySettings = new SecuritySettings(Utility.getUniqueStringID());

        GroupType.allGroupTypes.push(this);
        
    };

    public getGroupTypeID(): TGlobalUID {return super.getPrimaryID();};
    public getGroupTypeName(): string {return super.getName();}
    public getSupportGroupTypeArray(): TGroupTypeArray {return this.groupTypeArraySupport; };
    public getFirstParticipantRole(): Role {return this.firstParticipantRole;};
    public getFirstAdminRole(): Role {return this.firstAdminRoleParticipant}
    
    public isRoot(): boolean {return this.root;};
    public isFunctionalRoot(): boolean {return this.functionalRoot;};
    public isSupportGroup(): boolean {return this.supportGroup;};   
    public isFunctional(): boolean {return this.is(constant.groupType.functional.distributionList,
                                                    constant.groupType.functional.featureCreator,)};
    
    public isSpecialDisplay(): boolean {
        return this.isFunctional() || 
            this.is(constant.groupType.functional.broadcast,  constant.groupType.functional.spokenGroup)
    }
                                                
    public willLookForParent() : boolean {
        return ! (this.isRoot() || this.isFunctionalRoot() && this.isSupportGroup())
    };

    
    public isCommon(): boolean {
        return !this.isRoot() && !this.isFunctionalRoot() && !this.isSupportGroup() && !this.isFunctional() && !this.isPersonal();
    };
    
    public isPersonal(): boolean {
        return this.is(constant.groupType.functionalRoot.playerPersonalGroup, 
                            constant.groupType.personalGroupTypes.privateMessage,
                            constant.groupType.personalGroupTypes.avatarPersonalGroup)
    };

    public isPersonalRoot(): boolean {
        return this.is(constant.groupType.functionalRoot.playerPersonalGroup);
    }

    public isFirtstLevelDisplayCandidate(): boolean {
        return this.isFunctionalRoot() && ! this.isPersonal();
    };


    public allowSecurityParameters(): boolean {return this.acceptParamenters;};
    
    public isAlwaysSameParentParticipants(): boolean { return this.allwaysSameParentParticipant;};
    public isAdminOnlyParticipants(): boolean {return this.adminOnlyParticipants;};

    public getIDSecurityProfile(): number {return this.idSecurityProfile;};

    public addSecuritySettingItem(securityItem: SecurityItem): void {
        this.defaultSecuritySettings.addHash(securityItem);
    };

    public addSupportGroupType(groupType: GroupType): void {this.groupTypeArraySupport.push(groupType);};

    public showServiceGroupManifest(): boolean {
        return this.is(constant.groupType.standard.standardGroup, constant.groupType.functional.broadcast, constant.groupType.functional.distributionList)
    }

    public getSecuritySettings(): SecuritySettings {return this.defaultSecuritySettings;};

    public rehydrate(json: IGroupTypeJSON) {
        this.groupTypeArraySupport = [];
        for (let gType of json.groupTypeSupportArray)
            this.addSupportGroupType(GroupType.staticFactory(gType.primaryID));
        
        if (json.securitySettings) {
            for (let sec of json.securitySettings.securityItems) {
                this.defaultSecuritySettings.addHash(SecurityItem.factoryMessage(sec));
            };
        };
    };
    
    public static plainFactoryMessage(json: IGroupTypeJSON): GroupType {
        let gType: GroupType = <GroupType>UberCache.uberFactory(json.primaryID, constant.objectType.groupType, false);
        if (gType == null) 
            gType = new GroupType(json);
        return gType;
    };

    public static factoryMessage(json: IGroupTypeJSON): GroupType {
        let gType: GroupType = GroupType.staticFactory(json.primaryID);
        gType.rehydrate(json);
        return gType;
    };

    
    public static getAllGroupTypes(): TGroupTypeArray {return GroupType.allGroupTypes}

    
    public static staticFactory(idGroupType: TGlobalUID): GroupType {
        return <GroupType>UberCache.uberFactory(idGroupType, constant.objectType.groupType, true);
    };
    
    public static getGroupTypeArray(): TGroupTypeArray {
        return <TGroupTypeArray>UberCache.getSerializableArray(constant.objectType.groupType);
    };

    public static resetAllGroupTypesArray() {
        GroupType.allGroupTypes = [];
    }
};
