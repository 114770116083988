<div class="feedback-dialog__container">
  <header>
    <h3>{{ translate(labels.reactions) }}</h3>
    <button [mat-dialog-close]="true">
      <i class="material-icons clickable" >close</i>
    </button>
  </header>
  <hr>

  <nav>
    <ul>
      <li
        *ngFor="let feedback of feedbacks"
        (click)="setFilter(feedback.serializable.primaryID)"
        [ngClass]="{'current' : _isCurrentFilter(feedback.serializable.primaryID )}"
        >
        <span>
          {{ (feedback.real) ? feedback.serializable.getEmoji() : ""}}
        </span>
        <p class="mobile--amount">
          {{ feedback.real ? '' : translate(labels.all) }}
          ({{ feedback.amount }})
        </p>
        <p>
          {{ feedback.serializable.getName() }}({{ feedback.amount }})
        </p>
      </li>
    </ul>
  </nav>

  <section>
    <ul #scrolling>
      <li *ngFor="let avatar of filteredAvatars">
        <a (click)="goToAvatar(avatar)" class="avatar__container">
          <hexagon [hexagonHandler]="_makeHexagonHandler(avatar)"></hexagon>
          <div class="avatar--name">
            <p>
              {{ avatar.avatarName }}
            </p>
            <span>
              {{ _friendlyTime(avatar.clockTick) }}
            </span>
          </div>
        </a>
      </li>
    </ul>
  </section>
</div>
