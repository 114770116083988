import { ICardFilter, ICardSorter } from "@colmeia/core/src/shared-business-rules/kanban/kanban-shared-model";
import { hourToMS } from "@colmeia/core/src/time/time-utl";

const hours24InMS = hourToMS(24);

export enum EKanbanStandardCardFilters {
    recentlyUpdated = 'recentlyUpdated',
}

export enum EKanbanStandardCardSort {
    lastUpdate = 'lastUpdate',
    alphabeticalOrder = 'alphabeticalOrder',
}

export const kanbanStandardCardFilters: Record<EKanbanStandardCardFilters, ICardFilter> = {
    [EKanbanStandardCardFilters.recentlyUpdated]: {
        filterId: EKanbanStandardCardFilters.recentlyUpdated,
        label: "Recentemente atualizado",
        filterFn: (card) => {
            const now = Date.now();
            const lastClockTick = card.getData().updatedAt;
            const updated = now - lastClockTick;

            return updated < hours24InMS;
        }
    }
}

export const kanbanStandardCardSort: Record<EKanbanStandardCardSort, ICardSorter> = {
    [EKanbanStandardCardSort.lastUpdate]: {
        sortId: EKanbanStandardCardSort.lastUpdate,
        label: "Última atualização",
        sortFn: (cardA, cardB) => {
            return cardB.getData().updatedAt - cardA.getData().updatedAt;
        }
    },
    [EKanbanStandardCardSort.alphabeticalOrder]: {
        sortId: EKanbanStandardCardSort.alphabeticalOrder,
        label: "Ordem a alfabética",
        sortFn: (cardA, cardB) => {
            return cardA.getTitle().localeCompare(cardB.getTitle())
        }
    },
}