import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { isValidNumber, isValidString } from "../../../../tools/utility";
import { ICCAgentFileServer } from "../../../attendent-service-pack/attendente-service-pack";
import { agentFileErrors } from "../error-fields";
import { screenOptionToError } from "../error-messages";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";

const errors = screenOptionToError(agentFileErrors);

export const agentFileValidator: TNserValidator = (nser: ICCAgentFileServer, friendly: FriendlyMessage) => {
    defaultNserValidator(nser, friendly);
    
    if(!isValidNumber(nser.maxWeight)) {
        friendly.addReturnResponse(errors.invalidMaxWeight);
    }

    if(!isValidString(nser.idAvatarAgent)) {
        friendly.addReturnResponse(errors.invalidIdAvatarAgent);
    }

    return friendly;
};
