import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { UniqueSelectionDispatcher } from "@angular/cdk/collections";
import { ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, Component, Input } from "@angular/core";
import { visibleComponentAnimations } from "./visible.animations";
import { VisibleComponentBase } from "./visible.base";

@Component({
    selector: '[visible-vertical]',
    template: '<ng-content></ng-content>',
    // exportAs: "visible-vertical",
    encapsulation: ViewEncapsulation.None,
    animations: [
        visibleComponentAnimations.contentExpansionVertical,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[@contentExpansionVertical]': '_getExpandedState()',
        '(@contentExpansionVertical.start)': 'onAnimationStart($event)',
        '(@contentExpansionVertical.done)': 'onAnimationDone($event)'
    }
})
export class VisibleVerticalComponent extends VisibleComponentBase {

    @Input('visible-vertical')
    set _visible(value: boolean) {
        this.visible = coerceBooleanProperty(value);
    }

    constructor(
        _changeDetectorRef: ChangeDetectorRef,
        readonly elementRef: ElementRef,
        protected _expansionDispatcher: UniqueSelectionDispatcher,
    ) {
        super(
            _changeDetectorRef,
            elementRef,
            _expansionDispatcher,
        );
    }
}
