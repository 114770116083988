export enum ErrorClasses {
    Custom = 1,
    CustomField = 2,
    PropagateError = 3,
    PropagateErrorField = 4,
    ServerErrorField = 5,
    ServerErrorPropagated = 6,
}

export enum ECustomErrorTGlobalID {
   errorID = 'coreErrorID',
   unknownProvider = 'unknownProvider'
}

export const CCustomErrorFieldErrors = {
    uberCache: {
        notInCache: 1, 
    }
}
