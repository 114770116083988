<ng-container *ngIf="menuItems.length">
  <header>
    <span>{{ dashboardModule.title }}</span>
  </header>

  <mat-nav-list *ngIf="!loading" class="dashboard-menu-list">
    <ng-container *ngIf="menuItemSections.length; else noSections">
      <ng-container *ngFor="let sectionItems of menuItemSections; let i = index">
        <ng-container [ngTemplateOutlet]="menuList" [ngTemplateOutletContext]="{items: sectionItems}">
        </ng-container>

        <mat-divider *ngIf="i < menuItemSections.length - 1"></mat-divider>
      </ng-container>
    </ng-container>

    <ng-template #noSections>
      <ng-container [ngTemplateOutlet]="menuList" [ngTemplateOutletContext]="{items: menuItems}">
      </ng-container>
    </ng-template>

    <ng-template #menuList let-items="items">
      <a
        *ngFor="let item of items"
        mat-list-item
        ondragstart="return false;"
        class="menu-item"
        href="{{ item.fullRoute }}"
        [serializable]="item.primaryID"
        [class.menu-item-current]="isCurrentDashboard() && isCurrentDashboardItem(item)"
        (click)="item.goTo($event)"
        [matTooltip]="item.title"
        matTooltipPosition="right"
        matTooltipShowDelay="700"
        [matTooltipDisabled]="!isMinimized"
      >
        <span class="menu-label">
          <mat-icon mat-list-icon class="menu-icon">
            {{ item.icon }}
          </mat-icon>
          <span mat-line class="menu-txt">
            {{ item.title }}
          </span>
        </span>
      </a>
    </ng-template>
  </mat-nav-list>
</ng-container>
