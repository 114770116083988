import { ElementRef, Injectable, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { constant } from "@colmeia/core/src/business/constant";
import { IWebChatConfig } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { colmeiaServiceRequestType } from "@colmeia/core/src/comm-interfaces/colmeia-services-req-res";
import { IGeneralFormAnswer } from "@colmeia/core/src/general-form/general-form-answer";
import { Interaction } from "@colmeia/core/src/interaction/interaction";
import { MenuInteraction } from "@colmeia/core/src/interaction/menu-interaction/menu-interaction";
import { IContentBasicAsset } from "@colmeia/core/src/shared-business-rules/bot/bot-content-model";
import { getEngagementOfType, getEngagementOnOrder, getSchemaByEngagementOrder } from "@colmeia/core/src/shared-business-rules/bot/engagement-function";
import { IEmbeddedChatFinishConversationRequest, IEmbeddedChatFinishConversationResponse, IEmbeddedChatFirstFormAnswerResponse, IEmbeddedChatHandsShakeResponse, IEmbeddedChatSendTokenResponse, IEmbeddedChatValidateTokenRes, IEmbeddedChatValidateTokenResponse } from "@colmeia/core/src/shared-business-rules/embedded/embedded-req-res";
import { SchemaPropertyServer } from "@colmeia/core/src/shared-business-rules/files/files";
import { ELayoutElement, EMetadataEngagementType, IFieldMapper, ILayoutFieldMapper, IMetadataRegister } from "@colmeia/core/src/shared-business-rules/metadata/meta-engagement";
import { appURLToEnvironment, EAppBaseURL, environmentToLoader, getEnvironmentByAppUrl } from "@colmeia/core/src/shared-business-rules/production-deploy/prod-deploy-model";
import { isInvalid, isValidArray, isValidRef, typedClone } from "@colmeia/core/src/tools/utility";
import { NotificationDialogService } from "app/components/notifications-dialog/notification-dialog.service";
import { EmbeddedChatApi } from "../modules/embedded-chat/services/embedded-chat-api";
import { AuthService } from "./auth/auth.service";
import { ServerCommunicationService } from "./server-communication.service";
import { SessionService } from "./session.service";
import { IBrandTheme } from "@colmeia/core/src/shared-business-rules/brand-theme/brand-theme.model";
import { BrandTheme } from "app/components/color-theme-builder/brand-theme.functions";
import { EConversationFinishingActions } from "@colmeia/core/src/shared-business-rules/embedded/embedded-req-res";


interface IColmeiaOptions {
    voice: boolean;
    // sound: boolean;
}


export type ColmeiaEmbeddedEvent = 'ready' | 'abort' | 'data';

export enum EWebChatAction {
    Minify = 'minify',
    Close = 'close',
}

export interface IWebChatDataAction {
    action: EWebChatAction;
}

export type TWebChatData = IWebChatDataAction;

export interface ColmeiaEmbeddedSDKCommunication {
    ColmeiaEmbedded: true;
    event: ColmeiaEmbeddedEvent;
    message?: string;
    widgetId?: string;
    data?: TWebChatData;
}

@Injectable({
    providedIn: 'root',
})
export class EmbeddedChatService {


    private auth: AuthService;
    private schema: SchemaPropertyServer;
    private answer: IGeneralFormAnswer;
    private idHandshake: string;
    private uniqueID: string;
    private chatIdGroup: string;
    private origin: string;
    private customerFormulary: IMetadataRegister
    private _idService: string;
    private _isWidgetsApi: boolean = false;
    private _widgetId: string;

    private _serviceTitle: IContentBasicAsset;
    private _serviceDescription: IContentBasicAsset[];

    public _handshakeResponse: IEmbeddedChatHandsShakeResponse;
    private _firstMessage: IContentBasicAsset;
    private api: ServerCommunicationService;

    public isLoading: boolean = false;
    public isFinishingConversation: boolean = false;

    constructor(
        private router: Router,
        private chatEmbeddedAPI: EmbeddedChatApi,
        private session: SessionService,
        private notificationsSvc: NotificationDialogService,
        // private auth: AuthService
    ) {
        this._widgetId = this.widgetId;
    }


    public get idService(): string {
        return this._idService;
    }
    public set idService(value: string) {
        this._idService = value;
    }

    isAnEmbedInstance(): boolean {
        return isValidRef(this.idHandshake);
    }

    setAuthServiceDependency(auth: AuthService) {
        this.auth = auth;
    }

    setServerCommunicationService(serverCommunicationService: ServerCommunicationService) {
        this.api = serverCommunicationService;
    }

    getSchema(): SchemaPropertyServer {
        return this.schema;
    }

    getAnswer(): IGeneralFormAnswer {
        return this.answer;
    }

    setCustomerFormulary(customerFormulary: IMetadataRegister): void {
        this.customerFormulary = customerFormulary;
    }

    setSchemaAndAnswer(schema: SchemaPropertyServer, answer: IGeneralFormAnswer) {
        this.schema = schema;
        this.answer = answer;
        this.sortSchemaAndEngagement();
    }

    public sortSchemaAndEngagement(): void {
        if (isValidRef(this.customerFormulary?.engagement)) {
            this.schema = getSchemaByEngagementOrder(
                this.customerFormulary.engagement,
                this.schema
            );
        }

        if (isValidRef(this.customerFormulary?.engagement)) {
            this.customerFormulary.engagement = getEngagementOnOrder(
                this.customerFormulary.engagement,
            );
        }
    }

    getCustomerFormulary(): IMetadataRegister {
        return this.customerFormulary;
    }

    getHandshakeID(): string {
        return this.idHandshake;
    }

    setHandshakeID(id: string): void {
        this.idHandshake = id;
    }

    setOrigin(origin: string) {
        this.origin = origin;
    }

    setUniqueID(id: string): void {
        this.uniqueID = id;
    }

    getUniqueID(): string {
        return this.uniqueID;
    }


    async submitFirstForm(answer: IGeneralFormAnswer): Promise<void> {
        const response: IEmbeddedChatFirstFormAnswerResponse = await this.chatEmbeddedAPI.submitFirstForm(
            this.uniqueID,
            this.idHandshake,
            answer
        );

        if (!response.friendly.okState) {
            if (isValidRef(response.generalErrorMessage)) {
                this.notificationsSvc.open({
                    message: `Erro: ${response.generalErrorMessage}`,
                    containerTopPosition: "8px",
                    containerRightPosition: "8px"
                }, true);
            }
        } else {
            this.chatIdGroup = response.idChatGroup;
            await this.auth.loginByEmbeddedHandshake(response.idHandShake, this._firstMessage?.content);
        }
    }

    async validateToken(idProperty: string, value: string): Promise<IEmbeddedChatValidateTokenRes> {
        const response: IEmbeddedChatValidateTokenResponse = await this.chatEmbeddedAPI.validateToken(
            this.idHandshake,
            this.uniqueID,
            idProperty,
            value,
        );
        return response;
    }



    async sendToken(idProperty: string, value: string): Promise<boolean> {
        const response: IEmbeddedChatSendTokenResponse = await this.chatEmbeddedAPI.sendToken(
            this.idHandshake,
            this.uniqueID,
            idProperty,
            value,
        );

        if (!response.friendly.okState) {
            return false;
        } else {
            return true;
        }
    }

    private description: string;

    setDescription(desc: string) {
        this.description = desc;
    }

    getDescription(): string {
        return this.description;
    }

    public throwUpInvalidDomain(domain: string): void {
        this.throwUpError(`Não autorizado para esse domínio ${domain}`, 'abort');
    }

    public throwUpError(message: string, event?: ColmeiaEmbeddedEvent): void {
        this.postMessage({ message, event });
    }

    public postMessage(data: Omit<ColmeiaEmbeddedSDKCommunication, 'widgetId' | 'ColmeiaEmbedded'>) {
        if (isValidRef(parent)) {
            const comm: ColmeiaEmbeddedSDKCommunication = {
                ...data,
                ColmeiaEmbedded: true,
                widgetId: this._widgetId,
            };
            parent.postMessage(comm, this.origin);
        }
    }

    public isThisInteractionTheLastMenu(interaction: Interaction): boolean {
        const lastMenu = this.getLastInteractionIfItIsEmbeddedMenu();
        return interaction.getPrimaryID() === lastMenu.getPrimaryID();
    }

    private getSortedInteractions(): Interaction[] {
        const sortedInteractions: Interaction[] = this.session.getGroupSubscription(this.session.getSelectedGroupID())?.getInteractions().sort((a, b) => {
            return b.getClockTick() - a.getClockTick();
        });
        return sortedInteractions || [];
    }

    public getLastInteractionIfItIsEmbeddedMenu(): MenuInteraction {
        const sortedInteractions: Interaction[] = this.getSortedInteractions();
        const [last] = sortedInteractions.filter(int => int.getInteractionType().is(constant.interactionType.menu.menuHeader));
        return last as MenuInteraction;
    }

    public mustAnswerEmbeddedMenu(): boolean {
        const last = this.getLastInteractionIfItIsEmbeddedMenu();

        return isValidRef(last)
            && isInvalid(last.getChild(this.session.getSelectedGroupID(), constant.interactionType.menu.menuReply));
    }

    public getWebChatConfigOfLastFormField(): IWebChatConfig {
        let interactionFormFieldToResponse: Interaction;
        const interactions: Interaction[] = [...this.getSortedInteractions()].reverse();

        for (const interaction of interactions) {
            if (interaction.hasValidWebChatConfig()) {
                interactionFormFieldToResponse = interaction;
            }

            if (interaction.getParticipant().getAvatar().getAvatarID() === this.session.getAvatarID()) {
                interactionFormFieldToResponse = undefined;
            }
        }

        return interactionFormFieldToResponse?.getWebChatConfig();
    }

    public getDescriptorOfLastFormField(): IFieldMapper[] {
        return this.getWebChatConfigOfLastFormField()?.descriptor;
    }

    public hasFormFieldEngagement(): boolean {
        const descriptor: IFieldMapper[] = this.getDescriptorOfLastFormField();
        return isValidArray(descriptor);
    }

    public getFormFieldEngagementLayout<T extends ILayoutFieldMapper>(): T {
        const descriptor: IFieldMapper[] = this.getDescriptorOfLastFormField();
        return getEngagementOfType<T>(descriptor, EMetadataEngagementType.layout) as T;
    }

    public isLastFormFieldShowAsCalendar(): boolean {
        const layoutEngagement = this.getFormFieldEngagementLayout();
        return layoutEngagement?.type === ELayoutElement.calendar;
    }

    public set isWidgetsApi(value: boolean) {
        this._isWidgetsApi = value
    }

    public get isWidgetsApi(): boolean {
        return this._isWidgetsApi;
    }

    private _isCopilot: boolean;
    public get isCopilot(): boolean {
        return this._isCopilot;
    }

    public set isCopilot(value: boolean) {
        this._isCopilot = value;
    }

    public get widgetId(): string {
        try {
            const searchParams = new URLSearchParams(document.location.search);
            const widgetId = searchParams?.get('widgetId');

            return widgetId
        } catch (e) {
            console.error(e);
        }

        return '';
    }

    public set serviceTitle(title: IContentBasicAsset) {
        this._serviceTitle = title;
    }

    public set serviceDescription(description: IContentBasicAsset[]) {
        this._serviceDescription = description;
    }

    public get serviceTitle(): IContentBasicAsset {
        return this._serviceTitle;
    }

    public get serviceDescription(): IContentBasicAsset[] {
        return this._serviceDescription;
    }

    public setHandshakeResponse(response: IEmbeddedChatHandsShakeResponse) {
        this._handshakeResponse = response;
    }

    public getHandshakeResponse(): IEmbeddedChatHandsShakeResponse {
        return this._handshakeResponse;
    }

    public setFirstMessage(firstMessage: IContentBasicAsset) {
        this._firstMessage = firstMessage;
    }

    public getAndRemoveFirstMessage(): IContentBasicAsset {
        const firstMessage = typedClone(this._firstMessage);
        this._firstMessage = undefined;

        return firstMessage;
    }

    public async navToFirstForm(): Promise<void> {
        await this.router.navigateByUrl(`/embedded/${this.idService}/first-form`);
    }

    public async navToWelcomePage(): Promise<void> {
        await this.router.navigateByUrl(`/embedded/${this.idService}/start`);
    }

    public async finishConversation(finishingAction?: EConversationFinishingActions): Promise<boolean> {
        let response: IEmbeddedChatFinishConversationResponse;

        try {
            this.isLoading = true;
            this.isFinishingConversation = true;

            response = await this.api.sendRequest<IEmbeddedChatFinishConversationRequest, IEmbeddedChatFinishConversationResponse>(colmeiaServiceRequestType.embedded.finishConversation)({
                idHandShake: this._handshakeResponse.idHandShake,
                finishingAction,
            });

        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
            this.isFinishingConversation = true;
        }

        return response?.friendlyError.okState;
    }

    getType() {
        return
    }

    async initColmeiaChatScript(el: ElementRef, renderer: Renderer2): Promise<void> {

        return new Promise((resolve, reject) => {
            const script: HTMLScriptElement = renderer.createElement('script');
            script.type = 'text/javascript';

            const url = this.getCurrentBaseUrl();
            const environment = getEnvironmentByAppUrl(url);
            script.src = environmentToLoader[environment];

            renderer.appendChild(el.nativeElement, script);
            script.onload = () => {
                console.log('SCRIPT LOADED');
            };

            window.addEventListener('colmeia-widgets-loaded', () => {
                resolve();
            })

            script.onerror = () => {
                reject()
            };
        })

    }

    getCurrentBaseUrl() {
        const url = new URL(window.location.href);
        const baseUrl = <EAppBaseURL>url.origin;
        return baseUrl;
    }

    setTheme(theme: IBrandTheme) {
        BrandTheme.applyThemeOnElement(document.documentElement, theme);
    }
}
