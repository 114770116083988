import { MainSignal } from './main-signal';
import { observableList } from './signal-constants';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { ISignUpResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";


export class SocketReadySignal extends MainSignal {
    private cached: PlayerCachedInfo;
    private response: ISignUpResponse;
    
    constructor(socketReady: boolean, response: ISignUpResponse, cached: PlayerCachedInfo) {
        super(observableList.auth.socketReady, socketReady);
        this.cached = cached;
        this.response = response;
    };

    public getPlayerCachedInfo(): PlayerCachedInfo {return this.cached; };
    public getSignupResponse(): ISignUpResponse {return this.response; };

};
