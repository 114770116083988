import { ClientTransactService, IRollbackTransaction, rollbackType } from "./client.transact.service";
import { Group } from "@colmeia/core/src/business/group";
import {
    disabledOnVinculatedSecurity,
    groupEditHiddenSections,
    groupReflectSecuritySetting
} from "../model/group-editor-creator.model";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { TSecurityItemArray } from "@colmeia/core/src/security/security-settings";
import { GroupType } from "@colmeia/core/src/business/group-type";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { constant } from "@colmeia/core/src/business/constant";
import { GroupPersistorServices, IGetCreateEditSupportData } from "./group-persistor.services";
import { SessionService } from "./session.service";
import { TDriverFeedbackArray } from "@colmeia/core/src/comm-interfaces/business-interfaces";

export interface IGroupEditorServicePack {
    rollback: ClientTransactService;
    grpSVC: GroupPersistorServices;
    sessionSVC: SessionService;
}

interface ISecuritySettingsValueHash {
    [id: string]: boolean;
}

export class GroupEditor {

    private group: Group;

    private pack: IGroupEditorServicePack;

    private transaction: IRollbackTransaction;

    private securitySettings: ISecuritySettingsValueHash = {};

    private disabledHash: { [primaryID: string]: boolean } = {};

    private excludedHash: { [primaryID: string]: boolean } = {};

    private support: IGetCreateEditSupportData;

    private feedbackDrivers: TDriverFeedbackArray = [];

    private constructor(
        group: Group,
        svcPack: IGroupEditorServicePack,
        support: IGetCreateEditSupportData
    ) {
        this.support = support;
        this.group = group;
        this.feedbackDrivers.push(...this.group.getDriverFeedbackArrayStructure());
        this.fillHiddenSectionsHash(group.getGroupType().getPrimaryID());
        this.fillDisabledSectionsAndOptions();
        this.pack = svcPack;
        this.initializeTransaction();
        this.fillSecuritySettings();
    }

    public static async factory(
        group: Group,
        svcPack: IGroupEditorServicePack,
    ): Promise<GroupEditor> {
        const createEditSupportData: IGetCreateEditSupportData = await svcPack.grpSVC.getGroupSecuritySettings(group.getPrimaryID(), false);
        return new GroupEditor(group, svcPack, createEditSupportData);
    }

    public setSelectedFeedbackDrivers(drivers: TDriverFeedbackArray): void {
        this.feedbackDrivers = drivers;
    }

    public getSelectedFeedbackDrivers(): TDriverFeedbackArray {
        return this.feedbackDrivers;
    }

    public getSupportData(): IGetCreateEditSupportData {
        return this.support;
    }

    private fillDisabledSectionsAndOptions(): void {
        const vinculatedDisabledSettings = this.support.hasVinculatedSecuritySettings ? disabledOnVinculatedSecurity : [];

        vinculatedDisabledSettings.forEach(key => {
            this.disabledHash[key] = true;
        });
    }

    private fillHiddenSectionsHash(idGroupType: TGlobalUID): void {
        this.excludedHash = groupEditHiddenSections(idGroupType);
    }

    private initializeTransaction(): void {
        this.transaction = this.pack.rollback.getRollbackTransaction(
            this.group,
            rollbackType.group
        );
    }

    private fillSecuritySettings(): void {
        const secItems: TSecurityItemArray = this.group.getSecuritySettings().getSecurityITemArray();
        secItems.forEach(sec => {
            this.securitySettings[sec.getSecurityDomainPrimaryID()] = sec.isSelected();
        });
    }

    getSecuritySetting(setting: TGlobalUID): boolean {
        return this.securitySettings[setting] || false;
    }

    getAllSecuritySettings(): Array<string> {
        return Object.keys(this.securitySettings).filter(secKey => this.securitySettings[secKey]);
    }

    updateSecuritySetting(setting: TGlobalUID, value: boolean): void {
        this.securitySettings[setting] = value;
        this.reflectOption(setting, value);
    }

    getGroup(): Group {
        return this.group;
    }

    getGroupType(): GroupType {
        return this.group.getGroupType();
    }

    updateName(name: string): void {
        this.group.setName(name);
    }

    updateDescription(description: string): void {
        this.group.setDescription(description);
    }

    rollback(): void {
        if (isValidRef(this.transaction)) {
            this.pack.rollback.rollback(this.transaction);
        }
    }

    isExcluded(primaryID: TGlobalUID): boolean {
        return (primaryID in this.excludedHash);
    }

    isDisabled(primaryID: TGlobalUID): boolean {
        return this.disabledHash[primaryID] || false;
    }

    isNotExcluded(primaryID: TGlobalUID): boolean {
        return !this.isExcluded(primaryID);
    }

    getEditedGroup(): Group {
        return this.getGroup();
    }

    private reflectOption(primaryID: TGlobalUID, value: boolean): void {
        const reflections = groupReflectSecuritySetting(primaryID, value);
        reflections.enabled.forEach(reflected => {
            this.disabledHash[reflected] = false;
        });
        reflections.disabled.forEach(reflected => {
            this.disabledHash[reflected] = true;
        });
    }

    isDistributionList(): boolean {
        return constant.groupType.functional.distributionList === this.getGroup().getGroupType().getPrimaryID();
    }
}
