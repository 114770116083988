import { IContentBasicAsset } from "../bot/bot-content-model";
import { ICSDisplayField, TICSDisplayFieldArray } from "../corporate-search/corporate-search-model";
import { ENonSerializableObjectType, INonSerializable, INonSerializableHeader, IdSchema } from "../non-serializable-id/non-serializable-id-interfaces";
import { IdDep } from "../non-serializable-id/non-serializable-types";
import {EDocTypes} from "@colmeia/core/src/shared-business-rules/doc-reader-v2/field-extractors/shared-types";

export interface ISchemaRowView {
    fields: TICSDisplayFieldArray;
    idSchema: IdSchema,
    separator: string;

    content?: IContentBasicAsset;
}

export enum ETransformerType {
    schemaRowView = 'schemaRowView',
    document = 'document'
}

export enum ETransformerDocumentResult {
    Success = 'sccss',
    Fail = 'fail',
}

export interface ITransformerConfigBase {
    /**
     * Old, to be deprecated
     * @deprecated
     */
    visualizerType?: ETransformerType;
    transformerType: ETransformerType;
}


export interface ITransformer extends INonSerializableHeader, ITransformerConfigBase {
    nsType: ENonSerializableObjectType.transformer
}


export interface ITransformerServer extends ITransformer, INonSerializable {
    nsType: ENonSerializableObjectType.transformer;
}


export interface ISchemaRowViewTransformer extends ITransformerServer {
    transformerType: ETransformerType.schemaRowView,
    schemaRowView: ISchemaRowView;
}

export interface IDocMetadataConfig  {
    [docProperty: string]: {
        idProperty: string;
        required?: boolean;
    }
}

export interface IDocTransformerConfig {
    minThresholdAccuracy?: number;

}

export interface IDocumentTransformer extends ITransformerServer {
    transformerType: ETransformerType.document;
    docType: EDocTypes;
    idMetadata: IdDep<ENonSerializableObjectType.formSchemma>;
    mapFields: IDocMetadataConfig;
    config?: IDocTransformerConfig;
    cloudProviderIdNs?: string;
}

export type TTransformers /** stop at 3 */ = IDocumentTransformer | ISchemaRowViewTransformer;

export interface ITransformerEditDialogData {
    ns?: TTransformers
}

export interface TransformersDisplayData {
    transformerType: ETransformerType,
    label: string,
    templateOutlet : string
}