import {IBotExchangeConfiguration} from './bot-interfaces';
import {TBotActionTypeConfig} from './bot-action-config-db';
import {IBotEventTypeConfig, } from './bot-event-config-db';
import { EIslandEvent } from './bot-other-event-config-db';
import {ENextGenBotElementType} from './bot-model';
import { values } from '../../tools/utility';
// TActionDB

export const hasPreCondition = (reg: TBotActionTypeConfig) => {return reg.isPreCondition};

type TMatchBotGeneric<T> = (reg: T) => boolean;
export type TMatchBotEventFunction = TMatchBotGeneric<IBotEventTypeConfig>;
export type TMatchBotActionFunction =  TMatchBotGeneric<TBotActionTypeConfig>;



// THIAGO


export const isLeafAction: TMatchBotActionFunction = createBotMatchActionFunction(ENextGenBotElementType.botMenuItem);
export const isRootAction: TMatchBotActionFunction = createBotMatchActionFunction(ENextGenBotElementType.root);
export const isFormAction: TMatchBotActionFunction = createBotMatchActionFunction(ENextGenBotElementType.metadata);

export const isIslandEvent: TMatchBotEventFunction = (reg: IBotEventTypeConfig) => {return reg.eventListOfBranch ? reg.eventListOfBranch.includes(EIslandEvent.island) : false};
export const isLeafEvent: TMatchBotEventFunction = createBotMatchEventFunction(ENextGenBotElementType.botMenuItem);
export const isRootEvent: TMatchBotEventFunction = createBotMatchEventFunction(ENextGenBotElementType.root);
export const isFormEvent: TMatchBotEventFunction = createBotMatchEventFunction(ENextGenBotElementType.metadata);
export const isNlpTransactionEvent: TMatchBotEventFunction = createBotMatchEventFunction(ENextGenBotElementType.nlpTransaction);
export const isFormTransactionEvent: TMatchBotEventFunction = createBotMatchEventFunction(ENextGenBotElementType.formTransaction);
export const isMenuContainerEvent: TMatchBotEventFunction = createBotMatchEventFunction(ENextGenBotElementType.menuContainer);



// TExchangeDB
export const ifCleanQueueEvents = (reg: IBotExchangeConfiguration) => {return reg.cleanQueueEvents};

//
export type TMatchEventBranchType = ENextGenBotElementType | EIslandEvent;
export const matchEventBranchTypeValues: TMatchEventBranchType[] = [
    ...values(ENextGenBotElementType),
    ...values(EIslandEvent),
];



export function createBotMatchEventFunction(type: TMatchEventBranchType) {
    return (reg: IBotEventTypeConfig) => reg.eventListOfBranch ? reg.eventListOfBranch.includes(type) : false;
}
export function createBotMatchActionFunction(type: ENextGenBotElementType) {
    return (reg: TBotActionTypeConfig) => reg.actionEventOfBranch.includes(type);
}