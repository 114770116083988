import {Interaction} from '../interaction';
import { Participant } from '../../business/participant';
import { constant } from '../../business/constant';
import { InteractionType } from '../interaction-type';
import { ChainedInteraction } from '../chained-interaction';


export class Billable extends ChainedInteraction {

    private constructor(participant: Participant, pricedInteraction: Interaction) {
        super(null, participant,  InteractionType.staticFactory(constant.interactionType.products.prePurchase.billableDriverFeedback), pricedInteraction)
    };

} ;