import {Participant} from '../../business/participant';
import { TGlobalUID} from '../../business/constant';
import {Interaction} from '../interaction'
import {InteractionType} from '../interaction-type';
import {IFeedbackInteractionJSON} from '../../comm-interfaces/interaction-interfaces';
import {Feedback} from '../feedback';
import { ChainedInteraction } from '../chained-interaction';

export type TFeedbackInteractionArray = Array<FeedbackInteraction>;



export class FeedbackInteraction extends ChainedInteraction {
    

    protected constructor(primaryID: TGlobalUID, participant: Participant, interactionType: InteractionType, interactionParent: Interaction, idFeedback: TGlobalUID) {
        super(primaryID, participant, interactionType, interactionParent);
        super.setFeedback(idFeedback);
    };

    public static factoryMessage(json: IFeedbackInteractionJSON, participant: Participant = null): FeedbackInteraction {
        let feedbackInteraction: FeedbackInteraction = <FeedbackInteraction>Interaction.searchCachedInteraction(json.primaryID);
        if (feedbackInteraction == null)
            feedbackInteraction = new FeedbackInteraction(json.primaryID, 
                        participant? participant: Participant.staticFactory(json.participant.primaryID),
                        InteractionType.staticFactory(json.idInteractionType), 
                        Interaction.staticFactory(json.idInteractionParent),
                        json.idFeedback);
        feedbackInteraction.rehydrate(json);
        return feedbackInteraction;
    };

    public static staticFactory(idFeedback: TGlobalUID): FeedbackInteraction {
        return <FeedbackInteraction>Interaction.staticFactory(idFeedback);
    };

    public static getNewFeedbackInteraction(participant: Participant, interactionType: InteractionType, 
                            interactionParent: Interaction, feedback: Feedback): FeedbackInteraction {
        return new FeedbackInteraction(null, participant, interactionType, interactionParent, feedback.getFeedbackID());
    };


    public toJSON(): IFeedbackInteractionJSON  {
        const json: IFeedbackInteractionJSON = {
            ...super.toJSON(),
        };
        return json;
    };

    
    public rehydrate(json: IFeedbackInteractionJSON): void {
        super.rehydrate(json);
    };

};
