import { EYouTaskStatus, EYouTasksType } from "@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model";

export type TEYouTaskStatusMap = Record<EYouTaskStatus, string>;
export type TEYouTaskTypeMap = Record<EYouTasksType, string>;

export const statusMap: TEYouTaskStatusMap = {
    yOpen: 'Em aberto',
    yDone: 'Concluída',
    yRejec: 'Rejeitada',
    yForw: 'Encaminhada',
    yPrcss: 'Processando'
}

export const statusMapCustomer: TEYouTaskStatusMap = {
    yOpen: 'Aguardando aprovação',
    yDone: 'Solicitação aceita',
    yRejec: 'Solicitação rejeitada',
    yForw: 'Aguardando aprovação',
    yPrcss: 'Processando'
}

export const taskTypeMap: TEYouTaskTypeMap = {
    [EYouTasksType.groupParticiparionApproval]: 'Participação em Grupo',
    [EYouTasksType.formSolicitation]: 'Formulário',
    [EYouTasksType.socialNetworkParticipationApproval]: 'Participação em Rede Social',
}
