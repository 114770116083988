import { Component, Input, OnInit } from '@angular/core';
import { EPartialMimeTypes } from '@colmeia/core/src/multi-media/file-interfaces';
import { isValidRef, isValidArray, isValidString, mapBy } from '@colmeia/core/src/tools/utility';
import { MMconstant, MultimediaInstance, TMultimediaInstanceArray, EMultimediaNature, MultiMediaType } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { EMultimediaPreviewType, MultimediaPreviewHandler } from './multimedia-preview.handler';
import { constant } from '@colmeia/core/src/business/constant';
import {HardwareLayerService} from "../../../../../services/hardware";

@Component({
    selector: 'app-multimedia-preview',
    templateUrl: './multimedia-preview.component.html',
    styleUrls: ['./multimedia-preview.component.scss']
})
export class MultimediaPreviewComponent implements OnInit {
    private _handler: MultimediaPreviewHandler;

    @Input() //handler: MultimediaPreviewHandler;
    set handler(value: MultimediaPreviewHandler) {
        this._handler = value;
        this.populateDocuments();
    }

    get handler(): MultimediaPreviewHandler {
        return this._handler;
    }

    public documents: TMultimediaInstanceArray = [];

    constructor(
        private hw: HardwareLayerService,
    ) {}

    ngOnInit() {
        this.populateDocuments();
    }

    private populateDocuments() {
        this.documents = [];
        const mmObject = this.handler.getMmObject();
        if (isValidRef(mmObject)) {
            this.documents.push(...this.handler.getMmObject().getAllMultimediaInstanceWithNature(EMultimediaNature.Document));
            this.documents.push(...this.handler.getMmObject().getAllMultimediaInstanceWithNature(EMultimediaNature.SpreadSheet));

        }

        this.documents.forEach(doc => {
            if (!doc.getFileName())
                doc.setFileNameAsMediaID();

            if (!doc.toJSON().fileHash)
                doc.setHashFileAsMediaID()
        });
    }

    public openFirstMedia() {
        this.onMediaClicked(0);
    }

    public onMediaClicked(idx: number): void {
        const isVideo = (mm: MultimediaInstance) => mm.getMimeType().startsWith('video');

        let mmInstances = this.handler.getMmObject().getAllMultimediaInstanceWithTag(MMconstant.tag.photo);
        if (mmInstances.length == 0) {
            return;
        }

        const mmInstance: MultimediaInstance = mmInstances[idx];
        if(mmInstance.isPDF() && this.hw.isMobile()) {
            this.hw.getFileOpener().open(mmInstance);
            return;
        }

        const isValidTitle: boolean = isValidString(this.handler.getFormatedMessageTitle()) && isValidString(this.handler.getMessageDate())
        const bestTitle: string = isValidTitle
            ? `${this.handler.getFormatedMessageTitle()} | ${this.handler.getMessageDate()}`
            : ''

        this.hw.getMediaViewer().view(mmInstances, idx, bestTitle);
    }

    onDocumentClicked(doc: MultimediaInstance) {
        this.hw.getFileOpener().open(doc);
    }

    isTypeSimple(): boolean {
        return this.handler.getType() === EMultimediaPreviewType.Icon
    }

    canShowMultimedia(): boolean {
        const multimediaLength = this.handler.getAllMultimediaByTag(MMconstant.tag.photo).length
            + this.handler.getAllMultimediaByTag(MMconstant.tag.voiceMessage).length
        return multimediaLength != 0
    }

    hasMessage(): boolean {
        return this.handler.getMessage().length != 0;
    }

    getSlicedMultimediaList(): TMultimediaInstanceArray {
        let photos: TMultimediaInstanceArray = this.handler.getAllMultimediaByTag(MMconstant.tag.photo);

        if (this.handler.useThumbnails) {
            photos = photos.map(
                mmInstance => this.handler.getMmObject().getThumbnailOfaParentOrMainMultimedia(mmInstance.getMultmediaKey())
            );
        }

        let allMedia: TMultimediaInstanceArray = photos.slice(0, this.handler.getMaxDisplayMediaNumber());
        const audioList: TMultimediaInstanceArray = this.handler.getAllMultimediaByType(EPartialMimeTypes.Audio)
        allMedia.push(...audioList);

        return [...mapBy(allMedia.filter( media => isValidRef(media) && !media.isPDF()), item => item.getIdMedia()).values()];
    }

    containsOnlyOneMedia(): boolean {
        return this.handler.getAllMultimediaByTag(MMconstant.tag.thumbnail).length === 1;
    }

    containsAudio(): boolean {
        return isValidArray(this.handler.getAllMultimediaByType(EPartialMimeTypes.Audio))
    }

    isThumbnail(media: MultimediaInstance): boolean {
        return media.getMultimediaTag().is(MMconstant.tag.thumbnail);
    }

    isVideo(media: MultimediaInstance) {
        return this.handler.isVideo(media)
    }

    canOpenMediaModal(): boolean {
        return this.handler.canOpenMediaModal()
    }

    getMissingMediaNumber(): number {
        return this.handler.getMissingMediaNumber()
    }

    canShowMoreMediaIcon(): boolean {
        return this.canOpenMediaModal()
            && this.handler.getMissingMediaNumber() > 0
    }

    isParentDefineSize(): boolean {
        return this.handler.isSizeDefinedByParent();
    }

    getFileIconFor(mmInstance: MultimediaInstance): string {
        const type = MultiMediaType.getMutimediaTypeFromMime(mmInstance.getMimeType());
        return type.getSerializableText(constant.serializableField.icon);
    }

}
