import { BBCodeDelivery360ActionParsers, BBCodeStyleTags, EBBCodeStyles, TMarkupGenerator, IMarkupGeneratorOptions } from "../bbcode-types";
import { keys, isValidRef, isInvalid } from "../../../tools/utility";
import { parsersDefinitions } from "../bbcode-constants";
import { EDelivery360Action } from "../../../comm-interfaces/interaction-interfaces";
import { bbcodeHTMLParserDefinitions, createParser, emptyMarkupGenerator, markupNOOP } from "./bbcode-functions-simple";
import { BBCodeParser } from "@affilicon/bbcode-parser";

export function initParsers(): BBCodeDelivery360ActionParsers {
    const parsers: BBCodeDelivery360ActionParsers = {} as BBCodeDelivery360ActionParsers;
    keys(parsersDefinitions).forEach((delivery360Action: EDelivery360Action) => {
        const definition = parsersDefinitions[delivery360Action];
        if (isInvalid(definition)) return;
        parsers[delivery360Action] = createParser(definition.tags);
    });
    return parsers;
}


export function initHTMLParser(): BBCodeParser {
    return createParser<EBBCodeStyles>(bbcodeHTMLParserDefinitions);
}
export const noopParser: BBCodeParser = createParser<EBBCodeStyles>({
    [EBBCodeStyles.Italic]: markupNOOP,
    [EBBCodeStyles.Bold]: markupNOOP,
    [EBBCodeStyles.Strikethrough]: markupNOOP,
    [EBBCodeStyles.Code]: markupNOOP,
    [EBBCodeStyles.Monospace]: markupNOOP,
    [EBBCodeStyles.Menu]: markupNOOP,
});
export const emptyStylesParser: BBCodeParser = createParser<EBBCodeStyles>({
    [EBBCodeStyles.Italic]: emptyMarkupGenerator,
    [EBBCodeStyles.Bold]: emptyMarkupGenerator,
    [EBBCodeStyles.Strikethrough]: emptyMarkupGenerator,
    [EBBCodeStyles.Code]: emptyMarkupGenerator,
    [EBBCodeStyles.Monospace]: emptyMarkupGenerator,
    [EBBCodeStyles.Menu]: emptyMarkupGenerator,
});
