import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { customSvgIcons } from 'app/utils/custom-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsRegisterService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public registryCustomSvgIcons() {
    customSvgIcons.forEach( icon => {
        this.matIconRegistry.addSvgIcon(
            icon.name,
            this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
        );
    });
  }
}
