<div class="perpage-controls" *ngIf="!parameter.hidePerPage">
  <mat-button-toggle-group
    *ngIf="!isMobile"
    [ngModel]="parameter.perPage.current"
    (change)="onSelectionChange($event)"
    class="high-contrast"
    [class.dense]="dense"
    [class.densex2]="densex2"   
  >
    <mat-button-toggle disabled [matTooltip]="translations.perPage">
      <mat-icon>sort</mat-icon>
    </mat-button-toggle>
    
    <mat-button-toggle *ngFor="let option of options" [value]="option">
      {{ option }}
    </mat-button-toggle>

  </mat-button-toggle-group>
  <button
    *ngIf="isMobile"
    color="primary"
    mat-stroked-button
    (change)="onSelectChange($event)"
    [matMenuTriggerFor]="perPage"    
  >
    <mat-icon>sort</mat-icon>
    {{parameter.perPage.current}}
  </button>
  <mat-menu class="pagination-menu" #perPage="matMenu">
    <li mat-menu-item>Itens por página</li>
    <li 
      *ngFor="let option of options"
      [ngClass]="{'per-page-selected': parameter.perPage.current === option}"
    >
      <button
        mat-menu-item
        (click)="onSelectChange(option)"
        class="mat-menu-item">
        {{ option }}
      </button>
    </li>
  </mat-menu>

  <ng-container *ngIf="hasSorting()">
    <mat-button-toggle-group 
      class="high-contrast sorting-btn"
      [class.dense]="dense"
      [class.densex2]="densex2"
    >
      <!-- <mat-button-toggle
      [matMenuTriggerFor]="sorting"
      class="sorting-btn-toggle"
      >
        <mat-icon [class.pagination-sorting-selected]="selectedSorting">
          {{selectedSorting ? 'check_box' : 'import_export'}}
        </mat-icon> 
        <span>
          Ordenar
        </span>
      </mat-button-toggle> -->
      <mat-button-toggle
        class="sorting-btn-toggle"
        (click)="handleSorting(asc)"
        matTooltip="Ordenar do mais antigo para o mais recente"
      >
        <mat-icon [class.pagination-sorting-selected]="isSelected(asc)">
          arrow_upward
        </mat-icon> 
      </mat-button-toggle>
      <mat-button-toggle
        class="sorting-btn-toggle"
        (click)="handleSorting(desc)"
        matTooltip="Ordenar do mais recente para o mais antigo"
      >
        <mat-icon [class.pagination-sorting-selected]="isSelected(desc)"
        >
          arrow_downward
        </mat-icon> 
      </mat-button-toggle>
    </mat-button-toggle-group>

    <!-- <mat-menu #sorting="matMenu">
        <button
          *ngFor="let option of getSortingOptions()"
          mat-menu-item
          class="mat-menu-item dense densex2 high-contrast sorting-option"
          (click)="handleSorting(option)"
          [class.sorting-selected-option]="selectedSorting === option"
          >
          {{option}}
        </button>
        <mat-icon>arrow_upward</mat-icon>
        <mat-icon>arrow_downward</mat-icon>
    </mat-menu> -->
  </ng-container>

</div>

<div *ngIf="showCounter" class="items-counter"
  [ngClass]="{'select-all-activated-class': isSelectedAllActivated }">
  {{_intl.getRangeLabel(pageIndex, perPage, totalItems)}}{{this.parameter.hasMoreEntitiesToLoad ? '+' : ''}}
</div>

<div 
  class="arrow-controls" 
  [class.hide-per-page]="parameter.hidePerPage"
  [ngClass]="{'select-all-activated-class': isSelectedAllActivated }" 
>
  <button 
    *ngIf="!parameter.hidePerPage && !parameter.hideFirstLastPage"
    class="paginator-arrow-button first"
    (click)="firstPageDisabled ? noop() : goFirstPage()"
    [ngClass]="{'pg-disabled': firstPageDisabled}"
  >
    <mat-icon>
      first_page
    </mat-icon>
  </button>
  <button 
    class="paginator-arrow-button previous"
    (click)="previousPageDisabled ? noop() : goPreviousPage()"
    [ngClass]="{'pg-disabled': previousPageDisabled}"
  >
    <mat-icon>
      chevron_left
    </mat-icon>
  </button>
  <span *ngIf="parameter.showPageIndex" class="page-idx-indicator">{{ pageIndex + 1 }}</span>
  <button 
    class="paginator-arrow-button next"
    (click)="nextPageDisabled ? noop() : goNextPage()"
    [ngClass]="{'pg-disabled': nextPageDisabled}"
  >
    <mat-icon>
      chevron_right
    </mat-icon>
  </button>
  <button 
    *ngIf="!parameter.hidePerPage && !parameter.hideFirstLastPage"
    class="paginator-arrow-button last"
    (click)="lastPageDisabled ? noop() : goLastPage()"
    [ngClass]="{'pg-disabled': lastPageDisabled}"
  >
    <mat-icon>
      last_page
    </mat-icon>
  </button>
</div>
