import { EnumValue } from "../../utility";
import { $ValueOf, ValueOf } from "../../utility-types";


type TEnumValue = EnumValue | undefined;
export function isInEnumFactory<T extends Record<string, TEnumValue> | TEnumValue[]>(_enum: T) {
    const set = new Set(Object.values(_enum))
    verify.size = set.size;
    return verify;
    function verify(_value: TEnumValue): _value is $ValueOf<T> {
        return set.has(_value);
    }
}
