import { MainHandler } from "./main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";
import { CssSelector } from "@angular/compiler";
import { EBBCodeStyles } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-types";
import { factoryOf } from "@colmeia/core/src/tools/utility/functions/factory-of";
import { EDelivery360Action } from "@colmeia/core/src/comm-interfaces/barrel-comm-interfaces";
import { BBCode } from "@colmeia/core/src/shared-business-rules/bbcode/bbcode-main";
import { WhatsAppParser } from "@colmeia/core/src/shared-business-rules/social-media/whatsapp-constants";


export interface IBBCodeViewModalHandlerParameters extends IComponentParameter {
    text: string;
    isWhatsApp?: boolean;
    style?: { [key in EBBCodeStyles]?: Partial<CSSStyleDeclaration> };
}

export class BBCodeViewModalHandler extends MainHandler {
    public constructor(parameters: IBBCodeViewModalHandlerParameters) {
        super(parameters);
        this.init();
    }

    init() {
        if (!this.parameters.isWhatsApp) return;
        this.parameters.text = WhatsAppParser.formatMessage(BBCode.parseString(EDelivery360Action.Delivery360WhatsApp, this.parameters.text));
    }

    get parameters() {
        return this.getComponentParameter();
    }

    public static factory = factoryOf(this);
    public getComponentParameter(): IBBCodeViewModalHandlerParameters { return super.getComponentParameter() as IBBCodeViewModalHandlerParameters };
}