<h4 class="title">
  <mat-icon class="icon8">error</mat-icon> Atenção!
</h4>

<h4>Detectamos que sua máquina está enviando uma quantidade inadequada de requisições aos nossos servidores.</h4>

<ng-container *ngIf="hasCoundown">
  <div>Seu acesso será desbloqueado em <strong>{{ unlockTimeout }}</strong></div>
</ng-container>
<br>
<i>Em caso de dúvidas, por favor chame o suporte técnico de sua empresa.</i>
