import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { TInputType, EInputType } from './cm-input.model';
import {EFieldIdentifier, IColmeiaInputClient} from "../../../authentication/landing-page/landing-page.model";

@Component({
    selector: 'app-cm-input',
    templateUrl: './cm-input.component.html',
    styleUrls: ['./cm-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmInputComponent implements OnInit {
    @Input() id: EFieldIdentifier;
    @Input() type: TInputType;
    @Input() autocomplete: string;
    @Input() placeholderName: string;
    @Input() validatorClientInstance: IColmeiaInputClient;
    @Output() onEnterPressed: EventEmitter<void> = new EventEmitter<void>();
    @Output() onValueChanged: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('inputText', {static: true}) inputTextElement: ElementRef

    @Input()
    set currentValue(val: string) {
        this.typedText = val;
    }

    get currentValue(): string {
        return this.typedText;
    }

    typedText: string = '';


    constructor() {}

    ngOnInit() {}

    getType(): TInputType {
        return this.type
            ? this.type
            : EInputType.Text;
    }

    isValidatorType(): boolean {
        return this.validatorClientInstance != null
    }

    isValid(): boolean {
        return this.validatorClientInstance.isInputFieldValidCallback(this.id, this.typedText)
    }

    onEnterKeyPressed() {
        this.onEnterPressed.emit()
    }

    saveTypedText(newValue: string): void {
        this.typedText = newValue;
        this.onValueChanged.emit(newValue);
    }

    getIcon(): string {
        return this.isValid() ? 'check_circle' : 'cancel';
    }

    onIconClick(): void {
        if(!this.isValid()){
            this.typedText = '';
            this.inputTextElement.nativeElement.focus();
        }
    }

    canShowIcon(): boolean {
        return this.typedText.length && this.isValidatorType();
    }

    get iconClasses(): string {
        const result = ['data-input__status'];
        result.push(this.isValid() ? 'green' : 'red');
        return result.join(' ');
    }
}
