<mat-form-field appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select [(ngModel)]="intentId">
    <mat-option *ngFor="let intent of intents" [value]="intent.intentId">
      {{ intent.intentName }}
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-button-toggle-group appearance="fill" name="mode" aria-label="Mode" class="primary-active" *ngIf="selectMode"
  [(ngModel)]="selectedMode">
  <mat-button-toggle [value]="EBpmGraphElementInfoMode.Edit">Selecionar Gerador</mat-button-toggle>
  <mat-button-toggle [value]="EBpmGraphElementInfoMode.Create">Criar novo Gerador</mat-button-toggle>
</mat-button-toggle-group>
<div class="cm__footer-container">
  <button class="cm__footer-button button-w-icon" mat-flat-button [color]="'primary'" (click)="cancel()">
    <mat-icon>close</mat-icon>&nbsp;
    Cancelar
  </button>

  <button class="cm__footer-button button-w-icon" mat-flat-button [color]="'primary'" (click)="save()">
    <mat-icon>save</mat-icon>&nbsp;
    Confirmar
  </button>
</div>