import { IdDep } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types";
import { Miliseconds, Seconds } from "@colmeia/core/src/time/time-utl";
import { TGlobalUID } from "../../business/constant";
import { TGeoLocationConfig } from "../../client-shared/client-native.model";
import { TArrayID } from "../../core-constants/types";
import { INonSerializableHeader, INonSerializable, ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { EBotServiceStatus } from "@colmeia/core/src/core-constants/bot";
import { IBrandTheme } from "@colmeia/core/src/shared-business-rules/brand-theme/brand-theme.model";

export enum ESocialNetworkPrimaryCEPSearchType {
    google = 'ggle',
    cep = 'cep', // viacep?
}

export interface IVirtualServiceImplanted {
    idAvatarToImpersonateBot: string,
    idUserChatContainerGroup: TGlobalUID
}

export interface IExternalDBConfig {
    useMultimediaBucket: boolean;
    multimediaBucketName?: string; // a mesma regra de nomes para diretorios, aceitar letra minuscula e numero (1o caracter letra)
    shouldDoNotDeleteMediaOnFailure?: boolean;

    useExternalBlobAPI: boolean;
    externalBlobAPIBucketName?: string; // a mesma regra de nomes para diretorios, aceitar letra minuscula e numero (1o caracter letra)

    useExternalCloudProvider: boolean;
    externalCloudProviderNSId?: string; // id do "conn" do tipo "ct.cloudProvider"
}

export interface ISocialNetworkSettings {
    domain: TArrayID;
    returnCustomerToQueueOnAgentDowntime: boolean; // Retorna clientes à fila quando houver downtime de rede do agente
    cepSearch: {
        primary: ESocialNetworkPrimaryCEPSearchType;
        cepCacheTTLDays: number;
        useViaCepAsBackupOnly: boolean;
    };
    geo: TGeoLocationConfig,
    virtualService?: IVirtualServiceImplanted,
    agent: {
        useMenuSecurityToAllowContactsLists?: boolean;
        useMenuSecurityToAllowConversationHistory?: boolean;
        useMenuSecurityToAllowActiveCall?: boolean;
        historic: {
            disableHistoricSearch: boolean;
            disableOtherAgentHistoricSearch: boolean;
            disableSearchByDateInterval: boolean;
            allowActiveCallOnConversationHistory?: boolean
        };
        absense: {
            returnCustomerToQueuAfter: number; // Retorna cliente para fila caso ele não responda após
            disconnection: {
                returnCustomersToQueue: boolean; // Retorna clentes para fila quando rede indisponível
                numberOfChecks: number; // Quantas vezes irá checar a rede antes de retornar o cliente para fila
                interval: number; // Tempo entre checagens
            };
        };
        channelClosedControl?: IAgentChannelClosedControl;
    };
    attendance: {
        newAttNotifyAgentOutOfFocus?: boolean;
        forceLoggoutAfterDays?: number; // Forçar loggout (e devolver para a fila) após
        forcedLogoutFinishAtt?: boolean; // Finaliza atendimento ao invés de retornar para a fila
        queueKillTimeout: {
            forOwnerAfterHS: number;
            afterHS: number;
        };
        agentPunishForAttByLogout: Miliseconds,
    };
    visualIdentity?: {
        brandTheme?: IBrandTheme;
        /** @deprecated propriedade nunca utlizada */
        colors?: unknown;
    };
    security?: {
        authConsecutiveMaxFailure: number; // Erros consecutivos
        blockTime: number; // Tempo de Bloqueio (Usar do Fernando)
        changePDWInterval: number; // Intervalo de Atualização de Senha
        pwdHistoricControl: number; // Não repetir as últimas senhas
    };
    messageCordinator?: {
        textMessage: boolean; // Coordenar envios de mensagem texto
        waitTimeForMessage: Seconds; // Aguardar retorno do provedor até X segundos para mensagens
        waitTimeForMedia: Seconds; // Aguardar retorno do provedor até X segundos para medias
    },
    /**
     * caso esteja processando, enfila a conversação ao invés de mandar a mensagem de "Ainda Processando"
     */
    queueCustomerMessageIfBotProcessing?: boolean;
    externalDatabases?: IExternalDBConfig;
    externalFunction?: ISocialNetworkSettingsExternalFunction;
    botServiceStatus?: {
        status: EBotServiceStatus
        message: string
        idServiceStopped?: IdDep<ENonSerializableObjectType.deployedServices>
    },
    // detecção de anomalias
    anomaly?: {
        latestFields: number; // Campos mais recentes analisados (default 5)
        maxRepeatedInput: number; // Máximo número de imputs repetidos nas mensagens analisadas (default 15)    
        maxMessagesByConversation: number; // Máximo de mensagens permitidas em uma conversação (default 500)
    },
    waf?: {
        idPrivateKeyFlow?: string;
        appliedAt: Miliseconds;
        updatedAt?: Miliseconds;
    },
}
export interface ISocialNetworkSettingsExternalFunction {
    overwrite?: boolean;
    idExternalFunction?: IdDep<ENonSerializableObjectType.userFunction>;
}

export type TSocialNSRequiredConfig = Required<ISocialNetworkSettings>;

export interface ISocialNetworkSettingsClient extends ISocialNetworkSettings, INonSerializableHeader {
    idSocialContext: TGlobalUID;
}

export type TISocialNetworkSettingsServerArray = Array<ISocialNetworkSettingsServer>;

export interface ISocialNetworkSettingsServer extends ISocialNetworkSettingsClient, INonSerializable {
    nsType: ENonSerializableObjectType.socialNetworkUserSettings
}

export interface IAgentChannelClosedControl {
    /**
     * Define se o agente pode ou não reenviar templates de reabertura
     */
    isSendAllowed: boolean;
    /**
     * Intervalo em que o agente pode reenviar o template de reabertura
     */
    sendInterval: Miliseconds;
}

