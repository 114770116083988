import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";

export const activeSendsScreenOption = typedScreenOptions({
    idSerializable: EAllSerializable.ActiveSends,
    fields: {
        activeSends: 1, // Envios Ativos
        activeSendsIcon: 18, // send
    }
} as const);


export const activeSendsTranslations = {
    ...getTranslationsFields(activeSendsScreenOption)
};
