import {Participant} from '../business/participant';
import {TGlobalUID, constant} from '../business/constant';
import {Interaction} from './interaction'
import {InteractionType} from './interaction-type';
import { ETimedInteractionTypeID, EWrapperStatus } from '../business/constant.enums';
import { IWrapperInteractionJSON, IWrappedSimpleUniversalJSON } from '../comm-interfaces/interaction-interfaces';
import { MMconstant } from '../multi-media/barrel-multimedia';
import { isValidRef } from '../tools/utility';



export class WrapperInteraction extends Interaction {
    private wrapperType: ETimedInteractionTypeID;
    private wrapperStatus: EWrapperStatus;
    private idWrappedInteraction: TGlobalUID;
    private wrappedText: string;
    private wrappedGroup: IWrappedSimpleUniversalJSON;
    private wrappedAvatar: IWrappedSimpleUniversalJSON;
    

    protected constructor(primaryID: TGlobalUID, participant: Participant, wrapperType: ETimedInteractionTypeID) {
        super(primaryID, participant, InteractionType.staticFactory(constant.interactionType.special.wrapperInteraction));
        this.wrapperType = wrapperType
    };

    public rehydrate(json: IWrapperInteractionJSON): void {
        super.rehydrate(json);
        this.wrapperType = json.wrapperType;
        this.wrapperStatus = json.wrapperStatus;
        this.idWrappedInteraction = json.idWrappedInteraction;
        this.wrappedText = json.wrappedText;
        this.wrappedGroup = json.wrappedGroup;
        this.wrappedAvatar = json.wrappedAvatar;
    };

    public getWrapperType(): ETimedInteractionTypeID {
        return this.wrapperType;
    };

    public getWrapperStatus(): EWrapperStatus {
        return this.wrapperStatus;
    };

    public getWrappedText(): string {
        return this.wrappedText;
    };

    public toJSON(): IWrapperInteractionJSON {
        return {
            ...super.toJSON(),
            wrapperType: this.wrapperType,
            wrapperStatus: this.wrapperStatus,
            idWrappedInteraction: this.idWrappedInteraction,
            wrappedText: this.wrappedText,
            wrappedGroup: this.wrappedGroup,
            wrappedAvatar: this.wrappedAvatar,
        };
    };

    public static factoryMessage(json: IWrapperInteractionJSON, participant: Participant = null): WrapperInteraction {
        let wrapper: WrapperInteraction = <WrapperInteraction>Interaction.searchCachedInteraction(json.primaryID);
        if (wrapper == null || !(wrapper instanceof WrapperInteraction)) {
            wrapper = new WrapperInteraction(
                json.primaryID, 
                participant ? participant : Participant.staticFactory(json.participant.primaryID),
                json.wrapperType
            );
        }
        wrapper.rehydrate(json);
        return wrapper;
    };

    public getIdMediaAvatar(): string {
        return this.wrappedAvatar 
            ? this.wrappedAvatar.idMedia
            : MMconstant.profilePhoto.avatar;
    }

    public getNameAvatar(): string {
        return this.wrappedAvatar 
            ? this.wrappedAvatar.name
            : '';
    }

    public getIdMediaGroup(): string {
        return this.wrappedGroup 
            ? this.wrappedGroup.idMedia
            : MMconstant.profilePhoto.avatar;
    }

    public getNameGroup(): string {
        return this.wrappedGroup 
            ? this.wrappedGroup.name
            : '';
    }

    public isReply(): boolean {
        return isValidRef(this.idWrappedInteraction);
    }
};