<div class="toggle__container" *cmSpinner="!canShowLocationEnabledToggle">
  <mat-slide-toggle
    id="location-settings--toggle"
    *ngIf="canShowLocationEnabledToggle"
    labelPosition="before"
    [(ngModel)]="isPLayerBeingGPSTracked"
    [color]="'primary'"
    (change)="emitSignal()"
  >
    <span style="font-size: 1rem;">
      {{ labels.enableLocation }}
    </span>
  </mat-slide-toggle>
</div>
