import { MainSignal } from './main-signal';
import { observableList } from './signal-constants';


export class LogoutSignal extends MainSignal {
    

    constructor() {
        super(observableList.auth.logout, true);
    };


};
