<section class="wallet">

  <header class="wallet__balance">
      <span>{{ translations.availableBalance }}</span>
      <span>{{ balance }}</span>
  </header>
  <div class="wallet__table" *ngIf="canShowTable()">
      <table mat-table multiTemplateDataRows [dataSource]="dataSource">
          <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef> ID </th>
              <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>
          <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef> {{ translations.date }} </th>
              <td mat-cell *matCellDef="let element"> {{element.date}} </td>
          </ng-container>
          <ng-container matColumnDef="operation">
              <th mat-header-cell *matHeaderCellDef> {{ translations.operation }} </th>
              <td mat-cell *matCellDef="let element"> {{element.operation}} </td>
          </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> {{ translations.balance }} </th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <ng-container matColumnDef="secondary">
            <td mat-cell [attr.colspan]="displayedColumns.length"
                *matCellDef="let element">
              <label class="transfer-avatar" *ngIf="element.transferAvatar">
                  <span> {{ getTranferTypeLabel(element.transferAvatar) }} {{ element.transferAvatar.name }}</span>
                  <hexagon [hexagonHandler]="generateHandler(element)"></hexagon>
              </label>
            </td>
        </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-row *matRowDef="let row; columns: ['secondary'];" [hidden]="!row.transferAvatar" ></tr>
        </table>
  </div>
  <div class="wallet__buttons">
    <cm-button
      class="wallet__buttons--transfer-btn"
      [color]="getTransferBtnColor()"
      (click)= "onTransferButtonClick()">
      {{ translations.transfer }}
    </cm-button>
    <cm-button
      class="wallet__buttons--balance-btn"
      [color]="getBalanceBtnColor()"
      (click)="onChargeButtonClick()">
        {{ translations.loadBalance }}
    </cm-button>

  </div>
</section>
