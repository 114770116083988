import { EConfirmationType } from "@colmeia/core/src/core-constants/types";
import { TGeneralFieldArray } from "../../general-form/general-form-answer";

export enum ECallbackType {
    defaultRoute = 'defroute', // formato padrão - rota,
    custom = 'custom' // função customizãvel
}

export interface ICallbackInfo {
    type: ECallbackType;
    /**
     * Se o type for ECallbackType.defaultRoute a propriedade idRouteCallback torna-se obrigatória.
     * Se o type for ECallbackType.custom a propriedade idFunction e confirmationTypeList tornam-se obrigatória.
     */
    idRouteCallback?: string;
    idFunction?: string;

    /**
     * mustUseConfirmationCallbacksSubset só é visível se idFunction for preenchido.
     */
    mustUseConfirmationCallbacksSubset?: boolean
    /**
     * A confirmationTypeList é brigatória se o mustUseConfirmationCallbacksSubset for true.
     */
    confirmationTypeList?: EConfirmationType[];
}


export interface IExecuteCallbackInfo extends ICallbackInfo {
    row: TGeneralFieldArray
}


export interface IRouteCallback {
    activeCallback: boolean;
    callback: ICallbackInfo

}