import { Component, EventEmitter, Input, KeyValueChangeRecord, KeyValueDiffer, KeyValueDiffers, Output, SimpleChanges } from '@angular/core';
import { IBasicAsset, KAssetType, KBAssetType, KBAssetTypeClientOnly, TBasicAssetArray } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { IBotEvent } from '@colmeia/core/src/shared-business-rules/bot/bot-event-model';
import { isRegularContentAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-function-model-helper';
import { ENextGenBotElementType } from '@colmeia/core/src/shared-business-rules/bot/bot-model';
import { EBotActionType } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { TColmeiaTagArray } from '@colmeia/core/src/shared-business-rules/colmeia-tags/tags';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { EGeneratorTransactionType, IFormTransactionServer, INLPTransactionServer, ITransactionServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction';
import { IKnowledgeDBServer } from '@colmeia/core/src/shared-business-rules/knowledge-base/kb-inferfaces';
import { TIVariablesArray } from '@colmeia/core/src/shared-business-rules/metadata/metadata-util-interfaces';
import { getVariablesWithDelimeters } from '@colmeia/core/src/shared-business-rules/metadata/metadata-utils';
import { suggestions } from '@colmeia/core/src/tools/type-utils';
import { isValidArray, isValidRef } from '@colmeia/core/src/tools/utility';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { EVarEditorEntityType } from 'app/handlers/var-editor.handler';
import { IAssetAdderHandler, IIntentAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { BotTransactionService } from 'app/services/bot-transaction.service';

@Component({
    selector: 'bot-transaction-assets',
    templateUrl: './bot-transaction-assets.component.html',
    styleUrls: ['./bot-transaction-assets.component.scss']
})
export class BotTransactionAssetsComponent extends RootComponent<'title'> {

    @Input() transaction: ITransactionServer;
    @Input() knowledgeBases: IKnowledgeDBServer[] = [];
    @Input() expandedIntentsIDs: Array<string>;
    @Input() preSelectedValue: KAssetType;
    @Input() nsPickerSelectedProperty: EGeneratorTransactionType;
    @Output() softSync: EventEmitter<void> = new EventEmitter();

    formAssetAdderHandler: IAssetAdderHandler;

    nlpIntentAssetAdderHandler: IIntentAssetAdderHandler;

    private _variableTags: TColmeiaTagArray;
    @Input()
    set variableTags(variableTags: TColmeiaTagArray) {
        this._variableTags = variableTags;
        this.init();
    };

    get variableTags(): TColmeiaTagArray {
        return this._variableTags;
    }

    nsVariables: TIVariablesArray;

    private assetHistory = {};

    knowledgeBase: IKnowledgeDBServer;

    private nlpDiffer: KeyValueDiffer<string, any>;

    constructor(
        private differs: KeyValueDiffers,
        private transactionSvc: BotTransactionService,
    ) {
        super({
            title: gTranslations.bot.knowledgeBaseAssetsName
        });
    }

    public async init(): Promise<void> {
        this.nsVariables = await this.getNSVariables();
        if (!this.isFormTransaction) {
            this.nlpDiffer = this.differs.find(this.nlpTransaction.nlp).create();
        }
        this.initFormAssetAdderHandler();
        this.initNLPIntentAssetAdderHandler();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.knowledgeBases && changes.knowledgeBases.currentValue && !this.isFormTransaction) {
            this.onKnowledgeBaseChange(this.nlpTransaction.nlp.idKB, undefined);
        }
    }

    ngDoCheck(): void {
        if (this.nlpDiffer) {
            const changes = this.nlpDiffer.diff(this.nlpTransaction.nlp);
            if (changes) {
                changes.forEachChangedItem((record: KeyValueChangeRecord<string, string>) => {
                    if (record.key === 'idKB') {
                        this.onKnowledgeBaseChange(record.currentValue, record.previousValue);
                    }
                });
            }
        }
    }

    get nlpTransaction(): INLPTransactionServer {
        return <INLPTransactionServer>this.transaction;
    }

    get formTransaction(): IFormTransactionServer {
        return <IFormTransactionServer>this.transaction;
    }

    get isFormTransaction(): boolean {
        return this.transaction.botLevel === ENextGenBotElementType.formTransaction;
    }

    private getAssets(): TBasicAssetArray {
        return this.transaction.events.filter(asset => isRegularContentAsset(asset));
    }


    private defaultAssetAdderParameters() {
        const parameters = suggestions<Partial<IAssetAdderHandler>>()({
            removeAsset: (index: number, assets: TBasicAssetArray) => this.removeAsset(index, assets),
            saveAsset: (newAsset: IBasicAsset, assets: TBasicAssetArray, isEdit?: boolean) => this.saveAsset(newAsset, assets, isEdit),
            variables: {
                [EVarEditorEntityType.NonSerializable]: isValidArray(this.nsVariables) ? this.nsVariables.map(variable => ({ idProperty: variable.idProperty, variable: undefined })) : [],
            },
        });
        return parameters;
    }

    private initFormAssetAdderHandler(): void {
        this.formAssetAdderHandler = {
            ...this.defaultAssetAdderParameters(),
            assets: this.getAssets(),
            assetTypesEnabled: [...Object.values(KBAssetType), EBotActionType.goBot, EBotActionType.contentGenerator, KBAssetTypeClientOnly.sticker],
        }
    }

    private initNLPIntentAssetAdderHandler(): void {
        this.nlpIntentAssetAdderHandler = {
            ...this.defaultAssetAdderParameters(),
            transaction: <INLPTransactionServer>this.transaction,
            expandedIntentsIDs: this.expandedIntentsIDs ? new Set(this.expandedIntentsIDs) : new Set(),
            preSelectedValue: this.preSelectedValue ?? undefined,
            nsPickerSelectedProperty: this.nsPickerSelectedProperty
        }
    }

    onKnowledgeBaseChange(currentValue: string, previousValue: string) {
        if (currentValue) {
            this.knowledgeBase = this.knowledgeBases?.find(kb => kb.idNS === currentValue);
        } else {
            this.knowledgeBase = undefined;
        }
    }

    private getNSVariables(): TIVariablesArray {
        const nsVariables = getVariablesWithDelimeters(this.variableTags.map(item => {
            return {
                text: item.nName,
                idProperty: item.idNS,
            };
        }));
        return nsVariables;
    }

    async saveAsset(newAsset: IBasicAsset, assets: TBasicAssetArray, isEdit?: boolean): Promise<boolean> {
        console.log("add asset", newAsset, assets, isEdit);
        if (isEdit) {
            const asset: IBasicAsset = newAsset as IBasicAsset;

            const [index, ...duplicated]: number[] = assets
                .map(item => item.idAsset === asset.idAsset)
                .map((item, index) => item === true ? index : undefined)
                .filter(item => isValidRef(item));

            const isAssetFound: boolean = index !== -1;

            if (isAssetFound) {
                for (let index of duplicated) {
                    assets.splice(index, 1);
                    this.transaction.events.splice(index, 1)
                }
                this.transaction.events.splice(index, 1, asset);
                assets.splice(index, 1, asset);
                return true;
            } else {
                return false;
            }
        }

        this.transaction.events.push(newAsset as IBotEvent);
        assets.push(newAsset);
        return true;
    }

    async removeAsset(index: number, assets: TBasicAssetArray): Promise<boolean> {
        const removeEventIndex: number = this.transaction.events.findIndex(event => event.idAsset === assets[index].idAsset);
        if (removeEventIndex !== -1)
            this.transaction.events.splice(removeEventIndex, 1);
        assets.splice(index, 1);
        this.initFormAssetAdderHandler();
        return true;
    }

}
