import { Injectable } from '@angular/core';
import { GenericNonSerializableService } from './generic-ns.service';
import { ServerCommunicationService } from './server-communication.service';
import { RequestBuilderServices } from './request-builder.services';
import { SessionService } from './session.service';
import { RoutingService } from './routing.service';
import { routeList } from 'app/model/routes/route-constants';
import { IIslandAttendant, IIslandAttendantServer, IServiceIslandServer, IServiceIsland, ITemporaryQueuePrioritizationConfig, ITemporaryQueuePrioritization } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island';
import { IGetAttendanceIslandsResponse, IGetIslandAttendantsRequest, IGetIslandAttendantsResponse, ISaveIslandAttendantsRequest, IDeleteIslandAttendantRequest, IDeleteAttendanceIslandRequest, IGetServiceIslandsRequest, ISaveServiceIslandRequest, ISaveServiceIslandResponse, IGetIslandAttendantsByAvatarResponse, IGetIslandAttendantsByAvatarRequest, IChangeTemporaryQueuePrioritizationRequest, IChangeTemporaryQueuePrioritizationResponse } from '@colmeia/core/src/shared-business-rules/attendance-island/attendance-island-requests';
import { apiRequestType, EAttendanceIslandRequest } from '@colmeia/core/src/request-interfaces/message-types';
import { ITagableSearch } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-req-resp';
import { IListNonSerializablesResponse } from '@colmeia/core/src/dashboard-control/dashboard-response-interfaces';
import { IListNonSerializablesRequest } from '@colmeia/core/src/dashboard-control/dashboard-request-interfaces';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { toMultipleCursor } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-functions';
import { BpmService } from './bpm/bpm.service';
import { SpinType } from './screen-spinner.service';
import { IdDep, IdDepWithError } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-types';
import { LookupService } from './lookup.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceIslandService extends GenericNonSerializableService {

    cache = this.lookup.createNSCacheImplementation();

    constructor(
        api: ServerCommunicationService,
        rbs: RequestBuilderServices,
        session: SessionService,
        routeSvc: RoutingService,
        private lookup: LookupService,
    ) {
        super(
            routeList.dashboard.children.serviceStatus.path,
            routeList.dashboard.children.serviceStatus.children.serviceIslands,
            { api, rbs, session, routeSvc },
        )
    }

    async getIsland(idIsland: string): Promise<IServiceIslandServer> {
        return (await this.cache<IServiceIslandServer>([idIsland]))?.[0];
    }

    async getGenericIslands(search: ITagableSearch, cursor: string = null): Promise<IServiceIslandServer[]> {
        const request: IListNonSerializablesRequest = {
            ...this.baseRequest(apiRequestType.nonSerializable.list),
            ...search,
            nsType: ENonSerializableObjectType.serviceIsland,
            multipleCursor: toMultipleCursor(cursor),
        }

        const response: IListNonSerializablesResponse = await this.send<IListNonSerializablesResponse>(request);
        if (response) {
            return response.nonSerializableArray as IServiceIslandServer[];
        }
        return null;
    }

    async getIslands(search: ITagableSearch, cursor: string = null): Promise<IServiceIslandServer[]> {
        const request: IGetServiceIslandsRequest = {
            ...this.baseRequest(apiRequestType.attendanceIsland.listIslands),
            ...search,
            cursor
        }

        const response: IGetAttendanceIslandsResponse = await this.send<IGetAttendanceIslandsResponse>(request);
        if (response) {
            return response.islands;
        }
        return null;
    }

    async getAttendants(islandId: string): Promise<IGetIslandAttendantsResponse> {

        const request: IGetIslandAttendantsRequest = {
            ...this.baseRequest(apiRequestType.attendanceIsland.getAttendants),
            islandId,
            cursor: null,
        }
        const response: IGetIslandAttendantsResponse = await this.send<IGetIslandAttendantsResponse>(request);
        if (response) {
            return response;
        }
        return null;
    }

    async saveIsland(island: IServiceIsland): Promise<IServiceIslandServer> {
        island.nsType = ENonSerializableObjectType.serviceIsland
        const request: ISaveServiceIslandRequest = {
            ...this.baseRequest(apiRequestType.attendanceIsland.saveIsland),
            ns: island
        }
        const response: ISaveServiceIslandResponse = await this.send<ISaveServiceIslandResponse>(request, SpinType.none);
        return response.ns;
    }

    public saveTemporaryPrioritization(prioritization: ITemporaryQueuePrioritization) {
        return this.api.sendRequest<
            IChangeTemporaryQueuePrioritizationRequest,
            IChangeTemporaryQueuePrioritizationResponse
        >(EAttendanceIslandRequest.changeTemporaryQueuePrioritization)({
            prioritization,
        });
    }

    async saveAttendants(attendants: IIslandAttendant[]): Promise<boolean> {
        const request: ISaveIslandAttendantsRequest = {
            ...this.baseRequest(apiRequestType.attendanceIsland.saveAttendants),
            attendants
        }
        return this.isSuccessfull(request);
    }

    async deleteIsland(islandId: string): Promise<boolean> {
        const request: IDeleteAttendanceIslandRequest = {
            ...this.baseRequest(apiRequestType.attendanceIsland.deleteIsland),
            islandId
        }
        return this.isSuccessfull(request);
    }

    async deleteIslandAttendant(islandAttendantId: IdDepWithError<ENonSerializableObjectType.attendantAllowedAvatar>): Promise<boolean> {
        const request: IDeleteIslandAttendantRequest = {
            ...this.baseRequest(apiRequestType.attendanceIsland.deleteAttendants),
            islandAttendantId,
        }
        return this.isSuccessfull(request);
    }

}
