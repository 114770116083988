import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { IBasicUniversalInfo } from '../comm-interfaces/aux-interfaces';
import { ISecurityScreenGroup, ISerializableText, IUniversalJSON, TFieldTextArray, TScreenGroupArray } from '../comm-interfaces/business-interfaces';
import { IMultimediaObjectJSON } from '../comm-interfaces/multi-media-interfaces';
import { coreClientConf, TArrayID } from '../core-constants/types';
import { MultimediaInstance } from '../multi-media/barrel-multimedia';
import { MultimediaObject } from '../multi-media/multi-media-object';
import { newGlobalUID, UberCache } from '../persistency/uber-cache';
import { constRosetta } from '../rosetta/const-rosetta';
import { hasPermissionInRoles, hasPermissionInRolesIDs } from '../rules/sec-functions';
import { TRoleArray } from "../security/role";
import { EIdMenus } from '../shared-business-rules/colmeia-tags/id-menus';
import { nonUniquizedCapitalized } from '../shared-business-rules/social-cc/social-cc-rules';
import { EIdMenuContractAccessType, IMenuContractConfig, IMenuItemContractConfig, IScreenGroupMenuContractConfig, ISubMenuContractConfig, TIdMenuContractAccess } from '../shared-business-rules/social-network/social-network-config';
import { allAdHocSubMenusConfig } from '../shared-business-rules/social-network/social-network-config-ad-hoc-submenus';
import { ITranslationConfig } from "../shared-business-rules/translation/translation-engine";
import { EScreenGroups } from '../shared-business-rules/visual-constants';
import { nonNullable } from '../tools/type-utils';
import { add, createIndexedSearch, deepSet, getClock, getDate, hashToArray, isInvalid, isThisOneOfThat, isValidAndEqual, isValidArray, isValidRef, isValidString, keys, pickKeys, values } from '../tools/utility';
import { TDeepMap } from '../tools/utility-types';
import { constant, nonTranslatedField, TGlobalUID } from './constant';
import { EBasicRenderInfoType, IBasicRenderInfo } from './small-serializable';
import { UserDefaultSettings } from './user-default-settings';
import { PrimaryId, PrimaryIdOf } from '@colmeia/core/src/core-constants/named-types/named-primary-ids';



const failbackLanguage: TGlobalUID = constRosetta.language.portuguese;
export type TICurrentFieldArray = Array<ICurrentField>;

export interface ICurrentField {
    currentValue: string;
    idField: number;
    idLanguage: TGlobalUID;
}
export interface ICurrentSerializableFields {
    [idField: number]: ICurrentField;
}

export type TSerializableArray = Array<Serializable>;


export type TSubMenuControl = Array<ISecurityScreenGroup>;
export type TMenuSecurityControl = { [idMenu: string]: TSubMenuControl };


export class Serializable<IdObjectType extends TGlobalUID = TGlobalUID> implements IBasicRenderInfo {


    private static menuElements: TScreenGroupArray = [];
    private static menuSecurityControl: TMenuSecurityControl = {};
    private static menuContractConfigItems: IMenuContractConfig[]

    private idObjectType: TGlobalUID;
    private primaryID: TGlobalUID;
    private waitingReponse: boolean;
    private multimediaObject: MultimediaObject = null;
    private businessID: string;
    private socialContext: TGlobalUID;
    private excluded: boolean;
    private serializableTextArray: TFieldTextArray;
    private idMultiMedia: TGlobalUID;
    private dateCreation: Date;
    private posGenerated: boolean;
    private visualPriority: number;
    private needToRetrySending: boolean;
    private privilege: TGlobalUID;
    private displayable: boolean;
    private clockTick: number;
    private idAngularRoute: string;
    private hasAnnotationOnS: boolean;
    public screenGroup: TScreenGroupArray;




    protected constructor(idObjectType: IdObjectType, primaryID: TGlobalUID) {
        this.primaryID = primaryID ? primaryID : newGlobalUID();
        this.idObjectType = idObjectType;
        this.socialContext = constant.socialContext.colmeia;
        this.waitingReponse = false;
        this.excluded = false;
        this.serializableTextArray = [];
        this.clockTick = getClock();
        this.dateCreation = new Date(this.clockTick);
        this.addUberCache();
        this.needToRetrySending = false;
        this.businessID = null;
    };

    public isProcessedByServer(): boolean {
        return isValidString(this.businessID);
    }
    public getClockTick(): number { return this.clockTick; };
    protected _setClockTick(clock: number): void { this.clockTick = clock };


    public getSocialContext(): TGlobalUID { return this.socialContext };
    public excludedFromContext(): boolean { return this.excluded };

    public getVisualPriority(): number { return this.visualPriority; };
    public setVisualPriority(visualPriority: number) { this.visualPriority = visualPriority; };

    public getSerializableObjectTypeID(): TGlobalUID { return this.idObjectType; };
    public getContainerPKTable(): TGlobalUID { return null };


    public isObject(idObjectType: TGlobalUID): boolean {
        return this.idObjectType == idObjectType;
    }

    public issNot(...serializableArray: TSerializableArray): boolean {
        return !this.iss(...serializableArray);
    };

    public getBasicUniversalInfo(idField: number = constant.serializableField.name): IBasicUniversalInfo {
        return {
            primaryID: this.primaryID,
            clockTick: this.clockTick,
            idObjectType: this.idObjectType,
            name: this.getSerializableText(idField),
            idMedia: this.getBestMediaID()
        };
    };

    public getBestMediaID(): string {
        return this.multimediaObject ? this.multimediaObject.getBestIDMedia() : null
    };

    public isNot(...idArray: Array<TGlobalUID>): boolean {
        return !this.is(...idArray);
    };

    public is(...idArray: Array<TGlobalUID>): boolean {
        for (const primaryID of idArray) {
            if (primaryID && primaryID === this.getPrimaryID()) {
                return true;
            };
        };
        return false;
    };

    public iss(...serializableArray: TSerializableArray): boolean {
        for (const serializable of serializableArray) {
            if (serializable.getPrimaryID() === this.getPrimaryID()) {
                return true;
            };
        };
        return false;
    };




    public toJSON(): IUniversalJSON {
        let json: IUniversalJSON;


        if (!this.serializableTextArray) {
            this.serializableTextArray = [];
        };

        // Elimina campos vazios
        let idx: number;
        while ((idx = this.serializableTextArray.findIndex((txt) => { return !txt.text || txt.text.trim().length == 0 })) > -1) {
            this.serializableTextArray.splice(idx, 1);
        };

        let k: number = 0;
        let aux: ISerializableText;

        while (k < this.serializableTextArray.length) {
            aux = this.serializableTextArray[k];
            if (!aux.idValue) {
                const current: ISerializableText = this.serializableTextArray
                    .filter((t) => { return t.idValue && t.idField == aux.idField })
                    .sort((x, y) => { return y.clockTick - x.clockTick })[0]

                if (current && current.text == aux.text) {
                    this.serializableTextArray.splice(k, 1);
                    k = -1; // começa de novo, com uma nova configuração de array
                };
            };
            ++k;
        };

        json = {
            idObjectType: this.idObjectType, primaryID: this.primaryID,
            businessID: this.businessID,
            exluded: this.excluded,
            clockTick: this.clockTick,
            timeZone: getDate().getTimezoneOffset(),
            idMultimedia: this.idMultiMedia, // deve manter sempre o que vem do server
            visualPriority: this.visualPriority,
            multimediaObject: this.multimediaObject ? this.multimediaObject.toJSON() : null,
            serializableTextArray: this.serializableTextArray,
            _posGenerated: this.posGenerated,
            screenGroup: this.screenGroup,
            necessaryPermission: this.privilege,
            idAngularRoute: this.idAngularRoute,
            hasAnnotation: this.hasAnnotationOnS,
        };
        return json;
    };

    public setClientGenerated(): void { this.posGenerated = false; };

    public getMyScreenGroups(): TScreenGroupArray {
        const allSecSG: TScreenGroupArray = Serializable.menuElements.filter((me) => { return this.is(me.primaryID); });
        return allSecSG;
    };

    public hasAnnotation(): boolean { return this.hasAnnotationOnS; };
    public setAnnotation(annotation: boolean): void { this.hasAnnotationOnS = annotation; };

    public getScreenGroupWithIdentifier(identifier: string): TGlobalUID {
        const options: TScreenGroupArray = this.getMyScreenGroups();
        const screen: ISecurityScreenGroup = options.find((sg) => { return sg.params == identifier });
        return screen ? screen.screenGroup : null;
    };

    public getScreenGroupWithNoNullIdentifier(): TGlobalUID {
        const options: TScreenGroupArray = this.getMyScreenGroups();
        const screen: ISecurityScreenGroup = options.find((sg) => { return !sg.params });
        return screen ? screen.screenGroup : null;
    };

    public getScreenGroupWithNoNullIdentifierOrAny(): TGlobalUID {
        const options: TScreenGroupArray = this.getMyScreenGroups();
        const screen: ISecurityScreenGroup = options.find((sg) => { return !sg.params });
        return screen ? screen.screenGroup : (isValidArray(options) ? options[0].screenGroup : null);
    };

    public getScreenGroupWithIdentifierOrAny(identifier: string): TGlobalUID {
        const options: TScreenGroupArray = this.getMyScreenGroups();
        const screen: ISecurityScreenGroup = options.find((sg) => { return sg.params == identifier });
        return screen ? screen.screenGroup : (isValidArray(options) ? options[0].screenGroup : null);
    };



    public getAngularRouteID(): string { return this.idAngularRoute; };

    public updateHeader(json: IUniversalJSON): void {

        if (json.multimediaObject) {
            if (this.idObjectType != constant.objectType.interaction || !this.isWaitingResponse()) {
                this.multimediaObject = null;
                UberCache.removeFromCache(json.multimediaObject.primaryID);
                let mmObject: MultimediaObject = UberCache.bugFactoryMultiMedia(this.primaryID);

                mmObject.rehydrate(json.multimediaObject); // <= seguindo padrão, rehydrate coloca no cache
                this.multimediaObject = mmObject;
            };
        };
        this.visualPriority = json.visualPriority;
        this.privilege = json.necessaryPermission;
        this.serializableTextArray = json.serializableTextArray ? json.serializableTextArray : [];
        this.clockTick = json.clockTick;
        this.dateCreation = new Date(json.clockTick);
        this.screenGroup = json.screenGroup;
        if (isValidArray(json.screenGroup)) {
            Serializable.menuElements.push(...json.screenGroup.filter(
                secScreen => {
                    return Serializable.onlyUniqueScreen(secScreen);
                }));

            json.screenGroup.filter((el) => { return el.idMenu }).forEach((sec: ISecurityScreenGroup) => {
                if (!Serializable.menuSecurityControl[sec.screenGroup]) {
                    Serializable.menuSecurityControl[sec.screenGroup] = [];
                }

                Serializable.menuSecurityControl[sec.screenGroup].push({ idMenu: sec.idMenu, idPermission: sec.idPermission, primaryID: sec.primaryID, screenGroup: sec.screenGroup, params: undefined });
            })

            Serializable.initMenuContractConfigItems();
        };

        Serializable.initSearchTranslationMap()
    };


    public static getIdMenuConfig: (idMenu: TIdMenuContractAccess) => IMenuContractConfig;
    public static getMenusConfigByParent: (idMenuParent: TIdMenuContractAccess) => IMenuContractConfig[];
    public static getMenuContractConfigItems(): IMenuContractConfig[] {
        return Serializable.menuContractConfigItems;
    }


    private static mountSubMenusConfig() {
        const $config = pickKeys<ISubMenuContractConfig>();

        const configItems: ISubMenuContractConfig[] = keys(allAdHocSubMenusConfig)
            .map(add($config.idMenu))
            .map(add($config.type, () => EIdMenuContractAccessType.EAdHocSubMenus as const))
            .map(add($config.idMenuParent, item => nonNullable(allAdHocSubMenusConfig[item.idMenu])))
            ;

        return configItems;
    }
    public static initMenuContractConfigItems(): void {
        const screenGroups: TIdMenuContractAccess[] = Serializable.getAllMenus() as TIdMenuContractAccess[];
        const menus = screenGroups.map(screenGroup => Serializable.getAllSubMenus(screenGroup)).flat()
        const screenGroupsConfig: IScreenGroupMenuContractConfig[] = screenGroups.map((screenGroup: EScreenGroups): IScreenGroupMenuContractConfig => ({
            type: EIdMenuContractAccessType.EScreenGroups,
            idMenu: screenGroup,
        }))

        const menusConfig: IMenuItemContractConfig[] = menus.map(menu => ({
            type: EIdMenuContractAccessType.EIdMenus,
            idMenuParent: menu.screenGroup as EScreenGroups,
            idMenu: menu.idMenu as EIdMenus,
        }))

        const subMenusConfig: ISubMenuContractConfig[] = Serializable.mountSubMenusConfig();
        Serializable.menuContractConfigItems = [...screenGroupsConfig, ...menusConfig, ...subMenusConfig];
        Serializable.getIdMenuConfig = createIndexedSearch(Serializable.menuContractConfigItems, $ => [
            $.idMenu,
        ]);
        Serializable.getMenusConfigByParent = createIndexedSearch(
            Serializable.menuContractConfigItems,
            $ => [
                $.idMenuParent,
            ],
            { isToMany: true }
        );
    }

    public static getAllMenus(): TArrayID {
        return Object.keys(Serializable.menuSecurityControl);
    }

    public static getAllSubMenus(screenGroup: string): TSubMenuControl {
        return Serializable.menuSecurityControl[screenGroup];
    }

    public static slowGetIdMenu(primaryID: string, screenGroup: string): string {
        const found: ISecurityScreenGroup = Serializable.menuSecurityControl[screenGroup].find((sc: ISecurityScreenGroup) => sc.primaryID === primaryID);

        return found && found.idMenu;
    }

    static onlyUniqueScreen(secScreen: ISecurityScreenGroup): boolean {
        return !Serializable.menuElements
            .map(screen => screen.primaryID)
            .includes(secScreen.primaryID)
    }

    protected rehydrate(json: IUniversalJSON): void {
        this.idObjectType = json.idObjectType;
        this.primaryID = json.primaryID;
        this.businessID = json.businessID;
        this.posGenerated = json._posGenerated;
        this.idMultiMedia = json.idMultimedia;
        this.idAngularRoute = json.idAngularRoute;
        this.hasAnnotationOnS = json.hasAnnotation;
        this.updateHeader(json);
        UberCache.addUberHash(this);
    };


    public getPrivilegeNeeded(): TGlobalUID { return this.privilege; };

    public doIHaveThePermissionsForID(roles: TArrayID): boolean {
        return isInvalid(this.privilege) || hasPermissionInRolesIDs(roles, this.privilege);
    };


    public doIHaveThePermissionsFor(roles: TRoleArray): boolean {
        return isInvalid(this.privilege) || hasPermissionInRoles(roles, this.privilege);
    };


    public setDisplayable(isDisplayable: boolean): void { this.displayable = isDisplayable; };
    public isDisplayable(): boolean { return this.displayable; };


    public getSerializable(): Serializable { return this; };
    public getBusinessID(): string { return this.businessID; };


    public getPrimaryID(): PrimaryIdOf<this> { return this.primaryID; };
    public getDate(): Date { return this.dateCreation; };

    public getMultimediaObject(): MultimediaObject { return this.multimediaObject; };


    public getMultimediaIDwithIDTag(idMediaTag: TGlobalUID): TGlobalUID {
        let mmInstance: MultimediaInstance = this.getMultimediaInstanceWithIDTag(idMediaTag);
        let idMedia: TGlobalUID;
        if (mmInstance) {
            idMedia = mmInstance.getFileIdMedia();
        };
        return idMedia;
    };

    public getMultimediaInstanceWithIDTag(idMediaTag: TGlobalUID): MultimediaInstance {
        let mmInstance: MultimediaInstance;
        if (this.multimediaObject) {
            mmInstance = this.multimediaObject.getMultimediaInstanceByTag(idMediaTag);
        };
        return mmInstance;
    };

    public getMultimediaObjectJSON(): IMultimediaObjectJSON {
        return this.multimediaObject ? this.multimediaObject.toJSON() : null;
    }

    public getThumbnailOfaParentOrMainMultimedia(idMultimediaKey: TGlobalUID): MultimediaInstance {
        let mmInstance: MultimediaInstance;
        if (this.multimediaObject) {
            mmInstance = this.multimediaObject.getThumbnailOfaParentOrMainMultimedia(idMultimediaKey);
        };
        return mmInstance;
    };


    public getIdAngularRouteFromScreenGroup(screenGroupId: string): string {
        const screenGroups = Serializable.menuElements
        return

        // return screenGroup.idAngularRoute
    }

    public static getVisualElementFromScreenGroup(screenGroup: string, isGroup: boolean = true): TSerializableArray {
        let selected: TSerializableArray = [];

        for (let visual of Serializable.menuElements) {
            if (isGroup ?
                visual.screenGroup === screenGroup :
                visual.screenGroup.substr(0, screenGroup.length) === screenGroup) {
                selected.push(UberCache.unsafeUberFactory(visual.primaryID));
            };
        };
        return selected.sort((x, y) => { return y.visualPriority - x.visualPriority });
    };


    public replaceSerializableTextArray(array: TFieldTextArray): void { this.serializableTextArray = array; };
    public getSerializableTextArray(): TFieldTextArray { return this.serializableTextArray; };

    protected addSerializableText(idField: number, txt: string): string {
        const idLanguage: TGlobalUID = UserDefaultSettings.getDefaultLanguageID();
        const actual: ISerializableText = this.getSerializableField(idField, idLanguage);
        if (!actual) {
            this.addNewStringElement(idField, txt, idLanguage);
        } else if (actual.text != txt) {
            if (actual.idValue) {
                this.addNewStringElement(idField, txt, idLanguage);
            } else {
                actual.text = txt;
            }
        };
        return txt;
    };


    public static getJText(json: IUniversalJSON, idField: number = constant.serializableField.name): string {
        return nonUniquizedCapitalized(Serializable.getText(json.serializableTextArray, idField));
    };

    public static getJTextFields(json: IUniversalJSON, ...idFields: Array<number>): string {
        for (const idField of idFields) {
            const text: string = Serializable.getText(json.serializableTextArray, idField);
            if (isValidRef(text)) {
                return text;
            }
        };
        return null;
    };

    public static isFullfJText(json: IUniversalJSON, idField: number = constant.serializableField.name): boolean {
        const ret: string = Serializable.getText(json.serializableTextArray, idField);
        return (ret ? true : false) && ret.trim().length > 0
    };



    public static getText(array: TFieldTextArray, idField: number): string {
        const notTranslatable: boolean = isThisOneOfThat(idField, ...nonTranslatedField);
        const idLanguage: TGlobalUID = notTranslatable ? failbackLanguage : UserDefaultSettings.getDefaultLanguageID();

        // como adiciono o testo no começo da array, o primeiro a ser encontrado é o atual
        let txt: ISerializableText = Serializable.getJSerializableText(array, idField, idLanguage);

        if (!notTranslatable && !txt && UberCache.isClientSide()) {
            txt = Serializable.getJSerializableText(array, idField, failbackLanguage);
        };

        return txt ? txt.text : Serializable.getAnyLanguageText(array, idField);
    };


    public static getAnyLanguageText(array: TFieldTextArray, idField: number): string {
        // como adiciono o testo no começo da array, o primeiro a ser encontrado é o atual
        const txt: ISerializableText = Serializable.getJAnyLanguageSerializableText(array, idField);
        return txt ? txt.text : null;
    };

    public static getJAnyLanguageSerializableText(array: TFieldTextArray, idField: number): ISerializableText {
        return array.find((text) => {
            return text.idField == idField && text.isValid
                ;
        });
    };

    static upsertTextToJSON(json: IUniversalJSON, text: string, idField: number = constant.serializableField.name) {
        const textFromSerializable = Serializable.getJText(json, idField)
        if (!isValidString(textFromSerializable)) {
            // insert
            Serializable.addTextToJSON(json, text, constant.serializableField.name);
        } else {
            // update
            const idx = json.serializableTextArray.findIndex(txt => txt.idField === idField && txt.isValid)
            const isIdxExists = idx != -1
            if (isIdxExists) {
                const target = json.serializableTextArray[idx]
                json.serializableTextArray[idx] = {
                    ...target,
                    text,
                    clockTick: getClock()
                }
            }
        }
    }

    public static getJSerializableText(array: TFieldTextArray, idField: number, idLanguage: TGlobalUID): ISerializableText {
        return array.find((text) => {
            return text.idField == idField && text.idLanguage == idLanguage && text.isValid
        });
    };

    public static getTranslation(translationField: ITranslationConfig, isReturningFieldNameIfCantFindTranslation: boolean = true): string | undefined {
        let text: string | undefined;
        try {
            text = Serializable.searchTranslation(translationField);
        } catch (err) {
            console.error(err);
        }
        if (isInvalid(text)) Serializable.printMissingTranslation(translationField);

        if (text) return text;
        if (isReturningFieldNameIfCantFindTranslation) return translationField?.meta?.text ?? translationField.fieldName;
    }

    public static hasTranslation(translationField: ITranslationConfig): boolean {
        try {
            return isValidRef(Serializable.searchTranslation(translationField))
        } catch (err) {
            return false;
        }
    }


    public static saveMissingTranslations: boolean = false;
    private static missingTranslations: TDeepMap<[
        primaryID: string,
        idField: number,
        config: ITranslationConfig
    ]> = new Map();

    private static printMissingTranslation(translationField: ITranslationConfig): void {
        if (Serializable.saveMissingTranslations) {
            deepSet(Serializable.missingTranslations)(
                translationField.serializableId,
                translationField.idField,
                translationField
            )
        }
        console.error('Missing translation', translationField);
    }

    public static getMissingTranslations(): ITranslationConfig[][] {
        return [...Serializable.missingTranslations.values()].map(config => [...config.values()])
    }

    private static getTranslationText(translationField: ITranslationConfig): string {
        const instance = Serializable.staticFactory(translationField.serializableId);
        return instance.getSerializableText(translationField.idField);
    }

    public getSerializableText(idField: number): string {
        return Serializable.getText(this.serializableTextArray, idField);
    };

    public getNextAvailableField(idBeginField: number = constant.serializableField.chat_text): number {
        for (let k = 0; k < coreClientConf.limits.maxMultiValue; ++k) {
            const text: string = this.getSerializableText(k + idBeginField)
            const isValidText: boolean = text !== null
            if (!isValidText) {
                return k + idBeginField;
            }
        };
        return 0;
    };

    public getCurrentValidText(): ICurrentSerializableFields {
        return Serializable.getAllValidText(this.serializableTextArray);
    };

    public static getAllValidText(serializableTextArray: TFieldTextArray): ICurrentSerializableFields {
        const current: ICurrentSerializableFields = {};
        if (isValidArray(serializableTextArray)) {
            for (const field of serializableTextArray) {
                if (!isValidRef(current[field.idField])) {
                    current[field.idField] = {
                        idField: field.idField,
                        idLanguage: field.idLanguage,
                        currentValue: Serializable.getText(serializableTextArray, field.idField)
                    }
                };
            };
        };
        return current;
    };

    public static getAllLanguageValidText(serializableTextArray: TFieldTextArray, idLanguage: TGlobalUID): ICurrentSerializableFields {
        const current: ICurrentSerializableFields = {};
        if (isValidArray(serializableTextArray)) {
            for (const field of serializableTextArray.filter((x) => { return x.idLanguage === idLanguage })) {
                if (!isValidRef(current[field.idField])) {
                    current[field.idField] = {
                        idField: field.idField,
                        idLanguage: field.idLanguage,
                        currentValue: Serializable.getText(serializableTextArray, field.idField)
                    }
                };
            };
        };
        return current;
    };


    public static getTextAsArray(text: TFieldTextArray): Array<string> {
        const current: ICurrentSerializableFields = Serializable.getAllValidText(text);
        return (<TICurrentFieldArray>hashToArray(current)).map((x) => { return x.currentValue });
    };


    public static replaceCurrentSerializableField(serializableTextArray: TFieldTextArray, newState: ICurrentSerializableFields): boolean {
        let changed: boolean = false;
        for (const prop in newState) {
            const idField: number = parseInt(prop)
            const oldText: string = Serializable.getText(serializableTextArray, idField)
            const newText: string = newState[prop].currentValue
            if (!isValidAndEqual(oldText, newText)) {
                changed = true;
                Serializable.addTextToSerializableTextArray(
                    serializableTextArray,
                    newText, idField, getClock(),
                    newState[prop].idLanguage)
            };
        };
        const processed: { [idField: number]: boolean } = {};
        for (const text of serializableTextArray) {
            if (!newState[text.idField] && !processed[text.idField]) {
                changed = true;
                processed[text.idField] = true;
                Serializable.addTextToSerializableTextArray(serializableTextArray, '', text.idField, getClock(), text.idLanguage)
            };
        };
        return changed;
    };

    // O primeiro a ser encontrado é o mais recente
    private getSerializableField(idField: number, idLanguage: TGlobalUID): ISerializableText {
        return this.serializableTextArray.find((text) => {
            return text.idField == idField && text.idLanguage == idLanguage && text.isValid
        });
    };



    public static addTextToJSON(json: IUniversalJSON, text: string, idField: number = constant.serializableField.name,
        idLanguage: TGlobalUID = UserDefaultSettings.getDefaultLanguageID()): void {
        if (!json.serializableTextArray)
            json.serializableTextArray = [];

        Serializable.addTextToSerializableTextArray(json.serializableTextArray, text, idField,
            json.clockTick ? json.clockTick : getClock(),
            idLanguage);
    };

    public static addTextToSerializableTextArray(serializableTextArray: TFieldTextArray, text: string, idField: number, clockTick: number, idLanguage: string): void {
        serializableTextArray.unshift({
            idField: idField,
            clockTick: clockTick,
            idLanguage: idLanguage,
            text: text,
            idValue: null,
            isValid: true
        });
    }

    private addNewStringElement(idField: number, txt: string, idLanguage: TGlobalUID): void {
        Serializable.addTextToSerializableTextArray(this.serializableTextArray, txt, idField, getClock(), idLanguage);
    };

    public getName(): string {
        return nonUniquizedCapitalized(this.getSerializableText(constant.serializableField.name));
    };
    public getIcon(): string { return this.getSerializableText(constant.serializableField.icon); };
    public getSVGIcon(): string { return this.getSerializableText(constant.serializableField.auxiliars.aux18); };
    public hasSVGIcon(): boolean { return isValidRef(this.getSVGIcon()); };
    public getDescription(): string { return this.getSerializableText(constant.serializableField.description); };
    public getPrompt(): string { return this.getSerializableText(constant.serializableField.prompt); };
    public getDisclaimer(): string { return this.getSerializableText(constant.serializableField.disclaimer); };
    public getTooltip(): string { return this.getSerializableText(constant.serializableField.tooltip); };
    public getMessage(): string {
        return this.getSerializableText(constant.serializableField.chat_text);
    };
    public isEdited(idField: number = constant.serializableField.name): boolean {
        return this.serializableTextArray.filter((text) => { return text.idField == idField }).length > 1;
    }

    public getOrder(): number {
        const value = this.getSerializableText(constant.serializableField.auxiliars.aux32);
        
        return isValidString(value) ? Number(value) : undefined;
    };

    public getEmoji(): string { return this.getSerializableText(constant.serializableField.emoji) }
    public getObjectTypeID(): TGlobalUID { return this.idObjectType; };

    public setName(name: string): string {
        return this.addSerializableText(constant.serializableField.name, name);
    };

    public setDescription(description: string): string {
        return this.addSerializableText(constant.serializableField.description, description);
    };

    public setTooltip(tooltip: string): string {
        return this.addSerializableText(constant.serializableField.tooltip, tooltip)
    };

    public setMessage(message: string): string {
        return this.addSerializableText(constant.serializableField.chat_text, message)
    };

    public setMultimediaObject(multiMediaObject: MultimediaObject): void {
        if (multiMediaObject) {
            UberCache.addUberHash(multiMediaObject);
            this.multimediaObject = multiMediaObject;
        };
    };

    public isWaitingResponse(): boolean { return this.waitingReponse }
    public dataSent(): void {
        this.waitingReponse = true;

    };

    public dataReceived(): void {
        this.waitingReponse = false;
        this.needToRetrySending = false;
    };


    public isNeedToRetrySending(): boolean { return this.needToRetrySending; }

    //

    public static factoryMessage(json: IUniversalJSON): Serializable {
        let serializable: Serializable = <Serializable>UberCache.unsafeUberFactory(json.primaryID, false);
        if (serializable == null)
            serializable = new Serializable(json.idObjectType, json.primaryID);
        serializable.rehydrate(json);
        return serializable;
    };

    public static staticFactory(primaryID: TGlobalUID): Serializable {

        return <Serializable>UberCache.unsafeUberFactory(primaryID, true);
    };

    public static isOnCache(idObjectType: TGlobalUID, primaryID: TGlobalUID): boolean {
        return UberCache.testCache(primaryID);
    };

    public addUberCache(): void {
        UberCache.addUberHash(this);
    };

    public removeUberCache(clearPrimaryID: boolean = false): void {
        UberCache.removeFromCache(this.getPrimaryID());
        if (clearPrimaryID) {
            this.primaryID = null;
        };
    };

    public static isServerSide(): Boolean { return UberCache.isServerSide(); };

    getBasicInfoType(): EBasicRenderInfoType {
        return EBasicRenderInfoType.serializable;
    }

    getIdMedia(): string {
        return this.getBestMediaID();
    }

    static findByScreenGroup(screenGroupId: string): Serializable[] {
        const allSerializablesArr: TSerializableArray = UberCache.getAllSerializableArray();

        return allSerializablesArr.filter(ser => {
            return ser.getMyScreenGroups().some(sg => sg.screenGroup === screenGroupId)
        });
    }

    private static searchTranslationMap = Serializable.getSearchTranslationMap();
    private static initSearchTranslationMap(): void {
        Serializable.searchTranslationMap = Serializable.getSearchTranslationMap();
    }
    private static getSearchTranslationMap() {
        const items: Serializable[] = UberCache.getSerializables();
        const map: TDeepMap<[primaryID: string, idField: number, text: string]> = new Map();
        items.forEach(item => item.serializableTextArray.forEach(subItem => deepSet(map)(item.primaryID, subItem.idField, subItem.text)));
        return map;
    }

    public static searchTranslation(config: ITranslationConfig): string | undefined {
        return Serializable.searchTranslationMap.get(config.serializableId)?.get(+config.idField);
    }

}


