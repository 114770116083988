import { typedScreenOptions } from "../../../tools/utility";
import { EAllSerializable, getTranslationsFields } from "../all-serializables";


const dataExtractorScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.DataExtractor,
    fields: {
        dataExtractor: 1, // Extrator de dados
        dataExtractorIcon: 18, // perm_data_setting
    } as const
})
export const dataExtractorTranslations = {
    ...getTranslationsFields(dataExtractorScreenOptions),
};
