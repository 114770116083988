import { Component, Input, Inject } from '@angular/core';
import { IBBCodeViewModalHandlerParameters, BBCodeViewModalHandler } from 'app/handlers/bbcode-view.handler';
import { SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IColmeiaDialogComponentData } from '../dialogs/dialog/dialog.component';

@Component({
    selector: 'app-bbcode-view-modal',
    templateUrl: './bbcode-view-modal.component.html',
    styleUrls: ['./bbcode-view-modal.component.scss']
})
export class BBCodeViewModalComponent {
    
    _handler: BBCodeViewModalHandler;
    @Input()
    get handler(): BBCodeViewModalHandler { return this._handler }
    set handler(value: BBCodeViewModalHandler) { this._handler = value; }
    get parameters(): IBBCodeViewModalHandlerParameters { return this.handler.getComponentParameter() }
    get text(): string { return this.parameters.text }
    get isWhatsApp() { return this.parameters.isWhatsApp }
    get style() { return this.parameters.style }

    html: SafeHtml;
    
    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: IColmeiaDialogComponentData<BBCodeViewModalHandler>,
    ) {
        this.handler = data.getParamsToChildComponent();
    }
}
