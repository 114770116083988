import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Injectable, Injector } from "@angular/core";
import { EventManager } from "@angular/platform-browser";
import { UserDefaultSettings } from "@colmeia/core/src/business/user-default-settings";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { HardwareLayerService } from "app/services/hardware";
import { SessionService } from "app/services/session.service";
import { debounce } from "lodash";
import { Subject, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { NotificationDialogComponent } from "./notification-dialog.component";
import {
    NOTIFICATION_DIALOG_DATA,
    TNotificationDialogConfig,
} from "./notification-dialog.model";
import { NotificationDialogRef } from "./notification-dialog.ref";

@Injectable({
    providedIn: "root",
})
export class NotificationDialogService {
    static gutter: number = 20;
    static windowHeightMaxDivisor: number = 2; //1 = 100%, 2 = 50%, 3 = 33.33%

    public itemClosed = new Subject<NotificationDialogRef>();
    private notificationsRefs: NotificationDialogRef[] = [];
    private windowHeight: number;
    private notificationsQueue: NotificationDialogRef[] = [];
    private canShowNotification: boolean = true;

    constructor(
        private overlay: Overlay,
        private injector: Injector,
        private hardwareSvc: HardwareLayerService,
        // private sessionSvc: SessionService,
        eventManager: EventManager,
    ) {
        this.windowHeight = window.innerHeight;

        eventManager.addEventListener(document.documentElement, "resize", () => {
            this.windowHeight = window.innerHeight;
        });

        this.setupQueueListeners();
    }

    private getHeightLimitOffset() {
        return this.windowHeight / NotificationDialogService.windowHeightMaxDivisor;
    }

    private setupQueueListeners() {
        this.itemClosed.subscribe(() => {
            if(this.notificationsQueue.length === 0 || !this.canShowNotification) {
                return;
            }

            this.shiftQueuedNotification();
        });
    }

    private shiftQueuedNotification() {
        const notificationRef = this.notificationsQueue[0];
        this.showNotification(notificationRef);
        this.notificationsQueue.shift();
    }

    private createOverlay(config: TNotificationDialogConfig<any>): OverlayRef {
        const positionStrategy = this.overlay.position().global();
        positionStrategy.top(config.containerTopPosition || "70px");
        positionStrategy.right(config.containerRightPosition || `${NotificationDialogService.gutter}px`);

        const overlayConfig = new OverlayConfig({
            positionStrategy,
            panelClass: ["c-notification-overlay-wrapper", "hidden"],
        });

        return this.overlay.create(overlayConfig);
    }

    private queueNotification(notificationRef: NotificationDialogRef) {
        this.notificationsQueue.push(notificationRef);
    }

    private attach<T, D>(
        content: ComponentType<T>,
        config: TNotificationDialogConfig<D>
    ) {
        // TODO implement importance lvl
        /**
        pseudo

        if(config.importanceLvl = EImportanceLevels.Max) {
            this.showOnTop(content, config);
        }
        */
        const overlayRef = this.createOverlay(config);

        const notificationRef = new NotificationDialogRef<D>(overlayRef, config);
        this.notificationsRefs.push(notificationRef);

        const injector = this.getInjector<D>(config, notificationRef);
        const notificationPortal = new ComponentPortal(content, null, injector);

        overlayRef.attach(notificationPortal);

        if(!this.canShowNotification) {
            this.queueNotification(notificationRef);
        } else {
            this.showNotification(notificationRef);
        }

        return notificationRef;
    }

    private showNotification(notificationRef: NotificationDialogRef): void {
        this.updateItemsOffset();
        this.updateCanShowNotificationState();

        let updateSoundSubscription: Subscription;

        if(notificationRef.config.mute !== true) {
            this.playSound();
            updateSoundSubscription = notificationRef._update.subscribe(() => {
                this.playSound();
            });
        }

        notificationRef.closed.pipe(first()).subscribe(({ ref }) => {
            this.onRefClose(ref);

            if(isValidRef(updateSoundSubscription)) {
                updateSoundSubscription.unsubscribe();
            }
        });

        notificationRef.show();
    }

    private updateCanShowNotificationState() {
        this.canShowNotification = this.currentElementOnScreenHeight() < this.getHeightLimitOffset();
    }

    private currentElementOnScreenHeight(): number {
        return this.getVisibleNotifications().reduce((currentValue, ref) => {
            return currentValue + ref.overlay.overlayElement.offsetHeight;
        }, 0);
    }

    private onRefClose(ref: NotificationDialogRef) {
        this.notificationsRefs.splice(this.notificationsRefs.indexOf(ref), 1);
        this.updateItemsOffset();
        this.updateCanShowNotificationState();

        this.itemClosed.next(ref);
    }

    private getVisibleNotifications(): NotificationDialogRef[] {
        return this.notificationsRefs.filter(ref => ref.visible);
    }

    private updateItemsOffset = debounce(() => {
        let currentYOffset = 0;
        const notifications = this.getVisibleNotifications();

        for (let i = 0; i < notifications.length; ++i) {
            const ref = notifications[i];
            const elementHeight = ref.overlay.overlayElement.offsetHeight;

            ref._offsetY = currentYOffset;
            ref._updateStyle();
            currentYOffset += elementHeight + NotificationDialogService.gutter;
        }
    }, 500);

    private playSound = debounce(() => {
        this.hardwareSvc.getAudio().playSound(UserDefaultSettings.getDialogNotificationSound()[0]);
    }, 200);

    private getInjector<D>(
        config: TNotificationDialogConfig<D>,
        notificationRef: NotificationDialogRef<D>
    ) {
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: NotificationDialogRef, useValue: notificationRef },
                { provide: NOTIFICATION_DIALOG_DATA, useValue: config.data },
            ],
        });
    }

    // Public methods
    open<D>(config: TNotificationDialogConfig<D>, allowDuplicatedMessages?: boolean): NotificationDialogRef<D> {
        if (allowDuplicatedMessages) {
            this.canShowNotification = true;
        }
        return this.openFromComponent(NotificationDialogComponent, config);
    }

    replace<D>(ref: NotificationDialogRef<D>, config: TNotificationDialogConfig<D>): NotificationDialogRef<D> {
        ref.config = { ...ref.config, ...config };
        ref.update();
        return ref;
    }

    openFromComponent<T, D>(
        component: ComponentType<T>,
        config: TNotificationDialogConfig<D>
    ): NotificationDialogRef<D> {
        return this.attach(component, config);
    }
}
