import { ITransactionServer } from "@colmeia/core/src/shared-business-rules/knowledge-base/bot-transaction/bot-transaction";
import { IBotActionAsset, IBotActionAssetContainer, TIBotActionAssetContainerArray } from "../../../bot/bot-action-model";
import { ENextGenBotElementType, getEmptyAction, INextGenBotServer } from "../../../bot/bot-model";
import { EBPMType } from "../../../BPM/bpm-model";
import { GraphElement } from "../../../graph/essential/graph-element";
import { ENonSerializableObjectType, INonSerializable } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { BPMConfigToolbarSelector } from "../../toolbar/bpm-config-toolbar-selector";
import { EBPMElementMode, TReferencedObject, TReferencedObjectArray } from "../../toolbar/config-toolbar.types";
import { NSGenericHost } from "../ns/ns.host";
import { INodeDeletedConnection } from "../ns/ns.host.types";


export class BotHostNode extends NSGenericHost {

    constructor(root: INextGenBotServer | ITransactionServer) {
        super(root);
    }

    isNodeCreatable(): boolean {
        const ns = super.getNonSerializable();
        const botLevel = (ns as INextGenBotServer).botLevel;

        return ns.nsType === ENonSerializableObjectType.contentGenerator
            || botLevel == ENextGenBotElementType.root
            || botLevel == ENextGenBotElementType.menuContainer
            || botLevel == ENextGenBotElementType.botMenuItem
            || botLevel == ENextGenBotElementType.formTransaction
    }

    public getElementType(): TReferencedObject {
        return this.getHostedObject<INextGenBotServer>().botLevel;
    }

    public isRoot(): boolean {
        return (super.getNonSerializable() as INextGenBotServer).botLevel === ENextGenBotElementType.root;
    }

    public isMenuItem(): boolean {
        return (super.getNonSerializable() as INextGenBotServer).botLevel === ENextGenBotElementType.botMenuItem;
    }

    static isItemToMenuContainer(fromType: ENextGenBotElementType, toType: ENextGenBotElementType): boolean {
        return (
            fromType === ENextGenBotElementType.botMenuItem &&
            toType === ENextGenBotElementType.menuContainer
        );
    }

    static isItemToRoot(fromType: ENextGenBotElementType, toType: ENextGenBotElementType): boolean {
        return (
            fromType === ENextGenBotElementType.botMenuItem &&
            toType === ENextGenBotElementType.root
        );
    }

    static isRootToMenuContainer(fromType: ENextGenBotElementType, toType: ENextGenBotElementType): boolean {
        return (
            fromType === ENextGenBotElementType.root &&
            toType === ENextGenBotElementType.menuContainer
        );
    }

    static isMenuContainerToRoot(fromType: ENextGenBotElementType, toType: ENextGenBotElementType): boolean {
        return (
            fromType === ENextGenBotElementType.menuContainer &&
            toType === ENextGenBotElementType.root
        );
    }

    static isMenuContainerToItem(fromType: ENextGenBotElementType, toType: ENextGenBotElementType): boolean {
        return (
            fromType === ENextGenBotElementType.menuContainer &&
            toType === ENextGenBotElementType.botMenuItem
        );
    }

    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        const isInternalAsset = BPMConfigToolbarSelector.getOneToolbarElement(EBPMType.bot, nodeTypeToDrag).mode == EBPMElementMode.InternalElement
        // const canDragOn = (isValid(fromToolbarElement.canDragOn) && fromToolbarElement.canDragOn.includes(toType))

        return isInternalAsset
    }

    public getNonSerializable(): INextGenBotServer {
        return super.getNonSerializable() as INextGenBotServer;
    }

    public getHostedType(): TReferencedObject {
        return this.getNonSerializable().botLevel;
    }

    public handleRemoveSubTree(graphElement: GraphElement): INonSerializable[] {
        return [];
    }

    public isEditable(graphElement: GraphElement) {
        return true;
    }

    public setAction(newMenuItemActionAsset: IBotActionAsset) {
        throw new Error("Method not implemented.");
    }

    public getActionContainer(): TIBotActionAssetContainerArray {
        return [{
            action: undefined
        }]
    }

    public getPredicateTypesFromState(): TReferencedObjectArray {
        throw new Error("Method not implemented.");
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        const con = this.getConnectionTargetData()[0];
        return con.connectionType != null && con.targetHostId != null
    }

    public deleteConnectionTo(anotherHost: NSGenericHost, connectionType: TReferencedObject): INodeDeletedConnection {
        const actionContainer = this.getActionContainer()[0]

        if (actionContainer.action) {
            const action = actionContainer.action
            const isActionPointingToAnotherElement = action.idElement == anotherHost.getHostedID()
            if (isActionPointingToAnotherElement && connectionType == action.type) {
                this.setAction(getEmptyAction())
                return { deletedConnection: true }
            }
        }

        return { deletedConnection: false }
    }
}
