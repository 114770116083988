import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { RootComponent } from 'app/components/foundation/root/root.component';
import { TGlobalUID } from '@colmeia/core/src/business/constant';
import { IGeneralFormAnswer, TGeneralFormServerAnswerArray, IGeneralFormAnswerServer } from '@colmeia/core/src/general-form/general-form-answer';
import {
    IFormSchema,
    TFormSchemmaArray,
    INonserializableSchemaResponseServer
} from '@colmeia/core/src/general-form/general-form-interface';
import { GeneralFormService } from 'app/services/general-form.service';
import { MatSelectChange } from '@angular/material/select';
import { IGeneralAnswerListItem, IAnnotationPreviewData } from 'app/model/general-form.model';
import { DateService } from 'app/services/date.service';
import { HandlerHexagonon, EHexagonSizes } from 'app/handlers/hexagono.handler';
import { isInvalid, isValidArray, isValidRef, isInvalidArray } from '@colmeia/core/src/tools/utility';
import { ProfileAvatarStoreService } from 'app/services/profile-avatar-store.service';
import { LookupService } from 'app/services/lookup.service';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { AnnotationPreviewModalComponent } from '../annotation-preview-modal/annotation-preview-modal.component';
import { ColmeiaDialogService } from 'app/services/dialog/dialog.service';

export interface IAvatarHandlerHash {
    [idAvatar: string]: HandlerHexagonon
}

@Component({
    selector: 'annotations-list',
    templateUrl: './annotations-list.component.html',
    styleUrls: ['./annotations-list.component.scss']
})
export class AnnotationsListComponent extends RootComponent<
    'add' |
    'noAnnotations' |
    'selectTemplate' |
    'noTemplate' |
    'user' |
    'text' |
    'seeAnnotation' |
    'template' |
    'date'
> {

    displayedColumns: string[] = ['avatar', 'text', 'template', 'date'];
    hexagonHandlers: IAvatarHandlerHash = {};
    showSpinner: boolean = true;

    @Output() onListError = new EventEmitter<any>();
    @Output() onListLoad = new EventEmitter<void>();
    @Input() header: string;
    @Input() sourceId: TGlobalUID;
    @Input() hideListControls: boolean = false;
    @Input() hasSecurityFilter: boolean = false;
    @Input() set showAvatar(show: boolean) {
        const hasAvatar = this.displayedColumns.includes('avatar');

        if (show && !hasAvatar) {
            this.displayedColumns.splice(0, 0, 'avatar');
        } else if (!show && hasAvatar) {
            this.displayedColumns.splice(0, 1);
        }
    }

    currentTemplateFilter: IFormSchema = undefined;
    schema: IFormSchema

    annotations: IGeneralAnswerListItem[] = [];
    allAnnotations: IGeneralAnswerListItem[] = [];

    @Input() selectedSchema: IFormSchema
    @Input() schemas: TFormSchemmaArray;
    @Input() answersFromSourceId: IGeneralFormAnswerServer[]

    emptyTemplate: IFormSchema = {
        idSchemma: null,
        form: null,
        name: null,
        visibility: null
    }


    constructor(
        // private dialogSvc: ColmeiaDialogService,
        private generalFormSvc: GeneralFormService,
        private dateSvc: DateService,
        private avatarSvc: ProfileAvatarStoreService,
        private cdr: ChangeDetectorRef,
        private lookupSvc: LookupService,
        private dialogSvc: ColmeiaDialogService,
    ) {
        super({
            add: gTranslations.common.add,
            text: gTranslations.common.text,
            date: gTranslations.common.date,

            noAnnotations: gTranslations.annotations.noAnnotations, //
            selectTemplate: gTranslations.annotations.selectTemplate, //
            noTemplate: gTranslations.annotations.noTemplate, //
            user: gTranslations.annotations.user, //
            seeAnnotation: gTranslations.annotations.seeAnnotation, //
            template: gTranslations.annotations.template,//
        });
    }


    async setCurrentTemplateFilter() {
        if (!this.schemas) return

        if (this.selectedSchema) this.currentTemplateFilter = this.selectedSchema

        if (this.currentTemplateFilter) this.setCurrentTemplateFilterToOriginalSchemaReference()

        this.onTemplateSelected()
    }



    setCurrentTemplateFilterToOriginalSchemaReference() {
        this.currentTemplateFilter = this.getOriginalSchemaReference(this.currentTemplateFilter)
    }
    getOriginalSchemaReference(selectedSchema: IFormSchema) {
        return this.schemas.find(schema => schema.name === selectedSchema.name)
    }

    async ngOnInit() {

        await this.loadData()

        this.setCurrentTemplateFilter();
    }

    @Input() limitHeight = false


    onTemplateSelected(event?: MatSelectChange) {
        this.annotations = this.allAnnotations
        if (this.currentTemplateFilter) this.filterAnnotations()
    }

    filterAnnotations() {
        this.annotations = this
            .annotations
            .filter(
                annotation => annotation.idSchemma === this.currentTemplateFilter.idSchemma
            )
    }

    async loadSchemmasByIds(ids: TArrayID) {
        const nonSerializablesSchemmas = await this
            .lookupSvc
            .getBatchNonSerializables<INonserializableSchemaResponseServer[]>(ids);

        this.schemas = nonSerializablesSchemmas.map(ns => ns.schemma);
    }

    async loadData() {
        this.showSpinner = true;

        let annotations: TGeneralFormServerAnswerArray = await this.generalFormSvc.getAnswersForUniqueId(this.sourceId, this.hasSecurityFilter);

        if (isInvalidArray(annotations)) {this.showSpinner = false; return }

        const idSchemas: string[] = annotations.map(annotation => annotation.idSchemma).filter(id => isValidRef(id));

        if (isInvalidArray(idSchemas)) {this.showSpinner = false;return;}

        await this.loadSchemmasByIds(idSchemas);

        if (isValidArray(annotations)) {
            annotations = annotations.sort((a, b) => b.clockTick - a.clockTick);

            this.allAnnotations = annotations.map(ann => {

                const schema = this.schemas.find(schema => schema.idSchemma === ann.idSchemma);

                const templateName = schema && schema.name;
                const item: IGeneralAnswerListItem = {
                    ...ann,
                    templateName,
                    userName: null,
                    date: this.dateSvc.getBestDate(new Date(ann.clockTick))
                }
                return item;
            })

            this.onListLoad.next();
        }

        const hasOldFormat = this.hasOldFormat(annotations);

        this.onListError.emit({ sourceId: this.sourceId, hasOldFormat });
        this.showSpinner = false;
    }



    hasOldFormat(annotations) {
        return annotations.filter(Boolean).some(ann => !ann.clockTick)
    }
    async prepareAnnotations(annotations: TGeneralFormServerAnswerArray): Promise<IGeneralAnswerListItem[]> {
        return Promise.all(annotations.map(ann => this.loadAnnotationData(ann)))
    }

    async loadAnnotationData(ann: IGeneralFormAnswerServer): Promise<IGeneralAnswerListItem> {
        let avatar = null;

        if (isValidRef(ann.ident) && isValidRef(ann.ident.idAvatar)) {
            avatar = await this.avatarSvc.getAvatarFromServerIfNotOurs(ann.ident.idAvatar)
            if (isInvalid(this.hexagonHandlers[ann.ident.idAvatar])) {
                this.hexagonHandlers[ann.ident.idAvatar] = HandlerHexagonon.newHandler({
                    serializable: avatar,
                    size: EHexagonSizes.xss
                });
            }
        }

        const associatedTemplated = this.getTemplate(ann);
        const templateName: string = associatedTemplated ? associatedTemplated.name : '';

        return {
            ...ann,
            userName: avatar ? avatar.getNickName() : '',
            templateName,
            date: this.dateSvc.getBestDate(new Date(ann.clockTick)) // bug clocktick vazio
        }
    }

    getTemplate(annotation: IGeneralFormAnswer): IFormSchema {
        return this.schemas
            ? this.schemas.find(schema => schema.idSchemma === annotation.idSchemma)
            : undefined
    }

    seeAnnotation(ann: IGeneralFormAnswerServer) {
        if (ann.idSchemma === null) {
            return;
        }
        this.previewAnnotation(ann, this.getTemplate(ann), {})
    }


    previewAnnotation(annotation: IGeneralFormAnswerServer, template: IFormSchema, options = {}) {
        const data: IAnnotationPreviewData = { annotation, template, ...options };

        this.dialogSvc.open({
            componentRef: AnnotationPreviewModalComponent,
            dataToComponent: { data: { ...data, ...options } },
            panelClass: "medium-size",
            hideHeader: false
        })
    }
}
