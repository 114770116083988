import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import {ChipAutocompleteHandler} from "../../handlers/chip-autocomplete.handler";
import {Serializable, TSerializableArray} from "@colmeia/core/src/business/serializable";

@Component({
  selector: 'app-chip-autocomplete',
  templateUrl: './chip-autocomplete.component.html',
  styleUrls: ['./chip-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipAutocompleteComponent implements OnInit {

    private _handler: ChipAutocompleteHandler;

    @Input()
    set handler(handler: ChipAutocompleteHandler){
        this._handler = handler;
        this._handler.getParent().setChildren(this)
    }

    get handler(): ChipAutocompleteHandler {
        return this._handler;
    }

    separatorKeysCodes: number[] = [ENTER, COMMA];
    elementCtrl = new UntypedFormControl();
    filteredElements$: Observable<string[]>;
    subscription: Subscription;

    @ViewChild('auto', {static: true}) matAutocomplete: MatAutocomplete;
    @ViewChild('userInput', {static: true}) userInput: ElementRef<HTMLInputElement>;

    constructor(private cdRef: ChangeDetectorRef) {
        this.filteredElements$ = this.elementCtrl.valueChanges.pipe(
            startWith(null),
            map((element: string | null) => (element
                ? this._filter(element)
                : this.handler.getAllElements()
                    .map(serializable => serializable.getName()).slice()))
        );
    }

    ngOnInit(): void {
        console.log('chip autocomplete HANDLER: ', this.handler)
    }

    chipAutoCompleteClear(){
        this.handler.clearChosenElements()
        this.cdRef.markForCheck()
    }

    add(event: MatChipInputEvent): void {
        // Add fruit only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
            const input = event.input;
            const value = event.value;

            // Add our fruit
            const elem = this.nameToSerializable(this.handler.getAllElements(), value);
            if ((value || '').trim() && elem) {
                this.handler.addElement(elem);
                this.handler.chosenListChangedCallback();
            }

            // Reset the input value
            if (input) {
                input.value = '';
            }

            this.elementCtrl.setValue(null);
        }
    }

    remove(itemName: Serializable): void {
        const idx = this.handler.getChosenElements()
            .map(serializable => serializable.getName())
            .findIndex(name => name.includes(itemName.getName()));
        if (idx >= 0) {
            this.handler.getChosenElements().splice(idx, 1);
            this.handler.chosenListChangedCallback();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const elem = this.nameToSerializable(this.handler.getAllElements(), event.option.viewValue);
        if (elem) {
            this.handler.addElement(elem);
            this.handler.chosenListChangedCallback()
        }
        this.userInput.nativeElement.value = '';
        this.userInput.nativeElement.blur();
        this.elementCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        const result = this.handler.getAllElements()
            .map(serializable => serializable.getName())
            .filter(itemName => itemName && itemName.toLowerCase().includes(filterValue))
        return result
    }

    private nameToSerializable(elements: TSerializableArray, name: string): Serializable {
        const foundElement = elements.find(elem => elem && elem.getName() && elem.getName().includes(name))
        return foundElement
            ? foundElement
            : null
    }
}
