import {nop} from "@colmeia/core/src/tools/utility";
import { ICmModalParameter, ECmModalState } from "app/model/cm-modal.model";

export class CmModalHandler {
    constructor(private parameters: ICmModalParameter) { }

    static new(parameter: Partial<ICmModalParameter>) {
        return new CmModalHandler({
            state: ECmModalState.Closed,
            componentProjectionInstance: null,
            settings: {
                panelClass: `customizations-mat-dialog`
            },
            data: null,
            ...parameter,
        });
    }

    getState(): ECmModalState {
        return this.parameters.state;
    }

    getProjection() {
        return this.parameters.componentProjectionInstance;
    }

    safeAfterOpen(): () => void {
        return this.parameters.onWindowOpenedCallback
            ? this.parameters.onWindowOpenedCallback
            : nop;
    }

    safeAfterClose(): () => void {
        return this.parameters.onWindowClosedCallback
            ? this.parameters.onWindowClosedCallback
            : nop;
    }

    safeBeforeClose(): () => void {
        return this.parameters.beforeWindowCloseCallback
            ? this.parameters.beforeWindowCloseCallback
            : nop;
    }

    safeSettings(): any {
        return this.parameters.settings || {};
    }

    safeData(): any {
        return this.parameters.data || null;
    }
}
