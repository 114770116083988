import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, ɵresetCompiledComponents } from '@angular/core';
import { environment } from './environments/environment-client';
import { AppModule } from './app/app.module';
import { UserDefaultSettings } from "@colmeia/core/src/business/user-default-settings";

// declare var window: any;
declare var module: any;

const bootstrap = async () => {
    UserDefaultSettings.initProfile();
    if (environment.production) {
        enableProdMode();
    }

    try {
        await platformBrowserDynamic().bootstrapModule(AppModule);
    } catch (e) {
        window.error = e;
        console.error(e);
    }

};

if (module['hot']) {
    module['hot'].accept();
    module['hot'].dispose(() => ɵresetCompiledComponents());
}

bootstrap();
