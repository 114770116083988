import { IBotActionAsset } from "../../../bot/bot-action-model"
import { IFriendlyExtraMessage } from "../../../../comm-interfaces/business-interfaces"
import { getBotAction } from "../../../bot/bot-action-config-db";
import { EBotActionType } from "../../../bot/new-bot-action";
import { isInvalid } from "../../../../tools/utility";
import { actionMissIdElement } from "../error-messages";

export const validateBotAction = (action:IBotActionAsset) : Array<IFriendlyExtraMessage> => {
//export const validateBotAction = (action:IBotActionAsset) : Array<String> => {
    const ret : Array<IFriendlyExtraMessage> = [];
    const configAction = actionConfig(<EBotActionType>action.type);
    if(configAction.hasIDElemt && isInvalid(action.idElement)){
        ret.push(actionMissIdElement);
    }
    return ret;
    
}

const actionConfig = (actionType: EBotActionType) => getBotAction(actionType)