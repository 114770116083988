import { Location } from '@angular/common';
import { Injectable, NgZone, StaticProvider } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidString } from '@colmeia/core/src/tools/utility';
import { CopyToClipboardService } from 'app/services/copy-to-clipboard.service';
import { DateService } from 'app/services/date.service';
import { GlobalWarningService } from 'app/services/global-warning.service';
import { LookupService } from 'app/services/lookup.service';
import { RoutingService } from 'app/services/routing.service';
import { filter, first, take } from 'rxjs/operators';
import { GenericHomeHandler } from '../generic-dashboard-home/generic-home.handler';
import { ColmeiaWindowRuntime } from './colmeia-window-runtime';
import { IWindowRuntimeInitDescriptor, IWindowRuntimeSetupConfig } from './colmeia-window.model';
import { ColmeiaWindowService } from './colmeia-window.service';
import { MainHandler } from 'app/handlers/main-handler';

@Injectable({
    providedIn: 'root'
})
export class ColmeiaWindowInitService {

    constructor(
        private location: Location,
        private routing: RoutingService,
        private activatedRoute: ActivatedRoute,
        private windowSvc: ColmeiaWindowService,
        private zone: NgZone,
        private warningSvc: GlobalWarningService,
        private copyToClipboardSvc: CopyToClipboardService,
        private router: Router,
        private lookupSvc: LookupService,
        private dateSvc: DateService
    ) { }

    public setup(config: IWindowRuntimeSetupConfig): DashboardWindowInitiator {
        return new DashboardWindowInitiator(
            config,
            this.location,
            this.routing,
            this.activatedRoute,
            this.windowSvc,
            this.zone,
            this.warningSvc,
            this.copyToClipboardSvc,
            this.router,
            this.lookupSvc,
            this.dateSvc,
        );
    }
}


export class DashboardWindowInitiator<O = any> {
    constructor(
        private windowConfig: IWindowRuntimeSetupConfig,
        private location: Location,
        private routing: RoutingService,
        private activatedRoute: ActivatedRoute,
        private windowSvc: ColmeiaWindowService,
        private zone: NgZone,
        private warningSvc: GlobalWarningService,
        private copyToClipboardSvc: CopyToClipboardService,
        private router: Router,
        private lookupSvc: LookupService,
        private dateSvc: DateService
    ) {

        if(isValidString(this.windowConfig.initialPath)) {
            const windowEntry = this.windowSvc.push({ setActive: true });
            const navigationStart$ = this.router.events.pipe(filter( e => e instanceof NavigationStart ));

            const initialPathSubscription = this.windowSvc.anyUpdate$().subscribe(() => {
                    const { currentActiveIndex } = this.windowSvc;
                    if(currentActiveIndex === windowEntry) {
                        this.windowSvc.setCurrentUrl(this.windowConfig.initialPath);
                    }
                });

            navigationStart$.pipe(take(1)).subscribe(() => {
                if(this.windowSvc.currentActiveIndex === windowEntry) {
                    this.windowSvc.shiftWindow(false, windowEntry);
                }
                initialPathSubscription.unsubscribe();
            });
        }
    }

    getDescriptor(): IWindowRuntimeInitDescriptor {
        const { createPath, editPath, hasNesting } = this.windowConfig;
        const { snapshot } = this.activatedRoute;

        const allParams = this.routing.getAllParamsFromSnapshot(snapshot);

        const openCreateDialog = isValidString(createPath) && this.routing.isActive(createPath, allParams);
        const openEditExact = isValidString(editPath) && this.routing.isActive(editPath, allParams);
        const openEditSubset = isValidString(editPath) && this.routing.isActive(editPath, allParams, true);
        const openEditByNest = openEditSubset && hasNesting;
        const openEditDialog = openEditExact || openEditByNest;

        return {
            openCreateDialog,
            openEditExact,
            openEditSubset,
            openEditByNest,
            openEditDialog,
            autoOpen: true
        }
    }

    run(
        descriptor: IWindowRuntimeInitDescriptor,
        obj?: O,
        homeHandler?: GenericHomeHandler,
        genericHandler?: MainHandler,
        additionalProviders: StaticProvider[] = []
    ): ColmeiaWindowRuntime {

        const editRuntime = new ColmeiaWindowRuntime({
                windowService: this.windowSvc,
                routing: this.routing,
                location: this.location,
                warningSvc: this.warningSvc,
                zone: this.zone,
                copyToClipboardSvc: this.copyToClipboardSvc,
                lookupSvc: this.lookupSvc,
                dateSvc: this.dateSvc,
            },
            this.windowConfig,
            descriptor,
            homeHandler,
            obj as unknown as INonSerializable,
            genericHandler,
            additionalProviders,
        );

        const windowRef = this.windowSvc.openWindow(editRuntime);

        editRuntime.setDialogRef(windowRef);

        return editRuntime;
    }
}
