import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment-client';

@Injectable({
    providedIn: 'root'
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {

    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        if (!environment.production) {
            return fn();
        }

        if (route.data?.preload) {
            return fn();
        }

        return of(null);
    }
}
