import { constant, TGlobalUID } from '../business/constant';
import { Serializable } from '../business/serializable';
import { IBasicUniversalInfo } from '../comm-interfaces/aux-interfaces';
import { IUniversalJSON } from "../comm-interfaces/business-interfaces";
import { INonSerializable } from '../shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { isValidRef } from '../tools/utility';
import { getBestIdMediaFromNS, getBestIDMEdiaFromUniversal } from "./mm-functions";

export function getEnclodedPlayer(idPlayer: TGlobalUID): string {
    return constant.structures.begin
        + idPlayer
        + constant.structures.begin
}

export function getSessionIdentifier(idPlayer: TGlobalUID, isMobile: boolean): string {
    const idQueue: string = getEnclodedPlayer(idPlayer) + Date.now().toString() + (isMobile ? constant.structures.mobile : '');
    console.log({ idQueue });
    return idQueue;
};


export function getNormalizedError(error: any): Error {
    if (error.hasOwnProperty('rejection')) {
        error = error['rejection'];
    }
    return error;
}

export function getPlainError(err: Error): Error {
    if (isValidRef(err['toJSON'])) {
        return (<any>err).toJSON()
    }
    return err;
}

export function getSafeError(err: Error): Error {
    return {
        message: err?.message,
        stack: err?.stack,
        name: err?.name
    }
}

export function universalToBasicInfo(universal: IUniversalJSON, idField: number = constant.serializableField.name): IBasicUniversalInfo {
    return {
        idObjectType: universal.idObjectType,
        primaryID: universal.primaryID,
        clockTick: universal.clockTick,
        idMedia: getBestIDMEdiaFromUniversal(universal),
        name: Serializable.getJText(universal, idField),
    };
};

export function nonSerializableToBasicInfo(ns: INonSerializable): IBasicUniversalInfo {
    return {
        clockTick: ns.clockTick,
        idMedia: getBestIdMediaFromNS(ns),
        name: ns.nName,
        primaryID: ns.idNS,
        idObjectType: ns.nsType
    };
};


// Copia mantendo a referencia
export function copyBasicInfoFields(from: IBasicUniversalInfo, to: IBasicUniversalInfo): void {
    to.clockTick = from.clockTick;
    to.idMedia = from.idMedia;
    to.idObjectType = from.idObjectType;
    to.primaryID = from.primaryID;
    to.name = from.name;
}




