import { isValidString, objectShallowReplace, typedClone } from '@colmeia/core/src/tools/utility';
import { GenericDashboardEditHandler } from 'app/handlers/generic-dashboard-edit.handler';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { GenericNonSerializableService } from 'app/services/generic-ns.service';
import { LookupService } from 'app/services/lookup.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ColmeiaWindowRuntime } from './colmeia-window-runtime';
import { IWindowRef, TColmeiWindowRintimeCompareFunction } from "./colmeia-window.model";
import { MainHandler } from 'app/handlers/main-handler';

export class DashboardWindowEditorRef<NS extends object | INonSerializable = INonSerializable, D = any> implements IWindowRef<NS> {
    public get object (): NS {
        return this.runtime.multableObject as NS;
    };
    public get editHandler(): GenericDashboardEditHandler {
        return this.runtime.editHandler
    };
    public get editGenericHandler(): MainHandler {
        return this.runtime.genericHandler
    };
    private get editRuntime(): ColmeiaWindowRuntime {
        return this.runtime
    }

    public nsService: GenericNonSerializableService;

    constructor(
        private runtime: ColmeiaWindowRuntime,
        public readonly data: D,
        public dashboardSvc: DashBoardService,
        public api: ServerCommunicationService,
        public lookupSvc: LookupService
    ) {
        this.nsService = this.dashboardSvc.genericNonSerializableService;
    }

    upsertStoredData(data: NS) {
        objectShallowReplace(this.object, data as Partial<NS>)
        this.syncObjects();
    }

    close(reloadHomeList?: boolean, syncObjects?: boolean) {
        this.editRuntime.shouldReloadHomeList = reloadHomeList;

        if(syncObjects) {
            this.syncObjects();
        }

        this.runtime.subjects.close.next();
    }

    onSaveButtonClick(handler: () => Promise<boolean>) {
        this.runtime.setSaveButtonClick(async () => {
            const result = await handler();

            this.editRuntime.subjects.onSavedNS.next(result);

            if(!result) return result;

            this.syncObjects();
            this.editRuntime.reloadList();

            return result;
        });
    }

    reloadParentList() {
        this.editRuntime.reloadList();
    }

    syncObjects() {
        this.editRuntime.syncSourceObject();
    }

    hasChange(): boolean {
        return this.editRuntime.hasChange();
    }

    public get isEdit(): boolean {
        return isValidString((<INonSerializable> this.object).idNS, 30) || this.editRuntime.isEdit;
    }

    public minimize(): void {
        this.runtime.minimize();
    }

    public getSourceObjectCopy(): NS {
        return typedClone(this.runtime.sourceObject) as NS;
    }

    public setCompareFunction(fn: TColmeiWindowRintimeCompareFunction<NS>): void {
        this.runtime.setCompareFunction(fn as TColmeiWindowRintimeCompareFunction);
    }

    public afterCreated() {
        this.runtime.afterCreated();
    }
}
