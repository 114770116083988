
export enum ECRMDBType {
    customer = 'customer', // Clientes
    product = 'produt', // Produtos
    configurableItem = 'confItem', // Itens de Configuração (Serial#)
    issueClassification = 'issClass', // Classificação de Incidente
    division = 'division', // Divisão
}
// Atenção criar novos canonicos globais para
// IDProdutp


// standards

export enum ESeverityLevel {
    severity1 = 'severity1', //Production application down or major malfunction resulting in a product inoperative condition. Users are unable to perform
    severity2 = 'severity2', // Critical loss of application functionality or performance resulting in a high number of users unable to perform their normal functions. Major feature/product failure; inconvenient workaround or no workaround exists. The program is usable but severely limited.
    severity3 = 'severity3', // Moderate loss of application functionality or performance resulting in multiple users impacted in their normal functions. Minor feature/product failure, a convenient workaround exists/minor performance degradation/not impacting production. 
    severity4 = 'severity4',// Minor loss of application functionality, product feature requests, how-to questions. The issue consists of "how-to" questions including issues related to one or multiple modules and integration, installation and configuration inquiries, enhancement requests, or documentation questions. 
};

export enum EUrgencyLevel {
    urgent = 'urgent',
    normal = 'normal'
}

export enum ESupportLevel {
    tier0 = 'tier0', //Tier-0 IT Support level, also called as Level-0 IT support, is usually the automated machine interactive support level. There is little or no scope for immediate human interaction when receiving support.
    tier1 = 'tier1', // Tier-1 support or Level 1 support represents a very basic level of support service, usually provided by IT personnel having the lowest level of skills and access levels.
    tier2 = 'tier2', //The Tier-2 support level is for providing in-depth troubleshooting, technical analysis, and support from the backend.
    tier3 = 'tier3', // A level consisting of SMEs (Subject Matter Expert) on the product or service your organization is providing support to.
    tier4 = 'tier4', // This Tier-4 (or Level 4) support usually comes into the picture when some of the services are outsourced by the parent organization.
}

export enum EServiceManagementPhases {
    detect = 'detect', // Ocorrência Inicial (pode ser um usuário, um log, etc..)
    record = 'record', // Registro do Caso

    classify = 'classify', // Classificação da ocorrência (ex OmniSense => Bot => Validação => Conditional Display),
    prioritize = 'prioritize',  // Função entre Severidade e Urgencia
    
    investigate = 'investigate',
    diagnose = 'diagnose',
    
    resolution = 'resolution',
    restore = 'restore',
    
    closure = 'closure',
};

export enum ETicketState {
    new = 'new',
    inProgress = 'inProgress',
    OnHold = 'OnHold',
    Closed = 'Closed',
    Canceled = 'Canceled',
    reopened = 'reopened',
    waitingEscalation = 'waitingEscalation'
}


// Aqui vamos colocar isso com
export enum ECloseState {
    // solved
    workaround = 'workaround',
    solved = 'solved', 
    failed = 'failed',
    closedByCaller = 'closedByCaller',
    closedByCustomerTimeout = 'closedByCustomerTimeout'
}


/**
 * Essas interfaces são as representações dos objetos canonicos
 * salvos no postgree
 * 
 * Primary keys
 *  closeState
 *  phase
 *  severityLevel
 *  supportLevel
 *  ticketState
 *  urgencyLevel
 */

export interface ICRMCanonicalItem {
    name: string;
}

export type TICRMCanonicalItems = Array<ICRMCanonicalItem>;

export interface ICRMCloseStateCanonicalItem extends ICRMCanonicalItem {
    /**
     * Primary key
     */
    closeState: ECloseState;
    isSuccess: boolean;
    isExpired: boolean;
}

export interface ICRMPhaseCanonicalItem extends ICRMCanonicalItem {
    /**
     * Primary key
     */
    phase: EServiceManagementPhases;
    level: number;
}

export interface ICRMSeverityLevelCanonicalItem extends ICRMCanonicalItem {
    /**
     * Primary key
     */
    severityLevel: ESeverityLevel;
    level: number;
}

export interface ICRMSupportLevelCanonicalItem extends ICRMCanonicalItem {
    /**
     * Primary key
     */
    supportLevel: ESupportLevel;
    level: number;
}

export interface ICRMTicketStateCanonicalItem extends ICRMCanonicalItem {
    /**
     * Primary key
     */
    ticketState: ETicketState;
    isOpen: boolean;
}

export interface ICRMUrgencyLevelCanonicalItem extends ICRMCanonicalItem {
    /**
     * Primary key
     */
    urgencyLevel: EUrgencyLevel;
    level: number;
}

export enum ECRMCanonicalItemsTypes {
    Severity = 'severity',
    UrgencyLevel = 'UrgencyLevel',
    SupportLevel = 'SupportLevel',
    Phase = 'Phases',
    TicketState = 'TicketState',
    CloseState = 'CloseState',
}

export type TCRMTicketCanonicalItems = ESeverityLevel | EUrgencyLevel | ESupportLevel | EServiceManagementPhases | ETicketState | ECloseState;