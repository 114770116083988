import { MainHandler } from "app/handlers/main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";

export interface IDynamicComponentParameter<Handler extends MainHandler = MainHandler, Component = any, Inputs extends object = {}> {
    id?: string;
    component: Component;
    handler?: Handler;
    inputs?: Inputs;
    children?: Node[][];
    onInit?: () => void;
}

export class DynamicComponentHandler<Handler extends MainHandler = MainHandler, Component = any> extends MainHandler {
    private constructor(parameter: IDynamicComponentParameter) { super(parameter as {} as IComponentParameter); }
    public static factory<Handler extends MainHandler = MainHandler, Component = any>(parameter: IDynamicComponentParameter<Handler, Component>) { return new DynamicComponentHandler(parameter); }
    public getComponentParameter() { return super.getComponentParameter() as IDynamicComponentParameter & IComponentParameter };
}