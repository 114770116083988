<h4 mat-dialog-title>Selecione a ação:</h4>
  <div class="content">
    <ng-container *ngFor="let toolbarElement of toolbarElements">
      <button
        mat-flat-button
        id="{{ toolbarElement.referencedObject }}"
        class="button"
        type="button"
        (click)="toolbarElementSelected(toolbarElement)"
      >
        <img class="icon" src="{{ toolbarElement.icon }}" alt="{{ toolbarElement.icon }}" />
        {{ toolbarElement.title }}
      </button>
    </ng-container>
</div>