export type SignalType = number;

export const observableList = {
    subscription: 1,
    changeStateSignal: 2,
    auth: {
        loginByCookie: 10,
        init: 11,
        socketReady: 12,
        logout: 13,
    },
    multiMedia: {
        uploadingImage: 20,
        player: 21,
    },
    interaction: {
        incommingInteraction: 30,
        incommingChained: 31,
        answerMessage: 32,
        notHydrated: 33,
        scroolSearch: 34,
    },
    synch: {
        playerCachedChanged: 40,
    },
    tracker: {
        typing: 50,
        messageRead: 52,
        multimediaViewd: 53,

    },
    ui: {
        reactHandler: 60,
        dropdownHandler: 65,
        refreshTranslation: 66,
        exclusiveAction: 67
    },
    features: {
        batchUpload: 70,
    },
    share: {
        message: 80
    },
    payments: {
        sell: 90
    },
    geo: {
        activate: 100,
        deactivate: 101
    },
    interfaceState: {
        stateChange: 110,
        hideLeftBar: 111,
        hideRightBar: 112
    },
    mobile: {
        buttons: {
            panic: {
                panic: 210,
                chat: 211
            },
            volume: {
                up: 200,
                down: 201
            },
            call: {
                start: 202,
                end: 203
            },
            menu: 204,
            search: 205,
            back: 206
        },
        state: {
            resumed: 207,
            paused: 208,
            activated: 209
        },
        notification: {
            group: 300,
        }
    },
    groups: {
        newGroupInserted: 500
    },
    macros: 400
};
