import { isValidContentArray } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';
import { isValidNumber, isValidRegex } from '@colmeia/core/src/tools/utility';
import { isValidArray, isValidString, isValidRef, isValidAndEqual, isThisOneOfThat } from '../../tools/utility';
import { IBasicCondition, IOnCondition, EMetaEngagementConditionType, CONDITION_TOKEN_DELIMITER, ESourceOfInfo, TIDateValidationArray, IDateValidation, EDateOps, IMetaEngagementDateValidation } from '../metadata/meta-engagement';
import { IBasicAsset } from './bot-asset-model';
import { isActionAsset } from './bot-function-model-helper';
import { EMessageRecency } from './bot-content-model';
import { isValidAssetAction } from './asset-functions';
import { dbControlMetaEngagement, EEngagementValueTypes, getAllMetaEngagementConfigsByCondition, getBestMetaEngagementConfigByCondition, getValueRegexByCondition } from '../metadata/meta-engagement-db';
import { EBPMAction, IMultipleNestedFormConfig, TAllBPMACtion } from '../BPM/bpm-action-model';
import { EDateElements, EValidFormatFinal } from '../../tools/date/date-utils.types';

export function getConditionValueTokens(value: string): string[] {
    return value?.split(CONDITION_TOKEN_DELIMITER).map(token => token.trim()).filter(str => isValidString(str)) || [];
}

export function getConditionTokensAsString(tokens: string[]): string {
    return tokens.map(token => {
        return token.includes(CONDITION_TOKEN_DELIMITER)
            ? getConditionValueTokens(token)
            : [token]
    }).flat().filter(str => isValidString(str)).join(CONDITION_TOKEN_DELIMITER);
}

export function hasLateStateInjectionCondition(condition: IBasicCondition): boolean {
    return condition.sourceInfo === ESourceOfInfo.serviceScheduler
}

export function isValidBasicCondition(condition: IBasicCondition, bpmAction?: TAllBPMACtion): boolean {
    const hasAllRequiredProps = isValidRef(condition) && isValidString(condition.idConditionalAsset) && isValidRef(condition.metaConditionType);

    if (!hasAllRequiredProps) return false;

    const bestConfig = getBestMetaEngagementConfigByCondition(condition, bpmAction);

    const valueRegex = getValueRegexByCondition(condition, bpmAction);
    if (isValidRef(valueRegex) && !valueRegex?.test(condition.condition)) {
        return false;
    }

    if (
        bestConfig.needIdUserFunction &&
        !isValidString(condition.idUserFunction)
    ) {
        return false;
    }

    if (
        bestConfig.needIdInformationSource &&
        !bestConfig.hideTarget &&
        !isValidString(condition.idInformationSource)
    ) {
        return false;
    }

    if (!bestConfig.noNeedCondition && !isValidString(condition.condition)) {
        return false;
    }

    if (bestConfig.valueType === EEngagementValueTypes.List) {
        const { maxTokens, minTokens } = bestConfig;
        const conditionTokens = getConditionValueTokens(condition.condition);

        if (
            (isValidRef(maxTokens) && conditionTokens.length > maxTokens) ||
            (isValidRef(minTokens) && conditionTokens.length < minTokens)
        ) return false;
    }

    if (bestConfig.valueType === EEngagementValueTypes.Enum) {
        const { enums } = bestConfig;

        const values = getConditionValueTokens(condition.condition);

        return isValidArray(enums) && values.every(v => enums.includes(v));
    }

    if (condition.metaConditionType === EMetaEngagementConditionType.regex) {
        return isValidRegex(condition.condition);
    }

    return true;
}

export function hasValidConditionOnAsset(asset: IBasicAsset): boolean {
    return isValidAndEqual(asset.recency, EMessageRecency.conditional) && isValidArray(asset.advancedConditions);
}

export function hasValidConditionalActionAsset(action: IBasicAsset): boolean {
    if (isActionAsset(action.type)) {
        return hasValidConditionOnAsset(action);
    }
    return false;

}

export function isConditionalAsset(action: IBasicAsset): boolean {
    return isValidRef(action) && action.recency === EMessageRecency.conditional;
}

export function isValidOnCondition(onCond: IOnCondition): boolean {
    return isValidRef(onCond) && isValidAssetAction(onCond.execute);
}

export function isNumberEligibleCondition(type: EMetaEngagementConditionType): boolean {
    return isThisOneOfThat(type, EMetaEngagementConditionType.greater, EMetaEngagementConditionType.smaller, EMetaEngagementConditionType.literal)

}

export function isValidMultipleCallsConfig(input: IMultipleNestedFormConfig): boolean {
    return (
        isValidRef(input) &&
        (isValidNumber(input.minimumElements) || isValidString(input.minElementsLocalCanonical)) &&
        (isValidNumber(input.maxElements) || isValidString(input.maxElementsLocalCanonical)) &&
        isValidContentArray(input.title) &&
        isValidContentArray(input.yesQuestion) &&
        isValidContentArray(input.noQuestion)
    );
}

interface IDateInputTokens {
    day?: string;
    month: string;
    year: string
}

function getInputDateDelimiter(input: string): '/' | '-' {
    return input.indexOf('/') !== -1 ? '/' : '-';
}

function getDateInputTokens(input: string, format: EValidFormatFinal): IDateInputTokens {
    let day: string;
    let month: string;
    let year: string;
    const delimiter = getInputDateDelimiter(input)
    const tokens = input.split(delimiter);

    if (format === EValidFormatFinal.ddmmyy || format === EValidFormatFinal.ddmmyyyy) {
        ([day = '', month = '', year = ''] = tokens);
    } else if (format === EValidFormatFinal.mmyy) {
        ([month = '', year = ''] = tokens);
    } else if (format === EValidFormatFinal.yyyymmdd) {
        ([year = '', month = '', day = ''] = tokens);
    }

    return { day, month, year };
}

function isValidDateInputTokens(tokens: IDateInputTokens, format: EValidFormatFinal) {
    if (isValidRef(tokens.day) && tokens.day.length !== 2) {
        return false;
    }

    if (tokens.month.length !== 2) {
        return false;
    }

    const yearStrLength = format === EValidFormatFinal.ddmmyyyy || format === EValidFormatFinal.yyyymmdd
        ? 4
        : 2;

    if (tokens.year.length !== yearStrLength) {
        return false;
    }

    return true;
}

function hasValidDateTokensValues(tokens: IDateInputTokens) {
    const dayInt = parseInt(tokens.day);

    if (isValidRef(tokens.day) && dayInt < 1 || dayInt > 31) {
        return false;
    }

    const monthInt = parseInt(tokens.month);

    if (monthInt < 1 || monthInt > 12) {
        return false;
    }

    const yearInt = parseInt(tokens.year);

    if (yearInt < 1) {
        return false;
    }

    return true;
}

/**
 * Regex segura
 * https://devina.io/redos-checker
 * 
 * valido    : 2012-10-06T04:13:00+00:00
 * valido    : 0785-10-10T04:13:00+00:00
 * valido    : 2018-W42-4T04:13:00+00:00
 * invalido  : 2013-99-99T04:13:00+00:00
 */
const ISO8601DateRegex = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;

export function validateDateFormat(input: string, format: EValidFormatFinal): boolean {
    if (format === EValidFormatFinal.ISO8601) {
        return ISO8601DateRegex.test(input)
    }

    const tokens = getDateInputTokens(input, format);

    if (!isValidDateInputTokens(tokens, format)) {
        return false;
    }

    if (!hasValidDateTokensValues(tokens)) {
        return false;
    }

    return true;
}

export function validateDateValue(input: string, { format, validation }: IMetaEngagementDateValidation): boolean {
    let date: Date;
    let hasDay = true;

    if (format === EValidFormatFinal.ISO8601) {
        date = new Date(input);
    } else {
        let { day, month, year } = getDateInputTokens(input, format);

        hasDay = isValidRef(day);

        if (year.length === 2) {
            year = '20' + year;
        }

        date = new Date(`${month}/${day || '01'}/${year}`);
    }

    return validation
        /**
         * Evitar validação de dia para o formato que não contém o dia.
         */
        .filter(v => v.input.type === EDateElements.day ? hasDay : true)
        .every(validator => validateDateOperation(date, validator))
}


const getValidatorElementValue: Record<EDateElements, (date: Date) => number> = {
    [EDateElements.day]: (date) => date.getDate(),
    [EDateElements.month]: (date) => date.getMonth() + 1,
    [EDateElements.year]: (date) => date.getFullYear(),
    [EDateElements.hours]: (date) => date.getHours(),
    [EDateElements.seconds]: (date) => date.getSeconds(),
    [EDateElements.milliseconds]: (date) => date.getMilliseconds(),
    [EDateElements.quarters]: (date) => Math.floor((date.getMonth() + 3) / 3),
}

function validateDateOperation(date: Date, validator: IDateValidation): boolean {
    const userInputElement = getValidatorElementValue[validator.input.type](date);
    const compareValue = validator.input;

    switch (validator.operation) {
        case EDateOps.equalls:
            return userInputElement === compareValue.value;
        case EDateOps.greaterThan:
            return userInputElement > compareValue.value;
        case EDateOps.smallerThan:
            return userInputElement < compareValue.value;
    }
}