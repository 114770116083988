import { sum } from "lodash";
import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { ETypeOf, isInEnum, isInvalid, isValidNumber, isValidRef } from "../../../../tools/utility";
import { checkEarlyReturnSwitch } from "../../../../tools/utility-types";
import { isValidBPM } from "../../../BPM/bpm-functions";
import { getAvailableCadenceProportionForCampaign } from "../../../campaigns/campaign-exec.functions";
import { EAutomaticAllocationRule, ECampaignExecutionType, ERunCampaignCadenceType, ERunCampaignExecutionMethod, ICampaignExecutionHeader, IRunCampaignAutomaticAllocation } from "../../../campaigns/capaing-exec-interfaces";
import { gTranslations } from "../../../const-text/translations";
import { INonSerializable } from "../../non-serializable-id-interfaces";
import { AssertNser, assertNser, EAdditionalTypeOf } from "../helpers";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";


function validator(nser: ICampaignExecutionHeader & INonSerializable, friendly: FriendlyMessage): FriendlyMessage {
    defaultNserValidator(nser, friendly);

    ; assertNser(nser, friendly, {
        idFiredAction: {
            type: ETypeOf.String,
            isRequired: true,
            message: gTranslations.dashboard.selectAnAction,
        },
        filter: {
            type: ETypeOf.Object,
            isRequired: false,
            custom: ({ value }) => {
                if (!isValidBPM(value)) friendly.addValidationError(gTranslations.errors.invalidField, 'Filtro inválido')
            }
        },
        files: {
            type: ETypeOf.Array,
            isRequired: true,
            literalJoi: {
                min: 1,
            },
            message: "Selecione ao menos um banco de dados",
        },
        scheduleBeginning: {
            type: ETypeOf.Number,
            isRequired: false,
        },
        constants: {
            type: ETypeOf.Array,
            isRequired: false,
        },
        recency: {
            type: ETypeOf.Object,
            isRequired: false,
        },
        executionMethod: {
            type: EAdditionalTypeOf.Enum,
            isRequired: false,
            literalJoi: {
                entity: ERunCampaignExecutionMethod,
            },
        },
        cadence: {
            type: ETypeOf.Object,
            isRequired: false,
        },
        allocation: {
            type: ETypeOf.Object,
            isRequired: false,
        },
        idSchedule: {
            type: ETypeOf.String,
            isRequired: false,
        },
    });

    ; validateExecutionMethod(); 

    return friendly;

    
    function validateExecutionMethod() {
        switch (nser.executionMethod) {
            case ERunCampaignExecutionMethod.Allocation: {
                const errorMsg = getAllocationError(nser.allocation);
                if (isValidRef(errorMsg)) friendly.addValidationError(gTranslations.errors.invalidField, `Alocação - ${errorMsg}`);
                return;
            }
            case ERunCampaignExecutionMethod.Cadence: return validateCadenceProportions();
            case ERunCampaignExecutionMethod.None: return;
        }
    
        checkEarlyReturnSwitch()(nser.executionMethod);

        function validateCadenceProportions() {
            if (nser.cadence.type !== ERunCampaignCadenceType.proportion) return;

            const errorMsg = getCadenceProportionsError();
            if (isValidRef(errorMsg)) friendly.addValidationError(gTranslations.errors.invalidField, `Cadẽncia - Proporção - ${errorMsg}`);

            function getCadenceProportionsError() {
                // 0-100
                const available: number = getAvailableCadenceProportionForCampaign(nser);

                if (available)
                    return 'O total de proporções deve ser 100%'
                ;
                
                if (nser.cadence.proportionalCadence.proportions.slice(1).some(item => !item.minutesAfterLast))
                    return 'Todas as proporções devem ter um tempo entre o último.'
                ;
            }
        }
    }

    function getAllocationError(allocation: IRunCampaignAutomaticAllocation): string {
        if (!(allocation.maxOccupation)) return 'Ocupação máxima não preenchida.';
        if (!isValidNumber(allocation.resumeOccupation, 0)) return 'Resumir ocupação não preenchido';
        if (!isValidNumber(allocation.timeBetweenProcessOnSuccessMS)) return 'Sem tempo entre sucesso';
        if (!isValidNumber(allocation.timeBetweenProcessOnFailureMS)) return 'Sem tempo entre falha';
        if (!isValidNumber(allocation.maxTriesOnFailure)) return 'Sem quantidade máxima de tentativas após falha';
        if (!isValidNumber(allocation.doNotSendAfter)) return 'Não enviar após não preenchido';
        if (!allocation.idIslandToCheck) return 'Sem ilha para alocação';

        if (allocation.sendRulesType) {
            if (!isInEnum(EAutomaticAllocationRule, allocation.sendRulesType)) return 'Tipo de alocação invalido';
            if (!allocation.sendRulesValue) return 'Sem quantidade de envios'
        }
        
    }

    

}


export const sendCampaignValidator: TNserValidator = validator;