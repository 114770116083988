import { MainSignal } from '../main-signal';
import { observableList } from '../signal-constants';
import {ChatbarHandler} from "../../../handlers/chat-bar-interactions/chat-bar-handler";


export class FeatureSignal extends MainSignal {

    constructor(chatHandler: ChatbarHandler) {
        super(observableList.features.batchUpload, true, chatHandler);
    };

    public getChatHandler(): ChatbarHandler {
        return <ChatbarHandler>super.getObservableInformation();
    };
};
