import { Injectable } from '@angular/core';
import {TurnGPSOn} from "@colmeia/core/src/interaction/interactions/location/turn-gps-on";
import {SessionService} from "./session.service";
import {GroupChatServices} from "./group-chat.services";
import {FriendlyMessage} from "@colmeia/core/src/error-control/friendly-message";
import {IGPSConfigurationStatus} from "@colmeia/core/src/comm-interfaces/settings-interface";
import {Participant} from "@colmeia/core/src/business/participant";
import {Interaction} from "@colmeia/core/src/interaction/interaction";
import {TurnGpsOff} from "@colmeia/core/src/interaction/interactions/location/turn-gps-off";

@Injectable({
    providedIn: 'root'
})
export class GeolocationManagerService {
    private turnOnInteraction: TurnGPSOn;

    constructor(
        private sessionSvc: SessionService,
        private groupChatSvc: GroupChatServices,
    ) { }

    async sendToServerGPSToggledOn(): Promise<FriendlyMessage> {
        const personalGrpParticipant = this.sessionSvc.getPlayerPersonalGroupParticipant();
        const chosenInteraction = this.turnOnInteraction = TurnGPSOn.getNewTurnGPSOn(personalGrpParticipant);
        const response = await this.groupChatSvc.saveInteraction(chosenInteraction)
        return response
    }

    async sendToServerGPSToggledOff(gpsStatus: IGPSConfigurationStatus): Promise<FriendlyMessage> {
        const personalGrpParticipant = this.sessionSvc.getPlayerPersonalGroupParticipant();
        const chosenInteraction = this.handleTurnOff(personalGrpParticipant, gpsStatus)
        const response = await this.groupChatSvc.saveInteraction(chosenInteraction)
        return response
    }

    handleTurnOff(
        personalGrpParticipant: Participant,
        gpsStatus: IGPSConfigurationStatus
    ): Interaction {
        const isGpsStatusFromServerExistOnFirstToggle = !this.turnOnInteraction && gpsStatus && gpsStatus.json && gpsStatus.gpsOn

        if (isGpsStatusFromServerExistOnFirstToggle) {
            const onInteractionSentFromServer = TurnGPSOn.factoryMessage(gpsStatus.json);
            return TurnGpsOff.getNewTurnGpsOff(
                personalGrpParticipant,
                onInteractionSentFromServer
            );
        } else if(this.turnOnInteraction) {
            return TurnGpsOff.getNewTurnGpsOff(
                personalGrpParticipant,
                this.turnOnInteraction
            );
        }
    }

}
