import {constant, TGlobalUID} from '../../business/constant';
import {UberCache, TSerializableArray} from '../../persistency/uber-cache';
import {Serializable} from '../../business/serializable';
import {IDueDateTypeJSON} from '../../comm-interfaces/business-interfaces';



export type TDueDateTypeArray = Array<DueDateType>

export class DueDateType extends Serializable {
    private futureEvent: boolean;
    private static dueDateTypeArray: TDueDateTypeArray = [];

    constructor (primaryID: TGlobalUID) {
        super(constant.objectType.dueDateType, primaryID);
        DueDateType.dueDateTypeArray.push(this);
    };

    public toJSON(): IDueDateTypeJSON {
        let json: IDueDateTypeJSON = {
            ...super.toJSON(), 
            isFutureEvent: this.futureEvent
        };
        
        return json;
    };

    public isFutureEvent(): boolean {
        return this.futureEvent;
    };
   
    public rehydrate(json: IDueDateTypeJSON): void {
        super.rehydrate(json);
        this.futureEvent = json.isFutureEvent;
    };

    public static factoryMessage(json: IDueDateTypeJSON): DueDateType {
        let dueType: DueDateType = <DueDateType>UberCache.uberFactory(json.primaryID, constant.objectType.dueDateType, false);
        if (dueType == null) {
            dueType = new DueDateType(json.primaryID)
        };
        dueType.rehydrate(json);
        return dueType;
    };

    public static staticFactory(idDueDateType: TGlobalUID): DueDateType {
        return <DueDateType>UberCache.uberFactory(idDueDateType, constant.objectType.dueDateType, true);
    };

    public static getDueDateArray(): TDueDateTypeArray {
        return DueDateType.dueDateTypeArray;
    }
};
