import { MessageInstanceHandler, IMessageInstanceParameter } from "../message-instance-handler";
import { TSerializableArray } from "@colmeia/core/src/business/serializable";

export class EndRealtimeTrackingMessageHandler extends MessageInstanceHandler {

    constructor(messageParameter: IMessageInstanceParameter){
        super(messageParameter);
    }

    getMoreOptions(): TSerializableArray {
        return [];
    }
}
