import { EHardwareResourceStatus } from "./cordova/vendor/hardware-status";
import { ILocationCoordinates } from "@colmeia/core/src/tools/geo-util";
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { IClientVersion } from "@colmeia/core/src/security/version";
import { IShortMessage } from "@colmeia/core/src/shared-business-rules/small-message/shrink-message";
import { Observable, Subscription } from "rxjs";
import { MultimediaInstance, TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/barrel-multimedia";
import { EPlatformUpdateType, IVersionWarning } from "@colmeia/core/src/core-constants/version-control";
import { InteractionPersistorServices } from "../interaction-persistor.service";
import { MultimediaService } from "../multimedia.service";
import { SocketService } from "../socket.service";
import { UID } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IFocusInfo } from "@colmeia/core/src/request-interfaces/response-interfaces";

export const hwConfig = {
    cordova: 'cordova',
};

export interface IHardwareResource {
    isAllowed(): Promise<boolean>;
    getAuthorizationStatus(): Promise<EHardwareResourceStatus>;
    requestAuthorization(): Promise<EHardwareResourceStatus>;
}

export interface ITogglableHardwareResource extends IHardwareResource {
    isEnabled(): Promise<boolean>;
}

export interface ICameraHardwareAbstraction extends IHardwareResource {
    getCameraMedia(fs: IFileSystemLayer): Promise<File>;
}


export interface IRecordMicrophoneResult {
    file: File;
}

export interface IMicrophoneHardwareAbstraction extends IHardwareResource {
    startRecording(): Promise<boolean>;
    stopRecording(fs: IFileSystemLayer): Promise<IRecordMicrophoneResult>;
    cancelRecording(): void;
    /**
     * Expo React Native Hardware Layer Microphone Methods
     */
    resolveAuthorizationStatus?(status: boolean): void;
}

export enum EIOSLocationMode {
    ALWAYS = "always",
    WHEN_IN_USE = "when_in_use"
}

export interface IGeolocationError {
    gpsTimestamp: number;
    message: string;
}

export type SuccessGeolocationCallback = (location: ILocationCoordinates) => void;

export type ErrorGeolocationCallback = (error: IGeolocationError) => void;

export interface IGeolocationHardwareAbstraction extends ITogglableHardwareResource {
    setEmergencyMode(active: boolean): void;
    clearWatchPosition(id: number): void;
    getPosition(
        successCallback: SuccessGeolocationCallback,
        errorCallback: ErrorGeolocationCallback,
        options: PositionOptions
    ): void;
    watchPosition(
        successCallback: SuccessGeolocationCallback,
        errorCallback: ErrorGeolocationCallback,
        options?: PositionOptions
    ): number;
    getLastKnownPosition(): ILocationCoordinates;
    start(): Promise<any>;
    stop(): Promise<any>;
    /**
     * The following methods are for react-geolocation.ts only.
     */
    updateLocation?(location: ILocationCoordinates): void;
    resolveIsAllowed?(isAllowed: boolean): void;
    resolveAuthorization?(authorized: boolean): void;
    stopLocation?(): void;
}

export enum EMobileBrowserTarget {
    Blank = '_blank',
    System = '_system'
}

export interface IRedirect {
    url: string;
    mobileTarget?: EMobileBrowserTarget;
}

export interface IRedirectHardwareAbstraction {
    redirect(options: IRedirect): void;
    URI(base: string): string;
}

export type TNetworkStateSubscription = (state: boolean) => void;

export interface INetworkHardwareAbstraction {
    setSocketIsOnline(isOnline: boolean): void;
    subscribeToOffline(subscription: TNetworkStateSubscription): Subscription;
    isOnline(): boolean;
    isOffline(): boolean;
    setSocketService(service: SocketService): void;
}

export type IVibrationParameter = number | Array<number>;

export interface IVibrationHardwareAbstraction {
    vibrate(param: IVibrationParameter);
}

export interface IStorageLayer {
    getAllStorageKeys(prefix?: string);
    hasItem(key: string): boolean;
    putItem<T extends object>(key: string, item: T): void;
    getItem<T extends object>(key: string): T;
    clearItem(key: string): void;
    clearAllItems(): void;
}

export interface IFileSystemLayer {
    newFile(fileBits: BlobPart[], fileName: string, options?: FilePropertyBag): File;
}

export interface INotificationHardwareAbstraction extends IHardwareResource {
    /**
     * React native methods
     */
    notificationResolver?(isAllowed: boolean): void
}

export interface GenericPlatform {
    getMultimediaAllowedMIMETypes(): Array<string>;
}

export interface ILocalNotificationsHardwareAbstraction {
    notificate(short: IShortMessage);
}

export interface IAudioHardwareAbstraction {
    playSound(path: string): void;
}

export interface IUpdaterHardwareLayer {
    update(warn: IVersionWarning): void;
    isRestartPending(): boolean;
    setInteractionPersistor(int: InteractionPersistorServices): void;
}

export interface IFileOpenerAbstraction {
    open(mmInstance: MultimediaInstance): void;
    setMultimediaService(multimediaService: MultimediaService): void;
}

export interface IMediaViewer {
    view(mms: TMultimediaInstanceArray, startingIndex: number, title?: string): void;
}


export interface IHardwareAbstractionLayer {
    isFocused(): boolean;
    isNative(): boolean;
    isMobile(): boolean;
    getNetworkLayer(): INetworkHardwareAbstraction;
    getCameraLayer(): ICameraHardwareAbstraction;
    getMicrophoneLayer(): IMicrophoneHardwareAbstraction;
    getGeolocationLayer(): IGeolocationHardwareAbstraction;
    getRedirectLayer(): IRedirectHardwareAbstraction;
    getNetworkLayer(): INetworkHardwareAbstraction;
    getVibrationLayer(): IVibrationHardwareAbstraction;
    getStorage(): IStorageLayer;
    getFile(): IFileSystemLayer;
    bootListeners(): void;
    setCachedPlayer(player: PlayerCachedInfo): void;
    getVersion(): IClientVersion;
    waitUntilUnlock(): Promise<void>;
    getDeviceUID(): Promise<UID>;
    getBrowserId(): string;
    getPNClientID(): Promise<string>;
    getNotifications(): INotificationHardwareAbstraction;
    getDeviceName(): string;
    getGenericPlatform(): GenericPlatform;
    getLocalNotifications(): ILocalNotificationsHardwareAbstraction;
    onMainHeaderUnmounted(): void;
    onMainHeaderMounted(): void;
    beforeNotificationsActivate(): Promise<boolean>;
    getAudio(): IAudioHardwareAbstraction;
    getFileOpener(): IFileOpenerAbstraction;
    loadPlatformSpecificAssets();
    runOptimizations(): void;
    getUpdater(): IUpdaterHardwareLayer;
    setSocketConnectionOnline(isOnline: boolean): void;
    getMediaViewer(): IMediaViewer;
    platformHasPushNotifications(): boolean;
    isAllowedToChangeNotificationsState(): boolean;
    /**
     * @param handler 
     * @returns Remove listener method
     */
    onVisibilityChange(): Observable<IFocusInfo>;
}

export enum EHardwareUpdateDeviceType {
    Mobile = 'mobile',
    Desktop = 'desktop'
}
