import { Injectable } from '@angular/core';
import { INetworkHardwareAbstraction, TNetworkStateSubscription } from "../hardware-interfaces";
import { BehaviorSubject, Subscription } from "rxjs";
import { Navigator, Connection} from './cordova-plugins-definitions';
import { SocketService } from 'app/services/socket.service';
import { isValidRef } from '@colmeia/core/src/tools/utility';

declare var navigator: Navigator;
declare var Connection: Connection;

@Injectable({
    providedIn: 'root'
})
export class CordovaNetwork implements INetworkHardwareAbstraction {

    private onlineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private offlineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private socketOn: boolean = true;
    private socket: SocketService;
    
    constructor() {
        // document.addEventListener('online', (state) => {
        //    this.onlineSubject.next(true);
        //    this.offlineSubject.next(false);
        // }, false);
        // document.addEventListener('offline', (state) => {
        //     this.onlineSubject.next(false);
        //     this.offlineSubject.next(true);
        // }, false);
    }

    setSocketService(service: SocketService): void {
        this.socket = service;
    }

    setSocketIsOnline(isOnline: boolean): void {
        this.socketOn = isOnline;
        this.onlineSubject.next(this.isOnline());
        this.offlineSubject.next(this.isOffline());
    }

    isOffline(): boolean {
        return !this.isOnline();
    }

    isOnline(): boolean {
        return isValidRef(this.socket) && this.socket.isSocketOnline();
    }

    subscribeToOffline(subscription: TNetworkStateSubscription): Subscription {
        return this.onlineSubject.subscribe(subscription);
    }

    subscribeToOnline(subscription: TNetworkStateSubscription): Subscription {
        return this.offlineSubject.subscribe(subscription);
    }

}
