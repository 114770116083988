import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {domain} from "@colmeia/core/src/core-constants/security-constant";
import {ESocialMedia} from "@colmeia/core/src/business/social";
import {SignalListenerService} from "../../../../services/signal/signal-listener";
import {SocialService} from "../../../../services/social.service";
import {SettingsModalService} from "../../settings-modal/settings-modal.service";
import {RootComponent} from "../../../foundation/root/root.component";

export interface ISocialBind {
    primaryID: string;
    social: ESocialMedia;
    name: string,
    icon: {
        svgIcon: string;
        color: string;
    }
}

@Component({
    selector: 'app-social-bind',
    templateUrl: './social-bind.component.html',
    styleUrls: ['./social-bind.component.scss']
})
export class SocialBindComponent extends RootComponent<any> implements OnInit {

    canShowLoginOptions: boolean = false;
    allBinds: ISocialBind[] = [
        {
            primaryID: domain.validateBy.onlyEmailValidatedPlayer,
            icon: {
                svgIcon: 'google',
                color: '#dd4b39'
            },
            social: ESocialMedia.Google,
            name: "Google"
        },
        {
            primaryID: domain.validateBy.onlyFacebookValidatedPlayer,
            icon: {
                svgIcon: 'facebook',
                color: '#3a5998'
            },
            social: ESocialMedia.Facebook,
            name: "Facebook"
        },
        {
            primaryID: domain.validateBy.onlylinkedinValidated,
            icon: {
                svgIcon: 'linkedin',
                color: '#283e4a'
            },
            social: ESocialMedia.Linkedin,
            name: "Linkedin"
        },
        {
            primaryID: domain.validateBy.onlyTwiterValidatedPlayer,
            icon: {
                svgIcon: 'twitter',
                color: '#5baaf4'
            },
            social: ESocialMedia.Twitter,
            name: "Twitter"
        }
    ];

    private activeBinds: Array<ESocialMedia> = [];

    constructor(
        private listener: SignalListenerService,
        private cdRef: ChangeDetectorRef,
        private socialSVC: SocialService,
        private settingsModalService: SettingsModalService
    ) {
        super({}, true, cdRef);
    }

    async ngOnInit() {
        this.activeBinds = await this.settingsModalService.getSocialMedia();
        this.canShowLoginOptions = true;
        this.cdRef.markForCheck();
    }

    onSocialBindSwitchChanged(social: ESocialMedia) {
        if (this.isActiveSocialBind(social)) {
            this.socialSVC.unbindSocialMedia(social);
        } else {
            window.location.href = `/auth/${social}/bind`
        }
    }

    isActiveSocialBind(social: ESocialMedia): boolean {
        return this.activeBinds.includes(social);
    }
}
