import { Injectable } from '@angular/core';
import { isValidRef } from '@colmeia/core/src/tools/barrel-tools';
import { Nullish } from '@colmeia/core/src/tools/utility-types';
import { MenuDashboardService } from 'app/components/menu-dashboard/menu-dashboard.service';
import { DashBoardService } from 'app/services/dashboard/dashboard.service';
import { RoutingService } from 'app/services/routing.service';
import { Subject, Subscription, fromEvent } from 'rxjs';

export enum ESideMenuScreen {
    Dashboard = 'dashboard',
    Chat = 'chat'
}

export type TSideMenuScreenId = ESideMenuScreen | null;

@Injectable({
  providedIn: 'root'
})
export class ModuleNavService {
    private selectedSideScreen$ = new Subject<TSideMenuScreenId>();
    
    private windowResizeSubs: Subscription;
    private selectedSideScreenSubs: Subscription;
    private currentDashSubs: Subscription;

    _hideMenus: boolean = true;
    public get hideMenus(): boolean {
        return this._hideMenus;
    }
    public set hideMenus(value: boolean) {
        if (
            this._hideMenus !== value
            && !(this.isMobileLayout)
        ) {
            // trigger nesse evento para que o sidebar recalcule seu tamanho
            window.dispatchEvent(new Event('resize'));
        }

        this._hideMenus = value;
    }

    public isMobileLayout: boolean;

    constructor(
        private dashboardSvc: DashBoardService,
        private menuDashboardSvc: MenuDashboardService,
        private routingSvc: RoutingService
    ) {
        this.init();
    }

    init() {
        this.currentDashSubs = this.menuDashboardSvc.selectedDashboard$.subscribe(selectedDashboard => {
            this.hideMenus = false;

            if (isValidRef(selectedDashboard)) {
                this.openModuleMenus();
                return;
            }
            
            if (
                !(this.routingSvc.isOnChatScreen())
                && !this.isMobileLayout
            ) {
                this.hideMenus = true;
            }
        });

        this.currentDashSubs = this.dashboardSvc.currentDashboard$.subscribe(dashboardRoute => {
            this.checkModuleChange(dashboardRoute);
        });

        this.initIsMobile();
    }

    private initIsMobile(): void {
        this.windowResizeSubs = fromEvent(window, 'resize').subscribe(() => {
            this.isMobileLayout = window.screen.width <= 600;
        });
    }

    ngOnDestroy() {
        this.currentDashSubs.unsubscribe();
        this.windowResizeSubs.unsubscribe();
        this.selectedSideScreenSubs.unsubscribe();
    }

    public changeSelectedSideScreen(id: TSideMenuScreenId) {
        if (id) {
            this.hideMenus = false;
        }

        this.selectedSideScreen$.next(id);
    }

    public getSelectedSideScreen$() {
        return this.selectedSideScreen$.asObservable();
    }

    public openChat(): void {
        this.changeSelectedSideScreen(ESideMenuScreen.Chat);
    }

    public openModuleMenus(): void {
        this.changeSelectedSideScreen(ESideMenuScreen.Dashboard);
    }

    /**
     * esconde o menu em rotas que não são do chat e dashboard
     */
    private checkModuleChange(module: string | Nullish): void {
        if (this.routingSvc.isOnDashboardScreen()) {
            this.changeSelectedSideScreen(ESideMenuScreen.Dashboard);
        }
    }
}
