import { isValidString } from '@colmeia/core/src/tools/utility';
import { createTypeGuard, FriendlyMessage, isValidArray } from "../../../../tools/barrel-tools";
import { genericTypedSuggestions } from "../../../../tools/type-utils";
import { EEventType, ICustomEventsServer } from "../../../attendance-island/custom-events";
import { EBotEventType, IBotEvent, IOnChannelCloseOnService, TQueueEventSubtype } from "../../../bot/bot-event-model";
import { attendanceEventErrorMissingEvent, nserInsertNameError, nserSmallNameError } from "../error-messages";
import { minLength, nserNameMinLength } from "../helpers";


export const hashAttendanceEvents = genericTypedSuggestions<{ [key in TQueueEventSubtype]: true }>()({
    [EBotEventType.onQueueReturn]: true,
    [EBotEventType.onQueueTimeRestriction]: true,
    [EBotEventType.onQueueAdd]: true,
    [EBotEventType.onQueueNext]: true,
    [EBotEventType.onQueueOnBegin]: true,
    [EBotEventType.onQueueOnFinish]: true,
    [EBotEventType.onCustomerGaveUp]: true,
    [EBotEventType.onQueueTransfer]: true,
    [EBotEventType.onCreatingCredentials]: true,
    [EBotEventType.onAttendentAbsent]: true,
    [EBotEventType.onChannelClosedOnAgentChat]: true,
    [EBotEventType.onMaximumCustomerQueueReach]: true,
    [EBotEventType.onAgentNotAvailable]: true,
});


const attendanceEventsGuard = createTypeGuard<ICustomEventsServer & { type: EEventType.Attendace }>((nser: ICustomEventsServer) => nser.type === EEventType.Attendace);
const botEventsGuard = createTypeGuard<ICustomEventsServer & { type: EEventType.Bot }>((nser: ICustomEventsServer) => nser.type === EEventType.Bot);

export const validateCustomEvents = (nser: ICustomEventsServer, friendly: FriendlyMessage): FriendlyMessage => {

    if (!nser.nName) {
        friendly.addReturnResponse(nserInsertNameError);
    } else if (!minLength(nser.nName, nserNameMinLength)) {
        friendly.addReturnResponse(nserSmallNameError);
    }

    if (attendanceEventsGuard(nser)) validateAttendanceEvents(nser, friendly);
    if (botEventsGuard(nser)) validateBotEvents(nser, friendly);

    return friendly;
}

function isEventFulfilled(event: IBotEvent): boolean {
    return event.type === EBotEventType.onChannelClosedOnAgentChat
        ? isValidString((event as IOnChannelCloseOnService).idOpenChannelCampaignAction)
        : isValidArray(event.contentArray);
}

function validateAttendanceEvents(nser: ICustomEventsServer, friendly: FriendlyMessage): void {
    const mapEventTypeToTrue: Map<EBotEventType, boolean> = new Map();

    for (let event of nser.events) {
        mapEventTypeToTrue.set((event.type as EBotEventType), isEventFulfilled(event as IBotEvent));
    }

    for (let attendanceEvent in hashAttendanceEvents) {
        if (!mapEventTypeToTrue.get(attendanceEvent as EBotEventType)) {
            friendly.addReturnResponse(attendanceEventErrorMissingEvent)
        }
    }
}

function validateBotEvents(nser: ICustomEventsServer, friendly: FriendlyMessage): void {

}

