import { INotificationHardwareAbstraction } from "../hardware-interfaces";
import { EHardwareResourceStatus } from "../cordova/vendor/hardware-status";
import { Injectable } from "@angular/core";
import { NativeEventEmitterService } from "app/services/colmeia-mobile-app/native-event-emitter.service";
import { EEventName, IReactNativeNotification } from "@colmeia/core/src/client-shared/client-native.model";
import { permission } from "@colmeia/core/src/core-constants/security-constant";

@Injectable({
    providedIn: "root"
})
export class ReactNotifications implements INotificationHardwareAbstraction{
    private authorizationStatus: EHardwareResourceStatus = EHardwareResourceStatus.DENIED;
    
    constructor(
        private nativeEventEmitterSvc: NativeEventEmitterService
    ) {
        this.getAuthorizationStatus();
    }
    
    public notificationResolver: (value: boolean) => void;
    private notificationTimeout: NodeJS.Timeout | null;
    getAuthorizationStatus(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {
            this.notificationResolver = (isAllowed: boolean) => {
                if (this.notificationTimeout) {
                    clearTimeout(this.notificationTimeout)
                    this.notificationTimeout = null;
                }
                resolve(isAllowed ? EHardwareResourceStatus.GRANTED : EHardwareResourceStatus.DENIED);
               
            };


            this.nativeEventEmitterSvc.emit(EEventName.notification, {});
            this.notificationTimeout = setTimeout(() => {
                reject('Não foi possível verificar a permissão de notificação');
            }, 30000)
        });
    }

    isAllowed(): Promise<boolean> {
        return Promise.resolve(this.authorizationStatus === EHardwareResourceStatus.GRANTED);
    }

    private notificationAuthTimeout: NodeJS.Timeout | null;
    requestAuthorization(): Promise<EHardwareResourceStatus> {
        return new Promise<EHardwareResourceStatus>((resolve, reject) => {

            this.notificationResolver = (isAllowed: boolean) => {
                if (this.notificationAuthTimeout) {
                    clearTimeout(this.notificationAuthTimeout)
                    this.notificationAuthTimeout = null;
                }
                this.authorizationStatus = isAllowed ? EHardwareResourceStatus.GRANTED : EHardwareResourceStatus.DENIED
                resolve(this.authorizationStatus);
            };


            this.nativeEventEmitterSvc.emit<IReactNativeNotification>(EEventName.notification, {permission: true});
            this.notificationAuthTimeout = setTimeout(() => {
                reject('Não foi possível verificar a permissão de notificação');
            }, 30000)
        });
    }
}
