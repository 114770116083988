import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditedMsgsHandler, TEditedMsgViewerDescriptorArray } from './edited-msgs-viewer.handler';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { constant } from '@colmeia/core/src/business/constant';
import {SignalListenerService} from "../../../../../services/signal/signal-listener";
import {LoginSignal} from "../../../../../model/signal/cookie-login-sign";
import {chatMessageEdit} from "@colmeia/core/src/shared-business-rules/visual-constants";
import { IHashSet } from '@colmeia/core/src/tools/utility-types';

@Component({
    selector: 'app-edited-msgs-viewer',
    templateUrl: './edited-msgs-viewer.component.html',
    styleUrls: ['./edited-msgs-viewer.component.scss']
})
export class EditedMsgsViewerComponent implements OnInit {
    messagesHandler: EditedMsgsHandler;
    localConstants: IHashSet<string> = {
        title: ''
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: EditedMsgsHandler,
        private listenerSvc: SignalListenerService,
        private cdRef: ChangeDetectorRef,
    ) {
        this.messagesHandler = this.data
    }

    ngOnInit() {
        this.listenerSvc.listenToUserLoginEvent(this);
    }

    ngOnDestroy(): void {
        this.listenerSvc.destroySubscriptions(this);
    }

    receiveLoginEventCallback(_: LoginSignal): void {
        this.localConstants.title = Serializable
            .staticFactory(chatMessageEdit)
            .getSerializableText(constant.serializableField.auxiliars.aux01)
        this.cdRef.markForCheck();
    }

    getMessages(): TEditedMsgViewerDescriptorArray {
        return this.messagesHandler.getMessages()
    }

    getTitle(): string {
        return this.getMessages().length + ' ' +this.localConstants.title
    }

    onModalClosed(): void {
        this.messagesHandler.closeClicked()
    }
}
