import { IGraphElementRenderData } from "./diagram.interfaces";
import { IBasicElementClient } from "./graph-basic-element-interfaces";
import { initEmptyBasicElementClient } from "./graph-initializer";
import { IBasicElementJSON } from "./graph-interfaces";
import { EGraphElementType } from "./graph-types";

export interface IBasicElementInterfaceFactoryInfo {
    name: string,
    idParent: string,
    renderData: IGraphElementRenderData,
    existingBasicElementJSON?: IBasicElementJSON
}

export class BasicElementInterfaceFactory {
    private static emptyBasicInfo: IBasicElementInterfaceFactoryInfo = {
        name: '',
        idParent: null,
        renderData: null
    }

    public static createEmptyPredicate(): IBasicElementClient {
        return BasicElementInterfaceFactory.create(EGraphElementType.predicate, BasicElementInterfaceFactory.emptyBasicInfo)
    }

    public static createEmptyRoot(): IBasicElementClient {
        return BasicElementInterfaceFactory.create(EGraphElementType.root, BasicElementInterfaceFactory.emptyBasicInfo)
    }

    public static create(elementType: EGraphElementType, info: IBasicElementInterfaceFactoryInfo): IBasicElementClient {
        const emptyClient: IBasicElementClient = initEmptyBasicElementClient(elementType, info.idParent);
        const basicJSON: IBasicElementJSON = info.existingBasicElementJSON
            ? info.existingBasicElementJSON
            : {
                ...emptyClient.element,
                elementType: elementType,
                renderData: info.renderData
                    ? info.renderData
                    : emptyClient.element.renderData,
            }
        const result: IBasicElementClient = {
            ...emptyClient,
            nName: info.name,
            element: basicJSON,
        };

        return result
    }
}
