import { Serializable } from "@colmeia/core/src/business/serializable";
import { IdSN } from "@colmeia/core/src/core-constants/types";
import { nonSerializableFriendlyNameTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/non-serializable-friendly";
import { TGlobalUID } from "../../business/constant";
import { getSocialNetworkIdFromGenealogy } from "../../business/nser-identication";
import { getUniqueIdNS, isValidArray, isValidRef } from "../../tools/utility";
import { ENonSerializableObjectType, ENserVisualizationType, INonSerializable, INonSerializableIdentification } from "./non-serializable-id-interfaces";


export function getSNFromIDent(ident: INonSerializableIdentification): TGlobalUID {
    return getSocialNetworkIdFromGenealogy(ident.genealogy);
};

export function getSNFromNS(ns: INonSerializable): IdSN {
    return isValidRef(ns.ident) ? getSNFromIDent(ns.ident) : undefined;
}


export function safeGetSNFromNS(ns: INonSerializable): TGlobalUID {
    try {
        return getSNFromNS(ns)
    } catch { }
}


export function getNSParentRoot(ns: INonSerializable): string {
    return isValidArray(ns.genealogy) ? getSocialNetworkIdFromGenealogy(ns.genealogy) : ns.idNS
}


export function updateIdNS<T extends INonSerializable>(ns: T): asserts ns is (typeof ns & { idNS: NonNullable<T['idNS']> }) {
    ns.idNS = getUniqueIdNS();
}

export function getNSTypeName(nsType: ENonSerializableObjectType): string {
    return Serializable.getTranslation(nonSerializableFriendlyNameTranslations[nsType])!;
}

export function isArchivedNS(ns: INonSerializable) {
    return ns.visualizationType === ENserVisualizationType.archived;
}
export function isNotArchivedNS(ns: INonSerializable) {
    return !isArchivedNS(ns);
}

export const isActiveNS = isNotArchivedNS;