import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ColmeiaPopover, ColmeiaPopoverService } from 'app/services/dashboard/colmeia-popover.service';

export enum EPopoverStrategy {
    Click = 'click',
    Hover = 'hover'
}

@Directive({
    selector: '[popover]'
})
export class PopoverDirective implements OnInit, OnDestroy {
    @Input("popover")
    template: TemplateRef<unknown>;

    @Input()
    strategy: EPopoverStrategy = EPopoverStrategy.Click;

    private popoverRef: ColmeiaPopover | undefined;
    private isHoveringTrigger: boolean;
    private isHoveringPopover: boolean;

    constructor(
        private elementTrigger: ElementRef,
        private popoverService: ColmeiaPopoverService,
        private viewContainerRef: ViewContainerRef
    ) { }

    public ngOnInit() {
        this.popoverRef = this.popoverService.create({
            elementTrigger: this.elementTrigger.nativeElement,
            template: this.template,
            viewContainerRef: this.viewContainerRef
        });
    }

    public ngOnDestroy() {
        this.popoverRef = undefined;
    }

    @HostListener('click')
    onClick() {
        if (this.strategy === EPopoverStrategy.Click) {
            this.popoverRef?.open();

            this.popoverRef?.onBackdropClick.subscribe(() => {
                this.closePopover();
            });
        }
    }

    @HostListener('mouseenter')
    onMouseOver() {
        this.isHoveringTrigger = true;
        if (this.strategy === EPopoverStrategy.Hover) {
            if (this.isHoveringPopover)
                return;

            this.popoverRef?.open(false);
            if (this.popoverRef) {
                this.setPopoverHoverEvents(this.popoverRef.viewRef.rootNodes);
            }
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.isHoveringTrigger = false;

        this.delayUntilNextFrame(() => {
            if (this.strategy === EPopoverStrategy.Hover) {
                if (this.isHoveringPopover)
                    return;

                this.closePopover();
            }
        });
    }

    @HostListener('document:keydown.escape', ['$event'])
    onEscape(e: KeyboardEvent): void {
        this.closePopover();
    }

    mouseEnterCallback = () => {
        this.isHoveringPopover = true
    }
    mouseLeaveCallback = (event: MouseEvent) => {
        this.delayUntilNextFrame(() => {
            this.isHoveringPopover = false;

            if (!this.isHoveringTrigger) {
                this.closePopover();
            }
        });
        event.target?.removeEventListener('mouseenter', this.mouseEnterCallback);
        event.target?.removeAllListeners?.('mouseleave');
    }


    private setPopoverHoverEvents(elements: HTMLElement[]) {
        for (const element of elements) {

            element.addEventListener("mouseenter", this.mouseEnterCallback);

            element.addEventListener("mouseleave", this.mouseLeaveCallback);
        }
    }

    private closePopover() {
        this.isHoveringPopover = false;
        this.popoverRef?.close();
    }

    /**
     * Essa função "atrasa" a execução de um código para o próximo frame. Ela é usada
     * nesse componente quando queremos executar um código depois que outros eventos
     * tenham sido disparados
     * @param callback código a ser executado no próximo frame
     */
    private delayUntilNextFrame(callback: () => void) {
        setTimeout(callback, 0);
    }
}
