import { Injectable } from "@angular/core";
import { GenericNonSerializableService, IAdditionalGenericNonSerializableServiceOptions, IAdditionalGenericNonSerializableService } from "./generic-ns.service";
import { routeList, routeID,  } from "app/model/routes/route-constants";
import { ServerCommunicationService } from "./server-communication.service";
import { RoutingService } from "./routing.service";
import { RequestBuilderServices } from "./request-builder.services";
import { SessionService } from "./session.service";
import { INonSerializable, ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import { GenericHomeHandler } from "app/components/dashboard/dashboard-foundation/generic-dashboard-home/generic-home.handler";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { IDashboardClientCallback, IGenericHomeRow, IGenericDashboardTextColumn, EGenericDashboardColumnType, IGenericHomeHandlerParameter } from "app/components/dashboard/dashboard-foundation/generic-dashboard-home/generic-dashboard-home.model";
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";


const options: IAdditionalGenericNonSerializableServiceOptions = {
    nsType: ENonSerializableObjectType.customEvents,
    requestType: apiRequestType.dashboardExtra.attendance.provider,    
    title: gTranslations.attendance.attendanceEvents,
    currentDashboardRoute: routeList.dashboard.children.serviceStatus.path,
    defaultNSRoute: routeList.dashboard.children.serviceStatus.children.attendanceEvents,   
};
@Injectable({
    providedIn: 'root'
})
export class CustomEventsService extends GenericNonSerializableService  {

    public options: typeof options = options;

    constructor(
        api: ServerCommunicationService,
        routeSvc: RoutingService,
        rbs: RequestBuilderServices,
        session: SessionService,
    ) {
        
        super(
            options.currentDashboardRoute,
            options.defaultNSRoute,
            { api, rbs, session, routeSvc }
        )
    }


    public getRequest = (): IRequest => {
        return this.getRequestForNS({
            apiRequestType: this.options.requestType,
            nsType: this.options.nsType,
            cursor: null,
        });
    }
    
    
    public goToDetails(item: INonSerializable) {
        this.routeSvc.navigateToId(
            routeID.dashboard,
            this.mainRoute,
            this.baseRoute.path,
            this.baseRoute.children.details.path,
            this.baseRoute.children.details.routeParam.replace(`${this.baseRoute.children.details.routeParam}`, item.idNS),
        );
    }




}
