<ng-container *ngIf="isDialog">
  <h4 mat-dialog-title>Editar ação após recorrências de erro</h4>
</ng-container>

<ng-container *ngTemplateOutlet="editorBody"></ng-container>

<ng-container *ngIf="isDialog">
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Cancelar</button>
    <button mat-flat-button color="primary" (click)="dialogSaveClick()">Salvar</button>
  </mat-dialog-actions>
</ng-container>

<ng-template #editorBody>
  <mat-form-field appearance="fill" class="full-width-field">
    <mat-label>Quantidade de recorrência para executar a ação</mat-label>
    <input matInput [(ngModel)]="actionOnError.errorsCount" type="number">
  </mat-form-field>

  <app-ns-picker *ngIf="nsFnPicker" [handler]="nsFnPicker"></app-ns-picker>

  <cm-bot-action-editor *ngIf="botActionHandler" [handler]="botActionHandler"></cm-bot-action-editor>
</ng-template>
