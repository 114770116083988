import { EMetadataNames } from "../metadata/metadata-db";
import { genericTypedSuggestions } from "../../tools/type-utils";

export type TEFileError = Array<EFileError>;

export enum EFileError {
    wrongNumberOfColumns = 'wrongNumberOfColumns',
    emailInvalid = 'emailInvalid',
    foneInvalid = 'foneInvalid',
    characterInvalidAtBegining = 'characterInvalidAtBegining',
    emptyRequiredColumn = 'emptyColumn',
    invalidGeoOrZip = 'invalidGeoOrZip',
}


export interface IFileProcessorConfig {
    delimiters: string[];
    globalCanonicalToFileError: { [key in EMetadataNames]?: EFileError };
}


export const fileProcessorConfig = genericTypedSuggestions<IFileProcessorConfig>()({
    delimiters: [';'],
    globalCanonicalToFileError: {
        [EMetadataNames.email]: EFileError.emailInvalid,
        [EMetadataNames.cellular]: EFileError.foneInvalid,
    }
});