import { EPostgresTransactionType } from "@colmeia/core/src/persistency/postgres-shared.types";


export function getEmptyMenu() {
    const emptyMenu = {
        idMenu: '',
    } as const;
    return emptyMenu;
}

export function getCRMSaveConfigItemRequestConfig() {
    return {
        idMenu: '',
        postgresTransType: EPostgresTransactionType.readWrite,
    } as const
}