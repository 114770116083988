import { DateTime } from 'luxon';

/**
 * output do new Date().toISOString() -> '2024-06-10T20:25:58.201Z'
 */
export type TISODateString = string

export enum EValidFormatFinal {
    ddmmyyyy = 'ddMMyyyy',
    yyyymmdd = 'yyyyMMdd',
    ddmmyy = 'ddMMyy',
    mmyy = 'MMyy', //'01' + 'mm' + '20' + 'yy',
    ISO8601 = 'ISO8601',
}

export enum EDateElements {
    year = 'yr',
    month = 'mnth',
    day = 'd',
    hours = 'hrs',
    seconds = 'scnds',
    milliseconds = 'mllsconds',
    quarters = 'qrtrs',
}

export interface IDateInput {
    value: number;
    type: EDateElements
}


export interface IDurationObject {
    years?: number | undefined;
    quarters?: number | undefined;
    months?: number | undefined;
    weeks?: number | undefined;
    days?: number | undefined;
    hours?: number | undefined;
    minutes?: number | undefined;
    seconds?: number | undefined;
    milliseconds?: number | undefined;
}

export interface IDateUtilsState {
    isValid: boolean
    date?: DateTime
}

export interface IDateUtilsFormatType {
    type: EValidFormatFinal
}
export type TDateUtilsFormatType = EValidFormatFinal | string
export interface IDateUtilsCreateParams {
    date: string
    format?: TDateUtilsFormatType
}


export interface IDateUtils {
    isValid(): boolean;
    getISODate(): string;
    isToday(): boolean;
    isBigger(anotherDate: IDateUtils): boolean;
    isBiggerYearThan(year: number): boolean;
    isSmaller(anotherDate: IDateUtils): boolean;
    isSmallerYearThan(year: number): boolean;
    isFuture(): boolean;
    isPast(): boolean;
    add(duration: IDurationObject): IDateUtils;
    subtract(duration: IDurationObject): IDateUtils;
    diff(anotherDate: IDateUtils): IDurationObject;
}
