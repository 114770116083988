import { Injectable } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { ServerCommunicationService } from './server-communication.service';
import { Participant } from '@colmeia/core/src/business/participant';
import { TrackerErrorClientError } from '@colmeia/core/src/interaction/tracker/send-client-error';
import { TrackerDebug } from '@colmeia/core/src/interaction/tracker/tracker-debug';
import { ILogCharacteristic, allSeverityRegistry } from '@colmeia/core/src/core-constants/tracker-qualifiers';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { getNormalizedError } from '@colmeia/core/src/rules/aux-function';
import { ELocalQueueName } from '@colmeia/core/src/core-queue/core-queue-service';
import {RequestBuilderServices} from "./request-builder.services";
import {SessionService} from "./session.service";
import {SocketService} from "./socket.service";
import {QueuService} from "./queue.service";
import {clientConstants} from "../model/constants/client.constants";
import { IInteractionJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';


/**
 * Services that handles all kinds of communication with server regarding interactions (saving)
 */
@Injectable({
    providedIn: 'root'
})
export class InteractionPersistorServices {

    private rbs: RequestBuilderServices;
    private sessionService: SessionService;
    private server: ServerCommunicationService;

    constructor(private socket: SocketService, private queue: QueuService) {

    };

    public setDependencyRequestBuilderService(rbs: RequestBuilderServices): void {this.rbs = rbs;};
    public setDependSessionService(sessionService: SessionService): void {this.sessionService = sessionService;};
    public setDependencyCommunucationService(server: ServerCommunicationService): void {this.server = server;};

    public sendSocketInteraction(interaction: Interaction): void {
        this.socket.sendSocketInteraction(interaction);
    }

    public sendErrorEvent(error: Error, qualifier: ILogCharacteristic, functionName: string, details: Object = {}): void {
        try {
            error = getNormalizedError(error);
            const participant: Participant = this.sessionService.getPlayerPersonalGroupParticipant();
            const erroTracker: TrackerErrorClientError = new TrackerErrorClientError(participant, error, qualifier, functionName, details);
            this.sendSocketInteraction(erroTracker);
        } catch (error) {}
    }

    public sendTrace(qualifier: ILogCharacteristic, functionName: string, details: Object = {}): void {
        try {
            const participant: Participant = this.sessionService.getPlayerPersonalGroupParticipant();
            const deb: TrackerDebug = new TrackerDebug(participant, functionName, details, qualifier);
            this.sendSocketInteraction(deb);

        } catch (error) {}
    }


    public sendDebug(functionName: string, details: Object): void {
        if (clientConstants.debug.on) {
            const participant: Participant = this.sessionService.getPlayerPersonalGroupParticipant();
            const deb: TrackerDebug = new TrackerDebug(participant, functionName, details, allSeverityRegistry.debug);
            this.sendSocketInteraction(deb);
        }
    }

    public async sendControlledSocketInteraction(interaction: Interaction, controll: ELocalQueueName): Promise<void> {
        const json: IInteractionJSON = interaction.toJSON();
        this.queue.enqueue(controll, {id:  json.primaryID, data: json});
        this.socket.sendControlledSocketInteraction(interaction, controll);
    };

    public receiveInteraction(idInteraction: TGlobalUID): void {
        this.queue.removeFromQueue(ELocalQueueName.sendInteractionByAPI, idInteraction);
    }

}

