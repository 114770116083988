


<!--button class="menu-icon" (click)='goToMenu()' *ngIf="canShowMenuButton">
  <mat-icon>
    menu
  </mat-icon>
</button-->


<section class="section" *ngFor="let section of sections">
  <header
    [ngClass]="{
      'only-mobile': section.headerMobileOnly,
      'center-header': section.centerHeader
    }"
  >
    <h4>{{ section.header }}</h4>
    <button
      (click)="section.onRightIconClick()"
      class="rightIcon"
      *ngIf="section.rightButtonIcon"
    >
      <img
        class="rightIconImage"
        *ngIf="section.rightButtonIcon.url"
        [src]="section.rightButtonIcon?.url"
        [style.background-color]="section.rightButtonIcon?.backgroundColor"
      />

      <i *ngIf="!section.rightButtonIcon.url" class="material-icons">{{
        section.rightButtonIcon
      }}</i>
    </button>
  </header>
  <app-tools-content-visualization
    [section]="section"
  ></app-tools-content-visualization>
</section>

