<div class="notifications-settings__container">

  <div class="all-devices--control">
    <span *ngIf="!isNotificationsActive">
      {{ translations.activate }}
    </span>
    <span *ngIf="isNotificationsActive">
      {{ translations.deactivate }}
    </span>
    <div class="all-devices--togle">
      <mat-slide-toggle labelPosition="before" color="primary" class="loadable" (change)="handlePolicyChange($event)"
        [checked]="policyActive" [class.loading]="updatingPolicy" [disabled]="isOnlyAllowedToVisualizeNotifications">
      </mat-slide-toggle>
    </div>
  </div>

  <div class="deactivate-string" *ngIf="showDeactivateString">
    <p>{{ translations.deacu+' '+deactivateString }}</p>
  </div>
  <div class="deactivate-options" *ngIf="showDisableMode">
    <mat-radio-group color="primary" [ngModel]="disableMode" [disabled]="loadingModeChange"
      (change)="onDisableModeChange($event)">
      <ul>
        <li>
          <div class="disable__option">
            <div class="disable__option--button">
              <mat-radio-button class="dense-radio-button" [value]="EDisableNotificationAmount.TwoHours"></mat-radio-button>
            </div>
            <div class="disable__option--label">
              <span>
                {{ translations.deac1 }}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="disable__option">
            <div class="disable__option--button">
              <mat-radio-button class="dense-radio-button" [value]="EDisableNotificationAmount.OneDay"></mat-radio-button>
            </div>
            <div class="disable__option--label">
              <span>
                {{ translations.deac2 }}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="disable__option">
            <div class="disable__option--button">
              <mat-radio-button class="dense-radio-button" [value]="EDisableNotificationAmount.OneMonth"></mat-radio-button>
            </div>
            <div class="disable__option--label">
              <span>
                {{ translations.deac3 }}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div class="disable__option">
            <div class="disable__option--button">
              <mat-radio-button class="dense-radio-button" [value]="EDisableNotificationAmount.Undefined"></mat-radio-button>
            </div>
            <div class="disable__option--label">
              <span>
                {{ translations.deac4 }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </mat-radio-group>

  </div>

  <div class="devices" *ngIf="policyActive">
    <header class="title-header">
      <h2>{{ translations.devices }}</h2>
      <hr>
    </header>
  </div>
  <div class="devices-list" *ngIf="policyActive">
    <div class="device-entry" *ngFor="let device of devices; let i = index">
      <div class="device--name">
        {{ device.deviceName }}
      </div>
      <div class="device--status">
        <mat-slide-toggle labelPosition="before" color="primary" [checked]="device.cancelClock === 0"
          (change)="deactivateDevice($event, device)"
          [disabled]="isOnlyAllowedToVisualizeNotifications"></mat-slide-toggle>
      </div>
    </div>
  </div>

  <div class="add-button" *ngIf="canInsertCurrentDevice && policyActive">
    <header class="title-header">
      <h2>{{ translations.addDevice }}</h2>
      <hr>
    </header>
  </div>

  <div class="input--name" *ngIf="canInsertCurrentDevice && policyActive">
    <div class="input--container">
      <app-cm-input [placeholderName]="translations.placeholder" [currentValue]="deviceName"
        (onValueChanged)="deviceName = $event"></app-cm-input>
    </div>
    <div class="plus--button">
      <button [disabled]="!canSubmitNewDevice || isOnlyAllowedToVisualizeNotifications" (click)="createDevice()"
        tapFeedback="circular">
        <i class="material-icons add-icon">add</i>
      </button>
    </div>
  </div>

</div>
