import { FriendlyMessage } from "../../../../error-control/friendly-message";
import { ETypeOf, isInEnum, isInvalid, isValidString, pickKeys, values } from "../../../../tools/utility";
import { ValueOf } from "../../../../tools/utility-types";
import { isValidEngagement } from "../../../bot/engagement-function";
import { isValidBPMEvaluators } from "../../../BPM/bpm-functions";
import { TIBPConditionalEvaluatorArray } from "../../../BPM/bpm-model";
import { gTranslations } from "../../../const-text/translations";
import { ECRMCanonicalItemsTypes } from "../../../crm/crm-services/crm-config-canonical-model";
import { ICRMProjectServer, TSRdatabaseConfig } from "../../../crm/crm-services/crm-config-ds-model";
import { ECRMAgentAttributeUpdateControl } from '../../../crm/crm-services/crm-config.model';
import { ICRMAgentAttributeUpdateForm } from '../../../crm/crm-services/crm-config.model';
import { ICRMProjectAttributeConfig } from '../../../crm/crm-services/crm-config.model';
import { getCRMStatusNameByType } from "../../../crm/crm-utils";
import { assertNser } from "../helpers";
import { TNserValidator } from "../validate-nser";
import { defaultNserValidator } from "./nser";

export const srPackageValidator: TNserValidator = (nser: ICRMProjectServer, friendly: FriendlyMessage) => {

    defaultNserValidator(nser, friendly);

    if (!isValidString(nser.projectKey)) {
        friendly.addReturnGenericErrorMessage("Defina a chave do projeto");
    }

    validateDimensionFormConfig(nser.ticketState, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.TicketState));
    validateDimensionFormConfig(nser.phase, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.Phase));
    validateDimensionFormConfig(nser.severity, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.Severity));
    validateDimensionFormConfig(nser.urgencyLevel, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.UrgencyLevel));
    validateDimensionFormConfig(nser.closeState, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.CloseState));
    validateDimensionFormConfig(nser.supportLevel, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.SupportLevel));

    validateDimensionItemsConditions(nser.ticketState, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.TicketState));
    validateDimensionItemsConditions(nser.phase, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.Phase));
    validateDimensionItemsConditions(nser.severity, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.Severity));
    validateDimensionItemsConditions(nser.urgencyLevel, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.UrgencyLevel));
    validateDimensionItemsConditions(nser.closeState, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.CloseState));
    validateDimensionItemsConditions(nser.supportLevel, friendly, getCRMStatusNameByType(ECRMCanonicalItemsTypes.SupportLevel));

    if (isInvalid(nser.ticketState.id)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdTicketStateConfig);
    }

    if (isInvalid(nser.phase.id)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdSRPhasesConfig);
    }
    
    if (isInvalid(nser.severity.id)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdSeverityConfig);
    }

    if (isInvalid(nser.urgencyLevel.id)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdUrgencyLevelConfig);
    }

    if (isInvalid(nser.closeState.id)) {
        friendly.addReturnTranslationResponse(gTranslations.crm.missingIdCloseStateConfig);
    }

    assertNser(nser, friendly, {
        supportingDB: {
            type: ETypeOf.Object,
            isRequired: true,
            custom: ({ value }) => {
                const $keys = pickKeys<ValueOf<TSRdatabaseConfig>>()
                values(value)
                .map(item => {
                    if (isInvalid(item.idDatabaseManifest)) {
                        friendly.addValidationError(
                            gTranslations.errors.missingField,
                            $keys.idDatabaseManifest,
                        )
                    }

                    if (isInvalid(item.idDatabaseManifest)) {
                        friendly.addValidationError(
                            gTranslations.errors.missingField,
                            $keys.idDatabaseManifest,
                        )
                    }
                })
            },
        },
    })

    return friendly;
};

function isValidFormConfig(config: ICRMAgentAttributeUpdateForm): boolean {
    const hasValidControlType = isInEnum(ECRMAgentAttributeUpdateControl, config.control);
    const hasSelectedMetadata = config.control === ECRMAgentAttributeUpdateControl.useForm ? isValidString(config.register.idMetadata) : true;
    const hasValidEngagement = isValidEngagement(config.register.engagement, 0);

    return hasValidControlType && hasSelectedMetadata && hasValidEngagement;
}

function isValidDimensionConfig(config: ICRMProjectAttributeConfig): boolean {
    const isAllItemsValid: boolean = Object.values(config.updateConfig.items).every(i => isValidFormConfig(i.form || i as unknown as ICRMAgentAttributeUpdateForm))
    const validFormConfig: boolean = isValidFormConfig(config.updateConfig.form);

    return isValidString(config.id) && isAllItemsValid && validFormConfig;
}

function validateDimensionFormConfig(config: ICRMProjectAttributeConfig, friendly: FriendlyMessage, dimensionName: string) {
    if (!isValidFormConfig(config.updateConfig.form)) {
        friendly.addReturnGenericErrorMessage(`Configuração de formulário de "${dimensionName}" está inválida.`)
    }

    const isAllItemsValid: boolean = Object.values(config.updateConfig.items).every(i => {
        const isValid = isValidFormConfig(i.form || i as unknown as ICRMAgentAttributeUpdateForm);
        return isValid;
    });

    if (!isAllItemsValid) {
        friendly.addReturnGenericErrorMessage(`Configuração de formulários de itens de "${dimensionName}" inválida.`)
    }

}

function validateDimensionItemsConditions(config: ICRMProjectAttributeConfig, friendly: FriendlyMessage, dimensionName: string) {
    const isValid = Object.values(config.updateConfig.items).every(i => {
        const itemConditions = isValidBPMEvaluators(i.conditions as TIBPConditionalEvaluatorArray, { validatorErrorMessageIsOptional: true, minEvaluators: 0 });

        return itemConditions;
    });

    if (!isValid) {
        friendly.addReturnGenericErrorMessage(`Condicionais de atualização inválida na dimensão ${dimensionName}.`);
    }

}