import { Serializable } from "@colmeia/core/src/business/serializable";
import { whatsAppStatusTranslations } from "@colmeia/core/src/shared-business-rules/const-text/views/social-media";
import { GenericSharedService } from "@colmeia/core/src/shared-business-rules/shared-services/services/generic.shared.service";
import { EDelivery360Action } from "../../comm-interfaces/barrel-comm-interfaces";
import { asConst } from "../../tools/type-utils";
import { mapBy, keys, first, defaults, isValidRef, cloneDeep, isEqual, isValidArray } from "../../tools/utility";
import { makeHandlerFactory, TDeepMap } from "../../tools/utility-types";
import { ENonSerializableObjectType } from "../non-serializable-id/non-serializable-id-interfaces";
import { validateNonserializable } from "../non-serializable-id/validation/validate-nser";
import { isTemplateSentByStatus } from "../social-media/social-media.functions";
import { EColmeiaWATemplateApprovalStatus, ISocialMediaConnectionServer, IWhatsAppTemplate, TColmeiaWhatsAppTemplateApprovalStatus } from "../social-media/social-media.model";
import { IGenericNSTemplateServer, INSWhatsAppTemplateServer, ITemplateProvider, IWhatsAppTemplateApproval, IWhatsAppTemplateParameter } from "../social-media/template-model";
import { TGroupedSocialProvidersByIdProvider } from "../social-media/template-req-resp";
import { groupTemplateConnectionProviders, getIdProviderByConnection } from "../social-media/template.functions";
import { WhatsApp } from "../social-media/whatsapp-interface";

function merge<A, B, C>(fnA: (a: A) => B, fnB: (b: B) => C) {
    return (a: A) => fnB(fnA(a))
}
export class TemplateProcessor<NS extends IGenericNSTemplateServer = IGenericNSTemplateServer> {
    static #factory = makeHandlerFactory(this)
    static factory = merge(TemplateProcessor.#factory, item => item.is());

    public initialTemplate = cloneDeep(this.templateNS);
    constructor(public templateNS: NS) { }

    public get template(): NS['parameter'] { return this.templateNS.parameter; }

    // 

    hasModifications(): boolean {
        return !isEqual(this.templateNS, this.initialTemplate);
    }

    getProvidersMap(): TDeepMap<[idProvider: string, provider: ITemplateProvider]> {
        const map: Map<string, ITemplateProvider> = mapBy(this.template.providers.filter(item => item.idProvider), provider => provider.idProvider!);
        return map;
    }

    addProvidersAndReset(nss: ISocialMediaConnectionServer[]) {
        const grouped: TGroupedSocialProvidersByIdProvider = groupTemplateConnectionProviders(nss);

        this.template.providers ??= [];

        const previousProviders: Map<string, ITemplateProvider> = this.getProvidersMap();
        const providers: ITemplateProvider[] = keys(grouped)
            .map((idProvider: string) => asConst<ITemplateProvider>()({
                idProvider,
                provider: first(grouped[idProvider]).provider
            }))
            .map(provider => defaults(provider, previousProviders.get(provider.idProvider)))
            ;

        this.template.providers = providers;
    }

    removeProvider(ns: ISocialMediaConnectionServer) {
        if (!this.hasProvider(ns)) return;
        if (!this.hasValidTemplate()) return;
        const idProvider = getIdProviderByConnection(ns);
        return this.removeProviderById(idProvider);
    }

    removeProviderById(idProvider: string) {
        this.template.providers = this.template.providers.filter(provider => provider.idProvider !== idProvider);
    }

    addProviderIfDontExists(ns: ISocialMediaConnectionServer) {
        if (this.hasProvider(ns)) return;
        this.template.providers ??= [];
        const previousProviders: ITemplateProvider[] = this.template.providers;
        this.addProvidersAndReset([ns]);
        this.template.providers = [...previousProviders, ...this.template.providers];
    }

    isWhatsAppTemplate(): this is WhatsAppTemplateProcessor {
        return this.template.channelType === EDelivery360Action.Delivery360WhatsApp;
    }

    is(): NS extends INSWhatsAppTemplateServer ? WhatsAppTemplateProcessor<NS> : this;
    is() {
        if (this.isWhatsAppTemplate()) return new WhatsAppTemplateProcessor(this.templateNS);
        return this;
    }

    static getProvider<Provider extends ITemplateProvider>(
        connection: ISocialMediaConnectionServer,
        providers: ITemplateProvider[],
    ) {
        const idProvider = getIdProviderByConnection(connection);
        return providers.find(item => item.idProvider === idProvider) as (Provider | undefined);
    }

    getProvider(connection: ISocialMediaConnectionServer): NS['parameter']['providers'][number] | undefined {
        return TemplateProcessor.getProvider(connection, this.template.providers);
    }

    hasProvider(connection: ISocialMediaConnectionServer): boolean {
        return isValidRef(this.getProvider(connection));
    }

    getStatus(this: WhatsAppTemplateProcessor, connection: ISocialMediaConnectionServer): TColmeiaWhatsAppTemplateApprovalStatus | undefined {
        return (this.getProvider(connection))?.colmeiaApprovalStatus ?? EColmeiaWATemplateApprovalStatus.Notsent;
    }

    isTemplateSent(this: WhatsAppTemplateProcessor, connection: ISocialMediaConnectionServer) {
        return isTemplateSentByStatus(this.getStatus(connection));
    }

    hasValidTemplate() {
        const friendly = validateNonserializable(this.templateNS, ENonSerializableObjectType.channelTemplate);
        return isValidArray(this.template.providers) && friendly.isReallyOk();
    }

    static hasRejection(template: IWhatsAppTemplateParameter): boolean {
        return template.providers.some(provider => provider.colmeiaApprovalStatus === WhatsApp.Template.Status.Rejected);
    }

}

type Provider =
    | ISocialMediaConnectionServer
    | IWhatsAppTemplateApproval
    ;


export class WhatsAppTemplateProcessor<NS extends INSWhatsAppTemplateServer = INSWhatsAppTemplateServer> extends TemplateProcessor<NS> {
    throw(it: Iterable<string>) {
        for (const text of it) GenericSharedService.throw(text);
    }

    byConnectionOrProvider(provider: Provider): IWhatsAppTemplateApproval | undefined {
        if ('nName' in provider) return this.getProvider(provider);
        return provider;
    }

    throwCanNotSendErrors(input: Provider | undefined) {
        if (!input) return;
        const provider = this.byConnectionOrProvider(input);
        if (!provider) return;
        const error = this.getProviderError(provider)
        if (!error) return;
        GenericSharedService.throw(error);
    }

    getProviderError(input: Provider | undefined) {
        if (!input) return;
        const provider = this.byConnectionOrProvider(input);
        if (!provider) return 'Template não existe';
        if (!provider.colmeiaApprovalStatus) return `Template não enviado`;
        if (provider.colmeiaApprovalStatus === WhatsApp.Template.Status.Approved) return;

        let statusText: string;
        try {
            statusText = Serializable.getTranslation(whatsAppStatusTranslations[provider.colmeiaApprovalStatus]);
        } catch (e) {
            console.error(e);
            statusText = provider.colmeiaApprovalStatus;
        }
        return !provider.reason ? `Template não aprovado [status: ${statusText}]` : `Template rejeitado por ${provider.reason}`;
    }

    hasRejection() {
        return TemplateProcessor.hasRejection(this.template);
    }

}
