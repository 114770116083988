import { isValidRef, isThisOneOfThat } from "../../tools/utility";
import { IInteractionJSON } from "../../comm-interfaces/barrel-comm-interfaces";
import { Serializable } from "../../business/serializable";
import { EAppState, IInteractionActionsConfig as IInteractionActionsConfig, IInteractionActionHash, INotificationActions, INotificationDialogSetup } from "./notification-config";
import { constant, TGlobalUID } from "../../business/constant";
import { isRememberNotification, isPanic, isServiceChat } from "../inter-classification";
import { InteractionType } from "../../interaction/interaction-type";
import { UberCache } from "../../persistency/uber-cache";


export const DefaultInteractionActions: IInteractionActionsConfig = {
    enabled: false,
};

export const WrappedInteractionActions: IInteractionActionsConfig = {
    checkIfEnabled: isRememberNotification,
    generateActions: (interactionJson: IInteractionJSON, config: IInteractionActionsConfig, state: EAppState): INotificationActions => {
        return {
            vibrate: true,
            sound: config.sound[state],
            dialog: {
                title: getDialogTitle(config.dialog),
                content: interactionJson.serializableTextArray[0].text,
                sound: '/assets/audio/notifications/focused/notification.wav',
            }
        };
    },
    sound: {
        [EAppState.background]: '/assets/audio/notifications/focused/notification.wav',
        [EAppState.foregroud]: '/assets/audio/notifications/unfocused/plucky.mp3',
        [EAppState.chat]: '/assets/audio/notifications/dialog/dialog.mp3',
    },
    dialog: {
        title: {
            primaryId: 'tRememberme',
            idField: 1
        },
    }

};

export const StartServiceChatInteractionActions: IInteractionActionsConfig = {
    enabled: true,
    generateActions: (interactionJson: IInteractionJSON, config: IInteractionActionsConfig, state: EAppState): INotificationActions => {
        const panicService = isPanic(interactionJson);
        const serviceChat = isServiceChat(interactionJson);
        if (panicService || serviceChat) {
            config.dialog.content.idField = panicService ? 20 : 21;
            return {
                vibrate: true,
                sound: config.sound[state],
                dialog: {
                    title: getDialogTitle(config.dialog),
                    content: getDialogContent(config.dialog),
                    sound: config.dialog.soundToPlayUntilClosed
                }
            };
        }

        return NoActions;
    },
    sound: {
        [EAppState.background]: '/assets/audio/notifications/focused/cameronmusic__notification-1.wav',
        [EAppState.foregroud]: '/assets/audio/notifications/unfocused/plucky.mp3',
        [EAppState.chat]: '/assets/audio/notifications/dialog/dialog.mp3',
    },
    dialog: {
        title: {
            primaryId: 'startServiceChat',
            idField: 19
        },
        content: {
            primaryId: 'startServiceChat',
            idField: 20
        },
        soundToPlayUntilClosed: '/assets/audio/notifications/pearcewilsonking.mp3'
    }
}

export const StandardNotification: IInteractionActionsConfig = {
    enabled: true,
    vibrate: true,
    sound: {
        [EAppState.background]: '/assets/audio/notifications/focused/cameronmusic__notification-1.wav',
        [EAppState.foregroud]: '/assets/audio/notifications/unfocused/plucky.mp3',
        [EAppState.chat]: '/assets/audio/notifications/dialog/dialog.mp3',
    }
};

export function getStandardNotificationConfiguration(idInteractionType: TGlobalUID): IInteractionActionsConfig {
    const standars: IInteractionActionsConfig = interactionActionsHash[idInteractionType];
    if (standars) {
        return standars;
    } 
    const iType: InteractionType = <InteractionType>UberCache.uberFactory(idInteractionType, constant.objectType.interactionType)
    const isOneOfThat = isThisOneOfThat(
        idInteractionType,
        constant.interactionType.standard.message,
        constant.interactionType.standard.citation
    );
    const result = (isOneOfThat || iType.isFeedback()) ? StandardNotification : DefaultInteractionActions;
    return result;
 
}

const interactionActionsHash: IInteractionActionHash = {
    [constant.interactionType.special.wrapperInteraction]: WrappedInteractionActions,
    [constant.interactionType.serviceGroup.startServiceChat]: StartServiceChatInteractionActions,
};

export const NoActions: INotificationActions = {
    dialog: null,
    sound: null,
    vibrate: false,
};

export function getInteractionActions(interactionJson: IInteractionJSON, state: EAppState, isEmbed: boolean): INotificationActions {
    let config = isEmbed ? undefined : getStandardNotificationConfiguration(interactionJson.idInteractionType);
    
    if (!isValidRef(config)) {
        config = DefaultInteractionActions;
    }

    let enabled: boolean;
    if (isValidRef(config.checkIfEnabled)) {
        enabled = config.checkIfEnabled(interactionJson);
    } else {
        enabled = config.enabled;
    }

    if (!enabled) {
        return NoActions;
    }

    if (isValidRef(config.generateActions)) {
        return config.generateActions(interactionJson, config, state);
    }     

    let hasDialog = isValidRef(config.dialog);
    let title: string = hasDialog ? getDialogTitle(config.dialog) : null;
    let content: string = hasDialog ? getDialogContent(config.dialog) : null;

    return {
        sound: config.sound[state],
        vibrate: config.vibrate,
        dialog: hasDialog ? {
            title, content,
            sound: config.dialog.soundToPlayUntilClosed
        } : null
    };
}

function getDialogTitle(dialogSetup: INotificationDialogSetup): string {
    if (isValidRef(dialogSetup.title)) {
        return Serializable.staticFactory(dialogSetup.title.primaryId).getSerializableText(dialogSetup.title.idField);
    }
}

function getDialogContent(dialogSetup: INotificationDialogSetup): string {
    if (isValidRef(dialogSetup.content)) {
        return Serializable.staticFactory(dialogSetup.content.primaryId).getSerializableText(dialogSetup.content.idField);
    }
}
