import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../all-serializables";
import { EDelivery360Action } from "../../../comm-interfaces/interaction-interfaces";
import { TCustomEnumPickerFieldMaps } from "../../enum-db";
import { deprecatedSuggestions } from "../../../tools/type-utils";
import { IRequestDataColmeiaDownstream } from "../../colmeia-apis/colmeia-api-model";

const api = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.APIHeaderData,
    fields: deprecatedSuggestions<TCustomEnumPickerFieldMaps<keyof IRequestDataColmeiaDownstream>>()({
        token: 1, // Token
        idSocialNetwork: 2, // Id da rede social
        resourceId: 3, // Id do recurso
    }).ok,
});
export const apiScreenOptions = api.screenOptions;
export const apiTranslations = api.translations;
