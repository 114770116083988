import { IGetSchemmaRequest } from "@colmeia/core/src/request-interfaces/files-interfaces";
import { IBatchNonSerializableRequest } from "@colmeia/core/src/request-interfaces/lookup-ns-request";
import { apiRequestType, EFileRequest, EMacroRequest } from "@colmeia/core/src/request-interfaces/message-types";
import { IRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { IGetAttendentServicePackRequest } from "@colmeia/core/src/shared-business-rules/attendent-service-pack/attendent-sp-req-resp";
import { minToMS } from "@colmeia/core/src/time/time-utl";
import { EClientContext } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { ECRMTicketRuntimeRequests, ICRMGetAvatarPackagesRequest } from "@colmeia/core/src/shared-business-rules/crm/crm-services/crm-runtime-req-res";

export interface IRequestClientCacheConfig {
    /** 
     * Tempo de vida em milisegundos
     */
    ttl: number;
    /**
     * Função para definição da chave do cache
     */
    getKey: (req: IRequest) => string;
    /**
     * Define em quais contextos da aplicação ira relizar o cache
     * 
     * vazio = cache desativado
     */
    contexts: EClientContext[],
}

type TRequestClientCacheConfigHash = {
    [requestType in string]: IRequestClientCacheConfig;
}

export const requestClientCacheConfig: TRequestClientCacheConfigHash = {
    [ECRMTicketRuntimeRequests.getAvatarPackages]: {
        ttl: minToMS(5),
        contexts: [EClientContext.Dashboard],
        getKey(req: ICRMGetAvatarPackagesRequest) {
            return req.idAvatar;
        },
    },
    [apiRequestType.nonSerializable.getGeneric]: {
        ttl: minToMS(5),
        contexts: [],
        getKey(req: IBatchNonSerializableRequest) {
            return String(alphabeticalOrderStringArray(req.idsNS));
        },
    },
    [EMacroRequest.getMacros]: {
        ttl: minToMS(5),
        contexts: [EClientContext.Chat],
        getKey: (req: IGetAttendentServicePackRequest) => {
            return String(alphabeticalOrderStringArray(req.idServicePacks));
        }
    },
    [EFileRequest.retrieveSchemma]: {
        ttl: minToMS(5),
        contexts: [EClientContext.Chat],
        getKey: (req: IGetSchemmaRequest) => {
            return req.demandedTag;
        }
    },
    [apiRequestType.unAuthenticatedLookup]: {
        ttl: minToMS(5),
        contexts: [],
        getKey: (req: IBatchNonSerializableRequest) => {
            return String(alphabeticalOrderStringArray(req.idsNS));
        }
    },
}

function alphabeticalOrderStringArray(strs: string[]): string[] {
    return [...strs].sort((a, b) => a.localeCompare(b))
}