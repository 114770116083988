import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constant } from '@colmeia/core/src/business/constant';
import { isValidAssetAction } from '@colmeia/core/src/shared-business-rules/bot/asset-functions';
import { getTemplateActions, templateActionTypeConfig } from '@colmeia/core/src/shared-business-rules/bot/bot-action-config-db';
import { KBAssetType } from '@colmeia/core/src/shared-business-rules/bot/bot-asset-model';
import { emptyContentBasicAsset, IContentBasicAsset } from '@colmeia/core/src/shared-business-rules/bot/bot-content-model';
import { EBotActionType, TMarketingAction } from '@colmeia/core/src/shared-business-rules/bot/new-bot-action';
import { stringHasOnlyCanonical, validateURLButtonData } from '@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/template';
import { isValidWhatsappNumber } from '@colmeia/core/src/shared-business-rules/social-cc/config-cell';
import { EAdvancedTemplateOption, IQuickReplyOption, MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_PHONE_NUMBER_SIZE, MAX_WHATSAPP_TEMPLATE_BUTTON_TEXT_SIZE, MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_URL_SIZE, MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_COPY_CODE_SIZE, MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_OTP_SIZE } from '@colmeia/core/src/shared-business-rules/social-media/social-media.model';
import { IWhatsAppTemplateParameter } from '@colmeia/core/src/shared-business-rules/social-media/template-model';
import { getTemplateOptionConfig } from '@colmeia/core/src/shared-business-rules/social-media/template.constants';
import { isAuthTemplate } from '@colmeia/core/src/shared-business-rules/social-media/template.functions';
import { isInvalidString, isValidURL, typedClone } from '@colmeia/core/src/tools/utility';
import { IBotActionHandler } from 'app/components/dashboard/bot-action-editor/bot-action-editor.component';
import { TVarEditorVariables } from 'app/handlers/var-editor.handler';
import { AssetAdderProcessor, IAssetAdderHandler } from 'app/model/dashboard/asset-adder.model';
import { SnackMessageService } from 'app/services/snack-bar';

export interface WhatsappTemplateQuickReplyData {
    quickReply: IQuickReplyOption,
    isTemplateSent?: boolean;
    variables?: TVarEditorVariables;
    disabledActionTypes?: TMarketingAction[];
    template?: IWhatsAppTemplateParameter
}

type TAbstractControlHash = {
    [key: string]: AbstractControl;
}

export function dataValueValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isValueValidUrl = isValidURL(control.value);
        const isValueValidOTP = stringHasOnlyCanonical(control.value);
        const isValueValidNumber = isValidWhatsappNumber(control.value);

        return !(isValueValidUrl || isValueValidNumber || isValueValidOTP)
            ? { dataValue: true }
            : null;
    };
}

@Component({
    selector: 'app-whatsapp-template-quick-reply-edit',
    templateUrl: './whatsapp-template-quick-reply-edit.component.html',
    styleUrls: ['./whatsapp-template-quick-reply-edit.component.scss']
})
export class WhatsappTemplateQuickReplyEditComponent implements OnInit {
    public quickReply: IQuickReplyOption;
    public eventActionHandler: IBotActionHandler;
    public form: UntypedFormGroup;
    public buttonTextMinLenght: number = 2;

    public get buttonTextMaxLenght(): number {
        return MAX_WHATSAPP_TEMPLATE_BUTTON_TEXT_SIZE;
    }

    public get buttonContentMaxLenght(): number {
        switch (this.quickReply.action.type) {
            case EBotActionType.quickCallPhone:
                return MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_PHONE_NUMBER_SIZE;

            case EBotActionType.quickVisitWebsite:
                return MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_URL_SIZE;

            case EBotActionType.otpCopyCode:
                return MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_OTP_SIZE;

            case EBotActionType.copyCode:
                return MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_COPY_CODE_SIZE;

            default:
                return constant.maxAssetTextLength;
        }
    }

    public get getButtonContentMaxLenghtForInput(): number {
        if (this.shouldHideTextInput()) {
            // return nulls because the character limit is alreaby being validated by the asset adder
            // this prevents a bug caused by the UntypedFormGroup validating character limits using the wrong logic
            return null;
        }

        return this.buttonContentMaxLenght;
    }

    public requiredButtonData: boolean;
    public get isTemplateSent(): boolean {
        return this.data.isTemplateSent;
    }
    public get variables() {
        return this.data.variables;
    }
    constructor(
        private dialogRef: MatDialogRef<WhatsappTemplateQuickReplyEditComponent, WhatsappTemplateQuickReplyData>,
        private snackMessageSvc: SnackMessageService,
        @Inject(MAT_DIALOG_DATA) private data: WhatsappTemplateQuickReplyData
    ) {
        this.quickReply = typedClone(data.quickReply);
    }

    ngOnInit(): void {
        this.initForm();

        let isActionSelectDisabled = false;

        if (this.isTemplateSent) {
            const templateOptionType = templateActionTypeConfig[this.quickReply.action.type].templateOptionType;

            isActionSelectDisabled = getTemplateOptionConfig(templateOptionType).isSelectionDisabledWhenSent;
        }

        this.eventActionHandler = {
            actionTypes: getTemplateActions(),
            disabledActionTypes: this.data.disabledActionTypes,
            botAction: this.quickReply.action,
            actionTitle: 'Selecione uma ação:',
            isActionSelectDisabled,
            isEventAction: false,
            allowConfigOptOutFunction: true
        }

        this.initButtonDataContentHandler();
    }

    public initForm() {
        const abstractControlStruct: TAbstractControlHash = {};

        if (this.shouldShowButtonText()) {
            abstractControlStruct.buttonText = new UntypedFormControl(this.quickReply.buttonText);
        }

        const templateOptionType = templateActionTypeConfig[this.quickReply.action.type]?.templateOptionType;

        if (templateOptionType === EAdvancedTemplateOption.callToAction) {
            const useValueValidator = !this.isCopyCode();

            this.requiredButtonData = true;
            abstractControlStruct.buttonData = new UntypedFormControl(
                this.quickReply.buttonData,
                useValueValidator ? [dataValueValidator()] : undefined
            );
        } else {
            this.requiredButtonData = false;
        }

        this.form = new UntypedFormGroup(abstractControlStruct);
    }

    onActionChange() {
        this.initButtonDataContentHandler();
    }

    public isURL() {
        return this.quickReply.action.type === EBotActionType.quickVisitWebsite
    }

    public isOTP() {
        return this.quickReply.action.type === EBotActionType.otpCopyCode
    }

    public isCopyCode() {
        return this.quickReply.action.type === EBotActionType.copyCode;
    }

    public shouldHideTextInput() {
        return this.isURL() || this.isOTP() || this.isCopyCode();
    }

    public shouldShowButtonDataContentHandler() {
        return this.buttonDataContentHandler && this.shouldHideTextInput()
    }

    public shouldShowButtonText(): boolean {
        // botão copy code não tem texto
        return !this.isCopyCode();
    }

    get buttonText(): AbstractControl { return this.form.get('buttonText'); }
    get buttonData(): AbstractControl { return this.form.get('buttonData'); }


    public buttonDataContentHandler: IAssetAdderHandler<IContentBasicAsset>;

    MAX_WHATSAPP_TEMPLATE_BUTTON_PHONE_NUMBER_SIZE = MAX_WHATSAPP_TEMPLATE_BUTTON_CONTENT_PHONE_NUMBER_SIZE

    public initButtonDataContentHandler() {
        const { quickReply } = this;

        if (this.quickReply.buttonData) {
            this.quickReply.buttonDataContent ??= emptyContentBasicAsset(this.quickReply.buttonData);
        }
        const value = this.quickReply.buttonDataContent;
        const limitCharacters: number = this.buttonContentMaxLenght;
        const handler: IAssetAdderHandler<IContentBasicAsset> = {
            title: this.getButtonDataLabel(),
            assets: value ? [value] : [],
            //
            maxAssets: 1,
            assetTypesEnabled: [KBAssetType.content],
            disableFallback: true,
            removeAsset: AssetAdderProcessor.onRemove(setter),
            saveAsset: AssetAdderProcessor.onSaveWhatsApp(setter),
            variables: this.variables,
            isDisabled: this.isTemplateSent,
            shouldHideBBCode: true,
            shouldHideBottomOptions: true,
            limitCharacters,
            limitRows: 1,
            computeAmountCharactersString: AssetAdderProcessor.computeAmountCharactersForWhatsAppTemplate
        }

        this.buttonDataContentHandler = handler;

        this.initForm();

        function setter(asset?: IContentBasicAsset) {
            quickReply.buttonData = asset?.variablesTemplate?.compiledTemplate;
            quickReply.buttonDataContent = asset;
        }
    }


    getErrorMessageFor(formControl: AbstractControl): string | null {
        const requiredText = "Esse campo é obrigatório";

        if (formControl === this.buttonText) {
            return formControl.errors?.['required']
                ? requiredText
                : formControl.errors?.['minlength']
                    ? `Precisa ter no mínimo ${this.buttonTextMinLenght} caracteres`
                    : null
        }

        if (formControl === this.buttonData) {
            return formControl.errors?.['required']
                ? requiredText
                : formControl.errors?.['dataValue']
                    ? `Digite um número de telefone válido`
                    : null;
        }

        return null;
    }

    public hasErrorFor(formControl: AbstractControl): boolean {
        return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    public disableSaveButton(): boolean {
        return this.form.invalid || !isValidAssetAction(this.quickReply.action)
    }

    save() {
        if (this.isURL() && this.quickReply.buttonData) {
            const errorMessage = validateURLButtonData(this.quickReply.buttonData);
            if (errorMessage) return this.snackMessageSvc.openError(errorMessage);
        }
        if (this.isAuthTemplate() && isInvalidString(this.quickReply.buttonText)) this.quickReply.buttonText = "Copiar Código";

        this.dialogRef.close({ quickReply: this.quickReply });
    }

    public isAuthTemplate() {
        return this.data.template && isAuthTemplate(this.data.template);
    }

    public getButtonDataLabel(): string {
        switch (this.quickReply.action.type) {
            case EBotActionType.quickCallPhone:
                return "Telefone";

            case EBotActionType.quickVisitWebsite:
                return "URL";

            case EBotActionType.otpCopyCode:
                return "Código de autenticação";

            case EBotActionType.copyCode:
                return "Código promocional";
        }
    }
}
