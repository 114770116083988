<div class="upload__container">
  <!-- file upload options -->
  <div class="upload-body__container">
    <ul class="upload__options">
      <li>
        <button mat-stroked-button class="button-w-icon" color="primary" (click)="onFileUploadClicked()">
          <mat-icon>file_upload</mat-icon>&nbsp;
          {{ getMultimediaDescription() }}
        </button>
        <app-multimedia-instance-uploader
          #mmUploader
          [multimediaHandler]="multimediaHandler">
        </app-multimedia-instance-uploader>
      </li>
      <li *ngIf="mustShowCameraOption">
        <button style="float:right" (click)="onCameraClicked()">
        <span class="clickable" >
            Câmera
        </span>
        </button>
      </li>
    </ul>

    <!-- file list -->
    <ul class="upload__files">
      <li *ngFor="let hash of getFileHashList()" class="to-upload-file">
        <span *ngIf="!canShowUploadProgress(hash)"> {{ getFileName(hash) }} </span>
        <progressbar
          *ngIf="canShowUploadProgress(hash)"
          [uploadSignal]="getUploadSignal(hash)"
          [fileName]="getFileName(hash)"
          [color]="getProgressBarColor(hash)"
          [progressValue]="getPercentage(hash)">
        </progressbar>
      </li>
      <li *ngFor="let handler of multimediaPreviewHandlers">
        <app-multimedia-preview #multimediaPreview class="mm-preview" [handler]="handler">
        </app-multimedia-preview>
      </li>
    </ul>
  </div>

</div>
