export enum EMessagesTypes {
    confirmPurchaseMessage = 'confirmPurchaseMessage'
}

export enum EChatSideBarConstants{
    id = 'sideBarLeft.constants',  
}

export enum EChatSideBarTasks{
    id = 19,
    pendency = 20,
    demand = 21,
    opened = 29,
}

export enum EChatSideBarViewMode {
    id = 22,
    topic = 23,
    chat = 24,
    forum = 25,
}


export enum EChatSideBarSpecialsFilters {
    id = 30,
    invert = 31,
    service = 33
}

export enum EChatSideBarChannels{
    id = 32,
}