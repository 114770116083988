import { Injectable } from "@angular/core";
import { EBPMType } from '@colmeia/core/src/shared-business-rules/BPM/bpm-model';
import { GenericNonSerializableService } from "../generic-ns.service";
import { RequestBuilderServices } from "../request-builder.services";
import { RoutingService } from "../routing.service";
import { ServerCommunicationService } from "../server-communication.service";
import { SessionService } from "../session.service";
import { BPMRulesBotService } from './bpm-subject-rules/bpm-rules-bot.service';
import { BpmRulesCrmService } from "./bpm-subject-rules/bpm-rules-crm.service";
import { BpmRulesMktService } from "./bpm-subject-rules/bpm-rules-mkt.service";
import { IBPMSubjectRulesProcessorMethods } from "./bpm-subject-rules/bpm-rules.types";
import { BpmRulesNestedAIService } from './bpm-subject-rules/bpm-rules-nested-ai.service';

@Injectable({
    providedIn: "root",
})
export class BpmService {
    private genericNSSvc: GenericNonSerializableService;
    private bpmTypeToServiceHash: { [key in EBPMType]?: IBPMSubjectRulesProcessorMethods } = {}
    private chosenService: IBPMSubjectRulesProcessorMethods;
    private chosenType: EBPMType;

    constructor(
        session: SessionService,
        rbs: RequestBuilderServices,
        routeSvc: RoutingService,
        api: ServerCommunicationService,
        private bpmRulesBotSvc: BPMRulesBotService,
        private bpmRulesCrmService: BpmRulesCrmService,
        private bpmRulesMktSvc: BpmRulesMktService,
        private bpmRulesNestedAISvc: BpmRulesNestedAIService,
    ) {
        this.genericNSSvc = new GenericNonSerializableService(null, null, {
            api,
            rbs,
            routeSvc,
            session,
        });
        this.bpmTypeToServiceHash = {
            [EBPMType.crm]: this.bpmRulesCrmService,
            [EBPMType.bot]: this.bpmRulesBotSvc,
            [EBPMType.marketing]: this.bpmRulesMktSvc,
            [EBPMType.nestedAI]: this.bpmRulesNestedAISvc,
        }
    }

    setStrategy(bpmtype: EBPMType) {
        this.chosenService = this.bpmTypeToServiceHash[bpmtype]
        this.chosenType = bpmtype
    }

    getCurrentStrategy(): IBPMSubjectRulesProcessorMethods {
        return this.chosenService
    }

    getType(): EBPMType {
        return this.chosenType
    }
}
