import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger, MenuPositionX, MenuPositionY } from '@angular/material/menu';
import { Serializable } from '@colmeia/core/src/business/serializable';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { isValidRef } from "@colmeia/core/src/tools/utility";
import { first, timeout } from 'rxjs/operators';
import {
    MoreOptionsThreeDotsHandler, TMoreOptionsThreeDotsOption,
    TMoreOptionsThreeDotsOptionList
} from "../../../handlers/more-options-three-dots.handler";
import { InterfaceInfoSignal } from "../../../model/signal/interface-signal";
import { IChangeInterfaceListener } from "../../../model/signal/ps-interfaces";
import { HardwareLayerService } from "../../../services/hardware";
import { SignalListenerService } from "../../../services/signal/signal-listener";
import { SnackMessageService } from "../../../services/snack-bar";
import { SocketService } from './../../../services/socket.service';

@Component({
    selector: 'app-more-options-three-dots',
    templateUrl: './more-options-three-dots.component.html',
    styleUrls: ['./more-options-three-dots.component.scss']
})
export class MoreOptionsThreeDotsComponent implements OnInit, OnDestroy, IChangeInterfaceListener {
    @Input() handler: MoreOptionsThreeDotsHandler;

    @Input() isWhiteMoreOptions: boolean = false;
    @Input() isGrayMoreOptions: boolean = false;

    @Input()
    xPosition: MenuPositionX = "after";

    @Input()
    yPosition: MenuPositionY = "below";

    @ViewChild("menuTrigger")
    menuTrigger: MatMenuTrigger;

    public waitingReconnect: boolean = false;

    constructor(
        private hw: HardwareLayerService,
        private snack: SnackMessageService,
        private listener: SignalListenerService,
        private cdr: ChangeDetectorRef,
        private socketSvc: SocketService
    ) {}

    ngOnInit() {
        this.listener.listenToInterfaceChanges(this);
    }

    ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    }

    get hasNetwork(): boolean {
        return this.hw.getNetwork().isOnline();
    }

    receiveChangeInterfaceCallback(sign: InterfaceInfoSignal) {
        if(isValidRef((sign.getInterfaceSignal().isOffLine))) {
            this.cdr.markForCheck();
        }
    }

    getMenuOptions(): TMoreOptionsThreeDotsOptionList {
        return this.handler.getMenuOptions()
    }

    onOptionClicked(option: TMoreOptionsThreeDotsOption) {
        this.handler.optionSelected(option)
    }

    isValid(item: Serializable): boolean {
        return isValidRef(item);
    }

    canShowEmoji(item: Serializable): boolean {
        return isValidRef(item.getEmoji())
    }

    trackByFn(_, item: Serializable) {
        return item.getName();
    }

    onClick(): void {
        if (this.hw.getNetwork().isOffline()) {
            this.menuTrigger.closeMenu();
            this.waitingReconnect = true;
            this.socketSvc.socketReconnect$.pipe(first(), timeout(secToMS(10))).subscribe(() => {
                this.waitingReconnect = false;
                this.menuTrigger.openMenu();
            }, () => {
                this.waitingReconnect = false;
            });
        }
        else {
            this.menuTrigger.openMenu();
        }
    }
}
