import { IMultimediaTagJSON } from '../comm-interfaces/multi-media-interfaces';
import {MultiMediaType} from './multi-media-type';
import {Serializable} from '../business/serializable';
import {constant, TGlobalUID} from '../business/constant';
import {UberCache} from '../persistency/uber-cache';

export type TMultiMediaTagArray = Array<MultimediaTag>;

export class MultimediaTag extends Serializable {
    private multimediaType: MultiMediaType;

    private constructor(idMultimediaTag: TGlobalUID, multimediaType: MultiMediaType) {
        super(constant.objectType.multiMediaTag, idMultimediaTag);
        this.multimediaType = multimediaType;
    };

    public getMultiMediaTagID(): TGlobalUID { return super.getPrimaryID();};
    public getMultiMediaTag(): string {return super.getName();};

    public static getMultimediaTagArray(): TMultiMediaTagArray {
        return <TMultiMediaTagArray>UberCache.getSerializableArray(constant.objectType.multiMediaTag);
    };

    public static factoryMessage(json: IMultimediaTagJSON): MultimediaTag {
        let mmmTag: MultimediaTag = <MultimediaTag>UberCache.uberFactory(json.primaryID, constant.objectType.multiMediaTag, false);
        if (mmmTag == null)
            mmmTag = new MultimediaTag(json.primaryID, MultiMediaType.staticFactory(json.idMultimediaType));
        mmmTag.rehydrate(json);
        return mmmTag;
    };

    public rehydrate(json: IMultimediaTagJSON): void {
        super.rehydrate(json);
        this.multimediaType = MultiMediaType.staticFactory(json.idMultimediaType);
    };

    public toJSON(): IMultimediaTagJSON {
        let json: IMultimediaTagJSON = <IMultimediaTagJSON>super.toJSON();
        json.idMultimediaType = this.multimediaType.getMultimediaTypeID();
        return json;  
    };

    public static staticFactory(idMultimediaTag: TGlobalUID): MultimediaTag {
        return <MultimediaTag>UberCache.uberFactory(idMultimediaTag, constant.objectType.multiMediaTag, true);
    };
};

