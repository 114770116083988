import { TGlobalUID, constant } from "../../business/constant";
import { EServiceGroupIntType, EGeolocationIntType } from "../../business/constant.enums";
import {EMultimediaNature} from "../../multi-media/multi-media-type";

interface IInteractionTypeRules {
    allowedInChatFilter: boolean;
}

interface IInteractionTypeRuleHash {
    [idInteractionType: string]: IInteractionTypeRules;
}

const defaultRules: IInteractionTypeRules = {
    allowedInChatFilter: true
}

const InteractionTypeRules: IInteractionTypeRuleHash = {
    [constant.interactionType.security.newGroupCreated]: {
        ...defaultRules,
        allowedInChatFilter: false,
    },
    [constant.interactionType.standard.citation]: {
        ...defaultRules,
        allowedInChatFilter: false,
    },
    [constant.interactionType.sharing.shareInteraction]: {
        ...defaultRules,
        allowedInChatFilter: false,
    },
    [EServiceGroupIntType.finishService]: {
        ...defaultRules,
        allowedInChatFilter: false,
    },
    [EGeolocationIntType.endRealTimeTracking]: {
        ...defaultRules,
        allowedInChatFilter: false,
    },
};

export enum EInteractionMediaEmoji {
    Image = '📷',
    Audio = '🎧',
    Video = '🎞️',
    Document = '🗎'
}

export const MultimediaNatureEmojiMap: {[key in EMultimediaNature]: EInteractionMediaEmoji} = {
    [EMultimediaNature.Audio]: EInteractionMediaEmoji.Audio,
    [EMultimediaNature.Document]: EInteractionMediaEmoji.Document,
    [EMultimediaNature.Image]: EInteractionMediaEmoji.Image,
    [EMultimediaNature.SpreadSheet]: EInteractionMediaEmoji.Document,
    [EMultimediaNature.Video]: EInteractionMediaEmoji.Video,
    [EMultimediaNature.unknown]: EInteractionMediaEmoji.Image
};

function getInteractionTypeRules(interactionType: TGlobalUID): IInteractionTypeRules {
    return InteractionTypeRules[interactionType] || defaultRules;
}

export function isAllowedInChatFilter(interactionType: TGlobalUID) {
    return getInteractionTypeRules(interactionType).allowedInChatFilter;
}