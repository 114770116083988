<mat-form-field
    appearance="fill"
    class="no-padding-bottom quill-form-field"
  >
    <mat-label>{{inputLabel}}</mat-label>

    <mat-quill
      #paramValueInput
      theme="bubble"
      class="hide-toolbar"
      [modules]="modules"
      placeholder=""
      [ngModel]="_inputValue"
      (ngModelChange)="handleInputValueChange($event)"
      [readOnly]="readonly"
    ></mat-quill>

    <span matSuffix *ngIf="!readonly">
      <button
        #inserVarTrigger
        mat-icon-button
        (click)="openTemplate(inserVarTrigger, $event)"
        matTooltip="Clique para adicionar uma variável."
        [matTooltipPosition]="'above'"
      >
        <mat-icon>add</mat-icon>
      </button>
    </span>
  </mat-form-field>
