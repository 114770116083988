import { EDefineDashboard, EScreenGroups } from "@colmeia/core/src/shared-business-rules/visual-constants";
import { routeList } from "../../routes/route-constants";
import { createExtendedObjectHash, Swap, swap } from "@colmeia/core/src/tools/utility";
import { mapCurrentDashboard } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { IMapCurrentDashboardClient } from "./dashboard-db-types";
import { genericTypedSuggestions } from "@colmeia/core/src/tools/type-utils";
import { EFunctionContext } from "@colmeia/core/src/shared-business-rules/user-function/user-function-model";
import { $ValueOf } from "@colmeia/core/src/tools/utility-types";


export const mapCurrentDashboardClient = genericTypedSuggestions<{ [key in keyof typeof mapCurrentDashboard]: IMapCurrentDashboardClient }>()(createExtendedObjectHash(mapCurrentDashboard, {
    [EDefineDashboard.colmeia]: {
        route: routeList.dashboard.children.colmeia.path,
    },
    [EDefineDashboard.marketing]: {
        route: routeList.dashboard.children.marketing.path,
    },
    [EDefineDashboard.CRMServices]: {
        route: routeList.dashboard.children.crmServices.path,
    },
    [EDefineDashboard.jobs]: {
        route: routeList.dashboard.children.jobs.path,
    },
    [EDefineDashboard.omnisense]: {
        route: routeList.dashboard.children.ai.path,
    },
    [EDefineDashboard.attendance]: {
        route: routeList.dashboard.children.serviceStatus.path,
    },
    [EDefineDashboard.services]: {
        route: routeList.dashboard.children.service.path,
    },
    [EDefineDashboard.communication]: {
        route: routeList.dashboard.children.communication.path,
    },
    [EDefineDashboard.environments]: {
        route: routeList.dashboard.children.environments.path,
    },
    [EDefineDashboard.socialNetwork]: {
        route: routeList.dashboard.children.menuSN.path,
    },
    [EDefineDashboard.analytics]: {
        route: routeList.dashboard.children.analytics.path,
    },
    [EDefineDashboard.analyticsHistory]: {
        route: routeList.dashboard.children.analyticsHistory.path,
    },
    [EDefineDashboard.smartFlow]: {
        route: routeList.dashboard.children.smartFlow.path,
    },
    [EDefineDashboard.conversationalCommerce]: {
        route: routeList.dashboard.children.conversationalCommerce.path,
    },
    [EDefineDashboard.security]: {
        route: routeList.dashboard.children.security.path,
    },
    [EDefineDashboard.tools]: {
        route: routeList.dashboard.children.tools.path,
    }
} as const ))

export type TRapCurrentDashboardClientRoutes = $ValueOf<typeof mapCurrentDashboardClient>['route'];

type FunctionContextToRoutePageMap = { [context in EFunctionContext]: TRapCurrentDashboardClientRoutes };
type RoutePageToFunctionContextMap = Swap<FunctionContextToRoutePageMap>;

export const functionContextToRoutePageMap: FunctionContextToRoutePageMap = {
    [EFunctionContext.BOTContext]: routeList.dashboard.children.smartFlow.path,
    [EFunctionContext.ShakingHands]: routeList.dashboard.children.service.path,
    [EFunctionContext.MKTContext]: routeList.dashboard.children.marketing.path,
    [EFunctionContext.ClientContext]: routeList.dashboard.children.serviceStatus.path,
    [EFunctionContext.AI]: routeList.dashboard.children.ai.path,
}
export const routePageToFunctionContextMap: RoutePageToFunctionContextMap = swap(functionContextToRoutePageMap);