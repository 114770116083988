import { Location } from "@angular/common";
import { Component, Optional, ViewChild } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ActivatedRoute } from "@angular/router";
import { EUnitTypeID } from "@colmeia/core/src/business/constant.enums";
import {
    IFormSchema,
    SchemaProperty,
} from "@colmeia/core/src/general-form/general-form-interface";
import { EUniqueFileType } from "@colmeia/core/src/multi-media/file-interfaces";
import { TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/multi-media-instance";
import { MMconstant } from "@colmeia/core/src/multi-media/multimedia-constant";
import { IFileField } from "@colmeia/core/src/request-interfaces/files-interfaces";
import { EDefaultTag } from "@colmeia/core/src/shared-business-rules/colmeia-tags/tags";
import { gTranslations } from "@colmeia/core/src/shared-business-rules/const-text/translations";
import {
    ISchemaPropertyClient,
    SchemaPropertyServer,
} from "@colmeia/core/src/shared-business-rules/files/files";
import { ENonSerializableObjectType } from "@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces";
import { secToMS } from "@colmeia/core/src/time/time-utl";
import { isInvalid, isValidRef, nop } from "@colmeia/core/src/tools/utility";
import { defineProperties, defineProperty } from "@colmeia/core/src/tools/utility-types";
import { RootComponent } from "app/components/foundation/root/root.component";
import { GeneralFormSchemaEditComponent } from "app/components/general-form/general-form-schema-edit/general-form-schema-edit.component";
import {
    GenericDashboardEditHandler,
    IGenericDashboardEditCustomButton,
    IGenericDashboardEditHandlerParameter,
} from "app/handlers/generic-dashboard-edit.handler";
import { MultimediaHandler } from "app/handlers/multimedia-handler";
import { ENsMoreOptions } from "app/handlers/ns-more-options.handler";
import { createEmptySchemaProperty, createEmptySchemaPropertyServer } from "app/model/general-form.model";
import { DashBoardService } from "app/services/dashboard/dashboard.service";
import { GeneralFormService } from "app/services/general-form.service";
import { LookupService } from "app/services/lookup.service";
import { MultimediaService } from "app/services/multimedia.service";
import { SessionService } from "app/services/session.service";
import { SnackMessageService, EAppAlertTypes } from "app/services/snack-bar";
import * as _ from "lodash";
import { ColmeiaWindowRef } from "../dashboard-foundation/colmeia-window/colmeia-window-ref";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";

@Component({
    selector: "app-dashboard-form-create",
    templateUrl: "./dashboard-form-create.component.html",
    styleUrls: ["./dashboard-form-create.component.scss"],
})

//@TODO traducao antiga
export class DashboardFormCreateComponent extends RootComponent<
    "addTemplate" | "editTemplate" | "savedTemplate" | "saveErrorTemplate"
> {
    server: SchemaPropertyServer; // ESTE É O NONSERIALIZABLE
    schema: IFormSchema; // ESTE É O QUE SERÁ PASSADO EM CUSTOMTAGGABLE
    editing: boolean = false;

    @ViewChild(GeneralFormSchemaEditComponent)
    schemaEditor: GeneralFormSchemaEditComponent;

    constructor(
        private formSvc: GeneralFormService,
        private snackSvc: SnackMessageService,
        private location: Location,
        private session: SessionService,
        private dashboardSvc: DashBoardService,
        private lookupService: LookupService,
        private route: ActivatedRoute,
        private multiMediaSvc: MultimediaService,
        @Optional() private windowRef: ColmeiaWindowRef,

    ) {
        super({
            addTemplate: gTranslations.fragments.addTemplate,
            editTemplate: gTranslations.common.editTemplate,
            savedTemplate: gTranslations.fragments.savedTemplate,
            saveErrorTemplate: gTranslations.fragments.saveErrorTemplate,
        });
    }
    genericEditHandler: GenericDashboardEditHandler;

    async onGenericBackButtonPressed(): Promise<void> {
        this.goBack();
    }

    async onGenericSaveButtonPressed(): Promise<void> {
        await this.saveSchema(this.server);
    }


    multimediaHandler: MultimediaHandler;

    // this.mmUploader.onFileUploadClicked();

	private initMultimediaHandler(): void {
		this.multimediaHandler = new MultimediaHandler({
			idPlayer: null,
			idAvatar: null,
			generateHashNow: true,
			idMultimediaTag: MMconstant.tag.photo,
			mimeTypeFilter: [EUniqueFileType.CsvMimes],
			maxNumberOfFiles: 1,
			multimediaService: this.multiMediaSvc,
			clientCallback: {
				onClearMultimedia: () => nop,
				onMultimediaSelected: async (fileList: TMultimediaInstanceArray) => {
                    const selectedFile = fileList[0];
                    const [headers, fileFields]: [string[], IFileField[]] = await this.multiMediaSvc.getCsvDataPapaParse(
                        selectedFile
                    );
                    headers.forEach(header => {
                        const field = createEmptySchemaProperty();
                        field.prompt = _.startCase(header);
                        field.propertyName = header;
                        field.idUnity = EUnitTypeID.stringType;
                        this.schema.form.push(field);
                    });
				},
			},
		});
	}


    setGenericDashboardEditHandler() {
        const buttons: IGenericDashboardEditCustomButton[] = [];

        buttons.push({
            text: 'Adicionar campos de um arquivo',
            onClick: () => {
                this.multimediaHandler.getSlave()?.onFileUploadClicked();
            }
        });
        
        const parameter: IGenericDashboardEditHandlerParameter = {
            // title: this.editing ? this.translations.editTemplate.value : this.translations.addTemplate.value,
            clientCallback: this,
            moreOptions: {
                [ENsMoreOptions.CopyID]: true,
                [ENsMoreOptions.CheckDependencies]: true,
                [ENsMoreOptions.AddToPatch]: true,
            },
            nser: this.server,
            nsType: ENonSerializableObjectType.formSchemma,
            allowEditTitleInHeader: true,
            buttons,
        };
        this.genericEditHandler = new GenericDashboardEditHandler(parameter);
    }

    async ngOnInit() {
        await this.initSelectedSchema()
        this.initMultimediaHandler();
        this.setGenericDashboardEditHandler();
    }

    async initSelectedSchema() {
        let selectedSchema = this.formSvc.selectedSchema;

        if (!isValidRef(selectedSchema)) {
            const formId = this.route.snapshot.paramMap.get("idSchema");

            selectedSchema = formId && (await this.lookupService.getNS(formId));
        }

        if (!selectedSchema) {
            selectedSchema = createEmptySchemaPropertyServer(this.session.getCurrentSocialNetworkID());
        }

        this.server = selectedSchema;
        this.schema = this.server.schemma;
    }

    public validateType(properties: ISchemaPropertyClient) {}

    async saveSchema(properties: ISchemaPropertyClient) {
        const hasEmptyIdUnity =
            properties.schemma.form
                .map((property: SchemaProperty) => property.idUnity)
                .filter((idUnity: EUnitTypeID) => !idUnity).length > 0;

        if (hasEmptyIdUnity)
            return this.snackSvc.openDefaultMessage(EAppAlertTypes.Error)(
                "Tipo vazio"
            );

        const valid = this.schemaEditor.isValid();
        if (!valid) {
            return;
        }

        properties.schemma.name = properties.nName;

        // if (isValidRef(properties.schemma.name)) properties.nName = properties.schemma.name;

        if (isInvalid(this.dashboardSvc.getSaveFormRequestType()))
            return this.snackSvc.openDefaultMessage(EAppAlertTypes.Information)(
                "Não existe rota para salvar um form nessa parte do dashboard."
            );

        const success = await this.formSvc.saveSchema(
            this.dashboardSvc.getSaveFormRequestType(),
            properties
        );

        if (success) {
            this.snackSvc.open({
                message: this.translations.savedTemplate.value,
                duration: secToMS(3),
                type: EAppAlertTypes.Success,
            });

            if (this.windowRef) {
                this.windowRef.close(this.server);
                return;
            }

            this.goBack();
        } else {
            this.snackSvc.openError(this.translations.saveErrorTemplate.value);
        }
    }

    goBack() {
        if (this.windowRef) {
            this.windowRef.close();
        } else {
            this.location.back();
        }
    }
}
