import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { UniqueSelectionDispatcher } from "@angular/cdk/collections";
import { ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, Component, Input } from "@angular/core";
import { visibleComponentAnimations } from "./visible.animations";
import { VisibleComponentBase } from "./visible.base";

@Component({
    selector: '[visible-horizontal]',
    template: '<ng-content></ng-content>',
    // exportAs: "visible-horizontal",
    encapsulation: ViewEncapsulation.None,
    animations: [
        visibleComponentAnimations.contentExpansionHorizontal,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[@contentExpansionHorizontal]': '_getExpandedState()',
        '(@contentExpansionHorizontal.start)': 'onAnimationStart($event)',
        '(@contentExpansionHorizontal.done)': 'onAnimationDone($event)'
    }
})
export class VisibleHorizontalComponent extends VisibleComponentBase {

    @Input('visible-horizontal')
    set _visible(value: boolean) {
        this.visible = coerceBooleanProperty(value);
    }

    constructor(
        _changeDetectorRef: ChangeDetectorRef,
        readonly elementRef: ElementRef,
        protected _expansionDispatcher: UniqueSelectionDispatcher,
    ) {
        super(
            _changeDetectorRef,
            elementRef,
            _expansionDispatcher,
        );
    }
}
