import { MS } from "@colmeia/core/src/time/time-utl";
import { getClock, isValidRef } from "../tools/utility";

export interface IClientSemaphoreConfig {
    expirationLockTime?: number,
    numberOfTries?: number,
    retryInterval?: MS
    throwErrorIfNotLocked?: boolean,
};


export enum ELocalQueueName {
    recoverableInteraction = 'r',
    recoverableRequest = 'rr',
    batchableTracker = 'b',
    sendInteractionByAPI = 's',
    publishControl = 'p',
    semaphore = 'sm',
    androidBackButton = 'abb',
    spans = 'sp',
    batchDeliveryIncomingMessage = 'bd',
    readConfirmation = 'rc'
};

export type TObjectArray = Array<Object>


export interface ILocalQueuEntry {
    id: string;
    clockTick?: number
    data: Object;
    idQueue?: ELocalQueueName;
}

export type TIControlledRecordArray = Array<ILocalQueuEntry>;

export interface IQueueDatabase {
    [queue: string]: TIControlledRecordArray;
};

interface IQueueIndexes {
    [id: string]: ELocalQueueName;
}



export class CoreQueueService {

    private database: IQueueDatabase;

    constructor () {
        this.database = {};
    };

    private getQueue(idQueue: ELocalQueueName): TIControlledRecordArray {
        if (! this.database[idQueue]) {
            this.database[idQueue] = [];
        };
        return this.database[idQueue];
    }


    public removeFromQueue(idQueue: ELocalQueueName, id: string): boolean {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        const idx: number = queue.findIndex((x) => {return x.id === id});
        if (idx > -1) {
            queue.splice(idx, 1);
        };
        return idx > -1;
    };
  
    public enqueue(idQueue: ELocalQueueName, entry: ILocalQueuEntry): void {
        if (! entry.clockTick) {
            entry.clockTick = getClock();
        };
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        queue.push(entry);
    };

    public republish(idQueue: ELocalQueueName, entry: ILocalQueuEntry): void {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        queue.unshift(entry);
    };

    public getWithoutShift(idQueue: ELocalQueueName): ILocalQueuEntry {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        return queue[0];
    }

    public getCurrentID(idQueue: ELocalQueueName): string {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        return isValidRef(queue[0]) ? queue[0].id : null;
    };

    public isCurrentID(idQueue: ELocalQueueName, idEntry: string): boolean {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        return isValidRef(queue[0]) && queue[0].id === idEntry ;
    };

    public isCurrentIDOrInQueue(idQueue: ELocalQueueName, idEntry: string): boolean {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        return isValidRef(queue[0]) && queue[0].id === idEntry || queue.some((enq) => {return enq.id === idEntry});
    };


    
    public getLastEntry(idQueue: ELocalQueueName): ILocalQueuEntry {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        const q: ILocalQueuEntry = queue.pop();
        return q;
    };


    public getNextEntry(idQueue: ELocalQueueName): ILocalQueuEntry {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        const q: ILocalQueuEntry = queue.shift();
        return q;
    };

    public getAllEntries(idQueue: ELocalQueueName): TIControlledRecordArray {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        this.database[idQueue] = [];
        return queue
    }

    public getFirstXElements(idQueue: ELocalQueueName, elements: number): TIControlledRecordArray {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        const ret: TIControlledRecordArray = queue.splice(0, elements);
        return ret;
    }


    public getQueueSize(idQueue: ELocalQueueName): number {
        const queue: TIControlledRecordArray = this.getQueue(idQueue);
        return queue.length;
    }

    public isOnQueue(idQueue: ELocalQueueName, id: string): boolean {
        return this.getQueue(idQueue).some((x) => {return x.id === id});
    }


    public clearQueue(): void {
        this.database = {};
    }
}