import { Group } from '@colmeia/core/src/business/group';
import { secToMS } from '@colmeia/core/src/time/time-utl';
import { ExpirableEvent } from 'app/model/client-utility';
import { TGroupArray } from '@colmeia/core/src/core-constants/types';

export abstract class GroupNavigationEvent extends ExpirableEvent {
    private static lifetimeMS: number = secToMS(30);

    constructor(
        public readonly idGroup: string
    ) {
        super(GroupNavigationEvent.lifetimeMS)
    }
}

export abstract class SocialNetworkNavigationEvent extends ExpirableEvent {
    private static lifetimeMS: number = secToMS(60);

    constructor(
        public readonly originGroup: Group,
        public readonly targetGroup: Group
    ) {
        super(SocialNetworkNavigationEvent.lifetimeMS)
    }
}

export class GroupNavigationStart extends GroupNavigationEvent { }
export class GroupNavigationEnd extends GroupNavigationEvent { }


export class SocialNetworkNavigationStart extends SocialNetworkNavigationEvent { }
export class SocialNetworkNavigationEnd extends SocialNetworkNavigationEvent { }


export class GroupNavigator {
    private groups: TGroupArray;

    constructor(groupArray: TGroupArray) {
        this.groups = groupArray;

    };


}
