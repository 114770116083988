import { Component, OnInit } from '@angular/core';
import { IBPMMarketingListenerServer, TBpmMarketingNaturalNsTypes, TBpmMarketingNsTypes } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { MKTListenerBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { IBPMListenerHandler } from '../../bpm/bpm-listener/bpm-listener.handler';
import { MarketingBpmBase } from '../marketing-bpm-base.component';

@Component({
    selector: 'app-marketing-bpm-listener',
    templateUrl: './marketing-bpm-listener.component.html',
    styleUrls: ['./marketing-bpm-listener.component.scss']
})
export class MarketingBpmListenerComponent extends MarketingBpmBase<MKTListenerBpmGraphCreateHandler, IBPMMarketingListenerServer> implements OnInit {
    nsType = ENonSerializableObjectType.bpmMarketingListener as TBpmMarketingNaturalNsTypes;

    bpmListenerHandler: IBPMListenerHandler; 

    getNS(): IBPMMarketingListenerServer {
        return this.handler.getMarketingListener()
    }

    ngOnInit() {
        super.ngOnInit();
        this.initBPMListenerHandler();
    }

    private initBPMListenerHandler() {
        this.bpmListenerHandler = {
            listener: this.ns,
            hideSubscriber: true,
            clientCallback: {}
        }
    }
}
