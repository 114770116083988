<ng-container *ngIf="canShowMultimedia()">
  <div style="width: 100%;height: 100%;">
    <div class="media__container">
      <!-- Prev button -->
      <button type="button" (click)="prevMedia()" *ngIf="canShowArrows()">
        <mat-icon size="32">keyboard_arrow_left</mat-icon>
      </button>

      <!-- video audio or image -->
      <!-- [styles]="getMultimediaStyles()" -->
      <div class="multimedia-instance-container">
        <div class="item-actions">
          <ng-container *ngIf="isImage()">
            <ng-container *ngIf="(getUrl(getSelectedMedia()) | imgSecureDownload | async) as multimediaUrl">
              <a class="stroked-button" [href]="multimediaUrl" target="_blank">
                <mat-icon size="20">launch</mat-icon> Abrir
              </a>
              <a class="stroked-button" [href]="multimediaUrl" download>
                <mat-icon size="20">download</mat-icon> Download
              </a>
            </ng-container>
          </ng-container>
        </div>
        <app-multimedia-instance [mmInstance]="getSelectedMedia()" [styles]="getStyle()"></app-multimedia-instance>
      </div>

      <!-- Next button -->
      <button type="button" (click)="nextMedia()" *ngIf="canShowArrows()">
        <mat-icon size="32">keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <!-- Actual and total media -->
    <div class="media-gallery-label">
      {{ getCurrentSelectedMediaNumber() }} de {{ getTotalMediaNumber() }}
    </div>
  </div>
</ng-container>
