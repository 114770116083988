import { Injectable } from '@angular/core';
import { TExtendedParticipantArray, TExtendedParticipant, TInteractionSharedTo, TArrayID, TExtendedIdentifierArray } from '@colmeia/core/src/core-constants/types';
import { IShareAsFeature } from '@colmeia/core/src/comm-interfaces/interaction-interfaces';
import { Option, none, some } from 'ts-option';
import { ICheckIfSharedRequest, IShareSendRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { ICheckIfSharedResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import { SessionService } from 'app/services/session.service';
import { ServerCommunicationService } from 'app/services/server-communication.service';
import { RequestBuilderServices } from 'app/services/request-builder.services';
import { IInfraParameters } from 'app/model/component/client-infra-comm';
import { TShareMessageArray, NShareSignal } from 'app/model/signal/share-signal';

@Injectable({
    providedIn: 'root'
})
export class ShareService {

    constructor(
        private sessionSvc: SessionService,
        private serverSVC: ServerCommunicationService,
        private requestBuilderSvc: RequestBuilderServices,
    ) { }

    async isShared(
        interactionIds: TArrayID,
        participants: TExtendedIdentifierArray
    ): Promise<Option<TInteractionSharedTo>> {
        const infra: IInfraParameters = this.requestBuilderSvc.getContextNoCallBackNoSpinnningParameters();
        const request: ICheckIfSharedRequest = {
            ...this.requestBuilderSvc.secureBasicRequest(apiRequestType.share.currentState),
            interactionIds,
            participants
        };
        const clientResponse  = await this.serverSVC.managedRequest(
            infra,
            request
        );

        return (clientResponse.executionOK)
            ? some((<ICheckIfSharedResponse>clientResponse.response).sharedState)
            : none;
    }

    async sendShare(
        sharedItems: TShareMessageArray,
        shareWith: TExtendedParticipantArray,
    ): Promise<boolean> {
        const infra: IInfraParameters = this.requestBuilderSvc.getContextNoCallBackSpinnningParameters();
        const request: IShareSendRequest = {
            ...this.requestBuilderSvc.secureBasicRequest(apiRequestType.share.send),
            whatBeingShared: sharedItems.map((shr: NShareSignal.IShareMessage) => ({
                idGroup: shr.currentGroupID,
                idInteraction: shr.resource.getPrimaryID(),
                participant: this.sessionSvc.getSelectedParticipant().toJSON(),
            })),
            to: shareWith.map((par: TExtendedParticipant) => ({
                primaryID: par.getPrimaryID(),
                idObjectType: par.getObjectTypeID()
            }))
        };

        const clientResponse = await this.serverSVC.managedRequest(infra, request);
        return clientResponse.executionOK
    }
}
