

import { IEditorVariable, removeBracket } from "@colmeia/core/src/shared-business-rules/metadata/metadata-utils";
import { isValidString } from "@colmeia/core/src/tools/utility";
import Quill from "quill";
import { IEditorVariableClient } from "../../var-editor/var-editor.component";

const Embed = Quill.import("blots/embed");

export interface IVariableBlotData {
  id: string,
  value: string,
  canonical: string,
  isSafe: '0' | '1',
  isTag: '0' | '1',
  tooltip: string,
}

export class VariableBlot extends Embed {
  hoverHandler;

  static blotName: string = "variable";
  static tagName: string = "span";
  static className: string = "quill-cm-variable";

  static is(node: HTMLElement): boolean {
    const isElement: boolean = node.nodeType === Node.ELEMENT_NODE;
    const isSpan = node.nodeName === 'SPAN';
    const hasBlotClass = isElement && node.classList.contains(VariableBlot.className);

    return isSpan && hasBlotClass;
  }

  constructor(scroll, node) {
    super(scroll, node);
    this.clickHandler = null;
    this.hoverHandler = null;
    this.mounted = false;
  }

  private static createDenotationTagElement(matIcon: string): HTMLElement {
    const tagNodeContainer = document.createElement('span');

    tagNodeContainer.className = 'mat-icon material-icons type';
    tagNodeContainer.textContent = matIcon;

    return tagNodeContainer;
  }

  static editorVariableToDomStringMap(variable: IEditorVariableClient): IVariableBlotData {
    return {
      id: variable.idProperty,
      isSafe: variable.isSafe ? '1' : '0',
      isTag: variable.isTagVariable ? '1' : '0',
      value: removeBracket(variable.variable),
      canonical: variable.canonical?.nName || '',
      tooltip: isValidString(variable.tooltip) ? variable.tooltip : '',
    }
  }

  static createByVariable(variable: IEditorVariableClient): HTMLSpanElement {
    const blotData = VariableBlot.editorVariableToDomStringMap(variable);

    return VariableBlot.create(blotData);
  }

  static create(data: IVariableBlotData): HTMLSpanElement {
    const node: HTMLElement = super.create();
    
    const content = document.createElement('span');
    content.className = 'quill-variable'

    content.classList.add("global-variable", "mat-chip", "mat-standard-chip");

    if(data.isSafe === '1') {
      content.classList.add("is-safe")
    }

    if(data.isTag === '1') {
      const tagElement = VariableBlot.createDenotationTagElement('local_offer');
      content.appendChild(tagElement);
    }

    if(isValidString(data.canonical)) {
      const canonElement = VariableBlot.createDenotationTagElement('spellcheck');
      content.appendChild(canonElement);
    }

    if(isValidString(data.tooltip)) {
      const tooltipNodeContainer = document.createElement('span');

      tooltipNodeContainer.className = 'legacy-tooltip';
      tooltipNodeContainer.textContent = data.tooltip;

      content.appendChild(tooltipNodeContainer)
      console.log({ dotooltip: data.tooltip })
      // content.innerHTML += `<div class="legacy-tooltip">${data.tooltip}</div>`
    }

    content.innerHTML += data.value;
    node.innerHTML += content.outerHTML
    
    return VariableBlot.setDataValues(node, data);
  }

  static setDataValues(element, data) {
    const domNode = element;
    Object.keys(data).forEach(key => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  static value(domNode) {
    return domNode.dataset;
  }

  attach() {
    super.attach();
  
    if (!this.mounted) {
      this.mounted = true;
      this.clickHandler = this.getClickHandler();
      this.hoverHandler = this.getHoverHandler();

      this.domNode.addEventListener("click", this.clickHandler, false);
      this.domNode.addEventListener("mouseenter", this.hoverHandler, false);
    }
  }

  detach() {
    super.detach();
    this.mounted = false;
    if (this.clickHandler) {
      this.domNode.removeEventListener("click", this.clickHandler);
      this.clickHandler = null;
    }
  }

  getClickHandler() {
    return e => {
      const event = this.buildEvent("variable-clicked", e);
      window.dispatchEvent(event);
      e.preventDefault();
    };
  }

  getHoverHandler() {
    return e => {
      const event = this.buildEvent('variable-hovered', e);
      window.dispatchEvent(event);
      e.preventDefault();
    }
  }

  buildEvent(name: string, e: Event) {
      const event = new Event(name, {
        bubbles: true,
        cancelable: true
      });
      (event as any).value = Object.assign({}, this.domNode.dataset);
      (event as any).event = e;
      return event;
  }
}

Quill.register(VariableBlot);