import { NSGenericHost } from '@colmeia/core/src/shared-business-rules/graph-transaction/host/ns/ns.host';
import { ENonSerializableObjectType, INonSerializable } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { ENextGenBotElementType, INextGenBotServer } from '../../../bot/bot-model';
import { ESubscribedTo, IBPMBaseNodeServer } from '../../../BPM/common/common-bpm';
import { IBasicBPMMarketingModelClient, IBPMMarketingAcknowledgeServer, TBpmMarketingNserServer, TBpmMarketingNsTypes } from '../../../BPM/marketing/bpm-marketing.model';
import { IGraphConnectionData, TIGraphConnectionDataArray } from '../../../graph/essential/graph-types';
import { TGraphActionContainer, TReferencedObject } from '../../toolbar/config-toolbar.types';
import { INodeDeletedConnection } from '../ns/ns.host.types';


export class MktHostNode extends NSGenericHost {
    constructor(mktNser: INonSerializable) {
        super(mktNser);
    }

    static create(mktNser: INonSerializable): MktHostNode {
        return new MktHostNode(mktNser)
    }

    isNodeCreatable(): boolean {
        const nsType = this.getNonSerializable().nsType
        return nsType == ENonSerializableObjectType.bpmMarketingRoot
            || nsType == ENonSerializableObjectType.bpmMarketingAction
            || nsType == ENonSerializableObjectType.bpmMarketingPause
            || nsType == ENonSerializableObjectType.bpmMarketingAcknowledge
            || nsType == ENonSerializableObjectType.bpmMarketingListener
            || nsType == ENonSerializableObjectType.bpmMarketingEnd
            // || nsType == ENonSerializableObjectType.contentGenerator
            || nsType == ENonSerializableObjectType.bot
    }

    isNeighborDrawable(neighborType: TBpmMarketingNsTypes): boolean {
        return neighborType == ENonSerializableObjectType.contentGenerator
        // || neighborType == ENextGenBotElementType.botMenuItem
    }

    getNonSerializable(): TBpmMarketingNserServer | INextGenBotServer {
        return <TBpmMarketingNserServer>super.getNonSerializable()
    }

    getHostedType(): TBpmMarketingNsTypes {
        return <TBpmMarketingNsTypes>super.getNonSerializable().nsType
    }

    isRoot(): boolean {
        return this.getHostedType() == ENonSerializableObjectType.bpmMarketingRoot
    }

    isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return true
    }

    isExternal() {
        const hostedType = this.getHostedType();
        return hostedType === ENonSerializableObjectType.contentGenerator
            || hostedType === ENextGenBotElementType.botMenuItem
    }

    deleteConnectionTo(targetToBeDeleted: MktHostNode, connectionType: TBpmMarketingNsTypes): INodeDeletedConnection {
        const isContentGenerator: boolean = targetToBeDeleted.getHostedType() == ENonSerializableObjectType.contentGenerator;
        if (isContentGenerator) {
            const nser: IBPMMarketingAcknowledgeServer = (<IBPMMarketingAcknowledgeServer>this.getNonSerializable());

            nser.listenTo = {
                idSubscribedTo: undefined,
                type: undefined
            }
            return { deletedConnection: true }
        }

        return { deletedConnection: false }
    }

    public mustUpdateConnections(connectionType: ESubscribedTo): boolean {
        switch (connectionType) {
            case ESubscribedTo.contentGenerator:
                return true;
            case ESubscribedTo.botElement:
                return true;
            default:
                return false;
        };
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        const mktNode: TBpmMarketingNserServer = (super.getNonSerializable() as TBpmMarketingNserServer)

        return [{
            targetHostId: (<IBasicBPMMarketingModelClient>mktNode).listenTo?.idSubscribedTo,
            connectionType: (<IBasicBPMMarketingModelClient>mktNode).listenTo?.type,
            subElementId: undefined
        }]
    }

    public getActionContainer(): TGraphActionContainer {
        return (super.getNonSerializable() as unknown as IBPMBaseNodeServer)
    }
}
