import { IMediaViewer } from "../hardware-interfaces";
import { Injectable } from "@angular/core";
import { TMultimediaInstanceArray } from "@colmeia/core/src/multi-media/barrel-multimedia";
import {ColmeiaDialogService} from "../../dialog/dialog.service";
import { MultimediaPlayerComponent } from "../../../components/dialogs/multimedia-player/multimedia-player.component";
import { IMultimediaPlayerComponentHandler } from "app/handlers/multimedia-player-component.handler";

@Injectable({
    providedIn: 'root'
})
export class BrowserMediaViewer implements IMediaViewer {

    constructor(
        private dialogSvc: ColmeiaDialogService
    ) { }

    view(mms: TMultimediaInstanceArray, startingIndex: number = 0, title?: string) {
        this.dialogSvc.open<MultimediaPlayerComponent, IMultimediaPlayerComponentHandler>({
            title,
            componentRef: MultimediaPlayerComponent,
            panelClass: "multimedia-viewer-dialog-panel",
            dataToComponent: {
                data: {
                    getSelectedMMIdx: () => startingIndex,
                    getMultimediaList: () => mms
                }
            }
        })
    }
}
