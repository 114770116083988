import { IFileOpenerAbstraction } from "../hardware-interfaces";
import { Injectable } from "@angular/core";
import { MultimediaInstance } from "@colmeia/core/src/multi-media/barrel-multimedia";
import { Window } from "./cordova-plugins-definitions";
import { HttpClient } from "@angular/common/http";
import { EFileTypes } from "@colmeia/core/src/multi-media/file-interfaces";
import { isValidString } from "@colmeia/core/src/tools/utility";
import { SharedStorage } from "../shared/SharedStorage";
import {MultimediaService} from "../../multimedia.service";

declare var window: Window;

export type TMMInstanceFileHash = {
    [idMedia: string]: string;
}

@Injectable({
    providedIn: 'root'
})
export class CordovaFileOpener implements IFileOpenerAbstraction {

    private multimediaSvc: MultimediaService;
    private files: TMMInstanceFileHash = {};

    private static storageMediaKey: string = 'mediaDownloadCache';

    constructor(
        private http: HttpClient,
        private storage: SharedStorage,
    ) {}

    setMultimediaService(multimediaSvc: MultimediaService) {
        this.multimediaSvc = multimediaSvc;
    }

    getFileURIFromCache(idMedia: string): string {
        const hasHash = this.storage.hasItem(CordovaFileOpener.storageMediaKey);

        if (!hasHash) {
            return;
        }

        const hash: TMMInstanceFileHash = this.storage.getItem<TMMInstanceFileHash>(CordovaFileOpener.storageMediaKey);

        return hash[idMedia];
    }

    setFileURIOnCache(idMedia: string, fileURI: string): void {
        const hasHash = this.storage.hasItem(CordovaFileOpener.storageMediaKey);
        const hash: TMMInstanceFileHash = hasHash ? this.storage.getItem<TMMInstanceFileHash>(CordovaFileOpener.storageMediaKey) : {};

        hash[idMedia] = fileURI;
        this.storage.putItem(CordovaFileOpener.storageMediaKey, hash);
    }

    clearCache() {
        if (this.storage.hasItem(CordovaFileOpener.storageMediaKey)) {
            const hash: TMMInstanceFileHash = this.storage.getItem<TMMInstanceFileHash>(CordovaFileOpener.storageMediaKey);

            Object.keys(hash).forEach(idMedia => {
                window.resolveLocalFileSystemURL(hash[idMedia], fileEntry => {
                    fileEntry.remove(() => delete hash[idMedia]);
                });
            });

            this.storage.clearItem(CordovaFileOpener.storageMediaKey);
        }
    }

    open(mmInstance: MultimediaInstance) {
        const fileURI = this.getFileURIFromCache(mmInstance.getIdMedia());
        if (isValidString(fileURI)) {
            this.openFile(fileURI, mmInstance.getMimeType());
            return;
        }

        this.http.get(this.getMultimediaUrl(mmInstance), {responseType: EFileTypes.Blob}).subscribe(blob => {
            window.requestFileSystem(window.TEMPORARY, blob.size, fs => {
                fs.root.getFile(mmInstance.getFileName(), {create: true, exclusive: false}, fileEntry => {
                    fileEntry.createWriter(fileWriter => {
                        fileWriter.onwriteend = () => {
                            this.setFileURIOnCache(mmInstance.getIdMedia(), fileEntry.nativeURL);
                            this.openFile(fileEntry.nativeURL, mmInstance.getMimeType());
                        }
                        fileWriter.write(blob);
                    })
                })
            })
        });
    }

    private openFile(fileURI: string, mimeType: string) {
        window.cordova.plugins.fileOpener2.open(fileURI, mimeType, {
            error: (e) => console.log('error', e),
            success: () => console.log('success')
        });
    }

    public getMultimediaUrl(mmInstance: MultimediaInstance): string {
        return this.multimediaSvc.getMultimediaBestUrl(mmInstance);
    }

}
