import { I360Media, T360MediaArray } from "../../../../core-constants/bot";
import { IBotActionAsset } from "../../../bot/bot-action-model";
import { TBasicElementArray } from "../../../graph/essential/basic-element";
import { GraphElement } from "../../../graph/essential/graph-element";
import { GraphRulesProcessor } from "../../../graph/essential/graph-rules-processor";
import { IGraphConnectionData, TGraphPropertyData, TIGraphConnectionDataArray } from "../../../graph/essential/graph-types";
import { HostObject, IHostObjectInterface } from "../../../graph/essential/host-object";
import { GraphPredicate, TGraphPredicateArray } from "../../../graph/essential/predicate";
import { INonSerializable } from "../../../non-serializable-id/non-serializable-id-interfaces";
import { TBPMConnectionTypes, TBPMElementTypes, TGraphActionContainer, TReferencedObject, TReferencedObjectArray } from "../../toolbar/config-toolbar.types";
import { IAddedConnection, IHostNodeDeletionParams, IHostNodeDeletionResult, INodeDeletedConnection } from "./ns.host.types";


export abstract class NSGenericHost extends HostObject {




    constructor(ns: INonSerializable) {
        super(ns);
    }

    public abstract getHostedType(): TReferencedObject
    public abstract isRoot(): boolean

    isHostedOfType(another: TReferencedObject) {
        return this.getHostedType() === another
    }

    thereIsMoreChildrenNodesToCreate(isExternal?: boolean): boolean {
        return false
    }

    public mustUpdateConnections(connectionType: TReferencedObject): boolean {
        return false
    }

    public is(ns: INonSerializable): boolean {
        return ns?.idNS === this.getHostedID()

    }

    public iss(id: string): boolean {
        return id === this.getHostedID();
    }

    public hasAdditionalToBeRemoved(): boolean {
        return this.isRoot() //&& this.getHostedObject().isExternal() // checa sem elemtento externo;
    }

    isNodeCreatable(): boolean {
        throw new Error("Method not implemented.");
    }

    isNeighborDrawable(neighborType: TBPMElementTypes, neighborNSId: string, iamExternal: boolean): boolean {
        return false
        // throw new Error("Method not implemented.");
    }

    getEdgeTypeToConnect(neighborType: TBPMElementTypes): TBPMConnectionTypes {
        throw new Error("Method not implemented.");
    }

    public getConnectionTargetData(): TIGraphConnectionDataArray {
        return [{
            targetHostId: undefined,
            connectionType: undefined,
            subElementId: undefined
        }]
    }

    getAllConnectionsAndNodesToRemove(
        node: GraphElement, connectionType: TReferencedObject,
        ruleProcessor: GraphRulesProcessor
    ): TBasicElementArray {
        return []
    }

    getConnectionsToRemove(selfNode: GraphElement, ruleProcessor: GraphRulesProcessor): TGraphPredicateArray {
        const allConnectionsToOthers: TGraphPredicateArray = ruleProcessor.getConnectionsToOthers(selfNode.getGraphElementID());

        const allDrawableConnections: TGraphPredicateArray = allConnectionsToOthers.filter((pred) => {
            const target = ruleProcessor.getElementById(pred.getToElementId())
            const isPredicateDrawable = this.isPredicateDrawable(target.getHostedType(), pred.getBusinessPredicate())

            return pred.hasValidBusinessPredicate() && isPredicateDrawable
        });

        return allDrawableConnections;
    }

    /**
     * canRemoveNode armazena regras especificas de host de delecao de um node, 
     * foi inicializado como true aqui na classe base para nao quebrar codigo ja existente
     * @param data 
     * @returns 
     */
    canRemoveNode(data: IHostNodeDeletionParams): IHostNodeDeletionResult {
        return {
            allowed: true,
            explanationIfNotAllowed: ''
        }
    }

    public addConnectionTo(target: NSGenericHost, predicateType: TReferencedObject): IAddedConnection {
        throw new Error("Method not implemented.");
    }

    public deleteConnectionTo(target: NSGenericHost, connectionType: TReferencedObject, subElementId?: string): INodeDeletedConnection {
        throw new Error("Method not implemented.");
    }
    public getActionAvailableByConnectingManuallyToOtherNode(): TReferencedObject {
        throw new Error("Method not implemented.");
    }

    public isActionAvailableByConnectingManuallyToOtherNode(): boolean {
        throw new Error("Method not implemented.");
    }

    public getPredicateTypesFromState(): TReferencedObjectArray {
        throw new Error("Method not implemented.");
    }

    public isParenthoodPredicateDrawable(targetType: TReferencedObject): boolean {
        throw new Error("Method not implemented.");
    }
    public isActionPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        throw new Error("Method not implemented.");
    }

    public getActionContainer(): TGraphActionContainer {
        throw new Error("Method not implemented.");
    }

    public canConnectTo(toType: TReferencedObject): boolean {
        throw new Error("Method not implemented.");
    }

    public setAction(newMenuItemActionAsset: IBotActionAsset): void {
        throw new Error("Method not implemented.");
    }

    public addElementAction(type: TReferencedObject, hostedId: string): void {
        throw new Error("Method not implemented.");
    }

    public isConnectionDrawableForTarget(targetType: TReferencedObject, action: TReferencedObject) {
        return
    }

    public isPredicateDrawable(targetType: TReferencedObject, action: TReferencedObject): boolean {
        return this.isParenthoodPredicateDrawable(targetType)
            || this.isActionPredicateDrawable(targetType, action)
    }

    public getHostedMedia(): I360Media {
        throw new Error("Method not implemented.");
    }
    public getHostedValue(): TGraphPropertyData {
        throw new Error("Method not implemented.");
    }

    public getNonSerializable(): INonSerializable {
        return super.getHostedObject();
    }

    public getHostedID(): string {
        return this.getNonSerializable().idNS
    }

    public generateId(): void {
        return;
    }

    public validate(): boolean {
        return;
    }

    public onDragIntoMe(host: IHostObjectInterface): void { }
    public onDragged(host: IHostObjectInterface): void { }
    public isDraggableIntoMe(nodeTypeToDrag: TReferencedObject, edgesToOthersCount: number): boolean {
        throw new Error("Method not implemented.");
    }

    public onRemove(hostObject: IHostObjectInterface): void {
        throw new Error("Method not implemented.");
    }

    public getMedias(): T360MediaArray {
        return this.getNonSerializable().medias;
    }


    public getHostedName(): string {
        return this.getNonSerializable().nName;
    }


    public toJSON(): Object {
        throw new Error("Method not implemented.");
    }

    public rehydrate(json: Object): void {
        throw new Error("Method not implemented.");
    }

    public getSequencialPosition() {
        return null
    }

    handleRemoveSubTree(graphElement: GraphElement): INonSerializable[] {
        return [];
    }

    public isEditable(graphElement: GraphElement) {
        return true;
    }

    public allowSetInnerHTML(): boolean {
        return true;
    }
}