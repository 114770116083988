import { Interaction, TInteractionArray } from "@colmeia/core/src/interaction/interaction";


export interface IStatusIconsClient {
    getShares(): Interaction[];
    isSharedRoot(): boolean;
    isMessageToMe(): boolean;
    isSuicidal(): boolean;
    canShowFinishedTask(): boolean;
    isEditedMessage(): boolean;
    canShowAvatarName(): boolean;
    getInteraction(): Interaction;
    getMessageDate(date: Date): string;
}

export interface IStatusIconsMessageHandlerClient {
    isPastDueDate(): boolean;
    isFinishedTask(): boolean;
    isFutureDueDate(): boolean;
    isDueDate(): boolean;
    isSuicidal(): boolean;
    isTimeBounded(): boolean;
    isPersonalResponseToMe(): boolean;
}

export interface IStatusIconsParameter {
    clientInstance: IStatusIconsClient,
    messageHandlerInstance: IStatusIconsMessageHandlerClient;
}

export class StatusIconsHandler {
    constructor(private parameters: IStatusIconsParameter) { }

    getShares(): TInteractionArray {
        return this.parameters.clientInstance.getShares();
    }

    isShared(): boolean {
        return this.parameters.clientInstance.isSharedRoot();
    }

    getInteraction(): Interaction {
        return this.parameters.clientInstance.getInteraction();
    }

    getMessageDate(date: Date): string {
        return this.parameters.clientInstance.getMessageDate(date)
    }

    canApplyMarginToMoreOptions(): boolean {
        return this.parameters.clientInstance.canShowAvatarName()
            || this.isAnyStatusAvailable();
    }

    canShowExpired(): boolean {
        return this.parameters.messageHandlerInstance.isDueDate()
            && this.parameters.messageHandlerInstance.isPastDueDate()
            && !this.canShowFinishedTask();
    }

    canShowDueDate(): boolean {
        return this.parameters.messageHandlerInstance.isDueDate()
            && this.parameters.messageHandlerInstance.isFutureDueDate()
            && !this.canShowFinishedTask();
    }

    canShowFinishedTask(): boolean {
        return this.parameters.messageHandlerInstance.isDueDate()
            && this.parameters.messageHandlerInstance.isFinishedTask()
            && !this.parameters.messageHandlerInstance.isSuicidal();
    }

    getDueDate(): number {
        return this.parameters.messageHandlerInstance.isTimeBounded()
            && this.parameters.clientInstance.getInteraction().getWhenOccurs();
    }

    isEditedMessage(): boolean {
        return this.parameters.clientInstance.isEditedMessage();
    }

    hasAnnotation(): boolean {
        return this.parameters.clientInstance.getInteraction().hasAnnotation();
    }

    isAnyStatusAvailable(): boolean {
        try {
            return this.isSuicidal() || this.isEditedMessage() || this.isMessageToMe() || this.isShared() || this.hasAnnotation();
        } catch(e) {
            return false;
        }
    }

    isMessageToMe(): boolean {
        return this.parameters.messageHandlerInstance.isPersonalResponseToMe();
    }

    isSuicidal(): boolean {
        return this.parameters.messageHandlerInstance.isSuicidal();
    }

    isDueDate(): boolean {
        return this.parameters.messageHandlerInstance.isDueDate()
    }

    isFinishedTask(): boolean {
        return this.parameters.messageHandlerInstance.isFinishedTask()
    }

    isPastDueDate(): boolean {
        return this.parameters.messageHandlerInstance.isPastDueDate()
    }

    isOnlyYouCanSee(): boolean {
        return false;
    }

    isMultiplePeopleSeen(): boolean {
        return false;
    }
}
