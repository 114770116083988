import {Participant} from '../../business/participant';
import {TGlobalUID, constant} from '../../business/constant';
import { WalletOperationInteraction } from './wallet-operation';
import { UberCache } from '../../persistency/uber-cache';
import { EWalletOperation, IWalletChargeInteractionJSON } from '../../comm-interfaces/finance-interfaces';


export class WalletChargeInteraction extends WalletOperationInteraction {
    
    private constructor(primaryID: TGlobalUID, participant: Participant) {
        super (primaryID, participant, EWalletOperation.charge);
    };

    public rehydrate(json: IWalletChargeInteractionJSON): void {
        super.rehydrate(json);
    };

    public toJSON(): IWalletChargeInteractionJSON {
        return {
            ...super.toJSON(),
        };
    };

    public static staticFactory(idCharge: TGlobalUID): WalletOperationInteraction {
        return <WalletChargeInteraction>UberCache.uberFactory(idCharge, constant.objectType.interaction, true);
    };

    public static factoryMessage(json: IWalletChargeInteractionJSON, participant: Participant = null): WalletChargeInteraction {
        let charge: WalletChargeInteraction = <WalletChargeInteraction>WalletOperationInteraction.searchCachedInteraction(json.primaryID);
        if (charge == null)
           charge = new WalletChargeInteraction(json.primaryID, participant? participant: Participant.staticFactory(json.participant.primaryID));
        charge.rehydrate(json);
        return charge;
    };

};