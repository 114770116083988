import { TSerializableHeaderQuery, TSerializableHeaderResponse } from "@colmeia/core/src/serializable/header";
import { TGlobalUID } from "@colmeia/core/src/core-constants/types";
import { IUniversalJSON, IUniversalJSONArray } from "@colmeia/core/src/comm-interfaces/business-interfaces";
import { UberCache } from "@colmeia/core/src/persistency/uber-cache";
import { Serializable } from "@colmeia/core/src/business/serializable";
import { ISerializableHeaderRequest } from "@colmeia/core/src/request-interfaces/request-interfaces";
import { apiRequestType } from "@colmeia/core/src/request-interfaces/message-types";
import { ISerializableHeaderResponse } from "@colmeia/core/src/request-interfaces/response-interfaces";
import {RequestBuilderServices} from "./request-builder.services";
import {ServerCommunicationService} from "./server-communication.service";
import {IInfraParameters} from "../model/client-infra-comm";

export class SerializableHeaders {

    constructor() {}

    private static requestBuilder: RequestBuilderServices;
    private static serverAPI: ServerCommunicationService;

    private static serializableHeaders: TSerializableHeaderResponse = {};

    public static appendSerializableHeaders(response: TSerializableHeaderResponse): void {
        Object.keys(response)
            .forEach(
                k => {
                    SerializableHeaders.serializableHeaders[k] = response[k]
                }
            )
    }

    public static filterSerializableHeadersNeeded(query: TSerializableHeaderQuery): TSerializableHeaderQuery {
        return query.filter(
            q => ! (q.serializableID in SerializableHeaders.serializableHeaders)
        );
    }

    public static all(): TSerializableHeaderResponse {
        return SerializableHeaders.serializableHeaders;
    }

    public static has(serializableID: TGlobalUID): boolean {
        return serializableID in SerializableHeaders.serializableHeaders;
    }

    public static get(serializableID: TGlobalUID): IUniversalJSON {
        return SerializableHeaders.serializableHeaders[serializableID] || null;
    }

    private static hasOnUberChache(primaryID: TGlobalUID): boolean {
        try {
            return UberCache.testCache(primaryID);
        } catch (e) {
            return false;
        }
    }

    private static fetchFromUberCache(primaryID: TGlobalUID): IUniversalJSON {
        const serializable: Serializable = UberCache.unsafeUberFactory(primaryID);
        return serializable.toJSON();
    }

    public static setRequestBuilder(rbs: RequestBuilderServices): void {
        SerializableHeaders.requestBuilder = rbs;
    }

    public static setServerApi(api: ServerCommunicationService): void {
        SerializableHeaders.serverAPI = api;
    }

    private static buildQuery(toQuery: TSerializableHeaderQuery): [TSerializableHeaderQuery, TSerializableHeaderQuery] {
        const mustQuery: TSerializableHeaderQuery = [];
        const fetchLocal: TSerializableHeaderQuery = [];

        for(const header of toQuery) {
            if(header.ignoreCache) {
                mustQuery.push(header);
            } else {
                if(SerializableHeaders.hasOnUberChache(header.serializableID)) {
                    fetchLocal.push(header);
                } else {
                    mustQuery.push(header);
                }
            }
        }

        return [mustQuery, fetchLocal];
    }

    private static hashMapFromPrimaryID(serializables: IUniversalJSONArray): TSerializableHeaderResponse {
        const result: TSerializableHeaderResponse = {};

        return serializables.reduce((acc: TSerializableHeaderResponse, item: IUniversalJSON, index: number) => {
            acc[item.primaryID] = item;
            return acc;
        }, result);
    }

    public static async fetch(toQuery: TSerializableHeaderQuery): Promise<TSerializableHeaderResponse> {

        const [mustBeQueried, fetchFromLocal] = SerializableHeaders.buildQuery(toQuery);
        const localResult = SerializableHeaders.hashMapFromPrimaryID(
            fetchFromLocal.map(query => SerializableHeaders.fetchFromUberCache(query.serializableID))
        );

        if(mustBeQueried.length) {

            const infra: IInfraParameters = SerializableHeaders.requestBuilder.getContextNoCallBackNoSpinnningParameters();
            const req: ISerializableHeaderRequest = {
                ... SerializableHeaders.requestBuilder.secureBasicRequest(apiRequestType.serializable.header),
                query: mustBeQueried
            };

            const res = await SerializableHeaders.serverAPI.managedRequest(infra, req);

            if (res.executionOK) {
                const response: ISerializableHeaderResponse = <ISerializableHeaderResponse>res.response;

                return {
                    ...response.serializables,
                    ...localResult
                }

            }

        } else {
            return localResult;
        }
    }
}
