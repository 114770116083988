import {typedScreenOptions} from "../../../tools/utility";
import {EAllSerializable, getTranslationsFields} from "../all-serializables";

export const shareViewScreenOptions = typedScreenOptions({
    idSerializable: EAllSerializable.Share, // Serializable original: chatMessage.share
    fields: {
        clearItens: 1, // Limpar
        addedItens: 2, // Item(s) adicionado(s)
        advancedShare: 3, // Avançado
        basicShare: 4, // Básico
        whereIsGoingToBeShared: 5, // Onde vai ser compartilhado?
        share: 6, // Compartilhar
        alreadySharedWith: 7, // Já compartilhado com
        from: 8, // De
        item: 9, // Item(s) adicionado(s)
        shares: 10 // Compartilhamentos
    }
} as const);

export const shareTranslations = getTranslationsFields(shareViewScreenOptions);