import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {SignalListenerService} from "../../../services/signal/signal-listener";
import { GroupOrderSelectorShortcutHandler, IGroupOrderSelectorShortcutHandlerParameter, groupOrderSelectorModeDB, defaultGroupOrderSelectorModeConfig, EGroupOrderSelectorModeOption } from 'app/handlers/group-order-selector-shortcut.handler';
import { GroupShortcutHandlerService } from 'app/services/group-shortcut-handler.service';
import { EnumPickerHandler, EEnumPickerMode, IEnumPickerHandlerParameter, IEnumPickerClientCallback } from 'app/components/foundation/enum-picker/enum-picker.handler';
import { typedClone, isValidFunction, values } from '@colmeia/core/src/tools/utility';
import { EDelivery360Action } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';
import { activeAgentTranslations } from '@colmeia/core/src/shared-business-rules/const-text/views/active-agent';
import { EGroupOrderSelectorMode } from '@colmeia/core/src/shared-business-rules/const-text/views/group-order-selector-shortcut';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
    selector: 'app-group-order-selector-shortcut',
    templateUrl: './group-order-selector-shortcut.component.html',
    styleUrls: ['./group-order-selector-shortcut.component.scss'],
})
export class GroupOrderSelectorShortcutComponent implements OnInit, OnDestroy {
    // public hexagonHandler: HandlerHexagonon = HandlerHexagonon.newEmpty(EHexagonSizes.smd);
    private _handler: GroupOrderSelectorShortcutHandler;
    @Input()
    public set handler (val: GroupOrderSelectorShortcutHandler) { this._handler = val; this.init(); }
    public get handler(): GroupOrderSelectorShortcutHandler { return this._handler; }
    public get parameters(): IGroupOrderSelectorShortcutHandlerParameter { return this.handler.getComponentParameter() }
    public enumPickerHandler: EnumPickerHandler<EGroupOrderSelectorMode>;


    constructor (
        private listener: SignalListenerService,
        private shortcutSVC: GroupShortcutHandlerService,
    ) {}


    public ngOnInit(): void {
        this.handler = this.shortcutSVC.getGroupOrderShortCurtHandler();
    }

    public init(): void {
        this.handler.setSlave(this);
        this.initEnumPickerHandler();
        this.resetConfig();
    };

    public onSelectOrder(value: EGroupOrderSelectorMode): void {
        this.parameters.selected = value;
        this.resetConfig();
        if (isValidFunction(this.parameters.clientCallback?.onGroupOrderSelectorChange))
            this.parameters.clientCallback?.onGroupOrderSelectorChange()
        ;
    }

    public $configOptions = values(EGroupOrderSelectorModeOption);

    public get config() {
        return this.parameters.config;
    }

    public showConfigOption(option: EGroupOrderSelectorModeOption): boolean {
        return groupOrderSelectorModeDB[this.parameters.selected][option];
    }

    public resetConfig(): void {
        this.parameters.config = typedClone(defaultGroupOrderSelectorModeConfig[this.parameters.selected]);
    }

    public getOptionIcon(option: EGroupOrderSelectorModeOption) {
        switch (option) {
            case EGroupOrderSelectorModeOption.recent: return 'filter_list';
            case EGroupOrderSelectorModeOption.agent: return this.parameters.config[option] ? 'support_agent' : 'groups';
        }
    }

    public applyRotateOnIcon(option: EGroupOrderSelectorModeOption): boolean {
        return option === EGroupOrderSelectorModeOption.recent && !this.config.recent;
    }

    public initEnumPickerHandler(): void {
        this.enumPickerHandler = new EnumPickerHandler({
            client: {
                onSingleEnumSelection: value => this.onSelectOrder(value),
            } as IEnumPickerClientCallback<EGroupOrderSelectorMode>,
            mode: EEnumPickerMode.Single,
            appearance: 'outline',
            translations: gTranslations.groupOrderSelectorShortcurt,
            inputTitle: 'Ordernar por',
            enum: EGroupOrderSelectorMode,
            clientCallback: null,
            current: this.parameters.selected,
            buttonMode: true
        })
    }

    public onClickOption(option: EGroupOrderSelectorModeOption): void {
        switch (option) {
            case EGroupOrderSelectorModeOption.recent: {
                this.parameters.config[option] = !this.parameters.config[option];
            };
            break;
            case EGroupOrderSelectorModeOption.agent: {
                this.parameters.config[option] = !this.parameters.config[option];
            };
            break;
        }
        if (isValidFunction(this.parameters.clientCallback?.onGroupOrderSelectorChange))
            this.parameters.clientCallback?.onGroupOrderSelectorChange()
        ;
    }

    public ngOnDestroy(): void {
        this.listener.destroySubscriptions(this);
    };

};
