<!-- nao retirar, necessário para carregar os estilos do mat-chip -->
<mat-chip-listbox></mat-chip-listbox>

<div class="top-container">
  <header>

    <div class="title-container">
      <ticket-more-options></ticket-more-options>
      <h3>{{ ticketData.title }}</h3>
    </div>
    <div class="t-summary">

      <mat-chip color="accent" selected class="dense" [copyToClipboard]="protocol" matTooltip="Clique para copiar">{{
        protocol }}</mat-chip>
      <a [href]="ticketFullRoute" [copyToClipboard]="ticketFullRoute" (click)="$event.preventDefault()"
        class="dense t-link">
        <mat-icon>link</mat-icon>
      </a>

    </div>

  </header>

  <app-colmeia-window-top-bar *ngIf="isWindow" [hideTitle]="true"></app-colmeia-window-top-bar>

</div>

<div class="main">
  <section class="interactions">

    <h4 class="section-title">
      <span>Atividades</span>
    </h4>

    <div class="interaction-list" *ngIf="!loading; else loadingSpinner">
      <ticket-interaction *ngFor="let interaction of ticketInteractions"
        [interaction]="interaction"></ticket-interaction>
    </div>

    <ticket-add-comment (insert)="onAddComment()" [ticketId]="ticketData.idTicket"></ticket-add-comment>
  </section>

  <section class="details">
    <h4 class="section-title">
      <span>Detalhes</span>
    </h4>

    <ticket-info-list [data]="ticketData" [island]="island"></ticket-info-list>
  </section>
</div>

<ng-template #loadingSpinner>
  <mat-progress-spinner mode="indeterminate" diameter="30">
  </mat-progress-spinner>
</ng-template>