import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../../all-serializables";
import { ECloudNaturalLanguageEntityType, ECloudNaturalLanguageSyntaxLabel, ECloudNaturalLanguageSyntaxTag } from "../../../knowledge-base/kb-inferfaces";
import { ITranslationConfig } from "../../../translation/translation-engine";
import { genericTypedSuggestions } from "../../../../tools/type-utils";



//

const label = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.CloudNaturalLanguageSyntaxLabel,
    fields: genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxLabel]?: number }>()({
        [ECloudNaturalLanguageSyntaxLabel.UNKNOWN]: 1, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.ABBREV]: 2, // 
        [ECloudNaturalLanguageSyntaxLabel.ACOMP]: 3, // Predicativo do sujeito ou objeto
        [ECloudNaturalLanguageSyntaxLabel.ADVCL]: 4, // Oração subordinada adverbial
        [ECloudNaturalLanguageSyntaxLabel.ADVMOD]: 5, // Adjunto adverbial
        [ECloudNaturalLanguageSyntaxLabel.AMOD]: 6, // Adjunto adnominal
        [ECloudNaturalLanguageSyntaxLabel.APPOS]: 7, // Aposto
        [ECloudNaturalLanguageSyntaxLabel.ATTR]: 8, // Atributo
        [ECloudNaturalLanguageSyntaxLabel.AUX]: 9, // Verbo auxiliar
        [ECloudNaturalLanguageSyntaxLabel.AUXPASS]: 10, // Verbo auxiliar
        [ECloudNaturalLanguageSyntaxLabel.CC]: 11, // Conjunção
        [ECloudNaturalLanguageSyntaxLabel.CCOMP]: 12, // Verbo
        [ECloudNaturalLanguageSyntaxLabel.CONJ]: 13, // Termos adicionais no formato de lista
        [ECloudNaturalLanguageSyntaxLabel.CSUBJ]: 14, // Sujeito
        [ECloudNaturalLanguageSyntaxLabel.CSUBJPASS]: 15, // Sujeito
        [ECloudNaturalLanguageSyntaxLabel.DEP]: 16, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.DET]: 17, // Atributo
        [ECloudNaturalLanguageSyntaxLabel.DISCOURSE]: 18, // Interjeição
        [ECloudNaturalLanguageSyntaxLabel.DOBJ]: 19, // Objeto
        [ECloudNaturalLanguageSyntaxLabel.EXPL]: 20, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.GOESWITH]: 21, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.IOBJ]: 22, // Objeto
        [ECloudNaturalLanguageSyntaxLabel.MARK]: 23, // Conjunção
        [ECloudNaturalLanguageSyntaxLabel.MWE]: 24, // Preposição
        [ECloudNaturalLanguageSyntaxLabel.MWV]: 25, // Ignorar
    } as const),
});


const label2 = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.CloudNaturalLanguageSyntaxLabel2,
    fields: genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxLabel]?: number }>()({
        [ECloudNaturalLanguageSyntaxLabel.NEG]: 1, // Advérbio
        [ECloudNaturalLanguageSyntaxLabel.NN]: 2, // Adjunto adnominal
        [ECloudNaturalLanguageSyntaxLabel.NPADVMOD]: 3, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.NSUBJ]: 4, // Sujeito
        [ECloudNaturalLanguageSyntaxLabel.NSUBJPASS]: 5, // Sujeito
        [ECloudNaturalLanguageSyntaxLabel.NUM]: 6, // Numeral
        [ECloudNaturalLanguageSyntaxLabel.NUMBER]: 7, // Numeral
        [ECloudNaturalLanguageSyntaxLabel.P]: 8, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PARATAXIS]: 9, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PARTMOD]: 10, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PCOMP]: 11, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.POBJ]: 12, // Adjunto adverbial
        [ECloudNaturalLanguageSyntaxLabel.POSS]: 13, // Pronome
        [ECloudNaturalLanguageSyntaxLabel.POSTNEG]: 14, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PRECOMP]: 15, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PRECONJ]: 16, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PREDET]: 17, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PREF]: 18, // Pronome
        [ECloudNaturalLanguageSyntaxLabel.PREP]: 19, // Preposição
        [ECloudNaturalLanguageSyntaxLabel.PRONL]: 20, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PRT]: 21, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.PS]: 22, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.QUANTMOD]: 23, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.RCMOD]: 24, // Verbo
        [ECloudNaturalLanguageSyntaxLabel.RCMODREL]: 25, // Ignorar
    } as const),
});


const label3 = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.CloudNaturalLanguageSyntaxLabel3,
    fields: genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxLabel]?: number }>()({
        [ECloudNaturalLanguageSyntaxLabel.RDROP]: 1, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.REF]: 2, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.REMNANT]: 3, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.REPARANDUM]: 4, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.ROOT]: 5, // Núcleo
        [ECloudNaturalLanguageSyntaxLabel.SNUM]: 6, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.SUFF]: 7, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.TMOD]: 8, // Adjunto adverbial
        [ECloudNaturalLanguageSyntaxLabel.TOPIC]: 9, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.VMOD]: 10, // Verbo
        [ECloudNaturalLanguageSyntaxLabel.VOCATIVE]: 11, // Vocativo
        [ECloudNaturalLanguageSyntaxLabel.XCOMP]: 12, // Núcleo
        [ECloudNaturalLanguageSyntaxLabel.SUFFIX]: 13, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.TITLE]: 14, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.ADVPHMOD]: 15, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.AUXCAUS]: 16, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.AUXVV]: 17, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.DTMOD]: 18, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.FOREIGN]: 19, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.KW]: 20, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.LIST]: 21, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.NOMC]: 22, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.NOMCSUBJ]: 23, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.NOMCSUBJPASS]: 24, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.NUMC]: 25, // Ignorar
    } as const),
});


const label4 = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.CloudNaturalLanguageSyntaxLabel4,
    fields: genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxLabel]?: number }>()({
        [ECloudNaturalLanguageSyntaxLabel.COP]: 1, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.DISLOCATED]: 2, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.ASP]: 3, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.GMOD]: 4, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.GOBJ]: 5, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.INFMOD]: 6, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.MES]: 7, // Ignorar
        [ECloudNaturalLanguageSyntaxLabel.NCOMP]: 8, // Ignorar
    } as const),
});


// 
// 



export const allCloudNaturalLanguageSyntaxLabelTranslations = genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxLabel]: ITranslationConfig }>()({
    ...label.translations,
    ...label2.translations,
    ...label3.translations,
    ...label4.translations,
});



const entityType = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.CloudNaturalLanguageEntityType,
    fields: genericTypedSuggestions<{ [key in ECloudNaturalLanguageEntityType]: number }>()({
        [ECloudNaturalLanguageEntityType.UNKNOWN]: 1, // unknown
        [ECloudNaturalLanguageEntityType.PERSON]: 2, // person
        [ECloudNaturalLanguageEntityType.LOCATION]: 3, // location
        [ECloudNaturalLanguageEntityType.ORGANIZATION]: 4, // organization
        [ECloudNaturalLanguageEntityType.EVENT]: 5, // event
        [ECloudNaturalLanguageEntityType.WORK_OF_ART]: 6, // work of art
        [ECloudNaturalLanguageEntityType.CONSUMER_GOOD]: 7, // consumer good
        [ECloudNaturalLanguageEntityType.OTHER]: 8, // other
        [ECloudNaturalLanguageEntityType.PHONE_NUMBER]: 9, // phone number
        [ECloudNaturalLanguageEntityType.ADDRESS]: 10, // address
        [ECloudNaturalLanguageEntityType.DATE]: 11, // date
        [ECloudNaturalLanguageEntityType.NUMBER]: 12, // number
        [ECloudNaturalLanguageEntityType.PRICE]: 13, // price
    } as const),
});

// const syntaxTag = getTranslationsFieldsAndScreenOptions({
//     idSerializable: EAllSerializable.CloudNaturalLanguageSyntaxTag,
//     fields: genericTypedSuggestions<{ [key in ECloudNaturalLanguageSyntaxTag]: number }>()({
//         [ECloudNaturalLanguageSyntaxTag.UNKNOWN]: 1, // unknown
//         [ECloudNaturalLanguageSyntaxTag.ADJ]: 2, // adj
//         [ECloudNaturalLanguageSyntaxTag.ADP]: 3, // adp
//         [ECloudNaturalLanguageSyntaxTag.ADV]: 4, // adv
//         [ECloudNaturalLanguageSyntaxTag.CONJ]: 5, // conj
//         [ECloudNaturalLanguageSyntaxTag.DET]: 6, // det
//         [ECloudNaturalLanguageSyntaxTag.NOUN]: 7, // noun
//         [ECloudNaturalLanguageSyntaxTag.NUM]: 8, // num
//         [ECloudNaturalLanguageSyntaxTag.PRON]: 9, // pron
//         [ECloudNaturalLanguageSyntaxTag.PRT]: 10, // prt
//         [ECloudNaturalLanguageSyntaxTag.PUNCT]: 11, // punct
//         [ECloudNaturalLanguageSyntaxTag.VERB]: 12, // verb
//         [ECloudNaturalLanguageSyntaxTag.X]: 13, // x
//         [ECloudNaturalLanguageSyntaxTag.AFFIX]: 14, // affix
//     } as const),
// });


export const cloudNaturalLanguageTranslations = {
    ...entityType.translations,
    ...label.translations,
    ...label2.translations,
    ...label3.translations,
    ...label4.translations,
    // ...syntaxTag.translations,
};