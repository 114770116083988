import { RootComponent } from 'app/components/foundation/root/root.component';
import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TGlobalUID } from '@colmeia/core/src/core-constants/types';
import { Interaction } from '@colmeia/core/src/interaction/interaction';
import { Group } from '@colmeia/core/src/business/group';
import { DatePipe } from '@angular/common';
import { TArrayID } from '@colmeia/core/src/core-constants/types';
import { UserSettingsService } from 'app/services/user-settings.service';
import { IInteractionPreviewerHandler } from 'app/handlers/interaction-previewer-handler';
import {gTranslations} from "@colmeia/core/src/shared-business-rules/const-text/translations";


@Component({
    selector: 'app-shared-item',
    templateUrl: './shared-item.component.html',
    styleUrls: ['./shared-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareMessageContainerComponent extends RootComponent<'alreadySharedWith' | 'from' | 'item'>implements OnInit {
    private datePipe: DatePipe;
    @Input() item: Interaction;
    @Input() index: number;
    @Input() idGroup: TGlobalUID;
    @Input() alreadySharedIn: TArrayID;

    constructor(
        private settings: UserSettingsService
    ) {
        super({
            alreadySharedWith: gTranslations.share.alreadySharedWith,
            from: gTranslations.share.from,
            item: gTranslations.share.item
        });
        this.datePipe = new DatePipe(this.settings.getSelectedLocale());
    }

    ngOnInit() {}

    getInteractionPreviewerHandler(): IInteractionPreviewerHandler {
        return {
            getInteraction: () => this.item,
        }
    }

    wasSharedBefore(): boolean {
        return this.alreadySharedIn.length > 0;
    }

    alreadySharedWith(): string {
        return this.alreadySharedIn.join(', ');
    }

    get humanIndex(): number {
        return this.index + 1;
    }

    get origin(): string {
        return this.idGroup
            ? Group.staticFactory(this.idGroup).getName()
            : '';
    }

    get date(): string {
        const now = new Date();
        const org = this.item.getDate();
        return this.datePipe.transform(
            org,
            this.datePipe.transform(now, 'dd') !== this.datePipe.transform(org, 'dd')
                ? `dd/LL/yyyy HH:mm`
                : `HH:mm`
        );
    }

}
