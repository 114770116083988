import {Tracker} from './tracker';
import { Participant } from '../../business/participant';
import { constant } from '../../business/constant';
import { IClientErrorTracker } from '../../comm-interfaces/barrel-comm-interfaces';
import { ILogCharacteristic } from '../../core-constants/tracker-qualifiers';

export interface IErrorDescription {
    name: string;
    stack: string;
    message: string;

}


export class TrackerErrorClientError extends Tracker {
    private error: Error;
    private fname: string;
    private details: Object;
    private qualifiers: ILogCharacteristic;

    public constructor (participant: Participant, error: Error, qualifier: ILogCharacteristic, functionName: string, details: Object) {
        super(null, participant, constant.trackerTypes.client.error);
        this.details = details;
        this.fname = functionName;
        this.error = error;
        this.qualifiers = qualifier;
    };

    public toJSON(): IClientErrorTracker {
        
        return {...super.toJSON(),
            error: {
                name: this.error.name,
                stack: this.error.stack,
                message: this.error.message
            },
            functionName: this.fname,
            details: this.details,
            qualifier: this.qualifiers
        };
    };

    public rehydrate(json: IClientErrorTracker): void {
        super.rehydrate(json);
    };

};
