import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TAvatarArray } from '@colmeia/core/src/business/avatar';
import { constant } from '@colmeia/core/src/business/constant';
import { Group } from '@colmeia/core/src/business/group';
import { Serializable, TSerializableArray } from "@colmeia/core/src/business/serializable";
import { MMconstant, TMultimediaInstanceArray } from '@colmeia/core/src/multi-media/barrel-multimedia';
import { hasPermissionInRoles } from "@colmeia/core/src/rules/sec-functions";
import { isValidRef, isValidArray, isValidString, nop } from '@colmeia/core/src/tools/utility';
import { GroupCardItemService } from './group-card-item.service';
import {clientConstants} from "../../../model/constants/client.constants";
import {ConstantService} from "../../../services/constant.service";
import {RouterManagerService} from "../../../services/router-manager.service";
import {SessionService} from "../../../services/session.service";
import {HandlerHexagonon} from "../../../handlers/hexagono.handler";
import {MoreOptionsThreeDotsHandler} from "../../../handlers/more-options-three-dots.handler";
import {FollowButtonHandler} from "../../../handlers/follow-button-handler";
import { RootComponent } from 'app/components/foundation/root/root.component';
import {GroupType} from "@colmeia/core/src/business/group-type";
import {EGroupNavigation, EGroupType} from "@colmeia/core/src/shared-business-rules/visual-constants";
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { EYouTaskStatus } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { BooleanOf } from 'Boolean/_api';

export interface IGroupCardItemLabels {
    readMore: string;
    readLess: string;
}
@Component({
    selector: 'app-group-card-item',
    templateUrl: './group-card-item.component.html',
    styleUrls: ['./group-card-item.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupCardItemComponent extends RootComponent<'readMore' | 'readLess'> implements OnInit, OnChanges {

    @Input() group: Group;
    @Input() showStats: boolean = false;
    @Input() subscription: EYouTaskStatus | undefined;
    @Input() canShowChatButton: boolean;

    public showAllGroupDescription: boolean = false;
    public groupDescription: string;
    public maxCharacters: number = clientConstants.UI.group.description.maxCharacters;
    public showGroupDescription: boolean = false;
    public showReadMore: boolean = false;
    public followBtnHandler: FollowButtonHandler;
    public members: TAvatarArray;
    public mmList: TMultimediaInstanceArray;
    public showGroupTypePhoto: boolean = false;
    public groupTypeImg: string;
    public groupTypeName: string;

    public moreOptionsHandler: MoreOptionsThreeDotsHandler;

    public hexagonHandler: HandlerHexagonon = HandlerHexagonon.newEmpty();

    constructor(
        private constantSvc: ConstantService,
        private grpCardItemSvc: GroupCardItemService,
        private routerSvc: RouterManagerService,
        private session: SessionService,
    ) {
        super({
            readMore: gTranslations.fragments.readMore,
            readLess: gTranslations.fragments.readLess,
        });
    }

    ngOnInit() {
        this.moreOptionsHandler = this.getMoreOptionsHandler();
        this.setGroupDescription();
        this.showGroupDescription = isValidString(this.group.getDescription());
        this.showReadMore =  this.groupDescription && this.groupDescription.length > this.maxCharacters;
        this.followBtnHandler = new FollowButtonHandler({
            idObjectType: constant.objectType.group,
            primaryID: this.group.getPrimaryID(),
            subscriptionStatus: this.subscription,
            canShowChatButton: this.canShowChatButton,
            group: this.group,
        });
        this.members = isValidRef(this.group.getAvatarSample())
            ? this.group.getAvatarSample().filter(avatar => avatar.getMultimediaObject())
            : []
        ;
        const mmObject = this.group.getMultimediaObject();
        this.mmList = mmObject ?
            [
                ...mmObject.getAllMultimediaInstanceWithTag(MMconstant.tag.background),
                ...mmObject.getAllMultimediaInstanceWithTag(MMconstant.tag.background),
            ] : []
        ;
        this.groupTypeImg = this.grpCardItemSvc.getGroupTypeImgSrc(this.group.getGroupType().getPrimaryID());
        this.showGroupTypePhoto = isValidString(this.groupTypeImg);
        this.groupTypeName = this.grpCardItemSvc.getGroupTypeName(this.group.getGroupType().getPrimaryID());
        this.generateHexagonon();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.subscription) {
            this.followBtnHandler = new FollowButtonHandler({
                idObjectType: constant.objectType.group,
                primaryID: this.group.getPrimaryID(),
                subscriptionStatus: this.subscription
            });
        }
    }

    public generateHexagonon() {
        this.hexagonHandler = HandlerHexagonon.newHandler({
            serializable: this.group,
            idMultimediaTag: MMconstant.tag.photo
        });
    }
  
    canShowGroupDescription(): boolean {
        return isValidString(this.group.getDescription());
    }

    setGroupDescription(): void {
        this.groupDescription = this.group.getDescription();

        if (this.showReadMore && !this.showAllGroupDescription) {
            this.groupDescription = `${this.groupDescription.slice(0, this.maxCharacters)}...`;
        }
    }

    toggleTextToShow(): void{
        this.showAllGroupDescription = !this.showAllGroupDescription;
        this.setGroupDescription();
    }

    getPhoto(): string {
        const bannerUri: string = this.group.getMultimediaIDwithIDTag(MMconstant.tag.background);
        if(!isValidRef(bannerUri)) {
            return null;
        }

        const finalUrl: string = `${
            this.constantSvc.getFileUrl()
        }${
            bannerUri
        }`;
        return finalUrl;
    }

    canShowPhoto(): boolean {
        return isValidRef(this.getPhoto());
    }

    get moreOptionsOptions(): TSerializableArray {
        const groupID = this.group.getGroupID(),
              participantRoles = this.session.getPlayerInfo().getGroupAvatarRole(groupID, this.session.getSelectedAvatarID(), true),
              groupType: GroupType = this.group.getGroupType();

        if (groupType.isPersonalRoot() || groupType.isPersonal()) {
            return [];
        }
        const teste = [
            Serializable.staticFactory(EGroupType.SubGroupEdit),
            Serializable.staticFactory(EGroupType.Options),
            Serializable.staticFactory(EGroupNavigation.createGroup),
        ].filter((serializable: Serializable) => {
            return isValidArray(participantRoles) && (!serializable.getPrivilegeNeeded() || hasPermissionInRoles(participantRoles, serializable.getPrivilegeNeeded()));
        });

        return teste
    }

    get mustShowMoreOptions(): boolean {
        return this.moreOptionsOptions.length > 0;
    }

    private getMoreOptionsHandler(): MoreOptionsThreeDotsHandler {
        return MoreOptionsThreeDotsHandler.new({
            clientInstance: {
                onOptionSelectedCallback: (option) => {
                    this.grpCardItemSvc.handlerMoreOptionsSelect(option, this.group, nop);
                }
            },
            options: this.moreOptionsOptions
        });
    }

    async goToGroup(): Promise<void> {
        this.routerSvc.goToGroup(this.group.getPrimaryID());
    }

    get isGroupLockedToMe(): boolean {
        if (this.subscription === EYouTaskStatus.done || this.canShowChatButton) {
            return false;
        } else {
            return this.session.needToAskToParticipate(this.group.getGroupID()) && !this.session.avatarBelongsToGroup(this.group.getGroupID(), this.session.getSelectedAvatarID());
        }
    }
}
