import { Component, Input } from "@angular/core";
import { IToolbarElement } from "@colmeia/core/src/shared-business-rules/graph-transaction/toolbar/config-toolbar.types";
import { BpmGraphToolbarElementSelectorHandler } from "./bpm-graph-toolbar-element-selector.handler";


@Component({
  selector: "bpm-graph-toolbar-element-selector",
  templateUrl: "./bpm-graph-toolbar-element-selector.component.html",
  styleUrls: ["./bpm-graph-toolbar-element-selector.component.scss"],
})
export class BpmGraphToolbarElementSelectorComponent {
    public toolbarElements: IToolbarElement[] = [];
    
    @Input()
    public _handler: BpmGraphToolbarElementSelectorHandler;
    public get handler(): BpmGraphToolbarElementSelectorHandler {
        return this._handler;
    }
    public set handler(handler: BpmGraphToolbarElementSelectorHandler) {
        this._handler = handler;
        this.toolbarElements = handler.getComponentParameter().toolbarElements;
    }

    public toolbarElementSelected(toolbarElement: IToolbarElement) {
      this.handler.getDialogRef().close(toolbarElement);
    }
}