import {constant,TGlobalUID} from './constant';
import {errorCodes} from '../error-control/barrel-error';
import * as Utility from '../tools/utility';
import {Group} from './group';
import {Serializable} from './serializable';
import {Avatar} from './avatar';
import {Participant} from './participant';
import {IPlayerJSON} from '../comm-interfaces/business-interfaces';
import {SecuritySettings} from '../security/security-settings';
import {UberCache} from '../persistency/uber-cache';
import { TArrayAvatar } from '../core-constants/types';
import { isValidRef } from '../tools/utility';


export class Player extends Serializable {
    private email: string;
    private personalGroup: Group;
    private avatarOriginal: Avatar;
    private arrayAvatar: TArrayAvatar;
    private countAvatarOriginal: number;
    private securitySettings: SecuritySettings;
    private personalGroupParticipant: Participant;

    private constructor (idPlayer: TGlobalUID, email: string, name: string) {
        super(constant.objectType.player, idPlayer);
        super.setName(name);
        this.email    = email;
        this.countAvatarOriginal = 0;
        this.arrayAvatar = [];
        this.securitySettings = null;
    };

    public toJSON(): IPlayerJSON {
        let json: IPlayerJSON = <IPlayerJSON>super.toJSON();
        json.email = this.email;
        json.avatarOriginal = this.avatarOriginal.toJSON();
        json.arrayAvatar = [];

        if(isValidRef(this.personalGroup)) {
            json.personalGroup = this.personalGroup.toJSON();
            json.participantPersonalGroup = this.personalGroupParticipant.toJSON();
        }

        for (let r of this.arrayAvatar) {
            json.arrayAvatar.push(r.toJSON());
        };
        return json;
    };

    public rehydrate(json: IPlayerJSON) {
        super.rehydrate(json);
        this.email = json.email;
        this.arrayAvatar = [];
        for (let avatar of json.arrayAvatar) {
            const myAvatar: Avatar = Avatar.factoryMessage(avatar);
            this.arrayAvatar.push(myAvatar);
            if (myAvatar.isOriginal() && myAvatar.isRelatedToPlayer()) {
                this.avatarOriginal = myAvatar;
            }
        }

        if(isValidRef(json.personalGroup)) {
            this.personalGroup = Group.factoryMessage(json.personalGroup);
            if (isValidRef(json.securitySettings)) {
                this.securitySettings = SecuritySettings.factoryMessage(json.securitySettings);
            }
            this.personalGroupParticipant = Participant.factoryMessage(json.participantPersonalGroup);
        }
    };

    public static factoryMessage(json: IPlayerJSON): Player {
        let player: Player = new Player(json.primaryID, json.email, 
                Player.getJText(json, constant.serializableField.name));
        player.rehydrate(json);
        return player;
    };

    public static staticFactory(idPlayer: TGlobalUID): Player {
        return <Player>UberCache.uberFactory(idPlayer, constant.objectType.player, true);
    };

    public getPersonalGroupParticipant(): Participant {return this.personalGroupParticipant};

    public addAvatar(avatar: Avatar) {
        if (avatar.isOriginal()) {
            this.avatarOriginal = avatar;
        };
        this.arrayAvatar.push(avatar);
    };

    public getPlayerID(): TGlobalUID {return super.getPrimaryID()};
    public getEmail() {return this.email};
    public getAvatarArray(): TArrayAvatar {return this.arrayAvatar;};
    public getAvatarOriginal(): Avatar {return this.avatarOriginal;};
    public getPersonalGroup(): Group {return this.personalGroup;};
    public setPersonalGroup(group: Group) {this.personalGroup = group;};
    public getSecuritySettings(): SecuritySettings {return this.securitySettings};
    
    public setEmail(email: string): void {
        this.email = email;
    };
  
};




