import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { IGenerativePromptServer, IGenerativeVectorChunkServer } from "@colmeia/core/src/shared-business-rules/generative/generative-model";
import { defaultNserValidator } from "@colmeia/core/src/shared-business-rules/non-serializable-id/validation/implementations/nser";
import { contentVectorTranslations } from '../../../const-text/views/vectors';
import { isInvalid } from '@colmeia/core/src/tools/utility';

export function generativePromptValidator(ns: IGenerativePromptServer, friendly: FriendlyMessage): FriendlyMessage {

    defaultNserValidator(ns, friendly);

    if (isInvalid(ns.temperature) || ns.temperature < 0 || ns.temperature > 1) {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noPromptTemperature);
    }

    if (isInvalid(ns.text) || ns.text.trim() === "") {
        friendly.addReturnTranslationResponse(contentVectorTranslations.noPromptText);
    }

    if (ns.hasOwnProperty("idFunction")) {
        if (isInvalid(ns.idFunction))
            friendly.addReturnTranslationResponse(contentVectorTranslations.noPromptUserFunction);
    }
    return friendly;
}
