export enum EGroupContainer {
    participationRequest = 'conPartRequest',// Aprovações de Participação na Rede Social
    dashboardMenus = 'dashMenus', // Menus do Dashboard
}

interface IGroupContainerConfiguration {
    unlimited?: boolean;
};


interface IContainerConfDB {
    [egroupContainer: string]: IGroupContainerConfiguration;
};

const allGroupContainerConf: IContainerConfDB = {
    [EGroupContainer.participationRequest]: {},
};


export function getContainerConfiguration(container: EGroupContainer): IGroupContainerConfiguration {
    return allGroupContainerConf[container];
}