import { IClientSemaphoreConfig, CoreQueueService, ELocalQueueName, ILocalQueuEntry } from "./core-queue-service";
import { delay, getUniqueStringID,  } from "../tools/utility";
import { secToMS, minToMS } from "../time/time-utl";
import { genericTypedSuggestions } from "../tools/type-utils";

const standardSemaphore: IClientSemaphoreConfig = {
    expirationLockTime: secToMS(50),
    numberOfTries: 50,
    retryInterval: 1000,
    throwErrorIfNotLocked: true,
};

export const fileProcessorBatchSendConfig = genericTypedSuggestions<IClientSemaphoreConfig>()({
    expirationLockTime: minToMS(5),
    numberOfTries: 100,
    throwErrorIfNotLocked: true,
    retryInterval: 200
});



export class CoreSemaphore {
    private queue: CoreQueueService = new CoreQueueService();


    public async runClientWithLock<T>(operation: () => Promise<T>, resourceID: string, config: IClientSemaphoreConfig = standardSemaphore): Promise<T> {
        let locked: boolean;
        let result: T;
        let error: Error;
        const lockOwner: string = this.addToQueue(resourceID);
        try {
            locked = await this.getLockedResource(resourceID, lockOwner, config);
            if (locked) {
                result = await operation();
            };
        } catch (err) {
            error = err;
        } finally {
            
            
            if (locked) {
                const remove = this.queue.getNextEntry(<ELocalQueueName>resourceID);

            } else if (! locked) {
                if (config.throwErrorIfNotLocked) {

                };
            }

            if (error) {

            };
        }
        return result;
    };

     private async getLockedResource(resourceID: string, lockOwnewr: string, config: IClientSemaphoreConfig): Promise<boolean> {
        let currentAttempt: number = 0;
        let myTurn: boolean = false;
        while (currentAttempt++ <= config.numberOfTries && !(myTurn = this.isMyTurn(resourceID, lockOwnewr))) {
            await delay(config.retryInterval);
        };
        if (! myTurn) {
            this.queue.removeFromQueue(<ELocalQueueName>resourceID, lockOwnewr);
        }
        return myTurn;
    };

    private isMyTurn(resourceID: string, lockOwner: string): boolean {
        return this.getCurrentLock(resourceID) === lockOwner;
    };

    private getCurrentLock(resourceID: string): string {
        const entry: ILocalQueuEntry = this.queue.getWithoutShift(<ELocalQueueName>resourceID)
        return entry ? entry.id : null;
    };

    private addToQueue(resourceID: string) {
        const id: string = getUniqueStringID();
        this.queue.enqueue(<ELocalQueueName>resourceID, {id: id, data: id});
        return id;
    };



}