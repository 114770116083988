import { TGlobalUID } from "../../../core-constants/types";
import { Participant } from "../../../business/participant";
import { constant } from "../../../business/constant";
import { ToAvatar } from "../to-avatar";


export class InviteToGroupRequest extends ToAvatar {


    private constructor(primaryID: TGlobalUID, participant: Participant, idAvatar: TGlobalUID) {
        super(primaryID, participant, constant.subscription.request.inviteToGroup, idAvatar)
    };

    public static getNewInviteToGroupRequest(personalGroupParticipant: Participant, idAvatar: TGlobalUID): InviteToGroupRequest {
        return new InviteToGroupRequest(null, personalGroupParticipant, idAvatar);
    };
};

