import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { EYouTaskRequestType, EYouTaskStatus, IYouTaskFormSolicitationServer, TAllYouTasksServer } from '@colmeia/core/src/shared-business-rules/you-control/solicitation-tasks-model';
import { YouTasksClientService } from 'app/components/dashboard/security/solicitation-tasks.service';
import { SessionService } from 'app/services/session.service';
import { ColmeiaWindowRef } from '../../dashboard-foundation/colmeia-window/colmeia-window-ref';
import { IYouTasksComponentBase } from '../you-task.client-db';
import { YouTasksEditHandler } from '../you-tasks-edit.handler';
import { LookupService } from 'app/services/lookup.service';
import { IGeneralFormAnswerServer } from '@colmeia/core/src/general-form/general-form-answer';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { EGeneralFormViewType } from 'app/model/general-form.model';
import { SnackMessageService } from 'app/services/snack-bar';
import { getClock, isValidRef, isValidString, typedClone } from '@colmeia/core/src/tools/barrel-tools';
import { SerializableHeaders } from 'app/services/serializable-headers';
import { EObjectType } from '@colmeia/core/src/business/constant.enums';
import { HandlerHexagonon } from 'app/handlers/hexagono.handler';
import { serializableWithPhotoToHexagonHandler } from 'app/handlers/business-handlers';
import { Avatar } from '@colmeia/core/src/business/avatar';
import { IAvatarJSON } from '@colmeia/core/src/comm-interfaces/barrel-comm-interfaces';


@Component({
    selector: 'cm-you-tasks-form-solicitation',
    templateUrl: './you-tasks-form-solicitation.component.html',
    styleUrls: ['./you-tasks-form-solicitation.component.scss']
})
export class YouTasksEditComponent implements OnInit, IYouTasksComponentBase {

    static nsType = ENonSerializableObjectType.yourTasks;
    public receiverDescription: string = '';

    private nsClone: IYouTaskFormSolicitationServer;

    loading: boolean = true;
    saving: boolean = false;

    answer: IGeneralFormAnswerServer;
    metadata: SchemaPropertyServer;

    viewType: EGeneralFormViewType = EGeneralFormViewType.view;

    get handler(): YouTasksEditHandler {
        return this.colmeiaWindowRef.data;
    }

    get parameters() {
        return this.colmeiaWindowRef.data.getComponentParameter()
    };

    get ns(): IYouTaskFormSolicitationServer {
        return this.parameters.nss[0] as IYouTaskFormSolicitationServer;
    }

    get isResolved(): boolean {
        return this.colmeiaWindowRef.data.isResolved();
    }

    get readOnly(): boolean {
        return this.loading || this.isResolved;
    }

    get getComment(): string {
        return this.ns?.inputs?.[0]?.description;
    }

    get avatarHexagonHandler(): HandlerHexagonon | undefined {
        return this.handler.avatarHexagonHandler;
    }
    get resolverAvatar(): Avatar | undefined {
        return this.handler.resolverAvatar;
    }

    public constructor(
        private sessionSvc: SessionService,
        private youTaskSvc: YouTasksClientService,
        private colmeiaWindowRef: ColmeiaWindowRef<YouTasksEditComponent, unknown, YouTasksEditHandler>,
        private lookupSvc: LookupService,
        private snackSvc: SnackMessageService,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.nsClone = typedClone(this.parameters.nss[0] as IYouTaskFormSolicitationServer);
        this.loadItens();
    }

    private async loadItens(): Promise<void> {
        this.answer = await this.lookupSvc.getNS(this.ns.details.idAnswer);
        this.metadata = await this.lookupSvc.getNS(this.answer.idSchemma);

        if (this.isResolved) {
            this.receiverDescription = this.getComment;
        }

        await this.handler.loadResolvedTaskData();

        this.loading = false;
    }

    async save() {
    }

    async doneSolicitation() {
        this.saving = true;

        if (isValidString(this.receiverDescription)) {
            this.nsClone.inputs = [{
                idAvatar: this.sessionSvc.getAvatarID(),
                description: this.receiverDescription,
                clockTick: getClock()
            }]
        };

        this.nsClone.solicitationStatus = EYouTaskStatus.done;
        console.log({ nsClone: this.nsClone })
        const response = await this.youTaskSvc.saveTasks(this.nsClone, EYouTaskRequestType.solicitationResponse);

        if (isValidRef(response)) {
            this.colmeiaWindowRef?.close();
            this.parameters?.reloadCallback?.();

            this.snackSvc.openSuccess("Sucesso.");
        }
        this.saving = false;
    }
}
