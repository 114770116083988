import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {SignalListenerService} from "../../../services/signal/signal-listener";
import {IListenerSubscription} from "../../../model/signal/ps-interfaces";
import {SubscriptionSignal} from "../../../model/signal/subscription-signal";
import { RootComponent } from 'app/components/foundation/root/root.component';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';

@Component({
    selector: 'app-group-back',
    templateUrl: './group-back.component.html',
    styleUrls: ['./group-back.component.scss']
})
export class GroupBackComponent extends RootComponent<'goBack'> implements OnInit, OnDestroy {

    @Input() nav: MatSidenav;
    currentGroupName: string;

    constructor(
        private listenerSvc: SignalListenerService,
    ) { 
        super({
            goBack: gTranslations.fragments.goBackTo,
        });
    }

    private subscriptionComponent: IListenerSubscription = {
        changeSubscriptionSignCallback: (subscriptionSignal: SubscriptionSignal) => {
            this.currentGroupName = subscriptionSignal.getGroup().getName();
        }
    };

    ngOnInit() {
        this.listenerSvc.listenSubscriptionChanges(this.subscriptionComponent);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.listenerSvc.destroySubscriptions(this.subscriptionComponent);
    }

    close() {
        this.nav.close();
    }

}
