import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CustomIconsRegisterService } from './custom-icons-register.service';

@NgModule({
  providers: [
    CustomIconsRegisterService,
    {
      provide: APP_INITIALIZER,
      useFactory: (cirSvc: CustomIconsRegisterService) => () => cirSvc.registryCustomSvgIcons(),
      deps: [CustomIconsRegisterService],
      multi: true
    }
  ],
  imports: []
})
export class CustomIconsModule { }
