import { MetaTranslation } from '@colmeia/core/src/shared-business-rules/const-text/meta-translation';
import { formatMessage } from "../../../tools/utility";
import { EAllSerializable, defineTranslations } from "../all-serializables";


export const utteranceErrors = defineTranslations({
    idSerializable: EAllSerializable.UtteranceErrors,
    fields: {
        labelsCollisions: MetaTranslation.field(1, formatMessage`
            Entidades não podem colidir.
            As seguintes entidades colidem: 
        `),
    },
});

export const allUtteranceScreenOptionsTranslations = {
    ...utteranceErrors,
};
