<div class="data-input__container">
  <input class="data-input__item"
    #inputText
    [type]="getType()"
    [ngModel]="typedText"
    [placeholder]="placeholderName"
    [autocomplete]="autocomplete"
    (keydown.enter)="onEnterKeyPressed()"
    (ngModelChange)="saveTypedText($event)">
    <mat-icon *ngIf="canShowIcon()" (click)="onIconClick()" [class]="iconClasses">{{ getIcon() }}</mat-icon>
</div>
