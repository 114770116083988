export enum EFormFieldType {
    date = 'dt',
    numeric = 'nm',
    string = 'str',
    boolean = 'boo',
}

export interface IFormField {
    name: string;
    fieldType: EFormFieldType;
    serializableField: number;
    obligatory?: boolean;
    decimals?: number;
    length?: number;
};

export type TFormFieldArray = Array<IFormField>;

export interface TFormulary {
    fields: TFormFieldArray;
}