import { EIdMenus } from '@colmeia/core/src/shared-business-rules/colmeia-tags/id-menus';

interface IMenuOrderGrouping {
    order?: number;
    grouping?: string;
}

export const mapMenuOrderAndGrouping: { [key in EIdMenus]?: IMenuOrderGrouping } = {
    /* MÓDULO CUSTOMER CARE */
    
    [EIdMenus.AttendanceStatus]: { // Status
        order: 1,
        grouping: 'agente'
    },
    [EIdMenus.ProviderActivecall]: { // Chamadas ativas
        order: 2,
        grouping: 'agente'
    },
    [EIdMenus.ContactList]: { // Lista de contatos
        order: 3,
        grouping: 'agente'
    },
    [EIdMenus.CRMTicketsView]: { // Tickets
        order: 4,
        grouping: 'agente'
    },
    [EIdMenus.ProviderAgents]: { // Real Time
        order: 4,
        grouping: 'supervisor'
    },
    [EIdMenus.ConversationHistory]: { // Histórico de conversação
        order: 5,
        grouping: 'supervisor'
    },
    [EIdMenus.AttendanceFollowUp]: { // Acompanhamento de Atendimento
        order: 6,
        grouping: 'supervisor'
    },
    [EIdMenus.agentsOnIsland]: { // Atendentes por ilha
        order: 7,
        grouping: 'supervisor'
    },
    [EIdMenus.AttendanceAgent]: { // Gerenciamento de Agente
        order: 8,
        grouping: 'supervisor'
    },
    [EIdMenus.AttendanceAgentStatus]: { // Status de atendente
        order: 9,
        grouping: 'supervisor'
    },
    [EIdMenus.ProviderSendactive]: { // Envios ativos
        order: 10,
        grouping: 'supervisor'
    },
    [EIdMenus.ProviderAttendanceevents]: { // Eventos de atendimento
        order: 11,
        grouping: 'estrutura'
    },
    [EIdMenus.AttendanceForm]: { // Metadados
        order: 12,
        grouping: 'estrutura'
    },
    [EIdMenus.ProviderAttsuport]: { // Recursos de Atendente
        order: 13,
        grouping: 'estrutura'
    },
    [EIdMenus.AttendanceCalendar]: { // Calendário de atendimento
        order: 14,
        grouping: 'estrutura'
    },
    [EIdMenus.AttendanceIsland]: { // Ilhas de Atendimento
        order: 15,
        grouping: 'estrutura'
    },
    [EIdMenus.AttendenceUserFunctions]: { // Funções
        order: 16,
        grouping: 'estrutura'
    },
    [EIdMenus.AttendanceFile]: { // Bancos de dados
        order: 17,
        grouping: 'estrutura'
    },

    /* MÓDULO COLMEIA */
    [EIdMenus.ColmeiaBillingClose]: { // Fechamento de fatura
        order: 1,
        grouping: 'billing'
    },
    [EIdMenus.ColmeiaBusinessContracts]: { // Contratos
        order: 2,
        grouping: 'billing'
    },
    [EIdMenus.ColmeiaProducts]: { // Produtos
        order: 3,
        grouping: 'billing'
    },

    /* MÓDULO CRM */
    [EIdMenus.CRMservicesTicketState]: { // Status de ticket
        order: 1
    },
    [EIdMenus.CRMservicesPhases]: { // Fases
        order: 2
    },
    [EIdMenus.CRMservicesSeverity]: { // Severidade
        order: 3
    },
    [EIdMenus.CRMServicesUrgencyLevel]: { // Níveis de urgência
        order: 4
    },
    [EIdMenus.CRMServicesSupportLevel]: { // Níveis de suporte
        order: 5
    },
    [EIdMenus.CRMservicesClosedState]: { // Status de fechamento
        order: 6
    },
    [EIdMenus.CRMservicesPackages]: { // Pacotes
        order: 7
    },
    [EIdMenus.CRMservicesGraphList]: { // Configuração de serviços
        order: 8
    },
}