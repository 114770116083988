export interface IPhoneNumberTimezoneInfo {
    country_info: {
        capital: string;
        code: string;
        dst: boolean;
        end_day: number;
        end_month: number;
        end_week: number;
        name: 'Brazil' |string;
        offset: number;
        start_day: number;
        start_month: number;
        start_offset: boolean;
        start_week: number
    };
    dst: boolean;
    dstnow: boolean;
    location: string;
    offset: number;
    options: {
        military: boolean;
        zone_display: string;
    }
    text: string;
    time: IPhoneNumberTimezoneTimeInfo
    type: string;
}

export interface IPhoneNumberTimezoneTimeInfo {
    display: string;
    hour: string;
    meridian: boolean;
    mins: string;
    zone: string;
}


export enum EBrazilDDDs {
    SaoPauloSP = '11',
    SaoJosedosCamposSP = '12',
    SantosSP = '13',
    BauruSP = '14',
    SorocabaSP = '15',
    RibeiraoPretoSP = '16',
    SaoJosedoRioPretoSP = '17',
    PresidentePrudenteSP = '18',
    CampinasSP = '19',
    RiodeJaneiroRJ = '21',
    CamposdosGoytacazesRJ = '22',
    VoltaRedondaRJ = '24',
    VilaVelhaEVitoriaES = '27',
    CachoeirodeItapemirimES = '28',
    BeloHorizonteMG = '31',
    JuizdeForaMG = '32',
    GovernadorValadaresMG = '33',
    UberlandiaMG = '34',
    PocosdeCaldasMG = '35',
    DivinopolisMG = '37',
    MontesClarosMG = '38',
    CuritibaPR = '41',
    PontaGrossaPR = '42',
    LondrinaPR = '43',
    MaringaPR = '44',
    FozdoIguacuPR = '45',
    FranciscoBeltraoEPatoBrancoPR = '46',
    JoinvilleSC = '47',
    FlorianopolisSC = '48',
    ChapecoSC = '49',
    PortoAlegreRS = '51',
    PelotasRS = '53',
    CaxiasdoSulRS = '54',
    SantaMariaRS = '55',
    BrasiliaDF = '61',
    GoianiaGO = '62',
    PalmasTO = '63',
    RioVerdeGO = '64',
    CuiabaMT = '65',
    RondonopolisMT = '66',
    CampoGrandeMS = '67',
    RioBrancoAC = '68',
    PortoVelhoRO = '69',
    SalvadorBA = '71',
    IlheusBA = '73',
    JuazeiroBA = '74',
    FeiradeSantanaBA = '75',
    BarreirasBA = '77',
    AracajuSE = '79',
    RecifePE = '81',
    MaceioAL = '82',
    JoaoPessoaPB = '83',
    NatalRN = '84',
    FortalezaCE = '85',
    TeresinaPI = '86',
    PetrolinaPE = '87',
    JuazeirodoNorteCE = '88',
    PicosPI = '89',
    BelemPA = '91',
    ManausAM = '92',
    SantaremPA = '93',
    MarabaPA = '94',
    BoaVistaRR = '95',
    MacapaAP = '96',
    CoariAM = '97',
    SaoLuisMA = '98',
    ImperatrizMA = '99',
}