import { observableList } from '../signal-constants';
import { PlayerCachedInfo } from '@colmeia/core/src/business/player-cached';
import { TGlobalUID, TGroupArray, ESecurityChanged } from '@colmeia/core/src/core-constants/types';
import { getFirstLevelChildren } from '@colmeia/core/src/rules/filters';
import { constant } from '@colmeia/core/src/business/constant';
import { MainSignal } from '../main-signal';
import {IMainSignalState} from "../ps-interfaces";
import { IChangeDataInteractionJSON } from '@colmeia/core/src/comm-interfaces/tracker-interfaces';

export interface ISecurityContextChangeParameter extends IMainSignalState {
    playerInfo: PlayerCachedInfo;
}


export class SecurityContextSignal extends MainSignal {
    private groupArray: TGroupArray;
    private changeType: ESecurityChanged;
    private changesOnVisibility: boolean;
    private lastPlayerCached: PlayerCachedInfo;

    constructor (playerCached: PlayerCachedInfo, lastPlayerCached: PlayerCachedInfo, changedInteractionJSON: IChangeDataInteractionJSON) {
        super(observableList.synch.playerCachedChanged, true, <ISecurityContextChangeParameter>{ playerInfo: playerCached });
        this.changeType = changedInteractionJSON.changedType;
        this.groupArray = [];
        this.changesOnVisibility = playerCached.hasChangesOnGroupVisibilityOfPlayer(lastPlayerCached);
        this.lastPlayerCached = lastPlayerCached
    }

    public getLastPlayerCached(): PlayerCachedInfo {
        return this.lastPlayerCached
    }

    public isArchived(): boolean {
        return this.changeType === ESecurityChanged.archive;
    };

    public getObservableInformation(): ISecurityContextChangeParameter {
        return super.getObservableInformation();
    }

    public isForMyGroup(idGroup: TGlobalUID): boolean {
        return this.getInfoOnState().idGroup == idGroup;
    };

    public getInfoOnState(): ISecurityContextChangeParameter {
        return <ISecurityContextChangeParameter>super.getObservableInformation();
    };

    public getPlayerCached(): PlayerCachedInfo {
        return this.getInfoOnState().playerInfo;
    }

    public hasChangesOnGroupVisibilityOfPlayer(): boolean {
        return this.changesOnVisibility;
    }

    public getMyChanels(idGroup: TGlobalUID): TGroupArray {
        return getFirstLevelChildren(idGroup, this.groupArray)
            .filter((grp) => {return grp.getGroupType().is(constant.groupType.standard.channel)})
    };

};
