export enum ECLientErrorConstantsField {
    MicrophoneNotFound = 1,
    MicrophoneNotAllowed = 2,
    MicrophoneGeneric = 3,
    ChatGeneralAnswer = 4,
    ConsistencyCanSubmmitThis = 5,
    SessionSvcSetAvatar = 6,
    LocationRetriever = 7,
    SignUpFirstStepError = 8,
    MimeTypeNotFound = 9,
    SaveMultimediaHeaderError = 10,
    NoHandlerForClick = 11,
    NoVideoAvailable = 12,
}

export const clientErrorCodes = {
    pack2: {
        serilizable: 'clientErrors1',
        fields: {
            socialNet: {
                cantFindInDatabase: 1,
                cantFindPersonalParticipant: 2
            }
        }
    },
    errorPrimaryID: 'allClientErrors',
    fields: {
        hardware: {
            microphone: {
                notFound: ECLientErrorConstantsField.MicrophoneNotFound,
                notAllowed: ECLientErrorConstantsField.MicrophoneNotAllowed,
                generic: ECLientErrorConstantsField.MicrophoneGeneric,
            }
        }
    },
    auth: {
        signUp: ECLientErrorConstantsField.SignUpFirstStepError
    },
    chat: {
        generalAnswer: ECLientErrorConstantsField.ChatGeneralAnswer,
        noHandlerForClick: ECLientErrorConstantsField.NoHandlerForClick
    },
    consistency: {
        cantSubmitThis: ECLientErrorConstantsField.ConsistencyCanSubmmitThis,
    },
    sessionSvc: {
        setAvatar: ECLientErrorConstantsField.SessionSvcSetAvatar,
    },
    multimedia: {
        mimeNotFound: ECLientErrorConstantsField.MimeTypeNotFound,
        saveMultimediaHeader: ECLientErrorConstantsField.SaveMultimediaHeaderError,
        noVideoAvailable: ECLientErrorConstantsField.NoVideoAvailable
    },
    location: {
        retriever: ECLientErrorConstantsField.LocationRetriever
    },
};
