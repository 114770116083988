import {constant, TGlobalUID} from '../business/constant';
import {IPermissionJSON} from '../comm-interfaces/business-interfaces';
import {Serializable} from '../business/serializable';
import {UberCache} from '../persistency/uber-cache';
import {Role} from './role';



export type TPermissionArray = Array<Permission>;

export class Permission extends Serializable{
    private alias: string;

    private constructor (idPermission: TGlobalUID, alias: string, name: string) {
        super(constant.objectType.permission, idPermission)
        this.alias = alias;
    };

    public getPermissionID(): TGlobalUID {return super.getPrimaryID(); };
    public getAlias(): string {return this.alias;};


    public toJSON(): IPermissionJSON {
        let json: IPermissionJSON = <IPermissionJSON>super.toJSON();
        json.alias = this.alias;
        return json; 
    };

    public rehydrate(json: IPermissionJSON): void {
        super.rehydrate(json);
        this.alias = json.alias;
    };
   

    public static getPermissionArray(): TPermissionArray {
        return <TPermissionArray>UberCache.getSerializableArray(constant.objectType.permission);
    };

    public static staticFactory(idPermission: TGlobalUID): Permission {
        return <Permission>UberCache.uberFactory(idPermission, constant.objectType.permission, true)
    };

    public static factoryMessage(json: IPermissionJSON): Permission {
        let permission: Permission = <Permission>UberCache.uberFactory(json.primaryID, constant.objectType.role, false);
        if (permission == null)
            permission = new Permission(json.primaryID, json.alias, 
                                        Role.getJText(json, constant.serializableField.name));
        permission.rehydrate(json);
        return permission;
    };



};



