import { ColmeiaWindowRef } from '../colmeia-window/colmeia-window-ref';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, Optional, OnInit } from '@angular/core';

@Component({
    selector: 'app-colmeia-window-top-bar',
    templateUrl: './colmeia-window-top-bar.component.html',
    styleUrls: ['./colmeia-window-top-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColmeiaWindowTopBarComponent implements OnInit {

    @Input()
    public title: string = '';
    public get windowTitle(): string {
        return this.windowRef.title;
    }

    @Input()
    windowRef: ColmeiaWindowRef

    @Input()
    hideTitle: boolean = false;

    @Input()
    disableWindowMoving: boolean = false;

    constructor(
        @Optional()
        private _windowRef: ColmeiaWindowRef,
        public cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.windowRef ||= this._windowRef;
    }

    minimizeClick(event: Event){
        this.preventDefaultAndPropagation(event);

        this.windowRef.minimize();
    }

    closeClick(event: Event) {
        this.preventDefaultAndPropagation(event);
        this.windowRef.close();
    }

    private preventDefaultAndPropagation(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

}
