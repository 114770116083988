import { MainHandler } from "./main-handler";
import { IComponentParameter } from "app/model/component-comm/basic";
import { genericTypedSuggestions } from "@colmeia/core/src/tools/type-utils";

export interface ICmPrintClientCallback {}
export enum ECmPrintType {
    Text = 'Text',
    Texts = 'Texts',
    Select = 'Select',
}
export interface ICmPrintItem {
    type: ECmPrintType;
    value: any;
    title: string;

    enableEditing?: true;
    toggleData?: TToggleData,
    onChangeValue?(item: ICmPrintItem, value: any): void;
}

export type TToggleData = IToggleDataFalse | IToggleDataTrue
interface IToggleDataFalse {
    enableToggle: false;
}
interface IToggleDataTrue {
    enableToggle: true;
    toggleLabel: string;
    toggleChecked: boolean;
}

export interface ICmPrintItemSelect extends ICmPrintItem {
    type: ECmPrintType.Select;
    value: ICmPrintSubItemSelect;
    items: ICmPrintSubItemSelect[];
    onChangeValue?(item: ICmPrintItemSelect, value: ICmPrintSubItemSelect): void;
}

export interface ICmPrintSubItemSelect {
    text: string;
    id: string;
}

export interface ICmPrintItemText extends ICmPrintItem {
    type: ECmPrintType.Text;
    value: string;
}

export interface ICmPrintItemTexts extends ICmPrintItem {
    type: ECmPrintType.Texts;
    value: string[];
    onChangeValue?(item: ICmPrintItem, value: string[], index?: number): void;
    enableAdd?: true;
}

export interface ICmPrintParameters extends IComponentParameter {
    clientCallback: ICmPrintClientCallback;
    items: ICmPrintItem[];
    enableStyles?: { listItem?: true };
}

export class CmPrintHandler extends MainHandler {
    private constructor(parameters: ICmPrintParameters) { super(parameters); };
    public static factory(parameters: ICmPrintParameters): CmPrintHandler { return new CmPrintHandler(parameters) };
    public getComponentParameter(): ICmPrintParameters { return super.getComponentParameter() as ICmPrintParameters };
}

type TCmPrintField = {
    [key in ECmPrintType]: (...args: any) => ICmPrintItem & { type: key };
}
class CmPrintFieldImplement implements TCmPrintField {
    public Text = (title: string, text: string, options: Partial<ICmPrintItemText> = {}): ICmPrintItemText => {
        return {
            type: ECmPrintType.Text,
            title: title,
            value: text,
            ...options,
        };
    }

    public Texts(title: string, texts: string[], options: Partial<ICmPrintItemTexts> = {}): ICmPrintItemTexts {
        return {
            type: ECmPrintType.Texts,
            title: title,
            value: texts,
            ...options,
        };
    }

    public Select(title: string, value: ICmPrintSubItemSelect, items: ICmPrintSubItemSelect[], options: Partial<ICmPrintItemSelect> = {}): ICmPrintItemSelect {
        return {
            type: ECmPrintType.Select,
            title,
            value,
            items,
            ...options
        }
    }
}

export const CmPrintField = genericTypedSuggestions<TCmPrintField>()(new CmPrintFieldImplement());
