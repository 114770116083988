import { Injectable } from '@angular/core';
import { PlayerCachedInfo } from "@colmeia/core/src/business/player-cached";
import { TNotificationDeviceArray } from '@colmeia/core/src/business/push-notifications';
import { EPlatformUpdateType, versionContol } from "@colmeia/core/src/core-constants/version-control";
import { UID } from '@colmeia/core/src/request-interfaces/request-interfaces';
import { IFocusInfo } from '@colmeia/core/src/request-interfaces/response-interfaces';
import { IClientVersion } from "@colmeia/core/src/security/version";
import { getReadableUniqueID, isValidRef } from "@colmeia/core/src/tools/utility";
import * as FingerPrintJS from '@fingerprintjs/fingerprintjs';
import { createServiceLogger } from 'app/model/client-utility';
import * as Bowser from "bowser";
import { Parser } from "bowser";
import { Observable, Subject } from 'rxjs';
import { Memoize } from 'typescript-memoize';
import { clientConstants } from "../../../model/constants/client.constants";
import { BrowserAudio } from '../browser/browser-audio';
import { BrowserCamera } from '../browser/browser-camera';
import { BrowserFileOpener } from '../browser/browser-file-opener';
import { BrowserFilesystem } from '../browser/browser-filesystem';
import { BrowserGeneric } from '../browser/browser-generic';
import { BrowserLocalNotification } from '../browser/browser-local-notification';
import { BrowserMediaViewer } from '../browser/browser-media-viewer';
import { BrowserNetwork } from '../browser/browser-network';
import { BrowserRedirect } from '../browser/browser-redirect';
import { BrowserUpdater } from '../browser/browser-updater';
import { EHardwareResourceStatus } from '../cordova/vendor/hardware-status';
import {
    ICameraHardwareAbstraction,
    IFileSystemLayer,
    IGeolocationHardwareAbstraction,
    IHardwareAbstractionLayer, ILocalNotificationsHardwareAbstraction,
    IMicrophoneHardwareAbstraction,
    INetworkHardwareAbstraction,
    INotificationHardwareAbstraction,
    IRedirectHardwareAbstraction,
    IStorageLayer,
    IUpdaterHardwareLayer,
    IVibrationHardwareAbstraction
} from '../hardware-interfaces';
import { SharedStorage } from "../shared/SharedStorage";
import { SharedVibration } from "../shared/SharedVibration";
import { ReactGeolocation } from './react-geolocation';
import { ReactMicrophone } from './react-microphone';
import { ReactNotifications } from './react-notifications';

const ClientJS: typeof import('clientjs') = require("clientjs").ClientJS;
const loadcss = require("fg-loadcss");

Object.assign(window, {
    ClientJS,
    FingerPrintJS,
});

declare var window: any;// & typeof global['window'];

@Injectable({
    providedIn: 'root'
})
export class ReactHardwareLayer implements IHardwareAbstractionLayer {
    protected log = createServiceLogger('ReactHardwareLayer', '#0091CC');

    protected pnPromise: Promise<string>;
    protected pnPromiseResolver: (val: any) => void;
    protected parsedUA: Parser.ParsedResult = Bowser.parse(window.navigator.userAgent);

    private mobileDevices: TNotificationDeviceArray;

    private pageVisibility$ = new Subject<IFocusInfo>();

    constructor(
        private geo: ReactGeolocation,
        private vibrate: SharedVibration,
        private mic: ReactMicrophone,
        private storage: SharedStorage,
        private camera: BrowserCamera,
        private file: BrowserFilesystem,
        private net: BrowserNetwork,
        private redirect: BrowserRedirect,
        private notifications: ReactNotifications,
        private generic: BrowserGeneric,
        private localNotification: BrowserLocalNotification,
        private audio: BrowserAudio,
        private updater: BrowserUpdater,
        private fileOpener: BrowserFileOpener,
        private mediaViewer: BrowserMediaViewer,
        // private notificationSvc: PushNotificationsService
    ) {
        this.createPnPromise();

        document.addEventListener('visibilitychange', () => {
            this.pageVisibility$.next({ isFocus: !document.hidden, });
        });
    }

    onVisibilityChange(): Observable<IFocusInfo> {
        return this.pageVisibility$.asObservable();
    }

    protected createPnPromise(): void {
        this.pnPromise = new Promise<string>((resolve, reject) => {
            this.pnPromiseResolver = resolve;
        });
    }

    setSocketConnectionOnline(isOnline: boolean): void {
        this.getNetworkLayer().setSocketIsOnline(isOnline);
    }

    getUpdater(): IUpdaterHardwareLayer {
        return this.updater;
    }

    waitUntilUnlock(): Promise<void> {
        return Promise.resolve();
    }

    setCachedPlayer(player: PlayerCachedInfo): void { }

    isFocused(): boolean {
        return !document.hidden;
    }

    bootListeners(): void { }

    getFile(): IFileSystemLayer {
        return this.file;
    }

    getStorage(): IStorageLayer {
        return this.storage;
    }

    getVibrationLayer(): IVibrationHardwareAbstraction {
        return this.vibrate;
    }

    getCameraLayer(): ICameraHardwareAbstraction {
        return this.camera;
    }

    getGeolocationLayer(): IGeolocationHardwareAbstraction {
        return this.geo;
    }

    getMicrophoneLayer(): IMicrophoneHardwareAbstraction {
        return this.mic;
    }

    getRedirectLayer(): IRedirectHardwareAbstraction {
        return this.redirect;
    }

    isMobile(): boolean {
        return !!window.navigator?.userAgent.match(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/);
    }

    isNative(): boolean {
        return true;
    }

    getNetworkLayer(): INetworkHardwareAbstraction {
        return this.net;
    }

    getVersion(): IClientVersion {
        return {
            buildType: 'browser',
            build: 'latest',
            assets: 'latest',
            version: versionContol.version,
            platform: EPlatformUpdateType.Browser
        }
    }

    @Memoize()
    public async getMachineId() {
        const agent: FingerPrintJS.Agent = await FingerPrintJS.load();
        const computed: FingerPrintJS.GetResult = await agent.get();
        return computed.visitorId;
    }

    getDeviceUID(): Promise<UID> {
        return new Promise(async (resolve, reject) => {
            try {
                const machineId = await this.getMachineId();

                const completeDeviceID = `${machineId}-${this.getBrowserId()}`

                resolve(completeDeviceID);
            } catch (e) {
                this.log({ getDeviceUID: e });

                resolve(getReadableUniqueID())
            }
        });
    }

    getBrowserId(): string {
        return this.getBrowserName();
    }

    getPNClientID(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (this.platformHasPushNotifications()) {
                this.pnPromise.then(async () => {
                    let token = '';
                    try {
                        token = await window.firebase.messaging().getToken();
                        this.log('getPNClientID 1', { token });
                    } catch (e) {
                        this.log('getPNClientID 2', { error: e?.stack, msg: e?.message });
                    } finally {
                        this.log('getPNClientID 3', { token });
                        resolve(token);
                    }
                });
            } else {
                this.log('getPNClientID !platformHasPushNotifications');
                this.log('o dispositivo nao possui suporte a push notifications');
                resolve('');
            }
        });
    }

    getNotifications(): INotificationHardwareAbstraction {
        return this.notifications;
    }

    getDeviceName(): string {
        const browser: Parser.Parser = Bowser.getParser(window.navigator.userAgent);
        return `${browser.getOS().name} ${browser.getOSVersion() || ''} - ${browser.getBrowser().name}`;
    }

    getBrowserName(): string {
        const browser: Parser.Parser = Bowser.getParser(window.navigator.userAgent);
        return browser.getBrowserName(true)
    }

    getGenericPlatform(): any {
        return this.generic;
    }

    getLocalNotifications(): ILocalNotificationsHardwareAbstraction {
        return this.localNotification;
    }

    onMainHeaderMounted(): void { }
    onMainHeaderUnmounted(): void { }

    async beforeNotificationsActivate(): Promise<boolean> {
        const status = await this.notifications.requestAuthorization();
        return status === EHardwareResourceStatus.GRANTED;
    }

    getAudio() {
        return this.audio;
    }

    getFileOpener() {
        return this.fileOpener;
    }

    runOptimizations() { }

    getMediaViewer() {
        return this.mediaViewer;
    }

    async loadPlatformSpecificAssets() {
        loadcss.loadCSS('./assets/emojis/google.css');
        this.configureFirebase();
        this.pnPromiseResolver(null);
    }

    protected configureFirebase(): void {
        if (this.platformHasPushNotifications()) {
            try {
                const firebase = window.firebase;
                firebase.initializeApp(clientConstants.firebase.settings);
                const messaging = firebase.messaging();

                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker
                        .register('./assets/sw/colmeia-sw.js')
                        .then(registration => {
                            window.firebase.messaging().useServiceWorker(registration);
                        });
                }

                messaging.onMessage((payload) => {
                    this.log('push notification message arrived:', { payload });

                    if (isValidRef(window.Notification) && Notification.permission === 'granted') {
                        new Notification(payload.notification.title, {
                            body: payload.notification.body
                        })
                    }
                })
            } catch (e) {
                console.error('this device does not have push notifications enabled!!!', { e })
            }
        }
    }

    platformHasPushNotifications(): boolean {
        return true;
    }

    isAllowedToChangeNotificationsState(): boolean {
        return true;
    }
}
