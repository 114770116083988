<ng-container *ngIf="!loading; else spinner">
  <h4>
    {{ isEdit ? translations.editAnnotation : translations.createAnnotation }}
  </h4>

  <div *ngIf="!inputSchema">
    <mat-form-field class="select-type" appearance="fill" *ngIf="!answer || !schema">
      <mat-label> {{ translations.typeLabel }} </mat-label>
      <mat-select
        (selectionChange)="selectionChange($event)"
        [(ngModel)]="schema"
      >
        <mat-option [value]="undefined">---</mat-option>
        <mat-option *ngFor="let item of schemas" [value]="item">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div *ngIf="schema; else showSelect" [@.disabled]="disabledAnnotations" style="flex-grow: 1; display: flex; overflow: hidden;">
    <app-general-form-viewer
      #generalFormViewer
      *ngIf="generalFormEvaluator"
      [primaryID]="serializableId"
      [schema]="schema"
      [answer]="answer"
      [viewType]="_viewType"
      [engagement]="engagement"
      [loading]="saving"
      (save)="saveAnnotation($event)"
      [fnFieldValidator]="fnFieldValidator"
      [generalFormEvaluator]="generalFormEvaluator"
      [computedInfo]="computedInfo"
    ></app-general-form-viewer>
  </div>

  <ng-template #showSelect>
    <div class="no-template-container">
      <mat-form-field>
        <textarea
          matInput
          placeholder = "Digite sua anotação"
          [(ngModel)]="text"
        ></textarea>
        <!-- placeholder = "{{ translations.typeYourAnnotation }}" -->
      </mat-form-field>
      <div class="actions">
        <button
          mat-flat-button
          color="primary"
          (click)="saveTemplatelessAnnotation()"
        >
          {{ translations.save }}
        </button>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #spinner>
  <section class="spinner-container">
    <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
  </section>
</ng-template>
