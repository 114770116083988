import { Component, OnInit } from '@angular/core';
import { TSchemaPropertyArray } from '@colmeia/core/src/general-form/general-form-interface';
import { EBPMMarketingActionType, IBPMMarketingActionServer, TBpmMarketingNaturalNsTypes } from '@colmeia/core/src/shared-business-rules/BPM/marketing/bpm-marketing.model';
import { SchemaPropertyServer } from '@colmeia/core/src/shared-business-rules/files/files';
import { ESourceOfInfo } from '@colmeia/core/src/shared-business-rules/metadata/meta-engagement';
import { ENonSerializableObjectType } from '@colmeia/core/src/shared-business-rules/non-serializable-id/non-serializable-id-interfaces';
import { IBPMListenerHandler } from 'app/components/dashboard/bpm/bpm-listener/bpm-listener.handler';
import { ETimestmapPickerElements, TimestampPickerHandler } from 'app/components/foundation/timestamp-picker/timestamp-picker.component';
import { MKTActionBpmGraphCreateHandler } from 'app/model/bpm-graph-handler.model';
import { NewConditionEditorHandler, NewConditionEditorParameter } from '../../new-condition-editor/new-condition-editor.component';
import { MarketingBpmBase } from '../marketing-bpm-base.component';

@Component({
    selector: 'app-marketing-bpm-action',
    templateUrl: './marketing-bpm-action.component.html',
    styleUrls: ['./marketing-bpm-action.component.scss']
})
export class MarketingBpmActionComponent extends MarketingBpmBase<MKTActionBpmGraphCreateHandler, IBPMMarketingActionServer> implements OnInit {
    nsType = ENonSerializableObjectType.bpmMarketingAction as TBpmMarketingNaturalNsTypes;

    evaluatorsOfActions: NewConditionEditorHandler;

    timestampHandler: TimestampPickerHandler;

    bpmListenerHandler: IBPMListenerHandler;

    private conditionsFields: TSchemaPropertyArray = [];

    getNS(): IBPMMarketingActionServer {
        return this.handler.getMarketingAction();
    }

    ngOnInit() {
        super.ngOnInit();
        this.initConditionsEditor();
        this.initTimestampPicker();
        this.initBPMListenerHandler();
    }

    private initConditionsEditor() {
        const parameter: NewConditionEditorParameter = {
            field: undefined,
            fields: this.conditionsFields,
            clientCallback: undefined,
            allowedActionTypes: {
                [EBPMMarketingActionType.messageWithCampaing]: true,
                // [EBPMMarketingActionType.goCampaignWithBot]: true,
            },
            currentConditions: this.ns.condFormsAction,
            allowedSourceInfo: [ESourceOfInfo.campaignAction, ESourceOfInfo.canonical, ESourceOfInfo.mkt],
            disableAutoAdd: true,
        }

        this.evaluatorsOfActions = new NewConditionEditorHandler(parameter);
    }

    private initTimestampPicker() {
        this.timestampHandler = new TimestampPickerHandler({
            label: 'Executar próximo comando após',
            elements: {
                [ETimestmapPickerElements.Days]: {
                    min: 0,
                    max: 365
                },
                [ETimestmapPickerElements.Hours]: {
                    min: 0,
                    max: 23
                },
                [ETimestmapPickerElements.Minutes]: {
                    min: 0,
                    max: 59
                },
            },
            time: this.ns.bpmScheduleMS,
            clientCallback: {
                onChangeTime: (value: number) => {
                    this.ns.bpmScheduleMS = value;
                },
            },
        });
    }

    private initBPMListenerHandler() {
        this.bpmListenerHandler = {
            listener: this.ns,
            hideSubscriber: true,
            clientCallback: {
                onFormLoad: (metadata: SchemaPropertyServer) => {
                    this.conditionsFields = metadata.schemma.form;
                    this.initConditionsEditor();
                }
            }
        }
    }
}
