import { getTranslationsFieldsAndScreenOptions, EAllSerializable } from "../all-serializables";


const macro = getTranslationsFieldsAndScreenOptions({
    idSerializable: EAllSerializable.Macro,
    fields: {
        createServicePackage: 1, // 'SNManagement/macros' 20
        editMacroPackage: 2, // 'SNManagement/macros' 26
        possibleVariables: 3, // 'SNManagement/macros' 27
        addMacro: 4, // 'SNManagement/macros'  28
        errorLoading: 5, // 'SNManagement/macros' 24
        noServicePackages: 6, // 'SNManagement/macros' 25
        remove: 7, // 'SNManagement/macros' 30
        macroName: 8, // 'SNManagement/macros' 1
        macroTitle: 9, // 'SNManagement/macros' 31
        macroMessage: 10, // 'SNManagement/macros' 32
        editMessage: 11, // 'dashboardMacroEditItem' 36
        servicePackages: 12, // 'SNManagement/macros' 19
        serviceGroups: 13, // 'SNManagement/macros' 26
        servicePackageName: 14, // 'SNManagement/macros' 21
        addGroup: 15,// 'SNManagement/macros' 33
    },
});

export const macroScreenOptions = macro.screenOptions;
export const macroTranslations = macro.translations;

