import { FriendlyMessage } from "@colmeia/core/src/error-control/friendly-message";
import { isValidArray } from "@colmeia/core/src/tools/barrel-tools";
import { ETransformerType, TTransformers } from "../../../transformer/transformer.model";
import { TNserValidator } from "../validate-nser";
import { isValidDisplayFields } from "./corporate-search";
import { defaultNserValidator } from "./nser";

export const transformerNSValidator: TNserValidator = (nser: TTransformers, friendly: FriendlyMessage) => {
    defaultNserValidator(nser, friendly);

    if (nser.transformerType === ETransformerType.schemaRowView) {

        // if (!isValidString(nser.schemaRowView.idSchema)) {
        //     friendly.addReturnGenericErrorMessage('Selecione o metadado');
        // }


        if (!isValidArray(nser.schemaRowView.fields) || !isValidDisplayFields(nser.schemaRowView.fields)) {
            friendly.addReturnGenericErrorMessage('Configuração incorreta dos campos');
        }

    }


    return friendly;
};