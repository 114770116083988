

import { SharedServices } from "@colmeia/core/src/shared-business-rules/shared-services/shared-services.entities";
import { AddFnName } from "@colmeia/core/src/tools/utility/functions/toStatic";
import { AddStaticClassNameOnStack, mapValues, StaticClass, values } from "../../tools/utility";
import { GetByKey, ReMapObject } from "../../tools/utility-types";
import { RemoveNestedKeys } from "./shared-services.interfaces";


export const $SharedServices = createSharedServices(SharedServices);

export function createSharedServices<T extends {}>(Services: T) {
    mapValues(Services as {  [key in string]: StaticClass }, (service, name) => {
        if (!isClass(service)) return;
        AddStaticClassNameOnStack(service);
    });
    type Out = RemoveIgnoredKeys<RemoveNestedKeys<FilterByKey<T, `${string}Service`>, 'prototype'>>;

    return Services as {} as { [key in keyof Out]: AddFnName<Out[key], key & string> }
}

function isClass(target: unknown) {
    if (typeof target !== 'function') return false;
    if (!target) return false;
    try {
        return Reflect.getPrototypeOf(target as never)
    } catch {
        return false;
    }
}
type FilterByKey<T, Key> = ReMapObject< { [key in keyof T]: key extends Key ? Pick<T, key> : {} }>

export type RemoveIgnoredKeys<T extends { [key in string]: {} }> = {
    [name in keyof T]: RemoveIgnoredFromClass<T[name], GetByKey<T[name], 'ignored'>[never]>;
};

export type RemoveIgnoredFromClass<T extends { ignored?: (keyof T)[] }, InputKeys = GetByKey<T, 'ignored'>[never], Keys = 'ignored' | InputKeys> = ReMapObject<{
    [methodName in keyof T]: methodName extends Keys ? {} : { [key in methodName]: T[methodName]; }
}>
