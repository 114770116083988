import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IHelpTipHandler } from 'app/components/helper-info/help-tip/help-tip.component';
import { HelpTipsService } from 'app/services/help-tips.service';
import { ITooltipInfoModel } from "@colmeia/core/src/shared-business-rules/tooltip-json/tooltip-json.model";
import { isValidRef } from '@colmeia/core/src/tools/utility';
import { TooltipPosition } from '@angular/material/tooltip';

export type THelperInfoVerticalAlign = "top" | "center" | "bottom";
export type THelperInfoHorizontalAlign = "left" | "center" | "right";

@Component({
  selector: '[helperInfo]',
  templateUrl: './helper-info.component.html',
  styleUrls: ['./helper-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelperInfoComponent implements OnInit, OnChanges {
  public classes: string = '';

  @HostBinding('class.helper-info-container') readonly addContainerClass: boolean = true;

  @Input()
  helperInfo: string;

  @Input("hiPopover")
  // define se vai exibir popover ou apenas um mat tooltip
  isPopover: boolean;

  @Input()
  hiIcon: string = "help_outline";

  @Input()
  hiVertialAlign: THelperInfoVerticalAlign = "top";

  @Input()
  hiHorizontalAlign: THelperInfoHorizontalAlign = "right";

  @Input()
  hiIconSize: number = 18;

  _indicatorStyle: SafeStyle;
  @Input()
  hiIndicatorStyle: string = "right";

  @Input()
  isIconOnly: boolean = false;

  @Input()
  topPosition?: number; // TODO: change to "hiTopPosition"
  @Input()
  rightPosition?: number; // TODO: change to "hiRightPosition"
  @Input()
  bottomPosition?: number; // TODO: change to "hiBottomPosition"
  @Input()
  leftPosition?: number; // TODO: change to "hiLeftPosition"

  // https://material.angular.io/components/tooltip/api
  // mirror of the available Material Tooltip options
  @Input()
  hiTooltipPosition?: TooltipPosition = "below";
  @Input()
  hiPositionAtOrigin?: boolean = false;
  @Input()
  hiTooltipDisabled?: boolean = false;
  @Input()
  hiHideDelay?: number = 0;
  @Input()
  hiShowDelay?: number = 0;

  tipHandler: IHelpTipHandler;

  constructor(
    private elementRef: ElementRef,
    private domSanitizer: DomSanitizer,
    private helpTipsService: HelpTipsService
  ) { }

  public ngOnInit() {
    this.defineClasses();

    if (this.isPopover) {
      this.initTooltip();
    }
  }

  public ngOnChanges() {
    this.defineClasses();
  }

  private defineClasses() {
    this.classes = `hi-v-${this.hiVertialAlign} hi-h-${this.hiHorizontalAlign}`;
    this._indicatorStyle = this.domSanitizer.bypassSecurityTrustStyle(this.hiIndicatorStyle);
  }

  private initTooltip() {
    const tipId = this.helperInfo;

    const tip: ITooltipInfoModel = this.helpTipsService.getTipById(tipId);

    if (isValidRef(tip)) {
      this.tipHandler = {
        message: tip.message,
        link: tip.link
      }
    }

    this.setHelpIdHTMLAttribute(tipId);
  }

  private setHelpIdHTMLAttribute(tooltipId: string) {
    const componentElement: HTMLElement = this.elementRef.nativeElement;

    componentElement.setAttribute("data-help-id", tooltipId);
  }
}
