import { ViewContainerRef } from "@angular/core";
import { DialogPosition } from "@angular/material/dialog";
import { Direction } from "@angular/cdk/bidi";
import { ScrollStrategy } from "@angular/cdk/overlay";

export enum ECmModalState {
    Closed = 1,
    Open
}

export interface ICmModalParameter {
    state: ECmModalState
    componentProjectionInstance: any;
    beforeWindowCloseCallback?: () => void;
    onWindowClosedCallback?: () => void;
    onWindowOpenedCallback?: () => void;
    settings?: ICmModalSettings,
    data?: any;
}

export interface ICmModalSettings {
    /**
     * Where the attached component should live in Angular's *logical* component tree.
     * This affects what is available for injection and the change detection order for the
     * component instantiated inside of the dialog. This does not affect where the dialog
     * content will be rendered.
     */
    viewContainerRef?: ViewContainerRef;
    /** ID for the dialog. If omitted, a unique one will be generated. */
    id?: string;
    /** Custom class for the overlay pane. */
    panelClass?: string | string[];
    /** Whether the dialog has a backdrop. */
    hasBackdrop?: boolean;
    /** Custom class for the backdrop, */
    backdropClass?: string;
    /** Whether the user can use escape or clicking on the backdrop to close the modal. */
    disableClose?: boolean;
    /** Width of the dialog. */
    width?: string;
    /** Height of the dialog. */
    height?: string;
    /** Min-width of the dialog. If a number is provided, pixel units are assumed. */
    minWidth?: number | string;
    /** Min-height of the dialog. If a number is provided, pixel units are assumed. */
    minHeight?: number | string;
    /** Max-width of the dialog. If a number is provided, pixel units are assumed. Defaults to 80vw */
    maxWidth?: number | string;
    /** Max-height of the dialog. If a number is provided, pixel units are assumed. */
    maxHeight?: number | string;
    /** Position overrides. */
    position?: DialogPosition;
    /** Layout direction for the dialog's content. */
    direction?: Direction;
    /** ID of the element that describes the dialog. */
    ariaDescribedBy?: string | null;
    /** Aria label to assign to the dialog element */
    ariaLabel?: string | null;
    /** Whether the dialog should focus the first focusable element on open. */
    autoFocus?: boolean;
    /** Scroll strategy to be used for the dialog. */
    scrollStrategy?: ScrollStrategy;
    /** Whether the dialog should close when the user goes backwards/forwards in history. */
    closeOnNavigation?: boolean;
}
