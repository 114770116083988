import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { gTranslations } from '@colmeia/core/src/shared-business-rules/const-text/translations';
import { RootComponent } from 'app/components/foundation/root/root.component';

@Component({
  selector: 'app-group-service-form-history',
  templateUrl: './group-service-form-history.component.html',
  styleUrls: ['./group-service-form-history.component.scss']
})
export class GroupServiceFormHistoryComponent extends RootComponent<'formHistory'> {
  botThreadId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataToComponent: { botThreadId: string },
  ) {
    super({
      formHistory: gTranslations.fragments.formHistory,
    });

    this.botThreadId = dataToComponent.botThreadId;
  }
}
