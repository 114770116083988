import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedMsgsViewerHandler } from './shared-msgs-viewer.handler';
import { Serializable } from '@colmeia/core/src/business/serializable';
import {HandlerFactoryService} from "../../../../../handlers/handler-factory.service";
import { shareViewScreenOptions } from '@colmeia/core/src/shared-business-rules/const-text/views/share';
import { IHashSet } from '@colmeia/core/src/tools/utility-types';


type TIShareInfoArray = Array<IShareInfo>;

interface IShareInfo {
    userName: string,
    dateTimestamp: number,
}

 @Component({
    selector: 'app-shared-msgs-viewer',
    templateUrl: './shared-msgs-viewer.component.html',
    styleUrls: ['./shared-msgs-viewer.component.scss']
})
export class SharedMsgsViewerComponent implements OnInit {
    localConstants: IHashSet<string> = {
        title: ''
    };
    handler: SharedMsgsViewerHandler;
    shareList: TIShareInfoArray;

    constructor(
        private handlerFactorySvc: HandlerFactoryService,
        @Inject(MAT_DIALOG_DATA) private data: SharedMsgsViewerHandler
    ) {
        if(this.data)
            this.handler = data;

        this.shareList = this.handler.getShares()
            .map(share => ({
                dateTimestamp: share.getClockTick(),
                hexHandler: this.handlerFactorySvc.newHexagon({
                    serializable: share.getParticipant().getAvatar()
                }),
                userName: share.getParticipant().getAvatar().getName(),
            }))
    }

    ngOnInit() {
        this.localConstants.title = Serializable
                .staticFactory(shareViewScreenOptions.idSerializable)
                .getSerializableText(shareViewScreenOptions.fields.share)
    }

    geSharesList(): TIShareInfoArray {
        return this.shareList
    }

    getSharesLength(): number {
        return this.geSharesList().length
    }

    getTitle(): string {
        return this.geSharesList().length + ' ' + this.localConstants.title
    }

    onModalClosed() {
        this.handler.closeClicked()
    }
}
