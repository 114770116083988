<div class="ordenable-list">

  <div class="ordenable-item ordenable-header">
    <div class="ordenable-item-content">
      <div class="row-title">{{ translations.events }}</div>
    </div>
    <div class="ordenable-item-actions">
      <button
        mat-mini-fab
        color="primary"
        [matTooltip]="translations.add"
        [matMenuTriggerFor]="availableEventsMenu"
        [disabled]="!availableEvents.length"
      >
          <mat-icon>
            add
          </mat-icon>
      </button>
      <mat-menu #availableEventsMenu="matMenu">
        <button
          *ngFor="let eventType of availableEvents"
          mat-menu-item
          (click)="openEventModal(initBotEvent(eventType))"
          [attr.title]="translations[eventType] ? translations[eventType].value : eventType">
          {{ translations[eventType] ? translations[eventType].value : eventType }}
        </button>
      </mat-menu>
    </div>
  </div>

  <div *ngFor="let event of botEvents" class="ordenable-item">
    <div class="ordenable-item-content">
      <div class="row-name">{{ getEventName(event) }}</div>
    </div>
    <div class="ordenable-item-actions">
      <button mat-icon-button color="primary" (click)="openEventModal(event, true)">
        <mat-icon>edit</mat-icon>
      </button>

      <button mat-icon-button color="primary" (click)="removeEvent(event)">
        <mat-icon>delete</mat-icon>
      </button>

    </div>
  </div>

</div>
